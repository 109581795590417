import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as taskActions from "../../../actions/ToDo";
import {ES_INDEX} from '../../../constants'
import DatePickerBox from "../../views/DatePickerBox"
import AutoComplete from "../../views/AutoComplete"
import * as usersActions from "../../../actions/Users";
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {formatDate, getUrlSegment} from "../../../utilities/CustomFunctions";
import LoaderSvg from "../../views/LoaderSVG";
import * as phaseActions from "../../../actions/Phases";

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class AddNewMilestone extends Component {

    constructor(props) {
        super(props);

        this.state={
            milestonename:'',
            // startdate:new Date(),
            // enddate:new Date(),
            internalmilestone:'',
            selectedResponsible:[],
            milestoneid:0,
            strtdate_err:'',
            enddate_err:'',
            loading:0,
            responsibleError:false,
            phase:'',

        };
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.createNewmilestone = this.createNewmilestone.bind(this);
        this.usersList = this.usersList.bind(this);
        this.getPhaseList = this.getPhaseList.bind(this);
    }



    componentDidMount() {
        //console.log(this.props.action);
        if(this.props.action === "update"){
            this.setState({loading: 1})
        }
        this.getPhaseList();
    }

    usersList(searchText) {
        var user_data = { uid : "0", search : searchText? searchText : '',  offset : 100 , ignoreUsers : true, ignoreArrays :[], valueField : 'uniqueId', userOnly : true};
        this.props.users(user_data);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.milestondedata.getmilestoneData !== this.props.milestondedata.getmilestoneData ) {
            let milestoneData = this.props.milestondedata.getmilestoneData;
            //console.log(milestoneData)
            if(milestoneData){
                this.setState({loading:0});
                let milestoneusers = milestoneData.milestoneusers;
                let milestoneinfo = milestoneData.milestoneinfo;
                let selectedResponsible = milestoneusers;

                this.setState({
                    milestonename:milestoneinfo.milestone_name,
                    startdate:new Date(milestoneinfo.startdate),
                    enddate:new Date(milestoneinfo.enddate) ,
                    selectedResponsible : selectedResponsible,
                    // internalmilestone:milestoneinfo.internaluse,
                    milestoneid :milestoneinfo.milestone_id,
                    phase:milestoneinfo.phase_id

                })
            }
            this.setState({loading: 0})

        }
    }
    getPhaseList() {
        var projectid = atob(getUrlSegment(4));
        if(getUrlSegment(3) === "management"){
            projectid = atob(getUrlSegment(4))
        }else if(getUrlSegment(3) === "tasks"){
            projectid = atob(getUrlSegment(5))
        }
        var phaseParams  = {
            "projectid":projectid
        }
        this.props.projectphases(phaseParams);

    }
    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value})
        if (e.target.name === "internalmilestone") {

            if (e.target.value !== '' || e.target.value !== 'select') {
                e.target.classList.remove('val-error');
            }
        } else {
            if (e.target.value !== '') {
                e.target.classList.remove('val-error');
            }
        }
    }

    handleStartDateChange(date){
        this.setState({
            startdate : date
        })
        document.getElementById('startdate').classList.remove('val-error');
    }
    handleEndDateChange(date){
        this.setState({
            enddate : date
        })
        document.getElementById('enddate').classList.remove('val-error');
    }
    resultDataList( result, reference) {
        //console.log(result);
        this.setState({ [reference] : result });
    }

    createNewmilestone(e){
        e.preventDefault();

        let formValid = 0;
        var projectid = (atob(getUrlSegment(4)));
        var startdate = formatDate(this.state.startdate);
        var enddate = formatDate(this.state.enddate);

        var selectedResponsible = this.state.selectedResponsible;
        var Responsible = '';
        var milestonename = this.state.milestonename;
       // var internalmilestone = this.state.internalmilestone;
        var phase = this.state.phase;
        var milestoneid = this.state.milestoneid === 0 ? moment().valueOf() : this.state.milestoneid;
        var mode = this.state.milestoneid === 0? "save":"update";

        if (this.state.milestonename === '') {
            formValid++;
            document.getElementsByName('milestonename')[0].classList.add('val-error');
        }

        if (selectedResponsible.length  === 0) {
            formValid++;
            this.setState({responsibleError:true})
        }else{
            this.setState({responsibleError:false})
        }

        // if (this.state.internalmilestone === 'select' || this.state.internalmilestone === '') {
        //     formValid++;
        //     document.getElementsByName('internalmilestone')[0].classList.add('val-error');
        // }

        if (this.state.phase === 'select' || this.state.phase === '') {
            formValid++;
            document.getElementsByName('phase')[0].classList.add('val-error');
        }
        // if(this.state.startdate === '' ||  this.state.startdate === undefined) {
        //     formValid++;
        //     document.getElementById('startdate').classList.add('val-error');
        // }
        if(this.state.enddate === '' ||  this.state.enddate === undefined)
        {
            formValid++;
            document.getElementById('enddate').classList.add('val-error');
        }
        if ((this.state.startdate !== '' && this.state.startdate !== undefined )|| (this.state.enddate !== '' && this.state.enddate !== undefined)) {
            var start_date = formatDate(this.state.startdate);
            var end_date = formatDate(this.state.enddate);
            var today_date = formatDate(new Date);

            if(mode == 'save') {
                if (end_date < today_date) {
                    formValid++;
                    this.setState({enddate_err: true})
                    // alert('please enter a future date for end date');
                } else {
                    this.setState({enddate_err: ''})
                    this.setState({enddate: new Date()})
                }
            }
        }

        if(formValid === 0){
            this.setState({loading: 1});
            if (selectedResponsible.length > 0) {
                for (var i = 0; i < selectedResponsible.length; i++) {
                    Responsible = (Responsible !== '') ? (Responsible + ",") : "";
                    Responsible += selectedResponsible[i].userId;
                }
            }

            var concat = milestoneid + ' ' + getProfile().id + ' ' + projectid + ' ' + milestonename  + ' ' + enddate + ' ' + ' ' + Responsible + '' + phase;
            let milestonedata = {
                "i": milestoneid,
                "projectid": projectid,
                "mode": mode,
                "milestonename": milestonename,
                "uid": getProfile().id,
               // "startdate": startdate,
                "enddate": enddate,
                // "internal": internalmilestone,
                "concat": concat,
                "responsible": Responsible,
                "phase_id":phase
            };
            //console.log(milestonedata)
            this.props.savemilestone(milestonedata);

        }

    }


    render() {
        let PhasesList = this.props.listAllprojectPhaseData.listprojectphase;
        return (
                <div className="task-content-block">

                    <div className="persitant-top with-pad">
                        {this.state.loading === 1 &&
                        <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                        }
                        <div className="task-input-block">
                            <input type="text" id="milestonename" name="milestonename" className="task-input-box--new" placeholder="Name" defaultValue={this.state.milestonename} onChange={(e)=>this.handleInputChange(e)}/>
                        </div>
                        {/*<div className="task-input-block width-adjust date-arrow" id="startdate">*/}
                        {/*    <DatePickerBox addErrorClass = {this.state.strtdate_err}  placeholderText = {'Start Date'} handleDateChange={this.handleStartDateChange.bind(this)} startDate = {this.state.startdate}/>*/}
                        {/*</div>*/}
                        <div className="task-input-block width-adjust date-arrow" id="enddate">
                            <DatePickerBox  addErrorClass = {this.state.enddate_err} placeholderText = {'End Date'} handleDateChange={this.handleEndDateChange.bind(this)} startDate = {this.state.enddate} minDate={new Date()}/>
                        </div>
                        <div className="task-inp-container pj-inp-container">
                            <AutoComplete
                                fieldTitle = 'Responsible'
                                sourceDataList = {this.props.listUsers.todoUsers}
                                placeholder=' '
                                searchField = 'firstName'
                                showIcon={true}
                                showSubLabel = {true}
                                labelFields = 'firstName,lastName'
                                valueField ='userId'
                                subLabelField = 'designation'
                                showProfile = {true}
                                reference='selectedResponsible'
                                selectedItems = {this.state.selectedResponsible}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="responsible_users"
                                instantSearch={true}
                                actionMethod={this.usersList}

                            />
                            <label className = "assign-user-validation-err" style={{display: this.state.responsibleError === false ? 'none' : 'block' }}>Please provide select atleast one Responsible.</label>
                        </div>
                        <div className="split-drop-box">
                        {/*<div className="select-box eq-width">*/}
                        {/*    <select id="internaluse" name="internalmilestone" value={this.state.internalmilestone} onChange={(e)=>this.handleInputChange(e)}>*/}
                        {/*        <option value="select">Internal Milestone</option>*/}
                        {/*        <option value="yes">Yes</option>*/}
                        {/*        <option value="no">No</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        <div className="select-box eq-width">
                            <select id="phase" name="phase" value={this.state.phase} onChange={(e)=>this.handleInputChange(e)}>
                                <option value="select">Select Phase</option>
                                {PhasesList.map((list,index) =>
                                    < option value={list.phaseId} key={'phase'+index}>{list.phaseName}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    </div>
                    <div className="persitant-footer">
                        <div className="group-row">

                        </div>
                        <button data-masterid="0" data-mode="save" type="button" className="general-btn btn-shadow"
                                id="createmilestone"  onClick={(e)=>this.createNewmilestone(e)}>{this.state.milestoneid === 0 ? "Create" : "Update"}
                        </button>

                    </div>
                </div>

        );
    }
}


const mapStateToProps = state => ({
    milestondedata:state.ProjectReducer.GetMilestone,
    listUsers:state.UsersReducer.listUsersdata,
    listAllprojectPhaseData:state.PhaseReducer.getprojectPhaseDetails,
});
export default  withRouter(connect(mapStateToProps, {
    savemilestone:projectActions.saveMilestone,
    users:usersActions.listTodoUsers,
    projectphases:phaseActions.listprojectphases,
})(AddNewMilestone));



