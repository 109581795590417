import React, {Component} from 'react';
import SimpleModal from "../views/SimpleModal";
import {EditProfile} from "../pages/FormsData";
import {Button, ButtonToolbar,Accordion,Card} from "react-bootstrap";
import {getProfile} from "../../utilities/AuthService";
import {USRIMG_PATH } from '../../constants/index';
 import {ES_INDEX} from "../../constants";
import {connect} from "react-redux";
import * as taskActions from "../../actions/ToDo";
import {withRouter} from 'react-router';
import rootActions from "../../actions";
import * as usersActions from "../../actions/Users"
import LoaderSvg from "../views/LoaderSVG";

class ReportIssue extends Component {

    constructor(props) {
        super(props);
        this.state={
            sidePanelShow: false,
            desc:'',
            modalStatus: false,
            userlistdata: []
        }
        this.modalChange=this.modalChange.bind(this)
        this.sidePanelChange=this.sidePanelChange.bind(this)
        this.searchHelp=this.searchHelp.bind(this)
    }



    componentDidMount() {

        let userdata = {
            _id: ES_INDEX+'10006'
        }
        this.props.helpdata(userdata);
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    sidePanelChange(e, sidePanelShow, list, headername){
        //////console.log(list)
        this.setState({
            sidePanelShow: sidePanelShow,
            userlistdata: list,
            headername: headername
        })

    }

    searchHelp(e) {
        let searchValue = e.target.value;
        if(searchValue !== ''){
            let userdata = {
                _id: ES_INDEX+'10006',
                search: searchValue
            }
            this.props.helpdata(userdata);
        }
        else{
            let userdata = {
                _id: ES_INDEX+'10006'
            }
            this.props.helpdata(userdata);
        }


    }
    modalChange(e, sidePanelShow){
        this.setState({sidePanelShow:sidePanelShow})
    }

    render() {
        //////console.log(this.props)
        let UserHelp = this.props.HelpData.HelpInfo[0] !== undefined ? this.props.HelpData.HelpInfo[0]:[];
        //////console.log(UserHelp);
        let userlist = this.state.userlistdata;
        //////console.log(userlist);
        //////console.log(this.props.HelpData.loading)
        return (

            <>


                <div className="modal full-width" id="show-collection-detail" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="container">
                                    <h5 className="modal-title dynamic_heading" id="myModalLabel">Issue Reporter</h5>
                                </div>
                                <button type="button" className="close" data-dismiss="modal"
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body">


                                <div className="container" id="dynamic_form">
                                    <div id="subSection_0">
                                        <div className="closed-box" id="842432974">
                                            <div id="field_1165012008" className="">
                                                <div className="input-select  satisfied form_error_1165012008"
                                                     data-required="no" id="1434398727"><select
                                                    data-fetch="fetch_1165012008" data-master="1165012008"
                                                    data-validation="No" data-formtype="dropdown"
                                                    id="form_child_1165012008" name="form_child_1165012008"
                                                    data-label="Choose Module">
                                                    <option value="0">Choose Module</option>
                                                    <option value="Dashboard">Dashboard</option>
                                                    <option value="Task">Task</option>
                                                    <option value="Chat">Chat</option>
                                                    <option value="Others">Others</option>
                                                </select></div>
                                            </div>
                                            <div id="field_389675529" className="">
                                                <div className="input-block  satisfied form_error_389675529"
                                                     data-required="yes">
                                                    <div id="1157639132">
                                                        <input type="text" className="fiels-inp-box"
                                                                                data-fetch="fetch_389675529"
                                                                                data-master="389675529"
                                                                                data-validation="Yes"
                                                                                data-formtype="text"
                                                                                id="form_child_389675529"
                                                                                name="form_child_389675529"
                                                                                data-label="Whats the Issue " />
                                                                                <label className="anim-text">Whats the Issue </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="field_1195875265" className="">
                                                <div className="input-block   satisfied form_error_1195875265"
                                                     data-required="no">
                                                    <div id="1356240207"><label>Additional Info</label><textarea
                                                        data-label="Additional Info" data-fetch="fetch_1195875265"
                                                        data-master="1195875265" data-validation="No"
                                                        data-formtype="textarea" id="form_child_1195875265"
                                                        name="form_child_1195875265"></textarea></div>
                                                </div>
                                            </div>
                                            <div id="field_1051781043" className="">
                                                <div className="closed-box-slt  satisfied form_error_1051781043"
                                                     data-required="no" id="520038219">
                                                    <div className="radio-wrapper  ind-form-field"><h5>Mobile /
                                                        Desktop</h5>
                                                        <div className="radio-block"><input type="radio"
                                                                                            data-fetch="fetch_1051781043"
                                                                                            data-master="1051781043"
                                                                                            data-validation="No"
                                                                                            data-formtype="switch"
                                                                                            id="form_child_1051781043"
                                                                                            name="form_child_1051781043"
                                                                                            data-label="Mobile / Desktop"
                                                                                            value="Desktop" />
                                                            <label>Desktop </label>
                                                        </div>
                                                        <div className="radio-block"><input type="radio"
                                                                                            data-fetch="fetch_1051781043"
                                                                                            data-master="1051781043"
                                                                                            data-validation="No"
                                                                                            data-formtype="switch"
                                                                                            id="form_child_1051781043"
                                                                                            name="form_child_1051781043"
                                                                                            data-label="Mobile / Desktop"
                                                                                            value="Mobile" />
                                                            <label>Mobile </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="field_1007408180" className="">
                                                <div className="closed-box-slt  satisfied form_error_1007408180"
                                                     data-required="no" id="1293192131">
                                                    <div className="checkbox-wrapper  ind-form-field"><h5>Browser
                                                        Used</h5>
                                                        <div className="select-chk-block" style="display: block;"><label
                                                            className="control control-checkbox">Chrome<input
                                                            data-fetch="fetch_1007408180" data-master="1007408180"
                                                            data-validation="No" data-formtype="checkbox"
                                                            id="form_child_1007408180" name="form_child_1007408180"
                                                            data-label="Browser Used" value="Chrome" type="checkbox"
                                                            className="inp-chk" />
                                                            <div className="control-indicator"></div></label></div>
                                                        <div className="select-chk-block" style="display: block;"><label
                                                            className="control control-checkbox">Firefox<input
                                                            data-fetch="fetch_1007408180" data-master="1007408180"
                                                            data-validation="No" data-formtype="checkbox"
                                                            id="form_child_1007408180" name="form_child_1007408180"
                                                            data-label="Browser Used" value="Firefox" type="checkbox"
                                                            className="inp-chk" />
                                                            <div className="control-indicator"></div></label></div>
                                                        <div className="select-chk-block" style="display: block;"><label
                                                            className="control control-checkbox">IE<input
                                                            data-fetch="fetch_1007408180" data-master="1007408180"
                                                            data-validation="No" data-formtype="checkbox"
                                                            id="form_child_1007408180" name="form_child_1007408180"
                                                            data-label="Browser Used" value="IE" type="checkbox"
                                                            className="inp-chk" />
                                                            <div className="control-indicator"></div></label></div>
                                                        <div className="select-chk-block" style="display: block;"><label
                                                            className="control control-checkbox">IE Edge<input
                                                            data-fetch="fetch_1007408180" data-master="1007408180"
                                                            data-validation="No" data-formtype="checkbox"
                                                            id="form_child_1007408180" name="form_child_1007408180"
                                                            data-label="Browser Used" value="IE Edge" type="checkbox"
                                                            className="inp-chk" />
                                                            <div className="control-indicator"></div></label></div>
                                                        <div className="select-chk-block" style="display: block;"><label
                                                            className="control control-checkbox">Safari<input
                                                            data-fetch="fetch_1007408180" data-master="1007408180"
                                                            data-validation="No" data-formtype="checkbox"
                                                            id="form_child_1007408180" name="form_child_1007408180"
                                                            data-label="Browser Used" value="Safari" type="checkbox"
                                                            className="inp-chk" />
                                                            <div className="control-indicator"></div></label></div>
                                                        <div className="select-chk-block" style="display: block;"><label
                                                            className="control control-checkbox">Other<input
                                                            data-fetch="fetch_1007408180" data-master="1007408180"
                                                            data-validation="No" data-formtype="checkbox"
                                                            id="form_child_1007408180" name="form_child_1007408180"
                                                            data-label="Browser Used" value="Other" type="checkbox"
                                                            className="inp-chk" />
                                                            <div className="control-indicator"></div></label></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="field_976800835" className="">
                                                <div className="input-block  satisfied form_error_976800835"
                                                     data-required="no">
                                                    <div id="978836985"><input type="text" className="fiels-inp-box"
                                                                               data-fetch="fetch_976800835"
                                                                               data-master="976800835"
                                                                               data-validation="No" data-formtype="text"
                                                                               id="form_child_976800835"
                                                                               name="form_child_976800835"
                                                                               data-label="Browser Version" /><label
                                                        className="anim-text">Browser Version</label></div>
                                                </div>
                                            </div>
                                            <div id="field_700350399" className="">
                                                <div className="closed-box-slt  satisfied form_error_700350399"
                                                     data-required="no" id="111257074">
                                                    <div className="collection-image"
                                                         id="collection-image_700350399"></div>
                                                    <div className="upload-wrapper sm-line">
                                                        <div className="file-upload"><input type="file"
                                                                                            data-fetch="fetch_700350399"
                                                                                            data-master="700350399"
                                                                                            data-validation="No"
                                                                                            data-formtype="attachment"
                                                                                            id="form_child_700350399"
                                                                                            name="form_child_700350399"
                                                                                            data-label="Upload Screenshot(s)"
                                                                                            data-input="false"
                                                                                            onChange="upload_attachment(this,700350399)" /><label>Upload
                                                            Screenshot(s)</label><input type="hidden"
                                                                                        data-fetch="uniqvalue_700350399"
                                                                                        id="form_child_700350399_hide" /><input
                                                            type="hidden" data-fetch="extension_700350399"
                                                            id="form_child_700350399_extension" /><input type="hidden"
                                                                                                       data-fetch="value_700350399"
                                                                                                       id="form_child_700350399_value" />
                                                        </div>
                                                        <div className="fieldset-option " data-before="700350399"><a
                                                            data-multifield="true" data-master="700350399"
                                                            href="javascript:void(0);">
                                                            <button
                                                                className="fieldset-opt-button ico-lib add-plus"></button>
                                                        </a></div>
                                                        <div className="attached-file" data-fileicon="700350399"></div>
                                                    </div>
                                                    <div className="progress hide file-upload-per"
                                                         id="progress_700350399">
                                                        <div id="progress-bar_700350399" className="progress-bar"
                                                             role="progressbar" aria-valuenow="0" aria-valuemin="0"
                                                             aria-valuemax="100" style="width: 100%;">100%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="container form-field-bottom" id="dynamic_bottom">
                                    <button id="saveform" data-master="4_659007690778" data-child="0"
                                            className="general-btn" data-mode="Save">SUBMIT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProps = state => ({
    HelpData:state.UsersReducer.helpInfodata,
    menuClickData:state.CommonReducer.menuClick,
    sidebarmenudata: state.TaskReducer.SidebarMenudata,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    helpdata:usersActions.helpInfo,

})(ReportIssue));



