import React, {Component} from 'react';
import moment from 'moment';
import LoaderSvg from "../views/LoaderSVG";
import {ES_INDEX} from "../../constants";
import {Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import NoData from "./NoData";



class OverviewGraph extends Component {

    constructor(props) {
        super(props);

        this.state={
            intervl: 'day',
            wd:0,
            loading:0
        }
        this.changeInterval = this.changeInterval.bind(this);
        this.getduration = this.getduration.bind(this);
    }



    componentDidMount() {
        let sidemenucontainer = document.querySelector('.side-menu-wrapper');
        //console.log(sidemenucontainer)
        let container = document.querySelector('.graph-block');
        if(sidemenucontainer !== undefined){

            let wd = document.documentElement.clientWidth - 270;
            this.setState({wd: wd});

        }else{

            let wd = document.documentElement.clientWidth;
            this.setState({wd: wd});

        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {




    }
    changeInterval(e, intval) {
        this.setState({intervl: intval});
    }

    getduration(startdate,enddate){

        var start_date = moment(startdate, 'YYYY-MM-DD HH:mm:ss');
        var end_date =  moment(enddate, 'YYYY-MM-DD HH:mm:ss');
        var duration = moment.duration(end_date.diff(start_date));
        var days = duration.asDays();
        let resultdays = parseInt(days) + ' days';
        return resultdays ;
    }


    render() {
        let projectInfo = this.props.projectInfo ? this.props.projectInfo : '';
        let bydateData = this.props.bydateData ? this.props.bydateData : [];
        //let task = this.props.task ? this.props.task : []


        //console.log(projectInfo.clientname)

        let projChart = [];
        if(bydateData) {
            for(let cnt=0; cnt<bydateData.length; cnt++) {
                projChart.push({
                        date:moment(bydateData[cnt].key_as_string).format('Do MMM YYYY'),
                        xaxisdate:moment(bydateData[cnt].key_as_string).format('DD'),
                        New: bydateData[cnt].bystatus.buckets.filter(item => item.key === 'New').map(bucket => bucket.doc_count)[0] || 0,
                        Inprogress: bydateData[cnt].bystatus.buckets.filter(item => item.key === 'InProgress').map(bucket => bucket.doc_count)[0] || 0,
                        Done:bydateData[cnt].bystatus.buckets.filter(item => item.key === 'Done').map(bucket => bucket.doc_count)[0] || 0,
                        Total: bydateData[cnt].doc_count,
                })
            }
        }
        //console.log(projChart)
        const CustomTooltip = ({ active, payload, label }) => {
            if (active && payload) {
                return (
                    <div className="chart-tooltip">
                        <p className="label " style={{fontSize: '14px'}}>{`${payload[0].payload.date || 0}`}</p>
                        <p className="label" style={{color:'#6c757d',fontSize: '12px'}}>{`Total : ${payload[0].payload.Total || 0}`}</p>
                        <p className="label" style={{color:'#63b8ff',fontSize: '12px'}}>{`New : ${payload[0].payload.New || 0}`}</p>
                        <p className="label" style={{color:'#F3A624',fontSize: '12px'}}>{`In Progress : ${payload[0].payload.Inprogress || 0}`}</p>
                        <p className="label" style={{color:'#7db248',fontSize: '12px'}}>{`Done : ${payload[0].payload.Done || 0}`}</p>
                    </div>
                );
            }

            return null;
        };

        const COLORS = {New: '#0b99ff', Done:'#7db248', InProgress:'#F3A624'};
        return(


                <>
                    <div className="summar-tab-header">
                        <div className="summar-tab-header-left">
                            <ul className="project-other-info-block">
                                <li className="ico-lib pj-sm-task">{this.props.taskCount} Tasks</li>
                                <li className="ico-lib pj-sm-date">{this.getduration(projectInfo.startdate,projectInfo.enddate)}</li>
                                <li>Status: <span className="font-bold-500 highlight">{projectInfo.status}</span></li>
                                <li>Client Name: <span className="font-bold-500 highlight">{projectInfo.clientname}</span></li>
                                <li>Deadline: <span className="font-bold-500 highlight">{moment(projectInfo.enddate).format('Do MMM YYYY')}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="summar-tab-header-right">
                            <div className="graph-switcher">
                                <button className={this.state.intervl === 'day' ? "active" : ""} onClick={(e) => this.changeInterval(e, 'day')}>Daily</button>
                                <button className={this.state.intervl === 'month' ? "active" : ""} onClick={(e) => this.changeInterval(e, 'month')}>Monthly</button>
                                <button className={this.state.intervl === 'year' ? "active" : ""} onClick={(e) => this.changeInterval(e, 'year')}>Yearly</button>
                            </div>
                        </div>
                    </div>
                    <div className="graph-block" style={{width: this.state.wd}}>
                        {projChart.length === 0 &&

                        <div className="full-no-data">
                            <h5>No data found !</h5>
                        </div>

                        }
                        {projChart.length !== 0 &&
                            <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                            data={projChart}
                            margin={{
                            top: 0, right: 0, left: 0, bottom: 0,
                        }}
                            >
                            <CartesianGrid vertical={false} stroke="#ebf3f0" strokeDasharray="3 3"  horizontalFill={['#ffffff', '#f8f9fa']} />
                            <XAxis dataKey="xaxisdate"  axisLine={false} tickLine={false} style={{stroke: '#cccccc', color: '#6c757d',fontSize: '12px'}} />
                            <YAxis label={{angle: -90, position: 'insideLeft'}} axisLine={false} tickLine={false} style={{stroke: '#cccccc', color: '#6c757d',fontSize: '12px'}}/>
                            <Tooltip content={<CustomTooltip/>} cursor={{fill: 'transparent'}} />
                            <Legend verticalAlign="top" align="left" height={100} iconType="circle" iconSize={10} wrapperStyle={{
                            paddingLeft: "70px", fontWeight: "bold"
                        }}/>
                            <Bar dataKey="Done" stackId="a" fill="rgb(125, 178, 72)" barSize={10} >
                            </Bar>
                            <Bar dataKey="Inprogress" stackId="a" fill="rgb(243, 166, 36)" barSize={10} >
                            </Bar>
                            <Bar dataKey="New" stackId="a" fill="rgb(99, 184, 255)" barSize={10} >
                            </Bar>
                            </BarChart>
                            </ResponsiveContainer>
                        }
                    </div>

                </>




        );
    }
}

export default  OverviewGraph;
