import React from 'react';
import axios from 'axios';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getProfile} from "../../../../utilities/AuthService";

class OwnFilter extends React.Component {
    constructor(props, context) {
        super(props, context);
        var d = new Date();
        var cid = d.getTime();
        this.state = {
            filterFields: []
        }
    }

    componentDidMount() {
        var obj = this;
        axios({
            method: "GET",
            url: API_PATH + "collection/formfilter?collectionid=" + this.props.collections + "&index=" + ES_INDEX,
            async: false,
            headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
        }).then(function (res) {
            //console.log(res.data.results.data)
            obj.setState({filterFields: res.data.results.data});
            return res;

        }).catch(function (err) {
            return err;
        });
    }

    handleLabelChange = (e, filtertype) => {
        this.setState({[e.target.name]: e.target.value})
        let dtePm = {
            sFilter: e.target.name === "selectfilter" ? e.target.value : this.state.selectfilter,
            filtertype: filtertype
        }
        this.props.filterDate(dtePm, "selectFilter", this.props.ownid)
    }


    render() {
        let ownFilter = (this.props.ownFilterList.length !== 0 && this.props.ownFilterList[0].field)
        let fieldDta = this.state.filterFields.filter(list => list.masterid.toString() === ownFilter.toString());

        return (
            <React.Fragment>
                {fieldDta.length > 0 && fieldDta[0].type === "dropdown" &&
                <div class="select-box">
                    <select name="selectfilter"
                            onChange={(e) => this.handleLabelChange(e, "ownFilter", "conditions")}>
                        <option value="">All</option>
                        {fieldDta[0].fields.map((label, index) =>
                            <option key={`multi-area-chart-masterid` + index} value={label}>{label}</option>
                        )}
                    </select>
                </div>
                }
                {fieldDta.length > 0 && fieldDta[0].type === "autofill" &&
                <select name="selectfilter"
                        onChange={(e) => this.handleLabelChange(e, "ownFilter", "conditions")}>
                    <option value="">All</option>
                    {fieldDta[0].fields.map((label, index) =>
                        <option
                            key={`multi-area-chart-masterid` + index}
                            value={label}>{label}</option>
                    )}
                </select>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({});
export default withRouter(connect(mapStateToProps, {

    filterDate: DynamicDashboard.getFilterData,
})(OwnFilter));



