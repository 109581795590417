export function evaluateForm(formPages) { //IJSC BET INDEX
    //console.log(formPages);
    let  yearList = [0,1,2,3]
    let computedFields = [[],[],[],[],[],[],[],[],[]];
    let scoreFields = [];
    let ratingFields = [];
    let enablerFields = [];
    let min1 = 0;
    let max1 = 4.99;
    let min2 = 5;
    let max2 = 25;
    let min3 = 25.01;

    let ranges = [];

    computedFields[0]['type1'] = [];
    computedFields[0]['type2'] = [];
    computedFields[0]['type3'] = [];

    /*******************************************customer construct*****************************************/

    computedFields[1]['type1']= [{fld :'760931395', delta : '248909370', level : '112662072', score : '1337517991', year : '1051121013'},//delivery- Type 1
                                {fld : '1454808554', delta : '1445377751', level : '247626569', score : '1051434402', year : '660192711'},//customer satisfaction- Type 1
                                {fld : '1163011481', delta : '800740562', level : '492137011', score : '378167239', year : '78558228'},//new-customer-sale- Type 1
                                {fld : '1333979314', delta : '1029906502', level : '1397922204', score : '283264485', year : '930193007'},//new-product-sale- Type 1
                                {fld : '545705833', delta : '1550214969', level : '319030278', score : '984753073', year : '1480978'} //business-share- Type 1
                                ]; //customer construct
    computedFields[1]['type2'] = []; //customer construct
    computedFields[1]['type3'] = [{fld : '1588368677', delta : '1072191904', level : '485501653', score : '193490619', year : '108375809'},//quality - Type 3
    ];  //customer construct

    /*******************************************finance construct*****************************************/

    computedFields[2]['type1'] = [{fld : '1116450248', delta : '211934170', level : '668667532', score : '1294732250', year : '463158695'},//return-on-investment - Type 1
                                 {fld : '48915208', delta : '300696297', level : '712910944', score : '423778795', year : '1110930357'},//net-profit-margins - Type 1
                                 ]; //finance construct

    computedFields[2]['type2'] = [{fld : '482275891', delta : '1394137620', level : '321958244', score : '1452912036', year : '1215867083','min':1.2,'max':2.0}, //current-ratio - Type 2
                                {fld : '425748240', delta : '527699890', level : '1244511466', score : '1363074228', year : '427558824','min':1.0,'max':2.0}, //quick ratio - Type 2
                                {fld : '495306943', delta : '494427978', level : '973904902', score : '687412346', year : '948095729','min':4,'max':6},//inventory-turnover-ratio - Type 2
                                {fld : '924715496', delta : '1085625819', level : '200121664', score : '995975303', year : '1329395451','min':0,'max':35},//overhead-ratio - Type 2
                                {fld : '157199612', delta : '1480730282', level : '1483443811', score : '1088709184', year : '29557257','min':0,'max':2.0}//debt-to-equity - Type 2
                                ]; //finance construct

    computedFields[2]['type3'] = []; //finance construct

    /*******************************************Human resource construct*****************************************/

    computedFields[3]['type1'] = [{fld :'604769188', delta : '229922251', level : '1243112145', score : '420060271', year : '844011005'},//training-hours - Type 1
        {fld :'711586931', delta : '300952172', level : '1405088195', score : '1545702169', year : '1269887166'},//employee-satisfaction - Type 1
        {fld :'889746823', delta : '1021363880', level : '1297745205', score : '1296738877', year : '27306029'},//ideas-implentation - Type 1
        {fld :'1241487191', delta : '338475640', level : '127497159', score : '1161495756', year : '813377317'},//small-group-activities - Type 1
        {fld :'639170354', delta : '1317915305', level : '1146001048', score : '1133570955', year : '1531231014'},//standardization-of-indirect-functions - Type 1
        {fld : '28602627',  delta : '912699161', level : '1510438611', score : '149791237', year : '1083769552'}//lesson-learnt-documentation - Type 1
    ]; //Human resource construct

    computedFields[3]['type2'] = []; //Human resource construct

    computedFields[3]['type3'] = [{fld :'792455554', delta : '133738402', level : '1183839077', score : '295754432', year : '322316330'},//employee-turnover-ratio - Type 3
                                 ]; //Human resource construct

    /*******************************************Operations construct*****************************************/

    computedFields[4]['type1'] = [{fld :'1024004749', delta : '135783931', level : '1010385562', score : '86922875', year : '1327969680'},//process-capability-assessment - Type 1
        {fld :'190562191', delta : '669705032', level : '1002044098', score : '251679187', year : '1153506138'},//productivity - Type 1
    ]; //Operations construct

    computedFields[4]['type2'] = [{fld :'313888718', delta : '1293200092', level : '61054482', score : '181861423', year : '1235306213','min':2,'max':5},//inventory-finished-goods - Type 2
        {fld :'191369161', delta : '1358535807', level : '1547004895', score : '911919277', year : '873910567','min':2,'max':5}//raw-material - Type 2
    ]; //Operations construct

    computedFields[4]['type3'] = [{fld :'418991149', delta : '1303564774', level : '530234129', score : '1355719503', year : '138357880'},//defects-in-process - Type 3
        {fld :'949099029', delta : '845437775', level : '1515600952', score : '170179386', year : '36810591'},//machine-downtim - Type 3
    ]; //Operations construct


    /*******************************************Supply Chain construct*****************************************/


    computedFields[5]['type1'] = [{fld :'62994955', delta : '206118529', level : '1579988284', score : '843606432', year : '994850047'},//delivery-performance - Type 1
    ]; //Supply Chain Construct

    computedFields[5]['type2'] = [{fld :'884056757', delta : '423874003', level : '498620801', score : '1599328380', year : '211936942','min':2,'max':5},//inventory-finished-goods - Type 2
    ]; //Supply Chain Construct

    computedFields[5]['type3'] = [ {fld :'1127757173', delta : '1207761420', level : '1344153955', score : '1463662401', year : '32891609'},//price-reduction - Type 3
        {fld :'1333089832', delta : '239011281', level : '680354160', score : '1160143061', year : '208536992'},//defect-internal - Type 3
        {fld :'1209279914', delta : '896642484', level : '1046002776', score : '1005310296', year : '937664936'},//defect-external - Type 3
    ]; //Supply Chain Construct



    /*******************************************Leadership construct*****************************************/


    computedFields[6]['type1'] = [{fld :'116722750', delta : '372896475', level : '35569688', score : '1074742649', year : '1398643858'},//communication-with-employees - Type 1
                                {fld :'960307180', delta : '46092554', level : '812787313', score : '1541114754', year : '293751065'},//communication-with-external-stakeholders - Type 1
                                {fld :'1193468693', delta : '918502988', level : '1070589126', score : '787814131', year : '1291078226'},//satisfaction-of-stakeholders - Type 1
                                ]; //Leadership construct
    computedFields[6]['type2'] = []; //Leadership construct
    computedFields[6]['type3'] = [
                                    {fld :'315534936', delta : '840227585', level : '996216649', score : '1374355992', year : '561099556'}//ethics-&-governance - Type 3
                                 ]; //Leadership construct


    /*******************************************Strategy construct*****************************************/

    computedFields[7]['type1'] = [{fld :'770138294', delta : '1049804646', level : '885680944', score : '1583820627', year : '1568826026'},//patents - Type 1
        {fld :'1444377151', delta : '465994627', level : '1037853528', score : '955160683', year : '572208990'},//process-technology-innovations - Type 1
        {fld :'873101943', delta : '407781240', level : '345530442', score : '1384542575', year : '315940927'},//product-technology-innovations - Type 1
        {fld :'393447202', delta : '782811113', level : '1179420894', score : '680146761', year : '638450712'},//technically-qualified-workforce - Type 1

        //{fld :'178940201', delta : '192836152', level : '1595031429', score : '597424511', year : '1220942342'},//competitor-ranking
        //{fld :'581589315', delta : '604590491', level : '523908082', score : '449147858', year : '1563577357'} //share-of-business
    ]; //Strategy Construct


    computedFields[7]['type2'] = []; //Strategy Construct

    computedFields[7]['type3'] = [
        {fld :'1575027710', delta : '902431505', level : '702230085', score : '969069525', year : '1377553229'},//regulatory-compliances - Type 3
        {fld :'1183151752', delta : '292447904', level : '812824651', score : '1444389962', year : '289485927'},//internal/external-audits - Type 3
    ]; //Strategy Construct


    /*******************************************Sustenance construct*****************************************/


    computedFields[8]['type1'] = [{fld :'1563544782', delta : '753969471', level : '1336380772', score : '80871742', year : '421350620'},//ledership-level-driven-projects - Type 1
        {fld :'117348481', delta : '1171027412', level : '1053230502', score : '1385150629', year : '533409001'},//social-impacts - Type 1
        {fld :'529778124', delta : '836559073', level : '1339642804', score : '861690684', year : '711794832'},//staff-involvement - Type 1
        {fld :'195183708', delta : '616848434', level : '427751597', score : '349121827', year : '592294403'},//waste-management - Type 1
        {fld :'631284093', delta : '61736355', level : '859687062', score : '1441386202', year : '1110560383'},//energy-saved - Type 1
        {fld :'1377566965', delta : '1271622558', level : '308608162', score : '1178703693', year : '331937259'}//csr-contribution - Type 1
    ]; //Sustenance Construct

    computedFields[8]['type2'] = []; //Sustenance Construct

    computedFields[8]['type3'] = []; //Sustenance Construct





    enablerFields[1] = ['1001850014','639281288', '633620729', '996716181', '137840768', '132599270', '1027594100'];
    enablerFields[2] = ['870333434', '359328626', '1389457630', '115057024', '224934054', '699668465'];
    enablerFields[3] = ['1029116094','61863253','55479857','161819632','549118892','388184864','815185168','363453412','993437419'];
    enablerFields[4] = ['939185636','1115503130','850296300','1400266067','559816483','119142740','1551951580','477723654','1171513167','1518210145','940849661','791943287'];
    enablerFields[5] = ['1266601414','236706313','1398078865','1400788393','1450684970'];
    enablerFields[6] = ['701846475','1033190090','1295927441','1194683913','1593796777','891113524','534389381','824139924'];//leadership construct
    enablerFields[7] = ['992072203','1469853463','1490286421','1358764356','457011500','1277898368','1277898368','1303299432','279039869','577755495'];
    enablerFields[8] = ['774794671','388513952','1525403847','1120089208'];

    scoreFields[1] = "864960625"; ratingFields[1] = "517866537"; //customer consrtuct
    scoreFields[2] = "1410378341"; ratingFields[2] = "1268533046"; //finance construct
    scoreFields[3] = "833803894"; ratingFields[3] = "198881859"; //human resource constrcuct
    scoreFields[4] = "1533969288"; ratingFields[4] = "145283384"; //operations constrcut
    scoreFields[5] = "1310636452"; ratingFields[5] = "1123064955"; //supply chain constrcut
    scoreFields[6] = "10913537"; ratingFields[6] = "795511160"; //leadership construct
    scoreFields[7] = "596803241"; ratingFields[7] = "800223850"; //strategy construct
    scoreFields[8] = "514447822"; ratingFields[8] = "1442010206"; //sustenance rating

    let prev_form_page = 0;
    for(var i=1; i< formPages.length; i++) {
        let totalFields = 0;
        let totalScore = 0;
        let page = formPages[i];
        let components = formPages[i].components;
        let fields = [];
        let sortedFields = [];
        for(var q=1; q<=3; q++) {

            fields = computedFields[i]['type'+q];
            for(let j=0; j< fields.length; j++){

                let year_fieldArr = components.filter(item => item.masterid === fields[j].year);

                if(year_fieldArr.length > 0 && year_fieldArr[0].value && year_fieldArr[0].value.length > 0) {
                    let this_fieldArr = components.filter(item => item.masterid === fields[j].fld || item.referenceId === fields[j].fld); //computed fieldlist
                    let avg_percentage = 0;
                    let level = 0;
                    let score = 0;
                    if(this_fieldArr.length > 0) {

                        if(this_fieldArr.length === 1) {
                            level =0;
                            score = 0;
                        }
                        else{

                            let withinRange = 0;
                            for(let k=0; k< this_fieldArr.length; k++) {

                                let this_field = this_fieldArr[k];

                                let percentage = this_field.rawvalue ? this_field.rawvalue : this_field.value;
                               /* console.log(this_field)
                                console.log(percentage)*/
                                let this_year_val = getThisYearVal(this_field, components, fields, j);
                                sortedFields[this_year_val] = this_field;

                                if(parseFloat(percentage) >parseFloat(fields[j].min) &&  parseFloat(percentage) < parseFloat(fields[j].max)) {
                                    withinRange = withinRange + 1
                                }
                            }


                            if(q===1 || q===3) {
                                let diff_percentage = 0;
                                for(let k=0; k< sortedFields.length-1; k++) {
                                    let this_field = sortedFields[k];
                                    if(this_field) {
                                        let percentage = this_field.rawvalue ? this_field.rawvalue : this_field.value;
                                        let prev_percentage = sortedFields[k+1] ? ( sortedFields[k+1].rawvalue ? sortedFields[k+1].rawvalue : sortedFields[k+1].value) : 0;
                                        let diffval = (parseFloat(percentage) - parseFloat(prev_percentage))/parseFloat(prev_percentage)*100;

                                        //diffval = diffval.toFixed(2);

                                        diff_percentage += parseFloat(diffval);

                                    }

                                }
                                //return false;
                                avg_percentage = diff_percentage/(sortedFields.length-1);
                                //14-01-2021 Rounding of avg added as per discussion with team.
                                //avg_percentage = avg_percentage.toFixed(2)

                                //console.log(avg_percentage)

                                if(q===1) {
                                    if(avg_percentage < max1) {
                                        level =1;
                                        score =1;
                                    }
                                    else if(avg_percentage > min2 && avg_percentage < max2) {
                                        level =2;
                                        score =5;
                                    }
                                    else {
                                        level =3;
                                        score =10;
                                    }
                                }
                                else if(q===3) {
                                    if(avg_percentage > max2) {
                                        level =1;
                                        score =1;
                                    }
                                    else if(avg_percentage > min2 && avg_percentage < max2) {
                                        level =2;
                                        score =5;
                                    }
                                    else {
                                        level =3;
                                        score =10;
                                    }
                                }
                            }
                            else if(q===2) {
                                if(withinRange === 3) {
                                    level =3;
                                    score =10;
                                }
                                else if(withinRange === 2) {
                                    level = 2;
                                    score = 5;
                                }
                                else {
                                    level =1;
                                    score =1;
                                }
                            }

                        }

                        if(isNaN(avg_percentage) || parseFloat(avg_percentage) === Infinity ){
                            level = 0;
                            score = 0
                        }

                        components = components.map((item,index) => {

                            if(item.masterid === fields[j].delta) {
                                avg_percentage = avg_percentage.toFixed(2);// commented as per discussion 14-01-2021
                                //console.log(avg_percentage)
                                item.value = (q===2) ? '' :avg_percentage.toString()
                            }
                            if(item.masterid === fields[j].level) {
                                item.value = level.toString()
                            }
                            if(item.masterid === fields[j].score) {
                                item.value = score.toString();
                                totalFields = totalFields+1;
                                totalScore += parseFloat(score);

                                if(prev_form_page !== i ) {
                                    let radioFields = enablerFields[i];
                                    for(let t =0; t<radioFields.length; t++) {
                                        components.map((comp,ind) => {
                                            if(comp.masterid === radioFields[t]) {
                                                if(comp.value && comp.value.length >0 &&comp.value[0].weightage && comp.value[0].weightage > 0) {
                                                    totalFields = totalFields +1;
                                                    totalScore += parseFloat(comp.value[0].weightage);

                                                }
                                            }
                                        })
                                    }
                                    prev_form_page = i;
                                }
                            }
                            if(item.masterid === scoreFields[i]) {
                                item.value = totalScore.toString();
                            }
                            if(item.masterid === ratingFields[i]) {
                                let avg = parseFloat(totalScore/totalFields);
                                let avgFixed =  avg.toFixed(2)
                                item.value = avgFixed.toString();
                            }
                            return item;

                        }); //diff percentage
                    }
                }
            }

        }

        formPages[i].components = components;
    }

    return formPages;
}
function getThisYearVal(this_field, components,fields, j) {
    let parentid = this_field.parentid;
    let year_val = 0;
    let yearfield_Arr = components.filter(item => item.parentid === parentid && (item.masterid === fields[j].year || item.referenceId === fields[j].year));
    if(yearfield_Arr.length > 0) {
        let yearfield = yearfield_Arr[0];
        let year_value = (yearfield.value && yearfield.value.length>0 )?yearfield.value[0].value :'';
        let value_arr = year_value.split(",");
        if(value_arr.length >0 ) {
            //console.log(value_arr[0])
            year_val = parseInt(value_arr[0]) > 0 ? parseInt(value_arr[0]) : 0;
            year_val = (year_val=== 'NaN' || year_val === NaN) ? 0 : year_val;
            //console.log(year_val)
        }
    }
    return year_val;
}

export function getThankyouPage(formcomponents, thankyou_settings) { // Thank you page according to settings
    //console.log(thankyou_settings)
    if(thankyou_settings){
        for(var i=0; i< thankyou_settings.length; i++) {
            //console.log(i)
            let fieldItem = thankyou_settings[i].fieldItem;
            //console.log(fieldItem.length)
            if(fieldItem.length > 0) {
                let conditionSatisfied = 0;
                for(var j=0; j< fieldItem.length; j++) {
                    let field = fieldItem[j].field;
                    let field_setting_arr = fieldItem[j].field_setting;
                    if(field_setting_arr.length > 0) {
                        let field_setting = field_setting_arr[0];
                        let trigger = field_setting.trigger;
                        let matching_value = field_setting.value;
                        let formFld = formcomponents["id"+field];
                        let formFieldValue= '';
                        if(formFld) {
                            if(formFld.value && formFld.value.length > 0) {
                                formFieldValue = formFld.value[0].value;
                                switch (trigger) {
                                    case 'equal' :
                                        if(formFieldValue === matching_value) {
                                            conditionSatisfied++;
                                        }
                                        break;
                                    case 'contain' :
                                        if(formFieldValue.includes(matching_value)) {
                                            conditionSatisfied++;
                                        }
                                        break;
                                    case 'greater' :
                                        if(parseFloat(formFieldValue) > parseFloat(matching_value)) {
                                            conditionSatisfied++;
                                        }
                                        break;
                                    case 'greatequal' :
                                        if(parseFloat(formFieldValue) >= parseFloat(matching_value)) {
                                            conditionSatisfied++;
                                        }
                                        break;
                                    case 'less' :
                                        if(parseFloat(formFieldValue) < parseFloat(matching_value)) {
                                            conditionSatisfied++;
                                        }
                                        break;
                                    case 'lessequal' :
                                        if(parseFloat(formFieldValue) <= parseFloat(matching_value)) {
                                            conditionSatisfied++;
                                        }
                                        break;
                                }
                            }
                        }
                    }
                }
                //console.log(conditionSatisfied)
                if(conditionSatisfied === fieldItem.length) {
                    return thankyou_settings[i];
                }

            }

        }
    }
    return false;
}