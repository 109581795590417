import React, {Component} from 'react';
import LoaderSvg from "../../views/LoaderSVG";
import {getProfile} from "../../../utilities/AuthService";

class SelectFieldsModal extends Component {

    constructor(props) {
        super(props);
        this.state={
            selectedFields:[]
                }
            this.selectFields = this.selectFields.bind(this);
        this.saveFieds = this.saveFieds.bind(this);
    }

    selectFields(fieldid,index){
        let selectedFields = this.props.selectedFields;

        if(selectedFields) {
            if(selectedFields.includes(fieldid)) {
                var index = selectedFields.indexOf(fieldid)
                selectedFields.splice(index, 1);
            }
            else{
                selectedFields.push(fieldid);
            }
        }
        this.setState({selectedFields: selectedFields})

    }

    saveFieds(){
        let fields = this.state.selectedFields;
        console.log(fields);
        this.props.getselectedFields(fields);
        this.props.showFieldsModal('close')

    }
    render() {
    let formInfo = this.props.formInfo;
        return (

            <div className="modal fade bsy-modal lg-mod show no-pad-mod" id="chooseinherit" tabIndex="-1" role="dialog"
                 aria-labelledby="mngModal" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        {this.props.loaderdata &&
                        <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                        }
                        <div className="modal-header">
                            <h5 className="modal-title">Choose Form Fields</h5>
                            <button className="ico-lib-list modal-close" data-dismiss="modal"
                                    aria-label="Close"  onClick={()=> this.props.showFieldsModal('close')}></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-container">
                                <div className="form-field-wrapper-block">
                                    {formInfo && formInfo.map(page=>page.components.map((item,index)=>{
                                        return(
                                        <div className="ind-form-field-block" key={"formfield-"+index}>
                                            <div className="select-chk-block" id="collection_menu" style={{display: "block"}}>
                                                <label className="control control-checkbox">{item.label}<input type="checkbox" className="inp-chk"
                                                    defaultChecked={this.props.selectedFields.includes(item.id)} value="general" name="filter_cat" onClick={(e)=>this.selectFields(item.id, index)} />
                                                    <div className="control-indicator"></div>
                                                </label>
                                            </div>
                                        </div>
                                        )}
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="general-btn grey" data-dismiss="modal" onClick={()=> this.props.showFieldsModal('close')}>Cancel</button>
                            <button type="button" className="general-btn" data-dismiss="modal" onClick={this.saveFieds}>Save</button>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

class CreateCategoryModal extends Component {

    constructor(props) {
        super(props);
        this.state={
            categoryname:''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addCategory = this.addCategory.bind(this);
    }

    handleInputChange(e){
        this.setState({[e.target.name]: e.target.value});
        if (e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }

    addCategory(){

        let formValid = 0;

        if (this.state.categoryname === '') {
            formValid++;
            document.getElementsByName('categoryname')[0].classList.add('val-error');
        }

        if(formValid === 0) {
            this.props.createCategory({
                "category": this.state.categoryname,
            });
            this.props.showCreateCategoryModal();
        }
    }
    render() {
        return (

            <div className="modal fade bsy-modal x-large show" id="addcategory" tabIndex="-1" role="dialog"
                 aria-labelledby="mngModal" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title ico-lib ">Add category</h5>
                            <button className="ico-lib-list modal-close" data-dismiss="modal"
                                    aria-label="Close" onClick={()=>this.props.showCreateCategoryModal()}></button>
                        </div>
                        <div className="modal-body">
                            <div className="mang-lbl-block">
                                <div className="lbl-panel-search-block">
                                    <input type="text" name="categoryname" placeholder="Add category" className="lbl-search-box" onChange={this.handleInputChange}/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer checklist-footer">
                            <button type="button" className="general-btn grey" data-dismiss="modal" onClick={()=>this.props.showCreateCategoryModal()}>Cancel</button>
                            <button type="button" className="general-btn" id="addC" onClick={this.addCategory}>Add</button>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export  {SelectFieldsModal,CreateCategoryModal};