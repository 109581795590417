import {combineReducers} from 'redux';

const listCollectionsdata = (state = {Collections: {data: []}, Group: '', Search: ''}, action) => {
    switch (action.type) {

        case "LIST_All_COLLECTIONS":
            return {
                ...state,
                Collections: {
                    data: action.listallcollections
                },
                Group: action.group,
                Search: action.search

            };
        default:
            return state;
    }
};

const listSelectedCollections = (state = {SelectedCollections: {results: [], data: [], values: []}}, action) => {

    switch (action.type) {

        case "LIST_SELECTED_COLLECTIONS":
            return {
                ...state,
                SelectedCollections: action.selectedcollections.data.results
            };

        default:
            return state;
    }
};
const csvImportData = (state = {}, action) => {

    switch (action.type) {

        case "IMPORT_CSV_UPDATE":
            return {
                ...state,
                csvstatus: action.csvstatus

            };

        default:
            return state;
    }
};
const importCSVData = (state = {csvData: {data: []}, csvCode: '', csvMsg: ''}, action) => {

    switch (action.type) {

        case "IMPORT_CSV_GET":
            return {
                ...state,
                csvData: action.importCSVData,
                csvCode: action.importCSVData.code ? action.importCSVData.code : '',
                csvMsg: action.importCSVData.message ? action.importCSVData.message : '',

            };

        default:
            return state;
    }
};
const excelImportData = (state = {}, action) => {

    switch (action.type) {

        case "IMPORT_EXCEL_UPDATE":
            return {
                ...state,
                excelstatus: action.excelstatus
            };
            break;

        default:
            return state;
    }
};

const listCollectionDetaildata = (state = {
    facet: [],
    fieldview: [],
    showlist: [],
    flaglist: [],
    flagCategory: [],
    has_annotation: false,
    customtemplate_other: [],
    mobiletemplate_other: [],
    mobiletemplate_settings: [],
    totalchilds: 0,
    collData: [],
    collLabel: [],
    CollectionInfo: [],
    CollectionData: [],
    CollectionLabel: [],
    CollectionFilter: [],
    dashboard: [],
    workflow_filter: []
}, action) => {
    var _newstate;
    switch (action.type) {
        case "LIST_COLLECTIONDETAIL":
            var info = {"title": "", "description": "", "createuser": ''};
            var labels = [];
            var labelsNew = []
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var collLabel = []
            var cnt = 0;
            var indx = 0;
            var results = action.listCollectionDetail.data.results;
            var facet = action.listCollectionDetail.data.results.facet || []
            var flaglist = action.listCollectionDetail.data.results.flaglist
            var flagCategory = action.listCollectionDetail.data.results.category
            var totalchilds = action.listCollectionDetail.data.results.totalchilds
            var dashboard = action.listCollectionDetail.data.results.dashboard
            var horizontal_template = action.listCollectionDetail.data.results.horizontal_template
            var vertical_template = action.listCollectionDetail.data.results.vertical_template
            var mini_template = action.listCollectionDetail.data.results.mini_template
            var long1template = action.listCollectionDetail.data.results.long1_temp || []
            var kanbantemplate = action.listCollectionDetail.data.results.kanban_template || []
            var kanbanlist = action.listCollectionDetail.data.results.kanbanlist || []
            var horizontalImage = action.listCollectionDetail.data.results.horizontalImage
            var verticalImage = action.listCollectionDetail.data.results.verticalImage
            var miniImage = action.listCollectionDetail.data.results.miniImage
            var long1Image = action.listCollectionDetail.data.results.long1Image
            var mapTemplate = action.listCollectionDetail.data.results.map_template
            var customtemplate = action.listCollectionDetail.data.results.customtemplate
            var customtemplate_other = action.listCollectionDetail.data.results.customtemplate_other
            var mobiletemplate = action.listCollectionDetail.data.results.mobiletemplate
            var mobiletemplate_other = action.listCollectionDetail.data.results.mobiletemplate_other
            var mobiletemplate_settings = action.listCollectionDetail.data.results.mobiletemplate_settings
            var showlist = action.listCollectionDetail.data.results.showlist
            var fieldview = action.listCollectionDetail.data.results.fieldview
            var workflow_filter = action.listCollectionDetail.data.results.workflow_filter

            if (results.parentinfo[0] !== undefined) {
                info = results.parentinfo[0];
            }

            // if(action.listdata) {
            //
            //     if (results.data[0] !== undefined && results.data[0].collectedform) {
            //         labels = results.data[0].collectedform.map(a => a.label);
            //     }
            //     if (results.data[0] !== undefined && results.data[0].collectedform) {
            //         labelsNew.push(results.data[0].collectedform[0]);
            //     }
            // }

            if (results.data.length > 0) {
                results.data.map((entry, index) => {
                    collectionData[index] = {};
                    collData[index] = {}
                    collectionData[index]['id'] = entry.id;
                    collectionData[index]['isStar'] = entry.isStar;
                    collectionData[index]['has_annotation'] = entry.has_annotation;
                    collectionData[index]['users'] = entry.users;
                    collectionData[index]['flags'] = entry.flags;
                    collectionData[index]['createdate'] = entry.createdate;
                    collectionData[index]['createuser'] = entry.createuser;
                    collectionData[index]['editPermissionusers'] = entry.editPermissionusers;
                    collectionData[index]['editassignedrecords'] = entry.editassignedrecords;
                    collectionData[index]['editgroup'] = entry.editgroup;
                    collectionData[index]['editownrecords'] = entry.editownrecords;
                    collectionData[index]['editrecordpermission'] = entry.editrecordpermission;
                    collectionIDs.push(entry.id);
                    // if(action.listdata) {
                    //     collData[index] = (entry.collectedform[0])
                    //     collData[index]['id'] = entry.id;
                    //     collData[index]['users'] = entry.users;
                    // }


                    if (entry.collectedform) {
                        entry.collectedform.map(a => {
                            //.filter((a) => a.showlist == 'Yes')
                            // if(!action.listdata) {
                            collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['value'] : '';
                            if (a.type === "attachment") {
                                collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                            }

                            if (a.searchable === 'Yes') {
                                if (collectionFilter[a.label] === undefined) {
                                    collectionFilter[a.label] = [];
                                }
                                cnt = collectionFilter[a.label].length;
                                collectionFilter[a.label][cnt] = a.value[0]['value'];
                            }
                            // }
                        });
                    }
                });
            }
            _newstate = {...state};
            return {
                ...state,
                CollectionInfo: info,
                CollectionLabel: labels.slice(0, 5),
                CollectionData: collectionData,
                //  collData : collData,
                //    collLabel:labelsNew,
                CollectionFilter: collectionFilter,
                collectionIDs: collectionIDs,
                totalchilds: totalchilds,
                dashboard: dashboard,
                horizontaltemplate: horizontal_template,
                verticaltemplate: vertical_template,
                minitemplate: mini_template,
                long1template: long1template,
                kanbantemplate: kanbantemplate,
                kanbanlist: kanbanlist,
                verticalImage: verticalImage,
                miniImage: miniImage,
                long1Image: long1Image,
                horizontalImage: horizontalImage,
                maptemplate: mapTemplate,
                facet: facet.length !== 0 ? facet : _newstate["facet"],
                customtemplate: customtemplate,
                customtemplate_other: customtemplate_other,
                mobiletemplate: mobiletemplate,
                mobiletemplate_other: mobiletemplate_other,
                mobiletemplate_settings: mobiletemplate_settings,
                flaglist: flaglist,
                fieldview: fieldview.length !== 0 ? fieldview[0].fields : [],
                showlist: showlist,
                flagCategory: flagCategory,
                workflow_filter: workflow_filter

            };

            break;
        case "LOADMORE_COLLECTIONDETAIL":
            var info = {"title": "", "description": ""};
            var labels = [];
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var collLabel = []
            var cnt = 0;
            var indx = 0;
            var results = action.listCollectionDetail.data.results;
            var totalchilds = action.listCollectionDetail.data.results.totalchilds
            if (results.parentinfo[0] !== undefined) {
                info = results.parentinfo[0];
            }
            //let result = results.data[0].collectedform.map(a => a.label);
            if (results.data[0] !== undefined && results.data[0].collectedform) {
                labels = results.data[0].collectedform.map(a => a.label);
                // filter((a) => a.showlist == 'Yes')
            }

            if (results.data.length > 0) {
                results.data.map((entry, index) => {
                    collectionData[index] = {};
                    collData[index] = {}
                    collectionData[index]['id'] = entry.id;
                    collectionData[index]['users'] = entry.users;
                    collectionData[index]['isStar'] = entry.isStar;
                    collectionData[index]['has_annotation'] = entry.has_annotation;
                    collectionData[index]['flags'] = entry.flags;
                    collectionData[index]['createdate'] = entry.createdate;
                    collectionData[index]['createuser'] = entry.createuser;
                    collectionData[index]['editPermissionusers'] = entry.editPermissionusers;
                    collectionData[index]['editassignedrecords'] = entry.editassignedrecords;
                    collectionData[index]['editgroup'] = entry.editgroup;
                    collectionData[index]['editownrecords'] = entry.editownrecords;
                    collectionData[index]['editrecordpermission'] = entry.editrecordpermission;
                    collectionIDs.push(entry.id);
                    // collData[index]=(entry.collectedform[0])
                    // collData[index]['id'] = entry.id;
                    // collData[index]['users'] = entry.users;

                    if (entry.collectedform) {
                        entry.collectedform.map(a => {
                            // //.filter((a) => a.showlist == 'Yes')
                            // if(a.type==="attachment"){
                            // //     collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['value'] : '';
                            // //     collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                            // //
                            //     collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                            //     collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                            //
                            // }
                            // // else{
                            // //     collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['value'] : '';
                            // //
                            // // }
                            //
                            // if (a.searchable === 'Yes') {
                            //     if (collectionFilter[a.label] === undefined) {
                            //         collectionFilter[a.label] = [];
                            //     }
                            //     cnt = collectionFilter[a.label].length;
                            //     collectionFilter[a.label][cnt] = a.value[0]['value'];
                            // }

                            collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['value'] : '';
                            if (a.type === "attachment") {
                                collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                            }

                            if (a.searchable === 'Yes') {
                                if (collectionFilter[a.label] === undefined) {
                                    collectionFilter[a.label] = [];
                                }
                                cnt = collectionFilter[a.label].length;
                                collectionFilter[a.label][cnt] = a.value[0]['value'];
                            }

                        });
                    }
                });
            }

            _newstate = {...state};
            _newstate['CollectionInfo'] = info;
            _newstate['CollectionLabel'] = labels.slice(0, 5);
            _newstate['CollectionData'] = _newstate.CollectionData.concat(collectionData);
            //   _newstate['collData']= _newstate.collData.concat(collData);
            _newstate['CollectionFilter'] = collectionFilter;
            _newstate['collectionIDs'] = _newstate.collectionIDs.concat(collectionIDs);
            _newstate['totalchilds'] = totalchilds;
            _newstate['dashboard'] = action.listCollectionDetail.data.results.dashboard

            return _newstate

            break;
        case "COLLECTION_DETAIL_MAP":
            _newstate = {...state};
            var info = {"title": "", "description": ""};
            var labels = [];
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var collLabel = []
            var cnt = 0;
            var indx = 0;
            var results = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.data : []
            var totalchilds = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.count : 0
            var scrollid = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.scrollid : ""
            var facet = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.facet : _newstate['facet']
            results.map((entry, index) => {
                collData[index] = {}
                collData[index] = entry
            });
            _newstate['scrollid'] = scrollid;
            _newstate['CollectionLabel'] = labels.slice(0, 5);
            _newstate['CollectionData'] = _newstate.CollectionData.concat(collectionData);
            _newstate['collData'] = collData;
            _newstate['CollectionFilter'] = collectionFilter;
            _newstate['collectionIDs'] = _newstate.collectionIDs.concat(collectionIDs);
            _newstate['totalchilds'] = totalchilds;
            _newstate['facet'] = facet;

            return _newstate

            break;
        case "LOAD_MORE_COLLECTION_DETAIL_MAP":
            var info = {"title": "", "description": ""};
            var labels = [];
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var collLabel = []
            var cnt = 0;
            var indx = 0;
            var results = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.data : []
            var totalchilds = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.count : 0
            var scrollid = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.scrollid : ""

            results.map((entry, index) => {
                collData[index] = {}
                collData[index] = entry
            });

            _newstate = {...state};
            //   _newstate['CollectionInfo']= _newstate.info;
            _newstate['scrollid'] = scrollid;
            _newstate['CollectionLabel'] = labels.slice(0, 5);
            _newstate['CollectionData'] = _newstate.CollectionData.concat(collectionData);
            _newstate['collData'] = _newstate.collData.concat(collData);
            _newstate['CollectionFilter'] = collectionFilter;
            _newstate['collectionIDs'] = _newstate.collectionIDs.concat(collectionIDs);
            _newstate['totalchilds'] = totalchilds;
            return _newstate

            break;
        case "LIST_VIEW_COLLECTION_LIMIT":

            _newstate = {...state};
            _newstate['CollectionInfo'] = _newstate['CollectionInfo'];
            _newstate['CollectionLabel'] = _newstate['CollectionLabel'].slice(0, 100);
            _newstate['CollectionData'] = _newstate.CollectionData.slice(0, 100);
            //   _newstate['collData']= _newstate.collData.concat(collData);
            _newstate['CollectionFilter'] = _newstate['CollectionFilter'];
            _newstate['collectionIDs'] = _newstate.collectionIDs;
            _newstate['totalchilds'] = _newstate['totalchilds'];
            _newstate['dashboard'] = _newstate['dashboard']

            return _newstate

            break;
        case "RESET_COLLECTION_DATA":
            return {
                ...state,
                facet: [],
                shownlist: [],
                totalchilds: 0,
                CollectionInfo: [],
                collData: [],
                collLabel: [],
                CollectionData: [],
                CollectionLabel: [],
                CollectionFilter: [],
                dashboard: []
            };
            break;

        default:
            return state;
    }
};
const listCollectionFilterData = (state = {CollectionFilter: []}, action) => {
    switch (action.type) {
        case "LIST_COLLECTIONFILTER":
            var collectionFilter = [];
            var cnt = 0;
            var results = action.listCollectionFilter.data.results;

            if (results.data.length > 0) {
                results.data.map((entry, index) => {
                    if (entry.collectedform) {
                        entry.collectedform.filter((a) => a.showlist == 'Yes').map(a => {
                            if (a.searchable === 'Yes') {
                                if (collectionFilter[a.label] === undefined) {
                                    collectionFilter[a.label] = [];
                                }
                                cnt = collectionFilter[a.label].length;
                                if (collectionFilter[a.label].indexOf(a.value[0]['value']) <= -1) {
                                    collectionFilter[a.label][cnt] = a.value[0]['value'];
                                }
                            }
                        });
                    }
                });
            }

            return {
                ...state,
                CollectionFilter: collectionFilter,
            };

            break;


        default:
            return state;
    }
};
const saveMetaForm = (state = {metaformdata: [], embedformsaved: false, loading: false}, action) => {
    switch (action.type) {
        case "LIST_COLLECTIONDETAIL":
            return {
                ...state,
                metaformdata: action.metaformdata,
            };
            break;
        case "SAVE_FORM_LOADING":
            return {
                ...state,
                loading: action.loading
            };
            break;
        case "SAVE_METAFORM":
            return {
                ...state,
                metaformdata: action.metaformdata
            };
            break;
        case "UPDATE_EMBED_FORM":
            return {
                ...state,
                embedformsaved: !state.embedformsaved
            };
            break;
        default:
            return state;
    }
};
const showFormModal = (state = {
    showformdata: [],
    template: 1,
    templateInfo: [],
    pagelock: '',
    saveasdraft: 'no',
    status: "",
    width: 960,
    thankyoustatus: "",
    thankyoucontent: "",
    thankyoulink: "",
    thankyouimgcontent: "",
    profileimgcontent: "",
    header: [],
    footer: [],
    auth: "no",
    collaborator: true,
    getformcomponents: [],
    computedfield: [],
    jsenabled: '',
    buttonlist: [],
    rolesettings: [],
    quickform: false,
    thankyou_settings: [],
    showlist_enabled:[]
}, action) => {
    switch (action.type) {
        case "SHOW_METAFORM":
            return {
                ...state,
                showformdata: action.showformdata,
                template: action.template,
                templateInfo: action.templateInfo,
                pagelock: action.pagelock,
                width: action.width,
                status: action.status,
                thankyoustatus: action.thankyoustatus,
                thankyoulink: action.thankyoulink,
                thankyoucontent: action.thankyoucontent,
                thankyoulinkfield: action.thankyoulinkfield,
                thankyouimgcontent: action.thankyouimgcontent,
                profileimgcontent: action.profileimgcontent,
                header: action.header,
                footer: action.footer,
                auth: action.auth,
                collaborator: action.collaborator,
                jsenabled: action.jsenabled,
                saveasdraft: action.saveasdraft,
                buttonlist: action.buttonlist,
                rolesettings: action.rolesettings,
                master_published: action.master_published,
                quickform: action.quickform,
                thankyou_settings: action.thankyou_settings,
                showlist_enabled:action.showlist_enabled
            };
            break;

        case "GET_METAFORM" :
            return {
                ...state,
                getformcomponents: action.getformdata
            }
            break;
        case "GET_AGGREGATE_DATA" :
            return {
                ...state,
                aggData: action.aggData,
                refparams: action.refparams,
                computedfield: action.computedfield
            }
            break;
        default:
            return state;
    }
};

const getAuthenticateForm = (state = {authSuccess: false}, action) => {
    switch (action.type) {

        case "FORM_AUTH_DATA" :
            return {
                ...state,
                authSuccess: action.authSuccess
            }
            break;
        default:
            return state;
    }
};

const getExternalForm = (state = {externalFields: {"values": [], "masterField": ''}}, action) => {
    switch (action.type) {
        case "GET_EXTERNAL_FORM_DATA" :
            return {
                ...state,
                externalFields: {values: action.formData, masterField: action.masterid},
            }

        default:
            return state;
    }
};


const getDatabindingForm = (state = {databindingFields: [], masterid: ''}, action) => {
    switch (action.type) {
        case "GET_DATA_BINDING_FORM_DATA" :
            return {
                ...state,
                databindingFields: action.bindingData,
                masterid: action.masterid
            }

        default:
            return state;
    }
};

const getCustomApiData = (state = {fieldId: ''}, action) => {

    switch (action.type) {
        case "GET_CUSTOM_API_DATA" :
            return {
                ...state,
                [action.fieldId + "_customApiData"]: action[action.fieldId + "_customApiData"],
                fieldId: action.fieldId
            }

        default:
            return state;
    }
};

const getSavedForm = (state = {
    formSaved: {},
    isFormChanged: false,
    formSaveError: false,
    formSavedRes: {},
    draftSaved: '',
    draftId: '',
    errorMessage: '',
    errorCode: ''
}, action) => {
    switch (action.type) {
        case "SAVE_FORM_DATA" :
            return {
                ...state,
                formSaved: action.formSaved,
                isFormChanged: !state.isFormChanged,
                formSavedRes: action.formSavedRes
            }
        case "SAVE_FORM_DRAFT" :
            return {
                ...state,
                draftSaved: action.draftSaved,
                draftId: action.draftId
            }
        case "ERROR_FORM_SAVING" :
            return {
                ...state,
                formSaveError: true,
                errorMessage: action.errorMessage,
                errorCode: action.errorCode,
                formSaveStatus: action.formSaveStatus
            }

        default:
            return state;
    }
};

const getLinkedSavedForm = (state = {
    lnkedformSaved: {},
    linkisFormChanged: false,
    linkformSaveError: false
}, action) => {
    switch (action.type) {
        case "SAVE_LINKED_FORM_DATA" :
            return {
                ...state,
                formSaved: action.lnkedformSaved,
                linkisFormChanged: !state.linkisFormChanged
            }
        case "ERROR_LINKED_FORM_SAVING" :
            return {
                ...state,
                linkformSaveError: true
            }

        default:
            return state;
    }
};

const manageoader = (state = {loading: 0}, action) => {
    switch (action.type) {
        case "CONTENT_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;


        default:
            return state;
    }
};
const sharedCollection = (state = {loading: 0}, action) => {
    switch (action.type) {
        case "SHARE_COLLECTION":
            return {
                ...state,
                shared: action.shared,
                msg: action.msg
            };
            break;


        default:
            return state;
    }
};

const getChangedCollectionData = (state = {collection_data_modified: []}, action) => {

    switch (action.type) {
        case "MODIFY_COLLECTION_RECORDS":
            return {
                ...state,
                collection_data_modified: action.deleteCollectionData
            };
            break;
        default:
            return state;
    }
};

const saveAssigndata = (state = {results: false}, action) => {

    switch (action.type) {
        case "SAVE_ASSIGN_DATA":
            return {
                ...state,
                results: !state.results
            };
            break;
        default:
            return state;
    }
};

const saveSharedata = (state = {results: false}, action) => {

    switch (action.type) {
        case "SAVE_SHARE_DATA":
            return {
                ...state,
                results: !state.results
            };
            break;
        default:
            return state;
    }
};

const listAllCollections = (state = {
    ListAllCollections: {data: [], total: 0},
    searchResult: {data: [], Search: '', total: 0},
    Group: '',
    Search: '',
    usertype: '',
    managecollection: "",
    searchEnable: false,
    collectionsubgroup: [],
    ListAllEntity: []
}, action) => {
    var _newState;
    switch (action.type) {

        case "LIST_COLLECTIONS":
            return {
                ...state,
                ListAllCollections: {
                    data: action.listallcollections.collection,
                    total: action.listallcollections.totalitems
                },
                Group: action.group,
                Search: action.search,


            };
            break;

        case "LIST_TODO_COLLECTIONS":
            return {
                ...state,
                collectionsubgroup: action.collectionsubgroup

            };
            break;
        case "LOAD_MORE_COLLECTIONS":
            _newState = {...state};
            _newState['ListAllCollections'].data = _newState.ListAllCollections.data.concat(action.listallcollections.collection);
            return _newState;
            break;

            break;

        case "SEARCH_COLLECTION_DATA":
            return {
                ...state,
                searchResult: {
                    data: action.listallcollections.data.results.data.collection,
                    total: action.listallcollections.data.results.data.totalitems,
                    Search: action.search
                },
                Group: action.group,
                Search: action.search,
                usertype: action.usertype,
                searchEnable: action.searchEnable,
                managecollection: action.managecollection
            };
            break;


        case "LOAD_MORE_SEARCH_COLLECTIONS":
            _newState = {...state};
            _newState['searchResult'].data = _newState.searchResult.data.concat(action.listallcollections);
            return _newState;
            break;


        // case "UPDATE_COLLECTION":
        //     let ListAllCollections = state.ListAllCollections.data;
        //     ListAllCollections.unshift(action.newCollection);
        //     return {
        //         ...state,
        //         ListAllCollections: {
        //             data : ListAllCollections
        //         },
        //     };
        //     break;

        case "UPDATE_ENTITY":
            return {
                ...state,
                ListAllEntity: action.newEntity
            };
            break;
        case "GET_ENTITY_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};
const saveCollection = (state = {collectionSaved: false}, action) => {
    switch (action.type) {
        case "COLLECTION_CREATED":
            return {
                ...state,
                collectionSaved: !state.collectionSaved
            };
            break;
        default:
            return state;
    }
};
const getFormData = (state = {
    formvalues: [],
    fieldid: '',
    lk_fieldid: '',
    id: "",
    meta: [],
    wfinfo: [],
    wf_meta_users: [],
    versions: [],
    right_panel: [],
    draft: false
}, action) => {
    switch (action.type) {
        case "GET_FORM_DATA":
            return {
                ...state,
                formvalues: action.formvalues,
                meta: action.meta,
                wfinfo: action.wfinfo,
                right_panel: action.right_panel,
                wf_meta_users: action.wf_meta_users,
                versions: action.versions,
                id: action.id,
                draft: action.draft
            };
        case "RESET_FORM_DATA":
            return {
                ...state,
                formvalues: [],
                meta: [],
                wfinfo: [],
                right_panel: [],
                versions: []
            };
        case "RESET_EXTERNALFORM_DATA":
            return {
                ...state,
                formvalues: [],
            };
        case "GET_FORM_DATA_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        case "VALUE_EXISTS" :

            return {
                ...state,
                fieldid: action.fieldid,
                [action.fieldid + "_valueExists"]: action[action.fieldid + "_valueExists"],
                recordid: action.recordid,
                value: action.value
            }
            break;
        case "LK_VALUE_EXISTS" :

            return {
                ...state,
                lk_fieldid: action.lk_fieldid,
                [action.lk_fieldid + "_lk_valueExists"]: action[action.lk_fieldid + '_lk_valueExists'],
                lk_recordid: action.lk_recordid,
                lk_value: action.lk_value
            }
            break;

        case "RESET_VALUE_EXISTS" :

            return {
                ...state,
                [action.fieldid + "_valueExists"]: 'no',
            }
        case "RESET_LK_VALUE_EXISTS" :

            return {
                ...state,
                [action.lk_fieldid + '_lk_valueExists']: 'no',
            }
        case "GET_RECORD_ID_UNIQUEID" :
            return {
                ...state,
                formRecordId: action.formRecordId
            }
        default:
            return state;
    }
};

const collectionSettingsData = (state = {collectionsettings: [], loading: ''}, action) => {
    switch (action.type) {
        case "COLLECTION_SETTINGS":
            return {
                ...state,
                collectionsettings: action.collectionsettings ? action.collectionsettings : state.collectionsettings
            };
            break;
        case "COLLECTION_SETTINGS_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};
const filterDatadetails = (state = {filterData: []}, action) => {
    switch (action.type) {
        case "FORM_FILTER_DATA":
            return {
                ...state,
                filterData: action.filterData.data.results.data
            };
            break;
        default:
            return state;
    }
};

const CategoryList = (state = {categoryList: []}, action) => {
    switch (action.type) {
        case "LIST_CATEGORY":
            return {
                ...state,
                categoryList: action.categoryList.data.data.hits
            };
            break;
        case "GET_CATEGORY_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};
const collectionCreated = (state = {collectionCreated: []}, action) => {
    switch (action.type) {
        case "CREATE_COLLECTION":
            return {
                ...state,
                collectionCreated: action.collectionCreated
            };
            break;

        default:
            return state;
    }
};

const categoryCreated = (state = {categoryCreated: false, params: {}}, action) => {
    switch (action.type) {
        case "CATEGORY_CREATED":
            return {
                ...state,
                categoryCreated: !state.categoryCreated,
                params: action.params
            };
            break;
        default:
            return state;
    }
};
const savelinkingpermission = (state = {savelinkingpermission: false}, action) => {
    switch (action.type) {
        case "SAVE_LINKING_PERMISSIONS":
            return {
                ...state,
                savelinkingpermission: !state.savelinkingpermission
            };
            break;
        default:
            return state;
    }
};
const sendbsynOtp = (state = {bsynOtp: '', data: []}, action) => {
    switch (action.type) {
        case "GET_OTP":
            return {
                ...state,
                bsynOtp: action.BsynOtp,
                data: action.data
            };
            break;
        default:
            return state;
    }
};
const verifybsynotp = (state = {verifyOtp: '', data: []}, action) => {
    switch (action.type) {
        case "VERIFY_OTP":
            return {
                ...state,
                verifyOtp: action.verifyOtp,
                data: action.data
            };
            break;
        default:
            return state;
    }
};
const savadataSettings = (state = {savadatalevelSettings: false}, action) => {
    switch (action.type) {
        case "SAVE_DATA_SETTINGS":
            return {
                ...state,
                savadatalevelSettings: !state.savadatalevelSettings
            };
            break;
        default:
            return state;
    }
};
const saveFilters = (state = {saveFilters: false}, action) => {
    switch (action.type) {
        case "SAVE_DATA_FILTER":
            return {
                ...state,
                saveFilters: !state.saveFilters
            };
            break;
        default:
            return state;
    }
};

const saveRightPanels = (state = {saveRightPanel: false}, action) => {
    switch (action.type) {
        case "SAVE_RIGHT_PANEL":
            return {
                ...state,
                saveRightPanel: !state.saveRightPanel
            };
            break;
        default:
            return state;
    }
};


const exportfield = (state = {data: [], meta: []}, action) => {
    switch (action.type) {
        case "EXPORT_DISPLAY_DATA":
            return {
                ...state,
                data: action.exportfield.data.results.data,
                meta: action.exportfield.data.results.meta
            };
            break;
        default:
            return state;
    }
};

const GetCollectionConfig = (state = {
    getcollectionconfig: {
        dataview: {data: []},
        settings: {data: []},
        propagation: {inheritance: []},
        summary: []
    }, projects: []
}, action) => {
    switch (action.type) {
        case "COLLECTION_CONFIQ":
            if (action.collectionconfig.data.dataview.data !== undefined) {
                return {
                    ...state,
                    getcollectionconfig: action.collectionconfig.data
                };
            } else {
                return {
                    ...state,
                    getcollectionconfig: {dataview: {data: []}, settings: {data: []}, propagation: [], summary: []},
                };

            }


            break;
        default:
            return state;
    }
};

const getCollectionTemplate = (state = {templateData: []}, action) => {
    switch (action.type) {
        case "COLLECTION_SAVE_TEMPLATE":
            return {
                ...state,
                templateData: action.collectionTemplate
            };
            break;
        default:
            return state;
    }
};

const listRule = (state = {generic: [], fieldwise: [], loader: false}, action) => {
    switch (action.type) {
        case "COLLECTION_GET_RULE":
            let ruleData = action.getrule
            let generic = []
            let fieldwise = []
            let i = 0;
            let j = 0;
            ruleData.map((list) => {

                    if (list._source.model === "generic") {
                        generic[i] = list._source
                        generic[i]["id"] = list._id
                        if ((list._source.type === "Weekly" || list._source.type === "Yearly")) {
                            if (list._source.pattern !== "") {
                                //  generic[i]["pattern"] = list._source.pattern.split(",")
                            } else {
                                generic[i]["pattern"] = []
                            }
                        }
                        i++
                    }
                    if (list._source.model === "fieldwise") {
                        fieldwise[j] = list._source
                        fieldwise[j]["id"] = list._id
                        if ((list._source.type === "Weekly" || list._source.type === "Yearly")) {
                            if (list._source.pattern !== "") {
                                //   generic[j]["pattern"] = list._source.pattern.split(",")
                            } else {
                                generic[j]["pattern"] = []
                            }
                        }
                        j++
                    }
                }
            )
            return {
                ...state,
                generic: generic,
                fieldwise: fieldwise
            };
            break;

        case "COLLECTION_RULE_LOADER":
            return {
                ...state,
                loader: action.loader,
            };
            break;
        default:
            return state;
    }
};


const getWorkflow = (state = {workflow: []}, action) => {
    switch (action.type) {
        case "LISTING_WORKFLOW":

            return {
                ...state,
                workflow: action.listingWorkflow,
            };

            break;
        default:
            return state;
    }
};
const getlinkedWorkflow = (state = {workflow: []}, action) => {
    switch (action.type) {
        case "LISTING_LINKED_WORKFLOW":
            return {
                ...state,
                workflow: action.linkedWorkflow,
            };
            break;
        default:
            return state;
    }
};

const getWorkflowStatus = (state = {workflow: [], generic: [], fieldwise: []}, action) => {
    switch (action.type) {
        case "DELETE_WORKFLOW":
            return {
                ...state,
                workflow: action.deletelinkedWorkflow,
            };
            break;
        case "DELETE_GENERIC_RULE":
            return {
                ...state,
                generic: action.deletegenericWorkflow,
            };
            break;
        case "DELETE_FIELDWISE_RULE":
            return {
                ...state,
                fieldwise: action.fieldwiseRuleDelted,
            };
            break;
        case "CREATE_GENERAL_RULE":
            return {
                ...state,
                fieldwise: action.createGenericRule,
            };
            break;
        case "SAVE_WORK_FLOW_RULE":
            return {
                ...state,
                workflow: action.saveWorkflowrule,
            };
            break;


        default:
            return state;
    }
};

const GetEntityDetails = (state = {EntityDetails: []}, action) => {
    switch (action.type) {
        case "ENTITY_DETAILS":
            return {
                ...state,
                EntityDetails: action.entityDetails.data.results.data
            };
            break;
        default:
            return state;
    }
};

const showFormLabels = (state = {showformdata: []}, action) => {
    switch (action.type) {
        case "SHOW_META_FORM_LABELS":
            return {
                ...state,
                showformdata: action.showformdata,

            };
            break;

        default:
            return state;
    }
};


const savePropgationData = (state = {data: []}, action) => {
    switch (action.type) {
        case "SAVE_PROPAGATION_DATA":
            return {
                ...state,
                data: action.savepropagation,

            };
            break;

        default:
            return state;
    }
};

const deletePropgationData = (state = {data: []}, action) => {
    switch (action.type) {
        case "DELETE_PROPAGATION_DATA":
            return {
                ...state,
                data: action.data,

            };
            break;

        default:
            return state;
    }
};
const listPropgationCollections = (state = {linkedentityinfo: []}, action) => {
    switch (action.type) {
        case "LIST_PROPAGATION_COLLECTION_DATA":
            return {
                ...state,
                linkedentityinfo: action.list,

            };
            break;

        default:
            return state;
    }
};


const statusDeletedRecords = (state = {status: []}, action) => {
    switch (action.type) {
        case "DELETE_ALL_RECORDS":
            return {
                ...state,
                status: action.status,

            };
            break;

        default:
            return state;
    }
};

const filterSettingsData = (state = {filterData: []}, action) => {
    switch (action.type) {
        case "FILTER_SETTINGS_DATA":
            return {
                ...state,
                filterData: action.settings,

            };
            break;

        default:
            return state;
    }
};

const saveSettings = (state = {savedata: []}, action) => {
    switch (action.type) {
        case "SAVE_FILTER_SETTINGS_DATA":
            return {
                ...state,
                savedata: action.settings,

            };
            break;

        default:
            return state;
    }
};


const saveTemplate = (state = {savedata: []}, action) => {
    switch (action.type) {
        case "SAVE_TEMPLATE_SETTINGS_DATA":
            return {
                ...state,
                savedata: action.saveData,

            };
            break;

        default:
            return state;
    }
};
const listAnnotation = (state = {listAnn: []}, action) => {
    switch (action.type) {
        case "LIST_ANNOTATION":
            return {
                ...state,
                listAnn: action.listAnn,
                loader: action.loader

            };
            break;

        default:
            return state;
    }
};


const saveAnnotation = (state = {saveAnn: []}, action) => {
    switch (action.type) {
        case "SAVE_ANNOTATION":
            return {
                ...state,
                saveAnn: action.saveAnn,

            };
            break;

        default:
            return state;
    }
};
const listEntityDetails = (state = {EntityList: []}, action) => {
    switch (action.type) {
        case "LIST_SYSTEM_ENTITY_DATA":
            return {
                ...state,
                EntityList: action.entityList
            };
            break;
        default:
            return state;
    }
};
const listEntityCollections = (state = {linkedinfo: []}, action) => {
    switch (action.type) {
        case "LIST_ENTITY_COLLECTION_DATA":
            return {
                ...state,
                linkedinfo: action.list,

            };
            break;

        default:
            return state;
    }
};
const saveEntityMapping = (state = {data: []}, action) => {
    switch (action.type) {
        case "SAVE_ENTITY_DATA":
            return {
                ...state,
                data: action.data,

            };
            break;

        default:
            return state;
    }
};
const deleteEntityData = (state = {data: []}, action) => {
    switch (action.type) {
        case "DELETE_ENTITY_DATA":
            return {
                ...state,
                data: action.data,

            };
            break;

        default:
            return state;
    }
};

const showExternalFormModal = (state = {
    showformdata: [],
    template: 1,
    templateInfo: [],
    pagelock: '',
    status: "",
    width: 960,
    thankyoustatus: "",
    thankyoucontent: "",
    thankyoulink: "",
    thankyouimgcontent: "",
    profileimgcontent: "",
    header: [],
    footer: [],
    auth: "no",
    collaborator: true,
    master_published: false,
    getformcomponents: [],
    computedfield: [],
    rolesettings: [],
    quickform: false,
    thankyou_settings: []
}, action) => {
    switch (action.type) {
        case "SHOW_EXTFORM":
            return {
                ...state,
                showformdata: action.extformdata,
                template: action.template,
                templateInfo: action.templateInfo,
                pagelock: action.pagelock,
                width: action.width,
                status: action.status,
                thankyoustatus: action.thankyoustatus,
                thankyoulink: action.thankyoulink,
                thankyoucontent: action.thankyoucontent,
                thankyoulinkfield: action.thankyoulinkfield,
                thankyouimgcontent: action.thankyouimgcontent,
                profileimgcontent: action.profileimgcontent,
                header: action.header,
                footer: action.footer,
                auth: action.auth,
                collaborator: action.collaborator,
                jsenabled: action.jsenabled,
                saveasdraft: action.saveasdraft,
                buttonlist: action.buttonlist,
                rolesettings: action.rolesettings,
                master_published: action.master_published,
                quickform: action.quickform,
                thankyou_settings: action.thankyou_settings
            };
            break;

        case "EXT_METAFORM" :
            return {
                ...state,
                getformcomponents: action.getformdata
            }
            break;
        case "GET_AGGREGATE_DATA" :
            return {
                ...state,
                aggData: action.aggData,
                refparams: action.refparams,
                computedfield: action.computedfield
            }
            break;
        case "RESET_EXTERNALFORM_DATA":
            return {
                ...state,
                showformdata: []
            };
            break;
        default:
            return state;
    }
};
const saveCalendardata = (state = {results: false}, action) => {

    switch (action.type) {
        case "SAVE_CALENDAR_SET":
            return {
                ...state,
                results: !state.results
            };
            break;
        default:
            return state;
    }
};
const deleteCalendardata = (state = {results: false}, action) => {

    switch (action.type) {
        case "DELETE_CALENDAR_SET":
            return {
                ...state,
                results: !state.results
            };
            break;
        default:
            return state;
    }
};
const listCalendardata = (state = {results: false}, action) => {
    switch (action.type) {
        case "LIST_CALENDAR_SET":
            return {
                ...state,
                listCalendarSettings: action.listCalendarSettings,
            };
            break;
        default:
            return state;
    }
};
const previousVersion = (state = {previousValues: [], loader: false, userInfodata: ''}, action) => {
    switch (action.type) {
        case "TRACK_PREVIOUS_VERSION":
            return {
                ...state,
                previousValues: action.previousList,
                userInfodata: action.userinfo,
            };
            break;
        case "LOADER_UPDATE_STATUS":
            return {
                ...state,
                loader: action.status,
            };
            break;
        default:
            return state;
    }
};
const invokeWorkflowdata = (state = {status: []}, action) => {
    switch (action.type) {
        case "INVOKE_WORKFLOW":
            return {
                ...state,
                status: action.saveInvokeWorkflow.data,
            };
            break;
        default:
            return state;
    }
};
export const Collections = combineReducers({
    listCollectionsdata,
    listCollectionDetaildata,
    listSelectedCollections,
    saveMetaForm,
    showFormModal,
    listCollectionFilterData,
    getChangedCollectionData,
    manageoader,
    sharedCollection,
    getExternalForm,
    getDatabindingForm,
    getSavedForm,
    listAllCollections,
    getFormData,
    CategoryList,
    collectionCreated,
    categoryCreated,
    collectionSettingsData,
    filterDatadetails,
    exportfield,
    GetCollectionConfig,
    saveAssigndata,
    csvImportData,
    excelImportData,
    savelinkingpermission,
    saveFilters,
    saveRightPanels,
    savadataSettings,
    saveCollection,
    getCollectionTemplate,
    saveSharedata,
    listRule,
    getWorkflow,
    getlinkedWorkflow,
    getWorkflowStatus,
    GetEntityDetails,
    showFormLabels,
    savePropgationData,
    deletePropgationData,
    listPropgationCollections,
    statusDeletedRecords,
    filterSettingsData,
    saveSettings,
    saveTemplate,
    sendbsynOtp,
    verifybsynotp,
    listAnnotation,
    saveAnnotation,
    getAuthenticateForm,
    importCSVData,
    listEntityDetails,
    listEntityCollections,
    saveEntityMapping,
    deleteEntityData,
    showExternalFormModal,
    getLinkedSavedForm,
    saveCalendardata,
    deleteCalendardata,
    listCalendardata,
    previousVersion,
    invokeWorkflowdata,
    getCustomApiData
});

export default Collections;


