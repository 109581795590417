import React, {Component} from 'react';
import {Card,Col,Toast,Row,Button,Tooltip,OverlayTrigger} from "react-bootstrap"
import ReactNotifications from 'react-browser-notifications';
import {getProfile} from "../../utilities/AuthService";
import {ES_INDEX,SHARED_LOGIN} from "../../constants";
import notificationSound from "./quite-impressed.mp3";

class ToasterNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        this.showNotifications = this.showNotifications.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }


    componentDidMount() {

    }


    componentWillUpdate(nextProps) {

    }


    componentDidUpdate(prevProps, nextProps, snapshot) {
        if(this.props.show){
            this.showNotifications()
        }
        if (prevProps.toasternotification !== this.props.toasternotification) {
            var audio = new Audio(notificationSound);
            audio.play();
            this.setState({status: true, msg: this.props.toasternotification.notification})
            var that = this
            setTimeout(function () {
                that.props.chatUsers(getProfile().id, ES_INDEX)
            }, 1500)
            setTimeout(function () {
                that.props.allGroups(getProfile().id, 0, 50, ES_INDEX);
            }, 1500);

        }

    }
    componentWillReceiveProps(nextProps, prevProps) {


    }

    showNotifications() {
        // If the Notifications API is supported by the browser
        // then show the notification
        if(this.n.supported()) this.n.show();
    }

    handleClick(event) {
        this.n.close(event.target.tag);
    }


    render() {
        const {show} = this.props;
        if (show) {
            return (
                <div className={`toaster-notification`}>
                    <Row>
                        <Col>
                            <Toast onClose={() => this.props.handleClose(this.props.status)} show={show} delay={3000}
                                   autohide>
                                <Toast.Header>

                                    {this.props.msg.has_attachment !== 'yes' &&
                                    <strong className="mr-auto"  onClick={()=>this.props.loadChatHistory(getProfile().id, this.props.msg.id, 0, 500, ES_INDEX, this.props.msg.type,this.props.msg.type === 'group'?btoa(this.props.msg.toasterdata):this.props.msg.toasterdata,'',this.props.msg.userhead)}>You have a message from {this.props.msg.toasterdata}</strong>}
                                    {this.props.msg.has_attachment === 'yes' &&
                                    <strong className="mr-auto" onClick={()=>this.props.loadChatHistory(getProfile().id, this.props.msg.id, 0, 500, ES_INDEX, this.props.msg.type,this.props.msg.type === 'group'?btoa(this.props.msg.toasterdata):this.props.msg.toasterdata,'',this.props.msg.userhead)}>You have an attachment
                                        from {this.props.msg.toasterdata}</strong>}
                                </Toast.Header>
                            </Toast>
                        </Col>
                    </Row>
                    <ReactNotifications
                        onRef={ref => (this.n = ref)} // Required
                        title="Bsynapse" // Required
                        body={this.props.msg.has_attachment !== 'yes' ?`You have a message from `+ this.props.msg.toasterdata: 'You have an attachment'}
                        icon={SHARED_LOGIN+'/assets/images/min-logo.png'}
                        tag=""
                        timeout="4000"
                        onClick={event => this.handleClick(event)}
                    />

                </div>
            );
        }
        else {
            return null;
        }
    }
}



export default ToasterNotification;


