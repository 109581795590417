import React from "react";
import {Accordion, Card} from "react-bootstrap";
import {removeBackSlashes} from "../../../../../utilities/CustomFunctions";
import {FRMSIMG_PATH} from "../../../../../constants";

class Faq extends  React.Component{
    constructor(props){
        super(props)
        this.state={
            collectionData:[],
            from:0,
            size:300,
            graphStatus:true,
            viewStatus:false
        }
    }
    editRecord(col,masterids,type,mId){
        this.props.getselectedcollections({ _id : mId, collectionid : this.props.collection, metainfo : true});
        this.props.addRecord(col,masterids,type,mId)
    }
    render(){
        let item = this.props.item;
        let faqTitle = this.props.containerData.faqTitle;
        let faqDesc = this.props.containerData.faqDesc;
        let faqImage = this.props.containerData.faqImage;
        let titlecolor = this.props.containerData.titlecolor;
        let desccolor = this.props.containerData.desccolor;
        return(
            <Accordion className="default-accord">
                {item &&
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <div className="helptext-wrapper" >
                            <p style={{color : `${titlecolor}`}} className="font-bold col-black">{item[faqTitle] ?removeBackSlashes(item[faqTitle]) :''}</p>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="helptext-wrapper" >
                                <p style={{color : `${titlecolor}`}}>{item[faqTitle] ? removeBackSlashes(item[faqTitle]) :''}</p>
                            </div>
                            {item[faqDesc] !== '' &&
                            <div className="helptext-wrapper" >
                                <p style={{color : `${desccolor}`}}>{item[faqDesc] ? removeBackSlashes(item[faqDesc]):''}</p>
                            </div>
                            }
                            {item[faqImage] !== '' && item[faqImage] !== undefined &&
                            <div className="helptext-wrapper">
                                <p><img style={{width:'100%', objectFit : 'contain'}} src={FRMSIMG_PATH+ item[faqImage]}/></p>
                            </div>
                            }
                            <div className="help-button-block">
                                <nav title="Copy" className="action-link copy"
                                     onClick={(e) => this.props.copyHelpText(e, item)}>
                                </nav>
                                {/*<nav title="Send" className="action-link send"*/}
                                {/*     onClick={(e) => this.props.sendHelpText(e, item)}>*/}
                                {/*</nav>*/}
                            </div>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                }
            </Accordion>
        )
    }

}

export default Faq;
