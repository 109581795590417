import React from 'react';
import axios from 'axios';
import {FormGroup} from 'react-bootstrap';
import { Draggable, state } from 'react-page-maker';
import { DragDropContext, Droppable, Draggable as Drags } from "react-beautiful-dnd";
import AreaColorMap from "../views/Map/AreaColorMap/AreaColorMap.js";
import SimpleModal from "../../../views/SimpleModal"
import ColorPicker from "../views/ColorPicker";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import LoaderSvg from "../../../views/LoaderSVG";
let graphData= [
  {
    "x": "2018 Q1",
    "y": "500",
    "z": "2018 Q1"
  },
  {
    "x": "2018 Q2",
    "y": "620",
    "z": "2018 Q2"
  }]


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#fff",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "lightblue",
});

class AreaColorMapContainer extends React.Component {
  constructor(props,context) {
    super(props,context);
    this.state={
      modalStatus:false,
      showColorPicker:false,
      background:'#fff',
      payload:{
        chartTitle:"Title",
        subTitle:"subtitle",
        subTitleEnabled : false,
        stackedbarchart:graphData,
        cardvalue:0,
        collection:"",
        fields:"",
        aggregation:'',
        bordercolor:"",
        graph:false,
        x:"",
        y:"",
        z:"",
        xstroke:"",
        ystroke:"",
        labelx:"",
        labely:"",
        zstroke:"#fff",
        labelchartTitle:"",
      },
      stackedbarchart : [],
      tabledata:[{
        id: "item-1",
        label: "item 1",
        field:"",
        color:"#cc332b"
      }],
      items:[{
        id: "item-1",
        label: "item 1",
        field:"",
        color:"#cc332b"
      }],
      showlistField:[],
      loading:true,
      colorpickerStatus:false
    }


  }
  componentDidMount() {
    this.setState({payload:this.props.payload})
    this.graphData()
  }

  openSetting=()=>{
    this.setState({modalStatus:!this.state.modalStatus})
    this.props.dragStatus(!this.state.modalStatus)
  }
  handleChangeComplete = (color,param) => {
    //////console.log(param)
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[param]=color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
    //this.setState({ background: color.hex });
  };
  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }
  handleInputChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  borderColorChange=(color,param)=>{

    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["bordercolor"] = color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  graphData(){
    var date = new Date();
    var timestamp = date.getTime();
    var obj = this;
    const collection =  this.props.payload && this.props.payload.collection
    const tabledata = this.props.payload && this.props.payload.graph||this.state.items
    this.setState({tabledata:tabledata,items:tabledata,loading:true})
    if(collection !=='' || collection !== undefined || tabledata !== undefined) {

      let data = {
        collectionid: collection,
        "index": ES_INDEX,
        "graph": true,
        "fieldid": "0",
        "components": tabledata

      }
      axios.post(API_PATH+"project/datacard", data)
          .then(function (res) {
            var carddata = res.data.results.data;
            var aggregatevalue = carddata.aggregation;
            obj.setState({stackedbarchart: carddata.graph,loading:false});
            return res;

          }).catch(function (err) {
        obj.setState({loading:false});
        return err;
      });
    }



    if((getUrlSegment(1) === "dashboard")&& (collection !=='' && collection !== undefined)) {
      //  this.props.showform({ _id : collection});
      axios({
        method: "GET",
        url: API_PATH + "collection/showform?_id=" + collection+"&index="+ES_INDEX,
        async: false,
         headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        //////console.log(res.data.data)
        obj.setState({showlistField:res.data.data});
        return res;

      }).catch(function (err) {
        ////////console.log("error");
        ////////console.log(err);
        return err;
      });
      axios({
        method: "GET",
        url: API_PATH + "collection/" + collection + "/collectedinfo?index="+ES_INDEX,
        async: false,
         headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        ////////console.log(res.data.results)
        var carddata = res.data.results.data[0];
        //////console.log(carddata)
        var aggregatevalue = carddata.aggregation;
        obj.setState({carddata: carddata});
        return res;

      }).catch(function (err) {
        ////////console.log("error");
        ////////console.log(err);
        return err;
      });
    }
    else{
      obj.setState({stackedbarchart: graphData});

    }
  }
  handleChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  chartSaving=()=>{
    var prjctdtlid = atob(getUrlSegment(4));
    let sharetype = getUrlSegment(1);
    let  uid = '';
    if(sharetype === 'public') {
      uid = 2;
    }
    else{
      uid = getProfile().id;
    }
    let param={
      "projectid": prjctdtlid,
      "uid": uid,
      "index": ES_INDEX,
      "type": this.props.getdashboardChange.Dashboard,
      "dashboard":state.getStorableState()
      ,
      "id": this.props.getdashboardChange.UpdateId
    }
    this.props.chartSaving(param)
    this.setState({modalStatus:false})
  }
  checkBoxchange=(e)=> {
    let val;
    if (e.target.value === "false") {
      val = true
    } else {
      val = false
    }
    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = val
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  /*  drag and drop functions for hierarchial table */

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
    );

    this.setState({
      items
    });
  };
  addData=()=>{
    let data=this.state.items
    let counter=data.length+1
    let newData={
      id: "item-"+new Date().getTime(),
      label: "item "
    }
    let _data=data.concat(newData)
    this.setState({items:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  removeData=(dragid)=>{
    let data=this.state.items
    let _data=data.filter(item=>item.id !== dragid)
    this.setState({items:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleLabelChange=(e,dragid)=>{
    var _newState = this.state.items;
    var index=(_newState.findIndex(obj => obj["id"] === dragid))
    _newState[index][e.target.name]=e.target.value;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=this.state.items
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }

  handleColorChange=(color,param)=>{

    var _newState = this.state.items;
    var index=(_newState.findIndex(obj => obj["id"] === param))
    _newState[index]["color"]=color;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=this.state.items
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleClick=(status)=>{
   this.setState({colorpickerStatus:status})
  }

  render() {
    //////console.log(this.props);
    const {
      id,
      showBasicContent, showPreview,
      dropzoneID,payload, parentID, name
    } = this.props;


    const chartTitle =  this.state.chartTitle || payload && payload.chartTitle;
    const subTitle =  this.state.subTitle || payload && payload.subTitle;
    const subTitleEnabled = this.state.subTitleEnabled || payload && payload.subTitleEnabled;
    const collection =  this.state.collection || payload && payload.collection ;
    const tabledata =  this.state.items || payload && payload.graph ;
    let showlistField = []
    if(this.state.showlistField.length !==0) {
      for (let i = 0; i <this.state.showlistField.pages.length; i++) {
        (this.state.showlistField.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden")).map(list=>{
          showlistField.push(list)
        })
      }
    }

    let collections=(this.props.listAllCollections.ListAllCollections.data)
    if (showBasicContent) {
      return (
          <Draggable { ...this.props } >
            {
              <span className="drag-icon">{name}</span>
            }
          </Draggable>
      )
    }

    if (showPreview) {
      return (
          <div class="blue-lister">
            <div class="grid-header with-desc-box">
              <h5>{chartTitle}</h5>
              {subTitleEnabled &&
              <p>{subTitle}</p>
              }
            </div>
            <div class="blue-list-body">
              <div class="graph-wrap">
                    {this.state.loading &&
                    <LoaderSvg/>
                    }
                   <AreaColorMap />
              </div>
            </div>
          </div>


      );
    }
    return (
        <React.Fragment>

          <Draggable {...this.props}
              draggable={!this.props.DragDisabled.dragStatus}
          >
            <FormGroup className="m-0">
              <label className="col-sm-12">


                <span>{name}</span>
                <div className="chart-icons">
                  <div className="settings-icon" onClick={() => this.openSetting(id, dropzoneID, parentID)}></div>
                  <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>
                </div>
              </label>
            </FormGroup>
            {this.state.modalStatus &&
               <SimpleModal
                Close={this.openSetting}
                title={`Settings`}
                haveFooter={1}
                OKLabel="Save"
                OKAction={this.chartSaving}
                asignedUsershow = {false}
                cancelLabel="Cancel"
                cancelAction={this.openSetting}
            >
              <div className="DashBuilder-modal"  >
                <div className="task-input-container">
                  <div className="new-task-input">
                    <input type="text" placeholder="title" id="chartTitle" name="chartTitle" autoComplete="off" value={chartTitle}
                           onChange={(e)=>this.handleChange(e)} />
                  </div>

                </div>
                <div className="task-input-container container-flex">
                  <div className="new-task-input">
                    <input type="text" placeholder="subtitle" id="subTitle" name="subTitle" autoComplete="off" value={subTitle}
                           onChange={(e)=>this.handleChange(e)} />
                  </div>
                  <div className="select-chk-block" style={{"display": "block"}}>
                    <label className="control control-checkbox">Enable Subtitle
                      <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`} checked={subTitleEnabled} value={subTitleEnabled} onClick={(e)=>this.checkBoxchange(e)}/>
                      <div className="control-indicator"></div>
                    </label>
                  </div>

                </div>
                <div className="task-input-container">
                  <label>Collections:</label>
                  <div className="select-box eq-width">
                    <select id="priority" name="collection" onChange={(e)=>this.handleInputChange(e)} value={collection}>

                      <option value="">Option 1</option>
                      {collections.map((list,index)=>
                          <option value={list._id}>{atob(list.title)}</option>
                      )}
                    </select>
                  </div>
                </div>


                <div className="graph-proprties">

                  <div className="graph-proprties-color modal-panel-container">
                    <div className="task-input-container">
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(droppableProvided, droppableSnapshot) => (
                              <div
                                  ref={droppableProvided.innerRef}
                                  // style={getListStyle(droppableSnapshot.isDraggingOver)}
                              >
                                {tabledata.map((item, index) => (
                                    <Drags key={item.id} draggableId={item.id} index={index} isDragDisabled={this.state.colorpickerStatus}>
                                      {(draggableProvided, draggableSnapshot) => (
                                          <div onDrag={false} className={'panel-each'}
                                               ref={draggableProvided.innerRef}
                                               {...draggableProvided.draggableProps}
                                               {...draggableProvided.dragHandleProps}
                                               style={getItemStyle(
                                                   draggableSnapshot.isDragging,
                                                   draggableProvided.draggableProps.style
                                               )}
                                               isDragDisabled={this.state.colorpickerStatus}
                                          >
                                            <div className={`settings-panel`} >
                                              <input type={`text`} name="label" defaultValue={item.label} onChange={(e)=>this.handleLabelChange(e,item.id)}/>

                                              <div className="select-box">
                                                <select id="yaxis" name="field" onChange={(e)=>this.handleLabelChange(e,item.id)} value={item.field}>
                                                  <option value="">Y axis</option>
                                                  {showlistField.map((label,index)=>
                                                      <option key={`masterid`+index} value={label.masterid}>{label.label}</option>
                                                  )}
                                                </select>
                                              </div>

                                              <ColorPicker
                                                  stroke={item.id}
                                                  color={item.color}
                                                  onChangeComplete={this.handleColorChange }
                                                  handleClickStaus={this.handleClick}
                                              />

                                            </div>
                                            <div className={`settings-panel-option`}>
                                              <button className="ico-lib divider-add-button" onClick={()=>this.addData(item.id)}></button>
                                              <button className="ico-lib divider-sub-button" onClick={()=>this.removeData(item.id)}></button>
                                            </div>
                                          </div>
                                      )}
                                    </Drags>

                                ))}
                                {droppableProvided.placeholder}
                              </div>

                          )}

                        </Droppable>
                      </DragDropContext>
                    </div>


                  </div>
                </div>

              </div>


            </SimpleModal>
            }
          </Draggable>

        </React.Fragment>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled,
  EntityData:state.EntityReducer.GetEntity,

});
export default  withRouter(connect(mapStateToProps, {
  collections: collectionActions.listallcollections,
  showform : collectionActions.showform,
  chartSaving:DynamicDashboard.saveDashboarddata,
  dragStatus :DynamicDashboard.dragStatus,

})(AreaColorMapContainer));
