import React, {Component} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import {Dropdown, DropdownButton} from "react-bootstrap";


class CustomFields extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'text',
            options: [],
            id: '',
            required: false,
            switchLabel: false,
            label: '',
            formData:
                {
                    type: 'text',
                    options: [],
                    required: false,
                    id: '',
                    errormessage: '',
                    label:''
                },
        };
        this.choosetype = this.choosetype.bind(this)
        this.switchChange = this.switchChange.bind(this)
    }


    componentDidMount() {
        if (this.props.metaform.mode === 'edit') {
            this.setState({formData: this.props.metaform})
        } else {
            this.setState({formData: this.state.formData})

        }
    }



    componentWillUpdate(prevProps,nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    choosetype(value){
        this.setState({formData:{type:value,options: [],
                required: false,
                id: '',
                errormessage: ''}})

    }
    handleChange(i, event) {
        let options = [...this.state.formData.options];
        options[i] = event.target.value;
        this.setState({
                formData:
                { options: options,
                  type:this.state.formData.type ,
                  label:this.state.formData.label

                }});
    }

    addClick() {
        this.setState(prevState => ({
            formData: {                   // object that we want to update
                ...prevState.formData,    // keep all other key-value pairs
                options: [...prevState.formData.options,   null ]       // update the value of specific key
            }
        }))

        // this.setState(prevState => ({
        // formData:{type:this.state.formData.type,options: [...prevState.formData.options,   null ]}
        // }));
    }

    removeClick(i) {
        //////////////console.log(this.state.formData.options)
        let options = [...this.state.formData.options];
        options.splice(i, 1);
        this.setState(prevState => ({
            formData: {                   // object that we want to update
                ...prevState.formData,    // keep all other key-value pairs
                options: options       // update the value of specific key
            }
        }))
        //this.setState({formData:{ options: options,type:this.state.formData.type }});
    }
    handleFieldChange=(e)=>{
        //this.setState({...this.state.formData, [e.target.name]: [e.target.value] });
        e.persist();
        this.setState(prevState => ({
            formData: {                   // object that we want to update
                ...prevState.formData,    // keep all other key-value pairs
                [e.target.name]: e.target.value       // update the value of specific key
            }
        }))

       if(this.state.formData.mode === 'edit') {
           this.props.updateMetaFormField(e.target.name,e.target.value,this.state.formData.index)
        }
        //this.setState({formData:{[e.target.name]:[e.target.value]}})
}
    switchChange(){
        if(!this.state.switchLabel){
            var req='no'
        }
        else{
            var req='yes'
        }

        this.setState(prevState => ({
            switchLabel:!this.state.switchLabel,
            formData: {                   // object that we want to update
                ...prevState.formData,    // keep all other key-value pairs
                required: req       // update the value of specific key
            }
        }))
        //this.setState({switchLabel:!this.state.switchLabel,formData:{required:req,type:this.state.formData.type,options:this.state.formData.options,label:this.state.formData.label}});


    }
    SaveData=()=>{
        if(this.state.formData.mode !== 'edit') {
            //////////////console.log(this.state.formData)
            this.props.savedata(this.state.formData)
        }
      }

    render() {
            //////////////console.log(this.state.formData)
        return (
                <div className="mang-fm-block">
                    <div className="mang-fm-left">

                        <div className="ind-panel-options">
                            <label>Field Type<span className="req">*</span></label>
                            <div className="dropdown panel-drop">
                                <DropdownButton id="dropdownMenuButton" variant="dropdown-item ico-lib fs-ico" title={this.state.formData.type}>
                                    <Dropdown.Item className="dropdown-item ico-lib tx-ico" onClick={(e)=>this.choosetype('text')}>Text</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item ico-lib ta-ico" onClick={(e)=>this.choosetype('textarea')}>Textarea</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item ico-lib rb-ico" onClick={(e)=>this.choosetype('radiobutton')}>Radio Button</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item ico-lib cb-ico" onClick={(e)=>this.choosetype('checkbox')}>Checkbox</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item ico-lib dp-ico" onClick={(e)=>this.choosetype('datepicker')}>Datepicker</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item ico-lib nm-ico" onClick={(e)=>this.choosetype('number')}>Number</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item ico-lib dd-ico" onClick={(e)=>this.choosetype('dropdown')}>Dropdown</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item ico-lib fu-ico" onClick={(e)=>this.choosetype('fileupload')}>File Upload</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item ico-lib hd-ico" onClick={(e)=>this.choosetype('header')}>Header</Dropdown.Item>
                                 </DropdownButton>

                            </div>
                        </div>
                        <div className="ind-panel-options">
                            <label>Text Label<span className="req">*</span></label>
                            <div className="builder-input">
                                <input type="text" name="label" placeholder="Label Name" onChange={this.handleFieldChange}  value={this.state.formData.label }/>
                            </div>
                        </div>
                    </div>
                    <div className="mang-fm-right">
                        <div className="ind-panel-options split-input">
                            <div className="field-common field-req-block" id="form_required">
                                <label>Is this a required field?</label>
                                <button type="button" className={`btn-toggle ${this.state.formData.required === 'yes'?'active':''}`} data-toggle="button" aria-pressed="false"
                                        autocomplete="off" onClick={this.switchChange}>
                                    <div className="handle" on></div>
                                </button>

                            </div>

                        </div>

                        {this.state.formData.required ==='yes' &&
                        <div className="ind-panel-options">
                            <label>Error message</label>
                            <div className="builder-input">
                                <input type="text" name="errormessage" onChange={this.handleFieldChange} placeholder="Label Name" value={this.state.formData.errormessage }/>
                            </div>
                        </div>
                        }

                        {(this.state.formData.type === 'radiobutton' || this.state.formData.type === 'checkbox' || this.state.formData.type === 'dropdown') &&

                        <div className="inner-dropdown" id="optionfields">
                            <div className="header-dropdown">
                                <button className="general-button ico-lib pluse-button" id="addmetachild" onClick={() => this.addClick()}></button>
                                <h5>Options</h5>
                            </div>
                            <div className="dropdown-inner-content" id="change_metafieldchild">
                                {this.state.formData.options.map((el, i) => (

                                    <div key={i} id="1560961774194" className="ind-panel-options inner-drop-option">
                                        <div className="builder-input">
                                            <input type="text" placeholder="Change option"
                                                   value={el|| ""}
                                                   onChange={e => this.handleChange(i, e)}
                                            />
                                            <div className="choice-selector">
                                                <a  className="ico-lib minus-button" onClick={() => this.removeClick(i)}> </a>
                                            </div>
                                    </div>
                                </div>
                                ))}





                            </div>
                        </div>
                        }


                    </div>
                  <button onClick={this.SaveData}> Submit</button>
                </div>

        );
    }
}


export default CustomFields