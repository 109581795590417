import React, { useState, useEffect  } from 'react';
import moment from 'moment';
import {getProfile} from "../../../utilities/AuthService";
import OutsideAlerter from "../../views/OutsideAlerter";
import {FRMSVID_PATH,FRMSDOC_PATH,FRMSIMG_PATH,} from "../../../constants"
import {getFileformat, isAudio, isImage, getUrl,isVideo,checkUrl} from "../../../utilities/CustomFunctions";
import {Card} from "react-bootstrap";
const GoogleMap = React.lazy(() =>  import('../../views/GoogleMap'))
const GoogleStaticMap = React.lazy(() =>  import('../../pages/ceshareview/GoogleStaticMap'))

function Template1(Props) {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const container = document.querySelector('.card-container');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        var scrollY = 0;
        var scrollX = 0;
        var doc = document.getElementById("a");
        if(doc.scrollTop  !== scrollY) {
            const container = document.querySelector('.card-container')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            if (contentHeight <= scrollTop) {
                Props.loadmoreCollectionData()
            };
        }
          setIsFetching(true);
    }

    function fetchMoreListItems() {
        Props.loadmoreCollectionData()
    }
    let showlistField = []
    if(Props.label.showformdata.length !==0) {
        for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
            (Props.label.showformdata.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden")).map(list=>{
                showlistField.push(list)

            })
        }
    }
    let viewtemplate=Props.verticaltemplate
   // let lng=showlistField.filter(list=>list.type === "longitude")
   /// let lat=showlistField.filter(list=>list.type === "latitude")
    console.log(Props.collection.CollectionData)
    let perStat=((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].addedit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()))



    return (
            <div className={`card-container grid-view template-collection ${Props.verticalImage?` with-image `:''} `} id="a" >
                {Props.collection.CollectionData.map((row, rowIndex) =>
                    <React.Fragment key={`template-1-outer`+rowIndex}>



                        {viewtemplate.map((list,index)=>
                            <>

                                {/* <input type="checkbox" className="inp-chk"
                                       checked={(Props.state.checkedRow.indexOf(row["id"]) > -1) ? "checked" : ""}
                                       data-val={row['id']}
                                       onClick={(e) => Props.handleSelectRow(row['id'])}/> */}
                           <div key={`template-1`+index} className={Props.collection.flaglist.length === 0?"ind-collection-card":'ind-collection-card without-img-flag'}
                                onDoubleClick={(e) => Props.showPreviewFormModal(e, row['id'], rowIndex)}
                              //  onClick={(e) => Props.handleClicks(e, row['id'], rowIndex,perStat)}
                           >
                                <div className="collection-card-left">
                                    <div class="select-chk-block" style={{"display": "block"}}>
                                        <label class="control control-checkbox">
                                            <input type="checkbox" className="inp-chk" checked={(Props.state.checkedRow.indexOf(row["id"]) > -1) ? "checked" : ""}
                                                                        data-val={row['id']}
                                                                        onClick={(e) => Props.handleSelectRow(row['id'])}/>
                                            <div class="control-indicator"></div>
                                        </label>
                                    </div>
                                    <div className="form-option-block">
                                        {((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].addedit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                                        <button className="ico-lib opt-form-ico"  style={{"z-index": "2"}} onClick={(e)=>Props.showOption(e, row['id'])}></button>
                                        }
                                        {Props.showtemplateOptions && (Props.collectededitid === row['id']) &&
                                        <OutsideAlerter
                                            status={Props.showtemplateOptions}
                                            Triggered={() => Props.showOption(!Props.showtemplateOptions)}
                                        >
                                            <ul className="panel-option" id="option-list">
                                                <li><nav className="panel-option-click ico-lib form-edit-ico" onClick={(e) => Props.showFormModal(e, row['id'])} >Edit</nav></li>
                                            </ul>
                                        </OutsideAlerter>
                                        }
                                    </div>
                                    {!Props.verticalImage && !Props.verticalMapenabled &&
                                    <>
                                    <p>{list.item1.label}</p>
                                        <h5>{list.item1.type ==="datepicker" && row[list.item1.masterid] !==""?
                                            moment(row[list.item1.masterid]).format("MMMM DD, YYYY"):
                                            row[list.item1.masterid]}
                                        </h5>

                                    </>
                                        }
                                    {Props.verticalImage &&
                                    <AttachmentBox
                                        filename={row[list.item1.masterid]}
                                        type={list.item1.type}
                                    />
                                    }
                                    {!Props.verticalImage && Props.verticalMapenabled && list.hasOwnProperty("item10") &&
                                    <>
                                        {list.item13.masterid === "static" ?

                                            <GoogleStaticMap
                                                lat={row[list.item10.masterid]}
                                                lng={row[list.item11.masterid]}
                                                zoom={list.item12.masterid}
                                                type={`vertical`}
                                            />
                                            :
                                            <GoogleMap
                                                lat={row[list.item10.masterid]}
                                                lng={row[list.item11.masterid]}
                                                zoom={list.item12.masterid}
                                                type={`vertical`}
                                            />

                                        }


                                    </>
                                    }


                                </div>

                                <div className="collection-card-right">
                                    {!Props.verticalImage && Props.verticalMapenabled &&
                                    <div className="collection-card-right-header">
                                        <p>{list.item1.label}</p>
                                        <h5>{list.item1.type === "datepicker" && row[list.item1.masterid] !=="" ? moment(row[list.item1.masterid]).format("Do MMM, YYYY") : row[list.item1.masterid]}</h5>
                                    </div>
                                    }
                                    {list.item2.label !== "" &&
                                    <div className="collection-card-right-header">
                                        <p>{list.item2.label}</p>
                                        <h5>{list.item2.type === "datepicker" && row[list.item2.masterid] !=="" ? moment(row[list.item2.masterid]).format("Do MMM, YYYY") : row[list.item2.masterid]}</h5>
                                    </div>
                                    }
                                    {list.item3.label !== "" &&
                                    <div className="collection-card-right-content">
                                        <p>{list.item3.label}</p>
                                        <h5>{list.item3.type === "datepicker" && row[list.item3.masterid] !==""?
                                            moment(row[list.item3.masterid]).format("MMMM DD, YYYY") :
                                            row[list.item3.masterid]}
                                        </h5>

                                    </div>
                                    }

                                    <div className="collection-card-right-footer">
                                        <ul>
                                            {list.item4.label !== ""&&
                                            <li>
                                                <p>{list.item4.label}</p>
                                                <h5>{list.item4.type === "datepicker" && row[list.item4.masterid] !==""?
                                                    moment(row[list.item4.masterid]).format("MMMM DD, YYYY") :
                                                    row[list.item4.masterid]}
                                                </h5>
                                            </li>
                                            }

                                            {list.item5.label !== ""&&
                                            <li>
                                                <p>{list.item5.label}</p>
                                                <h5>{list.item5.type === "datepicker" && row[list.item5.masterid] !==""?
                                                    moment(row[list.item5.masterid]).format("MMMM DD, YYYY") :
                                                    row[list.item5.masterid]}
                                                </h5>
                                            </li>
                                            }

                                            {list.item6.label !== "" &&
                                            <li>
                                                <p>{list.item6.label}</p>
                                                <h5>{list.item6.type === "datepicker" && row[list.item6.masterid] !=="" ?
                                                    moment(row[list.item6.masterid]).format("MMMM DD, YYYY") :
                                                    row[list.item6.masterid]}
                                                </h5>
                                            </li>
                                            }
                                            {list.item7.label !== "" &&
                                            <li>
                                                <p>{list.item7.label}</p>
                                                <h5>{list.item7.type === "datepicker" && row[list.item7.masterid] !==""?
                                                    moment(row[list.item7.masterid]).format("MMMM DD, YYYY") :
                                                    row[list.item7.masterid]}
                                                </h5>

                                            </li>
                                            }
                                            {list.item8.label !== "" &&
                                            <li>
                                                <p>{list.item8.label}</p>
                                                <h5>{list.item8.type === "datepicker" && row[list.item8.masterid] !=="" ?
                                                    moment(row[list.item8.masterid]).format("MMMM DD, YYYY") :
                                                    row[list.item8.masterid]}
                                                </h5>
                                            </li>
                                            }
                                            {list.item9.label !== "" &&
                                            <li>
                                                <p>{list.item9.label}</p>
                                                <h5>{list.item9.type === "datepicker" && row[list.item9.masterid] !=="" ?
                                                    moment(row[list.item9.masterid]).format("MMMM DD, YYYY") :
                                                    row[list.item9.masterid]}
                                                </h5>
                                            </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                       {/*        {Props.collection.flaglist.length !== 0 &&*/}
                       {/*        <div className="flag-area">*/}


                       {/*            {Props.collection.flaglist.map((item, itmIndex) =>*/}
                       {/*                <div className="select-chk-block" style={{"display": "block"}}>*/}
                       {/*                    <label className="control control-checkbox">{item.title}*/}
                       {/*                        <input*/}
                       {/*                            checked={(row['flags'].filter(list=>list.id === item.id)).length === 1}*/}
                       {/*                            type="checkbox" className="inp-chk" name="dataview"*/}
                       {/*                            onChange={()=>Props.handleFlagSet(rowIndex,item.id,row['id'])}*/}

                       {/*                        />*/}
                       {/*                        <div className="control-indicator"*/}

                       {/*style={(row['flags'].filter(list=>list.id === item.id)).length === 1?{background:item.color, borderColor:item.color}  :{}}*/}
                       {/*                        />*/}
                       {/*                    </label>*/}
                       {/*                </div>*/}
                       {/*            )}*/}


                       {/*        </div>*/}
                       {/*        }*/}
                            </div>


                            </>
                        )}
                    </React.Fragment>

                )}
            </div>
    )

}

function TemplateVerticalPreview(Props) {
    let viewtemplate=Props.verticaltemplate
    let showlistField = []
    if(Props.label.showformdata.length !==0) {
        for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
            (Props.label.showformdata.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden")).map(list=>{
                showlistField.push(list)

            })
        }
    }
    let lon=showlistField.filter(list=>list.type === "longitude")
    let lat=showlistField.filter(list=>list.type === "latitude")
    console.log(Props.collection.CollectionData)
    return (
        <div className={`card-container grid-view template-collection ${Props.verticalImage?` with-image `:''} `} id="a">
            {Props.collection.CollectionData.slice(0,1).map((row, rowIndex) =>
                <React.Fragment>
                    {viewtemplate.map((list,index)=>
                        <div className="ind-collection-card">
                            <div className="collection-card-left">

                                {!Props.verticalImage &&  !Props.verticalMapenabled &&
                                <>
                                    <p>{list.item1.label}</p>
                                    <h5>{list.item1.type ==="datepicker"?
                                        moment(row[list.item1.masterid]).format("MMMM DD, YYYY"):
                                        row[list.item1.masterid]}
                                    </h5>

                                </>
                                }
                                {Props.verticalImage &&
                                    <AttachmentBox
                                        filename={row[list.item1.masterid]}
                                        type={list.item1.type}

                                    />

                                }
                                {!Props.verticalImage && Props.verticalMapenabled && lon.length !== 0 && lat.length !== 0 &&
                                <GoogleMap
                                    lat={row[list.item10.masterid]}
                                    lng={row[list.item11.masterid]}
                                    zoom={list.item12.masterid}
                                    type={`vertical`}
                                />
                                }

                            </div>
                            <div className="collection-card-right">

                                {!Props.verticalImage && !Props.verticalMapenabled &&
                                <div className="collection-card-right-header">
                                    <p>{list.item1.label}</p>
                                    <h5>{list.item1.type ==="datepicker"?moment(row[list.item1.masterid]).format("Do MMM, YYYY"):row[list.item1.masterid]}</h5>
                                </div>

                                }



                                <div className="collection-card-right-header">
                                    <p>{list.item2.label}</p>
                                    <h5>{list.item2.type ==="datepicker"?moment(row[list.item2.masterid]).format("Do MMM, YYYY"):row[list.item2.masterid]}</h5>
                                </div>
                                <div className="collection-card-right-content">
                                    <p>{list.item3.label}</p>
                                    <h5>{list.item3.type ==="datepicker"?
                                        moment(row[list.item3.masterid]).format("MMMM DD, YYYY"):
                                        row[list.item3.masterid]}
                                    </h5>

                                </div>
                                <div className="collection-card-right-footer">
                                    <ul>
                                        <li>
                                            <p>{list.item4.label}</p>
                                            <h5>{list.item4.type ==="datepicker"?
                                                moment(row[list.item4.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item4.masterid]}
                                            </h5>
                                        </li>
                                        <li>
                                            <p>{list.item5.label}</p>
                                            <h5>{list.item5.type ==="datepicker"?
                                                moment(row[list.item5.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item5.masterid]}
                                            </h5>
                                        </li>
                                        <li>
                                            <p>{list.item6.label}</p>
                                            <h5>{list.item6.type ==="datepicker"?
                                                moment(row[list.item6.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item6.masterid]}
                                            </h5>
                                        </li>
                                        <li>
                                            <p>{list.item7.label}</p>
                                            <h5>{list.item7.type ==="datepicker"?
                                                moment(row[list.item7.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item7.masterid]}
                                            </h5>

                                        </li>
                                        <li>
                                            <p>{list.item8.label}</p>
                                            <h5>{list.item8.type ==="datepicker"?
                                                moment(row[list.item8.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item8.masterid]}
                                            </h5>
                                        </li>
                                        <li>
                                            <p>{list.item9.label}</p>
                                            <h5>{list.item9.type ==="datepicker"?
                                                moment(row[list.item9.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item9.masterid]}
                                            </h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {Props.collection.flaglist.length !== 0 &&
                            <div className="flag-area">


                                {Props.collection.flaglist.map((item, itmIndex) =>
                                    <div className="select-chk-block" style={{"display": "block"}}>
                                        <label className="control control-checkbox">{item.title}
                                            <input type="radio" className="inp-chk" name="dataview"/>
                                            <div className="control-indicator"/>
                                        </label>
                                    </div>
                                )}


                            </div>
                            }
                       </div>
                    )}



                </React.Fragment>
            )}
        </div>
    )

}

function AttachmentBox(Props) {

    return(
        <React.Fragment>
            {Props.type  === "imageurl"   && Props.filename !== undefined?
                <div className="collection-card-image">
                    <img src={Props.filename}/>
                </div>
                :Props.type  === "videourl"   ?
                    <React.Fragment>
                    {Props.filename !=="" &&
                    (checkUrl(Props.filename) === "Vimeo" || checkUrl(Props.filename) === "Youtube") ?
                            <div className="collection-card-image">

                              <iframe src={checkUrl(Props.filename) === "Vimeo"?Props.filename:getUrl(Props.filename)} width="100%" height="100%"
                                        frameBorder="0" allow="autoplay; fullscreen" allowFullScreen
                                        style={{"z-index": "1"}}> </iframe>


                            </div>
                        :
                        (checkUrl(Props.filename) === "Video") ?
                            <div className="collection-card-image">
                                <video width="100%" height={`100%`} controls>
                                    <source src={Props.filename} type="video/mp4"/>
                                </video>

                            </div>
                            :

                        <div className="collection-card-image">

                            <tr>
                                <td>
                                    <a href="javascript:void(0);" className="attach-click"
                                       data-toggle="tooltip" data-placement="bottom" style={{"width": "43px", "height": "50px", "margin-top": "11px",}}>
                                    <div className="format mp4">Video</div>
                                </a></td>
                            </tr>

                        </div>

                    }
                    </React.Fragment>
                :
                    <React.Fragment>
                        {isImage(Props.filename) &&
                        <div className="collection-card-image">

                            <img src={FRMSIMG_PATH + Props.filename+"?fit=crop&h=262"}/>
                        </div>
                        }
                        {Props.filename !=="" && isVideo(Props.filename) &&
                        <div className="collection-card-image">
                            <video width="100%" height={`100%`} controls>
                                <source src={FRMSVID_PATH + "/" + Props.filename} type="video/mp4"/>
                            </video>

                        </div>
                        }
                        {isAudio(Props.filename) &&
                        <div className="collection-card-image">
                            <audio controls>
                                <source src={FRMSDOC_PATH + "/" + Props.filename} type="video/mp4"/>
                            </audio>
                        </div>
                        }


                        {Props.filename !=="" &&Props.filename !==undefined  &&!isAudio(Props.filename) && !isVideo(Props.filename) && !isImage(Props.filename) &&
                        <div className={`collection-card-image ${getFileformat(Props.filename).toLowerCase()}`}
                             style={{"max-width": "100%"}}>
                        <span
                            className={`format ${getFileformat(Props.filename).toLowerCase()}`}> {getFileformat(Props.filename).toLowerCase()} </span>
                        </div>
                        }

                    </React.Fragment>

            }
       </React.Fragment>
       )

}

export  {Template1,TemplateVerticalPreview};

