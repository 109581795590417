import {combineReducers} from 'redux';

const listUsersdata = (state = {Users:{data:[],count:'',totalitems:''}, todoUsers:[],searchParam:{search:"",company:'',designation:'',team:''}, autocompleteSearch : false,loading : 0}, action) => {

    switch (action.type) {
        case 'USER_LOADING' :
            return {
                ...state,
                loading: action.loading,

            };
        case "LIST_USERS_DATA":
            return {
                ...state,
                Users: action.listUsers.data.results,
                searchParam:action.searchParam
            };
            break;
        case "LIST_TODO_USERS_DATA":
            return {
                ...state,
                todoUsers: action.listUsers,
                autocompleteSearch : action.search ? true : false,
                searchParam:action.searchParam

            };
            break;
        default:
            return state;
    }
};
const listAllUsers = (state = {Users:[],searchParam:{search:"",company:'',designation:'',team:''},Totaluser : '',loading : 0}, action) => {
    var _newState;
    switch (action.type) {

        case "LIST_ALLUSERS_DATA":
            return {
                ...state,
                Users: action.listAllUsers.data.results.data,
                TotalUsers: action.listAllUsers.data.results.totalusers,
                searchParam:action.searchParam
            };
            break;
        case "LIST_MORE_USERS_DATA":
            _newState = {...state};
            _newState['Users'] = _newState.Users.concat(action.listMoreUsers.data.results.data);
            
            return _newState;

            break;
        default:
            return state;
    }
};

const listUsersDetaildata = (state = {UsersDetail:{data:[],userStatistics:[]}}, action) => {
    switch (action.type) {
        case "LIST_USERDETAIL":
            return {
                ...state,
                UsersDetail: action.listUsersDetail.data.results.data[0],
            };
            break;



        default:
            return state;
    }
};
const listUserInfoData = (state = {UserInfo:{data:[]}}, action) => {
    switch (action.type) {
        case "RESET_USERINFO":
            return {
                ...state,
                UserInfo: {permission:[]},
            };
            break;

        case "LIST_USERINFO":
            return {
                ...state,
                UserInfo: action.listUserInfo.data.results.data[0],
            };
            break;



        default:
            return state;
    }
};


const listUsersStaticsdata = (state = {UsersStatics:{data:[]}}, action) => {
    switch (action.type) {
        case "LIST_USERSTATICS":
            return {
                ...state,
                UsersStatics: action.listUsersStatics.data.data,
            };
            break;
        default:
            return state;
    }
};

const userModuleUpdationsdata = (state = {UsersModule:false}, action) => {    switch (action.type) {
        case "MODULE_PERMISSIONUPDATION":
            return {
                ...state,
                UsersModule:!state.UsersModule,
            };
            break;
            
        default:
            return state;
    }
};

const listUsersActlogdata = (state = {UsersActlog:{data:[]}}, action) => {
    switch (action.type) {
        case "LIST_USERACTIVITYLOG":
            return {
                ...state,
                UsersActlog: action.listUsersActlog.data.results,
            };
            break;
        default:
            return state;
    }
};
const listUsersActionsdata = (state = {UsersActions:{data:[]}}, action) => {
    switch (action.type) {
        case "LIST_USERACTIONS":
                 return {
                ...state,
                UsersActions: action.listUsersActions.data.data,
            };
            break;
        default:
            return state;
    }
};
const listUsersAllpermissiondata = (state = {UsersAllpermission:{data:[]}}, action) => {
    switch (action.type) {
		
        case "LIST_USERALLPERMISSION":
            return {
                ...state,
                UsersAllpermission: action.listUsersAllpermission.data.data,
            };
            break;
        default:
            return state;
    }
};
const RoleModulePermissiondata = (state = {RoleModpermission:{data:[]}}, action) => {
    switch (action.type) {
        case "ALL_ROLEMODULEPERMISSION":
            return {
                ...state,
                RoleModpermission: action.listRolemodulePermission.data.results.data,
            };
            break;
        case "ROLE_MODPERMSN_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};


const listAllusersPermissiondata = (state = {AllusersPermission:[],searchParam:{search:"",company:'',designation:'',team:''},loading : 0}, action) => {
	    var _newState;
    switch (action.type) {
        case "ALL_USERMODULEPERMISSION":
            return {
                ...state,
                AllusersPermission: action.listAllusersPermission.data.results.data,
				 searchParam:action.searchParam
            };
            break;
		case "ALL_MODULEPERMISSION":
            _newState = {...state};
            _newState['AllusersPermission'] = _newState.AllusersPermission.concat(action.listAllusersPermission.data.results.data);
            
            return _newState;

            break;
        default:
            return state;
    }
};
 const listUsersPermissiondata = (state = {UsersPermission:[],module:[]}, action) => {
     switch (action.type) {
       case "LIST_USERPERMISSION":
           let results=action.listUsersPermission.data
           let module=[]
           if(results.length > 0) {
               results.map((list,index)=>{
                   module[index]={
                       module_id:list.module_id,
                       module:list.module,
                       displayname:list.display_name,
                       actions:list.actions,
                       submodule:list.submodule,
                       module_permission:list.module_permission
                   }
               })

           }
             return {
               ...state,
                UsersPermission: action.listUsersPermission.data,
                 module:  module
             };
            break;
        default:
            return state;
    }
 };
const listAllUsersdata = (state = {Users:{data:[],count:'',totalitems:''},data:[]}, action) => {

    switch (action.type) {

        case "LIST_ALL_USERS_DATA":
            let results = action.listUsers.data.data;
            let users = [];
            let org = [];
            if(results) {

                let userList = results.user;
                let organizationList = results.organization;
                let department = results.department;
                let designation = results.designation;
                let group = results.group;

                userList = userList.map(item=> {

                                item.name = item.first_name + ' ' + item.last_name;
                                item.fullname = item.name;
                                item.permissionto = item.user_id;
                                item.user_type = 'user';
                                item.category = item.user_type;
                                item.uniqueId = item.user_type.toLowerCase()+"_"+item.user_id;
                                return item;
                            });
                organizationList = organizationList.map(item=> {return {first_name : item.name, last_name : '', fullname : item.name, designation : '', department : '', user_type :'organization', user_id :item.id ,permissionto : item.id, category : 'organization', uniqueId : "organization_"+item.id  }});
                department = department.map(item=> {return {first_name : item.dep_name, last_name : '', fullname : item.dep_name, designation : '', department : '', user_type :'department', user_id :item.id ,permissionto : item.id, category : 'department', uniqueId : "department_"+item.id }});
                designation = designation.map(item=> {return {first_name : item.name, last_name : '', fullname : item.name,  designation : '', department : '', user_type :'designation', user_id :item.id, permissionto : item.id,  category : 'designation', uniqueId : "designation_"+item.id }});
                group = group.map(item=> {return {first_name : item.group_name, last_name : '', fullname : item.group_name,  designation : '', department : '', user_type :'group', user_id :item.group_id, permissionto : item.group_id,  category : 'group', uniqueId : "group_"+item.group_id }});
                let combinedList = [].concat.apply([], [userList,organizationList,department,designation,group]);

                return {
                    ...state,
                    Users: combinedList,
                    data:combinedList

                };
                break;

                /*results.user.filter((a) => a.user_type === 'User').map(a => {
                    let user = a;
                    user.name = user.first_name + ' ' + user.last_name;
                    user.fullname = user.first_name + ' ' + user.last_name;
                    user.id = user.user_id;
                    user.user_type = 'user';
                    users.push(user);
                });*/
            }
            /*if(results.organization) {
                results.organization.map(a => {
                    let organization = a;
                    organization.fullname = organization.name;
                    organization.user_type = 'organization';
                    users.push(organization);

                });
            }
            if(results.group) {
                results.group.map(a => {
                    let group = a;
                    group.user_type = 'group';
                    group.name = group.group_name;
                    group.fullname = group.group_name;

                    group.id = group.group_id;
                    users.push(group);

                });
            }
            if(results.department) {
                results.department.map(a => {
                    let department = a;
                    department.user_type = 'function';
                    department.name = department.dep_name;
                    department.fullname = department.dep_name;
                    users.push(department);

                });
            }*/



        default:
            return state;
    }
};
const notificationInfodata = (state = {NotificationInfo:{},aggs:[], clearNotifications: false}, action) => {
    var _newState;
    var resultdata;

    switch (action.type) {
        case "NOTIFICATION_INFO":
            return {
                ...state,
                NotificationInfo: action.notificationInfo.data.results.data.notification,
                aggs:action.notificationInfo.data.results.data.aggs,
            };
            break;
        case "NOTIFICATION_INFO_UPDATED":

            _newState = {...state};
            resultdata=(action.results)
         
            _newState[`NotificationInfo`][`data`]=resultdata.concat(_newState.NotificationInfo.data)
            //_newState[`NotificationInfo`][`aggs`][1][`doc_count`]=_newState.NotificationInfo.aggs.filter(list => list.key === 'unread')[0].doc_count + 1

           
            return _newState;
            break;
        case "CLEAR_NOTIFICATION":
            return {
                ...state,
                clearNotifications: !state.clearNotifications
            };
            break;
        default:
            return state;
    }
};
const helpInfodata = (state = {HelpInfo:[],data:[]}, action) => {
    switch (action.type) {
        case "HELP_INFO":
            return {
                ...state,
                HelpInfo: action.helpInfo.data.results.data,
            };
            break;
        case "HELP_INFO_CONTENT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
const listUserModulePermissiondata = (state = {UsersPermission:[]}, action) => {
    switch (action.type) {
        case "LIST_USER_MODULE_PERMISSION":
            let results=action.listUsersPermission.data
            let module=[]
            if(results.length > 0) {
                results.map((list,index)=>{
                    module[index]={
                        module_id:list.module_id,
                        module:list.module,
                        displayname:list.display_name,
                        actions:list.actions,
                        submodule:list.submodule,
                        module_permission:list.module_permission
                    }
                })

            }
            return {
                ...state,
                UsersPermission: action.listUsersPermission.data,
                module:  module
            };
            break;



        default:
            return state;
    }
};
const deleteUserInfo = (state = {userDeleted:false}, action) => {
    switch (action.type) {
        case "DELETE_USER":
            return {
                ...state,
                userDeleted:!state.userDeleted,
            };
            break;
        default:
            return state;
    }
};
const listUserGroup = (state = {userGrouplist:[]}, action) => {
    switch (action.type) {
        case "LIST_USERGROUPS":
            return {
                ...state,
                userGrouplist:action.listGroups.data.results.data,
            };
            break;
        case "RESET_GROUP":
            return {
                ...state,
                userGrouplist:[]
            };
            break;
        default:
            return state;
    }
};

const listAllCompanies = (state = {userAllcompanies:[],data:[]}, action) => {
    switch (action.type) {
        case "LIST_ALLCOMPANIES":
           if(action.allCompanies.length !==0) {
               return {
                   ...state,
                   userAllcompanies: action.allCompanies.data.data,
               };
           }
           else{
               return {
                   ...state,
                   userAllcompanies: [],
               };
           }

            break;
        default:
            return state;
    }
};
const listAllDesignations = (state = {userAlldesignations:[],data:[]}, action) => {
    switch (action.type) {
        case "LIST_ALLDESIGNATIONS":
            if(action.allDesignations.length !==0) {
                return {
                    ...state,
                    userAlldesignations: action.allDesignations.data.data,
                };
            }
            else{
                return {
                    ...state,
                    userAlldesignations: [],
                };
            }
            break;
        default:
            return state;
    }
};
const listAllReportees = (state = {userAllReportees:[],data:[]}, action) => {
    switch (action.type) {
        case "LIST_ALLREPORTEES":
            if(action.allReportees.length !==0) {
                return {
                    ...state,
                    userAllReportees: action.allReportees.data.data,
                };
            }
            else {
                return {
                    ...state,
                    userAllReportees: [],
                };
            }
            break;
        default:
            return state;
    }
};
const UserStatistics = (state = {userStatistics:[],data:[]}, action) => {
	
    switch (action.type) {		
        case "LIST_USER_STATISTICS":
            return {
                ...state,
                userStatistics:action.UserStatistics.data.data,
            };
            break;
        default:
            return state;
    }
};
const saveUserGroup = (state = {userGroupcreated:false,userGroupedited:false,userGroupdeleted:false}, action) => {

    switch (action.type) {
        case "SAVE_USERGROUP":
            return {
                ...state,
                userGroupcreated:!state.userGroupcreated,
            };
            break;
            case "EDIT_USERGROUP":
            return {
                ...state,
                userGroupedited:!state.userGroupedited,
            };
            break;
        case "DELETE_USERGROUP":
            return {
                ...state,
                userGroupdeleted:!state.userGroupdeleted,
            };
            break;
        default:
            return state;
    }
};
const addmoreUserGroup = (state = {moreusersadded:false}, action) => {
    switch (action.type) {
        case "SAVE_MOREGROUPUSERS":
            return {
                ...state,
                moreusersadded:!state.moreusersadded,
            };
            break;
        default:
            return state;
    }
};
const groupDetails = (state = {groupdetails:[]}, action) => {
    switch (action.type) {
        // case "RESET_GROUPINFO":
        //     return {
        //         ...state,
        //         groupdetails: [],
        //     };
        //     break;
        case "GET_GROUPINFO":
            return {
                ...state,
                groupdetails:action.groupdetails.data.results.data,
            };
            break;
        default:
            return state;
    }
};
const getModeration = (state = {getmoderation:[]}, action) => {
    switch (action.type) {
        case "GET_MODERATION":
            return {
                ...state,
                getmoderation:action.getmoderation.data.results.data,
            };
            break;
        default:
            return state;
    }
};
const sendEmailusers = (state = {senduseremail:[]}, action) => {
    //console.log(action)
    switch (action.type) {

        case "SEND_USEREMAIL":
            return {
                ...state,
                senduseremail:action.sendEmailTousers.data.results,

            };
            break;
        default:
            return state;
    }
};

const GetRoleEntityDetails = (state = {RoleEntityDetails: [] }, action) => {

    switch (action.type) {
        case "ROLE_ENTITY_DETAIL":
            return {
                ...state,
                RoleEntityDetails: action.listEntityFieldDetails.data.results.data
            };
            break;
        default:
            return state;
    }
};

const GetRoleEntityFields = (state = {RoleEntityFields: [] }, action) => {

    switch (action.type) {
        case "ROLE_ENTITY_FIELD":
            return {
                ...state,
                RoleEntityFields: action.listRolecollectionFields.data.results.data
            };
            break;
        default:
            return state;
    }
};

const listAllusersPermissionRole = (state = {allUsersPermissionRole:[],loading : 0}, action) => {

    var _newState;
    switch (action.type) {
        case "ALL_USER_MODULE_PERMISSION_ROLE":
            return {
                ...state,
                allUsersPermissionRole: action.listAllusersPermissionRole.data.data
            };
            break;
        default:
            return state;
    }
};

const getAllRoles = (state = {allRoles:[],loading : 0}, action) => {
//    console.log(action)
    switch (action.type) {
        case "ALL_ROLES":
            return {
                ...state,
                allRoles: action.listAllRoles.data.results.data
            };
            break;
        default:
            return state;
    }
};

const getAllSearchEntityRoles = (state = {searchRoles:[],loading : 0}, action) => {

    switch (action.type) {
        case "SEARCH_ROLES":
            return {
                ...state,
                searchRoles: action.searchEntityRoles.data.data,
            };
            break;
        default:
            return state;
    }
};

const saveRoles = (state = {saveRoleset:false,loading : 0}, action) => {

    switch (action.type) {
        case "SAVE_ROLES":
            return {
                ...state,
                saveRoleset: !state.saveRoleset,
            };
            break;
        default:
            return state;
    }
};

const saveRoleModPermission = (state = {roleModPermission:[]}, action) => {
    switch (action.type) {
        case "SAVE_ROLEMOD_PERMISSION":
            return {
                ...state,
                roleModPermission: action.saveRolemodulePermission.data,
            };
            break;
        default:
            return state;
    }
};

const getOrgUserList = (state = {orgUsers : []}, action) => {
    switch (action.type) {
        case "GET_ORG_USER_LIST" :
            return {
                ...state,
                ['userList_'+ action.masterid] : action['userList_'+ action.masterid]
            }

        default:
            return state;
    }
};

export const Users = combineReducers({
    listUsersdata,
    listAllUsers,
    listUsersDetaildata,
    listUserInfoData,
    listUsersStaticsdata,
    listUsersActlogdata,
    listUsersActionsdata,
    listUsersAllpermissiondata,
	listAllusersPermissiondata,
    RoleModulePermissiondata,
    listUsersPermissiondata,
    listAllUsersdata,
    notificationInfodata,
    helpInfodata,
    listUserModulePermissiondata,
    deleteUserInfo,
    listUserGroup,
    listAllCompanies,
    listAllDesignations,
    listAllReportees,
    saveUserGroup,
	UserStatistics,
    addmoreUserGroup,
    groupDetails,
	userModuleUpdationsdata,
    getModeration,
    sendEmailusers,
    GetRoleEntityDetails,
    listAllusersPermissionRole,
    getAllRoles,
    getAllSearchEntityRoles,
    GetRoleEntityFields,
    saveRoles,
    saveRoleModPermission,
    getOrgUserList
});

export default Users;

export const getCurrentState = state => state.Users;
