import {combineReducers} from 'redux';
let initialComponents= {}

const listFormsdata = (state = {Forms:{data:[]},Group:'',Search:''}, action) => {

    switch (action.type) {

        case "LIST_All_FORMS":
            return {
                ...state,
                Forms: action.listallforms.data,
                Group:action.group,
                Search:action.search

            };
            break;



        default:
            return state;
    }
};
const GetCollectionInfoDet = (state = {collectionInfo:{}}, action) => {
    switch (action.type) {
        case "GET_COLLECTION_INFO":
            var info=[]
            var linkedentitys=[]
            var linkedinheritance=[]
            var uploadedFiles={}
            var results = action.collectionInfo.data.data
            if(results !== undefined) {
                info = results;
            }
            if(results !== undefined) {
                uploadedFiles = {
                    originalfileName: results.filetitle,
                    fileName : results.filename,
                    fileSize : results.filesize,
                    fileType : results.filetype,
                }
            }
            if(results.linkedentityinfo.length > 0) {
                results.linkedentityinfo.map((entry, index) => {
                    if(entry.category === 'entity') {
                        linkedentitys[index] = {};
                        linkedentitys[index]['id'] = entry.collectionid;
                        linkedentitys[index]['name'] = entry.collectionname;
                        linkedentitys[index]['category'] = entry.category;
                    }
                    if(entry.category === 'collection') {
                        linkedentitys[index] = {};
                        linkedentitys[index]['_id'] = entry.collectionid;
                        linkedentitys[index]['title'] = entry.collectionname;
                        linkedentitys[index]['category'] = entry.category;
                    }
                });
            }
            if(results.inheritanceinfo.length > 0) {
                results.inheritanceinfo.map((entry, index) => {
                    if(entry.category === 'entity') {
                        linkedinheritance[index] = {};
                        linkedinheritance[index]['id'] = entry.collectionid;
                        linkedinheritance[index]['name'] = entry.collectionname;
                        linkedinheritance[index]['category'] = entry.category;
                    }
                    if(entry.category === 'collection') {
                        linkedinheritance[index] = {};
                        linkedinheritance[index]['_id'] = entry.collectionid;
                        linkedinheritance[index]['title'] = entry.collectionname;
                        linkedinheritance[index]['category'] = entry.category;
                    }
                });
            }
            return {
                ...state,
                collectionInfo: info,
                linkedentitys:linkedentitys,
                linkedinheritance:linkedinheritance,
                uploadedFiles:uploadedFiles
            };
            break;
        case "GET_COLLECTION_INFO_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};

const formsCatagory = (state = {getForms:{data:[]}}, action) => {
    switch (action.type) {

        case "LIST_ALL_FORMS_CATEGORY":
            return {
                ...state,
                getForms: action.getForms.data,

            };
            break;
        default:
            return state;
    }
};
const getFormData = (state = {cform:[],initialComponents}, action) => {
   let  _newstate=[]
    let _component=[]
    let page={
        "type": "single",
        "pages": [
            {
                "heading": "Page 1",
                "masterid": 0,
                "components": [

                ],
                "visibility": true
            }
        ]
    }
    switch (action.type) {
        case "UPDATE_FORM_DATA":
            return {
                ...state,
                cform: action.cform.length !==0?action.cform: page,
            };
            break;
        case "UPDATE_FORM_COMPONENT":
            _newstate={...state};
            _component={...state};
            let random=Math.floor(Math.random() * 1000000) + 1
            let initialComponents= {
                "id":"form_child_"+random,
                "type":action.txt,
                "masterid":random,
                "name":"form_child_"+random,
                "placeholder":"Placeholder",
                "label":action.txt,
                "display_label":"",
                "option":[],
                "child":[],
                "externallink":"",
                "externalfield":[],
                "searchable":"Yes",
                "showlist":"Yes",
                "addmuliple":"Yes",
                "linked":"No",
                "watchable":"No",
                "errormsg":"This field is mandatory!",
                "removable":"Yes",
                "visibility":"visible",
                "setcondition":"No",
                "parentid":"",
                "fieldsetview":"",
                "hint":"",
                "fieldcondition":[],
                "conditionalhide":"Yes",
                "min":"No",
                "max":"No",
                "aggregation":"No",
                "multipleselection":"No",
                "prefix":"",
                "range":0,
                "textsize":100,
                "predefined":"",
                "text_random":"Yes",
                "custom_filter":"No",
                "filter_option":[],
                "days_filter":"No",
                "days_filter_label":"",
                "dayfilter_option":[],
                "formula":[],
                "dashbord_visibility":"No",
                "questionlabel":"Yes",
                "encryption":"",
                "uniquevalue":"",
                "alphabetsonly": "No",
                "characterlimit": "",
                "numberlimit": "",
                "validation": "No",
                "alphabetscase": "",
                "allowspace": "No",
                "optionstatus": "textoption",
                "setdefaultval":"Yes",
                "displaytext":''
            }

           _newstate["cform"]['pages'][action.activePage]["components"]= _newstate["cform"]['pages'][action.activePage]["components"].concat(initialComponents)
            return _newstate
            break;
        case "UPDATE_LABEL_CHANGE":
            _newstate={...state};
            let index= _newstate["cform"]['pages'][action.param.activePage]["components"].findIndex(list=>list.id===action.param.id)
            _newstate["cform"]['pages'][action.param.activePage]["components"][index][action.param.name]=action.param.value
            return _newstate
            break;

        case "UPDATE_OPTION_CHANGE":
            _newstate={...state};
            let oId= _newstate["cform"]['pages'][action.param.activePage]["components"].findIndex(list=>list.id===action.param.id)
            _newstate["cform"]['pages'][action.param.activePage]["components"][oId]["option"]=action.param.value
            return _newstate
            break;


        default:
            return state;
    }
};

const getCform = (state = {getForms:{data:[]}}, action) => {
    switch (action.type) {

        case "LIST_FORM_BUILDER_DATA":
            return {
                ...state,
                getForms: action.getForms.data,

            };
            break;
        default:
            return state;
    }
};


const getlistFormData = (state = {formvalues: [], fieldid : '',id:"", meta:[] }, action) => {
    switch (action.type) {
        case "GET_FORM_LIST_DATA":
            return {
                ...state,
                formvalues: action.formvalues,
                meta:action.meta,
                id:action.id,
            };
            break;
        case "RESET_FORM_LIST_DATA":
            return {
                ...state,
                formvalues: [],
                meta:[],
                fieldid : '',
                id:""

            };
            break;
        case "GET_FORM_LIST_DATA_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        case "VALUE_EXISTS" :

            return {
                ...state,
                fieldid: action.fieldid,
                [action.fieldid+"_valueExists"] : action[action.fieldid+"_valueExists"],
                recordid : action.recordid,
                value : action.value
            }
            break;

        case "RESET_VALUE_EXISTS" :

            return {
                ...state,
                [action.fieldid+"_valueExists"] : 'no',
            }
        case "GET_RECORD_ID_UNIQUEID" :
            return {
                ...state,
                formRecordId : action.formRecordId
            }
        default:
            return state;
    }
};


const showFormModal = (state = {showformdata:[], template :1,templateInfo : [], status:"", width : 960, thankyoustatus:"", thankyoucontent:"", thankyoulink:"", header:[], footer:[], getformcomponents :[],computedfield:[]}, action) => {
    switch (action.type) {
        case "SHOW_FORM_META_FORM": //console.log(action.showformdata);
            return {
                ...state,
                showformdata: action.showformdata,
                template : action.template,
                templateInfo : action.templateInfo,
                width : action.width,
                status:action.status,
                thankyoustatus : action.thankyoustatus,
                thankyoulink : action.thankyoulink,
                thankyoucontent:action.thankyoucontent,
                header:action.header,
                footer:action.footer
            };
        case "RESET_FORM_LIST_DATA" :
            return {
                ...state,
                showformdata:[],
                template :1,
                templateInfo : [],
                status:"",
                width : 960,
                thankyoustatus:"",
                thankyoucontent:"",
                thankyoulink:"",
                header:[],
                footer:[],
                getformcomponents :[],
                computedfield:[]

            }


        case "GET_FORM_METAFORM" :
            return {
                ...state,
                getformcomponents: action.getformdata,
            }
        case "GET_AGGREGATE_DATA" :
            return {
                ...state,
                aggData: action.aggData,
                refparams : action.refparams,
                computedfield:action.computedfield
            }
        default:
            return state;
    }
};


export const Forms = combineReducers({
    listFormsdata,
    getFormData,
	GetCollectionInfoDet,
    getCform,
    getlistFormData,
    showFormModal
});

export default Forms;


