import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import LoaderSvg from "../LoaderSVG";
import LineChartCard from "../General/LineChartCard";
import axios from "axios";
import {API_PATH} from "../../../constants";
import AreaLineChart from "../General/AreaLineChart";
import BarChartCard from "../General/BarChartCard";

/*const linedata = [
    {
        "name": "Q1 2008",
        "ratio": 13
    },
    {
        "name": "Q2 2008",
        "ratio": 50
    },
    {
        "name": "Q3 2008",
        "ratio": 100
    },
    {
        "name": "Q4 2008",
        "ratio": 45
    },
    {
        "name": "Q1 2009",
        "ratio": 78
    },
    {
        "name": "Q2 2009",
        "ratio": 20
    },
    {
        "name": "Q3 2009",
        "ratio": 5
    },
    {
        "name": "Q4 2009",
        "ratio": 37
    },
    {
        "name": "Q1 2010",
        "ratio": 10
    }
]*/
class BarChartTile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //linedata : []
            //loading : 0
        }
    }

    componentDidMount() {
        // this.setState({loading: 1});
        var date = new Date();
        var timestamp = date.getTime();

        var obj = this;

        // Send an HTTP  request
        axios({
            method: "GET",
            url :"https://apir4dev.bsynapse.com/project/datacard?collectionid=2_626794774756&fieldid=563147825&index=ibtstaging&graph=true&x=731198562&y=563147825&z=563147825",
            async:false,
            headers : {'Content-Type' : 'application/json'}
        }).then(function(res){
            var carddata = res.data.results.data;
            var aggregatevalue = carddata.aggregation;
            obj.setState({bardata : carddata.graph});
            return res;


        }).catch(function(err){
            //console.log("error");
            //console.log(err);
            return err;
        });
    }

    render() {
        return (
            <div className="ibt-progress-table">
                <div className="ibt-progress-table-header">
                    <h5>Areas of Impacts</h5>
                </div>
                <div className="ibt-progress-table-body">
                    <BarChartCard data={this.state.bardata}/>
                </div>
            </div>
        )
    }

}

export default BarChartTile;