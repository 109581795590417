import React, {Component} from 'react';
import {Card,Tooltip,OverlayTrigger, Button} from "react-bootstrap";
import Resources from "../resources/Resources";
class WhiteModal extends Component {

    constructor(props) {
        super(props);
        this.Minimize=this.Minimize.bind(this)
        this.Maximise=this.Maximise.bind(this)
        this.state={
            miniMize:false,
            Maximise:false,
            Close:false,
            showSave : true

        }
    }

    Minimize(){
        this.setState({Maximise:false,miniMize:true})
    }
    Maximise(){
        if(!this.state.miniMize) {
            this.setState({Maximise: !this.state.Maximise, miniMize: false})
        }
        else{
            this.setState({Maximise: false, miniMize: false})
        }
    }

    render() {
        return (

            <div className={`cs-modal fade bsy-modal white-modal full-modal-res in show `}>
                <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="task-head-text">Resources</h5>
                            <div className="modal-header-options">
                                <button className="modal-option-button"  onClick={this.props.openResourceModal}>
                                    <span className="icon-in icon-close-in"></span>
                                </button>
                            </div>
                        </div>

                        <div className="modal-body">
                         <Resources/>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}



export default WhiteModal;


