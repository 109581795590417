import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import SimpleModal from "./SimpleModal";
import {getUrlSegment} from "../../utilities/CustomFunctions";

export class GoogleMaP extends Component {
    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        status:false
    };
    componentDidMount() {
        console.log(1)
        this.setState({lat:this.props.lat,lng:this.props.lng})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.lat !== prevProps.lat) {
            this.setState({lat:this.props.lat})
            console.log(323232)
        }
        if(this.props.lng !== prevProps.lng) {
            this.setState({lng:this.props.lng})
            console.log(44545)
        }
    }
    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            status:true
        });
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };
    Close=()=>{
        this.setState({status:!this.state.status})
    }
    render() {
        console.log(this.state.lat)
        console.log(this.state.lng)
        console.log(3)
        return (
            <div className="map-container" style={{ height: this.props.type === "vertical"?'262px':"105px", width: '100%' }}>
                {(this.state.lat != '' && this.state.lng != '') && (this.state.lat != null && this.state.lng != null) && (this.state.lat != undefined && this.state.lng != undefined) &&
                <Map google={this.props.google}
                     style={{height: this.props.type === "vertical" ? '262px' : "105px", width: '100%'}}
                     onClick={this.onMapClicked}
                     className={'map'}
                     zoom={this.props.zoom ? parseInt(this.props.zoom) : 10}
                     initialCenter={{
                         lat: this.state.lat ? (this.state.lat) : 31.087411,
                         lng: this.state.lng ? (this.state.lng) : 75.471643
                     }}
                >
                    <Marker
                        // onClick={this.onMarkerClick}
                        // title={'Sidhwan Project'}
                        // name={'Sidhwan Project'}
                        position={{
                            lat: this.state.lat ? (this.state.lat) : 31.087411,
                            lng: this.state.lng ? (this.state.lng) : 75.471643
                        }}/>


                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        position={{
                            lat: this.state.lat ? (this.state.lat) : 31.087411,
                            lng: this.state.lng ? (this.state.lng) : 75.471643
                        }}>
                        <div>
                            <h1>{this.state.selectedPlace.name}</h1>
                        </div>
                    </InfoWindow>


                </Map>
                }

            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyBXqInkJv9wzIEUY1jb5qUMdVPrC6Dq5LM")
})(GoogleMaP)