import React,{Component} from 'react';
import Dropzone from 'react-dropzone';
import {AttachmentPopupPreview} from "../pages/chat/ChatData"


class DropZone extends Component {
    constructor() {
        super();

        this.state = {
            files: []
        };
        this.onDrop=this.onDrop.bind(this)
        this.onSubmit=this.onSubmit.bind(this)
        this.removeFile=this.removeFile.bind(this)
    }
    onDrop = (files) => {
        let totalSize=0;
        for (var i = 0; i < files.length; i++) {
            totalSize = totalSize + files[i].size;

        }
          this.setState({files,totalSize})
        if(this.props.hasOwnProperty("AutoUpdate") && this.props.AutoUpdate){
            this.onSubmit()
       }
    };
    onSubmit(){
        this.props.onSubmit(this.state.files,this.state.totalSize,"uploaded")

    }
    removeFile(i){
        let options = [...this.state.files];
        options.splice(i, 1);
        ////////////console.log(options)
        let Size=0;
        for (var i = 0; i < options.length; i++) {

            Size = Size + options[i].size;

        }
        this.setState({files:  options ,totalSize:Size})
        if(this.props.hasOwnProperty("AutoUpdate") && this.props.AutoUpdate){
            this.props.onSubmit(options,Size,"removed")
        }

    }
    render() {

      let multipleFiles=this.props.hasOwnProperty("multipleFiles")?this.props.multipleFiles:true

        return (
            <>
            <Dropzone onDrop={this.onDrop}>
                {({getRootProps, getInputProps}) => (
                    <section className="drop-cont">
                        {this.state.files.length ===0 &&
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} multiple={multipleFiles}/>
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                        }
                        <aside>

                            <AttachmentPopupPreview
                                files={this.state.files}
                                removeFile={this.removeFile}
                            />

                        </aside>

                    </section>
                )}

            </Dropzone>
                {this.props.hasOwnProperty("AutoUpdate") && !this.props.AutoUpdate &&
                <div className="button-block">
                    <button type="button" className="general-btn" onClick={this.onSubmit}>Submit</button>
                </div>
                    }
                </>
        );
    }
}


export  {DropZone};