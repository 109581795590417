import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import {getProfile} from '../../utilities/AuthService';
import * as taskActions from "../../actions/ToDo";
import {ES_INDEX, FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH, SHARED_LOGIN, USRIMG_PATH, S3_URL} from "../../constants";
import {
    forceDownload,
    getFileIcon,
    getUrlSegment,
    timeConvertToDays
} from "../../utilities/CustomFunctions";
import * as usersActions from "../../actions/Users";
import ReactToPrint from 'react-to-print';
import DatePickerBox from "../views/DatePickerBox";
import UploadFile from "../../utilities/Upload/UploadFile";
import LoaderSvg from "../views/LoaderSVG";
let assigneestatus ='';
let time = "";
let shared = "";
let donedate = "";
let archived ="";
let taskmode=""
let taskData = "";
let assigneesData = ""
let logData = ""
let commentData = ""
let allfilesData = ""
let attachmentData = ""
let collectionData = ""
let timelogData = ""
let subtaskData = []
let remarksData = ""
let previewableAttachments = ""
let taskid =  ""
let deleteid = ''
let parent =""
let assignees = ""

class PrintWorkflowDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            log: [],
            date: '',
            hours: '',
            mins: '',
            taskid: '',
            rightTab: 'home-tab',
            taskstatus: "",
            action: '',
            timetaken: '',
            timeLogValues: [],
            childid: '',
            creator: '',
            assignee_status: '',
            attach: '',
            donedate: '',
            verified: 'no',
            disable_verify: 'disable',
            disable_markasdone: 'disable',
            doneStatus: '',
            previewIndex: 0,
            previewableAttachments: [],
            showFormModal: false,
            formPages: [],
            taskmode: '',
            fieldsToDisplay: [],
            timelogloading: 0,
            markasdoneloading: 0,
            verifyloading: 0,
            filename: null,
            uploadedFiles: [],
            remark: '',
            reOpenSubmit: false,
            archive_action: '',
            archived: '',
            previewType: '',
            attach_index: '',
            consolidated_time: '',
            assignee: '',
            fileData: [],
            reference: '',
            mark_as_done_comment_array: [],
            markasDoneTemp: false,
            markasdonedata: '',
            verifytaskdata: '',
            reOpenparam: '',
            dateChoosen: false,

        }
        this.goBack = this.goBack.bind(this)
    }

    componentDidMount() {
        this.setTaskStatuses();
        let params = {
            "_id": atob(getUrlSegment(3)),
            "uid": getProfile().id,
            "systype": getProfile().usertype,
            "index": ES_INDEX,
            "mode": atob(getUrlSegment(4)),
        }
////////console.log(params)
        this.props.getPrintTask(params);
        let data = {"index" : ES_INDEX}
        this.props.enterprise(data);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if (prevProps.Getprinttask.results !== this.props.Getprinttask.results) {
            let printDetailData = this.props.Getprinttask.results  ;
            let task =  printDetailData.task ;
            let collectionid = task !== '' ? task.collecteddataid : '';
            let param = {"_id" : task.collecteddataid, "collectionid" : task.collecteddata}
            this.props.selectedCollection(param);
        }
        if (this.props.taskdetailsData.taskdetails.parent.masterid !== prevProps.taskdetailsData.taskdetails.parent.masterid) {
            this.setTaskStatuses()
            this.props.modifyTaskTodefault()
        }

        if (prevProps.HistoryPushData !== this.props.HistoryPushData) {
            this.setState({open: false})
        }

    }

    timeConvertToDays(totalminutes) {
        var exactdays = totalminutes / (60 * 24);
        var days = parseInt(exactdays);

        ////////console.log(days);

        var remaininghours = totalminutes % (60 * 24);
        ////////console.log("remaininghours " + remaininghours);
        var exacthours = remaininghours / 60;
        var hours = parseInt(exacthours);

        var exactminutes = remaininghours % 60;
        var minutes = parseInt(exactminutes);


        var totaltime = '';
        if (days > 0) {
            totaltime = days + " Day" + (days > 1 ? "s " : ' ');
        }
        if (hours > 0) {
            totaltime += hours + " Hr" + (hours > 1 ? "s " : ' ');
        }
        if (minutes > 0) {
            totaltime += minutes + " Min" + (minutes > 1 ? "s " : ' ');
        }

        return totaltime;
    }

    preview(url, name) {
        this.setState({url: url, name: name, previewStatus: !this.state.previewStatus})
    }

    setTaskStatuses() {
        let parent = this.props.taskdetailsData.taskdetails.parent;
        if (parent) {
            if (parent.reassign === 'yes') {
                let uid = ''
                if (parent.createuser !== getProfile().id) {
                    uid = getProfile().id
                }
                this.loadmoreData({taskid: parent.masterid, uid: uid});
            }
            let tasktype = parent.tasktype;
            this.setState({
                tasktype: tasktype,
                taskmode: (parent.createuser === getProfile().id) ? 'by-me' : '',
                disable_verify: (parent.status === 'Done') ? '' : 'disable',
                taskid: parent.masterid,
                verified: parent.verified ? parent.verified : 'no',
                archived: (parent.createuser === getProfile().id && parent.archive === 'yes') ? 'yes' : 'no'
            })
        }

        let assignees = this.props.taskdetailsData.taskdetails.assignees;
        assignees = assignees.filter(
            user => user.assignee.search(getProfile().id) >= 0
        );
        //////////console.log(getProfile().id)
        //////////console.log(assignees)


        if (assignees.length > 0) {

            let assigneestatus = assignees[0].status;
            let time = assignees[0].consolidated_time;
            let shared = assignees[0].share;
            let donedate = assignees[0].donedate;
            let archived = assignees[0].archive;
            this.setState({
                assignee_id: assignees[0].assignee,
                childid: assignees[0].childid,
                assignee_status: assigneestatus,
                shared: shared,
                consolidated_time: time,
                donedate: donedate,
                archived: archived
            })

            ////////////console.log(this.state.consolidated_time)
        }

    }

    handleinputChange(e) {
        this.setState({[e.target.name]: e.target.value})

        if (e.target.value !== '') {
            e.target.classList.remove('val-error');

        }
    }

    loadmoreData = (params) => {
        this.props.getmoretaskdetails(params)
    }
    viewParentTask(id) {
        this.props.history.push('/task/details/' + btoa(id) + '/' + btoa('to-me'))
    }

    OnlineUsers(val) {
        var OnlineStatus = this.props.onlineusers.users.filter(p => val === p.User_ID);
        if (OnlineStatus.length !== 0)
            return true;
        else
            return false;
    }
    getAttachmentPath=(type)=> {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }
    goBack() {
        if ((getUrlSegment(5) !== undefined) || getUrlSegment(6) !== undefined) {
            if (atob(getUrlSegment(5) !== "project")) {
                window.location.href = SHARED_LOGIN.redirect_url + "Project/details/" + atob(getUrlSegment(6)) + "#proTask"
            } else {
                this.props.history.goBack();
            }
        } else
            this.props.history.goBack();
    }
    createMarkupeditor=(value)=>{
        let editorvalue = value;
        return {__html: editorvalue};
    }

    render() {
        ////////console.log(this.props.Getprinttask)
        let printDetailData = this.props.Getprinttask.results;
        console.log(printDetailData)
        let task = printDetailData.task ;
        let subtask = printDetailData.subtask ;
        let assignees = printDetailData.assignees;
        let discussion = printDetailData.discussion;
        let activities = printDetailData.activities;
        let timelog = printDetailData.timelog;
        let previoustasks = printDetailData.previoustasks;
        let stagestatus = printDetailData.stagestatus;
        let formDataCollection = this.props.Getselectedcollection.results.length !== 0 ? this.props.Getselectedcollection.results : '';
        let dueDateChange=new Date(task.duedate);
        // let dueDateChange=moment.utc(task.duedate).local().format('YYYY-MM-DD')
        let enterpriseData = this.props.GetEnterprise.results.length !== 0 ? this.props.GetEnterprise.results : '';
        console.log(formDataCollection)
        return (
            <React.Fragment>
            <div className={`print-holder workflow-print-form wf-print-ui`}>
                {enterpriseData !== '' &&
                <div className="workflow-task-header">
                    {/*<div className="workflow-task-header-thumb">*/}
                    {/*    <img src={enterpriseData.logo}/>*/}
                    {/*</div>*/}

                    <div className="workflow-task-header-details">
                        <h5>{enterpriseData.organization_name}</h5>
                        <p>{enterpriseData.organization_address}</p>
                    </div>
                </div>
                }
                {this.props.Getselectedcollection.loader === true &&
                <div className={`holder-adjust`}>
                <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                </div>
                }
                {formDataCollection !== '' &&
                <React.Fragment>
                    <div className="subheader-task main-header">
                        <p>{task.collectedformtitle ? atob(task.collectedformtitle) : ''}</p>
                    </div>
                    {formDataCollection.map((item, index) =>
                    <React.Fragment>
                        {item.type === 'formheading' &&
                        <div className="subheader-task workflow-form-step" key={index}>
                            <p>{item.label}</p>
                        </div>
                        }
                        {item.type === 'textarea' &&
                        <table className="table preview-table field-table" key={index}>
                            <tbody id="">
                            <tr>
                                <th>{item.label}</th>
                                <td>
                                    {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                                        <span key={index}>{list.value}</span>
                                    )}
                                </td>

                            </tr>
                            </tbody>
                        </table>
                        }
                        {item.type === 'editor' &&
                        <table className="table preview-table field-table" key={index}>
                            <tbody id="">
                            <tr>
                                <th>{item.label}</th>
                                <td>
                                    {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                                        // <span key={index}>{atob(list.value)}</span>
                                        <span key={index} dangerouslySetInnerHTML={this.createMarkupeditor(list.value)} ></span>
                                    )}
                                </td>

                            </tr>
                            </tbody>
                        </table>
                        }
                        {item.type !== 'formheading' && item.type !== 'textarea' && item.type !== 'editor' && item.type !== 'fieldset' &&
                        <table className="table preview-table" key={index}>
                            <tbody id="colectionInfo">
                            <tr>
                                <th>{item.label}</th>
                                <td>
                                    {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                                        <span key={index}>{list.value}</span>
                                    )}
                                </td>

                            </tr>
                            </tbody>
                        </table>
                        }

                        {item.type === 'fieldset' &&
                            <React.Fragment>
                            <div className="subheader-task workflow-form-step" key={index}>
                                <p>{item.label}</p>
                            </div>

                            {item.values.length !== 0 && item.values !== '' && item.values.map((data, index) =>
                                <>
                                    {data.type === 'formheading' &&
                                    <div className="subheader-task workflow-form-step" key={index}>
                                        <p>{data.label}</p>
                                    </div>
                                    }
                                    {data.type === 'textarea' &&
                                    <table className="table preview-table field-table" key={index}>
                                        <tbody id="">
                                        <tr>
                                            <th>{data.label}</th>
                                            <td>
                                                {data.values.length !== 0 && data.values !== '' && data.values.map((list, index) =>
                                                    <span key={index}>{list.value}</span>
                                                )}
                                            </td>

                                        </tr>
                                        </tbody>
                                    </table>
                                    }
                                    {data.type === 'editor' &&
                                    <table className="table preview-table field-table" key={index}>
                                        <tbody id="">
                                        <tr>
                                            <th>{data.label}</th>
                                            <td>
                                                {data.values.length !== 0 && data.values !== '' && data.values.map((list, index) =>
                                                    // <span key={index}>{atob(list.value)}</span>
                                                    <span key={index} dangerouslySetInnerHTML={this.createMarkupeditor(list.value)} ></span>
                                                )}
                                            </td>

                                        </tr>
                                        </tbody>
                                    </table>
                                    }
                                    {data.type !== 'formheading' && data.type !== 'textarea' && data.type !== 'editor' && data.type !== 'fieldset' &&
                                    <table className="table preview-table" key={index}>
                                        <tbody id="colectionInfo">
                                        <tr>
                                            <th>{data.label}</th>
                                            <td>
                                                {data.values.length !== 0 && data.values !== '' && data.values.map((list, index) =>
                                                    <span key={index}>{list.value}</span>
                                                )}
                                            </td>

                                        </tr>
                                        </tbody>
                                    </table>
                                    }
                                </>
                            )}

                        </React.Fragment>
                        }

                    </React.Fragment>
                    )}
                </React.Fragment>
                }
                {task.length !== 0 &&
                <div className="task-detail-panel">
                    {/*<div className="task-details-header-block">*/}
                    {/*    <div className="task-detail-header-laeft">*/}
                    {/*        <button className="task-mob-back"></button>*/}
                    {/*        <ul className="task-details-info-wrap">*/}
                    {/*            <li><p>DUE ON </p>*/}
                    {/*                <h5>{task.duedate !== "0000-00-00 00:00:00" ? moment.utc(task.duedate).local().format('DD MMM YYYY hh:mm a').toUpperCase() : ''}</h5>*/}
                    {/*            </li>*/}
                    {/*            <li><p>PRIORITY</p><h5>{task.priority.toUpperCase()}</h5></li>*/}
                    {/*            {task.projectname !== '' &&*/}
                    {/*            <>*/}
                    {/*                <li><p>PROJECT</p><h5>{task.projectname.toUpperCase()}</h5></li>*/}
                    {/*            </>*/}
                    {/*            }*/}
                    {/*            {task.workFlowname !== '' &&*/}
                    {/*            <>*/}
                    {/*                <li><p>WORKFLOW</p><h5>{(atob(task.workFlowname)).toUpperCase()}</h5></li>*/}
                    {/*            </>*/}
                    {/*            }*/}

                    {/*        </ul>*/}
                    {/*    </div>*/}

                    {/*    <div className="task-detail-header-right">*/}
                    {/*        <div className="task-other-opt">*/}
                    {/*            {task.status === "Done" &&*/}
                    {/*            <div className="comp-task">COMPLETED TASK</div>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="subheader-task workflow-form-step">
                        <p>WorkFlow Details</p>
                    </div>





                    {/*<div className="task-details-header-block">*/}
                    {/*    <div className="task-detail-header-laeft">*/}
                    {/*        <button className="task-mob-back"></button>*/}
                    {/*        <ul className="task-details-info-wrap">*/}
                    {/*            <li><p>DUE ON </p>*/}
                    {/*                <h5>{task.duedate !== "0000-00-00 00:00:00" ? moment.utc(task.duedate).local().format('DD MMM YYYY hh:mm a').toUpperCase() : ''}</h5>*/}
                    {/*            </li>*/}
                    {/*            <li><p>PRIORITY</p><h5>{task.priority.toUpperCase()}</h5></li>*/}
                    {/*            {task.projectname !== '' &&*/}
                    {/*            <>*/}
                    {/*                <li><p>PROJECT</p><h5>{task.projectname.toUpperCase()}</h5></li>*/}
                    {/*            </>*/}
                    {/*            }*/}
                    {/*            {task.workFlowname !== '' &&*/}
                    {/*            <>*/}
                    {/*                <li><p>WORKFLOW</p><h5>{(atob(task.workFlowname)).toUpperCase()}</h5></li>*/}
                    {/*            </>*/}
                    {/*            }*/}

                    {/*        </ul>*/}

                    {/*    </div>*/}

                    {/*    <div className="task-detail-header-right">*/}
                    {/*        <div className="task-other-opt">*/}
                    {/*            {task.status === "Done" &&*/}
                    {/*            <div className="comp-task">COMPLETED TASK</div>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                    <div className="task-detail-panel-content padding-0"><span className=" ico-lib cleared  done-mark"></span>
                        <div className="task-detail-panel-content-area">
                            <div className="task-detail-panel-content-area-left">
                                {/*<p className={`stage-header`}>WORKFLOW : {(atob(task.workFlowname)).toUpperCase()}</p>*/}
                                {/*<p className={`stage-header`}>STAGE : {task.stagename}</p>*/}

                                <table className="table preview-table">
                                    <tbody id="colectionInfo">
                                    <tr>
                                        <th>WORKFLOW : </th>
                                        <td>
                                            <span>{(atob(task.workFlowname)).toUpperCase()}</span>
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>

                                <table className="table preview-table">
                                    <tbody id="colectionInfo">
                                    <tr>
                                        <th>STAGE : </th>
                                        <td>
                                            <span>{task.stagename}</span>
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>

                                <h5>{decodeURIComponent(escape(atob(task.subject)))}</h5>
                                <p dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(atob(task.Title))).replace(/\n/g, '<br />')}}/>
                            </div>
                            {/* <div className="due-date-block">
                                {task.status === "Done" &&
                                <React.Fragment>
                                    <p>COMPLETED ON </p>
                                    <p className="f-b">
                                        {moment.utc(task.donedate).local().format('DD MMM YYYY hh:mm a')}
                                    </p>
                                </React.Fragment>
                                }
                            </div> */}
                        </div>
                        {/*<div className="task-submit-block">*/}
                        {/*    <div className="working-time-section"></div>*/}
                        {/*</div>*/}
                    </div>

                </div>
                }

                {/*{task.length !== 0 && task.collectedformtitle !== '' &&*/}
                {/*    <React.Fragment>*/}
                {/*    <div className="subheader-task">*/}
                {/*        <p>1. PREVIEW THE FORM</p>*/}
                {/*    </div>*/}
                {/*    <div className="task-details-body-block sub-block">*/}
                {/*        <div className="workflow-task-body-container">*/}
                {/*            <div className="nested-sub-block">*/}
                {/*                <div className="stage-name">*/}
                {/*                    <div className="ico-lib-v1 nested-form"></div>*/}
                {/*                    <div className="nested-form-content">*/}
                {/*                        <h5>{atob(task.collectedformtitle)}</h5>*/}
                {/*                        <p>{atob(task.collectedformtitle)}</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    </React.Fragment>*/}
                {/*}*/}


                {task && task.meta_form && task.meta_form.length > 0 &&
                <React.Fragment>
                <div className="subheader-task">
                    <p>Submitted Data(Current Stage)</p>
                </div>
                <div className="task-details-body-block sub-block">
                    <div className="workflow-task-body-container">
                        <div className="nested-sub-block">
                            {task.meta_form.map((meta, index) =>
                                <React.Fragment>
                                    {meta.type !== "attachment" &&
                                    <div className="input-block" key={index}>
                                        <h5>{meta.label}</h5>
                                        {meta.type === "checkbox" &&
                                        <ul className="select-lister">
                                            {meta.options !== null && meta.options !== undefined && meta.options.map((option, opt_index) => {
                                                    let checkedVal = meta.value && meta.value.length !== 0 ? meta.value.filter(item => item === option) : false;
                                                    return (
                                                        <li>
                                                            <div className="select-chk-block"
                                                                 style={{display: "block"}}>
                                                                <label className="control control-checkbox">
                                                                    {option}
                                                                    <input type="checkbox"
                                                                           name={meta.id}
                                                                           className="inp-chk" value={option}
                                                                           required={meta.required === 'yes' ? true : false}
                                                                           defaultChecked={checkedVal}
                                                                           disabled="disabled"

                                                                    />
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                            )}

                                        </ul>
                                        }
                                        {meta.type === "switch" &&
                                        <ul className="select-lister">
                                            {meta.options !== null && meta.options !== undefined && meta.options.map((option, option_index) =>
                                                <li key={option_index}>
                                                    <div className="radio-block">
                                                        <input type="radio" id="singlestep" className="off_type"
                                                               name={meta.id} value={option}
                                                               required={meta.required === 'yes' ? true : false}
                                                               checked={(meta.value ? (meta.value[0].value === option) : false)}
                                                               disabled="disabled"
                                                        />
                                                        <label htmlFor="singlestep">{option}</label>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                        }
                                        {meta.type === "text" &&
                                        <input type="text" name={meta.id} className="input-box"
                                               placeholder="Input here"
                                               required={meta.required === 'yes' ? true : false}
                                               defaultValue={meta.value ? meta.value[0].value : ''}
                                               disabled="disabled"
                                        />
                                        }
                                        {meta.type === "dropdown" &&
                                        <div className="select-box">
                                            <select name={meta.id} disabled="disabled"
                                                    required={meta.required === 'yes' ? true : false}
                                                    value={meta.value ? meta.value[0].value : ''}>
                                                <option value="">Select</option>
                                                {meta.options !== null && meta.options !== undefined && meta.options.map((option) =>
                                                    <option value={option}>{option}</option>
                                                )}
                                            </select>
                                        </div>
                                        }
                                        {meta.type === "datepicker" &&
                                        <div className="input-box date-box " style={{background: "white"}}>

                                            <DatePickerBox
                                                startDate={meta.value ? new Date(meta.value[0].value) : dueDateChange}
                                                id={meta.id} name={meta.id}
                                                required={meta.required === 'yes' ? true : false}
                                                index={index}/>

                                        </div>
                                        }
                                        {meta.type === "number" &&
                                        <input type="number" name={meta.id} className="input-box"
                                               placeholder="Input here"
                                               required={meta.required === 'yes' ? true : false}
                                               defaultValue={meta.value ? meta.value[0].value : ''}
                                               disabled="disabled"
                                        />
                                        }
                                        {meta.type === "textarea" &&
                                        <textarea disabled="disabled" className="textarea" name={meta.id}
                                                  placeholder="textarea"
                                                  required={meta.required === 'yes' ? true : false}
                                                  defaultValue={meta.value ? meta.value[0].value : ''}></textarea>
                                        }

                                        {/*<label className={"form-error-text form-error-label "+ ((Props.state.submitted && (!Props.state[meta.id] || Props.state[meta.id]=== '') && meta.required=== 'yes')?" " : "hide")}> Please fill the field</label>*/}
                                    </div>
                                    }
                                    {meta.type === "attachment" &&
                                    <React.Fragment>
                                        <div className="attach-block disabled">
                                            <div id="todoFileAssets" className="file-upload-wf">
                                                <label className="ico-lib att">
                                                    {meta.label}
                                                </label>
                                            </div>
                                            {/*<label className={"form-error-text form-error-label "+ ((Props.state.submitted && meta.required=== 'yes' && (Props.state.uploadedFiles.length === 0)?"" : "hide"))}> Please attach files</label>*/}
                                        </div>
                                        <div className="attached-file round-edge">
                                            {/*this.state.uploadedFiles && this.state.uploadedFiles.map((item, opt_index) =>
                                                <div className="file-tag" id={"attachment_"+item.fileId}>{item.originalfileName} <button className="ico-lib cl-fl" onClick={(e)=>this.removeSelectedAttachment(opt_index)}></button>
                                                </div>
                                            )*/}
                                            {meta.value !== null && meta.value !== undefined && meta.value.map((value, opt_index) =>
                                                <div className="file-tag"
                                                     id={"attachment_" + value.fileId}>{value.value}
                                                    <button className="ico-lib cl-fl"></button>
                                                </div>
                                            )}
                                        </div>

                                    </React.Fragment>
                                    }
                                </React.Fragment>
                            )}
                            {/*{(Props.indAssignee.length !== 0 && Props.indAssignee[0].status !== "Done") && getUrlSegment(1) !== "workflowci" &&*/}
                            {/*<React.Fragment>*/}
                            {/*    {Props.state.metasubmitted === false &&*/}
                            {/*    <div className="btn-section-left">*/}
                            {/*        <button className="general-btn-green ico-lib verify-button"*/}
                            {/*                onClick={(e) => Props.submitWorkFlowAction(e, '')}>{Props.submissiontype === "withoutdecision" ? "Mark As Done" : "Proceed"}*/}
                            {/*        </button>*/}

                            {/*        {Props.list.poolenabled === true && Props.submissiontype === "withoutdecision" &&*/}
                            {/*        <button className="general-btn-green ico-lib quit"*/}
                            {/*                onClick={() => Props.quitPoolTaskConfirm('quit')}>Return to Task Pool*/}
                            {/*        </button>*/}
                            {/*        }*/}
                            {/*    </div>*/}
                            {/*    }*/}
                            {/*    {Props.state.metasubmitted === true &&*/}
                            {/*    <div className="btn-section-left">*/}
                            {/*        <button className="general-btn-green ico-lib verify-button"*/}
                            {/*                onClick={(e) => Props.submitWorkFlowAction(e, '')}>Edit*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*    }*/}
                            {/*</React.Fragment>*/}
                            {/*}*/}
                        </div>
                    </div>
                </div>
                </React.Fragment>
                }

                {subtask.length !== 0 &&
                <React.Fragment>
                    <div className="subheader-task">
                        <p>Sub Tasks</p>
                    </div>
                    {subtask.map((listitem, index) =>
                        <div className="subtask-detail-panel" key={index}>

                            <div className="task-detail-panel" key={`subtask` + index}>
                                <div className="task-detail-panel-content">

                                    <div className="task-detail-user-info">
                                        <div className="task-detail-user-info-left">
                                            <div className="task-detail-user">
                                                <div className="task-detail-user-det">
                                                    <h5>{listitem.creator_name}</h5>
                                                    <div className="assign-line">
                                                        <span className="ico-lib-v1 to-sub-ico"></span>
                                                        {listitem.assignees.map((assignee, index) =>
                                                            <div className="assigned-tau-block single-user"
                                                                 key={'assignee' + assignee}>
                                                                <div className="tau">
                                                                        <span className="tau-img">
                                                                            <img
                                                                                src={USRIMG_PATH + assignee.assignee_img_name}/>
                                                                        </span>
                                                                    <span
                                                                        className="tau-name">{assignee.assignee_name}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-detail-user-info-right">
                                            <div className="due-date-block">
                                                <p>DUE ON</p>
                                                <p className="f-b">{moment(listitem.duedate).format('DD MMM YYYY hh:mm a')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="task-detail-panel-content-area">
                                        <h5>{atob(listitem.subject)}</h5>
                                        <p>{atob(listitem.title)}</p>
                                    </div>
                                </div>
                                <div className="individual-assigners">
                                    {listitem.assignees.map((key, index) =>
                                        <div className="ind-div-assign" key={index}>
                                            <div className="info-contianer">
                                                <div className="task-detail-user-info-left">
                                                    <div className="task-detail-user-thumb">
                                                        <img src={USRIMG_PATH + key.assignee_img_name}/>
                                                    </div>
                                                    <div className="task-detail-user-det">
                                                        <h5>{key.assignee_name}</h5>
                                                        <p>{key.status}</p>
                                                    </div>
                                                </div>
                                                {key.status === "Done" &&
                                                <div className="task-detail-user-info-right">
                                                    <div className="due-date-block">
                                                        <p>COMPLETED ON </p>
                                                        <p className="f-b">
                                                            {moment.utc(key.donedate).local().format('DD MMM YYYY')}
                                                        </p>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
                }

                {(assignees !== null && assignees.filter(listitem=>listitem.mark_as_done_comment !== undefined && listitem.mark_as_done_comment !== null && listitem.mark_as_done_comment.length).length  !==0) &&
                <React.Fragment>
                <div className="subheader-task">
                    <p>Completion Remarks</p>
                </div>
                <div className="completion-remarks-panel">
                    {assignees !== null && assignees.map((listitem, index) =>
                        listitem.mark_as_done_comment !== undefined && listitem.mark_as_done_comment !== null && listitem.mark_as_done_comment.length !== 0 &&

                        <div className="task-detail-panel" key={index}>
                            <div className="task-detail-panel-content">
                                <div className="task-detail-user-info">
                                    <div className="task-detail-user-info-left">
                                        <div className="task-detail-user">
                                            <div
                                                className={`task-detail-user-thumb ${this.OnlineUsers(listitem.user_name) ? 'online' : ''}`}>
                                                <img src={USRIMG_PATH + listitem.user_img_name}/>
                                            </div>
                                            <div className="task-detail-user-det">
                                                <h5>{listitem.user_name}</h5>
                                                {listitem.status === 'done' &&
                                                <p>
                                                    <div className={`info-task`}>Completed
                                                        on <span>{moment.utc(listitem.donedate).local().format('DD MMM YYYY hh:mm a')}</span>
                                                    </div>
                                                    <div className={`info-task`}>Time
                                                        Taken <span>{timeConvertToDays(listitem.consolidated_time)}</span>
                                                    </div>
                                                </p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="task-detail-user-info-right">*/}
                                    {/*    <div className="due-date-block">*/}
                                    {/*        <p>DUE ON</p>*/}
                                    {/*        <p className="f-b">{moment(listitem.duedate).format('DD MMM YYYY hh:mm a')}</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                {listitem.mark_as_done_comment !== undefined && listitem.mark_as_done_comment.map((item, index) =>
                                    <div className='remarks-list' key={`mark-as-done-comment` + index}>
                                        <p>{atob(item.discussion)}</p>

                                    </div>
                                )}
                            </div>

                        </div>

                    )}
                </div>
                </React.Fragment>
                }

                {discussion.length !== 0 &&
                <React.Fragment>
                <div className="subheader-task">
                    <p>Discussions</p>
                </div>
                <div className="discussion-detail-panel">
                    <div className="task-detail-panel">
                        <div className="task-detail-panel-content">
                            <div className="task-detail-user-info">
                                <div className="task-detail-user-info-left">
                                    <div className="task-detail-user">
                                        <div className="discuss">
                                            {discussion.map((item, index) =>
                                                <div className="message-in-box " key={index}>
                                                    <figure className="user-image-holder"><img
                                                        src={USRIMG_PATH + item.userhead}
                                                        alt="user-image" className="chat-user-image-in"/></figure>
                                                    <div className="chat-message-detailfalse"><h5
                                                        className="chat-user-name">{item.creator}</h5><span
                                                        className="chat-time-in">{moment.utc(item.crtdate).local().format("DD-MM-YYYY")}</span>
                                                        <p
                                                            className="chat-message-in">{atob(item.discussion)}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>
                }

                {/*{assignees.length !== 0 &&*/}
                {/*<React.Fragment>*/}
                {/*<div className="subheader-task">*/}
                {/*    <p>Users</p>*/}
                {/*</div>*/}
                {/*<div className="user-detail-panel">*/}
                {/*    <div className="task-detail-panel">*/}
                {/*        <div className="task-detail-panel-content">*/}
                {/*            <div className="task-detail-user-info">*/}
                {/*                <div className="task-detail-user-info-left">*/}
                {/*                    <div className="task-detail-user">*/}
                {/*                        {assignees.filter(item => (item.share === 'no')).length !== 0 &&*/}
                {/*                        <React.Fragment>*/}
                {/*                            <div className="user-tab-head">*/}
                {/*                                <h5> {`Assigned users`}</h5>*/}
                {/*                            </div>*/}
                {/*                            <div className="discuss-inner">*/}
                {/*                                {assignees.filter(item => (item.share === 'no')).map((item, index) =>*/}
                {/*                                    <React.Fragment key={"assignee-tab-" + index}>*/}
                {/*                                        <div className="assigned-block">*/}
                {/*                                            <div className="ind-assigned-user-thumb">*/}
                {/*                                                <a >*/}
                {/*                                                    <img title={item.user_name}*/}
                {/*                                                         src={USRIMG_PATH + item.user_img_name}*/}
                {/*                                                         alt=""/></a>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="ind-assigned-desc">*/}
                {/*                                                <h5>{item.user_name}</h5>*/}
                {/*                                                <p>{item.user_designation}</p>*/}
                {/*                                            </div>*/}
                {/*                                        </div>*/}
                {/*                                    </React.Fragment>*/}
                {/*                                )}*/}


                {/*                            </div>*/}
                {/*                        </React.Fragment>*/}
                {/*                        }*/}

                {/*                        {assignees.filter(item => (item.share === 'yes')).length !== 0 &&*/}
                {/*                        <React.Fragment>*/}
                {/*                            <div className="user-tab-head">*/}
                {/*                                <h5> {`Invited users`}</h5>*/}
                {/*                            </div>*/}
                {/*                            <div className="discuss-inner">*/}
                {/*                                {assignees.filter(item => (item.share === 'yes')).map((item, index) =>*/}
                {/*                                    <React.Fragment key={"assignee-tab-" + index}>*/}
                {/*                                        <div className="assigned-block">*/}
                {/*                                            <div className="ind-assigned-user-thumb">*/}
                {/*                                                <a href="javascript:void(0)">*/}
                {/*                                                    <img title={item.user_name}*/}
                {/*                                                         src={USRIMG_PATH + item.user_img_name}*/}
                {/*                                                         alt=""/></a>*/}
                {/*                                            </div>*/}
                {/*                                            <div className="ind-assigned-desc">*/}
                {/*                                                <h5>{item.user_name}</h5>*/}
                {/*                                                <p>{item.user_designation}</p>*/}
                {/*                                            </div>*/}
                {/*                                        </div>*/}
                {/*                                    </React.Fragment>*/}
                {/*                                )}*/}


                {/*                            </div>*/}
                {/*                        </React.Fragment>*/}
                {/*                        }*/}


                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        */}
                {/*    </div>*/}
                {/*</div>*/}
                {/*</React.Fragment>*/}
                {/*}*/}

                {previoustasks.length !== 0 &&
                <React.Fragment>
                <div className="subheader-task">
                    <p>Status / Decisions</p>
                </div>
                <div className="activities-detail-panel">
                    <div className="task-detail-panel">
                        <div className="task-detail-panel-content">
                            <div className="task-detail-user-info">
                                <div className="task-detail-user-info-left">
                                    <div className="task-detail-user">
                                    <div className="spliter-task-user">
                                        <div className="accordion stage-accordion milestone-type" id="accordionExample">
                                            {previoustasks.map((data, index) =>
                                            <div className="card" key={index}>
                                                <div className="card-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    <div className="stage-accord-header without-left-info">
                                                        <div className="stage-accord-header-right">
                                                            <h5>STAGE | {data.stagename}</h5>
                                                            <p>Completed On {moment.utc(data.donedate).local().format('DD MMM YYYY hh:mm a')}
                                                            {data.assignees && data.assignees.length > 0 &&
                                                            <span>{' By ' + data.assignees[0].user_name}{data.assignees[0].user_designation ? '(' + data.assignees[0].user_designation + ')' : ''}</span>
                                                            }
                                                            </p>
                                                        </div>
                                                        {data.assignees && data.assignees.length > 0 &&
                                                        // <div className="workflow-user-wrapper">
                                                            <div className="workflow-user-row" key={"user" + index}>
                                                                <div className="workflow-user-img">
                                                                    <img src={USRIMG_PATH + data.assignees[0].user_img_name}/>
                                                                </div>
                                                                <div className="workflow-user-details">
                                                                    <h5>{data.assignees[0].user_name}</h5>
                                                                    {data.assignees[0].user_designation !== null &&
                                                                    <p>{data.assignees[0].user_designation}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        // </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">

                                                    <div className="card-body">
                                                        <div className="workflow-task-check-block">
                                                            <div className="task-check-container">
                                                                {data.collection && data.collection.map((collectiondata, index) =>
                                                                    <React.Fragment key={"collecdata" + index}>
                                                                        <div className="ind-task-check">
                                                                            <div className="task-check-header">
                                                                                <h5 className="checkpoint checked">Verified Form</h5>
                                                                            </div>
                                                                            <div className="task-check-body">
                                                                                <h5 className="ico-lib form-small-icon">{atob(collectiondata.title)}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}
                                                                {data.meta_form.length > 0 &&
                                                                <div className="ind-task-check">
                                                                    {/*<div className="task-check-header">*/}
                                                                    {/*    <h5 className="checkpoint checked">Submitted Data</h5>*/}
                                                                    {/*</div>*/}
                                                                    <div className="task-check-body">
                                                                        <div className="split-data">
                                                                            {data.meta_form.map((metalist, index) =>
                                                                                    <div
                                                                                        className={(metalist.type === 'textarea' || metalist.type === 'text') ? "split-data-row textarea-block" : "split-data-row"}>
                                                                                        <div
                                                                                            className="split-data-left">{metalist.label}{(metalist.type !== 'textarea' && metalist.type !== 'text') && ''}
                                                                                        </div>
                                                                                        <div
                                                                                            className="split-data-right"> {metalist.value !== undefined && metalist.value !== null && metalist.value.map((values, index) => {
                                                                                                let contentPath = ''
                                                                                                if (metalist.type === 'attachment') {
                                                                                                    let iconClass = getFileIcon(values.uniqval);
                                                                                                    contentPath = this.getAttachmentPath(iconClass);
                                                                                                }
                                                                                                return (
                                                                                                    <React.Fragment>
                                                                                                        {metalist.type === 'attachment' &&
                                                                                                        <span
                                                                                                            onClick={(e) => forceDownload(contentPath + values.uniqval, values.value)}>
                                                                        {values.value}
                                                                    </span>
                                                                                                        }
                                                                                                        {(metalist.type !== 'attachment') &&

                                                                                                        values.value
                                                                                                        }
                                                                                                        {(index < metalist.value.length - 1 ? ", " : "")}
                                                                                                    </React.Fragment>
                                                                                                )
                                                                                            }
                                                                                        )}</div>
                                                                                    </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                }
                                                                {data.wf_status_info && data.wf_status_info.submission_status &&
                                                                <div className="ind-task-check">
                                                                    {/*<div className="task-check-header">*/}
                                                                    {/*    <h5 className="checkpoint checked">Decision</h5>*/}
                                                                    {/*</div>*/}
                                                                    <div className="task-check-body">
                                                                        <div className="split-data">
                                                                            <div className="split-data-row">
                                                                                <div className="split-data-left">DECISION</div>
                                                                                <div
                                                                                    className="split-data-right">{data.wf_status_info && data.wf_status_info.submission_status}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="split-data">
                                                                            {data.wf_status_info && data.wf_status_info.meta && data.wf_status_info.meta.map((metalist, index) =>
                                                                                <div
                                                                                    className={(metalist.type === 'textarea' || metalist.type === 'text') ? "split-data-row textarea-block" : "split-data-row"}>
                                                                                    <div
                                                                                        className="split-data-left">{metalist.label}</div>
                                                                                    {metalist.value !== undefined && metalist.value !== null && metalist.value.map((values, index) => {
                                                                                            return (
                                                                                                <React.Fragment>

                                                                                                    <div
                                                                                                        className="split-data-right">{values.value}</div>


                                                                                                </React.Fragment>
                                                                                            )
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            )}
                                        </div>
                                    </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>
                }

                {timelog.length !== 0 &&
                <React.Fragment>
                <div className="subheader-task">
                    <p>Time Logged</p>
                </div>
                <div className="log-detail-panel">
                    <div className="task-detail-panel">

                        <div className="form-table coll-full-height">
                            <table id="a" className="table data-table">
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                {timelog.map((item, index) =>
                                    <tr key={index}>
                                        <td>
                                            <div className="task-detail-user-info-left">
                                                <div className="task-detail-user-thumb"><img
                                                    src={USRIMG_PATH + item.assignee_img_name}/>
                                                </div>
                                                <div className="task-detail-user-det">
                                                    <h5>{item.assigneeName}</h5>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {item.logs.map((list, index) =>
                                                <p className="text-overflow">{moment(list.log_date).format('LLLL')}</p>
                                            )}
                                        </td>
                                        <td>
                                            {item.logs.map((list, index) =>
                                                <p className="text-overflow">{list.log_hours}:{list.log_mins} Hrs</p>
                                            )}
                                            <p className="text-overflow">Total <span>{timeConvertToDays(item.totaltime)} </span>
                                            </p>
                                        </td>

                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
                </React.Fragment>
                }



            </div>
            <div className={`print-footer`}></div>
            </React.Fragment>
        );
    }


}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
    Getprinttask:state.TaskReducer.getPrinttask,
    Getselectedcollection:state.TaskReducer.getselectedCollection,
    GetEnterprise:state.TaskReducer.getEnterprise,
    loader:state.CommonReducer.loader,
});

export default  withRouter(connect(mapStateToProps, {
    getPrintTask: taskActions.getprinttask,
    selectedCollection: taskActions.getselectedCollection,
    enterprise: taskActions.getEnterprise
})(PrintWorkflowDetail));






