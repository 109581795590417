import React from 'react';
import axios from 'axios';
import {FormGroup} from 'react-bootstrap';
import { Draggable, state } from 'react-page-maker';
import Faq from "../views/content/Faq";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {withRouter} from 'react-router';
import {API_PATH, ES_INDEX, FRMSIMG_PATH} from "../../../../constants"
import {getProfile} from '../../../../utilities/AuthService';
import {getUrlSegment, parseDataList, removeBackSlashes} from "../../../../utilities/CustomFunctions";
import LoaderSvg from "../../../views/LoaderSVG";
import SettingsModal from "./SettingsModal";
import FaqSettingsModal from "./FaqSettingsModal";
import moment from "moment";
import * as whatsappActions from "../../../../actions/Whatsapp";
import * as CeShareViewActions from "../../../../actions/CeShareView";
import SimpleModal from "../../../views/SimpleModal";
import DatePickerBox from "../../../views/DatePickerBox";
import AlertModal from "../../../views/AlertModal";

let graphData= []
class FaqContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            faqData : [],
            modalStatus:false,
            showColorPicker:false,
            background:'#fff',
            whatsapp : false,
            email : false,
            paymentMode:"Razorpay",
            fieldValues:[],
            payload:{
                chartTitle:"Title",
                subTitle:"subtitle",
                faqTitle:"",
                faqDesc:"",
                faqImage:"",
                titlecolor : "",
                desccolor : "",
                subTitleEnabled : false,
                whatsapp : false
            },
            loading:true,
            whereCondition:[
                {
                    id: "item-1",
                    label: "",
                    field:"",
                    conditions:"",
                    min:'',
                    max:'',
                    from:'',
                    to:'',
                    text:"",
                    type:""
                },
            ],
            globalFilter:[
                {
                    id: "item-1",
                    label: "",
                    field:"",
                    conditions:"",
                    min:'',
                    max:'',
                    from:'',
                    to:'',
                    text:"",
                    type:""
                },
            ],
            ownFilterList:[
                {
                    id: "item-1",
                    label: "",
                    field:"",
                    conditions:"",
                    min:'',
                    max:'',
                    from:'',
                    to:'',
                    text:"",
                    type:""
                },
            ],
            showlistField:[],
            condition :[ {
                field : "",
                type : "",
                sort :""
            } ],
        }


    }
    componentDidMount() {
        this.setState({payload:this.props.payload})
        this.graphData()

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterCnt.date !== this.props.filterCnt.date) {
            if (this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") {
                this.graphData()
            }
        }
        if (prevProps.linkedId.param !== this.props.linkedId.param) {
            let collection = getUrlSegment(3) ? atob(getUrlSegment(3)) :  this.props.linkedCollectionId.param;
            if(this.props.linkedId.param && this.props.linkedId.param !=='') {
                let params = {
                    "recordid": this.props.linkedId.param,
                    "_id": "",
                    "collectionid": collection
                }
                this.props.getPaymentData(params);
            }
        }
        if (prevProps.filterCnt.select !== this.props.filterCnt.select) {
            this.graphData()
        }
        if (prevProps.getFieldList.fieldList !== this.props.getFieldList.fieldList) {
            let fieldValues = [];
            let data = this.props.getFieldList.fieldList;
            if (data.length !== 0) {
                data.map((item, index) => {
                    if (item.collectedform.length > 0) {
                        item.collectedform.map((list, indx) => {
                            if (list.masterid === this.state.fieldId) {
                                if (list.value.length !== 0) {
                                    list.value.map((data, ind) => {
                                        fieldValues.push({"id" : item.id, "value" : data.value});
                                    })
                                }
                            }
                        })
                    }
                })
            }

            this.setState({fieldValues: fieldValues})
        }

        if (prevProps.savedPaymentUrl.payurl !== this.props.savedPaymentUrl.payurl) {
            if (this.props.savedPaymentUrl.payurl.success === true) {
                let mainUrl = window.location.origin;
                let selectedRecordId = this.state.selectedRecordId;
                let parentID = this.props.savedPaymentUrl.payurl.data;
                let PaymentUrl = mainUrl + `/payment/` + btoa(parentID) + `/` + btoa(selectedRecordId);
                this.setState({PaymentUrl : PaymentUrl, paymentErrorModal:false})
            }
            else{
                let savePaymentMsg = this.props.savedPaymentUrl.payurl.message;
                this.setState({savePaymentMsg : savePaymentMsg, paymentErrorModal:true})
            }
        }
        if (prevProps.paymentData.data !== this.props.paymentData.data) {
            if (this.props.paymentData.success === true && this.props.paymentData.data.recordid) {
                let mainUrl = window.location.origin;
                let selectedRecordId = this.props.paymentData.data.recordid;
                let parentID = this.props.paymentData.data._id;
                //console.log(selectedRecordId)
                if(selectedRecordId !== ''){
                    let PaymentUrl = mainUrl + `/payment/` + btoa(parentID) + `/` + btoa(selectedRecordId);
                    this.setState({PaymentUrl : PaymentUrl})
                }
            }
            else{
                this.setState({PaymentUrl : ''})
            }
        }


    }
    getFieldValues = (sourceType, collection, searchtext) => {
        let entityId = collection;
        let search = searchtext ? searchtext : '';
        let refer = sourceType.toString() === "true" ? "collection" : "entity";
        let params = {"from":0,"size":100,"search":search,"uid":"2","usertype":"Superadmin","refer":refer,"dashboard":false,"facetrequired":false}
        this.props.getFieldValues( entityId, params);
    }

    getFieldValuesSearch=(e, sourceType, collection)=>{
        this.getFieldValues(sourceType, collection, e.target.value)
    }


    toggleColorPicker = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    }


    handleInputChange=(e)=>{
        let sel=e.target
        if(e.target.name==="collection"){
            this.props.showform({ _id : e.target.value});
            this.props.getFlag({collection:e.target.value,uid:2})
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        payload["label"+e.target.name]=sel.options[sel.selectedIndex].text
        payload['paymentMode']='Razorpay';
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    handleChange=(e)=>{
        if(e.target.name==="collection"){
            this.props.showform({ _id : e.target.value});
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    setDate=(e)=>{

        let sel=e.target
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        payload["label"+e.target.name]=sel.options[sel.selectedIndex].text
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }




    graphData(){
        var date = new Date();
        var timestamp = date.getTime();
        var obj = this;
        let payload=this.state.payload;
        const tabledata = this.props.payload && this.props.payload.graph||this.state.items
        const whereCondition = this.props.payload && this.props.payload.whereCondition||this.state.whereCondition
        const condition = this.props.payload && this.props.payload.condition||this.state.condition;
        const limit = this.props.payload && this.props.payload.limit;
        const x = this.props.payload && this.props.payload.x;
        const y = this.props.payload && this.props.payload.y;
        const globalFilter = this.props.payload && this.props.payload.globalFilter||this.state.globalFilter
        const ownFilterList = this.props.payload && this.props.payload.ownFilterList||this.state.ownFilterList
        const collection =  this.props.payload && this.props.payload.collection;

        const fields = this.props.payload && this.props.payload.fields
        const faqTitle = this.props.payload && this.props.payload.faqTitle
        const faqDesc = this.props.payload && this.props.payload.faqDesc
        const faqImage = this.props.payload && this.props.payload.faqImage;

        this.setState({condition:condition,whereCondition:whereCondition, limit : limit, faqTitle : faqTitle, faqDesc :faqDesc,faqImage : faqImage })


        if((collection !=='' && collection !== undefined ) || getUrlSegment(1)==="collections") {
            let collections =(getUrlSegment(1)==="collections")? atob(getUrlSegment(4) ) :collection

            let filters = [];
            let numberfilters = [];
            let datefilters = [];
            var relFilCnt=0;
            if(whereCondition.length > 0) {
                for( var i=0; i< whereCondition.length;i++){
                    let conditionEntry = whereCondition[i];
                    if(conditionEntry && conditionEntry.field&& conditionEntry.field !== '') {
                        let conditionValue = '';
                        if(conditionEntry.type === 'text') {
                            conditionValue = conditionEntry.text;
                        }
                        else if(conditionEntry.type === 'conditions') {
                            conditionValue = conditionEntry.conditions;
                        }
                        let conditionArr = conditionValue !== '' ? conditionValue.split(',') :[];

                        if(conditionEntry.type === 'datepicker') {
                            let datefilter = {
                                "id" : conditionEntry.field,
                                "from" : conditionEntry.from,
                                "to" : conditionEntry.to
                            }
                            datefilters.push(datefilter)
                        }
                        else if(conditionEntry.type === 'number') {
                            let numberfilter = {
                                "id" : conditionEntry.field,
                                "min" : conditionEntry.min,
                                "max" : conditionEntry.max
                            }
                            numberfilters.push(numberfilter)
                        }
                        else {
                            if(conditionArr.length > 0) {
                                let filter = {
                                    "id" : conditionEntry.field,
                                    "value" : conditionArr
                                }
                                filters.push(filter);
                            }

                        }

                    }
                }
            }
            if((this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") || this.props.filterCnt.select !== "") {
                if (globalFilter.filter(list=>list.text === this.props.filterCnt.filterName).length > 0) {
                    for (var i = 0; i < globalFilter.length; i++) {
                        let conditionEntry = globalFilter[i];
                        if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                            let conditionValue = '';
                            if (conditionEntry.type === 'text') {
                                conditionValue = conditionEntry.text;
                            } else if (conditionEntry.type === 'conditions') {
                                conditionValue = conditionEntry.conditions;
                            }
                            let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];
                            if (conditionEntry.type === 'datepicker' && this.props.filterCnt.date.to !== "") {
                                let datefilter = {
                                    "id": conditionEntry.field,
                                    "from": this.props.filterCnt.date.from,
                                    "to": this.props.filterCnt.date.to
                                }
                                datefilters.push(datefilter)
                            } else if (conditionEntry.type === 'number') {
                                let numberfilter = {
                                    "id": conditionEntry.field,
                                    "min": conditionEntry.min,
                                    "max": conditionEntry.max
                                }
                                numberfilters.push(numberfilter)
                            } else {
                                if (this.props.filterCnt.select !=="") {
                                    let filter = {
                                        "id": conditionEntry.field,
                                        "value": [this.props.filterCnt.select]
                                    }
                                    filters.push(filter);
                                }

                            }

                        }
                        relFilCnt=this.state.relFilCnt+1
                    }
                }
            }
            if (this.state.ownid === this.props.filterCnt.filterName) {
                for (var i = 0; i < ownFilterList.length; i++) {
                    let conditionEntry = ownFilterList[i];
                    if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                        let conditionValue = '';
                        if (conditionEntry.type === 'text') {
                            conditionValue = conditionEntry.text;
                        } else if (conditionEntry.type === 'conditions') {
                            conditionValue = conditionEntry.conditions;
                        }
                        let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];
                        if (conditionEntry.type === 'datepicker' && this.props.filterCnt.date.to !== "") {
                            let datefilter = {
                                "id": conditionEntry.field,
                                "from": this.props.filterCnt.date.from,
                                "to": this.props.filterCnt.date.to
                            }
                            datefilters.push(datefilter)
                        } else if (conditionEntry.type === 'number') {
                            let numberfilter = {
                                "id": conditionEntry.field,
                                "min": conditionEntry.min,
                                "max": conditionEntry.max
                            }
                            numberfilters.push(numberfilter)
                        } else {
                            if (this.props.filterCnt.select !=="") {
                                let filter = {
                                    "id": conditionEntry.field,
                                    "value": [this.props.filterCnt.select]
                                }
                                filters.push(filter);
                            }

                        }

                    }
                    var relFilCnt=this.state.relFilCnt+1
                }
                relFilCnt=this.state.relFilCnt+1

            }
            let sortCondition = [];
            if(condition.length > 0) {
                for(var j=0; j<condition.length; j++) {
                    if( condition[j].field && condition[j].field !== '' && condition[j].sort && condition[j].sort !== ''
                        && condition[j].type && condition[j].type !== '') {
                        sortCondition.push(condition[j]);
                    }
                }
            }
            let param={
                "from": 0,
                "size": limit,
                "search": "",
                "index": ES_INDEX,
                "usertype": getProfile().usertype,
                "uid":getProfile().id,
                "fromdate": "",
                "todate": "",
                "filters": filters,
                "datefilters": datefilters,
                "dayfilters": [],
                "numberfilters": numberfilters,
                "dashboard":true,
                "facetrequired":true,
                "fieldview":true,
                "assignees": [],
                "sort": [] ,
                "starred" :false
                //          listdata:((this.state.defaultTemplate ===''? this.props.collectionData.CollectionInfo.default_template:this.state.defaultTemplate === "list")?true:false)
            }

            this.setState({cId:collections})
            axios({
                method: "POST",
                url: API_PATH + "collection/" + collections + "/collectedinfo?index="+ES_INDEX,
                async: false,
                headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token},
                params : param
            }).then(function (res) {
               // console.log(res.data.results.data)
                var faqData = parseDataList(res.data.results.data);
                obj.setState({faqData: faqData});
                return res;

            }).catch(function (err) {
                return err;
            });
        }
        if((collection !=='' && collection !== undefined) || getUrlSegment(1)==="collections") {
            let collections =(getUrlSegment(1)==="collections")? atob(getUrlSegment(4) ) :collection
            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + collections + "&index=" + ES_INDEX,
                async: false,
                headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
            }).then(function (res) {
                obj.setState({showlistField: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });

            this.setState({cId:collection})
        }

        // if((collection !=='' || collection !== undefined) || getUrlSegment(1)==="collections" ) {
        //     let collections =(getUrlSegment(1)==="collections")? atob(getUrlSegment(4) ) :collection
        //     this.setState({cId:collections})
        //     axios({
        //         method: "POST",
        //         url: API_PATH + "collection/" + collections + "/collectedinfo?index="+ES_INDEX,
        //         async: false,
        //         headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token},
        //         params : data
        //     }).then(function (res) {
        //         console.log(res.data.results.data)
        //         var faqData = parseDataList(res.data.results.data);
        //         obj.setState({faqData: faqData});
        //         return res;
        //
        //     }).catch(function (err) {
        //         return err;
        //     });
        // }
        else{
            obj.setState({ faqData: []});

        }
    }
    addData=(Id,updateParam)=>{
        let data=this.state[updateParam]
        let newData={
            id: "item-"+new Date().getTime(),
            label: "item "
        }
        let _data=data.concat(newData)
        this.setState({[updateParam]:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[updateParam]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    removeData=(dragid,updateParam)=>{
        let data=this.state[updateParam]
        let _data=data.filter(item=>item.id !== dragid)
        this.setState({[updateParam]:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        //console.log(payload)
        payload[updateParam]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    radioBoxchange=(e)=> {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    checkBoxchange=(e)=>{
        let val;
        if(e.target.value === "false"){
            val=true
        }
        else{
            val=false
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    handleLabelChange=(e,dragid,whereCondition,type)=>{
        var updatedParam = "items";
        if(whereCondition === "graph"){
            updatedParam = "items";
        }
        else{
            updatedParam = whereCondition;
        }

        var _newState = this.state[updatedParam];
        //console.log(_newState)
        var index=(_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name]=e.target.value;
        if(whereCondition ==="whereCondition" && type !=="field"){
            _newState[index]["type"]=type;
        }
        //console.log(_newState)
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[whereCondition]=this.state[updatedParam]
        //console.log(payload)
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    handleChangeComplete = (color,param) => {
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[param]=color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    };

    toggleColorPicker = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    }

    chartSaving=()=>{
        var prjctdtlid = atob(getUrlSegment(4));
        let sharetype = getUrlSegment(1);
        let  uid = '';
        if(sharetype === 'public') {
            uid = 2;
        }
        else{
            uid = getProfile().id;
        }
        let param={
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard":state.getStorableState(),
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus:false})
    }

    handleConditionChange=(e)=>{

        var _newState = this.state.condition;

        if(e.target.name ==='field') {
            let t = e.target.value.split("|");
            _newState[0]["field"] = t[0];
            _newState[0]["type"] =  t[1] === "datepicker" ?"date":t[1];
        }
        else{
            _newState[0][e.target.name] = e.target.value;
        }


        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["condition"]=this.state.condition
        //////console.log(payload)
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }

    handleClick=(status)=>{
        this.setState({colorpickerStatus:status})
    }

    handleFlagSet=(fid)=>{
        // Get the index of id in the array
        const index = this.state.flagId.indexOf(fid);
        if (index > -1) {
            // This means id is present in the array, so remove it
            this.state.flagId.splice(index, 1);
        } else {
            // This means id is not present in the array, so add it
            this.state.flagId.push(fid);
        }

        // You can use this to debug purpose

        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["flagId"]=this.state.flagId
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });

    }

    copyHelpText=(e, item)=> {
        let selectedHelpText = "";
        if(item[this.state.faqTitle] ){
            selectedHelpText += item[this.state.faqTitle] ;
        }
        if(item[this.state.faqDesc] ){
            selectedHelpText += "<br />"+item[this.state.faqDesc];
        }

        if(item[this.state.faqImage] ){
            selectedHelpText += "<br />"+FRMSIMG_PATH+ item[this.state.faqImage];
        }

        let copiedHelpText= removeBackSlashes(selectedHelpText);
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute("id", "dummy_id");
        document.getElementById("dummy_id").value = copiedHelpText;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
      //  this.props.copyHelpTextSuccess()

    }
    sendHelpText=(e, item)=> {
        let selectedHelpText = [];
        if(item[this.state.faqTitle] ){
            selectedHelpText += item[this.state.faqTitle] ;
        }
        if(item[this.state.faqDesc] ){
            selectedHelpText += "<br />"+item[this.state.faqDesc];
        }

        if(item[this.state.faqImage] ){
            selectedHelpText += "<br />"+FRMSIMG_PATH + item[this.state.faqImage];
        }
        let new_message= selectedHelpText;
        this.setState({new_message: removeBackSlashes(new_message)},function(){
           // this.props.sendChatFromFAQ();
        })
        /*let params = {
            "recordid": this.state.selectedLeadId,
            "sender": getProfile().id,
            "receiver": this.state.selected_phone_no,
            "message": new_message,
            "bsynuser": true,
            "sendnotification": true,
            "channelid": this.state.channelId
        }*/
        //this.props.saveNewChat(params);
    }

    openSetting=()=>{
        this.setState({modalStatus:!this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }


    render() {

        const {
            id,
            showBasicContent, showPreview,
            dropzoneID,payload, parentID, name
        } = this.props;

        let sourceType= (payload && payload.sourceType)||false
        // console.log(sourceType)
        let showlistField = []
        let sourceData=[]
        if(this.state.showlistField.length !==0) {
            for (let i = 0; i <this.state.showlistField.pages.length; i++) {
                (this.state.showlistField.pages[i].components).map(list=>{
                    showlistField.push(list)
                })
            }
        }
        var collections=''
        const chartTitle =  this.state.chartTitle || payload && payload.chartTitle;
        const subTitle =  this.state.subTitle || payload && payload.subTitle;
        const faqTitle =  this.state.faqTitle || payload && payload.faqTitle;
        const faqDesc =  this.state.faqDesc || payload && payload.faqDesc;
        const faqImage =  this.state.faqImage || payload && payload.faqImage;
        const titlecolor =  this.state.titlecolor || payload && payload.titlecolor;
        const desccolor =  this.state.desccolor || payload && payload.desccolor;



        if(sourceType.toString() === "true"){
            sourceData=  this.props.listAllCollections.ListAllCollections.data
        }
        else{
            sourceData=  this.props.EntityData.Entity.data
        }

        let containerData={
            chartTitle :  chartTitle,
            subTitle :  subTitle,
            data :  this.state.faqData || [],
            collection :  this.state.collection || payload && payload.collection ,
            tabledata :  this.state.items || payload && payload.graph ,
            condition : this.props.payload && this.props.payload.condition||this.state.condition,
            whereCondition :  this.state.whereCondition || payload && payload.whereCondition ,
            limit :  this.state.limit || payload && payload.limit,
            showlistField : showlistField,
            sourceType : sourceType,
            collectionData:sourceData,
            faqTitle :  faqTitle ,
            faqDesc :  faqDesc ,
            faqImage :  faqImage,
            titlecolor : titlecolor,
            desccolor : desccolor

        }

        if (showBasicContent) {
            return (
                <Draggable { ...this.props } >
                    {
                        <span className="drag-icon">{name}</span>
                    }
                </Draggable>
            )
        }

        if (showPreview) {
            // console.log(this.props.paymentData.data)

            return (
                <>
                    {this.state.faqData.map(item =>
                        <Faq
                            item={item}
                            containerData={containerData}
                            copyHelpText = {this.copyHelpText}
                            sendHelpText = {this.sendHelpText}
                        />
                    )}
                </>

            );
        }
        return (
            <React.Fragment>
                <Draggable {...this.props}  draggable={!this.props.DragDisabled.dragStatus}>
                    <FormGroup className="m-0">
                        <label className="col-sm-12">
                            <span>{name}</span>
                            <div className="chart-icons">
                                <div className="settings-icon" onClick={() => this.openSetting(id, dropzoneID, parentID)}></div>
                                <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>
                            </div>
                        </label>
                    </FormGroup>
                </Draggable>
                {this.state.modalStatus &&
                <FaqSettingsModal
                    title={` FAQ -   Settings`}
                    containerData={containerData}
                    colorpickerStatus={this.state.colorpickerStatus}
                    modaltype={8}
                    repeater={false}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    handleInputChange = {this.handleInputChange}
                    handleColorChange = {this.handleColorChange}
                    handleLabelChange = {this.handleLabelChange}
                    handleConditionChange = {this.handleConditionChange}
                    handleChangeComplete = {this.handleChangeComplete}
                    handleColorChange={this.handleColorChange}
                    handleClick={this.handleClick}
                    handleChange = {this.handleChange}
                    radioBoxchange = {this.radioBoxchange}
                />
                }

            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    loader:state.CommonReducer.loader,
    showFormModal:state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange:state.DynamicDashboard.getDashboard,
    DragDisabled:state.DynamicDashboard.getDragDisabled,
    EntityData:state.EntityReducer.GetEntity,
    filterCnt:state.DynamicDashboard.getFilterCnt,
    linkedId: state.EntityReducer.LinkedId,
    linkedCollectionId: state.EntityReducer.linkedCollectionId,
    getFlagData:state.DynamicDashboard.getFlagData,
    getFieldList : state.CeShareView.getFieldList,
    savedPaymentUrl : state.CeShareView.savedPaymentUrl,
    paymentData : state.CeShareView.paymentData,
    mainEntityFormData : state.EntityReducer.showFormModal


});
export default  withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform : collectionActions.showform,
    chartSaving:DynamicDashboard.saveDashboarddata,
    dragStatus :DynamicDashboard.dragStatus,
    getFlag:DynamicDashboard.getFlag,
    getFieldValues : CeShareViewActions.getFieldValues,
    savePaymentUrl : CeShareViewActions.savePaymentUrl,
    getPaymentData : CeShareViewActions.getPaymentData,

})(FaqContainer));
