import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";
import MetricFilter from "../../views/MetricFilter";
import {MetricItem} from "../../views/MetricData";
import AddNewMetrics from "./AddNewMetrics";
import Modal from "../../views/Modal";
import ImpactSnapshotCard from "../../views/project-dashboard/ImpactSnapshotCard";
import {BeneficiaryData,GraphBlock} from "../../views/ImpactData";

class ProjectImpact extends Component {

    constructor(props) {
        super(props);

        this.state={
            expandHeader:false,
            showMetricsModal : false
        }
        this.expandHeader = this.expandHeader.bind(this)
        this.showAddNewMetricsModal = this.showAddNewMetricsModal.bind(this)
        this.closeMetricsModal = this.closeMetricsModal.bind(this)
    }
    componentDidMount() {
        this.setState({loading: 1});
        var projectId = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/impact/'+projectId);
    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }

    showAddNewMetricsModal(){
        this.setState({showMetricsModal:true})
    }

    closeMetricsModal(){
        this.setState({showMetricsModal:false})
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {

        return(

             <div  className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                 <ProjectHeader expandHeader={this.expandHeader}/>
                 <div className="ibt-body">
                     <div className="ibt-financial-body">
                         <ImpactSnapshotCard />
                         <div className="stakeholders-header">
                             <h5>Number of Beneficiaries</h5>
                         </div>
                         <BeneficiaryData/>
                        <GraphBlock/>
                     </div>
                 </div>
                 {/*<div className="float-button-block" data-toggle="tooltip" data-placement="top" title=""*/}
                 {/*     data-original-title="New Metrics">*/}
                 {/*    <button className="float-button ico-lib float-add" onClick={this.showAddNewMetricsModal}></button>*/}
                 {/*</div>*/}
                 {/*{this.state.showMetricsModal &&*/}
                 {/*<Modal title="Add New Metrics" Close ={this.closeMetricsModal.bind(this)}>*/}
                 {/*    <AddNewMetrics*/}
                 {/*        AddmodalClose = {this.closeMetricsModal}*/}
                 {/*    />*/}

                 {/*</Modal>*/}
                 {/*}*/}
             </div>



        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
})(ProjectImpact));
