import {combineReducers} from 'redux';

const listallResources = (state = {ListAllResources:[],searchParam:{srchval:'',filter_type:''}}, action) => {
    var _newState;
    switch (action.type) {

        case "LIST_All_RESOURCES":
            return {
                ...state,
                ListAllResources: action.listresources.data.results.data,
                searchParam:action.searchParam
            };
            break;
            case "LIST_MORE_RESOURCES":
                _newState = {...state};
                _newState['ListAllResources'] = _newState.ListAllResources.concat(action.listmoreResources.data.results.data);
                return _newState;

            break;
        case "LIST_TODO_RESOURCES":
            return {
                ...state,
                ListAllResources: action.resources,
                searchParam:action.searchParam
            };
            break;
            case "RESOURCE_LOADER":
            return {
                ...state,
                loading: action.loading,
            };
            break;

        default:
            return state;
    }
};

const delResources = (state = {delResources:false}, action) => {
    switch (action.type) {

        case "DELETE_RESOURCES":
            return {
                ...state,
                delResources: !state.delResources,

            };
            break;

        default:
            return state;

    }
};
const gallerySaved = (state = {gallerysaved:{}}, action) => {
    switch (action.type) {

        case "SAVE_GALLERY":
            return {
                ...state,
                gallerysaved: action.saveGallery.data.results.time,

            };
            break;

        default:
            return state;

    }
};
const listResourcetags = (state = {Resourcetags:[]}, action) => {
    switch (action.type) {

        case "LIST_All_RESOURCE_TAGS":
            return {
                ...state,
                Resourcetags: action.Resourcetaglist

            };
            break;
        case "RESOURCE_TAG_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
const getResourceInfo = (state = {ResourceInfo:[]}, action) => {
    switch (action.type) {

        case "GET_RESOURCES_INFO":
            return {
                ...state,
                ResourceInfo: action.ResourcesInfo.data.results.data

            };
            break;
        case "RESOURCE_INFO_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
export const Resources = combineReducers({
    listallResources,
    delResources,
    gallerySaved,
    listResourcetags,
    getResourceInfo
});

export default Resources;

