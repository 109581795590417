import React, {Component} from 'react';

class ProjectNotesRight extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }

    }


    componentDidMount() {

    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.state !== nextState || this.props !== nextProps) {
            return true;
        }
    }


    render() {

        return (

            <div className="nav nav-pills nicescroll" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home"
                   role="tab" aria-controls="v-pills-home" aria-selected="true">
                    <div className="project-note-header">
                        <h5>Demo User Login Credentials</h5>
                        <button className="ico-lib-v1 chain-ico"></button>
                    </div>
                    <div className="project-note-desc">
                        <p>In a professional context it often happens that private… </p>
                        <span className="days-count">1 day ago</span>
                    </div>
                </a>


                <a className="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings"
                   role="tab" aria-controls="v-pills-settings" aria-selected="false">
                    <div className="project-note-header">
                        <h5>Demo User Login Credentials</h5>
                        <button className="ico-lib-v1 chain-ico"></button>
                    </div>
                    <div className="project-note-desc">
                        <p>In a professional context it often happens that private… </p>
                        <span className="days-count">1 day ago</span>
                    </div>
                </a>

            </div>
        );


    }
}
export default ProjectNotesRight;
