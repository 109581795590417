import {projects} from '../services/projects.services'
import {workflow} from "../services/workflow.services";
import {getProfile} from "../utilities/AuthService";
import {ES_INDEX} from "../constants";
import {formatDate} from "../utilities/CustomFunctions";
import {collections} from "../services/collections.services";
export const listallprojects = (data) => dispatch => {
    let listallprojects = projects.listProject(data);

    dispatch({
        type: 'PROJECTSLIST_LOADER',
        loading:true,

    });
    listallprojects.then(listallprojects => {

        dispatch({
            type: 'LIST_All_PROJECTS',
            listallprojects: listallprojects,
            clearList : data.clearList,
            searchvalue : data.search,
            page : data.page

        })
        dispatch({
            type: 'PROJECTSLIST_LOADER',
            loading:false,

        });
    })

};


export const listallprojectsdetails = (data) => dispatch => {
    dispatch({
        type: 'RESET_PROJECTSDETAILS',
        loading:true,

    });
    let listallprojectsdetails = projects.listProjectDetails(data);
    dispatch({
        type: 'PROJECTSDETAILS_CONTENT_LOADER',
        loading:true,

    });

    listallprojectsdetails.then(listallprojectsdetails => {
        dispatch({
            type: 'PAGE_HEADLINE',
            title: listallprojectsdetails.data.results.data.projectinfo.project_name,
        });
        dispatch({
            type: 'LIST_All_PROJECTSDETAILS',
            listallprojectsdetails: listallprojectsdetails.data.results,

        })

        dispatch({
            type: 'PROJECTSDETAILS_CONTENT_LOADER',
            loading:false,

        });
    })
};


export const listalltags = (type,count) => dispatch => {
    let listTags = projects.listTags(type,count);
    listTags.then(listTags => {
        dispatch({
            type: 'LIST_All_TAGS',
            listTags: listTags,

        })
    })
};

export const milestoneList = (mdata) => dispatch => {
    let milestonelist = projects.milestonelist(mdata);
    dispatch({
        type: 'RESET_MILESTONESLIST_CONTENT',

    });
    dispatch({
        type: 'MILESTONESLIST_CONTENT_LOADER',
        loading:true,

    });
    milestonelist.then(milestonelist => {
        dispatch({
            type: 'LIST_All_MILESTONES',
            milestonelistdata: milestonelist,

        })

        dispatch({
            type: 'MILESTONESLIST_CONTENT_LOADER',
            loading:false,

        });
    })
};

export const assignMilestone = (params) => dispatch => {

    let AssignMilestone = projects.AssignMilestone(params);

    AssignMilestone.then(AssignMilestone => {
        dispatch({
            type: 'ASSIGN_MILESTONE',
            assignMilestone:AssignMilestone.data.results.data
        });
    })
};

export const SaveProject = (params) => dispatch => {
    let projectSaved = projects.SaveProject(params);

    let newProject = {
        "project_id": params.i,
        "project_name": params.projectname,
        "description": btoa(unescape(encodeURIComponent(params.description))),
        "enddate": formatDate(params.enddate),
        "portfolioName" : params.portfolio_name,
         "programName" : params.program_name,
        "mode":params.mode,

        "statusbucket": [
            {
                "key": "New",
                "doc_count": 0
            },
            {
                "key": "InProgress",
                "doc_count": 0
            },
            {
                "key": "Done",
                "doc_count": 0
            }
        ],
        "taskcount": params.taskcount,
        "usercount": params.usercount,
        "crtuser": params.uid};


    projectSaved.then(projectSaved => {
        dispatch({
            type: 'SAVE_PROJECT',
            projectSaved,
            newProject
        })
    })
};


export const moreItems = (moredata) => dispatch => {
    let moreitems = projects.moreitems(moredata);

    moreitems.then(moreitems => {
        dispatch({
            type: 'LIST_MORE_ACTIVITYLOG',
            logitems: moreitems.data.results.data,


        })
    })
};

export const projectInfo = (params) => dispatch => {
    let projectInfo = projects.ProjectInfo(params);
    dispatch({
        type: 'PROJECT_INFO_CONTENT_LOADER',
        loading:true,

    });
    dispatch({
        type: 'PROJECT_LOG_CONTENT_LOADER',
        loading:true,

    });
    projectInfo.then(projectInfo => {
        dispatch({
            type: 'PROJECT_INFO',
            projectInfo: projectInfo,

        })
        dispatch({
            type: 'LIST_ACTIVITYLOG',
            logitems: projectInfo.data.results.data,

        })
        dispatch({
            type: 'PROJECT_INFO_CONTENT_LOADER',
            loading:false,

        });
        dispatch({
            type: 'PROJECT_LOG_CONTENT_LOADER',
            loading:false,

        });
    })
};

export const overviewHelper = (params) => dispatch => {
    dispatch({
        type: 'RESET_PROJECTSDETAILS',
        loading:true,

    });
    let projectDetails = projects.overviewHelper(params);
    dispatch({
        type: 'PROJECT_OVERVIEW_CONTENT_LOADER',
        loading:true,

    });
    projectDetails.then(projectDetails => {
        dispatch({
            type: 'PROJECT_OVERVIEW',
            projectDetails: projectDetails.data.results,

        })
        dispatch({
            type: 'PROJECT_OVERVIEW_CONTENT_LOADER',
            loading:false,

        });
    })
};

export const saveMilestone = (params) => dispatch => {
    let saveMilestone = projects.SaveMilestone(params);

    let newMilestone = {
        "milestone_id": params.i,
        "projectid": params.projectid,
        "milestone_name": params.milestonename,
        "crtuser": getProfile().id,
        "internaluse": params.internal,
        "concat": "",
        "startdate": params.startdate,
        "enddate": params.enddate,
        "visibility": "yes",
        "status": "active",
        "creator_first_name": getProfile().first_name,
        "creator_last_name": getProfile().last_name,
        "mode":params.mode,
    }

    saveMilestone.then(created => {
        dispatch({
            type: 'SAVE_MILESTONE',
            newMilestone
        })
    })
};
export const getMilestoneInfo = (params) => dispatch => {
    let getMilestoneInfo = projects.Getmilestone(params);
    getMilestoneInfo.then(getMilestoneInfo => {
        dispatch({
            type: 'GET_MILESTONE_DETAILS',
            getmilestoneData:getMilestoneInfo

        })
    })
};
export const getProjectUsers = (params) => dispatch => {
    let getProjectUsers = projects.Getprojectusers(params);
    dispatch({
        type: 'USERLIST_LOADER',
        loading:true,

    });
    getProjectUsers.then(getProjectUsers => {
        dispatch({
            type: 'GET_PROJECT_USERS',
            getprojectusers:getProjectUsers

        })
        dispatch({
            type: 'USERLIST_LOADER',
            loading:false,

        });
    })
};
export const getProjectSections = (params) => dispatch => {
    let getProjectSections = projects.Getprojectsections(params);
    getProjectSections.then(getProjectSections => {
        dispatch({
            type: 'GET_PROJECT_SECTIONS',
            projectsections:getProjectSections

        })
    })
};

export const getProjectDiscussions = (params) => dispatch => {

    let getProjectDiscussions = projects.GetProjectdiscussions(params);
    dispatch({
        type: 'PROJECT_DISCUSSIONS_CONTENT_LOADER',
        loading:true,

    });
    getProjectDiscussions.then(getProjectDiscussions => {
        dispatch({
            type: 'GET_PROJECT_DISCUSSIONS',
            projectdiscussions:getProjectDiscussions,
        });
        dispatch({
            type: 'PROJECT_DISCUSSIONS_CONTENT_LOADER',
            loading:false,

        });

    })
};
export const getDiscussionsDetails = (params) => dispatch => {
    //console.log()
    let getDiscussionsDetails = projects.GetDiscussionsDetails(params);
    dispatch({
        type: 'DISCUSSIONS_DETAILS_CONTENT_LOADER',
        loading:true,

    });
    getDiscussionsDetails.then(getDiscussionsDetails => {
        dispatch({
            type: 'PAGE_HEADLINE',
             title: getDiscussionsDetails.topic,
        });
        dispatch({
            type: 'GET_DISCUSSIONS_DETAILS',
            discussionsdetails:getDiscussionsDetails

        }) ;

        dispatch({
            type: 'DISCUSSIONS_DETAILS_CONTENT_LOADER',
            loading:false,

        });
    })
};

export const getAllComments = (params) => dispatch => {
    let getAllComments = projects.GetAllComments(params);
    getAllComments.then(getAllComments => {
        dispatch({
            type: 'GET_ALL_COMMENTS',
            allComments:getAllComments

        })
    })
};
export const saveProjectUser = (params) => dispatch => {
    let saveProjectUser = projects.SaveProjectUser(params);
    saveProjectUser.then(saveProjectUser => {
        dispatch({
            type: 'SAVE_PROJECT_USER',
            saveProjectUser:saveProjectUser.data.results.data.projectusers
        })
    })
};
export const SaveProjectDiscussion = (params) => dispatch => {
    let SaveProjectDiscussion = projects.SaveProjectDiscussion(params);
    SaveProjectDiscussion.then(SaveProjectDiscussion => {
        dispatch({
            type: 'SAVE_PROJECT_DISCUSSION',
        })
    })
};
export const SaveProjectComments = (params) => dispatch => {
    let SaveProjectComments = projects.SaveProjectComments(params);
    SaveProjectComments.then(SaveProjectComments => {
        dispatch({
            type: 'SAVE_PROJECT_COMMENTS',
        })
    })
};
export const DeleteProject = (params) => dispatch => {
    let DeleteProject = projects.DeleteProject(params);
    DeleteProject.then(DeleteProject => {
        dispatch({
            type: 'DELETE_PROJECT',


        })
    })
};
export const deleteProjectUser = (params) => dispatch => {
    let deleteProjectUser = projects.DeleteProjectUser(params);
    deleteProjectUser.then(deleteProjectUser => {
        dispatch({
            type: 'DELETE_PROJECT_USER',
            deleteProjectUser:deleteProjectUser.data.results.data.projectusers

        })
    })
};
export const saveAssignedUsers = (params) => dispatch => {
    let saveAssignedUsers = projects.SaveAssignedUsers(params);
    saveAssignedUsers.then(saveAssignedUsers => {
        dispatch({
            type: 'SAVE_ASSIGNED_USERS',
        })
    })
};
export const saveSection = (params) => dispatch => {
    let saveSection = projects.SaveSections(params);
    saveSection.then(saveSection => {

        dispatch({
            type: 'SAVE_SECTION',
            saveSection : saveSection.data.results.data.section
        })
    })
};
export const updateTaskSection = (params) => dispatch => {
    let updateSection = projects.UpdateTaskSections(params);
    updateSection.then(updateSection => {
        dispatch({
            type: 'UPDATE_TASK_SECTION',
            updated: true
        })
    })
};
export const getSection = (params) => dispatch => {
    let getSection= projects.GetSections(params);
    getSection.then(getSection => {
        dispatch({
            type: 'GET_SECTION_DETAILS',
            sectiondata : getSection
        })
    })
};

export const taskHelper = (params) => dispatch => {

    let projectDetails = projects.taskHelper(params);
    dispatch({
        type: 'PROJECT_TASK_CONTENT_LOADER',
        loading:true,

    });
    projectDetails.then(projectDetails => {
        dispatch({
            type: 'PROJECT_TASK',
            projectDetails: projectDetails.data.results,

        })
        dispatch({
            type: 'PROJECT_TASK_CONTENT_LOADER',
            loading:false,

        });
    })
};
export const milestoneSections = (params) => dispatch => {

    let milestoneSections = projects.milestonesections(params);
    dispatch({
        type: 'PROJECT_MILESTONE_LIST_LOADER',
        loading:true,

    });
    milestoneSections.then(milestoneSections => {
        dispatch({
            type: 'PROJECT_MILESTONES',
            milestoneSections: milestoneSections

        })
        dispatch({
            type: 'PROJECT_MILESTONE_LIST_LOADER',
            loading:false,

        });
    })
};
export const overviewData = (params) => dispatch => {

    let getOverviewdata = projects.getheaderinfo(params);
    dispatch({
        type: 'PROJECT_OVERVIEW_DATA_LOADER',
        loading:true

    });
    getOverviewdata.then(getOverviewdata => {
        dispatch({
            type: 'PROJECT_OVERVIEW_DATA',
            getOverviewdata: getOverviewdata

        })
        dispatch({
            type: 'PROJECT_OVERVIEW_DATA_LOADER',
            loading:false

        });
    })
};
export const getNewsMedia = (params) => dispatch => {

    let getNewsMedia = projects.GetNewsMedia(params);
    dispatch({
        type: 'NEWS_AND_MEDIA_DATA_LOADER',
        loading:true

    });
    getNewsMedia.then(getNewsMedia => {
        dispatch({
            type: 'NEWS_AND_MEDIA_DATA',
            NewsMedia: getNewsMedia

        })
        dispatch({
            type: 'NEWS_AND_MEDIA_DATA_LOADER',
            loading:false

        });
    })
};
export const saveNewsMedia = (params) => dispatch => {
    let saveNewsMedia = projects.Savenewsandmedia(params);
    saveNewsMedia.then(saveNewsMedia => {

        dispatch({
            type: 'SAVE_NEWSMEDIA',
            saveNewsMedia : saveNewsMedia
        })
    })
};
export const StakeholderClick = (masterid,title) => dispatch => {

    dispatch({
        type: "STAKEHOLDER_MENU_UPDATE_SUCCESS",
        masterId:masterid,
        title:title

    });

};
export const getNewsMediaInfo = (params) => dispatch => {

    let getNewsMedia = projects.GetNewsMedia(params);
    dispatch({
        type: 'NEWS_AND_MEDIA_INFO_LOADER',
        loading:true

    });
    getNewsMedia.then(getNewsMedia => {
        dispatch({
            type: 'NEWS_AND_MEDIA_INFO',
            NewsMediaInfo: getNewsMedia

        })
        dispatch({
            type: 'NEWS_AND_MEDIA_INFO_LOADER',
            loading:false

        });
    })
};
export const DeleteProjectMilestone = (params) => dispatch => {
    let Deletemilestone = projects.DeleteMilestone(params);
    Deletemilestone.then(Deletemilestone => {
        dispatch({
            type: 'DELETE_MILESTONE'
        })
    })
};
export const getCollectionInfo = (params) => dispatch => {

    let getCollectionInfo = projects.GetCollectionInfo(params);
    dispatch({
        type: 'GET_COLLECTION_INFO_LOADER',
        loading:true

    });
    getCollectionInfo.then(getCollectionInfo => {
        dispatch({
            type: 'GET_COLLECTION_INFO',
            collectionInfo: getCollectionInfo

        })
        dispatch({
            type: 'GET_COLLECTION_INFO_LOADER',
            loading:false

        });
    })
};

export const deleteSection = (params) => dispatch => {
    let Deletesection = projects.DeleteSection(params);
    Deletesection.then(Deletesection => {
        dispatch({
            type: 'DELETE_SECTION'
        })
    })
};
export const updateNewsmedialist = (params) => dispatch => {

    let moreNewsMedia = projects.GetNewsMedia(params);

    moreNewsMedia.then(moreNewsMedia => {
        dispatch({
            type: 'UPDATE_NEWS_AND_MEDIA_DATA',
            moreNewsMedia: moreNewsMedia

        })

    })
};
export const updateProject = (params) => dispatch => {

    let projectUpdated = projects.updateProject(params);
    dispatch({
        type: 'LOCATION_LOADER',
        loading:true,

    });
    projectUpdated.then(projectUpdated => {
        dispatch({
            type: 'UPDATE_PROJECT_LOCATION',
            projectUpdate: projectUpdated

        })
        dispatch({
            type: 'LOCATION_LOADER',
            loading:false,

        });
    })
};

