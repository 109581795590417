import React, {Component} from 'react';


class MetricFilterLeft extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        return (

            <ul className="filter-left-wrapper" id="defaultId">
                <li>
                    <div className="select-box">
                        <select>
                            <option value="volvo">By Type</option>
                            <option value="Type1">By Type</option>
                            <option value="Type2">By Type</option>
                        </select>
                    </div>
                </li>
                <li>
                    <div className="select-box">
                        <select>
                            <option value="volvo">Choose SDG</option>
                            <option value="Type1">SDG1</option>
                            <option value="Type2">SDG2</option>
                        </select>
                    </div>
                </li>
            </ul>

        );
    }


}
class MetricFilterRight extends Component {
    constructor(props) {
        super(props);
        this.state={
            showCloseButton : false
        }

        this.OrganizeMetrics = this.OrganizeMetrics.bind(this);
    }

    OrganizeMetrics(){
        this.setState({showCloseButton : !this.state.showCloseButton})
    }
    render() {

        return (

            <div className="filter-right-wrapper">
                <div className="pagination-wrapper">
                    <div className="pagination-nav-block">
                        <button className="pagination-nav ico-lib pag-left"></button>
                        <button className="pagination-nav ico-lib pag-right"></button>
                    </div>
                </div>
                <button className={`settings-button ico-lib settings-ico ${this.state.showCloseButton === true ? "filter-close" :""}`} data-toggle="tooltip"
                        data-placement="top" title="" data-original-title="Organize" onClick={this.OrganizeMetrics}></button>
            </div>


        );
    }


}
export {MetricFilterLeft,MetricFilterRight};