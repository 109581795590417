import React, {Component} from 'react';
import axios from "axios";
import SimpleModal from "../SimpleModal";
import FileViewers from "../FileViewers";
import AutoComplete from "../AutoComplete";
import UploadFile from "../../../utilities/Upload/UploadFile";
import {
    getFileIcon,
    getAttachmentPath,
    convertb64toFile,
    forceDownload,
    getFileExtension, convertb64VideotoFile
} from "../../../utilities/CustomFunctions";
import {FRMSIMG_PATH, ES_INDEX, FRMSPDFDOC_PATH, FRMSDOC_PATH, FRMSVID_PATH} from "../../../constants";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import LoaderPHSVG from "../LoaderPHSVG";
import {isBrowser} from "react-device-detect";
import TextEditor from "../TextEditor";
import PaymentGateway from "./PaymentGateway"
import LinkResource from "./LinkResource";
import SpecialEditor from "../SpecialEditor";
import VideoPlayer from "../player/VideoPlayer";

const RichText = React.lazy(() => import ("../RichText"))
const SignaturePad = React.lazy(() => import ("react-signature-canvas"))


class Input extends Component {

    constructor(props) {
        super(props);

    }

    onFieldKeyDown = (e) => {
        let attr = this.props.attributes;
        if (attr.allow_negative && attr.allow_negative === 'Yes') {
            if (["e", "E", "+"].includes(e.key)) {
                e.preventDefault()
            }
        } else {
            if (["e", "E", "+", "-"].includes(e.key)) {
                e.preventDefault()
            }
        }
    }

    onTextFieldKeyDown = (e) => {
        let attr = this.props.attributes;
        if (attr.replace_hyphen && attr.replace_hyphen === 'Yes') {
            if (["`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "=", "+", "?", "=", "{", "}", ";", ":", ",", ".", "~", "<", ">", "|", "'", "[", "]", "-"].includes(e.key)) {
                e.preventDefault()
            }
            if (e.keyCode == 191 || e.keyCode == 220 || e.keyCode == 222 || e.keyCode == 111) {
                e.preventDefault()
            }
        }
    }

    handleInputChange(e) {
        let Reg = new RegExp(/^-?[0-9]\d*(\.\d+)?$/);
        if (e.target.value.length === 0) {
            this.props.handleInputChange(e)
        } else if (Reg.test(e.target.value)) {
            this.props.handleInputChange(e)
        } else {
            if (e.target.value.length === 1 && e.target.value === "-") {
                this.props.handleInputChange(e)
            }
        }

    }

    render() {
        let attr = this.props.attributes;
        let bgcolor = attr.backgroundcolor;
        let textcolor = attr.textcolor;
        if (attr.password && attr.password === 'Yes') {
            return (
                <input type="text"
                       className={`${this.props.inputClassName} ${this.props.addErrorClass ? " red-alert" : ""}`}
                       name={attr.name} id={attr.name}
                       placeholder={attr.placeholder} value="******" autofocus
                       onKeyUp={(e) => this.props.onFieldKeyUp(e, attr.type)} readOnly={attr.readOnly}/>
            )
        } else {
            return (
                <React.Fragment>
                    {attr.type === 'text' && (!attr.hasOwnProperty("replace_hyphen") || (attr.hasOwnProperty("replace_hyphen") && attr.replace_hyphen === 'No')) &&
                    <input type={(attr.password && attr.password === 'Yes') ? 'password' : attr.type}
                           className={`${this.props.inputClassName} ${this.props.addErrorClass ? " red-alert" : ""}`}
                           name={attr.name} id={attr.name} placeholder={attr.placeholder} required={this.props.required}
                           onChange={(e) => this.props.handleInputChange(e)}
                           value={attr.value} maxLength={attr.characterlimit ? attr.characterlimit : ''} autofocus
                           onKeyUp={(e) => this.props.onFieldKeyUp(e, attr.type)}
                           onKeyDown={(e) => this.onTextFieldKeyDown(e)}
                           readOnly={attr.readOnly}
                           ref={(el) => {
                               if (el) {
                                   el.style.setProperty('background-color', attr.colorpicker === 'Yes' ? bgcolor : '', 'important');
                                   el.style.setProperty('color', attr.colorpicker === 'Yes' ? textcolor : '');
                               }
                           }}/>
                    }
                    {attr.type === 'text' && attr.hasOwnProperty("replace_hyphen") && attr.replace_hyphen === 'Yes' &&
                    <input type={(attr.password && attr.password === 'Yes') ? 'password' : attr.type}
                           className={`${this.props.inputClassName} ${this.props.addErrorClass ? " red-alert" : ""}`}
                           name={attr.name} id={attr.name} placeholder={attr.placeholder} required={this.props.required}
                           onChange={(e) => this.props.handleInputChange(e)}
                           value={attr.value} maxLength={attr.characterlimit ? attr.characterlimit : ''} autofocus
                           onKeyUp={(e) => this.props.onFieldKeyUp(e, attr.type)}
                           onKeyDown={(e) => this.onTextFieldKeyDown(e)}
                           onPaste={(e) => this.props.onPasteRestrictSpace(e)}
                           readOnly={attr.readOnly}
                           ref={(el) => {
                               if (el) {
                                   el.style.setProperty('background-color', attr.colorpicker === 'Yes' ? bgcolor : '', 'important');
                                   el.style.setProperty('color', attr.colorpicker === 'Yes' ? textcolor : '');
                               }
                           }}/>
                    }
                    {(attr.type === 'number' && (!attr.hasOwnProperty("allow_negative") || (attr.hasOwnProperty("allow_negative") && attr.allow_negative === 'No'))) &&
                    <>
                        <input type="number"
                               className={`${this.props.inputClassName}  ${this.props.addErrorClass ? " red-alert" : ""}`}
                               name={attr.name}
                               id={attr.name} placeholder={attr.placeholder} required={this.props.required}
                               min={attr.min !== '' ? attr.min : ''} max={attr.max !== '' ? attr.max : ''}
                               onChange={(e) => this.props.handleInputChange(e)} value={attr.value} autofocus
                               onKeyUp={(e) => this.props.onFieldKeyUp(e)} onKeyDown={(e) => this.onFieldKeyDown(e)}
                               inputMode="decimal" step="any" readOnly={attr.readOnly}
                               ref={(el) => {
                                   if (el) {
                                       el.style.setProperty('background-color', attr.colorpicker === 'Yes' ? bgcolor : '', 'important');
                                       el.style.setProperty('color', attr.colorpicker === 'Yes' ? textcolor : '');
                                   }
                               }}
                        />
                        {/*{attr.setasslider && attr.setasslider === 'Yes' &&*/}
                        {/*<div className="number-arrow">*/}
                        {/*    <nav className="ico-lib-v1 top-arrow"></nav>*/}
                        {/*    <nav className="ico-lib-v1 down-arrow"></nav>*/}
                        {/*</div>*/}
                        {/*}*/}
                    </>
                    }
                    {attr.type === 'number' && attr.hasOwnProperty("allow_negative") && attr.allow_negative === 'Yes' &&
                    <>
                        <input type="text"
                               className={`${this.props.inputClassName} ${this.props.addErrorClass ? " red-alert" : ""}`}
                               name={attr.name}
                               id={attr.name} placeholder={attr.placeholder} required={this.props.required}
                               min={attr.min !== '' ? attr.min : ''} max={attr.max !== '' ? attr.max : ''}
                               onChange={(e) => this.handleInputChange(e)} value={attr.value} autofocus
                               onKeyUp={(e) => this.props.onFieldKeyUp(e)} onKeyDown={(e) => this.onFieldKeyDown(e)}
                               inputMode="decimal" step="any" readonly
                               ref={(el) => {
                                   if (el) {
                                       el.style.setProperty('background-color', attr.colorpicker === 'Yes' ? bgcolor : '', 'important');
                                       el.style.setProperty('color', attr.colorpicker === 'Yes' ? textcolor : '');
                                   }
                               }}
                        />
                        {/*{attr.setasslider && attr.setasslider === 'Yes' &&*/}
                        {/*<div className="number-arrow">*/}
                        {/*    <nav className="ico-lib-v1 top-arrow"></nav>*/}
                        {/*    <nav className="ico-lib-v1 down-arrow"></nav>*/}
                        {/*</div>*/}
                        {/*}*/}
                    </>
                    }

                    {attr.type !== 'text' && attr.type !== 'number' && attr.type !== 'email' && attr.type !== 'pan' && attr.type !== 'url' &&
                    <input type={attr.type}
                           className={`${this.props.inputClassName} ${this.props.addErrorClass ? " red-alert" : ""}`}
                           name={attr.name}
                           id={attr.name} placeholder={attr.placeholder} required={this.props.required}
                           min={attr.min !== '' ? attr.min : ''} max={attr.max !== '' ? attr.max : ''}
                           onChange={this.props.handleInputChange} value={attr.value} autofocus
                           onKeyUp={(e) => this.props.onFieldKeyUp(e)}
                           readOnly={attr.readOnly}/>
                    }
                    {(attr.type === 'email' || attr.type === 'pan' || attr.type === 'url') &&
                    <input type={attr.type}
                           className={`${this.props.inputClassName} ${this.props.addErrorClass ? " red-alert" : ""}`}
                           name={attr.name}
                           id={attr.name} placeholder={attr.placeholder} required={this.props.required}
                           min={attr.min !== '' ? attr.min : ''} max={attr.max !== '' ? attr.max : ''}
                           onChange={this.props.handleInputChange} value={attr.value} autofocus
                           onKeyUp={(e) => this.props.onFieldKeyUp(e)}
                           readOnly={attr.readOnly}
                           ref={(el) => {
                               if (el) {
                                   el.style.setProperty('background-color', attr.colorpicker === 'Yes' ? bgcolor : '', 'important');
                                   el.style.setProperty('color', attr.colorpicker === 'Yes' ? textcolor : '');
                               }
                           }}/>
                    }
                </React.Fragment>
            )
        }
    }
}

class TextArea extends Component {

    constructor(props) {
        super(props);
    }


    render() {

        let attr = this.props.attributes;
        let bgcolor = attr.backgroundcolor;
        let textcolor = attr.textcolor;
        return (
            <textarea className={`${this.props.inputClassName} ${(this.props.addErrorClass ? " red-alert" : "")}`}
                      name={attr.name} id={attr.name} placeholder={attr.placeholder}
                      required={this.props.required} onChange={this.props.handleInputChange} value={attr.value}
                      autofocus onKeyUp={(e) => this.props.onFieldKeyUp(e)} readOnly={attr.readOnly}
                      ref={(el) => {
                          if (el) {
                              el.style.setProperty('background-color', attr.colorpicker === 'Yes' ? bgcolor : '', 'important');
                              el.style.setProperty('color', attr.colorpicker === 'Yes' ? textcolor : '');
                          }
                      }}
            ></textarea>
        )
    }
}

class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldcondition: [],
            setcondition: 'No'
        }
    }


    componentDidMount() {

        this.setState({
            fieldCondition: this.props.attributes.fieldcondition,
            setcondition: this.props.attributes.setcondition
        })
    }

    render() {
        //console.log(this.props.checkedValue)
        let attr = this.props.attributes;
        let checkedValue = this.props.checkedValue;
        /*if( this.props.checkedValue && Array.isArray(this.props.checkedValue) && this.props.checkedValue.length > 0) {
            checkedValue = this.props.checkedValue[0]
        }*/

        if (attr.customapi && attr.customapi === 'Yes') {
            checkedValue = this.props.checkedValue[0];
        }
        return (
            <select name={attr.name} id={attr.name} required={this.props.required}
                    onChange={this.props.handleInputChange}
                    className={` ${this.props.selectClass} ${this.props.addErrorClass && " red-alert"}`}
                    value={checkedValue}
                    multiple={attr.multipleselection && attr.multipleselection === 'Yes'} autofocus
                    readOnly={attr.readOnly}>
                <option value=""></option>
                {/*{attr.options.map((opt, opInd) =>
                    <option key={`op-in-${opInd}`} value={opt.value} dangerouslySetInnerHTML={{__html :opt.value}} />
                )}*/}
                {(!attr.customapi || (attr.customapi && attr.customapi === 'No')) && attr.options.map((opt, opInd) =>
                    <option key={`op-in-${opInd}`} value={opt.value} dangerouslySetInnerHTML={{__html: opt.value}}/>
                )}
                {attr.customapi && attr.customapi === 'Yes' && attr.options.map((opt, opInd) =>
                    <option key={`op-in-${opInd}`} value={opt.recordid} dangerouslySetInnerHTML={{__html: opt.value}}/>
                )}
            </select>
        )
    }
}

/*class RadioButtonOld extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let attr = this.props.attributes;
        let checkedValues = this.props.checkedValue;
        return (
            <input type="radio" name={attr.name} id={attr.name} value={this.props.value}  required={this.props.required} onChange={this.props.handleInputChange} checked={checkedValues &&  checkedValues.includes(this.props.value)} />

        )
    }
}*/
class RadioButton extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let attr = this.props.attributes;
        let checkedValues = this.props.checkedValue;
        let fieldoption = this.props.fieldoption;

        return (
            <React.Fragment>

                <input type="radio" name={attr.name} id={attr.name} value={this.props.value}
                       required={this.props.required} onChange={this.props.handleInputChange}
                       checked={checkedValues && checkedValues.includes(this.props.value)} readOnly={attr.readOnly}/>

            </React.Fragment>
        )
    }
}

/*class CheckBoxOld extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let attr = this.props.attributes;
        let checkedValues = this.props.checkedValue;
        return (
            <input type="checkbox" name={attr.name} id={attr.name+"_"+this.props.optionIndex} value={this.props.value}  required={this.props.required} onChange={this.props.handleInputChange} className={`input-check-box ${checkedValues &&  checkedValues.includes(this.props.value)? 'active' : ''}`}/>

        )
    }
}*/
class CheckBox extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let attr = this.props.attributes;
        let checkedValues = this.props.checkedValue;
        let fieldoption = this.props.fieldoption;
        // console.log(checkedValues)
        return (
            <input type="checkbox" name={attr.name} id={attr.name + "_" + this.props.optionIndex}
                   value={this.props.value} required={this.props.required}
                   onChange={this.props.handleInputChange}
                   className={`input-check-box ${checkedValues && checkedValues.includes(this.props.value) ? 'active' : ''}`}
                   readOnly={attr.readOnly}/>
        )
    }
}

class CheckboxWithImage extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let attr = this.props.attributes;
        let checkedValues = this.props.checkedValue;

        return (
            <input type="checkbox" name={attr.name} id={attr.name + "_" + this.props.optionIndex}
                   value={this.props.value} required={this.props.required} onChange={this.props.handleInputChange}
                   className={`input-check-box ${(checkedValues && checkedValues.includes(this.props.value) || (attr.setdefaultval === attr.name)) ? 'active' : ''}`}/>
        )
    }
}

class Attachment extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let attr = this.props.attributes;

        return (

            <>
                <div className="attach-block">
                    <input type="file" name={attr.name}/>
                    <label className="ico-lib att">Attach File Here</label>
                </div>
                <div className="attached-file">
                    <div className="file-tag">
                        Image.JPG
                        <button className="ico-lib cl-fl"></button>
                    </div>
                </div>
            </>
        )
    }
}

/*
class HTMLEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index : 0
        };
        // this.handleChange = this.handleChange.bind(this);
    }
    // handleChange(e) {
    //     this.props.handleTextChange(e.target.getContent(), this.props.index);
    // }
    handleInputChange=(id,val)=> {
        console.log(val)
        this.props.handleTextChange(val, this.props.index);
    }
    /!*handleKeyUp = (e) => {
        let content =  e.target.innerHTML;
        this.props.handleTextChange(content, this.props.index);
    }*!/
    render() {
        let attr = this.props.attributes;
        return (


            <TextEditor
                placeholder="Description"
                className="task-text-area-expandable"
                name={attr.name}
                id={attr.id? attr.id :'editor'}
                value={attr.value===undefined?"":attr.value}
                handleChange={this.handleInputChange}
                bounds={'.expandable-textarea'}
            />



        )
    }
}
*/
class HTMLEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0
        };
        // this.handleChange = this.handleChange.bind(this);
    }

    // handleChange(e) {
    //     this.props.handleTextChange(e.target.getContent(), this.props.index);
    // }
    handleInputChange = (id, val) => {
        console.log(val)
        this.props.handleTextChange(val, this.props.index);
    }

    /*handleKeyUp = (e) => {
        let content =  e.target.innerHTML;
        this.props.handleTextChange(content, this.props.index);
    }*/
    render() {
        let attr = this.props.attributes;
        return (

            <>

                {(ES_INDEX === 'sepalika') &&
                <SpecialEditor
                    placeholder="Description"
                    className="task-text-area-expandable"
                    name={attr.name}
                    id={attr.id ? attr.id : 'editor'}
                    value={attr.value === undefined ? "" : attr.value}
                    handleChange={this.handleInputChange}
                    bounds={'.expandable-textarea'}
                />
                }
                {(ES_INDEX !== 'sepalika') &&
                <TextEditor
                    placeholder="Description"
                    className="task-text-area-expandable"
                    name={attr.name}
                    id={attr.id ? attr.id : 'editor'}
                    value={attr.value === undefined ? "" : attr.value}
                    handleChange={this.handleInputChange}
                    bounds={'.expandable-textarea'}
                />
                }
            </>


        )
    }
}

class DigitalSignature extends Component {
    sigPad = {}

    constructor(props) {
        super(props);
        this.state = {
            trimmedDataURL: ''
        };
    }

    clear = () => {
        this.sigPad.clear()
        this.setState({trimmedDataURL: ''})
    }
    trim = () => {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png')
        })
        this.props.handleSignatureVal(this.sigPad.getTrimmedCanvas()
            .toDataURL('image/png'));

    }

    componentDidMount() {
        let attributes = this.props.value;
        this.setState({
            trimmedDataURL: this.props.value
        }, function () {
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                trimmedDataURL: this.props.value
            }, function () {
            });
        }
    }

    render() {
        let attr = this.props.attributes;
        let trimmedDataURL = this.state.trimmedDataURL;
        //console.log(trimmedDataURL)
        let fromData = '';
        let versionedFieldId = '';
        if (attr.versioning && attr.versioning === 'Yes' && this.props.editId && this.props.editId !== '' && this.props.formMeta && this.props.formMeta.version && this.props.formMeta.version > 1 && this.props.formVersions && this.props.formVersions.length > 0) {

            if (this.props.formVersions.includes(attr.masterid)) {
                versionedFieldId = attr.masterid;
            } else if (this.props.formVersions.includes(attr.referenceId + '_' + attr.refIndexNo)) {
                versionedFieldId = attr.referenceId + '_' + attr.refIndexNo;
            }
        }
        return <div>
            <div className="signature-canvas">
                <div className="signature-left">
                    <div className="label-header">

                        <div className="label-title-block">
                            <label>Signature</label>
                            {versionedFieldId !== '' &&
                            <button className={'ico-lib-v1 prev-version'}
                                    onClick={(e) => this.props.showPreviousVersion(e, attr, '', 0, this.props.pageIndex, versionedFieldId)}></button>
                            }
                        </div>

                    </div>
                    <SignaturePad canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                                  ref={(ref) => {
                                      this.sigPad = ref
                                  }} id={attr.id ? attr.id : 'sig'}
                                  index={attr.index}
                                  onEnd={this.trim}/></div>
                {trimmedDataURL && trimmedDataURL !== '' &&
                <div className="sig-preview"><img
                    src={trimmedDataURL}/></div>
                }

            </div>
            <div className="sig-button-block">
                <button className="general-btn grey" onClick={this.clear}>
                    Clear
                </button>
                {/*<button  className="general-btn" onClick={this.trim}>
            Trim
        </button>*/}
            </div>
        </div>


    }
}

class DataLoad extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let attr = this.props.attributes;
        let fieldValue = (attr.value && attr.value.length > 0) ? attr.value[0].value : '';
        return (

            <>
                <input type="text" className={`fiels-inp-box  ${this.props.addErrorClass ? " red-alert" : ""}`}
                       name={attr.name} id={attr.name} placeholder={attr.placeholder} required={this.props.required}
                       min={attr.min !== 'No' ? attr.min : ''} max={attr.max !== 'No' ? attr.max : ''}
                       onFocus={() => this.props.initiateDataLoad(attr.masterid)}
                       onChange={this.props.handleInputChange} value={fieldValue} readOnly={attr.readOnly}/>

            </>
        )
    }
}

class Toggle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 'Yes'
        }
        this.toggleStatus = this.toggleStatus.bind(this);
    }

    componentDidMount() {
        var status = this.props.attributes.value ? this.props.attributes.value : this.props.attributes.setdefaultval;
        this.setState({status: status},
            function () {
                this.props.handleToggleChange(this.props.attributes.name, status);
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.setState({status: this.props.value});
            this.props.handleToggleChange(this.props.attributes.name, this.props.value);
        }
    }


    toggleStatus() {
        var status = this.state.status === 'Yes' ? 'No' : 'Yes';
        this.setState({status: status});
        this.props.handleToggleChange(this.props.attributes.name, status);
    }

    render() {
        let attr = this.props.attributes;
        let versionedFieldId = '';
        if (attr.versioning && attr.versioning === 'Yes' && this.props.editId && this.props.editId !== '' && this.props.formMeta && this.props.formMeta.version && this.props.formMeta.version > 1 && this.props.formVersions && this.props.formVersions.length > 0) {

            if (this.props.formVersions.includes(attr.masterid)) {
                versionedFieldId = attr.masterid;
            } else if (this.props.formVersions.includes(attr.referenceId + '_' + attr.refIndexNo)) {
                versionedFieldId = attr.referenceId + '_' + attr.refIndexNo;
            }
        }
        return (

            <>
                <div className="field-common field-req-block" id="form_visible">
                    <div className="label-title-block">
                        <label>{attr.fieldLabel}
                            {attr.hint && attr.hint !== '' &&
                            <div className="info-clicker-wrap">
                                <OverlayTrigger key='bsy-tooltip' placement='top'
                                                overlay={
                                                    <Tooltip id="bsy-tooltip">
                                                        {attr.hint}
                                                    </Tooltip>
                                                }
                                >
                                    <button className="info-clicker"></button>
                                </OverlayTrigger>
                            </div>
                            }
                        </label>
                        {versionedFieldId !== '' &&
                        <button className={'ico-lib-v1 prev-version'}
                                onClick={(e) => this.props.showPreviousVersion(e, attr, '', 0, this.props.pageIndex, versionedFieldId)}></button>
                        }
                    </div>

                    <button type="button" className={`btn-toggle ${this.state.status === 'Yes' && 'active'}`}
                            data-target="#text_visibility" data-toggle="button" aria-pressed="false" auto-complete="off"
                            onClick={this.toggleStatus}>
                        <div className="handle"></div>
                    </button>
                    <label className="control control-checkbox" style={{display: 'none'}}>
                        <input type="checkbox" className="inp-chk" id="text_visibility"
                               checked={this.state.status === 'Yes'}/>
                        <div className="control-indicator"></div>
                    </label>
                </div>

            </>
        )
    }
}

class Autofill extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 'Yes'
        }
        this.toggleStatus = this.toggleStatus.bind(this);
    }

    componentDidMount() {
        var status = this.props.attributes.value ? this.props.attributes.value : this.props.attributes.setdefaultval;
        this.setState({status: status},
            function () {
                // this.props.handleToggleChange(this.props.attributes.name, status);
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.setState({status: this.props.value});
            // this.props.handleToggleChange(this.props.attributes.name, this.props.value);
        }
    }


    toggleStatus() {
        var status = this.state.status === 'Yes' ? 'No' : 'Yes';
        this.setState({status: status});
        //this.props.handleToggleChange(this.props.attributes.name, status);
    }


    render() {

        let attr = this.props.attributes;
        return (

            <>

                <AutoComplete
                    eleClass='selectize-custom'
                    fieldTitle={attr.label}
                    placeholder={attr.label}
                    hideTitle={true}
                    sourceDataList={attr.options}
                    searchField='value'
                    showIcon={false}
                    hideMainLabel={false}
                    showSubLabel={true}
                    labelFields='value'
                    valueField='value'
                    subLabelField='additionaltext'
                    showProfile={false}
                    reference={attr.masterid}
                    selectedItems={attr.selectedItems}
                    resultDataList={this.props.resultDataList}
                    addNewSuggestion={true}
                    instantSearch={true}
                    id={attr.masterid}
                    multiSelect={false}
                    actionMethod={this.props.actionMethod}
                    readOnly={attr.readOnly}
                />
            </>
        )
    }
}

class PhotoCaptureold extends Component {
    constructor(props) {
        super(props);
        this.state = {
            capturedImg: '',
            tempImg: '',
            selectedAction: '',
            showPhotoCaptureModal: false,
            showUploadPhotoModal: false,
            showPreview: false,
            iscam: false,
            frontCam: true,
            loading: false
        }

        this.setCapImg = this.setCapImg.bind(this)
    }

    componentDidMount() {
        this.setCapImg()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.setCapImg()
        }

    }

    setCapImg = () => {

        if (this.props.value && Array.isArray(this.props.value) && this.props.value.length > 0) {
            let capturedImg = this.props.value[0].uniqvalue;
            if (capturedImg !== '') {
                capturedImg = FRMSIMG_PATH + this.props.value[0].uniqvalue;
            }
            this.hidePhotoCaptureModal();
            this.setState({capturedImg: capturedImg, loading: false, showPreview: false});

        }
    }

    handleRadioChange = (e) => {
        this.setState({selectedAction: e.target.value});
    }

    getImg = (img) => {
        //this.hidePhotoCaptureModal();
        convertb64toFile(img, 'image/jpeg').then((data) => {
            //this.setState({capturedImg : data.location});
            let fileUrl = data.location;
            let fileArr = fileUrl.split('/images/');
            let filename = fileUrl;
            if (fileArr.length > 1) {
                filename = fileArr[1];
                this.props.handleCapture(this.props.attributes.name, filename);
                //this.setState({capturedImg : img});
            }
        });
        // this.props.handleCapture(this.props.attributes.name,img);
    }
    showPhotoCaptureModal = () => {
        this.setState({
            showPhotoCaptureModal: true
        }, function () {
            this.openCam();
        })
    }
    hidePhotoCaptureModal = () => {
        this.closeCam();
        this.setState({
            showPhotoCaptureModal: false
        })
    }
    removePhoto = () => {
        this.setState({capturedImg: ''});
        this.props.handleCapture(this.props.attributes.name, '');
    }

    openCam = () => {
        var video = document.getElementById('cam_' + this.props.attributes.id);
        this.setState({iscam: true})
        if (navigator.mediaDevices.getUserMedia) {
            let constraints = {video: {facingMode: {exact: this.state.frontCam ? "user" : "environment"}}};
            ////console.log(constraints)
            navigator.mediaDevices.getUserMedia(constraints)
                .then(function (stream) {
                    video.srcObject = stream;

                }.bind(this))
                .catch(function (error) {
                    //console.log(error)
                    //console.log("Something went wrong!");
                });
        }
    }
    closeCam = () => {
        var video = document.getElementById('cam_' + this.props.attributes.id);
        if (video) {
            var stream = video.srcObject;
            if (stream) {
                var tracks = stream.getTracks();

                for (var i = 0; i < tracks.length; i++) {
                    var track = tracks[i];
                    track.stop();
                    this.setState({iscam: false})
                }
            }


            video.srcObject = null;
        }


    }
    flipCam = () => {
        this.closeCam();
        this.setState({
            frontCam: !this.state.frontCam
        }, function () {
            ////console.log(this.state.frontCam)
            this.openCam();
        })
    }
    doCapture = () => {
        let fn = 'camcap_' + new Date().getTime() + '.jpg';

        var video = document.getElementById('cam_' + this.props.attributes.id);
        var canvas = document.getElementById('canvas_' + this.props.attributes.id);

        var context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        var data = canvas.toDataURL('image/png');
        var src = encodeURI(data);

        this.closeCam();

        this.setState({showPreview: true, tempImg: src});

        // this.getImg(src)
    }

    acceptImage = () => {
        this.setState({loading: true})
        this.getImg(this.state.tempImg)
    }

    retakePhoto = () => {
        this.setState({
            capturedImg: '',
        }, function () {
            this.showPhotoCaptureModal()
        })
    }

    render() {
        /*let attr = this.props.attributes;
        let selectedAction = this.state.selectedAction ? this.state.selectedAction : this.props.attributes.selectedAction;*/
        let attr = this.props.attributes;
        let capturedImg = this.state.capturedImg ? this.state.capturedImg : '';
        let wid = 500;
        let hgt = 500;
        let userImg = '';
        let showLoader = true;
        let modalView = this.props.formTemplateType === 'media' ? false : true;

        return (
            <React.Fragment>

                {capturedImg !== '' &&
                <div className="image-container">
                    <img
                        src={capturedImg}/>
                    <div class="sub-image-option">
                        <button className="general-btn reBtn" onClick={this.retakePhoto}>Retake</button>
                        <button className="general-btn removeBtn" onClick={this.removePhoto}>Remove</button>
                    </div>
                </div>
                }
                {capturedImg === '' &&
                <div className="photo-capture-wrapper">

                    <div className="photo-capture-button-block">
                        {!this.state.showPhotoCaptureModal &&
                        <nav className="photo-capture-btn take" onClick={this.showPhotoCaptureModal}>Photo Capture</nav>
                        }
                        {this.state.showPhotoCaptureModal && !modalView &&
                        <div className="capture-mode-1">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={this.hidePhotoCaptureModal}></button>
                            <div id={'cam_cont_' + attr.id} className="photo-capture-cam"
                                 style={{width: wid, height: hgt}}>
                                <video autoPlay={true} id={'cam_' + attr.id}
                                       style={{width: wid, height: hgt, display: this.state.iscam ? 'block' : 'none'}}
                                ></video>
                                <canvas id={'canvas_' + attr.id}
                                        style={{width: wid, height: hgt, display: !this.state.iscam ? 'block' : 'none'}}
                                ></canvas>

                                <div className="shot-preview-button-block">

                                    {/* step - 2 */}
                                    {!this.state.iscam &&
                                    <React.Fragment>
                                        <button className="general-btn" onClick={this.acceptImage}>Accept</button>
                                        <button className="general-btn grey" onClick={this.openCam}>Re-take</button>
                                    </React.Fragment>
                                    }

                                    {/* step - 1 */}
                                    {this.state.iscam &&
                                    <React.Fragment>
                                        <button className="flipIcon" onClick={this.flipCam}></button>
                                        <button className="capture" onClick={this.doCapture}></button>
                                        <button className="retake" onClick={this.openCam}></button>
                                    </React.Fragment>

                                    }
                                </div>


                            </div>
                            {this.state.loading &&
                            <span className="Loader-holder">
                                                <LoaderPHSVG/>
                                            </span>
                            }
                        </div>
                        }
                    </div>
                </div>
                }

                {this.state.showPhotoCaptureModal && modalView &&
                <div className="photo-capture">
                    <div className="modal photo-modal fade show" id="phCapModal" tabIndex="-1" role="dialog"
                         aria-labelledby="phCapModalLabel" aria-hidden="true" style={{display: 'block'}}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="phCapModalLabel">Capture Photo</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                            onClick={this.hidePhotoCaptureModal}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="capture-mode-1">

                                        <div id={'cam_cont_' + attr.id} className="photo-capture-cam"
                                             style={{width: wid, height: hgt}}>
                                            <video autoPlay={true} id={'cam_' + attr.id} style={{
                                                width: wid,
                                                height: hgt,
                                                display: this.state.iscam ? 'block' : 'none'
                                            }}
                                            ></video>
                                            <canvas id={'canvas_' + attr.id} style={{
                                                width: wid,
                                                height: hgt,
                                                display: !this.state.iscam ? 'block' : 'none'
                                            }}
                                            ></canvas>

                                            <div className="shot-preview-button-block">

                                                {/* step - 2 */}
                                                {!this.state.iscam &&
                                                <React.Fragment>
                                                    <button className="general-btn" onClick={this.acceptImage}>Accept
                                                    </button>
                                                    <button className="general-btn grey"
                                                            onClick={this.openCam}>Re-take
                                                    </button>
                                                </React.Fragment>
                                                }

                                                {/* step - 1 */}
                                                {this.state.iscam &&
                                                <React.Fragment>
                                                    <button className="flipIcon" onClick={this.flipCam}></button>
                                                    <button className="capture" onClick={this.doCapture}></button>
                                                    <button className="retake" onClick={this.openCam}></button>
                                                </React.Fragment>

                                                }

                                            </div>


                                        </div>
                                        {this.state.loading &&
                                        <span className="Loader-holder">
                                                <LoaderPHSVG/>
                                            </span>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                }

            </React.Fragment>

        );
    }
}

class PhotoCapture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            capturedImg: '',
            tempImg: '',
            selectedAction: '',
            showPhotoCaptureModal: false,
            showUploadPhotoModal: false,
            showPreview: false,
            iscam: false,
            frontCam: true,
            loading: false
        }

        this.setCapImg = this.setCapImg.bind(this)
    }

    componentDidMount() {
        this.setCapImg()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.setCapImg()
        }

    }

    setCapImg = () => {

        if (this.props.value && Array.isArray(this.props.value) && this.props.value.length > 0) {
            let capturedImg = this.props.value[0].uniqvalue;
            if (capturedImg !== '') {
                capturedImg = FRMSIMG_PATH + this.props.value[0].uniqvalue;
            }
            this.hidePhotoCaptureModal();
            this.setState({capturedImg: capturedImg, loading: false, showPreview: false});

        }
    }

    handleRadioChange = (e) => {
        this.setState({selectedAction: e.target.value});
    }

    getImg = (img) => {
        //this.hidePhotoCaptureModal();
        convertb64toFile(img, 'image/jpeg').then((data) => {
            //this.setState({capturedImg : data.location});
            let fileUrl = data.location;
            let fileArr = fileUrl.split('/images/');
            let filename = fileUrl;
            if (fileArr.length > 1) {
                filename = fileArr[1];
                this.props.handleCapture(this.props.attributes.name, filename);
                //this.setState({capturedImg : img});
            }
        });
        // this.props.handleCapture(this.props.attributes.name,img);
    }
    showPhotoCaptureModal = () => {
        this.setState({
            showPhotoCaptureModal: true
        }, function () {
            this.openCam();
        })
    }
    hidePhotoCaptureModal = () => {
        this.closeCam();
        this.setState({
            showPhotoCaptureModal: false
        })
    }
    removePhoto = () => {
        this.setState({capturedImg: ''});
        this.props.handleCapture(this.props.attributes.name, '');
    }

    openCam = () => {
        var video = document.getElementById('cam_' + this.props.attributes.id);
        this.setState({iscam: true})
        if (navigator.mediaDevices.getUserMedia) {
            let constraints = {video: {facingMode: {exact: this.state.frontCam ? "user" : "environment"}}};
            ////console.log(constraints)
            navigator.mediaDevices.getUserMedia(constraints)
                .then(function (stream) {
                    video.srcObject = stream;

                }.bind(this))
                .catch(function (error) {
                    //console.log(error)
                    //console.log("Something went wrong!");
                });
        }
    }
    closeCam = () => {
        var video = document.getElementById('cam_' + this.props.attributes.id);
        if (video) {
            var stream = video.srcObject;
            if (stream) {
                var tracks = stream.getTracks();

                for (var i = 0; i < tracks.length; i++) {
                    var track = tracks[i];
                    track.stop();
                    this.setState({iscam: false})
                }
            }


            video.srcObject = null;
        }


    }
    flipCam = () => {
        this.closeCam();
        this.setState({
            frontCam: !this.state.frontCam
        }, function () {
            ////console.log(this.state.frontCam)
            this.openCam();
        })
    }
    doCapture = () => {
        let fn = 'camcap_' + new Date().getTime() + '.jpg';

        var video = document.getElementById('cam_' + this.props.attributes.id);
        var canvas = document.getElementById('canvas_' + this.props.attributes.id);

        var context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        var data = canvas.toDataURL('image/png');
        var src = encodeURI(data);

        this.closeCam();

        this.setState({showPreview: true, tempImg: src});

        // this.getImg(src)
    }

    acceptImage = () => {
        this.setState({loading: true})
        this.getImg(this.state.tempImg)
    }

    retakePhoto = () => {
        this.setState({
            capturedImg: '',
        }, function () {
            this.showPhotoCaptureModal()
        })
    }

    render() {
        /*let attr = this.props.attributes;
        let selectedAction = this.state.selectedAction ? this.state.selectedAction : this.props.attributes.selectedAction;*/
        let attr = this.props.attributes;
        let capturedImg = this.state.capturedImg ? this.state.capturedImg : '';
        let wid = 500;
        let hgt = 500;
        let userImg = '';
        let showLoader = true;
        let modalView = this.props.formTemplateType === 'media' ? false : true;

        return (
            <React.Fragment>

                {capturedImg !== '' &&
                <div className="image-container">
                    <img
                        src={capturedImg}/>
                    <div class="sub-image-option">
                        <button className="general-btn reBtn" onClick={this.retakePhoto}>Retake</button>
                        <button className="general-btn removeBtn" onClick={this.removePhoto}>Remove</button>
                    </div>
                </div>
                }
                {capturedImg === '' &&
                <div className="photo-capture-wrapper">

                    <div className="photo-capture-button-block">
                        {!this.state.showPhotoCaptureModal &&
                        <nav className="photo-capture-btn take" onClick={this.showPhotoCaptureModal}>Photo Capture</nav>
                        }
                        {this.state.showPhotoCaptureModal && !modalView &&
                        <div className="capture-mode-1">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={this.hidePhotoCaptureModal}></button>
                            <div id={'cam_cont_' + attr.id} className="photo-capture-cam"
                                 style={{width: wid, height: hgt}}>
                                <video autoPlay={true} id={'cam_' + attr.id}
                                       style={{width: wid, height: hgt, display: this.state.iscam ? 'block' : 'none'}}
                                ></video>
                                <canvas id={'canvas_' + attr.id}
                                        style={{width: wid, height: hgt, display: !this.state.iscam ? 'block' : 'none'}}
                                ></canvas>

                                <div className="shot-preview-button-block">

                                    {/* step - 2 */}
                                    {!this.state.iscam &&
                                    <React.Fragment>
                                        <button className="general-btn" onClick={this.acceptImage}>Accept</button>
                                        <button className="general-btn grey" onClick={this.openCam}>Re-take</button>
                                    </React.Fragment>
                                    }

                                    {/* step - 1 */}
                                    {this.state.iscam &&
                                    <React.Fragment>
                                        <button className="flipIcon" onClick={this.flipCam}></button>
                                        <button className="capture" onClick={this.doCapture}></button>
                                        <button className="retake" onClick={this.openCam}></button>
                                    </React.Fragment>

                                    }
                                </div>


                            </div>
                            {this.state.loading &&
                            <span className="Loader-holder">
                                                <LoaderPHSVG/>
                                            </span>
                            }
                        </div>
                        }
                    </div>
                </div>
                }

                {this.state.showPhotoCaptureModal && modalView &&
                <div className="photo-capture">
                    <div className="modal photo-modal fade show" id="phCapModal" tabIndex="-1" role="dialog"
                         aria-labelledby="phCapModalLabel" aria-hidden="true" style={{display: 'block'}}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="phCapModalLabel">Capture Photo</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                            onClick={this.hidePhotoCaptureModal}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="capture-mode-1">

                                        <div id={'cam_cont_' + attr.id} className="photo-capture-cam"
                                             style={{width: wid, height: hgt}}>
                                            <video autoPlay={true} id={'cam_' + attr.id} style={{
                                                width: wid,
                                                height: hgt,
                                                display: this.state.iscam ? 'block' : 'none'
                                            }}
                                            ></video>
                                            <canvas id={'canvas_' + attr.id} style={{
                                                width: wid,
                                                height: hgt,
                                                display: !this.state.iscam ? 'block' : 'none'
                                            }}
                                            ></canvas>

                                            <div className="shot-preview-button-block">

                                                {/* step - 2 */}
                                                {!this.state.iscam &&
                                                <React.Fragment>
                                                    <button className="general-btn" onClick={this.acceptImage}>Accept
                                                    </button>
                                                    <button className="general-btn grey"
                                                            onClick={this.openCam}>Re-take
                                                    </button>
                                                </React.Fragment>
                                                }

                                                {/* step - 1 */}
                                                {this.state.iscam &&
                                                <React.Fragment>
                                                    <button className="flipIcon" onClick={this.flipCam}></button>
                                                    <button className="capture" onClick={this.doCapture}></button>
                                                    <button className="retake" onClick={this.openCam}></button>
                                                </React.Fragment>

                                                }

                                            </div>


                                        </div>
                                        {this.state.loading &&
                                        <span className="Loader-holder">
                                                <LoaderPHSVG/>
                                            </span>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                }

            </React.Fragment>

        );
    }
}

class VideoCapture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            capturedImg: '',
            tempVideo: '',
            selectedAction: '',
            showPhotoCaptureModal: false,
            showUploadPhotoModal: false,
            showPreview: false,
            iscam: false,
            frontCam: true,
            loading: false,
            recordingTimeMS: 60000,
            recorder: null,
            stopped: false,
            initRecord: false
        }

        this.setCapVideo = this.setCapVideo.bind(this)
    }

    componentDidMount() {
        //this.setCapImg();
        let attr = this.props.attributes;
        let value = attr.value;
        let preview = document.getElementById("vid_cam_" + this.props.attributes.id);
        let recording = document.getElementById("recording");
        let startButton = document.getElementById("startButton");
        let stopButton = document.getElementById("stopButton");
        // let downloadButton = document.getElementById("downloadButton");
        // let logElement = document.getElementById("log")
        let recordingTimeMS = this.state.recordingTimeMS;

        this.setState({
            preview: preview,
            recording: recording,
            startButton: startButton,
            stopButton: stopButton,
            // downloadButton : downloadButton,
            //logElement : logElement
        });
        let beta = this;
        if (startButton) {
            startButton.addEventListener("click", function () {
                navigator.mediaDevices.getUserMedia({
                    video: true,
                    audio: true
                }).then(stream => {
                    preview.srcObject = stream;
                    //downloadButton.href = stream;
                    preview.captureStream = preview.captureStream || preview.mozCaptureStream;
                    return new Promise(resolve => preview.onplaying = resolve);
                }).then(() => beta.startRecording(preview.captureStream(), recordingTimeMS))
                    .then(recordedChunks => {
                        let recordedBlob = new Blob(recordedChunks, {type: "video/webm"});
                        recording.src = URL.createObjectURL(recordedBlob);
                        //downloadButton.href = recording.src;
                        //downloadButton.download = "RecordedVideo.webm"


                        /* beta.log("Successfully recorded " + recordedBlob.size + " bytes of " +
                             recordedBlob.type + " media.");*/
                    })
                    .catch(this.log);
            }, false);
        }

        if (stopButton) {
            stopButton.addEventListener("click", function () {
                beta.closeCam()
                beta.setState({
                    initRecord: false,
                    loading: true
                })
                let recorder = beta.state.recorder;
                recorder.state === "recording" && recorder.stop();
                recorder.onstop = e => {
                    //alert("dfgdgdg")
                    const recordedBlob = new Blob(beta.state.data, {type: "video/webm"});
                    recording.src = URL.createObjectURL(recordedBlob);
                    //downloadButton.href = recording.src;
                    //console.log(recording.src)
                    // downloadButton.download = "RecordedVideo.webm";


                    fetch(recording.src).then(data => {
                        const blob = data.blob();
                        return blob;
                    }).then(blob => {
                        let reader = new window.FileReader();

                        reader.onloadend = function () {
                            const data = reader.result;
                            //console.log(data);

                            beta.setState({
                                tempVideo: data
                            });
                            beta.getVideo(data)
                        };

                        reader.readAsDataURL(blob);
                    });

                    beta.log("Successfully recorded " + recordedBlob.size + " bytes of " +
                        recordedBlob.type + " media.");
                }
            }, false);
        }

        this.setCapVideo();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            this.setCapVideo()
        }

    }

    setCapVideo = () => {

        if (this.props.value && Array.isArray(this.props.value) && this.props.value.length > 0) {
            let capturedVid = this.props.value[0].uniqvalue;
            if (capturedVid !== '') {
                capturedVid = FRMSVID_PATH + this.props.value[0].uniqvalue;
            }
            this.setState({tempVideo: capturedVid, loading: false});

        }
    }

    handleRadioChange = (e) => {
        this.setState({selectedAction: e.target.value});
    }

    getVideo = (video) => {
        //this.hidePhotoCaptureModal();
        convertb64VideotoFile(video, 'video/mp4').then((data) => {
            //this.setState({capturedImg : data.location});
            let fileUrl = data.location;
            // console.log(fileUrl)
            let fileArr = fileUrl.split('/videos/');
            let filename = fileUrl;
            if (fileArr.length > 1) {
                filename = fileArr[1];
                //alert(filename);

                this.props.handleCapture(this.props.attributes.name, filename);
                //this.setState({capturedImg : img});
            }
        });
        // this.props.handleCapture(this.props.attributes.name,img);
    }
    showPhotoCaptureModal = () => {
        this.setState({
            showPhotoCaptureModal: true
        }, function () {
            this.openCam();
        })
    }
    hidePhotoCaptureModal = () => {
        this.closeCam();
        this.setState({
            showPhotoCaptureModal: false
        })
    }
    removePhoto = () => {
        this.setState({capturedImg: ''});
        this.props.handleCapture(this.props.attributes.name, '');
    }

    openCam = () => {
        var video = document.getElementById('vid_cam_' + this.props.attributes.id);
        this.setState({iscam: true})
        if (navigator.mediaDevices.getUserMedia) {
            let constraints = {video: {facingMode: {exact: this.state.frontCam ? "user" : "environment"}}};
            ////console.log(constraints)
            navigator.mediaDevices.getUserMedia(constraints)
                .then(function (stream) {
                    video.srcObject = stream;

                }.bind(this))
                .catch(function (error) {
                    //console.log(error)
                    //console.log("Something went wrong!");
                });
        }
    }
    closeCam = () => {
        var video = document.getElementById('vid_cam_' + this.props.attributes.id);
        if (video) {
            var stream = video.srcObject;
            if (stream) {
                var tracks = stream.getTracks();

                for (var i = 0; i < tracks.length; i++) {
                    var track = tracks[i];
                    track.stop();
                    this.setState({iscam: false})
                }
            }


            video.srcObject = null;
        }


    }
    flipCam = () => {
        this.closeCam();
        this.setState({
            frontCam: !this.state.frontCam
        }, function () {
            ////console.log(this.state.frontCam)
            this.openCam();
        })
    }
    startRecording = (stream, lengthInMS) => {
        let recorder = new MediaRecorder(stream);
        let data = [];

        recorder.ondataavailable = event => data.push(event.data);
        recorder.start();
        this.log(recorder.state + " for " + (lengthInMS / 1000) + " seconds...");

        let stopped = new Promise((resolve, reject) => {
            recorder.onstop = resolve;
            recorder.onerror = event => reject(event.name);
        });

        let recorded = this.state.stopped ? (recorder.state === "recording" && recorder.stop()) : null;
        //console.log(recorded)

        /*let recorded = this.wait(lengthInMS).then(
            () => recorder.state == "recording" && recorder.stop()
        );*/

        this.setState({
            recorder: recorder,
            data: data,
            initRecord: true
        })

        let beta = this;
        return Promise.all([
            stopped,
            recorded
        ])
            .then(() => {
                    beta.setState({
                        recorder: recorder,
                        data: data
                    })
                    return data;
                }
            );


    }


    acceptVideo = () => {
        this.setState({loading: true})
        this.getVideo(this.state.tempVideo)
    }

    /*    retakePhoto = () => {
         this.setState({
             capturedImg :'',
         },function(){
             this.showPhotoCaptureModal()
         })
     }*/
    log = (msg) => {
        // this.state.logElement.innerHTML += msg + "\n";
    }

    wait = (delayInMS) => {
        return new Promise(resolve => setTimeout(resolve, delayInMS));
    }

    removeVideo = () => {
        this.setState({
            initRecord: false,
            tempVideo: ''
        }, function () {
            this.props.handleCapture(this.props.attributes.name, '');
        })
    }


    render() {

        let recordingTimeMS = 5000;
        let attr = this.props.attributes;
        let value = attr.value;

        return (
            <React.Fragment>


                <div class="label-title-block">
                    <label class="anim-text">Video Capture</label>
                </div>
                <div className="video-recorder-block">

                    <div className={`video-recorder-start ${this.state.tempVideo !== '' ? 'hide' : ''}`}>
                        <h2>Preview</h2>
                        <video id={"vid_cam_" + attr.id} width="160" height="120" autoPlay muted></video>
                        <div class="video-record-options">

                            {/* <button id="pauseButton" className={`record-button pause-record ${!this.state.initRecord ? 'hide' : ''} `} title="Pause"><i
                                class="fa fa-pause-circle" aria-hidden="true"></i></button> */}

                            <button id="startButton"
                                    class={`record-button start-record ${this.state.initRecord ? 'hide' : ''}`}
                                    title="Start"><i
                                class="fa fa-circle" aria-hidden="true"></i></button>

                            <button id="stopButton"
                                    class={`record-button stop-record ${!this.state.initRecord ? 'hide' : ''}`}
                                    title="Stop"><i
                                class="fa fa-stop-circle" aria-hidden="true"></i></button>
                        </div>
                    </div>

                    <div className={`video-recorder-end ${this.state.tempVideo === '' ? 'hide' : ''} `}>
                        <h2>Recording</h2>
                        <video id="recording" width="160" height="120" src={this.state.tempVideo} controls></video>
                        {/* <div className="video-record-options">
                            <button id="downloadButton" className="button">Download</button>
                        </div> */}
                        <div className="output-options">
                            <button id="remove_video" className="record-button close-recorder"
                                    onClick={this.removeVideo}><i class="fa fa-times-circle" aria-hidden="true"></i>
                            </button>
                            {/* <button id="download_video" className="record-button download-recorder" onClick={this.removeVideo}><i class="fa fa-download" aria-hidden="true"></i></button> */}
                        </div>

                    </div>

                    {this.state.loading &&
                    <span className="Loader-holder">
                        <LoaderPHSVG/>
                    </span>
                    }

                    {/* <div id="log"></div> */}

                </div>


            </React.Fragment>

        );
    }
}

function DisplayText(props) {
    let attr = props.attributes;
    let fieldLabel = attr.fieldLabel ? attr.fieldLabel : attr.label;
    let displaytext = attr.displaytext ? attr.displaytext : '';

    function createDisplaytext() {
        let displaytext = attr.displaytext && attr.displaytext !== '' ? atob(attr.displaytext) : '';
        return {__html: displaytext};
    }

    return (

        <>
            {attr.displaytextstatus === 'normaltext' &&
            <p>
                {displaytext}
            </p>
            }
            {attr.displaytextstatus === 'richtext' &&
            <div dangerouslySetInnerHTML={createDisplaytext()} className="typeform-display-text"/>
            }
        </>
    )
}

class Agree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAgreeModalopen: false,
            agreerichtext: '',
            fieldValue: ''
        }
    }

    componentDidMount() {
        this.setState({fieldValue: this.props.value})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.value !== this.props.value) {
            this.setState({fieldValue: this.props.value})
        }
    }

    handleAgreeChange = (e) => {
        var fieldName = this.props.attributes.name;
        var values;
        let fieldValue = this.state.fieldValue === '' ? 'yes' : '';
        this.setState({fieldValue: fieldValue})
        let pageIndex = this.props.pageIndex;
        this.props.setFieldValues(fieldName, fieldValue, pageIndex)
    }
    createAgreeurl = (attr) => {

        let clickabletext = attr.fieldLabel ? attr.fieldLabel : attr.label;
        if (attr.clickabletext !== "" && attr.clickabletext !== undefined) {
            var disptext = attr.clickabletext;
            var displaylabel = attr.fieldLabel ? attr.fieldLabel : attr.label;
            var filteredtext = displaylabel.includes(disptext);
            if (filteredtext === true) {
                var agreerichtext = attr.agreerichtext ? attr.agreerichtext : '#';
                var replacetext = '<a target="_blank" href=' + agreerichtext + '>' + disptext + '</a>';
                var clicktext = displaylabel.replace(disptext, replacetext);
                clickabletext = '<div class="custom-checkbox-text"><p>' + clicktext + '</p></div>'
            }
        }
        return {__html: clickabletext};
    }
    createAgreerichtext = (clickabletext) => {
        return {__html: clickabletext};
    }
    showAgreeModal = (agreerichtext) => {
        let agreetext = agreerichtext;
        this.setState({
            agreerichtext: agreetext,
            showAgreeModalopen: !this.state.showAgreeModalopen,
        });
    }
    showAgreeCloseModal = () => {
        this.setState({showAgreeModalopen: !this.state.showAgreeModalopen,});
    }

    createMarkup = () => {
        let agreerich = this.state.agreerichtext;
        return {__html: agreerich};
    }

    render() {
        let attr = this.props.attributes;
        let disptext = attr.fieldLabel ? attr.fieldLabel : attr.label;
        let beforeText = '';
        let afterText = '';
        let agreerichtext = attr.agreerichtext;
        if (attr.agreerichtextstatus === 'agreerichtext') {
            if (attr.clickabletext !== "" && attr.clickabletext !== undefined) {
                disptext = attr.clickabletext;
                var displaylabel = attr.fieldLabel ? attr.fieldLabel : attr.label;
                var filteredtext = displaylabel.split(disptext);
                beforeText = filteredtext[0];
                afterText = filteredtext[1];
                agreerichtext = attr.agreerichtext ? atob(attr.agreerichtext) : '';
            }
        }
        let checkedValues = this.props.checkedValue;

        return (
            <React.Fragment>

                <div className="agreement">
                    <div className="custom-check">
                        {attr.enablecheckbox === 'Yes' &&
                        <div className="custom-checkbox">

                            <input type="checkbox" name={attr.name} id={attr.name + "_0"} value={this.props.value}
                                   required={this.props.required} onChange={this.handleAgreeChange}
                                   className={`input-check-box ${checkedValues && checkedValues.includes(this.state.fieldValue) ? 'active' : ''}`}
                                   checked={this.state.fieldValue === "yes"}/>


                            <label></label>
                        </div>
                        }
                        <div className="custom-checkbox-text">
                            {attr.agreerichtextstatus === 'inputurl' &&
                            <p dangerouslySetInnerHTML={this.createAgreeurl(attr)}></p>
                            }
                            {attr.agreerichtextstatus === 'agreerichtext' &&

                            <>
                                {attr.addlink === 'Yes' &&
                                <p>{beforeText} <a href="javascript:void(0);"
                                                   onClick={() => this.showAgreeModal(agreerichtext)}><span
                                    dangerouslySetInnerHTML={this.createAgreerichtext(disptext)}></span></a> {afterText}
                                </p>
                                }
                                {attr.addlink === 'No' &&
                                <p>{beforeText} <span
                                    dangerouslySetInnerHTML={this.createAgreerichtext(disptext)}></span>{afterText}
                                </p>
                                }
                            </>

                            }
                        </div>
                    </div>
                    {this.state.showAgreeModalopen && attr.popupenable === "Yes" && this.state.agreerichtext !== '' &&
                    <div id={`dis-pop`} className={`cs-modal fade bsy-modal agree-modal  in show `}>
                        <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="task-head-text">{``}</h5>
                                    <div className="modal-header-options">
                                        <button className="modal-option-button" onClick={this.showAgreeCloseModal}>
                                            <span className="icon-in icon-close-in"></span>
                                        </button>
                                    </div>
                                </div>

                                <div className="modal-body">
                                    <div className="terms-text-wrapper">
                                        <div dangerouslySetInnerHTML={this.createMarkup()} className="terms-text"></div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Button className="general-btn grey"
                                            onClick={this.showAgreeCloseModal}> {`Cancel`}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </React.Fragment>

        );
    }
}

class LoggedIn extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        let attr = this.props.attributes;
        return (
            <input type="text" className={`fiels-inp-box ${this.props.addErrorClass ? " red-alert" : ""}`}
                   name={attr.name} id={attr.name} placeholder={attr.placeholder} value={attr.value} readOnly={true}/>
        )

    }
}

class OTP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAction: '',
            date: new Date(),
            seconds: 15,
            timer: false,
            buttontext: 'Send OTP',
            showVerify: false,
            validNumber: false,
            mobileNumber: '',
            validOtp: false,
            phoneReadOnly: false,
            activeOtp: '',
            submitMsg: false,
            changeNumber: false,
            verfied: false
        }
    }

    componentDidMount() {
        // this.timerID = setInterval(
        //     this.tick(),                    //problematic line
        //     1000
        // );
        // this.timer = setInterval(this.tick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleOtpChange = (e) => {
        var value = e.target.value;
        var fieldName = e.target.name;
        this.setState({validNumber: false})
        // var pattern = new RegExp(/^+\d{1,5}\d{6,11}$/);
        // if (!pattern.test(value)) {
        //     this.setState({validNumber: true})
        // }
        if (isNaN(value)) {
            this.setState({validNumber: true, mobileNumber: ''})
        } else if (value.length > 10) {
            this.setState({validNumber: true})
        } else {
            this.setState({validNumber: false, mobileNumber: value})
            // this.props.handleInputChange(e)
            // let pageindx = this.props.pageIndex;
            // this.props.handleInputOtpChange(e, pageindx)
        }
    }
    otpSend = () => {
        if (this.state.validNumber === false && this.state.mobileNumber !== '') {
            this.setState({
                timer: true,
                seconds: this.props.attributes.otptimer,
                otp: '',
                changeNumber: false,
                showVerify: true,
                validOtp: false
            })

            this.timer = setInterval(this.tick, 1000);


            var mobile = this.state.mobileNumber, service = this.props.attributes.otpservice,
                fieldid = this.props.attributes.masterid;
            this.props.sendOtpfield(mobile, service, fieldid)

        }

    }
    tick = () => {
        if (this.props.bsynOtp !== false) {
            this.setState({timer: true})
            if (this.state.seconds > 0) {
                this.setState({seconds: this.state.seconds - 1})
            } else {
                clearInterval(this.timer);
                this.setState({
                    timer: false,
                    buttontext: 'Resend OTP',
                    changeNumber: true
                })
            }
        } else {
            this.setState({
                timer: false,
                changeNumber: false
            })
        }
    }
    handleOtpSubmit = (e) => {
        var otpvalue = e.target.value;
        this.setState({otp: otpvalue, validOtp: false, activeOtp: otpvalue !== '' ? 'active' : ''})

    }
    otpVerify = () => {
        if (this.state.otp !== '') {
            this.props.verifyOtp(this.state.mobileNumber, this.state.otp)
            this.setState({validOtp: false, submitMsg: true, verfied: true})
        } else {
            this.setState({validOtp: true, activeOtp: ''})
        }
    }
    NumberChange = () => {
        this.setState({showVerify: false})
    }

    render() {
        let attr = this.props.attributes;

        return (
            <React.Fragment>
                {this.state.showVerify && this.props.bsynOtp === true && this.props.recievedOtp !== true ?
                    <input type="number" className={`fiels-inp-box ${this.props.addErrorClass ? " red-alert" : ""}`}
                           placeholder='please enter otp' onChange={(e) => this.handleOtpSubmit(e)}
                           value={this.state.otp}
                    />
                    :
                    <input type="number" className={`fiels-inp-box ${this.props.addErrorClass ? " red-alert" : ""}`}
                           name={attr.name} id={attr.name} placeholder='please enter your mobile number'
                           value={this.state.mobileNumber}
                           readOnly={this.props.recievedOtp === true ? true : false}
                           onChange={(e) => this.handleOtpChange(e)}
                    />
                }

                <div className="otp-button-block">
                    {(this.state.validNumber || this.props.bsynOtp === false) ?
                        <p class={`error-label`}>Please enter valid mobile number</p> : (this.state.timer ?
                            <p>You can resend OTP after <span class="highlight">{this.state.seconds} Seconds</span>
                            </p> : ((this.state.validOtp === true || this.props.recievedOtp === false) ?
                                <p className={`error-label`}>Please enter correct
                                    otp</p> : (this.state.changeNumber && this.props.recievedOtp !== true ?
                                    <a onClick={this.NumberChange}>Change
                                        Number</a> : ((this.props.recievedOtp === true && this.state.submitMsg === true) ?
                                        <p className={`success-label`}>OTP submitted succesfully</p> : <p></p>))))
                    }
                    <div>
                        {attr.otpbuttontype === 'text' && !this.state.timer && this.props.recievedOtp !== true &&
                        <button onClick={this.otpSend} style={{color: attr.otptextcolor}}>{attr.otptext}</button>
                        }
                        {attr.otpbuttontype === 'button' && !this.state.timer && this.props.recievedOtp !== true &&
                        <button onClick={this.otpSend} className="general-btn" style={{
                            color: attr.otptextcolor,
                            backgroundColor: attr.otpbgcolor
                        }}>{this.state.buttontext}</button>
                        }
                        {this.state.showVerify && this.props.bsynOtp === true && this.props.recievedOtp !== true &&
                        <button onClick={this.otpVerify} className="general-btn verify">Verify</button>
                        }
                    </div>
                </div>
                <h5 className="field-header">{this.state.showVerify && this.props.bsynOtp === true && this.props.recievedOtp !== true ? 'OTP' : 'Mobile Number'}</h5>

            </React.Fragment>
        )

    }
}

class Rating extends Component {

    constructor(props) {
        super(props);
        this.state = {
            starred: 0,
            value: [],
            clearRating: false
        }
    }

    componentDidMount() {
        let count = 5;
        let val = [];
        if (count > 0) {
            for (var i = 0; i < count; i++) {
                val.push(i)
            }
            this.setState({value: val})
        }
    }

    ratingsClick = (e, attr) => {

        if (this.props.collaborator === false) {
            if (attr.lockonadd === 'Yes' && (!this.props.editId || this.props.editId === '')) {
                this.setState({starred: 0, clearRating: true})
            }
            if (attr.lockonedit === 'Yes' && this.props.editId && this.props.editId !== '') {
                this.setState({starred: attr.value})
            }
        } else {
            var value = e.target.value;
            this.setState({starred: value})
            this.props.handleInputChange(e)
        }


    }
    clearStar = () => {
        if (this.props.collaborator !== false) {
            this.setState({starred: 0, clearRating: true})
        }
    }

    render() {
        let attr = this.props.attributes;
        let values = this.state.value;
        let starred = this.state.starred !== 0 ? this.state.starred : (this.state.clearRating === true ? 0 : (attr.value ? attr.value : 0));
        let versionedFieldId = '';
        if (attr.versioning && attr.versioning === 'Yes' && this.props.editId && this.props.editId !== '' && this.props.formMeta && this.props.formMeta.version && this.props.formMeta.version > 1 && this.props.formVersions && this.props.formVersions.length > 0) {

            if (this.props.formVersions.includes(attr.masterid)) {
                versionedFieldId = attr.masterid;
            } else if (this.props.formVersions.includes(attr.referenceId + '_' + attr.refIndexNo)) {
                versionedFieldId = attr.referenceId + '_' + attr.refIndexNo;
            }
        }
        return (
            <>

                <div className="label-title-block">
                    <h5 className="field-header">{attr.fieldLabel}
                        {attr.hint && attr.hint !== '' &&
                        <div className="info-clicker-wrap">
                            <OverlayTrigger key='bottom' placement='top'
                                            overlay={
                                                <Tooltip id="bsy-tooltip">
                                                    {attr.hint}
                                                </Tooltip>
                                            }
                            >
                                <button className="info-clicker"></button>
                            </OverlayTrigger>
                        </div>
                        }
                    </h5>
                    {versionedFieldId !== '' &&
                    <button className={'ico-lib-v1 prev-version'}
                            onClick={(e) => this.props.showPreviousVersion(e, attr, '', 0, this.props.pageIndex, versionedFieldId)}></button>
                    }
                </div>
                <div className="feedback-block">
                    <button className="btnsubIcon ico-lib" title="Reset" onClick={this.clearStar}></button>
                    <ul className={`rate-star ${attr.ratingtype === 'bulb' ? "bulb" : ""}`} name={attr.name}
                        id={attr.id}>
                        {values.map((opt, index) =>
                            <li className={`${index < starred ? "active" : ""}`}
                                onClick={(e) => this.ratingsClick(e, attr)} title={attr.name} value={index + 1}
                                key={index}></li>
                        )}
                    </ul>
                </div>
            </>
        )

    }
}

class Payment extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let attr = this.props.attributes;
        return (
            <>
                {/*<h5 className="field-header">Payment</h5>*/}
                <div className="field-button-block">
                    <PaymentGateway
                        attr={attr}
                        paymentDetails={this.props.paymentDetails}
                        payChange={this.props.payChange}
                        payAmount={this.props.payAmount}
                    />
                </div>

            </>
        )

    }
}

class Resource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openResource: false,
            selectedResources: []
        }
    }

    render() {
        let attr = this.props.attributes;
        return (
            <>
                <h5 className="field-header"></h5>

                <LinkResource
                    attributes={attr}
                    selectedResourceItems={this.props.selectedResourceItems}
                    handleResourceChange={this.props.handleResourceChange}
                    editId={this.props.editId}
                />
            </>
        )

    }
}

class PhoneNumber extends Component {

    constructor(props) {
        super(props);
        this.state = {
            setcondition: 'No',
            countryName: '',
            countryCode: '',
            countryNumber: '',
            selectedCountries: [
                {
                    'code': '',
                    'number': ''
                }
            ],
        }
    }


    componentDidMount() {
        this.getGeoInfo();

    }

    getGeoInfo = () => {
        axios.get('https://ipapi.co/json/').then((response) => {
            let data = response.data;
            this.setState({
                countryName: data.country_name,
                countryCode: data.country_calling_code
            });
        }).catch((error) => {
            // console.log(error);
        });
    };

    handleChange = (e, type, item) => {
        let selectedCountries = this.state.selectedCountries;
        selectedCountries[0].code = this.props.editId && this.props.editId !== '' ? (item.length !== 0 && item[0].code && item[0].code !== '' ? item[0].code : this.state.countryCode) : this.state.countryCode;
        selectedCountries[0].number = this.props.editId && this.props.editId !== '' ? item.length !== 0 && item[0].number && item[0].number !== '' ? item[0].number : this.state.countryNumber : this.state.countryNumber;
        if (type === 'code') {
            selectedCountries[0].code = e.target.value;
            this.setState({countryCode: e.target.value});
        } else {
            this.setState({countryNumber: e.target.value});
            selectedCountries[0].number = e.target.value;
        }
        this.setState({selectedCountries: selectedCountries});
        this.props.handlePhoneNumberChange(selectedCountries)

    };

    onFieldKeyDown = (e) => {
        if (["e", "E", "+", "-"].includes(e.key)) {
            e.preventDefault()
        }
    }

    render() {
        let attr = this.props.attributes;
        let options = attr.option && attr.option !== 0 ? attr.option : [];
        let selectedCountries = this.state.selectedCountries;
        if (attr.value !== undefined && attr.value !== '' && attr.value.length !== 0) {
            let t = attr.value.split(" ");
            selectedCountries[0].code = t[0];
            selectedCountries[0].number = t[1];
            if (selectedCountries[0].number === undefined) {
                selectedCountries[0].code = this.state.countryCode;
                selectedCountries[0].number = t[0];
            }
        } else {
            selectedCountries = this.state.selectedCountries;
        }
        let countryCode = selectedCountries.length !== 0 && selectedCountries[0].code && selectedCountries[0].code !== '' ? selectedCountries[0].code : this.state.countryCode;
        let countryNumber = selectedCountries.length !== 0 && selectedCountries[0].number && selectedCountries[0].number !== '' ? selectedCountries[0].number : this.state.countryNumber;

        return (
            <>
                <div className="phone-number-field">
                    <div className="select-box">
                        <select name={attr.name} id={attr.name} required={this.props.required}
                                onChange={(e) => this.handleChange(e, 'code', selectedCountries)}
                                className={` ${this.props.selectClass} ${this.props.addErrorClass && " red-alert"}`}
                                value={countryCode} autofocus>
                            <option value={countryCode}>{countryCode}</option>
                            {options.length !== 0 && options.map((opt, opInd) =>
                                <option key={`op-in-${opInd}`} value={opt.text}>{opt.text}</option>
                            )}
                        </select>
                    </div>
                    <input type="number" className={`fiels-inp-box `}
                           placeholder='Phone Number'
                           onChange={(e) => this.handleChange(e, 'number', selectedCountries)} value={countryNumber}
                           onKeyDown={(e) => this.onFieldKeyDown(e)}
                    />
                </div>
            </>
        )
    }
}

class Preview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldValue: '',
            previewStatus: false,
            previewAttributes: {}
        }
    }

    componentDidMount() {
        this.setState({fieldValue: this.props.value})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.value !== this.props.value) {
            this.setState({fieldValue: this.props.value})
        }
    }

    handleAgreeChange = (e) => {
        var fieldName = this.props.attributes.name;
        var values;
        let fieldValue = this.state.fieldValue === '' ? 'yes' : '';
        this.setState({fieldValue: fieldValue})
        let pageIndex = this.props.pageIndex;
        this.props.setFieldValues(fieldName, fieldValue, pageIndex)
    }
    showDisplaytext = (text) => {
        let displaytext = text !== '' ? atob(text) : '';
        return {__html: displaytext};
    }
    preview = (previewAttributes) => {
        this.setState({
            previewAttributes: previewAttributes,
            previewStatus: !this.state.previewStatus
        })
    }
    closePreview = () => {
        this.setState({previewStatus: false, previewAttributes: {}})
    }

    render() {
        let attr = this.props.attributes;
        let checkedValues = this.props.checkedValue;
        return (
            <>

                <div class="label-title-block">
                    <label class="anim-text">{attr.fieldLabel ? attr.fieldLabel : attr.label}</label>
                </div>
                <div className="preview-component">
                    {attr.previewurldisplay === 'popup' ?
                        <div className="preview-popup">
                            <div className="field-button-block">
                                <button className="general-btn"
                                        onClick={(e) => this.preview(attr)}>{attr.fieldLabel ? attr.fieldLabel : attr.label}</button>
                            </div>
                        </div>
                        :
                        <div className="preview-embed">
                            <div className="preview-panel" style={{
                                width: attr.previewidth !== '' ? attr.previewidth + 'px' : '100%',
                                height: attr.previewheight !== '' ? attr.previewheight + 'px' : '350px'
                            }}>
                                {attr.previewfileurl !== '' &&
                                <>
                                    {getFileExtension(attr.previewfileurl) === 'doc' || getFileExtension(attr.previewfileurl) === 'xls' ?
                                        <iframe
                                            src={'https://docs.google.com/viewer?url=' + attr.previewfileurl + '&embedded=true'}
                                            width="100%" height="100%"/>
                                        :
                                        (getFileExtension(attr.previewfileurl) === 'jpg' || getFileExtension(attr.previewfileurl) === 'png' || getFileExtension(attr.previewfileurl) === 'jpeg') ?
                                            <img src={attr.previewfileurl}/>
                                            :
                                            <FileViewers
                                                url={attr.previewfileurl}
                                                type={getFileExtension(attr.previewfileurl)}
                                                height={attr.previewheight}
                                            />
                                    }
                                </>
                                }
                            </div>
                            <div className="field-button-block">
                                {attr.previewbuttons.length !== 0 && attr.previewbuttons.map((list, index) =>
                                    <>
                                        {list.previewtext !== '' &&
                                        <button className="general-btn" style={{
                                            color: attr.previewtextcolor,
                                            backgroundColor: attr.previewbgcolor
                                        }}>{list.previewtext}</button>
                                        }
                                    </>
                                )}
                            </div>

                        </div>
                    }

                    {attr.previewagreerichtext !== '' &&
                    <div className="agreement">
                        <div className="custom-check">

                            {attr.previewenablecheckbox === 'Yes' &&
                            <div className="custom-checkbox">
                                <input type="checkbox" name={attr.name} id={attr.name + "_0"} value={this.props.value}
                                       required={this.props.required} onChange={this.handleAgreeChange}
                                       className={`input-check-box ${checkedValues && checkedValues.includes(this.state.fieldValue) ? 'active' : ''}`}
                                       checked={this.state.fieldValue === "yes"}/>
                                <label></label>
                            </div>
                            }

                            <div className="custom-checkbox-text">
                                <p dangerouslySetInnerHTML={this.showDisplaytext(attr.previewagreerichtext)}
                                   className=""></p>
                            </div>

                        </div>
                    </div>
                    }
                </div>

                {this.state.previewStatus &&
                <div className="modal fade bsy-modal x-large fl-pv show" id="toModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" aria-hidden="true" style={{display: 'block'}}>
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Preview</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closePreview}></button>
                            </div>
                            <div className="modal-body">
                                <div class="preview-component">
                                    <div class="preview-embed">
                                        <div className="preview-panel" style={{
                                            width: this.state.previewAttributes.previewidth !== '' ? this.state.previewAttributes.previewidth + 'px' : '100%',
                                            height: this.state.previewAttributes.previewheight !== '' ? this.state.previewAttributes.previewheight + 'px' : '350px'
                                        }}>
                                            {this.state.previewAttributes.previewfileurl !== '' &&
                                            <>
                                                {getFileExtension(this.state.previewAttributes.previewfileurl) === 'doc' || getFileExtension(this.state.previewAttributes.previewfileurl) === 'xls' ?
                                                    <iframe
                                                        src={'https://docs.google.com/viewer?url=' + this.state.previewAttributes.previewfileurl + '&embedded=true'}
                                                        width="100%" height="100%"/>
                                                    :
                                                    (getFileExtension(this.state.previewAttributes.previewfileurl) === 'jpg' || getFileExtension(this.state.previewAttributes.previewfileurl) === 'png' || getFileExtension(this.state.previewAttributes.previewfileurl) === 'jpeg') ?
                                                        <img src={this.state.previewAttributes.previewfileurl}/>
                                                        :
                                                        <FileViewers
                                                            url={this.state.previewAttributes.previewfileurl}
                                                            type={getFileExtension(this.state.previewAttributes.previewfileurl)}
                                                        />
                                                }
                                            </>
                                            }
                                        </div>

                                        {this.state.previewAttributes.previewbuttons.length !== 0 && this.state.previewAttributes.previewbuttons.map((list, index) =>
                                            <div className="field-button-block">
                                                {list.previewtext !== '' &&
                                                <button className="general-btn" style={{
                                                    color: this.state.previewAttributes.previewtextcolor,
                                                    backgroundColor: this.state.previewAttributes.previewbgcolor
                                                }}>{list.previewtext}</button>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }

            </>
        )
    }
}

class DisplayImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldValue: '',
            previewStatus: false,
            previewAttributes: {}
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        let attr = this.props.attributes;
        let checkedValues = this.props.checkedValue;
        return (
            <>

                <div class="label-title-block">
                    <label class="anim-text">{attr.fieldLabel ? attr.fieldLabel : attr.label}</label>
                </div>
                <div className="display-component">
                    {attr.displayimagewithtext === 'Yes' ?
                        <div className="display-inner">
                            <div className="display-img" style={{width: '50%', height: '350px'}}>
                                {attr.displayimageurl !== '' &&
                                <img src={attr.displayimageurl}/>
                                }
                            </div>
                            <div className="display-text-content">
                                {attr.displayimagetext !== '' &&
                                <h3 className="" style={{textAlign: attr.displayimagetextalign}}>
                                    {attr.displayimagetext}
                                </h3>
                                }
                            </div>
                        </div>
                        :
                        <div className="display-inner">
                            <div className="display-img" style={{width: '100%', height: '350px'}}>
                                {attr.displayimageurl !== '' &&
                                <img src={attr.displayimageurl}/>
                                }
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}

class DisplayVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldValue: '',
            previewStatus: false,
            previewAttributes: {}
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        let attr = this.props.attributes;
        return (
            <>

                <div class="label-title-block">
                    <label class="anim-text">{attr.fieldLabel ? attr.fieldLabel : attr.label}</label>
                </div>
                <div className="display-component">
                    {attr.displayvideowithtext === 'Yes' ?
                        <div className="display-inner">
                            <div className="display-video" style={{width: '50%', height: '350px'}}>
                                {attr.displayvideourl !== '' &&
                                <FileViewers
                                    url={attr.displayvideourl}
                                    type={getFileExtension(attr.displayvideourl)}
                                />
                                }
                            </div>
                            <div className="display-text-content">
                                {attr.displayvideotext !== '' &&
                                <h3 className="" style={{textAlign: attr.displayvideotextalign}}>
                                    {attr.displayvideotext}
                                </h3>
                                }
                            </div>
                        </div>
                        :
                        <div className="display-inner">
                            <div className="display-img" style={{width: '100%', height: '350px'}}>
                                {attr.displayvideourl !== '' &&
                                <FileViewers
                                    url={attr.displayvideourl}
                                    type={getFileExtension(attr.displayvideourl)}
                                />
                                }
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}

class DisplayLabel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldValue: '',
            previewStatus: false,
            previewAttributes: {}
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        let attr = this.props.attributes;
        let textcolor = attr.textcolor;
        return (
            <>
                {attr.refRecordId && attr.refRecordId !== '' ?
                    <></>
                    :
                    <div class="label-title-block">
                        <label class="anim-text">{attr.fieldLabel ? attr.fieldLabel : attr.label}</label>
                    </div>
                }
                <div className="display-component">
                    <div className="display-inner">
                        <div className="display-text-content">
                            <h5 style={{'color': attr.colorpicker === 'Yes' ? textcolor : ''}}
                                className="">{attr.value}</h5>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export {
    Input,
    TextArea,
    Dropdown,
    RadioButton,
    CheckBox,
    CheckboxWithImage,
    Attachment,
    HTMLEditor,
    DigitalSignature,
    DataLoad,
    Toggle,
    Autofill,
    PhotoCapture,
    VideoCapture,
    DisplayText,
    Agree,
    LoggedIn,
    OTP,
    Rating,
    Payment,
    Resource,
    PhoneNumber,
    Preview,
    DisplayImage,
    DisplayVideo,
    DisplayLabel
};
