import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../actions/ToDo";
import {withRouter} from 'react-router';
import rootActions from "../../actions";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import * as projectActions from "../../actions/Project";
import {getProfile} from "../../utilities/AuthService";
import AlertModal from "./AlertModal";
import {ShareDetails} from "./IBTHeaderComponent";
import moment from 'moment'
import {USRIMG_PATH} from '../../constants/index'
import GoogleMaP from "./GoogleMap";
import SimpleModal from "../views/SimpleModal"
import LoaderSvg from "./LoaderSVG";
import AutoComplete from "./AutoComplete";
class ProjectHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showOption : false,
            editModal : false,
            headline: '',
            latitude: '',
            longitude: '',
            loading:0
        };
        this.goBack = this.goBack.bind(this);
        this.showOptions = this.showOptions.bind(this);
        this.DelmodalOpen = this.DelmodalOpen.bind(this);
        this.deleteproject = this.deleteproject.bind(this);
        this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
        this.editLocation = this.editLocation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.editProject = this.editProject.bind(this);

    }
    componentDidMount() {
        var projectid = getUrlSegment(5)? atob(getUrlSegment(5)) :atob(getUrlSegment(4));
        this.setState({project_id:projectid});
        let params={
            _id : projectid
        };
        this.props.getprojectoverview(params);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var projectid = getUrlSegment(5)? atob(getUrlSegment(5)) :atob(getUrlSegment(4));
        if(this.props.getupdatedprojectdata.updatedInfo !== prevProps.getupdatedprojectdata.updatedInfo){
            var beta = this;
            setTimeout(function () {
                let params={
                    _id : projectid
                };
                beta.props.getprojectoverview(params);
                beta.setState({loading: 0});
                beta.editLocation();
            }, 1000)
        }
        if(this.props.getheaderData.Overviewdata !== prevProps.getheaderData.Overviewdata){
            let projectData =  this.props.getheaderData.Overviewdata;
            this.setState({projectData: projectData,
                                 latitude :  projectData ? projectData.latitude :'',
                                 longitude : projectData ? projectData.longitude :'',
            });
        }
    }

    goBack() {
        this.props.history.goBack();
    }
    showOptions(){
        this.setState({showOption : !this.state.showOption});
    }
    DelmodalOpen(e, pid){
        e.stopPropagation();
        this.setState({Delopen : true , showOption : false});
        this.setState({projectdelid : pid});
    }
    deleteproject(){
        let project_id = this.state.project_id;

        let deletedddata = {projectid : project_id};
        this.props.deleteProject(deletedddata);

        this.setState({Delopen : false});
        this.props.history.push('/project');
    }

    cancelDeleteModal(){
        this.setState({Delopen : false});

    }
    editLocation(){
        this.setState({editModal : !this.state.editModal});
    }
    handleChange(e){
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }
    editProject()
    {
        let formValid = 0;
        let lattitude = this.state.latitude;
        let longitude = this.state.longitude;
        if (lattitude === '') {
            formValid++;
            document.getElementsByName('latitude')[0].classList.add('val-error');
        }  if (longitude === '') {
            formValid++;
            document.getElementsByName('longitude')[0].classList.add('val-error');
        }
         if(formValid === 0){
             this.setState({loading:1})
            let params = {
                latitude : lattitude,
                longitude : longitude,
                proj_id: this.state.project_id
            };
            //console.log(params)
            this.props.editproject(params);
        }

    }

    render() {
        let ProjectInfo =  (this.state.pagename === 'dashboard') ? this.props.projectDetail.projectDetails.projectinfo : this.props.ProjectInfoDet.ProjectInfo.projectinfo;
        let projectData = this.state.projectData ||[];
        let description = '';
        let startDate = '';
        let endDate = '';
        if(projectData) {
            description = atob(([projectData.description]));
            startDate = moment(projectData.startDate).utc().local().format('DD MMM YYYY');
            endDate = moment(projectData.endDate).utc().local().format('DD MMM YYYY');
        }
        let projectSponsors = projectData.projectSponsors ? projectData.projectSponsors:[];
        console.log(this.state.latitude)
        console.log(this.state.longitude)
            return (
                <div className="ibt-header">
                    <div className="ibt-header-top">
                        <div className="ibt-header-top-left">
                            <div className="ibt-header-back" onClick={this.goBack}>

                                {/* <button className="ico-lib-v1 ibt-header-back-btn">{projectData.projectName}</button> */}
                                <button className="ico-lib-v1 ibt-header-back-btn button-flex"><h5 className="ico-lib-v2 project-source-header energy">{projectData.portfolioName} / {projectData.programName}</h5></button>

                            </div>
                            
                        </div>
                        <div className="ibt-header-top-right" onClick={(e)=> this.props.expandHeader(e)}>
                            <button className="ico-lib-v2 down-arrow-ico" ></button>
                        </div>
                    </div>

                    <div className="ibt-header-bottom">
                    <div className="ibt-header-bottom-left">
                        {(this.state.latitude !='' && this.state.longitude !='') && (this.state.latitude !=null && this.state.longitude !=null) && (this.state.latitude != undefined && this.state.longitude != undefined) &&
                            <GoogleMaP
                                lat={this.state.latitude}
                                lng={this.state.longitude}
                                text="My Marker"
                                />
                        }

                        <div className="bottom-edit-block">
                            <nav className="pj-edit-ico" onClick={(e)=> this.editLocation()}>Edit Location</nav>
                        </div>
                    </div>
                    <div className="ibt-header-bottom-right">

                        <div className="ibt-header-bottom-right-left">
                            <div className="ibt-header-bottom-right-bottom-left">

                                <div className="inline-flex">
                                    <h5 className="main-title-header">{projectData.projectName}</h5>
                                    {(projectData.status === "InProgress" || projectData.status === "New")&&
                                        <div className="inprog">{projectData.status}</div>
                                    }
                                    {projectData.status === "Done" &&
                                    <div className="live">{projectData.status}</div>
                                    }
                                </div>

                                <p>{description}</p>

                                {/* <h5 className="ico-lib-v2 project-source-header energy">{projectData.portfolioName} / {projectData.programName}</h5>
                                <p>{description}</p> */}
                            </div>

                            <div className="ibt-header-bottom-right-top">
                                <ul className="ibt-header-details">
                                    <li>
                                        <h5>Project ID</h5>
                                        <p>{projectData.projectCode}</p>
                                    </li>
                                    <li>
                                        <h5>Start Date</h5>
                                        <p>{startDate}</p>
                                    </li>
                                    <li>
                                        <h5>Country</h5>
                                        <p>{projectData.country}</p>
                                    </li>
                                    <li>
                                        <h5>Main Sponsor</h5>
                                        <p>{projectData.mainShareholder}</p>
                                    </li>
                                    <li>
                                        <h5>Team Leader</h5>
                                        <p>{projectData.teamLeader}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {projectSponsors.length >0 &&
                        <div className="ibt-header-bottom-right-right">
                            <h5 className="sponsor-header">Project Financiers</h5>
                            <div className="ibt-header-bottom-right-bottom-right-left">
                                {projectSponsors.map((item,index) =>
                                    <React.Fragment key={index}>
                                            <ul className="share-prop">
                                                <span className="share-prop-span extra">{item.priority}</span>
                                                <ShareDetails shareholders={item.shareholders}/>
                                            </ul>
                                    </React.Fragment>
                                    )}
                            </div>
                        </div>
                        }
                    </div>
                </div>
                    {this.state.editModal &&
                    <SimpleModal
                        Close={this.editLocation}
                        title={`Edit Location`}
                        haveFooter={1}
                        OKLabel="Save"
                        OKAction={this.editProject}
                        cancelLabel="Cancel"
                        cancelAction={this.editLocation}>
                        {(this.props.getupdatedprojectdata.loading === true || this.state.loading === 1 ) &&
                        <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                        }
                        <div className="task-tg">
                            <div className="task-inp-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Latitude" id="latitude" name="latitude" value={this.state.latitude} autoComplete="off"
                                           onChange={(e)=>this.handleChange(e)} />
                                </div>
                                <div className="new-task-input">
                                    <input type="text" placeholder="Longitude" id="longitude" name="longitude" value={this.state.longitude} autoComplete="off"
                                           onChange={(e)=>this.handleChange(e)} />
                                </div>
                            </div>

                        </div>

                    </SimpleModal>
                    }

                </div>
            );
        }


}
const mapStateToProps = state => ({
    headline: state.CommonReducer.loadHeadline,
    ProjectInfoDet : state.ProjectReducer.ProjectInfo,
    projectDetail : state.ProjectReducer.listallProjectsdetails,
    getheaderData:state.ProjectReducer.GetOverviewdata,
    getupdatedprojectdata:state.ProjectReducer.updatedproject
});

export default withRouter(connect(mapStateToProps, {
    mainClick: rootActions.commonActions.MenuClick,
    projectInfo:projectActions.projectInfo,
    overviewHelper : projectActions.overviewHelper,
    taskHelper : projectActions.taskHelper,
    projectdetails:projectActions.listallprojectsdetails,
    deleteProject:projectActions.DeleteProject,
    getprojectoverview:projectActions.overviewData,
    editproject:projectActions.updateProject
})(ProjectHeader));


