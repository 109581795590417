import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import {getProfile} from '../../../utilities/AuthService';
import * as taskActions from "../../../actions/ToDo";
import {FRMSIMG_PATH, FRMSVID_PATH, FRMSDOC_PATH, ES_INDEX, SHARED_LOGIN} from "../../../constants";
import {
    getUrlSegment,
    getFileIcon,
    isImage,
    isVideo,
    isAudio,
    getFileExtension,
    forceDownload, getAttachmentPath, formatBytes
} from "../../../utilities/CustomFunctions";
import { TaskDetailBox,WorkTimeSection,TaskStatusButton,TaskDetailContent,TaskDetailUserInfo,ActivityPanel,HeaderBox,AttachmentPanelTab,NavTab,TaskOptionButton, TimeLogForm, TimeLogList,ViewParentPanel,UsersPanelTab,IndividualAssigners,AttachmentPanel,CollectionPanel}  from "./TaskDetailData";
import ChatContainer from "../chat/ChatContainer"
import Modal from "../../views/Modal";
import ShowFormModal from "../../views/ShowFormModal";
import ShowFormPreview from "../../views/ShowFormPreview";
import AddToDoTask from "../AddToDoTask";
import * as usersActions from "../../../actions/Users";
import * as collectionActions from "../../../actions/Collections";
import AlertModal from "../../views/AlertModal";
import LoaderSvg from "../../views/LoaderSVG";
import UploadFile from "../../../utilities/Upload/UploadFile";
import {Tooltip,OverlayTrigger,Button,Spinner} from "react-bootstrap"
import SimpleModal from "../../views/SimpleModal";
import {DateRange} from "react-date-range";
import DateTimePickerBox from "../../views/DateTimePickerBox";
let assigneestatus ='';
let time = "";
let shared = "";
let donedate = "";
let archived ="";
let taskmode=""
let taskData = "";
let assigneesData = ""
let logData = ""
let commentData = ""
let allfilesData = ""
let attachmentData = ""
let collectionData = ""
let timelogData = ""
let subtaskData = []
let remarksData = ""
let previewableAttachments = ""
let taskid =  ""
let deleteid = ''
let parent =""
let assignees = ""

class TaskDetails extends Component {

    constructor(props) {
        super(props);
        this.state={
            showOption : false,
            showremarksModal : false,
            showtimelogModal : false,
            showmarkasdoneModal : false,
            showverifyModal : false,
            showpreviewmodal:false,
            showReopenConfirm : false,
            showReopenModal : false,
            showPreviewFormModal : false,
            startDate: new Date(),
            log:[],
            date:'',
            hours:'',
            mins:'',
            taskid:'',
            rightTab:'home-tab',
            taskstatus:"",
            action:'',
            timetaken:'',
            timeLogValues: [],
            childid:'',
            creator:'',
            assignee_status:'',
            attach:'',
            donedate:'',
            verified : 'no',
            disable_verify : 'disable',
            disable_markasdone : 'disable',
            doneStatus : '',
            previewIndex : 0,
            previewableAttachments : [],
            showFormModal :false,
            formPages:[],
            taskmode : '',
            fieldsToDisplay : [],
            timelogloading : 0,
            markasdoneloading : 0,
            verifyloading : 0,
            filename: null,
            uploadedFiles : [],
            remark:'',
            reOpenSubmit : false,
            archive_action:'',
            archived:'',
            previewType:'',
            attach_index:'',
            consolidated_time:'',
            assignee:'',
            fileData:[],
            reference:'',
            mark_as_done_comment_array : [],
            markasDoneTemp:false,
            markasdonedata:'',
            verifytaskdata:'',
            reOpenparam:'',
            dateChoosen: false,

        }
        this.markasDone = this.markasDone.bind(this);
        this.showOptions = this.showOptions.bind(this);
        this.showremarksModal = this.showremarksModal.bind(this);
        this.closeremarksModal = this.closeremarksModal.bind(this);
        this.showtimelogModal = this.showtimelogModal.bind(this);
        this.closetimelogModal = this.closetimelogModal.bind(this);
        this.handleinputChange = this.handleinputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addTimelog = this.addTimelog.bind(this);
        this.getrightTabid = this.getrightTabid.bind(this);
        this.addMoretime = this.addMoretime.bind(this);
        this.showmarkasdoneModal = this.showmarkasdoneModal.bind(this);
        this.closemarkasdoneModal = this.closemarkasdoneModal.bind(this);
        this.showverifyModal = this.showverifyModal.bind(this);
        this.verify = this.verify.bind(this);
        this.showpreviewmodal = this.showpreviewmodal.bind(this);
        this.closepreviewmodal = this.closepreviewmodal.bind(this);
        this.setPreviewIndex = this.setPreviewIndex.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.showFormModal = this.showFormModal.bind(this);
        this.hideFormModal = this.hideFormModal.bind(this);
        this.moveToArchive = this.moveToArchive.bind(this);
        this.removeSelectedAttachment = this.removeSelectedAttachment.bind(this);
        this.viewParentTask = this.viewParentTask.bind(this);
        this.showReopenModal = this.showReopenModal.bind(this);
        this.reopenAllTask = this.reopenAllTask.bind(this);
        this.OnlineUsers=this.OnlineUsers.bind(this);
        this.hidePreviewForm = this.hidePreviewForm.bind(this);
        this.previewForm = this.previewForm.bind(this);
        this.showReopenConfirm=this.showReopenConfirm.bind(this);
        this.downloadAll = this.downloadAll.bind(this);
        this.preview=this.preview.bind(this)
        this.closeReopenModal=this.closeReopenModal.bind(this)
        this.deleteTaskConfirm=this.deleteTaskConfirm.bind(this)
        this.dateSwap=this.dateSwap.bind(this)
        this.updatetask=this.updatetask.bind(this)
        this.updatetaskdata=this.updatetaskdata.bind(this)
        this.goBack=this.goBack.bind(this)

    }

    componentDidMount() {
        this.setTaskStatuses();
        let params ={
            "uid": getProfile().id,
            "usertype": getProfile().usertype
        }

        this.props.getTaskHelper(params);
        this.props.gettimelog({taskid : atob(getUrlSegment(3)), uid : getProfile().id});
        let userdata = {
            uid: getProfile().id
        }
        let that=this
        setTimeout(function () {
            that.props.notificationdata(userdata);
        }, 1000)
        let taskData = this.props.taskdetailsData ? this.props.taskdetailsData.taskdetails : '';
        this.setState({
            duedate: new Date(taskData.parent.duedate)
        })

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        // if(prevProps.markasdonestatus !== this.props.markasdonestatus) {
        //
        //     this.setTaskStatuses();
        //     this.setState({ showmarkasdoneModal : !this.state.showmarkasdoneModal});
        // }
        if(prevProps.postTaskCompletionasync !== this.props.postTaskCompletionasync) {
              var taskid = (atob(getUrlSegment(3)));
              var mode = (atob(getUrlSegment(4)));
              let params = { _id : taskid, systype: "root", uid : getProfile().id, mode: mode };
              this.props.gettaskdetails(params)
          }
        if(this.props.taskdetailsData.taskdetails.parent.masterid !== prevProps.taskdetailsData.taskdetails.parent.masterid){
            this.setTaskStatuses()
            this.props.modifyTaskTodefault()
        }
        if(this.props.projects.projectData !== prevProps.projects.projectData) {
            this.setState({ projectList : this.props.projects.projectData});
        }
        if(this.props.projects.tagData !== prevProps.projects.tagData) {
            this.setState({ tagList : this.props.projects.tagData});
        }
        if(this.props.taskSelect.tasksArchived !== prevProps.taskSelect.tasksArchived) {
            var type = atob(getUrlSegment(4));
            var data = {category : type, status : "All" , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
            this.props.todo(data);
            setTimeout(() => {
            if(type === 'to-me') {
                window.location.href = "/task/todo";
                //this.props.history.push('/task/todo')
            }
            if(type === 'by-me') {
                window.location.href = "/task/to-follow-up";
                //this.props.history.push('/task/to-follow-up')
            }
            if(type === 'to-know') {
                window.location.href = "/task/to-know";
               // this.props.history.push('/task/to-know')
            }
            if(type === 'manage-task') {
                window.location.href = "/task/to-oversee";
                //this.props.history.push('/task/to-oversee')
            }
            }, 1000);
        }
        if(this.props.timelogDetails !== prevProps.timelogDetails) {
            let tasklogHits = this.props.timelogDetails.timelog.data;
            let ctime=0;
            if(tasklogHits) {
                for( var i=0; i< tasklogHits.length ; i++) {
                    ctime += parseInt(tasklogHits[i]['consolidated_time']);
                }
            }
            ////////////console.log(ctime)
            if(ctime > 0) {
                let consolidated_time = (ctime)
                ////////////console.log(consolidated_time)
                setTimeout(function () {
                    beta.setState({consolidated_time : consolidated_time, timelogloading : 0 });
                }, 1000)

                this.closetimelogModal();
                setTimeout(function () {
                    ////////////console.log(beta.state.consolidated_time)
                }, 1000)

            }
            this.setState({timelogloading : 0,showtimelogModal:false});
        }
        if(this.props.taskSelect.taskDeleted !== prevProps.taskSelect.taskDeleted) {
            var type = 'to-me';
            setTimeout(() => {
            var data = {category : type, status : "All" , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
            this.props.todo(data);
            },500)
            setTimeout(() => {
                this.setState({deleteTask:false})
                this.props.history.push('/task/todo')
            }, 1000);
        }
        if(this.props.taskSelect.dateUpdated !== prevProps.taskSelect.dateUpdated) {
            this.setState({
                dateChoosen:false
            })
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            let params = { _id : taskid, systype: "root", uid : getProfile().id, mode: mode };
            this.props.gettaskdetails(params)
        }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            this.setState({open:false})
        }
        if(this.props.modifyTask.task_modified !== prevProps.modifyTask.task_modified){
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            let params = { _id : taskid, systype: getProfile().usertype, uid : getProfile().id, mode: mode };
            this.setState({markasdoneloading : 0, verifyloading : 0, reopenloading : 0});
            this.closemarkasdoneModal();
            this.closeverifyModal();
            this.closeReopenModal();
            this.setTaskStatuses();
            if(this.props.modifyTask.task_type ===  'markasdone') {
                //////////console.log(this.state.markasdonedata)
                this.setState({markasDoneTemp:true})
                this.props.TaskCompletionasync(this.state.markasdonedata);
                this.props.gettimelog({taskid : atob(getUrlSegment(3)), uid : getProfile().id});
                let that=this
                setTimeout(() => {
                    var data = {category : "to-me", status : "All" , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
                    this.props.todo(data);
                },1000)


            }

            if(this.props.modifyTask.task_type ===  'verifytask') {
                this.props.TaskCompletionasync(this.state.verifytaskdata);
                this.props.gettimelog({taskid : atob(getUrlSegment(3)), uid : getProfile().id});
                let that=this
                setTimeout(() => {
                    var data = {
                        category: "by-me",
                        status: 'Done',
                        uid: getProfile().id,
                        bydue: 'by-me-done',
                        priority: that.props.taskFilters.taskPriorityFilter,
                        project: that.props.taskFilters.taskProjectFilter,
                        asigntype: 'All',
                        username: that.props.taskFilters.taskAssignedFilter,
                        managetask: 'mytask',
                        limit: 0,
                        offset:30,
                        systype: getProfile().usertype,
                        actionType: 'LIST_TO_VERIFY_DATA'
                    }
                    that.props.toVerify(data);
                },1000)
            }
            if(this.props.modifyTask.task_type ===  'reopentask') {
                   this.props.gettimelog({taskid : atob(getUrlSegment(3)), uid : getProfile().id});
            }





        }
        if(this.props.reassigntask !== prevProps.reassigntask){
            this.setState({open:false,showOption:false})
            this.props.history.push("/task/details/"+btoa(this.props.reassigntask.results[0].masterid)+"/"+getUrlSegment(4))
        }

    }

    preview(url, name) {
        this.setState({url: url, name: name, previewStatus: !this.state.previewStatus})
    }

    setTaskStatuses() {
        let parent = this.props.taskdetailsData.taskdetails.parent;
           if (parent) {
            if(parent.reassign === 'yes') {
                let uid=''
               if(parent.createuser !== getProfile().id){
                   uid=getProfile().id
               }
                this.loadmoreData({ taskid : parent.masterid,uid:uid});
            }
            let tasktype = parent.tasktype;
            this.setState({
                tasktype: tasktype,
                taskmode : (parent.createuser === getProfile().id) ? 'by-me' : '',
                disable_verify : ( parent.status === 'Done' ) ? '' : 'disable',
                taskid : parent.masterid,
                verified : parent.verified?parent.verified:'no',
                archived : (parent.createuser === getProfile().id && parent.archive=== 'yes') ? 'yes' : 'no'
            })
        }

        let assignees = this.props.taskdetailsData.taskdetails.assignees;
        assignees = assignees.filter(
            user => user.assignee.search(getProfile().id) >= 0
        );
        //////////console.log(getProfile().id)
        //////////console.log(assignees)





        if(assignees.length > 0) {

            let assigneestatus = assignees[0].status;
            let time = assignees[0].consolidated_time;
            let shared =  assignees[0].share;
            let donedate = assignees[0].donedate;
            let archived = assignees[0].archive;
            this.setState({
                assignee_id: assignees[0].assignee,
                childid : assignees[0].childid,
                assignee_status: assigneestatus,
                shared : shared,
                consolidated_time: time,
                donedate : donedate,
                archived:archived
            })

            ////////////console.log(this.state.consolidated_time)
        }

    }
    showOptions(){
        this.setState({showOption : !this.state.showOption});
    }
    showremarksModal(mark_as_done_comment,reference){
        this.setState({
            showremarksModal : !this.state.showremarksModal,
            mark_as_done_comment_array : mark_as_done_comment
        });

        // this.props.getremarks(getProfile().id,taskid,reference);

    }
    closeremarksModal(){
        this.setState({
            showremarksModal : false,
            mark_as_done_comment_array : []
        });
    }
    showtimelogModal(id){
        let assignees = this.props.taskdetailsData.taskdetails.assignees;
        assignees = assignees.filter(
            user => user.assignee.search(getProfile().id)>= 0
        )
        let assigneestatus = assignees[0].status;

        this.setState({showtimelogModal : !this.state.showtimelogModal,
            assignee_status:assigneestatus
        });
    }
    closetimelogModal(){
         this.setState({showtimelogModal : false})
    }
    showmarkasdoneModal(id,createuser,reference){
        this.setState({showmarkasdoneModal : !this.state.showmarkasdoneModal, action:'',reference:reference, creator:createuser, uploadedFiles : [] });
       // this.props.gettimelog(id,getProfile().id);
    }
    closemarkasdoneModal(){
        this.setState({showmarkasdoneModal : false,
        });
    }
    showverifyModal(id,reference){
        this.setState({showverifyModal : true , uploadedFiles : [],reference:reference})
    }
    closeverifyModal(){
        this.setState({showverifyModal : false})
    }
    showFormModal(collectionid, formName) {
        this.props.showform({ _id : collectionid});
        this.setState({
            formName : formName,
            collectionid : collectionid,
            showFormModal: true
        })
    }
    hideFormModal(id) {
        this.setState({
            showFormModal: false
        })
    }
    previewForm(id, collectionid) {
        this.props.getFormData({ _id : id, collectionid : collectionid});
        this.setState({
            collectionid : collectionid,
            showPreviewFormModal : true
        })
    }
    hidePreviewForm() {
        this.setState({
            showPreviewFormModal : false
        })
    }
    showReopenConfirm(id , mainTask,assignee) {
        this.setState({ taskIdToReopen : id, assignee:assignee, showReopenConfirm : !this.state.showReopenConfirm, mainTask : mainTask});
    }
    showReopenModal() {
        this.setState({showReopenConfirm : false, showReopenModal : true,  uploadedFiles : []});
    }
    closeReopenModal() {
        this.setState({ showReopenModal : false});
    }
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    handleinputChange(e){
        this.setState({[e.target.name]:e.target.value})

        if(e.target.value !== '') {
            e.target.classList.remove('val-error');

        }
    }
    saveDiscussion(taskid, childid, reference) {
        let has_attachment = (this.state.uploadedFiles.length > 0) ? "yes" :"no";
        let savediscussiondata = { todoid : taskid, discussion : this.state.remark, reference : reference, has_attachment : has_attachment, uid : getProfile().id, childid : childid, filerelator : "" };
        this.props.savediscussion(savediscussiondata);
    }
    getrightTabid(tab){
        this.setState({rightTab : tab})
    }
    addMoretime(){
        this.setState({action:'addtime'})
    }
    loadmoreData =(params)=>{
        this.props.getmoretaskdetails(params)
    }
    showpreviewmodal(index,previewType){
        this.setState({showpreviewmodal : !this.state.showpreviewmodal,
        previewType:previewType,
        attach_index:index
        })
    }
    closepreviewmodal(){
        this.setState({showpreviewmodal : false,
        })
    }
    getTimeLogEntries(timeLogValues) {
        this.setState({ timeLogValues : timeLogValues });

    }
    addTimelog(saveLog){
        if( saveLog ) {
            this.setState({timelogloading : 1});
        }
        let timeLogValues = [...this.state.timeLogValues];
        ////////////console.log(timeLogValues)

        let invalid = 0;
        if(timeLogValues.length > 0) {
            for(var i=0; i< timeLogValues.length ; i++) {

                // if(timeLogValues[i]['rawdate'] === '') {
                //     document.getElementById('date'+i).classList.add('val-error');
                //     invalid++;
                // }
                if(timeLogValues[i]['rawdate'] === ''){
                    document.getElementById('date'+i).classList.add('val-error');
                    invalid++;
                }
                else{
                    var el = document.getElementById("date"+i);
                    if (el.classList.contains("val-error")) {
                        el.classList.remove("val-error");
                    }
                }

                if((timeLogValues[i]['hour'] === '') &&timeLogValues[i]['min'] === '') {
                    document.getElementById('hour'+i).classList.add('val-error');
                    document.getElementById('min'+i).classList.add('val-error');
                    invalid++;
                }
                else {
                    var el = document.getElementById('hour' + i);
                    var e2 = document.getElementById('min' + i);
                    if (el.classList.contains("val-error"))
                        el.classList.remove("val-error");
                    if (e2.classList.contains("val-error"))
                        e2.classList.remove("val-error");
                }

                if(timeLogValues[i]['hour'] !== '' && parseInt(timeLogValues[i]['hour']) > 23 ){
                    timeLogValues[i]['hour'] = 23;
                }
                if(timeLogValues[i]['min'] !== '' && parseInt(timeLogValues[i]['min']) > 59 ){
                    timeLogValues[i]['min'] = 59;
                }
            }

            if(saveLog) {
                if(invalid === 0 ) {
                    this.props.addtimelog({ uid : getProfile().id, taskid : this.state.taskid, logtime : JSON.stringify(timeLogValues)});
                    this.setState({timeLogValues : []})
                     //this.setState({timeLogValues:JSON.stringify(timeLogValues)})
                }
                else {
                    this.setState({timelogloading : 0});
                }
            }
            else{
                return invalid;
            }

        }
        else{
            this.setState({timelogloading : 0});
            document.getElementById('hour0').classList.add('val-error');
            document.getElementById('min0').classList.add('val-error');
            document.getElementById('date0').classList.add('val-error');

        }
    }
    markasDone(e){
        e.preventDefault();
        var reference="markasdone";
        var mode="";
        var parentid=""
        this.setState({markasdoneloading : 1});
        let formInvalid = 0;
        if( this.props.timelogDetails.timelog.data.length === 0 ) {
            formInvalid = this.addTimelog(false);
        }
        if( this.state.remark === '') {
            formInvalid++;
            document.getElementsByName('remark')[0].classList.add('val-error');
        }
        if(formInvalid === 0) {

            let assignees = this.props.taskdetailsData.taskdetails.assignees;
            assignees = assignees.filter(
                user => user.assignee.search(getProfile().id)>= 0
            )
          //  let childid = assignees[0].childid;

            let tasklogHits = this.props.timelogDetails.timelog.data;
            let ctime=0;
            if(tasklogHits) {
                for( var i=0; i< tasklogHits.length ; i++) {

                    ctime += parseInt(tasklogHits[i]['consolidated_time']);
                }
            }

            let newTimeLogs = this.state.timeLogValues;
            if(newTimeLogs.length > 0) {
                for( var j=0; j< newTimeLogs.length; j++) {
                    if(newTimeLogs[j].hour || newTimeLogs[j].min) {
                        var h = parseInt(newTimeLogs[j].hour) *60;
                        var m = parseInt(newTimeLogs[j].min);
                        if(isNaN(h)){
                            h=0;
                        }
                        if(isNaN(m)){
                            m=0;
                        }
                        ctime += h+m;
                    }
                }
            }

               let uploadArray=[]
                uploadArray = this.getAttachments();

            if(getProfile().id === this.props.taskdetailsData.taskdetails.parent.createuser &&
                this.props.taskdetailsData.taskdetails.assignees.length === 1 &&
                this.state.tasktype === 'task' &&
                this.props.taskdetailsData.taskdetails.parent.createuser === getProfile().id &&
                this.props.taskdetailsData.taskdetails.parent.shared !== "yes" &&
                this.props.taskdetailsData.taskdetails.parent.has_parent !=="yes"
                ){

                mode="self";
                reference="verifyandmarkasdone"
            }

           if(this.props.taskdetailsData.taskdetails.parent.has_parent ==="yes" && this.props.taskdetailsData.taskdetails.parent.createuser === getProfile().id){
               parentid=this.props.taskdetailsData.taskdetails.parent.parent_id
               reference="verifyandmarkasdone"
           }



            let markasdonedata = {
                "taskid": this.state.taskid,
                "uid": getProfile().id,
                "index": ES_INDEX,
                "reference": reference,
                "timelog": this.state.timeLogValues,
                "consolidated_time":ctime,
                "remarks": [{
                    "content": this.state.remark,
                    "attachments": uploadArray
                }],
                "parentid": parentid,
                "mode":mode
            }
           this.setState({markasdonedata:markasdonedata})

            this.props.markasdone(markasdonedata);
            this.setState({timeLogValues : [{ rawdate: '', hour : '' , min : '' }]})

           // this.saveDiscussion(this.state.taskid, childid, "markasdone");

            // if(this.state.timeLogValues[0]['rawdate'] !== '' && this.state.timeLogValues[0]['hour'] !== '') {
            //     this.addTimelog(true);
            // }

        }
        else {
            this.setState({ markasdoneloading : 0});
        }

    }
    verify(){
        this.setState({markasdoneloading : 1});
        let formInvalid = 0;
        if( this.state.remark === '') {
            formInvalid++;
            document.getElementsByName('remark')[0].classList.add('val-error');
        }

        if(formInvalid === 0) {

            let uploadArray=[]
            uploadArray = this.getAttachments();
            let verifytaskdata = {
                "taskid": this.state.taskid,
                "uid": getProfile().id,
                "index": ES_INDEX,
                "reference": this.state.reference,
                "consolidated_time":0,
                "timelog": [],
                "remarks": [{
                    "content": this.state.remark,
                    "attachments": uploadArray
                }],
                "parentid": ""
            }
            this.setState({verifytaskdata:verifytaskdata})

            this.props.verifytask(verifytaskdata);
           // this.props.verifytask(verifydata);

         //   this.saveDiscussion(this.state.taskid, this.state.childid, "verify");

           // this.saveAttachments(this.state.taskid);
        }
    }
    reopenAllTask() {
        let uploadArray=[]
            uploadArray = this.getAttachments();
        var reOpenparam={
            "todoid": this.state.taskIdToReopen,
            "uid": getProfile().id,
            "index": ES_INDEX,
            "remarks": [{
                "content": this.state.remark,
                "attachments": uploadArray
            }],
            "assignee": ""
        }
        this.setState({reOpenSubmit : true});
        var reopenData = { todoid : this.state.taskIdToReopen , uid : getProfile().id };
        var formInvalid = 0;

        if(this.state.remark === '') {
            formInvalid++;
        }
        if(formInvalid === 0) {
            this.props.reopenAllTask(reOpenparam);
            //this.saveDiscussion(this.state.taskIdToReopen, this.state.taskIdToReopen, "reopen ");

           // this.saveAttachments(this.state.taskIdToReopen);
        }
    }
    reopenTask() {
        let uploadArray=[]
        uploadArray = this.getAttachments();
        this.setState({reOpenSubmit : true});
        var reopenData = { cid : this.state.taskIdToReopen , pid : this.state.taskid, status: 'New', view : 'no' , readstatus : 'unread' };



        var formInvalid = 0;

        if(this.state.remark === '') {
            formInvalid++;
        }
        if(formInvalid === 0) {
            var reOpenparam={
                "todoid": this.state.taskid,
                "taskid": this.state.taskid,
                "uid": getProfile().id,
                "index": ES_INDEX,
                "assignee": this.state.assignee,
                "consolidated_time":0,
                "remarks": [{
                    "content": this.state.remark,
                    "attachments": uploadArray
                }]
            }
            this.setState({reOpenparam:reOpenparam})
            this.props.reopenTask(reOpenparam);
          //  this.saveDiscussion(this.state.taskid, this.state.taskIdToReopen, "reopen ");

           // this.saveAttachments(this.state.taskIdToReopen);
        }
    }
    getAttachments() {
        var uploadedFiles = this.state.uploadedFiles;

        var uploadArray = [];

        if (uploadedFiles.length > 0) {
            for (var i = 0; i < uploadedFiles.length; i++) {
                var uploadedFile = uploadedFiles[i];
                var fileNameArr = uploadedFile.fileName.split('/');
                var fileName = fileNameArr.pop();

                var fileData = {
                    filetype: uploadedFile.fileType.startsWith('image') ? 'images' : (uploadedFile.fileType.startsWith('video') ? 'videos' : 'documents'),
                    filesize: uploadedFile.fileSize,
                    filetitle: uploadedFile.originalfileName,
                    filename: fileName
                }

                uploadArray.push(fileData);
            }

        }
        return uploadArray;
    }
    saveAttachments(taskid) {
        let uploadArray = this.getAttachments();

        if(uploadArray.length > 0) {
            var fileData = {
                todoid: taskid,
                uid: getProfile().id,
                file :uploadArray
            }

            this.setState({fileData:uploadArray})

            this.props.saveToDoAttachments(fileData);
        }
    }
    setPreviewIndex(type) {
        let previewIndex = this.state.previewIndex;

        if( type === 'prev') {
            --previewIndex;
        }
        else{
            ++previewIndex;
        }
        let previewableAttachments =  this.props.taskdetailsData.taskdetails.attachment.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
            // +
            // this.props.taskdetailsData.taskdetails.allfiles.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
        if(previewIndex >= 0 && previewIndex < previewableAttachments.length) {
            this.setState({ previewIndex : previewIndex});
        }

    }
    downloadAll(e) {
        e.preventDefault();
        let attachmentData = this.props.taskdetailsData.taskdetails.attachment;
        for( var i =0 ; i<attachmentData.length; i++) {
            let filename = attachmentData[i].filename;
            let fileType = getFileIcon(filename);
            let filePath = (fileType === 'image') ? FRMSIMG_PATH : ( (fileType === 'mp4') ? FRMSVID_PATH : FRMSDOC_PATH );
            forceDownload(filePath+filename, attachmentData[i].filetitle);
        }

    }
    moveToArchive(id) {
        var data = {param:"archive",uid: getProfile().id,_id: id};
        this.props.moveTasksToArchive(data);
    }
    modalOpen(action){
        this.setState({open:!this.state.open, taskAction : action});
    }
    showConfirmArchive = (archiveaction) => {
        this.setState({showArchive:!this.state.showArchive,
            archive_action : archiveaction});
    }
    deleteTaskConfirm(deleteaction) {
        this.setState({deleteTask:!this.state.deleteTask,
            delete_action : deleteaction});
    }
    getUploadedFile(uploadedFile) {
        let uploadedFiles = this.state.uploadedFiles;
        uploadedFiles.push(uploadedFile);
        this.setState({
            filename: uploadedFile.fileName,
            uploadedFiles : uploadedFiles
        });
        var element = document.getElementById("progress-bar-wrapper");
        if(element)
        element.parentNode.removeChild(element);
    }
    removeSelectedAttachment(index) {
        let uploadedFiles = this.state.uploadedFiles;
        if(uploadedFiles) {
            uploadedFiles.splice(index, 1);
            this.setState({uploadedFiles: uploadedFiles});
        }
    }
    viewParentTask(id){
        this.props.history.push('/task/details/'+btoa(id)+'/'+btoa('to-me'))
    }
    OnlineUsers(val) {
        var OnlineStatus= this.props.onlineusers.users.filter(p => val === p.User_ID);
        if(OnlineStatus.length !==0)
            return true;
        else
            return false;
    }
    handleDateChange(date){
        ////////////console.log(date)
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD HH:mm:ss');
        ////////////console.log(formattedDate);
        this.setState({
            duedate : date,
            formattedDate : formattedDate
        })
    }
    dateSwap=(dateChosen)=>{
        this.setState({
            dateChoosen:!this.state.dateChoosen
        })
    }
    updatetask(date){
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            duedate : date,
            formattedDate : formattedDate
        })

    }
    updatetaskdata(dateChoosen){

        this.setState({
            dateChoosen:dateChoosen
        })
        let taskData = this.props.taskdetailsData.taskdetails;
        let taskid =  taskData ? taskData.parent.masterid : atob(getUrlSegment(3));
        let params ={
            "taskid": taskid,
            "uid": getProfile().id,
            "duedate": this.state.duedate,
            "index": ES_INDEX
        }
        this.props.dateupdatetask(params)
    }
    goBack() {
        if((getUrlSegment(5) !==undefined )|| getUrlSegment(6) !==undefined) {
            if (atob(getUrlSegment(5) !== "project")) {
                window.location.href = SHARED_LOGIN.redirect_url + "Project/details/" + atob(getUrlSegment(6)) + "#proTask"
            } else {
                this.props.history.goBack();
            }
        }
        else
            this.props.history.goBack();
    }


    render() {
        ////console.log(this.props.taskdetailsData)
         taskData = this.props.taskdetailsData.taskdetails;
         assigneesData = taskData.assignees;
         logData = this.props.logData.logList;
         commentData = taskData.comment;
         attachmentData = taskData.attachment;
         /* Disscussion attachment data */
         allfilesData = attachmentData.concat(this.props.attachmentData.attachList);
         collectionData = taskData.collection;
         timelogData = this.props.timelogDetails.timelog.data;
         subtaskData = this.props.moretaskDetailsdata.moretaskdetails;
         remarksData = this.props.remarksData.remarks.hits;
         previewableAttachments = attachmentData.length !==0 && attachmentData.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
         taskid =  taskData.parent.masterid;
         deleteid = taskData.parent.masterid;
        if( taskData.parent.createuser === getProfile().id) {
            taskid = "_parent||"+ taskData.parent.masterid;
        }
        else if(  this.state.assignee_id !== '') {
            taskid = this.state.childid+'||'+taskData.parent.masterid;
        }

        parent = this.props.taskdetailsData.taskdetails.parent;
        assignees = this.props.taskdetailsData.taskdetails.assignees;
        assignees = assignees.filter(
            (user => user.assignee ===getProfile().id)

        );
        if(assignees.length > 0) {
             assigneestatus = assignees[0].status;
             time = assignees[0].consolidated_time;
             shared =  assignees[0].share;
             donedate = assignees[0].donedate;
             archived = assignees[0].archive;
        }
        taskmode = (parent.createuser === getProfile().id) ? 'by-me' : '';
        let list = taskData.parent;
        let share =taskData.assignees.filter((item) => item.assignee === getProfile().id)
        return (
          <React.Fragment key={`Task-Detail-data-normal`}>
              <React.Fragment>
                       {list &&
                           <Layout key={"task_layout_1"}
                               taskData={taskData}
                               headerbox={<HeaderBox
                                   list={list}
                                   state={this.state}
                                   data={this.props}
                                   dateSwap = {this.dateSwap}
                                   dateChoosen = {this.state.dateChoosen}
                                   updatetask = {this.updatetask}
                                   goBack = {this.goBack}
                                   taskoptionbutton={<TaskOptionButton
                                       list={list}
                                       share={share}
                                       showReopenConfirm={this.showReopenConfirm}
                                       verified={this.state.verified}
                                       archived={this.state.archived}
                                       creator={parent.createuser}
                                       assignee_status={assignees.length !==0 && assignees[0].status}
                                       showConfirmArchive={this.showConfirmArchive}
                                       modalOpen={this.modalOpen}
                                       deleteTaskConfirm = {this.deleteTaskConfirm}
                                       handleDateChange = {this.handleDateChange}
                                       duedate = {this.state.duedate}
                                   />}

                               />}
                               taskuserinfo={ <TaskDetailUserInfo
                                   state={this.state}
                                   list={list}
                                   assigneesData={assigneesData}
                                   OnlineUsers={this.OnlineUsers}
                                   data={this.props}
                               />}
                               taskdetailcontent={<TaskDetailContent
                                   state={this.state}
                                   list={list}
                                   showremarksModal={this.showremarksModal}
                                   remarksData={remarksData}

                               />}
                               taskstatusbutton={<TaskStatusButton
                                   markasDoneTemp={this.props.modifyTask}
                                   taskAsync={this.props.taskdetailsData}
                                   list={list}
                                   assigneestatus={assigneestatus}
                                   craetorInassignee={assignees}
                                   shared={shared}
                                   taskmode={taskmode}
                                   taskData={taskData}
                                   state={this.state}
                                   parent={parent}
                                   showmarkasdoneModal={this.showmarkasdoneModal}
                                   showverifyModal={this.showverifyModal}
                               />}
                               worktimesection={<WorkTimeSection
                                   list={list}
                                   state={this.state}
                                   share={share}
                                   data={this.props}
                                   showtimelogModal={this.showtimelogModal}
                                   timelogData={timelogData}

                               />}
                               viewparentpanel={<ViewParentPanel
                                   hasparentTask={(list.createuser === getProfile().id && list.has_parent === "yes")?true:false}
                                   viewParentTask={this.viewParentTask}
                                   parentId={list.parent_id}
                               />}
                               attachmentpanel={<AttachmentPanel
                                   has_attachemnt={list.has_attachemnt === "yes"?true:false}
                                   previewableAttachments={previewableAttachments}
                                   attachmentData={attachmentData}
                                   showpreviewmodal={this.showpreviewmodal}
                                   downloadAll={this.downloadAll}
                                   preview = {this.preview}
                               />}
                               collectionpanel={<CollectionPanel
                                   has_collection={list.has_collection === "yes" ?true:false}
                                   createUser={list.createuser}
                                   collectionData={collectionData}
                                   showFormModal={this.showFormModal}
                                   previewForm={this.previewForm}
                               />}
                               individualassigners={<IndividualAssigners
                                   markasDoneTemp={this.props.modifyTask}
                                   taskAsync={this.props.taskdetailsData}
                                   assigneesData={assigneesData}
                                   consolidated_time={assigneesData.consolidated_time}
                                   verified={taskData.parent.verified?taskData.parent.verified:'no' }
                                   data={list}
                                   showReopenConfirm={this.showReopenConfirm}
                                   showremarksModal={this.showremarksModal}
                               />}
                               taskdetailbox={<TaskDetailBox
                                   creator={parent.createuser}
                                   reassign={list.reassign === "yes" ?true:false}
                                   subtaskData={subtaskData !== undefined ? subtaskData :[] }
                                   loader={this.props.moretaskDetailsdata.loader}
                               />}
                            />
                       }



                       <div className="task-right">
                            <div className="chat-tab-block">
                        <div className="scroll-div">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <NavTab
                                getrightTabid={this.getrightTabid}
                                rightTab={this.state.rightTab}
                                AcTab={'home-tab'}
                                label={`Disscussion`}
                                icon={`ico-lib disscussion`}
                            />


                        </ul>
                    </div>
                        <div className="tab-content" id="myTabContent">
                        {this.state.rightTab === 'home-tab' &&
                        <div className="tab-pane fade show active" id="disscussion" role="tabpanel"
                             aria-labelledby="disscussion-tab">
                             <ChatContainer
                                 taskid={atob(getUrlSegment(3))}
                                 assigneesData={assigneesData}
                             />

                        </div>
                        }
                        {this.state.rightTab === 'user-tab' &&
                        <div className="tab-scroll" id="user" role="tabpanel" aria-labelledby="user-tab">
                            <UsersPanelTab
                                assigneesData={assigneesData.filter(item => (item.share === 'no'))}
                                title={`Assigned users`}
                            />
                            <UsersPanelTab
                                assigneesData={assigneesData.filter(item => (item.share === 'yes'))}
                                title={`Invited users`}
                            />
                        </div>
                        }
                        {this.state.rightTab === 'file-tab' &&
                        <div className="split" id="file" role="tabpanel" aria-labelledby="file-tab">
                            <div className="discuss-inner" tabIndex="3">
                                <AttachmentPanelTab
                                    allfilesData={allfilesData}
                                    showpreviewmodal={this.showpreviewmodal}
                                />

                            </div>
                        </div>
                        }
                        {this.state.rightTab === 'act-tab' &&
                        <div className="split" id="act" role="tabpanel" aria-labelledby="act-tab">
                            <div className="discuss-inner" tabIndex="4">
                                <ul className="task-activities-new">
                                        <ActivityPanel
                                            logData={logData}
                                        />

                                </ul>
                            </div>
                        </div>
                        }
                    </div>
                   </div>
                       </div>
              </React.Fragment>

              <React.Fragment>
                {this.state.mark_as_done_comment_array.length !== '0' &&
                     <div className="modal fade bsy-modal s-modal show"  tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: this.state.showremarksModal === true ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib">Remarks</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closeremarksModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="remark-block">
                                    {this.state.mark_as_done_comment_array.map((item,index)=>
                                        <div className='remarks-list' key={`mark-as-done-comment`+index}>
                                            <p>{atob(item.discussion)}</p>
                                            <div className='remarks-attachment-list'>
                                            {item.has_attachment === 'yes' && item.attachment.map((attach,indx)=>
                                                <div className="ind-task-added-user-list" key={"attach-files-"+indx}>
                                                    <div className="ind-added-file-list-left">
                                                        <div className="ind-added-file-list-left-thumb">
                                                            <div className={"format " + getFileExtension(attach.filename)}>{getFileExtension(attach.filename)}</div>
                                                        </div>
                                                        <div className="ind-added-file-list-left-info">
                                                            <h5>{attach.filetitle.split(".")[0]}</h5>
                                                            <p>{formatBytes(attach.filesize) !== 'NaN undefined' ? formatBytes(attach.filesize) : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="ind-added-file-list-right">
                                                        <button className="ico-lib-v1 download-ico-file" onClick={((e) => forceDownload(getAttachmentPath(getFileIcon(attach.filename)) + attach.filename, attach.filename))}></button>
                                                    </div>
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.showtimelogModal === true &&
                     <div className="modal fade bsy-modal show" id="mdone" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: this.state.showtimelogModal === true ? 'block' : 'none' }}>
                    <div className="modal-dialog  modal-dialog-centered modal-lg" role="document"
                         id="bsynapsemodal-container">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib">Time Log</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={(e) => {this.closetimelogModal(e)}}></button>
                            </div>
                            <div className="modal-body time-log">
                                <div className="modal-time-log">
                                    {this.state.timelogloading === 1 &&
                                    <span className="Loader-holder">
                                            <LoaderSvg/>
                                        </span>
                                    }
                                    <div className="time-log-top">
                                        <div className="time-log-sub-list-select">
                                            <form>
                                                {this.state.assignee_status !== "Done" &&
                                                <TimeLogForm getTimeLogEntries={this.getTimeLogEntries.bind(this)} />
                                                }
                                                {timelogData.map((log, index) =>
                                                    <TimeLogList index={index} log={log} key={"log-"+index}/>

                                                )}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {this.closetimelogModal(e)}}>Cancel</button>
                                {this.state.assignee_status !== "Done" &&
                                <button id="add_timelog" className={"general-btn " + (this.state.timelogloading ? 'disable' : '')} data-function="markasdone"
                                        onClick={(e) => this.addTimelog(true)} >Add</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.showmarkasdoneModal === true &&
                     <div className="modal fade bsy-modal show" id="mdone" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: this.state.showmarkasdoneModal === true ? 'block' : 'none' }}>
                    <div className="modal-dialog  modal-dialog-centered modal-lg" role="document"
                         id="bsynapsemodal-container">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib">Mark As Done</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={(e) => {this.closemarkasdoneModal(e)}}></button>
                            </div>
                            <div className="modal-body time-log">
                                <div className="modal-time-log">

                                    {this.state.markasdoneloading === 1 &&
                                    <span className="Loader-holder">
                                        <LoaderSvg/>
                                    </span>
                                    }

                                    <div className="time-log-top">
                                        {(timelogData.length > 0 && this.state.action !=="addtime") &&
                                        <div className="addmore-top">
                                            <h5>Do you want to log more time? <a href="javascript:void(0);" onClick={(e) => {this.addMoretime(e)}} className="addmore-click">Add More</a></h5>
                                        </div>
                                        }


                                        <div className="time-log-sub-list-select">
                                            <form onSubmit={this.handleSubmit}>
                                                {(timelogData.length === 0 || this.state.action === "addtime") &&
                                                <TimeLogForm getTimeLogEntries={this.getTimeLogEntries.bind(this)} />
                                                }
                                                {timelogData.map((log, index) =>
                                                    <TimeLogList index={index} log={log}/>

                                                )}


                                                <div className="time-log-sub-list-remark">
                                                    <textarea className="log-time-remark" id="markComment" name="remark"
                                                              placeholder="Remark (Mandatory)" onChange={(e)=>{this.handleinputChange(e)}}></textarea>
                                                </div>

                                                <div className="time-log-bottom">
                                                    <div className="attachment-task-group progress-ctr" id="markasdoneFiles">
                                                        <div id="progress-file-done" ></div>
                                                        { this.state.uploadedFiles.map((item, index) =>
                                                            <div className="ind-attach-list file" id={"attachment_"+item.fileId} key={"attachment_-"+index}>
                                                                <h5>{item.originalfileName}</h5>
                                                                <button className="ico-lib cl-fl" data-parent={item.fileId}
                                                                        data-target="#delete-attachfile" data-action="unselectattachfiles" onClick={(e)=>this.removeSelectedAttachment(index)}></button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="time-log-sub-list">
                                                        <div className="time-log-items">
                                                            <div className="file-upload">
                                                                <UploadFile getUploadedFile={this.getUploadedFile.bind(this)} progressId="progress-file-done"  />
                                                                <label>Upload a file</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                {/*<input type="submit" value="Submit" />*/}
                                            </form>
                                        </div>



                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={"general-btn grey " + (this.state.markasdoneloading === 1 ? ' disable' : '')}  data-dismiss="modal" onClick={(e) => {this.closemarkasdoneModal(e)}}>Cancel</button>
                                <button id="add_timelog" className="general-btn" data-function="markasdone" onClick={(e) => {this.markasDone(e)}}>Done</button>

                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.showverifyModal === true &&
                     <div className="modal bsy-modal in show" id="varify-task" role="dialog" style={{display: this.state.showverifyModal === true ? 'block' : 'none' }} >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib   verify-tsk">Verify Task</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={(e) => {this.closeverifyModal()}}></button>

                            </div>
                            <div className="modal-body">

                                {this.props.taskdetailsData.loading === true &&
                                <span className="Loader-holder">
                                        <LoaderSvg/>
                                    </span>
                                }
                                <div className="add-lb-nem">
                                    <textarea id="verification" className="task-textarea" name="remark"
                                              placeholder="Remarks(Mandatory)" onChange={(e)=>{this.handleinputChange(e)}}></textarea>
                                    <div className="mdone-attach-bg ">
                                        <ul className="">
                                            <li className="attach-upload ico-lib">
                                                <label htmlFor="verificationAttach">
                                                    <div>Upload a file</div>
                                                    <UploadFile getUploadedFile={this.getUploadedFile.bind(this)} progressId="progress-file-verify" />
                                                </label>
                                            </li>
                                            <li className="">
                                                <div className="progress ui-progress hide "
                                                     id="progress_verificationAttach">
                                                    <div id="progress-bar_verificationAttach" className="progress-bar"
                                                         role="progressbar" aria-valuenow="0" aria-valuemin="0"
                                                         aria-valuemax="100">
                                                    </div>
                                                </div>

                                            </li>
                                        </ul>
                                    </div>
                                    <div id="progress-result_verificationAttach" className="progress-ctr" >
                                        <div id="progress-file-verify" ></div>
                                        { this.state.uploadedFiles.map((item, index) =>
                                            <div className="ind-attach-list file" id={"attachment_"+item.fileId} key={"attachment_"+item.fileId}>
                                                <h5>{item.originalfileName}</h5>
                                                <button className="ico-lib cl-fl" data-parent={item.fileId}
                                                        data-target="#delete-attachfile" data-action="unselectattachfiles" onClick={(e)=>this.removeSelectedAttachment(index)}></button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer checklist-footer">
                                <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {this.closeverifyModal()}}>Cancel</button>
                                <button type="button" id="postVerify" className="general-btn" onClick={(e) => {this.verify()}}>Verify</button>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.showpreviewmodal === true &&
                     <div className="modal bsy-modal att-preview x-large in show" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: this.state.showpreviewmodal === true ? 'block' : 'none' }} >
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header ">
                                <h5 className="modal-title ico-lib "></h5>
                                <div className="close-section">
                                    <button className="ico-lib-list modal-close" data-dismiss="modal"
                                            aria-label="Close" onClick={(e)=>this.closepreviewmodal(e)}></button>
                                </div>

                            </div>
                            <div className="modal-body">
                                {this.state.previewType === "all" &&
                                <>
                                    {previewableAttachments.map((item, index) => {

                                                return (
                                                    <>
                                                        {isImage(item.filename) &&
                                                        <img id="attachPrv" src={FRMSIMG_PATH + item.filename}
                                                             style={{display: (this.state.previewIndex === index) ? 'block' : 'none'}} key={"prev-attach-" + index}/>
                                                        }

                                                        {isVideo(item.filename) &&
                                                        <video width="320" height="240" controls
                                                               style={{display: (this.state.previewIndex === index) ? 'block' : 'none'}}  key={"prev-attach-" + index}>
                                                            <source src={FRMSVID_PATH + item.filename}
                                                                    type="video/mp4"></source>
                                                        </video>
                                                        }



                                                    </>

                                                )
                                            }
                                        )
                                    }
                                    {/*<span onClick={(e) => this.setPreviewIndex('next')}>gfgfgf</span>*/}
                                    {previewableAttachments.length > 1 &&
                                    <div className="slider-button-block">

                                        <button className="slider-button ico-lib slide-left" data-movement="prv"
                                                data-id="0" style={{opacity: (this.state.previewIndex > 0) ? '1' : '0'}} onClick={(e) => this.setPreviewIndex('prev')}>

                                        </button>


                                        <button className="slider-button ico-lib slide-right" data-movement="next"
                                                data-id="1" style={{opacity: (this.state.previewIndex < previewableAttachments.length - 1) ? '1' : '0'}} onClick={(e) => this.setPreviewIndex('next')}></button>

                                    </div>
                                    }
                                </>
                                }
                                {this.state.previewType === "single" &&
                                <>
                                    {
                                        previewableAttachments.map((item, index) => {



                                                return (
                                                    <>
                                                    {isImage(item.filename) &&
                                                    <img id="attachPrv" src={FRMSIMG_PATH + item.filename}
                                                         style={{display: 'block'}} key={"prev-attach-" + index}/>
                                                      }

                                                        {isVideo(item.filename) &&
                                                        <video width="320" height="240" controls
                                                               style={{display: 'block'}} key={"prev-attach-" + index}>
                                                            <source src={FRMSVID_PATH + item.filename}
                                                                    type="video/mp4"></source>
                                                        </video>
                                                        }
                                                  </>
                                                )
                                            }
                                        )
                                    }
                                </>
                                }
                            </div>
                            <div className="modal-footer">
                                {getFileIcon(previewableAttachments[this.state.previewIndex].filename) === 'image'  &&
                                <a className="general-btn-a" id="downloadPrv"
                                   href="javascript:;" onClick={()=>forceDownload(FRMSIMG_PATH + previewableAttachments[this.state.previewIndex].filename, previewableAttachments[this.state.previewIndex].filetitle)}>Download</a>
                                }
                                {getFileIcon(previewableAttachments[this.state.previewIndex].filename) === 'mp4'  &&
                                <a className="general-btn-a" id="downloadPrv"
                                   href="javascript:;" onClick={()=>forceDownload(FRMSVID_PATH + previewableAttachments[this.state.previewIndex].filename, previewableAttachments[this.state.previewIndex].filetitle)}>Download</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.open &&
                    <Modal  title = { this.state.taskAction === 'subtask' ? 'Sub Task' : 'Update Task'} Close ={this.modalOpen.bind(this)}>
                            <AddToDoTask
                                modalOpen = {this.modalOpen}
                                taskDetailId = {atob(getUrlSegment(3))}
                                taskData = {taskData}
                                taskAction = {this.state.taskAction}
                            />
                </Modal>
                }
                {this.state.showFormModal &&
                    <ShowFormModal
                        formName={this.state.formName}
                        collectionId ={this.state.collectionid}
                        referenceId ={this.state.taskid}
                                   hideFormModal={this.hideFormModal} />
                }
                {this.state.showPreviewFormModal &&
                    <ShowFormPreview
                        formName={this.state.formName}
                        collectionId ={this.state.collectionid}
                        previewForm={this.previewForm}
                        hidePreviewForm={this.hidePreviewForm}
                    />
                }

                {this.state.showReopenConfirm &&
                    <AlertModal id="reopen-todo"
                            title="Are you sure you want to reopen this task?"
                            OK_button_text="Yes, Reopen It"
                            OK_action={() => this.showReopenModal()}
                            showModal = {this.state.showReopenConfirm}
                            CANCEL_action={() => this.showReopenConfirm()}
                />
                }
                  {this.state.previewStatus &&
                  <SimpleModal
                      Close={this.preview}
                  >
                      <div className="preview-area chat-panel">


                          {isImage(this.state.url) &&
                          <img id="attachPrv" src={this.state.url}
                              />
                          }

                          {isVideo(this.state.url) &&
                          <video width="320" height="240" controls
                                 >
                              <source src={this.state.url}
                                      type="video/mp4"></source>
                          </video>
                          }

                          {isAudio(this.state.url) &&
                          <audio  width="320" height="240" controls
                          >
                              <source src={this.state.url}
                                      type="audio/mpeg"></source>
                          </audio >
                          }

                          <div className="preview-options">
                              <ul className="resources-filters-icons">
                                  <li><a className="rs-tab-btn ico-lib-v1 rc-download single-option"
                                         onClick={() => forceDownload(this.state.url, this.state.name)}> </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </SimpleModal>

                  }
                {this.state.showReopenModal  &&
                     <div className="modal fade bsy-modal s-modal show"  tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: this.state.showReopenModal === true ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib">Re open task</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closeReopenModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="modal-time-log">

                                    {this.props.taskdetailsData.loading === true &&
                                    <span className="Loader-holder">
                                        <LoaderSvg/>
                                    </span>
                                    }

                                    <div className="time-log-top">


                                        <div className="time-log-sub-list-select">
                                            <form onSubmit={this.handleSubmit}>


                                                <div className="time-log-sub-list-remark">
                                                    <textarea className={"log-time-remark " + ( this.state.reOpenSubmit && (!this.state.remark  || this.state.remark === '') ? 'val-error' :''  )} id="markComment" name="remark"
                                                              placeholder="Remark (Mandatory)" onChange={(e)=>{this.handleinputChange(e)}}></textarea>
                                                </div>

                                                <div className="time-log-bottom">
                                                    <div className="attachment-task-group progress-ctr" id="todoFileAssets">
                                                        <div id="progress-file-reopen"></div>
                                                        { this.state.uploadedFiles.map((item, index) =>
                                                            <div className="ind-attach-list file" id={"attachment_"+item.fileId} key={"attachment_"+item.fileId}>
                                                                <h5>{item.originalfileName}</h5>
                                                                <button className="ico-lib cl-fl" data-parent={item.fileId}
                                                                        data-target="#delete-attachfile" data-action="unselectattachfiles" onClick={(e)=>this.removeSelectedAttachment(index)}></button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="time-log-sub-list">
                                                        <div className="time-log-items">
                                                            <div className="file-upload">
                                                                <UploadFile getUploadedFile={this.getUploadedFile.bind(this)} progressId="progress-file-reopen"  />
                                                                <label>Upload a file</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>



                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {this.closeReopenModal(e)}}>Cancel</button>
                                <button id="reopen_task" className={"general-btn " + (this.state.reopenloading ? 'disable' : '')} data-function="reopen"
                                        onClick={(e) => this.state.mainTask ? this.reopenAllTask(): this.reopenTask()} >Reopen</button>
                            </div>
                        </div>
                    </div>
                </div>
                }

                {this.state.showArchive &&
                    <AlertModal
                            id="take-pool"
                            title={this.state.archive_action === "archive" ? "Are you sure want to archive this task?" : "Are you sure want to unarchive this task?"}
                            OK_button_text={this.state.archive_action === "archive" ? "Yes, Archive It" : "Yes, Unarchive It"}
                            OK_action={() => this.props.moveTasksToArchive({param:this.state.archive_action,uid: getProfile().id,_id: taskid})}
                            showModal = {this.state.showArchive}
                            CANCEL_action={() => this.showConfirmArchive()}
                />
                }

                  {this.state.deleteTask &&
                  <AlertModal
                      id="delete-pool"
                      title={this.state.delete_action === "delete" ? "Are you sure you want to delete this task?" : "Are you sure you don't want to delete this task?"}
                      OK_button_text={this.state.delete_action === "delete" ? "Yes, Delete It" : "No, Don't delete It"}
                      OK_action={() => this.props.deleteTaskPermanent({_id: deleteid, secretKey: "lsuser12345!", index: ES_INDEX})}
                      showModal = {this.state.deleteTask}
                      CANCEL_action={() => this.deleteTaskConfirm()}
                  />
                  }

                  {this.state.dateChoosen &&
                  <SimpleModal

                      Close={()=>this.dateSwap(false)}
                      title={`Due On Date Change`}
                      haveFooter={1}
                      cancelAction={()=>this.dateSwap(false)}
                      OKAction={()=>this.updatetaskdata(false)}
                      OKLabel={`Apply`}
                      modalType={'custom-date-range'}
                  >
                      <div>
                          <DateTimePickerBox
                              handleDateChange={this.handleDateChange}
                              placeholderText="Due date"
                              id="duedate"
                              fromTaskDetail={true}
                              startDate = {this.state.duedate ? this.state.duedate : new Date() }
                              updatetask = {this.updatetask}
                              dateSwap = {this.dateSwap}
                              input = {false}
                              renderInput = {true}

                          />

                      </div>


                  </SimpleModal>
                  }

            </React.Fragment>


            </React.Fragment>
        );
    }


}
const mapStateToProps = state => ({
    taskdetailsData : state.TaskReducer.TaskDetails,
    moretaskDetailsdata  : state.TaskReducer.MoretaskDetails,
    timelogDetails : state.TaskReducer.Gettimelog,
    remarksData : state.TaskReducer.GetRemarks,
    projects:state.TaskReducer.GetProjectData,
    taskSelect:state.TaskReducer.getTaskSelection,
    modifyTask:state.TaskReducer.ModifyTask,
    taskFilters:state.TaskReducer.filterTasks,
    onlineusers:state.CommonReducer.onlineusers,
    markasdonestatus:state.TaskReducer.MarkAsDoneStatus,
    reassigntask:state.TaskReducer.ReassignTask,
    multiLoader:state.CommonReducer.multiLoader,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    listUsersPermission:state.UsersReducer.listUsersPermissiondata,
});

export default  withRouter(connect(mapStateToProps, {
    toVerify:taskActions.listtask,
    gettaskdetails:taskActions.taskdetails,
    gettaskdetailsAsync:taskActions.gettaskdetailsAsync,
    getmoretaskdetails:taskActions.moretaskdetails,
    markasdone :taskActions.markasdone,
    verifytask :taskActions.verify,
    addtimelog :taskActions.addtimelog,
    gettimelog:taskActions.gettimelog,
    getremarks:taskActions.getremarks,
    savediscussion :taskActions.savediscussion,
    users:usersActions.listUsers,
    showform : collectionActions.showform,
    getFormData : collectionActions.getFormData,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    moveTasksToArchive : taskActions.moveTasksToArchive,
    deleteTaskPermanent : taskActions.deleteTaskPermanent,
    saveToDoAttachments : taskActions.saveToDoAttachments,
    reopenTask:taskActions.reopenTask,
    reopenAllTask : taskActions.reopenAllTask,
    todo:taskActions.listtask,
    getTaskHelper : taskActions.getTaskHelper,
    TaskCompletionasync:taskActions.postTaskCompletionasync,
    notificationdata:usersActions.notificationInfo,
    dateupdatetask: taskActions.dateupdatetask

})(TaskDetails));


function Layout(props) {
    let list = props.taskData.parent;

    return (
        <React.Fragment key={`Task-Detail-data-normal`}>
            <React.Fragment>
                <div className="task-left" key={"task-details-main-layout"}>
                    <div className="task-detail-wrapper switch-status-view task-all-done" tabIndex="1">
                        {list &&
                            <React.Fragment key={`Task-Detail-data-1`}>
                                <div className="task-detail-panel">
                                            {props.headerbox}
                                    <div className="task-detail-panel-content">
                                        <span className={" ico-lib cleared " + (list.status === 'Done' ? ' done-mark' : '')}></span>
                                        <div className="task-detail-user-info">
                                            {props.taskuserinfo}
                                        </div>
                                        <div className="task-detail-panel-content-area">
                                            {props.taskdetailcontent}
                                        </div>
                                        <div className="task-submit-block">
                                            {props.taskstatusbutton}
                                            <div className="working-time-section">
                                                {props.worktimesection}
                                            </div>
                                        </div>
                                    </div>

                                        {props.viewparentpanel}
                                        {props.attachmentpanel}
                                        {props.collectionpanel}
                                    <div className="individual-assigners">
                                        {props.individualassigners}
                                    </div>
                                </div>
                                    {props.taskdetailbox}
                            </React.Fragment>
                        }

                    </div>
                </div>

            </React.Fragment>



        </React.Fragment>
    );
}
