import React from 'react';
import axios from 'axios';
import { Draggable, state } from 'react-page-maker';
import MultiAreaChart from "../views/charts/MultiAreaChart";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment, isValidDate} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {SettingsPanel, ViewData} from "./ViewData";
import SettingsModal from "./SettingsModal";
import moment from "moment";
import 'moment-timezone';

class CurrentDateTime extends React.Component {
    constructor(props,context) {
        super(props,context);
        this.state={
            modalStatus:false,
            showColorPicker:false,
            background:'green',
            payload:{
                chartTitle:"",
                subTitle:"subtitle",

            },



            showlistField:[],
            dispClock:'',
            time: moment().tz('Australia/Melbourne').format('hh:mm:ss')
        }


    }
    componentDidMount() { // create the interval once component is mounted
        if(this.props.payload && this.props.payload.dispClock === 'yes') {
            this.update = setInterval(() => {
                this.setState({time: moment().tz('Australia/Melbourne').format('hh:mm:ss')});
            }, 1000);
        }
        else{
            this.setState({time: moment().tz('Australia/Melbourne').format('hh:mm:ss')});
        }
        this.setState({payload:this.props.payload})
    }


    openSetting=()=>{
        this.setState({modalStatus:!this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }

    chartSaving=()=>{
        var prjctdtlid = atob(getUrlSegment(4));
        let sharetype = getUrlSegment(1);
        let  uid = '';
        if(sharetype === 'public') {
            uid = 2;
        }
        else{
            uid = getProfile().id;
        }
        let param={
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard":state.getStorableState()
            ,
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus:false})
    }





    checkBoxchange=(e)=> {
        let val;
        if (e.target.value === "false") {
            val = true
        } else {
            val = false
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    radioBoxchange=(e)=> {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    handleInputChange=(e)=>{
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    handleChange=(e)=>{
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }

    render() {

        const {
            id,
            showBasicContent, showPreview,
            dropzoneID,payload, parentID, name
        } = this.props;


        let containerData={
            chartTitle :  this.state.chartTitle || payload && payload.chartTitle,
            showlistField:[],
            collection :  this.state.collection || payload && payload.collection ,
            dispClock:this.state.dispClock || payload && payload.dispClock ,
            timeFormat: this.state.format || payload && payload.timeFormat

        }

        if (showBasicContent) {
            return (
                <Draggable { ...this.props } >
                    {
                        <span  className="drag-icon">{name}</span>
                    }
                </Draggable>
            )
        }

        if (showPreview) {
            return (


          <>
              <div className="ind-small-tab-dashboard">
                  <div className="small-tab-header with-desc-box"><h5>{containerData.chartTitle} </h5></div>
                  <div className="small-tab-footer">
                      <h5>
                      {(containerData.format === '' || containerData.format === undefined) ? moment().format("MMM DD , YYYY") :  moment().format(containerData.format)}
                       &nbsp; {(containerData.timeFormat === 'yes')&&  (this.state.time.toLocaleString())}
                      </h5>
                  </div>
              </div>

          </>


            );
        }
        return (
            <React.Fragment>
                <Draggable {...this.props}  draggable={!this.props.DragDisabled.dragStatus} >
                    <SettingsPanel
                        id={id}
                        name={containerData.chartTitle?containerData.chartTitle:name}
                        dropzoneID={dropzoneID}
                        parentID={parentID}
                        openSetting={this.openSetting}
                        removeElement={<div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}/>}
                    />
                </Draggable>
                {this.state.modalStatus &&
                <SettingsModal
                    title={`CURRENT DATE TIME  -   Settings`}
                    containerData={containerData}
                    colorpickerStatus={this.state.colorpickerStatus}
                    modaltype={'datetime'}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    checkBoxchange={this.checkBoxchange}
                    radioBoxchange={this.radioBoxchange}
                    handleChange={this.handleChange}
                    handleInputChange={this.handleInputChange}

                />

                }
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    showFormModal:state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange:state.DynamicDashboard.getDashboard,
    DragDisabled:state.DynamicDashboard.getDragDisabled,
    EntityData:state.EntityReducer.GetEntity,
    filterCnt:state.DynamicDashboard.getFilterCnt,


});
export default  withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform : collectionActions.showform,
    chartSaving:DynamicDashboard.saveDashboarddata,
    dragStatus :DynamicDashboard.dragStatus,

})(CurrentDateTime));
