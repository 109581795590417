import React, {Component, useState} from 'react';
import {connect} from "react-redux";
import * as usersActions from "../../../actions/Users"
import {withRouter} from 'react-router';
import moment from "moment";
import {getProfile} from "../../../utilities/AuthService";
import {ES_INDEX, SHARED_LOGIN} from '../../../constants'
import rootActions from "../../../actions";
import * as collectionActions from "../../../actions/Collections";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import LoaderSvg from "../../views/LoaderSVG";
import AlertModal from "../../views/AlertModal";
import ShowFormModal from "../../views/ShowFormModal";
import UserPermission from "./UserPermission"
import {InfoBlock,Navbar,LogTab,Team,RelatedGroup,InvolvedProjects, InfoBlockUpper, InfoBlockDetails, StatisticsDetails, LogintimeTaskPerform, PositionStatistics,TaskStatistics} from "./UsersDetailsData";
import Modal from "../../views/Modal";
import AddToDoTask from "../AddToDoTask";
import {Scrollbars} from "react-custom-scrollbars";
import NoData from "../../views/NoData";
import OutsideAlerter from "../../views/OutsideAlerter";
let seluserid=[];
class UsersDetails extends Component {
    constructor(props) {
        super(props);
        this.state={
            drop : false,
            showDeleteConfirm : false,
            showFormModal: false,
            collectionId: ES_INDEX + '10003',
            status:true,
            addNewTask:false,
            userDetails:{},
            loginprfrmnc :'day',
            profileId : 0,
			projectPermission : false,
			chatPermission : false,
            sendLoader: 0
        }
        this.showDrop = this.showDrop.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.showFormModal = this.showFormModal.bind(this);
        this.hideFormModal = this.hideFormModal.bind(this);
        this.closeDeleteConfirm = this.closeDeleteConfirm.bind(this);
        this.goBack=this.goBack.bind(this);
        this.addNewTask = this.addNewTask.bind(this);
        this.voiceCall = this.voiceCall.bind(this);
        this.vedioCall = this.vedioCall.bind(this);
        this.closeNewTask = this.closeNewTask.bind(this);
        this.confirmUserEmail = this.confirmUserEmail.bind(this);
        this.sendUserEmail = this.sendUserEmail.bind(this);
        this.loginTaskPerformnc = this.loginTaskPerformnc.bind(this);
        this.goToChat = this.goToChat.bind(this);
       
    }
    showDrop(){
        if (!this.state.drop) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        this.setState({drop: !this.state.drop});
    }
    componentDidMount() {
        this.props.mainClick('team','details');
        this.props.teamClick('overview');
        this.props.getModeration();
        this.props.loaderStatus('user-details', true);
        var uId = atob(getUrlSegment(3));
        var profileId = getProfile().id;
        this.setState({uId : uId, profileId : profileId}, function(){
            this.props.userinfo(uId,profileId);
            this.props.usermodulepermission({uid:uId});
            var data = { uid : uId , intrval : 'day' };
            this.props.userstatistics(data);
            this.props.showform({ _id : this.state.collectionId, uid : getProfile().id});
            this.props.getFormData({ _id : uId, collectionid : this.state.collectionId, metainfo : true});
        });
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "overview",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);

    }


	handleOutsideClick(e) {
        this.showDrop();
    }
    hideFormModal(id) {
        this.setState({
            showFormModal: false
        })
    }
    loginTaskPerformnc(e, typ) {
        var uId = atob(getUrlSegment(3));
        this.setState({
            loginprfrmnc: typ
        })
        var data = { uid : uId , intrval : typ };
        this.props.userstatistics(data);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if(this.props.deleteUserInfo.userDeleted !== prevProps.deleteUserInfo.userDeleted){
            setTimeout(function () {
                beta.props.history.push('/team');
                beta.closeDeleteConfirm();
                beta.setState({loading:0})
            }, 1000)
        }
        if(this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged) {
            var uId = atob(getUrlSegment(3));
            var profileId = getProfile().id;
            setTimeout(function () {            
                beta.props.loaderStatus('user-details', true);
                beta.props.userinfo(uId, profileId);
                beta.props.showform({ _id : beta.state.collectionId, uid : getProfile().id});
                beta.props.getFormData({ _id : uId, collectionid : beta.state.collectionId, metainfo : true});
            }, 1000)
        }
        if(this.props.listUserInfo.UserInfo !== prevProps.listUserInfo.UserInfo){
            this.setState({userDetails:this.props.listUserInfo.UserInfo||{}})
        }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            var uId = atob(getUrlSegment(3));
            var profileId = getProfile().id;
            beta.props.userinfo(uId,profileId);
            beta.props.userstatistics(uId);
            beta.closeNewTask();
            beta.setState({loading:0})
        }
        if(prevProps.teamClickData.subMenu !== this.props.teamClickData.subMenu){
            var action ='';
            if(this.props.teamClickData.subMenu === 'activities'){
                action = "activitieslisting";

            }else if(this.props.teamClickData.subMenu === 'team') {
                action = "teamlisting";

            }else if(this.props.teamClickData.subMenu === 'permissions') {
                action = "permissionslisting";
                }
            else {
                action ="overview";
            }
            let trackparams = {
                "module": "Team",
                "page": window.location.pathname,
                "action": action,
                "browser": window.navigator.userAgent,
                "uid": getProfile().id,
            }
            this.props.activityTracking(trackparams);
            let params={
                "uid": this.props.listUserInfo.UserInfo.userid,
                "usertype" : this.props.listUserInfo.UserInfo.userType
            }
            this.props.listusergroup(params);
        }
        if(this.props.sendEmailusers.senduseremail !== prevProps.sendEmailusers.senduseremail){
            var uId = atob(getUrlSegment(3));
            var profileId = getProfile().id;
            setTimeout(function () {
                beta.props.userinfo(uId, profileId);
                beta.setState({sendLoader:0})
            }, 1000)
        }

    }
    showDeleteConfirm() {
        this.setState({drop: false})
        this.setState({showDeleteConfirm : !this.state.showDeleteConfirm});
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "deletebuttonclick",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }
    closeDeleteConfirm() {
        this.setState({drop: false})
        this.setState({showDeleteConfirm : false});
    }
    handleDelete() {
        this.setState({loading:1})
        var userId = atob(getUrlSegment(3));
        this.props.deleteUser(userId,  getProfile().id);
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "deleteuser",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }
    showFormModal(e, collecteddataid){
        let collectionid = this.state.collectionId
        this.props.resetForm();
        if(collecteddataid){
            this.props.getFormData({ _id : collecteddataid, collectionid : collectionid, metainfo : true});
        }
        this.setState({
            formName : 'User Details',
            collectionid : collectionid,
            collecteddataid : collecteddataid,
            showFormModal: true
        })
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "editbuttonclick",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }
    goBack(){
        this.props.history.push('/team');
    }
    addNewTask(){
        let userDetails = this.state.userDetails;
        let selectedToUsers=[{
            phone: userDetails.phone,
            userType: userDetails.userType,
            image: userDetails.image,
            userId: userDetails.userid,
            systemType: userDetails.userType,
            organizationId: "",
            lastName: userDetails.lastName,
            designation: userDetails.designation,
            department: userDetails.department,
            firstName: userDetails.firstName,
            email: userDetails.email,
            uniqueId: "user_"+userDetails.userid,
            gender: "",
            _id: userDetails.userid}]
        this.setState({addNewTask:true,selectedToUsers:selectedToUsers,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData})
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "addtaskbuttonclick",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }
    voiceCall(e,uname,umail){
        window.trova_startaudiocall(umail, uname);
    }
    vedioCall(e,uname,umail){
        window.trova_startvideocall("Yes", umail, uname);
    }
    closeNewTask(){
        this.setState({addNewTask:false})
    }
    confirmUserEmail(e){
        this.setState({
            confirmUsermail: !this.state.confirmUsermail
        })
    }
    sendUserEmail(e){
        let seluserid = atob(getUrlSegment(3));
        let params={
            "ids": [seluserid]
        }
        this.props.sendEmailTousers(params);
        this.setState({
            confirmUsermail: !this.state.confirmUsermail,
            sendLoader: 1
        })

    }
    goToChat(e,uid,firstName,lastName,image){
        let userdata='';
        userdata={"receiverId":uid,"type":"One to One","name":firstName+' '+lastName,"userHead":image};
        window.location.href = window.location.origin+'/chat/'+btoa(JSON.stringify(userdata));
    }
    render() {
        let usersdetail = this.props.listUserInfo.UserInfo;
        let userssubordinate = usersdetail.subordinate;
        let getModerationstatus=this.props.getModerationstatus.getmoderation.moderation||'';
        let usersStaticsdata = this.props.listUserStatistics ? this.props.listUserStatistics.userStatistics.Taskstatistics : [];
        var statValue  = 0;
        var data = Array();
	    let datacolor ={'New' : '#3fa9f5','Done':'#1BB188','InProgress':'#F5A623','overdue':'#D0021B'};

        if (Array.isArray(usersStaticsdata) && usersStaticsdata.length){
            usersStaticsdata.forEach(
                function iterator(item,index){
                    
					var keys = Object.keys(item)[0];
					if(keys!='total' && keys !='unread'){
						if(item[keys] > 0){
							statValue = 1;
						}
						data.push({Count:item[keys],Name:keys,fill:datacolor[keys]});
					}
                    
                }
            );
        }

        const tabledata = [{areaLabel: "Bar Color",color: "#cc332b",field: "1004359745",id: "item-1",label: "Count"},{areaLabel: "Bar Color",color: "#cc332b",field: "1004359745",id: "item-2",label: "Count"}];

        let usersStatisticsdetail = this.props.listUserStatistics ? this.props.listUserStatistics.userStatistics : '';
        let UserTaskRate = usersStatisticsdetail.task_rate ? usersStatisticsdetail.task_rate : '';
      
			
        let loginPerformance = this.props.listUserStatistics.userStatistics ? this.props.listUserStatistics.userStatistics.task_perfomance : [];
        let UserTimelog = this.props.listUserStatistics.userStatistics ? this.props.listUserStatistics.userStatistics.user_timelog : [];
        let loginPerformancedata = loginPerformance ? loginPerformance[0] :[];
        let dates = loginPerformancedata ? loginPerformancedata.dates:[];
        let done = loginPerformancedata ? loginPerformancedata.done:[];
        let inprogress = loginPerformancedata ? loginPerformancedata.inprogress:[];
        let new1 = loginPerformancedata ? loginPerformancedata.new:[];
        let pending = loginPerformancedata ? loginPerformancedata.pending:[];
        var timests = 0;
        if (Array.isArray(dates) && dates.length){
            var out = Array();
			var presentprfrmnctyp = this.state.loginprfrmnc;
			
            dates.forEach(
                function iterator(item,index){
					
					if(presentprfrmnctyp === 'day'){
						item = moment(item, 'DD-MM-YYYY')
						item  = item.format('YYYY-MM-DD');
						item = moment(item).format("DD MMM, YYYY");
					}
                    var timelog = 0;
                    if(UserTimelog[item] !== 'undefined'){
                        timelog = UserTimelog[item] ? Math.floor(UserTimelog[item]/ 3600) : 0 ;
                    }
                    timests = 1;
                    out.push({"item 1":item,"done":done[index],"inprogress":inprogress[index],"new":new1[index],"pending":pending[index],"timelog":timelog});
                }
            );
        }
        let usersactlog = usersdetail.log;
        let userteam = usersdetail.team;
        let sideMenu =  this.props.teamClickData.subMenu||'';
        let userPermissiondata = this.props.listUsersPermission.UsersPermission;
	
		
        let projectPermission=""
        userPermissiondata.forEach(
            function iterator(item,index){
                if(item.module == "Project" && item.module_permission == "yes"){

                        projectPermission= true;
                }
            }
        );
		let chatPermission=""
        userPermissiondata.forEach(
            function iterator(item,index){
                if(item.module == "Chat" && item.module_permission == "yes"){

                        chatPermission= true;
                }
            }
        );
        let teamPermission=false
		 let editPermission=false
		 let delPermission=false
        userPermissiondata.forEach(
            function iterator(item,index){
                if(item.module == "Team" && item.module_permission == "yes"){

                    teamPermission= true;
					 let permission = item.actions.filter(subitem =>subitem.action === "Edit")[0]||[];
                    if(permission.action_permission === 'yes') {
                        editPermission = true;
                    } 
					let permissiondlt = item.actions.filter(subitem =>subitem.action === "Delete")[0]||[];
                    if(permissiondlt.action_permission === 'yes') {
                        delPermission = true;
                    }
                }
            }
        );
        let userrelatedGroups = this.props.listgroups.userGrouplist
        //console.log(userrelatedGroups)
        return (
            <>
            {teamPermission == true ?
                <div className="details-wrapper user-full">
                    {this.props.loader.loaderType === 'user-details' && this.props.loader.status === true &&
                        <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                    }
                    {this.state.sendLoader === 1 &&
                        <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                    }
                    <div className="user-detail-card">
                        <InfoBlockUpper
                            goBack={this.goBack}
                            goToChat={this.goToChat}
                            voiceCall={this.voiceCall}
                            vedioCall={this.vedioCall}
                            addNewTask={this.addNewTask}
                            showDrop={this.showDrop}
                            drop={this.state.drop}
                            showFormModal={this.showFormModal}
                            showDeleteConfirm={this.showDeleteConfirm}
                            delPermission={delPermission}
                            editPermission={editPermission}
                            showDeleteConfirm={this.showDeleteConfirm}
                            usersdetail={usersdetail}
                            chatPermission={chatPermission}
                        />
                        <InfoBlockDetails
                            usersdetail={usersdetail}/>
                        <InfoBlock
                            usersStatisticsdetail={usersStatisticsdetail}
                        />
                        <div className="selective-mail-box">
                            {getModerationstatus ==='yes' && usersdetail.sentmail =='no' &&
                            <button className="layer-btn" onClick={(e) => this.confirmUserEmail(e)}>Send Email</button>
                            }
                        </div>
                    </div>
                    {(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === "root" || userssubordinate === 1) &&
                    <React.Fragment>
                        {sideMenu === "overview" &&
                        <React.Fragment>
                            <div className="grid-row">
                                <div className="each-grid grid-col-3">
                                    <div className="grid-text-header">
                                        <h5>Involved Projects</h5>
                                    </div>
                                    <Scrollbars style={{"height": "100%"}}>
                                        <div className="grid-body">
                                            <InvolvedProjects
                                                history={this.props.history}
                                                usersStatisticsdetail={usersStatisticsdetail}
                                                projectPermission={projectPermission}
                                            />
                                        </div>
                                    </Scrollbars>
                                </div>
                                <div className="each-grid grid-col-3">
                                    <div className="grid-text-header">
                                        <h5>Task Run Rate</h5>
                                    </div>
                                    <div className="grid-body">
                                        <div className="grid-task-runrate">
                                            <PositionStatistics UserTaskRate={UserTaskRate}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="each-grid grid-col-3">
                                    <div className="grid-text-header">
                                        <h5>Task Statistics</h5>
                                    </div>
                                    <div className="grid-body task-stat">
                                        {statValue == 1 ?
                                            <React.Fragment>
                                                <div className="chart-left">
                                                    <TaskStatistics containerData={data} tabledata={tabledata}/>
                                                </div>
                                                <StatisticsDetails
                                                    usersStaticsdata={usersStaticsdata}/>
                                            </React.Fragment>
                                            :
                                            <div className="static-mode-block">
                                                <div className="empty-data">No Data Found</div>
                                                <div className=""></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="grid-row">
                                <LogintimeTaskPerform
                                    timests={timests}
                                    loaderStatus={this.props.loader}
                                    loginprfrmnc={this.state.loginprfrmnc}
                                    loginTaskPerformnc={this.loginTaskPerformnc}
                                    out={out}/>
                            </div>
                        </React.Fragment>
                        }
                        {sideMenu !== "overview" &&
                        <div className="tab-holder user-ui user-height">
                            <Navbar
                                sideMenu={sideMenu}
                                userteam={userteam}
                            />
                            <div className="tab-in-content">
                                {sideMenu === "overview" &&
                                <div className="tab-pane">
                                    <div className="tab-wrapper">
                                        <h5>UserDashboard</h5>
                                    </div>
                                </div>
                                }
                                {sideMenu === "activities" &&
                                <LogTab
                                    usersactlog={usersactlog}
                                />
                                }
                                {sideMenu === "permissions" &&
                                <UserPermission/>
                                }
                                {sideMenu === "team" &&
                                <Team
                                    userteam={userteam}
                                />
                                }
                                {sideMenu === "relatedgroups" &&
                                <RelatedGroup
                                    userrelatedGroups={userrelatedGroups}
                                />
                                }
                            </div>
                        </div>
                        }
                    </React.Fragment>
                    }
                    {this.state.showDeleteConfirm &&
                    <AlertModal id="take-pool"
                                title="Are you sure you want to delete?"
                                OK_button_text="Yes, Delete It"
                                OK_action={this.handleDelete}
                                showModal={this.state.showDeleteConfirm}
                                CANCEL_action={this.closeDeleteConfirm}
                    />
                    }
                    {this.state.confirmUsermail &&
                    <>
                        <AlertModal id="proceed"
                                    title="Do you need to send activation mail?"
                                    OK_button_text="Yes, Proceed"
                                    OK_action={() => this.sendUserEmail()}
                                    showModal={this.state.confirmUsermail}
                                    CANCEL_action={() => this.confirmUserEmail()}
                        />
                    </>
                    }
                    {this.state.showFormModal &&
                    <ShowFormModal formName={this.state.formName}
                                   collectionId={this.state.collectionId}
                                   referenceId={this.state.collectionId}
                                   editId={this.state.collecteddataid}
                                   hideFormModal={this.hideFormModal}
                    />
                    }
                    {this.state.addNewTask &&
                    <Modal title={'Create New Task'} Close={this.closeNewTask}>
                        <AddToDoTask
                            projectList={this.state.projectList}
                            tagList={this.state.tagList}
                            type={'user'}
                            selectedToUsers={this.state.selectedToUsers}
                            // modalOpen = {this.modalOpen}
                        />
                    </Modal>
                    }
                </div> :
                <div className='absolute-holder'>
                    <NoData
                        msg={`No permission to access`}

                    />
                </div>
            }
            </>
        );
    }
}

const mapStateToProps = state => ({
    listUserInfo:state.UsersReducer.listUserInfoData,
    listUsersStatics:state.UsersReducer.listUsersStaticsdata,
    listUserStatistics:state.UsersReducer.UserStatistics,
    listUsersActions1:state.UsersReducer.listUserModulePermissiondata,
    loader:state.CommonReducer.loader,
    deleteUserInfo:state.UsersReducer.deleteUserInfo,
    getSavedForm : state.CollectionsReducer.getSavedForm,
    teamClickData:state.CommonReducer.teamClick,
    projects:state.TaskReducer.GetProjectData,
    HistoryPushData:state.TaskReducer.HistoryPushData,
	listUsersPermission:state.UsersReducer.listUsersPermissiondata,
    listgroups:state.UsersReducer.listUserGroup,
    getModerationstatus : state.UsersReducer.getModeration,
    sendEmailusers : state.UsersReducer.sendEmailusers,
});
export default  withRouter(connect(mapStateToProps, {
    userinfo:usersActions.listUserInfo,
    usersstatics:usersActions.listUsersStatics,
    userstatistics:usersActions.UserStatistics,
    deleteUser:usersActions.deleteUser,
    loaderStatus:rootActions.commonActions.loaderStatus,
    showform : collectionActions.showform,
    resetForm : collectionActions.resetForm,
    getFormData : collectionActions.getFormData,
    mainClick:rootActions.commonActions.MenuClick,
    users:usersActions.listUsers,
    usermodulepermission:usersActions.listUserModulePermission,
    teamClick:rootActions.commonActions.teamClick,
    activityTracking:rootActions.commonActions.activityTracking,
    listusergroup:usersActions.listGroup,
    getModeration:usersActions.getModeration,
    sendEmailTousers:usersActions.sendEmailTousers,
})(UsersDetails));