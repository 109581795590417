import React, {Component} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class DatePickerBox extends Component {

    constructor(props) {
        super(props);
           this.state = {
            startDate: new Date(),
               index : 0
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });

        this.props.handleDateChange(date, this.props.index);
    }

    componentDidMount() {


    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.startDate!==prevState.startDate){
            return { startDate: nextProps.startDate};
        }
        else return null;
    }



    render() {
        return (
                <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                    placeholderText = {this.props.placeholderText}
                    id={this.props.id? this.props.id :'date'}
                    index = {this.props.index}
                    className={ "form-type-input "+ (this.props.addErrorClass ? " red-alert" : "")}
                    maxDate={this.props.maxDate}
                    minDate={this.props.minDate}
                    autoComplete={'off'}
                    showMonthYearDropdown={false}
                />
        );
    }
}


export default DatePickerBox
