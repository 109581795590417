import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';


class UserDetailsHtml extends Component {

    constructor(props) {
        super(props);
    }



    componentDidMount() {

    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }



    render() {

        return (


                <div className='details-wrapper'>
                    <div className="details-in-holder">
                        <div className="pintile-thumb">
                            <img src="https://d22znjc9y9upyw.cloudfront.net/user/133_5befd20388a35_thumb.png" className="pintile-thumb-image" alt="user-image" />
                        </div>
                        <div className="pintile-description">
                            <div className="user-description-in">
                                <h1 className="user-name-in">Ajin P P</h1>
                                <ul className="user-detail-list">
                                    <li className="user-detail-list-items">
                                        <span className="icon-in icon-user-in"></span>UI Developer
                                    </li>
                                    <li className="user-detail-list-items">
                                        <span className="icon-in icon-map-in"></span>Logical Steps,Kochi Office
                                    </li>
                                    <li className="user-detail-list-items">
                                        <span className="icon-in icon-mail-in"></span>ajin@logicalsteps.net
                                    </li>
                                </ul>
                            </div>
                            <div className="user-task-indicator">
                                <div className="task-indic-box task-indic-box--in-progress">
                                    <p className="task-count-text"><span className="task-count-in">1</span>Tasks</p>
                                    <p className="indic-box-text">In Progress</p>
                                </div>
                                <div className="task-indic-box task-indic-box--pending">
                                    <p className="task-count-text"><span className="task-count-in">0</span>Tasks</p>
                                    <p className="indic-box-text">Pending</p>
                                </div>
                                <div className="task-indic-box task-indic-box--completed">
                                    <p className="task-count-text"><span className="task-count-in">6</span>Tasks</p>
                                    <p className="indic-box-text">Completed</p>
                                </div>
                            </div>
                            <div className="description-options">
                                <span className="icon-in icon--optionsbullet description-options-icon"></span>
                            </div>
                            <div className="description-options-box fly-in">
                                <ul className="options-list">
                                    <li className="options-list-items">
                                        <span className="icon-in icon-edit"></span>
                                        <span className="option-text-in">Edit</span>
                                    </li>
                                    <li className="options-list-items">
                                        <span className="icon-in icon-delete"></span>
                                        <span className="option-text-in">Delete</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="tab-holder">
                        <ul className="tab-list">
                            <li className="tab-list-items">
                                <span className="icon-in icon-statics"></span>
                                Statistics
                            </li>
                            <li className="tab-list-items active-in">
                                <span className="icon-in icon-recent"></span>
                                Recent Activities
                            </li>
                            <li className="tab-list-items">
                                <span className="icon-in icon-permissions"></span>
                                Permissions
                            </li>
                        </ul>

                        <div className="tab-in-content">
                            <div className="tab-pane" style={{display:'none'}}>
                                <div className="tab-wrapper">
                                    <div className="user-button-block">
                                        <button className="filter-button">Daily</button>
                                        <button className="filter-button">Monthly</button>
                                        <button className="filter-button">Yearly</button>
                                    </div>
                                    <div className="graph-in-block"></div>
                                </div>
                            </div>

                            <div className="tab-pane" style={{display:'none'}}>
                                <div className="tab-wrapper">
                                    <div className="project-summary-block">
                                        <div className="tab-head-in">
                                            <h5 className="tab-head-text">Activity Logs</h5>
                                        </div>

                                        <div className="project-status">
                                            <div className="project-status-block status-finish">
                                                <h5 className="status-text">Marked as Done</h5>
                                                <p className="highlight-in">
                                                    <label className="date-time-label">
                                                        <span className="date-in">29 May 2019</span>|
                                                        <span className="time-in">05:17 pm</span>
                                                    </label>
                                                </p>
                                            </div>

                                            <div className="project-status-block status-finish">
                                                <h5 className="status-text">commented on Marked as Done</h5>
                                                <p className="highlight-in">
                                                    Converted to scss for more easy development
                                                    <label className="date-time-label">
                                                        <span className="date-in">29 May 2019</span>|
                                                        <span className="time-in">05:17 pm</span>
                                                    </label>
                                                </p>
                                            </div>

                                            <div className="project-status-block status-message">
                                                <h5 className="status-text">commented on Todo</h5>
                                                <p className="highlight-in">
                                                    So kindly make it as a new request
                                                    <label className="date-time-label">
                                                        <span className="date-in">29 May 2019</span>|
                                                        <span className="time-in">05:17 pm</span>
                                                    </label>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane">
                                <div className="tab-wrapper">
                                    <div className="scrollable-in">
                                        <table className="permission-table table table-scrollable-in">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className="has-checkbox">
                                                            <label className="checkbox-in">
                                                                <input type="checkbox" className="cs-checkbox" value="all-permission" id="all-select" />
                                                                <span className="cs-checkbox-pl"></span>
                                                            </label>
                                                            <label className="checkbox-text checkbox-text-b" for="all-select">User permissions</label>
                                                        </div>
                                                    </th>
                                                    <th>Add</th>
                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                    <th>Download</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="has-checkbox">
                                                            <label className="checkbox-in">
                                                                <input type="checkbox" className="cs-checkbox" value="all-permission" id="dashboard" />
                                                                <span className="cs-checkbox-pl"></span>
                                                            </label>
                                                            <label className="checkbox-text" for="dashboard">Dashboard</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <label className="checkbox-in">
                                                            <input type="checkbox" className="cs-checkbox" value="all-permission" id="dashboard-add" />
                                                            <span className="cs-checkbox-pl"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="checkbox-in">
                                                            <input type="checkbox" className="cs-checkbox" value="all-permission" id="dashboard-edit" />
                                                            <span className="cs-checkbox-pl"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="checkbox-in">
                                                            <input type="checkbox" className="cs-checkbox" value="all-permission" id="dashboard-delete" />
                                                            <span className="cs-checkbox-pl"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="checkbox-in">
                                                            <input type="checkbox" className="cs-checkbox" value="all-permission" id="dashboard-download" />
                                                            <span className="cs-checkbox-pl"></span>
                                                        </label>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div className="has-checkbox">
                                                            <label className="checkbox-in">
                                                                <input type="checkbox" className="cs-checkbox" value="all-permission" id="task" />
                                                                <span className="cs-checkbox-pl"></span>
                                                            </label>
                                                            <label className="checkbox-text" for="task">Task</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <label className="checkbox-in">
                                                            <input type="checkbox" className="cs-checkbox" value="all-permission" id="task-add" />
                                                            <span className="cs-checkbox-pl"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="checkbox-in">
                                                            <input type="checkbox" className="cs-checkbox" value="all-permission" id="task-edit" />
                                                            <span className="cs-checkbox-pl"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="checkbox-in">
                                                            <input type="checkbox" className="cs-checkbox" value="all-permission" id="task-delete" />
                                                            <span className="cs-checkbox-pl"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label className="checkbox-in">
                                                            <input type="checkbox" className="cs-checkbox" value="all-permission" id="task-download" />
                                                            <span className="cs-checkbox-pl"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="scrollable-in">
                                        <table className="permission-table table table-scrollable-in">
                                            <thead>
                                                <tr>
                                                    <th>Role</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="has-checkbox">
                                                            <label className="checkbox-in">
                                                                <input type="checkbox" className="cs-checkbox" value="all-permission" id="relief-agent" />
                                                                <span className="cs-checkbox-pl"></span>
                                                            </label>
                                                            <label className="checkbox-text" for="relief-agent">Relief<br/> Agent</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <button className="button submit-button">Submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


        );
    }
}



const mapStateToProps = state => ({

});
export default  withRouter(connect(mapStateToProps, {

})(UserDetailsHtml));


