import React, {Component} from 'react';

class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab : "New"
        }
        this.gettab = this.gettab.bind(this);

    }
    gettab(tab){
        this.setState({tab : tab})
    }
    render() {

        return(

                    <div className="user-st-block">
                        <a href="javascript:void(0)" className={this.state.tab === "New" ? "ind-widget-status active" : "ind-widget-status"} onClick={(e)=>(this.props.gettaskList('New'),this.gettab("New"))} >
                            <h5>New Tasks <span className="widget-cnt">{this.props.topbardata.newtask}</span></h5>
                        </a>
                        <a href="javascript:void(0)" className={this.state.tab === "InProgress" ? "ind-widget-status active" : "ind-widget-status"} onClick={(e)=>(this.props.gettaskList('InProgress'),this.gettab("InProgress"))}>
                            <h5 className="inp-shade">In Progress <span className="widget-cnt">{this.props.topbardata.inprogress}</span></h5>
                        </a>
                        {(this.props.menuClickData === '/task/todo' || this.props.menuClickData === '/task/to-follow-up' || this.props.menuClickData === '/task/to-comment' || this.props.menuClickData === '/task/to-know' || this.props.menuClickData === '/task/to-oversee') &&
                            <>
                                <a href="javascript:void(0)" className={this.state.tab === "Done" ? "ind-widget-status active" : "ind-widget-status"} onClick={(e)=>(this.props.gettaskList('Done'),this.gettab("Done"))}>
                                    <h5 className="comp-shade">Completed <span className="widget-cnt">{this.props.topbardata.completed}</span></h5>
                                </a>
                                <a href="javascript:void(0)" className={this.state.tab === "Verify" ? "ind-widget-status active" : "ind-widget-status"} onClick={(e) => (this.props.gettaskList('Verify'),this.gettab("Verify"))}>
                                    <h5 className="verf-shade">Verified <span className="widget-cnt">{this.props.topbardata.verified}</span></h5>
                                </a>
                                <a href="javascript:void(0)" className={this.state.tab === "Pending" ? "ind-widget-status active" : "ind-widget-status"} onClick={(e)=>(this.props.gettaskList('Pending'),this.gettab("Pending"))}>
                                    <h5 className="od-shade">Overdue <span className="widget-cnt">{this.props.topbardata.overdues}</span></h5>
                                </a>
                            </>
                        }
                    </div>
    );
}
}

export default  Topbar;
