import React, {Component} from "react";
import {FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH} from "../../../constants";
import {
    isImage,
    forceDownload,
    getFilePath,
    previewModalFormat,
    getFileExtension,
    isVideo,
    isAudio,
    formatBytes,
    isDocument
} from "../../../utilities/CustomFunctions";
class ResourceAttachments extends Component {

    constructor(props) {
        super(props);
        this.getAttachmentPath = this.getAttachmentPath.bind(this);
    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        if(type){
            switch(getFilePath(type)) {
                case 'image' :  filePath = FRMSIMG_PATH;
                    break;
                case 'video' : filePath = FRMSVID_PATH;
                    break;
                default : filePath = FRMSDOC_PATH;
                    break;
            }
        }


        return filePath;
    }
    render() {
        let index = this.props.index;
        let item = this.props.item;
        let iconClass = getFileExtension(item.thumb);
        iconClass = iconClass.length > 4 ? "YTUBE" : iconClass;
        let contentPath = this.getAttachmentPath(item.thumb);
        var albumName;
        if (isImage(item.thumb)) {
            albumName = 'images/'
        } else if (isVideo(item.thumb)) {
            albumName = 'videos/'
        } else if (isAudio(item.thumb)) {
            albumName = 'audios/'
        } else if (isDocument(item.thumb)) {
            albumName = 'documents/'
        } else {
            albumName = 'youtube/'
        }
        return (
            <div className="file-each image-type">
                {!isImage(item.thumb)&&
                <div className={"format " + iconClass}>
                    {iconClass}
                </div>
                }
                {isImage(item.thumb) &&
                <img src={contentPath+ item.thumb} />
                }
                <div className= {`file-each-opt single-opt`}>

                    <button className="opt-file-each preview" title={item.title} onClick={(e)=>this.props.preview(item.resourceId,item.thumb,item.title,item.res_type,item.ImgPath,item.creator)}></button>
                    
                    {/*{previewModalFormat(item) &&*/}
                    {/*<button className="opt-file-each preview" title={contentPath} onClick= {(previewModalFormat(item)) ? ((e) => this.props.preview(item, iconClass)) : ((e) => forceDownload(contentPath + item, contentPath))}></button>*/}
                    {/*}*/}
                    {/*<button className="opt-file-each download" onClick={(e)=>forceDownload(item, iconClass)}></button>*/}
                </div>
            </div>
        );
    }
}export default (ResourceAttachments)