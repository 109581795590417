import React, {Component} from 'react';
import SimpleModal from "../views/SimpleModal";
import {EditProfile} from "../pages/FormsData";
import {Button, ButtonToolbar,Accordion,Card} from "react-bootstrap";
import {getProfile} from "../../utilities/AuthService";
import {USRIMG_PATH } from '../../constants/index';
class LogoutPanel extends Component {

    constructor(props) {
        super(props);
        this.state={
            modalStatus:false,
            desc:''
        }
this.modalChange=this.modalChange.bind(this)
      }



    componentDidMount() {

    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    groupEdit(desc,groupid,creator){

        this.setState({desc:desc,modalStatus:true,groupid:groupid,creator:creator})

    }

    modalChange(){
        this.setState({modalStatus:!this.state.modalStatus})
    }
    editprofile=()=>{
        this.setState({modalStatus:!this.state.modalStatus})
    }
    modalChange(){
        this.setState({modalStatus:!this.state.modalStatus})
    }

    render() {
//console.log(this.props)
         return (

               <>


                       <div className="float-panel-header">
                           <h5 className="subheader">User Profile</h5>
                           <button className="ico-lib fly-close"  onClick={()=>this.props.sidePanel(false,'')}> </button>
                       </div>
                       <div className="float-panel-content-holder auto-stretch">
                           <div className="user-profile-info">
                               <div className="user-profile-image">
                                   <img
                                   src={USRIMG_PATH+getProfile().image}
                                   alt="User"/>
                               </div>
                               <div className="user-profile-details">
                                   <h4 className="subheader">{getProfile().first_name} {getProfile().last_name}</h4>
                                   <ul className="user-profile-det-list">
                                       {getProfile().email &&<li className="ico-lib mail-ico">{getProfile().email}</li>}
                                       {getProfile().phone && <li className="ico-lib phone-ico">{getProfile().phone}</li>}
                                   </ul>
                                   <div className="profile-other-opt">
                                       <div className="profile-other-left">
                                           {/*<button className="trans-button ico-lib pro-edit-ico"*/}
                                                   {/*onClick={()=>this.editprofile(this.state.modalStatus,'editProfile')}>Edit Profile*/}
                                           {/*</button>*/}

                                       </div>
                                       <div className="profile-other-right">
                                           <button className="trans-button ico-lib logout-ico log-out-btn"
                                                   data-id="2" onClick={this.props.logOut}>Logout
                                           </button>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   {this.state.modalStatus === true &&
                   <SimpleModal
                       Close={this.modalChange}
                   >

                       <EditProfile

                           Close={this.modalChange}
                       />
                   </SimpleModal>
                   }


               </>
        );
    }
}




export default (LogoutPanel);


