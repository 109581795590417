import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    BarChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Area,
    Legend,
    ResponsiveContainer,
    LabelList, AreaChart
} from 'recharts';

var graphdata = [];

const generateLabelData = (data) => {
    var labelData = [];
    for (var i = 0; i < graphdata.length; i++) {
        labelData[i] = [];
        let keys = Object.keys(graphdata[i]);
    }

}

const renderLabel = (props, data) => {
    //console.log(props)
    //console.log(data)

    const {
        x, y, width, height, value,
    } = props;
    //console.log(value)
    const radius = 10;

    return (
        <g>
            <text x={x + width / 2} y={y + height / 2} fill="#000" textAnchor="middle">
                {value}
            </text>
        </g>
    );
};
const CustomTooltip = ({active, payload, label, unit}) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                {payload && payload.map((item, index) =>
                    <p className="intro" key={`tool-${index}`}
                       style={{color: item.color}}>{`${item.name} : ${new Intl.NumberFormat('en-IN', {maximumFractionDigits: 2}).format(item.value)} ${unit ? unit : ''}`}</p>
                )}
            </div>
        );
    }

    return null;
};

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const CustomBar = (props) => {
    const {
        fill, x, y, width, height,
    } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
};

CustomBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};


function StackedBarChart(props) {
    graphdata = props.containerData.data;
    let labeldata = generateLabelData(graphdata)
    let stacked = props.containerData.stacked;
    let layout = props.containerData.layout ? props.containerData.layout : 'horizontal';
    let xData = props.containerData.tabledata.slice(0, 1);
    let barData = props.containerData.tabledata.slice(1);
    let xwidth = props.containerData.xwidth && parseInt(props.containerData.xwidth);
    let xheight = props.containerData.xheight && parseInt(props.containerData.xheight);
    let yheight = props.containerData.yheight && parseInt(props.containerData.yheight);
    let ywidth = props.containerData.ywidth && parseInt(props.containerData.ywidth);
    let graphHeight = props.containerData.graphHeight ? parseInt(props.containerData.graphHeight) : 200;
    let xAngle = props.containerData.xAngle ? parseInt(props.containerData.xAngle) : 0;
    let yAngle = props.containerData.yAngle ? parseInt(props.containerData.yAngle) : 0;
    let borderColor = props.containerData.bordercolor && props.containerData.bordercolor;


    return (
        <React.Fragment>
            <ResponsiveContainer width={props.containerData.legend ? "95%" : "100%"} height={graphHeight}>
                <BarChart data={props.containerData.data} layout={layout}>
                    <CartesianGrid vertical={false} stroke="#f0f0f0"/>
                    {layout === 'horizontal' &&
                    <YAxis type={'number'} axisLine={false} tickLine={false}/>
                    }
                    {layout === 'horizontal' && xData.map((list, index) =>
                        <XAxis key={`horiz-${index}`} type={'category'} dataKey={list.label}
                               padding={{left: 0, right: 0}} width={xwidth} height={xheight} angle={-xAngle}
                               fontSize={12} textAnchor={xAngle > 0 ? 'end' : 'middle'}
                               interval={props.containerData.alldata ? 0 : 'preserveStartEnd'}
                        />
                    )
                    }
                    {layout === 'vertical' &&
                    <XAxis type={'number'} axisLine={false} tickLine={false}
                           textAnchor={xAngle > 0 ? 'end' : 'middle'}/>
                    }
                    {layout === 'vertical' && xData.map((list, index) =>
                        <YAxis key={`vert-${index}`} type={'category'}
                               hide={props.containerData.enableyaxis ? false : true} dataKey={list.label}
                               padding={{left: 0, right: 0}} width={ywidth} height={yheight} axisLine={false}
                               tickLine={false} angle={-yAngle} fontSize={12} textAnchor={'end'}
                               interval={props.containerData.alldata ? 0 : 'preserveStartEnd'}
                        />
                    )}

                    <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip unit={props.containerData.unit}/>}/>
                    {props.containerData.legend &&
                    <Legend iconType={`circle`} iconSize={8} height={36} layout='vertical' verticalAlign={`middle`}
                            align={`right`}/>
                    }
                    {stacked && barData.map((list, index) =>
                        <Bar key={`barl-${index}`} dataKey={list.label} stackId="a" fill={list.color}
                             stroke={borderColor}>
                        </Bar>
                    )}

                    {!stacked && barData.map((list, index) =>
                        <Bar key={`barl-${index}`} name={list.label} dataKey={list.label} fill={list.color}
                             stroke={borderColor}>
                        </Bar>
                    )}
                </BarChart>
            </ResponsiveContainer>
            {/*<ResponsiveContainer width="100%" height={layout === 'vertical' ? 500 : 200}>
                <BarChart data={props.data} layout={layout}>
                    <CartesianGrid vertical={false}/>

                    {layout === 'horizontal' &&
                    <React.Fragment>
                        {xData.map((list, index) =>
                            <XAxis dataKey={list.label} padding={{left: 0, right: 0}} height={50} fontSize={12} textAnchor={'start'}
                                   interval={props.alldata ? 0 : 'preserveStartEnd'}/>
                        )}
                        <YAxis hide={props.enableyaxis ? false : true} axisLine={false}
                               tickLine={false}/>

                    </React.Fragment>
                    }

                    {layout === 'vertical' &&
                    <React.Fragment>
                            <XAxis type={'number'} axisLine={false} tickLine={false}
                                   interval={props.alldata ? 0 : 'preserveStartEnd'}/>

                        {xData.map((list, index) =>
                            <YAxis type={'category'} hide={props.enableyaxis ? false : true} dataKey={list.label}
                                   padding={{left: 0, right: 0}} height={50} axisLine={false}
                                   tickLine={false} angle={-28} fontSize={12} textAnchor={'start'}
                            />
                        )}

                    </React.Fragment>
                    }
                    <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip unit={props.unit}/>}/>
                    {props.legend &&
                    <Legend iconType={`circle`} iconSize={8} height={36}/>
                    }
                    {stacked && layout === 'horizontal' && barData.map((list, index) =>
                        <Bar name={list.label} dataKey={list.label} stackId="a" fill={list.color}>
                            <LabelList dataKey={list.label} position="center"
                                       style={{textAnchor: 'middle', fontSize: '80%', fill: '#fff'}}/>
                        </Bar>
                    )}
                    {stacked && layout === 'vertical' && barData.map((list, index) =>
                        <Bar name={list.label} dataKey={list.label} stackId="a" fill={list.color}>
                        </Bar>
                    )}

                    {!stacked && barData.map((list, index) =>
                        <Bar name={list.label} dataKey={list.label} fill={list.color}>
                        </Bar>
                    )}

                </BarChart>


            </ResponsiveContainer>*/}

        </React.Fragment>
    );
}

export default StackedBarChart;