import React, {Component, useState} from 'react';
import {USRIMG_PATH} from "../../../constants";
import {DateFormatter} from "../../../utilities/CustomFunctions";
import moment from "moment";
import {Scrollbars} from "react-custom-scrollbars";
import {getProfile} from "../../../utilities/AuthService";
import LoaderSvg from "../../views/LoaderSVG";
import {
	 RadialBarChart,
    RadialBar,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar,
    Area,	
	BarChart, 
	Cell, 
	Legend,
	Label,
	LabelList, 
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Line,
    ResponsiveContainer,
} from 'recharts';
import OutsideAlerter from "../../views/OutsideAlerter";
class InfoBlockUpper extends Component {
    render(){
        let usersdetail = this.props.usersdetail||'';
        let delPermission = this.props.delPermission;
        let editPermission = this.props.editPermission;
	
        return(
            <React.Fragment>
                <div className="user-detail-card-top">
                    <button className="previous-stage" onClick={(e)=>this.props.goBack(e)}>{usersdetail.firstName} {usersdetail.lastName}</button>
                    <ul className="communication-panel">
					{this.props.chatPermission &&
					<React.Fragment>
                        <li>
                            <nav className="ico-lib user-text-chat"  onClick={(e)=> this.props.goToChat(e,usersdetail.userid,usersdetail.firstName,usersdetail.lastName,usersdetail.image)}>Text Chat</nav>
                        </li>
                     {/*   <li>
                            <nav className="ico-lib user-voice-call"  onClick= {(e)=>this.props.voiceCall(e,usersdetail.firstName,usersdetail.email)}>Voice Call</nav>
                        </li>
                        <li>
                            <nav className="ico-lib user-video-call" onClick= {(e)=>this.props.vedioCall(e,usersdetail.firstName,usersdetail.email)}>Video Call</nav>
                        </li>*/}
						</React.Fragment>
						}
						
                        <li>
                            <nav className="ico-lib user-assign-task" onClick= {(e)=>this.props.addNewTask(e,usersdetail.userid)}>Assign Task</nav>
                        </li>
                    </ul>
                    { (getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === "root" ) &&
                    <React.Fragment>
                        <div className="description-options" onClick={this.props.showDrop}>
                            <span className="icon-in icon--optionsbullet description-options-icon"></span>
                        </div>
                    </React.Fragment>
                    }   
					{ (getProfile().usertype === "User" && (delPermission === true || editPermission === true)) &&
                    <React.Fragment>
                        <div className="description-options" onClick={this.props.showDrop}>
                            <span className="icon-in icon--optionsbullet description-options-icon"></span>
                        </div>
                    </React.Fragment>
                    }
                    {this.props.drop &&
                    <div className="description-options-box fly-in">
                        <ul className="options-list">
						{editPermission === true &&
                            <li className="options-list-items" onClick={(e)=>this.props.showFormModal(e,usersdetail.userid)}>
                                <span className="icon-in icon-edit"></span>
                                <span className="option-text-in">Edit</span>
                            </li>
						}
						{delPermission === true &&
                            <li className="options-list-items" onClick={this.props.showDeleteConfirm}>
                                <span className="icon-in icon-delete"></span>
                                <span className="option-text-in">Delete</span>
                            </li>
					}
                        </ul>
                    </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}
class InfoBlockDetails extends Component {
    render(){
        let usersdetail = this.props.usersdetail||{};
		
        return(
            <div className="user-detail-card-center">
                <div className="user-detail-card-center-left">
                    <img src={USRIMG_PATH+(usersdetail.image || 'person_avatar.gif')}  />
                </div>
                <div className="user-detail-card-center-right">
                    <ul className="user-detail-list">
                        {usersdetail.designation &&
                        <li className="user-detail-list-items"><span
                            className="icon-in icon-user-in"></span>{usersdetail.designation}</li>
                        }
                        {(usersdetail.companyname || usersdetail.office) &&
                        <li className="user-detail-list-items"><span
                            className="icon-in icon-map-in"></span>{usersdetail.companyname ? usersdetail.companyname : ''} {usersdetail.office.length == 0 ? '' : ','+usersdetail.office}
                        </li>
                        }
                        {usersdetail.email &&
                        <li className="user-detail-list-items"><span
                            className="icon-in icon-mail-in"></span>{usersdetail.email}</li>
                        }
                        {usersdetail.phone &&
                        <li className="user-detail-list-items"><span
                            className="icon-in phone-text"></span>{usersdetail.phone}</li>
                        }
                    </ul>
                </div>
            </div>
        );
    }
}
class StatisticsDetails extends Component {
    render(){
        let usersStaticsdata = this.props.usersStaticsdata||{};
		let userstatisticsclass ={'New' : 'chart-target-new','Done':'chart-target-completed','InProgress':'chart-target-progress','overdue':'chart-target-due'};
		//console.log(userstatisticsclass);
        return(
            <div className="chart-right">
                <ul className="chart-target">
				
				  {usersStaticsdata.map((entry, index) => 
				  <>
					  {Object.keys(entry) != 'total' && Object.keys(entry) != 'unread' &&
                             <li className={userstatisticsclass[Object.keys(entry)]}>
								<h5>{entry[Object.keys(entry)] == "" ? 0 : entry[Object.keys(entry)]}</h5>
								<p>{Object.keys(entry)}</p>
							</li> 
					  }
					</>
					)}
				

                    
                </ul>
            </div>
        );
    }
}
class PositionStatistics extends Component {
    render(){
		 let graphval=[];
        let status=this.props.UserTaskRate||{};

		graphval = [
                {name: 'Lowest', rate: status.minval  },
                {name: 'I\'m here', rate: status.am_here },
                {name: 'Average', rate: status.avarage},
                {name: 'Highest', rate: status.maxval }

            ];
            graphval.sort(function(a, b){
                return a.rate-b.rate
            });
		
        return(
		
           <ResponsiveContainer width="100%" height="100%" >
                                <BarChart data={graphval} margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                                    <XAxis tick={false}
                                           
                                           style={{stroke: '#E5EBF2', color: '#E5EBF2', height: '1px'}}/>
                                    {status.minval > 0 && status.avarage > 0 && status.maxval > 0 &&
                                    <Bar isAnimationActive={false} type="monotone" dataKey="rate" barSize={30}
                                         fill='#9fd4fa' position="inside" >
										   {graphval.map((entry, index) => (
                                            <Cell fill={entry.name === "I\'m here" ? '#0e9d58' : '#9fd4fa'} key = {index}/>
                                        ))}
                                        <LabelList dataKey="name" position="top" style={{fill: '#848484',fontSize : '12'}}/>
                                        <LabelList dataKey="rate" position="inside" style={{fill: '#ffffff'}}/>
                                    </Bar>
                                    }
                                </BarChart>
           </ResponsiveContainer>
		  
        );
    }
}

class TaskStatistics extends Component {
    render(){
		 let graphval=[];
        let taskstatus=this.props.containerData||{};
		let bardata = this.props.tabledata.slice(1)||{};
	 
		
        return(
		  <React.Fragment>
            <ResponsiveContainer width="100%" height={350}>
                <RadialBarChart
                    innerRadius="5%"
                    outerRadius="120%"
                    data={taskstatus}
                    startAngle={90}
                    endAngle={-200}
                    barGap={50}
                >
                    <RadialBar minAngle={15} label={{ fill: '#666', size:14, position: 'end'}} background={false} clockWise={true} dataKey={bardata[0].label} fill={bardata[0].color} />                   
                   
                </RadialBarChart>
            </ResponsiveContainer>
        </React.Fragment>
	
		  
        );
    }
}


class InfoBlock extends Component {
    render(){
        let usersStatisticsdetail = this.props.usersStatisticsdetail||{};
        return(
            <React.Fragment>
                <div className="user-detail-card-bottom">
                    <div className="user-statistics-each">
                        <p>Normal Task</p>
                        <h5>{usersStatisticsdetail.tasktask_count}</h5>
                    </div>
                    <div className="user-statistics-each">
                        <p>Workflow Task</p>
                        <h5>{usersStatisticsdetail.workflowtask_count}</h5>
                    </div>
                    <div className="user-statistics-each">
                        <p>Pool Task</p>
                        <h5>{usersStatisticsdetail.pooltask_count}</h5>
                    </div>
                    <div className="user-statistics-each">
                        <p>System Task</p>
                        <h5>{usersStatisticsdetail.systemtasktask_count}</h5>
                    </div>
                    <div className="user-statistics-each">
                        <p>Project</p>
                        <h5>{usersStatisticsdetail.project_count}</h5>
                    </div>
                    <div className="user-statistics-each">
                        <p>Collections</p>
                        <h5>{usersStatisticsdetail.collection_count}</h5>
                    </div>
                    <div className="user-statistics-each">
                        <p>Workflows</p>
                        <h5>{usersStatisticsdetail.workflow_count}</h5>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
class Navbar extends Component {
    render(){
        let userteam = this.props.userteam||[];
        let sideMenu = this.props.sideMenu ||'';
        return(
            <ul className="tab-list">
                {/*{sideMenu === 'overview' &&*/}
                {/*    <li className={this.props.tabState === "statistics" ? "tab-list-items active-in" : "tab-list-items"}>*/}
                {/*        <span className="icon-in icon-statics"></span>*/}
                {/*        Statistics*/}
                {/*    </li>*/}
                {/*}*/}
                {sideMenu === 'activities' &&
                <li className={this.props.tabState === "recentactivity" ? "tab-list-items active-in" : "tab-list-items"}>
                    <span className="icon-in icon-recent"></span>
                    Recent Activities
                </li>
                }
                {sideMenu === 'permissions' &&
                <li className={this.props.tabState === "permissions" ? "tab-list-items active-in" : "tab-list-items"}>
                    <span className="icon-in icon-permissions"></span>
                    Permissions
                </li>
                }
                {sideMenu === 'team' && userteam && userteam.length > 0 &&
                <li className={this.props.tabState === "team" ? "tab-list-items active-in" : "tab-list-items"}>
                    <span className="icon-in icon-teams"></span>
                    Team
                </li>
                }
                {sideMenu === 'relatedgroups' &&
                <li className={this.props.tabState === "relatedgroups" ? "tab-list-items active-in" : "tab-list-items"}>
                    <span className="icon-in icon-groups"></span>
                    Groups
                </li>
                }
            </ul>
        );
    }
}
class LogTab extends Component {
    render(){
        let usersactlog = this.props.usersactlog||[];
        return(
            <div className="tab-pane">
                <Scrollbars style={{"height": "100%"}}>
                    <div className="tab-wrapper no-overflow">
                        <div className="project-summary-block">
                            <div className="tab-head-in">
                                <h5 className="tab-head-text">Activity Logs</h5>
                            </div>
                            <div className="project-status">
                                {usersactlog.length === 0 &&
                                <div className="static-mode-block">
                                    <div className="empty-data">No Data Found</div>
                                    <div className=""></div>
                                </div>
                                }
                                {usersactlog.map((list, index) =>
                                    <div className="project-status-block status-finish" key={index}>
                                        <h5 className="status-text">{list.activity}</h5>
                                        <p className="highlight-in">
                                            <label className="date-time-label">
                                                {moment(list.crtdate).format("DD MMMM, YYYY")}   |   {DateFormatter(list.crtdate,true,false)}
                                            </label>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            </div>
        );
    }
}
class Team extends Component {
    render(){
        let userteam = this.props.userteam||[];
        return(
            <div className="tab-pane">
                <div className="tab-wrapper user-no-pad">
                    <div className="user-table-wrapper full-height-table wid-40">
                        <div className="user-table">
                            <div className="user-table-header">
                                <div className="user-th">Name</div>
                                <div className="user-th">Tasks</div>
                                <div className="user-th">New</div>
                                <div className="user-th">In progress</div>
                                <div className="user-th">Completed</div>
                            </div>
                            <Scrollbars style={{"height": "calc(100% - 40px)"}}>
                                <div className="user-table-body">
                                    {userteam.map((member, index) =>
                                        <div className="user-tr" key = {index}>
                                            <div className="user-td">
                                                <div className="members-details">
                                                    <img src={USRIMG_PATH + member.img_name} alt="user-image" className="member-image-in" />
                                                    <div className="members-info-in">
                                                        <h4 className="member-name">{member.name}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="user-td">{member.Total}</div>
                                            <div className="user-td">{member.New}</div>
                                            <div className="user-td">{member.InProgress}</div>
                                            <div className="user-td">{member.Done}</div>
                                        </div>
                                    )}
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class RelatedGroup extends Component {
    render(){

        let userrelatedgroups = this.props.userrelatedGroups||[];
        return(
            <div className="tab-pane">
                <div className="tab-wrapper user-no-pad">
                    <div className="user-table-wrapper full-height-table wid-33">
                        <div className="user-table">
                            <div className="user-table-header">
                                <div className="user-th">Group Name</div>
                                <div className="user-th">Creator</div>
                                <div className="user-th">Members Count</div>

                            </div>
                            <Scrollbars style={{"height": "calc(100% - 40px)"}}>
                                <div className="user-table-body">
                                    {userrelatedgroups.map((member, index) =>
                                        <div className="user-tr" key = {index}>
                                            <div className="user-td">{member.groupName}</div>
                                            <div className="user-td">{member.creator}</div>
                                            <div className="user-td">{member.assigneeCnt}</div>
                                        </div>
                                    )}
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class InvolvedProjects extends Component {
    render(){
        let usersStatisticsdetail = this.props.usersStatisticsdetail||{};
        let projects = usersStatisticsdetail.project||[];
        let projectPermission = this.props.projectPermission;
		
        return(
            <React.Fragment>
                {projects.length === 0 &&
                <div className="static-mode-block">
                    <div className="empty-data">No Data Found</div>
                    <div className=""></div>
                </div>
                }
                {projects.map((item,index)=>
                    <div className={`grid-body-table ${projectPermission?'cursor':""}`} onClick={(e) =>( projectPermission? this.props.history.push('/project/details/overview/' + btoa(item.projectid)):"")} key={index}>
                        <div className="grid-body-table-left full-list">
						    <span className={ item.projectStatus.split(' ').length>1 ? "New" : item.projectStatus}></span> <div className="tool-tip">{item.projectStatus}</div> {item.project_name}
                            
                        </div>
                       
                    </div>
                )}
            </React.Fragment>
        );
    }
}
class LogintimeTaskPerform extends Component {
    render(){
        return(
            <div className="each-grid grid-col-12">
                <div className="grid-text-header">
                    <h5>Login Time and Task Performance</h5>
                    {this.props.timests == 1 ?
                        <div className="right-panel-header">
                            <ul className="graph-note-panel">
                                <li className="graph-note done">done</li>
                                <li className="graph-note new">new</li>
                                <li className="graph-note inprogress">inprogress</li>
                                <li className="graph-note pending">pending</li>
                                <li className="graph-note timelog">timelog</li>
                            </ul>
                            <div className="filter-graph-button">
                                <button className={this.props.loginprfrmnc == "day" ? "active" : ""}
                                        onClick={(e) => {
                                            this.props.loginTaskPerformnc(e, 'day');
                                        }}>Daily
                                </button>
                                <button className={this.props.loginprfrmnc == "month" ? "active" : ""}
                                        onClick={(e) => {
                                            this.props.loginTaskPerformnc(e, 'month');
                                        }}>Monthly
                                </button>
                                <button className={this.props.loginprfrmnc == "year" ? "active" : ""}
                                        onClick={(e) => {
                                            this.props.loginTaskPerformnc(e, 'year');
                                        }}>Yearly
                                </button>
                            </div>
                        </div>
                        : ''}
                </div>
                <div className="grid-body">
				{this.props.loaderStatus.loaderType === 'user-statistics' && this.props.loaderStatus.status === true &&
				  <div className="statics-loader">
					<LoaderSvg/>
				</div>
				}
                    {this.props.timests == 1 ?
                        <ResponsiveContainer width="100%">
                            <ComposedChart width={1000} height={400} data={this.props.out}
                                           margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                                <CartesianGrid strokeDasharray="3 3 3 3"/>
                                <XAxis dataKey="item 1" tick={{fontSize: 12}}/>
                                <YAxis/>
								
                                <Tooltip/>
                                <Bar dataKey="done" stackId="a" fill="#04B287" barSize={30}/>
                                <Bar dataKey="new" stackId="a" fill="#3fa9f5" barSize={30}/>
                                <Bar dataKey="inprogress" stackId="a" fill="#F5A623" barSize={30}/>
                                <Bar dataKey="pending" stackId="a" fill="#D0021B" barSize={30}/>
                                <Line type="monotone" dataKey="timelog" stroke="#B6BBD3"/>
                            </ComposedChart>
                        </ResponsiveContainer>
                        :
                        <div className="static-mode-block">
                            <div className="empty-data">No Data Found</div>
                            <div className=""></div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
function UserActionButton(Props){

    const [status, showOptions] = useState(false);
    return(
        <div className="form-option-block">
            <button className="ico-lib opt-form-ico" onClick={() => showOptions(!status)}></button>
            {status &&
            <OutsideAlerter
                status={status}
                Triggered={() => showOptions(!status)}
            >

                <ul className="panel-option">
                    {/*<li className="options-list-items" onClick={(e) => Props.history.push('/settings/usersettings')}>*/}
                    {/*    <span className="icon-in icon-settings"></span>*/}
                    {/*    <span className="option-text-in">User Settings</span>*/}
                    {/*</li>*/}
                    <li className="options-list-items" onClick={(e) => Props.history.push('/team/rolesettings')}>
                        <span className="icon-in roles"></span>
                        <span className="option-text-in">Roles Settings</span>
                    </li>
                   <li className="options-list-items" onClick={(e) => Props.history.push('/team/rolemodulepermission')}>
                  <span className="icon-in permission"></span>
                    <span className="option-text-in">Role Module Permission</span>
                    </li>
                </ul>
            </OutsideAlerter>
            }
        </div>
    )


}
export {InfoBlock,Navbar,LogTab,Team,RelatedGroup,InvolvedProjects, InfoBlockUpper, InfoBlockDetails, StatisticsDetails, LogintimeTaskPerform, PositionStatistics,TaskStatistics,UserActionButton};
