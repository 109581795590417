import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal";
import AddNewSector from "./AddNewSector";
import * as portfolioActions from "../../../actions/Portfolio";
import * as usersActions from "../../../actions/Users";
import NoData from "../../views/NoData";
import moment from 'moment';
import SectorTile from "../../views/SectorTile";
import AlertModal from "../../views/AlertModal";
class Sector extends Component {
    constructor(props) {
        super(props);
        this.state={
            Addopen:false,
            portfolio_id : '0',
            loading: 0,
            offset: 30,
            lastOffset: 0,
            search:'',
            clearList : false,
            portfoliolist:[],
            Delopen:false,
            sectordeleteid:0

        }
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.getPortfolioList = this.getPortfolioList.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);
        this.DelmodalOpen = this.DelmodalOpen.bind(this);
        this.deleteSector = this.deleteSector.bind(this);
        this.cancelDeleteModal=this.cancelDeleteModal.bind(this);
    }
    componentDidMount() {
        this.getPortfolioList();
        var user_data = { uid : getProfile().id, usertype : getProfile().usertype , srchval : '' };
        this.props.users(user_data);
        this.props.mainClick('sector','');
        const container = document.querySelector('.project-container');
        container.addEventListener('scroll', this.listenToScroll)

    }
    componentWillUnmount() {
        const container = document.querySelector('.project-container');
        container.removeEventListener('scroll', this.listenToScroll)
    }
    listenToScroll = () => {
        const container = document.querySelector('.project-container')
        let lastOffset = document.querySelector('.ind-project-list').childElementCount;
        //console.log(lastOffset)
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;

        //console.log(scrollTop+"----"+scrollHeight+"----"+offsetHeight+"-----"+contentHeight);

        if (contentHeight <= scrollTop) {
            this.setState({loading : 1});
            let lastOffset = this.state.lastOffset + 1;
            this.setState({lastOffset : lastOffset,clearList:false},
                function(){
                    var params  = {
                        uid :  getProfile().id,
                        usertype : getProfile().usertype,
                        search: this.state.search,
                        page : this.state.lastOffset + 1,
                        per_page: this.state.offset
                    }
                    this.props.updatesectorlist(params);
                })

        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.listAllPortfolioData.portfolios !== this.props.listAllPortfolioData.portfolios)
        {
            this.setState({
                portfoliolist: this.props.listAllPortfolioData.portfolios.data,
                loading: 0
            });
            this.AddmodalClose();
        }
        if ((prevProps.listAllPortfolioData.searchValue !== this.props.listAllPortfolioData.searchValue && (this.props.listAllPortfolioData.searchValue!== '')) || (prevProps.listAllPortfolioData.searchResult !== this.props.listAllPortfolioData.searchResult )) {
            this.setState({
                portfoliolist: this.props.listAllPortfolioData.searchResult.data,
            });
        }
        else if ((prevProps.listAllPortfolioData.searchValue !== this.props.listAllPortfolioData.searchValue && ( this.props.listAllPortfolioData.searchValue === '') ) || (prevProps.listAllPortfolioData.portfolios !== this.props.listAllPortfolioData.portfolios)) {
            this.setState({
                portfoliolist: this.props.listAllPortfolioData.portfolios.data,
                loading: 0
            });

        }
        if(prevProps.listAllPortfolioData !== this.props.listAllPortfolioData){
            this.setState({
                portfoliolist: this.props.listAllPortfolioData.portfolios.data,
                loading: 0
            });
        }
        if(prevProps.deletePortfoliodata.portfolioDeleted !== this.props.deletePortfoliodata.portfolioDeleted){
            var beta = this;
            setTimeout(function () {
                beta.getPortfolioList();
            }, 1000)
            this.setState({Delopen:false})
        }
    }

    AddmodalOpen(e, pid){
        e.stopPropagation();
        if(pid){
            this.setState({portfolio_id : pid});
            var portfolioParams  = {
                uid :  getProfile().id,
                usertype : getProfile().usertype,
                portfolioId:pid
            }
            this.props.getSectorDetails(portfolioParams);
        }
        this.setState({Addopen : true});
    }

    AddmodalClose(){
        this.setState({Addopen : false , portfolio_id :'0'});
    }

    getPortfolioList() {
        var portfolioParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: this.state.search,
            page : this.state.lastOffset + 1,
            per_page: this.state.offset
        }
        this.props.portfolio(portfolioParams);
    }

    DelmodalOpen(e, sid){
        e.stopPropagation();
        this.setState({
            Delopen : true ,
            sectordeleteid : sid
        });
    }
    deleteSector(){
        this.setState({loading : 1})
        let project_id = this.state.sectordeleteid;

        let deletedddata = {projectid : project_id};
        this.props.deleteSector(deletedddata);

        //this.setState({Delopen : false});
    }

    cancelDeleteModal(){
        this.setState({Delopen : false});

    }

    render() {
        let Portfolio = this.state.portfoliolist;
        return (
            <div className="form-detail-container data-view field-form">
                {(this.props.listAllPortfolioData.loading || this.state.loading === 1)&&
                <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                }
                <div className="project-container">
                    {Portfolio.length === 0 &&
                    <div className='absolute-holder'>
                        <NoData
                            msg={`No Sectors added yet`}

                        />
                    </div>
                    }
                    {Portfolio.map((list,index) => {
                            let description = atob(list.description);
                            var endDate = moment(list.enddate).utc().local();
                            return(
                                <SectorTile
                                    key={list.project_id}
                                    list={list}
                                    history={this.props.history}
                                    endDate={endDate}
                                    AddmodalOpen={this.AddmodalOpen}
                                    DelmodalOpen={this.DelmodalOpen}
                                />
                            )
                        }
                    )}
                </div>
                <div className={'float-button-box'} onClick={(e)=>this.AddmodalOpen(e, 0)}>
                    <span className={'icon-in icon-plus'}></span>
                </div>
                {this.state.Addopen &&
                <Modal title={(this.state.portfolio_id == '0' ? 'Create New Sector' : 'Update Sector')} Close ={this.AddmodalClose}>
                    <AddNewSector
                        AddmodalClose = {this.AddmodalClose}
                        action={this.state.portfolio_id == "0"? "create":"update"}
                    />

                </Modal>
                }
                {this.state.Delopen === true &&
                <AlertModal id="delete-sector"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_action={() => this.deleteSector()}
                            showModal = {this.state.Delopen}
                            CANCEL_action={() => this.cancelDeleteModal()}
                />
                }
            </div>
        );
    }
}
const mapStateToProps = state => ({
    listAllPortfolioData:state.SectorReducer.listallPortfolio,
    SavePortfolioDet : state.SectorReducer.SavePortfolio,
    searchData:state.CommonReducer.searchparamdata,
    deletePortfoliodata :state.SectorReducer.DeletePortfolio
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    portfolio:portfolioActions.listallportfolio,
    users:usersActions.listUsers,
    getSectorDetails:portfolioActions.getportfoliodetails,
    updatesectorlist:portfolioActions.updatesectorlist,
    deleteSector:portfolioActions.deleteSector,

})(Sector));
