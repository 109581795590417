import axios from 'axios'
import {commonService} from "./common.services";
import {API_PATH,ES_INDEX} from '../constants/index'

export const programs = {
    listProgram,
    saveVertical,
    DeleteProgram
};


function listProgram(params){
    // params["index"]=ES_INDEX
    // params["t"]=Math.floor(Date.now() / 1000)
    // return axios.get(API_PATH+'sector/programs', {params});
    return commonService.sendHttpRequest('sector/programs', params, "GET");
}

function saveVertical(params){
    return commonService.sendHttpRequest('sector/createprogram', params, "POST");
}

function DeleteProgram(params) {
    return commonService.sendHttpRequest('sector/', params, "POST");
}
