import {resources} from '../services/Resources.services'

export const listResources = (params) => dispatch => {
    let listResources = resources.listResources(params);
    dispatch({
        type: 'RESOURCE_LOADER',
        loading:true,

    });
    listResources.then(listResources => {
        dispatch({
            type: 'LIST_All_RESOURCES',
            listresources: listResources,
            searchParam:params

        })
        dispatch({
            type: 'RESOURCE_LOADER',
            loading:false,

        });
    })
        .catch(error => {
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: 'RESOURCE_LOADER',
                loading:false,

            });

        });



};
export const listmoreResources = (params) => dispatch => {
    dispatch({
        type: 'RESOURCE_LOADER',
        loading:true,

    });
    let listmoreResources = resources.listResources(params);
    listmoreResources.then(listmoreResources => {
        dispatch({
            type: 'LIST_MORE_RESOURCES',
            listmoreResources: listmoreResources,
            searchParam:params

        })
        dispatch({
            type: 'RESOURCE_LOADER',
            loading:false,

        });
    })
        .catch(error => {
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: 'RESOURCE_LOADER',
                loading:false,

            });

        });



};
export const getResourcesInfo = (params) => dispatch => {
    let ResourcesInfo = resources.listResources(params);
    dispatch({
        type: 'RESOURCE_INFO_LOADER',
        loading:true,

    });
    ResourcesInfo.then(ResourcesInfo => {
        dispatch({
            type: "GET_RESOURCES_INFO",
            ResourcesInfo: ResourcesInfo,

        })
        dispatch({
            type: 'RESOURCE_INFO_LOADER',
            loading:false,

        });
    })
        .catch(error => {
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: 'RESOURCE_INFO_LOADER',
                loading:false,

            });

        });
};
export const DelResources = (params) => dispatch => {
    let DelResources = resources.DelResources(params);
    DelResources.then(DelResources => {
        dispatch({
            type: 'DELETE_RESOURCES',
            DelResources: DelResources,

        })
    })

};
export const saveGallery = (params) => dispatch => {
    let saveGallery = resources.saveGallery(params);
    saveGallery.then(saveGallery => {
        dispatch({
            type: 'SAVE_GALLERY',
            saveGallery: saveGallery,

        })
    })

};
export const listallresourcetags = (params) => dispatch => {
    let Resourcetaglist = resources.getResourceTags(params);
    dispatch({
        type: 'RESOURCE_TAG_LOADER',
        loading:true,

    });
    Resourcetaglist.then(Resourcetaglist => {
        dispatch({
            type: 'LIST_All_RESOURCE_TAGS',
            Resourcetaglist: Resourcetaglist.data.results.data
        });
        dispatch({
            type: 'RESOURCE_TAG_LOADER',
            loading:false,

        });
    })
        .catch(error => {
            dispatch({
                type: 'LIST_All_RESOURCE_TAGS',
                Resourcetaglist: []
            });
            dispatch({
                type: 'RESOURCE_TAG_LOADER',
                loading:false,

            });
        });

};

export const listTodoResources = (params) => dispatch => {
    let listResources = resources.listResources(params);
    dispatch({
        type: 'RESOURCE_LOADER',
        loading:true,

    });
    listResources.then(listResources => {
        let resources = listResources.data.results.data;

        if( resources) {
            dispatch({
                type: 'LIST_TODO_RESOURCES',
                resources: resources,
                searchParam : params.srchval
            });
        }
        dispatch({
            type: 'RESOURCE_LOADER',
            loading:false,

        });
    })
};
