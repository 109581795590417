import {combineReducers} from 'redux';

const listallProjects = (state = {ListAllProjects:{data:[], total :0, owncount :0, sharedcount : 0}, searchResult:{data:[], total :0, owncount :0, sharedcount : 0}, searchvalue : ''}, action) => {
    switch (action.type) {
        case "LIST_All_PROJECTS":
            return {
                ...state,
                ListAllProjects: {
                    data : [].concat.apply([],[action.clearList ? [] :state.ListAllProjects.data, action.listallprojects.data.results.data]),
                    // : action.listallprojects.data.results.data,
                    total : action.listallprojects.data.results.total,
                    owncount : action.listallprojects.data.results.owncount,
                    sharedcount : action.listallprojects.data.results.sharedcount,
                },
                searchvalue : action.searchvalue,
                page : action.page
            };
            break;

        case "PROJECTSLIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        /*case "SEARCH_PROJECT_DATA":
            return{
                ...state,
                searchResult: {
                    searchValue: action.value,
                },
                searchMode: true
            }*/
        case "SEARCH_PROJECT_DATA":
            return {
                ...state,
                searchResult: {
                    data : [].concat.apply([],[action.clearList ? [] :state.searchResult.data, action.projectList.data.results.data]),
                    total : action.projectList.data.results.total,
                    owncount : action.projectList.data.results.owncount,
                    sharedcount : action.projectList.data.results.sharedcount
                },
                searchValue: action.value
            };
            break;

        default:
            return state;
    }
};

const listallProjectsdetails = (state = {ListAllProjectsdetails:{data:[],results:[],file:[],log:[],projectinfo:[],projectusers : [],task:[]} ,logitems:[], projectDetails :{ projectinfo:[], projectusers : [], milestones:[], sections:[],task:[],file:[],log:[] }, loadmore:false}, action) => {

    var _newState;

    switch (action.type) {

        case "RESET_PROJECTSDETAILS":
            return {
                ...state,
                ListAllProjectsdetails:{data:[],results:[],file:[],log:[],projectinfo:[],projectusers : [],task:[]},loadmore:false,
                projectDetails :{ projectinfo:[], projectusers : [], milestones:[], sections:[],task:[],file:[],log:[] }
            };
            break;
        case "LIST_All_PROJECTSDETAILS":
            return {
                ...state,
                ListAllProjectsdetails: action.listallprojectsdetails ? action.listallprojectsdetails.data : state.ListAllProjectsdetails,
            };
            break;
        case "PROJECT_OVERVIEW":
            return {
                ...state,
                projectDetails : action.projectDetails ? action.projectDetails.data : state.projectDetails,
            };
            break;
         case "PROJECT_TASK":
            return {
                ...state,
                projectDetails : action.projectDetails ? action.projectDetails.data : state.projectDetails,
            };
            break;

        case "PROJECTSDETAILS_CONTENT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        case "PROJECT_OVERVIEW_CONTENT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        case "PROJECT_TASK_CONTENT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};

const milestonelist = (state = {ListAllMilestones:[]}, action) => {
    switch (action.type) {
        case "RESET_MILESTONESLIST_CONTENT":
            return {
                ...state,
                ListAllMilestones:[],
                results:[],
                data:[]
            };
            break;
        case "LIST_All_MILESTONES":
            return {
                ...state,
                ListAllMilestones:  action.milestonelistdata.data.results.data,
            };
            break;
        case "MILESTONESLIST_CONTENT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};

const SaveProject = (state = {projectSaved : false, newProject: {} ,projectDeleted:false}, action) => {
    switch (action.type) {
        case "SAVE_PROJECT":
            return {
                ...state,
                projectSaved: !state.projectSaved,
                newProject : action.newProject
            };
            break;
        case "DELETE_PROJECT":
            return {
                ...state,
                projectDeleted: !state.projectDeleted
            };
            break;
        default:
            return state;
    }
};

const ProjectInfo = (state = {ProjectInfo:{results:[],data:[] , projectinfo:[],projectusers:[],files:[],logs:[] }}, action) => {
    var _newState;
    switch (action.type) {
        case "RESET_PROJECTSDETAILS":
            return {
                ...state,
                ProjectInfo:{results:[],data:[] , projectinfo:[],projectusers:[],files:[],logs:[] }
            };
            break;
        case "PROJECT_INFO":
            return {
                ...state,
                ProjectInfo: action.projectInfo.data.results.data,
            };
            break;
        case "PROJECT_INFO_CONTENT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        case "LIST_ACTIVITYLOG":
            return {
                ...state,
                logitems: action.logitems ? action.logitems.logs : state.logitems,
            };
            break;
        case "LIST_MORE_ACTIVITYLOG":
            _newState = {...state};
            _newState['logitems'] = _newState.logitems.concat(action.logitems);
            return _newState;

            break;
        case "PROJECT_LOG_CONTENT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};

const SaveMilestone = (state = {milestonesaved:false,newMilestone:[]}, action) => {
    switch (action.type) {
        case "SAVE_MILESTONE":
            return {
                ...state,
                milestonesaved: !state.milestonesaved,
                newMilestone : action.newMilestone
            };
            break;
        default:
            return state;
    }
};

const GetMilestone = (state = {getmilestoneData:[],results:[],data:[]}, action) => {
    switch (action.type) {
        case "GET_MILESTONE_DETAILS":
            return {
                ...state,
                getmilestoneData: action.getmilestoneData.data.results.data,

            };
            break;
        default:
            return state;
    }
};


const GetProjectUsers = (state = {getprojectusers:[],results:[],data:[]}, action) => {
    switch (action.type) {
        case "GET_PROJECT_USERS":
            return {
                ...state,
                getprojectusers: action.getprojectusers.data.results.data.projectusers,

            };
            break;
        case "USERLIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};

const GetProjectDiscussions = (state = {getprojectdiscussions:[],results:[],data:[]}, action) => {
    ////console.log(action)
    switch (action.type) {
        case "GET_PROJECT_DISCUSSIONS":
            return {
                ...state,
                getprojectdiscussions: action.projectdiscussions.data.results.data,

            };
            break;
        case "PROJECT_DISCUSSIONS_CONTENT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};

const GetDiscussionsDetails = (state = {getdiscussionsdetails:[],results:[],data:[]}, action) => {
    ////console.log(action)
    switch (action.type) {
        case "GET_DISCUSSIONS_DETAILS":
            return {
                ...state,
                getdiscussionsdetails: action.discussionsdetails.data.results.data,

            };
            break;
        case "DISCUSSIONS_DETAILS_CONTENT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
const GetAllComments = (state = {getallcomments:[],results:[],data:[]}, action) => {
    ////console.log(action)
    switch (action.type) {
        case "GET_ALL_COMMENTS":
            return {
                ...state,
                getallcomments: action.allComments.data.results.data,

            };
            break;
        default:
            return state;
    }
};
const GetProjectSections = (state = {projectsections:[]}, action) => {
    switch (action.type) {
        case "GET_PROJECT_SECTIONS":
            return {
                ...state,
                projectsections: action.projectsections.data.results.data,

            };
            break;
        default:
            return state;
    }
};

const SaveProjectUser = (state = {projectuser_saved : []}, action) => {

    switch (action.type) {
        case "SAVE_PROJECT_USER":
            return {
                ...state,
                projectuser_saved: action.saveProjectUser
            };
            break;
        case "DELETE_PROJECT_USER":
            return {
                ...state,
                projectuser_saved: action.deleteProjectUser
            };
            break;
        default:
            return state;
    }
};

const SaveProjectDiscussion = (state = {project_discussion : false }, action) => {

    switch (action.type) {
        case "SAVE_PROJECT_DISCUSSION":
            return {
                ...state,
                project_discussion: !state.project_discussion
            };
            break;
        default:
            return state;
    }
};

const SaveProjectComments = (state = {project_comments : false }, action) => {

    switch (action.type) {
        case "SAVE_PROJECT_COMMENTS":
            return {
                ...state,
                project_comments: !state.project_comments
            };
            break;
        default:
            return state;
    }
};

const SaveAssignedUsers = (state = {assigned_users : false }, action) => {

    switch (action.type) {
        case "SAVE_ASSIGNED_USERS":
            return {
                ...state,
                assigned_users : !state.assigned_users
            };
            break;
        default:
            return state;
    }
};

const SaveSection = (state = {saveSection : [] }, action) => {
    switch (action.type) {
        case "SAVE_SECTION":
            return {
                ...state,
                saveSection: action.saveSection
            }
            break;
        default:
            return state;
    }
};
const UpdateTaskSection = (state = {updated : false }, action) => {

    switch (action.type) {
        case "UPDATE_TASK_SECTION":
            return {
                ...state,
                updated : !state.updated
            };
            break;
        default:
            return state;
    }
};
const AssignMilestone = (state = {assignMilestone : [], assigned : false }, action) => {

    switch (action.type) {
        case "ASSIGN_MILESTONE":
            return {
                ...state,
                assignMilestone : action.assignMilestone,
                assigned : !state.assigned
            };
            break;
        default:
            return state;
    }
};

const GetSection = (state = {sectiondata : [],data:[], results:[]}, action) => {

    switch (action.type) {
        case "GET_SECTION_DETAILS":
            return {
                ...state,
                sectionData : action.sectiondata.data.results.data
            };
            break;
        default:
            return state;
    }
};

const GetMilestoneSections = (state = {milestoneSections:[],results:[],data:[]}, action) => {
    ////console.log(action)
    switch (action.type) {
        case "PROJECT_MILESTONES":
            return {
                ...state,
                milestoneSections: action.milestoneSections.data.results.data,

            };
            break;
        case "PROJECT_MILESTONE_LIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
const GetOverviewdata = (state = {Overviewdata:[]}, action) => {
    switch (action.type) {
        case "PROJECT_OVERVIEW_DATA":
            return {
                ...state,
                Overviewdata: action.getOverviewdata.data.results.data

            };
            break;
        case "PROJECT_OVERVIEW_DATA_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
const SaveNewsAndMedia = (state = {newsmediasaved : false }, action) => {

    switch (action.type) {
        case "SAVE_NEWSMEDIA":
            return {
                ...state,
                newsmediasaved : !state.newsmediasaved,
            };
            break;
        default:
            return state;
    }
};
const GetNewsMediadata = (state = {newsmediadata:[]}, action) => {
    var _newState;
    switch (action.type) {
        case "NEWS_AND_MEDIA_DATA":
            return {
                ...state,
                newsmediadata: action.NewsMedia.data.results.data

            };
            break;
        case "NEWS_AND_MEDIA_DATA_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        case "UPDATE_NEWS_AND_MEDIA_DATA":
            _newState = {...state};
            _newState['newsmediadata'].content = _newState.newsmediadata.content.concat(action.moreNewsMedia.data.results.data.content?action.moreNewsMedia.data.results.data.content:[]);
            return _newState;
            break;
        default:
            return state;
    }
};
const Stakeholdermenudata = (state = {masterId:'',title:""}, action) => {

    switch (action.type) {

        case "STAKEHOLDER_MENU_UPDATE_SUCCESS":
            return {
                ...state,
                masterId: action.masterId,
                title: action.title,
            };
            break;
        default:
            return state;
    }
};
const GetNewsMediaInfo = (state = {newsmediainfo:[]}, action) => {
    switch (action.type) {
        case "NEWS_AND_MEDIA_INFO":
            return {
                ...state,
                newsmediainfo: action.NewsMediaInfo.data.results.data

            };
            break;
        case "NEWS_AND_MEDIA_INFO_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};
const DeleteMilestone = (state = {milestoneDeleted:false}, action) => {
    switch (action.type) {
        case "DELETE_MILESTONE":
            return {
                ...state,
                milestoneDeleted: !state.milestoneDeleted
            };
            break;
        default:
            return state;
    }
};
const GetCollectionInfoDet = (state = {collectionInfo:{}}, action) => {
    switch (action.type) {
        case "GET_COLLECTION_INFO":
            var info=[]
            var linkedentitys=[]
            var linkedinheritance=[]
            var uploadedFiles={}
            var results = action.collectionInfo.data.data
                if(results !== undefined) {
                    info = results;
                }
            if(results !== undefined) {
                uploadedFiles = {
                    originalfileName: results.filetitle,
                    fileName : results.filename,
                    fileSize : results.filesize,
                    fileType : results.filetype,
                }
            }
            if(results.linkedentityinfo.length > 0) {
                results.linkedentityinfo.map((entry, index) => {
                    if(entry.category === 'entity') {
                    linkedentitys[index] = {};
                    linkedentitys[index]['id'] = entry.collectionid;
                    linkedentitys[index]['name'] = entry.collectionname;
                    linkedentitys[index]['category'] = entry.category;
                    }
                    if(entry.category === 'collection') {
                        linkedentitys[index] = {};
                        linkedentitys[index]['_id'] = entry.collectionid;
                        linkedentitys[index]['title'] = entry.collectionname;
                        linkedentitys[index]['category'] = entry.category;
                    }
                });
            }
            if(results.inheritanceinfo.length > 0) {
                results.inheritanceinfo.map((entry, index) => {
                    if(entry.category === 'entity') {
                        linkedinheritance[index] = {};
                        linkedinheritance[index]['id'] = entry.collectionid;
                        linkedinheritance[index]['name'] = entry.collectionname;
                        linkedinheritance[index]['category'] = entry.category;
                    }
                    if(entry.category === 'collection') {
                        linkedinheritance[index] = {};
                        linkedinheritance[index]['_id'] = entry.collectionid;
                        linkedinheritance[index]['title'] = entry.collectionname;
                        linkedinheritance[index]['category'] = entry.category;
                    }
                });
            }
            return {
                ...state,
                collectionInfo: info,
                linkedentitys:linkedentitys,
                linkedinheritance:linkedinheritance,
                uploadedFiles:uploadedFiles
            };
            break;
        case "GET_COLLECTION_INFO_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
const DeleteSections = (state = {sectionDeleted:false}, action) => {
    switch (action.type) {
        case "DELETE_SECTION":
            return {
                ...state,
                sectionDeleted: !state.sectionDeleted
            };
            break;
        default:
            return state;
    }
};
const updatedproject = (state = {updatedInfo:''}, action) => {
    switch (action.type) {
        case "UPDATE_PROJECT_LOCATION":
            return {
                ...state,
                updatedInfo: action.projectUpdate.data.time

            };
            break;
        case "LOCATION_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
export const Projects = combineReducers({
    listallProjects,
    listallProjectsdetails,
    milestonelist,
    SaveProject,
    ProjectInfo,
    SaveMilestone,
    GetMilestone,
    GetProjectUsers,
    GetProjectDiscussions,
    GetDiscussionsDetails,
    GetAllComments,
    SaveProjectUser,
    SaveProjectDiscussion,
    SaveAssignedUsers,
    GetProjectSections,
    SaveSection,
    SaveProjectComments,
    UpdateTaskSection,
    AssignMilestone,
    GetSection,
    GetMilestoneSections,
    GetOverviewdata,
    SaveNewsAndMedia,
    GetNewsMediadata,
    Stakeholdermenudata,
    GetNewsMediaInfo,
    DeleteMilestone,
    GetCollectionInfoDet,
    DeleteSections,
    updatedproject
});

export default Projects;

