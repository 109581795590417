import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import LoaderSvg from "./LoaderSVG";

class DashboardListCard extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    render() {

        return (

                <div className="ibt-progress-table-body">
                    <div className="scrollable">
                        <table className="table ibt-table">
                                <tbody>
                                <tr>
                                    <td>Total Customers with Impact</td>
                                    <td align="right">900</td>
                                </tr>
                                <tr>
                                    <td>Region 1</td>
                                    <td align="right">400</td>
                                </tr>
                                <tr>
                                    <td>Region 2</td>
                                    <td align="right">200</td>
                                </tr>
                                <tr>
                                    <td>Region 3</td>
                                    <td align="right">125</td>
                                </tr>
                                <tr>
                                    <td>Region 4</td>
                                    <td align="right">40</td>
                                </tr>
                                </tbody>
                        </table>
                    </div>
                </div>

        );
    }


}

export default DashboardListCard;