import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../../actions/ToDo"
import {withRouter} from 'react-router';
import ListCard from "../../views/ListCard"
import NoData from "../../views/NoData"
import {getProfile} from "../../../utilities/AuthService";
import LoaderSvg from "../../views/LoaderSVG";
import FilterBlock from "../FilterBlock";
import FilerRightBlock from "../FilterRightBlock";
import * as projectActions from "../../../actions/Project";
import Modal from "../../views/Modal";
import AddToDoTask from "../AddToDoTask";
import rootActions from "../../../actions";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import AlertBar from "./AlertBar";



class ToKnow extends Component {

    constructor(props) {
        super(props);
        this.gettaskList=this.gettaskList.bind(this);
        this.modalOpen=this.modalOpen.bind(this);
        this.state={
            open:false,
            projectList:[],
            limit : 0,
            loadingState: false,
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            toknowdata: [],
            offset: 250
        }
      }



    componentDidMount() {
         this.props.mainClick('task','/task/to-know')

        if(this.props.todoListing.ToKnow.data.length ===0) {
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
            }
            else {
                this.gettaskList('to-know','All');
            }
        }
        else{
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
                this.setState({toknowdata: this.props.todoListing.ToKnow.data});
            }
            else {
                this.setState({toknowdata: this.props.todoListing.ToKnow.data});

            }
        }
        this.props.userFilter("to-know",getProfile().id)
        const container = document.querySelector('.main-panel-holder');
        container.addEventListener('scroll', this.listenToScroll)
    }


    searchFilterActive(){
        var status = "All"
        if(getUrlSegment(2)==='todo'){
            var category='to-me'
        }
        if(getUrlSegment(2)==='to-follow-up'){
            var category='by-me'
        }
        if(getUrlSegment(2)==='to-oversee'){
            var category='manage-task'
        }
        if(getUrlSegment(2)==='to-know'){
            var category='to-know'
        }
        if(getUrlSegment(2)==='to-comment'){
            var category='Comments'
        }
        if(getUrlSegment(2)==='to-verify'){
            var category='by-me'
            status = "Done"
        }

        var data = {
            category : category,
            status :this.props.searchData.task.status,
            uid : getProfile().id,
            bydue : '',
            priority :this.props.searchData.task.priority,
            project :this.props.searchData.task.project,
            asigntype : '',
            username :'',
            managetask : 'mytask',
            srchval:this.props.searchData.task.srchval,
            limit : 0,
            offset  : this.state.offset,
            systype : getProfile().usertype,
        }

        this.props.searchType('SEARCH_TASK_DATA',this.props.searchData.task.srchval,"srchval",data)

    }

    componentWillUnmount() {
        const container = document.querySelector('.main-panel-holder');
        container.removeEventListener('scroll', this.listenToScroll)
    }
    listenToScroll = () => {
        const container = document.querySelector('.main-panel-holder')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;
        if (contentHeight <= scrollTop) {
            if(this.state.lastOffset + 1 < this.state.totalitems) {
                this.setState({loading : 1})
                var data = {category : 'to-know', status : "All" , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', limit: this.state.lastOffset, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TO_KNOW_DATA' }
                this.props.toknow(data);
            }
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            if(this.props.HistoryPushData.savemode === 'by-me') {
                var data = {category : 'by-me', status : 'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', username : this.props.taskFilters.taskAssignedFilter,limit: 0, offset  : this.state.offset, systype :  getProfile().usertype,  actionType : 'LIST_FOLLOW_UP_DATA' }
             //   this.props.toFollowUp(data);
             //   this.props.history.push("/task/to-follow-up")
                this.setState({open:false})
            }
            if(this.props.HistoryPushData.savemode === 'to-me') {
                var data = {category : 'to-me', status :'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
           //     this.props.todo(data);
           //     this.props.history.push("/task/todo")
                this.setState({open:false})
            }
        }
        if(prevProps.taskFilters  !== this.props.taskFilters){
            this.gettaskList('to-know','All');
        }

        var beta = this;
        if(prevProps.taskSelect.tasksArchived  !== this.props.taskSelect.tasksArchived ){
            setTimeout(function () {
                beta.gettaskList('to-know','All');
            }, 1000)
        }
        if(prevProps.todoListing  !== this.props.todoListing){
            this.setState({toknowdata: this.props.todoListing.ToKnow.data});
        }

    }

    modalOpen(){
        ////////////////console.log(this.props.projects.projectData);//////////////console.log(this.props.projects.tagData);
        this.setState({open:!this.state.open,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData});
       // //////////////console.log(this.props.projects.tagData);
    }
    gettaskList(cat,status){
        var data = {category : cat, status : status , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit: 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TO_KNOW_DATA' }
        this.props.toknow(data);
    }
    selectTasks(id) {
        this.props.selectTasksToMove(id);
    }

    render() {
        let ToKnow=this.props.searchData.task.active? this.props.searchData.data:  this.state.toknowdata


       // let ToKnow=this.state.toknowdata.filter(item => (item.subject===''?atob(item.Title):atob(item.subject)).toLowerCase().search(this.props.searchData.task.toLowerCase()) >= 0)
        ////////////////console.log(ToKnow.length)
         return (



             <>
                 <div className='filter-all-wrapper task-home'>
                     {/*<AlertBar*/}
                     {/*    history={this.props.history}*/}

                     {/*/>*/}
                     <div className='filter-holder'>
                         {this.props.taskSelect.taskSelection === 'no' &&
                         <FilterBlock/>
                         }
                         {/*{this.props.taskSelect.taskSelection === 'no' &&*/}
                         {/*< Topbar gettaskList={this.gettaskList}  topbardata={this.props.topbardata} menuClickData={this.props.menuClickData.subMenu} />*/}
                         {/*}*/}
                         <FilerRightBlock
                             history={this.props.history}
                             taskCount ={this.state.toknowdata.length} />
                     </div>
                 </div>
                 <div className={this.props.todoListing.loading ? 'main-panel-holder with-loader has-filter' : 'main-panel-holder has-filter'}>
                     {this.props.todoListing.loading &&
                     <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                     }
                     {ToKnow.length === 0 &&
                         <div className='absolute-holder'>
                         <NoData

                             msg={`No task assigned to you`}

                         />
                         </div>
                     }

                     {ToKnow.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'unread').map((list,index) =>
                         <React.Fragment key={'know'+index}>
                             {index === 0 &&
                             <h4 className={'main-panel-head'}>Unread</h4>
                             }
                         <ListCard
                             key={'know-list-card'+index}
                             index={index}
                             sectionTitle={`Unread`}
                             list={list}
                             history={this.props.history}
                             mode='to-know'
                             taskSelection = {this.props.taskSelect.taskSelection}
                             selectTasks = {this.selectTasks.bind(this)}
                             allChecked = {this.props.taskSelect.allChecked}
                             id={list.masterid}
                             tasksource={list.createdfrom}
                             poolenabled={list.poolenabled}
                         />
                         </React.Fragment>
                     )}

                     {ToKnow.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'read').map((list,index) =>
                         <React.Fragment>
                             {index === 0 &&
                             <h4 className={'main-panel-head'}>Read</h4>
                             }
                         <ListCard
                             key={'know'+index}
                             index={index}
                             sectionTitle={`Read`}
                             list={list}
                             history={this.props.history}
                             mode='to-know'
                             taskSelection = {this.props.taskSelect.taskSelection}
                             selectTasks = {this.selectTasks.bind(this)}
                             allChecked = {this.props.taskSelect.allChecked}
                             id={list.masterid}
                             tasksource={list.createdfrom}
                             poolenabled={list.poolenabled}
                         />
                         </React.Fragment>
                     )}
                 </div>
                 {this.state.open &&
                 <Modal title={'Create New Task'} Close ={this.modalOpen.bind(this)} >
                     <AddToDoTask />

                 </Modal>
                 }

                 <div className={'float-button-box'} onClick={this.modalOpen}>
                     <span className={'icon-in icon-plus'}></span>
                 </div>
                 </>




         );
    }
}



const mapStateToProps = state => ({

    todoListing:state.TaskReducer.listtoDo,
    taskFilters:state.TaskReducer.filterTasks,
    taskSelect:state.TaskReducer.getTaskSelection,
    menuClickData:state.CommonReducer.menuClick,
    searchData:state.CommonReducer.searchparamdata,
    topbardata:state.TaskReducer.ToKnowTopbarMenudata,
    listtags:state.TaskReducer.ListAllTags,
    saveTodo : state.TaskReducer.saveTodo,
    projects:state.TaskReducer.GetProjectData,
    HistoryPushData:state.TaskReducer.HistoryPushData

});
export default  withRouter(connect(mapStateToProps, {
    todo:taskActions.listtask,
    toFollowUp:taskActions.listtask,
    toknow:taskActions.listtask,
    mainClick:rootActions.commonActions.MenuClick,
    selectTasksToMove : taskActions.selectTasksToMove,
    listTags:projectActions.listalltags,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    searchType: rootActions.commonActions.searchType,
    userFilter:taskActions.userFilter,

})(ToKnow));


