import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";
import {getProfile} from "../../../utilities/AuthService";
import {ES_INDEX} from "../../../constants";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import ListCollection from "../../views/ListCollection";
import Modal from "../../views/Modal";
import AddNewForm from "./AddNewForm";
import * as collectionActions from "../../../actions/Collections";
import * as usersActions from "../../../actions/Users";
import * as projectActions from "../../../actions/Project";


class ProjectCommunication extends Component {

    constructor(props) {
        super(props);

        this.state={
            expandHeader:false,
            Collections:[],
            loading:0,
            search: '',
            viewtype : 'card',
            collectiontype:'',
            lastOffset: 0,
            totalitems: 0,
            collectionId:0,
            createNewForm:false,
            showOption:false,
            offset: 50,

        }
        this.expandHeader = this.expandHeader.bind(this);
        this.getCollections=this.getCollections.bind(this);
        this.collectionView=this.collectionView.bind(this);
        this.showCreateNewFormModal=this.showCreateNewFormModal.bind(this);
        this.closeCreateNewFormModal = this.closeCreateNewFormModal.bind(this);
        this.showOptions =this.showOptions.bind(this);
    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }
    collectionView(e,statusType)
    {
        this.setState({viewtype: statusType});
    }
    componentDidMount() {
        var prjctdtlid = getUrlSegment(4);
        let project_id = atob(prjctdtlid);
        this.getCollections();

        this.props.users(getProfile().id,'User','',ES_INDEX);

        const container = document.querySelector('.ibt-body');
        container.addEventListener('scroll', this.listenToScroll)
    }
    componentWillUnmount() {
        const container = document.querySelector('.ibt-body');
        container.removeEventListener('scroll', this.listenToScroll)
    }
    listenToScroll = () => {
        const container = document.querySelector('.ibt-body')
        let lastOffset = document.querySelector('.team-wrapper').childElementCount;
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;
        var prjctdtlid = getUrlSegment(4);
        let project_id = atob(prjctdtlid);
        if (contentHeight <= scrollTop) {
            if(this.props.listAllCollections.Search === '') {
                let params = {group : 'community', search : this.state.search, uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : lastOffset, offset : this.state.offset, projectId : project_id,collectiontype:this.state.collectiontype};
                this.props.updatecollections(params);
            } else {
                let params = {group : 'community', search : this.props.listAllCollections.Search, uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : lastOffset, offset : this.state.offset, projectId : project_id,collectiontype:this.state.collectiontype};
                this.props.moreSearchCollections(params)
            }

        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.collectionSaveInfo.collectionSaved !== this.props.collectionSaveInfo.collectionSaved) {
            var beta = this;
            setTimeout(function () {
                beta.getCollections();
                beta.closeCreateNewFormModal();
                beta.setState({loading:0})
            }, 1000)
        }
    }

    getCollections(){
        var prjctdtlid = getUrlSegment(4);
        let project_id = atob(prjctdtlid);
        var collectionType = getUrlSegment(3);
        var type='';
        this.props.mainClick('project', '/project/details/community/' + prjctdtlid);
        this.setState({collectiontype:'default'})
        type='community';

        let params = {
            group : type,
            search : this.state.search,
            uid : getProfile().id,
            usertype : getProfile().usertype,
            managecollection : 0,
            limit : 0,
            offset : this.state.offset,
            projectId : project_id,
            collectiontype:''
        };
        this.props.collections(params);
    }
    showCreateNewFormModal(e,id){
        this.setState({createNewForm : !this.state.createNewForm,
            collectionId:id})
        if(id !== 0){
            var params= {
                "_id": id,
                "linkedcollectioninfo": "yes",
                "showinheritanceinfo": "yes",
                "showfieldinfo": "no"
            }

            this.props.getCollectionInfo(params)
        }

    }
    closeCreateNewFormModal(){
        this.setState({createNewForm : false,collectionId:0,})
    }
    showOptions(index){
        if(this.state.showOption === index){
            index = -1;
        }
        this.setState({showOption:index})
    }
    render() {
        let Collections = [];
        if(this.props.listAllCollections.Search === '') {
            Collections = this.props.listAllCollections.ListAllCollections.data;
        } else {
            Collections = this.props.listAllCollections.searchResult.data;
        }
        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>
                <div className="ibt-body">
                    <div className="pj-data-container">
                        <div className="pj-team-wrapper project-team-holder collection-pad-top">
                            {Collections.length > 0 &&
                            <div className="task-nav align-cent">
                                <div className="task-nav-left">
                                    <div className="filter-tag-block"></div>
                                </div>
                                <div className="task-nav-right">
                                    <div className="resources-tab-wrap">
                                        <ul className="resources-filters-icons">
                                            <li><a
                                                onClick={(e) => this.collectionView(e, 'card')}
                                                className={`rs-tab-btn ico-lib-v1 rc-tab-grid ${this.state.viewtype === 'card' ? "active" : ""}`}></a>
                                            </li>
                                            <li><a
                                                onClick={(e) => this.collectionView(e, 'tile')}
                                                className={`rs-tab-btn ico-lib-v1 rc-tab-list ${this.state.viewtype === 'tile' ? "active" : ""}`}></a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            }
                            {this.props.loader.loaderType === 'collection-list' && this.props.loader.status === true &&
                            <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                            }
                            {Collections.length === 0 &&
                            <div className='absolute-holder'>
                                <NoData
                                    msg={`No Collections added yet`}

                                />
                            </div>
                            }
                            <div className= {this.state.viewtype === 'card' ? "team-wrapper" : "team-wrapper status-view" } >
                                {Collections.map((list, index) =>
                                    <ListCollection
                                        list={list}
                                        key={"collection-"+index}
                                        history={this.props.history}
                                        optionOpened={this.state.optionOpened}
                                        viewtype={this.state.viewtype}
                                        manageOptionsDropDown={this.manageOptionsDropDown}
                                        updateAction={this.updateAction}
                                        section="projectcommunity"
                                        showCreateNewFormModal={this.showCreateNewFormModal}
                                        showOption={this.state.showOption}
                                        showOptions={this.showOptions}
                                        index={index}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                </div>
                {this.state.createNewForm &&
                <Modal title={this.state.collectionId === 0 ? 'Create New Form': 'Update New Form'} Close ={this.closeCreateNewFormModal.bind(this)}>
                    <AddNewForm />
                </Modal>

                }
            </div>



        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    loader:state.CommonReducer.loader,
    listUsers:state.UsersReducer.listAllUsersdata,
    collectionSaveInfo:state.CollectionsReducer.saveCollection
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    collections: collectionActions.listallcollections,
    users:usersActions.listAllUsers,
    getCollectionInfo:projectActions.getCollectionInfo,
    updatecollections: collectionActions.updatecollections,
    moreSearchCollections: collectionActions.updatesearchcollections,
})(ProjectCommunication));
