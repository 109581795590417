import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
class PositionDetail extends Component {

    constructor(props) {
        super(props);

    }
    render() {
        var list = this.props.dataList;
        var perc = ((list.task_total > 0) ? list.task_completed / list.task_total : 0) + '%';
        return(
            <tr>
                <td>
                    <div className="progress-wrapper">
                        <h5>{list.milestone_name}</h5>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar"
                                 style={{width:perc}} aria-valuenow="84" aria-valuemin="0"
                                 aria-valuemax="100"></div>
                        </div>
                        <p>{perc}</p>
                    </div>
                </td>
                <td>{moment(list.startdate).format('DD MMM YYYY')}</td>
                <td>{moment(list.enddate).format('DD MMM YYYY')}</td>
            </tr>
        );
    }
}


export default  withRouter(connect(null, {

})(PositionDetail));
