import React, {Component, useState, useRef} from 'react';
import {
    Input,
    TextArea,
    Dropdown,
    RadioButton,
    CheckBox,
    CheckboxWithImage,
    HTMLEditor,
    DigitalSignature,
    DataLoad,
    Toggle,
    Autofill,
    PhotoCapture,
    VideoCapture,
    DisplayText,
    Agree,
    LoggedIn,
    OTP,
    Rating,
    Payment,
    Resource,
    PhoneNumber,
    Preview,
    DisplayImage,
    DisplayVideo,
    DisplayLabel
} from "./FormFieldData";
import UploadFile from '../../../utilities/Upload/UploadFile';
import {
    isImage,
    isVideo,
    formatDate,
    forceDownload,
    getAttachmentPath,
    getFilePath, isAudio, getFileExtension, getInputSelection
} from "../../../utilities/CustomFunctions";
import AutoComplete from "../AutoComplete";
import DateTimePickerBox from "../DateTimePickerBox";
import TimePickerBox from "../TimePickerBox";
import moment from "moment";
import SimpleModal from "../SimpleModal";
import LinkResource from "./LinkResource";
import ResourcesFromBrowse from "./ResourcesFromBrowse";
import {ES_INDEX, FRMSDOC_PATH, FRMSIMG_PATH, FRMSPDFDOC_PATH, FRMSVID_PATH} from "../../../constants";
import VideoPlayer from "../player/VideoPlayer";
import {getProfile} from "../../../utilities/AuthService";
import {Overlay, OverlayTrigger, Tooltip} from "react-bootstrap";

const DropdownBox = ({hasContainer, attributes, ...props}) => {
    return (
        <DropdownWrapper
            condition={hasContainer}
            wrapper={children =>
                <div className={props.dropClassName}>
                    {children}
                </div>

            }
        >
            <React.Fragment>

                {((attributes.multipleValues && attributes.multipleValues === 'Yes' && (attributes.externallink && attributes.externallink === 'Yes') || (attributes.databinding && attributes.databinding === 'Yes'))) &&
                <>
                    {attributes.externaladdbutton && attributes.externaladdbutton === 'Yes' &&
                    <button className={'icon-in icon-plus'}
                            onClick={(e) => props.showAddExternalValuesModal(e, attributes.externalfield[0].collection, attributes)}></button>
                    }
                    <AutoComplete
                        eleClass='selectize-custom'
                        fieldTitle={attributes.fieldLabel}
                        placeholder={attributes.fieldLabel}
                        hideTitle={true}
                        sourceDataList={attributes.options}
                        searchField='value,displayvalue'
                        showIcon={false}
                        hideMainLabel={true}
                        showSubLabel={true}
                        labelFields='value'
                        valueField='value'
                        subLabelField='value'
                        showProfile={false}
                        reference={attributes.masterid}
                        selectedItems={attributes.selectedItems}
                        resultDataList={props.resultDataList}
                        addNewSuggestion={false}
                        instantSearch={true}
                        id={attributes.masterid}
                        actionMethod={props.actionMethod}
                        multiSelect={(attributes.multipleselection && attributes.multipleselection.toLowerCase() === 'yes') ? true : false}
                        readOnly={attributes.readOnly}
                    />
                </>
                }
                {(attributes.multipleselection && attributes.multipleselection.toLowerCase() === 'yes') &&
                (!attributes.externallink || attributes.externallink !== 'Yes') &&
                <>
                    <AutoComplete
                        eleClass='selectize-custom'
                        fieldTitle={attributes.fieldLabel}
                        placeholder={attributes.fieldLabel}
                        hideTitle={true}
                        sourceDataList={attributes.options}
                        searchField='value'
                        showIcon={false}
                        hideMainLabel={true}
                        showSubLabel={true}
                        labelFields='value'
                        valueField='value'
                        subLabelField='value'
                        showProfile={false}
                        reference={attributes.masterid}
                        selectedItems={attributes.selectedItems}
                        resultDataList={props.resultDataList}
                        addNewSuggestion={false}
                        instantSearch={false}
                        id={attributes.masterid}
                        actionMethod={props.actionMethod}
                        multiSelect={(attributes.multipleselection && attributes.multipleselection.toLowerCase() === 'yes') ? true : false}
                        readOnly={attributes.readOnly}
                    />
                </>
                }
                {((!attributes.multipleValues || attributes.multipleValues === 'No') && (!attributes.multipleselection || (attributes.multipleselection && attributes.multipleselection.toLowerCase() === 'no'))) &&
                <Dropdown attributes={attributes} setFieldValues={props.setFieldValues}
                          handleInputChange={props.handleInputChange}
                          addErrorClass={props.addErrorClass}
                          checkedValue={attributes.selectedVals}
                          onKeyUp={props.onKeyUp}
                          selectClass={props.selectClass}
                />
                }
            </React.Fragment>
        </DropdownWrapper>
    )
}

const DropdownWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;

const CheckboxContainer = ({isWithoutCover, attributes, ...props}) => {
    return (
        <CheckboxWrapper
            condition={isWithoutCover}
            wrapper={children =>
                (isWithoutCover) ? <div
                        className={`select-chk-block ${(attributes.optionstatus && attributes.optionstatus === 'imageoption') && 'option-with-img'}`}
                        key={`check-opt-${props.optionIndex}`}>
                        <label className="control control-checkbox">
                            {(!attributes.optionstatus || (attributes.optionstatus && attributes.optionstatus === 'textoption')) && props.option.value}
                            {children}
                            <div className="checkbox-indicator"></div>

                        </label>
                    </div> :
                    <div className="type-form-checkbox" key={`check-opt-${props.optionIndex}`}>
                        <label>{(!attributes.optionstatus || (attributes.optionstatus && attributes.optionstatus === 'textoption')) && props.option.value} </label>
                        {children}
                        <div
                            className="chk-indicator"></div>
                    </div>

            }
        >

            <CheckBox attributes={attributes} value={props.option.value} required={attributes.validation === 'Yes'}
                      setFieldValues={props.setFieldValues} handleInputChange={props.handleInputChange.bind(this)}
                      fieldoption={props.option} addErrorClass={props.formSubmitted && (!attributes.isValid)}
                      checkedValue={attributes.selectedVals} optionIndex={props.optionIndex}/>
            {attributes.optionstatus && attributes.optionstatus === 'imageoption' && props.fieldoption.value !== '' &&
            <span className="image-tag"><img src={props.fieldoption.value}></img></span>
            }
        </CheckboxWrapper>
    )
}

const CheckboxWrapper = ({condition, wrapper, children}) =>
    wrapper(children);

const RadioBox = ({isWithoutCover, attributes, ...props}) => {
    let optvalue = attributes.customapi && attributes.customapi === 'Yes' ? props.option.recordid : props.option.value;
    return (
        <RadioWrapper
            condition={isWithoutCover}
            wrapper={children =>
                isWithoutCover ? <div
                    className={`radio-block ${(attributes.optionstatus && attributes.optionstatus === 'imageoption') && 'option-with-img'}`}>
                    {children}
                    <label
                        dangerouslySetInnerHTML={{__html: (!attributes.optionstatus || (attributes.optionstatus && attributes.optionstatus === 'textoption')) && props.option.value}}/>

                </div> : <div className="type-form-radio">
                    <label
                        dangerouslySetInnerHTML={{__html: (!attributes.optionstatus || (attributes.optionstatus && attributes.optionstatus === 'textoption')) && props.option.value}}/>
                    {children}
                    <div
                        className="rd-indicator"></div>
                </div>
            }
        >
            <React.Fragment>
                <RadioButton attributes={attributes} value={optvalue} required={attributes.validation === 'Yes'}
                             setFieldValues={props.setFieldValues}
                             handleInputChange={props.handleInputChange.bind(this)}
                             fieldoption={props.option} addErrorClass={props.formSubmitted && (!attributes.isValid)}
                             checkedValue={attributes.selectedVals} optionIndex={props.optionIndex}/>
            </React.Fragment>

        </RadioWrapper>
    )
}

const RadioWrapper = ({condition, wrapper, children}) =>
    wrapper(children);


const Labelbox = ({hasContainer, attributes, ...props}) => {
    // let show = props.showHintStatus;
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <LabelWrapper
            condition={hasContainer}
            wrapper={children =>
                <div className={props.containerClass}>
                    {children}
                </div>

            }
        >
            <React.Fragment>
                {props.formTemplateType === 'withbg' &&
                <p>QUESTION {props.fieldIndex + 1}</p>
                }
                {(props.formTemplateType === 'withbg' || attributes.type === 'switch' || attributes.type === 'checkbox' || attributes.type === 'autofill' || attributes.type === 'check_image') ?
                    <h5 className={props.labelClass}>{!attributes.hideFieldLabel ? attributes.fieldLabel : ''}
                        {attributes.hint && attributes.hint !== '' &&
                        <div className="info-clicker-wrap">
                            {/*<button ref={target} className="info-clicker" onClick={() => setShow(!show)}></button>

                        <Overlay trigger="focus" key='bsy-tooltip' target={target.current} show={show} placement="top" rootClose={true}
                        >
                            {(props) => (
                                <Tooltip id="bsy-tooltip" {...props}>
                                    {attributes.hint}
                                </Tooltip>
                            )}
                        </Overlay>
*/}

                            <OverlayTrigger trigger={'click'} rootClose key='bsy-tooltip' placement='top'
                                            overlay={
                                                <Tooltip id="bsy-tooltip">
                                                    {attributes.hint}
                                                </Tooltip>
                                            }
                            >
                                <button className="info-clicker" onClick={() => setShow(!show)}></button>

                            </OverlayTrigger>
                        </div>
                        }
                    </h5>
                    :

                    <label className={props.labelClass}>
                        {!attributes.hideFieldLabel ? attributes.fieldLabel : ''}
                        {attributes.type !== 'attachment' && attributes.type !== 'otp' &&
                        attributes.hint && attributes.hint !== '' &&
                        <div className="info-clicker-wrap">
                            {/* <button ref={target} className="info-clicker" onClick={() => setShow(!show)}></button>

                        <Overlay trigger="focus" key='bsy-tooltip' target={target.current} show={show} placement="top" rootClose={true}  >
                            {(props) => (
                                <Tooltip id="bsy-tooltip" {...props}>
                                    {attributes.hint}
                                </Tooltip>
                            )}
                        </Overlay>*/}


                            {/*<button className="info-clicker" onClick={()=> props.showHint()}></button>*/}
                            <OverlayTrigger trigger={'click'} rootClose key='bsy-tooltip' placement='top'
                                            overlay={
                                                <Tooltip id="bsy-tooltip">
                                                    {attributes.hint}
                                                </Tooltip>
                                            }
                            >
                                <button className="info-clicker" onClick={() => setShow(!show)}></button>

                            </OverlayTrigger>
                        </div>
                        }
                    </label>
                }

                {attributes.check_image_text &&
                <div className="checbox-with-image-text">
                    <img src={attributes.check_image_url}/>
                    <p>{attributes.check_image_text}</p>
                </div>
                }
                {props.versionedFieldId !== '' &&
                <button className={'ico-lib-v1 prev-version'}
                        onClick={(e) => props.showPreviousVersion(e, attributes, props.fieldIndex, props.page, props.pageIndex, props.versionedFieldId)}></button>
                }
            </React.Fragment>
        </LabelWrapper>
    )
}

const LabelWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;


const FieldBox = ({hasContainer, attributes, ...props}) => {
    return (
        <FieldWrapper
            condition={hasContainer}
            wrapper={children =>
                <div className={props.containerClass}>
                    {children}
                </div>

            }
        >
            {props.renderField(attributes)}
            {props.formTemplateType === 'withbg' && props.formSubmitted && (!attributes.isValid) &&
            <label className="error-fields" id={"error_" + attributes.name}>{attributes.errormsg}</label>
            }
        </FieldWrapper>
    )
}

const FieldWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;

const InputBox = ({hasContainer, attributes, ...props}) => {
    return (
        <InputWrapper
            condition={hasContainer}
            wrapper={children =>
                <div className={props.containerClass}>
                    {children}
                </div>

            }
        >
            <Input attributes={attributes} inputClassName={props.inputClassName}
                   required={attributes.validation === 'Yes' ? true : false}
                   setFieldValues={props.setFieldValues.bind(this)}
                   handleInputChange={props.handleInputChange.bind(this)}
                   addErrorClass={props.formSubmitted && (!attributes.isValid)}
                   onFieldKeyUp={props.onFieldKeyUp} onPasteRestrictSpace={props.onPasteRestrictSpace}/>
        </InputWrapper>
    )
}


const InputWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;

const TextareaBox = ({hasContainer, attributes, ...props}) => {
    return (
        <TextareaWrapper
            condition={hasContainer}
            wrapper={children =>
                <div className={"form-input-box"}>
                    {children}
                </div>

            }
        >
            <TextArea attributes={attributes} inputClassName={props.inputClassName}
                      required={attributes.validation === 'Yes' ? true : false}
                      setFieldValues={props.setFieldValues.bind(this)}
                      handleInputChange={props.handleInputChange.bind(this)}
                      addErrorClass={props.formSubmitted && (!attributes.isValid)}
                      onFieldKeyUp={props.onFieldKeyUp} readonly={attributes.readOnly}/>
        </TextareaWrapper>
    )
}


const TextareaWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;


const AttachmentBox = ({hasContainer, attributes, ...props}) => {

    return (
        <AttachmentWrapper
            condition={hasContainer}
            wrapper={children =>
                <div className="upload-wrapper sm-line">
                    {children}
                </div>
            }
        >
            <React.Fragment>

                <div
                    className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "form-upload-box" : "file-upload"}>
                    <UploadFile getUploadedFile={props.getUploadedFile.bind(this)}
                                showUploadErrors={props.showUploadErrors} fileIndex={parseInt(attributes.masterid)}
                                progressId={"progress-file-" + attributes.masterid}
                                disallowedFiles={attributes.attachmenttypes} attachmentsize={attributes.attachmentsize}
                                attachmentheight={attributes.attachmentheight}
                                attachmentwidth={attributes.attachmentwidth} attributesValue={attributes.value}/>

                    <label
                        className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "" : "ico-lib att"}>{attributes.directupload === 'Yes' ? 'Upload' : attributes.fieldLabel}</label>

                </div>


                <div
                    className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "attached-group" : "attached-file"}>
                    <div className="upload-progress" id={"progress-file-" + attributes.masterid}></div>
                    {attributes.value && Array.isArray(attributes.value) && attributes.value.map((file, index) => {
                            if (file) {
                                let uniqvalue = file.uniqvalue;
                                let filename = file.value;
                                let filetype = 'document';
                                if (filename) {
                                    filetype = getFilePath(filename);
                                }
                                let contentPath = getAttachmentPath(filetype);

                                return (
                                    <div
                                        className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "tf-each-attach" : "file-tag"}
                                        key={"file-" + file.value + "-" + attributes.masterid}
                                        title={file.value}>
                                        {(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') &&
                                        <div className="tf-attach-icon"></div>
                                        }
                                        {(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ?
                                            <h5>{file.value}</h5>
                                            :
                                            (attributes.browseresource === 'Yes') ?
                                                <span className="limit-text">{file.title}</span>
                                                :
                                                <span className="limit-text">{file.value}</span>
                                        }
                                        <button
                                            className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "tf-close" : " ico-lib cl-fl"}
                                            onClick={(e) => props.removeFile(index, attributes.value)}></button>
                                        <button className="form-download"
                                                onClick={() => forceDownload(contentPath + uniqvalue, filename)}></button>
                                    </div>
                                )
                            }

                        }
                    )
                    }
                </div>

            </React.Fragment>
        </AttachmentWrapper>
    )
}

const BrowseAttachmentBox = ({hasContainer, attributes, ...props}) => {
    return (
        <AttachmentWrapper
            condition={hasContainer}
            wrapper={children =>
                <div className="multi-upload-wrapper">
                    {children}
                </div>
            }
        >
            <React.Fragment>
                <div className="field-button-block">
                    <div className="multi-upload-block">
                        <div
                            className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "form-upload-box" : "file-upload"}>
                            <UploadFile getUploadedFile={props.getUploadedFile.bind(this)}
                                        showUploadErrors={props.showUploadErrors}
                                        fileIndex={parseInt(attributes.masterid)}
                                        progressId={"progress-file-" + attributes.masterid}
                                        disallowedFiles={attributes.attachmenttypes}
                                        attachmentsize={attributes.attachmentsize}
                                        attachmentheight={attributes.attachmentheight}
                                        attachmentwidth={attributes.attachmentwidth}
                                        attributesValue={attributes.value}/>
                            <label
                                className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "" : "ico-lib att"}>{attributes.directupload === 'Yes' ? 'Upload' : attributes.fieldLabel}</label>
                        </div>
                        <button className="general-btn"
                                onClick={(e) => props.openBrowseResourceModal('upload', attributes)}>Browse
                        </button>
                    </div>


                    <div className="attached-file-wrap">
                        <div
                            className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "attached-group" : "attached-file"}>
                            <div className="upload-progress" id={"progress-file-" + attributes.masterid}></div>
                            {attributes.value && Array.isArray(attributes.value) && attributes.value.map((file, find) => {
                                    if (file) {
                                        let uniqvalue = file.uniqvalue;
                                        let filename = file.value;
                                        let filetype = 'document';
                                        if (filename) {
                                            filetype = getFilePath(filename);
                                        }
                                        let contentPath = getAttachmentPath(filetype);

                                        return (
                                            <div
                                                className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "tf-each-attach" : "file-tag"}
                                                key={"file-" + file.value + "-" + attributes.masterid}
                                                title={file.value}>
                                                {(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') &&
                                                <div className="tf-attach-icon"></div>
                                                }
                                                {(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ?
                                                    <h5>{file.value}</h5>
                                                    :
                                                    (attributes.browseresource === 'Yes') ?
                                                        <span className="limit-text">{file.title}</span>
                                                        :
                                                        <span className="limit-text">{file.value}</span>
                                                }
                                                <button
                                                    className={(props.formTemplateType === 'withcover' || props.formTemplateType === 'withbg') ? "tf-close" : " ico-lib cl-fl"}
                                                    onClick={(e) => props.removeFile(find, attributes.value)}></button>

                                                <button className="form-preview" title={filename}
                                                        onClick={(e) => props.preview(uniqvalue, file.title, file.extension)}></button>

                                                <button className="form-download"
                                                        onClick={() => forceDownload(contentPath + uniqvalue, filename)}></button>

                                            </div>
                                        )
                                    }

                                }
                            )
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </AttachmentWrapper>
    )
}

const AttachmentWrapper = ({condition, wrapper, children}) =>
    condition ? wrapper(children) : children;


class FormFields extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formPages: [],
            showErrorMsg: false,
            filename: null,
            uploadedFiles: [],
            conditionalhide: 'No',
            fieldcondition: [],
            setcondition: 'No',
            isValid: false,
            errormsg: '',
            openBrowse: false,
            openBrowseResource: false,
            browseAttachments: [],
            browseType: '',
            browseAttributes: [],
            showHintStatus: false

        }

        this.resultDataList = this.resultDataList.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.onPasteRestrictSpace = this.onPasteRestrictSpace.bind(this);


    }

    componentDidMount() {
        this.setState({
            errormsg: this.props.attributes.errormsg,
            fieldCondition: this.props.attributes.fieldcondition,
            setcondition: this.props.attributes.setcondition,
        });

    }

    onPasteRestrictSpace(e) {

        let attributes = this.props.attributes;
        let paste = e.clipboardData.getData('Text');
        if (attributes.hasOwnProperty("replace_hyphen") && attributes.replace_hyphen === 'Yes') {
            var regex = /(?:\s*-\s*)+|\s{1,}/gm;
            var str = e.clipboardData.getData('Text');
            str = str.replace(/[^a-zA-Z0-9 ]/g, '');
            var subst = `-`;
            paste = str.replace(regex, subst);
        } else {
            paste = e.clipboardData.getData('Text');
        }
        const selectionStart = e.target.selectionStart;
        const selectionEnd = e.target.selectionEnd;
        const currentValue = e.target.value;
        const startValue = currentValue.substring(0, selectionStart);
        const endValue = currentValue.substring(selectionEnd);

        var fieldValue = startValue + paste + endValue;

        this.props.setFieldValues(attributes.name, fieldValue, this.props.pageIndex);
        e.preventDefault();
    }

    handleInputChange(e) {
        var fieldName = e.target.name;
        let attributes = this.props.attributes;
        if (attributes.replace_hyphen && attributes.replace_hyphen === 'Yes') {
            if (e.target.value.length >= 2 && e.target.value.charAt(e.target.value.length - 2) === "-" && e.target.value.charAt(e.target.value.length - 1) === ' ') {
                var fieldValue = e.target.value.replace(/\s+/g, '')
            } else {
                if (e.target.value.charAt(e.target.value.length - 1) === ' ') {
                    var fieldValue = e.target.value.replace(/\s+/g, '-')
                } else {
                    var fieldValue = e.target.value
                }
            }
        } else {
            var fieldValue = e.target.value
        }
        if (attributes.type === 'rating') {
            fieldName = e.target.title;
        }
        let field = this.props.checkFieldValid(this.props.attributes, fieldValue);
        this.props.setFieldValues(fieldName, fieldValue, this.props.pageIndex);
        if (field.isValid) {
            if (this.props.attributes.setcondition === 'Yes') {
                this.props.renderByCondition(this.props.attributes, fieldValue);
            }
        }
        if (attributes.type === 'text') {
            let value = attributes.alphabetscase === 'uppercase' ? fieldValue.toUpperCase() : (attributes.alphabetscase === 'lowercase' ? fieldValue.toLowerCase() : fieldValue);
            if (attributes.alphabetsonly === 'Yes' && attributes.allowspace && attributes.allowspace === 'No') {
                value = value.replace(/[^a-zA-Z]/g, '')
                this.props.setFieldValues(attributes.name, value, this.props.pageIndex);
            } else if (attributes.alphabetsonly === 'Yes') {
                value = value.replace(/[^a-zA-Z\s]/g, '')
                this.props.setFieldValues(attributes.name, value, this.props.pageIndex);
            } else if (attributes.allowspace && attributes.allowspace === 'No') {
                value = value.replace(/\s/g, '')
                this.props.setFieldValues(attributes.name, value, this.props.pageIndex);
            } else {
                this.props.setFieldValues(attributes.name, value, this.props.pageIndex);
            }
        }
    }

    handleSignatureVal(data) {
        let fieldValue = data;
        let fieldName = this.props.attributes.name;
        let field = this.props.checkFieldValid(this.props.attributes, fieldValue);
        if (field.isValid) {
            this.props.setFieldValues(fieldName, fieldValue, this.props.pageIndex);
            if (this.props.attributes.setcondition === 'Yes') {
                this.props.renderByCondition(this.props.attributes, fieldValue);
            }
        }
    }

    handleDateChange(date) {
        let fieldValue = date;
        if (moment(date, 'MM/DD/YYYY', true).isValid()) {
            fieldValue = formatDate(date);
        }
        if (this.props.attributes.datetime && this.props.attributes.datetime === 'Yes' && date !== '') {
            // fieldValue = formatDate(date);
            fieldValue = moment(date).format('YYYY-MM-DD HH:mm:ss');
        }

        this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);
        let field = this.props.checkFieldValid(this.props.attributes, fieldValue);

    }

    handleTimeChange(time) {
        let fieldValue = time;
        if (moment(time, 'hh:mm', true).isValid()) {
            fieldValue = moment(time).format('HH:mm:ss');
        }
        this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);
        let field = this.props.checkFieldValid(this.props.attributes, fieldValue);

    }

    getbrowseUploadedFile = (uploadedFile, index, status, attributesValue) => {
        if (attributesValue.length !== 0) {
            if (status === 'completed') {
                let filename = uploadedFile.fileName.split('/').pop();
                let extension = isImage(filename) ? '1' : (isVideo(filename) ? '2' : '3');
                let fieldValue = {
                    uniqvalue: filename,
                    value: uploadedFile.originalfileName,
                    extension: extension,
                    title: uploadedFile.originalfileName
                };
                attributesValue.push(fieldValue)
                this.props.setFieldValues(this.props.attributes.name, attributesValue, this.props.pageIndex);
                let field = this.props.checkFieldValid(this.props.attributes, fieldValue);
            }
        } else {
            if (status === 'completed') {
                let filename = uploadedFile.fileName.split('/').pop();
                let extension = isImage(filename) ? '1' : (isVideo(filename) ? '2' : '3');
                let fieldValue = [{
                    uniqvalue: filename,
                    value: uploadedFile.originalfileName,
                    extension: extension,
                    title: uploadedFile.originalfileName
                }];
                if (this.props.attributes.type === 'photocapture') {
                    fieldValue[0].type = 'attach';
                }
                this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);
                let field = this.props.checkFieldValid(this.props.attributes, fieldValue);
            }
        }

    }

    browseUploadedFile = (selectedFormResources) => {

        let fieldValue = [];
        if (this.state.browseType === 'browse') {
            if (selectedFormResources.length !== 0) {
                selectedFormResources.map((file, index) => {
                    let uniqvalue = '';
                    let value = '';
                    let extension = '';
                    let title = '';

                    uniqvalue = file.thumb;
                    value = file.thumb;
                    extension = file.res_type;
                    title = file.title;

                    file.uniqvalue = uniqvalue;
                    file.value = value;
                    file.extension = extension;
                    fieldValue.push(file);
                })
            }
        } else {
            if (selectedFormResources.length !== 0) {
                selectedFormResources.map((file, index) => {
                    let uniqvalue = '';
                    let value = '';
                    let extension = '';
                    let title = '';
                    if (!file.uniqvalue) {
                        uniqvalue = file.thumb;
                        value = file.thumb;
                        extension = file.res_type;
                        title = file.title;
                    } else {
                        uniqvalue = file.uniqvalue;
                        value = file.value;
                        extension = file.extension;
                        title = file.value;
                    }
                    file.uniqvalue = uniqvalue;
                    file.value = value;
                    file.extension = extension;
                    fieldValue.push(file);
                })
            }

        }

        this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);

    }
    handlePhoneNumberChange = (selectedCountries) => {
        let fieldValue = selectedCountries[0].code + ' ' + selectedCountries[0].number;
        this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);
    }

    handleResourceChange = (selectedFormResources) => {
        let fieldValue = selectedFormResources;
        this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);
    }

    /*getUploadedFile = (uploadedFile, index,status) =>{
        if(status === 'completed'){
            let filename = uploadedFile.fileName.split('/').pop();
            let extension = isImage(filename) ? 1 : (isVideo(filename) ? 2 : 3);
            let fieldValue = [{uniqvalue : filename , value : uploadedFile.originalfileName, extension : extension}];
            if(this.props.attributes.type === 'photocapture') {
                fieldValue[0].type = 'attach';
            }
            this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);
            let field = this.props.checkFieldValid(this.props.attributes, fieldValue);
        }
    }*/

    getUploadedFile = (uploadedFile, index, status) => {
        let fieldValues = this.props.attributes.value || [];
        if (status === 'completed') {
            let filename = uploadedFile.fileName.split('/').pop();
            let extension = isImage(filename) ? 1 : (isVideo(filename) ? 2 : 3);
            let fieldValue = {uniqvalue: filename, value: uploadedFile.originalfileName, extension: extension};
            if (this.props.attributes.type === 'photocapture') {
                fieldValue.type = 'attach';
            }
            fieldValues.push(fieldValue)
            this.props.setFieldValues(this.props.attributes.name, fieldValues, this.props.pageIndex);
            let field = this.props.checkFieldValid(this.props.attributes, fieldValue);
        }
    }

    showUploadErrors = (errormessage, index) => {
        this.props.showUploadErrors(this.props.attributes.name, errormessage, this.props.pageIndex);
    }

    resultDataList(result, reference) {
        this.props.setSelectedValues(result, reference);
        if (result.length > 0) {
            let recordId = result[0].recordid;
            this.props.setFieldValues(this.props.attributes.name, result, this.props.pageIndex);//return false;
            let field = this.props.checkFieldValid(this.props.attributes, result);

            if (this.props.attributes.type === 'autofill') {

                if (this.props.attributes.autofill_relation) {
                    let autofills = result[0].autofill;
                    let autofillRelation = this.props.attributes.autofill_relation;
                    if (autofills && autofills !== undefined) {
                        this.setAutoFillValues(autofills, autofillRelation, recordId)
                    } else {
                        // alert("00000")
                    }
                } else {
                    let autofills = result[0].autofill;
                    if (autofills && autofills !== undefined) {
                        this.setAutoFillValues(autofills, [], recordId)
                    } else {
                        let autofillFieldsArr = this.props.attributes.externalfield;
                        if (autofillFieldsArr.length > 0) {
                            let autofillFields = autofillFieldsArr[0].display;
                            if (autofillFields.length > 0) {
                                for (var i = 0; i < autofillFields.length; i++) {
                                    if (autofillFields[i]) {
                                        let fieldValue = '';
                                        this.props.setFieldValues('form_child_' + autofillFields[i], fieldValue, this.props.pageIndex);
                                    }
                                }
                            }
                        }
                    }
                }

            }
        } else {
            if (this.props.attributes.type === 'autofill') {
                if (this.props.attributes.externalValues.length > 0) {
                    let autofills = this.props.attributes.externalValues[0].autofill;
                    for (var key in autofills) {
                        if (autofills.hasOwnProperty(key)) {
                            this.props.setFieldValues('form_child_' + key, '', this.props.pageIndex);
                        }
                    }
                }
            }
        }
    }

    setAutoFillValues = (autofills, autofillRelation, recordId) => {
        console.log(autofills)
        console.log(autofillRelation)
        console.log(recordId)
        for (var key in autofills) {
            if (autofills.hasOwnProperty(key)) {
                let fieldValue = autofills[key];
                if (Array.isArray(fieldValue) && fieldValue.length > 0) {
                    fieldValue[0].recordid = recordId;
                    this.assignAutoFillValues(key, fieldValue, autofillRelation)
                }
            }
        }
    }

    assignAutoFillValues = (key, fieldValue, autofillRelation) => {
        //console.log("assignAutoFillValues")
        if (this.props.page) {
            if (autofillRelation) {
                //1616074224118
                let matchedField = autofillRelation.filter((item) => item.masterid.toString() === key.toString());
                console.log(matchedField)
                if (matchedField.length > 0) {
                    key = matchedField[0].fillto;
                }
            }
            let comp = this.props.page.components.filter((item) => item.masterid.toString() === key.toString());
            if (comp.length > 0) {
                let matchField = comp[0];
                let selectedItems = [];

                console.log(matchField)
                console.log(fieldValue)

                this.props.assignFieldValues(fieldValue, matchField);
            }
        }
    }

    actionMethod = (searchtext = '') => {
        let attributes = this.props.attributes;
        this.props.getExternalValues(attributes.masterid, attributes.externalfield, searchtext);

    }

    handleTextChange(data) {
        let fieldValue = data;
        let fieldName = this.props.attributes.name;
        let field = this.props.checkFieldValid(this.props.attributes, fieldValue);
        if (field.isValid) {
            this.props.setFieldValues(fieldName, fieldValue, this.props.pageIndex);
            if (this.props.attributes.setcondition === 'Yes') {
                this.props.renderByCondition(this.props.attributes, fieldValue);
            }
        }
    }

    handleToggleChange = (fieldName, fieldValue) => {
        let field = this.props.checkFieldValid(this.props.attributes, fieldValue);

        this.props.setFieldValues(fieldName, fieldValue, this.props.pageIndex);
        if (field.isValid) {

            if (this.props.attributes.setcondition === 'Yes') {
                this.props.renderByCondition(this.props.attributes, fieldValue);
            }
        }
    }

    removeFile(index, file) {
        let files = file;
        if (files.length !== 0) {
            files.splice(index, 1);
        }
        let fieldValue = files;
        this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);
        // let fieldValue = [];
        // this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);
    }

    openBrowseModal = () => {
        this.setState({
            openBrowse: !this.state.openBrowse
        })
    }
    browseClose = () => {
        this.setState({
            openBrowse: false
        })
    }
    openBrowseResourceModal = (type, attributes) => {
        this.setState({
            openBrowseResource: !this.state.openBrowseResource,
            openBrowse: false,
            browseType: type,
            browseAttributes: attributes
        })
    }
    browseResourceClose = () => {
        this.setState({
            openBrowseResource: false
        })
    }
    preview = (thumb, title, res_type) => {
        this.setState({
            ImgName: thumb,
            Title: title,
            res_type: res_type,
            Res_id: '',
            creator: '',
            previewStatus: !this.state.previewStatus
        })
        if (res_type === '1') {
            this.setState({ImgPath: FRMSIMG_PATH})
        } else if (res_type === '3') {
            this.setState({ImgPath: FRMSDOC_PATH})
        } else if (res_type === '2') {
            this.setState({ImgPath: FRMSVID_PATH})
        } else if (res_type === '4') {
            this.setState({ImgPath: FRMSDOC_PATH})
        } else if (res_type === '5') {
            // this.setState({ImgPath:FRMSDOC_PATH})
        } else {
            this.setState({ImgPath: FRMSDOC_PATH})
        }

    }
    closePreview = () => {
        this.setState({
            previewStatus: false,
            ImgName: '',
            Title: '',
            res_type: '',
            Res_id: '',
            ImgPath: '',
            creator: ''
        })
    }

    handleCapture = (fieldName, filename) => {
        let extension = isImage(filename) ? 1 : (isVideo(filename) ? 2 : 3);
        let fieldValue = [{uniqvalue: filename, value: filename, extension: extension, type: 'capture'}];
        this.props.setFieldValues(this.props.attributes.name, fieldValue, this.props.pageIndex);
        let field = this.props.checkFieldValid(this.props.attributes, fieldValue);
    }

    showHint = () => {
        this.setState({
            showHintStatus: true
        })
    }

    renderLabel = (attributes, referenceId, refIndexNo, extraClass = '') => {
        let containerClass = (this.props.formTemplateType === 'withbg') ? 'type-question-left' : (attributes.refRecordId && attributes.refRecordId !== '' ? 'hide' : 'label-title-block');


        let labelClass = (attributes.type === 'editor' || attributes.type === 'computedfield' || attributes.type === 'datecompute' || attributes.type === 'datepicker' || attributes.type === 'timepicker' || attributes.type === 'attachment' || attributes.type === 'photocapture' || attributes.type === 'age')
            ? 'field-header' : ((attributes.type === 'autofill') ? 'autofill-label ' : '');

        labelClass += extraClass;
        //labelClass +=  (attributes.type === 'autofill' || (attributes.type === 'dropdown' && attributes.multipleValues && attributes.multipleValues === 'Yes')) ? ' hide' : '';

        let versionedFieldId = '';
        if (attributes.versioning && attributes.versioning === 'Yes' && this.props.editId && this.props.editId !== '' && this.props.formMeta && this.props.formMeta.version && this.props.formMeta.version > 1 && this.props.formVersions && this.props.formVersions.length > 0) {
            if (this.props.formVersions.includes(attributes.masterid)) {
                versionedFieldId = attributes.masterid;
            } else if (this.props.formVersions.includes(referenceId + '_' + refIndexNo)) {
                versionedFieldId = referenceId + '_' + refIndexNo;
            }
        }

        return (
            <Labelbox hasContainer={this.props.formTemplateType !== 'withcover'} attributes={attributes}
                      versionedFieldId={versionedFieldId} labelClass={labelClass} containerClass={containerClass}
                      page={this.props.page} pageIndex={this.props.pageIndex} fieldIndex={this.props.fieldIndex}
                      showPreviousVersion={this.props.showPreviousVersion}
                      formTemplateType={this.props.formTemplateType}
                      showHintStatus={this.state.showHintStatus} showHint={this.showHint}/>
        )
    }

    renderFieldBox = (attributes) => {
        let containerClass = '';
        return (
            <FieldBox hasContainer={this.props.formTemplateType === 'withbg'} attributes={attributes}
                      containerClass={this.props.formTemplateType === 'withbg' ? 'type-question-right' : ''}
                      formSubmitted={this.props.formSubmitted} renderField={this.renderField}
                      page={this.props.page} pageIndex={this.props.pageIndex} fieldIndex={this.props.fieldIndex}
                      showPreviousVersion={this.props.showPreviousVersion}
                      formTemplateType={this.props.formTemplateType}/>
        )
    }

    renderField = (attributes) => {
        switch (attributes.type) {

            case 'text' :
            case 'number' :
            case 'imageurl' :
            case 'videourl' :
            case 'url' :
            case 'email' :
            case 'pan' :
            case 'name' :
            case 'address' ://Currently not an individual component. Taking as the multiple fields in json
            case 'latitude' :
            case 'longitude' :
                return <InputBox
                    hasContainer={this.props.formTemplateType === 'withcover' || this.props.formTemplateType === 'withbg'}
                    attributes={attributes}
                    inputClassName={this.props.formTemplateType === 'withcover' ? '' : 'fiels-inp-box'}
                    required={attributes.validation === 'Yes' ? true : false}
                    setFieldValues={this.props.setFieldValues.bind(this)} containerClass={"form-input-box"}
                    handleInputChange={this.handleInputChange.bind(this)}
                    onPasteRestrictSpace={this.onPasteRestrictSpace}
                    addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                    onFieldKeyUp={this.props.onFieldKeyUp}/>;
                break;
            case 'dropdown' :
                return <DropdownBox
                    hasContainer={this.props.formTemplateType === 'withcover' || this.props.formTemplateType === 'withbg'}
                    attributes={attributes} formTemplateType={this.props.formTemplateType}
                    dropClassName={(this.props.formTemplateType === 'withcover' || this.props.formTemplateType === 'withbg') ? "form-select" : "input-select"}
                    selectClass={(this.props.formTemplateType === 'withcover' || this.props.formTemplateType === 'withbg') ? '' : 'fiels-inp-box'}
                    setFieldValues={this.props.setFieldValues.bind(this)}
                    showAddExternalValuesModal={this.props.showAddExternalValuesModal}
                    handleInputChange={this.handleInputChange.bind(this)}
                    addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                    onFieldKeyUp={this.props.onFieldKeyUp} resultDataList={this.resultDataList.bind(this)}
                    actionMethod={this.actionMethod}/>
                break;
            case 'switch' :
                return <React.Fragment>
                    {attributes.options.map((option, optionIndex) =>
                        <RadioBox
                            isWithoutCover={this.props.formTemplateType !== 'withcover' && this.props.formTemplateType !== 'withbg'}
                            attributes={attributes} option={option}
                            value={option.value} required={attributes.validation === 'Yes'}
                            setFieldValues={this.props.setFieldValues}
                            handleInputChange={this.handleInputChange.bind(this)}
                            addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                            checkedValue={attributes.selectedVals} optionIndex={optionIndex}/>
                    )}

                </React.Fragment>
                break;
            case 'checkbox' :
                return <React.Fragment>
                    {attributes.options.map((option, optionIndex) =>
                        <CheckboxContainer
                            isWithoutCover={this.props.formTemplateType !== 'withcover' && this.props.formTemplateType !== 'withbg'}
                            attributes={attributes} option={option} value={option.value}
                            required={attributes.validation === 'Yes'} setFieldValues={this.props.setFieldValues}
                            handleInputChange={this.handleInputChange.bind(this)}
                            addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                            checkedValue={attributes.selectedVals} optionIndex={optionIndex}/>
                    )}

                </React.Fragment>
                break;
            case 'check_image' :
                return attributes.option.map((option, optionInd) =>
                    <div className="select-chk-block" key={`check-opt-${optionInd}`}>
                        <label className="control control-checkbox">{option}
                            <CheckboxWithImage attributes={attributes} value={option}
                                               required={attributes.validation === 'Yes'}
                                               setFieldValues={this.props.setFieldValues}
                                               handleInputChange={this.handleInputChange.bind(this)}
                                               addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                               checkedValue={attributes.selectedVals} optionIndex={optionInd}/>
                            <div className="checkbox-indicator"></div>
                        </label>
                    </div>
                );
                break;
            case 'textarea' :
                return <TextareaBox
                    hasContainer={this.props.formTemplateType === 'withcover' || this.props.formTemplateType === 'withbg'}
                    attributes={attributes}
                    inputClassName={(this.props.formTemplateType === 'withcover' || this.props.formTemplateType === 'withbg') ? '' : 'fiels-inp-box'}
                    required={attributes.validation === 'Yes' ? true : false} setFieldValues={this.props.setFieldValues}
                    handleInputChange={this.handleInputChange.bind(this)}
                    addErrorClass={this.props.formSubmitted && (!attributes.isValid)} value={attributes.value}
                    onFieldKeyUp={this.props.onFieldKeyUp}/>;
                break;
            case 'attachment' :
                return <React.Fragment>
                    {attributes.directupload && attributes.browseresource && attributes.directupload === 'Yes' && attributes.browseresource === 'Yes' ?
                        <React.Fragment>
                            <BrowseAttachmentBox hasContainer={this.props.formTemplateType !== 'withcover'}
                                                 attributes={attributes} formTemplateType={this.props.formTemplateType}
                                                 getUploadedFile={this.getbrowseUploadedFile}
                                                 showUploadErrors={this.showUploadErrors}
                                                 fileIndex={parseInt(attributes.masterid)}
                                                 progressId={"progress-file-" + attributes.masterid}
                                                 disallowedFiles={attributes.attachmenttypes}
                                                 attachmentsize={attributes.attachmentsize}
                                                 attachmentheight={attributes.attachmentheight}
                                                 attachmentwidth={attributes.attachmentwidth}
                                                 removeFile={this.removeFile}
                                                 openBrowseResourceModal={this.openBrowseResourceModal}
                                                 preview={this.preview}

                            />
                        </React.Fragment>
                        :
                        (attributes.browseresource && attributes.browseresource === 'Yes') ?
                            <React.Fragment>
                                <div className="field-button-block">
                                    <div className="attachment-wrap">
                                        <button className="general-btn"
                                                onClick={(e) => this.openBrowseResourceModal('browse', attributes)}>Browse
                                        </button>
                                    </div>
                                </div>
                                {attributes.value && Array.isArray(attributes.value) &&
                                <div className="form-resource-preview">
                                    <div className="form-resource-block">
                                        {attributes.value.map((file) => {
                                            if (file) {
                                                let uniqvalue = file.uniqvalue;
                                                let filename = file.value;
                                                let filetype = 'document'
                                                if (filename) {
                                                    filetype = getFilePath(filename);
                                                }
                                                let contentPath = getAttachmentPath(filetype);
                                                let iconClass = getFileExtension(uniqvalue);
                                                return (

                                                    <div className="file-each image-type">
                                                        {filetype !== 'image' &&
                                                        <div className={"format " + iconClass}>
                                                            {iconClass}
                                                        </div>
                                                        }
                                                        {filetype === 'image' &&
                                                        <img src={contentPath + uniqvalue}/>
                                                        }
                                                        <div className={`file-each-opt single-opt`}>

                                                            <button className="opt-file-each preview" title={filename}
                                                                    onClick={(e) => this.preview(uniqvalue, file.title, file.extension)}></button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                        }
                                    </div>
                                </div>
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <AttachmentBox hasContainer={this.props.formTemplateType !== 'withcover'}
                                               attributes={attributes} formTemplateType={this.props.formTemplateType}
                                               getUploadedFile={this.getUploadedFile.bind(this)}
                                               showUploadErrors={this.showUploadErrors}
                                               fileIndex={parseInt(attributes.masterid)}
                                               progressId={"progress-file-" + attributes.masterid}
                                               disallowedFiles={attributes.attachmenttypes}
                                               attachmentsize={attributes.attachmentsize}
                                               attachmentheight={attributes.attachmentheight}
                                               attachmentwidth={attributes.attachmentwidth}
                                               removeFile={this.removeFile}/>

                                {attributes.value && Array.isArray(attributes.value) && attributes.value.map((file) => {
                                    if (file) {
                                        let uniqvalue = file.uniqvalue;
                                        let filename = file.value;
                                        let filetype = getFilePath(filename);
                                        if (filetype === 'image') {
                                            let contentPath = getAttachmentPath(filetype);

                                            return (
                                                <div className="upload-image-container">
                                                    <img
                                                        src={contentPath + uniqvalue}/>
                                                </div>
                                            )
                                        }

                                    }
                                })}
                            </React.Fragment>
                    }
                </React.Fragment>
                break;
            case 'datepicker' :
                return <DateTimePickerBox placeholderText={attributes.fieldLabel}
                                          handleDateChange={this.handleDateChange.bind(this)} id={attributes.id}
                                          startDate={(attributes.value && attributes.value !== '') ? new Date(attributes.value) : ''}
                                          dateFormat={'DD-MM-YYYY'}
                                          timeFormat={attributes.datetime && attributes.datetime === 'Yes' ? true : false}
                                          autoCaptureRemoveClose={attributes.autocapture && attributes.autocapture === 'Yes' ? false : true}
                                          setFieldValues={this.props.setFieldValues.bind(this)}
                                          addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                          previousDate={true}
                                          showClearButton={attributes.validation === 'yes' ? false : true}/>

                break;
            case 'timepicker' :
                return <TimePickerBox placeholderText={attributes.fieldLabel}
                                      handleTimeChange={this.handleTimeChange.bind(this)} id={attributes.id}
                                      startTime={(attributes.value && attributes.value !== '') ? moment(attributes.value, 'HH:mm:ss').format('hh:mm A') : ''}
                                      dateFormat={false} timeFormat={'hh:mm A'}
                                      setFieldValues={this.props.setFieldValues.bind(this)}
                                      addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                      showClearButton={attributes.validation === 'yes' ? false : true}/>

                break;
            case 'formheading' :
            case 'fieldset' :
                return <><h4>{attributes.fieldLabel}</h4>
                    <p>{attributes.placeholder}</p></>;
                break;
            case 'editor' :
                return <HTMLEditor attributes={attributes} setFieldValues={this.props.setFieldValues.bind(this)}
                                   handleTextChange={this.handleTextChange.bind(this)}
                                   addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                   value={attributes.value}/>
                break;
            case 'signature' :
                return <DigitalSignature attributes={attributes} setFieldValues={this.props.setFieldValues.bind(this)}
                                         handleSignatureVal={this.handleSignatureVal.bind(this)}
                                         addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                         value={attributes.value} isCortiza={this.props.formTemplateType === 'cortiza'}
                                         pageIndex={this.props.pageIndex} editId={this.props.editId}
                                         showPreviousVersion={this.props.showPreviousVersion}
                                         formMeta={this.props.formMeta} formVersions={this.props.formVersions}/>
                break;
            case 'dataload' :
                return <DataLoad attributes={attributes} setFieldValues={this.props.setFieldValues.bind(this)}
                                 addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                 value={attributes.value}
                                 initiateDataLoad={this.props.initiateDataLoad} getEntities={this.props.getEntities}
                                 getCollections={this.props.getCollections}
                                 collectionSource={this.props.collectionSource} entitySource={this.props.entitySource}
                                 getFields={this.props.getFields} dataloadFields={this.props.dataloadFields}
                                 getAggregate={this.props.getAggregate}
                                 aggValue={this.props.aggValue} isCortiza={this.props.formTemplateType === 'cortiza'}/>
                break;
            case 'toggle' :
                return <Toggle attributes={attributes} handleToggleChange={this.handleToggleChange}
                               addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                               value={attributes.value} onFieldKeyUp={this.props.onFieldKeyUp}
                               isCortiza={this.props.formTemplateType === 'cortiza'} pageIndex={this.props.pageIndex}
                               editId={this.props.editId} showPreviousVersion={this.props.showPreviousVersion}
                               formMeta={this.props.formMeta} formVersions={this.props.formVersions}
                />
                break;
            case 'autofill' :
                return <Autofill attributes={attributes}
                                 handleInputChange={this.handleInputChange.bind(this)}
                                 addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                 value={attributes.value}
                                 resultDataList={this.resultDataList}
                                 actionMethod={this.actionMethod}
                />
                break;

            case 'photocapture' :
                return <PhotoCapture attributes={attributes}
                                     handleCapture={this.handleCapture.bind(this)}
                                     addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                     value={attributes.value}
                                     getUploadedFile={this.getUploadedFile}
                                     formTemplateType={this.props.formTemplateType}
                />
                break;
            case 'videocapture' :
                return <VideoCapture attributes={attributes}
                                     handleCapture={this.handleCapture.bind(this)}
                                     addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                     value={attributes.value}
                                     getUploadedFile={this.getUploadedFile}
                                     formTemplateType={this.props.formTemplateType}
                />
                break;
            case 'computedfield' :
                return <div className="form-input-block-left">
                    <div className="form-type-input ">
                        {/*{console.log(attributes.masterid+"-----"+ attributes.realvalue +"----" + attributes.value)}*/}
                        {/*{(attributes.realvalue === Infinity ? '' : (attributes.value === 0 ? attributes.value :(( attributes.value !== undefined && !isNaN(attributes.value) ) ? parseFloat(attributes.value).toFixed(2) : '')))}*/}
                        {(attributes.realvalue && attributes.realvalue === Infinity ? '' : ((attributes.value !== undefined && attributes.value !== '' && !isNaN(attributes.value)) ? parseFloat(attributes.value).toFixed(2) : ''))}
                    </div>
                    {/*<div>
                        <button className="refresh-btn" onClick={()=>this.props.calculateComputedValue(attributes.masterid)} title="Refresh"></button>
                    </div>*/}
                </div>
                break;
            case 'datecompute' :
                return <div className="form-input-block-left cf-field">
                    <div className="form-type-input ">
                        {(attributes.value !== undefined && attributes.value !== Infinity && attributes.value !== 'Invalid date') ? attributes.value : ''}
                    </div>
                </div>
                break;
            case 'displaytext' :
                return <DisplayText attributes={attributes}
                                    addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                    value={attributes.value}
                />
                break;
            case 'displaylabel' :
                return <DisplayLabel attributes={attributes}
                                     addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                     value={attributes.value}
                />
                break;
            case 'agree' :
                return <Agree attributes={attributes} addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                              checkedValue={attributes.selectedVals}
                              value={attributes.value} setFieldValues={this.props.setFieldValues}
                              pageIndex={this.props.pageIndex}
                />
                break;
            case 'loggedin' :
                return <LoggedIn attributes={attributes} required={attributes.validation === 'Yes' ? true : false}
                                 setFieldValues={this.props.setFieldValues.bind(this)}
                                 handleInputChange={this.handleInputChange.bind(this)}
                                 addErrorClass={this.props.formSubmitted && (!attributes.isValid)}/>;
                break;
            case 'otp' :
                return <OTP attributes={attributes} required={attributes.validation === 'Yes' ? true : false}
                            setFieldValues={this.props.setFieldValues.bind(this)}
                            handleInputChange={this.handleInputChange.bind(this)}
                            addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                            sendOtpfield={this.props.sendOtpfield} bsynOtp={this.props.bsynOtp}
                            verifyOtp={this.props.verifyOtp} handleInputOtpChange={this.props.handleInputOtpChange}
                            recievedOtp={this.props.recievedOtp} pageIndex={this.props.pageIndex}/>;
                break;
            case 'rating' :
                return <Rating attributes={attributes} required={attributes.validation === 'Yes' ? true : false}
                               setFieldValues={this.props.setFieldValues.bind(this)}
                               handleInputChange={this.handleInputChange.bind(this)}
                               addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                               collaborator={this.props.collaborator} editId={this.props.editId}
                               pageIndex={this.props.pageIndex} editId={this.props.editId}
                               showPreviousVersion={this.props.showPreviousVersion} formMeta={this.props.formMeta}
                               formVersions={this.props.formVersions}/>;
                break;
            case 'payment' :
                return <Payment attributes={attributes} required={attributes.validation === 'Yes' ? true : false}
                                setFieldValues={this.props.setFieldValues.bind(this)}
                                handleInputChange={this.handleInputChange.bind(this)}
                                addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                paymentDetails={this.props.paymentDetails} payChange={this.props.payChange}
                                payAmount={this.props.payAmount}/>;
                break;
            case 'resources' :
                return <Resource attributes={attributes} required={attributes.validation === 'Yes' ? true : false}
                                 setFieldValues={this.props.setFieldValues.bind(this)}
                                 handleResourceChange={this.handleResourceChange.bind(this)}
                                 addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                 selectedResourceItems={this.props.selectedResourceItems} editId={this.props.editId}
                                 pageIndex={this.props.pageIndex}
                                 showPreviousVersion={this.props.showPreviousVersion}/>;
                break;
            case 'phonenumber' :
                return <PhoneNumber
                    hasContainer={this.props.formTemplateType === 'withcover' || this.props.formTemplateType === 'withbg'}
                    attributes={attributes}
                    inputClassName={this.props.formTemplateType === 'withcover' ? '' : 'fiels-inp-box'}
                    required={attributes.validation === 'Yes' ? true : false}
                    setFieldValues={this.props.setFieldValues.bind(this)} containerClass={"form-input-box"}
                    handlePhoneNumberChange={this.handlePhoneNumberChange} editId={this.props.editId}
                    pageIndex={this.props.pageIndex}/>;
                break;
            case 'preview' :
                return <Preview attributes={attributes} setFieldValues={this.props.setFieldValues.bind(this)}
                                addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                editId={this.props.editId} pageIndex={this.props.pageIndex}
                                checkedValue={attributes.selectedVals} value={attributes.value}
                                setFieldValues={this.props.setFieldValues} pageIndex={this.props.pageIndex}/>;
                break;
            case 'displayimage' :
                return <DisplayImage attributes={attributes} setFieldValues={this.props.setFieldValues.bind(this)}
                                     addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                     editId={this.props.editId} pageIndex={this.props.pageIndex}
                                     checkedValue={attributes.selectedVals} value={attributes.value}
                                     setFieldValues={this.props.setFieldValues} pageIndex={this.props.pageIndex}/>;
                break;
            case 'displayvideo' :
                return <DisplayVideo attributes={attributes} setFieldValues={this.props.setFieldValues.bind(this)}
                                     addErrorClass={this.props.formSubmitted && (!attributes.isValid)}
                                     editId={this.props.editId} pageIndex={this.props.pageIndex}
                                     checkedValue={attributes.selectedVals} value={attributes.value}
                                     setFieldValues={this.props.setFieldValues} pageIndex={this.props.pageIndex}/>;
                break;
            case 'age' :
                return <div className="form-input-block-left cf-field">
                    <div className="form-type-input ">
                        {(attributes.value && !isNaN(attributes.value) && attributes.value !== undefined && attributes.value !== Infinity) ? attributes.value : ''}
                    </div>
                </div>

        }
    }

    render() {

        let formTemplateType = this.props.formTemplateType;
        let attributes = this.props.attributes;
        let fieldWrapperClass = (attributes.questionlabel === 'Yes' ? 'input-block question-head ' : attributes.databindingid && attributes.databindingid !== '' ? '' : 'input-block ');

        if (formTemplateType === 'withcover' || formTemplateType === 'withbg') {
            fieldWrapperClass = formTemplateType === 'withcover' ? 'type-form-input' : 'each-type-question';
            if (formTemplateType === 'withcover') {
                if (attributes.type === 'checkbox') {
                    fieldWrapperClass = "type-form-checkbox-container"
                } else if (attributes.type === 'switch') {
                    fieldWrapperClass = "type-form-radio-container"
                }
            } else {
                if (attributes.type === 'photocapture') {
                    fieldWrapperClass += " photocapture"
                }
            }
            if (attributes.type === 'textarea') {
                fieldWrapperClass += " text"
            } else if (attributes.type === 'attachment') {
                fieldWrapperClass += " files"
            } else if (attributes.type === 'signature') {
                fieldWrapperClass += " signature"
            } else if (attributes.type === 'editor') {
                fieldWrapperClass += " editor"
            } else if (attributes.type === 'computedfield') {
                fieldWrapperClass += " c-field"
            } else if (attributes.type === 'datecompute') {
                fieldWrapperClass += " c-field"
            } else if (attributes.type === 'age') {
                fieldWrapperClass += " c-field"
            } else if (attributes.type === 'otp') {
                fieldWrapperClass += " onetime"
            } else if (attributes.type === 'rating') {
                fieldWrapperClass += " rating"
            } else if (attributes.type === 'payment') {
                fieldWrapperClass += " payment"
            } else if (attributes.type === 'phonenumber') {
                fieldWrapperClass += " phonenumber"
            } else if (attributes.type === 'preview') {
                fieldWrapperClass += " preview"
            } else if (attributes.type === 'displayimage') {
                fieldWrapperClass += " displayimage"
            } else if (attributes.type === 'displayvideo') {
                fieldWrapperClass += " displayvideo"
            } else if (attributes.type === 'resources') {
                fieldWrapperClass += " l-resources"
            } else if (attributes.type === 'displaytext') {
                fieldWrapperClass += " displaytext"
            } else if (attributes.type === 'displaylabel') {
                fieldWrapperClass += " displaytext"
            } else if (attributes.type === 'autofill') {
                fieldWrapperClass += " auto-fill"
            } else if (attributes.type === 'videocapture') {
                fieldWrapperClass += " video-capture"
            } else if (attributes.type === 'dropdown' && ((attributes.externallink && attributes.externallink === 'Yes') || (attributes.multipleselection && attributes.multipleselection.toLowerCase() === 'yes'))) {
                fieldWrapperClass += " auto-fill"
            } else if (attributes.type === 'dataload') {
                fieldWrapperClass += " dataload"
            }
        } else {
            if (attributes.type === 'fieldset') {
                fieldWrapperClass = " ind-form-field field-set";
            } else if (attributes.type === 'dropdown' && (!attributes.externallink || (attributes.externallink && attributes.externallink !== 'Yes'))) {
                fieldWrapperClass += " drop collection-drop";
                if (attributes.value !== '') {
                    fieldWrapperClass += " active";
                }
            } else if (attributes.type === 'dropdown' && (attributes.externallink && attributes.externallink === 'Yes')) {
                fieldWrapperClass += " autofill collection-drop";
                if (attributes.externaladdbutton && attributes.externaladdbutton === 'Yes') {
                    fieldWrapperClass += " lbl-hidden ";
                }
                if (attributes.value !== '') {
                    fieldWrapperClass += " active";
                }
            } else if (attributes.type === 'autofill') {
                fieldWrapperClass += " autofill ";
                if (attributes.value !== '') {
                    fieldWrapperClass += " active";
                }
            } else if (attributes.type === 'switch') {
                fieldWrapperClass = (attributes.singlecolumn === 'Yes' ? (attributes.display_style && attributes.display_style === 'vertical' ? 'radio-wrapper v-align single-col' : 'radio-wrapper h-align single-col') : (attributes.display_style && attributes.display_style === 'vertical' ? 'radio-wrapper v-align' : 'radio-wrapper h-align'));
            } else if (attributes.type === 'checkbox') {
                fieldWrapperClass = (attributes.display_style && attributes.display_style === 'vertical' ? 'checkbox-wrapper v-align ind-form-field' : 'checkbox-wrapper h-align ind-form-field');
            } else if (attributes.type === 'check_image') {
                fieldWrapperClass = "checkbox-wrapper checkbox-image  ind-form-field";
            } else if (attributes.type === 'displaytext') {
                fieldWrapperClass = "input-block display-text";
            } else if (attributes.type === 'displaylabel') {
                fieldWrapperClass = "input-block display-text";
            } else if (attributes.type === 'number') {
                fieldWrapperClass = (attributes.setasslider && attributes.setasslider === 'Yes' ? 'input-block unique-field range-slider' : attributes.refRecordId && attributes.refRecordId !== '' ? '' : 'input-block unique-field');
            } else if (attributes.type === 'attachment') {
                fieldWrapperClass = "input-block unique-field attachment-box";
            } else if (attributes.type === 'formheading') {
                fieldWrapperClass = " heading ";
            } else if (attributes.type === 'datepicker') {
                fieldWrapperClass += " date-field ";
            } else if (attributes.type === 'timepicker') {
                fieldWrapperClass += " date-field ";
            } else if (attributes.type === 'toggle') {
                fieldWrapperClass += " toggle ";
            } else if (attributes.type === 'photocapture') {
                //fieldWrapperClass += " photo-capture-block  inline-capture";
                fieldWrapperClass += " photo-capture-block ";
                if (formTemplateType === 'media')
                    fieldWrapperClass += " inline-capture ";
            } else if (attributes.type === ' videocapture') {
                fieldWrapperClass += " video-capture";
            } else if (attributes.type === '  computedfield') {
                fieldWrapperClass += "  computed-field";
            } else if (attributes.type === '  age') {
                fieldWrapperClass += "  current-age";
            } else if (attributes.type === 'signature') {
                fieldWrapperClass += " signature"
            } else if (attributes.type === 'editor') {
                fieldWrapperClass += " html-editor"
            } else if (attributes.type === 'rating') {
                fieldWrapperClass = "input-block feedback-field ";
            } else if (attributes.type === 'payment') {
                fieldWrapperClass = "input-block payment ";
            } else if (attributes.type === 'phonenumber') {
                fieldWrapperClass = "input-block phonenumber ";
            } else if (attributes.type === 'preview') {
                fieldWrapperClass = "input-block preview ";
            } else if (attributes.type === 'displayimage') {
                fieldWrapperClass = "input-block displayimage ";
            } else if (attributes.type === 'displayvideo') {
                fieldWrapperClass = "input-block displayvideo ";
            } else if (attributes.type === 'displaylabel') {
                fieldWrapperClass = " ";
            } else if (attributes.type === 'resources') {
                fieldWrapperClass = "input-block resources";
            } else if (attributes.type === 'otp') {
                // fieldWrapperClass = "input-block otp-field unique-field";
                fieldWrapperClass = (attributes.otpbuttontype === 'text' ? 'input-block otp-field unique-field otp-textlink' : 'input-block otp-field unique-field');
            }
        }


        if (attributes.value && attributes.value !== '') {
            if (attributes.type === 'text' || attributes.type === 'number' || attributes.type === 'imageurl' || attributes.type === 'videourl' || attributes.type === 'url' || attributes.type === 'email' || attributes.type === 'pan' || attributes.type === 'name' || attributes.type === 'address' || attributes.type === 'textarea') {
                fieldWrapperClass += " active";
            }
        }

        if (this.props.formSubmitted && (!attributes.isValid && attributes.showInForm === 'Yes')) {
            fieldWrapperClass += " mandatory-box red-alert";
            if (attributes.uniqError) {
                fieldWrapperClass += " unique-field";
            }
        }

        if (this.props.fieldNature && this.props.fieldNature === 'readonly') {
            fieldWrapperClass += " no-action";
        }
        if (attributes.focus && attributes.focus === 'Yes') {
            fieldWrapperClass += " focus-ele";
        }
        if (ES_INDEX === 'sepalika' || ES_INDEX === 'bsynupgrade') {
            fieldWrapperClass += " with-source";
        }

        let page = this.props.page;


        let referenceId = attributes.referenceId;
        let refIndexNo = attributes.refIndexNo ? attributes.refIndexNo : 0;

        let thisRefIndex = refIndexNo + 1;

        let refElement = attributes;
        let refElementIndex = this.props.fieldIndex;
        if (refIndexNo > 0) {
            let baseField = page.components.filter((item, i) => (item.masterid && item.masterid === referenceId));
            let refElementItems = page.components.map((item, i) => {
                if (item.masterid === attributes.referenceId) {
                    return i;
                } else {
                    return -1;
                }
            });

            let refElementIndexes = refElementItems.filter((item, ind) => item > -1);
            refElementIndex = refElementIndexes.length > 0 && refElementIndexes[0];
            refElement = baseField.length > 0 && baseField[0];
        }

        let versionedFieldId = '';
        let autocaptureDate = attributes.type === 'datepicker' && attributes.autocapture && attributes.autocapture === 'Yes' ? 'Yes' : 'No';
        let autocaptureTime = attributes.type === 'timepicker' && attributes.autocapture && attributes.autocapture === 'Yes' ? 'Yes' : 'No';

        return (
            <React.Fragment>
                {(attributes.type !== 'uniqueid' && attributes.type !== 'loggedin' && attributes.type !== 'location') &&
                <div
                    className={`${fieldWrapperClass} ${(attributes.showInForm === 'No') ? ' hide' : ''}`}
                    data-id={attributes.masterid} id={attributes.masterid}>


                    {((this.props.formTemplateType === 'withcover' && attributes.type !== 'toggle' && attributes.type !== 'signature' && attributes.type !== 'rating') || this.props.formTemplateType === 'withbg' || attributes.type === 'switch' || attributes.type === 'checkbox' || attributes.type === 'autofill' || attributes.type === 'check_image' || attributes.type === 'editor' || attributes.type === 'computedfield' || attributes.type === 'datecompute' || attributes.type === 'age' || attributes.type === 'datepicker' || attributes.type === 'timepicker' || attributes.type === 'attachment' || attributes.type === 'photocapture') &&
                    this.renderLabel(attributes, referenceId, refIndexNo)
                    }
                    {this.renderFieldBox(attributes)}

                    {(attributes.type !== 'formheading' && attributes.type !== 'fieldset' && attributes.type !== 'uniqueid' && attributes.type !== 'autofill'
                        && attributes.type !== 'editor' && attributes.type !== 'displaytext' && attributes.type !== 'displaylabel' && attributes.type !== 'agree' && attributes.type !== 'loggedin' && attributes.type !== 'header' && attributes.type !== 'footer'
                        && attributes.type !== 'location' && attributes.type !== 'payment' && attributes.type !== 'resources' && attributes.type !== 'preview' && attributes.type !== 'displayimage' && attributes.type !== 'displayvideo') &&

                    <React.Fragment>


                        {this.props.formTemplateType !== 'withcover' && this.props.formTemplateType !== 'withbg' &&
                        attributes.type !== 'switch' && attributes.type !== 'checkbox' && attributes.type !== 'toggle' && attributes.type !== 'signature' && attributes.type !== 'datepicker'
                        && attributes.type !== 'timepicker' && attributes.type !== 'attachment' && attributes.type !== 'photocapture' && attributes.type !== 'videocapture' && attributes.type !== 'computedfield' && attributes.type !== 'datecompute' && attributes.type !== 'age'
                        && attributes.type !== 'rating' && attributes.type !== 'otp' &&
                        this.renderLabel(attributes, referenceId, refIndexNo, 'anim-text')
                        }
                        {/*{attributes.hint &&
                        <div className="info-clicker-wrap">
                            <button className="info-clicker"></button>
                            <div className="clicker-toggle">
                                <p>{attributes.hint}</p>
                            </div>
                        </div>
                        }*/}
                    </React.Fragment>
                    }

                    {this.props.formTemplateType !== 'withbg' && this.props.formSubmitted && (!attributes.isValid) &&
                    <label className="error-fields" id={"error_" + attributes.name}>{attributes.errormsg}</label>
                    }
                    {/*{attributes.type === 'attachment' && this.props.formSubmitted && ( !attributes.isValid ) &&
                    <label className="error-fields" id={"error_" + attributes.name}>{attributes.errormsg}</label>
                    }*/}

                    {attributes.addmuliple === 'Yes' &&
                    <div className="rightButtons">
                        {attributes.removableField && attributes.removableField === "Yes" &&
                        <button className="btnsubIcon ico-lib"
                                onClick={(e) => this.props.removeElement(this.props.pageIndex, this.props.fieldIndex, attributes.masterid)}>
                        </button>
                        }
                        {attributes.showPlusBtn &&
                        <button
                            className="btnsubIconsadd ico-lib"
                            onClick={(e) => this.props.appendElement(this.props.pageIndex, refElementIndex, refElement, -1, 'yes')}>
                        </button>
                        }
                    </div>
                    }
                </div>
                }

                {this.state.openBrowseResource &&
                <ResourcesFromBrowse
                    attributes={this.state.browseAttributes}
                    selectedResourceItems={this.props.selectedBrowseResourceItems}
                    handleResourceChange={this.browseUploadedFile}
                    editId={this.props.editId}
                    browseResourceClose={this.browseResourceClose}
                    browseType={this.state.browseType}
                />
                }
                {this.state.previewStatus &&
                <div className="modal fade bsy-modal x-large fl-pv show" id="toModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" aria-hidden="true" style={{display: 'block'}}>
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.Title}</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closePreview}></button>
                            </div>
                            <div className="modal-body">
                                <div className="preview-area chat-panel">
                                    {this.state.res_type === '1' &&
                                    <img src={this.state.ImgPath + this.state.ImgName}/>
                                    }
                                    {this.state.res_type === '2' &&
                                    <React.Fragment>
                                        <VideoPlayer
                                            key={this.state.ImgPath + this.state.ImgName}
                                            display={'block'}
                                            width="100%"
                                            height="100%"
                                            src={this.state.ImgPath + this.state.ImgName}
                                        />
                                    </React.Fragment>
                                    }
                                    {this.state.res_type === '3' &&
                                    <iframe src={FRMSPDFDOC_PATH + this.state.ImgName + '&embedded=true'} width="100%"
                                            height="250px"/>
                                    }
                                    {this.state.res_type === '4' &&
                                    <audio width="100%" height="100%" controls controlsList="nodownload"
                                           key={this.state.ImgPath + this.state.ImgName}>
                                        <source type="audio/mpeg"
                                                src={this.state.ImgPath + this.state.ImgName}></source>
                                    </audio>
                                    }
                                    {this.state.res_type === '5' &&
                                    <React.Fragment>
                                        <VideoPlayer
                                            key={this.state.ImgPath + this.state.ImgName}
                                            display={'block'}
                                            width="100%"
                                            height="100%"
                                            src={this.state.ImgPath + this.state.ImgName}
                                            resType='5'
                                            fileName={this.state.ImgName}
                                        />
                                    </React.Fragment>
                                    }
                                    {this.state.res_type === '6' &&
                                    <iframe width="500" height="250" src={this.state.ImgName} alt="Thumb-img"/>
                                    }
                                    <div className="preview-options">
                                        <ul className="resources-filters-icons">
                                            {this.state.res_type !== 5 &&
                                            <li>
                                                <a className="rs-tab-btn ico-lib-v1 rc-download"
                                                   onClick={(e) => {
                                                       forceDownload(this.state.ImgPath + this.state.ImgName, this.state.Title)
                                                   }}></a>
                                            </li>
                                            }
                                            {this.props.creator === getProfile().id &&
                                            <li><a className="rs-tab-btn ico-lib-v1 rc-delete"
                                                   onClick={(e) => this.props.deleteResource(e)}></a></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </React.Fragment>
        )
    }
}

export default FormFields;
