import React, {Component, useState, useEffect} from 'react';
import {FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH, S3_URL} from "../../../../../constants";
import {isValidDate, isImage, isVideo, forceDownload} from "../../../../../utilities/CustomFunctions"
import NoData from "../../../../views/NoData";

function ResultTable(Props) {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const container = document.querySelector('.form-table');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        var scrollY = 0;
        var scrollX = 0;
        var doc = document.getElementById("a");
        if (doc.scrollTop !== scrollY) {
            const container = document.querySelector('.form-table')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            if (contentHeight <= scrollTop) {
                Props.loadmoreCollectionData()
            }
            ;
        }
        setIsFetching(true);
    }

    function fetchMoreListItems() {
        Props.loadmoreCollectionData()
    }

    let showlistField = Props.showlistField
    //console.log(Props.collection)
    return (
        <div className="form-table coll-full-height full-view-tabel fixed-header">

            {showlistField.length === 0 &&
            <div className='absolute-holder result-table-no-data'>
                <NoData
                    msg={`No Data linked to this collection`}
                />
            </div>
            }

            <div className="table-responsive">
                <table className="table entity-dashboard-table">
                    <thead>
                    <tr>
                        {showlistField.filter(fld => !fld.hasOwnProperty("default") || fld.default === '').map((title, index) =>
                            <React.Fragment key={`showlist-field` + index}>
                                <th scope="col">
                                    {title.label}
                                </th>
                            </React.Fragment>
                        )}

                    </tr>
                    </thead>
                    <tbody>
                    {Props.collection.map((row, rowIndex) =>
                        <tr key={`result-table-content` + rowIndex}>

                            {showlistField.filter(fld => !fld.hasOwnProperty("default") || fld.default === '').map((title, ind) => {
                                    let fieldValue = isValidDate(row[title.masterid]);
                                    let albumName;
                                    let URL;
                                    let filename;
                                    if (title.type === "attachment") {

                                        // fieldValue = FRMSIMG_PATH + row[title.masterid];

                                        if (isImage(row[title.masterid])) {
                                            albumName = 'images/'
                                            URL = FRMSIMG_PATH
                                        } else if (isVideo(row[title.masterid])) {
                                            albumName = 'videos/'
                                            URL = FRMSVID_PATH
                                        } else {
                                            albumName = 'documents/'
                                            URL = FRMSDOC_PATH
                                        }
                                        fieldValue = row[title.masterid];
                                        filename = row[title.masterid + "filename"];
                                        //console.log(fieldValue)
                                    }

                                    return (
                                        <React.Fragment key={`edit-collection-data` + ind}>
                                            <td>
                                                <div className={`form-table-edit`}>
                                                    {(title.type === "attachment") ?
                                                        <div className="att-file">
                                                            {(fieldValue !== '' && albumName === 'images/') ?
                                                                <span style={{cursor: "pointer"}} onClick={(e) => {
                                                                    forceDownload(URL + fieldValue, filename)
                                                                }}>
                                                    <img src={URL + fieldValue}/>
                                                        </span>
                                                                : (fieldValue !== '') ?

                                                                    <span style={{cursor: "pointer"}} onClick={(e) => {
                                                                        forceDownload(URL + fieldValue, filename)
                                                                    }}>{filename}</span>
                                                                    :
                                                                    <span/>

                                                            }
                                                        </div>


                                                        : (title.type === "signature") ?
                                                            <div className="att-file">
                                                                {fieldValue !== '' &&
                                                                <img src={fieldValue}/>
                                                                }
                                                                {/*<span className="att-text"></span>*/}
                                                            </div>
                                                            :
                                                            <ReadMore>{fieldValue}</ReadMore>
                                                    }
                                                    {ind === 0 && Props.containerData.hasOwnProperty("edit") && Props.containerData.edit &&
                                                    <div className="wf-btn-block">
                                                        <button className="ico-lib wf-ed"
                                                                onClick={() => Props.addRecord(Props.collId, Props.showlistField, "edit", row.id)}>

                                                        </button>

                                                    </div>
                                                    }

                                                </div>
                                            </td>

                                        </React.Fragment>
                                    )
                                }
                            )}

                        </tr>
                    )}

                    </tbody>
                </table>
            </div>
        </div>
    )

}

export {ResultTable};

const ReadMore = ({children}) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p style={{"white-space": "normal", "max-width": "none", "min-width": "235px", "padding-right": "12px"}}>
            {isReadMore ? isNaN(text) ? text.slice(0, 100) : text : text}
            <span onClick={toggleReadMore} className={`${isReadMore ? 'read-or-hide' : ''}`} style={{
                color: "rgb(192,192,192)",
                cursor: "pointer"
            }}>
                {text.length > 100 &&
                <>
                    {isReadMore ? "...read more" : " show less"}
                </>
                }
        </span>
        </p>
    );
};
