import React, {Component} from 'react';
import {ES_INDEX} from "../../../constants";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {Datetimecomparison, getUrlSegment} from "../../../utilities/CustomFunctions";
import {getProfile} from "../../../utilities/AuthService";
import * as commonActions from "../../../actions/Common";
import Modal from "../../views/Modal";
import AddNewMilestone from "./AddNewMilestone";
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import AlertModal from "../../views/AlertModal";
import ProjectHeader from "../../views/ProjectHeader";


class ProjectInformation extends Component {

    constructor(props) {
        super(props);

        this.state={
            showOption : false,
            overviewtab:"Summary",
            activemilestone:false,
            completedmilestone:false,
            NewMilestoneModal:false,
            milestoneAction:'',
            project_id:'',
            Delopen:false,
            lastOffset: 0,
            totalitems: 0,
            logdetails: [],
            offset:10,
        }

        this.getProjectdetails = this.getProjectdetails.bind(this);
    }



    componentDidMount() {
        //this.setState({loading: 1});
        var prjctdtlid = getUrlSegment(4);
        this.setState({project_id:prjctdtlid})
        this.props.mainClick('project', '/project/details/information/'+prjctdtlid);
       // this.getProjectdetails();

        const container = document.querySelector('.pj-issue-details-wrapper');
        container.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        const container = document.querySelector('.pj-issue-details-wrapper');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.pj-issue-details-wrapper')
        let lastOffset = document.querySelector('.act-log-container').childElementCount;
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;

       //console.log(lastOffset)

        if (contentHeight <= scrollTop) {
            let moredata ={
                "projectid": this.state.project_id,
                "from": lastOffset,
                "size":this.state.offset,
                "filter": "log",
            }

            this.props.getmoreitems(moredata);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {



    }




    getProjectdetails(){
        var projectid = (atob(getUrlSegment(4)));
        //console.log(projectid);
        let data ={
            "_id": projectid,
        }
        this.props.projectdetails(data)
    }








    render() {
        var today = new Date();
        //console.log(today)
        var date = today.getDate();
        var month = today.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        var year = today.getFullYear();
        var curr_date = year + '-' + month + '-' + date ;
       // console.log(this.props.listAllProjectDetailData)
        let logdetails = this.props.ProjectLogData.logitems ? this.props.ProjectLogData.logitems :[];
        console.log(logdetails)
        return(

            <div className="form-detail-container data-view field-form">
               <ProjectHeader />
                <div className="pj-board-container">
                    <div className="pj-issue-details-wrapper">

                        <div className="project-activity-sec">
                            <div className="proj-tab-header">
                                <h5>Activity Logs</h5>
                            </div>
                            <div className="project-status">
                                <div className="act-log-container">
                                    {this.props.ProjectLogData.loading  &&
                                    <span className="Loader-holder" style={{'background':'transparent'}}>
                                                            <LoaderSvg/>
                                                        </span>
                                    }
                                    {logdetails.length === 0 &&
                                    <div className="full-no-data">
                                        <h5>There is no activity found in this month !</h5>
                                    </div>
                                    }
                                    {logdetails.map((log,index) =>
                                        <>
                                            {log.activity !== "Archive the Todo" &&
                                            <div className="ind-act-log finished-log" key={"log-"+index}>

                                                <h5>{log.activity}</h5>

                                                    {/*<p><span className="log-user">{log.creator}</span></p>*/}
                                                    {/*<p className="log-time">{Datetimecomparison(log.crtdate,today)}</p>*/}
                                                    <p onClick={(e)=>this.props.history.push('/task/details/'+btoa(log.contentid)+'/'+btoa("to-me"))}><span className="log-user">{log.creator}</span>
                                                        {/*finished task <span*/}
                                                        {/*className="highlight">Corporate clients corder a publication to be made</span>*/}
                                                    </p>



                                            </div>
                                            }
                                            {log.activity === "Archive the Todo" &&
                                            <div className="ind-act-log overdue-log" key={"log-"+index}>
                                                <h5>{log.activity}</h5>
                                                <a href="javascript:void(0)" onClick={(e)=>this.props.history.push('/task/details/'+btoa(log.contentid)+'/'+btoa("to-me"))}>
                                                    <p>{log.creator}</p>
                                                    <p className="log-time">{Datetimecomparison(log.crtdate,today)}</p>
                                                </a>
                                            </div>
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}
const mapStateToProps = state => ({
    //listAllProjectDetailData:state.ProjectReducer.listallProjectsdetails,
    ProjectLogData:state.ProjectReducer.ProjectInfo,
    mileStoneData: state.ProjectReducer.milestonelist,
    savenewmilestoneDet : state.ProjectReducer.SaveMilestone,
    milestondedata:state.ProjectReducer.GetMilestone

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    projectdetails:projectActions.listallprojectsdetails,
    getmilestonelist : projectActions.milestoneList,
    getmoreitems : projectActions.moreItems,
    getmilesonedetails:projectActions.getMilestoneInfo,
    deleteProject:projectActions.DeleteProject
})(ProjectInformation));
