import React from "react";
import {USRIMG_PATH,S3_URL,FRMSIMG_PATH,FRMSDOC_PATH,FRMSVID_PATH} from "../../../constants";
import {getProfile} from "../../../utilities/AuthService";
import moment from "moment";
import {Scrollbars} from "react-custom-scrollbars";
import {
    forceDownload,
    formatBytes,
    getFileformat,
    isImage,
    isAudio,
    isVideo,
    urlify,
    getFileExtension
} from "../../../utilities/CustomFunctions";
import OutsideAlerter from "../../views/OutsideAlerter";
import {Tab, Tabs} from "react-bootstrap";
const PdfViewer = React.lazy(() =>  import('../../views/PdfViewer'))
function MessageHeadLine(list){
    return(
        <div className="chat-start-box">
            {list.chatUserId.name &&
            <p className="chat-starter-text">This is the very beginning of your direct message history with {list.chatUserId.chatType === 'single' ? list.chatUserId.name : atob(list.chatUserId.name)}</p>
            }
        </div>
    )
}
function UserImagePanel(list) {
    if (list.showImage)
        return (
            <figure className="user-image-holder">
                <img src={USRIMG_PATH + list.userImage}
                     alt="user-image"
                     className="chat-user-image-in"
                />
            </figure>
        )
    else
        return null
}
// function MessagePanel(list) {
//     return (
//
//                 <div id={list.chat_id} className={`chat-message-detail${!list.showImage && 'sub-section'}`}>
//
//                     {list.showImage &&
//                     <h5 className={`chat-user-name ${getProfile().id === list.sender ? 'brown' : 'purple'}`}>{list.creator}</h5>
//                     }
//                     <span className="chat-time-in">{moment.utc(list.createdate).local().format('LT')}</span>
//                     {list.urlify ?
//                         <p className="chat-message-in"
//                            dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(urlify(atob(list.message))))}}></p>
//                         :
//                         <p className="chat-message-in"
//                            dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(atob(list.message)))}}></p>
//                         // <p className="chat-message-in">{atob(list.message)}</p>
//                     }
//                     {/*{list.has_whatsapp &&*/}
//                     {/*<div className="buisiness-reply-box">*/}
//                     {/*    <p className={`chat-com ico-lib pin-cl`}*/}
//                     {/*       onClick={() => list.whatsappReply(list.creator, list.message)}></p>*/}
//                     {/*    <p className={`ico-lib icon-task-assign`}*/}
//                     {/*       onClick={() => list.createTak(list.creator, list.message)}></p>*/}
//                     {/*</div>*/}
//                     {/*}*/}
//
//                 </div>
//
//     )
// }

class MessagePanel extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            chat_id:""
        }
    }
    render(){
        let list=this.props
        return(
            <div id={list.chat_id} key={`chat`+this.props.chat_id} className={`chat-message-detail${!list.showImage && 'sub-section'} with-more-option ${this.props.schat_id === this.props.chat_id?"option-sticky":""}`}>

                {list.hasOwnProperty('chatType') && list.chatType === "single" &&
                <div className="wa-aside-option-block">
                    <button
                        className={`ico-lib-v1 wa-more-option-tog active`}
                        onClick={() => this.props.showMsgActionMenu(this.props.chat_id)}
                        title="Menu">

                    </button>


                    {this.props.schat_id === this.props.chat_id && !this.props.aTask &&
                    <ul className={`wa-aside-option-menu`} key={`chatOpt` + this.props.chat_id}>
                        <li onClick={() => this.props.singleTask(list.creator, list.message)}>
                            <nav>Assign as Task</nav>
                        </li>
                    </ul>
                    }

                </div>
                }

                {list.showImage &&
                <h5 className={`chat-user-name ${getProfile().id === list.sender ? 'brown' : 'purple'}`}>{list.creator}</h5>
                }
                <span className="chat-time-in">{moment.utc(list.createdate).local().format('LT')}</span>
                {list.urlify ?
                    <p className="chat-message-in"
                       dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(urlify(atob(list.message))))}}></p>
                    :
                    <p className="chat-message-in"
                       dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(atob(list.message)))}}></p>
                    // <p className="chat-message-in">{atob(list.message)}</p>
                }

                {/*<div className="buisiness-reply-box">*/}
                {/*    /!*<p className={`chat-com ico-lib pin-cl`}*!/*/}
                {/*    /!*   onClick={() => list.whatsappReply(list.creator, list.message)}></p>*!/*/}
                {/*    <p className={`ico-lib icon-task-assign`}*/}
                {/*       onClick={() => list.createTak(list.creator, list.message)}></p>*/}
                {/*</div>*/}


            </div>
        )
    }
}


function WhatsappBox(list) {
    if(list.showMessageBox) {
        let imageData=list.imageData[0] ||[];
        let dataValue1=list.dataValue1[0] ||[];
        let dataValue2=list.dataValue2[0] ||[];
        let dataValue3=list.dataValue3[0] ||[];

        return (
            <>
             {list.whatsappinfo.length === 0 ?
             <div id={list.chat_id} className={`message-in-box ${!list.showImage ? 'sub-disscussion' : ''}`} key={`message-inbox-details-card` + list.index}>
                <UserImagePanel
                    userImage={list.userhead}
                    showImage={list.showImage}
                />
                <div className={`chat-message-detail${!list.showImage && 'sub-section'}`}>
                    {list.showImage &&
                    <React.Fragment>
                        <h5 className={`chat-user-name ${getProfile().id === list.sender ? 'brown' : 'purple'}`}>{list.creator}</h5>
                        {list.has_whatsapp === "yes" &&
                        <div className="info-text-block">
                            <button className="info-ico-layer" title="info" onClick={() => list.cardDetails(list.groupid, list.creator,list.chat_id)}></button>
                            {(list.showcardDetails === list.chat_id) &&
                            <OutsideAlerter status={list.showcardDetails} Triggered={() => list.cardDetails()}>
                            <div className="info-layer">
                                <div className="wa-chat-thumb">
                                   {imageData.value && imageData.value.map ((val,index)=> <img src={USRIMG_PATH+val.uniqvalue}/>)}

                                </div>
                                <div className="info-layer-details">
                                    <p>{dataValue1.label}: {dataValue1.value && dataValue1.value.map ((val,index)=><span key={index}>{val.value}</span>)}</p>
                                    <p>{dataValue2.label} : {dataValue2.value && dataValue2.value.map ((val,index)=><span key={index}>{val.value}</span>)}</p>
                                    <p>{dataValue3.label}: {dataValue3.value && dataValue3.value.map ((val,index)=><span key={index}>{val.value}</span>)}</p>
                                </div>
                            </div>
                            </OutsideAlerter>
                            }
                            </div>
                        }
                    </React.Fragment>
                    }
                    <span className="chat-time-in">{moment.utc(list.createdate).local().format('LT')}</span>
                    {list.urlify ?
                        <p className="chat-message-in"
                           dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(urlify(atob(list.message))))}}></p>
                        :
                        <p className="chat-message-in"
                           dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(atob(list.message)))}}></p>
                        // <p className="chat-message-in">{atob(list.message)}</p>
                    }


                    <div className="buisiness-reply-box reply-box">

                        <p className={`ico-lib icon-task-assign`}
                           onClick={() => list.createTak(list.creator, list.message)}>Assign Task</p>
                        {list.has_whatsapp === "yes" &&
                        <p className={`chat-com ico-lib-v1 reply-cl`}
                           onClick={() => list.whatsappReply(list.creator, list.message, list.index)}>Reply</p>

                        }
                    </div>

                    {list.whatsappinfo.length !== 0 &&
                    <div>
                        {list.whatsappinfo[0].replyto !== "" &&
                        <>
                            <p>{list.whatsappinfo[0].whatsappno}</p>
                            <p>{list.whatsappinfo[0].replyto}</p>
                        </>
                        }
                        {list.whatsappinfo[0].taskinfo !== "" &&
                        <>
                            <p>{list.whatsappinfo[0].whatsappno}</p>
                            <p>{list.whatsappinfo[0].taskinfo}</p>
                        </>
                        }
                    </div>

                    }



                </div>
            </div>
                 :
                 <div className="message-in-box reply-msg " onClick={()=>list.chatPnt(list.chat_id)}>
                     <UserImagePanel
                         userImage={list.userhead}
                         showImage={list.showImage}
                     />

                     <div className="chat-message-detailfalse">
                         <h5 className={`chat-user-name ${getProfile().id === list.sender ? 'brown' : 'purple'}`}>{list.creator}</h5>

                         <span className="chat-time-in">{moment.utc(list.createdate).local().format('LT')}</span>
                         <div className="message-in-box ">
                             <figure className="user-image-holder">
                                 {list.has_whatsapp === "yes" &&
                                 <img src="https://bsynapse.imgix.net/images/fn1595809363356.jpg" alt="user-image"
                                      className="chat-user-image-in"/>
                                 }
                             </figure>
                             {list.whatsappinfo.length !== 0 &&
                             <div className="chat-message-detailfalse">
                                 <h5 className="chat-user-name purple">{list.whatsappinfo[0].whatsappno}</h5>
                                 {list.urlify ?
                                     <p className="chat-message-in"
                                        dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(urlify(atob(list.message))))}}></p>
                                     :
                                     <p className="chat-message-in"
                                        dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(atob(list.message)))}}></p>
                                     // <p className="chat-message-in">{atob(list.message)}</p>
                                 }
                             </div>
                             }

                         </div>

                         {list.whatsappinfo.length !== 0 && list.whatsappinfo[0].replyto !== "" &&
                         <p className="chat-message-in">
                             {list.whatsappinfo[0].replyto}
                         </p>
                         }
                         {list.whatsappinfo.length !== 0 && list.whatsappinfo[0].taskinfo !== "" &&
                         <p className="chat-message-in" onClick={()=>list.history.push(list.whatsappinfo[0].taskinfo)}>
                           Go to task
                         </p>
                         }

                     </div>



                 </div>



             }

            </>

        )
    }
    else
        return null
}



function MetaInfoPanel(list) {
    return (
        <div className="message-in-box url-panel" id={list.chat_id}>
            {list.metainfo.map((meta, index) =>
                <div className="url-block ind-disscussion-sub url-lk" key={`meta-info-chat` + index}>

                    <div className="url-details">
                        <p className="site_name">{meta.siteName}</p>
                        {meta.pageTitle !== '' &&
                        <p>
                            <a href={meta.pageUrl} target={`_blank`}><span
                                className="title">{meta.pageTitle}</span></a>
                        </p>
                        }
                        {meta.pageDescription !== '' &&
                        <p className="description">{meta.pageDescription}</p>
                        }
                        <p className="time"></p>
                    </div>

                    <div className="url-img">
                        <img className="image"
                             src={meta.pageImage === '' ? 'https://lsinternalcopy.bsynapse.com/assets/images/website-icon.png' : meta.pageImage}/>
                    </div>
                </div>
            )}
        </div>
    )

}
function AttachmentPanel(list) {
    console.log(list)
    return (
        <div className='file-bl-wrap' id={list.chat_id}>
            {list.attachment.map((meta, index) => {
                    var albumName;
                    if (isImage(meta.filename)) {
                        albumName = FRMSIMG_PATH
                    } else if (isVideo(meta.filename)) {
                        albumName = FRMSVID_PATH
                    }
                    else if (isAudio(meta.filename)) {
                        albumName = FRMSDOC_PATH
                    }
                    else {
                        albumName = FRMSDOC_PATH
                    }
               let filename= meta.filetitle ? meta.filetitle : meta.name
                    return (
                        <span key={`attachmet-data-details` + index}>
                           {isImage(meta.filename) ?
                               <div id="attachement-panel"
                                    className="attach-chat-block"
                                    key={`attachment-panel-block` + index}>
                                   <>
                                       <div className="attachment-block-left"
                                            onClick={() => list.preview(albumName + meta.filename, meta.filetitle,"image")}>
                                           <img
                                               src={albumName + meta.filename}/>
                                       </div>
                                       <div className="attachment-block-right"
                                            onClick={() => list.preview(albumName + meta.filename, meta.filetitle)}>
                                           <h5>{meta.filetitle ? meta.filetitle : meta.name}</h5>
                                           <p>{formatBytes(parseInt(meta.filesize))}
                                               <span
                                                   className="format-span">{getFileformat(meta.filename)}</span>
                                               <span
                                                   className="format-view"

                                               >- Click to view</span>
                                           </p></div>
                                       {list.type===`mainchat` && meta.approved === "" && meta.creatorid.toString() !==getProfile().id.toString() &&
                                       <div className="approve-block">
                                           <button className="general-btn grey" onClick={()=>list.chatmediaStatus({"_id": meta.id,"id":list.chat_id,"approvedstatus": "no",uid:getProfile().id,type:list.ChatType})}>Reject</button>
                                           <button className="general-btn" onClick={()=>list.chatmediaStatus({"_id": meta.id,"id":list.chat_id,"approvedstatus": "yes",uid:getProfile().id,type:list.ChatType})}>Approve</button>
                                       </div>
                                       }

                                       {list.type===`mainchat` && meta.approved !== "" &&
                                       <div className="approve-block">
                                           {meta.approved === "yes"?"Approved":"Rejected"}
                                       </div>
                                       }
                                   </>
                               </div>
                               :
                               <>
                                   {getFileformat(meta.filename).toLowerCase() === 'pdf' ?
                                       <div id="attachement-panel"
                                            className="auto-height attach-chat-block pdf-block"
                                            key={`pdf-block` + index}>
                                           <div className={`pdf-preview-holder`}>
                                               <div
                                                   className="attachment-block-left"  onClick={() => forceDownload(albumName + meta.filename, filename)}>
                                                   <span
                                                       className={`format icon-panel ${getFileformat(filename).toLowerCase()}`}
                                                       aria-hidden="true">{getFileformat(filename)}</span>

                                               </div>
                                               <div
                                                   className="attachment-block-right">
                                                   <h5>{filename}</h5>
                                                   <p>{formatBytes(parseInt(meta.filesize))} &nbsp;
                                                       <span
                                                           className="format-span">{getFileformat(filename)}  </span>
                                                       <span
                                                           className="format-view"
                                                           onClick={() => list.preview(albumName + meta.filename,filename,"pdf")}>- Click to view</span>
                                                   </p>

                                                   <div className={'preview-btn-block'}>
                                                       <button className={'ico-lib-v1 down-ico'} onClick={() => forceDownload(albumName + meta.filename, filename)}/>
                                                       {/*<button className={'ico-lib-v1 preview-ico'}></button>*/}
                                                   </div>

                                               </div>

                                           </div>
                                           <div className={`pdf-preview-wrapper`}>

                                               <PdfViewer
                                                   fileUrl={albumName + meta.filename}
                                                   fileType={getFileformat(filename).toLowerCase()}
                                               />

                                           </div>
                                           {list.type===`mainchat` && meta.approved === "" && meta.creatorid.toString() !==getProfile().id.toString() &&
                                           <div className="approve-block">
                                               <button className="general-btn grey" onClick={()=>list.chatmediaStatus({"_id": meta.id,"id":list.chat_id,"approvedstatus": "no",uid:getProfile().id,type:list.ChatType})}>Reject</button>
                                               <button className="general-btn" onClick={()=>list.chatmediaStatus({"_id": meta.id,"id":list.chat_id,"approvedstatus": "yes",uid:getProfile().id,type:list.ChatType})}>Approve</button>
                                           </div>
                                           }
                                           {list.type===`mainchat` && meta.approved !== "" &&
                                           <div className="approve-block">
                                               {meta.approved === "yes"?"Approved":"Rejected"}
                                           </div>
                                           }
                                       </div>
                                       :
                                       <>
                                           <div id="attachement-panel"
                                                className="attach-chat-block"
                                                key={`attach-block` + index}>
                                               <div
                                                   className="attachment-block-left"  onClick={() => forceDownload(albumName + meta.filename, filename)}>
                                                   <span
                                                       className={`format   ${getFileformat(filename).toLowerCase()}`}
                                                       aria-hidden="true">{getFileformat(filename).toLowerCase()}</span>

                                               </div>
                                               <div className="attachment-block-right">
                                                   <h5>{filename}</h5>
                                                   <p>{formatBytes(parseInt(meta.filesize))}&nbsp;&nbsp;
                                                       <span
                                                           className="format-span">{getFileformat(filename)}  </span>

                                                       <span
                                                           className="format-view"
                                                           onClick={() => forceDownload(albumName + meta.filename, filename)}>- Click to Download</span>
                                                   </p>
                                               </div>
                                               {list.type===`mainchat` && meta.approved === "" && meta.creatorid.toString() !==getProfile().id.toString() &&
                                               <div className="approve-block">
                                                   <button className="general-btn grey" onClick={()=>list.chatmediaStatus({"_id": meta.id,"id":list.chat_id,"approvedstatus": "no",uid:getProfile().id,type:list.ChatType})}>Reject</button>
                                                   <button className="general-btn" onClick={()=>list.chatmediaStatus({"_id": meta.id,"id":list.chat_id,"approvedstatus": "yes",uid:getProfile().id,type:list.ChatType})}>Approve</button>
                                               </div>
                                               }
                                               {list.type===`mainchat` && meta.approved !== "" &&
                                               <div className="approve-block">
                                                   {meta.approved === "yes"?"Approved":"Rejected"}
                                               </div>
                                               }
                                           </div>
                                       </>
                                   }
                               </>
                           }
                        </span>
                    )
                }
            )}
        </div>
    )
}
function MessageBox(list) {
    if (list.showMessageBox)
        return (
            <div id={list.chat_id} className={`message-in-box ${list.msg_ids.includes(list.chat_id)?"selected":""} ${!list.showImage ? 'sub-disscussion' : ''}`}
                 key={`message-inbox-details-card` + list.index}>
                {list.aTask &&
                <div className="select-chk-block" style={{display:"block"}}>
                    <label className="control control-checkbox">
                        <input type="checkbox" className={`inp-chk`} value={list.chat_id} checked={list.msg_ids.includes(list.chat_id)}
                               onChange={list.handleCheckboxChange}/>
                        <div className="control-indicator"></div>
                    </label>
                    </div>

                }

                <UserImagePanel
                    userImage={list.userhead}
                    showImage={list.showImage}
                    chat_id={list.chat_id}
                    schat_id={list.schat_id}
                />
                <MessagePanel
                    showImage={list.showImage}
                    sender={list.sender}
                    creator={list.creator}
                    createdate={list.createdate}
                    message={list.message}
                    has_whatsapp={list.has_whatsapp}
                    whatsappinfo={list.whatsappinfo}
                    whatsappReply={list.whatsappReply}
                    createTak={list.createTak}
                    schat_id={list.schat_id}
                    chat_id={list.chat_id}
                    msg_ids={list.msg_ids}
                    showMsgActionMenu={list.showMsgActionMenu}
                    singleTask={list.singleTask}
                    aTask={list.aTask}
                    chatType={list.chatType}
                />
            </div>
        )
    else
        return null;
}
function FloatMessageBox(list) {

    let userType=(parseInt(list.createuser) === parseInt(getProfile().id) )|| (list.hasOwnProperty("user")&& list.user === getProfile().id)? "sender":"reciever"
    if (list.showMessageBox)
        return (

                <div className={`ind-chat-module ${userType === "sender"? "sender":"reciever"}`}>
                    {userType === "sender" &&
                    <FloatUserhead
                        userImage={list.userhead}
                        showImage={list.showImage}
                        creator={list.creator}
                        createdate={list.createdate}
                    />
                    }
                     <FloatUserMessage
                         message={list.message}
                         urlify={list.urlify}
                     />
                    {userType === "reciever" &&
                    <FloatUserhead
                        userImage={list.userhead}
                        showImage={list.showImage}
                        creator={list.creator}
                        createdate={list.createdate}
                    />
                    }

                </div>

        )
    else
        return null;
}
function FloatUserhead(Props) {
    return(
        <div className="chat-user-block">
            <div className="chat-user">
                {Props.showImage &&
                <div className="chat-thumb">
                    <img src={USRIMG_PATH + Props.userImage} alt="user-image"/>
                </div>
                }
                <div className="chat-user-info">
                    <h5>{Props.showImage?Props.creator:""}</h5>
                    <p>{moment.utc(Props.createdate).local().format('LT')}</p>
                </div>
            </div>
        </div>
    )
}
function FloatUserMessage(Props) {
    return(
        <div className="chat-user-message">
            {Props.urlify ?
                <h5 dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(urlify(atob(Props.message))))}}></h5>
                :
                <h5 dangerouslySetInnerHTML={{__html:decodeURIComponent(escape(atob(Props.message)))}}></h5>
            }
        </div>
    )
}
function MetaBox(list) {
    if(list.showMessageBox)
    return (
        <span key={`meta-url` + list.index}>
            <div className="message-in-box" key={`message-inbox` + list.index}>
                    {list.showImage &&
                    <UserImagePanel
                        userImage={list.userhead}
                        showImage={list.showImage}
                    />
                    }
                <MessagePanel
                    showImage={list.showImage}
                    sender={list.sender}
                    creator={list.creator}
                    createdate={list.createdate}
                    message={list.message}
                    urlify={true}
                    schat_id={list.schat_id}
                    chat_id={list.chat_id}
                    msg_ids={list.msg_ids}
                    showMsgActionMenu={list.showMsgActionMenu}
                    singleTask={list.singleTask}
                    aTask={list.aTask}
                />
            </div>
            <MetaInfoPanel
                metainfo={list.metainfo}
            />

       </span>
    )
    else
        return null;
}
function FloatMetaBox(list) {
    if(list.showMessageBox)
        return (
            <span key={`meta-url` + list.index}>
            <div className="message-in-box" key={`message-inbox` + list.index}>
                    {list.showImage &&
                    <FloatUserhead
                        userImage={list.userhead}
                        showImage={list.showImage}
                        creator={list.creator}
                        createdate={list.createdate}
                    />
                    }
                <FloatUserMessage
                    message={list.message}
                    urlify={true}
                />
            </div>
            <MetaInfoPanel
                metainfo={list.metainfo}
            />

       </span>
        )
    else
        return null;
}
function AttachmentBox(list) {
    if(list.showMessageBox)
        return (
            <span key={`has-attachmet-chat` + list.index}>
            <div className="message-in-box">
                <UserImagePanel
                    userImage={list.userhead}
                    showImage={list.showImage}
                />
                <MessagePanel
                    showImage={list.showImage}
                    sender={list.sender}
                    creator={list.creator}
                    createdate={list.createdate}
                    message={btoa(atob(list.message)+"<br>"+(list.attachment.length === 1 ? list.attachment.length + ' ' + 'file' : list.attachment.length + ' ' + 'files'))}
                    urlify={false}
                    schat_id={list.schat_id}
                    chat_id={list.chat_id}
                    msg_ids={list.msg_ids}
                    showMsgActionMenu={list.showMsgActionMenu}
                    singleTask={list.singleTask}
                    aTask={list.aTask}

                />
            </div>
               <AttachmentPanel
                   attachment={list.attachment}
                   preview={list.preview}
                   chatmediaStatus={list.chatmediaStatus}
                   chat_id={list.chat_id}
                   type={list.type}
                   ChatType={(list.ChatType === "group"?"Group_chat":"One_to_One")}
               />
      </span>
        )
    else
        return null;
}
function FloatAttachmentBox(list) {
    if(list.showMessageBox)
    return (
        <span key={`has-attachmet-chat` + list.index}>
            <div className="message-in-box">
                <FloatUserhead
                    userImage={list.userhead}
                    showImage={list.showImage}
                    creator={list.creator}
                    createdate={list.createdate}
                />
                 <FloatUserMessage
                     message={btoa(atob(list.message)+"<br>"+(list.attachment.length === 1 ? list.attachment.length + ' ' + 'file' : list.attachment.length + ' ' + 'files'))}
                     urlify={false}
                 />
               </div>
               <AttachmentPanel
                   attachment={list.attachment}
                   preview={list.preview}
               />
      </span>
    )
    else
        return null;
}
function MessageInitialScreen() {
  return( <div className="chat-panel-holder" key={`chat-panel-holder-no-data`}>
        <div className="chat-all-wrapper">
            <div className="chat-in-panel">
                <div id="default-txt" className="def-text">
                    <div className="full-center">
                        <h5>
                            Here is your <span>SMART CHAT</span>
                        </h5>
                        <p>You can select or mention <span className="font-bold">Users</span>, <span
                            className="font-bold">Groups</span> and start a chat with them </p>
                    </div>
                </div>
            </div>
        </div>
    </div>)

}
function AttachmentPopupPreview(list) {

    return (
        <div className='file-upload-lister'>

            {list.files.map((attachment,index)=>


                                <div id="attachement-panel" className="attach-chat-block">
                                    <button onClick={() => list.removeFile(index)} className={"attach-close-pop"}/>
                                    <div className="attachment-block-left non-download">
                                        <span className={`format ${getFileformat(attachment.name)}`} aria-hidden="true">{getFileformat(attachment.name)}</span>
                                    </div>
                                    <div className="attachment-block-right">
                                        <h5>{attachment.name}</h5>
                                        <p>{formatBytes(attachment.size)}&nbsp;&nbsp;
                                            <span className="format-span">{getFileformat(attachment.name)} </span>
                                        </p>
                                    </div>
                                </div>

            )}

        </div>
    )
}


class GetChatMedia extends React.Component{

    render(){
        return(
            <div className="chat-right-tab">
                <Tabs defaultActiveKey="Files">
                    <Tab eventKey="Files" title="Files">
                        <div className="chat-file-wrapper">
                            <div className="chat-file-header">
                                <p>{this.props.listChatmedia.total} Files</p>
                                <div className="chat-file-filter">
                                    {/*<nav className="active">All</nav>*/}
                                    {/*<nav className="active">Approved</nav>*/}
                                </div>
                            </div>
                            <div className="chat-file-body">
                            <Scrollbars autoHide autoHideTimeout={1000}>

                                {this.props.listChatmedia.media.map((list,index)=> {
                                    var albumName;
                                    if (isImage(list.filename)) {
                                        albumName = 'images/'
                                    } else if (isVideo(list.filename)) {
                                        albumName = 'videos/'
                                    } else {
                                        albumName = 'documents/'
                                    }
                                      return ( <div className="each-chat-file" key={`list-index` + index} onClick={() => forceDownload(S3_URL + albumName + list.filename, list.filetitle)}>
                                            <span className={`format ${getFileExtension(list.filename)}`}>{getFileExtension(list.filename)}</span>
                                            <div className="chat-file-details">
                                                <h5>{list.filetitle}</h5>
                                                <ul>
                                                    <li>{moment().utc(list.createdate).local().format("Do MMM, YYYY")}, {list.creator}</li>
                                                    {(list.approved === "yes" || list.approved === "no") &&
                                                    <li className="approved">
                                                        <span>{list.approved === "yes" ? "Approved" : "Rejected"}</span>: {moment().utc(list.approveddate).local().format("Do MMM, YYYY")}, {list.approveduser}
                                                    </li>
                                                    }

                                                </ul>
                                                {/*<span className="file-version">ver 1.0</span>*/}

                                            </div>
                                        </div>)
                                    }
                                )}


                                {/*<div className="each-chat-file">*/}
                                {/*    <span className="format pdf">ppt</span>*/}
                                {/*    <div className="chat-file-details">*/}
                                {/*        <h5>Electric Drawing.pdf</h5>*/}
                                {/*        <ul>*/}
                                {/*            <li>28 May 20201, Dhanya</li>*/}
                                {/*            <li className="approved"><span>Approved</span>: 29 May 20201, Alex</li>*/}
                                {/*        </ul>*/}
                                {/*        <span className="file-version">ver 1.0</span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                </Scrollbars>
                            </div>
                        </div>
                    </Tab>
                    {/*<Tab eventKey="ToDo" title="ToDo">*/}
                    {/*    b*/}
                    {/*</Tab>*/}
                    {/*<Tab eventKey="Links" title="Links">*/}
                    {/*    c*/}
                    {/*</Tab>*/}
                </Tabs>
            </div>

        )
    }
}


export {MessageBox,GetChatMedia, MetaBox,FloatMetaBox,WhatsappBox,FloatAttachmentBox, AttachmentBox,MessageInitialScreen,MessageHeadLine,AttachmentPopupPreview,FloatMessageBox}
