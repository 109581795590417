import {Datecomparison, Datecomparisondue} from "../../utilities/CustomFunctions";
import {Draggable} from "react-beautiful-dnd";
import {USRIMG_PATH} from "../../constants";
import React, {Component} from "react";
import moment from "moment";
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    //background: isDragging ? "#F1F1F1" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});
class ProjectTaskCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openSection: ['unorg'],
        }
    }

    render() {
        let index = this.props.index;
        let task = this.props.task
        var curr_date = moment.utc().local().format('YYYY-MM-DD')
        var due_date=moment.utc(task.duedate).local().format('YYYY-MM-DD')
        let due = due_date !== '0000-00-00 00:00:00' ? Datecomparisondue(due_date, curr_date, 'task') : '';
        let Title = task.subject === '' ? atob(task.Title) : atob(task.subject);
        return (
            <Draggable key={task.masterid}
                       draggableId={task.masterid}
                       index={index}>
                {(provided, snapshot) => (
                    <div className="ind-task-list"
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                         style={getItemStyle(
                             snapshot.isDragging,
                             provided.draggableProps.style
                         )}>
                        <a onClick={(e) => this.props.history.push('/task/details/' + btoa(task.masterid) + '/' + btoa('to-me'))}
                           className="ind-task-list-click">
                            <div className="ind-task-list-left">
                                <div
                                    className="select-chk-block">
                                    <label
                                        className="control control-checkbox">
                                        <input type="checkbox"
                                               className="inp-chk"/>
                                        <div
                                            className="control-indicator"></div>
                                    </label>
                                </div>
                                <div
                                    className="task-priority-block">
                                                                                                    <span
                                                                                                        className={`task-priority  ${task.priority.toLowerCase()}`}>{task.priority.charAt(0)}</span>
                                    <span
                                        className="gen-tooltip cl-m">{task.priority}</span>
                                </div>
                                <div
                                    className="task-user-block">
                                                                                                    <span
                                                                                                        className="task-user">{task.assigneeuser_fname}</span>
                                    <span
                                        className="mobile-com-count">3</span>
                                    <div
                                        className="user-popover-block">
                                        <div
                                            className="user-popover-left">
                                            <div
                                                className="user-popover-img online">
                                                <img
                                                    src={USRIMG_PATH + task.assigneeuser_img_name}/>
                                            </div>
                                            <div
                                                className="user-popover-btn-block">
                                                <button
                                                    className="commun-button ico-lib chat-ico">
                                                    Chat
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="user-popover-right">
                                            <div
                                                className="user-profile-details">
                                                <h4 className="subheader">{task.assigneeuser_fname} {task.assigneeuser_lname}</h4>
                                                <ul className="user-profile-det-list">
                                                    <li className="ico-lib prof-user-ico">{task.assigneeuser_designation}</li>
                                                    <li className="ico-lib mail-ico">{task.assigneeuser_email}</li>
                                                    {task.assigneeuser_mob !== "" &&
                                                    <li className="ico-lib phone-ico">{task.assigneeuser_mob}
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="ind-task-list-center">
                                {task.reassign === 'yes' &&
                                <span
                                    className="ico-lib subtask-ico"></span>}
                                <span
                                    className="task-text">{Title.charAt(0).toUpperCase() + Title.slice(1)}</span>
                            </div>
                            <div
                                className="ind-task-list-right">
                                <div
                                    className="task-more-block">
                                    {/*<div className="more-ico comment-info">
                                                                                                        <span className="ico-lib comment-ico"></span>
                                                                                                        <span className="counter">3</span>
                                                                                                    </div>*/}
                                    {task.has_attachemnt === 'yes' &&
                                    <span
                                        className="more-ico ico-lib task-attach"></span>}
                                </div>
                                <span className={`status-block ${task.status.toLowerCase()}`}>{task.status}</span>
                                {(task.status === 'New' || task.status === 'InProgress') &&
                                <div className={`due-block ${moment(curr_date).isAfter(due_date) ? 'due' : ''}`}>{due}</div>
                                }
                                {task.status === 'Done'  &&
                                <div className={`due-block ${moment(curr_date).isAfter(due_date) ? 'due' : ''}`}></div>
                                }

                                <div
                                    className={"due-block " + (due === 'overdue' ? 'in-due' : '')}>{due}</div>
                            </div>
                        </a>
                    </div>
                )}
            </Draggable>
        )
    }
}
export default ProjectTaskCard;

