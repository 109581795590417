import {combineReducers} from 'redux';
import moment from 'moment'

const initialState = {
    data: [],
    statuswise_in: [],
    statuswise_out: [],
    statuswise_manage: [],
    statuswise_todayin: [],
    statuswise_tomorrowin: [],
    statuswise_monthin: [],
    statuswise_overduein: [],
    statuswise_comments: [],
    statuswise_know: [],
    statuswise_archive: [],
    status: "",
    data1:[]
}
const listtoDo = (state = {
    toDo: initialState,
    ToFollowUp: initialState,
    ToVerify: initialState,
    ToKnow: initialState,
    ToOversee: initialState,
    ToDraft: initialState,
    ToSchedule: initialState,
    ToComment: initialState,
    ToRespond:initialState,
    ToDueToday: initialState,
    ToDueInSevenDays: initialState,
    ToDueTomorrow: initialState,
    ToDueThisMonth: initialState,
    AllOverDues: initialState,
    AllArchived: initialState,
    thirtyToSixtyDays:initialState,
    fifteenTothirtydays:initialState,
    sevenTofourteendays:initialState,
    oneTosevendays:initialState,
}, action) => {
    var _newState;
    _newState = {...state};
    var resultdata;
    switch (action.type) {

        case "TO-ME":
            _newState = {...state};
            if (_newState[`toDo`]['data'].length !== 0) {
                if(_newState[`toDo`]['data'].filter(list => list.masterid === action.masterid).length !==0) {
                    _newState[`toDo`]['data'].filter(list => list.masterid === action.masterid)[0]["inner_hits"]["childtodo"]["hits"]["hits"][0]["_source"]["readstatus"] = "read"
                }
            }
            return _newState;
            break;
        case "BY-ME":
            _newState = {...state};
            if (_newState[`ToFollowUp`]['data'].length !== 0) {
                if(_newState[`ToFollowUp`]['data'].filter(list => list.masterid === action.masterid).length !==0) {
                    _newState[`ToFollowUp`]['data'].filter(list => list.masterid === action.masterid)[0]["readstatus"] = "read"
                }
            }
            return _newState;
            break;
        case "MANAGE-TASK":
            _newState = {...state};
            if (_newState[`ToOversee`]['data'].length !== 0) {
                _newState[`ToOversee`]['data'].filter(list => list.masterid === action.masterid)[0]["inner_hits"]["childtodo"]["hits"]["hits"][0]["_source"]["readstatus"]="read"
            }
            return _newState;
            break;





        case "LIST_TODO_DATA":
            return {
                ...state,
                toDo: {
                    ...state.toDo,
                    data : action.taskData.content,
                    toverified : action.taskData.toverified
                }
            };
            break;
        case "UPDATE_TO_DO_DATA":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results)
            if (_newState[`toDo`]['data'].length !== 0)
                _newState[`toDo`]['data'] = resultdata.concat(_newState.toDo.data)
            return _newState;
            break;

        case "LOADMORE_TODO_DATA":
            _newState = {...state};
            resultdata = []
            _newState[`toDo`]['data'] = (_newState.toDo.data).concat(action.taskData.content)
            return _newState;
            break;
        case "LOADMORE_FOLLOW_UP_DATA":
            _newState = {...state};
            resultdata = []
            _newState[`ToFollowUp`]['data'] = (_newState.ToFollowUp.data).concat(action.taskData.content)
            return _newState;
            break;
        case "LOADMORE_OVERSEE_DATA":
            _newState = {...state};
            resultdata = []
            _newState[`ToOversee`]['data'] = (_newState.ToOversee.data).concat(action.taskData.content)
            return _newState;
            break;
        case "LOADMORE_TOVERIFY_DATA":
            _newState = {...state};
            resultdata = []
            _newState[`ToVerify`]['data'] = (_newState.ToVerify.data).concat(action.taskData.content)
            return _newState;
            break;
        case "LIST_FOLLOW_UP_DATA":
            return {
                ...state,
                ToFollowUp: {
                    ...state.ToFollowUp,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_DRAFT_DATA":
            return {
                ...state,
                ToDraft: {
                    ...state.ToDraft,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_SCHEDULE_DATA":
            return {
                ...state,
                ToSchedule: {
                    ...state.ToSchedule,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_THIRTY_TO_SIXTY_DAYS":
            return {
                ...state,
                thirtyToSixtyDays: {
                    ...state.thirtyToSixtyDays,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_ONE_TWO_SEVEN_DAYS":
            return {
                ...state,
                oneTosevendays: {
                    ...state.oneTosevendays,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_SEVEN_TO_FOURTEEN_DAYS":
            return {
                ...state,
                sevenTofourteendays: {
                    ...state.sevenTofourteendays,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_FIFTEEN_TO_THIRTY_DAYS":
            return {
                ...state,
                fifteenTothirtydays: {
                    ...state.fifteenTothirtydays,
                    data : action.taskData.content
                }
            };
            break;
        case "UPDATE_FOLLOW_UP_DATA":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results)
            if (_newState[`ToFollowUp`]['data'].length !== 0)
                _newState[`ToFollowUp`]['data'] = resultdata.concat(_newState.ToFollowUp.data)
            return _newState;
            break;
        case "UPDATE_SCHEDULED_DATA":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results)
            if (_newState[`ToSchedule`]['data'].length !== 0)
                _newState[`ToSchedule`]['data'] = resultdata.concat(_newState.ToSchedule.data)
            return _newState;
            break;
        case "TASK_DATE_UPDATE":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results)
            if (_newState.ToFollowUp.data.filter((item) => item.masterid === action.taskid).length !== 0){
              let DueDate=  _newState.ToFollowUp.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.toDo.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.toDo.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.ToComment.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.ToComment.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.ToDueToday.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.ToDueToday.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.ToDueInSevenDays.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.ToDueInSevenDays.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.ToDueTomorrow.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.ToDueTomorrow.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.ToDueThisMonth.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.ToDueThisMonth.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.AllOverDues.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.AllOverDues.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }

            if (_newState.thirtyToSixtyDays.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.thirtyToSixtyDays.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.fifteenTothirtydays.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.fifteenTothirtydays.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.sevenTofourteendays.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.sevenTofourteendays.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.oneTosevendays.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.oneTosevendays.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }
            if (_newState.AllArchived.data.filter((item) => item.masterid === action.taskid).length !== 0){
                let DueDate=  _newState.AllArchived.data.filter((item) => item.masterid === action.taskid)
                DueDate[0].duedate=action.utcduedate
            }


            return _newState;
            break;


        // case "":
        //     _newState = {...state};
        //     resultdata = []
        //     _newState[`ToFollowUp`]['data'] = (_newState.ToFollowUp.data).concat(action.taskData.data.results.data)
        //     return _newState;
        //     break;
        case "LIST_MARKASDONE_DATA_EVENT_STREAM_UPDATE":
            _newState = {...state};
            resultdata = []
            resultdata = (action.results)
             if (_newState[`ToFollowUp`]['data'].length !== 0)
            _newState[`ToFollowUp`]['data'] = _newState.ToFollowUp.data.filter((item) => item.masterid !== action.results[0].masterid);
            if (_newState[`ToVerify`]['data'].length !== 0)
            _newState[`ToVerify`]['data']=resultdata.concat(_newState.ToVerify.data)
            return _newState;
            break;
        case "LIST_REOPEN_DATA_EVENT_STREAM_UPDATE":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results[0])
            ////////////console.log(_newState[`toDo`]['data'].length)
            ////////////console.log(action.results)
            if (_newState[`toDo`]['data'].length !== 0)
                _newState[`toDo`]['data'] = resultdata.concat(_newState.toDo.data)
            //    _newState[`ToVerify`]['data'] = resultdata.concat(_newState.ToVerify.data)
          //  _newState[`ToVerify`][`data`] = _newState.ToVerify.data.filter((item) => item.masterid !== action.results[0].masterid);
            return _newState;
            break;
        case "VERIFY_TASK":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results)
            ////////////console.log(action.results.data.results.data[0].masterid)
            //_newState[`ToVerify`]['data']=resultdata.concat(_newState.ToVerify.data)
           // _newState[`ToVerify`][`data`] = _newState.ToVerify.data.filter((item) => item.masterid !== action.results.data.results.data[0].masterid);
            if (_newState[`ToVerify`]['data'].length !== 0)
                _newState[`ToVerify`][`data`] = _newState.ToVerify.data.filter((item) => item.masterid !== action.results.data.results.data);


            return _newState;
            break;
        case "UPDATE_MARK_AS_DONE_DATA":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results)
            if (_newState[`toDo`]['data'].length !== 0)
                _newState[`toDo`][`data`] = _newState.toDo.data.filter((item) => item.masterid !== action.results.data.results.data);
            return _newState;
            break;
        case "REOPEN_DATA_UPDATE":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results)
            if (_newState[`toDo`]['data'].length !== 0)
                _newState[`toDo`]['data'] = resultdata.concat(_newState.toDo.data)
            return _newState;
            break;
        case "REOPEN_TASK":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results.data.results.data[0])
            if (_newState[`ToFollowUp`]['data'].length !== 0)
                _newState[`ToFollowUp`]['data'] = resultdata.concat(_newState.ToFollowUp.data)
            ////////////console.log(resultdata.concat(_newState.ToFollowUp.data))
            //  _newState[`toDo`][`data`]=_newState.toDo.data.filter((item) => item.masterid !== action.results.masterid);
            if (_newState[`ToVerify`]['data'].length !== 0)
            _newState[`ToVerify`][`data`] = _newState.ToVerify.data.filter((item) => item.masterid !== action.results.data.results.data[0].masterid);
            return _newState;
            break;
        case "VERIFY_EVENT_UPDATE_STREAM":
            _newState = {...state};
            resultdata = []
            resultdata[0] = (action.results)
            if (_newState[`ToVerify`]['data'].length !== 0)
                _newState[`ToVerify`][`data`] = _newState.ToVerify.data.filter((item) => item.masterid !== action.results.masterid);
            return _newState;
            break;
        case "LIST_TO_VERIFY_DATA":
            return {
                ...state,
                ToVerify: {
                    ...state.ToVerify,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_TO_COMMENT_DATA":
            return {
                ...state,
                ToComment: {
                ...state.ToComment,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_TO_KNOW_DATA":
            return {
                ...state,
                ToKnow: {
                    ...state.ToKnow,
                    data : action.taskData.content
                }
            };
            break;
            case "LIST_TO_RESPOND_DATA":
            return {
                ...state,
                ToRespond: {
                    ...state.ToRespond,
                    data : action.taskData.content,
                    data1 : action.taskData.toresponds
                }
            };
            break;
        case "LIST_TO_OVERSEE_DATA":
            return {
                ...state,
                ToOversee: {
                    ...state.ToOversee,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_TO_DUE_TODAY_DATA":
            return {
                ...state,
                ToDueToday: {
                    ...state.ToDueToday,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_TO_DUE_IN_SEVEN_DAYS_DATA":
            return {
                ...state,
                ToDueInSevenDays: {
                    ...state.ToDueInSevenDays,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_TO_DUE_TOMORROW_DATA":
            return {
                ...state,
                ToDueTomorrow: {
                    ...state.ToDueTomorrow,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_TO_DUE_THISMONTH_DATA":
            return {
                ...state,
                ToDueThisMonth: {
                    ...state.ToDueThisMonth,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_TO_ALL_OVERDUES_DATA":
            return {
                ...state,
                AllOverDues: {
                    ...state.AllOverDues,
                    data : action.taskData.content
                }
            };
            break;
        case "LIST_TO_ALL_ARCHIVED_DATA":
            return {
                ...state,
                AllArchived: {
                    ...state.AllArchived,
                    data : action.taskData.content
                }
            };
            break;
        case "CONTENT_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        case "LIST_TODO_DATA_ERROR":
            return {
                ...state,
                status: "error",
            };
        case "READ_STATUS_UPDATE":
            _newState = {...state};
            if(action.from === "to-follow-up"){
                if (_newState[`ToFollowUp`]['data'].length !== 0) {
                    if(_newState[`ToFollowUp`]['data'].filter(list => parseInt(list.masterid) === parseInt(action.masterid)).length !==0) {
                        _newState[`ToFollowUp`]['data'].filter(list => parseInt(list.masterid) === parseInt(action.masterid))[0]["readstatus"] = "read"
                    }
                }
             }

            if(action.from === "to-know"){
                if (_newState[`ToKnow`]['data'].length !== 0) {
                    if(_newState[`ToKnow`]['data'].filter(list => parseInt(list.masterid) === parseInt(action.masterid)).length !==0) {
                        _newState[`ToKnow`]['data'].filter(list => parseInt(list.masterid) === parseInt(action.masterid))[0]["inner_hits"]["childtodo"]["hits"]["hits"][0]["_source"]["readstatus"] = "read"
                    }
                }
            }
            if(action.from === "to-respond"){
                if (_newState[`ToRespond`]['data'].length !== 0) {
                    if(_newState[`ToRespond`]['data'].filter(list => (list.masterid.toString()) === (action.masterid.toString())).length !==0) {
                        _newState[`ToRespond`]['data'].filter(list => (list.masterid.toString()) === (action.masterid.toString()))[0]["readstatus"] = "unread"
                    }
                }

                if (_newState[`ToRespond`]['data1'].length !== 0) {
                    _newState[`ToRespond`]['data1']=  (_newState[`ToRespond`]['data1'].filter(list => (list.masterid.toString()) !== action.masterid.toString()))
                }


            }



            return _newState


        default:
            return state;
    }
};


const SidebarMenudata = (state = {
    archive: '',
    comments: '',
    todo: '',
    tofollowup: '',
    toverify: '',
    toknow: '',
    tooversee: '',
    torespond:'',
    duetoday: '',
    dueinsevendays: '',
    duetomorrow: '',
    duethismaonth: '',
    alloverdues: '',
    loading: false,
    sidebarcount:{}
}, action) => {

    var _newState;
    switch (action.type) {

        case "SIDEBAR_MENU_COUNT":
            return {
                ...state,
                archive: action.archive,
                comments: action.comments,
                todo: action.todo,
                tofollowup: action.tofollowup,
                toverify: action.toverify,
                toknow: action.toknow,
                tooversee: action.tooversee,
                torespond:action.torespond,
                todraft:action.todraft,
                toschedule:action.toschedule,
                duetoday: action.duetoday,
                dueinsevendays: action.dueinsevendays,
                duetomorrow: action.duetomorrow,
                duethismaonth: action.duethismaonth,
                alloverdues: action.alloverdues,
                overdueinsevendays: action.overdueinsevendays,
                overdueinfifteendays: action.overdueinfifteendays,
                overdueinthirtydays: action.overdueinthirtydays,
                overdueinsixtydays: action.overdueinsixtydays,
                responds:action.responds
            };
            break;

        case "SIDEBAR_TOVERIFY_COUNT_UPDATE":
            _newState = {...state};
            _newState[`tofollowup`] = _newState.tofollowup - 1;
            // _newState[`toverify`]=_newState.toverify+1;
            return _newState;
            break;
        case "SIDEBAR_TOVERIFY_COUNT_EVENT_STREAM_UPDATE":
            _newState = {...state};
            _newState[`tofollowup`] = _newState.tofollowup - 1;
            _newState[`toverify`] = _newState.toverify + 1;
            return _newState;
            break;

        case "UPDATE_MARK_AS_DONE_SIDEBAR_MENU_COUNT":
            _newState = {...state};
            _newState[`todo`] = _newState.todo - 1;
            return _newState;
            break;
        case "SIDEBAR_TO_FOLLOW_UP_COUNT_UPDATE":
            _newState = {...state};
            _newState[`tofollowup`] = _newState.tofollowup + 1;
            return _newState;
            break;
        case "SIDEBAR_TO_SCHEDULE_COUNT_UPDATE":
            _newState = {...state};
            _newState[`toschedule`] = _newState.toschedule + 1;
            return _newState;
            break;
        case "SIDEBAR_TO_DO_OUNT_UPDATE":
            _newState = {...state};
            _newState[`todo`] = _newState.todo + 1;
            return _newState;
            break;
        case "REOPEN_TASK":
            _newState = {...state};
            _newState[`tofollowup`] = _newState.tofollowup + 1;
            _newState[`toverify`] = _newState.toverify - 1;
            return _newState;
            break;
        case "SIDEBAR_REOPEN_COUNT_UPDATE":
            _newState = {...state};
            _newState[`todo`] = _newState.todo + 1;
            // _newState[`toverify`]=_newState.toverify-1;
            return _newState;
            break;
        case "SIDEBAR_VERIFY_COUNT_EVENT_STRAM_UPDATE":
            _newState = {...state};
            _newState[`toverify`] = _newState.toverify - 1;
            // _newState[`toverify`]=_newState.toverify-1;
            return _newState;
            break;

        case "VERIFY_TASK":
            _newState = {...state};
            _newState[`toverify`] = _newState.toverify - 1;
            // _newState[`toverify`]=_newState.toverify-1;
            return _newState;
            break;
        case "TASK_SIDEBAR_COUNT_CHECKING" :

            return{
                ...state,
                sidebarcount: action.tasksidebarCount.data.results
            }


            break;

        case "SIDEBAR_MENU_LOADER":

            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};

const SidebarSearchMenudata = (state = {
    archive: '',
    comments: '',
    todo: '',
    tofollowup: '',
    toverify: '',
    toknow: '',
    tooversee: '',
    torespond:'',
    duetoday: '',
    duetomorrow: '',
    duethismaonth: '',
    alloverdues: '',
    responds:"",
    loading: false
}, action) => {

    var _newState;
    switch (action.type) {

        case "SIDEBAR_MENU_SEARCH_COUNT":

            return {
                ...state,
                archive: action.archive,
                comments: action.comments,
                todo: action.todo,
                tofollowup: action.tofollowup,
                toverify: action.toverify,
                toknow: action.toknow,
                tooversee: action.tooversee,
                torespond:action.torespond,
                duetoday: action.duetoday,
                duetomorrow: action.duetomorrow,
                duethismaonth: action.duethismaonth,
                dueinsevendays: action.dueinsevendays,
                alloverdues: action.alloverdues,
                overdueinsevendays:action.overdueinsevendays,
                overdueinfifteendays:action.overdueinfifteendays,
                overdueinthirtydays:action.overdueinthirtydays,
                overdueinsixtydays:action.overdueinsixtydays,
                responds:action.responds




            };
            break;
        case "SIDEBAR_MENU_SEARCH_LOADER":

            return {
                ...state,
                loading: action.loading
            };
            break;

        default:
            return state;
    }
};


const TodoTopbarMenudata = (state = {
    newtask: '',
    inprogress: '',
    completed: '',
    verified: '',
    overdues: ''
}, action) => {
    switch (action.type) {

        case "TODO_TOP_BAR_DATA":
            var _newState;
            var resultdata;
            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
                completed: action.completed,
                verified: action.verified,
                overdues: action.overdues,
            };
            break;

        //
        // case "SIDEBAR_MARK_AS_DONE_COUNT_UPDATE":
        //     _newState = {...state};
        //     resultdata=[]
        //     _newState[`completed`]=_newState.completed+1;
        //     return _newState;
        //     break;


        case "SIDEBAR_MENU_LOADER":

            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};
const TofollowupTopbarMenudata = (state = {
    newtask: '',
    inprogress: '',
    completed: '',
    verified: '',
    overdues: ''
}, action) => {
    switch (action.type) {

        case "TOFOLLOWUP_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
                completed: action.completed,
                verified: action.verified,
                overdues: action.overdues,
            };
            break;


        default:
            return state;
    }
};
const ToCommentTopbarMenudata = (state = {
    newtask: '',
    inprogress: '',
    completed: '',
    verified: '',
    overdues: ''
}, action) => {
    switch (action.type) {

        case "TOCOMMENT_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
                completed: action.completed,
                verified: action.verified,
                overdues: action.overdues,
            };
            break;

        default:
            return state;
    }
};



const taskStatusData = (state = {
   taskstausdata:[]
}, action) => {
    switch (action.type) {

        case "CONTENT_STATUS_DATA":

            return {
                ...state,
                taskstausdata: action.taskData,

            };
            break;

        default:
            return state;
    }
};



const ToKnowTopbarMenudata = (state = {
    newtask: '',
    inprogress: '',
    completed: '',
    verified: '',
    overdues: ''
}, action) => {
    switch (action.type) {

        case "TOKNOW_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
                completed: action.completed,
                verified: action.verified,
                overdues: action.overdues,
            };
            break;

        default:
            return state;
    }
};



const ToOverseeTopbarMenudata = (state = {
    newtask: '',
    inprogress: '',
    completed: '',
    verified: '',
    overdues: ''
}, action) => {
    switch (action.type) {

        case "TOOVERSEE_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
                completed: action.completed,
                verified: action.verified,
                overdues: action.overdues,
            };
            break;

        default:
            return state;
    }
};
const DueTodayTopbarMenudata = (state = {newtask: '', inprogress: ''}, action) => {
    switch (action.type) {

        case "DUETODAY_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
            };
            break;

        default:
            return state;
    }
};
const DueTomorrowTopbarMenudata = (state = {newtask: '', inprogress: ''}, action) => {
    switch (action.type) {

        case "DUETOMORROW_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
            };
            break;

        default:
            return state;
    }
};
const DueInSevenDaysTopbarMenudata = (state = {newtask: '', inprogress: ''}, action) => {
    switch (action.type) {

        case "DUEINSEVENDAYS_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
            };
            break;

        default:
            return state;
    }
};
const DueThismonthTopbarMenudata = (state = {newtask: '', inprogress: ''}, action) => {
    switch (action.type) {

        case "DUEMONTH_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
            };
            break;

        default:
            return state;
    }
};
const AlloverdueTopbarMenudata = (state = {newtask: '', inprogress: ''}, action) => {
    switch (action.type) {

        case "ALLOVERDUE_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
            };
            break;

        default:
            return state;
    }
};
const AllarchiveTopbarMenudata = (state = {
    newtask: '',
    inprogress: '',
    completed: '',
    verified: '',
    overdues: ''
}, action) => {
    switch (action.type) {

        case "ALLARCHIVE_TOP_BAR_DATA":

            return {
                ...state,
                newtask: action.newtask,
                inprogress: action.inprogress,
                completed: action.completed,
                verified: action.verified,
                overdues: action.overdues,
            };
            break;

        default:
            return state;
    }
};
const CreateTask = (state = '', action) => {
    switch (action.type) {

        case "CREATE_TASK":

            return {
                ...state,
                createTask: action.createtask,
            };
            break;


        default:
            return state;
    }
};
const saveTodo = (state = {todoCreated: false}, action) => {
    switch (action.type) {

        case "ADD_TODO":

            return {
                ...state,
                todoCreated: !state.todoCreated
            };
            break;


        default:
            return state;
    }
};

const listFilterUserdata = (state = {Users:[]},action) => {
//////////console.log(action.userdata)
    switch (action.type) {

        case "USER_FILTER_DATA":
            return {
                ...state,
                Users: action.userdata.data.results.data.users,

            };
            break;

        default:
            return state;
    }
};




const TaskDetails = (state = {taskdetails: {
        data: [],
        parent: [],
        assignees: [],
        log: [],
        comment: [],
        allfiles: [],
        subtask: [],
        attachment: [],
        collection: [],
        previoustasks: [],
        meta_form: [],
        value: [],
        loading: false
    }, tempLoading:false,asyncLoad:false,timeLoad:false}, action) => {
    var _newState;

    switch (action.type) {


        case "GET_TASK_DETAILS":

            return {
                ...state,
                taskdetails: action.taskdetails.data.data,
                loading: false,
                task_modified: false,
                tempLoading:false,

            };
            break;
        case "GET_TASK_DETAILS_ASYNC_CALL":

            return {
                ...state,
                taskdetails: action.taskdetails.data.data,
                loading: false,
                task_modified: false,
                tempLoading:true,
            };
            break;



        case "../assets/":
            //window.location.reload();
             _newState = {...state};
            // ////////////console.log(_newState)
            // _newState[`taskdetails`]["parent"]['verifieddate'] = action.results.data.results.data[0].verifieddate;
            // _newState[`taskdetails`]["parent"]['verified'] = action.results.data.results.data[0].verified;
            // _newState[`taskdetails`]["parent"]['verifiedby'] = action.results.data.results.data[0].verifiedby;
            // _newState[`taskdetails`]["parent"]['remarks'] = action.results.data.results.data[0].remarks;
            // _newState[`taskdetails`]["assignees"] = action.results.data.results.data[0].assignees;
            return _newState;
            break;

        case "TEMP_DATA_ASYNC_CALL":
            return {
                ...state,
                tempLoading: action.taskasynccall
            };
            break;

        case "ADD_INVITE_USERS":
            //////////console.log( action.addinviteusers.data.results.data)
            ////////////console.log(action.results.data.results.data[0])
            _newState = {...state};
            _newState[`taskdetails`]["assignees"] =  _newState[`taskdetails`]["assignees"].concat(action.addinviteusers.data.results.data);
            _newState[`taskdetails`]["assignedusers"] =  _newState[`taskdetails`]["assignedusers"].concat(action.addinviteusers.data.results.data);
            return _newState;
            break;


        case "REOPEN_TASK":
            _newState = {...state};
            _newState[`taskdetails`]["parent"]['readstatus'] = "unread"
            _newState[`taskdetails`]["parent"]['status'] = action.results.data.results.data[0].status;
            _newState[`taskdetails`]["parent"]['readdate'] = action.results.data.results.data[0].readdate;
            _newState[`taskdetails`]["parent"]['updatedby'] = action.results.data.results.data[0].updatedby;
            _newState[`taskdetails`]["assignees"] = action.results.data.results.data[0].assignees;
            return _newState;
            break;
        case "MARK_AS_DONE":
            _newState = {...state};
            ////////////console.log(_newState)
           //  _newState[`taskdetails`]["parent"]['status'] = "Done";
           //  _newState[`taskdetails`]["parent"]['readdate'] = "";
           //  _newState[`taskdetails`]["parent"]['verified'] = "";
           //   _newState[`taskdetails`]["parent"]['donedate'] = moment.utc().format('YYYY-MM-DD HH:mm:ss');
           //  _newState[`taskdetails`]["parent"]['updatedby'] = getProfile().id
           //  _newState[`taskdetails`]["assignees"][0]["status"] =    "Done"
           // _newState[`taskdetails`]["assignees"][0]["consolidated_time"] =   ""

            //action.results.data.results.data[0].assignees;
            return _newState;
            break;
        case "UPDATE_TASK_DETAILS_WITH_EVENT_STREAM":
            ////////////console.log(action.results)
            _newState = {...state};
            if (_newState[`taskdetails`]["parent"]  &&_newState[`taskdetails`]["parent"]['masterid'] === action.results[0].masterid) {
                _newState[`taskdetails`]["parent"]['status'] = action.results[0].status;
                _newState[`taskdetails`]["parent"]['readdate'] = action.results[0].readdate;
                _newState[`taskdetails`]["parent"]['donedate'] = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                _newState[`taskdetails`]["parent"]['updatedby'] = ''
                _newState[`taskdetails`]["assignees"] = action.results[0].assignees;
                // return _newState;
            }
            return _newState;
        case "LIST_REOPEN_DATA_EVENT_STREAM_UPDATE":
            _newState = {...state};
            if (_newState[`taskdetails`]["parent"]  &&_newState[`taskdetails`]["parent"]['masterid'] === action.results[0].masterid) {
                _newState[`taskdetails`]["parent"]['status'] = action.results[0].status;
                _newState[`taskdetails`]["parent"]['readdate'] = action.results[0].readdate;
                _newState[`taskdetails`]["parent"]['donedate'] = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                _newState[`taskdetails`]["parent"]['updatedby'] = ''
                _newState[`taskdetails`]["assignees"][0]["status"] = "InProgress"         //action.results[0].assignees;
            }
            return _newState;
        case "UPDATE_TASK_DETAIL_PAGE":
            return {
                ...state,
                taskUpdat: action.taskasynccall
            };
            break;
            /*_newState = {...state};
            if (_newState[`taskdetails`]["parent"]  &&_newState[`taskdetails`]["parent"]['masterid'] === action.results.masterid) {
                _newState[`taskdetails`]["parent"]['Title'] = action.results.Title;
                _newState[`taskdetails`]["parent"]['subject'] = action.results.subject;
                _newState[`taskdetails`]["parent"]['has_attachemnt'] = action.results.has_attachemnt;
                _newState[`taskdetails`]["parent"]['has_collection'] = action.results.has_collection;
                _newState[`taskdetails`]["parent"]['projectname'] = action.results.projectname;
                _newState[`taskdetails`]['collection'] = action.results.collection;
                _newState[`taskdetails`]['assignees'] = action.results.assignees;
                _newState[`taskdetails`]["parent"]['priority'] = action.results.priority;
                _newState[`taskdetails`]["parent"]['projectinfo'] = action.results.projectinfo;
                _newState[`taskdetails`]["parent"]['taginfo'] = action.results.taginfo;
                _newState[`taskdetails`]["parent"]['duedate'] = action.results.duedate;
            }
            return _newState;
            break;*/


        case "UPDATE_TASK_DETAIL_EVENT_STREAM_UPDATE":
            _newState = {...state};
            if (_newState[`taskdetails`]["parent"]  &&_newState[`taskdetails`]["parent"]['masterid'] === action.results.masterid) {
                _newState[`taskdetails`]["parent"]['Title'] = action.results.Title;
                _newState[`taskdetails`]["parent"]['subject'] = action.results.subject;
                _newState[`taskdetails`]["parent"]['has_attachemnt'] = action.results.has_attachemnt;
                _newState[`taskdetails`]["parent"]['has_collection'] = action.results.has_collection;
                _newState[`taskdetails`]["parent"]['projectname'] = action.results.projectname;
                _newState[`taskdetails`]['collection'] = action.results.collection;
                _newState[`taskdetails`]['assignees'] = action.results.assignees;
            }
            return _newState;
        case "ADD_ATTACHMENT_MAIN_TASK":
            _newState = {...state};
                _newState[`taskdetails`]['attachment'] = action.attachmentAdded.data.results.data;
            return _newState;
        case "TASKDETAILS_LOADER":
            return {
                ...state,
                loading: action.loading
            };
       case "TASKDETAILS_LOADER_ASYNC":
            return {
                ...state,
                asyncLoad: action.asyncLoad
            };
        case "TASKDETAILS_TIME_UPDATE":
            return {
                ...state,
                timeLoad: action.timeLoad
            };
        case "RESET_TASK_DETAILS_DATA" :
            return {
                taskdetails: {
                    data: [],
                    parent: [],
                    assignees: [],
                    log: [],
                    comment: [],
                    allfiles: [],
                    subtask: [],
                    attachment: [],
                    collection: [],
                    previoustasks: [],
                    meta_form: [],
                    value: [],
                    tempLoading:false
                }

            }

        default:
            return state;
    }
};

const MoretaskDetails = (state = {moretaskdetails: [],loader:false}, action) => {
    switch (action.type) {
        case "MORE_TASK_DETAILS":
            return {
                ...state,
                moretaskdetails: action.moretaskdetails.data.data,
            };
            break
        case "MORE_TASK_DETAILS_LOADER":
            return {
                ...state,
                loader:action.loader
            };
            break;


    break;
        default:
            return state;
    }
};



const ModifyTask = (state = {task_modified: [],task_type:'', async_status : false}, action) => {

    switch (action.type) {

        case "MARK_AS_DONE":
            return {
                ...state,
                task_type:"markasdone",
                task_modified: action.results,

            };
        case "VERIFY_TASK":
            return {
                ...state,
                task_type:"verifytask",
                task_modified: action.results,

            };

        case "REOPEN_TASK":
            return {
                ...state,
                task_type:"reopentask",
                task_modified: action.results,

            };

        case "EDIT_TASK_CONTENT":
            return {
                ...state,
                task_type:"updatetask",
                task_modified: action.results,

            };
        case "UPDATE_TASK_FORM":
            return {
                ...state,
                task_type:"updatetaskform",
                task_modified: action.results,

            };
        case "SET_TO_DEFAULT":
            return {
                ...state,
                task_type:"",
                task_modified:[],

            };

        case "CLAEAR_MODIFY_TEMP_DATA":
            return {
                ...state,
                // task_type:"cleardata",
                task_type:"",
                task_modified:[],

            };
            case "TASK_COMPLETION_ASYNC":
            return {
                ...state,
                async_status : action.status,
                task_type:"",
                task_modified:[],

            };
        case "EXTERNAL_TASK_COMPLETION":
            return {
                ...state,
                // task_type:"cleardata",
                task_type:"",
                task_modified:[],

            };



        // return {
        //     ...state,
        //   //  task_modified: true,
        // };
        // break;


        default:
            return state;
    }
};

const Gettimelog = (state = {timelogAdded: false, timelog: {data: [], _source: []}}, action) => {
   // //////////console.log(action.results)
    ////////////console.log(action.addlog)
    var _newState;
    switch (action.type) {

        // case "ADD_TIME_LOG":
        //     _newState = {...state};
        //     _newState[`timelog`] = action.addlog;
        //     return  _newState;
        //     break;
    // ////////////console.log(action)
    // ////////////console.log(action.addlog)
    // switch (action.type) {
    //
        case "ADD_TIME_LOG":
            _newState = {...state};
            _newState[`timelog`]["data"] = action.addlog.data.data;
            _newState[`timelogAdded`] = action.addlog.data.data;
            ////console.log(  _newState[`timelogAdded`])
            return _newState;

            // case "UPDATE_TIME_LOG":
            // _newState = {...state};
            // _newState[`timelog`]["data"] =(_newState.timelog.data).concat(      {
            //     "taskid": "62_1570784335835",
            //     "userid": "10",
            //     "log_date": "2019-10-09",
            //     "log_hours": "22",
            //     "log_mins": "21",
            //     "consolidated_time": 1341,
            //     "addeddate": "2019-10-11 09:02:25"
            // })
            //
            //
            // _newState[`timelogAdded`] =    false
            // return _newState;


            // return {
            //     ...state,
            //     timelogAdded: true
            //
            // };
            // break;
            // _newState = {...state};
            // _newState[`taskdetails`]["parent"]['status'] = action.results[0].status;
            // _newState[`taskdetails`]["parent"]['readdate'] = action.results[0].readdate;
            // _newState[`taskdetails`]["parent"]['donedate'] = moment.utc().format('YYYY-MM-DD HH:mm:ss');
            // _newState[`taskdetails`]["parent"]['updatedby'] = ''
            // _newState[`taskdetails`]["assignees"] = action.results[0].assignees;

        case "GET_TIME_LOG":

            return {
                ...state,
                timelog: action.timelog.data,
                timelogAdded: false
            };
            break;

        default:
            return state;
    }
};

const GetRemarks = (state = {remarks: {data: [], hits: [], _source: []}}, action) => {
    switch (action.type) {

        case "SAVE_REMARKS":

            return {
                ...state,
                remarks: action.remarks.data.data.hits,
            };
            break;


        default:
            return state;
    }
};

const saveDiscussion = (state = '', action) => {
    switch (action.type) {

        case "SAVE_DISCUSSION":

            return {
                ...state,
                discussion: action.discussion,
            };
            break;


        default:
            return state;
    }
};

const filterTasks = (state = {taskPriorityFilter: 'All', taskProjectFilter: 'All', taskAssignedFilter: ''}, action) => {
    switch (action.type) {

        case "SET_TASK_FILTER":

            return {
                ...state,
                taskPriorityFilter: action.data.priority ? action.data.priority : state.taskPriorityFilter,
                taskProjectFilter: action.data.project ? action.data.project : state.taskProjectFilter,
                taskAssignedFilter: (action.data.assigned !== undefined) ? action.data.assigned : state.taskAssignedFilter

            };
            break;

        default:
            return state;
    }
};

const getTaskSelection = (state = {
    taskSelection: 'no',
    selectedTaskIds: [],
    tasksArchived: false,
    taskDeleted: false,
    allChecked: false,
    dateUpdated: false,
    taskUpdated: false,
    utcduedate : ''
}, action) => {
    var _newState;

    switch (action.type) {
        case "SELECT_ALL_TASKS":

            return {
                ...state,
                allChecked: action.checked,
                selectedTaskIds: []

            };
            break;
        case "SET_TASK_SELECTION":

            return {
                ...state,
                taskSelection: action.data.status ? action.data.status : state.taskSelection,
                selectedTaskIds: []

            };
            break;
        case "SET_TASK_TO_MOVE":

            if (state.selectedTaskIds) {
                if (state.selectedTaskIds.includes(action.id)) {
                    var index = state.selectedTaskIds.indexOf(action.id)
                    state.selectedTaskIds.splice(index, 1);
                } else {
                    state.selectedTaskIds.push(action.id);
                }
            }
            return {
                ...state,
                selectedTaskIds: state.selectedTaskIds
            }
        case "TASK_SELECTED":
            return {
                ...state,
                selectedTaskIds: state.selectedTaskIds
            }
        case "MOVE_TASKS_TO_ARCHIVE":
            return {
                ...state,
                tasksArchived: !state.tasksArchived
            }
        case "DELETE_TASK_PERMANENT":
            return {
                ...state,
                taskDeleted: !state.taskDeleted
            }
        case "UPDATE_TASK_STATUS":
            return {
                ...state,
                taskUpdated: !state.taskUpdated
            }
        case "TASK_DATE_UPDATE":

           /* _newState = {...state};
            //console.log(_newState)
            /!*if (_newState[`taskdetails`]["parent"]  &&_newState[`taskdetails`]["parent"]['masterid'] === action.results.masterid) {
                _newState[`taskdetails`]["parent"]['Title'] = action.results.Title
                // return _newState;
            }*!/
            return _newState;
            break;*/
            return {
                ...state,
                dateUpdated: !state.dateUpdated,
                utcduedate : action.utcduedate,
                taskid : action.taskid
            }
        default:
            return state;
    }
};


const ListAllTags = (state = {tags: [], loading : false}, action) => {
    switch (action.type) {

        case "TAG_LOADING":

            return {
                ...state,
                loading: action.loading,
            };
            break;
        case "LIST_All_TAGS":

            return {
                ...state,

                tags: action.listTags.data.data.hits,

            };
            break;


        default:
            return state;
    }
};

const getPoolData = (state = {
    poolMenu: [],
    poolTaskList: [],
    poolTaskTaken: false,
    poolTaskQuit: false,
    todoPoolTaskQuit: false,
    result: '',
    takenTaskId : '',
    loading :false,
    poolId : '',
    poolTaskAlreadyTaken : -1,
    poolTaskTakenBy : ''
}, action) => {
    let data=[]
    switch (action.type) {
        case "GET_POOL_MENU":
            if(action.poolMenu.length !== 0){
                data=action.poolMenu.data
            }

            return {
                ...state,
                poolMenu: data
            };
            break;
        case "GET_POOL_TASKS":
            //////////console.log(action.poolTasks);
            return {
                ...state,

                poolTaskList: action.poolTasks,
                poolTaskAlreadyTaken : -1
            };
            break;
        case "POOL_TASKS_LOADING":
            //////////console.log(action.poolTasks);
            return {
                ...state,
                loading : action.loading

            };
            break;

        case "TAKE_POOL_TASK" :
            ////////console.log(action.poolResponse);
            return {
                ...state,
                poolTaskTaken: !state.poolTaskTaken,
                takenTaskId : action.poolResponse.data.results.data
            }
            break;
        case "QUIT_POOL_TASK" :
            return {
                ...state,
                poolTaskQuit: !state.poolTaskQuit
            };
            break;
        case "QUIT_TODO_POOL_TASK" :
            return {
                ...state,
                todoPoolTaskQuit: !state.todoPoolTaskQuit,
                poolId: action.quit.data.results.data,
            };
            break;
        case "IS_TASK_OPEN" :
            return {
                ...state,
                poolTaskAlreadyTaken: action.taskStatus.data.results.data.taken,
                poolTaskTakenBy : action.taskStatus.data.results.data.assignee,

            };
            break;
        case "RESET_POOLTASK_STATUS" :
            return {
                ...state,
                poolTaskAlreadyTaken : -1,
                poolTaskTakenBy : ''
            }
        default:
            return state;
    }
};
const GetProjectData = (state = {tagData: [], autocompleteSearch : false}, action) => {
    switch (action.type) {

        case "GET_USER_PROJECT_DATA":
            return {
                ...state,
                projectData: action.projectData,
                autocompleteSearch : action.search ? true : false
            };
            break;
        case "GET_USER_TAG_DATA":

            return {
                ...state,
                tagData: action.tagData,
                autocompleteSearch : action.search ? true : false
            };
            break;


        default:
            return state;
    }
};


const HistoryPushData = (state = {tagData: []}, action) => {
    switch (action.type) {

        case "ADD_TODO_HISTORY_PUSH":

            return {
                ...state,
                data: action.results,
                savemode:action.savemode
            };
            break;


        default:
            return state;
    }
};
const MarkAsDoneStatus = (state = {data: []}, action) => {
    switch (action.type) {

        case "MARK_AS_DONE":

            return {
                ...state,

                //   data: action.results,

            };
            break;


        default:
            return state;
    }
};


const ReassignTask = (state = {results: []}, action) => {
    switch (action.type) {
        case "RE_ASSIGN_TASK":
            return {
                ...state,
                results: action.results,
            };
            break;
        default:
            return state;
    }
};

const Attachmentlist = (state = {attachList: [],loader:false}, action) => {
    var _newState;
    let data=[]

    switch (action.type) {
        case "GET_ALL_ATTACHMENT_DATA":
            if(action.listattachment.length !== 0){
                data=action.listattachment.data.results.data
            }
            return {
                ...state,
                attachList: data,
            };
        case "ADD_ATTACHMENT_TODO":
            _newState = {...state};
             _newState[`attachList`] = action.attachmentAdded.data.results.data;
             _newState[`loader`] =false;
            return _newState;
        case "UPLOAD_ATTACHMENT_DATA_FILE":
            _newState = {...state};
            _newState['attachList'] = action.savediscussion.data.results.data;
            return _newState;
        case "ATTACHMENT_DISCUSSION_LOADER":
            return {
                ...state,
                loader: action.loader,
            };


        default:
            return state;
    }
};
const TodoLoglist = (state = {logList: {results:[],data:[]}}, action) => {
    let data=[]
    switch (action.type) {

        case "GET_ALL_TO_DO_LOGS":
            if(action.todologs.length !==0){
                data=action.todologs.data.results.data
            }
            return {
                ...state,
                logList: data,
            };
            break;
        default:
            return state;
    }
};
const getPrinttask = (state = {results: {activities:[],task:[],assignees:[],collection:[],subtask:[],timelog:[],discussion:[],attachment:[],previoustasks:[],stagestatus:[]}}, action) => {
    switch (action.type) {
        case "PRINT_DATA":
            return {
                ...state,
                results: action.getprinttask.data,
            };
        default:
            return state;
    }
};

const getselectedCollection = (state = {results:[]}, action) => {
    switch (action.type) {
        case "SELECTED_COLLECTION_DATA":
            return {
                ...state,
                results: action.getselectedCollection.data.results.data,
            };
        case "FORMPREVIEW_LOADER":
            return {
                ...state,
                loader:action.loader
            };
        default:
            return state;
    }
};
const getEnterprise = (state = {results:[]}, action) => {
    switch (action.type) {
        case "ENTERPRISE":
            return {
                ...state,
                results: action.getEnterprise.data.results.data,
            };
        case "ENTERPRISE_LOADER":
            return {
                ...state,
                loader:action.loader
            };
        default:
            return state;
    }
};

const AssigneeTimelog = (state = {logpaneldetails:[]}, action) => {
    let data=[]
    switch (action.type) {
        case "GET_ASSIGNEE_TIME_LOG":
            if(action.logpaneldetails.length !== 0){
                data=action.logpaneldetails.data.data
            }
            return {
                ...state,
                logpaneldetails: data,
            };
        default:
            return state;
    }
};
const savepoolMembers = (state = {savepoolMembers: {data:[]}}, action) => {
    switch (action.type) {
        case "GET_POOL_MEMBERS":
            return {
                ...state,
                savepoolMembers: action.savePoolMembers
            };
        default:
            return state;
    }
};
const getSelectedItems = (state = {},action) => {
    switch (action.type) {
        case "SET_SELECTED_ITEMS" :
            return {
                ...state,
                [action.name] : action.value
            };
        case "RESET_SELECTED_ITEMS" :
            return {
                ...state = []
            };
        default:
            return state;
    }
}

const tasksidebarCountdata = (state = {
    archive: false,
    comments: false,
    todo: false,
    tofollowup: false,
    toverify: false,
    toknow: false,
    tooversee: false,
},action) => {
    switch (action.type) {
        case "TASK_SIDEBAR_COUNT_CHECKING" :
            return {
                ...state,
                sidebarcount : action.tasksidebarCount.data.results
            };
        default:
            return state;

    }
}


const taskSearch = (state = {tome:[],byme:[],toverify:[],searchParam:""},action) => {
    var _newState;
    switch (action.type) {
        case "TASK_DATA_SEARCH_LIST_LOADER" :
            return {
                ...state,
                loader:action.loader
            };
            break;

        case "TASK_DATA_SEARCH_LIST" :
            if(action.tasksearch.data.results.data.length !== 0) {
                return {
                    ...state,
                    tome: action.tasksearch.data.results.data.tome,
                    byme: action.tasksearch.data.results.data.byme,
                    toverify: action.tasksearch.data.results.data.toverify,
                    toverified: action.tasksearch.data.results.data.toverified,
                    tome_count: action.tasksearch.data.results.data.tome_count,
                    byme_count: action.tasksearch.data.results.data.byme_count,
                    toverify_count: action.tasksearch.data.results.data.toverify_count,
                    toverified_count: action.tasksearch.data.results.data.toverified_count,
                    searchParam: action.srchval,
                    loader: false
                };
            }
            else{
                return {
                    ...state,
                    tome: [],
                    byme: [],
                    toverify: [],
                    toverified:[],
                    tome_count: 0,
                    byme_count: 0,
                    toverify_count: 0,
                    toverified_count:0,

                    searchParam: action.srchval,
                    loader: false
                };


            }

        case "TASK_DATA_SEARCH_LIST_LOAD_MORE" :
            _newState = {...state};
            if(action.mode === 'tome') {
                _newState["tome"] = _newState.tome.concat(action.loaderTask.data.results.data);
            }
            if(action.mode === 'byme') {
                _newState["byme"] = _newState.byme.concat(action.loaderTask.data.results.data);
            }
            if(action.mode === 'toverify') {
                _newState["toverify"] = _newState.toverify.concat(action.loaderTask.data.results.data);
            }
            if(action.mode === 'toverified') {
                _newState["toverified"] = _newState.toverified.concat(action.loaderTask.data.results.data);
            }
            if(action.mode === 'searchParam') {
                _newState["searchParam"] = action.srchval;
            }
             _newState["loader"]=false
            return _newState;
        default:
            return state;

    }
}

const getProjectTodoUsers = (state = {userList:[]}, action) => {
    switch (action.type) {
        case "LIST_PROJECT_TODO_USERS":
            return {
                ...state,
                userList: action.listUsers,

            };
        default:
            return state;
    }
};
const getTaskalert = (state = {alert:[]}, action) => {
    switch (action.type) {
        case "LIST_TASK_ALERT":
            return {
                ...state,
                alert: action.taskalert,

            };
        default:
            return state;
    }
};
const staAlert = (state = {sta:true}, action) => {
    switch (action.type) {
        case "TASK_ALERT_DISPLAY":
            return {
                ...state,
                sta: action.alert,

            };
        default:
            return state;
    }
};


const forceClose = (state = {closed:""}, action) => {
    switch (action.type) {
        case "FORCE_CLOSE_BY_CREATOR":
            return {
                ...state,
                closed: action.closed,

            };
        default:
            return state;
    }
};

const getRelatedtask = (state = {data:[],loader:false}, action) => {
    switch (action.type) {
        case "RELATED_TASK_LOADER":
            return {
                ...state,
                loader: action.loader,
            };
        case "LIST_RELATED_TASK":
            return {
                ...state,
                loader: action.loader,
                data:action.data
            };
        default:
            return state;
    }
};
const myTaskList = (state = {data:[],loader:false}, action) => {
    switch (action.type) {
        case "MY_TASK_LOADER":
            return {
                ...state,
                loader: action.loader,
            };
        case "LIST_MY_TASK":
            return {
                ...state,
                loader: action.loader,
                data:action.data
            };
        default:
            return state;
    }
};
const followupTaskData = (state = {followupTaskList:[],loader:false}, action) => {
    switch (action.type) {
        case "FOLLOWUP_TASK_LIST":
            return {
                ...state,
                loader: action.loader,
                followupTaskList:action.followupTaskList
            };
        default:
            return state;
    }
};

const whatsappReply = (state = {replied:[],loader:false}, action) => {
    switch (action.type) {
        case "REPLY_ADDED":
            return {
                ...state,
                loader: action.loader,
                replied:action.replied
            };
        case "REPLY_RESET":
            return {
                ...state,
                loader: action.loader,
                replied:action.replied
            };
        default:
            return state;
    }
};


export const Byaction = combineReducers({
    listtoDo,
    SidebarMenudata,
    CreateTask,
    TodoTopbarMenudata,
    TofollowupTopbarMenudata,
    ToCommentTopbarMenudata,
    ToKnowTopbarMenudata,
    ToOverseeTopbarMenudata,
    DueTodayTopbarMenudata,
    DueTomorrowTopbarMenudata,
    DueInSevenDaysTopbarMenudata,
    DueThismonthTopbarMenudata,
    AlloverdueTopbarMenudata,
    AllarchiveTopbarMenudata,
    TaskDetails,
    MoretaskDetails,
    ModifyTask,
    Gettimelog,
    GetRemarks,
    saveDiscussion,
    filterTasks,
    ListAllTags,
    getTaskSelection,
    getPoolData,
    saveTodo,
    GetProjectData,
    HistoryPushData,
    MarkAsDoneStatus,
    ReassignTask,
    SidebarSearchMenudata,
    Attachmentlist,
    TodoLoglist,
    getSelectedItems,
    savepoolMembers,
    tasksidebarCountdata,
    taskStatusData,
    listFilterUserdata,
    AssigneeTimelog,
    getPrinttask,
    getselectedCollection,
    getEnterprise,
    taskSearch,
    getProjectTodoUsers,
    getTaskalert,
    staAlert,
    forceClose,
    getRelatedtask,
    myTaskList,
    followupTaskData,
    whatsappReply
});

export default Byaction;
