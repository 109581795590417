import {whatsapp} from "../services/whatsapp.services";
import {collections} from "../services/collections.services";
import moment from "moment";
import {entity} from "../services/entity.services";
import {ceshareview} from "../services/ceshareview.services";
export const getChannelSettings = (id,params) => dispatch => {
    let listAllSettings = whatsapp.getCollectedInfo(id,params);
    dispatch({
        type: 'CHANNELLIST_LOADER',
        loading:true,

    });
    listAllSettings.then(listAllSettings => {

        var channelList = [];
        var settingsData = listAllSettings.data.results.data;
        for(var i=0; i<settingsData.length; i++) {
            var collectedform = settingsData[i].collectedform;
            if(collectedform.length > 0) {
                channelList[i] = { "id" : settingsData[i].id}
                for(var j=0; j< collectedform.length; j++) {
                    let masterid = parseInt(collectedform[j].masterid);
                    var label = collectedform[j].label;
                    var value = '';

                    switch(masterid) {
                        case 307620645 :
                            label = "channel_name";
                            value = (collectedform[j].value && collectedform[j].value[0]) ? collectedform[j].value[0].value : '';
                        break;
                        case 1430697216 :
                            label = "channel_desc";
                            value = (collectedform[j].value && collectedform[j].value[0]) ? collectedform[j].value[0].value : '';
                            break;
                        case 210370675 :
                            let mapping = (collectedform[j].value && collectedform[j].value[0]) ? collectedform[j].value[0].value : '';
                            mapping = JSON.parse(mapping);

                            for(var k=0; k<mapping.length; k++) {
                                if(mapping[k]['id'] === 'phone_no')
                                    channelList[i]['number_field'] = mapping[k]['value']? mapping[k]['value'] :'';
                                if(mapping[k]['id'] === 'display_name')
                                    channelList[i]['name_field'] = mapping[k]['value']? mapping[k]['value'] :'';
                                if(mapping[k]['id'] === 'prof_img')
                                    channelList[i]['image_field'] = mapping[k]['value']? mapping[k]['value'] :'';
                            }

                            label = "mapping";
                            value = (collectedform[j].value && collectedform[j].value[0]) ? collectedform[j].value[0].value : '';
                            break;
                        case 463977364 :
                            label = "entityId";
                            value = (collectedform[j].value && collectedform[j].value[0]) ? collectedform[j].value[0].value : '';
                            break;
                        case 1012681233 :
                            label = "default_channel";
                            value = (collectedform[j].value && collectedform[j].value[0]) ? collectedform[j].value[0].value : '';
                            break;
                    }
                    channelList[i][[label]] = value;
                }
            }

        }

        dispatch({
            type: 'LIST_ALL_CHANNELS',
            channelList: channelList

        });

        dispatch({
            type: 'LEADLIST_LOADER',
            loading:false,

        });
    })
        .catch(error => {
            dispatch({
                type: 'CHANNELLIST_LOADER',
                loading:false,

            });

        });

};

export const createChannel = (data,params) => dispatch => {
    let formSaved = collections.saveForm(data);
    formSaved.then( formSaved => {
       // if(data.mode === 'save') { // Sync users
            let userList = whatsapp.syncToWhatsapp(params);
        //}
        if(formSaved.data) {
            dispatch({
                type: 'SAVE_CHANNEL_DATA',
                channelCreated :formSaved.data.results.data

            });



        }
        else {
            dispatch({
                type: 'ERROR_FORM_SAVING',
                formSaveError : true

            });
        }


    });


};
export const syncToWhatsapp = (params) => dispatch => {
     // Sync users
    dispatch({
        type: 'LEADS_SYNCED',
        synced:'',

    });
    let synced = whatsapp.syncToWhatsapp(params);
    synced.then(synced => {
        dispatch({
            type: 'LEADS_SYNCED',
            leadSync:'yes',

        });
    })

};

export const getChatLeads = (params) => dispatch => {
    let listChatLeads = whatsapp.getChatLeads(params);
    dispatch({
        type: 'LEADLIST_LOADER',
        loading:true,

    });
    listChatLeads.then(listChatLeads => {
        dispatch({
            type: 'LIST_CHAT_LEADS',
            leadsList : listChatLeads.data.allusers,
            activeLeadsList : listChatLeads.data.mychat,
            newLeadsList : listChatLeads.data.newsession,
            //folUpLeadsList : listChatLeads.data.followup,
            //customtemplate_other:listActiveUsers.data.results.customtemplate_other
        });

        dispatch({
            type: 'LEADLIST_LOADER',
            loading:false,

        });
    })
        .catch(error => {

            dispatch({
                type: 'LEADLIST_LOADER',
                loading:false,

            });

        });

};
export const getActiveLeads = (params) => dispatch => {
    let listActiveLeads = whatsapp.getActiveLeads(params);
    dispatch({
        type: 'LEADLIST_LOADER',
        loading:true,

    });
    listActiveLeads.then(listActiveLeads => {

        dispatch({
            type: 'LIST_ACTIVE_LEADS',
            activeLeadsList: listActiveLeads.data.data,
            //customtemplate_other:listActiveUsers.data.results.customtemplate_other
        });

        dispatch({
            type: 'LEADLIST_LOADER',
            loading:false,

        });
    })
        .catch(error => {

            dispatch({
                type: 'LEADLIST_LOADER',
                loading:false,

            });

        });

};

export const getAllLeads = (params) => dispatch => {
    let listAllLeads = whatsapp.getAllLeads(params);
    dispatch({
        type: 'LEADLIST_LOADER',
        loading:true,

    });
    listAllLeads.then(listAllLeads => {
        //console.log(listAllLeads)
        dispatch({
            type: 'LIST_ALL_LEADS',
            allLeads: listAllLeads.data.data,
            //customtemplate_other:listActiveUsers.data.results.customtemplate_other
        });

        dispatch({
            type: 'LEADLIST_LOADER',
            loading:false,

        });
    })
        .catch(error => {

            dispatch({
                type: 'LEADLIST_LOADER',
                loading:false,

            });

        });

};
export const getNewLeads = (params) => dispatch => {
    let listNewLeads = whatsapp.getNewLeads(params);
    dispatch({
        type: 'LEADLIST_LOADER',
        loading:true,

    });
    listNewLeads.then(listNewLeads => {

        dispatch({
            type: 'LIST_NEW_LEADS',
            newLeads: listNewLeads.data.data,
            //customtemplate_other:listActiveUsers.data.results.customtemplate_other
        });

        dispatch({
            type: 'LEADLIST_LOADER',
            loading:false,

        });
    })
    .catch(error => {

        dispatch({
            type: 'LEADLIST_LOADER',
            loading:false,

        });

    });

};
export const getFolUpLeads = (params) => dispatch => {
    let listFolUpLeads = whatsapp.getFolUpLeads(params);
    dispatch({
        type: 'LEADLIST_LOADER',
        loading:true,

    });
    listFolUpLeads.then(listFolUpLeads => {
        let userList = listFolUpLeads.data.results.data;
        userList = userList.map((item,index) =>{
            item.collectionid = "1600230072115";
            item.img = "";
            item.lastmessage = "";
            item.lastseen = "";
            item.messagecount = "0";
            item.name = item.first_name+" "+item.last_name;
            item.number = item.contact_number;
            item.pin = false;
            item.recordid = item.recordid;
            item.status= "idle";
            return item;
        });
        dispatch({
            type: 'LIST_FOLUP_LEADS',
            folUpLeads: userList,
            //customtemplate_other:listActiveUsers.data.results.customtemplate_other
        });

        dispatch({
            type: 'LEADLIST_LOADER',
            loading:false,

        });
    })
        .catch(error => {

            dispatch({
                type: 'LEADLIST_LOADER',
                loading:false,

            });

        });

};

export const getUserChats = (params) => dispatch => {
    let listAllChats = whatsapp.getUserChats(params);

    dispatch({
        type: 'CHATLIST_LOADER',
        loading:true,

    });
    dispatch({
        type: 'LIST_ALL_CHATS',
        chatList:[],

    });
    listAllChats.then(listAllChats => {

        dispatch({
            type: 'LIST_ALL_CHATS',
            chatList: listAllChats.data.data,
            userInfo : listAllChats.data.userinfo

        });

        dispatch({
            type: 'CHATLIST_LOADER',
            loading:false,

        });
    })
    .catch(error => {
       /* dispatch({
            type: "NET_WORK_ERROR",
            status:true,

        })*/
        dispatch({
            type: 'CHATLIST_LOADER',
            loading:false,

        });

    });

};
export const saveNewChat = (data) => dispatch => {
    let chatSaved = whatsapp.saveNewChat(data);
    //console.log(moment.utc().format('YYYY-MM-DD HH:mm:ss'))
    chatSaved.then( chatSaved => {
        let recordid = data.recordid;
        let newChat = {
            bsynuser: data.bsynuser,
            chat_id: chatSaved.data.results.id,
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            message: data.message,
            readstatus: "unread",
            receiver: data.receiver,
            sender: data.sender
        }

        if(chatSaved.data) {
            dispatch({
                type: 'SAVE_CHAT_MESSAGE',
                chatSaved : chatSaved.data.results.id,
                newChat :newChat
            });

        }
        else {
            dispatch({
                type: 'ERROR_CHAT_SAVING',
                formSaveError : true

            });
        }


    });


};
export const updateList = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_CHAT_MESSAGE',
        chatSaved : data,
        newChat :data
    });

};
export const takeChat = (params) => dispatch => {
    let chatTaken = whatsapp.takeChat(params);
    chatTaken.then( chatTaken => {
        dispatch({
            type: 'CHAT_TAKEN',
            chatTaken: true
        });
    });

};
export const followUpChat = (params) => dispatch => {
    let followedUp = whatsapp.followUp(params);
    followedUp.then( followedUp => {
        dispatch({
            type: 'FOLLOWED_UP',
            followedUp: true
        });
    });

};
export const removeFromfollowUp = (params) => dispatch => {
    let followedUp = whatsapp.followUp(params);
    followedUp.then( followedUp => {
        dispatch({
            type: 'FOLLOWED_UP_REMOVED',
            followedUp: false
        });
    });

};
export const getProgramList = (collectionid, params) => dispatch => {
    let programData = whatsapp.getCollectedInfo( collectionid,params);
    programData.then( programData => {
        let programList = parseDataList(programData.data.results.data);
        dispatch({
            type: 'PROGRAM_LIST_SUCCESS',
            programList: programList
        });
    });

};
export const convertLeadToPatient = (params) => dispatch => {
    let converted = whatsapp.convertLeadToPatient( params);
    converted.then( converted => {
        dispatch({
            type: 'LEAD_CONVERSION_SUCCESS',
            converted: converted
        });
    });

};
function parseDataList(dataset) {
    let resultset = [];
    if(dataset) {
        for(var i=0; i< dataset.length;i++){
            let formdata = dataset[i].collectedform;
            let record = {};
            for(var j=0; j< formdata.length; j++) {
                let value = (formdata[j].value && formdata[j].value.length > 0)?formdata[j].value[0].value : '';
                record[formdata[j].label] = value;
            }
            resultset.push(record);
        }
    }
    return resultset;
}

export const getFormJson = (data) => dispatch => {
    let showformdata = entity.showForm(data);

    showformdata.then(showformdata => {
        dispatch({
            type: 'GET_FORM_JSON',
            entityId : data._id,
            formJson: { [data._id] :showformdata.data.data},

        })
    })
};
export const getFormData = (data) => dispatch => {

    dispatch({
        type: 'RESET_FORM_VALUES'
    })
    let formData = entity.getFormData(data);

    formData.then(formData => {
        let parsedData = parseDataDetail(formData.data.results.data);
        dispatch({
            type: 'GET_FORM_VALUES',
            formValues : { [data._id] : formData.data.results.data},
            recordid : data._id,
            profileValues: { [data._id] : parsedData},
            meta: { [data._id] : formData.data.results.meta},
            id: { [data._id] : formData.data.results.id},
            flags:{[data._id] : formData.data.results.flag}

        })
    })
};

export const getFormDataList = (id,params) => dispatch => {
    let listFollowupCalls = whatsapp.getCollectedInfo(id,params);
    dispatch({
        type: 'FOLLOWUP_CALLS_LOADER',
        loading:true,

    });
    listFollowupCalls.then(listFollowupCalls => {

        var followupCallsList = [];
        var followupCallsData = listFollowupCalls.data.results.data;
        for(var i=0; i<followupCallsData.length; i++) {
            var collectedform = followupCallsData[i].collectedform;
            //console.log(collectedform)
            if(collectedform.length > 0) {
                followupCallsList[i] = { "id" : followupCallsData[i].id}
                for(var j=0; j< collectedform.length; j++) {
                    //console.log(collectedform[j])
                    let masterid = parseInt(collectedform[j].masterid);
                    var label = collectedform[j].label;
                    var value = (collectedform[j].value && collectedform[j].value[0]) ? collectedform[j].value[0].value : '';

                    followupCallsList[i][[label]] = value;
                }
            }

        }


        dispatch({
            type: 'LIST_ALL_FOLLOWUP_CALLS',
            followupCallsList: followupCallsList

        });
        dispatch({
            type: 'FOLLOWUP_CALLS_LOADER',
            loading:false,

        });


    })
        .catch(error => {
            dispatch({
                type: 'FOLLOWUP_CALLS_LOADER',
                loading:false,

            });

        });
};


export const getLeadScore = (id,params) => dispatch => {
    let leadScoreResult = whatsapp.getCollectedInfo(id,params);
    dispatch({
        type: 'LEAD_SCORE_LOADER',
        loading:true,

    });
    leadScoreResult.then(leadScoreResult => {

        var leadScores = [];
        var leadScoreData = leadScoreResult.data.results.data;
        for(var i=0; i<leadScoreData.length; i++) {
            var collectedform = leadScoreData[i].collectedform;
            if(collectedform.length > 0) {

                for(var j=0; j< collectedform.length; j++) {
                    let masterid = collectedform[j].masterid;
                    if(masterid === '134924570' || masterid === '984061092' || masterid === '420965558' || masterid === '244104322'){

                        var label = collectedform[j].fieldname;
                        var value = (collectedform[j].value && collectedform[j].value[0]) ? collectedform[j].value[0].value : '';

                        leadScores.push( {
                            "scoreLabel": label,
                            "score": value
                        });
                    }

                }
            }
        }

        dispatch({
            type: 'LIST_LEAD_SCORES',
            leadScores: {[params.search] :leadScores},
            selected_lead_id : params.search

        });
        dispatch({
            type: 'LEAD_SCORE_LOADER',
            loading:false,

        });


    })
        .catch(error => {
            dispatch({
                type: 'LEAD_SCORE_LOADER',
                loading:false,

            });

        });
};


export const paymentInfo = (params) => dispatch => {
    let paymentInfo = whatsapp.paymentInfo(params);
    paymentInfo.then(paymentInfo => {
        dispatch({
            type: 'PAYMENT_INFO',
            paymentInfo: paymentInfo.data
        });
    });
}
export const LeadProgram = (params) => dispatch => {
    let LeadProgram = whatsapp.LeadProgram(params);
    dispatch({
        type: 'LEAD_PROGRAM_LOADER',
        loading:true,
    });
    LeadProgram.then(LeadProgram => {
        dispatch({
            type: 'LEAD_PROGRAM',
            LeadProgram: LeadProgram.data.data,
            lead: LeadProgram.data.data.lead,
            program: LeadProgram.data.data.program,

        });
        dispatch({
            type: 'LEAD_PROGRAM_LOADER',
            loading:false,

        });
    });
}

export const invoiceList = (params) => dispatch => {
    let invoiceList = whatsapp.invoiceList( params);
    dispatch({
        type: 'INVOICE_LOADER',
        loading:true,
    });
    invoiceList.then( invoiceList => {
        dispatch({
            type: 'INVOICE_LIST',
            invoiceList: invoiceList.data ? invoiceList.data.data : []
        });
        dispatch({
            type: 'INVOICE_LOADER',
            loading:false,

        });
    });

};

function parseDataDetail(formdata) {
    let record = {};
    for(var j=0; j< formdata.length; j++) {
        let value = '';
        if(formdata[j].values && formdata[j].values.length > 0) {
            for(var k=0; k< formdata[j].values.length; k++) {
                if(formdata[j].external_linked === 'yes') {
                    value += formdata[j].values[k].recordid;
                }
                else {
                    value += formdata[j].values[k].value;
                }
                if(k < formdata[j].values.length-1) {
                    value += ',';
                }
            }

        }
        record[formdata[j].masterid] = value;

    }
    return record;
}
export const deleteWhatsappChat = (params) => dispatch => {
    dispatch({
        type: 'DELETE_WH_MSG',
        chatDeleted: ''
    });
    let deleted = whatsapp.deleteWhatsappChat( params);
    deleted.then( deleted => {
        dispatch({
            type: 'DELETE_WH_MSG',
            chatDeleted: 'yes'
        });
    });

};

export const getWhatsappTasks = (params) => dispatch => {
    dispatch({
        type: 'WH_TASK_LOADER',
        loading:true,

    });
    let taskData = whatsapp.getWhatsappTasks(params);

    taskData.then(taskData => {
        dispatch({
            type: 'WH_TASK_LIST',
            taskList : taskData.data.results.data.tasks

        })
        dispatch({
            type: 'WH_TASK_LOADER',
            loading:false,

        });
    })
        .catch(error => {
            dispatch({
                type: 'WH_TASK_LOADER',
                loading:false,

            });

        });
};

export const generateOrderId = (params) => dispatch => {
    let generateOrderId = whatsapp.generateOrderId( params);

    generateOrderId.then( generateOrderId => {
        dispatch({
            type: 'GENERATE_ORDERID',
            orderId: generateOrderId.data
        });

    });

};
export const getFollowupCallsToday = (params) => dispatch => {
    let followupCallsToday = whatsapp.getFollowupCallsToday( params);
    followupCallsToday.then( followupCallsToday => {

        let userList = followupCallsToday.data.results.data;
        if(userList){
            userList = userList.filter(item => item !== null).map((item,index) =>{
                item.collectionid = "1600230072115";
                item.img = "";
                item.lastmessage = "";
                item.lastseen = "";
                item.messagecount = "0";
                item.name = item.first_name+" "+item.last_name;
                item.number = item.contact_number;
                item.pin = false;
                item.recordid = item.recordid;
                item.status= "idle";
                return item;
            });
        }


        dispatch({
            type: 'LIST_FOLUP_TODAY_LEADS',
            folUpLeadsListToday: userList
        });

    });

};
export const searchLead = (params) => dispatch => {
    let listAllLeads = whatsapp.getAllLeads(params);
    dispatch({
        type: 'LEADLIST_LOADER',
        loading:true,

    });
    listAllLeads.then(listAllLeads => {
        //console.log(listAllLeads)
        dispatch({
            type: 'SEARCH_ALL_LEADS',
            allLeads: listAllLeads.data.data,
            //customtemplate_other:listActiveUsers.data.results.customtemplate_other
        });

        dispatch({
            type: 'LEADLIST_LOADER',
            loading:false,

        });
    })
        .catch(error => {

            dispatch({
                type: 'LEADLIST_LOADER',
                loading:false,

            });

        });

};

export const resourceDetails = (id, params) => dispatch => {
    let resourceDetails = whatsapp.resourceDetails(id, params);
    dispatch({
        type: 'RESOURCE_LOADER',
        loading:true,
    });
    resourceDetails.then( resourceDetails => {

        dispatch({
            type: 'RESOURCE_DETAILS',
            resourceDetails: resourceDetails
        });
        dispatch({
            type: 'RESOURCE_LOADER',
            loading:false,

        });
    });

};

export const getSavedResources = (params) => dispatch => {
    let getSavedResources = whatsapp.resourceDetails(params);
    dispatch({
        type: 'GET_RESOURCE_LOADER',
        loading:true,
    });
    getSavedResources.then( getSavedResources => {
        dispatch({
            type: 'GET_RESOURCE_SAVE',
            getSavedResources: getSavedResources
        });
        dispatch({
            type: 'GET_RESOURCE_LOADER',
            loading:false,

        });
    });

};
export const saveResourcesForm = (params) => dispatch => {
    let saveResourcesForm = whatsapp.saveResourcesForm(params);
    dispatch({
        type: 'SAVE_RESOURCE_LOADER',
        loading:true,
    });
    saveResourcesForm.then( saveResourcesForm => {
        dispatch({
            type: 'RESOURCE_SAVE',
            saveResourcesForm: saveResourcesForm
        });
        dispatch({
            type: 'SAVE_RESOURCE_LOADER',
            loading:false,

        });
    });

};
export const updateFields = (params) => dispatch => {
    let fieldUpdated = ceshareview.fieldUpdate(params);
    fieldUpdated.then( fieldUpdated => {
        dispatch({
            type: 'UPDATE_FORM_FIELD',
            fieldUpdated: fieldUpdated
        });

        /*dispatch({
            type: 'UPDATE_FOLLOW_UP',
            followupUpdated: fieldUpdated
        });*/

    });

};
export const updateFollowup = (params) => dispatch => {
    let fieldUpdated = ceshareview.fieldUpdate(params);
    fieldUpdated.then( fieldUpdated => {
        dispatch({
            type: 'UPDATE_FOLLOW_UP',
            followupUpdated: fieldUpdated
        });

    });

};
export const getHelpText = (id, params) => dispatch => {
    let getHelpText = whatsapp.getHelpText(id, params);
    dispatch({
        type: 'GET_HELP_TEXT_LOADER',
        loading:true,
    });
    getHelpText.then( getHelpText => {
        dispatch({
            type: 'GET_HELP_TEXT',
            getHelpText: getHelpText
        });
        dispatch({
            type: 'GET_HELP_TEXT_LOADER',
            loading:false,

        });
    });

};
export const getFlagList = (params) => dispatch => {
    let flagData = whatsapp.getFlagList(params);
    flagData.then( flagData => {
        console.log(flagData)
        dispatch({
            type: 'GET_FLAG_LIST',
            flagList: flagData.data.data,
            flagCategories: flagData.data.category
        });
    });
};
export const sendInvoice = (params) => dispatch => {
    let sendInvoice = whatsapp.sendInvoice(params);
    sendInvoice.then( flagData => {
        dispatch({
            type: 'SEND_INVOICE',
            sendInvoice: sendInvoice
        });
    });
};