import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as usersActions from "../../../actions/Users";


class AddNewMetrics extends Component {

    constructor(props) {
        super(props);

        this.state={

        };


    }



    componentDidMount() {


    }



    render() {
        return (
            <div className="task-content-block">
                <div className="persitant-top pj-pad">
                    <div className="split-drop-box">
                        <div className="select-box eq-width">
                            <select>
                                <option value="volvo">Choose Metric Category</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                        <div className="select-box eq-width">
                            <select>
                                <option value="volvo">Choose Indicator Type</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>
                    <div className="ind-drop-box">
                        <div className="select-box eq-width">
                            <select>
                                <option value="volvo">Project Metric Name (Output)</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>
                    <div className="ind-drop-box">
                        <div className="select-box eq-width">
                            <select>
                                <option value="volvo">Outcome</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>
                    <div className="ind-drop-box">
                        <div className="select-box eq-width">
                            <select>
                                <option value="volvo">Metric</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>
                    <div className="ind-drop-box">
                        <div className="select-box eq-width">
                            <select>
                                <option value="volvo">Input Mode</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>

                    <div className="ind-drop-box">
                        <div className="select-box eq-width">
                            <select>
                                <option value="volvo">Choose Collection/Entity</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="persitant-footer">
                    <div className="group-row">

                    </div>
                    <button data-masterid="0" data-mode="save" type="button" className="general-btn"
                            id="createproject">Add
                    </button>
                </div>
            </div>


        );
    }
}


const mapStateToProps = state => ({
    listUsers:state.UsersReducer.listUsersdata,

});
export default  withRouter(connect(mapStateToProps, {
    users:usersActions.listTodoUsers,

})(AddNewMetrics));



