import React, {Component} from 'react';
import SimpleModal from "../views/SimpleModal";
import {EditProfile} from "../pages/FormsData";
import {Button, ButtonToolbar,Accordion,Card} from "react-bootstrap";
import {getProfile} from "../../utilities/AuthService";
import {USRIMG_PATH,ES_INDEX } from '../../constants/index';
 //import {FRMSIMG_PATH, } from "../../constants";
import {connect} from "react-redux";
import * as taskActions from "../../actions/ToDo";
import {withRouter} from 'react-router';
import rootActions from "../../actions";
import * as usersActions from "../../actions/Users"
import LoaderSvg from "../views/LoaderSVG";
import ReportIssue from "./ReportIssue";
class HelpPanel extends Component {

    constructor(props) {
        super(props);
        this.state={
            desc:'',
            modalStatus: false,
            userlistdata: [],
            ReportShow: false,

        }
        this.modalChange=this.modalChange.bind(this)
        this.sidePanelChange=this.sidePanelChange.bind(this)
        this.searchHelp=this.searchHelp.bind(this)
        this.helpReport=this.helpReport.bind(this)
    }



    componentDidMount() {

        let userdata = {
            _id: ES_INDEX+'10006',
        }
        this.props.helpdata(userdata);
    }

    helpReport(ReportShow) {
        this.props.helpReported(ReportShow)
    }
    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    sidePanelChange(e, sidePanelShow, list, headername){
        ////////console.log(list)
        this.setState({
            sidePanelShow: sidePanelShow,
            userlistdata: list,
            headername: headername
        })

    }

    searchHelp(e) {
        let searchValue = e.target.value;
        if(searchValue !== ''){
            let userdata = {
                _id: ES_INDEX+'10006',
                search: searchValue
            }
            this.props.helpdata(userdata);
        }
        else{
            let userdata = {
                _id: ES_INDEX+'10006',
            }
            this.props.helpdata(userdata);
        }
    }

    modalChange(e, sidePanelShow){
        this.setState({sidePanelShow:sidePanelShow})
    }

    render() {
        ////////console.log(this.props)
        let UserHelp = this.props.HelpData.HelpInfo[0] !== undefined ? this.props.HelpData.HelpInfo[0]:[];
        ////////console.log(UserHelp);
        let userlist = this.state.userlistdata;
        ////////console.log(userlist);
        ////////console.log(this.props.HelpData.loading)
        return (

            <>


                <div className="float-panel-header">
                    <h5 className="subheader">Help Topics</h5>
                    <div className="report-issue-block">
                        <p> <a href="javascript:void(0);" className="report-issue" onClick={()=>this.helpReport(true)} >Report an Issue</a> </p>

                        {/*<p> <a href="javascript:void(0);" className="report-issue"  >Report an Issue</a> </p>*/}
                        <button className="ico-lib fly-close" onClick={()=>this.props.helpClose("help","")}></button>
                    </div>
                </div>


                <div className="float-panel-content-holder">

                    <div className="float-panel-search">
                        <input type="text" className="float-search" placeholder="Search..." onChange={this.searchHelp} />
                        <span className="ico-lib fl-search-ico"></span>
                    </div>



                    <div className="float-panel-content">
                        {this.props.HelpData.loading &&
                        <span className="Loader-holder">
                                                <LoaderSvg/>
                                            </span>
                        }
                        {UserHelp.child !== undefined && UserHelp.child.map((list, index) =>
                            <div className="ind-content" key={index}
                                 onClick={(e) => this.sidePanelChange(e, true, list, list.fields[0].values[0].value)}>

                                <h5 className="subheader">{list.fields[0].values[0].value}</h5>

                            </div>
                        )}
                    </div>

                    <div className={this.state.sidePanelShow === true ? "float-panel-slider active" : "float-panel-slider" }>
                        <div className="panel-slider-header">
                            <a href="javascript:void(0);" className="qa-back-button ico-lib less-ico"
                               onClick={(e) => this.modalChange(e, false)}>Back</a>
                        </div>
                            <div className="panel-slider-content" >
                                <h5 className="subheader">{this.state.headername}</h5>
                                { userlist.fields !== undefined && userlist.fields.map((list, index) =>
                                    <div key={index}>
                                        {index > 0 && list.values.length > 0 &&
                                        <p className="subheader-wrap">{list.values[0].uniqvalue ? '' : list.values[0].value}</p>
                                        }
                                            {list.values.length > 0  && list.values.map((item, index) =>
                                                    item.hasOwnProperty("uniqvalue") &&
                                               <img  key={index} className="faq-info-img" src={ USRIMG_PATH + item.uniqvalue } />


                                            )}
                                    </div>
                                )}
                            </div>


                    </div>

                </div>

                {/*{this.state.ReportShow === true &&*/}
                {/*<div>*/}
                {/*    <div dangerouslySetInnerHTML={{ __html: "<iframe src=\"https://mathrubhumi.bsynapse.com/embed/form/mathrubhumi10008\" style=\"border:1px #E4E6E9 solid;\" name=\"bsynapseform\" scrolling=\"yes\" frameborder=\"1\" marginheight=\"0px\" marginwidth=\"1px\" height=\"1080px\" width=\"750px\" allowfullscreen=\"\"></iframe>" }}></div>*/}
                {/*</div>*/}`
                {/*}*/}

            </>
        );
    }
}

const mapStateToProps = state => ({
    HelpData:state.UsersReducer.helpInfodata,
    menuClickData:state.CommonReducer.menuClick,
    sidebarmenudata: state.TaskReducer.SidebarMenudata,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    helpdata:usersActions.helpInfo,

})(HelpPanel));



