import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal";
import AddNewRole from "./AddNewRole";
import * as roleActions from "../../../actions/Role";
import * as usersActions from "../../../actions/Users";
import AddNewPhase from "../phase/AddNewPhase";
import NoData from "../../views/NoData";
import AlertModal from "../../views/AlertModal";

class Roles extends Component {
    constructor(props) {
        super(props);
        this.state={
            Addopen:false,
            roleID:0,
            loading: 0,
            offset: 30,
            lastOffset: 0,
            search:'',
            clearList : false,
            RoleList:[],
            roledeleteid:0,
            Delopen:false

        }
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);
        this.getRoleList = this.getRoleList.bind(this);
        this.showdeleteRoleModal = this.showdeleteRoleModal.bind(this);
        this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
        this.deleteRole=this.deleteRole.bind(this);
    }

    componentDidMount() {
        this.getRoleList();
        this.props.mainClick('settings','/settings/roles');
        const container = document.querySelector('.table-all-wrapper');
        container.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        const container = document.querySelector('.table-all-wrapper');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.table-all-wrapper')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;

        // //console.log(scrollTop+"----"+scrollHeight+"----"+offsetHeight+"-----"+contentHeight);

        if (contentHeight <= scrollTop) {
            this.setState({loading : 1});
            let lastOffset = this.state.lastOffset + 1;
            this.setState({lastOffset : lastOffset,clearList:false},
                function(){
                    var params  = {
                        search: this.state.search,
                        page : this.state.lastOffset + 1,
                        per_page: this.state.offset
                    }
                    this.props.updaterolelist(params);
                })

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.listAllRoleData.roles !== this.props.listAllRoleData.roles)
        {
            this.setState({
                RoleList: this.props.listAllRoleData.roles.data,
                loading: 0
            });
            this.AddmodalClose();
        }
        if ((prevProps.listAllRoleData.searchValue !== this.props.listAllRoleData.searchValue && (this.props.listAllRoleData.searchValue!== '')) || (prevProps.listAllRoleData.searchResult !== this.props.listAllRoleData.searchResult )) {
            this.setState({
                RoleList: this.props.listAllRoleData.searchResult.data,
            });
        }
        else if ((prevProps.listAllRoleData.searchValue !== this.props.listAllRoleData.searchValue && ( this.props.listAllRoleData.searchValue === '') ) || (prevProps.listAllRoleData.roles !== this.props.listAllRoleData.roles)) {
            this.setState({
                RoleList: this.props.listAllRoleData.roles.data,
                loading: 0
            });

        }
        if(prevProps.listAllRoleData !== this.props.listAllRoleData){
            this.setState({
                RoleList: this.props.listAllRoleData.roles.data,
                loading: 0
            });
        }
        if(prevProps.deleteRoles.roleDeleted !== this.props.deleteRoles.roleDeleted){
            var beta = this;
            setTimeout(function () {
                beta.getRoleList();
            }, 1000)
            this.setState({Delopen: false})


        }
    }
    getRoleList(){
        var roleParams  = {
            search: this.state.search,
            page : this.state.lastOffset + 1,
            per_page: this.state.offset
        }
        this.props.roles(roleParams);
    }

    AddmodalOpen(e, rid){
        e.stopPropagation();
        if(rid){
            this.setState({roleID : rid});
            var roleParams  = {
                roleId : rid
            }
            this.props.getroledetails(roleParams);

        }
        this.setState({Addopen : true});
    }
    AddmodalClose(){
        this.setState({Addopen : false , roleID :0});
    }
    showdeleteRoleModal(id){
        this.setState({roledeleteid: id, Delopen: true });
    }


    deleteRole(id){
        this.setState({loading : 1})
        let roledeleteid = this.state.roledeleteid;

        let deletedddata = {roledeleteid : roledeleteid};
        this.props.deleteRole(deletedddata);
    }
    cancelDeleteModal(){
        this.setState({Delopen : false});

    }

    render() {
       let RoleList = this.state.RoleList;
        return (
            <React.Fragment>
            {RoleList.length === 0 &&
                    <div className='absolute-holder'>
                        <NoData
                            msg={`No Roles added yet`}

                        />
                    </div>
            }
            <div className="table-all-wrapper">
                {(this.props.listAllRoleData.loading || this.state.loading === 1)&&
                <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                }
                <div className="table-scrollable">
                    <table className="bsy-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody className="rolelist">
                        {RoleList.map((list,index) => {
                            let description = decodeURIComponent(escape(atob(list.description)));
                                return(
                                    <tr key={"program-" + index}>
                                        <td className="high-widthed">
                                            <a>
                                                <div className="project-name-wrappe">
                                                    <div className="project-name pro-ico role-ico">
                                                        <h5 className="pj-header-list">{list.role}</h5>
                                                        <p dangerouslySetInnerHTML={{__html: ((((description.length > 100) ? (description.substr(0, 100)+'...') : description)))}} />
                                                    </div>
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            {(getProfile().id === list.creator) &&
                                            <div className="wf-btn-block">
                                                <button className="ico-lib wf-ed"
                                                        onClick={(e) => this.AddmodalOpen(e, list.roleId)}
                                                        data-action="editworkflow"></button>
                                                <button className="ico-lib  wf-dt"
                                                        onClick={(e) => {
                                                            this.showdeleteRoleModal(list.roleId)
                                                        }}></button>
                                            </div>
                                            }

                                        </td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </table>
                </div>
                {this.state.Addopen &&
                <Modal title={(this.state.roleID === 0 ? 'Create New Role' : 'Update Role')} Close ={this.AddmodalClose}>
                    <AddNewRole
                        AddmodalClose = {this.AddmodalClose}
                        action={this.state.roleID === 0 ? "create":"update"}
                    />

                </Modal>
                }
                {this.state.Delopen === true &&
                <AlertModal id="delete-vertical"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_action={() => this.deleteRole()}
                            showModal = {this.state.Delopen}
                            CANCEL_action={() => this.cancelDeleteModal()}
                />
                }
                <div className={'float-button-box'} onClick={(e)=>this.AddmodalOpen(e, 0)}>
                    <span className={'icon-in icon-plus'}></span>
                </div>
            </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    listAllRoleData:state.RoleReducer.listallRoles,
    deleteRoles :state.RoleReducer.DeleteRole

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    roles:roleActions.listallroles,
    users:usersActions.listUsers,
    getroledetails : roleActions.getroledetails,
    updaterolelist:roleActions.updaterolelist,
    deleteRole:roleActions.deleteRole

})(Roles));
