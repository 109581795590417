import {roles} from '../services/roles.services'
import {getProfile} from "../utilities/AuthService";
import {phases} from "../services/phases.services";



export const listallroles= (data) => dispatch => {
    let listallroles = roles.listroles(data);
    dispatch({
        type: 'ROLELIST_LOADER',
        loading:true,

    });
    listallroles.then(listallroles => {
        dispatch({
            type: 'LIST_All_ROLE',
            listallroles: listallroles,
            clearList : data.clearList

        })
        dispatch({
            type: 'ROLELIST_LOADER',
            loading:false,

        });
    })

};
export const updaterolelist= (data) => dispatch => {
    let listmoreroles = roles.listroles(data);
    listmoreroles.then(listmoreroles => {
        dispatch({
            type: 'LOAD_MORE_ROLES',
            listmoreroles: listmoreroles,

        })
    })

};
export const getroledetails= (data) => dispatch => {
    let roledetails = roles.listroles(data);
    dispatch({
        type: 'GET_ROLEDET_LOADER',
        loading:true,

    });
    roledetails.then(roledetails => {
        dispatch({
            type: 'GET_ROLEDET',
            roleDet: roledetails,


        })
        dispatch({
            type: 'GET_ROLEDET_LOADER',
            loading:false,

        });
    })

};

export const SaveRole= (params) => dispatch => {
    dispatch({
        type: 'ROLESAVE_LOADER',
        loading:true,

    });
    let roleSaved = roles.SaveRoles(params);

    let newRole = {
        "roleId" : params._id,
        "role": params.role,
        "description": btoa(unescape(encodeURIComponent(params.description))),
        "uid": getProfile().id,
        "mode": params.mode,
        "creator":params.creator
    };


    roleSaved.then(roleSaved => {

        dispatch({
            type: 'UPDATE_ROLE',
            newRole: newRole

        })
        dispatch({
            type: 'ROLESAVE_LOADER',
            loading:false,

        });
    })
};
export const getprojectroles= (data) => dispatch => {
    let projectroledetails = roles.ProjectRoles(data);
    dispatch({
        type: 'PROJECT_ROLES_LOADER',
        loading:true,

    });
    projectroledetails.then(projectroledetails => {
        dispatch({
            type: 'GET_PROJECT_ROLES',
            projectRoles: projectroledetails,


        })
        dispatch({
            type: 'PROJECT_ROLES_LOADER',
            loading:false,

        });
    })

};
export const rolestobeassigned = (data) => dispatch => {
    let rolestobeassignedlist = roles.RolestobeAssigned(data);
    dispatch({
        type: 'ROLES_TO_BE_ASSIGNED_LOADER',
        loading:true,

    });
    rolestobeassignedlist.then(rolestobeassignedlist => {
        dispatch({
            type: 'ROLES_TO_BE_ASSIGNED',
            rolestobeassignedlist: rolestobeassignedlist,


        })
        dispatch({
            type: 'ROLES_TO_BE_ASSIGNED_LOADER',
            loading:false,

        });
    })

};
export const assignroles= (data) => dispatch => {
    let assignroledetails = roles.AssignRoles(data);
    dispatch({
        type: 'ASSIGN_ROLES_TO_PROJECT_LOADER',
        loading:true,

    });
    assignroledetails.then(assignroledetails => {
        dispatch({
            type: 'ASSIGN_ROLES_TO_PROJECT',
            roleassigned: assignroledetails,


        })
        dispatch({
            type: 'ASSIGN_ROLES_TO_PROJECT_LOADER',
            loading:false,

        });
    })

};
export const getusersrolelist= (data) => dispatch => {
    let usersrolelistdetails = roles.UserRoles(data);
    dispatch({
        type: 'USERS_ROLE_LIST_LOADER',
        loading:true,

    });
    usersrolelistdetails.then(usersrolelistdetails => {
        dispatch({
            type: 'USERS_ROLE_LIST',
            usersrolelistdetails: usersrolelistdetails,


        })
        dispatch({
            type: 'USERS_ROLE_LIST_LOADER',
            loading:false,

        });
    })

};
export const assignuserroles= (data) => dispatch => {
    let userroleupdated = roles.AssignUserRole(data);
    dispatch({
        type: 'USERS_ROLE_UPDATED_LIST_LOADER',
        loading:true,

    });
    userroleupdated.then(userroleupdated => {
        dispatch({
            type: 'USERS_ROLE_UPDATED_LIST',
            userroleupdated: userroleupdated,


        })
        dispatch({
            type: 'USERS_ROLE_UPDATED_LIST_LOADER',
            loading:false,

        });
    })

};
export const deleteRole = (params) => dispatch => {

    let roledeleted = roles.DeleteRoles(params);


    roledeleted.then(roledeleted => {

        dispatch({
            type: 'DELETE_ROLE',
        })
    })
};
