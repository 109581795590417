import React from 'react';
import ColorPicker from "../views/ColorPicker";
import {getUrlSegment} from "../../../../utilities/CustomFunctions";

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : "#fff",
    ...draggableStyle
});

class PaymentSettingsModal extends React.Component {

    render() {
        let collections = this.props.containerData.collectionData
        let PRcollections = this.props.containerData.PRcollectionData
        let collection = this.props.containerData.collection
        let showlistField = this.props.containerData.showlistField.concat({
            type: "datepicker",
            masterid: "101",
            display_label: "CreateDate"
        })
        let sourceType = this.props.containerData.sourceType
        let PRsourceType = this.props.containerData.PRsourceType
        let PRcollection = this.props.containerData.PRcollection
        let responseFormComponents = this.props.containerData.responseFormComponents || [];


        return (
            <div className="modal bsy-modal lg-modal body-grey fade show" style={{"display": "block"}}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.title}</h5>
                            <div class="modal-header-options">
                                <button class="modal-option-button" onClick={() => this.props.Close()}><span
                                    class="icon-in icon-close-in"></span></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="chart-settings-container">
                                <div className="chart-settings-splitter">
                                    <div className="chart-settings-splitter-header">
                                        <h5>Data</h5>
                                    </div>

                                    <div className="each-settings-panel-wrap">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Data Set</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="each-inp-field">
                                                <label>Select Type</label>
                                                <div className="radio-wrap">
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Collection
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`sourceType`}
                                                                   checked={sourceType.toString() === "true"}
                                                                   value={true}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Entity
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`sourceType`}
                                                                   checked={sourceType.toString() === "false"}
                                                                   value={false}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {sourceType.toString() === "true" ?
                                                <div className="each-inp-field">
                                                    <label>Choose Collection</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="collection"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={collection}>>
                                                            <option value="">Choose Collection</option>
                                                            {collections.map((list, index) =>
                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list.title)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                :
                                                <div className="each-inp-field">
                                                    <label>Choose Entity</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="collection"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={collection}>
                                                            <option value="">Choose Entity</option>
                                                            {collections.map((list, index) =>

                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list._source.name)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            <div className="each-inp-field">
                                                <label>Choose Field</label>
                                                <div className="select-box eq-width">
                                                    <select id="priority" name="fields"
                                                            onChange={(e) => this.props.handleInputChange(e)}
                                                            value={this.props.containerData.fields}>
                                                        <option value="">Please Select</option>
                                                        <option value="101">CreateDate</option>
                                                        {showlistField.map((label, index) =>
                                                            <option
                                                                value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="each-settings-panel-subheader">
                                            <h5>Payment mode</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="task-input-container">
                                                <div className="select-chk-block"
                                                     style={{"display": "block"}}>
                                                    <label className="control control-checkbox">Razorpay
                                                        <input type="radio" value={`Razorpay`} className="inp-chk"
                                                               id="payment_mode" name="paymentMode"
                                                               checked onClick={(e) => this.props.radioBoxchange(e)}
                                                        />
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="each-settings-panel-subheader">
                                            <h5>Payment Url Settings</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="task-input-container">
                                                <div className="each-inp-field">
                                                    <label>Button Text</label>
                                                    <div className="new-task-input">
                                                        <input type="text" placeholder="Generate Payment Url"
                                                               id="button_text" name="paymentButtonText"
                                                               autoComplete="off"
                                                               value={this.props.containerData.paymentButtonText}
                                                               onChange={(e) => this.props.handleChange(e)}/>
                                                    </div>
                                                </div>

                                                <div className="each-inp-field">
                                                    <label>Send Mode</label>
                                                    <div className="radio-wrap">
                                                        {this.props.containerData.hasOwnProperty("email") &&
                                                        <div className="radio-block">
                                                            <label
                                                                className="control control-checkbox">
                                                                <input className="inp-chk"
                                                                       type={`checkbox`}
                                                                       name={`email`}
                                                                       checked={this.props.containerData.email || false}
                                                                       value={this.props.containerData.email || false}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                                Email
                                                            </label>
                                                        </div>
                                                        }
                                                        {this.props.containerData.hasOwnProperty("whatsapp") &&
                                                        <div className="radio-block">
                                                            <label
                                                                className="control control-checkbox">
                                                                <input className="inp-chk"
                                                                       type={`checkbox`}
                                                                       name={`whatsapp`}
                                                                       checked={this.props.containerData.whatsapp || false}
                                                                       value={this.props.containerData.whatsapp || false}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                                Whatsapp
                                                            </label>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="each-settings-panel-subheader">
                                            <h5>Color Settings</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="task-input-container">
                                                {this.props.containerData.hasOwnProperty("cardcolor") &&
                                                <div className="each-inp-field">
                                                    <label>Card Colour</label>
                                                    <ColorPicker
                                                        stroke={"cardcolor"}
                                                        color={this.props.containerData.cardcolor}
                                                        onChangeComplete={this.props.handleChangeComplete}
                                                        handleClickStaus={this.props.handleClick}
                                                    />
                                                </div>
                                                }
                                                {this.props.containerData.hasOwnProperty("labelcolor") &&
                                                <div className="each-inp-field">
                                                    <label>Label Colour</label>
                                                    <ColorPicker
                                                        stroke={"labelcolor"}
                                                        color={this.props.containerData.labelcolor}
                                                        onChangeComplete={this.props.handleChangeComplete}
                                                        handleClickStaus={this.props.handleClick}
                                                    />
                                                </div>
                                                }

                                                {this.props.containerData.hasOwnProperty("valuecolor") &&
                                                <div className="each-inp-field">
                                                    <label>Value Colour</label>
                                                    <ColorPicker
                                                        stroke={"valuecolor"}
                                                        color={this.props.containerData.valuecolor}
                                                        onChangeComplete={this.props.handleChangeComplete}
                                                        handleClickStaus={this.props.handleClick}
                                                    />
                                                </div>
                                                }


                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="chart-settings-splitter">
                                    <div className="chart-settings-splitter-header">
                                        <h5>Display Properties</h5>
                                    </div>
                                    {this.props.containerData.hasOwnProperty("chartTitle") &&
                                    <div className="each-settings-panel-wrap">
                                        <div class="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Chart Details</h5>
                                        </div>
                                        <div class="each-settings-panel-wrap-body">
                                            {this.props.containerData.hasOwnProperty("chartTitle") &&
                                            <div className="each-inp-field">
                                                <label>Chart Title</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="title" id="chartTitle"
                                                           name="chartTitle"
                                                           autoComplete="off"
                                                           value={this.props.containerData.chartTitle}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("subTitle") &&
                                            <div className="each-inp-field">
                                                <label>Subtitle</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="subtitle" id="subTitle"
                                                           name="subTitle"
                                                           autoComplete="off" value={this.props.containerData.subTitle}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("subTitleEnabled") &&
                                            <div className="each-inp-field">
                                                <div className="select-chk-block" style={{"display": "block"}}>
                                                    <label className="control control-checkbox">Enable Subtitle
                                                        <input className="inp-chk" type={`checkbox`}
                                                               name={`subTitleEnabled`}
                                                               checked={this.props.containerData.subTitleEnabled}
                                                               value={this.props.containerData.subTitleEnabled}
                                                               onClick={(e) => this.props.checkBoxchange(e)}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("formLabel") &&
                                            <div className="each-inp-field">
                                                <label>Form Label</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="Label" id="formLabel"
                                                           name="formLabel"
                                                           autoComplete="off" value={this.props.containerData.formLabel}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("formUrl") &&
                                            <div className="each-inp-field">
                                                <label>Form Url</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="Url" id="formUrl" name="formUrl"
                                                           autoComplete="off" value={this.props.containerData.formUrl}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    }


                                    <div className="each-settings-panel-wrap ">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Payment Page Details</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="task-input-container select-line">
                                                <div className="each-inp-field">
                                                    <label>Title</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="paymentTitle"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.paymentTitle}>
                                                            <option value="">Please Select</option>
                                                            <option value="101">CreateDate</option>
                                                            {showlistField.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Description</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="paymentDesc"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.paymentDesc}>
                                                            <option value="">Please Select</option>
                                                            <option value="101">CreateDate</option>
                                                            {showlistField.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Image</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="paymentImage"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.paymentImage}>
                                                            <option value="">Please Select</option>
                                                            <option value="101">CreateDate</option>
                                                            {showlistField.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>No of Items</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="paymentItems"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.paymentItems}>
                                                            <option value="">Please Select</option>
                                                            <option value="101">CreateDate</option>
                                                            {showlistField.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Amount</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="paymentAmt"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.paymentAmt}>
                                                            <option value="">Please Select</option>
                                                            <option value="101">CreateDate</option>
                                                            {showlistField.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>


                            <div className="chart-settings-container">
                                <div className="chart-settings-splitter container-full-width">

                                    <div className="each-settings-panel-wrap">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Choose Response Mapping
                                                Dataset</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="each-inp-field">
                                                <label>Select Type</label>
                                                <div className="radio-wrap">
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Collection
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`PRsourceType`}
                                                                   checked={PRsourceType.toString() === "true"}
                                                                   value={true}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Entity
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`PRsourceType`}
                                                                   checked={PRsourceType.toString() === "false"}
                                                                   value={false}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {PRsourceType.toString() === "true" ?
                                                <div className="each-inp-field">
                                                    <label>Choose Collection</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="PRcollection"
                                                                onChange={(e) => this.props.handlePRInputChange(e)}
                                                                value={PRcollection}>>
                                                            <option value="">Choose Collection</option>
                                                            {PRcollections.map((list, index) =>
                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list.title)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                :
                                                <div className="each-inp-field">
                                                    <label>Choose Entity</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="PRcollection"
                                                                onChange={(e) => this.props.handlePRInputChange(e)}
                                                                value={PRcollection}>
                                                            <option value="">Choose Entity</option>
                                                            {PRcollections.map((list, index) =>

                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list._source.name)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="each-settings-panel-wrap ">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Payment Response Settings</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="task-input-container select-line">
                                                <div className="each-inp-field">
                                                    <label>Payment Reference ID</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="PRUniqueId"
                                                                onChange={(e) => this.props.handlePRInputChange(e)}
                                                                value={this.props.containerData.PRUniqueId}>
                                                            <option value="">Please Select</option>
                                                            {responseFormComponents.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Payment ID</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="PRID"
                                                                onChange={(e) => this.props.handlePRInputChange(e)}
                                                                value={this.props.containerData.PRID}>
                                                            <option value="">Please Select</option>
                                                            {responseFormComponents.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Payment Amount</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="PRAmount"
                                                                onChange={(e) => this.props.handlePRInputChange(e)}
                                                                value={this.props.containerData.PRAmount}>
                                                            <option value="">Please Select</option>
                                                            {responseFormComponents.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Payment Method</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="PRMethod"
                                                                onChange={(e) => this.props.handlePRInputChange(e)}
                                                                value={this.props.containerData.PRMethod}>
                                                            <option value="">Please Select</option>
                                                            {responseFormComponents.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Payment Status</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="PRStatus"
                                                                onChange={(e) => this.props.handlePRInputChange(e)}
                                                                value={this.props.containerData.PRStatus}>
                                                            <option value="">Please Select</option>
                                                            {responseFormComponents.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Payment Date</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="PRDate"
                                                                onChange={(e) => this.props.handlePRInputChange(e)}
                                                                value={this.props.containerData.PRDate}>
                                                            <option value="">Please Select</option>
                                                            <option value="101">CreateDate</option>
                                                            {responseFormComponents.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Program Name</label>
                                                    <div className="select-box eq-width">
                                                        <select id="priority" name="PRprogram"
                                                                onChange={(e) => this.props.handlePRInputChange(e)}
                                                                value={this.props.containerData.PRprogram}>
                                                            <option value="">Please Select</option>
                                                            {responseFormComponents.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                        {getUrlSegment(1) !== "entity" ?
                            <div class="modal-footer">
                                <button type="button" class="general-btn grey btn btn-primary"
                                        onClick={() => this.props.cancelAction()}>Cancel
                                </button>
                                <button type="submit" class="general-btn btn btn-primary"
                                        onClick={() => this.props.OKAction()}>Apply
                                </button>
                            </div>
                            :
                            <div className="modal-footer">
                                <button type="button" className="general-btn grey btn btn-primary"
                                        onClick={() => this.props.cancelAction()}>Ok
                                </button>

                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }

}


export default PaymentSettingsModal
