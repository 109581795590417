import React, {Component} from 'react';
import {
    Treemap,
    BarChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Area,
    Legend,
    ResponsiveContainer,
    LabelList, AreaChart
} from 'recharts';
const data = [
    {
        name: 'axis',
        children: [
            { name: 'Axes', size: 1302 }
        ],
    },
    {
        name: 'controls',
        children: [
            { name: 'AnchorControl', size: 2138 },
        ],
    },
    {
        name: 'controls123',
        children: [
            { name: 'AnchorControl123', size: 2138 },
        ],
    }
];
const COLORS = ['#8889DD', '#9597E4', '#8DC77B', '#A5D297', '#E2CF45', '#F8C12D'];
class CustomizedContent extends React.Component {

    render() {
        const { root, depth, x, y, width, height, index, payload, colors, rank, name } = this.props;

        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    style={{
                        fill: depth < 2 ? colors[Math.floor(index / root.children.length * 6)] : 'none',
                        stroke: '#fff',
                        strokeWidth: 2 / (depth + 1e-10),
                        strokeOpacity: 1 / (depth + 1e-10),
                    }}
                />
                {
                    depth === 1 ?
                        <text
                            x={x + width / 2}
                            y={y + height / 2 + 7}
                            textAnchor="middle"
                            fill="#fff"
                            fontSize={14}
                        >
                            {name}
                        </text>
                        : null
                }
                {
                    depth === 1 ?
                        <text
                            x={x + 4}
                            y={y + 18}
                            fill="#fff"
                            fontSize={16}
                            fillOpacity={0.9}
                        >
                            {index + 1}
                        </text>
                        : null
                }
            </g>
        );
    }
};
function TreeMap(props) {
    return (
        <React.Fragment>
            <ResponsiveContainer width="100%" height={300} >
                <Treemap
                    width={400}
                    height={200}
                    data={data}
                    dataKey="size"
                    ratio={4 / 3}
                    stroke="#fff"
                    fill="#8884d8"
                    content={<CustomizedContent colors={COLORS}/>}
                />
            </ResponsiveContainer>

        </React.Fragment>
    );
}

export default TreeMap;