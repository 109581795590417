import React, {Component} from 'react';
import {ES_INDEX, FRMSIMG_PATH, USRIMG_PATH} from "../../../constants";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {Datecomparison, Datetimecomparison, getUrlSegment} from "../../../utilities/CustomFunctions";
import * as commonActions from "../../../actions/Common";
import {getProfile} from "../../../utilities/AuthService";
import rootActions from "../../../actions";
import Modal from "../../views/Modal";
import AddNewDiscussionDetail from "./AddNewDiscussionDetail";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import ProjectHeader from "../../views/ProjectHeader";


class ProjectDiscussionsDetail extends Component {

    constructor(props) {
        super(props);

        this.state={
            projectid:'',
            Addopen:false,
            Editopen:false,
            userlist: 'false',
            showremarksModal: true,
        }
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.closeremarksModal = this.closeremarksModal.bind(this);
        this.saveUserlist = this.saveUserlist.bind(this);
    }



    componentDidMount() {
        this.setState({loading: 1});
        var prjctdtlid = getUrlSegment(5);
         this.props.mainClick('project', '/project/details/discussions/'+prjctdtlid);
        var projectid = (atob(getUrlSegment(5)));
        //console.log(projectid)
        let data ={
            "_id": projectid,
        }
        this.props.projectdetail(data)
        var discussionid = (atob(getUrlSegment(6)));
        //console.log(discussionid)
        let cdata ={
            "projectid": projectid,
            "discussionid": discussionid,

        }
        this.props.getDiscussionsDetails(cdata)
        let alldata ={
            "discussionid": discussionid,

        }
        this.props.getAllComments(alldata)
        let Pdata ={
            "projectid": projectid,
        }
        this.props.projectuserdetail(Pdata);

        this.setState({
            projectid: projectid,
            discussionid: discussionid
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        var projectid = (atob(getUrlSegment(5)));
        //console.log( projectid)
        var discussionid = (atob(getUrlSegment(6)));
        //console.log(discussionid)
        var  beta =  this;
        if(prevProps.commentsAdddata.project_comments !== this.props.commentsAdddata.project_comments ) {
            setTimeout(function () {
                let alldata ={
                    "discussionid": discussionid,

                }
                beta.props.getAllComments(alldata)
            }, 1000)
            this.AddmodalClose();

        }

        if(prevProps.userlistAssignmoredata.assigned_users !== this.props.userlistAssignmoredata.assigned_users ) {
            setTimeout(function () {
                let cdata ={
                    "projectid": projectid,
                    "discussionid": discussionid,

                }
                beta.props.getDiscussionsDetails(cdata);
            }, 1000)



        }

    }

    AddmodalOpen(e, pid){
        e.stopPropagation();

        if(pid){
            this.setState({prjct_id : pid});
            let projectdata = {_id: pid}
            // this.props.projectInfo(projectdata);
        }
        this.setState({Addopen : true});
    }

    AddmodalClose(){
        this.setState({Addopen : false});
    }
    handleUserChange(e, userlist, assignees, discussion_id){

        //console.log(assignees);
        let userdetail = this.props.listAllProjectUserData.getprojectusers;
        let user_ids = userdetail.map(a => parseInt(a.user_id));
        //console.log(user_ids);
        let assigneeslist =  assignees.map(a => parseInt(a.assigneeuser_id)) ;
        //console.log(assigneeslist);

        this.setState({
            userlist: userlist,
            user_ids: user_ids,
            assigneeslist: assigneeslist,
            discussion_id: discussion_id
        })
    }
    closeremarksModal(){
        this.setState({userlist : false});
    }
    handleUserSelect(e, selectUserid){

        selectUserid = parseInt(selectUserid);
        //console.log(this.state.assigneeslist )
        let assigneeslist = [...this.state.assigneeslist];
        (assigneeslist.indexOf(selectUserid) > -1) ? assigneeslist.splice(assigneeslist.indexOf(selectUserid), 1) : assigneeslist.push(selectUserid);
        this.setState({assigneeslist: assigneeslist});

    }
    saveUserlist(){

        //console.log(this.state.assignee_ids )
        var assigneeslist = this.state.assigneeslist;
        var discussion_id = this.state.discussion_id;
        var projectid = (this.state.projectid =='0' ? moment().valueOf() : this.state.projectid);

        let assignmoreusers = {
            projectid: projectid,
            discussion_id: discussion_id,
            assignees: assigneeslist,
        };

        //console.log(assignmoreusers);
        this.props.saveAssignedusers(assignmoreusers);
        this.setState({userlist : false});

    }

    render() {
        let projectasks = this.props.listAllProjectDetailData.ListAllProjectsdetails.task;
        let discussionsdetails = this.props.discussionsdetailsdata.getdiscussionsdetails;
        //console.log(discussionsdetails)
        let allcommentsdata = this.props.getallcommentsdata.getallcomments;
        //console.log(allcommentsdata)
        let userdetail = this.props.listAllProjectUserData.getprojectusers;

        var today = new Date();
        var date = today.getDate();
        var month = today.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        var year = today.getFullYear();
        var curr_date = year + '-' + month + '-' + date ;

        return(

            <div className="list-wrapper">
                <div className="form-detail-container data-view field-form">
                    {this.props.discussionsdetailsdata.loading &&
                    <span className="Loader-holder">
                                                <LoaderSvg/>
                                            </span>
                    }
                    {(discussionsdetails.length === 0) &&
                    <div className='absolute-holder'>
                        <NoData

                            msg={`No Discussions assigned to you`}

                        />
                    </div>
                    }
                    <ProjectHeader/>
                    <div className="pj-board-container">
                        <div className="pj-chat-wrapper">
                            <div className="pj-detail-chat-wrapper">
                                {discussionsdetails.map((list,index) =>
                                <div className="pj-detail-chat-details" key={index}>
                                    <div className="parent-chat">
                                        <div className="parent-left">
                                            <div className="chat-list-left-top">
                                                <div className="chat-list-left-top-left">
                                                    <img src={USRIMG_PATH + list.creator_img_name} />
                                                </div>
                                                <div className="chat-list-left-top-right">
                                                    <h5>{list.topic}</h5>
                                                    {list.lastmessages !== '' && list.lastmessages[0] !== undefined &&
                                                    <p className="ico-lib-v1 comm-ico">Latest reply from @
                                                        <span className="mention">{list.lastmessages[0].messageuser_fname}</span>
                                                        { ' ' + moment.utc(list.lastmessages[0].posteddate).format('DD MMM YYYY') + ', ' + moment.utc(list.lastmessages[0].posteddate).local().format('LT')}
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="parent-right">
                                            <ul className="pj-added-user">
                                                {list.assignees.map((item,index) =>
                                                <li key={index}>
                                                    <div className="ind-added-user-list">
                                                        <img src={USRIMG_PATH + item.assigneeuser_img_name} />
                                                    </div>
                                                </li>
                                                )}
                                                <li onClick={(e)=>this.handleUserChange(e, 'true', list.assignees, list.discussion_id)}>
                                                    <button className="float-add-button"></button>
                                                </li>

                                            </ul>



                                        </div>
                                        <div className="parent-bottom">
                                            <p>{list.description}</p>
                                        </div>
                                    </div>

                                </div>
                                )}
                            </div>
                            {allcommentsdata.length !== 0 &&
                            <div className="pj-detail-chat-wrapper">
                                {allcommentsdata.map((item, index) =>
                                    <div className="sub-chat" key={index}>
                                        <div className="parent-left">
                                            <div className="chat-list-left-top">
                                                <div className="chat-list-left-top-left">
                                                    <img src={USRIMG_PATH + item.messageuser_img_name}/>
                                                </div>
                                                <div className="chat-list-left-top-right">
                                                    <h5>{item.messageuser_fname + ' ' + item.messageuser_lname}</h5>
                                                    <p className="comm-ico">Posted
                                                        on {moment.utc(item.posteddate).format('DD MMM YYYY') + ', ' + moment.utc(item.posteddate).local().format('LT')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="parent-bottom">
                                            <p dangerouslySetInnerHTML={{__html: atob(item.message)}}></p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Discussion Detail" onClick={(e)=>this.AddmodalOpen(e, this.state.projectid)}>
                <button className="float-button ico-lib float-add"></button>
                </div>

                {this.state.Addopen &&
                <Modal title={(this.state.prjct_id ? 'New Comment' : 'New Comment')} Close ={this.AddmodalClose.bind(this)}>
                    <AddNewDiscussionDetail
                        AddmodalClose = {this.AddmodalClose}
                        userid={allcommentsdata}
                        projectid={this.state.projectid}
                        discussionid={this.state.discussionid}
                    />

                </Modal>
                }

                {this.state.userlist === 'true' &&
                <div className="modal fade bsy-modal s-modal show x-large"  tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">User List</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closeremarksModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="mang-lbl-block un-tick">
                                    <div className="user-list-container" id="Usrlst">

                                        {userdetail.map((list,index) =>
                                            <div key={index} className={`${this.state.assigneeslist.indexOf(parseInt(list.user_id)) > -1 ? 'selected-user':''}`}>
                                                <div className="ind-user-list" data-action="filter"
                                                     data-hideuser={list.user_id}>
                                                    <div className="ind-user-list-left" onClick={(e)=>this.handleUserSelect(e, list.user_id, )}>
                                                        <div className="ind-user-img"><img
                                                            src={USRIMG_PATH + list.img_name} />
                                                        </div>
                                                        <div className="ind-user-name" >
                                                            {list.first_name + ' ' + list.last_name}</div>
                                                    </div>
                                                    <div className="ind-user-list-right">
                                                        <div className="ind-user-list-right">
                                                            <div className="usr-select-button" style={{display:'none'}}><input
                                                                name="text_searchable" type="checkbox" value={list.user} className="input-check" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="general-btn grey" data-dismiss="modal"  onClick={this.closeremarksModal}>Cancel</button>
                                <button type="button" className="general-btn" onClick={this.saveUserlist}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                }

            </div>



        );
    }
}
const mapStateToProps = state => ({
    listAllProjectDetailData:state.ProjectReducer.listallProjectsdetails,
    discussionsdetailsdata:state.ProjectReducer.GetDiscussionsDetails,
    getallcommentsdata:state.ProjectReducer.GetAllComments,
    commentsAdddata:state.ProjectReducer.SaveProjectComments,
    listAllProjectUserData:state.ProjectReducer.GetProjectUsers,
    userlistAssignmoredata:state.ProjectReducer.SaveAssignedUsers,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    projectdetail:projectActions.listallprojectsdetails,
    getDiscussionsDetails : projectActions.getDiscussionsDetails,
    getAllComments : projectActions.getAllComments,
    saveAssignedusers:projectActions.saveAssignedUsers,
    projectuserdetail:projectActions.getProjectUsers,
})(ProjectDiscussionsDetail));
