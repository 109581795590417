import React, {Component} from 'react';
import {FRMSIMG_PATH} from '../../constants/index'
import Coverimage from '../../images/placeholders/Coverimage.jpg';
import {getUrlSegment} from "../../utilities/CustomFunctions";
import OutsideAlerter from "./OutsideAlerter";
import moment from 'moment';
import {getProfile} from "../../utilities/AuthService";
class FormPanel extends Component {

    constructor(props) {
        super(props);
        this.Minimize=this.Minimize.bind(this)
        this.Maximise=this.Maximise.bind(this)
        this.showOptions=this.showOptions.bind(this)
        this.state={
            miniMize:false,
            Maximise:false,
            Close:false,
            showOption : false
        }
    }



    componentDidMount() {
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    handleAction(e, act, id) {
        this.props.updateAction(e, act, this.props.list, id);
    }
    Minimize(){
        this.setState({Maximise:false,miniMize:true})
    }
    Maximise(){
        if(!this.state.miniMize) {
            this.setState({Maximise: !this.state.Maximise, miniMize: false})
        }
        else{
            this.setState({Maximise: false, miniMize: false})
        }
    }
    showOptions(){
        this.setState({showOption : !this.state.showOption});
    }

    render() {

         var list = this.props.list;
        let detailUrl = '';

        if(list.has_form === 'yes') {
            detailUrl = getUrlSegment(1) === 'entity' ? '/entity/details/' + btoa(list._id) : '/collections/details/' + btoa(list._id);

            if(getUrlSegment(1) === 'project') {
                detailUrl = '/project/details/collectiondetails/' + btoa(list._id)+'/'+getUrlSegment(4);
            }
        }

         return (
             <div className="tile-each" key={btoa(list._id)}
                  onClick={(e)=> (list.has_form === 'yes' ) ?
                      this.props.history.push(detailUrl) : this.props.buildForm(e, list._id)}>
                 <div className="tile-info-all">
                     <div className="tile-description-box list-with-icon collection-list-icon">
                         <div onClick={(e)=> (list.has_form === 'yes' ) ?
                             this.props.history.push(detailUrl) : this.props.buildForm(e, list._id)}>
                         <h5>{atob(list.title)}</h5><p>{atob(list.description)}</p>
                             {list.complaincecategory && list.complaincecategory !== "" &&
                             <p className={'category-tag'}>Category : {list.complaincecategory}</p>
                             }
                             </div>
                         {this.props.type ==='Project' && list.createuser === parseInt(getProfile().id) &&
                             <div className="form-option-block nav-option">
                                 <button className="ico-lib opt-form-ico" onClick={() => this.showOptions()} style={{pointerEvents: this.state.showOption === "true" ? "none" : ''}}></button>
                                 {this.state.showOption  === true  &&
                                 <OutsideAlerter status={this.state.showOption} Triggered={() => this.showOptions(!this.state.showOption)}>
                                     <ul className="panel-option" style={{display: "block"}}>
                                         <li>
                                             <a className="panel-option-click ico-lib form-edit-ico"
                                                onClick={(e) => this.props.showCreateNewFormModal(e, list._id)}>Edit</a>
                                         </li>
                                         {list.has_form === 'no' &&
                                         <li>
                                             <a onClick={(e) => this.props.buildForm(e, list._id)}
                                                className="panel-option-click ico-lib-v1 build-form-ico">Build Form</a>
                                         </li>
                                         }
                                     </ul>
                                 </OutsideAlerter>
                                 }
                             </div>
                         }
                     </div>
                     <div className="tile-options-in">
                         <div className="date-data">{moment(list.createdate).format("Do MMM, YYYY")}</div>
                         {/*<div className="other-info">*/}
                         {/*    <span className="ico-lib icon-form-task icon-style">{list.childs}</span>*/}
                         {/*    /!*<span className="ico-lib icon-form-share icon-style">{list.share}</span>*!/*/}
                         {/*</div>*/}
                         {/*<div className="span-box-in">*/}
                         {/*    <span className="icon-in icon-stack-filled"></span>*/}
                         {/*    <span className="item-count-in">{list.childs}</span>*/}
                         {/*</div>*/}

                         {/*<div className="span-box-in">*/}
                         {/*    <span className="icon-in icon-share-in"></span>*/}
                         {/*    <span className="item-count-in">{list.share}</span>*/}
                         {/*</div>*/}
                     </div>
                 </div>
             </div>



        );
    }
}

export default FormPanel;