import {collections} from '../services/collections.services'
import {entity} from '../services/entity.services'
import {getProfile} from "../utilities/AuthService";
import {workflow} from "../services/workflow.services";
import {ES_INDEX} from "../constants";

export const Entity = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATES_STATUS',
        loaderType: 'entity-list',
        status:true

    });
    let Entity = entity.searchEntity(params);
    Entity.then(Entity => {
        dispatch({
            type: 'ENTITY',
            Entity: Entity,
            search:params.search,
            optionOpened: 0,
            group:params.group

        });
        dispatch({
            type: 'LOADER_UPDATES_STATUS',
            loaderType: 'entity-list',
            status: false

        });
    }).catch(error => {
            dispatch({
                type: 'ENTITY',
                Entity:{data: {data:[]}},
                group:[],
                search:[],
                optionOpened: 0

            })
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'collection-list',
                status: false

            });
        })






};

export const updateEntity = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-list',
        status:true

    });
    let Entity = entity.searchEntity(params);
    Entity.then(Entity => {
        dispatch({
            type: 'LOAD_MORE_ENTITY',
            Entity: Entity,
            search:params.search,
            optionOpened: 0

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-list',
            status: false

        });
    })

};
export const updatesearchentity = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-list',
        status:true

    });
    let Entity = entity.searchEntity(params);
    Entity.then(Entity => {
        dispatch({
            type: 'LOAD_MORE_SEARCH_ENTITY',
            Entity: Entity,
            search:params.search,
            optionOpened: 0

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-list',
            status: false

        });
    })

};

export const selectedcollections = (params) => dispatch => {
    let selectedcollections = entity.selectedCollections(params);
    selectedcollections.then(selectedcollections => {
        dispatch({
            type: 'LIST_SELECTED_COLLECTIONS',
            selectedcollections: selectedcollections
        })
    })

};
export const entityActions = () => dispatch => {
    dispatch({
        type: 'RESET_ENTITY_DATA',
    });


};
export const collectionDetail = (id, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let listCollectionDetail = entity.listCollectionDetail(id, params);
    listCollectionDetail.then(listCollectionDetail => {
        dispatch({
            type: 'LIST_ENTITY_DETAIL',
            listCollectionDetail: listCollectionDetail,
        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    })
};
export const calendarSettingsDetail = (id, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let listCollectionDetail = entity.listCollectionDetail(id, params);
    listCollectionDetail.then(listCollectionDetail => {
        dispatch({
            type: 'LIST_CALENDAR_DETAIL',
            listCollectionDetail: listCollectionDetail,
        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    })
};

export const collectionDetailsGe = (id, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS_GE',
        loaderType: 'collection-details-ge',
        status:true

    });
    let listCollectionDetail = entity.listCollectionDetail(id, params);
    listCollectionDetail.then(listCollectionDetail => {
        dispatch({
            type: 'LIST_ENTITY_DETAILS_GE',
            listCollectionDetail: listCollectionDetail,
        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS_GE',
            loaderType: 'collection-details-ge',
            status:false

        });
    })
};

export const loadmoreEntityDetails = (id, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let listEntityDetail = entity.listCollectionDetail(id, params);

    listEntityDetail.then(listEntityDetail => {
        dispatch({
            type: 'LOADMORE_ENTITY_DETAIL',
            listEntityDetail: listEntityDetail,

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    })
};



export const collectionFilter = (id, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let listCollectionDetail = entity.listCollectionDetail(id, params);

    listCollectionDetail.then(listCollectionDetail => {
        dispatch({
            type: 'PAGE_HEADLINE',
            title: listCollectionDetail.data.results.parentinfo[0].title,
        });
        dispatch({
            type: 'LIST_COLLECTIONFILTER',
            listCollectionFilter: listCollectionDetail,
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    }) .catch(error => {
        dispatch({
            type: 'PAGE_HEADLINE',
            title: [],
        });
        dispatch({
            type: "NET_WORK_ERROR",
            status:true,

        })
        dispatch({
            type: 'LIST_COLLECTIONFILTER',
            listCollectionFilter: {data:{results:{data:[]}}},
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    })






};

export const collectionDataDelete = (collectionid, uid, records) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let deleteCollectionData = entity.deleteCollectionRecords(collectionid, uid, records);
    deleteCollectionData.then(deleteCollectionData => {
        dispatch({
            type: 'MODIFY_COLLECTION_RECORDS',
            deleteCollectionData: deleteCollectionData,

        });

    })
};

export const savemetaform = (params, type, params2) => dispatch => {
    //////////console.log(params);
    dispatch({
        type: 'SAVE_FORM_LOADING',
        loading: true,

    });
    let metaformdata = entity.saveMetaform(params);

    metaformdata.then(metaformdata => {

        if(type && type === 'withoutdecision') {

            let Processworkflowtask = workflow.ProcessNextStage(params2);
            Processworkflowtask.then(Processworkflowtask => {

                dispatch({
                    type: 'PROCESS_WORKFLOW_TASK',
                    processWorkflowtask: Processworkflowtask

                })
                dispatch({
                    type: 'SAVE_METAFORM',
                    metaformdata: metaformdata,

                })
                dispatch({
                    type: 'SAVE_FORM_LOADING',
                    loading: false,

                });
            })
        }
        else if(type && type === 'throughapi') {

            let Processworkflowtask = workflow.ProcessThroughApi(params2);

            Processworkflowtask.then(Processworkflowtask => {

                dispatch({
                    type: 'PROCESS_THROUGH_API',
                    processResult: Processworkflowtask

                })

                dispatch({
                    type: 'SAVE_METAFORM',
                    metaformdata: metaformdata,

                })
                dispatch({
                    type: 'SAVE_FORM_LOADING',
                    loading: false,

                });
            })
        }
        else{
            dispatch({
                type: 'SAVE_METAFORM',
                metaformdata: metaformdata,

            })
        }

    })
};
export const showform = (data) => dispatch => {
    let showformdata = entity.showForm(data);

    showformdata.then(showformdata => {
        //console.log(showformdata);
        if(data.source) {
            dispatch({
                type: 'GET_ENTITY_METAFORM',
                getformdata: showformdata.data.data,

            })
        }
        else {
            dispatch({
                type: 'SHOW_ENTITY_META_FORM',
                _id : data._id,
                showformdata: showformdata.data.data,
                template : showformdata.data.template,
                templateInfo : showformdata.data.templateinfo,
                pagelock : showformdata.data.pagelock,
                width : showformdata.data.template_width,
                status : showformdata.data.success,
                thankyoustatus : showformdata.data.thankyoustatus,
                thankyoulink : showformdata.data.thankyoulink,
                thankyoucontent : showformdata.data.thankyoucontent,
                thankyoulinkfield : showformdata.data.thankyoulinkfield,
                thankyouimgcontent : showformdata.data.thankyouimgcontent,
                profileimgcontent : showformdata.data.profileimgcontent,
                header : showformdata.data.header,
                footer : showformdata.data.footer,
                auth : showformdata.data.auth,
                collaborator : showformdata.data.collaborator,
                saveasdraft : showformdata.data.saveasdraft,
                buttonlist : showformdata.data.buttonlist,
                rolesettings : showformdata.data.rolesettings,
                master_published : showformdata.data.master_published,
                quickform : showformdata.data.quickform,
                thankyou_settings : showformdata.data.thankyou_settings,
                showlist_enabled:showformdata.data.showlist_enabled
            })
        }

    })
};
export const getcollectionconfig = (param) => dispatch => {
    let collectionconfig = entity.getcollectionconfig(param);

    collectionconfig.then(collectionconfig => {
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status: false

        })
        dispatch({
            type: 'ENTITY_CONFIQ',
            collectionconfig: collectionconfig,

        })
    }).catch(error => {
        dispatch({
            type: 'ENTITY_CONFIQ',
            collectionconfig: {data: {dataview: [],settings:[]}},

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status: false

        })
    })




};
export const savaDataView = (param) => dispatch => {
    let savaDataView = entity.savaDataView(param);



    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })

    savaDataView.then(savaDataView => {
        ////////////console.log(showformdata);
        dispatch({
            type: 'SAVE_DATA_VIEW',
            savaDataView: savaDataView,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })



    })
};
export const savaDataSettings = (param) => dispatch => {

    return new Promise((resolve,reject) => {
        let savaDataSettings = entity.savaDataSettings(param);
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status: true

        })
        savaDataSettings.then(savaDataSettings => {
            ////////////console.log(showformdata);
            resolve(1)
            dispatch({
                type: 'SAVE_DATA_SETTINGS',
                savaDataSettings: savaDataSettings,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: "",
                status: false

            })
        })
    })


};
export const singlerecorddAssign = (param) => dispatch => {
    let singlerecorddAssign = entity.singlerecorddAssign(param);
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    singlerecorddAssign.then(savaDataSettings => {
        ////////////console.log(showformdata);
        dispatch({
            type: 'SAVE_DATA_SETTINGS',
            savaDataSettings: savaDataSettings,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })
};

export const saveShareData = (param) => dispatch => {
    let saveShareData = entity.saveShareData(param);

    saveShareData.then(saveShareData => {
        ////////////console.log(showformdata);
        dispatch({
            type: 'SAVE_SHARE_DATA',
            saveShareData: saveShareData,

        })
    })
};


export const saveAssignData = (param) => dispatch => {
    let saveAssignData = entity.saveAssignData(param);

    saveAssignData.then(saveAssignData => {
        ////////////console.log(showformdata);
        dispatch({
            type: 'SAVE_ASSIGN_DATA',
            saveAssignData: saveAssignData,

        })
    })
};

export const saveFilter = (param) => dispatch => {
    return new Promise((resolve,reject) => {
        let saveFilter = entity.saveFilter(param);
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:true

        })

        saveFilter.then(saveFilter => {
            ////////////console.log(showformdata);
            dispatch({
                type: 'SAVE_DATA_FILTER',
                saveFilter: saveFilter,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: "",
                status:false

            })

        })
    })


};

export const saveRightSidePanel = (param) => dispatch => {
    return new Promise((resolve,reject) => {
        let saveRightPanel = entity.saveRightSidePanel(param);
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:true

        })

        saveRightPanel.then(saveRightPanel => {
            // console.log(saveRightPanel);
            dispatch({
                type: 'SAVE_RIGHT_PANELS',
                saveRightPanel: saveRightPanel,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: "",
                status:false

            })

        })
    })


};

export const savelinkingpermission = (param) => dispatch => {
    let savelinkingpermission = entity.savelinkingpermission(param);

    savelinkingpermission.then(savelinkingpermission => {
        dispatch({
            type: 'SAVE_LINKING_PERMISSIONS',
            savelinkingpermission: savelinkingpermission,

        })
    })
};

export const importCollectionData = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "import-csv",
        status:true

    })
    let importCollectionData='';
    if(param.systemgenerated === "yes"){
        importCollectionData = entity.importSystemCollectionData(param);
    }
    else{
        importCollectionData = entity.importCollectionData(param);
    }
   // let importCollectionData = entity.importCollectionData(param);

    importCollectionData.then(importCollectionData => {
        dispatch({
            type: 'IMPORT_CSV_GET_EN',
            importCSVData: importCollectionData.data,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}

export const updateImportCsvtStram = (param) => dispatch => {
    dispatch({
        type: 'IMPORT_CSV_UPDATE',
        csvstatus: param,
    })


}
export const getFormData = (data) => dispatch => {
    let formData = entity.getFormData(data);

    dispatch({
        type: 'GET_FORM_DATA_LOADER',
        loading:true,

    });
    formData.then(formData => {
        dispatch({
            type: 'GET_FORM_DATA',
            formvalues: formData.data.results.data,
            meta:formData.data.results.meta,
            wfinfo:formData.data.results.wfinfo,
            right_panel:formData.data.results.right_panel,
            versions:formData.data.results.versions,
            id:formData.data.results.id,
            draft : formData.data.results.draft

        })
        dispatch({
            type: 'GET_FORM_DATA_LOADER',
            loading:false,

        });
    })
};


export const sharecollection = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'share-collection',
        status:true

    });
    let shareCollection = entity.shareCollection(params);
    shareCollection.then(selectedcollections => {
        dispatch({
            type: 'SHARE_COLLECTION',
            shared: 1,
            msg: ''

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'share-collection',
            status:false

        });

    }).catch(error => {
            dispatch({
                type: 'SHARE_COLLECTION',
                shared: 1,
                msg: error.message

            });
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'share-collection',
                status: false

            });
        }

    )

};


export const getExternalValues = (data) => dispatch => {
    let externalValues = entity.getExternalValues(data);
    externalValues.then( externalValues => {
        if( externalValues.data.results) {
            let formData = externalValues.data.results.data;
////////console.log(formData);
            dispatch({
                type: 'GET_EXTERNAL_FORM_DATA',
                formData:formData,
                masterid : data.masterid
            });
        }
    });

};
export const getAggregate = (data) => dispatch => {
    let aggregateValue = entity.getAggregate(data);
    aggregateValue.then( aggregateValue => {
        if( aggregateValue.data.data) {
            let aggData = aggregateValue.data.data;
            dispatch({
                type: 'GET_AGGREGATE_DATA',
                aggData:aggData,
                refparams : data,
                computedfield:aggregateValue
            });
        }
        else{
            dispatch({
                type: 'GET_AGGREGATE_DATA',
                computedfield:aggregateValue
            });
        }
    });

};


export const saveForm = (data) => dispatch => {
    let formSaved = entity.saveForm(data);
    formSaved.then( formSaved => {

        dispatch({
            type: 'SAVE_FORM_DATA',
            formSaved :formSaved.data.results.data

        });

        let params={
            "taskid": data.referenceID,
            "col_id": data.parent,
            "dataid": formSaved.data.results.data,
            "uid": data.uid
        };
        let para1={
            "masterid":data.parent,
            "childid":formSaved.data.results.data,
            "index":ES_INDEX
        }
        let para2={
            "uid":data.uid,
            "usertype":getProfile().usertype,
            "collectionid":data.parent,
            "collectionformid":formSaved.data.results.data,
            index:ES_INDEX
        }
        setTimeout(function () {
            if(data.mode === 'save') {
                // entity.startcronjob(para1);
                // entity.startworkflow(para2);
            }

        }, 2000)


        let updatecollectiontodo = entity.updatetodoCollection(params);
        updatecollectiontodo.then( updatecollectiontodo => {
            dispatch({
                type: 'UPDATE_TODO_COLLECTION',
                updatecollectiontodo
            });

        });
    });


};
export const listTodoCollections = (params) => dispatch => {

    let listallcollections = entity.listTodoCollections(params);

    listallcollections.then(listallcollections => {
        let collections = listallcollections.data.results.data.collection;
        let  collectionsubgroup = collections.map(item=> { item['title'] = atob(item['title']); return item;});

        dispatch({
            type: 'LIST_TODO_COLLECTIONS',
            collectionsubgroup: collectionsubgroup
        });
    })

};
export const getCollectionSettings = (params) => dispatch => {
    dispatch({
        type: 'COLLECTION_SETTINGS_LOADER',
        loading:true

    });
    let collectionsettings = entity.getCollectionSettings(params);

    collectionsettings.then(collectionsettings => {
        dispatch({
            type: 'COLLECTION_SETTINGS',
            collectionsettings: collectionsettings.data.data
        });
        dispatch({
            type: 'COLLECTION_SETTINGS_LOADER',
            loading:false

        });
    })
        .catch(error => {
                dispatch({
                    type: 'COLLECTION_SETTINGS',
                    collectionsettings: []
                });
                dispatch({
                    type: 'COLLECTION_SETTINGS_LOADER',
                    loading: false

                });
            }
        )

};


export const downloadEntity= (data) => dispatch => {

    let downloadEntity = entity.downloadEntity(data);

    downloadEntity.then(downloadEntity => {
        dispatch({
            type: 'DOWNLOADENTITY',
            downloadEntity:downloadEntity
        })

    })
};

export const entityDetails= (data) => dispatch => {
    let entityDetails = entity.entitydetails(data);

    entityDetails.then(entityDetails => {
        dispatch({
            type: 'ENTITY_DETAILS',
            entityDetails:entityDetails
        })

    })
};
export const deleteEntity= (data) => dispatch => {
    let deleteEntity = entity.deleteEntity(data);

    deleteEntity.then(deleteEntity => {
        dispatch({
            type: 'DELETE_ENTITY',
            deleteEntity:deleteEntity
        })

    })
};
export const duplicateEntity= (entityid, UserId, index, projectId) => dispatch => {
    let duplicateEntity = entity.duplicateEntity(entityid, UserId, index, projectId);

    duplicateEntity.then(deleteEntity => {
        dispatch({
            type: 'DUPLICATE_ENTITY',
            duplicateEntity:duplicateEntity
        })

    })
};

export const getFilterData= (data) => dispatch => {
    let filterData = entity.formFilter(data);

    filterData.then(filterData => {
        dispatch({
            type: 'FORM_FILTER_DATA',
            filterData:filterData
        })

    })
};

export const exportField= (data) => dispatch => {
    let exportfield = entity.exportField(data);

    exportfield.then(exportField => {
        dispatch({
            type: 'EXPORT_DISPLAY_DATA',
            exportfield:exportField
        })

    })
};
export const saveExportFieldData= (data) => dispatch => {
    let saveExportFieldData = entity.saveExportFieldData(data);
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    saveExportFieldData.then(saveExportFieldData => {
        dispatch({
            type: 'SAVE_EXPORT_DISPLAY_DATA',
            saveexportfielddata:saveExportFieldData
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })

    })
};

export const exportall= (data) => dispatch => {
    let exportall = entity.exportall(data);
    dispatch({
        type: 'EXPORT_ALL_STATUS',
        loaderType: "EXPORT-ALL",
        status: true

    })
}
export const searchEntity = (data, formaccess='') => dispatch => {

    if(formaccess === 'external') {
        let entityList = entity.externalSearchEntity(data);
        dispatch({
            type: 'GET_ENTITY_LOADER',
            loading:true,

        });
        entityList.then( entityList => {

            let entityData= entityList.data.data;
            let modifiedList = entityData.map(a => { let id = a['_id']; a = a['_source']; a['id'] = id; a['name'] = atob(a['name']); return a;});

            dispatch({
                type: 'LIST_ENTITY',
                entityList : modifiedList
            });
            dispatch({
                type: 'GET_ENTITY_LOADER',
                loading:false,

            });
        });
    }
    else{
        let entityList = entity.searchEntity(data);
        dispatch({
            type: 'GET_ENTITY_LOADER',
            loading:true,

        });
        entityList.then( entityList => {

            let entityData= entityList.data.data;
            let modifiedList = entityData.map(a => { let id = a['_id']; a = a['_source']; a['id'] = id; a['name'] = atob(a['name']); return a;});

            dispatch({
                type: 'LIST_ENTITY',
                entityList : modifiedList
            });
            dispatch({
                type: 'GET_ENTITY_LOADER',
                loading:false,

            });
        });
    }


};

export const getCategory = (data) => dispatch => {

    let categoryList = entity.getCategory(data);
    dispatch({
        type: 'GET_CATEGORY_LOADER',
        loading:true,

    });
    categoryList.then( categoryList => {
        dispatch({
            type: 'LIST_CATEGORY',
            categoryList
        });
        dispatch({
            type: 'GET_CATEGORY_LOADER',
            loading:false,

        });
    });

};

export const createCollections = (data) => dispatch => {

    let collectionCreated = entity.createCollections(data);

    // let newCollection =  {
    //     "_id": data.collectionid,
    //     "share": 0,
    //     "filetitle": data.filetitle,
    //     "filename": data.filename,
    //     "complaincecategory": data.complaincecategory,
    //     "has_form": "no",
    //     "subname": "",
    //     "description": btoa(data.description),
    //     "filesize": data.filesize,
    //     "title": btoa(data.title),
    //     "createuser":getProfile().id,
    //     "cid": data.collectionid,
    //     "childs": 0
    // }
    collectionCreated.then( collectionCreated => {
        // dispatch({
        //     type: 'UPDATE_COLLECTION',
        //     newCollection:newCollection
        // });
        dispatch({
            type: 'COLLECTION_CREATED',
        });
    });

};
export const createEntity = (data) => dispatch => {
    //////console.log(data)
    let EntityCreated = entity.createEntity(data);
    dispatch({
        type: 'GET_ENTITY_LOADER',
        loading:true,

    });
    let newEntity =  {
        "_id": data.collectionid,
        "share": 0,
        "filetitle": data.filetitle,
        "filename": data.filename,
        "complaincecategory": data.complaincecategory,
        "has_form": "no",
        "subname": "",
        "description": btoa(encodeURIComponent(escape(data.description))),
        "filesize": data.filesize,
        "title": btoa(data.title),
        "createuser":getProfile().id,
        "cid": data.collectionid,
        "childs": 0
    }
    EntityCreated.then( EntityCreated => {
        dispatch({
            type: 'UPDATE_ENTITY',
            newEntity:newEntity
        });

    });
    dispatch({
        type: 'GET_ENTITY_LOADER',
        loading:true,

    });
};
export const createCategory= (data) => dispatch => {
    let CategoryCreated = entity.createCategory(data);

    CategoryCreated.then(CategoryCreated => {
        dispatch({
            type: 'CATEGORY_CREATED',
            params:data
        })

    })
};
export const selectExport= (data) => dispatch => {
    let selectExport = entity.selectExport(data);


};
export const resetForm = () => dispatch => {
    dispatch({
        type: 'RESET_FORM_DATA'
    });
};
export const entityCollections = (data) => dispatch => {

    let entityLinkedList = entity.entityCollections(data);
    dispatch({
        type: 'GET_ENTITY_LOADER',
        loading:true,

    });
    entityLinkedList.then( entityLinkedList => {
        dispatch({
            type: 'LINKED_ENTITY',
            entityLinkedList: entityLinkedList
        });
        dispatch({
            type: 'GET_ENTITY_LOADER',
            loading:false,

        });
    });

};


export const CollectionTemplateSaving = (cId,param) => dispatch => {

    let collectionTemplate = entity.collectionSaveTemplate(cId,param);
    dispatch({
        type: 'COLLECTION_TEMPLATE_LOADER',
        loading:true,

    });
    collectionTemplate.then( collectionTemplate => {
        dispatch({
            type: 'COLLECTION_SAVE_TEMPLATE',
            collectionTemplate: collectionTemplate
        });
        dispatch({
            type: 'COLLECTION_TEMPLATE_LOADER',
            loading:false,

        });
    });

};

/* entity rule */
export const getRule = (param) => dispatch => {

    let getRule = entity.getRule(param);
    dispatch({
        type: 'ENTITY_RULE_LOADER',
        loader: true
    });
    getRule.then( getRule => {
        dispatch({
            type: 'ENTITY_GET_RULE',
            getrule: getRule.data.data
        });
        dispatch({
            type: 'ENTITY_RULE_LOADER',
            loader: false
        });
    });

};

export const listingWorkflow = (param) => dispatch => {

    let listingWorkflow = entity.listingWorkflow(param);

    listingWorkflow.then( listingWorkflow => {
        dispatch({
            type: 'LISTING_WORKFLOW',
            listingWorkflow: listingWorkflow.data.data
        });
        dispatch({
            type: 'ENTITY_RULE_LOADER',
            loader: false
        });

    });

};
export const linkedWorkflow = (cid,param) => dispatch => {
    dispatch({
        type: 'ENTITY_RULE_LOADER',
        loader: true
    });
    let linkedWorkflow = entity.linkedWorkflow(cid,param);
    linkedWorkflow.then( linkedWorkflow => {
        dispatch({
            type: 'LISTING_LINKED_WORKFLOW',
            linkedWorkflow: linkedWorkflow.data.results.data
        });
        dispatch({
            type: 'ENTITY_RULE_LOADER',
            loader: false
        });


    });

};
export const deletelinkedWorkflow = (param) => dispatch => {
    dispatch({
        type: 'ENTITY_RULE_LOADER',
        loader: true
    });
    let deletelinkedWorkflow = entity.deletelinkedWorkflow(param);
    deletelinkedWorkflow.then( deletelinkedWorkflow => {
        dispatch({
            type: 'DELETE_WORKFLOW',
            deletelinkedWorkflow: deletelinkedWorkflow
        });

    });

};
export const createGenericRule = (param) => dispatch => {
    dispatch({
        type: 'ENTITY_RULE_LOADER',
        loader: true
    });
    let createGenericRule = entity.createGenericRule(param);
    createGenericRule.then( createGenericRule => {
        dispatch({
            type: 'CREATE_GENERAL_RULE',
            createGenericRule: createGenericRule
        });

    });

};

export const genericRuleDelted = (param,wID) => dispatch => {
    let genericRuleDelted = entity.genericRuleDelted(param,wID);
    dispatch({
        type: 'ENTITY_RULE_LOADER',
        loader: true
    });
    genericRuleDelted.then( genericRuleDelted => {
        dispatch({
            type: 'DELETE_GENERIC_RULE',
            deletegenericWorkflow: genericRuleDelted
        });

    });

};
export const fieldwiseRuleDelted = (param,wID) => dispatch => {
    let fieldwiseRuleDelted = entity.genericRuleDelted(param,wID);
    dispatch({
        type: 'ENTITY_RULE_LOADER',
        loader: true
    });
    fieldwiseRuleDelted.then( fieldwiseRuleDelted => {
        dispatch({
            type: 'DELETE_FIELDWISE_RULE',
            fieldwiseRuleDelted: fieldwiseRuleDelted
        });

    });

};
export const saveWorkflowrule = (param) => dispatch => {
    let saveWorkflowrule = entity.saveWorkflowrule(param);
    dispatch({
        type: 'ENTITY_RULE_LOADER',
        loader: true
    });
    saveWorkflowrule.then( saveWorkflowrule => {
        dispatch({
            type: 'SAVE_WORK_FLOW_RULE',
            saveWorkflowrule: saveWorkflowrule
        });

    });

};

export const deleteAll = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let deleteAllrecords = collections.deleteAllrecords(param);
    deleteAllrecords.then( deleteAllrecords => {
        //console.log(deleteAllrecords)
        dispatch({
            type: 'DELETE_ALL_RECORDS_ENTITY',
            status:deleteAllrecords.data

        })
        if(deleteAllrecords.data.success) {
            dispatch({
                type: 'LIST_ENTITY_DETAIL',
                listCollectionDetail: {
                    data: {
                        results: {
                            parentinfo: [],
                            dashboard: [],
                            data: [],
                            totalchilds: 0,
                            facet:[]
                        }
                    }
                },
            });
        }

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })
    });

};

export const LinkedId = (param) => dispatch => {
    dispatch({
        type: 'LINKED_ID',
        param:param

    })
}

export const LinkedCollectionId = (param) => dispatch => {
    dispatch({
        type: 'LINKED_COLLECTION_ID',
        param:param
    })
}
export const LinkedCollectionName = (leadName) => dispatch => {
    dispatch({
        type: 'LINKED_COLLECTION_NAME',
        leadName:leadName
    })
}

export const starClick = (params) => dispatch => {
    let starClick = entity.starClick(params);
};
export const listNotes = (params) => dispatch => {
    if(params.recordid !== undefined && params.recordid !== '' ) {
        dispatch({
            type: 'LIST_ANNOTATIONS',
            listAnn: [],
            loader: true

        });

        let listAnnotation = entity.listAnnotation(params);

        listAnnotation.then(listAnnotation => {
            dispatch({
                type: 'LIST_ANNOTATIONS',
                listAnn: listAnnotation.data.data,
                loader: false

            });

        })
    }
};
export const saveNotes = (params) => dispatch => {
    dispatch({
        type: 'SAVE_ANNOTATIONS',
        saveAnn:[],


    });

    let saveAnnotation = entity.saveAnnotation(params);
    saveAnnotation.then(saveAnnotation => {
        dispatch({
            type: 'SAVE_ANNOTATIONS',
            saveAnn: saveAnnotation,


        });

    })
};
export const saveEnitityInvokeWorkflow = (param) => dispatch => {
    let saveEnitityInvokeWorkflow = entity.saveEnitityInvokeWorkflow(param);
    saveEnitityInvokeWorkflow.then(saveEnitityInvokeWorkflow => {
        dispatch({
            type: 'ENTITY_INVOKE_WORKFLOW',
            saveEnitityInvokeWorkflow: saveEnitityInvokeWorkflow,

        })
    })
};

