import React, {Component} from "react";
import FormFields from "./FormFields";
import AutoComplete from "../AutoComplete";
import {Dropdown} from "./FormFieldData";

class FieldSet extends Component {

    constructor(props) {
        super(props);
        this.addNewFieldSet = this.addNewFieldSet.bind(this)
    }

    addNewFieldSet = (e, refElementIndex, refElement) => {
        //console.log(refElementIndex)
        e.preventDefault()
        e.stopPropagation();
        this.props.appendElement(this.props.pageIndex, refElementIndex, refElement, -1,'yes')
    }

    render() {

        let children = this.props.children;

        let attributes = this.props.attributes;
        let pageIndex = this.props.pageIndex;
        let fieldIndex=this.props.fieldIndex;
        let page = this.props.page;

        let referenceId = attributes.referenceId;
        let refIndexNo = attributes.refIndexNo ? attributes.refIndexNo : 0;

        let thisRefIndex = refIndexNo + 1;

        let refElement = attributes;
        let refElementIndex = this.props.fieldIndex;
        if(refIndexNo > 0) {
            let baseField = page.components.filter((item,i) => ( item.masterid && item.masterid === referenceId));
            let refElementItems = page.components.map((item,i) => {
                if(item.masterid === attributes.referenceId) {
                    return i;
                }
                else {
                    return -1;
                }
            });

            let refElementIndexes = refElementItems.filter((item, ind) => item >-1);
            refElementIndex = refElementIndexes.length > 0 && refElementIndexes[0];
            refElement = baseField.length > 0 && baseField[0];
        }

       let siblings = page.components.filter((item,i) => ( item.referenceId && item.referenceId  === attributes.masterid ));
        ////console.log(page);
        return (

            <div className={`ind-form-field fieldset field-set ${(attributes.showInForm === 'No')  && 'hide '} ${(attributes.focus && attributes.focus === 'Yes')  && ' focus-ele '} ${(attributes.fieldsetview === 'vertical'? " flex-box ": " ")}  ${(attributes.singlepanel && attributes.singlepanel === 'yes') && " singlepanel-fieldset "}`} data-id={attributes.masterid} id={attributes.masterid} >
                <div className="form-header-block">
                    <h5>{refIndexNo > 0 ?
                        (
                            (!attributes.displayfieldheading || (attributes.displayfieldheading && attributes.displayfieldheading === 'Yes'))
                            && attributes.fieldLabel
                        )
                        : attributes.fieldLabel}</h5>
                    <p>{attributes.placeholder}</p>
                    <div className="style-view">
                        {children.map((child, fieldIndex)=>
                            <React.Fragment key={"fieldset-"+fieldIndex}>
                                {this.props.renderFormFields(child, parseInt(this.props.fieldIndex) + parseInt(fieldIndex)+1, page, pageIndex)}
                            </React.Fragment>
                        )}
                    </div>
                    {(attributes.singlepanel && attributes.singlepanel.toLowerCase() === 'yes' && siblings.length > 0) &&
                        <React.Fragment>
                            {siblings.map((sibl, siblIndex)=> {
                                    let fchildren = page.components.filter(item=> item.linked === 'Yes' && parseInt(item.parentid) === parseInt(sibl.masterid) );
                                    //console.log(this.props.fieldIndex+"----"+siblIndex+"---"+fchildren.length)
                                    //console.log(fchildren)
                                    let fIndex = parseInt(this.props.fieldIndex) + parseInt(siblIndex)+  ((parseInt(siblIndex)+1) * fchildren.length) + 2;
                                    ////console.log(fIndex)
                                    let fRefElement = sibl;
                                    let fRefElementIndex = fIndex -1;


                                    return(
                                        <React.Fragment key={"fieldset-" + siblIndex}>
                                            <div className="style-view style-view-fieldset-new">
                                                {fchildren.map((child, fieldIndex)=>
                                                    <React.Fragment key={"fieldset-"+fieldIndex}>
                                                        {this.props.renderFormFields(child, parseInt(fIndex) + parseInt(fieldIndex)+1, page, pageIndex)}
                                                    </React.Fragment>
                                                )}
                                                {sibl.addmuliple === 'Yes' &&
                                                <div className="rightButtons">
                                                    {sibl.removableField && sibl.removableField === "Yes" &&
                                                        <React.Fragment>

                                                            <button className="btnsubIcon ico-lib"
                                                                    onClick={(e) => this.props.removeElement(this.props.pageIndex, fRefElementIndex, sibl.masterid)}>

                                                            </button>
                                                        </React.Fragment>

                                                    }
                                                    {sibl.showPlusBtn &&
                                                    <button
                                                        className="btnsubIconsadd ico-lib"
                                                        onClick={(e) => this.addNewFieldSet(e, refElementIndex, refElement)}>
                                                    </button>
                                                    }
                                                </div>
                                                }
                                            </div>

                                        </React.Fragment>
                                    )
                                }
                            )}
                        </React.Fragment>
                    }
                </div>
                {attributes.addmuliple === 'Yes' && !attributes.readOnly &&
                <div className="rightButtons">
                    {attributes.removableField && attributes.removableField === "Yes" &&
                    <button className="btnsubIcon ico-lib"
                            onClick={(e) => this.props.removeElement(this.props.pageIndex, this.props.fieldIndex, attributes.masterid)}>
                    </button>
                    }
                    {attributes.showPlusBtn &&
                    <button
                        className="btnsubIconsadd ico-lib"
                        onClick={(e) => this.addNewFieldSet(e, refElementIndex, refElement)}>
                    </button>
                    }
                </div>
                }
            </div>
        )
    }
}

export default FieldSet;
