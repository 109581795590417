import axios from 'axios'
import {commonService} from "./common.services";
import {API_PATH , ES_INDEX} from "../constants";
export const roles = {
    listroles,
    SaveRoles,
    ProjectRoles,
    AssignRoles,
    UserRoles,
    AssignUserRole,
    RolestobeAssigned,
    DeleteRoles
};
function listroles(params) {
    return commonService.sendHttpRequest('sector/role', params, "GET");
}
function SaveRoles(params) {
    return commonService.sendHttpRequest('sector/createrole', params, "POST");
}
function ProjectRoles(params) {
    return commonService.sendHttpRequest('sector/projectassignedroles', params, "GET");
}
function RolestobeAssigned(params) {
    return commonService.sendHttpRequest('sector/projectnotassignedroles', params, "GET");
}
function AssignRoles(params) {
    return commonService.sendHttpRequest('sector/assignprojectroles', params, "POST");
}
function UserRoles(params) {
    return commonService.sendHttpRequest('sector/assigneduserroles', params, "GET");
}
function AssignUserRole(params) {
    return commonService.sendHttpRequest('sector/assignuserroles', params, "POST");
}
function DeleteRoles(params) {
    return commonService.sendHttpRequest('sector/', params, "POST");
}
