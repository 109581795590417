import React from 'react';
import {Form} from "react-bootstrap";

const TextArea = (props) => (
	<Form.Group  className={props.className}>
		<Form.Control as="textarea"
			className="form-input"
			style={props.resize ? null : {resize: 'none'}}
			name={props.name}
			rows={props.rows}
			value={props.content}
			onChange={props.controlFunc}
			placeholder={props.placeholder}
		/>
	</Form.Group>

);

export default TextArea;