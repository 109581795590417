import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import React, {Component} from "react";
import ProjectTaskCard from "./ProjectTaskCard";
import NoData from "./NoData";
import OutsideAlerter from "./OutsideAlerter";
import {getProfile} from "../../utilities/AuthService";
const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? "#333333" : "lightgrey",

});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    //background: isDragging ? "#F1F1F1" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});

class ProjectSection extends Component {

    constructor(props) {
        super(props);

        this.state={
            showOptions: false,
            secId: 0,
            mouseover: 0,
        }

    }

    onmouseover(e, secId) {
        this.setState({mouseover: secId})
    }

    render() {
        let projectsections = this.props.projectsections;
        let sectionindex = this.props.index;
        return(
            <React.Fragment>

                <div className="card">
                    <div className="card-header proj-card-header" id="headingTwo">
                        <h5 className="mb-0">
                            <button
                                className= "btn btn-link collapsed"
                                data-toggle="collapse"
                                data-target="#col-2"
                                aria-expanded="true"
                                aria-controls="col-2">
                                <span className="button-text ico-lib section-flag">{this.props.milestonename}</span>
                            </button>
                            <div className="accord-header-right">
                                <ul className="milestone-task-info">
                                    <li>{projectsections.length} Task Groups</li>
                                    <li>{this.props.taskcnt} Tasks</li>
                                </ul>
                                {this.props.createuser === getProfile().id &&
                                <div className="form-option-block nav-option">
                                    <button className="ico-lib opt-form-ico" onClick={() => this.props.showOptions()}></button>
                                    {this.props.showlistOptions &&
                                    <OutsideAlerter
                                        status={this.props.showlistOptions}
                                        Triggered={() => this.props.showOptions()}>
                                        <ul className="panel-option">
                                            <li>
                                                <a className="panel-option-click ico-lib form-edit-ico"
                                                   onClick={(e) => this.props.showCreateNewMilestoneModal(e, 'update')}> Edit
                                                    Milestone</a>
                                            </li>
                                            {this.props.taskcnt === 0 &&
                                            <li>
                                                <a className="panel-option-click ico-lib  wf-dt" onClick={(e) => {
                                                    this.props.deleteMilestone()
                                                }}> Delete Milestone</a>
                                            </li>
                                            }
                                        </ul>
                                    </OutsideAlerter>
                                    }
                                </div>
                                }
                            </div>
                        </h5>
                    </div>
                    <DragDropContext onDragEnd={this.props.onDragEnd}>
                    {projectsections.map((sec,index) =>
                        <React.Fragment key={"sec"+index}>
                            <Droppable droppableId={sec.sectionid}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}>
                                <div id="col-2"
                                     className="collapse show"
                                     aria-labelledby="headingTwo"
                                     data-parent="#accordion" onMouseOver={(e) => {this.onmouseover(e, sec.sectionid)}} onMouseOut={(e) => {this.onmouseover(e, 0)}}>
                                    <div className="card-body">
                                        <div className="task-list-wrapper" style={{minHeight: "100px"}}>
                                            <div className="ind-task-group-header">
                                                <h5>{sec.section}</h5>
                                                <div className={` button-proj-block ${this.state.mouseover === sec.sectionid ? "" : "hide"}`}>
                                                    <button className="general-btn proj-btn section"
                                                            onClick={(e) => this.props.showAddNewSectionModal(e, sec.sectionid,index)}>
                                                        Edit Task Group
                                                    </button>
                                                    <button className="general-btn proj-btn add-tsk"
                                                            onClick={(e) => this.props.showAddNewTaskModal(e, sec.sectionid)}>
                                                        Add Task
                                                    </button>
                                                    {sec.taskcnt === 0 &&
                                                    <button className="general-btn proj-btn delete"
                                                        data-toggle="modal"
                                                        data-target="#milestone-modal" onClick={(e) => this.props.showdeleteSectionModal(e, sec.sectionid)}>Delete Task Group
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                            {sec.task.map((task, taskindx) =>
                                                <ProjectTaskCard
                                                    key={task.masterid}
                                                    history={this.props.history}
                                                    task={task}
                                                    index={taskindx}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </React.Fragment>
                    )}
                    </DragDropContext>
                </div>
                {projectsections.length === 0 &&
                <div className='absolute-holder'>
                    <NoData
                        msg={`No Task Group added yet`}

                    />
                </div>
                }
            </React.Fragment>
        )
    }
}
export default ProjectSection;
