import React, {Component} from 'react';
import ReactQuill,{ Quill, Mixin, Toolbar }  from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
import {convertb64toFile,convertb64VideotoFile} from "../../../utilities/CustomFunctions";
import LoaderSVG from "../../views/LoaderSVG";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {resources} from "../../../services/Resources.services";

import {getProfile} from "../../../utilities/AuthService";
let uploading = 'no';
class EmailTextEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = { text: '', uploading : 'no' } // You can also pass a Quill Delta here
        this.handleChange = this.handleChange.bind(this);
        //this.quillRef = React.createRef();

    }
    handleChange=(val)=>{
        this.props.handleChange(this.props.id,val)
    }


    render() {
        return (
            <>
                {this.state.uploading === 'yes' &&
                <LoaderSVG />
                }
                <ReactQuill
                    onChange={this.handleChange}
                    theme={`snow`}
                    value={this.props.value}
                    modules={{

                        toolbar: {
                            container: this.props.container,
                            handlers: {
                                image: imageHandler
                            },
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                            }
                        }
                    }}
                    formats={EmailTextEditor.formats}
                    bounds={this.props.bounds}
                    placeholder={this.props.placeholder}

                />

            </>
        )
    }
}

function imageHandler(Props) {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("name", "editor_image");
    input.setAttribute("accept", "image/*,video/*");
    input.setAttribute("class", "Editor-mage");
    input.click();
    input.onchange = async () => {

        //debugger
        const file = input.files[0];
        console.log(file)
        var ValidImageTypes = [
            "image/gif",
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/GIF",
            "image/JPEG",
            "image/PNG",
            "image/JPG"
        ];
        var ValidVideoTypes = [
            "video/mp4",
            "video/MP4"
        ];
        let file_type = file.type;
        let filename = file.name;
        let extension = filename.split(".").pop();

        console.log(this.quillRef)
        if (ValidImageTypes.indexOf(file_type) >= 0) {
            if (true) {
                var fileToLoad = file;

                const reader = new FileReader();
                reader.onloadend = () => {
                    // log to console
                    // logs data:<type>;base64,wL2dvYWwgbW9yZ...
                    console.log(reader.result);
                    uploading = 'yes'


                    convertb64toFile(reader.result, 'image/jpeg').then((data) => {
                        console.log(uploading)
                        let fileUrl = data.location;
                        console.log(fileUrl);

                        //debugger
                        const range = this.quill.getSelection(true);

                        console.log(range)

                        // Insert temporary loading placeholder image
                        // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

                        // Move cursor to right side of image (easier to continue typing)
                        this.quill.setSelection(range.index + 1);

                        // Remove placeholder image
                        this.quill.deleteText(range.index, 1);

                        // Insert uploaded image
                        this.quill.insertEmbed(range.index, "image", fileUrl);
                        this.quill.insertEmbed(range.index, "alt", filename);
                        this.quill.pasteHTML(
                            range.index,
                            <img
                                src={fileUrl}
                                class="blog-image-content"
                                alt={filename}
                                width="560"
                                height="320"
                            />
                        );
                        uploading = 'no';
                        saveResource(fileUrl,filename,'images', 1)
                        console.log(uploading)

                    });
                };
                reader.readAsDataURL(file);


            }
        }
        else if (ValidVideoTypes.indexOf(file_type) >= 0) {
            if (true) {
                var fileToLoad = file;
                const reader = new FileReader();
                reader.onloadend = () => {
                    // log to console
                    // logs data:<type>;base64,wL2dvYWwgbW9yZ...
                    console.log(reader.result);

                    convertb64VideotoFile(reader.result, 'video/mp4').then((data) => {

                        //this.setState({capturedImg : data.location});
                        let fileUrl = data.location;
                        console.log(fileUrl);

                        //debugger
                        const range = this.quill.getSelection(true);

                        console.log(range)

                        // Insert temporary loading placeholder image
                        // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

                        // Move cursor to right side of image (easier to continue typing)
                        this.quill.setSelection(range.index + 1);

                        // Remove placeholder image
                        this.quill.deleteText(range.index, 1);

                        // Insert uploaded image
                        this.quill.insertEmbed(range.index, "video", fileUrl);
                        this.quill.pasteHTML(
                            range.index,
                            <video
                                src={fileUrl}
                                class="blog-image-content"
                                alt={filename}
                                width
                            />
                        );
                        uploading = 'no';
                        saveResource(fileUrl,filename,'videos',2)

                    });
                };
                reader.readAsDataURL(file);


            }
        }
        else {
            console.log("invalid file")
        }

    }
}

function saveResource(url,filename,filetype,res_type) {
    let fileStrArr = filename.split('.'); //original filename
    let newFileNameStr = url.split("/");
    let newFileName = filename
    if(newFileNameStr.length >0) {
        newFileName = newFileNameStr[newFileNameStr.length-1];

    }

    let params = {
        "uid": getProfile()?getProfile().id: '2',
        "title": fileStrArr.length>0?fileStrArr[0]:filename ,
        "thumbname": newFileName,
        "actimg": newFileName,
        "res_type": res_type,
        "filesize": 0,
        "filetype": filetype,
        "f_type": fileStrArr.length>0 ?fileStrArr[1]:'',
        "tagsid": '',
        "tagsname": fileStrArr.length>0?fileStrArr[0]:filename ,
        "galleryid": "",
        "folderid": "100"
    }
    resources.saveGallery(params);
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
/*
EmailTextEditor.modules = {

    toolbar: {
        container: [
            ['bold', 'italic'],
            [{'list': 'ordered'}, {'list': 'bullet'},
            ],
            ['link', 'image']
        ],
        handlers: {
            image: function(value){
                const input = document.createElement("input");

                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/!*");
                input.setAttribute("class", "Editor-mage");
                input.click();
                input.onchange = async () => {
                    //debugger
                    const file = input.files[0];
                    console.log(file)

                    var ValidImageTypes = [
                        "image/gif",
                        "image/jpeg",
                        "image/png",
                        "image/jpg",
                        "image/GIF",
                        "image/JPEG",
                        "image/PNG",
                        "image/JPG",
                    ];
                    let file_type = file.type;
                    let filename = file.name;
                    let extension = filename.split(".").pop();

                    console.log(ReactQuill.ref)
                    if (ValidImageTypes.indexOf(file_type) >= 0) {
                        if (true) {
                            var fileToLoad = file;

                            const reader = new FileReader();
                            reader.onloadend = () => {
                                // log to console
                                // logs data:<type>;base64,wL2dvYWwgbW9yZ...
                                console.log(reader.result);

                                convertb64toFile(reader.result, 'image/jpeg').then((data) => {
                                    //this.setState({capturedImg : data.location});
                                    let fileUrl = data.location;
                                    console.log(fileUrl);

                                    //debugger
                                    const range = ReactQuill.ref.getSelection(true);

                                    // Insert temporary loading placeholder image
                                    // this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

                                    // Move cursor to right side of image (easier to continue typing)
                                    this.quillRef.setSelection(range.index + 1);

                                    // Remove placeholder image
                                    this.quillRef.deleteText(range.index, 1);

                                    // Insert uploaded image
                                    this.quillRef.insertEmbed(range.index, "image", fileUrl);
                                    this.quillRef.pasteHTML(
                                        range.index,
                                        <img
                                            src={fileUrl}
                                            class="blog-image-content"
                                            alt="Responsive image"
                                        />
                                    );

                                });
                            };
                            reader.readAsDataURL(file);


                        } else {
                            // this.setState({
                            // image_warning:'File size larger than maximum allowed limit',
                            /!*image = "";
                            image_extension = "";*!/
                            // })
                            //this.fileInput.value = "";
                        }
                    }
                    else {
                        console.log("invalid file")
                    }

                }

            },
            'link': function(value) {
                if (value) {
                    var href = prompt('Enter the URL');
                    this.quill.format('link', href);
                } else {
                    this.quill.format('link', false);
                }
            }
        },
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }
}
*/
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
EmailTextEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

/*
 * PropType validation
 */
// EmailTextEditor.propTypes = {
//     placeholder: PropTypes.string,
// }



export default EmailTextEditor;
