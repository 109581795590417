import React from 'react';
import { Draggable, state } from 'react-page-maker';
import {withRouter} from 'react-router';
import { FaTrash } from 'react-icons/fa';
import {connect} from "react-redux";

import {FormGroup} from "react-bootstrap";
import {getUrlSegment} from "../../../../../../utilities/CustomFunctions";

class LabelContainer extends React.Component {
  state = {
    showColorPicker: false,
    label: '',
  };

  handleChangeComplete = (e) => {
    let labelValue=e.target.value
    const { id, dropzoneID, parentID }= this.props;
    this.setState({ label: labelValue }, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: { label: labelValue}
      });
    });
   // this.chartSaving()
  };
  // onBlur(){
  //   this.props.dragStatus(false)
  // }
  // onClick(){
  //   this.props.dragStatus(true)
  // }



  render() {
    let getFormdetails=this.props.getFormdetails

    let showlistField=[]

    if(this.props.showFormModalData.showformdata.length !==0) {

      for (let i = 0; i < this.props.showFormModalData.showformdata.pages.length; i++) {
        (this.props.showFormModalData.showformdata.pages[i].components.filter(list=> list.visibility !=="hidden")).map(list=>{
          showlistField.push(list)

        })
      }
    }
    const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, name, payload
    } = this.props;

    const label =  this.state.label ||
      payload && payload.label || '';

    if (showBasicContent) {
      return (
        <Draggable { ...this.props } >
          {

            <span className="drag-icon">Description</span>
          }
        </Draggable>
      )
    }

    if (showPreview) {
      return (
          <div className={`floating-summary-body ${getUrlSegment(1) === 'whatsappchat' ? "ge-description":''}`}>
            {label && getFormdetails.length !== 0 && getFormdetails.formvalues.length !== 0 &&
            getFormdetails.formvalues.filter(list => list.masterid === label)[0].values.map((list,index)=>
            <p key={`desc`+index} dangerouslySetInnerHTML={{__html: (list.value)}}></p>
              )}
            {label === "" &&
             <p>Please Select.</p>
            }

            </div>
      );
    }

    return (
      <Draggable { ...this.props } draggable={!this.props.DragDisabled.dragStatus}>
        <React.Fragment>
          <FormGroup className="m-0">
            <label className="col-sm-12">
              <div className={`left-container`} >
              <span>{name}</span>
                <select onChange={(e)=>this.handleChangeComplete(e)} value={label}>
                  {showlistField.map((label, index) =>
                      <option key={`masterid` + index}
                              value={label.masterid}>{label.label}</option>
                  )}

                </select>
            {/*<input onChange={(e)=>this.handleChangeComplete(e)} onClick={()=>this.onClick()} onBlur={()=>this.onBlur()} defaultValue={label} className="layout-header-block"/>*/}
              </div>
              <div className="chart-icons">
                {/*<div className="save-in-icon" onClick={() => this.chartSaving()}> </div>*/}
                <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}> </div>
              </div>
            </label>
          </FormGroup>
        </React.Fragment>
      </Draggable>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled,
  showFormModalData:state.EntityReducer.showFormModal,
  getFormdetails : state.CollectionsReducer.getFormData,

});
export default  withRouter(connect(mapStateToProps, {
})(LabelContainer));
