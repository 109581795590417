import React, {Component} from 'react';

class MetricItem extends Component {
    constructor(props) {
        super(props);

    }


    render() {

        return (

            <React.Fragment>
                <div className="metric-item">
                    <div className="metric-head">
                        <h5 className="ico-lib-v2 project-source-header energy">Clean Energy</h5>
                    </div>
                    <div className="metric-content">
                        <div className="metric-detail">
                            <div className="tag-code-block">
                                <label className="sdg yellow">SDG7</label>
                            </div>
                            <h5>Number of beneficiaries</h5>
                            <p>Improve quality of life for poor or underserved populations through access to
                                energy</p>
                        </div>
                        <div className="metric-detail-right">
                            <h3>2000</h3>
                            <h5>People</h5>
                        </div>
                    </div>
                    <div className="metric-footer">
                        <p>Impact</p>
                        <p className="footer-date">Created By John On Aug 21, 2019</p>
                    </div>
                </div>
                <div className="metric-item">
                    <ul className="graph-tag">
                        <li className="blue-circle">Number of beneficiaries</li>
                    </ul>
                    <MetricGraph />
                </div>
                <div className="metric-item">
                    <div className="metric-head">
                        <h5 className="ico-lib-v2 project-source-header energy">Clean Energy</h5>
                    </div>
                    <div className="metric-content">
                        <div className="metric-detail">
                            <div className="tag-code-block">
                                <label className="sdg red">SDG1</label>
                            </div>
                            <h5>Job creation - Fair Wages</h5>
                            <p>Catalyze job creation and positive socioeconomic outcomes in the local
                                economy</p>
                        </div>
                        <div className="metric-detail-right">
                            <h3>700</h3>
                            <h5>Per Day</h5>
                        </div>
                    </div>
                    <div className="metric-footer">
                        <p>Impact</p>
                        <p className="footer-date">Created By John On Aug 21, 2019</p>
                    </div>
                </div>
                <div className="metric-item">
                    <ul className="graph-tag">
                        <li className="red-circle">Job creation - Fair Wages</li>
                    </ul>
                    <MetricGraph />
                </div>
                <div className="metric-item">
                    <div className="metric-head">
                        <h5 className="ico-lib-v2 project-source-header energy">Clean Energy</h5>
                    </div>
                    <div className="metric-content">
                        <div className="metric-detail">
                            <div className="tag-code-block">
                                <label className="sdg green">SDG3</label>
                                <label className="sdg orange">SDG11</label>
                                <label className="sdg brown">SDG11</label>
                            </div>
                            <h5>Reduction or avoidance of greenhouse gas (GHG) emissions due to products or
                                services sold </h5>
                            <p>Catalyze positive environmental outcomes</p>
                        </div>
                        <div className="metric-detail-right">
                            <h3>0.82</h3>
                            <h5>t/mWh</h5>
                        </div>
                    </div>
                    <div className="metric-footer">
                        <p>Impact</p>
                        <p className="footer-date">Created By John On Aug 21, 2019</p>
                    </div>
                </div>
                <div className="metric-item">
                    <ul className="graph-tag">
                        <li className="green-circle">Number of beneficiaries</li>
                    </ul>
                    <MetricGraph />
                </div>
            </React.Fragment>

        );
    }


}
class MetricGraph extends Component {
    constructor(props) {
        super(props);

    }


    render() {

        return (

            <div className="ibt-graph">
                <img src="/assets/images/metric-graph.jpg" />
            </div>

        );
    }


}
export {MetricItem,MetricGraph};