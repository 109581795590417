import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import Selectbox from './Selectbox'
import PerformanceDetail from './PerformanceDetail'
import LoaderSvg from "./LoaderSVG";
class Ownperformance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initial: 'current',
            onLoad: true
        }
        this.getStat = this.getStat.bind(this)
    }
    componentDidMount() {

    }
    getStat(type) {
        this.setState({onLoad:false});
        this.setState({initial:type});
        this.props.getWeeklyStatistics(type)
    }

    render() {
        let weeklyStatistics = [];
        if(this.props.type === 'own_task') {
            weeklyStatistics = [
                {
                    label: 'Task Completed',
                    value: this.state.onLoad === false ? this.props.weeklyStatistics.week_tododone_count : this.props.dashboard.taskcompleted
                },
                {
                    label: 'Tasks Reopened',
                    value: this.state.onLoad === false ? this.props.weeklyStatistics.week_reopen_count : this.props.dashboard.tasksreopened
                },
                {
                    label: 'Comments Posted',
                    value: this.state.onLoad === false ? this.props.weeklyStatistics.week_comment_count : this.props.dashboard.commentsposted
                },
                {
                    label: 'Files Uploaded',
                    value: this.state.onLoad === false ? this.props.weeklyStatistics.week_file_count : this.props.dashboard.filesuploaded
                },
                {
                    label: 'Total Hours Logged',
                    value: this.state.initial === 'previous' && this.props.weeklyStatistics ? this.props.weeklyStatistics.week_hours || 0 : this.props.dashboard.totalhours
                },
                {
                    label: 'My Task Run Rate',
                    value: this.state.initial === 'previous' && this.props.weeklyStatistics ? this.props.weeklyStatistics.week_runrate || 0 : this.props.dashboard.runrate || 0
                }
            ];
        } else {
            weeklyStatistics = [
                {
                    label: 'Task Delegated',
                    value: this.state.onLoad === false ? this.props.weeklyStatistics.week_tododone_count : this.props.dashboard.taskdelegated
                },
                {
                    label: 'Tasks Verified',
                    value: this.state.onLoad === false ? this.props.weeklyStatistics.week_reopen_count : this.props.dashboard.taskverified
                },
                {
                    label: 'Tasks Reopened',
                    value: this.state.onLoad === false ? this.props.weeklyStatistics.week_comment_count : this.props.dashboard.delegated_taskreopened
                },
                {
                    label: 'Total Hours Logged',
                    value: this.state.initial === 'previous' && this.props.weeklyStatistics ? this.props.weeklyStatistics.week_hours || 0 : this.props.dashboard.delegated_totalhours
                }
            ];
        }


        let dropDown = [{
                'label': 'Previous Week',
                'value': 'previous'
            },
            {
                'label': 'Current Week',
                'value': 'current'
            }];
        return(

            <div className="content-left small-tab">
                {
                    ((this.props.multiLoader.loader['dashboard-userstat'] && this.props.multiLoader.loader['dashboard-userstat'] === true) ||
                    (this.props.multiLoader.loader['dashboard-weeklystat'] && this.props.multiLoader.loader['dashboard-weeklystat'] === true)) &&
                    <span className="Loader-holder">
                        <LoaderSvg/>
                    </span>
                }
                <div className="content-bl-header">
                    <h5>{(this.props.type === 'own_task') ? 'My Performance': 'Delegated Tasks' }</h5>
                    <Selectbox dropDown={dropDown} handleSelect={this.getStat} initialValue={this.state.initial}/>
                </div>
                <div className="static-mode-block">
                   {weeklyStatistics.map((val, index) =>
                    <PerformanceDetail dataList={val} key={`ownperf-${index}`}/>
                    )}
                </div>
            </div>
        );
    }
}
export default Ownperformance;