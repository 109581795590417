import {schedulerService} from "../services/scheduler.services";
import {collections} from "../services/collections.services";
import {entity} from "../services/entity.services";
import {ceshareview} from "../services/ceshareview.services";

export const getCalendarSettings = () => dispatch => {
    dispatch({
        type: 'CALENDAR_SETTINGS',
        list: []
    });

    let events = schedulerService.getCalendarSettings();

    events.then(res => {

        let result = res.data.results;


        if (result.success === true) {
            let data = result.data;
            let settings = {};
            data.map((d, indx) => {

                let val = [];
                if (d.label === 'Working days' || d.label === 'Break start time' || d.label === 'Break end time') {
                    d.values.map((c, cIndx) => {
                        val.push(d.label === 'Working days' ? parseInt(c.value) : c.value);
                    });
                    settings[d.label] = val;
                } else {

                    settings[d.label] = d.values[0].value;
                }

            });

            dispatch({
                type: 'CALENDAR_SETTINGS',
                list: settings
            });
        }
    });
};
export const findHolidays = () => dispatch => {
    let cat = schedulerService.getHolidays();
    cat.then(res => {
        let result = res.data.results;
        let holidays = [];
        let atendeeEvents = [];
        let hostEvents = [];
        let events = [];
        if (result.success === true) {
            let data = result.data;
            data.map((d, indx) => {
                holidays[indx] = {id: indx, masterid: d.id};
                d.collectedform.map((c, cIndx) => {
                    let val = "";
                    c.value.map((v, vIndx) => {
                        val = v.value;
                    });

                    holidays[indx][c.label] = val;

                });
            });
            dispatch({
                type: 'CALENDAR_HOLIDAYS',
                list: holidays
            });
        }
    });
};
export const findBookingsAndLeaves = (id) => dispatch => {
    let cat = schedulerService.getBookingsAndLeaves(id);
    cat.then(res => {
        let result = res.data.results;
        let leaves = [];
        let bookings = [];
        let atendeeEvents = [];
        let hostEvents = [];
        let events = [];
        if (result.success === true) {
            let data = result.data;
            data.map((d, indx) => {
                let row = {id: indx, masterid: d.id}
                // leaves[indx] = {id: indx, masterid: d.id};
                d.collectedform.map((c, cIndx) => {
                    let val = "";
                    c.value.map((v, vIndx) => {
                        val = v.value;
                    });

                    row[c.label] = val;

                });
                if (row['category'] === 'Leave') {
                    leaves[indx] = row;
                }
                if (row['category'] === 'Booking') {
                    bookings[indx] = row;
                }
            });

            dispatch({
                type: 'HOST_LEAVES',
                list: leaves
            });
            dispatch({
                type: 'HOST_EVENTS',
                list: bookings,
                users: [],
                patientprogram: []
            });
        }
    });
};
export const saveBooking = (params) => dispatch => {
    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'EVENTS'
    });
    dispatch({
        type: 'BOOKED',
        status: 'loading'

    });
    setTimeout(function () {
        dispatch({
            type: 'BOOKED',
            status: 'loading'

        });
    }, 3000);

};

export const userLoginTrack = (data) => dispatch => {

    let pages = schedulerService.userLoginTrack(data);
    pages.then(res => {

    });
};

export const sendOTP = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'SEND_OTP'
    });
    dispatch({
        type: 'SEND_OTP',
        status: 'loading',
        message: ''
    });
    let otp = schedulerService.sendOTP(data);
    otp.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'SEND_OTP'
        });

        console.log(res.data)
        let result = res.data;
        if (result.success === true) {
            dispatch({
                type: 'SEND_OTP',
                status: result.success,
                message: result.message
            });
        } else {
            dispatch({
                type: 'SEND_OTP',
                status: result.success,
                message: result.message
            });
        }
    });
};
export const verifyOTP = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'VERIFY_OTP'
    });
    dispatch({
        type: 'VERIFY_OTP',
        status: 'loading',
        message: ''
    });
    let otp = schedulerService.verifyOTP(data);
    otp.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'VERIFY_OTP'
        });

        let result = res.data;
        if (result.success === true) {
            dispatch({
                type: 'VERIFY_OTP',
                status: result.success,
                message: result.message
            });
        } else {
            dispatch({
                type: 'VERIFY_OTP',
                status: result.success,
                message: result.message
            });
        }
    });
};


export const findCities = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'CITIES'
    });
    let cities = schedulerService.findCities(data);

    cities.then(res => {
        console.log(res)
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'CITIES'
        });
        if (res.data) {
            let result = res.data.results;
            if (result.success === true) {

                dispatch({
                    type: 'CITIES',
                    data: result.data
                });
            } else {
                dispatch({
                    type: 'CITIES',
                    data: []
                });
            }
        }
    });
};

export const findShowrooms = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'SHOWROOMS'
    });
    let bookmarks = schedulerService.findShowrooms(data);

    bookmarks.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'SHOWROOMS'
        });
        if (res.data) {
            let result = res.data.results;
            if (result.success === true) {

                dispatch({
                    type: 'SHOWROOMS',
                    data: result.data
                });
            } else {
                dispatch({
                    type: 'SHOWROOMS',
                    data: []
                });
            }
        }
    });
};
export const findSalespersons = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'SALESPERSONS'
    });
    let bookmarks = schedulerService.findSalespersons(data);

    bookmarks.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'SALESPERSONS'
        });
        if (res.data) {
            let result = res.data.results;
            if (result.success === true) {

                dispatch({
                    type: 'SALESPERSONS',
                    data: result.data
                });
            } else {
                dispatch({
                    type: 'SALESPERSONS',
                    data: []
                });
            }
        }
    });
};
export const findResourceBookings = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'RESOURCE_BOOKINGS'
    });
    let bookmarks = schedulerService.findResourceBookings(data);

    bookmarks.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'RESOURCE_BOOKINGS'
        });
        if (res.data) {
            let result = res.data.results;
            if (result.success === true) {

                dispatch({
                    type: 'RESOURCE_BOOKINGS',
                    data: result.data
                });
            } else {
                dispatch({
                    type: 'RESOURCE_BOOKINGS',
                    data: []
                });
            }
        }
    });
};
export const saveResourceBookings = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'SAVE_RESOURCE_BOOKING'
    });
    dispatch({
        type: 'SAVE_RESOURCE_BOOKING',
        success: 'loading',
        message: '',
        data: '',
        code: ''
    });
    let bookmarks = schedulerService.saveResourceBookings(data);

    bookmarks.then(res => {
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'SAVE_RESOURCE_BOOKING'
        });
        if (res.data) {
            let result = res.data.results;
            if (result.success === true) {

                dispatch({
                    type: 'SAVE_RESOURCE_BOOKING',
                    success: result.success,
                    message: result.message,
                    data: result.data,
                    code: result.code
                });
            } else {
                dispatch({
                    type: 'SAVE_RESOURCE_BOOKING',
                    success: result.success,
                    message: result.message
                });
            }
        }
    });
};


export const findAgentAvailableSlots = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'AGENT_AVAIL_SLOTS'
    });
    let slotData = schedulerService.getAgentAvailableSlots(data);

    slotData.then(slotData => {
        console.log(slotData.data)
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'AGENT_AVAIL_SLOTS'
        });
        if (slotData.data.success === true) {
            let result = slotData.data.data;
            if (result) {
                console.log(result)
                dispatch({
                    type: 'AGENT_AVAIL_SLOTS',
                    allSlots: result.allslots,
                    availableSlots: result.availableslots,
                    bookedSlots: result.bookedslots,
                });
            } else {
                dispatch({
                    type: 'AGENT_AVAIL_SLOTS',
                    data: []
                });
            }
        }
    });
};
export const findAgentLeaves = (data) => dispatch => {

    dispatch({
        type: 'UPDATE_LOADER',
        status: true,
        element: 'AGENT_ALL_LEAVES'
    });
    let leaveData = schedulerService.getAgentLeaves(data);

    leaveData.then(leaveData => {
        console.log(leaveData.data)
        dispatch({
            type: 'UPDATE_LOADER',
            status: false,
            element: 'AGENT_ALL_LEAVES'
        });
        if (leaveData.data.success === true) {
            let result = leaveData.data.data;
            if (result) {
                console.log(result)
                let leavedates = result.map(res => {
                    return res.leavedate;
                })

                let datewiseSlots = [];

                if (result.length > 0) {
                    for (var i = 0; i < result.length; i++) {
                        datewiseSlots[result[i].leavedate] = result[i].slots;
                    }
                }

                dispatch({
                    type: 'AGENT_ALL_LEAVES',
                    data: result,
                    leavedates: leavedates,
                    datewiseSlots: datewiseSlots
                });
            } else {
                dispatch({
                    type: 'AGENT_ALL_LEAVES',
                    data: []
                });
            }
        }
    });
};

export const getTrovaUserInfo = (data) => dispatch => {

    let userdata = schedulerService.getTrovaUserInfo(data);

    userdata.then(userdata => {
        console.log(userdata)
        console.log(userdata.data)
        if (userdata.data.success === true) {
            dispatch({
                type: 'GET_TROVA_USERINFO',
                data: userdata.data.data

            });
        }


    });
};
export const updateCallStatus = (data) => dispatch => {

    let updated = schedulerService.updateCallStatus(data);

    updated.then(res => {
        dispatch({
            type: 'UPDATE_CALL_STATUS',
            updated: true

        });

    });
};

export const getScheduleCalls = (data) => dispatch => {
    dispatch({
        type: 'SCHEDULE_LOADER',
        status: true,
    });
    let updated = schedulerService.getScheduleCalls(data);

    updated.then(updated => {
        console.log(updated)
        if (updated.data.success === true) {

            let calldata = updated.data.data
            dispatch({
                type: 'GET_SCHEDULE_CALLS',
                todayscalls: calldata.todayscalls,
                allcalls: calldata.allcalls,
                scheduleddates: calldata.scheduleddates,
                loader: false
            });
        }
        dispatch({
            type: 'SCHEDULE_LOADER',
            status: false,
        });


    });
};

export const saveCallSchedule = (data) => dispatch => {

    let updated = schedulerService.saveCallSchedule(data);

    updated.then(res => {
        dispatch({
            type: 'SAVE_CALL_SCHEDULE',
            saved: true

        });

    });
};


export const saveTrovaUserProfile = (enqparams, leadparams) => dispatch => {
    dispatch({
        type: 'SAVE_TROVA_USER',
        usersaved: false,
        loader: true

    });
    let updated = schedulerService.saveTrovaUserProfile(enqparams);

    updated.then(res => {
        let enqupdated = schedulerService.saveTrovaUserProfile(leadparams);
        enqupdated.then(eres => {

            dispatch({
                type: 'SAVE_TROVA_USER',
                usersaved: true,
                loader: false

            });
        });

    });
};


export const getUserPassword = (data) => dispatch => {
    dispatch({
        type: 'SCHEDULE_LOADER',
        status: true,
    });
    let userdata = schedulerService.getUserPassword(data);

    userdata.then(userdata => {
        console.log(userdata)
        if (userdata.data.success === true) {

            let user_pass = userdata.data.data
            dispatch({
                type: 'GET_USER_PASS',
                user_pass: user_pass,
                loader: false
            });
        }
        dispatch({
            type: 'SCHEDULE_LOADER',
            status: false,
        });


    });
};
export const getStateList = (data, field_type, collectionid) => dispatch => {
    dispatch({
        type: 'SCHEDULE_LOADER',
        status: true,
    });
    let stateData = schedulerService.getCollectionData(data);


    stateData.then(stateData => {
        if (stateData.data.results && stateData.data.results.success === true) {
            //console.log(stateData.data.results.data)

            let stateList = parseCollectionDataOptions(stateData.data.results.data, field_type, collectionid);
            // console.log(stateList)
            dispatch({
                type: 'GET_TROVA_STATES',
                stateList: stateList,
                loader: false
            });
        }
        dispatch({
            type: 'SCHEDULE_LOADER',
            status: false,
        });


    });
};

export const getCityList = (data, field_type, collectionid) => dispatch => {
    dispatch({
        type: 'SCHEDULE_LOADER',
        status: true,
    });
    let cityData = schedulerService.getCollectionData(data);

    cityData.then(cityData => {
        if (cityData.data.results && cityData.data.results.success === true) {
            let cityList = parseCollectionDataOptions(cityData.data.results.data, field_type, collectionid);
            //console.log(cityList)
            dispatch({
                type: 'GET_TROVA_CITIES',
                cityList: cityList,
                loader: false
            });
        }
        dispatch({
            type: 'SCHEDULE_LOADER',
            status: false,
        });

    });
};

export const getLeadList = (data, field_type, collectionid) => dispatch => {
    dispatch({
        type: 'SCHEDULE_LOADER',
        status: true,
    });
    let leadData = schedulerService.getCollectionData(data);

    leadData.then(leadData => {
        if (leadData.data.results && leadData.data.results.success === true) {
            let leadList = parseCollectionDataOptions(leadData.data.results.data, field_type, collectionid);
            //console.log(leadList)
            dispatch({
                type: 'GET_TROVA_LEADS',
                leadList: leadList,
                loader: false
            });
        }
        dispatch({
            type: 'SCHEDULE_LOADER',
            status: false,
        });

    });
};

function parseCollectionDataOptions(optionData, field_type, collectionid) {
    let externalValues = optionData;
    let collection = collectionid;
    let optArray = [];
    if (Array.isArray(externalValues)) {
        optArray = externalValues.map(external => {
            let option = {};
            //console.log(external)
            option['value'] = external.value[0].value;
            option['displayvalue'] = '';
            option['additionaltext'] = '';
            if (external.displayvalue !== false && external.displayvalue !== '' && external.displayvalue !== null) {
                if (field_type === 'checkbox' || field_type === 'check_image' || field_type === 'switch' || field_type === 'dropdown') {
                    option['value'] += ", " + external.displayvalue;
                } else if (field_type === 'autofill') {
                    option['displayvalue'] = external.displayvalue;
                    option['autofill'] = external.autofill;
                }
            }
            if (external.additionaltext !== false && external.additionaltext !== '' && external.additionaltext !== null && field_type === 'autofill') {
                option['additionaltext'] = external.additionaltext;

            }

            option['recordid'] = external.recordid;
            option['collection'] = collection.toString();
            option['fieldid'] = external.fieldid;
            return option;
        });
    }

    return optArray;

}

export const getFormJson = (data) => dispatch => {
    let showformdata = entity.showForm(data);

    showformdata.then(showformdata => {
        console.log(showformdata)
        dispatch({
            type: 'GET_TROVA_FORM_JSON',
            entityId: data._id,
            formJson: {[data._id]: showformdata.data.data}
        })
    })
};


export const getAllSlots = (data) => dispatch => {

    let slotData = schedulerService.getAllSlots(data);

    slotData.then(slotData => {
        //console.log(slotData)
        if (slotData.data.success === true) {

            let slotArr = slotData.data.data;
            let slotList = [];
            for (var i = 0; i < slotArr.length; i++) {
                let slot = {"text": slotArr[i], "value": slotArr[i]};
                if (i > 5) {
                    let slotStr = slotArr[i].split("-");
                    let timeSlot = "";
                    if (slotArr.length > 1) {
                        let timeStr1 = formatSlot(slotStr[0]);
                        let timeStr2 = formatSlot(slotStr[1]);
                        timeSlot = timeStr1 + "-" + timeStr2;
                        slot = {"text": timeSlot, "value": slotArr[i]};
                    }
                }

                slotList.push(slot);
            }
            //console.log(slotList)

            dispatch({
                type: 'GET_ALL_SLOTS',
                slotList: slotList,
                loader: false
            });
        }

    });
};

const formatSlot = (timeStr) => {

    let timeArr = timeStr.split(":");
    let timeSlotStr = timeStr;
    if (timeArr.length > 1) {
        if ((parseInt(timeArr[0]) === 12 && parseInt(timeArr[1]) > 0) || parseInt(timeArr[0]) > 12) {
            if (parseInt(timeArr[0]) > 12) {
                timeSlotStr = "0" + (parseInt(timeArr[0]) - 12) + ":" + timeArr[1];
            } else if (parseInt(timeArr[0]) === 12) {
                timeSlotStr = "12:" + timeArr[1];
            }

            //timeSlotStr = "0" + (parseInt(timeArr[0]) - 12) + ":" + timeArr[1];
        }
    }

    return timeSlotStr;
}

export const saveAgentLeave = (data) => dispatch => {

    let leaveSaved = schedulerService.saveAgentLeave(data);

    leaveSaved.then(leaveSaved => {
        dispatch({
            type: 'SAVE_AGENT_LEAVE',
            leaveSaved: true

        });

    });
};
