import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as collectionActions from "../../../actions/Collections";
import * as taskActions from "../../../actions/ToDo";
import {ES_INDEX} from '../../../constants'
import DatePickerBox from "../../views/DatePickerBox"
import AutoComplete from "../../views/AutoComplete"
import {Form} from "react-bootstrap";
import * as usersActions from "../../../actions/Users";
import {formatDate, getUrlSegment} from "../../../utilities/CustomFunctions";
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {USRIMG_PATH} from "../../../constants";
import LoaderSvg from "../../views/LoaderSVG";

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
class AddNewProjectBoard extends Component {

    constructor(props) {
        super(props);

        this.state={
            name:'',
            description : '',
            enddate:new Date(),
            startdate:new Date(),
            projectname:'',
            clientname:'',
            project_id :0,
            selectedItems : [],
            Close:false,
            newDiscussionLoading:0,
            stageforward: 'allUser'
        };
        this.ProjectInputChange= this.ProjectInputChange.bind(this);
        this.discussionUsers = this.discussionUsers.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.CreateProject=this.CreateProject.bind(this)
        this.UserSelection=this.UserSelection.bind(this)

    }

    ProjectInputChange(e) {
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }


    }

    componentDidMount() {
        // this.setState({
        //     selectedItems : this.props.listtags ? this.props.listtags : this.state.selectedItems
        // });

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        var projectid = (atob(getUrlSegment(4)));
        //console.log( projectid)
        var  beta =  this;
        if(prevProps.discussionAdddata.project_discussion !== this.props.discussionAdddata.project_discussion ) {
            setTimeout(function () {
                let Ddata ={
                    "projectid": projectid,
                    "systype": getProfile().usertype,
                    "uid": getProfile().id,
                    "from": 0,
                    "size": 50,

                }
                beta.props.getprojectDiscussions(Ddata);
            }, 1000)

            this.setState({loading: 0});

        }


    }

    discussionUsers(e, userCount) {

        this.setState({
            userCount : userCount,
            [e.target.name]:e.target.value
        })


        let userslist = this.props.listtags? this.props.listtags : '';
        //console.log(userslist)
        //console.log(userslist.length)

        let assignee_ids = userslist.map(a => parseInt(a.user_id));

        this.setState({assignee_ids : assignee_ids})
        ////console.log(assignee_ids)
    }
    UserSelection(e, userid) {

        userid = parseInt(userid);
        ////console.log(this.state.assignee_ids )
        let assignee_ids = [...this.state.assignee_ids];
        (assignee_ids.indexOf(userid) > -1) ? assignee_ids.splice(assignee_ids.indexOf(userid), 1) : assignee_ids.push(userid);
        this.setState({assignee_ids: assignee_ids});

    }


    resultDataList( result, reference) {
        ////console.log(result);
        this.setState({ [reference] : result });
    }
    CreateProject(event) {
        event.preventDefault();
        let formValid = 0;

        ////console.log(this.state.assignee_ids )
        var assignee_ids = this.state.assignee_ids;
        var projectname = this.state.name;
        var description = this.state.description;

        var projectid = (this.state.project_id =='0' ? moment().valueOf() : this.state.project_id);
        var mode = (this.state.project_id =='0' ? 'save' : 'update');


        if (this.state.name === '') {
            formValid++;
            document.getElementsByName('name')[0].classList.add('val-error');
        }

        if (this.state.description === '') {
            formValid++;
            document.getElementsByName('description')[0].classList.add('val-error');
        }

        if(formValid === 0){
            this.setState({newDiscussionLoading: 1})
            let discussiontopic = {
                projectid: projectid,
                topic: projectname,
                description: description,
                type: getProfile().usertype,
                uid: getProfile().id,
                assignees: assignee_ids,
            };

            ////console.log(discussiontopic);
            this.props.saveprojectdiscussion(discussiontopic);
            // this.props.AddmodalClose();
        }
    }

    render() {
        // //console.log(this.state.edistartdate);  //console.log(this.state.edienddate);
        let users = this.props.listtags? this.props.listtags : '';
        let userlength = this.props.userlength;
        ////console.log(users)
        ////console.log(this.state.assignee_ids )
        return (
            <>
                <div className="task-content-block">
                    <div className="persitant-top">
                        {this.state.newDiscussionLoading === 1 &&
                        <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                        }
                        <div className="task-input-block">
                            <input type="text" id="name" name="name" className="task-input-box--new" value = {this.state.name} placeholder="Name" onChange={this.ProjectInputChange} />
                        </div>
                        <div className="task-input-block">
                            <textarea className="task-textarea--new" id="description" name="description"
                                      placeholder="Description" value = {this.state.description} onChange={this.ProjectInputChange}></textarea>
                        </div>

                        <div className="select-options">
                            <div className="radio-block">
                                <input type="radio" id="singlestep" className="off_type" name="stageforward" defaultValue="allUser"
                                       checked={this.state.stageforward === 'allUser' ? true : false} onChange={(e) => this.discussionUsers(e, 'all')}/>
                                <label htmlFor="singlestep">Accessible to all {users.length} users</label>
                            </div>
                            <div className="radio-block">
                                <input type="radio" id="singlesteps" className="off_type" name="stageforward" defaultValue="Specific"
                                       checked={this.state.stageforward === 'Specific' ? true : false} onChange={(e) => this.discussionUsers(e, 'specific')}/>
                                <label htmlFor="singlestep">Only visible to specific users</label>
                            </div>
                            { this.state.userCount === 'specific' &&
                            <div>
                                <ul className="pj-added-user">
                                    {users.map((item, index) =>
                                        <li className={`${this.state.assignee_ids.indexOf(parseInt(item.user_id)) > -1 ? '':'active'}`} key={index}>
                                            <div className="ind-added-user-list">
                                                <img src={USRIMG_PATH + item.img_name} />
                                                <div className="pj-remove-block" onClick={(e) => this.UserSelection(e, item.user_id)} id={item.user_id}>
                                                    <button className="add-remove"></button>
                                                </div>
                                            </div>
                                        </li>
                                    )}

                                </ul>
                            </div>
                            }
                        </div>



                    </div>
                    <div className="persitant-footer">
                        <div className="group-row">

                        </div>
                        <button data-masterid="0" data-mode="save" type="button" className="general-btn" onClick={(e)=> this.CreateProject(e)}
                                id="createproject">{this.state.project_id =='0' ? 'Create' : 'Update'}
                        </button>
                    </div>
                </div>


            </>


        );
    }
}


const mapStateToProps = state => ({
    listUsers:state.UsersReducer.listUsersdata,
    discussionAdddata:state.ProjectReducer.SaveProjectDiscussion
});
export default  withRouter(connect(mapStateToProps, {
    createProject : taskActions.saveToDoAttachments,
    saveprojectdiscussion:projectActions.SaveProjectDiscussion,
    getprojectDiscussions : projectActions.getProjectDiscussions,

})(AddNewProjectBoard));



