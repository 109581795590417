import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as taskActions from "../../actions/ToDo";
import {getProfile} from "../../utilities/AuthService";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import TaskDetails from "./task/TaskDetails";
import WorkflowTaskDetails from "./workflow-task/WorkfowTaskDetails";
import rootActions from "../../actions";
import LoaderSvg from "../views/LoaderSVG";
class DetailContainer extends Component {

    componentDidMount() {
        this.props.mainClick('task','task/details');
        var taskid = (atob(getUrlSegment(3)));
        var mode = (atob(getUrlSegment(4)));
        this.setState({taskmode : mode});
        let data = { _id : taskid, systype: getProfile().usertype, uid : getProfile().id, mode: mode };
        this.props.gettaskdetails(data);
        this.props.getattchmentlist({"_id": taskid, "reference": "comment"});
        this.props.getloglist({"_id": taskid, "type": "todo"});
        /* set props to default */
        this.props.modifyTaskTodefault()

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /* works only parent task button enable */
        if(prevProps.match !== this.props.match) {
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            this.setState({taskmode : mode});
            let data = { _id : taskid, systype: 'user', uid : getProfile().id, mode: mode };
            this.props.gettaskdetails(data);
        }

        if(this.props.modifyTask.task_modified !== prevProps.modifyTask.task_modified){


            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            this.setState({taskmode : mode});
            let data = { _id : taskid, systype: "root", uid : getProfile().id, mode: mode };
            if(this.props.modifyTask.task_type ===  'cleardata') {
                var that=this
                setTimeout(function () {
                    that.props.gettaskdetailsAsync(data);
                    that.props.modifyTaskTodefault()
                }, 500)

            }

          //  this.props.gettaskdetails(data);
        }


    }

    render() {
        let taskData = this.props.taskdetailsData.taskdetails;
        let list = taskData.parent;
        //////////console.log(taskData)
        return(
            <div className="right-content-wrapper" key={`right-content-wrapper`}>
                <div className="task-wrapper task-detail-stand" key={`task-wrapper task-detail-stand`}>
                    {this.props.taskdetailsData.loading &&
                    <span className="Loader-holder">
                        <LoaderSvg/>
                    </span>
                    }
                    {list  &&
                        <React.Fragment key={"task-detail-type-1"}>
                            {(list.createdfrom === "task" || list.createdfrom === "") &&
                            <TaskDetails
                                taskdetailsData={this.props.taskdetailsData}
                                attachmentData={this.props.attachmentData}
                                logData = {this.props.todologData}
                            />
                            }


                            {list.createdfrom === "workflow" &&
                            <WorkflowTaskDetails
                                taskdetailsData={this.props.taskdetailsData}
                                attachmentData={this.props.attachmentData}
                                logData = {this.props.todologData}/>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    taskdetailsData : state.TaskReducer.TaskDetails,
    modifyTask:state.TaskReducer.ModifyTask,
    attachmentData : state.TaskReducer.Attachmentlist,
    todologData : state.TaskReducer.TodoLoglist

});

export default  withRouter(connect(mapStateToProps, {
    gettaskdetails:taskActions.taskdetails,
    gettaskdetailsAsync:taskActions.gettaskdetailsAsync,
    mainClick:rootActions.commonActions.MenuClick,
    getattchmentlist : taskActions.getattchments,
    getloglist : taskActions.gettodologs,
    modifyTaskTodefault:taskActions.modifyTaskTodefault,
})(DetailContainer));
