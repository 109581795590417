import React, {Component} from 'react';
import {
    BarChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Area,
    Legend,
    ResponsiveContainer,
    LabelList
} from 'recharts';
/*const data = [
    {
        "x": 10,
        "y1": 60,
        "y2": 10,
        "y3": 10,
        "y4": 10,
        "y5": 10
    }
    ,
    {
        "x": 20,
        "y1": 10
    },
    {
        "x": 30,
        "y1": 10
    },
    {
        "x": 40,
        "y1": 10
    },
    {
        "x": 50,
        "y1": 10
    },
    {
        "x": 60,
        "y1": 10
    },
    {
        "x": 70,
        "y1": 10
    },
    {
        "x": 80,
        "y1": 10
    },
    {
        "x":90,
        "y1": 10
    },
    {
        "x": 100,
        "y1": 10
    }
]*/
const data = [
    {
        name: 'Page A', y1: 200, y2: 230
    },
    {
        name: 'Page B', y1: 200, y2: 230
    },
    {
        name: 'Page C', y1: 200, y2: 230
    },
    {
        name: 'Page D', y1: 201, y2: 230
    },
    {
        name: 'Page E', y1: 201, y2: 230
    },
    {
        name: 'Page F', y1: 201, y2: 235
    },
    {
        name: 'Page G', y1: 201, y2: 235
    },
    {
        name: 'Page E', y1: 203, y2: 230
    },
    {
        name: 'Page F', y1: 203, y2: 235
    },
    {
        name: 'Page G', y1: 203, y2: 235
    },
];

function StackedBarChart(props) {
    console.log(props);
    return (
        <React.Fragment>
            <ResponsiveContainer width="100%" height={300} >
                <BarChart barGap={0} barCategoryGap={0}
                    data={props.data} >
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="x" padding={{left:0, right:0}} axisLine={false} tickLine={false}/>
                    <YAxis axisLine={false} tickLine={false}/>
                    <Tooltip />
                    <Legend align={'left'} verticalAlign="top" height={36} iconType={'circle'}/>
                    <Bar name="Men" dataKey="y" stackId="a" fill="red"  radius={[0, 0, 5, 5]}>
                        <LabelList dataKey="y" position="center" style={{ textAnchor: 'middle', fontSize: '80%', fill :'#fff' }}  />
                    </Bar>
                    <Bar name="Women"  dataKey="z" stackId="a" fill="#8FC8E7"  radius={[5, 5, 0, 0]}>
                        <LabelList dataKey="z" position="center" style={{ textAnchor: 'middle', fontSize: '80%', fill :'#fff' }}/>
                    </Bar>

                </BarChart >
            </ResponsiveContainer>

        </React.Fragment>
    );
}

export default StackedBarChart;