import React, {Component} from 'react';
import {connect} from "react-redux";
import * as usersActions from "../../../actions/Users"
import {withRouter} from 'react-router';
import * as commonActions from "../../../actions/Common";
import LoaderSvg from "../../views/LoaderSVG"
import * as collectionActions from "../../../actions/Collections";
import {getProfile} from "../../../utilities/AuthService";
import {ES_INDEX, SHARED_LOGIN, USRIMG_PATH} from '../../../constants'
import rootActions from "../../../actions";
import {Scrollbars} from "react-custom-scrollbars";
import NoData from "../../views/NoData";
import {debounce} from "throttle-debounce";
let savemodulelist = [];
class RoleModulePermission extends Component {

    constructor(props) {
        super(props);

        this.state={
            showFormModal: false,
            SaveDatalist : [],
            collectionId: ES_INDEX + '10003',
            loading: 0,
            offset:20,
            lastOffset:0
        }
        this.rolemodpermission = this.rolemodpermission.bind(this);
        this.listenToScroll=debounce(500, this.listenToScroll);
    }



    componentDidMount() {
        this.props.mainClick('team','userpermissions');
        this.props.rolemodulepermission();
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": 'rolemodulepermissionlisting',
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if(prevProps.saveRoleModPermissionData.roleModPermission !== this.props.saveRoleModPermissionData.roleModPermission) {
            setTimeout(function () {
                beta.props.rolemodulepermission();
                beta.setState({loading: 0});
            }, 1000)
        }
    }


    rolemodpermission(uid,mid){

        let allmodpermission=this.props.RoleModulePermission ? this.props.RoleModulePermission.RoleModpermission : [];
        if(allmodpermission.length > 0){
            let updatedArray = this.state.SaveDatalist;
            let roleexist = updatedArray.filter(permissions => permissions.roleid  === uid);

            if(roleexist.length > 0){
             updatedArray.map((newarray, index) =>{
                    if(newarray.roleid == uid){
                        let newpermission = newarray.modules;
                        newpermission.map((newprmsn, index1) =>{
                            if(newprmsn.module_id == mid){
                                updatedArray[index].modules[index1].permission = newprmsn.permission == 'yes' ? 'no' :  'yes';
                            }
                        })
                    }
                })
                this.setState({SaveDatalist: updatedArray});
            } else{
                let selectedroledet = allmodpermission.filter( modules => modules.roleid  === uid);
                let selectedrolepermissions = selectedroledet[0].modules;
                allmodpermission.map((module, index) =>{
                    if(module.roleid == uid){
                        let newpermission = module.modules;
                      newpermission.map((newprmsn, index1) =>{
                            if(newprmsn.module_id == mid){
                                allmodpermission[index].modules[index1].permission = newprmsn.permission == 'yes' ? 'no' :  'yes';
                            }
                        })
                    }
                })
                this.setState({SaveDatalist: allmodpermission});
            }

        }
    }
    roleModulepermission(){
        this.setState({loading: 1});
        console.log(this.state.SaveDatalist);
        var data = {
            "creator" : getProfile().id,
            "permissionlist" : this.state.SaveDatalist
        };
     this.props.saverolemodpermission(data);
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": 'alluserpermissionsave',
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);

    }


    render() {
        let rolemodepermission=this.props.RoleModulePermission.RoleModpermission;
        return (
            <div className="permission-settings permission-main  full-width">
                {this.props.RoleModulePermission.loading || this.state.loading === 1 &&
                <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                }

                {rolemodepermission.length === 0 &&
                <div className='absolute-holder'>
                    <NoData
                        msg={`No Roles added yet`}

                    />
                </div>
                }
                <div className="permission-settings-panel">
                    <div className="permission-settings-panel-header">
                        <h5>Permission Settings</h5>
                        <button className="button submit-button" onClick={() => this.roleModulepermission()}>Submit</button>
                    </div>
                    <div className="permission-settings-panel-body">
                        <div className="user-table-wrapper wid-40">
                            <div className="user-table">

                                <div className="user-table-body">
                                    {rolemodepermission.length > 0 && rolemodepermission.map((modulelst, index1) =>
                                        <div className="user-tr">
                                            <div className="user-td">
                                                <div className="setting-each-user">
                                                    <div className="each-user-details">
                                                        <p>{modulelst.role}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {modulelst.modules.map((module, items) => {
                                                    let userPermsn=this.state.SaveDatalist.filter((list,index)=>list.roleid===modulelst.roleid)
                                                    let modulepermsn=userPermsn.length !==0?userPermsn[0].modules.filter((list)=>list.module_id===module.module_id)[0].permission:[]
                                                let mStatus=userPermsn.length !==0?modulepermsn:module.permission
                                                return (<div className="user-td">
                                                            <div
                                                                className={`permission-checkbox ${mStatus === 'yes' ? 'active' : ''}`}>
                                                                <input type="checkbox"
                                                                       id={modulelst.roleid + '_' + module.module_id}
                                                                       name={module.module}
                                                                       onClick={() => this.rolemodpermission(modulelst.roleid, module.module_id)}/>
                                                                <label>{module.module}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}


                                        </div>
                                    )}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>







        );
    }
}



const mapStateToProps = state => ({
    menuClickData:state.CommonReducer.menuClick,
    RoleModulePermission:state.UsersReducer.RoleModulePermissiondata,
    saveRoleModPermissionData:state.UsersReducer.saveRoleModPermission,
});

export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    rolemodulepermission:usersActions.listRolemodulePermission,
    saverolemodpermission:usersActions.saveRolemodulePermission,
    activityTracking:rootActions.commonActions.activityTracking

})(RoleModulePermission));


