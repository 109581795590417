import React, {Component} from 'react';
import moment from 'moment';

class DashboardMilestoneTile extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    render() {
        let mileStoneList = this.props.mileStoneList ? this.props.mileStoneList:[];
        return (
            <div className="ibt-progress-table">
                <div className="ibt-progress-table-header">
                    <h5>Project Cycle</h5>
                </div>
                <div className="ibt-progress-table-body">
                    {mileStoneList.length === 0 ?
                    <div className="full-no-data">
                        <h5><br/>No data found !</h5>
                    </div>
                    :
                    <div className="scrollable">
                        <table className="table ibt-table">
                            <thead>
                            <tr>
                                <th scope="col">MILESTONE</th>
                                <th scope="col">RESPONSIBLE</th>
                                <th scope="col">END DATE</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {mileStoneList.map((list,index)=>{
                                let perCalculated= this.props.showProgress(list.completedtask,list.totaltask);
                                let completedmilestone = list.completedmilestone;
                                return(
                                <tr>
                                    <td>
                                        <h5>{list.milestone_name}</h5>
                                    </td>
                                    <td>{list.responsibleuser}</td>
                                    <td>{moment(list.enddate).format('Do MMM YYYY')}</td>
                                    <td>
                                        <div className="mile-progress">
                                        <div className="progress">
                                            <div className="progress-bar"
                                                 role="progressbar"
                                                 aria-valuenow="0" aria-valuemin="0"
                                                 aria-valuemax="100"
                                                 style={{width: (completedmilestone===true ? "100%" : perCalculated+'%' )}}></div>
                                        </div>
                                        <span className="prog-count">{completedmilestone===true ? "100%" : perCalculated+'%'}</span>
                                        </div>
                                    </td>

                                </tr>
                                )}
                            )}
                            </tbody>
                        </table>
                    </div>
                    }
                </div>
            </div>

        );
    }


}

export default DashboardMilestoneTile;