import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../utilities/CustomFunctions";
import rootActions from "../../actions";
import ProjectHeader from "../views/ProjectHeader";
import * as projectActions from "../../actions/Project";
import LoaderSVG from "../views/LoaderSVG"
import DashboardMilestoneTile from "../views/DashboardMilestoneTile";
import FundamentalsTile from "../views/FundamentalsTile";
import FinancialPerfomance from "../views/FinancialPerfomance";
import DashboardListCard from "../views/DashboardListCard";
import ProjectDashboardGraph from "../views/ProjectDashboardGraph";
import ImpactSnapshotBlock from "../views/project-dashboard/ImpactSnapshotBlock";
import ProjectFeatures from "../views/ProjectFeatures";
import RatingBlock from "../views/project-dashboard/RatingBlock";
import FinancialBlock from "../views/project-dashboard/FinancialBlock";
import BarChartCard from "../views/General/BarChartCard";
import BarChartTile from "../views/project-dashboard/BarChartTile";
import FinancialTile from "../views/project-dashboard/FinancialTile";
import StackedBarChart from "../views/General/StackedBarChart";
class ProjectDashboard extends Component {

    constructor(props) {
        super(props);

        this.state={
            expandHeader : false
        }
        this.expandHeader = this.expandHeader.bind(this)
    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }

    componentDidMount() {
        this.setState({loading: 1});
        var projectId = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/dashboard/'+projectId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        let mileStoneList = this.props.listAllProjectDetailData.projectDetails ? this.props.listAllProjectDetailData.projectDetails.milestones:[];
        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>
                <div className="ibt-body">
                    {this.props.listAllProjectDetailData.loading &&
                    <span className="Loader-holder">
                            <LoaderSVG/>
                        </span>
                    }

                    <RatingBlock/>

                    <FinancialBlock />
                    <FinancialTile title={'Multi Area'} type={"multi_area"}  color1={"#FFA960"} color2={"#BACCA1"} color3={"#8FC8E7"} />
                    <FinancialTile title={'Stacked Bar Chart'} type={"stacked_bar"}  color1={"#FFA960"} color2={"#BACCA1"} color3={"#8FC8E7"} />
                    <FinancialTile title={'Tree Map'} type={"tree_map"}  color1={"#FFA960"} color2={"#BACCA1"} color3={"#8FC8E7"} />
                    <FinancialTile title={'Multi Bar Chart'} type={"multi_bar"}  color1={"#FFA960"} color2={"#BACCA1"} color3={"#8FC8E7"} />

                    <ImpactSnapshotBlock />




                    <div className="ibt-split-table">
                        <div className="ibt-progress-table">
                            <div className="ibt-progress-table-header">
                                <h5>Customers Impacted</h5>
                            </div>
                            <DashboardListCard />
                        </div>
                        <BarChartTile />
                    </div>
                    <div className="ibt-split-table">
                        <div className="blue-lister">
                            <div className="blue-list-header green-list">
                                <div className="blue-list-header-left">
                                    <h5>S</h5>
                                </div>
                                <div className="blue-list-header-right">
                                    <h5>Strength</h5>
                                </div>
                            </div>
                            <ProjectFeatures />
                        </div>
                        <div className="blue-lister">
                            <div className="blue-list-header">
                                <div className="blue-list-header-left">
                                    <h5>W</h5>
                                </div>
                                <div className="blue-list-header-right">
                                    <h5>Weaknesses</h5>
                                </div>
                            </div>
                            <ProjectFeatures />
                        </div>
                        <div className="blue-lister">
                            <div className="blue-list-header ash-list">
                                <div className="blue-list-header-left">
                                    <h5>O</h5>
                                </div>
                                <div className="blue-list-header-right">
                                    <h5>Opportunities</h5>
                                </div>
                            </div>
                            <ProjectFeatures />
                        </div>
                        <div className="blue-lister">
                            <div className="blue-list-header navy-list">
                                <div className="blue-list-header-left">
                                    <h5>T</h5>
                                </div>
                                <div className="blue-list-header-right">
                                    <h5>Threats</h5>
                                </div>
                            </div>
                            <ProjectFeatures />
                        </div>
                    </div>
                    <DashboardMilestoneTile mileStoneList={mileStoneList}/>
                </div>
            </div>



        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
    listAllProjectDetailData:state.ProjectReducer.listallProjectsdetails,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    projectdetails:projectActions.listallprojectsdetails,
})(ProjectDashboard));
