import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import {Draggable, Dropzone, state} from 'react-page-maker';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
const FlexibleContent = (props) => {
  // make sure you are passing `parentID` prop to dropzone
  // it help to mainatain the state to meta data
  const {
    dropzoneID,
    parentID,
    showBasicContent,
    showPreview,
    id,
    dropzoneProps,
    initialElements,
    ...rest
  } = props;

  if (showBasicContent) {
    return (
      <Draggable {...props} >
        <span className="drag-icon">{ rest.name }</span>
      </Draggable>
    );
  }

  const _onDrop = (data, cb) => {
    // no need to ask id and name again
    if (data.payload && data.payload.dropped) {
      return cb(data);
    }

    // This can be an async call or some modal to fetch data
    let name = data.name;

    let uniqueId=localStorage.getItem('uniqueId')?parseInt(localStorage.getItem('uniqueId')):0
    let newid=uniqueId+1
    const id = "id_"+(newid)
    localStorage.setItem('uniqueId', newid);

    const result = cb({
      ...data,
      name,
      id,
      payload: { dropped: true }
    });
  };

  if (showPreview) {
    let paymentRest = rest.fields && rest.fields.length !== 0 && rest.fields[0].type ? rest.fields[0].type : '';
    return (
        <div className={`floating-summary ${paymentRest === 'PAYMENT_CONTAINER' ? 'ge-float-summary'  :""}` }>
          {rest.childNode['canvas-1-1']}

      </div>
    )
  }

  const filterInitialElements = (dID) => {
    return initialElements.filter(e => e.dropzoneID === dID) || [];
  };

  return (
    <Draggable id="grid-layout-builder" {...props}  draggable={!props.DragDisabled.dragStatus}>
      <div className="layout-builder-titles">{ rest.name }</div>
      <div className="mt-3 inner-layout-builder">
        <FaTrash
            className="pull-right"
            color="#dc3545"
            onClick={() => state.removeElement(id, dropzoneID, parentID)}
        />
        <Row className="row inner-layout-builder">

          <Col sm="12">
            <Dropzone
              {...dropzoneProps}
              initialElements={filterInitialElements('canvas-1-1')}
              id="canvas-1-1"
              onDrop={_onDrop}
              placeholder="Drop Here"
            />
          </Col>

        </Row>
      </div>
    </Draggable>
  );
};

FlexibleContent.propTypes = {
  id: PropTypes.string.isRequired,
  showBasicContent: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
  DragDisabled:state.DynamicDashboard.getDragDisabled
});
export default   withRouter(connect(mapStateToProps, {


})(FlexibleContent));

