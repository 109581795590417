import React, { useState, useEffect ,Suspense } from 'react';
import LoaderSvg from "../../views/LoaderSVG";
const GoogleMapMarkers = React.lazy(() =>  import('./GoogleMapMarkers'))
const renderLoader = () => <span className="Loader-holder"><LoaderSvg/></span>;
function Template3(Props) {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const container = document.querySelector('.card-container');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        var scrollY = 0;
        var scrollX = 0;
        var doc = document.getElementById("a");
        if(doc.scrollTop  !== scrollY) {
            const container = document.querySelector('.card-container')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            if (contentHeight <= scrollTop) {
                Props.loadmoreCollectionData()
            };
        }
          setIsFetching(true);
    }

    function fetchMoreListItems() {
        Props.loadmoreCollectionData()
    }


    let showlistField = []
    if(Props.label.showformdata.length !==0) {
        for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
            (Props.label.showformdata.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden")).map(list=>{
                showlistField.push(list)

            })
        }
    }
    let viewtemplate=Props.maptemplate
    //console.log(viewtemplate)
     let mapCoordinate=[]
    {Props.collData.map((row, rowIndex) =>
        {
            viewtemplate.map((list, index) =>

                mapCoordinate.push({
                    lat: row[list.item1.label],
                    long: row[list.item2.label],
                    temp: row[list.item3.label],
                    label: list.item3.label,
                    unit: row[list.item4.label]
                })
            )
        }
    )}
    //console.log(mapCoordinate)


    return (
        <>
            {/*{parseInt(Props.collData.length) !== parseInt(Props.collection.totalchilds)  &&*/}
            {/*<span className="Loader-holder">*/}
            {/*            ....Loading*/}
            {/*         </span>*/}
            {/*}*/}
            <div className={`card-container globe-container full-height-globe`}>
                <div className="record-counter">
                    <div className="mile-progress">
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                                 aria-valuemax="100" style={{width: Number.isInteger(  Math.floor((parseInt(Props.collData.length)/parseInt(Props.collection.totalchilds))*100))  ?Math.floor((parseInt(Props.collData.length)/parseInt(Props.collection.totalchilds))*100): 0 }}>

                            </div>
                        </div>
                        <span className="prog-count">{Number.isInteger(  Math.floor((parseInt(Props.collData.length)/parseInt(Props.collection.totalchilds))*100))  ?Math.floor((parseInt(Props.collData.length)/parseInt(Props.collection.totalchilds))*100): 0 }%</span></div>
                    <h5><span className="current-count">{Props.collData.length}</span> / <span className="total-count">{Props.collection.totalchilds}</span></h5>
                </div>
                <GoogleMapMarkers
                    mapCoordinate={mapCoordinate}
                    height={`calc(100vh - 100px)`}
                />

            </div>


            </>
    )

}

function TemplateVerticalPreview(Props) {
    //console.log(Props)
    let viewtemplate=Props.maptemplate
    let mapCoordinate=[]
    {Props.collection.CollectionData.slice(0, 1).map((row, rowIndex) =>
        {viewtemplate.map((list,index)=>

            mapCoordinate.push({lat:row[list.item1.masterid],long:row[list.item2.masterid],temp:row[list.item3.masterid]})

        )}
    )}
    return (
        <div className={`card-container`} id="a">
            <Suspense fallback={renderLoader()}>
            <GoogleMapMarkers
                mapCoordinate={mapCoordinate}
                height={`315px`}
            />
            </Suspense>
        </div>
    )

}


export  {Template3,TemplateVerticalPreview};

