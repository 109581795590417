import axios from 'axios'
import {API_PATH , ES_INDEX} from '../constants/index'
import {commonService} from "./common.services";
import {task} from "./task.services";
export const workflow = {
    listWorkFlow,
    WorkflowStageinfo,
    WorkflowUserinfo,
    WorkflowDetails,
    WorkflowFormDetails,
    stagetaskinfo,
    InvokeDetails,
    SavestageTask,
    SaveDisplaySettings,
    triggerwftask,
    ProcessNextStage,
    ProcessThroughApi,
    CloseWorkflowtask,
    SaveWorkflow,
    DeleteWorkflow,
    Saveworkflowcollection,
    Getstagetaskinfo,
    getWorkFlowTags,
    saveWorkflowBatch,
    listWorkflowBatch,
    deleteWorkflowBatch,
    activateWorkflowBatch,
    noformWorkFlow,
    saveWorkflowMetaForm,
    processAssignedNextStage,
    closeRecordWorkflow
};


function listWorkFlow(params){
    return commonService.sendHttpRequest('workflow/searchworkflow', params, "GET");

    //return axios.get(API_PATH + 'workflow/searchworkflow?uid='+uid+'&usertype='+usertype+'&offset='+limit+'&index='+index)
}
function WorkflowStageinfo(wid,search,limit,offset,initialstage,index){
    return axios.get(API_PATH + 'workflow/stageinfo?workflowid='+wid+'&search='+search+'&limit='+limit+'&offset='+offset+'&initialstage='+initialstage+'&index='+index)
}
function WorkflowUserinfo(wid,limit,offset,index){
    return axios.get(API_PATH + 'workflow/userinfo?workflowid='+wid+'&limit='+limit+'&offset='+offset+'&index='+index)
}
function WorkflowDetails(wid,index){
    return axios.get(API_PATH + 'workflow/workflowdetails?workflowid='+wid+'&index='+index)
}

function WorkflowFormDetails(params){
    ////////console.log(params);
    return commonService.sendHttpRequest('workflow/linkedcollection/'+params.wid, params, "GET");

}
function stagetaskinfo(stageid,index){
    return axios.get(API_PATH + 'workflow/stagetaskinfo?stageid='+stageid+'&index='+index)
}
function InvokeDetails(wid,index){
    return axios.get(API_PATH + 'workflow/invokedetails?workflowid='+wid+'&index='+index)
}
function SavestageTask(stageData){
    return axios.post(API_PATH + 'workflow/savestagetask?',{
        "workflowid": stageData.workflowid,
        "stageid": stageData.stageid,
        "taskid": stageData.taskid,
        "subject": stageData.subject,
        "uid": stageData.uid,
        "priority": stageData.priority,
        "mode": stageData.mode,
        "duein": stageData.duein,
        "instruction": stageData.instruction,
        "stageforward":stageData.stageforward,
        "to": stageData.to,
        "cc": stageData.cc,
        "//console.log": stageData.forms,
        //"tagsid": stageData.tagsid,
        "tagsname": stageData.tagsname,
        "files": [

        ],
        //"nextstage": stageData.nextstage,
        "metaform": stageData.metaform
    ,
        "formstatus": stageData.formstatus,
        "index": stageData.index
    })
}
function SaveDisplaySettings(wid,dispid,index){
    return axios.post(API_PATH + 'workflow/setinvokeheader?',{
        "workflowid":wid,
        "fields": [
            dispid
        ],
        "index":index
    })
}
function SaveWorkflow(params){
    return commonService.sendHttpRequest('workflow/saveworkflow', params, "POST");

}



function Saveworkflowcollection(params){
    return commonService.sendHttpRequest('workflow/linkworkflowcollection', params, "POST");

}


function triggerwftask(params) {

        return commonService.sendHttpRequest('workflow/triggerwftask', params, "POST");
}
function ProcessNextStage(params) {
    if(params.stage === 'final') {
        return task.MarkAsDone(params);
    }
    else{
        return commonService.sendHttpRequest('workflow/processnextstage', params, "POST");
        //return commonService.sendHttpRequest('workflow/workflowprocessnextstage', params, "POST");
    }
}

function ProcessThroughApi(params) {
    return commonService.sendHttpRequest('workflow/processthroughapi', params, "GET");
}

function CloseWorkflowtask(params) {
    return commonService.sendHttpRequest('task/closeworkflowtask', params, "GET");
}
function DeleteWorkflow(params){
    return commonService.sendHttpRequest('workflow/deleteworkflow', params, "POST");

}
function Getstagetaskinfo(params){
    return commonService.sendHttpRequest('workflow/stagetaskinfo', params, "GET");

}
function getWorkFlowTags(params) {
    return commonService.sendHttpRequest('task/todotags', params, "GET");
}
function saveWorkflowBatch(params) {
    return commonService.sendHttpRequest('workflow/savebatch', params, "POST");
}
function listWorkflowBatch(params) {
    return commonService.sendHttpRequest('workflow/batchlist', params, "GET");
}
function deleteWorkflowBatch(params) {
    return commonService.sendHttpRequest('workflow/deletebatch', params, "POST");
}
function activateWorkflowBatch(params) {
    return commonService.sendHttpRequest('/workflow/activatebatch', params, "POST");
}
function noformWorkFlow(params) {
    return commonService.sendHttpRequest('workflow/noformworkFlow', params, "GET");
}
function saveWorkflowMetaForm(params) {
    return commonService.sendHttpRequest('workflow/savemetaform', params, "POST");
}
function processAssignedNextStage(params) {
    return commonService.sendHttpRequest('workflow/processassignednextstage', params, "POST");
}
function closeRecordWorkflow(params) {
    return commonService.sendHttpRequest('workflow/updateinvokestatus', params, "POST");
}