import React, {Component} from 'react';
import {FRMSIMG_PATH, SHARED_LOGIN} from '../../constants/index'
import Coverimage from '../../images/placeholders/Coverimage.jpg';
import {getUrlSegment, isImage, isVideo} from "../../utilities/CustomFunctions"
import {getProfile} from "../../utilities/AuthService";
import moment from 'moment';
import OutsideAlerter from "./OutsideAlerter";
class ListCollection extends Component {

    constructor(props) {
        super(props);
        this.state={
            miniMize:false,
            Maximise:false,
            Close:false,
        }
        this.Minimize=this.Minimize.bind(this)
        this.Maximise=this.Maximise.bind(this)
        this.Close=this.Close.bind(this)
    }



    componentDidMount() {
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    handleAction(e, act, id) {
        this.props.updateAction(e, act, this.props.list, id);
    }
    Minimize(){
        this.setState({Maximise:false,miniMize:true})
    }
    Maximise(){
        if(!this.state.miniMize) {
            this.setState({Maximise: !this.state.Maximise, miniMize: false})
        }
        else{
            this.setState({Maximise: false, miniMize: false})
        }
    }

    Close(){

    }
    buildForm(e, id) {
        //e.preventDefault();
        e.stopPropagation();
        //alert(SHARED_LOGIN.redirect_url+ "Collection/buildform/"+id)
       window.location = SHARED_LOGIN.redirect_url+ "Collection/buildform/"+id+'/p';

    }

    render() {
         var list = this.props.list ? this.props.list : '';
         var index=this.props.index ? this.props.index : '';
        let createdDate = moment(list.createdate).utc().local().format('MMM DD,  YYYY');
         return (
             <div className="ind-pj-data" key={btoa(list._id)}>


                 <div className="pj-data-image">
                     {list.filename ?
                         <img src={FRMSIMG_PATH + list.filename} alt="no-image" /> :
                         <img src={Coverimage} alt="no-image" />
                     }
                     {list.has_form === 'no' &&
                     <button className="form-btn form-build" onClick={(e)=>this.buildForm(e,list._id)} >Build Form</button>
                     }
                 </div>
                 <div className="pj-data-content">
                     <span  onClick={list.has_form === 'yes' ? ((e)=>this.props.history.push('/project/details/collectiondetails/'+ btoa(list._id) + '/' +getUrlSegment(4))):undefined}>
                     <h5>{atob(list.title)}</h5>
                     <p>{atob(list.description)}</p></span>
                     {(this.props.viewtype === 'tile' &&  list.createuser == getProfile().id) &&
                         <div className="project-option-block-ab">
                             <button className="ico-lib-v1 more-sym" onClick={() => this.props.showOptions(index)}
                                     style={{pointerEvents: this.state.showOption === "true" ? "none" : ''}}></button>
                             {this.props.showOption === index &&
                             <OutsideAlerter status={this.props.showOption} Triggered={() => this.props.showOptions()}>
                                 <ul className="panel-option" style={{display: "block"}}>
                                     <li>
                                         <a className="panel-option-click ico-lib form-edit-ico"
                                            onClick={(e) => this.props.showCreateNewFormModal(e, list._id)}>Edit</a>
                                     </li>
                                     {list.has_form === 'no' &&
                                     <li>
                                         <a onClick={(e) => this.buildForm(e, list._id)}
                                            className="panel-option-click ico-lib-v1 build-form-ico">Build Form</a>
                                     </li>
                                     }
                                 </ul>
                             </OutsideAlerter>
                             }
                         </div>
                     }
                 </div>
                 <div className="pj-data-footer">
                     <p>By {list.creatorname}</p>
                     <p>On {createdDate}</p>
                 </div>
             </div>
        );
    }
}

export default ListCollection;
