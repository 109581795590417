import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
class OverviewDetails extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        var list = this.props.dataList;
        return(
            <li className="shaded">
                <div className="static-block">
                    <p>{list.label}</p>
                    <h5>{list.value} <span className="sm-text">Tasks</span></h5>
                </div>
            </li>

        );
    }
}

export default OverviewDetails;