import React from 'react';
import axios from 'axios';
import {Draggable, state} from 'react-page-maker';
import AreaLineChart from "../views/charts/AreaLineChart";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment, isValidDate} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {ViewData, SettingsPanel} from "./ViewData";
import SettingsModal from "./SettingsModal";

let graphData = [
    {
        "x": "2018 Q1",
        "y": "500",
        "z": "2018 Q1"
    },
    {
        "x": "2018 Q2",
        "y": "620",
        "z": "2018 Q2"
    },
    {
        "x": "2018 Q3",
        "y": "750",
        "z": "2018 Q3"
    }
]
//  reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

class AreachartContainer extends React.Component {
    constructor(props, context) {
        super(props, context);
        var d = new Date();
        var cid = d.getTime();
        this.state = {
            modalStatus: false,
            viewStatus: false,
            showColorPicker: false,
            background: 'green',
            CollectionData: [],
            payload: {
                chartTitle: "Title",
                subTitle: "subtitle",
                subTitleEnabled: false,
                multiareachart: graphData,
                cardvalue: 0,
                collection: "",
                bordercolor: "",
                fields: "",
                aggregation: '',
                graph: false,
                x: "",
                y: "",
                z: "",
                xstroke: "",
                ystroke: "",
                labelx: "",
                labely: "",
                zstroke: "red",
                labelchartTitle: "",
                transparancy: ''

            },
            multiareachart: [],
            tabledata: [
                {
                    id: "item-1",
                    label: "X Axis",
                    field: "",
                    areaLabel: 'Area Color',
                    color: "#cc332b"
                },
                {
                    id: "item-2",
                    label: "Y Axis",
                    field: "",
                    areaLabel: 'Bar Color',
                    color: "#cc332b"
                }

            ],
            items: [

                {
                    id: "item-1",
                    label: "X Axis",
                    field: "",
                    areaLabel: 'Area Color',
                    color: "#cc332b"
                },
                {
                    id: "item-2",
                    label: "Y Axis",
                    field: "",
                    areaLabel: 'Bar Color',
                    color: "#cc332b"
                }


            ],
            whereCondition: [
                {
                    id: "item-1",
                    label: "",
                    field: "",
                    conditions: "",
                    min: '',
                    max: '',
                    from: '',
                    to: '',
                    text: "",
                    type: ""
                },
            ],
            globalFilter: [
                {
                    id: "item-1",
                    label: "",
                    field: "",
                    conditions: "",
                    min: '',
                    max: '',
                    from: '',
                    to: '',
                    text: "",
                    type: ""
                },
            ],
            ownFilterList: [
                {
                    id: "item-1",
                    label: "",
                    field: "",
                    conditions: "",
                    min: '',
                    max: '',
                    from: '',
                    to: '',
                    text: "",
                    type: ""
                },
            ],
            showlistField: [],
            condition: [{
                field: "",
                type: "",
                sort: ""
            }],
            serColor: [{
                id: "item-1",
                label: "item 1",
                field: "",
                color: "#cc332b"
            }],
            cid: cid,
            enableyaxis: false,
            legend: false,
            alldata: false,
            unit: '',
            limit: '100',
            colorpickerStatus: false,
            axisData: [],
            ownFilter: false,
            relFilCnt: 0,
            relFltr: false,
            globalFilterComp: [],
            selectedGlobalFilters: []
        }
    }

    componentDidMount() {
        let ownid = "bar" + Math.round(new Date().getTime() + (Math.random() * 100));
        this.setState({
            ownid: ownid,
            payload: this.props.payload,
            globalFilterComp: this.props.globalFilters.globalFilterComp
        })
        this.graphData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterCnt.date !== this.props.filterCnt.date) {
            if (this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") {
                if (this.props.filterCnt.filterType !== 'globalFilter') {
                    if (this.props.filterCnt.filterName === this.state.ownid) {
                        this.graphData();
                    }

                } else {

                    this.graphData()
                }
            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.filterCnt.select !== this.props.filterCnt.select) {
            if (this.props.filterCnt.filterType !== 'globalFilter') {
                if (this.props.filterCnt.filterName === this.state.ownid) {
                    this.graphData();
                }

            } else {
                this.graphData()

            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.globalFilterCnt.select !== this.props.globalFilterCnt.select) {
            if (this.state.selectedGlobalFilters.length > 0) {
                this.graphData();
            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.globalFilterCnt.date.from !== this.props.globalFilterCnt.date.from || prevProps.globalFilterCnt.date.to !== this.props.globalFilterCnt.date.to) {
            if (this.state.selectedGlobalFilters.length > 0) {
                this.graphData();
            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.globalFilterCnt.resetdate !== this.props.globalFilterCnt.resetdate) {
            if (this.state.selectedGlobalFilters.length > 0) {
                this.graphData();
            }
            this.setState({relFltr: false, relFilCnt: 0})
        }
        if (prevProps.globalFilters.globalFilterComp !== this.props.globalFilters.globalFilterComp) {
            this.setState({
                globalFilterComp: this.props.globalFilters.globalFilterComp
            })
        }
    }

    openSetting = () => {
        this.setState({modalStatus: !this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }
    handleChangeComplete = (color, param) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[param] = color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
        //this.setState({ background: color.hex });
    };
    toggleColorPicker = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    }
    handleInputChange = (e) => {
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    borderColorChange = (color, param) => {

        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["bordercolor"] = color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }

    serColorChange = (color, param, label) => {
        var _newState = this.state[label];
        var index = (_newState.findIndex(obj => obj["id"] === param))
        _newState[index]["color"] = color;
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["serColor"] = this.state.serColor
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }

    radioBoxchange = (e) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    graphData() {
        var date = new Date();
        var timestamp = date.getTime();

        var obj = this;
        const collection = this.props.payload && this.props.payload.collection
        const tabledata = this.props.payload && this.props.payload.graph || this.state.items
        const condition = this.props.payload && this.props.payload.condition || this.state.condition
        const whereCondition = this.props.payload && this.props.payload.whereCondition || this.state.whereCondition
        const groupbyEnable = this.props.payload && this.props.payload.groupbyEnable;
        const limit = this.props.payload && this.props.payload.limit || this.state.limit
        const bordercolor = this.props.payload && this.props.payload.bordercolor
        const seriesLabel = this.props.payload && this.props.payload.seriesLabel
        const seriesField = this.props.payload && this.props.payload.seriesField
        const serColor = this.props.payload && this.props.payload.serColor || this.state.serColor;
        const globalFilter = this.props.payload && this.props.payload.globalFilter || this.state.globalFilter
        const selectFilter = this.state.selectFilter || this.props.payload && this.props.payload.selectFilter
        const filterName = this.state.filterName || this.props.payload && this.props.payload.filterName
        const ownFilterList = this.props.payload && this.props.payload.ownFilterList || this.state.ownFilterList;

        let selectedGlobalFilters = [];
        if (globalFilter.length > 0) {
            console.log(globalFilter)
            selectedGlobalFilters = globalFilter.filter(item => item.collection && item.collection !== '' && item.field && item.field !== '').map(fil => {
                return fil.id;
            })
            selectedGlobalFilters = [].concat.apply([], selectedGlobalFilters);
        }

        //console.log(tabledata)
        this.setState({
            ownFilterList: ownFilterList,
            tabledata: tabledata,
            serColor: serColor,
            items: tabledata,
            condition: condition,
            whereCondition: whereCondition,
            limit: limit,
            globalFilter: globalFilter,
            filterName: filterName,
            selectFilter: selectFilter,
            selectedGlobalFilters: selectedGlobalFilters
        })
        if (collection !== '' && collection !== undefined && tabledata !== undefined) {

            /**************** where condition and group by ***************/

            let groupby = '';
            let groupbyLabel = "";
            let groupdatewithtime = "";
            let components = tabledata;
            if (tabledata.length > 0) {
                if (groupbyEnable) {
                    groupbyLabel = tabledata[0].label;
                    groupby = tabledata[0].field;
                    groupdatewithtime = tabledata[0].datewithtime;
                    components = tabledata.slice(1, tabledata.length);
                }
            }
            let filters = [];
            let numberfilters = [];
            let datefilters = [];
            let globalFilters = [];

            if (whereCondition.length > 0) {
                for (var i = 0; i < whereCondition.length; i++) {
                    let conditionEntry = whereCondition[i];
                    if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                        let conditionValue = '';
                        if (conditionEntry.type === 'text') {
                            conditionValue = conditionEntry.text;
                        } else if (conditionEntry.type === 'conditions') {
                            conditionValue = conditionEntry.conditions;
                        }
                        let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];

                        if (conditionEntry.type === 'datepicker') {
                            let datefilter = {
                                "id": conditionEntry.field,
                                "from": conditionEntry.from,
                                "to": conditionEntry.to
                            }
                            datefilters.push(datefilter)
                        } else if (conditionEntry.type === 'number') {
                            let numberfilter = {
                                "id": conditionEntry.field,
                                "min": conditionEntry.min,
                                "max": conditionEntry.max
                            }
                            numberfilters.push(numberfilter)
                        } else {
                            if (conditionArr.length > 0) {
                                let filter = {
                                    "id": conditionEntry.field,
                                    "value": conditionArr
                                }
                                filters.push(filter);
                            }

                        }

                    }

                }
            }
            // if ((this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") || this.props.filterCnt.select !== "") {
            //     if (globalFilter.filter(list => list.text === this.props.filterCnt.filterName).length > 0) {
            //         for (var i = 0; i < globalFilter.length; i++) {
            //             let conditionEntry = globalFilter[i];
            //             if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
            //                 let conditionValue = '';
            //                 if (conditionEntry.type === 'text') {
            //                     conditionValue = conditionEntry.text;
            //                 } else if (conditionEntry.type === 'conditions') {
            //                     conditionValue = conditionEntry.conditions;
            //                 }
            //                 let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];
            //                 if (conditionEntry.type === 'datepicker' && this.props.filterCnt.date.to !== "") {
            //                     let datefilter = {
            //                         "id": conditionEntry.field,
            //                         "from": this.props.filterCnt.date.from,
            //                         "to": this.props.filterCnt.date.to
            //                     }
            //                     datefilters.push(datefilter)
            //                 } else if (conditionEntry.type === 'number') {
            //                     let numberfilter = {
            //                         "id": conditionEntry.field,
            //                         "min": conditionEntry.min,
            //                         "max": conditionEntry.max
            //                     }
            //                     numberfilters.push(numberfilter)
            //                 } else {
            //                     if (this.props.filterCnt.select !== "") {
            //                         let filter = {
            //                             "id": conditionEntry.field,
            //                             "value": [this.props.filterCnt.select]
            //                         }
            //                         filters.push(filter);
            //                     }
            //
            //                 }
            //
            //             }
            //             relFilCnt = this.state.relFilCnt + 1
            //         }
            //     }
            // }
            if (this.state.ownid === this.props.filterCnt.filterName) {
                for (var i = 0; i < ownFilterList.length; i++) {
                    let conditionEntry = ownFilterList[i];
                    if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                        let conditionValue = '';
                        if (conditionEntry.type === 'text') {
                            conditionValue = conditionEntry.text;
                        } else if (conditionEntry.type === 'conditions') {
                            conditionValue = conditionEntry.conditions;
                        }
                        let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];
                        if (conditionEntry.type === 'datepicker' && this.props.filterCnt.date.to !== "") {
                            let datefilter = {
                                "id": conditionEntry.field,
                                "from": this.props.filterCnt.date.from,
                                "to": this.props.filterCnt.date.to
                            }
                            datefilters.push(datefilter)
                        } else if (conditionEntry.type === 'number') {
                            let numberfilter = {
                                "id": conditionEntry.field,
                                "min": conditionEntry.min,
                                "max": conditionEntry.max
                            }
                            numberfilters.push(numberfilter)
                        } else {
                            if (this.props.filterCnt.select !== "") {
                                let filter = {
                                    "id": conditionEntry.field,
                                    "value": [this.props.filterCnt.select]
                                }
                                filters.push(filter);
                            }

                        }

                    }
                    var relFilCnt = this.state.relFilCnt + 1
                }
                relFilCnt = this.state.relFilCnt + 1

            }

            if (selectedGlobalFilters.length > 0) {
                console.log(globalFilter)
                for (var i = 0; i < selectedGlobalFilters.length; i++) {
                    let gFilterVal = selectedGlobalFilters[i];
                    let gFilterArr = globalFilter.filter(gf => gf.id === gFilterVal);
                    if (gFilterArr.length > 0 && gFilterArr[0].collection && gFilterArr[0].collection !== '' && this.props.globalFilterCnt.select && this.props.globalFilterCnt.select !== '') {

                        globalFilters.push({
                            "type": "select",
                            "collectionid": gFilterArr[0].collection,
                            "recordid": this.props.globalFilterCnt.select
                        });
                        relFilCnt = this.state.relFilCnt + 1
                    }

                    if (gFilterArr.length > 0 && gFilterArr[0].type === 'DATE' && (this.props.globalFilterCnt.date.from !== '' || this.props.globalFilterCnt.date.to !== '')) {
                        console.log(gFilterArr[0])
                        console.log("selectFilter" + gFilterArr[0].field)
                        globalFilters.push({
                            "type": "date",
                            "from": this.props.globalFilterCnt.date.from,
                            "to": this.props.globalFilterCnt.date.to,
                            "field": gFilterArr[0].field
                        });
                        relFilCnt = this.state.relFilCnt + 1

                    }
                }
            }


            let sortCondition = [];
            if (condition.length > 0) {
                for (var j = 0; j < condition.length; j++) {
                    if (condition[j].field && condition[j].field !== '' && condition[j].sort && condition[j].sort !== ''
                        && condition[j].type && condition[j].type !== '') {
                        sortCondition.push(condition[j]);
                    }
                }
            }
            let series = [];
            if (seriesLabel && seriesLabel !== '' && seriesField && seriesField !== '') {
                let aggFunction = '';
                let aggField = '';
                if (components.length > 0) {
                    aggFunction = components[0].aggs;
                    aggField = components[0].field;
                }
                series = [
                    {
                        "id": seriesField,
                        "label": seriesLabel,
                        "aggs": [
                            {
                                "label": seriesLabel,
                                "field": aggField,
                                "aggs": aggFunction
                            }
                        ]
                    }
                ];

            }
            /*series =[
              {
                "id":"1536163300",
                "label":"user"
              }
            ]*/


            let data = {
                collectionid: collection,
                "index": ES_INDEX,
                "graph": true,
                "fieldid": "0",
                "components": components,
                "condition": sortCondition,
                "filters": filters,
                "numberfilters": numberfilters,
                "datefilters": datefilters,
                "globalFilters": globalFilters,
                "series": series,
                "size": limit,
                "linkedid": this.props.linkedId.param
            }
            if (groupbyEnable) {
                data.groupbyEnable = groupbyEnable;
                data.groupby = groupby;
                data.groupbyLabel = groupbyLabel;
                data.groupdatewithtime = groupdatewithtime;
            }
            /******************* Where condition and group by end*************/

            /*let data = {
              collectionid: collection,
              "index": ES_INDEX,
              "graph": true,
              "fieldid": "0",
              "components": tabledata,
              "condition":condition
            }*/
            if ((relFilCnt !== 0 && this.state.relFltr) || !this.state.relFltr) {
                axios.post(API_PATH + "project/datacard", data)
                    .then(function (res) {
                        var carddata = res.data.results.data;
                        let graphData = [];
                        let axisData = [];
                        let areadata = [];

                        let labelarray = [];
                        if (carddata.graph) {
                            areadata = carddata.graph.map(function (item, index) {
                                //console.log(carddata.graph)
                                let firstKey = Object.keys(item)[0];
                                item[firstKey] = isValidDate(item[firstKey]);
                                graphData[index] = {[firstKey]: item[firstKey]};
                                if (seriesLabel && seriesLabel !== '' && seriesField && seriesField !== '') {

                                    let seriesData = {};
                                    let itemArr = item[seriesLabel];
                                    /*for (var key in itemArr) {
                                      if (itemArr.hasOwnProperty(key)) {
                                        seriesData[key] = itemArr[key][seriesLabel];
                                      }
                                    }*/
                                    //console.log(itemArr)
                                    for (var key in itemArr) {
                                        if (itemArr.hasOwnProperty(key)) {
                                            //console.log(seriesLabel)
                                            seriesData[key] = itemArr[key][seriesLabel];
                                        }
                                    }
                                    Object.assign(graphData[index], seriesData);
                                    if (index === 0) {
                                        labelarray = Object.keys(item[seriesLabel]);
                                        let colorsLength = serColor.length;
                                        for (var i = 0; i < labelarray.length; i++) {
                                            axisData.push({
                                                label: labelarray[i],
                                                color: serColor[i % colorsLength].color
                                            })
                                        }
                                    }
                                } else {
                                    for (var key in item) {
                                        if (item.hasOwnProperty(key) && key !== firstKey) {
                                            item[key] = parseInt(item[key]);
                                        }
                                    }
                                }


                                return item;
                            }, graphData);

                        }
                        //console.log(graphData)
                        //console.log(axisData)

                        var d = new Date();
                        var cid = d.getTime();
                        let multiareadata = (seriesLabel && seriesLabel !== '' && seriesField && seriesField !== '') ? graphData : areadata;
                        obj.setState({multiareachart: multiareadata, cid: cid, axisData: axisData});
                        return res;

                    }).catch(function (err) {

                    return err;
                });
            }
        }


        if ((collection !== '' && collection !== undefined)) {
            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + collection + "&index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                //////console.log(res.data.data)
                obj.setState({showlistField: res.data.data});
                return res;

            }).catch(function (err) {
                ////////console.log("error");
                ////////console.log(err);
                return err;
            });
            this.setState({cId: collection})

        }


        if (collection !== '' || collection !== undefined) {
            axios({
                method: "GET",
                url: API_PATH + "collection/" + collection + "/collectedinfo?index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                ////////console.log(res.data.results)
                var carddata = res.data.results.data[0];
                //////console.log(carddata)
                obj.setState({carddata: carddata});
                return res;
            }).catch(function (err) {
                return err;
            });
        } else {
            obj.setState({multiareachart: graphData});
        }
    }

    handleChange = (e) => {
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    chartSaving = () => {
        var prjctdtlid = atob(getUrlSegment(4));
        let sharetype = getUrlSegment(1);
        let uid = '';
        if (sharetype === 'public') {
            uid = 2;
        } else {
            uid = getProfile().id;
        }
        let param = {
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard": state.getStorableState()
            ,
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus: false})
    }
    /*  drag and drop functions for hierarchial table */
    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );
        this.setState({
            items
        });
    };
    // addData=()=>{
    //
    //   let data=this.state.items
    //   let counter=data.length+1
    //   let newData={
    //     id: "item-"+new Date().getTime(),
    //     label: "item "
    //   }
    //   let _data=data.concat(newData)
    //   this.setState({items:_data})
    //   const { id, dropzoneID, parentID }= this.props;
    //   let payload=this.state.payload
    //   payload["graph"]=_data
    //   this.setState({payload}, () => {
    //     state.updateElement(id, dropzoneID, parentID, {
    //       payload: payload
    //     });
    //   });
    //
    // }


    addData = (Id, updateParam) => {
        let data = this.state[updateParam]
        let newData = {
            id: "item-" + new Date().getTime(),
            label: "item "
        }
        let _data = data.concat(newData)
        this.setState({[updateParam]: _data})
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        ////console.log(payload)
        payload[updateParam] = _data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }

    removeData = (dragid, updateParam) => {
        let data = this.state[updateParam]
        let _data = data.filter(item => item.id !== dragid)
        this.setState({[updateParam]: _data})
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        //console.log(payload)
        payload[updateParam] = _data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }


    // removeData=(dragid)=>{
    //   let data=this.state.items
    //   let _data=data.filter(item=>item.id !== dragid)
    //   this.setState({items:_data})
    //   const { id, dropzoneID, parentID }= this.props;
    //   let payload=this.state.payload
    //   payload["graph"]=_data
    //   this.setState({payload}, () => {
    //     state.updateElement(id, dropzoneID, parentID, {
    //       payload: payload
    //     });
    //   });
    //
    // }
    handleLabelChange = (e, dragid, whereCondition, type) => {
        var updatedParam = "items";
        if (whereCondition === "graph") {
            updatedParam = "items";
        } else {
            updatedParam = whereCondition;
        }

        var _newState = this.state[updatedParam];
        var index = (_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name] = e.target.value;
        if (whereCondition === "whereCondition" && type !== "field") {
            _newState[index]["type"] = type;
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[whereCondition] = this.state[updatedParam]
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }

    handleColorChange = (color, param) => {
        var _newState = this.state.items;
        var index = (_newState.findIndex(obj => obj["id"] === param))
        _newState[index]["color"] = color;
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["graph"] = this.state.items
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    handleClick = (status) => {
        this.setState({colorpickerStatus: status})
    }
    handleConditionChange = (e) => {
        var _newState = this.state.condition;
        if (e.target.name === 'field') {
            let t = e.target.value.split("|");
            _newState[0]["field"] = t[0];
            _newState[0]["type"] = t[1] === "datepicker" ? "date" : t[1];
        } else {
            _newState[0][e.target.name] = e.target.value;
        }


        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["condition"] = this.state.condition
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });


    }
    checkBoxchange = (e) => {
        let val;
        if (e.target.value === "false") {
            val = true
        } else {
            val = false
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    globalFilterchange = (e) => {
        let val = e.target.value;

        let globalFilter = this.state.globalFilter || [];
        let selectedGlobalFilters = this.state.selectedGlobalFilters;
        let globalFilterComp = this.state.globalFilterComp;

        if (selectedGlobalFilters.includes(val)) {
            var index = selectedGlobalFilters.indexOf(val);
            selectedGlobalFilters.splice(index, 1)
            globalFilter.splice(index, 1)
        } else {
            selectedGlobalFilters.push(val);
            globalFilterComp = globalFilterComp.filter(comp => comp.id === val);

            if (globalFilterComp.length > 0) {
                if (globalFilterComp[0].type === "DATE_FILTER_CONTAINER") {
                    globalFilter.push({
                        "type": "DATE",
                        "id": globalFilterComp[0].id,
                        "from": this.props.globalFilterCnt.date.from,
                        "to": this.props.globalFilterCnt.date.to,
                        "field": globalFilterComp[0].payload.selectFilter
                    })
                } else {
                    globalFilter.push({
                        "type": "SELECT",
                        "id": globalFilterComp[0].id,
                        "collection": globalFilterComp[0].payload.collection,
                        "field": globalFilterComp[0].payload.selectFilter
                    })
                }

            }
        }

        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload['globalFilter'] = globalFilter;
        //delete payload['[object Object]'];

        this.setState({
            selectedGlobalFilters: selectedGlobalFilters,
            globalFilter: globalFilter
        })
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        }, function () {
            this.graphData()
        });
    }


    render() {
        const {
            id,
            showBasicContent, showPreview,
            dropzoneID, payload, parentID, name
        } = this.props;
        let sourceType = (payload && payload.sourceType) || false
        let showlistField = []
        let sourceData = []
        if (this.state.showlistField.length !== 0) {
            for (let i = 0; i < this.state.showlistField.pages.length; i++) {
                (this.state.showlistField.pages[i].components).map(list => {
                    showlistField.push(list)
                })
            }
        }
        //console.log(sourceType)
        if (sourceType.toString() === "true") {
            sourceData = this.props.listAllCollections.ListAllCollections.data
        } else {
            sourceData = this.props.EntityData.Entity.data
        }
        let containerData = {
            chartTitle: this.state.chartTitle || payload && payload.chartTitle,
            subTitle: this.state.subTitle || payload && payload.subTitle,
            subTitleEnabled: this.state.subTitleEnabled || payload && payload.subTitleEnabled,
            data: this.state.multiareachart || [],
            collection: this.state.collection || payload && payload.collection,
            tabledata: this.state.items || payload && payload.graph,
            enableyaxis: this.state.enableyaxis || payload && payload.enableyaxis || false,
            legend: this.state.legend || payload && payload.legend || false,
            alldata: this.state.alldata || payload && payload.alldata || false,
            unit: this.state.unit || payload && payload.unit,
            xwidth: this.state.xwidth || payload && payload.xwidth,
            xheight: this.state.xheight || payload && payload.xheight,
            yheight: this.state.yheight || payload && payload.yheight,
            ywidth: this.state.ywidth || payload && payload.ywidth,
            graphHeight: this.state.graphHeight || payload && payload.graphHeight,
            xAngle: this.state.xAngle || payload && payload.xAngle,
            yAngle: this.state.yAngle || payload && payload.yAngle,
            enablegradient: this.state.enablegradient || payload && payload.enablegradient || false,
            hidearea: this.state.hidearea || payload && payload.hidearea || false,
            condition: this.props.payload && this.props.payload.condition || this.state.condition,
            showlistField: showlistField,
            transparancy: this.state.transparancy || payload && payload.transparancy,
            collectionData: sourceData,
            groupby: this.state.groupby || payload && payload.groupby,
            groupbyEnable: this.state.groupbyEnable || payload && payload.groupbyEnable,
            Aggregate: this.state.Aggregate || payload && payload.Aggregate,
            whereCondition: this.state.whereCondition || payload && payload.whereCondition,
            limit: this.state.limit || payload && payload.limit,
            bordercolor: (this.props.payload && this.props.payload.bordercolor) || "#fff",
            sourceType: sourceType,
            seriesLabel: this.state.seriesLabel || payload && payload.seriesLabel,
            seriesField: this.state.seriesField || payload && payload.seriesField,
            serColor: this.state.serColor || payload && payload.serColor,
            axisData: this.state.axisData,
            globalFilter: this.state.globalFilter || payload && payload.globalFilter,
            ownFilter: this.state.ownFilter || payload && payload.ownFilter,
            ownFilterList: this.state.ownFilterList || payload && payload.ownFilterList,
            globalFilterComp: this.state.globalFilterComp || [],
            selectedGlobalFilters: this.state.selectedGlobalFilters || []

        }


        if (showBasicContent) {
            return (
                <Draggable {...this.props} >
                    {
                        <span className="drag-icon">{name}</span>

                    }
                </Draggable>
            )
        }

        if (showPreview) {
            return (

                <ViewData
                    containerData={containerData}
                    viewStatus={this.state.viewStatus}
                    settingStatus={this.settingStatus}
                    cid={this.state.cId}
                    state={this.state}
                    linkedId={this.props.linkedId.param}
                    key={`result-table`}
                    chart={<AreaLineChart
                        containerData={containerData}
                    />}
                />


            );
        }
        return (
            <React.Fragment>
                <Draggable {...this.props} draggable={!this.props.DragDisabled.dragStatus}>
                    <SettingsPanel
                        id={id}
                        name={containerData.chartTitle ? containerData.chartTitle : name}
                        dropzoneID={dropzoneID}
                        parentID={parentID}
                        openSetting={this.openSetting}
                        removeElement={<div className="delete-icon"
                                            onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
                    />
                </Draggable>
                {this.state.modalStatus &&
                <SettingsModal
                    title={`Area Chart  -   Settings`}
                    containerData={containerData}
                    colorpickerStatus={this.state.colorpickerStatus}
                    repeater={false}
                    modaltype={8}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    handleChange={this.handleChange}
                    checkBoxchange={this.checkBoxchange}
                    radioBoxchange={this.radioBoxchange}
                    handleConditionChange={this.handleConditionChange}
                    handleLabelChange={this.handleLabelChange}
                    handleColorChange={this.handleColorChange}
                    handleInputChange={this.handleInputChange}
                    borderColorChange={this.borderColorChange}
                    serColorChange={this.serColorChange}
                    handleClick={this.handleClick}
                    globalFilterchange={this.globalFilterchange}
                    addData={this.addData}
                    removeData={this.removeData}
                    onDragEnd={this.onDragEnd}
                />

                }


            </React.Fragment>
        )
    }
};


const mapStateToProps = state => ({
    showFormModal: state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange: state.DynamicDashboard.getDashboard,
    DragDisabled: state.DynamicDashboard.getDragDisabled,
    EntityData: state.EntityReducer.GetEntity,
    filterCnt: state.DynamicDashboard.getFilterCnt,
    linkedId: state.EntityReducer.LinkedId,
    globalFilterCnt: state.DynamicDashboard.getGlobalFilterCnt,
    globalFilters: state.DynamicDashboard.getGlobalFilters
});
export default withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform: collectionActions.showform,
    chartSaving: DynamicDashboard.saveDashboarddata,
    dragStatus: DynamicDashboard.dragStatus,

})(AreachartContainer));
