import React, {Component} from 'react';
import {Dropdown} from "react-bootstrap";
import {Scrollbars} from "react-custom-scrollbars";


class AdvancedSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            advancedSearch : [],
            dropdownOpen:false,
            searchData: {}
        }
        this.submitSearch = this.submitSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);

    }


    handleSearch(e){
        let searchData = this.state.searchData;
        searchData[e.target.id] = e.target.value;
        this.setState({searchData: searchData});
        var currentIndex = '';
        if(this.state.advancedSearch.filter(item1 => parseInt(item1.id) === parseInt(e.target.id)).length > 0){
            currentIndex = this.state.advancedSearch.findIndex(obj => obj.id === e.target.id);
            this.state.advancedSearch.splice(currentIndex, 1);
        }
        this.state.advancedSearch.push({"id" : e.target.id, "value" : e.target.value });

        if(!e.target.value){

            if(this.state.advancedSearch.filter(item1 => parseInt(item1.id) === parseInt(e.target.id)).length > 0){
                currentIndex = this.state.advancedSearch.findIndex(obj => obj.id === e.target.id);
                this.state.advancedSearch.splice(currentIndex, 1);
            }
        }

    }
    submitSearch(){
        this.props.submitAdvncSearch(this.state.advancedSearch);
    }
    clearSearch() {
        let searchData = this.state.searchData;
        Object.keys(searchData).forEach((k) => searchData[k] = '');
        this.setState({searchData: searchData});
        this.props.submitAdvncSearch([]);

    }
    render() {
        let searchData = this.props.searchData ? this.props.searchData : [];
        return (

            <div className="adv-search-block">
                <Dropdown >
                    <Dropdown.Toggle className="ico-lib adv-search-ico" id="dropdown-basic"/>
                    <Dropdown.Menu >
                        <div className="adv-search-body">
                            <Scrollbars autoHide autoHideTimeout={1000}>
                                <div className="adv-search-body-inner">
                                    <p>Hint: Use &lt;space&gt;@ symbol to search second word onwards. Fields are case sensitive</p>
                                    {searchData.map((item,index) =>
                                        <div className="each-adv-search">
                                            <label>{item.display_label}</label>
                                            <div className="each-inp-box">
                                                <input type="text"  id={item.masterid} onChange={(e) => this.handleSearch(e)} value={this.state.searchData[item.masterid]}/>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </Scrollbars>
                        </div>

                        <div className="adv-search-footer">
                            {/*
                            <button className="general-btn grey" onClick={() => this.toggle()}>cancel</button>
*/}
                            <button className="general-btn grey" onClick={() => this.clearSearch()}>Clear</button>
                            <button className="general-btn" onClick={() => this.submitSearch()}>Search</button>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );


    }
}
export default AdvancedSearch;
