import React, {Component} from 'react';
import {Card,Tooltip,OverlayTrigger, Button} from "react-bootstrap"
class FullPageModal extends Component {

    constructor(props) {
        super(props);
        this.state={
            Close:false,


        }
      }




    render() {

//////console.log(this.props.title)

        return (

            <div className={`modal show full-width template-4 usr-profile fade ${this.props.extraClass}`} id="collectionModal"
                 style={{display: "block"}}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header-left">
                                <h5>{this.props.title}</h5>
                                {this.props.subTitleEnabled &&
                                <p>{this.props.subTitle}</p>
                                }
                            </div>
                            {this.props.otherActionClass &&
                            <button className={this.props.otherActionClass}
                                    onClick={() => this.props.otherAction()}></button>
                            }
                            {this.props.hasOwnProperty("Close")&&
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                    onClick={() => this.props.Close()}>

                            </button>
                            }
                        </div>
                        <div className="modal-body">
                            {this.props.children}

                        </div>



                    </div>
                </div>
            </div>



        );
    }
}



export default FullPageModal;


