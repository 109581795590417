import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import LoaderSvg from "../LoaderSVG";
import LineChartCard from "../General/LineChartCard";
import axios from "axios";
import {API_PATH} from "../../../constants";

/*const linedata = [
    {
        "name": "Q1 2008",
        "ratio": 13
    },
    {
        "name": "Q2 2008",
        "ratio": 50
    },
    {
        "name": "Q3 2008",
        "ratio": 100
    },
    {
        "name": "Q4 2008",
        "ratio": 45
    },
    {
        "name": "Q1 2009",
        "ratio": 78
    },
    {
        "name": "Q2 2009",
        "ratio": 20
    },
    {
        "name": "Q3 2009",
        "ratio": 5
    },
    {
        "name": "Q4 2009",
        "ratio": 37
    },
    {
        "name": "Q1 2010",
        "ratio": 10
    }
]*/
class ImpactSnapshotCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linedata : []
            //loading : 0
        }
    }

    componentDidMount() {
        // this.setState({loading: 1});
        var date = new Date();
        var timestamp = date.getTime();

        var obj = this;

        // Send an HTTP  request
        axios({
            method: "GET",
            url :"https://apir4dev.bsynapse.com/project/datacard?collectionid=2_964597778248&fieldid=322236917%2C322236917&index=ibtstaging&graph=true&x=1306208660%2C1018507210&y=322236917&z=1306208660%2C1018507210",
            async:false,
            headers : {'Content-Type' : 'application/json'}
        }).then(function(res){
            var carddata = res.data.results.data;
            var aggregatevalue = carddata.aggregation;
            obj.setState({linedata : carddata.graph, cardvalue : aggregatevalue[0].sum});
            return res;


        }).catch(function(err){
            //console.log("error");
            //console.log(err);
            return err;
        });
    }

    render() {
        return (
            <div className="ind-small-tab">
                <div className="small-tab-header">
                    <h5>{this.props.title}</h5>
                </div>
                <div className="small-tab-footer">
                    <h5>{this.state.cardvalue}</h5>
                    <div className="small-tab-graph">
                        <LineChartCard data={this.state.linedata}/>
                    </div>
                </div>
            </div>
        )
    }

}

export default ImpactSnapshotCard;