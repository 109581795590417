 import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
 import PositionDetail from "./PositionDetail";
 import LoaderSvg from "../views/LoaderSVG";
class Milestone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList : []
        }
    }
    componentDidMount() {
        this.setState({
            dataList : this.props.dataList ? this.props.dataList.slice(0,5) : this.state.dataList
        });
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.dataList!==prevState.dataList){
            return { dataList: nextProps.dataList};
        }
        else return null;
    }
    render() {
        return(
            <div className="content-left lg-tab md-ls-tab">
                {this.props.multiLoader.loader['dashboard-userstat'] && this.props.multiLoader.loader['dashboard-userstat'] === true &&
                <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                }
                <div className="content-bl-header">
                    <h5>Upcoming Milestones</h5>
                    {/*{this.state.dataList.length > 0 &&
                        <a href="javascript:void(0);" className="expand-click">View All</a>
                    }*/}
                </div>
                <div className="static-mode-block">
                    <div className="scrollable">

                        <table className="table dash-tab" width="100%">
                            {this.state.dataList.length > 0 ?
                                <>
                            <thead>
                            <tr>
                                <th scope="col">Milestone</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.dataList.map((val, index) =>
                                <PositionDetail key={index} dataList={val}/>
                            )}
                            </tbody>
                                </>
                                :
                                <tr><td><p className="task-text-small">No milestones found</p></td></tr>
                            }
                        </table>



                    </div>
                </div>
            </div>
        );
    }
}

export default Milestone;
