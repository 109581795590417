import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal";
import AddNewVertical from "./AddNewVertical";
import * as verticalActions from "../../../actions/Program";
import * as usersActions from "../../../actions/Users";
import NoData from "../../views/NoData";
import moment from 'moment';
import VerticalTile from "../../views/VerticalTile";
import AlertModal from "../../views/AlertModal";
import * as portfolioActions from "../../../actions/Portfolio";
class Vertical extends Component {
    constructor(props) {
        super(props);
        this.state={
            Addopen:false,
            verticalId: '0',
            loading: 0,
            offset: 30,
            lastOffset: 0,
            search:'',
            clearList : false,
            VerticalList:[],
            Delopen:false,
            verticaldeleteid:0

        }
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.getVerticalList = this.getVerticalList.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);
        this.DelmodalOpen = this.DelmodalOpen.bind(this);
        this.deleteVertical = this.deleteVertical.bind(this);
        this.cancelDeleteModal=this.cancelDeleteModal.bind(this);
    }
    componentDidMount() {
        this.getVerticalList();
        var user_data = { uid : getProfile().id, usertype : getProfile().usertype , srchval : '' };
        this.props.users(user_data);
        this.props.mainClick('vertical','');
        const container = document.querySelector('.project-container');
        container.addEventListener('scroll', this.listenToScroll)

    }

    componentWillUnmount() {
        const container = document.querySelector('.project-container');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.project-container')
        let lastOffset = document.querySelector('.ind-project-list').childElementCount;
        //console.log(lastOffset)
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;

        //console.log(scrollTop+"----"+scrollHeight+"----"+offsetHeight+"-----"+contentHeight);

        if (contentHeight <= scrollTop) {
            this.setState({loading : 1});
            let lastOffset = this.state.lastOffset + 1;
            this.setState({lastOffset : lastOffset,clearList:false},
                function(){
                    var params  = {
                        uid :  getProfile().id,
                        usertype : getProfile().usertype,
                        search: this.state.search,
                        page : this.state.lastOffset + 1,
                        per_page: this.state.offset
                    }
                    this.props.updateprogramlist(params);
                })

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.listAllProgramData.programs !== this.props.listAllProgramData.programs)
        {
            this.setState({
                VerticalList: this.props.listAllProgramData.programs.data,
                loading: 0
            });
            this.AddmodalClose();
        }
        if ((prevProps.listAllProgramData.searchValue !== this.props.listAllProgramData.searchValue && (this.props.listAllProgramData.searchValue!== '')) || (prevProps.listAllProgramData.searchResult !== this.props.listAllProgramData.searchResult )) {
            this.setState({
                VerticalList: this.props.listAllProgramData.searchResult.data,
            });
        }
        else if ((prevProps.listAllProgramData.searchValue !== this.props.listAllProgramData.searchValue && ( this.props.listAllProgramData.searchValue === '') ) || (prevProps.listAllProgramData.programs !== this.props.listAllProgramData.programs)) {
            this.setState({
                VerticalList: this.props.listAllProgramData.programs.data,
                loading: 0
            });

        }
        if(prevProps.listAllProgramData !== this.props.listAllProgramData){
            this.setState({
                VerticalList: this.props.listAllProgramData.programs.data,
                loading: 0
            });
        }
        if(prevProps.deleteProgramdata.programDeleted !== this.props.deleteProgramdata.programDeleted){
            var beta = this;
            setTimeout(function () {
                beta.getVerticalList();
            }, 1000)
            this.setState({Delopen:false})
        }
    }
    AddmodalOpen(e, pid){
        e.stopPropagation();
        if(pid){
            this.setState({verticalId : pid});
            var programParams  = {
                uid :  getProfile().id,
                usertype : getProfile().usertype,
                programId : pid
            }
            this.props.getprogramdetails(programParams);
        }
        this.setState({Addopen : true,});
    }

    AddmodalClose(){

        this.setState({Addopen : false , verticalId :'0'});
    }
    getVerticalList() {
        var programParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: this.state.search,
            page : this.state.lastOffset + 1,
            per_page: this.state.offset
        }
        this.props.program(programParams);

    }
    deleteVertical(){
        this.setState({loading : 1})
        let verticaldeleteid = this.state.verticaldeleteid;

        let deletedddata = {projectid : verticaldeleteid};
        this.props.deleteVertical(deletedddata);

        //this.setState({Delopen : false});
    }
    cancelDeleteModal(){
        this.setState({Delopen : false});

    }

    DelmodalOpen(e, vid){
        e.stopPropagation();
        this.setState({
            Delopen : true ,
            verticaldeleteid : vid
        });
    }
    render() {
        let Program=this.state.VerticalList;
        return (
            <div className="form-detail-container data-view field-form">
                {(this.props.listAllProgramData.loading  || this.state.loading === 1) &&
                <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                }
                <div className="project-container">
                    {Program.length === 0 &&
                    <div className='absolute-holder'>
                        <NoData
                            msg={`No Verticals added yet`}

                        />
                    </div>
                    }
                    {Program.map((list,index) => {
                            let description = atob(list.description);
                            var endDate = moment(list.enddate).utc().local();
                            return(
                                <VerticalTile
                                    key={list.project_id}
                                    list={list}
                                    history={this.props.history}
                                    endDate={endDate}
                                    AddmodalOpen={this.AddmodalOpen}
                                    DelmodalOpen={this.DelmodalOpen}
                                />
                            )
                        }
                    )}
                </div>
                <div className={'float-button-box'} onClick={(e)=>this.AddmodalOpen(e, 0)}>
                    <span className={'icon-in icon-plus'}></span>
                </div>
                {this.state.Addopen &&
                <Modal title={(this.state.verticalId === '0' ? 'Create New Vertical' : 'Update Vertical')} Close ={this.AddmodalClose.bind(this)}>
                    <AddNewVertical
                        AddmodalClose = {this.AddmodalClose}
                        action={this.state.verticalId === "0"? "create":"update"}
                    />

                </Modal>
                }
                {this.state.Delopen === true &&
                <AlertModal id="delete-vertical"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_action={() => this.deleteVertical()}
                            showModal = {this.state.Delopen}
                            CANCEL_action={() => this.cancelDeleteModal()}
                />
                }
            </div>
        );
    }
}
const mapStateToProps = state => ({
    listAllProgramData:state.VerticalReducer.listallPrograms,
    SaveVerticalDet : state.VerticalReducer.SaveVertical,
    deleteProgramdata :state.VerticalReducer.DeleteProgram
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    program:verticalActions.listallprograms,
    users:usersActions.listUsers,
    getprogramdetails : verticalActions.getprogramdetails,
    updateprogramlist :verticalActions.updateprogramlist,
    deleteVertical:verticalActions.deleteVertical,
})(Vertical));
