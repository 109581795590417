import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import {withRouter} from 'react-router';

import {Draggable, Dropzone, state} from 'react-page-maker';
import {elements} from '../const';
import {connect} from "react-redux";

const DraggableCardLayout = (props) => {
  // make sure you are passing `parentID` prop to dropzone
  // it help to mainatain the state to meta data
  const {
    dropzoneID,
    parentID,
    showBasicContent,
    showPreview,
    id,
    dropzoneProps,
    initialElements,
    ...rest
  } = props;

  if (showBasicContent) {
    return (
        <Draggable {...props} >
          <span className="drag-icon">{ rest.name }</span>
        </Draggable>
    );
  }

  const _onDrop = (data, cb) => {
    // no need to ask id and name again
    if (data.payload && data.payload.dropped) {
      return cb(data);
    }

    // This can be an async call or some modal to fetch data
    let name = data.name;
    // if (data.type === elements.TEXTBOX || data.type === elements.DROPDOWN) {
    //   name = window.prompt('Enter name of field');
    // }
    let uniqueId=localStorage.getItem('uniqueId')?parseInt(localStorage.getItem('uniqueId')):0
    let newid=uniqueId+1
    const id = "id_"+(newid)
    localStorage.setItem('uniqueId', newid);

    const result = cb({
      ...data,
      name,
      id,
      payload: { dropped: true }
    });
  };

  if (showPreview) {
    return (

        <div className="floating-summary-body">
          <div className="floating-summary-user-block">
            <div className="floating-summary-user-left">
              {rest.childNode['canvas-1-1']}
            </div>
            <div className="floating-summary-user-right">
              {rest.childNode['canvas-1-2']}
            </div>
          </div>
        </div>

    )
  }

  const filterInitialElements = (dID) => {
    return initialElements.filter(e => e.dropzoneID === dID) || [];
  };

  return (
      <Draggable id="grid-layout-builder" {...props} draggable={!props.DragDisabled.dragStatus}>
        <div className="layout-builder-titles">{ rest.name }</div>
        <div className="mt-3">
          <FaTrash
              className="pull-right"
              color="#dc3545"
              onClick={() => state.removeElement(id, dropzoneID, parentID)}
          />
          <Row className="row inner-layout-builder">
            <Col sm="6">
              <Dropzone
                  {...dropzoneProps}
                  initialElements={filterInitialElements('canvas-1-1')}
                  id="canvas-1-1"
                  onDrop={_onDrop}
                  placeholder="Drop Here"
              />
            </Col>
            <Col sm="6">
              <Dropzone
                  {...dropzoneProps}
                  initialElements={filterInitialElements('canvas-1-2')}
                  id="canvas-1-2"
                  onDrop={_onDrop}
                  placeholder="Drop Here"
              />
            </Col>
          </Row>
        </div>
      </Draggable>
  );
};

DraggableCardLayout.propTypes = {
  id: PropTypes.string.isRequired,
  showBasicContent: PropTypes.bool.isRequired
};
const mapStateToProps = state => ({
  DragDisabled:state.DynamicDashboard.getDragDisabled
});
export default  withRouter(connect(mapStateToProps, {


})(DraggableCardLayout));

