import React, {Component,useRef, useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as taskActions from "../../actions/ToDo";
import {getProfile} from "../../utilities/AuthService";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import PrintTaskDetail from "../pages/PrintTaskDetail";
import PrintWorkflowDetail from "../pages/PrintWorkflowDetail";
import WorkflowTaskDetails from "./workflow-task/WorkFlowtaskDetailsNew";
import rootActions from "../../actions";
import LoaderSvg from "../views/LoaderSVG";
class DetailContainerPrint extends Component {

    componentDidMount() {
        this.props.mainClick('task','task/details');
        var taskid = (atob(getUrlSegment(3)));
        var mode = (atob(getUrlSegment(4)));
        this.setState({taskmode : mode});
        let data = { _id : taskid, systype: "root", uid : getProfile().id, mode: mode };
        this.props.gettaskdetails(data);
        this.props.getattchmentlist({"_id": taskid, "reference": "All"});
        this.props.getloglist({"_id": taskid, "type": "todo"});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match !== this.props.match) {
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            this.setState({taskmode : mode});

            let data = { _id : taskid, systype: 'user', uid : getProfile().id, mode: mode };
            this.props.gettaskdetails(data);
        }
        if(this.props.modifyTask.task_modified !== prevProps.modifyTask.task_modified){
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            this.setState({taskmode : mode});
            let data = { _id : taskid, systype: "root", uid : getProfile().id, mode: mode };
            this.props.gettaskdetails(data);
        }
    }

    render() {
        let taskData = this.props.taskdetailsData.taskdetails.parent;
        return(
            <React.Fragment>
                    {this.props.taskdetailsData.loading &&
                    <span className="Loader-holder">
                        <LoaderSvg/>
                    </span>
                    }
                    {Object.entries(taskData).length > 0  &&
                    <React.Fragment key={"task-detail-type-1"}>
                        {(taskData.createdfrom !== "workflow" && taskData.tasktype !== "workflow") &&
                        <PrintTaskDetail
                            taskdetailsData={this.props.taskdetailsData}
                            attachmentData={this.props.attachmentData}
                            logData = {this.props.todologData}
                        />
                        }

                        {(taskData.createdfrom === "workflow" || taskData.tasktype === "workflow") &&
                        <PrintWorkflowDetail
                            taskdetailsData={this.props.taskdetailsData}
                            attachmentData={this.props.attachmentData}
                            logData = {this.props.todologData}
                        />
                        }
                    </React.Fragment>
                    }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    taskdetailsData : state.TaskReducer.TaskDetails,
    modifyTask:state.TaskReducer.ModifyTask,
    attachmentData : state.TaskReducer.Attachmentlist,
    todologData : state.TaskReducer.TodoLoglist

});

export default  withRouter(connect(mapStateToProps, {
    gettaskdetails:taskActions.taskdetails,
    mainClick:rootActions.commonActions.MenuClick,
    getattchmentlist : taskActions.getattchments,
    getloglist : taskActions.gettodologs
})(DetailContainerPrint));
