import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import LoaderSvg from "./LoaderSVG";

class BeneficiaryData extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    render() {

        return (
            <React.Fragment>
            <div className="list-row with-solid">
                <div className="solid-graph">
                    <div className="solid-graph-wrap">
                        <img src="/assets/images/solid-graph.png"/>
                    </div>
                </div>
                <div className="blue-lister">
                    <div className="grid-header">
                        <ul className="graph-tag">
                            <li className="orange-circle">Men</li>
                            <li className="navy-circle">Women</li>
                            <li className="blue-circle">Childern</li>
                        </ul>
                    </div>
                    <div className="blue-list-body">
                        <div className="graph-wrap">
                            <img src="/assets/images/sample-graph-1.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-row">
                <div className="blue-lister">
                    <div className="grid-header">
                         <ul className="graph-tag">
                             <li className="orange-circle">Men</li>
                            <li className="navy-circle">Women</li>
                            <li className="blue-circle">Childern</li>
                            <li className="ash-circle">Boys</li>
                            <li className="green-circle">Girls</li>
                        </ul>
                    </div>
                    <div className="blue-list-body">
                        <div className="graph-wrap">
                        <img src="/assets/images/sample-graph-2.png"/>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>

        );
    }


}
class GraphBlock extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    render() {

        return (
            <React.Fragment>
                <div className="stakeholders-header">
                    <h5>Clean Energy Capacity</h5>
                </div>
                <div className="ibt-split-table two-row">
                    <div className="blue-lister">
                        <div className="grid-header">
                            <h5>Clean Energy Capacity</h5>
                        </div>
                        <div className="blue-list-body">
                            <ul className="graph-tag">
                                <li className="navy-circle">Gross Profit Margin</li>
                                <li className="blue-circle">Targeted Gross Profit Margin</li>
                            </ul>
                            <div className="graph-wrap">
                                <img src="/assets/images/sample-graph-3.png"/>
                            </div>
                        </div>
                    </div>
                    <div className="blue-lister">
                        <div className="grid-header">
                            <h5>Cumulative Clean Energy Capacity</h5>
                        </div>
                        <div className="blue-list-body">
                            <ul className="graph-tag">
                                <li className="blue-circle">Net Profit Margin</li>
                                <li className="green-circle">Targeted Gross Profit Margin</li>
                            </ul>
                            <div className="graph-wrap">
                                <img src="/assets/images/sample-graph-4.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stakeholders-header">
                    <h5>Usage Type</h5>
                </div>
                <div className="ibt-split-table two-row">
                    <div className="blue-lister">
                        <div className="grid-header">
                            <h5>Primary Usage Type</h5>
                        </div>
                        <div className="blue-list-body">
                            <ul className="graph-tag">
                                <li className="navy-circle">Comfort</li>
                                <li className="blue-circle">Entertainment</li>
                                <li className="orange-circle">Work</li>
                                <li className="ash-circle">Education</li>
                                <li className="green-circle">Health</li>
                            </ul>
                            <div className="graph-wrap">
                                <img src="/assets/images/sample-graph-5.png"/>
                            </div>
                        </div>
                    </div>
                    <div className="blue-lister">
                        <div className="grid-header">
                            <h5>Secondary Usage Type</h5>
                        </div>
                        <div className="blue-list-body">
                            <ul className="graph-tag">
                                <li className="navy-circle">Comfort</li>
                                <li className="blue-circle">Entertainment</li>
                                <li className="orange-circle">Work</li>
                                <li className="ash-circle">Education</li>
                                <li className="green-circle">Health</li>
                            </ul>
                            <div className="graph-wrap">
                                <img src="/assets/images/sample-graph-6.png"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="stakeholders-header">
                    <h5>People Employed</h5>
                </div>
                <div className="ibt-split-table two-row">
                    <div className="blue-lister">
                        <div className="grid-header">
                            <h5>People Employed</h5>
                            <ul className="graph-tag">
                                <li className="navy-circle">Men</li>
                                <li className="blue-circle">Women</li>
                            </ul>
                        </div>
                        <div className="blue-list-body without-remark">
                            <div className="graph-wrap">
                                <img src="/assets/images/sample-graph-7.png"/>
                            </div>
                        </div>
                    </div>
                    <div className="blue-lister">
                        <div className="grid-header">
                            <h5>Employed in Rural/Urban Areas</h5>
                            <ul className="graph-tag">
                                <li className="orange-circle">Rural</li>
                                <li className="ash-circle">Urban</li>
                            </ul>
                        </div>
                        <div className="blue-list-body without-remark">
                            <div className="graph-wrap">
                                <img src="/assets/images/sample-graph-8.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }


}
export {BeneficiaryData,GraphBlock};