import React, {Component} from 'react';
import {getProfile} from "../../utilities/AuthService";
import {Form, FormControl, InputGroup,ButtonToolbar,Button,Dropdown} from "react-bootstrap";
import DatePickerBox from "../views/DatePickerBox";
import {ReactSelectize, SimpleSelect, MultiSelect} from 'react-selectize';
import 'react-selectize/themes/index.css'
import SimpleModal from "./Header";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "" : "",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? "" : "",
    // padding: grid,
    // // width: 250
});


class ListAllCustomFields extends Component {

    constructor(props) {
        super(props);
        this.state={
            items: [],
            listCustomdata:[]
        }
        this.onDragEnd = this.onDragEnd.bind(this);
      }


    componentDidMount() {
 this.setState({listCustomdata:this.props.allcustomfields})
       // //console.log(this.state.listCustomdata)

    }


    componentWillUpdate(prevProps,nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.allcustomfields !== this.props.allcustomfields) {
            this.setState({listCustomdata: this.props.allcustomfields})
            //this.props.metaForm(this.props.allcustomfields)
        }
    }
    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const listCustomdata = reorder(
            this.state.listCustomdata,
            result.source.index,
            result.destination.index
        );

        this.setState({
            listCustomdata
        });
        this.props.metaForm(listCustomdata)
    }
    render() {

       ////console.log(this.state.listCustomdata)
         return (
                 <div className="stage-form-container">
                     <div className="default-placeholder">
                         <h5>Create Fields. Publish Form Anywhere.</h5>
                         <p>Create and share online forms or embed forms on your
                             websites, <br/>share them privately or go public.</p>
                     </div>
                     <div className="form-block-container">
                         <div className="form-block-left">
                             <div className="form-list-wrap">
             <DragDropContext onDragEnd={this.onDragEnd}>
                 <Droppable droppableId="droppable">
                     {(provided, snapshot) => (
                         <div
                             {...provided.droppableProps}
                             ref={provided.innerRef}
                             style={getListStyle(snapshot.isDraggingOver)}
                         >
                             {this.state.listCustomdata.map((item,index)=> (
                                 <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                     {(provided, snapshot) => (
                                         <div
                                             ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}
                                             style={getItemStyle(
                                                 snapshot.isDragging,
                                                 provided.draggableProps.style
                                             )}
                                         >

                                             <div onClick={()=>this.props.getCustomField(item.errormessage,item.id,item.label,item.type,item.required,(item.options === undefined ?[]:item.options),'edit',index)}
                                             >
                                                 <div className="counter-field">{index+1}</div>
                                                 <div className="field-type-name">
                                                     <p> {item.label}</p>
                                                 </div>
                                             </div>


                                             <a  className="ico-lib minus-button" onClick={() => this.props.removeClick(index)}> remove</a>

                                         </div>
                                     )}
                                 </Draggable>
                             ))}
                             {provided.placeholder}
                         </div>
                     )}
                 </Droppable>
             </DragDropContext>
                             </div>
                         </div>
                         <div className="form-block-right">
                             <div className="faq-form-field">
                                 <h5>How to use fields?</h5>
                                 <p>Once the printer ink runs dry it has to be replaced with
                                     another inkjet cartridge. There are many reputed
                                     companies like Canon, Epson, Dell, and Lexmark that
                                     provide the necessary cartridges.</p>
                             </div>
                             <div className="faq-form-field">
                                 <h5>What’s Fieldset?</h5>
                                 <p>It won’t be a bigger problem to find one video game lover
                                     in your neighbor. Since the introduction of Virtual
                                     Game, it has been achieving great heights so far as its
                                     popularity and technological advancement are
                                     concerned.</p>
                             </div>
                         </div>
                     </div>
                 </div>




        );
    }
}


export {ListAllCustomFields}


