import React, {Component} from 'react';
import {urlify} from "../../../utilities/CustomFunctions";
function showRating(stars) {

    let ulItems = [];
    for (var i = 0; i < 5; i++) {
        ulItems.push(<li className={(i<stars ? "active" :'')}></li>);
    }
    return ulItems;
}
function RatingTile(props) {

        return (
                <div className="ind-ibt-rating">
                    <div className="ibt-rating-top">
                        <h5>{props.title}</h5>
                    </div>
                    <div className="ibt-rating-bottom">
                        <h5>{props.stars}/5</h5>
                        <ul className="ibt-rate-star">{showRating(props.stars)}</ul>

                    </div>
                </div>
        );
}

export default RatingTile;