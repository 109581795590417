import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../../actions/ToDo"
import {withRouter} from 'react-router';
import ListCard from "../../views/ListCard"
import {getProfile} from "../../../utilities/AuthService";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import FilterBlock from "../FilterBlock";
import FilerRightBlock from "../FilterRightBlock";
import * as projectActions from "../../../actions/Project";
import Modal from "../../views/Modal";
import AddToDoTask from "../AddToDoTask";
import rootActions from "../../../actions";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import AlertBar from "./AlertBar";


class ToRespond extends Component {

    constructor(props) {
        super(props);
        this.gettaskList=this.gettaskList.bind(this);
        this.modalOpen=this.modalOpen.bind(this);
        this.state={
            open:false,
            projectList:[],
            limit : 0,
            loadingState: false,
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            toverifydata: [],
            tocommentdata:[],
            offset: 250
        }
    }



    componentDidMount() {

        if(this.props.todoListing.ToRespond.data.length ===0) {
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
            }
            else {
                this.gettaskList("respond",'All');
            }
        }
        else{
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
                this.setState({ toverifydata: this.props.todoListing.ToRespond.data,tocommentdata: this.props.todoListing.ToRespond.data1, loadingState : false })
            }
            else {
                this.setState({ toverifydata: this.props.todoListing.ToRespond.data, tocommentdata: this.props.todoListing.ToRespond.data1,loadingState : false })

            }
        }

        this.props.mainClick('task','/task/to-respond')
        this.props.userFilter("respond",getProfile().id)
    }

    searchFilterActive(){
        var status = "All"
        if(getUrlSegment(2)==='todo'){
            var category='to-me'
        }
        if(getUrlSegment(2)==='to-follow-up'){
            var category='by-me'
        }
        if(getUrlSegment(2)==='to-oversee'){
            var category='manage-task'
        }
        if(getUrlSegment(2)==='to-know'){
            var category='to-know'
        }
        if(getUrlSegment(2)==='to-comment'){
            var category='Comments'
        }
        if(getUrlSegment(2)==='to-respond'){
            var category='respond'
        }
        if(getUrlSegment(2)==='to-verify'){
            var category='by-me'
            status = "Done"
        }


        var data = {
            category : category,
            status :"All",
            uid : getProfile().id,
            bydue : '',
            priority :this.props.searchData.task.priority,
            project :this.props.searchData.task.project,
            asigntype : '',
            username :'',
            managetask : 'mytask',
            srchval:this.props.searchData.task.srchval,
            limit : 0,
            offset  : this.state.offset,
            systype : getProfile().usertype,
        }

        this.props.searchType('SEARCH_TASK_DATA',this.props.searchData.task.srchval,"srchval",data)

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if(prevProps.taskFilters  !== this.props.taskFilters){
            this.setState({  limit : 0 },
                function() {
                    this.gettaskList("respond",'All');
                })
        }
        if(prevProps.taskSelect.tasksArchived  !== this.props.taskSelect.tasksArchived ){
            setTimeout(function () {
                beta.gettaskList('respond','All');
            }, 1000)
        }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            if(this.props.HistoryPushData.savemode === 'by-me') {
                var data = {category : 'by-me', status : 'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', username : this.props.taskFilters.taskAssignedFilter,limit: 0, offset  : this.state.offset, systype :  getProfile().usertype,  actionType : 'LIST_TO_RESPOND_DATA' }
                this.setState({open:false})
            }
            if(this.props.HistoryPushData.savemode === 'to-me') {
                var data = {category : 'to-me', status :'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
                this.setState({open:false})
            }
        }

        if(prevProps.todoListing  !== this.props.todoListing){
            this.setState({toverifydata: this.props.todoListing.ToRespond.data,tocommentdata: this.props.todoListing.ToRespond.data1});
        }



    }
    modalOpen(){
        this.setState({open:!this.state.open,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData});
    }
    gettaskList(cat,status){

        var data = {category : cat, status : status , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask',  limit: 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TO_RESPOND_DATA' }
        this.props.toRespond(data);
    }
    selectTasks(id) {
        this.props.selectTasksToMove(id);
    }

    render() {
        let ToVerify= this.props.searchData.task.active? this.props.searchData.data: this.state.toverifydata;
        let ToComment= this.props.searchData.task.active? this.props.searchData.data: this.state.tocommentdata;
        // let ToVerify=this.state.verifydata.filter(item => (item.subject===''?atob(item.Title):atob(item.subject)).toLowerCase().search(this.props.searchData.task.toLowerCase()) >= 0)
        return (



            <>
                <div className='filter-all-wrapper task-home'>
                    {/*<AlertBar*/}
                    {/*    history={this.props.history}*/}

                    {/*/>*/}
                    <div className='filter-holder'>
                        {this.props.taskSelect.taskSelection === 'no' &&
                        <FilterBlock/>
                        }
                        <FilerRightBlock
                            history={this.props.history}

                        />
                    </div>
                </div>
                {this.props.todoListing.loading &&
                <span className="Loader-holder" style={{'background':'transparent'}}>
                            <LoaderSvg/>
                        </span>
                }
                <div className={this.props.todoListing.loading ? 'main-panel-holder with-loader has-filter' : 'main-panel-holder has-filter'} ref="iScroll">
                    {this.props.todoListing.loading &&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                    }
                    {ToVerify.length === 0 && ToComment.length ===  0 && !this.props.todoListing.loading &&
                    <div className='absolute-holder'>
                        <NoData

                            msg={`No task assigned to you`}

                        />
                    </div>
                    }

                    {ToVerify.map((list,index) =>
                        <React.Fragment key={'verify-read'+index}>
                            {index === 0 &&
                            <h4 className={'main-panel-head'}>To Verify</h4>
                            }
                            <ListCard
                                key={'verify-list-card-unread'+index}
                                index={index}
                                sectionTitle={`Unread`}
                                list={list}
                                history={this.props.history}
                                mode='by-me'
                                taskSelection = {this.props.taskSelect.taskSelection}
                                selectTasks = {this.selectTasks.bind(this)}
                                allChecked = {this.props.taskSelect.allChecked}
                                id={list.masterid}
                                tasksource={list.createdfrom}
                                poolenabled={list.poolenabled}
                            />
                        </React.Fragment>
                    )}


                    {ToComment.map((list,index) =>
                        <React.Fragment key={'comment'+index}>
                            {index === 0 &&
                            <h4 className={'main-panel-head'}>To Comment</h4>
                            }
                            <ListCard
                                key={'comment-list-card'+index}
                                index={index}
                                sectionTitle={`Unread`}
                                list={list}
                                history={this.props.history}
                                mode='by-me'
                                taskSelection = {this.props.taskSelect.taskSelection}
                                selectTasks = {this.selectTasks.bind(this)}
                                allChecked = {this.props.taskSelect.allChecked}
                                id={list.masterid}
                                tasksource={list.createdfrom}
                                poolenabled={list.poolenabled}
                            />
                        </React.Fragment>
                    )}
                </div>
                {this.state.open &&
                <Modal title={'Create New Task'} Close ={this.modalOpen.bind(this)}>
                    <AddToDoTask />

                </Modal>
                }
                <div className={'float-button-box'} onClick={this.modalOpen}>
                    <span className={'icon-in icon-plus'}></span>
                </div>
            </>


        );
    }
}



const mapStateToProps = state => ({

    todoListing:state.TaskReducer.listtoDo,
    menuClickData:state.CommonReducer.menuClick,
    searchData:state.CommonReducer.searchparamdata,
    taskFilters:state.TaskReducer.filterTasks,
    taskSelect:state.TaskReducer.getTaskSelection,
    saveTodo : state.TaskReducer.saveTodo,
    projects:state.TaskReducer.GetProjectData,
    listtags:state.TaskReducer.ListAllTags,
    sidebarmenudata: state.TaskReducer.SidebarMenudata,
    HistoryPushData:state.TaskReducer.HistoryPushData
});
export default  withRouter(connect(mapStateToProps, {
    todo:taskActions.listtask,
    toFollowUp:taskActions.listtask,
    toRespond:taskActions.listtask,
    mainClick:rootActions.commonActions.MenuClick,
    selectTasksToMove : taskActions.selectTasksToMove,
    listTags:projectActions.listalltags,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    searchType: rootActions.commonActions.searchType,
    userFilter:taskActions.userFilter,

})(ToRespond));



