import axios from 'axios'
import {commonService} from "./common.services";
import {API_PATH , ES_INDEX} from "../constants";
export const portfolio = {
    listportfolio,
    SavePortfolio,
    DeletePortfolio
};

function listportfolio(params){
    return commonService.sendHttpRequest('sector/portfolio', params, "GET");
    // params["index"]=ES_INDEX
    // params["t"]=Math.floor(Date.now() / 1000)
    // return axios.get(API_PATH+'sector/portfolio', {params});
}

function SavePortfolio(params) {
    return commonService.sendHttpRequest('sector/createportfolio', params, "POST");
    // params["index"]=ES_INDEX
    // return axios.post(API_PATH + 'sector/createportfolio?',params)
}

function DeletePortfolio(params) {
    return commonService.sendHttpRequest('sector/', params, "POST");
}