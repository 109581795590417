import React, {Component} from 'react';
import {getProfile} from "../../utilities/AuthService";
import {Form, FormControl, InputGroup,ButtonToolbar,Button,Dropdown} from "react-bootstrap";
import DatePickerBox from "../views/DatePickerBox";
import {ReactSelectize, SimpleSelect, MultiSelect} from 'react-selectize';
import 'react-selectize/themes/index.css'
import CheckboxOrRadioGroup from '../../components/views/FormElements/CheckboxOrRadioGroup';
import SingleInput from '../../components/views/FormElements/SingleInput';
import TextArea from '../../components/views/FormElements/TextArea';
import Select from '../../components/views/FormElements/Select';
import {ES_INDEX,USRIMG_PATH} from "../../constants/index";
import UploadFile from "../../utilities/Upload/UploadFile";


class AddTask extends Component {

    constructor(props) {
        super(props);
        this.onSubmit=this.onSubmit.bind(this)
        this.FormHandleChange=this.FormHandleChange.bind(this)
        this.handleProject =this.handleProject.bind(this)
        this.state={
            to:'',
            subject:'',
            description:'',
            ProjectLists:[]
        }
        this.state = {
            startDate: new Date()
        };
        this.ProjectList=[]
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
      }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    componentDidMount() {


    }


    componentWillUpdate(prevProps,nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    handleProject(val){
      ////////////console.log(val)



    }
    onSubmit(event){
        event.preventDefault();
        //alert(this.state.to)
        //alert(this.state.subject)
        if(this.state.to !=='' && this.state.subject !==''){
            this.props.formSubmission(this.state.to,this.state.subject,this.state.description,getProfile().id)
        }
    }
    FormHandleChange(e){

        this.setState({[e.target.name]:e.target.value})
    }
    handleDateChange(date){
        alert(date)

    }
    render() {

       ////////////console.log(this.ProjectList)
        this.state=this.props
         return (

             <form onSubmit={this.onSubmit}>




                 <Form.Group controlId="full-panel" className={`full-panel`}>
                     <InputGroup className="mb-3">
                         <InputGroup.Prepend>
                             <InputGroup.Text name={`to`} id="inputGroup-sizing-default" placeholder="To" onChange={this.FormHandleChange}>To</InputGroup.Text>
                         </InputGroup.Prepend>
                         <FormControl
                             name={`to`}
                             onChange={this.FormHandleChange}
                             aria-label="Default"
                             aria-describedby="inputGroup-sizing-default"
                         />
                     </InputGroup>
                 </Form.Group>

                 <SingleInput
                     className={`full-panel`}
                     inputType={'text'}
                     title={'subject'}
                     name={'subject'}
                     controlFunc={this.FormHandleChange}
                     content={this.state.subject}
                     placeholder={'Subject'}
                 />
                 <TextArea
                     className={`full-panel`}
                     title={'description'}
                     rows={3}
                     resize={true}
                     content={this.state.description}
                     name={'description'}
                     controlFunc={this.FormHandleChange}
                     placeholder={'Description'}
                 />





                 <Form.Group controlId="" className={`full-panel`}>
                 <DatePickerBox

                     handleDateChange={this.handleDateChange}
                 />
                 </Form.Group>

                 <Form.Group>
                     <Form.Control as="select">
                         <option value={`All`}>Priority</option>
                         <option value={`Low`}>Low</option>
                         <option value={`Medium`}>Medium</option>
                         <option value={`High`}>High</option>
                         <option value={`Urgent`}>Urgent</option>
                     </Form.Control>
                 </Form.Group>

                  <Form.Group>
                     <Form.Group>
                         <MultiSelect
                             placeholder = "Select Projects"
                             options = {this.props.projectList.map(

                                 (list,index) => ({label: list.project_name, value: list.project_name})
                             )}
                             onValuesChange = {(value) => this.handleProject(value)}
                         />
                     </Form.Group>
                 </Form.Group>

                  <Form.Group >
                     <Form.Group>
                         <MultiSelect
                             placeholder = "Select Projects"
                             options = {this.props.listtags.map(

                                 (list,index) => ({
                                     label: list._source.tag_name,
                                     value: list.tag_name
                                 })
                             )}
                             createFromSearch = {function(options, values, search){
                                 var labels = values.map(function(value){
                                     return value.label;
                                 })
                                 if (search.trim().length == 0 || labels.indexOf(search.trim()) != -1)
                                     return null;
                                 return {label: search.trim(), value: search.trim()};
                             }}

                             onValuesChange = {(value) => this.handleProject(value)}


                         />
                     </Form.Group>
                 </Form.Group>



                 <ButtonToolbar>
                     <Button variant="primary" type="submit">Primary</Button>
                 </ButtonToolbar>
             </form>






        );
    }
}

class AddGroup extends Component {

    constructor(props) {
        super(props);
        this.onSubmit=this.onSubmit.bind(this)
        this.FormHandleChange=this.FormHandleChange.bind(this)
        this.state={
            cname:'',
            cdescription:''
        }

    }

    handleChange(date) {

    }

    componentDidMount() {


    }


    componentWillUpdate(prevProps,nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    handleProject(val){

    }
    onSubmit(event){
        event.preventDefault();
        if(this.state.cdescription !=='' && this.state.cname !=='' ) {
            this.props.Close()
            this.props.addTeam(getProfile().id, this.state.cname, this.state.cdescription, '', 'lsinternalcopy','group')
        }
    }
    FormHandleChange(e){
        this.setState({[e.target.name]:e.target.value})

    }
    handleDateChange(date){


    }
    render() {


        return (

            <form onSubmit={this.onSubmit}>




                <Form.Group controlId="full-panel" className={`full-panel`}>
                    <InputGroup className="mb-3">
                        <Form.Group controlId="" className={`full-panel`}>

                            <Form.Control type="text" className="modal-text-field" name={`cname`} placeholder="Channel Name" onChange={this.FormHandleChange}/>
                        </Form.Group>
                     </InputGroup>
                </Form.Group>

                <Form.Group controlId="full-panel" className={`full-panel`}>
                    <InputGroup className="mb-3">
                       <Form.Group controlId="" className={`full-panel`}>

                            <Form.Control as="textarea" className="modal-text-area" rows="4"  name={`cdescription`} placeholder="Channel Description" onChange={this.FormHandleChange}/>
                        </Form.Group>
                    </InputGroup>
                </Form.Group>


                <ButtonToolbar className="modal-footer">
                    <Button type="submit" className="general-btn">OK</Button>
                    <Button className="general-btn grey" onClick={this.props.Close}>Cancel</Button>
                </ButtonToolbar>
            </form>






        );
    }
}


class AddMembers extends Component {

    constructor(props) {
        super(props);
        this.onSubmit=this.onSubmit.bind(this)
        this.FormHandleChange=this.FormHandleChange.bind(this)
        this.state={
            cname:'',
            cdescription:'',
            searchValue:''
        }

    }

    handleChange(date) {

    }

    componentDidMount() {


    }


    componentWillUpdate(prevProps,nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    handleProject(val){

    }
    onSubmit(event){
        event.preventDefault();
        if(this.state.cdescription !=='' && this.state.cname !=='' ) {
            this.props.addTeam(getProfile().id, this.state.cname, this.state.cdescription, '', ES_INDEX,'group')
        }
    }
    FormHandleChange(e){
        this.setState({[e.target.name]:e.target.value})

    }
    handleChange(e){

        this.setState({searchValue:e.target.value})
    }


    render() {
        let allUsers=this.props.allUsers.filter(item => (item.firstName === ''?(item.designation):(item.firstName)).toLowerCase().search(this.state.searchValue.toLowerCase()) >= 0)

        return (

            <form>




                <Form.Group controlId="full-panel" className={`full-panel`}>
                    <InputGroup className="mb-3">
                        <Form.Group controlId="" className={`full-panel`}>
                            <div className="search-input-all">
                                <Form.Control type="text" className="modal-text-field search-text-field" placeholder="Search Users" onChange={(e)=>this.handleChange(e)}/>
                                <span className="icon-in icon-search-in"></span>
                            </div>
                        </Form.Group>
                    </InputGroup>
                </Form.Group>

                {this.state.searchValue !== '' ?

                    <div className="scrollable-panel-holder">
                        {/*<h5 className="main-text">Recent Conversations</h5>*/}
                        <div className="scrollable-panel">
                            {allUsers.map((list, index) =>
                                <div className="member-each" onClick={()=>this.props.ChatHistory(getProfile().id,list.user_id,0,50,ES_INDEX,'single',list.firstName+' '+list.lastName,list.designation,list.image)}>
                                    <div className="members-details">
                                        <img src={USRIMG_PATH + list.image}
                                             alt="user-image" className="member-image-in"/>
                                        <div className="members-info-in">
                                            <h4 className="member-name">{list.firstName} {list.lastName}</h4>
                                            <p className="member-role">{list.designation}</p>
                                        </div>
                                    </div>
                                    <div className="chat-date-indic">
                                        {/*<p className="hover-hidden-text">5 days ago</p>*/}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    :
                    <div className="scrollable-panel-holder">
                        {/*<h5 className="main-text">Recent Conversations</h5>*/}
                        <div className="scrollable-panel">
                            {this.props.chatUsers.users.map((list, index) =>
                                <div className="member-each" onClick={()=>this.props.ChatHistory(getProfile().id,list.userid,0,50,ES_INDEX,'single',list.firstname+' '+list.lastname,list.designation,list.userhead)}>
                                    <div className="members-details">
                                        <img src={USRIMG_PATH+ list.userhead}
                                             alt="user-image" className="member-image-in"/>
                                        <div className="members-info-in">
                                            <h4 className="member-name">{list.firstname} {list.lastName}</h4>
                                            <p className="member-role">{list.designation}</p>
                                        </div>
                                    </div>
                                    <div className="chat-date-indic">
                                        {/*<p className="hover-hidden-text">5 days ago</p>*/}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                }



                {/*<ButtonToolbar className="button-all-holder">*/}
                    {/*<Button type="submit" className="general-btn">Go</Button>*/}
                {/*</ButtonToolbar>*/}
            </form>






        );
    }
}


class InviteUsers extends Component {

    constructor(props) {
        super(props);
        this.onSubmit=this.onSubmit.bind(this)
        this.FormHandleChange=this.FormHandleChange.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
        this.state={
            cname:'',
            cdescription:'',
            searchValue:"",
            members:[],
            temp_user_array:[]
        }

    }

    componentDidMount() {


        var temp_user_array = this.props.allUsers;
        for(var i=0; i < this.props.assigneesData.length; i++)
        {
            var index = temp_user_array.findIndex(x => x.user_id === parseInt(this.props.assigneesData[i].assignee));
            //////////console.log(this.props.assigneesData);
            if(index > -1)
            {
                temp_user_array.splice(index, 1);
            }
        }
        this.setState({temp_user_array:temp_user_array})
       // ////////console.log(temp_user_array);



    }


    componentWillUpdate(prevProps,nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    handleSubmit(event){
        event.preventDefault();
        if(this.state.members !== 0){

            //////////console.log(this.state.members)
            this.props.addInviteUsers(this.state.members,getProfile().id)
            this.props.Close()

        }
    }
    AddMembers(id){
        let remove = this.state.members.indexOf('user_'+id);
        if(remove === -1) {
            this.setState({
                members: [...this.state.members, 'user_'+id],
            });
            //var element = document.getElementById('user-list-'+id)
         //   element.classList.add("selected-user");

        }
        else{
            this.setState({
                members: this.state.members.filter((_, i) => i !== remove)
            });
           // var element = document.getElementById('user-list-'+id)
            //element.classList.remove("selected-user");
        }






    }
    onSubmit(event){
        event.preventDefault();
        if(this.state.cdescription !=='' && this.state.cname !=='' ) {
            this.props.addTeam(getProfile().id, this.state.cname, this.state.cdescription, '', 'lsinternalcopy','group')
        }
    }
    FormHandleChange(e){
        this.setState({[e.target.name]:e.target.value})

    }
    handleChange(e){

        this.setState({searchValue:e.target.value})
    }


    render() {

        let allUsers=this.state.temp_user_array.filter(item => (item.firstName === ''?(item.designation):(item.firstName)).toLowerCase().search(this.state.searchValue.toLowerCase()) >= 0)



        return (

            <form className="invite-user-form">




                <Form.Group controlId="full-panel" className={`full-panel`}>
                    <InputGroup className="mb-3">
                        <Form.Group controlId="" className={`full-panel`}>
                            <div className="search-input-all">
                                <Form.Control type="text" className="modal-text-field search-text-field" placeholder="Search Users" onChange={(e)=>this.handleChange(e)}/>
                                <span className="icon-in icon-search-in"></span>
                            </div>
                        </Form.Group>
                    </InputGroup>
                </Form.Group>


                <div className="scrollable-panel-holder">
                    {/*<h5 className="main-text">Recent Conversations</h5>*/}
                    <div className="scrollable-panel">
                        {allUsers.map((list, index) =>
                            <div id={`user-list-`+list.user_id} className={`member-each ${this.state.members.includes("user_"+list.user_id.toString())? 'selected-user': ''}`}  onClick={()=>this.AddMembers(list.user_id)} key={`add-members-to-group`+index}>
                                <div className="members-details">
                                    <div  className="member-image-in">
                                    <img src={USRIMG_PATH + list.image}
                                         alt="user-image"/>
                                </div>
                                    <div className="members-info-in">
                                        <h4 className="member-name">{list.firstName} {list.lastName}</h4>
                                        <p className="member-role">{list.designation}</p>
                                    </div>
                                </div>
                                <div className="chat-add-indic">

                                </div>
                            </div>
                        )}
                    </div>
                </div>






                <ButtonToolbar className="modal-footer">
                    <p className={`user-count-text`}> {this.state.members.length} Users added </p>
                    <Button className="general-btn grey" onClick={this.props.Close}>Cancel</Button>
                    <Button type="submit" className="general-btn" onClick={this.handleSubmit}>Go</Button>
                </ButtonToolbar>
            </form>



        );
    }
}


class AddMembersToGroup extends Component {

    constructor(props) {
        super(props);
        this.onSubmit=this.onSubmit.bind(this)
        this.FormHandleChange=this.FormHandleChange.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
        this.state={
            cname:'',
            cdescription:'',
            searchValue:"",
            members:[]
        }

    }

    componentDidMount() {


    }


    componentWillUpdate(prevProps,nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    handleSubmit(event){
        event.preventDefault();
        if(this.state.members !== 0){

            ////////////console.log(this.state.members)
            this.props.addMembersToGroup(this.props.groupData.ReceiverUserid,this.state.members,atob(this.props.groupData.name),"save","lsinternalcopy")
            this.props.socket.emit('add_members_to_group', this.state.members,ES_INDEX);

            this.props.Close()

        }
    }
    AddMembers(id){
        let remove = this.state.members.indexOf(id);
        if(remove === -1) {
            this.setState({
                members: [...this.state.members, id],
            });
            var element = document.getElementById('user-list-'+id)
            element.classList.add("selected-user");

        }
        else{
            this.setState({
                members: this.state.members.filter((_, i) => i !== remove)
            });
            var element = document.getElementById('user-list-'+id)
            element.classList.remove("selected-user");
        }






    }
    onSubmit(event){
        event.preventDefault();
        if(this.state.cdescription !=='' && this.state.cname !=='' ) {
            this.props.addTeam(getProfile().id, this.state.cname, this.state.cdescription, '', 'lsinternalcopy','group')
        }
    }
    FormHandleChange(e){
        this.setState({[e.target.name]:e.target.value})

    }
    handleChange(e){

        this.setState({searchValue:e.target.value})
    }
    render() {
          let allUsers=this.props.allUsers.filter(item => (item.firstName).toLowerCase().search(this.state.searchValue.toLowerCase())!== -1)

        return (

            <form>




                <Form.Group controlId="full-panel" className={`full-panel`}>
                    <InputGroup className="mb-3">
                        <Form.Group controlId="" className={`full-panel`}>
                            <div className="search-input-all">
                                <Form.Control type="text" className="modal-text-field search-text-field" placeholder="Search Users" onChange={(e)=>this.handleChange(e)}/>
                                <span className="icon-in icon-search-in"></span>
                            </div>
                        </Form.Group>
                    </InputGroup>
                </Form.Group>


                    <div className="scrollable-panel-holder">
                        {/*<h5 className="main-text">Recent Conversations</h5>*/}
                        <div className="scrollable-panel">
                            {allUsers.map((list, index) =>
                                <div id={`user-list-`+list.user_id} className={`member-each ${this.state.members.indexOf(list.user_id) !== -1? 'selected-user': ''}`}  onClick={()=>this.AddMembers(list.user_id)} key={`add-members-to-group`+index}>
                                    <div className="members-details">
                                        <img src={USRIMG_PATH + list.image}
                                             alt="user-image" className="member-image-in"/>
                                        <div className="members-info-in">
                                            <h4 className="member-name">{list.firstName} {list.lastName}</h4>
                                            <p className="member-role">{list.designation}</p>
                                        </div>
                                    </div>
                                    <div className="chat-add-indic">

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>






                <ButtonToolbar className="modal-footer">
                    <p className={`user-count-text`}> {this.state.members.length} Users added </p>
                    <Button className="general-btn grey" onClick={this.props.Close}>Cancel</Button>
                    <Button type="submit" className="general-btn" onClick={this.handleSubmit}>Go</Button>
                </ButtonToolbar>
            </form>






        );
    }
}

class EditGroup extends Component {

    constructor(props) {
        super(props);
        this.onSubmit=this.onSubmit.bind(this)
        this.FormHandleChange=this.FormHandleChange.bind(this)
        this.state={
            cname:'',
            cdescription:'',
            mode:false
        }

    }

    handleChange(date) {

    }

    componentDidMount() {


    }


    componentWillUpdate(prevProps,nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    handleProject(val){

    }
    onSubmit(event){
        event.preventDefault();
        if(this.state.desc !=='') {
            this.props.Close()
            this.props.editGroup(this.props.groupid, this.state.desc, this.props.creator,'lsinternalcopy')
        }
    }
    FormHandleChange(e){
        this.setState({modeEdit:true,[e.target.name]:e.target.value})

    }
    handleDateChange(date){


    }
    render() {

const {desc}=this.props
        return (

            <form onSubmit={this.onSubmit}>


                <Form.Group controlId="full-panel" className={`full-panel`}>
                    <InputGroup className="mb-3">
                        <Form.Group controlId="" className={`full-panel`}>

                            <Form.Control as="textarea" className="modal-text-area" rows="4"  name={`desc`} value={this.state.modeEdit?this.state.desc:atob(desc)} placeholder="Channel Description" onChange={this.FormHandleChange}/>
                        </Form.Group>
                    </InputGroup>
                </Form.Group>


                <ButtonToolbar className="modal-footer btn-toolbar">
                    <Button type="submit" className="general-btn">OK</Button>
                    <Button className="general-btn grey" onClick={this.props.Close}>Cancel</Button>
                </ButtonToolbar>
            </form>







        );
    }
}
class EditProfile extends Component {

    constructor(props) {
        super(props);
        this.onSubmit=this.onSubmit.bind(this)
        this.FormHandleChange=this.FormHandleChange.bind(this)
        this.Update=this.Update.bind(this)
        this.getUploadedFile=this.getUploadedFile.bind(this)
        this.state={
            cname:'',
            cdescription:'',
            passwordStatus:false,
            firstnamemode:false,
            lastnamemode:false,
            emailmode:false,
            phonemode :false,
            addressmode:false,
            firstname:'',
            lastname:'',
            email:'',
            phone :'',
            address:'',
            cnfpassword:'',
            password:'',
            filename: null,
            uploadedFiles : [],
        }

    }

    changePassword=()=> {
        this.setState({passwordStatus:!this.state.passwordStatus})

    }

    componentDidMount() {


    }


    componentWillUpdate(prevProps,nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }
    handleProject(val){

    }
    onSubmit(event){
        event.preventDefault();
        if(this.state.cdescription !=='' && this.state.cname !=='' ) {
            this.props.Close()
            this.props.addTeam(getProfile().id, this.state.cname, this.state.cdescription, '', 'lsinternalcopy','group')
        }
    }
    FormHandleChange(e){
        this.setState({[e.target.name]:e.target.value})

    }
    handleDateChange(date){


    }
    getUploadedFile(uploadedFile) {
        // //////////console.log(this.state.uploadedFiles);
        let uploadedFiles = [... this.state.uploadedFiles];
        ////////////console.log(uploadedFiles)
        uploadedFiles.push(uploadedFile);
        // //////////console.log(uploadedFiles)
        this.setState({
            filename: uploadedFile.fileName.split('/').pop(),
            uploadedFiles : uploadedFiles
        });
        // //////////console.log(this.state.uploadedFiles);
        if(this.state.filename !== null){
            let userdate = {
                "uid": getProfile().id,
                "photo": this.state.filename,
                "index": ES_INDEX
            }
            this.props.profilepicUpdate(userdate)
        }
    }
    controlFunc(e){
        this.setState({[e.target.name]:e.target.value,[e.target.name+'mode']:true});
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }
    Update(){
        let formValid = 0;
        let firstname = this.state.firstnamemode ? this.state.firstname : getProfile().first_name;
        let lastname = this.state.lastnamemode ? this.state.lastname : getProfile().last_name;
        let email = this.state.emailmode ? this.state.email : getProfile().email;
        let phone = this.state.phonemode ? this.state.phone : getProfile().phone;
        let address = this.state.addressmode ? this.state.address : getProfile().address;
        let password = this.state.password;
        let cnfpassword = this.state.cnfpassword;

        if (firstname === '') {
            formValid++;
            document.getElementsByName('firstname')[0].classList.add('val-error');
        }
        if (lastname === '') {
            formValid++;
            document.getElementsByName('lastname')[0].classList.add('val-error');
        }
        if (email === '') {
            formValid++;
            document.getElementsByName('email')[0].classList.add('val-error');
        }
        if (email !== '') {
            const reg = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
            if ( reg.test(email) === false) {
                formValid++;
                document.getElementsByName('email')[0].classList.add('val-error');
            }
            else{
                document.getElementsByName('email')[0].classList.remove('val-error');
            }
        }
        if (phone !== '') {
            const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if ( regex.test(phone) === false) {
                formValid++;
                document.getElementsByName('phone')[0].classList.add('val-error');
            }
            else{
                document.getElementsByName('phone')[0].classList.remove('val-error');
                }
        }
        if (this.state.passwordStatus && password === '') {
            formValid++;
            document.getElementsByName('password')[0].classList.add('val-error');
        }
        if (this.state.passwordStatus && cnfpassword === '') {
            formValid++;
            document.getElementsByName('cnfpassword')[0].classList.add('val-error');
        }
        if (this.state.passwordStatus && password !== '' && cnfpassword !== '') {
            if(cnfpassword !== password){
                formValid++;
                document.getElementsByName('password')[0].classList.add('val-error');
                document.getElementsByName('cnfpassword')[0].classList.add('val-error');
            }
            else{
                document.getElementsByName('password')[0].classList.remove('val-error');
                document.getElementsByName('cnfpassword')[0].classList.remove('val-error');
            }
        }
        if(formValid === 0) {
            let param = {
                "uid": getProfile().id,
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "phone": phone,
                "address": address,
                "password": cnfpassword,
                "index": ES_INDEX
            }
            this.props.profileUpdate(param)
        }
    }

    render() {


        return (

            <>

                  <div className="modal-body">

                    <div className="user-image-panel">
                        <img src={USRIMG_PATH+getProfile().image} alt="profile-image" className="user-profile-thumb" />
                        <label className="user-profile-thumb-updater">
                            {/*<input type="file" onChange={this.uploadFile} className="thumb-update-input" />*/}
                            <UploadFile getUploadedFile={this.getUploadedFile.bind(this)} progressId="progress-file-reopen"  />
                            <span className="thumb-update-text">Change Image</span>
                        </label>
                    </div>
                    <div className="user-content-panel">

                        <div className="form-group">
                            <input name="firstname" placeholder="First Name" className="border-bottom-only form-control" value={this.state.firstnamemode? this.state.firstname:getProfile().first_name} onChange={(e)=>this.controlFunc(e)}/>
                        </div>

                        <div className="form-group">
                            <input name="lastname" placeholder="Last Name" className="border-bottom-only form-control" value={this.state.lastnamemode? this.state.lastname:getProfile().last_name} onChange={(e)=>this.controlFunc(e)}/>
                        </div>

                        <div className="form-group">
                            <input name="email" placeholder="Email" className="border-bottom-only form-control" value={this.state.emailmode? this.state.email:getProfile().email} onChange={(e)=>this.controlFunc(e)}/>
                        </div>
                        <div className="form-group">
                            <input type='number' name="phone" placeholder="Phone" className="border-bottom-only form-control" value={this.state.phonemode? this.state.phone:getProfile().phone} onChange={(e)=>this.controlFunc(e)}/>
                        </div>

                        <div className="form-group">
                            <input name="address" placeholder="Address" className="border-bottom-only form-control" value={this.state.addressmode? this.state.address:getProfile().address} onChange={(e)=>this.controlFunc(e)}/>
                         </div>


                        <span className="change-password-opt" onClick={this.changePassword}>Change Password</span>
                        {this.state.passwordStatus&&
                        <>
                            <div className="form-group">
                                <input type="password" name="password" placeholder="Password" className="border-bottom-only form-control" value={this.state.password} onChange={(e)=>this.controlFunc(e)}/>
                            </div>
                            <div className="form-group">
                                <input type="password" name="cnfpassword" placeholder="Confirm Password" className="border-bottom-only form-control" value={this.state.cnfpassword} onChange={(e)=>this.controlFunc(e)}/>
                            </div>
                        </>
                        }


                    </div>
                </div>

                   <div className="modal-footer">

                    <div className="modal-footer">
                        <Button type="submit" className="general-btn grey" onClick={this.props.Close}>Cancel</Button>
                        <Button className="general-btn" onClick={()=>this.Update()}>Update</Button>
                    </div>


                </div>

            </>






        );
    }
}
export {AddTask,AddGroup,AddMembers,AddMembersToGroup,EditGroup,EditProfile,InviteUsers}


