import React, { useState, useEffect  } from 'react';
import {FRMSIMG_PATH} from "../../constants";
import NoData from "../views/NoData";
import {indianDateFormat} from "../../utilities/CustomFunctions"
import {getProfile} from "../../utilities/AuthService";
function DataTable(Props) {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const container = document.querySelector('.form-table');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        var scrollY = 0;
        var scrollX = 0;
        var doc = document.getElementById("a");
        if(doc.scrollTop  !== scrollY) {
            const container = document.querySelector('.form-table')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            if (contentHeight <= scrollTop) {
                Props.loadmoreCollectionData()
            };
        }
          setIsFetching(true);
    }

    function fetchMoreListItems() {
        Props.loadmoreCollectionData()
    }
    let showlistField = []
    if(Props.label.showformdata.length !==0) {
        for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
            (Props.label.showformdata.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden" && list.type !=="fieldset")).map(list=>{
                showlistField.push(list)

            })
        }
    }

    return (
        <div className="form-table coll-full-height fixed-header" id="a">
            {showlistField.length === 0 &&
              <div className='absolute-holder result-table-no-data'>
                <NoData
                    msg={`No Data linked to this collection`}
                />
            </div>
            }
              <table id="a" className="table data-table">
                <thead>
                <tr>
                    {/*{showlistField.length !== 0 &&*/}
                    {/*<th scope="col">*/}
                    {/*    <div className="select-chk-block" style={{display: "block"}}>*/}
                    {/*        <label className="control control-checkbox">*/}
                    {/*            <input type="checkbox" className="inp-chk"*/}
                    {/*                   onClick={Props.handleSelectAll}/>*/}
                    {/*            <div className="control-indicator"></div>*/}
                    {/*        </label>*/}
                    {/*    </div>*/}
                    {/*    <span className="share-text">SHARE</span>*/}
                    {/*</th>*/}
                    {/*}*/}

                    {showlistField.map((title, index) =>
                        <React.Fragment key={`showlist-field`+index}>
                            {/* {index ===0 &&
                            <th className={`width-less`}></th>
                            } */}
                            <th scope="col" >
                                {title.label}
                            </th>
                        </React.Fragment>
                    )}

                </tr>
                </thead>
                <tbody>
                {Props.collection.map((row, rowIndex) =>
                    <tr key={`result-table-content`+rowIndex}>
                        {/*<td key={`result-tr`+rowIndex}>*/}
                        {/*    <div className="select-chk-block" style={{display: "block"}}>*/}
                        {/*        <label className="control control-checkbox">*/}
                        {/*            <input type="checkbox" className="inp-chk"*/}
                        {/*                   checked={(Props.state.checkedRow.indexOf(row["id"]) > -1) ? "checked" : ""}*/}
                        {/*                   data-val={row['id']}*/}
                        {/*                   onClick={(e) => Props.handleSelectRow(row['id'])}/>*/}
                        {/*            <div className="control-indicator"></div>*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*    {row['users'] && Array.isArray(row['users']) && row['users'].length !== 0 &&*/}
                        {/*    <span className="ico-lib user-assigned-ico assigned_user_list"*/}
                        {/*          title="Assigned" onClick={(e)=>Props.showAssignedUsers(row['users'],row['id'])}>*/}
                        {/*        </span>*/}
                        {/*    }*/}
                        {/*    {row['users'] && Array.isArray(row['users']) && row['users'].length === 0 &&*/}
                        {/*    <span className="ico-lib user-assigned-ico assigned_user_list inactive-assign" title="Not Assigned" ></span>*/}
                        {/*    }*/}
                        {/*</td>*/}



                        {showlistField.map((title, index) => {
                            let fieldValue = row[title.masterid];
                            if( title.type === "attachment" ) {
                                fieldValue = FRMSIMG_PATH + row[title.masterid];
                            }


                            return(
                                <React.Fragment key={`edit-collection-data` + index}>

                                    <td className={`collection-detail-edit ${index === 0? "edited-field" :""}`} onDoubleClick={(e) => Props.showPreviewFormModal(e, row['id'], rowIndex)}>
                                        <div className={`form-table-edit`}>
                                            {(title.type === "attachment" || title.type === "signature") ?
                                                <div className="att-file">
                                                    {fieldValue !== '' &&
                                                    <img src={fieldValue}/>
                                                    }
                                                    {/*<span className="att-text"></span>*/}
                                                </div>
                                                :
                                                (title.type === "datepicker")?
                                                 <span className="text-overflow">{indianDateFormat(fieldValue,true)}</span>
                                                    :
                                                <span className="text-overflow">{fieldValue}</span>

                                            }



                                            {/*{index === 0 &&*/}
                                            {/*<div className="wf-btn-block">*/}
                                            {/*    /!*{((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].edit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&*!/*/}
                                            {/*    /!*<button className="ico-lib wf-ed" data-action="editcollection"*!/*/}
                                            {/*    /!*        onClick={(e) => Props.showFormModal(e, row['id'])}>*!/*/}
                                            {/*    */}
                                            {/*    /!*</button>*!/*/}
                                            {/*    /!*}*!/*/}
                                            {/*</div>*/}
                                            {/*}*/}
                                        </div>
                                    </td>

                                </React.Fragment>
                            )
                            }

                        )}

                    </tr>
                )}

                </tbody>
            </table>
        </div>
    )

}
export  {DataTable};