import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import UploadFile from '../../../utilities/Upload/UploadFile';
import AutoComplete from "../../views/AutoComplete"
import moment from 'moment';
import {SelectFieldsModal,CreateCategoryModal} from "../project/ProjectDetailData";
import {formatDate, getUrlSegment, convertUnicode, urlify} from "../../../utilities/CustomFunctions";
import LoaderSvg from "../../views/LoaderSVG";
import * as collectionActions from "../../../actions/Collections";
import * as entityActions from "../../../actions/Entity";
import * as taskActions from "../../../actions/ToDo";
import AlertModal from "../../views/AlertModal";
import {ES_INDEX} from "../../../constants";

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class AddEntityForm extends Component {

    constructor(props) {
        super(props);

        this.state={
            inheritField:'',
            collection_name:'',
            collection_description:'',
            complaincecategory:'',
            selectedEntities : [],
            selectedCollections : [],
            selectedInheritedEntities : [],
            selectedInheritCollections : [],
            inheritedformfields : [],
            showFieldsModal : false,
            collectionid:0,
            filename: null,
            uploadedFiles : '',
            selectedTags : [],
            tags: [],
            newTags:[],
            selectedFields:[],
            showCreateCategoryModal:false,
            loading:0,
            proceedModal:false,
            create: 'add',
            bookableStatus:false,
            resourceType:'',
            resourceTypeError:false,
            slot:0,
            selfService:false,
            shared_with_projectUsers:false

        };
        this.showInheritField = this.showInheritField.bind(this);
        this.getEntities = this.getEntities.bind(this);
        this.getCollections = this.getCollections.bind(this);
        this.showFieldsModal = this.showFieldsModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.createCollection = this.createCollection.bind(this);
        this.getUploadedFile = this.getUploadedFile.bind(this);
        this.removeSelectedAttachment = this.removeSelectedAttachment.bind(this);
        this.getTags = this.getTags.bind(this);
        this.showFieldsModal = this.showFieldsModal.bind(this);
        this.selectedFields = this.selectedFields.bind(this);
        this.showCreateCategoryModal = this.showCreateCategoryModal.bind(this);
        this.createCategory = this.createCategory.bind(this);
        this.showProceedmodal =  this.showProceedmodal.bind(this);
        this.hideProceedmodal = this.hideProceedmodal.bind(this);
        this.proceedAddNewForm = this.proceedAddNewForm.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleSelfCheckChange = this.handleSelfCheckChange.bind(this);
        this.sharetoProjectusers = this.sharetoProjectusers.bind(this);


    }



    componentDidMount() {
        this.setState({loading: 1})
        this.props.getCategory();
        this.getEntities();
        this.getCollections();
        this.setState({loading: 0})

        var create = this.props.create ? this.props.create : this.state.create;
        this.setState({ create : create });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta=this;
        if(prevProps.createCategoryData.categoryCreated !== this.props.createCategoryData.categoryCreated){
            let data = this.props.createCategoryData.params||{};
            this.setState({complaincecategory:data.category})
            setTimeout(function () {
                beta.props.getCategory();
            }, 1000);

        }
        if(prevProps.entity !== this.props.entity){
        let entity = this.props.entity;
        console.log(entity)
        if(entity !== undefined && entity.linkedentityinfo !== undefined){
            entity.linkedentityinfo.map(item=> { item.name = (item.collectionname ? item.collectionname.toLowerCase(): '');
                item.id = item.collectionid;
                // item.user_type = item.category;
                return item;});
            entity.linkedcollectioninfo.map(items=> { items.title = (items.collectionname ? items.collectionname.toLowerCase(): '');
                items._id = items.collectionid;
                // item.user_type = item.category;
                return items;});
            ////////console.log(entity)
            this.setState({
                collection_name: decodeURIComponent(escape(atob(entity.name))),
                collection_description : decodeURIComponent(escape(atob(entity.description)) ),
                complaincecategory : entity.category,
                inheritField : entity.inheritancetype,
                selectedEntities : entity.linkedentityinfo,
                selectedCollections : entity.linkedcollectioninfo,
                inheritedformfields : entity.inheritedfields,
                bookableStatus: entity.bookable ? entity.bookable:false,
                resourceType:entity.resource_type ? entity.resource_type:'',
                slot:entity.slot?(entity.slot === 0.5 ? '.5':entity.slot):0,
                selfService:entity.selservice?entity.selservice:false,
                shared_with_projectUsers:entity.shared_with_projectUsers?entity.shared_with_projectUsers:false

            });
            let inheritancetype = entity.inheritancetype;
            if(inheritancetype) {
                ////////console.log(inheritancetype)
                if (inheritancetype === 'collection') {
                    entity.inheritanceinfo.map(items => {
                        items.title = (items.collectionname ? items.collectionname.toLowerCase() : '');
                        items._id = items.collectionid;
                        return items;
                    });
                    this.setState({
                        selectedInheritCollections: entity.inheritanceinfo
                    });
                }if (inheritancetype === 'entity') {
                    entity.inheritanceinfo.map(item => {
                        item.name = (item.collectionname ? item.collectionname.toLowerCase() : '');
                        item.id = item.collectionid;
                        return item;
                    });
                    this.setState({
                        selectedInheritedEntities: entity.inheritanceinfo
                    });
                }
            }
        }
        }

    }


    handleInputChange(e) {

            this.setState({[e.target.name]: e.target.value})

        if (e.target.name === "complaincecategory") {

            if (e.target.value !== '' || e.target.value !== 'select') {
                e.target.classList.remove('val-error');
            }
        } else {
            if (e.target.value !== '') {
                e.target.classList.remove('val-error');
            }
        }
        if (e.target.value !== '') {
            if(e.target.name === 'resourceType'){
                this.setState({resourceTypeError:false})
            }
        }

    }


    resultDataList( result, reference) {
        //////////////console.log(result);
        this.setState({ [reference] : result });
    }


    showInheritField(field){
        this.setState({inheritField:field})
    }

    getEntities(searchtext) {
        // this.props.getEntity({uid : getProfile().id, usertype : getProfile().usertype, searchtext : searchtext?searchtext:'', page :1, per_page : 100 });
        let param = {uid : getProfile().id, usertype : getProfile().usertype, searchtext : searchtext? searchtext : '', page: 1, per_page: 50, index: ES_INDEX,  managecollection : ''};
        this.props.getEntity(param);
    }

    getCollections(search) {
        // let params = {group : 'All', search :search ? search : '', uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : 0, offset : 100};
        // this.props.collections(params);
        let params = {
            group : 'All',
            search : search ? search : '',
            uid : getProfile().id,
            usertype : getProfile().usertype,
            managecollection : '',
            limit : 0,
            offset : 100
        };
        this.props.collections(params);
    }

    showFieldsModal(action){
        this.setState({showFieldsModal : !this.state.showFieldsModal})
        if(this.state.inheritField === "entity"){
            var selectedInheritedEntities = this.state.selectedInheritedEntities;
            var InheritedEntities = [];
            if (selectedInheritedEntities.length > 0) {
                for (var i = 0; i < selectedInheritedEntities.length; i++) {
                    InheritedEntities.push(selectedInheritedEntities[i].id);
                }
            }
            var id = InheritedEntities ? InheritedEntities[0] : '';
        }
        else{
            var selectedInheritCollections = this.state.selectedInheritCollections;
            var InheritCollections = [];
            if (selectedInheritCollections.length > 0) {
                for (var i = 0; i < selectedInheritCollections.length; i++) {
                    //  InheritCollections = (InheritCollections !== '') ? (InheritCollections + ",") : "";
                    InheritCollections.push(selectedInheritCollections[i]._id);
                }
            }
            var id = InheritCollections ? InheritCollections[0] : '';
        }

        if(action === 'open') {
            this.props.getFormFields({_id : id});
        }
    }

    getUploadedFile(uploadedFile) {

        // let uploadedFiles = [... this.state.uploadedFiles];

        // uploadedFiles.push(uploadedFile);
        // let uploadedFiles = [uploadedFile];
        this.setState({
            filename: uploadedFile.fileName,
            uploadedFiles : uploadedFile
        });
        ////////////console.log(this.state.uploadedFiles);


    }

    removeSelectedAttachment() {
        this.setState({uploadedFiles: ''});
    }

    getTags(searchText){
        let data={
            "search": searchText? searchText : ''
        }
        this.props.tagData(data);
    }

    selectedFields(fields){

        this.setState({selectedFields:fields})

    }

    createCategory(data){
        this.props.createCategory(data);

    }

    showProceedmodal(){
        this.setState({proceedModal : true})
    }

    hideProceedmodal(){
        this.setState({proceedModal : false})
    }

    createCollection(e){
        e.preventDefault();

        let formValid = 0;

        if (this.state.collection_name === '') {
            formValid++;
            document.getElementsByName('collection_name')[0].classList.add('val-error');
        }
        if (this.state.collection_description === '') {
            formValid++;
            document.getElementsByName('collection_description')[0].classList.add('val-error');
        }
        if (this.state.bookableStatus === true) {
            if(this.state.resourceType === ''){
                formValid++;
                this.setState({resourceTypeError:true})
                document.getElementsByName('resourceType')[0].classList.add('val-error');
            }
            if(this.state.resourceType !== '' && parseInt(this.state.slot) === 0){
                formValid++;
                document.getElementsByName('slot')[0].classList.add('val-error');
            }

        }

        if(formValid === 0){
            this.showProceedmodal();
        }

    }

    proceedAddNewForm(){
////////console.log(this.state.selectedFields)
        if(getUrlSegment(4)){
            var projectid = (atob(getUrlSegment(4)));
        }
        var collectionid = this.props.entityid ? this.props.entityid : moment().valueOf() ;
        var complaincecategory = this.state.complaincecategory;
        var collectionname = this.state.collection_name;
        var collectiondescription = this.state.collection_description;
        var mode = (this.state.create === "update"? "update":"save");
        let uploadedFiles = this.state.uploadedFiles;
        var inheritField = this.state.inheritField;
        var selectedEntities = this.state.selectedEntities;
        var Entities = [];
        var selectedCollections = this.state.selectedCollections;
        var Collections = [];
        var selectedInheritedEntities = this.state.selectedInheritedEntities;
        var InheritedEntities = [];
        var selectedInheritCollections = this.state.selectedInheritCollections;
        var InheritCollections = [];

        if (selectedEntities.length > 0) {
            for (var i = 0; i < selectedEntities.length; i++) {
                // Entities = (Entities !== '') ? (Entities + ",") : '';
                Entities.push(selectedEntities[i].id);
            }
        }
        if (selectedCollections.length > 0) {
            for (var i = 0; i < selectedCollections.length; i++) {
                // Collections = (Collections !== '') ? (Collections + ",") : "";
                Collections.push(selectedCollections[i]._id);
            }
        }
        if (selectedInheritedEntities.length > 0) {
            for (var i = 0; i < selectedInheritedEntities.length; i++) {
                // InheritedEntities = (InheritedEntities !== '') ? (InheritedEntities + ",") : "";
                InheritedEntities.push(selectedInheritedEntities[i].id);
            }
        }
        if (selectedInheritCollections.length > 0) {
            for (var i = 0; i < selectedInheritCollections.length; i++) {
                //  InheritCollections = (InheritCollections !== '') ? (InheritCollections + ",") : "";
                InheritCollections.push(selectedInheritCollections[i]._id);
            }
        }
        var inheritanceItem = (inheritField === "entity" ? InheritedEntities : InheritCollections);

        var tagids = '';
        var tagnames = '';
        var selectedTags = this.state.selectedTags;
        if (selectedTags.length > 0) {
            for (var i = 0; i < selectedTags.length; i++) {
                tagids = (tagids !== '') ? (tagids + ",") : "";
                if( selectedTags[i].id !== '') {
                    tagids = (tagids !== '') ? (tagids + ",") : "";
                    tagids += selectedTags[i].id;
                }
                else {
                    tagnames = (tagnames !== '') ? (tagnames + ",") : "";
                    tagnames += selectedTags[i].tag_name;
                }

            }
        }
        var fileNameArr = uploadedFiles ? uploadedFiles.fileName.split('/'):'';
        var fileName = fileNameArr? fileNameArr.pop():'';
        var collectiondata ={
            "uid": getProfile().id,
            "_id": collectionid,
            "name": collectionname,
            "description": collectiondescription,
            "mode": mode,
            "index": ES_INDEX,
            "category": complaincecategory,
            // "subname": "",
            // "filetitle": uploadedFiles ? uploadedFiles.originalfileName : '',
            // "filename": fileName,
            // "filetype": uploadedFiles ? uploadedFiles.fileType:'',
            // "filesize": uploadedFiles ? uploadedFiles.fileSize:'',
            // "linknode": Collections,
            "linkentity": Entities,
            "linkcollection": Collections,
            // "tagsid": tagids,
            // "tagsname": tagnames,
            "inheritancetype": inheritField,
            "inheritanceitem": inheritanceItem,
            "inheritedfields": this.state.selectedFields,
            "projectid": projectid,
            "slot":this.state.slot,
            "bookable":this.state.bookableStatus,
            "resource_type":this.state.resourceType,
            "selservice":this.state.selfService,
            "type":this.state.bookableStatus ? "booking" : 'entity',
            "shared_with_projectUsers":this.state.shared_with_projectUsers
        }
////////console.log(collectiondata)
        this.props.saveEntity(collectiondata)

    }

    showCreateCategoryModal(){
        this.setState({showCreateCategoryModal:!this.state.showCreateCategoryModal})
    }
    handleCheckChange(e){
        this.setState({bookableStatus:!this.state.bookableStatus},
            function(){
                if(this.state.bookableStatus === false){
                    this.setState({
                        resourceType:'',
                        slot:0,
                    })
                }
            }
            )

    }
    handleSelfCheckChange(e){
        this.setState({selfService:!this.state.selfService})
    }
    sharetoProjectusers(e){
        this.setState({shared_with_projectUsers:!this.state.shared_with_projectUsers})
    }
    render() {
        let categorylist = this.props.categorydata.categoryList;
        var fromproject = false;
        let formInfo = this.props.formInfo.showformdata ? this.props.formInfo.showformdata.pages : [];
        if(getUrlSegment(4)){
             fromproject =true;
             //fromproject = false;
        }
        return (
            <div className="task-content-block">
                <div className="persitant-top">
                    {(this.state.loading === 1 || this.props.categorydata.loading || this.props.entitydata.loading ) &&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                    }


                    <div className="task-input-block">
                        <input type="text" placeholder="Name" name="collection_name" onChange={this.handleInputChange}  className="task-input-box"  value={this.state.collection_name}/>
                    </div>

                    <div className="new-task-input text-input">
                        <textarea className="task-textarea" name="collection_description" onChange={this.handleInputChange}  placeholder="Description" value={this.state.collection_description}></textarea>
                    </div>

                    <div className="task-input-container mb-15">
                        <div className="select-box">
                            <select id="complaincecategory" name="complaincecategory" onChange={this.handleInputChange} value={this.state.complaincecategory}>
                                <option value="select">Category</option>
                                {categorylist && categorylist.map((list,index) =>
                                    <option value={list._source.categoryValue} key={"list-"+index}>{list._source.categoryName}</option>
                                )}
                            </select>
                        </div>
                        <div className="append-icn-area">
                            <span className="ico-lib cat-add" data-toggle="modal" data-target="#addcategory" onClick={()=>this.showCreateCategoryModal()}></span>
                        </div>
                    </div>

                    <div className="task-inp-container" id="linkentity">
                        <div className="ind-task-input">

                            <AutoComplete
                                fieldTitle = 'Link To Entity'
                                sourceDataList = {this.props.entitydata.entityList}
                                searchField = 'name'
                                showIcon={true}
                                showSubLabel = {true}
                                labelFields = 'name'
                                valueField ='id'
                                subLabelField = ''
                                showProfile = {true}
                                reference='selectedEntities'
                                selectedItems = {this.state.selectedEntities}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="to_users"
                                instantSearch = {true}
                                actionMethod = {this.getEntities}
                                preventInitialApiCall = {false}
                            />
                        </div>
                    </div>

                    <div className="task-inp-container" id="linkcategory">
                        <div className="ind-task-input">

                            <AutoComplete
                                fieldTitle = 'Link To Collection'
                                sourceDataList = {this.props.listAllCollections.collectionsubgroup}
                                searchField = 'title'
                                showIcon={true}
                                showSubLabel = {true}
                                labelFields = 'title'
                                valueField ='_id'
                                subLabelField = ''
                                showProfile = {true}
                                reference='selectedCollections'
                                selectedItems = {this.state.selectedCollections}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="to_users"
                                instantSearch = {true}
                                actionMethod = {this.getCollections}
                                preventInitialApiCall = {true}
                            />

                        </div>
                    </div>

                    {/*<div className="block-separator">*/}
                    {/*    <label className="tag-block-separator">Inherit</label>*/}
                    {/*    <div className="ind-panel-options radio-wrapper">*/}
                    {/*        <div className="radio-block" >*/}
                    {/*            <input type="radio" name="fieldinherit" id="filed_entity" onClick={()=>this.showInheritField("entity")} checked={this.state.inheritField === 'entity' ? true : false}/>*/}
                    {/*            <label>INHERIT ENTITY</label>*/}
                    {/*        </div>*/}
                    {/*        <div className="radio-block" >*/}
                    {/*            <input type="radio" name="fieldinherit" id="filed_collection"  onClick={()=>this.showInheritField("collection")} checked={this.state.inheritField === 'collection' ? true : false}/>*/}
                    {/*            <label>INHERIT COLLECTION</label>*/}
                    {/*        </div>*/}
                    {/*        <div className="radio-block" >*/}
                    {/*            <input type="radio" name="fieldinherit"  id="filed_none"  onClick={()=>this.showInheritField("none")} checked={this.state.inheritField === '' ? true : false}/>*/}
                    {/*            <label>NONE</label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {this.state.inheritField === "entity" &&
                    <div className="task-inp-container">
                        <div className="ind-task-input">
                            <AutoComplete
                                fieldTitle='Search Entity'
                                sourceDataList={this.props.entitydata.entityList}
                                searchField='name'
                                showIcon={true}
                                showSubLabel={true}
                                labelFields='name'
                                valueField='id'
                                subLabelField=''
                                showProfile={true}
                                reference='selectedInheritedEntities'
                                selectedItems={this.state.selectedInheritedEntities}
                                resultDataList={this.resultDataList.bind(this)}
                                id="to_users"
                                instantSearch={true}
                                actionMethod={this.getEntities}
                                preventInitialApiCall = {true}
                            />
                        </div>
                    </div>
                    }
                    {this.state.inheritField === "collection" &&
                    <div className="task-inp-container">
                        <div className="ind-task-input">
                            <AutoComplete
                                fieldTitle='Search Collection'
                                sourceDataList={this.props.listAllCollections.collectionsubgroup}
                                searchField='title'
                                showIcon={true}
                                showSubLabel={true}
                                labelFields='title'
                                valueField='_id'
                                subLabelField=''
                                showProfile={true}
                                reference='selectedInheritCollections'
                                selectedItems={this.state.selectedInheritCollections}
                                resultDataList={this.resultDataList.bind(this)}
                                id="to_users"
                                instantSearch={true}
                                actionMethod={this.getCollections}
                                preventInitialApiCall = {true}
                            />

                        </div>
                    </div>
                    }
                    {(this.state.selectedInheritCollections.length > 0 || this.state.selectedInheritedEntities.length > 0) &&
                    < div className="group-row" id="inheritfields" >
                        <span onClick={()=> this.showFieldsModal('open')}><h5>Choose fields </h5></span>
                    </div>
                    }

                     <div className="entity-add-panel">
                        <div className="select-chk-block task-select " style={{display: "block"}}>
                            <label className="control control-checkbox" data-action="organise">Is this entity as bookable or not?
                                <input type="checkbox" className="inp-chk"
                                    name="bookable" checked={this.state.bookableStatus === true} value={this.state.bookableStatus} onChange={(e) => this.handleCheckChange(e)}/>
                                <div className="control-indicator"></div>
                            </label>
                        </div>
                        {this.state.bookableStatus &&
                        <>
                            <div className="switch-mode entity-switch radio-wrapper">
                                <div className={`radio-block ${this.state.resourceType === 'venue' ? 'active':''}`}>
                                    <input type="radio" name="resourceType" id="venue" defaultValue="venue"
                                        defaultChecked={this.state.resourceType === 'venue'} onClick={(e)=>this.handleInputChange(e)}/><label>Venue</label>
                                </div>
                                <div className={`radio-block ${this.state.resourceType === 'role' ? 'active':''}`}>
                                    <input type="radio" name="resourceType" id="role" defaultValue="role"
                                           defaultChecked={this.state.resourceType === 'role'} onClick={(e)=>this.handleInputChange(e)}/><label>Role</label>
                                </div>
                                <div className={`radio-block ${this.state.resourceType === 'asset' ? 'active':''}`}>
                                    <input type="radio" name="resourceType" id="asset" defaultValue="asset"
                                           defaultChecked={this.state.resourceType === 'asset'} onClick={(e)=>this.handleInputChange(e)}/><label>Asset</label>
                                </div>
                            </div>
                            <label className = "assign-user-validation-err" style={{display: this.state.resourceTypeError === false ? 'none' : 'block' }}>Please select Venue , Role or Asset.</label>
                        </>
                        }
                        {this.state.resourceType !== '' &&
                        
                                <div className="select-box">
                                    <select id="slot" name="slot" onChange={this.handleInputChange} value={this.state.slot}>
                                        <option value={0}>Select Slot</option>
                                        <option value={'.5'}>30 Minute</option>
                                        <option value={'1'}>1 Hour</option>
                                        <option value={'2'}>2 Hour</option>
                                        <option value={'24'}>1 Day</option>

                                    </select>
                                </div>
                        }
                        <div className="select-chk-block task-select " style={{display: "block"}}>
                            <label className="control control-checkbox" data-action="organise">Enable the entity as self service?
                                <input type="checkbox" className="inp-chk"
                                    name="selfService" checked={this.state.selfService === true}  value={this.state.selfService} onChange={(e) => this.handleSelfCheckChange(e,this.state.selfService)}/>
                                <div className="control-indicator"></div>
                            </label>
                        </div>
                         <div className="select-chk-block task-select " style={{display: fromproject === false ? 'none' : 'block' }}>
                             <label className="control control-checkbox" data-action="organise">Do you want to share this entity with project users?
                                 <input type="checkbox" className="inp-chk"
                                        name="shared_with_projectUsers" checked={this.state.shared_with_projectUsers === true}  value={this.state.shared_with_projectUsers} onChange={(e) => this.sharetoProjectusers(e,this.state.shared_with_projectUsers)}/>
                                 <div className="control-indicator"></div>
                             </label>
                         </div>
                    </div>



                    {/*<div className="task-inp-container">*/}
                    {/*    <AutoComplete*/}
                    {/*        eleClass = 'selectize-custom'*/}
                    {/*        fieldTitle = 'Search Collection'*/}
                    {/*        hideTitle = {false}*/}
                    {/*        sourceDataList = {this.props.projects.tagData}*/}
                    {/*        searchField = 'tag_name'*/}
                    {/*        showIcon={false}*/}
                    {/*        hideMainLabel = {true}*/}
                    {/*        showSubLabel = {true}*/}
                    {/*        labelFields = 'tag_name'*/}
                    {/*        valueField ='id'*/}
                    {/*        subLabelField = 'tag_name'*/}
                    {/*        showProfile = {false}*/}
                    {/*        reference='selectedTags'*/}
                    {/*        selectedItems = {this.state.selectedTags}*/}
                    {/*        resultDataList = {this.resultDataList.bind(this)}*/}
                    {/*        addNewSuggestion = {true}*/}
                    {/*        id="tags"*/}
                    {/*        actionMethod = {this.getTags}*/}
                    {/*    />*/}
                    {/*</div>*/}

                    <div className="attachment-task-group" id="todoFileAssets">
                        { this.state.uploadedFiles !== "" &&
                        <div className="ind-attach-list file" id={"attachment_"+this.state.uploadedFiles.fileId} key={"attachment_"+this.state.uploadedFiles.fileId}>
                            <h5>{this.state.uploadedFiles.originalfileName}</h5>
                            <button className="ico-lib cl-fl" data-parent={this.state.uploadedFiles.fileId}
                                    data-target="#delete-attachfile" data-action="unselectattachfiles" onClick={(e)=>this.removeSelectedAttachment()}></button>
                        </div>
                        }


                    </div>
                </div>
                <div className="persitant-footer">
                    <div className="group-row">
                        {/*<h5>Upload cover Photo</h5>*/}
                        {/*<ul className="attach-lister">*/}
                        {/*    <li>*/}
                        {/*        <button className="ico-lib attach-at">*/}
                        {/*            <UploadFile  getUploadedFile={this.getUploadedFile.bind(this)} />*/}
                        {/*        </button>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                    </div>
                    <button data-reference="" data-function="validate-collection" type="button" id="createMain" className="general-btn" onClick={this.createCollection}>{this.state.create === 'update' ? 'Update' : 'Create'}</button>
                </div>

                {this.state.showFieldsModal &&
                <SelectFieldsModal
                    formInfo={formInfo}
                    showFieldsModal={this.showFieldsModal}
                    getselectedFields={this.selectedFields}
                    loaderdata={this.props.formInfo.loading}
                    selectedFields={this.state.inheritedformfields}
                />
                }
                {this.state.showCreateCategoryModal &&
                <CreateCategoryModal
                    showCreateCategoryModal = {this.showCreateCategoryModal}
                    addCategory ={this.addCategory}
                    createCategory={this.createCategory}
                />
                }
                {this.state.proceedModal &&
                <AlertModal id="fill-metadata"
                            title={this.state.create === 'update' ? 'Ready to update entity?' : "Ready to create entity?"}
                            OK_button_text="Yes"
                            OK_action={() => this.proceedAddNewForm()}
                            showModal = {this.state.proceedModal}
                            CANCEL_action={() => this.hideProceedmodal()}
                />
                }
            </div>

        );
    }
}


const mapStateToProps = state => ({
    categorydata: state.EntityReducer.CategoryList,
    entitydata : state.EntityReducer.EntityList,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    formInfo : state.EntityReducer.showFormModal,
    listtags:state.TaskReducer.ListAllTags,
    projects:state.TaskReducer.GetProjectData,
    createCategoryData:state.EntityReducer.categoryCreated
});
export default  withRouter(connect(mapStateToProps, {
    collections: collectionActions.listTodoCollections,
    getCategory : entityActions.getCategory,
    getEntity : entityActions.searchEntity,
    getFormFields : entityActions.showform,
    saveEntity : entityActions.createEntity,
    tagData : taskActions.getTagData,
    createCategory : entityActions.createCategory,
})(AddEntityForm));



