import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getProfile} from "../../utilities/AuthService";
import {ES_INDEX, SHARED_LOGIN, USRIMG_PATH} from '../../constants/index'

import LoaderSvg from "../views/LoaderSVG"

import {
    getSubdomain,
    getUrlSegment,
    base64encode,
    getFormComponentList,
    getFieldOptions,
    buildFieldJsonFromForm
} from "../../utilities/CustomFunctions";

import {debounce} from 'throttle-debounce';
import * as SchedulerActions from "../../actions/Scheduler";
import './frame.css';
import AutoComplete from "../views/AutoComplete";

let TrovaUserId = '';

class CallModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalstatus: false,
            trovaUserDetails: [],
            iframeurl: "eyJlbWFpbCI6InJhaHVsQHRyb3ZhLmluIiwicGFzc3dvcmQiOiJQYXNzd29yZEAxMjMifQ==",
            customer_name: '',
            customer_email: '',
            customer_mobile: '',
            selectedStates: [],
            selectedCities: [],
            state_options: [],
            city_options: [],
            lead_options: [],
            leadFormId: '1641960182801',
            enquiryFormId: '2_207157244562',
            formFieldValues: [],
            leadRecordId: '164261389040460',
            enquiryRecordId: '164261389063142',
            closeTrovaProfile: false
        }
    }

    componentDidMount() {

        if (ES_INDEX === 'tata') {
            this.props.getUserPassword({uid: getProfile().id});
            // this.props.getTrovaUserInfo({uid: '1642836633901'});
            this.props.getFormJson({_id: this.state.enquiryFormId}, 'internal')
            this.props.getFormJson({_id: this.state.leadFormId}, 'internal')

            this.getStateList();
            //this.getCityList();
            this.getLeadList("");
        }


        window.addEventListener("message", this.receiveTrovaId, false);

    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.GetCallData.userdata !== prevProps.GetCallData.userdata) {
            console.log(this.props.GetCallData.userdata)
            this.setState({
                trovaUserDetails: this.props.GetCallData.userdata
            })
        }

        if (this.props.GetCallData.user_pass !== prevProps.GetCallData.user_pass) {
            let user_pass = this.props.GetCallData.user_pass;
            if (user_pass !== '') {
                let iframeJson = '{"email":"' + getProfile().email + '","password":"' + user_pass + '"}';
                console.log(iframeJson)
                this.setState({
                    iframeurl: base64encode(iframeJson)

                }, function () {
                    console.log(this.state.iframeurl)
                })
            }
        }

        if (this.props.GetCallData.userdata !== prevProps.GetCallData.userdata) {
            console.log(this.props.GetCallData.userdata)
            let trovaUserDetails = this.props.GetCallData.userdata || {};
            let formFieldValues = this.state.formFieldValues || [];


            /***************Enquiry Form*************/

            this.getLeadList(trovaUserDetails.name)

            let selectedStates = trovaUserDetails.lead_state ? trovaUserDetails.lead_state : [];
            let selectedCities = trovaUserDetails.lead_city ? trovaUserDetails.lead_city : [];

            if (selectedStates.length > 0 && selectedStates[0].value === '') {
                selectedStates = [];
            } else if (selectedStates.length > 0 && selectedStates[0].value !== '') {
                this.getCityList(selectedStates[0].value)
            }
            if (selectedCities.length > 0 && selectedCities[0].value === '') {
                selectedCities = [];
            }


            formFieldValues["911236364"] = trovaUserDetails.lead_name ? trovaUserDetails.lead_name : [];

            formFieldValues["1641960947402"] = trovaUserDetails.phone ? trovaUserDetails.phone : '';
            formFieldValues["1151381852"] = selectedStates; //selected states
            formFieldValues["599124938"] = selectedCities; //selected city
            formFieldValues["1515299780"] = trovaUserDetails.pincode ? trovaUserDetails.pincode : '';
            formFieldValues["1366255631"] = trovaUserDetails.model ? trovaUserDetails.model : '';
            formFieldValues["1274571671"] = trovaUserDetails.variant ? trovaUserDetails.variant : '';
            formFieldValues["1418731066"] = trovaUserDetails.ppl ? trovaUserDetails.ppl : '';
            formFieldValues["111664306"] = trovaUserDetails.call_disposition ? trovaUserDetails.call_disposition : '';
            formFieldValues["1301363726"] = trovaUserDetails.remarks ? trovaUserDetails.remarks : '';


            /*************************Lead form ******************/
            formFieldValues["1433231961"] = trovaUserDetails.name ? trovaUserDetails.name : '';
            formFieldValues["1437331529"] = trovaUserDetails.email ? trovaUserDetails.email : '';
            formFieldValues["115577395"] = trovaUserDetails.phone ? trovaUserDetails.phone : '';
            formFieldValues["1464278927"] = trovaUserDetails.pincode ? trovaUserDetails.pincode : '';

            //formFieldValues["112184734"] = trovaUserDetails.model ? trovaUserDetails.model : '';
            //formFieldValues["1328496730"] = trovaUserDetails.variant ? trovaUserDetails.variant : '';
            //formFieldValues["448333085"] = trovaUserDetails.ppl ? trovaUserDetails.ppl : '';
            // formFieldValues["652747531"] = trovaUserDetails.call_disposition ? trovaUserDetails.call_disposition : '';
            // formFieldValues["527295201"] = trovaUserDetails.final_call_disposition ? trovaUserDetails.final_call_disposition : '';
            //formFieldValues["1251194166"] = trovaUserDetails.remarks ? trovaUserDetails.remarks : '';

            console.log(this.state.state_options)
            console.log(this.state.city_options)


            formFieldValues["716634994"] = selectedStates;
            formFieldValues["1476143713"] = selectedCities;


            this.setState({
                trovaUserDetails: trovaUserDetails,
                customer_lead_id: trovaUserDetails.Leadid ? trovaUserDetails.Leadid : '',
                customer_name: formFieldValues['1433231961'],
                customer_email: formFieldValues['1437331529'],
                customer_mobile: formFieldValues['1641960947402'],
                customer_state: formFieldValues['1151381852'],
                customer_city: formFieldValues['599124938'],
                customer_pincode: formFieldValues['1515299780'],
                customer_model: formFieldValues['1366255631'],
                customer_variant: formFieldValues['1274571671'],
                customer_ppl: formFieldValues['1418731066'],
                call_disposition: formFieldValues['111664306'],
                //final_call_disposition: formFieldValues['527295201'],
                customer_remarks: formFieldValues['1301363726'],
                formFieldValues: formFieldValues,
                selectedStates: selectedStates,
                selectedCities: selectedCities,
                leadRecordId: trovaUserDetails.leadrid ? trovaUserDetails.leadrid : '',
                enquiryRecordId: trovaUserDetails.enquiryrid ? trovaUserDetails.enquiryrid : '',

            })
        }

        if (this.props.GetProfileData.stateList !== prevProps.GetProfileData.stateList) {
            console.log(this.props.GetProfileData.stateList)
            this.setState({
                state_options: this.props.GetProfileData.stateList
            })
        }
        if (this.props.GetProfileData.cityList !== prevProps.GetProfileData.cityList) {
            console.log(this.props.GetProfileData.cityList)
            this.setState({
                city_options: this.props.GetProfileData.cityList
            })
        }
        if (this.props.GetProfileData.leadList !== prevProps.GetProfileData.leadList) {
            console.log(this.props.GetProfileData.leadList)
            this.setState({
                lead_options: this.props.GetProfileData.leadList
            })
        }

        if (this.props.GetProfileData.usersaved !== prevProps.GetProfileData.usersaved) {
            if (this.state.closeTrovaProfile) {

            }
        }

        if (this.props.GetFormJsonData !== prevProps.GetFormJsonData) {
            // if (this.props.formDetails.formValues !== prevProps.formDetails.formValues) {
            //     this.getLeadScore();
            // }
            console.log(this.props.GetFormJsonData.formJson)
            console.log(this.props.GetFormJsonData.formJson[this.state.leadFormId])

            let leadFormJson = this.props.GetFormJsonData.formJson[this.state.leadFormId.toString()];
            let enquiryFormJson = this.props.GetFormJsonData.formJson[this.state.enquiryFormId];

            console.log(leadFormJson)
            console.log(enquiryFormJson)

            let leadComponentList = getFormComponentList(leadFormJson);
            let enquiryComponentList = getFormComponentList(enquiryFormJson);

            console.log(leadComponentList)
            let modelOptions = [];
            let variantOptions = [];
            let pplOptions = [];
            let disOptions = [];
            //let finDisOptions = [];


            if (leadComponentList.length > 0) {
                //Model Options
                modelOptions = getFieldOptions(enquiryComponentList, "1366255631");
                console.log(modelOptions)

                //Variant Options
                variantOptions = getFieldOptions(enquiryComponentList, "1274571671");
                console.log(variantOptions)


                //Color Options
                pplOptions = getFieldOptions(enquiryComponentList, "1418731066");
                console.log(pplOptions)

                //Disposition Options
                disOptions = getFieldOptions(enquiryComponentList, "111664306");
                console.log(disOptions)

                // //Final disposition Options
                // finDisOptions = getFieldOptions(leadComponentList, "527295201");
                // console.log(finDisOptions)
            }


            this.setState({
                leadComponentList: leadComponentList,
                enquiryComponentList: enquiryComponentList,
                modelOptions: modelOptions,
                variantOptions: variantOptions,
                pplOptions: pplOptions,
                disOptions: disOptions,
                //finDisOptions: finDisOptions,
            })

        }
    }


    showCallScheduler = (status) => {
        this.setState({
            showSchedulerStatus: status
        })
    }

    receiveTrovaId = (event) => {
        // console.log("TrovaUserId");
        // console.log(event)
        TrovaUserId = event.data.userId;

        if (TrovaUserId && TrovaUserId !== '') {
            TrovaUserId = TrovaUserId.replace('u', '');
            this.props.getTrovaUserInfo({uid: TrovaUserId})
            this.props.updateCallStatus({
                "cid": ES_INDEX === 'tata' ? "2_207157244562" : '2_601123469100',
                "index": ES_INDEX,
                "uniqueid": TrovaUserId
            });
        }

    }

    getStateList = (search) => {

        let params = {
            "masterid": "716634994",
            "collection": "1642136374973",
            "field": "1404894186",
            "displayfields": "",
            "additionalvalues": "",
            "filterfield": [],
            "search": search ? search : '',
            "size": 500
        };
        this.props.getStateList(params, 'dropdown', '1642136374973');
    }
    getCityList = (search) => {
        let params = {
            "masterid": "1476143713",
            "collection": "1642136393628",
            "field": "1359423193",
            "displayfields": "",
            "additionalvalues": "",
            "filterfield": [],
            "search": search ? search : '',
            "size": 500
        };
        this.props.getCityList(params, 'dropdown', '1642136393628');
    }

    getLeadList = (search) => {
        let params = {
            "masterid": "911236364",
            "collection": "1641960182801",
            "field": "1433231961",
            "displayfields": "",
            "additionalvalues": "",
            "filterfield": [],
            "search": search,
            "size": 500
        };
        this.props.getLeadList(params, 'dropdown', '1641960182801');
    }

    resultDataList = (result, reference) => {
        let formFieldValues = this.state.formFieldValues || [];
        let masterid = '';
        let masterid2 = '';
        if (reference === 'selectedStates') {
            console.log(result)
            masterid = '716634994';
            masterid2 = '1151381852';
        } else if (reference === 'selectedCities') {
            masterid = '1476143713';
            masterid2 = '599124938';
        }

        let selectedCities = this.state.selectedCities;
        if (result.length > 0) {
            formFieldValues[masterid] = result;
            formFieldValues[masterid2] = result;
            if (reference === 'selectedStates') {
                selectedCities = [];
                formFieldValues['1476143713'] = [];
                this.getCityList(result[0].value);
            }
        }


        this.setState({
            [masterid]: result,
            [reference]: result,
            formFieldValues: formFieldValues,
            selectedCities: selectedCities
        })
    }


    setFormFieldValue = (e, masterids) => {
        let formFieldValues = this.state.formFieldValues || [];

        if (masterids.length > 0) {
            for (var i = 0; i < masterids.length; i++) {
                if (masterids[i] !== '911236364') {
                    formFieldValues[masterids[i]] = e.target.value;
                }

                if (masterids[i] === '911236364') { //Enquiry name field

                    let this_field_value = formFieldValues['911236364'] || [];
                    if (this_field_value.length > 0) {
                        this_field_value[0].value = e.target.value;
                    }
                    formFieldValues['911236364'] = this_field_value;
                }
            }
        }


        this.setState({
            //[masterid]: e.target.value,
            [e.target.name]: e.target.value,
            formFieldValues: formFieldValues
        })
    }

    trovaUserUpdate = (status) => {

        this.setState({
            closeTrovaProfile: status
        })
        // console.log(this.state.state_options)
        // console.log(this.state.city_options)


        let leadfieldIdsTobeUpdated = ["1433231961", "115577395", "1464278927", "716634994", "1476143713"];
        let enqfieldIdsTobeUpdated = ["911236364", "1641960947402", "1151381852", "599124938", "1515299780", "1366255631", "1274571671", "1418731066", "111664306", "1301363726"];

        let leadComponentList = this.state.leadComponentList;
        let formFieldValues = this.state.formFieldValues;


        let enquiryComponentList = this.state.enquiryComponentList;
        let enqfieldsJson = buildFieldJsonFromForm(enquiryComponentList, enqfieldIdsTobeUpdated, formFieldValues);

        let enqparams = {
            "uid": getProfile().id,
            "collectionid": this.state.enquiryFormId,
            "recordid": this.state.enquiryRecordId,
            "fields": enqfieldsJson
        }

        let leadfieldsJson = buildFieldJsonFromForm(leadComponentList, leadfieldIdsTobeUpdated, formFieldValues);

        console.log(enqfieldsJson);
        console.log(leadfieldsJson);
        //return false;
        let leadparams = {
            "uid": getProfile().id,
            "collectionid": this.state.leadFormId,
            "recordid": this.state.leadRecordId,
            "fields": leadfieldsJson
        }


        this.props.saveTrovaUserProfile(enqparams, leadparams);
    }

    render() {
        let subdomain = getSubdomain(window.location.hostname);

        let trovaUserDetails = this.state.trovaUserDetails;

        let modelOptions = this.state.modelOptions || [];
        let variantOptions = this.state.variantOptions || [];
        let pplOptions = this.state.pplOptions || [];
        let disOptions = this.state.disOptions || [];
        //let finDisOptions = this.state.finDisOptions || [];


        let state_options = this.state.state_options;
        let city_options = this.state.city_options;

        let formFieldValues = this.state.formFieldValues || [];


        let selectedStates = formFieldValues['716634994'] || [];
        let selectedCities = formFieldValues['1476143713'] || [];

        // console.log(selectedStates)
        // console.log(selectedCities)


        return (
            <>

                <div className={`schedule-call-frame ${this.props.showSchedulerStatus ? ' active' : ''}`}>
                    <div className='schedule-call-header'>
                        <h5></h5>
                        <div className='schedule-call-header-right'>
                            <button className="task-option-button minimize-button"
                                    onClick={() => this.props.showCallScheduler(false)}><span
                                className="icon-in icon-minimize"></span></button>
                            <button className="task-option-button"
                                    onClick={() => this.props.showCallScheduler(false)}><span
                                class="icon-in icon-close-in"></span></button>
                        </div>
                    </div>
                    <div className='schedule-call-wrapper'>

                        <div className='schedule-call-left'>
                            <iframe
                                src={`https://area51.trova.in:8071/singleSignOn/${this.state.iframeurl}`}
                                scrolling="yes" frameborder="1" marginheight="0px" marginwidth="1px" height="100%"
                                width="100%" allowfullscreen="" allow="camera; microphone;display-capture"/>
                        </div>
                        <div className='schedule-call-right'>
                            {this.props.GetProfileData.loader &&
                            <span className="Loader-holder">
                                   <LoaderSvg/>
                                </span>
                            }
                            {trovaUserDetails && trovaUserDetails.enquiryrid && trovaUserDetails.enquiryrid !== '' && !this.state.closeTrovaProfile &&
                            <>
                                <div className='schedule-call-right-inner'>

                                    <div className="each-inp-field">
                                        <label>Source Lead Id</label>
                                        <div className="new-task-input">
                                            <input type="text" name="customer_lead_id" id="customer_lead_id"
                                                   value={this.state.customer_lead_id} autoComplete="off"
                                                   readOnly={true}/>
                                        </div>
                                    </div>
                                    <div className="each-inp-field">
                                        <label>Customer Name</label>
                                        <div className="new-task-input">
                                            <input type="text" name="customer_name" id="customer_name"
                                                   onChange={(e) => this.setFormFieldValue(e, ["1433231961", "911236364"])}
                                                   value={this.state.customer_name} autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div className="each-inp-field">
                                        <label>Email</label>
                                        <div className="new-task-input">
                                            <input type="text" name="customer_email" id="customer_email"
                                                   value={this.state.customer_email} autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div className="each-inp-field">
                                        <label>Mobile</label>
                                        <div className="new-task-input">
                                            <input type="text" name="customer_mobile" id="customer_mobile"
                                                   onChange={(e) => this.setFormFieldValue(e, ["115577395", "1641960947402"])}
                                                   value={this.state.customer_mobile} autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div className="each-inp-field">
                                        <label>State</label>
                                        <div className="task-inp-container task-tg input-auto-complete empty-label">
                                            <AutoComplete
                                                eleClass='selectize-custom'
                                                fieldTitle={'customer_state'}
                                                placeholder={'Choose a state'}
                                                hideTitle={true}
                                                sourceDataList={this.state.state_options}
                                                searchField='value'
                                                showIcon={false}
                                                hideMainLabel={false}
                                                showSubLabel={true}
                                                labelFields='value'
                                                valueField='value'
                                                subLabelField='additionaltext'
                                                showProfile={false}
                                                reference={'selectedStates'}
                                                selectedItems={selectedStates}
                                                resultDataList={this.resultDataList}
                                                addNewSuggestion={true}
                                                instantSearch={true}
                                                id={'customer_state'}
                                                multiSelect={false}
                                                actionMethod={this.actionMethod}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="each-inp-field">
                                        <label>City</label>
                                        <div className="task-inp-container task-tg input-auto-complete empty-label">
                                            <AutoComplete
                                                eleClass='selectize-custom'
                                                fieldTitle={'customer_city'}
                                                placeholder={'Choose a city'}
                                                hideTitle={true}
                                                sourceDataList={this.state.city_options}
                                                searchField='value'
                                                showIcon={false}
                                                hideMainLabel={false}
                                                showSubLabel={true}
                                                labelFields='value'
                                                valueField='value'
                                                subLabelField='additionaltext'
                                                showProfile={false}
                                                reference={'selectedCities'}
                                                selectedItems={selectedCities}
                                                resultDataList={this.resultDataList}
                                                addNewSuggestion={true}
                                                instantSearch={true}
                                                id={'customer_city'}
                                                multiSelect={false}
                                                actionMethod={this.actionMethod}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="each-inp-field">
                                        <label>Pincode</label>
                                        <div className="new-task-input">
                                            <input type="number" name="customer_pincode" id="customer_pincode"
                                                   onChange={(e) => this.setFormFieldValue(e, ["1464278927", "1515299780"])}
                                                   value={this.state.customer_pincode} autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div className="each-inp-field"><label>Model</label>
                                        <div className="select-box eq-width">
                                            <select id="customer_model" name="customer_model"
                                                    value={this.state.customer_model}
                                                    onChange={(e) => this.setFormFieldValue(e, ["112184734", "1366255631"])}>
                                                <option value="">Please Select</option>
                                                {modelOptions.map((model, mInd) =>
                                                    <option value={model.text}>{model.text}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="each-inp-field"><label>Variant</label>
                                        <div className="select-box eq-width">
                                            <select id="customer_variant" name="customer_variant"
                                                    value={this.state.customer_variant}
                                                    onChange={(e) => this.setFormFieldValue(e, ["1328496730", "1274571671"])}>
                                                <option value="">Please Select</option>
                                                {variantOptions.map((variant, vInd) =>
                                                    <option value={variant.text}>{variant.text}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="each-inp-field"><label>PPL</label>
                                        <div className="select-box eq-width">
                                            <select id="customer_ppl" name="customer_ppl"
                                                    value={this.state.customer_ppl}
                                                    onChange={(e) => this.setFormFieldValue(e, ["448333085", "1418731066"])}>
                                                <option value="">Please Select</option>
                                                {pplOptions.map((ppl, vInd) =>
                                                    <option value={ppl.text}>{ppl.text}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="each-inp-field"><label>Call Disposition</label>
                                        <div className="select-box eq-width">
                                            <select id="call_disposition" name="call_disposition"
                                                    value={this.state.call_disposition}
                                                    onChange={(e) => this.setFormFieldValue(e, ["652747531", "111664306"])}>
                                                <option value="">Please Select</option>
                                                {disOptions.map((dis, vInd) =>
                                                    <option value={dis.text}>{dis.text}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="each-inp-field">
                                        <label>Remarks</label>
                                        <div className="mention-textarea">
                                        <textarea type="text" name="customer_remarks" id="customer_remarks"
                                                  onChange={(e) => this.setFormFieldValue(e, ["1251194166", "1301363726"])}
                                                  value={this.state.customer_remarks}
                                                  className="mentions__input"></textarea>
                                        </div>
                                    </div>


                                </div>
                                <div className={'schedule-call-right-footer'}>

                                    <button className='general-btn grey' onClick={() => this.trovaUserUpdate(true)}>Save
                                        & Close
                                    </button>

                                    <button className='general-btn' onClick={(e) => this.trovaUserUpdate(false)}>Save
                                    </button>
                                </div>

                            </>
                            }

                        </div>
                    </div>
                </div>
            </>
        );
    }

}


const mapStateToProps = state => ({
    GetCallData: state.SchedulerReducer.GetCallData,
    GetProfileData: state.SchedulerReducer.GetProfileData,
    GetFormJsonData: state.SchedulerReducer.GetFormJsonData

});
export default withRouter(connect(mapStateToProps, {

    getTrovaUserInfo: SchedulerActions.getTrovaUserInfo,
    updateCallStatus: SchedulerActions.updateCallStatus,
    getUserPassword: SchedulerActions.getUserPassword,
    getStateList: SchedulerActions.getStateList,
    getCityList: SchedulerActions.getCityList,
    getLeadList: SchedulerActions.getLeadList,
    getFormJson: SchedulerActions.getFormJson,
    saveTrovaUserProfile: SchedulerActions.saveTrovaUserProfile,


})(CallModal));


