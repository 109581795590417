import React, {Component} from 'react';
import {getProfile} from "../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as collectionActions from "../../actions/Collections";
import * as taskActions from "../../actions/ToDo";
import {ES_INDEX} from '../../constants/index'
import DatePickerBox from "../views/DatePickerBox"
import AutoComplete from "../views/AutoComplete"
import {Form} from "react-bootstrap";
import * as usersActions from "../../actions/Users";
import {formatDate, getUrlSegment} from "../../utilities/CustomFunctions";
import moment from 'moment';
import * as projectActions from "../../actions/Project";
import {USRIMG_PATH} from "../../constants";
import LoaderSvg from "../views/LoaderSVG";

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
class ProjectAddCard extends Component {

    constructor(props) {
        super(props);

        this.state={
            name:'',
            description : '',
            enddate:new Date(),
            startdate:new Date(),
            projectname:'',
            clientname:'',
            project_id :0,
            selectedItems : [],
            Close:false,
            newDiscussionLoading:0,
            stageforward: 'allUser'
        };
        this.ProjectInputChange= this.ProjectInputChange.bind(this);

    }

    ProjectInputChange(e) {
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }


    }

    componentDidMount() {
        // this.setState({
        //     selectedItems : this.props.listtags ? this.props.listtags : this.state.selectedItems
        // });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var projectid = (atob(getUrlSegment(4)));
        //console.log( projectid)
        var  beta =  this;
        if(prevProps.discussionAdddata.project_discussion !== this.props.discussionAdddata.project_discussion ) {
            setTimeout(function () {
                let Ddata ={
                    "projectid": projectid,
                    "systype": getProfile().usertype,
                    "uid": getProfile().id,
                    "from": 0,
                    "size": 50,

                }
                beta.props.getprojectDiscussions(Ddata);
            }, 1000)

            this.setState({loading: 0});

        }
    }


    render() {
        // //console.log(this.state.edistartdate);
        let users = this.props.listtags? this.props.listtags : '';
        let userlength = this.props.userlength;

        return (
            <>
                <div className="ind-board-list">
                    <h5>Lorem ipsum dolor sit amet mutat ceteros assueverit mei et</h5>
                    <ul className="board-option">
                        <li className="ico-lib-v1 board-com">
                            <span className="board-com-count">5</span>
                        </li>
                        <li className="int-hide">
                            <a href="javascript:void(0);" className="ico-lib-v1 board-task-ico"></a>
                        </li>
                        <li className="int-hide">
                            <a href="javascript:void(0);" className="ico-lib-v1 board-edit-ico"></a>
                        </li>
                    </ul>
                </div>


            </>


        );
    }
}


const mapStateToProps = state => ({
    listUsers:state.UsersReducer.listUsersdata,
    discussionAdddata:state.ProjectReducer.SaveProjectDiscussion
});
export default  withRouter(connect(mapStateToProps, {
    createProject : taskActions.saveToDoAttachments,
    saveprojectdiscussion:projectActions.SaveProjectDiscussion,
    getprojectDiscussions : projectActions.getProjectDiscussions,

})(ProjectAddCard));



