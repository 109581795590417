import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {getProfile} from "../utilities/AuthService";
import {connect} from "react-redux";
import rootActions from "../actions";
import {ES_INDEX} from "../constants";
import notificationSound from "../components/views/quite-impressed.mp3";
import ToasterNotification from "../components/views/ToasterNotification";

var socket;
class Events extends Component {
    constructor(e) {
        super(e);
        this.state={
            msg: '',
            status: false,
        }
        this.handleClose = this.handleClose.bind(this)
        this.chatHistory=this.chatHistory.bind(this)
    }


    componentDidMount() {

        var socket=this.props.socket
        var that=this
        socket.on('bsynapse_usersOnline', function(usersOnline) {
            that.props.usersOnline(usersOnline)
            setTimeout(function()
            {
                that.props.usersOnline(usersOnline)
            }, 4000);

        });
        socket.on('disconnect', function (reason) {
            //console.log('Disconnected from Server. Reason: '+reason);
            setTimeout(function() {
                //console.log('Reconnecting....');
                socket.emit('user name',{ userid: getProfile().id, socketid: socket.id });
            }, 10000);

        });
        socket.emit('user name',{ userid: getProfile().id, socketid: socket.id });

        socket.on('bsynapse_list_onlineusers_react', function(receiver) {
            //alert('reached inside')
        });
        socket.on('toasterreciverPeer', function(message, from, to, recieverName, has_attachment = 0) {

            that.props.toaster(from)
        });
        socket.on('toasterreciverGroupPeer', function(message, from, to, recieverName, has_attachment = 0) {
            that.props.toaster(from)
        });
        /* one To one message,group message,adding or removing members, delete group actions for a current user  from socket   */
        socket.on('bsynapse_reciverPeer', function (message, from, to,metatags) {
            if (parseInt(that.props.chatUserId.ReceiverUserid) === parseInt(from.id)) {
                //console.log(from)
                that.props.updatereceiverpeer(from)
            }
        });
        socket.on('bsynapse_reciverGroupPeer', function (message, from, to, recieverName, has_attachment = 0) {
            if (that.props.chatUserId.ReceiverUserid === to) {
                that.props.updatereceiverpeer(from)
            }
        });
        socket.on('update_group_listing', function () {
            that.props.chatReset()
            that.props.allGroups(getProfile().id, 0, 500, ES_INDEX);

        });
        socket.on('update_members_to_group', function (user) {
            setTimeout(function () {
                that.props.allGroups(getProfile().id, 0, 500, ES_INDEX);
            }, 1500)

        });
        socket.on('removeduser', function(group) {
            //console.log("You have been removed from the Group");
            socket.emit("leavegroup",group);
            window.location.reload();

        });
        socket.on('show_todo_notification', function (id, to_username, from_username, taskid, message) {
            //console.log("show_todo_notification")
            // toastr.info("New Message from " + from);
            //toastr.info("New Message from " + from);
            if(message == "attach")
            {
                //console.log("attachment")

            }
            else
            {
         //console.log("normal")
            }

        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.toasternotification !== this.props.toasternotification) {
            var audio = new Audio(notificationSound);
            audio.play();
            this.setState({status: true, msg: this.props.toasternotification.notification})
            var that = this
            setTimeout(function () {
                that.props.chatUsers(getProfile().id, ES_INDEX)
            }, 1500)
            setTimeout(function () {
                that.props.allGroups(getProfile().id, 0, 50, ES_INDEX);
            }, 1500);

        }


    }
    handleClose() {
        this.setState({status: !this.state.status})
    }
    chatHistory(uid, user_id, limit, offset, index, type, name, designation, image) {
        this.props.chatHistory(uid, user_id, limit, offset, index, type, name, designation, image)
        if (type === 'group') {
            this.props.groupInfo(user_id, index)
            this.props.allGroups(getProfile().id, 0, 500, ES_INDEX);
        }

    }
    render() {

        return(
            <ToasterNotification
            msg={this.state.msg}
            show={this.state.status}
            handleClose={this.handleClose}
            loadChatHistory={this.chatHistory}
        />
        )

    }


}


const mapStateToProps = state => ({
    chatUserId: state.ChatReducer.chatUserId,
    onlineusers:state.CommonReducer.onlineusers,
    toasternotification: state.ChatReducer.toasterNotification,
});
export default  withRouter(connect(mapStateToProps, {
    allGroups: rootActions.chatAction.listAllGroups,
    chatReset: rootActions.commonActions.chatReset,
    updatereceiverpeer: rootActions.chatAction.updateReceiverpeer,
    groupInfo: rootActions.chatAction.groupInfo,
    chatUsers: rootActions.chatAction.chatUsers,
    usersOnline:rootActions.commonActions.UsersOnline,
    toaster:rootActions.chatAction.Toaster,
    chatHistory: rootActions.chatAction.listChatHistory,
})(Events));

