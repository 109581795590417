import axios from 'axios'
import {commonService} from "./common.services";
import {API_PATH , ES_INDEX} from "../constants";
export const phases = {
    listphases,
    SavePhases,
    listprojectphase,
    listphasemilestone,
    DeletePhases
};
function listphases(params) {
    return commonService.sendHttpRequest('sector/phase', params, "GET");
}
function SavePhases(params) {
    return commonService.sendHttpRequest('sector/createphase', params, "POST");
}
function listprojectphase(params) {
    return commonService.sendHttpRequest('sector/project_phase', params, "GET");
}
function listphasemilestone(params) {
    return commonService.sendHttpRequest('sector/projectphases', params, "GET");
}
function DeletePhases(params) {
    return commonService.sendHttpRequest('sector/', params, "POST");
}