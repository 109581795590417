import axios from 'axios'
import {commonService} from "./common.services";
import {API_PATH,ES_INDEX} from '../constants/index'

export const loginForm = {
    login,
    sessionValidate
};


function login(username,passs){
    let params = {
        "username": username,
        "password": passs,
        "deviceid": ""
    }
    return axios.post( API_PATH+'login/userlogin', {

        "username": username,
        "password": passs,
        "deviceid": "",
        "index":ES_INDEX

    })

    //return commonService.sendHttpRequest('login/signin', params, "POST");
}
function sessionValidate(sid,uid){
    return axios.post(API_PATH + 'login/checksession', {

        "sid": sid,
        "uid": uid,

    })
}
