import React, {Component} from 'react';
import {Card,Tooltip,OverlayTrigger, Button, Dropdown} from "react-bootstrap"
import ProjectChat from "../pages/chat/ProjectChat";
import AddNotes from "../pages/ceshareview/AddNotes";
import Modal from "./Modal";
import AddToDoTask from "../pages/AddToDoTask";
import {ES_INDEX, SHARED_LOGIN} from "../../constants";
import AlertModal from "./AlertModal";
import {getProfile} from "../../utilities/AuthService";
import {DateFormatter} from "../../utilities/CustomFunctions";
import {connect} from "react-redux";
import {withRouter} from 'react-router';

import * as taskActions from "../../actions/ToDo";
import rootActions from "../../actions";
import * as projectActions from "../../actions/Project";

class GeModal extends Component {

    constructor(props) {
        super(props);
        this.state={
            Close:false,
            chat:false,
            note:false,
            showTask: false,
            navigation: false,
            showAddNewTaskModal: true
        }
      }

    componentDidMount() {
        console.log(this.props.customtemplate)
        let navigation = this.props.customtemplate !== undefined && this.props.customtemplate.name === "growthenabled" && this.props.customtemplate.hasOwnProperty("navigation") ?  this.props.customtemplate.navigation : false;
        this.setState({navigation: navigation})

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.HistoryPushData !== this.props.HistoryPushData) {
            this.setState({showTask: false})
        }
        if (prevProps.customtemplate !== this.props.customtemplate) {
            let navigation = this.props.customtemplate && this.props.customtemplate.name === "growthenabled" &&  this.props.customtemplate.navigation ?  this.props.navigationtemplate.navigation : false;
            this.setState({navigation: navigation})
        }
    }

    closePanel = (pan) => {

            this.setState({note: false,chat: false,[pan]:false})

    }

    msgCnt = (cnt) => {
        this.setState({msgCnt: cnt})
    }
    panToggle=(pan)=>{
        if (pan === "note") {
            this.setState({note: true,chat:false})
        } else if (pan === "chat") {
            this.setState({chat: true,note:false})
        }
    }

    showDuplicateConfirm=() => {
        this.setState({showDuplicateConfirm: !this.state.showDuplicateConfirm});
    }


    handleDuplicate = () => {
        this.showDuplicateConfirm();
        let param = {
            "cid": this.props.collectionId,
            "recordid": this.props.recordId,
            "uid": getProfile().id
        }
        this.props.collectionRecordDuplicate(param);
    }

    taskAction = () => {

        this.setState({showTask: true})

    }
    infoChange = () =>{
        this.setState({info:!this.state.info})
    }
    closeAddNewTaskModal() {
        this.setState({showAddNewTaskModal: false, showTask: false, addOptions: false});

    }
    addProjectToTask(e) {
        this.setState({showProjectModal: false, projectAdded: true});
        // this.hideAttachCtr();
    }
    removeSelectedProject(e, index) {
        let selectedProjects = this.state.selectedProjects;

        if (selectedProjects) {
            selectedProjects.splice(index, 1);
            this.setState({selectedProjects: selectedProjects});
        }
    }


    render() {
        let meta=this.props.entityDetails.meta;

        return (

            <div className={`modal show full-width template-4 usr-profile full-wid-mod fade ${this.props.extraClass} ${this.state.navigation ? 'with-navtab' : ''}`} id="collectionModal"
                 style={{display: "block"}}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content" style={{backgroundColor:this.props.colorcode}}>
                        <div className="modal-header">
                            <div className="modal-header-left">
                                <h5>{this.props.title}</h5>
                                {this.props.subTitleEnabled &&
                                <p>{this.props.subTitle}</p>
                                }
                            </div>
                            <div className="modal-header-right align-right-option">

                                 {this.props.otherActionClass &&
                                   <button className={this.props.otherActionClass} onClick={() => this.props.otherAction()}/>
                                  }

                                    <>
                                        <div className="collection-chat show-lg"
                                            onClick={() => this.panToggle("chat")}/>

                                    </>


                                        <div className="collection-note show-lg"
                                            onClick={() => this.panToggle("note")}/>


                                {this.state.chat &&
                                    <ProjectChat
                                        taskid={this.props.recordId.toString()}
                                        inviteUsers={false}
                                        type={"collection"}
                                        msgOpn={this.closePanel}
                                        msgCnt={this.msgCnt}
                                        openSta={this.state.chat}
                                        sound={true}
                                        toaster={true}
                                        collectionId={this.props.collectionId}
                                        // mailNotification={mailNotification}
                                        //  assigneesData={assigneesData}
                                    />
                                }
                                { this.state.note &&
                                    <div className="side-bar-panel collection-clik-panel grey-bg-panel list-view-panel">
                                        <div id="progress-holder" className="upload-pr-wrap"/>
                                        <div className="float-panel-header"><h5 className="subheader">Note</h5>
                                            <button className="ico-lib fly-close" onClick={() => this.closePanel("note")}/>
                                        </div>
                                        <div className="discuss-inner collection-chat-container note-tab full-height-modal "
                                             tabIndex="2"
                                             id="disscussion">

                                            <div className="collection-meta-note">
                                    <AddNotes
                                        recordId={this.props.recordId.toString()}
                                        changeNotes={this.props.changeNotes}
                                        collectionId={this.props.collectionId}
                                        value={this.props.listAnnotation}
                                        // chatNotification={chatNotification}
                                    />
                                            </div>


                                        </div>
                                    </div>

                              }

                                {(this.state.info) &&
                                <div className="side-bar-panel collection-clik-panel grey-bg-panel list-view-panel">
                                    <div id="progress-holder" className="upload-pr-wrap"/>
                                    <div className="float-panel-header"><h5 className="subheader p-0">Info</h5>
                                        <button className="ico-lib fly-close" onClick={() => this.closePanel("info")}/>
                                    </div>
                                    <div className="discuss-inner collection-chat-container note-tab full-height-modal "
                                         tabIndex="2"
                                         id="disscussion">

                                        <div >
                                            <ul>
                                                <li>
                                                    <div className="right-panel-group-each">
                                                        <h5>CREATED BY</h5>
                                                        <p>{meta.creator}</p>
                                                    </div>
                                                    {meta.createdate !== "" &&
                                                    <div className="right-panel-group-each">
                                                        <h5>CREATED ON</h5>
                                                        <p>{DateFormatter(meta.createdate, true, true)}</p>
                                                    </div>
                                                    }
                                                </li>
                                                {((meta.updateby !== "") || (meta.updatedate !== "")) &&
                                                <li>
                                                    {meta.updateby !== "" &&
                                                    <div className="right-panel-group-each">
                                                        <h5>LAST EDITED BY</h5>
                                                        <p>{meta.updateby}</p>
                                                    </div>
                                                    }
                                                    {meta.updatedate !== "" &&
                                                    <div className="right-panel-group-each">
                                                        <h5>LAST EDITED ON</h5>
                                                        <p>{DateFormatter(meta.updatedate, true, true)}</p>
                                                    </div>
                                                    }

                                                </li>
                                                }

                                                {meta.workflows && meta.workflows.length !== 0 &&
                                                <li>
                                                    <div className="right-panel-group-each">
                                                        <h5>LINKED WORKFLOWS</h5>
                                                        <ol>
                                                            {meta.workflows.map((list) =>
                                                                <li>{list}</li>
                                                            )}

                                                        </ol>
                                                    </div>
                                                </li>
                                                }
                                            </ul>
                                        </div>


                                    </div>
                                </div>
                                }
                                {this.state.showDuplicateConfirm &&
                                    <AlertModal id="take-pool"
                                                title="Are you sure you want to duplicate the record?"
                                                OK_button_text="Yes"
                                                OK_button_style="btn-danger"
                                                OK_action={this.handleDuplicate}
                                                showModal={this.state.showDuplicateConfirm}
                                                CANCEL_action={this.showDuplicateConfirm}
                                    />
                                }
                                {this.state.showTask &&
                                    <Modal title={'Create New Task'} Close={this.closeAddNewTaskModal.bind(this)}>
                                        <AddToDoTask
                                            projectList={this.props.projectList}
                                            tagList={this.props.tagList}
                                            listtags={this.props.listtags.tags}
                                            modalOpen={this.closeAddNewTaskModal}
                                            collectionRecord={this.props.collectionRecord}
                                            removeSelectedRecord={this.props.removeSelectedRecord}
                                            taggedProject={this.props.taggedProject.projectinfo}
                                            projectId={this.props.projectId}
                                            createdfrom={this.props.projectId !== '' ? 'prjcol' : 'collection'}
                                        />

                                    </Modal>
                                }
                                <Dropdown>
                                    <Dropdown.Toggle className="more-btn" menuAlign="right">More actions</Dropdown.Toggle>
                                    <Dropdown.Menu className="more-panel">
                                        <Dropdown.Item id="chat" className="panel-option-click drop-chat-ico show-sm">Chat</Dropdown.Item>
                                        <Dropdown.Item id="note" className="panel-option-click infos show-sm">Note</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => {
                                            this.infoChange();
                                        }} id="info" className="panel-option-click infos">Info</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => {
                                            this.taskAction();
                                        }} id="task" className="panel-option-click ico-lib form-assign-ico">Task</Dropdown.Item>
                                        <Dropdown.Item
                                            href={ SHARED_LOGIN.CISERVICE + `Collection/exportexcel/` + this.props.recordId + `/1/` +  this.props.collectionId + `/0/` + ES_INDEX}
                                           className="panel-option-click ico-lib export-ico-absolute">Export To Excel</Dropdown.Item>

                                        <Dropdown.Item
                                            href={SHARED_LOGIN.CISERVICE + `Collection/exportpdf/` + this.props.recordId + `/` + this.props.collectionId + `/` + ES_INDEX}
                                            id="exportpdf"
                                            className="panel-option-click ico-lib export-ico-absolute"
                                            >Export To PDF</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => {
                                            this.showDuplicateConfirm();
                                        }} id="duplicate" className="panel-option-click ico-lib-v1 form-dup-ico"
                                                      >Duplicate Record</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                {this.props.hasOwnProperty("Close")&&
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => this.props.Close()}>

                                </button>
                                }
                            </div>
                        </div>
                        <div className="modal-body" style={{backgroundColor:this.props.colorcode}}>
                            {this.props.children}

                        </div>



                    </div>
                </div>
            </div>



        );
    }
}


const mapStateToProps = state => ({

    HistoryPushData: state.TaskReducer.HistoryPushData,
    listAnnotation : state.CollectionsReducer.listAnnotation,

});
export default withRouter(connect(mapStateToProps, {

})(GeModal));



