import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import NoData from '../../views/NoData';
import * as ResourcesActions from "../../../actions/Resources";
import {FRMSIMG_PATH,FRMSVID_PATH, FRMSDOC_PATH,SECURED} from '../../../constants'
import {getProfile} from "../../../utilities/AuthService";
import {getFileExtension} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import moment from "moment";
import AddNewResources from "./AddNewResources";
import Modal from "../../views/Modal";
import LoaderSvg from "../../views/LoaderSVG";
import AlertModal from "../../views/AlertModal";
import PreviewModal from "./PreviewModal";
import VideoPlayer from "../../views/player/VideoPlayer"
import {debounce} from "throttle-debounce";
//import {getURL} from "next/dist/next-server/lib/utils";
import OutsideAlerter from "../../views/OutsideAlerter";
class Resources extends Component {
    constructor(props) {
        super(props);
        this.state ={
            loading:0,
            resViewGrid : true,
            resViewList : false,
            res1 :'active',
            res2 : '',
            res3 : '',
            res4:'',
            res5 : '',
            ImgName:'',
            Title:'',
            Ftype:'',
            Res_id:'',
            ImgPath:'',
            showModal : false,
            addNewResource:false,
            resourceId:0,
            Delpage:false,
            offset:100,
            lastOffset: 0,
            totalitems: 0,
            creator:'',
            addOptions:false,
            secured:false,
            alertstatus:false,
            fieldID:'',
        }
        this.getResourceslist = this.getResourceslist.bind(this);
        this.ImagesViewGrid = this.ImagesViewGrid.bind(this);
        this.ImagesViewList = this.ImagesViewList.bind(this);
        this.ResourcesDetails = this.ResourcesDetails.bind(this);
        this.ResourceDel = this.ResourceDel.bind(this);
        this.addNewResourceModal = this.addNewResourceModal.bind(this);
        this.cancelDeletemodal = this.cancelDeletemodal.bind(this);
        this.deleteResource = this.deleteResource.bind(this);
        this.closePreviewModal =  this.closePreviewModal.bind(this);
        this.listenToScroll=debounce(500, this.listenToScroll);
        this.downloadTracking = this.downloadTracking.bind(this);
        this.showAddOptions = this.showAddOptions.bind(this);
        this.showOptions = this.showOptions.bind(this);
        this.editResourceModal = this.editResourceModal.bind(this);
    }
    componentDidMount() {
        this.props.mainClick('resources','');
        this.getResourceslist(1);
        let trackparams={
            "module": "Resources",
            "page": window.location.pathname,
            "action": "listing",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams)
        const container = document.querySelector('.resources-list');
        container.addEventListener('scroll', this.listenToScroll)
    }
    componentWillUnmount() {
        const container = document.querySelector('.resources-list');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.resources-list')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - (offsetHeight + 1);
        if (contentHeight <= scrollTop) {
            let params={
                "systype":getProfile().usertype,
                "uid":getProfile().id,
                "res_type":this.props.resourceClickData.subMenu,
                "filter_type":this.props.listAllResourcesData.searchParam.filter_type,
                "srchval":this.props.listAllResourcesData.searchParam.srchval,
                "limit":this.props.listAllResourcesData.ListAllResources.length,
                "offset":this.state.offset
            }
            this.props.listmoreresources(params)

        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if (prevProps.delResourcesData.delResources !== this.props.delResourcesData.delResources) {
            setTimeout(function () {
              //  beta.getResourceslist(beta.props.resourceClickData.subMenu?beta.props.resourceClickData.subMenu:1);
                let params={
                    systype:getProfile().usertype,
                    uid:getProfile().id,
                    res_type:beta.props.resourceClickData.subMenu?beta.props.resourceClickData.subMenu:1,
                    filter_type:beta.props.listAllResourcesData.searchParam.filter_type,
                    srchval:beta.props.listAllResourcesData.searchParam.srchval,
                    limit:0,
                    offset:beta.state.offset
                }
                beta.props.listresources(params)
                beta.cancelDeletemodal();
                beta.setState({loading:0,Res_id:0,showModal: false});
            }, 1000)

        }
        if (prevProps.saveGalleryData.gallerysaved !== this.props.saveGalleryData.gallerysaved) {
            setTimeout(function () {
                //beta.getResourceslist(beta.props.resourceClickData.subMenu?beta.props.resourceClickData.subMenu:1);
                let params={
                    systype:getProfile().usertype,
                    uid:getProfile().id,
                    res_type:beta.props.resourceClickData.subMenu?beta.props.resourceClickData.subMenu:1,
                    filter_type:beta.props.listAllResourcesData.searchParam.filter_type,
                    srchval:beta.props.listAllResourcesData.searchParam.srchval,
                    limit:0,
                    offset:beta.state.offset
                }
                beta.props.listresources(params)
                beta.addNewResourceModal();
                beta.setState({loading:0,Res_id:0});
            }, 1000)

        }
    }
    getResourceslist(res_type){
        var res_type = res_type ? res_type:1;
        let params={
            systype:getProfile().usertype,
            uid:getProfile().id,
            res_type:res_type,
            filter_type:'All',
            srchval:'',
            limit:0,
            offset:this.state.offset
        }
        this.props.listresources(params)
    }

    ImagesViewGrid(){
        this.setState({resViewGrid :true,resViewList : false})
    }
    ImagesViewList(){
        this.setState({resViewGrid :false,resViewList : true})
    }
    ResourcesList(resid){
        let params={
            systype:getProfile().usertype,
            uid:getProfile().id,
            res_type:resid,
            filter_type:'All',
            srchval:'',
            limit:0,
            offset:this.state.offset
        }
        this.props.listresources(params)
        this.props.resourceClick(resid);
        if(resid == '1'){
            this.setState({res1 :'active', res2 : '', res3 : '',res4 : '',res5 : ''})
        } else if(resid =='2'){
            this.setState({res1 :'',res2 : 'active', res3 : '',res4 : '',res5 : ''})
        } else if(resid =='3') {
            this.setState({res1 :'',res2 : '', res3 : 'active',res4 : '',res5 : ''})
        }
        else if (resid =='4'){
            this.setState({res1 :'',res2 : '', res3 : '',res4 :'active',res5 : ''})
        }
        else{
            this.setState({res1 :'',res2 : '', res3 : '',res4 :'',res5 : 'active'})
        }
        let trackparams={
            "module": "Resources",
            "page": window.location.pathname,
            "action": "listing",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams)
    }


    ResourcesDetails(Resource_id,Imgname,Title,Ftype,res_type,creator){
        this.setState({
            ImgName:Imgname,
            Title:Title,
            Ftype:Ftype,
            Res_id:Resource_id,
            creator:creator
        })
        if(res_type === '1'){
            this.setState({ImgPath:FRMSIMG_PATH})
        } else if (res_type === '3'){
            this.setState({ImgPath:FRMSDOC_PATH})
        } else if(res_type === '2'){
            this.setState({ImgPath:FRMSVID_PATH})
        }else if(res_type === '4'){
            this.setState({ImgPath:FRMSDOC_PATH})
        }else if(res_type === '5'){
            // this.setState({ImgPath:FRMSDOC_PATH})
        }
        this.setState({showModal: !this.state.showModal})
        let trackparams={
            "module": "Resources",
            "page": window.location.pathname,
            "action": "preview",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams)
    }
    closePreviewModal(){
        this.setState({showModal: false,
            ImgName:'',
            Title:'',
            Ftype:'',
            Res_id:'',
            ImgPath:'',
            creator:''})
    }
    ResourceDel(){
        this.setState({loading:1})
        this.props.deleteresources({_id:this.state.Res_id});
        let trackparams={
            "module": "Resources",
            "page": window.location.pathname,
            "action": "delete",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams)
    }
    addNewResourceModal(e,secured){
        this.setState({addNewResource:!this.state.addNewResource,secured:secured},
            function(){
                let params={
                    "module": "Resources",
                    "page": window.location.pathname,
                    "action": "addnewbuttonclick",
                    "browser": window.navigator.userAgent,
                    "uid": getProfile().id,
                }
                if(this.state.addNewResource === true){
                    this.props.activityTracking(params)
                }
                if(this.state.addNewResource === false){
                    this.setState({resourceId: 0})
                }


            })

    }
    editResourceModal(e,id,restype){
        this.setState({addNewResource:!this.state.addNewResource,resourceId:id})
        if(restype === '5') {
            this.setState({secured: true})
        }else{
            this.setState({secured: false})
        }

        if(id){
            let params={
                systype:getProfile().usertype,
                uid:getProfile().id,
                res_type:restype,
                filter_type:'All',
                _id:id
            }
            this.props.getResourceInfo(params);

        }
        let trackparams={
            "module": "Resources",
            "page": window.location.pathname,
            "action": "editbuttonclick",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams)
    }
    deleteResource(e,id){
        this.setState({Delpage:true})
        let trackparams={
            "module": "Resources",
            "page": window.location.pathname,
            "action": "deleteclick",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams)
    }
    cancelDeletemodal(){
        this.setState({Delpage: false,Res_id:0});
    }
    downloadTracking(){
        let trackparams={
            "module": "Resources",
            "page": window.location.pathname,
            "action": "download",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams)
    }
    showAddOptions(){
        this.setState({addOptions :!this.state.addOptions})
    }
    showOptions(ID){
        this.setState({alertstatus :!this.state.alertstatus,fieldID :ID})
    }
    render() {
        let Resources=this.props.listAllResourcesData.ListAllResources||[];
        let userPermissiondata = this.props.listUsersPermission.UsersPermission;
        let resourcepermission='';
        let permission=[];
        permission = userPermissiondata.filter(item=>item.module === "Resources")[0]||[];
        resourcepermission = permission!== undefined ? permission.module_permission:'';
        return (
            <div className="wrapper">
                <div className="right-content-wrapper">
                    <div className="list-wrapper stand-page">
                        <div className="task-content-wrapper full-dv">
                            <div className="resources-contents">
                                {resourcepermission === 'yes' &&
                                <div className="resources-filters">
                                    <div className="resources-filters-left">
                                        <ul className="resources-filters-icons with-text">
                                            <li>
                                                <nav className={this.state.res1}
                                                     onClick={(e) => this.ResourcesList(1)}>Image
                                                </nav>
                                            </li>
                                            <li>
                                                <nav className={this.state.res3}
                                                     onClick={(e) => this.ResourcesList(3)}>Document
                                                </nav>
                                            </li>
                                            <li>
                                                <nav className={this.state.res2}
                                                     onClick={(e) => this.ResourcesList(2)}>Video
                                                </nav>
                                            </li>
                                            <li>
                                                <nav className={this.state.res4}
                                                     onClick={(e) => this.ResourcesList(4)}>Audio
                                                </nav>
                                            </li>
                                            {SECURED &&
                                            <li>
                                                <nav className={this.state.res5}
                                                     onClick={(e) => this.ResourcesList(5)}>Streaming Video
                                                </nav>
                                            </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className="resources-filters-right">
                                        <div className="resources-tab-wrap">
                                            <ul className="resources-filters-icons">
                                                <li>
                                                    <nav
                                                        className={this.state.resViewGrid === true ? "rs-tab-btn ico-lib-v1 rc-tab-grid active" : "rs-tab-btn ico-lib-v1 rc-tab-grid"}
                                                        onClick={this.ImagesViewGrid}></nav>
                                                </li>
                                                <li>
                                                    <nav
                                                        className={this.state.resViewList === true ? "rs-tab-btn ico-lib-v1 rc-tab-list active" : "rs-tab-btn ico-lib-v1 rc-tab-list"}
                                                        onClick={this.ImagesViewList}></nav>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                }
                                {(this.props.listAllResourcesData.loading || this.state.loading === 1) &&
                                <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                                }
                                {resourcepermission === 'no' &&
                                <div className='absolute-holder'>
                                    <NoData
                                        msg={`No Permission to access`}
                                    />
                                </div>
                                }
                                {resourcepermission === 'yes' &&  Resources.length === 0 &&
                                <div className='absolute-holder'>
                                    <NoData
                                        msg={`No Resource added yet`}
                                    />
                                </div>
                                }

                                <div className= {this.state.resViewList === true ? "resources-list mob-scroll" :"resources-list mob-scroll grid-vw"}>
                                    <div className="wrapper-able-aliker">
                                        <div className="resources-list-header">
                                            <div className="ind-header res-name">Name</div>
                                            <div className="ind-header res-type">Type</div>
                                            <div className="ind-header res-size">Size</div>
                                            <div className="ind-header res-mod">Modified</div>
                                            <div className="ind-header res-mod"></div>

                                        </div>
                                        <div className="rc-list-content-wrap ">
                                            {resourcepermission === 'yes' && Resources.map((list,index) =>
                                                <div className="rc-list-contents" key={index} >
                                                    <div className="default-block first-block res-name"  onDoubleClick={(e)=> this.ResourcesDetails(list.resource_id,list.res_img,list.title,list.f_type,list.res_type,list.creatorid)}>
                                                        {list.res_type == '1' &&
                                                        <React.Fragment>
                                                            <div className="rc-thumb-grid-img" style={{display: this.state.resViewGrid === true ? 'block' : 'none' }}>
                                                                <img src={FRMSIMG_PATH + list.res_thumb}
                                                                     alt="Thumb-img"/>
                                                                {list.creatorid === getProfile().id &&
                                                                    <div className="form-option-block nav-option">
                                                                        <button className="ico-lib opt-form-ico"
                                                                                onClick={() => this.showOptions(list.resource_id)}></button>
                                                                        {this.state.alertstatus && (this.state.fieldID === list.resource_id) &&
                                                                        <OutsideAlerter
                                                                            status={this.props.alertstatus}
                                                                            Triggered={() => this.showOptions(!this.props.alertstatus)}>
                                                                            <ul className="panel-option">
                                                                                <li>
                                                                                    <nav
                                                                                        className="panel-option-click ico-lib form-edit-ico"
                                                                                        onClick={(e) => {
                                                                                            this.editResourceModal(e, list.resource_id, list.res_type);
                                                                                            this.showOptions(!this.state.alertstatus)
                                                                                        }}>Edit
                                                                                    </nav>
                                                                                </li>
                                                                            </ul>
                                                                        </OutsideAlerter>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="rc-thumb-img">
                                                                <img src={FRMSIMG_PATH + list.res_thumb} alt="Thumb-img"/>
                                                            </div>
                                                        </React.Fragment>
                                                        }
                                                        {list.res_type == '3' &&
                                                        <React.Fragment>
                                                            <div className="rc-thumb-grid-img" style={{display: this.state.resViewGrid === true ? 'block' : 'none' }}>
                                                                <div className="rc-small-thumb">
                                                                    <div className= {"format " + getFileExtension(list.res_img)}>{getFileExtension(list.res_img)}</div>
                                                                </div>
                                                                {list.creatorid === getProfile().id &&
                                                                    <div className="form-option-block nav-option">
                                                                        <button className="ico-lib opt-form-ico"
                                                                                onClick={() => this.showOptions(list.resource_id)}></button>
                                                                        {this.state.alertstatus && (this.state.fieldID === list.resource_id) &&
                                                                        <OutsideAlerter
                                                                            status={this.props.alertstatus}
                                                                            Triggered={() => this.showOptions(!this.props.alertstatus)}>
                                                                            <ul className="panel-option">
                                                                                <li>
                                                                                    <nav
                                                                                        className="panel-option-click ico-lib form-edit-ico"
                                                                                        onClick={(e) => {
                                                                                            this.editResourceModal(e, list.resource_id, list.res_type);
                                                                                            this.showOptions(!this.state.alertstatus)
                                                                                        }}>Edit
                                                                                    </nav>
                                                                                </li>
                                                                            </ul>
                                                                        </OutsideAlerter>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="rc-thumb-img">
                                                                <div className="rc-lg-thumb">
                                                                    <div className= {"format " + getFileExtension(list.res_img)}>{getFileExtension(list.res_img)}</div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        }
                                                        {list.res_type == '2' &&
                                                        <React.Fragment>
                                                            <div className="rc-thumb-grid-img" style={{display: this.state.resViewGrid === true ? 'block' : 'none' }}>
                                                                <VideoPlayer
                                                                    key={FRMSVID_PATH + list.res_img}
                                                                    display={'block'}
                                                                    width="100%"
                                                                    height="100%"
                                                                    src={FRMSVID_PATH + list.res_img}
                                                                />
                                                                {list.creatorid === getProfile().id &&
                                                                    <div className="form-option-block nav-option">
                                                                        <button className="ico-lib opt-form-ico"
                                                                                onClick={() => this.showOptions(list.resource_id)}></button>
                                                                        {this.state.alertstatus && (this.state.fieldID === list.resource_id) &&
                                                                        <OutsideAlerter
                                                                            status={this.props.alertstatus}
                                                                            Triggered={() => this.showOptions(!this.props.alertstatus)}>
                                                                            <ul className="panel-option">
                                                                                <li>
                                                                                    <nav
                                                                                        className="panel-option-click ico-lib form-edit-ico"
                                                                                        onClick={(e) => {
                                                                                            this.editResourceModal(e, list.resource_id, list.res_type);
                                                                                            this.showOptions(!this.state.alertstatus)
                                                                                        }}>Edit
                                                                                    </nav>
                                                                                </li>
                                                                            </ul>
                                                                        </OutsideAlerter>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="rc-thumb-img">
                                                                <div className="rc-lg-thumb">
                                                                    <div className= {"format " + getFileExtension(list.res_img)}>{getFileExtension(list.res_img)}</div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        }
                                                        {list.res_type == '4' &&
                                                        <React.Fragment>
                                                            <div className="rc-thumb-grid-img" style={{display: this.state.resViewGrid === true ? 'block' : 'none' }}>
                                                                <audio  width="320px" height="240px" controls controlsList="nodownload" key={FRMSDOC_PATH + list.res_img}>
                                                                    <source type="audio/mpeg" src={FRMSDOC_PATH + list.res_img}></source>
                                                                </audio >
                                                                {list.creatorid === getProfile().id &&
                                                                    <div className="form-option-block nav-option">
                                                                        <button className="ico-lib opt-form-ico"
                                                                                onClick={() => this.showOptions(list.resource_id)}></button>
                                                                        {this.state.alertstatus && (this.state.fieldID === list.resource_id) &&
                                                                        <OutsideAlerter
                                                                            status={this.props.alertstatus}
                                                                            Triggered={() => this.showOptions(!this.props.alertstatus)}>
                                                                            <ul className="panel-option">
                                                                                <li>
                                                                                    <nav
                                                                                        className="panel-option-click ico-lib form-edit-ico"
                                                                                        onClick={(e) => {
                                                                                            this.editResourceModal(e, list.resource_id, list.res_type);
                                                                                            this.showOptions(!this.state.alertstatus)
                                                                                        }}>Edit
                                                                                    </nav>
                                                                                </li>
                                                                            </ul>
                                                                        </OutsideAlerter>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="rc-thumb-img">
                                                                <div className="rc-lg-thumb">
                                                                    <div className= {"format " + getFileExtension(list.res_img)}>{getFileExtension(list.res_img)}</div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        }
                                                        {list.res_type == '5' &&
                                                        <React.Fragment>
                                                            <div className="rc-thumb-grid-img" style={{display: this.state.resViewGrid === true ? 'block' : 'none' }}>
                                                                <VideoPlayer
                                                                    key={FRMSVID_PATH + list.res_img}
                                                                    display={'block'}
                                                                    width="100%"
                                                                    height="100%"
                                                                    src={FRMSVID_PATH + list.res_img}
                                                                    resType='5'
                                                                    fileName={list.res_img}
                                                                />
                                                                {list.creatorid === getProfile().id &&
                                                                    <div className="form-option-block nav-option">
                                                                        <button className="ico-lib opt-form-ico"
                                                                                onClick={() => this.showOptions(list.resource_id)}></button>
                                                                        {this.state.alertstatus && (this.state.fieldID === list.resource_id) &&
                                                                        <OutsideAlerter
                                                                            status={this.props.alertstatus}
                                                                            Triggered={() => this.showOptions(!this.props.alertstatus)}>
                                                                            <ul className="panel-option">
                                                                                <li>
                                                                                    <nav
                                                                                        className="panel-option-click ico-lib form-edit-ico"
                                                                                        onClick={(e) => {
                                                                                            this.editResourceModal(e, list.resource_id, list.res_type);
                                                                                            this.showOptions(!this.state.alertstatus)
                                                                                        }}>Edit
                                                                                    </nav>
                                                                                </li>
                                                                            </ul>
                                                                        </OutsideAlerter>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="rc-thumb-img">
                                                                <div className="rc-lg-thumb">
                                                                    <div className= {"format " + getFileExtension(list.res_img)}>{getFileExtension(list.res_img)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        }
                                                        <div className="rc-title" data-toggle="modal"
                                                             data-target="#toModal">
                                                            <h5>{list.title}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="default-block res-type">
                                                        <h5>{list.f_type}</h5>
                                                    </div>
                                                    <div className="default-block res-size">
                                                        <h5>{list.filesize}</h5>
                                                    </div>
                                                    <div className="default-block res-mod">
                                                        <h5>{moment(list.createddate).format("DD MMM, YYYY")}</h5>
                                                    </div>
                                                    <div className="default-block res-option">
                                                        {list.creatorid === getProfile().id &&
                                                            <div className="form-option-block nav-option">
                                                                <button className="ico-lib opt-form-ico"
                                                                        onClick={() => this.showOptions(list.resource_id+"list")}></button>

                                                                {this.state.alertstatus && (this.state.fieldID === list.resource_id+"list") &&
                                                                <OutsideAlerter
                                                                    status={this.props.alertstatus}
                                                                    Triggered={() => this.showOptions(!this.props.alertstatus)}>
                                                                    <ul className="panel-option">
                                                                        <li>
                                                                            <nav
                                                                                className="panel-option-click ico-lib form-edit-ico"
                                                                                onClick={(e) => {
                                                                                    this.editResourceModal(e, list.resource_id, list.res_type);
                                                                                    this.showOptions(!this.state.alertstatus)
                                                                                }}>Edit
                                                                            </nav>
                                                                        </li>
                                                                    </ul>
                                                                </OutsideAlerter>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {!SECURED && resourcepermission === 'yes' &&
                        <div className={'float-button-box'} onClick={(e) => this.addNewResourceModal(e,false)}
                             title="New Resource">
                            <span className={'icon-in icon-plus'}></span>
                        </div>
                        }
                        {SECURED && resourcepermission === 'yes' &&
                        <div className="float-button-block" data-toggle="tooltip" data-placement="top"
                             title="New Resource">
                            <button className="float-button ico-lib float-add"
                                    onClick={() => this.showAddOptions()}></button>
                            {this.state.addOptions === true &&
                            <OutsideAlerter
                                status={this.state.addOptions}
                                Triggered={() => this.showAddOptions(!this.state.addOptions)}>
                                <div className="add-users__list--pop-up milestone" id="added-user-popup"
                                     style={{display: "block"}}>
                                    <ul>
                                        <li>
                                            <a className="milestone-plus-new ico-lib-v1 ico-lib-upload"
                                               onClick={(e) => this.addNewResourceModal(e, false)}>Upload</a>
                                        </li>
                                        <li>
                                            <a className="milestone-plus-new ico-lib-v1 ico-lib-secure-upload"
                                               onClick={(e) => this.addNewResourceModal(e, true)}>Secured Upload</a>
                                        </li>


                                    </ul>
                                </div>

                            </OutsideAlerter>
                            }
                        </div>
                        }
                        {this.state.addNewResource &&
                        <Modal title={this.state.resourceId === 0 ? 'Add New Resource':'Update Resource' } Close ={()=>this.addNewResourceModal()}>
                            <AddNewResources
                                addNewResourceModal={this.addNewResourceModal}
                                secured={this.state.secured}

                            />
                        </Modal>
                        }
                        {this.state.Delpage === true &&
                        <AlertModal
                            id="delResource-modal"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_action={() => this.ResourceDel()}
                            showModal = {this.state.Delpage}
                            CANCEL_action={() => this.cancelDeletemodal()}
                        />
                        }
                    </div>
                </div>
                {this.state.showModal &&
                <PreviewModal
                    Title={this.state.Title}
                    ResourcesDetails={this.ResourcesDetails}
                    Ftype={this.state.Ftype}
                    ImgPath={this.state.ImgPath}
                    ImgName={this.state.ImgName}
                    Res_id={this.state.Res_id}
                    res_type={this.props.resourceClickData.subMenu}
                    deleteResource={this.deleteResource}
                    closePreviewModal={this.closePreviewModal}
                    creator={this.state.creator}
                    downloadTracking={this.downloadTracking}
                />
                }
            </div>
        );
    }
}
const mapStateToProps = state => ({
    listAllResourcesData:state.resourcesReducer.listallResources,
    delResourcesData:state.resourcesReducer.delResources,
    menuClickData:state.CommonReducer.menuClick,
    resourceClickData:state.CommonReducer.resourceClick,
    saveGalleryData:state.resourcesReducer.gallerySaved,
    listUsersPermission:state.UsersReducer.listUsersPermissiondata,
});
export default  withRouter(connect(mapStateToProps, {
    listresources:ResourcesActions.listResources,
    deleteresources:ResourcesActions.DelResources,
    mainClick:rootActions.commonActions.MenuClick,
    resourceClick:rootActions.commonActions.resourceClick,
    listmoreresources:ResourcesActions.listmoreResources,
    activityTracking:rootActions.commonActions.activityTracking,
    getResourceInfo:ResourcesActions.getResourcesInfo
})(Resources));