import React, {Component} from 'react';
import {debounce} from 'throttle-debounce';

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialLoad : true,
            searchInput : '',
            filteredDataList : [],
            selectedItems : [],
            selectedItemNames : [],
            selectedIndex : 0,
            selectionStatus : '',
            instantSearch: this.props.instantSearch?this.props.instantSearch:false,
            initiateSearch : false,
            selectedValues : [],
            ignoredValues : [],
            initialDataSet : [],
            multiSelect : true,
            hideTitle : false,
            preventInitialApiCall : false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.captureNewTag = this.captureNewTag.bind(this);
        this.reset = this.reset.bind(this);
        this.loadSuggestions = this.loadSuggestions.bind(this);
        this.selectTag = this.selectTag.bind(this)
        this.fetchResourceList = debounce(500, this.fetchResourceList);
    }

    componentDidMount() {
        let id = "target_list_"+this.props.id;
        let ignoredValues = this.props.ignoredValues;
        this.setState({
            selectedItems : this.props.selectedItems ? this.props.selectedItems : this.state.selectedItems,
            targetId : id,
            initialDataSet: this.props.sourceDataList
        },function(){
            let selectedValues = this.state.selectedItems.map(item=> item[[this.props.valueField]]);
            let selectedItemNames = this.state.selectedItems.map(item=> item[[this.props.labelField]]);
            let finalValuesToBeIgnored = [].concat.apply([], [ignoredValues,selectedValues]);
            this.setState({selectedValues : selectedValues, selectedItemNames : selectedItemNames, ignoredValues : finalValuesToBeIgnored});
        });


        if(this.props.addNewSuggestion) {
            var selectedItemNames = this.state.selectedItems.map(a => a[[this.props.labelFields]]);
            this.setState({
                selectedItemNames : selectedItemNames
            });
        }
        if(this.props.instantSearch) {
            this.setState({
                instantSearch : this.props.instantSearch
            });
        }
        if(this.props.preventInitialApiCall) {
            this.setState({
                preventInitialApiCall : this.props.preventInitialApiCall
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log(this.props.ignoredValues)
        if(prevProps.sourceDataList !== this.props.sourceDataList && this.state.initiateSearch) {
            ////console.log(this.props.sourceDataList);
            //////////////console.log(this.state.selectedValues);
            let finalItemsToBeIgnored = [].concat.apply([], [this.props.ignoredValues,this.state.selectedValues]);

            ////////////console.log(finalItemsToBeIgnored);

            let filteredDataList = this.props.sourceDataList.filter(item => !finalItemsToBeIgnored.includes(item[[this.props.valueField]]));

            this.setState({filteredDataList: filteredDataList})
        }

        if(prevProps.sourceDataList !== this.props.sourceDataList && !this.state.initiateSearch) {
            let initialDataSet = this.props.sourceDataList;
            this.setState({initialDataSet: initialDataSet})
        }

        if(prevState.selectedItems !== this.state.selectedItems) {
            let selectedValues = this.state.selectedItems.map(item=> item[[this.props.valueField]]);
            this.setState({selectedValues : selectedValues});
            let selectedItemNames = this.state.selectedItems.map(item=> item[[this.props.labelFields]]);
            this.setState({selectedItemNames : selectedItemNames});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.selectedItems!==prevState.selectedItems){
            return { selectedItems: nextProps.selectedItems};
        }
        else return null;
    }

    handleInputChange(e) {

        var fieldName = [e.target.id];
        var fieldValue = e.target.value;
        this.setState({initiateSearch : true});

        if(this.state.preventInitialApiCall) { // For preventing the first api call and just filters the intital data
            let filteredDataList = this.setFilteredList(fieldValue);
            console.log(filteredDataList)
            if(filteredDataList.length === 0 && this.state.instantSearch){
                this.fetchResourceList(fieldValue);
            }
        }
        else {
            if(this.state.instantSearch) {
                this.fetchResourceList(fieldValue);
            }
            else{
                this.setFilteredList(fieldValue);
            }
        }


    }

    fetchResourceList = (fieldValue) => {
        this.props.actionMethod(fieldValue);

    }

    setFilteredList =(fieldValue) => {
       // alert("setFilterd list");
        var emptyField = fieldValue;
        ////console.log(this.state.initialDataSet)
        ////console.log(this.props.sourceDataList)
        let sourceDataList = this.state.initialDataSet || [];
        let searchField = this.props.searchField;

        let finalItemsToBeIgnored = [].concat.apply([], [this.props.ignoredValues,this.state.selectedValues]);

        let filteredDataList = sourceDataList.filter((item,index) => {
            if(item[[searchField]].toLowerCase().includes(fieldValue.toLowerCase()) &&
            !finalItemsToBeIgnored.includes(item[[this.props.valueField]])){
                return item;
            }
        });

        this.setState({filteredDataList: filteredDataList,emptyField:emptyField});
        return filteredDataList;
    }

    selectItem(e,item) {
        e.stopPropagation();
        let selectedItems = [...this.state.selectedItems];
        let selectedValues = [...this.state.selectedValues];
        let selectedItemNames = [...this.state.selectedItemNames];
        var fieldLabel = item[[this.props.labelFields]];
        var fieldValue = item[[this.props.valueField]];

        if(this.props.multiSelect === false) {
            selectedItems = [];
            selectedValues = [];
            selectedItemNames = [];
        }

        if(this.props.addNewSuggestion ) {

            if(selectedItemNames) {
                if(!selectedItemNames.includes(fieldLabel)) {
                    selectedItems.push(item);
                    selectedItemNames.push(fieldLabel);
                    selectedValues.push(fieldValue);
                }
            }
            this.setState({selectedItemNames : selectedItemNames})
        }
        else {
            if(selectedItems) {
                if(!selectedValues.includes(fieldValue)) {
                    selectedItems.push(item);
                    selectedValues.push(fieldValue);
                    selectedItemNames.push(fieldLabel);
                }
            }
        }

        let reference = this.props.reference;

        this.setState({selectedItems: selectedItems, selectedValues : selectedValues, selectedItemNames : selectedItemNames, filteredDataList: [], selectedIndex : 0},
            function() {
                this.props.resultDataList(selectedItems, reference, this.props.valueField);
                if(this.state.instantSearch){
                    //this.fetchResourceList();
                }
            });
            document.getElementById('searchInput_'+this.props.reference).value = '';
            document.getElementById('searchInput_'+this.props.reference).focus();

    }

    removeItem(index) {
        let selectedItems = this.state.selectedItems;
        let selectedValues = this.state.selectedValues;
        let selectedItemNames = this.state.selectedItemNames;
        if(selectedItems) {
            selectedItems.splice(index, 1);
            selectedValues.splice(index, 1);
            selectedItemNames.splice(index, 1);
            let reference = this.props.reference;
            this.setState({selectedItems: selectedItems, selectedValues  : selectedValues, selectedItemNames : selectedItemNames},
                function() {
                    ////////////console.log(selectedItems);
                    //////////////console.log(reference);
                    //////////////console.log(this.props.valueField);
                    this.props.resultDataList(selectedItems, reference, this.props.valueField);
                    document.getElementById('searchInput_'+this.props.reference).focus();
                });
        }
    }

    captureNewTag(e) {
        var fieldName = this.props.labelFields;
        var valueField = this.props.valueField;
        if (e.which === 13) {

            //if(this.state.filteredDataList.length === 0) {
            var item_name = e.target.value;
            let matchedItem = this.state.filteredDataList.filter(item=> item_name === item[[fieldName]]);
            var item = {[fieldName]: item_name, [valueField]: ''};

            if(matchedItem.length >0) {
                item = {[fieldName]: item_name, [valueField]: matchedItem[0][[valueField]]};
            }

            this.selectItem(e,item);
                //this.setState({emptyField : ''});
            /*}
            else{
                this.selectItem(e,this.state.filteredDataList[this.state.selectedIndex]);
            }*/

        }

    }
    reset(e) {

        e.target.value = '';
        this.setState({initiateSearch : false});

        if(this.state.selectionStatus === '') {
            this.setState({ filteredDataList: [], selectedIndex : 0});
        }

        if(this.state.selectedItems.length === 0 || this.props.addNewSuggestion === undefined ){
            this.setState({ emptyField: '' });
        }
        this.setSelectionStatus('');
    }

    loadSuggestions(e){
        e.stopPropagation();
        let type = 'next';
        let  selectedIndex = this.state.selectedIndex;
        if (e.which === 38 || e.which === 40 || e.which === undefined ) {
            if(this.state.filteredDataList.length === 0) {

                let sourceDataList = [];
                if (e.which === 38 || e.which === 40) {
                    sourceDataList = this.props.sourceDataList || [];
                }
                else if(e.which === undefined ) {
                    //////////////console.log(this.state.initialDataSet);
                    sourceDataList = this.state.initialDataSet;

                }

                ////////////console.log(sourceDataList);
                let finalItemsToBeIgnored = [].concat.apply([], [this.props.ignoredValues,this.state.selectedValues]);

                let filteredDataList = sourceDataList.filter(item => !finalItemsToBeIgnored.includes(item[[this.props.valueField]]));
                this.setState({filteredDataList: filteredDataList});
            }
            else {
                if (e.which === 38) { //up arrow
                    type = 'prev';
                    selectedIndex = selectedIndex>0? selectedIndex-1 : 0;
                }
                else if (e.which === 40) { //Down arrow
                    type = 'next';
                    let total = this.state.filteredDataList.length;
                    selectedIndex = selectedIndex < total-1? selectedIndex+1 : total-1;
                }
                else if(e.which === undefined ){
                    type = 'next';
                    let total = this.state.filteredDataList.length;
                    selectedIndex = selectedIndex < total-1? selectedIndex+1 : total-1;
                }
            }

            this.setState({
                selectedIndex : selectedIndex
            },function(){
                this.moveToNext(type,this.state.selectedIndex);
            });
        }
    }
    moveToNext(type, selectedIndex) {

        let index = selectedIndex;
        let nextIndex = (type === 'prev') ? (index+1) : (index-1);

        if(document.getElementById(this.state.targetId).childNodes[index])
            document.getElementById(this.state.targetId).childNodes[index].classList.add('selected');

        if(document.getElementById(this.state.targetId).childNodes[nextIndex]) {
            document.getElementById(this.state.targetId).childNodes[nextIndex].classList.remove('selected');
            this.manageView();
        }

    }

    manageView() {
        let containerHeight = document.getElementById(this.state.targetId).offsetHeight;
        let itemTop = document.getElementById(this.state.targetId).childNodes[this.state.selectedIndex].offsetTop;
        let itemHeight = document.getElementById(this.state.targetId).childNodes[this.state.selectedIndex].offsetHeight;

        let scrollTop = document.getElementById(this.state.targetId).scrollTop;

        if( itemTop < scrollTop ) {
            document.getElementById(this.state.targetId).scrollTop = itemTop;
        }
        else if(itemTop > containerHeight) {
            document.getElementById(this.state.targetId).scrollTop = scrollTop+itemHeight;
        }

    }

    selectTag(e) {
        if (e.which === 13) {
            if(this.state.filteredDataList.length > 0) {
                this.selectItem(e,this.state.filteredDataList[this.state.selectedIndex]);
            }
            else{
                this.setState({emptyField : ''});
            }
        }
    }

    setSelectionStatus(type) {
        this.setState({ selectionStatus : type})
    }

    setFocus = () => {
        let ele = document.getElementById("searchInput_"+this.props.reference);
        if(ele) {
            ele.focus();
        }
    }

    render(){
        ////////console.log(this.props.hideTitle)
        var labelFields = this.props.labelFields;
        var labelArr = labelFields.split(',');
        var subLabelField = this.props.subLabelField;
        var eleClass = this.props.eleClass ? this.props.eleClass :  "";

        return(
            <div className={"ind-task-input " + eleClass} onClick={this.setFocus}>
                {!this.props.hideTitle &&
                <span className="lb-tx">{this.props.fieldTitle}</span>
                }

                <div className="tag-wrapper-bl">
                    <div id="to_list" className="tag-task-container">

                        {this.state.selectedItems.map((item, index) =>
                            <div className="sl-tag" key={"labelFields_"+index} >
                                {this.props.showProfile &&
                                <span className="tag-left">{item[[labelArr[0]]].charAt(0).toUpperCase()}</span>
                                }

                                <span className="tag-right">
                                    {labelArr.map((label) =>
                                        item[[label]]+" "
                                    )}
                                    <button className="tag-cl" onClick={()=>this.removeItem(index)}>×</button>
                                </span>
                            </div>
                        )}
                        <input type="text" className="width-dynamic proba dva searchInput"
                           id={"searchInput_"+this.props.reference} placeholder={this.props.placeholder } style={{width: "0px"}} autoComplete="off"  onChange={this.handleInputChange}  onKeyDown={this.loadSuggestions}
                            onKeyPress={(this.props.addNewSuggestion ? this.captureNewTag: this.selectTag)} onBlur={(e)=>this.reset(e)} onClick={this.loadSuggestions}
                               readOnly={this.props.readOnly}/>

                    </div>

                    
                </div>
                <ul className="select-drop" data-action="placeuser"
                    id={"target_list_" + this.props.id} onMouseOver={() => this.setSelectionStatus('InProgress')}
                    onMouseOut={() => this.setSelectionStatus('')}>
                    {this.state.filteredDataList.map((item, index) =>

                        <li onClick={(e) => this.selectItem(e, item)} key={index}>
                            <a
                                href="javascript:void(0);" className="select-drop-click">
                                {this.props.showIcon &&
                                <div className="select-drop-click-left">
                                    <img
                                        src="/assets/images/man.png"/>
                                </div>
                                }
                                <div className="select-drop-click-right">
                                    {!this.props.hideMainLabel &&
                                    <h5>
                                        {labelArr.map((label) =>
                                            item[[label]] + " "
                                        )}
                                    </h5>
                                    }
                                    {this.props.showSubLabel &&
                                    <p>{item[[subLabelField]]}</p>
                                    }
                                </div>
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}


export default AutoComplete;
