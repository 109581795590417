import { render } from 'react-dom';
//import './index.css';
import * as React from 'react';
import { SampleBase } from './sample-base';
import { FileManagerComponent, Inject, NavigationPane, DetailsView, Toolbar, ContextMenu } from '@syncfusion/ej2-react-filemanager';
/**
 * File Manager sample with Amazon S3 file provider service
 */
export class Overview extends SampleBase {
    constructor() {
        super(...arguments);
        this.hostUrl = "https://ej2s3aws.bsynapse.com/api/AmazonS3Provider/";
    }
    render() {
        return (
        <div className="data-room-wrapper">
            <div className="control-section">
                <FileManagerComponent id="filemanager" ajaxSettings={{
                    url: this.hostUrl + 'AmazonS3FileOperations',
                    getImageUrl: this.hostUrl + 'AmazonS3GetImage',
                    uploadUrl: this.hostUrl + 'AmazonS3Upload',
                    downloadUrl: this.hostUrl + 'AmazonS3Download'
                }} searchSettings={{ allowSearchOnTyping: false }} beforeDownload={this.onBeforeDownload.bind(this)} beforeSend={this.onBeforeSend.bind(this)} beforeImageLoad={this.onBeforeImageLoad.bind(this)}  ref={FileManagerComponent => this.FileManagerComponentInstance = FileManagerComponent}>
                    <Inject services={[NavigationPane, DetailsView, Toolbar, ContextMenu]} />
                </FileManagerComponent>
            </div>
        </div>);
    }
    // send rootName from client to server by overriding the ajaxSettings
    onBeforeSend(args) {
        // Assign your preferred your root file name to the below variable and don't change other lines
        var rootFileName = "infrablocks";
        var includeCustomAttribute = JSON.parse(args.ajaxSettings.data);
        //provide your prefered folder name in the below check.name which present in s3 bucket's first level to set it as root folder for file manager
        if(args.action != "Upload"){
            includeCustomAttribute.RootName = rootFileName;
        } else{
            includeCustomAttribute.push({'rootName': rootFileName})
        }
        args.ajaxSettings.data = JSON.stringify(includeCustomAttribute);
    }

    // send rootName from client to server by overriding the imageUrl
    onBeforeImageLoad(args){
        // Assign your preferred your root file name to the below variable and don't change other lines
        var rootFileName = "infrablocks";
        args.imageUrl = args.imageUrl + "&rootName="+rootFileName;
    }

    // send rootName from client to server by overriding the arguement data
    onBeforeDownload(args){
        // Assign your preferred your root file name to the below variable and don't change other lines
        var rootFileName = "infrablocks";
        var includeCustomAttribute = args.data;
        includeCustomAttribute.rootName = "rootFileName";
        args.data = includeCustomAttribute;
    }
}
export default  Overview