import React, {Component, lazy, Suspense} from 'react';
import {Dropdown} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import Toaster from "../../views/Toaster"
import * as collectionActions from "../../../actions/Collections";
import {getUrlSegment} from "../../../utilities/CustomFunctions"
import AlertModal from "../../views/AlertModal";
import {
    FilterTag,
    ActionButton,
    SearchOptionsPanel,
    NewUser,
    TaskAssignContainer,
    InvokeWorkflow
} from "./CollectionDetailData";
import {ResultTable} from "./templates/ResultTable"
import {Template1} from "../ceshareview/Template1"
import {Template2} from "../ceshareview/Template2";
import {Template3} from "../ceshareview/Template3";
import {Template6} from "../ceshareview/Template6";
import {TemplateLongCard} from "../ceshareview/TemplateLongCard";
import {TemplateKanban} from "../ceshareview/TemplateKanbanCard";
import FlagSidepanel from "../ceshareview/FlagSidepanel";
import EditTemplate from "../ceshareview/EditTemplate";
import EditMapTemplate from "../ceshareview/EditMapTemplate";
import {getProfile} from "../../../utilities/AuthService";
import LoaderSvg from "../../views/LoaderSVG";
import Modal from "../../views/Modal"
import * as usersActions from "../../../actions/Users";
import AddToDoTask from "../AddToDoTask"
import SimpleModal from "../../views/SimpleModal"
import AutoComplete from "../../views/AutoComplete";
import {ES_INDEX, API_PATH, FRMSDOC_PATH, USRIMG_PATH} from '../../../constants'
import rootActions from "../../../actions";
import ShowFormModal from "../../views/ShowFormModal";
import ShowFormPreview from "../../views/ShowFormPreview";
import {FileUploadHandler} from "../../../utilities/FileUpload";
import moment from "moment";
import NoData from "../../views/NoData";
import * as taskActions from "../../../actions/ToDo";
import {Scrollbars} from "react-custom-scrollbars";
import CollectionDashBoard from "./CollectionDashBoard"
import {debounce} from "throttle-debounce";
import * as projectActions from "../../../actions/Project";
import UploadFile from "../../../utilities/FileUpload/UploadFile";
import Facets from "../ceshareview/Facets"
import ImportExcel from "../ceshareview/ImportExcel";
import ProjectChat from "../chat/ProjectChat";
import * as WorkFlowActions from "../../../actions/WorkFlow";
import * as CeShareViewActions from "../../../actions/CeShareView";
import FilterdView from "../ceshareview/FilterdView";
import Rule from "./Rule"
import RuleModal from "../../views/RuleModal";
import Schedule from "./Schedule"
import ScheduleModal from "../../views/ScheduleModal";
import * as entityActions from "../../../actions/Entity";
import ApiExplorer from "../../views/ApiExplorer";
import DatePickerBox from "../../views/DatePickerBox";
import BookingCalendar from "../../AppointmentScheduler/sc-pages/BookingCalendar";

const PropagationSettings = lazy(() => import('../ceshareview/PropagationSettings'));
const FilterSettings = lazy(() => import('../ceshareview/FilterSettings'));
const TemplateSettings = lazy(() => import('../ceshareview/TemplateSettings'));
const ProjectcollectionSettings = lazy(() => import('../ceshareview/ProjectcollectionSettings'));
const Template4 = lazy(() => import('../ceshareview/Template4'));
const ExportMetainfo = lazy(() => import('../ceshareview/ExportMetainfo'));
const AddNotes = lazy(() => import('../ceshareview/AddNotes'));
const SystemEntitySettings = lazy(() => import('../ceshareview/SystemEntitySettings'));
const CalendarSettings = lazy(() => import('../ceshareview/CalendarSettings'));
const ParentSettings = lazy(() => import('../ceshareview/ParentSettings'));
const FlagSettings = lazy(() => import('../ceshareview/FlagSettings'));
const QuickFormSettings = lazy(() => import('../ceshareview/QuickFormSettings'));
const FilteredViewSettings = lazy(() => import('../ceshareview/FilteredViewSettings'));
const FormButtonsSettings = lazy(() => import('../ceshareview/FormButtonsSettings'));
const RoleWiseSettings = lazy(() => import('../ceshareview/RoleWiseSettings'));
const FullwidthColumn = lazy(() => import('../ceshareview/FullwidthColumn'));
const SummaryPanelSettings = lazy(() => import('../ceshareview/SummaryPanelSettings'));
const DisplaySettings = lazy(() => import('../ceshareview/DisplaySettings'));

const SummaryCard = lazy(() => import('../ceshareview/SummaryCard'));
const FlagSettingsNew = lazy(() => import('../ceshareview/FlagSettingsNew'));
const PrintSettings = lazy(() => import('../ceshareview/PrintSettings'));
const PaymentSettings = lazy(() => import('../ceshareview/PaymentSettings'));

/* settings nav list */

var dataNav1 = [
    {
        "AcTab": "view-tab",
        "label": "Views",
        "toggle": true,
        "subdataNav":
            [
                {
                    "AcTab": "viewsettings-tab",
                    "label": "List View",
                },
                {
                    "AcTab": "template-setting-tab",
                    "label": "Detail View",
                },
                {
                    "AcTab": "summarypanel-settings",
                    "label": "Summary Panel",
                },
                {
                    "AcTab": "display-settings",
                    "label": "Display Settings",
                }
            ]
    },
    {
        "AcTab": "listing-tab",
        "label": "Filters",
        "toggle": true,
        "subdataNav":
            [
                {
                    "AcTab": "filterd-columns",
                    "label": "Custom Filters",
                },
                {
                    "AcTab": "listingfilter-tab",
                    "label": "Filter Settings",
                },
                {
                    "AcTab": "filter-setting-tab",
                    "label": "Filter Order",
                }
            ]
    },
    {
        "AcTab": "data-tab",
        "label": "Data Management",
        "toggle": true,
        "subdataNav":
            [
                {
                    "AcTab": "propagation-tab",
                    "label": "Propagation",
                },
                {
                    "AcTab": "parent-settings",
                    "label": "Reverse Propagation",
                },
                {
                    "AcTab": "systementity-settings",
                    "label": "User Mapping",
                },
                {
                    "AcTab": "calendar-settings",
                    "label": "Calendar Linking",
                },
                {
                    "AcTab": "pjt-setting-tab",
                    "label": "Project Linking",
                },
                {
                    "AcTab": "manage-flags",
                    "label": "Manage Flags",
                }
            ]
    },
    {
        "AcTab": "permission-tab",
        "label": "Data Permissions",
        "toggle": true,
        "subdataNav":
            [
                {
                    "AcTab": "dataview-tab",
                    "label": "Access Settings",
                },
                {
                    "AcTab": "permissions-tab",
                    "label": "User Permission",
                },
                {
                    "AcTab": "linkpermission-tab",
                    "label": "Parent Entity Permission",
                },
                {
                    "AcTab": "manage-roles",
                    "label": "Role Permission",
                }

            ]
    },
    {
        "AcTab": "field-tab",
        "label": "Print Settings",
        "toggle": true,
        "subdataNav":
            [
                {
                    "AcTab": "print-settings",
                    "label": "Manage Templates",
                }
            ]
    },
    {
        "AcTab": "form-tab",
        "label": "Form Management",
        "toggle": true,
        "subdataNav":
            [
                {
                    "AcTab": "quickform-tab",
                    "label": "Quick Forms",
                },
                {
                    "AcTab": "formbuttons-tab",
                    "label": "Custom Buttons",
                }
            ]
    },
    {
        "AcTab": "export-tab",
        "label": "Export Settings",
        "toggle": true,
        "subdataNav":
            [
                {
                    "AcTab": "exportfield-tab",
                    "label": "Data Exporting",
                }
            ]
    },
    {
        "AcTab": "payment-tab",
        "label": "Payment Settings",
        "toggle": true,
        "subdataNav":
            [
                {
                    "AcTab": "pay-tab",
                    "label": "Payment",
                }
            ]
    },
    {
        "AcTab": "sidePanel-tab",
        "label": "Right Side Panel Settings",
        "toggle": true,
        "subdataNav":
            [
                {
                    "AcTab": "rightSidePanel-tab",
                    "label": "Right Side Panel",
                }
            ]
    }
]

const renderLoader = () => <span className="Loader-holder"><LoaderSvg/></span>;

class CollectionDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 'data',
            selectAll: false,
            checkedRow: [],
            checkedFlag: [],
            showDeleteConfirm: false,
            showDuplicateConfirm: false,
            collapsed: -1,
            search: '',
            filter: [],
            optionOpened: 0,
            fixedScroll: false,
            showFormModal: false,
            showPreviewFormModal: false,
            collectionid: [],
            rightTab: "viewsettings-tab",
            subPan: "viewsettings-tab",
            mainPan: "view-tab",
            fromdate: "",
            todate: "",
            fromfilterdate: "",
            tofilterdate: "",
            index: 0,
            // saveExportField:[],
            savedataView: [],
            own_record: '',
            creator_group: '',
            report_person: '',
            set_project: '',
            saveSettings: [],
            optionSettings: [],
            showdatefilter: '',
            disableWorkflowPanel: '',
            csvImportMsg: '',
            savedataview: [],
            AddNew: '',
            assignUserSelect: false,
            mailNotification: false,
            chatNotification: false,
            assignUsers: [],
            showpermissionsarray: [],
            entity_perm: false,
            loadmorecount: 50,
            datalevelSelect: false,
            filterDate: "",
            numberFilter: [],
            daysfilters: [],
            datefilters: [],
            filterUserdata: [],
            recId: '',
            template: '',
            buttonStatus: false,
            mappedData: [],
            defaultTemplate: '',
            mappedDatavertical: [],
            mappedDatahorizontal: [],
            mappedDatamini: [],
            mappedDataLong1Temp: [],
            mappedDataKanbanTemp: [],
            tabView: 'list',
            horizontalImage: "",
            verticalImage: "",
            miniImage: "",
            long1Image: "",
            showtemplateOptions: false,
            collectededitid: '', customizeDateFrom: '', customizeDateTo: '', flterData: [], FilterLabel: [],
            showAddNewTaskModal: true,
            horizontalMapenabled: "",
            verticalMapenabled: '',
            miniMapenabled: '',
            maptemplate: [],
            importstep: 1,
            excelimportstep: 1,
            miniMize: false,
            Maximise: false,
            csvparam: '',
            csvImportfalse: '',
            dashStat: false,
            projectId: '',
            mapLoad: false,
            viewType: "",
            changeApi: false,
            selectedFile: null,
            toasterdata: {type: "", msg: "", show: false},
            excelImportMsg: '',
            excelImportfalse: '',
            starred: false,
            showInvokeWorkflow: false,
            sortData: [],
            sort: "asc",
            sortParam: [],
            msgOpn: false,
            msgCnt: '',
            csvMsg: '',
            csvCode: '',
            csvData: [],
            csvErrorModal: false,
            recordshowid: [],
            excelCode: '',
            excelData: [],
            excelErrorModal: false,
            tPane: false,
            selworkflow: "",
            wloader: 0,
            recordVersionid: '',
            shareToAll: false,
            flagState: [],
            flagId: [],
            searchValue: [],
            flagPan: false,
            showShareColumn: false,
            showNotesColumn: false,
            showCreateDateColumn: false,
            showCalendarColumn: false,
            calendarFields: [],
            clearFlagID: [],
            validateFlag: false,
            validateFlagModal: false,
            setFilter: "",
            dateopen: false,
            showChat: false,
            showPublishStatus: false,
            assigneeList: [],
            AssigneLabel: [],
            showQuickform: false,
            quickFormList: [],
            quickFormSelect: false,
            roleid: '',
            quickformid: '',
            submitQuickForm_error: false,
            editPermission: false,
            editPermissionStatus: false,
            selectedId: '',
            ownrecords: '',
            creatorgroups: '',
            creatorreporting: '',
            normalForm: '',
            Allrecords: '',
            Ownrecords: '',
            Assignedrecords: '',
            editLoader: false,
            workflowFilter: [],
            urlCopied: false,
            showBookingStatus: false,
            loggedIn: false,
            modal: false,
            showModal: false,
            modalMessage: '',
            modalHeader: '',
            modalOKaction: '',
            modalShowCancel: false,
            modalCancelAction: '',
            modalSize: 'md',//xl, lg, md, sm
            t: new Date().getTime(),
            user: {},
            bookingProps: {},
            bookingStatus: '',
            bookingDate: '',
            bookingTime: '',
            bookingResultCode: '',
            bookingcustId: '',
            bookingCustName: '',
            bookingCustId: '',
            bookingCustPhone: '',
            bookingCustEmail: '',
            bookingCustUid: '',
            custInfo: []

        }
        // if(NEW_VERSION){
        //     dataNav.push(
        //         {
        //             "AcTab": "task-tab",
        //             "label":"Task Settings",
        //         })
        // }
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectRow = this.handleSelectRow.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.showDuplicateConfirm = this.showDuplicateConfirm.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.handlefilterSearch = this.handlefilterSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDuplicate = this.handleDuplicate.bind(this);
        this.manageOptionsDropDown = this.manageOptionsDropDown.bind(this);
        this.shareCollection = this.shareCollection.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.goBack = this.goBack.bind(this);
        this.showFormModal = this.showFormModal.bind(this);
        this.hideFormModal = this.hideFormModal.bind(this);
        this.showPreviewFormModal = this.showPreviewFormModal.bind(this);
        this.hidePreviewForm = this.hidePreviewForm.bind(this)
        this.getUsers = this.getUsers.bind(this);
        this.showAssignedUsers = this.showAssignedUsers.bind(this);
        this.AddnewhandleAction = this.AddnewhandleAction.bind(this);
        this.linkingPermission = this.linkingPermission.bind(this);
        this.showlinkingPermission = this.showlinkingPermission.bind(this);
        this.showSettingsModal = this.showSettingsModal.bind(this);
        this.showdatalevelAssignedUsers = this.showdatalevelAssignedUsers.bind(this);
        this.saveDataLevel = this.saveDataLevel.bind(this);
        this.submitAdvncSearch = this.submitAdvncSearch.bind(this);
        this.handleViewChange = this.handleViewChange.bind(this);
        this.closeInvokeModal = this.closeInvokeModal.bind(this);
        this.invokeWorkflow = this.invokeWorkflow.bind(this);
        this.editOption = this.editOption.bind(this);
        this.editPermission = this.editPermission.bind(this);
        this.editPermissionClose = this.editPermissionClose.bind(this);
        this.loadmoreCollectionData = debounce(500, this.loadmoreCollectionData);
        this.handleDataSearch = debounce(500, this.handleDataSearch);
        this.changeNotes = debounce(500, this.changeNotes);

    }

    componentDidMount() {
        let collectionid;
        let projectid = '';
        if (getUrlSegment(1) === 'project') {
            collectionid = (atob(getUrlSegment(4)));
            projectid = getUrlSegment(5) ? (atob(getUrlSegment(5))) : '';
            this.props.mainClick('project', '/project/details/collectiondetails/' + atob(getUrlSegment(5)));
            if (projectid !== '') {
                let data = {
                    "_id": projectid,
                    pagename: 'collections'
                }
                this.props.projectInfo(data);
            }
        } else {
            collectionid = (atob(getUrlSegment(3)));
        }
        this.setState({collectionId: collectionid, colID: collectionid, projectId: projectid, uid: getProfile().id});
        let paramet = {
            _id: collectionid,
            uid: getProfile().id,
        }
        let param = {
            from: 0,
            size: this.state.loadmorecount,
            index: ES_INDEX,
            search: '',
            "uid": getProfile().id,
            "usertype": getProfile().usertype,
            "dashboard": true,
            "facetrequired": true,
            "fieldview": true,
        }
        let params = {
            "collectionid": collectionid

        }
        if (getUrlSegment(1) === 'project') {
            param.projectid = projectid;
        }
        this.props.getsharedSettingsData(paramet)
        this.props.collectionDetails(collectionid, param)
        this.props.collectionFilter(collectionid, param)
        this.props.getCollectionconfig(params);
        this.props.listShareNotes(params);
        this.props.getFilterData({
            collectionid: collectionid,
            uid: getProfile().id,
            usertype: getProfile().systype,
            refer: "collection"
        })
        this.props.showform({_id: collectionid, uid: getProfile().id});
        this.props.users(getProfile().id, 'User', '', ES_INDEX, 0, 500);
        const container = document.querySelector('.infinte-scroll');
        if (container) {
            container.addEventListener('scroll', this.listenToScroll);
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        let collectionid = this.state.collectionId;

        if (this.props.deletedRec !== prevProps.deletedRec) {

            if (this.props.deletedRec.status.success) {
                this.setState({
                    toasterdata: {
                        type: "green",
                        msg: this.props.deletedRec.status.total + " " + (this.props.deletedRec.status.total === 1 ? "Record " : "Records ") + " deleted successfully",
                        show: true
                    }
                })
            } else {
                this.setState({toasterdata: {type: "red", msg: "Something went wrong", show: true}})
            }
        }
        if (this.props.recordDuplicate !== prevProps.recordDuplicate) {
            if (this.props.recordDuplicate.data.success) {
                this.setState({toasterdata: {type: "green", msg: "Record duplicated successfully", show: true}})
                let param = {
                    from: 0,
                    size: this.state.loadmorecount,
                    index: ES_INDEX,
                    search: '',
                    "uid": getProfile().id,
                    "usertype": getProfile().usertype,
                    "dashboard": true,
                    "facetrequired": true,
                    "fieldview": true,
                }
                setTimeout(function () {
                    beta.props.collectionDetails(collectionid, param)

                }, 1000)
            } else {
                this.setState({toasterdata: {type: "red", msg: "Something went wrong", show: true}})
            }
        }

        if (prevProps.collectionDataChanged.collection_data_modified !== this.props.collectionDataChanged.collection_data_modified) {
            setTimeout(function () {
                beta.handleDataSearch();
            }, 1000)
        }

        if (prevProps.showFormModal.showformdata !== this.props.showFormModal.showformdata) {
            if (this.state.showQuickform === true && this.state.quickformid !== '' && this.props.showFormModal.showformdata.length !== 0) {
                this.showFormModal();
                this.setState({roleid: '', quickformid: ''})
            }
        }

        if (this.state.collectionId !== prevState.collectionId) {
            this.props.resetcollectionData()
        }
        /* Map AsyncLoadMore */

        if ((parseInt(this.props.collectionData.totalchilds - 1) >= parseInt(this.props.collectionData.collData.length))) {

            if (this.props.collectionData.collData.length !== prevProps.collectionData.collData.length) {
                if (this.props.collectionData.CollectionInfo.default_template === "map" && this.state.clusterId.length === 0) {
                    if (parseInt(this.props.collectionData.totalchilds) >= parseInt(this.props.collectionData.collData.length)) {
                        let that = this
                        if (((this.state.viewType === "map") && (this.state.clearScrollid !== this.props.collectionData.scrollid))) {
                            that.maploadmoreData(this.props.collectionData.scrollid)
                            this.setState({currntScrollid: this.props.collectionData.scrollid})
                        }


                    }
                }
            }

        }

        if (prevProps.saveAssigndata.results !== this.props.saveAssigndata.results) {
            this.setState({action: '', assignUserSelect: false, checkedRow: ''});
            let param = {
                "from": 0,
                "size": this.state.loadmorecount,
                "search": this.state.search,
                "index": ES_INDEX,
                "usertype": getProfile().usertype,
                "uid": getProfile().id,
                "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                "filters": this.state.flterData,
                "datefilters": this.state.datefilters,
                "dayfilters": this.state.daysfilters,
                "numberfilters": this.state.numberFilter,
                "dashboard": true,
                "facetrequired": true,
                "fieldview": true,
                assignees: this.state.assignees,
                sort: this.state.sortParam,
                starred: this.state.starred,
                flags: this.state.flagId,

                //          listdata:((this.state.defaultTemplate ===''? this.props.collectionData.CollectionInfo.default_template:this.state.defaultTemplate === "list")?true:false)
            }
            if (getUrlSegment(1) === 'project') {
                param.projectid = this.state.projectId;
            }
            setTimeout(function () {
                beta.props.collectionDetails(collectionid, param)

            }, 1000)
        }

        if (prevProps.ColUpdate !== this.props.ColUpdate) {
            this.setState({action: '', assignUserSelect: false, checkedRow: ''});
            let param = {
                "from": 0,
                "size": this.state.loadmorecount,
                "search": this.state.search,
                "index": ES_INDEX,
                "usertype": getProfile().usertype,
                "uid": getProfile().id,
                "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                "filters": this.state.flterData,
                "datefilters": this.state.datefilters,
                "dayfilters": this.state.daysfilters,
                "numberfilters": this.state.numberFilter,
                "dashboard": true,
                assignees: this.state.assignees,
                "facetrequired": true,
                "fieldview": true,
                sort: this.state.sortParam,
                starred: this.state.starred,
                flags: this.state.flagId,

                //          listdata:((this.state.defaultTemplate ===''? this.props.collectionData.CollectionInfo.default_template:this.state.defaultTemplate === "list")?true:false)
            }
            if (getUrlSegment(1) === 'project') {
                param.projectid = this.state.projectId;
            }
            setTimeout(function () {
                beta.props.collectionDetails(collectionid, param)

            }, 1000)
        }


        if (prevProps.importCSVData !== this.props.importCSVData) {
            let csvMsg = this.props.importCSVData.csvMsg ? this.props.importCSVData.csvMsg : '';
            let csvCode = this.props.importCSVData.csvCode ? this.props.importCSVData.csvCode : '';
            if (csvCode !== 1) {
                let csvData = this.props.importCSVData.csvData && this.props.importCSVData.csvData.data ? this.props.importCSVData.csvData.data : '';
                this.setState({csvData: csvData, csvMsg: csvMsg, csvCode: csvCode, csvErrorModal: true})
            }
        }
        if (prevProps.csvImportData !== this.props.csvImportData) {

            if (collectionid === this.props.csvImportData.csvstatus.collectionid) {
                let param = {
                    "from": 0,
                    "size": this.state.loadmorecount,
                    "search": this.state.search,
                    "index": ES_INDEX,
                    "usertype": getProfile().usertype,
                    "uid": getProfile().id,
                    "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                    "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                    "filters": this.state.flterData,
                    "datefilters": this.state.datefilters,
                    "dayfilters": this.state.daysfilters,
                    "numberfilters": this.state.numberFilter,
                    "dashboard": true,
                    assignees: this.state.assignees,
                    "facetrequired": true,
                    "fieldview": true,
                    sort: this.state.sortParam,
                    starred: this.state.starred,
                    flags: this.state.flagId,

                    //          listdata:((this.state.defaultTemplate ===''? this.props.collectionData.CollectionInfo.default_template:this.state.defaultTemplate === "list")?true:false)
                }
                setTimeout(function () {
                    if (getUrlSegment(1) === 'project') {
                        param.projectid = beta.state.projectId;
                    }
                    beta.props.collectionDetails(collectionid, param);
                }, 1000)
            }
        }

        if (prevProps.excelImportData !== this.props.excelImportData) {
            if (collectionid === this.props.excelImportData.excelstatus.collectionid) {
                let param = {
                    "from": 0,
                    "size": this.state.loadmorecount,
                    "search": this.state.search,
                    "index": ES_INDEX,
                    "usertype": getProfile().usertype,
                    "uid": getProfile().id,
                    "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                    "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                    "filters": this.state.flterData,
                    "datefilters": this.state.datefilters,
                    "dayfilters": this.state.daysfilters,
                    "numberfilters": this.state.numberFilter,
                    "dashboard": true,
                    assignees: this.state.assignees,
                    sort: this.state.sortParam,
                    starred: this.state.starred,
                    flags: this.state.flagId,

                    //          listdata:((this.state.defaultTemplate ===''? this.props.collectionData.CollectionInfo.default_template:this.state.defaultTemplate === "list")?true:false)
                }
                setTimeout(function () {
                    if (getUrlSegment(1) === 'project') {
                        param.projectid = beta.state.projectId;
                    }
                    beta.props.collectionDetails(collectionid, param);
                }, 1000)
            }
        }

        if (this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged || this.props.getSavedForm.draftSaved !== prevProps.getSavedForm.draftSaved) {
            // this.props.collectionFilter(this.state.collectionId, '', []);
            let param = {
                "from": 0,
                "size": this.state.loadmorecount,
                "search": this.state.search,
                "index": ES_INDEX,
                "usertype": getProfile().usertype,
                "uid": getProfile().id,
                "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                "filters": this.state.flterData,
                "datefilters": this.state.datefilters,
                "dayfilters": this.state.daysfilters,
                "numberfilters": this.state.numberFilter,
                "dashboard": true,
                assignees: this.state.assignees,

                "facetrequired": true,
                "fieldview": true,
                sort: this.state.sortParam,
                starred: this.state.starred,
                flags: this.state.flagId,

            }
            setTimeout(function () {
                if (getUrlSegment(1) === 'project') {
                    param.projectid = beta.state.projectId;
                }

                beta.props.collectionFilter(collectionid, param);
                beta.props.collectionDetails(collectionid, param);
            }, 1000)
        }
        if (this.props.fieldUpdateData.data !== prevProps.fieldUpdateData.data) {
            if (this.props.fieldUpdateData.data.success === true) {
                // this.props.collectionFilter(this.state.collectionId, '', []);
                let param = {
                    "from": 0,
                    "size": this.state.loadmorecount,
                    "search": this.state.search,
                    "index": ES_INDEX,
                    "usertype": getProfile().usertype,
                    "uid": getProfile().id,
                    "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                    "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                    "filters": this.state.flterData,
                    "datefilters": this.state.datefilters,
                    "dayfilters": this.state.daysfilters,
                    "numberfilters": this.state.numberFilter,
                    "dashboard": true,
                    "facetrequired": true,
                    "fieldview": true,
                    sort: this.state.sortParam,
                    starred: this.state.starred,
                    flags: this.state.flagId,

                }
                setTimeout(function () {
                    if (getUrlSegment(1) === 'project') {
                        param.projectid = beta.state.projectId;
                    }

                    beta.props.collectionFilter(collectionid, param);
                    beta.props.collectionDetails(collectionid, param);
                }, 1000)
            }
        }
        if (this.props.saveLinkingPermission.savelinkingpermission !== prevProps.saveLinkingPermission.savelinkingpermission) {
            this.setState({entity_perm: true})
            let param = {
                "from": 0,
                "size": this.state.loadmorecount,
                "search": this.state.search,
                "index": ES_INDEX,
                "usertype": getProfile().usertype,
                "uid": getProfile().id,
                "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                "filters": this.state.flterData,
                "datefilters": this.state.datefilters,
                "dayfilters": this.state.daysfilters,
                "numberfilters": this.state.numberFilter,
                "dashboard": true,
                assignees: this.state.assignees,
                "facetrequired": true,
                fieldview: true,
                sort: this.state.sortParam,
                starred: this.state.starred,
                flags: this.state.flagId,

            }
            setTimeout(function () {
                if (getUrlSegment(1) === 'project') {
                    param.projectid = beta.state.projectId;
                }

                beta.props.collectionDetails(collectionid, param);
                beta.setState({entity_perm: false})
            }, 1000)
        }
        if (this.props.savadataSettings.savadatalevelSettings !== prevProps.savadataSettings.savadatalevelSettings) {
            this.setState({datalevelSelect: false});
            let data = {
                "collectionid": collectionid
            }
            setTimeout(function () {
                beta.props.getCollectionconfig(data);
            }, 1000)
        }
        if (this.props.GetCollectionconfig.getcollectionconfig !== prevProps.GetCollectionconfig.getcollectionconfig) {
            let saveSettings = this.props.GetCollectionconfig && this.props.GetCollectionconfig.getcollectionconfig && this.props.GetCollectionconfig.getcollectionconfig.settings ? this.props.GetCollectionconfig.getcollectionconfig.settings.data : '';
            saveSettings.map(item => {
                item.id = item.id ? item.id : item.permissionto;
                item.name = item.name ? item.name : item.fullname;
                item.user_type = item.user_type ? item.user_type : item.category;
                item.role = item.role ? item.role : '';
                item.uniqueId = item.uniqueId ? item.uniqueId : item.category && item.category.toLowerCase() + "_" + item.id;
                return item;
            })
            this.setState({saveSettings: saveSettings})

        }
        if (this.props.getWorkflowStatus !== prevProps.getWorkflowStatus) {
            if (this.props.getWorkflowStatus.workflow !== prevProps.getWorkflowStatus.workflow) {
                let linkedworkflow = {
                    uid: getProfile().id,
                    usertype: getProfile().usertype
                }
                setTimeout(function () {
                    beta.props.linkedWorkflow(beta.state.collectionId, linkedworkflow)
                }, 1000)
            }
            if (this.props.getWorkflowStatus.generic !== prevProps.getWorkflowStatus.generic) {
                let ruleParam = {
                    "masterid": this.state.colID,
                }

                setTimeout(function () {
                    beta.props.getRule(ruleParam)
                }, 1000)
            }

            if (this.props.getWorkflowStatus.fieldwise !== prevProps.getWorkflowStatus.fieldwise) {
                let ruleParam = {
                    "masterid": this.state.colID,
                }

                setTimeout(function () {
                    beta.props.getRule(ruleParam)
                }, 1000)
            }

        }
        if (this.props.getCollectionTemplate !== prevProps.getCollectionTemplate) {
            let param = {
                "from": 0,
                "size": this.state.loadmorecount,
                "search": this.state.search,
                "index": ES_INDEX,
                "usertype": getProfile().usertype,
                "uid": getProfile().id,
                "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                "filters": this.state.flterData,
                "datefilters": this.state.datefilters,
                "dayfilters": this.state.daysfilters,
                "numberfilters": this.state.numberFilter,
                "dashboard": true,
                assignees: this.state.assignees,

                "facetrequired": true,
                fieldview: true,
                sort: this.state.sortParam,
                starred: this.state.starred,
                flags: this.state.flagId,

            }
            setTimeout(function () {
                if (getUrlSegment(1) === 'project') {
                    param.projectid = beta.state.projectId;
                }
                beta.props.collectionDetails(collectionid, param);
            }, 1000)
        }
        if (prevProps.HistoryPushData !== this.props.HistoryPushData) {

            this.setState({showAddNewTaskModal: false, addOptions: false, action: "", checkedRow: ''});
        }
        if (this.props.collectionData.CollectionInfo !== prevProps.collectionData.CollectionInfo) {
            let collectionInfo = this.props.collectionData.CollectionInfo;
            if (collectionInfo.length !== 0) {
                let showQuickform = collectionInfo.show_quickform ? collectionInfo.show_quickform : false;
                if (showQuickform === true) {
                    let param = {
                        "collection": collectionid,
                        "uid": getProfile().id
                    }
                    this.props.quickFormList(param);
                }
                let showchat = collectionInfo.showchat ? collectionInfo.showchat : false;
                let showwf_info = collectionInfo.showwf_info ? collectionInfo.showwf_info : false;
                this.setState({
                    dateopen: collectionInfo.dateopen,
                    showChat: showchat,
                    disableWorkflowPanel: showwf_info,
                    showQuickform: showQuickform
                });
            }
        }
        if (this.props.listQuickForm.data !== prevProps.listQuickForm.data) {
            if (this.props.listQuickForm.data.length !== 0) {
                this.setState({quickFormList: this.props.listQuickForm.data})
            }
        }

        if (this.props.invokeworkflowdata !== prevProps.invokeworkflowdata) {
            this.setState({showInvokeWorkflow: !this.state.showInvokeWorkflow})
            if (this.props.invokeworkflowdata.status) {
                this.setState({checkedRow: [], wloader: 0});
                this.setState({
                    toasterdata: {
                        type: "green",
                        msg: this.props.invokeworkflowdata.status.message,
                        show: true
                    }
                })
            } else {
                this.setState({toasterdata: {type: "red", msg: "Something went wrong", show: true}})
            }
        }
        if (this.props.shareAllInfo !== prevProps.shareAllInfo) {
            if (this.props.shareAllInfo.data.success) {
                this.setState({
                    toasterdata: {
                        type: "green",
                        msg: "Records Assigned Successfully",
                        show: true,

                    },

                })
            } else {
                this.setState({toasterdata: {type: "red", msg: "Something went wrong", show: true}})

            }
        }

        if (this.props.listShareNote.data !== prevProps.listShareNote.data) {
            if (this.props.listShareNote.data.length !== 0) {
                let showShareColumn = this.props.listShareNote.data[0].share;
                let showNotesColumn = this.props.listShareNote.data[0].notes;
                let showCreateDateColumn = this.props.listShareNote.data[0].showdate;
                let showCalendarColumn = this.props.listShareNote.data[0].calendar;
                let calendarFields = this.props.listShareNote.data[0].calendar_fields;
                this.setState({
                    showShareColumn: showShareColumn,
                    showNotesColumn: showNotesColumn,
                    showCreateDateColumn: showCreateDateColumn,
                    showCalendarColumn: showCalendarColumn,
                    calendarFields: calendarFields
                })
            }

        }


    }

    componentWillUnmount() {
        const container = document.querySelector('.infinte-scroll');
        if (container) {
            container.removeEventListener('scroll', this.listenToScroll)
        }
    }

    componentWillMount = props => {
        this.clickTimeout = null
    }
    panelClose = () => {
        this.setState({flagPan: false, checkedRow: []})
        this.props.colUpdate()

    }
    submitAdvncSearch = (value) => {
        this.setState({searchValue: value})
        this.handleDataSearch(this.state.search, this.state.flterData, '', value);
    }

    FlagPanelClose = () => {
        this.setState({flagPan: false, validateFlagModal: false, checkedRow: []})
        //this.setState({flagPan:false,checkedRow:[]})
        this.props.colUpdate()
    }
    handleClicks = (e, id, index, permStat) => {

        if (this.clickTimeout !== null) {
            if (permStat) {
                this.hideFormModal(e, id)
            } else {
                this.showPreviewFormModal(e, id, index)
            }
            clearTimeout(this.clickTimeout)
            this.clickTimeout = null
        } else {
            this.clickTimeout = setTimeout(() => {

                this.handleSelectRow(id)
                clearTimeout(this.clickTimeout)
                this.clickTimeout = null
            }, 250)
        }
    }


    buttonChange = (sta) => {
        this.setState({buttonStatus: !sta})
    }

    handleFromChange() {
    }

    filterToggle = () => {
        this.setState({tPane: !this.state.tPane})
    }


    handleApicall = (type, Filters) => {
        let cId = Filters.hasOwnProperty("collectionid") ? Filters.collectionid : this.state.collectionId
        if (type !== "map") {
            let param = {
                "from": 0,
                "size": this.state.loadmorecount,
                "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                "search": Filters.hasOwnProperty("search") ? Filters.search : this.state.search,
                "filters": Filters.hasOwnProperty("flterData") ? Filters.flterData : this.state.flterData,
                "datefilters": Filters.hasOwnProperty("datefilters") ? Filters.datefilters : this.state.datefilters,
                "dayfilters": Filters.hasOwnProperty("daysfilters") ? Filters.daysfilters : this.state.daysfilters,
                "numberfilters": Filters.hasOwnProperty("numberFilter") ? Filters.numberFilter : this.state.numberFilter,
                "uid": getProfile().id,
                "usertype": getProfile().usertype,
                "dashboard": true,
                "listdata": false,
                "facetrequired": true,
                fieldview: true,
                sort: this.state.sortParam,
                starred: this.state.starred,
                flags: this.state.flagId,

            }
            if (getUrlSegment(1) === 'project') {
                param.projectid = this.state.projectId;
            }
            this.props.collectionDetails(cId, param)
        }

        if (type === "map" || type === "cluster") {
            var displaylist = []
            if (this.props.collectionData.maptemplate.length !== 0) {
                let showid = this.props.collectionData.maptemplate
                displaylist = [showid[0].item1.masterid, showid[0].item2.masterid, showid[0].item3.masterid]
            }
            let params = {
                "_id": cId,
                "usertype": getProfile().usertype,
                "uid": getProfile().id,
                'displaylist': displaylist,
                "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                "search": Filters.hasOwnProperty("search") ? Filters.search : this.state.search,
                "filters": Filters.hasOwnProperty("flterData") ? Filters.flterData : this.state.flterData,
                "datefilters": Filters.hasOwnProperty("datefilters") ? Filters.datefilters : this.state.datefilters,
                "dayfilters": Filters.hasOwnProperty("daysfilters") ? Filters.daysfilters : this.state.daysfilters,
                "numberfilters": Filters.hasOwnProperty("numberFilter") ? Filters.numberFilter : this.state.numberFilter,
                "refer": "collection",
                "per_page": 5000,
                "facetrequired": true,
                fieldview: true,
                sort: this.state.sortParam,
                starred: this.state.starred,
                flags: this.state.flagId,


            }
            if (getUrlSegment(1) === 'project') {
                params.projectid = this.state.projectId;
            }
            this.setState({displaylist: displaylist, clusterId: cId})
            this.props.maploadmoreCollectionDetails(cId, params)
        }


    }

    handleViewChange(type) {
        let filter = []
        this.setState({viewType: type, clusterId: ''})
        this.handleApicall(type, filter)
        //   this.props.viewChangelimit(100)

    }

    selectedQuickForm = (e) => {
        let collectionid = this.state.collectionId;
        if (this.state.roleid !== '' && this.state.quickformid !== '') {
            this.props.showform({
                _id: collectionid,
                uid: getProfile().id,
                roleid: this.state.roleid,
                quickformid: this.state.quickformid
            });
        } else if (this.state.quickformid !== '') {
            this.props.showform({_id: collectionid, uid: getProfile().id, quickformid: this.state.quickformid});
        } else if (this.state.normalForm === 'normal') {
            this.showFormModal(e);
        } else {
            this.setState({submitQuickForm_error: true})
        }
    }
    closeQuickFormModal = () => {
        this.setState({
            quickFormSelect: false,
            submitQuickForm_error: false,
            roleid: '',
            quickformid: '',
            normalForm: ''
        })
    }
    handleQuickFormChange = (e, quickformid, roleid, normalForm) => {
        this.setState({roleid: roleid, quickformid: quickformid, submitQuickForm_error: false, normalForm: normalForm})
    }
    showFormModalTypeSelect = (e) => {
        if (this.state.showQuickform === true) {
            this.setState({
                quickFormSelect: true,
                submitQuickForm_error: false,
                roleid: '',
                quickformid: '',
                normalForm: ''
            })
        } else {
            this.showFormModal(e)
        }
    }
    showFormModal = (e, collecteddataid) => {
        let collectionid = this.state.collectionId
        this.props.resetForm();
        if (collecteddataid) {
            this.props.getFormData({_id: collecteddataid, collectionid: collectionid, metainfo: true});
        }
        this.setState({
            formName: this.props.collectionData.CollectionInfo.title,
            formDesc: this.props.collectionData.CollectionInfo.description,
            collectionid: collectionid,
            collecteddataid: collecteddataid,
            showFormModal: true,
            quickFormSelect: false
        })
    }

    showSettingsModal() {
        this.setState({
            assignUserSelect: true
        })
    }

    hideFormModal(id) {
        this.setState({
            showFormModal: false
        })
    }

    showPreviewFormModal(e, id, index) {
        let collectionid = this.state.collectionId;
        let params = {_id: id, collectionid: collectionid, metainfo: true, wfinfo: true};

        let collectionData = this.props.collectionData ? this.props.collectionData.CollectionData : [];
        let colRecordArr = collectionData.filter(row => row.id.toString() === id.toString() && row.users.includes(parseInt(getProfile().id)));
        if (colRecordArr.length > 0) {
            params.wfinfo = true;
        }
        this.props.getFormData(params);
        let recordshowid = [id]
        let recordVersionid = id
        this.setState({
            collectionid: collectionid,
            showPreviewForm: true,
            index: index,
            _id: id,
            recordshowid: recordshowid,
            recordVersionid: recordVersionid
        })
        //  this.onNoteClick(e,id)
    }

    hidePreviewForm() {
        this.setState({
            showPreviewForm: false
        })
    }

    showDeleteConfirm() {
        this.setState({showDeleteConfirm: !this.state.showDeleteConfirm});
    }

    handleDelete() {
        this.showDeleteConfirm();
        let collectionid = this.state.collectionId
        let that = this;
        this.props.collectionDataDelete(collectionid, getProfile().id, this.state.checkedRow);
        setTimeout(function () {
            that.props.collectionFilter(collectionid, '', []);
            that.setState({
                'search': '',
                'filter': [],
                'checkedRow': []
            });
        }, 3000);
    }

    showDuplicateConfirm() {
        this.setState({showDuplicateConfirm: !this.state.showDuplicateConfirm});
    }

    handleDuplicate() {
        this.showDuplicateConfirm();
        let collectionid = this.state.collectionId
        let that = this;
        let param = {
            "cid": collectionid,
            "recordid": this.state.checkedRow.toString(),
            "uid": getProfile().id
        }
        this.props.collectionRecordDuplicate(param);
        setTimeout(function () {
            that.props.collectionFilter(collectionid, '', []);
            that.setState({
                'search': '',
                'filter': [],
                'checkedRow': []
            });
        }, 3000);
    }

    selectExport = (type) => {
        let title = (this.props.collectionData.CollectionInfo.title)
        let collectionid = this.state.colID;
        var url = API_PATH + "/export/exportexcel";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var blob = this.response;
                var header = xhr.getResponseHeader('Content-Disposition');
                var filename = title + ".xlsx";
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var downloadLink = window.document.createElement('a');
                    var contentTypeHeader = xhr.getResponseHeader("Content-Type");
                    downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: contentTypeHeader}));
                    downloadLink.download = filename;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
            }
        };
        xhr.send("ids=" + this.state.checkedRow + "&collectionid=" + collectionid + "&index=" + ES_INDEX + "&showrecordid=" + type + "&flags=" + JSON.stringify(this.state.flagId));
    }

    handleSelectAll() {
        let checkedRow = [];
        if (this.state.selectAll === false) {
            checkedRow = this.props.collectionData.collectionIDs;
        }
        this.setState({
            selectAll: !this.state.selectAll,
            checkedRow: checkedRow,
            shareToAll: false
        });
    }

    handleSelectRow(id) {
        let checkedRow = [...this.state.checkedRow];
        (checkedRow.indexOf(id) > -1) ? checkedRow.splice(checkedRow.indexOf(id), 1) : checkedRow.push(id);


        // if (checkedRow.length === 1){
        //     let row=this.props.collectionData.CollectionData.filter(list=>list.id === id)
        //     let rowIndex=(this.props.collectionData.CollectionData.findIndex(list=>list.id === id))
        //     var parm = {
        //         row: row[0],
        //         rowIndex:rowIndex,
        //      }
        // }
        // else{
        //
        //     let row={'flags':[]}
        //     var parm = {
        //         row: row,
        //         rowIndex:0
        //     }
        //
        // }
        //this.setState({checkedRow: checkedRow,shareToAll:false});
        this.setState({checkedRow: checkedRow, shareToAll: false, flagPan: true});

    }

    removeSelectedRecord = () => {
        this.setState({checkedRow: []});
    }
    removeSelectedRecordPreview = () => {
        this.setState({recordshowid: [], recordVersionid: ''});
    }


    showdatalevelAssignedUsers() {
        let saveSettings = [];
        this.state.saveSettings.map(item => {
            item.name = (item.name ? item.name.toLowerCase() : '');
            item.user_id = item.permissionto;
            item.user_type = item.category;
            return item;
        });
        this.setState({datalevelSelect: !this.state.datalevelSelect});
    }

    /* assigned users popup */
    showAssignedUsers(assignUsers, recId) {
        let newUsers = []
        assignUsers.map((list) =>
            newUsers.push(this.props.listUsers.data && this.props.listUsers.data.filter(item => (item.user_id === list.toString() && item.category === "user")))
        )
        let filterUserdata = []
        for (var i = 0; i < newUsers.length; i++) {
            if (newUsers[i][0] !== undefined) {
                filterUserdata.push(newUsers[i][0])
            }
        }
        this.setState({
            assignUserSelect: !this.state.assignUserSelect,
            assignUsers: assignUsers,
            filterUserdata: filterUserdata,
            recId: [recId]
        });
    }

    toggleAccordion(id) {
        let collapsed = (this.state.collapsed === id) ? -1 : id;
        this.setState({collapsed: collapsed})
    }

    getdatalevelUsers(searchText) {
        var user_data = {
            uid: "0",
            search: searchText ? searchText : '',
            offset: 100,
            ignoreUsers: true,
            valueField: 'uniqueId'
        };
        this.props.users(user_data);
    }

    getUsers(searchText) {
        let ignoreUsers = [].concat.apply([], [this.state.selectedToUsers, this.state.selectedCcUsers]);

        var user_data = {
            uid: "0",
            search: searchText ? searchText : '',
            offset: 100,
            ignoreUsers: true,
            ignoreArrays: ['selectedToUsers', 'selectedCcUsers'],
            valueField: 'uniqueId'
        };
        this.props.users(user_data);
    }

    listenToScroll = () => {
        const container = document.querySelector('.infinte-scroll')
        var scroll = container.scrollTop;
        var formTopHt = document.querySelector('.form-detail-top').offsetHeight;
        if (scroll > formTopHt) {
            this.setState({fixedScroll: true});
        } else {
            if (scroll == 0) {
                this.setState({fixedScroll: false});
            }
        }
    }
    loadmoreCollectionData = () => {
        console.log(this.state)
        if (parseInt(this.props.collectionData.totalchilds - 1) >= parseInt(this.props.collectionData.CollectionData.length)) {
            let collectionid = this.state.collectionId
            let params = {
                from: this.props.collectionData.CollectionData.length,
                size: this.state.loadmorecount,
                "search": this.state.search,
                "index": ES_INDEX,
                "usertype": getProfile().usertype,
                "uid": getProfile().id,
                "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                "filters": this.state.flterData,
                "datefilters": this.state.datefilters,
                "dayfilters": this.state.daysfilters,
                "numberfilters": this.state.numberFilter,
                "dashboard": true,
                assignees: this.state.assignees,
                sort: this.state.sortParam,
                starred: this.state.starred,
                flags: this.state.flagId,

                //          listdata:((this.state.defaultTemplate ===''? this.props.collectionData.CollectionInfo.default_template:this.state.defaultTemplate === "list")?true:false)
            }
            if (getUrlSegment(1) === 'project') {
                params.projectid = this.state.projectId;
            }
            this.props.loadmoreCollectionDetails(collectionid, params)
        }
    }

    manageOptionsDropDown() {
        this.setState({optionOpened: !this.state.optionOpened});
    }

    getRule = () => {
        let collection = Object.assign({}, this.props.collectionData.CollectionInfo);

        let ruleParam = {
            "masterid": collection.masterid,
        }
        this.props.getRule(ruleParam)
    }

    handleAction = (e, act) => {
        let collection = Object.assign({}, this.props.collectionData.CollectionInfo);
        collection._id = this.state.collectionId;
        collection.title = btoa(encodeURIComponent(escape(collection.title)));
        let id = collection._id;
        e.stopPropagation();
        this.setState({
            action: act,
            collectionId: this.state.collectionId,
            optionOpened: 0,
            selectedCollection: [collection],
            selectedToUsers: [],
            showpermissionsarray: collection.linked_formpermission,
            importstep: 1,
            flagPan: false,

        });
        // if(act === 'settings'){
        // 	this.setState({checkedRow: []})
        // }
        let param = {
            collectionid: this.state.collectionId
        }

        this.props.exportField(param)
        if (act === "rule") {
            /*get fieldwise and generic rule */
            let ruleParam = {
                "masterid": collection.masterid,
            }
            this.props.getRule(ruleParam)
            /*listing workflow */
            let workflow = {
                uid: getProfile().id,
                collectionid: this.state.collectionId,
                limit: 0,
                offset: 100
            }
            this.props.listingWorkflow(workflow)
            /*get linked workflow */
            let linkedworkflow = {
                uid: getProfile().id,
                usertype: getProfile().usertype
            }
            this.props.linkedWorkflow(collection.masterid, linkedworkflow)
        }
        if (act === "schedule") {
            /*get fieldwise and generic rule */
            let ruleParam = {
                "masterid": collection.masterid,
            }
            this.props.getRule(ruleParam)
            /*listing workflow */
            // let workflow={
            //     uid:getProfile().id,
            //     usertype:getProfile().usertype,
            //     limit:0,
            //     offset:100
            // }
            // this.props.listingWorkflow(workflow)
            /*get linked workflow */
            // let linkedworkflow={
            //     uid:getProfile().id,
            //     usertype:getProfile().usertype
            // }
            // this.props.linkedWorkflow(collection.masterid,linkedworkflow)
        }

        if (act === "buildform") {
            document.cookie = ES_INDEX + "_redirectUrl=" + window.location.href + ";domain=.bsynapse.com;path=/;"
        }
        if (act === "update") {
            this.selectExport(true)
        }
        if (act === "updateall") {
            this.exportAll(true)
        }
        if (act === "deleteall") {
            this.setState({action: "deleteall"})
        }
        if (act === "pjtsettings") {
            this.setState({action: "pjtsettings"})
        }

    }
    deleteAllRecords = () => {
        let Params = {
            "cid": this.state.collectionId,
            "uid": getProfile().id,
        }
        this.props.deleteAll(Params)
        this.setState({action: ""})
    }


    AddnewhandleAction = (e, act, addnew) => {
        this.setState({
            action: act,
            AddNew: addnew,
        });
    }

    modalOpen() {
        this.setState({
            action: '',
            csvImportMsg: "",
            excelImportMsg: "",
            upload: false,
            assignUserSelect: false,
            datalevelSelect: false,
            importstep: 1,
            csvImportfalse: '',
            excelImportfalse: '',
            excelimportstep: 1
        });
    }

    excelImportStatusData = (importExcelData) => {
        if (importExcelData !== '') {
            let excelCode = importExcelData.code ? importExcelData.code : '';
            if (excelCode !== 1) {
                let excelData = importExcelData.data ? importExcelData.data : [];
                this.setState({excelData: excelData, excelCode: excelCode, excelErrorModal: true})
            }
        }
    }
    ruleClose = (clearUser) => {
        if (clearUser === "clearUser") {
            this.setState({selectedDescriptionUsers: [], selectedfieldUsers: [], selectedfieldUsersCc: []})
        } else {
            this.setState({action: '', selectedDescriptionUsers: [], selectedfieldUsers: [], selectedfieldUsersCc: []})
        }
    }
    handleDeleted = (param, type) => {
        if (type === "workflow") {
            this.props.deletelinkedWorkflow(param)
        }
        if (type === "genericRule") {
            this.props.genericRuleDelted(param)
        }
        if (type === "fieldwiseRule") {
            this.props.fieldwiseRuleDelted(param)
        }
    }
    scheduleClose = (clearUser) => {
        if (clearUser === "clearUser") {
            this.setState({selectedDescriptionUsers: [], selectedfieldUsers: []})
        } else {
            this.setState({action: '', selectedDescriptionUsers: [], selectedfieldUsers: []})
        }
    }
    saveAssign = () => {
        let assignees = []
        let result = this.state.newData;
        for (var i = 0; i < result.length; i++) {
            let newElem = {
                "id": result[i].user_id,
                "type": result[i].user_type,
            };
            assignees.push(newElem);
        }
        let collectionid = this.state.collectionId
        if (this.state.shareToAll) {
            let params = {
                "collectionid": collectionid,
                "uid": getProfile().id,
                "assignees": assignees,
                "starred": this.state.starred,
                "search": this.state.search,
                "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
                "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
                "filters": this.state.flterData,
                "datefilters": this.state.datefilters,
                "numberfilters": this.state.numberFilter,
                "dayfilters": this.state.daysfilters,
                "timefilters": [
                    {}
                ],
                flags: this.state.flagId,

            }
            this.props.shareAll(params)
            this.setState({action: "", shareToAll: false, checkedRow: []})

        } else {
            let param = {
                "collectionid": collectionid,
                "assignees": assignees,
                "index": ES_INDEX,
                "uid": getProfile().id,
                "records": this.state.checkedRow,
                "type_val": 'collection'
            }
            this.props.saveAssignData(param)
        }


    }
    resultDataList = (result, reference, valueField) => {
        let uniqueIds = result.map(item => item[valueField]);
        this.setState({[reference]: result, newData: result});
    }
    saveDataLevel = (result, reference) => {
        this.setState({[reference]: result, newData: result, filterUserdata: result});
    }

    shareCollection() {
        let users = [];
        let cnt = 0;
        this.state.selectedToUsers.map(a => {
            let user = {"type": a.user_type, "id": +a.id};
            users.push(user);
        });
        this.props.sharecollection(this.state.collectionId, getProfile().id, users);
        this._interval = setInterval(() => {
            if (this.props.sharedCollection.shared === 1) {
                if (this.props.sharedCollection.msg === '') {
                    this.setState({action: ''});
                    this.props.collectionFilter(this.state.collectionId, '', []);

                } else {
                    //alert(this.props.sharedCollection.msg)
                }
                clearInterval(this._interval);
            }
        }, 500);

    }

    goBack() {
        this.props.history.goBack();
    }

    getrightTabid = (tab) => {
        let saveSettings = this.props.GetCollectionconfig && this.props.GetCollectionconfig.getcollectionconfig && this.props.GetCollectionconfig.getcollectionconfig.settings ? this.props.GetCollectionconfig.getcollectionconfig.settings.data : '';
        console.log(saveSettings);
        saveSettings.map(item => {
            item.id = item.id ? item.id : item.permissionto;
            item.name = item.name ? item.name : item.fullname;
            item.user_type = item.user_type ? item.user_type : item.category;
            item.role = item.role ? item.role : '';
            item.uniqueId = item.uniqueId ? item.uniqueId : item.category && item.category.toLowerCase() + "_" + item.id;
            return item;
        })
        this.setState({rightTab: tab, saveSettings: saveSettings})
    }
    // changeExportField=(masterid,type)=>{
    //
    //     if(type==="meta"){
    //       let  metaval=  this.props.exportfield.meta[masterid]
    //            this.props.exportfield.meta[masterid]=!metaval
    //      // this.setState({[masterid]:val})
    //     }
    //     else {
    //         let data = this.state.saveExportField.length === 0 ? this.props.exportfield.data : this.state.saveExportField
    //         let indx = (data.findIndex(obj => obj["masterid"] === masterid))
    //         data[indx]["selected"] = !data[indx].selected
    //         this.setState({saveExportField: data})
    //     }
    // }
    // saveExportField=()=>{
    //     var data=[]
    //     if(this.state.saveExportField.length === 0){
    //          data=this.props.exportfield.data.filter((item) => item.selected === true);
    //     }else{
    //          data=this.state.saveExportField.filter((item) => item.selected === true);
    //     }
    //     var dataString = JSON.stringify(data);
    //     let param={
    //         collectionid: atob(getUrlSegment(3)),
    //         uid:getProfile().id,
    //         rec:dataString,
    //         metacreatedby: this.state.metacreatedby !== undefined?this.state.metacreatedby:this.props.exportfield.meta.metacreatedby,
    //         metaupdatedby: this.state.metaupdatedby !== undefined?this.state.metaupdatedby:this.props.exportfield.meta.metaupdatedby,
    //         metacreatedate: this.state.metacreatedate !== undefined?this.state.metacreatedate:this.props.exportfield.meta.metacreatedate,
    //         metaupdatedate: this.state.metaupdatedate !== undefined?this.state.metaupdatedate:this.props.exportfield.meta.metaupdatedate,
    //     }
    //     this.props.saveFieldData(param)
    // }
    dataView = (e, type) => {
        let status = e.target.value === "true" ? false : true
        this.setState({[type]: status})

        let data = this.state.savedataView.length === 0 ? this.props.GetCollectionconfig.getcollectionconfig.dataview.data : this.state.savedataView
        if (data.length === 0) {
            let collectionid = this.state.collectionId
            data = [{
                "collectionid": collectionid,
                "own_record": false,
                "creator_group": false,
                "report_person": false,
                "set_project": false
            }]
        }
        data[0][type] = status
        this.setState({savedataView: data})
    }
    saveDataView = () => {
        let dataView = []
        let collectionid = this.state.collectionId
        if (this.state.savedataView.length !== 0) {
            if (this.state.savedataView[0].creator_group) {
                dataView.push("creator_group")
            }
            if (this.state.savedataView[0].own_record) {
                dataView.push("own_record")
            }
            if (this.state.savedataView[0].report_person) {
                dataView.push("report_person")
            }
            if (this.state.savedataView[0].set_project) {
                dataView.push("set_project")
            }

            let param = {
                "collectionid": collectionid,
                "premission": dataView,
                "index": ES_INDEX,
                "uid": getProfile().id
            }
            this.props.savaDataView(param)
        }

    }
    permission = (e, type, index) => {
        let status = '';
        if (type === 'role') {
            status = e.target.value;
        } else {
            status = (e.target.value !== "true");
        }
        let data = this.state.saveSettings;
        if (type === 'edit') {
            this.setState({editPermission: !this.state.editPermission});
            if (e.target.value === 'false') {
                let own_status = (e.target.value !== "true");
                data[index]['Ownrecords'] = own_status;
            }
        }
        data[index][type] = status;
        this.setState({saveSettings: data})
    }
    editOption = (e, type, id) => {

        if (type === 'Ownrecords') {
            let status = (e.target.value !== "true");
            this.setState({Ownrecords: status})
        }
        if (type === 'Creatorgroups') {
            let status = (e.target.value !== "true");
            this.setState({Creatorgroups: status})
        }
        if (type === 'Assignedrecords') {
            let status = (e.target.value !== "true");
            this.setState({Assignedrecords: status})
        }
        if (type === 'Allrecords') {
            let status = (e.target.value !== "true");
            this.setState({Allrecords: status})
        }
        if (type === 'Reportingperson') {
            let status = (e.target.value !== "true");
            this.setState({Reportingperson: status})
        }
        let status = (e.target.value === "true") ? false : true;
        let option_data = this.state.saveSettings;
        let option_index = parseInt(this.state.saveSettings.findIndex(item => parseInt(item.id) === parseInt(this.state.selectedId)));
        option_data[option_index][type] = status;

        this.setState({saveSettings: option_data});
    }
    savePermission = () => {
        let savedataview = [];
        let data = this.state.saveSettings;
        for (var i = 0; i < data.length; i++) {
            let masterArr = [];
            let edit_permission = [];
            if (data[i].addedit === true) {
                masterArr.push('Add')
            }
            if (data[i].edit === true) {
                masterArr.push('Edit');

                if (data[i].Ownrecords === true) {
                    edit_permission.push('Ownrecords')
                }
                if (data[i].Creatorgroups === true) {
                    edit_permission.push('Creatorgroups')
                }
                if (data[i].Assignedrecords === true) {
                    edit_permission.push('Assignedrecords')
                }
                if (data[i].Allrecords === true) {
                    edit_permission.push('Allrecords')
                }
                if (data[i].Reportingperson === true) {
                    edit_permission.push('Reportingperson')
                }
            }
            if (data[i].import === true) {
                masterArr.push('Import')
            }
            if (data[i].export === true) {
                masterArr.push('Export')
            }
            if (data[i].assign === true) {
                masterArr.push('Assign')
            }
            if (data[i].alldata === true) {
                masterArr.push('Alldata')
            }
            if (data[i].rule === true) {
                masterArr.push('Rule')
            }
            if (data[i].schedule === true) {
                masterArr.push('Schedule')
            }
            if (data[i].api === true) {
                masterArr.push('Api')
            }
            if (data[i].delete === true) {
                masterArr.push('Delete')
            }
            if (data[i].collabrator === true) {
                masterArr.push('Collabrator')
            }
            if (data[i].restricted  === true) {
                masterArr.push('Restricted')
            }
            let newElem = {
                "id": data[i].permissionto,
                "category": data[i].category,
                "role": data[i].role,
                "master": masterArr,
                "edit_permission": edit_permission,
            };
            savedataview.push(newElem);
        }

        this.setState({dataView: savedataview})
        if (savedataview.length !== 0) {
            let collectionid = this.state.saveSettings[0].master_id;
            let param = {
                "collectionid": collectionid,
                "PermissionVals": savedataview,
                "index": ES_INDEX,
                "uid": getProfile().id,
                "flag": 0,
                "type_val": 'collection'
            }
            let data = {
                "collectionid": collectionid
            }
            let beta = this
            this.props.savaDataSettings(param).then(
//                 setTimeout(function () {
// alert(2)
//                     beta.props.getCollectionconfig(data);
//                 }, 500)


            )
        }


    }
    showlinkingPermission = (e, id) => {

        let showpermissionsarray = [...this.state.showpermissionsarray];
        (showpermissionsarray.indexOf(id) > -1) ? showpermissionsarray.splice(showpermissionsarray.indexOf(id), 1) : showpermissionsarray.push(id);
        this.setState({showpermissionsarray: showpermissionsarray, entity_perm: false});

    }
    linkingPermission = () => {
        let showlinkingpermissions = []
        this.setState({entity_perm: false})
        let param = {
            "_id": atob(getUrlSegment(3)),
            "index": ES_INDEX,
            "premission": this.state.showpermissionsarray,
        }
        this.props.savelinkingpermission(param)
    }
    showFilter = (e) => {
        let status = e.target.value === "true" ? false : true
        this.setState({"showdatefilter": status})
    }
    handleDafultOpenSelect = (e) => {
        let showdatefilter = this.state.showdatefilter !== '' ? this.state.showdatefilter : this.props.collectionData.CollectionInfo.showdatefilter;
        this.setState({dateopen: !this.state.dateopen, showdatefilter: showdatefilter})
    }
    saveFilter = () => {
        let param = {
            "collectionid": this.state.collectionId,
            "index": ES_INDEX,
            "showdatefilter": this.state.showdatefilter,
            "category": "collection",
            "dateopen": this.state.dateopen
        }
        let collectionParam = {
            from: 0,
            size: this.state.loadmorecount,
            index: ES_INDEX,
            search: '',
            "uid": getProfile().id,
            "usertype": getProfile().usertype,
            "dashboard": true,
            "facetrequired": true,
            fieldview: true,
            sort: this.state.sortParam,
            starred: this.state.starred
//          listdata:((this.state.defaultTemplate ===''? this.props.collectionData.CollectionInfo.default_template:this.state.defaultTemplate === "list")?true:false)
        }
        let that = this
        this.props.saveFilter(param).then(
            setTimeout(function () {
                if (getUrlSegment(1) === 'project') {
                    collectionParam.projectid = that.state.projectId;
                }
                that.props.collectionDetails(that.state.colID, collectionParam)
            }, 1000)
        )


    }

    workflowPanel = (e) => {
        let status = e.target.value === "true" ? false : true
        this.setState({"disableWorkflowPanel": status})
    }
    enableMailNotification = (e) => {
        let status = e.target.value === "true" ? false : true
        this.setState({"mailNotification": status})
    }
    enableChatNotification = (e) => {
        let status = e.target.value === "true" ? false : true
        this.setState({"chatNotification": status})
    }
    handleDafultShowChatSelect = (e) => {
        let disableWorkflowPanel = this.state.disableWorkflowPanel !== '' ? this.state.disableWorkflowPanel : this.props.collectionData.CollectionInfo.showwf_info;
        this.setState({showChat: !this.state.showChat, disableWorkflowPanel: disableWorkflowPanel})
    }
    saveRightPanel = () => {
        let showChat = this.state.disableWorkflowPanel ? this.state.showChat : false;
        let param = {
            "cid": this.state.collectionId,
            "index": ES_INDEX,
            "showwf_info": this.state.disableWorkflowPanel,
            "category": "collection",
            "showchat": showChat,
            "mailnotification": this.state.mailNotification,
            "chatnotification": this.state.chatNotification,
        }
        let collectionParam = {
            from: 0,
            size: this.state.loadmorecount,
            index: ES_INDEX,
            search: '',
            "uid": getProfile().id,
            "usertype": getProfile().usertype,
            "dashboard": true,
            "facetrequired": true,
            fieldview: true,
            sort: this.state.sortParam,
            starred: this.state.starred
        }
        let that = this
        this.props.saveRightSidePanel(param).then(
            setTimeout(function () {
                if (getUrlSegment(1) === 'project') {
                    collectionParam.projectid = that.state.projectId;
                }
                that.props.collectionDetails(that.state.colID, collectionParam)
            }, 1000)
        )


    }

    exportAll = (type) => {
        let title = (this.props.collectionData.CollectionInfo.title)
        //let  collectionid = this.state.colID;
        var url = API_PATH + "/export/exportall";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.responseType = "blob";
        xhr.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var blob = this.response;
                var header = xhr.getResponseHeader('Content-Disposition');
                var filename = title + ".xlsx";
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var downloadLink = window.document.createElement('a');
                    var contentTypeHeader = xhr.getResponseHeader("Content-Type");
                    downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: contentTypeHeader}));
                    downloadLink.download = filename;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
            }
        };
        var params = "_id=" + this.state.collectionId + "&index=" + ES_INDEX + "&uid=" + getProfile().id + "&usertype=" + getProfile().usertype
            + "&filters=" + JSON.stringify(this.state.flterData)
            + "&datefilters=" + JSON.stringify(this.state.datefilters) +
            "&dayfilters=" + JSON.stringify(this.state.daysfilters) +
            "&numberfilters=" + JSON.stringify(this.state.numberFilter) +
            "&fromdate=" + (this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate) +
            "&todate=" + (this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate) +
            "&starred=" + this.state.starred +
            "&showrecordid=" + type + "&flags=" + JSON.stringify(this.state.flagId);
        if (getUrlSegment(1) === 'project') {
            params += "&projectid=" + this.state.projectId;
        }

        xhr.send(params);

    }


    enableImage = (temp, status) => {
        this.setState({[temp]: status})
    }
    backtoListing = () => {
        /* handling propagation setting initial state */
        let data = {
            "collectionid": this.state.collectionId
        }
        this.props.getCollectionconfig(data);
        /**********************************/
        this.setState({action: ""})


    }
    /* Assign data from settings Permissions */
    savedatalevelUsers = () => {
        let collectionid = this.state.collectionId
        let users = this.state.saveSettings;
        let permissionVal = [];
        for (var i = 0; i < users.length; i++) {
            let masterArr = [];
            let edit_permission = [];
            if (users[i].user_type === "user") {
                if (users[i].addedit === true) {
                    masterArr.push('Add')
                }
                if (users[i].edit === true) {
                    masterArr.push('Edit');

                    if (users[i].Ownrecords === true) {
                        edit_permission.push('Ownrecords')
                    }
                    if (users[i].Creatorgroups === true) {
                        edit_permission.push('Creatorgroups')
                    }
                    if (users[i].Assignedrecords === true) {
                        edit_permission.push('Assignedrecords')
                    }
                    if (users[i].Allrecords === true) {
                        edit_permission.push('Allrecords')
                    }
                    if (users[i].Reportingperson === true) {
                        edit_permission.push('Reportingperson')
                    }
                }
                if (users[i].import === true) {
                    masterArr.push('Import')
                }
                if (users[i].export === true) {
                    masterArr.push('Export')
                }
                if (users[i].assign === true) {
                    masterArr.push('Assign')
                }
                if (users[i].alldata === true || users[i].system_type) {
                    masterArr.push('Alldata')
                }
                if (users[i].rule === true) {
                    masterArr.push('Rule')
                }
                if (users[i].schedule === true) {
                    masterArr.push('Schedule')
                }
                if (users[i].api === true) {
                    masterArr.push('Api')
                }
                if (users[i].delete === true) {
                    masterArr.push('Delete')
                }
                if (users[i].collabrator === true) {
                    masterArr.push('Collabrator')
                }
                if (users[i].restricted === true) {
                    masterArr.push('Restricted ')
                }
            }
            if (users[i].user_type === "group") {
                masterArr.push('Alldata')
            }
            if (users[i].user_type === "function") {
                masterArr.push('Alldata')
            }
            let user = {
                "id": users[i].user_id,
                "category": users[i].user_type,
                "role": users[i].role,
                "master": masterArr,
                "edit_permission": edit_permission
            };
            permissionVal.push(user);

        }

        let data = {
            "collectionid": collectionid,
            "index": ES_INDEX,
            "uid": getProfile().id,
            "flag": 0,
            "PermissionVals": permissionVal
        }
        this.props.savaDatalevelSettings(data);

    }
    /* Assign users from result table icon */
    singleRecorddAssign = () => {
        let collectionid = this.state.collectionId
        let users = this.state.saveSettings;
        let permissionVal = [];
        for (var i = 0; i < users.length; i++) {
            let user = {
                "id": users[i].user_id,
                "type": users[i].user_type,
            };
            permissionVal.push(user);
        }

        let data = {
            "collectionid": collectionid,
            "index": ES_INDEX,
            "uid": getProfile().id,
            "assignees": permissionVal,
            "records": this.state.recId
        }
        this.props.saveAssignData(data);

    }
    /* Multi User Assign */
    /*Template selection*/
    selectTemplate = (e, template) => {
        this.setState({template: template})
    }
    saveTempalete = (state, temp) => {
        if (temp === 'horizontal') {
            this.setState({mappedDatahorizontal: state})
        }
        if (temp === 'vertical') {
            this.setState({mappedDatavertical: state})
        }
        if (temp === 'map') {
            this.setState({maptemplate: state})
        }
        if (temp === 'mini') {
            this.setState({mappedDatamini: state})
        }
        if (temp === 'long1') {
            this.setState({mappedDataLong1Temp: state})
        }
        if (temp === 'kanban') {
            this.setState({mappedDataKanbanTemp: state})
        }

    }
    defaultView = (defTemp) => {
        this.setState({defaultTemplate: defTemp, viewType: ""})
    }
    saveTemplate = () => {
        let param = {
            "template_type": this.state.defaultTemplate === '' ? this.props.collectionData.CollectionInfo.default_template : this.state.defaultTemplate,
            "default": this.state.defaultTemplate === '' ? this.props.collectionData.CollectionInfo.default_template : this.state.defaultTemplate,
            "horizontal_temp": (this.state.mappedDatahorizontal.length === 0 ? this.props.collectionData.horizontaltemplate : this.state.mappedDatahorizontal),
            "vertical_temp": (this.state.mappedDatavertical.length === 0 ? this.props.collectionData.verticaltemplate : this.state.mappedDatavertical),
            "mini_temp": (this.state.mappedDatamini.length === 0 ? this.props.collectionData.minitemplate : this.state.mappedDatamini),
            "long1_temp": (this.state.mappedDataLong1Temp.length === 0 ? this.props.collectionData.long1template : this.state.mappedDataLong1Temp),
            //"kanban_template": (this.state.mappedDataKanbanTemp.length === 0 ? this.props.collectionData.kanbantemplate : this.state.mappedDataKanbanTemp),
            "map_template": (this.state.maptemplate.length === 0 ? this.props.collectionData.maptemplate : this.state.maptemplate),
            "category": "collection",
            "mode": "save",
            "horizontalImage": this.state.horizontalImage === '' ? this.props.collectionData.CollectionInfo.horizontalImage : this.state.horizontalImage,
            "verticalImage": this.state.verticalImage === '' ? this.props.collectionData.CollectionInfo.verticalImage : this.state.verticalImage,
            "miniImage": this.state.miniImage === '' ? this.props.collectionData.CollectionInfo.miniImage : this.state.miniImage,
            "long1Image": this.state.long1Image === '' ? this.props.collectionData.CollectionInfo.long1Image : this.state.long1Image,
            "horizontalMapenabled": this.state.horizontalMapenabled === '' ? this.props.collectionData.CollectionInfo.horizontalMapenabled : this.state.horizontalMapenabled,
            "verticalMapenabled": this.state.verticalMapenabled === '' ? this.props.collectionData.CollectionInfo.verticalMapenabled : this.state.verticalMapenabled,
            "miniMapenabled": this.state.miniMapenabled === '' ? this.props.collectionData.CollectionInfo.miniMapenabled : this.state.miniMapenabled,
            "uid": getProfile().id,
        }
        this.props.CollectionTemplateSaving(this.state.collectionId, param)
        this.setState({template: ''})
    }
    onTabChange = (e, tabView) => {
        this.setState({tabView: tabView})
        // this.selectTemplate(e,tabView)
    }
    /*Template selection*/
    showOption = (e, collectededitid) => {
        this.setState({showtemplateOptions: !this.state.showtemplateOptions, collectededitid: collectededitid})

    }

    /* FILTER SECTION */
    handleSearch = (e) => {
        let q = e.target.value;
        this.setState({search: q});
        this.handleDataSearch(q, this.state.flterData);
    }

    handlefilterSearch(item, masterid) {

        if (item !== 'clear') {
            let filter = [...this.state.filter];

            (filter.indexOf(item) > -1) ? filter.splice(filter.indexOf(item), 1) : filter.push(item);
            if (this.state.flterData.filter(list => (list.id === masterid.toString())).length === 0) {
                let flterData = this.state.flterData.filter(list => (list.id !== masterid.toString()))
                let FilterLabel = this.state.FilterLabel.filter(list => (list.id !== masterid.toString()))
                flterData.push({"id": masterid, "value": [item]})
                FilterLabel.push({"id": masterid, "value": item})
                this.setState({filter: filter, flterData: flterData, FilterLabel: FilterLabel});
                this.handleDataSearch(this.state.search, flterData);
            } else {
                let getCurrntfilter = this.state.flterData.filter(list => (list.id === masterid.toString()))
                let FilterLabel = Array.from(this.state.FilterLabel)
                if (FilterLabel.filter(list => (list.value === item)).length === 0) {
                    FilterLabel.push({"id": masterid, "value": item})
                    this.setState({FilterLabel: FilterLabel});
                } else {
                    FilterLabel = FilterLabel.filter(list => (list.value !== item))
                    this.setState({FilterLabel: FilterLabel});
                }

                let updateFilter = getCurrntfilter[0].value
                let filterItemdata = (updateFilter.indexOf(item) > -1) ? updateFilter.splice(updateFilter.indexOf(item), 1) : updateFilter.push(item);
                let flterData = this.state.flterData.filter(list => (list.id !== masterid.toString()))
                let _newflterData = {"id": masterid, "value": updateFilter}
                flterData.push(_newflterData)
                this.setState({filter: filter, flterData: flterData});
                this.handleDataSearch(this.state.search, flterData);
            }
        } else {
            this.setState({
                filter: [],
                filters: [],
                customizeDateFrom: '',
                customizeDateTo: '',
                FilterLabel: [],
                numberfilters: [],
                dayfilters: [],
                datefilters: [],
                fromdate: "",
                todate: '',
                filterDate: "",
                flterData: [],
                AssigneLabel: [],
                assigneeList: []

            });

            let param = {
                "search": this.state.search,
                "index": ES_INDEX,
                "usertype": getProfile().usertype,
                "uid": getProfile().id,
                "fromdate": "",
                "todate": '',
                "filters": [],
                "datefilters": [],
                "numberfilters": [],
                "dayfilters": [],
                "dashboard": true,
                assignees: [],
                "facetrequired": true,
                fieldview: true,
                sort: this.state.sortParam,
                starred: this.state.starred,
                flags: this.state.flagId,

                //          listdata:((this.state.defaultTemplate ===''? this.props.collectionData.CollectionInfo.default_template:this.state.defaultTemplate === "list")?true:false)
            }
            if (getUrlSegment(1) === 'project') {
                param.projectid = this.state.projectId;
            }

            this.props.collectionDetails(this.state.collectionId, param)
        }

    }

    numberFilter = (e, master, range) => {
        let numberFilter = []
        /* To get existing total filter values */
        numberFilter = [...this.state.numberFilter];
        let numberFilterNew = []
        let numberFilterOld = []
        var num = []
        /* To get Existing filter value based on master id*/
        numberFilterNew = numberFilter.filter((item) => item.id.toString() === master.toString())
        /* create or update array based results*/
        if (numberFilterNew.length === 0) {
            num = {
                "id": master,
                "min": range === "min" ? e.target.value : '',
                "max": range === "max" ? e.target.value : ''
            }
        } else {
            numberFilterNew[0][range] = e.target.value
            num = numberFilterNew
        }

        numberFilterOld = numberFilter.filter((item) => item.id.toString() !== master.toString())
        let newResult = numberFilterOld.concat(num)
        this.setState({numberFilter: newResult})
        let param = {
            "search": this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
            "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
            "filters": this.state.flterData,
            "datefilters": this.state.datefilters,
            "dayfilters": this.state.daysfilters,
            "numberfilters": newResult,
            "dashboard": true,
            assignees: this.state.assignees,
            "facetrequired": true,
            fieldview: true,
            sort: this.state.sortParam,
            starred: this.state.starred,
            flags: this.state.flagId,


        }
        let collectionid = this.state.collectionId;
        if (getUrlSegment(1) === 'project') {
            param.projectid = this.state.projectId;
        }

        this.props.collectionDetails(collectionid, param)
    }
    rangeFilter = (value, master, range) => {
        let numberFilter = []
        /* To get existing total filter values */
        numberFilter = [...this.state.numberFilter];
        let numberFilterNew = []
        let numberFilterOld = []
        var num = []
        /* To get Existing filter value based on master id*/
        numberFilterNew = numberFilter.filter((item) => item.id.toString() === master.toString())
        /* create or update array based results*/
        if (numberFilterNew.length === 0) {
            num = {
                "id": master,
                "min": value.min,
                "max": value.max
            }
        } else {
            numberFilterNew[0]["min"] = value.min
            numberFilterNew[0]["max"] = value.max
            num = numberFilterNew
        }

        numberFilterOld = numberFilter.filter((item) => item.id.toString() !== master.toString())
        let newResult = numberFilterOld.concat(num)
        this.setState({numberFilter: newResult})
        let param = {
            "search": this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
            "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
            "filters": this.state.flterData,
            "datefilters": this.state.datefilters,
            "dayfilters": this.state.daysfilters,
            "numberfilters": newResult,
            "dashboard": true,
            assignees: this.state.assignees,
            "facetrequired": true,
            fieldview: true,
            sort: this.state.sortParam,
            starred: this.state.starred,
            flags: this.state.flagId,


        }
        let collectionid = this.state.collectionId;
        if (getUrlSegment(1) === 'project') {
            param.projectid = this.state.projectId;
        }

        this.props.collectionDetails(collectionid, param)
    }
    dateWise = (e, period, label) => {
        let collectionid = this.state.colID;
        let fromdate = ''
        let todate = ''
        if (period === "reset") {
            fromdate = ""
            this.setState({filterDate: "", fromdate: '', todate: "", tofilterdate: '', fromfilterdate: ""});
        }
        if (label === "fromdate") {
            fromdate = ""
            this.setState({fromdate: "", fromfilterdate: ""});
        }
        if (label === "todate") {
            todate = ""
            this.setState({todate: "", tofilterdate: ""});

        }

        if (e.target.value === "today" && period !== "reset" && label !== "fromdate" && label !== "todate") {
            fromdate = moment.utc().format('YYYY-MM-DD')
            todate = moment.utc().format('YYYY-MM-DD')
            //this.setState({filterDate: e.target.value,fromdate: fromdate ,todate: todate});
            this.setState({
                filterDate: e.target.value,
                fromdate: "",
                todate: "",
                fromfilterdate: fromdate,
                tofilterdate: todate
            });
        }
        if (e.target.value !== "today" && period !== "reset" && label !== "fromdate" && label !== "todate") {
            fromdate = moment().subtract(e.target.value, period).format('YYYY-MM-DD');
            this.setState({filterDate: label, fromdate: "", todate: "", fromfilterdate: fromdate, tofilterdate: ""});
            // this.setState({filterDate: label,fromdate: fromdate });
        }

        let param = {
            "search": this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": fromdate,
            "todate": todate,
            "filters": this.state.flterData,
            "datefilters": this.state.datefilters,
            "dayfilters": this.state.daysfilters,
            "numberfilters": this.state.numberFilter,
            "dashboard": true,
            assignees: this.state.assignees,
            "facetrequired": true,
            fieldview: true,
            sort: this.state.sortParam,
            starred: this.state.starred,
            flags: this.state.flagId,

        }
        if (getUrlSegment(1) === 'project') {
            param.projectid = this.state.projectId;
        }

        this.props.collectionDetails(collectionid, param)
    }
    dateRange = (formattedDate, label) => {
        this.setState({filterDate: "", tofilterdate: '', fromfilterdate: '', [label]: formattedDate})

        let fromdate = (label === "fromdate" ? formattedDate : this.state.fromdate)
        let todate = (label === "todate" ? formattedDate : this.state.todate)
        let param = {
            "search": this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": fromdate,
            "todate": todate,
            "filters": this.state.flterData,
            "datefilters": this.state.datefilters,
            "dayfilters": this.state.daysfilters,
            "numberfilters": this.state.numberFilter,
            "dashboard": true,
            assignees: this.state.assignees,
            "facetrequired": true,
            fieldview: true,
            sort: this.state.sortParam,
            starred: this.state.starred,
            flags: this.state.flagId,


        }
        let collectionid = this.state.colID;
        if (getUrlSegment(1) === 'project') {
            param.projectid = this.state.projectId;
        }

        this.props.collectionDetails(collectionid, param)
    }
    daysFilter = (term, count, type, masterid, filterType) => {

        let fromdate;
        let todate;
        let daysfilters
        if (filterType === "daysfilterReset") {
            let _oldData = [...this.state.daysfilters]
            let _fData = _oldData.filter((list) => list.id !== masterid)
            this.setState({daysfilters: _fData})
            this.search(_fData, "daysfilters")
        } else if (filterType === "datefilterReset") {
            let _oldData = [...this.state.datefilters]
            let _fData = _oldData.filter((list) => list.id === masterid)
            if (type === "from") {
                _fData[0]["from"] = ""
                this.setState({datefilters: _fData, customizeDateFrom: '', customizeDateTo: _fData[0]["to"]})
            }
            if (type === "to") {
                _fData[0]["to"] = ""
                this.setState({datefilters: _fData, customizeDateFrom: _fData[0]["from"], customizeDateTo: ""})
            }
            this.search(_fData, "datefilters")
        } else if (term === "today") {
            fromdate = moment(new Date()).format('YYYY-MM-DD')
            todate = moment(new Date()).format('YYYY-MM-DD')
            daysfilters = [{id: masterid, from: fromdate, to: todate, label: "today", labelFrom: "today" + masterid}]
            let _oldData = [...this.state[filterType]]
            let _fData = _oldData.filter((list) => list.id !== masterid.toString())
            let _newData = []
            if (_fData.length !== 0) {
                _newData = [_fData[0], daysfilters[0]]
            } else {
                _newData = [daysfilters[0]]
            }
            this.setState({[filterType]: _newData})
            if (filterType === "datefilters") {
                this.search(_newData, "datefilters")
            } else {
                this.search(_newData, "daysfilters")
            }
        } else if (term === "fromdate") {
            daysfilters = [{id: masterid, from: count, to: this.state.customizeDateTo, label: ""}]
            this.setState({datefilters: daysfilters, customizeDateFrom: count})
            this.search(daysfilters, "datefilters")
        } else if (term === "todate") {
            daysfilters = [{id: masterid, from: this.state.customizeDateFrom, to: count, label: ""}]
            this.setState({datefilters: daysfilters, customizeDateTo: count})
            this.search(daysfilters, "datefilters")
        } else {
            if (type === "day") {
                type = "day"
            }
            if (term === "next") {
                fromdate = moment().add(1, "days").format("YYYY-MM-DD")
                todate = moment().add(count, type).format("YYYY-MM-DD")
            }
            if (term === "last") {
                fromdate = moment().subtract(count, type).format("YYYY-MM-DD")
                todate = moment().subtract(1, "days").format("YYYY-MM-DD")
            }
            if (term === "before") {
                fromdate = ""
                todate = moment().subtract(count, type).format("YYYY-MM-DD")
            }
            if (term === "after") {
                fromdate = moment().add(count, type).format("YYYY-MM-DD")
                todate = ""
            }
            daysfilters = [{
                id: masterid,
                from: fromdate,
                to: todate,
                label: term + " " + count + " " + type,
                labelFrom: term + "" + count + "" + type + masterid
            }]
            let _oldData = [...this.state[filterType]]
            let _fData = _oldData.filter((list) => list.id !== masterid.toString())
            let _newData = []
            if (_fData.length !== 0) {
                _newData = [_fData[0], daysfilters[0]]
            } else {
                _newData = [daysfilters[0]]
            }
            this.setState({[filterType]: _newData, customizeDateTo: "", customizeDateFrom: ""})
            if (filterType === "datefilters") {
                this.search(_newData, "datefilters")
            } else {
                this.search(_newData, "daysfilters")
            }
        }

    }
    previewTemplate = (name, item, mobileName, mobileItem) => {
        this.props.collectionData.customtemplate["name"] = name
        this.props.collectionData.customtemplate["template"] = item
        this.props.collectionData.customtemplate["name"] = mobileName
        this.props.collectionData.customtemplate["template"] = mobileItem
    }
    handleDataSearch = (search = '', filter = [], masterid = "", advncsearch = []) => {
        let collectionid = this.state.colID;
        let searchValue = this.state.searchValue ? this.state.searchValue : advncsearch;
        let flterData = []
        if (filter.length !== 0) {
            flterData = filter
        }
        let param = {
            "search": search,
            "advanced": searchValue,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
            "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
            "filters": flterData,
            "datefilters": this.state.datefilters,
            "numberfilters": this.state.numberFilter,
            "dayfilters": this.state.daysfilters,
            "dashboard": true,
            assignees: this.state.assignees,
            "facetrequired": true,
            fieldview: true,
            sort: this.state.sortParam,
            starred: this.state.starred,
            flags: this.state.flagId,

        }
        if (getUrlSegment(1) === 'project') {
            param.projectid = this.state.projectId;
        }
        this.props.collectionDetails(collectionid, param)
    }

    sortingData = (field) => {
        let sort = ''
        let type = ""
        if (field.type === "number" || field.type === "datepicker") {
            type = field.type === "number" ? "number" : "date"
        } else {
            type = "text"
        }
        if (this.state.sortData.masterid === field.masterid) {
            sort = (this.state.sort === "asc" ? "desc" : "asc")

        } else {
            sort = "asc"
        }
        let sortParam = [
            {
                "type": type,
                "order": sort,
                "id": field.masterid
            }
        ]

        let collectionid = this.state.colID;
        let param = {
            "search": this.state.search,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
            "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
            "filters": this.state.flterData,
            "datefilters": this.state.datefilters,
            "numberfilters": this.state.numberFilter,
            "dayfilters": this.state.daysfilters,
            "dashboard": true,
            assignees: this.state.assignees,
            "facetrequired": true,
            fieldview: true,
            "starred": this.state.starred,
            "sort": sortParam,
            flags: this.state.flagId,

        }
        this.props.collectionDetails(collectionid, param)

        this.setState({sortData: field, sort: sort, sortParam: sortParam})

    }


    search = (data, filterType) => {
        let daysfilters = (filterType === 'daysfilters' ? data : this.state.daysfilters)
        let datefilters = (filterType === 'datefilters' ? data : this.state.datefilters)
        let collectionid = this.state.colID;
        let param = {
            "search": this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
            "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
            "filters": this.state.flterData,
            "datefilters": datefilters,
            "numberfilters": this.state.numberFilter,
            "dayfilters": daysfilters,
            "dashboard": true,
            assignees: this.state.assignees,
            "facetrequired": true,
            fieldview: true,
            sort: this.state.sortParam,
            starred: this.state.starred,
            flags: this.state.flagId,


        }
        if (getUrlSegment(1) === 'project') {
            param.projectid = this.state.projectId;
        }

        this.props.collectionDetails(collectionid, param)

    }

    closeAddNewTaskModal() {
        this.setState({showAddNewTaskModal: false, addOptions: false, action: ""});

    }

    showAddNewTaskModal = (e, secId) => {
        this.setState({
            showAddNewTaskModal: !this.state.showAddNewTaskModal,
            projectList: this.props.projects.projectData,
            tagList: this.props.projects.tagData,
            secId: secId

        })
    }
    importStages = (e, stages) => {
        let importstep = this.state.importstep;
        if (stages === 'next') {
            importstep = importstep + 1;
        } else {
            importstep = importstep - 1;
        }
        this.setState({importstep: importstep})
    }
    importStagesNew = (e, stages) => {
        let excelimportstep = this.state.excelimportstep;
        if (stages === 'next') {
            excelimportstep = excelimportstep + 1;
        } else {
            excelimportstep = excelimportstep - 1;
        }
        this.setState({excelimportstep: excelimportstep})
    }

    Minimize = () => {
        this.setState({Maximise: false, miniMize: true})
    }
    Maximise = () => {
        if (!this.state.miniMize) {
            this.setState({Maximise: !this.state.Maximise, miniMize: false})
        } else {
            this.setState({Maximise: false, miniMize: false})
        }
    }
    handleFile = e => {
        let name = ""
        const file = e.target.files;
        var fileData = []
        var j = 0;
        var _id = Math.round(new Date().getTime() + (Math.random() * 100));
        document.getElementById("progress-panel-container").style.display = "block";
        var progressContainer = document.getElementById('progress-holder')
        for (var i = 0; i < file.length; i++) {
            FileUploadHandler(name, file[i], progressContainer, file.length, i + 1)
                .then(data => {
                    this.setState({csvImportMsg: "CSV Import Is Under Process."})
                    document.getElementById("progress-panel-container").style.display = "none";

                    let param = {
                        collectionid: this.state.colID,
                        filepath: data.location,
                        index: ES_INDEX,
                        uid: getProfile().id
                    }
                    if (getUrlSegment(1) === 'project') {
                        param.projectid = this.state.projectId;
                    }
                    this.props.importCollectionData(param)
                    let that = this
                    setTimeout(function () {
                        that.setState({csvImportMsg: ''})
                        that.modalOpen()
                    }, 4000);

                })
                .catch(err => {
                    this.setState({messageData: ''})
                })
        }

    }

    handleFileImport = (data) => {
        let paramImport = {
            collectionid: this.state.collectionId,
            filepath: FRMSDOC_PATH + data[0].filename,
            index: ES_INDEX,
            uid: getProfile().id
        }
        this.setState({paramImport: paramImport})
    }
    messageError = (Stat, filePath) => {
        if (Stat) {
            this.setState({Stat: Stat, csvImportfalse: "Please enter a valid csv file", csvImportMsg: ""})
        } else {
            this.setState({Stat: Stat, csvImportMsg: filePath, csvImportfalse: ""})

        }
    }
    messageErrorNew = (Stat, filePath) => {
        if (Stat) {
            this.setState({Stat: Stat, excelImportfalse: "Please enter a valid Excel file", excelImportMsg: ""})
        } else {
            this.setState({Stat: Stat, excelImportMsg: filePath, excelImportfalse: ""})

        }
    }
    submitCsv = () => {
        this.props.importCollectionData(this.state.paramImport)
        this.setState({Stat: false, upload: true, csvImportMsg: "", csvImportfalse: "CSV Import Is Under Process."})
        let that = this
        setTimeout(function () {
            that.setState({upload: false})

        }, 1000);
        setTimeout(function () {
            that.setState({csvImportMsg: '', Stat: '', upload: false})
            that.modalOpen()
        }, 3000);
    }

    msgOpn = (opSt) => {
        this.setState({[opSt]: !this.state[opSt]})
    }
    msgCnt = (cnt) => {
        this.setState({msgCnt: cnt})
    }
    toasterClose = () => {
        this.setState({toasterdata: {type: "", msg: "", show: false}})
    }
    fileSelect = event => {
        this.setState({selectedFile: event.target.files[0], excelImportMsg: true})
    }
    fileUpload = () => {
        const fd = new FormData();
        fd.append('excel', this.state.selectedFile);
        fd.append('collectionid', atob(getUrlSegment(3)));
        fd.append('index', ES_INDEX);
        fd.append('uid', getProfile().id);
        this.props.importExcelData(fd)
        this.setState({
            Stat: false,
            upload: true,
            excelImportMsg: "",
            excelImportfalse: "Excel Import Is Under Process."
        })
        let that = this
        setTimeout(function () {
            that.setState({upload: false})

        }, 1000);
        setTimeout(function () {
            that.setState({excelImportMsg: '', selectedFile: [], Stat: '', upload: false})
            that.modalOpen()
        }, 3000);
    }
    onStarClick = (e, recId, star) => {
        let newState = [...this.props.collectionData.CollectionData]
        newState.filter(list => list.id === recId)[0].isStar = star
        let Params = {
            "collectionid": this.state.collectionId,
            "recordid": recId,
            "uid": getProfile().id,
            "star": star
        }
        this.setState({newState: newState})
        this.props.starClick(Params)
    }

    onNoteClick = (e, recId) => {
        let Params = {
            "collection": this.state.collectionId,
            "recordid": recId,
            "page": "0",
            "per_page": "1"
            // "uid": getProfile().id,
        }
        this.props.listNotes(Params)
        this.setState({
            // note:true,
            noteId: recId
        })
    }
    changeNotes = (value, noteid, chatNotification) => {
        let noteId = this.state.noteId !== undefined ? this.state.noteId : noteid
        var param = {
            "collectionid": this.state.colID,
            "recordid": noteId,
            "uid": getProfile().id,
            "message": value,
            "attachment": [],
            "_id": "",
            "sendnotification": chatNotification
        }
        console.log(param)
        this.props.saveNotes(param)
    }


    filterStarClick = (starred) => {
        let collectionid = this.state.colID;
        let param = {
            "search": this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
            "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
            "filters": this.state.flterData,
            "datefilters": this.state.datefilters,
            "numberfilters": this.state.numberFilter,
            "dayfilters": this.state.daysfilters,
            "dashboard": true,
            assignees: this.state.assignees,
            "facetrequired": true,
            fieldview: true,
            sort: this.state.sortParam,
            starred: starred,
            flags: this.state.flagId,

        }
        this.setState({starred: starred})
        if (getUrlSegment(1) === 'project') {
            param.projectid = this.state.projectId;
        }
        this.props.collectionDetails(collectionid, param)

    }
    editPermission = (e, type, id) => {
        this.setState({editPermissionStatus: !this.state.editPermissionStatus, selectedId: id});
        let settings = this.props.GetCollectionconfig.getcollectionconfig.settings.data;
        if (this.state.saveSettings.length > 0) {
            let owner_data = this.state.saveSettings.filter(item => parseInt(item.id) === parseInt(id))[0].Ownrecords;
            let assigned_data = this.state.saveSettings.filter(item => parseInt(item.id) === parseInt(id))[0].Assignedrecords;
            let all_data = this.state.saveSettings.filter(item => parseInt(item.id) === parseInt(id))[0].Allrecords;
            let creator_data = this.state.saveSettings.filter(item => parseInt(item.id) === parseInt(id))[0].Creatorgroups;
            let reporting_data = this.state.saveSettings.filter(item => parseInt(item.id) === parseInt(id))[0].Reportingperson;
            this.setState({
                Ownrecords: owner_data,
                Creatorgroups: creator_data,
                Reportingperson: reporting_data,
                Assignedrecords: assigned_data,
                Allrecords: all_data
            })
        }
    }
    editPermissionClose = (e) => {
        this.setState({editPermissionStatus: !this.state.editPermissionStatus});
    }

    selectWorkflow = (e) => {
        this.setState({selworkflow: e.target.value})
    }

    closeInvokeModal = () => {
        this.setState({showInvokeWorkflow: !this.state.showInvokeWorkflow})
    }
    SaveInvokeworkflow = (wid) => {
        this.setState({wloader: 1})
        let params = {
            uid: getProfile().id,
            collectionid: this.state.collectionId,
            workflowid: this.state.selworkflow,
            recordids: this.state.checkedRow
        }
        this.props.saveinvokeWorkflow(params);
    }
    invokeWorkflow = () => {
        let params = {
            uid: getProfile().id,
            limit: 0,
            offset: '100'
        }
        this.props.noformworkFlow(params);
        this.setState({showInvokeWorkflow: !this.state.showInvokeWorkflow})
    }
    assignToAllUsers = (action) => {
        this.setState({shareToAll: !this.state.shareToAll, checkedRow: !this.state.shareToAll ? ["1"] : []})
    }
    handleFlagchange = (flagname, id) => {
        let flagState = [...this.state.flagState]
        let flagId = [...this.state.flagId];
        (flagId.indexOf(id) > -1) ? flagId.splice(flagId.indexOf(id), 1) : flagId.push(id);

        this.setState({flagId: flagId})

        if ((this.state.flagState.filter(list => list.id === id)).length === 0) {
            let newItem = {
                "flagname": flagname,
                "id": id
            }

            let item = flagState.concat(newItem)
            this.setState({flagState: item})
        } else {

            let newItem = this.state.flagState.filter(list => list.id !== id)
            this.setState({flagState: newItem})

        }

        let param = {
            "from": 0,
            "size": this.state.loadmorecount,
            "search": this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
            "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
            "filters": this.state.flterData,
            "datefilters": this.state.datefilters,
            "dayfilters": this.state.daysfilters,
            "numberfilters": this.state.numberFilter,
            "dashboard": true,
            assignees: this.state.assignees,
            sort: this.state.sortParam,
            starred: this.state.starred,
            flags: flagId,
            fieldview: true

        }
        let beta = this
        let collectionid = this.state.colID;

        setTimeout(function () {
            if (getUrlSegment(1) === 'project') {
                param.projectid = beta.state.projectId;
            }
            beta.props.collectionDetails(collectionid, param);
        }, 500)
    }
    handleWorkflowFilterchange = (workflowId, stageId) => {
        let workflowFilter = [...this.state.workflowFilter];

        let item = [];
        let stages = [];
        let ids = [];
        let workflowIds = [];
        if (workflowFilter.length !== 0) {
            console.log(workflowFilter.filter(list => list.workflowid === workflowId).length)
            if ((workflowFilter.filter(list => list.workflowid === workflowId)).length === 0) {
                let newItem = {
                    "workflowid": workflowId,
                    "stageid": stages.concat(stageId)
                }
                item = workflowFilter.concat(newItem)

            } else {
                workflowFilter.filter(list => list.workflowid === workflowId).map(list => {
                    console.log(list.stageid.includes(stageId))
                    if (list.stageid.indexOf(stageId) > -1) {
                        console.log(list.stageid.indexOf(stageId))
                    }
                    (list.stageid.includes(stageId) && list.stageid.indexOf(stageId) > -1) ? list.stageid.splice(list.stageid.indexOf(stageId), 1) : list.stageid.push(stageId)
                })
                item = workflowFilter;

            }
        } else {
            let newItem = {
                "workflowid": workflowId,
                "stageid": stages.concat(stageId)
            }
            item = workflowFilter.concat(newItem)

        }
        item.length !== 0 && item.map((list, index) => {
            if (list.stageid.length !== 0) {
                ids[index] = {};
                ids[index]["workflowid"] = list.workflowid;
                ids[index]["stageid"] = list.stageid.toString();
            }
        })

        if (ids.length !== 0) {
            workflowIds = ids.filter(function (el) {
                return el != null;
            });
        }

        this.setState({workflowFilter: item})

        let param = {
            "from": 0,
            "size": this.state.loadmorecount,
            "search": this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
            "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
            "filters": this.state.flterData,
            "datefilters": this.state.datefilters,
            "dayfilters": this.state.daysfilters,
            "numberfilters": this.state.numberFilter,
            "workflowfilter": workflowIds,
            "dashboard": true,
            assignees: this.state.assignees,
            sort: this.state.sortParam,
            starred: this.state.starred,
            flags: this.state.flagId,
            "fieldview": true,

        }
        let beta = this
        let collectionid = this.state.colID;

        setTimeout(function () {
            if (getUrlSegment(1) === 'project') {
                param.projectid = beta.state.projectId;
            }
            beta.props.collectionDetails(collectionid, param);
        }, 500)

    }

    handleAssigneClick = (name, id) => {
        let collectionid = this.state.colID;
        let AssigneLabel = Array.from(this.state.AssigneLabel)

        if (this.state.assigneeList.includes(id)) {
            AssigneLabel = this.state.AssigneLabel.filter(list => (list.id !== id))
            this.setState({AssigneLabel: AssigneLabel});

        } else {
            let AssigneLabel = this.state.AssigneLabel.filter(list => (list.id.toString() !== id.toString()))
            AssigneLabel.push({"id": id, "value": name})
            this.setState({AssigneLabel: AssigneLabel});


        }
        let assigneeList = [...this.state.assigneeList];
        (assigneeList.indexOf(id) > -1) ? assigneeList.splice(assigneeList.indexOf(id), 1) : assigneeList.push(id);


        let param = {
            "from": 0,
            "size": this.state.loadmorecount,
            "search": this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid": getProfile().id,
            "fromdate": this.state.fromfilterdate === "" ? this.state.fromdate : this.state.fromfilterdate,
            "todate": this.state.tofilterdate === "" ? this.state.todate : this.state.tofilterdate,
            "filters": this.state.flterData,
            "datefilters": this.state.datefilters,
            "dayfilters": this.state.daysfilters,
            "numberfilters": this.state.numberFilter,
            "dashboard": true,
            assignees: assigneeList,
            "facetrequired": true,
            "fieldview": true,
            sort: this.state.sortParam,
            starred: this.state.starred,
            flags: this.state.flagId,

            //          listdata:((this.state.defaultTemplate ===''? this.props.collectionData.CollectionInfo.default_template:this.state.defaultTemplate === "list")?true:false)
        }
        let beta = this
        if (getUrlSegment(1) === 'project') {
            param.projectid = this.state.projectId;
        }
        setTimeout(function () {
            beta.props.collectionDetails(collectionid, param)

        }, 1000)

        this.setState({assigneeList: assigneeList, assignees: assigneeList});


    }


    changeFilter = (param, setFilter) => {

        let collectionid = this.state.colID;
        if (getUrlSegment(1) === 'project') {
            param.projectid = this.state.projectId;
        }

        this.setState({
            fromdate: param.fromdate,
            todate: param.todate,
            filters: param.filters,
            setFilter: setFilter, FilterLabel: param.FilterLabel, search: param.search,
            flterData: param.filterData,

            "numberFilter": param.numberFilter,
            filterDate: param.filterDate, "daysfilters": param.daysfilters,
            "datefilters": param.datefilters, "filter": param.filter


        })
        this.props.collectionDetails(collectionid, param);
    }

    mainClick = (label) => {
        let sublabel = label === 'view-tab' ? 'viewsettings-tab' : label === 'data-tab' ? 'propagation-tab' : label === 'listing-tab' ? 'filterd-columns' : label === 'permission-tab' ? 'dataview-tab' : label === 'field-tab' ? 'print-settings' : label === 'form-tab' ? 'quickform-tab' : label === 'export-tab' ? 'exportfield-tab' : label === 'payment-tab' ? 'pay-tab' : label === 'sidePanel-tab' ? 'rightSidePanel-tab' : 'viewsettings-tab';
        this.setState({mainPan: label, subPan: sublabel})
    }
    subClick = (label) => {
        this.setState({subPan: label})
    }
    showManageColumns = (e, type) => {

        if (type === 'share') {
            let status = e.target.value === "true" ? false : true;
            this.setState({showShareColumn: status})
        }
        if (type === 'notes') {
            let status = e.target.value === "true" ? false : true;
            this.setState({showNotesColumn: status})
        }
        if (type === 'date') {
            let status = e.target.value === "true" ? false : true;
            this.setState({showCreateDateColumn: status})
        }
        if (type === 'calendar') {
            let status = e.target.value === "true" ? false : true;
            this.setState({showCalendarColumn: status})
        }
    }
    saveManageColumns = () => {

        let params = {
            uid: getProfile().id,
            collectionid: this.state.collectionId,
            notes: this.state.showNotesColumn,
            share: this.state.showShareColumn,
            showdate: this.state.showCreateDateColumn,
            calendar: this.state.showCalendarColumn,
            calendar_fields: this.state.calendarFields,
        }

        console.log(params)
        this.props.savingNoteShare(params);

    }
    roleFinder = (roleid) => {
        let roleName = '';
        let roles = [];
        let settings = this.props.GetCollectionconfig.getcollectionconfig.settings;
        let roleSettings = settings.roles && settings.roles.length !== 0 ? settings.roles : [];
        if (roleSettings.length !== 0) {
            roles = roleSettings.filter((list) => list.id === roleid);
            if (roles.length !== 0) {
                roleName = roles[0].role;
            }
        }
        return roleName;
    }

    showPublishWarning = () => {
        this.setState({
            showPublishStatus: true
        })
    }
    hidePublishWarning = () => {
        this.setState({
            showPublishStatus: false
        })
    }

    copySharingUrl = (e) => {
        this.setState({
            urlCopied: false
        })
        let url = window.location.hostname + "/sharing/form/" + btoa(this.state.collectionId) + "?metaid={metaid}";
        let selectedUrl = url.toString();
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute("id", "dummy_id");
        document.getElementById("dummy_id").value = selectedUrl;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        this.setState({
            urlCopied: true
        })
    }

    showBookingCalendar = (e, row) => {

        let collectionid = this.state.collectionId;
        let params = {_id: row['id'], collectionid: collectionid, metainfo: true, wfinfo: true};
        this.props.getFormData(params);
        console.log(getProfile())
        console.log(row)
        console.log(this.state.calendarFields)

        let bookingCustName = '';
        if (this.state.calendarFields.length > 0) {
            let fld = this.state.calendarFields[0] || '';
            bookingCustName = row[fld];
        }

        let name = ES_INDEX === 'tata' ? '911236364' : '911236364';
        let email = ES_INDEX === 'tata' ? '1641960947362' : '1641960947362';
        let contact_number = ES_INDEX === 'tata' ? '1641960947402' : '1641960947402';
        let recordId = ES_INDEX === 'tata' ? '1466653631' : '1466653631';
        this.setState({
            showBookingStatus: true,
            bookingCustId: row['id'],
            bookingCustName: row[name],
            bookingCustPhone: row[contact_number],
            bookingCustEmail: row[email],
            bookingCustUid: row[recordId],
            custInfo: row
        })
    }
    closeBookingCalendar = (e, id) => {
        this.setState({
            showBookingStatus: false
        })
    }

    chooseCalendarField = (e) => {
        let fieldId = e.target.value;
        let calendarFields = this.state.calendarFields || [];
        if (calendarFields.includes(fieldId)) {
            var index = calendarFields.indexOf(fieldId);
            calendarFields.splice(index, 1);
        } else {
            calendarFields.push(fieldId);
        }

        this.setState({
            calendarFields: calendarFields
        })
    }

    toggleModal(stat, header = '', message = '', showOK = true, OKaction = '', showCancel = false, cancelAction = '', size = 'md') {
        this.setState({
            showModal: stat,
            modalHeader: header,
            modalMessage: message,
            modalOKaction: OKaction,
            modalShowOK: showOK,
            modalShowCancel: showCancel,
            modalCancelAction: cancelAction,
            modalSize: size
        });
    }

    showUserDetails = (props) => {
        //console.log(props)
        this.setState({bookingProps: props, page: 'userentry'});
    }

    toggleModal = (stat, header = '', message = '', showOK = true, OKaction = '', showCancel = false, cancelAction = '', size = 'md') => {
        this.setState({
            showModal: stat,
            modalHeader: header,
            modalMessage: message,
            modalOKaction: OKaction,
            modalShowOK: showOK,
            modalShowCancel: showCancel,
            modalCancelAction: cancelAction,
            modalSize: size
        });
    }


    render() {
        //console.log(this.state)
        //console.log(this.props);
        let collection = this.props.collectionData;
        let dataview = this.props.GetCollectionconfig.getcollectionconfig.dataview;
        let settings = this.props.GetCollectionconfig.getcollectionconfig.settings;
        let summary = this.props.GetCollectionconfig.getcollectionconfig.summary;
        let print = this.props.GetCollectionconfig.getcollectionconfig.print ? this.props.GetCollectionconfig.getcollectionconfig.print : [];
        let roleSettings = settings.roles && settings.roles.length !== 0 ? settings.roles : [];
        let propagationData = this.props.GetCollectionconfig.getcollectionconfig.propagation;
        let sysEntitySetting = this.props.GetCollectionconfig.getcollectionconfig.systementitymapping;
        let collectionFilter = this.props.filterDatadetails.filterData
        let listuser = this.props.listUsers.data;
        let datausers = this.props.listUsers.Users.length > 0 ? this.props.listUsers.Users : '';
        let permissionsSelected = this.props.sharedCollectionData ? this.props.sharedCollectionData.collectionsettings : '';
        let createuser = collection.CollectionInfo.length !== 0 ? collection.CollectionInfo.createuser : '';
        let exported = permissionsSelected.length > 0 ? permissionsSelected[0].export : '';

        let showlistField = [];
        let showlinkingpermission = []
        //let advancedSelectionFields = [];

        if (this.props.showFormModal.showformdata.length !== 0) {

            let formPages = this.props.showFormModal.showformdata.pages || [];
            let pageComponents = formPages.map((page, pageIndex) => {
                return page.components;
            });

            let componentList = [].concat.apply([], pageComponents);


            showlistField = componentList.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden" && list.type !== "fieldset");

            //showlinkingpermission = componentList.filter(list => list.externallink === 'Yes');
            //advancedSelectionFields = componentList.filter(list => list.adv_search === 'Yes');


            for (let i = 0; i < this.props.showFormModal.showformdata.pages.length; i++) {
                (this.props.showFormModal.showformdata.pages[i].components.filter(list => list.externallink === 'Yes')).map(list => {
                    showlinkingpermission.push(list)
                })
            }
        }
        let advancedSelectionFields = []

        if (this.props.showFormModal.showformdata.length !== 0) {
            for (let i = 0; i < this.props.showFormModal.showformdata.pages.length; i++) {
                (this.props.showFormModal.showformdata.pages[i].components.filter(list => list.adv_search === 'Yes')).map(list => {
                    advancedSelectionFields.push(list)
                })
            }
        }

        let showlinkingpermissionid = [];
        let showlinkingpermissionArray = [];
        if (collection.CollectionInfo.length !== 0) {
            showlinkingpermissionid = collection.CollectionInfo.linked_formpermission;
            showlinkingpermissionArray = collection.CollectionInfo;
        }
        let collectionid = this.state.collectionId;
        let Userpermission = ((permissionsSelected.length > 0 && permissionsSelected[0].assign) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || (createuser.toString()) === (getProfile().id).toString()))
        let dashboard = this.props.collectionData.dashboard;
        let default_template = this.state.template === '' ? this.props.collectionData.CollectionInfo.default_template : this.state.template
        let horizontaltemplate = this.props.collectionData.horizontaltemplate
        let verticaltemplate = this.props.collectionData.verticaltemplate
        let minitemplate = this.props.collectionData.minitemplate
        let maptemplate = this.props.collectionData.maptemplate;
        let long1template = this.props.collectionData.long1template
       // let kanbantemplate = this.props.collectionData.kanbantemplate
        //let kanbanlist = this.props.collectionData.kanbanlist

        let horizontalImage = this.state.horizontalImage === "" ? (this.props.collectionData.CollectionInfo.horizontalImage) : this.state.horizontalImage
        let verticalImage = this.state.verticalImage === "" ? (this.props.collectionData.CollectionInfo.verticalImage) : this.state.verticalImage
        let miniImage = this.state.miniImage === "" ? (this.props.collectionData.CollectionInfo.miniImage) : this.state.miniImage
        let long1Image = this.state.long1Image === "" ? (this.props.collectionData.CollectionInfo.long1Image) : this.state.long1Image
        let horizontalMapenabled = this.state.horizontalMapenabled === "" ? (this.props.collectionData.CollectionInfo.horizontalMapenabled) : this.state.horizontalMapenabled
        let verticalMapenabled = this.state.verticalMapenabled === "" ? (this.props.collectionData.CollectionInfo.verticalMapenabled) : this.state.verticalMapenabled
        let miniMapenabled = this.state.miniMapenabled === "" ? (this.props.collectionData.CollectionInfo.miniMapenabled) : this.state.miniMapenabled
        let collectionRecord = {
            cId: (this.state.collectionId),
            recordId: this.state.checkedRow,
            cName: collection.CollectionInfo.title
        }
        let collData = collection.collData
        let facet = this.props.collectionData.facet
        let customtemplate = this.props.collectionData.customtemplate
        let customtemplate_other = this.props.collectionData.customtemplate_other
        let mobiletemplate = this.props.collectionData.mobiletemplate
        let mobiletemplate_other = this.props.collectionData.mobiletemplate_other
        let mobiletemplate_settings = this.props.collectionData.mobiletemplate_settings
        let listNoWorkFlow = this.props.listnoFormWorkFlow ? this.props.listnoFormWorkFlow.noFormWorkFlow : [];
        let formPublished = this.props.formInfo && this.props.formInfo.master_published ? this.props.formInfo.master_published : false;


        let calendarFields = this.state.calendarFields
        let formtitle = collection.CollectionInfo.custom_name
        let restrictedView = collection.CollectionInfo.restrictedview
console.log(collection.CollectionInfo)
        return (
              <>
                {collection && collection.CollectionInfo && collection.CollectionInfo.hasOwnProperty('restrictedview') && restrictedView === true ?
                    <div className='absolute-holder'>
                        <NoData
                            msg={`View Permission Restricted`}
                        />
                    </div>
                    :

                    <React.Fragment>
                        {this.state.toasterdata.show &&
                        <Toaster
                            toasterdata={this.state.toasterdata}
                            toasterClose={this.toasterClose}
                        />
                        }
                        <div
                            className={`right-content-wrapper collection-view ${this.state.action === 'settings' ? " project-tab-rise" : ""} ${this.state.buttonStatus ? "activate-layer" : ""}`}
                            key={`collection-details-data`}>
                            <div
                                className={`form-detail-container data-view field-form small-no-tab with-opt ${this.props.listFilterdList.data.length !== 0 ? "filter-view" : ""} ${this.state.checkedRow.length !== 0 ? "collection-more-block" : ""} ${(ES_INDEX === 'ibt' || ES_INDEX === 'ibtstaging') ? "with-pj-sidebar" : ""} ${getUrlSegment(1) === 'project' ? "project-collection-wrap" : ""}`}>
                                <div className="form-detail-panel">

                                    <div className="form-detail-right">
                                        <div className={`form-detail-top project-top small-header`}>
                                       <span className="form-detail-top-left common-bk-button"
                                             onClick={() => (getUrlSegment(1) === "project" && getUrlSegment(2) === "details") ? this.props.history.push("/project/details/collections/" + getUrlSegment(5)) : this.props.history.push("/collections")}>
                                        <h5>{collection.CollectionInfo.title}</h5>
                                        <p>{collection.CollectionInfo.description}</p>
                                    </span>
                                            {this.state.action === 'settings' &&
                                            <div className="task-nav-left bg-back bread-crumb">
                                                <button className="backto-list"
                                                        onClick={(e) => this.backtoListing(e)}>Back To Listing
                                                </button>
                                            </div>
                                            }
                                            <div className="form-detail-top-right">
                                                {this.state.action !== 'settings' &&
                                                <SearchOptionsPanel
                                                    handleSearch={this.handleSearch}
                                                    state={this.state}
                                                    showDeleteConfirm={this.showDeleteConfirm}
                                                    showDuplicateConfirm={this.showDuplicateConfirm}
                                                    selectExport={this.selectExport}
                                                    handleAction={this.handleAction}
                                                    filterToggle={this.filterToggle}
                                                    invokeWorkflow={this.invokeWorkflow}
                                                    checkedRow={this.state.checkedRow}
                                                    permissionsSelected={permissionsSelected}
                                                    advancedSelectionFields={advancedSelectionFields}
                                                    submitAdvncSearch={this.submitAdvncSearch}
                                                    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                                />
                                                }
                                                {dashboard.length !== 0 && this.state.checkedRow.length === 0 &&
                                                <div onClick={() => this.msgOpn("dashStat")}
                                                     id="dashboard" className="collection-dashboard"
                                                     title="Collection Dashboard">
                                                </div>
                                                }

                                                {this.state.checkedRow.length === 0 &&
                                                <div onClick={() => this.msgOpn("msgOpn")}
                                                     id="chat"
                                                     className={`collection-chat ${(!this.state.msgOpn && this.state.msgCnt !== "") ? "active" : ""}`}
                                                     title="Collection Chat">
                                                </div>
                                                }


                                                {(exported || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || createuser.toString() === getProfile().id.toString()) && this.state.checkedRow.length === 0 && collection.CollectionInfo.length !== 0 && collection.CollectionData.length !== 0 &&
                                                <div onClick={() => this.exportAll(false)}
                                                     id="exportExcel" className="general-btn ico-lib ex-cel-ico"
                                                     target={"_blank"}>Export All
                                                </div>
                                                }
                                                <ActionButton
                                                    handleAction={this.handleAction}
                                                    collectionid={collectionid}
                                                    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                                    permissionsSelected={permissionsSelected}
                                                    checkedRow={this.state.checkedRow.length}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.action !== 'settings' &&
                                <FilterdView
                                    changeFilter={this.changeFilter}
                                />
                                }


                                {this.state.action !== 'settings' &&
                                <div
                                    className={`data-holder form-wrap ${(default_template === "vertical" || default_template === "map" || default_template === "horizontal" || default_template === "mini" || default_template === "long1") && 'template-container-view map-view'} ${(default_template === "list") && 'template-container-view vertical-list'} ${this.props.haveLoader.loading === 1 ? 'with-loader' : ''}`}
                                    id="formData" style={{display: this.state.stage === 'data' ? "block" : "none"}}>
                                    {this.props.loader.loaderType === 'collection-details' && this.props.loader.status === true && this.props.collectionData.CollectionInfo.default_template !== "map" &&
                                    <span className="Loader-holder">
                                 <LoaderSvg/>
                              </span>
                                    }
                                    <div
                                        className={`side-tab ${(collection.CollectionInfo.length !== 0 && collection.CollectionInfo.showdatefilter === false && this.state.setFilter === "" && collection.flagCategory && collection.flagCategory.length === 0 && collection.workflow_filter && collection.workflow_filter.length === 0 && facet && facet.length === 0) ? "without-filter" : ""}`}>
                                        <div className={`data-holder-left ${this.state.tPane ? "active" : ""}`}>

                                            <Scrollbars style={{width: '100%', height: `100%`}} autoHide
                                                        autoHideTimeout={1000}>
                                                <div className="mob-filter-header">
                                                    <h5>Filter By</h5>
                                                    <button className="close-ico" onClick={() => this.filterToggle()}/>
                                                </div>
                                                {this.state.action !== 'settings' &&
                                                //this.state.setFilter === "" &&
                                                <Facets
                                                    facet={facet}
                                                    handlefilterSearch={this.handlefilterSearch}
                                                    FilterLabel={this.state.FilterLabel}
                                                    totalCount={collection.totalchilds}
                                                    filterData={collectionFilter}
                                                    dateWise={this.dateWise}
                                                    daysFilter={this.daysFilter}
                                                    dateRange={this.dateRange}
                                                    numberFilter={this.numberFilter}
                                                    rangeFilter={this.rangeFilter}
                                                    filter={this.state.filter}
                                                    fromdate={this.state.fromdate}
                                                    todate={this.state.todate}
                                                    setFilter={this.state.setFilter}
                                                    filterDate={this.state.filterDate}
                                                    daysfilters={this.state.daysfilters}
                                                    datefilters={this.state.datefilters}
                                                    customizeDateTo={this.state.customizeDateTo}
                                                    customizeDateFrom={this.state.customizeDateFrom}
                                                    handleFromChange={this.handleFromChange()}
                                                    clusterId={this.state.clusterId}
                                                    collection={collection}
                                                    handleFlagchange={this.handleFlagchange}
                                                    handleAssigneClick={this.handleAssigneClick}
                                                    assigneeList={this.state.assigneeList}
                                                    showdatefilter={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.showdatefilter}
                                                    dateopen={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.dateopen}
                                                    flagopen={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.flagopen}
                                                    handleWorkflowFilterchange={this.handleWorkflowFilterchange}

                                                />

                                                }

                                                <div className="mob-filter-footer">
                                                    <button type="button" onClick={() => this.filterToggle()}
                                                            className="general-btn">Apply
                                                    </button>
                                                </div>
                                            </Scrollbars>


                                        </div>

                                        <div className="data-holder-right filter-strech">
                                            {this.state.action !== 'settings' &&
                                            <React.Fragment>
                                                <FilterTag
                                                    totalCount={collection.totalchilds}
                                                    filterData={collectionFilter}
                                                    filter={this.state.filter}
                                                    fromdate={this.state.fromdate}
                                                    todate={this.state.todate}
                                                    filterDate={this.state.filterDate}
                                                    daysfilters={this.state.daysfilters}
                                                    datefilters={this.state.datefilters}
                                                    customizeDateTo={this.state.customizeDateTo}
                                                    customizeDateFrom={this.state.customizeDateFrom}
                                                    FilterLabel={this.state.FilterLabel}
                                                    handlefilterSearch={this.handlefilterSearch}
                                                    dateWise={this.dateWise}
                                                    daysFilter={this.daysFilter}
                                                    dateRange={this.dateRange}
                                                    numberFilter={this.numberFilter}
                                                    handleFromChange={this.handleFromChange()}
                                                    default_template={default_template}
                                                    handleViewChange={this.handleViewChange}
                                                    setFilter={this.state.setFilter}
                                                    handleAssigneClick={this.handleAssigneClick}
                                                    AssigneLabel={this.state.AssigneLabel}

                                                />


                                                {((this.state.viewType === "" && (default_template === "list" || default_template === "map")) ||
                                                    this.state.viewType === "list") &&
                                                <ResultTable
                                                    label={this.props.showFormModal}
                                                    collection={collection.CollectionData}
                                                    handleSelectAll={this.handleSelectAll}
                                                    showPreviewFormModal={this.showPreviewFormModal}
                                                    state={this.state}
                                                    handleSelectRow={this.handleSelectRow}
                                                    showAssignedUsers={this.showAssignedUsers}
                                                    loadmoreCollectionData={this.loadmoreCollectionData}
                                                    showFormModal={this.showFormModal}
                                                    key={`result-table`}
                                                    permissionsSelected={permissionsSelected}
                                                    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                                    onStarClick={this.onStarClick}
                                                    filterStarClick={this.filterStarClick}
                                                    handleClicks={this.handleClicks}
                                                    sortingData={this.sortingData}
                                                    onNoteClick={this.onNoteClick}
                                                    value={this.props.listAnnotation}
                                                    changeNotes={this.changeNotes}
                                                    assignToAllUsers={this.assignToAllUsers}
                                                    flags={collection.flaglist}
                                                    flagList={this.flagList}
                                                    showlist={collection.showlist}
                                                    fieldview={collection.fieldview}
                                                    showlistField={showlistField}
                                                    showBookingCalendar={this.showBookingCalendar}
                                                    CollectionInfo={this.props.collectionData.CollectionInfo}

                                                />
                                                }
                                                {/* new Api integration  */}

                                                {/*{((this.state.viewType === "" && default_template === "list") ||*/}
                                                {/*    this.state.viewType === "list") &&*/}
                                                {/*<DataTable*/}
                                                {/*    collData={collData}*/}
                                                {/*    collLabel={collLabel}*/}
                                                {/*    label={this.props.showFormModal}*/}
                                                {/*    collection={collection.CollectionData}*/}
                                                {/*    handleSelectAll={this.handleSelectAll}*/}
                                                {/*    showPreviewFormModal={this.showPreviewFormModal}*/}
                                                {/*    state={this.state}*/}
                                                {/*    handleSelectRow={this.handleSelectRow}*/}
                                                {/*    showAssignedUsers={this.showAssignedUsers}*/}
                                                {/*    loadmoreCollectionData={this.loadmoreCollectionData}*/}
                                                {/*    showFormModal={this.showFormModal}*/}
                                                {/*    key={`result-table`}*/}
                                                {/*    permissionsSelected={permissionsSelected}*/}
                                                {/*    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}*/}

                                                {/*/>*/}
                                                {/*}*/}


                                                {/*{default_template === "vertical" &&*/}
                                                {((this.state.viewType === "" && default_template === "vertical") || this.state.viewType === "vertical") &&

                                                <Template1
                                                    label={this.props.showFormModal}
                                                    collection={collection}
                                                    handleSelectAll={this.handleSelectAll}
                                                    showPreviewFormModal={this.showPreviewFormModal}
                                                    state={this.state}
                                                    handleSelectRow={this.handleSelectRow}
                                                    showAssignedUsers={this.showAssignedUsers}
                                                    loadmoreCollectionData={this.loadmoreCollectionData}
                                                    showFormModal={this.showFormModal}
                                                    verticaltemplate={verticaltemplate}
                                                    horizontaltemplate={horizontaltemplate}
                                                    minitemplate={minitemplate}
                                                    verticalImage={verticalImage}
                                                    verticalMapenabled={verticalMapenabled}
                                                    key={`result-table`}
                                                    permissionsSelected={permissionsSelected}
                                                    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                                    showOption={this.showOption}
                                                    showtemplateOptions={this.state.showtemplateOptions}
                                                    collectededitid={this.state.collectededitid}
                                                    handleClicks={this.handleClicks}
                                                    handleFlagSet={this.handleFlagSet}

                                                />
                                                }
                                                {((this.state.viewType === "" && default_template === "mini") || this.state.viewType === "mini") &&

                                                <Template6
                                                    label={this.props.showFormModal}
                                                    collection={collection}
                                                    handleSelectAll={this.handleSelectAll}
                                                    showPreviewFormModal={this.showPreviewFormModal}
                                                    state={this.state}
                                                    handleSelectRow={this.handleSelectRow}
                                                    showAssignedUsers={this.showAssignedUsers}
                                                    loadmoreCollectionData={this.loadmoreCollectionData}
                                                    showFormModal={this.showFormModal}
                                                    verticaltemplate={verticaltemplate}
                                                    horizontaltemplate={horizontaltemplate}
                                                    minitemplate={minitemplate}
                                                    verticalImage={verticalImage}
                                                    miniImage={miniImage}
                                                    long1Image={long1Image}
                                                    verticalMapenabled={verticalMapenabled}
                                                    miniMapenabled={miniMapenabled}
                                                    key={`result-table`}
                                                    permissionsSelected={permissionsSelected}
                                                    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                                    showOption={this.showOption}
                                                    showtemplateOptions={this.state.showtemplateOptions}
                                                    collectededitid={this.state.collectededitid}
                                                    handleClicks={this.handleClicks}
                                                    handleFlagSet={this.handleFlagSet}

                                                />
                                                }
                                                {((this.state.viewType === "" && default_template === "long1") || this.state.viewType === "long1") &&

                                                <TemplateLongCard
                                                    label={this.props.showFormModal}
                                                    collection={collection}
                                                    handleSelectAll={this.handleSelectAll}
                                                    showPreviewFormModal={this.showPreviewFormModal}
                                                    state={this.state}
                                                    handleSelectRow={this.handleSelectRow}
                                                    showAssignedUsers={this.showAssignedUsers}
                                                    loadmoreCollectionData={this.loadmoreCollectionData}
                                                    showFormModal={this.showFormModal}
                                                    verticaltemplate={verticaltemplate}
                                                    horizontaltemplate={horizontaltemplate}
                                                    minitemplate={minitemplate}
                                                    long1template={long1template}
                                                    verticalImage={verticalImage}
                                                    miniImage={miniImage}
                                                    long1Image={long1Image}
                                                    verticalMapenabled={verticalMapenabled}
                                                    miniMapenabled={miniMapenabled}
                                                    key={`result-table`}
                                                    permissionsSelected={permissionsSelected}
                                                    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                                    showOption={this.showOption}
                                                    showtemplateOptions={this.state.showtemplateOptions}
                                                    collectededitid={this.state.collectededitid}
                                                    handleClicks={this.handleClicks}
                                                    handleFlagSet={this.handleFlagSet}
                                                    default_template={default_template}

                                                />
                                                }
                                                {/*{default_template === "horizontal" &&*/}
                                                {((this.state.viewType === "" && default_template === "horizontal") || this.state.viewType === "horizontal") &&
                                                <Template2
                                                    label={this.props.showFormModal}
                                                    collection={collection}
                                                    handleSelectAll={this.handleSelectAll}
                                                    showPreviewFormModal={this.showPreviewFormModal}
                                                    state={this.state}
                                                    handleSelectRow={this.handleSelectRow}
                                                    showAssignedUsers={this.showAssignedUsers}
                                                    loadmoreCollectionData={this.loadmoreCollectionData}
                                                    showFormModal={this.showFormModal}
                                                    verticaltemplate={verticaltemplate}
                                                    horizontaltemplate={horizontaltemplate}
                                                    horizontalImage={horizontalImage}
                                                    minitemplate={minitemplate}
                                                    verticalImage={horizontalImage}
                                                    horizontalMapenabled={horizontalMapenabled}
                                                    key={`result-table`}
                                                    permissionsSelected={permissionsSelected}
                                                    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                                    showOption={this.showOption}
                                                    showtemplateOptions={this.state.showtemplateOptions}
                                                    collectededitid={this.state.collectededitid}
                                                    handleClicks={this.handleClicks}
                                                />
                                                }
                                                {/*{((this.state.viewType === "" && default_template === "kanban") || this.state.viewType === "kanban") &&*/}
                                                {/*    <TemplateKanban*/}
                                                {/*        label={this.props.showFormModal}*/}
                                                {/*        collection={collection}*/}
                                                {/*        handleSelectAll={this.handleSelectAll}*/}
                                                {/*        showPreviewFormModal={this.showPreviewFormModal}*/}
                                                {/*        state={this.state}*/}
                                                {/*        handleSelectRow={this.handleSelectRow}*/}
                                                {/*        showAssignedUsers={this.showAssignedUsers}*/}
                                                {/*        loadmoreCollectionData={this.loadmoreCollectionData}*/}
                                                {/*        showFormModal={this.showFormModal}*/}
                                                {/*        key={`result-table`}*/}
                                                {/*        permissionsSelected={permissionsSelected}*/}
                                                {/*        createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}*/}
                                                {/*        showOption={this.showOption}*/}
                                                {/*        showtemplateOptions={this.state.showtemplateOptions}*/}
                                                {/*        collectededitid={this.state.collectededitid}*/}
                                                {/*        handleClicks={this.handleClicks}*/}
                                                {/*        kanbanlist={kanbanlist}*/}
                                                {/*    />*/}
                                                {/*}*/}
                                                {(this.state.viewType === "map") &&
                                                <Template3
                                                    label={this.props.showFormModal}
                                                    collection={collection}
                                                    maptemplate={maptemplate}
                                                    collData={collData}
                                                />
                                                }

                                                {(this.state.viewType === "cluster") &&
                                                <Template4
                                                    label={this.props.showFormModal}
                                                    collection={collection}
                                                    maptemplate={maptemplate}
                                                    collData={collData}
                                                />
                                                }
                                                {/*{((this.state.viewType === "" && default_template === "longcontent") || this.state.viewType==="longcontent" )&&*/}
                                                {/*<Template5*/}
                                                {/*    label={this.props.showFormModal}*/}
                                                {/*    collection={collection}*/}
                                                {/*    handleSelectAll={this.handleSelectAll}*/}
                                                {/*    showPreviewFormModal={this.showPreviewFormModal}*/}
                                                {/*    state={this.state}*/}
                                                {/*    handleSelectRow={this.handleSelectRow}*/}
                                                {/*    showAssignedUsers={this.showAssignedUsers}*/}
                                                {/*    loadmoreCollectionData={this.loadmoreCollectionData}*/}
                                                {/*    showFormModal={this.showFormModal}*/}
                                                {/*    verticaltemplate={verticaltemplate}*/}
                                                {/*    horizontaltemplate={horizontaltemplate}*/}
                                                {/*    horizontalImage={horizontalImage}*/}
                                                {/*    verticalImage={horizontalImage}*/}
                                                {/*    horizontalMapenabled={horizontalMapenabled}*/}
                                                {/*    key={`result-table`}*/}
                                                {/*    permissionsSelected={permissionsSelected}*/}
                                                {/*    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}*/}
                                                {/*    showOption={this.showOption}*/}
                                                {/*    showtemplateOptions={this.state.showtemplateOptions}*/}
                                                {/*    collectededitid={this.state.collectededitid}*/}
                                                {/*    handleClicks={this.handleClicks}*/}
                                                {/*/>*/}
                                                {/*}*/}

                                                {/*{default_template === "map" &&  (this.props.collectionData.totalchilds !== this.props.collectionData.CollectionData.length) &&*/}
                                                {/*       <span className="Loader-holder">*/}
                                                {/*           <LoaderSvg/>*/}
                                                {/*        </span>*/}

                                                {/*}*/}
                                            </React.Fragment>
                                            }


                                        </div>
                                    </div>

                                </div>
                                }
                                {(this.state.action === 'settings' || this.state.AddNew === 'addnew') &&
                                <div className="data-holder form-set side-set-scroll mobile-top collection-settings">
                                    {this.props.loader.loaderType === 'collection-details' && this.props.loader.status === true && this.props.collectionData.CollectionInfo.default_template !== "map" &&
                                    <span className="Loader-holder">
                                        <LoaderSvg/>
                                    </span>
                                    }
                                    <div className="side-tab">
                                        <div className="nav nav-pills" id="v-pills-tab" role="tablist"
                                             aria-orientation="vertical">

                                            <Scrollbars style={{width: '100%', height: `100%`}} autoHide
                                                        autoHideTimeout={1000}>

                                                <div className="settings-group-link">

                                                    {dataNav1.map((label, index) =>
                                                        <div
                                                            className={`each-setting-group ${this.state.mainPan === label.AcTab ? "active" : ""}`}>
                                                            <nav
                                                                onClick={() => this.mainClick(label.AcTab)}> {label.label}</nav>
                                                            <ul className="each-setting-child-block">
                                                                {label.subdataNav.map((list) =>
                                                                    <li className={`${this.state.subPan === list.AcTab ? "active" : ""}`}>
                                                                        <nav
                                                                            onClick={() => this.subClick(list.AcTab)}>{list.label}</nav>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    )}

                                                </div>
                                            </Scrollbars>

                                        </div>

                                        <div className="tab-content form-set" id="v-pills-tabContent">
                                            {(this.state.subPan === "dataview-tab") &&
                                            <div className="tab-pane setting-pane fade show active" id="v-pills-home"
                                                 role="tabpanel"
                                                 aria-labelledby="v-pills-home-tab">

                                                <div className="settings-loungue-header">
                                                    <div className="tab-wrap-block full-width-tab">
                                                        <div className="tab-wrap-block-left">
                                                            <h5>Record Permission</h5>
                                                            <p>Select the viewing permission of collection data</p>
                                                        </div>
                                                        <div className="tab-wrap-block-right">
                                                            <button className="general-btn  focus-resolve"
                                                                    id="savedata_viewpermission"
                                                                    onClick={this.saveDataView}>Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="set-container">
                                                    <div className="next-stage-selector block-line">
                                                        <div className="select-box-container">
                                                            <div className="select-chk-block"
                                                                 style={{"display": "block"}}>
                                                                <label className="control control-checkbox">Own Records
                                                                    <input type="checkbox" className="inp-chk"
                                                                           id="text_searchable" name="dataview"
                                                                           value={this.state.own_record !== '' ? this.state.own_record : (dataview.data.length !== 0 ? dataview.data[0].own_record : this.state.own_record)}
                                                                           defaultChecked={dataview.data.length !== 0 ? dataview.data[0].own_record : this.state.own_record}
                                                                           onClick={(e) => this.dataView(e, "own_record")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>

                                                            <div className="select-chk-block"
                                                                 style={{"display": "block"}}>
                                                                <label className="control control-checkbox">Creator
                                                                    Groups
                                                                    <input type="checkbox" className="inp-chk"
                                                                           id="text_searchable" name="dataview"
                                                                           value={this.state.creator_group !== '' ? this.state.creator_group : (dataview.data.length !== 0 ? dataview.data[0].creator_group : '')}
                                                                           defaultChecked={dataview.data.length !== 0 ? dataview.data[0].creator_group : this.state.creator_group}
                                                                           onClick={(e) => this.dataView(e, "creator_group")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block"
                                                                 style={{"display": "block"}}>
                                                                <label className="control control-checkbox">Creator
                                                                    Reporting
                                                                    Person
                                                                    <input type="checkbox" className="inp-chk"
                                                                           id="text_searchable" name="dataview"
                                                                           value={this.state.report_person !== '' ? this.state.report_person : (dataview.data.length !== 0 ? dataview.data[0].report_person : '')}
                                                                           defaultChecked={dataview.data.length !== 0 ? dataview.data[0].report_person : this.state.report_person}
                                                                           onClick={(e) => this.dataView(e, "report_person")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>

                                                            <div className="select-chk-block"
                                                                 style={{"display": "block"}}>
                                                                <label className="control control-checkbox">Project
                                                                    Settings
                                                                    <input type="checkbox" className="inp-chk"
                                                                           id="text_searchable" name="dataview"
                                                                           value={this.state.set_project !== '' ? this.state.set_project : (dataview.data.length !== 0 ? dataview.data[0].set_project : '')}
                                                                           defaultChecked={dataview.data.length !== 0 ? dataview.data[0].set_project : this.state.set_project}
                                                                           onClick={(e) => this.dataView(e, "set_project")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            }
                                            {this.state.subPan === "exportfield-tab" &&
                                                    <Suspense fallback={renderLoader()}>
                                                        <ExportMetainfo
                                                            exportfield={this.props.exportfield}
                                                            saveFieldData={this.props.saveFieldData}
                                            />
                                                    </Suspense>

                                            }

                                            {this.state.subPan === "listingfilter-tab" &&
                                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                                 aria-labelledby="v-pills-home-tab">

                                                <div className="settings-loungue-header">
                                                    <div className="tab-wrap-block full-width-tab">
                                                        <div className="tab-wrap-block-left">
                                                            <h5>Listing & Filter</h5>
                                                        </div>
                                                        <div className="tab-wrap-block-right">
                                                            <button className="general-btn  focus-resolve"
                                                                    id="savedata_viewpermission"
                                                                    onClick={this.saveFilter}>Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="set-container">
                                                    <div className="next-stage-selector block-line">
                                                        <div class="inline-container">
                                                            <div className="select-chk-block"
                                                                 style={{"display": "block"}}>
                                                                <label className="control control-checkbox">Datewise
                                                                    Filter
                                                                    <input type="checkbox" className="inp-chk"
                                                                           id="text_searchable"
                                                                           value={this.state.showdatefilter !== '' ? this.state.showdatefilter : collection.CollectionInfo.showdatefilter}
                                                                           defaultChecked={collection.CollectionInfo.showdatefilter}
                                                                           onClick={(e) => this.showFilter(e)}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="right-switch">
                                                                <label>Filter Settings</label>
                                                                <button type="button"
                                                                        className={`btn-toggle ${this.state.dateopen ? 'active' : ''}`}
                                                                        data-toggle="button" aria-pressed="false"
                                                                        onClick={this.handleDafultOpenSelect}>
                                                                    <div className="handle"></div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            }


                                            {this.state.subPan === "manage-columns" &&
                                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                                 aria-labelledby="v-pills-home-tab">

                                                <div className="settings-loungue-header">
                                                    <div className="tab-wrap-block full-width-tab">
                                                        <div className="tab-wrap-block-left">
                                                            <h5>Field Visibility</h5>
                                                        </div>
                                                        <div className="tab-wrap-block-right">
                                                            <button className="general-btn  focus-resolve"
                                                                    id="savedata_viewpermission"
                                                                    onClick={this.saveManageColumns}>Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="set-container">
                                                    <div className="next-stage-selector block-line">
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Enable Share
                                                                <input type="checkbox" className="inp-chk"
                                                                       id="text_searchable"
                                                                       value={this.state.showShareColumn}
                                                                       defaultChecked={this.state.showShareColumn}
                                                                       onClick={(e) => this.showManageColumns(e, 'share')}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="next-stage-selector block-line">
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Enable Notes
                                                                <input type="checkbox" className="inp-chk"
                                                                       id="text_searchable"
                                                                       value={this.state.showNotesColumn}
                                                                       defaultChecked={this.state.showNotesColumn}
                                                                       onClick={(e) => this.showManageColumns(e, 'notes')}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="next-stage-selector block-line">
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Enable Timestamp
                                                                <input type="checkbox" className="inp-chk"
                                                                       id="text_searchable"
                                                                       value={this.state.showCreateDateColumn}
                                                                       defaultChecked={this.state.showCreateDateColumn}
                                                                       onClick={(e) => this.showManageColumns(e, 'date')}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {/*<div className="next-stage-selector block-line">*/}
                                                    {/*    <div className="select-chk-block" style={{"display": "block"}}>*/}
                                                    {/*        <label className="control control-checkbox">Enable Calendar*/}
                                                    {/*            <input type="checkbox" className="inp-chk" id="text_searchable"*/}
                                                    {/*                   value={this.state.showCalendarColumn}*/}
                                                    {/*                   defaultChecked={this.state.showCalendarColumn}*/}
                                                    {/*                   onClick={(e) => this.showManageColumns(e, 'calendar')}/>*/}
                                                    {/*            <div className="control-indicator"></div>*/}
                                                    {/*        </label>*/}
                                                    {/*    </div>*/}

                                                    {/*    <div className="select-box">*/}
                                                    {/*        <select id={`dropOptions_cal`}*/}
                                                    {/*                name={`dropOptions_cal`}*/}
                                                    {/*        >*/}
                                                    {/*            <option value="">Select</option>*/}
                                                    {/*            {showlistField && showlistField.map((opt, index) =>*/}
                                                    {/*                <option value={opt}*/}
                                                    {/*                        key={"opt-" + index}>{opt.label}</option>*/}
                                                    {/*            )}*/}
                                                    {/*        </select>*/}
                                                    {/*    </div>*/}

                                                    {/*</div>*/}
                                                </div>

                                            </div>
                                            }
                                            {(this.state.subPan === "permissions-tab" && settings.data.length !== 0) ?
                                                <div className="tab-pane fade show active" id="v-pills-home"
                                                     role="tabpanel"
                                                     aria-labelledby="v-pills-home-tab">

                                                    <div className="settings-loungue-header">
                                                        <div className="tab-wrap-block full-width-tab">
                                                            <div className="tab-wrap-block-left">
                                                                <h5>Share Users</h5>
                                                                <p>Click the + icon to select users and set the
                                                                    permissions</p>
                                                            </div>
                                                            <div className="tab-wrap-block-right">
                                                                <button className="general-btn  focus-resolve"
                                                                        id="savedata_viewpermission"
                                                                        onClick={this.savePermission}>Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="set-container user-settings-cont permission-tbl">
                                                        <div className="next-stage-selector">
                                                            <div className="scrollable">
                                                                <div className="form-table" style={{border: '0px'}}>
                                                                    <table className="table set-permission">
                                                                        <tbody>
                                                                        {settings.data.map((list, index) =>
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div className="team-perform-left">
                                                                                        <div
                                                                                            className="team-perform-thumb">
                                                                                            <img
                                                                                                src={USRIMG_PATH + list.img_name}
                                                                                                alt="User Image"/>
                                                                                        </div>
                                                                                        <div
                                                                                            className="team-perform-det">
                                                                                            <h6>{list.fullname}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">All
                                                                                            Data
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="alldata"
                                                                                                   id="alldata"
                                                                                                   value={list.alldata}
                                                                                                   checked={list.alldata}
                                                                                                   onClick={(e) => this.permission(e, 'alldata', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Add
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="addedit"
                                                                                                   id="addedit"
                                                                                                   value={list.addedit}
                                                                                                   checked={list.addedit}
                                                                                                   onClick={(e) => this.permission(e, 'addedit', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div
                                                                                        className="inline-permission-option">
                                                                                        <div
                                                                                            className="select-chk-block"
                                                                                            style={{display: 'block'}}>
                                                                                            <label
                                                                                                className="control control-checkbox">Edit
                                                                                                <input type="checkbox"
                                                                                                       className="inp-chk"
                                                                                                       name="edit"
                                                                                                       id="edit"
                                                                                                       value={list.edit}
                                                                                                       checked={list.edit}
                                                                                                       onClick={(e) => this.permission(e, 'edit', index)}/>
                                                                                                <div
                                                                                                    className="control-indicator"></div>
                                                                                            </label>
                                                                                        </div>

                                                                                        {list.edit &&
                                                                                        <button
                                                                                            onClick={(e) => this.editPermission(e, 'edit', list.id)}
                                                                                            class="inline-permission-edit">click</button>
                                                                                        }
                                                                                    </div>
                                                                                    {this.state.editPermissionStatus &&
                                                                                    <SimpleModal
                                                                                        Close={(e) => this.editPermissionClose(e)}
                                                                                        title={`Edit Option`}

                                                                                        modalType={'custom-date-range'}
                                                                                    >
                                                                                        <div
                                                                                            className="more-option-box">
                                                                                            <>
                                                                                                <div
                                                                                                    className="select-chk-block"
                                                                                                    style={{display: 'block'}}>
                                                                                                    <label
                                                                                                        className="control control-checkbox">Own
                                                                                                        Records
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="inp-chk"
                                                                                                            name="Ownrecords"
                                                                                                            id="Ownrecords"
                                                                                                            value={this.state.Ownrecords}
                                                                                                            checked={this.state.Ownrecords}
                                                                                                            onClick={(e) => this.editOption(e, 'Ownrecords')}/>
                                                                                                        <div
                                                                                                            className="control-indicator"></div>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="select-chk-block"
                                                                                                    style={{display: 'block'}}>
                                                                                                    <label
                                                                                                        className="control control-checkbox">Creator
                                                                                                        Groups
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="inp-chk"
                                                                                                            name="Creatorgroups"
                                                                                                            id="Creatorgroups"
                                                                                                            value={this.state.Creatorgroups}
                                                                                                            checked={this.state.Creatorgroups}
                                                                                                            onClick={(e) => this.editOption(e, 'Creatorgroups')}/>
                                                                                                        <div
                                                                                                            className="control-indicator"></div>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="select-chk-block"
                                                                                                    style={{display: 'block'}}>
                                                                                                    <label
                                                                                                        className="control control-checkbox">Assigned
                                                                                                        Records
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="inp-chk"
                                                                                                            name="Assignedrecords"
                                                                                                            id="Assignedrecords"
                                                                                                            value={this.state.Assignedrecords}
                                                                                                            checked={this.state.Assignedrecords}
                                                                                                            onClick={(e) => this.editOption(e, 'Assignedrecords')}/>
                                                                                                        <div
                                                                                                            className="control-indicator"></div>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="select-chk-block"
                                                                                                    style={{display: 'block'}}>
                                                                                                    <label
                                                                                                        className="control control-checkbox">All
                                                                                                        Records
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            className="inp-chk"
                                                                                                            name="Allrecords"
                                                                                                            id="Allrecords"
                                                                                                            value={this.state.Allrecords}
                                                                                                            checked={this.state.Allrecords}
                                                                                                            onClick={(e) => this.editOption(e, 'Allrecords')}/>
                                                                                                        <div
                                                                                                            className="control-indicator"></div>
                                                                                                    </label>
                                                                                                </div>
                                                                                                {/*<div className="select-chk-block" style={{display: 'block'}}>
                                                                                            <label className="control control-checkbox">Creator Reporting Person
                                                                                                <input type="checkbox" className="inp-chk" name="Reportingperson" id="Reportingperson" value={this.state.Reportingperson} checked={this.state.Reportingperson}  onClick={(e)=>this.editOption(e,'Reportingperson')} />
                                                                                                <div className="control-indicator"></div>
                                                                                            </label>
                                                                                        </div>
*/}
                                                                                            </>
                                                                                        </div>
                                                                                    </SimpleModal>
                                                                                    }

                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Restricted
                                                                                            View
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="import"
                                                                                                   id="import"
                                                                                                   value={list.restricted}
                                                                                                   checked={list.restricted}
                                                                                                   onClick={(e) => this.permission(e, 'restricted', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Import
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="import"
                                                                                                   id="import"
                                                                                                   value={list.import}
                                                                                                   checked={list.import}
                                                                                                   onClick={(e) => this.permission(e, 'import', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Export
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="export"
                                                                                                   id="export"
                                                                                                   value={list.export}
                                                                                                   checked={list.export}
                                                                                                   onClick={(e) => this.permission(e, 'export', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Assign
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="assign"
                                                                                                   id="assign"
                                                                                                   value={list.assign}
                                                                                                   checked={list.assign}
                                                                                                   onClick={(e) => this.permission(e, 'assign', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Rule
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="rule" id="rule"
                                                                                                   value={list.rule}
                                                                                                   checked={list.rule}
                                                                                                   onClick={(e) => this.permission(e, 'rule', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Schedule
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="schedule"
                                                                                                   id="schedule"
                                                                                                   value={list.schedule}
                                                                                                   checked={list.schedule}
                                                                                                   onClick={(e) => this.permission(e, 'schedule', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">API
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="api" id="api"
                                                                                                   value={list.api}
                                                                                                   checked={list.api}
                                                                                                   onClick={(e) => this.permission(e, 'api', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Delete
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="delete"
                                                                                                   id="delete"
                                                                                                   value={list.delete}
                                                                                                   checked={list.delete}
                                                                                                   onClick={(e) => this.permission(e, 'delete', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="select-chk-block"
                                                                                         style={{display: 'block'}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Collaborator
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   name="collabrator"
                                                                                                   id="collabrator"
                                                                                                   value={list.collabrator}
                                                                                                   checked={list.collabrator}
                                                                                                   onClick={(e) => this.permission(e, 'collabrator', index)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                {roleSettings.length !== 0 &&
                                                                                <td>
                                                                                    <div className={`select-box `}>
                                                                                        <select name={`role`}
                                                                                                onChange={(e) => this.permission(e, 'role', index)}>
                                                                                            <option
                                                                                                value="">{list.role !== '' ? this.roleFinder(list.role) : 'Select Role'}</option>
                                                                                            {roleSettings.map((item, indx) =>
                                                                                                <React.Fragment>
                                                                                                    {item.id !== list.role &&
                                                                                                    <option
                                                                                                        value={item.id}>{item.role}</option>
                                                                                                    }
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                }
                                                                            </tr>
                                                                        )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <NewUser
                                                        showFormModal={(e) => this.AddnewhandleAction(e, 'share', 'AddNew')}
                                                    />
                                                </div> :
                                                (this.state.subPan === "permissions-tab" && settings.data.length === 0) ?
                                                    <div className='absolute-holder'>
                                                        <NoData
                                                            msg={`No Data linked to this collection`}
                                                        />
                                                    </div> : ''
                                            }
                                            {this.state.subPan === "linkpermission-tab" &&
                                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                                 aria-labelledby="v-pills-home-tab">

                                                <div className="settings-loungue-header">
                                                    <div className="tab-wrap-block full-width-tab">
                                                        <div className="tab-wrap-block-left">
                                                            <h5>Entity Permissions</h5>
                                                            <p>Following are the entities connected to the collection.
                                                                Tick mark
                                                                if the entity level data access permissions need to be
                                                                inherited
                                                                here.</p>
                                                        </div>
                                                        <div className="tab-wrap-block-right">
                                                            {showlinkingpermission.length !== 0 &&
                                                            <button className="general-btn focus-resolve"
                                                                    id="savedata_viewpermission"
                                                                    onClick={this.linkingPermission}>Update
                                                            </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="set-container">

                                                    {this.state.entity_perm === true &&
                                                    <p>Succesfully updated..!</p>
                                                    }

                                                    <div className="next-stage-selector block-line">
                                                        <div className="select-box-container">
                                                            {showlinkingpermission.map((title, index) =>
                                                                <div className="select-chk-block"
                                                                     style={{"display": "block"}}>

                                                                    <label
                                                                        className="control control-checkbox">{title.label}
                                                                        <input type="checkbox" className="inp-chk"
                                                                               id="text_searchable"
                                                                               value={title.masterid}
                                                                               defaultChecked={collection.CollectionInfo.linked_formpermission.filter((item) => item.toString() === title.masterid.toString()).length !== 0}
                                                                               onClick={(e) => this.showlinkingPermission(e, title.masterid)}/>
                                                                        <div className="control-indicator"></div>
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            }
                                            {(this.state.subPan === "viewsettings-tab") &&
                                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                                 aria-labelledby="v-pills-home-tab">
                                                <div className="settings-loungue-header">
                                                    <div className="tab-wrap-block full-width-tab">
                                                        <div className="tab-wrap-block-left">
                                                            <h5>View Template Settings</h5>
                                                            <p>Select the viewing template of collection data</p>
                                                        </div>
                                                        <div className="tab-wrap-block-right">
                                                            <button className="general-btn  focus-resolve"
                                                                    id="savedata_viewpermission"
                                                                    onClick={this.saveTemplate}>Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="set-container">
                                                    <div className="template-tab">
                                                        <ul className="template-anchor">
                                                            <li>
                                                                <nav
                                                                    className={`template-anchor-click${this.state.tabView === "list" ? " active" : ""}`}
                                                                    onClick={(e) => this.onTabChange(e, 'list')}>Column
                                                                    Settings
                                                                </nav>
                                                            </li>
                                                            <li>
                                                                <nav
                                                                    className={`template-anchor-click${this.state.tabView === "vertical" ? " active" : ""}`}
                                                                    onClick={(e) => this.onTabChange(e, 'vertical')}>Card1
                                                                </nav>
                                                            </li>
                                                            <li>
                                                                <nav
                                                                    className={`template-anchor-click${this.state.tabView === "horizontal" ? " active" : ""}`}
                                                                    onClick={(e) => this.onTabChange(e, 'horizontal')}>Card2
                                                                </nav>
                                                            </li>
                                                            <li>
                                                                <nav
                                                                    className={`template-anchor-click${this.state.tabView === "mini" ? " active" : ""}`}
                                                                    onClick={(e) => this.onTabChange(e, 'mini')}>Mini
                                                                    Card
                                                                </nav>
                                                            </li>

                                                            <li>
                                                                <nav
                                                                    className={`template-anchor-click${this.state.tabView === "map" ? " active" : ""}`}
                                                                    onClick={(e) => this.onTabChange(e, 'map')}>Map View
                                                                </nav>
                                                            </li>
                                                            <li>
                                                                <nav
                                                                    className={`template-anchor-click${this.state.tabView === "long1" ? " active" : ""}`}
                                                                    onClick={(e) => this.onTabChange(e, 'long1')}>Long
                                                                    Card1
                                                                </nav>
                                                            </li>
                                                            {/*<li>*/}
                                                            {/*    <nav*/}
                                                            {/*        className={`template-anchor-click${this.state.tabView === "kanban" ? " active" : ""}`}*/}
                                                            {/*        onClick={(e) => this.onTabChange(e, 'kanban')}>Kanban*/}
                                                            {/*    </nav>*/}
                                                            {/*</li>*/}
                                                        </ul>
                                                        <div className="template-content-block">
                                                            {this.state.tabView === "list" &&
                                                            <React.Fragment>
                                                                <div className="template-tab-header">
                                                                    <h5></h5>
                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox">Set
                                                                            as
                                                                            Default View
                                                                            <input type="radio" className="inp-chk"
                                                                                   id="text_searchable" name="dataview"
                                                                                // checked={default_template ==="vertical"}
                                                                                   defaultChecked={default_template === "list"}
                                                                                   onClick={() => this.defaultView('list')}
                                                                            />
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="template-body">
                                                                    <ResultTable
                                                                        label={this.props.showFormModal}
                                                                        collection={collection.CollectionData.slice(0, 5)}
                                                                        handleSelectAll={this.handleSelectAll}
                                                                        showPreviewFormModal={this.showPreviewFormModal}
                                                                        state={this.state}
                                                                        handleSelectRow={this.handleSelectRow}
                                                                        showAssignedUsers={this.showAssignedUsers}
                                                                        loadmoreCollectionData={this.loadmoreCollectionData}
                                                                        showFormModal={this.showFormModal}
                                                                        key={`result-table`}
                                                                        permissionsSelected={permissionsSelected}
                                                                        createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                                                        onStarClick={this.onStarClick}
                                                                        filterStarClick={this.filterStarClick}
                                                                        handleClicks={this.handleClicks}
                                                                        flags={collection.flaglist}
                                                                        flagList={this.flagList}
                                                                        shownlist={collection.shownlist}
                                                                        fieldview={collection.fieldview}
                                                                        CollectionInfo={collection.CollectionInfo}

                                                                    />

                                                                    <div className="multiple-box">
                                                                        <div className="multiple-box-wrap">
                                                                            <div
                                                                                className="checkbox-wrapper sub-action-wrap">
                                                                                <div className='multiple-box-header'>
                                                                                    <h5>Subaction Settings</h5>
                                                                                    <button
                                                                                        className="general-btn  focus-resolve"
                                                                                        id="savedata_viewpermission"
                                                                                        onClick={this.saveManageColumns}>Save
                                                                                    </button>
                                                                                </div>
                                                                                <div className="multiple-box-body">
                                                                                    <div className="select-chk-block"
                                                                                         style={{"display": "block"}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Enable
                                                                                            Notes
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   id="text_searchable"
                                                                                                   value={this.state.showNotesColumn}
                                                                                                   defaultChecked={this.state.showNotesColumn}
                                                                                                   onClick={(e) => this.showManageColumns(e, 'notes')}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="select-chk-block"
                                                                                         style={{"display": "block"}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Enable
                                                                                            Share
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   id="text_searchable"
                                                                                                   value={this.state.showShareColumn}
                                                                                                   defaultChecked={this.state.showShareColumn}
                                                                                                   onClick={(e) => this.showManageColumns(e, 'share')}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="select-chk-block"
                                                                                         style={{"display": "block"}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Enable
                                                                                            Timestamp
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   id="text_searchable"
                                                                                                   value={this.state.showCreateDateColumn}
                                                                                                   defaultChecked={this.state.showCreateDateColumn}
                                                                                                   onClick={(e) => this.showManageColumns(e, 'date')}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className="select-chk-block"
                                                                                         style={{"display": "block"}}>
                                                                                        <label
                                                                                            className="control control-checkbox">Enable
                                                                                            Calendar
                                                                                            <input type="checkbox"
                                                                                                   className="inp-chk"
                                                                                                   id="text_searchable"
                                                                                                   value={this.state.showCalendarColumn}
                                                                                                   defaultChecked={this.state.showCalendarColumn}
                                                                                                   onClick={(e) => this.showManageColumns(e, 'calendar')}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="select-box">
                                                                                        <select id={`dropOptions_cal`}
                                                                                                name={`dropOptions_cal`}
                                                                                                onChange={(e) => this.chooseCalendarField(e)}
                                                                                                value={calendarFields && calendarFields.length > 0 ? calendarFields[0] : ''}
                                                                                        >
                                                                                            <option value="">Select
                                                                                            </option>
                                                                                            {showlistField && showlistField.map((opt, index) =>
                                                                                                <option
                                                                                                    value={opt.masterid}
                                                                                                    key={"opt-" + index}>{opt.label}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <Suspense fallback={renderLoader()}>
                                                                        <FullwidthColumn
                                                                            label={this.props.showFormModal}
                                                                            collectionId={this.state.collectionId}
                                                                            collection={collection}
                                                                            hideFormModal={this.hideFormModal}
                                                                            Formtemplate={collection.CollectionInfo.formtemplate}
                                                                            showlinkingpermission={showlinkingpermission}
                                                                            showlinkingpermissionArray={showlinkingpermissionArray}
                                                                            showlinkingpermissionid={showlinkingpermissionid}
                                                                            type={`collection`}
                                                                        />
                                                                        </Suspense>
                                                                    </div>

                                                                </div>
                                                            </React.Fragment>
                                                            }
                                                            {this.state.tabView === "horizontal" &&
                                                            <React.Fragment>
                                                                <div className="template-tab-header">
                                                                    <h5>Map the Data</h5>
                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox">Set
                                                                            as
                                                                            Default View
                                                                            <input type="radio" className="inp-chk"
                                                                                   id="text_searchable" name="dataview"
                                                                                // checked={default_template ==="vertical"}
                                                                                   defaultChecked={default_template === "horizontal"}
                                                                                   onClick={() => this.defaultView('horizontal')}
                                                                            />
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="template-body">
                                                                    <div className="radio-container">
                                                                        <div className="radio-wrap">
                                                                            <div className="radio-block">
                                                                                <input type="radio"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={horizontalImage === true}
                                                                                       onClick={() => this.enableImage("horizontalImage", true)}/>
                                                                                <label>With Image</label>
                                                                            </div>
                                                                            <div className="radio-block">
                                                                                <input type="radio"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={horizontalImage === false}
                                                                                       onClick={() => this.enableImage("horizontalImage", false)}/>
                                                                                <label>Without Image</label>
                                                                            </div>


                                                                        </div>
                                                                        {!horizontalImage &&
                                                                        <div className="select-chk-block"
                                                                             style={{"display": "block"}}>
                                                                            <label className="control control-checkbox">With
                                                                                Map
                                                                                [latitude,longitudes]
                                                                                <input type="checkbox"
                                                                                       className="inp-chk"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={horizontalMapenabled}
                                                                                       onClick={() => this.enableImage("horizontalMapenabled", !horizontalMapenabled)}/>
                                                                                <div
                                                                                    className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                    <EditTemplate
                                                                        saveTempalete={this.saveTempalete}
                                                                        verticaltemplate={verticaltemplate}
                                                                        horizontaltemplate={horizontaltemplate}
                                                                        minitemplate={minitemplate}
                                                                        long1template={long1template}
                                                                        horizontalImage={horizontalImage}
                                                                        horizontalMapenabled={horizontalMapenabled}
                                                                        verticalMapenabled={verticalMapenabled}
                                                                        miniMapenabled={miniMapenabled}
                                                                        template={'horizontal'}
                                                                        default_template={default_template}
                                                                        label={this.props.showFormModal}
                                                                        collection={collection}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                            }

                                                            {this.state.tabView === "vertical" &&
                                                            <React.Fragment>
                                                                <div className="template-tab-header">
                                                                    <h5>Map the Data</h5>
                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox">Set
                                                                            as
                                                                            Default View
                                                                            <input type="radio" className="inp-chk"
                                                                                   id="text_searchable" name="dataview"
                                                                                // checked={default_template ==="vertical"}
                                                                                   defaultChecked={default_template === "vertical"}
                                                                                   onClick={() => this.defaultView('vertical')}
                                                                            />
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="template-body">
                                                                    <div className="radio-container">
                                                                        <div className="radio-wrap">
                                                                            <div className="radio-block">
                                                                                <input type="radio"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={verticalImage === true}
                                                                                       onClick={() => this.enableImage("verticalImage", true)}/>
                                                                                <label>With Image/video</label>

                                                                            </div>
                                                                            <div className="radio-block">
                                                                                <input type="radio"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={verticalImage === false}
                                                                                       onClick={() => this.enableImage("verticalImage", false)}/>
                                                                                <label>Without Image/video</label>
                                                                            </div>
                                                                        </div>
                                                                        {!verticalImage &&
                                                                        <div className="select-chk-block"
                                                                             style={{"display": "block"}}>
                                                                            <label className="control control-checkbox">With
                                                                                Map
                                                                                [latitude,longitudes]
                                                                                <input type="checkbox"
                                                                                       className="inp-chk"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={verticalMapenabled}
                                                                                       onClick={() => this.enableImage("verticalMapenabled", !verticalMapenabled)}/>
                                                                                <div
                                                                                    className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                        }

                                                                        {/*{verticalImage &&*/}
                                                                        {/*    <>*/}
                                                                        {/*   <label>eg:https://www.youtube.com/embed/rgZfmWXTqas</label>*/}
                                                                        {/*    <label>eg:https://player.vimeo.com/video/10679287</label>*/}
                                                                        {/*   </>*/}
                                                                        {/*}*/}


                                                                    </div>
                                                                    <EditTemplate
                                                                        saveTempalete={this.saveTempalete}
                                                                        verticaltemplate={verticaltemplate}
                                                                        horizontaltemplate={horizontaltemplate}
                                                                        minitemplate={minitemplate}
                                                                        long1template={long1template}
                                                                        horizontalMapenabled={horizontalMapenabled}
                                                                        verticalMapenabled={verticalMapenabled}
                                                                        miniMapenabled={miniMapenabled}
                                                                        verticalImage={verticalImage}
                                                                        template={'vertical'}
                                                                        default_template={default_template}
                                                                        label={this.props.showFormModal}
                                                                        collection={collection}
                                                                    />


                                                                </div>
                                                            </React.Fragment>
                                                            }

                                                            {this.state.tabView === "mini" &&
                                                            <React.Fragment>
                                                                <div className="template-tab-header">
                                                                    <h5>Map the Data</h5>
                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox">Set
                                                                            as
                                                                            Default View
                                                                            <input type="radio" className="inp-chk"
                                                                                   id="text_searchable" name="dataview"
                                                                                // checked={default_template ==="vertical"}
                                                                                   defaultChecked={default_template === "mini"}
                                                                                   onClick={() => this.defaultView('mini')}
                                                                            />
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="template-body">
                                                                    <div className="radio-container">
                                                                        <div className="radio-wrap">
                                                                            <div className="radio-block">
                                                                                <input type="radio"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={miniImage === true}
                                                                                       onClick={() => this.enableImage("miniImage", true)}/>
                                                                                <label>With Image</label>

                                                                            </div>
                                                                            <div className="radio-block">
                                                                                <input type="radio"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={miniImage === false}
                                                                                       onClick={() => this.enableImage("miniImage", false)}/>
                                                                                <label>Without Image</label>
                                                                            </div>
                                                                        </div>
                                                                        {!miniImage &&
                                                                        <div className="select-chk-block"
                                                                             style={{"display": "block"}}>
                                                                            <label className="control control-checkbox">With
                                                                                Map
                                                                                [latitude,longitudes]
                                                                                <input type="checkbox"
                                                                                       className="inp-chk"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={miniMapenabled}
                                                                                       onClick={() => this.enableImage("miniMapenabled", !miniMapenabled)}/>
                                                                                <div
                                                                                    className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                        }

                                                                        {/*{verticalImage &&*/}
                                                                        {/*    <>*/}
                                                                        {/*   <label>eg:https://www.youtube.com/embed/rgZfmWXTqas</label>*/}
                                                                        {/*    <label>eg:https://player.vimeo.com/video/10679287</label>*/}
                                                                        {/*   </>*/}
                                                                        {/*}*/}


                                                                    </div>
                                                                    <EditTemplate
                                                                        saveTempalete={this.saveTempalete}
                                                                        verticaltemplate={verticaltemplate}
                                                                        minitemplate={minitemplate}
                                                                        long1template={long1template}
                                                                        horizontaltemplate={horizontaltemplate}
                                                                        horizontalMapenabled={horizontalMapenabled}
                                                                        verticalMapenabled={verticalMapenabled}
                                                                        miniMapenabled={miniMapenabled}
                                                                        verticalImage={verticalImage}
                                                                        miniImage={miniImage}
                                                                        long1Image={long1Image}
                                                                        template={'mini'}
                                                                        default_template={default_template}
                                                                        label={this.props.showFormModal}
                                                                        collection={collection}
                                                                    />


                                                                </div>
                                                            </React.Fragment>
                                                            }

                                                            {this.state.tabView === "map" &&
                                                            <React.Fragment>
                                                                <div className="template-tab-header">
                                                                    <h5>Map the Data</h5>
                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox">Set
                                                                            as
                                                                            Default View
                                                                            <input type="radio" className="inp-chk"
                                                                                   id="text_searchable" name="dataview"
                                                                                // checked={default_template ==="vertical"}
                                                                                   defaultChecked={default_template === "map"}
                                                                                   onClick={() => this.defaultView('map')}
                                                                            />
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="template-body">
                                                                    <div className="radio-container">
                                                                        <div className="radio-wrap">
                                                                        </div>

                                                                    </div>
                                                                    <EditMapTemplate
                                                                        saveTempalete={this.saveTempalete}
                                                                        maptemplate={maptemplate}
                                                                        template={'map'}
                                                                        default_template={default_template}
                                                                        label={this.props.showFormModal}
                                                                        collection={collection}

                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                            }

                                                            {this.state.tabView === "long1" &&
                                                            <React.Fragment>
                                                                <div className="template-tab-header">
                                                                    <h5>Map the Data</h5>
                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox">Set
                                                                            as
                                                                            Default View
                                                                            <input type="radio" className="inp-chk"
                                                                                   id="text_searchable" name="dataview"
                                                                                // checked={default_template ==="vertical"}
                                                                                   defaultChecked={default_template === "long1"}
                                                                                   onClick={() => this.defaultView('long1')}
                                                                            />
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="template-body">
                                                                    <div className="radio-container">
                                                                        <div className="radio-wrap">
                                                                            <div className="radio-block">
                                                                                <input type="radio"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={long1Image === true}
                                                                                       onClick={() => this.enableImage("long1Image", true)}/>
                                                                                <label>With Image {long1Image}</label>

                                                                            </div>
                                                                            <div className="radio-block">
                                                                                <input type="radio"
                                                                                       name="stagesubmissiontype"
                                                                                       defaultChecked={long1Image === false}
                                                                                       onClick={() => this.enableImage("long1Image", false)}/>
                                                                                <label>Without Image</label>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                    <EditTemplate
                                                                        saveTempalete={this.saveTempalete}
                                                                        verticaltemplate={verticaltemplate}
                                                                        minitemplate={minitemplate}
                                                                        long1template={long1template}
                                                                        horizontaltemplate={horizontaltemplate}
                                                                        horizontalMapenabled={horizontalMapenabled}
                                                                        verticalMapenabled={verticalMapenabled}
                                                                        miniMapenabled={miniMapenabled}
                                                                        verticalImage={verticalImage}
                                                                        miniImage={miniImage}
                                                                        long1Image={long1Image}
                                                                        template={'long1'} //mini,long1
                                                                        default_template={default_template}
                                                                        label={this.props.showFormModal}
                                                                        collection={collection}
                                                                    />


                                                                </div>
                                                            </React.Fragment>
                                                            }
                                                            {/*{this.state.tabView === "kanban" &&*/}
                                                            {/*    <React.Fragment>*/}
                                                            {/*        <div className="template-tab-header">*/}
                                                            {/*            <h5>Map the Data</h5>*/}
                                                            {/*            <div className="select-chk-block"*/}
                                                            {/*                 style={{"display": "block"}}>*/}
                                                            {/*                <label className="control control-checkbox">Set as*/}
                                                            {/*                    Default View*/}
                                                            {/*                    <input type="radio" className="inp-chk"*/}
                                                            {/*                           id="text_searchable" name="dataview"*/}
                                                            {/*                           defaultChecked={default_template === "kanban"}*/}
                                                            {/*                           onClick={() => this.defaultView('kanban')}*/}
                                                            {/*                    />*/}
                                                            {/*                    <div className="control-indicator"></div>*/}
                                                            {/*                </label>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*        <div className="template-body">*/}
                                                            {/*            <EditTemplate*/}
                                                            {/*                saveTempalete={this.saveTempalete}*/}
                                                            {/*                verticaltemplate={verticaltemplate}*/}
                                                            {/*                minitemplate={minitemplate}*/}
                                                            {/*                long1template={long1template}*/}
                                                            {/*                kanbanTemplate={kanbantemplate}*/}
                                                            {/*                horizontaltemplate={horizontaltemplate}*/}
                                                            {/*                horizontalMapenabled={horizontalMapenabled}*/}
                                                            {/*                verticalMapenabled={verticalMapenabled}*/}
                                                            {/*                miniMapenabled={miniMapenabled}*/}
                                                            {/*                verticalImage={verticalImage}*/}
                                                            {/*                miniImage={miniImage}*/}
                                                            {/*                long1Image={long1Image}*/}
                                                            {/*                template={'kanban'} //mini,long1*/}
                                                            {/*                default_template={default_template}*/}
                                                            {/*                label={this.props.showFormModal}*/}
                                                            {/*                collection={collection}*/}
                                                            {/*            />*/}


                                                            {/*        </div>*/}
                                                            {/*    </React.Fragment>*/}
                                                            {/*}*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                            {(this.state.subPan === "propagation-tab") &&
                                            <Suspense fallback={renderLoader()}>
                                                <PropagationSettings
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    propagationData={propagationData}
                                                    type={`collection`}
                                                />
                                            </Suspense>
                                            }
                                            {(this.state.subPan === "filter-setting-tab") &&
                                            <Suspense fallback={renderLoader()}>
                                                <FilterSettings
                                                    type={`collection`}
                                                    loader={this.props.loader}
                                                />
                                            </Suspense>
                                            }
                                            {(this.state.subPan === "template-setting-tab") &&
                                            <Suspense fallback={renderLoader()}>
                                                <TemplateSettings
                                                    formtitle={formtitle}
                                                    type={`collection`}
                                                    loader={this.props.loader}
                                                    label={this.props.showFormModal}
                                                    customtemplate={customtemplate_other}
                                                    previewTemplate={this.previewTemplate}
                                                    mobiletemplate={mobiletemplate_other}
                                                />
                                            </Suspense>
                                            }

                                            {(this.state.subPan === "pjt-setting-tab") &&
                                            <Suspense fallback={renderLoader()}>
                                                <ProjectcollectionSettings
                                                    type={`collection`}
                                                    loader={this.props.loader}
                                                    label={this.props.showFormModal}
                                                    // customtemplate={customtemplate}
                                                    previewTemplate={this.previewTemplate}
                                                    seletedProjects={this.props.GetCollectionconfig.getcollectionconfig.projects}
                                                />
                                            </Suspense>
                                            }


                                            {(this.state.subPan === "task-tab") &&
                                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                                 aria-labelledby="v-pills-home-tab">
                                                <TaskAssignContainer
                                                    label={this.props.showFormModal}
                                                />
                                            </div>
                                            }

                                            {(this.state.subPan === "systementity-settings") &&
                                            <Suspense fallback={renderLoader()}>
                                                <SystemEntitySettings
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    sysEntitySetting={sysEntitySetting}
                                                    type={`collection`}
                                                    sourceType={`Source Collection`}
                                                />
                                            </Suspense>
                                            }
                                            {(this.state.subPan === "calendar-settings") &&
                                            <Suspense fallback={renderLoader()}>
                                                <CalendarSettings
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    collection={collection}
                                                    maptemplate={maptemplate}
                                                    collData={collData}
                                                />
                                            </Suspense>
                                            }
                                            {(this.state.subPan === "parent-settings") &&
                                            <Suspense fallback={renderLoader()}>
                                                <ParentSettings
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    collection={collection}
                                                    maptemplate={maptemplate}
                                                    collData={collData}
                                                />
                                            </Suspense>
                                            }
                                            {(this.state.subPan === "summarypanel-settings") &&
                                            <Suspense fallback={renderLoader()}>
                                                <SummaryPanelSettings
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    collection={collection}
                                                    maptemplate={maptemplate}
                                                    collData={collData}
                                                    summary={summary}
                                                />
                                            </Suspense>
                                            }
                                            {(this.state.subPan === "display-settings") &&
                                            <Suspense fallback={renderLoader()}>
                                                <DisplaySettings
                                                    label={this.props.showFormModal}
                                                    CollectionInfo={this.props.collectionData.CollectionInfo}

                                                />
                                            </Suspense>
                                            }
                                            {(this.state.subPan === "print-settings") &&
                                            <Suspense fallback={renderLoader()}>
                                                <PrintSettings
                                                    collectionId={this.state.collectionId}
                                                    print={print}
                                                />
                                            </Suspense>
                                            }
                                            {(this.state.subPan === "manage-flags") &&
                                            <Suspense fallback={renderLoader()}>
                                                {/*<FlagSettings*/}
                                                {/*    label={this.props.showFormModal}*/}
                                                {/*    collectionId ={this.state.collectionId}*/}
                                                {/*    collection={collection}*/}
                                                {/*    maptemplate={maptemplate}*/}
                                                {/*    collData={collData}*/}
                                                {/*    flagopen={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.flagopen}*/}
                                                {/*/>*/}
                                                <FlagSettingsNew
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    collection={collection}
                                                    maptemplate={maptemplate}
                                                    collData={collData}
                                                    flagopen={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.flagopen}
                                                />
                                            </Suspense>
                                            }

                                            {(this.state.subPan === "filterd-columns") &&
                                            <Suspense fallback={renderLoader()}>
                                                <FilteredViewSettings
                                                    facet={facet}
                                                    showdatefilter={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.showdatefilter}
                                                    handlefilterSearch={this.handlefilterSearch}
                                                    FilterLabel={this.state.FilterLabel}
                                                    totalCount={collection.totalchilds}
                                                    filterData={collectionFilter}
                                                    dateWise={this.dateWise}
                                                    daysFilter={this.daysFilter}
                                                    dateRange={this.dateRange}
                                                    numberFilter={this.numberFilter}
                                                    rangeFilter={this.rangeFilter}
                                                    filter={this.state.filter}

                                                    collection={collection}
                                                    collectionId={this.state.collectionId}/>
                                            </Suspense>
                                            }

                                            {(this.state.subPan === "quickform-tab") &&
                                            <Suspense fallback={renderLoader()}>
                                                <QuickFormSettings
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    collection={collection}
                                                    hideFormModal={this.hideFormModal}
                                                    Formtemplate={collection.CollectionInfo.formtemplate}
                                                    showlinkingpermission={showlinkingpermission}
                                                    showlinkingpermissionArray={showlinkingpermissionArray}
                                                    showlinkingpermissionid={showlinkingpermissionid}
                                                    roleSettings={roleSettings}
                                                    type={`collection`}
                                                    showQuickform={this.state.showQuickform}
                                                />
                                            </Suspense>
                                            }

                                            {(this.state.subPan === "viewsettings-tab") &&
                                            <Suspense fallback={renderLoader()}>
                                                <FullwidthColumn
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    collection={collection}
                                                    hideFormModal={this.hideFormModal}
                                                    Formtemplate={collection.CollectionInfo.formtemplate}
                                                    showlinkingpermission={showlinkingpermission}
                                                    showlinkingpermissionArray={showlinkingpermissionArray}
                                                    showlinkingpermissionid={showlinkingpermissionid}
                                                    type={`collection`}
                                                />
                                            </Suspense>
                                            }


                                            {(this.state.subPan === "formbuttons-tab") &&
                                            <Suspense fallback={renderLoader()}>
                                                <FormButtonsSettings
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    collection={collection}
                                                    hideFormModal={this.hideFormModal}
                                                    Formtemplate={collection.CollectionInfo.formtemplate}
                                                    showlinkingpermission={showlinkingpermission}
                                                    showlinkingpermissionArray={showlinkingpermissionArray}
                                                    showlinkingpermissionid={showlinkingpermissionid}
                                                />
                                            </Suspense>
                                            }
                                            {this.state.subPan === "manage-roles" &&
                                            <Suspense fallback={renderLoader()}>
                                                <RoleWiseSettings
                                                    label={this.props.showFormModal}
                                                    collectionId={this.state.collectionId}
                                                    collection={collection}
                                                    hideFormModal={this.hideFormModal}
                                                    Formtemplate={collection.CollectionInfo.formtemplate}
                                                    showlinkingpermission={showlinkingpermission}
                                                    showlinkingpermissionArray={showlinkingpermissionArray}
                                                    showlinkingpermissionid={showlinkingpermissionid}
                                                    type={`collection`}
                                                />
                                            </Suspense>
                                            }
                                            {this.state.subPan === "pay-tab" &&
                                                    <Suspense fallback={renderLoader()}>
                                            <PaymentSettings
                                                label={this.props.showFormModal}
                                                collectionId={this.state.collectionId}
                                                type={`collection`}
                                                paymentData={mobiletemplate_settings}
                                            />
                                                    </Suspense>
                                            }
                                            {this.state.subPan === "rightSidePanel-tab" &&
                                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                                 aria-labelledby="v-pills-home-tab">

                                                <div className="settings-loungue-header">
                                                    <div className="tab-wrap-block full-width-tab">
                                                        <div className="tab-wrap-block-left">
                                                            <h5>Right Side Panel Settings</h5>
                                                        </div>
                                                        <div className="tab-wrap-block-right">
                                                            <button className="general-btn  focus-resolve"
                                                                    id="savedata_viewpermission"
                                                                    onClick={this.saveRightPanel}>Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="set-container">
                                                    <div className="next-stage-selector block-line">
                                                        <div className={'row'}>
                                                            <div class="col-lg-12">
                                                                <div className="each-inp-box">
                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox">Disable
                                                                            Workflow Panel
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   id="text_searchable"
                                                                                   value={this.state.disableWorkflowPanel !== '' ? this.state.disableWorkflowPanel : collection.CollectionInfo.showwf_info}
                                                                                   defaultChecked={collection.CollectionInfo.showwf_info}
                                                                                   onClick={(e) => this.workflowPanel(e)}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {(this.state.disableWorkflowPanel) &&
                                                            <div class="col-lg-12">
                                                                <div class="each-inp-box flex-indent">
                                                                    <label>Do you want to show chat window as
                                                                        default?</label>
                                                                    <button type="button"
                                                                            className={`btn-toggle ${this.state.showChat ? 'active' : ''}`}
                                                                            data-toggle="button" aria-pressed="false"
                                                                            onClick={this.handleDafultShowChatSelect}>
                                                                        <div className="handle"></div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            }
                                                            <div className="col-lg-12">
                                                                <div className="each-inp-box">
                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox">Send
                                                                            Email
                                                                            Notification
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   id="text_searchable"
                                                                                   value={this.state.mailNotification !== '' ? this.state.mailNotification : collection.CollectionInfo.mailnotification}
                                                                                   defaultChecked={collection.CollectionInfo.mailnotification}
                                                                                   onClick={(e) => this.enableMailNotification(e)}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="each-inp-box">
                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox">Send
                                                                            Chat
                                                                            Notification
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   id="text_searchable"
                                                                                   value={this.state.chatNotification !== '' ? this.state.chatNotification : collection.CollectionInfo.chatnotification}
                                                                                   defaultChecked={collection.CollectionInfo.chatnotification}
                                                                                   onClick={(e) => this.enableChatNotification(e)}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>

                        <React.Fragment>
                            {this.state.dashStat &&
                            <CollectionDashBoard
                                dashboard={dashboard}
                                handleDashboard={this.msgOpn}
                            />
                            }


                            <ProjectChat
                                taskid={getUrlSegment(1) === 'project' ? atob(getUrlSegment(5)) : atob(getUrlSegment(3))}
                                inviteUsers={false}
                                type={"collection"}
                                msgOpn={this.msgOpn}
                                msgCnt={this.msgCnt}
                                openSta={this.state.msgOpn}
                                sound={true}
                                toaster={true}
                                //  assigneesData={assigneesData}
                            />


                            {this.state.showPreviewForm &&
                            <ShowFormPreview
                                formtitle={formtitle}
                                formName={this.state.formName}
                                collectionId={this.state.collectionid}
                                previewForm={this.previewForm}
                                hidePreviewForm={this.hidePreviewForm}
                                state={this.state}
                                slideShow={this.showPreviewFormModal}
                                previewSource={`collection`}
                                customtemplate={customtemplate}
                                mobiletemplate={mobiletemplate}
                                collectionRecord={collectionRecord}
                                projectList={this.state.projectList}
                                tagList={this.state.tagList}
                                listtags={this.props.listtags.tags}
                                taggedProject={this.props.ProjectInfoDet.ProjectInfo.projectinfo}
                                projectId={this.state.projectId}
                                createdfrom={this.state.projectId !== '' ? 'prjcol' : 'collection'}
                                removeSelectedRecord={this.removeSelectedRecordPreview}
                                recordId={this.state.recordshowid}
                                recordVersionid={this.state.recordVersionid}
                                changeNotes={this.changeNotes}
                                value={this.props.listAnnotation}
                                print={print}


                            />
                            }
                            {this.state.showFormModal &&
                            <ShowFormModal
                                formName={this.state.formName}
                                formDesc={this.state.formDesc}
                                collectionId={this.state.collectionId}
                                referenceId={getUrlSegment(1) === 'project' ? atob(getUrlSegment(5)) : this.state.collectionId}
                                hideFormModal={this.hideFormModal}
                                editId={this.state.collecteddataid}
                                Formtemplate={collection.CollectionInfo.formtemplate}
                                showlinkingpermission={showlinkingpermission}
                                showlinkingpermissionArray={showlinkingpermissionArray}
                                showlinkingpermissionid={showlinkingpermissionid}
                                reference={getUrlSegment(1) === 'project' ? 'project' : ''}
                            />
                            }
                            {this.state.showDeleteConfirm &&
                            <AlertModal id="take-pool"
                                        title="Are you sure you want to delete?"
                                        OK_button_text="Yes, Delete It"
                                        OK_button_style="btn-danger"
                                        OK_action={this.handleDelete}
                                        showModal={this.state.showDeleteConfirm}
                                        CANCEL_action={this.showDeleteConfirm}
                            />
                            }

                            {this.state.showDuplicateConfirm &&
                            <AlertModal id="take-pool"
                                        title="Are you sure you want to duplicate the record?"
                                        OK_button_text="Yes"
                                        OK_button_style="btn-danger"
                                        OK_action={this.handleDuplicate}
                                        showModal={this.state.showDuplicateConfirm}
                                        CANCEL_action={this.showDuplicateConfirm}
                            />
                            }


                            {this.state.action === 'deleteall' &&

                            <AlertModal id="delete-all-rec"
                                        title="Are you sure you want to delete?"
                                        OK_button_text="Yes, Delete It"
                                        OK_button_style="btn-danger"
                                        OK_action={this.deleteAllRecords}
                                        showModal={true}
                                        CANCEL_action={() => this.AddnewhandleAction("", "", "")}
                            />
                            }

                            {this.state.validateFlagModal &&
                            <AlertModal id="alertemodal"
                                        title="Please save your flag changes"
                                        OK_button_text="OK"
                                        OK_action={() => this.setState({validateFlagModal: false})}
                                        showModal={true}
                                        CANCEL_action={this.FlagPanelClose}
                                        CANCEL_button_text="Close Panel"
                            />
                            }

                            {this.state.showPublishStatus &&
                            <AlertModal id="un-publish-warning"
                                        title="Please publish the form to proceed"
                                        OK_button_text="OK"
                                        OK_button_style="btn-danger"
                                        OK_action={this.hidePublishWarning}
                                        showModal={this.state.showPublishStatus}
                                        CANCEL_button="hide"
                            />
                            }


                            {this.state.action === 'assign' &&
                            <Modal title={'Create New Task'} Close={this.modalOpen.bind(this)}>
                                <AddToDoTask
                                    selectedCollections={this.state.selectedCollection}
                                    modalOpen={this.modalOpen}
                                />

                            </Modal>
                            }

                            {this.state.action === 'share' &&
                            <SimpleModal
                                Close={this.modalOpen}
                                title={`Share Form`}
                                haveFooter={1}
                                OKLabel="Save"
                                OKAction={this.saveAssign}
                                cancelLabel="Cancel"
                                cancelAction={this.modalOpen}
                            >
                                {this.props.loader.loaderType === 'share-collection' && this.props.loader.status === true &&
                                <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                                }
                                <div className="task-tg">
                                    <p className="remove-txt-gp">Assign to Individual, Team, Organization, etc (Ignore
                                        this for
                                        self tasks)</p>
                                    <div className="task-inp-container">

                                        <AutoComplete
                                            eleClass=''
                                            fieldTitle='To'
                                            sourceDataList={this.props.listUsers.Users}
                                            searchField='fullname'
                                            showIcon={false}
                                            hideTitle={true}
                                            hideMainLabel={false}
                                            showSubLabel={true}
                                            labelFields='fullname'
                                            valueField='user_id'
                                            subLabelField='designation'
                                            showProfile={true}
                                            reference='selectedToUsers'
                                            selectedItems={this.state.selectedToUsers}
                                            resultDataList={this.resultDataList}
                                            id="to_users"
                                            actionMethod={this.getUsers}
                                        />
                                    </div>

                                </div>

                            </SimpleModal>
                            }
                            {this.state.datalevelSelect === true &&
                            <SimpleModal
                                Close={this.modalOpen}
                                title={`Share Users`}
                                haveFooter={1}
                                OKLabel="Save"
                                OKAction={this.savedatalevelUsers}
                                cancelLabel="Cancel"
                                cancelAction={this.modalOpen}
                            >
                                {this.props.loader.loaderType === 'share-collection' && this.props.loader.status === true &&
                                <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                                }
                                <div className="task-tg max-250">

                                    <p className="remove-txt-gp">Assign to Individual, Team, Organization, etc (Ignore
                                        this for
                                        self tasks)</p>
                                    <div className="task-inp-container">

                                        <AutoComplete
                                            eleClass=''
                                            fieldTitle='To'
                                            sourceDataList={datausers}
                                            searchField='fullname'
                                            showIcon={false}
                                            hideTitle={true}
                                            hideMainLabel={false}
                                            showSubLabel={true}
                                            labelFields='fullname'
                                            valueField='uniqueId'
                                            subLabelField='designation'
                                            showProfile={true}
                                            reference='saveSettings'
                                            selectedItems={this.state.saveSettings}
                                            resultDataList={this.saveDataLevel}
                                            id="to_datalevel"
                                            actionMethod={this.getdatalevelUsers}
                                        />
                                    </div>


                                </div>

                            </SimpleModal>
                            }
                            {this.state.action === 'embed' &&
                            <div className="bs-modal show" id="generate-link" style={{"display": "block"}}>
                                <div className="bs-modal-dialog">
                                    <div className="bs-modal-content text-center">
                                        <div className="bs-modal-title">
                                            <h3>Form Generated Link</h3>
                                            <button className="modal-option-button"
                                                    onClick={() => this.modalOpen()}><span
                                                className="icon-in icon-close-in"></span>
                                            </button>
                                        </div>

                                        <div className="bs-modal-body">
                                    <textarea className="input-text-area" id="embedlink"
                                              value={`<iframe src="${window.location.hostname}/embed/form/${btoa(collectionid)}" style="border:1px #E4E6E9 solid;" name="bsynapseform" scrolling="yes" frameborder="1" marginheight="0px" marginwidth="1px" height="1080px" width="750px" allowfullscreen=""></iframe>`}>

                                    </textarea>
                                            <div className="input-block copy-link">
                                                <input className="input-box" type="text"
                                                       value={`${window.location.hostname}/sharing/form/${btoa(collectionid)}?metaid={metaid}`}/>
                                                <button className="general-btn ico-lib-v1 copy-ico"
                                                        onClick={this.copySharingUrl}>Copy Link
                                                </button>
                                                {this.state.urlCopied &&
                                                <p className="success-fdls">Sharing Url Copied</p>
                                                }
                                            </div>

                                            <button className="btn btn-default" data-dismiss="modal"
                                                    onClick={() => this.modalOpen()}>OK
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {this.state.action === "rule" &&
                            <RuleModal
                                listRule={this.props.listRule}
                                getWorkflow={this.props.getWorkflow}
                                linkedWorkflow={this.props.getlinkedWorkflow}
                                label={this.props.showFormModal}
                                deleteWorkflow={this.handleDeleted}
                                ruleClose={this.ruleClose}
                                createGenericRule={this.props.createGenericRule}
                                saveWorkflowrule={this.props.saveWorkflowrule}
                                eid={this.state.colID}
                                userList={this.props.listUsers.Users}
                                resultDataList={this.resultDataList}
                                selectedDescriptionUsers={this.state.selectedDescriptionUsers}
                                selectedfieldUsers={this.state.selectedfieldUsers}
                                selectedfieldUsersCc={this.state.selectedfieldUsersCc}
                                ignoredValues={this.props.selectedItems.selectedDescriptionUsers}
                                ignoredfieldValues={this.props.selectedItems.selectedfieldUsers}
                                ignoredfieldValuesCc={this.props.selectedItems.selectedfieldUsersCc}
                                getUsers={this.getUsers}
                                listPool={this.props.listPool}
                                ListAllPool={this.props.ListAllPool.pool}
                                getRule={this.getRule}
                                reference={'collection'}
                                getExternalValues={this.props.getExternalValues}
                                externalData={this.props.externalData}
                            />
                            }
                            {this.state.action === "schedule" &&
                            <ScheduleModal
                                listRule={this.props.listRule}
                                getWorkflow={this.props.getWorkflow}
                                linkedWorkflow={this.props.getlinkedWorkflow}
                                label={this.props.showFormModal}
                                deleteWorkflow={this.handleDeleted}
                                ruleClose={this.ruleClose}
                                createGenericRule={this.props.createGenericRule}
                                saveWorkflowrule={this.props.saveWorkflowrule}
                                eid={this.state.colID}
                                userList={this.props.listUsers.Users}
                                resultDataList={this.resultDataList}
                                selectedDescriptionUsers={this.state.selectedDescriptionUsers}
                                selectedfieldUsers={this.state.selectedfieldUsers}
                                ignoredValues={this.props.selectedItems.selectedDescriptionUsers}
                                ignoredfieldValues={this.props.selectedItems.selectedfieldUsers}
                                getUsers={this.getUsers}
                            />

                            }

                            {/*{this.state.action === 'api' &&*/}
                            {/*<>*/}
                            {/*    <ApiExplorer*/}
                            {/*        */}
                            {/*    />*/}
                            {/*</>*/}
                            {/*}*/}

                            {this.state.assignUserSelect === true &&
                            <SimpleModal
                                Close={this.modalOpen}
                                title={`Assigned Users`}
                                haveFooter={1}
                                OKLabel={Userpermission ? "Save" : ""}
                                OKAction={this.singleRecorddAssign}
                                asignedUsershow={true}
                                cancelLabel="Cancel"
                                cancelAction={this.modalOpen}
                            >
                                {this.props.loader.loaderType === 'share-collection' && this.props.loader.status === true &&
                                <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                                }
                                <div className="task-tg">
                                    <div id="assigned_users_list">

                                        {!Userpermission && this.state.assignUsers &&

                                        this.state.filterUserdata.map((item, indx) =>
                                            <div className="sl-tag">
                                                <span
                                                    className="tag-left"> {item.fullname.charAt(0).toUpperCase()}</span>
                                                <span className="tag-right" key={indx}>{item.fullname}</span> &nbsp;
                                            </div>
                                        )}

                                        {Userpermission &&
                                        <AutoComplete
                                            eleclassName=''
                                            fieldTitle='To'
                                            sourceDataList={datausers}
                                            searchField='fullname'
                                            showIcon={false}
                                            hideTitle={true}
                                            hideMainLabel={false}
                                            showSubLabel={false}
                                            labelFields='fullname'
                                            valueField='uniqueId'
                                            subLabelField='designation'
                                            showProfile={true}
                                            reference='saveSettings'
                                            selectedItems={this.state.filterUserdata}
                                            resultDataList={this.saveDataLevel}
                                            id="to_datalevel"
                                            actionMethod={this.getdatalevelUsers}
                                        />
                                        }


                                    </div>

                                </div>

                            </SimpleModal>
                            }
                            {this.state.action !== 'settings' && !this.state.msgOpn && ((permissionsSelected.length > 0 && permissionsSelected[0].addedit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || (collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser.toString()) === getProfile().id.toString())) &&
                            <div className="float-button-block">
                                <button className="float-button ico-lib float-add" data-toggle="modal"
                                        data-target="#addField"
                                        onClick={(e) => formPublished ? this.showFormModalTypeSelect(e) : this.showPublishWarning()}></button>
                            </div>
                            }
                            {this.state.action === 'settings' && this.state.subPan === "permissions-tab" &&
                            <div className="float-button-block">
                                <button className="float-button ico-lib float-add" data-toggle="modal"
                                        data-target="#addField"
                                        onClick={(e) => this.showdatalevelAssignedUsers(e)}></button>
                            </div>
                            }

                            {this.state.action === 'excel' &&

                            <Modal title={'Import Excel'} Close={this.modalOpen.bind(this)}>


                                <ImportExcel
                                    loader={this.props.loader}
                                    modalOpen={this.modalOpen}
                                    excelImportStatusData={this.excelImportStatusData}
                                />

                            </Modal>
                            }

                            {this.state.action === 'import' &&
                            <Modal title={'Import CSV'} Close={this.modalOpen.bind(this)}>
                                {this.props.loader.loaderType === 'import-csv' && this.props.loader.status === true &&
                                <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                                }
                                <div className="task-content-block dynamic-creation">
                                    <div className="persitant-top">
                                        {/* step1 */}
                                        {this.state.importstep === 1 &&
                                        <div className="instruction-model">
                                            <h5>Make sure the steps to Upload CSV:</h5>
                                            <p>Before you proceed with importing the csv, kindly make sure that the
                                                columns are
                                                in the right order.</p>
                                            <p>If in doubt, please refer to the exported template and verify the CSV
                                                columns.</p>
                                            <p>For the columns with options (dropdowns, check boxes and radio buttons),
                                                the
                                                values given in CSV must be the same as the ones given in the template
                                                or
                                                otherwise the right options will not get selected while importing.</p>
                                            <p>This results in collection records with wrong values selected or with
                                                blank
                                                values.</p>
                                        </div>
                                        }
                                        {/* step2 */}
                                        {this.state.importstep === 2 &&
                                        <div className="drag-drop-area">
                                            <div id={`progress-panel-container`} className={`progress-panel-holder`}>
                                                <div id={`progress-holder`} className={'upload-pr-wrap'}></div>
                                            </div>
                                            <div className="drag-drop-container">
                                                <UploadFile
                                                    AutoUpdate={true}
                                                    getUploadedFile={this.handleFileImport}
                                                    multipleFiles={false}
                                                    overLay={false}
                                                    dropZone={false}
                                                    wrapperclassName=""
                                                    progressBar={true}
                                                    progressContainer={`progress-holder`}
                                                    acceptedFiles={["csv"]}
                                                    displayFormat={"\.csv"}
                                                    ErrorMessage={this.messageError}

                                                />
                                                {/*<input type="file" name="csv" onChange={this.handleFileImport} />*/}
                                                <label className="ico-lib upload-csv">Upload CSV</label>
                                            </div>
                                            <div className="attachment-task-group" id="todoFileAssets">
                                                {this.state.csvImportMsg !== '' &&
                                                <div className="ind-attach-list file">
                                                    <span className={`msg-csv-import`}>{this.state.csvImportMsg}</span>
                                                </div>
                                                }
                                                {this.state.csvImportfalse !== '' &&
                                                <p className={`infom-msg ${this.state.Stat ? "error" : this.state.upload ? "" : "sucess"}`}>{this.state.csvImportfalse}</p>
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className="persitant-footer align-small">
                                        <div className="group-row ">

                                        </div>
                                        <div className="more-button-panel">
                                            {this.state.importstep === 2 &&
                                            <React.Fragment>
                                                <button type="button" className="general-btn button-lg grey"
                                                        id="createtask"
                                                        onClick={(e) => this.importStages(e, 'previous')}>Previous
                                                </button>
                                                {this.state.csvImportMsg !== "" &&
                                                <button type="button" className="general-btn button-lg" id="createtask"
                                                        onClick={this.submitCsv}>Submit
                                                </button>
                                                }
                                            </React.Fragment>
                                            }
                                            {this.state.importstep === 1 &&
                                            <button type="button" className="general-btn button-lg" id="createtask"
                                                    onClick={(e) => this.importStages(e, 'next')}>
                                                Next
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </Modal>

                            }
                            {this.state.showInvokeWorkflow &&
                            <InvokeWorkflow
                                SaveInvokeworkflow={this.SaveInvokeworkflow}
                                selectWorkflow={this.selectWorkflow}
                                closeInvokeModal={this.closeInvokeModal}
                                listNoWorkFlow={listNoWorkFlow}
                                WorkFlowloader={this.props.listnoFormWorkFlow.loading}
                                wloader={this.state.wloader}
                            />
                            }

                            {this.state.action === "task" &&

                            <Modal title={'Create New Task'} Close={this.closeAddNewTaskModal.bind(this)}>
                                <AddToDoTask
                                    projectList={this.state.projectList}
                                    tagList={this.state.tagList}
                                    listtags={this.props.listtags.tags}
                                    modalOpen={this.closeAddNewTaskModal}
                                    collectionRecord={collectionRecord}
                                    removeSelectedRecord={this.removeSelectedRecord}
                                    taggedProject={this.props.ProjectInfoDet.ProjectInfo.projectinfo}
                                    projectId={this.state.projectId}
                                    createdfrom={this.state.projectId !== '' ? 'prjcol' : 'collection'}

                                />

                            </Modal>
                            }

                            {this.state.note !== undefined &&
                            <SimpleModal
                                Close={() => this.setState({note: undefined})}
                                title={`Notes`}
                                haveFooter={1}
                                extraclassName="note-trans-mod"
                            >

                                <AddNotes
                                    value={this.props.listAnnotation}
                                    changeNotes={this.changeNotes}
                                />

                            </SimpleModal>
                            }

                            {this.state.csvErrorModal === true &&
                            <SimpleModal
                                Close={() => this.setState({csvErrorModal: false})}
                                title={`CSV import failed`}
                                haveFooter={1}
                                extraclassName="max-350"
                                cancelAction={() => this.setState({csvErrorModal: false})}
                            >
                                {this.state.csvCode === 0 &&
                                <div className="csv-validation">
                                    <h5>There is some technical issue</h5>
                                </div>
                                }
                                {this.state.csvCode === 2 &&
                                <div className="csv-validation">
                                    <h5>Duplicate values found in below mentioned columns of input file. The values in
                                        below
                                        mentioned columns must be unique</h5>
                                    {this.state.csvData.length > 0 &&
                                    <>
                                        <p>The duplicate values are </p>
                                        {this.state.csvData.map((list, index) =>
                                            <ul key={index}>
                                                <h5>{list.label}</h5>
                                                {list.value.map((item, indx) =>
                                                    <li key={indx}>{item}</li>
                                                )}
                                            </ul>
                                        )}
                                    </>
                                    }
                                </div>
                                }
                                {this.state.csvCode === 3 &&
                                <div className="csv-validation">
                                    <h5>Some of the values in the below mentioned columns of the input file already
                                        exist in the
                                        collection.The values in below mentioned columns must be unique</h5>
                                    {this.state.csvData.length > 0 &&
                                    <>
                                        <p>The duplicate values are </p>
                                        {this.state.csvData.map((list, index) =>
                                            <ul key={index}>
                                                <h5>{list.label}</h5>
                                                {list.value.map((item, indx) =>
                                                    <li key={indx}>{item}</li>
                                                )}
                                            </ul>
                                        )}
                                    </>
                                    }
                                </div>
                                }
                                {this.state.csvCode === 4 &&
                                <div className="csv-validation">
                                    <h5>The imported CSV is not related to this collection.</h5>
                                    {this.state.csvData.length > 0 &&
                                    <>
                                        <p>We have found invalid unique I'd(s) in the excel file which are not present
                                            in this
                                            collection. Kindly rectify those before trying again. </p>
                                        <ul>
                                            {this.state.csvData.map((list, index) =>

                                                list !== "" && <li key={index}>{list}</li>
                                            )}
                                        </ul>

                                    </>
                                    }
                                </div>
                                }
                            </SimpleModal>
                            }

                            {this.state.excelErrorModal === true &&
                            <SimpleModal
                                Close={() => this.setState({excelErrorModal: false})}
                                title={`Excel import failed`}
                                haveFooter={1}
                                extraclassName="max-350"
                                cancelAction={() => this.setState({excelErrorModal: false})}
                            >
                                {this.state.excelCode === 0 &&
                                <div className="csv-validation">
                                    <h5>There is some technical issue</h5>
                                </div>
                                }
                                {this.state.excelCode === 2 &&
                                <div className="csv-validation">
                                    <h5>Duplicate values found in below mentioned columns of input file. The values in
                                        below
                                        mentioned columns must be unique</h5>
                                    {this.state.excelData.length > 0 &&
                                    <>
                                        <p>The duplicate values are </p>
                                        {this.state.excelData.map((list, index) =>
                                            <ul key={index}>
                                                <h5>{list.label}</h5>
                                                {list.value.map((item, indx) =>
                                                    <li key={indx}>{item}</li>
                                                )}
                                            </ul>
                                        )}
                                    </>
                                    }
                                </div>
                                }
                                {this.state.excelCode === 3 &&
                                <div className="csv-validation">
                                    <h5>Some of the values in the below mentioned columns of the input file already
                                        exist in the
                                        collection.The values in below mentioned columns must be unique</h5>
                                    {this.state.excelData.length > 0 &&
                                    <>
                                        <p>The duplicate values are </p>
                                        {this.state.excelData.map((list, index) =>
                                            <ul key={index}>
                                                <h5>{list.label}</h5>
                                                {list.value.map((item, indx) =>
                                                    <li key={indx}>{item}</li>
                                                )}
                                            </ul>
                                        )}
                                    </>
                                    }
                                </div>
                                }
                                {this.state.excelCode === 4 &&
                                <div className="csv-validation">
                                    <h5>The imported Excel is not related to this collection.</h5>
                                    {this.state.excelData.length > 0 &&
                                    <>
                                        <p>We have found invalid unique I'd(s) in the excel file which are not present
                                            in this
                                            collection. Kindly rectify those before trying again. </p>
                                        <ul>
                                            {this.state.excelData.map((list, index) =>

                                                list !== "" && <li key={index}>{list}</li>
                                            )}
                                        </ul>

                                    </>
                                    }
                                </div>
                                }
                            </SimpleModal>
                            }

                            {this.state.quickFormSelect === true &&
                            <SimpleModal
                                Close={this.closeQuickFormModal}
                                title={`Quick Forms`}
                                haveFooter={1}
                                cancelAction={this.closeQuickFormModal}
                                OKAction={(e) => this.selectedQuickForm(e)}
                                OKLabel={`Submit`}
                                extraClass="small-height-modal"
                                extraClass="max-350"
                            >
                                <div>
                                    <div>
                                        {this.state.quickFormList.length !== 0 ?
                                            <React.Fragment>
                                                <div className="payment-mode quick-form">
                                                    <div class="each-inp-box">
                                                        <label>Select Quick Form</label>
                                                    </div>
                                                    <div className="radio-wrapper" id="recurringmode">
                                                        {(getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || createuser.toString() === getProfile().id.toString()) &&
                                                        <div className="radio-block">
                                                            <input
                                                                onChange={(e) => this.handleQuickFormChange(e, '', '', 'normal')}
                                                                type="radio" value='normal' name="quickform"/>
                                                            <label>Normal Form</label>
                                                        </div>
                                                        }
                                                        {this.state.quickFormList.map((list, index) =>

                                                            <div className="radio-block">
                                                                <input
                                                                    onChange={(e) => this.handleQuickFormChange(e, list.id, list.roleid)}
                                                                    type="radio" value={list.id} name="quickform"/>
                                                                <label>{list.title}</label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {this.state.submitQuickForm_error &&
                                                <span className="error-fdls">Please select a Quick Form</span>
                                                }
                                            </React.Fragment>
                                            :
                                            <div className="payment-mode">
                                                <p>No Quick Forms Found</p>
                                            </div>

                                        }
                                    </div>
                                </div>
                            </SimpleModal>
                            }

                        </React.Fragment>
                        {this.state.flagPan && this.state.checkedRow.length >= 1 &&
                        <React.Fragment>
                            {(this.state.collectionId === '1600230072115' || this.state.collectionId === '1614837782109') ?
                                <Suspense fallback={renderLoader()}>
                                <FlagSidepanel
                                    close={() => this.panelClose()}
                                    flagItems={this.state.checkedRow.length !== 0 ? this.props.collectionData.CollectionData.filter(list => list.id === this.state.checkedRow.toString()) : []}
                                    collection={collection}
                                    //   handleFlagSet={this.handleFlagSet}
                                    // handleFlagSetRadio={this.handleFlagSetRadio}
                                    checkedRow={this.state.checkedRow}
                                    // saveFlag={this.saveFlag}
                                    clearCategory={this.clearCategory}
                                    collectionData={this.props.collectionData}
                                    collectionId={this.state.collectionId}
                                    paymentData={mobiletemplate_settings}
                                    dashboard={dashboard}

                                />
                                </Suspense>
                                :
                                <Suspense fallback={renderLoader()}>
                                <SummaryCard
                                    summary={summary}
                                    checkedRow={this.state.checkedRow}
                                    getCollectionconfig={this.props.GetCollectionconfig}
                                    close={() => this.panelClose()}
                                    flagItems={this.state.checkedRow.length !== 0 ? this.props.collectionData.CollectionData.filter(list => this.state.checkedRow.includes(list.id)) : []}
                                    collection={collection}
                                    clearCategory={this.clearCategory}
                                    collectionData={this.props.collectionData}
                                    collectionId={this.state.collectionId}
                                    label={this.props.showFormModal}
                                    permissionsSelected={permissionsSelected}
                                    createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                    paymentData={mobiletemplate_settings}
                                    dashboard={dashboard}

                                />
                                </Suspense>
                            }
                        </React.Fragment>
                        }

                        {this.state.showBookingStatus &&
                        <SimpleModal
                            Close={this.closeBookingCalendar}
                            title={`Appointment Schedule`}
                            haveFooter={false}
                            cancelAction={this.closeBookingCalendar}
                            OKAction={(e) => this.selectedQuickForm(e)}
                            OKLabel={`Submit`}
                            extraClass="scehdule-mod"
                            modalSize="modal-xl"

                        >
                            <BookingCalendar
                                user={this.state.user}
                                toggleModal={this.toggleModal}
                                showUserDetails={this.showUserDetails}
                                bookingCustId={this.state.bookingCustId}
                                bookingCustName={this.state.bookingCustName}
                                bookingCustEmail={this.state.bookingCustEmail}
                                bookingCustPhone={this.state.bookingCustPhone}
                                bookingCustUid={this.state.bookingCustUid}
                                custInfo={this.state.custInfo}

                            />
                        </SimpleModal>
                        }
                    </React.Fragment>
                }
                </>
        );
    }
}


const mapStateToProps = state => ({
    collectionData: state.CollectionsReducer.listCollectionDetaildata,
    collectionFilterData: state.CollectionsReducer.listCollectionFilterData,
    collectionDataChanged: state.CollectionsReducer.getChangedCollectionData,
    haveLoader: state.CollectionsReducer.manageoader,
    listUsers: state.UsersReducer.listAllUsersdata,
    sharedCollection: state.CollectionsReducer.sharedCollection,
    loader: state.CommonReducer.loader,
    filterDatadetails: state.CollectionsReducer.filterDatadetails,
    exportfield: state.CollectionsReducer.exportfield,
    GetCollectionconfig: state.CollectionsReducer.GetCollectionConfig,
    showFormModal: state.CollectionsReducer.showFormModal,
    saveAssigndata: state.CollectionsReducer.saveAssigndata,
    csvImportData: state.CollectionsReducer.csvImportData,
    excelImportData: state.CollectionsReducer.excelImportData,
    getSavedForm: state.CollectionsReducer.getSavedForm,
    saveLinkingPermission: state.CollectionsReducer.savelinkingpermission,
    sharedCollectionData: state.CollectionsReducer.collectionSettingsData,
    savadataSettings: state.CollectionsReducer.savadataSettings,
    selectedItems: state.TaskReducer.getSelectedItems,
    listRule: state.CollectionsReducer.listRule,
    getWorkflow: state.CollectionsReducer.getWorkflow,
    getlinkedWorkflow: state.CollectionsReducer.getlinkedWorkflow,
    getWorkflowStatus: state.CollectionsReducer.getWorkflowStatus,
    getCollectionTemplate: state.CollectionsReducer.getCollectionTemplate,
    listtags: state.TaskReducer.ListAllTags,
    projects: state.TaskReducer.GetProjectData,
    HistoryPushData: state.TaskReducer.HistoryPushData,
    ProjectInfoDet: state.ProjectReducer.ProjectInfo,
    deletedRec: state.CollectionsReducer.statusDeletedRecords,
    listAnnotation: state.CollectionsReducer.listAnnotation,
    importCSVData: state.CollectionsReducer.importCSVData,
    listnoFormWorkFlow: state.workflowReducer.noformWorkFlow,
    invokeworkflowdata: state.CollectionsReducer.invokeWorkflowdata,
    shareAllInfo: state.CeShareView.shareAllInfo,
    removeAssignFlagData: state.CeShareView.removeAssignFlagData,
    assignFlagData: state.CeShareView.assignFlagData,
    ColUpdate: state.CeShareView.ColUpdate,
    savedNoteShare: state.CeShareView.savedNoteShare,
    listShareNote: state.CeShareView.listShareNote,
    externalData: state.CollectionsReducer.getExternalForm,
    listFilterdList: state.CeShareView.FilterdList,
    ListAllPool: state.CeShareView.ListPoolData,
    formInfo: state.CollectionsReducer.showFormModal,
    fieldUpdateData: state.CeShareView.fieldUpdateData,
    listQuickForm: state.CeShareView.listQuickForm,
    recordDuplicate: state.CeShareView.recordDuplicate,

});
export default withRouter(connect(mapStateToProps, {
    mainClick: rootActions.commonActions.MenuClick,
    collectionDetails: collectionActions.collectionDetail,
    collectionFilter: collectionActions.collectionFilter,
    collectionDataDelete: collectionActions.collectionDataDelete,
    users: usersActions.listAllUsers,
    sharecollection: collectionActions.sharecollection,
    showform: collectionActions.showform,
    getFormData: collectionActions.getFormData,
    getFilterData: collectionActions.getFilterData,
    exportField: collectionActions.exportField,
    saveFieldData: collectionActions.saveExportFieldData,
    getCollectionconfig: collectionActions.getcollectionconfig,
    savaDataView: collectionActions.savaDataView,
    savaDataSettings: collectionActions.savaDataSettings,
    savaDatalevelSettings: collectionActions.savaDataSettings,
    singlerecorddAssign: collectionActions.singlerecorddAssign,
    saveFilter: collectionActions.saveFilter,
    saveRightSidePanel: collectionActions.saveRightSidePanel,
    importCollectionData: collectionActions.importCollectionData,
    importExcelData: collectionActions.importExcelData,
    saveAssignData: collectionActions.saveAssignData,
    savelinkingpermission: collectionActions.savelinkingpermission,
    selectExport: collectionActions.selectExport,
    loadmoreCollectionDetails: collectionActions.loadmoreCollectionDetails,
    getsharedSettingsData: collectionActions.getCollectionSettings,
    resetForm: collectionActions.resetForm,
    exportall: collectionActions.exportall,
    setSelectedItems: taskActions.setSelectedItems,
    getRule: collectionActions.getRule,
    listingWorkflow: collectionActions.listingWorkflow,
    linkedWorkflow: collectionActions.linkedWorkflow,
    deletelinkedWorkflow: entityActions.deletelinkedWorkflow,
    createGenericRule: collectionActions.createGenericRule,
    genericRuleDelted: collectionActions.genericRuleDelted,
    fieldwiseRuleDelted: collectionActions.fieldwiseRuleDelted,
    saveWorkflowrule: collectionActions.saveWorkflowrule,
    CollectionTemplateSaving: collectionActions.CollectionTemplateSaving,
    resetcollectionData: collectionActions.collectionActions,
    projectInfo: projectActions.projectInfo,
    viewChangelimit: collectionActions.viewChangelimit,
    deleteAll: collectionActions.deleteAll,
    maploadmoreCollectionDetails: collectionActions.maploadmoreCollectionDetails,
    loadmoremapData: collectionActions.loadmoremapData,
    starClick: collectionActions.starClick,
    listNotes: collectionActions.listNotes,
    saveNotes: collectionActions.saveNotes,
    noformworkFlow: WorkFlowActions.noformWorkFlow,
    saveinvokeWorkflow: collectionActions.saveInvokeWorkflow,
    shareAll: CeShareViewActions.shareAll,
    assignFlag: CeShareViewActions.assignFlag,
    removeAssignedFlag: CeShareViewActions.removeAssignedFlag,
    colUpdate: CeShareViewActions.colUpdate,
    savingNoteShare: CeShareViewActions.savingNoteShare,
    listShareNotes: CeShareViewActions.listShareNotes,
    getExternalValues: collectionActions.getExternalValues,
    listPool: CeShareViewActions.listPool,
    quickFormList: CeShareViewActions.quickFormList,
    collectionRecordDuplicate: CeShareViewActions.collectionRecordDuplicate,

})(CollectionDetails));
