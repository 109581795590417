import React from 'react';
import axios from 'axios';
import { Draggable, state } from 'react-page-maker';
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {ES_INDEX} from "../../../../constants";
import {getUrlSegment} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {DashboardSettings, SettingsPanel} from "./ViewData";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};


class ApiComponentContainer extends React.Component {
  constructor(props,context) {
    super(props,context);
    this.state={
      modalStatus:false,
      showColorPicker:false,
      background:'#FF1B40',
      cId:'',
      payload: {
        chartTitle: "Title",
        subTitle: "subtitle",
        subTitleEnabled: false,
        loading: true,
        apiUrl:"",

      },
      apiData:""
    }


  }
  componentDidMount() {
    this.setState({payload:this.props.payload})
    this.graphData()
  }
  checkBoxchange=(e)=> {
    let val;
    if (e.target.value === "false") {
      val = true
    } else {
      val = false
    }
    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = val
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  openSetting=()=>{
    this.setState({modalStatus:!this.state.modalStatus})
    this.props.dragStatus(!this.state.modalStatus)
  }
  handleChangeComplete = (color,param) => {
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[param]=color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
    //this.setState({ background: color.hex });
  };
  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }
  handleInputChange=(e)=>{
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  graphData(){
    var obj = this;
    const apiUrl = this.props.payload && this.props.payload.apiUrl||this.state.apiUrl
    this.setState({apiUrl:apiUrl})
    if(apiUrl !=='' ||apiUrl !== undefined && this.state.apiData ==="") {
      axios.get(apiUrl, {})
          .then(function (res) {
            var apiData = res.data.results.data;
           obj.setState({apiData: apiData,loading:false});
            return res;
          }).catch(function (err) {
        return err;
      });

    }


  }
  handleChange=(e)=>{
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  chartSaving=()=>{
    var prjctdtlid = atob(getUrlSegment(4));
    let sharetype = getUrlSegment(1);
    let  uid = '';
    if(sharetype === 'public') {
        uid = 2;
    }
    else{
        uid = getProfile().id;
    }
    let param={
      "projectid": prjctdtlid,
      "uid": uid,
      "index": ES_INDEX,
      "type": this.props.getdashboardChange.Dashboard,
      "dashboard":state.getStorableState()
      ,
      "id": this.props.getdashboardChange.UpdateId
    }
    this.props.chartSaving(param)
    this.setState({modalStatus:false})
  }
  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
    );

    this.setState({
      items
    });
  };
  addData=()=>{
    let data=this.state.items
    let counter=data.length+1
    let newData={
      id: "item-"+new Date().getTime(),
      label: "item "
    }
    let _data=data.concat(newData)
    this.setState({items:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  removeData=(dragid)=>{
    let data=this.state.items
    let _data=data.filter(item=>item.id !== dragid)
    this.setState({items:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleLabelChange=(e,dragid)=>{
    var _newState = this.state.items;
    var index=(_newState.findIndex(obj => obj["id"] === dragid))
    _newState[index][e.target.name]=e.target.value;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=this.state.items
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }
  render() {
    const {
      id,
      showBasicContent, showPreview,
      dropzoneID,payload, parentID, name
    } = this.props;
    const chartTitle =  this.state.chartTitle || payload && payload.chartTitle;
    const subTitle =  this.state.subTitle || payload && payload.subTitle;
    const subTitleEnabled = this.state.subTitleEnabled || payload && payload.subTitleEnabled;
    const apiUrl =  this.state.apiUrl || payload && payload.apiUrl;
    const apiData =  this.state.apiData  ;
    if (showBasicContent) {
      return (
          <Draggable { ...this.props } >
            {
              <span className="drag-icon">{name}</span>
            }
          </Draggable>
      )
    }

    if (showPreview) {
      return (

          <div className="ind-small-tab-dashboard">
            <div className="small-tab-header with-desc-box">
              <h5>{chartTitle} </h5>
            </div>
            <div className="small-tab-footer">
              <h5>{apiData && apiData.diffcnt}</h5>
              <div className="small-tab-graph">
                <span className={apiData && apiData.daychange ==="up" ?"high-val":"low-val"}> </span>
              </div>
            </div>
          </div>


      );
    }
    return (
        <React.Fragment>
          <Draggable {...this.props}
              draggable={!this.props.DragDisabled.dragStatus}
          >
            <SettingsPanel
                id={id}
                name={chartTitle?chartTitle:name}
                dropzoneID={dropzoneID}
                parentID={parentID}
                openSetting={this.openSetting}
                removeElement={<div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
            />
          </Draggable>
            {this.state.modalStatus &&
              <DashboardSettings
                title={`Api Component  -   Settings`}
                chartTitle={chartTitle}
                subTitle={subTitle}
                subTitleEnabled={subTitleEnabled}
                apiUrl ={apiUrl}
                modaltype={7}
                Close={this.openSetting}
                OKAction={this.chartSaving}
                cancelAction={this.openSetting}
                handleChange={this.handleChange}
                checkBoxchange={this.checkBoxchange}
                handleLabelChange={this.handleLabelChange}
                handleInputChange={this.handleInputChange}
                addData={this.addData}
                removeData={this.removeData}
                onDragEnd={this.onDragEnd}
            />
            }
        </React.Fragment>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled
});
export default  withRouter(connect(mapStateToProps, {
  collections: collectionActions.listallcollections,
  showform : collectionActions.showform,
  chartSaving:DynamicDashboard.saveDashboarddata,
  dragStatus :DynamicDashboard.dragStatus,

})(ApiComponentContainer));
