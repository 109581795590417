import React, {Component} from 'react';
import Datetime  from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import "./DateTimePickerBox.css";
import moment from "moment";
import {getProfile} from "../../utilities/AuthService";

class TimePickerBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startTime: null,
            index : 0,
            disabled:false,
            closeOnSelect:false,
            fromTaskDetail:false,
            showClass: '',
            showClearButton : false

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(time) {
        var time = moment(time);
        //console.log(time)
        this.setState({
            startTime: time,
        });

        this.props.handleTimeChange(time, this.props.index)


    }

    closeOnSelect = () => {
        // this.setState({closeOnSelect:true})
        this.props.dateSwap(false)
    }
    renderInput = (props, openCalendar, closeCalendar) => {
        if(this.state.fromTaskDetail === true) {
            function clear() {
                props.onChange({target: {value: ''}});
            }

            return (
                <div>
                    <input {...props} />
                    <button onClick={openCalendar}>open calendar</button>
                    <button onClick={closeCalendar}>close calendar</button>
                    <button onClick={clear}>clear</button>
                </div>
            );
        }
    }
    componentDidMount() {

        this.setState({
            fromTaskDetail: this.props.fromTaskDetail,
            showClass : this.props.showClass ? this.props.showClass : this.state.showClass,
            showClearButton : this.props.showClearButton? this.props.showClearButton : this.state.showClearButton
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.startTime!==prevState.startTime){
            return { startTime: nextProps.startTime};
        }
        else return null;
    }
    // onChange(date) {
    //      var date = moment(date).set({hour:11,minute:59,second:0,millisecond:0})
    //      date.toISOString()
    //      date.format()
    //
    //     //////////console.log(date);
    // }

    clearTime = () => {
        this.setState({
            startTime: '',
        });
        this.props.handleTimeChange('', this.props.index)
    }
    render() {
        let yesterday = Datetime.moment().subtract(1, 'day');
        let valid = function( current ){
            return current.isAfter( yesterday );
        };

        return (
            <React.Fragment>
                <Datetime
                    inputProps={{ placeholder: this.props.placeholderText,autoComplete: 'off', id : (this.props.id? this.props.id :'date'), readOnly : true }}
                    defaultValue={this.state.startTime}
                    value={this.state.startTime}
                    onChange={this.handleChange}
                    placeholderText = {this.props.placeholderText}
                    id={this.props.id? this.props.id :'date'}
                    index = {this.props.index}
                    className={ "form-type-input "+ (this.props.addErrorClass ? " red-alert" : "")}
                    isValidDate={this.props.previousDate ? '' : valid}
                    input={this.props.input}
                    renderInput={this.props.renderInput && this.renderInput}
                    closeOnSelect = {true}
                    closeOnTab = {true}
                    open = {this.props.open}
                    // onChange={this.onChange}
                    timeFormat={this.props.timeFormat}
                    dateFormat={false}
                    onFocus={this.props.onfocus}
                    onBlur={this.props.onblur}
                    defaultHour={14}
                    defaultMinute={30}
                />
                {this.state.showClearButton && this.state.startTime !== '' &&
                <button className="ico-lib-v1 date-clear" onClick={this.clearTime}></button>
                }
            </React.Fragment>

        );
    }
}

export default TimePickerBox
