import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import OverviewDetails from './OverviewDetails'
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import DashboardStatistics from "./DashboardStatistics";
import LoaderSvg from "./LoaderSVG";

class Overview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList: []
        }
    }

    componentDidMount() {
        this.setState({
            dataList: this.props.dataList ? this.props.dataList : this.state.dataList
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.dataList !== prevState.dataList) {
            return {dataList: nextProps.dataList, type: nextProps.type};
        }
        else return null;
    }

    render() {

        let dataList = this.props.dataList
        //var data = this.state.dataList;
        var data = [
            {label: "To Follow Up", value: 233, value2: 123},
            {label: "Overdue", value: "27", value2: "187"},
            {label: "Due Today", value: "227", value2: "100"},
            {label: "Due This Week", value: "127", value2: "156"},
        ];

        return (
            <div className="content-left lg-tab">
                <div className="content-bl-header">
                    <h5>{(this.props.type === 'own_task') ? 'My Overview' : 'Overview'}</h5>
                </div>
                <div className="static-mode-block">
                    <ul className="static-counter">
                        {this.props.multiLoader.loader['dashboard-userstat'] && this.props.multiLoader.loader['dashboard-userstat'] === true &&
                            <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                        }
                        {dataList.map((val, index) =>
                            <OverviewDetails key={index} dataList={val}/>
                        )}
                    </ul>
                    <div className="graph-wrapper"
                         style={{width: '100%', height: '67px', marginLeft: '-20px', marginRight: '-20px'}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart width='100%' height='100%' data={data}
                                       margin={{top: 10, right: -20, left: -20, bottom: 0}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor="#12784A" stopOpacity={0.3}/>
                                        <stop offset="95%" stopColor="#12784A" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor="#3C98DC" stopOpacity={0.3}/>
                                        <stop offset="95%" stopColor="#3C98DC" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="label" hide={true} tick={false}/>
                                <YAxis dataKey="value" hide={true} tick={false}/>
                                <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false}/>
                                <Tooltip/>
                                <Area type="linear" dataKey="value" stroke="#12784A" fillOpacity={1}
                                      fill="url(#colorUv)"/>
                                <Area type="linear" dataKey="value2" stroke="#3C98DC" fillOpacity={1}
                                      fill="url(#colorPv)"/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default Overview;