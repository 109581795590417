import React from 'react';
import axios from 'axios';
import {FormGroup} from 'react-bootstrap';
import {Draggable, state} from 'react-page-maker';
import LineChartCard from "../views/charts/LineChartCard";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {withRouter} from 'react-router';
import {API_PATH, ES_INDEX} from "../../../../constants"
import {getProfile} from '../../../../utilities/AuthService';
import {getUrlSegment, isValidDate} from "../../../../utilities/CustomFunctions";
import LoaderSvg from "../../../views/LoaderSVG";
import SettingsModal from "./SettingsModal";
import moment from "moment";

let graphData = [
    {
        "x": "2018 Q1",
        "y": "500",
        "z": "2018 Q1"
    },
    {
        "x": "2018 Q2",
        "y": "620",
        "z": "2018 Q2"
    }]

class LinechartContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalStatus: false,
            showColorPicker: false,
            background: '#fff',
            payload: {
                chartTitle: "Title",
                subTitle: "subtitle",
                subTitleEnabled: false,
                linedata: graphData,
                cardvalue: 0,
                collection: "",
                fields: "",
                aggregation: '',
                graph: false,
                x: "",
                y: "",
                z: "",
                labelx: "",
                labely: "",
                labelfield: '',
                linecolor: "",
                labeltitle: "",
                min: "",
                max: "",
                whereCondition: [
                    {
                        id: "item-1",
                        label: "",
                        field: "",
                        conditions: "",
                        min: '',
                        max: '',
                        from: '',
                        to: '',
                        text: "",
                        type: ""
                    },
                ]
            },
            linedata: [],
            whereCondition: [
                {
                    id: "item-1",
                    label: "",
                    field: "",
                    conditions: "",
                    min: '',
                    max: '',
                    from: '',
                    to: '',
                    text: "",
                    type: ""
                },
            ],
            globalFilter: [],
            ownFilterList: [
                {
                    id: "item-1",
                    label: "",
                    field: "",
                    conditions: "",
                    min: '',
                    max: '',
                    from: '',
                    to: '',
                    text: "",
                    type: ""
                },
            ],
            condition: [{
                field: "",
                type: "",
                sort: ""
            }],
            loading: true,
            showlistField: [],
            ownFilter: false,
            relFilCnt: 0,
            relFltr: false,
            linecolor: "",
            fields: "",
            aggregation: '',
            aggValue: '',
            flagId: [],
            globalFilterComp: [],
            selectedGlobalFilters: []

        }


    }

    componentDidMount() {

        let ownid = "linechart" + Math.round(new Date().getTime() + (Math.random() * 100));
        this.setState({
            ownid: ownid,
            payload: this.props.payload,
            globalFilterComp: this.props.globalFilters.globalFilterComp
        })
        this.graphData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterCnt.date !== this.props.filterCnt.date) {
            if (this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") {
                if (this.props.filterCnt.filterType !== 'globalFilter') {
                    if (this.props.filterCnt.filterName === this.state.ownid) {
                        this.graphData();
                    }

                } else {

                    this.graphData()
                }
            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.filterCnt.select !== this.props.filterCnt.select) {
            if (this.props.filterCnt.filterType !== 'globalFilter') {
                if (this.props.filterCnt.filterName === this.state.ownid) {
                    this.graphData();
                }

            } else {
                this.graphData()

            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.globalFilterCnt.select !== this.props.globalFilterCnt.select) {
            if (this.state.selectedGlobalFilters.length > 0) {
                this.graphData();
            }
            this.setState({relFltr: true, relFilCnt: 0})
        }

        if (prevProps.globalFilterCnt.date.from !== this.props.globalFilterCnt.date.from || prevProps.globalFilterCnt.date.to !== this.props.globalFilterCnt.date.to) {
            if (this.state.selectedGlobalFilters.length > 0) {
                this.graphData();
            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.globalFilterCnt.resetdate !== this.props.globalFilterCnt.resetdate) {
            if (this.state.selectedGlobalFilters.length > 0) {
                this.graphData();
            }
            this.setState({relFltr: false, relFilCnt: 0})
        }
        if (prevProps.globalFilters.globalFilterComp !== this.props.globalFilters.globalFilterComp) {
            this.setState({
                globalFilterComp: this.props.globalFilters.globalFilterComp
            })
        }
    }


    openSetting = () => {
        this.setState({modalStatus: !this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }
    handleChangeComplete = (color, param) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[param] = color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    };

    toggleColorPicker = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    }


    handleInputChange = (e) => {
        let sel = e.target
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
            this.props.getFlag({collection: e.target.value, uid: 2})
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        payload["label" + e.target.name] = sel.options[sel.selectedIndex].text
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    handleChange = (e) => {
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    setDate = (e) => {

        let sel = e.target
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        payload["label" + e.target.name] = sel.options[sel.selectedIndex].text
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }


    handleConditionChange = (e) => {
        var _newState = this.state.condition;
        if (e.target.name === 'field') {
            let t = e.target.value.split("|");
            _newState[0]["field"] = t[0];
            _newState[0]["type"] = t[1] === "datepicker" ? "date" : t[1];
        } else {
            _newState[0][e.target.name] = e.target.value;
        }


        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["condition"] = this.state.condition
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });


    }


    graphData() {
        var date = new Date();
        var timestamp = date.getTime();
        var obj = this;
        let payload = this.state.payload
        const collection = this.props.payload && this.props.payload.collection
        const fields = this.props.payload && this.props.payload.fields
        const condition = this.props.payload && this.props.payload.condition || this.state.condition
        const whereCondition = this.props.payload && this.props.payload.whereCondition || this.state.whereCondition
        const x = this.props.payload && this.props.payload.x || ''
        const y = this.props.payload && this.props.payload.y || ''
        const aggregation = this.props.payload && this.props.payload.aggregation || ''
        const graph = this.props.payload && this.props.payload.graph || false;
        const limit = this.props.payload && this.props.payload.limit || this.state.limit
        const ownFilterList = this.props.payload && this.props.payload.ownFilterList || this.state.ownFilterList
        const globalFilter = this.props.payload && this.props.payload.globalFilter || this.state.globalFilter
        const selectFilter = this.state.selectFilter || this.props.payload && this.props.payload.selectFilter
        const filterName = this.state.filterName || this.props.payload && this.props.payload.filterName
        const setDate = this.props.payload && this.props.payload.setDate || this.state.setDate
        const setFrom = this.props.payload && this.props.payload.setFrom || this.state.setFrom
        const setTo = this.props.payload && this.props.payload.setTo || this.state.setTo
        const dateFrom = this.props.payload && this.props.payload.dateFrom || this.state.dateFrom
        const flagId = this.props.payload && this.props.payload.flagId || this.state.flagId

        let selectedGlobalFilters = [];
        if (globalFilter.length > 0) {
            console.log(globalFilter)
            selectedGlobalFilters = globalFilter.filter(item => item.collection && item.collection !== '' && item.field && item.field !== '').map(fil => {
                return fil.id;
            })
            selectedGlobalFilters = [].concat.apply([], selectedGlobalFilters);
        }


        this.setState({
            ownFilterList: ownFilterList,
            flagId: flagId,
            whereCondition: whereCondition,
            globalFilter: globalFilter,
            filterName: filterName,
            selectFilter: selectFilter,
            selectedGlobalFilters: selectedGlobalFilters
        })

        if ((collection !== '' && collection !== undefined) || getUrlSegment(1) === "collections") {
            let collections = (getUrlSegment(1) === "collections") ? atob(getUrlSegment(4)) : collection

            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + collections + "&index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                //////console.log(res.data.data)
                obj.setState({showlistField: res.data.data, whereCondition: whereCondition});
                return res;

            }).catch(function (err) {
                return err;
            });

            axios({
                method: "GET",
                url: API_PATH + "collection/flagsettingslist?collection=" + collections + "&index=" + ES_INDEX + "&uid=" + 2,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                //console.log(res.data.data)
                obj.setState({getFlagData: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });


        }

        if ((collection !== "" && collection !== undefined) || getUrlSegment(1) === "collections") {
            let collections = (getUrlSegment(1) === "collections") ? atob(getUrlSegment(4)) : collection

            let filters = [];
            let numberfilters = [];
            let datefilters = [];
            if (whereCondition.length > 0) {
                for (var i = 0; i < whereCondition.length; i++) {
                    let conditionEntry = whereCondition[i];
                    if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                        let conditionValue = '';
                        if (conditionEntry.type === 'text') {
                            conditionValue = conditionEntry.text;
                        } else if (conditionEntry.type === 'conditions') {
                            conditionValue = conditionEntry.conditions;
                        }
                        let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];


                        if (conditionEntry.type === 'datepicker') {
                            let datefilter = {
                                "id": conditionEntry.field,
                                "from": conditionEntry.from,
                                "to": conditionEntry.to
                            }
                            datefilters.push(datefilter)
                        } else if (conditionEntry.type === 'number') {
                            let numberfilter = {
                                "id": conditionEntry.field,
                                "min": conditionEntry.min,
                                "max": conditionEntry.max
                            }
                            numberfilters.push(numberfilter)
                        } else {
                            //console.log(conditionArr)
                            if (conditionArr.length > 0) {
                                let filter = {
                                    "id": conditionEntry.field,
                                    "value": conditionArr
                                }
                                filters.push(filter);
                            }
                        }

                    }

                }
            }

            if ((this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") || this.props.filterCnt.select !== "") {
                if (globalFilter.length > 0) {
                    for (var i = 0; i < globalFilter.length; i++) {
                        let conditionEntry = globalFilter[i];
                        if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                            let conditionValue = '';
                            if (conditionEntry.type === 'text') {
                                conditionValue = conditionEntry.text;
                            } else if (conditionEntry.type === 'conditions') {
                                conditionValue = conditionEntry.conditions;
                            }
                            let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];

                            if (conditionEntry.type === 'datepicker' && this.props.filterCnt.date.from !== "") {
                                let datefilter = {
                                    "id": conditionEntry.field,
                                    "from": this.props.filterCnt.date.from,
                                    "to": this.props.filterCnt.date.to
                                }
                                datefilters.push(datefilter)
                            } else if (conditionEntry.type === 'number') {
                                let numberfilter = {
                                    "id": conditionEntry.field,
                                    "min": conditionEntry.min,
                                    "max": conditionEntry.max
                                }
                                numberfilters.push(numberfilter)
                            } else {
                                if (this.props.filterCnt.select !== "") {
                                    let filter = {
                                        "id": conditionEntry.field,
                                        "value": [this.props.filterCnt.select]
                                    }
                                    filters.push(filter);
                                }

                            }

                        }
                    }
                }
            }


            let sortCondition = [];
            if (condition.length > 0) {
                for (var j = 0; j < condition.length; j++) {
                    if (condition[j].field && condition[j].field !== '' && condition[j].sort && condition[j].sort !== ''
                        && condition[j].type && condition[j].type !== '') {
                        sortCondition.push(condition[j]);
                    }
                }
            }

            let data = {
                collectionid: collections,
                "index": ES_INDEX,
                "fieldid": fields,
                "components": [{"label": "x", "field": x}, {"label": "y", "field": y}],
                "condition": sortCondition,
                "graph": graph,
                "x": x,
                "y": y,
                "filters": filters,
                "numberfilters": numberfilters,
                "datefilters": datefilters,
                "size": limit,
                "linkedid": this.props.linkedId.param,
                "flags": flagId

            }
            if (flagId.length !== 0) {
                data.fieldid = "101"
            }

            if (setDate) {
                if (setDate === 'todays') {

                    data.fromdate = moment().format('YYYY-MM-DD');
                    data.todate = moment().format('YYYY-MM-DD');
                }
                if (setDate === 'lastsevendays') {
                    data.fromdate = moment().add(-7, 'days').format('YYYY-MM-DD');
                    data.todate = moment().format('YYYY-MM-DD')
                }

                if (setDate === 'thismonth') {
                    let thisMoment = moment();
                    data.fromdate = moment().moment(thisMoment).startOf('month').format('YYYY-MM-DD')
                    data.todate = moment().format('YYYY-MM-DD')
                }

                if (setDate === 'last6month') {
                    data.fromdate = moment().subtract(6, 'months').format('YYYY-MM-DD')
                    data.todate = moment().format('YYYY-MM-DD')
                }
                if (setDate === 'daterange') {
                    data.fromdate = moment(setFrom).format('YYYY-MM-DD')
                    data.todate = moment(setTo).format('YYYY-MM-DD')
                }
                if (setDate === 'dateFrom') {
                    data.fromdate = moment().add('-' + dateFrom, 'days').format('YYYY-MM-DD')
                    data.todate = moment().format('YYYY-MM-DD')
                }
            }

            obj.setState({loading: true});
            if (data.collectionid) {
                axios.post(API_PATH + "project/datacard", data).then(function (res) {
                    var carddata = res.data.results.data;
                    var aggregatevalue = carddata.aggregation;
                    //console.log(aggregatevalue[0][aggregation])

                    let linedata = carddata.graph.map(function (item) {
                        let firstKey = Object.keys(item)[0];
                        item[firstKey] = isValidDate(item[firstKey])
                        for (var key in item) {
                            if (item.hasOwnProperty(key) && key !== firstKey) {
                                item[key] = parseInt(item[key]);
                            }
                        }

                        return item;
                    });
                    //////console.log(linedata)
                    obj.setState({
                        linedata: linedata,
                        x: x,
                        y: y,
                        loading: false,
                        condition: condition,
                        cardvalue: aggregatevalue.length > 0 ? Math.round(aggregatevalue[0][aggregation]) : ''
                    });
                    return res;

                }).catch(function (err) {
                    ////////console.log("error");
                    ////////console.log(err);
                    obj.setState({loading: false});

                    return err;
                });
            }

        } else {
            obj.setState({linedata: graphData, cardvalue: 0});

        }
    }

    addData = (Id, updateParam) => {
        let data = this.state[updateParam]
        let newData = {
            id: "item-" + new Date().getTime(),
            label: "item "
        }
        let _data = data.concat(newData)
        this.setState({[updateParam]: _data})
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[updateParam] = _data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    removeData = (dragid, updateParam) => {
        let data = this.state[updateParam]
        let _data = data.filter(item => item.id !== dragid)
        this.setState({[updateParam]: _data})
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        //console.log(payload)
        payload[updateParam] = _data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    radioBoxchange = (e) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    checkBoxchange = (e) => {
        let val;
        if (e.target.value === "false") {
            val = true
        } else {
            val = false
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    handleLabelChange = (e, dragid, whereCondition, type) => {
        var updatedParam = "items";
        if (whereCondition === "graph") {
            updatedParam = "items";
        } else {
            updatedParam = whereCondition;
        }

        var _newState = this.state[updatedParam];
        //console.log(_newState)
        var index = (_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name] = e.target.value;
        if (whereCondition === "whereCondition" && type !== "field") {
            _newState[index]["type"] = type;
        }
        //console.log(_newState)
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[whereCondition] = this.state[updatedParam]
        //console.log(payload)
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    chartSaving = () => {
        var prjctdtlid = getUrlSegment(4) ? atob(getUrlSegment(4)) : '';
        let sharetype = getUrlSegment(1);
        let uid = '';
        if (sharetype === 'public') {
            uid = 2;
        } else {
            uid = getProfile().id;
        }
        let param = {
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard": state.getStorableState(),
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus: false})
    }

    handleClick = (status) => {
        this.setState({colorpickerStatus: status})
    }

    handleFlagSet = (fid) => {
        // Get the index of id in the array
        const index = this.state.flagId.indexOf(fid);
        if (index > -1) {
            // This means id is present in the array, so remove it
            this.state.flagId.splice(index, 1);
        } else {
            // This means id is not present in the array, so add it
            this.state.flagId.push(fid);
        }

        // You can use this to debug purpose

        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["flagId"] = this.state.flagId
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });

    }

    render() {

        const {
            id,
            showBasicContent, showPreview,
            dropzoneID, payload, parentID, name
        } = this.props;

        let sourceType = (payload && payload.sourceType) || false
        //console.log(sourceType)
        let showlistField = []
        let sourceData = []
        if (this.state.showlistField.length !== 0) {
            for (let i = 0; i < this.state.showlistField.pages.length; i++) {
                (this.state.showlistField.pages[i].components).map(list => {
                    showlistField.push(list)
                })
            }
        }
        var collections = ''
        const chartTitle = this.state.chartTitle || payload && payload.chartTitle;
        const subTitle = this.state.subTitle || payload && payload.subTitle;
        const min = this.state.min || payload && payload.min;
        const max = this.state.max || payload && payload.max;
        const subTitleEnabled = this.state.subTitleEnabled || payload && payload.subTitleEnabled;
        const linedata = this.state.linedata || payload && payload.linedata || graphData;
        const cardvalue = this.state.cardvalue || payload && payload.cardvalue;
        const collection = this.state.collection || payload && payload.collection;
        const linecolor = this.state.linecolor || payload && payload.linecolor || "#3AFF2D";
        const cardcolor = this.state.cardcolor || payload && payload.cardcolor;
        const valuecolor = this.state.valuecolor || payload && payload.valuecolor;
        const labelcolor = this.state.labelcolor || payload && payload.labelcolor;
        const checked = this.state.graph || payload && payload.graph || false;
        if (sourceType.toString() === "true") {
            sourceData = this.props.listAllCollections.ListAllCollections.data
        } else {
            sourceData = this.props.EntityData.Entity.data
        }
        let containerData = {
            chartTitle: this.state.chartTitle || payload && payload.chartTitle,
            subTitle: this.state.subTitle || payload && payload.subTitle,
            min: this.state.min || payload && payload.min,
            max: this.state.max || payload && payload.max,
            subTitleEnabled: this.state.subTitleEnabled || payload && payload.subTitleEnabled,
            linedata: this.state.linedata || payload && payload.linedata || graphData,
            cardvalue: this.state.cardvalue || payload && payload.cardvalue,
            collection: this.state.collection || payload && payload.collection,
            fields: this.state.fields || payload && payload.fields,
            aggregation: this.state.aggregation || payload && payload.aggregation,
            graph: this.state.graph || payload && payload.graph,
            x: this.state.x || payload && payload.x,
            y: this.state.y || payload && payload.y,
            linecolor: this.state.linecolor || payload && payload.linecolor || "#3AFF2D",
            labelcolor: this.state.labelcolor || payload && payload.labelcolor,
            cardcolor: this.state.cardcolor || payload && payload.cardcolor,
            valuecolor: this.state.valuecolor || payload && payload.valuecolor,
            checked: this.state.graph || payload && payload.graph || false,
            condition: this.state.condition || payload && payload.condition,
            whereCondition: this.state.whereCondition || payload && payload.whereCondition,
            limit: this.state.limit || payload && payload.limit,
            sourceType: sourceType,
            showlistField: showlistField,
            collectionData: sourceData,
            setDate: this.state.setDate || payload && payload.setDate,
            setFrom: this.state.setFrom || payload && payload.setFrom,
            setTo: this.state.setTo || payload && payload.setTo,
            dateFrom: this.state.dateFrom || payload && payload.dateFrom,
            getFlagData: this.state.getFlagData || this.props.getFlagData.getFlag,
            globalFilter: this.state.globalFilter || payload && payload.globalFilter,
            linechart: true,
            flagId: this.state.flagId

        }

        if (showBasicContent) {
            return (
                <Draggable {...this.props} >
                    {
                        <span className="drag-icon">{name}</span>
                    }
                </Draggable>
            )
        }

        if (showPreview) {
//////console.log(cardvalue)
            return (
                <div className="ind-small-tab-dashboard" style={{"background": cardcolor}}>
                    <div className="small-tab-header with-desc-box">
                        <h5 style={{"color": labelcolor}}>{chartTitle}</h5>
                        {subTitleEnabled &&
                        <p>{subTitle}</p>
                        }
                    </div>
                    <div className="small-tab-footer">
                        <h5 style={{"color": valuecolor}}>{cardvalue > 0 ? cardvalue : 0}</h5>
                        {/*{this.state.loading &&*/}
                        {/*<LoaderSvg/>*/}
                        {/*}*/}
                        {checked &&
                        <div className="small-tab-graph">
                            <LineChartCard data={linedata} color={linecolor} collection={collection} min={min}
                                           max={max}/>
                        </div>
                        }
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <Draggable {...this.props} draggable={!this.props.DragDisabled.dragStatus}>
                    <FormGroup className="m-0">
                        <label className="col-sm-12">
                            <span>{name}</span>
                            <div className="chart-icons">
                                <div className="settings-icon"
                                     onClick={() => this.openSetting(id, dropzoneID, parentID)}></div>
                                <div className="delete-icon"
                                     onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>
                            </div>
                        </label>
                    </FormGroup>
                </Draggable>
                {this.state.modalStatus &&
                <SettingsModal
                    title={` Summary Card  -   Settings`}
                    containerData={containerData}
                    colorpickerStatus={this.state.colorpickerStatus}
                    modaltype={8}
                    repeater={false}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    handleChange={this.handleChange}
                    checkBoxchange={this.checkBoxchange}
                    radioBoxchange={this.radioBoxchange}
                    handleConditionChange={this.handleConditionChange}
                    handleLabelChange={this.handleLabelChange}
                    handleInputChange={this.handleInputChange}
                    handleChangeComplete={this.handleChangeComplete}
                    handleFlagSet={this.handleFlagSet}
                    handleClick={this.handleClick}
                    addData={this.addData}
                    removeData={this.removeData}
                    setDate={this.setDate}
                />
                }
            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    showFormModal: state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange: state.DynamicDashboard.getDashboard,
    DragDisabled: state.DynamicDashboard.getDragDisabled,
    EntityData: state.EntityReducer.GetEntity,
    filterCnt: state.DynamicDashboard.getFilterCnt,
    linkedId: state.EntityReducer.LinkedId,
    getFlagData: state.DynamicDashboard.getFlagData,
    globalFilterCnt: state.DynamicDashboard.getGlobalFilterCnt,
    globalFilters: state.DynamicDashboard.getGlobalFilters

});
export default withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform: collectionActions.showform,
    chartSaving: DynamicDashboard.saveDashboarddata,
    dragStatus: DynamicDashboard.dragStatus,
    getFlag: DynamicDashboard.getFlag

})(LinechartContainer));
