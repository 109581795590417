import {combineReducers} from 'redux';
const listallPortfolio = (
    state = {portfolios:{data:[]}}, action) => {
    var _newState;
    switch (action.type) {
        case "LIST_All_PORTFOLIO":
            return {
                ...state,
                portfolios: {
                    data : action.listallportfolio.data.results.data
                },
            };
            break;
        case "UPDATE_PORTFOLIO":
            let portfolios = state.portfolios.data;
            if(action.newPortfolio.mode !== 'update'){
                portfolios.unshift(action.newPortfolio);
            }else{
                portfolios = portfolios.map((item) => { return ( parseInt(item.portfolioId) === parseInt(action.newPortfolio.portfolioId)) ? action.newPortfolio: item; });

            }

            return {
                ...state,
                portfolios: {
                    data : portfolios

                },
            };
            break;
        case "LIST_MORE_PORTFOLIO":
            _newState = {...state};
            _newState['portfolios'].data = _newState.portfolios.data.concat(action.listmoreportfolio.data.results.data);
            return _newState;
            break;
        case "PORTFOLIO_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        case "SEARCH_PORTFOLIO_DATA":
            return {
                ...state,
                searchResult: {
                    data : [].concat.apply([],[action.clearList ? [] :state.searchResult.data, action.listallportfolio.data.results.data])
                },
                searchValue: action.value
            };
            break;
        default:
            return state;
    }
};
const getPortfolioDetails = (state = {portfolioDet:{data:[]}}, action) => {
    switch (action.type) {
        case "GET_PORTFOLIO_DETAILS":
            return {
                ...state,
                portfolioDet: action.portfolioDet.data.results.data,
            };
            break;
        case "PORTFOLIODET_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};

const SavePortfolio = (state = {portfolioSaved : false, loading: false }, action) => {
    switch (action.type) {
        case "PORTFOLIOSAVE_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        case "SAVE_PORTFOLIO":
            return {
                ...state,
                portfolioSaved: action.portfolioSaved.data.results,
            };
            break;
        default:
            return state;
    }
};
const DeletePortfolio = (state = {portfolioDeleted : false }, action) => {
    switch (action.type) {
        case "DELETE_PORTFOLIO":
            return {
                ...state,
                portfolioDeleted: !state.portfolioDeleted
            };
            break;
        default:
            return state;
    }
};

export const Portfolio = combineReducers({
    listallPortfolio,
    SavePortfolio,
    getPortfolioDetails,
    DeletePortfolio
});

export default Portfolio;