import axios from 'axios'

import {API_PATH , ES_INDEX} from '../constants/index'
import {commonService} from "./common.services";
import {getProfile} from "../utilities/AuthService";

export const users = {
    listUsers,
    getAllUsers,
    listTodoUsers,
    listUsersDetail,
    listUsersStatics,
    listUsersActlog,
    listUsersActions,
    listUsersAllpermission,
    listUsersPermission,
    listAllUsers,
    notificationInfo,
    helpInfo,
    saveUserPermissions,
    deleteUser,
    listUserInfo,
    updatePasswordInfo,
    profileUpdate,
    profilepicUpdate,
    savePlatformhits,
    userTimespent,
    clearNotifications,
    listgroups,
    listallcompanies,
    listallDesignations,
    listallReportees,
    UserStatisticsDetail,
    saveGroup,
    addmoregroupusers,
    groupdetails,
    editGroup,
    deleteGroup,
	usersmodulewisepermission,
    listrolemodulepermission,
	userModuleUpdations,
    getModeration,
    sendEmailTousers,
    listRolecollectionDetails,
    userModulePermissionRole,
    listAllRole,
    searchEntityRoles,
    saveRolesettings,
    listRolecollectionFields,
    saveRolemodulePermission,
    organizationUsers
};


function listUsers(params){

   // params["index"]=ES_INDEX
  //  return axios.get(API_PATH+'user/allusers?uid='+params.uid+'&index='+params.index+'&search='+params.search+'&team='+params.team+'&company='+params.company+'&reportees='+params.reportees+'&designation='+params.designation, "GET");
    return commonService.sendHttpRequest('user/allusers', params, "GET");
}
function getAllUsers(params){
    return commonService.sendHttpRequest('user/allusers', params, "GET");
}

async function listTodoUsers(params){
   // params["index"]=ES_INDEX
    //params["t"]=Math.floor(Date.now() / 1000)
  // return await axios.get(API_PATH+'task/allusers', {params});
    return commonService.sendHttpRequest('task/allusers', params, "GET");
}

function listUsersDetail(uid,index){
    let params={
        "uid": uid
    }
    return commonService.sendHttpRequest('user/userdetails', params, "GET");
//    return axios.get(API_PATH + 'user/userdetails?uid='+uid+'&index='+ES_INDEX)
}

function listUserInfo(uid,profileId){
    let params={
        "uid": uid,
        "profileId" : profileId
    }
    return commonService.sendHttpRequest('user/userinfo', params, "GET");
 //   return axios.get(API_PATH + 'user/userinfo?uid='+uid+'&index='+ES_INDEX+'&profileId='+profileId+'&'+ Math.floor(Date.now() / 1000))
}


function listUsersStatics(uid,intrval,index){
    let params={
        "_id": uid,
        "intrval":intrval
    }
    return commonService.sendHttpRequest('user/userstatus', params, "GET");
    //return axios.get(API_PATH + 'user/userstatus?_id='+uid+'&intrval='+intrval+'&index='+ES_INDEX)
}

function listUsersActlog(uid,usertype,limit,index){
    let params = {
        "_id": uid,
        "type":usertype,
        "limit":limit
    }
    return commonService.sendHttpRequest('todo/gettodolog', params, "POST");
  /*  return axios.post(API_PATH + 'todo/gettodolog?',{
        "_id": uid,
        "type":usertype,
        "limit":limit,
        "index":ES_INDEX
    })*/
}
function listUsersActions(){
    //return axios.get(API_PATH+ 'user/masteractions');
    return commonService.sendHttpRequest('user/masteractions', "GET");
}

async function listUsersPermission(params){
  //  params["index"]=ES_INDEX
  //  params["t"]=Math.floor(Date.now() / 1000)
    //return await axios.get(API_PATH+'user/usermodulepermission', {params});
    return commonService.sendHttpRequest('user/usermodulepermission', params, "GET");


}
function listAllUsers(){
    let params={
        "uid": 0,
        "index":ES_INDEX,
        limit:0,
        offset:1000
    }
    return commonService.sendHttpRequest('todo/allusers', params, "POST");
 /*   return axios.post(API_PATH + 'todo/allusers',{
        "uid": 0,
        "index":ES_INDEX,
         limit:0,
        offset:1000
    })*/
}


async function notificationInfo(params){

    return commonService.sendHttpRequest('task/notification', params, "GET");
  //  params["index"]=ES_INDEX
  //  params["t"]=Math.floor(Date.now() / 1000)
  //  return await axios.get(API_PATH+'task/notification', {params});
}


function helpInfo(params){
    return commonService.sendHttpRequest('collection/collecteddata', params, "GET");
}
function clearNotifications(params){
    return commonService.sendHttpRequest('library/clearallnotifications', params, "POST");
}
function userModuleUpdations(params){
    return commonService.sendHttpRequest('user/saveusermodulepermission', params, "POST");
}
function listUsersAllpermission(params){
    console.log(params)
    return commonService.sendHttpRequest('user/masterpermission', params, "GET");
}
function usersmodulewisepermission(params){
    return commonService.sendHttpRequest('user/modulepermission', params, "GET");
}
function listrolemodulepermission(params){
    return commonService.sendHttpRequest('user/rolemodulepermission',params, "GET");
}
function updatePasswordInfo(params){
    return commonService.sendHttpRequest('user/changepassword', params, "POST");
}
function savePlatformhits(params){
    return commonService.sendHttpRequest('library/saveplatformhits', params, "POST");
}
function userTimespent(params){
    return commonService.sendHttpRequest('user/usertimespent', params, "POST");
}

function saveUserPermissions(params){
    // return axios.post(API_PATH + 'user/saveuserpermission',{
    //     "user_id": userId,
    //     "creator": creator,
    //     "permission": permissions,
    //     "mode": mode,
    //     "index":ES_INDEX
    // })
    return commonService.sendHttpRequest('user/saveuserpermission', params, "POST");
}
function deleteUser(params){
    return commonService.sendHttpRequest('user/deleteuser', params, "POST");
}


// function profileUpdate(params){
//
//     return axios.post(" https://lsinternalcopy.bsynapse.com/Login/UpdateUserProfile",{
//         "uid":params.uid,
//         "fname" : params.firstname,
//         "lname" : params.lastname,
//         "email" : params.email,
//         "phone" : params.phone,
//         "address" : params.address,
//         "password":params.password,
//         "index":params.index
//     })
// }


function profileUpdate(params){
    //////console.log(params)
    let par=btoa(params.uid+"|"+params.firstname+"|"+params.lastname+"|"+params.email+"|"+params.phone+"|"+params.address+"|"+params.password+"|"+params.index)
    //////console.log(par)

   // return axios.get("https://lsinternalcopy.bsynapse.com/Login/UpdateUserProfile/"+par);

    return commonService.sendHttpRequest('https://lsinternalcopy.bsynapse.com/Login/UpdateUserProfile', par, "GET");
}



function profilepicUpdate(userdate){

    return commonService.sendHttpRequest('user/updateuserprofilepic', userdate, "POST");
 /*   return axios.post(API_PATH+"user/updateuserprofilepic",{
        "uid":userdate.uid,
        "photo":userdate.photo,
        "index":userdate.index
    })*/
}
function listgroups(params){
    return commonService.sendHttpRequest('user/groups', params, "GET");
}
function listallcompanies(params){
    return commonService.sendHttpRequest('user/allcompanies', params, "GET");
}
function listallDesignations(params){
    return commonService.sendHttpRequest('user/designation', params, "GET");
}
function listallReportees(params){
    return commonService.sendHttpRequest('user/teamleaders', params, "GET");
}
function UserStatisticsDetail(params){
    return commonService.sendHttpRequest('user/user_statistics', params, "GET");
}
function saveGroup(params){
    return commonService.sendHttpRequest('user/savegroup', params, "POST");
}
function addmoregroupusers(params){
    return commonService.sendHttpRequest('user/moregpusers', params, "POST");
}
function groupdetails(params){
    return commonService.sendHttpRequest('user/groupdetails', params, "GET");
}
function editGroup(params){
    return commonService.sendHttpRequest('user/editgroup', params, "POST");
}
function deleteGroup(params){
    return commonService.sendHttpRequest('user/deletegroup', params, "POST");
}
function getModeration(params){
    return commonService.sendHttpRequest('login/enterprise', params, "GET");
}
function sendEmailTousers(params){
    return commonService.sendHttpRequest('user/sendactivationmail', params, "POST");
}
function listRolecollectionDetails(id, params){
    return commonService.sendHttpRequest('collection/' + id + '/collectedinfo', params, "POST");
}
function userModulePermissionRole(params){
    return commonService.sendHttpRequest('user/masterpermission', params, "GET");
}
function listAllRole(params){
    return commonService.sendHttpRequest('user/rolemodulesettings', params, "GET");
}
function searchEntityRoles(params){
    return commonService.sendHttpRequest('collection/searchentity', params, "GET");
}
function saveRolesettings(params){
    return commonService.sendHttpRequest('user/saverolemodulesettings', params, "POST");
}
function listRolecollectionFields(id, params){
    return commonService.sendHttpRequest('collection/' + id + '/collectedinfo', params, "POST");
}
function saveRolemodulePermission(params){
    return commonService.sendHttpRequest('user/saverolemodulepermission', params, "POST");
}
function organizationUsers(params){
    return commonService.sendHttpRequest('user/Userlist', params, "GET");
}