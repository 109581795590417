import React from "react";
import {API_PATH, ES_INDEX} from "../../../../../constants";
import {getProfile} from "../../../../../utilities/AuthService";
import axios from "axios";
import {ResultTable} from "../../container/entity/ResultTable";
import FullPageModal from "../../../../views/FullPageModal"

class Table extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collectionData: [],
            from: 0,
            size: 300,
            graphStatus: true,
            viewStatus: false,
            collectionFilter: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.showlistField !== prevProps.showlistField) {
            this.CollectionData()

        }

    }

    CollectionData = () => {
        //////console.log(this.props.cid)
        let obj = this
        let from = parseInt(obj.state.from) + parseInt(obj.state.collectionData.length)
        let param = {
            from: from,
            size: obj.state.size,
            index: ES_INDEX,
            search: '',
            "uid": getProfile().id,
            "usertype": getProfile().usertype,
            "dashboard": false,
            "allfields": true,
            "facetrequired": true
        }
        if ((parseInt(this.state.totalchilds - 1) >= parseInt(this.state.collectionData.length) || from === 0)) {
            axios({
                method: "POST",
                url: API_PATH + "collection/" + this.props.collection + "/collectedinfo?&index=" + ES_INDEX,
                async: false,
                data: param,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                let results = (res.data.results)
                var totalchilds = results.totalchilds
                var collectionData = [];
                var collectionIDs = [];
                var collectionFilter = [];
                var cnt = 0;
                if (results.data.length > 0) {
                    results.data.map((entry, index) => {
                        collectionData[index] = {};
                        collectionData[index]['id'] = entry.id;
                        collectionData[index]['users'] = entry.users;
                        collectionIDs.push(entry.id);
                        if (entry.collectedform) {
                            entry.collectedform.map(a => {
                                //.filter((a) => a.showlist == 'Yes')

                                collectionData[index][a.masterid] = '';

                                if (a.value && a.value[0] !== undefined) {
                                    for (var t = 0; t < a.value.length; t++) {

                                        if (a.type === "attachment") {
                                            collectionData[index][a.masterid + "uniquevalue"] = (a.value && a.value[t] !== undefined) ? a.value[t]['uniqvalue'] : '';
                                            collectionData[index][a.masterid] += (a.value && a.value[t] !== undefined) ? a.value[t]['uniqvalue'] : '';
                                            collectionData[index][a.masterid + "filename"] = (a.value && a.value[t] !== undefined) ? a.value[t]['value'] : '';
                                        } else {
                                            collectionData[index][a.masterid] += (a.value && a.value[t] !== undefined) ? a.value[t]['value'] : '';
                                        }
                                        if (t < a.value.length - 1) {
                                            collectionData[index][a.masterid] += ", ";
                                        }

                                    }

                                    collectionData[index][a.masterid] = collectionData[index][a.masterid].replace(/(^,)|(,$)/g, "");
                                }
                                // collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['value'] : '';
                                // if (a.type === "attachment") {
                                //     collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                                //     collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                                // }

                                // if (a.searchable === 'Yes') {
                                //     if (collectionFilter[a.label] === undefined) {
                                //         collectionFilter[a.label] = [];
                                //     }
                                //     cnt = collectionFilter[a.label].length;
                                //     collectionFilter[a.label][cnt] = a.value[0]['value'];
                                // }
                            });
                        }
                    });
                }

                if (results.facet.length > 0) {
                    collectionFilter = results.facet;
                }
                if (from === 0) {
                    //////console.log(collectionData)
                    obj.setState({
                        collectionData: collectionData,
                        totalchilds: totalchilds,
                        collectionFilter: collectionFilter
                    });


                } else {
                    collectionData = obj.state.collectionData.concat(collectionData)
                    //////console.log(collectionData)
                    obj.setState({collectionData: collectionData, collectionFilter: collectionFilter});
                }

                return res;
            }).catch(function (err) {
                return err;
            });
        }


    }
    ToggleGraph = () => {
        this.setState({graphStatus: !this.state.graphStatus})
    }
    settingStatus = () => {
        this.setState({viewStatus: !this.state.viewStatus})
        if (!this.state.viewStatus) {
            //this.CollectionData()

        }
    }

    editRecord(col, masterids, type, mId) {
        this.props.getselectedcollections({_id: mId, collectionid: this.props.collection, metainfo: true});

        this.props.addRecord(col, masterids, type)
    }

    render() {
        let collectionData = this.props.containerData.data
       // console.log(collectionData)
        return (

            <ResultTable
                containerData={this.props.containerData}
                showlistField={this.props.showlistField}
                collection={collectionData}
                handleSelectAll={this.props.handleSelectAll}
                showPreviewFormModal={this.props.showPreviewFormModal}
                state={this.props.state}
                handleSelectRow={this.props.handleSelectRow}
                showAssignedUsers={this.props.showAssignedUsers}
                loadmoreCollectionData={this.CollectionData}
                showFormModal={this.props.showFormModal}
                key={`result-table`}
                addRecord={this.props.addRecord}
                collId={this.props.collId}
            />

        )
    }

}

export default Table;
