import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as collectionActions from "../../../actions/Collections";
import * as taskActions from "../../../actions/ToDo";
import {ES_INDEX} from '../../../constants'
import DatePickerBox from "../../views/DatePickerBox"
import AutoComplete from "../../views/AutoComplete"
import {Form} from "react-bootstrap";
import * as usersActions from "../../../actions/Users";
import {formatDate, getUrlSegment} from "../../../utilities/CustomFunctions";
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {USRIMG_PATH} from "../../../constants";
import LoaderSvg from "../../views/LoaderSVG";
import rootActions from "../../../actions";

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
class ProjectBoardList extends Component {

    constructor(props) {
        super(props);

        this.state={
            name:'',
            description : '',
            enddate:new Date(),
            startdate:new Date(),
            projectname:'',
            clientname:'',
            project_id :0,
            selectedItems : [],
            Close:false,
            newDiscussionLoading:0,
            stageforward: 'allUser'
        };
        this.ProjectInputChange= this.ProjectInputChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.closeremarksModal = this.closeremarksModal.bind(this);
        this.handleUserSelect = this.handleUserSelect.bind(this);
        this.saveUserlist = this.saveUserlist.bind(this);

    }

    ProjectInputChange(e) {
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }


    }

    componentDidMount() {
        // this.setState({
        //     selectedItems : this.props.listtags ? this.props.listtags : this.state.selectedItems
        // });

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        var projectid = (atob(getUrlSegment(4)));
        //console.log( projectid)
        var  beta =  this;
        if(prevProps.discussionAdddata.project_discussion !== this.props.discussionAdddata.project_discussion ) {
            setTimeout(function () {
                let Ddata ={
                    "projectid": projectid,
                    "systype": getProfile().usertype,
                    "uid": getProfile().id,
                    "from": 0,
                    "size": 50,

                }
                beta.props.getprojectDiscussions(Ddata);
            }, 1000)

            this.setState({loading: 0});

        }
    }

    handleUserChange(e, userlist, assignees, discussion_id){

        // //console.log(assignees);
        // let userdetail = this.props.listAllProjectUserData.getprojectusers;
        // let user_ids = userdetail.map(a => parseInt(a.user_id));
        // //console.log(user_ids);
        // let assigneeslist =  assignees.map(a => parseInt(a.assigneeuser_id)) ;
        // //console.log(assigneeslist);

        this.setState({
            userlist: userlist,
            // user_ids: user_ids,
            // assigneeslist: assigneeslist,
            // discussion_id: discussion_id
        })
    }
    closeremarksModal(){
        this.setState({userlist : false});
    }
    handleUserSelect(e, selectUserid){

        selectUserid = parseInt(selectUserid);
        //console.log(this.state.assigneeslist )
        // let assigneeslist = [...this.state.assigneeslist];
        // (assigneeslist.indexOf(selectUserid) > -1) ? assigneeslist.splice(assigneeslist.indexOf(selectUserid), 1) : assigneeslist.push(selectUserid);
        // this.setState({assigneeslist: assigneeslist});

    }
    saveUserlist(){

        //console.log(this.state.assignee_ids )
        var assigneeslist = this.state.assigneeslist;
        var discussion_id = this.state.discussion_id;
        var projectid = (this.state.projectid =='0' ? moment().valueOf() : this.state.projectid);

        let assignmoreusers = {
            projectid: projectid,
            discussion_id: discussion_id,
            assignees: assigneeslist,
        };

        //console.log(assignmoreusers);
        // this.props.saveAssignedusers(assignmoreusers);
        this.setState({userlist : false});

    }

    render() {
        // //console.log(this.state.edistartdate);
        let users = this.props.listtags? this.props.listtags : '';
        let userlength = this.props.userlength;
        let userdetail = this.props.listAllProjectUserData.getprojectusers;

        return (
            <div className="board-header">
                <div className="board-header-top">
                    <h5>Board Name Here <span className="board-count">(3)</span></h5>
                    <button className="ico-lib-v1 list-edit-ico"></button>
                </div>
                <ul className="pj-added-user">
                    <li>
                        <div className="ind-added-user-list">
                            <img src="https://bsynapse.imgix.net/images/2_5cfa0b679e3ad_thumb.jpg" />
                        </div>
                    </li>
                    <li>
                        <div className="ind-added-user-list">
                            <img src="https://bsynapse.imgix.net/images/2_5cfa0b679e3ad_thumb.jpg" />
                        </div>
                    </li>
                    <li onClick={(e)=>this.handleUserChange(e, 'true', '', '')} data-target="#disUserlist" data-id="146850132206">
                        <button className="float-add-button" ></button>
                    </li>
                </ul>


                {this.state.userlist === 'true' &&
                <div className="modal fade bsy-modal s-modal show x-large"  tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Board List</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closeremarksModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="mang-lbl-block un-tick">
                                    <div className="user-list-container" id="Usrlst">

                                        {userdetail.map((list,index) =>
                                            <div key={index} className='selected-user'>
                                                <div className="ind-user-list" data-action="filter"
                                                     data-hideuser={list.user_id}>
                                                    <div className="ind-user-list-left">
                                                        <div className="ind-user-img"><img
                                                            src={USRIMG_PATH + list.img_name} />
                                                        </div>
                                                        <div className="ind-user-name" onClick={(e)=>this.handleUserSelect(e, list.user_id, )}>
                                                            {list.first_name + ' ' + list.last_name}</div>
                                                    </div>
                                                    <div className="ind-user-list-right">
                                                        <div className="ind-user-list-right">
                                                            <div className="usr-select-button" style={{display:'none'}}><input
                                                                name="text_searchable" type="checkbox" value={list.user} className="input-check" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="general-btn grey" data-dismiss="modal"  onClick={this.closeremarksModal}>Cancel</button>
                                <button type="button" className="general-btn" onClick={this.saveUserlist}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                }


            </div>


        );
    }
}


const mapStateToProps = state => ({
    projectdiscussionsdata:state.ProjectReducer.GetProjectDiscussions,
    discussionAdddata:state.ProjectReducer.SaveProjectDiscussion,
    listAllProjectUserData:state.ProjectReducer.GetProjectUsers,
    userlistAssignmoredata:state.ProjectReducer.SaveAssignedUsers,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getprojectDiscussions : projectActions.getProjectDiscussions,
    projectuserdetail:projectActions.getProjectUsers,
    saveAssignedusers:projectActions.saveAssignedUsers,

})(ProjectBoardList));



