import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {Container} from "react-bootstrap";
import '../../../dashboardBuilder/App.css';
import {Scrollbars} from "react-custom-scrollbars";
import {
  Canvas,
  state,
  Preview,
  registerPaletteElements
} from 'react-page-maker';

import { elements } from './const';
import LabelContainer from './elements/container/LabelContainer';
import DescriptionContainer from './elements/container/DescriptionContainer';
import ImageContainer from './elements/container/ImageContainer';
import FlexibleContent from './elements/container/FlexibleContent';
import GoogleMap from './elements/container/GoogleMap';
// import List from './elements/container/List';
import List from "../../../dashboardBuilder/elements/container/entity/List"
import GeGroupTable from "../../../dashboardBuilder/elements/container/entity/GeGroupTable";
import DraggableCardLayout from "./elements/DraggableCardLayout";
import TitleContainer from "./elements/container/TitleContainer";
import ListCard from "./elements/container/ListCard";
import VideoContainer from "./elements/container/VideoContainer";
// import AccordionContainer from "./elements/container/Accordion"
import AccordionContainer from "./elements/container/GeMobileAccordion";
import ButtonblockContainer from "./elements/container/ButtonblockContainer"
import BarchartContainer from '../../../dashboardBuilder/elements/container/BarchartContainer';
import PieChartContainer from '../../../dashboardBuilder/elements/container/PieChartContainer';
import LinechartContainer from '../../../dashboardBuilder/elements/container/LinechartContainer';
import AreachartContainer from '../../../dashboardBuilder/elements/container/AreachartContainer';
import AreaBarChartContainer from '../../../dashboardBuilder/elements/container/AreaBarChartContainer';
import FaqContainer from '../../../dashboardBuilder/elements/container/FaqContainer';
import {getUrlSegment, parseDataDetail} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {ES_INDEX} from "../../../../constants";
import rootActions from "../../../../actions";
import * as collectionActions from "../../../../actions/Collections";
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import * as entityActions from "../../../../actions/Entity";
import * as formActions from "../../../../actions/Forms";
import PaymentContainer from "../../../dashboardBuilder/elements/container/PaymentContainer";
import ShowFormModal from "../../../dashboardBuilder/ShowFormModal";
import * as whatsappActions from "../../../../actions/Whatsapp";

let initialElements=[]
class GrowthMobView extends Component {
  constructor(props) {
    super(props);

    // register all palette elements
    registerPaletteElements([
      {
      type: elements.LABEL,
      component: LabelContainer
      },
      {
      type: elements.DESCRIPTION,
      component: DescriptionContainer
      },
      {
        type: elements.IMAGE,
        component: ImageContainer
      },
      {
        type: elements.FLEXIBLECONTENT,
        component: FlexibleContent
      },
      {
        type: elements.GOOGLEMAP,
        component: GoogleMap
      },
      {
        type: elements.CARD_LAYOUT,
        component: DraggableCardLayout
      },
      {
        type: elements.TITLE,
        component: TitleContainer
      },
      {
        type: elements.LISTCARD,
        component: List
      },
      {
        type: elements.VIDEOCARD,
        component: VideoContainer
      },
      {
        type: elements.BAR_CHART_CONTAINER,
        component: BarchartContainer
      },

      {
        type: elements.PIE_CHART_CONTAINER,
        component: PieChartContainer
      },

      {
        type: elements.AREA_CHART_CONTAINER,
        component: AreachartContainer
      },
      {
        type: elements.AREA_BAR_CHART_CONTAINER,
        component: AreaBarChartContainer
      },
      {
        type: elements.GROUP_TABLE,
        component: GeGroupTable,
      },
      {
        type: elements.LINE_CHART_CONTAINER,
        component: LinechartContainer
      },
      {
        type: elements.PAYMENT_CONTAINER,
        component: PaymentContainer
      },
      {
        type: elements.ACCORDION,
        component: AccordionContainer
      },
      {
        type: elements.BUTTON_BLOCK,
        component: ButtonblockContainer
      },
      {
        type: elements.FAQ_CONTAINER,
        component: FaqContainer
      },




    ]);

    // state.clearState() triggers this event
    state.addEventListener('flush', (e) => {
      //console.log('flush', e);
    });

    // state.removeElement() triggers this event
    state.addEventListener('removeElement', (e) => {
      //console.log('removeElement', e);
    });

    // state.updateElement() triggers this event
    state.addEventListener('updateElement', (e) => {
      //console.log('updateElement', e);
    });
  }

  state = {
    sharetype : 'private',
    editData:[],
    loading:true,
    expandHeader:false,
    toasterdata:{type:"",msg:"",show:false},
    showFormModal:false,
    activeTab: '1',
    currentState: [],
    field:1,
    uniqueId:1,
    initialElements:[],
    showSummaryStatus : false,
    collectionId : '',
    checkedRow : [],
    validateFlag:false,
    validateFlagModal:false,
    flagPan:false,
  }
  componentDidMount() {
    let collectionId = getUrlSegment(3) ? atob(getUrlSegment(3)) :  this.props.entityId;
    let leadRecordId = this.props._id;
    this.props.LinkedId(this.props._id);
    this.props.LinkedCollectionId(this.props.entityId);
    this.props.LinkedCollectionName(this.props.leadName);
    let checkedRow = this.state.checkedRow || [];
    checkedRow.push(this.props._id)
    initialElements=(this.props.templateData.filter(list=>(list.name  === true || list.name  === "true" || list.name  === "gemobile")).length !==0?this.props.templateData.filter(list=>(list.name  === true || list.name  === "true" || list.name  === "gemobile"))[0].template:[])
    this.setState({dashboardLoader:false,initialElements:initialElements, collectionId : collectionId, checkedRow: checkedRow, leadRecordId : leadRecordId})
    let params = {uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : 0, offset : 500};
    this.props.collections(params);
    let Entity = {uid : getProfile().id, usertype : getProfile().usertype, searchtext : this.state.search, page: 1, per_page: 50, index: ES_INDEX,  managecollection : ''};
    this.props.searchEntity(Entity);
    this.props.showEntityform({ _id : collectionId})
    if(getUrlSegment(1) === 'whatsappchat'){
      this.props.getFormDat({ _id : leadRecordId, collectionid : collectionId,metainfo: true})
      this.props.getLeadData({ _id : leadRecordId, collectionid : collectionId, metainfo : true});
    }
    //console.log(this.props._id)

  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.leadName !== this.props.leadName) {
      this.props.LinkedCollectionName(this.props.leadName);
    }
    if(prevProps.entityId !== this.props.entityId) {
      this.props.LinkedCollectionId(this.props.entityId);
    }
    if(this.props.templateData !== prevProps.templateData ){
      initialElements=(this.props.templateData.filter(list=>(list.name  === true || list.name  === "true" || list.name  === "gemobile")).length !==0?this.props.templateData.filter(list=>(list.name  === true || list.name  === "true" || list.name  === "gemobile"))[0].template:[])
      this.setState({initialElements:initialElements})
    }
    if(prevProps._id !== this.props._id) {
      this.props.LinkedId(this.props._id);
      if(getUrlSegment(1) === 'whatsappchat'){
        this.props.getFormDat({ _id : this.props._id, collectionid : this.props.entityId, metainfo: true})
        this.props.getLeadData({ _id : this.props._id, collectionid : this.props.entityId, metainfo : true});
      }
    }
    if(this.props.SaveDashboardMenus !== prevProps.SaveDashboardMenus){
      if(this.props.SaveDashboardMenus.data !== ""){
        this.setState({toasterdata:{type:"Green",msg:"Dashboard menu updated successfully",show:true}})
      }
      else{
        this.setState({toasterdata:{type:"red",msg:"Something went wrong",show:true}})
      }

    }
    if(this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged) {
      let obj=this
      setTimeout(function () {
        if(obj.state.syncType === "mainentity") {
          obj.props.getFormData({ _id : obj.state.refId, collectionid : obj.state.cid,metainfo: true, wfinfo:true});
        }
        else{
          obj.props.syncRec(obj.state.showFormModal)
        }
      }, 1000)
    }
    if(this.props.getaddRecord !== prevProps.getaddRecord){

      this.props.showform({ _id : this.props.getaddRecord.cid});
      let linkedFields = this.props.getaddRecord.masterid;
      let formvalues = this.props.getFormdetails.formvalues;
      let fieldValue = '';
      if(linkedFields && formvalues) {

        linkedFields = linkedFields.map(item => {
          let thisFieldArr = [];

          if(item.default) {
            let thisFieldArr = formvalues.filter( val=> val.masterid === item.default);
            if(thisFieldArr.length >0 && thisFieldArr[0].values && thisFieldArr[0].values.length>0 ){
              if(thisFieldArr[0].type === 'text' || thisFieldArr[0].type === 'number' || thisFieldArr[0].type === 'email'){
                fieldValue = thisFieldArr[0].values[0].value;
              }
            }
          }
          item.value = fieldValue;
          return item;
        });
      }
      this.setState({showFormModal:!this.state.showFormModal,cid:this.props.getaddRecord.cid,masterid:linkedFields,act:this.props.getaddRecord.act,refId:this.props.getaddRecord.refId,syncType:this.props.getaddRecord.syncType})
    }
    if(this.props.getScrollElement !== prevProps.getScrollElement){
      this.scrollElement(this.props.getScrollElement.id)
    }


    if(this.props.getFormdetails !== prevProps.getFormdetails) {

      this.getFormValues();
      //For leads only
      let leadRecData = this.props.getFormdetails;
      let followupCallsList = [];
      let lead_id = '';
      let program = '';

      let profileValues = [];

      if(leadRecData.formvalues) {
        let formData = leadRecData.formvalues;
        profileValues = parseDataDetail(formData);

        let leadIdField = formData.filter(item => item.masterid === "1500062073");
        let programField = formData.filter(item => item.masterid === "396730219");
        if(leadIdField.length > 0){
          if(leadIdField[0].values && leadIdField[0].values.length>0) {
            lead_id = leadIdField[0].values[0].value;
          }
        }
        if(programField.length > 0){
          if(programField[0].values && programField[0].values.length>0) {
            program = programField[0].values[0].value;
          }
        }
        let followupFldSet = formData.filter(item => item.masterid === "488326912");
        if(followupFldSet.length>0) {
          if(followupFldSet[0].values && followupFldSet[0].values.length>0) {
            let fields = followupFldSet[0].values;
            if(fields.length >0) {
              for(var i=0; i< fields.length; i++) {
                if(fields[i].masterid === "641650952") { //Follow up category
                  if(fields[i].values && fields[i].values.length > 0) {
                    let k=0;
                    for(var j =0; j< fields[i].values.length;j++) {

                      if(fields[i].values[j].value !== '') {
                        followupCallsList[k] = followupCallsList[k] || {};
                        followupCallsList[k]['category'] = fields[i].values[j].value;
                        k++;
                      }

                    }
                  }
                }
                if(fields[i].masterid === "910893713") { //Follow up date
                  if(fields[i].values && fields[i].values.length > 0) {
                    let k=0;
                    for(var j =0; j< fields[i].values.length;j++) {
                      if(fields[i].values[j].value !== '') {
                        followupCallsList[k] = followupCallsList[k] || {};
                        followupCallsList[k]['date'] = fields[i].values[j].value;
                        k++;
                      }
                    }
                  }
                }
                if(fields[i].masterid === "1500855405") { //Follow up status
                  if(fields[i].values && fields[i].values.length > 0) {
                    let k=0;
                    for(var j =0; j< fields[i].values.length;j++) {
                      if(fields[i].values[j].value !== '') {
                        followupCallsList[k] = followupCallsList[k] || {};
                        followupCallsList[k]['status'] = fields[i].values[j].value;
                        k++;
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }

      this.setState({
        followupCallsList : followupCallsList,
        lead_id :lead_id,
        program :program,
        profileValues : profileValues
      })
    }


    if(this.props.showFormModalData.showformdata !== prevProps.showFormModalData.showformdata) {
      let collectionid = this.state.collectionId;
      if( collectionid === '1600230072115' || collectionid === '1614837782109') {
        let locationComponentArr = [];
        let genderComponentArr = [];
        let genderOptions = [];
        let jobComponentArr = [];
        let jobOptions = [];
        let followupCategories = [];
        let profilePage = (this.props.showFormModalData.showformdata.pages && this.props.showFormModalData.showformdata.pages.length > 0) ?
            this.props.showFormModalData.showformdata.pages[0].components : [];
        if (profilePage.length > 0) {
          let followupComponentArr = profilePage.filter(item => item.masterid === '641650952');
          if (followupComponentArr.length > 0) {
            followupCategories = followupComponentArr[0].option;

          }

          genderComponentArr = profilePage.filter(item => item.masterid === '264162770');
          if (genderComponentArr.length > 0) {
            genderOptions = genderComponentArr[0].option;

          }
          jobComponentArr = profilePage.filter(item => item.masterid === '1168206831');
          if (jobComponentArr.length > 0) {
            jobOptions = jobComponentArr[0].option;

          }

          locationComponentArr = profilePage.filter(item => item.masterid === '1342905233');
          if (locationComponentArr.length > 0) {

            let collectionIds = [];
            let fieldIds = [];
            let displayFieldIds = [];
            let additionalvaluesIds = [];

            let externals = locationComponentArr[0].externalfield;

            if (externals.length > 0) {
              for (var i = 0; i < externals.length; i++) {
                collectionIds.push(externals[i].collection);
                fieldIds.push(externals[i].field);
                if (externals[i].display && externals[i].display.length > 0) {
                  displayFieldIds = displayFieldIds.concat(externals[i].display);
                }
                if (externals[i].displayvalue && externals[i].displayvalue.length > 0) {
                  additionalvaluesIds = additionalvaluesIds.concat(externals[i].displayvalue);
                }
              }
            }
            let displayfields = "";
            let additionalvalues = "";
            if (displayFieldIds.length > 0) {
              displayfields = displayFieldIds.toString();
            }
            if (additionalvaluesIds.length > 0) {
              additionalvalues = additionalvaluesIds.toString();
            }
            let data = {
              masterid: '1342905233',
              collection: collectionIds.toString(),
              field: fieldIds.toString(),
              displayfields: displayfields,
              additionalvalues: additionalvalues,
              search: this.state.searchloc,
              size: 500
            };
            this.props.getExternalValues(data);
            //locationOptions = locationComponentArr[0].option;
          }
        }
        this.setState({

          followupCategories: followupCategories,
          locationComponentArr: locationComponentArr,
          genderOptions: genderOptions,
          jobOptions : jobOptions
        })
      }

      if(this.state.showQuickform === true && this.state.quickformid !== '' && this.props.showFormModalData.showformdata.length !==0) {
        this.showFormModal();
        this.setState({roleid:'', quickformid:''})
      }

    }
    if(this.props.externalData !== prevProps.externalData) {
      if (this.props.externalData.externalFields.masterField === '1342905233') {
        let locationOptions = [];
        let externalValues = this.props.externalData.externalFields.values;
        let collection = (this.state.locationComponentArr.length > 0 && this.state.locationComponentArr[0].externalfield.length > 0) ? this.state.locationComponentArr[0].externalfield[0].collection : [];
        let optArray = [];
        if (Array.isArray(externalValues)) {
          optArray = externalValues.map(external => {
            external.value[0]['value'] = external.value[0].value;
            external.value[0]['displayvalue'] = '';
            external.value[0]['recordid'] = external.recordid;
            external.value[0]['collection'] = collection.toString();
            external.value[0]['fieldid'] = external.fieldid;
            return external.value;
          });
        }
        let optValues = [].concat.apply([], optArray);
        locationOptions = optValues;

        this.setState({
          locationOptions: locationOptions
        });
      }
    }
  }
  componentWillMount() {
    state.addEventListener('change', this._stateChange);
  }

  componentWillUnmount() {
    state.removeEventListener('change', this._stateChange);
  }

  getFormValues(){
    let formData = this.props.getFormdetails.formvalues;
    let meta = this.props.getFormdetails.meta;
    let submissionType = this.props.getFormdetails.draft ? 'Draft' :'';
    let formVersions = this.props.getFormdetails.versions;
    let id = this.props.getFormdetails.id;
    let wfDetails = this.props.getFormdetails.wfinfo;
    // this.loadTaskInfoStagewise();
    this.setState({formData : formData,meta:meta,id:id,formVersions:formVersions, submissionType : submissionType, wfDetails :wfDetails});
  }
  scrollElement=(id)=>{
    const items = document.getElementById(id);
    items.scrollIntoView({ block: 'start',  behavior: 'smooth' });
  }
  hideFormModal=()=>{
    this.props.resetForm()
    this.setState({showFormModal:!this.state.showFormModal})
  }

  render() {
    //console.log(this.state.initialElements)

    return (
        <>
          <div style={{"display": "none"}}>
            <Canvas
                onDrop={this._onDrop}
                initialElements={this.state.initialElements}
                placeholder="Drop Here"
                recordId = {this.props._id}
            />
          </div>

          {getUrlSegment(1) === 'whatsappchat' ?
            <Preview>
              {
                ({children}) => (
                    <Container>
                      {children}
                    </Container>
                )
              }
            </Preview>
            :
            <Scrollbars
                style={{width: '100%', height: 'calc(100% - 70px)'}}
            >
              <Preview>
                {
                  ({children}) => (
                      <Container>
                        {children}
                      </Container>
                  )
                }
              </Preview>
              {this.state.showFormModal &&
              <ShowFormModal formName={""}
                             collectionId ={this.state.cid}
                             referenceId ={this.state.cid}
                             previewSource={`entity`}
                             hideFormModal={this.hideFormModal}
                             type={`dashboard`}
                             masterid={this.state.masterid}
                             action={this.state.act}
                             editId={this.state.refId}
                             showFormModal={this.props.getFormdetails}
                             mapid={this.props._id}
                             formPages={[]}
              />
              }
            </Scrollbars>
          }

        </>
    );
  }
}
const mapStateToProps = state => ({
  entityData: state.EntityReducer.listCollectionDetaildata,
  searchData:state.CommonReducer.searchparamdata,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  DashboardMenus: state.DynamicDashboard.DashboardMenus,
  SaveDashboardMenus:state.DynamicDashboard.SaveDashboardMenus,
  getFormdetails : state.CollectionsReducer.getFormData,
  getaddRecord : state.DynamicDashboard.getaddRecord,
  getSavedForm : state.CollectionsReducer.getSavedForm,
  getScrollElement : state.DynamicDashboard.getScrollElement,
  showFormModalData:state.EntityReducer.showFormModal,
  externalData : state.CollectionsReducer.getExternalForm,
  formDetails : state.WhatsappReducer.getFormDetails,


});
export default  withRouter(connect(mapStateToProps, {
  mainClick:rootActions.commonActions.MenuClick,
  collections: collectionActions.listallcollections,
  trackURL: DynamicDashboard.trackSharedLink,
  searchEntity : entityActions.Entity,
  addDashboard:DynamicDashboard.addDashboard,
  LinkedId:entityActions.LinkedId,
  LinkedCollectionId:entityActions.LinkedCollectionId,
  LinkedCollectionName:entityActions.LinkedCollectionName,
  syncRec:DynamicDashboard.syncRec,
  showform : formActions.showform,
  resetForm:formActions.resetForm,
  getFormData : entityActions.getFormData,
  getFormDat : collectionActions.getFormData,
  getLeadData : whatsappActions.getFormData,
  showEntityform : entityActions.showform,
  getExternalValues : collectionActions.getExternalValues,



})(GrowthMobView));
