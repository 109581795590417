import React, {Component, useState, useEffect} from 'react';
import 'react-selectize/themes/index.css'
import {Accordion, Card} from "react-bootstrap"
import moment from 'moment';
import {ES_INDEX, NEW_VERSION, SHARED_LOGIN} from "../../../constants";
import {getProfile} from "../../../utilities/AuthService";
import OutsideAlerter from "../../views/OutsideAlerter";
import DateTimePickerBox from "../../views/DateTimePickerBox";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import RangeSlider from "../../views/RangeSlider";
import LoaderSvg from "../../views/LoaderSVG";
import {Dropdown, Tab, Tabs} from "react-bootstrap";
import AdvancedSearch from "../../views/AdvancedSearch";

class FilterTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            active: ""
        }
    }

    handleFilterClick = (item, masterid) => {
        this.props.handlefilterSearch(item, masterid);
        this.setState({masterid: masterid})
    }
    actionStatus = (sTa) => {
        this.setState({active: sTa})
    }

    render() {

        return (
            <div className="one-line-stretch">
                <div className="filter-tag-add-block">
                    <div className="filter-tag-block-left inline-filter">
                        <h5 className="search-text MycollCount">Results Found ({this.props.totalCount})</h5>
                        {this.props.setFilter === "" &&
                        <div className={`tag-list`}>
                            {this.props.filterDate !== '' &&
                            <div className="filter-tag-block" id="filtered_values">
                                        <span className="filter-tag"
                                              id="filter_837793876AEDPA4925R">{this.props.filterDate}
                                            <button className="tag-close" data-action="remove-filter" data-master=""
                                                    onClick={(e) => this.props.dateWise(e, "reset")}
                                                    data-type="checkbox"> </button>
                                        </span>
                            </div>
                            }
                            {this.props.fromdate !== '' &&
                            <div className="filter-tag-block" id="filtered_values">
                                        <span className="filter-tag"
                                              id="filter_837793876AEDPA4925R">{this.props.fromdate}
                                            <button className="tag-close" data-action="remove-filter" data-master=""
                                                    onClick={(e) => this.props.dateWise(e, "", "fromdate")}
                                                    data-type="checkbox"> </button>
                                        </span>
                            </div>
                            }
                            {this.props.todate !== '' &&
                            <div className="filter-tag-block" id="filtered_values">
                                        <span className="filter-tag" id="filter_837793876AEDPA4925R">{this.props.todate}
                                            <button className="tag-close" data-action="remove-filter" data-master=""
                                                    onClick={(e) => this.props.dateWise(e, "", "todate")}
                                                    data-type="checkbox"> </button>
                                        </span>
                            </div>
                            }
                            {this.props.daysfilters.map((list, index) =>

                                <div className="filter-tag-block" key={"filtered_values" + index}>
                                        <span className="filter-tag">{list.label}
                                            <button className="tag-close"
                                                    onClick={(e) => this.props.daysFilter("", "", "", list.labelFrom, "daysfilterReset")}
                                                    data-type="checkbox"> </button>
                                        </span>
                                </div>
                            )}

                            {/*{this.props.datefilters.length !==0&& this.props.customizeDateFrom ==="" && this.props.customizeDateTo === "" &&*/}
                            {/*{this.props.datefilters.map((list,index)=>*/}
                            {/*    <div className="filter-tag-block" key={"filtered_values"+index}>*/}
                            {/*            <span className="filter-tag" id="filter_837793876AEDPA4925R">{list.label}*/}
                            {/*                <button className="tag-close" onClick={(e)=>this.props.daysFilter("","","",list.labelFrom,"datefilterReset")}  data-type="checkbox"> </button>*/}
                            {/*            </span>*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            {this.props.datefilters.map((list, index) =>
                                    <div className="filter-tag-block" key={"filtered_values" + index}>
                                        {list.label !== "" &&
                                        <div className="filter-tag-block" key={"filtered_values12" + index}>
                            <span className="filter-tag">{list.label}
                                <button className="tag-close"
                                        onClick={(e) => this.props.daysFilter("", "", "", list.labelFrom, "datefilterReset")}
                                        data-type="checkbox"> </button>
                                    </span>
                                        </div>
                                        }
                                        {list.from !== "" && list.label === "" &&
                                        <div className="filter-tag-block" key={"filtered_values2" + index}>
                            <span className="filter-tag">{list.from}
                                <button className="tag-close"
                                        onClick={(e) => this.props.daysFilter("", "", "from", list.id, "datefilterReset")}
                                        data-type="checkbox"> </button>
                                    </span>
                                        </div>
                                        }
                                        {list.to !== "" && list.label === "" &&
                                        <div className="filter-tag-block" key={"filtered_values3" + index}>
                            <span className="filter-tag">{list.to}
                                <button className="tag-close"
                                        onClick={(e) => this.props.daysFilter("", "", "to", list.id, "datefilterReset")}
                                        data-type="checkbox"> </button>
                                    </span>
                                        </div>
                                        }

                                    </div>
                            )}


                            {/*{this.props.datefilters.length !==0&&*/}
                            {/*<>*/}
                            {/*    {this.props.customizeDateFrom !== "" &&*/}
                            {/*    <div className="filter-tag-block" id="filtered_values">*/}
                            {/*        <span className="filter-tag" id="filter_837793876AEDPA4925R">{this.props.customizeDateFrom}*/}
                            {/*            <button className="tag-close"*/}
                            {/*                    onClick={(e) => this.props.daysFilter("", "", "", "", "datefilterReset")}*/}
                            {/*                    data-type="checkbox"> </button>*/}
                            {/*        </span>*/}
                            {/*    </div>*/}
                            {/*    }*/}
                            {/*    {this.props.customizeDateTo !== "" &&*/}
                            {/*    <div className="filter-tag-block" id="filtered_values">*/}
                            {/*    <span className="filter-tag" id="filter">{this.props.customizeDateTo}*/}
                            {/*        <button className="tag-close"*/}
                            {/*                onClick={(e) => this.props.daysFilter("", "", "", "", "datefilterReset")}*/}
                            {/*                data-type="checkbox"> </button>*/}
                            {/*    </span>*/}
                            {/*    </div>*/}
                            {/*    }*/}
                            {/*</>*/}
                            {/*}*/}

                            {this.props.FilterLabel.map((list, index) =>
                                <div className="filter-tag-block" id="filtered_values" key={"filter-tag-label" + index}>

                                <span className="filter-tag">{list.value === "" ? "Empty" : list.value}
                                    <button className="tag-close"
                                            onClick={() => this.handleFilterClick(list.value, list.id)}>
                                    </button>
                                </span>

                                </div>
                            )}
                            {this.props.AssigneLabel.map((list, index) =>
                                <div className="filter-tag-block" id="filtered_values" key={"filter-tag-label" + index}>
                                          <span className="filter-tag">{list.value}
                                              <button className="tag-close"
                                                      onClick={() => this.props.handleAssigneClick(list.value, list.id)}>
                                              </button>
                                          </span>
                                </div>
                            )}

                            {this.props.setFilter === "" &&
                            <div className={`filter-tag-block-right`}>
                                {(this.props.filter.length !== 0 || this.props.filterDate !== '' || this.props.datefilters.length !== 0 || this.props.todate !== '' || this.props.fromdate !== '' || this.props.datefilters.length !== 0 || this.props.filterDate !== '' || this.props.AssigneLabel.length !== 0) &&
                                <span className="clear-all" onClick={() => this.handleFilterClick("clear", "")}>
                                    Clear
                                </span>
                                }

                            </div>
                            }


                        </div>
                        }
                    </div>

                </div>
                {this.props.default_template !== "list" && this.props.default_template !== undefined && this.props.setFilter === "" &&
                <ul className="resources-filters-icons">

                    {this.props.default_template === "vertical" &&
                    <li>
                        <nav
                            className={`rs-tab-btn ico-lib-v1 rc-tab-grid ${(this.state.active === "" ? this.props.default_template : this.state.active) === "vertical" ? "active" : ""}`}
                            onClick={(e) => {
                                this.props.handleViewChange("vertical");
                                this.actionStatus("vertical")
                            }}></nav>
                    </li>
                    }

                    {this.props.default_template === "horizontal" &&
                    <li>
                        <nav
                            class={`rs-tab-btn ico-lib-v1 rc-tab-grid ${(this.state.active === "" ? this.props.default_template : this.state.active) === "horizontal" ? "active" : ""}`}
                            onClick={(e) => {
                                this.props.handleViewChange("horizontal");
                                this.actionStatus("horizontal")
                            }}>

                        </nav>
                    </li>
                    }

                    {this.props.default_template === "mini" &&
                    <li>
                        <nav
                            className={`rs-tab-btn ico-lib-v1 rc-tab-grid ${(this.state.active === "" ? this.props.default_template : this.state.active) === "mini" ? "active" : ""}`}
                            onClick={(e) => {
                                this.props.handleViewChange("mini");
                                this.actionStatus("mini")
                            }}>

                        </nav>
                    </li>
                    }

                    {this.props.default_template === "map" &&
                    <li>
                        <nav
                            class={`rs-tab-btn ico-lib-v1 rc-tab-map ${(this.state.active === "" ? this.props.default_template : this.state.active) === "map" ? "active" : ""}`}
                            onClick={(e) => {
                                this.props.handleViewChange("map");
                                this.actionStatus("map")
                            }}></nav>
                    </li>
                    }

                    {this.props.default_template === "long1" &&
                    <li>
                        <nav
                            className={`rs-tab-btn ico-lib-v1 rc-tab-grid ${(this.state.active === "" ? this.props.default_template : this.state.active) === "long1" ? "active" : ""}`}
                            onClick={(e) => {
                                this.props.handleViewChange("long1");
                                this.actionStatus("long1")
                            }}>

                        </nav>
                    </li>
                    }


                    <li>
                        <nav
                            class={`rs-tab-btn ico-lib-v1 rc-tab-list ${(this.state.active === "" ? this.props.default_template : this.state.active) === "list" ? "active" : ""}`}
                            onClick={(e) => {
                                this.props.handleViewChange("list");
                                this.actionStatus("list")
                            }}></nav>
                    </li>


                </ul>
                }
            </div>)
    }
}

class EntityDetailData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filter: [],
            collapsed: 0,
            masterid: ''
        }
    }

    handleFilterClick = (item, masterid) => {
        this.props.handlefilterSearch(item, masterid);
        this.setState({masterid: masterid})
    }

    toggleAccordion(id) {
        let collapsed = (this.state.collapsed === id) ? -1 : id;
        this.setState({collapsed: collapsed})
    }

    overlayShow = () => {
        document.body.classList.add('layover-bg');
    }
    overlayHide = () => {
        document.body.classList.remove('layover-bg');
    }
    handleFromChange = (date) => {
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        this.props.dateRange(formattedDate, "fromdate")
    }
    handleToChange = (date) => {
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        this.props.dateRange(formattedDate, "todate")
    }
    handleFromCustomdateRange = (date, masterid) => {
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        this.props.daysFilter("fromdate", formattedDate, "", masterid, "")
    }
    handleToCustomdateRange = (date, masterid) => {
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        this.props.daysFilter("todate", formattedDate, "", masterid, "")
    }

    camelize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {
        let filterString = this.props.filter.length !== 0 ? this.props.filter : [];
        let collectionFilter = this.props.filterData ? this.props.filterData : [];
        return (
            <div className="label-menu-right-block filter-top">
                <div className="filter-accord create-date accordion">
                    <div className="card">
                        {this.props.showdatefilter &&
                        <React.Fragment>
                            <div className="card-header" id="dateheading">
                                <h5 className="mb-0">
                                    <button className="btn btn-link  " aria-expanded="false"
                                            aria-controls="collapse1"> Created Date
                                    </button>
                                </h5>
                            </div>
                            <div id="date_heading" className="collapse show" aria-labelledby="dateheading"
                                 data-parent="#accordion">
                                <div className="card-body">
                                    <div className="radio-wrapper filter-block createdatefilter"
                                         data-master="datacreateddate">
                                        <div className=" radio-block">
                                            <input type="radio" name="dateFilter" value="today"
                                                   checked={this.props.filterDate === 'today'}
                                                   onClick={(e) => this.props.dateWise(e)}/>
                                            <label>Today</label>
                                        </div>
                                        <div className="  radio-block">
                                            <input type="radio" name="dateFilter" value="7"
                                                   checked={this.props.filterDate === 'Last  7 days'}
                                                   onClick={(e) => this.props.dateWise(e, "days", "Last  7 days")}/>
                                            <label>Last 7 days</label>
                                        </div>
                                        <div className=" radio-block">
                                            <input type="radio" name="dateFilter" value="30"
                                                   checked={this.props.filterDate === 'Last  30 days'}
                                                   onClick={(e) => this.props.dateWise(e, "days", "Last  30 days")}/>
                                            <label>Last 30 days</label>
                                        </div>
                                        <div className=" radio-block">
                                            <input type="radio" name="dateFilter" value="3"
                                                   checked={this.props.filterDate === 'Last  3 months'}
                                                   onClick={(e) => this.props.dateWise(e, "months", "Last  3 months")}/>
                                            <label>Last 3 months</label>
                                        </div>
                                        <div className=" radio-block">
                                            <input type="radio" name="dateFilter" value="6"
                                                   checked={this.props.filterDate === 'Last  6 months'}
                                                   onClick={(e) => this.props.dateWise(e, "months", "Last  6 months")}/>
                                            <label>Last 6 months</label>
                                        </div>
                                        <div className="task-input-block eq-width">
                                            <DateTimePickerBox handleDateChange={this.handleFromChange}
                                                               onfocus={this.overlayShow} onblur={this.overlayHide}
                                                               placeholderText="From Date" timeFormat={false}
                                                               startDate={this.props.fromdate} previousDate={true}/>
                                        </div>
                                        <div className="task-input-block eq-width">
                                            <DateTimePickerBox handleDateChange={this.handleToChange}
                                                               onfocus={this.overlayShow} onblur={this.overlayHide}
                                                               placeholderText="To Date" id="todate" timeFormat={false}
                                                               startDate={this.props.todate} previousDate={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                        {collectionFilter.length !== 0 &&
                        <Accordion defaultActiveKey={this.state.collapsed} className="filter-accord">
                            {collectionFilter.map((filter, index) => (
                                <Card className="card" key={"filter-accord-fields-label" + index}>
                                    <Accordion.Toggle as={Card.Header} eventKey={index} className="card-header"
                                                      onClick={() => this.toggleAccordion(index)}>
                                        <button
                                            className={`btn btn-link arrow-ico ${this.state.collapsed !== index ? "collapsed" : ""}`}>{filter.title}</button>
                                    </Accordion.Toggle>


                                    <Accordion.Collapse eventKey={index}>
                                        <Card.Body>

                                            {filter.type === "number" &&
                                            <div className="radio-wrapper eq-height-filter">
                                                {filter.setasslider === "No" ?
                                                    <>
                                                        <div className="task-input-block eq-width">
                                                            <input type={`number`} className={`task-input-box`}
                                                                   placeholder={`min`}
                                                                   onChange={(e) => this.props.numberFilter(e, filter.masterid, "min")}/>
                                                        </div>
                                                        <div className="task-input-block eq-width">
                                                            <input type={`number`} className={`task-input-box`}
                                                                   placeholder={`max`}
                                                                   onChange={(e) => this.props.numberFilter(e, filter.masterid, "max")}/>
                                                        </div>
                                                    </>
                                                    :
                                                    <RangeSlider
                                                        min={(filter.min === "No" || filter.min === "") ? 0 : parseInt(filter.min)}
                                                        max={(filter.max === "No" || filter.min === "") ? 10000 : parseInt(filter.max)}
                                                        masterid={filter.masterid}
                                                        rangeFilter={this.props.rangeFilter}


                                                    />
                                                }
                                            </div>
                                            }

                                            {filter.fields.map((item, itmIndex) =>
                                                <div className="select-chk-block"
                                                     key={"filter-fields-label" + itmIndex}>
                                                    <label className="control control-checkbox"> {item}
                                                        <input type="checkbox" className="inp-chk"
                                                               checked={filterString.filter(a => (a === item.toString())).length !== 0}
                                                               onClick={() => this.handleFilterClick(item, filter.masterid)}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                            )}


                                            {filter.fields.length === 0 && filter.hasOwnProperty("filter_option") && filter.filter_option.length !== 0 &&

                                            <div className="radio-wrapper filter-block createdatefilter">
                                                <div className=" radio-block">
                                                    <input type="radio" name="dateFilter"
                                                           checked={this.props.datefilters.length !== 0 && this.props.datefilters[0].labelFrom === "today" + filter.masterid}
                                                           onClick={() => this.props.daysFilter("today", "", "", filter.masterid, "datefilters")}/>
                                                    <label>Today</label>
                                                </div>
                                            </div>
                                            }


                                            {filter.fields.length === 0 && filter.hasOwnProperty("filter_option") && filter.filter_option.length !== 0 &&
                                            filter.filter_option.map((list, index) => {
                                                    let filterString = list.option_term + list.option_count + list.option_type + filter.masterid
                                                    return (<div className="radio-wrapper filter-block createdatefilter"
                                                                 key={"datefilters" + index}>
                                                        <div className=" radio-block">
                                                            <input type="radio" name="dateFilter"
                                                                   checked={this.props.datefilters.filter((list) => list.labelFrom === filterString).length !== 0}
                                                                   onClick={() => this.props.daysFilter(list.option_term, list.option_count, list.option_type, filter.masterid, "datefilters")}/>
                                                            <label>{this.camelize(list.option_term)} {list.option_count} {list.option_type}</label>
                                                        </div>
                                                    </div>)
                                                }
                                            )}
                                            {filter.fields.length === 0 && filter.hasOwnProperty("filter_option") && filter.filter_option.length !== 0 &&
                                            <React.Fragment>
                                                <div className="radio-wrapper filter-block createdatefilter">
                                                    <div className="task-input-block eq-width">
                                                        <DateTimePickerBox
                                                            handleDateChange={this.handleFromCustomdateRange}
                                                            index={filter.masterid}
                                                            onfocus={this.overlayShow} onblur={this.overlayHide}
                                                            placeholderText="From Date"
                                                            timeFormat={false} startDate={this.props.customizeDateFrom}
                                                            previousDate={true}/>
                                                    </div>
                                                    <div className="task-input-block eq-width">
                                                        <DateTimePickerBox
                                                            handleDateChange={this.handleToCustomdateRange}
                                                            onfocus={this.overlayShow}
                                                            onblur={this.overlayHide}
                                                            placeholderText="To Date" id="todate"
                                                            timeFormat={false}
                                                            index={filter.masterid}
                                                            startDate={this.props.customizeDateTo}
                                                            previousDate={true}/>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            }

                                            {filter.days_filter_label &&
                                            <div className="card-header" id="dateheading">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link"> {filter.days_filter_label}
                                                    </button>
                                                </h5>
                                            </div>
                                            }
                                            {filter.fields.length === 0 && filter.hasOwnProperty("dayfilter_option") && filter.dayfilter_option.length !== 0 &&
                                            <div className="radio-wrapper filter-block createdatefilter">
                                                <div className=" radio-block">
                                                    <input type="radio" name={"daysFilter" + filter.masterid}
                                                           checked={this.props.daysfilters.filter((list) => list.labelFrom === "today" + filter.masterid).length !== 0}
                                                           onClick={() => this.props.daysFilter("today", "", "", filter.masterid, "daysfilters")}/>
                                                    <label>Today</label>
                                                </div>
                                            </div>
                                            }

                                            {filter.fields.length === 0 && filter.hasOwnProperty("dayfilter_option") && filter.dayfilter_option.length !== 0 &&
                                            filter.dayfilter_option.map((list, index) => {
                                                    let filterString = list.days_term + list.days_count + list.days_type + filter.masterid

                                                    return (<div className="radio-wrapper filter-block createdatefilter"
                                                                 key={"dayfilter_option" + index}>
                                                        <div className=" radio-block">
                                                            <input type="radio" name={"daysFilter" + filter.masterid}
                                                                   checked={this.props.daysfilters.filter((list) => (list.labelFrom === filterString.toString())).length !== 0}
                                                                   onClick={() => this.props.daysFilter(list.days_term, list.days_count, list.days_type, filter.masterid, "daysfilters")}/>
                                                            <label>
                                                                {this.camelize(list.days_term)} {list.days_count} {list.days_type}</label>
                                                        </div>
                                                    </div>)
                                                }
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            ))}
                        </Accordion>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class InvokeWorkflow extends Component {
    render() {
        let listNoWorkFlow = this.props.listNoWorkFlow || [];
        return (
            <React.Fragment>
                <div className="modal bsy-modal x-large show" id="collectionModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: "block"}}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            {(this.props.WorkFlowloader || this.props.wloader == 1) &&
                            <span className="Loader-holder">
                                 <LoaderSvg/>
                              </span>
                            }
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib ">Invoke Workflow</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={() => this.props.closeInvokeModal()}></button>
                            </div>
                            <div className="modal-body  small-height modal-body-heighted">
                                <div className="task-inp-container">
                                    <div className="select-box">
                                        <select name="workflowlist" onClick={(e) => this.props.selectWorkflow(e)}>
                                            {listNoWorkFlow.length > 0 &&
                                            <option value="select">Workflow</option>
                                            }
                                            {listNoWorkFlow.length > 0 && listNoWorkFlow.map((list, index) =>
                                                <option name="selworkflow" id={list.id} value={list.id}
                                                        key={"list-" + index}>{list.name}</option>
                                            )}

                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer  checklist-footer">
                                <button type="button" className="general-btn grey" data-dismiss="modal"
                                        onClick={() => this.props.closeInvokeModal()}>Cancel
                                </button>
                                <button type="button" className="general-btn" id="addProject"
                                        onClick={(e) => this.props.SaveInvokeworkflow(e)}>Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </React.Fragment>
        );
    }

}

function ResultTable(Props) {
    const [listItems, setListItems] = useState(Array.from(Array(30).keys(), n => n + 1));
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        const container = document.querySelector('.form-table');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        const container = document.querySelector('.form-table')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - (offsetHeight + 1);
        const height = document.getElementById('a').clientHeight;
        if (contentHeight <= scrollTop) {
            Props.loadmoreCollectionData()
        }
        ;
        setIsFetching(true);
    }

    function fetchMoreListItems() {

        Props.loadmoreCollectionData()

    }

    //////////console.log(Props.label)
    let showlistField = []
    if (Props.label.showformdata.length !== 0) {

        for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
            (Props.label.showformdata.pages[i].components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden")).map(list => {
                showlistField.push(list)

            })
        }
    }
    //////////console.log(Props.collection.CollectionData)
    return (

        <div className="form-table coll-full-height" id="a">
            <table id="a" className="table data-table">
                <thead>
                <tr>
                    <th scope="col">
                        <div className="select-chk-block" style={{display: "block"}}>
                            <label className="control control-checkbox">
                                <input type="checkbox" className="inp-chk"
                                       onClick={Props.handleSelectAll}/>
                                <div className="control-indicator"></div>
                            </label>
                        </div>
                    </th>
                    {showlistField.map((title, index) =>
                        <React.Fragment key={`showlist-field` + index}>
                            {index === 0 &&
                            <th></th>
                            }
                            <th scope="col">
                                {title.label}
                            </th>
                            {showlistField.length - 1 === index &&
                            <th></th>
                            }

                        </React.Fragment>
                    )}

                </tr>
                </thead>
                <tbody>
                {Props.collection.CollectionData.map((row, rowIndex) =>
                    <tr key={`result-table-content` + rowIndex}>
                        <td key={`result-tr` + rowIndex}>
                            <div className="select-chk-block" style={{display: "block"}}>
                                <label className="control control-checkbox">
                                    <input type="checkbox" className="inp-chk"
                                           defaultChecked={(Props.state.checkedRow.indexOf(row["id"]) > -1)}
                                           data-val={row['id']}
                                           onClick={(e) => Props.handleSelectRow(row['id'])}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                        </td>
                        <td className="no-pad-team" key={`no-pad-team` + rowIndex}>
                            {row['users'].length !== 0 &&
                            <span className="ico-lib user-assigned-ico assigned_user_list"
                                  title="assigned" data-item="133_user"
                                  onClick={(e) => Props.showAssignedUsers(row['users'])}>
                                </span>
                            }
                        </td>


                        {showlistField.map((title, index) =>

                            <React.Fragment key={`edit-collection-data` + index}>
                                <td onDoubleClick={(e) => Props.showPreviewFormModal(e, row['id'], rowIndex)}>
                                    <span className="text-overflow">{row[title.masterid]}</span>
                                </td>


                                {Props.collection.CollectionLabel.length - 1 === index &&
                                <td align="right">
                                    <div className="wf-btn-block">
                                        <button className="ico-lib wf-ed" data-action="editcollection"
                                                onClick={(e) => Props.showFormModal(e, row['id'])}/>
                                    </div>
                                </td>
                                }

                            </React.Fragment>
                        )}

                    </tr>
                )}

                </tbody>
            </table>
        </div>


    )

}

function ActionButton(Props) {
    let permissionsSelected = Props.permissionsSelected;
    let collabrator = Props.permissionsSelected.length > 0 ? Props.permissionsSelected[0].collabrator : '';

    let exported = Props.permissionsSelected.length > 0 ? Props.permissionsSelected[0].export : '';
    const [status, showOptions] = useState(false);

    return (
        <div className="form-option-block">
            {((getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) || (permissionsSelected.length > 0 && (permissionsSelected[0].rule || permissionsSelected[0].assign || permissionsSelected[0].export || permissionsSelected[0].import || permissionsSelected[0].schedule || permissionsSelected[0].api))) &&
            <button className="ico-lib opt-form-ico" onClick={() => showOptions(!status)}></button>
            }
            {status &&
            <OutsideAlerter
                status={status}
                Triggered={() => showOptions(!status)}
            >

                <ul className="panel-option">
                    {((permissionsSelected.length > 0 && permissionsSelected[0].rule) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <nav className="panel-option-click ico-lib form-rule-ico"
                             onClick={(e) => {
                                 Props.handleActionEn(e, 'rule');
                                 showOptions(!status)
                             }}>Rule
                        </nav>
                    </li>
                    }
                    {((permissionsSelected.length > 0 && permissionsSelected[0].schedule) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <nav className="panel-option-click ico-lib-v1 form-schedule-ico"
                             onClick={(e) => {
                                 Props.handleActionEn(e, 'schedule');
                                 showOptions(!status)
                             }}>Schedule
                        </nav>
                    </li>
                    }
                    {((permissionsSelected.length > 0 && permissionsSelected[0].api) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <a href={`../../collections/apiexplorer/` + btoa(Props.collectionid)}
                           target={`_blank`} className="panel-option-click ico-lib form-api-ico"
                           onClick={(e) => {
                               showOptions(!status)
                           }}>Api Explorer</a>
                    </li>
                    }
                    {((permissionsSelected.length > 0 && permissionsSelected[0].assign) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <nav className="panel-option-click ico-lib form-share-ico"
                             onClick={(e) => {
                                 Props.ShareAction(e, 'shareAll');
                                 showOptions(!status)
                             }}>Share
                        </nav>
                    </li>
                    }
                    {((permissionsSelected.length > 0 && permissionsSelected[0].export) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <a href={SHARED_LOGIN.CISERVICE + `Collection/export_template/` + Props.collectionid + '/' + ES_INDEX}
                           className="panel-option-click ico-lib export-ico-absolute"
                           onClick={(e) => {
                               Props.handleActionEn(e, 'export');
                               showOptions(!status)
                           }}>Export Template</a>
                    </li>
                    }
                    {((getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <nav className="panel-option-click ico-lib form-embed-ico"
                             onClick={(e) => {
                                 Props.handleActionEn(e, 'embed');
                                 showOptions(!status)
                             }}>Embed Form
                        </nav>
                    </li>
                    }
                    {((permissionsSelected.length > 0 && permissionsSelected[0].import) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <nav className="panel-option-click ico-lib-v1 form-dup-ico"
                             onClick={(e) => {
                                 Props.handleActionEn(e, 'import');
                                 showOptions(!status)
                             }}>Import CSV
                        </nav>
                    </li>
                    }

                    {((permissionsSelected.length > 0 && permissionsSelected[0].import) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <nav className="panel-option-click ico-lib-v1 form-dup-ico"
                             onClick={(e) => {
                                 Props.handleActionEn(e, 'excel');
                                 showOptions(!status)
                             }}>Import Excel
                        </nav>
                    </li>
                    }

                    {((permissionsSelected.length > 0 && permissionsSelected[0].export) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&

                    <li>
                        <nav className="panel-option-click ico-lib-v1 form-update-all-ico"
                             onClick={(e) => {
                                 Props.handleActionEn(e, 'updateall');
                                 showOptions(!status)
                             }}>Update all
                        </nav>
                    </li>

                    }

                    {/*{(exported || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) && Props.checkedRow !== 0 &&*/}
                    {/*<li>*/}
                    {/*    <nav href="" className="panel-option-click ico-lib-v1 form-update-ico"*/}
                    {/*         onClick={(e) => {*/}
                    {/*             Props.handleActionEn(e, 'update');*/}
                    {/*             showOptions(!status)*/}
                    {/*         }}>Update*/}
                    {/*    </nav>*/}
                    {/*</li>*/}
                    {/*}*/}
                    {((getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <nav className="panel-option-click ico-lib form-set-ico"
                             onClick={(e) => {
                                 Props.handleActionEn(e, 'settings');
                                 showOptions(!status)
                             }}>Settings
                        </nav>
                    </li>
                    }
                    {((collabrator || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>

                        <a href={SHARED_LOGIN.redirect_url + `Collection/buildform/` + Props.collectionid + '/entity'}
                           target={`_blank`} className="panel-option-click ico-lib-v1 build-form-ico"
                           onClick={(e) => {
                               Props.handleActionEn(e, 'buildform');
                               showOptions(!status)
                           }}>Build Form</a>
                    </li>
                    }
                    {((getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <nav className="panel-option-click ico-lib lnkd"
                             onClick={(e) => {
                                 Props.linkedAction(e, 'linked');
                                 showOptions(!status)
                             }}>Linked Collections
                        </nav>
                    </li>
                    }
                    {((getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                    <li>
                        <nav className="panel-option-click ico-lib delete-task-ico"
                             onClick={(e) => {
                                 Props.handleActionEn(e, 'deleteall');
                                 showOptions(!status)
                             }}>Delete All Records
                        </nav>
                    </li>
                    }

                </ul>
            </OutsideAlerter>
            }
        </div>
    )


}

function SearchOptionsPanel(Props) {
    let assigned = Props.permissionsSelected.length > 0 ? Props.permissionsSelected[0].assign : '';
    let exported = Props.permissionsSelected.length > 0 ? Props.permissionsSelected[0].export : '';
    let deleted = Props.permissionsSelected.length > 0 ? Props.permissionsSelected[0].delete : '';
    // console.log(Props.state.shareToAll)
    return (
        <React.Fragment>
            <div className="data-search-left">
                <div className="data-search-box">
                    <input type="text" name="" placeholder="Search Entity Data" className="data-search"
                           onChange={Props.handleSearch} value={Props.state.search}/>
                    <span className="ico-lib search-ico"></span>
                    {Props.advancedSelectionFields.length > 0 &&
                    <AdvancedSearch
                        submitAdvncSearch={Props.submitAdvncSearch}
                        searchData={Props.advancedSelectionFields}
                    />
                    }
                </div>
                <button className="filter-ico" onClick={() => Props.filterToggle()}></button>
            </div>
            <div className="data-search-button"
                 style={{display: Props.state.checkedRow.length === 0 ? "none" : "flex"}}>
                {((assigned || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) && !Props.state.shareToAll &&
                <button onClick={(e) => {
                    Props.handleActionEn(e, 'task');
                }} id="exportExcel" className="general-btn ico-lib coll-task-ico" target={"_blank"}>Task
                </button>
                }
                {(assigned || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) &&
                <button onClick={(e) => {
                    Props.handleActionEn(e, 'share');
                }} id="exportExcel" className="general-btn ico-lib coll-assign-ico" target={"_blank"}>Assign

                </button>
                }


                <Dropdown className="ml-15">
                    <Dropdown.Toggle className="more-btn" menuAlign="right">More actions</Dropdown.Toggle>
                    <Dropdown.Menu className="more-panel">
                        {(deleted || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) && !Props.state.shareToAll &&
                        <Dropdown.Item onClick={Props.showDeleteConfirm}
                                       className="panel-option-click ico-lib delete-task-ico">Delete</Dropdown.Item>
                        }

                        {Props.checkedRow.length === 1 &&
                        <Dropdown.Item id="invokeWorkflow" onClick={Props.showDuplicateConfirm}
                                       className="panel-option-click ico-lib-v1 form-dup-ico">Duplicate</Dropdown.Item>
                        }

                        {(exported || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) && !Props.state.shareToAll &&
                        <Dropdown.Item id="exportExcel" onClick={() => Props.selectExport(false)}
                                       className="panel-option-click ico-lib export-ico-absolute">Select
                            Export</Dropdown.Item>
                        }

                        {(exported || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) && !Props.state.shareToAll &&
                        <Dropdown.Item id="invokeWorkflow" onClick={Props.invokeWorkflow}
                                       className="panel-option-click ico-lib-v1 invoke-ico">Invoke
                            Workflow</Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>

                {/* {(exported || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) && !Props.state.shareToAll &&
                <button id="exportExcel" className="general-btn ico-lib ex-cel-ico" onClick={()=>Props.selectExport(false)}>Select Export
                </button>
                }
                {(exported || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) && !Props.state.shareToAll &&
                <button id="invokeWorkflow" className="general-btn ico-lib ex-cel-ico" onClick={Props.invokeWorkflow}>Invoke Workflow
                </button>
                }
                {(deleted || getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) && !Props.state.shareToAll &&
                <button className="general-btn ico-lib delete-ico" onClick={Props.showDeleteConfirm}>Delete</button>
                }
                {Props.checkedRow.length === 1 &&
                <button id="invokeWorkflow" className="general-btn" onClick={Props.showDuplicateConfirm}>Duplicate
                    </button>
                } */}

                {/*<button className="general-btn ico-lib export-ico">Export</button>*/}
            </div>
        </React.Fragment>
    )

}

function NewUser(Props) {
    return (
        <div className={'float-button-box'} onClick={(e) => Props.showFormModal(e, 0)} title="New User">
            <span className={'icon-in icon-plus'}></span>
        </div>
    )

}


// fake data generator
const getlabel = (count, offset = 0) =>
    Array.from({length: count}, (v, k) => k).map(k => ({
        id: `item-${k + offset}`,
        content: `item ${k + offset}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getlabeltyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the label look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '#ddd',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#ddd',
    padding: grid,
    width: "100%",
    height: "100%"
});

class TaskAssignContainer extends React.Component {

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'label',
        to: 'to',
        cc: 'cc',
        subject: 'subject',
        description: "description",
        priority: "priority",
        duedate: "duedate"


    };

    constructor(props) {
        super(props)
        this.state = {
            label: [],
            to: [],
            cc: [],
            subject: [],
            description: [],
            priority: [],
            duedate: [],
            dueError: false,
            subError: false,
            descriptionError: false

        };
    }

    componentDidMount() {
        let showlistField = []
        if (this.props.label.showformdata.length !== 0) {
            for (let i = 0; i < this.props.label.showformdata.pages.length; i++) {
                (this.props.label.showformdata.pages[i].components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden")).map(list => {
                    showlistField.push(list)

                })
                this.setState({label: showlistField})
            }
        }
    }

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const {source, destination} = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {

        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );
            if (source.droppableId === "droppable") {
                if (this.state[destination.droppableId].length === 0) {
                    this.setState({
                        label: result.droppable,
                        [destination.droppableId]: result[destination.droppableId]
                    });
                }
            } else {

                this.setState({
                    label: result.droppable,
                    [source.droppableId]: result[source.droppableId]
                });
            }

        }
    };
    saveTask = () => {
        var subject = true
        var duedate = false
        if (this.state.subject.length === 0 && this.state.description.length === 0) {

            this.setState({subError: true, descriptionError: true})
            subject = false
        } else {
            this.setState({subError: false, descriptionError: false})
            subject = true
        }
        if (this.state.duedate.length === 0) {

            this.setState({dueError: true})
            duedate = false
        } else {
            this.setState({dueError: false})
            duedate = true
        }

        if (subject && duedate) {
            var to = []
            var cc = []
            var subject = []
            var description = []
            var duedate = []
            var priority = []
            if (this.state.to.length !== 0) {
                to["masterid"] = this.state.to[0].masterid
                to["label"] = this.state.to[0].label
            }
            if (this.state.cc.length !== 0) {
                cc["masterid"] = this.state.cc[0].masterid
                cc["label"] = this.state.cc[0].label
            }
            if (this.state.subject.length !== 0) {
                subject["masterid"] = this.state.subject[0].masterid
                subject["label"] = this.state.subject[0].label
            }
            if (this.state.description.length !== 0) {
                description["masterid"] = this.state.description[0].masterid
                description["label"] = this.state.description[0].label
            }

            if (this.state.duedate.length !== 0) {
                duedate["masterid"] = this.state.duedate[0].masterid
                duedate["label"] = this.state.duedate[0].label
            }
            if (this.state.priority.length !== 0) {
                priority["masterid"] = this.state.priority[0].masterid
                priority["label"] = this.state.priority[0].label
            }
            let taskdata = {
                "to": to,
                "cc": cc,
                "subject": subject,
                "description": description,
                "duedate": duedate,
                "priority": priority


            }

            //console.log(taskdata)


        }


        this.setState({taskitem: this.state})
    }

    render() {
        return (
            <>
                <div className="settings-loungue-header">
                    <div className="tab-wrap-block full-width-tab">
                        <div className="tab-wrap-block-left">
                            <h5>View Template Settings</h5>
                            <p>Select the viewing template of collection data</p>
                        </div>
                        <div className="tab-wrap-block-right">
                            <button className="general-btn  focus-resolve" id="savedata_viewpermission"
                                    onClick={this.saveTask}>Save
                            </button>
                        </div>
                    </div>
                </div>
                <div className="set-container">
                    <DragDropContext onDragEnd={this.onDragEnd}>

                        <div className={`DashBuilder`}>


                            <div className="main-canvas" style={{"width": "40%"}}>
                                <div className="canvas  dropzone">
                                    <div className="drag-item " draggable="true">
                                        <div className="layout-builder-titles">Label</div>
                                        <div className="mt-3 inner-layout-builder">
                                            <div className="row inner-layout-builder row">
                                                <div className="col-sm-12">
                                                    <div className="dropzone">
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                                    {this.state.label.map((item, index) => (
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={item.id}
                                                                            index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getlabeltyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}>

                                                                                    <div className="drag-item "
                                                                                         draggable="true">
                                                                                        <div className="m-0 form-group">
                                                                                            <label
                                                                                                className="col-sm-12"><span>{item.label}</span>

                                                                                            </label>
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="main-canvas" style={{"width": "60%"}}>
                                <div className="canvas  dropzone">
                                    <div className="drag-item " draggable="true">
                                        <div className="layout-builder-titles">To</div>
                                        <div className="mt-3 inner-layout-builder">
                                            <div className="row inner-layout-builder row">
                                                <div className="col-sm-12">
                                                    <div className="dropzone">
                                                        <Droppable droppableId="to" style={{transform: "none"}}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                                    {this.state.to.map((item, index) => (
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={item.id}
                                                                            index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getlabeltyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}>
                                                                                    <div className="drag-item "
                                                                                         draggable="true">
                                                                                        <div className="m-0 form-group">
                                                                                            <label
                                                                                                className="col-sm-12"><span>{item.label}</span>

                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="main-canvas" style={{"width": "60%"}}>
                                <div className="canvas  dropzone">
                                    <div className="drag-item " draggable="true">
                                        <div className="layout-builder-titles">CC</div>
                                        <div className="mt-3 inner-layout-builder">
                                            <div className="row inner-layout-builder row">
                                                <div className="col-sm-12">
                                                    <div className="dropzone">
                                                        <Droppable droppableId="cc">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                                    {this.state.cc.map((item, index) => (
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={item.id}
                                                                            index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getlabeltyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}>
                                                                                    <div className="drag-item "
                                                                                         draggable="true">
                                                                                        <div className="m-0 form-group">
                                                                                            <label
                                                                                                className="col-sm-12"><span>{item.label}</span>

                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`main-canvas`} style={{"width": "60%"}}>
                                <div className="canvas  dropzone">
                                    <div className={`drag-item ${this.state.subError ? "border-error-holder" : ""}`}>
                                        <div className="layout-builder-titles">subject</div>
                                        <div className="mt-3 inner-layout-builder">
                                            <div className="row inner-layout-builder row">
                                                <div className="col-sm-12">
                                                    <div className="dropzone">
                                                        <Droppable droppableId="subject">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                                    {this.state.subject.map((item, index) => (
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={item.id}
                                                                            index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getlabeltyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}>
                                                                                    <div className="drag-item "
                                                                                         draggable="true">
                                                                                        <div className="m-0 form-group">
                                                                                            <label
                                                                                                className="col-sm-12"><span>{item.label}</span>

                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`main-canvas`} style={{"width": "60%"}}>
                                <div className="canvas  dropzone">
                                    <div
                                        className={`drag-item ${this.state.descriptionError ? "border-error-holder" : ""}`}>
                                        <div className="layout-builder-titles">Description</div>
                                        <div className="mt-3 inner-layout-builder">
                                            <div className="row inner-layout-builder row">
                                                <div className="col-sm-12">
                                                    <div className="dropzone">
                                                        <Droppable droppableId="description">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                                    {this.state.description.map((item, index) => (
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={item.id}
                                                                            index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getlabeltyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}>
                                                                                    <div className="drag-item "
                                                                                         draggable="true">
                                                                                        <div className="m-0 form-group">
                                                                                            <label
                                                                                                className="col-sm-12"><span>{item.label}</span>

                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`main-canvas`} style={{"width": "60%"}}>
                                <div className="canvas  dropzone">
                                    <div className={`drag-item ${this.state.dueError ? "border-error-holder" : ""}`}>
                                        <div className="layout-builder-titles">Due Date</div>
                                        <div className="mt-3 inner-layout-builder">
                                            <div className="row inner-layout-builder row">
                                                <div className="col-sm-12">
                                                    <div className="dropzone">
                                                        <Droppable droppableId="duedate">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                                    {this.state.duedate.map((item, index) => (
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={item.id}
                                                                            index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getlabeltyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}>
                                                                                    <div className="drag-item "
                                                                                         draggable="true">
                                                                                        <div className="m-0 form-group">
                                                                                            <label
                                                                                                className="col-sm-12"><span>{item.label}</span>

                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="main-canvas" style={{"width": "60%"}}>
                                <div className="canvas  dropzone">
                                    <div className="drag-item " draggable="true">
                                        <div className="layout-builder-titles">Priority</div>
                                        <div className="mt-3 inner-layout-builder">
                                            <div className="row inner-layout-builder row">
                                                <div className="col-sm-12">
                                                    <div className="dropzone">
                                                        <Droppable droppableId="priority">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                                    {this.state.priority.map((item, index) => (
                                                                        <Draggable
                                                                            key={item.id}
                                                                            draggableId={item.id}
                                                                            index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getlabeltyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}>
                                                                                    <div className="drag-item "
                                                                                         draggable="true">
                                                                                        <div className="m-0 form-group">
                                                                                            <label
                                                                                                className="col-sm-12"><span>{item.label}</span>

                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </DragDropContext>
                </div>
            </>
        )
    }
}

export {
    EntityDetailData,
    ResultTable,
    ActionButton,
    SearchOptionsPanel,
    NewUser,
    FilterTag,
    TaskAssignContainer,
    InvokeWorkflow
}
