import React, {Component} from 'react';
import {connect} from "react-redux";
import * as usersActions from "../../../actions/Users"
import {withRouter} from 'react-router';
import LoaderSvg from "../../views/LoaderSVG"
import * as collectionActions from "../../../actions/Collections";
import {getProfile} from "../../../utilities/AuthService";
import {ES_INDEX, USRIMG_PATH} from '../../../constants'
import rootActions from "../../../actions";
import ShowFormModal from "../../views/ShowFormModal";
import NoData from "../../views/NoData";
import OutsideAlerter from "../../views/OutsideAlerter";
import AlertModal from "../../views/AlertModal";
import {UserActionButton} from "./UsersDetailsData";

let userrarray =[];
class Users extends Component {

    constructor(props) {
        super(props);

        this.state={
            showFormModal: false,
            enableSendMail: false,
			 droplist : false,
			 chatPermission : false,
            collectionId: ES_INDEX + '10003',
            offset:100,
            lastOffset:0,
            enableUserids:[],
            confirmUsermail:false,
            sendLoader: 0,
            emailErrorModal:false
        }
        this.hideFormModal = this.hideFormModal.bind(this);
        this.handleEnableSendmail = this.handleEnableSendmail.bind(this);
        this.goToChat = this.goToChat.bind(this);
        this.dropList = this.dropList.bind(this);
        this.confirmUserEmail = this.confirmUserEmail.bind(this);
      }



    componentDidMount() {
      //  console.log(getProfile());
         this.props.mainClick('team','');
         if(getProfile().usertype === "User" || getProfile().usertype === "Admin"){
             var CompanyId = getProfile().organization_id;
         } else {
             var CompanyId = this.props.listUsers.searchParam.company;
         }
        var data = {
            "uid" : getProfile().id,
            "index" :  ES_INDEX,
            "search": this.props.listUsers.searchParam.search,
            "team": this.props.listUsers.searchParam.team,
            "company": CompanyId,
            "reportees": this.props.listUsers.searchParam.reportees,
            "designation": this.props.listUsers.searchParam.designation,
            "page": this.state.lastOffset + 1,
            "per_page": this.state.offset
        };
        this.props.users(data);
        this.props.showform({ _id : this.state.collectionId});
        let params = {index : ES_INDEX}
        this.props.usersallcompanies(params);
        this.props.usersalldesignations(params);
        this.props.getModeration();
        this.props.usersallreportees(params);
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "listing",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);

        const container = document.querySelector('.main-panel-holder');
        container.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        const container = document.querySelector('.main-panel-holder');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.main-panel-holder')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;
        if (contentHeight <= scrollTop) {
            let lastOffset = this.state.lastOffset + 1;
            this.setState({lastOffset: lastOffset},
                function () {
                    if(getProfile().usertype === "User" || getProfile().usertype === "Admin"){
                        var CompanyId = getProfile().organization_id;
                    } else {
                        var CompanyId = this.props.listUsers.searchParam.company;
                    }
                    let params = {
                        "uid": getProfile().id,
                        "index": ES_INDEX,
                        "search": this.props.listUsers.searchParam.search,
                        "team": this.props.listUsers.searchParam.team,
                        "company": CompanyId,
                        "reportees": this.props.listUsers.searchParam.reportees,
                        "designation": this.props.listUsers.searchParam.designation,
                        "page": this.state.lastOffset + 1,
                        "per_page": this.state.offset
                    };
                    this.props.moreusers(params)
                })
        }
    }
    goToChat(e,uid,firstName,lastName,image){
        let userdata='';
        userdata={"receiverId":uid,"type":"One to One","name":firstName+' '+lastName,"userHead":image};
        window.location.href = window.location.origin+'/chat/'+btoa(JSON.stringify(userdata));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if(this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged) {
            if(getProfile().usertype === "User" || getProfile().usertype === "Admin"){
                var CompanyId = getProfile().organization_id;
            } else {
                var CompanyId = this.props.listUsers.searchParam.company;
            }
            if(this.props.getSavedForm.formSavedRes.success) {
                var data = {
                    uid: getProfile().id,
                    "search": this.props.listUsers.searchParam.search,
                    "team": this.props.listUsers.searchParam.team,
                    "company": CompanyId,
                    "reportees": this.props.listUsers.searchParam.reportees,
                    "designation": this.props.listUsers.searchParam.designation,
                    "page": this.state.lastOffset + 1,
                    "per_page": this.state.offset
                };
                setTimeout(function () {
                    beta.props.users(data);
                }, 1000)
            }else{
                this.setState({emailErrorModal:true})
            }
        }

        if(this.props.sendEmailusers.senduseremail !== prevProps.sendEmailusers.senduseremail){
            if(getProfile().usertype === "User" || getProfile().usertype === "Admin"){
                var CompanyId = getProfile().organization_id;
            } else {
                var CompanyId = this.props.listUsers.searchParam.company;
            }
            var data = { uid : getProfile().id,
                "search": this.props.listUsers.searchParam.search,
                "team": this.props.listUsers.searchParam.team,
                "company": CompanyId,
                "reportees": this.props.listUsers.searchParam.reportees,
                "designation": this.props.listUsers.searchParam.designation,
                "page": this.state.lastOffset + 1,
                "per_page": this.state.offset};
            setTimeout(function () {
                beta.props.users(data);
                beta.setState({sendLoader:0,enableUserids : []})
            }, 1000)

        }

    }

    showFormModal(e, id) {
        this.props.resetForm();
        this.setState({
            formName : 'User Details',
            showFormModal: true
        })
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "addbuttonclick",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }
    hideFormModal(id) {
        this.setState({
            showFormModal: false
        })
    }
    handleEnableSendmail(e,u_id) {
        if(userrarray.includes(u_id)){
            var index = userrarray.indexOf(u_id)
            userrarray.splice(index, 1);
        }else{
            userrarray.push(u_id);
        }
        this.setState({
            enableSendMail: true,
            enableUserids : userrarray
        })
    }
    sendUserEmail(e){
        let seluserid = this.state.enableUserids;
        let params={
            "ids": seluserid
        }
        this.props.sendEmailTousers(params);
        this.setState({
            confirmUsermail: !this.state.confirmUsermail,
            sendLoader: 1
        })

    }
    confirmUserEmail(e){
        this.setState({
            confirmUsermail: !this.state.confirmUsermail
        })
    }


	dropList(){
	if (!this.state.droplist) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        this.setState({droplist: !this.state.droplist});
}
    render() {
        let Users=this.props.listUsers.Users||[];
        let TotalUsers=this.props.listUsers.TotalUsers||'';
        let getModerationstatus=this.props.getModerationstatus.getmoderation.moderation||'';
        let userArrayIds = this.state.enableUserids;
    	//console.log(userArrayIds);
    	//console.log(getModerationstatus);
        //console.log(this.state.enableUserids);
        // //console.log(this.props.sendEmailusers.senduseremail.success);
		let userPermissiondata = this.props.listUsersPermission.UsersPermission;
		let chatPermission=false
        userPermissiondata.forEach(
            function iterator(item,index){
                if(item.module == "Chat" && item.module_permission == "yes"){

                        chatPermission= true;
                }
            }
        );
        let teamPermission=false
        let addPermission=false
        userPermissiondata.forEach(
            function iterator(item,index){
                if(item.module == "Team" && item.module_permission == "yes"){
                    teamPermission= true;
                    let permission = item.actions.filter(subitem =>subitem.action === "Add")[0]||[];
                    if(permission.action_permission === 'yes') {
                        addPermission = true;
                    }
                }
            }
        );
         return (
		 <>
				{Users.length !== 0 && teamPermission == true &&
					<div className="filter-all-wrapper task-home">
						<div className="filter-holder">
                            <div className="filter-left-block">

                                <h5 className="count-header">Users ({TotalUsers}) </h5>
                            </div>
							<div className="filter-right-block">

                                {(getProfile().usertype === "Superadmin" && getProfile().id === '2') &&

                                    <React.Fragment>
                                        {this.state.enableUserids.length > 0 &&
                                        <button class="layer-btn" onClick={(e) => this.confirmUserEmail(e)}>Send Email</button>
                                        }

                                    {/*<button className="ico-lib opt-form-ico" data-toggle="tooltip" onClick={this.dropList} data-placement="top" title="" data-original-title="Organize" ></button>*/}
                                    {/*{this.state.droplist &&*/}
                                    {/*<div className="description-options-box fly-in">*/}
                                    {/*    <OutsideAlerter*/}
                                    {/*        status={this.state.droplist}*/}
                                    {/*        Triggered={() => this.dropList(!this.state.droplist)}>*/}
                                    {/*    <ul className="options-list">*/}
                                    {/*        /!*<li className="options-list-items" onClick={(e) => this.props.history.push('/team/permissionsettings')}>*!/*/}
                                    {/*        /!*    <span className="icon-in icon-edit"></span>*!/*/}
                                    {/*        /!*    <span className="option-text-in">Permission Settings</span>*!/*/}
                                    {/*        /!*</li>*!/*/}
                                    {/*        <li className="options-list-items" onClick={(e) => this.props.history.push('/settings/usersettings')}>*/}
                                    {/*            <span className="icon-in icon-settings"></span>*/}
                                    {/*            <span className="option-text-in">User Settings</span>*/}
                                    {/*        </li>*/}
                                    {/*        <li className="options-list-items" onClick={(e) => this.props.history.push('/team/rolesettings')}>*/}
                                    {/*            <span className="icon-in icon-settings"></span>*/}
                                    {/*            <span className="option-text-in">Roles Settings</span>*/}
                                    {/*        </li>*/}

                                    {/*    </ul>*/}
                                    {/*    </OutsideAlerter>*/}
                                    {/*</div>*/}
                                    {/*}*/}

                                    <UserActionButton
                                        droplist={this.state.droplist}
                                        history={this.props.history}

                                    />

                                    </React.Fragment>
                                }
                            </div>
						</div>
					</div>
				}
                     <div className="main-panel-holder has-filter user-lister-container">

                         {this.props.loader.loaderType === 'users-list' && this.props.loader.status === true &&
                             <span className="Loader-holder">
                                <LoaderSvg/>
                             </span>
                         }
                         {this.state.sendLoader === 1 &&
                         <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                         }
                         {teamPermission == true ?
                             <>
                             {Users.length === 0 &&
                         <div className='absolute-holder'>
                             <NoData
                                 msg={`No Users added yet`}

                             />
                         </div>}
                             </> :
                             <div className='absolute-holder'>
                                 <NoData
                                     msg={`No permission to access`}

                                 />
                             </div>
                         }

                         {teamPermission == true &&
                         <React.Fragment>
                         
                         <div className="card-holder">
                             {Users.map((list, index) =>
                                 <div  className= {`team-card ${userrarray.includes(list.user_id) === true ? "selected":''}`}  key={index}>

                                     <div className="user-thumb">
                                         <img className="user-image-in"
                                              src={USRIMG_PATH + (list.image || 'person_avatar.gif')}/>
                                         {chatPermission &&
                                         <div className="hover-in-button">
                                             <div className="button-in">
                                                 <span className="icon-in icon-chat-sm"></span>
                                                 <span className="botton-text"
                                                       onClick={(e) => this.goToChat(e, list.user_id, list.firstName, list.lastName, list.image)}>Chat</span>
                                             </div>
                                         </div>
                                         }
                                     </div>

                                     <div className="user-description"
                                          onClick={(e) => this.props.history.push('/team/details/' + btoa(list.user_id))}
                                          key={index}>
                                         <h5 className="card-user-head">{list.firstName} {list.lastName}</h5>
                                         {list.designation &&
                                         <p className="designation-in">{list.designation}</p>
                                         }
                                         {list.email &&
                                         <p className="mail-in">{list.email}</p>
                                         }
                                         {list.phone &&
                                         <p className="contant-no-in">{list.phone}</p>
                                         }

                                     </div>
                                     {getModerationstatus ==='yes' && list.sentmail =='no' &&
                                     <div className= "select-chk-block team-check" style={{display: 'block'}} >
                                         <label className="control control-checkbox">
                                             <input type="checkbox" id={index} className={`input-check-box ${userrarray.includes(list.user_id) === true ? "active":''}`}  name="enablesendmail"
                                                    onClick={(e) => this.handleEnableSendmail(e, list.user_id)}/>
                                             <div className="checkbox-indicator"></div>
                                         </label>
                                     </div>
                                    }
                                 </div>
                             )}

                         </div>
                         </React.Fragment>
                         }
                         {(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === "root") &&
                         <div className={'float-button-box'} onClick={(e)=>this.showFormModal(e, 0)} title="New User">
                             <span className={'icon-in icon-plus'}></span>
                         </div>
                             }
                         {(getProfile().usertype === "User" && addPermission === true) &&
                         <div className={'float-button-box'} onClick={(e)=>this.showFormModal(e, 0)} title="New User">
                             <span className={'icon-in icon-plus'}></span>
                         </div>
                             }
                         {this.state.showFormModal &&
                         <ShowFormModal formName={this.state.formName}
                                        collectionId ={this.state.collectionId}
                                        referenceId ={this.state.collectionId}
                                        hideFormModal={this.hideFormModal} />
                         }
                         {this.state.confirmUsermail &&
                         <>

                         <AlertModal id="proceed"
                                     title="Do you need to send activation mail?"
                                     OK_button_text="Yes, Proceed"
                                     OK_action={() => this.sendUserEmail()}
                                     showModal={this.state.confirmUsermail}
                                     CANCEL_action={() => this.confirmUserEmail()}
                         />
                         </>
                         }
                         {this.state.emailErrorModal &&
                         <>

                             <AlertModal id="proceed"
                                         title="User not created. Email id already exist"
                                         OK_button_text="Ok"
                                         showModal={this.state.emailErrorModal}
                                         CANCEL_button={'hide'}
                                         OK_action={()=>this.setState({emailErrorModal:false})}
                             />
                         </>
                         }
                     </div>


</>


        );
    }
}



const mapStateToProps = state => ({
    todoListing:state.TaskReducer.listtoDo,
    menuClickData:state.CommonReducer.menuClick,
    listUsers:state.UsersReducer.listAllUsers,
    searchData:state.CommonReducer.searchparamdata,
    loader:state.CommonReducer.loader,
    getSavedForm : state.CollectionsReducer.getSavedForm,
    getModerationstatus : state.UsersReducer.getModeration,
    sendEmailusers : state.UsersReducer.sendEmailusers,
	listUsersPermission:state.UsersReducer.listUsersPermissiondata,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    users:usersActions.getallUsers,
    showform : collectionActions.showform,
    resetForm : collectionActions.resetForm,
    usersallcompanies:usersActions.allCompanies,
    usersalldesignations:usersActions.allDesignations,
    sendEmailTousers:usersActions.sendEmailTousers,
    usersallreportees:usersActions.allReportees,
    moreusers:usersActions.listmoreUsers,
    getModeration:usersActions.getModeration,
    activityTracking:rootActions.commonActions.activityTracking
})(Users));


