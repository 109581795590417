import { render } from 'react-dom';
import './index.css';
/**
 * Projection sample
 */
import * as React from "react";
import { MapsComponent, Inject, LayersDirective,
    Legend,
    LayerDirective, Bubble, BubblesDirective, BubbleDirective, MapsTooltip, Zoom } from '@syncfusion/ej2-react-maps';
import { SampleBase } from './sample-base';

import * as data from './map-data/bubble-datasource.json';
import * as map from "./worldmap.json";
let shapeData = map;
let datasource = Object.values(data);
const SAMPLE_CSS = `
    .control-fluid {
		padding: 0px !important;
		width:"100%",height:"100%"
    }`;


export class AreaColorMap extends SampleBase {
    render() {
        return (
            <div className="control-pane row">
                <style>{SAMPLE_CSS}</style>
                <div className="control-section row">
                    <div className="col-md-12">
                        <MapsComponent
                            id="areacolormap"
                            tooltipRender={this.tooltipRender.bind(this)}
                            loaded={this.onMapsLoad.bind(this)}
                            load={this.load}
                            ref={m => (this.mapInstance = m)}
                            zoomSettings={{
                                enable: false
                            }}
                            legendSettings={{
                                visible: true,
                                mode: "Interactive",
                                position: "Left",
                                orientation: "Vertical",
                                height: "70%",
                                width: "10"
                            }}

                        >
                            <Inject services={[MapsTooltip, Legend]} />
                            <LayersDirective>
                                <LayerDirective
                                    shapeData={shapeData}
                                    shapePropertyPath="name"
                                    shapeDataPath="name"
                                    dataSource={datasource}
                                    tooltipSettings={{
                                        visible: true,
                                        valuePath: "name",
                                        template:
                                            '<div id="tooltemplate" style="width: 90px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding-bottom: 10px;padding-top: 10px;padding-left: 10px;padding-right: 10px;border: 1px #abb9c6">' +
                                            '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${country}</center></div>' +
                                            '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' +
                                            '<div><span style="font-size:13px;color:#cccccc">Finalist : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${value1}</span></div>' +
                                            '<div><span style="font-size:13px;color:#cccccc">Win : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${value2}</span></div></div>'
                                    }}
                                    shapeSettings={{
                                        fill: "lightgrey",
                                        colorMapping: [
                                            {
                                                value: "1",
                                                color: "#b3daff"
                                            },
                                            {
                                                color: "#80c1ff",
                                                value: "2"
                                            },
                                            {
                                                color: "#1a90ff",
                                                value: "3"
                                            },
                                            {
                                                color: "#005cb3",
                                                value: "7"
                                            }
                                        ],
                                        colorValuePath: "value1"
                                    }}
                                />
                            </LayersDirective>
                        </MapsComponent>
                    </div>
                </div>


            </div>
        );
    }
    onMapsLoad(args) {
        let maps = document.getElementById("areacolormap");
        maps.setAttribute("title", "");
    }
    tooltipRender(args) {
        if (!args.options["data"]) {
            args.cancel = true;
        }
    }
}


export default AreaColorMap;