import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {
    getDataId,
    getRedirectUrlForEmbedForms,
    getUrlSegment,
    isJsonObjectEmpty,
    parseQueryStr
} from "../../utilities/CustomFunctions";
import ShowFormModal from "../views/ShowFormModal";
import * as collectionActions from "../../actions/Collections";
import LoaderSvg from "../views/LoaderSVG";
import {getProfile} from "../../utilities/AuthService";
import * as WorkFlowActions from "../../actions/WorkFlow";

class SharingFormModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formName: '',
            formDesc: '',
            collectionId: '',
            collecteddataid: '',
            taskList: [],
            loading: 0,
            lastOffset: 0,
            totalitems: 0,
            todotaskdata: [],
            offset: 20,
            successMessage: '',
            submissionCompleted: false,
            referenceId: '',
            uid: 2,
            usertype: 'Superadmin',
            formaccess: 'external',
            nextStageOnFormSubmit: false,
            recordWfParams: {} //record assigned via email from workflow

        }
    }

    componentDidMount() {
        let collectionId = atob(getUrlSegment(3));
        let formName = '';
        let formDesc = '';
        this.props.showform({_id: collectionId}, this.state.formaccess);

        let queryStr = this.props.location.search;

        console.log(queryStr)
        let collecteddataid = '';
        if (queryStr !== '') {
            collecteddataid = getDataId(queryStr);
            console.log(collecteddataid)
        }

        let referenceId = getUrlSegment(6) ? getUrlSegment(6) : '';
        if (referenceId === '') {
            referenceId = collectionId;
        }
        if (collecteddataid !== '') {
            this.props.getFormValues({_id: collecteddataid, collectionid: collectionId, metainfo: true, wfinfo: true});

            this.setState({
                formName: formName,
                formDesc: formDesc,
                collectionId: collectionId,
                collecteddataid: collecteddataid,
                referenceId: referenceId
            })
        } else {
            this.setState({
                formName: formName,
                formDesc: formDesc,
                collectionId: collectionId,
                referenceId: referenceId
            })
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged) {
            if (this.state.nextStageOnFormSubmit) { // Workflow next stage assigned via email
                //When form submitting via external email
                this.processAssignedNextStage();

            }
            ////console.log("adasdadss")
            this.setState({submissionCompleted: true, successMessage: "Form Data Saved Successfully"})
        }
        if (this.props.fieldUpdateData.data !== prevProps.fieldUpdateData.data) {
            if (this.props.fieldUpdateData.data.success === true) {
                this.setState({submissionCompleted: true, successMessage: "Form Data Saved Successfully"})
            }
        }

        if (this.props.getFormData.wfinfo !== prevProps.getFormData.wfinfo) {
            let nextStageOnFormSubmit = this.state.nextStageOnFormSubmit;
            let submissionFormId = '';

            let wfinfo = this.props.getFormData.wfinfo || [];
            let currentstage = {};
            if (wfinfo.length > 0) {
                currentstage = wfinfo[wfinfo.length - 1];

                console.log(currentstage)

                if (!isJsonObjectEmpty(currentstage) && currentstage.submissiontype === 'withoutdecision' && currentstage.submissionform !== '' && currentstage.submissionform !== null && currentstage.meta_form.length === 0) {
                    nextStageOnFormSubmit = true;
                    submissionFormId = currentstage.submissionform;
                    this.setState({
                        nextStageOnFormSubmit: nextStageOnFormSubmit
                    })
                    this.setCloseWFParams();
                }
            }
        }
        if (this.props.stagetaskInfoDet !== prevProps.stagetaskInfoDet) {

            let wfinfo = this.props.getFormData.wfinfo || [];
            if (wfinfo.length > 0) {
                let currentstage = wfinfo[wfinfo.length - 1];
                let workflowid = currentstage.workflowid;
                let recordid = currentstage.recordid;
                let collectionid = currentstage.collectionid;
                let stageid = currentstage.stageid;
                let _id = currentstage._id;


                let nextstageid = currentstage.decisionstage;

                let finalStage = nextstageid === '' ? true : false;


                let params = {

                    previoustask: "0",
                    stageid: nextstageid,
                    collectionid: collectionid,
                    recordid: recordid,
                    workflowid: workflowid,
                    uid: 0,
                    invokeid: _id,
                    statusinfo: {}
                }
                if (finalStage) {
                    params.stageid = 'final';
                }

                this.setState({
                    recordWfParams: params
                })

                //this.props.processAssignedNextStage(params);

            }
        }

    }

    setCloseWFParams = () => {
        let wfinfo = this.props.getFormData.wfinfo || [];
        if (wfinfo.length > 0) {
            let currentstage = wfinfo[wfinfo.length - 1];
            let workflowid = currentstage.workflowid;
            let stageid = currentstage.stageid;
            if (stageid && workflowid) {
                this.props.getstagetaskinfo({stageid: stageid, workflowid: workflowid});
            }

        }
    }

    processAssignedNextStage = () => {
        this.props.processAssignedNextStage(this.state.recordWfParams);
    }

    render() {
        let collection = this.props.collectionData;
        return (
            <React.Fragment>
                {this.state.loading === 1 &&
                <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                }
                {!this.state.submissionCompleted &&
                <ShowFormModal
                    formName={this.state.formName}
                    formDesc={this.state.formDesc}
                    collectionId={this.state.collectionId}
                    referenceId={this.state.referenceId}
                    hideFormModal={this.hideFormModal}
                    editId={this.state.collecteddataid}
                    Formtemplate={collection.CollectionInfo.formtemplate}
                    iosForm={false}
                    actiontype={''}
                    nextStageOnFormSubmit={this.state.nextStageOnFormSubmit}
                    processAssignedNextStage={this.processAssignedNextStage}
                />
                }
                {this.state.submissionCompleted &&
                <CompletedBlock/>
                }
            </React.Fragment>

        );
    }
}


const mapStateToProps = state => ({
    collectionData: state.CollectionsReducer.listCollectionDetaildata,
    getSavedForm: state.CollectionsReducer.getSavedForm,
    fieldUpdateData: state.CeShareView.fieldUpdateData,
    getFormData: state.CollectionsReducer.getFormData,
    stagetaskInfoDet: state.workflowReducer.StageTaskInfo,

});
export default withRouter(connect(mapStateToProps, {
    showform: collectionActions.showform,
    getFormValues: collectionActions.getFormData,
    getstagetaskinfo: WorkFlowActions.StageTaskInfoDet,
    processAssignedNextStage: WorkFlowActions.processAssignedNextStage,


})(SharingFormModal));


function CompletedBlock(Props) {
    return (
        <div className="task-details-wrapper niceScroll">
            <div className="task-details-inside">
                <div className="task-split task-split-full">

                    <div className="task-details-body-block">

                        <div className="task-details-body-container">
                            <h5>Form Data Saved Successfully</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {CompletedBlock};