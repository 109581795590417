import React from 'react';
import {dateYMD, xAmzDate} from "../lib/react-s3/Date";
import Signature from "../lib/react-s3/Signature";
import Policy from "../lib/react-s3/Policy";
import ReactDOM from 'react-dom';
import ProgressBars from './ProgressBar';
import {BUCKET_CONFIG, ES_INDEX} from '../constants/index';
import {
    isVideo,
    isImage

} from "../utilities/CustomFunctions";
let loaded=0;
let count=0;
let status=true;
var percentComplete=0
var loaded1=0
class ReactS3 {
    static upload(file, config, fn) {
        const fd = new FormData();
        const key = `${config.albumName ? config.albumName + '/' : ''}${fn}`;
        const url = `https://${config.bucketName}.s3.amazonaws.com/`
        fd.append('key', key);
        fd.append('acl', 'public-read');
        fd.append('Content-Type', file.type);
        fd.append('x-amz-meta-uuid', '14365123651274');
        fd.append('x-amz-server-side-encryption', 'AES256')
        fd.append('X-Amz-Credential', `${config.accessKeyId}/${dateYMD}/${config.region}/s3/aws4_request`);
        fd.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
        fd.append('X-Amz-Date', xAmzDate)
        fd.append('x-amz-meta-tag', '');
        fd.append('Policy', Policy.getPolicy(config))
        fd.append('X-Amz-Signature', Signature.getSignature(config, dateYMD, Policy.getPolicy(config)));
        fd.append("file", file);

        return new Promise(async(resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.addEventListener("progress", updateProgress);
            xhr.upload.addEventListener("loadstart", startUpload, false);
            xhr.upload.addEventListener("progress", updateProgress, false);
            xhr.upload.addEventListener("load", uploadCompleted, false)
            xhr.open('POST', url);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve({
                        bucket: config.bucketName,
                        key: `${config.albumName ? config.albumName + '/' : ''}${fn}`,
                        location: `${url}${config.albumName ? config.albumName +'/' : ''}${fn}`,
                        result: xhr.response
                    })

                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send(fd);


            function startUpload (e) {
                count=0
                loaded=0
                percentComplete=0
                loaded1=0
               // loaded=parseInt(loaded) + e.total
            }

            function updateProgress (e) {
                if (e.lengthComputable) {
                    if(config.Total  === undefined){
                        config.Total=   e.loaded
                    }
                     loaded1 =loaded+e.loaded
                     percentComplete =loaded1 / config.Total * 100;
                   if(percentComplete >= 100){
                       percentComplete =100;
                   }


                } else {
                    // Unable to compute progress information since the total size is unknown
                }
            }

            function uploadCompleted (e) {
                loaded=status?e.total:(loaded)
                status=false
             //console.log("üpload completed");
            }
        })
    }
}





export function  FileUploadHandler(ename,efiles, progressContainer,count,completed,total){
    const config =  BUCKET_CONFIG
    var albumName='documents';
    if(isImage(efiles.name)){
         albumName ='images'
    }
    else if(isVideo(efiles.name)){
        albumName ='videos'
    }
    var uploadQ= {}
          config.albumName=albumName
          config.progressContainer=progressContainer;
          config.Count=count;
          config.Completed=completed;
          config.Total=total;

        const file = efiles;
        const fnarr = file.name.split('.');
        const fn = ES_INDEX + new Date().getTime() + "." + fnarr[fnarr.length-1];
        let tmpobj = {'stat':'uploading', 'fn':fn}
        return  ReactS3.upload(file, config, fn)
            .then((data) => {
                data['file']=file.name
                data['filesize']=file.size
                data['type']=file.type
                return Promise.resolve(data);
                }
            )
}
