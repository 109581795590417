import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";
import ProjectCycleCard from "../../views/ProjectCycleCard";
import * as phaseActions from "../../../actions/Phases";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal";
import AddNewMilestone from "./AddNewMilestone";
import AddNewPhase from "../phase/AddNewPhase";
import NoData from "../../views/NoData";
import OutsideAlerter from "../../views/OutsideAlerter";
import * as projectActions from "../../../actions/Project";
import LoaderSvg from "../../views/LoaderSVG";
import AlertModal from "../../views/AlertModal";


class ProjectCycle extends Component {

    constructor(props) {
        super(props);

        this.state={
            addOptions: false,
            NewMilestoneModal : false,
            milestoneAction:'',
            phaseID:0,
            Addopen:false,
            PhasesList:[],
            expandHeader:false,
            showOptions:false,
            removePhase:false

        }
        this.showAddOptions = this.showAddOptions.bind(this);
        this.expandHeader = this.expandHeader.bind(this);
        this.openSection = this.openSection.bind(this);
        this.getPhaseList = this.getPhaseList.bind(this);
        this.showCreateNewMilestoneModal = this.showCreateNewMilestoneModal.bind(this);
        this.AddmilestonemodalClose = this.AddmilestonemodalClose.bind(this);
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);
        this.showProgress = this.showProgress.bind(this);
        this.showOptions = this.showOptions.bind(this);
        this.showRemoveModal =  this.showRemoveModal.bind(this);
        this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
        this.removePhase = this.removePhase.bind(this);
    }



    componentDidMount() {
        this.setState({loading: 1});
        var projectId = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/management/'+projectId);
        this.getPhaseList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.milestoneData.milestonesaved !== this.props.milestoneData.milestonesaved){
            var beta = this;
            setTimeout(function () {
                beta.getPhaseList();
                beta.AddmilestonemodalClose();
            }, 1000)

        }
        if(prevProps.phaseDetails.phaseSaved !== this.props.phaseDetails.phaseSaved){
            var beta = this;
            setTimeout(function () {
                beta.getPhaseList();
                beta.AddmodalClose();
            }, 1000)


        }
        if(prevProps.listAllPhaseMilestoneData.listphasemilestone !== this.props.listAllPhaseMilestoneData.listphasemilestone)
        {
            this.setState({
                PhasesList: this.props.listAllPhaseMilestoneData.listphasemilestone,
                loading: 0,
                addOptions:false
            });
            this.AddmodalClose();
        }
    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }

    showAddOptions(){
        this.setState({addOptions :!this.state.addOptions})
    }
    openSection(e, index){

        //(this.state.openSection.indexOf(id) > -1) ? this.state.openSection.splice(this.state.openSection.indexOf(id), 1) : this.state.openSection.push(id);
        //this.setState({openSection: !this.state.openSection})
        if(this.state.openSection === index){
            index = -1;
        }
        this.setState({openSection:index})
    }
    getPhaseList() {
        var phaseParams  = {
            "projectid":atob(getUrlSegment(4))
        }
        this.props.phasesmilestones(phaseParams);

    }

    showCreateNewMilestoneModal(action,id){
        if(action === 'update') {
            var data={
                "_id": id,
            }
            this.props.getmilesonedetails(data);
        }

        this.setState({NewMilestoneModal:!this.state.NewMilestoneModal,
            milestoneAction:action,
        })

    }
    AddmilestonemodalClose(){
        this.setState({NewMilestoneModal:false})
    }

    AddmodalOpen(e, pid){
        e.stopPropagation();
        if(pid){
            this.setState({phaseID : pid});
            var phaseParams  = {
                uid :  getProfile().id,
                usertype : getProfile().usertype,
                phaseId : pid
            }
            this.props.getphasedetails(phaseParams);

        }
        this.setState({Addopen : true,phaseID: 0});
    }

    AddmodalClose(){
        this.setState({Addopen : false , vertical_id :0});
    }

    showProgress(workDone,totalCount){
        let workProgress = 0;
        if(totalCount > 0){
            let currentProgress = Math.round( ( workDone / totalCount) * 100);
            let remainProgress = currentProgress % 5;
            workProgress = currentProgress - remainProgress;
        }
        return workProgress;
    }

    showOptions(index){

        if(this.state.showOptions === index){
            index = -1;
        }
        this.setState({showOptions:index})
    }

    showRemoveModal(){
        this.setState({removePhase:true})
    }
    cancelDeleteModal(){
        this.setState({removePhase : false});
    }
    removePhase(){
        this.props.removePhase()
    }
    render() {
        let PhasesList = this.state.PhasesList;
        let users = this.props.listUsers.Users.data;
        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>

                <div className="ibt-body top-bot-pad">
                    {(this.props.listAllPhaseMilestoneData.loading || this.state.loading === 1) &&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                    }
                    {this.state.PhasesList.length === 0 &&
                    <div className='absolute-holder'>
                        <NoData
                            msg={`No Phases added yet`}

                        />
                    </div>
                    }
                        <div id="accordion" className="task-tab project-cycle-tab">
                            {PhasesList.map((list,index) =>
                            <div className="card" key={'phase'+index} >
                                <div className={list.milestones.length > 0 ?"card-header proj-card-header cursor with-milestone" : "card-header proj-card-header cursor" }  id={"headingTwo"+index} onClick={(e) => this.openSection(e, index)}>
                                    <h5 className="mb-0">

                                        <button className={this.state.openSection === index ? "btn btn-link" : "btn btn-link collapsed"} data-toggle="collapse"
                                                data-target={"#col-"+index} aria-expanded={this.state.openSection === index ? "true" : "false"}
                                                 aria-controls={"col-"+index}>
                                            <span
                                                className="button-text ico-lib section-flag">{list.phaseName}</span>
                                        </button>
                                        <div className="main-head-right-block">
                                            <ul className="project-cycle-info">
                                                {/*<li className="mob-hide">Completed On: <span className="fb">29 Sep 2017</span>*/}
                                                {/*</li>*/}
                                                <li className="mob-hide"> <span
                                                    className="fb">{list.milestones.length} Milestones</span></li>
                                                {list.milestones.length > 0 &&
                                                <li><span className="proj-tag proj-ontrack">{list.track}</span></li>
                                                }
                                            </ul>
                                            {/*{this.props.createuser === getProfile().id &&*/}
                                            {list.milestones.length > 0 &&
                                            <div className="form-option-block nav-option">
                                                <button className="ico-lib opt-form-ico" onClick={() => this.showOptions(index)}></button>
                                                {/*{(this.state.showOptions === index) &&*/}
                                                {/*<OutsideAlerter*/}
                                                {/*    status={this.state.showOptions}*/}
                                                {/*    Triggered={() => this.showOptions()}>*/}
                                                {/*    <ul className="panel-option">*/}
                                                {/*        <li>*/}
                                                {/*            <a className="panel-option-click ico-lib  wf-dt" onClick={(e) => {*/}
                                                {/*                this.showRemoveModal()*/}
                                                {/*            }}> Remove Phase</a>*/}
                                                {/*        </li>*/}

                                                {/*    </ul>*/}
                                                {/*</OutsideAlerter>*/}
                                                {/*}*/}
                                            </div>
                                            }

                                        </div>

                                    </h5>
                                </div>
                                <div id={"col-"+index} className={this.state.openSection === index ? "collapse show" : "collapse"} aria-labelledby={"headingTwo"+index}
                                     data-parent="#accordion">
                                     <ProjectCycleCard
                                         history={this.props.history}
                                        milestonelist={list.milestones}
                                         showProgress={this.showProgress}
                                         showCreateNewMilestoneModal={this.showCreateNewMilestoneModal}
                                     />
                                </div>
                            </div>
                            )}
                        </div>



                    <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Task">
                        <button className="float-button ico-lib float-add" onClick={()=>this.showAddOptions()}></button>

                        {this.state.addOptions === true &&
                        <OutsideAlerter status={this.state.addOptions} Triggered={() => this.showAddOptions()}>
                        <div className="add-users__list--pop-up milestone" id="added-user-popup" style={{display: "block"}}>
                            <ul>
                                <li>
                                    <a className="milestone-plus-new ico-lib ico-lib-new-task" onClick={(e) => {this.showCreateNewMilestoneModal('create')}}>New Milestone</a>
                                </li>
                                <li>
                                    <a className="milestone-plus-new ico-lib ico-lib-new-sec" onClick={(e)=>this.AddmodalOpen(e, 0)}>New Phase</a>
                                </li>

                            </ul>
                        </div>
                        </OutsideAlerter>
                        }
                    </div>
                </div>
                {this.state.NewMilestoneModal &&
                <Modal title={this.state.milestoneAction === "create" ? 'Create New Milestone' : 'Update Milestone'} Close ={this.AddmilestonemodalClose}>
                    <AddNewMilestone
                        AddmilestonemodalClose = {this.AddmilestonemodalClose}
                        action={this.state.milestoneAction === "create"? "create":"update"}
                        users={users}
                    />

                </Modal>
                }
                {this.state.Addopen &&
                <Modal title={(this.state.phaseID === 0 ? 'Create New Phase' : 'Update Phase')} Close ={this.AddmodalClose}>
                    <AddNewPhase
                        AddmodalClose = {this.AddmodalClose}
                        action={this.state.phaseID === 0 ? "create":"update"}
                    />

                </Modal>
                }
                {this.state.removePhase === true &&
                <AlertModal id="remove-phase"
                            title="Are you sure want to remove the Phase from this Project?"
                            OK_button_text="Yes, Delete It"
                            OK_action={() => this.removePhase()}
                            showModal = {this.state.removePhase}
                            CANCEL_action={() => this.cancelDeleteModal()}
                />
                }
            </div>



        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
    listAllPhaseMilestoneData:state.PhaseReducer.getPhasemilestoneDetails,
    phaseDetails : state.PhaseReducer.SavePhase,
    listUsers:state.UsersReducer.listUsersdata,
    milestoneData:state.ProjectReducer.SaveMilestone,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    phasesmilestones:phaseActions.listphasesmilestone,
   //removePhase:phaseActions.removePhase
})(ProjectCycle));
