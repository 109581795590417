import React, {Component} from 'react';
import {Card,Col,Toast,Row,Button,Tooltip,OverlayTrigger} from "react-bootstrap"
import ReactNotifications from 'react-browser-notifications';
import {getProfile} from "../../utilities/AuthService";
import {ES_INDEX,SHARED_LOGIN} from "../../constants";
import notificationSound from "../views/plucky.mp3";

class NotificationPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        this.showNotifications = this.showNotifications.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }


    componentDidMount() {
    }


    componentWillUpdate(nextProps) {

    }


    componentDidUpdate(prevProps, nextProps, snapshot) {
        if(this.props.show){
            this.showNotifications()
            var audio = new Audio(notificationSound);
            audio.play();
        }


    }
    componentWillReceiveProps(nextProps, prevProps) {


    }

    showNotifications() {
        // If the Notifications API is supported by the browser
        // then show the notification
        if(this.n.supported()) this.n.show();
    }

    handleClick(event) {
            this.n.close(event.target.tag);
    }


    render() {
        const {show} = this.props;
        if (show) {
            return (
                <div className={`toaster-notification`}>
                    <Row>
                        <Col>
                            <Toast onClose={() => this.props.handleClose(this.props.status)} show={show} delay={3000}
                                   autohide>
                                <Toast.Header>
                                    <strong className="mr-auto" onClick={()=>this.props.loadChatHistory()}>{this.props.msg}</strong>
                                </Toast.Header>
                            </Toast>
                        </Col>
                    </Row>
                    <ReactNotifications
                        onRef={ref => (this.n = ref)} // Required
                        title="Bsynapse" // Required
                        body={this.props.msg}
                        icon={SHARED_LOGIN+'/assets/images/min-logo.png'}
                        tag=""
                        timeout="4000"
                        onClick={event => this.handleClick(event)}
                    />

                </div>
            );
        }
        else {
            return null;
        }
    }
}



export default NotificationPanel;


