import React, {Component} from 'react';
import {connect} from "react-redux";
import * as collectionActions from "../../../actions/Collections"
import {withRouter} from 'react-router';
import * as commonActions from "../../../actions/Common";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal"

import AddToDoTask from "../AddToDoTask"
import SimpleModal from "../../views/SimpleModal"
import AddNewForm from "./AddNewForm"
import {Accordion, Button, ButtonToolbar, Card} from "react-bootstrap";
import AutoComplete from "../../views/AutoComplete";
import {ES_INDEX, FRMSIMG_PATH} from '../../../constants'
import * as usersActions from "../../../actions/Users";
import LoaderSvg from "../../views/LoaderSVG";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import ListCollection from "../../views/ListCollection";
import NoData from "../../views/NoData";
import * as projectActions from "../../../actions/Project";

class ProjectDataCollection extends Component {

    constructor(props) {
        super(props);
        this.state= {
            expandHeader:false,
            optionOpened: 0,
            action: '',
            search: '',
            group: 'All',
            selectedCollection: {},
            selectedTags : [],
            selectedToUsers : [],
            tags: [],
            newTags:[],
            createNewForm:false,
            lastOffset: 0,
            totalitems: 0,
            collections: [],
            offset: 30,
            Collections:[],
            loading:0,
            viewtype : 'tile',
            collectiontype:'',
            collectionId:0,
            showOption:false

        }
        this.manageOptionsDropDown = this.manageOptionsDropDown.bind(this);
        this.updateAction = this.updateAction.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.shareCollection = this.shareCollection.bind(this);
        this.showCreateNewFormModal = this.showCreateNewFormModal.bind(this);
        this.closeCreateNewFormModal = this.closeCreateNewFormModal.bind(this);
        this.expandHeader = this.expandHeader.bind(this)
        this.listenToScroll = this.listenToScroll.bind(this);
        this.collectionView = this.collectionView.bind(this);
        this.getCollections=this.getCollections.bind(this);
        this.showOptions =this.showOptions.bind(this);

    }
    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }
    componentDidMount() {

        //console.log(this.props.listAllCollections.ListAllCollections);


        var prjctdtlid = getUrlSegment(4);
        let project_id = atob(prjctdtlid);
        this.getCollections();

        this.props.users(getProfile().id,'User','',ES_INDEX);

        const container = document.querySelector('.ibt-body');
        container.addEventListener('scroll', this.listenToScroll)
    }
    componentWillUnmount() {
        const container = document.querySelector('.ibt-body');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.ibt-body')
        let lastOffset = document.querySelector('.team-wrapper').childElementCount;
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;
        var prjctdtlid = getUrlSegment(4);
        let project_id = atob(prjctdtlid);
        if (contentHeight <= scrollTop) {
            if(this.props.listAllCollections.Search === '') {
                let params = {group : this.props.listAllCollections.Group || this.state.group, search : this.state.search, uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : lastOffset, offset : this.state.offset, projectId : project_id,collectiontype:this.state.collectiontype};
                this.props.updatecollections(params);
            } else {
                let params = {group : this.props.listAllCollections.Group || this.state.group, search : this.props.listAllCollections.Search, uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : lastOffset, offset : this.state.offset, projectId : project_id,collectiontype:this.state.collectiontype};
                this.props.moreSearchCollections(params)
            }

        }
    }
    updateAction(e, act, collection, id) {
        e.stopPropagation();
        this.setState({
            action:act,
            collectionId: id,
            optionOpened: 0,
            selectedCollection: [collection],
            selectedToUsers: []
        });
    }
    modalOpen(){
        this.setState({action:'close'});
    }

    collectionView(e,statusType)
    {
        this.setState({viewtype: statusType});
    }
    getCollections(){
        var prjctdtlid = getUrlSegment(4);
        let project_id = atob(prjctdtlid);
        var collectionType = getUrlSegment(3);
        var type='';
        if(collectionType === 'iotcollections') {
            this.props.mainClick('project', '/project/details/iotcollections/' + prjctdtlid);
            this.setState({collectiontype:'iotcollection'})
            type='iotcollection';
        }
        else {
            this.props.mainClick('project', '/project/details/collections/' + prjctdtlid);
            this.setState({collectiontype:'default'})
            type='default';
        }
        let params = {group : this.state.group,
            search : this.state.search,
            uid : getProfile().id,
            usertype : getProfile().usertype,
            managecollection : 0,
            limit : 0,
            offset : this.state.offset,
            projectId : project_id,
            collectiontype:type
        };
        this.props.collections(params);
        }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var prjctdtlid = getUrlSegment(4);
        let project_id = atob(prjctdtlid);
        if (this.props.sharedCollection.shared !== prevProps.sharedCollection.shared) {
            //this.props.collections(this.state.group, this.state.search, getProfile().id, 'root', 0, "0", this.state.offset)
        }
        if (prevProps.HistoryPushData !== this.props.HistoryPushData) {
            this.modalOpen();
        }

        if (prevProps.listAllCollections.ListAllCollections !== this.props.listAllCollections.ListAllCollections) {
            this.setState({
                Collections: this.props.listAllCollections.ListAllCollections.data,
                loading: 0
            });
            this.closeCreateNewFormModal();
        }
        if (prevProps.match.path !== this.props.match.path) {
            this.setState({lastOffset: 0},
                function () {
                    this.getCollections();
                })
        }
        if (prevProps.collectionSaveInfo.collectionSaved !== this.props.collectionSaveInfo.collectionSaved) {
            var beta = this;
            setTimeout(function () {
                beta.getCollections();
                beta.closeCreateNewFormModal();
                beta.setState({loading:0})
            }, 1000)
        }

    }
    showOptions(index){
        if(this.state.showOption === index){
            index = -1;
        }
        this.setState({showOption:index})
    }
    manageOptionsDropDown(e, id) {
        e.stopPropagation();
        this.setState({optionOpened: id});
    }
    resultDataList( result, reference) {
        this.setState({ [reference] : result });
    }
    shareCollection() {
        let users = [];
        let cnt = 0;
        this.state.selectedToUsers.map(a => {
            let user = {"type": a.user_type ,"id": + a.id };
            users.push(user);
        });

        this.props.sharecollection(this.state.collectionId, getProfile().id, users);
        this._interval = setInterval(() => {
            if(this.props.sharedCollection.shared === 1) {
                if(this.props.sharedCollection.msg === '') {
                    this.setState({action: ''});
                } else {
                    alert(this.props.sharedCollection.msg)
                }
                clearInterval(this._interval);
            }
        }, 500);

    }
    showCreateNewFormModal(e,id){
        this.setState({createNewForm : !this.state.createNewForm,
            collectionId:id})
        if(id !== 0){
            var params= {
                        "_id": id,
                        "linkedcollectioninfo": "yes",
                        "showinheritanceinfo": "yes",
                        "showfieldinfo": "no"
                        }

            this.props.getCollectionInfo(params)
        }

    }

    closeCreateNewFormModal(){
        this.setState({createNewForm : false,collectionId:0})
    }





    render() {
        let Collections = [];
        if(this.props.listAllCollections.Search === '') {
            Collections = this.props.listAllCollections.ListAllCollections.data;
            //Collections = this.state.Collections;
        } else {
            Collections = this.props.listAllCollections.searchResult.data;
        }

        return (
            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                 <ProjectHeader expandHeader={this.expandHeader}/>
                <div className="ibt-body">
                    <div className="pj-data-container">
                        <div className="pj-team-wrapper project-team-holder collection-pad-top">
                            {Collections.length > 0 &&
                                <div className="task-nav align-cent">
                                    <div className="task-nav-left">
                                        <div className="filter-tag-block"></div>
                                    </div>
                                    <div className="task-nav-right">
                                        <div className="resources-tab-wrap">
                                            <ul className="resources-filters-icons">
                                                <li><a
                                                    onClick={(e) => this.collectionView(e, 'tile')}
                                                    className={`rs-tab-btn ico-lib-v1 rc-tab-list ${this.state.viewtype === 'tile' ? "active" : ""}`}></a>
                                                </li>
                                                <li><a
                                                    onClick={(e) => this.collectionView(e, 'card')}
                                                    className={`rs-tab-btn ico-lib-v1 rc-tab-grid ${this.state.viewtype === 'card' ? "active" : ""}`}></a>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            }
                            {this.props.loader.loaderType === 'collection-list' && this.props.loader.status === true &&
                            <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                            }
                            {Collections.length === 0 &&
                            <div className='absolute-holder'>
                                <NoData
                                    msg={`No Collections added yet`}

                                />
                            </div>
                            }
                            <div
                                className={this.state.viewtype === 'card' ? "team-wrapper" : "team-wrapper status-view"}>
                                {Collections.map((list, index) =>
                                    <ListCollection
                                        list={list}
                                        key={"collection-" + index}
                                        history={this.props.history}
                                        optionOpened={this.state.optionOpened}
                                        viewtype={this.state.viewtype}
                                        manageOptionsDropDown={this.manageOptionsDropDown}
                                        updateAction={this.updateAction}
                                        section="project"
                                        showCreateNewFormModal={this.showCreateNewFormModal}
                                        showOption={this.state.showOption}
                                        showOptions={this.showOptions}
                                        index={index}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="float-button-block">
                        <button className="float-button ico-lib float-add" data-toggle="modal" data-target="#addField" onClick={(e)=>this.showCreateNewFormModal(e,0)}></button>
                    </div>
                </div>
                {this.state.createNewForm &&
                <Modal title={this.state.collectionId === 0 ? 'Create New Form': 'Update New Form'} Close ={this.closeCreateNewFormModal.bind(this)}>
                    <AddNewForm />
                </Modal>

                }
            </div>
        );
    }
}


const mapStateToProps = state => ({
    searchData: state.CommonReducer.searchparamdata,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    listUsers:state.UsersReducer.listAllUsersdata,
    haveLoader : state.CollectionsReducer.manageoader,
    sharedCollection : state.CollectionsReducer.sharedCollection,
    loader:state.CommonReducer.loader,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    collectionSaveInfo:state.CollectionsReducer.saveCollection
});

export default withRouter(connect(mapStateToProps, {
    mainClick: rootActions.commonActions.MenuClick,
    collections: collectionActions.listallcollections,
    updatecollections: collectionActions.updatecollections,
    moreCollections: collectionActions.updatecollections,
    moreSearchCollections: collectionActions.updatesearchcollections,
    sharecollection: collectionActions.sharecollection,
    users:usersActions.listAllUsers,
    getCollectionInfo:projectActions.getCollectionInfo
})(ProjectDataCollection));


