import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as portfolioActions from "../../../actions/Portfolio";
import DatePickerBox from "../../views/DatePickerBox"
import AutoComplete from "../../views/AutoComplete"
import {formatDate} from "../../../utilities/CustomFunctions";
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import LoaderSvg from "../../views/LoaderSVG";
import * as usersActions from "../../../actions/Users";
import * as verticalActions from "../../../actions/Program";
import * as phaseActions from "../../../actions/Phases";

class AddNewProject extends Component {

    constructor(props) {
        super(props);

        this.state={
            name:'',
            code:'',
            client:'',
            description : '',
            projectname:'',
            clientname:'',
            selectedSector:[],
            selectedVertical:[],
            project_id :0,
            nullname : 0,
            nulldesc:0,
            newprjctloading:0,
            newTags:[],
            miniMize:false,
            Maximise:false,
            Close:false,
            selectedToUsers : [],
            selectedResponsible:[],
            selectedAccountable:[],
            selectedConsultant:[],
            selectedInformer:[],
            selectedPhases:[],
            strtdate_err:'',
            enddate_err:'',
            responsibleError:false,
            accountableError:false,
            consultantError:false,
            informerError:false,
            phaseError:false,
            verticalError:false,
            sectorError:false

        };
        this.ProjectInputChange= this.ProjectInputChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.CreateProject=this.CreateProject.bind(this);
        this.usersList = this.usersList.bind(this);
        this.getPhaseList = this.getPhaseList.bind(this);
        this.getPortfolioList = this.getPortfolioList.bind(this);
        this.getVerticalList = this.getVerticalList.bind(this);
    }

    ProjectInputChange(e) {
        this.setState({[e.target.name]:e.target.value})
            if (e.target.value !== '') {
                e.target.classList.remove('val-error');
            }
    }

    componentDidMount() {
        if(this.props.action === "update"){
            this.setState({newprjctloading: 1})
        }
        this.usersList();
        var portfolioParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
        }
        this.props.portfolio(portfolioParams);
        this.getPhaseList();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.ProjectInfoDet !== prevProps.ProjectInfoDet) {
            let projectDetails = this.props.ProjectInfoDet.ProjectInfo;
            if(projectDetails){
                this.setState({newprjctloading:0});
                let projectInfo = projectDetails.projectinfo;
                let projectInfouser = projectDetails.projectusers;
                let u_id = projectInfo.crtuser;

                // var programParams  = {
                //     uid :  getProfile().id,
                //     usertype : getProfile().usertype,
                //     portfolioId : projectInfo.portfolio_id
                // }
                // this.props.program(programParams);
                // console.log(projectDetails.portfolio)
                let selectedAccountable = projectInfouser.filter((a) => a.type == "accountable");
                let selectedResponsible = projectInfouser.filter((a) => a.type == "responsible");
                let selectedConsultant = projectInfouser.filter((a) => a.type == "consultant");
                let selectedInformer = projectInfouser.filter((a) => a.type == "informer");
                let selectedPhases = projectDetails.phase;
                var selectedSector = projectDetails.portfolio;
                var selectedVertical = projectDetails.program;
                let prjct_desc= decodeURIComponent(escape(atob(projectInfo.description)));
                //let verticalname =
                this.setState({
                    project_id : projectInfo.project_id,
                    selectedSector:selectedSector,
                    selectedVertical:selectedVertical,
                    name : projectInfo.project_name,
                    code : projectInfo.projectcode,
                    client : projectInfo.clientname,
                    description : prjct_desc,
                    enddate :  new Date(projectInfo.enddate),
                    startdate : new Date(projectInfo.startdate),
                    selectedResponsible : selectedResponsible,
                    selectedAccountable:selectedAccountable,
                    selectedConsultant:selectedConsultant,
                    selectedInformer:selectedInformer,
                    selectedPhases:selectedPhases
                })
            }
            this.setState({newprjctloading: 0})

        }

        // if(prevProps.SaveProjectDet.projectSaved !== this.props.SaveProjectDet.projectSaved ) {
        //     let newProject = {
        //         "project_id": this.props.SaveProjectDet.projectSaved.data,
        //         "project_name": this.state.name,
        //         "description": this.state.description,
        //         "enddate": formatDate(this.state.enddate),
        //         "statusbucket": [
        //             {
        //                 "key": "New",
        //                 "doc_count": 0
        //             },
        //             {
        //                 "key": "InProgress",
        //                 "doc_count": 0
        //             },
        //             {
        //                 "key": "Done",
        //                 "doc_count": 0
        //             }
        //         ],
        //         "taskcount": 0};
        //     this.AddmodalClose();
        // }
    }

    handleStartDateChange(date){
        this.setState({
            startdate : date
        })
        document.getElementById('startdate').classList.remove('val-error');
    }
    handleEndDateChange(date){
        this.setState({
            enddate : date
        })
        document.getElementById('enddate').classList.remove('val-error');
    }
    usersList(searchText) {
        var user_data = { uid : "0", search : searchText? searchText : '',  offset : 100 , ignoreUsers : true, ignoreArrays :[], valueField : 'uniqueId', userOnly : true};
        this.props.users(user_data);
    }

    resultDataList( result, reference) {
        this.setState({ [reference] : result });
        if(reference === "selectedSector"){
            if (result.length  === 0) {
                this.setState({sectorError:true})
            }else{
                this.setState({sectorError:false})
            }
            if(result.length > 0) {
                this.getVerticalList(result[0].portfolioId)
            }
        }
        if(reference === "selectedPhases"){
            if (result.length  === 0) {
                this.setState({phaseError:true})
            }else{
                this.setState({phaseError:false})
            }
        }
        if(reference === "selectedVertical"){
            if (result.length  === 0) {
                this.setState({verticalError:true})
            }else{
                this.setState({verticalError:false})
            }
        }


    }
    CreateProject(event) {
        event.preventDefault();
        let formInValid = 0;
        let taskcount = 0;
        let usercount = 0;
        var startdate = formatDate(this.state.startdate);
        var enddate = formatDate(this.state.enddate);
        var selectedResponsible = this.state.selectedResponsible;
        var selectedAccountable = this.state.selectedAccountable;
        var selectedConsultant = this.state.selectedConsultant;
        var selectedInformer = this.state.selectedInformer;
        var selectedPhases = this.state.selectedPhases;
        var selectedSector = this.state.selectedSector;
        var selectedVertical = this.state.selectedVertical;
        var Responsible = '';
        var Accountable = '';
        var Consultant = '';
        var Informer = '';
        var Phases = [];
        var vertical = '';
        var sector ='';
        var projectcode = this.state.code.trim();
        var projectname = this.state.name;
        var description = this.state.description;
        var clientname = this.state.client;
        var projectid = (this.state.project_id =='0' ? moment().valueOf() : this.state.project_id);
        var mode = (this.state.project_id =='0' ? 'save' : 'update');

        if (projectcode === '' || projectcode.length !== 6 ) {
            formInValid++;
            document.getElementsByName('code')[0].classList.add('val-error');
        }
        else if (projectcode.length === 6) {
            var firstLetter = projectcode.charAt(0);
            var secondLetter = projectcode.charAt(1);
            if(!firstLetter.match(/[a-z]/i) || !secondLetter.match(/[a-z]/i)){
                formInValid++;
                document.getElementsByName('code')[0].classList.add('val-error');
            }

        }

        if (this.state.name.trim() === '') {
            formInValid++;
            document.getElementsByName('name')[0].classList.add('val-error');
        }

        if (this.state.description.trim() === '') {
            formInValid++;
            document.getElementsByName('description')[0].classList.add('val-error');
        }
        if(this.state.startdate === '' ||  this.state.startdate === undefined) {
            formInValid++;
            document.getElementById('startdate').classList.add('val-error');
        }
        if(this.state.enddate === '' ||  this.state.enddate === undefined)
        {
            formInValid++;
            document.getElementById('enddate').classList.add('val-error');
        }
        if (selectedPhases.length  === 0) {
            formInValid++;
            this.setState({phaseError:true})
        }else{
            this.setState({phaseError:false})
        }

        if (selectedSector.length  === 0) {
            formInValid++;
            this.setState({sectorError:true})
        }else{
            this.setState({sectorError:false})
        }

        if (selectedVertical.length  === 0) {
            formInValid++;
            this.setState({verticalError:true})
        }else{
            this.setState({verticalError:false})
        }

        if ((this.state.startdate !== '' && this.state.startdate !== undefined) || (this.state.enddate !== '' && this.state.enddate !== undefined)) {
            var start_date = formatDate(this.state.startdate);
            var end_date = formatDate(this.state.enddate);
            var today_date = formatDate(new Date);
           if(mode == 'save') {
                if (start_date < today_date) {
                    formInValid++;
                    this.setState({strtdate_err: true})

                   } else {
                    this.setState({strtdate_err: ''})
                    this.setState({startdate: new Date()})
               }

                if(end_date < today_date){
                    formInValid++;
                    this.setState({enddate_err:true})
                                }
                // else {
                //     this.setState({enddate_err:''})
                //     this.setState({enddate:new Date()})
                // }
           }
            if(end_date < start_date){
                formInValid++;
                this.setState({enddate_err:true})
                this.setState({enddate:new Date()})
            }

        }

        if(formInValid === 0){
            this.setState({newprjctloading: 1})
        if (selectedResponsible.length > 0) {
            for (var i = 0; i < selectedResponsible.length; i++) {
                Responsible = (Responsible !== '') ? (Responsible + ",") : "";
                Responsible += selectedResponsible[i].userId;
            }
        }

        if (selectedAccountable.length > 0) {
            for (var i = 0; i < selectedAccountable.length; i++) {
                Accountable = (Accountable !== '') ? (Accountable + ",") : "";
                Accountable += selectedAccountable[i].userId;
            }
        }

        if (selectedConsultant.length > 0) {
            for (var i = 0; i < selectedConsultant.length; i++) {
                Consultant = (Consultant !== '') ? (Consultant + ",") : "";
                Consultant += selectedConsultant[i].userId;
            }
        }

        if (selectedInformer.length > 0) {
            for (var i = 0; i < selectedInformer.length; i++) {
                Informer = (Informer !== '') ? (Informer + ",") : "";
                Informer += selectedInformer[i].userId;
            }
        }
        if (selectedPhases.length > 0) {
            for (var i = 0; i < selectedPhases.length; i++) {
                Phases.push(selectedPhases[i].phaseId);
            }
        }
        if (selectedSector.length > 0) {
            sector += selectedSector[0].portfolioId
        }
        if (selectedVertical.length > 0) {
            vertical += selectedVertical[0].programId
        }
        var concat = projectid + ' ' + getProfile().id + ' ' + sector + ' ' + vertical + ' ' + projectname + ' ' + clientname + ' ' + description + ' ' + startdate + ' ' + enddate + ' ' + Responsible + ' ' + Accountable + ' ' + Consultant + ' ' + Informer + '' + Phases;
        if( mode === 'update') {
            taskcount = this.props.listAllProjectData.ListAllProjects.data.filter(item =>item.project_id ).map(Project=>Project.taskcount)[0];
            usercount = this.props.listAllProjectData.ListAllProjects.data.filter(item =>item.project_id ).map(Project=>Project.usercount)[0];
        }
        let portfolio_name = this.props.listAllPortfolioData.portfolios.data.filter((a) => a.portfolioId === sector)[0].portfolioName;
        let program_name = this.props.listAllProgramData.programs.data.filter((a) => a.programId === vertical)[0].programName;

        let projectdata = {
            i: projectid,
            mode: mode,
            projectname: projectname,
            projectCode: projectcode,
            uid: getProfile().id,
            clientname: clientname,
            description: description,
            startdate: startdate,
            enddate: enddate,
            concat: concat,
            responsible: Responsible,
            accountable: Accountable,
            consultant: Consultant,
            informer: Informer,
            usercount : usercount,
            taskcount : taskcount,
            portfolio_id: sector,
            program_id: vertical,
            portfolio_name:portfolio_name,
            program_name:program_name,
            phase_id:Phases
        };
        this.props.saveproject(projectdata);

        }
    }
    getPhaseList(searchText) {
        var phaseParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: searchText?searchText:'',
            // page : this.state.lastOffset + 1,
            // per_page: 1000
        }
        this.props.phases(phaseParams);

    }
    getPortfolioList(searchText) {
        var portfolioParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: searchText?searchText:'',
           // page : this.state.lastOffset + 1,
            //per_page: this.state.offset
        }
        this.props.portfolio(portfolioParams);
    }
    getVerticalList(portfolioId) {
        //console.log(this.state.selectedSector)
        // let portfolioId = this.state.selectedSector[0].portfolioId;
        var programParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: '',
            //page : this.state.lastOffset + 1,
            //per_page: this.state.offset
            "portfolioId":portfolioId
        }
        this.props.program(programParams);

    }

    render() {
        return (
            <>
                <div className="task-content-block">

                    <div className="persitant-top with-pad">
                        {(this.state.newprjctloading === 1 || this.props.listAllPortfolioData.loading || this.props.listAllProgramData.loading) &&
                        <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                        }
                        <div className="task-inp-container empty-label">
                            <AutoComplete
                                eleClass='selectize-custom'
                                fieldTitle = ''
                                placeholder = 'Sector'
                                sourceDataList = {this.props.listAllPortfolioData.portfolios.data}
                                searchField = 'portfolioName'
                                showIcon={false}
                                showSubLabel = {true}
                                labelFields = 'portfolioName'
                                valueField ='portfolioId'
                                subLabelField = 'Sector'
                                showProfile = {false}
                                reference='selectedSector'
                                selectedItems = {this.state.selectedSector}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="selected_sector"
                                instantSearch={false}
                                actionMethod={this.getPortfolioList}
                                multiSelect={false}
                            />
                            <label className = "assign-user-validation-err" style={{display: this.state.sectorError === false ? 'none' : 'block' }}>Please select atleast one Sector.</label>

                        </div>
                        <div className="task-inp-container empty-label">
                            <AutoComplete
                                eleClass='selectize-custom'
                                fieldTitle = ''
                                placeholder = 'Vertical'
                                sourceDataList = {this.props.listAllProgramData.programs.data}
                                searchField = 'programName'
                                showIcon={false}
                                showSubLabel = {true}
                                labelFields = 'programName'
                                valueField ='programId'
                                subLabelField = 'Vertical'
                                showProfile = {false}
                                reference='selectedVertical'
                                selectedItems = {this.state.selectedVertical}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="selected_vertical"
                                instantSearch={false}
                                actionMethod={this.getVerticalList}
                                multiSelect={false}
                            />
                            <label className = "assign-user-validation-err" style={{display: this.state.verticalError === false ? 'none' : 'block' }}>Please select atleast one Vertical.</label>

                        </div>
                        <div className="task-inp-container empty-label">
                            <AutoComplete
                                eleClass='selectize-custom'
                                fieldTitle = ''
                                placeholder = 'Phases'
                                sourceDataList = {this.props.listAllPhaseData.phases.data}
                                searchField = 'phaseName'
                                showIcon={false}
                                showSubLabel = {true}
                                labelFields = 'phaseName'
                                valueField ='phaseId'
                                subLabelField = 'Phases'
                                showProfile = {false}
                                reference='selectedPhases'
                                selectedItems = {this.state.selectedPhases}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="selected_phases"
                                instantSearch={true}
                                actionMethod={this.getPhaseList}
                            />
                            <label className = "assign-user-validation-err" style={{display: this.state.phaseError === false ? 'none' : 'block' }}>Please select atleast one Phase.</label>

                        </div>
                        <div className="task-input-block">
                            <input type="text" id="code" name="code" className="task-input-box--new"  value = {this.state.code} placeholder="Project Code" onChange={this.ProjectInputChange} />
                        </div>
                        <div className="task-input-block">
                            <input type="text" id="name" name="name" className="task-input-box--new" value = {this.state.name} placeholder="Title" onChange={this.ProjectInputChange} />
                        </div>
                        <div className="task-input-block text-input">
                            <textarea className="task-textarea--new" id="description" name="description"
                                      placeholder="Details" value = {this.state.description} onChange={this.ProjectInputChange}></textarea>
                        </div>
                        <div className="task-input-block">
                            <input type="text" id="client" name="client" className="task-input-box--new" value = {this.state.client} placeholder="Client" onChange={this.ProjectInputChange}/>
                        </div>
                        <div className="task-input-block width-adjust task-input-box__top-border date-arrow" id="startdate">
                            <DatePickerBox  id="startdate" addErrorClass = {this.state.strtdate_err}  placeholderText = {'Start Date'}   handleDateChange={this.handleStartDateChange.bind(this)} startDate = {this.state.startdate} minDate={new Date()}/>
                        </div>
                        <div className="task-input-block width-adjust task-input-box__top-border date-arrow" id="enddate">
                            <DatePickerBox  id="enddate" addErrorClass = {this.state.enddate_err} placeholderText = {'End Date'}  handleDateChange={this.handleEndDateChange.bind(this)} startDate = {this.state.enddate} minDate={new Date()}/>
                        </div>
                        <div className="task-inp-container empty-label">
                            <AutoComplete
                                fieldTitle = ''
                                placeholder='Responsible'
                                sourceDataList = {this.props.listUsers.todoUsers}
                                searchField = 'firstName'
                                showIcon={true}
                                showSubLabel = {true}
                                labelFields = 'firstName,lastName'
                                valueField ='userId'
                                subLabelField = 'Responsible'
                                showProfile = {true}
                                reference='selectedResponsible'
                                selectedItems = {this.state.selectedResponsible}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="responsible_users"
                                instantSearch={true}
                                actionMethod={this.usersList}
                            />
                            {/*<label className = "assign-user-validation-err" style={{display: this.state.responsibleError === false ? 'none' : 'block' }}>Please select atleast one Responsible.</label>*/}

                        </div>
                        <div className="task-inp-container empty-label">
                            <AutoComplete
                                fieldTitle=''
                                placeholder = 'Accountable'
                                sourceDataList = {this.props.listUsers.todoUsers}
                                searchField = 'firstName'
                                showIcon={true}
                                showSubLabel = {true}
                                labelFields = 'firstName,lastName'
                                valueField ='userId'
                                subLabelField = 'Accountable'
                                showProfile = {true}
                                reference='selectedAccountable'
                                selectedItems = {this.state.selectedAccountable}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="accountable_users"
                                instantSearch={true}
                                actionMethod={this.usersList}
                            />
                            {/*<label className = "assign-user-validation-err" style={{display: this.state.responsibleError === false ? 'none' : 'block' }}>Please select atleast one Accountable.</label>*/}
                        </div>
                        <div className="task-inp-container empty-label">
                            <AutoComplete
                                fieldTitle=''
                                placeholder = 'Consultant'
                                sourceDataList = {this.props.listUsers.todoUsers}
                                searchField = 'firstName'
                                showIcon={true}
                                showSubLabel = {true}
                                labelFields = 'firstName,lastName'
                                valueField ='userId'
                                subLabelField = 'Consultant'
                                showProfile = {true}
                                reference='selectedConsultant'
                                selectedItems = {this.state.selectedConsultant}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="consultant_users"
                                instantSearch={true}
                                actionMethod={this.usersList}
                            />
                            {/*<label className = "assign-user-validation-err" style={{display: this.state.responsibleError === false ? 'none' : 'block' }}>Please select atleast one Consultant.</label>*/}
                        </div>
                        <div className="task-inp-container empty-label">
                            <AutoComplete
                                fieldTitle=''
                                placeholder = 'Informer'
                                sourceDataList = {this.props.listUsers.todoUsers}
                                searchField = 'firstName'
                                showIcon={true}
                                showSubLabel = {true}
                                labelFields = 'firstName,lastName'
                                valueField ='userId'
                                subLabelField = 'Informer'
                                showProfile = {true}
                                reference='selectedInformer'
                                selectedItems = {this.state.selectedInformer}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="informer_users"
                                instantSearch={true}
                                actionMethod={this.usersList}
                            />
                            {/*<label className = "assign-user-validation-err" style={{display: this.state.responsibleError === false ? 'none' : 'block' }}>Please select atleast one Informer.</label>*/}
                        </div>
                    </div>
                    <div className="persitant-footer">
                        <div className="group-row">

                        </div>
                        <button data-masterid="0" data-mode="save" type="button" className="general-btn" onClick={(e)=> this.CreateProject(e)}
                                id="createproject">{this.state.project_id =='0' ? 'Create' : 'Update'}
                        </button>
                    </div>
                </div>


            </>


        );
    }
}


const mapStateToProps = state => ({
    listAllProjectData:state.ProjectReducer.listallProjects,
    listUsers:state.UsersReducer.listUsersdata,
    ProjectInfoDet : state.ProjectReducer.ProjectInfo,
    SaveProjectDet : state.ProjectReducer.SaveProject,
    listAllPortfolioData:state.SectorReducer.listallPortfolio,
    listAllProgramData:state.VerticalReducer.listallPrograms,
    listAllPhaseData:state.PhaseReducer.listallPhases,
});
export default  withRouter(connect(mapStateToProps, {
    saveproject:projectActions.SaveProject,
    projectInfo:projectActions.projectInfo,
    users:usersActions.listTodoUsers,
    portfolio:portfolioActions.listallportfolio,
    program:verticalActions.listallprograms,
    phases:phaseActions.listallphases,
    getmilesonedetails:projectActions.getMilestoneInfo,
})(AddNewProject));



