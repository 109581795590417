import React, {Component,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import LoaderSvg from "../../views/LoaderSVG"
import {ES_INDEX} from "../../../constants";
import axios from "axios";

const API = 'https://rzp_test_pqTOMoiwZxODPK:lIZyjadXB05p99a7UftHlGrW@api.razorpay.com/v1/payments/';

class PaymentGateway extends Component {
    constructor(props) {
        super(props);
        this.state={
            hits: [],
            showFormModal: false,
            PaymentId: ''
        }
        this.openPayModal = this.openPayModal.bind(this);
    }
    componentDidMount() {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.PaymentId !== prevState.PaymentId){

        }
    }
    openPayModal(e, amount){
        let that=this;
        let options = {
            key: 'rzp_test_pqTOMoiwZxODPK',
            amount: amount, //  = INR 1
            name: 'Transfer Window',
            description: 'Transaction Details',
            image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
            handler: function(response) {
                //console.log(response.razorpay_payment_id);
                // this.setState({PaymentId: response.razorpay_payment_id})
                const paymentId = response.razorpay_payment_id;
                that.props.paymentDetails(paymentId)
                // this.paymentSend(paymentId)
            },
            prefill: {
                name: 'Anju',
                contact: '8281574656',
                email: 'demo@demo.com'
            },
            notes: {
                address: 'some address'
            },
            theme: {
                color: '#3fa9f5',
                hide_topbar: false
            }
        };
        //console.log(options);
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    };



    render() {
        let attr = this.props.attr;
        let payamount = attr.payamount;
        if(this.props.payChange){
            payamount = this.props.payAmount;
        }
        // console.log(payamount)
        return (

                <div>
                {attr.paymentbuttontype === 'text' &&
                <button onClick={(e) => this.openPayModal(e, payamount*100)} style={{
                    color: attr.paymenttextcolor}}>{attr.paymenttext !== '' ? attr.paymenttext : 'payment'}</button>
                }
                {attr.paymentbuttontype === 'button' &&
                <button onClick={(e) => this.openPayModal(e, payamount*100)} className="general-btn" style={{
                    color: attr.paymenttextcolor,
                    backgroundColor: attr.paymentbgcolor
                }}>{attr.paymenttext !== '' ? attr.paymenttext : 'payment'}</button>
                }
                </div>


        );
    }
};


const mapStateToProps = state => ({

});
export default  withRouter(connect(mapStateToProps, {

})(PaymentGateway));


