import React, {Component} from 'react';
import RatingTile from "./RatingTile";


function RatingBlock() {
        return (
            <div className="ibt-rating">

                <RatingTile title={`Project's Fundamentals`} stars={4} />
                <RatingTile title={`Host Country Risk`} stars={3}/>
                <RatingTile title={`Construction Risk`} stars={4} />
                <RatingTile title={`0 $M Risk`} stars={5} />
                <RatingTile title={`Market Risk`} stars={4} />
                <RatingTile title={`Financial Structure`} stars={5} />

            </div>

        );

}

export default RatingBlock;