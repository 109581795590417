import React, {Component,useEffect} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as CeShareViewActions from "../../../actions/CeShareView";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import moment from "moment";
import {getProfile} from "../../../utilities/AuthService";

class FilterdView extends Component {
    constructor(props) {
        super(props);
        this.state={
label:""
        }


    }
    componentDidMount(){
        let collectionid=''
        if (getUrlSegment(1) === 'project'){
            collectionid = (atob(getUrlSegment(4)));

        }
        else {
            collectionid = (atob(getUrlSegment(3)));
        }
        let param={"collection": collectionid}
        this.props.getFilterdList(param)

    }
    changeFilter=(ind,json)=>{
      let fromdate=""
      let todate=""
      let setFilter=(this.state.label === ind) ?"":ind
      let dFilter =  json.dateFilter.length !==0 ?json.dateFilter[0].period:[]
        if(dFilter=== "today"){
            fromdate=moment().format("YYYY-MM-DD")
        }
        if(dFilter=== "Last  7 days"){
            fromdate=moment().subtract(7,'d').format('YYYY-MM-DD');
        }
        if(dFilter=== "Last  30 days"){
            fromdate=moment().subtract(30,'d').format('YYYY-MM-DD');
        }
        if(dFilter=== "Last  6 months"){
            fromdate=moment().subtract(6,'months').format('YYYY-MM-DD');
        }
        if(dFilter=== "Last  3 months"){
            fromdate=moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        }
        if(json.todate!== ""){
            fromdate=json.fromdate
            todate=json.todate
        }






      this.setState({label:setFilter})
        let param = {}
        if(this.state.label === ind) {

            param = {
                from: 0,
                size: 50,
                search: '',
                "uid": getProfile().id,
                "usertype": getProfile().usertype,
                "dashboard": true,
                "facetrequired": true,
                "filters":[],
                "fromdate": "",
                "todate": "",
                "filterDate":"",
                "numberFilter":[],
                "daysfilters":[],
                "datefilters":[],
                "filter":[],
                "FilterLabel":[],
                "filterData":[],
                fieldview:true
            }
        }
        else{
            param = {
                "from": 0,
                "size": 50,
                "search":json.search,
                "usertype": getProfile().usertype,
                "uid":getProfile().id,
                 "fromdate": fromdate,
                 "todate": todate,
                "filters": json.filterData,
                "filterData":json.filterData,
                FilterLabel:json.FilterLabel,
                filter:json.filter,
                 "datefilters": json.dateFilter !== "" ? json.dateFilter :[],
                 "dayfilters": json.daysfilters,
                 "numberfilters": json.numberFilter,
                "dashboard":true,
                filterDate:dFilter,
                // sort:this.state.sortParam,
                 flags:json.flagId,
                 fieldview:true,
                "facetrequired": true,
            }

        }
       this.props.changeFilter(param,setFilter)


    }
    render(){
        if(this.props.listFilterdList.data.length !== 0) {
            return (
                <div className="filter-view-panel">
                    <h5>Filtered View</h5>
                    <div className="filter-view-tab">
                        {this.props.listFilterdList.data.map((list, ind) =>
                         <>
                            <button className={`general-btn ${this.state.label === ind ? "active" : ""}`}
                                    onClick={() => this.changeFilter(ind, JSON.parse(list.json))}
                                    key={`pane-${ind}`}>{JSON.parse(list.json).title}</button>
                         </>
                        )}

                    </div>
                </div>
            );
        }
        else{
            return null
        }




    }

}
const mapStateToProps = state => ({
    listFilterdList:state.CeShareView.FilterdList,
});
export default  withRouter(connect(mapStateToProps, {
    getFilterdList:CeShareViewActions.getFilterdList,
})(FilterdView));
