import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import ShowFormModal from "../views/ShowFormModal";
import * as collectionActions from "../../actions/Collections";
import LoaderSvg from "../views/LoaderSVG";

class ShowRecordModal extends Component {

    constructor(props) {
        super(props);

        this.state={
            formName: '',
            formDesc: '',
            collectionId : '',
            collecteddataid: '',
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            todotaskdata:[],
            offset: 20,
            successMessage : '',
            submissionCompleted : false,
            recordIds : [],
            recIndex : 0,
            formTemplate : '',
            formWidth : 960,

        }
    }

    componentDidMount() {
        var formTemplate = this.props.formInfo? this.props.formInfo.template: this.state.formTemplate;
        var formWidth = this.props.formInfo? this.props.formInfo.width: this.state.formWidth;
        this.setState({
            formName : this.props.formName,
            formDesc : this.props.formDesc,
            formTemplate : formTemplate === undefined ? '' : formTemplate,
            formWidth : formWidth,
            collectionId : this.props.collectionId,
            collecteddataid : this.props.collecteddataid,
            recordIds : this.props.recordIds
        })


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.formInfo.template !== prevProps.formInfo.template) {
            this.setState({
                formTemplate : this.props.formInfo.template
            });
        }
        if(this.props.formInfo.width !== prevProps.formInfo.width) {
            this.setState({
                formWidth : this.props.formInfo.width
            });
        }
        if(this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged) {
            let recIndex = this.state.recIndex;
            recIndex = recIndex+1;
            this.changeRecord(recIndex)
        }
    }

    changeRecord = ( recIndex) => {
        let id = this.state.recordIds[recIndex];
        if(id ) {
            this.props.getFormData({_id: this.state.recordIds[recIndex],metainfo: true, collectionid: this.state.collectionId});
            this.setState({recIndex : recIndex})
        }
        else{
            this.props.hideRecordModal();
        }
    }

    prevRecord = () => {
        let prevIndex = this.state.recIndex-1;
        if(prevIndex >=0) {
            this.changeRecord(prevIndex)
        }
    }
    nextRecord = () => {
        let nextIndex = this.state.recIndex+1;
        if(nextIndex < this.state.recordIds.length) {
            this.changeRecord(nextIndex)
        }
    }
    render() {
        let collection = this.props.collectionData;
        let recordIds = this.state.recordIds;
        let Formtemplate = this.state.formTemplate;
        let Formwidth = this.state.formWidth;
        console.log(this.props.collectionId)
        return (
            <React.Fragment>
                <div className={`multi-form-wrapper modal show full-width template-${Formtemplate === "" ? '1' : Formtemplate} width-${Formwidth} fade`} style={{display :'block'}}>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                    <div className="container">
                                        <div className="header-options">
                                            <h5 className="modal-title-header">Record {this.state.recIndex +1} / {recordIds.length}</h5>
                                            <div className="record-slider">
                                                <button className={`prevButton ${this.state.recIndex === 0 && 'disable'}`} onClick={() => this.prevRecord()}></button>
                                                <button className={`nextButton ${this.state.recIndex === (recordIds.length -1) && 'disable'}`} onClick={() => this.nextRecord()}></button>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.hideRecordModal()}></button>
                                    
                            </div>
                            <div className="modal-body inner-modal">
                                {this.state.loading === 1 &&
                                <span className="Loader-holder">
                                                    <LoaderSvg/>
                                                </span>
                                }

                                    {/* {recordIds &&
                                        <ul className="stage-flow" style={{position: 'relative',zIndex: '99999999'}}>
                                            {recordIds.length > 1 && recordIds.map((rec, recIndex) => {
                                                    let recNo = recIndex + 1;
                                                    return <li key={`pageli-${recIndex}`}
                                                            className={(recIndex === this.state.recIndex) ? "active" : ""}
                                                            onClick={(e) => this.changeRecord(recIndex)}>{recNo}</li>
                                                }
                                            )}
                                        </ul>
                                    } */}
                                <ShowFormModal
                                    formName={this.state.formName}
                                    formDesc={this.state.formDesc}
                                    collectionId={this.props.collectionId}
                                    referenceId={this.props.collectionId}
                                    editId={recordIds[this.state.recIndex]}
                                    Formtemplate={collection.CollectionInfo.formtemplate}
                                    recordBasedForm = {true}
                                />

                                {this.state.submissionCompleted &&
                                <CompletedBlock />
                                }

                            </div>
                        </div>
                </div>
                </div>
            </React.Fragment>

        );
    }
}



const mapStateToProps = state => ({
    formInfo : state.CollectionsReducer.showFormModal,
    collectionData: state.CollectionsReducer.listCollectionDetaildata,
    getSavedForm : state.CollectionsReducer.getSavedForm,
});
export default  withRouter(connect(mapStateToProps, {
    showform : collectionActions.showform,
    getFormData : collectionActions.getFormData,
})(ShowRecordModal));


function CompletedBlock(Props){
    return(
        <div className="task-details-wrapper niceScroll">
            <div className="task-details-inside">
                <div className="task-split task-split-full">

                    <div className="task-details-body-block">

                        <div className="task-details-body-container">
                            <h5>Form Data Saved Successfully</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {CompletedBlock};