import {combineReducers} from 'redux';
import TaskReducer from './TaskReducer';
import CommonReducer from './CommonReducer';
import UsersReducer from './UsersReducer';
import ProjectReducer from './ProjectReducer';
import FormsReducer from './FormsReducer';
import CollectionsReducer from './CollectionsReducer';
import EntityReducer from './EntityReducer';
import workflowReducer from './WorkFlowReducer';
import resourcesReducer from './ResourcesReducer';
import ChatReducer from './ChatReducer';
import UploadReducer from './UploadReducer';
import DashboardReducer from './DashboardReducer';
import PdfBuilderReducer from './PdfBuilderReducer';
import SectorReducer from './SectorReducer';
import VerticalReducer from './VerticalReducer';
import PhaseReducer from './PhaseReducer';
import RoleReducer from './RoleReducer';
import DynamicDashboard from './DynamicDashboard';
import WhatsappReducer from "./WhatsappReducer";
import CeShareView from "./CeShareView";
import SchedulerReducer from "./SchedulerReducer";
const app = combineReducers({
    TaskReducer,
    CommonReducer,
    UsersReducer,
    ProjectReducer,
    FormsReducer,
    CollectionsReducer,
    EntityReducer,
    workflowReducer,
    resourcesReducer,
    ChatReducer,
    UploadReducer,
    DashboardReducer,
    PdfBuilderReducer,
    SectorReducer,
    VerticalReducer,
    PhaseReducer,
    RoleReducer,
    DynamicDashboard,
    CeShareView,
    WhatsappReducer,
    SchedulerReducer,

});
export default app;
