import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {getProfile} from "../utilities/AuthService";
import {connect} from "react-redux";
import * as taskActions from "../actions/ToDo";
import NotificationPanel from "../components/views/NotificationPanel";
import   {Language} from "../components/language/notification";




class Events extends Component {
    constructor(e) {
        super(e);
      this.state={
          toaster:false,
          browsernotification:false,
          action:'',
          data:'',
          status:false,
          msg:''
      }
     this.handleClose=this.handleClose.bind(this)
    }


    componentDidMount() {
        this.props.events.onmessage = e => {
            console.log(e); // do something with the payload
            var messageData=JSON.parse(e.data)
            var tasktype=messageData[0].status
            var event=messageData[0].event
            messageData=messageData[0]
            if(event==='create') {
               var msg=messageData.createuser_name+" "+Language.TASK_CREATION_MESSAGE+" - "+atob(messageData.Title)
                this.setState({action:true,data:messageData,browsernotification:true,status:!this.state.status,msg:msg})
                this.props.updateCreateEventStram(messageData)
            }
            if(event==='update') {
                var msg=messageData.createuser_name+" "+Language.TASK_UPDATE_MESSAGE+" - "+atob(messageData.Title)
                this.setState({action:true,data:messageData,browsernotification:true,status:!this.state.status,msg:msg})
                this.props.updateTaskDetailEventStram(messageData)
            }
            if(tasktype==='InProgress' && event === 'markasdone') {
                var result = JSON.parse(e.data);
                var msg=messageData.assigneeuser_name+" "+Language.TASK_MARK_AS_DONE_MESSAGE+" - "+atob(messageData.Title)
                this.setState({action:true,data:result,browsernotification:true,status:!this.state.status,msg:msg})
            }

            if(event === 'markasdone' && tasktype !=='InProgress') {
                var result = JSON.parse(e.data);
                var msg=messageData.assigneeuser_name+" "+Language.TASK_MARK_AS_DONE_MESSAGE+" - "+atob(messageData.Title)
                this.setState({action:true,data:result,browsernotification:true,status:!this.state.status,msg:msg})
                this.props.updateMarkAsDoneEventStram(result)

            }
            if(event === 'reopen') {
                var result = JSON.parse(e.data);
                var msg=messageData.createuser_name+" "+Language.TASK_REOPEN_MESSAGE+" - "+atob(messageData.Title)
                this.setState({action:true,data:result,browsernotification:true,status:!this.state.status,msg:msg})
                this.props.updateReOpenEvntStream(result)

            }

            if(event === 'verify') {
                var result = JSON.parse(e.data);
                var msg=messageData.createuser_name+" "+Language.TASK_VERIFY_MESSAGE+" - "+atob(messageData.Title)
                this.setState({action:true,data:result,browsernotification:true,status:!this.state.status,msg:msg})
                this.props.updateVerifyEventstram(messageData)

            }
            if(event === 'reassign') {
                var msg=messageData.createuser_name+" "+Language.TASK_CREATION_MESSAGE+" - "+atob(messageData.Title)
                this.setState({action:true,data:messageData,browsernotification:true,status:!this.state.status,msg:msg})
                this.props.updateCreateEventStram(messageData)
            }




        };
    }
    handleClose(){
        this.setState({status:!this.state.status})
    }

    render() {

           return( <NotificationPanel
            action={this.state.action}
            data={this.state.data}
            browsernotification={this.state.browsernotification}
            handleClose={this.handleClose}
            show={this.state.status}
            msg={this.state.msg}

           />)



    }


}


const mapStateToProps = state => ({



});
export default  withRouter(connect(mapStateToProps, {
    updateCreateEventStram:taskActions.updateCreateEventStram,
    updateMarkAsDoneEventStram:taskActions.updateMarkAsDoneEventStram,
    updateReOpenEvntStream:taskActions.updateReOpenEvntStream,
    updateVerifyEventstram:taskActions.updateVerifyEventstram,
    updateTaskDetailEventStram:taskActions.updateTaskDetailEventStram,
})(Events));

