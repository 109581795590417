import React from 'react';
import { Draggable, state } from 'react-page-maker';
import {withRouter} from 'react-router';
import {FRMSIMG_PATH,FRMSVID_PATH} from '../../../../../../constants'
import {connect} from "react-redux";
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext
} from 'pure-react-carousel';
import {FormGroup} from "react-bootstrap";
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";
import 'pure-react-carousel/dist/react-carousel.es.css';
import {checkUrl} from "../../../../../../utilities/CustomFunctions";

class VideoContainer extends React.Component {
  state = {
    showColorPicker: false,
    label: '',
  };

  handleChangeComplete = (e) => {
    let labelValue=e.target.value
    const { id, dropzoneID, parentID }= this.props;
    this.setState({ label: labelValue }, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: { label: labelValue}
      });
    });
   // this.chartSaving()
  };
  // onBlur(){
  //   this.props.dragStatus(false)
  // }
  // onClick(){
  //   this.props.dragStatus(true)
  // }


    updateCount = (factor) => {
        this.setState({
            currentSlide: this.state.currentSlide + (1 * factor)
        });
    }

  render() {

    let showlistField=[]
   let getFormdetails=this.props.getFormdetails
      if(this.props.showFormModalData.showformdata.length !==0) {

          for (let i = 0; i < this.props.showFormModalData.showformdata.pages.length; i++) {
              (this.props.showFormModalData.showformdata.pages[i].components.filter(list=> list.visibility !=="hidden")).map(list=>{
                  showlistField.push(list)

              })
          }
      }

      const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, name, payload
    } = this.props;

    const label =  this.state.label ||
      payload && payload.label || '';

    if (showBasicContent) {
      return (
        <Draggable { ...this.props } >
          {

            <span className="drag-icon">Video Card</span>
          }
        </Draggable>
      )
    }

    if (showPreview) {
        console.log(getFormdetails.formvalues.filter(list => list.masterid === label)[0])
        console.log(label)
      return (
          <>

            <div className="floating-summary-body">
              <div className="floating-boiler-plate">

                  {label && getFormdetails.length  !==0 && getFormdetails.formvalues.length !==0&&
                  <div className="floating-boiler-image">
                   <>
                      {getFormdetails.formvalues.length !== 0 && getFormdetails.formvalues.filter(list => list.masterid === label)[0].values.length === 1 &&
                       <>
                           {getFormdetails.formvalues.filter(list => list.masterid === label)[0].type === "videourl" &&
                               (checkUrl(getFormdetails.formvalues.filter(list => list.masterid === label)[0].values[0].value) === "Vimeo"|| checkUrl(getFormdetails.formvalues.filter(list => list.masterid === label)[0].values[0].value) ==="Youtube") ?
                                   <iframe src={getFormdetails.formvalues.filter(list => list.masterid === label)[0].values[0].value } style={{'width': '100%'}} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen> </iframe>
                                       :
                              <video style={{'width': '100%'}} controls>
                                   <source
                                       src={getFormdetails.formvalues.filter(list => list.masterid === label)[0].type === "attachment" ? FRMSVID_PATH + getFormdetails.formvalues.filter(list => list.masterid === label)[0].values[0].uniqvalue : getFormdetails.formvalues.filter(list => list.masterid === label)[0].values[0].value}/>
                               </video>
                           }

                        </>

                      }

                      {getFormdetails.formvalues.length !== 0 && getFormdetails.formvalues.filter(list => list.masterid === label)[0].values.length > 1 &&
                       <>

                           {(checkUrl(getFormdetails.formvalues.filter(list => list.masterid === label)[0].values[0].value) === "Vimeo" || checkUrl(getFormdetails.formvalues.filter(list => list.masterid === label)[0].values[0].value) === "Youtube") ?
                               <CarouselProvider
                                   naturalSlideWidth={300}
                                   naturalSlideHeight={200}
                                   isPlaying={true}
                                   totalSlides={getFormdetails.formvalues.filter(list => list.masterid === label)[0].values.length}
                               >
                                   <Slider>
                                       {
                                           getFormdetails.formvalues.filter(list => list.masterid === label)[0].values
                                               .map((src, i) => (
                                                   <Slide index={`${i}-1`}>
                                                       <iframe
                                                           src={src.value}
                                                           style={{'width': '100%'}} frameBorder="0"
                                                           allow="autoplay; fullscreen" allowFullScreen> </iframe>

                                                   </Slide>
                                               ))
                                       }
                                   </Slider>
                                   <ButtonBack><FaArrowLeft/></ButtonBack>
                                   <ButtonNext><FaArrowRight/></ButtonNext>
                               </CarouselProvider>

                               :
                               <CarouselProvider
                                   naturalSlideWidth={300}
                                   naturalSlideHeight={200}
                                   isPlaying={true}
                                   totalSlides={getFormdetails.formvalues.filter(list => list.masterid === label)[0].values.length}
                               >
                                   <Slider>
                                       {
                                           getFormdetails.formvalues.filter(list => list.masterid === label)[0].values
                                               .map((src, i) => (
                                                   <Slide index={`${i}-1`}>
                                                       <video style={{'width': '100%'}} controls>
                                                           <source
                                                               src={getFormdetails.formvalues.filter(list => list.masterid === label)[0].type === "attachment" ? FRMSVID_PATH + src.uniqvalue : src.value}
                                                               type="video/mp4"/>
                                                       </video>
                                                   </Slide>
                                               ))
                                       }
                                   </Slider>
                                   <ButtonBack><FaArrowLeft/></ButtonBack>
                                   <ButtonNext><FaArrowRight/></ButtonNext>
                               </CarouselProvider>
                           }
                          </>
                      }
                   </>
                  </div>
                  }

                  {label === "" &&
                  <div className="floating-boiler-image">
                      <img src={"https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=jpg&quality=90&v=1530129081"} />
                  </div>
                  }
                  </div>
            </div>

          </>
      );
    }

    return (
      <Draggable { ...this.props } draggable={!this.props.DragDisabled.dragStatus}>
        <React.Fragment>
          <FormGroup className="m-0">
            <label className="col-sm-12">
              <div className={`left-container`} >
              <span>{name}</span>
                <select onChange={(e)=>this.handleChangeComplete(e)} value={label}>
                    {showlistField.map((label, index) =>
                        <option key={`masterid` + index}
                                value={label.masterid}>{label.label}</option>
                    )}
                </select>
            {/*<input onChange={(e)=>this.handleChangeComplete(e)}  defaultValue={label} className="layout-header-block"/>*/}
              </div>
              <div className="chart-icons">
                {/*<div className="save-in-icon" onClick={() => this.chartSaving()}> </div>*/}
                <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}> </div>
              </div>
            </label>
          </FormGroup>
        </React.Fragment>
      </Draggable>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled,
  getFormdetails : state.CollectionsReducer.getFormData,
    showFormModalData:state.EntityReducer.showFormModal,

});
export default  withRouter(connect(mapStateToProps, {
})(VideoContainer));
