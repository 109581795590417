import React, {Component} from 'react';
import NoData from "../../views/NoData";
import {ES_INDEX, FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH} from "../../../constants";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {Datetimecomparison, getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import {getFileIcon,getFileExtension, forceDownload} from "../../../utilities/CustomFunctions";
import LoaderSvg from "../../views/LoaderSVG";
import ProjectHeader from "../../views/ProjectHeader";
import ImagePreviewModalview from "../../views/ImagePreviewModal";
import Modal from "../../views/Modal";
import {DataRoomRight,DataRoomLeft} from "../../views/DataRoomDetails";
import Overview from "../../views/syncfusion/FileManager/Overview";



class ProjectFiles extends Component {

    constructor(props) {
        super(props);

        this.state={
            ImgName : '',
            ImgPath : '',
            ImgPrvwModal: false,
            expandHeader:false,
        }
        this.ImagePrvwOpen= this.ImagePrvwOpen.bind(this);
        this.PreviewClose = this.PreviewClose.bind(this);
        this.expandHeader = this.expandHeader.bind(this)

    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }

    componentDidMount() {
        this.setState({loading: 1});
        var prjctdtlid = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/files/'+prjctdtlid);

    }

    ImagePrvwOpen (name, ImgPath){

        this.setState({ImgName : name , ImgPrvwModal: true , ImagePath : ImgPath})
    }
    PreviewClose (){
        this.setState({ ImgPrvwModal: false })
    }

    render() {
       //console.log(this.state.ImgName);
        //console.log(this.state.ImgPath);
        let userdetail = this.props.listAllProjectUserData;
        let projectfiles = this.props.ProjectFilesData.ProjectInfo.files.file;
        let projectotherfile = this.props.ProjectFilesData.ProjectInfo.files.otherfile;
        //console.log(projectfiles);
        //console.log(projectotherfile);


        return(
                <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                    <ProjectHeader expandHeader={this.expandHeader}/>
                    {/*<div className="ibt-body file-data-wrap">*/}
                    {/*    {this.props.ProjectFilesData.loading &&*/}
                    {/*    <span className="Loader-holder">*/}
                    {/*         <LoaderSvg/>*/}
                    {/*     </span>*/}
                    {/*    }*/}
                    {/*    <div className="data-room-controller">*/}
                    {/*       <DataRoomLeft />*/}
                    {/*        <DataRoomRight/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Overview/>
                </div>


        );
    }
}
const mapStateToProps = state => ({
    ProjectFilesData:state.ProjectReducer.ProjectInfo,
    //listAllProjectDetailData:state.ProjectReducer.listallProjectsdetails,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    projectdetail:projectActions.listallprojectsdetails,


})(ProjectFiles));
