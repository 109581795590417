import React, {Component} from 'react';


class ShareDetails extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let shareholders = this.props.shareholders ? this.props.shareholders : [];
        return (
            <React.Fragment>
            {shareholders.map((item,index)=>
                <li key={index}>
                    <h5>{item.name}</h5>
                    <p>{item.sharesRenewable === ''? "0% of Shares":item.sharesRenewable}</p>
                </li>
                )}
            </React.Fragment>
        );
    }


}

export {ShareDetails};