import React from 'react';
import axios from 'axios';
import {FormGroup} from 'react-bootstrap';
import { Draggable, state } from 'react-page-maker';
import LineChartCard from "../views/charts/LineChartCard";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {withRouter} from 'react-router';
import {API_PATH,ES_INDEX} from "../../../../constants"
import {getProfile} from '../../../../utilities/AuthService';
import {getUrlSegment, isValidDate} from "../../../../utilities/CustomFunctions";
import LoaderSvg from "../../../views/LoaderSVG";
import SettingsModal from "./SettingsModal";
import PaymentSettingsModal from "./PaymentSettingsModal";
import moment from "moment";
import * as whatsappActions from "../../../../actions/Whatsapp";
import * as CeShareViewActions from "../../../../actions/CeShareView";
import SimpleModal from "../../../views/SimpleModal";
import DatePickerBox from "../../../views/DatePickerBox";
import AlertModal from "../../../views/AlertModal";

let graphData= [
    {
        "x": "2018 Q1",
        "y": "500",
        "z": "2018 Q1"
    },
    {
        "x": "2018 Q2",
        "y": "620",
        "z": "2018 Q2"
    }]
class PaymentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            modalStatus:false,
            showColorPicker:false,
            background:'#fff',
            whatsapp : false,
            email : false,
            paymentMode:"Razorpay",
            newPaymentMethod:"normal",
            fieldValues:[],
            showPaymentModal:false,
            payload:{
                chartTitle:"Title",
                subTitle:"subtitle",
                formLabel:"Label",
                formUrl:"URL",
                paymentButtonText:"Generate Payment Url",
                subTitleEnabled : false,
                whatsapp : false,
                email : false,
                linedata:graphData,
                cardvalue:0,
                collection:"",
                PRcollection:"",
                fields:"",
                paymentTitle:"",
                paymentMode:"Razorpay",
                paymentDesc:"",
                paymentImage:"",
                paymentItems:"",
                paymentAmt:"",
                aggregation:'',
                graph:false,
                x:"",
                y:"",
                z:"",
                labelx:"",
                labely:"",
                labelfield:'',
                linecolor:"",
                labeltitle:"",
                min:"",
                max:"",
                whereCondition:[
                    {
                        id: "item-1",
                        label: "",
                        field:"",
                        conditions:"",
                        min:'',
                        max:'',
                        from:'',
                        to:'',
                        text:"",
                        type:""
                    },
                ],
                PRUniqueId : '',
                PRID :'',
                PRAmount : '',
                PRMethod : '',
                PRStatus : '',
                PRDate : '',
                PRprogram : ''
            },
            linedata : [],
            whereCondition:[
                {
                    id: "item-1",
                    label: "",
                    field:"",
                    conditions:"",
                    min:'',
                    max:'',
                    from:'',
                    to:'',
                    text:"",
                    type:""
                },
            ],
            globalFilter:[
                {
                    id: "item-1",
                    label: "",
                    field:"",
                    conditions:"",
                    min:'',
                    max:'',
                    from:'',
                    to:'',
                    text:"",
                    type:""
                },
            ],
            condition :[ {
                field : "",
                type : "",
                sort :""
            } ],
            channel :[ {
                field : "",
                type : ""
            } ],
            loading:true,
            showlistField:[],
            flagId:[],
            fieldId:'',
            selectedRecordId:'',
            payment_error: '',
            PaymentUrl: '',
            razorpayUrl: '',
            savePaymentMsg:'',
            paymentErrorModal:false,
            PRUniqueId :'',
            PRID :'',
            PRAmount : '',
            PRMethod : '',
            PRStatus : '',
            PRDate : '',
            PRprogram : ''
        }


    }
    componentDidMount() {
        this.setState({payload:this.props.payload, PaymentUrl: ''})
        this.graphData()
        // console.log(this.props.payload)
        let collection = getUrlSegment(3) ? atob(getUrlSegment(3)) :  this.props.linkedCollectionId.param;
        if(this.props.linkedId.param) {
            let params = {
                "recordid": this.props.linkedId.param,
                "_id": "",
                "collectionid": collection
            }
            this.props.getPaymentData(params);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterCnt.date !== this.props.filterCnt.date) {
            if (this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") {
                this.graphData()
            }
        }
        if (prevProps.linkedId.param !== this.props.linkedId.param) {
            let collection = getUrlSegment(3) ? atob(getUrlSegment(3)) :  this.props.linkedCollectionId.param;
            if(this.props.linkedId.param && this.props.linkedId.param !=='') {
                let params = {
                    "recordid": this.props.linkedId.param,
                    "_id": "",
                    "collectionid": collection
                }
                this.props.getPaymentData(params);
            }
        }
        if (prevProps.filterCnt.select !== this.props.filterCnt.select) {
            this.graphData()
        }
        if (prevProps.getFieldList.fieldList !== this.props.getFieldList.fieldList) {
            let fieldValues = [];
            let data = this.props.getFieldList.fieldList;
            if (data.length !== 0) {
                data.map((item, index) => {
                    if (item.collectedform.length > 0) {
                        item.collectedform.map((list, indx) => {
                            if (list.masterid === this.state.fieldId) {
                                if (list.value.length !== 0) {
                                    list.value.map((data, ind) => {
                                        fieldValues.push({"id" : item.id, "value" : data.value});
                                    })
                                }
                            }
                        })
                    }
                })
            }

            this.setState({fieldValues: fieldValues})
        }

        if (prevProps.savedPaymentUrl.payurl !== this.props.savedPaymentUrl.payurl) {
            if (this.props.savedPaymentUrl.payurl.success === true) {
                let mainUrl = window.location.origin;
                let selectedRecordId = this.state.selectedRecordId;
                let parentID = this.props.savedPaymentUrl.payurl.data;
                let PaymentUrl = mainUrl + `/payment/` + btoa(parentID) + `/` + btoa(selectedRecordId);
                this.setState({PaymentUrl : PaymentUrl, paymentErrorModal:false})
            }
            else{
                let savePaymentMsg = this.props.savedPaymentUrl.payurl.message;
                this.setState({savePaymentMsg : savePaymentMsg, paymentErrorModal:true})
            }
        }
        if (prevProps.savedPaymentUrlRazorpay.payurlRazorpay !== this.props.savedPaymentUrlRazorpay.payurlRazorpay) {
            if (this.props.savedPaymentUrlRazorpay.payurlRazorpay.success === true) {
                let mainUrl = window.location.origin;
                let selectedRecordId = this.state.selectedRecordId;
                let parentID = this.props.savedPaymentUrlRazorpay.payurlRazorpay.data;
                let razorpayUrl = this.props.savedPaymentUrlRazorpay.payurlRazorpay.short_url;
                this.setState({razorpayUrl : razorpayUrl, paymentErrorModal:false})
            }
            else{
                let savePaymentMsg = this.props.savedPaymentUrlRazorpay.payurlRazorpay.message;
                this.setState({savePaymentMsg : savePaymentMsg, paymentErrorModal:true})
            }
        }
        if (prevProps.paymentData.data !== this.props.paymentData.data) {
            if (this.props.paymentData.success === true && this.props.paymentData.data.recordid) {
                let mainUrl = window.location.origin;
                let selectedRecordId = this.props.paymentData.data.recordid;
                let parentID = this.props.paymentData.data._id;
                let razorpayUrl = this.props.paymentData.data.short_url ? this.props.paymentData.data.short_url : '';
                // console.log(razorpayUrl)
                if(selectedRecordId !== ''){
                    let PaymentUrl = mainUrl + `/payment/` + btoa(parentID) + `/` + btoa(selectedRecordId);
                    this.setState({PaymentUrl : PaymentUrl, razorpayUrl : razorpayUrl})
                }
            }
            else{
                this.setState({PaymentUrl : ''})
            }
        }


    }
    getFieldValues = (sourceType, collection, searchtext) => {
        let entityId = collection;
        let search = searchtext ? searchtext : '';
        let refer = sourceType.toString() === "true" ? "collection" : "entity";
        let params = {"from":0,"size":100,"search":search,"uid":"2","usertype":"Superadmin","refer":refer,"dashboard":false,"facetrequired":false}
        this.props.getFieldValues( entityId, params);
    }
    paymentModal=(sourceType, collection, fieldId, newPaymentMethod)=>{
        this.setState({fieldId : fieldId})
        if(this.props.linkedId.param){
            this.getFieldValues(sourceType, collection)
            let selectedPaymentlist = [];
            this.setState({showPaymentModal : !this.state.showPaymentModal, selectedRecordId:'', payment_error: '', newPaymentMethod: newPaymentMethod})
        }

    }
    paymentModalForRazorpay=(sourceType, collection, fieldId, newPaymentMethod)=>{
        this.setState({fieldId : fieldId})
        if(this.props.linkedId.param){
            this.getFieldValues(sourceType, collection)
            this.setState({showPaymentModal : !this.state.showPaymentModal, selectedRecordId:'', payment_error: '', newPaymentMethod: newPaymentMethod})
        }

    }
    getFieldValuesSearch=(e, sourceType, collection)=>{
            this.getFieldValues(sourceType, collection, e.target.value)
    }
    handlePaymentChange = (e) => {
        this.setState({selectedRecordId : e.target.value})
    }
    submitPayment = (parentID, collection) => {
        let selectedRecordId = this.state.selectedRecordId;
        if(selectedRecordId !== ''){
            let params = {
                "recid":this.props.linkedId.param,
                "leadname":this.props.LinkedCollectionName.leadName,
                "linked_recid":selectedRecordId,
                "uid":getProfile().id,
                "collectionid": getUrlSegment(3) ? atob(getUrlSegment(3)) :  this.props.linkedCollectionId.param,
                "setfrom": "mobile_builder",
                "gst": "",
                "discount": ""
            }
            if(this.state.newPaymentMethod === 'razorpayPayment'){
                this.props.saveRazorpayPaymentUrl(params);
            }
            else{
                this.props.savePaymentUrl(params);
            }

            this.setState({submitPaymentModal : true, showPaymentModal : false})
        }
        else{
            let payment_error = selectedRecordId === '' ? 'Please select an option' : '';
            this.setState({payment_error : payment_error})
        }

    }
    closePaymentModal = () => {
        this.setState({showPaymentModal : false})
    }
    openSetting=()=>{
        this.setState({modalStatus:!this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }
    handleChangeComplete = (color,param) => {
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[param]=color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    };

    toggleColorPicker = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    }


    handleInputChange=(e)=>{
        let sel=e.target
        if(e.target.name==="collection"){
            this.props.showform({ _id : e.target.value});
            this.props.getFlag({collection:e.target.value,uid:2})
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        payload["label"+e.target.name]=sel.options[sel.selectedIndex].text
        payload['paymentMode']='Razorpay';
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    handlePRInputChange=(e)=>{
        let sel=e.target
        if(e.target.name==="PRcollection"){
            let PRcollectionid = e.target.value;
            let obj = this;
            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + PRcollectionid + "&index=" + ES_INDEX,
                async: false,
                headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
            }).then(function (res) {
                //console.log(res.data.data)
                obj.setState({responseMappingForm: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        payload["label"+e.target.name]=sel.options[sel.selectedIndex].text

    }

    handleChange=(e)=>{
        if(e.target.name==="collection"){
            this.props.showform({ _id : e.target.value});
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    setDate=(e)=>{

        let sel=e.target
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        payload["label"+e.target.name]=sel.options[sel.selectedIndex].text
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }



    handleConditionChange=(e)=>{
        var _newState = this.state.condition;
        if(e.target.name ==='field') {
            let t = e.target.value.split("|");
            _newState[0]["field"] = t[0];
            _newState[0]["type"] =  t[1] === "datepicker" ?"date":t[1];
        }
        else{
            _newState[0][e.target.name] = e.target.value;
        }


        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["condition"]=this.state.condition
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    handleChannelChange=(e)=>{
        var _newState = this.state.channel;
        _newState[0][e.target.name] = e.target.value;
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["channel"]=this.state.channel
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }

    graphData(){
        var date = new Date();
        var timestamp = date.getTime();
        var obj = this;
        let payload=this.state.payload
        const collection =  this.props.payload && this.props.payload.collection
        const PRcollection =  this.props.payload && this.props.payload.PRcollection
        const fields = this.props.payload && this.props.payload.fields
        const paymentTitle = this.props.payload && this.props.payload.paymentTitle
        const paymentDesc = this.props.payload && this.props.payload.paymentDesc
        const paymentImage = this.props.payload && this.props.payload.paymentImage
        const paymentItems = this.props.payload && this.props.payload.paymentItems
        const paymentAmt = this.props.payload && this.props.payload.paymentAmt
        const condition = this.props.payload && this.props.payload.condition||this.state.condition
        const whereCondition = this.props.payload && this.props.payload.whereCondition||this.state.whereCondition
        const channel = this.props.payload && this.props.payload.channel||this.state.channel
        const x = this.props.payload && this.props.payload.x||''
        const y = this.props.payload && this.props.payload.y||''
        const aggregation = this.props.payload && this.props.payload.aggregation||''
        const graph = this.props.payload && this.props.payload.graph||false;
        const limit = this.props.payload && this.props.payload.limit||this.state.limit
        const globalFilter = this.props.payload && this.props.payload.globalFilter||this.state.globalFilter
        const selectFilter =     this.state.selectFilter || this.props.payload && this.props.payload.selectFilter
        const filterName =     this.state.filterName || this.props.payload && this.props.payload.filterName
        const setDate = this.props.payload && this.props.payload.setDate||this.state.setDate
        const setFrom = this.props.payload && this.props.payload.setFrom||this.state.setFrom
        const setTo = this.props.payload && this.props.payload.setTo||this.state.setTo
        const dateFrom = this.props.payload && this.props.payload.dateFrom ||this.state.dateFrom
        const flagId = this.props.payload && this.props.payload.flagId ||this.state.flagId


        this.setState({filterName:filterName,flagId:flagId,selectFilter:selectFilter,globalFilter:globalFilter})

        if((collection !=='' && collection !== undefined) || getUrlSegment(1)==="collections") {
            let collections =(getUrlSegment(1)==="collections")? atob(getUrlSegment(4) ) :collection

            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + collections + "&index=" + ES_INDEX,
                async: false,
                headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
            }).then(function (res) {
                //////console.log(res.data.data)
                obj.setState({showlistField: res.data.data,whereCondition:whereCondition});
                return res;

            }).catch(function (err) {
                return err;
            });

            axios({
                method: "GET",
                url: API_PATH + "collection/flagsettingslist?collection=" + collections + "&index=" + ES_INDEX + "&uid=" + 2,
                async: false,
                headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
            }).then(function (res) {
                //console.log(res.data.data)
                obj.setState({getFlagData: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });

        }
        if((PRcollection !=='' && PRcollection !== undefined)) {

            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + PRcollection + "&index=" + ES_INDEX,
                async: false,
                headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
            }).then(function (res) {
                //////console.log(res.data.data)
                obj.setState({responseMappingForm: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });


        }

        if((collection !== "" &&  collection !== undefined) || getUrlSegment(1)==="collections" ) {
            let collections = getUrlSegment(3) ? atob(getUrlSegment(3)) :  this.props.linkedCollectionId.param

            let filters = [];
            let numberfilters = [];
            let datefilters = [];
            if(whereCondition.length > 0) {
                for( var i=0; i< whereCondition.length;i++){
                    let conditionEntry = whereCondition[i];
                    if(conditionEntry && conditionEntry.field&& conditionEntry.field !== '') {
                        let conditionValue = '';
                        if(conditionEntry.type === 'text') {
                            conditionValue = conditionEntry.text;
                        }
                        else if(conditionEntry.type === 'conditions') {
                            conditionValue = conditionEntry.conditions;
                        }
                        let conditionArr = conditionValue !== '' ? conditionValue.split(',') :[];


                        if(conditionEntry.type === 'datepicker') {
                            let datefilter = {
                                "id" : conditionEntry.field,
                                "from" : conditionEntry.from,
                                "to" : conditionEntry.to
                            }
                            datefilters.push(datefilter)
                        }
                        else if(conditionEntry.type === 'number') {
                            let numberfilter = {
                                "id" : conditionEntry.field,
                                "min" : conditionEntry.min,
                                "max" : conditionEntry.max
                            }
                            numberfilters.push(numberfilter)
                        }
                        else {
                            //console.log(conditionArr)
                            if(conditionArr.length > 0) {
                                let filter = {
                                    "id" : conditionEntry.field,
                                    "value" : conditionArr
                                }
                                filters.push(filter);
                            }
                        }

                    }

                }
            }

            if(this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") {
                if (globalFilter.length > 0) {
                    for (var i = 0; i < globalFilter.length; i++) {
                        let conditionEntry = globalFilter[i];
                        if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                            let conditionValue = '';
                            if (conditionEntry.type === 'text') {
                                conditionValue = conditionEntry.text;
                            } else if (conditionEntry.type === 'conditions') {
                                conditionValue = conditionEntry.conditions;
                            }
                            let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];

                            if (conditionEntry.type === 'datepicker' && this.props.filterCnt.date.from !== "") {
                                let datefilter = {
                                    "id": conditionEntry.field,
                                    "from": this.props.filterCnt.date.from,
                                    "to": this.props.filterCnt.date.to
                                }
                                datefilters.push(datefilter)
                            } else if (conditionEntry.type === 'number') {
                                let numberfilter = {
                                    "id": conditionEntry.field,
                                    "min": conditionEntry.min,
                                    "max": conditionEntry.max
                                }
                                numberfilters.push(numberfilter)
                            } else {
                                if (this.props.filterCnt.select !=="") {
                                    let filter = {
                                        "id": conditionEntry.field,
                                        "value": [this.props.filterCnt.select]
                                    }
                                    filters.push(filter);
                                }

                            }

                        }
                    }
                }
            }



            let sortCondition = [];
            if(condition.length > 0) {
                for(var j=0; j<condition.length; j++) {
                    if( condition[j].field && condition[j].field !== '' && condition[j].sort && condition[j].sort !== ''
                        && condition[j].type && condition[j].type !== '') {
                        sortCondition.push(condition[j]);
                    }
                }
            }

            let paymentChannel = [];
            if(channel.length > 0) {
                for(var j=0; j<channel.length; j++) {
                    if( channel[j].field && channel[j].field !== ''
                        && channel[j].type && channel[j].type !== '') {
                        paymentChannel.push(channel[j]);
                    }
                }
            }

            let data = {
                collectionid: collections,
                "index": ES_INDEX,
                "fieldid": fields,
                "components": [{"label" : "x","field" :x}, {"label" : "y", "field" :y}],
                "condition":sortCondition,
                "channel":paymentChannel,
                "graph":graph,
                "x":x,
                "y":y,
                "filters" : filters,
                "numberfilters" : numberfilters,
                "datefilters" : datefilters,
                "size" : limit,
                "linkedid":this.props.linkedId.param,
                "flags":flagId

            }
            if(flagId.length !==0){
                data.fieldid="101"
            }

            if(setDate){
                if(setDate === 'todays'){

                    data.fromdate=moment().format('YYYY-MM-DD');
                    data.todate=moment().format('YYYY-MM-DD');
                }
                if(setDate === 'lastsevendays'){
                    data.fromdate=moment().add(-7, 'days').format('YYYY-MM-DD');
                    data.todate=moment().format('YYYY-MM-DD')
                }

                if(setDate === 'thismonth'){
                    let thisMoment = moment();
                    data.fromdate=moment().moment(thisMoment).startOf('month').format('YYYY-MM-DD')
                    data.todate=moment().format('YYYY-MM-DD')
                }

                if(setDate === 'last6month'){
                    data.fromdate=moment().subtract(6, 'months').format('YYYY-MM-DD')
                    data.todate=moment().format('YYYY-MM-DD')
                }
                if(setDate === 'daterange'){
                    data.fromdate=moment(setFrom).format('YYYY-MM-DD')
                    data.todate=moment(setTo).format('YYYY-MM-DD')
                }
                if(setDate === 'dateFrom'){
                    data.fromdate=moment().add('-'+dateFrom, 'days').format('YYYY-MM-DD')
                    data.todate=moment().format('YYYY-MM-DD')
                }
            }

            obj.setState({loading:true});
            axios.post(API_PATH+"project/datacard", data).
            then(function (res) {
                var carddata = res.data.results.data;
                var aggregatevalue = carddata.aggregation;
                //console.log(aggregatevalue[0][aggregation])

                let linedata = carddata.graph.map(function (item){
                    let firstKey = Object.keys(item)[0];
                    item[firstKey] = isValidDate(item[firstKey])
                    for (var key in item) {
                        if (item.hasOwnProperty(key) && key !== firstKey) {
                            item[key] = parseInt(item[key]);
                        }
                    }

                    return item;
                });
                //////console.log(linedata)
                obj.setState({linedata: linedata,x:x,y:y,loading:false,condition:condition, cardvalue: aggregatevalue.length > 0? Math.round(aggregatevalue[0][aggregation]) :''});
                return res;

            }).catch(function (err) {
                ////////console.log("error");
                ////////console.log(err);
                obj.setState({loading:false});

                return err;
            });
        }
        else{
            obj.setState({linedata: graphData, cardvalue: 0});

        }
    }
    addData=(Id,updateParam)=>{
        let data=this.state[updateParam]
        let newData={
            id: "item-"+new Date().getTime(),
            label: "item "
        }
        let _data=data.concat(newData)
        this.setState({[updateParam]:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[updateParam]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    removeData=(dragid,updateParam)=>{
        let data=this.state[updateParam]
        let _data=data.filter(item=>item.id !== dragid)
        this.setState({[updateParam]:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        //console.log(payload)
        payload[updateParam]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    radioBoxchange=(e)=> {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    checkBoxchange=(e)=>{
        let val;
        if(e.target.value === "false"){
            val=true
        }
        else{
            val=false
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    handleLabelChange=(e,dragid,whereCondition,type)=>{
        var updatedParam = "items";
        if(whereCondition === "graph"){
            updatedParam = "items";
        }
        else{
            updatedParam = whereCondition;
        }

        var _newState = this.state[updatedParam];
        //console.log(_newState)
        var index=(_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name]=e.target.value;
        if(whereCondition ==="whereCondition" && type !=="field"){
            _newState[index]["type"]=type;
        }
        //console.log(_newState)
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[whereCondition]=this.state[updatedParam]
        //console.log(payload)
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    chartSaving=()=>{
        var prjctdtlid = atob(getUrlSegment(4));
        let sharetype = getUrlSegment(1);
        let  uid = '';
        if(sharetype === 'public') {
            uid = 2;
        }
        else{
            uid = getProfile().id;
        }
        let param={
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard":state.getStorableState(),
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus:false})
    }

    handleClick=(status)=>{
        this.setState({colorpickerStatus:status})
    }

    handleFlagSet=(fid)=>{
        // Get the index of id in the array
        const index = this.state.flagId.indexOf(fid);
        if (index > -1) {
            // This means id is present in the array, so remove it
            this.state.flagId.splice(index, 1);
        } else {
            // This means id is not present in the array, so add it
            this.state.flagId.push(fid);
        }

        // You can use this to debug purpose

        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["flagId"]=this.state.flagId
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });

    }

    copyToClipBoard=(e, link)=> {
        let selectedPaymentLink = link.toString();
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute("id", "dummy_id");
        document.getElementById("dummy_id").value = selectedPaymentLink;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }


    render() {

        // let mainFormData = this.props.mainEntityFormData.showformdata;
        // let pageComponents = [];
        // if(mainFormData.pages && mainFormData.pages.length > 0) {
        //     pageComponents = mainFormData.pages.map((page, pageIndex)=>{
        //         return page.components;
        //     });
        // }


        let responseMappingForm = this.state.responseMappingForm;
        let pageComponents = [];
        if(responseMappingForm && responseMappingForm.pages && responseMappingForm.pages.length > 0 ) {
            pageComponents = responseMappingForm.pages.map((page, pageIndex)=>{
                return page.components;
            });
        }
        let responseFormComponents =  [].concat.apply([], pageComponents);
        //console.log(responseFormComponents)


        const {
            id,
            showBasicContent, showPreview,
            dropzoneID,payload, parentID, name
        } = this.props;

        let sourceType= (payload && payload.sourceType)||false
        let PRsourceType= (payload && payload.PRsourceType)||false
        // console.log(sourceType)
        let showlistField = []
        let sourceData=[]
        let PRsourceData=[]
        if(this.state.showlistField.length !==0) {
            for (let i = 0; i <this.state.showlistField.pages.length; i++) {
                (this.state.showlistField.pages[i].components).map(list=>{
                    showlistField.push(list)
                })
            }
        }
        var collections=''
        const chartTitle =  this.state.chartTitle || payload && payload.chartTitle;
        const subTitle =  this.state.subTitle || payload && payload.subTitle;
        const formLabel =  this.state.formLabel || payload && payload.formLabel;
        const formUrl =  this.state.formUrl || payload && payload.formUrl;
        const min =  this.state.min || payload && payload.min;
        const max =  this.state.max || payload && payload.max;
        const subTitleEnabled = this.state.subTitleEnabled || payload && payload.subTitleEnabled;
        const paymentMode = this.state.paymentMode || payload && payload.paymentMode;
        const email = this.state.email || payload && payload.email;
        const whatsapp = this.state.whatsapp || payload && payload.whatsapp;
        const linedata =  this.state.linedata || payload && payload.linedata || graphData;
        const cardvalue =  this.state.cardvalue || payload && payload.cardvalue ;
        const collection =  this.state.collection || payload && payload.collection ;
        const linecolor =  this.state.linecolor || payload && payload.linecolor ||"#3AFF2D" ;
        const cardcolor =  this.state.cardcolor || payload && payload.cardcolor ;
        const valuecolor =  this.state.valuecolor || payload && payload.valuecolor;
        const labelcolor =  this.state.labelcolor || payload && payload.labelcolor;
        const checked =  this.state.graph || payload && payload.graph||false  ;
        const paymentButtonText =  this.state.paymentButtonText || payload && payload.paymentButtonText;
        const fields =  this.state.fields || payload && payload.fields;


        if(sourceType.toString() === "true"){
            sourceData=  this.props.listAllCollections.ListAllCollections.data
        }
        else{
            sourceData=  this.props.EntityData.Entity.data
        }
        if(PRsourceType.toString() === "true"){
            PRsourceData=  this.props.listAllCollections.ListAllCollections.data
        }
        else{
            PRsourceData=  this.props.EntityData.Entity.data
        }
        let containerData={
            chartTitle :  this.state.chartTitle || payload && payload.chartTitle,
            subTitle :  this.state.subTitle || payload && payload.subTitle,
            formLabel :  this.state.formLabel || payload && payload.formLabel,
            formUrl :  this.state.formUrl || payload && payload.formUrl,
            paymentButtonText :  this.state.paymentButtonText || payload && payload.paymentButtonText,
            min :  this.state.min || payload && payload.min,
            max :  this.state.max || payload && payload.max,
            subTitleEnabled : this.state.subTitleEnabled || payload && payload.subTitleEnabled,
            email : this.state.email || payload && payload.email,
            whatsapp : this.state.whatsapp || payload && payload.whatsapp,
            linedata :  this.state.linedata || payload && payload.linedata || graphData,
            cardvalue :  this.state.cardvalue || payload && payload.cardvalue ,
            collection :  this.state.collection || payload && payload.collection ,
            fields :  this.state.fields || payload && payload.fields ,
            paymentMode :  this.state.paymentMode || payload && payload.paymentMode ,
            paymentTitle :  this.state.paymentTitle || payload && payload.paymentTitle ,
            paymentDesc :  this.state.paymentDesc || payload && payload.paymentDesc ,
            paymentImage :  this.state.paymentImage || payload && payload.paymentImage ,
            paymentItems :  this.state.paymentItems || payload && payload.paymentItems ,
            paymentAmt :  this.state.paymentAmt || payload && payload.paymentAmt ,
            aggregation :  this.state.aggregation || payload && payload.aggregation ,
            graph :  this.state.graph || payload && payload.graph ,
            x :  this.state.x || payload && payload.x ,
            y :  this.state.y || payload && payload.y ,
            linecolor :  this.state.linecolor || payload && payload.linecolor ||"#3AFF2D" ,
            labelcolor :  this.state.labelcolor || payload && payload.labelcolor,
            cardcolor :  this.state.cardcolor || payload && payload.cardcolor ,
            valuecolor :  this.state.valuecolor || payload && payload.valuecolor ,
            checked :  this.state.graph || payload && payload.graph||false  ,
            condition : this.state.condition || payload && payload.condition ,
            whereCondition :    this.state.whereCondition || payload && payload.whereCondition,
            channel :    this.state.channel || payload && payload.channel,
            limit :  this.state.limit || payload && payload.limit,
            sourceType:  sourceType,
            PRsourceType:  PRsourceType,
            showlistField:showlistField,
            collectionData:sourceData,
            PRcollectionData:PRsourceData,
            PRcollection :  this.state.PRcollection || payload && payload.PRcollection ,
            setDate:this.state.setDate || payload && payload.setDate,
            setFrom:this.state.setFrom || payload && payload.setFrom,
            setTo:this.state.setTo || payload && payload.setTo,
            dateFrom:this.state.dateFrom || payload && payload.dateFrom,
           // getFlagData:this.state.getFlagData || this.props.getFlagData.getFlag,
            globalFilter :  this.state.globalFilter || payload && payload.globalFilter ,
            linechart:true,
            flagId:this.state.flagId,
            responseFormComponents : responseFormComponents,
            PRUniqueId :  this.state.PRUniqueId || payload && payload.PRUniqueId ,
            PRID :  this.state.PRID || payload && payload.PRID ,
            PRAmount :  this.state.PRAmount || payload && payload.PRAmount ,
            PRMethod :  this.state.PRMethod || payload && payload.PRMethod ,
            PRStatus :  this.state.PRStatus || payload && payload.PRStatus ,
            PRDate :  this.state.PRDate || payload && payload.PRDate ,
            PRprogram :  this.state.PRprogram || payload && payload.PRprogram

        }

        if (showBasicContent) {
            return (
                <Draggable { ...this.props } >
                    {
                        <span className="drag-icon">{name}</span>
                    }
                </Draggable>
            )
        }

        if (showPreview) {
            // console.log(this.props.paymentData.data)

            return (
                <>
                <div className="ind-small-tab-dashboard payment ge-payment" style={{"background":cardcolor}}>
                    {this.props.loader.loaderType === 'Field_List' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                        <LoaderSvg/>
                    </span>
                    }
                    {this.props.loader.loaderType === 'Payment_Data_Loader' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                        <LoaderSvg/>
                    </span>
                    }
                    {this.props.loader.loaderType === 'Payment_url_Loader' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                        <LoaderSvg/>
                    </span>
                    }

                    {ES_INDEX !== 'hedge' &&
                    <div className="small-tab-header with-desc-box">

                        <h5 style={{"color": labelcolor}}>{chartTitle}</h5>

                        {subTitleEnabled &&
                        <p>{subTitle}</p>
                        }

                        {paymentMode &&
                        <p>Payment Gateway : {paymentMode}</p>
                        }

                        {/*{(email || whatsapp) &&*/}
                        {/*<p>Sending channel*/}
                        {/*    : {email ? "email" + (email && whatsapp ? ', ' : '') : ''} {whatsapp ? "whatsapp" : ''}</p>*/}
                        {/*}*/}

                        {this.state.PaymentUrl !== '' &&
                        <p>Payment Url : <a href={this.state.PaymentUrl} target="_blank">{this.state.PaymentUrl}</a></p>
                        }

                        <div className="payment-button-container">
                            {paymentButtonText &&
                            <button type="submit" className="general-btn btn btn-primary"
                                    onClick={() => this.paymentModal(sourceType, collection, fields, 'normal')}
                            >{paymentButtonText}
                            </button>
                            }

                            {this.state.PaymentUrl !== '' &&
                            <button className="general-btn dark" onClick={(e) => {
                                this.copyToClipBoard(e, this.state.PaymentUrl)
                            }}>Copy Url
                            </button>
                            }
                        </div>
                    </div>
                    }
                    {ES_INDEX === 'hedge' &&
                    <div className="small-tab-header with-desc-box">
                        <h5 style={{"color": labelcolor}}>{chartTitle}</h5>

                        {subTitleEnabled &&
                        <p>{subTitle}</p>
                        }

                        {paymentMode &&
                        <p>Payment Gateway : {paymentMode}</p>
                        }

                        {this.state.razorpayUrl !== '' &&
                        <p>Payment Url : <a href={this.state.razorpayUrl} target="_blank" >{this.state.razorpayUrl}</a></p>
                        }
                        <div className="payment-button-container">
                            <button type="submit" className="general-btn btn btn-primary"
                                    onClick={() => this.paymentModalForRazorpay(sourceType, collection, fields, 'razorpayPayment')}
                            >{paymentButtonText ? paymentButtonText : 'Generate New URL'}
                            </button>

                            {this.state.razorpayUrl !== '' &&
                            <button className="general-btn dark" onClick={(e) => {
                                this.copyToClipBoard(e, this.state.razorpayUrl)
                            }}>Copy Url
                            </button>
                            }
                        </div>
                    </div>
                     }

                    <div className="small-tab-footer">
                        {checked &&
                        <div className="small-tab-graph">
                            <LineChartCard data={linedata} color={linecolor} collection={collection} min={min} max={max}/>
                        </div>
                        }
                    </div>
                </div>
                    {this.state.showPaymentModal &&
                    <SimpleModal
                        Close={this.closePaymentModal}
                        title={`Choose the program`}
                        haveFooter={1}
                        cancelAction={this.closePaymentModal}
                        OKAction={()=>this.submitPayment(parentID, collection)}
                        OKLabel={`Submit`}
                        extraClass="small-height-modal payment-modal-wrap"
                    >
                        <React.Fragment>
                                    <div className="payment-selector-wrap">
                                        <div class="data-search-box">
                                            <input type="text" name=""
                                                   placeholder="Search Here" class="data-search"
                                                   onChange={(e)=>this.getFieldValuesSearch(e, sourceType, collection)}
                                            />
                                        <span class="ico-lib search-ico"></span></div>
                                        {this.state.fieldValues.length !== 0 ?
                                        <div className="payment-mode">
                                            <div className="radio-wrapper" id="recurringmode">
                                                {this.state.fieldValues.map((item,index) =>
                                                    <>
                                                    {item.value !== '' &&
                                                    <div className="radio-block">
                                                        <input onChange={(e)=>this.handlePaymentChange(e)} type="radio"  value={item.id} name="paymentMode"/>
                                                        <label>{item.value} </label>
                                                    </div>
                                                    }
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                            :
                                            <div className="payment-absolute">
                                                <p>No Programs Found</p>
                                            </div>
                                        }
                                        <span className="error-fdls">{this.state.paymentProgramId === '' && this.state.payment_error}</span>
                                    </div>
                        </React.Fragment>

                    </SimpleModal>
                    }
                    {this.state.paymentErrorModal &&
                    <AlertModal
                        id="payment-Error"
                        title={this.state.savePaymentMsg}
                        OK_button_text={"Ok"}
                        OK_action={()=>this.setState({paymentErrorModal:false})}
                        showModal = {this.state.paymentErrorModal}
                        CANCEL_action={()=>this.setState({paymentErrorModal:false})}
                    />
                    }
                </>
            );
        }
        return (
            <React.Fragment>
                <Draggable {...this.props}  draggable={!this.props.DragDisabled.dragStatus}>
                    <FormGroup className="m-0">
                        <label className="col-sm-12">
                            <span>{name}</span>
                            <div className="chart-icons">
                                <div className="settings-icon" onClick={() => this.openSetting(id, dropzoneID, parentID)}></div>
                                <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>
                            </div>
                        </label>
                    </FormGroup>
                </Draggable>
                {this.state.modalStatus &&
                <PaymentSettingsModal
                    title={` Payment -   Settings`}
                    containerData={containerData}
                    colorpickerStatus={this.state.colorpickerStatus}
                    modaltype={8}
                    repeater={false}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    handleChange={this.handleChange}
                    checkBoxchange={this.checkBoxchange}
                    radioBoxchange={this.radioBoxchange}
                    handleConditionChange={this.handleConditionChange}
                    handleChannelnChange={this.handleChannelChange}
                    handleLabelChange={this.handleLabelChange}
                    handleInputChange={this.handleInputChange}
                    handlePRInputChange={this.handlePRInputChange}
                    handleChangeComplete={this.handleChangeComplete}
                    handleFlagSet={this.handleFlagSet}
                    handleClick={this.handleClick}
                    addData={this.addData}
                    removeData={this.removeData}
                    setDate={this.setDate}
                />
                }

            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    loader:state.CommonReducer.loader,
    showFormModal:state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange:state.DynamicDashboard.getDashboard,
    DragDisabled:state.DynamicDashboard.getDragDisabled,
    EntityData:state.EntityReducer.GetEntity,
    filterCnt:state.DynamicDashboard.getFilterCnt,
    linkedId: state.EntityReducer.LinkedId,
    linkedCollectionId: state.EntityReducer.linkedCollectionId,
    LinkedCollectionName: state.EntityReducer.LinkedCollectionNames,
    getFlagData:state.DynamicDashboard.getFlagData,
    getFieldList : state.CeShareView.getFieldList,
    savedPaymentUrl : state.CeShareView.savedPaymentUrl,
    savedPaymentUrlRazorpay : state.CeShareView.savedPaymentUrlRazorpay,
    paymentData : state.CeShareView.paymentData,
    mainEntityFormData : state.EntityReducer.showFormModal


});
export default  withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform : collectionActions.showform,
    chartSaving:DynamicDashboard.saveDashboarddata,
    dragStatus :DynamicDashboard.dragStatus,
    getFlag:DynamicDashboard.getFlag,
    getFieldValues : CeShareViewActions.getFieldValues,
    savePaymentUrl : CeShareViewActions.savePaymentUrl,
    saveRazorpayPaymentUrl : CeShareViewActions.saveRazorpayPaymentUrl,
    getPaymentData : CeShareViewActions.getPaymentData,

})(PaymentContainer));
