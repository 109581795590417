import {combineReducers} from 'redux';

const listallWorkFlow = (state = {ListAllWorkFlow:[],loading:false}, action) => {

    switch (action.type) {

        case "LIST_All_WORKFLOW":
            return {
                ...state,
                ListAllWorkFlow: action.listworkflow.data.results.data,
            };
            break;
        case "WORKFLOW_LOADER":
            return {
                ...state,
                loading: action.loading,
            };
            break;
        default:
            return state;
    }
};
const batchListWorkFlow = (state = {ListAllWorkFlow:[],listworkflowdata:[],loading:false}, action) => {

    switch (action.type) {

        case "LIST_BATCH_WORKFLOW":
            return {
                ...state,
                batchListWorkFlow: action.batchWorkFlowdata
            };
            break;

        default:
            return state;
    }
};
const WorkFlowInfo = (state = {ListAllWorkFlow:[],loading:false}, action) => {

    switch (action.type) {

        case "WORKFLOW_DATA":
            return {
                ...state,
                workFlowData: action.workFlowData.data.results.data,
            };
            break;
        case "WORKFLOW_DATA_LOADER":
            return {
                ...state,
                loading: action.loading,
            };
            break;
        default:
            return state;
    }
};
const workflowStageinfo = (state = {WorkFlowStageinfo:[]}, action) => {
    switch (action.type) {
        case "WORKFLOW_STAGEINFO":
            return {
                ...state,
                WorkFlowStageinfo: action.workflowstageinfo.data.results.data                                    ,
            };
            break;
        default:
            return state;
    }
};
const workflowUserinfo = (state = {WorkFlowUserinfo:{data:{users:[]},results:[]}}, action) => {
    switch (action.type) {
        case "WORKFLOW_USERINFO":
            return {
                ...state,
                WorkFlowUserinfo: action.workflowuserinfo.data.results,
            };
            break;
        default:
            return state;
    }
};
const workflowDetails = (state = {WorkFlowDetails:{data:{stageinfo:[],workflowinfo:[]}}}, action) => {
    switch (action.type) {
        case "WORKFLOW_DETAILS":
            return {
                ...state,
                WorkFlowDetails: action.workflowdetails.data,
            };
            break;
        default:
            return state;
    }
};
const workflowFormDetails = (state = {WorkFlowFormDetails:{data:[],results:[],form:[],pages:[],components:[]}}, action) => {
    switch (action.type) {
        case "WORKFLOW_FORMDETAILS":
            return {
                ...state,
                WorkFlowFormDetails: action.workflowformdetails.data.results,
            };
            break;
        default:
            return state;
    }
};



const workflowStageTaskData= (state = {stagetaskinfo:{task:[],forms:[],attachments:[],assignees:[],metaform:[]}}, action) => {

    var _newState;
    switch (action.type) {
        case "STAGE_TASK_INFO":
            return {
                ...state,
                stagetaskinfo: action.stagetaskinfo.data.results.data,
            };
            break;
        case "UPDATE_META_FORM":
                _newState = {...state};
                _newState[`stagetaskinfo`][`metaform`] = action.metaform;
               // stagetaskinfo: action.stagetaskinfo.data.results.data,
            return _newState;
            break;
        case "UPDATE_META_FORM_SPECIFIC_FIELD":
            _newState = {...state};
            _newState[`stagetaskinfo`][`metaform`][action.index][action.name] = action.value;
            // stagetaskinfo: action.stagetaskinfo.data.results.data,
            return _newState;
            break;
        case "UPADATE_TASK_DATA":
            _newState = {...state};
            _newState[`stagetaskinfo`][`task`][0][action.name] = (action.value);
            // stagetaskinfo: action.stagetaskinfo.data.results.data,
            return _newState;
            break;




        default:
            return state;
    }
};
const workflowInvokeDetails= (state = {invokedetails:{data:[],workflowinfo:[],stageinfo:[],collecteddata:[],_source:[],invoke_show_header:[]}}, action) => {
    switch (action.type) {
        case "INVOKE_DETAILS":
            return {
                ...state,
                invokedetails: action.invokedetails.data.data,
            };
            break;
        default:
            return state;
    }
};

const getModifiedWorkFlow = (state = {workflow_modified : false }, action) => {

    switch (action.type) {
        case "MODIFY_WORKFLOW":
            return {
                ...state,
                workflow_modified: !state.workflow_modified
            };
            break;
        default:
            return state;
    }
};

const saveWorkflowCollection = (state = {workflowcollection_save : false }, action) => {
    switch (action.type) {
        case "SAVE_WORKFLOW_COLLECTION":
            return {
                ...state,
                workflowcollection_save: !state.workflowcollection_save
            };
            break;
        default:
            return state;
    }
};
const getProcessWorkFlow = (state = {processWorkflow : false, processWorkflowtask : [] }, action) => {
    switch (action.type) {
        case "PROCESS_WORKFLOW_TASK":
            return {
                ...state,
                processWorkflowtask: action.processWorkflowtask,
                processWorkflow : !state.processWorkflow
            };
            break;
        case "PROCESS_THROUGH_API":
            return {
                ...state,
                processWorkflowtask: action.processWorkflowtask,
                processWorkflow: !state.processWorkflow
            };
            break;
        default:
            return state;
    }
};
const StageTaskInfo = (state = {StagetaskInfo : {assignees:[],attachments:[],externaluser:[] ,forms:[], stages:[], status : [], task:[]}, loading:false}, action) => {

    switch (action.type) {
        case "GET_STAGE_TASK_INFO":
            return {
                ...state,
                StagetaskInfo: action.stagetaskinfodet.data.results.data
            };
            break;
        case "STAGE_INFO_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};
const listWorkFlowtags = (state = {WorkFlowtags:[]}, action) => {
    switch (action.type) {

        case "LIST_All_WORKFLOW_TAGS":
            return {
                ...state,
                WorkFlowtags: action.WorkFlowtaglist

            };
            break;
        case "WORKFLOW_TAG_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
const noformWorkFlow = (state = {noFormWorkFlow:[],loading:false}, action) => {
    switch (action.type) {
        case "NO_FORM_WORKFLOW":
            return {
                ...state,
                noFormWorkFlow: action.noformworkflow.data.data
            };
        case "NOFORM_WORKFLOW_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};
const listWorkflowBatchData = (state = {data:[],taskStatus:false,loading:false}, action) => {
    switch (action.type) {
        case "LIST_All_WORKFLOW_BATCH":
            return {
                ...state,
                data: action.listworkflowbatch.data.data
            };
        case "SAVE_WORKFLOW_BATCH":
            return {
                ...state,
                taskStatus:!state.taskStatus
            };
        case "DELETE_WORKFLOW_BATCH":
            return {
                ...state,
                taskStatus: !state.taskStatus
            };
        case "WORK_FLOW_LOADER":
            return {
                ...state,
                loading: action.loading
            };
        case "ACTIVATE_WORKFLOW_BATCH":
            return {
                ...state,
                taskStatus: action.activateWorkflowBatch
            };



        default:
            return state;
    }
};


const getRecordWorkflow = (state = {loading : false, savedMeta : [], processed : [] }, action) => {
    switch (action.type) {
        case "WORKFLOW_META_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        case "WORKFLOW_META_SAVE":
            return {
                ...state,
                savedMeta: action.savedMeta
            };
            break;
        case "PROCESS_ASSIGNED_NEXTSTAGE":
            return {
                ...state,
                processed: action.processed
            };
            break;
        case "ASSIGNED_NEXTSTAGE_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        case "CLOSE_RECORD_WORKFLOW":
            return {
                ...state,
                closed: action.closed
            };
            break;
        default:
            return state;
    }
};

export const WorkFlow = combineReducers({
    listallWorkFlow,
    workflowStageinfo,
    workflowUserinfo,
    workflowDetails,
    workflowFormDetails,
    workflowInvokeDetails,
    workflowStageTaskData,
    getModifiedWorkFlow,
    saveWorkflowCollection,
    getProcessWorkFlow,
    StageTaskInfo,
    WorkFlowInfo,
    listWorkFlowtags,
    listWorkflowBatchData,
    batchListWorkFlow,
    noformWorkFlow,
    getRecordWorkflow
});

export default WorkFlow;

