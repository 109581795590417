import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getProfile} from "../../../utilities/AuthService";
import {ES_INDEX, USRIMG_PATH} from "../../../constants";
import {MessageBox,GetChatMedia,MetaBox,AttachmentBox,WhatsappBox,MessageInitialScreen,MessageHeadLine} from "./ChatData"
import rootActions from "../../../actions"
import {forceDownload, getUrlSegment, urltoFile} from "../../../utilities/CustomFunctions";
import moment from 'moment'
import {chat} from "../../../services/chat.services";
import SimpleModal from "../../views/SimpleModal";
import LoaderSvg from "../../views/LoaderSVG";
import UploadFile,{DirectFileUpload} from "../../../utilities/FileUpload/UploadFile";
import OutsideAlerter from "../../views/OutsideAlerter";
import Modal from "../../views/Modal";
import AddToDoTask from "../AddToDoTask";
import ChatResourceList from "./ChatResourceList";
const PickerBox = React.lazy(() =>  import('../../views/PickerBox'))
class MainChat extends Component {
    listRef = null;
    constructor(props) {
        super(props)
        this.state = {
            appendingChatData: [],
            messageData: '',
            receiver: '',
            attachmentStatus: false,
            previewStatus: false,
            name: '',
            url: '',
            progressPanel: true,
            scrollTop:0,
            scroll:false,
            emojiState:false,
            attachmentMesage:'',
            clipboardStatus:false,
            watsupStatus:true,
            clipboardUrl:'',
            wReply:false,
            wData:"",
            task:false,
            modalresources: false,
            showcardDetails: false,
            schat_id:"",
            msg_ids:[],
            cTask:false,
            aTask:false,
            selectedChats: []

        }
        this.SaveChat = this.SaveChat.bind(this)
        this.addAttachment = this.addAttachment.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.preview=this.preview.bind(this)
        this.Close = this.Close.bind(this)
        this.attachmentClose = this.attachmentClose.bind(this)
        this.emojiStatus=this.emojiStatus.bind(this)
        this.pasteHandlechange=this.pasteHandlechange.bind(this)
        this.date = "1919-07-21 21:20:56"
        this.UsersGroup = [];
        this.textInput = React.createRef();
        this.openResourcesModal = this.openResourcesModal.bind(this);
        this.cardDetails = this.cardDetails.bind(this);
    }
    getSnapshotBeforeUpdate(prevProps, prevState) {

        // Are we adding new items to the list?
        // Capture the scroll position so we can adjust scroll later.

        if (this.props.chatUserId.ReceiverUserid !== '' && prevProps.chathistory.ListChatHistory.length < this.props.chathistory.ListChatHistory.length) {

            return (
                this.listRef.scrollHeight - this.listRef.scrollTop
            );
        }
        return null;
    }
    addEmoji=(emoj)=>{
        this.setState({messageData: this.state.messageData+emoj.native,emojiState:false})
        this.textInput.current.focus();
    }
    onScroll = () => {
        /* load More Data
         * Call this function only when component in scrollable
         * this.props.chathistory.ListChatHistory.length is used to check avoid initial call  call (scrollTop = 0)
         */
        if(this.listRef.scrollTop === 0 && this.props.chathistory.ListChatHistory.length !==0 ){
            this.props.loadMorechatHistory(getProfile().id, this.props.chatUserId.ReceiverUserid, this.props.chathistory.ListChatHistory.length.toString(),'20',this.props.chatUserId.chatType)
        }


    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }
    componentDidMount() {
        var socket = this.props.socket
        /* The page reload set current page state in props */
        this.props.mainClick('chat', '/chat')
        /* Set Chat data, When user click message notoification in ci header */
        if(getUrlSegment(2)){
            var userDetails=JSON.parse(atob(getUrlSegment(2)))
            this.props.chatHistory(getProfile().id, userDetails.receiverId, '0', '100', ES_INDEX,(userDetails.type ==='One to One'?"single":"group"),(userDetails.type ==='One to One'?userDetails.name:(userDetails.name)),'',(userDetails.type ==='One to One'?userDetails.userHead:''))
            this.props.history.push('/chat')
        }
        /* list all users and group based on user id */
        this.props.allUsers(getProfile().id, 'user');

        this.props.allGroups(getProfile().id, 0, 50, ES_INDEX);

        var that = this

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.chatUserId.ReceiverUserid !== this.props.chatUserId.ReceiverUserid) {

            //this.date = moment.utc().local()
        }
        if (this.props.chatUserId.ReceiverUserid !== '') {
            //   document.getElementById('contentid').scrollTop = document.getElementById('contentid').scrollHeight
        }
        if (snapshot !== null) {
            this.listRef.scrollTop =
                this.listRef.scrollHeight - snapshot;
        }
        if (prevProps.allgroups !== this.props.allgroups) {
            var data = (this.props.allgroups.ListAllgroups);
            for (var i = 0; i < data['data'].length; i++) {
                var temp = 0;
                var groupname = atob(data['data'][i].groupname, 'base64');
                var groupdesc = atob(data['data'][i].groupdesc, 'base64');
                this.UsersGroup.push({
                    groupid: data['data'][i].groupid,
                    groupname: groupname,
                    groupdesc: groupdesc,
                    status: data['data'][i].status,
                    has_icon: data['data'][i].has_icon,
                    creator: data['data'][i].creator,
                    createdate: data['data'][i].createdate,
                    counter: temp
                })

            }
            this.props.socket.emit("users_group", this.UsersGroup);
        }
        if (prevProps.creategroupdata !== this.props.creategroupdata) {
            var that = this
            setTimeout(function () {
                that.props.allGroups(getProfile().id, 0, 50, ES_INDEX);
                that.props.chatHistory("", that.props.creategroupdata.createGroup.results.id, 0, 50, ES_INDEX, 'group', btoa(that.props.creategroupdata.createGroup.results.groupname), '', '')
                that.props.groupInfo(that.props.creategroupdata.createGroup.results.id, ES_INDEX)
                that.props.allGroups(getProfile().id, 0, 500, ES_INDEX);
            }, 1000);
        }
        if (prevProps.removeusergroupUpdation !== this.props.removeusergroupUpdation) {
            this.props.socket.emit('removeuser', this.props.removeusergroupUpdation.info.userid, this.props.removeusergroupUpdation.info.groupid,ES_INDEX);
        }
        if (prevProps.savegoupchat !== this.props.savegoupchat) {
            if (this.props.savegoupchat.messageData.has_metaurl) {
            }
            if (this.props.savegoupchat.messageData.has_attachment === 'yes') {

            }
        }
        if (prevProps.groupdelete !== this.props.groupdelete) {
            this.props.socket.emit('group_deleted_by_owner',  getProfile().id, this.props.chatUserId.ReceiverUserid);
            var that = this
            setTimeout(function () {
                that.props.allGroups(getProfile().id, 0, 50, ES_INDEX);
            }, 1000);
            this.props.chatReset()
        }
        if (prevProps.EditGroup !== this.props.EditGroup) {
            var that = this
            setTimeout(function () {
                that.props.groupInfo(that.props.chatUserId.ReceiverUserid,ES_INDEX)
            }, 1500)
        }
        if (prevProps.MembersStatus !== this.props.MembersStatus) {
            var that = this
            setTimeout(function () {
                that.props.groupInfo(that.props.chatUserId.ReceiverUserid,ES_INDEX)
            }, 1500)
        }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ) {
            //this.replyWmess("task",this.props.HistoryPushData)
            var has_task="yes"
            let task_content=this.state.selectedChats
            if(this.state.cTask) {

               this.props.saveChat(getProfile().id, this.props.chatUserId.ReceiverUserid, '0', this.state.messageData, 'no', ES_INDEX, false, this.props.socket, this.state.appendingChatData.length,[],has_task,task_content,this.props.HistoryPushData.data)
            }
            else{
                this.replyWmess("task",this.props.HistoryPushData)
            }
            this.setState({task: false,cTask:false,chat_id:"",selectedChats:[],msg_ids:[]});



        }
    }
    pasteHandlechange(event) {
        var items = (event.clipboardData || event.originalEvent.clipboardData).items;
        let index;
        for (index in items) {
            var item = items[index];
            if (item.kind === 'file') {
                var blob = item.getAsFile();
                var reader = new FileReader();
                let that=this
                reader.onload = function (event) {
                    that.setState({clipboardStatus:true,clipboardUrl:event.target.result})
                    var src = encodeURI(event.target.result);
                    that.setState({clipboardStatus:true,clipboardUrl:event.target.result,src:src})
                    var _newImg= ES_INDEX+"-"+Math.round(new Date().getTime() + (Math.random() * 100))+".jpg";
                    urltoFile(src, _newImg, 'image/jpeg')
                        .then(function (file) {
                            that.setState({copyFile:file})
                        }.bind(this))
                }; // data url!
                reader.readAsDataURL(blob);
            }
        }
    }
    saveCopyData = () =>{
        const file =this.state.copyFile;
        let fileData=[]
        this.setState({clipboardStatus:false,clipboardUrl:""})
        DirectFileUpload(file)
            .then(data => {
                var filename = data.key.substring(data.key.lastIndexOf("/") + 1, data.key.length);
                fileData.push({
                    "filetype": data.type,
                    "filesize": data.filesize.toString(),
                    "filetitle": data.file,
                    "name": data.file,
                    "res_type": '1',
                    "filename": filename
                });
                if (this.props.chatUserId.chatType === 'single') {
                    this.props.saveChat(getProfile().id, this.props.chatUserId.ReceiverUserid, '0', this.state.messageData, 'yes', ES_INDEX, false, this.props.socket, this.state.appendingChatData.length, fileData,"")
                    this.setState({messageData:'',clipboardStatus:false,clipboardUrl:""})
                } else {
                    this.props.groupsaveChat(this.props.chatUserId.ReceiverUserid, getProfile().id, '0', this.state.messageData, 'yes', ES_INDEX, false, this.props.socket, this.state.appendingChatData.length, fileData,atob(this.props.groupinfo.info[0].groupname))
                    this.setState({messageData:'',clipboardStatus:false,clipboardUrl:""})
                }
            })
            .catch(err => {
                this.setState({copyFile:''})
            })
    }
    emojiStatus(){
        this.setState({emojiState:!this.state.emojiState})

    }
    urlCheck(msg) {
        var expression =/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        //var expression = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

        var regex = new RegExp(expression);
        return msg.match(regex)
    }
    openResourcesModal(){
        this.setState({modalresources:!this.state.modalresources})
    };

    SaveChat() {
        let that = this
        var has_metaurl = false
        if (this.state.messageData === '') {
            return false;
        }
        // this.props.sessionvalidate(getProfile().cookie, getProfile().id)
        // var date = new Date();
        // date.setTime(date.getTime() + (2 * 60 * 60 * 1000));
        // var expires = date.toUTCString();
        // document.cookie = SHARED_LOGIN.cookie+'=' + getProfile().scookie + ';domain=.bsynapse.com;expires=' + expires + ';path=/';
        if (!this.state.attachmentStatus) {
            if (this.urlCheck(this.state.messageData)) {
                has_metaurl = true;
            }
        }
        if (this.props.chatUserId.chatType === 'single') {
            if (!has_metaurl) {
                this.props.saveChat(getProfile().id, this.props.chatUserId.ReceiverUserid, '0', this.state.messageData, 'no', ES_INDEX, has_metaurl, this.props.socket, this.state.appendingChatData.length)
            }
            if (has_metaurl) {
                this.props.saveChat(getProfile().id, this.props.chatUserId.ReceiverUserid, '0', this.state.messageData, 'no', ES_INDEX, has_metaurl, this.props.socket, this.state.appendingChatData.length)
            }
            this.setState({messageData: ''})
        } else {
            this.props.groupsaveChat(this.props.chatUserId.ReceiverUserid, getProfile().id, '0', this.state.messageData, 'no', ES_INDEX, has_metaurl, this.props.socket, this.state.appendingChatData.length, "", atob(this.props.groupinfo.info[0].groupname))
            this.setState( {messageData: ''})

        }
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter' && this.state.messageData !== '') {
            this.SaveChat();
        }
    }
    addAttachment(Status) {
        this.setState({attachmentStatus: !Status})
    }
    lazyUploadStatus=()=>{
        this.setState({displayStatus:"none",attachmentMesage:this.state.messageData,messageData:""})
    }
    handleFile=(fileData)=>{

        if (this.props.chatUserId.chatType === 'single') {
            this.props.saveChat(getProfile().id, this.props.chatUserId.ReceiverUserid, '0', this.state.attachmentMesage, 'yes', ES_INDEX, false, this.props.socket, this.state.appendingChatData.length, fileData,"")
            this.setState({attachmentStatus:false,displayStatus:"block",attachmentMesage:"",messageData:''})
        } else {
            this.props.groupsaveChat(this.props.chatUserId.ReceiverUserid, getProfile().id, '0', this.state.attachmentMesage, 'yes', ES_INDEX, false, this.props.socket, this.state.appendingChatData.length, fileData,atob(this.props.groupinfo.info[0].groupname))
            this.setState({attachmentStatus:false,displayStatus:"block",attachmentMesage:"",messageData:''})
        }


    }
    preview(url, name) {
        this.setState({url: url, name: name, previewStatus: !this.state.previewStatus})
    }
    Close() {
        this.setState({previewStatus: !this.state.previewStatus})
    }
    attachmentClose(){
        this.setState({attachmentStatus: !this.state.attachmentStatus})
    }
    copyfileClose=()=>{
        this.setState({clipboardStatus:false,clipboardUrl:""})

    }
    closeWreply=(event)=>{
        this.setState({watsupStatus:false})

    }

    whatsappReply=(id,msg,index,number)=>{
        this.setState({wReply:!this.state.wReply,watsupStatus:true,wTo:id,msg:msg,wInd:index})
    }
    replyWmess=(type,info="")=>{
        let parm={
            wMes:this.state.wData,
            wTo:this.state.wTo,
            msg:this.state.msg
        }
        this.setState({wData:""})
        if(type !== "task") {
            this.whatsappReply("", "")
        }

        let whatsappinfo=[{
            replyto:this.state.wData,
            whatsappno:this.state.wTo,
            taskinfo: info !=="" ? "/task/details/"+btoa(info.data)+"/YnktbWU=":""
        }]

        this.props.saveWhatsapp(this.props.chatUserId.ReceiverUserid, getProfile().id, '0', atob(this.state.msg), 'no', ES_INDEX, false, this.props.socket, this.state.appendingChatData.length, "", atob(this.props.groupinfo.info[0].groupname),type,whatsappinfo)
        if(type !== "task") {
            this.props.replyWmess(parm)
        }
    }
    createTak=(id,msg)=>{
        this.setState({task:!this.state.task,wTo:id,msg:msg})
    }
    cardDetails=(id,dataval,chat_id)=>{
        this.setState({showcardDetails:chat_id})
        if(dataval !== undefined){
            let whtsNo=dataval.split(":")
                let params={
                    "groupid": this.props.chatUserId.ReceiverUserid,
                    "dataval": whtsNo[1]
                }
                this.props.listuserEntityDetails(params);
        }
    }

    chatPointer=(id)=>{
        // alert(id)
        // const items = document.getElementById(id);
        // items.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
    handleCheckboxChange =(e)=>{
        let value = e.target.value;
        let checked = e.target.checked;
        let msg_ids = [...this.state.msg_ids];
        if(checked) {
            msg_ids.push(value);
        }
        else {
            let index = msg_ids.indexOf(value);
            if(index > -1) {
                msg_ids.splice(index, 1);
            }
        }

        let selectedChats = [];
        if(msg_ids.length > 0) {
            for(let i=0; i<msg_ids.length; i++) {
                this.props.chathistory.ListChatHistory.filter((chat,index) => {
                    if(chat.chat_id === msg_ids[i]){
                        selectedChats.push(chat);
                    }
                },selectedChats)
            }
        }
        if(selectedChats.length > 0) {
            selectedChats.sort(function(a, b) {
                    return new Date(a.createdate).getTime() - new Date(b.createdate).getTime();
                }
            );
        }

        this.setState({chatSelection : msg_ids.length > 0 ? true : false,msg_ids : msg_ids, selectedChats : selectedChats})
    }
    CreateTask=()=>{
        this.setState({cTask:!this.state.cTask})

    }

    showMsgActionMenu=(chat_id)=>{
        if(chat_id === this.state.chat_id){
            this.setState({schat_id:""})
        }
        else {
            this.setState({schat_id: chat_id})
        }
    }
    singleTask=()=>{
        this.setState({aTask:!this.state.aTask})
    }
    AssignTask=()=>{
        this.setState({cTask:!this.state.cTask})

    }

    getChatMedia=()=>{
        let param={
            sender:getProfile().id,
            receiver:this.props.chatUserId.ReceiverUserid,
            type: this.props.chatUserId.chatType === 'group' ?"Group_chat":'One to One',
            limit:0,
            offset:100
        }


        this.props.getChatmedia(param)
    }



    render() {

        this.date=moment.utc().local().format("DD-MM-YYYY");
        let listentityDetails = this.props.listentityDetails.data.collectedform||[];
        let linkjsoncontent = this.props.listentityDetails.data.jsoncontent !== undefined ? JSON.parse(atob(this.props.listentityDetails.data.jsoncontent)):[];
        let imageData=listentityDetails.filter(item=>parseInt(item.masterid) === parseInt(linkjsoncontent.image))
        let dataValue1=listentityDetails.filter(item=>parseInt(item.masterid) === parseInt(linkjsoncontent.data1))
        let dataValue2=listentityDetails.filter(item=>parseInt(item.masterid) === parseInt(linkjsoncontent.data2))
        let dataValue3=listentityDetails.filter(item=>parseInt(item.masterid) === parseInt(linkjsoncontent.data3))
        return (
            <React.Fragment>
                {(this.props.loader.loaderType === 'chatContainer' || this.props.listentityDetails.loading)&&
                <span className="Loader-holder" style={{'background':'transparent'}}>
                            <LoaderSvg/>
                   </span>
                }
                {(this.props.chatUserId.ReceiverUserid !== '') ?
                    <div className={`chat-panel-holder ${(this.props.chatUserId.chatType === 'single' || this.props.chatUserId.chatType === 'group')?"with-tab":""}`} key={`chat-panel-holder`}>
                        <div id={`progress-holder`} className={'upload-pr-wrap'}/>
                        <div className="chat-mob-header">
                            <div className="chat-profile">
                                <div className="chat-profile-img">
                                    <img src={USRIMG_PATH + this.props.chatUserId.image}/>
                                </div>
                                <div className="chat-profile-description">
                                    <h5>{this.props.chatUserId.chatType === 'group' ? atob(this.props.chatUserId.name) : this.props.chatUserId.name}</h5>
                                    <p>{this.props.chatUserId.designation}</p>
                                </div>
                            </div>
                            <div className="communication-block">
                                {this.props.chatUserId.chatType === 'group' &&
                                <div className="user-info-click ">
                                    <a href="javascript:void(0);" className="chat-com ico-lib add-us"/>
                                </div>
                                }
                                <div className="user-info-click ">
                                    <a href="javascript:void(0);" className="chat-com ico-lib pin-cl"/>
                                </div>
                                <div className="user-info-click ">
                                    <a href="javascript:void(0);" className="chat-com ico-lib audio-cl"/>
                                </div>
                                <div className="user-info-click ">
                                    <a href="javascript:void(0);" className="chat-com ico-lib video-cl"/>
                                </div>
                            </div>
                        </div>
                        <div className={`chat-all-wrapper ${this.state.aTask?"chat-add-opt":""}`}>
                            <div className="chat-in-panel" ref={this.setListRef}
                                 onScroll={this.onScroll}

                            >
                                <div className="chat-in-content">
                                    <MessageHeadLine
                                        chatUserId={this.props.chatUserId}
                                    />

                                    {this.props.chathistory.ListChatHistory.map((list, index) => {
                                        if (moment.utc(this.date).local().format("DD-MM-YYYY") !== moment.utc(list.createdate).local().format("DD-MM-YYYY")) {
                                            var nextdate = list.createdate;
                                            this.nextImage=''
                                            var image=false
                                        }
                                        if(this.nextImage !== list.sender || list.has_whatsapp ){
                                            image=true
                                        }
                                        else{
                                            image=false
                                        }
                                        this.date = list.createdate;
                                        this.nextImage=list.sender
                                        return (
                                            <div key={`list-chat-history-data`+index}>
                                                {((moment.utc(nextdate).local().format("DD-MM-YYYY")=== moment.utc(list.createdate).local().format("DD-MM-YYYY")) && nextdate !== undefined  )&&
                                                <div className="chat-date-indicator">
                                                    <span className="chat-date-in"> {moment.utc(nextdate).local().format('DD-MM-YYYY') === moment.utc().local().format('DD-MM-YYYY') ? 'Today' : moment.utc(nextdate).local().format('DD-MM-YYYY')}</span>
                                                </div>
                                                }
                                                {/*// && list.hasOwnProperty(`has_whatsapp`)) === false*/}
                                                {(this.props.chatUserId.chatType === 'single') &&
                                                <MessageBox
                                                    showMessageBox={(list.has_metaurl === false && list.has_attachment === 'no' && list.has_task === 'no' && list.reply_task === "no")}
                                                    index={index}
                                                    sender={list.sender}
                                                    createdate={list.createdate}
                                                    message={list.message}
                                                    showImage={image}
                                                    userhead={list.userhead}
                                                    creator={list.creator}
                                                    has_whatsapp={list.has_whatsapp}
                                                    whatsappinfo={list.whatsappinfo}
                                                    chat_id={list.chat_id}
                                                    schat_id={this.state.schat_id}
                                                    msg_ids={this.state.msg_ids}
                                                    aTask={this.state.aTask}
                                                    handleCheckboxChange={this.handleCheckboxChange}
                                                    whatsappReply={this.whatsappReply}
                                                    createTak={this.createTak}
                                                    showMsgActionMenu={this.showMsgActionMenu}
                                                    singleTask={this.singleTask}
                                                    chatType={this.props.chatUserId.chatType}

                                                />
                                                }
                                                {list.has_task === 'yes' &&
                                                <div className="message-in-box">

                                                    <div id={list.chat_id}
                                                         className="chat-message-detailfalse with-more-option ">

                                                        <div className="task-group-container">
                                                            {JSON.parse(list.taskcontent).map((item,index)=>
                                                            <div id={item.chat_id} className="wa-reply-box">
                                                                <div className="wa-each-chat-list-header">
                                                                    <h5>{item.creator}</h5>
                                                                </div>
                                                                <div className="wa-each-chat-list-content">
                                                                    <p>{atob(item.message)}</p>
                                                                </div>
                                                            </div>
                                                            )}
                                                        </div>
                                                        <div className="buisiness-reply-box">
                                                            <p onClick={() => this.props.history.push("task/details/" + btoa(list.taskid) + "/dG8tbWU=")} className="ico-lib icon-task-assign with-text">Go to
                                                                Task</p>
                                                        </div>
                                                    </div>
                                                </div>




                                                }

                                                {list.reply_task === 'yes' &&
                                                <div className="message-in-box">

                                                    <div id={list.chat_id}
                                                         className="chat-message-detailfalse with-more-option ">

                                                        <div className="task-group-container">
                                                            {JSON.parse(list.taskcontent).map((item,index)=>
                                                                <div id={item.chat_id} className="wa-reply-box">
                                                                    <div className="wa-each-chat-list-header">
                                                                        <h5>{item.creator}</h5>
                                                                    </div>
                                                                    <div className="wa-each-chat-list-content">
                                                                        <p>{atob(item.message)}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="buisiness-reply-box">
                                                            <p className="ico-lib  with-text">{list.reply_content}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                }

                                                <MetaBox
                                                    showMessageBox={list.has_metaurl}
                                                    index={index}
                                                    sender={list.sender}
                                                    createdate={list.createdate}
                                                    message={list.message}
                                                    showImage={image}
                                                    userhead={list.userhead}
                                                    creator={list.creator}
                                                    metainfo={list.metainfo}
                                                    chat_id={list.chat_id}
                                                    schat_id={this.state.schat_id}
                                                    msg_ids={this.state.msg_ids}
                                                    aTask={this.state.aTask}
                                                    handleCheckboxChange={this.handleCheckboxChange}
                                                    whatsappReply={this.whatsappReply}
                                                    createTak={this.createTak}
                                                    showMsgActionMenu={this.showMsgActionMenu}
                                                    singleTask={this.singleTask}
                                                />

                                                <AttachmentBox
                                                    showMessageBox={list.has_attachment==='yes'}
                                                    index={index}
                                                    sender={list.sender}
                                                    createdate={list.createdate}
                                                    message={list.message}
                                                    showImage={image}
                                                    userhead={list.userhead}
                                                    creator={list.creator}
                                                    attachment={list.attachment}
                                                    preview={this.preview}
                                                    chat_id={list.chat_id}
                                                    schat_id={this.state.schat_id}
                                                    msg_ids={this.state.msg_ids}
                                                    aTask={this.state.aTask}
                                                    handleCheckboxChange={this.handleCheckboxChange}
                                                    whatsappReply={this.whatsappReply}
                                                    createTak={this.createTak}
                                                    showMsgActionMenu={this.showMsgActionMenu}
                                                    singleTask={this.singleTask}
                                                    chatmediaStatus={this.props.chatmediaStatus}
                                                    type={`mainchat`}
                                                    ChatType={this.props.chatUserId.chatType}

                                                />


                                                <WhatsappBox
                                                    showMessageBox={(list.hasOwnProperty(`has_whatsapp`)) === true}
                                                    index={index}
                                                    sender={list.sender}
                                                    createdate={list.createdate}
                                                    message={list.message}
                                                    showImage={image}
                                                    userhead={list.userhead}
                                                    creator={list.creator}
                                                    has_whatsapp={list.has_whatsapp}
                                                    whatsappinfo={list.whatsappinfo}
                                                    whatsappReply={this.whatsappReply}
                                                    createTak={this.createTak}
                                                    chatPnt={this.chatPointer}
                                                    chat_id={list.chat_id}
                                                    history={this.props.history}
                                                    groupid={list.groupid}
                                                    cardDetails={this.cardDetails}
                                                    showcardDetails={this.state.showcardDetails}
                                                    imageData={imageData}
                                                    dataValue1={dataValue1}
                                                    dataValue2={dataValue2}
                                                    dataValue3={dataValue3}
                                                />
                                                {this.state.wReply && this.state.wInd === index && this.state.watsupStatus === true &&
                                                <div className="message-in-box reply-msg ">
                                                    <div className="chat-message-detailfalse">
                                                        <div className="message-in-box ">
                                                            <figure className="user-image-holder">
                                                                <img src="https://bsynapse.imgix.net/images/fn1595809363356.jpg" alt="user-image" className="chat-user-image-in" />
                                                            </figure>
                                                            <div className="chat-message-detailfalse">
                                                                <h5 className="chat-user-name purple">{this.state.wTo}</h5>
                                                                <p className="chat-message-in">{atob(this.state.msg)}</p>
                                                            </div>
                                                            <button className="reply-close" onClick={(e) => this.closeWreply(e)} ></button>
                                                        </div>
                                                        <div className="reply-textbox">
                                                                        <textarea autoComplete={false} id={`messagebox-attachment`}
                                                                                  type="text" name="wData" className="upload-comment"
                                                                                  placeholder="Type here" value={this.state.wData}
                                                                                  onChange={(e) => this.handleChange(e)} onKeyPress={this.handleKeyPress}
                                                                        />
                                                        </div>
                                                        <div className="reply-complete-area">
                                                            <button className="attach-res" onClick={() => this.openResourcesModal()}></button>
                                                            <button className="chat-submit"  onClick={()=>this.replyWmess("reply")}></button>
                                                        </div>
                                                        {/* <p className="chat-message-in">test case message</p> */}
                                                    </div>
                                                </div>
                                                }



                                            </div>
                                        )

                                    })}
                                </div>
                            </div>
                            <div className="chat-type-panel">
                                {/*{!this.state.attachmentStatus &&*/}
                                <div className="chat-text-area">

                                    {this.state.selectedChats.length !== 0 &&
                                    <button className="ico-lib-v1 chat-task-section" onClick={()=>this.AssignTask()}>Assign Task </button>

                                    }



                                    <button className="chat-attach-button" onClick={() => this.addAttachment(this.state.attachmentStatus)}/>
                                    {this.state.emojiState &&
                                    <OutsideAlerter
                                        status={this.state.emojiState}
                                        Triggered={() => this.emojiStatus()}
                                    >
                                        <div className={`emoji-panel`}>
                                            <PickerBox onSelect={this.addEmoji}/>
                                        </div>
                                    </OutsideAlerter>

                                    }
                                    <input id={`messagebox`} type="text"
                                           ref={this.textInput}
                                           autoComplete={"off"}
                                           name="messageData"
                                           className="message-input-in"
                                           placeholder="Type here" value={this.state.messageData}
                                           onChange={(e) => this.handleChange(e)}
                                           onKeyPress={this.handleKeyPress}
                                           onPaste={this.pasteHandlechange}
                                    />
                                    {!this.state.emojiState &&
                                    <button className="chat-send-btn-emoji" onClick={this.emojiStatus}>😄</button>}
                                    <button className="chat-send-btn" onClick={this.SaveChat}/>
                                </div>



                                {/*{this.state.attachmentStatus &&*/}
                                {/*<OutsideAlerter*/}
                                {/*status={this.state.attachmentStatus}*/}
                                {/*Triggered={this.addAttachment}*/}
                                {/*>*/}
                                {/*<form id={`fileUpload`}>*/}
                                {/*<div className="attachment-fly-box">*/}
                                {/*<label className="cs-file-type-chat">*/}
                                {/*<input id="fileup" type="file" name="upload[]"*/}
                                {/*className="chat-attach-file-type"*/}
                                {/*onChange={(e) => this.handleFile(e, 'logo')} multiple/>*/}
                                {/*<div className="attachment-placeholder">*/}
                                {/*<span className="icon-in icon-attachment-chat"></span>*/}
                                {/*<span className="attachment-text">Attach Files</span>*/}
                                {/*</div>*/}
                                {/*</label>*/}
                                {/*</div>*/}
                                {/*</form>*/}
                                {/*</OutsideAlerter>*/}

                                {/*}*/}
                                {this.state.previewStatus &&
                                <SimpleModal
                                    Close={this.Close}
                                >
                                    <div className="preview-area chat-panel">
                                        <img className={`chat-image-panel`}
                                             src={this.state.url}/>
                                        <div className="preview-options">
                                            <ul className="resources-filters-icons">
                                                <li><a className="rs-tab-btn ico-lib-v1 rc-download single-option"
                                                       onClick={() => forceDownload(this.state.url, this.state.name)}> </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </SimpleModal>
                                }
                                {this.state.clipboardStatus &&
                                <SimpleModal
                                    Close={this.copyfileClose}
                                >

                                          <textarea id={`messagebox-attachment`} type="text" name="messageData" className="upload-comment"
                                                    placeholder="Type here" value={this.state.messageData}
                                                    onChange={(e) => this.handleChange(e)} onKeyPress={this.handleKeyPress}/>
                                    <img className={`chat-image-panel copy-data-panel`}  src={this.state.clipboardUrl}/>
                                    <div className="button-block">
                                        <button type="button" className="general-btn" onClick={this.saveCopyData}>Submit</button>
                                    </div>

                                </SimpleModal>
                                }

                                {this.state.attachmentStatus &&
                                <div style={{display:this.state.displayStatus}}>
                                    <SimpleModal
                                        Close={this.attachmentClose}
                                    >
                                        <textarea autoComplete={false} id={`messagebox-attachment`}
                                                   name="messageData" className="upload-comment"
                                                  placeholder="Type here" value={this.state.messageData}
                                                  onChange={(e) => this.handleChange(e)} onKeyPress={this.handleKeyPress}
                                        />
                                        <UploadFile
                                            AutoUpdate={false}
                                            getUploadedFile={this.handleFile}
                                            multipleFiles={true}
                                            overLay={true}
                                            dropZone={true}
                                            wrapperclassName=""
                                            progressBar={true}
                                            progressContainer={`progress-holder`}
                                            videoAlbum={'videos'}/* only for chat*/
                                            lazyUpload={true}
                                            lazyUploadStatus={this.lazyUploadStatus}
                                            progressbarType={`line`}
                                        />
                                    </SimpleModal>
                                </div>
                                }
                            </div>
                        </div>

                        {/*{this.props.chatUserId.chatType === 'single' &&*/}

                        <GetChatMedia
                            listChatmedia={this.props.listChatmedia}


                        />
                        {/*}*/}






                    </div>
                    :
                    <MessageInitialScreen/>
                }

                {this.state.task &&
                <Modal title={'Create New Task'} Close ={()=>this.createTak("","")} >
                    <AddToDoTask
                        type={'mainchat'}
                        subject={atob(this.state.msg) + "  -  " + this.state.wTo}
                        createdfrom={'mainchat'}
                    />

                </Modal>
                }


                {this.state.cTask &&
                <Modal title={'Create New Task'} Close ={()=>this.setState({cTask:false})}>
                    <AddToDoTask
                        projectList={this.state.projectList}
                        tagList={this.state.tagList}
                        selectedChats = {this.state.selectedChats}
                        createdfrom = {`singlemainchat`}
                      //  leadId = {this.state.selectedLeadId}
                       // showCalendar = {true}
                        // programId={this.state.selectedInterestValues.length >0 ? selectedInterestValues[0]: ''}
                        //  leadPhoneNumber ={this.state.selected_phone_no}
                        whatsappChannelId = {this.props.chatUserId.ReceiverUserid}
                        // eventId = {}
                        // modalOpen = {this.modalOpen}
                    />

                </Modal>
                }



                {this.state.modalresources &&
                <ChatResourceList
                    modalresources={this.state.modalresources}
                    openResourceModal ={this.openResourcesModal}/>
                }
            </React.Fragment>
        );
    }
    setListRef = ref => {
        this.listRef = ref;
    };
}
const mapStateToProps = state => ({
    allusers: state.ChatReducer.listallusers,
    allgroups: state.ChatReducer.listallgroups,
    chathistory: state.ChatReducer.listchathistory,
    chatUserId: state.ChatReducer.chatUserId,
    creategroupdata: state.ChatReducer.createGroupData,
    savechatdata: state.ChatReducer.savechatdata,
    savegoupchat: state.ChatReducer.saveGroupChat,
    groupdelete: state.ChatReducer.GroupDelete,
    groupinfo: state.ChatReducer.GroupInfo,
    mobToggle: state.CommonReducer.mobileTogle,
    removeusergroupUpdation:state.ChatReducer.RemoveUserFromGroup,
    loader: state.CommonReducer.loader,
    MembersStatus: state.ChatReducer.MembersStatus,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    listentityDetails: state.ChatReducer.listlinkEntityDetails,
    listgroupsEntity: state.ChatReducer.listlinkgroupsEntity,
    listChatmedia:state.ChatReducer.listChatmedia,
    updateMediaStatus:state.ChatReducer.updateMediaStatus
});
export default withRouter(connect(mapStateToProps, {
    allUsers: rootActions.chatAction.ListAllUsers,
    allGroups: rootActions.chatAction.listAllGroups,
    chatHistory:rootActions.chatAction.listChatHistory,
    loadMorechatHistory:rootActions.chatAction.updateChatHistory,
    saveChat: rootActions.chatAction.saveChatData,
    groupsaveChat: rootActions.chatAction.saveGroupChatData,
    mainClick: rootActions.commonActions.MenuClick,
    chatReset: rootActions.commonActions.chatReset,
    sessionvalidate: rootActions.commonActions.SessionValidate,
    chatUsers: rootActions.chatAction.chatUsers,
    updatereceiverpeer: rootActions.chatAction.updateReceiverpeer,
    groupInfo: rootActions.chatAction.groupInfo,
    replyWmess:rootActions.chatAction.replyWmess,
    saveWhatsapp:rootActions.chatAction.saveWhatsapp,
    listuserEntityDetails: rootActions.chatAction.linkEntityDetails,
    listuserGroupEntity: rootActions.chatAction.linkgroupsEntity,
    getChatmedia:rootActions.chatAction.getChatmedia,
    chatmediaStatus:rootActions.chatAction.updateMediaStatus
})(MainChat));

