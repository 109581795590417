import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {getProfile} from "../../../utilities/AuthService";
import OutsideAlerter from "../../views/OutsideAlerter";
import {FRMSVID_PATH, FRMSDOC_PATH, FRMSIMG_PATH,} from "../../../constants"
import {getFileformat, isAudio, isImage, getUrl, isVideo, checkUrl} from "../../../utilities/CustomFunctions";
import {Row, Col} from "react-bootstrap";
import placeholder from "../images/zynapse-placeholder.png";
import {Card} from "react-bootstrap";

function TemplateLongCard(Props) {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const container = document.querySelector('.card-container');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        var scrollY = 0;
        var scrollX = 0;
        var doc = document.getElementById("a");
        if (doc.scrollTop !== scrollY) {
            const container = document.querySelector('.card-container')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            if (contentHeight <= scrollTop) {
                Props.loadmoreCollectionData()
            }
            ;
        }
        setIsFetching(true);
    }

    function fetchMoreListItems() {
        Props.loadmoreCollectionData()
    }

    let showlistField = []
    if (Props.label.showformdata.length !== 0) {
        for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
            (Props.label.showformdata.pages[i].components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden")).map(list => {
                showlistField.push(list)

            })
        }
    }
    let viewtemplate = Props.default_template && Props.default_template === 'long1' ? Props.long1template : Props.minitemplate

    console.log(viewtemplate)
    //console.log(Props.collection.CollectionData)
    let perStat = ((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].addedit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()))

    function parseResourceValue(value) {
        // console.log(value)
        let rows = JSON.parse(value);
        // console.log(rows)
        let resourceValue = '';
        if (rows.length > 0) {
            resourceValue = <div className="att-file"><img src={FRMSIMG_PATH + rows[0].thumb} alt={`resources`}/></div>;
        }
        return resourceValue;
    }

    return (
        <div
            className={`card-container grid-view template-collection long-card-view ${Props.long1Image ? 'with-img-card' : 'without-img-card'}`}
            id="a">
            <Row>
                {Props.collection.CollectionData.map((row, rowIndex) =>
                    <React.Fragment key={`template-1-outer` + rowIndex}>

                        {/*<div class="ind-collection-card">*/}
                        {/*    <figure class="mini-card-figure">*/}
                        {/*        <img src={placeholder} />*/}
                        {/*    </figure>*/}
                        {/*    <div class="mini-card-details">*/}
                        {/*        <h5>Map Name here</h5>*/}
                        {/*        <p>lorem ipsum</p>*/}
                        {/*        <p>lorem ipsum</p>*/}
                        {/*        <p>lorem ipsum</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <Col className="col" lg={12} md={6} sm={12}>
                            {viewtemplate.map((list, index) =>
                                <>
                                    <div key={`template-1` + index}
                                         className={Props.collection.flaglist.length === 0 ? "ind-collection-card" : 'ind-collection-card'}
                                         onDoubleClick={(e) => Props.showPreviewFormModal(e, row['id'], rowIndex)}>
                                        {Props.long1Image &&
                                        <figure className="long-card-figure">
                                            <AttachmentBox
                                                filename={row[list.item1.masterid]}
                                                type={list.item1.type}
                                            />
                                        </figure>
                                        }


                                        <div className="h-card-details">


                                            <div className="select-chk-block" style={{"display": "block"}}>
                                                <label className="control control-checkbox">
                                                    <input type="checkbox" className="inp-chk"
                                                           checked={(Props.state.checkedRow.indexOf(row["id"]) > -1) ? "checked" : ""}
                                                           data-val={row['id']}
                                                           onClick={(e) => Props.handleSelectRow(row['id'])}/>
                                                    <div className="control-indicator"></div>
                                                </label>
                                            </div>

                                            <div className="form-option-block">
                                                {((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].addedit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                                                <button className="ico-lib opt-form-ico" style={{"z-index": "2"}}
                                                        onClick={(e) => Props.showOption(e, row['id'])}></button>
                                                }
                                                {Props.showtemplateOptions && (Props.collectededitid === row['id']) &&
                                                <OutsideAlerter
                                                    status={Props.showtemplateOptions}
                                                    Triggered={() => Props.showOption(!Props.showtemplateOptions)}
                                                >
                                                    <ul className="panel-option" id="option-list">
                                                        <li>
                                                            <nav
                                                                className="panel-option-click ico-lib form-edit-ico"
                                                                onClick={(e) => Props.showFormModal(e, row['id'])}>Edit
                                                            </nav>
                                                        </li>
                                                    </ul>
                                                </OutsideAlerter>
                                                }
                                            </div>


                                            {!Props.long1Image &&

                                            <div className="each-map-field">
                                                <h5>{row[list.item1.masterid]}</h5>
                                                <p>{row[list.item2.masterid]}
                                                </p>

                                            </div>
                                            }
                                            {Props.long1Image &&

                                            <div className="each-map-field">
                                                <h5>{row[list.item2.masterid]}</h5>
                                                <p>{row[list.item3.masterid]}
                                                </p>

                                            </div>
                                            }
                                        </div>


                                        {/*<div className="mini-card-details">*/}

                                        {/*    <>*/}
                                        {/*        <div class="select-chk-block" style={{"display": "block"}}>*/}
                                        {/*            <label class="control control-checkbox">*/}
                                        {/*                <input type="checkbox" className="inp-chk"*/}
                                        {/*                       checked={(Props.state.checkedRow.indexOf(row["id"]) > -1) ? "checked" : ""}*/}
                                        {/*                       data-val={row['id']}*/}
                                        {/*                       onClick={(e) => Props.handleSelectRow(row['id'])}/>*/}
                                        {/*                <div class="control-indicator"></div>*/}
                                        {/*            </label>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="form-option-block">*/}
                                        {/*            {((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].addedit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&*/}
                                        {/*            <button className="ico-lib opt-form-ico" style={{"z-index": "2"}}*/}
                                        {/*                    onClick={(e) => Props.showOption(e, row['id'])}></button>*/}
                                        {/*            }*/}
                                        {/*            {Props.showtemplateOptions && (Props.collectededitid === row['id']) &&*/}
                                        {/*            <OutsideAlerter*/}
                                        {/*                status={Props.showtemplateOptions}*/}
                                        {/*                Triggered={() => Props.showOption(!Props.showtemplateOptions)}*/}
                                        {/*            >*/}
                                        {/*                <ul className="panel-option" id="option-list">*/}
                                        {/*                    <li>*/}
                                        {/*                        <nav*/}
                                        {/*                            className="panel-option-click ico-lib form-edit-ico"*/}
                                        {/*                            onClick={(e) => Props.showFormModal(e, row['id'])}>Edit*/}
                                        {/*                        </nav>*/}
                                        {/*                    </li>*/}
                                        {/*                </ul>*/}
                                        {/*            </OutsideAlerter>*/}
                                        {/*            }*/}
                                        {/*        </div>*/}
                                        {/*        {!Props.miniImage && !Props.miniMapenabled &&*/}
                                        {/*        <div class="each-map-field">*/}
                                        {/*            <p>{list.item1.label}</p>*/}
                                        {/*            <h5>{list.item1.type === "datepicker" && row[list.item1.masterid] !== "" ?*/}
                                        {/*                moment(row[list.item1.masterid]).format("MMMM DD, YYYY") :*/}
                                        {/*                row[list.item1.masterid]}*/}
                                        {/*            </h5>*/}

                                        {/*        </div>*/}
                                        {/*        }*/}

                                        {/*        {!Props.miniImage && Props.miniMapenabled && list.hasOwnProperty("item10") &&*/}
                                        {/*        <>*/}
                                        {/*            {list.item13.masterid === "static" ?*/}

                                        {/*                <GoogleStaticMap*/}
                                        {/*                    lat={row[list.item10.masterid]}*/}
                                        {/*                    lng={row[list.item11.masterid]}*/}
                                        {/*                    zoom={list.item12.masterid}*/}
                                        {/*                    type={`mini`}*/}
                                        {/*                />*/}
                                        {/*                :*/}
                                        {/*                <GoogleMap*/}
                                        {/*                    lat={row[list.item10.masterid]}*/}
                                        {/*                    lng={row[list.item11.masterid]}*/}
                                        {/*                    zoom={list.item12.masterid}*/}
                                        {/*                    type={`vertical`}*/}
                                        {/*                />*/}

                                        {/*            }*/}


                                        {/*        </>*/}
                                        {/*        }*/}


                                        {/*    </>*/}

                                        {/*    <>*/}
                                        {/*        {!Props.miniImage && Props.miniMapenabled &&*/}
                                        {/*        <div class="each-map-field">*/}
                                        {/*            <p>{list.item1.label}</p>*/}
                                        {/*            <h5>{list.item1.type === "datepicker" && row[list.item1.masterid] !== "" ? moment(row[list.item1.masterid]).format("Do MMM, YYYY") :*/}
                                        {/*                list.item1.type === "resources" && row[list.item1.masterid] !== "" ?*/}
                                        {/*                    parseResourceValue(row[list.item1.masterid]) :*/}
                                        {/*                    row[list.item1.masterid]}</h5>*/}
                                        {/*        </div>*/}
                                        {/*        }*/}
                                        {/*        {list.item2 && list.item2.label !== "" &&*/}
                                        {/*        <div class="each-map-field">*/}
                                        {/*            <p>{list.item2.label}</p>*/}
                                        {/*            <h5>{list.item2.type === "datepicker" && row[list.item2.masterid] !== "" ? moment(row[list.item2.masterid]).format("Do MMM, YYYY") :*/}
                                        {/*                list.item2.type === "resources" && row[list.item2.masterid] !== "" ?*/}
                                        {/*                    parseResourceValue(row[list.item2.masterid]) :*/}
                                        {/*                    row[list.item2.masterid]}</h5>*/}
                                        {/*        </div>*/}
                                        {/*        }*/}
                                        {/*        {list.item3 && list.item3.label !== "" &&*/}
                                        {/*        <div class="each-map-field">*/}
                                        {/*            <p>{list.item3.label}</p>*/}
                                        {/*            <h5>{list.item3.type === "datepicker" && row[list.item3.masterid] !== "" ?*/}
                                        {/*                moment(row[list.item3.masterid]).format("MMMM DD, YYYY") :*/}
                                        {/*                list.item3.type === "resources" && row[list.item3.masterid] !== "" ?*/}
                                        {/*                    parseResourceValue(row[list.item3.masterid]) :*/}
                                        {/*                    row[list.item3.masterid]}*/}
                                        {/*            </h5>*/}

                                        {/*        </div>*/}
                                        {/*        }*/}
                                        {/*        <>*/}
                                        {/*            {list.item4 && list.item4.label !== "" &&*/}
                                        {/*            <div class="each-map-field">*/}
                                        {/*                <p>{list.item4.label}</p>*/}
                                        {/*                <h5>{list.item4.type === "datepicker" && row[list.item4.masterid] !== "" ?*/}
                                        {/*                    moment(row[list.item4.masterid]).format("MMMM DD, YYYY") :*/}
                                        {/*                    list.item4.type === "resources" && row[list.item4.masterid] !== "" ?*/}
                                        {/*                        parseResourceValue(row[list.item4.masterid]) :*/}
                                        {/*                        row[list.item4.masterid]}*/}
                                        {/*                </h5>*/}
                                        {/*            </div>*/}
                                        {/*            }*/}

                                        {/*            {list.item5 && list.item5.label !== "" &&*/}
                                        {/*            <div class="each-map-field">*/}
                                        {/*                <p>{list.item5.label}</p>*/}
                                        {/*                <h5>{list.item5.type === "datepicker" && row[list.item5.masterid] !== "" ?*/}
                                        {/*                    moment(row[list.item5.masterid]).format("MMMM DD, YYYY") :*/}
                                        {/*                    list.item5.type === "resources" && row[list.item5.masterid] !== "" ?*/}
                                        {/*                        parseResourceValue(row[list.item5.masterid]) :*/}
                                        {/*                        row[list.item5.masterid]}*/}
                                        {/*                </h5>*/}
                                        {/*            </div>*/}
                                        {/*            }*/}

                                        {/*            /!*{list.item6.label !== "" &&*!/*/}
                                        {/*            /!*<>*!/*/}
                                        {/*            /!*    <p>{list.item6.label}</p>*!/*/}
                                        {/*            /!*    <h5>{list.item6.type === "datepicker" && row[list.item6.masterid] !=="" ?*!/*/}
                                        {/*            /!*        moment(row[list.item6.masterid]).format("MMMM DD, YYYY") :*!/*/}
                                        {/*            /!*        row[list.item6.masterid]}*!/*/}
                                        {/*            /!*    </h5>*!/*/}
                                        {/*            /!*</>*!/*/}
                                        {/*            /!*}*!/*/}
                                        {/*            /!*{list.item7.label !== "" &&*!/*/}
                                        {/*            /!*<>*!/*/}
                                        {/*            /!*    <p>{list.item7.label}</p>*!/*/}
                                        {/*            /!*    <h5>{list.item7.type === "datepicker" && row[list.item7.masterid] !==""?*!/*/}
                                        {/*            /!*        moment(row[list.item7.masterid]).format("MMMM DD, YYYY") :*!/*/}
                                        {/*            /!*        row[list.item7.masterid]}*!/*/}
                                        {/*            /!*    </h5>*!/*/}

                                        {/*            /!*</>*!/*/}
                                        {/*            /!*}*!/*/}
                                        {/*            /!*{list.item8.label !== "" &&*!/*/}
                                        {/*            /!*<>*!/*/}
                                        {/*            /!*    <p>{list.item8.label}</p>*!/*/}
                                        {/*            /!*    <h5>{list.item8.type === "datepicker" && row[list.item8.masterid] !=="" ?*!/*/}
                                        {/*            /!*        moment(row[list.item8.masterid]).format("MMMM DD, YYYY") :*!/*/}
                                        {/*            /!*        row[list.item8.masterid]}*!/*/}
                                        {/*            /!*    </h5>*!/*/}
                                        {/*            /!*</>*!/*/}
                                        {/*            /!*}*!/*/}
                                        {/*            /!*{list.item9.label !== "" &&*!/*/}
                                        {/*            /!*<>*!/*/}
                                        {/*            /!*    <p>{list.item9.label}</p>*!/*/}
                                        {/*            /!*    <h5>{list.item9.type === "datepicker" && row[list.item9.masterid] !=="" ?*!/*/}
                                        {/*            /!*        moment(row[list.item9.masterid]).format("MMMM DD, YYYY") :*!/*/}
                                        {/*            /!*        row[list.item9.masterid]}*!/*/}
                                        {/*            /!*    </h5>*!/*/}
                                        {/*            /!*</>*!/*/}
                                        {/*            /!*}*!/*/}
                                        {/*        </>*/}
                                        {/*    </>*/}
                                        {/*</div>*/}
                                    </div>

                                </>
                            )}

                        </Col>
                    </React.Fragment>
                )}
            </Row>
        </div>
    )

}


function TemplateHCardPreview(Props) {
    let viewtemplate = Props.templatecontent
    console.log(viewtemplate)
    let showlistField = []
    if (Props.label.showformdata.length !== 0) {
        for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
            (Props.label.showformdata.pages[i].components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden")).map(list => {
                showlistField.push(list)

            })
        }
    }

    function parseResourceValue(value) {
        let rows = JSON.parse(value);
        let resourceValue = '';
        if (rows.length > 0) {
            resourceValue = <div className="att-file"><img src={FRMSIMG_PATH + rows[0].thumb} alt={`resources`}/></div>;
        }
        return resourceValue;
    }

    // console.log(113)
    console.log(Props.collection.CollectionData)
    console.log(viewtemplate)
    return (
        <div
            className={`card-container grid-view template-collection  h-card ${Props.miniImage ? '' : ` without-img-card `} `}
            id="a">
            {Props.collection.CollectionData.slice(0, 1).map((row, rowIndex) =>
                <React.Fragment>
                    {viewtemplate.map((list, index) =>
                        <>
                            <div className="ind-collection-card">
                                <figure className="h-card-figure">
                                    {Props.long1Image &&
                                    <AttachmentBox
                                        filename={row[list.item1.masterid]}
                                        type={list.item1.type}
                                    />
                                    }
                                </figure>
                                <div className="h-card-details">

                                    <div className="each-map-field">
                                        <h5>{row[list.item1.masterid]}</h5>
                                        <p>{row[list.item2.masterid]}
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </>
                    )}


                </React.Fragment>
            )}
        </div>
    )

}

function AttachmentBox(Props) {

    return (
        <React.Fragment>
            {Props.type === "imageurl" && Props.filename !== undefined ?
                <>
                    <img src={Props.filename}/>
                </>
                :
                <React.Fragment>
                    {isImage(Props.filename) &&
                    <>

                        <img src={FRMSIMG_PATH + Props.filename}/>
                    </>
                    }

                    {Props.filename !== "" && Props.filename !== undefined && !isAudio(Props.filename) && !isVideo(Props.filename) && !isImage(Props.filename) &&
                    <div className={`collection-card-image ${getFileformat(Props.filename).toLowerCase()}`}
                         style={{"max-width": "100%"}}>
                        <span
                            className={`format ${getFileformat(Props.filename).toLowerCase()}`}> {getFileformat(Props.filename).toLowerCase()} </span>
                    </div>
                    }

                </React.Fragment>

            }
        </React.Fragment>
    )

}

export {TemplateLongCard, TemplateHCardPreview};

