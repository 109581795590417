import React from "react";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getProfile} from "../../../../utilities/AuthService";
import axios from "axios";
import {ResultTable} from "../ResultTable";
import FullPageModal from "../../../views/FullPageModal"
import {FormGroup} from "react-bootstrap";
import SimpleModal from "../../../views/SimpleModal";
import {DragDropContext, Draggable as Drags, Droppable} from "react-beautiful-dnd";
import ColorPicker from "../views/ColorPicker";
import {getUrlSegment} from "../../../../utilities/CustomFunctions";
import OwnFilter from "./OwnFilter";

class ViewData extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collectionData: [],
            from: 0,
            size: 300,
            graphStatus: true,
            viewStatus: false,
        }
    }

    CollectionData = () => {
        let obj = this
        let from = parseInt(obj.state.from) + parseInt(obj.state.collectionData.length)
        let sharetype = getUrlSegment(1);
        let uid = '';
        let usertype = '';
        if (sharetype === 'public' || sharetype === 'state') {
            uid = 2;
            usertype = 'root';
        } else {
            uid = getProfile().id;
            usertype = getProfile().usertype;
        }
        let param = {
            from: from,
            size: obj.state.size,
            index: ES_INDEX,
            search: '',
            "uid": uid,
            "usertype": usertype,
            "dashboard": false
        }
        if ((parseInt(this.state.totalchilds - 1) >= parseInt(this.state.collectionData.length) || from === 0)) {
            axios({
                method: "POST",
                url: API_PATH + "collection/" + this.props.cid + "/collectedinfo?&index=" + ES_INDEX,
                async: false,
                data: param,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                let results = (res.data.results)
                var totalchilds = results.totalchilds
                var collectionData = [];
                var collectionIDs = [];
                var collectionFilter = [];
                var cnt = 0;
                if (results.data.length > 0) {
                    results.data.map((entry, index) => {
                        collectionData[index] = {};
                        collectionData[index]['id'] = entry.id;
                        collectionData[index]['users'] = entry.users;
                        collectionIDs.push(entry.id);
                        if (entry.collectedform) {
                            entry.collectedform.map(a => {
                                //.filter((a) => a.showlist == 'Yes')
                                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['value'] : '';
                                if (a.type === "attachment") {
                                    collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                                    collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                                }

                                if (a.searchable === 'Yes') {
                                    if (collectionFilter[a.label] === undefined) {
                                        collectionFilter[a.label] = [];
                                    }
                                    cnt = collectionFilter[a.label].length;
                                    collectionFilter[a.label][cnt] = a.value[0]['value'];
                                }
                            });
                        }
                    });
                }
                if (from === 0) {
                    obj.setState({collectionData: collectionData, totalchilds: totalchilds});
                } else {
                    collectionData = obj.state.collectionData.concat(collectionData)
                    //////console.log(collectionData)
                    obj.setState({collectionData: collectionData});
                }

                return res;
            }).catch(function (err) {
                return err;
            });
        }


    }
    ToggleGraph = () => {
        this.setState({graphStatus: !this.state.graphStatus})
    }
    settingStatus = () => {
        this.setState({viewStatus: !this.state.viewStatus})
        if (!this.state.viewStatus) {
            this.CollectionData()

        }
    }


    render() {

        let collectionData = this.state.collectionData
        let componentData = this.props.containerData;
        return (
            <div className="blue-lister">
                <div className="grid-header with-desc-box">
                    <div className={`page-block-header`}>
                        <h5>{componentData.chartTitle}</h5>
                        {componentData.subTitleEnabled &&
                        <p>{componentData.subTitle}</p>
                        }
                    </div>
                    {componentData.hasOwnProperty(`ownFilter`) && componentData.ownFilter &&
                    <div className={`select-box max-200`}>
                        <OwnFilter
                            selectFilter={componentData.selectFilter}
                            collections={componentData.collection}
                            ownFilterList={componentData.ownFilterList}
                            ownid={this.props.state.ownid}
                        />

                    </div>
                    }

                    {(this.props.linkedId === "" || this.props.linkedId === undefined) &&
                    <button className="button-anchor" onClick={() => this.settingStatus()}>
                        <span className="icon-in icon-settings button-settings-icon"> </span>
                    </button>
                    }


                </div>
                <div className="blue-list-body">
                    <div className="graph-wrap">
                        {this.props.chart}
                    </div>
                </div>
                {this.state.viewStatus &&
                <FullPageModal
                    title={componentData.chartTitle}
                    subTitleEnabled={componentData.subTitleEnabled}
                    subTitle={componentData.subTitle}
                    Close={this.settingStatus}
                    otherAction={this.ToggleGraph}
                    otherActionClass={`ico-lib toggle-button`}
                >
                    <div className="chart-container">
                        <div className="chart-holder">
                            {this.state.graphStatus &&
                            this.props.chart
                            }
                        </div>

                        <ResultTable
                            showlistField={componentData.showlistField}
                            collection={collectionData}
                            handleSelectAll={this.props.handleSelectAll}
                            showPreviewFormModal={this.props.showPreviewFormModal}
                            state={this.props.state}
                            handleSelectRow={this.props.handleSelectRow}
                            showAssignedUsers={this.props.showAssignedUsers}
                            loadmoreCollectionData={this.CollectionData}
                            showFormModal={this.props.showFormModal}
                            key={`result-table`}
                        />
                    </div>
                </FullPageModal>
                }
            </div>
        )
    }

}

class SettingsPanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let id = this.props.id
        let dropzoneID = this.props.dropzoneID
        let parentID = this.props.dropzoneID
        return (
            <FormGroup className="m-0">
                <label className="col-sm-12">
                    <span>{this.props.name}</span>
                    <div className="chart-icons">
                        <div className="settings-icon"
                             onClick={() => this.props.openSetting(id, dropzoneID, parentID)}></div>
                        {this.props.removeElement}
                    </div>
                </label>
            </FormGroup>
        )
    }

}

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#fff",
    // styles we need to apply on draggables
    ...draggableStyle
});

class DashboardSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sColor: false
        }
    }

    colorChange = () => {
        this.setState({sColor: !this.state.sColor})
    }


    render() {
        let collections = this.props.containerData.collectionData
        let collection = this.props.containerData.collection
        let enableyaxis = this.props.containerData.enableyaxis
        let legend = this.props.containerData.legend
        let alldata = this.props.containerData.alldata
        let tabledata = this.props.containerData.tabledata
        let showlistField = this.props.containerData.showlistField
        let condition = this.props.containerData.condition
        let enablegradient = this.props.containerData.enablegradient
        let hidearea = this.props.containerData.hidearea
        let sourceType = this.props.containerData.sourceType
        let whereCondition = this.props.containerData.whereCondition
        //console.log(this.props.containerData)
        return (
            <React.Fragment>
                {this.props.modalType === 1 &&
                <SimpleModal
                    Close={this.props.Close}
                    title={this.props.title}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.props.OKAction}
                    asignedUsershow={false}
                    cancelLabel="Cancel"
                    cancelAction={this.props.cancelAction}

                >
                    <div className="DashBuilder-modal">
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                                       autoComplete="off" value={this.props.chartTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        <div className="task-input-container container-flex">
                            <div className="new-task-input">
                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle"
                                       autoComplete="off" value={this.props.subTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Subtitle
                                    <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`}
                                           checked={this.props.subTitleEnabled} value={this.props.subTitleEnabled}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                        </div>
                        <div className="task-input-container">
                            <label>Collections:</label>
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="collection"
                                        onChange={(e) => this.props.handleInputChange(e)} value={collection}>

                                    <option value="">Collections</option>
                                    {collections.map((list, index) =>
                                        <option key={`multi-area-chart-${index}`}
                                                value={list._id}>{atob(list.title)}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="Unit" id="unit" name="unit" autoComplete="off"
                                       value={this.props.unit}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        <div className="task-input-container check">

                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Y Axis
                                    <input className="inp-chk" type={`checkbox`} name={`enableyaxis`}
                                           checked={enableyaxis} value={enableyaxis}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Legend
                                    <input className="inp-chk" type={`checkbox`} name={`legend`} checked={legend}
                                           value={legend} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">All Data
                                    <input className="inp-chk" type={`checkbox`} name={`alldata`} checked={alldata}
                                           value={alldata} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>


                        </div>


                        <div className="main-container-wrap">

                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X width" id="xwidth" name="xwidth"
                                           autoComplete="off"
                                           value={this.props.xwidth}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>

                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X Height" id="xheight" name="xheight"
                                           autoComplete="off" value={this.props.xheight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Width" id="ywidth" name="ywidth"
                                           autoComplete="off" value={this.props.ywidth}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Height" id="yheight" name="yheight"
                                           autoComplete="off" value={this.props.yheight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Graph Height" id="graphHeight" name="graphHeight"
                                           autoComplete="off" value={this.props.graphHeight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X Angle" id="xAngle" name="xAngle"
                                           autoComplete="off" value={this.props.xAngle}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Angle" id="yAngle" name="yAngle"
                                           autoComplete="off" value={this.props.yAngle}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>

                        </div>


                        {this.props.hasOwnProperty("layoutStatus") &&
                        <div className="task-input-container">
                            <label>Layout:</label>
                            <div className="select-box eq-width">
                                <select id="layout"
                                        name="layout"
                                        onChange={(e) => this.props.handleChange(e)}
                                        value={this.props.layout}>
                                    <option value="">none</option>
                                    <option value={"horizontal"}>horizontal</option>
                                    <option value={"vertical"}>vertical</option>
                                </select>
                            </div>
                        </div>
                        }


                        <div className="graph-proprties">

                            <div className="graph-proprties-color modal-panel-container">
                                <div className="task-input-container">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(droppableProvided, droppableSnapshot) => (
                                                <div
                                                    ref={droppableProvided.innerRef}
                                                >
                                                    {tabledata.map((item, index) => (
                                                        <Drags key={item.id} draggableId={item.id} index={index}
                                                               isDragDisabled={this.props.colorpickerStatus}>
                                                            {(draggableProvided, draggableSnapshot) => (
                                                                <div className={'panel-each'}
                                                                     ref={draggableProvided.innerRef}
                                                                     {...draggableProvided.draggableProps}
                                                                     {...draggableProvided.dragHandleProps}
                                                                     style={getItemStyle(
                                                                         draggableSnapshot.isDragging,
                                                                         draggableProvided.draggableProps.style
                                                                     )}
                                                                     isDragDisabled={this.props.colorpickerStatus}
                                                                >
                                                                    <div className={`settings-panel`}
                                                                         style={{"width": "100%"}}>
                                                                        <input type={`text`} name="label"
                                                                               defaultValue={item.label}
                                                                               onChange={(e) => this.props.handleLabelChange(e, item.id)}/>

                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="field"
                                                                                    onChange={(e) => this.props.handleLabelChange(e, item.id)}
                                                                                    value={item.field}>
                                                                                <option value="">Y axis</option>
                                                                                {showlistField.map((label, index) =>
                                                                                    <option
                                                                                        key={`multi-area-chart-masterid` + index}
                                                                                        value={label.masterid}>{label.label}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        <div className="task-input-container">
                                                                            <label>{item.areaLabel}:</label>
                                                                        </div>

                                                                        <ColorPicker
                                                                            stroke={item.id}
                                                                            color={item.color}
                                                                            onChangeComplete={this.props.handleColorChange}
                                                                            handleClickStaus={this.props.handleClick}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Drags>
                                                    ))}
                                                    {droppableProvided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>


                            </div>
                        </div>
                        <div className="task-input-container"><label>Conditions:</label>
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="field"
                                        onChange={(e) => this.props.handleConditionChange(e)}
                                        value={condition && condition[0].field + "|" + (condition[0].type === "date" ? "datepicker" : condition[0].type)}>


                                    <option value="|">Conditional Parameter</option>
                                    {showlistField.filter(item => (item.type === "number" || item.type === "datepicker")).map((label, index) =>
                                        <option key={`multi-area-chart-masterid` + index}
                                                value={label.masterid + "|" + label.type}>{label.label}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="task-input-container"><label>Sort:</label>
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="sort"
                                        onChange={(e) => this.props.handleConditionChange(e)}
                                        value={condition && condition[0].sort}>

                                    <option value="">Sort</option>
                                    <option value="asc">ASC</option>
                                    <option value="desc">DESC</option>

                                </select>
                            </div>
                        </div>

                    </div>

                </SimpleModal>
                }

                {this.props.modaltype === 2 &&
                <SimpleModal
                    Close={this.props.Close}
                    title={this.props.title}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.props.OKAction}
                    asignedUsershow={false}
                    cancelLabel="Cancel"
                    cancelAction={this.props.cancelAction}

                >
                    <div className="DashBuilder-modal">
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                                       autoComplete="off" value={this.props.chartTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                        </div>
                        <div className="task-input-container container-flex">
                            <div className="new-task-input">
                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle"
                                       autoComplete="off" value={this.props.subTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Subtitle
                                    <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`}
                                           checked={this.props.subTitleEnabled} value={this.props.subTitleEnabled}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                        </div>
                        <div className="task-input-container">
                            <label>Collections:</label>
                            <div className="select-box eq-width">
                                <select id="priority" name="collection"
                                        onChange={(e) => this.props.handleInputChange(e)} value={collection}>

                                    <option value="">Option 1</option>
                                    {collections.map((list, index) =>
                                        <option value={list._id}>{atob(list.title)}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="graph-proprties">
                            <div className="graph-proprties-color modal-panel-container">
                                <div className="task-input-container">
                                    <DragDropContext onDragEnd={this.props.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(droppableProvided, droppableSnapshot) => (
                                                <div
                                                    ref={droppableProvided.innerRef}
                                                    // style={getListStyle(droppableSnapshot.isDraggingOver)}
                                                >
                                                    {tabledata.map((item, index) => (
                                                        <Drags key={item.id} draggableId={item.id} index={index}
                                                               isDragDisabled={this.props.colorpickerStatus}>
                                                            {(draggableProvided, draggableSnapshot) => (
                                                                <div onDrag={false} className={'panel-each'}
                                                                     ref={draggableProvided.innerRef}
                                                                     {...draggableProvided.draggableProps}
                                                                     {...draggableProvided.dragHandleProps}
                                                                     style={getItemStyle(
                                                                         draggableSnapshot.isDragging,
                                                                         draggableProvided.draggableProps.style
                                                                     )}
                                                                     isDragDisabled={this.props.colorpickerStatus}

                                                                >
                                                                    <div className={`settings-panel`}>
                                                                        <input type={`text`} name="label"
                                                                               defaultValue={item.label}
                                                                               onChange={(e) => this.props.handleLabelChange(e, item.id)}/>

                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="field"
                                                                                    onChange={(e) => this.props.handleLabelChange(e, item.id)}
                                                                                    value={item.field}>
                                                                                <option value="">Y axis</option>
                                                                                {showlistField.map((label, index) =>
                                                                                    <option key={`masterid` + index}
                                                                                            value={label.masterid}>{label.label}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>

                                                                        <ColorPicker
                                                                            stroke={item.id}
                                                                            color={item.color}
                                                                            onChangeComplete={this.props.handleColorChange}
                                                                            handleClickStaus={this.props.handleClick}
                                                                        />

                                                                    </div>
                                                                    <div className={`settings-panel-option`}>
                                                                        <button className="ico-lib divider-add-button"
                                                                                onClick={() => this.props.addData(item.id)}></button>
                                                                        <button className="ico-lib divider-sub-button"
                                                                                onClick={() => this.props.removeData(item.id)}></button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Drags>

                                                    ))}
                                                    {droppableProvided.placeholder}
                                                </div>

                                            )}

                                        </Droppable>
                                    </DragDropContext>
                                </div>


                            </div>
                        </div>

                    </div>


                </SimpleModal>

                }
                {this.props.modaltype === 3 &&

                <SimpleModal
                    Close={this.props.Close}
                    title={this.props.title}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.props.OKAction}
                    asignedUsershow={false}
                    cancelLabel="Cancel"
                    cancelAction={this.props.cancelAction}
                >
                    <div className="DashBuilder-modal">
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                                       autoComplete="off" value={this.props.chartTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        <div className="task-input-container container-flex">
                            <div className="new-task-input">
                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle"
                                       autoComplete="off" value={this.props.subTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Subtitle
                                    <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`}
                                           checked={this.props.subTitleEnabled} value={this.props.subTitleEnabled}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                        </div>
                        <div className="task-input-container margin-b-10">
                            <label>Collections:</label>
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="collection"
                                        onChange={(e) => this.props.handleInputChange(e)} value={collection}>

                                    <option value="">Collections</option>
                                    {collections.map((list, index) =>
                                        <option key={`multi-area-chart-${index}`}
                                                value={list._id}>{atob(list.title)}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="Unit" id="unit" name="unit" autoComplete="off"
                                       value={this.props.unit}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>


                        <div className="task-input-container select-line">
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Y Axis
                                    <input className="inp-chk" type={`checkbox`} name={`enableyaxis`}
                                           checked={enableyaxis} value={enableyaxis}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Legend
                                    <input className="inp-chk" type={`checkbox`} name={`legend`} checked={legend}
                                           value={legend} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">All Data
                                    <input className="inp-chk" type={`checkbox`} name={`alldata`} checked={alldata}
                                           value={alldata} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            {this.props.hasOwnProperty("stackedStatus") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Stacked
                                    <input className="inp-chk" type={`checkbox`} name={`stacked`}
                                           checked={this.props.stacked}
                                           value={this.props.stacked} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                            }
                            {this.props.hasOwnProperty("enablegradient") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Gradient
                                    <input className="inp-chk" type={`checkbox`} name={`enablegradient`}
                                           checked={enablegradient}
                                           value={enablegradient} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            }

                            {this.props.hasOwnProperty("hidearea") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Hide Area
                                    <input className="inp-chk" type={`checkbox`} name={`hidearea`} checked={hidearea}
                                           value={hidearea} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            }
                        </div>


                        <div className="main-container-wrap">
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X width" id="xwidth" name="xwidth"
                                           autoComplete="off"
                                           value={this.props.xwidth}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>

                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X Height" id="xheight" name="xheight"
                                           autoComplete="off" value={this.props.xheight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Width" id="ywidth" name="ywidth"
                                           autoComplete="off" value={this.props.ywidth}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Height" id="yheight" name="yheight"
                                           autoComplete="off" value={this.props.yheight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Graph Height" id="graphHeight" name="graphHeight"
                                           autoComplete="off" value={this.props.graphHeight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X Angle" id="xAngle" name="xAngle"
                                           autoComplete="off" value={this.props.xAngle}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Angle" id="yAngle" name="yAngle"
                                           autoComplete="off" value={this.props.yAngle}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                        </div>
                        {this.props.hasOwnProperty("layoutStatus") &&
                        <div className="task-input-container margin-b-20">
                            <label>Layout:</label>
                            <div className="select-box eq-width">
                                <select id="layout"
                                        name="layout"
                                        onChange={(e) => this.props.handleChange(e)}
                                        value={this.props.layout}>
                                    <option value="">none</option>
                                    <option value={"horizontal"}>horizontal</option>
                                    <option value={"vertical"}>vertical</option>
                                </select>
                            </div>
                        </div>
                        }
                        <div className="graph-proprties">

                            <div className="graph-proprties-color modal-panel-container">
                                <div className="task-input-container">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(droppableProvided, droppableSnapshot) => (
                                                <div
                                                    ref={droppableProvided.innerRef}
                                                    // style={getListStyle(droppableSnapshot.isDraggingOver)}
                                                >
                                                    {tabledata.map((item, index) => (
                                                        <Drags key={item.id} draggableId={item.id} index={index}
                                                               isDragDisabled={this.props.colorpickerStatus}>
                                                            {(draggableProvided, draggableSnapshot) => (
                                                                <div className={'panel-each'}
                                                                     ref={draggableProvided.innerRef}
                                                                     {...draggableProvided.draggableProps}
                                                                     {...draggableProvided.dragHandleProps}
                                                                     style={getItemStyle(
                                                                         draggableSnapshot.isDragging,
                                                                         draggableProvided.draggableProps.style
                                                                     )}
                                                                     isDragDisabled={this.props.colorpickerStatus}
                                                                >
                                                                    <div className={`settings-panel`}>
                                                                        <input type={`text`} name="label"
                                                                               defaultValue={item.label}
                                                                               onChange={(e) => this.props.handleLabelChange(e, item.id)}/>

                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="field"
                                                                                    onChange={(e) => this.props.handleLabelChange(e, item.id)}
                                                                                    value={item.field}>
                                                                                <option value="">Y axis</option>
                                                                                {showlistField.map((label, index) =>
                                                                                    <option
                                                                                        key={`multi-area-chart-masterid` + index}
                                                                                        value={label.masterid}>{label.label}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        <ColorPicker
                                                                            stroke={item.id}
                                                                            color={item.color}
                                                                            onChangeComplete={this.props.handleColorChange}
                                                                            handleClickStaus={this.props.handleClick}
                                                                        />

                                                                    </div>
                                                                    <div className={`settings-panel-option`}>
                                                                        <button className="ico-lib divider-add-button"
                                                                                onClick={() => this.props.addData(item.id)}></button>
                                                                        <button className="ico-lib divider-sub-button"
                                                                                onClick={() => this.props.removeData(item.id)}></button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Drags>
                                                    ))}
                                                    {droppableProvided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>


                            </div>
                        </div>


                        {this.props.hasOwnProperty("conditionalParam") &&
                        <>
                            <div className="task-input-container"><label>Conditions:</label>
                                <div className="select-box eq-width">
                                    <select id="multi-area-chart-collections" name="field"
                                            onChange={(e) => this.props.handleConditionChange(e)}
                                            value={condition && condition[0].field + "|" + (condition[0].type === "date" ? "datepicker" : condition[0].type)}>

                                        <option value="|">Conditional Parameter</option>
                                        {showlistField.filter(item => (item.type === "number" || item.type === "datepicker")).map((label, index) =>
                                            <option key={`multi-area-chart-masterid` + index}
                                                    value={label.masterid + "|" + label.type}>{label.label}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="task-input-container"><label>Sort:</label>
                                <div className="select-box eq-width">
                                    <select id="multi-area-chart-collections" name="sort"
                                            onChange={(e) => this.props.handleConditionChange(e)}
                                            value={condition && condition[0].sort}>

                                        <option value="">Sort</option>
                                        <option value="asc">ASC</option>
                                        <option value="desc">DESC</option>

                                    </select>
                                </div>
                            </div>
                        </>
                        }


                    </div>


                </SimpleModal>
                }


                {this.props.modaltype === 4 &&
                <SimpleModal
                    Close={this.props.Close}
                    title={this.props.title}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.props.OKAction}
                    asignedUsershow={false}
                    cancelLabel="Cancel"
                    cancelAction={this.props.cancelAction}
                >
                    <div className="DashBuilder-modal">
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                                       autoComplete="off" value={this.props.containerData.chartTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                        </div>
                        <div className="task-input-container container-flex">
                            <div className="new-task-input">
                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle"
                                       autoComplete="off" value={this.props.containerData.subTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Subtitle
                                    <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`}
                                           checked={this.props.containerData.subTitleEnabled}
                                           value={this.props.containerData.subTitleEnabled}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                        </div>
                        <div className="task-input-container">
                            <label>Collections:</label>
                            <div className="select-box eq-width">
                                <select id="priority" name="collection"
                                        onChange={(e) => this.props.handleInputChange(e)}
                                        value={collection}>

                                    <option value="">Option 1</option>
                                    {collections.map((list, index) =>
                                        <option value={list._id}>{atob(list.title)}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="graph-proprties">
                            <div className="graph-proprties-axis">
                                <div className="task-input-container">
                                    <label>X axis:</label>
                                    <div className="select-box eq-width">
                                        <select id="xaxis" name="x" onChange={(e) => this.props.handleInputChange(e)}
                                                value={this.props.containerData.x}>
                                            <option value="">X axis</option>
                                            {showlistField.map((label, index) =>
                                                <option key={`yaxis` + index}
                                                        value={label.masterid}>{label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>


                </SimpleModal>
                }


                {this.props.modaltype === 5 &&
                <SimpleModal
                    Close={this.props.Close}
                    title={this.props.title}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.props.OKAction}
                    asignedUsershow={false}
                    cancelLabel="Cancel"
                    cancelAction={this.props.cancelAction}
                >
                    <div className="DashBuilder-modal">
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                                       autoComplete="off" value={this.props.chartTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        <div className="task-input-container container-flex">
                            <div className="new-task-input">
                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle"
                                       autoComplete="off" value={this.props.subTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Subtitle
                                    <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`}
                                           checked={this.props.subTitleEnabled} value={this.props.subTitleEnabled}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>


                        </div>
                        <div className="task-input-container">
                            <label>Collections:</label>
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="collection"
                                        onChange={(e) => this.props.handleInputChange(e)} value={collection}>

                                    <option value="">Collections</option>
                                    {collections.map((list, index) =>
                                        <option key={`multi-area-chart-${index}`}
                                                value={list._id}>{atob(list.title)}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="Unit" id="unit" name="unit" autoComplete="off"
                                       value={this.props.unit}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>

                        <div className="task-input-container">
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Y Axis
                                    <input className="inp-chk" type={`checkbox`} name={`enableyaxis`}
                                           checked={enableyaxis} value={enableyaxis}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Legend
                                    <input className="inp-chk" type={`checkbox`} name={`legend`} checked={legend}
                                           value={legend} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">All Data
                                    <input className="inp-chk" type={`checkbox`} name={`alldata`} checked={alldata}
                                           value={alldata} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                        </div>

                        <div className="graph-proprties">

                            <div className="graph-proprties-color modal-panel-container">
                                <div className="task-input-container">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(droppableProvided, droppableSnapshot) => (
                                                <div
                                                    ref={droppableProvided.innerRef}
                                                    // style={getListStyle(droppableSnapshot.isDraggingOver)}
                                                >
                                                    {tabledata.map((item, index) => (
                                                        <Drags key={item.id} draggableId={item.id} index={index}
                                                               isDragDisabled={this.props.colorpickerStatus}>
                                                            {(draggableProvided, draggableSnapshot) => (
                                                                <div className={'panel-each'}
                                                                     ref={draggableProvided.innerRef}
                                                                     {...draggableProvided.draggableProps}
                                                                     {...draggableProvided.dragHandleProps}
                                                                     style={getItemStyle(
                                                                         draggableSnapshot.isDragging,
                                                                         draggableProvided.draggableProps.style
                                                                     )}
                                                                     isDragDisabled={this.props.colorpickerStatus}
                                                                >
                                                                    <div className={`settings-panel`}>
                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="masterid"
                                                                                    onChange={(e) => this.props.handleLabelChange(e, item.id)}
                                                                                    value={item.masterid}>
                                                                                <option value="">Y axis</option>
                                                                                {showlistField.map((label, index) =>
                                                                                    <option
                                                                                        key={`multi-area-chart-masterid` + index}
                                                                                        value={label.masterid}>{label.label}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>


                                                                    </div>
                                                                    <div className={`settings-panel-option`}>
                                                                        <button className="ico-lib divider-add-button"
                                                                                onClick={() => this.props.addData(item.id)}></button>
                                                                        <button className="ico-lib divider-sub-button"
                                                                                onClick={() => this.props.removeData(item.id)}></button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Drags>
                                                    ))}
                                                    {droppableProvided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>


                            </div>
                        </div>

                    </div>


                </SimpleModal>
                }

                {this.props.modaltype === 6 &&
                <SimpleModal
                    Close={this.props.Close}
                    title={this.props.title}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.props.OKAction}
                    asignedUsershow={false}
                    cancelLabel="Cancel"
                    cancelAction={this.props.cancelAction}
                >
                    <div className="DashBuilder-modal">
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                                       autoComplete="off" value={this.props.chartTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        <div className="task-input-container container-flex">
                            <div className="new-task-input">
                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle"
                                       autoComplete="off" value={this.props.subTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Subtitle
                                    <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`}
                                           checked={this.props.subTitleEnabled} value={this.props.subTitleEnabled}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                        </div>
                        <div className="task-input-container margin-b-10">
                            <label>Collections:</label>
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="collection"
                                        onChange={(e) => this.props.handleInputChange(e)} value={collection}>

                                    <option value="">Collections</option>
                                    {collections.map((list, index) =>
                                        <option key={`multi-area-chart-${index}`}
                                                value={list._id}>{atob(list.title)}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="Unit" id="unit" name="unit" autoComplete="off"
                                       value={this.props.unit}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>


                        <div className="task-input-container select-line">
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Y Axis
                                    <input className="inp-chk" type={`checkbox`} name={`enableyaxis`}
                                           checked={enableyaxis} value={enableyaxis}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Legend
                                    <input className="inp-chk" type={`checkbox`} name={`legend`} checked={legend}
                                           value={legend} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">All Data
                                    <input className="inp-chk" type={`checkbox`} name={`alldata`} checked={alldata}
                                           value={alldata} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            {this.props.hasOwnProperty("stackedStatus") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Stacked
                                    <input className="inp-chk" type={`checkbox`} name={`stacked`}
                                           checked={this.props.stacked}
                                           value={this.props.stacked} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                            }
                        </div>


                        <div className="main-container-wrap">

                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X width" id="xwidth" name="xwidth"
                                           autoComplete="off"
                                           value={this.props.xwidth}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>

                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X Height" id="xheight" name="xheight"
                                           autoComplete="off" value={this.props.xheight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Width" id="ywidth" name="ywidth"
                                           autoComplete="off" value={this.props.ywidth}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Height" id="yheight" name="yheight"
                                           autoComplete="off" value={this.props.yheight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Graph Height" id="graphHeight" name="graphHeight"
                                           autoComplete="off" value={this.props.graphHeight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X Angle" id="xAngle" name="xAngle"
                                           autoComplete="off" value={this.props.xAngle}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Angle" id="yAngle" name="yAngle"
                                           autoComplete="off" value={this.props.yAngle}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>

                        </div>


                        {this.props.hasOwnProperty("layoutStatus") &&
                        <div className="task-input-container margin-b-20">
                            <label>Layout:</label>
                            <div className="select-box eq-width">
                                <select id="layout"
                                        name="layout"
                                        onChange={(e) => this.props.handleChange(e)}
                                        value={this.props.layout}>
                                    <option value="">none</option>
                                    <option value={"horizontal"}>horizontal</option>
                                    <option value={"vertical"}>vertical</option>
                                </select>
                            </div>
                        </div>
                        }


                        <div className="graph-proprties">

                            <div className="graph-proprties-color modal-panel-container">
                                <div className="task-input-container">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(droppableProvided, droppableSnapshot) => (
                                                <div
                                                    ref={droppableProvided.innerRef}
                                                    // style={getListStyle(droppableSnapshot.isDraggingOver)}
                                                >
                                                    {tabledata.map((item, index) => (
                                                        <Drags key={item.id} draggableId={item.id} index={index}
                                                               isDragDisabled={this.props.colorpickerStatus}>
                                                            {(draggableProvided, draggableSnapshot) => (
                                                                <div className={'panel-each'}
                                                                     ref={draggableProvided.innerRef}
                                                                     {...draggableProvided.draggableProps}
                                                                     {...draggableProvided.dragHandleProps}
                                                                     style={getItemStyle(
                                                                         draggableSnapshot.isDragging,
                                                                         draggableProvided.draggableProps.style
                                                                     )}
                                                                     isDragDisabled={this.props.colorpickerStatus}
                                                                >
                                                                    <div className={`settings-panel`}>
                                                                        <input type={`text`} name="label"
                                                                               defaultValue={item.label}
                                                                               onChange={(e) => this.props.handleLabelChange(e, item.id)}/>

                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="field"
                                                                                    onChange={(e) => this.props.handleLabelChange(e, item.id)}
                                                                                    value={item.field}>
                                                                                <option value="">Y axis</option>
                                                                                {showlistField.map((label, index) =>
                                                                                    <option
                                                                                        key={`multi-area-chart-masterid` + index}
                                                                                        value={label.masterid}>{label.label}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        <ColorPicker
                                                                            stroke={item.id}
                                                                            color={item.color}
                                                                            onChangeComplete={this.props.handleColorChange}
                                                                            handleClickStaus={this.props.handleClick}
                                                                        />

                                                                    </div>
                                                                    <div className={`settings-panel-option`}>
                                                                        <button className="ico-lib divider-add-button"
                                                                                onClick={() => this.props.addData(item.id)}></button>
                                                                        <button className="ico-lib divider-sub-button"
                                                                                onClick={() => this.props.removeData(item.id)}></button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Drags>
                                                    ))}
                                                    {droppableProvided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>


                            </div>
                        </div>

                        <div className="task-input-container"><label>Conditions:</label>
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="field"
                                        onChange={(e) => this.props.handleConditionChange(e)}
                                        value={condition && condition[0].field + "|" + (condition[0].type === "date" ? "datepicker" : condition[0].type)}>

                                    <option value="|">Conditional Parameter</option>
                                    {showlistField.filter(item => (item.type === "number" || item.type === "datepicker")).map((label, index) =>
                                        <option key={`multi-area-chart-masterid` + index}
                                                value={label.masterid + "|" + label.type}>{label.label}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="task-input-container"><label>Sort:</label>
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="sort"
                                        onChange={(e) => this.props.handleConditionChange(e)}
                                        value={condition && condition[0].sort}>

                                    <option value="">Sort</option>
                                    <option value="asc">ASC</option>
                                    <option value="desc">DESC</option>

                                </select>
                            </div>
                        </div>

                    </div>


                </SimpleModal>
                }

                {this.props.modaltype === 7 &&
                <SimpleModal
                    Close={this.props.Close}
                    title={this.props.title}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.props.OKAction}
                    asignedUsershow={false}
                    cancelLabel="Cancel"
                    cancelAction={this.props.cancelAction}
                >
                    <div className="DashBuilder-modal">
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                                       autoComplete="off" value={this.props.chartTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        <div className="task-input-container container-flex">
                            <div className="new-task-input">
                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle"
                                       autoComplete="off" value={this.props.subTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Subtitle
                                    <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`}
                                           checked={this.props.subTitleEnabled} value={this.props.subTitleEnabled}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                        </div>


                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="Api url" id="apiUrl" name="apiUrl" autoComplete="off"
                                       value={this.props.apiUrl}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                        </div>


                    </div>


                </SimpleModal>
                }


                {this.props.modaltype === 8 &&
                <SimpleModal
                    Close={this.props.Close}
                    title={this.props.title}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.props.OKAction}
                    asignedUsershow={false}
                    cancelLabel="Cancel"
                    cancelAction={this.props.cancelAction}
                >
                    <div className="DashBuilder-modal">
                        {this.props.containerData.hasOwnProperty("chartTitle") &&
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                                       autoComplete="off" value={this.props.containerData.chartTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                        </div>
                        }

                        <div className="task-input-container container-flex">
                            {this.props.containerData.hasOwnProperty("subTitle") &&
                            <div className="new-task-input">
                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle"
                                       autoComplete="off" value={this.props.containerData.subTitle}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("subTitleEnabled") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Subtitle
                                    <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`}
                                           checked={this.props.containerData.subTitleEnabled}
                                           value={this.props.containerData.subTitleEnabled}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            }

                        </div>
                        {this.props.containerData.hasOwnProperty("sourceType") && getUrlSegment(1) !== "collections" &&
                        <>
                            <div className="task-input-container select-line">
                                <div className="select-chk-block" style={{"display": "block"}}>
                                    <label className="control control-checkbox">Collection
                                        <input className="inp-chk" type={`radio`} name={`sourceType`}
                                               checked={sourceType.toString() === "true"} value={true}
                                               onClick={(e) => this.props.radioBoxchange(e)}/>
                                        <div className="control-indicator"></div>
                                    </label>
                                </div>


                                <div className="select-chk-block" style={{"display": "block"}}>
                                    <label className="control control-checkbox">Entity
                                        <input className="inp-chk" type={`radio`} name={`sourceType`}
                                               checked={sourceType.toString() === "false"}
                                               value={false} onClick={(e) => this.props.radioBoxchange(e)}/>
                                        <div className="control-indicator"></div>
                                    </label>
                                </div>
                            </div>

                            <div className="task-input-container margin-b-10">
                                <label>{sourceType ? 'Collections :' : "Entity :"}</label>
                                {sourceType.toString() === "true" ?
                                    <div className="select-box eq-width">
                                        <select id="multi-area-chart-collections" name="collection"
                                                onChange={(e) => this.props.handleInputChange(e)} value={collection}>

                                            <option value="">Collections</option>
                                            {collections.map((list, index) =>
                                                <option key={`multi-area-chart-${index}`}
                                                        value={list._id}>{atob(list.title)}</option>
                                            )}
                                        </select>
                                    </div>
                                    :

                                    <div className="select-box eq-width">
                                        <select id="multi-area-chart-collections" name="collection"
                                                onChange={(e) => this.props.handleInputChange(e)} value={collection}>

                                            <option value="">Entity</option>

                                            {collections.map((list, index) =>

                                                <option key={`multi-area-chart-${index}`}
                                                        value={list._id}>{atob(list._source.name)}</option>
                                            )}
                                        </select>
                                    </div>
                                }

                            </div>
                        </>
                        }


                        {!this.props.containerData.hasOwnProperty("sourceType") &&
                        <div className="task-input-container">
                            <label>Collections:</label>
                            <div className="select-box eq-width">
                                <select id="priority" name="collection"
                                        onChange={(e) => this.props.handleInputChange(e)} value={collection}>

                                    <option value="">Option 1</option>
                                    {collections.map((list, index) =>
                                        <option value={list._id}>{atob(list.title)}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        }

                        {this.props.hasOwnProperty("radialbarchartType") &&
                        <div className="task-input-container"><label>Type:</label>
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="type"
                                        onChange={(e) => this.props.handleChange(e)}
                                        value={this.props.radialbarchartType}>
                                    <option value="">Please Select</option>
                                    <option value="normal">Normal</option>
                                    <option value="custom">Custom</option>

                                </select>
                            </div>
                        </div>

                        }

                        {this.props.containerData.hasOwnProperty("min") &&
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="Min" id="min" name="min" autoComplete="off"
                                       value={this.props.containerData.min}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        }

                        {this.props.containerData.hasOwnProperty("max") &&
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="Max" id="max" name="max" autoComplete="off"
                                       value={this.props.containerData.max}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        }


                        {this.props.containerData.hasOwnProperty("selectFilter") &&
                        <div className="task-input-container">
                            <div className="select-box eq-width">
                                <select id="multi-area-chart-collections" name="selectFilter"
                                        onChange={(e) => this.props.handleChange(e)}
                                        value={this.props.containerData.selectFilter}
                                >

                                    <option value="|">Please select</option>
                                    {showlistField.map((label, index) =>
                                        <option key={`multi-area-chart-masterid` + index}
                                                value={label.masterid}>{label.label}</option>
                                    )}
                                </select>
                            </div>

                        </div>
                        }


                        {this.props.containerData.hasOwnProperty("unit") &&
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="Unit" id="unit" name="unit" autoComplete="off"
                                       value={this.props.containerData.unit}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        }
                        {this.props.containerData.hasOwnProperty("transparancy") &&
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="transparancy" id="transparancy" name="transparancy"
                                       autoComplete="off"
                                       value={this.props.containerData.transparancy}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>

                        </div>
                        }

                        <div className="task-input-container select-line">
                            {this.props.containerData.hasOwnProperty("enableyaxis") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Y Axis
                                    <input className="inp-chk" type={`checkbox`} name={`enableyaxis`}
                                           checked={enableyaxis} value={enableyaxis}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("legend") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Legend
                                    <input className="inp-chk" type={`checkbox`} name={`legend`} checked={legend}
                                           value={legend} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("alldata") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">All Data
                                    <input className="inp-chk" type={`checkbox`} name={`alldata`} checked={alldata}
                                           value={alldata} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("stacked") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Stacked
                                    <input className="inp-chk" type={`checkbox`} name={`stacked`}
                                           checked={this.props.containerData.stacked}
                                           value={this.props.containerData.stacked}
                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                            }
                            {this.props.containerData.hasOwnProperty("enablegradient") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Gradient
                                    <input className="inp-chk" type={`checkbox`} name={`enablegradient`}
                                           checked={enablegradient}
                                           value={enablegradient} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            }

                            {this.props.containerData.hasOwnProperty("hidearea") &&
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Hide Area
                                    <input className="inp-chk" type={`checkbox`} name={`hidearea`} checked={hidearea}
                                           value={hidearea} onClick={(e) => this.props.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            }
                        </div>


                        <div className="main-container-wrap">
                            {this.props.containerData.hasOwnProperty("xwidth") &&
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X width" id="xwidth" name="xwidth"
                                           autoComplete="off"
                                           value={this.props.containerData.xwidth}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>

                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("xheight") &&
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X Height" id="xheight" name="xheight"
                                           autoComplete="off" value={this.props.containerData.xheight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("ywidth") &&
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Width" id="ywidth" name="ywidth"
                                           autoComplete="off" value={this.props.containerData.ywidth}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("yheight") &&
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Height" id="yheight" name="yheight"
                                           autoComplete="off" value={this.props.containerData.yheight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("graphHeight") &&
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Graph Height" id="graphHeight" name="graphHeight"
                                           autoComplete="off" value={this.props.containerData.graphHeight}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("xAngle") &&
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="X Angle" id="xAngle" name="xAngle"
                                           autoComplete="off" value={this.props.containerData.xAngle}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            }
                            {this.props.containerData.hasOwnProperty("yAngle") &&
                            <div className="task-input-container wid-3">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Y Angle" id="yAngle" name="yAngle"
                                           autoComplete="off" value={this.props.containerData.yAngle}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>
                            </div>
                            }
                        </div>
                        {this.props.containerData.hasOwnProperty("layout") &&
                        <div className="task-input-container margin-b-20">
                            <label>Layout:</label>
                            <div className="select-box eq-width">
                                <select id="layout"
                                        name="layout"
                                        onChange={(e) => this.props.handleChange(e)}
                                        value={this.props.containerData.layout}>
                                    <option value="">none</option>
                                    <option value={"horizontal"}>horizontal</option>
                                    <option value={"vertical"}>vertical</option>
                                </select>
                            </div>
                        </div>
                        }
                        {this.props.containerData.hasOwnProperty("bordercolor") &&
                        <div className="task-input-container margin-b-20 ">
                            <div className="stroke-color-select">
                                <label>Border Color:</label>
                                <div className="graph-proprties-color">
                                    <div className="task-input-container">
                                        <ColorPicker
                                            stroke={`bordercolor`}
                                            color={this.props.containerData.bordercolor}
                                            onChangeComplete={this.props.borderColorChange}
                                            handleClickStaus={this.props.handleClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {this.props.containerData.hasOwnProperty("bubblecolor") &&
                        <>
                            <div className="main-container-wrap">
                                <label className="task-input-container container-flex"> Bubble color</label>
                            </div>
                            <div className="graph-proprties">
                                <div className="graph-proprties-color modal-panel-container">
                                    <div className="task-input-container">
                                        <div>
                                            {this.props.containerData.bubblecolor.map((item, index) => (
                                                <div className={'panel-each'}>
                                                    <div className={`settings-panel`}>
                                                        <input type={`text`} name="label"
                                                               defaultValue={item.label}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "bubblecolor")}
                                                        />
                                                        &nbsp;&nbsp;
                                                        <ColorPicker
                                                            stroke={item.id}
                                                            color={item.color}
                                                            label={`bubblecolor`}
                                                            onChangeComplete={this.props.handleBubblecolorChange}
                                                            handleClickStaus={this.props.handleClick}
                                                        />

                                                    </div>
                                                    <div className={`settings-panel-option`}>
                                                        {index + 1 === this.props.containerData.bubblecolor.length &&
                                                        <button className="ico-lib divider-add-button"
                                                                onClick={() => this.props.addData(item.id, "bubblecolor")}></button>
                                                        }
                                                        {this.props.containerData.bubblecolor.length !== 1 &&
                                                        <button className="ico-lib divider-sub-button"
                                                                onClick={() => this.props.removeData(item.id, "bubblecolor")}></button>
                                                        }
                                                    </div>
                                                </div>

                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }

                        {this.props.containerData.hasOwnProperty("strokecolor") &&
                        <>
                            <div className="main-container-wrap">
                                <label className="task-input-container container-flex"> Stroke color</label>
                            </div>
                            <div className="graph-proprties">
                                <div className="graph-proprties-color modal-panel-container">
                                    <div className="task-input-container">
                                        <div>
                                            {this.props.containerData.strokecolor.map((item, index) => (
                                                <div className={'panel-each'}>
                                                    <div className={`settings-panel`}>
                                                        <ColorPicker
                                                            stroke={item.id}
                                                            color={item.color}
                                                            label={`strokecolor`}
                                                            onChangeComplete={this.props.handleBubblecolorChange}
                                                            handleClickStaus={this.props.handleClick}
                                                        />

                                                    </div>
                                                    <div className={`settings-panel-option`}>
                                                        {index + 1 === this.props.containerData.strokecolor.length &&
                                                        <button className="ico-lib divider-add-button"
                                                                onClick={() => this.props.addData(item.id, "strokecolor")}></button>
                                                        }
                                                        {this.props.containerData.strokecolor.length !== 1 &&
                                                        <button className="ico-lib divider-sub-button"
                                                                onClick={() => this.props.removeData(item.id, "strokecolor")}></button>
                                                        }
                                                    </div>
                                                </div>

                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }

                        {this.props.containerData.hasOwnProperty("fillcolor") &&
                        <>
                            <div className="main-container-wrap">
                                <label className="task-input-container container-flex"> Fill color</label>
                            </div>
                            <div className="graph-proprties">
                                <div className="graph-proprties-color modal-panel-container">
                                    <div className="task-input-container">
                                        <div>
                                            {this.props.containerData.fillcolor.map((item, index) => (
                                                <div className={'panel-each'}>
                                                    <div className={`settings-panel`}>
                                                        <ColorPicker
                                                            stroke={item.id}
                                                            color={item.color}
                                                            label={`fillcolor`}
                                                            onChangeComplete={this.props.handleBubblecolorChange}
                                                            handleClickStaus={this.props.handleClick}
                                                        />

                                                    </div>
                                                    <div className={`settings-panel-option`}>
                                                        {index + 1 === this.props.containerData.fillcolor.length &&
                                                        <button className="ico-lib divider-add-button"
                                                                onClick={() => this.props.addData(item.id, "fillcolor")}></button>
                                                        }
                                                        {this.props.containerData.fillcolor.length !== 1 &&
                                                        <button className="ico-lib divider-sub-button"
                                                                onClick={() => this.props.removeData(item.id, "fillcolor")}></button>
                                                        }
                                                    </div>
                                                </div>

                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        }


                        {this.props.containerData.hasOwnProperty("basedon") &&
                        <>
                            <div className="main-container-wrap">
                                <label className="task-input-container container-flex"> Based on</label>
                            </div>
                            <div className="task-input-container">
                                <div className="select-box eq-width">
                                    <select id="multi-area-chart-collections" name="basedon"
                                            onChange={(e) => this.props.handleChange(e)}
                                            value={this.props.containerData.basedon}>

                                        <option value="|">Please select</option>
                                        {showlistField.map((label, index) =>
                                            <option key={`multi-area-chart-masterid` + index}
                                                    value={label.masterid}>{label.label}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </>
                        }


                        {this.props.containerData.hasOwnProperty("seriesLabel") &&
                        <>
                            <div className="main-container-wrap">
                                <label className="task-input-container container-flex"> Series</label>
                            </div>
                            <div className="main-container-wrap">


                                <div className="task-input-container" style={{"width": "33.33%"}}>
                                    <div className="new-task-input">
                                        <input type="text" placeholder="series label" id="seriesLabel"
                                               name="seriesLabel"
                                               autoComplete="off" value={this.props.containerData.seriesLabel}
                                               onChange={(e) => this.props.handleChange(e)}/>
                                    </div>
                                </div>
                                <div className="task-input-container wid-3">
                                    <div className="select-box eq-width">
                                        <select id="multi-area-chart-collections" name="seriesField"
                                                onChange={(e) => this.props.handleChange(e)}
                                                value={this.props.containerData.seriesField}>

                                            <option value="|">Please select</option>
                                            {showlistField.map((label, index) =>
                                                <option key={`multi-area-chart-masterid` + index}
                                                        value={label.masterid}>{label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className={`task-input-container wid-3`}>
                                    <div className="new-task-input" onClick={() => this.colorChange()}>
                                        <div style={{
                                            "width": "42px",
                                            "height": "39px",
                                            "border-radius": "9px",
                                            "background": "rgb(204, 51, 43)"
                                        }}></div>
                                    </div>

                                </div>
                            </div>

                        </>

                        }


                        {this.props.containerData.hasOwnProperty("tabledata") &&
                        <>
                            <div className="main-container-wrap">
                                <label className="task-input-container container-flex">Graph axis</label>
                            </div>
                            <div className="graph-proprties">
                                <div className="graph-proprties-color modal-panel-container">
                                    <div className="task-input-container">
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(droppableProvided, droppableSnapshot) => (
                                                    <div
                                                        ref={droppableProvided.innerRef}
                                                        // style={getListStyle(droppableSnapshot.isDraggingOver)}
                                                    >
                                                        {tabledata.map((item, index) => (
                                                            <Drags key={item.id} draggableId={item.id} index={index}
                                                                   isDragDisabled={this.props.colorpickerStatus}>
                                                                {(draggableProvided, draggableSnapshot) => (
                                                                    <div className={'panel-each'}
                                                                         ref={draggableProvided.innerRef}
                                                                         {...draggableProvided.draggableProps}
                                                                         {...draggableProvided.dragHandleProps}
                                                                         style={getItemStyle(
                                                                             draggableSnapshot.isDragging,
                                                                             draggableProvided.draggableProps.style
                                                                         )}
                                                                         isDragDisabled={this.props.colorpickerStatus}
                                                                    >
                                                                        <div className={`settings-panel`}>
                                                                            <input type={`text`} name="label"
                                                                                   defaultValue={item.label}
                                                                                   onChange={(e) => this.props.handleLabelChange(e, item.id, "graph")}/>

                                                                            <div className="select-box">
                                                                                <select id="yaxis" name="field"
                                                                                        onChange={(e) => this.props.handleLabelChange(e, item.id, "graph")}
                                                                                        value={item.field}>{item.type}
                                                                                    <option value="">Y axis</option>
                                                                                    {showlistField.map((label, index) =>
                                                                                        <option
                                                                                            key={`multi-area-chart-masterid` + index}
                                                                                            value={label.masterid}>{label.label}</option>
                                                                                    )}
                                                                                </select>
                                                                            </div>
                                                                            <ColorPicker
                                                                                stroke={item.id}
                                                                                color={item.color}
                                                                                onChangeComplete={this.props.handleColorChange}
                                                                                handleClickStaus={this.props.handleClick}
                                                                            />
                                                                            {this.props.containerData.hasOwnProperty("Aggregate") && index === 0 &&
                                                                            <div style={{paddingLeft: "10px"}}>
                                                                                <label
                                                                                    className="control control-checkbox">
                                                                                    <input className="inp-chk"
                                                                                           type={`checkbox`}
                                                                                           name={`groupbyEnable`}
                                                                                           checked={this.props.containerData.groupbyEnable}
                                                                                           value={this.props.containerData.groupbyEnable}
                                                                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                                    <div
                                                                                        className="control-indicator"></div>
                                                                                    Group By
                                                                                </label>
                                                                            </div>

                                                                            }
                                                                            {this.props.containerData.hasOwnProperty("Aggregate") && index !== 0 &&
                                                                            <div className="select-box">
                                                                                <select
                                                                                    id="multi-area-chart-collections"
                                                                                    name="aggs"
                                                                                    onChange={(e) => this.props.handleLabelChange(e, item.id, "graph")}

                                                                                    value={item.aggs}>
                                                                                    <option value="">Aggregate</option>

                                                                                    <option key={`multi-area-chart-sum`}
                                                                                            value={"sum"}>Sum
                                                                                    </option>
                                                                                    <option key={`multi-area-chart-sum`}
                                                                                            value={"avg"}>Avg
                                                                                    </option>
                                                                                    <option key={`multi-area-chart-sum`}
                                                                                            value={"min"}>Min
                                                                                    </option>
                                                                                    <option key={`multi-area-chart-sum`}
                                                                                            value={"max"}>Max
                                                                                    </option>
                                                                                    <option key={`multi-area-chart-sum`}
                                                                                            value={"count"}>Count
                                                                                    </option>

                                                                                </select>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                        {(this.props.hasOwnProperty("repeater") && !this.props.repeater) || this.props.repeater || !this.props.hasOwnProperty("repeater") &&
                                                                        <div className={`settings-panel-option`}>
                                                                            {index + 1 === tabledata.length &&
                                                                            <button
                                                                                className="ico-lib divider-add-button"
                                                                                onClick={() => this.props.addData(item.id, "items")}></button>
                                                                            }

                                                                            {tabledata.length !== 1 &&
                                                                            <button
                                                                                className="ico-lib divider-sub-button"
                                                                                onClick={() => this.props.removeData(item.id, "items")}></button>
                                                                            }
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Drags>
                                                        ))}
                                                        {droppableProvided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>


                                </div>
                            </div>
                        </>
                        }


                        <div className="main-container-wrap">
                            {/*{this.props.containerData.hasOwnProperty("groupbyEnable") &&*/}
                            {/*<div className="task-input-container">*/}
                            {/*    <div className="select-chk-block" style={{"display": "block"}}>*/}
                            {/*        <label className="control control-checkbox">Group By Enable*/}
                            {/*            <input className="inp-chk" type={`checkbox`} name={`groupbyEnable`}*/}
                            {/*                   checked={this.props.containerData.groupbyEnable}*/}
                            {/*                   value={this.props.containerData.groupbyEnable} onClick={(e) => this.props.checkBoxchange(e)}/>*/}
                            {/*            <div className="control-indicator"></div>*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*}*/}
                            {/*{this.props.containerData.hasOwnProperty("groupby") &&*/}
                            {/*<div className="task-input-container">*/}
                            {/*    /!*<label>Group By:</label>*!/*/}
                            {/*    <div className="select-box eq-width">*/}
                            {/*        <select id="multi-area-chart-collections" name="groupby"*/}
                            {/*                onChange={(e) => this.props.handleInputChange(e)} value={this.props.containerData.groupby}>*/}

                            {/*            <option value="">Group BY</option>*/}
                            {/*            {showlistField.map((label, index) =>*/}
                            {/*                <option*/}
                            {/*                    key={`multi-area-chart-masterid` + index}*/}
                            {/*                    value={label.masterid}>{label.label}</option>*/}
                            {/*            )}*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*}*/}
                        </div>

                        {this.props.containerData.hasOwnProperty("whereCondition") &&
                        <>
                            <div className="main-container-wrap">
                                <label className="task-input-container container-flex"> Where Conditions</label>
                            </div>
                            <div className="graph-proprties">
                                <div className="graph-proprties-color modal-panel-container">
                                    <div className="task-input-container">
                                        {this.props.containerData.whereCondition.map((item, index) =>
                                            <div className={'panel-each'}>
                                                <div className={`settings-panel`}>
                                                    {/*<input type={`text`} name="label"*/}
                                                    {/*       defaultValue={item.label}*/}
                                                    {/*       onChange={(e) => this.props.handleLabelChange(e, item.id)}*/}
                                                    {/*/>*/}
                                                    <div className="select-box">
                                                        <select id="yaxis" name="field"
                                                                onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "field")}
                                                                value={item.field}>
                                                            <option value="">Conditions</option>
                                                            {showlistField.filter(list => (list.type === "number" || list.type === "switch" || list.type === "dropdown" || list.type === "checkbox" || list.type === "text" || list.type === "datepicker")).map((label, index) =>
                                                                <option
                                                                    key={`multi-area-chart-masterid` + index}
                                                                    value={label.masterid}>{label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                    showlistField.filter(list => list.masterid === item.field && (list.type === "switch" || list.type === "dropdown" || list.type === "checkbox"))[0] &&
                                                    <div className="select-box">
                                                        <select id="yaxis" name="conditions"
                                                                onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "conditions")}
                                                                value={item.conditions}>
                                                            <option value="">Conditions</option>
                                                            {showlistField.filter(list => list.masterid === item.field).length !== 0 && showlistField.filter(list => list.masterid === item.field)[0].option.map((lab, index) =>
                                                                <option
                                                                    key={`multi-area-chart-masterid` + index}
                                                                    value={lab}>{lab}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    }

                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                    showlistField.filter(list => list.masterid === item.field && list.type === "number")[0] &&
                                                    <>
                                                        <input type={`text`} name="min"
                                                               defaultValue={item.min}
                                                               placeholder={`min`}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "number")}
                                                        />
                                                        <input type={`text`} name="max"
                                                               placeholder={`max`}
                                                               defaultValue={item.max}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "number")}
                                                        />
                                                    </>
                                                    }

                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                    showlistField.filter(list => list.masterid === item.field && list.type === "datepicker")[0] &&
                                                    <>
                                                        <input type={`date`} name="from"
                                                               defaultValue={item.from}
                                                               placeholder={`from`}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "datepicker")}
                                                        />
                                                        <input type={`date`} name="to"
                                                               defaultValue={item.to}
                                                               placeholder={`to`}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "datepicker")}
                                                        />
                                                    </>
                                                    }
                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                    showlistField.filter(list => list.masterid === item.field && list.type === "text")[0] &&
                                                    <>
                                                        <input type={`text`} name="text"
                                                               defaultValue={item.text}
                                                               placeholder={`text`}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "text")}
                                                        />

                                                    </>
                                                    }


                                                </div>
                                                <div className={`settings-panel-option`}>
                                                    {index + 1 === whereCondition.length &&
                                                    <button className="ico-lib divider-add-button"
                                                            onClick={() => this.props.addData(item.id, "whereCondition")}>

                                                    </button>
                                                    }

                                                    {whereCondition.length !== 1 &&
                                                    <button className="ico-lib divider-sub-button"
                                                            onClick={() => this.props.removeData(item.id, "whereCondition")}>

                                                    </button>
                                                    }
                                                </div>

                                            </div>
                                        )}


                                    </div>


                                </div>
                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Limit" id="limit" name="limit" autoComplete="off"
                                           value={this.props.containerData.limit}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>

                            </div>
                        </>
                        }


                        {this.props.containerData.hasOwnProperty("globalFilter") &&
                        <>
                            <div className="main-container-wrap">
                                <label className="task-input-container container-flex"> Global Filters</label>
                            </div>
                            <div className="graph-proprties">
                                <div className="graph-proprties-color modal-panel-container">
                                    <div className="task-input-container">
                                        {this.props.containerData.globalFilter.map((item, index) =>
                                            <div className={'panel-each'}>
                                                <div className={`settings-panel`}>
                                                    {/*<input type={`text`} name="label"*/}
                                                    {/*       defaultValue={item.label}*/}
                                                    {/*       onChange={(e) => this.props.handleLabelChange(e, item.id)}*/}
                                                    {/*/>*/}
                                                    <div className="select-box">
                                                        <select id="yaxis" name="field"
                                                                onChange={(e) => this.props.handleLabelChange(e, item.id, "globalFilter", "field")}
                                                                value={item.field}>
                                                            <option value="">Conditions</option>
                                                            {showlistField.filter(list => (list.type === "number" || list.type === "autofill" || list.type === "switch" || list.type === "dropdown" || list.type === "checkbox" || list.type === "text" || list.type === "datepicker")).map((label, index) =>
                                                                <option
                                                                    key={`multi-area-chart-masterid` + index}
                                                                    value={label.masterid}>{label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                    showlistField.filter(list => list.masterid === item.field && (list.type === "switch" || list.type === "dropdown" || list.type === "checkbox"))[0] &&
                                                    <div className="select-box">
                                                        <input type={`text`} name="text"
                                                               defaultValue={item.text}
                                                               placeholder={`Filter Name`}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "globalFilter", "conditions")}
                                                        />
                                                    </div>
                                                    }

                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                    showlistField.filter(list => list.masterid === item.field && list.type === "number")[0] &&
                                                    <>
                                                        <input type={`text`} name="text"
                                                               defaultValue={item.text}
                                                               placeholder={`Filter Name`}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "globalFilter", "number")}
                                                        />
                                                        {/*<input type={`text`} name="max"*/}
                                                        {/*       placeholder={`max`}*/}
                                                        {/*       defaultValue={item.max}*/}
                                                        {/*       onChange={(e) => this.props.handleLabelChange(e, item.id,"globalFilter","number")}*/}
                                                        {/*/>*/}
                                                    </>
                                                    }

                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                    showlistField.filter(list => list.masterid === item.field && list.type === "datepicker")[0] &&
                                                    <>
                                                        <input type={`text`} name="text"
                                                               defaultValue={item.text}
                                                               placeholder={`Filter Name`}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "globalFilter", "datepicker")}
                                                        />
                                                        {/*<input type={`date`} name="to"*/}
                                                        {/*       defaultValue={item.to}*/}
                                                        {/*       placeholder={`to`}*/}
                                                        {/*       onChange={(e) => this.props.handleLabelChange(e, item.id,"globalFilter","datepicker")}*/}
                                                        {/*/>*/}
                                                    </>
                                                    }
                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                    showlistField.filter(list => list.masterid === item.field && list.type === "text")[0] &&
                                                    <>
                                                        <input type={`text`} name="text"
                                                               defaultValue={item.text}
                                                               placeholder={`Filter Name`}
                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "globalFilter", "text")}
                                                        />

                                                    </>
                                                    }


                                                </div>
                                                <div className={`settings-panel-option`}>
                                                    {index + 1 === this.props.containerData.globalFilter.length &&
                                                    <button className="ico-lib divider-add-button"
                                                            onClick={() => this.props.addData(item.id, "globalFilter")}>

                                                    </button>
                                                    }

                                                    {this.props.containerData.globalFilter.length !== 1 &&
                                                    <button className="ico-lib divider-sub-button"
                                                            onClick={() => this.props.removeData(item.id, "globalFilter")}>

                                                    </button>
                                                    }
                                                </div>

                                            </div>
                                        )}


                                    </div>


                                </div>
                            </div>
                            <div className="task-input-container">
                                <div className="new-task-input">
                                    <input type="text" placeholder="Limit" id="limit" name="limit" autoComplete="off"
                                           value={this.props.containerData.limit}
                                           onChange={(e) => this.props.handleChange(e)}/>
                                </div>

                            </div>
                        </>
                        }


                        {this.props.containerData.hasOwnProperty("filterName") &&
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="Filter Name" id="filterName" name="filterName"
                                       autoComplete="off" value={this.props.containerData.filterName}
                                       onChange={(e) => this.props.handleChange(e)}/>
                            </div>
                        </div>
                        }


                        {this.props.containerData.hasOwnProperty("condition") &&
                        <>
                            <div className="task-input-container"><label>Conditions:</label>
                                <div className="select-box eq-width">
                                    <select id="multi-area-chart-collections" name="field"
                                            onChange={(e) => this.props.handleConditionChange(e)}
                                            value={condition && condition[0].field + "|" + (condition[0].type === "date" ? "datepicker" : condition[0].type)}>

                                        <option value="|">Conditional Parameter</option>
                                        {showlistField.filter(item => (item.type === "number" || item.type === "datepicker")).map((label, index) =>
                                            <option key={`multi-area-chart-masterid` + index}
                                                    value={label.masterid + "|" + label.type}>{label.label}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="task-input-container"><label>Sort:</label>
                                <div className="select-box eq-width">
                                    <select id="multi-area-chart-collections" name="sort"
                                            onChange={(e) => this.props.handleConditionChange(e)}
                                            value={condition && condition[0].sort}>

                                        <option value="">Sort</option>
                                        <option value="asc">ASC</option>
                                        <option value="desc">DESC</option>

                                    </select>
                                </div>
                            </div>
                        </>
                        }
                    </div>

                    {this.state.sColor &&
                    <SimpleModal
                        Close={this.colorChange}
                        title={`Area Color`}
                        haveFooter={1}
                        OKLabel="Save"
                        OKAction={this.colorChange}
                        asignedUsershow={false}
                        cancelLabel="Cancel"
                        cancelAction={this.colorChange}
                    >
                        {this.props.containerData.serColor.map((item, index) => (
                            <div className={'panel-each'}>
                                <div className={`settings-panel dynamic-cp`}>

                                    <ColorPicker
                                        stroke={item.id}
                                        color={item.color}
                                        label={`serColor`}
                                        onChangeComplete={this.props.serColorChange}
                                        handleClickStaus={this.props.handleClick}
                                    />

                                </div>
                                <div className={`settings-panel-option`}>
                                    {index + 1 === this.props.containerData.serColor.length &&
                                    <button className="ico-lib divider-add-button"
                                            onClick={() => this.props.addData(item.id, "serColor")}></button>
                                    }
                                    {this.props.containerData.serColor.length !== 1 &&
                                    <button className="ico-lib divider-sub-button"
                                            onClick={() => this.props.removeData(item.id, "serColor")}></button>
                                    }
                                </div>
                            </div>

                        ))}
                    </SimpleModal>
                    }

                </SimpleModal>
                }

            </React.Fragment>
        )
    }
}

export {ViewData, SettingsPanel, DashboardSettings}
