import React from 'react';
import {formatSizeUnits} from "../../utilities/CustomFunctions"
import {ProgressBar as reactProgressBar} from 'react-bootstrap'

class ProgressBar extends React.Component{
    constructor(props){
        super();
        this.state = {
            progressPercentage: 0,
            uploaded:0,
            totalSize:0,
            progressbarType:"custom"
        }

        this.onProgress = this.onProgress.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onProgress(percent,config) {
        this.setState({progressPercentage:percent,progressbarType:config.progressbarType,uploaded:config.uploaded,fileLength:config.fileLength,totalSize:config.totalSize})
        console.log("this.state.progressPercentage"+this.state.progressPercentage+this.state.progressbarType);
    }

    render(){
        return(
            <>
                {this.state.progressbarType !== "line" ?
                    <div className="upload-container-panel" style={{zIndex: 99999999999999}}>
                        <div className="upload-container-panel-header">
                            <h5>{this.state.uploaded + 1} / {this.state.fileLength} {this.state.fileLength===1?"File":"Files"} Uploaded</h5>
                            <p>{formatSizeUnits(this.state.totalSize)}</p>
                        </div>
                        <div className="upload-container-panel-bottom">
                            <div id="progress-holder" className="upload-pr-wrap">
                                <div className="progress">
                                    <div role="progressbar" className="progress-bar bg-success progress-bar-striped"
                                         aria-valuenow={parseInt(this.state.progressPercentage)} aria-valuemin="0"
                                         aria-valuemax="100"
                                         style={{'width': `${parseFloat(this.state.progressPercentage).toFixed(2)}%`}}>{Math.round(this.state.progressPercentage)}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div id="progress-holder" className="upload-pr-wrap">
                        <div className="progress">
                            <div role="progressbar" className="progress-bar bg-success progress-bar-striped"
                                 aria-valuenow={this.state.progressPercentage} aria-valuemin="0" aria-valuemax="100" style={{width: `${Math.round(this.state.progressPercentage)}%`}}>100%
                            </div>
                        </div>
                    </div>


                }



                    </>


    )}
};

export default ProgressBar;
