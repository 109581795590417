import React, {Component,useEffect} from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "./api.css";
import axios from "axios";
import NoData from "./NoData.js";
import * as collectionActions from "../../actions/Collections";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import {API_PATH, ES_INDEX} from "../../constants";
import {getProfile} from "../../utilities/AuthService";
import * as CeShareViewActions from "../../actions/CeShareView";


class ApiExplorer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiExplorerData:'',
            options:{},
            formData:[],
        };

    }
     removejscssfile(filename, filetype){
        var targetelement=(filetype=="js")? "script" : (filetype=="css")? "link" : "none" //determine element type to create nodelist from
        var targetattr=(filetype=="js")? "src" : (filetype=="css")? "href" : "none" //determine corresponding attribute to test for
        var allsuspects=document.getElementsByTagName(targetelement)
        for (var i=allsuspects.length; i>=0; i--){ //search backwards within nodelist for matching elements to remove
            if (allsuspects[i] && allsuspects[i].getAttribute(targetattr)!=null && allsuspects[i].getAttribute(targetattr).indexOf(filename)!=-1)
                allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
        }
    }
    componentDidMount() {
        let collectionId = atob(getUrlSegment(3));
        let param={
            "collectionid": atob(getUrlSegment(3))
        }
        this.props.getResultSet(param, collectionId)

        this.removejscssfile("main-layout.css", "css") //remove all occurences "somestyle.css" on page

        this.loadCollectionDataJson();

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.resultSetData.result !== prevProps.resultSetData.result) {
            if(this.props.resultSetData.result.length !== 0){
                this.setState({formData :this.props.resultSetData.result});
                this.setState({
                    formData : this.props.resultSetData.result
                },function(){
                    this.loadCollectionDataJson();
                });
            }
        }
    }

    loadCollectionDataJson=()=> {
        let options = {
            "openapi": "3.0.0",
            "info": {
                "title": "BSYNAPSE API Documentation",
                "description": "Knowledge @ Work by **LogicalSteps**<p>Api Documentation - [ReDoc](http://apir4dev.bsynapse.com/explorer/documentation.html)</p>",
                "contact": {
                    "email": "arul@logicalsteps.net"
                },
                "license": {
                    "name": "all rights reserved."
                },
                "version": "2"
            },
            "servers": [
                {
                    "url": API_PATH,
                    "description": "prod"
                }
            ],
            "paths": {
                "/collection/createdata": {
                    "post": {
                        "operationId": "POST v1/collection/createdata",
                        "tags": [
                            "collection"
                        ],
                        "parameters": [],
                        "requestBody": {
                            "$ref": "#/components/requestBodies/CollectionsCreatecreateDataModel"
                        },
                        "summary": "save collection data",
                        "description": "if the parameter 'mode' pass as \"save\" help to save form data whereas 'mode' is \"update\" help to update the form data, 'child' is the id for the form which pass only in mode=\"update\" otherwise pass it as ''. 'parent' is the id of the colllection. 'referenceID' is used for from where we add the form data, if it added from task page then pass the taskid else pass the parent as referenceID.",
                        "responses": {
                            "200": {
                                "description": "OK",
                                "content": {
                                    "application/json": {
                                        "schema": {
                                            "type": "array",
                                            "items": {
                                                "type": "object"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                "/collection/{_id}/collectedinfo": {
                    "post": {
                        "operationId": "POST v1/collection/{_id}/collectedinfo",
                        "tags": [
                            "collection"
                        ],
                        "parameters": [
                            {
                                "name": "_id",
                                "in": "path",
                                "description": "",
                                "required": true,
                                "schema": {
                                    "type": "string",
                                    "default": atob(getUrlSegment(3)),
                                }
                            }
                        ],
                        "requestBody": {
                            "$ref": "#/components/requestBodies/CollectionsCollectedDatav2Model"
                        },
                        "summary": "get collected data GET method",
                        "description": "we can filter the result by collection id . search is the user added value(field value), subsearch is the exact user entered value, labelsearch is the exact label that created",
                        "responses": {
                            "200": {
                                "description": "OK",
                                "content": {
                                    "application/json": {
                                        "schema": {
                                            "type": "array",
                                            "items": {
                                                "type": "object"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                "/collection/selectedcollection": {
                    "post": {
                        "operationId": "POST v1/collection/selectedcollection",
                        "tags": [
                            "collection"
                        ],
                        "parameters": [],
                        "requestBody": {
                            "$ref": "#/components/requestBodies/CollectionsCreateselectedcollectionModel"
                        },
                        "summary": "information about a particular collected data",
                        "description": "",
                        "responses": {
                            "200": {
                                "description": "OK",
                                "content": {
                                    "application/json": {
                                        "schema": {
                                            "type": "array",
                                            "items": {
                                                "type": "object"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                "/collection/deleterecords": {
                    "post": {
                        "operationId": "POST v1/collection/deleterecords",
                        "tags": [
                            "collection"
                        ],
                        "parameters": [],
                        "requestBody": {
                            "$ref": "#/components/requestBodies/CollectionsCreateremoveRecordsModel"
                        },
                        "summary": "remove collection records",
                        "description": "",
                        "responses": {
                            "200": {
                                "description": "OK",
                                "content": {
                                    "application/json": {
                                        "schema": {
                                            "type": "array",
                                            "items": {
                                                "type": "object"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "components": {
                "schemas": {
                    "CollectionsCreatecreateDataModel": {
                        "type": "object",
                        "properties": {
                            "mode": {
                                "type": "string",
                                "default": "save"
                            },
                            "parent": {
                                "type": "string",
                                "default": atob(getUrlSegment(3))
                            },
                            "formdata": {
                                "type": "string",
                                "default": this.state.formData.length !== 0 ? JSON.stringify(this.state.formData) : this.state.formData
                            },
                            "uid": {
                                "type": "integer",
                                "format": "int64",
                                "default": getProfile().id
                            },
                            "referenceID": {
                                "type": "string",
                                "default": atob(getUrlSegment(3))
                            },
                            "child": {
                                "type": "string"
                            },
                            "index": {
                                "type": "string",
                                "default": ES_INDEX
                            },
                            "reference": {
                                "type": "string",
                                "default":  getUrlSegment(1) === 'entity' ? "entity" : "collection"
                            },
                        },
                        "required": [
                            "mode",
                            "parent",
                            "formdata",
                            "uid",
                            "referenceID"
                        ]
                    },
                    "CollectionsCollectedDatav2Model": {
                        "type": "object",
                        "properties": {
                            "search": {
                                "type": "string",
                                "default": ""
                            },
                            "subsearch": {
                                "type": "string",
                                "default": ""
                            },
                            "from": {
                                "type": "integer",
                                "format": "int64",
                                "default": 0
                            },
                            "size": {
                                "type": "integer",
                                "format": "int64",
                                "default": 50
                            },
                            "index": {
                                "type": "string",
                                "default": ES_INDEX
                            },
                            "refer": {
                                "type": "string",
                                "default": getUrlSegment(1) === 'entity' ? "entity" : "collection"
                            },
                            "childid": {
                                "type": "string",
                                "default": ""
                            },
                            "addparentinfo": {
                                "type": "string",
                                "default": "yes"
                            },
                            "uid": {
                                "type": "integer",
                                "format": "int64",
                                "default": getProfile().id
                            },
                            "usertype": {
                                "type": "string",
                                "default": getProfile().usertype
                            },
                            "fromdate": {
                                "type": "string",
                                "default": ""
                            },
                            "todate": {
                                "type": "string",
                                "default": ""
                            },
                            "filters": {
                                "type": "array",
                                "default": [],
                            },
                            "datefilters": {
                                "type": "array",
                                "default": [],
                            },
                            "numberfilters": {
                                "type": "array",
                                "default": [],
                            },
                            "dayfilters": {
                                "type": "array",
                                "default": [],
                            },
                            "scrollid": {
                                "type": "string",
                                "default": ""
                            },
                            "projectid": {
                                "type": "",
                                "default": ""
                            },
                            "dashboard": {
                                "type": "boolean"
                            },
                            "linkedids": {
                                "type": "",
                                "default": ""
                            },
                            "facetrequired": {
                                "type": "boolean"
                            },
                            "starred": {
                                "type": "boolean",
                                "default": false
                            },
                            "sort": {
                                "type": "array",
                                "default": [],
                            },
                            "allfields": {
                                "type": "boolean"
                            },
                            "timefilters": {
                                "type": "array",
                                "default": [],
                            },
                            "defaultvalue": {
                                "type": "boolean"
                            },
                            "flags": {
                                "type": "array",
                                "default": [],
                            },
                            "fieldview": {
                                "type": "boolean"
                            }
                        }
                    },
                    "CollectionsCreateselectedcollectionModel": {
                        "type": "object",
                        "properties": {
                            "_id": {
                                "type": "string",
                                "example": "Please enter record id here"
                            },
                            "collectionid": {
                                "type": "string",
                                "example": atob(getUrlSegment(3))
                            },
                            "index": {
                                "type": "string",
                                "default": ES_INDEX,
                            },
                            "metainfo": {
                                "type": "boolean"
                            },
                            "flaginfo": {
                                "type": "boolean"
                            },
                            "wfinfo": {
                                "type": "boolean"
                            }
                        },
                        "required": [
                            "_id",
                            "collectionid",
                            "index"
                        ]
                    },
                    "CollectionsCreateremoveRecordsModel": {
                        "type": "object",
                        "properties": {
                            "collectionid": {
                                "type": "string",
                                "example": atob(getUrlSegment(3))
                            },
                            "uid": {
                                "type": "string",
                                "default": getProfile().id
                            },
                            "records": {
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "example": "Please enter record id's here"
                                }
                            },
                            "index": {
                                "type": "string",
                                "default": ES_INDEX
                            }
                        },
                        "required": [
                            "collectionid",
                            "uid"
                        ]
                    }
                },
                "requestBodies": {
                    "CollectionsCreatecreateDataModel": {
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CollectionsCreatecreateDataModel"
                                }
                            }
                        }
                    },
                    "CollectionsCollectedDatav2Model": {
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CollectionsCollectedDatav2Model"
                                }
                            }
                        }
                    },
                    "CollectionsCreateselectedcollectionModel": {
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CollectionsCreateselectedcollectionModel"
                                }
                            }
                        }
                    },
                    "CollectionsCreateremoveRecordsModel": {
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/CollectionsCreateremoveRecordsModel"
                                }
                            }
                        }
                    }
                },
                "securitySchemes": {
                    "ApiKey": {
                        "type": "apiKey",
                        "name": "X_API_KEY",
                        "in": "header",
                        "description": "authenticates with an api key in the header"
                    }
                }
            }
        }

        this.setState({options: options});
    }

    render() {
        console.log(this.state.apiExplorerData)
        return (
            <React.Fragment>

                <>
                    <SwaggerUI
                        // url={options}
                        spec = {this.state.options}
                    />
                </>

            </React.Fragment>
        )
    }
}


const mapStateToProps = state => ({
    resultSetData: state.CeShareView.resultSetData,
});
export default  withRouter(connect(mapStateToProps, {
    getResultSet:CeShareViewActions.getResultSet,
})(ApiExplorer));


