'use strict'

import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    }, colorHex:"red",
    stroke:'1'
  };
 componentDidMount() {
   this.setState({stroke:this.props.stroke,colorHex:this.props.color,label:this.props.label})
 }
 componentDidUpdate(prevProps, prevState, snapshot) {
     if(this.props.color !== prevProps.color){
         this.setState({colorHex:this.props.color})
     }
 }

    handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
      this.props.handleClickStaus(!this.state.displayColorPicker )
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
      this.props.handleClickStaus(!this.state.displayColorPicker )
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb,colorHex:color.hex })
    this.props.onChangeComplete(color.hex,this.props.stroke,this.props.label)
  };

  render() {

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `green`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
        <div className="color-align">
          <div style={ styles.swatch } onClick={ this.handleClick }>
            <div style={ {"width": '50px',
                "height": '30px',
                "borderRadius": '2px',
                "background": this.state.colorHex}} />
          </div>
          { this.state.displayColorPicker ? <div style={ styles.popover }>
            <div style={ styles.cover } onClick={ this.handleClose }/>
            <SketchPicker color={ this.props.color } onChange={ this.handleChange } />
          </div> : null }

        </div>
    )
  }
}

export default ColorPicker
