import React, {Component} from 'react';
import {TemplateVerticalPreview} from "./Template3"
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {

    const result = Array.from(list);

    ////////console.log(result)
    const [removed] = result.splice(startIndex, 1);
    ////////console.log(removed)
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "red",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "grey",
    padding: grid,
    width: 250
});

class EditMapTemplate extends Component {
  constructor(props){
      super(props)
      this.state={
          verticaltemp:[{
              "item1": {
                  "label":"",
                  "masterid":""

              },
              "item2": {
                  "label":"",
                  "masterid":""

              },
              "item3": {
                  "label":"",
                  "masterid":""

              },
              "item4": {
                  "label":"",
                  "masterid":""

              },
          }],

          maptemplate:[],
          horizontaltemplate:[]
      }
  }
  componentDidMount() {
      this.setState({maptemplate:(this.props.maptemplate.length === 0  ? this.state.verticaltemp:  this.props.maptemplate)
        })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
      if(this.props.default_template !== prevProps.default_template){
          this.setState(
              {maptemplate:(this.props.maptemplate.length === 0  ? this.state.verticaltemp:  this.props.maptemplate)
           })
      }
  }





    handleLabelChange=(e,id,temp)=>{
      let sel=e.target
      let labelParam=e.target.value.split("|")
      let masterid=labelParam[0]
      let type=  labelParam[1]
        if(temp === 'map'){
            var _newStatemap= this.state.maptemplate;
            [_newStatemap[0][id]][0]["masterid"] = masterid;
            [_newStatemap[0][id]][0]["type"] = type;
            [_newStatemap[0][id]][0]["label"] = sel.options[sel.selectedIndex].text;
            this.setState({_newStatemap})
            this.props.saveTempalete(_newStatemap,temp)
        }

    }
    handleChange=(e,id,temp)=>{
        if(temp === 'horizontal') {
            var _newState = this.state.horizontaltemplate;
            [_newState[0][id]][0]["label"] = e.target.value;

            this.setState({_newState})
            this.props.saveTempalete(_newState,temp)
        }

        if(temp === 'vertical') {
            var _newState = this.state.verticaltemplate;
            [_newState[0][id]][0]["label"] = e.target.value;

            this.setState({_newState})
            this.props.saveTempalete(_newState,temp)
        }



    }


    render() {
        ////////console.log( this.state)
        let template=this.props.template
        let viewtemplate=this.props.viewtemplate

        let showlistField = []
        let showlistImage = []

        if(this.props.label.showformdata.length !==0) {
            for (let i = 0; i < this.props.label.showformdata.pages.length; i++) {
                (this.props.label.showformdata.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden")).map(list=>{
                    showlistField.push(list)

                })


            }
            for (let i = 0; i < this.props.label.showformdata.pages.length; i++) {

                (this.props.label.showformdata.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden" &&(list.type ==="imageurl" || list.type ==="attachment"))).map(list=>{
                    showlistImage.push(list)

                })
            }
        }

 let maptemplate=this.state.maptemplate

        return (


                <div className="card-container grid-view">
                    <div className="ind-collection-card inline-flex-box edit-template">
                    <React.Fragment>
                        {maptemplate.map((item, index) =>
                            <React.Fragment>
                                <div className="edit-template-left">
                                    <div className="collection-card-left">

                                        <div className="select-box">
                                            <select onChange={(e) => this.handleLabelChange(e, "item1",'map')} value={item.item1.masterid+"|"+item.item1.type}>
                                                <option value="">Select</option>

                                                {showlistField.map((label, index) =>
                                                    <option key={`masterid` + index}
                                                            value={label.masterid+"|"+label.type}>{label.label}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="select-box">
                                            <select onChange={(e) => this.handleLabelChange(e, "item2",'map')} value={item.item2.masterid+"|"+item.item2.type}>
                                                <option value="">Select</option>

                                                {showlistField.map((label, index) =>
                                                    <option key={`masterid` + index}
                                                            value={label.masterid+"|"+label.type}>{label.label}</option>
                                                )}
                                            </select>
                                        </div>

                                        <div className="select-box">
                                            <select onChange={(e) => this.handleLabelChange(e, "item3",'map')} value={item.item3.masterid+"|"+item.item3.type}>
                                                <option value="">Select</option>

                                                {showlistField.map((label, index) =>
                                                    <option key={`masterid` + index}
                                                            value={label.masterid+"|"+label.type}>{label.label}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="select-box">
                                            <select onChange={(e) => this.handleLabelChange(e, "item4",'map')} value={item.item4.masterid+"|"+item.item4.type}>
                                                <option value="">Select</option>

                                                {showlistField.map((label, index) =>
                                                    <option key={`masterid` + index}
                                                            value={label.masterid+"|"+label.type}>{label.label}</option>
                                                )}
                                            </select>
                                        </div>

                                    </div>
                                </div>                                
                            </React.Fragment>
                        )}
                        <div className="edit-template-right">
                            <div className="edit-template-right-header">
                                <h5>Preview</h5>
                            </div>
                            <div className="edit-template-right-body">
                                <TemplateVerticalPreview
                                    saveTempalete={this.saveTempalete}
                                    maptemplate={maptemplate}
                                    template={'map'}
                                    default_template={this.props.default_template}
                                    label={this.props.showFormModal}
                                    collection={this.props.collection}
                                    />
                            </div>
                        </div>
                    </React.Fragment>
                    </div>
                </div>




        )
    }

}
export default EditMapTemplate ;