import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import LoaderSvg from "../../views/LoaderSVG";
import AutoComplete from "../../views/AutoComplete"
import * as ResourcesActions from "../../../actions/Resources";
import {getFileExtension,formatSizeUnits} from "../../../utilities/CustomFunctions";
import UploadFile from "../../../utilities/FileUpload/UploadFile";
import {NEW_VERSION, SECURED} from "../../../constants";
import rootActions from "../../../actions";
class AddNewResources extends Component {
    constructor(props) {
        super(props);
        this.state={
            loading:0,
            resourceId:0,
            name:'',
            nameError:'',
            selectedTags:[],
            file:'',
            fileError:false
        }
        this.createResource = this.createResource.bind(this);
        this.getTaglist = this.getTaglist.bind(this);
        this.handleinputChange = this.handleinputChange.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }
    componentDidMount() {
        this.getTaglist();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.resourceInfo.ResourceInfo !== prevProps.resourceInfo.ResourceInfo){
           let resourceInfo = this.props.resourceInfo.ResourceInfo[0]||[];
           if(resourceInfo){
               let file=[];
               file.push({
                   filename: resourceInfo.res_thumb,
                   filesize:resourceInfo.filesize,
                   filetitle: resourceInfo.title,
                   filetype: resourceInfo.filetype,
                   name: resourceInfo.title,
                   res_type: resourceInfo.res_type
               })
               this.setState({
                   resourceId:resourceInfo.resource_id,
                   name:resourceInfo.title,
                   selectedTags:resourceInfo.taglist,
                   file:file
               })
           }
        }
    }

    handleinputChange(e){
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            if(e.target.name === "name"){
                this.setState({nameError:false})
            }
        }
    }
    handleFile=(file)=>{
        console.log(file)
        if(file !== ''){
            this.setState({ fileError:false,nameError:false})

        }
        this.setState({loading:1})
        let str = file[0] ? file[0].name:'';
        let name = str.split(".")[0];
       this.setState({file,
           name:name
       })

        this.setState({loading:0})
    }
    createResource(e){
        e.preventDefault();
        let formValid = 0;
        if (this.state.name.trim() === '') {
            formValid++;
            this.setState({nameError:true})
        }
        if (this.state.file === '' || this.state.file.length === 0) {
            formValid++;
            this.setState({fileError:true})
        }

        var selectedTags = this.state.selectedTags;
        var tagids = '';
        var tagnames ='';
        var galleryid = this.state.resourceId === 0 ? '':this.state.resourceId;
        if(formValid === 0) {
            this.setState({loading: 1});
            if (selectedTags.length > 0) {
                for (var i = 0; i < selectedTags.length; i++) {
                    if (selectedTags[i].id !== '') {
                        tagids = (tagids !== '') ? (tagids + ",") : "";
                        tagids += selectedTags[i].id;
                    } else {
                        tagnames = (tagnames !== '') ? (tagnames + ",") : "";
                        tagnames += selectedTags[i].tag_name;
                    }
                }
            }

            let uploadedFiles=this.state.file;
            let params={
                uid: getProfile().id,
                title: this.state.name,
                thumbname: uploadedFiles[0].filename,
                actimg:uploadedFiles[0].filename,
                res_type: uploadedFiles[0].res_type,
                filesize: isNaN(uploadedFiles[0].filesize.toString())?(uploadedFiles[0].filesize.toString()):formatSizeUnits(uploadedFiles[0].filesize.toString()),
                filetype: uploadedFiles[0].filetype,
                f_type: getFileExtension(uploadedFiles[0].filename),
                tagsid: tagids,
                tagsname: tagnames,
                galleryid:galleryid
            }
            this.props.createResource(params);
            var action = this.state.resourceId === 0 ? 'create':'edit';
            let trackparams={
                "module": "Resources",
                "page": window.location.pathname,
                "action": action,
                "browser": window.navigator.userAgent,
                "uid": getProfile().id,
            }
            this.props.activityTracking(trackparams)
        }


    }
    resultDataList( result, reference) {
        this.setState({ [reference] : result });
    }
    getTaglist(search){
        let params={
            search:search?search:'',
            category:"resources"
        }
        this.props.getTaglist(params);
    }
    removeFile(){
        this.setState({file:[]})
    }
    render() {
        let acceptedfiles = this.props.secured === true ? "video/mp4" : "image/jpeg,image/png,image/gif,image/bmp,\.ico,\.svg,image/webp,application/pdf,\.docx,\.doc,\.xls,\.xlsx,\.csv,\.ppt,\.pptx,video/mp4,audio/mp3,audio/wav,audio/ogg";
        let file = this.state.file[0]||[];
        return (
            <div className="task-content-block">
                {(this.state.loading === 1  || this.props.resourceInfo.loading) &&
                <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                }
                    <div id={`progress-holder`} className={'upload-pr-wrap'}></div>
                    <div className="persitant-top">
                    <div className="upload-drag-wrapper">
                        {file.length === 0 &&
                            <UploadFile
                                AutoUpdate={true}
                                getUploadedFile={this.handleFile}
                                multipleFiles={false}
                                overLay={true}
                                dropZone={true}
                                wrapperClass=""
                                progressBar={true}
                                progressContainer={`progress-holder`}
                                validationError={this.state.fileError}
                                acceptedFiles={acceptedfiles}
                                secureMode={this.props.secured}

                            />
                        }
                        {file.length !== 0 &&
                        <section className="drop-cont">
                            <aside>
                                <div className="file-upload-lister">
                                    <div id="attachement-panel" className="attach-chat-block">
                                        <button className="attach-close-pop" onClick={(e)=>this.removeFile()}></button>
                                        <div className="attachment-block-left non-download"><span className= {"format " + getFileExtension(file.filename)}
                                                                                                  aria-hidden="true">{getFileExtension(file.filename)}</span>
                                        </div>
                                        <div className="attachment-block-right">
                                            <h5>{file.filetitle}</h5>
                                            <p>{file.filesize}&nbsp;&nbsp;<span className="format-span">{getFileExtension(file.filename)} </span></p>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </section>
                            }
                        <div className="hint-area">
                            <p className="hint-label marg-15">*Supported Formats</p>
                            <div className="hover-toggle">
                                <ul>
                                    {!SECURED &&
                                    <React.Fragment>
                                        <li>
                                            <p><span className="hint-image">Image</span> - jpg,png,gif,bmp,ico,svg,webp</p>
                                        </li>
                                        <li>
                                            <p><span className="hint-doc">Document</span> - pdf,doc,docx,xls,xlsx,csv,ppt
                                            </p>
                                        </li>
                                        <li>
                                            <p><span className="hint-video">Video</span> - mp4</p>
                                        </li>
                                        <li>
                                            <p><span className="hint-audio">Audio</span> - mp3,wav,ogg</p>
                                        </li>
                                    </React.Fragment>
                                    }
                                    {SECURED &&
                                    <React.Fragment>
                                        {this.props.secured === true &&
                                        <React.Fragment>
                                            <li>
                                                <p><span className="hint-video">Video</span> - mp4</p>
                                            </li>
                                        </React.Fragment>
                                        }
                                        {this.props.secured === false &&
                                        <React.Fragment>
                                            <li>
                                                <p><span className="hint-image">Image</span> - jpg,png,gif,bmp,ico,svg,webp</p>
                                            </li>
                                            <li>
                                                <p><span className="hint-doc">Document</span> - pdf,doc,docx,xls,xlsx,csv,ppt
                                                </p>
                                            </li>
                                            <li>
                                                <p><span className="hint-video">Video</span> - mp4</p>
                                            </li>
                                            <li>
                                                <p><span className="hint-audio">Audio</span> - mp3,wav,ogg</p>
                                            </li>
                                        </React.Fragment>
                                        }
                                    </React.Fragment>
                                    }
                                </ul>
                            </div>
                        </div>                       
                    </div>
                    <div className={`new-task-input ${this.state.nameError ? "val-error":""}`}>
                        <input type="text" id="name" name="name" value = {this.state.name} placeholder="Resource Name" onChange={this.handleinputChange} autoComplete={'off'}/>
                    </div>
                    <div className="task-inp-container empty-label">
                        <AutoComplete
                            eleClass = 'selectize-custom'
                            fieldTitle = ' '
                            placeholder = 'Select Tags'
                            sourceDataList = {this.props.listtags.Resourcetags}
                            searchField = 'tag_name'
                            showIcon={false}
                            showSubLabel = {false}
                            labelFields = 'tag_name'
                            valueField ='id'
                            subLabelField = 'Tags'
                            showProfile = {false}
                            reference='selectedTags'
                            selectedItems = {this.state.selectedTags}
                            resultDataList = {this.resultDataList.bind(this)}
                            id="selected_Tags"
                            instantSearch={true}
                            actionMethod={this.getTaglist}
                            addNewSuggestion={true}
                        />
                    </div>
                       <p className="hint-label marg-15">Hint: For new tags - Type and Enter</p>
                </div>
                <div className="persitant-footer">
                    <div className="group-row">

                    </div>
                    <button data-masterid="0" data-mode="save" type="button" className="general-btn"
                            onClick={(e) => this.createResource(e)}
                            id="createpage">{this.state.resourceId === 0 ? 'Create' : 'Update'}
                    </button>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    listtags:state.resourcesReducer.listResourcetags,
    resourceInfo:state.resourcesReducer.getResourceInfo
});
export default  withRouter(connect(mapStateToProps, {
    getTaglist:ResourcesActions.listallresourcetags,
    createResource:ResourcesActions.saveGallery,
    activityTracking:rootActions.commonActions.activityTracking
})(AddNewResources));



