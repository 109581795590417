import config from './config.json';
import {getSubdomain} from "../utilities/CustomFunctions"
var DATA;

if(!getSubdomain(window.location.hostname))
    DATA=config.localhost
else
    DATA=config[getSubdomain(window.location.hostname)];

export const ES_INDEX=DATA.ESINDEX
export const API_PATH=DATA.APIPATH
export const ONE_TO_ONE_SOCKET_URL=DATA.ONETOONESOCKETURL
export const USRIMG_PATH=DATA.USRIMGPATH
export const FRMSIMG_PATH=DATA.USRIMGPATH
export const FRMSVID_PATH=DATA.FRMSVIDPATH
export const FRMSDOC_PATH=DATA.FRMSDOCPATH
export const FRMSAUDIO_PATH=DATA.FRMSAUDIOPATH
export const DISCUSSION=DATA.DISCUSSION
export const NEW_VERSION=DATA.NEWVERSION

export const S3_URL=DATA.S3URL
export const FRMSPDFDOC_PATH='https://docs.google.com/viewer?url=https://d22znjc9y9upyw.cloudfront.net/documents/'
export const TINY_API_KEY = 'dxerzt7m9dzc8dfvrlkoc11mtz2ymklrkzthc9sfwbwqf7yp';
export const BUCKET_CONFIG = DATA.BUCKETCONFIG
export const SECURED = DATA.SECURED
export const BUCKET_CONFIG_SECURED = DATA.SECURED? DATA.BUCKETCONFIGSECURED: DATA.BUCKETCONFIG
export const AUTHENTICATION = DATA.AUTHENTICATION
export const SHARED_LOGIN={
    status:DATA.SHARED_LOGIN,
    subdomain:DATA.ESINDEX+'.bsynapse.com',
    redirect_url:DATA.REDIRECTURL,
    cookie:DATA.ESINDEX,
    es_index:DATA.ESINDEX,
    events:DATA.EVENTS,
    CISERVICE:DATA.CISERVICE,
    CISERVICES:DATA.CISERVICES,
    TROVAENABLE: DATA.TROVAENABLE,
}


