import React,{useState} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import configureStore from './configureStore';
import './App.css';
import Header from './components/pages/Header'
import Dashboard from './components/pages/Dashboard'
import WorkFlow from './components/pages/WorkFlow'
import ToDo from './components/pages/task/ToDo'
import ToFollowUp from './components/pages/task/ToFollowUp'
import DraftTasks from './components/pages/task/DraftTasks'
import ScheduleTasks from './components/pages/task/ScheduleTasks'
import ToComment from './components/pages/task/ToComment'
import ToKnow from './components/pages/task/ToKnow'
import ToOverSee from './components/pages/task/ToOverSee'
import DueToday from './components/pages/task/DueToday'
import DueTomorrow from './components/pages/task/DueTomorrow'
import DueThisMonth from './components/pages/task/DueThisMonth'
import DueInSevenDays from './components/pages/task/DueInSevenDays'
import AllOverDues from './components/pages/task/AllOverDues'
import SevenToFourteenDays from "./components/pages/task/SevenToFourteenDays";
import OneToSevenDays from "./components/pages/task/OneToSevenDays";
import FifteenToThirtyDays from "./components/pages/task/FifteenToThirtyDays";
import ThirtyToSixtyDays from "./components/pages/task/ThirtyToSixtyDays"
import ToVerify from './components/pages/task/ToVerify';
import PoolToDo from './components/pages/PoolToDo'
import Archive from './components/pages/Archive'
import Login from './components/pages/Login'
import SideBar from './components/pages/sidebar/SideBar'
import Users from "./components/pages/team/Users";
import Project from "./components/pages/project/Project";
import Resources from "./components/pages/resources/Resources";
import Forms from "./components/pages/Forms";
import Collections from "./components/pages/collections/Collections";
import CollectionDetails from "./components/pages/collections/CollectionDetails";
import Events from "./socket/Events";
import EventStream from "./events/Events";
import UserDetails from "./components/pages/team/UserDetails";
import UserGroup from "./components/pages/team/UserGroup";
import UserGroupDetails from "./components/pages/team/UserGroupDetails";
import RoleSettings from './components/pages/team/RoleSettings';
import RoleModulePermission from './components/pages/team/RoleModulePermission';
import DetailContainer from "./components/pages/DetailContainer";
import TaskDetails from "./components/pages/task/TaskDetails";
import DetailContaineNew from "./components/pages/DetailContainerNew";
import UserDetailsHtml from "./components/pages/UserDetailsHtml";
import MainChat from "./components/pages/chat/MainChat";
import CustomFields from "./components/views/CustomFields";
import WorkflowDetails from "./components/pages/WorkflowDetails";
import ProjectOverview from "./components/pages/project/ProjectOverview";
import ProjectDashboard from "./components/pages/ProjectDashboard";
import ProjectDashboards from "./components/pages/ProjectDashboards";
import ProjectTeam from "./components/pages/project/ProjectTeam";
import ProjectFiles from "./components/pages/project/ProjectFiles";
import ProjectBoard from "./components/pages/project/ProjectBoard";
import ProjectNotes from "./components/pages/project/ProjectNotes";
import ProjectDiscussions from "./components/pages/project/ProjectDiscussions";
import ProjectDiscussionsDetail from "./components/pages/project/ProjectDiscussionsDetail";
import ProjectTasks from "./components/pages/project/ProjectTasks";
import ProjectInformation from "./components/pages/project/ProjectInformation";
import ProjectCollections from "./components/pages/project/ProjectCollections";
import ProjectDataCollection from "./components/pages/project/ProjectDataCollection";
import ProjectCollectionDetails from "./components/pages/project/ProjectCollectionDetails";
import ProjectCycle from "./components/pages/project/ProjectCycle";
import ProjectImpact from "./components/pages/project/ProjectImpact";
import ProjectFinancial from "./components/pages/project/ProjectFinancial";
import ProjectIOTCollection from "./components/pages/project/ProjectIOTCollection";
import ProjectInsights from "./components/pages/project/ProjectInsights";
import ProjectCommunity from "./components/pages/project/ProjectCommunity";
import ProjectNewsMedia from "./components/pages/project/ProjectNewsMedia";
import ProjectSettings from "./components/pages/project/ProjectSettings";
import TestPage from "./components/pages/PdfBuilder/PdfBuilder";
import io from 'socket.io-client';
import {SHARED_LOGIN,ONE_TO_ONE_SOCKET_URL} from "./constants";
import {getProfile, loggedIn} from './utilities/AuthService';
import {Detector, Offline, Online } from "react-detect-offline";
import Vertical from "./components/pages/vertical/Vertical";
import Sector from "./components/pages/sector/Sector";
import ProjectNotification from "./components/pages/project/ProjectNotification";
import ProjectStakeholder from "./components/pages/project/ProjectStakeholders";
import Phases from "./components/pages/phase/Phases"
import Roles from "./components/pages/role/Roles";
import DashboardBuilder from "./components/dashboardBuilder/DashboardBuilder";
import CustomDateRange from "./components/pages/task/CustomDateRange";
import PrintApp  from "./components/pages/printApp";
import ProjectChat from "./components/pages/project/ProjectChat";
import ToRespond from  './components/pages/task/ToRespond'
import Entity from  './components/pages/entity/Entity'
import EntityDetails from  './components/pages/entity/EntityDetails'

import PrintWFCollection from './components/pages/PrintWFCollection'
import DetailContainerPrint from './components/pages/DetailContainerPrint'

import FormDataModal from "./components/pages/FormDataModal";
import SharingFormModal from "./components/pages/SharingFormModal";
import ShowFormModal from './components/views/ShowFormModal'
import TaskFormIosModal from "./components/pages/TaskFormIosModal";
import WorkflowContainerNew from "./components/pages/workflow-task-ios/WorkflowContainerNew";



//const TaskDetailsMedMigrate = React.lazy(() =>  import('./components/pages/medmigrate/TaskDetailsMedMigrate'))
const ExternalFormWrapper = React.lazy(() =>  import( "./components/pages/ExternalFormWrapper"));
const LearnTaskWrapper = React.lazy( () => import("./components/webviews/LearnTaskWrapper"));

const PrintTemplateList = React.lazy(() =>  import('./components/pages/print/PrintTemplateList'))
const PrintSettings = React.lazy(() =>  import('./components/pages/print/Settings'))
const PrintView = React.lazy(() =>  import('./components/pages/print/ViewSettings'))


const store = configureStore();
var socket = io(ONE_TO_ONE_SOCKET_URL,{ transports: ["websocket"] });
var eventSource;
var online=true;
if (loggedIn()) {
    const url = 'https://events.bsynapse.com/hub?topic='+SHARED_LOGIN.events+'/' + getProfile().id;
     eventSource = new EventSource(url);

}



const LoginLayout = ({children, ...rest}) => {
  return (
      <div className="main-wrapper">


            {children}
      </div>
  );
};
const MainLayout = ({children},...rest) => {
    const [online, setCount] = useState(true);

    eventSource.onerror = function(err) {
        setCount(false)

    };
    eventSource.onopen = function(err) {
        setCount(true)

    };
    return (
        <div className="main-wrapper">
            <section className="content-area ibt">
              <SideBar socket={socket} />
                <div className="right-content-wrapper">
                            <>
                                {/*!online &&
                                <div className="normal active">
                                    <p>Connection lost or Technical Error </p>
                                </div>
                            */}
                            </>

                    <Header socket={socket} />
                    <Events socket={socket} />
                    <EventStream events={eventSource}/>
                   {children}
                </div>
            </section>
        </div>
    );
};
const NoLayout = ({children, ...rest}) => {
  return (
      <div className="main-wrapper-no-layout">
        {children}
      </div>
  )
};
const MainLayoutRoute = ({component: Component, ...rest }) => {

    if (!loggedIn()) {
        if (!SHARED_LOGIN.status) {
            return <Redirect to='/login'/>
        } else {
            window.location.replace(SHARED_LOGIN.redirect_url+'login')

        }
    }

    return (
        <Route {...rest}  render={matchProps => (
            <MainLayout >
                <Component {...matchProps}  socket={socket} events={eventSource}/>
            </MainLayout>
        )}/>
    );
};
const LoginLayoutRoute = ({component: Component, ...rest}) => {


    if (loggedIn()) {
        if (!SHARED_LOGIN.status) {
            return <Redirect to='/'/>
        } else {
            return <Redirect to='/chat'/>

        }
    }

  return (
      <Route {...rest} render={matchProps => (
          <LoginLayout>
            <Component {...matchProps} />
          </LoginLayout>
      )}/>
  );
};
const NoLayoutRoute = ({component: Component, ...rest}) => {

  return (
      <Route {...rest} render={matchProps => (
          <NoLayout>
            <Component {...matchProps} />
          </NoLayout>
      )}/>
  )
};
function App() {
  return (
      <Provider store={store}>
          <Router>
            <Switch>
                <LoginLayoutRoute exact path="/login"  component={Login}/>
                <LoginLayoutRoute exact path="/login/signup"  component={Login}/>
                <MainLayoutRoute exact path="/"  component={Dashboard}/>
                <MainLayoutRoute exact path="/task/todo"  component={ToDo}/>
                <MainLayoutRoute exact path="/task/to-follow-up"  component={ToFollowUp}/>
                <MainLayoutRoute exact path="/task/to-verify"  component={ToVerify}/>
                <MainLayoutRoute exact path="/task/to-comment"  component={ToComment}/>
                <MainLayoutRoute exact path="/task/to-know"  component={ToKnow}/>
                <MainLayoutRoute exact path="/task/to-respond"  component={ToRespond}/>
                <MainLayoutRoute exact path="/task/to-oversee"  component={ToOverSee}/>
                <MainLayoutRoute exact path="/task/drafts"  component={DraftTasks}/>
                <MainLayoutRoute exact path="/task/schedule"  component={ScheduleTasks}/>
                <MainLayoutRoute exact path="/task/due-today"  component={DueToday}/>
                <MainLayoutRoute exact path="/task/due-tomorrow"  component={DueTomorrow}/>
                <MainLayoutRoute exact path="/task/due-this-month"  component={DueThisMonth}/>
                <MainLayoutRoute exact path="/task/due-in-seven-days"  component={DueInSevenDays}/>
                <MainLayoutRoute exact path="/task/all-overdues"  component={AllOverDues}/>
                <MainLayoutRoute exact path="/due/one-to-seven-days"  component={OneToSevenDays}/>
                <MainLayoutRoute exact path="/due/seven-to-fourteen-days"  component={SevenToFourteenDays}/>
                <MainLayoutRoute exact path="/due/fifteen-to-thirty-days"  component={FifteenToThirtyDays}/>
                <MainLayoutRoute exact path="/due/thirty-to-sixty-days"  component={ThirtyToSixtyDays}/>
                <MainLayoutRoute exact path="/overdue/customdaterange/"  component={CustomDateRange}/>
                <MainLayoutRoute exact path="/overdue/customdaterange/:startdate/:enddate"  component={CustomDateRange}/>
                <MainLayoutRoute exact path="/task/archive"  component={Archive}/>
                <MainLayoutRoute exact path="/task/pool/:id"  component={PoolToDo}/>
                <MainLayoutRoute exact path="/team"  component={Users}/>
                <MainLayoutRoute exact path="/team/details/:id"  component={UserDetails}/>
                <MainLayoutRoute exact path="/team/rolesettings"  component={RoleSettings}/>
                <MainLayoutRoute exact path="/team/rolemodulepermission"  component={RoleModulePermission}/>
                <MainLayoutRoute exact path="/group"  component={UserGroup}/>
                <MainLayoutRoute exact path="/group/details/:id"  component={UserGroupDetails}/>
                <MainLayoutRoute exact path="/project"  component={Project}/>
                <MainLayoutRoute exact path="/project/my_projects"  component={Project}/>
                <MainLayoutRoute exact path="/project/shared_projects"  component={Project}/>
                <MainLayoutRoute exact path="/resources"  component={Resources}/>
                <MainLayoutRoute exact path="/forms"  component={Forms}/>
                <MainLayoutRoute exact path="/collections"  component={Collections}/>
                <MainLayoutRoute exact path="/collections/details/:id"  component={CollectionDetails}/>
                <MainLayoutRoute exact path="/workflow"  component={WorkFlow}/>
                <MainLayoutRoute exact path="/user/details/:id"  component={UserDetails}/>
                <MainLayoutRoute exact path="/task/details/:id/:id"  component={DetailContaineNew}/>
                <NoLayoutRoute   exact path="/home" component={Login}/>
                <MainLayoutRoute exact path="/userdetails/"  component={UserDetailsHtml}/>
                <MainLayoutRoute exact path="/chat"  component={MainChat}/>
                <MainLayoutRoute exact path="/chat/:userDetails"  component={MainChat}/>
                <MainLayoutRoute exact path="/workflow/details/:id"  component={WorkflowDetails}/>
                <MainLayoutRoute exact path="/customfields"  component={CustomFields}/>
                <MainLayoutRoute exact path="/project/details/overview/:id"  component={ProjectOverview}/>
                <MainLayoutRoute exact path="/project/details/team/:id"  component={ProjectTeam}/>
                <MainLayoutRoute exact path="/project/details/discussions/:id"  component={ProjectDiscussions}/>
                <MainLayoutRoute exact path="/project/details/discussions/details/:id/:id"  component={ProjectDiscussionsDetail}/>
                <MainLayoutRoute exact path="/project/details/tasks/:id/:id"  component={ProjectTasks}/>
                <MainLayoutRoute exact path="/project/details/files/:id"  component={ProjectFiles}/>
                <MainLayoutRoute exact path="/project/details/board/:id"  component={ProjectBoard}/>
                <MainLayoutRoute exact path="/project/details/notes/:id"  component={ProjectNotes}/>
                <MainLayoutRoute exact path="/project/details/information/:id"  component={ProjectInformation}/>
                <MainLayoutRoute exact path="/project/details/milestone/:id"  component={ProjectOverview}/>

                <MainLayoutRoute exact path="/vertical"  component={Vertical}/>
                <MainLayoutRoute exact path="/sector"  component={Sector}/>
                <MainLayoutRoute exact path="/project/details/collectionsold/:id"  component={ProjectCollections}/>
                <MainLayoutRoute exact path="/project/details/collectiondetails/:id/:id"  component={CollectionDetails}/>
                <MainLayoutRoute exact path="/project/details/management/:id"  component={ProjectCycle}/>
                {/*<MainLayoutRoute exact path="/project/details/impact/:id"  component={ProjectImpact}/>*/}
                {/*<MainLayoutRoute exact path="/project/details/financial/:id"  component={ProjectFinancial}/>*/}
                <MainLayoutRoute exact path="/project/details/insights/:id"  component={ProjectInsights}/>
                <MainLayoutRoute exact path="/project/details/newsmedia/:id"  component={ProjectNewsMedia}/>
                <MainLayoutRoute exact path="/project/details/community/:id"  component={ProjectCommunity}/>
                <MainLayoutRoute exact path="/project/details/notification/:id"  component={ProjectNotification}/>
                <MainLayoutRoute exact path="/project/details/settings/:id"  component={ProjectSettings}/>
                <MainLayoutRoute exact path="/project/details/stakeholders/:id/:id"  component={ProjectStakeholder}/>
                <MainLayoutRoute exact path="/project/details/collections/:id"  component={ProjectDataCollection}/>
                <MainLayoutRoute exact path="/project/details/iotcollections/:id"  component={ProjectDataCollection}/>

                <MainLayoutRoute exact path="/project/details/chat/:id"  component={ProjectChat}/>
                <MainLayoutRoute exact path="/settings/phases"  component={Phases}/>
                <MainLayoutRoute exact path="/settings/roles"  component={Roles}/>
                <MainLayoutRoute exact path="/dashboard/builder/:type/:projectid"  component={DashboardBuilder}/>
                <MainLayoutRoute exact path="/projects/details/:type/:id"  component={ProjectDashboard}/>
                <MainLayoutRoute exact path="/project/details/:type/:id"  component={ProjectDashboards}/>
                <NoLayoutRoute exact path="/task/print/:id/:id"  component={PrintApp}/>
                <MainLayoutRoute exact path="/task/details-print/:id/:id"  component={DetailContainerPrint}/>
                <NoLayoutRoute exact path="/workflow/print/:id/:id"  component={PrintApp}/>
                <NoLayoutRoute exact path="/collections/print/:id/:id"  component={PrintWFCollection}/>
                <NoLayoutRoute exact path="/entity/print/:id/:id"  component={PrintWFCollection}/>
                <MainLayoutRoute exact path="/filemanager"  component={TestPage}/>

                <MainLayoutRoute exact path="/entity"  component={Entity}/>
                <MainLayoutRoute exact path="/entity/details/:id"  component={EntityDetails}/>

                <NoLayoutRoute path="/embed/form/:id"  component={ShowFormModal}/>
                <NoLayoutRoute exact path="/embedform/:id/:uid/:utype/:dataid"  component={FormDataModal}/>
                <NoLayoutRoute exact path="/embedform/:id/:uid/:utype/:dataid"  component={FormDataModal}/>
                <NoLayoutRoute exact path="/quickform/:id/:uid/:utype/:dataid"  component={FormDataModal}/>
                <NoLayoutRoute path="/quickform/:id/:formid"  component={ShowFormModal}/>
                {/*<NoLayoutRoute exact path="/embed/form/:id/:uid"  component={TaskDetailsMedMigrate}/>*/}
                <NoLayoutRoute exact path="/externaltask/details/:id/:mode/:uid/:usertype"  component={WorkflowContainerNew}/>
                <NoLayoutRoute exact path="/workflowios/details/:id/:id/:userid/:usertype"
                               component={WorkflowContainerNew}/>
                <NoLayoutRoute exact path="/formdataios/:id/:uid/:utype"  component={FormDataModal}/>
                <NoLayoutRoute exact path="/formdataios/:id/:uid/:utype/:dataid"  component={FormDataModal}/>
                <NoLayoutRoute exact path="/taskformios/:taskid/:id/:uid/:utype"  component={TaskFormIosModal}/>
                <NoLayoutRoute exact path="/taskformios/:taskid/:id/:uid/:utype/:dataid"  component={TaskFormIosModal}/>
                <NoLayoutRoute exact path="/record/:cid/:dataid/:index/:code"  component={ExternalFormWrapper}/>
                <NoLayoutRoute exact path="/learntask/details/:taskid/:mode/:uid/:utype"  component={LearnTaskWrapper}/>
                <NoLayoutRoute exact path="/sharing/form/:id"  component={SharingFormModal}/>



                <MainLayoutRoute exact path="/print"  component={PrintTemplateList}/>
                <MainLayoutRoute exact path="/print/settings/:template/:id/:pid"  component={PrintSettings}/>
                <MainLayoutRoute exact path="/print/view/:template/:id/:recid/:pid"  component={PrintView}/>

            </Switch>
          </Router>
      </Provider>
);
}

export default App;
