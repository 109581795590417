import React, {Component} from 'react';
import {connect} from "react-redux";
import GroupDetails from "../pages/GroupDetails"
import LogoutPanel from "../pages/LogoutPanel"
import * as taskActions from "../../actions/ToDo"
import {withRouter} from 'react-router';
import * as commonActions from "../../actions/Common";
import * as projectActions from "../../actions/Project";
import * as formActions from "../../actions/Forms";
import * as collectionActions from "../../actions/Collections";
import {getProfile} from "../../utilities/AuthService";
import {ES_INDEX, SHARED_LOGIN,USRIMG_PATH} from '../../constants/index'
import ToasterNotification from "../views/ToasterNotification"
import SimpleModal from "../views/SimpleModal";
import {AddMembersToGroup} from "../pages/FormsData";
import notificationSound from "../views/quite-impressed.mp3"
import * as ChatActions from "../../actions/Chat";
import LoaderSvg from "../views/LoaderSVG"
import rootActions from "../../actions";
import NotificationPanel from "../pages/NotificationPanel";
import * as usersActions from "../../actions/Users";
import HelpPanel from "./HelpPanel";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import HeaderSearch from "./task/Search/HeaderSearch"
// import ReportIssue from "./ReportIssue";
import OutsideAlerter from "../views/OutsideAlerter";
import moment from 'moment';

class Header extends Component {

    constructor(props) {
        super(props);
        this.searchData = this.searchData.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.modalChange = this.modalChange.bind(this)
        this.sidePanel = this.sidePanel.bind(this)
        this.LogOut = this.LogOut.bind(this)
        this.mobToggle = this.mobToggle.bind(this)
        this.chatHistory = this.chatHistory.bind(this)
        this.updateDimensions = this.updateDimensions.bind(this);
        this.notifyClose = this.notifyClose.bind(this);
        this.helpClose = this.helpClose.bind(this);
        this.goBack = this.goBack.bind(this);
        this.helpReport=this.helpReport.bind(this);
        this.mobFilterToggle = this.mobFilterToggle.bind(this);
        this.mobSearchToggle = this.mobSearchToggle.bind(this);
        this.checkClose = this.checkClose.bind(this);
        this.profileUpdate=this.profileUpdate.bind(this)
        this.profilepicUpdate=this.profilepicUpdate.bind(this)
        this.savePlatformHits=this.savePlatformHits.bind(this)

        this.state = {
            msg: '',
            status: false,
            modalStatus: false,
            panel: false,
            comp: '',
            mobToggle: false,
            headline: '',
            component : '',
            sidePanelShow: false,
            ReportShow: false,
            activesearch : false,
        }
    }

    componentDidMount() {
        this.savePlatformHits();
        let userdata = {
            uid: getProfile().id
        }
        this.props.listusersdetail(getProfile().id);
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        if(this.props.NotificationData.NotificationInfo.data !=='') {
            let that=this
            setTimeout(function () {
                that.props.notificationdata(userdata);
            }, 200)
        }

       let params={
            "uid":getProfile().id
        }
         let  that=this
        function countCrossCheck() {
            that.props.tasksidebarcount(params);
        }
        setInterval(countCrossCheck, 600000);

    }

    updateDimensions() {
        this.props.updateDimensions(window.innerHeight, window.innerWidth)

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
       if(prevProps.SidebarMenudata.sidebarcount  !== this.props.SidebarMenudata.sidebarcount){

           ////console.log(prevProps.SidebarMenudata.todo)
           ////console.log(this.props.SidebarMenudata.sidebarcount.todo)
         if(prevProps.SidebarMenudata.todo !== this.props.SidebarMenudata.sidebarcount.todo){
             ////console.log(prevProps.SidebarMenudata.todo)
             ////console.log(this.props.SidebarMenudata.sidebarcount.todo)
             var data = {category : 'to-me', status : "All" , uid : getProfile().id, bydue : '',  priority : "All", project :"All", asigntype : 'All', managetask : 'mytask', limit : 0, offset  : 30, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
             this.props.toFollowUp(data);
         }
           // if(prevProps.SidebarMenudata.archive !== this.props.SidebarMenudata.sidebarcount.archive){
           //     var data = {category : 'archive', status : "All" , uid : getProfile().id, bydue : '',  priority : "All", project :"All", asigntype : 'All', managetask : 'mytask', limit : 0, offset  : 30, systype : getProfile().usertype,  actionType : 'LIST_TO_ALL_ARCHIVED_DATA' }
           //     this.props.toFollowUp(data);
           // }
           if(prevProps.SidebarMenudata.comments !== this.props.SidebarMenudata.sidebarcount.comments){
               var data = {category : 'Comments', status : "All" , uid : getProfile().id, bydue : '',  priority : "All", project :"All", asigntype : 'All', managetask : 'mytask', limit : 0, offset  : 30, systype : getProfile().usertype,  actionType : 'LIST_TO_COMMENT_DATA' }
               this.props.toFollowUp(data);
           }
           if(prevProps.SidebarMenudata.toknow !== this.props.SidebarMenudata.sidebarcount.toknow){
               var data = {category : 'to-know', status : "All" , uid : getProfile().id, bydue : '',  priority : "All", project :"All", asigntype : 'All', managetask : 'mytask', limit : 0, offset  : 30, systype : getProfile().usertype,  actionType : 'LIST_TO_KNOW_DATA' }
               this.props.toFollowUp(data);
           }
           // if(prevProps.SidebarMenudata.tooversee !== this.props.SidebarMenudata.sidebarcount.oversee){
           //     var data = {category : 'manage-task', status : "All" , uid : getProfile().id, bydue : '',  priority : "All", project :"All", asigntype : 'All', managetask : 'mytask', limit : 0, offset  : 30, systype : getProfile().usertype,  actionType : 'LIST_TO_OVERSEE_DATA' }
           //     this.props.toFollowUp(data);
           // }


       }
    }
    searchFilterData(a,b,c,d,e) {
        this.props.searchType(a,b,c,d,e)
    }

    searchData(e) {

        if (this.props.location.pathname === "/team") {
            let filter= this.props.listUsersdata.searchParam
            let params = {
                "uid" : getProfile().id,
                "search" :e.target.value,
                "team":filter.team,
                "company":filter.company,
                "reportees":filter.reportees,
                "designation":filter.designation,
                "page": 1,
                "per_page": 100
            };

            this.searchFilterData('SEARCH_USERS_DATA',params,"user")
        }
        if (this.props.menuClickData.mainMenu === 'team' && this.props.menuClickData.subMenu === 'userpermissions') {
            let filter= this.props.listAllUserspermission.searchParam
            let params = {
                "userid" : getProfile().id,
                "search" :e.target.value,
                "team":filter.team,
                "company":filter.company,
                "reportees":filter.reportees,
                "designation":filter.designation,
                "page": 1,
                "per_page": 20
            };

            this.searchFilterData('SEARCH_USERPERMISSION_DATA', params,"userpermission")
        }
        if (this.props.menuClickData.mainMenu === 'group') {
            this.props.searchType('SEARCH_GROUP_DATA', e.target.value,"group")
        }
        if (this.props.menuClickData.mainMenu === 'project') {
            this.props.searchType('SEARCH_PROJECT_DATA', e.target.value,"projects")
        }
        if (this.props.menuClickData.mainMenu === 'collections') {
            this.props.searchType('SEARCH_COLLECTION_DATA', e.target.value, "collections", this.props.listAllCollections.Group,this.props.listAllCollections.managecollection)
        }
        if (this.props.menuClickData.mainMenu === 'entity' || this.props.menuClickData.mainMenu === 'services') {

            this.searchFilterData('SEARCH_ENTITY_DATA', e.target.value,"entity",this.props.EntityData.Group,this.props.EntityData.managecollection)
        }
        if (this.props.menuClickData.mainMenu === 'sector') {
            this.props.searchType('SEARCH_PORTFOLIO_DATA', e.target.value, "sector")
        }
        if (this.props.menuClickData.mainMenu === 'vertical') {
            this.props.searchType('SEARCH_PROGRAM_DATA', e.target.value,"vertical")
        }
        if (this.props.menuClickData.subMenu === '/settings/phases') {
            this.props.searchType('SEARCH_PHASE_DATA', e.target.value,"phases")
        }
        if (this.props.menuClickData.subMenu === '/settings/roles') {
            this.props.searchType('SEARCH_ROLE_DATA', e.target.value, "roles")
        }
        if (this.props.menuClickData.mainMenu === 'forms') {
           // this.props.forms(this.props.listAllFormsData.Group, e.target.value, getProfile().id, 'root', 0, "0", 20, "lsinternal")
        }
        // if (this.props.menuClickData.mainMenu === 'collections') {
        //     this.props.collections(this.props.listAllCollectionsData.Group, e.target.value, getProfile().id, 'root', 0, "0", 20, "lsinternal")
        // }
        if (this.props.menuClickData.mainMenu === 'task') {

            var status = "All"
            if(getUrlSegment(2)==='todo'){
                var category='to-me'
            }
            if(getUrlSegment(2)==='to-follow-up'){
                var category='by-me'
            }
            if(getUrlSegment(2)==='to-oversee'){
                var category='manage-task'
            }
            if(getUrlSegment(2)==='to-know'){
                var category='to-know'
            }
            if(getUrlSegment(2)==='to-comment'){
                var category='Comments'
            }
            if(getUrlSegment(2)==='to-verify'){
                var category='by-me'
                     status = "Done"
            }

            var data = {
                category : category,
                status : this.props.searchData.task.status !== ''?this.props.searchData.task.status:status,
                uid : getProfile().id,
                bydue : '',
                priority : this.props.searchData.task.priority,
                project :this.props.searchData.task.project,
                asigntype : '',
                username :'',
                managetask : 'mytask',
                srchval:e.target.value,
                limit : 0,
                offset  : 10,
                systype : getProfile().usertype,
            }

            this.props.searchType('SEARCH_TASK_DATA', e.target.value,"srchval",data)
        }
    }

    savePlatformHits(){
        var ip = require("ip");
        console.log(window.location);
        let param={
            ip: ip.address(),
            uid:getProfile().id,
            action:window.location.href,
            browser:navigator.userAgent,
            "index": ES_INDEX
        }
        this.props.savePlatformhits(param)
        let beta=this;
        let params={
            uid:getProfile().id,
            seconds: "300",
            "index": ES_INDEX
        }
        setTimeout(function () {
            beta.props.userTimespent(params)
        }, 300000)
    }
    LogOut() {
        this.props.logout(getProfile().cookie)
        this.setState({panel: '', component: ''})
    }

    handleClose() {
        this.setState({status: !this.state.status})
    }

    modalChange() {
        this.setState({modalStatus: !this.state.modalStatus})
    }

    sidePanel(panel, comp) {

        this.setState({panel: !panel, component: comp})

    }
    helpReport(ReportShow) {
        this.setState({ReportShow: ReportShow, panel : false, component: ''})
    }
    checkClose(ReportShow){
        this.setState({ReportShow: ReportShow})
    }
    notifyClose(){
        this.setState({panel : false, component: ''})
    }
    helpClose(){
        this.setState({panel : false, component: ''})
    }
    mobToggle() {
        this.props.mobiletogle(!this.props.mobToggle.toggle)
    }

    chatHistory(uid, user_id, limit, offset, index, type, name, designation, image) {
        this.props.chatHistory(uid, user_id, limit, offset, index, type, name, designation, image)
        if (type === 'group') {
            this.props.groupInfo(user_id, index)
            this.props.allGroups(getProfile().id, 0, 500, ES_INDEX);
        }

    }
    goBack() {
      if((getUrlSegment(5) !==undefined )|| getUrlSegment(6) !==undefined) {
          if (atob(getUrlSegment(5) !== "project")) {
              window.location.href = SHARED_LOGIN.redirect_url + "Project/details/" + atob(getUrlSegment(6)) + "#proTask"
          }
          else {
              this.props.history.goBack();
          }
      }
      else if( (getUrlSegment(1) === "task") && (getUrlSegment(2) === "details") )  {
        //  this.props.history.push('/task/todo');
          this.props.history.goBack();
      }
        else{
        this.props.history.goBack();
      }
    }
    mobSearchToggle() {
        this.setState({ activesearch : !this.state.activesearch });
    }
    mobFilterToggle() {
        this.props.mobileFilterToggle(!this.props.mobToggle.filterToggle)
    }
    updatePassword(params){
       this.props.updatePassword(params)
    }
    profileUpdate(params){
        this.props.profileUpdate(params)
    }
    profilepicUpdate(userdate){
        this.props.profilepicUpdate(userdate)
    }

    render() {
        let countNotification = [];
        let urlParams = String(document.location).split('/');
        let subPage = urlParams.indexOf('details') > -1 ? true : false;
        let UserNotfctncount = this.props.NotificationData.aggs;
        let modDetails = getUrlSegment(1) + '/' + getUrlSegment(2);
        let hidCommnHdr = ['project/details', 'collections/details', 'entity/details','team/details'];
        let isDashboard = getUrlSegment(1) === '' || getUrlSegment(1) === undefined ? 1 : 0;
        return (
            <>
                { this.props.match.path !== '/workflow/details/:id' &&
                <div className = {hidCommnHdr.indexOf(modDetails) <= -1 ? 'top-sticky-bar' : 'top-sticky-bar hide-large'}>

                    {this.props.menuClickData.mainMenu === 'chat' &&
                    <div className={`mobile-header-hide`}>
                        {this.props.chatUserId.chatType !== '' &&
                        <div className="chat-profile mob-hide">
                            <div className="chat-profile-img userImage">
                                <img
                                    src={this.props.chatUserId.chatType === 'group' ? USRIMG_PATH + 'person_avatar.gif' : USRIMG_PATH + this.props.chatUserId.image}
                                    alt="User"/>
                            </div>
                            <div className="chat-profile-description">
                                <h5 className="userName">{this.props.chatUserId.chatType === 'single' ? this.props.chatUserId.name : atob(this.props.chatUserId.name)}</h5>
                                <p className="designation">
                                    {this.props.loader.loaderType === 'groupMembers' && this.props.loader.status ?
                                        <LoaderSvg/>
                                        :
                                        <>
                                            {this.props.chatUserId.chatType === 'group' &&
                                            <i className="fa fa-user-o" aria-hidden="true"></i>}
                                            {this.props.chatUserId.chatType === 'single' ?
                                                this.props.chatUserId.designation :
                                                (this.props.groupinfo.info.length !== 0 ? (
                                                ' ' + this.props.groupinfo.info[0].groupmembers.length + ' | ' + atob(this.props.groupinfo.info[0].groupdesc))
                                                    :
                                                    ""
                                                )}
                                        </>
                                    }

                                    </p>
                            </div>
                        </div>
                        }
                    </div>
                    }
                    {subPage === false && isDashboard === 0 ?
                        <>
                        {this.props.menuClickData.mainMenu !== 'chat' && getUrlSegment(1) !=="task" && getUrlSegment(1) !=="entity" && getUrlSegment(1) !=="collections" && getUrlSegment(2) !=="rolesettings" && getUrlSegment(2) !=="rolemodulepermission" && this.props.menuClickData.mainMenu !== 'team' && this.props.menuClickData.mainMenu !== 'group' &&
                        <div className={"search-block "+ (this.state.activesearch? ' active ': '')}>
                            <input type="text" name="srch_tval" id="srch_tval" className="search-box"
                                   placeholder={`Search ${this.props.menuClickData.mainMenu.charAt(0).toUpperCase() + this.props.menuClickData.mainMenu.slice(1)}`} onChange={this.searchData}/>
                            <span className="icon-in icon-search search-box-icon"> </span>
                            <button className="ico-lib mobile-toggle-search" onClick={()=>this.mobSearchToggle()}></button>
                        </div>
                        }
                            {getUrlSegment(1) ==="collections" &&
                            <div className={"search-block "+ (this.state.activesearch? ' active ': '')}>
                                <input type="text" name="srch_tval" id="srch_tval" className="search-box"
                                       defaultValue={this.props.listAllCollections.searchResult.Search}
                                       placeholder={`Search ${this.props.menuClickData.mainMenu.charAt(0).toUpperCase() + this.props.menuClickData.mainMenu.slice(1)}`}
                                       onChange={this.searchData} autoComplete={'off'}/>
                                <span className="icon-in icon-search search-box-icon"> </span>
                                <button className="ico-lib mobile-toggle-search" onClick={()=>this.mobSearchToggle()}></button>
                            </div>
                            }
                            {getUrlSegment(1) ==="entity" &&
                            <div className={"search-block "+ (this.state.activesearch? ' active ': '')}>
                                <input type="text" name="srch_tval" id="srch_tval" className="search-box"
                                       defaultValue={this.props.EntityData.searchResult.Search}
                                       placeholder={`Search ${this.props.menuClickData.mainMenu.charAt(0).toUpperCase() + this.props.menuClickData.mainMenu.slice(1)}`}
                                       onChange={this.searchData} autoComplete={'off'}/>
                                <span className="icon-in icon-search search-box-icon"> </span>
                                <button className="ico-lib mobile-toggle-search" onClick={()=>this.mobSearchToggle()}></button>
                            </div>
                            }

                            {getUrlSegment(1) === "task" &&
                            <HeaderSearch
                                state={this.state}
                                searchData={this.props.searchData}
                            />
                            }
                            {this.props.menuClickData.mainMenu == 'team' && getUrlSegment(2) !=="rolesettings" && getUrlSegment(2) !=="rolemodulepermission" &&
                            <div className={"search-block "+ (this.state.activesearch? ' active ': '')}>
                                <input type="text" name="srch_tval" id="srch_tval" className="search-box"
                                       placeholder={'Search User, Email etc...'}
                                       defaultValue={this.props.listUsers.searchParam.search}
                                       onChange={this.searchData} autoComplete={'off'}/>
                                <span className="icon-in icon-search search-box-icon"> </span>
                                <button className="ico-lib mobile-toggle-search" onClick={()=>this.mobSearchToggle()}></button>
                            </div>
                            }
                            {this.props.menuClickData.mainMenu == 'group' &&
                            <div className={"search-block "+ (this.state.activesearch? ' active ': '')}>
                                <input type="text" name="srch_tval" id="srch_tval" className="search-box"
                                       placeholder={'Search Group etc...'} onChange={this.searchData} autoComplete={'off'}/>
                                <span className="icon-in icon-search search-box-icon"> </span>
                                <button className="ico-lib mobile-toggle-search" onClick={()=>this.mobSearchToggle()}></button>
                            </div>
                            }
                        </>
                        :
                        <>
                        {isDashboard === 1 &&
                            <div className="calendar-today">
                                <h5>
                                    <span>{moment(new Date()).format('DD')} </span>
                                    {moment(new Date()).format('MMMM YYYY')}
                                </h5>
                            </div>
                        }
                        {isDashboard === 0 &&
                            <div className="task-nav">
                                <div className="task-nav-left bg-back">
                                    {this.props.loader.status &&
                                    <span className="Loader-holder">
                                       <LoaderSvg/>
                                    </span>
                                    }
                                    <button className="backto-list" onClick={this.goBack}>{this.props.headline.title || 'Back To Listing'}</button>
                                </div>
                            </div>
                        }
                        </>
                    }
                    <div className="user-othe-info">
                        <div className="user-info-click mob-area">
                            <div className="mobile-toggle">
                                <div onClick={this.mobToggle}
                                     className={`toggle-mnu hidden-lg ${this.props.mobToggle.toggle ? 'on' : ''}`}>
                                    <span></span></div>
                            </div>
                        </div>

                        <div className="user-info-click mob-area">
                            <span  className="ico-lib mob-search-ico" onClick={()=>this.mobSearchToggle()}> </span>
                        </div>
                        <div className="user-info-click mob-area">
                            <span  className="ico-lib mob-filter-ico" data-active="#fil-in" onClick={()=>this.mobFilterToggle()}> </span>
                        </div>
                        <div className={this.state.component === 'notification' ? 'user-info-click on-hover': 'user-info-click'} onClick={() => this.sidePanel(this.state.component, 'notification')} style={{'pointerEvents':this.state.panel?'none':''}} >
                        <div  className="ico-lib not-ico" data-active="#notf-in">
                            {UserNotfctncount.length !== 0 &&  UserNotfctncount.filter(list => list.key === 'unread').map((list,index) =>
                            <span className="not-count" key={'user-count'+index}>{list.doc_count}</span>
                            )}
                        </div>
                        </div>
                        {this.props.chatUserId.chatType === 'group' &&
                        <>
                            <div className="user-info-click  small-hide">
                             <span onClick={() => this.sidePanel(!this.state.component, 'groupDetails')}
                                   className="chat-com ico-lib info-ico" data-active="#info-in">
                             </span>
                            </div>
                            {((getProfile().systype === 'root') || (getProfile().systype === 'Superadmin' || getProfile().channel_permission === "1"
                                && (this.props.groupinfo.info.length !==0&&
                                this.props.groupinfo.info[0].creator === getProfile().id) )) &&
                            <div className="user-info-click small-hide">
                                <span onClick={this.modalChange} className="chat-com ico-lib add-us"
                                      data-active="#info-in"></span>
                            </div>
                            }
                        </>
                        }

                        <div className="user-info-click" onClick={() => this.sidePanel(this.state.component, 'logoutPanel')} style={{'pointerEvents':this.state.panel?'none':''}}>
                            <span  className="user-prof">
                                <img src={USRIMG_PATH + getProfile().image}
                                     alt="User"/>
                            </span>
                        </div>
                        <div className={this.state.component === 'help' ? 'user-info-click on-hover': 'user-info-click'} onClick={() => this.sidePanel(this.state.component, 'help')} style={{'pointerEvents':this.state.panel?'none':''}}>
                            <span  className="ico-lib qa-ico" data-active="#notf-in"></span>
                        </div>

                    </div>
                </div>
                }
                {/*<ToasterNotification*/}
                    {/*msg={this.state.msg}*/}
                    {/*show={this.state.status}*/}
                    {/*handleClose={this.handleClose}*/}
                    {/*loadChatHistory={this.chatHistory}*/}
                {/*/>*/}
                {this.state.modalStatus === true &&
                <SimpleModal
                    Close={this.modalChange}
                    title={`Select Users`}
                >
                    <AddMembersToGroup
                        addMembersToGroup={this.props.addMembersToGroup}
                        allUsers={this.props.allusers.ListAllUsers}
                        groupData={this.props.chatUserId}
                        Close={this.modalChange}
                        socket={this.props.socket}
                    />
                </SimpleModal>
                }
                {this.state.panel && this.state.component !== '' &&
                <div className={`side-bar-panel`} key={`sdebar-group-details`}>

                    {this.state.component === 'groupDetails' &&

                    <GroupDetails
                        groupinfo={this.props.groupinfo}
                        groupDelete={this.props.groupDelete}
                        editGroupDesc={this.props.editGroupDesc}
                        editGroup={this.props.editGroup}
                        sidePanel={this.sidePanel}
                        removeUser={this.props.removeUser}

                    />
                    }

                    {this.state.component === 'logoutPanel' && this.state.panel &&
                    <OutsideAlerter
                        status={this.state.panel}
                        Triggered={()=>this.sidePanel(this.state.component,'')}
                    >
                    <LogoutPanel
                        sidePanel={this.sidePanel}
                        logOut={this.LogOut}
                        profileUpdate={this.profileUpdate}
                        profilepicUpdate={this.profilepicUpdate}
                    />
                    </OutsideAlerter>
                    }
                    {this.state.component === 'notification'  && this.state.panel &&
                    <OutsideAlerter
                        status={this.state.panel}
                        Triggered={()=>this.sidePanel(this.state.component,'')}
                    >
                    <NotificationPanel
                        sidePanel={this.sidePanel}
                        notifyClose={this.notifyClose}

                    />
                    </OutsideAlerter>
                    }
                    {this.state.component === 'help'  && this.state.panel &&
                    <OutsideAlerter
                        status={this.state.panel}
                        Triggered={()=>this.sidePanel(this.state.component,'')}
                    >
                    <HelpPanel
                        sidePanel={this.sidePanel}
                        helpClose={this.helpClose}
                        helpReported={this.helpReport}
                    />
                    </OutsideAlerter>
                    }

                </div>
                }
                {/*{this.state.ReportShow === true &&*/}
                {/*<ReportIssue*/}
                {/*    helpReport={this.helpReport}*/}

                {/*/>*/}
                {/*}*/}

                {this.state.ReportShow === true &&
                <div className='report-iframe'>
                    <div className='reprot-iframe-wrapper'>
                        <button type="button" data-dismiss="modal" aria-label="Close" className='close' onClick={() => this.checkClose(false)}></button>
                    <div contentEditable='true' className='reportissue-iframe' dangerouslySetInnerHTML={{ __html: "<iframe src=\"https://mathrubhumi.bsynapse.com/embed/form/mathrubhumi10008\" style=\"border:1px #E4E6E9 solid;\" name=\"bsynapseform\" scrolling=\"yes\" frameborder=\"1\" marginheight=\"0px\" marginwidth=\"1px\" height=\"100%\" width=\"100%\" allowfullscreen=\"\"></iframe>" }}>
                    </div>
                    </div>
                </div>
                }


            </>

        );
    }

}


const mapStateToProps = state => ({
    todoListing: state.TaskReducer.listtoDo,
    menuClickData: state.CommonReducer.menuClick,
    searchData: state.CommonReducer.searchparamdata,
    listAllFormsData: state.FormsReducer.listFormsdata,
    listAllCollectionsData: state.CollectionsReducer.listCollectionsdata,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    toasternotification: state.ChatReducer.toasterNotification,
    chatUserId: state.ChatReducer.chatUserId,
    allusers: state.ChatReducer.listallusers,
    groupinfo: state.ChatReducer.GroupInfo,
    EditGroup: state.ChatReducer.EditGroup,
    MembersStatus: state.ChatReducer.MembersStatus,
    mobToggle: state.CommonReducer.mobileTogle,
    loader: state.CommonReducer.loader,
    headline: state.CommonReducer.loadHeadline,
    NotificationData:state.UsersReducer.notificationInfodata,
    listUsersDetaildata:state.UsersReducer.listUsersDetaildata,
    SidebarMenudata:state.TaskReducer.SidebarMenudata,
    EntityData:state.EntityReducer.GetEntity,
    listUsers:state.UsersReducer.listAllUsers,
    listUsersdata:state.UsersReducer.listAllUsers,

});
export default withRouter(connect(mapStateToProps, {
    toFollowUp: taskActions.listtask,
    mainClick: rootActions.commonActions.MenuClick,
    searchType: rootActions.commonActions.searchType,
    project: projectActions.listallprojects,
    forms: formActions.listallforms,
    collections: collectionActions.listallcollections,
    addMembersToGroup: rootActions.chatAction.addMembersToGroup,
    groupDelete: rootActions.chatAction.groupDelete,
    editGroup: rootActions.chatAction.editGroupDesc,
    groupInfo: rootActions.chatAction.groupInfo,
    allUsers: rootActions.chatAction.ListAllUsers,
    chatUsers: rootActions.chatAction.chatUsers,
    logout: rootActions.commonActions.logOut,
    updateDimensions: rootActions.commonActions.updateDimensions,
    allGroups: rootActions.chatAction.listAllGroups,
    removeUser: rootActions.chatAction.removeUser,
    mobiletogle: rootActions.commonActions.MobileToggle,
    mobileFilterToggle: rootActions.commonActions.mobileFilterToggle,
    chatHistory: rootActions.chatAction.listChatHistory,
    notificationdata:usersActions.notificationInfo,
    tasksidebarcount:taskActions.tasksidebarCount,
    listusersdetail:usersActions.listUsersDetail,
    profileUpdate:usersActions.profileUpdate,
    profilepicUpdate:usersActions.profilepicUpdate,
    savePlatformhits:usersActions.savePlatformhits,
    userTimespent:usersActions.userTimespent,
})(Header));


