import React, {Component} from 'react';
import NoData from "../../views/NoData";
import {ES_INDEX, FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH} from "../../../constants";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {Datetimecomparison, getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import ProjectNotesRight from "../../views/ProjectNotesRight";
import ProjectNotesLeft from "../../views/ProjectNotesLeft";
import ProjectHeader from "../../views/ProjectHeader";



class ProjectNotes extends Component {

    constructor(props) {
        super(props);

        this.state={
            ImgName : '',
            ImgPath : ''
        }

    }



    componentDidMount() {

        var prjctdtlid = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/notes/'+prjctdtlid);

    }


    componentDidUpdate(prevProps, prevState, snapshot) {


    }


    render() {

        return(
            <div className="form-detail-container data-view field-form">
                <ProjectHeader/>
                <div className="pj-board-container">
            <div className="note-list-wrapper">
                <ProjectNotesLeft/>
               <ProjectNotesRight/>


            </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,



})(ProjectNotes));
