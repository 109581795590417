import React, {Component} from 'react';
import {connect} from "react-redux";
import * as collectionActions from "../../../actions/Collections"
import {withRouter} from 'react-router';
import * as commonActions from "../../../actions/Common";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal"

import AddToDoTask from "../AddToDoTask"
import SimpleModal from "../../views/SimpleModal"
import AddNewForm from "./AddNewForm"
import {Accordion, Button, ButtonToolbar, Card} from "react-bootstrap";
import AutoComplete from "../../views/AutoComplete";
import {ES_INDEX, FRMSIMG_PATH} from '../../../constants'
import * as usersActions from "../../../actions/Users";
import LoaderSvg from "../../views/LoaderSVG";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import FormTile from "../../views/FormTile";

class ProjectCollections extends Component {

    constructor(props) {
        super(props);
        this.state= {
            optionOpened: 0,
            action: '',
            search: '',
            group: 'All',
            selectedCollection: {},
            selectedTags : [],
            selectedToUsers : [],
            tags: [],
            newTags:[],
            createNewForm:false,
            lastOffset: 0,
            totalitems: 0,
            collections: [],
            offset: 30,
            Collections:[],
            loading:0,

        }
        this.manageOptionsDropDown = this.manageOptionsDropDown.bind(this);
        this.updateAction = this.updateAction.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.shareCollection = this.shareCollection.bind(this);
        this.showCreateNewFormModal = this.showCreateNewFormModal.bind(this);
        this.closeCreateNewFormModal = this.closeCreateNewFormModal.bind(this);
        this.expandHeader = this.expandHeader.bind(this)

    }
    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }
    componentDidMount() {

        console.log(this.props.listAllCollections.ListAllCollections);

        var prjctdtlid = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/collections/' + prjctdtlid);

        let project_id = atob(prjctdtlid);

        let params = {group : this.state.group, search : this.state.search, uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : 0, offset : this.state.offset, project_id : project_id};
        this.props.collections(params);

        this.props.users(getProfile().id,'User','',ES_INDEX);

        const container = document.querySelector('.pj-collection-container');
        container.addEventListener('scroll', this.listenToScroll)
    }
    componentWillUnmount() {
       const container = document.querySelector('.pj-collection-container');
       container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.pj-collection-container')
        let lastOffset = document.querySelector('.content-main').childElementCount;
        console.log(lastOffset)
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;
        var prjctdtlid = getUrlSegment(4);
        let project_id = atob(prjctdtlid);
        if (contentHeight <= scrollTop) {
            if(this.props.listAllCollections.Search === '') {
                let params = {group : this.props.listAllCollections.Group || this.state.group, search : this.state.search, uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : lastOffset, offset : this.state.offset, project_id : project_id};
                this.props.updatecollections(params);
            } else {
                let params = {group : this.props.listAllCollections.Group || this.state.group, search : this.props.listAllCollections.Search, uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : lastOffset, offset : this.state.offset, project_id : project_id};
                this.props.moreSearchCollections(params)
            }

        }
    }
    updateAction(e, act, collection, id) {
        e.stopPropagation();
        this.setState({
            action:act,
            collectionId: id,
            optionOpened: 0,
            selectedCollection: [collection],
            selectedToUsers: []
        });
    }
    modalOpen(){
        this.setState({action:'close'});
    }

    componentWillUpdate(prevProps, nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.sharedCollection.shared !== prevProps.sharedCollection.shared) {
            //this.props.collections(this.state.group, this.state.search, getProfile().id, 'root', 0, "0", this.state.offset)
        }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            this.modalOpen();
        }

        if(prevProps.listAllCollections.ListAllCollections !== this.props.listAllCollections.ListAllCollections)
        {
            this.setState({
                Collections: this.props.listAllCollections.ListAllCollections.data,
                loading: 0
            });
            this.closeCreateNewFormModal();
        }

    }


    manageOptionsDropDown(e, id) {
        e.stopPropagation();
        this.setState({optionOpened: id});
    }
    resultDataList( result, reference) {
        this.setState({ [reference] : result });
    }
    shareCollection() {
        let users = [];
        let cnt = 0;
        this.state.selectedToUsers.map(a => {
            let user = {"type": a.user_type ,"id": + a.id };
            users.push(user);
        });

        this.props.sharecollection(this.state.collectionId, getProfile().id, users);
        this._interval = setInterval(() => {
            if(this.props.sharedCollection.shared === 1) {
                if(this.props.sharedCollection.msg === '') {
                    this.setState({action: ''});
                } else {
                    alert(this.props.sharedCollection.msg)
                }
                clearInterval(this._interval);
            }
        }, 500);

    }
    showCreateNewFormModal(){
        this.setState({createNewForm : !this.state.createNewForm})
    }

    closeCreateNewFormModal(){
        this.setState({createNewForm : false})
    }





    render() {
        console.log(this.props.listAllCollections.ListAllCollections)
        let Collections = [];
        if(this.props.listAllCollections.Search === '') {
            Collections = this.props.listAllCollections.ListAllCollections.data;
            //Collections = this.state.Collections;
        } else {
            Collections = this.props.listAllCollections.searchResult.data;
        }
       //Collections = this.state.Collections;
       console.log(Collections);

        return (
            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>
                <div className="pj-collection-container">
                    {this.props.loader.loaderType === 'collection-list' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                    }
                    <div className="content-main">
                        {Collections.map((list, index) =>
                            <FormTile list={list} key={"collection-"+index} history={this.props.history} optionOpened={this.state.optionOpened} manageOptionsDropDown={this.manageOptionsDropDown} updateAction={this.updateAction} section="project"/>
                        )}
                    </div>
                    <div className="float-button-block">
                        <button className="float-button ico-lib float-add" data-toggle="modal" data-target="#addField" onClick={this.showCreateNewFormModal}></button>
                    </div>
                </div>
                {this.state.action === 'assign' &&
                <Modal title={'Create New Task'} Close ={this.modalOpen.bind(this)}>
                    <AddToDoTask
                        selectedCollections={this.state.selectedCollection}
                        modalOpen = {this.modalOpen}
                    />
                </Modal>
                }
                {this.state.action === 'share' &&
                <SimpleModal
                    Close={this.modalOpen}
                    title={`Share Form`}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.shareCollection}
                    cancelLabel="Cancel"
                    cancelAction={this.modalOpen}>
                    {this.props.loader.loaderType === 'share-collection' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                    }
                    <div className="task-tg">

                        <p className="remove-txt-gp">Assign to Individual, Team, Organization, etc (Ignore this for self tasks)</p>
                        <div className="task-inp-container">

                            <AutoComplete
                                eleClass = 'selectize-custom'
                                fieldTitle = 'To'
                                sourceDataList = {this.props.listUsers.Users}
                                searchField = 'name'
                                showIcon={false}
                                hideTitle = {true}
                                hideMainLabel = {false}
                                showSubLabel = {false}
                                labelFields = 'name'
                                valueField ='user_id'
                                subLabelField = 'designation'
                                showProfile = {false}
                                reference='selectedToUsers'
                                selectedItems = {this.state.selectedToUsers}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="to_users"
                            />
                        </div>

                    </div>

                </SimpleModal>
                }
                {this.state.createNewForm &&
                <Modal title={'Create New Form'} Close ={this.showCreateNewFormModal.bind(this)}>
                    <AddNewForm />
                </Modal>

                }
            </div>

        );
    }
}


const mapStateToProps = state => ({
    searchData: state.CommonReducer.searchparamdata,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    listUsers:state.UsersReducer.listAllUsersdata,
    haveLoader : state.CollectionsReducer.manageoader,
    sharedCollection : state.CollectionsReducer.sharedCollection,
    loader:state.CommonReducer.loader,
    HistoryPushData:state.TaskReducer.HistoryPushData,
});

export default withRouter(connect(mapStateToProps, {
    mainClick: rootActions.commonActions.MenuClick,
    collections: collectionActions.listallcollections,
    updatecollections: collectionActions.updatecollections,
    moreCollections: collectionActions.updatecollections,
    moreSearchCollections: collectionActions.updatesearchcollections,
    sharecollection: collectionActions.sharecollection,
    users:usersActions.listAllUsers
})(ProjectCollections));


