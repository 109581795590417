import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import DailyTask from '../views/DailyTask'
import DashboardStatistics from '../views/DashboardStatistics'
import * as commonActions from "../../actions/Common";
import {getProfile} from '../../utilities/AuthService';
import * as dashboardActions from "../../actions/Dashboard";
import rootActions from "../../actions";

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state= {
            weeklyStatistics: []
        }
        this.getWeeklyStatistics = this.getWeeklyStatistics.bind(this);
    }
    getWeeklyStatistics(type) {
        var params = {type: type, uid: getProfile().id}
        this.props.getWeeklyStatistics(params);
    }
    componentDidMount() {
        this.props.mainClick('/','')
        var params = {type: 'previous', uid: getProfile().id}
        //this.props.getWeeklyStatistics(params);
        this.props.getDashboardStat(getProfile().usertype, getProfile().id);

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.weeklyStatistics !== prevProps.weeklyStatistics) {
            this.setState({weeklyStatistics: this.props.weeklyStatistics.weeklyStat})
        }
    }
    render() {

        let dashboard = this.props.dashboardStat.Dashboard;
        return (
            <div className="dash-wrapper">
                <div className="dash-wrapper-left">
                    <div className="task-dash-top">
                        <DailyTask dataList={dashboard.data || []} taskCount={dashboard.total || 0} type={'todaystask'} multiLoader={this.props.multiLoader}/>
                    </div>
                    <div className="task-dash-bottom">
                        <DailyTask dataList={dashboard.datadue || []} taskCount={dashboard.totaldue || 0} type={'duetask'} multiLoader={this.props.multiLoader}/>
                    </div>
                </div>
                <DashboardStatistics
                    dashboard ={dashboard}
                    multiLoader={this.props.multiLoader}
                    getWeeklyStatistics={this.getWeeklyStatistics}
                    weeklyStatistics={this.state.weeklyStatistics}
                />
            </div>
        );
    }
}



const mapStateToProps = state => ({
    dashboardStat: state.DashboardReducer.getDashboardStatistics,
    multiLoader:state.CommonReducer.multiLoader,
    weeklyStatistics: state.DashboardReducer.getWeeklyStatistics

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getWeeklyStatistics:dashboardActions.listWeeklyStatistics,
    getDashboardStat:dashboardActions.listDashboardStatistics
})(Dashboard));
