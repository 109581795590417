import React from 'react';
import {ProgressBar} from 'react-bootstrap'


class ProgressBars extends React.Component {
    constructor(props){
        super(props);
     this.state = {
        completed :0
     }
     this.fileProgress = this.fileProgress.bind(this);
    }

  componentDidMount() {
   // this.timer = setInterval(this.fileProgress, 500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  shouldComponentUpdate(nextProps, nextState){
    if(this.props !== nextProps || this.state !== nextState) {
        return true;
    }
  }

  DidComponentUpdate(){
      alert("DidComponentUpdate");
  }



  fileProgress(completed){
  alert("fileProgrsss");alert(completed);
  this.setState({ completed: completed });
    //const { completed } = this.state;
    /*if (completed === 100) {
      this.setState({ completed: 0 });
    } else {
      const diff = Math.random() * 10;
      this.setState({ completed: Math.min(completed + diff, 100) });
    }*/
  };

  render() {
      ////////console.log(this.props.percentage)
    return (
          <ProgressBar striped variant="success" label={`${Math.round(this.props.percentage)}%`}  now={this.props.percentage} />
    );
  }
}


export default (ProgressBars);