import React, {Component} from 'react';
import moment from "moment";
import DateTimePickerBox from "../../views/DateTimePickerBox";
import {Accordion, Card} from "react-bootstrap";
import RangeSlider from "../../views/RangeSlider";
import {getUrlSegment} from "../../../utilities/CustomFunctions";

class Facets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filter: [],
            collapsed: "0",
            masterid: '',
            cItem: [],
            facet: [],
            stime: "",
            etime: ""
        }
    }

    componentDidMount() {
        ////console.log(this.props.facet)
        this.setState({facet: this.props.facet})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.facet !== prevProps.facet) {
            this.setState({facet: this.props.facet})
            if (this.state.masterid !== "") {
                let _newState = this.props.facet
                ////console.log(this.state.cItem)
                ////console.log(this.state.cItem[0])
                ////console.log(this.props.facet.filter(list=>list.masterid === this.state.masterid.toString()))
                //  ////console.log( _newState[0]=this.state.cItem)
                let index = _newState.findIndex(list => list.masterid === this.state.masterid.toString());
                ////console.log(index);
                _newState[index] = this.state.cItem[0]
                ////console.log(_newState)
                this.setState({facet: _newState})

            }

        }


    }

    handleFilterClick = (item, masterid) => {

        let cItem = this.props.facet.filter(list => list.masterid === masterid)
        if (this.props.facet.filter(list => list.masterid === masterid)) {
            if (this.props.facet.filter(list => list.masterid === masterid)[0].fields.length !== 1) {
                this.setState({masterid: masterid, cItem: cItem})

            } else {
                this.setState({masterid: "", cItem: []})
            }
        }
        this.props.handlefilterSearch(item, masterid);

    }

    toggleAccordion(id) {
        let collapsed = (this.state.collapsed === id) ? -1 : id;
        this.setState({collapsed: collapsed})
    }

    overlayShow = () => {
        document.body.classList.add('layover-bg');
    }
    overlayHide = () => {
        document.body.classList.remove('layover-bg');
    }
    handleFromChange = (date) => {
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        this.props.dateRange(formattedDate, "fromdate")
    }
    handleToChange = (date) => {
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        this.props.dateRange(formattedDate, "todate")
    }
    handleFromCustomdateRange = (date, masterid) => {
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        this.props.daysFilter("fromdate", formattedDate, "", masterid, "")
    }
    handleToCustomdateRange = (date, masterid) => {
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        this.props.daysFilter("todate", formattedDate, "", masterid, "")
    }

    camelize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    handleAvaibilityChange = (date, type) => {

        if (type === undefined) {
            this.setState({[date.target.name]: date.target.value})
        } else {
            this.setState({[type]: date})
        }
        if (type === undefined) {
            this.props.handleAvailability(date.target.name, date.target.value)
        } else {
            let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
            this.props.handleAvailability(type, formattedDate)
        }


    }


    render() {
        // let filterString=this.props.filter.length!==0? this.props.filter:[];
        //  let collectionFilter = this.props.filterData ? this.props.filterData : [];
        let facet = this.state.facet;
        return (
            <div className="label-menu-right-block filter-top">
                <div className="filter-accord create-date accordion">

                    <div className="card">

                        {this.props.showdatefilter && this.props.setFilter !== "" &&
                        <Accordion defaultActiveKey={this.props.dateopen ? "datefilter" : ''} className="filter-accord">
                            <Card className="card" key={"filter-accord-fields-label"}>
                                <Accordion.Toggle as={Card.Header} eventKey={"datefilter"} className="card-header"
                                                  onClick={() => this.toggleAccordion("datefilter")}>
                                    <button
                                        className={`btn btn-link arrow-ico ${this.state.collapsed !== "datefilter" ? "collapsed" : ""}`}>Created
                                        Date
                                    </button>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={"datefilter"}>
                                    <Card.Body>

                                        <React.Fragment>
                                            <div className="radio-wrapper filter-block createdatefilter">
                                                <div className=" radio-block">
                                                    <input type="radio" name="dateFilter" value="today"
                                                           checked={this.props.filterDate === 'today'}
                                                    />
                                                    <label>Today</label>
                                                </div>
                                                <div className="radio-block">
                                                    <input type="radio" name="dateFilter" value="7"
                                                           checked={this.props.filterDate === 'Last  7 days'}
                                                    />
                                                    <label>Last 7 days</label>
                                                </div>
                                                <div className="radio-block">
                                                    <input type="radio" name="dateFilter" value="30"
                                                           checked={this.props.filterDate === 'Last  30 days'}
                                                    />
                                                    <label>Last 30 days</label>
                                                </div>
                                                <div className="radio-block">
                                                    <input type="radio" name="dateFilter" value="3"
                                                           checked={this.props.filterDate === 'Last  3 months'}
                                                    />
                                                    <label>Last 3 months</label>
                                                </div>
                                                <div className="radio-block">
                                                    <input type="radio" name="dateFilter" value="6"
                                                           checked={this.props.filterDate === 'Last  6 months'}
                                                    />
                                                    <label>Last 6 months</label>
                                                </div>
                                                {this.props.fromdate !== "" && this.props.todate !== "" &&
                                                <>
                                                    <div className="task-input-block eq-width">
                                                        <DateTimePickerBox handleDateChange={this.handleFromChange}
                                                                           onfocus={this.overlayShow}
                                                                           onblur={this.overlayHide}
                                                                           placeholderText="From Date"
                                                                           timeFormat={false}
                                                                           startDate={this.props.fromdate}
                                                                           previousDate={true}/>
                                                    </div>
                                                    <div className="task-input-block eq-width">
                                                        <DateTimePickerBox handleDateChange={this.handleToChange}
                                                                           onfocus={this.overlayShow}
                                                                           onblur={this.overlayHide}
                                                                           placeholderText="To Date" id="todate"
                                                                           timeFormat={false}
                                                                           startDate={this.props.todate}
                                                                           previousDate={true}/>
                                                    </div>
                                                </>
                                                }
                                            </div>
                                        </React.Fragment>

                                    </Card.Body>
                                </Accordion.Collapse>

                            </Card>

                        </Accordion>
                        }


                        {this.props.showdatefilter && this.props.setFilter === "" &&
                        <Accordion defaultActiveKey={this.props.dateopen ? "datefilter" : ''} className="filter-accord">
                            <Card className="card" key={"filter-accord-fields-label"}>
                                <Accordion.Toggle as={Card.Header} eventKey={"datefilter"} className="card-header"
                                                  onClick={() => this.toggleAccordion("datefilter")}>
                                    <button
                                        className={`btn btn-link arrow-ico ${this.state.collapsed !== "datefilter" ? "collapsed" : ""}`}>Created
                                        Date
                                    </button>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={"datefilter"}>
                                    <Card.Body>

                                        <React.Fragment>
                                            <div className="radio-wrapper filter-block createdatefilter">
                                                <div className=" radio-block">
                                                    <input type="radio" name="dateFilter" value="today"
                                                           checked={this.props.filterDate === 'today'}
                                                           onClick={(e) => this.props.dateWise(e)}/>
                                                    <label>Today</label>
                                                </div>
                                                <div className="radio-block">
                                                    <input type="radio" name="dateFilter" value="7"
                                                           checked={this.props.filterDate === 'Last  7 days'}
                                                           onClick={(e) => this.props.dateWise(e, "days", "Last  7 days")}/>
                                                    <label>Last 7 days</label>
                                                </div>
                                                <div className="radio-block">
                                                    <input type="radio" name="dateFilter" value="30"
                                                           checked={this.props.filterDate === 'Last  30 days'}
                                                           onClick={(e) => this.props.dateWise(e, "days", "Last  30 days")}/>
                                                    <label>Last 30 days</label>
                                                </div>
                                                <div className="radio-block">
                                                    <input type="radio" name="dateFilter" value="3"
                                                           checked={this.props.filterDate === 'Last  3 months'}
                                                           onClick={(e) => this.props.dateWise(e, "months", "Last  3 months")}/>
                                                    <label>Last 3 months</label>
                                                </div>
                                                <div className="radio-block">
                                                    <input type="radio" name="dateFilter" value="6"
                                                           checked={this.props.filterDate === 'Last  6 months'}
                                                           onClick={(e) => this.props.dateWise(e, "months", "Last  6 months")}/>
                                                    <label>Last 6 months</label>
                                                </div>
                                                <div className="task-input-block eq-width">
                                                    <DateTimePickerBox handleDateChange={this.handleFromChange}
                                                                       onfocus={this.overlayShow}
                                                                       onblur={this.overlayHide}
                                                                       placeholderText="From Date" timeFormat={false}
                                                                       startDate={this.props.fromdate}
                                                                       previousDate={true}/>
                                                </div>
                                                <div className="task-input-block eq-width">
                                                    <DateTimePickerBox handleDateChange={this.handleToChange}
                                                                       onfocus={this.overlayShow}
                                                                       onblur={this.overlayHide}
                                                                       placeholderText="To Date" id="todate"
                                                                       timeFormat={false}
                                                                       startDate={this.props.todate}
                                                                       previousDate={true}/>
                                                </div>
                                            </div>
                                        </React.Fragment>

                                    </Card.Body>
                                </Accordion.Collapse>

                            </Card>

                        </Accordion>
                        }


                        {(getUrlSegment(1) === "bookings" || (this.props.type && this.props.type === 'booking')) &&
                        <Accordion defaultActiveKey={"availability"} className="filter-accord">
                            <Card className="card" key={"filter-accord-fields-label"}>
                                <Accordion.Toggle as={Card.Header} eventKey={"datefilter"} className="card-header"
                                                  onClick={() => this.toggleAccordion("datefilter")}>
                                    <button
                                        className={`btn btn-link arrow-ico ${this.state.collapsed !== "datefilter" ? "collapsed" : ""}`}>
                                        Availability
                                    </button>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={"availability"}>
                                    <Card.Body>

                                        <React.Fragment>
                                            <div className="radio-wrapper filter-block createdatefilter">
                                                <div className="task-input-block">
                                                    <DateTimePickerBox handleDateChange={this.handleAvaibilityChange}
                                                                       onfocus={this.overlayShow}
                                                                       onblur={this.overlayHide}
                                                                       placeholderText="From Date" index={`sdate`}
                                                                       timeFormat={false}
                                                                       startDate={this.props.sdate}
                                                                       previousDate={true}/>
                                                </div>

                                                <div className="task-input-block">
                                                    <label>Start Time</label>
                                                    <input className={`task-input-block`} id="appt-time" type="time"
                                                           name="stime" dataformatas={"H:MM"}
                                                           onChange={(e) => this.handleAvaibilityChange(e)}/>
                                                </div>

                                                <div className="task-input-block">
                                                    <DateTimePickerBox handleDateChange={this.handleAvaibilityChange}
                                                                       onfocus={this.overlayShow}
                                                                       onblur={this.overlayHide}
                                                                       placeholderText="End Date" index="edate"
                                                                       timeFormat={false}
                                                                       startDate={this.props.todate}
                                                                       previousDate={false}/>
                                                </div>

                                                {this.state.stime !== "" &&
                                                <div className="task-input-block">
                                                    <label>End Time</label>
                                                    <input className={`task-input-block`} type="time" name="etime"
                                                           dataformatas={"H:MM"}
                                                           onChange={(e) => this.handleAvaibilityChange(e)}/>
                                                </div>
                                                }

                                            </div>
                                        </React.Fragment>

                                    </Card.Body>
                                </Accordion.Collapse>

                            </Card>

                        </Accordion>
                        }

                        {this.props.collection.flagCategory && this.props.collection.flagCategory.length !== 0 &&
                        <Accordion defaultActiveKey={this.props.flagopen ? "flag" : ''} className="filter-accord">
                            <Card className="card" key={"filter-accord-fields-label"}>
                                <Accordion.Toggle as={Card.Header} eventKey={"flag"} className="card-header"
                                                  onClick={() => this.toggleAccordion("flag")}>
                                    <button
                                        className={`btn btn-link arrow-ico ${this.state.collapsed !== "flag" ? "collapsed" : ""}`}>Flags
                                    </button>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={"flag"}>
                                    <Card.Body>


                                        {this.props.collection.flagCategory.map((list, inx) =>
                                            <React.Fragment>
                                                {list.flags.length !== 0 &&
                                                <div className="each-category-filter">
                                                    <h5 className="category-filter-header">{list.category}</h5>
                                                    {list.flags.map((item, itmIndex) =>
                                                        <div className="select-chk-block flag-tag"
                                                             key={"filter-fields-label" + itmIndex}>
                                                            <label
                                                                className="control control-checkbox"> {item.title}({item.count})
                                                                <input type="checkbox" className="inp-chk"
                                                                    // checked={this.props.FilterLabel.filter(list=>list.id.toString() ===  (filter.masterid.toString()) && list.value ===item.label).length ===0 ? false:true}
                                                                       onClick={() => this.props.handleFlagchange(item.title, item._id)}/>
                                                                <div className="control-indicator"/>
                                                            </label>
                                                            <span className={`flag-color`}
                                                                  style={{backgroundColor: item.color}}/>
                                                        </div>
                                                    )}
                                                </div>
                                                }
                                            </React.Fragment>
                                        )}


                                    </Card.Body>
                                </Accordion.Collapse>

                            </Card>

                        </Accordion>
                        }

                        {this.props.collection.workflow_filter && this.props.collection.workflow_filter.length !== 0 &&
                        <Accordion defaultActiveKey={"workflow"} className="filter-accord">
                            <Card className="card" key={"filter-accord-fields-label"}>
                                <Accordion.Toggle as={Card.Header} eventKey={"workflowfilter"} className="card-header"
                                                  onClick={() => this.toggleAccordion("workflowfilter")}>
                                    <button
                                        className={`btn btn-link arrow-ico ${this.state.collapsed !== "workflowfilter" ? "collapsed" : ""}`}>By
                                        Stage
                                    </button>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={"workflowfilter"}>
                                    <Card.Body>

                                        {this.props.collection.workflow_filter.map((list, inx) =>
                                            <React.Fragment>
                                                {list.stages.length !== 0 &&
                                                <div className="each-category-filter">
                                                    <h5 className="category-filter-title">{list.name}</h5>
                                                    {list.stages.map((item, itmIndex) =>
                                                        <div className="select-chk-block flag-tag"
                                                             key={"filter-fields-label" + itmIndex}>
                                                            <label
                                                                className="control control-checkbox"> {item.stagename}
                                                                <input type="checkbox" className="inp-chk"
                                                                    // checked={this.props.FilterLabel.filter(list=>list.id.toString() ===  (filter.masterid.toString()) && list.value ===item.label).length ===0 ? false:true}
                                                                       onClick={() => this.props.handleWorkflowFilterchange(list.id, item.stageid)}/>
                                                                <div className="control-indicator"/>
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                                }
                                            </React.Fragment>
                                        )}


                                    </Card.Body>
                                </Accordion.Collapse>

                            </Card>

                        </Accordion>
                        }

                        {facet && facet.length !== 0 &&
                        <Accordion defaultActiveKey={this.state.collapsed} className="filter-accord">
                            {facet.map((filter, index) => (
                                <Card className="card" key={"filter-accord-fields-label" + index}>
                                    <Accordion.Toggle as={Card.Header} eventKey={index.toString()}
                                                      className="card-header"
                                                      onClick={() => this.toggleAccordion(index)}>
                                        <button
                                            className={`btn btn-link arrow-ico ${this.state.collapsed !== index.toString() ? "collapsed" : ""}`}>{filter.type === "assignees" ? "Assignees" : filter.title}</button>
                                    </Accordion.Toggle>


                                    <Accordion.Collapse eventKey={index.toString()}>
                                        <Card.Body>

                                            {filter.type === "number" &&
                                            <div className="radio-wrapper eq-height-filter">
                                                {filter.setasslider === "No" ?
                                                    <>
                                                        <div className="task-input-block eq-width">
                                                            <input type={`number`} className={`task-input-box`}
                                                                   placeholder={`min`}
                                                                   onChange={(e) => this.props.numberFilter(e, filter.masterid, "min")}/>
                                                        </div>
                                                        <div className="task-input-block eq-width">
                                                            <input type={`number`} className={`task-input-box`}
                                                                   placeholder={`max`}
                                                                   onChange={(e) => this.props.numberFilter(e, filter.masterid, "max")}/>
                                                        </div>
                                                    </>
                                                    :

                                                    <RangeSlider
                                                        min={(filter.min === "No" || filter.min === "") ? 0 : parseInt(filter.min)}
                                                        max={(filter.max === "No" || filter.min === "") ? 10000 : parseInt(filter.max)}
                                                        masterid={filter.masterid}
                                                        rangeFilter={this.props.rangeFilter}

                                                    />
                                                }
                                            </div>
                                            }

                                            {filter.type === "computedfield" &&
                                            <div className="radio-wrapper eq-height-filter">
                                                <div className="task-input-block eq-width">
                                                    <input type={`number`} className={`task-input-box`}
                                                           placeholder={`min`}
                                                           onChange={(e) => this.props.numberFilter(e, filter.masterid, "min")}/>
                                                </div>
                                                <div className="task-input-block eq-width">
                                                    <input type={`number`} className={`task-input-box`}
                                                           placeholder={`max`}
                                                           onChange={(e) => this.props.numberFilter(e, filter.masterid, "max")}/>
                                                </div>
                                            </div>
                                            }

                                            {filter.type !== "assignees" && filter.fields && filter.fields.map((item, itmIndex) =>
                                                <div
                                                    className={`select-chk-block ${this.props.FilterLabel.filter(list => list.id.toString() === (filter.masterid.toString()) && list.value === item.label).length === 0 ? false : this.props.FilterLabel.filter(list => list.id.toString() === (filter.masterid.toString()) && list.value === item.label)[0].disabled ? 'disabled' : ""}`}
                                                    key={"filter-fields-label" + itmIndex}>
                                                    <label
                                                        className="control control-checkbox"> {item.label === "" ? "Empty" : item.label}({item.count})
                                                        <input type="checkbox" className="inp-chk"
                                                               checked={this.props.FilterLabel.filter(list => list.id.toString() === (filter.masterid.toString()) && list.value === item.label).length === 0 ? false : true}
                                                               disabled={this.props.FilterLabel.filter(list => list.id.toString() === (filter.masterid.toString()) && list.value === item.label).length === 0 ? false : this.props.FilterLabel.filter(list => list.id.toString() === (filter.masterid.toString()) && list.value === item.label)[0].disabled}
                                                               onClick={() => this.handleFilterClick(item.label, filter.masterid)}/>
                                                        <div className="control-indicator"/>
                                                    </label>
                                                </div>
                                            )}

                                            {filter.type === "assignees" && filter.fields && filter.fields.map((item, itmIndex) =>
                                                <div className="select-chk-block"
                                                     key={"filter-fields-label" + itmIndex}>
                                                    <label
                                                        className="control control-checkbox"> {item.name}({item.count})
                                                        <input type="checkbox" className="inp-chk"
                                                               checked={this.props.assigneeList.includes(item.id)}
                                                               onClick={() => this.props.handleAssigneClick(item.name, item.id)}/>
                                                        <div className="control-indicator"/>
                                                    </label>
                                                </div>
                                            )}


                                            {(!filter.fields || filter.fields.length === 0) && filter.hasOwnProperty("filter_option") && filter.dayfilter_option && filter.filter_option.length !== 0 &&

                                            <div className="radio-wrapper filter-block createdatefilter">
                                                <div className=" radio-block">
                                                    <input type="radio" name="dateFilter"
                                                           checked={this.props.datefilters.length !== 0 && this.props.datefilters[0].labelFrom === "today" + filter.masterid}
                                                           onClick={() => this.props.daysFilter("today", "", "", filter.masterid, "datefilters")}/>
                                                    <label>Today</label>
                                                </div>
                                            </div>
                                            }


                                            {(!filter.fields || filter.fields.length === 0) && filter.hasOwnProperty("filter_option") && filter.dayfilter_option && filter.filter_option.length !== 0 &&
                                            filter.filter_option.map((list, index) => {
                                                    let filterString = list.option_term + list.option_count + list.option_type + filter.masterid
                                                    return (<div className="radio-wrapper filter-block createdatefilter"
                                                                 key={"datefilters" + index}>
                                                        <div className=" radio-block">
                                                            <input type="radio" name="dateFilter"
                                                                   checked={this.props.datefilters.filter((list) => list.labelFrom === filterString).length !== 0}
                                                                   onClick={() => this.props.daysFilter(list.option_term, list.option_count, list.option_type, filter.masterid, "datefilters")}/>
                                                            <label>{this.camelize(list.option_term)} {list.option_count} {list.option_type}</label>
                                                        </div>
                                                    </div>)
                                                }
                                            )}
                                            {(!filter.fields || filter.fields.length === 0) && filter.hasOwnProperty("filter_option") && filter.dayfilter_option && filter.filter_option.length !== 0 &&
                                            <React.Fragment>
                                                <div className="radio-wrapper filter-block createdatefilter">
                                                    <div className="task-input-block eq-width">
                                                        <DateTimePickerBox
                                                            handleDateChange={this.handleFromCustomdateRange}
                                                            index={filter.masterid}
                                                            onfocus={this.overlayShow} onblur={this.overlayHide}
                                                            placeholderText="From Date"
                                                            timeFormat={false} startDate={this.props.customizeDateFrom}
                                                            previousDate={true}/>
                                                    </div>
                                                    <div className="task-input-block eq-width">
                                                        <DateTimePickerBox
                                                            handleDateChange={this.handleToCustomdateRange}
                                                            onfocus={this.overlayShow}
                                                            onblur={this.overlayHide}
                                                            placeholderText="To Date" id="todate"
                                                            timeFormat={false}
                                                            index={filter.masterid}
                                                            startDate={this.props.customizeDateTo}
                                                            previousDate={true}/>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            }

                                            {filter.days_filter_label &&
                                            <div className="card-header" id="dateheading">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link"> {filter.days_filter_label}
                                                    </button>
                                                </h5>
                                            </div>
                                            }
                                            {(!filter.fields || filter.fields.length === 0) && filter.hasOwnProperty("dayfilter_option") && filter.dayfilter_option && filter.dayfilter_option.length !== 0 &&
                                            <div className="radio-wrapper filter-block createdatefilter">
                                                <div className=" radio-block">
                                                    <input type="radio" name={"daysFilter" + filter.masterid}
                                                           checked={this.props.daysfilters.filter((list) => list.labelFrom === "today" + filter.masterid).length !== 0}
                                                           onClick={() => this.props.daysFilter("today", "", "", filter.masterid, "daysfilters")}/>
                                                    <label>Today</label>
                                                </div>
                                            </div>
                                            }

                                            {(!filter.fields || filter.fields.length === 0) && filter.hasOwnProperty("dayfilter_option") && filter.dayfilter_option && filter.dayfilter_option.length !== 0 &&
                                            filter.dayfilter_option.map((list, index) => {
                                                    let filterString = list.days_term + list.days_count + list.days_type + filter.masterid

                                                    return (<div className="radio-wrapper filter-block createdatefilter"
                                                                 key={"dayfilter_option" + index}>
                                                        <div className=" radio-block">
                                                            <input type="radio" name={"daysFilter" + filter.masterid}
                                                                   checked={this.props.daysfilters && this.props.daysfilters.filter((list) => (list.labelFrom === filterString.toString())).length !== 0}
                                                                   onClick={() => this.props.daysFilter(list.days_term, list.days_count, list.days_type, filter.masterid, "daysfilters")}/>
                                                            <label>
                                                                {this.camelize(list.days_term)} {list.days_count} {list.days_type}</label>
                                                        </div>
                                                    </div>)
                                                }
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            ))}
                        </Accordion>
                        }
                    </div>


                </div>
            </div>
        );
    }
}

export default Facets
