import React, {Component} from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis,CartesianGrid, Tooltip, Area, Legend } from 'recharts';
function RatingCards(props) {
//////console.log(props.ratingdata >= 1? "active":"")
    return (
        <div className="ind-ibt-rating">
            <div className="ibt-rating-top"><h5>{props.title}</h5></div>
            {props.subTitleEnabled &&
            <p>{props.subTitle}</p>
            }
            <div className="ibt-rating-bottom"><h5>{props.ratingNo}/{props.totalRating}</h5>
                <ul className="ibt-rate-star">
                    <li className={props.ratingNo >= 1? "active":""}> </li>
                    <li className={props.ratingNo >= 2? "active":""}> </li>
                    <li className={props.ratingNo >= 3? "active":""}> </li>
                    <li className={props.ratingNo >= 4? "active":""}> </li>
                    <li className={props.ratingNo >= 5? "active":""}> </li>

                </ul>
            </div>
        </div>
    );
}

export default RatingCards;