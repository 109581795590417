import {dynamicdashboard} from '../services/dynamicdashboard.services'
import {entity} from "../services/entity.services";

export const saveDashboarddata = (params) => dispatch => {
    dispatch({
        type: 'SAVE_DASHBOARD_LOADER',
        loader: true,
    });
    let saveDashboarddata = dynamicdashboard.saveDashboarddata(params);
    saveDashboarddata.then(saveDashboarddata => {
        dispatch({
            type: 'SAVE_DASHBOARD_DATA',
            saveDashboarddata: saveDashboarddata,
            data: saveDashboarddata.data.results,
            loader: false

        });

        dispatch({
            type: 'CHANGE_UPDATE_ID',
            Updateid: saveDashboarddata.data.results.data

        });

    })

};
export const dashboardChange = (params) => dispatch => {

    dispatch({
        type: 'CHANGE_DASHBOARD_TYPE',
        dashType: params

    });


};

export const changeId = (params) => dispatch => {
    dispatch({
        type: 'CHANGE_UPDATE_ID',
        Updateid: params

    });


};
export const dragStatus = (status) => dispatch => {
    dispatch({
        type: 'DRAGABLE_STATUS',
        dragStatus: status

    });


};
export const trackSharedLink = (params) => dispatch => {

    let sharedurl = dynamicdashboard.trackSharedUrl(params);
    sharedurl.then(sharedurl => {
        dispatch({
            type: 'TRACK_SHARED_URL',
            sharedurl: sharedurl.data.results
        });

    })

};

export const projectDashBoard = (params) => dispatch => {

    let projectdetails = dynamicdashboard.Projectdetails(params);
    projectdetails.then(projectdetails => {
        dispatch({
            type: 'PROJECT_DETAILS_DATA',
            projectdetails: projectdetails.data.results.data
        });

    })

};

export const addDashboard = (params) => dispatch => {

    let addDashboard = dynamicdashboard.addDashboard(params);
    addDashboard.then(addDashboard => {
        dispatch({
            type: 'ADD_NEW_DASHBOARD_MENU',
            addDashboard: addDashboard.data.results.data
        });

    })

};
export const getDashboardMenus = (params) => dispatch => {

    let addDashboard = dynamicdashboard.getDashboardMenus(params);
    addDashboard.then(addDashboard => {
        dispatch({
            type: 'GET_NEW_DASHBOARD_MENU',
            addDashboard: addDashboard.data.results.data
        });

    })

};

export const getFilterData = (params, type, filterName) => dispatch => {
    if (type === "dateFilter") {
        dispatch({
            type: 'GET_FILTER_DATE',
            date: params,
            filterName: filterName,
            filterType: params.filtertype || ''
        });
    }

    if (type === "selectFilter") {
        dispatch({
            type: 'FILTER_SELECT',
            select: params.sFilter,
            filterName: filterName,
            filterType: params.filtertype || ''

        });
    }

};

export const getGlobalFilterData = (params, type) => dispatch => {
    console.log(params)
    console.log(type)
    if (type === "globaldate") {
        dispatch({
            type: 'GET_GLOBAL_FILTER_DATE',
            date: params,
            filterType: params.filtertype || ''
        });
    }

    if (type === "globalselect") {
        dispatch({
            type: 'GET_GLOBAL_FILTER_SELECT',
            select: params.sFilter,
            filterType: params.filtertype || ''

        });
    }

};
export const resetGlobalFilterData = (params, type) => dispatch => {
    console.log(params)
    console.log(type)
    if (type === "globaldate") {
        dispatch({
            type: 'RESET_GLOBAL_FILTER_DATE'
        });
    }

    if (type === "globalselect") {
        dispatch({
            type: 'RESET_GLOBAL_FILTER_SELECT'

        });
    }

};

export const setGlobalFilters = (params) => dispatch => {

    dispatch({
        type: 'SET_GLOBAL_FILTERS',
        globalFilterComp: params
    });
};


export const entityCollections = (data) => dispatch => {

    let entityLinkedList = entity.entityCollections(data);
    dispatch({
        type: 'GET_ENTITY_LOADER',
        loading: true,

    });
    entityLinkedList.then(entityLinkedList => {
        dispatch({
            type: 'LINKED_ENTITY_DASHBOARD',
            entityLinkedList: entityLinkedList
        });
        dispatch({
            type: 'GET_ENTITY_LOADER',
            loading: false,

        });
    });

};


export const addRecord = (cid, masterid, act, mId, syncType) => dispatch => {
    dispatch({
        type: 'ADD_LINKED_REC',
        cid: cid,
        masterid: masterid,
        act: act,
        refId: mId,
        syncType: syncType

    });
};

export const syncRec = (sta) => dispatch => {
    dispatch({
        type: 'SYNC_REC',
        sta: sta
    });
};


export const ScrollElement = (id) => dispatch => {
    dispatch({
        type: 'SCROLL_ELEMENT',
        id: id
    });
};


export const toggle = (status) => dispatch => {
    dispatch({
        type: 'TOGGLE_ACCORDION',
        status: status
    });
};

export const getFlag = (param) => dispatch => {

    let getFlag = dynamicdashboard.getFlag(param);
    getFlag.then(getFlag => {
        dispatch({
            type: 'GET_FLAG_DASH_DATA',
            getFlag: getFlag
        });
    });

};

