import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import { BarChart, Bar,Cell, Tooltip, Legend,XAxis,CartesianGrid,YAxis,Label,LabelList, ResponsiveContainer} from 'recharts';
class Position extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let graphval=[];
        let status=this.props.dataList.wheretodo || [];
        if(this.props.type === 'project')
        {
             graphval = [
                {name: 'Lowest', rate: status.lowest , value :'4'},
                {name: 'Average', rate: status.average , value :'7'},
                {name: 'Highest', rate: status.highest , value :'10'}
            ];
        }
        else
        {
            graphval = [
                {name: 'Lowest', rate: status.lowest ,value :'4'  },
                {name: 'I\'m here', rate: status.am_here ,value :'6'},
                {name: 'Average', rate: status.average ,value :'8'},
                {name: 'Highest', rate: status.highest ,value :'10'}

            ];
            graphval.sort(function(a, b){
                return a.rate-b.rate
            });
            // graphval.sort(function(a, b){
            //     return a.value-b.value
            // });
            ////////console.log(graphval)
        }

        return(
            <div className="content-left lg-tab md-tab graph-empty">
                <div className="content-bl-header">
                    <h5>Where do I stand?</h5>
                </div>
                <div className="static-mode-block">
                    {status.lowest === 0 && status.average === 0 && status.highest === 0 &&
                        <div className="empty-data">
                            No data available to show the graph !!
                        </div>
                    }
                    <div className="graph-wrapper" style={{height: '100%', fontSize: '11px', color: '#B6C5C9',padding: "20px 0 0 0"}}>
                        {this.props.type !== 'project' ?
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={graphval} margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                                    <XAxis tick={false}
                                           label={{value: 'Task Run Rate Comparison Chart', fill: '#848484'}}
                                           style={{stroke: '#E5EBF2', color: '#E5EBF2', height: '1px'}}/>
                                    {status.lowest > 0 && status.average > 0 && status.highest > 0 &&
                                    <Bar isAnimationActive={false} type="monotone" dataKey="value" barSize={30}
                                         fill='#848484' position="inside">
                                        {graphval.map((entry, index) => (
                                            <Cell fill={entry.name === "I\'m here" ? '#0e9d58' : '#9fd4fa'}/>
                                        ))}
                                        <LabelList dataKey="name" position="top" style={{fill: '#848484'}}/>
                                        <LabelList dataKey="rate" position="inside" style={{fill: '#ffffff'}}/>
                                    </Bar>
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                            :
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={graphval} margin={{top: 20, right: 30, left: 20, bottom: 5}}>
                                    <XAxis tick={false}
                                           label={{value: 'Task Run Rate Comparison Chart', fill: '#848484'}}
                                           style={{stroke: '#E5EBF2', color: '#E5EBF2', height: '1px'}}/>
                                    {status.lowest > 0 && status.average > 0 && status.highest > 0 &&
                                    <Bar isAnimationActive={false} type="monotone" dataKey="value" barSize={30}
                                         fill='#9fd4fa' position="inside">
                                        <LabelList dataKey="name" position="top" style={{fill: '#848484'}}/>
                                        <LabelList dataKey="rate" position="inside" style={{fill: '#ffffff'}}/>
                                    </Bar>
                                    }
                                </BarChart>
                            </ResponsiveContainer>
                        }
                    </div>
                </div>
            </div>
        );
    }
}





export default Position;
