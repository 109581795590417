import React, {Component} from "react";
import {getProfile} from "../../../utilities/AuthService";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import Toaster from "../../views/Toaster"
import * as collectionActions from "../../../actions/Collections";
import AlertModal from "../../views/AlertModal";
import {Scrollbars} from "react-custom-scrollbars";

let initialElements =
    [
        {
            "type": "text",
            "option": [
                {
                    "name": "Select",
                    "value": ""
                },
                {
                    "name": "equal",
                    "value": "equal"
                },
                {
                    "name": "contain",
                    "value": "contain"
                }
            ],
            "placeholder": "Enter Text",
            "condition": {
                "field": "",
                "field_rule": [
                    {
                        "trigger": "equal",
                        "value": ""
                    }
                ]
            }
        },
        {
            "type": "textarea",
            "option": [
                {
                    "name": "Select",
                    "value": ""
                },
                {
                    "name": "equal",
                    "value": "equal"
                },
                {
                    "name": "contain",
                    "value": "contain"
                }
            ],
            "placeholder": "Enter Text",
            "condition": {
                "field": "",
                "field_rule": [
                    {
                        "trigger": "equal",
                        "value": ""
                    }
                ]
            }
        },
        {
            "type": "number",
            "option": [
                {
                    "name": "Select",
                    "value": ""
                },
                {
                    "value": "greater",
                    "name": "Greater than ( > )"
                },
                {
                    "value": "greatequal",
                    "name": "Greater than or equal ( >= )"
                },
                {
                    "value": "equal",
                    "name": "Equal ( = )"
                },
                {
                    "value": "less",
                    "name": "Less than ( < )"
                },
                {
                    "value": "lessequal",
                    "name": "Less than or equal ( <= )"
                }
            ],
            "placeholder": "Enter no of days",
            "condition": {
                "field": "",
                "field_rule": [
                    {
                        "trigger": "greater",
                        "value": ""
                    }
                ]
            }
        },
        {
            "type": "email",
            "option": [
                {
                    "name": "Select",
                    "value": ""
                },
                {
                    "name": "equal",
                    "value": "equal"
                },
                {
                    "name": "contain",
                    "value": "contain"
                }
            ],
            "placeholder": "Enter Text",
            "condition": {
                "field": "",
                "field_rule": [
                    {
                        "trigger": "equal",
                        "value": ""
                    }
                ]
            }
        },
        {
            "type": "dropdown",
            "condition": {
                "field": "",
                "field_rule": [
                    {
                        "trigger": "",
                        "value": ""
                    }
                ]
            }
        },
        {
            "type": "checkbox",
            "condition": {
                "field": "",
                "field_rule": [
                    {
                        "trigger": "",
                        "value": ""
                    }
                ]
            }
        },
        {
            "type": "switch",
            "condition": {
                "field": "",
                "field_rule": [
                    {
                        "trigger": "",
                        "value": ""
                    }
                ]
            }
        },
        {
            "type": "datepicker",
            "option": [
                {
                    "name": "Select",
                    "value": ""
                },
                {
                    "name": "on",
                    "value": "On"
                },
                {
                    "name": "before",
                    "value": "Before"
                },
                {
                    "name": "after",
                    "value": "After"
                }
            ],
            "placeholder": "Enter no of days",
            "condition": {
                "field": "",
                "field_rule": [
                    {
                        "trigger": "on",
                        "value": ""
                    }
                ]
            }
        }
    ]

class PropagationSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propagationSetting: [],
            label: [],
            editMode: '',
            toasterdata: {type: "", msg: "", show: false},
            cnfStat: false,
            dropdownrule: false,
            removeCondition: false,
            remindex: '',
            removeConditionName: '',
            showlistField: [],
            condition: [],
            //  ignoreItems:[]
        }
    }

    componentDidMount() {
        let showlistField = []
        if (this.props.label.showformdata.length !== 0) {
            for (let i = 0; i < this.props.label.showformdata.pages.length; i++) {
                (this.props.label.showformdata.pages[i].components).map(list => {
                    showlistField.push(list)

                })
                this.setState({showlistField: showlistField, initialElements: initialElements})
            }
        }
        let params = {
            "_id": this.props.collectionId,
        }
        this.props.listPropgationCollections(params);
        let propagationData = this.props.propagationData && this.props.propagationData.inheritance && this.props.propagationData.inheritance.length !== 0 ? this.props.propagationData.inheritance : this.state.propagationSetting;
        console.log(propagationData)
        if (propagationData.length !== 0) {
            if (propagationData[0].condition && propagationData[0].condition.length !== 0) {
                let dataFields = [];
                propagationData[0].condition.map((list, index) => {

                    let random = Math.round(new Date().getTime() + (Math.random() * 100));
                    if (list && !list.hasOwnProperty("masterid") && list.field !== '') {
                        let d = (showlistField.filter(item => item.masterid === list.field)[0])
                        if (d !== undefined) {
                            let deepCopyObj = JSON.parse(JSON.stringify(d));
                            dataFields.push(deepCopyObj)
                            dataFields[index]['condition'] = propagationData[0].condition[index]
                            dataFields[index]['opt'] = dataFields[index].option
                            dataFields[index]['name'] = "name-" + random

                            let mapData = initialElements.filter(item => (item.type === dataFields[index].type))
                            if (dataFields[index].type !== "switch") {
                                dataFields[index]['option'] = mapData[0].option
                            }
                        }
                    }
                })
                propagationData[0]['condition'] = dataFields.length !== 0 ? dataFields : propagationData[0].condition;
            }
        }
        this.setState({propagationSetting: propagationData})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.savePropgationData.data !== prevProps.savePropgationData.data) {
            if (this.props.savePropgationData.data.success === true) {
                let propagationData = this.props.savePropgationData.data.inheritance;
                if (propagationData.length !== 0) {
                    if (propagationData[0].condition && propagationData[0].condition.length !== 0) {
                        let dataFields = [];
                        propagationData[0].condition.map((list, index) => {
                            let random = Math.round(new Date().getTime() + (Math.random() * 100));
                            if (!list.hasOwnProperty("masterid") && list.field !== '') {
                                let d = (this.state.showlistField.filter(item => item.masterid === list.field)[0])
                                let deepCopyObj = JSON.parse(JSON.stringify(d));
                                dataFields.push(deepCopyObj)
                                dataFields[index]['condition'] = propagationData[0].condition[index]
                                dataFields[index]['opt'] = dataFields[index].option
                                dataFields[index]['name'] = "name-" + random
                                let mapData = initialElements.filter(item => (item.type === dataFields[index].type))
                                if (dataFields[index].type !== "switch") {
                                    dataFields[index]['option'] = mapData[0].option
                                }
                            }
                        })
                        propagationData[0]['condition'] = dataFields.length !== 0 ? dataFields : propagationData[0].condition;
                    }
                }
                this.setState({
                    propagationSetting: propagationData,
                    toasterdata: {type: "green", msg: "Settings saved successfully", show: true}
                })
            } else {
                this.setState({toasterdata: {type: "red", msg: "Something went wrong", show: true}})
            }
        }

        if (this.props.listCollections.linkedentityinfo !== prevProps.listCollections.linkedentityinfo) {
            if (this.props.listCollections.linkedentityinfo) {
                let linkedentityinfo = this.props.listCollections.linkedentityinfo;
                // let dataFields=[]
                // linkedentityinfo.condition && linkedentityinfo.condition.length !== 0 && linkedentityinfo.condition.map((list,index)=> {
                //     let d=(this.state.showlistField.filter(item => item.masterid === list.field)[0])
                //     let deepCopyObj = JSON.parse(JSON.stringify(d));
                //
                //     dataFields.push(deepCopyObj)
                //
                //     dataFields[index]['condition']= linkedentityinfo.condition[index]
                //     dataFields[index]['opt']=dataFields[index].option
                //     let mapData = initialElements.filter(item => (item.type ===dataFields[index].type))
                //     if(dataFields[index].type !=="switch") {
                //         dataFields[index]['option'] = mapData[0].option
                //     }
                // })
                // this.setState({condition:dataFields})
            }
            // else{
            //     this.setState({toasterdata:{type:"red",msg:"Something went wrong",show:true}})
            // }
        }

        if (this.props.deletePropgationData !== prevProps.deletePropgationData) {
            // if(this.props.deletePropgationData.data.data.success) {
            this.removeConnection()
            // }
        }

        if (this.props.propagationData !== prevProps.propagationData) {
            let propagationData = this.props.propagationData && this.props.propagationData.inheritance && this.props.propagationData.inheritance.length !== 0 ? this.props.propagationData.inheritance : this.state.propagationSetting;
            if (propagationData.length !== 0) {
                let dataFields = []
                propagationData[0].condition && propagationData[0].condition.length !== 0 && propagationData[0].condition.map((list, index) => {
                    if (!list.hasOwnProperty("masterid") && list.field !== '') {
                        let d = (this.state.showlistField.filter(item => item.masterid === list.field)[0])
                        let deepCopyObj = JSON.parse(JSON.stringify(d));
                        dataFields.push(deepCopyObj)
                        dataFields[index]['condition'] = propagationData[0].condition[index]
                        dataFields[index]['opt'] = dataFields[index].option
                        let mapData = initialElements.filter(item => (item.type === dataFields[index].type))
                        if (dataFields[index].type !== "switch") {
                            dataFields[index]['option'] = mapData[0].option
                        }
                    }
                })
                propagationData[0]['condition'] = dataFields;
            }
            this.setState({propagationSetting: propagationData})
        }

        if (this.props.label.showformdata !== prevProps.label.showformdata) {
            this.labelData()
        }

    }

    labelData = () => {
        let showlistField = []
        if (this.props.label.showformdata.length !== 0) {
            for (let i = 0; i < this.props.label.showformdata.pages.length; i++) {
                (this.props.label.showformdata.pages[i].components.filter(list => list.visibility !== "hidden")).map(list => {
                    showlistField.push(list)
                })
            }
            this.setState({showlistField: showlistField})
        }
    }
    addCntnItem = (index) => {
        let param = {
            "outputfield": "",
            "linked_to": ""
        }
        let _newState = [...this.state.propagationSetting]
        _newState[index]["connection"] = this.state.propagationSetting[index]["connection"].concat(param)
        this.setState({_newState})
    }
    removeCntnItem = (index, indexItem) => {
        let _newState = [...this.state.propagationSetting]
        _newState[index]["connection"].splice(indexItem, 1)
        this.setState({_newState})
    }
    handleLabelCange = (e, indexList, indexItem) => {
        let sel = e.target
        let _newState = [...this.state.propagationSetting]
        _newState[indexList]["connection"][indexItem][e.target.name] = e.target.value
        _newState[indexList]["connection"][indexItem][e.target.name + "Label"] = sel.options[sel.selectedIndex].text
        this.setState({_newState})
    }
    addNewConnection = () => {
        let newState = {
            "outputform": "",
            "inputform": this.props.collectionId,
            "save": true,
            "update": true,
            "delete": true,
            "_id": Math.round(new Date().getTime() + (Math.random() * 100)).toString(),
            "connection": [
                {
                    "outputfield": "",        // output field
                    "linked_to": "",           // input field,
                    "outputfieldLabel": "",
                    "linked_toLabel": "",
                }
            ],
            "condition": []
        }
        let _newState = [newState, ...this.state.propagationSetting]
        this.setState({propagationSetting: _newState, editMode: 0, initialElements: initialElements})
    }
    editConnection = (index) => {
        this.setState({editMode: index})
        this.outputformLabels(index)
    }
    actionChange = (e, indexList, action) => {
        let _newState = [...this.state.propagationSetting]
        _newState[indexList][action] = (e.target.value.toString() === "true" ? false : true)
        this.setState({_newState})

    }


    CollectionChange = (e, indexList) => {
        for (let i = 0; i < this.state.propagationSetting.length; i++) {
            if (this.state.propagationSetting[i].outputform === e.target.value) {
                //ignoreItems.push(this.state.propagationSetting[i].outputform)
                // this.setState({ignoreItems:ignoreItems})
                this.setState({toasterdata: {type: "red", msg: "Connection already exist", show: true}})
                return false
            }

        }

        let sel = e.target
        let _newState = [...this.state.propagationSetting]
        _newState[indexList]["outputform"] = e.target.value
        // _newState[indexList]["outputformLabel"]=sel.options[sel.selectedIndex].text
        this.setState({_newState})
        this.outputformLabels(indexList)
    }
    outputformLabels = (index) => {
        let _newState = [...this.state.propagationSetting]
        if (_newState[index]["outputform"] !== "") {
            let param = {
                _id: _newState[index]["outputform"]
            }
            this.props.formLabels(param)
        }
    }
    SavePropagation = () => {
        //let ignoreItems = []
        let condition = []
        let propagationSettings = []

        if (this.state.propagationSetting.length !== 0) {
            if (this.state.propagationSetting[0].condition) {
                this.state.propagationSetting[0].condition.map(list => {
                    condition.push(list.condition)
                })
            }
            for (let i = 0; i < this.state.propagationSetting.length; i++) {
                if (this.state.propagationSetting[i].outputform !== "") {
                    //ignoreItems.push(this.state.propagationSetting[i].outputform)
                    // this.setState({ignoreItems:ignoreItems})
                    if (this.state.propagationSetting[i].condition && this.state.propagationSetting[i].condition.length !== 0) {
                        this.state.propagationSetting[i].condition = condition;
                    }
                } else {
                    this.setState({toasterdata: {type: "red", msg: "Please check the settings values", show: true}})
                    return false
                }
            }
        }

        let param = {
            "collectionid": this.props.collectionId,
            "inheritancetype": this.props.type,
            // "inheritanceitem": inheritanceitem.toString(),
            "inheritance": this.state.propagationSetting,
            "condition": condition,
            "uid": getProfile().id,
        }
        this.props.savePropagation(param)
    }
    toasterClose = () => {
        this.setState({toasterdata: {type: "", msg: "", show: false}})
    }
    removeConnection = (index) => {
        let _newState = [...this.state.propagationSetting]
        _newState.splice(this.state.indexList, 1)
        this.setState({propagationSetting: _newState, cnfStat: false})
    }
    deletePropagation = () => {
        let param = {
            "collectionid": this.props.collectionId,
            "recordid": this.state.removeindex._id,
            "uid": getProfile().id,
        }
        this.props.deletePropagation(param)
    }
    removePropagationItem = (removeindex, indexList) => {
        let remindex = removeindex
        if (this.state.cnfStat) {
            remindex = ""
        }
        this.setState({cnfStat: !this.state.cnfStat, removeindex: remindex, indexList: indexList})
    }

    handleChange(e, label, type, opt, masterid) {
        let random = Math.round(new Date().getTime() + (Math.random() * 100));
        var h = []
        h = [...this.state.initialElements]
        let d = Array.from(h.filter(list => (list.type === type)))
        let deepCopyObj = JSON.parse(JSON.stringify(d));
        let mapData = deepCopyObj
        if (mapData.length !== 0) {
            mapData[0][`name`] = "name-" + random
            mapData[0][`masterid`] = masterid.toString()
            mapData[0][`label`] = label
            mapData[0][`opt`] = opt
            let propagationSetting = [...this.state.propagationSetting]
            propagationSetting[0].condition.push(mapData[0])
            this.setState({propagationSetting: propagationSetting, dropdownrule: false})
        }
    }

    labelChange = (e, name, type) => {
        let propagationSetting = [...this.state.propagationSetting]
        propagationSetting[0].condition.map((list, index) => {
            if (list.name === name) {
                list.condition[`field`] = list.masterid
                list.condition[`field_rule`][0][`value`] = e.target.value
            }
        })
        this.setState({propagationSetting})

    }
    optionChange = (e, name, type) => {
        if (e.target.value !== '') {
            let _newState = []
            let propagationSetting = [...this.state.propagationSetting]
            if (type === "switch" || type === "checkbox" || type === "dropdown") {
                propagationSetting[0].condition.map((list, index) => {
                    if (list.name === name) {
                        list.condition[`field`] = list.masterid
                        list.condition[`field_rule`][0][`trigger`] = e.target.value
                        list.condition[`field_rule`][0][`value`] = e.target.value
                    }
                })
            }
            this.setState({propagationSetting})
        }
    }
    setTab = (act) => {
        this.setState({dropdownrule: !this.state.dropdownrule})
    }
    removeFromCondition = (e, name) => {
        this.setState({removeCondition: !this.state.removeCondition, removeConditionName: name})
    }
    handleRemove = () => {
        let propagationSetting = [...this.state.propagationSetting]
        let name = this.state.removeConditionName;
        let propagationSet = [];
        propagationSetting[0].condition.map((list, index) => {
            if (list.name !== name) {
                propagationSet.push(list)
            }
        })
        propagationSetting[0].condition = propagationSet;
        this.setState({propagationSetting: propagationSetting})
        this.removeFromConditionConfirm();
    }
    removeFromConditionConfirm = () => {
        this.setState({removeCondition: false})
    }

    render() {
        let collectionList = this.props.listCollections.linkedentityinfo;
        let showlistField = this.state.showlistField;
        let showFormLabels = []
        if (this.props.showFormLabels.showformdata.length !== 0) {
            for (let i = 0; i < this.props.showFormLabels.showformdata.pages.length; i++) {
                (this.props.showFormLabels.showformdata.pages[i].components).map(list => {
                    showFormLabels.push(list)

                })
            }
        }
        let condition = this.state.propagationSetting.length !== 0 && this.state.propagationSetting[0].condition ? this.state.propagationSetting[0].condition : [];
        // console.log(condition)
        // console.log(showFormLabels)
        // console.log(this.state.propagationSetting)

        return (
            <>
                {this.state.toasterdata.show &&
                <Toaster
                    toasterdata={this.state.toasterdata}
                    toasterClose={this.toasterClose}
                />
                }
                <div className="tab-pane fade show active">
                    <div className="settings-loungue-header">
                        <div className="tab-wrap-block full-width-tab">
                            <div className="tab-wrap-block-left">
                                <h5>Choose the action</h5>
                                <p>please choose action from selectbox</p>
                            </div>
                            <div className="tab-wrap-block-right">
                                {this.state.propagationSetting.length < collectionList.length &&
                                <button className="add-common" type="button"
                                        onClick={() => this.addNewConnection()}></button>
                                }
                                {/* <button className="add-cl" onClick={()=>this.addNewConnection()}> </button> */}
                            </div>
                        </div>
                    </div>

                    <div className="set-container pb-0">
                        <div className="left-rule-panel">
                            <Scrollbars autoHide autoHideTimeout={1000}>
                                {condition && condition.length !== 0 && condition.filter(item => item !== null).map((list, index) =>
                                    <React.Fragment>
                                        {list.type === "checkbox" || list.type === "switch" ?
                                            <React.Fragment>
                                                <div className="leftContentbox">
                                                    <div className="leftContentboxbody">

                                                        <div className="leftContentHead">
                                                            <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                        </div>
                                                        <div className="leftInputSection">
                                                            <div className="split-rule-block">
                                                                <div className="rule-select-box">
                                                                    <select
                                                                        value={list.condition.field_rule[0].trigger}
                                                                        onChange={(e) => this.optionChange(e, list.name, list.type)}>
                                                                        <option value="">Select</option>
                                                                        {list.opt.map((item, index) =>
                                                                            <option
                                                                                key={`option` + index}
                                                                                value={item.text ? item.text : item}>{item.text ? item.text : item}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="split-rule-block">

                                                                <div className="leftInputaddIcons">
                                                                    <button
                                                                        className="btnsubIconsadd ico-lib">
                                                                    </button>
                                                                </div>
                                                                <div className="leftInputaddIcons">
                                                                    <button
                                                                        className="btnsubIcon ico-lib"
                                                                        onClick={(e) => this.removeFromCondition(e, list.name)}>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="lefttext">
                                                        <p>and</p>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                            :
                                            list.type === "dropdown" ?
                                                <React.Fragment>
                                                    <div className="leftContentbox">
                                                        <div className="leftContentboxbody">

                                                            <div className="leftContentHead">
                                                                <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                            </div>
                                                            <div className="leftInputSection">
                                                                <div className="split-rule-block">
                                                                    <div className="rule-select-box">
                                                                        <select
                                                                            value={list.condition.field_rule[0].trigger}
                                                                            onChange={(e) => this.optionChange(e, list.name, list.type)}>
                                                                            <option value="">Select</option>
                                                                            {list.opt.map((item, index) =>
                                                                                <option
                                                                                    key={`option` + index}
                                                                                    value={item.text ? item.text : item}>{item.text ? item.text : item}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="split-rule-block">

                                                                    <div className="leftInputaddIcons">
                                                                        <button
                                                                            className="btnsubIconsadd ico-lib">
                                                                        </button>
                                                                    </div>
                                                                    <div className="leftInputaddIcons">
                                                                        <button
                                                                            className="btnsubIcon ico-lib"
                                                                            onClick={(e) => this.removeFromCondition(e, list.name)}>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="lefttext">
                                                            <p>and</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className="leftContentbox">
                                                        <div className="leftContentboxbody">
                                                            <div className="leftContentHead">
                                                                <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                            </div>
                                                            <div className="leftInputSection">
                                                                <div className="split-rule-block">
                                                                    <div className="rule-select-box">
                                                                        <select
                                                                            onChange={(e) => this.optionChange(e, list.name, "switch")}
                                                                            value={list.condition && list.condition.field_rule[0].trigger}
                                                                        >
                                                                            {list.option && list.option.map((item, index) =>
                                                                                <option
                                                                                    key={`option` + index}
                                                                                    value={item.value}>{item.name}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="split-rule-block">
                                                                    <div className="rule-input-block">
                                                                        <input type="text"
                                                                               placeholder={list.placeholder}
                                                                               name={`name` + index}
                                                                               value={list.condition && list.condition.field_rule[0].value}
                                                                               onChange={(e) => this.labelChange(e, list.name, list.type)}/>
                                                                    </div>
                                                                    <div className="leftInputaddIcons">
                                                                        <button
                                                                            className="btnsubIcon ico-lib"
                                                                            onClick={(e) => this.removeFromCondition(e, list.name)}>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="lefttext">
                                                                <p>and</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </React.Fragment>
                                        }


                                    </React.Fragment>
                                )}
                                {this.state.propagationSetting.length !== 0 &&
                                <div className="rule-field-btn-block">
                                    <div className="dropdown show active">
                                        <button className="dropdown-toggle" type="button"
                                                onClick={() => this.setTab("dropdownrule")}>Choose <span
                                            className="blue-bold"
                                        >Field</span>
                                        </button>
                                        {this.state.dropdownrule &&

                                        <div className="dropdown-menu show active"
                                             aria-labelledby="dropdownMenu2">

                                            {showlistField.map((list, index) =>
                                                <button className="dropdown-item" type="button"
                                                        onClick={(e) => this.handleChange(e, list.label, list.type, list.option, list.masterid)}>{list.label}
                                                </button>
                                            )}

                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                            </Scrollbars>
                        </div>
                        <div className="right-rule-panel">
                            <Scrollbars autoHide autoHideTimeout={1000}>
                                <div className="propogation-container">
                                    {this.state.propagationSetting.map((list, indexList) =>
                                        <div className="propogation-wrap">
                                            <div className="checkbox-container">
                                                <div className="select-chk-block" style={{display: 'block'}}>
                                                    <label className="control control-checkbox">Save
                                                        <input type="checkbox"
                                                               className={`inp-chk ${list.save.toString() === "true" ? "active" : ""}`}
                                                               name="stagesubmissiontype"
                                                               onChange={(e) => this.actionChange(e, indexList, "save")}
                                                               value={list.save}
                                                               defaultChecked={list.save.toString() === "true"}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                                <div className="select-chk-block" style={{display: 'block'}}>
                                                    <label className="control control-checkbox">Update
                                                        <input type="checkbox"
                                                               className={`inp-chk ${list.update.toString() === "true" ? "active" : ""}`}
                                                               name="stagesubmissiontype"
                                                               onChange={(e) => this.actionChange(e, indexList, "update")}
                                                               value={list.update}
                                                               defaultChecked={list.update.toString() === "true"}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                                <div className="select-chk-block" style={{display: 'block'}}>
                                                    <label className="control control-checkbox">Delete
                                                        <input type="checkbox"
                                                               className={`inp-chk ${list.delete.toString() === "true" ? "active" : ""}`}
                                                               name="stagesubmissiontype"
                                                               onChange={(e) => this.actionChange(e, indexList, "delete")}
                                                               value={list.delete}
                                                               defaultChecked={list.delete.toString() === "true"}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="propogation-block">
                                                <div className="propogation-header">
                                                    <div className="ind-propogation-header">
                                                        <h5>Source Collection</h5>
                                                    </div>
                                                    <div className="ind-propogation-header">
                                                        <h5>Choose Destination Collection</h5>
                                                        <div className="prop-right">
                                                            <div
                                                                className={`select-box ${list.outputform === "" ? "validation-error" : ""}`}>
                                                                {this.state.editMode !== "" && this.state.editMode === indexList ?
                                                                    <select
                                                                        onChange={(e) => this.CollectionChange(e, indexList)}
                                                                        value={list.outputform}>
                                                                        <option value="">Select</option>
                                                                        {collectionList.map((list) =>
                                                                            <option
                                                                                value={list.cid}>{(list.title)}</option>
                                                                        )}

                                                                    </select>
                                                                    :
                                                                    <select
                                                                        onChange={(e) => this.CollectionChange(e, indexList)}
                                                                        value={list.outputform} disabled={true}>
                                                                        <option value="">Select</option>
                                                                        {collectionList.map((list) =>
                                                                            <option
                                                                                value={list.cid}>{(list.title)}</option>
                                                                        )}

                                                                    </select>

                                                                }
                                                            </div>
                                                            <div className="prop-row-option">
                                                                {this.state.editMode !== indexList &&
                                                                <button onClick={() => this.editConnection(indexList)}
                                                                        className="prop-edit"></button>
                                                                }
                                                                <button className="prop-delete"
                                                                        onClick={() => this.removePropagationItem(list, indexList)}></button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                {(this.state.editMode !== "" && this.state.editMode === indexList) ?
                                                    <div className="propogation-body">
                                                        {list.connection.map((item, indexItem) =>
                                                            <div className="propogation-row">
                                                                <div className="ind-prop-list">
                                                                    <div
                                                                        className={`select-box ${item.linked_to === "" ? "validation-error" : ""}`}>
                                                                        <select name={`linked_to`}
                                                                                value={item.linked_to}
                                                                                onChange={(e) => this.handleLabelCange(e, indexList, indexItem)}>
                                                                            <option value="">Select</option>
                                                                            {this.state.showlistField.map((list) =>
                                                                                <option
                                                                                    value={list.masterid}>{list.label}</option>
                                                                            )}

                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="ind-prop-list">
                                                                    <div
                                                                        className={`select-box ${item.outputfield === "" ? "validation-error" : ""}`}>
                                                                        <select name={`outputfield`}
                                                                                value={item.outputfield}
                                                                                onChange={(e) => this.handleLabelCange(e, indexList, indexItem)}>
                                                                            <option value="">Select</option>
                                                                            {showFormLabels.length !== 0 && showFormLabels.map((list) =>
                                                                                <option
                                                                                    value={list.masterid}>{list.label}</option>
                                                                            )}

                                                                        </select>
                                                                    </div>
                                                                    <div className="prop-row-option">
                                                                        {list.connection.length !== 1 &&
                                                                        <button className="prop-remove"
                                                                                onClick={() => this.removeCntnItem(indexList, indexItem)}></button>
                                                                        }
                                                                        {indexItem === list.connection.length - 1 && showFormLabels.length - 1 !== indexItem &&
                                                                        <button className="prop-add"
                                                                                onClick={() => this.addCntnItem(indexList)}></button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                    :
                                                    <div className="propogation-body">
                                                        {list.connection.map((item, indexItem) =>
                                                            <div className="propogation-row">
                                                                <div className="ind-prop-list">
                                                                    <h5>{item.linked_toLabel}</h5>
                                                                </div>
                                                                <div className="ind-prop-list">
                                                                    <h5>{item.outputfieldLabel}</h5>

                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>

                                                }


                                            </div>
                                        </div>
                                    )}
                                    {this.state.propagationSetting.length !== 0 &&
                                    <div className="propogation-footer">
                                        <button className="general-btn focus-resolve"
                                                onClick={() => this.SavePropagation()}>Save
                                        </button>
                                    </div>
                                    }
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </div>
                {this.state.cnfStat &&
                <AlertModal
                    id="save-draft-todo"
                    title="Are you sure want to delete?"
                    OK_button_text="Yes, Delete It"
                    OK_button_style="btn-danger"
                    showModal={true}
                    OK_action={this.deletePropagation}
                    CANCEL_action={this.removePropagationItem}
                />
                }
                {this.state.removeCondition &&
                <AlertModal id="take-pool"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_button_style="btn-danger"
                            OK_action={this.handleRemove}
                            showModal={this.state.removeCondition}
                            CANCEL_action={this.removeFromConditionConfirm}
                />
                }
            </>

        );
    }

}

const mapStateToProps = state => ({
    listCollections: state.CollectionsReducer.listPropgationCollections,
    showFormLabels: state.CollectionsReducer.showFormLabels,
    savePropgationData: state.CollectionsReducer.savePropgationData,
    deletePropgationData: state.CollectionsReducer.deletePropgationData,
});

export default withRouter(connect(mapStateToProps, {
    listPropgationCollections: collectionActions.listPropgationCollections,
    formLabels: collectionActions.formLabels,
    savePropagation: collectionActions.savePropagation,
    deletePropagation: collectionActions.deletePropagation,

})(PropagationSettings));
