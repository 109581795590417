import React, {Component} from 'react';
import FileViewer from 'react-file-viewer';

import { Document, Page ,pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PdfViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
        }
    }


    componentDidMount() {
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }


    render() {
        const { pageNumber, numPages } = this.state;

        return (

            <div className="attachment-pdf-block">
                <Document
                    file={this.props.fileUrl}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>

                {/*<FileViewer*/}
                    {/*fileType={this.props.fileType}*/}
                    {/*filePath={this.props.fileUrl}*/}
                    {/*//errorComponent={CustomErrorComponent}*/}
                   {/*/>*/}
                {/*<p>Page {pageNumber} of {numPages}</p>*/}
            </div>

        );
    }
}



export default PdfViewer;


