import React, {Component} from 'react';
import SimpleModal from "../views/SimpleModal";
import {EditGroup} from "../pages/FormsData";
import {Accordion, Button, ButtonToolbar, Card} from "react-bootstrap";
import {getProfile} from "../../utilities/AuthService";
import {ES_INDEX, USRIMG_PATH} from '../../constants/index'
import {Scrollbars} from "react-custom-scrollbars";
import moment from 'moment'

class GroupDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalStatus: false,
            ModalType: '',
            desc: ''
        }
        this.modalChange = this.modalChange.bind(this)
    }

    componentDidMount() {
    }

    componentWillUpdate(nextProps) {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    groupEdit(desc, groupid, creator) {

        this.setState({desc: desc, modalStatus: true, modalType: 'groupEdit', groupid: groupid, creator: creator})

    }

    modalChange() {
        this.setState({modalStatus: !this.state.modalStatus, modalType: ''})
    }

    groupDelete(groupid, creator) {
        this.setState({groupid, creator, modalStatus: true, modalType: 'deleteGroup'})
        //  this.props.sidePanel(false,'')
    }

    confirmGroupDelete = () => {
        this.props.groupDelete(this.state.groupid, this.state.creator, ES_INDEX)
        this.props.sidePanel(false, '')
    }

    removeUser(groupid, userid, addedby, mode, firstname, image) {
        this.setState({
            modalStatus: true,
            modalType: 'removeUser',
            groupId: groupid,
            userid: userid,
            addedby: addedby,
            mode: mode,
            firstname: firstname,
            image: image
        })

    }

    deleteUser = () => {
        this.props.removeUser(this.state.groupId, this.state.userid, this.state.addedby, this.state.mode)
        this.modalChange();
        this.props.sidePanel(false, '')
    }

    render() {
        return (
            <>
                <div className={`side-panel-active`} key={`group-info-panel`}>

                    {this.props.groupinfo.info.map((list, index) =>
                        <div key={`group-inf0-block`+index}>
                            <div className="side-panel-active-header chat-detail-header" key={`channelDetails-group-data`+ index}>
                                <h5 className="subheader" id="channel-name">
                                    About <i className="fa fa-lock" aria-hidden="true"></i> {atob(list.groupname)}
                                </h5>

                                {getProfile().id === list.creator &&
                                      <span id="remove-group">
                                           <ButtonToolbar className="">
                                             <Button type="submit" className="side-panel general-btn" onClick={() => this.groupDelete(list.groupid, list.creator)}>Delete Group</Button>
                                           </ButtonToolbar>
                                       </span>
                                }
                                <span className="ico-lib fly-close" onClick={() => this.props.sidePanel(false, '')}> </span>
                            </div>
                            <Accordion defaultActiveKey="0" key={`members-panel-` + index}>
                                <Card className={`side-panel`}>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        Channel Details
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>

                                            <div className="title-bl">
                                                {getProfile().id === list.creator &&
                                                <h5>Purpose &nbsp;&nbsp;
                                                    <i id="edit_channel_description"
                                                       onClick={() => this.groupEdit(list.groupdesc, list.groupid, list.creator)}
                                                       className="fa fa-pencil"
                                                       aria-hidden="true">

                                                    </i>
                                                </h5>
                                                }
                                                <p id="channel-purpose">{atob(list.groupdesc)}</p>
                                            </div>
                                            <div className="title-bl">
                                                <h5>Created</h5>
                                                <p id="channel-creator">Created by <span
                                                    id="creator-name">{(list.creatorfirstname)} {(list.creatorlastname)} </span> on <span
                                                    id="created-date">{moment.utc(list.createdate).local().format('DD MMMM YYYY')} </span>
                                                </p>
                                            </div>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className={`side-panel`}>
                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                        {list.groupmembers.length} Member{list.groupmembers.length > 1 ? 's' : ''}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Scrollbars
                                            style={{width: '100%', height: '100%'}}
                                        >
                                            <Card.Body>
                                                {list.groupmembers.map((list, index) =>
                                                    <div className="ind-chat-list" id={`mem_${list.groupid}`}
                                                         key={index + 'chat-in-list'}>
                                                        <div className="chat-click">
                                                            <div className="chat-list-left">
                                                                <div className="chat-user-thumb"><img
                                                                    src={USRIMG_PATH+list.img_name}/>
                                                                </div>
                                                                <div className="chat-content-det">
                                                                    <h5>{list.firstname} {list.lastname}</h5>
                                                                    <p>{list.designation}</p></div>
                                                                {getProfile().id === this.props.groupinfo.info[0].creator &&
                                                                <div className="remove-button-block">

                                                                    {getProfile().id !== list.userid &&
                                                                    <button
                                                                        onClick={() => this.removeUser(list.groupid, list.userid, getProfile().id, 'remove', list.firstname, list.img_name)}
                                                                        className="general-remove-sm">
                                                                    </button>
                                                                    }
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}


                                            </Card.Body>
                                        </Scrollbars>

                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    )}
                    {this.state.modalStatus && this.state.modalType === 'groupEdit' &&

                        <SimpleModal

                        Close={this.modalChange}
                        title={`Edit Channel Description`}
                    >
                        <EditGroup
                            creator={this.state.creator}
                            groupid={this.state.groupid}
                            desc={this.state.desc}
                            editGroupDesc={this.props.groupinfo.info[0].groupdesc}
                            Close={this.modalChange}
                            editGroup={this.props.editGroup}
                        />
                    </SimpleModal>
                    }

                    {this.state.modalStatus && this.state.modalType === 'removeUser' &&
                        <SimpleModal

                        Close={this.modalChange}
                        title={`Remove User`}
                    >
                        <div className="remove-user" id="remove-user">

                            <p className="remove-txt-gp">Are you sure to
                                remove the selected user?</p>
                            <div className="chat-list-left">
                                <div className="chat-user-thumb"><img
                                    src={USRIMG_PATH + this.state.image}
                                    data-image={USRIMG_PATH + this.state.image}/>
                                </div>
                                <div className="chat-content-det"><h5>{this.state.firstname}</h5></div>
                            </div>
                        </div>
                        <ButtonToolbar className="modal-footer btn-toolbar">
                            <Button type="submit" className="general-btn" onClick={this.deleteUser}>OK</Button>
                            <Button className="general-btn grey" onClick={this.modalChange}>Cancel</Button>
                        </ButtonToolbar>

                    </SimpleModal>
                    }

                    {this.state.modalStatus && this.state.modalType === 'deleteGroup' &&
                    <SimpleModal
                        Close={this.modalChange}
                        title={`Remove Group`}
                    >
                        <div className="remove-user" id="remove-user">
                            <p className="remove-txt-gp">Are you sure to remove the Group?</p>
                        </div>
                        <ButtonToolbar className="modal-footer btn-toolbar">
                            <Button type="submit" className="general-btn" onClick={this.confirmGroupDelete}>OK</Button>
                            <Button className="general-btn grey" onClick={this.modalChange}>Cancel</Button>
                        </ButtonToolbar>
                    </SimpleModal>
                    }
                </div>
            </>
        );
    }
}


export default (GroupDetails);


