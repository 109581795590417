import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../../actions/ToDo"
import {withRouter} from 'react-router';
import ListCard from "../../views/ListCard"
import LoaderSvg from "../../views/LoaderSVG"
import Modal from "../../views/Modal"
import AddToDoTask from "../AddToDoTask"
import {getProfile} from '../../../utilities/AuthService';
import * as projectActions from "../../../actions/Project";
import NoData from "../../views/NoData";
import FilterBlock from "../FilterBlock";
import FilerRightBlock from "../FilterRightBlock";
import rootActions from "../../../actions";
import {formatDate, getUrlSegment} from "../../../utilities/CustomFunctions";
import SimpleModal from "../../views/SimpleModal";
import { DateRange } from 'react-date-range';
import moment from "moment";

class CustomDateRange extends Component {

    constructor(props) {
        super(props);
        this.modalOpen=this.modalOpen.bind(this)
        this.gettaskList=this.gettaskList.bind(this);
        // this.loadtasks = this.loadtasks.bind(this);
        this.createmodalClose = this.createmodalClose.bind(this);
        this.applyCustomDateRange = this.applyCustomDateRange.bind(this);
        this.state={
            open:false,
            projectList:[],
            limit : 0,
            loadingState: false,
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            todotaskdata:[],
            offset: 20,
            customDate :  moment.utc().local().format('YYYY-MM-DD'),
            dateRange: {
                selection: {
                    startDate: new Date(),
                    endDate: null,
                    key: 'selection',
                },
            }
        }

    }

    componentDidMount() {
        this.props.mainClick('task','/overdue/customdaterange');
        this.setState({open:false})

        if(this.props.todoListing.toDo.data.length ===0) {
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
            }
            else {
                this.gettaskList('All');
            }
        }
        else{
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
                this.setState({ todotaskdata: this.props.todoListing.toDo.data, loadingState : false })
            }
            else {
                this.setState({ todotaskdata: this.props.todoListing.toDo.data, loadingState : false })

            }
        }
        const container = document.querySelector('.main-panel-holder');
        container.addEventListener('scroll', this.listenToScroll)

    }

    searchFilterActive(){
        var status = "All"
        if(getUrlSegment(2)==='todo'){
            var category='to-me'
        }
        if(getUrlSegment(2)==='to-follow-up'){
            var category='by-me'
        }
        if(getUrlSegment(2)==='to-oversee'){
            var category='manage-task'
        }
        if(getUrlSegment(2)==='to-know'){
            var category='to-know'
        }
        if(getUrlSegment(2)==='to-comment'){
            var category='Comments'
        }
        if(getUrlSegment(2)==='to-verify'){
            var category='by-me'
            status = "Done"
        }

        var data = {
            category : category,
            status :this.props.searchData.task.status,
            uid : getProfile().id,
            bydue : '',
            priority :this.props.searchData.task.priority,
            project :this.props.searchData.task.project,
            asigntype : '',
            username :'',
            managetask : 'mytask',
            srchval:this.props.searchData.task.srchval,
            limit : 0,
            offset  : 20,
            systype : getProfile().usertype,
        }

        this.props.searchType('SEARCH_TASK_DATA',this.props.searchData.task.srchval,"srchval",data)

    }



    componentWillUnmount() {
        const container = document.querySelector('.main-panel-holder');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.main-panel-holder')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - (offsetHeight + 1);
        //////////////console.log(contentHeight + 'scrolltop' + scrollTop + "H" + offsetHeight)
        if (contentHeight <= scrollTop) {
            if(this.props.sidebarmenudata.todo > this.props.todoListing.toDo.data.length+1) {
                var data = {
                    category: 'to-me',
                    status: "All",
                    uid: getProfile().id,
                    bydue: '',
                    priority: this.props.taskFilters.taskPriorityFilter,
                    project: this.props.taskFilters.taskProjectFilter,
                    asigntype: 'All',
                    managetask: 'mytask',
                    limit: this.props.todoListing.toDo.data.length,
                    offset: this.state.offset,
                    systype: getProfile().usertype,
                    actionType: 'LOADMORE_TODO_DATA'
                }
                this.props.todo(data);

            }
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        //////////////console.log(this.props.todoListing)
        if(prevProps.todoListing  !== this.props.todoListing){
            this.setState({todotaskdata: this.props.todoListing.toDo.data});
        }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            this.props.history.push("/task/to-follow-up")
        }
        if(prevProps.taskFilters  !== this.props.taskFilters){
            this.setState({  limit : 0 },
                function() {
                    this.gettaskList('All');
                })
        }
        if(prevProps.taskSelect.tasksArchived  !== this.props.taskSelect.tasksArchived ){
            setTimeout(function () {
                beta.gettaskList('All');
            }, 1000)
        }
    }
    modalOpen(){
        this.setState({open:!this.state.open,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData});
    }
    gettaskList(status){
        var data = {category : 'by-date', status : status , uid : getProfile().id,  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' , bydue : 'Customdays', customdays: this.state.customDate}
        this.props.todo(data);
    }
    selectTasks(id) {
        this.props.selectTasksToMove(id);
    }

    handleSelect(range){
        ////////////console.log(range);
        // An object with two keys,
        // 'startDate' and 'endDate' which are Momentjs objects.
    }

    handleRangeChange(which, payload) {
        ////////////console.log(which, payload);
        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });
    }

    // loadtasks() {
    //   this.setState({limit : this.state.limit++},
    //     function() {
    //       this.gettaskList('All');
    //     })
    // }
    createmodalClose(){
        this.setState({open : false});
    }
    applyCustomDateRange() {
        ////////////console.log(this.state.dateRange.startDate)
        var startDate = moment.utc(this.state.dateRange.startDate._d).local().format('YYYY-MM-DD');
        var endDate = moment.utc(this.state.dateRange.endDate._d).local().format('YYYY-MM-DD');
        var customDate = startDate+" - "+endDate;
        this.setState({customDate : customDate}, function(){
            this.gettaskList('All');
            this.props.overDuePopup(false)
        })

    }

    render() {
        //////////////console.log(this.props)
        ////////////console.log(this.props.overduepopup.duedata);

        let TodoData=this.props.searchData.task.active? this.props.searchData.data:  this.state.todotaskdata
        return (
            <>
                <div className={'filter-all-wrapper task-home '+ ( this.props.mobToggle.filterToggle ? ' filter-mob-active' :'')}>
                    <div className="float-panel-header show-mob">
                        <h5 className="subheader">Filter By</h5>
                        <button className="ico-lib fly-close" onClick={()=>this.props.mobileFiltertogle(!this.props.mobToggle.filterToggle)}></button>
                    </div>
                    <div className='filter-holder'>
                        {this.props.taskSelect.taskSelection === 'no' &&
                        <FilterBlock/>
                        }

                        <FilerRightBlock taskCount={this.state.todotaskdata.length}/>

                    </div>
                </div>
                {this.props.todoListing.loading &&
                <span className="Loader-holder" style={{'background':'transparent'}}>
                            <LoaderSvg/>
                        </span>
                }
                <div className={this.props.todoListing.loading ? 'main-panel-holder with-loader has-filter' : 'main-panel-holder has-filter'} ref="iScroll">

                    {TodoData.length === 0 && !this.props.todoListing.loading &&
                    <div className='absolute-holder'>
                        <NoData
                            msg={`No task assigned to you`}
                        />
                    </div>
                    }

                    {TodoData.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'unread').map((list,index) =>
                        <React.Fragment>
                            {index === 0 &&
                            <h4 className={'main-panel-head'}>Unread</h4>
                            }
                            <ListCard
                                key={'todo'+index}
                                index={index}
                                list = {list}
                                history={this.props.history}
                                mode='to-me'
                                taskSelection = {this.props.taskSelect.taskSelection}
                                selectTasks = {this.selectTasks.bind(this)}
                                allChecked = {this.props.taskSelect.allChecked}
                                id={list.masterid}
                                tasksource={list.createdfrom}
                                poolenabled={list.poolenabled}
                            />
                        </React.Fragment>
                    )}

                    {TodoData.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'read').map((list,index) =>
                        <React.Fragment key={`list-card-data`+index}>
                            {index === 0 &&
                            <h4 className={'main-panel-head'}>Read</h4>
                            }
                            <ListCard
                                key={'todo'+index}
                                index={index}
                                list = {list}
                                history={this.props.history}
                                mode='to-me'
                                taskSelection = {this.props.taskSelect.taskSelection}
                                selectTasks = {this.selectTasks.bind(this)}
                                allChecked = {this.props.taskSelect.allChecked}
                                id={list.masterid}
                                tasksource={list.createdfrom}
                                poolenabled={list.poolenabled}
                            />
                        </React.Fragment>

                    )}
                </div>
                {this.props.overduepopup.duedata &&
                <SimpleModal

                    Close={()=>this.props.overDuePopup(false)}
                    title={`Custom Date Range`}
                    haveFooter={1}
                    cancelAction={()=>this.props.overDuePopup(false)}
                    OKAction={this.applyCustomDateRange}
                    OKLabel={`Apply`}
                    modalType={'custom-date-range'}
                >
                    <div>
                        <DateRange
                            onChange={this.handleRangeChange.bind(this, 'dateRange')}
                            moveRangeOnFirstSelection={false}
                            className={'PreviewArea'}
                            calendars={1}
                        />
                    </div>


                </SimpleModal>
                }
                <div className={'float-button-box'} onClick={this.modalOpen}>
                    <span className={'icon-in icon-plus'}> </span>
                </div>
            </>
        );
    }
}



const mapStateToProps = state => ({
    listAllProjectData:state.ProjectReducer.listallProjects,
    todoListing:state.TaskReducer.listtoDo,
    taskFilters:state.TaskReducer.filterTasks,
    taskSelect:state.TaskReducer.getTaskSelection,
    menuClickData:state.CommonReducer.menuClick,
    searchData:state.CommonReducer.searchparamdata,
    CreateTask:state.TaskReducer.CreateTask,
    topbardata:state.TaskReducer.TodoTopbarMenudata,
    listtags:state.TaskReducer.ListAllTags,
    saveTodo : state.TaskReducer.saveTodo,
    projects:state.TaskReducer.GetProjectData,
    sidebarmenudata: state.TaskReducer.SidebarMenudata,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    mobToggle: state.CommonReducer.mobileTogle,
    taskdetailsData : state.TaskReducer.TaskDetails,
    overduepopup: state.CommonReducer.overduePopup,
});
export default  withRouter(connect(mapStateToProps, {
    todo:taskActions.listtask,
    toFollowUp:taskActions.listtask,
    mainClick:rootActions.commonActions.MenuClick,
    createTask:taskActions.createtask,
    listTags:projectActions.listalltags,
    selectTasksToMove : taskActions.selectTasksToMove,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    mobileFiltertogle: rootActions.commonActions.mobileFilterToggle,
    searchType: rootActions.commonActions.searchType,
    overDuePopup:taskActions.overDuePopup,

})(CustomDateRange));


