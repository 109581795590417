import rootService from "../services";


const listCustomDateRange = (cat,duedate) => dispatch => {

    let customdaterange = rootService.customdaterangeService.customData(cat,duedate);
    customdaterange.then(customdaterange => {
        dispatch({
            type: "CUSTOM_DATE_RANGE",
            result:customdaterange

        });
    })



};

export const CustomDateRange = ({
    listCustomDateRange,

});

export default CustomDateRange;