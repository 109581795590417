import {forms} from "../services/forms.services";
import {loginForm} from "../services/login.services";
import  rootService from "../services/index";
import  {task} from "../services/task.services";
import {ES_INDEX, SHARED_LOGIN} from '../constants/index'
import {getProfile, logout} from '../utilities/AuthService';
import {projects} from "../services/projects.services";
import {collections} from '../services/collections.services'
import {pool} from "../services/pool.services"
import {getUrlSegment} from "../utilities/CustomFunctions";
import {portfolio} from "../services/portfolio.services";
import {programs} from "../services/programs.services";
import {phases} from "../services/phases.services";
import {roles} from "../services/roles.services";
import {commonService} from "../services/common.services";
import {entity} from "../services/entity.services";
import {users} from "../services/users.services";
const updateDimensions = (wheight, wwidth) => dispatch => {
    dispatch({
        type: "SCREEN_WIDTH_HEIGHT",
        wheight: wheight,
        wwidth: wwidth
    });


};
 const MenuClick = (main,sub) => dispatch => {

        dispatch({
            type: "MENU_UPDATE_SUCCESS",
            mainMenu:main,
            subMenu:sub

        });
         dispatch({
             type: 'PAGE_HEADLINE',
             title: '',
         });



};
const searchType = (action,value,type,data,managecollection="") => dispatch => {


    if(type === 'projects') {
        var current_url = getUrlSegment(2);
        let projectType = (current_url === 'my_projects' ? 'own' : ( current_url === 'shared_projects' ? 'shared' : ''));

        var projectParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: value,
            page : 1,
            per_page: 1000,
            type : projectType
        }

        let projectList = projects.listProject(projectParams);
        dispatch({
            type: 'PROJECTSLIST_LOADER',
            loading:true,

        });
        projectList.then(projectList => {

            dispatch({
                type: action,
                projectList: projectList,
                clearList : true,
                value:value
            })
            dispatch({
                type: 'PROJECTSLIST_LOADER',
                loading:false,

            });
        })

        //SEARCH_PROJECT_DATA
    }
    else if(type === 'sector') {
        var portfolioParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: value,
            page : 1,
            per_page: 500
        }

        let listallportfolio = portfolio.listportfolio(portfolioParams);
        dispatch({
            type: 'PORTFOLIO_LOADER',
            loading:true,

        });
        listallportfolio.then(listallportfolio => {
            dispatch({
                type: "LIST_All_PORTFOLIO",
                listallportfolio: listallportfolio,
                clearList : true,
                value:value

            })
            dispatch({
                type: 'PORTFOLIO_LOADER',
                loading:false,

            });
        })

        //SEARCH_PORTFOLIO_DATA
    }
    else if(type === 'vertical') {

        var programParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: value,
            page : 1,
            per_page: 500
        }
        let listallprograms = programs.listProgram(programParams);
        dispatch({
            type: 'PROGRAMLIST_LOADER',
            loading:true,

        });
        listallprograms.then(listallprograms => {
            dispatch({
                type: 'LIST_All_PROGRAMS',
                listallprograms: listallprograms,
                clearList : true

            })
            dispatch({
                type: 'PROGRAMLIST_LOADER',
                loading:false,

            });
        })
    }
    else if(type === 'phases') {

        var phaseParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: value,
            page : 1,
            per_page: 500
        }
        let listallphases = phases.listphases(phaseParams);
        dispatch({
            type: 'PHASELIST_LOADER',
            loading:true,

        });
        listallphases.then(listallphases => {
            dispatch({
                type: 'LIST_All_PHASE',
                listallphases: listallphases,
                clearList : true

            })
            dispatch({
                type: 'PHASELIST_LOADER',
                loading:false,

            });
        })
    }
    else if(type === 'roles') {

        var roleParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: value,
            page : 1,
            per_page: 500
        }
        let listallroles = roles.listroles(roleParams);
        dispatch({
            type: 'ROLELIST_LOADER',
            loading:true,

        });
        listallroles.then(listallroles => {
            dispatch({
                type: 'LIST_All_ROLE',
                listallroles: listallroles,
                clearList : true

            })
            dispatch({
                type: 'ROLELIST_LOADER',
                loading:false,

            });
        })
    }
    else if(type === 'collections') {

        var collectionparams= {group : data, search : value, uid : getProfile().id, usertype : getProfile().usertype, managecollection : managecollection, limit : 0, offset : 50,has_form:"yes"};
        let collectionList = collections.listCollections(collectionparams)

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-list',
            status:true

        });
        let searchEnable= (!(data === '')||(value===""))
        collectionList.then(collectionList => {
            dispatch({
                type: action,
                listallcollections: collectionList,
                group: data,
                search: value,
                usertype:action.usertype,
                optionOpened: 0,
                searchEnable:searchEnable,
                managecollection:managecollection
            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'collection-list',
                status:false

            });
        })

    }
    else if(type === 'entity') {

        var entityparam = {group: data,uid : getProfile().id, usertype : getProfile().usertype, searchtext : value, page: 1, per_page: 50, index: ES_INDEX,  managecollection : managecollection};
        if(getUrlSegment(1) === 'services'){
            entityparam['selservice']=true
        }
        let entityList = entity.searchEntity(entityparam)

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'entity-list',
            status:true

        });
        let searchEnab= (!(data === '')||(value===""))
        entityList.then(entityList => {
            dispatch({
                type: action,
                listallentity: entityList,
                group: data,
                search: value,
                usertype:action.usertype,
                optionOpened: 0,
                searchEnable:searchEnab,
                managecollection:managecollection
            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'entity-list',
                status:false

            });
        })

    }
    else if (type === "user"){

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'users-list',
            status: true

        });
        let listAllUsers = users.getAllUsers(value);
        listAllUsers.then(listAllUsers => {
            dispatch({
                type: 'LIST_ALLUSERS_DATA',
                listAllUsers: listAllUsers,
                searchParam:value

            });
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'users-list',
                status: false

            });
        })
    }
    else if (type === "userpermission"){

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'userPermission-list',
            status: true

        });
        let listAllusersPermission = users.usersmodulewisepermission(value);
        listAllusersPermission.then(listAllusersPermission => {

            dispatch({
                type: 'ALL_USERMODULEPERMISSION',
                listAllusersPermission: listAllusersPermission,
                searchParam:value

            });

            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'userPermission-list',
                status: false

            });
        })
    }
    else if (type === "group"){
        let params = {
            search :value
        };
        let listGroups = users.listgroups(params);
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType:'usergroup-list',
            status:true
        });
        listGroups.then(listGroups => {
            dispatch({
                type: 'LIST_USERGROUPS',
                listGroups: listGroups,

            });
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType:'usergroup-list',
                status:false
            });
        })
    }
    else{
        dispatch({
            type: action,
            value:value,
            fieldType:type,
            data:data

        });

        data.project=(data.project ===''?"All":data.project)
        data.asigntype=(data.asigntype === ''?"All":data.asigntype)
        data.priority=(data.priority === ''?"All":data.priority)
        data.status=(data.status === ''?"All":data.status)
        data.username=(data.username === ''?"":data.username)
        dispatch({
            type: "SIDEBAR_MENU_SEARCH_LOADER",
            loading:true,

        })


        let taskSearchData = task.listTodo(data);


        taskSearchData.then(taskSearchData => {
            let taskData = taskSearchData.data.results.data;
            dispatch({
                type: 'LIST_ALL_SEARCH_DATA',
                results: taskData,


            })
            dispatch({
                type: "SIDEBAR_MENU_SEARCH_LOADER",
                loading:false,

            })

            dispatch({
                type: "SIDEBAR_MENU_SEARCH_COUNT",
                // archive:taskSearchData.data.results.archive,
                // comments:taskSearchData.data.results.comments,
                // todo:taskSearchData.data.results.statuswise_in.filter(item => item.key == 'New')[0].doc_count + taskSearchData.data.results.statuswise_in.filter(item => item.key == 'InProgress')[0].doc_count,
                // tofollowup:taskSearchData.data.results.statuswise_out.filter(item => item.key == 'New')[0].doc_count + taskSearchData.data.results.statuswise_out.filter(item => item.key == 'InProgress')[0].doc_count,
                // toverify:taskSearchData.data.results.verified_in,
                // toknow:taskSearchData.data.results.statuswise_know.filter(item => item.key == 'New')[0].doc_count + taskSearchData.data.results.statuswise_know.filter(item => item.key == 'InProgress')[0].doc_count,
                // tooversee:taskSearchData.data.results.statuswise_manage.filter(item => item.key == 'New')[0].doc_count + taskSearchData.data.results.statuswise_manage.filter(item => item.key == 'InProgress')[0].doc_count,
                // duetoday:taskSearchData.data.results.today_in,
                // duetomorrow:taskSearchData.data.results.tomorrow_in,
                // duethismaonth:taskSearchData.data.results.month_in,
                // alloverdues:taskSearchData.data.results.overdue_in,
                archive:taskData.archive,
                comments:taskData.comments,
                todo:taskData.todo,
                tofollowup:taskData.followup,
                toverify:taskData.toverify,
                toknow:taskData.toknow,
                tooversee:taskData.oversee,
                duetoday:taskData.today_in,
                duetomorrow:taskData.tomorrow_in,
                duethismaonth:taskData.month_in,
                alloverdues:taskData.overdue_in,
                overdueinsevendays:taskData.overdueinsevendays,
                overdueinfifteendays:taskData.overdueinfifteendays,
                overdueinthirtydays:taskData.overdueinthirtydays,
                overdueinsixtydays:taskData.overdueinsixtydays,



            })





        })
    }






};
 const GetForms = (uid, usertype,type) => dispatch => {
    let getFormsdata = forms.GetForms(uid, usertype,type);

        getFormsdata.then(getFormsdata => {
        dispatch({
            type: 'LIST_ALL_FORMS_CATEGORY',
            getForms: getFormsdata,

        })
    })



};
 const chatReset = () => dispatch => {

        dispatch({
            type: 'CHAT_RESET',
            chatRest: [],

        })
};
 const selectTasksToMove = (id) => dispatch => {
    alert(id);
    /*dispatch({
        type: 'SET_TASK_TO_MOVE',
        id : id

    });*/
};
 const SessionValidate = (sid,uid) => dispatch => {
    let sessionvalidate = loginForm.sessionValidate(sid,uid);
    //console.log(sessionvalidate);
    sessionvalidate.then(sessionvalidate => {
        //console.log(sessionvalidate)
        if(sessionvalidate.data.success) {

        }
        else{
            //alert(2)
        }

    })


};
 const MobileToggle = (togleDta) => dispatch => {
     dispatch({
         type: 'MOBILE_MENU_UPDATE',
         toggle: togleDta,

     })
 }
const mobileFilterToggle = (toggleFilter) => dispatch => {
    dispatch({
        type: 'MOBILE_FILTER_UPDATE',
        toggle:toggleFilter,

})


}
 const loaderStatus = (loaderType,status) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType:loaderType,
        status:status


    })
}
const multiloaderStatus = (loaderType,status) => dispatch => {
    dispatch({
        type: 'MULTI_LOADER_UPDATE_STATUS',
        loaderType:loaderType,
        status:status


    })
}
const UsersOnline = (users) => dispatch => {
    dispatch({
        type: 'ONLINE_USERS',
        onlineUsers:users,


    })


}


 const logOut = (id) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'chatContainer',
        status:true

    })
 if(SHARED_LOGIN.status) {
     let logOut = rootService.chat.logOut(id);
     logOut.then(logOut => {
         var d = new Date();
         d.setTime(d.getTime());
         var expires = "expires=" + d.toUTCString();
         document.cookie = SHARED_LOGIN.cookie+ "=" + "" + ";domain=" + SHARED_LOGIN.subdomain + ";path=/;expires=" + expires;
         setTimeout(function () {
             window.location.href = SHARED_LOGIN.redirect_url
         }, 1000)




     })
 }
 else{
     logout();
     window.location.href = "/";
 }


};
const activityTracking = (params) => dispatch => {
    let activitytracked = commonService.activityTracking(params);
    activitytracked.then(activitytracked => {
        dispatch({
            type: 'ACTIVITY_TRACKING',
            activitytracked: activitytracked
        });
    })

};
const teamClick = (sub) => dispatch => {

    dispatch({
        type: "TEAM_MENU_UPDATE_SUCCESS",
        subMenu:sub

    });

};
 const commonActions = ({
     searchType,
     GetForms,
     chatReset,
     selectTasksToMove,
     SessionValidate,
     MobileToggle,
     mobileFilterToggle,
     loaderStatus,
     logOut,
     MenuClick,
     updateDimensions,
     UsersOnline,
     activityTracking,
     teamClick
});

export default commonActions;
