import React, {Component} from 'react';
import {connect} from "react-redux";
import * as usersActions from "../../../actions/Users"
import {withRouter} from 'react-router';
import {getProfile} from "../../../utilities/AuthService";
import {USRIMG_PATH, ES_INDEX} from '../../../constants'
import {Sector} from 'recharts';
import rootActions from "../../../actions";
import * as collectionActions from "../../../actions/Collections";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import {Scrollbars} from "react-custom-scrollbars";
let userActions = [
    {"action_id": 1,"actions": "Add"},
    {"action_id": 2,"actions": "Edit"},
    {"action_id": 3,"actions": "Delete"},
    {"action_id": 5,"actions": "Download"}];

class UserPermission extends Component {


    constructor(props) {
        super(props);

        this.state={
            drop : false,
            userprmsn : '',
            intervl: 'day',
            offset: 20,
            activeIndex: null,
            userspermissions: [],
            selectAll: 0,
            showDeleteConfirm : false,
            showFormModal: false,
            collectionId: ES_INDEX + '10003',
            moduleData:[],
            actModule:[],
            status:true,
            usersactions:[],
            addNewTask:false,
            userDetails:{},
            loginprfrmnc :'day',
            profileId : 0,


            module:[]
        }

    }

    componentDidMount() {
        this.props.mainClick('team','details');
        /* permission api call */
        var uId = atob(getUrlSegment(3));
        this.props.usermodulepermission({uid:uId});


    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.listUsersActions1 !== prevProps.listUsersActions1){
            if(this.props.listUsersActions1!==0){
                this.setState({UsersPermission:this.props.listUsersActions1.UsersPermission,module:this.props.listUsersActions1.module})
            }
            //console.log(this.props.listUsersActions1)
        }
    }
    resetModulePermission=()=>{
        let  index=this.state.actModule.indexOf("all")
        let module=this.state.module
        if(index === -1) {
            let actModule = ["all"]
            module.map((list)=> {
                list.module_permission = "yes";
                list.actions.map((actionStat)=> {
                    actionStat.action_permission = "yes";
                })
                if(list.submodule.length !==0 ){
                    list.submodule.map((actionStat)=> {
                        actionStat.module_permission = "yes";
                        actionStat.actions.map((actionSub)=> {
                            actionSub.action_permission = "yes";
                        })
                    })


                }




            })
            this.setState({actModule})
        }
        else{
            let actModule = []
            module.map((list)=> {
                list.module_permission = "no";
                list.actions.map((actionStat)=> {
                    actionStat.action_permission = "no";
                })

                if(list.submodule.length !==0 ){
                    list.submodule.map((actionStat)=> {
                        actionStat.module_permission = "no";
                        actionStat.actions.map((actionSub)=> {
                            actionSub.action_permission = "no";
                        })
                    })
                }

            })
            this.setState({actModule})
        }
    }
    handleModulePermission(e, moduleId,index) {
        let module = [...this.state.module];
        let allPermitd = module.filter( module => module.module_id === moduleId)
        if(allPermitd[0].module_permission === "yes"){
            allPermitd.map((list)=> {
                list.module_permission = "no";
                list.actions.map((actionStat)=> {
                    actionStat.action_permission = "no";
                })
            })

        }
        else{
            allPermitd.map((list)=> {
                list.module_permission = "yes";
                list.actions.map((actionStat)=> {
                    actionStat.action_permission = "yes";
                })
            })

        }

    }
    handlePermission(e, moduleId, actionId) {
        let usrPermisns = [...this.state.module];
        let action = usrPermisns.filter( module => module.module_id === moduleId)[0].
        actions.filter( action => action.action_id === actionId)
        //console.log(action)
         action.map((list)=>{
            list.action_permission= (list.action_permission  === 'yes' ? 'no' : 'yes');
           })
       this.setState({usrPermisns})
    }
    level1Submodule=(subMod,ModlueId)=>{
        let usrPermisns =[...this.state.module];
        let level1=usrPermisns.filter(list=>list.module_id === ModlueId)
        let level2=level1[0].submodule.filter(list=>list.module_id === subMod)
        //console.log(level1)
        //console.log(level2)
        if(level2[0].module_permission === "yes"){
            level2[0].module_permission = "no";
            level2[0].actions.map((actionStat)=> {
                actionStat.action_permission = "no";
            })
        }
        else{
            level2[0].module_permission = "yes";
            level2[0].actions.map((actionStat)=> {
                actionStat.action_permission = "yes";
            })
        }
        this.setState({usrPermisns})
    }


    savePermissions=()=> {
        //console.log(this.state.module)
        var userId = atob(getUrlSegment(3));
        var creator = getProfile().id;
        //let usrPermisns = [...this.state.userspermissions];
        let usrPermisns =[...this.state.module];
        //console.log(usrPermisns)
        let permission = [];
        let mode = 'update';
        ////////console.log(usrPermisns)
        usrPermisns.map(module => {
            //console.log(module)
            let actions = [];
            let subpermissions=[];

            if(module.length !== 0) {
                module.actions.map(action => {
                    actions.push({id: action.action_id, permission: action.action_permission});
                });
                permission.push({
                    module: module.module_id,
                    module_permission: module.module_permission,
                    action: actions,
                    // submodule:module.submodule ? this.rendersavePermission(module.submodule):[]
                });
            }



            if(module.submodule.length !== 0){

                module.submodule.map(list=> {
                    let subActions = [];
                    list.actions.map(action => {
                        subActions.push({id: action.action_id, permission: action.action_permission});
                    });
                    permission.push({
                        module: list.module_id,
                        module_permission: list.module_permission,
                        action: subActions,
                    });
                })

            }


        });
        //console.log(permission)
        let params={
            "user_id": userId,
            "creator": creator,
            "permission": permission,
            "mode": mode,
        }
        this.props.saveUserPermissions(params);
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "saveuserpermission",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
     //   this.props.activityTracking(trackparams);

    }

    renderedModule(submodule,mod,module_id){
        if(submodule.length !== 0){
            return(
                <div className={`has-submodule-container`}>
                    {submodule.map((list,index)=>
                        <React.Fragment>
                            {/*<div onClick={()=>this.moduleActive(list.module)}>{list.module}</div>*/}

                            {/*<tr key={`list-module-${index}`} style={this.state.moduleData.indexOf(list.module) === -1?{"display":""}:{"display":""}}>*/}
                            <div className="user-tr"  key={`list-module-${index}`}>
                                {list.actions.map((usrprmsn, index) =>
                                    <React.Fragment>
                                        {index === 0 &&
                                        <div className="user-td">
                                            <div className={`submodule-action`}>
                                                <label className="checkbox-in">
                                                    <input type="checkbox" className="cs-checkbox"
                                                           value="all-permission"
                                                           onClick={() => this.level1Submodule(list.module_id,module_id)}
                                                        //checked={this.state.actModule.includes(mod)}
                                                           defaultChecked={list.module_permission === "yes"}
                                                           checked={list.module_permission === "yes"}
                                                    />
                                                    <span className="cs-checkbox-pl"></span>
                                                </label>
                                                <label className="checkbox-text" htmlFor="dashboard">{list.module}</label>

                                            </div>
                                        </div>
                                        }
                                        <div className="user-td">
                                            <div className="has-checkbox">
                                                <label className="checkbox-in">
                                                    <input type="checkbox" className="cs-checkbox" value="all-permission"
                                                           defaultChecked={usrprmsn.action_permission === "yes"}
                                                           checked={usrprmsn.action_permission === "yes"}
                                                           disabled={`${list.module_permission === "yes" ? "" : "disabled"}`}

                                                        //disabled={`${this.state.actModule.includes(mod) ? "" : "disabled"}`}
                                                           onChange ={(e) => {this.handleSubPermission(e, list.module_id, usrprmsn.action_id,module_id)}}
                                                        // onClick={()=>this.moduleActive(list.module)}
                                                    />
                                                    <span className="cs-checkbox-pl"></span>
                                                </label>
                                            </div>
                                        </div>

                                    </React.Fragment>

                                )}
                            </div>
                            {/*</tr>*/}
                            {/*{this.renderedModule(list.submodule,mod,module_id)}*/}
                        </React.Fragment>

                    )}
                </div>
            );
        }
        else{
            return null
        }
    }



    handleSubPermission=(e, submoduleId, actionId,moduleId) =>{
        let actionpermissions = this.state.usersactions.length === 0 ? this.props.listUsersActions1.module:this.state.usersactions
        actionpermissions.map((item)=>{
            if(item.module_id === moduleId) {
                item.submodule.map((sub) => {
                    if(sub.module_id === submoduleId) {
                        sub.actions.map((act) => {
                            if(act.action_id === actionId) {
                                act.action_permission = act.action_permission === "yes" ? "no" : "yes";
                            }
                        })

                    }
                })



            }
        })
        this.setState({usersactions1:actionpermissions})
        //this.validateAllPermissions();
    }
    selectAllPermissions(usrPermisns,permission) {
        usrPermisns.map(module => {
            this.selectModulePermissions(usrPermisns, module.id, permission);
        })
        this.setState({
            userspermissions: usrPermisns,
            selectAll: permission
        });
    }
    selectModulePermissions(usrPermisns, moduleId, permission) {
        usrPermisns.filter( module => module.id === moduleId)[0].allPermtd = permission;
        usrPermisns.filter( module => module.id === moduleId)[0].actions.map((action,index) => {
            this.selectActionPermission(usrPermisns, moduleId, action.id, permission === 1 ? 'yes' : 'no');
        })
        this.setState({
            userspermissions: usrPermisns
        });
    }
    selectActionPermission(usrPermisns, moduleId, actionId, permission) {
        let action = usrPermisns.filter( module => module.id === moduleId)[0].actions.filter( action => action.id === actionId);
        action[0].permission = permission;
        this.setState({
            userspermissions: usrPermisns
        });
    }
    handleSelectAll() {
        let usrPermisns = [...this.state.userspermissions];
        let selectAll = this.state.selectAll;
        selectAll = selectAll === 1 ? 0 : 1;
        this.selectAllPermissions(usrPermisns, selectAll);
    }

    validateAllPermissions() {
        let usrPermisns = [...this.state.userspermissions];
        let selectAll = 1;
        usrPermisns.map(module => {
            let modulePerm = 1;

            module.actions.map(action => {
                if(action.permission === 'no') {
                    modulePerm = 0;
                    selectAll = 0;
                }
            })
            module.allPermtd = modulePerm;
            if(module.allPermtd === 0) {
                selectAll = 0
            }
        })
        this.setState({
            userspermissions: usrPermisns,
            selectAll: selectAll
        });
    }

    rendersavePermission(submodule){
        let subpermissions = [];
        let subid=''
        submodule.map(submodule=> {
            let actions = [];

            submodule.actions && submodule.actions.map((action,index) => {
                actions.push({id: action.action_id, permission: action.action_permission});

                if(subid !== submodule.module_id) {
                    subpermissions.push({
                        module: submodule.module_id,
                        action: actions,
                        submodule: (submodule.submodule ? this.rendersavePermission(submodule.submodule) : [])
                    });
                }
                subid=submodule.module_id
            });
        })

        return subpermissions;
    }
    moduleActive=(mid)=>{

        let  index=this.state.moduleData.indexOf(mid)
        if(index === -1)
        {
            this.setState({
                moduleData: [...this.state.moduleData, mid],
            });
        }
        else{
            this.setState({
                moduleData: this.state.moduleData.filter((_, i) => i !== index)
            });
        }
    }
    actModule=(e,mid)=>{
        let  index=this.state.actModule.indexOf(mid)
        let all=this.state.actModule.includes("all")
        if(all){
            if(index === -1) {
                this.setState({
                    actModule: [...this.state.actModule, mid],
                });
            }
            else{
                this.setState({
                    actModule: this.state.actModule.filter((_, i) => i !== index)
                });
            }
        }
        else {
            if (index === -1) {
                this.setState({
                    actModule: [...this.state.actModule,mid],
                });
            } else {
                this.setState({
                    actModule: this.state.actModule.filter((_, i) => i !== index)
                });
            }
        }
    }



    userModulePermission=()=>{
        this.setState({status:!this.state.status})
        let  index=this.state.actModule.indexOf("all")
        if(index === -1) {
            let actModule = ["all"]
            this.props.listUsersActions1.module.map((list) =>
                actModule.push(list.displayname)
            )
            this.setState({
                actModule: actModule,
            })
        }
        else{
            let actModule = []
            this.setState({
                actModule: actModule,
            })
        }

        /****************/
        let status = this.state.status;
        let modulepermissions = this.props.listUsersActions1.module
        modulepermissions.map((item)=>{
            item.module_permission = (status === true) ? "yes" : "no";
            item.actions.map((act)=>{
                act.action_permission = (status === true) ? "yes" : "no";
            })
            {this.renderPermission(item.submodule,status)}
        })
        ////////console.log(modulepermissions)
    }
    renderPermission(submodule,status){
        submodule.map((list)=>{
            list.module_permission = (status === true) ? "yes" : "no";
            list.actions.map((act)=>{
                act.action_permission = (status === true) ? "yes" : "no";
            })
            {this.renderPermission(list.submodule,status)}
        })
    }


    render() {
        let usersactions1 = this.state.module
        //console.log(usersactions1)

        return (



                <div className="tab-pane">
                    <div className="tab-wrapper user-no-pad">
                        <div className="user-table-wrapper wid-40">
                            <div className="user-table">
                                <div className="user-table-header">
                                    <div className="user-th">
                                        <div className="has-checkbox">
                                            <label className="checkbox-in">
                                                <input type="checkbox" className="cs-checkbox"
                                                       checked={this.state.actModule.includes("all")}
                                                       value="all-permission" id="all-select"
                                                       onClick={() => this.resetModulePermission()}/>
                                                <span className="cs-checkbox-pl"></span>
                                            </label>
                                            <label className="checkbox-text checkbox-text-b" htmlFor="all-select">
                                                User permissions
                                            </label>
                                        </div>
                                    </div>
                                    {userActions.map((usract, index) =>
                                        <div className="user-th">{usract.actions}</div>
                                    )}
                                </div>
                                <Scrollbars style={{"height": "calc(100% - 40px)"}}>
                                    <div className="user-table-body">
                                        {usersactions1.map((usrprmsn, index) =>
                                            <>
                                                <div className="user-tr"  key={`list-module-${index}`}>
                                                    <div className="user-td">
                                                        <div className="has-checkbox">
                                                            <label className="checkbox-in">
                                                                <input type="checkbox" className="cs-checkbox" value="all-permission" onClick={(e) => {
                                                                    this.actModule(e, usrprmsn.displayname);
                                                                    this.handleModulePermission(e, usrprmsn.module_id, index)
                                                                }}
                                                                       defaultChecked={usrprmsn.module_permission === "yes"}
                                                                       checked={usrprmsn.module_permission === "yes"}
                                                                    //checked={this.state.actModule.includes(usrprmsn.displayname)}
                                                                />
                                                                <span className="cs-checkbox-pl"></span>
                                                            </label>
                                                            <label className="checkbox-text" htmlFor="dashboard">{usrprmsn.displayname}</label>
                                                        </div>
                                                    </div>
                                                    {usrprmsn.actions.map((usract, index) =>
                                                        <div className="user-td">
                                                            <label className="checkbox-in">
                                                                <input type="checkbox" className="cs-checkbox" value="all-permission"
                                                                       id={usrprmsn.module_id + '-' + usract.module_id}
                                                                       defaultChecked={usract.action_permission === "yes"}
                                                                       checked={usract.action_permission === "yes"}
                                                                       onChange={(e) => {
                                                                           this.handlePermission(e, usrprmsn.module_id, usract.action_id)
                                                                       }}
                                                                       disabled={`${usrprmsn.module_permission === "yes" ? "" : "disabled"}`}
                                                                />
                                                                <span className="cs-checkbox-pl"></span>
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                                {this.renderedModule(usrprmsn.submodule, usrprmsn.displayname, usrprmsn.module_id)}
                                            </>
                                        )}
                                    </div>
                                </Scrollbars>
                            </div>
                        </div>
                        <div className="user-table-btn-wrapper">
                            <button className="button submit-button" onClick={this.savePermissions}>Submit</button>
                        </div>
                    </div>
                </div>




        );
    }
}



const mapStateToProps = state => ({

    listUsersActions1:state.UsersReducer.listUserModulePermissiondata,
    getSavedForm : state.CollectionsReducer.getSavedForm,

});
export default  withRouter(connect(mapStateToProps, {
    usermodulepermission:usersActions.listUserModulePermission,
    mainClick:rootActions.commonActions.MenuClick,
    saveUserPermissions:usersActions.saveUserPermissions,
    activityTracking:rootActions.commonActions.activityTracking
})(UserPermission));


