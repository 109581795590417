import {combineReducers} from 'redux';

const excelImportStatus = (state = {data: []}, action) => {
    switch (action.type) {

        case "EXCEL_IMPORT_UPDATE_ALL":
            return {
                ...state,
                data: action.importExcelData

            };

        default:
            return state;
    }
};

const pcsaveData = (state = {data: []}, action) => {
    switch (action.type) {

        case "PC_COLLECTION_LINKED_DATA":
            return {
                ...state,
                data: action.pcsaveData,
                type: 'adding'

            };
            break;
        case "PC_COLLECTION_LINKED_DATA_REMOVAL":
            return {
                ...state,
                data: action.pcremoveData,
                type: 'removal'

            };
            break;


        default:
            return state;
    }
};
const listLinkedItems = (state = {data: []}, action) => {
    switch (action.type) {

        case "GET_LINKED_ITEMS":
            return {
                ...state,
                data: action.linkeditems.data.data,
            };
            break;
        default:
            return state;
    }
};

const listFieldPropagation = (state = {data: []}, action) => {
    switch (action.type) {

        case "LIST_FIELD_PROPAGATION":
            return {
                ...state,
                data: action.fieldPropagationlist.data,
            };
            break;
        default:
            return state;
    }
};
const saveFieldPropagationData = (state = {data: [], fieldPropagationSaved: false}, action) => {
    switch (action.type) {

        case "SAVE_FIELD_PROPAGATION":
            return {
                ...state,
                data: action.saveFieldPropagationData.data,
                fieldPropagationSaved: !state.fieldPropagationSaved
            };
            break;
        default:
            return state;
    }
};
const removeFieldPropagationData = (state = {data: [], fieldPropagationRemoved: false}, action) => {
    switch (action.type) {

        case "REMOVE_FIELD_PROPAGATION":
            return {
                ...state,
                data: action.removeFieldPropagationData.data,
                fieldPropagationRemoved: !state.fieldPropagationRemoved
            };
            break;
        default:
            return state;
    }
};
const formFieldsData = (state = {data: []}, action) => {
    switch (action.type) {

        case "GET_FORM_FIELDS":
            return {
                ...state,
                data: action.formFields.data.data,
            };
            break;
        default:
            return state;
    }
};

const listFieldVer = (state = {data: [], totalchilds: "", loader: false}, action) => {
    switch (action.type) {
        case "LOADER_FIELD_VERSION":
            return {
                ...state,
                data: [],
                loader: true,
                totalchilds: ""
            };

        case "FIELD_VERSION":
            return {
                ...state,
                data: action.fieldVer.data.data,
                loader: false,
                totalchilds: action.fieldVer.data.totalchilds
            };
        default:
            return state;
    }
};
const shareAllInfo = (state = {data: []}, action) => {
    switch (action.type) {

        case "SHARE_ALL":
            return {
                ...state,
                data: action.sharealldata.data.results,
            };
        default:
            return state;
    }
};
const getFlag = (state = {data: []}, action) => {
    switch (action.type) {

        case "FLAG_LIST":
            return {
                ...state,
                data: action.getFlagList.data.data,
                category: action.getFlagList.data.category,
                settings: action.getFlagList.data.settings,
            };
        default:
            return state;
    }
};
const saveFlags = (state = {data: []}, action) => {
    switch (action.type) {

        case "FLAG_SAVE":
            return {
                ...state,
                data: action.saveFlagList.data,
            };
        default:
            return state;
    }
};
const delFlagData = (state = {data: []}, action) => {
    switch (action.type) {

        case "FLAG_DELETE":
            return {
                ...state,
                data: action.deleteFlagData.data,
            };
        default:
            return state;
    }
};
const delCategoryData = (state = {data: []}, action) => {
    switch (action.type) {

        case "CATEGORY_DELETE":
            return {
                ...state,
                data: action.deleteFlagCategory.data,
            };
        default:
            return state;
    }
};
const removeAssignFlagData = (state = {data: []}, action) => {
    switch (action.type) {

        case "REMOVE_ASSIGNED_FLAG":
            return {
                ...state,
                data: action.removeFlagData.data,
            };
        default:
            return state;
    }
};
const assignFlagData = (state = {data: []}, action) => {
    switch (action.type) {

        case "ASSIGN_FLAG":
            return {
                ...state,
                data: action.assignFlagData.data,
            };
        default:
            return state;
    }
};
const ColUpdate = (state = {data: []}, action) => {
    switch (action.type) {

        case "FlAG-COL-UPDATE":
            return {
                ...state,
                data: action.status,
            };
        default:
            return state;
    }
};
const savedNoteShare = (state = {data: []}, action) => {
    switch (action.type) {

        case "SAVE_NOTE_SHARE":
            return {
                ...state,
                data: action.savedNoteShare,
            };
        default:
            return state;
    }
};
const listShareNote = (state = {data: []}, action) => {
    switch (action.type) {

        case "LIST_NOTE_SHARE":
            return {
                ...state,
                data: action.listShareNotes.data.data,
            };
        default:
            return state;
    }
};
const categorylist = (state = {data: []}, action) => {
    switch (action.type) {

        case "FLAG_CATEGORY":
            return {
                ...state,
                data: action.getFlagCategory.data.data,
            };
        default:
            return state;
    }
};
const categorySave = (state = {data: []}, action) => {
    switch (action.type) {

        case "SAVE_CATEGORY":
            return {
                ...state,
                data: action.saveCategory.data,
            };
        default:
            return state;
    }
};
const listQuickForm = (state = {data: []}, action) => {
    switch (action.type) {

        case "LIST_QUICKFORM":
            return {
                ...state,
                data: action.quickFormList.data.data,
                roles: action.quickFormList.data.roles,
            };
        default:
            return state;
    }
};
const savedQuickForm = (state = {data: []}, action) => {
    switch (action.type) {

        case "SAVE_QUICKFORM":
            return {
                ...state,
                data: action.saveQuickForm.data,
            };
        default:
            return state;
    }
};

const saveFullwidth = (state = {data: []}, action) => {
    switch (action.type) {

        case "SAVE_FULLWIDTH_IDS":
            return {
                ...state,
                data: action.data.data,
                loader: action.loader
            };
        default:
            return state;
    }
};

const quickFormData = (state = {data: []}, action) => {
    switch (action.type) {

        case "QUICKFORM_DETAIL":
            return {
                ...state,
                data: action.quickFormDetail.data.data,
            };
        default:
            return state;
    }
};
const quickFormDeleted = (state = {data: []}, action) => {
    switch (action.type) {

        case "QUICKFORM_DELETE":
            return {
                ...state,
                data: action.deleteQuickForm.data,
            };
        default:
            return state;
    }
};
const roleQuickForm = (state = {data: []}, action) => {
    switch (action.type) {

        case "QUICKFORM_ROLE":
            return {
                ...state,
                data: action.assignRoleQuickForm.data,
            };
        default:
            return state;
    }
};
const manageQuickformShow = (state = {data: ''}, action) => {
    switch (action.type) {

        case "MANAGE_QUICKFORM":
            return {
                ...state,
                data: action.manageQuickform.data,
            };
        default:
            return state;
    }
};
const defaultQuickform = (state = {data: ''}, action) => {
    switch (action.type) {

        case "DEFAULT_QUICKFORM":
            return {
                ...state,
                data: action.setdefaultQuickform.data,
            };
        default:
            return state;
    }
};
const formTemplateList = (state = {data: []}, action) => {
    switch (action.type) {

        case "TEMPLATE_LIST":
            return {
                ...state,
                data: action.templateList.data.data,
            };
        default:
            return state;
    }
};


const FilterdList = (state = {data: []}, action) => {
    switch (action.type) {

        case "GET_FILTERED_VIEW":
            return {
                ...state,
                data: action.getFilterdList.data.data,
                loader: false,
            };
        case "GET_FILTERED_VIEW_LOADER":
            return {
                ...state,
                loader: true,
            };
        default:
            return state;
    }
};

const removeFilterdata = (state = {data: []}, action) => {
    // console.log(action.deleteFilter.data.time)

    switch (action.type) {
        case "DELETE_FILTERED_VIEW":
            return {
                ...state,
                data: action.deleteFilter.data.time,
                loader: false
            };

        case "DELETE_FILTERED_VIEW_LOADER":
            return {
                ...state,
                loader: true
            };


        default:
            return state;
    }
};

const savefilterData = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SAVE_FILTERED_VIEW":
            return {
                ...state,
                data: action.savefilteredview.data.data,
                loader: false
            };
        case "SAVE_FILTERED_VIEW_LOADER":
            return {
                ...state,
                loader: true,
            };
        default:
            return state;
    }
};

const savedflagOpen = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SAVE_FLAG_OPEN":
            return {
                ...state,
                data: action.saveFlagOpen.data.data,
                loader: false
            };
        default:
            return state;
    }
};

const buttonList = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "BUTTON_LIST":
            return {
                ...state,
                data: action.getButtonList.data.data,
                loader: false
            };
        default:
            return state;
    }
};
const saveButtons = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SAVE_BUTTON_LIST":
            return {
                ...state,
                data: action.saveButtonList.data,
                loader: false
            };
        default:
            return state;
    }
};
const delButtonData = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "DELETE_BUTTON_LIST":
            return {
                ...state,
                data: action.deleteButtonData.data,
                loader: false
            };
        default:
            return state;
    }
};
const saveRoles = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SAVE_ROLE_LIST":
            return {
                ...state,
                data: action.saveRoleList.data,
                loader: false
            };
        default:
            return state;
    }
};
const roleList = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "ROLE_LIST":
            return {
                ...state,
                data: action.getRoleList.data.data,
                loader: false
            };
        default:
            return state;
    }
};
const delRoleData = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "DELETE_ROLE_LIST":
            return {
                ...state,
                data: action.deleteRoleData.data,
                loader: false
            };
        default:
            return state;
    }
};

const permissionList = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "LIST_PERMISSION":
            return {
                ...state,
                data: action.getRolePermission.data.data,
                loader: false
            };
        default:
            return state;
    }
};
const permissionSave = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SAVE_PERMISSION":
            return {
                ...state,
                data: action.savePermission.data.results,
                loader: false
            };
        default:
            return state;
    }
};

const getFullwidth = (state = {data: []}, action) => {
    switch (action.type) {

        case "LIST_FULLWIDTH_DATA":
            return {
                ...state,
                data: action.data,
                loader: action.loader
            };
        default:
            return state;
    }
};

const ListPoolData = (state = {pool: []}, action) => {
    switch (action.type) {

        case "POOL_LIST":
            return {
                ...state,
                pool: action.listPool.data.data.pools
            };
        default:
            return state;
    }
};

const resultSetData = (state = {result: []}, action) => {
    switch (action.type) {

        case "RESULT_SET":
            return {
                ...state,
                result: action.resultSet.data
            };
        default:
            return state;
    }
};

const fieldUpdateData = (state = {data: []}, action) => {
    switch (action.type) {

        case "FIELD_UPDATE_COL_ENTITY":
            return {
                ...state,
                data: action.data.data.results
            };
        default:
            return state;
    }
};
const saveSummaryInfo = (state = {data: {}, loader: false, saved: false}, action) => {
    switch (action.type) {

        case "SAVE_SUMMARY_SETTINGS":
            return {
                ...state,
                data: action.data,
                loader: action.loader,
                saved: !state.saved
            };
        default:
            return state;
    }
};
const SaveDate = (state = {data: {}, loader: false}, action) => {
    switch (action.type) {

        case "SAVE_BOOKING_DATA":
            return {
                ...state,
                data: action.data,
                loader: action.loader

            };
        default:
            return state;
    }
};

const sendFieldEmailStatus = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SEND_EMAILS":
            return {
                ...state,
                data: action.emails.data,
                loader: false
            };
        default:
            return state;
    }
};
const sendFieldSMStatus = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SEND_SMS":
            return {
                ...state,
                data: action.sms.data,
                loader: false
            };
        default:
            return state;
    }
};
const SavecalSetngs = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SAVE_CAL_SET_DATA":
            return {
                ...state,
                data: action.setngs,
                loader: action.loader
            };
        default:
            return state;
    }
};

const summaryDetails = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SUMMARY_PANEL_DETAILS":
            return {
                ...state,
                data: action.summaryDetails.data,
                loader: false
            };
        default:
            return state;
    }
};

const sharedFormUrl = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SHARED_FORM_DETAILS":
            return {
                ...state,
                data: action.sharedForms.data,
                loader: false
            };
        default:
            return state;
    }
};

const recordDuplicate = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "DUPLICATE_RECORD":
            return {
                ...state,
                data: action.collectionRecordDuplicate.data,
                loader: false
            };
        default:
            return state;
    }
};
const getFieldList = (state = {fieldList: []}, action) => {
    switch (action.type) {

        case "FIELD_LIST_SUCCESS":
            return {
                ...state,
                fieldList: action.getFieldValues.data.results.data
            };
        default:
            return state;
    }
};
const paymentData = (state = {data: [], success: false, message: ''}, action) => {
    switch (action.type) {

        case "PAYMENT_DATA":
            return {
                ...state,
                data: action.getPaymentData.data.results.data,
                whatsapp: action.getPaymentData.data.results.whatsapp,
                success: action.getPaymentData.data.results.success,
                message: action.getPaymentData.data.results.message
            };
        default:
            return state;
    }
};
const paymentDetail = (state = {data: [], success: false, message: ''}, action) => {
    switch (action.type) {

        case "PAYMENT_DETAIL":
            return {
                ...state,
                data: action.getPaymentDetail.data.results.data,
                success: action.getPaymentDetail.data.results.success,
                message: action.getPaymentDetail.data.results.message
            };
        default:
            return state;
    }
};
const savedPaymentUrl = (state = {payurl: []}, action) => {
    switch (action.type) {

        case "SAVE_PAYMENT_URL":
            return {
                ...state,
                payurl: action.savePaymentUrl.data
            };
        default:
            return state;
    }
};
const savedPaymentUrlRazorpay = (state = {payurlRazorpay: []}, action) => {
    switch (action.type) {

        case "RAZORPAY_SAVE_PAYMENT_URL":
            return {
                ...state,
                payurlRazorpay: action.savePaymentUrlRazorpay.data
            };
        default:
            return state;
    }
};
const sendFieldWhatsappStatus = (state = {data: [], loader: false}, action) => {
    switch (action.type) {

        case "SEND_WHATSAPP":
            return {
                ...state,
                data: action.whatsapp.data,
                loader: false
            };
        default:
            return state;
    }
};
const paymentInfoResult = (state = {data: ''}, action) => {
    switch (action.type) {
        case "SAVE_PAYMENT_INFO":
            return {
                ...state,
                data: action.paymentInfoSave
            };
            break;
        default:
            return state;
    }
};
const savePrintDeatilsInfo = (state = {data: {}}, action) => {
    switch (action.type) {
        case "SAVE_PRINT_DETAILS":
            return {
                ...state,
                data: action.savePrintDetailsInfo
            };
            break;
        default:
            return state;
    }
};
const removePrintDeatilsInfo = (state = {data: {}}, action) => {
    switch (action.type) {
        case "REMOVE_PRINT_DETAILS":
            return {
                ...state,
                data: action.removePrintDetailsInfo
            };
            break;
        default:
            return state;
    }
};
const savePrintSettingsInfo = (state = {data: {}}, action) => {
    switch (action.type) {
        case "SAVE_PRINT_SETTINGS":
            return {
                ...state,
                data: action.saveprintsettingsInfo
            };
            break;
        default:
            return state;
    }
};
const printDetails = (state = {data: []}, action) => {
    switch (action.type) {
        case "GET_PRINT_SETTINGS":
            return {
                ...state,
                data: action.printDetails
            };
            break;
        default:
            return state;
    }
};

const savedPaymentList = (state = {data: []}, action) => {
    switch (action.type) {
        case "SAVE_PAYMENT_DATA":
            return {
                ...state,
                paymentItem: action.savePayments
            };
            break;
        default:
            return state;
    }
};

const  getsaveHeadersettings = (state = {data: []}, action) => {
    switch (action.type) {
        case "SAVE-HEADER-SETTINGS":
            return {
                ...state,
                data: action.data
            };

        default:
            return state;
    }
};
const  getColorsettings = (state = {data: []}, action) => {
    switch (action.type) {
        case "SAVE-COLOR-SETTINGS":
            return {
                ...state,
                data: action.data
            };

        default:
            return state;
    }
};
const advancedSumpanel = (state = {data: {}, loader: false, saved: false}, action) => {
    switch (action.type) {

        case "SAVE_ADVANCED_SUMPANEL":
            return {
                ...state,
                data: action.data,
                loader: action.loader,
                saved: !state.saved
            };
        default:
            return state;
    }
};
const listCustomapi = (state = {data: [], loader: false, saved: false}, action) => {
    switch (action.type) {

        case "GET_CUSTOM_API":
            return {
                ...state,
                data: action.data,
                loader: action.loader,
                success:action.success
            };
        default:
            return state;
    }
};
const destLabels = (state = {showformdata: []}, action) => {
    switch (action.type) {
        case "SUMMARY_FORM_LABELS":
            return {
                ...state,
                showformdata: action.showformdata,

            };
            break;

        default:
            return state;
    }
};
const saveDisplaysettingInfo = (state = {data: {}, loader: false, saved: false}, action) => {
    switch (action.type) {

        case "SAVE_DISPLAY_SETTINGS":
            return {
                ...state,
                data: action.data,
                loader: action.loader,
                saved: !state.saved
            };
        default:
            return state;
    }
};

const saveAdvstatus = (state = {data: {}, loader: false, saved: false}, action) => {
    switch (action.type) {

        case "ADVANCED_PANEL_SETTINGS":
            return {
                ...state,
                data: action.data,

            };
        default:
            return state;
    }
};
const kanbanSettingsList = (state = {data: [], loader: false, }, action) => {
    switch (action.type) {

        case "GET_KANBAN_SETTINGS":
            return {
                ...state,
                data: action.data,
                loader: action.loader,
            };
        default:
            return state;
    }
};
export const CommonReducer = combineReducers({
    excelImportStatus,
    pcsaveData,
    listLinkedItems,
    listFieldPropagation,
    saveFieldPropagationData,
    removeFieldPropagationData,
    formFieldsData,
    listFieldVer,
    shareAllInfo,
    getFlag,
    saveFlags,
    delFlagData,
    delCategoryData,
    removeAssignFlagData,
    assignFlagData,
    ColUpdate,
    savedNoteShare,
    listShareNote,
    categorylist,
    categorySave,
    listQuickForm,
    savedQuickForm,
    quickFormData,
    roleQuickForm,
    quickFormDeleted,
    formTemplateList,
    manageQuickformShow,
    defaultQuickform,
    FilterdList,
    removeFilterdata,
    savefilterData,
    savedflagOpen,
    buttonList,
    saveButtons,
    delButtonData,
    saveRoles,
    roleList,
    delRoleData,
    permissionList,
    permissionSave,
    getFullwidth,
    saveFullwidth,
    ListPoolData,
    resultSetData,
    fieldUpdateData,
    saveSummaryInfo,
    SaveDate,
    sendFieldEmailStatus,
    sendFieldSMStatus,
    SavecalSetngs,
    summaryDetails,
    sharedFormUrl,
    recordDuplicate,
    getFieldList,
    paymentData,
    savedPaymentUrl,
    savedPaymentUrlRazorpay,
    paymentDetail,
    paymentInfoResult,
    sendFieldWhatsappStatus,
    savePrintDeatilsInfo,
    removePrintDeatilsInfo,
    savePrintSettingsInfo,
    printDetails,
    savedPaymentList,
    getsaveHeadersettings,
    getColorsettings,
    saveDisplaysettingInfo,
    advancedSumpanel,
    listCustomapi,
    destLabels,
    saveAdvstatus,
    kanbanSettingsList

});

export default CommonReducer;


