import { combineReducers } from 'redux';
const initialState = {
    errors : undefined,
    errorMessage: undefined
};
const uploadData = (state = initialState, action) => {

    ////console.log(action.type);
    ////console.log(action);
    switch(action.type){
        case "UPLOAD_ERROR":
            return {
                ...state,
                errors : (action.data.error === 'invalid') ?true :false,
                errorMessage : (action.data.error === 'invalid') ? "This file is not supported." : ""
            }

        default:
            return state;
    }
}

const UploadReducer = combineReducers({
    uploadData
})

export default UploadReducer;
// export const getUploadData = state => state.upload.uploadData;
