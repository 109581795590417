import React from 'react';
import axios from 'axios';
import {Draggable, state} from 'react-page-maker';
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment, isValidDate} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {SettingsPanel} from "./ViewData";
import SettingsModal from "./SettingsModal";
import {getGlobalFilterData} from "../../../../actions/DynamicDashboard";

let graphData = [
    {
        "x": "2018 Q1",
        "y": "500",
        "z": "2018 Q1"
    },
    {
        "x": "2018 Q2",
        "y": "620",
        "z": "2018 Q2"
    }]

class FilterContainer extends React.Component {
    constructor(props, context) {
        super(props, context);
        var d = new Date();
        var cid = d.getTime();
        this.state = {
            modalStatus: false,
            showColorPicker: false,
            background: 'green',
            selectfilter: "",
            payload: {
                chartTitle: "Title",
                subTitle: "subtitle",
                subTitleEnabled: false,
                multiarachart: graphData,
                cardvalue: 0,
                collection: "",
                entity: '',
                bordercolor: "",
                fields: "",
                aggregation: '',
                graph: false,
                x: "",
                y: "",
                z: "",
                xstroke: "",
                ystroke: "",
                labelx: "",
                labely: "",
                zstroke: "red",
                labelchartTitle: "",
            },
            multiarachart: [],
            layout: "",
            tabledata: [
                {
                    id: "item-1",
                    label: "X Axis",
                    field: "",
                    areaLabel: 'Area Color',
                    color: "#cc332b"
                },
                {
                    id: "item-2",
                    label: "Y Axis",
                    field: "",
                    areaLabel: 'Border Color',
                    color: "#cc332b"
                }
            ],
            items: [

                {
                    id: "item-1",
                    label: "X Axis",
                    field: "",
                    areaLabel: 'Border Color',
                    color: "#cc332b"
                },
                {
                    id: "item-2",
                    label: "Y Axis",
                    field: "",
                    areaLabel: 'Bar Color',
                    color: "#cc332b"
                }


            ],
            whereCondition: [
                {
                    id: "item-1",
                    label: "",
                    field: "",
                    conditions: "",
                    min: '',
                    max: '',
                    from: '',
                    to: '',
                    text: "",
                    type: ""
                },
            ],
            globalFilter: [],
            showlistField: [],

            condition: [{
                field: "",
                type: "",
                sort: ""
            }],
            cid: cid,
            enableyaxis: false,
            legend: false,
            alldata: false,
            unit: '',
            limit: '',
            sourceType: false,
            filterFields: [],
            filterData: []
        }


    }

    componentDidMount() {
        this.setState({payload: this.props.payload})
        this.graphData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterCnt.date !== this.props.filterCnt.date) {
            if (this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") {
                this.graphData()
            }
        }
        if (prevProps.filterCnt.select !== this.props.filterCnt.select) {
            this.graphData()

        }
    }

    openSetting = () => {
        this.setState({modalStatus: !this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }


    graphData() {


        console.log(this.props.payload)
        var date = new Date();
        var obj = this;
        const collection = this.props.payload && this.props.payload.collection
        const tabledata = this.props.payload && this.props.payload.graph || this.state.items
        const whereCondition = this.props.payload && this.props.payload.whereCondition || this.state.whereCondition
        const condition = this.props.payload && this.props.payload.condition || this.state.condition
        const groupbyEnable = this.props.payload && this.props.payload.groupbyEnable
        const limit = this.props.payload && this.props.payload.limit
        const bordercolor = this.props.payload && this.props.payload.bordercolor
        const globalFilter = this.props.payload && this.props.payload.globalFilter || this.state.globalFilter
        const selectFilter = this.state.selectFilter || this.props.payload && this.props.payload.selectFilter
        const filterName = this.state.filterName || this.props.payload && this.props.payload.filterName
        this.setState({
            filterName: filterName,
            selectFilter: selectFilter,
            tabledata: tabledata,
            globalFilter: globalFilter,
            bordercolor: bordercolor,
            items: tabledata,
            condition: condition,
            whereCondition: whereCondition,
            limit: limit
        })
        if ((collection !== '' && collection !== undefined && tabledata !== undefined) || getUrlSegment(1) === "collections") {
            let collections = (getUrlSegment(1) === "collections") ? atob(getUrlSegment(4)) : collection

            let data = {
                "cid": collections,
                "index": ES_INDEX,
                "fieldid": selectFilter
            }

            console.log(data)
            if (data.cid) {
                axios.get(API_PATH + "project/fieldvalues?index=" + ES_INDEX + "&cid=" + collections + "&field=" + selectFilter, data)
                    .then(function (res) {
                        var filterData = res.data.data;

                        var d = new Date();
                        var cid = d.getTime();
                        obj.setState({filterData: filterData, cid: cid});
                        return res;

                    }).catch(function (err) {

                    return err;
                });
            }

        }

        if ((collection !== '' && collection !== undefined) || getUrlSegment(1) === "collections") {
            let collections = (getUrlSegment(1) === "collections") ? atob(getUrlSegment(4)) : collection

            if (getUrlSegment(1) === "collections") {
                this.setState({cId: collections})
            }
            //  this.props.showform({ _id : collection});
            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + collections + "&index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                obj.setState({showlistField: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });


            axios({
                method: "GET",
                url: API_PATH + "collection/" + collection + "/collectedinfo?index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                var carddata = res.data.results.data[0];
                obj.setState({carddata: carddata});
                return res;

            }).catch(function (err) {
                return err;
            });
        } else {
            obj.setState({stackedbarchart: graphData});

        }
    }

    radioBoxchange = (e) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    handleChange = (e) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({[e.target.name]: e.target.value, payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    handleInputChange = (e) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    chartSaving = () => {
        var prjctdtlid = atob(getUrlSegment(4));
        let sharetype = getUrlSegment(1);
        let uid = '';
        if (sharetype === 'public' || sharetype === 'state') {
            uid = 2;
        } else {
            uid = getProfile().id;
        }
        let param = {
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard": state.getStorableState()
            ,
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus: false})
    }

    handleLabelChange = (e, filtertype) => {
        this.setState({[e.target.name]: e.target.value})
        let dtePm = {
            sFilter: e.target.name === "globalselect" ? e.target.value : this.state.selectfilter,
            filtertype: filtertype
        }
        this.props.setGlobalFilter(dtePm, "globalselect")
    }


    render() {

        const {
            id,
            showBasicContent, showPreview,
            dropzoneID, payload, parentID, name
        } = this.props;
        let sourceType = (payload && payload.sourceType) || false
        let showlistField = []
        let sourceData = []
        if (this.state.showlistField.length !== 0) {
            for (let i = 0; i < this.state.showlistField.pages.length; i++) {
                (this.state.showlistField.pages[i].components).map(list => {
                    showlistField.push(list)
                })
            }
        }
        let fieldDta = this.state.filterFields.filter(list => list.masterid === this.state.selectFilter)
        if (sourceType.toString() === "true") {
            sourceData = this.props.listAllCollections.ListAllCollections.data
        } else {
            sourceData = this.props.EntityData.Entity.data
        }
        let containerData = {
            collection: this.state.collection || payload && payload.collection,
            showlistField: showlistField,
            collectionData: sourceData,
            sourceType: sourceType,
            selectFilter: this.state.selectFilter || payload && payload.selectFilter,
            filterName: this.state.filterName || payload && payload.filterName,
        }

        let filterData = this.state.filterData;


        if (showBasicContent) {
            return (
                <Draggable {...this.props} >
                    {
                        <span className="drag-icon">Select Filter</span>
                    }
                </Draggable>
            )
        }
        if (showPreview) {
            return (

                <div className="stakeholders-header">
                    <div class="select-box">
                        <select name="globalselect"
                                onChange={(e) => this.handleLabelChange(e, "globalFilter")}>
                            <option value="">All</option>
                            {filterData.map((fil, index) =>
                                <option key={`filtere` + index} value={fil.id}>{fil.fieldvalue}</option>
                            )}
                        </select>
                    </div>

                </div>


            );
        }
        return (
            <React.Fragment>
                <Draggable {...this.props} draggable={!this.props.DragDisabled.dragStatus}>
                    <SettingsPanel
                        id={id}
                        name={containerData.chartTitle ? containerData.chartTitle : name}
                        dropzoneID={dropzoneID}
                        parentID={parentID}
                        openSetting={this.openSetting}
                        removeElement={<div className="delete-icon"
                                            onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
                    />
                </Draggable>
                {this.state.modalStatus &&
                <SettingsModal
                    title={`Select Filter  -   Settings`}
                    containerData={containerData}
                    colorpickerStatus={this.state.colorpickerStatus}
                    modaltype={8}
                    repeater={false}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    handleChange={this.handleChange}
                    handleInputChange={this.handleInputChange}
                    radioBoxchange={this.radioBoxchange}
                    handleLabelChange={this.handleLabelChange}

                />
                }
            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    showFormModal: state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange: state.DynamicDashboard.getDashboard,
    DragDisabled: state.DynamicDashboard.getDragDisabled,
    EntityData: state.EntityReducer.GetEntity,
    filterCnt: state.DynamicDashboard.getFilterCnt,
});
export default withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform: collectionActions.showform,
    chartSaving: DynamicDashboard.saveDashboarddata,
    dragStatus: DynamicDashboard.dragStatus,
    setGlobalFilter: DynamicDashboard.getGlobalFilterData,
})(FilterContainer));
