import React, { PureComponent } from 'react';
import {
    ScatterChart, Scatter, XAxis, YAxis, ZAxis, Tooltip,
    ResponsiveContainer, Legend,Cell, CartesianGrid, Label
} from 'recharts';
import BarChartCard from "./BarChartCard";

const data01 = [
    { hour: '12a', index: 1, value: 170 },
    { hour: '1a', index: 1, value: 180 },
    { hour: '2a', index: 1, value: 150 },
    { hour: '3a', index: 1, value: 120 },
    { hour: '4a', index: 1, value: 200 },
    { hour: '5a', index: 1, value: 300 },
    { hour: '6a', index: 1, value: 400 },
    { hour: '7a', index: 1, value: 200 },
    { hour: '8a', index: 1, value: 100 },
    { hour: '9a', index: 1, value: 150 },
    { hour: '10a', index: 1, value: 160 },
    { hour: '11a', index: 1, value: 170 },
    { hour: '12a', index: 1, value: 180 },
    { hour: '1p', index: 1, value: 144 },
    { hour: '2p', index: 1, value: 166 },
    { hour: '3p', index: 1, value: 145 },
    { hour: '4p', index: 1, value: 150 },
    { hour: '5p', index: 1, value: 170 },
    { hour: '6p', index: 1, value: 180 },
    { hour: '7p', index: 1, value: 165 },
    { hour: '8p', index: 1, value: 130 },
    { hour: '9p', index: 1, value: 140 },
    { hour: '10p', index: 1, value: 170 },
    { hour: '11p', index: 1, value: 180 },
];

const data02 = [
    { hour: '12a', index: 1, value: 160 },
    { hour: '1a', index: 1, value: 180 },
    { hour: '2a', index: 1, value: 150 },
    { hour: '3a', index: 1, value: 120 },
    { hour: '4a', index: 1, value: 200 },
    { hour: '5a', index: 1, value: 300 },
    { hour: '6a', index: 1, value: 100 },
    { hour: '7a', index: 1, value: 200 },
    { hour: '8a', index: 1, value: 100 },
    { hour: '9a', index: 1, value: 150 },
    { hour: '10a', index: 1, value: 160 },
    { hour: '11a', index: 1, value: 160 },
    { hour: '12a', index: 1, value: 180 },
    { hour: '1p', index: 1, value: 144 },
    { hour: '2p', index: 1, value: 166 },
    { hour: '3p', index: 1, value: 145 },
    { hour: '4p', index: 1, value: 150 },
    { hour: '5p', index: 1, value: 160 },
    { hour: '6p', index: 1, value: 180 },
    { hour: '7p', index: 1, value: 165 },
    { hour: '8p', index: 1, value: 130 },
    { hour: '9p', index: 1, value: 140 },
    { hour: '10p', index: 1, value: 160 },
    { hour: '11p', index: 1, value: 180 },
];

const parseDomain = (graphData, zField) => [
    0,
    Math.max(
        Math.max.apply(null, graphData.map(entry => entry[[zField]]))
    ),
];

const parseData = (dataArray, xField, tabledata) => {
    //console.log(dataArray)
    let graphJson = [];
    if(dataArray.length > 0) {
        for(var j=1; j<tabledata.length; j++) {
            let grItem = [];
            for(var i=0; i < dataArray.length; i++) {
                grItem.push({
                    [xField] : dataArray[i][xField],
                    [tabledata[j].label] : dataArray[i][tabledata[j].label]
                });
            }
            graphJson.push(grItem);
        }
    }
    return graphJson;
}
const CustomTooltip = ({ active, payload, label, xField, yField, zField, xUnit, yUnit, zUnit }) => {
    if (active) {
        //console.log(payload)
        //console.log(label)
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                {payload && payload.map(item => {
                    let unit = (item.dataKey === xField) ? xUnit : ((item.dataKey === yField) ? yUnit : (item.dataKey === zField) && zUnit );
                    return(
                        <p className="intro"
                           style={{color: item.color}}> {`${item.name} : ${item.value} ${unit ? unit : ''}`}</p>
                    );
                    }
                )}
            </div>
        );
    }

    return null;
};

const getColor = (entry , rangeColor, field) => {
    let fieldValue = parseFloat(entry[field]);
    //console.log(fieldValue);//console.log(entry); //console.log(rangeColor); //console.log(field)
    for(var i=0; i< rangeColor.length; i++) {
        if(fieldValue >= parseFloat(rangeColor[i].from) && fieldValue <= parseFloat(rangeColor[i].to)) {
            return rangeColor[i].color;
        }
    }
}
function BubbleChart(props) {

        let tabledata = props.containerData.tabledata;
        let xData = tabledata.slice(1,2);
        let xField = xData.length >0 ?xData[0].label : '';
        let yData = tabledata.slice(2,tabledata.length);
        let yField = yData.length >0 ?yData[0].label : '';
        let zField = yData.length >1 ?yData[1].label : '';
        let xUnit = xData.length >0 ?xData[0].unit : '';
        let yUnit = yData.length >0 ?yData[0].unit : '';
        let zUnit = yData.length >1 ?yData[1].unit : '';

        let layout = props.containerData.layout?props.containerData.layout:'horizontal' ;
        let xwidth = props.containerData.xwidth && parseInt(props.containerData.xwidth);
        let xheight =  props.containerData.xheight && parseInt(props.containerData.xheight);
        let yheight = props.containerData.yheight && parseInt(props.containerData.yheight);
        let ywidth = props.containerData.ywidth && parseInt(props.containerData.ywidth);
        let graphHeight =  props.containerData.graphHeight ? parseInt(props.containerData.graphHeight) : 200;
        let xAngle =  props.containerData.xAngle ? parseInt(props.containerData.xAngle) : 0;
        let yAngle = props.containerData.yAngle ? parseInt(props.containerData.yAngle) : 0;
        let borderColor = props.containerData.bordercolor && props.containerData.bordercolor;
        let rangeColor = props.containerData.rangeColor && props.containerData.rangeColor;
        let colorAxis = props.containerData.colorAxis && props.containerData.colorAxis;
        let colorField = zField;
        if(colorAxis === 'x') {
            colorField = xField;
        }
        else if(colorAxis === 'y') {
            colorField = yField;
        }
        else{
            colorField = zField;
        }


        let graphData = props.containerData.data;

        const domain = parseDomain(graphData);
        const range = [100, 300];

        let showxaxislabel = props.containerData.showxaxislabel && props.containerData.showxaxislabel;
        let showyaxislabel = props.containerData.showyaxislabel && props.containerData.showyaxislabel;

        return (
            <ResponsiveContainer width={props.containerData.legend ? "95%" : "100%"} height={graphHeight}>
                    <ScatterChart
                        width={800}
                        height={40}
                        margin={{
                            top: 10, right: 0, bottom: 20, left: 10,
                        }}
                    >
                        <XAxis dataKey={xField} label={{ value: showxaxislabel ? xField +" ("+ xUnit+")" : '', position: "outsideBottom", dy: 20}}  interval={0} fontSize={12}  tickLine={{ transform: 'translate(0, -6)' }}
                               interval={props.containerData.alldata ? 0 : 'preserveStartEnd' } textAnchor={xAngle >0 ? 'end': 'middle'}
                               width={xwidth} height={xheight} padding={{left:10, right:0}} axisUnit={xUnit} />
                        <YAxis type="number"  dataKey={yField} name={yField}  fontSize={12} tickLine={false} axisLine={true}
                               label={{ value: showyaxislabel ? yField +" ("+ yUnit+")": '', position: 'insideLeft', angle :-90, dy:10 }} padding={{bottom:10}} axisUnit={yUnit} />
                        <ZAxis type="number" dataKey={zField} domain={domain} range={range} axisUnit={zUnit}/>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip xField={xField} yField={yField} zField={zField} xUnit={xUnit} yUnit={yUnit} zUnit={zUnit} />} />
                        <Scatter data={graphData} fill="#8884d8" >
                            {
                                graphData.map((entry, index) => {
                                    //console.log(entry)
                                    return <Cell key={`cell-${index}`} fill={getColor(entry, rangeColor, colorField)} />
                                })
                            }
                        </Scatter>

                        {props.containerData.legend &&
                        <Legend  height={36} iconType={'circle'} layout='vertical' verticalAlign={`middle`} align={`right`}/>
                        }
                    </ScatterChart>

            </ResponsiveContainer>
        );

}
export default BubbleChart;