import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../../actions/ToDo"
import {withRouter} from 'react-router';
import ListCard from "../../views/ListCard"
import LoaderSvg from "../../views/LoaderSVG"
import Modal from "../../views/Modal"
import AddToDoTask from "../AddToDoTask"
import {getProfile} from '../../../utilities/AuthService';
import * as projectActions from "../../../actions/Project";
import NoData from "../../views/NoData";
import FilterBlock from "../FilterBlock";
import FilerRightBlock from "../FilterRightBlock";
import rootActions from "../../../actions";
import {getUrlSegment} from "../../../utilities/CustomFunctions";

class ToDo extends Component {

    constructor(props) {
        super(props);
        this.modalOpen=this.modalOpen.bind(this)
        this.gettaskList=this.gettaskList.bind(this);
       // this.loadtasks = this.loadtasks.bind(this);
        this.createmodalClose = this.createmodalClose.bind(this)

        this.state={
            open:false,
            projectList:[],
            limit : 0,
            loadingState: false,
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            todotaskdata:[],
            offset: 250,
            toverified:[]

        }
      }

    componentDidMount() {

        this.props.mainClick('task','/task/todo');
        if(this.props.todoListing.toDo.data.length ===0) {
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
            }
            else {
                this.gettaskList('to-me','All');
            }
        }
        else{
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
                this.setState({ todotaskdata: this.props.todoListing.toDo.data,toverified:this.props.todoListing.toDo.toverified, loadingState : false })
            }
            else {
                this.setState({ todotaskdata: this.props.todoListing.toDo.data,toverified:this.props.todoListing.toDo.toverified, loadingState : false })

            }
        }
        this.props.userFilter("to-me",getProfile().id)
        const container = document.querySelector('.main-panel-holder');
        container.addEventListener('scroll', this.listenToScroll)

    }

  searchFilterActive(){
      var status = "All"
      if(getUrlSegment(2)==='todo'){
          var category='to-me'
      }
      if(getUrlSegment(2)==='to-follow-up'){
          var category='by-me'
      }
      if(getUrlSegment(2)==='to-oversee'){
          var category='manage-task'
      }
      if(getUrlSegment(2)==='to-know'){
          var category='to-know'
      }
      if(getUrlSegment(2)==='to-comment'){
          var category='Comments'
      }
      if(getUrlSegment(2)==='to-verify'){
          var category='by-me'
          status = "Done"
      }

      var data = {
          category : category,
          status :this.props.searchData.task.status,
          uid : getProfile().id,
          bydue : '',
          priority :this.props.searchData.task.priority,
          project :this.props.searchData.task.project,
          asigntype : '',
          username :'',
          managetask : 'mytask',
          srchval:this.props.searchData.task.srchval,
          limit : 0,
          offset  : this.state.offset,
          systype : getProfile().usertype,
      }

      this.props.searchType('SEARCH_TASK_DATA',this.props.searchData.task.srchval,"srchval",data)

  }



    componentWillUnmount() {
        const container = document.querySelector('.main-panel-holder');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
            const container = document.querySelector('.main-panel-holder')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            //////////////console.log(contentHeight + 'scrolltop' + scrollTop + "H" + offsetHeight)
            if (contentHeight <= scrollTop) {
                if(this.props.sidebarmenudata.todo > this.props.todoListing.toDo.data.length+1) {
                        var data = {
                            category: 'to-me',
                            status: "All",
                            uid: getProfile().id,
                            bydue: '',
                            priority: this.props.taskFilters.taskPriorityFilter,
                            project: this.props.taskFilters.taskProjectFilter,
                            asigntype: 'All',
                            managetask: 'mytask',
                            limit: this.props.todoListing.toDo.data.length,
                            offset: this.state.offset,
                            systype: getProfile().usertype,
                            actionType: 'LOADMORE_TODO_DATA'
                        }
                        this.props.todo(data);

                }
            }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if(prevProps.todoListing  !== this.props.todoListing){

            this.setState({todotaskdata: this.props.todoListing.toDo.data,toverified:this.props.todoListing.toDo.toverified});
        }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
           if(this.props.HistoryPushData.savemode === 'by-me') {
              // var data = {category : 'by-me', status : 'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', username : this.props.taskFilters.taskAssignedFilter,limit: 0, offset  : this.state.offset, systype :  getProfile().usertype,  actionType : 'LIST_FOLLOW_UP_DATA' }
              // this.props.toFollowUp(data);
            //   this.props.history.push("/task/to-follow-up")
               this.setState({open:false})
           }
            if(this.props.HistoryPushData.savemode === 'to-me') {
               // var data = {category : 'to-me', status :'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
              //  this.props.todo(data);
           //     this.props.history.push("/task/todo")
                this.setState({open:false})
            }

        }
        if(prevProps.taskFilters  !== this.props.taskFilters){
            this.setState({  limit : 0 },
                function() {
                    this.gettaskList('to-me','All');
                })
        }
        if(prevProps.taskSelect.tasksArchived  !== this.props.taskSelect.tasksArchived ){
            setTimeout(function () {
                beta.gettaskList('to-me','All');
            }, 1000)
        }
        if(this.props.taskSelect.dateUpdated !== prevProps.taskSelect.dateUpdated) {

            let tasklist = [...this.state.todotaskdata];
            tasklist.filter(item =>{
                item.duedate = (item.masterid === this.props.taskSelect.taskid) ? this.props.taskSelect.utcduedate : item.duedate;
                return item;
            });
            this.setState({todotaskdata : tasklist})
        }
    }
    modalOpen(){
        this.setState({open:!this.state.open,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData});
    }
    gettaskList(cat,status){
        var data = {category : cat, status : status , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
        this.props.todo(data);
    }
    selectTasks(id) {
        this.props.selectTasksToMove(id);
    }

    createmodalClose(){
        this.setState({open : false});
    }

    render() {
        //console.log(this.state.toverified)
        let
            TodoData=this.props.searchData.task.active? this.props.searchData.data:  this.state.todotaskdata
        let
            toverified=this.state.toverified === undefined ?[]:this.state.toverified
        //console.log(toverified)
         return (
            <>

                  <div className={'filter-all-wrapper task-home '+ ( this.props.mobToggle.filterToggle ? ' filter-mob-active' :'')}>
                      {/*<AlertBar*/}
                      {/*    history={this.props.history}*/}

                      {/*/>*/}
                      <div className="float-panel-header show-mob">
                          <h5 className="subheader">Filter By</h5>
                          <button className="ico-lib fly-close" onClick={()=>this.props.mobileFiltertogle(!this.props.mobToggle.filterToggle)}></button>
                      </div>
                    <div className='filter-holder'>
                        {this.props.taskSelect.taskSelection === 'no' &&
                          <FilterBlock/>
                        }

                        <FilerRightBlock
                            taskCount={this.state.todotaskdata.length}
                            history={this.props.history}

                        />

                    </div>


                   </div>
                      {this.props.todoListing.loading &&
                        <span className="Loader-holder" style={{'background':'transparent'}}>
                            <LoaderSvg/>
                        </span>
                      }
                  <div className={this.props.todoListing.loading ? 'main-panel-holder with-loader has-filter' : 'main-panel-holder has-filter'} ref="iScroll">

                        {TodoData.length === 0 && !this.props.todoListing.loading &&
                            <div className='absolute-holder'>
                            <NoData
                                msg={`No task assigned to you`}
                            />
                            </div>
                        }

                      {toverified.map((list,index) =>
                          <React.Fragment   key={'todo'+index}>
                              {index === 0 &&
                              <h4 className={'main-panel-head'}>verified - Unread</h4>
                              }
                              <ListCard
                                  key={'todo-list-card-verified'+index}
                                  index={index}
                                  list = {list}
                                  history={this.props.history}
                                  mode='to-me'
                                  taskSelection = {this.props.taskSelect.taskSelection}
                                  selectTasks = {this.selectTasks.bind(this)}
                                  allChecked = {this.props.taskSelect.allChecked}
                                  id={list.masterid}
                                  dateupdatetask={this.props.dateupdatetask}
                                  tasksource={list.createdfrom}
                                  poolenabled={list.poolenabled}
                              />
                          </React.Fragment>
                      )}

                      {TodoData.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'unread').map((list,index) =>
                            <React.Fragment   key={'todo'+index}>
                            {index === 0 &&
                                    <h4 className={'main-panel-head'}>Unread</h4>
                            }
                                <ListCard
                                    key={'todo-list-card'+index}
                                    sectionTitle={`Unread`}
                                     index={index}
                                     list = {list}
                                     history={this.props.history}
                                     mode='to-me'
                                    taskSelection = {this.props.taskSelect.taskSelection}
                                    selectTasks = {this.selectTasks.bind(this)}
                                    allChecked = {this.props.taskSelect.allChecked}
                                    id={list.masterid}
                                    dateupdatetask={this.props.dateupdatetask}
                                    tasksource={list.createdfrom}
                                    poolenabled={list.poolenabled}
                                />
                            </React.Fragment>
                        )}

                        {TodoData.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'read').map((list,index) =>
                            <React.Fragment key={`list-card-data`+index}>
                                {index === 0 &&
                                <h4 className={'main-panel-head'}>Read</h4>
                                }
                            <ListCard
                                key={'todo'+index}
                                index={index}
                                list = {list}
                                history={this.props.history}
                                mode='to-me'
                                taskSelection = {this.props.taskSelect.taskSelection}
                                selectTasks = {this.selectTasks.bind(this)}
                                allChecked = {this.props.taskSelect.allChecked}
                                id={list.masterid}
                                dateupdatetask={this.props.dateupdatetask}
                                tasksource={list.createdfrom}
                                poolenabled={list.poolenabled}
                            />
                            </React.Fragment>

                        )}
                  </div>
                      {this.state.open &&
                         <Modal title={'Create New Task'} Close ={this.modalOpen.bind(this)}>
                          <AddToDoTask
                              projectList={this.state.projectList}
                              tagList={this.state.tagList}
                             // modalOpen = {this.modalOpen}
                          />

                      </Modal>
                      }
                  <div className={'float-button-box'} onClick={this.modalOpen}>
                        <span className={'icon-in icon-plus'}> </span>
                  </div>


               </>
        );
    }
}



const mapStateToProps = state => ({
    listAllProjectData:state.ProjectReducer.listallProjects,
    todoListing:state.TaskReducer.listtoDo,
    taskFilters:state.TaskReducer.filterTasks,
    taskSelect:state.TaskReducer.getTaskSelection,
    menuClickData:state.CommonReducer.menuClick,
    searchData:state.CommonReducer.searchparamdata,
    CreateTask:state.TaskReducer.CreateTask,
    topbardata:state.TaskReducer.TodoTopbarMenudata,
    listtags:state.TaskReducer.ListAllTags,
    saveTodo : state.TaskReducer.saveTodo,
    projects:state.TaskReducer.GetProjectData,
    sidebarmenudata: state.TaskReducer.SidebarMenudata,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    mobToggle: state.CommonReducer.mobileTogle,
    taskdetailsData : state.TaskReducer.TaskDetails,
});
export default  withRouter(connect(mapStateToProps, {
    todo:taskActions.listtask,
    toFollowUp:taskActions.listtask,
     mainClick:rootActions.commonActions.MenuClick,
    createTask:taskActions.createtask,
    listTags:projectActions.listalltags,
    selectTasksToMove : taskActions.selectTasksToMove,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    mobileFiltertogle: rootActions.commonActions.mobileFilterToggle,
    searchType: rootActions.commonActions.searchType,
    userFilter:taskActions.userFilter,
    dateupdatetask: taskActions.dateupdatetask,
})(ToDo));


