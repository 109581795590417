import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {Datetimecomparison, getUrlSegment} from "../../../utilities/CustomFunctions";
import {getProfile} from "../../../utilities/AuthService";
import * as commonActions from "../../../actions/Common";
import Modal from "../../views/Modal";
import AddNewMilestone from "./AddNewMilestone";
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import AlertModal from "../../views/AlertModal";
import ProjectHeader from "../../views/ProjectHeader";
import OverviewGraph from "../../views/OverviewGraph";
import * as usersActions from "../../../actions/Users";

class ProjectOverview extends Component {

    constructor(props) {
        super(props);

        this.state={
            showOption : false,
            overviewtab:"Summary",
            activemilestone:true,
            completedmilestone:false,
            NewMilestoneModal:false,
            milestoneAction:'',
            project_id:'',
            Delopen:false,
            lastOffset: 0,
            totalitems: 0,
            logdetails: [],
            offset: 20,
            logButtonText:'view older logs',
            showMoreLogtext:true,
            page:'',
            mileStoneList:[]
        }
        this.getoverviewtab = this.getoverviewtab.bind(this);
        this.showActivemilestones = this.showActivemilestones.bind(this);
        this.showCompletedmilestones = this.showCompletedmilestones.bind(this);
        this.showCreateNewMilestoneModal = this.showCreateNewMilestoneModal.bind(this);
        this.AddmilestonemodalClose = this.AddmilestonemodalClose.bind(this);
        this.getmilestonelist = this.getmilestonelist.bind(this);
        this.showOptions = this.showOptions.bind(this);
    }



    componentDidMount() {
        this.setState({loading: 1});

        var projectId = getUrlSegment(4);
        var page = getUrlSegment(3);
        this.setState({page:page})

        if(page === "overview") {
            this.props.mainClick('project', '/project/details/overview/' + projectId);
        }
        if(page === "milestone") {
            this.props.mainClick('project', '/project/details/milestone/' + projectId);
        }
        var projectid = (atob(projectId));

        this.setState({project_id:projectid});


        /*let mdata ={
            "uid": getProfile().id,
            "usertype" : getProfile().usertype,
            "projectid" : projectid,
        }
        this.props.getmilestonelist(mdata);*/

        var obj = this;
        setTimeout(function () {
            var udata = { uid : getProfile().id, usertype : getProfile().usertype , srchval : '' };
            obj.props.users(udata);
         }, 500)

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        var beta = this;


        if(prevProps.menuClickData !== this.props.menuClickData){
            var page = getUrlSegment(3);
            var projectid = (atob(getUrlSegment(4)));
            this.setState({page:page})


        }


        if(prevProps.savenewmilestoneDet.milestonesaved !== this.props.savenewmilestoneDet.milestonesaved ) {

            let mileStoneList = [...this.state.mileStoneList];
            let mode=this.props.savenewmilestoneDet.newMilestone.mode;
            if(mode!=='update')
            {
                mileStoneList.unshift(this.props.savenewmilestoneDet.newMilestone);
            }
            else {
                mileStoneList = mileStoneList.map((item) => { return ( parseInt(item.milestone_id) === parseInt(this.props.savenewmilestoneDet.newMilestone.milestone_id)) ? this.props.savenewmilestoneDet.newMilestone: item; });
            }
            this.setState({mileStoneList: mileStoneList,loading:0});

            this.AddmilestonemodalClose();
        }
        if (prevProps.listAllProjectDetailData.projectDetails.milestones !== this.props.listAllProjectDetailData.projectDetails.milestones ) {
            this.setState({
                mileStoneList: this.props.listAllProjectDetailData.projectDetails.milestones
            });
        }

       /* if (prevProps.mileStoneData.ListAllMilestones !== this.props.mileStoneData.ListAllMilestones ) {
            this.setState({
                mileStoneList: this.props.mileStoneData.ListAllMilestones,
            });
        }*/
    }
    getmilestonelist(){
        var projectid = (atob(getUrlSegment(4)));
        let mdata ={
            "uid": getProfile().id,
            "usertype" : getProfile().usertype,
            "projectid" : projectid,
        }
        this.props.getmilestonelist(mdata);
    }

    getoverviewtab(tab){
        this.setState({overviewtab:tab})
    }

    showActivemilestones(){

        this.setState({
            activemilestone:!this.state.activemilestone,
            completedmilestone:false
        })
    }

    showCompletedmilestones(){

        this.setState({
            completedmilestone:!this.state.completedmilestone,
            activemilestone:false
        })
    }

    showCreateNewMilestoneModal(action,id){
        if(action === 'update') {
            var data={
                "_id": id,
            }
            this.props.getmilesonedetails(data);
        }

       this.setState({NewMilestoneModal:!this.state.NewMilestoneModal,
           milestoneAction:action,
           projectid:id
       })

    }

    AddmilestonemodalClose(){
        this.setState({NewMilestoneModal:false})
    }

    showOptions(){
        this.setState({showOption : !this.state.showOption});
    }







    render() {

        let projectInfo = this.props.listAllProjectDetailData.projectDetails.projectinfo;
        let bydateData = this.props.listAllProjectDetailData.projectDetails.graphdata;
        let taskCount = this.props.listAllProjectDetailData.projectDetails.taskcount;
        let mileStoneList = this.state.mileStoneList;
        let users = this.props.listUsers.Users.data;

        let activemilestone = mileStoneList.filter((a) => a.completedmilestone === false);
        let completedmilestone = mileStoneList.filter((a) => a.completedmilestone === true);
        console.log(activemilestone)
        return(

            <div className="form-detail-container data-view field-form">
                <ProjectHeader/>
                {this.props.listAllProjectDetailData.loading &&
                <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                }
                <div className={this.state.page === "milestone" ? "common-tab milestone-scroll" : "common-tab"}>
                    {this.state.page === "overview" &&
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <div
                                    className={this.state.overviewtab === "Summary" ? "nav-link ico-lib proj-ov-sum-ico active" : "nav-link ico-lib proj-ov-sum-ico"}
                                    id="Summary-tab" data-toggle="tab" role="tab" aria-controls="Summary"
                                    aria-selected="true" onClick={(e) => this.getoverviewtab("Summary")}>Project
                                    Summary
                                </div>
                            </li>
                            <li className="nav-item">
                                <div
                                    className={this.state.overviewtab === "Timeline" ? "nav-link ico-lib-v1 proj-ov-time-ico active" : "nav-link ico-lib-v1 proj-ov-time-ico"}
                                    id="Timeline-tab" data-toggle="tab" role="tab" aria-controls="Timeline"
                                    aria-selected="false" onClick={(e) => this.getoverviewtab("Timeline")}>Timeline
                                </div>
                            </li>
                        </ul>
                    }
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="Summary" role="tabpanel"
                             aria-labelledby="Summary-tab">
                            {this.state.overviewtab === "Summary" &&
                            <div className="comment-tab-wrapper">
                                {this.state.page === "overview" &&
                                <OverviewGraph
                                    projectInfo={projectInfo}
                                    bydateData={bydateData}
                                    taskCount = {taskCount}
                                />
                                }
                                <div className="accordian-mile-block">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button
                                                    className={this.state.activemilestone === true ? "btn btn-link" : "btn btn-link collapsed"}
                                                    data-toggle="collapse"
                                                    data-target="#collapseOne"
                                                    aria-expanded={this.state.activemilestone === true ? "true" : "false"}
                                                    aria-controls="collapseOne"
                                                    onClick={this.showActivemilestones}> Active Milestones
                                                </button>
                                            </h5>
                                        </div>
                                        {this.state.activemilestone &&
                                        <div id="collapseOne" className={this.state.activemilestone === true ? "in collapse show" : "in collapse"} aria-labelledby="headingOne"
                                             data-parent="#accordion">
                                            <div className="card-body">
                                                {activemilestone.length === 0 &&
                                                <div className="full-no-data">
                                                    <h5>No data found !</h5>
                                                </div>
                                                }
                                                {activemilestone.length > 0 &&
                                                <div className="miles-table">
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th scope="col">Milestone</th>
                                                            <th scope="col">Owner</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col"></th>
                                                            {/*<th scope="col">Issues</th>*/}
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {activemilestone.map((data, index) =>
                                                            <tr key={index}>
                                                                <td>{data.milestone_name}

                                                                    <div className="wf-btn-block">
                                                                        <button className="ico-lib wf-ed"
                                                                                data-parent="1562584045119"
                                                                                data-action="editworkflow" onClick={(e) => {
                                                                            this.showCreateNewMilestoneModal('update', data.milestone_id)
                                                                        }}></button>
                                                                    </div>

                                                                </td>
                                                                <td>{data.creator_first_name} {data.creator_last_name}</td>
                                                                <td>{moment.utc(data.startdate).local().format('Do MMM YYYY')}</td>
                                                                <td>{moment.utc(data.enddate).local().format('Do MMM YYYY')}</td>
                                                                <td>
                                                                    <div className="mile-progress">
                                                                        <div className="progress">
                                                                            <div className="progress-bar"
                                                                                 role="progressbar"
                                                                                 aria-valuenow="0" aria-valuemin="0"
                                                                                 aria-valuemax="100"
                                                                                 style={{width: "50%"}}></div>
                                                                        </div>
                                                                        <span className="prog-count">50%</span>
                                                                    </div>
                                                                </td>
                                                                {/*<td>No Issues</td>*/}
                                                            </tr>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className={this.state.completedmilestone === true ? "btn btn-link" : "btn btn-link collapsed"} data-toggle="collapse"
                                                        data-target="#collapseTwo" aria-expanded={this.state.completedmilestone === true ? "true" : "false"}
                                                        aria-controls="collapseTwo" onClick={this.showCompletedmilestones}> Completed Milestones
                                                </button>
                                            </h5>
                                        </div>
                                        {this.state.completedmilestone === true &&
                                        <div id="collapseTwo"  className={this.state.completedmilestone === true ? "in collapse show" : "in collapse"} aria-labelledby="headingTwo"
                                             data-parent="#accordion">
                                            <div className="card-body">
                                                <div className="card-body">
                                                    {completedmilestone.length === 0 &&
                                                    <div className="full-no-data">
                                                        <h5>No data found !</h5>
                                                    </div>
                                                    }
                                                    {completedmilestone.length > 0 &&
                                                    <div className="miles-table">
                                                        <table className="table">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">Milestone</th>
                                                                <th scope="col">Owner</th>
                                                                <th scope="col">Start Date</th>
                                                                <th scope="col">End Date</th>
                                                                <th scope="col"></th>
                                                                {/*<th scope="col">Issues</th>*/}
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {completedmilestone.map((data, index) =>
                                                                <tr key={index}>
                                                                    <td>{data.milestone_name}

                                                                        <div className="wf-btn-block">
                                                                            <button className="ico-lib wf-ed"
                                                                                    data-parent="1562584045119"
                                                                                    data-action="editworkflow" onClick={(e) => {
                                                                                this.showCreateNewMilestoneModal('update', data.milestone_id)
                                                                            }}></button>
                                                                        </div>

                                                                    </td>
                                                                    <td>{data.creator_first_name} {data.creator_last_name}</td>
                                                                    <td>{moment.utc(data.startdate).local().format('Do MMM YYYY')}</td>
                                                                    <td>{moment.utc(data.enddate).local().format('Do MMM YYYY')}</td>
                                                                    <td>
                                                                        <div className="mile-progress">
                                                                            <div className="progress">
                                                                                <div className="progress-bar"
                                                                                     role="progressbar"
                                                                                     aria-valuenow="0" aria-valuemin="0"
                                                                                     aria-valuemax="100"
                                                                                     style={{width: "50%"}}></div>
                                                                            </div>
                                                                            <span className="prog-count">50%</span>
                                                                        </div>
                                                                    </td>
                                                                    {/*<td>No Issues</td>*/}
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>

                                <div className="float-button-block" data-toggle="tooltip" data-placement="top"
                                     title="New Task">
                                    <button className="float-button ico-lib float-add" onClick={(e) => {
                                        this.showCreateNewMilestoneModal('create')
                                    }}></button>
                                </div>

                            </div>
                            }
                            {this.state.overviewtab === "Timeline" &&
                            <div className="tab-pane fade" id="Timeline" role="tabpanel" aria-labelledby="Timeline-tab">
                            </div>
                            }
                        </div>
                    </div>
                </div>
                {this.state.NewMilestoneModal &&
                <Modal title={this.state.milestoneAction === "create" ? 'Create New Milestone' : 'Update Milestone'} Close ={this.AddmilestonemodalClose.bind(this)}>
                    <AddNewMilestone
                        AddmilestonemodalClose = {this.AddmilestonemodalClose}
                        action={this.state.milestoneAction === "create"? "create":"update"}
                        users={users}
                    />

                </Modal>
                }
                {this.state.Delopen == true &&
                <AlertModal id="take-pool"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_action={() => this.deleteproject()}
                            showModal = {this.state.Delopen}
                            CANCEL_action={() => this.cancelDeleteModal()}
                />
                }
            </div>



        );
    }
}
const mapStateToProps = state => ({
    ProjectInfoDet : state.ProjectReducer.ProjectInfo,
    listAllProjectDetailData:state.ProjectReducer.listallProjectsdetails,
    mileStoneData: state.ProjectReducer.milestonelist,
    activitylogitems :state.ProjectReducer.listallProjectsdetails,
    savenewmilestoneDet : state.ProjectReducer.SaveMilestone,
    milestondedata:state.ProjectReducer.GetMilestone,
    listUsers:state.UsersReducer.listUsersdata,
    menuClickData: state.CommonReducer.menuClick,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    projectdetails:projectActions.listallprojectsdetails,
    //getmilestonelist : projectActions.milestoneList,
    getmoreitems : projectActions.moreItems,
    getmilesonedetails:projectActions.getMilestoneInfo,
    deleteProject:projectActions.DeleteProject,
    users:usersActions.listUsers,
})(ProjectOverview));
