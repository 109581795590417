import React, {Component} from 'react';
import moment from 'moment'
import {Card,Tooltip,OverlayTrigger} from "react-bootstrap"
import { Datecomparisondue,getUrlSegment} from "../../utilities/CustomFunctions";
import {getProfile} from "../../utilities/AuthService";
import {ES_INDEX, USRIMG_PATH} from '../../constants/index'
import SimpleModal from "./SimpleModal";
import {DateTimeField} from "./FormElements";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as taskActions from "../../actions/ToDo";
class ListCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checked : false,
            dateopen : false,
            taskAction :''
        }
        this.dateSwap = this.dateSwap.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.updatetask = this.updatetask.bind(this);
        this.updatetaskdata=this.updatetaskdata.bind(this)
        this.history=this.history.bind(this)

      }

      componentDidMount() {
          let loggedInUser = getProfile().id;
          let taskid = this.props.list.masterid;
          if( this.props.list.createuser === loggedInUser) {
              taskid = "_parent||"+this.props.list.masterid;
          }
          else if( this.props.list.assigneeuser_id === loggedInUser) {
              taskid = this.props.list.inner_hits.childtodo.hits.hits[0]['_id']+'||'+this.props.list.masterid;
          }
          this.setState({taskid : taskid, loggedInUser : loggedInUser})

      }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.allChecked !== this.props.allChecked) {
            var taskEleId = "task_"+this.props.id+"_checked";
            this.setState({[taskEleId] : this.props.allChecked , checked: this.props.allChecked});
            if(this.props.allChecked) {
                this.props.selectTasks(this.state.taskid);
            }
        }
    }
    dateSwap(e, duedate, masterid, createuserid){

       // let recievedDate = moment(dueDate).utc(dueDate).local().format('YYYY-MM-DD HH:mm:ss');
        let recievedDate = moment.utc(duedate).local().format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            dateopen : !this.state.dateopen,
            taskAction : 'edit',
            duedate:new Date(recievedDate),
            id:masterid, createuserid});
    }
    handleDateChange(date){
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            duedate : date,
            formattedDate : formattedDate
        })
    }
    updatetaskdata(dateopen){
        var duedate;
        if(this.state.duedate !== null) {
             if (this.state.dateselected === true){
                duedate = (this.state.duedate && this.state.duedate !== '')? this.state.duedate : new Date();
            }
            else{
                duedate = this.state.duedate;
            }
            if(duedate < new Date()) {
                duedate = new Date();
            }
            //alert(duedate); return false;
            this.setState({
                dateopen:dateopen
            })
            var utcStart = new moment(duedate, "YYYY-MM-DDTHH:mm").utc();
            duedate=(utcStart.format('YYYY-MM-DD HH:mm:ss'));


            let taskid =  this.state.id !== '' ? this.state.id : '';
            let params ={
                "taskid": taskid,
                "uid": getProfile().id,
                "duedate": duedate,
                "index": ES_INDEX
            }
            this.props.dateupdatetask(params)
        }

    }
    updatetask(date){

        this.setState({
            duedate : date
        })

    }
    selectTasks(e,id) {
        e.preventDefault();
        e.stopPropagation();
        this.props.selectTasks(id);
        this.setState({ checked : !this.state.checked});
    }

    setFieldValue = (fieldName, fieldValue) =>{
        this.setState({ [fieldName] : fieldValue});
    }

    history(masterid){
        if(this.props.sectionTitle === "Unread") {
            this.props.readStatus(masterid, getUrlSegment(2))
        }
        this.props.history.push('/task/details/'+masterid+'/'+btoa(this.props.mode))
    }

    render() {
        var list = this.props.list;
        var curentDate = moment.utc().local().format('YYYY-MM-DD')
        var dueDate=moment.utc(list.duedate).local().format('YYYY-MM-DD')
        let Title = list.subject === '' ? atob(list.Title) : atob(list.subject);
        let username = list.createuser_name;
        let userimage = list.createuser_img_name;
        let useremail = list.createuser_email;
        let userdesignation = list.createuser_designation;
        let usermob = list.createuser_mob;
        if(this.props.mode === 'by-me') {
            username = list.assigneeuser_name;
            userimage = list.assigneeuser_img_name;
            useremail = list.assigneeuser_email;
            userdesignation = list.assigneeuser_designation;
            usermob = list.assigneeuser_mob
        }
        let AssigneeuserName = username.split(" ");
      //  let createdate=list.createdate.split(" ")[0];

        let due = list.duedate !== '0000-00-00 00:00:00'? Datecomparisondue(dueDate, curentDate,'task'):'';

        let loggedInUser = getProfile().id;

        /*let taskid = list.masterid;

        if( list.createuser === loggedInUser) {
            taskid = "_parent||"+list.masterid;
        }
        else if( list.assigneeuser_id === loggedInUser) {
            taskid = list.inner_hits.childtodo.hits.hits[0]['_id']+'||'+list.masterid;
        }*/
        let assigneeStatus = list.status;
        let archiveStatus = list.archive;
       if(list.inner_hits.childtodo.hits.hits.length > 0) {
           if(list.inner_hits.childtodo.hits.hits[0]['_source']['assignee'] === loggedInUser.toString()) {
               assigneeStatus = list.inner_hits.childtodo.hits.hits[0]['_source']['status'];
               //archiveStatus = list.inner_hits.childtodo.hits.hits[0]['_source']['status'];
           }
       }

        /************Syncfusion datepicker *************/

        let duedate = moment.utc(list.duedate).local().format('YYYY-MM-DD HH:mm:ss');

        let currentTime = new Date()
        let min = currentTime;
        if(this.state.taskAction === 'edit') {
            if(duedate < currentTime) {
                min = duedate;
            }
        }

        /************Syncfusion datepicker *************/
         return (
             <React.Fragment>

             <Card key={list.Title} className={`ind-task-list  ${(getUrlSegment(2) === "to-respond" && list.readstatus === 'read')  ? "read" :""}`} >

                 <Card.Body
                     className={`ind-task-list-click`}
                 >
                     <div  className="ind-task-list-left" onClick={(e)=>this.history(btoa(list.masterid))}>
                         {this.props.taskSelection === 'yes' &&
                         <div className="select-chk-block" style={{display: "block"}}  onClick={(e) => this.selectTasks(e,this.state.taskid)} >
                             <label className="control control-checkbox" data-action="organise">
                                 <input type="checkbox" className="inp-chk" name={"task_"+this.props.id+"_checked"}
                                        data-child=""
                                        data-attribute={list.masterid} data-action="organise" checked={this.state.checked} />
                                 <div className="control-indicator"></div>
                             </label>
                         </div>
                         }


                         {this.props.taskSelection !== 'yes' &&
                         <OverlayTrigger
                             key={`1`}
                             placement={`top`}
                             overlay={

                                 <Tooltip id={`tooltip-top`} className={'test'}>
                                     {list.priority}
                                 </Tooltip>
                             }>
                             <span
                                 className={`act-priority task-priority  ${list.priority.toLowerCase()}`}>{list.priority.charAt(0)}</span>
                         </OverlayTrigger>
                         }

                         <div className="task-user-block __usercard">
                             <span className="task-user">{AssigneeuserName[0].toLowerCase()}</span>
                             <span className="mobile-com-count">3</span>
                                <div className="user-popover-block">
                                    <div className="user-popover-left">
                                        <div className="user-popover-img online">
                                            <img src={USRIMG_PATH + userimage} />
                                        </div>
                                        <div className="user-popover-btn-block">
                                            <button
                                                className="commun-button ico-lib chat-ico">Chat</button>
                                        </div>
                                    </div>
                                    <div className="user-popover-right">
                                        <div className="user-profile-details">
                                            <h4 className="subheader">{username}</h4>
                                            <ul className="user-profile-det-list">
                                                {userdesignation && userdesignation !== "" &&
                                                <li className="ico-lib prof-user-ico">{userdesignation}</li>
                                                }
                                                <li className="ico-lib mail-ico">{useremail}</li>
                                                {usermob && usermob !== "" &&
                                                <li className="ico-lib phone-ico">{usermob}</li>
                                                }
                                                {/*<li className="ico-lib loc-ico">Kochi, India</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                         </div>






                     </div>
                     <div  className="ind-task-list-center"  onClick={(e)=>this.history(btoa(list.masterid))}>

                        {this.props.tasksource  === "workflow" &&
                         <span className="ico-lib wf-task-ico"></span>
                         }
                         {this.props.poolenabled && this.props.tasksource  !== "workflow" &&
                           <span className="ico-lib task-pool-ico"></span>
                         }
                         {this.props.tasksource  === "systemtask" &&
                          <span className="ico-lib task-systask-ico"></span>
                         }
                         {this.props.tasksource  === "board" &&
                         <span className="ico-lib task-board-ico"></span>
                         }
                         {list.tasktype  === "notification" &&
                         <span className="ico-lib-v1 notf-task-ico"></span>
                         }

                        {list.reassign ==='yes' && <span className="icon-in icon-forward task-forward-btn"></span>}
                         {/*{(list.projectcode.length > 0 ? list.projectcode[0] : '')}*/}
                        <span className="task-text">
                            {list.projectcode && list.projectcode.length > 0 &&
                            <span className={`project-code`}>{list.projectcode[0]} - </span>
                            }
                            {(decodeURIComponent(escape(Title)))}
                        </span>
                     </div>
                     <div  className="ind-task-list-right">
                        <div className="task-more-block">
                            {list.has_attachemnt ==='yes' &&<span className="more-ico icon-in icon-attachment"></span> }
                        </div>

                         {this.props.mode !== 'to-draft' && (assigneeStatus === 'New' || assigneeStatus === 'InProgress') && (archiveStatus !== 'yes') &&
                             <React.Fragment>
                                 <div className={`due-block ${moment(curentDate).isAfter(dueDate) ? 'due' : ''} ${list.createuser === loggedInUser ? '' : 'activeduedate'}`}  >  {due}</div>


                                 {(list.createuser === loggedInUser && list.status !== "Done" && list.archive === "no" ) && this.props.tasksource  !== "workflow" &&
                                     <ul className="quick-edit">
                                         <li>
                                             <button className="ico-lib edited-task-ico"
                                                     onClick={(e) => this.dateSwap(e, list.duedate, list.masterid, list.createuser)}></button>
                                         </li>
                                     </ul>
                                 }
                             </React.Fragment>
                         }

                     </div>

                 </Card.Body>
             </Card>

                 {this.state.dateopen && (this.state.createuserid === loggedInUser) &&
                 <SimpleModal

                     Close={this.dateSwap}
                     title={`Reschedule Task`}
                     haveFooter={1}
                     cancelAction={this.dateSwap}
                     OKAction={()=>this.updatetaskdata(false)}
                     OKLabel={`Apply`}
                     modalType={'custom-date-range'}
                 >
                     <div className="reschedule-wrapper">

                     <DateTimeField
                             name='duedate' placeholder ="Due Date" id="duedate"
                             value={this.state.duedate !== '' ? this.state.duedate : null}
                             taskAction={this.state.taskAction}
                             setFieldValue={this.setFieldValue}
                             //submitted={this.state.submitted}
                             min={min}
                             updatetask = {this.updatetask}
                             dateSwap = {this.dateSwap}
                         />

                     </div>


                 </SimpleModal>
                 }

             </React.Fragment>

        );
    }
}


const mapStateToProps = state => ({


});
export default  withRouter(connect(mapStateToProps, {
    readStatus:taskActions.readStatus
})(ListCard));
