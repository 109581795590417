import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import {Draggable, Dropzone, state} from 'react-page-maker';
import {withRouter} from 'react-router';
import {connect} from "react-redux";

class DraggableLayoutR1C4 extends Component {
  _onDrop = (data, cb) => {
    if (data.id === this.props.id) {
      return false;
    }

    // no need to ask id and name again
    if (data.payload && data.payload.dropped) {
      return cb(data);
    }

    // This can be an async call or some modal to fetch data
    let name = data.name;
    let uniqueId=localStorage.getItem('uniqueId')?parseInt(localStorage.getItem('uniqueId')):0
    let newid=uniqueId+1
    const id = "id_"+(newid)
    localStorage.setItem('uniqueId', newid);

    const result = cb({
      ...data,
      name,
      id,
      payload: { dropped: true }
    });
  }

  render() {
    const {
      dropzoneID,
      parentID,
      showBasicContent,
      showPreview,
      id,
      dropzoneProps,
      initialElements,
      ...rest
    } = this.props;

    if (showBasicContent) {
      return (
          <Draggable { ...this.props } >
            <span className="drag-icon">{ rest.name }</span>
          </Draggable>
      )
    }

    if (showPreview) {
      return (
          <div>
            <div className="mt-3">
              <Row className="mb-3">
                <Col sm="3">
                  <div className="build-row">
                   {rest.childNode['canvas-1-1']}
                  </div>
                </Col>
                <Col sm="3">
                  <div className="build-row">
                   {rest.childNode['canvas-1-2']}
                  </div>
                </Col>
                <Col sm="3">
                  <div className="build-row">
                   {rest.childNode['canvas-1-3']}
                  </div>
                </Col>
                <Col sm="3">
                  <div className="build-row">
                   {rest.childNode['canvas-1-4']}
                  </div>
                </Col>
              </Row>

            </div>
          </div>
      )
    }

    const filterInitialElements = (dID) => {
      return initialElements.filter(e => e.dropzoneID === dID) || [];
    };

    return (
        <Draggable  id="grid-layout-builder" { ...this.props } draggable={!this.props.DragDisabled.dragStatus}>
          <div className="layout-builder-titles">{ rest.name }</div>
          <div className="mt-3">
            <FaTrash
                className="pull-right"
                color="#dc3545"
                onClick={() => state.removeElement(id, dropzoneID, parentID)}
            />
            <Row className="mb-3 inner-layout-builder">
              <Col sm="3">
                <Dropzone
                    placeholder="Drop Here"
                    parentID={id}
                    initialElements={filterInitialElements('canvas-1-1')}
                    id="canvas-1-1"
                    onDrop={this._onDrop}
                    capacity={5}
                />
              </Col>
              <Col sm="3">
                <Dropzone
                    placeholder="Drop Here"
                    parentID={id}
                    initialElements={filterInitialElements('canvas-1-2')}
                    id="canvas-1-2"
                    onDrop={this._onDrop}
                    capacity={5}
                />
              </Col>
              <Col sm="3">
                <Dropzone
                    placeholder="Drop Here"
                    parentID={id}
                    initialElements={filterInitialElements('canvas-1-3')}
                    id="canvas-1-3"
                    onDrop={this._onDrop}
                    capacity={5}
                />
              </Col>
              <Col sm="3">
                <Dropzone
                    placeholder="Drop Here"
                    parentID={id}
                    initialElements={filterInitialElements('canvas-1-4')}
                    id="canvas-1-4"
                    onDrop={this._onDrop}
                    capacity={5}
                />
              </Col>
            </Row>

          </div>
        </Draggable>
    )
  }
}
const mapStateToProps = state => ({
  DragDisabled:state.DynamicDashboard.getDragDisabled
});
export default  withRouter(connect(mapStateToProps, {


})(DraggableLayoutR1C4));

