import React, {Component} from 'react';
import {MetricFilterLeft,MetricFilterRight} from "../views/MetricFilterData";

class MetricFilter extends Component {
    constructor(props) {
        super(props);

    }


    render() {

        return (

            <div className="filter-wrapper-ibt-metrics">
                <div className="float-panel-header">
                    <MetricFilterLeft />
                   <MetricFilterRight/>
                </div>
            </div>

        );
    }


}

export default MetricFilter;