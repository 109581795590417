import React, {Component,useEffect} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import {Scrollbars} from "react-custom-scrollbars";
import summery from "./summery.json"
import {getUrlSegment, validateEmail} from "../../../utilities/CustomFunctions";
import {getProfile} from "../../../utilities/AuthService";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as CeShareViewActions from "../../../actions/CeShareView";
import { debounce } from "throttle-debounce";
import LoaderSvg from "../../views/LoaderSVG";
import moment from "moment";
import SimpleModal from "../../views/SimpleModal";
import {DateTimeField} from "../../views/FormElements";
import {ES_INDEX} from "../../../constants";
import * as collectionActions from "../../../actions/Collections";
import * as whatsappActions from "../../../actions/Whatsapp";
import * as taskActions from "../../../actions/ToDo";
import DatePickerBox from "../../views/DatePickerBox";
import {OverlayTrigger, Popover} from "react-bootstrap";
import AutoComplete from "../../views/AutoComplete";
import EmailTextEditor from "./EmailTextEditor";
import AlertModal from "../../views/AlertModal";
import OutsideAlerter from "../../views/OutsideAlerter";
import UploadFile from "../../../utilities/Upload/UploadFile";
import ProjectChat from "../chat/ProjectChat";
import CollectionDashBoard from "../collections/CollectionDashBoard";

class FlagSidepanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFollowupModal :false,
            followupCallsList : [],
            leadRecordId : '',
            lead_id : '',
            followup_category :'',
            followup_status :'',
            paymentProgramId:'',
            PaymentUrl:'',invoiceShow: false,
            invoiceModal: false,
            showPaymentModal: false,
            submitPaymentModal: false,
            showConvertPopStatus : false,
            invoiceData: [],
            payment_start_date: new Date(),
            start_date: new Date(),
            program_id: '',
            selectedPaymentlist:[],
            profileValues: [],
            lead_name: '',
            lead_age: '',
            lead_gender: '',
            lead_location: '',
            lead_job: '',
            lead_email: '',
            lead_mobile: '',
            lead_height: '',
            lead_weight: '',
            lead_bmi: '',
            lead_time: '',
            lead_note :'',
            locationOptions : [],
            locationComponentArr :[],
            checkedFlag:[],
            checkedRow:[],
            clearFlagID:[],
            flagItems:[],
            save:false,
            sendEmailList:[],
            sendSMSList:[],
            sendWhatsappList:[],
            email:'',
            emailContent:'',
            emailSubject:'',
            smsContent:'',
            whatsappContent:'',
            phone:'',
            emailError:false,
            emailContentError:false,
            emailSubjectError:false,
            phoneError:false,
            smsContentError:false,
            whatsappContentError:false,
            whatsappToError:false,
            showAttachCtr :false,
            filename: null,
            uploadedFiles : [],
            emailStatus:false,
            smsStatus:false,
            WhatsappStatus:false,
            validEmail:false,
            validEmailCc:false,
            validSms:false,
            validWhatsapp:false,
            summaryDetails:[],
            ccDataList:[],
            sendcc:[],

        }
        this.update=debounce(500, this.update);
        this.getUploadedFile = this.getUploadedFile.bind(this);

    }
    componentDidMount() {
        this.getProgramList();
        this.loadProfileInfo();
        this.followupTasks();
        let jString=JSON.stringify(this.props.flagItems[0].flags)
        this.setState({flagItems:JSON.parse(jString)})

        let checkedRow=this.props.checkedRow[0] ||'';
        let collectionId=getUrlSegment(1) === 'project' ? atob(getUrlSegment(4)) : atob(getUrlSegment('3'));
        this.props.getselectedcollections({ _id : checkedRow, collectionid : collectionId, metainfo :true});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.invoiceDetails.data !== prevProps.invoiceDetails.data) {
            this.setState({invoiceShow: false, invoiceData: [], submitPaymentModal:false, PaymentUrl:'' });
            if(this.props.invoiceDetails.data.length > 0) {
                let payment_url = this.props.invoiceDetails.data[0].payment_url;
                this.setState({invoiceShow: true, invoiceData: this.props.invoiceDetails.data, submitPaymentModal:false, PaymentUrl:payment_url });
            }
        }
        if(this.props.lead_id !== prevProps.lead_id) {
            if(this.props.lead_id){
                let params = {
                    "reference": this.props.lead_id,
                }
                this.props.invoiceList(params)
            }
        }

        if(this.props.flagItems !== prevProps.flagItems) {
            this.loadProfileInfo();
            console.log(this.props.flagItems)
            console.log(this.props.checkedRow)
            console.log(this.props.collectionData)

        }

        if(this.props.followupTaskData !== prevProps.followupTaskData){
            this.setState({
                followupTaskList : this.props.followupTaskData.followupTaskList
            })

        }

        if (this.props.listSelectedCollections.SelectedCollections.data !== prevProps.listSelectedCollections.SelectedCollections.data) {
            let selectedCollectionData = this.props.listSelectedCollections.SelectedCollections.data || []
            let emailList = [];
            let smsList = [];
            let whatsappList = [];
            if (selectedCollectionData.length !== 0) {
                selectedCollectionData.map((list, lIndex) => {
                    if(list.type === "columnbreak" || list.type === "fieldset"){
                        if (list.values && list.values.length !== 0) {
                            list.values.map((item, gIndex) => {
                                if(item.type === 'email'){
                                    emailList.push(item)
                                }
                                if(item.type === 'phonenumber'){
                                    smsList.push(item)
                                    whatsappList.push(item)
                                }
                            })
                        }
                    }
                    else{
                        if(list.type === 'email'){
                            emailList.push(list)
                        }
                        if(list.type === 'phonenumber'){
                            smsList.push(list)
                            whatsappList.push(list)
                        }
                    }
                })
            }

            let sendEmailList = [];
            let sendSMSList = [];
            let sendWhatsappList = [];
            if (emailList.length !== 0) {
                emailList.map((list, lIndex) => {
                    if (list.values && list.values.length !== 0) {
                        list.values.map((item, index) => {
                            if (item.value !== '') {
                                sendEmailList.push(item.value)
                            }
                        })
                    }
                })
            }
            if (smsList.length !== 0) {
                smsList.map((list, lIndex) => {
                    if (list.values && list.values.length !== 0) {
                        list.values.map((item, index) => {
                            if (item.value !== '') {
                                sendSMSList.push(item.value)
                            }
                        })
                    }
                })
            }
            if (whatsappList.length !== 0) {
                whatsappList.map((list, lIndex) => {
                    if (list.values && list.values.length !== 0) {
                        list.values && list.values.length !== 0 && list.values.map((item, index) => {
                            if (item.value !== '') {
                                sendWhatsappList.push(item.value)
                            }
                        })
                    }
                })
            }
            this.setState({
                selectedCollectionData: selectedCollectionData,
                sendEmailList: sendEmailList,
                sendSMSList: sendSMSList,
                sendWhatsappList: sendWhatsappList
            })
        }


        if(this.props.sendFieldEmailStatus.data !== prevProps.sendFieldEmailStatus.data){
            if(this.props.sendFieldEmailStatus.data.success === true){
                this.setState({emailStatus:true})
            }

        }
        if(this.props.sendFieldSMStatus.data !== prevProps.sendFieldSMStatus.data){
            if(this.props.sendFieldSMStatus.data.success === true){
                this.setState({smsStatus:true})
            }
        }
        if(this.props.sendFieldWhatsappStatus.data !== prevProps.sendFieldWhatsappStatus.data){
            if(this.props.sendFieldWhatsappStatus.data.success === true){
                this.setState({WhatsappStatus:true})
            }
        }

        if(this.props.chatData.converted !== prevProps.chatData.converted) {
            //console.log(this.props.chatData.converted)
            if(this.props.chatData.converted.data && this.props.chatData.converted.data.status) {
                if(this.props.chatData.converted.data.status === true) {
                    this.setState({convertedToPatient : true})
                    let params = {
                        "programid": this.state.program_id,
                        "leadid": this.props.leadRecordId
                    }
                    this.props.sendInvoice(params);
                }
            }
        }

        /*if(this.props.externalData !== prevProps.externalData) {
            //location
            this.getLocations()
        }*/
    }


    loadProfileInfo = () => {
        let profileValues = this.props.profileValues;
       // console.log(profileValues)
        this.setState({
            profileValues: profileValues,
            lead_name: profileValues ? profileValues['1285339021'] : '',
            lead_age: profileValues ? profileValues['900047680'] : '',
            lead_gender: profileValues ? profileValues['264162770'] : '',
            lead_location: profileValues ? profileValues['1342905233'] : '',
            lead_job: profileValues ? profileValues['1168206831'] : '',
            lead_email: profileValues ? profileValues['287959290'] : '',
            lead_id: profileValues ? profileValues['1500062073'] : '',
            lead_mobile: profileValues ? profileValues['1339990172'] : '',
            lead_height: profileValues ? profileValues['148790709'] : '',
            lead_weight: profileValues ? profileValues['512912199'] : '',
            lead_bmi: profileValues ? profileValues['1375371033'] : '',
            lead_time: profileValues ? profileValues['937258526'] : '',
            lead_note : profileValues ? profileValues['86711669'] :''
        });
    }

    followupTasks = () => {
        if(this.props.leadRecordId && this.props.leadRecordId !== ''){
            let params = {
                recordid : this.props.leadRecordId,
                collectionid : atob(getUrlSegment(3))
            }

            this.props.getFollowupTasks(params);
        }

    }
    handleProfileChange = (e, masterid) => {
        var fieldValue = e.target.value;
        var fieldName = e.target.name;



        this.setState({[fieldName]: fieldValue },function() {
            if(fieldName === 'lead_height' || fieldName === 'lead_weight') {
                let lead_height = this.state.lead_height;
                let lead_weight = this.state.lead_weight;
                let lead_bmi = '';
                if(parseFloat(lead_height) >0 && parseFloat(lead_weight)>0) {
                    lead_bmi = parseFloat(lead_weight) / ( parseFloat(lead_height) * parseFloat(lead_height));
                    this.setState({
                        lead_bmi : lead_bmi
                    })
                }
            }
        });
    }

    fieldUpdate=(e,id,label,type,recordid)=>{

        //this.props.flagItems[id]=e.target.value

        let param ={
            "uid":getProfile().id,
            "collectionid":atob(getUrlSegment(3)),
            "recordid":recordid,
            "fields":[
                {
                    "type":type,
                    "masterid":id,
                    "label":label,
                    "value":[
                        {
                            "value":e.target.value,
                            "refIndexNo":0
                        }
                    ],
                    "external_linked":"no"
                }

            ]
        }
       this.update(param)
        this.setState({[e.target.name]:e.target.value})
    }
    update(param){
        this.props.fieldUpdate(param)
    }

    showAddFollowupModal = (call) => {
        let appointmentdate = new Date();
        let dateaction = 'add';
        let date_id = '';
        if(call) {
            appointmentdate = new Date(call.start_time);
            dateaction = 'edit';
            date_id = call.id;
        }
        this.setState({
            dateaction : dateaction,
            appointmentdate : appointmentdate,
            showFollowupModal : true,
            date_id : date_id
        })
    }

    hideAddFollowupModal = () => {
        this.setState({
            showFollowupModal : false
        })
    }

    saveFollowupDate = () => {
        let collectionId = atob(getUrlSegment(3)); //Lead entity Id
        let entityId = ES_INDEX+'10021';
        let startTime = this.state.appointmentdate.toISOString();
        // moment(this.state.appointmentdate).format('YYYY-MM-DD HH:mm:ss').utc();
        let endTime = this.state.appointmentdate.toISOString();
        let subject = "Follow up call";
        let description = "Follow up call from whatsapp";
        let recurrenceRule = "";
        let hostId = getProfile().id;
        let month = moment(this.state.appointmentdate).format('MM');
        let year = moment(this.state.appointmentdate).format('YYYY');
        let date = moment(this.state.appointmentdate).format('YYYY-MM-DD HH:mm:ss');
        let time = moment(this.state.appointmentdate).format('HH:mm a');
        let category = "Call";
        let fullday = "No";
        let formconcat = this.props.lead_id+" Follow Up Call "+ startTime+" "+endTime+" "+ " "+ subject+ " "+description+
            " "+recurrenceRule+" "+ month+" "+year+ " "+time+ " "+category+" "+fullday;
        let metaForm = [{
            "type": "number",
            "masterid": "807157770",
            "label": "attendee_id",
            "value": [{
                "refIndexNo": 0,
                "value": this.props.lead_id
            }],
            "external_linked" : "no"
        }, {
            "type": "text",
            "masterid": "719723961",
            "label": "subject",
            "value": [{
                "refIndexNo": 0,
                "value": subject
            }],
            "external_linked": "no"
        }, {
            "type": "text",
            "masterid": "700987138",
            "label": "location",
            "value": [{
                "refIndexNo": 0
            }],
            "external_linked": "no"
        }, {
            "type": "text",
            "masterid": "59977177",
            "label": "start_time",
            "value": [{
                "refIndexNo": 0,
                "value": startTime
            }],
            "external_linked": "no"
        }, {
            "type": "text",
            "masterid": "740795586",
            "label": "end_time",
            "value": [{
                "refIndexNo": 0,
                "value": endTime
            }],
            "external_linked": "no"
        }, {
            "type": "text",
            "masterid": "809384225",
            "label": "description",
            "value": [{
                "refIndexNo": 0,
                "value" : description
            }],
            "external_linked": "no"
        }, {
            "type": "text",
            "masterid": "1412520341",
            "label": "recurrence_rule",
            "value": [{
                "refIndexNo": 0,
                "value" : recurrenceRule
            }],
            "external_linked": "no"
        }, {
            "type": "number",
            "masterid": "176439646",
            "label": "host_id",
            "value": [{
                "refIndexNo": 0,
                "value": hostId
            }],
            "external_linked": "no"
        }, {
            "type": "number",
            "masterid": "1455382640",
            "label": "month",
            "value": [{
                "refIndexNo": 0,
                "value": month
            }],
            "external_linked": "no"
        }, {
            "type": "number",
            "masterid": "1536506600",
            "label": "year",
            "value": [{
                "refIndexNo": 0,
                "value": year
            }],
            "external_linked": "no"
        }, {
            "type": "datepicker",
            "masterid": "749206920",
            "label": "date",
            "value": [{
                "refIndexNo": 0,
                "value": date
            }],
            "external_linked": "no"
        }, {
            "type": "timepicker",
            "masterid": "600885400",
            "label": "time",
            "value": [{
                "refIndexNo": 0,
                "value": time
            }],
            "external_linked": "no"
        }, {
            "type": "dropdown",
            "masterid": "1136635310",
            "label": "category",
            "value": [{
                "refIndexNo": 0,
                "value": category
            }],
            "external_linked": "no"
        }, {
            "type": "toggle",
            "masterid": "1247503762",
            "label": "full_day",
            "value": [{
                "refIndexNo": 0,
                "value": fullday
            }],
            "external_linked": "no",
            "checkboxoption": "textoption"
        }, {
            "type": "dropdown",
            "masterid": "1207964459",
            "label": "isfollowup",
            "value": [{
                "refIndexNo": 0,
                "value": 'yes'
            }],
            "external_linked": "no"
        }, {
            "type": "datepicker",
            "masterid": "677904848",
            "label": "followup_date",
            "value": [{
                "refIndexNo": 0,
                "value": date
            }],
            "external_linked": "no"
        }];
        let formData = {
            mode: this.state.dateaction === 'edit' ? "update" : "save",
            parent: entityId,
            uid: getProfile().id,
            usertype: getProfile().usertype,
            referenceID: entityId,
            formconcat: formconcat,
            formdata: JSON.stringify(metaForm),
            child: this.state.date_id,
            formtype: '',
            formSource: 'whatsapp',
            reference : this.props.reference ? this.props.reference : ''
        };
        this.props.saveForm(formData);

        //Save to lead entity

        let followupCalls = this.props.followupCallsList;
        //console.log(followupCalls)
        let followup_category = [];
        let followup_date = [];
        let followup_status = [];
        // console.log(followupCalls)
        if(followupCalls.length > 0) {
            for(var i=0; i<followupCalls.length; i++){
                followup_category.push({"refIndexNo" : i, value : followupCalls[i].category });
                followup_date.push({"refIndexNo" : i, value : followupCalls[i].date });
                followup_status.push({"refIndexNo" : i, value : followupCalls[i].status });
            }
        }
        followup_category.push({"refIndexNo" : followupCalls.length, value : this.state.followup_category });
        followup_date.push({"refIndexNo" : followupCalls.length, value : date });
        followup_status.push({"refIndexNo" : followupCalls.length, value : this.state.followup_status });


        let fieldsJson = [{
            "type": "dropdown",
            "masterid": "641650952",
            "label": "followup_category",
            "value": followup_category,
            "external_linked": "no"
        },{
            "type": "datepicker",
            "masterid": "910893713",
            "label": "followup_date",
            "value": followup_date,
            "external_linked": "no"
        },
            {
                "type": "text",
                "masterid": "1500855405",
                "label": "followup_status",
                "value": followup_status,
                "external_linked": "no"
            }];
        let params = {
            "uid": getProfile().id,
            "collectionid": collectionId ,
            "recordid": this.props.leadRecordId,
            "fields": fieldsJson
        }
        //console.log(params);//return false;
        this.props.updateFields(params);


        this.setState({
            successMessage : 'Followup calls updated successfully'
        })
        this.hideAddFollowupModal();

    }

    setFollowupDate = (id,date) => {
        this.setState({ [id] : date});
    }

    handleDateChange = (date) =>{
        this.setFieldValue('910893713', 'followup_date', date);
        this.setState({start_date : date});
    }
    handlePaymentDateChange = (date) =>{
        this.setState({payment_start_date : date});
    }
    handleConvertDateChange = (date) =>{
        this.setState({start_date : date});
    }

    invoiceClick=(e, reference)=>{
        this.setState({invoiceModal : !this.state.invoiceModal});
    }
    showConvertLeadPop = () => {
        this.setState({showConvertPopStatus : true})
    }
    hideConvertLeadPop = () => {
        this.setState({showConvertPopStatus : false})
    }
    convertLeadToPatient = () => {
        let start_date = this.state.start_date;
        let program_id = this.state.program_id;
        let invalid =0;
        if(this.state.program_id === '') {
            invalid++;
            this.setState({program_field_error : 'Please select a program'});
        }
        if(invalid === 0) {
            let params = {ids : this.props.leadRecordId, programid : program_id, date : start_date};
            this.props.convertLeadToPatient(params);
            this.setState({showConvertPopStatus : false})
        }

    }
    paymentModal = (e, programList, selectedInterests) => {
        this.setState({showPaymentModal : !this.state.showPaymentModal})
    }
    RefreshClick = (e, paymentLeadId) => {
        if(paymentLeadId){
            let params = {
                "reference": paymentLeadId,
            }
            this.props.invoiceList(params)
        }
    }
    closePaymentModal = () => {
        this.setState({showPaymentModal : false, payment_start_date: new Date()})
    }
    handlePaymentChange = (e) => {
        this.setState({paymentProgramId : e.target.value})
    }
    // submitPayment = () => {
    //     let payment_start_date = this.state.payment_start_date ? moment(this.state.payment_start_date).format('YYYY-MM-DD') : '';
    //     if(this.state.paymentProgramId !== '' && payment_start_date !== ''){
    //         let PaymentUrl = `https://data-sepalika.bsynapse.com/payment/` + btoa(this.props.lead_id) + `/` + btoa(this.state.paymentProgramId) + `/` + btoa(this.props.leadRecordId) + `/` + btoa(payment_start_date)
    //         this.setState({submitPaymentModal : true, showPaymentModal : false, PaymentUrl : PaymentUrl, invoiceData:[]})
    //     }
    //     else{
    //         let payment_error = payment_start_date === '' ? 'Please select a date' : 'Please select a program';
    //         this.setState({payment_error : payment_error})
    //     }
    // }
    submitPayment = () => {
        let payment_start_date = this.state.payment_start_date ? moment(this.state.payment_start_date).format('YYYY-MM-DD') : '';
        if(this.state.paymentProgramId !== '' && payment_start_date !== ''){
            let PaymentUrl = `https://data-sepalika.bsynapse.com/payment/` + btoa(this.props.lead_id) + `/` + btoa(this.state.paymentProgramId) + `/` + btoa(this.props.leadRecordId) + `/` + btoa(payment_start_date);
            let formValid = 0;
            let sendEmailList=this.state.sendEmailList||[];
            let emailSubject = 'Thank You For Your Interest in Sepalika'

            let emailContent = 'Dear ' + this.state.lead_name + ', <br> ' +
            '<br>' +
            'Thank you for making Sepalika a part of your journey to vibrant health. <br>' +
            '<br>' +
            'The goal of our program is to identify your health concerns & see how we can use nutrition and natural healing solutions to support them. <br>' +
            '<br>' +
            'Payment for 1st Consultation would be Rs.15000+18% GST. <br>' +
            '<br>' +
            'Payment Link - ' + PaymentUrl + '<br>' +
            '<br>' +
            'Look forward to getting you on the path to vibrant health! <br>' +
            '<br>' +
            'Warm regards,<br>' +
            'Payal Doshi <br>' +
            'Medical Admin, Sepalika'

            if(sendEmailList.length === 0){
                formValid++;
            }
            if(sendEmailList.length !== 0){
                sendEmailList.map((list,index)=>{
                    if(validateEmail(list) === false){
                        this.setState({validEmail:true})
                        formValid++;
                    }
                })
            }
            if(formValid === 0) {
                let  param= {
                    "subject": emailSubject,
                    "content": emailContent,
                    "sendto": sendEmailList.toString(),
                    "sendcc": '',
                    "collectionid": this.props.collectionId,
                    "recordid": this.props.checkedRow.toString(),
                    "uid": getProfile().id,
                    "attachment": []
                }
                this.props.sendFieldEmails(param)
            }
            this.setState({submitPaymentModal : true, showPaymentModal : false, PaymentUrl : PaymentUrl, invoiceData:[]})
        }
        else{
            let payment_error = payment_start_date === '' ? 'Please select a date' : 'Please select a program';
            this.setState({payment_error : payment_error})
        }
    }
    handleInputChange = (e) => {
        this.setState({[e.target.name]:e.target.value});
    }
    copyToClipBoard=(e, link)=> {
        let selectedPaymentLink = link.toString();
        var dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute("id", "dummy_id");
        document.getElementById("dummy_id").value = selectedPaymentLink;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }
    getProgramList = () => {
        let entityId = '1600339341972';
        let params = {"from":0,"size":100,"search":"","uid":"2","usertype":"Superadmin","refer":"entity","dashboard":false,"facetrequired":false}
        this.props.getProgramList( entityId, params);
    }

    updateProfile = () => {

        let collectionId = atob(getUrlSegment(3)); //Lead entity Id

        let fieldsJson = [{
            "type": "text",
            "masterid": "1285339021",
            "label": "first_name",
            "value": [{"refIndex": 0,
                "value" : this.state.lead_name
            }],
            "external_linked": "no"
        },{
            "type": "number",
            "masterid": "900047680",
            "label": "age",
            "value": [{"refIndex": 0,
                "value" : this.state.lead_age
            }],
            "external_linked": "no"
        },
            {
                "type": "dropdown",
                "masterid": "264162770",
                "label": "gender",
                "value": [{"refIndex": 0,
                    "value" : this.state.lead_gender
                }],
                "external_linked": "no"
            },
            {
                "type": "dropdown",
                "masterid": "1342905233",
                "label": "location",
                "value": [{"refIndex": 0,
                    "value" : this.state.lead_location
                }],
                "external_linked": "yes"
            },
            {
                "type": "dropdown",
                "masterid": "1168206831",
                "label": "job",
                "value": [{"refIndex": 0,
                    "value" : this.state.lead_job
                }],
                "external_linked": "no"
            },
            {
                "type": "email",
                "masterid": "287959290",
                "label": "email",
                "value": [{"refIndex": 0,
                    "value" : this.state.lead_email
                }],
                "external_linked": "no"
            },
            {
                "type": "text",
                "masterid": "1339990172",
                "label": "contact_number",
                "value": [{"refIndex": 0,
                    "value" : this.state.lead_mobile
                }],
                "external_linked": "no"
            },
            {
                "type": "number",
                "masterid": "148790709",
                "label": "height",
                "value": [{"refIndex": 0,
                    "value" : this.state.lead_height
                }],
                "external_linked": "no"
            },
            {
                "type": "number",
                "masterid": "512912199",
                "label": "weight",
                "value": [{"refIndex": 0,
                    "value" : this.state.lead_weight
                }],
                "external_linked": "no"
            },
            {
                "type": "computedfield",
                "masterid": "1375371033",
                "label": "bmi",
                "value": [{"refIndex": 0,
                    "value" : this.state.lead_bmi
                }],
                "external_linked": "no"
            }];
        let params = {
            "uid": getProfile().id,
            "collectionid": collectionId ,
            "recordid": this.props.leadRecordId,
            "fields": fieldsJson
        }
        //console.log(params);//return false;
        this.props.updateFields(params);
    }
    updateCallNote = () =>{
        let collectionId = atob(getUrlSegment(3)); //Lead entity Id

        let fieldsJson = [{
            "type": "textarea",
            "masterid": "86711669",
            "label": "notes",
            "value": [{"refIndex": 0,
                "value" : this.state.lead_note
            }],
            "external_linked": "no"
        }];
        let params = {
            "uid": getProfile().id,
            "collectionid": collectionId ,
            "recordid": this.props.leadRecordId,
            "fields": fieldsJson
        }
        //console.log(params);//return false;
        this.props.updateFields(params);
    }

    handleFlagSet=(index,id,recId)=>{
        let checkedFlag = [...this.state.checkedFlag];
        let clearFlagID = this.state.clearFlagID;
        if (this.props.flagItems[0].flags.findIndex(obj => obj["id"] === id) > -1) {
            let indexId = this.props.flagItems[0].flags.findIndex(obj => obj["id"] === id)
            this.props.flagItems[0].flags.splice(indexId, 1);
            clearFlagID.push(id)

        } else {
            let items = {
                color: (this.props.collectionData.flaglist.filter(list => list.id === id))[0].color,
                count: 0,
                createdat: "",
                id: id,
                message: "",
                meta: "",
                title: ""
            }
            this.props.flagItems[0].flags.push(items);

        }


        (checkedFlag.indexOf(id) > -1) ? checkedFlag.splice(checkedFlag.indexOf(id), 1) : checkedFlag.push(id);
        this.setState({set:true,checkedFlag:checkedFlag, clearFlagID:clearFlagID, validateFlag:true,save:true})

    }
    handleFlagSetRadio=(index,id,recId, flags)=>{
        for (var i = 0; i < flags.length; i++) {
            if (this.props.flagItems[0].flags.findIndex(obj => obj["id"] === flags[i]._id) > -1)
            {
                let indexId = this.props.flagItems[0].flags.findIndex(obj => obj["id"] === flags[i]._id)
                this.props.flagItems[0].flags.splice(indexId, 1)
            }
        }

        let items = {
            color: (this.props.collectionData.flaglist.filter(list => list.id === id))[0].color,
            count: 0,
            createdat: "",
            id: id,
            message: "",
            meta: "",
            title: ""
        }
        this.props.flagItems[0].flags.push(items)
        this.setState({set:true, validateFlag:true,save:true})
    }
    clearCategory=(index, flags)=>{
        let clearFlagID = this.state.clearFlagID;
        for (var i = 0; i < flags.length; i++) {
            if (this.props.flagItems[0].flags.findIndex(obj => obj["id"] === flags[i]._id) > -1)
            {
                let indexId = this.props.flagItems[0].flags.findIndex(obj => obj["id"] === flags[i]._id)
                this.props.flagItems[0].flags.splice(indexId, 1)
                clearFlagID.push(flags[i]._id)
            }
        }
        this.setState({set:true, clearFlagID:clearFlagID, validateFlag:true,save:true})
    }

    saveFlag=()=>{
        let flags = this.props.flagItems[0].flags;
        let selectedFlags = [];
        let selectedClearFlagID = [];

        if(flags.length === 0 && this.state.clearFlagID.length !== 0){
            for (var i = 0; i < this.state.clearFlagID.length; i++) {
                selectedClearFlagID.push(this.state.clearFlagID[i])
            }
            let  param= {
                "collectionid":this.props.collectionId,
                "recordid": this.props.checkedRow.toString(),
                "flagids":selectedClearFlagID.toString(),
                "uid": getProfile().id
            }
            this.props.removeAssignedFlag(param)
        }

        if(flags.length !== 0){
            for (var i = 0; i < flags.length; i++) {
                selectedFlags.push(flags[i].id)
            }
            let param = {
                "collectionid": this.props.collectionId,
                "recordid": this.props.checkedRow.toString(),
                "flagids": selectedFlags.toString(),
                "uid": getProfile().id
            }
            let beta=this;
            setTimeout(function () {
                beta.props.assignFlag(param)
            }, 1000)
        }

        this.setState({set:true, clearFlagID:[], validateFlag:false,save:false})
    }
    close=()=>{

         if(this.state.save) {
            this.props.flagItems[0].flags = this.state.flagItems
         }
        this.props.close()
    }


    handleChangeInputEditor=(id,value)=>{
        var fieldName = id;
        var content = value;
        this.setState({ emailContent : content});
        if(value !== ""){
            this.setState({emailContentError:false})
        }
    }
    handleChangeInput=(e)=>{
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            if(e.target.name === "emailSubject"){
                this.setState({emailSubjectError:false})
            }
            if(e.target.name === "smsContent"){
                this.setState({smsContentError:false})
            }
            if(e.target.name === "whatsappContent"){
                this.setState({whatsappContentError:false})
            }
        }
    }
    toggleAttachCtr=()=>{
        this.setState({showAttachCtr : !this.state.showAttachCtr})
    }
    getUploadedFile(uploadedFile){
        let uploadedFiles = [... this.state.uploadedFiles];
        uploadedFiles.push(uploadedFile);
        this.setState({
            filename: uploadedFile.fileName,
            uploadedFiles : uploadedFiles
        });
        this.toggleAttachCtr();
    }
    removeSelectedAttachment(index) {
        let uploadedFiles = this.state.uploadedFiles;
        if(uploadedFiles) {
            uploadedFiles.splice(index, 1);
            this.setState({uploadedFiles: uploadedFiles});
        }
    }
    msgCnt=(cnt)=>{
        this.setState({msgCnt:cnt})
    }
    sendEmail(){
        let formValid = 0;
        let sendEmailList=this.state.sendEmailList||[]
        let sendcc=this.state.sendcc||[]
        let selectedCollectionData=this.state.selectedCollectionData||[]
        let uploadedFiles = this.state.uploadedFiles;
        let uploadArray = [];

        if (this.state.emailContent === '') {
            formValid++;
            this.setState({emailContentError:true})
        }
        if (this.state.emailSubject === '') {
            formValid++;
            this.setState({emailSubjectError:true})
        }
        if(sendEmailList.length === 0){
            formValid++;
        }
        if(sendEmailList.length !== 0){
            sendEmailList.map((list,index)=>{
                if(validateEmail(list) === false){
                    this.setState({validEmail:true})
                    formValid++;
                }
            })
        }
        let cc = [];
        if(sendcc.length !== 0){
            sendcc.map((list,index)=>{
                if(validateEmail(list.cc) === false){
                    this.setState({validEmailCc:true})
                    formValid++;
                }
                else{
                    cc.push(list.cc)
                }
            })
        }
        if (uploadedFiles.length > 0) {
            for (var i = 0; i < uploadedFiles.length; i++) {
                var uploadedFile = uploadedFiles[i];
                var fileNameArr = uploadedFile.fileName.split('/');
                var fileName = fileNameArr.pop();

                var fileData = {
                    fileName: uploadedFile.fileName,
                    originalfileName: uploadedFile.originalfileName
                }
                uploadArray.push(fileData);
            }
        }

        if(formValid === 0) {
            let  param= {
                "subject": this.state.emailSubject,
                "content": this.state.emailContent,
                "sendto": sendEmailList.toString(),
                "sendcc": cc.length !== 0 ? cc.toString() : '',
                "collectionid": this.props.collectionId,
                "recordid": this.props.checkedRow.toString(),
                "uid": getProfile().id,
                "attachment": uploadArray,
                "from": getUrlSegment(1) === 'collections' ? "collection" : 'entity'
            }
            this.props.sendFieldEmails(param)
        }

    }
    sendSMS(){
        let formValid = 0;
        let sendSMSList=this.state.sendSMSList||[]
        let selectedCollectionData=this.state.selectedCollectionData||[]
        if (this.state.smsContent === '') {
            formValid++;
            this.setState({smsContentError:true})
        }
        if(sendSMSList.length === 0){
            formValid++;
        }
        if(sendSMSList.length !== 0){
            sendSMSList.map((list,index)=>{
                if(list.trim().length !== 14){
                    this.setState({validSms:true})
                    formValid++;
                }
            })
        }

        if(formValid === 0) {
            let  param= {
                "sendto": sendSMSList.toString(),
                "msg": this.state.smsContent,
                "collectionid": this.props.collectionId,
                "recordid": this.props.checkedRow.toString(),
                "uid": getProfile().id
            }
            this.props.sendFieldSMS(param)
        }

    }
    emailModalClose=()=>{
        this.setState({emailStatus:false, emailContent:'', emailSubject:'', sendcc:[], filename:null, uploadedFiles:[]})
    }
    smsModalClose=()=>{
        this.setState({smsStatus:false, smsContent:''})
    }
    whatsappModalClose=()=>{
        this.setState({WhatsappStatus:false, whatsappContent:''})
    }
    removeEmail=(e, index)=>{
        let sendEmailList=this.state.sendEmailList;
        sendEmailList.splice(index, 1);
        this.setState({sendEmailList:sendEmailList})
    }
    removeSms=(e, index)=>{
        let sendSMSList=this.state.sendSMSList;
        sendSMSList.splice(index, 1);
        this.setState({sendSMSList:sendSMSList})
    }
    removeWhatsapp=(e, index)=>{
        let sendWhatsappList=this.state.sendWhatsappList;
        sendWhatsappList.splice(index, 1);
        this.setState({sendWhatsappList:sendWhatsappList})
    }
    resultDataList=( result, reference)=> {
        this.setState({ [reference] : result });

    }
    getTaglist(search){
        // let params={
        //     search:search?search:''
        // }
    }
    sendWhatsapp(){
        let formValid = 0;
        let sendWhatsappList=this.state.sendWhatsappList||[]
        let selectedCollectionData=this.state.selectedCollectionData||[]
        if (this.state.whatsappContent === '') {
            formValid++;
            this.setState({whatsappContentError:true})
        }
        if(sendWhatsappList.length === 0){
            formValid++;
        }
        if(sendWhatsappList.length !== 0){
            sendWhatsappList.map((list,index)=>{
                if(list.trim().length !== 14){
                    this.setState({validWhatsapp:true})
                    formValid++;
                }
            })
        }

        if(formValid === 0) {
            let  param= {
                "to": sendWhatsappList.toString(),
                "content": this.state.whatsappContent,
                "_id": this.props.checkedRow.toString(),
                "uid": getProfile().id,
                "from": getUrlSegment(1) === 'collections' ? "collection" : 'entity'
            }
            this.props.sendFieldWhatsapp(param)
        }

    }

    render() {
       let row = this.props.flagItems
       // console.log(this.props.flagItems[0])
        let flags = this.props.flagItems.length !==0 ?this.props.flagItems[0].flags:[]
        let rowIndex=0
       let followupCallsList = this.props.followupCallsList || [];
        let followupCategories = this.props.followupCategories || [];
        if (getUrlSegment(1) === 'project') {
            var  collectionid = (atob(getUrlSegment(4)));
        }
        else{
            var   collectionId = atob(getUrlSegment(3));
        }
        let programList = this.props.chatData.programList;

        let locationOptions = this.props.locationOptions || [];
        let genderOptions = this.props.genderOptions || [];
        let jobOptions = this.props.jobOptions || [];
        let lead_bmi = (this.state.lead_bmi && this.state.lead_bmi !== '' && this.state.lead_bmi > 0) ? parseFloat(this.state.lead_bmi).toFixed(2) :'';

        let followupTasks = this.props.followupTaskData.followupTaskList || [];

        let selectedCollectionData=this.state.selectedCollectionData||[]
        let sendEmailList=this.state.sendEmailList||[]
        let sendSMSList=this.state.sendSMSList||[]
        let sendWhatsappList=this.state.sendWhatsappList||[]
        let  container =[['bold', 'italic'], [{'list': 'ordered'}, {'list': 'bullet'},]]
        let dashboard = this.props.dashboard || []

        return (
            <div className="side-bar-panel collection-clik-panel grey-bg-panel list-view-panel side-summary">
                <div className="float-panel-header">
                    <h5 className="subheader">Summary</h5>
                    <button className="ico-lib fly-close" onClick={()=>this.close()}/>
                </div>
                <div className="float-panel-content-body">
                    <Scrollbars autoHide autoHideTimeout={1000}>
                        <div className="float-panel-content-body-inner height-100">
                            <div className="summary-card-tab no-header with-field-padding">
                                <Tabs defaultActiveKey="summary" id="uncontrolled-tab-example">
                                    {this.props.checkedRow.length === 1 &&
                                        <Tab eventKey="summary" title="Summary" tabClassName="ico-lib-v1 summary-tab">
                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                <div className="summary-card-tab-inner">

                                                    {this.props.checkedRow.length === 1 && (collectionId === '1600230072115' || collectionId === '1614837782109') &&
                                                    <React.Fragment>
                                                        <div className="profile-entry-body">
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>Name</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <input type="text" name="lead_name"
                                                                           value={this.state.lead_name}
                                                                           onChange={(e) => this.handleProfileChange(e, '1285339021')}/>
                                                                </div>
                                                            </div>
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>Age</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <input type="text" name="lead_age"
                                                                           value={this.state.lead_age}
                                                                           onChange={(e) => this.handleProfileChange(e, '900047680')}/>
                                                                </div>
                                                            </div>
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>Gender</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <div className="select-box">
                                                                        <select name="lead_gender"
                                                                                value={this.state.lead_gender}
                                                                                onChange={(e) => this.handleProfileChange(e, '264162770')}>
                                                                            <option value="">Choose Gender</option>
                                                                            {genderOptions && genderOptions.map((option, optionInd) =>
                                                                                <option value={option}>{option}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>Location</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <div className="select-box">
                                                                        <select name="lead_location"
                                                                                value={this.state.lead_location}
                                                                                onChange={(e) => this.handleProfileChange(e, '1342905233')}>
                                                                            <option>Select location</option>
                                                                            {locationOptions && locationOptions.map((loc, ind) =>
                                                                                <option
                                                                                    value={loc.recordid}>{loc.value}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>

                                                                    {/*<AutoComplete
                                                                                fieldTitle = 'lead_location'
                                                                                placeholder= ""
                                                                                sourceDataList = {locationOptions}
                                                                                searchField = 'value'
                                                                                showIcon={true}
                                                                                showSubLabel = {true}
                                                                                labelFields = 'value'
                                                                                valueField ='recordid'
                                                                                showProfile = {false}
                                                                                reference='selectedLocations'
                                                                                selectedItems = {this.state.selectedLocations}
                                                                                resultDataList = {this.resultDataList.bind(this)}
                                                                                id="lead_location"
                                                                                instantSearch = {false}
                                                                                preventInitialApiCall = {true}
                                                                                hideTitle={true}
                                                                                multiSelect = {false}

                                                                        />*/}
                                                                    {/*<input type="text" name="lead_location"
                                                                                value={this.state.lead_location}
                                                                                onChange={(e) => this.handleProfileChange(e, '1342905233')}/>*/}
                                                                </div>
                                                            </div>
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>Job</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <div className="select-box">
                                                                        <select name="lead_job"
                                                                                value={this.state.lead_job}
                                                                                onChange={(e) => this.handleProfileChange(e, '1168206831')}>
                                                                            <option value="">Choose Job</option>
                                                                            {jobOptions && jobOptions.map((option, optionInd) =>
                                                                                <option value={option}>{option}</option>
                                                                            )}
                                                                        </select>
                                                                        {/*<input type="text" name="lead_job"
                                                                value={this.state.lead_job}
                                                                onChange={(e) => this.handleProfileChange(e, '1168206831')}/>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>Email</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <input type="text" name="lead_email"
                                                                           value={this.state.lead_email}
                                                                           onChange={(e) => this.handleProfileChange(e, '287959290')}/>
                                                                </div>
                                                            </div>
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>Mobile</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <input type="text" name="lead_mobile"
                                                                           value={this.state.lead_mobile}
                                                                           onChange={(e) => this.handleProfileChange(e, '1339990172')}/>
                                                                </div>
                                                            </div>
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>Height</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <input type="number" name="lead_height"
                                                                           placeholder={"in meters"}
                                                                           value={this.state.lead_height}
                                                                           onChange={(e) => this.handleProfileChange(e, '148790709')}/>
                                                                </div>
                                                            </div>
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>Weight</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <input type="number" name="lead_weight"
                                                                           value={this.state.lead_weight}
                                                                           onChange={(e) => this.handleProfileChange(e, '512912199')}/>
                                                                </div>
                                                            </div>
                                                            <div className="each-details-entry">
                                                                <div className="details-entry-left">
                                                                    <label>BMI</label>
                                                                </div>
                                                                <div className="details-entry-right">
                                                                    <input type="number" name="lead_bmi"
                                                                           value={lead_bmi}
                                                                           onChange={(e) => this.handleProfileChange(e, '1375371033')}/>
                                                                </div>
                                                            </div>
                                                            <button className="general-btn"
                                                                    onClick={this.updateProfile}>Update
                                                            </button>
                                                        </div>

                                                        <div className="each-setting-entry" id="notes" tabIndex="0">
                                                            <div className="setting-entry">
                                                                <h5>First Call Note</h5>
                                                                <nav onClick={this.updateCallNote}>Save</nav>
                                                            </div>
                                                            <div className="setting-body">
                                                                <div className="chat-note-block">
                                                            <textarea name="lead_note"
                                                                      onChange={(e) => this.handleProfileChange(e, '86711669')}
                                                                      value={this.state.lead_note}></textarea>
                                                                    {/* <button className="general-btn" onClick={this.updateLead}>Save
                                                                            </button> */}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </React.Fragment>
                                                    }

                                                    {/*{this.props.checkedRow.length === 1 && getUrlSegment(3)  === 'MTYwMDIzMDA3MjExNQ==' &&
                                                <>
                                                    {summery.cards.map((list, index) =>
                                                        <div className="each-flag-settings" key={`flag`+index}>
                                                            {list.elements.map((item, index) =>
                                                                <>
                                                                    {item.editable ?
                                                                        <div className={`summery-editable`}>
                                                                        <p>{item.displayLabel ? item.label  : ""}</p>

                                                                            {item.type === 'textarea' &&
                                                                            <textarea name={item.masterid} onChange={(e)=>this.fieldUpdate(e,item.masterid,item.label,item.type,row.id)} >{row[item.masterid]}</textarea>
                                                                            }
                                                                            {item.type === 'text' &&
                                                                            <input type={`text`} name={item.masterid} value={row[item.masterid]} onChange={(e)=>this.fieldUpdate(e,item.masterid,item.label,item.type,row.id)} />
                                                                            }

                                                                            {item.type === 'datepicker' &&
                                                                            <input type={`date`} name={item.masterid} value={row[item.masterid]} onChange={(e)=>this.fieldUpdate(e,item.masterid,item.label,item.type,row.id)} />
                                                                            }

                                                                        </div>

                                                                        :
                                                                        <>
                                                                            {item.header ?
                                                                                <h5>{item.displayLabel ? item.label + " :" : ""} {row[item.masterid]}</h5>
                                                                                :
                                                                                <p>{item.displayLabel ? item.label + " :" : ""}  {row[item.masterid]}</p>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            )}

                                                        </div>
                                                    )}
                                                </>
                                                }*/}

                                                    {collectionId === '1600230072115' &&
                                                    <div className="each-setting-entry" id="follow-ups" tabIndex="0">
                                                        <div className="setting-entry">
                                                            <h5>Follow up</h5>

                                                            <div className="right-btn-wrap">
                                                                <OverlayTrigger rootClose trigger="click"
                                                                                placement="bottom" overlay={
                                                                    <Popover className="overlay-drop">
                                                                        <Popover.Content>
                                                                            <ul class="panel-option">
                                                                                <li>
                                                                                    <nav
                                                                                        class="panel-option-click ico-lib form-call-ico"
                                                                                        onClick={() => this.showAddFollowupModal()}>Call
                                                                                    </nav>
                                                                                </li>
                                                                                <li>
                                                                                    <nav
                                                                                        class="panel-option-click ico-lib form-assign-ico"
                                                                                        onClick={(e) => this.props.handleActionEn(e, 'task')}>Task
                                                                                    </nav>
                                                                                </li>
                                                                            </ul>
                                                                        </Popover.Content>
                                                                    </Popover>
                                                                }
                                                                >
                                                                    <nav>+ Add</nav>
                                                                </OverlayTrigger>

                                                                {/* <nav onClick={() => this.showAddFollowupModal()}>+ Add</nav>
                                                        <nav onClick={(e) => this.props.handleActionEn(e, 'task')} class="ico-lib task-ico">Add Task</nav> */}
                                                            </div>

                                                        </div>
                                                        <div className="each-flag-category">
                                                            <h5>Calls</h5>
                                                            <ul>
                                                                <React.Fragment>
                                                                    {followupCallsList.length === 0 &&
                                                                    <div>No Follow up calls</div>
                                                                    }
                                                                    {followupCallsList.length > 0 && followupCallsList.map((call, index) =>
                                                                        <li className="each-call-list">
                                                                            <p><span
                                                                                className="font-bold">{moment(call.date).format('DD MMM YYYY hh:mm a')}</span>
                                                                            </p>
                                                                            <p><span
                                                                                className="grey-txt">{call.status !== '' ? ' ' + call.status : ''}</span>
                                                                            </p>
                                                                        </li>
                                                                    )}
                                                                </React.Fragment>
                                                            </ul>
                                                        </div>
                                                        <div className="each-flag-category">
                                                            <h5>Tasks</h5>
                                                            <ul>
                                                                <React.Fragment>

                                                                    {followupTasks.length === 0 &&

                                                                    <div>No Follow up tasks</div>
                                                                    }
                                                                    {followupTasks.length > 0 && followupTasks.map((task, index) =>
                                                                        <li className="each-call-list"
                                                                            onClick={() => this.props.history.push(`/task/details/${btoa(task.masterid)}/${btoa('to-me')}`)}>
                                                                            <p>
                                                                                <span
                                                                                    className="font-bold tx-heading">{atob(task.subject)}</span>
                                                                                <div
                                                                                    className="task-status new inprogress done">{task.status !== '' ? ' ' + task.status : ''}</div>
                                                                            </p>

                                                                            <p><span
                                                                                className="grey-txt">{moment(task.duedate).format('DD MMM')}</span>
                                                                            </p>
                                                                        </li>
                                                                    )}
                                                                </React.Fragment>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    }

                                                    {/*{this.props.checkedRow.length === 1 && getUrlSegment(3)  === 'MTYwMDIzMDA3MjExNQ==' &&*/}
                                                    {/*<>*/}
                                                    {/*    <div className="each-setting-entry" id="payment" tabIndex="0">*/}
                                                    {/*        <div className="setting-entry">*/}
                                                    {/*            <h5>Payment</h5>*/}
                                                    {/*            <nav onClick={(e) => this.RefreshClick(e, this.props.lead_id)}>Refresh</nav>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className="setting-body">*/}
                                                    {/*            {this.props.invoiceDetails.loading &&*/}
                                                    {/*            <LoaderSvg/>*/}
                                                    {/*            }*/}
                                                    {/*            <nav onClick={(e) => this.paymentModal(e, programList, this.props.program)} className="set-tag">{(this.state.submitPaymentModal || this.state.invoiceData.length !== 0) ? 'Change Payment Url' : 'Generate Payment Url'}</nav>*/}
                                                    {/*            {this.state.submitPaymentModal &&*/}
                                                    {/*            <React.Fragment>*/}
                                                    {/*                <div className="clipboard-wrap">*/}

                                                    {/*                    <div className="clipboard-header">*/}
                                                    {/*                        <nav>{this.state.PaymentUrl}</nav>*/}
                                                    {/*                    </div>*/}
                                                    {/*                    <div className="clipboard-footer">*/}
                                                    {/*                        <button className="general-btn dark" onClick={(e) => {*/}
                                                    {/*                            this.copyToClipBoard(e, this.state.PaymentUrl)*/}
                                                    {/*                        }}>Copy to Clipboard*/}
                                                    {/*                        </button>*/}
                                                    {/*                    </div>*/}
                                                    {/*                </div>*/}
                                                    {/*            </React.Fragment>*/}
                                                    {/*            }*/}

                                                    {/*        </div>*/}
                                                    {/*        {this.state.invoiceData.length !== 0 &&*/}
                                                    {/*        <>*/}
                                                    {/*            <div className="setting-entry">*/}
                                                    {/*                <h5>Status</h5>*/}
                                                    {/*            </div>*/}
                                                    {/*            <div className="setting-body">*/}
                                                    {/*                <div className={`set-tag goal`}>Done</div>*/}

                                                    {/*                <div className="invoice-wrapper auto-wid" id="convert-lead-to-patient" tabIndex="0">*/}
                                                    {/*                    <a href={`invoice/` + btoa(this.props.lead_id)}*/}
                                                    {/*                    target={`_blank`} className="text-links">View Invoice</a>*/}
                                                    {/*                </div>*/}
                                                    {/*            </div>*/}
                                                    {/*        </>*/}
                                                    {/*        }*/}
                                                    {/*    </div>*/}
                                                    {/*</>*/}
                                                    {/*}*/}

                                                </div>
                                            </Scrollbars>
                                        </Tab>
                                    }
                                    <Tab eventKey="flag" title="Flag" tabClassName="ico-lib flag-tab">
                                        <Scrollbars autoHide autoHideTimeout={1000}>
                                            <div className="summary-card-tab-inner">
                                                <div className="each-flag-settings perisitent">
                                                        <div className="flag-settings-header">
                                                            <h5>Flag</h5>
                                                            {/*<nav>Save</nav>*/}
                                                            <nav data-mode="save" onClick={() => this.saveFlag(row, row['id'])}>Save</nav>
                                                        </div>
                                                        <div className="flag-settings-content">
                                                            <Scrollbars autoHide autoHideTimeout={1000}>


                                                            <div className="flag-settings-content-inner">
                                                                {this.props.loader.loaderType === 'Assign-Flag-Loader' && this.props.loader.status === true &&
                                                                <span className="Loader-holder">
                                                                    <LoaderSvg/>
                                                                </span>
                                                                }
                                                                {this.props.loader.loaderType === 'Remove-Assigned-Flag-Loader' && this.props.loader.status === true &&
                                                                <span className="Loader-holder">
                                                                    <LoaderSvg/>
                                                                </span>
                                                                }
                                                                {this.props.collection.flagCategory.map((data, itmIndex) =>
                                                                    <React.Fragment>
                                                                    {data.flags.length !== 0 &&
                                                                    <div className="each-flag-category">
                                                                    <React.Fragment>
                                                                    {data.multiselection === true ?
                                                                        <h5>{data.category === 'uncategorized' ? 'Other' : data.category}</h5>
                                                                        :
                                                                        <div className="flag-category-header">
                                                                            <h5>{data.category === 'uncategorized' ? 'Other' : data.category}</h5>
                                                                            <button className="clear-all"
                                                                                onClick={()=>this.clearCategory(rowIndex, data.flags)}>
                                                                                Clear
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                    <ul>
                                                                    {data.flags.map((item, itmIndex) =>

                                                                    <li>
                                                                        {data.multiselection === true &&
                                                                            <div className="select-chk-block flag-tag">
                                                                                <label className="control control-checkbox"> {item.title}
                                                                                    {/*{this.props.checkedRow.length <= 1 ?*/}
                                                                                    <input
                                                                                        checked={(flags.filter(list => list.id === item._id)).length === 1}
                                                                                        type="checkbox" className="inp-chk" name="dataview"
                                                                                        onChange={() => this.handleFlagSet(rowIndex, item._id, row['id'])}

                                                                                    />
                                                                                    <div className="control-indicator"/>
                                                                                </label>
                                                                                <span className="flag-color"
                                                                                    style={(flags.filter(list => list.id === item._id)).length === 1 ? {
                                                                                        background: item.color,
                                                                                        borderColor: item.color
                                                                                    } : {}}/>
                                                                            </div>
                                                                        }
                                                                        {data.multiselection === false &&
                                                                            <React.Fragment>
                                                                            <div className="radio-wrap flag-tag">
                                                                                <div className="radio-block">
                                                                                    <input type="radio" className="inp-chk"
                                                                                        name={data.category} value={item._id}
                                                                                        onChange={() => this.handleFlagSetRadio(rowIndex, item._id, row['id'], data.flags)}
                                                                                        checked={(flags.filter(list => list.id === item._id)).length === 1} />
                                                                                    <label>{item.title}</label>
                                                                                </div>
                                                                                <span className="flag-color"
                                                                                    style={(flags.filter(list => list.id === item._id)).length === 1 ? {
                                                                                        background: item.color,
                                                                                        borderColor: item.color
                                                                                    } : {}}/>
                                                                            </div>
                                                                            </React.Fragment>
                                                                        }

                                                                    </li>
                                                                    )}
                                                                    </ul>
                                                                    </React.Fragment>
                                                                    </div>
                                                                    }
                                                                    </React.Fragment>

                                                                )}





                                                                    {/*<li>*/}
                                                                    {/*    <div className="select-chk-block flag-tag">*/}
                                                                    {/*        <label className="control control-checkbox"> test(0)*/}
                                                                    {/*            <input type="checkbox" className="inp-chk" />*/}
                                                                    {/*            <div className="control-indicator"/>*/}
                                                                    {/*        </label>*/}
                                                                    {/*        <span className="flag-color" style={{backgroundColor: "black"}}/>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</li>*/}
                                                                    {/*<li>*/}
                                                                    {/*    <div className="select-chk-block flag-tag">*/}
                                                                    {/*        <label className="control control-checkbox"> test(0)*/}
                                                                    {/*            <input type="checkbox" className="inp-chk" />*/}
                                                                    {/*            <div className="control-indicator"/>*/}
                                                                    {/*        </label>*/}
                                                                    {/*        <span className="flag-color" style={{backgroundColor: "Orange"}}/>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</li>*/}
                                                                    {/*<li>*/}
                                                                    {/*    <div className="select-chk-block flag-tag">*/}
                                                                    {/*        <label className="control control-checkbox"> test(0)*/}
                                                                    {/*            <input type="checkbox" className="inp-chk" />*/}
                                                                    {/*            <div className="control-indicator"></div>*/}
                                                                    {/*        </label>*/}
                                                                    {/*        <span className="flag-color" style={{backgroundColor: "green"}}/>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</li>*/}
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                    </div>
                                            </div>
                                        </Scrollbars>
                                    </Tab>
                                    {sendEmailList.length !== 0 && this.props.checkedRow.length === 1 &&
                                    <Tab eventKey="email" title="Email" tabClassName="ico-lib-v1 email-tab">
                                        <Scrollbars autoHide autoHideTimeout={1000}>
                                            <div className="summary-card-tab-inner">
                                                <React.Fragment>
                                                    {(this.props.loader.loaderType === 'Save_FieldEmails_Loader') && this.props.loader.status === true &&
                                                    <span className="Loader-holder"
                                                          style={{'background': 'transparent'}}>
                                                <LoaderSvg/>
                                            </span>
                                                    }
                                                    <div className="persitant-top padding-0">
                                                        <Scrollbars autoHide autoHideTimeout={1000}>
                                                            <div className="ind-task-input rich-area">
                                                                <span className="lb-tx">TO</span>
                                                                <div className="tag-wrapper-bl">
                                                                    <div className="tag-task-container">
                                                                        {sendEmailList.map((list, index) =>
                                                                                <div className="sl-tag">
                                                                <span className="tag-right">{list}
                                                                    <button onClick={(e) => this.removeEmail(e, index)}
                                                                            className="tag-cl">×</button>
                                                                </span>
                                                                                </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="task-inp-container input-auto-complete rich-selectize">
                                                                <AutoComplete
                                                                    eleClass=''
                                                                    fieldTitle='CC'
                                                                    placeholder='Type Email and Press Enter'
                                                                    sourceDataList={this.state.ccDataList}
                                                                    searchField='cc'
                                                                    showIcon={false}
                                                                    showSubLabel={true}
                                                                    labelFields='cc'
                                                                    valueField='id'
                                                                    subLabelField='Tags'
                                                                    showProfile={false}
                                                                    reference='sendcc'
                                                                    selectedItems={this.state.sendcc}
                                                                    resultDataList={this.resultDataList}
                                                                    id="cc"
                                                                    instantSearch={true}
                                                                    actionMethod={this.getTaglist}
                                                                    addNewSuggestion={true}
                                                                />
                                                            </div>

                                                            <div
                                                                className={`each-inp-box ${this.state.emailSubjectError ? "val-error" : ""}`}>
                                                                <input type="text" className="e-field"
                                                                       placeholder="Subject" id="emailSubject"
                                                                       name="emailSubject"
                                                                       value={this.state.emailSubject}
                                                                       onChange={this.handleChangeInput}
                                                                       autoComplete={'off'}/>
                                                            </div>

                                                            <div className={`input-block expandable-textarea ${this.state.emailContentError ? "val-error" : ""}`}>
                                                                <EmailTextEditor
                                                                    className="task-text-area-expandable"
                                                                    name="emailContent"
                                                                    id="emailContent"
                                                                    value={this.state.emailContent}
                                                                    handleChange={this.handleChangeInputEditor}
                                                                    bounds={'.expandable-textarea'}
                                                                    container={container}
                                                                />
                                                            </div>
                                                            <div className="attach-button-container">
                                                                <button className="options-button attach black-shade" type="button" onClick={() =>this.toggleAttachCtr()}></button>
                                                                {this.state.showAttachCtr &&
                                                                <OutsideAlerter
                                                                    status={this.state.showAttachCtr}
                                                                    Triggered={() => this.toggleAttachCtr()}
                                                                >
                                                                    <ul className="attach-toggler">
                                                                        <li>
                                                                            <nav className="toggle-icon ico-lib toggle-file-ico">Attach File
                                                                                <UploadFile
                                                                                    getUploadedFile={this.getUploadedFile}
                                                                                />
                                                                            </nav>
                                                                        </li>
                                                                    </ul>
                                                                </OutsideAlerter>
                                                                }
                                                            </div>
                                                            <div className="attachment-task-group" id="todoFileAssets">
                                                                {this.state.uploadedFiles.map((item, index) =>
                                                                    <div className="ind-attach-list file" id={"attachment_"+item.fileId}>
                                                                        <h5>{item.originalfileName}</h5>
                                                                        <button className="ico-lib cl-fl" data-parent={item.fileId}
                                                                                data-target="#delete-attachfile" data-action="unselectattachfiles" onClick={(e)=>this.removeSelectedAttachment(index)}></button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Scrollbars>
                                                    </div>
                                                    <div className="persitant-footer">
                                                        <div className="group-row">
                                                        </div>
                                                        <button data-masterid="0" data-mode="save" type="button"
                                                                className="general-btn"
                                                                onClick={(e) => this.sendEmail(e)}
                                                                id="createboard">Send
                                                        </button>
                                                    </div>
                                                </React.Fragment>
                                            </div>
                                        </Scrollbars>
                                    </Tab>
                                    }
                                    {sendSMSList.length !== 0 && this.props.checkedRow.length === 1 &&
                                    <Tab eventKey="sms" title="SMS" tabClassName="ico-lib-v1 sms-tab">
                                        <Scrollbars autoHide autoHideTimeout={1000}>
                                            <div className="summary-card-tab-inner">
                                                <React.Fragment>
                                                    {(this.props.loader.loaderType === 'Save_FieldSMS_Loader') && this.props.loader.status === true &&
                                                    <span className="Loader-holder"
                                                          style={{'background': 'transparent'}}>
                                                <LoaderSvg/>
                                            </span>
                                                    }
                                                    <div className="persitant-top padding-0">
                                                        <div className="ind-task-input rich-area">
                                                            <span className="lb-tx">TO</span>
                                                            <div className="tag-wrapper-bl">
                                                                <div className="tag-task-container">
                                                                    {sendSMSList.map((list, index) =>
                                                                            <div className="sl-tag">
                                                                 <span className="tag-right">{list}
                                                                     <button onClick={(e) => this.removeSms(e, index)}
                                                                             className="tag-cl">×</button>
                                                                 </span>
                                                                            </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`input-block ${this.state.smsContentError ? "val-error" : ""}`}>
                                                <textarea className="task-textarea" placeholder="SMS Content"
                                                          id="smsContent" name="smsContent"
                                                          value={this.state.smsContent}
                                                          onChange={this.handleChangeInput}>

                                                </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="persitant-footer">
                                                        <div className="group-row">
                                                        </div>
                                                        <button data-masterid="0" data-mode="save" type="button"
                                                                className="general-btn"
                                                                onClick={(e) => this.sendSMS(e)}
                                                                id="createboard">Send
                                                        </button>
                                                    </div>
                                                </React.Fragment>
                                            </div>
                                        </Scrollbars>
                                    </Tab>
                                    }
                                    {sendEmailList.length !== 0 && this.props.checkedRow.length === 1 &&
                                    <Tab eventKey="payment" title="Payment"  tabClassName="ico-lib-v1 payment-tab">
                                        <Scrollbars autoHide autoHideTimeout={1000}>
                                            <div className="summary-card-tab-inner">
                                                <React.Fragment>
                                                    {(this.props.loader.loaderType === 'Save_FieldEmails_Loader') && this.props.loader.status === true &&
                                                    <span className="Loader-holder"
                                                          style={{'background': 'transparent'}}>
                                                <LoaderSvg/>
                                            </span>
                                                    }
                                                    <div className="each-setting-entry" id="payment" tabIndex="0">
                                                        <div className="setting-entry">
                                                            <h5>Payment</h5>
                                                            <nav onClick={(e) => this.RefreshClick(e, this.props.lead_id)}>Refresh</nav>
                                                        </div>
                                                        
                                                        <div className="setting-body">
                                                            <div className="button-container">
                                                                <button data-masterid="0" data-mode="save" type="button"
                                                                        className="general-btn"
                                                                        onClick={(e) => this.paymentModal(e, programList, this.props.program)}
                                                                        id="createboard">Send Payment Url
                                                                </button>
                                                                <button data-masterid="0" data-mode="save" type="button"
                                                                        className="general-btn"
                                                                        onClick={() => this.showConvertLeadPop()}
                                                                        id="createboard">Recieved Payment
                                                                </button>
                                                            </div>
                                                            <div class="loader-block">
                                                            {this.props.invoiceDetails.loading &&
                                                            <LoaderSvg/>
                                                            }
                                                            </div>
                                                        </div>
                                                        {this.state.invoiceData.length !== 0 &&
                                                        <>
                                                            <div className="setting-entry">
                                                                <h5>Status</h5>
                                                            </div>
                                                            <div className="setting-body">
                                                                <div className={`set-tag goal`}>Done</div>

                                                                <div className="invoice-wrapper auto-wid" id="convert-lead-to-patient" tabIndex="0">
                                                                    <a href={`invoice/` + btoa(this.props.lead_id)}
                                                                       target={`_blank`} className="text-links">View Invoice</a>
                                                                </div>
                                                            </div>
                                                        </>
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            </div>
                                        </Scrollbars>
                                    </Tab>
                                    }
                                    {sendWhatsappList.length !== 0 && this.props.checkedRow.length === 1 &&
                                    <Tab eventKey="whatsapp" title="WhatsApp"  tabClassName="ico-lib-v1 wa-tab">
                                        <React.Fragment>
                                                    {(this.props.loader.loaderType === 'Save_FieldWhatsapp_Loader') && this.props.loader.status === true &&
                                                    <span className="Loader-holder"
                                                          style={{'background': 'transparent'}}>
                                                <LoaderSvg/>
                                            </span>
                                                    }
                                                    <div className="persitant-top">
                                                        <div className="ind-task-input rich-area">
                                                            <span className="lb-tx">TO</span>
                                                            <div className="tag-wrapper-bl">
                                                                <div className="tag-task-container">
                                                                    {sendWhatsappList.map((list, index) =>
                                                                            <div className="sl-tag">
                                                                 <span className="tag-right">{list}
                                                                     <button onClick={(e) => this.removeWhatsapp(e, index)}
                                                                             className="tag-cl">×</button>
                                                                 </span>
                                                                            </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`input-block ${this.state.whatsappContentError ? "val-error" : ""}`}>
                                                <textarea className="task-textarea" placeholder="Whatsapp Text"
                                                          id="whatsappContent" name="whatsappContent"
                                                          value={this.state.whatsappContent}
                                                          onChange={this.handleChangeInput}>

                                                </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="persitant-footer">
                                                        <div className="group-row">
                                                        </div>
                                                        <button data-masterid="0" data-mode="save" type="button"
                                                                className="general-btn"
                                                                onClick={(e) => this.sendWhatsapp(e)}
                                                                id="createtext">Send
                                                        </button>
                                                    </div>
                                                </React.Fragment>
                                    </Tab>
                                    }
                                    {this.props.checkedRow.length === 1 &&
                                    <Tab eventKey="chat" title="Chat" tabClassName="ico-lib-v1 chat-tab">
                                        <React.Fragment>

                                            <ProjectChat
                                                taskid={this.props.checkedRow.toString()}
                                                inviteUsers={false}
                                                type={getUrlSegment(1) === 'collections' ? "collection" : 'entity'}
                                                msgCnt={this.msgCnt}
                                                openSta={true}
                                                sound={true}
                                                toaster={true}
                                                source={'summarypanel'}
                                                //  assigneesData={assigneesData}
                                            />

                                        </React.Fragment>
                                    </Tab>
                                    }

                                    {this.props.checkedRow.length === 1 && dashboard.length !== 0 &&
                                    <Tab eventKey="dashboard" title="Dashboard" tabClassName="ico-lib-v1 dashboard-tab">
                                        <React.Fragment>
                                            <CollectionDashBoard
                                                dashboard={dashboard}
                                                source={'summarypanel'}
                                            />

                                        </React.Fragment>
                                    </Tab>
                                    }

                                </Tabs>
                            </div>
                        </div>
                    </Scrollbars>
                </div>

                {this.state.showFollowupModal === true &&
                <SimpleModal

                    Close={this.hideAddFollowupModal}
                    title={`Follow up`}
                    haveFooter={1}
                    cancelAction={this.hideAddFollowupModal}
                    OKAction={()=>this.saveFollowupDate()}
                    OKLabel={`Save`}
                    modalType={'custom-date-range'}
                >
                    <div className="reschedule-wrapper">
                        <div className="select-box" style={{ margin: '0 0 15px 0'}}>
                            <select name="followup_category" placeholder="Followup Category"  onChange={(e) => this.handleProfileChange(e, '641650952')} >
                                <option>Select</option>
                                {followupCategories.map(opt =>
                                    <option value={opt}>{opt}</option>
                                )}
                            </select>
                        </div>
                        <DateTimeField
                            name='appointmentdate' placeholder ="Date" id="appointmentdate"
                            value={this.state.appointmentdate !== '' ? this.state.appointmentdate : null}
                            //handleDateChange={this.handleDateChange}
                            setFieldValue={this.setFollowupDate}
                            min={new Date()}
                            taskAction={this.state.dateaction}

                        />
                        <div className="chat-note-block">
                            <textarea name="followup_status" onChange={(e) => this.handleProfileChange(e, '1500855405')} value={this.state.followup_status} placeholder="Followup Status"></textarea>
                            {/* <button className="general-btn" onClick={this.updateLead}>Save
                                                        </button> */}

                        </div>

                    </div>


                </SimpleModal>
                }

                {this.state.showPaymentModal &&
                <SimpleModal

                    Close={this.closePaymentModal}
                    title={`Choose the program`}
                    haveFooter={1}
                    cancelAction={this.closePaymentModal}
                    OKAction={()=>this.submitPayment()}
                    OKLabel={`Submit`}
                    extraClass="small-height-modal"
                >
                    <div>
                        <div>
                            {programList.length !== 0 ?
                                <React.Fragment>
                                    <div className="payment-mode">
                                        <div className="radio-wrapper" id="recurringmode">
                                            {programList.map((item,index) =>

                                                <div className="radio-block">
                                                    <input onChange={(e)=>this.handlePaymentChange(e)} type="radio"  value={item.program_id} name="paymentMode"/>
                                                    <label>{item.program_title + '  -  Rs. ' + item.program_price} </label>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                    <span className="error-fdls">{this.state.paymentProgramId === '' && this.state.payment_error}</span>
                                    <div className="package-date">
                                        <label>Start Date</label>
                                        <DatePickerBox handleDateChange={this.handlePaymentDateChange} startDate = {this.state.payment_start_date} id ={"payment_start_date"} name={"payment_start_date"} required="true"/>
                                    </div>
                                </React.Fragment>
                                :
                                <div className="payment-mode">
                                    <p>No Programs Found</p>
                                </div>

                            }
                        </div>
                    </div>


                </SimpleModal>
                }

                {this.state.emailStatus &&
                <AlertModal id="alertemodal"
                            title="Mail sent successfully"
                            OK_button_text="Ok"
                            OK_action={()=>this.emailModalClose()}
                            showModal={true}
                            CANCEL_action={()=>this.emailModalClose()}
                />
                }
                {this.state.smsStatus &&
                <AlertModal id="alertemodal"
                            title="SMS sent successfully"
                            OK_button_text="Ok"
                            OK_action={()=>this.smsModalClose()}
                            showModal={true}
                            CANCEL_action={()=>this.smsModalClose()}
                />
                }
                {this.state.WhatsappStatus &&
                <AlertModal id="alertemodal"
                            title="Message sent successfully"
                            OK_button_text="Ok"
                            OK_action={()=>this.whatsappModalClose()}
                            showModal={true}
                            CANCEL_action={()=>this.whatsappModalClose()}
                />
                }
                {this.state.validEmail &&
                <AlertModal id="alertemodal"
                            title="Invalid email address found in To"
                            OK_button_text="Ok"
                            OK_action={()=>this.setState({validEmail:false})}
                            showModal={true}
                            CANCEL_action={()=>this.setState({validEmail:false})}
                />
                }
                {this.state.validEmailCc &&
                <AlertModal id="alertemodal"
                            title="Invalid email address found in Cc"
                            OK_button_text="Ok"
                            OK_action={()=>this.setState({validEmailCc:false})}
                            showModal={true}
                            CANCEL_action={()=>this.setState({validEmailCc:false})}
                />
                }
                {this.state.validSms &&
                <AlertModal id="alertemodal"
                            title="Invalid phone number found"
                            OK_button_text="Ok"
                            OK_action={()=>this.setState({validSms:false})}
                            showModal={true}
                            CANCEL_action={()=>this.setState({validSms:false})}
                />
                }
                {this.state.validWhatsapp &&
                <AlertModal id="alertemodal"
                            title="Invalid phone number found"
                            OK_button_text="Ok"
                            OK_action={()=>this.setState({validWhatsapp:false})}
                            showModal={true}
                            CANCEL_action={()=>this.setState({validWhatsapp:false})}
                />
                }
                {this.state.showConvertPopStatus &&
                <SimpleModal

                    Close={this.hideConvertLeadPop}
                    title={`Convert Lead to Patient`}
                    haveFooter={1}
                    cancelAction={this.hideConvertLeadPop}
                    OKAction={()=>this.convertLeadToPatient()}
                    OKLabel={`Submit`}
                    extraClass="height-195"
                >
                    <div className="divide-block">
                        <div className="package-date">
                            <label>Choose the program</label>
                            <div className="select-box">
                                <select name="program_id" onChange={(e) => this.handleInputChange(e)}>
                                    <option>Select</option>
                                    {programList.map((item,index) =>
                                        <option value={item.program_id}>{item.program_title + '  -  Rs. ' + item.program_price}</option>
                                    )}
                                </select>
                            </div>
                            <span className="error-fdls">{this.state.program_id === '' && this.state.program_field_error}</span>
                        </div>
                        <div className="package-date">
                            <label>Start Date</label>
                            <DatePickerBox handleDateChange={this.handleConvertDateChange} startDate = {this.state.start_date} id ={"start_date"} name={"start_date"} required="true"/>
                        </div>
                    </div>


                </SimpleModal>
                }

            </div>)

    }


}
const mapStateToProps = state => ({
    loader:state.CommonReducer.loader,
    chatData : state.WhatsappReducer.listChatData,
    invoiceDetails : state.WhatsappReducer.invoiceDetails,
    assignFlagData:state.CeShareView.assignFlagData,
    fieldUpdateData:state.CeShareView.fieldUpdateData,
    followupTaskData : state.TaskReducer.followupTaskData,
    listSelectedCollections:state.CollectionsReducer.listSelectedCollections,
    sendFieldEmailStatus:state.CeShareView.sendFieldEmailStatus,
    sendFieldSMStatus:state.CeShareView.sendFieldSMStatus,
    sendFieldWhatsappStatus:state.CeShareView.sendFieldWhatsappStatus

});
export default  withRouter(connect(mapStateToProps, {
    fieldUpdate:CeShareViewActions.fieldUpdate,
    saveForm : collectionActions.saveForm,
    updateFields : whatsappActions.updateFields,
    getProgramList : whatsappActions.getProgramList,
    invoiceList : whatsappActions.invoiceList,
    sendInvoice : whatsappActions.sendInvoice,
    convertLeadToPatient : whatsappActions.convertLeadToPatient,
    getFollowupTasks : taskActions.getFollowupTasks,
    assignFlag:CeShareViewActions.assignFlag,
    removeAssignedFlag:CeShareViewActions.removeAssignedFlag,
    getselectedcollections : collectionActions.selectedcollections,
    sendFieldEmails:CeShareViewActions.sendFieldEmails,
    sendFieldSMS:CeShareViewActions.sendFieldSMS,
    sendFieldWhatsapp:CeShareViewActions.sendFieldWhatsapp

})(FlagSidepanel))

