import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as taskActions from "../../../actions/ToDo";
import {ES_INDEX} from '../../../constants'
import DatePickerBox from "../../views/DatePickerBox"
import AutoComplete from "../../views/AutoComplete"
import {Form} from "react-bootstrap";
import * as usersActions from "../../../actions/Users";
import {formatDate, getUrlSegment} from "../../../utilities/CustomFunctions";
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {USRIMG_PATH, TINY_API_KEY} from "../../../constants";
import { Editor } from '@tinymce/tinymce-react';
import LoaderSvg from "../../views/LoaderSVG";
import RichText from "../../views/RichText";

const KeyCodes = {
    comma: 188,
    enter: 13,
};
let editorInstance;
let obj;
const delimiters = [KeyCodes.comma, KeyCodes.enter];
class AddNewDiscussionDetail extends Component {

    constructor(props) {
        super(props);

        this.state={
            name:'',
            description : '',
            enddate:new Date(),
            startdate:new Date(),
            projectname:'',
            clientname:'',
            project_id :0,
            newDiscussionLoading:0,
            selectedItems : [],
            comment_err:false,
            newTags:[],
            miniMize:false,
            Maximise:false,
            Close:false,
            content: '',
            newCommentloading: 0
        };
        this.ProjectInputChange= this.ProjectInputChange.bind(this);
        this.discussionUsers = this.discussionUsers.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.PostComment=this.PostComment.bind(this)
        this.UserSelection=this.UserSelection.bind(this)
        this.strip = this.strip.bind(this)
    }

    ProjectInputChange(e) {
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }
    componentDidMount() {
        var projectid = (atob(getUrlSegment(5)));
        //console.log(projectid)
        var discussionid = (atob(getUrlSegment(6)));
        //console.log(discussionid)
        this.setState({
            projectid: projectid,
            discussionid: discussionid,
            newCommentloading: 1
        })
        var  beta =  this;
        setTimeout(function () {
            beta.setState({newCommentloading: 0})
        }, 4000)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var projectid = (atob(getUrlSegment(5)));
        //console.log( projectid)
        var discussionid = (atob(getUrlSegment(6)));
        //console.log(discussionid)
        var  beta =  this;
        if(prevProps.commentsAdddata.project_comments !== this.props.commentsAdddata.project_comments ) {
            setTimeout(function () {
                let cdata ={
                    "projectid": projectid,
                    "discussionid": discussionid,

                }
                beta.props.getDiscussionsDetails(cdata)
            }, 1000)
        }
    }
    discussionUsers(e, userCount) {
        this.setState({
            userCount : userCount,
            [e.target.name]:e.target.value
        })
        let userslist = this.props.listtags? this.props.listtags[0].assignees : '';
        // //console.log(userslist)
        let assignee_ids = userslist.map(a => parseInt(a.assigneeuser_id));
        this.setState({assignee_ids : assignee_ids})
        // //console.log(assignee_ids)
    }
    UserSelection(e, userid) {
        userid = parseInt(userid);
        //console.log(this.state.assignee_ids )
        let assignee_ids = [...this.state.assignee_ids];
        (assignee_ids.indexOf(userid) > -1) ? assignee_ids.splice(assignee_ids.indexOf(userid), 1) : assignee_ids.push(userid);
        this.setState({assignee_ids: assignee_ids});
    }
    handleEditorChange = (e) => {
        //console.log('Content was updated:', e.target.getContent());
        this.setState({content : e.target.getContent()});
    }
    handleKeyUp = (e) => {
        //console.log('Content for preview keyup:', e.target.innerHTML);
        let content =  e.target.innerHTML;
        this.setState({content : content});
    }
    strip(html){
        var doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }
    PostComment(event) {
        let formValid = 0;
        var projectid = this.props.projectid;
        var discussionid = this.props.discussionid;
        var mode = (this.state.project_id =='0' ? 'save' : 'update');
        var emptyContent = this.strip(this.state.content)
        var emptyContentLength = emptyContent.trim().length;

        if(this.state.content === ''){
            formValid++;
            this.setState({comment_err: true});
            this.setState({loading: 0});
        }
        if(emptyContentLength === 0){
            alert(2)
            formValid++;
            this.setState({comment_err: true});
        }
        if(formValid === 0){

            this.setState({newDiscussionLoading: 1})
            let savecomments = {
                projectid: projectid,
                discussionid: discussionid,
                userid: getProfile().id,
                message: this.state.content,
            };
            //console.log(savecomments);
            this.props.saveprojectcomments(savecomments);
        }
    }





    render() {
        //console.log(this.state.content);
        let users = this.props.listtags? this.props.listtags : '';
        //console.log(users)
        //console.log(this.state.assignee_ids )
        return (
            <>
                <div className="task-content-block">
                    <div className="persitant-top pj-pad">
                        {this.state.newDiscussionLoading === 1 &&
                        <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                        }
                        <div className="new-pool-input text-input">
                            {this.state.newCommentloading === 1 &&
                            <span className="Loader-holder">
                                                <LoaderSvg/>
                                            </span>
                            }
                            <RichText
                                menubar = 'hide'
                                plugins = 'print preview fullpage searchreplace autolink directionality  visualblocks visualchars fullscreen image link media template table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textcolor wordcount   imagetools  contextmenu colorpicker textpattern paste code'
                                toolbar = 'bold italic | alignleft aligncenter alignright alignjustify  | bullist numlist | link | media |'
                                extended_valid_elements = "video[controls|preload|width|height|data-setup],source[src|type]"
                                width = '100%'
                                height = '100%'
                                file_picker_types= 'file image media'
                                handleEditorChange = {this.handleEditorChange}
                                handleKeyUp = {this.handleKeyUp}

                            />
                            <div className="comment-error"><label className = "assign-user-validation-err" style={{display: this.state.comment_err === false ? 'none' : 'block' }}>
                                Please provide a new Comment.</label></div>
                        </div>

                    </div>
                    <div className="persitant-footer">

                        <div className="group-row">

                        </div>
                        <button data-masterid="0" data-mode="save" type="button" className="general-btn" onClick={(e)=> this.PostComment(e)}
                                id="createproject">{this.state.project_id =='0' ? 'Post Comment' : 'Update'}
                        </button>
                    </div>
                </div>


            </>


        );
    }
}


const mapStateToProps = state => ({
    listUsers:state.UsersReducer.listUsersdata,
    commentsAdddata:state.ProjectReducer.SaveProjectComments
});
export default  withRouter(connect(mapStateToProps, {
    createProject : taskActions.saveToDoAttachments,
    saveprojectcomments:projectActions.SaveProjectComments,
    getprojectDiscussions : projectActions.getProjectDiscussions,

})(AddNewDiscussionDetail));



