import React, { Component } from 'react';
import {Col, Row, Toast} from "react-bootstrap";

export class Toaster extends Component {

    render() {
        let color=this.props.toasterdata.type
          if(this.props.toasterdata.show) {
            return (
                <div className={`toaster-notification`}>
                    <Row>
                        <Col>
                            <Toast style={{backgroundColor:color}} onClose={() => this.props.toasterClose()} show={this.props.toasterdata.show}
                                   delay={3000}
                                   autohide>
                                <Toast.Header>
                                    <strong className="mr-auto">{this.props.toasterdata.msg}</strong>
                                </Toast.Header>
                            </Toast>
                        </Col>
                    </Row>
                </div>
            )
        }
        else{
        return  null
        }

    }
}

export default Toaster