import React, {Component} from 'react';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css';
class TextEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = { text: '' } // You can also pass a Quill Delta here
        this.handleChange = this.handleChange.bind(this)
    }


    handleChange=(val)=>{
        this.props.handleChange(this.props.id,val)
    }

    render() {
        return (
            <ReactQuill
                        onChange={this.handleChange}
                        theme={`snow`}
                        value={this.props.value}
                        modules={TextEditor.modules}
                        formats={TextEditor.formats}
                        bounds={this.props.bounds}
            />
        )
    }
}


/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
TextEditor.modules = {
    toolbar: [


        ['bold', 'italic'],
        [{'list': 'ordered'}, {'list': 'bullet'},
           ],
        ['link'],

    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
TextEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

/*
 * PropType validation
 */
// TextEditor.propTypes = {
//     placeholder: PropTypes.string,
// }
export default TextEditor;
