export const elements = {
    BARCHART: 'BARCHART',
    AREACHART: 'AREACHART',
    LINECHART: 'LINECHART',
    JOINCHART: 'JOINTCHART',
    SCATTERCHART: 'SCATTERCHART',
    PIECHARTTWOLEVEL: 'PIECHARTTWOLEVEL',
    PIE_CHART_MAP_CONTAINER: 'PIE_CHART_MAP_CONTAINER',
    BUBBLE_CHART_MAP_CONTAINER: 'BUBBLE_CHART_MAP_CONTAINER',
    ANIMATED_CHART_MAP_CONTAINER: 'ANIMATED_CHART_MAP_CONTAINER',
    DATE_FILTER_CONTAINER: 'DATE_FILTER_CONTAINER',
    FILTER_CONTAINER: "FILTER_CONTAINER",
    TREEMAP: "TREE_MAP",
    CARDS: 'CARDS',
    AREA_BAR_CHART: 'AREA_BAR_CHART',
    STACKED_BAR_CHATRT: 'STACKED_BAR_CHATRT',
    MULTIAREA_CHART: "MULTIAREA_CHART",
    RADAR_CHART: "RADAR_CHART",
    FEATURED_BLOCK: "FEATURED_BLOCK",
    HIERARCHICAL_CONTAINER: "HIERARCHICAL_CONTAINER",
    TREND_BLOCK_CONTAINER: "TREND_BLOCK_CONTAINER",
    RADIAL_BAR_CHART_CONTAINER: "RADIAL_BAR_CHART_CONTAINER",
    BUBBLE_MAP_CONTAINER: "BUBBLE_MAP_CONTAINER",
    HEAT_MAP_CONTAINER: "HEAT_MAP_CONTAINER",
    COLOR_MAP_CONTAINER: "COLOR_MAP_CONTAINER",
    AREA_COLOR_MAP_CONTAINER: "AREA_COLOR_MAP_CONTAINER",
    API_COMPONENT_CONTAINER: "API_COMPONENT_CONTAINER",
    TABLE_COMPONENT: "TABLE_COMPONENT",
    CONTENTHEADER: "CONTENT_HEADER",
    TABLE_CONTAINER: "TABLE_CONTAINER",
    GRID_LAYOUT_3_3: 'GRID_LAYOUT_3_3',
    GRID_LAYOUT_1_2: 'GRID_LAYOUT_1_2',
    GRID_LAYOUT_1_1: 'GRID_LAYOUT_1_1',
    GRID_LAYOUT_1_3: 'GRID_LAYOUT_1_3',
    GRID_LAYOUT_1_4: 'GRID_LAYOUT_1_4',
    GRID_LAYOUT_3_6_3: 'GRID_LAYOUT_3_6_3',
    GRID_LAYOUT_3_3_6: 'GRID_LAYOUT_3_3_6',
    GRID_LAYOUT_6_3_3: 'GRID_LAYOUT_6_3_3',
    GRID_LAYOUT_4_8: 'GRID_LAYOUT_4_8',
    GRID_LAYOUT_9_3: 'GRID_LAYOUT_9_3',
    GRID_LAYOUT_3_9: 'GRID_LAYOUT_3_9',
    DESCRIPTION: "DESCRIPTION",
    IMAGE: "IMAGE",
    GRID_LAYOUT_CARD: "GRID_LAYOUT_CARD",
    RESIZABLE_BOX_CONTAINER: "RESIZABLE_BOX_CONTAINER",
    TITLE_CONTAINER: "TITLE_CONTAINER",
    CARD_BLOCK_CONTAINER: "CARD_BLOCK_CONTAINER",
    TAB_CONTAINER: "TAB_CONTAINER",
    SCROLL_POINTER: "SCROLL_POINTER",
    GROUP_TABLE: "GROUP_TABLE",
    BUTTON_BLOCK: "BUTTON_BLOCK",
    LINKED_CARD: "LINKED_CARD",
    LIST: "LIST",
    MAST_HEAD: "MAST_HEAD",
    FIELD_SET: "FIELD_SET",
    HORIZONTAL_CARD: "HORIZONTAL_CARD",
    ORDER_BODY: "ORDER_BODY",
    EMBED_FORM: "EMBED_FORM",
    CURRENT_DATE_TIME: 'CURRENT DATE TIME',
};
