import React, {Component} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {TINY_API_KEY} from "../../constants";

class RichText extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menubar:'show'
        }

    }


    componentDidMount() {
        this.setState({
            plugins: this.props.plugins?this.props.plugins: this.state.plugins ,
            toolbar : this.props.toolbar ? this.props.toolbar : this.state.toolbar,
            extended_valid_elements : this.props.extended_valid_elements ? this.props.extended_valid_elements : this.state.extended_valid_elements,
            width : this.props.width ? this.props.width : this.state.width,
            height : this.props.height ? this.props.height : this.state.height,
            file_picker_types : this.props.file_picker_types ? this.props.file_picker_types : this.state.file_picker_types
        });
    }
    // handleEditorChange = (e) => {
    //     this.setState({content : e.target.getContent()});
    //
    // }
    handleKeyUp = (e) => {
        let content =  e.target.innerHTML;
        this.setState({content : content});

    }

    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.state !== nextState || this.props !== nextProps) {
            return true;
        }
    }


    render() {
        //console.log(this.state.content)
        return (
            <Editor apiKey={TINY_API_KEY} cloudChannel='dev'
                    init={{
                        menubar:  this.props.menubar === 'show' ? true : false,
                        plugins: this.state.plugins,
                        toolbar: this.state.toolbar,
                        browser_spellcheck: true,
                        extended_valid_elements : this.state.extended_valid_elements,
                        width : this.state.width,
                        height: this.state.height,
                        file_picker_types: this.state.file_picker_types
                    }}
                    onChange={this.props.handleEditorChange}
                    onKeyUp = {this.props.handleKeyUp}
            />

        );


    }
}
export default RichText;
