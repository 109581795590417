import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import AutoComplete from "../../views/AutoComplete"
import LoaderSvg from "../../views/LoaderSVG";
import * as usersActions from "../../../actions/Users";
import rootActions from "../../../actions";
class AddNewGroup extends Component {

    constructor(props) {
        super(props);

        this.state={

            groupId : 0,
            groupname:'',
            description:'',
            Addopen:false,
            loading: 0,
            selectedUsers:[],
            groupnameError:false,
            descError:false,
            userError:false,
			channelenable:false
        };
        this.handleinputChange =  this.handleinputChange.bind(this);
        this.createGroup = this.createGroup.bind(this);
        this.usersList = this.usersList.bind(this);
        this.handleGroupChannel = this.handleGroupChannel.bind(this);
    }


    componentDidMount() {
        this.usersList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.groupDetails.groupdetails !== prevProps.groupDetails.groupdetails){
            let groupInfo = this.props.groupDetails.groupdetails[0] || [];
            let assignees = groupInfo.assignees||[];
            if(assignees){
                assignees=assignees.map(item => {
                    item.uniqueId = item.uniqueId ? item.uniqueId : 'user_'+item.assigneeId;
                    item.firstName = item.firstName ? item.firstName : item.assigneeName;
                    item.lastName = item.lastName ? item.lastName : '';
                    item.userId = item.id ? item.id : item.assigneeId;
                    item.userType = item.type ? item.type : 'User';
                    return item;
                })
            }
            this.setState({
                groupId:groupInfo.groupId,
                groupname:groupInfo.groupName,
                description:decodeURIComponent(groupInfo.description),
                selectedUsers:assignees})
        }
    }
    handleinputChange(e){
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            if(e.target.name === "groupname"){
                this.setState({groupnameError:false})
            }
            if(e.target.name === "description"){
                this.setState({descError:false})
            }
        }

    }
    createGroup(e){
        e.preventDefault();
        let formValid = 0;
        var groupname= this.state.groupname;
        var description= this.state.description;
        var selectedUsers = this.state.selectedUsers;
        var assignees = '';
        var channelenable = 'no';
        var mode = (this.state.groupId === 0 ? 'save' : 'update');

        if (this.state.groupname.trim() === '') {
            formValid++;
            this.setState({groupnameError:true})
        }
        if (this.state.description.trim() === '') {
            formValid++;
            this.setState({descError:true})
        }
        if (selectedUsers.length  === 0) {
            formValid++;
            this.setState({userError:true})
        }
        else{
            this.setState({userError:false})
        }
		if(this.state.channelenable){
		var channelenable = "yes";
		} 

        if(formValid === 0){
            this.setState({loading: 1});
            if (selectedUsers.length > 0) {

                for (var i = 0; i < selectedUsers.length; i++) {
                    assignees = (assignees !== '') ? (assignees + ",") : "";
                    assignees += selectedUsers[i].uniqueId;
                }

            }

            if(mode === 'save'){
                let params = {
                    groupname: groupname,
                    creator: getProfile().id,
                    description:description,
                    assignees:assignees,
					channelenable:channelenable

                };
             //console.log(params)
                this.props.savegroup(params);
            }else{
                let params = {
                    _id: this.state.groupId,
                    groupname: groupname,
                    creator: getProfile().id,
                    description:description,
                    assignees:assignees,
					channelenable:channelenable

                };
                //console.log(params)
                this.props.editGroup(params);

            }
            let trackparams={
                "module": "Team",
                "page": window.location.pathname,
                "action": mode === 'save'?'create':'edit',
                "browser": window.navigator.userAgent,
                "uid": getProfile().id,
            }
            this.props.activityTracking(trackparams);
        }
    }

    resultDataList( result, reference) {
        this.setState({ [reference] : result });

        if(reference === "selectedUsers"){
            if (result.length  === 0) {
                this.setState({userError:true})
            }else{
                this.setState({userError:false})
            }
        }
    }
    usersList(searchText) {
        var user_data = { uid : "0", search : searchText? searchText : '',  offset : 150 , ignoreUsers : true, ignoreArrays :[], valueField : 'uniqueId', userOnly : true};
        this.props.users(user_data);
    }
	handleGroupChannel(e){
	       this.setState({channelenable:!this.state.channelenable})
	}
    render() {

        return (
            <div className="task-content-block">
                {((this.props.loader.loaderType === 'usergroup-details' && this.props.loader.status === true)|| (this.state.loading === 1)) &&
                <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                }
                <div className="persitant-top">
                    <div className={`new-task-input ${this.state.groupnameError ? "val-error":""}`}>
                        <input type="text" id="groupname" name="groupname" value = {this.state.groupname} placeholder="Group Name" onChange={this.handleinputChange} autoComplete={'off'}/>
                    </div>
                    <div className={`new-task-input text-input ${this.state.descError ? "val-error":""}`}>
                                <textarea className="task-textarea" id="description" name="description"
                                          placeholder="Description" value = {this.state.description} onChange={this.handleinputChange} autoComplete={'off'}></textarea>
                    </div>
                    <div className="task-inp-container">
                        <AutoComplete
                            fieldTitle = 'Users'
                            placeholder = 'Type here'
                            sourceDataList = {this.props.listUsers.todoUsers}
                            searchField = 'firstName'
                            showIcon={true}
                            showSubLabel = {true}
                            labelFields = 'firstName,lastName'
                            valueField ='uniqueId'
                            subLabelField = 'designation'
                            showProfile = {true}
                            reference='selectedUsers'
                            selectedItems = {this.state.selectedUsers}
                            resultDataList = {this.resultDataList.bind(this)}
                            id="selectedUsers"
                            instantSearch={true}
                            actionMethod={this.usersList}
                            preventInitialApiCall = {true}
                        />
                        <label className = "assign-user-validation-err" style={{display: this.state.userError === false ? 'none' : 'block' }}>Please select atleast one User.</label>
                    </div>
                    {this.state.groupId === 0 && !this.props.loader.status &&
                        <div className="ind-panel-options checkbox-wrapper">
                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Add this group to channel
                                    <input type="checkbox" name="channelenable" className="inp-chk" id="channelenable"
                                           value={this.state.channelenable}
                                           defaultChecked={this.state.channelenable ? "yes" : ""} onClick={(e) => {
                                        this.handleGroupChannel(e)
                                    }}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                        </div>
                    }
          


                </div>
                <div className="persitant-footer">
                    <div className="group-row">

                    </div>
                    <button data-masterid="0" data-mode="save" type="button" className="general-btn"
                            onClick={(e) => this.createGroup(e)}
                            id="createpage">{this.state.groupId === 0 ? 'Create' : 'Update'}
                    </button>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    listUsers:state.UsersReducer.listUsersdata,
    groupDetails:state.UsersReducer.groupDetails,
    loader:state.CommonReducer.loader,
});
export default  withRouter(connect(mapStateToProps, {
    users:usersActions.listTodoUsers,
    savegroup:usersActions.saveGroup,
    editGroup:usersActions.editGroup,
    activityTracking:rootActions.commonActions.activityTracking
})(AddNewGroup));



