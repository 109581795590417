import React from 'react';
import axios from 'axios';
import {Draggable, state} from 'react-page-maker';
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import RatingCards from "./../views/content/RatingCards"
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {DashboardSettings, SettingsPanel} from "./ViewData";

class CardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalStatus: false,
            showColorPicker: false,
            background: '#fff',
            payload: {
                chartTitle: "Title",
                subTitle: "subtitle",
                subTitleEnabled: false,
                cardvalue: 0,
                collection: "",
                fields: "",
                aggregation: '',
                graph: false,
                x: "",
                y: "",
                z: "",
                xstroke: "",
                ystroke: "",
                labelx: "",
                labely: "",
                zstroke: "#fff",
                labelchartTitle: "",
            },
            linedata: []
        }


    }

    componentDidMount() {
        this.setState({payload: this.props.payload})
        this.graphData()
    }

    openSetting = () => {
        this.setState({modalStatus: !this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }
    handleChangeComplete = (color, param) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[param] = color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    };
    toggleColorPicker = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    }
    handleInputChange = (e) => {
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    graphData() {
        var date = new Date();
        var obj = this;
        const collection = this.props.payload && this.props.payload.collection
        const fields = this.props.payload && this.props.payload.x
        const x = this.props.payload && this.props.payload.x
        if ((getUrlSegment(1) === "dashboard") && (collection !== '' && collection !== undefined)) {
            this.props.showform({_id: collection});
        }
        if (collection !== '' && fields !== '' && x !== '') {
            axios({
                method: "GET",
                url: API_PATH + "collection/" + collection + "/collectedinfo?index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                var carddata = res.data.results.data;
                var ratingNo = carddata[0].collectedform.filter(a => a.masterid === x)[0].value[0].value;
                obj.setState({ratingNo: ratingNo});
                return res;

            }).catch(function (err) {
                return err;
            });
        } else {
            obj.setState({ratingNo: 0});

        }
    }

    checkBoxchange = (e) => {
        let val;
        if (e.target.value === "false") {
            val = true
        } else {
            val = false
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    handleChange = (e) => {
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    chartSaving = () => {
        var prjctdtlid = atob(getUrlSegment(4));
        let sharetype = getUrlSegment(1);
        let uid = '';
        if (sharetype === 'public') {
            uid = 2;
        } else {
            uid = getProfile().id;
        }
        let param = {
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard": state.getStorableState()
            ,
            "id": this.props.getdashboardChange.UpdateId
        }
        this.setState({modalStatus: false})
        this.props.chartSaving(param)
    }

    handleClick = (status) => {
        this.setState({colorpickerStatus: status})
    }

    render() {

        const {
            id,
            showBasicContent, showPreview,
            dropzoneID, payload, parentID, name
        } = this.props;


        let showlistField = []
        if (this.props.showFormModal.showformdata.length !== 0) {

            for (let i = 0; i < this.props.showFormModal.showformdata.pages.length; i++) {
                (this.props.showFormModal.showformdata.pages[i].components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden")).map(list => {
                    showlistField.push(list)

                })
            }
        }
        let containerData = {
            chartTitle: this.state.chartTitle || payload && payload.chartTitle,
            subTitle: this.state.subTitle || payload && payload.subTitle,
            subTitleEnabled: this.state.subTitleEnabled || payload && payload.subTitleEnabled,
            collection: this.state.collection || payload && payload.collection,
            x: this.state.x || payload && payload.x,
            ratingNo: this.state.ratingNo || payload && payload.ratingNo,
            showlistField: showlistField,
            collectionData: (this.props.listAllCollections.ListAllCollections.data)
        }


        if (showBasicContent) {
            return (
                <Draggable {...this.props} >
                    {
                        <span className="drag-icon">{name}</span>
                    }
                </Draggable>
            )
        }

        if (showPreview) {
            return (

                <div className="ibt-card">
                    <div className="ibt-rating">
                        <RatingCards title={containerData.chartTitle} subTitle={containerData.subTitle}
                                     subTitleEnabled={containerData.subTitleEnabled} ratingNo={containerData.ratingNo}
                                     totalRating={5}/>
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <Draggable {...this.props} draggable={!this.props.DragDisabled.dragStatus}>
                    <SettingsPanel
                        id={id}
                        name={containerData.chartTitle ? containerData.chartTitle : name}
                        dropzoneID={dropzoneID}
                        parentID={parentID}
                        openSetting={this.openSetting}
                        removeElement={<div className="delete-icon"
                                            onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
                    />

                </Draggable>
                {this.state.modalStatus &&


                <DashboardSettings
                    title={`Card -   Settings`}
                    showlistField={showlistField}
                    colorpickerStatus={this.state.colorpickerStatus}
                    containerData={containerData}
                    modaltype={4}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    handleChange={this.handleChange}
                    checkBoxchange={this.checkBoxchange}
                    handleConditionChange={this.handleConditionChange}
                    handleLabelChange={this.handleLabelChange}
                    handleColorChange={this.handleColorChange}
                    handleInputChange={this.handleInputChange}
                    handleClick={this.handleClick}
                />
                }
            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    showFormModal: state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange: state.DynamicDashboard.getDashboard,
    DragDisabled: state.DynamicDashboard.getDragDisabled,
    EntityData: state.EntityReducer.GetEntity,

});
export default withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform: collectionActions.showform,
    chartSaving: DynamicDashboard.saveDashboarddata,
    dragStatus: DynamicDashboard.dragStatus,
})(CardContainer));
