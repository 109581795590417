import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";

class ProjectInsights extends Component {

    constructor(props) {
        super(props);

        this.state={
        }
        this.expandHeader = this.expandHeader.bind(this)
    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }

    componentDidMount() {
        this.setState({loading: 1});
        var projectId = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/insights/'+projectId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {

        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>
            </div>



        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
})(ProjectInsights));
