import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {
    Canvas,
    Palette,
    state,
    Trash,
    core,
    Preview,
    registerPaletteElements
} from 'react-page-maker';
import {elements} from './const';
import DraggableLayoutR3C3 from './elements/layout/DraggableLayoutR3C3';
import DraggableLayoutR1C2 from './elements/layout/DraggableLayoutR1C2';
import DraggableLayoutR1C1 from './elements/layout/DraggableLayoutR1C1';
import DraggableLayoutR1C3 from './elements/layout/DraggableLayoutR1C3';
import DraggableLayoutR1C4 from './elements/layout/DraggableLayoutR1C4';
import DraggableLayout3x3x6 from './elements/layout/DraggableLayout3x3x6';
import DraggableLayout3x6x3 from './elements/layout/DraggableLayout3x6x3';
import DraggableLayout6x3x3 from './elements/layout/DraggableLayout6x3x3';

import DraggableLayoutR3C9 from './elements/layout/DraggableLayoutR3C9.js';
import DraggableLayoutR9C3 from './elements/layout/DraggableLayoutR9C3.js';

import BarchartContainer from './elements/container/BarchartContainer';
import AreachartContainer from './elements/container/AreachartContainer';
import LinechartContainer from './elements/container/LinechartContainer';
import RadarchartContainer from './elements/container/RadarchartContainer';
import CardContainer from "./elements/container/CardContainer"
import LabelContainer from "./elements/container/LabelContainer"
// import TableContainer from "./elements/container/TableContainer"
import CeTable from "./elements/container/CeTable"
import HierarchicalContainer from "./elements/container/HierarchicalContainer"
import MultiareaChart from "./elements/container/MultiareaChart"
import AreaBarChartContainer from "./elements/container/AreaBarChartContainer"
import StackedBarChartContainer from "./elements/container/StackedBarChartContainer"
import FeaturedBlockContainer from "./elements/container/FeaturedBlockContainer"
// import TreeMapContainer from "./elements/container/TreeMapContainer"
import RadialBarChartContainer from "./elements/container/RadialBarChartContainer";
import AnimatedChartContainer from "./elements/container/AnimatedChartContainer";
import BubbleChartContainer from "./elements/container/BubbleChartContainer";
// import BubbleMapContainer from "./elements/container/BubbleMapContainer";
// import AreaColorMapContainer from "./elements/container/AreaColorMapContainer";
//import HeatMapContainer from "./elements/container/HeatMapContainer";
import PieChartContainer from "./elements/container/PieChartContainer";
import ApiComponentContainer from "./elements/container/ApiComponentContainer";
import DateFilterContainer from "./elements/container/DateFilterContainer";
import FilterContainer from "./elements/container/FilterContainer"
import {Button, Col, Tab, Tabs, Container, Accordion, Card, Row, Toast} from "react-bootstrap";
import './App.css';
import {getProfile} from "../../utilities/AuthService";
import * as collectionActions from "../../actions/Collections";
import * as DynamicDashboard from "../../actions/DynamicDashboard";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import axios from "axios";
import {API_PATH, ES_INDEX, SHARED_LOGIN} from "../../constants";
import rootActions from "../../actions";
import LoaderSvg from "../views/LoaderSVG";
import {Scrollbars} from "react-custom-scrollbars";
import TrendBoxContainer from "./elements/container/TrendBoxContainer";
import TableblockContainer from "./elements/container/entity/TableblockContainer";
import CurrentDateTime from "./elements/container/CurrentDateTime";

import * as entityActions from "../../actions/Entity";

let initialElements = []

class DashboardBuilder extends Component {
    // define all palette elements that you want to show
    LayoutElements = [
        {
            type: elements.GRID_LAYOUT_1_1,
            name: '1 by 1 Grid Layout',
            id: '1-1-grid'
        },
        {
            type: elements.GRID_LAYOUT_1_2,
            name: '1 by 2 Grid Layout',
            id: '1-2-grid'
        },
        {
            type: elements.GRID_LAYOUT_1_3,
            name: '1 by 3 Grid Layout',
            id: '1-3-grid'
        },
        {
            type: elements.GRID_LAYOUT_1_4,
            name: '1 by 4 Grid Layout',
            id: '1-4-grid'
        },
        {
            type: elements.GRID_LAYOUT_3_6_3,
            name: '1:2:1 Grid Layout',
            id: '1_2_1-grid'
        },
        {
            type: elements.GRID_LAYOUT_3_3_6,
            name: '1:1:2 Grid Layout',
            id: '1_1_2-grid'
        },
        {
            type: elements.GRID_LAYOUT_6_3_3,
            name: '2:1:1 Grid Layout',
            id: '2_1_1-grid'
        },
        {
            type: elements.GRID_LAYOUT_3_9,
            name: '3:1 Grid Layout',
            id: '3-9-grid'
        },
        {
            type: elements.GRID_LAYOUT_9_3,
            name: '1:3 Grid Layout',
            id: '9-3-grid'
        }

    ]
    ChartElements = [
        {
            type: elements.RADIAL_BAR_CHART_CONTAINER,
            name: 'Radial Bar Chart',
            id: 'radial-bar-chart',
        },
        {
            type: elements.RADAR_CHART,
            name: 'Radar Chart',
            id: 'radar-bar-chart',
        },
        {
            type: elements.BARCHART,
            name: 'Barchart',
            id: 'barchart-1'
        },
        {
            type: elements.AREACHART,
            name: 'Areachart',
            id: 'areachart-1'
        },
        {
            type: elements.LINECHART,
            name: 'Summary Card',
            id: 'linechart-1'
        },
        {
            type: elements.MULTIAREA_CHART,
            name: 'MultiArea Chart',
            id: 'multiareachart-1'
        },
        {
            type: elements.AREA_BAR_CHART,
            name: 'Area Bar Chart',
            id: 'areabar-1'
        },
        {
            type: elements.STACKED_BAR_CHATRT,
            name: 'Stacked Bar Chart',
            id: 'stacked-1',
        },
        {
            type: elements.PIE_CHART_MAP_CONTAINER,
            name: 'Pie Chart',
            id: 'pie-chart-container',
        },
        {
            type: elements.BUBBLE_CHART_MAP_CONTAINER,
            name: 'Bubble Chart',
            id: 'bubble-chart-container',
        },
        {
            type: elements.ANIMATED_CHART_MAP_CONTAINER,
            name: 'Animated Chart',
            id: 'animated-chart-container',
        },
        {
            type: elements.TREEMAP,
            name: 'TREE MAP',
            id: 'tree-map',
        }


    ]
    ContentElemets = [
        {
            type: elements.CONTENTHEADER,
            name: 'Header Block',
            id: 'content-header'
        },
        {
            type: elements.CARDS,
            name: 'Cards',
            id: 'content-cards'
        },
        {
            type: elements.HIERARCHICAL_CONTAINER,
            name: 'Hierarchical Table',
            id: 'hierarchicalContainer'
        },
        {
            type: elements.TABLE_CONTAINER,
            name: 'Table',
            id: 'table-container'
        },
        {
            type: elements.FEATURED_BLOCK,
            name: 'Featured Block',
            id: 'featuredblock-1'

        },
        {
            type: elements.API_COMPONENT_CONTAINER,
            name: 'Api Component',
            id: 'api-component'

        },
        {
            type: elements.TREND_BLOCK_CONTAINER,
            name: 'Trend Box',
            id: 'trend-component'

        },
        {
            type: elements.CURRENT_DATE_TIME,
            name: 'Current Date Time',
            id: 'current-date-time'

        }


    ]
    FiltersElements = [

        {
            type: elements.DATE_FILTER_CONTAINER,
            name: 'Date Filter',
            id: 'date-filter',
        },
        {
            type: elements.FILTER_CONTAINER,
            name: 'Select Filter',
            id: 'select-filter',
        }

    ]
    MapElemets = [
        // {
        //     type: elements.BUBBLE_MAP_CONTAINER,
        //     name: 'Bubble Map',
        //     id: 'bubble-map'
        // },
        // {
        //     type: elements.AREA_COLOR_MAP_CONTAINER,
        //     name: 'Area Map',
        //     id: 'area-map'
        // },
        {
            type: elements.HEAT_MAP_CONTAINER,
            name: 'HEAT MAP',
            id: 'heat-map',
        }


    ]

    constructor(props) {
        super(props);
        registerPaletteElements([
            {
                type: elements.BARCHART,
                component: BarchartContainer,
            },
            {
                type: elements.TREND_BLOCK_CONTAINER,
                component: TrendBoxContainer,
            },
            {
                type: elements.LINECHART,
                component: LinechartContainer
            },
            {
                type: elements.AREACHART,
                component: AreachartContainer
            },
            // {
            //     type: elements.TREEMAP,
            //     component: TreeMapContainer
            // },
            {
                type: elements.HIERARCHICAL_CONTAINER,
                component: HierarchicalContainer
            },
            {
                type: elements.API_COMPONENT_CONTAINER,
                component: ApiComponentContainer
            },

            {
                type: elements.RADIAL_BAR_CHART_CONTAINER,
                component: RadialBarChartContainer
            },
            {
                type: elements.MULTIAREA_CHART,
                component: MultiareaChart
            },

            {
                type: elements.AREA_BAR_CHART,
                component: AreaBarChartContainer
            },
            {
                type: elements.STACKED_BAR_CHATRT,
                component: StackedBarChartContainer
            },
            {
                type: elements.DATE_FILTER_CONTAINER,
                component: DateFilterContainer
            },
            {
                type: elements.FILTER_CONTAINER,
                component: FilterContainer
            },
            {
                type: elements.PIE_CHART_MAP_CONTAINER,
                component: PieChartContainer
            },
            {
                type: elements.ANIMATED_CHART_MAP_CONTAINER,
                component: AnimatedChartContainer
            },

            {
                type: elements.BUBBLE_CHART_MAP_CONTAINER,
                component: BubbleChartContainer
            },

            // {
            //     type: elements.HEAT_MAP_CONTAINER,
            //     component: HeatMapContainer
            // },
            // {
            //     type: elements.AREA_COLOR_MAP_CONTAINER,
            //     component: AreaColorMapContainer
            // },
            {
                type: elements.RADAR_CHART,
                component: RadarchartContainer
            },
            {
                type: elements.FEATURED_BLOCK,
                component: FeaturedBlockContainer
            },
            {
                type: elements.CARDS,
                component: CardContainer
            },
            {
                type: elements.CONTENTHEADER,
                component: LabelContainer
            },
            {
                type: elements.GRID_LAYOUT_3_3,
                component: DraggableLayoutR3C3
            }, {
                type: elements.GRID_LAYOUT_1_2,
                component: DraggableLayoutR1C2
            },
            {
                type: elements.GRID_LAYOUT_1_1,
                component: DraggableLayoutR1C1
            },
            {
                type: elements.GRID_LAYOUT_1_3,
                component: DraggableLayoutR1C3
            },
            {
                type: elements.GRID_LAYOUT_1_4,
                component: DraggableLayoutR1C4
            },
            {
                type: elements.GRID_LAYOUT_3_6_3,
                component: DraggableLayout3x6x3
            },
            {
                type: elements.GRID_LAYOUT_3_3_6,
                component: DraggableLayout3x3x6
            },

            {
                type: elements.GRID_LAYOUT_6_3_3,
                component: DraggableLayout6x3x3
            },
            {
                type: elements.GRID_LAYOUT_3_9,
                component: DraggableLayoutR3C9
            },

            {
                type: elements.GRID_LAYOUT_9_3,
                component: DraggableLayoutR9C3
            },

            {
                type: elements.TABLE_CONTAINER,
                component: CeTable
            },
            {
                type: elements.TABLE_COMPONENT,
                component: TableblockContainer
            },
            {
                type: elements.CURRENT_DATE_TIME,
                component: CurrentDateTime
            },


        ]);
        // state.clearState() triggers this event
        state.addEventListener('flush', (e) => {
            ////////console.log('flush', e);
        });
        // state.removeElement() triggers this event
        state.addEventListener('removeElement', (e) => {
            ////////console.log('removeElement', e);
        });
        // state.updateElement() triggers this event
        state.addEventListener('updateElement', (e) => {
            ////////console.log('updateElement', e);
        });
        this.state = {
            loading: false,
            activeTab: '1',
            currentState: [],
            field: 1,
            uniqueId: 1,
            toasterStatus: false,
            dashboardLoader: false
        }
        this.fetchData = this.fetchData.bind(this)
    }

    componentDidMount() {
        this.props.mainClick('', '');
        let params = {
            uid: getProfile().id,
            usertype: getProfile().usertype,
            managecollection: 0,
            limit: 0,
            offset: 500
        };
        this.props.collections(params);
        let Entity = {
            uid: getProfile().id,
            usertype: getProfile().usertype,
            searchtext: this.state.search,
            page: 1,
            per_page: 250,
            index: ES_INDEX,
            managecollection: ''
        };
        this.props.searchEntity(Entity);
        this.fetchData()
        let pjtParam = {projectid: atob(getUrlSegment(4))}
        this.props.getprojectDashBoard(pjtParam)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let msg;
        if (this.props.getdashboardChange.data !== prevProps.getdashboardChange.data) {
            if (this.props.getdashboardChange.data.length !== 0) {
                if (this.props.getdashboardChange.data.success) {
                    msg = "Dashboard updated successfully."
                } else {
                    msg = "Please try again."
                }
            }
            this.setState({toasterStatus: true, msg: msg})
        }
    }

    componentWillMount() {
        state.addEventListener('change', this._stateChange);
    }

    componentWillUnmount() {
        state.removeEventListener('change', this._stateChange);
    }

    toasterClose = () => {
        this.setState({toasterStatus: false, dashboardLoader: false})
    }

    dashboardChange = (e) => {
        this.props.dashboardChange(e.target.value)
        var projectId = (getUrlSegment(4));
        this.props.history.push("/dashboard/builder/" + e.target.value + "/" + projectId)
        window.location.reload()
        this.fetchData()
    }

    _stateChange = (s) => {
        const newState = state.getStorableState();
        this.setState({currentState: newState, initialElements: newState}, () => {
        });
    }

    // re-hydrate canvas state
    fetchData() {
        var projectId = atob(getUrlSegment(4));
        var type = getUrlSegment(3);
        this.props.dashboardChange(type)
        var that = this
        axios({
            method: "GET",
            url: API_PATH + "project/dashboard?projectid=" + projectId + "&index=" + ES_INDEX + "&id=&type=" + type,
            async: false,
            headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
        }).then(function (res) {

            var t = JSON.parse(res.data.results.data[0].dashboard)
            var initialData = t;
            var id = res.data.results.data[0]._id;
            that.props.dashboardIdChange(id)
            initialElements = initialData
            let layoutFields = [];
            let globalFilterComponents = [];
            if (initialData.length > 0) {
                let fieldsArr = initialData.map(item => {
                    return item.fields
                });
                layoutFields = [].concat.apply([], fieldsArr);
                globalFilterComponents = layoutFields.filter(fld => fld.type === 'FILTER_CONTAINER' || fld.type === 'DATE_FILTER_CONTAINER')
                //initialElements[0].globalFilterComponents = globalFilterComponents;
                that.props.setGlobalFilters(globalFilterComponents);

            }

            that.setState({
                initialElements: initialElements,
                layoutFields: layoutFields,
                globalFilterComponents: globalFilterComponents,
                loading: true,
                dashboardLoader: true,
                msg: "Dashboard Data loaded successfully."
            })
            return res;
        }).catch(function (err) {
            initialElements = []
            that.props.dashboardIdChange("")
            that._clearState()
            that.setState({loading: true, dashboardLoader: true, msg: "Builder setup completed."})
            return err;
        });
    }

    _onDrop = (data, cb) => {
        // no need to ask id and name again
        if (data.payload && data.payload.dropped) {
            return cb(data);
        }
        let name = data.name;
        let newid = Math.round(new Date().getTime() + (Math.random() * 100));
        const id = "id_" + (newid)
        const result = cb({
            ...data,
            name,
            id,
            payload: {dropped: true}
        });
    }
    _clearState = () => {
        state.clearState();
    }
    chartSaving = () => {
        var prjctdtlid = atob(getUrlSegment(4));
        let param = {
            "projectid": prjctdtlid,
            "uid": getProfile().id,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard": state.getStorableState(),
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus: false})
    }
    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    render() {

        let show = this.state.toasterStatus || this.state.dashboardLoader
        let loaderstatus = this.props.getdashboardChange.loader
        let projectData = this.props.projectDashBoard.projectdetails
        if (this.state.loading) {
            return (
                <>
                    <div className="form-detail-panel common-align no-bottom project-header-align"
                         style={{"height": "75px"}}>
                        <div className="form-detail-top project-top right-extra-option">
                            {projectData.length === 0 &&
                            <React.Fragment>
                                <div className="form-detail-top-left-editable">
                                    <div className="back-to-list-button-block">
                                        <button className="ico-lib-v1 back-to-list-button"
                                                onClick={() => window.location.replace(window.location.origin + "/dashboard/report/" + getUrlSegment(3) + "/MDA3MDA3")}></button>
                                        <h5>{this.capitalize(getUrlSegment(3).replace("-", ' '))}</h5>
                                    </div>
                                </div>
                                <div className="form-detail-top-right"></div>
                            </React.Fragment>
                            }
                            {projectData.length !== 0 &&
                            <React.Fragment>
                                <nav onClick={() => this.props.history.push("../../../project")}
                                     className="form-detail-top-left common-bk-button">
                                    <h5>{projectData.length !== 0 ? projectData.projectCode + " - " + projectData.projectName : ""}</h5>
                                    <p></p>
                                </nav>
                                <div className="form-detail-top-right"></div>
                            </React.Fragment>
                            }


                        </div>
                    </div>
                    <div className="task-content-block DashBuilder with-db-header">


                        {loaderstatus &&
                        <span className="Loader-holder" style={{'background': 'transparent'}}>
                    <LoaderSvg/>
                    </span>
                        }
                        {show &&
                        <div className={`toaster-notification`}>
                            <Row>
                                <Col>
                                    <Toast onClose={() => this.toasterClose()} show={show} delay={3000}
                                           autohide>
                                        <Toast.Header>
                                            <strong className="mr-auto">{this.state.msg}</strong>
                                        </Toast.Header>
                                    </Toast>
                                </Col>
                            </Row>
                        </div>
                        }
                        <Col>
                            <Tabs defaultActiveKey="builder" transition={false} id="noanim-tab">
                                <Tab eventKey="builder" title="Builder">
                                    <div className="page-builder mt-3">
                                        <Col sm="9" className="canvas-container">
                                            <Scrollbars
                                                style={{width: '100%', height: '100%'}}
                                            >
                                                <Canvas onDrop={this._onDrop} initialElements={initialElements}
                                                        placeholder="Drag & Drop Here"/>
                                            </Scrollbars>
                                        </Col>

                                        <Col sm="3" className="canvas-properties">
                                            <Scrollbars
                                                style={{width: '100%', height: 'calc(100% - 60px)'}}
                                            >
                                                <div className="canvas-body">
                                                    <Accordion defaultActiveKey="0">
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle as={Button} variant="link"
                                                                                  eventKey="0">
                                                                    Layout
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body>
                                                                    <Palette paletteElements={this.LayoutElements}/>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle as={Button} variant="link"
                                                                                  eventKey="1">
                                                                    Chart
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="1">
                                                                <Card.Body>
                                                                    <Palette paletteElements={this.ChartElements}/>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle as={Button} variant="link"
                                                                                  eventKey="2">
                                                                    Content
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="2">
                                                                <Card.Body>
                                                                    <Palette paletteElements={this.ContentElemets}/>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle as={Button} variant="link"
                                                                                  eventKey="3">
                                                                    Map
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="3">
                                                                <Card.Body>
                                                                    <Palette paletteElements={this.MapElemets}/>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle as={Button} variant="link"
                                                                                  eventKey="4">
                                                                    Filter
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="4">
                                                                <Card.Body>
                                                                    <Palette paletteElements={this.FiltersElements}/>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>

                                                    </Accordion>
                                                </div>
                                            </Scrollbars>
                                            <div className="canvas-footer">
                                                <button className="ResetBtn IconIn ResetIcon" color="danger"
                                                        onClick={this._clearState}>Reset Canvas
                                                </button>
                                                <button className="SaveBtn IconIn SaveIcon"
                                                        onClick={this.chartSaving}>Save
                                                </button>
                                            </div>
                                        </Col>

                                    </div>
                                </Tab>
                                <Tab eventKey="Preview" title="Preview">
                                    <Scrollbars
                                        style={{width: '100%', height: '100%'}}
                                    >
                                        <Row className="mt-3">
                                            <Preview>
                                                {
                                                    ({children}) => (
                                                        <Container>
                                                            {children}
                                                        </Container>
                                                    )
                                                }
                                            </Preview>
                                        </Row>
                                    </Scrollbars>
                                </Tab>
                            </Tabs>

                            <div className="dashboard-selection">

                                {/*<div className="select-box eq-width">*/}
                                {/*    <select id="priority" name="collection"  value={getUrlSegment(3).toLowerCase()} onChange={(e) => this.dashboardChange(e)}>*/}

                                {/*        <option value="dashboard">Main Dashboard</option>*/}
                                {/*        <option value="impact">Impact</option>*/}
                                {/*        <option value="financial">Financial</option>*/}
                                {/*        <option value="community">Community</option>*/}
                                {/*        <option value="impactv1">Impact V1</option>*/}
                                {/*        <option value="financialv1">Financial V1</option>*/}
                                {/*    </select>*/}
                                {/*</div>*/}
                            </div>
                        </Col>
                        {/* <div className="float-button-box">   <button className="float-button-new ico-lib-v2 float-close" onClick={this.chartSaving}></button></div> */}
                    </div>
                </>
            );
        } else {
            return (
                <span className="Loader-holder" style={{'background': 'transparent'}}>
                    <LoaderSvg/>
                </span>
            )
        }
    }
}

const mapStateToProps = state => ({
    searchData: state.CommonReducer.searchparamdata,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange: state.DynamicDashboard.getDashboard,
    projectDashBoard: state.DynamicDashboard.projectDashBoard,
    globalFilters: state.DynamicDashboard.getGlobalFilters

});
export default withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    dashboardChange: DynamicDashboard.dashboardChange,
    dashboardIdChange: DynamicDashboard.changeId,
    mainClick: rootActions.commonActions.MenuClick,
    chartSaving: DynamicDashboard.saveDashboarddata,
    getprojectDashBoard: DynamicDashboard.projectDashBoard,
    searchEntity: entityActions.Entity,
    setGlobalFilters: DynamicDashboard.setGlobalFilters

})(DashboardBuilder));
