import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {Navbar, OverlayTrigger, Tooltip} from "react-bootstrap";
import 'font-awesome/css/font-awesome.min.css';
import { Scrollbars } from 'react-custom-scrollbars';
import {ES_INDEX,SHARED_LOGIN} from "../../../constants/index";
import {Task, Forms, Chat, Collections, Project, Settings,Entity,Team,Group} from "./SubMenu"
import * as formActions from "../../../actions/Forms";
import * as collectionActions from "../../../actions/Collections";
import {getProfile} from "../../../utilities/AuthService";
import * as usersActions from "../../../actions/Users";
import * as taskActions from "../../../actions/ToDo";
import rootActions from "../../../actions";
import * as projectActions from "../../../actions/Project";
import * as portfolioActions from "../../../actions/Portfolio";
import * as verticalActions from "../../../actions/Program";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import packageJson from '../../../../package.json';
global.reactVersion = packageJson.react;
class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleStatus: true,
            limit : 0,
            offset: 100,
            lastOffset: 0,
            search:'',
            sectors : [],
            verticals : []
        }
        this.menuToggle = this.menuToggle.bind(this)
        this.mainClick = this.mainClick.bind(this)
        this.projectSubClick = this.projectSubClick.bind(this)
        this.loadSidebarUsers = this.loadSidebarUsers.bind(this);
        this.teamClick=this.teamClick.bind(this);
    }

    componentDidMount() {
        var obj = this;
        setTimeout(function () {
            if(getUrlSegment(1)==="services"){
                obj.props.getforms(getProfile().id,getProfile().usertype, 'entity')
            }else{
                obj.props.getforms(getProfile().id, getProfile().usertype,getUrlSegment(1)==="collections"?"collection":getUrlSegment(1))
            }
            obj.props.chatUsers(getProfile().id)
            obj.props.userspermissions({uid: getProfile().id})
        }, 1500)

        // var menu = {userid : getProfile().id};
        // this.props.getPoolMenu(menu);
        // var data = {category : 'to-me', status : 'All' , uid : getProfile().id, bydue : '',  priority : 'All',
        //     project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : '', managetask : 'mytask',
        //     limit : this.state.limit, offset  : 50, systype : 'root',  actionType : 'LIST_TODO_DATA' }
        // this.props.gettasklist(data);
        this.setState({topbardata : this.props.todotopbardata});
       //console.log(getUrlSegment(1))

        var programParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: this.state.search,
            page : this.state.lastOffset + 1,
            per_page: this.state.offset
        }
        this.props.program(programParams);
        var portfolioParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: this.state.search,
            page : this.state.lastOffset + 1,
            per_page: this.state.offset
        }
        this.props.portfolio(portfolioParams);





    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.todoListing  !== this.props.todoListing){
            if(this.props.menuClickData.subMenu === '/task/todo'){

                this.setState({topbardata : this.props.todotopbardata});

            }else if(this.props.menuClickData.subMenu === '/task/to-follow-up'){

                this.setState({topbardata : this.props.tofollowuptopbardata});

            }else if(this.props.menuClickData.subMenu === '/task/to-comment'){

                this.setState({topbardata : this.props.tocommenttopbardata});

            }else if(this.props.menuClickData.subMenu === '/task/to-know'){

                this.setState({topbardata : this.props.toknowtopbardata});

            }else if(this.props.menuClickData.subMenu === '/task/to-oversee'){

                this.setState({topbardata : this.props.tooverseetopbardata});

            }else if(this.props.menuClickData.subMenu === '/task/due-today'){

                this.setState({topbardata : this.props.duetodaytopbardata});

            }else if(this.props.menuClickData.subMenu === '/task/due-tomorrow'){

                this.setState({topbardata : this.props.duetomorrowtopbardata});

            }else if(this.props.menuClickData.subMenu === '/task/due-this-month'){

                this.setState({topbardata : this.props.duethismonthtopbardata});

            }else if(this.props.menuClickData.subMenu === '/task/all-overdues'){

                this.setState({topbardata : this.props.alloverduetopbardata});
            }else{
                this.setState({topbardata : this.props.allarchivetopbardata});
            }
        }
    }

    CollectionsParam =(param)=>{
        let type="SEARCH_COLLECTION_DATA"
        if(getUrlSegment(1)==="entity"){
            type="SEARCH_ENTITY_DATA"

        }
        if(getUrlSegment(1)==="services"){
            this.props.searchType('SEARCH_ENTITY_DATA', param.search, 'entity',param.group,param.managecollection )

        }else{
            this.props.searchType(type, param.search, getUrlSegment(1),param.group,param.managecollection )
        }


    }

    menuToggle() {
        this.setState({toggleStatus: !this.state.toggleStatus})
    }

    mainClick(main,sub,migrated) {
        if(!SHARED_LOGIN.status) {
            this.props.mainclick(main.toString(), sub.toString())
            this.props.history.push(sub.toString())
            if(sub === '/overdue/customdaterange'){
                this.props.overDuePopup(true)
            }
        }
        else{
            if(!migrated && migrated !== undefined){
                window.location.href = SHARED_LOGIN.redirect_url+main.toString();
            }
            else{
                this.props.mainclick(main.toString(), sub.toString())
                this.props.history.push(sub.toString())
                if(sub === '/overdue/customdaterange'){
                    this.props.overDuePopup(true)
                }
            }
        }
    }

    loadSidebarUsers() {
        this.props.chatUsers(getProfile().id, ES_INDEX)
    }
    teamClick(e,submenu){
        this.props.teamClick(submenu.toString())
    }
    projectSubClick(type, id) {
        let sectors = [...this.state.sectors];
        let verticals = [...this.state.verticals];
        if(type === 'All') {
            sectors = [];
            verticals = [];
        }
        else {
            if(type === 'sector') {
                if(sectors.includes(id))
                {
                    const index = sectors.indexOf(id);
                    if (index > -1) {
                        sectors.splice(index, 1);
                    }
                }
                else
                {
                    sectors.push(id);
                }
                //this.setState({sectorsdata: !this.state.sectors})
            }
            if(type === 'vertical') {
                if(verticals.includes(id))
                {
                    const index = verticals.indexOf(id);
                    if (index > -1) {
                        verticals.splice(index, 1);
                    }
                }
                else
                {
                    verticals.push(id);
                }
                //this.setState({verticalsdata: !this.state.verticals})

            }
        }

        this.setState({sectors : sectors, verticals : verticals})
        var projectParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: '',
            page : 1,
            per_page: 30,
            type : '',
            clearList : true,
            filters : {sector : sectors.toString(), vertical : verticals.toString()}
        }
        this.props.project(projectParams);
    }

    render() {
        let urlParams = String(document.location).split('/');
        let subPage = urlParams.indexOf('details') > -1 ? true : false;
        let projectmenuData = this.props.listUsersPermission.UsersPermission.filter((item=> item.module === "Project"));
        let allcompanies = this.props.listallCompany.userAllcompanies;
        let allDesignation = this.props.listallDesignation.userAlldesignations;
        let allReportees = this.props.listallReportees.userAllReportees;
        let userdetail = this.props.listUserInfo.UserInfo;
        return (

            <div className={`side-menu-wrapper ${this.props.mobToggle.toggle ? 'active' : ''}`}>
                <div className={`side-menu-left min-side ${this.state.toggleStatus ? 'skew' : ''}`}>
                    <Navbar className="side-nav-in navbar navbar-expand navbar-light home-menu">
                            <span className="side-left-click navbar-brand">
                                <span className="icon-in icon-menu" onClick={this.menuToggle}></span>
                                <span className="brand-indicator">Menu</span>
                            </span>
                    </Navbar>

                    <Scrollbars
                        style={this.state.toggleStatus === true ? {
                            width: 50,
                            height: 'calc(100% - 110px)'
                        } : {
                            width: 155,
                            height: (!this.props.mobToggle.toggle ? 'calc(100% - 110px)' : 'calc(100% - 170px)')
                        }}
                        autoHide
                    >
                        <div className={`auto-scroll`}>
                            {this.props.listUsersPermission.UsersPermission.map((list, index) => {
                                    if (list.module_permission === 'yes') {
                                        return (
                                            <Navbar className={`side-nav-in  ${this.props.menuClickData.mainMenu === [list.react_action].toString() ? "active-in" : ""}`} key={`side-bar-in`+index}>
                                                {SHARED_LOGIN.status ?
                                                    <Navbar.Brand
                                                        onClick={(e) => this.mainClick([list.action], [list.react_subactions],list.migrated)}
                                                        className={`side-left-click`}>
                                                        <span
                                                            className={`icon-in icon-${list.module.toLowerCase()}`}></span>
                                                        <span className={'brand-indicator'}>{list.display_name}</span>
                                                    </Navbar.Brand>
                                                    :
                                                    <Navbar.Brand onClick={(e) => this.mainClick([list.react_action], [list.react_subactions],list.migrated)} className={`side-left-click`}>
                                                        <span className={`icon-in icon-${list.module.toLowerCase()}`}></span>
                                                        <span className={'brand-indicator'}>{list.display_name}</span>
                                                    </Navbar.Brand>
                                                }
                                            </Navbar>
                                        )
                                    }
                                }
                            )}
                        </div>

                    </Scrollbars>
                    <div className="min-tab" style={{display: 'flex'}}>
                        <div className="version-text-container">
                            {/* <img src="../../../../assets/images/min-logo.png"/> */}
                            <span className="version-brand"></span>
                            <span className="version-text">{global.reactVersion}</span>
                        </div>
                    </div>

                </div>
                {this.props.menuClickData.mainMenu === 'task' &&  subPage === false &&
                <Task
                    menuClickData={this.props.menuClickData.subMenu}
                    mainClick={this.mainClick}
                    sidebarmenudata={this.props.sidebarmenudata}
                    menuToggle={this.menuToggle}
                    toggleStatus={this.state.toggleStatus}
                    poolMenu = {this.props.getPoolMenuItems}
                    topbardata={this.state.topbardata}
                    //  gettaskList={this.gettaskList}
                    sidebarsearchmenudata={this.props.sidebarsearchmenudata}
                    searchData={this.props.searchData}


                />
                }
                {this.props.menuClickData.mainMenu === 'forms' && subPage === false &&
                <Forms
                    toggleStatus={this.state.toggleStatus}
                    menuToggle={this.menuToggle}
                    getforms={this.props.getformsData}
                    formsFilter={this.props.forms}
                    listAllFormsData={this.props.listAllFormsData}

                />
                }
                {this.props.menuClickData.mainMenu === 'collections' && subPage === false &&
                <Collections
                    toggleStatus={this.state.toggleStatus}
                    menuToggle={this.menuToggle}
                    categories={this.props.getcollectionsData.getForms.data}
                    collectionsFilter={this.props.collections}
                    listAllCollections={this.props.listAllCollections}

                />
                }
                {(this.props.menuClickData.mainMenu === 'entity' || this.props.menuClickData.mainMenu === 'services' )&& subPage === false &&
                <Entity
                    toggleStatus={this.state.toggleStatus}
                    menuToggle={this.menuToggle}
                    categories={this.props.getcollectionsData.getForms.data}
                    collectionsFilter={this.CollectionsParam}
                    listAllCollections={this.props.listAllCollections}
                    loader={this.props.loader}
                />
                }
                {this.props.menuClickData.mainMenu === 'chat' &&
                <Chat
                    toggleStatus={this.state.toggleStatus}
                    menuToggle={this.menuToggle}
                    allusers={this.props.allusers}
                    loadChatHistory={this.props.chatHistory}
                    groupData={this.props.allgroups}
                    createGroup={this.props.createGroup}
                    chatUsers={this.props.chatusers}
                    socket={this.props.socket}
                    groupInfo={this.props.groupInfo}
                    groupDelete={this.props.groupDelete}
                    loadSidebarUsers={this.loadSidebarUsers}
                    allGroups={this.props.allGroups}
                    chatUserId={this.props.chatUserId}
                    loader={this.props.loader}
                    onlineUsers={this.props.onlineusers}
                />
                }
                {this.props.menuClickData.mainMenu.toLowerCase() === 'project' &&
                <Project
                    menuClickData={this.props.menuClickData.subMenu}
                    toggleStatus={this.state.toggleStatus}
                    menuToggle={this.menuToggle}
                    allprojects={this.props.listAllProjectData}
                    mainClick={this.mainClick}
                    projectSubClick={this.projectSubClick}
                    ProjectFilesData={this.props.ProjectFilesData}
                    listAllCollections={this.props.listAllCollections}
                    loader={this.props.loader}
                    stakeholdermenuUpdate={this.props.stakeholdermenuUpdate}
                    Stakeholdermenudata={this.props.Stakeholdermenudata}
                    sectorList={this.props.listAllPortfolioData.portfolios.data}
                    VerticalList={this.props.listAllProgramData.programs.data}
                    sectors={this.state.sectors}
                    vertical={this.state.verticals}
                    projectmenuData={projectmenuData}
                />
                }
                {this.props.menuClickData.mainMenu === 'settings' &&
                <Settings
                    menuClickData={this.props.menuClickData.subMenu}
                    toggleStatus={this.state.toggleStatus}
                    menuToggle={this.menuToggle}
                    mainClick={this.mainClick}
                />
                }
                {(this.props.menuClickData.mainMenu === 'team') && getUrlSegment(2) !=="rolesettings" && getUrlSegment(2) !=="rolemodulepermission" &&
                <Team
                    menuClickData={this.props.menuClickData.subMenu}
                    mainClick={this.mainClick}
                    allcompanies={allcompanies}
                    allDesignation={allDesignation}
                    allReportees={allReportees}
                    teamClick={this.teamClick}
                    userdetail={userdetail}
                    teamClickData={this.props.teamClickData.subMenu}
                    searchType={this.props.searchType}
                    listUsers={this.props.listAllUsers}
                    listPermissions={this.props.listAllUserspermission}
                    history={this.props.history}
                />
                }
                {this.props.menuClickData.mainMenu === 'group' &&  subPage === false &&
                <Group
                    menuClickData={this.props.menuClickData.subMenu}
                    mainClick={this.mainClick}
                    teamClick={this.teamClick}
                    userdetail={userdetail}
                    teamClickData={this.props.teamClickData.subMenu}
                    searchType={this.props.searchType}
                    listUsers={this.props.listAllUsers}
                    history={this.props.history}
                />
                }
            </div>

        );
    }
}


const mapStateToProps = state => ({
    menuClickData: state.CommonReducer.menuClick,
    sidebarmenudata: state.TaskReducer.SidebarMenudata,
    getformsData: state.CommonReducer.formsCatagory,
    getcollectionsData: state.CommonReducer.formsCatagory,
    listAllFormsData: state.FormsReducer.listFormsdata,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    allusers: state.ChatReducer.listallusers,
    allgroups: state.ChatReducer.listallgroups,
    chatusers: state.ChatReducer.ChatUsers,
    listUsersPermission:state.UsersReducer.listUsersPermissiondata,
    listAllProjectData:state.ProjectReducer.listallProjects,
    mobToggle: state.CommonReducer.mobileTogle,
    toasternotification: state.ChatReducer.toasterNotification,
    chatUserId: state.ChatReducer.chatUserId,
    loader:state.CommonReducer.loader,
    getPoolMenuItems: state.TaskReducer.getPoolData,
    todoListing:state.TaskReducer.listtoDo,
    todotopbardata:state.TaskReducer.TodoTopbarMenudata,
    tofollowuptopbardata:state.TaskReducer.TofollowupTopbarMenudata,
    tocommenttopbardata:state.TaskReducer.ToCommentTopbarMenudata,
    toknowtopbardata:state.TaskReducer.ToKnowTopbarMenudata,
    tooverseetopbardata:state.TaskReducer.ToOverseeTopbarMenudata,
    duetodaytopbardata:state.TaskReducer.DueTodayTopbarMenudata,
    duetomorrowtopbardata:state.TaskReducer.DueTomorrowTopbarMenudata,
    duethismonthtopbardata:state.TaskReducer.DueThismonthTopbarMenudata,
    alloverduetopbardata:state.TaskReducer.AlloverdueTopbarMenudata,
    allarchivetopbardata:state.TaskReducer.AllarchiveTopbarMenudata,
    taskFilters:state.TaskReducer.filterTasks,
    haveLoader : state.CollectionsReducer.manageoader,
    onlineusers:state.CommonReducer.onlineusers,
    searchData: state.CommonReducer.searchparamdata,
    sidebarsearchmenudata:state.TaskReducer.SidebarSearchMenudata,
    ProjectFilesData:state.ProjectReducer.ProjectInfo,
    Stakeholdermenudata:state.ProjectReducer.Stakeholdermenudata,
    listAllProgramData:state.VerticalReducer.listallPrograms,
    listAllPortfolioData:state.SectorReducer.listallPortfolio,
    teamClickData:state.CommonReducer.teamClick,
    listallCompany:state.UsersReducer.listAllCompanies,
    listallDesignation:state.UsersReducer.listAllDesignations,
    listallReportees:state.UsersReducer.listAllReportees,
    listUserInfo:state.UsersReducer.listUserInfoData,
    listAllUsers:state.UsersReducer.listAllUsers,
});
export default withRouter(connect(mapStateToProps, {
    mainclick: rootActions.commonActions.MenuClick,
    getforms: rootActions.commonActions.GetForms,
    forms: formActions.listallforms,
    collections: collectionActions.listallcollections,
    chatHistory: rootActions.chatAction.listChatHistory,
    createGroup: rootActions.chatAction.createGroup,
    chatUsers: rootActions.chatAction.chatUsers,
    groupInfo: rootActions.chatAction.groupInfo,
    userspermissions:usersActions.listUsersPermission,
    allGroups: rootActions.chatAction.listAllGroups,
    getPoolMenu : taskActions.getPoolMenu,
    gettasklist:taskActions.listtask,
    overDuePopup:taskActions.overDuePopup,
    searchType: rootActions.commonActions.searchType,
    stakeholdermenuUpdate:projectActions.StakeholderClick,
    portfolio:portfolioActions.listallportfolio,
    program:verticalActions.listallprograms,
    project:projectActions.listallprojects,
    teamClick:rootActions.commonActions.teamClick,
})(SideBar));
