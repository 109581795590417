import axios from "axios";
import {API_PATH,AUTHENTICATION, ES_INDEX} from '../constants/index';
import {getProfile} from "../utilities/AuthService";
import rootActions from "../actions";

export  const commonService = {
    sendHttpRequest,
    activityTracking
}

function sendHttpRequest(URL , data, method) {
    var date = new Date(); var timestamp = date.getTime();
    var params = '';
    if(method === 'GET' && data) {
        params = data;
    }

    let headers = {'Content-Type' : 'application/json'};
    let msg=''
    let status=""
    let statikKey = 'VVRKR2JGcHRaREJPUkZVeFRsUlVUSkdiRnB0WkRCT1JGVXhUbFJGZUU1cVZXaE5la2t3VGxoS2IxcEhXbTVqZWxWNlRrUldaVXBwY0dWTFExbHdTME5WYkZvelRtdGFNMDVyV25rMWJHUXlWbkZoTW1Sb1lUTktiMWxSUFQwPUZlRTVxVldoTmVra3dUbGhLYjFwSFdtNWplbFY2VGtSV1pVcHBjR1ZMUTFsd1MwTlZiRm96VG10YU0wNXJXbmsxYkdReVZuRmhNbVJvWVROS2IxbFJQVDA9PT0';
    // Send an HTTP  request
    return axios({
        method: method,
        url: API_PATH+URL+'?t='+timestamp+'&index='+ES_INDEX,
        data: data,
        params : params,
        async:false,
        headers : {'Content-Type' : 'application/json',"X_API_KEY":AUTHENTICATION?(typeof  getProfile() !== "undefined"?getProfile().token:statikKey):statikKey}
    }).then(function(res){
        /*////////console.log("success");
        if(res.data.response === 'failure' || Object.keys(res.data.response).length === 0) {
            return undefined;
        }*/
        return res;

    }).catch(function(error){
        // if (error.response) {
        //     // The request was made and the server responded with a status code
        //     // that falls out of the range of 2xx
        //     // console.log(error.response.data);
        //     // console.log(error.response.status);
        //     // console.log(error.response.headers);
        //
        //     if(error.response.status === 401 && error.response.data.error.message === "Unauthorized"){
        //         param= {
        //             msg : "Your user session has expired. Click ok to relogin.",
        //             code : 401
        //         }
        //     }
        //     if(error.response.status === 500 ){
        //         param= {
        //             msg : "Something Went wrong.",
        //             code : error.response.status
        //         }
        //     }
        // }
        // else{
        //     param= {
        //         msg: "There is a network failure, please try again.",
        //         code : ""
        //     }
        //
        // }


        return error;
    });


}
function activityTracking(params) {
    return commonService.sendHttpRequest('library/activitytracking', params, "POST");
}

// function activityTracking(params) {
//     return commonService.sendHttpRequest('library/activitytracking', params, "POST");
// }
