import React, {Component} from 'react';
import {
    RadialBarChart,
    RadialBar,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar,
    CartesianGrid,
    Tooltip,
    Area,
    Legend,
    ResponsiveContainer
} from 'recharts';
const data = [
    {
        name: '18-24', uv: 31.47, pv: 2400, fill: '#8884d8',
    },
    {
        name: '25-29', uv: 26.69, pv: 4567, fill: '#83a6ed',
    },
    {
        name: '30-34', uv: 15.69, pv: 1398, fill: '#8dd1e1',
    },
    {
        name: '35-39', uv: 8.22, pv: 9800, fill: '#82ca9d',
    },
    {
        name: '40-49', uv: 8.63, pv: 3908, fill: '#a4de6c',
    },
    {
        name: '50+', uv: 2.63, pv: 4800, fill: '#d0ed57',
    },
    {
        name: 'unknow', uv: 6.67, pv: 4800, fill: '#ffc658',
    },
];
const style = {
    top: 0,
    left: 350,
    lineHeight: '24px',
};

const CustomTooltip = ({ active, payload, label, unit }) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                {payload && payload.map(item =>
                    <p className="intro" style={{color : item.color}}>{`${item.name} : ${item.value} ${unit?unit:''}`}</p>
                )}
            </div>
        );
    }

    return null;
};
const renderCustomizedLabel = (props) => {
    ////console.log(props)

    const {
        x, y, width, height, value,
    } = props;
    const radius = 10;

    return (
        <g>
            <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
            <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                {value.split(' ')[1]}
            </text>
        </g>
    );
};
const COLORS = ['#B0B0B0', '#6ED4B9', '#76A6F0','#DB1238'];

function RadialChart(props) {

    let graphdata = props.containerData.data;
    /*let xwidth = props.containerData.xwidth && parseInt(props.containerData.xwidth);
    let xheight =  props.containerData.xheight && parseInt(props.containerData.xheight);
    let yheight = props.containerData.yheight && parseInt(props.containerData.yheight);
    let ywidth = props.containerData.ywidth && parseInt(props.containerData.ywidth);*/
    /*let xAngle =  props.containerData.xAngle ? parseInt(props.containerData.xAngle) : 0;
    let yAngle = props.containerData.yAngle ? parseInt(props.containerData.yAngle) : 0;*/
    let graphHeight =  props.containerData.graphHeight ? parseInt(props.containerData.graphHeight) : 350;
    let bardata = props.containerData.tabledata.slice(1);
    ////console.log(bardata)
    let borderColor = props.containerData.bordercolor && props.containerData.bordercolor;


    return (
        <React.Fragment>
            <ResponsiveContainer width={props.containerData.legend ? "95%" : "100%"} height={graphHeight}>
                <RadialBarChart
                    innerRadius="5%"
                    outerRadius="95%"
                    data={graphdata}
                    startAngle={90}
                    endAngle={-200}
                    barGap={50}
                >
                    <RadialBar minAngle={15} label={{ fill: '#666', size:14, position: 'end'}} background={false} clockWise={true} dataKey={bardata[0].label} fill={bardata[0].color} stroke={borderColor ? borderColor :bardata[0].color } />
                    {props.containerData.legend &&
                    <Legend   iconSize={10} width={120} height={140} fill="#f0f0f0" layout='vertical' verticalAlign={`middle`} align={`right`}/>
                    }
                    <Tooltip content={<CustomTooltip unit={props.containerData.unit} />}/>
                </RadialBarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}

export default RadialChart;