import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import FormFields from "../views/CollectionFormElements/FormFields";
import FieldSet from "../views/CollectionFormElements/FieldSet";
import ColumnBreak from "../views/CollectionFormElements/ColumnBreak";
import {
    DateFormatter,
    forceDownload, getFileExtension,
    getFilePath, getMetaId, getSubdomain,
    validURL
} from "../../utilities/CustomFunctions";
import LoaderSvg from "../views/LoaderSVG";
import * as collectionActions from "../../actions/Collections";
import * as entityActions from "../../actions/Entity";
import moment from 'moment';
import {getProfile} from "../../utilities/AuthService";
import SimpleModal from "../views/SimpleModal";
import AutoComplete from "../views/AutoComplete";
import {
    getUrlSegment,
    parseQueryStr,
    getRedirectUrlForEmbedForms,
    parseQuillEditorContent
} from "../../utilities/CustomFunctions";
import * as formActions from "../../actions/Forms";
import {ES_INDEX, FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH, NEW_VERSION} from "../../constants";
import AlertModal from "../views/AlertModal";
import {debounce} from 'throttle-debounce';
import {Scrollbars} from "react-custom-scrollbars";
import {Button} from "react-bootstrap";
import LinkedFormModal from "../views/LinkedFormModal";
import * as CeShareViewActions from "../../actions/CeShareView";
import * as userActions from "../../actions/Users";
import DatePicker from "react-datepicker";
import {components} from "react-big-calendar";
import {resetForm} from "../../actions/Forms";

let setActiveIndex = false;
let moduleName = getUrlSegment(1);
let uniqueValueCheck = 0;
let newFindex = 0;
let focusToEle = '';
let externalReqs = 0;
let newEleCloned = false;

class ShowFormModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formPages: [],
            title: '',
            desc: '',
            formSubmitted: false,
            formSaved: false,
            metaForm: [],
            metaComponents: [],
            pageIndex: 0,
            formSubmitIndex: 0,
            editableForm: true,
            initialContainerClass: 'closed-box flex-box form-alignment-lbl',
            fieldsetIndices: [],
            dataloadFields: [],
            showDataLoadModal: false,
            selectedCollections: [],
            selectedEntities: [],
            datalistType: 'collection',
            dataloadoption: '',
            dataloadaggs: '',
            optionError: '',
            aggError: '',
            dataloadmasterid: '',
            embedForm: false,
            externalSuccessMessage: false,
            formTemplate: '',
            formWidth: 960,
            subdomain: '',
            submittedFormAlert: false,
            hideSubmitButton: false,
            errorMessageOnSaving: false,
            recordBasedForm: false,
            moduleName: "entity",
            uniqueValueCheck: 0,
            formSubmissionStatus: false,
            formSubmitting: 0,
            thankyoucontent: '',
            thankyouimgcontent: '',
            thankyoulink: '',
            thankyoustatus: '',
            thankyouMessage: false,
            thankyou_settings: [],
            profileimgcontent: '',
            newHeader: false,
            header: [],
            headerlogo: '',
            headerlogoalignment: '',
            headertext: '',
            headertextalignment: '',
            headertextsize: '',
            headerbgcolor: '',
            headertextcolor: '',
            headerwidth: '',
            headerpersistent: '',
            latitudeval: 0,
            longitudeval: 0,
            locationalert: false,
            newFooter: false,
            footer: [],
            footericon: [],
            footercolumns: '',
            footertextsize: '',
            footertextalignment: '',
            footertextbullet: [],
            footerbgcolor: '',
            footertextcolor: '',
            footerwidth: '',
            focusToEle: '',
            moveFocus: false,
            thankyoulinkfield: '',
            textcontentmod: '',
            savedvalue: '',
            validationMode: 'page',
            currentFieldIndex: 0,
            formTemplateType: 'normal',
            componentList: [],
            bsynOtp: '',
            recievedOtp: '',
            otpNames: '',
            otpValues: '',
            otpIndx: 0,
            singlePageForm: false, //Chatboat form
            showAuthPop: false,
            showPageList: false, // For phone only
            presetValues: [], //Prefilled values for the form
            metaId: '',
            allFormPages: [],
            collaborator: false,
            readOnlyFields: [],
            showDropdownModal: false,
            dropdownPlus: false,
            dropdownSubmit: false,
            selectedResources: [],
            selectedCountries: [],
            selectedBrowseResources: [],
            newImageUrl: '',
            showVersionModal: false,
            functionEnabled: false,
            formLoading: false,
            previousVersion: [],
            quickFormInfo: [],
            quickFormTemplate: [],
            rolesettings: [],
            quickform: false,
            saveasdraft: 'no',
            draftSuccessMessage: false,
            draftId: '',
            submissionType: 'submit',
            customButtonLabel: '',
            customButtonColor: '',
            customButtonId: 0,
            autoCaptureModeDate: '',
            redirectUrl: '',
            quickformStatus: false,
            customapiformsubmission: ''
        }
        try {
            getProfile();
            this.state.uid = getProfile().id;
            this.state.usertype = getProfile().usertype;
            this.state.formaccess = 'internal';
            this.state.loggedInValue = getProfile().first_name + " " + getProfile().last_name;
        } catch (e) {
            this.state.uid = 2;
            this.state.usertype = 'Superadmin';
            this.state.formaccess = 'external';
            this.state.loggedInValue = '';
        }

        this.submitForm = this.submitForm.bind(this);
        this.setFieldValues = this.setFieldValues.bind(this);
        this.checkFieldValid = this.checkFieldValid.bind(this);
        this.validateField = this.validateField.bind(this);
        this.verifyField = this.verifyField.bind(this);
        this.createFieldJson = this.createFieldJson.bind(this);
        this.setFormPage = this.setFormPage.bind(this);
        this.setFormValues = this.setFormValues.bind(this);
        this.changePage = this.changePage.bind(this);
        this.appendElement = this.appendElement.bind(this);
        this.removeElement = this.removeElement.bind(this);

        this.initiateDataLoad = this.initiateDataLoad.bind(this);
        this.Close = this.Close.bind(this);
        this.getEntities = this.getEntities.bind(this);
        this.getCollections = this.getCollections.bind(this);
        this.getAggregate = this.getAggregate.bind(this);
        this.dataloadhandleChange = this.dataloadhandleChange.bind(this);
        this.resultDataListDataload = this.resultDataListDataload.bind(this)
        this.renderFieldValues = this.renderFieldValues.bind(this);
        this.renderFSValues = this.renderFSValues.bind(this);
        this.saveLocationAlert = this.saveLocationAlert.bind(this);
        this.cancelLocationAlert = this.cancelLocationAlert.bind(this);
        this.checkIfUnique = debounce(500, this.checkIfUnique);
        this.generateNewValue = this.generateNewValue.bind(this);
        this.renderFormpage = this.renderFormPage.bind(this);
    }


    componentDidMount() {
        let collaborator = this.state.collaborator;
        if (this.state.usertype === 'Superadmin' || this.state.usertype === 'admin' || this.state.usertype === 'root') {
            collaborator = true;
        }
        let subdomain = getSubdomain(window.location.hostname);
        //let subdomain = 'data.bsynupgrade';
        this.setState({subdomain: subdomain});
        let collectionid = this.props.collectionId ? this.props.collectionId : '';
        let singlePageForm = this.state.singlePageForm;
        if (this.props.singlePageForm) {
            singlePageForm = this.props.singlePageForm;
        }
        if (getUrlSegment(1) === 'embed' || this.props.embedForm) {
            collectionid = atob(getUrlSegment(3));
            let presetValues = [];
            let metaId = '';
            if (getUrlSegment(1) === 'chat') {
                let queryStr = this.props.location.search;
                if (queryStr !== '') {
                    presetValues = parseQueryStr(queryStr);
                    metaId = getMetaId(queryStr);
                }
            }
            if (!getUrlSegment(4)) {
                this.props.showform({_id: collectionid}, this.state.formaccess);
            }
            this.setState({
                embedForm: true,
                uid: 2,
                usertype: 'Superadmin',
                collectionid: collectionid,
                singlePageForm: singlePageForm,
                presetValues: presetValues,
                metaId: metaId
            });
        }
        if (this.props.iosForm) {
            collectionid = atob(getUrlSegment(2));
            this.setState({
                iosForm: true,
                uid: getUrlSegment(3),
                usertype: getUrlSegment(4),
                collectionid: collectionid,
                singlePageForm: singlePageForm
            });
        }
        if (this.state.uid) {
            var formInfo = (this.state.moduleName === 'entity') ? this.props.entityFormInfo : this.props.formInfo;
            collaborator = formInfo.collaborator;
            var formTemplate = '';
            var formWidth = formInfo ? formInfo.width : this.state.formWidth;
            var thankyoucontent = formInfo ? formInfo.thankyoucontent : this.state.thankyoucontent;
            var thankyoulink = formInfo ? formInfo.thankyoulink : this.state.thankyoulink;
            var thankyoustatus = formInfo ? formInfo.thankyoustatus : this.state.thankyoustatus;
            var thankyoulinkfield = formInfo ? formInfo.thankyoulinkfield : this.state.thankyoulinkfield;
            var header = formInfo ? formInfo.header : this.state.header;
            var footer = formInfo ? formInfo.footer : this.state.footer;
            //Typeform
            let formTemplateType = this.state.formTemplateType;
            let validationMode = this.state.validationMode;
            var templateInfo = formInfo ? formInfo.templateInfo : [];
            let pageIndex = this.state.pageIndex;
            if (templateInfo.length > 0) {
                formTemplateType = templateInfo[0].type;
                formTemplate = templateInfo[0].templateclass;
                if (formTemplateType === 'typeform') {
                    //formTemplateType = formTemplateType;
                    validationMode = 'field';
                } else if (formTemplateType === 'cortiza') {
                    //formTemplateType = formTemplateType;
                    validationMode = 'form';
                }
            }

            var newHeader = false, headerlogo = '', headerlogoalignment = '', headertext = '', headertextalignment = '',
                headertextsize = '', headerbgcolor = '', headertextcolor = '', headerwidth = '', headerpersistent = '',
                newFooter = false, footercolumns = '', footertextsize = '', footertextalignment = '',
                footerbgcolor = '', footertextcolor = '', footerwidth = '';
            let footericon = [];
            let footertextbullet = [];

            if (header.length > 0) {
                newHeader = true;
                headerlogo = header[0]['logo'];
                headerlogoalignment = header[0]['logoalignment'];
                headertext = header[0]['text'];
                headertextalignment = header[0]['textalignment'];
                headertextsize = header[0]['textsize'];
                headerbgcolor = header[0]['bgcolor'];
                headertextcolor = header[0]['textcolor'];
                headerwidth = header[0]['width'];
                headerpersistent = header[0]['persistent'];
            }
            if (footer.length > 0) {
                newFooter = true;
                footercolumns = footer[0]['columns'];
                footertextsize = footer[0]['textsize'];
                footertextalignment = footer[0]['textalignment'];
                footerbgcolor = footer[0]['bgcolor'];
                footertextcolor = footer[0]['textcolor'];
                footerwidth = footer[0]['width'];
                footericon = footer[0]['meta'] ? footer[0]['meta'] : [];
                footertextbullet = footer[0]['meta'] ? footer[0]['meta'] : [];
            }
            this.setState({
                title: this.props.formName,
                desc: this.props.formDesc,
                subdomain: subdomain,
                formTemplate: formTemplate === undefined ? '' : formTemplate,
                formWidth: formWidth,
                recordBasedForm: this.props.recordBasedForm,
                collectionid: collectionid,
                thankyoucontent: thankyoucontent,
                //thankyouimgcontent: thankyouimgcontent,
                thankyoulink: thankyoulink,
                thankyoustatus: thankyoustatus,
                thankyoulinkfield: thankyoulinkfield,
                //profileimgcontent: profileimgcontent,
                header: header,
                footer: footer,
                newHeader: newHeader,
                headerlogo: headerlogo,
                headerlogoalignment: headerlogoalignment,
                headertext: headertext,
                headertextalignment: headertextalignment,
                headertextsize: headertextsize,
                headerbgcolor: headerbgcolor,
                headertextcolor: headertextcolor,
                headerwidth: headerwidth,
                headerpersistent: headerpersistent,
                newFooter: newFooter,
                footericon: footericon,
                footercolumns: footercolumns,
                footertextsize: footertextsize,
                footertextalignment: footertextalignment,
                footertextbullet: footertextbullet,
                footerbgcolor: footerbgcolor,
                footertextcolor: footertextcolor,
                footerwidth: footerwidth,
                formTemplateType: formTemplateType,
                validationMode: validationMode,
                singlePageForm: singlePageForm,
                collaborator: collaborator,
                //saveasdraft : formInfo? formInfo.saveasdraft: this.state.saveasdraft,


            }, function () {
                this.setValidationMode();
                this.setFormPage();
                this.getEntities();
                this.getCollections();
            });

        }
        this.trackCollection('formload', collectionid);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.formInfo.showformdata !== prevProps.formInfo.showformdata || this.props.entityFormInfo.showformdata !== prevProps.entityFormInfo.showformdata) {
            this.setState({
                collaborator: this.props.formInfo.collaborator
            }, function () {
                this.setFormPage();
            });

            if ((this.props.formInfo.auth && this.props.formInfo.auth === 'yes') || (this.props.entityFormInfo.auth && this.props.entityFormInfo.auth === 'yes')) {
                this.setState({
                    showAuthPop: true
                });
            }

        }

        // if(this.props.formInfoExternal.showformdata !== prevProps.formInfoExternal.showformdata || this.props.entityFormInfo.showformdata !== prevProps.entityFormInfo.showformdata) {
        //     this.setState({
        //         collaborator : this.props.formInfo.collaborator
        //     },function(){
        //         this.setFormPage();
        //     });
        //
        //     if((this.props.formInfo.auth && this.props.formInfo.auth === 'yes') || (this.props.entityFormInfo.auth && this.props.entityFormInfo.auth === 'yes') ) {
        //         this.setState({
        //             showAuthPop : true
        //         });
        //     }
        //
        // }


        if (this.props.formAuthData.authSuccess !== prevProps.formAuthData.authSuccess) {
            this.setState({
                showAuthPop: false
            });
            this.setFormPage();
        }


        /*if(this.props.formInfo.template !== prevProps.formInfo.template) {
            this.setState({
                formTemplate : this.props.formInfo.template
            });
        }*/
        if (this.props.formInfo.pagelock !== prevProps.formInfo.pagelock || this.props.entityFormInfo.pagelock !== prevProps.entityFormInfo.pagelock) {
            this.setValidationMode();
        }
        if (this.props.formInfo.templateInfo !== prevProps.formInfo.templateInfo) {
            var templateInfo = this.props.formInfo ? this.props.formInfo.templateInfo : [];
            if (templateInfo.length > 0) {
                let formTemplateType = templateInfo[0].type;
                let formTemplate = templateInfo[0].templateclass;

                if (formTemplateType === 'typeform') {
                    this.setState({
                        formTemplateType: formTemplateType,
                        validationMode: 'field',
                        formTemplate: formTemplate
                    });
                } else {
                    let pageIndex = this.state.pageIndex;
                    let formSubmitIndex = this.state.formSubmitIndex;

                    if (this.state.formTemplateType === 'cortiza') {
                        pageIndex = formSubmitIndex;
                    }
                    this.setState({
                        formTemplate: formTemplate,
                        formTemplateType: formTemplateType
                    });
                }
            }
        }
        if (this.props.entityFormInfo.templateInfo !== prevProps.entityFormInfo.templateInfo) {
            var templateInfo = this.props.entityFormInfo ? this.props.entityFormInfo.templateInfo : [];
            if (templateInfo.length > 0) {
                let formTemplateType = templateInfo[0].type;
                let formTemplate = templateInfo[0].templateclass;

                if (formTemplateType === 'typeform') {
                    this.setState({
                        formTemplateType: formTemplateType,
                        validationMode: 'field',
                        formTemplate: formTemplate
                    });
                } else {
                    let pageIndex = this.state.pageIndex;
                    let formSubmitIndex = this.state.formSubmitIndex;

                    if (this.state.formTemplateType === 'cortiza') {
                        pageIndex = formSubmitIndex;
                    }
                    this.setState({
                        formTemplate: formTemplate,
                        formTemplateType: formTemplateType
                    });
                }
            }
        }
        /*if(this.props.entityFormInfo.template !== prevProps.entityFormInfo.template) {
            this.setState({
                formTemplate : this.props.entityFormInfo.template
            });
        }*/
        if (this.props.formInfo.width !== prevProps.formInfo.width) {
            this.setState({
                formWidth: this.props.formInfo.width
            });
        }
        if (this.props.formInfo.profileimgcontent !== prevProps.formInfo.profileimgcontent) {
            this.setState({
                profileimgcontent: this.props.formInfo.profileimgcontent
            });
        }
        if (this.props.entityFormInfo.width !== prevProps.entityFormInfo.width) {
            this.setState({
                formWidth: this.props.entityFormInfo.width
            });
        }
        if (this.props.entityFormInfo.profileimgcontent !== prevProps.entityFormInfo.profileimgcontent) {
            this.setState({
                profileimgcontent: this.props.entityFormInfo.profileimgcontent
            });
        }
        if (this.props.formInfo.thankyoustatus !== prevProps.formInfo.thankyoustatus) {
            this.setState({
                thankyoustatus: this.props.formInfo.thankyoustatus,
                thankyoulinkfield: this.props.formInfo.thankyoulinkfield,
                thankyoucontent: this.props.formInfo.thankyoucontent,
                thankyoulink: this.props.formInfo.thankyoulink
            });
        }
        if (this.props.formInfo.header !== prevProps.formInfo.header) {
            this.setState({header: this.props.formInfo.header});

        }
        if (this.props.formInfo.footer !== prevProps.formInfo.footer) {
            this.setState({footer: this.props.formInfo.footer});
        }
        if (this.props.entityFormInfo.header !== prevProps.entityFormInfo.header) {
            this.setState({header: this.props.entityFormInfo.header});
        }
        if (this.props.entityFormInfo.footer !== prevProps.entityFormInfo.footer) {
            this.setState({footer: this.props.entityFormInfo.footer});
        }
        if (this.props.entityFormInfo.thankyoustatus !== prevProps.entityFormInfo.thankyoustatus) {
            this.setState({
                thankyoustatus: this.props.entityFormInfo.thankyoustatus,
                thankyoulinkfield: this.props.entityFormInfo.thankyoulinkfield,
                thankyoucontent: this.props.entityFormInfo.thankyoucontent,
                thankyoulink: this.props.entityFormInfo.thankyoulink
            });
        }

        /* if(this.props.formInfo.saveasdraft !== prevProps.formInfo.saveasdraft) {
             this.setState({saveasdraft : this.props.formInfo.saveasdraft});

         }
         if(this.props.entityFormInfo.saveasdraft !== prevProps.entityFormInfo.saveasdraft) {
             this.setState({saveasdraft : this.props.entityFormInfo.saveasdraft});

         }*/

        /************** Data Load compoennt *********************/
        if (this.props.formInfo.getformcomponents !== prevProps.formInfo.getformcomponents || this.props.entityFormInfo.getformcomponents !== prevProps.entityFormInfo.getformcomponents) {
            this.setDataLoadFields();
        }
        /* prevProps.formInfo.computedfield [computed] param used for closing computed field api result is empty */
        if (this.props.formInfo.aggData !== prevProps.formInfo.aggData || this.props.formInfo.computedfield !== prevProps.formInfo.computedfield) {
            let refparams = this.props.formInfo.refparams;
            let dataloadmasterid = refparams ? refparams.masterid : '';
            let formPages = [...this.state.formPages];
            let matchedComponent = formPages.map(page => page.components.filter(comp => (comp.masterid === dataloadmasterid)));
            if (matchedComponent.length > 0 && matchedComponent[0].length > 0) {
                matchedComponent[0][0].value = [{
                    value: this.props.formInfo.aggData.toFixed(2),
                    collection: refparams.collectionid,
                    field: refparams.field,
                    aggs: refparams.aggs
                }];
            }
            this.setState({formPages: formPages});
            this.Close()
        }
        if (this.props.entityFormInfo.aggData !== prevProps.entityFormInfo.aggData || this.props.entityFormInfo.computedfield !== prevProps.entityFormInfo.computedfield) {
            let refparams = this.props.entityFormInfo.refparams;
            let dataloadmasterid = refparams ? refparams.masterid : '';
            let formPages = [...this.state.formPages];
            let matchedComponent = formPages.map(page => page.components.filter(comp => (comp.masterid === dataloadmasterid)));
            if (matchedComponent.length > 0 && matchedComponent[0].length > 0) {
                matchedComponent[0][0].value = [{
                    value: this.props.entityFormInfo.aggData.toFixed(2),
                    collection: refparams.collectionid,
                    field: refparams.field,
                    aggs: refparams.aggs
                }];
            }
            this.setState({formPages: formPages});
            this.Close()
        }

        if (this.props.externalData !== prevProps.externalData) {

            let formPages = [...this.state.formPages];
            formPages.map(page => page.components.filter(comp => (comp.externallink && comp.externallink === 'Yes' && (parseInt(comp.masterid) === parseInt(this.props.externalData.externalFields.masterField) || parseInt(comp.referenceId) === parseInt(this.props.externalData.externalFields.masterField)))).map(item => {
                let externalValues = this.props.externalData.externalFields.values;
                item.externalValues = externalValues;
                // console.log(externalValues)
                let collection = item.externalfield[0].collection;
                let optArray = [];
                if (Array.isArray(externalValues)) {
                    optArray = externalValues.map(external => {

                        external.value[0]['value'] = external.value[0].value;
                        external.value[0]['displayvalue'] = '';
                        external.value[0]['additionaltext'] = '';
                        if (external.displayvalue !== false && external.displayvalue !== '' && external.displayvalue !== null) {
                            if (item.type === 'checkbox' || item.type === 'check_image' || item.type === 'switch' || item.type === 'dropdown') {
                                external.value[0]['value'] += ", " + external.displayvalue;
                            } else if (item.type === 'autofill') {
                                external.value[0]['displayvalue'] = external.displayvalue;
                                external.value[0]['autofill'] = external.autofill;
                            }
                        }
                        if (external.additionaltext !== false && external.additionaltext !== '' && external.additionaltext !== null && item.type === 'autofill') {
                            external.value[0]['additionaltext'] = external.additionaltext;

                        }

                        external.value[0]['recordid'] = external.recordid;
                        external.value[0]['collection'] = collection.toString();
                        external.value[0]['fieldid'] = external.fieldid;
                        return external.value;
                    });
                }
                // console.log(optArray)

                let optValues = [].concat.apply([], optArray);
                //console.log(optValues)


                item.options = optValues;

                let matchedValues = (item.value) ? item.value : [];

                if (item.externalValues && matchedValues && Array.isArray(matchedValues)) {
                    matchedValues = matchedValues.filter(sel => sel.recordid !== '');
                    let selectedValueArr = matchedValues;

                    item.selectedItems = selectedValueArr;
                    item.selectedItems = Array.isArray(item.selectedItems) ? item.selectedItems : [];
                    item.value = item.selectedItems;
                    item.selectedVals = item.selectedItems.map(sel => {
                        return sel.value;
                    });
                }

                let defVal = this.props.masterid.filter(list => list.masterid === item.masterid);
                if (defVal.length !== 0) {
                    let mapid = this.props.mapid;

                    //let matchedValues = [];
                    if (defVal[0].default && defVal[0].default !== "") {
                        // console.log(item.options)
                        // console.log(mapid.toString())
                        matchedValues = item.options.filter(opt => opt.recordid && opt.recordid.toString() === mapid.toString());

                        if (matchedValues.length > 0) {
                            if (item.type === 'autofill') {
                                let autofillValues = matchedValues[0].autofill;
                                let autofillRel = item.autofill_relation;
                                if (autofillRel.length > 0) {
                                    for (var af = 0; af < autofillRel.length; af++) {
                                        let fillto = autofillRel[af].fillto;
                                        formPages.map(fp => fp.components.filter(com => com.masterid === fillto).map(citem => {
                                            let defaultValue = autofillValues[autofillRel[af].masterid];
                                            if (citem.externallink !== 'Yes') {
                                                citem.setdefaultval = (defaultValue && defaultValue.length > 0) ? defaultValue[0].value : '';
                                                //console.log(citem.setdefaultval)
                                                citem.value = citem.setdefaultval;
                                            }

                                            if (citem.type === 'autofill' || citem.type === 'checkbox' || citem.type === 'switch' || citem.type === 'check_image' || citem.type === 'dropdown') {
                                                citem.selectedItems = [];
                                                citem.selectedVals = [];
                                                citem.value = [];
                                                if (citem.setdefaultval && citem.setdefaultval !== '') {
                                                    if (citem.optionstatus) {
                                                        let selOption = [];
                                                        if (citem.optionstatus === 'textoption') {
                                                            selOption = citem.options.filter((opt) => opt.value === citem.setdefaultval);
                                                        } else if (citem.optionstatus === 'imageoption') {
                                                            selOption = citem.options.filter((opt) => opt.value === citem.setdefaultval);
                                                        }
                                                        citem.selectedItems = selOption;
                                                        citem.selectedVals = [citem.setdefaultval];
                                                        citem.value = citem.selectedItems;
                                                    } else {
                                                        citem.selectedItems.push({value: citem.setdefaultval});
                                                        citem.selectedVals.push(citem.setdefaultval);
                                                        citem.value = citem.selectedItems;
                                                    }
                                                }
                                            }
                                            return citem;
                                        }));
                                    }
                                }
                            }
                            item.readOnly = true;
                        }
                    }

                    item.selectedItems = matchedValues;
                    item.selectedItems = Array.isArray(item.selectedItems) ? item.selectedItems : [];
                    item.value = item.selectedItems;
                    item.selectedVals = item.selectedItems.map(sel => {
                        return sel.value;
                    });
                }

                //this.setThisFieldValue(item, matchedValues);
                return item;
            }));
            this.setState({formPages: formPages});

        }

        // Close the modal on save
        if (this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged) {
            if (this.state.thankyoustatus !== '') {
                this.setState({loading: 0, pageIndex: 0, formSubmitted: false});
                this.thankyoupage();
            } else {
                this.setState({loading: 0, pageIndex: 0, formSubmitted: false, dropdownPlus: false})
                if (!this.state.embedForm && !this.state.iosForm && !this.state.recordBasedForm && !this.state.dropdownPlus) {
                    this.hideFormModal();

                } else if (this.state.embedForm && !getUrlSegment(4)) {
                    this.setState({formSaved: true, formSubmitted: false})
                    if (this.state.subdomain === 'medmigrate.data' || this.state.subdomain === 'data-atmantan') {
                        this.setState({externalSuccessMessage: true})
                    }
                } else {
                    return false;
                }
            }


        }
        //Show an error message on saving failure
        if (this.props.getSavedForm.formSaveError !== prevProps.getSavedForm.formSaveError && this.props.getSavedForm.formSaveError === true) {
            this.setState({loading: 0})
            this.setState({errorMessageOnSaving: true})
        }

        //Save draft success message
        if (this.props.getSavedForm.draftSaved !== prevProps.getSavedForm.draftSaved && this.props.getSavedForm.draftSaved === 'yes') {
            this.setState({loading: 0, draftSuccessMessage: true, draftId: this.props.getSavedForm.draftId})
        }

        // Get the form field values in case of edit
        if (this.props.getFormData.formvalues !== prevProps.getFormData.formvalues) {
            this.setFormValues();
        }


        // Check if the value exists
        let fieldid = this.props.getFormData.fieldid;
        if (this.props.getFormData[fieldid + "_valueExists"] !== prevProps.getFormData[fieldid + "_valueExists"]) {
            // this.setState({[fieldid+"_valueExists"] : this.props.getFormData[fieldid+"_valueExists"]})
            if (!this.state.dropdownSubmit) {
                let formPages = this.state.formPages;
                formPages = formPages.map((page, pageIndex) => {
                    page.components.map((item, fieldIndex) => {
                        if (item.masterid === fieldid) {
                            if (this.props.editId) {
                                item.isValid = (this.props.getFormData[fieldid + "_valueExists"] && this.props.editId.toString() !== this.props.getFormData.recordid.toString() && this.props.getFormData["value"].toString() === item.value.toString()) ? false : true;
                            } else {
                                item.isValid = this.props.getFormData[fieldid + "_valueExists"] ? false : true;
                            }

                            if (item.isValid) {
                                item.uniquecheck = 'done';
                                uniqueValueCheck = parseInt(uniqueValueCheck) - 1;
                                item.errormsg = '';
                                item.uniqError = false;
                            } else {
                                item.isValid = false;
                                item.errormsg = 'This value already exists';
                                item.uniqError = true;
                            }
                            return item;
                        }

                    });
                    return page;
                });

                this.setState({formPages: formPages, formSumissionStatus: !this.state.formSubmissionStatus},
                    function () {
                        this.submitForm();

                    })
            }
        }


        if (this.state.focusToEle !== prevState.focusToEle && document.getElementById(this.state.focusToEle) && this.state.moveFocus && !this.props.editId) {
            var element = document.getElementById(this.state.focusToEle);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
            this.setState({moveFocus: false})
        }
        if (this.state.formPages !== prevState.formPages) {
            let formPages = this.state.formPages;
            let pageComponents = formPages.map((page, pageIndex) => {
                return page.components;
            });
            let componentList = [].concat.apply([], pageComponents);
            this.setState({componentList: componentList})
        }

        if (this.props.sendbsynOtp.data !== prevProps.sendbsynOtp.data) {
            this.setState({bsynOtp: this.props.sendbsynOtp.bsynOtp});
        }
        if (this.props.verifybsynotp.data !== prevProps.verifybsynotp.data) {
            this.setState({recievedOtp: this.props.verifybsynotp.verifyOtp});
        }
    }

    setValidationMode = () => {
        let validationMode = '';
        let pagelockInfo = (this.state.moduleName === 'entity') ? this.props.entityFormInfo.pagelock : this.props.formInfo.pagelock;
        if (pagelockInfo == 'onsubmit') {
            validationMode = 'form';
        } else if (pagelockInfo == 'fieldwise') {
            validationMode = 'field';
        } else {
            validationMode = 'page';
        }
        this.setState({
            validationMode: validationMode
        })
    }

    authenticateForm = (e) => {
        e.preventDefault();
        let authParams = {
            "collectionid": this.state.collectionid,
            "username": this.state.form_username,
            "pwd": this.state.form_password
        }
        this.props.authenticateForm(authParams);
    }
    authInputChange = (e) => {
        var fieldName = e.target.id;
        var fieldValue = e.target.value;
        this.setState({[fieldName]: fieldValue});
    }

    setFormPage() {
        var formPages = (this.state.moduleName === 'entity') ? this.props.entityFormInfo.showformdata.pages : this.props.formInfo.showformdata.pages;
        // console.log(formPages)
        var settingsType = this.props.settingsType || '';
        var hiddenFields = this.props.hiddenFields || [];
        var readOnlyFields = this.props.readOnlyFields || [];
        var writableFields = this.props.writableFields || [];
        if (this.state.rolesettings.length > 0) {
            settingsType = this.state.rolesettings[0].type || '';
            hiddenFields = this.state.rolesettings[0].hide || [];
            readOnlyFields = this.state.rolesettings[0].readonly || [];
            writableFields = this.state.rolesettings[0].write || [];
        }
        var editableForm = this.props.formSource === 'workflow' ? ((writableFields.length > 0) ? true : false) : true;
        this.setState({editableForm: editableForm});
        var latitudeval = 0, longitudeval = 0;
        let allFormPages = formPages;

        if (formPages) {
            let obj = this;
            //formPages = this.sortJson(formPages);
            let formSubmitIndex = formPages.length - 1;

            allFormPages = formPages.map((page, pageIndex) => {
                page.pageIndex = pageIndex;
                if (this.props.formSource === 'workflow') {
                    page.accessRestriction = 'readonly';
                } else {
                    page.accessRestriction = 'writable';
                }

                if (settingsType === 'page') {
                    if (hiddenFields.includes(pageIndex.toString())) {
                        page.accessRestriction = 'hidden';
                        if (!setActiveIndex) {
                            obj.setState({pageIndex: pageIndex + 1});
                        }
                    } else if (readOnlyFields.includes(pageIndex.toString())) {
                        page.accessRestriction = 'readonly';
                        setActiveIndex = true;
                    } else if (writableFields.includes(pageIndex.toString())) {
                        page.accessRestriction = 'writable';

                        setActiveIndex = true;
                    }
                }

                if (getUrlSegment(1) === 'chat' || getUrlSegment(2) === 'pagewise') {
                    let pIndex = getUrlSegment(4);
                    page.accessRestriction = (parseInt(pageIndex) === parseInt(pIndex)) ? 'writable' : 'hidden';
                    if (parseInt(pIndex) > 0 && (!this.props.editId || this.props.editId === '')) {
                        if (parseInt(pageIndex) < parseInt(pIndex)) {
                            page.accessRestriction = 'writable';
                        }

                    }

                }

                if (settingsType === 'page' && (page.accessRestriction === 'readonly' || page.accessRestriction === 'writable')) {
                    page.components.map(item => item.visibility = 'visible');
                }
                let visibleFields = page.components.filter(item => ((((!item.visibility || item.visibility === 'visible') && (!item.conditionalhide || item.conditionalhide === 'No')) || writableFields.includes(item.masterid) || readOnlyFields.includes(item.masterid)) && item.linked === 'No'));
                if (visibleFields.length === 0) {
                    page.accessRestriction = 'hidden';
                    /*if(!setActiveIndex) {
                        obj.setState({pageIndex : pageIndex+1});
                    }*/
                }

                /*************** For growth enabled view only for picking up values from parent form *******/
                let linkedFields = this.props.masterid;
                let linkedFieldId = '';
                let parentformvalues = this.props.getFormValues.formvalues; // Parent record values

                console.log(linkedFields)
                console.log(page.components)
                /*************** For growth enabled view only for picking up values from parent form End*******/
                let showlistField = [];
                if (this.props.hasOwnProperty("type")) {
                    //if(this.props.action === "edit") {
                    this.props.masterid.map(list => {
                        showlistField.push(list.masterid);
                        if (list.type === 'fieldset') {

                        }
                    });
                    //}
                }

                console.log(showlistField)

                //if(page.accessRestriction !== 'hidden') {
                page.components.map((item, index) => {
                    item.name = "form_child_" + item.masterid;
                    item.id = item.name;
                    item.pageIndex = pageIndex;
                    item.temp_value = [];
                    item.fieldLabel = (item.display_label && item.display_label !== '') ? item.display_label : item.label;

                    let presetValues = this.state.presetValues;
                    let preVals = presetValues.filter(pv => parseInt(pv.field) === parseInt(item.masterid));


                    if (preVals.length > 0) {
                        item.setdefaultval = preVals[0].value;
                        //console.log(item.setdefaultval)
                    }


                    /************** For retaining the autocomplete field selection if the linked field is a text ***********/
                    let searchval = '';
                    if (linkedFields) {
                        let matchedSubFields = linkedFields.filter(lField => lField.default && lField.default !== '' && item.masterid === lField.masterid);
                        if (matchedSubFields.length > 0) {
                            searchval = matchedSubFields[0].value;
                            linkedFieldId = matchedSubFields[0].default;
                            if (linkedFieldId !== '') {// Taking the value from base form record which is clicked
                                let pfvalues = parentformvalues.filter(pfval => pfval.masterid === linkedFieldId);
                                if (pfvalues.length > 0) {
                                    searchval = (pfvalues[0].values && pfvalues[0].values.length > 0) ? pfvalues[0].values[0].value : '';
                                }

                            }
                        }
                    }

                    //console.log(linkedFields)

                    /************** For retaining the autocomplete field selection if the linked field is a text ***********/


                    if (item.type === 'location') {
                        this.initialLocationAlert(item);
                        latitudeval = this.state.latitudeval;
                        longitudeval = this.state.longitudeval;
                    }
                    if (item.type === 'fieldset') {
                        if (index === 0) {
                            obj.setState({initialContainerClass: "fieldset"});
                        }
                        obj.state.fieldsetIndices.push(index - 1);
                    }

                    item.showInForm = "No";

                    if (settingsType === 'page' && (page.accessRestriction === 'readonly' || page.accessRestriction === 'writable')) {
                        item.showInForm = "Yes";
                    }

                    item.readOnly = false;
                    if (((!item.visibility || item.visibility === 'visible') && (!item.conditionalhide || item.conditionalhide === 'No')) || writableFields.includes(item.masterid) || readOnlyFields.includes(item.masterid)) {
                        item.showInForm = "Yes";
                    }
                    if (item.type === 'fieldset') {
                        let fieldsetId = item.masterid;
                        //let childComponents = page.components.filter( item => (parseInt(item.parentid) === parseInt(fieldsetId)));
                        let visibleChildComponents = page.components.filter(item => (parseInt(item.parentid) === parseInt(fieldsetId)) && ((!item.visibility || item.visibility === 'visible') && (!item.conditionalhide || item.conditionalhide === 'No')));
                        if (visibleChildComponents.length === 0) {
                            item.showInForm = 'No'
                        }

                        if (showlistField.includes(item.masterid)) {
                            let fchld = item.child || [];
                            fchld.map(chl => {
                                showlistField.push(chl)
                            })
                        }
                    }
                    if (settingsType === 'field' && item.linked === 'Yes' && (writableFields.includes(item.masterid) || readOnlyFields.includes(item.masterid))) {
                        let fieldsetId = item.parentid;
                        let fieldsetComponent = page.components.filter(item => (parseInt(item.masterid) === parseInt(fieldsetId)));
                        if (fieldsetComponent.length > 0) {
                            fieldsetComponent[0].showInForm = "Yes";
                            if (fieldsetComponent[0].linked === "Yes") {
                                let fieldsetPId = fieldsetComponent[0].parentid;
                                let fieldsetComponentParent = page.components.filter(citem => (parseInt(citem.masterid) === parseInt(fieldsetPId)));
                                fieldsetComponentParent[0].showInForm = "Yes";
                            }
                        }
                    }

                    if (hiddenFields.includes(item.masterid)) {
                        item.showInForm = "No";
                    }
                    if (this.state.formTemplateType === 'typeform' && (item.type === 'uniqueid' || item.type === 'location' || item.type === 'loggedin')) {
                        item.showInForm = "No";
                    }

                    if (showlistField.includes(item.masterid)) {
                        item.showInForm = "Yes";
                    } else {
                        item.showInForm = "No";

                    }

                    if (readOnlyFields.includes(item.masterid)) {
                        item.readOnly = true;
                    }
                    if (!this.state.collaborator || this.state.collaborator === false) {
                        if (item.lockonadd === 'Yes' && (!this.props.editId || this.props.editId === '')) {
                            item.readOnly = true;
                            this.setState({locationalert: false});
                        }
                        if (item.lockonedit === 'Yes' && this.props.editId && this.props.editId !== '') {
                            item.readOnly = true;
                            this.setState({locationalert: false});
                        }
                    }
                    if (item.type === 'dropdown') {
                        let options = item.option;
                        item.optionvalues = options;
                        let optionObj = [];
                        for (let i = 0; i < options.length; i++) {
                            optionObj.push({value: options[i]});
                        }
                        item.options = optionObj;

                    } else if (item.type === 'switch' || item.type === 'checkbox' || item.type === 'check_image') {
                        if (item.optionstatus) {
                            let options = item.option;
                            let optionValues = [];
                            if (item.optionstatus === "textoption") {
                                for (let i = 0; i < options.length; i++) {
                                    optionValues.push(options[i].text);
                                    options[i].value = options[i].text;
                                }
                            } else if (item.optionstatus === "imageoption") {
                                for (let i = 0; i < options.length; i++) {
                                    optionValues.push(options[i].image);
                                    options[i].value = options[i].image;
                                }
                            }
                            item.optionvalues = optionValues;
                            item.options = options;
                        } else {
                            let options = item.option;
                            item.optionvalues = options;
                            let optionObj = [];
                            for (let i = 0; i < options.length; i++) {
                                optionObj.push({value: options[i]});
                            }
                            item.options = optionObj;
                        }


                    }
                    if (item.type === 'autofill' || item.type === 'checkbox' || item.type === 'switch' || item.type === 'check_image' || (item.type === 'dropdown' && item.externallink === 'No')) {
                        item.selectedItems = [];
                        item.selectedVals = [];
                        item.value = [];
                        if (item.setdefaultval && item.setdefaultval !== '') {
                            if (item.optionstatus) {
                                let selOption = [];
                                if (item.optionstatus === 'textoption') {
                                    selOption = item.options.filter((opt) => opt.value === item.setdefaultval);
                                } else if (item.optionstatus === 'imageoption') {
                                    selOption = item.options.filter((opt) => opt.value === item.setdefaultval);
                                }
                                item.selectedItems = selOption;
                                item.selectedVals = [item.setdefaultval];
                                item.value = item.selectedItems;
                            } else {
                                item.selectedItems.push({value: item.setdefaultval});
                                item.selectedVals.push(item.setdefaultval);
                                item.value = item.selectedItems;
                            }
                        }
                    }
                    if (item.type === 'agree') {
                        item.value = '';
                    }
                    if (item.type === 'otp' || item.type === 'payment') {
                        if (this.props.editId) {
                            item.showInForm = "No";
                        } else {
                            item.showInForm = "Yes";
                        }
                    }

                    if (item.externallink && item.externallink === 'Yes') {
                        //console.log(item);
                        //console.log(searchval)
                        obj.getExternalValues(item.masterid, item.externalfield, searchval);
                        if (item.type === 'dropdown' || item.type === 'checkbox' || item.type === 'check_image') {
                            item.multipleValues = "Yes";
                        }
                        item.selectedItems = [];
                        item.selectedVals = [];
                        item.value = [];
                    } else {
                        item.value = searchval;
                    }
                    if (item.addmuliple === 'Yes') {
                        item.showPlusBtn = true;
                    }

                    //Autocapture field
                    if (item.type === 'datepicker' && item.autocapture && item.autocapture === 'Yes') {
                        item.readOnly = true;
                        var date = new Date();
                        item.value = '';

                        if (item.capturemode) {
                            item.datechange = false; // for backend
                            if (item.capturemode === 'insert') {
                                if (!this.props.editId) {
                                    item.datechange = true;// for backend
                                    item.value = item.datetime && item.datetime === 'Yes' ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment(date).format("YYYY-MM-DD");
                                }
                            } else if (item.capturemode === 'update') {
                                if (this.props.editId) {
                                    item.datechange = true;// for backend
                                    item.value = item.datetime && item.datetime === 'Yes' ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment(date).format("YYYY-MM-DD");
                                }
                            } else {
                                item.datechange = true;// for backend
                                item.value = item.datetime && item.datetime === 'Yes' ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment(date).format("YYYY-MM-DD");
                            }
                        } else {
                            if (!this.props.editId) {
                                item.datechange = true;// for backend
                                item.value = item.datetime && item.datetime === 'Yes' ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment(date).format("YYYY-MM-DD");
                            }
                        }

                        if (item.onchangeaction && item.onchangeaction === 'Yes') {
                            item.value = '';
                            if (item.onchangefield && item.onchangefield !== '' && item.onchangevalue && item.onchangevalue !== '') {
                                page.components.filter(list => list.masterid === item.onchangefield).map(fld => {
                                    fld.triggerOnChangeField = true;
                                    if (fld.triggeredField) {
                                        if (fld.triggeredField.indexOf(item.masterid) < 0) {
                                            fld.triggeredField.push(item.masterid);
                                        }
                                    } else {
                                        fld.triggeredField = [];
                                        fld.triggeredField.push(item.masterid);
                                    }
                                    if (fld.value !== item.onchangevalue) {
                                        item.value = '';
                                    }
                                })
                            }
                        }
                    }

                    if (item.customapi && item.customapi === 'Yes') {
                        let customapiparam = item.customapiparam;
                        if (customapiparam.length > 0) {
                            let customapi = customapiparam[0];

                            let params = {};
                            let apiparam = customapi.apiparam;
                            if (apiparam.length > 0) {
                                for (var ap = 0; ap < apiparam.length; ap++) {
                                    let apiparamvalue = apiparam[ap].value;
                                    if (apiparamvalue.startsWith("{")) {
                                        let apiparamfield = apiparamvalue.replace('{', '');
                                        apiparamfield = apiparamfield.replace('}', '');

                                        page.components.filter(cmp => cmp.masterid === apiparamfield).map(fld => {
                                            //console.log(item.masterid)
                                            fld.triggerCustomApiOnChange = true;
                                            fld.triggeredField = fld.triggeredField || [];
                                            fld.triggeredField.push(item.masterid);
                                            apiparamvalue = fld.value;
                                            return fld;
                                        }, apiparamvalue);
                                        //params['check_field'] = apiparamfield;
                                        //params['check_value'] = '';
                                        params[apiparam[ap].param] = apiparamvalue;

                                    } else {
                                        params[apiparam[ap].param] = apiparamvalue;
                                    }

                                }
                            }


                            let customApiParams = {
                                method: customapi.apimthod,
                                url: customapi.apiname,
                                args: params
                            }
                            item.customApiParams = customApiParams;

                            this.triggerCustomApi(item.masterid, item);
                        }

                    }
                    if (item.addmuliple === 'Yes') {
                        item.showPlusBtn = true;
                        item.keepButtonOnTop = true;
                    }

                    if (item.type === 'computedfield') {
                        let formula = item.formula;
                        if (formula.length > 0) {
                            for (var b = 0; b < formula.length; b++) {
                                if (formula[b].type === 'field') {
                                    page.components.filter(item => item.masterid === formula[b].value).map(fld => {
                                        fld.triggerOnChange = true;
                                        if (fld.triggeredField) {
                                            if (fld.triggeredField.indexOf(item.masterid) < 0) {
                                                if (item.masterid !== fld.masterid)
                                                    fld.triggeredField.push(item.masterid);
                                            }
                                        } else {
                                            fld.triggeredField = [];
                                            if (item.masterid !== fld.masterid)
                                                fld.triggeredField.push(item.masterid);
                                        }

                                        return fld;
                                    })
                                }
                            }
                        }
                    }

                    if (item.type === 'datecompute') {
                        let formula = item.formula;
                        if (formula.length > 0) {
                            for (var b = 0; b < formula.length; b++) {
                                if (formula[b].type === 'field') {
                                    page.components.filter(item => item.masterid === formula[b].value).map(fld => {
                                        fld.triggerOnSelect = true;
                                        if (fld.triggeredField) {
                                            if (fld.triggeredField.indexOf(item.masterid) < 0) {
                                                fld.triggeredField.push(item.masterid);
                                            }
                                        } else {
                                            fld.triggeredField = [];
                                            fld.triggeredField.push(item.masterid);
                                        }

                                        return fld;
                                    })

                                }
                            }
                        }
                    }

                    if (item.type === 'age') {
                        let currentage = item.currentage;
                        if (currentage !== '' && currentage !== 0) {
                            page.components.filter(item => item.masterid === currentage).map(fld => {
                                fld.triggerOnDate = true;
                                if (fld.triggeredField) {
                                    if (fld.triggeredField.indexOf(item.masterid) < 0) {
                                        fld.triggeredField.push(item.masterid);
                                    }
                                } else {
                                    fld.triggeredField = [];
                                    fld.triggeredField.push(item.masterid);
                                }

                                return fld;
                            })

                        }
                    }

                    //Embed Form - External visibility - Yes

                    return item;
                });

                if (settingsType === 'field') {
                    let editableFields = page.components.filter(item => (item.readOnly === false && item.showInForm === "Yes"));
                    if (editableFields.length > 0) {
                        page.accessRestriction = 'writable';
                    }
                }
                let visibleComponents = page.components.filter(item => item.showInForm === "Yes");
                if (visibleComponents.length === 0) {
                    page.accessRestriction = 'hidden';
                }
                return page;
                /*}
                else {
                    return false;
                }*/
            }, obj);

            formPages = allFormPages.filter(page => page.accessRestriction !== 'hidden');

            //formPages = allFormPages.filter(item => item !== false);
            formSubmitIndex = formPages.length - 1;
            let pageIndex = this.state.pageIndex;
            if (this.state.formTemplateType === 'cortiza') {
                //alert("uuu")
                pageIndex = formSubmitIndex;
            }
            /*if(this.state.singlePageForm && getUrlSegment(1) === 'chat' ){
                formSubmitIndex = pIndex ? pIndex : 0;
            }*/

            this.setState({
                formPages: formPages, allFormPages: allFormPages, formSubmitIndex: formSubmitIndex,
                pageIndex: pageIndex,
                latitudeval: latitudeval,
                longitudeval: longitudeval


            }, function () {
                this.setFormValues();
            });
        }

    }

    triggerCustomApi = (fieldID, matchedComponent, fieldValue) => {
        let formPages = [...this.state.formPages];
        if (!matchedComponent) {
            let matchedComponentArr = formPages.map(page => page.components.filter(comp => (comp.masterid === fieldID)));
            matchedComponentArr = [].concat.apply([], matchedComponentArr);
            matchedComponent = matchedComponentArr[0];
        }
        this.execCustomApi(fieldID, matchedComponent, fieldValue);
        /* let beta = this;
         setTimeout(function() {
             beta.execCustomApi(fieldID, matchedComponent, fieldValue);
         },300);*/

    }

    execCustomApi = (fieldID, matchedComponent, fieldValue) => {
        if (matchedComponent) {
            let customApiParams = matchedComponent.customApiParams;


            let customapiparam = matchedComponent.customapiparam;
            if (customapiparam.length > 0) {
                let customapi = customapiparam[0];
                let apiparam = customapi.apiparam;
                if (apiparam.length > 0) {
                    for (var ap = 0; ap < apiparam.length; ap++) {
                        let apiparamvalue = apiparam[ap].value;
                        if (apiparamvalue.startsWith("{")) {
                            customApiParams.args[[apiparam[ap].param]] = fieldValue;
                        }
                    }
                }

            }
            if (fieldValue && fieldValue !== '') {
                this.props.triggerCustomApi(customApiParams, fieldID);

            }
        }
    }
    getCurrentlocation = () => {
        let obj = this
        navigator.geolocation.getCurrentPosition(function (position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            obj.setState({sGeo: true, latitudeval: lat, longitudeval: lng})
        });
    }

    sortJson(formPages) {
        let sortedComponents = [];
        formPages.map(function (page) {
            let components = page.components;
            sortedComponents = components;
            for (var i = 0; i < components.length; i++) {
                let fieldsetIndex = -1;
                let fieldsetId;
                if (components[i].type === 'fieldset') {
                    fieldsetIndex = i;
                    let fieldsetId = components[i].masterid;
                    let children = components[i].child;
                    let prevIndex = fieldsetIndex;
                    for (let j = 0; j < sortedComponents.length; j++) {
                        if (sortedComponents[j].linked === 'Yes' && parseInt(sortedComponents[j].parentid) === parseInt(fieldsetId)) {
                            if (j === parseInt(prevIndex) + 1) {
                                prevIndex = j;
                            } else {
                                sortedComponents.splice(prevIndex + 1, 0, sortedComponents[j]);
                                sortedComponents.splice(j + 1, 1);
                                prevIndex = prevIndex + 1;
                            }
                        }
                    }
                } else {

                }
            }
            page.components = sortedComponents;
        });
        return formPages;
    }

    setFormValues() {
        //console.log(this.props.getFormData.formvalues)
        if (this.props.getFormData.formvalues && this.props.getFormData.formvalues.length > 0) {

            let formValues = this.props.getFormData.formvalues;
            let formPages = [...this.state.formPages];

            formPages.map((page, pageIndex) => page.components.filter(comp => (comp.type !== 'fieldset')).map((item, fieldIndex) => {
                if (formValues.length > 0) {
                    let matchedField = formValues.filter(formItem => formItem.masterid === item.masterid);
                    if (item.linked === 'Yes') {  // Fields inside fieldset

                        let children = formValues.filter(formItem => parseInt(formItem.masterid) === parseInt(item.parentid));
                        let fieldsetvalues = [];

                        //matchedField = this.setFieldsetValues(item, children, formValues);
                        if (children.length > 0) {
                            let fieldsetvalues = children[0].values;
                            fieldsetvalues = Array.isArray(fieldsetvalues) ? fieldsetvalues : [];
                            matchedField = fieldsetvalues.filter(formItem => formItem.masterid === item.masterid);
                        } else {
                            let childrenFields = formValues.map(vals => {
                                let fieldValues = [];
                                if (vals.values && vals.values !== '' && Array.isArray(vals.values)) {
                                    fieldValues = Array.isArray(vals.values) ? vals.values : [];
                                    let isObject = fieldValues.length > 0 && fieldValues[0] !== null && fieldValues[0] !== undefined && typeof fieldValues[0] === 'object' && fieldValues[0].constructor === Object;
                                    return (isObject) ?
                                        fieldValues.filter(formItem => parseInt(formItem.masterid) === parseInt(item.parentid)) : []
                                } else {
                                    return [];
                                }
                            });

                            if (childrenFields.length > 0) {
                                childrenFields = childrenFields.filter(list => list.length > 0);
                                if (childrenFields.length > 0) {
                                    if (childrenFields[0].length > 0) {
                                        fieldsetvalues = childrenFields[0][0].values;
                                        fieldsetvalues = Array.isArray(fieldsetvalues) ? fieldsetvalues : [];
                                        matchedField = fieldsetvalues.filter(formItem => formItem.masterid === item.masterid);
                                    }
                                }
                            }
                        }
                    }
                    let matchedValues = (matchedField.length > 0 && matchedField[0] && matchedField[0].values) ? matchedField[0].values : [];

                    /*if(matchedValues.length > 0 && item.externallink && item.externallink === 'Yes') {
                        matchedValues = matchedValues.filter(val => val.recordid !== '');
                    }*/
                    this.assignFieldValues(matchedValues, item);
                } else {
                    if (item.type === 'attachment' || item.type === 'photocapture' || item.type === 'checkbox' || item.type === 'dataload' || item.type === 'check_image' || item.type === 'dropdown') {
                        item.value = item.selectedItems;
                    } else {
                        item.value = "";
                    }

                }
                return item;
            }));
            this.setState({formPages: formPages},
                function () {
                    this.reConstructJsonForForm();
                    if (this.props.editId) {
                        var elements = document.getElementsByClassName("focus-ele");
                        if (elements.length > 0) {
                            let element = elements[0];
                            element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                        }
                    }
                });
            this.calculateComputedModfiydate(formPages);
        }
    }


    assignFieldValues = (matchedValues, item) => {
        let resultset = matchedValues;
        let mappedFields = this.props.masterid || [];
        if (matchedValues.length > 0) {
            if (matchedValues.length > 1) {
                matchedValues = matchedValues.filter(mv => (mv.refIndexNo && mv.refIndexNo === 0) || (!mv.refIndexNo));
            }

            if (item.externallink && item.externallink === 'Yes') {
                if (item.externalValues) {
                    let selectedValueArr = matchedValues.filter(sel => sel.recordid !== '');
                    /*let selectedValueArr = matchedValues.map(sel => {
                        let selectedOptionArr = item.options.filter( opt => (opt.recordid === sel.recordid && sel.recordid !== ''));
                        let selectedOptions = selectedOptionArr.length > 0 ? selectedOptionArr[0] : "";
                        return selectedOptions;
                    });

                    selectedValueArr = selectedValueArr.filter(selV => selV !== "");*/
                    item.selectedItems = selectedValueArr;
                    item.selectedItems = Array.isArray(item.selectedItems) ? item.selectedItems : [];
                    item.value = item.selectedItems;
                    item.selectedVals = item.selectedItems.map(sel => {
                        return sel.value;
                    });
                }
                item.value = matchedValues;
                //console.log(item.value)
            } else if (item.type === 'attachment' || item.type === 'photocapture') {
                item.value = [];
                if (matchedValues[0] !== undefined) {
                    item.value.push(matchedValues[0]);
                    if (item.type === 'photocapture') {
                        item.selectedAction = matchedValues[0].type ? (matchedValues[0].type === 'capture' ? matchedValues[0].type : 'attach') : '';
                    }
                }
            } else if (item.type === 'checkbox' || item.type === 'switch' || item.type === 'check_image') {
                if (item.optionstatus) {
                    item.value = [];
                    let this_value_obj = matchedValues.filter(mv => (mv.refIndexNo && mv.refIndexNo === 0) || (!mv.refIndexNo));

                    if (this_value_obj.length > 0) {
                        this_value_obj = this_value_obj.filter(vals => item.optionvalues && item.optionvalues.includes(vals.value));
                    }
                    item.selectedItems = this_value_obj;
                    let selectedVals = this_value_obj.map((val, ind) => {
                        return val.value;
                    })
                    item.selectedVals = selectedVals;
                    item.value = this_value_obj;
                } else {
                    item.value = [];
                    let this_value_obj = matchedValues.filter(mv => (mv.refIndexNo && mv.refIndexNo === 0) || (!mv.refIndexNo));
                    if (this_value_obj.length === 0) {
                        this_value_obj = [matchedValues[0]];
                    }
                    if (this_value_obj.length > 0) {
                        this_value_obj = this_value_obj.filter(vals => item.optionvalues && item.optionvalues.includes(vals.value));
                    }
                    let this_value = this_value_obj.length > 0 ? this_value_obj[0] : {};

                    //item.value.push(this_value);
                    item.selectedItems = this_value_obj;
                    let selectedVals = this_value_obj.map((val, ind) => {
                        return val.value;
                    })
                    item.selectedVals = selectedVals;
                    item.value = this_value_obj;
                }
            } else if (item.type === 'agree' || item.type === 'dropdown') {
                item.value = [];
                let this_value_obj = matchedValues.filter(mv => (mv.refIndexNo && mv.refIndexNo === 0) || (!mv.refIndexNo))
                if (this_value_obj.length === 0) {
                    this_value_obj = [matchedValues[0] ? matchedValues[0] : []];
                }
                if (this_value_obj.length > 0) {
                    this_value_obj = this_value_obj.filter(vals => item.optionvalues && item.optionvalues.includes(vals.value));
                }
                let this_value = this_value_obj.length > 0 ? this_value_obj[0] : {};

                item.selectedItems = this_value_obj;

                let selectedVals = this_value_obj.map((val, ind) => {
                    return val.value;
                })

                item.selectedVals = selectedVals;
                item.value = this_value_obj;

                if (item.type === 'agree') {
                    item.value = matchedValues[0].value === 'no' ? '' : 'yes';
                }

            } else if (item.type === 'dataload') {
                item.value = Array.isArray(matchedValues) ? matchedValues : [];
                if (matchedValues) {
                    this.setState({
                        dataloadaggs: matchedValues[0] ? matchedValues[0].aggs : ''
                    })
                }
            } else {
                item.value = matchedValues[0] ? matchedValues[0].value : '';
            }


            if (item.type === 'datepicker' && item.autocapture && item.autocapture === 'Yes' && this.props.editId && this.props.editId !== '') {
                var date = new Date();
                if (item.capturemode) {
                    if (item.onchangeaction && item.onchangeaction === 'Yes') {
                        if (item.value !== '') {
                            item.value = item.datetime && item.datetime === 'Yes' ? moment.utc(item.value).local().format('YYYY-MM-DD HH:mm:ss') : moment(item.value).format('YYYY-MM-DD');
                        }
                    } else {
                        if (item.capturemode === 'update' || item.capturemode === 'both') {
                            item.value = (item.datetime && item.datetime === 'Yes') ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment(date).format("YYYY-MM-DD");
                        } else {
                            if (item.value !== '') {
                                item.value = item.datetime && item.datetime === 'Yes' ? moment.utc(item.value).local().format('YYYY-MM-DD HH:mm:ss') : moment(item.value).format('YYYY-MM-DD');
                            }
                        }

                    }
                }
            }

            if (item.type === 'datecompute') {
                item.value = this.evaluateFormulaDate(item, this.props.getFormData.meta);
                if (Array.isArray(resultset) && resultset.length > 0) {
                    resultset[0].value = item.value;
                }
            }

            if (item.triggerOnChange) { //Computed field
                //console.log("triggeronchngeg")
                // console.log(thisComponent.masterid)
                let triggeredField = item.triggeredField;
                for (var i = 0; i < triggeredField.length; i++) {
                    this.calculateComputedValue(triggeredField[i]);
                }
            }

            item.temp_value = resultset;

            if (item.setcondition === 'Yes' && mappedFields.includes(item.masterid)) {
                this.renderByCondition(item, item.value)
            }
        } else {
            if (item.type === 'attachment' || item.type === 'checkbox' || item.type === 'switch' || item.type === 'dataload' || item.type === 'check_image' || item.type === 'agree' || item.type === 'dropdown') {
                item.value = item.selectedItems;
            } else {
                item.value = '';
            }
        }
    }

    setFieldsetValues = (item, children, formValues) => {
        let matchedField = [];
        if (children.length > 0) {
            let fieldsetvalues = children[0].values;
            fieldsetvalues = Array.isArray(fieldsetvalues) ? fieldsetvalues : [];
            matchedField = fieldsetvalues.filter(formItem => formItem.masterid === item.masterid);
        } else {
            let childrenFields = formValues.map(vals => {
                let fieldValues = [];
                if (vals.values && vals.values !== '' && Array.isArray(vals.values)) {
                    fieldValues = Array.isArray(vals.values) ? vals.values : [];
                    let isObject = fieldValues.length > 0 && fieldValues[0] !== null && fieldValues[0] !== undefined && typeof fieldValues[0] === 'object' && fieldValues[0].constructor === Object;
                    return (isObject) ?
                        fieldValues.filter(formItem => parseInt(formItem.masterid) === parseInt(item.parentid)) : []

                } else {
                    return [];
                }
            });
            if (childrenFields.length > 0) {
                childrenFields = childrenFields.filter(list => list.length > 0);
                if (childrenFields.length > 0) {
                    if (childrenFields[0].length > 0) {
                        let fieldsetvalues = childrenFields[0][0].values;
                        matchedField = this.setFieldsetValues(item, fieldsetvalues, formValues);
                    }
                }
            }
        }
        return matchedField;
    }
    reConstructJsonForForm = () => {

        if (this.props.getFormData.formvalues) {
            let formValues = this.props.getFormData.formvalues;
            let formPages = [...this.state.formPages];
            let vPage = {};
            let prevPageIndex = -1;
            formValues.map((vfield, vIndex) => {
                let matchedField = {};
                let pageIndex = 0;

                formPages.map((vPage, vPageIndex) => {
                    if (prevPageIndex !== vPageIndex) {
                        newFindex = 0;
                        prevPageIndex = vPageIndex;
                    }

                    matchedField = vPage.components.filter(item => parseInt(item.masterid) === parseInt(vfield.masterid));
                    if (matchedField.length > 0) {
                        matchedField = matchedField[0];
                        newFindex = this.findFieldIndex(vPageIndex, matchedField);
                        let children = [];
                        if (vfield.type === 'fieldset') {
                            children = vPage.components.filter(item => item.linked === 'Yes' && parseInt(item.parentid) === parseInt(vfield.masterid));
                            this.buildFSJson(vPageIndex, matchedField, children);
                            //newFindex = newFindex+1;
                            return false;
                        } else {
                            this.buildFieldJson(vPageIndex, matchedField);
                            //newFindex = newFindex+1;
                        }

                    }
                }, {matchedField: matchedField});

            });
        }
    }

    buildFSJson = (pageIndex, field, children) => {
        let innerFieldsets = children.filter(ch => ch.type === 'fieldset');
        let containsFieldset = innerFieldsets.length > 0 ? true : false;
        let fieldSetCount = 1;
        let showSlNo = false;

        let formPages = [...this.state.formPages];
        let page = formPages[pageIndex];

        fieldSetCount = this.findFieldSetCount(children, innerFieldsets, containsFieldset, page);
        let isMultipleFieldSet = fieldSetCount > 1 && (field.linked === 'No');
        let fieldCount = 1;
        if (isMultipleFieldSet) {
            showSlNo = true;
            fieldCount = field.temp_value.length;
            for (var i = 1; i < fieldSetCount; i++) {

                /*let newFieldIndexArr = page.components.map((fld, ind) => {
                    return (fld.masterid === field.masterid) ? ind : '-1';
                });
                newFieldIndexArr = newFieldIndexArr.filter(item => item> -1);
                if(newFieldIndexArr.length > 0) {
                    fieldIndex = newFieldIndexArr[0]
                }*/
                this.appendElement(pageIndex, newFindex, field, i, 'no');
            }
            newFindex = newFindex + fieldSetCount;
        }
    }

    buildFieldJson = (pageIndex, field) => {
        let isMultipleField = (Array.isArray(field.temp_value)) && (field.temp_value.length > 1) && (field.linked === 'No');
        if ((field.externallink && field.externallink === 'Yes') || (field.type === 'checkbox') || (field.type === 'check_image') || (field.type === 'dropdown' && field.multipleselection && field.multipleselection.toLowerCase() === 'yes') || (field.type === 'attachment' && field.browseresource && field.browseresource === 'Yes')
            || (field.linked === 'No' && field.addmuliple === 'No')
        ) {
            isMultipleField = false;
        }

        let fieldCount = 1;
        let showSlNo = false;
        if (isMultipleField) {
            let temp_value = field.temp_value;

            showSlNo = true;
            const itemByIndex = temp_value.reduce((acc, value) => {

                acc[value.refIndexNo] = acc[value.refIndexNo] || [];
                acc[value.refIndexNo].push(value)
                return acc;
            }, []);

            fieldCount = itemByIndex.length;

            for (var i = 1; i < fieldCount; i++) {
                this.appendElement(pageIndex, newFindex, field, i, 'no');
            }
            newFindex = newFindex + fieldCount;
        }
    }

    findFieldIndex = (pageIndex, this_component) => {
        let formPages = [...this.state.formPages];
        let page = formPages[pageIndex];
        let masterid = this_component.masterid;
        var index_arr = formPages[pageIndex].components.map((item, itemIndex) => {
            if (parseInt(masterid) === parseInt(item.masterid)) {
                return itemIndex;
            } else {
                return -1;
            }
        });
        let filtered_arr = index_arr.filter(item => parseInt(item) > -1);
        let cfIndex = filtered_arr.length > 0 ? filtered_arr[0] : -1;
        return cfIndex;
    }

    setDataLoadFields() {
        var fPages = (this.state.moduleName === 'entity') ? this.props.entityFormInfo.getformcomponents.pages : this.props.formInfo.getformcomponents.pages;
        if (fPages) {
            let dataloadFields = [];
            for (var i = 0; i < fPages.length; i++) {
                let numericFields = fPages[i].components.filter(item => (item.type === 'number'));
                dataloadFields.push(numericFields);
            }
            dataloadFields = [].concat.apply([], dataloadFields);
            this.setState({dataloadFields: dataloadFields});
        }
    }

    renderByCondition(field, inputValue) {

        let fieldValues = [];
        if (field.type === 'checkbox' || field.type === 'check_image') {
            inputValue = field.value;
        }
        if (Array.isArray(inputValue)) {
            let fieldValue = '';
            for (var i = 0; i < inputValue.length; i++) {
                fieldValue = inputValue[i].value ? inputValue[i].value : '';
                fieldValues.push(fieldValue);

            }
        } else {
            fieldValues.push(inputValue);
        }
        let conditionArr = [];
        if (fieldValues.length > 0) {
            for (var i = 0; i < fieldValues.length; i++) {
                let fieldValue = fieldValues[i];
                if (Number.isInteger(fieldValue)) {
                    fieldValue = parseInt(fieldValue);
                    conditionArr = field.fieldcondition.filter(item => fieldValues.includes(parseInt(item.optionvalue))).map(a => a.condition);
                } else {
                    conditionArr = field.fieldcondition.filter(item => fieldValues.includes(item.optionvalue)).map(a => a.condition);
                }


                let fieldsToAdd = [];
                if (conditionArr.length > 0) {
                    fieldsToAdd = [].concat.apply([], conditionArr);
                }
                let removedEleArr = field.fieldcondition.filter(item => !fieldValues.includes(item.optionvalue)).map(a => a.condition);
                let fieldsToRemove = [];

                if (removedEleArr.length > 0) {
                    fieldsToRemove = [].concat.apply([], removedEleArr);
                    fieldsToRemove = fieldsToRemove.filter(value => !fieldsToAdd.includes(value));
                }

                if (fieldsToAdd.length > 0 || fieldsToRemove.length > 0) {
                    for (var k = 0; k < fieldsToRemove.length; k++) {
                        let matchedFields = this.state.formPages[this.state.pageIndex].components.filter(item => (item.masterid.toString() === fieldsToRemove[k].toString()));
                        if (matchedFields.length > 0) {
                            if (!fieldsToAdd.includes(matchedFields[0])) {
                                if (!this.props.editId) {
                                    this.resetFieldValues(matchedFields[0].id, this.state.pageIndex);
                                }
                                this.renderByCondition(matchedFields[0], '');
                            }
                        }
                    }
                    this.modifyFields(fieldsToAdd, fieldsToRemove);
                }

            }

        } else {
            let conditionArr = field.fieldcondition.map(a => a.condition);
            let fieldsToRemove = [];

            if (conditionArr.length > 0) {
                fieldsToRemove = [].concat.apply([], conditionArr);
                if (fieldsToRemove.length > 0) {
                    for (var k = 0; k < fieldsToRemove.length; k++) {
                        let matchedFields = this.state.formPages[this.state.pageIndex].components.filter(item => (item.masterid.toString() === fieldsToRemove[k].toString()));
                        if (matchedFields.length > 0) {

                            if (!this.props.editId) {
                                this.resetFieldValues(matchedFields[0].id, this.state.pageIndex);
                            }
                            this.renderByCondition(matchedFields[0], '');
                        }
                    }
                    this.modifyFields([], fieldsToRemove);
                }
            }

        }


    }

    getExternalValues = (masterid, externals, search = '') => {
        let collectionIds = [];
        let fieldIds = [];
        let displayFieldIds = [];
        let additionalvaluesIds = [];
        let filterFields = [];
        let filterids = [];
        if (externals.length > 0) {
            for (var i = 0; i < externals.length; i++) {
                collectionIds.push(externals[i].collection);
                fieldIds.push(externals[i].field);
                if (externals[i].display && externals[i].display.length > 0) {
                    displayFieldIds = displayFieldIds.concat(externals[i].display);
                }
                if (externals[i].displayvalue && externals[i].displayvalue.length > 0) {
                    additionalvaluesIds = additionalvaluesIds.concat(externals[i].displayvalue);
                }

                let filterField = {};
                if (externals[i].filter && externals[i].filter.length > 0) {
                    filterField.filters = [];
                    filterField.collection = externals[i].collection;

                    externals[i].filter.map((field, fieldIndex) => {
                        var item = {
                            field: field.filterfield,
                            type: field.type,
                            values: field.filtervalues
                        }
                        if (field.filterfield !== '' && field.filtervalues.length !== 0) {
                            filterField.filters.push(item);
                        }
                    })
                    if (filterField.filters.length !== 0) {
                        filterFields.push(filterField);
                    }
                }

                if (externals[i].searchby && externals[i].searchby === 'setvalue') {
                    filterids.push({"id": externals[i].field})
                }
            }
        }

        let displayfields = "";
        let additionalvalues = "";
        let filterfieldValues = filterFields;
        if (displayFieldIds.length > 0) {
            displayfields = displayFieldIds.toString();
        }
        if (additionalvaluesIds.length > 0) {
            additionalvalues = additionalvaluesIds.toString();
        }

        let permissionEnabled = this.props.showlinkingpermission;
        let permissionFields = this.props.showlinkingpermissionArray;
        let permittedFieldIds = this.props.showlinkingpermissionid;

        let data = {
            masterid: masterid,
            collection: collectionIds.toString(),
            field: fieldIds.toString(),
            displayfields: displayfields,
            additionalvalues: additionalvalues,
            filterfield: filterfieldValues,
            filterid: filterids,
            search: search,
            size: 1000
        };

        if (this.state.usertype !== 'admin' && this.state.usertype !== 'Superadmin' && this.state.usertype !== 'root') {
            if (permittedFieldIds && Array.isArray(permittedFieldIds) && (permittedFieldIds.includes(masterid) || permittedFieldIds.includes(masterid.toString()))) {
                data.userpermission = true;
                data.uid = this.state.uid;
            }
        }
        this.props.getExternalValues(data);
    }

    hideFormModal = () => {
        this.props.hideFormModal();
        //this.resetForm();
        newFindex = 0;
        this.resetUniqueValidations();
        let collectionid = this.props.collectionId ? this.props.collectionId : this.state.collectionid;

        // (this.state.moduleName === 'entity') ? this.props.entityshowform({ _id : collectionid, uid: this.state.uid},this.state.formaccess) : this.props.showform({ _id : collectionid, uid: this.state.uid},this.state.formaccess);
    }
    thankyoupage = () => {
        if (this.state.thankyoustatus === 'thankyou_content') {
            if (this.state.thankyoucontent !== '') {
                this.setState({thankyouMessage: true});
            } else {
                this.hideFormModal();
            }
        }
        if (this.state.thankyoustatus === 'thankyou_url') {
            window.open(this.state.thankyoulink, '_blank');
            this.hideFormModal();
        }
        if (this.state.thankyoustatus === 'thankyou_none') {
            this.hideFormModal();
        }
    }
    showAgreeCloseModal = () => {
        this.setState({thankyouMessage: !this.state.thankyouMessage})
        if (this.state.embedForm) {
            this.resetForm();
        } else {
            this.hideFormModal();

        }
    }
    hideSavedFormAlert = () => {
        this.setState({formSaved: false}, function () {
            this.resetForm();
        })
    }
    hideErrorAlert = () => {
        this.setState({errorMessageOnSaving: false})
    }
    renderFormPage = (page, pageIndex, formTemplateType) => {
        if (formTemplateType === 'cortiza') {
            return (
                <div className="each-form-container" key={"form-page-" + pageIndex}>
                    <div className="each-form-header">
                        <h5>My Header</h5>
                    </div>
                    <div className="each-form-body">
                        <div className="ind-form-field custom-template-view">
                            {page.components.map((field, fieldIndex) =>
                                <React.Fragment key={"form-field-" + fieldIndex}>

                                    {field.linked === 'No' &&
                                    <React.Fragment>
                                        {this.renderFormFields(field, fieldIndex, page, pageIndex)}
                                    </React.Fragment>
                                    }
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            )
        } else {
            return (

                <div className="form-preview-container "
                     key={"form-page-" + pageIndex}
                     style={{display: (pageIndex !== this.state.pageIndex) ? "none" : "block"}}>

                    <div className="ind-form-field custom-template-view">
                        {page.components.map((field, fieldIndex) => {

                            let parentType = '';
                            if (field.linked === 'Yes' && field.showInForm === 'Yes') {
                                let parentArr = page.components.filter(cm => cm.masterid.toString() === field.parentid.toString() && (cm.type === 'columnbreak' || cm.type === 'fieldset') && cm.showInForm === 'No');
                                console.log(parentArr)
                                console.log(page.components)
                                if (parentArr.length > 0) {
                                    parentType = parentArr[0].type;
                                }
                            }
                            return (

                                <React.Fragment key={"form-field-" + fieldIndex}>

                                    {field.linked === 'No' && field.showInForm === 'Yes' &&
                                    <React.Fragment>
                                        {(this.state.formTemplateType !== 'typeform' || (this.state.formTemplateType === 'typeform' && fieldIndex === this.state.currentFieldIndex)) &&
                                        <>
                                            {this.renderFormFields(field, fieldIndex, page, pageIndex)}
                                            {this.state.formTemplateType === 'typeform' &&
                                            <React.Fragment>
                                                {/*fieldIndex > 0 &&
                                                                    <div
                                                                        className="type-form-button-block">
                                                                        <button
                                                                            className="type-form-button"
                                                                            onClick={() => this.triggerPrevField()}> Back
                                                                        </button>
                                                                    </div>
                                                                    */}
                                                <div className="type-form-button-block">
                                                    <button className="type-form-button"
                                                            onClick={() => this.triggerNextField()}> OK
                                                    </button>
                                                    <span className="or-text">OR Press Enter</span>
                                                </div>
                                            </React.Fragment>
                                            }
                                        </>
                                        }
                                    </React.Fragment>
                                    }
                                    {field.linked === 'Yes' && field.showInForm === 'Yes' && (parentType === 'columnbreak' || parentType === 'fieldset') &&
                                    <React.Fragment>
                                        {(this.state.formTemplateType !== 'typeform' || (this.state.formTemplateType === 'typeform' && fieldIndex === this.state.currentFieldIndex)) &&
                                        <>
                                            {this.renderFormFields(field, fieldIndex, page, pageIndex)}
                                            {this.state.formTemplateType === 'typeform' &&
                                            <React.Fragment>
                                                {/*fieldIndex > 0 &&
                                                                    <div
                                                                        className="type-form-button-block">
                                                                        <button
                                                                            className="type-form-button"
                                                                            onClick={() => this.triggerPrevField()}> Back
                                                                        </button>
                                                                    </div>
                                                                    */}
                                                <div className="type-form-button-block">
                                                    <button className="type-form-button"
                                                            onClick={() => this.triggerNextField()}> OK
                                                    </button>
                                                    <span className="or-text">OR Press Enter</span>
                                                </div>
                                            </React.Fragment>
                                            }
                                        </>
                                        }
                                    </React.Fragment>
                                    }
                                </React.Fragment>
                            )
                        })}

                    </div>
                </div>
            )
        }

    }

    renderFormFields(field, fieldIndex, page, pageIndex) {
        let children = [];
        if (field.type === 'fieldset' || field.type === 'columnbreak') {
            children = page.components.filter(item => item.linked === 'Yes' && parseInt(item.parentid) === parseInt(field.masterid));
        }
        if (page.accessRestriction === 'readonly' || field.readOnly === true) {
            /*return (field.type === 'fieldset') ? this.renderFSValues(field,children, page,0) :
                this.renderFieldValues(field,0);*/

            return (field.type === 'fieldset') ?
                this.renderFieldSet(field, fieldIndex, page, pageIndex, children, 'readonly')
                : ((field.type === 'attachment' || field.type === 'editor') ? this.renderFieldValues(field, 0) : this.renderField(field, fieldIndex, page, pageIndex, 'readonly'));
        } else {
            return (field.type === 'fieldset') ?
                this.renderFieldSet(field, fieldIndex, page, pageIndex, children) :
                (field.type === 'columnbreak') ? this.renderColumnBreak(field, fieldIndex, page, pageIndex, children)
                    : this.renderField(field, fieldIndex, page, pageIndex);

        }
    }

    renderField = (field, fieldIndex, page, pageIndex, fieldNature = '') => {
        return (
            <FormFields attributes={field} fieldIndex={fieldIndex} renderByCondition={this.renderByCondition.bind(this)}
                        setFieldValues={this.setFieldValues.bind(this)}
                        checkFieldValid={this.checkFieldValid.bind(this)}
                        setSelectedValues={this.setSelectedValues.bind(this)} pageIndex={pageIndex}
                        formSubmitted={this.state.formSubmitted}
                        removeElement={this.removeElement}
                        appendElement={this.appendElement}
                        initiateDataLoad={this.initiateDataLoad}
                        getEntities={this.getEntities} getCollections={this.getCollections}
                        collectionSource={this.props.listAllCollections.collectionsubgroup}
                        entitySource={this.props.entitydata.entityList}
                        dataloadFields={this.state.dataloadFields} getAggregate={this.getAggregate}
                        aggValue={(this.state.moduleName === 'entity') ? this.props.entityFormInfo.aggData : this.props.formInfo.aggData}
                        getExternalValues={this.getExternalValues}
                        assignFieldValues={this.assignFieldValues}
                        page={page}
                        fieldNature={fieldNature}
                        calculateComputedValue={this.calculateComputedValue}
                        showUploadErrors={this.showUploadErrors}
                        onFieldKeyUp={this.onFieldKeyUp}
                        formTemplateType={this.state.formTemplateType}
                        sendOtpfield={this.sendOtpfield.bind(this)}
                        verifyOtp={this.verifyOtp.bind(this)}
                        handleInputOtpChange={this.handleInputOtpChange.bind(this)}
                        bsynOtp={this.state.bsynOtp}
                        recievedOtp={this.state.recievedOtp}
                        paymentDetails={this.paymentDetails.bind(this)}
                        payChange={this.props.payChange ? this.props.payChange : false}
                        payAmount={this.props.payChange && this.props.payAmount ? this.props.payAmount : 100}
                        collaborator={this.state.collaborator}
                        editId={this.props.editId}
                        showAddExternalValuesModal={this.showAddExternalValuesModal.bind(this)}

            />
        )
    }
    renderFieldSet = (field, fieldIndex, page, pageIndex, children, fieldNature = '') => {
        return (
            <React.Fragment>

                {(!field.singlepanel || (field.singlepanel && field.singlepanel !== 'yes') || !field.refIndexNo || field.refIndexNo === 0) &&

                <FieldSet attributes={field} fieldIndex={fieldIndex} page={page}
                          renderFormFields={this.renderFormFields.bind(this)}
                          renderByCondition={this.renderByCondition.bind(this)}
                          setFieldValues={this.setFieldValues.bind(this)} appendElement={this.appendElement}
                          removeElement={this.removeElement.bind(this)}
                          checkFieldValid={this.checkFieldValid.bind(this)}
                          setSelectedValues={this.setSelectedValues.bind(this)} children={children}
                          pageIndex={pageIndex}
                          formSubmitted={this.state.formSubmitted}
                          getExternalValues={this.getExternalValues}
                          fieldNature={fieldNature}
                          calculateComputedValue={this.calculateComputedValue}
                          showUploadErrors={this.showUploadErrors}

                />

                }

            </React.Fragment>

        )
    }

    renderColumnBreak = (field, fieldIndex, page, pageIndex, children, fieldNature = '') => {
        return (
            <React.Fragment>


                <ColumnBreak attributes={field} fieldIndex={fieldIndex} page={page}
                             renderFormFields={this.renderFormFields.bind(this)}
                             renderByCondition={this.renderByCondition.bind(this)}
                             setFieldValues={this.setFieldValues.bind(this)} appendElement={this.appendElement}
                             removeElement={this.removeElement.bind(this)}
                             checkFieldValid={this.checkFieldValid.bind(this)}
                             setSelectedValues={this.setSelectedValues.bind(this)} children={children}
                             pageIndex={pageIndex}
                             formSubmitted={this.state.formSubmitted}
                             getExternalValues={this.getExternalValues}
                             fieldNature={fieldNature}
                             calculateComputedValue={this.calculateComputedValue}
                             showUploadErrors={this.showUploadErrors}

                />

            </React.Fragment>

        )
    }

    renderFieldValues(field, parentIndex) {
        let renderedField = [];
        let isMultipleField = (Array.isArray(field.value)) && (field.value.length > 1) && (field.linked === 'No');
        let fieldCount = 1;
        let showSlNo = false;
        if ((field.externallink && field.externallink === 'Yes') || (field.type === 'checkbox') || (field.type === 'check_image')) {
            isMultipleField = false;
        }
        if (isMultipleField) {
            showSlNo = true;
            fieldCount = field.value.length;
            for (var i = 0; i < fieldCount; i++) {
                renderedField.push(this.pushRenderField(field, parentIndex + i, showSlNo))
            }
        } else {
            renderedField.push(this.pushRenderField(field, parentIndex));
        }
        return renderedField;

    }

    pushRenderField = (field, i, showSlNo) => {

        let fieldValue = field.value;
        let fieldType = field.type;
        let contentPath = '';
        let uniqvalue = '';
        let filename = '';
        if (fieldType === 'attachment') {
            uniqvalue = (fieldValue && fieldValue[0]) ? fieldValue[0].uniqvalue : '';
            contentPath = this.getAttachmentPath(uniqvalue);
            filename = (fieldValue && fieldValue[0]) ? fieldValue[0].value : uniqvalue;
            fieldValue = contentPath + uniqvalue;
        }
        return (

            <div className="form-input-container-outer " style={{display: "inline-block"}}
                 key={`render-sng-field-${i}`}>
                <div className="form-input-block-left no-action">
                    {field.fieldLabel && field.fieldLabel !== '' &&
                    <div className="label-header"><label>{field.fieldLabel} {showSlNo && (i + 1)}</label></div>
                    }
                    {field.type !== 'formheading' && field.type !== 'uniqueid' &&
                    fieldValue &&
                    <React.Fragment>
                        {Array.isArray(fieldValue) &&
                        fieldValue.map((val, ind) =>
                            this.showFieldValue(val.value, field)
                        )
                        }
                        {!Array.isArray(fieldValue) &&
                        this.showFieldValue(fieldValue, field)
                        }

                    </React.Fragment>
                    }
                </div>
                {fieldType === 'attachment' &&
                <button className="form-download"
                        onClick={() => forceDownload(contentPath + uniqvalue, filename)}></button>
                }
            </div>
        )
    }

    showFieldValue = (fieldValue, field) => {

        let fieldType = field.type;
        /*let contentPath = '';
        let uniqvalue = '';
        if(fieldType === 'attachment') {
            uniqvalue = field.value[0] ? field.value[0].uniqvalue : '';
            contentPath = this.getAttachmentPath(fieldValue);
        }*/
        let fileType = getFilePath(fieldValue);

        return (
            <React.Fragment>
                {fieldType === 'editor' &&
                < div className="form-type-input " dangerouslySetInnerHTML={{__html: fieldValue}}></div>
                }
                {fieldType === 'signature' &&
                <img className="custom-image" src={fieldValue}/>
                }
                {fieldType !== 'editor' && fieldType !== 'signature' &&
                <React.Fragment>
                    {fieldType === 'attachment' &&
                    <div className="form-type-input ">
                        {fileType === 'image' &&
                        <img src={fieldValue}/>
                        }
                        {fileType !== 'image' &&
                        fieldValue
                        }

                    </div>
                    }
                    {fieldType !== 'attachment' &&
                    <div className="form-type-input ">
                        {fieldValue}
                    </div>
                    }
                </React.Fragment>

                }
            </React.Fragment>
        )
    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch (getFilePath(type)) {
            case 'image' :
                filePath = FRMSIMG_PATH;
                break;
            case 'video' :
                filePath = FRMSVID_PATH;
                break;
            default :
                filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }

    initialLocationAlert = (item) => {

        var oldCookie = this.getCookie('locationstatus');
        if (oldCookie) {
            let formid = atob(getUrlSegment(3));
            var details = JSON.parse(oldCookie);
            var userid = ES_INDEX + getProfile().id;
            if (details.filter(list => list.formid === formid && list.userid === userid)[0]) {
                var status = details.filter(list => list.formid === formid && list.userid === userid)[0].status;
                if (status) {
                    this.setState({locationalert: false});
                    if (status === 'yes') {
                        this.getCurrentlocation();
                    } else {
                        this.setState({latitudeval: 0, longitudeval: 0})
                    }
                } else {
                    this.setState({locationalert: true});
                }
            } else {
                this.setState({locationalert: true});
            }

        } else {
            this.setState({locationalert: true});
        }
    }
    saveLocationAlert = () => {
        this.setState({locationalert: false});
        this.getCurrentlocation();
        this.locationModal('yes');

    }
    cancelLocationAlert = () => {
        this.locationModal('no');
        this.setState({locationalert: false, latitudeval: 0, longitudeval: 0})
    }

    setCookie(name, value, daysToLive) {
        document.cookie = name + "=" + value + ";" + daysToLive + ";path=/";

    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    locationModal = (stat) => {
        let formid = atob(getUrlSegment(3));
        var existingCookie = this.getCookie('locationstatus');
        if (existingCookie) {
            var details = JSON.parse(existingCookie);
            var userid = ES_INDEX + getProfile().id;
            if (details.filter(list => list.formid === formid && list.userid === userid)[0]) {
                var newdet = [{formid: formid, userid: ES_INDEX + getProfile().id, status: stat}];
                var newarray = newdet;
            } else {
                var newdet = {formid: formid, userid: userid, status: stat};
                var newarray = details.concat(newdet);
            }

        } else {
            var newdet = [{formid: formid, userid: ES_INDEX + getProfile().id, status: stat}];
            var newarray = newdet;
        }
        this.setCookie('locationstatus', JSON.stringify(newarray), '223222');

    }

    renderFSValues(field, children, page, parentIndex) {
        let renderedFieldSet = [];
        let innerFieldsets = children.filter(ch => ch.type === 'fieldset');
        let containsFieldset = innerFieldsets.length > 0 ? true : false;
        let fieldSetCount = 1;
        let showSlNo = false;

        fieldSetCount = this.findFieldSetCount(children, innerFieldsets, containsFieldset, page);
        let isMultipleFieldSet = fieldSetCount > 1 && (field.linked === 'No');

        if (isMultipleFieldSet) {
            showSlNo = true;
            for (var i = 0; i < fieldSetCount; i++) {
                renderedFieldSet.push(this.pushRenderFieldSet(field, parentIndex + i, children, showSlNo, page));
                break;
            }
        } else {
            renderedFieldSet.push(this.pushRenderFieldSet(field, parentIndex, children, showSlNo, page));
        }

        return renderedFieldSet;

    }

    findFieldSetCount = (children, innerFieldsets, containsFieldset, page) => {
        let fieldSetCount = 1;
        if (containsFieldset) { // Render child fields and fieldset
            let subFields = innerFieldsets.map(fs => {
                let innerChildren = page.components.filter(item => item.linked === 'Yes' && parseInt(item.parentid) === parseInt(fs.masterid));
                let innerSubFieldset = innerChildren.filter(ch => ch.type === 'fieldset');
                let containsSubFieldset = innerSubFieldset.length > 0 ? true : false;
                fieldSetCount = this.findFieldSetCount(innerChildren, innerSubFieldset, containsSubFieldset, page);
                fs.valuelength = fieldSetCount;
                return fs;
            });
            let childValCounts = innerFieldsets.map(ch => {
                return ch.valuelength ? ch.valuelength : 0;
            });

            fieldSetCount = Math.max.apply(null, childValCounts);
        } else {
            let multifields = ["checkbox"]
            let childValCounts = children.filter(ch_f => ((!ch_f.multipleselection || ch_f.multipleselection === 'No') && (!ch_f.multipleValues || ch_f.multipleValues === 'No') && !multifields.includes(ch_f.type)))
                .map(ch => {
                    ch.valuelength = (ch.temp_value ? (Array.isArray(ch.temp_value) ? ch.temp_value.length : 1) : 1);
                    return ch.valuelength
                });
            fieldSetCount = Math.max.apply(null, childValCounts);
        }
        return fieldSetCount;
    }

    pushRenderFieldSet = (field, i, children, showSlNo, page) => {
        if (children.length > 0) {
            return (
                <React.Fragment key={`render-fds-field-${i}`}>
                    <div className="form-input-container-outer" style={{display: "inline-block"}}>
                        <div className="form-input-block-left">
                            {field.fieldLabel && field.fieldLabel !== '' &&
                            <div className="label-header  fieldset-title">
                                <label>{field.fieldLabel} {showSlNo && (i + 1)}</label></div>
                            }
                            {
                                children.map(child => {
                                        let subchildren = page.components.filter(item => item.linked === 'Yes' && parseInt(item.parentid) === parseInt(child.masterid));
                                        return (
                                            (child.type === 'fieldset') ?
                                                this.renderFSValues(child, subchildren, page, i)
                                                : this.renderFieldValues(child, i)
                                        )
                                    },
                                    {page: page}
                                )
                            }

                        </div>
                    </div>

                </React.Fragment>
            )

        }
    }

    /*********** Conditional rendering of fields ************/
    modifyFields(fieldsToAdd, fieldsToRemove) {
        let formPages = [...this.state.formPages];
        let mappedFields = this.props.masterid || [];
        if (fieldsToAdd.length > 0) {
            formPages.map(page => page.components.filter(item => fieldsToAdd.includes(item.masterid)).map(item => {
                item.showInForm = 'Yes'
            }));
        }

        if (fieldsToRemove.length > 0) {
            formPages.map(page => page.components.filter(item => fieldsToRemove.includes(item.masterid)).map(item => {
                item.showInForm = 'No';
            }));

        }
        this.setState({formPages: formPages});
    }

    appendElement(pageIndex, fieldIndex, componentToCopy, indexOfValue = -1, eleClicked) {

        let fieldLength = this.state.formPages[pageIndex].components.filter(item => ((item.referenceId && parseInt(item.referenceId) === parseInt(componentToCopy.masterid)) || (!item.referenceId && parseInt(item.masterid) === parseInt(componentToCopy.masterid)))).length;
        let newFieldIndex = (eleClicked === 'yes') ? fieldIndex + 1 : fieldIndex;

        if (eleClicked === 'no' && componentToCopy.linked === 'No' && componentToCopy.type !== 'fieldset') {
            newFieldIndex = fieldIndex + 1;
        }

        this.createClone(componentToCopy, pageIndex, newFieldIndex, fieldLength, 0, 'Yes', indexOfValue, eleClicked);
    }

    createClone(componentToCopy, pageIndex, fieldIndex, fieldLength, fieldSetId, removableField = 'Yes', indexOfValue = -1, eleClicked) {
        let formPages = [...this.state.formPages];
        let formPage = formPages[pageIndex];

        let referenceId = componentToCopy.masterid;

        let subElements = formPage.components.filter(item => (item.referenceId && item.referenceId === referenceId));
        let subEleLength = subElements.length;
        let refIndexNo = parseInt(subEleLength) + 1;

        let masterid = parseInt(componentToCopy.masterid) + parseInt(subEleLength) + 10;

        if (subElements.length > 0) {
            let lastId = subElements[subElements.length - 1].masterid;
            masterid = parseInt(lastId) + parseInt(subEleLength) + 10;

        }

        let newmid = this.getUniqueMid(masterid);
        masterid = newmid;

        masterid = masterid.toString();
        let children = [];
        let childComponents = [];
        let childCount = 0;
        let newFormula = [];
        let formula = [];

        if (componentToCopy.type === 'fieldset') {
            // Add child count too
            children = componentToCopy.child;
            childCount = formPage.components.filter((item) => parseInt(item.parentid) === parseInt(componentToCopy.masterid)).length;
            childComponents = formPage.components.filter((item) => children.includes(item.masterid));
            fieldLength = (fieldLength * childCount) + (fieldLength - 1);
            fieldSetId = masterid;
        } else {
            if (componentToCopy.linked === 'No') {
                fieldLength = fieldLength - 1;
            }
        }

        let index = fieldIndex + fieldLength;

        let newCom = {...componentToCopy};


        newCom.id = "form_child_" + masterid;
        newCom.masterid = masterid;
        if (newCom.linked === 'No' && eleClicked && eleClicked === 'yes') {
            focusToEle = masterid;
            this.setState({
                focusToEle: focusToEle,
                moveFocus: true
            })
        }


        newCom.name = "form_child_" + masterid;
        newCom.removableField = removableField;
        newCom.referenceId = referenceId;
        newCom.refIndexNo = refIndexNo;
        if (newCom.masterid === "1637219583622") {
            console.log(newCom);
            console.log(newCom.referenceId)
        }


        componentToCopy.showPlusBtn = false;
        formPage.components.filter((fld, ind) => fld.referenceId === referenceId).map((item) => {
            item.showPlusBtn = false;
            return item;
        });
        newCom.showPlusBtn = true;
        if (newCom.linked === 'Yes') {
            let parentEleArr = formPage.components.filter((item) => parseInt(item.masterid) === parseInt(fieldSetId));
            let parentEle = parentEleArr.length > 0 && parentEleArr[0];
            if (parentEle && ((parentEle.singlepanel && parentEle.singlepanel.toLowerCase() === 'yes') || (parentEle.displayfieldsetlabel && parentEle.displayfieldsetlabel.toLowerCase() !== 'yes'))) {
                newCom.hideFieldLabel = true;
            }
        }

        if (newCom.type === 'computedfield') {
            formula = newCom.formula;
            if (formula.length > 0) {
                for (var f = 0; f < formula.length; f++) {
                    newFormula[f] = {...formula[f]};
                    if (formula[f].type === 'field') {
                        let opField = formula[f].value;
                        let refFields = formPage.components.filter((item) => item.referenceId === opField && (item.refIndexNo === refIndexNo || (!item.refIndexNo)));
                        if (refFields.length > 0) {
                            newFormula[f].value = refFields[0].masterid;
                        }
                    }
                }
            }
        }

        if (newCom.type === 'computedfield') {
            newCom.formula = newFormula;
            let formula = newFormula;

            if (formula.length > 0) {
                for (var b = 0; b < formula.length; b++) {
                    if (formula[b].type === 'field') {
                        formPage.components.filter(item => item.masterid === formula[b].value).map(fld => {
                            fld.triggerOnChange = true;
                            fld.triggeredField = [];
                            fld.triggeredField.push(newCom.masterid);
                            return fld;
                        })
                    }
                }
            }
        }
        if (newCom.temp_value && newCom.temp_value[indexOfValue]) {

            let this_value_obj = newCom.temp_value.filter(tmp => tmp.refIndexNo && tmp.refIndexNo === indexOfValue);
            if (this_value_obj.length === 0) {
                this_value_obj = [newCom.temp_value[indexOfValue]];
            }
            let this_value = this_value_obj.length > 0 ? this_value_obj[0] : {};

            if (newCom.externallink && newCom.externallink === 'Yes') {
                newCom.options = componentToCopy.options;

                if (newCom.options) {
                    let selectedValueArr = this_value_obj.map(sel => {
                        let selectedOptionArr = newCom.options.filter(opt => (opt.recordid === sel.recordid));
                        let selectedOptions = selectedOptionArr.length > 0 ? selectedOptionArr[0] : []
                        return selectedOptions;
                    });
                    newCom.selectedItems = selectedValueArr;
                    newCom.selectedItems = Array.isArray(newCom.selectedItems) ? newCom.selectedItems : [];
                    newCom.value = newCom.selectedItems;
                    newCom.selectedVals = newCom.selectedItems.map(sel => {
                        return sel.value;
                    });
                }
                newCom.value = this_value_obj;
            } else if (newCom.type === 'checkbox' || newCom.type === 'switch' || newCom.type === 'check_image' || newCom.type === 'dropdown') {

                if (this_value_obj.length > 0) {
                    this_value_obj = this_value_obj.filter(vals => newCom.optionvalues.includes(vals.value));
                }

                //newCom.value.push(this_value);
                newCom.selectedItems = this_value_obj;

                let selectedVals = this_value_obj.map((val, ind) => {
                    return val.value;
                })
                newCom.selectedVals = selectedVals;
                newCom.value = this_value_obj;
            } else if (newCom.type === 'attachment' || newCom.type === 'photocapture') {

                newCom.value = [];
                newCom.value.push(this_value);
                newCom.selectedAction = (newCom.type === 'photocapture' && newCom.temp_value[indexOfValue] && newCom.temp_value[indexOfValue].type) ? newCom.temp_value[indexOfValue].type : '';
            } else {
                newCom.value = this_value.value;
            }

        } else {
            newCom.value = (newCom.type === 'attachment' || newCom.type === 'photocapture') ? [] : '';
            newCom.temp_value = [];
            newCom.selectedItems = [];
            newCom.selectedVals = [];
        }
        if (newCom.validation === "Yes") {
            newCom.isValid = false;
        }

        formPage.components.splice(index, 0, newCom);

        if (children.length > 0) {

            // Duplicate the fieldset children
            if (formPage.components[index]) {
                formPage.components[index].child = [];
                let childIndex = 0;
                let newmasterid = '';
                if (children.length > 0) {
                    for (var q = 0; q < children.length; q++) {

                        [formPage, childIndex, newmasterid] = this.createClone(childComponents[q], pageIndex, index + 1, q, fieldSetId, removableField = 'No', indexOfValue);
                        formPage.components[index].child.push(newmasterid);
                        formPage.components[childIndex].parentid = fieldSetId;
                        //console.log(newmasterid)

                        if (q === children.length - 1) {
                            newEleCloned = true;
                            this.modifyAutoFill(formPage, fieldSetId);
                        }
                    }
                }

            }

        }
        return [formPage, index, masterid];
    }


    getUniqueMid = (masterid) => {
        let newMid = masterid;
        let formPages = this.state.formPages;
        let pageComponents = formPages.map((pg, pageIndex) => {
            return pg.components;
        });
        let componentList = [].concat.apply([], pageComponents);
        //console.log(componentList);

        let existArr = componentList.filter(com => com.masterid.toString() === masterid.toString());
        if (existArr.length > 0) {
            //console.log("1111111111111111 exists");
            masterid = parseInt(masterid) + 11;
            newMid = this.getUniqueMid(masterid);
        }


        return newMid;
    }


    removeElement(pageIndex, fieldIndex) {
        var index = fieldIndex;
        let formPages = [...this.state.formPages];
        let this_component = formPages[pageIndex].components[fieldIndex];
        let fieldtype = this_component.type;
        let referenceId = this_component.referenceId;
        let refIndexNo = this_component.refIndexNo ? this_component.refIndexNo : 0;

        if (this_component.type === 'fieldset') {
            let siblingEles = formPages[pageIndex].components.filter((item, i) => item.refIndexNo > refIndexNo && item.referenceId === referenceId)
                .map((fld, ind) => {
                    fld.refIndexNo = fld.refIndexNo - 1;
                    let children = fld.child;
                    formPages[pageIndex].components.filter((item, i) => children.includes(item.masterid))
                        .map((ch_fld, ch_ind) => {
                            ch_fld.refIndexNo = ch_fld.refIndexNo - 1;
                            return ch_fld;
                        });
                    return fld;
                });

        } else {
            formPages[pageIndex].components.filter((item, i) => item.refIndexNo > refIndexNo && item.referenceId === referenceId)
                .map((fld, ind) => {
                    fld.refIndexNo = fld.refIndexNo - 1;
                    return fld;
                });
        }


        if (this_component.showPlusBtn) {
            let siblings = [];
            if (refIndexNo > 1) {
                siblings = formPages[pageIndex].components.filter((item, i) => item.refIndexNo === refIndexNo - 1 && item.referenceId === referenceId);
            } else if (refIndexNo === 1) {
                siblings = formPages[pageIndex].components.filter((item, i) => (!item.refIndexNo || item.refIndexNo === 0) && item.masterid === referenceId);
            }
            if (siblings.length > 0) {
                siblings[0].showPlusBtn = true;
            }
            /// let thisRefIndex = refIndexNo + 1;
        }
        if (fieldtype === 'fieldset') {
            let children = this_component.child;
            let cfIndex = formPages[pageIndex].components.map((item, itemIndex) => {
                if (children.includes(item.masterid)) {
                    return itemIndex;
                } else {
                    return -1;
                }
            });
            cfIndex = cfIndex.filter(item => parseInt(item) > -1);
            cfIndex.sort(function (a, b) {
                return b - a
            });

            for (var i = 0; i < cfIndex.length; i++) {
                formPages[pageIndex].components.splice(cfIndex[i], 1);
            }

            formPages[pageIndex].components.splice(index, 1);
        } else {
            formPages[pageIndex].components.splice(index, 1);

        }

        this.setState({formPages: formPages},
            function () {
                //console.log(formPages);
            });

    }

    setFieldValues(fieldName, fieldValue, pageIndex) {
        let thisComponent = {};
        let formPages = [...this.state.formPages];

        let matchedComponent = formPages[pageIndex].components.filter(item => item.name === fieldName);
        if (matchedComponent.length > 0) {
            thisComponent = matchedComponent[0];
            if (thisComponent.type === 'checkbox' || thisComponent.type === 'switch' || thisComponent.type === 'check_image' || thisComponent.type === 'dropdown') {
                let matchedValue = '';
                if (thisComponent.externallink === 'Yes' || ((thisComponent.type === 'checkbox' || thisComponent.type === 'switch') && thisComponent.optionstatus)) {
                    let matchedOption = thisComponent.options.filter(item => item.value === fieldValue);
                    if (matchedOption.length > 0) {
                        matchedValue = matchedOption[0];
                    }
                } else {
                    matchedValue = fieldValue;
                }
                if (matchedValue !== '') {
                    if (!thisComponent.selectedItems) {
                        thisComponent.selectedItems = [];
                        thisComponent.selectedVals = [];
                    }
                    let checkedValue = (thisComponent.externallink === 'Yes' || ((thisComponent.type === 'checkbox' || thisComponent.type === 'switch') && thisComponent.optionstatus)) ? matchedValue.value : matchedValue;
                    if (thisComponent.type === 'switch' || (thisComponent.type === 'dropdown' && (!thisComponent.multipleselection || thisComponent.multipleselection === 'No'))) {
                        thisComponent.selectedItems = [];
                        thisComponent.selectedVals = [];
                    }

                    if (thisComponent.selectedVals.includes(checkedValue)) {
                        if (thisComponent.type === 'checkbox' || thisComponent.type === 'check_image' || (thisComponent.type === 'dropdown' && thisComponent.multipleselection && thisComponent.multipleselection === 'Yes')) { // If checbox, remove the elemnt
                            const index = thisComponent.selectedVals.indexOf(checkedValue);
                            if (index > -1) {
                                thisComponent.selectedItems.splice(index, 1);
                                thisComponent.selectedVals.splice(index, 1);
                            }
                        }

                    } else {
                        if (thisComponent.externallink === 'Yes' || ((thisComponent.type === 'checkbox' || thisComponent.type === 'switch') && thisComponent.optionstatus)) {
                            thisComponent.selectedItems.push(matchedValue);
                        } else {
                            thisComponent.selectedItems.push({value: matchedValue});
                        }
                        thisComponent.selectedVals.push(checkedValue);
                    }
                    thisComponent.value = thisComponent.selectedItems;
                } else {
                    thisComponent.value = thisComponent.selectedItems;
                    if (thisComponent.type === 'dropdown' && (!thisComponent.externallink || thisComponent.externallink === 'No')) {
                        thisComponent.value = [];
                        thisComponent.selectedItems = [];
                        thisComponent.selectedVals = [];
                    }
                }
            } else {
                thisComponent.value = fieldValue;
            }

            if (thisComponent.triggerOnChange) { //Computed field

                let triggeredField = thisComponent.triggeredField;
                for (var i = 0; i < triggeredField.length; i++) {
                    this.calculateComputedValue(triggeredField[i]);
                }
            }
            if (thisComponent.triggerOnSelect) { //Date computed field
                let triggeredField = thisComponent.triggeredField;
                let meta = this.props.getFormData ? this.props.getFormData.meta : '';
                for (var i = 0; i < triggeredField.length; i++) {
                    this.calculateComputedValueDate(triggeredField[i], meta);
                }
            }
            if (thisComponent.triggerOnDate) { //Current Age field
                let triggeredField = thisComponent.triggeredField;
                for (var i = 0; i < triggeredField.length; i++) {
                    this.calculateCurrentAge(triggeredField[i]);
                }
            }
            if (thisComponent.triggerOnChangeField) {
                //Auto capture case
                thisComponent.hasFieldChanged = true;
                let triggeredField = thisComponent.triggeredField;
                for (var i = 0; i < triggeredField.length; i++) {
                    this.autoCaptureUpdateMode(triggeredField[i]);
                }
            }
            //Custom api triggering
            if (thisComponent.triggerCustomApiOnChange) {
                //console.log(thisComponent)
                let triggeredField = thisComponent.triggeredField;
                let beta = this;
                //this.execCustomApi.cancel();
                for (var i = 0; i < triggeredField.length; i++) {
                    beta.triggerCustomApi(triggeredField[i], false, fieldValue);

                }
            }

            if (this.state.validationMode === 'field') {
                let formInvalid = this.validateField(thisComponent, 0);
                if (formInvalid === 0) {
                    thisComponent.isValid = true;
                } else {
                    this.setState({formSubmitted: true})
                }
            }

            this.setState({formPages: formPages});

            /**************Typeform changes *****************/
            if (thisComponent.type === 'switch' && this.state.formTemplateType === 'typeform') {
                this.triggerNextField();
            }

        }
    }

    modifyAutoFill = (formPage, fieldSetId) => {

        //console.log(fieldSetId)

        let chList = [];
        let parentFieldSetArr = formPage.components.filter(item => item.masterid.toString() === fieldSetId.toString() && item.addmuliple === 'Yes');
        if (parentFieldSetArr.length > 0) {
            chList = parentFieldSetArr[0].child;
        }
        let clonedChList = formPage.components.filter(item => item.linked === 'Yes' && item.parentid.toString() === fieldSetId.toString());

        if (chList.length === clonedChList.length) {
            let af_fld_arr = clonedChList.filter(item => item.type === 'autofill' && parseInt(item.refIndexNo) > 0);
            //console.log(af_fld_arr)
            if (af_fld_arr.length > 0) {
                for (var af = 0; af < af_fld_arr.length; af++) {
                    let autofill_relation = af_fld_arr[af].autofill_relation;
                    let new_autofill_relation = [];
                    if (autofill_relation.length > 0) {
                        for (var f = 0; f < autofill_relation.length; f++) {
                            new_autofill_relation[f] = {...autofill_relation[f]};

                            let filled_fld_arr = clonedChList.filter(item => item.referenceId.toString() === new_autofill_relation[f].fillto.toString());
                            if (filled_fld_arr.length > 0) {
                                new_autofill_relation[f].fillto = filled_fld_arr[0].masterid;
                            }
                        }
                    }
                    af_fld_arr[af].autofill_relation = new_autofill_relation;
                }

            }
        }

    }


    setRawValue = (fieldName, rawvalue, pageIndex) => {
        let thisComponent = {};
        let allFormPages = [...this.state.allFormPages];

        let matchedComponent = allFormPages[pageIndex].components.filter(item => item.name === fieldName);
        if (matchedComponent.length > 0) {
            thisComponent = matchedComponent[0];
            thisComponent.rawvalue = rawvalue;
        }
        this.setState({allFormPages: allFormPages});
    }


    triggerNextField = () => {
        let fieldIndex = this.state.currentFieldIndex;
        let formPages = [...this.state.formPages];
        let pageIndex = this.state.pageIndex;
        let thisComponent = formPages[pageIndex].components[fieldIndex];

        let formInvalid = 0;
        formInvalid = this.validateField(thisComponent, formInvalid);
        if (formInvalid === 0) {
            let nextFieldIndex = this.getNextFieldIndex(fieldIndex)
            this.setState({
                currentFieldIndex: nextFieldIndex,
                formSubmitted: false
            })
        } else {
            this.setState({formSubmitted: true});
        }
    }

    getNextFieldIndex = (fieldIndex) => {
        let nextFieldIndex = fieldIndex + 1;
        let formPages = [...this.state.formPages];
        let pageIndex = this.state.pageIndex;
        let nextField = formPages[pageIndex].components[nextFieldIndex];
        if (nextField) {
            if (nextField.showInForm === 'No' || nextField.linked === 'Yes') {
                nextFieldIndex = this.getNextFieldIndex(nextFieldIndex)
            }
            return nextFieldIndex;
        } else {
            this.submitForm();
        }

    }

    triggerPrevField = () => {
        let fieldIndex = this.state.currentFieldIndex;
        let formPages = [...this.state.formPages];
        let pageIndex = this.state.pageIndex;
        let thisComponent = formPages[pageIndex].components[fieldIndex];

        let formInvalid = 0;
        formInvalid = this.validateField(thisComponent, formInvalid);
        if (formInvalid === 0) {
            let prevFieldIndex = this.getPrevFieldIndex(fieldIndex)
            this.setState({
                currentFieldIndex: prevFieldIndex,
                formSubmitted: false
            })
        } else {
            this.setState({formSubmitted: true});
        }
    }

    getPrevFieldIndex = (fieldIndex) => {
        let prevFieldIndex = fieldIndex - 1;
        let formPages = [...this.state.formPages];
        let pageIndex = this.state.pageIndex;
        let prevField = formPages[pageIndex].components[prevFieldIndex];
        if (prevField && prevFieldIndex > -1) {
            if (prevField.showInForm === 'No' || prevField.linked === 'Yes') {
                prevFieldIndex = this.getPrevFieldIndex(prevFieldIndex)
            }
            return prevFieldIndex;
        } else {
            this.submitForm();
        }

    }

    onFieldKeyUp = (e) => {
        if (e.keyCode === 13) {
            // Cancel the default action, if needed
            e.preventDefault();
            // Trigger the button element with a click
            if (this.state.formTemplateType === 'typeform') {
                this.triggerNextField();
            }
        }
    }

    resetFieldValues(fieldName, pageIndex) {
        let thisComponent = {};
        let formPages = [...this.state.formPages];
        let matchedComponent = formPages[pageIndex].components.filter(item => item.name === fieldName);
        if (matchedComponent.length > 0) {
            thisComponent = matchedComponent[0];
            if (thisComponent.type === 'attachment' || thisComponent.type === 'photocapture' || thisComponent.type === 'dataload' || thisComponent.type === 'checkbox' || thisComponent.type === 'switch' || thisComponent.type === 'check_image' || thisComponent.type === 'dropdown') {
                thisComponent.value = [];
                thisComponent.selectedItems = [];
                thisComponent.selectedVals = [];
            } else {
                thisComponent.value = '';
            }
            this.setState({formPages: formPages});
        }
    }

    showUploadErrors = (fieldName, errorMessage, pageIndex) => {
        let thisComponent = {};
        let formPages = [...this.state.formPages];
        let matchedComponent = formPages[pageIndex].components.filter(item => item.name === fieldName);
        if (matchedComponent.length > 0) {
            thisComponent = matchedComponent[0];
            thisComponent.isValid = false;
            thisComponent.errormsg = errorMessage;
            this.setState({formPages: formPages});
        }
    }

    setSelectedValues(result, reference) {
        console.log(result)

        let formPages = [...this.state.formPages];
        formPages.map(page => page.components.filter(item => (item.masterid === reference)).map(item => {
            item.selectedItems = result;
        }));
        this.setState({formPages: formPages}, function () {
        });
    }

    checkFieldValid(component, fieldValue, pageIndex, fieldIndex) {

        component.isValid = (component.uniquecheck === 'inprogress') ? component.isValid : true;

        if (component.uniquecheck !== 'inprogress') {
            if (component.validation === 'Yes') {

                if (component.multipleValues && component.multipleValues === 'Yes') {
                    if (component.selectedItems.length === 0) {
                        component.isValid = false;
                    }
                } else if (component.type === 'attachment' || component.type === 'photocapture' || component.type === 'checkbox' || component.type === 'switch' || component.type === 'check_image' || component.type === 'dropdown') {
                    if (!fieldValue || fieldValue === '' || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
                        component.isValid = false;
                    }
                } else {
                    if (!fieldValue || fieldValue === '') {
                        component.isValid = false;
                    } else {
                        component = (fieldValue && fieldValue !== '') ? this.verifyField(component, fieldValue) : true;
                    }
                }
            }
            //!component.multipleValues || component.multipleValues === 'No' &&
            if (component.validation === 'No') {
                component = (fieldValue && fieldValue !== '') ? this.verifyField(component, fieldValue) : true;
            }
        }


        return component;
    }

    verifyField(component, fieldValue) {

        let errormsg = '';
        let isValidField = false;
        switch (component.type) {
            case 'email':
                let emailValid = fieldValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                errormsg = 'Please enter a valid email!';
                isValidField = emailValid ? true : false;
                break;
            case 'pan':
                let panValid = fieldValue.match(/^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/);
                errormsg = 'Please enter a valid pancard!';
                isValidField = panValid ? true : false;
                break;
            case 'number':
                let numberfield = fieldValue;
                fieldValue = parseInt(fieldValue)
                let min = parseInt(component.min);
                let max = parseInt(component.max);
                let numberlimitval = component.numberlimit && component.numberlimit !== '' ? component.numberlimit : '';
                isValidField = true;
                if (min && min !== '' && min !== 'No') {
                    isValidField = (fieldValue >= min) ? true : false;
                    errormsg = 'The value should be greater than or equal to ' + min;
                }
                if (isValidField && max && max !== '' && max !== 'No') {
                    isValidField = (fieldValue <= max) ? true : false;
                    errormsg = 'The value should be greater than or equal to ' + max;
                }
                if (isValidField && min && min !== '' && min !== 'No' && max && max !== '' && max !== 'No') {
                    isValidField = (fieldValue >= min && fieldValue <= max) ? true : false;
                    errormsg = 'The value should be between ' + min + ' and ' + max;
                }
                if (numberlimitval !== '') {
                    let numberlimit = parseInt(numberlimitval);
                    if (numberlimit !== numberfield.length) {
                        isValidField = false;
                        errormsg = 'Please enter ' + numberlimit + ' digit number';
                    }
                }
                break;
            case 'imageurl':

                let imageurlValid = validURL(fieldValue) ? true : false;
                if (!imageurlValid) {
                    errormsg = 'Please enter a valid image url!';
                    isValidField = imageurlValid ? true : false;
                } else {
                    isValidField = imageurlValid;
                }
                break;
            case 'videourl':
                let videourlValid = validURL(fieldValue) ? true : false;
                if (!videourlValid) {
                    errormsg = 'Please enter a valid video url!';
                    isValidField = videourlValid ? true : false;
                } else {
                    isValidField = videourlValid;
                }
                break;
            default:
                isValidField = true;
                break;
        }

        component.errormsg = errormsg;
        component.isValid = isValidField;

        return component;
    }

    checkIfUnique = (component, fieldValue) => {
        let params = {
            fieldid: component.masterid,
            collectionid: this.props.collectionId ? this.props.collectionId : this.state.collectionid,
            value: fieldValue
        };
        this.props.checkIfUnique(params)
    }

    resetUniqueValidations = () => {
        let formPages = this.state.formPages;
        let pageIndex = this.state.pageIndex;
        let uniqueFields = formPages[pageIndex].components.filter(field => field.uniquevalue && field.uniquevalue === 'Yes');
        if (uniqueFields.length > 0) {
            for (var q = 0; q < uniqueFields.length; q++) {
                this.props.resetUniqueValues(uniqueFields[q].masterid)
            }
        }
    }
    resetUniqueValues = (fieldid) => {
        this.props.resetUniqueValues(fieldid)
    }

    resetForm = () => {

        window.location.reload();
        /*newFindex =0;
        this.resetUniqueValidations();
        let collectionid = this.props.collectionId ? this.props.collectionId : this.state.collectionid;

        (this.state.moduleName === 'entity') ? this.props.entityshowform({ _id : collectionid},this.state.formaccess) : this.props.showform({ _id : collectionid},this.state.formaccess);
*/
    }
    submitForm = () => {
        this.setState({dropdownSubmit: false});
        if (this.state.formSubmitting === 0) {
            let formPages = [...this.state.formPages];
            let allFormPages = [...this.state.allFormPages];
            //formPages = this.sortJson(formPages);
            let metaComponents = this.state.metaComponents;
            let j = metaComponents.length;
            let pageIndex = this.state.pageIndex
            uniqueValueCheck = 0;
            let formInvalid = 0;
            if (this.state.validationMode === 'page' || this.state.validationMode === 'field') {
                formInvalid = this.validatePage(this.state.pageIndex);
            }
            if (this.state.validationMode === 'form' && parseInt(this.state.pageIndex) === parseInt(this.state.formSubmitIndex)) {
                formInvalid = this.validateForm();
            }
            if (formInvalid === 0 && uniqueValueCheck === 0) {
                this.resetUniqueValidations();
                if (parseInt(this.state.pageIndex) !== parseInt(this.state.formSubmitIndex)) {
                    let nextPageIndex = parseInt(this.state.pageIndex) + 1;
                    this.changePage(nextPageIndex);
                } else {
                    if (allFormPages.length > formPages.length) {
                        this.setAllFieldValues();
                    }
                    if (this.state.editableForm) {
                        let mode = 'save';
                        let childid = '';
                        let referenceID = this.props.referenceId ? this.props.referenceId : this.state.collectionid;
                        let metaId = this.state.metaId;
                        if (this.props.editId) {
                            referenceID = this.props.getFormData.meta.referenceid;
                            mode = 'update';
                            childid = this.props.editId;
                            metaId = this.props.getFormData.meta.metaid;

                        }
                        let metaForm = [];
                        let formconcat = '';

                        //console.log(metaComponents); //return false;

                        for (var key in metaComponents) {
                            if (metaComponents[key].type != 'fieldset') {
                                if (metaComponents[key].value && metaComponents[key].value.length > 0) {
                                    for (var t = 0; t < metaComponents[key].value.length; t++) {
                                        formconcat += (metaComponents[key].value
                                            && metaComponents[key].value.length > 0 && metaComponents[key].value !== undefined
                                            && metaComponents[key].value[t]) ?
                                            (metaComponents[key].value[t].value + " ") : '';
                                    }
                                }

                            }

                            //metaComponents[key].temp_value = this.mergeValues(metaComponents[key].temp_value,metaComponents[key].value);
                            metaComponents[key].temp_value = metaComponents[key].temp_value ? metaComponents[key].temp_value : metaComponents[key].value;

                            // metaComponents[key].temp_value = metaComponents[key].temp_value ? metaComponents[key].temp_value.concat(metaComponents[key].value) : metaComponents[key].value;
                            metaComponents[key].value = metaComponents[key].temp_value;
                            metaComponents[key].temp_value = [];
                            //delete metaComponents[key].temp_value;
                            metaForm.push(metaComponents[key]);
                        }
                        for (var i = 0; i < metaForm.length; i++) {
                            if (metaForm[i].temp_value)
                                delete metaForm[i].temp_value;
                            if (metaForm[i].type === 'autofill') {
                                for (j = 0; j < metaForm[i].value.length; j++)
                                    delete metaForm[i].value[j].autofill;
                            }
                        }

                        console.log(metaForm);
                        let formData = {
                            mode: mode,
                            parent: this.props.collectionId ? this.props.collectionId : this.state.collectionid,
                            uid: this.state.uid,
                            usertype: this.state.usertype,
                            referenceID: referenceID,
                            formconcat: formconcat,
                            formdata: JSON.stringify(metaForm),
                            child: childid,
                            formtype: this.state.embedForm ? 'embed' : '',
                            formSource: this.props.formSource,
                            reference: this.props.reference ? this.props.reference : '',
                            draft: false,
                            metaid: metaId // metaid from embed form

                        };

                        console.log(metaForm); //return false;

                        this.props.saveForm(formData);
                        this.trackCollection("form" + mode);

                        this.setState({loading: 1, formSubmitting: 1});
                        this.generateNewValue(metaForm);
                        if (this.state.embedForm && !getUrlSegment(4) && this.state.subdomain !== 'medmigrate.data') {
                            this.setState({hideSubmitButton: true});
                        }
                    } else {
                        let readonlycomponents = formPages[pageIndex].components.filter(field => field.showInForm === 'Yes' && formPages[pageIndex].accessRestriction === 'readonly');
                        if (readonlycomponents && readonlycomponents.length > 0) {
                            if (this.state.pageIndex !== this.state.formSubmitIndex) {
                                let nextPageIndex = parseInt(this.state.pageIndex) + 1;
                                this.changePage(nextPageIndex);
                            } else {
                                if (this.state.embedForm) {
                                    this.props.saveReadonlyForm();
                                } else {
                                    this.hideFormModal();
                                }
                            }
                        }
                    }
                }
            } else {


                /*if( getUrlSegment(1) === 'chat' ){
                    let pIndex = getUrlSegment(4);
                    let invalidComponents = formPages[0].components.filter( item => item.isValid === false);
                    let elementId = invalidComponents[0].id;
                    let element = document.getElementById(elementId);
                    element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

                }
                else {*/
                let invalidComponentsArr = formPages.map((page, index) => page.components.filter(item => item.isValid === false));
                let invalidComponents = [].concat.apply([], invalidComponentsArr);
                if (invalidComponents.length > 0) {
                    let elementId = invalidComponents[0].id;
                    if (this.state.pageIndex !== invalidComponents[0].pageIndex) {
                        this.setState({
                            pageIndex: invalidComponents[0].pageIndex
                        }, function () {
                            let element = document.getElementById(elementId);
                            if (element) {
                                element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

                            }
                        })
                    } else {
                        let element = document.getElementById(elementId);
                        if (element) {
                            element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

                        }
                    }

                }
                //}

                /*var elements = document.getElementsByClassName("mandatory-box");
                /!*for (i = 0; i < elements.length; i++) {
                    elements.item(i).scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                    return false;
                }*!/
                if (elements.length > 0) {
                    let element = elements.item(0);
                    element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                }*/
            }
        }
    }

    saveAsDraft = () => {
        this.validateForm('draft');
        let mode = 'save';
        let childid = '';
        let metaId = this.state.metaId;
        let draftId = this.props.getSavedForm.draftId ? this.props.getSavedForm.draftId : (this.props.editId ? this.props.editId : '')
        if (draftId !== '') {
            mode = 'update';
            childid = draftId;
            metaId = this.props.getFormData.meta.metaid;
        }

        let referenceID = this.props.referenceId ? this.props.referenceId : this.state.collectionid;
        let metaComponents = this.state.metaComponents;
        let metaForm = [];
        let formconcat = '';
        let j = metaComponents.length;


        //console.log(metaComponents); //return false;
        for (var key in metaComponents) {

            metaComponents[key].temp_value = metaComponents[key].temp_value ? metaComponents[key].temp_value : metaComponents[key].value;

            metaComponents[key].value = metaComponents[key].temp_value;
            metaComponents[key].temp_value = [];

            if (metaComponents[key].type != 'fieldset') {
                if (metaComponents[key].value && metaComponents[key].value.length > 0) {
                    for (var t = 0; t < metaComponents[key].value.length; t++) {
                        let thisfieldvalue = '';
                        if (metaComponents[key].value
                            && metaComponents[key].value.length > 0 && metaComponents[key].value !== undefined
                            && metaComponents[key].value[t]) {

                            thisfieldvalue = metaComponents[key].value[t].value ? metaComponents[key].value[t].value : '';
                            let trimmedvalue = thisfieldvalue.toString().trim();

                            metaComponents[key].value[t].value = trimmedvalue;
                            formconcat += trimmedvalue + " ";

                        }
                    }
                }

            }


            //delete metaComponents[key].temp_value;
            metaForm.push(metaComponents[key]);
        }
        for (var i = 0; i < metaForm.length; i++) {
            if (metaForm[i].temp_value)
                delete metaForm[i].temp_value;
            if (metaForm[i].type === 'autofill') {
                for (j = 0; j < metaForm[i].value.length; j++)
                    delete metaForm[i].value[j].autofill;
            }
        }

        //return false;

        /******************** Code for closing the WF task onForm Submit ***********/

        let closeworkflowparams = "";
        if (this.props.closeTaskOnFormSubmit) {
            closeworkflowparams = JSON.stringify(this.props.closeworkflowparams);
        }

        /******************** Code for closing the WF task onForm Submit ***********/


        let formData = {
            mode: mode,
            parent: this.props.collectionId ? this.props.collectionId : this.state.collectionid,
            uid: this.state.uid,
            usertype: this.state.usertype,
            referenceID: referenceID,
            formconcat: formconcat,
            formdata: JSON.stringify(metaForm),
            child: childid,
            formtype: this.state.embedForm ? 'embed' : '',
            formSource: this.props.formSource,
            reference: this.props.reference ? this.props.reference : '',
            closeTaskOnFormSubmit: this.props.closeTaskOnFormSubmit,
            closeworkflowparams: closeworkflowparams,
            draft: true,
            buttonid: this.state.customButtonId,
            metaid: metaId // metaid from embed form
        };

        //console.log(formData);//return false;
        this.props.saveForm(formData);
    }

    hideSuccessDraft = () => {
        this.setState({
            draftSuccessMessage: false
        })
    }

    createFieldJson(component, fieldValue) {
        let valueArray = [];
        if (fieldValue !== '') {
            if ((component.type === 'fieldset') || (component.multipleValues && component.multipleValues === 'Yes') || (component.type === 'attachment') || (component.type === 'photocapture') || (component.type === 'videocapture') || (component.type === 'checkbox') || (component.type === 'check_image') || (component.type === 'switch') || (component.type === 'dataload') || (component.type === 'autofill') || (component.type === 'dropdown')) {
                valueArray = fieldValue;
            } else if (component.externallink && component.externallink === 'Yes') {
                valueArray = component.options.filter(option => (option.value === fieldValue));
            } else {
                valueArray.push({value: fieldValue, refIndexNo: 0});
            }
        }

        let formEle = {
            type: component.type,
            masterid: component.referenceId ? component.referenceId : component.masterid,
            label: component.label,
            value: valueArray,
            external_linked: (component.externallink && component.externallink === "Yes") ? "yes" : "no",
            temp_value: component.temp_value,
        };
        if (component.type === 'uniqueid' && component.text_random) {
            formEle.text_random = component.text_random;
        }
        if ((component.type === 'checkbox' || component.type === 'switch') && component.optionstatus) {
            formEle.optionstatus = component.optionstatus ? component.optionstatus : '';
        }
        if (component.type === 'datepicker' && component.autocapture && component.autocapture === 'Yes') {
            formEle.datechange = component.datechange;
        }
        return formEle;
    }

    changePageNav = (curIndex) => {

        if (curIndex < this.state.pageIndex) {
            this.changePage(curIndex);
        } else {

            this.setState({formSubmitted: true});
            let nextpage = this.state.pageIndex;
            for (var pIndex = this.state.pageIndex; pIndex < curIndex; pIndex++) {
                let formInvalid = this.validatePage(pIndex);
                if (formInvalid > 0) {
                    break;
                } else {
                    nextpage = pIndex + 1;
                }
            }
            if (nextpage !== this.state.pageIndex) {
                this.changePage(nextpage);
            }

        }

    }

    changePage(pageIndex) {
        this.trackCollection("form load");
        if (this.state.validationMode === 'form') {
            this.setState({pageIndex: pageIndex});
        } else {
            this.setState({pageIndex: pageIndex, formSubmitted: false});

        }
        this.hidePageMenu();
    }

    validatePage = (curIndex) => {
        let formPages = [...this.state.formPages];
        this.setState({formSubmitted: true});
        let formInvalid = 0;
        //let metaForm =  [...this.state.metaForm];
        /*let metaComponents =  this.state.metaComponents;
        let j = metaComponents.length;*/
        let pageIndex = curIndex;

        let components = formPages[pageIndex].components.filter(field => (formPages[pageIndex].accessRestriction === 'writable' || formPages[pageIndex].accessRestriction === 'readonly'));

        if (components && components.length > 0) {
            for (var i = 0; i < components.length; i++) {
                let component = components[i];
                formInvalid = this.validateField(component, formInvalid)
            }

            if (formPages[pageIndex].accessRestriction === 'readonly') {
                formInvalid = 0;
            }
            return formInvalid;
        }
    }
    validateForm = () => {
        let formPages = [...this.state.formPages];
        this.setState({formSubmitted: true});
        let formInvalid = 0;

        //let metaForm =  [...this.state.metaForm];
        /*let metaComponents =  this.state.metaComponents;
        let j = metaComponents.length;*/
        for (var pageIndex = 0; pageIndex < formPages.length; pageIndex++) {

            let components = formPages[pageIndex].components.filter(field => (formPages[pageIndex].accessRestriction === 'writable' || formPages[pageIndex].accessRestriction === 'readonly'));

            if (components && components.length > 0) {
                for (var i = 0; i < components.length; i++) {
                    let component = components[i];
                    formInvalid = this.validateField(component, formInvalid)
                }
            }
        }
        return formInvalid;
    }

    validateField = (component, formInvalid, submissionType = '') => {
        let metaComponents = this.state.metaComponents;
        let j = metaComponents.length;
        let formPages = [...this.state.formPages];
        let allFormPages = [...this.state.allFormPages];
        let pageIndex = this.state.singlePageForm ? this.state.pageIndex : component.pageIndex;

        let collectionId = this.props.collectionId ? this.props.collectionId : this.state.collectionid;

        let components = allFormPages[pageIndex].components.filter(field => (allFormPages[pageIndex].accessRestriction === 'writable' || allFormPages[pageIndex].accessRestriction === 'readonly'));
        let fieldValue = component.value ? component.value : '';
        if (component.type === 'text' || component.type === 'email' || component.type === 'number' || component.type === 'pan' || component.type === 'textarea' || component.type === 'editor') {
            //  fieldValue = fieldValue.trim();
        }
        if (component.type === 'editor') {
            fieldValue = parseQuillEditorContent(fieldValue);
        }
        let validField = true;
        component.temp_value = [];

        let validateComponent = true;

        console.log(component)

        if (component.linked === 'Yes') { // If the fieldset is hidden, dont validate children
            let parentid = component.parentid;
            let parentComponent = components.filter(item => parseInt(item.masterid) === parseInt(parentid));
            if (parentComponent.length > 0) {
                validateComponent = (parentComponent[0].showInForm === 'Yes') ? true : false;
            } else { // if the parent componnt is not visible dont validate children
                validateComponent = false;
            }
        }

        if (component.showInForm === 'No') {
            validateComponent = false;
            component.isValid = true;
            if (getUrlSegment(1) === 'chat' && component.uniquevalue && component.uniquevalue === "Yes") {
                fieldValue = getUrlSegment(5)
            }
        }

        if (component.readOnly || submissionType === 'draft') {
            validateComponent = false;
            component.isValid = true;
        }

        if (component.type === 'fieldset') {
            fieldValue = [];
        } else if (component.type === 'formheading') {
            fieldValue = "";
            component.isValid = true;
        } else if (component.type === 'uniqueid') {
            // fieldValue = "";
            component.isValid = true;
            // component.text_random = "Yes";
        } else if (component.type === 'text' && component.password && component.password === 'Yes') {
            // fieldValue = "";
            component.isValid = true;
            // component.text_random = "Yes";
        } else if (component.type === 'computedfield') {

            let computedValue = this.evaluateFormula(component);

            this.setRawValue(component.id, computedValue, component.pageIndex);
            //component.rawvalue = computedValue;

            fieldValue = computedValue ? computedValue : 0;

            if (fieldValue === 'Invalid') {
                fieldValue = '';
            }
            component.isValid = true;
        } else if (component.type === 'age') {

            let computedValueAge = this.evaluateAge(component);
            this.setRawValue(component.id, computedValueAge, component.pageIndex);
            fieldValue = '';
            component.isValid = true;
        } else if (component.type === 'datecompute') {
            if (component.autotriggerOnSelect) {
                component.isValid = true;
                if (component.value === 0) {
                    fieldValue = '0';
                } else {
                    fieldValue = component.value;
                }
            } else {
                let computedValueDate = this.evaluateFormulaDate(component);
                this.setRawValue(component.id, computedValueDate, component.pageIndex);
                if (computedValueDate === 0) {
                    fieldValue = '0';
                } else {
                    fieldValue = computedValueDate;
                }
                if (fieldValue === 'Invalid' || fieldValue === 'Invalid date') {
                    fieldValue = '';
                }
                component.isValid = true;
            }
        } else if (component.type === 'datepicker' && component.autocapture && component.autocapture === 'Yes') {
            fieldValue = this.evaluateAutoCaptureUpdateMode(component);

            if (!component.datechange && fieldValue !== '') {
                if (component.datetime && component.datetime === 'Yes') {
                    var date_value = new moment(fieldValue, "YYYY-MM-DDThh:mm").utc();
                    fieldValue = (date_value.format('YYYY-MM-DD HH:mm:ss'));
                }
            }
        } else if (component.type === 'toggle') {
            if (fieldValue === '') {
                fieldValue = 'No';
            }
            component.isValid = true;
        } else if (component.type === 'agree') {
            if (fieldValue === '') {
                fieldValue = 'no';
            }
            component.isValid = true;
        } else if (component.type === 'preview') {
            if (fieldValue === '') {
                fieldValue = 'no';
            }
            component.isValid = true;
        } else if (component.type === 'loggedin') {

            if (!this.props.editId || this.props.editId === '') {
                fieldValue = this.state.loggedInValue;
            } else {
                fieldValue = component.value;
            }
            component.isValid = true;
        } else if (component.type === 'location') {
            if (!this.state.collaborator || this.state.collaborator === false) {
                if (component.lockonadd === 'Yes' && (!this.props.editId || this.props.editId === '')) {
                    let location = [];
                    fieldValue = location;
                    component.isValid = true;
                } else if (component.lockonedit === 'Yes' && this.props.editId && this.props.editId !== '') {
                    let location = [];
                    fieldValue = location;
                    component.isValid = true;
                } else {
                    let location = [this.state.latitudeval + ', ' + this.state.longitudeval];
                    fieldValue = location;
                    component.isValid = true;
                }
            } else {
                let location = [this.state.latitudeval + ', ' + this.state.longitudeval];
                fieldValue = location;
                component.isValid = true;
            }
        } else if (component.type === 'resources') {
            fieldValue = this.state.selectedResources.length !== 0 ? JSON.stringify(this.state.selectedResources) : component.value;
        } else if (component.type === 'phonenumber') {
            fieldValue = component.value;
            if (component.value && component.value !== '') {
                let t = component.value.split(" ");
                if (t[1] && t[1] !== '') {
                    fieldValue = component.value;
                } else {
                    fieldValue = '';
                }
            }
            component.isValid = true;
        } else {

            if (validateComponent) {
                validField = this.checkFieldValid(component, fieldValue);

                if (validField.isValid !== undefined) {
                    //formInvalid = validField.isValid ?((validField.uniqueCheck && validField.uniqueCheck === 'inprogress') ? formInvalid+1 :formInvalid) : formInvalid + 1;
                    formInvalid = validField.isValid ? formInvalid : formInvalid + 1;
                }

                if (fieldValue !== '' && component.uniquevalue && component.uniquevalue === "Yes" && component.uniquecheck !== 'done') {
                    uniqueValueCheck = parseInt(uniqueValueCheck) + 1;
                    //component.uniqueerrormsg = false;
                    component.uniquecheck = 'inprogress';
                    let componentValue = fieldValue;
                    if (Array.isArray(fieldValue)) {
                        if (fieldValue.length > 0) {
                            componentValue = fieldValue[0].value;
                        } else {
                            componentValue = '';
                        }
                    }

                    if (componentValue !== '') {
                        let isUnique = this.checkIfUnique(component, componentValue);
                    }

                }
            }
        }


        let metaRefkey = component.masterid;
        if (component.referenceId && component.referenceId !== '') {
            metaRefkey = component.referenceId
        }

        if (metaRefkey !== '') {
            if (!metaComponents["id" + metaRefkey]) {
                metaComponents["id" + metaRefkey] = this.createFieldJson(component, fieldValue);
                if (!metaComponents["id" + metaRefkey].temp_value) {
                    metaComponents["id" + metaRefkey].temp_value = [];
                }
            }
            metaComponents["id" + metaRefkey].temp_value = !metaComponents["id" + metaRefkey].temp_value ? [] : metaComponents["id" + metaRefkey].temp_value;


            if (component.type !== 'fieldset') {

                if (component.type === 'attachment' || component.type === 'photocapture' || component.type === 'videocapture' || component.type === 'dropdown' || component.type === 'checkbox' || component.type === 'switch' || component.type === 'autofill') {
                    //console.log(fieldValue)
                    if (fieldValue.length > 0) {
                        for (var f = 0; f < fieldValue.length; f++) {
                            let myRefIndexNo = component.refIndexNo ? component.refIndexNo : 0;
                            let temp_length = metaComponents["id" + metaRefkey].temp_value.length;

                            let fieldValExists = 'no';
                            if (temp_length > 0) {

                                for (var fv = 0; fv < temp_length; fv++) {

                                    if (metaComponents["id" + metaRefkey].temp_value[fv].refIndexNo === myRefIndexNo) {
                                        fieldValExists = 'yes';
                                        //fieldValue[f].refIndexNo = myRefIndexNo;

                                        metaComponents["id" + metaRefkey].temp_value[fv].refIndexNo = myRefIndexNo;
                                    }

                                }
                            }
                            if (fieldValExists === 'no') {
                                let refIndexNo = component.refIndexNo ? component.refIndexNo : 0;
                                //console.log("refIndexNo"+refIndexNo)
                                // console.log(fieldValue[f])

                                let newValue = Object.assign({}, fieldValue[f]);
                                newValue.refIndexNo = refIndexNo;

                                if (refIndexNo > -1) {
                                    metaComponents["id" + metaRefkey].temp_value.splice(refIndexNo, 0, newValue);
                                } else {
                                    metaComponents["id" + metaRefkey].temp_value.push(newValue);
                                }
                            } else {
                                let refIndexNo = component.refIndexNo ? component.refIndexNo : 0;
                                let newValue = Object.assign({}, fieldValue[f]);
                                newValue.refIndexNo = refIndexNo;
                                metaComponents["id" + metaRefkey].temp_value.push(newValue);
                            }

                        }

                    }
                } else {
                    if (!Array.isArray(fieldValue)) {
                        let fieldValExists = 'no';
                        let temp_length = metaComponents["id" + metaRefkey].temp_value ? metaComponents["id" + metaRefkey].temp_value.length : 0;
                        let myRefIndexNo = component.refIndexNo ? component.refIndexNo : 0;
                        if (temp_length > 0) {
                            for (var fv = 0; fv < temp_length; fv++) {
                                if (metaComponents["id" + metaRefkey].temp_value[fv].refIndexNo === myRefIndexNo) {
                                    fieldValExists = 'yes';
                                    metaComponents["id" + metaRefkey].temp_value[fv].value = fieldValue;
                                }
                            }
                        }
                        if (fieldValExists === 'no') {

                            let refIndexNo = component.refIndexNo ? component.refIndexNo : 0;
                            let newValue = {
                                value: fieldValue,
                                refIndexNo: refIndexNo
                            };
                            if (refIndexNo > -1) {
                                metaComponents["id" + metaRefkey].temp_value.splice(refIndexNo, 0, newValue);
                            } else {
                                metaComponents["id" + metaRefkey].temp_value.push(newValue);
                            }

                        }
                    }

                }
            }
        }
        this.setState({metaComponents: metaComponents});
        return formInvalid;
    }

    setAllFieldValues = () => {
        let allFormPages = [...this.state.allFormPages];

        for (var pageIndex = 0; pageIndex < allFormPages.length; pageIndex++) {

            let thisPage = allFormPages[pageIndex];
            if (thisPage.accessRestriction === 'hidden') {
                let components = thisPage.components;

                if (components && components.length > 0) {
                    for (var i = 0; i < components.length; i++) {
                        let item = components[i];
                        item.showInForm = 'No';

                        if (item.type !== 'fieldset') {
                            if (this.props.getFormData.formvalues && this.props.getFormData.formvalues.length > 0) {
                                let formValues = this.props.getFormData.formvalues;

                                if (formValues.length > 0) {
                                    let matchedField = formValues.filter(formItem => formItem.masterid === item.masterid);
                                    if (item.linked === 'Yes') {  // Fields inside fieldset

                                        let children = formValues.filter(formItem => parseInt(formItem.masterid) === parseInt(item.parentid));
                                        let fieldsetvalues = [];

                                        //matchedField = this.setFieldsetValues(item, children, formValues);
                                        if (children.length > 0) {
                                            let fieldsetvalues = children[0].values;
                                            fieldsetvalues = Array.isArray(fieldsetvalues) ? fieldsetvalues : [];
                                            matchedField = fieldsetvalues.filter(formItem => formItem.masterid === item.masterid);
                                        } else {
                                            let childrenFields = formValues.map(vals => {
                                                let fieldValues = [];
                                                if (vals.values && vals.values !== '' && Array.isArray(vals.values)) {
                                                    fieldValues = Array.isArray(vals.values) ? vals.values : [];
                                                    let isObject = fieldValues.length > 0 && fieldValues[0] !== null && fieldValues[0] !== undefined && typeof fieldValues[0] === 'object' && fieldValues[0].constructor === Object;
                                                    return (isObject) ?
                                                        fieldValues.filter(formItem => parseInt(formItem.masterid) === parseInt(item.parentid)) : []
                                                } else {
                                                    return [];
                                                }
                                            });

                                            if (childrenFields.length > 0) {
                                                childrenFields = childrenFields.filter(list => list.length > 0);
                                                if (childrenFields.length > 0) {
                                                    if (childrenFields[0].length > 0) {
                                                        fieldsetvalues = childrenFields[0][0].values;
                                                        fieldsetvalues = Array.isArray(fieldsetvalues) ? fieldsetvalues : [];
                                                        matchedField = fieldsetvalues.filter(formItem => formItem.masterid === item.masterid);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    let matchedValues = (matchedField.length > 0 && matchedField[0] && matchedField[0].values) ? matchedField[0].values : [];
                                    this.assignFieldValues(matchedValues, item);
                                } else {
                                    if (item.type === 'attachment' || item.type === 'photocapture' || item.type === 'checkbox' || item.type === 'dataload' || item.type === 'check_image' || item.type === 'dropdown') {
                                        item.value = item.selectedItems;
                                    } else {
                                        item.value = "";
                                    }

                                }
                            }
                        }

                        this.validateField(item, 0);
                    }
                }
            }

        }
    }
    trackCollection = (action, collectionid) => {
        let trackParams = {
            "uid": this.state.uid,
            "collection_id": (collectionid && collectionid !== '') ? collectionid : this.state.collectionid,
            "action": action,
            "pageno": this.state.pageIndex + 1
        }

        this.props.trackCollection(trackParams);
    }

    initiateDataLoad(masterid) {

        this.setState({dataloadmasterid: masterid, showDataLoadModal: true})
    }

    Close() {
        this.setState({showDataLoadModal: false})
    }

    getEntities(searchtext) {
        this.props.getEntity({
            uid: this.state.uid,
            usertype: this.state.usertype,
            searchtext: searchtext ? searchtext : '',
            page: 1,
            per_page: 100
        }, this.state.formaccess);
    }

    getCollections(search) {

        let params = {
            group: 'All',
            search: search ? search : '',
            uid: this.state.uid,
            usertype: this.state.usertype,
            managecollection: 0,
            limit: 0,
            offset: 100
        };
        this.props.collections(params, this.state.formaccess);
    }

    resultDataListDataload(result, reference, valueField) {
        this.setState({[reference]: result});
        let uniqueIds = result.map(item => item[valueField]);
        this.setState({dataload_id: uniqueIds})

        //  this.props.getFields(reference, uniqueIds);

        if (uniqueIds !== '' && uniqueIds.length > 0 && uniqueIds[0] !== '') {
            this.props.showform({_id: uniqueIds[0], source: 'dataLoad'}, this.state.formaccess);
        }

    }

    sendOtpfield = (mobile, service, fieldid) => {
        let params = {
            fieldid: fieldid,
            collectionid: this.props.collectionId ? this.props.collectionId : this.state.collectionid,
            mobile: mobile,
            key: 'lsuser12345!',
            service: service
        };
        this.props.sendBsynOtp(params)
    }
    verifyOtp = (mobile, otp) => {
        let param = {
            collectionid: this.props.collectionId ? this.props.collectionId : this.state.collectionid,
            mobile: mobile,
            otp: otp
        };
        this.props.verifyBsynotp(param)
    }
    handleInputOtpChange = (e, pageIndx) => {
        var fieldNames = e.target.name;
        var fieldValues = e.target.value;
        this.setState({otpNames: fieldNames, otpValues: fieldValues, otpIndx: pageIndx});
    }
    paymentDetails = (paymentId) => {
        let param = {_id: paymentId};
        this.props.paymentSend(param)
    }

    showAddExternalValuesModal = (e, externalCollectionId, attributes) => {
        this.props.resetExternalFormData()
        this.setState({
            externalCollectionId: externalCollectionId,
            dropdownPlus: true,
            showDropdownModal: true,
            linkedMasterid: attributes.masterid,
            linkedExternalfield: attributes.externalfield,
            linkedSearchtext: '',
            dropdownSubmit: true
        })
        // this.props.showformLinkedExternally({ _id : externalCollectionId, uid : this.state.uid},formaccess);
        this.props.showformLinkedExternally({_id: externalCollectionId, uid: getProfile().id});

        // let param={
        //     from: 0,
        //     size: 50,
        //     index: ES_INDEX,
        //     search:'',
        //     "uid": getProfile().id,
        //     "usertype": getProfile().usertype,
        //     "dashboard":true,
        //     "facetrequired":true
        // }
        // this.props.collectionDetails(externalCollectionId, param)
    }
    linkedhideFormModal = (id) => {
        this.getExternalValues(this.state.linkedMasterid, this.state.linkedExternalfield, this.state.linkedSearchtext);

        this.setState({
            showDropdownModal: false,
            dropdownPlus: false,
            formSubmitting: 0
        })

        // this.props.showformLinkedExternally({ _id : this.state.collectionId , uid: getProfile().id});
    }

    /*getFieldsForDataLoad(reference, selectedId) {
        if(selectedId !== '' && selectedId.length > 0 && selectedId[0] !== '') {
            this.props.showform({ _id : selectedId[0], source :'dataLoad'});
        }
    }*/
    dataloadhandleChange(e) {
        var fieldName = e.target.id;
        var fieldValue = e.target.value;
        this.setState({[fieldName]: fieldValue});
    }

    getAggregate() {
        if (this.state.dataloadoption === '') {
            this.setState({optionError: 'Please select a field'})
        } else {
            this.setState({optionError: ''})
        }
        if (this.state.dataloadaggs === '') {
            this.setState({aggError: 'Please select an aggregate type'})
        } else {
            this.setState({optionError: ''})
        }
        if (this.state.dataloadoption !== '' && this.state.dataloadaggs !== '') {
            if (this.state.dataload_id !== '' && this.state.dataload_id.length > 0 && this.state.dataload_id[0] !== '') {
                let params = {
                    collectionid: this.state.dataload_id[0],
                    field: this.state.dataloadoption,
                    aggs: this.state.dataloadaggs,
                    masterid: this.state.dataloadmasterid
                }
                this.props.getAggregate(params);
            }
            // this.props.getAggregate(this.state.dataload_id, this.state.dataloadoption, this.state.dataloadaggs,this.props.attributes.masterid);
        }
    }

    /*getAggregate(selectedId, field, aggs,masterid) {
        if(selectedId !== '' && selectedId.length > 0 && selectedId[0] !== '') {
            let params = {collectionid: selectedId[0], field: field, aggs: aggs, masterid:masterid}
            this.props.getAggregate(params);
        }
    }*/

    calculateComputedValue = (masterid) => {
        //console.log("calculateComputedValue"+masterid)
        let computedValue = '';
        let formPages = this.state.formPages;
        let matchedComponent = formPages.map(page => page.components.filter(comp => (comp.masterid === masterid)));
        matchedComponent = [].concat.apply([], matchedComponent);
        //console.log(matchedComponent)
        if (matchedComponent.length > 0) {
            let this_field = matchedComponent[0];
            //console.log("ddddddddd")
            computedValue = this.evaluateFormula(this_field);
            //console.log(computedValue)
            this.setRawValue(this_field.id, computedValue, this_field.pageIndex)

            let roundedValue = computedValue ? computedValue : 0;
            this.setFieldValues(this_field.id, roundedValue, this.state.pageIndex)
        }
        return computedValue;
    }
    evaluateFormula = (component) => {
        // console.log("evaluateFormula")
        //console.log(component.masterid)
        let formPages = this.state.formPages;
        let pageIndex = this.state.pageIndex;
        let fieldValue = '';
        let formula = component.formula;
        let expression = '';
        let components = formPages.map(page => page.components.filter(field => (formPages[pageIndex].accessRestriction === 'writable' || formPages[pageIndex].accessRestriction === 'readonly')));
        components = [].concat.apply([], components);
        //console.log(formula)
        if (formula.length > 0) {
            for (var f = 0; f < formula.length; f++) {
                if (formula[f].type === 'operator') {
                    expression += formula[f].value;
                } else if (formula[f].type === 'field') {
                    let thisFieldId = formula[f].value;
                    let thisFieldArr = components.filter(item => item.masterid === thisFieldId);
                    let thisField = thisFieldArr.length > 0 ? thisFieldArr[0] : {};
                    if (thisField) {
                        let specComponent = component.linked === 'Yes' ? components.filter(item => item.masterid === formula[f].value) : components.filter(item => item.masterid === formula[f].value || item.referenceId === formula[f].value);
                        let fieldValueSum = 0;
                        if (specComponent.length > 0) {
                            for (var t = 0; t < specComponent.length; t++) {
                                let formulaValue = (specComponent[t] && specComponent[t].value) ? specComponent[t].value : 0;
                                if (specComponent[t].type === 'computedfield' && specComponent[t] && specComponent[t].value === undefined) {

                                    // console.log(specComponent[t].value)
                                    // console.log(component.masterid +"---" +specComponent[t].masterid)
                                    //return false;
                                    if (component.masterid !== specComponent[t].masterid) {
                                        formulaValue = this.evaluateFormula(specComponent[t]);
                                    } else {
                                        specComponent[t].value = 0;
                                        formulaValue = 0;
                                    }

                                }
                                fieldValueSum += parseFloat(formulaValue);
                            }
                        }
                        expression += parseFloat(fieldValueSum);
                    }
                } else if (formula[f].type === 'number') {
                    expression += parseFloat(formula[f].value);
                }
            }
        }
        try {
            fieldValue = eval(expression);

            fieldValue = (fieldValue !== '' && !isNaN(fieldValue) && fieldValue !== Infinity) ? fieldValue : '';
            //component.isValid = true;
        } catch (e) {
            if (e instanceof SyntaxError) {
                fieldValue = 'Invalid';
            }
        }
        return fieldValue;
    }

    /* Calculate Date compute for triggered fields(only for Date compute field) */
    calculateComputedValueDate = (masterid, meta) => {
        let computedValue = '';
        let formPages = [...this.state.formPages];
        let matchedComponent = formPages.map(page => page.components.filter(comp => (comp.masterid === masterid)));
        matchedComponent = [].concat.apply([], matchedComponent);
        if (matchedComponent.length > 0) {
            let this_field = matchedComponent[0];
            computedValue = this.evaluateFormulaDate(this_field, meta);
            computedValue = computedValue !== 'Invalid date' ? computedValue : '';

            this.setFieldValues(this_field.id, computedValue, this.state.pageIndex)
            if (Array.isArray(this_field.temp_value) && this_field.temp_value.length > 0) {
                this_field.temp_value[0].value = computedValue;
            }
        }

        return computedValue;
    }
    evaluateFormulaDate = (component, metaVal) => {

        let formPages = this.state.formPages;
        let pageIndex = this.state.pageIndex;
        let fieldValue = '';
        let formulaValue = '';
        let dateField = '';
        let formulaNumField = '';
        let formulaDateField = '';
        let Mdate = '';
        let Cdate = '';
        let formula = component.formula;
        let expression = '';
        let components = formPages.map(page => page.components.filter(field => (formPages[pageIndex].accessRestriction === 'writable' || formPages[pageIndex].accessRestriction === 'readonly')));
        components = [].concat.apply([], components);
        let formulaField = [];
        let autoformulaVal = 0;
        let autoformulaValArray = 0;
        let ageCalculate = 0;
        let numberFieldPresent = 0;
        let meta = this.props.getFormData.meta;
        if (formula.length > 0) {
            for (var f = 0; f < formula.length; f++) {
                if (formula[f].type === 'operator') {
                    var operator = formula[f].value;
                } else if (formula[f].type === 'field') {
                    let thisFieldId = formula[f].value;
                    let thisField = {};
                    let thisFieldArr = components.filter(item => item.masterid === thisFieldId);
                    thisField = thisFieldArr.length > 0 ? thisFieldArr[0] : {};
                    if (thisField) {
                        let specComponent = component.linked === 'Yes' ? components.filter(item => item.masterid === formula[f].value) : components.filter(item => item.masterid === formula[f].value || item.referenceId === formula[f].value);
                        let fieldValueSum = '';
                        if (specComponent.length > 0) {
                            for (var t = 0; t < specComponent.length; t++) {
                                formulaValue = (specComponent[t] && specComponent[t].value) ? specComponent[t].value : '';
                                if (specComponent[t].type === 'datecompute' && specComponent[t] && !specComponent[t].value) {
                                    formulaValue = this.evaluateFormulaDate(specComponent[t]);
                                }
                                if (specComponent[t].autocapture && specComponent[t].autocapture === 'Yes') {
                                    formulaValue = (specComponent[t] && specComponent[t].value) ? specComponent[t].value : '';
                                    autoformulaVal++;
                                    if (formulaValue !== '') {
                                        formulaValue = moment(formulaValue).format("yyyy-MM-DD");
                                        autoformulaValArray++;
                                    }
                                }
                                if (specComponent[t] && specComponent[t].value && specComponent[t].datetime && specComponent[t].datetime === 'Yes') {
                                    formulaValue = (specComponent[t] && specComponent[t].value) && specComponent[t].value !== '' ? moment(specComponent[t].value).format("YYYY-MM-DD") : '';
                                }
                                if (specComponent[t].autocapture && specComponent[t].autocapture === 'Yes' && specComponent[t].datetime && specComponent[t].datetime === 'Yes') {
                                    formulaValue = (specComponent[t] && specComponent[t].value) && specComponent[t].value !== '' ? moment(specComponent[t].value).format("YYYY-MM-DD") : '';
                                }
                                if (formulaValue !== '') {
                                    formulaField.push(formulaValue)
                                }
                                if (formulaField.length > 1) {
                                    var date1 = new Date(formulaField[0]);
                                    var date2 = new Date(formulaField[1]);
                                    if (ageCalculate > 0) {
                                        Difference_In_Days = date2.getFullYear() - date1.getFullYear();
                                    } else {
                                        var Difference_In_Time = date1.getTime() - date2.getTime();  //To calculate the time difference of two dates
                                        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); // To calculate the no. of days between two dates
                                    }
                                    fieldValueSum = Math.abs(Difference_In_Days);
                                } else if (formulaDateField !== '') {
                                    var date1 = new Date(formulaDateField);
                                    var date2 = new Date(formulaValue);
                                    var Difference_In_Time = date1.getTime() - date2.getTime();  //To calculate the time difference of two dates
                                    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); // To calculate the no. of days between two dates
                                    fieldValueSum = Math.abs(Difference_In_Days);
                                } else {
                                    if (formulaValue !== '') {
                                        fieldValueSum = moment(formulaValue).format("DD-MM-yyyy");
                                    }
                                }
                                dateField = formulaValue;
                                formulaNumField = formulaValue;
                                expression = fieldValueSum;
                            }
                        }
                    }
                    if (formula[f].value === 'm_date' || formula[f].value === 'c_date') {
                        let fieldValueSum = '';
                        let formulaValue = ''
                        if (formula[f].value === 'm_date') {
                            if (meta && meta.updatedate !== '') {
                                formulaValue = moment(meta.updatedate).format("yyyy-MM-DD");
                            }
                        }
                        if (formula[f].value === 'c_date') {
                            if (meta && meta.createdate !== '') {
                                formulaValue = moment(meta.createdate).format("yyyy-MM-DD");
                            }
                        }
                        if (formulaValue !== '') {
                            formulaField.push(formulaValue)
                        }
                        if (formulaField.length > 1) {
                            var date1 = new Date(formulaField[0]);
                            var date2 = new Date(formulaField[1]);
                            var Difference_In_Time = date1.getTime() - date2.getTime();  //To calculate the time difference of two dates
                            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); // To calculate the no. of days between two dates
                            fieldValueSum = Math.abs(Difference_In_Days);
                            expression = fieldValueSum;
                        }
                    }

                    if (formula[f].value === 'current_date') {
                        let fieldValueSum = '';
                        let currentDate = new Date();
                        currentDate = moment(currentDate).format("yyyy-MM-DD");
                        formulaField.push(currentDate)
                        if (formulaField.length > 1) {
                            var date1 = new Date(formulaField[0]);
                            var date2 = new Date(formulaField[1]);
                            var Difference_In_Days = date2.getFullYear() - date1.getFullYear();  //To calculate the year difference of two dates
                            fieldValueSum = Math.abs(Difference_In_Days);
                            expression = fieldValueSum;
                        }
                        ageCalculate++;
                    }

                } else if (formula[f].type === 'date') {
                    let formulaDate = formula[f].value;
                    let fieldValueSum = '';
                    if (dateField !== '') {
                        var date1 = new Date(dateField);
                        var date2 = new Date(formulaDate);
                        var Difference_In_Time = date1.getTime() - date2.getTime();  //To calculate the time difference of two dates
                        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); // To calculate the no. of days between two dates
                        fieldValueSum = Math.abs(Difference_In_Days);
                    } else {
                        fieldValueSum = formulaDate;
                    }
                    formulaDateField = fieldValueSum;
                    expression = fieldValueSum;
                } else if (formula[f].type === 'number') {
                    var result = formulaNumField;
                    numberFieldPresent++;
                    if (formula[f].value) {
                        var num = formula[f].value;
                        result = new Date(result);
                        if (operator === '+') {
                            var expressn = result.setTime(result.getTime() + num * 86400000);
                            expression = moment(expressn).format("DD-MM-yyyy");
                        }
                        if (operator === '-') {
                            var expressn = result.setTime(result.getTime() - num * 86400000);
                            expression = moment(expressn).format("DD-MM-yyyy");
                        }
                    }
                }
            }
        }
        try {
            fieldValue = expression;
            if (autoformulaVal > 1 && autoformulaValArray < 2) {
                fieldValue = '';
            }

            if (numberFieldPresent === 0) {
                var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
                // Match the date format through regular expression
                if (fieldValue.match(dateformat)) {
                    fieldValue = '';
                }
            }
            fieldValue = (fieldValue !== '' && fieldValue !== Infinity) ? fieldValue : '';
            //component.isValid = true;
        } catch (e) {
            if (e instanceof SyntaxError) {
                fieldValue = 'Invalid';
            }
        }
        return fieldValue;
    }

    /* Calculate Date compute for c_date, m_date & autocapture(only for edit form) */
    calculateComputedModfiydate = (formPages) => {

        // let formPages = formPages
        let pageIndex = this.state.pageIndex;
        let component = formPages.map(page => page.components.filter(comp => (comp.type === 'datecompute')));
        component = [].concat.apply([], component);
        let componentFormula = []
        let formula = []
        let autocomponent = []

        if (component.length > 0) {
            component.map((list, index) => {
                if (list.formula && list.formula.length !== 0) {

                    // list.formula.map((item, indx) => {

                    if (list.formula[0].type === 'field' && list.formula[2].type === 'field') {
                        if (list.formula[0].value === 'm_date' || list.formula[2].value === 'c_date') {
                            componentFormula.push(list)

                        } else if (list.formula[0].value === 'c_date' || list.formula[2].value === 'm_date') {
                            componentFormula.push(list)

                        } else {
                            autocomponent.push(list)

                        }
                    }
                    // })
                }
            })
        }

        let computedValue = '';
        let fieldValueSum = '';
        let Mdate = '';
        let Cdate = '';
        let meta = this.props.getFormData.meta;
        let fieldValue = '';
        let expression = '';
        let formulaField = [];
        let formulaValue = '';
        if (componentFormula.length !== 0) {
            componentFormula.map((componentData, index) => {
                let this_field = componentData;
                let components = componentData;
                formula = this_field.formula ? this_field.formula : [];
                if (formula.length > 0) {

                    if (formula[0].type === 'field' && formula[2].type === 'field') {
                        if (formula[0].value === 'm_date' || formula[2].value === 'm_date') {
                            if (meta && meta.updatedate !== '') {
                                Mdate = moment(meta.updatedate).format("yyyy-MM-DD");
                                formulaField.push(Mdate)
                            }
                        }
                        if (formula[0].value === 'c_date' || formula[2].value === 'c_date') {
                            if (meta && meta.createdate !== '') {
                                Cdate = moment(meta.createdate).format("yyyy-MM-DD");
                                formulaField.push(Cdate)
                            }
                        }
                        if (formula[0].value !== 'm_date' && formula[0].value !== 'c_date') {
                            let specautocomponent = formPages.map(page => page.components.filter(field => (formPages[pageIndex].accessRestriction === 'writable' || formPages[pageIndex].accessRestriction === 'readonly')));
                            specautocomponent = [].concat.apply([], specautocomponent);

                            if (specautocomponent.length > 0) {
                                let specautocomponents = specautocomponent.linked === 'Yes' ? specautocomponent.filter(item => item.masterid === formula[0].value) : specautocomponent.filter(item => item.masterid === formula[0].value || item.referenceId === formula[0].value);

                                formulaValue = (specautocomponents && specautocomponents.value && specautocomponents.value !== '') ? moment(specautocomponents.value).format("yyyy-MM-DD") : '';
                            }
                            if (formulaValue !== '') {
                                formulaField.push(formulaValue)
                            }
                        }
                        if (formula[2].value !== 'c_date' && formula[2].value !== 'm_date') {
                            let specautocomponent = formPages.map(page => page.components.filter(field => (formPages[pageIndex].accessRestriction === 'writable' || formPages[pageIndex].accessRestriction === 'readonly')));
                            specautocomponent = [].concat.apply([], specautocomponent);
                            if (specautocomponent.length > 0) {
                                let specautocomponents = specautocomponent.linked === 'Yes' ? specautocomponent.filter(item => item.masterid === formula[2].value) : specautocomponent.filter(item => item.masterid === formula[2].value || item.referenceId === formula[2].value);

                                formulaValue = (specautocomponents && specautocomponents.value && specautocomponents.value !== '') ? moment(specautocomponents.value).format("yyyy-MM-DD") : '';
                            }
                            if (formulaValue !== '') {
                                formulaField.push(formulaValue)
                            }
                        }


                        if (formulaField.length > 1) {
                            if (formulaField[0] && formulaField[0] !== '' && formulaField[1] && formulaField[1] !== '') {
                                var date1 = new Date(formulaField[0]);
                                var date2 = new Date(formulaField[1]);
                                var Difference_In_Time = date1.getTime() - date2.getTime();  //To calculate the time difference of two dates
                                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); // To calculate the no. of days between two dates
                                fieldValueSum = Math.abs(Difference_In_Days);
                                expression = fieldValueSum;

                            }
                        }
                    }
                }

                computedValue = fieldValueSum;
                if ((Array.isArray(this_field.temp_value)) && (this_field.temp_value.length !== 0) && (this_field.temp_value[0].value !== '')) {

                } else {
                    this.setRawValue(this_field.id, computedValue, this_field.pageIndex)
                    let roundedValue = computedValue && computedValue !== '' ? computedValue : '';
                    this.setFieldValues(this_field.id, roundedValue, this.state.pageIndex)
                }

            })
        }

        formulaField = [];
        fieldValueSum = '';
        let autocomponents = formPages.map(page => page.components.filter(field => (formPages[pageIndex].accessRestriction === 'writable' || formPages[pageIndex].accessRestriction === 'readonly')));
        autocomponents = [].concat.apply([], autocomponents);

        if (autocomponent.length !== 0) {
            autocomponent.map((component, index) => {
                let this_field = component;
                formula = component.formula;

                if (formula.length > 0) {
                    let specComponents = []
                    autocomponents.map((item, indx) => {
                        if (autocomponents.linked === 'Yes') {
                            if (item.masterid === formula[0].value || item.masterid === formula[2].value) {
                                if (item.autocapture && item.autocapture === 'Yes') {
                                    specComponents.push(item);
                                }
                            }
                        } else if (item.masterid === formula[0].value || item.referenceId === formula[0].value || item.masterid === formula[2].value || item.referenceId === formula[2].value) {

                            if (item.autocapture && item.autocapture === 'Yes') {
                                specComponents.push(item);
                            }
                        }

                    })

                    if (specComponents.length > 1) {

                        for (var t = 0; t < specComponents.length; t++) {

                            formulaValue = (specComponents[t] && specComponents[t].value && specComponents[t].value !== '') ? moment(specComponents[t].value).format("yyyy-MM-DD") : '';

                            if (formulaValue !== '') {
                                formulaField.push(formulaValue)
                            }

                            if (formulaField.length > 1) {

                                if (formulaField[0] && formulaField[0] !== '' && formulaField[1] && formulaField[1] !== '') {
                                    var date1 = new Date(formulaField[0]);
                                    var date2 = new Date(formulaField[1]);
                                    var Difference_In_Time = date1.getTime() - date2.getTime();  //To calculate the time difference of two dates

                                    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); // To calculate the no. of days between two dates

                                    fieldValueSum = Math.abs(Difference_In_Days);
                                    component.autotriggerOnSelect = true;
                                }
                            }
                            expression = fieldValueSum;

                        }
                        computedValue = fieldValueSum;
                        this.setRawValue(this_field.id, computedValue, this_field.pageIndex)
                        let roundedValue = computedValue && computedValue !== '' ? computedValue : '';
                        this.setFieldValues(this_field.id, roundedValue, this.state.pageIndex)
                        if (Array.isArray(this_field.temp_value) && this_field.temp_value.length !== 0) {
                            this_field.temp_value[0].value = computedValue;
                        }
                    }
                }

            })
        }

        return computedValue;
    }

    /* autocapture update mode(only for date picker) */
    autoCaptureUpdateMode = (masterid) => {
        let computedValue = '';
        let formPages = this.state.formPages;
        let matchedComponent = formPages.map(page => page.components.filter(comp => (comp.masterid === masterid)));
        matchedComponent = [].concat.apply([], matchedComponent);
        if (matchedComponent.length > 0) {
            let this_field = matchedComponent[0];
            computedValue = this.evaluateAutoCaptureUpdateMode(this_field);
            computedValue = computedValue !== 'Invalid date' ? computedValue : '';
            this.setFieldValues(this_field.id, computedValue, this.state.pageIndex)
        }
        return computedValue;
    }
    evaluateAutoCaptureUpdateMode = (component) => {

        let changeFieldValue = false
        let fieldValue = '';
        let onchangefield = '';
        let clearvalue = false;
        let formPages = this.state.formPages;
        if (component.onchangefield) {
            onchangefield = component.onchangefield;
        }
        let matchComponent = formPages.map(page => page.components.filter(comp => (comp.masterid === onchangefield)));

        matchComponent = [].concat.apply([], matchComponent);
        if (matchComponent.length > 0 && component.onchangevalue) {

            matchComponent.map((item, index) => {
                if (item.value && item.value.length !== 0) {
                    item.value.map((list, index) => {
                        if (list.value === component.onchangevalue) {
                            if (item.hasFieldChanged) {
                                changeFieldValue = true;
                                component.datechange = true;
                            } else {
                                changeFieldValue = false;
                                component.datechange = false;
                            }
                        } else {
                            if (item.hasFieldChanged) {
                                clearvalue = true;
                                changeFieldValue = false;
                                component.datechange = false;
                            }
                        }
                    })
                }
            })
        }


        fieldValue = changeFieldValue ? this.assignDateToAutocapture(component) : (clearvalue ? '' : component.value);
        return fieldValue;
    }

    assignDateToAutocapture = (component) => {
        var date = new Date();
        let fieldValue = component.value;
        if (component.capturemode) {
            if (component.capturemode === 'insert') {
                if (!this.props.editId) {
                    fieldValue = component.datetime && component.datetime === 'Yes' ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment(date).format("YYYY-MM-DD");
                }
            } else if (component.capturemode === 'update') {
                if (this.props.editId) {
                    fieldValue = component.datetime && component.datetime === 'Yes' ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment(date).format("YYYY-MM-DD");

                }
            } else {
                fieldValue = component.datetime && component.datetime === 'Yes' ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment(date).format("YYYY-MM-DD");
            }
        } else {
            if (!this.props.editId) {
                fieldValue = component.datetime && component.datetime === 'Yes' ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment(date).format("YYYY-MM-DD");
            }
        }

        return fieldValue;
    }

    calculateCurrentAge = (masterid, formPage) => {
        let currentAgeValue = '';
        // let formPages = this.state.formPages;
        let formPages = formPage ? formPage : this.state.formPages;
        let matchedComponent = formPages.map(page => page.components.filter(comp => (comp.masterid === masterid)));
        matchedComponent = [].concat.apply([], matchedComponent);
        if (matchedComponent.length > 0) {
            let this_field = matchedComponent[0];
            currentAgeValue = this.evaluateAge(this_field, formPage);
            this.setRawValue(this_field.id, currentAgeValue, this_field.pageIndex)

            let roundedValue = currentAgeValue !== '' ? currentAgeValue : 0;
            if (roundedValue === 0) {
                roundedValue = '0';
            } else {
                roundedValue = roundedValue;
            }
            this.setFieldValues(this_field.id, roundedValue, this.state.pageIndex)
        }
        return currentAgeValue;
    }
    evaluateAge = (component, formPage) => {
        let formPages = formPage ? formPage : this.state.formPages;
        let pageIndex = this.state.pageIndex;
        let fieldValue = '';
        let currentAge = component.currentage;
        let expression = '';
        let components = formPages.map(page => page.components.filter(field => field.showInForm === 'Yes' && (formPages[pageIndex].accessRestriction === 'writable' || formPages[pageIndex].accessRestriction === 'readonly')));
        components = [].concat.apply([], components);
        if (currentAge !== '' && currentAge !== 0) {
            let thisFieldId = currentAge;
            let thisFieldArr = components.filter(item => item.masterid === thisFieldId);
            let thisField = thisFieldArr.length > 0 ? thisFieldArr[0] : {};
            if (thisField) {
                let specComponent = components.filter(item => item.masterid === currentAge);
                let fieldValueSum = 0;
                if (specComponent.length > 0) {
                    for (var t = 0; t < specComponent.length; t++) {
                        let formulaValue = (specComponent[t] && specComponent[t].value) ? specComponent[t].value : '';
                        let tempValue = (specComponent[t] && specComponent[t].temp_value && specComponent[t].temp_value[0]) ? specComponent[t].temp_value[0].value : '';
                        if (formulaValue !== '') {
                            let fieldValueSum = '';
                            let currentDate = new Date();
                            currentDate = moment(currentDate).format("yyyy-MM-DD");
                            let birthDate = formulaValue;
                            birthDate = birthDate !== '' ? moment(birthDate).format("yyyy-MM-DD") : '';
                            if (birthDate !== '') {
                                var date1 = new Date(birthDate);
                                var date2 = new Date(currentDate);
                                var Difference_In_Days = date2.getFullYear() - date1.getFullYear();  //To calculate the year difference of two dates
                                Difference_In_Days = Difference_In_Days > 0 ? Difference_In_Days : ''
                                fieldValueSum = Difference_In_Days !== '' ? Math.abs(Difference_In_Days) : '';
                                expression = fieldValueSum;
                                fieldValue = fieldValueSum;
                            }
                        }
                    }
                }
            }
        }
        try {
            fieldValue = (fieldValue !== '' && !isNaN(fieldValue) && fieldValue !== Infinity) ? fieldValue : '';
        } catch (e) {
            if (e instanceof SyntaxError) {
                fieldValue = 'Invalid';
            }
        }
        if (fieldValue === 0) {
            fieldValue = '0';
        } else {
            fieldValue = fieldValue;
        }
        return fieldValue;
    }

    generateNewValue(metaForm) {
        if (this.state.thankyoucontent && this.state.thankyoucontent != undefined && this.state.thankyoucontent !== '') {
            let thankyoulinkfield = this.state.thankyoulinkfield;
            let thankyounewcontent;
            let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
            let thankyouContent = this.state.thankyoucontent;
            if (base64regex.test(thankyouContent)) {
                thankyounewcontent = atob(thankyouContent);
                {
                    thankyoulinkfield &&
                    thankyoulinkfield.map((item, index) => {
                        if (item.id && item.label) {
                            let savedvalue = metaForm.filter(items => items.masterid === item.id);
                            thankyounewcontent = thankyounewcontent.replace(new RegExp('{' + item.label + '}', 'gi'), savedvalue[0].value[0].value);
                        }
                    })
                }
            } else {
                thankyounewcontent = thankyouContent;
            }

            this.setState({textcontentmod: thankyounewcontent})
        }
    }

    showPageMenu = () => {
        this.setState({
            showPageList: true
        })
    }
    hidePageMenu = () => {
        this.setState({
            showPageList: false
        })
    }

    render() {
        let Formtemplate = this.state.formTemplate;
        let Formwidth = this.state.formWidth;
        let formTemplateType = this.state.formTemplateType;
        let visiblePages = this.state.formPages.filter(page => page.accessRestriction !== 'hidden');
        if (this.props.payChange) {
            Formtemplate = this.props.payFormtemplate ? this.props.payFormtemplate : 4;
        }

        console.log(this.state.formPages)
        return (
            <React.Fragment>
                {this.state.showAuthPop &&
                <section className="login-area form-login">
                    <div className="login-bg ">
                        <div className="login-block">
                            <div className="login-input-block int">
                                <input type="text" name="form_username" id="form_username" className="login-input error"
                                       placeholder="User Name" onChange={this.authInputChange}/>
                            </div>
                            <div className="login-input-block">
                                <input type="Password" name="form_password" id="form_password"
                                       className="login-input error" placeholder="Password"
                                       onChange={this.authInputChange}/>
                            </div>
                            <div className="login-button-block">
                                <button className="login-button"
                                        onClick={(e) => this.authenticateForm(e)}>Authenticate
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                }
                {Formtemplate === '' &&
                <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                }

                {Formtemplate !== '' &&
                <div
                    className={`modal show full-width detailed-form-view direct-flow web-view form-full-height template-${Formtemplate === "" ? '1' : Formtemplate} width-${Formwidth} ${(visiblePages.length === 1 || Formtemplate === "cortiza" || this.state.singlePageForm) && 'alone-page'} ${this.state.embedForm && 'embedded-form'} ${this.props.previewOnly && 'preview-form'}  fade  ${Formtemplate === "cortiza" && "template-4"}`}
                    id="collectionModal" tabIndex="-1" role="dialog"
                    aria-labelledby="mngModal" style={{display: "block"}}>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            {this.state.externalSuccessMessage === false &&
                            <React.Fragment>

                                <div className="modal-body">

                                    {this.state.formPages.length === 0 &&
                                    <span className="Loader-holder">
                                                    <LoaderSvg/>
                                                </span>
                                    }

                                    {this.state.formPages.length > 0 &&
                                    <div className="detailed-form-view-container">
                                        <div
                                            className={`detailed-form-view-left ${this.state.showPageList && 'active'}`}>
                                            <button className="mob-close" onClick={this.hidePageMenu}></button>
                                            <ul className="linked-collection-record">
                                                {this.state.formPages.map((fpage, pIndex) =>
                                                    <li key={`pageli-${pIndex}`}
                                                        onClick={(e) => this.changePageNav(pIndex)}>
                                                        <nav className={this.state.pageIndex === pIndex && "active"}>
                                                            <h5>{fpage.heading !== 'NIL' ? fpage.heading : `Page ${pIndex + 1}`} </h5>
                                                        </nav>
                                                    </li>
                                                )}

                                            </ul>
                                        </div>

                                        <div
                                            className={`detailed-form-view-right ${this.state.newHeader ? 'header-component-top' : ''} ${this.state.newHeader && this.state.headerpersistent === 'on' ? 'header-persistent' : ''} ${this.state.newHeader && this.state.headerlogo === '' ? 'header-only' : ''} ${this.state.newHeader && this.state.headerwidth === 'fullwidth' ? 'header-full-width' : (this.state.newHeader && this.state.headerwidth === 'persistent' ? 'sticky-header' : '')}`}>
                                            <div
                                                className={`detailed-form-view-right-flow-top ${this.state.newFooter ? 'footer-component' : ''} ${this.state.newFooter && this.state.footerwidth === 'fullwidth' ? 'footer-full-width' : (this.state.newFooter && this.state.footerwidth === 'persistent' ? 'footer-normal' : '')}`}>
                                                <Scrollbars>
                                                    {!this.props.recordBasedForm &&
                                                    <div className="detailed-form-view-right-top">
                                                        <div className="container">
                                                            {!this.state.newHeader &&
                                                            <div className="form-preview-header">
                                                                <h1 className="preview-subheader">{this.state.title}</h1>
                                                                <p className="preview-desc">{this.state.desc}</p>
                                                                {(!this.state.embedForm && !this.state.iosForm && !this.props.previewOnly) &&
                                                                <button type="button" className="close"
                                                                        data-dismiss="modal"
                                                                        aria-label="Close"
                                                                        onClick={() => this.hideFormModal()}/>
                                                                }
                                                            </div>
                                                            }
                                                            {this.state.newHeader &&
                                                            <div
                                                                className={`form-preview-header header-component ${this.state.headerlogoalignment === 'right' ? 'header-order-1' : 'header-order-0'}`}
                                                                style={{backgroundColor: this.state.headerbgcolor}}>
                                                                <div className="form-preview-header-image">
                                                                    <img src={this.state.headerlogo}/>
                                                                </div>
                                                                <div className="form-preview-header-content">
                                                                    <h1 className="preview-subheader" style={{
                                                                        textAlign: this.state.headertextalignment,
                                                                        color: this.state.headertextcolor,
                                                                        fontSize: this.state.headertextsize + 'px'
                                                                    }}>{this.state.headertext}</h1>
                                                                </div>
                                                                {!this.state.embedForm &&
                                                                <button type="button" className="close"
                                                                        data-dismiss="modal"
                                                                        aria-label="Close"
                                                                        onClick={() => this.hideFormModal()}></button>
                                                                }
                                                            </div>
                                                            }

                                                        </div>
                                                    </div>
                                                    }
                                                    <div
                                                        className={`detailed-form-view-right-body ${formTemplateType === 'typeform' && 'type-form'}`}>
                                                        <div
                                                            className={`${formTemplateType === 'cortiza' ? "multiple-form-container" : "container"}`}>
                                                            {this.state.formPages.map((page, pageIndex) =>

                                                                <React.Fragment>
                                                                    {this.renderFormpage(page, pageIndex, formTemplateType)}
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {!this.props.previewOnly &&
                                                    <div className="detailed-form-view-right-bottom">
                                                        <div className="container">
                                                            {(!this.state.hideSubmitButton && formTemplateType !== 'typeform') &&
                                                            <div className="detailed-form-view-right-bottom-wrap">
                                                                <div>
                                                                    <div className="side-page-link">
                                                                        <button
                                                                            className="side-page-link-button"
                                                                            onClick={this.showPageMenu}>Pages
                                                                        </button>

                                                                    </div>
                                                                </div>
                                                                <div className="button-block">
                                                                    <button className="general-btn"
                                                                            onClick={(e) => this.submitForm()}>
                                                                        {(parseInt(this.state.pageIndex) === parseInt(this.state.formSubmitIndex)) ? "Submit" : "Next"}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.state.newFooter &&
                                                    <footer className="footer-block">
                                                        <div className="container">
                                                            <div
                                                                className={`footer-wrap footer-col-` + this.state.footercolumns}
                                                                style={{backgroundColor: this.state.footerbgcolor}}>

                                                                {this.state.footertextalignment === 'icon' && this.state.footericon.length > 0 && this.state.footericon.map((list, index) =>
                                                                    <div className="footer-column" key={index}>
                                                                        {list.subcolumn && list.subcolumn.length > 0 && list.subcolumn.map((item, index) =>
                                                                            <div className="footer-icon-content"
                                                                                 key={index}>
                                                                                <div className="footer-icon-image">
                                                                                    <img
                                                                                        src={item.icon !== '' ? item.icon : 'https://d22znjc9y9upyw.cloudfront.net/assets/bsynapse/images/default.png'}/>
                                                                                </div>
                                                                                <div className="footer-icon-text">
                                                                                    {item.url !== '' &&
                                                                                    <p style={{
                                                                                        color: this.state.footertextcolor,
                                                                                        fontSize: this.state.footertextsize + 'px'
                                                                                    }}>
                                                                                        <a target={`_blank`}
                                                                                           href={item.url}> {item.text}</a>
                                                                                    </p>
                                                                                    }
                                                                                    {item.url === '' &&
                                                                                    <p style={{
                                                                                        color: this.state.footertextcolor,
                                                                                        fontSize: this.state.footertextsize + 'px'
                                                                                    }}>
                                                                                        {item.text}
                                                                                    </p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}

                                                                {this.state.footertextalignment === 'bullet' && this.state.footertextbullet.length > 0 && this.state.footertextbullet.map((list, index) =>
                                                                    <div className="footer-column" key={index}>
                                                                        {list.subcolumn && list.subcolumn.length > 0 && list.subcolumn.map((item, index) =>
                                                                            <div className="footer-bullettext"
                                                                                 key={index}>
                                                                                <div className="footer-icon-content">
                                                                                    <div className="footer-icon-text">
                                                                                        {item.url !== '' &&
                                                                                        <p style={{
                                                                                            color: this.state.footertextcolor,
                                                                                            fontSize: this.state.footertextsize + 'px'
                                                                                        }}>
                                                                                            <a target={`_blank`}
                                                                                               href={item.url}>{item.text}</a>
                                                                                        </p>
                                                                                        }
                                                                                        {item.url === '' &&
                                                                                        <p style={{
                                                                                            color: this.state.footertextcolor,
                                                                                            fontSize: this.state.footertextsize + 'px'
                                                                                        }}>
                                                                                            {item.text}
                                                                                        </p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </footer>
                                                    }
                                                </Scrollbars>
                                            </div>
                                        </div>
                                    </div>


                                    }

                                </div>
                                {this.state.locationalert &&
                                <AlertModal id="filled"
                                            title="This form will access your current location"
                                            OK_button_text="Ok"
                                            OK_action={() => this.saveLocationAlert()}
                                            showModal={this.state.locationalert}
                                            CANCEL_action={() => this.cancelLocationAlert()}
                                />
                                }

                                {this.state.formSaved &&
                                <AlertModal id="filled"
                                            title="The form is submitted successfully"
                                            OK_button_text="Ok"
                                            OK_action={() => this.hideSavedFormAlert()}
                                            showModal={this.state.formSaved}
                                            CANCEL_button={`hide`}
                                />
                                }
                                {this.state.errorMessageOnSaving &&
                                <AlertModal id="filled"
                                            title="Some technical issues occured. Please try again"
                                            OK_button_text="Ok"
                                            OK_action={() => this.hideErrorAlert()}
                                            showModal={this.state.errorMessageOnSaving}
                                            CANCEL_button={`hide`}
                                />
                                }
                            </React.Fragment>
                            }
                            {this.state.externalSuccessMessage &&

                            <React.Fragment>

                                <div className="modal-body">
                                    <div className="submit-block">
                                        <h5>Form submitted successfully</h5>{/*<h5>Form submitted successfully. Please check your Email to proceed
                                            further.</h5>*/}
                                    </div>
                                </div>
                            </React.Fragment>
                            }

                            {this.state.draftSuccessMessage &&

                            <AlertModal id="draft-success"
                                        title="The details entered has been saved as a draft"
                                        OK_button_text="Ok"
                                        OK_action={() => this.hideSuccessDraft()}
                                        showModal={this.state.draftSuccessMessage}
                                        CANCEL_button={`hide`}
                            />
                            }

                        </div>
                    </div>
                </div>

                }

                {this.state.showDataLoadModal &&
                <SimpleModal title="Data Load" Close={this.Close}
                             haveFooter={1}
                             OKLabel={"Submit"}
                             OKAction={this.getAggregate}
                             cancelAction={this.Close}
                             children={<React.Fragment>
                                 <div className="form-container">
                                     <div className={'form-option-header'}>
                                         <div className="radio-wrapper">

                                             <div className="radio-block">
                                                 <input type="radio" name="datalistType" id="datalistType"
                                                        value="collection" onChange={this.dataloadhandleChange}
                                                        defaultChecked={this.state.datalistType === 'collection'}/>
                                                 <label>Collection </label>
                                             </div>
                                             <div className="radio-block">
                                                 <input type="radio" name="datalistType" id="datalistType"
                                                        value="entity" onChange={this.dataloadhandleChange}
                                                        defaultChecked={this.state.datalistType === 'entity'}/>
                                                 <label>Entity </label>
                                             </div>
                                         </div>

                                         {/*Collection <input type="radio" name="datalistType" id="datalistType" value="collection" onChange={this.handleRadioChange}  defaultChecked={ this.state.datalistType === 'collection'}/>*/}
                                         {/*Entity <input type="radio" name="datalistType" id="datalistType"  value="entity" onChange={this.handleRadioChange} defaultChecked={ this.state.datalistType === 'entity'}/>*/}

                                     </div>

                                     <div className="container-left"
                                          style={{width: '50%', float: 'left', height: 'calc(100% - 50px)'}}>
                                         {this.state.datalistType === 'collection' &&
                                         <div className="task-inp-container new-entity list-collctn"
                                              id="dataload_collection">
                                             <AutoComplete
                                                 fieldTitle=''
                                                 sourceDataList={this.props.listAllCollections.collectionsubgroup}
                                                 placeholder="Search Collection"
                                                 searchField='title'
                                                 showIcon={false}
                                                 showSubLabel={true}
                                                 labelFields='title'
                                                 valueField='_id'
                                                 subLabelField=''
                                                 showProfile={true}
                                                 reference='selectedCollections'
                                                 selectedItems={this.state.selectedCollections}
                                                 resultDataList={this.resultDataListDataload}
                                                 id="to_users"
                                                 instantSearch={true}
                                                 actionMethod={this.getCollections}
                                                 multiSelect={false}
                                             />
                                         </div>
                                         }
                                         {this.state.datalistType === 'entity' &&
                                         <div className="task-inp-container new-entity list-collctn"
                                              id="dataload_entity">
                                             <AutoComplete
                                                 fieldTitle=''
                                                 sourceDataList={this.props.entitydata.entityList}
                                                 placeholder="Search entity"
                                                 searchField='name'
                                                 showIcon={false}
                                                 showSubLabel={true}
                                                 labelFields='name'
                                                 valueField='id'
                                                 subLabelField=''
                                                 showProfile={true}
                                                 reference='selectedEntities'
                                                 selectedItems={this.state.selectedEntities}
                                                 resultDataList={this.resultDataListDataload}
                                                 id="to_users"
                                                 instantSearch={true}
                                                 actionMethod={this.getEntities}
                                                 multiSelect={false}
                                             />
                                         </div>
                                         }
                                     </div>

                                     <div className="container-right" style={{
                                         width: '50%',
                                         float: 'left',
                                         'borderLeft': '1px solid #dddd ',
                                         height: 'calc(100% - 50px)',
                                         padding: '20px'
                                     }}>
                                         <div className="select-box eq-width">
                                             <select id="dataloadoption" onChange={this.dataloadhandleChange}
                                                     name="dataloadoption">
                                                 <option value="_all">Choose field</option>
                                                 {this.state.dataloadFields.map((item) => {
                                                         item.fieldLabel = (item.display_label && item.display_label !== '') ? item.display_label : item.label;

                                                         return (
                                                             <option value={item.masterid}>{item.fieldLabel}</option>
                                                         )
                                                     }
                                                 )}
                                             </select>
                                             <span>{this.state.optionError}</span>
                                         </div>
                                         <div className="select-box eq-width">
                                             <select id="dataloadaggs" onChange={this.dataloadhandleChange}
                                                     name="dataloadaggs">
                                                 <option value="_all">Choose Aggregation</option>
                                                 <option value="sum">Sum</option>
                                                 <option value="avg">Average</option>
                                             </select>
                                             <span>{this.state.aggError}</span>
                                         </div>
                                     </div>


                                 </div>
                             </React.Fragment>}
                />
                }
                {this.state.thankyouMessage &&
                <div id={`dis-pop`} className={`cs-modal fade bsy-modal fixed-md agree-modal  in show `}>
                    <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                        <div className="modal-content">
                            {this.state.subdomain !== 'data-atmantan' &&
                            <div className="modal-header">
                                <h5 className="task-head-text">{`Thank You Message`}</h5>
                                <div className="modal-header-options">
                                    <button className="modal-option-button" onClick={this.showAgreeCloseModal}>
                                        <span className="icon-in icon-close-in"></span>
                                    </button>
                                </div>
                            </div>
                            }

                            <div className="modal-body">
                                <div className="terms-text-wrapper-block">
                                    <div dangerouslySetInnerHTML={{__html: this.state.textcontentmod}}></div>
                                </div>
                            </div>
                            {this.state.subdomain !== 'data-atmantan' &&
                            <div className="modal-footer">
                                <Button className="general-btn grey" onClick={this.showAgreeCloseModal}> {`Ok`}</Button>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                }

                {this.state.showDropdownModal &&
                <LinkedFormModal
                    formTitle={'External Linked Form'}
                    formDesc={''}
                    collectionId={this.state.externalCollectionId}
                    referenceId={this.state.externalCollectionId}
                    editId={this.state.collecteddataid}
                    Formtemplate={this.props.formInfoExternal.showformdata.template}
                    dropdownPlus={this.state.dropdownPlus}
                    previewOnly={false}
                    formInfo={this.props.formInfoExternal}
                    linkedhideFormModal={this.linkedhideFormModal}
                    oldcollectionid={this.state.collectionid}
                />
                }


            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    formInfo: [],
    entityFormInfo: state.FormsReducer.showFormModal,
    externalData: state.CollectionsReducer.getExternalForm,
    getSavedForm: state.CollectionsReducer.getSavedForm,
    getFormData: state.FormsReducer.getlistFormData,
    entitydata: state.EntityReducer.EntityList,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    sendbsynOtp: state.CollectionsReducer.sendbsynOtp,
    verifybsynotp: state.CollectionsReducer.verifybsynotp,
    formAuthData: state.CollectionsReducer.getAuthenticateForm,
    getFormValues: state.CollectionsReducer.getFormData,

});

export default withRouter(connect(mapStateToProps, {
    getExternalValues: collectionActions.getExternalValues,
    saveForm: collectionActions.saveForm,
    collections: collectionActions.listTodoCollections,
    getEntity: entityActions.searchEntity,
    entityshowform: formActions.showform,
    getAggregate: collectionActions.getAggregate,
    saveReadonlyForm: collectionActions.saveReadonlyForm,
    checkIfUnique: collectionActions.checkIfUnique,
    resetUniqueValues: collectionActions.resetUniqueValues,
    trackCollection: collectionActions.trackCollection,
    resetForm: formActions.resetForm

})(ShowFormModal));

