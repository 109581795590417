import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";
import Modal from "../../views/Modal";
import AddNewNewsMedia from "./AddNewNewsMedia";
import {getProfile} from "../../../utilities/AuthService";
import {FilterAccordian,NewsMediaTile} from "./ProjectNewsMediaData";
import * as projectActions from "../../../actions/Project";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import ChatContainer from "../chat/ChatContainer";

class ProjectChat extends Component {

    constructor(props) {
        super(props);

        this.state={
            expandHeader:false,
            Addopen:false,
            openFilter:true,
            offset: 30,
            lastOffset: 0,
            search:'',
            clearList : false,
            loading:0,
            filterparam:'',
            filterOn:false,
            medialist:[],
            showOption:false,
            id:0,
            searchparams:'facebook'
        }
        this.expandHeader = this.expandHeader.bind(this);
    }

    componentDidMount() {
        var projectId = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/chat/'+projectId);
        //window.instgrm.Embeds.process();
        //twttr.widgets.load()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
    }
    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }

    render() {

        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>

                <ProjectHeader expandHeader={this.expandHeader}/>
                <div  className="project-disscussion" >
                <ChatContainer
                    taskid={atob(getUrlSegment(4))}
                  //  assigneesData={assigneesData}
                />
                </div>


            </div>
        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
    Savenewsandmediadata:state.ProjectReducer.SaveNewsAndMedia,
    newsMediaData:state.ProjectReducer.GetNewsMediadata,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getNewsMedia:projectActions.getNewsMedia,
    getNewsMediaInfo:projectActions.getNewsMediaInfo
})(ProjectChat));
