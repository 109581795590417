import React, {Component} from 'react';
import { Calendar, momentLocalizer,Views  } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import LoaderSvg from "../views/LoaderSVG";
const localizer = momentLocalizer(moment);
const _year = new Date().getFullYear();
const _month = new Date().getMonth();
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


class Deadlines extends Component {

    constructor(props) {
        super(props);
        this.state = {
            month: _month,
            year: _year,
        }
        this.updateCalendarData = this.updateCalendarData.bind(this)
    }

    range(start, end){
        let nums = [];
        for (let cnt = start; cnt <= end; cnt++) nums.push(cnt);
        return nums;
    }
    updateCalendarData(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    render() {
        let years = this.range(_year - 25, _year + 5);
        let taskList = [];
        let pending = 0;
        let upComing = 0;
        let pendingTasks =  this.props.dataList.overduedate || [];
        let upComingTasks =  this.props.dataList.dataupcoming || [];
        pendingTasks.map(task => {
            taskList.push({
                title: <span className='task-nums overdue-tasks'>{task.count} Task{task.count>1 ? 's' : ''}</span>,
                start: moment.utc(task.date).toDate(),
                end: moment.utc(task.date).toDate(),
                allDay : false,
                resource : '',
                tooltip: task.count + " Task" + task.count>1 ? 's' : ''
            });
            pending += task.count;
        });
        upComingTasks.map(task => {
            taskList.push({
                title: <span className='task-nums ontime-tasks'>{task.count} Task{task.count>1 ? 's' : ''}</span>,
                start: moment.utc(task.date).toDate(),
                end: moment.utc(task.date).toDate(),
                allDay : false,
                resource : '',
                tooltip: task.count + " Task" + task.count>1 ? 's' : ''
            });
            upComing += task.count;
        });

        return(
            <div className="content-left small-tab">
                {this.props.multiLoader.loader['dashboard-userstat'] && this.props.multiLoader.loader['dashboard-userstat'] === true &&
                <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                }
                <div className="content-bl-header">
                    <h5>Upcoming Deadlines</h5>
                </div>
                <div className="static-mode-block">

                    <div className="calendar-block">
                        <div className="calendar-selection-block">
                            <div className="select-box">
                                <select name="month" onChange={this.updateCalendarData}>
                                    <option value="">Select Month</option>
                                    {months.map((m,index) =>
                                    <option key={index} selected={`${_month === index ? "selected" : ""}`} value={index}>{m}</option>
                                    )}
                                </select>
                            </div>
                            <div className="select-box">
                                <select name="year" onChange={this.updateCalendarData}>
                                    <option value="">Select Year</option>
                                    { years.map((y,index) =>
                                    <option key={index} value={y} selected={`${y === _year ? "selected" : ""}`}>{y}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="calendar-wrap">
                            <Calendar
                                localizer={localizer}
                                events={taskList}
                                views={['month']}
                                startAccessor="start"
                                endAccessor="end"
                                tooltipAccessor="tooltip"
                                date={new Date(this.state.year, this.state.month, 1)}
                            />
                        </div>
                        <div className="task-counter">
                            <h5 className="task-counter-text upcoming">
                                <span className="task-text-small">Upcoming<br/>Tasks</span>
                                <span className="task-text-large">{upComing}</span>
                            </h5>
                            <h5 className="task-counter-text overdue">
                                <span className="task-text-small">Overdue<br/>Tasks</span>
                                <span className="task-text-large">{pending}</span>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Deadlines;