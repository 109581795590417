import React, {Component} from 'react';
import {Form, FormControl, InputGroup,ButtonToolbar,Button,Dropdown} from "react-bootstrap";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as WorkFlowActions from "../../actions/WorkFlow";
import {getProfile} from "../../utilities/AuthService";
import Modal from "../../components/views/Modal";
import AlertModal from "../views/AlertModal";

import moment from 'moment';
import rootActions from "../../actions";

//import {AddTask} from "../../components/pages/FormsData";
class WorkFlow extends Component {

    constructor(props) {
        super(props);
        this.state={
            DeleteModal : false,
            EditModal : false
        }
        this.WorkFlowHandleChange = this.WorkFlowHandleChange.bind(this);
        this.WorkflowCreate = this.WorkflowCreate.bind(this);
        this.workFlowDelete = this.workFlowDelete.bind(this);
        this.deleteworkflow = this.deleteworkflow.bind(this);
        this.workflowAdd= this.workflowAdd.bind(this);
        this.WorkFlowEditChange = this.WorkFlowEditChange.bind(this);
        this.WorkflowUpdate = this.WorkflowUpdate.bind(this);
        this.workFlowEdit = this.workFlowEdit.bind(this);
        this.updateclose = this.updateclose.bind(this);
        this.getWorkFlowList = this.getWorkFlowList.bind(this);
      }



    componentDidMount() {
        this.props.mainClick('workflow','')
        this.getWorkFlowList();
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;

        if(prevProps.workFlowModifiedDet.workflow_modified !== this.props.workFlowModifiedDet.workflow_modified ) {
            setTimeout(function () {
                beta.getWorkFlowList();
            }, 1000)
        }
    }

    getWorkFlowList() {
        this.props.listworkflow({uid: getProfile().id, usertype:'root',offset : 50});
    }

    WorkFlowHandleChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }

    WorkflowCreate(prevProps,prevState){
       let wname =  this.state.name;
       let wdesc = this.state.description;
       let wselectedtagsid = "";
       let wselectedtagsname = "";
       let workflowid = moment().valueOf();
       let saveddata = {_id : workflowid, name : wname, description : wdesc, uid : getProfile().id,  tagsid : wselectedtagsid, tagsname :wselectedtagsname, mode : 'save'}
       this.props.saveworkflow(saveddata);

        this.setState({AddworkflowModal : false});
    }

    workFlowDelete(wid){
        this.setState({DeleteModal:!this.stateDeleteModal});
        this.setState({workflowid : wid});

    }
    deleteworkflow(){
        var wrkflwid = this.state.workflowid;
        let deletedddata = {_id : wrkflwid, uid : getProfile().id}
        this.props.deleteworkflow(deletedddata);
        this.setState({DeleteModal : false});
    }
    cancelDeleteModal(){
        this.setState({DeleteModal : false});

    }
    workflowAdd(){
        this.setState({AddworkflowModal : true});
    }
    workFlowEdit(id,name,desc){

        this.setState({EditId :  id});
        this.setState({EditName : atob(name)});
        this.setState({EditDesc : atob(desc)});
        this.setState({EditModal : true});
    }

    WorkFlowEditChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    WorkflowUpdate(prevProps,prevState){
        let wEditname =  this.state.EditName;
        let wEditdesc = this.state.EditDesc;
        let wEditselectedtagsid = "";
        let wEditselectedtagsname = "";
        let workflowEditid = this.state.EditId;
        let updateddata = {_id : workflowEditid, name : wEditname, description : wEditdesc, uid : getProfile().id,  tagsid : wEditselectedtagsid, tagsname :wEditselectedtagsname, mode : 'edit'}
        this.props.saveworkflow(updateddata);
        this.setState({EditModal : false});
    }
    updateclose(){
        this.setState({EditModal : false});
    }

    Addclose(){
        this.setState({AddworkflowModal : false});
    }

    render() {
////console.log(this.props)
         return (

             <div className="table-all-wrapper">
                      <div className="table-scrollable">
                          <table className="bsy-table">
                              <thead>
                                  <tr>
                                      <th className="high-widthed">Name</th>
                                      <th>Stages</th>

                                  </tr>
                              </thead>
                              <tbody>
                              {this.props.listAllWorkFlowData.ListAllWorkFlow.data.map ((list,index) =>
                                  <tr>
                                      <td className="high-widthed"  onClick={(e)=>this.props.history.push('/workflow/details/'+btoa(list.id))}>
                                          <div className="project-detailbox">
                                              <span className="icon-in icon-workflow-in workflow-icon"></span>
                                              <div className="project-details-in">
                                                  <h5 className="editable-head editable-head--middle">
                                                      {atob(list.name)}
                                                  </h5>
                                              </div>
                                          </div>
                                      </td>
                                      <td>
                                            <span className="count-in">{list.stages}</span>
                                      </td>
                                      <td align="right">
                                          <div className="wf-btn-block">
                                              <button className="ico-lib wf-ed" data-parent={list.id}
                                                      data-action="editworkflow" onClick={(e)=>this.workFlowEdit(list.id,list.name,list.description)}></button>
                                              <button className="ico-lib wf-dt" data-parent={list.id}
                                                      data-pop="bs-popup" data-target="#delete-workflow"
                                                      data-action="deleteworkflow" onClick={(e)=>this.workFlowDelete(list.id)} ></button>
                                          </div>
                                      </td>
                                  </tr>
                              )}


                              </tbody>
                          </table>
                      </div>

                 {this.state.AddworkflowModal == true &&
                 <Modal title={'Create New Workflow'} Close ={()=> this.Addclose()}>
                     <div className="user-content-panel">
                         <Form.Group controlId="full-panel" className={`full-panel`}>
                             <InputGroup className="mb-3">
                                 <Form.Group controlId="" className={`full-panel`}>

                                     <Form.Control type="text" className="border-bottom-only" name={'name'}
                                                   placeholder="name" onChange={this.WorkFlowHandleChange}/>
                                 </Form.Group>
                             </InputGroup>
                         </Form.Group>
                         <Form.Group controlId="full-panel" className={`full-panel`}>
                             <InputGroup className="mb-3">
                                 <Form.Group controlId="" className={`full-panel`}>
                                     <Form.Control as="textarea" className="border-bottom-only" rows="4"
                                                   name={'description'} placeholder="Description"
                                                   onChange={this.WorkFlowHandleChange}/>
                                 </Form.Group>
                             </InputGroup>
                         </Form.Group>
                         <Form.Group controlId="exampleForm.ControlSelect2" name={'selecttags'}
                                     onChange={this.WorkFlowHandleChange}>
                             <Form.Control as="select" multiple>
                                 <option>1</option>
                                 <option>2</option>
                                 <option>3</option>
                                 <option>4</option>
                                 <option>5</option>
                             </Form.Control>
                         </Form.Group>


                         <ButtonToolbar className="button-all-holder">
                             <Button type="submit" onClick={this.WorkflowCreate} className="general-btn">Create</Button>
                         </ButtonToolbar>

                     </div>

                 </Modal>

                 }
                             <div className={'float-button-box'} onClick={this.workflowAdd}>
                                 <span className={'icon-in icon-plus'}></span>
                             </div>

                 {this.state.DeleteModal == true &&
                 <AlertModal id="take-pool"
                             title="Are you sure want to delete?"
                             OK_button_text="Yes, Delete It"
                             OK_action={() => this.deleteworkflow()}
                             showModal = {this.state.DeleteModal}
                             CANCEL_action={() => this.cancelDeleteModal()}
                 />
                 }
                 {this.state.EditModal == true &&
                 <Modal title={'Update Workflow'}   Close ={()=> this.updateclose()} >
                     <div className="user-content-panel">
                         <Form.Group controlId="full-panel" className={`full-panel`}>
                             <InputGroup className="mb-3">
                                 <Form.Group controlId="" className={`full-panel`}>

                                     <Form.Control type="text" className="border-bottom-only" name={'EditName'}
                                                   placeholder="name" defaultValue = {this.state.EditName} onChange={this.WorkFlowEditChange}/>
                                 </Form.Group>
                             </InputGroup>
                         </Form.Group>
                         <Form.Group controlId="full-panel" className={`full-panel`}>
                             <InputGroup className="mb-3">
                                 <Form.Group controlId="" className={`full-panel`}>
                                     <Form.Control as="textarea" className="border-bottom-only" rows="4"
                                                   name={'EditDesc'} placeholder="Description"  defaultValue = {this.state.EditDesc}
                                                   onChange={this.WorkFlowEditChange}/>
                                 </Form.Group>
                             </InputGroup>
                         </Form.Group>
                         <Form.Group controlId="exampleForm.ControlSelect2" name={'selecttags'}
                                     onChange={this.WorkFlowEditChange}>
                             <Form.Control as="select" multiple>
                                 <option>1</option>
                                 <option>2</option>
                                 <option>3</option>
                                 <option>4</option>
                                 <option>5</option>
                             </Form.Control>
                         </Form.Group>


                         <ButtonToolbar className="button-all-holder">
                             <Button type="submit" onClick={this.WorkflowUpdate} className="general-btn">Update</Button>
                         </ButtonToolbar>

                     </div>

                 </Modal>

                 }


                  </div>


        );
    }
}



const mapStateToProps = state => ({

    listAllWorkFlowData:state.workflowReducer.listallWorkFlow,
    workFlowModifiedDet : state.workflowReducer.getModifiedWorkFlow

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    listworkflow:WorkFlowActions.listWorkFlow,
    saveworkflow:WorkFlowActions.SaveWorkflow,
    deleteworkflow:WorkFlowActions.DeleteWorkflow


})(WorkFlow));


