import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../../actions/ToDo"
import {Card, Form} from "react-bootstrap"
import {withRouter} from 'react-router';
import ListCard from "../../views/ListCard"
import * as commonActions from "../../../actions/Common";
import {getProfile} from "../../../utilities/AuthService";
import NoData from "../../views/NoData";
import LoaderSvg from "../../views/LoaderSVG";
import Topbar from "../Topbar";
import FilterBlock from "../FilterBlock";
import FilerRightBlock from "../FilterRightBlock";
import Modal from "../../views/Modal";
import AddToDoTask from "../AddToDoTask";
import * as projectActions from "../../../actions/Project";
import rootActions from "../../../actions";
import {getUrlSegment} from "../../../utilities/CustomFunctions";


class DueThisMonth extends Component {

    constructor(props) {
        super(props);
        this.gettaskList=this.gettaskList.bind(this);
        this.modalOpen=this.modalOpen.bind(this);
        this.state={
            open:false,
            projectList:[],
            limit : 0,
            loadingState: false,
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            duethismonthdata: [],
            offset: 250
        }
      }



    componentDidMount() {
        this.props.mainClick('task','/task/due-this-month');

        if(this.props.searchData.task.active) {
            this.searchFilterActive()
        }
        else {
            this.gettaskList('All');
        }


        const container = document.querySelector('.main-panel-holder');
        container.addEventListener('scroll', this.listenToScroll)
    }
    searchFilterActive(){
        var status = "All"
        if(getUrlSegment(2)==='due-this-month'){
            var category='by-date'
            var bydue="Duemonth"
        }


        var data = {
            category : category,
            status :this.props.searchData.task.status,
            uid : getProfile().id,
            bydue : bydue,
            priority :this.props.searchData.task.priority,
            project :this.props.searchData.task.project,
            asigntype : '',
            username :'',
            managetask : 'mytask',
            srchval:this.props.searchData.task.srchval,
            limit : 0,
            offset  : 20,
            systype : getProfile().usertype,
        }

        this.props.searchType('SEARCH_TASK_DATA',this.props.searchData.task.srchval,"srchval",data)

    }



    componentWillUnmount() {
        const container = document.querySelector('.main-panel-holder');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.main-panel-holder')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;
        if (contentHeight <= scrollTop) {
            if(this.state.lastOffset + 1 < this.state.totalitems) {
                this.setState({loading : 1})
                var data = {category : 'by-date', status : "All" , uid : getProfile().id, bydue : 'Duemonth',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', limit: this.state.lastOffset, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TO_DUE_THISMONTH_DATA' }
                this.props.duethismonth(data);
            }
        }
    }

    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if(prevProps.taskSelect.tasksArchived  !== this.props.taskSelect.tasksArchived ){
            setTimeout(function () {
                beta.gettaskList('All');
            }, 1000)
        }

        if(prevProps.taskFilters  !== this.props.taskFilters){
            this.gettaskList('All');
        }

        if(prevProps.todoListing  !== this.props.todoListing){
            this.setState({duethismonthdata: this.props.todoListing.ToDueThisMonth.data});
        }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ) {
            if (this.props.HistoryPushData.savemode === 'by-me') {
                // var data = {category : 'by-me', status : 'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', username : this.props.taskFilters.taskAssignedFilter,limit: 0, offset  : this.state.offset, systype :  getProfile().usertype,  actionType : 'LIST_FOLLOW_UP_DATA' }
                // this.props.toFollowUp(data);
                // this.props.history.push("/task/to-follow-up")
                this.setState({open: false})

            }
            if (this.props.HistoryPushData.savemode === 'to-me') {
                // var data = {category : 'to-me', status :'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
                // this.props.todo(data);
                // this.props.history.push("/task/todo")
                this.setState({open: false})
            }
        }

        if(this.props.taskSelect.dateUpdated !== prevProps.taskSelect.dateUpdated) {
            var data = {category : 'by-date', status : "All" , uid : getProfile().id, bydue : 'Duemonth',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', limit: 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TO_DUE_IN_SEVEN_DAYS_DATA' }
            this.props.duethismonth(data);
            this.props.history.push("/task/due-this-month")
        }
        /*if(prevProps.todoListing.ToDueThisMonth.data !== this.props.todoListing.ToDueThisMonth.data) {
            let cnt = parseInt(this.state.lastOffset) + (this.props.todoListing.ToDueThisMonth.data.length);
            this.setState({
                lastOffset: cnt,
                totalitems: this.props.todoListing.ToDueThisMonth.total,
            });
            let duethismonthdata = [...this.state.duethismonthdata];
            if(this.props.taskFilters.taskPriorityFilter === 'All' && this.props.taskFilters.taskProjectFilter === 'All' && this.props.taskFilters.taskAssignedFilter === '') {
                duethismonthdata.push(...this.props.todoListing.ToDueThisMonth.data)
            }else{
                this.setState({lastOffset: 0});
                duethismonthdata = [...this.props.todoListing.ToDueThisMonth.data];
            }
            this.setState({duethismonthdata: duethismonthdata});
            this.setState({loading:0})
        }*/

    }
    modalOpen(){
        //////////////console.log(this.props.projects.projectData);//////////////console.log(this.props.projects.tagData);
        this.setState({open:!this.state.open,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData});
        //////////////console.log(this.props.projects.tagData);
    }
    gettaskList(status){
        var data = {category : 'by-date', status : status , uid : getProfile().id, bydue : 'Duemonth',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', limit: 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TO_DUE_THISMONTH_DATA' }
        this.props.duethismonth(data);
    }

    selectTasks(id) {
        this.props.selectTasksToMove(id);
    }

    render() {

      // let ToDueThisMonth=this.state.duethismonthdata.filter(item => (item.subject===''?atob(item.Title):atob(item.subject)).toLowerCase().search(this.props.searchData.task.srchval.toLowerCase()) >= 0)

        let ToDueThisMonth=this.props.searchData.task.active? this.props.searchData.data: this.state.duethismonthdata


        return (



                     <>
                         <div className='filter-all-wrapper task-home'>
                             <div className='filter-holder'>
                                 {this.props.taskSelect.taskSelection === 'no' &&
                                 <FilterBlock/>
                                 }
                                 {/*{this.props.taskSelect.taskSelection === 'no' &&*/}
                                 {/*<Topbar  gettaskList={this.gettaskList}  topbardata={this.props.topbardata}/>*/}
                                 {/*}*/}
                                 <FilerRightBlock taskCount ={this.state.duethismonthdata.length} />
                             </div>
                         </div>

                        <div className={this.props.todoListing.loading ? 'main-panel-holder with-loader has-filter' : 'main-panel-holder has-filter'}>
                            {this.props.todoListing.loading &&
                            <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                            }
                            {ToDueThisMonth.length === 0 &&
                                <div className='absolute-holder'>
                                <NoData

                                    msg={`No task assigned to you`}

                                />
                                </div>
                            }

                            {/*<h4 className={'main-panel-head'}>To Do & Recently Updated</h4>*/}
                            {ToDueThisMonth.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'unread').map((list,index) =>
                                <React.Fragment>
                                    {index === 0 &&
                                    <h4 className={'main-panel-head'}>Unread</h4>
                                    }
                                <ListCard
                                    key={'duemonth'+index}
                                    index={index}
                                    sectionTitle={`Unread`}
                                    list={list}
                                    history={this.props.history}
                                    mode='by-date'
                                    taskSelection = {this.props.taskSelect.taskSelection}
                                    selectTasks = {this.selectTasks.bind(this)}
                                    allChecked = {this.props.taskSelect.allChecked}
                                    id={list.masterid}
                                    dateupdatetask={this.props.dateupdatetask}
                                    tasksource={list.createdfrom}
                                    poolenabled={list.poolenabled}
                                />
                                </React.Fragment>
                            )}

                            {/*<h4 className={'main-panel-head'}>Read and In Progress</h4>*/}
                            {ToDueThisMonth.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'read').map((list,index) =>
                                <React.Fragment>
                                    {index === 0 &&
                                    <h4 className={'main-panel-head'}>Read</h4>
                                    }
                                <ListCard
                                    key={'duemonth'+index}
                                    index={index}
                                    sectionTitle={`Read`}
                                    list={list}
                                    history={this.props.history}
                                    mode='by-date'
                                    taskSelection = {this.props.taskSelect.taskSelection}
                                    selectTasks = {this.selectTasks.bind(this)}
                                    allChecked = {this.props.taskSelect.allChecked}
                                    id={list.masterid}
                                    dateupdatetask={this.props.dateupdatetask}
                                    tasksource={list.createdfrom}
                                    poolenabled={list.poolenabled}
                                />
                                </React.Fragment>
                            )}
                        </div>
                         {this.state.open &&
                         <Modal title={'Create New Task'} Close ={this.modalOpen.bind(this)} >
                             <AddToDoTask />

                         </Modal>
                         }
                        <div className={'float-button-box'} onClick={this.modalOpen}>
                            <span className={'icon-in icon-plus'}></span>
                        </div>
                     </>


        );
    }
}



const mapStateToProps = state => ({

    todoListing:state.TaskReducer.listtoDo,
    taskFilters:state.TaskReducer.filterTasks,
    taskSelect:state.TaskReducer.getTaskSelection,
    menuClickData:state.CommonReducer.menuClick,
    searchData:state.CommonReducer.searchparamdata,
    topbardata:state.TaskReducer.DueThismonthTopbarMenudata,
    listtags:state.TaskReducer.ListAllTags,
    saveTodo : state.TaskReducer.saveTodo,
    projects:state.TaskReducer.GetProjectData,
    HistoryPushData:state.TaskReducer.HistoryPushData,
});
export default  withRouter(connect(mapStateToProps, {
    todo:taskActions.listtask,
    toFollowUp:taskActions.listtask,
    duethismonth:taskActions.listtask,
    mainClick:rootActions.commonActions.MenuClick,
    selectTasksToMove : taskActions.selectTasksToMove,
    listTags:projectActions.listalltags,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    searchType: rootActions.commonActions.searchType,
    dateupdatetask: taskActions.dateupdatetask


})(DueThisMonth));


