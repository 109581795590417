import React from 'react';

class CollectionDashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false
        };
    }
    buttonStatus=()=>{
        this.setState({status:!this.state.status})
        this.props.buttonChange(this.state.status)
    }



    render() {
        if(this.props.dashboard.length !==0) {
            return (
                <div className={this.props.hasOwnProperty('source') && this.props.source === 'summarypanel' ? "collection-summary-chat":'side-bar-panel collection-clik-panel grey-bg-panel list-view-panel'}>
                    <div>
                        {!this.props.hasOwnProperty('source') &&
                        <div className="float-panel-header">
                            <h5 className="subheader">Dashboard</h5>
                            <button className="ico-lib fly-close"
                                    onClick={() => this.props.handleDashboard("dashStat")}></button>
                        </div>
                        }
                        <div className="float-panel-content-holder auto-stretch">
                            <div className="collection-dashboard-item-container">

                            {this.props.dashboard.map((list,index) =>
                            <div className="collection-dashboard-item" key={`dash-board-`+index}>
                                    <div className="title">
                                        <h5>{list.label}</h5>
                                    </div>
                                    <div className="item-detail">
                                        <h5>{list.value}</h5>
                                    </div>
                            </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else{
            return null
        }
    }
}
export default CollectionDashBoard;












