import React from 'react';
import { Draggable, state } from 'react-page-maker';
import {withRouter} from 'react-router';
import { FaTrash } from 'react-icons/fa';
import {connect} from "react-redux";
import {FormGroup} from "react-bootstrap";
import * as collectionActions from "../../../../../../actions/Collections";
import * as DynamicDashboard from "../../../../../../actions/DynamicDashboard";

class LabelContainer extends React.Component {
  state = {
    showColorPicker: false,
    label: '',
  };

  handleChangeComplete = (e) => {
    let labelValue=e.target.value
    const { id, dropzoneID, parentID }= this.props;
    this.setState({ label: labelValue }, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: { label: labelValue}
      });
    });
   // this.chartSaving()
  };
  onBlur(){
    this.props.dragStatus(false)
  }
  onClick(){
    this.props.dragStatus(true)
  }



  render() {
    const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, name, payload
    } = this.props;

    const label =  this.state.label ||
      payload && payload.label || 'Please enter the text';

    if (showBasicContent) {
      return (
        <Draggable { ...this.props } >
          {

            <span className="drag-icon">Header</span>
          }
        </Draggable>
      )
    }

    if (showPreview) {
      return (
          <div className="floating-summary-header">
            <h5>{ label }</h5>
          </div>

      );
    }

    return (
      <Draggable { ...this.props } draggable={!this.props.DragDisabled.dragStatus}>
        <React.Fragment>
          <FormGroup className="m-0">
            <label className="col-sm-12">
              <div className={`left-container`} >
              <span>{name}</span>
            <input onChange={(e)=>this.handleChangeComplete(e)}  defaultValue={label} className="layout-header-block"/>
              </div>
              <div className="chart-icons">
                {/*<div className="save-in-icon" onClick={() => this.chartSaving()}> </div>*/}
                <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}> </div>
              </div>
            </label>
          </FormGroup>
        </React.Fragment>
      </Draggable>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled
});
export default  withRouter(connect(mapStateToProps, {
  collections: collectionActions.listallcollections,
  showform : collectionActions.showform,
  chartSaving:DynamicDashboard.saveDashboarddata,
  dragStatus :DynamicDashboard.dragStatus,
})(LabelContainer));
