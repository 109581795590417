import React, {Component} from 'react';
import {connect} from "react-redux";
import * as formActions from "../../actions/Forms"
import {withRouter} from 'react-router';
import * as commonActions from "../../actions/Common";
import {getProfile} from "../../utilities/AuthService";
import {FRMSIMG_PATH} from '../../constants/index'
import Coverimage from '../../images/placeholders/Coverimage.jpg';
import rootActions from "../../actions";
import FormTile from "../views/FormTile";


class Forms extends Component {

    constructor(props) {
        super(props);
      }



    componentDidMount() {

        this.props.mainClick('forms','')
        this.props.forms('All',"",getProfile().id,'root',0,"0",20)
    }


    componentWillUpdate(prevProps,nextProps) {
        if(prevProps.searchData !== this.props.searchData){
           // alert(1)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


    }



    render() {
       ////////////console.log(this.props)
       let Forms=this.props.listAllFormsData.Forms.data//.filter(item => item.concat.toLowerCase().search('bsy') >= 0)
         return (



                  <div className="tile-main-panel">
                      <div className="tile-all-holder">

                          {Forms.map((list,index) =>
                              <FormTile list={list} />

                          )}
                      </div>
                  </div>




        );
    }
}



const mapStateToProps = state => ({

    searchData:state.CommonReducer.searchparamdata,
    listAllFormsData:state.FormsReducer.listFormsdata
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    forms:formActions.listallforms

})(Forms));


