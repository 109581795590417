import axios from 'axios'
import {commonService} from "./common.services";
import {API_PATH, AUTHENTICATION, ES_INDEX, NEW_VERSION, SHARED_LOGIN} from '../constants/index'
import {getProfile} from "../utilities/AuthService";

export const collections = {
    listCollections,
    listCollectionDetail,
    listProjectCollectionDetail,
    selectedCollections,
    saveMetaform,
    showForm,
    externalShowForm,
    deleteCollectionRecords,
    shareCollection,
    getExternalValues,
    getDatabindingValues,
    getAggregate,
    saveForm,
    getFormData,
    listTodoCollections,
    externalListTodoCollections,
    getCollectionSettings,
    formFilter,
    getCategory,
    createCollections,
    createCategory,
    updatetodoCollection,
    exportField,
    saveExportFieldData,
    getcollectionconfig,
    savaDataView,
    savaDataSettings,
    singlerecorddAssign,
    saveFilter,
    saveRightSidePanel,
    importCollectionData,
    importExcelData,
    saveAssignData,
    saveShareData,
    savelinkingpermission,
    selectExport,
    exportall,
    startcronjob,
    startworkflow,
    collectionSaveTemplate,
    getRule,
    listingWorkflow,
    linkedWorkflow,
    deletelinkedWorkflow,
    createGenericRule,
    genericRuleDelted,
    saveWorkflowrule,
    searchEntity,
    checkIfUnique,
    trackCollection,
    savePropagation,
    deletePropagation,
    listPropgationCollections,
    deleteAllrecords,
    maplistCollectionDetail,
    loadmoremapData,
    getFilterSettings,
    saveFilterSettings,
    saveData,
    listProjectCollections,
    starClick,
    sendBsynOtp,
    verifyBsynotp,
    paymentSend,
    listAnnotation,
    saveAnnotation,
    getCollectedInfo,
    getFormAuthentication,
    listsystementities,
    saveentitymapping,
    deleteentitymapping,
    listentityCollections,
    saveCalendarSettings,
    deleteCalendarSettings,
    listCalendarSettings,
    fieldVersion,
    saveInvokeWorkflow,
    triggerCustomApi
};
function searchEntity(params){
    return commonService.sendHttpRequest('collection/searchentity', params, "GET");
}
function startcronjob(params){
    return commonService.sendHttpRequest('collection/startcronjob', params, "GET");
}
function startworkflow(params){
    return commonService.sendHttpRequest('workflow/startworkflow', params, "GET");
}

function selectExport(params){
    return commonService.sendHttpRequest('/export/exportexcel', params, "POST");
}


function savaDataSettings(params){
    return commonService.sendHttpRequest('collection/settingscollection', params, "POST");
}
function singlerecorddAssign(params){
    return commonService.sendHttpRequest('collection/savedataaccessusers', params, "POST");
}

function saveAssignData(params){
    return commonService.sendHttpRequest('collection/savedataaccessusers', params, "POST");
}
function saveShareData(params){
    return commonService.sendHttpRequest('collection/sharecollection', params, "POST");
}
function saveFilter(params){
    return commonService.sendHttpRequest('collection/savefiltersettings', params, "POST");
}
function saveRightSidePanel(params){
    return commonService.sendHttpRequest('custom/savepanelsettings', params, "POST");
}
function savelinkingpermission(params){
    return commonService.sendHttpRequest('collection/save_linkedpermission', params, "POST");
}
function savaDataView(params){
    return commonService.sendHttpRequest('collection/savedataview_permission', params, "POST");
}
function importCollectionData(params){
    return commonService.sendHttpRequest('collection/importcsv', params, "POST");
}
function importExcelData(params){
    return commonService.sendHttpRequest('export/import', params, "POST");
}
function collectionSaveTemplate(collectionId,params){
    return commonService.sendHttpRequest('collection/savetemplate_view/'+collectionId, params, "POST");
}


function listCollections(params){
    return commonService.sendHttpRequest('collection/collectionsubgroup', params, "GET");

    /*return axios.post(API_PATH + 'collection/collectionsubgroup',{
        "group": group,
        "search": search,
        "uid": uid,
        "usertype": usertype,
        "managecollection": managecollection,
        "limit": limit,
        "offset": offset,
        "index": ES_INDEX,
        "has_form": "yes"


    });*/
}

function listProjectCollections(params){
    return commonService.sendHttpRequest('collection/projectcollection', params, "GET");
}

function saveMetaform(params){
    return commonService.sendHttpRequest('collection/savemetaform', params, "POST");
    /*return axios.post(API_PATH + 'collection/savemetaform',{
        "metaresult": [
            metaresult
        ],
        "uid": uid,
        "todoid": todoid,
        "index": "lsinternalcopy"


    });*/

}

function selectedCollections(params){
    // return axios.post(API_PATH + 'collection/selectedcollection',{
    //     "_id": id,
    //     "index": ES_INDEX
    // });
    return commonService.sendHttpRequest('collection/selectedcollection', params, "POST");
}

function deleteCollectionRecords(collectionid, uid, records){

    let params={
        "collectionid": collectionid,
        "uid": uid,
        "records": records,
    }
    return commonService.sendHttpRequest('collection/deleterecords', params, "POST");


    // return axios.post(API_PATH + 'collection/deleterecords',{
    //     "collectionid": collectionid,
    //     "uid": uid,
    //     "records": records,
    //     "index": ES_INDEX
    // });
}

function exportField(collectionid){

    return commonService.sendHttpRequest('collection/displayfields', collectionid, "GET");
}



function showForm(params) {
    return commonService.sendHttpRequest('collection/form', params, "GET");
   // return axios.get(API_PATH + 'collection/showform?_id=' + id + '&index=' + ES_INDEX);
}
function externalShowForm(params) {
    return commonService.sendHttpRequest('collection/externalform', params, "GET");
   // return axios.get(API_PATH + 'collection/showform?_id=' + id + '&index=' + ES_INDEX);
}

function getFormData(params) {
    ////console.log(params)
    return commonService.sendHttpRequest('collection/selectedcollection', params, "post");
}

function getcollectionconfig(params) {
    ////console.log(params)
    return commonService.sendHttpRequest('collection/collectionconfig', params, "GET");
}

function listCollectionDetail(id, params){
    return commonService.sendHttpRequest('collection/' + id + '/collectedinfo', params, "POST");
}
function listProjectCollectionDetail(id, params){
    return commonService.sendHttpRequest('collection/' + id + '/collectedinfo_pjt', params, "POST");
}

function shareCollection(params){
    return commonService.sendHttpRequest('collection/sharecollection', params, "POST");
}

function getExternalValues(params) {

    return commonService.sendHttpRequest('collection/searchautofill', params, "POST");

}
function getDatabindingValues(params) {

    return commonService.sendHttpRequest('custom/selectedfields', params, "POST");

}
function getAggregate(params) {

    return commonService.sendHttpRequest('collection/calculateaggs', params, "GET");

}

function saveForm(params){
    return commonService.sendHttpRequest('collection/createdata', params, "POST");
}
function listTodoCollections(params){
    return commonService.sendHttpRequest('collection/collectionsubgroup', params, "GET");
}
function externalListTodoCollections(params){
    return commonService.sendHttpRequest('collection/externalcollectionsubgroup', params, "GET");
}
function getCollectionSettings(params) {
    return commonService.sendHttpRequest('collection/collectionsettings', params, "GET");
}
function formFilter(params){
    return commonService.sendHttpRequest('collection/formfilter', params, "GET");
}

function saveExportFieldData(params){
    return commonService.sendHttpRequest('collection/save_exoprtsettings', params, "POST");
}
function exportall(params){
    return commonService.sendHttpRequest('export/exportall', params, "POST");
}

function getCategory(params){
    return commonService.sendHttpRequest('collection/getcategory', params, "POST");
}

function createCollections(params){
    return commonService.sendHttpRequest('collection/createcollections', params, "POST");
}

function createCategory(params){
    return commonService.sendHttpRequest('collection/savecategory', params, "POST");
}
function updatetodoCollection(params){
    return commonService.sendHttpRequest('task/updatetodocollection', params, "POST");
}

/* rule */
function getRule(params){
    return commonService.sendHttpRequest('collection/rulerecurrence', params, "POST");
}
function listingWorkflow(params){
    return commonService.sendHttpRequest('workflow/noformworkflow', params, "GET");
}
function linkedWorkflow(cid,params){
    params.collectionid=cid
    return commonService.sendHttpRequest('workflow/linkedworkflow/', params, "GET");
}
function deletelinkedWorkflow(params){
    return commonService.sendHttpRequest('workflow/deleteworkflowlink', params, "POST");
}
function createGenericRule(params){
    return commonService.sendHttpRequest('pool/saverecurrence', params, "POST");
}
function genericRuleDelted(id){
    return commonService.sendHttpRequest('collection/deletrecurrence/'+id, {}, "GET");
}
function saveWorkflowrule(params){
    return commonService.sendHttpRequest('workflow/linkworkflowcollection', params, "POST");
}
function checkIfUnique(params){
    return commonService.sendHttpRequest('collection/validatefield', params, "GET");
}
function trackCollection(params){
    return commonService.sendHttpRequest('library/collectionurltracking', params, "POST");
}
function savePropagation(params){
    return commonService.sendHttpRequest('collection/savepropagation', params, "POST");
}

function deletePropagation(params){
    return commonService.sendHttpRequest('collection/removepropogation', params, "POST");
}

function listPropgationCollections(params){
    return commonService.sendHttpRequest('collection/propagationforms', params, "GET");
}

function deleteAllrecords(params){
    return commonService.sendHttpRequest('collection/truncate', params, "POST");
}
function loadmoremapData(params){
    return commonService.sendHttpRequest('export/loadmoreitem', params, "POST");
}
function maplistCollectionDetail(id, params){
    return commonService.sendHttpRequest('export/graphdata', params, "POST");
}
function getFilterSettings(params){
    return commonService.sendHttpRequest('collection/sortedfields', params, "GET");
}
function saveFilterSettings(params){
    return commonService.sendHttpRequest('collection/savesortby', params, "POST");
}

function saveData(params){
    return commonService.sendHttpRequest('collection/savecustomtemplate', params, "POST");
}
function starClick(params){
    return commonService.sendHttpRequest('collection/saverecordstar', params, "POST");
}
function sendBsynOtp(params){
    return commonService.sendHttpRequest('collection/sendbsynotp', params, "POST");
}
function verifyBsynotp(params){
    return commonService.sendHttpRequest('collection/verifybsynotp', params, "POST");
}

function paymentSend(params){
    return commonService.sendHttpRequest('collection/savepaymentinfo', params, "POST");
}

function listAnnotation(params){
    return commonService.sendHttpRequest('collection/annotation', params, "GET");
}
function saveAnnotation(params){
    return commonService.sendHttpRequest('collection/saveannotation', params, "POST");
}

function getCollectedInfo(id, params){
    var date = new Date(); var timestamp = date.getTime();

    let APIPATH =  NEW_VERSION ? "https://apir4dev.bsynapse.com/" : "https://apidemo.bsynapse.com/";


    return axios.post(APIPATH+'collection/' + id + '/collectedinfo?t='+timestamp+'&index='+ES_INDEX,params);
    //return commonService.sendHttpRequest('collection/' + id + '/collectedinfo', params, "POST");

}
function getFormAuthentication(params){
    return commonService.sendHttpRequest('collection/authenticateform', params, "POST");
}
function listsystementities(params){
    return commonService.sendHttpRequest('collection/searchentity', params, "GET");
}
function saveentitymapping(params){
    return commonService.sendHttpRequest('collection/savesystementitymapping', params, "POST");
}
function deleteentitymapping(params){
    return commonService.sendHttpRequest('collection/removesystementitymapping', params, "POST");
}
function listentityCollections(params){
    return commonService.sendHttpRequest('collection/collectionconfig', params, "GET");
}
function saveCalendarSettings(params){
    return commonService.sendHttpRequest('/collection/savecalendarsettings', params, "POST");
}
function deleteCalendarSettings(params){
    return commonService.sendHttpRequest('/collection/deletecalendarsettings', params, "POST");
}
function listCalendarSettings(params){
    return commonService.sendHttpRequest('/collection/calendarsettings', params, "GET");
}
function fieldVersion(params){
    return commonService.sendHttpRequest('collection/fieldversion', params, "GET");
}
function saveInvokeWorkflow(params){
    return commonService.sendHttpRequest('workflow/invokeworkflow', params, "POST");
}
function triggerCustomApi(url, method, args){

    var date = new Date();
    var timestamp = date.getTime();
    var params = '';
    if(method === 'get' && args) {
        params = args;
    }
    console.log(params)
    let statikKey = 'VVRKR2JGcHRaREJPUkZVeFRsUlVUSkdiRnB0WkRCT1JGVXhUbFJGZUU1cVZXaE5la2t3VGxoS2IxcEhXbTVqZWxWNlRrUldaVXBwY0dWTFExbHdTME5WYkZvelRtdGFNMDVyV25rMWJHUXlWbkZoTW1Sb1lUTktiMWxSUFQwPUZlRTVxVldoTmVra3dUbGhLYjFwSFdtNWplbFY2VGtSV1pVcHBjR1ZMUTFsd1MwTlZiRm96VG10YU0wNXJXbmsxYkdReVZuRmhNbVJvWVROS2IxbFJQVDA9PT0';
    // Send an HTTP  request
    return axios({
        method: method,
        url: url+'?t='+timestamp,
        data: args,
        params : params,
        async:false,
        headers : {'Content-Type' : 'application/json',"X_API_KEY":AUTHENTICATION?(typeof  getProfile() !== "undefined"?getProfile().token:statikKey):statikKey}
    }).then(function(res){
        return res;

    }).catch(function(error){
        return error;
    });
}
