import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as usersActions from "../../../actions/Users";


class AddNewStakeholder extends Component {

    constructor(props) {
        super(props);

        this.state={

        };


    }



    componentDidMount() {


    }



    render() {
        return (
            <div className="task-content-block">
                <div className="persitant-top nicescroll">
                    <div className="split-dropdown">
                        <div className="select-box eq-width">
                            <select>
                                <option value="volvo">Choose a User</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                        <div className="select-box eq-width">
                            <select>
                                <option value="volvo">Role - Contractor</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                    </div>
                    <div className="addmore-link-block">
                        <a href="javascript:void(0);">+ Add New User</a>
                    </div>
                </div>
                <div className="persitant-footer">
                    <div className="group-row">

                    </div>
                    <button data-masterid="0" data-mode="save" type="button" className="general-btn"
                            id="createproject">Add
                    </button>
                </div>
            </div>

        );
    }
}


const mapStateToProps = state => ({
    listUsers:state.UsersReducer.listUsersdata,

});
export default  withRouter(connect(mapStateToProps, {
    users:usersActions.listTodoUsers,

})(AddNewStakeholder));



