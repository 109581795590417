import React, {Component} from 'react';
import RichText from '../views/RichText';

class ProjectNotesLeft extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }


    componentDidMount() {

    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.state !== nextState || this.props !== nextProps) {
            return true;
        }
    }
    handleEditorChange = (e) => {
        //console.log('Content was updated:', e.target.getContent());
        this.setState({content : e.target.getContent()});

    }
    handleKeyUp = (e) => {
        //alert("handleKeyUp")
        //console.log('Content for preview keyup:', e.target.innerHTML);
        let content =  e.target.innerHTML;

        this.setState({content : content});

    }

    render() {

        return (
            <div className="tab-content" id="v-pills-tabContent">

                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                     aria-labelledby="v-pills-home-tab">
                    <div className="note-detailed-wrapper">
                        <div className="note-detailed-header">
                            <h5>Demo User Login Credentials</h5>
                            <p>Created By <span className="user-mention">dhanya</span> 16 March 2019, 11:37 am</p>
                        </div>
                        <div className="note-detailed-body">
                            <div className="note-elaborate-edit">
                                <RichText
                                    menubar = 'hide'
                                    plugins = ' print preview fullpage image link media template anchor insertdatetime advlist lists textcolor imagetools  colorpicker textpattern paste code'
                                    toolbar = 'cut copy paste pastetext | undo redo | spellchecker code | bold italic | alignleft aligncenter alignright alignjustify  | bullist numlist | link autolink unlink| media |'
                                    extended_valid_elements = "video[controls|preload|width|height|data-setup],source[src|type]"
                                    powerpaste_word_import =  'prompt'
                                    width = '100%'
                                    height = '100%'
                                    file_picker_types= 'file image media'
                                    onChange = {this.handleEditorChange}
                                    onKeyUp = {this.handleKeyUp}

                                />
                            </div>
                            <div className="note-elaborate-content">
                                <div className="note-attachment">
                                    <div className="attached-icon-img">
                                        <div className="format docx">docx</div>
                                    </div>
                                    <div className="note-attachment-tite">
                                        <h5>Document Title.docx</h5>
                                        <p>27KB Word Document</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel"
                     aria-labelledby="v-pills-profile-tab">
                    <div className="note-detailed-wrapper">
                        <div className="note-detailed-header">
                            <h5>Demo User Login Credentials</h5>
                            <p>Created By <span className="user-mention">dhanya</span> 16 March 2019, 11:37 am</p>
                        </div>
                        <div className="note-detailed-body nicescroll">
                        </div>
                    </div>
                </div>


                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel"
                     aria-labelledby="v-pills-messages-tab">
                    <div className="note-detailed-wrapper">
                        <div className="note-detailed-header">
                            <h5>Demo User Login Credentials</h5>
                            <p>Created By <span className="user-mention">dhanya</span> 16 March 2019, 11:37 am</p>
                        </div>
                        <div className="note-detailed-body nicescroll">
                        </div>
                    </div>
                </div>


                <div className="tab-pane fade" id="v-pills-settings" role="tabpanel"
                     aria-labelledby="v-pills-settings-tab">
                    <div className="note-detailed-wrapper">
                        <div className="note-detailed-header">
                            <h5>Demo User Login Credentials</h5>
                            <p>Created By <span className="user-mention">dhanya</span> 16 March 2019, 11:37 am</p>
                        </div>
                        <div className="note-detailed-body nicescroll">
                        </div>
                    </div>
                </div>

            </div>
        );


    }
}
export default ProjectNotesLeft;