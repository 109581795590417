import React from 'react';
import { Draggable, state } from 'react-page-maker';
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import {FormGroup} from "react-bootstrap";
import * as DynamicDashboard from "../../../../../actions/DynamicDashboard";
import * as formsActions from "../../../../../actions/Forms";
import EntityModal from "./EntityModal";
import moment from "moment";
import {forceDownload, getUrlSegment, hasExtension, isImage, isVideo} from "../../../../../utilities/CustomFunctions";
import {S3_URL} from "../../../../../constants";
import {getProfile} from "../../../../../utilities/AuthService";
import * as collectionActions from "../../../../../actions/Collections";
import AlertModal from "../../../../views/AlertModal";

class GeGroupTable extends React.Component {
    state = {
        showColorPicker: false,
        label: '',
        leadSubmitting :0,
        getFormdetails:[],
        setFields:[
            {
                id: "item-1",
                masterid:"",
            },
        ]
    };

    componentDidMount() {
        this.setState({payload:this.props.payload})
        this.graphData()

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.linkedId.param !== this.props.linkedId.param) {
            let setFieldId = '';
            let selectedLeadId = this.props.linkedId.param;
            if(this.props.linkedId.param && this.props.linkedId.param !=='') {
                this.setState({selectedLeadId:selectedLeadId})
            }
        }
        if (prevProps.getFormdetails.formvalues !== this.props.getFormdetails.formvalues) {
            let getFormdetails = this.props.getFormdetails.formvalues;
            if(getFormdetails) {
                this.setState({getFormdetails:getFormdetails})
            }
        }
        if(this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged) {
            this.setState({
                leadSubmitting : 0
            })
            var beta =this;
            setTimeout(function () {
                beta.setState({formSaved : true})
            }, 1000)

        }

        //Show an error message on saving failure
        if(this.props.getSavedForm.formSaveError !== prevProps.getSavedForm.formSaveError && this.props.getSavedForm.formSaveError === true) {
            this.setState({loading :0})
            this.setState({errorMessageOnSaving : true})
        }

    }
    graphData() {
        const setFields = this.props.payload && this.props.payload.setFields ||this.state.setFields
        let selectedLeadId = this.props.linkedId.param;
        let getFormdetails = this.props.getFormdetails.formvalues;
        this.setState({setFields:setFields, selectedLeadId:selectedLeadId, getFormdetails:getFormdetails})
    }
    hideErrorAlert =() => {
        this.setState({errorMessageOnSaving :false})
    }
    hideSavedFormAlert =() => {
        this.setState({formSaved:false})
    }
    handleChangeComplete = (e) => {
        let labelValue=e.target.value
        const { id, dropzoneID, parentID }= this.props;
        this.setState({ label: labelValue }, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: { label: labelValue}
            });
        });
        // this.chartSaving()
    };
    editRecord(col,masterids,type,mId){
        this.props.getselectedcollections({ _id : mId, collectionid : col, metainfo : true});
        this.props.addRecord(col,masterids,type,mId,"mainentity")
    }
    openSetting=()=>{
        this.setState({modalStatus:!this.state.modalStatus})
        //   this.props.dragStatus(!this.state.modalStatus)
    }

    addData=(Id,updateParam)=>{
        let data=this.state[updateParam]
        let newData={
            id: "item-"+new Date().getTime(),
            label: "item "
        }
        let _data=data.concat(newData)
        this.setState({[updateParam]:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[updateParam]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    removeData=(dragid,updateParam)=>{
        let data=this.state[updateParam]
        let _data=data.filter(item=>item.id !== dragid)
        this.setState({[updateParam]:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[updateParam]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    handleLabelChange=(e,dragid,whereCondition,type)=>{
        var name=''
        var fType=''
        if(e.target.name === "masterid"){
            var data = e.target.value.split("|");
            name =data[0]
            fType=data[1]
        }
        else{
            name =e.target.name
        }

        let sel=e.target
        var updatedParam = whereCondition;
        var _newState = this.state[updatedParam];
        var index=(_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name]=name;
        _newState[index]["label"]=sel.options[sel.selectedIndex].text ==="Please Select" ? "":sel.options[sel.selectedIndex].text;
        if(e.target.name ==="masterid"){
            _newState[index]["type"]=fType;
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[whereCondition]=this.state[updatedParam]
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    checkBoxchange=(e)=> {
        let val;
        if (e.target.value === "false") {
            val = true
        } else {
            val = false
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }

    handleProfileChange = (e, masterid) => {
        var fieldValue = e.target.value;
        var fieldName = e.target.name;
        let getFormdetails = [...this.state.getFormdetails];
        if(getFormdetails.length !== 0){
            getFormdetails.map((item, index) => {
                if(item.masterid === masterid){
                    if (item.type === 'checkbox' || item.type === 'switch' || item.type === 'dropdown' ) {
                        if(item.externallink === 'Yes' ) {
                            if(Array.isArray(item.values)) {
                                item.values[0].value = fieldValue;
                            }
                            else if(item.values !== '') {
                                item.values.push({value : fieldValue, refIndexNo :0});
                            }
                        }
                        else if((item.type === 'checkbox' || item.type === 'switch') && item.optionstatus){
                            if(Array.isArray(item.values)) {
                                item.values[0].value = fieldValue;
                            }
                            else if(item.values !== '') {
                                item.values.push({value : fieldValue, refIndexNo :0});
                            }
                        }
                        else if(item.type === 'dropdown' ){
                            if(Array.isArray(item.values)) {
                                item.values[0].value = fieldValue;
                            }
                            else if(item.values !== '') {
                                item.values.push({value : fieldValue, refIndexNo :0});
                            }
                        }
                        else {
                            item.values = [{value : fieldValue.length >0 ? fieldValue[0] :''}];
                        }
                    }
                    else {
                        item.values = [{value : fieldValue, refIndexNo :0}];
                    }
                }
            })

        }

        this.setFieldValue(masterid, fieldName, fieldValue);
        this.setState({[fieldName]: fieldValue, getFormdetails: getFormdetails});

    }
    setFieldValue = (masterid, fieldName , fieldValue) => {
        let leadComponentList = this.props.getFormdetails.formvalues;

        let temp_value = [];

        let thisComponent = {};
        let matchedComponent = leadComponentList.filter( item => item.masterid === masterid);
        if(matchedComponent.length > 0) {
            thisComponent = matchedComponent[0];
            if (thisComponent.type === 'checkbox' || thisComponent.type === 'switch' || thisComponent.type === 'dropdown' ) {
                if(thisComponent.externallink === 'Yes' ) {
                    thisComponent.temp_value = fieldValue;
                }
                else if((thisComponent.type === 'checkbox' || thisComponent.type === 'switch') && thisComponent.optionstatus){
                    thisComponent.temp_value = fieldValue;
                }
                else if(thisComponent.type === 'dropdown' ){
                    thisComponent.temp_value = fieldValue;
                }
                else {
                    thisComponent.temp_value = [{value : fieldValue.length >0 ? fieldValue[0] :''}];
                }
            }
            else {
                thisComponent.temp_value = [{value : fieldValue}];
            }
        }
    }

    updateLead = () => {

        this.setState({
            leadSubmitting : 1
        })
        // alert(); return false
        let collectionId = this.props.linkedCollectionId.param;

        let componentList =  [...this.state.getFormdetails];

        let formValues = this.props.formDetails.formValues[this.props.linkedId.param];

        let metaForm = [];
        let formconcat = '';
        for(var i=0;i< componentList.length ; i++) {

            let fieldValue = '';
            if(formValues && formValues.length > 0) {
                let this_field = formValues.filter((item, index) => item.masterid === componentList[i].masterid);

                if(componentList[i].parentid !== '') {
                    let parentFieldArr = formValues.filter((item, index) => item.masterid === componentList[i].parentid);

                    if(parentFieldArr.length >0) {
                        let parentField = parentFieldArr[0];
                        let parentValues = parentField.values;
                        this_field = parentValues.filter((pv, index) => pv.masterid === componentList[i].masterid);
                    }
                }
                if(this_field.length > 0){
                    fieldValue = componentList[i].temp_value ? componentList[i].temp_value: this_field[0].values;
                }
            }

            let compEntry = this.createFieldJson(componentList[i],fieldValue);
            metaForm.push(compEntry);
        }

        // console.log(metaForm); //return false;
        let formData = {
            mode: "update",
            parent: collectionId,
            uid: getProfile().id,
            usertype: getProfile().usertype,
            referenceID: collectionId,
            formconcat: formconcat,
            formdata: JSON.stringify(metaForm),
            child: this.state.selectedLeadId,
            formtype: '',
            formSource: 'whatsapp',
            reference : this.props.reference ? this.props.reference : ''
        };
        this.props.saveForm(formData);
        this.setState({
            successMessage : 'Profile updated successfully'
        })
    }

    createFieldJson = (component, fieldValue) => {
        let valueArray = [];
        if(Array.isArray(fieldValue)) {
            valueArray = fieldValue;
        }
        else if(fieldValue !== '') {
            valueArray.push({value : fieldValue, refIndexNo :0});
        }
        let formEle = {
            type : component.type,
            masterid : component.masterid,
            label : component.label,
            value : valueArray,
            external_linked : (component.externallink && component.externallink === "Yes")? "yes" : "no",
        };
        if(component.type === 'uniqueid' && component.text_random) {
            formEle.text_random = component.text_random;
        }
        if((component.type === 'checkbox' || component.type === 'switch') && component.optionstatus) {
            formEle.optionstatus = component.optionstatus ? component.optionstatus : '';
        }
        return formEle;
    }


    render() {
        //console.log(this.props.showFormModalData._id)
        //    console.log(this.state)
        // console.log(this.props)
        let getFormdetails=this.state.getFormdetails
        // console.log(getFormdetails)
        let showlistField=[]
        if(this.props.showFormModalData.showformdata.length !==0) {

            for (let i = 0; i < this.props.showFormModalData.showformdata.pages.length; i++) {
                (this.props.showFormModalData.showformdata.pages[i].components.filter(list=> list.visibility !=="hidden")).map(list=>{
                    showlistField.push(list)

                })
            }
            //console.log(showlistField)
        }
        const {
            id, showBasicContent, showPreview,
            dropzoneID, parentID, name, payload
        } = this.props;

        const label =  this.state.label || payload && payload.label || '';
        const setFields =  this.state.setFields || payload && payload.setFields ;
        let  containerData={
            label :  this.state.label || payload && payload.label || '',
            accessbox:true,
            edit :  this.state.edit || payload && payload.edit||false,
            display:true,
            showlistField:showlistField,
            setFields :  this.state.setFields || payload && payload.setFields

        }
        /*************** Mapping the fieldset child components **************/
        let fieldsetComp = getFormdetails && getFormdetails.length !== 0 && getFormdetails.filter(list => list.type === 'fieldset') || [];
        let childComp = [];
        if(fieldsetComp.length > 0) {
            childComp = fieldsetComp.map(fld => {
                return fld.values !== null ? fld.values :[];
            })
        }

        let childFields = [].concat.apply([], childComp);
        /*************** Mapping the fieldset child components **************/


        if (showBasicContent) {
            return (
                <Draggable { ...this.props } >
                    {

                        <span className="drag-icon">{name}</span>
                    }
                </Draggable>
            )
        }

        if (showPreview){

            return (
                <>
                    {getUrlSegment(1) !== 'whatsappchat' &&
                    
                    <div class="floating-summary">
                        <div class="growth-boiler-details-header">
                            <h5></h5>
                            {containerData.edit &&
                            <nav className="edit-block"
                                 onClick={() => this.editRecord(this.props.showFormModalData._id, setFields, "edit", this.props.getFormdetails.id)}>Edit</nav>
                            }
                        </div>
                        <div className="growth-enable-table">
                            <table className="table entity-dashboard-table">
                                <tbody>

                                {setFields.map((item, index) => {
                                        let matchedFieldArr = childFields.filter(list => list.masterid === item.masterid);
                                        let valueArr = this.props.getFormdetails.formvalues.filter(list => list.masterid === item.masterid) || [];
                                        let fieldName = valueArr.length > 0 ? valueArr[0].fieldname : '';
                                        let fieldValues = (valueArr.length > 0 && valueArr[0].values && valueArr[0].values.length !== 0) ? valueArr[0].values : [];

                                        let attr = (valueArr.length > 0 && valueArr[0]) ? valueArr[0] : {};
                                        let fileUrl = '';
                                        let fileName = '';
                                        let downloadImage = false;
                                        let urlClickable = false;
                                        if (item.type === 'imageurl') {
                                            fileUrl = fieldValues.length > 0 ? fieldValues[0].value : '';
                                            downloadImage = true;
                                            urlClickable = false;
                                            let fileStrArr = fileUrl.split('/');
                                            if (fileStrArr.length > 0) {
                                                fileName = fileStrArr[fileStrArr.length - 1];
                                            }
                                        }
                                        if (item.type === 'url') {
                                            fileUrl = fieldValues.length > 0 ? fieldValues[0].value : '';
                                            if (hasExtension(fileUrl) === true) {
                                                downloadImage = true;
                                                urlClickable = false;
                                                let fileStrArr = fileUrl.split('/');
                                                if (fileStrArr.length > 0) {
                                                    fileName = fileStrArr[fileStrArr.length - 1];
                                                }
                                            } else {
                                                fileName = fileUrl;
                                                downloadImage = false;
                                                urlClickable = true;
                                            }
                                            // console.log(fileName)
                                        }

                                        return (
                                            <>
                                                {valueArr.length > 0 && valueArr.filter(val => val.type !== 'fieldset').length !== 0 &&
                                                <tr>
                                                    <td>
                                                        {fieldName}
                                                    </td>
                                                    {fieldValues.length > 0 && fieldValues.map((list, index) => {
                                                            let albumName;
                                                            if (isImage(list.uniqvalue)) {
                                                                albumName = 'images/'
                                                            } else if (isVideo(list.uniqvalue)) {
                                                                albumName = 'videos/'
                                                            } else {
                                                                albumName = 'documents/'
                                                            }

                                                            return ((item.type === 'imageurl' || item.type === 'url') ?
                                                                <td>
                                                                    {urlClickable ? <a target="_blank" href={list.value}>{list.value}</a> : fileName}
                                                                    {fileName !== '' && downloadImage &&
                                                                    <a className="rs-tab-btn ico-lib-v1 rc-download cursor" onClick={(e) => {
                                                                        forceDownload(fileUrl, fileName)
                                                                    }}></a>
                                                                    }
                                                                </td>
                                                                : (item.type === 'attachment') ?
                                                                    <tr className="attach-wrap-multiple">
                                                                        <td onClick={(e) => {
                                                                            forceDownload(S3_URL + albumName + list.uniqvalue, list.value.split("/").slice(-1).join().split(".").shift())
                                                                        }} dangerouslySetInnerHTML={{__html: list.value}}/>
                                                                        <td></td>
                                                                        <td onClick={(e) => {
                                                                            forceDownload(S3_URL + albumName + list.uniqvalue, list.value.split("/").slice(-1).join().split(".").shift())
                                                                        }} className="rs-tab-btn ico-lib-v1 rc-download cursor"/>


                                                                    </tr>


                                                                    :
                                                                    (item.type !== "datepicker" ?
                                                                            <td colspan="2" dangerouslySetInnerHTML={{__html: list.value}}/> :
                                                                            <td>{(list.value !== "" && attr.datetime && attr.datetime === 'Yes' && attr.autocapture && attr.autocapture === 'Yes') ?
                                                                                moment.utc(list.value).local().format('DD-MM-YYYY hh:mm:ss') :
                                                                                (list.value !== "" && attr.datetime && attr.datetime === 'Yes') ?
                                                                                    moment(list.value).format('DD-MM-YYYY hh:mm:ss') :
                                                                                    (list.value !== "" && (!attr.datetime || attr.datetime === 'No')) ? moment.utc(list.value).local().format('DD-MM-YYYY') : ''} </td>
                                                                    ))
                                                        }
                                                    )}
                                                    {valueArr.length > 0 && valueArr[0].values.length === 0 &&
                                                    <td/>

                                                    }
                                                </tr>

                                                }


                                                {matchedFieldArr.length > 0 &&
                                                <tr>
                                                    <td>
                                                        {matchedFieldArr[0].fieldname}
                                                    </td>

                                                    {matchedFieldArr[0].values.map((list, index) =>

                                                        <td dangerouslySetInnerHTML={{__html: list.value}}/>
                                                    )}
                                                    {matchedFieldArr[0].values.length === 0 &&
                                                    <td/>
                                                    }

                                                </tr>
                                                }
                                            </>
                                        )
                                    }
                                )}


                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    }
                    {getUrlSegment(1) === 'whatsappchat' &&
                    
                    <div className="profile-entry-data" id="profile-details">

                        <div className="profile-entry-header">
                            <h5>Profile Details</h5>
                            {containerData.edit &&
                            <button className={`general-btn ${this.state.leadSubmitting === 1 ? 'disabled' : ''}`}
                                    onClick={this.updateLead}>Update</button>
                            }
                        </div>
                        <div className="profile-entry-body">
                            {setFields.map((item, index) => {
                                    let matchedFieldArr = childFields.filter(list => list.masterid === item.masterid);
                                    let valueArr = getFormdetails && getFormdetails.length !== 0 && getFormdetails.filter(list => list.masterid === item.masterid) || [];
                                    let fieldName = valueArr.length > 0 ? valueArr[0].fieldname : '';
                                    let fieldValues = (valueArr.length > 0 && valueArr[0].values && valueArr[0].values.length !== 0) ? valueArr[0].values : [];
                                    let attr = (valueArr.length > 0 && valueArr[0]) ? valueArr[0] : {};
                                    let fileUrl = '';
                                    let fileName = '';
                                    let downloadImage = false;
                                    let urlClickable = false;
                                    if (item.type === 'imageurl') {
                                        fileUrl = fieldValues.length > 0 ? fieldValues[0].value : '';
                                        downloadImage = true;
                                        urlClickable = false;
                                        let fileStrArr = fileUrl.split('/');
                                        if (fileStrArr.length > 0) {
                                            fileName = fileStrArr[fileStrArr.length - 1];
                                        }
                                    }
                                    if (item.type === 'url') {
                                        fileUrl = fieldValues.length > 0 ? fieldValues[0].value : '';
                                        if (hasExtension(fileUrl) === true) {
                                            downloadImage = true;
                                            urlClickable = false;
                                            let fileStrArr = fileUrl.split('/');
                                            if (fileStrArr.length > 0) {
                                                fileName = fileStrArr[fileStrArr.length - 1];
                                            }
                                        } else {
                                            fileName = fileUrl;
                                            downloadImage = false;
                                            urlClickable = true;
                                        }
                                        // console.log(fileName)
                                    }
                                    return (
                                        <>
                                            {valueArr.length > 0 && valueArr.filter(val => val.type !== 'fieldset').length !== 0 &&
                                            <div className="each-details-entry">
                                                <div className="details-entry-left">
                                                    <label>{fieldName}</label>
                                                </div>
                                                {fieldValues.length > 0 && fieldValues.map((list, index) => {
                                                        let albumName;
                                                        if (isImage(list.uniqvalue)) {
                                                            albumName = 'images/'
                                                        } else if (isVideo(list.uniqvalue)) {
                                                            albumName = 'videos/'
                                                        } else {
                                                            albumName = 'documents/'
                                                        }

                                                        return ((item.type === 'imageurl' || item.type === 'url') ?
                                                                <div className="details-entry-right">
                                                                    {urlClickable ? <a target="_blank" href={list.value}>{list.value}</a> : fileName}
                                                                    {fileName !== '' && downloadImage &&
                                                                    <a className="rs-tab-btn ico-lib-v1 rc-download cursor" onClick={(e) => {
                                                                        forceDownload(fileUrl, fileName)
                                                                    }}></a>
                                                                    }
                                                                </div>
                                                                : (item.type === 'attachment') ?
                                                                    <div className="details-entry-right">
                                                    <span onClick={(e) => {
                                                        forceDownload(S3_URL + albumName + list.uniqvalue, list.value.split("/").slice(-1).join().split(".").shift())
                                                    }} dangerouslySetInnerHTML={{__html: list.value}}>
                                                    </span>
                                                                        <span></span>
                                                                        <span onClick={(e) => {
                                                                            forceDownload(S3_URL + albumName + list.uniqvalue, list.value.split("/").slice(-1).join().split(".").shift())
                                                                        }} className="rs-tab-btn ico-lib-v1 rc-download cursor">
                                                    </span>
                                                                    </div>


                                                                    :
                                                                    (item.type !== "datepicker" ?
                                                                            <div className="details-entry-right">
                                                                                {/*<span dangerouslySetInnerHTML={{__html: list.value}}></span>*/}
                                                                                <input type="text" name={item.label}
                                                                                       value={list.value}
                                                                                       onChange={(e) => this.handleProfileChange(e, item.masterid)}/>
                                                                            </div>
                                                                            :
                                                                            <div className="details-entry-right">
                                                        <span>{(list.value !== "" && attr.datetime && attr.datetime === 'Yes' && attr.autocapture && attr.autocapture === 'Yes') ?
                                                            moment.utc(list.value).local().format('DD-MM-YYYY hh:mm:ss') :
                                                            (list.value !== "" && attr.datetime && attr.datetime === 'Yes') ?
                                                                moment(list.value).format('DD-MM-YYYY hh:mm:ss') :
                                                                (list.value !== "" && (!attr.datetime || attr.datetime === 'No')) ? moment.utc(list.value).local().format('DD-MM-YYYY') : ''} </span>
                                                                            </div>
                                                                    )
                                                        )
                                                    }
                                                )}
                                                {valueArr.length > 0 && valueArr[0].values.length === 0 &&
                                                <div className="details-entry-right"></div>
                                                }
                                            </div>

                                            }


                                            {matchedFieldArr.length > 0 &&
                                            <div className="details-entry-right">
                                    <span>
                                      {matchedFieldArr[0].fieldname}
                                    </span>

                                                {matchedFieldArr[0].values.map((list, index) =>

                                                    <span dangerouslySetInnerHTML={{__html: list.value}}></span>
                                                )}
                                                {matchedFieldArr[0].values.length === 0 &&
                                                <span></span>
                                                }

                                            </div>
                                            }
                                        </>
                                    )
                                }
                            )}

                        </div>

                    </div>
                    
                    }
                </>
            );
        }

        return (
            <React.Fragment>
                <Draggable { ...this.props } draggable={!this.props.DragDisabled.dragStatus}>
                    <React.Fragment>
                        <FormGroup className="m-0">
                            <label className="col-sm-12">
                                <div className={`left-container`} >
                                    <span>{name}</span>
                                </div>
                                <div className="chart-icons">
                                    <div className="settings-icon" onClick={() => this.openSetting(id, dropzoneID, parentID)}/>
                                    <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}> </div>
                                </div>
                            </label>
                        </FormGroup>
                    </React.Fragment>
                </Draggable>

                {this.state.modalStatus &&
                <EntityModal
                    title={`Group Card  -   Settings`}
                    containerData={containerData}
                    //removeData={this.state.colorpickerStatus}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    addData={this.addData}
                    checkBoxchange={this.checkBoxchange}
                    handleLabelChange={this.handleLabelChange}
                    handleChangeComplete={this.handleChangeComplete}
                    removeData={this.removeData}
                />
                }
                {this.state.errorMessageOnSaving &&
                <AlertModal id="formError"
                            title="Some technical issues occured. Please try again"
                            OK_button_text="Ok"
                            OK_action={() => this.hideErrorAlert()}
                            showModal={this.state.errorMessageOnSaving}
                            CANCEL_button={`hide`}
                />
                }
                {this.state.formSaved &&
                <AlertModal id="formSaved"
                            title={this.state.successMessage}
                            OK_button_text="Ok"
                            OK_action={() => this.hideSavedFormAlert()}
                            showModal={this.state.formSaved}
                            CANCEL_button={`hide`}
                />
                }

            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    showFormModal:state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange:state.DynamicDashboard.getDashboard,
    DragDisabled:state.DynamicDashboard.getDragDisabled,
    showFormModalData:state.EntityReducer.showFormModal,
    getFormdetails : state.CollectionsReducer.getFormData,
    getaddRecord : state.DynamicDashboard.getaddRecord,
    linkedId: state.EntityReducer.LinkedId,
    linkedCollectionId: state.EntityReducer.linkedCollectionId,
    getSavedForm : state.CollectionsReducer.getSavedForm,
    formDetails : state.WhatsappReducer.getFormDetails,

});
export default  withRouter(connect(mapStateToProps, {
    addRecord:DynamicDashboard.addRecord,
    getselectedcollections : formsActions.getFormData,
    saveForm : collectionActions.saveForm,

})(GeGroupTable));
