import axios from 'axios'
import {commonService} from "./common.services";
import {API_PATH,ES_INDEX} from '../constants/index'

export const projects = {
    listProject,
    listProjectDetails,
    listTags,
    milestonelist,
    SaveProject,
    SaveProjectDiscussion,
    SaveProjectComments,
    SaveAssignedUsers,
    moreitems,
    ProjectInfo,
    SaveMilestone,
    Getmilestone,
    Getprojectusers,
    Getprojectsections,
    GetProjectdiscussions,
    GetDiscussionsDetails,
    GetAllComments,
    SaveProjectUser,
    DeleteProject,
    DeleteProjectUser,
    SaveSections,
    UpdateTaskSections,
    AssignMilestone,
    GetSections,
    overviewHelper,
    taskHelper,
    milestonesections,
    getheaderinfo,
    Savenewsandmedia,
    GetNewsMedia,
    DeleteMilestone,
    GetCollectionInfo,
    DeleteSection,
    updateProject
};


function listProject(params){
    return commonService.sendHttpRequest('sector/projectlist', params, "GET");
    /*params["index"]=ES_INDEX
    params["t"]=Math.floor(Date.now() / 1000)
    return axios.get(API_PATH+'portfolio/projectlist', {params});*/
}

function listProjectDetails(params){
    /*params["index"]=ES_INDEX
    params["t"]=Math.floor(Date.now() / 1000)
    return axios.get(API_PATH+'portfolio/projectdetails', {params});*/

    return commonService.sendHttpRequest('sector/projectdetails', params, "GET");
}

function listTags(type,count){
    // return axios.post(API_PATH + 'todo/userdata',{
    //     "type": type,
    //     "count":count,
    //     "index": "lsinternal"
    // })
    let params={
        "type": type,
        "count":count,
    }
    return commonService.sendHttpRequest('todo/userdata', params, "POST");
}
function milestonelist(params){
    return commonService.sendHttpRequest('sector/milestonelist', params, "GET");
}

function SaveProject(params) {
    return commonService.sendHttpRequest('sector/saveproject', params, "POST");
}

function SaveProjectDiscussion(params) {
    return commonService.sendHttpRequest('sector/savediscussiontopic', params, "POST");
}
function SaveProjectComments(params) {
    return commonService.sendHttpRequest('sector/savecomments', params, "POST");
}
function SaveAssignedUsers(params) {
    return commonService.sendHttpRequest('sector/assignmoreusers', params, "POST");
}
function moreitems(params) {
    return commonService.sendHttpRequest('sector/moreitems', params, "POST");
}

function ProjectInfo(params) {
    return commonService.sendHttpRequest('sector/projectInfo', params, "GET");
}
function SaveMilestone(params) {
    return commonService.sendHttpRequest('sector/savemilestone', params, "POST");
}

function Getmilestone(params) {
    return commonService.sendHttpRequest('sector/milestone', params, "GET");
}
function Getprojectusers(params) {
    return commonService.sendHttpRequest('sector/projectmembers', params, "GET");
}
function Getprojectsections(params) {
    return commonService.sendHttpRequest('sector/projectsections', params, "GET");
}
function GetProjectdiscussions(params) {
    return commonService.sendHttpRequest('sector/projectdiscussions', params, "GET");
}
function GetDiscussionsDetails(params) {
    return commonService.sendHttpRequest('sector/discussiondetails', params, "GET");
}
function GetAllComments(params) {
    return commonService.sendHttpRequest('sector/allcomments', params, "GET");
}
function SaveProjectUser(params) {
    return commonService.sendHttpRequest('sector/saveprojectusers', params, "POST");
}
function DeleteProject(params) {
    return commonService.sendHttpRequest('sector/deleteproject', params, "POST");
}
function DeleteProjectUser(params) {
    return commonService.sendHttpRequest('sector/removeprojectuser', params, "POST");
}
function SaveSections(params) {
    return commonService.sendHttpRequest('sector/savemastersection', params, "POST");
}
function GetSections(params) {
    return commonService.sendHttpRequest('sector/getsection', params, "POST");
}
function UpdateTaskSections(params) {
    return commonService.sendHttpRequest('task/updatetasksection', params, "POST");
}
function AssignMilestone(params) {
    // let params = {
    //     "projectid": pid,
    //     "sectionid": sid,
    //     "milestoneid": mid,
    //     "child": 0,
    //     "index": ES_INDEX
    // }
    return commonService.sendHttpRequest('sector/assignmilestone', params, "POST");
}
function overviewHelper(params){
    return commonService.sendHttpRequest('sector/overviewhelper', params, "GET");
}
function taskHelper(params){
    return commonService.sendHttpRequest('sector/taskhelper', params, "GET");
}
function milestonesections(params){
    return commonService.sendHttpRequest('sector/milestonesections', params, "GET");
}
function getheaderinfo(params){
    return commonService.sendHttpRequest('sector/projectoverview', params, "GET");
}
function Savenewsandmedia(params){
    return commonService.sendHttpRequest('sector/savenews', params, "POST");
}
function GetNewsMedia(params){
    return commonService.sendHttpRequest('sector/news', params, "GET");
}
function DeleteMilestone(params) {
    return commonService.sendHttpRequest('sector/deletemilestone', params, "POST");
}
function GetCollectionInfo(params) {
    return commonService.sendHttpRequest('collection/collectioninfo', params, "POST");
}
function DeleteSection(params) {
    return commonService.sendHttpRequest('sector/ ', params, "POST");
}
function updateProject(params) {
    return commonService.sendHttpRequest('portfolio/saveprojectlocation', params, "POST");
}