import React,{Component} from "react";
import {Droppable} from "react-beautiful-dnd";
import OutsideAlerter from "../../views/OutsideAlerter";
import {getProfile} from "../../../utilities/AuthService";
import {Accordion,Card,Table} from "react-bootstrap";
import moment from "moment";
import {
    DateFormatter,
    forceDownload,
    getFilePath,
    isImage,
    isVideo,
    previewModalFormat, urlify
} from "../../../utilities/CustomFunctions";
import {FRMSDOC_PATH, FRMSIMG_PATH, FRMSPDFDOC_PATH, FRMSVID_PATH, USRIMG_PATH} from "../../../constants";
import * as CustomFunctions from "../../../utilities/CustomFunctions";
import VideoPlayer from "../../views/player/VideoPlayer";
import LoaderSvg from "../../views/LoaderSVG";
import DateTimePickerBox from "../../views/DateTimePickerBox";
import AutoComplete from "../../views/AutoComplete";
import NoData from "../../views/NoData";
const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? "#333333" : "lightgrey",

});
class LearnHeader extends Component {

    render() {
        let courseInfo = this.props.courseInfo||[];
        let description = decodeURIComponent(escape(atob([courseInfo.description])))
        let sectionList = this.props.sectionList||[];
        let moduleCount = 0;
        sectionList.forEach(
            function iterator(item,index){
                item.modules.forEach(
                    function iterator(item,index){
                        moduleCount++;
                    }
                );
            }
        );
        return(
            <React.Fragment>
                <div className="form-detail-panel common-align no-bottom project-header-align  with-publish  learn-tab-block">
                    <div className="form-detail-left">
                        <img src="assets/images/animal.jpg"/>
                    </div>
                    <div className="form-detail-right with-publish">
                        <div className="form-detail-top learn-top height-210">
                            <div className="form-detail-top-inside-wrapper">
                                <a className="form-detail-top-left common-bk-button wid-75"
                                   onClick={this.props.history.goBack}>
                                    <h5 className={`${courseInfo.has_publish === 'yes' && courseInfo.has_modify === 'no' ? "published":''}`}>{atob([courseInfo.title])}</h5> </a>
                                <div className="form-detail-top-left wid-75">
                                    <p>{description}</p>
                                    <ul className="learn-course-info">
                                        <li>Version : {courseInfo.version_count}</li>
                                        {courseInfo.sectioncount > 0 &&
                                        <li>Sections : {courseInfo.sectioncount}</li>
                                        }
                                        {courseInfo.assigneecount > 0 &&
                                        <li>Users : {courseInfo.assigneecount}</li>
                                        }
                                    </ul>                                    
                                    <p>Created on : {moment(courseInfo.created_on).format('DD MMMM YYYY')}</p>
                                    {courseInfo.published_date && <p>Last Published Date : {moment(courseInfo.published_date).format('DD MMMM YYYY')}</p>}
                                </div>
                                <div className="learn-option-block">
                                    {courseInfo.sectioncount > 0 && moduleCount > 0  && courseInfo.has_modify === 'yes' &&
                                    <button type="button" className="general-btn publish-btn" id="publish" onClick={(e)=>this.props.publishCourse(e,courseInfo.version_count)}>Publish</button>
                                    }
                                    {courseInfo.has_publish === 'yes' && courseInfo.has_modify === 'no' &&
                                    <button type="button" className="general-btn" id="publish"
                                            onClick={(e) => this.props.createNewVersion(e,courseInfo.version_count)}>New Version</button>
                                    }
                                    {courseInfo.sectioncount > 0 && moduleCount > 0 &&
                                    <button type="button" className="general-btn" id="preview"
                                            onClick={(e) => this.props.coursePreview(e)}>Course Preview</button>
                                    }
                                    {courseInfo.sectioncount > 0 && courseInfo.has_publish === 'yes' && courseInfo.has_modify === 'no' &&
                                        <button type="button" className="general-btn" id="assign"
                                                onClick={(e) => this.props.showAssignCourseModal(e)}>Assign Course</button>
                                    }
                                </div>
                            </div>


                            {(getProfile().usertype === "Admin" || getProfile().usertype === "SuperAdmin" || getProfile().usertype === "root" || courseInfo.creator === getProfile().id || this.props.editPermission === 'yes' || this.props.deletePermission === 'yes') &&
                            <div className="form-option-block">
                                <button className="ico-lib opt-form-ico"
                                        onClick={this.props.showHeaderOptions}></button>
                                <div>
                                    {this.props.headerOptions &&
                                    <OutsideAlerter
                                        status={this.props.headerOptions}
                                        Triggered={() => this.props.showHeaderOptions(!this.props.headerOptions)}>
                                        <ul className="panel-option" style={{display: 'block'}}>
                                            {this.props.editPermission === 'yes' &&
                                            <li>
                                                <nav className="panel-option-click ico-lib form-edit-ico"
                                                     onClick={(e) => {
                                                         this.props.AddmodalOpen(e);
                                                         this.props.showHeaderOptions(!this.props.headerOptions)
                                                     }}>Edit
                                                    Course
                                                </nav>
                                            </li>
                                            }
                                            {this.props.deletePermission === 'yes' &&  courseInfo.assigneecount === 0 &&
                                            <li>
                                                <nav
                                                    className="panel-option-click ico-lib delete-task-ico"
                                                    onClick={(e) => {
                                                        this.props.deleteCourse(e);
                                                        this.props.showHeaderOptions(!this.props.headerOptions)
                                                    }}>Delete
                                                    Course
                                                </nav>
                                            </li>
                                            }
                                        </ul>
                                    </OutsideAlerter>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                        <div className="learn-tab">
                            <button className={`each-learn-tab ${this.props.tabType === 'details' ? 'active' : ''}`} onClick={(e)=>this.props.changeTab(e,'details')}>Details</button>
                            <button className={`each-learn-tab ${this.props.tabType === 'reports' ? 'active' : ''}`} onClick={(e)=>this.props.changeTab(e,'reports')}>Report</button>
                            <button className={`each-learn-tab ${this.props.tabType === 'logs' ? 'active' : ''}`} onClick={(e)=>this.props.changeTab(e,'logs')}>Logs</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )};
}
class  SectionHeaderOptions extends Component {

    render() {
        let list=this.props.list ? this.props.list:{};
        let courseInfo = this.props.courseInfo||[];
        return(
            <React.Fragment>
                {courseInfo.has_modify === 'yes' &&
                <div className="form-option-block">
                    <button className="ico-lib opt-form-ico" onClick={() => this.props.showOptions(list.sectionid)}></button>
                    {/*<button className="general-btn" onClick={() => this.props.addNewModuleModal(list.sectionid)}>Add Module</button>*/}
                    <div>
                        {this.props.alerterstatus && this.props.fieldID === list.sectionid &&
                        <OutsideAlerter
                            status={this.props.alerterstatus}
                            Triggered={() => this.props.showOptions(!this.props.alerterstatus)}>
                            <ul className="panel-option" style={{display: 'block'}}>
                                {/*<li>*/}
                                {/*    <nav className="panel-option-click ico-lib form-edit-ico" onClick={(e) => {*/}
                                {/*        this.props.AddNewSectionModal(e, list.sectionid);*/}
                                {/*        this.props.showOptions(!this.props.alerterstatus)}}>Edit Section</nav>*/}
                                {/*</li>*/}
                                <li>
                                    <nav className="panel-option-click ico-lib toggle-resource-ico" onClick={(e) => {
                                        this.props.showAttachResourceModal(e, list.sectionid);
                                        this.props.showOptions(!this.props.alerterstatus)}}>Add Resource</nav>
                                </li>
                                <li>
                                    <nav className="panel-option-click ico-lib-v1 build-page-ico"  onClick={(e) => {
                                        this.props.showAttachPageModal(e, list.sectionid);
                                        this.props.showOptions(!this.props.alerterstatus)}}>Add Page</nav>
                                </li>
                                <li>
                                    <nav className="panel-option-click ico-lib lnkd"  onClick={(e) => {
                                        this.props.showAttachFormModal(e, list.sectionid);
                                        this.props.showOptions(!this.props.alerterstatus)}}>Add Forms</nav>
                                </li>

                                 <li>
                                    <nav className="panel-option-click ico-lib-v1 assessment"  onClick={(e) => {
                                        this.props.showAssessmentModal(e, list.sectionid);
                                        this.props.showOptions(!this.props.alerterstatus)}}>Add Assessment</nav>
                                </li>
                                <li>
                                    <nav className="panel-option-click ico-lib form-embed-ico"  onClick={(e) => {
                                        this.props.showHtmlContentModal(e, list.sectionid);
                                        this.props.showOptions(!this.props.alerterstatus)}}>Add Content</nav>
                                </li>
                                {/*<li>*/}
                                {/*    <nav className="panel-option-click ico-lib add-task-ico"  onClick={(e) => {*/}
                                {/*        this.props.showAddNewTaskModal(e, list.sectionid);*/}
                                {/*        this.props.showOptions(!this.props.alerterstatus)}}>Add Task</nav>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*    <nav className="panel-option-click ico-lib form-set-ico">Settings</nav>*/}
                                {/*</li>*/}
                                {/*{list.modules.length === 0 &&*/}
                                {/*<li>*/}
                                {/*    <nav className="panel-option-click ico-lib delete-task-ico"*/}
                                {/*         onClick={(e) => {*/}
                                {/*             this.props.deleteSectionModal(e, list.sectionid);*/}
                                {/*             this.props.showOptions(!this.props.alerterstatus)}}>Delete Secton</nav>*/}
                                {/*</li>*/}
                                {/*}*/}
                            </ul>
                        </OutsideAlerter>
                        }
                    </div>
                </div>
                }
            </React.Fragment>
        )};
}
class AttachResources extends Component {

    render() {
        let resources = this.props.resources||[];
        let combinedResources=this.props.combinedResources||[];
        console.log(combinedResources)
        return(
            <React.Fragment>
                <div className="modal bsy-modal resource-preview-mod show" id="resourceModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: "block"}}>
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib ">Attach Resources</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={(e) => {this.props.hideResourceList(e)}}></button>
                            </div>
                            <div className="modal-body  modal-in-colored modal-body-heighted padding-zero">
                                <div className="rs-preview-block">
                                    <div className="mang-lbl-block mang-lbl-block-mod-in">
                                        <div className="resource-filter-modal-header">
                                            <div className="lbl-panel-search-block">
                                                <input data-target="#dropResources" data-action="search" type="text"
                                                       placeholder="Search Resources..." className="lbl-search-box" onChange={(e)=> this.props.handleResourceChange(e,e.target.value,this.props.resType)} />
                                                <span className="ico-lib lbl-search-ico"></span>
                                            </div>
                                            <div className="select-box">
                                                <select
                                                    onChange={(e) => this.props.handleResourceChange(e, this.props.searchText, e.target.value)}>
                                                    <option value={'0'}>Select</option>
                                                    <option value={'1'}>Images</option>
                                                    <option value={'3'}>Documents</option>
                                                    <option value={'2'}>Video</option>
                                                    <option value={'4'}>Audio</option>
                                                    <option value={'5'}>Streaming Video</option>
                                                    <option value={'6'}>YouTube Video</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="resource-filter-modal-body">
                                            <ul className="grid-list-switch res-modal">
                                                <li><a className="icon-list" data-switch="list-grid" data-target="1"></a>
                                                </li>
                                                <li><a className="icon-grid active" data-switch="list-grid"
                                                       data-target="2"></a>
                                                </li>
                                            </ul>
                                            <div className="content-main-in" id="dropResources">
                                                {resources.map((item,index)=>
                                                    <ResourceTodoTile key={`resource_${index}`} item={item} index={index} selectResource = {this.props.selectResource} combinedResourceIds={this.props.combinedResourceIds}  />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rs-preview-box">
                                        <div className="rs-preview-header">
                                            <h5>Selected Resources</h5>
                                        </div>
                                        <div className="rs-preview-body">
                                            {combinedResources.length === 0 &&
                                            <p>No Resource selected</p>
                                             }
                                            {combinedResources.map((item,index)=>
                                                <div className="each-rs-preview">
                                                    <div className="rs-thumb-preview">
                                                        {/*<img src="https://bsynapse.imgix.net/images/fn1602483418334.jpg" />*/}
                                                        {item.res_type === '1' &&
                                                        <img src={FRMSIMG_PATH + item.res_img}/>
                                                        }
                                                        {item.res_type === '2' &&
                                                        <React.Fragment>
                                                            <VideoPlayer
                                                                key={FRMSVID_PATH + item.res_img}
                                                                display={'block'}
                                                                width="100%"
                                                                height="100%"
                                                                src={FRMSVID_PATH + item.res_img}
                                                            />
                                                        </React.Fragment>
                                                        }
                                                        {item.res_type === '3' &&
                                                        <iframe src={FRMSPDFDOC_PATH + item.res_img + '&embedded=true'} width="100%" height="250px"/>
                                                        }
                                                        {item.res_type === '4'  &&
                                                        <audio  width="100%" height="100%" controls controlsList="nodownload" key={this.props.ImgPath + this.props.ImgName}>
                                                            <source type="audio/mpeg" src={FRMSDOC_PATH + item.res_img}></source>
                                                        </audio >
                                                        }
                                                        {item.res_type === '5' &&
                                                        <React.Fragment>
                                                            <VideoPlayer
                                                                key={FRMSVID_PATH + item.res_img}
                                                                display={'block'}
                                                                width="100%"
                                                                height="100%"
                                                                src={FRMSVID_PATH + item.res_img}
                                                                resType='5'
                                                                fileName={item.res_img}
                                                            />
                                                        </React.Fragment>
                                                        }
                                                        {item.res_type === '6'  &&
                                                        <iframe width="500" height="250" src={item.res_img} alt="Thumb-img"/>
                                                        }
                                                    </div>
                                                    <div className="rs-thumb-details">
                                                        <div className="section-info image">
                                                            <h5>{item.title}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer  checklist-footer">
                                {this.props.combinedResources.length > 0 && <span id="resourceCount">{this.props.combinedResources.length} items selected</span>}
                                <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {this.props.hideResourceList(e)}}>Cancel
                                </button>
                                <button type="button" className="general-btn" id="fetchResources" onClick={(e)=>this.props.attachSelectedResources(e)}>Attach</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )};
}
class AttachPages extends Component {

    render() {
        let Pages = this.props.Pages||[];
        return(
            <React.Fragment>
                <div className="modal bsy-modal x-large show" id="collectionModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: "block"}}>
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-lg--x" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib ">Attach Pages</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={(e) => {this.props.hidePagelist(e)}}></button>
                            </div>
                            <div className="modal-body  modal-in-colored modal-body-heighted">
                                <div className="mang-lbl-block mang-lbl-block-mod-in">
                                    <div className="lbl-panel-search-block">
                                        <input data-target="#dropCollection" data-action="search" type="text"
                                               placeholder="Search pages..." className="lbl-search-box" onChange={(e)=> this.props.handleChange(e)} />
                                        <span className="ico-lib lbl-search-ico"></span>
                                    </div>
                                    <ul className="grid-list-switch res-modal">
                                        <li><a className="icon-list" data-switch="list-grid" data-target="1"></a>
                                        </li>
                                        <li><a className="icon-grid active" data-switch="list-grid"
                                               data-target="2"></a>
                                        </li>
                                    </ul>
                                    <div className="content-main-in" id="dropCollection">
                                        {Pages.map((item,index)=>
                                            <PageTile item={item} index={index} selectPage = {this.props.selectPage} selected combinedPagesIds={this.props.combinedPagesIds}  />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer  checklist-footer">
                                {this.props.combinedPages.length > 0 && <span id="collectionCount">{this.props.combinedPages.length} items selected</span>}
                                <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {this.props.hidePagelist(e)}}>Cancel
                                </button>
                                <button type="button" className="general-btn" id="fetchCollection" onClick={(e)=>this.props.attachSelectedPages(e)}>Attach</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )};
}
class AttachForms extends Component {

    render() {
        let Collections = this.props.Collections||[];
        return(
            <React.Fragment>
                <div className="modal bsy-modal x-large show" id="collectionModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: "block"}}>
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-lg--x" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib ">Attach Forms</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={(e) => {this.props.hideFormlist(e)}}></button>
                            </div>
                            <div className="modal-body  modal-in-colored modal-body-heighted">
                                <div className="mang-lbl-block mang-lbl-block-mod-in">
                                    <div className="lbl-panel-search-block">
                                        <input data-target="#dropCollection" data-action="search" type="text"
                                               placeholder="Search forms..." className="lbl-search-box" onChange={(e)=> this.props.handleFormChange(e)} />
                                        <span className="ico-lib lbl-search-ico"></span>
                                    </div>
                                    <ul className="grid-list-switch res-modal">
                                        <li><a className="icon-list" data-switch="list-grid" data-target="1"></a>
                                        </li>
                                        <li><a className="icon-grid active" data-switch="list-grid"
                                               data-target="2"></a>
                                        </li>
                                    </ul>
                                    <div className="content-main-in" id="dropCollection">
                                        {Collections.map((item,index)=>
                                            <FormTodoTile item={item} index={index} selectForm = {this.props.selectForm} selected combinedFormIds={this.props.combinedFormIds}  />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer  checklist-footer">
                                {this.props.combinedForms.length > 0 && <span id="collectionCount">{this.props.combinedForms.length} items selected</span>}
                                <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {this.props.hideFormlist(e)}}>Cancel
                                </button>
                                <button type="button" className="general-btn" id="fetchCollection" onClick={(e)=>this.props.attachSelectedForms(e)}>Attach</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )};
}
function ResourceTodoTile(props) {


    let selected = props.combinedResourceIds.includes(props.item.resource_id) && "selected" ;
    let item_cls  = 'document';
    if(props.item.res_type === '1') {
        item_cls  = 'image';
    }
    else if(props.item.res_type === '2') {
        item_cls  = 'video';
    }else if(props.item.res_type === '3') {
        item_cls  = 'document';
    }else if(props.item.res_type === '4') {
        item_cls  = 'audio';
    }else if(props.item.res_type === '5') {
        item_cls  = 'secured-video';
    }else if(props.item.res_type === '6') {
        item_cls  = 'video';
    }

    return(
        <div className="content-section  form-list-item " data-action="filter" title={props.item.title} onClick={ (e) => props.selectResource(props.item)}>
            <div  className={`content-click ${selected} `}>

                <div className="section-info-main">
                    <div className={`section-info ${item_cls}`}><h5>{props.item.title}</h5></div>
                </div>
            </div></div>
    );
}
function PageTile(props) {

    let cid = props.item.pageid;

    let selected = props.combinedPagesIds.includes(cid.toString()) ? "selected" : "";
    return(
        <div className="content-section  form-list-item " data-id={cid} data-action="filter" onClick={ (e) => props.selectPage(props.item)}>
            <div  className={`content-click ${selected} `}>

                <div className="section-info-main">
                    <div className="section-info"><h5>{props.item.pagename}</h5></div>
                </div>
            </div></div>
    );
}
function FormTodoTile(props) {

    let cid = props.item.cid;

    let selected = props.combinedFormIds.includes(cid.toString()) ? "selected" : "";

    //console.log(cid)


    return(
        <div className="content-section  form-list-item " data-id={cid} data-action="filter" onClick={ (e) => props.selectForm(props.item)}>
            <div  className={`content-click ${selected} `}>

                <div className="section-info-main">
                    <div className="section-info"><h5>{atob(props.item.title)}</h5></div>
                </div>
            </div></div>
    );
}
class TaskPreview extends Component {

    constructor(props) {
        super(props);
        this.getAttachmentPath = this.getAttachmentPath.bind(this);
    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(getFilePath(type)) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'video' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }
    render() {
        let taskData = this.props.taskData;
        let download=this.props.dwnldPermission;
        return(
            <React.Fragment>
                <div id={`dis-pop`} className={`cs-modal fade bsy-modal learn-task-mod without-footer in show `}>
                    <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="task-head-text">Task Details</h5>
                                <div className="modal-header-options">
                                    <button className="modal-option-button" onClick={this.props.closePreviewModal}><span className="icon-in icon-close-in"></span></button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="task-left">
                                    <div className="task-detail-wrapper switch-status-view task-all-done" tabIndex="1">
                                        <div className="task-detail-panel">
                                            <div className="task-details-header-block">
                                                <div className="task-detail-header-laeft">
                                                    <ul className="task-details-info-wrap">
                                                        {taskData.duedate !== "0000-00-00 00:00:00" &&
                                                        <li>
                                                            <p>DUE ON </p>
                                                            <h5>{DateFormatter(taskData.duedate, true, true).toUpperCase()}
                                                            </h5>
                                                        </li>
                                                        }
                                                        <li>
                                                            <p>PRIORITY</p>
                                                            <h5>{taskData.priority}</h5>
                                                        </li>
                                                        <li></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="task-detail-panel-content">
                                                <span className="ico-lib"></span>
                                                <div className="task-time-label">
                                                    {taskData.duedate !== "0000-00-00 00:00:00" &&
                                                    <h5><span className="task-date"> {DateFormatter(taskData.createdate)}</span>
                                                        <span className="task-time">{DateFormatter(taskData.createdate,true,false)}</span></h5>
                                                    }
                                                </div>
                                                <div className="task-detail-panel-content-area">
                                                    <div className="task-detail-panel-content-area-left">
                                                        <h5>{taskData.title}</h5>
                                                        <p>
                                                            <p>{taskData.description}</p>
                                                            <br/></p>
                                                    </div>
                                                </div>
                                            </div>
                                            {taskData.attach && taskData.attach.length > 0 &&
                                            <div className="task-detail-panel-attachment white-panel">
                                                <div className="attachment-header">
                                                    <div className="attachment-header-left">
                                                        <h5>Attachments <span
                                                            className="count">({taskData.attach && taskData.attach.length})</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className="attachment-list-wrapper">
                                                    {taskData.attach && taskData.attach.map((item, index) => {
                                                            let iconClass = CustomFunctions.getFileExtension(item.filename);
                                                            let contentPath = this.getAttachmentPath(item.filename);
                                                            var albumName;
                                                            if (isImage(item.filename)) {
                                                                albumName = 'images'
                                                            } else if (isVideo(item.filename)) {
                                                                albumName = 'videos'
                                                            } else {
                                                                albumName = 'documents'
                                                            }
                                                            return (
                                                                <li className="active" key={index}>
                                                                    <a className="attach-click"
                                                                       onClick={(e) => (albumName === "videos" && download === "yes" || albumName !== "videos") ? forceDownload(contentPath + item.filename, item.filetitle) : ""}
                                                                       data-toggle="tooltip"
                                                                       data-placement="bottom" data-original-title="">
                                                                <span className="attach-attach-thumb">
                                                                    {iconClass !== 'image' &&
                                                                    <div className={"format " + iconClass}>
                                                                        {iconClass}
                                                                    </div>
                                                                    }
                                                                    {iconClass === 'image' &&
                                                                    <img src={contentPath + item.filename}/>
                                                                    }
                                                                </span>
                                                                    </a>
                                                                    <div className={`attach-click-toggle extended-attatch`}>
                                                                        {item.filetitle &&
                                                                        <div className="file-info-previewer">
                                                                            <span className="file-info-name">{item.filetitle}</span>
                                                                        </div>
                                                                        }
                                                                        {previewModalFormat(item.filename) &&
                                                                        <>
                                                                            <div className="file-info-option">
                                                                                <button className="ico-lib-v2 preview-file"
                                                                                        title={item.filetitle}
                                                                                        onClick={(previewModalFormat(item.filename)) ? ((e) => this.props.preview(contentPath + item.filename, item.filetitle, albumName, download)) : ((e) => forceDownload(contentPath + item.filename, item.filetitle))}
                                                                                        data-toggle="tooltip">
                                                                                </button>

                                                                            </div>
                                                                        </>
                                                                        }
                                                                        {albumName === "videos" && download === "yes" &&
                                                                        <button
                                                                            onClick={(e) => forceDownload(contentPath + item.filename, item.filetitle)}
                                                                            className="ico-lib-v2 download-file">
                                                                        </button>
                                                                        }
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )};
}
class FormsPreview extends Component {

    render() {
        return(
            <React.Fragment>
                <div id={`dis-pop`} className={`cs-modal fade bsy-modal resource-preview-mod show `}>
                    <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="task-head-text">Forms</h5>
                                <div className="modal-header-options">
                                    <button className="modal-option-button" onClick={this.props.closePreviewModal}><span className="icon-in icon-close-in"></span></button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <iframe src={"/embed/form/"+btoa(this.props.formsId)}
                                        width="100%"
                                        height="100%"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )};
}
class CourseReport extends Component {

    constructor(props) {
        super(props);
        this.getAttachmentPath = this.getAttachmentPath.bind(this);
    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(getFilePath(type)) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'video' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }
    render() {
        let taskData = this.props.taskData;
        let download=this.props.dwnldPermission;
        return(
            <React.Fragment>
                <div id={`dis-pop`} className={`cs-modal fade bsy-modal learn-task-mod without-footer in show `}>
                    <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="task-head-text">Task Details</h5>
                                <div className="modal-header-options">
                                    <button className="modal-option-button" onClick={this.props.closeReportModal}><span className="icon-in icon-close-in"></span></button>
                                </div>
                            </div>
                            <div className="modal-body">
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )};
}
class Report extends Component {
    render() {
        let assignedUsers = this.props.assignedUsers||[];
        let assignedModuleStatusInfo = this.props.assignedModuleStatusInfo||[];
        let sectionList = this.props.sectionList||[];
        let courseInfo = this.props.courseInfo||[];
        return(
            <>

            <div className="course-index-block">
                {assignedUsers.length === 0 &&
                <div className='absolute-holder'>
                    <NoData
                        msg={`No users has been added yet`}
                    />
                </div>
                }
                {(this.props.assignedModuleStatusInfoLoader.loading)&&
                <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                }
                {assignedUsers.length > 0 &&
                <Accordion className="couser-index height-120" defaultActiveKey="0">
                    {assignedUsers.map((user, index) =>
                        <React.Fragment key={index}>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={index+1} onClick={(e)=>this.props.showReportModal(e,user.assignee_id,index)}>
                                <div className="course-index-user">
                                    <div className="course-user">
                                        <figure>
                                            <img
                                                src={USRIMG_PATH+user.assignee_img}/>
                                        </figure>
                                        <div className="course-user-details">
                                            <h5>{user.assignee}</h5>
                                            <p>{user.email}</p>
                                            {user.assigned_date !== "" &&<p>Assigned Date - {moment(user.assigned_date).format('DD MMMM YYYY')}</p>}
                                            {user.startdate !== "" &&<p>Start Date - {moment(user.startdate).format('DD MMMM YYYY')}</p>}
                                        </div>
                                    </div>
                                    <div className="course-selected">
                                        <h5>{user.status === "completed" ? 'Completed' :'Not Completed'}</h5>
                                        {user.status === "completed" &&
                                            <p>Completed On - {moment(user.completeddate).format('DD MMMM YYYY')}</p>                                        
                                        }
                                    </div>
                                    
                                </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index+1}>
                                <Card.Body>
                                    <div className="course-index-table">
                                        <Table responsive="sm" className="learn-table">
                                            <thead>
                                            <tr>
                                                <th>Module</th>
                                                <th>Completion Date</th>
                                                <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {sectionList.map((item,sIndex)=>{
                                                let modules = item.modules.filter(list=>list.publishstatus === "yes") || [];
                                                return(
                                                    <React.Fragment key={sIndex}>
                                                    {item.modules.length > 0 && modules.length > 0 &&
                                                        <tr>
                                                        <td colSpan="3" className="font-bold" style={{background: "var(--col-active);color: var(--default-grey)"}}>{item.sectionname}</td>
                                                        </tr>
                                                    }
                                                    {item.modules.filter(list=>list.publishstatus === "yes").map((list,index)=>
                                                        {
                                                            let statusinfo = assignedModuleStatusInfo.filter(i=>i.module_id === list.moduleid )[0] || {}
                                                            return(
                                                                <tr key={"rep"+index}>
                                                                    <td>
                                                                        {list.module_title !== "" ? list.module_title : list.title}
                                                                    </td>
                                                                    <td>{statusinfo.attendeddate ? moment(statusinfo.attendeddate).format('DD MMM YYYY') : "Nil"}</td>
                                                                    <td>
                                                                        <div className={`status-tag ${statusinfo.status === "completed" ? 'opened':''} `}>{statusinfo.status === "completed" ? 'Completed' :'Not Completed'}</div></td>

                                                                </tr>
                                                            )}

                                                        )}
                                                    </React.Fragment>
                                                )}
                                            )}

                                            {/*        <div className="status-tag opened">Completed</div>*/}
                                            {/*        <div className="status-tag inprogress">Processing</div>*/}

                                            </tbody>
                                        </Table>
                                    </div>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        </React.Fragment>
                        )}
                </Accordion>
                }
                {/*{assignedUsers.length < courseInfo.assigneecount &&*/}
                {/*<div className="loadmore-block">*/}
                {/*    <button className="loadmore-button ico-lib"*/}
                {/*            onClick={() => this.props.loadMoreAssignedUsers()}>Load more*/}
                {/*    </button>*/}
                {/*</div>*/}
                {/*}*/}
            </div>

            </>
        )};
}
class AssessmentPreview extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        let assessmentData = this.props.assessmentData||{}
        console.log(assessmentData)
        return(
            <React.Fragment>
                <div id={`dis-pop`} className={`cs-modal fade bsy-modal height-520 learn-task-mod without-footer in show `}>
                    <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="task-head-text">Assessment</h5>
                                <div className="modal-header-options">
                                    <button className="modal-option-button" onClick={this.props.closePreviewModal}><span className="icon-in icon-close-in"></span></button>
                                </div>
                            </div>
                            <div className="modal-body" style={{padding: '20px'}}>
                                <div className="assessment-preview">
                                    <h5 className="font-bold">Passing Score  : {assessmentData.passingscore}</h5>
                                    {assessmentData.assessmentcontent && assessmentData.assessmentcontent.length > 0  && assessmentData.assessmentcontent.map((item,index)=>
                                        <React.Fragment key={index}>
                                            <div className="each-assessment-preview">
                                                <h5>Question : {item.Question}</h5>
                                                <p>Options : </p>
                                                <ul>
                                                {item.Option.map(list=>
                                                    <li>{list}</li>
                                                )}
                                                </ul>
                                                <p className="correct-answer">Correct Option :  {item.Correct.map((list,cIndex)=>
                                                    <span>{list} {(cIndex < item.Correct.length - 1) ? " , " : ""}</span>
                                                )}</p>
                                            </div>
                                        </React.Fragment>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )};
}
class RichTextPreview extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        let richTextPreviewData = this.props.richTextPreviewData||{}
        return(
            <React.Fragment>
                <div id={`dis-pop`} className={`cs-modal fade bsy-modal height-520 learn-task-mod without-footer in show `}>
                    <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="task-head-text">Content</h5>
                                <div className="modal-header-options">
                                    <button className="modal-option-button" onClick={this.props.closePreviewModal}><span className="icon-in icon-close-in"></span></button>
                                </div>
                            </div>
                            <div className="modal-body" style={{padding: '20px'}}>
                                <div className="rich-text-preview">
                                    {/*<h5 className="font-bold">Passing Score  : {UmljaCB0ZXh0.passingscore}</h5>*/}
                                    <React.Fragment>
                                        {/*<div className="each-assessment-preview">*/}
                                            <div dangerouslySetInnerHTML={{__html:decodeURIComponent(escape(atob(richTextPreviewData)))}}/>
                                    </React.Fragment>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )};
}
export {SectionHeaderOptions,AttachResources,AttachPages,ResourceTodoTile,PageTile,LearnHeader,TaskPreview,CourseReport,AttachForms,FormTodoTile,FormsPreview,Report,AssessmentPreview,RichTextPreview};