import React, {Component} from 'react';
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Area,
    Legend,
    AreaChart,
    ResponsiveContainer,
    Line

} from 'recharts';

const CustomTooltip = ({active, payload, label, unit}) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                {payload && payload.map(item =>
                    <p className="intro"
                       style={{color: item.color}}>{`${item.name} : ${new Intl.NumberFormat('en-IN', {maximumFractionDigits: 2}).format(item.value)} ${unit ? unit : ''}`}</p>
                )}
            </div>
        );
    }

    return null;
};

function AreaBarChart(props) {
    //console.log(props)
    let xData = props.containerData.tabledata.slice(0, 1);
    let barData = props.containerData.tabledata.slice(1, 2);
    let gradData = props.containerData.tabledata.slice(2, 3);
    let layout = props.containerData.layout ? props.containerData.layout : 'horizontal';
    let xwidth = props.containerData.xwidth && parseInt(props.containerData.xwidth);
    let xheight = props.containerData.xheight && parseInt(props.containerData.xheight);
    let yheight = props.containerData.yheight && parseInt(props.containerData.yheight);
    let ywidth = props.containerData.ywidth && parseInt(props.containerData.ywidth);
    let graphHeight = props.containerData.graphHeight ? parseInt(props.containerData.graphHeight) : 200;
    let xAngle = props.containerData.xAngle ? parseInt(props.containerData.xAngle) : 0;
    let yAngle = props.containerData.yAngle ? parseInt(props.containerData.yAngle) : 0;
    let hidearea = props.containerData.hidearea ? props.containerData.hidearea : false;
    let enablegradient = props.containerData.enablegradient ? props.containerData.enablegradient : false;
    let transparancy = props.containerData.transparancy ? props.containerData.transparancy : 0;
    let areaOpacity = 1 - parseFloat(transparancy);
//console.log(gradData)
    return (
        <React.Fragment>
            <ResponsiveContainer width={props.containerData.legend ? "95%" : "100%"} height={graphHeight}>
                <ComposedChart
                    data={props.containerData.data}>
                    {props.containerData.legend &&
                    <Legend iconType={`circle`} iconSize={8} height={35} margin={{left: 10}} layout='vertical'
                            verticalAlign={`middle`} align={`right`}/>
                    }
                    <CartesianGrid vertical={false} stroke="rgba(240, 240, 240, 0.80)"/>
                    {xData.map((list, index) =>
                        <XAxis dataKey={list.label} padding={{left: 10, right: 0}} axisLine={false} tickLine={false}
                               angle={-xAngle} fontSize={12}
                               interval={props.containerData.alldata ? 0 : 'preserveStartEnd'}
                               textAnchor={xAngle > 0 ? 'end' : 'middle'}/>
                    )}
                    <YAxis type={'number'} hide={props.containerData.enableyaxis ? false : true}
                           padding={{left: 0, right: 0}}/>
                    <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip unit={props.containerData.unit}/>}/>
                    {barData.map((list, index) =>
                        <Bar dataKey={list.label} fill={list.color} radius={[5, 5, 0, 0]} maxBarSize={20}/>
                    )}

                    {enablegradient &&
                    <defs>
                        {gradData.map((list, index) =>
                            <linearGradient id={`colorUv${props.containerData.cid + index}`} x1="0" y1="0" x2="0"
                                            y2="1">
                                <stop offset="5%" stopColor={list.color} stopOpacity={0.5}/>
                                <stop offset="95%" stopColor={list.color} stopOpacity={0}/>
                            </linearGradient>
                        )}
                    </defs>
                    }


                    {!hidearea && gradData.map((list, index) =>
                        <Area type="monotone" dataKey={list.label} stroke={list.color}
                              fill={enablegradient ? `url(#colorUv${props.containerData.cid + index})` : list.color}
                              fillOpacity={areaOpacity} dot={false}/>
                    )}
                    {gradData.map((list, index) =>
                        <Line type="monotone" dataKey={list.label} stroke={list.color} dot={false}/>
                    )}


                </ComposedChart>

            </ResponsiveContainer>
        </React.Fragment>
    );
}

export default AreaBarChart;