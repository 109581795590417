import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import DashboardUser from './DashboardUser'


class ListDashboardtask extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var list = this.props.dataList;
        var pendingtask=atob(list.subject);
        var priority=list.priority;
        var status = priority.charAt(0).toUpperCase();
         return (
             <div className="ind-task-list">
                 <a onClick={()=>this.props.history.push('/task/details/'+btoa(list.masterid)+'/'+btoa('to-me'))} className="ind-task-list-click">
                     <div className="ind-task-list-left">
                         <div className="select-chk-block">
                             <label className="control control-checkbox">
                                 <input type="checkbox" className="inp-chk"/>
                                 <div className="control-indicator"></div>
                             </label>
                         </div>
                         <div className="task-priority-block">
                             <div className="progress--circle progress--50"><span
                                 className="toper">{status}</span></div>
                         </div>
                         <DashboardUser list={list}/>
                     </div>
                     <div className="ind-task-list-center">
                         <span className="task-text">{(pendingtask==='' ? atob(list.Title):pendingtask)}</span>
                     </div>
                 </a>
             </div>
        );
    }
}


export default  withRouter(connect(null, {

})(ListDashboardtask));



