import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import LoaderSvg from "./LoaderSVG";

class FinancialPerfomance extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    render() {

        return (
            <div className="ibt-split-table">
                <div className="blue-lister">
                    <div className="grid-header">
                        <h5>Return on Assets</h5>
                    </div>
                    <div className="blue-list-body">
                        <ul className="graph-tag">
                            <li className="green-circle">ROA</li>
                            <li className="blue-circle">Targeted ROA</li>
                        </ul>
                        <div className="graph-wrap">
                            <img src="/assets/images/graph-1.png" />
                        </div>
                    </div>
                </div>
                <div className="blue-lister">
                    <div className="grid-header">
                        <h5>Return on Equity</h5>
                    </div>
                    <div className="blue-list-body">
                        <ul className="graph-tag">
                            <li className="green-circle">ROA</li>
                            <li className="blue-circle">Targeted ROA</li>
                        </ul>
                        <div className="graph-wrap">
                            <img src="/assets/images/graph-2.png"/>
                        </div>
                    </div>
                </div>
                <div className="blue-lister">
                    <div className="grid-header">
                        <h5>Working Capital Ratio</h5>
                    </div>
                    <div className="blue-list-body">
                        <ul className="graph-tag">
                            <li className="ash-circle">Ratio</li>
                        </ul>
                        <div className="graph-wrap">
                            <img src="/assets/images/graph-3.png"/>
                        </div>
                    </div>
                </div>
                <div className="blue-lister">
                    <div className="grid-header">
                        <h5>Debt Equity Ratio</h5>
                    </div>
                    <div className="blue-list-body">
                        <ul className="graph-tag">
                            <li className="orange-circle">Ratio</li>
                        </ul>
                        <div className="graph-wrap">
                            <img src="/assets/images/graph-4.png"/>
                        </div>
                    </div>
                </div>
            </div>

        );
    }


}

export default FinancialPerfomance;