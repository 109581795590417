import {getProfile} from "../../../utilities/AuthService";
import moment from "moment";
import {OverlayTrigger} from "react-bootstrap";
import React, {Component} from "react";
import OutsideAlerter from "../../views/OutsideAlerter";

class WhMsgBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            masterEntityId: '',
            channelId: '',
            chatSelection : false,
            showMsgActionStatus : false
        }
    }

    showMsgActionMenu = () => {
        this.props.hideAllActions();
        this.setState({showMsgActionStatus : true})
    }
    hideMsgActionMenu = () => {
        this.setState({showMsgActionStatus : false})
    }
    selectForTask = (chat_id) => {
        this.setState({chatSelection : true, showMsgActionStatus : false});
        this.props.selectForTask(chat_id)
    }

    deleteChat = (chat_id) => {
        this.setState({showMsgActionStatus : false})
        this.props.showConfirmMsgDelete(chat_id)

    }

    render() {

        let index = this.props.index;
        let chat =  this.props.chat;
        let msg_ids = this.props.msg_ids?this.props.msg_ids : [];
        let chatList = this.props.chatList ||[];
        //console.log(chatList)

        let reference = this.props.reference;

        //Reply Message Detaills;
        let thread_id = chat.replyto;
        let thread = {};

        if(thread_id !== '' && thread_id !== '0') {
            let threadArr = chatList.filter(item  => item.chat_id === thread_id);
            if(threadArr.length > 0) {
                thread = threadArr[0];
            }
        }

        let taskCreated = false;
        let taskList = this.props.taskList || [];
        let taskChats = [];
        let chatTasks = [];

        if(taskList.length > 0) {
            taskList.map(task => {
                if(task.whatsappcontent && task.whatsappcontent !== ''){
                   let whcontent = JSON.parse(task.whatsappcontent);
                   taskChats = taskChats.concat(whcontent);
                   let contArr = whcontent.filter(cont => cont.chat_id === chat.chat_id);
                   if(contArr.length > 0) {
                       chatTasks.push(task);
                   }
                }
            }, taskChats, chatTasks );

            if(taskChats.length > 0) {
                let taskChatArr = taskChats.filter(item => item.chat_id === chat.chat_id);
                if(taskChatArr.length >0) {
                    taskCreated = true;
                }
            }
        }
        return (
            <div id={`chtat-${chat.chat_id}`}
                className={`wa-ind-com-each ${msg_ids.includes(chat.chat_id) ? 'selected' : ''} ${(chat.bsynuser && parseInt(getProfile().id) === parseInt(chat.sender)) ? 'message-out' : ''}  ${this.props.lastMsg ? 'last-msg-wh' : ''}`}
                key={`chtat-${index}`} ref={`${this.props.lastMsg ? 'lastMsgWh' : ''}`} tabIndex = {0}>

                <div className="select-chk-block">
                    <label className="control control-checkbox">
                        <input type="checkbox" name="chat_id" className="inp-chk" value={chat.chat_id} checked={msg_ids.includes(chat.chat_id)}
                                onChange={this.props.handleCheckboxChange}/>
                        <div className="control-indicator"></div>
                    </label>
                </div>
                <div className="chat-selection-container">
                    <div className="wa-ind-com-list task-linked">
                        <div className="wa-chat-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 13"
                                 width="8" height="13">
                                <path opacity=".13" fill="#0000000"
                                      d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"></path>
                                <path fill="currentColor"
                                      d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"></path>
                            </svg>
                        </div>

                        {chat.replyto && chat.replyto !== '0' && reference === "whatsapp" &&

                        <div className="wa-reply-box">
                            <div className="wa-each-chat-list-header">
                                <h5>{thread.sender_name}</h5>
                            </div>
                            <div className="wa-each-chat-list-content">
                                <p>{thread.message}</p>
                            </div>
                        </div>
                        }

                        <div className="wa-each-chat-list-header">
                            {parseInt(getProfile().id) !== parseInt(chat.sender) &&
                            <h5>{chat.sender_name}</h5>
                            }
                        </div>
                        <div className="wa-each-chat-list-content">
                            <p dangerouslySetInnerHTML={{__html:(this.props.createdfrom === "singlemainchat" || this.props.createdfrom === "externalchat" )?atob(chat.message):chat.message}}/>
                            {chatTasks.length > 0 &&
                            <ul className="linked-tasks">
                                {chatTasks.map(task =>
                                    <li><span className="task-text-header" dangerouslySetInnerHTML={{__html:atob(task.subject)}}/> <span>{task.status}</span></li>
                                )}
                            </ul>
                            }
                        </div>
                        <div className="wa-each-chat-list-info">
                            <p>{moment(chat.createdate).utc(chat.createdate).local().format('LT')}</p>
                        </div>
                        {reference === 'whatsapp' &&
                            <React.Fragment>
                                <div className="wa-aside-option-block">
                                    <button
                                        className={`ico-lib-v1 wa-more-option-tog ${(!this.state.showMsgActionStatus && this.props.hideOptionMenu) ? '' : 'active'}`}
                                        onClick={this.showMsgActionMenu}
                                        title="Menu"></button>
                                    {this.state.showMsgActionStatus &&
                                    <OutsideAlerter
                                        status={this.state.showMsgActionStatus}
                                        Triggered={() => this.hideMsgActionMenu()}
                                    >
                                        <ul className={`wa-aside-option-menu ${(!this.state.showMsgActionStatus && this.props.hideOptionMenu) ? 'hide' : ''}`}>
                                            {/* <li>
                                        <nav>Reply Message</nav>
                                    </li>*/}
                                            <li onClick={() => this.selectForTask(chat.chat_id)}>
                                                <nav>Assign as Task</nav>
                                            </li>
                                            {/*<li>
                                        <nav>Forward Message</nav>
                                    </li>
                                    <li>
                                        <nav>Pin Message</nav>
                                    </li>*/}
                                            <li>
                                                <nav onClick={() => this.deleteChat(chat.chat_id)}>Delete Message</nav>
                                            </li>
                                        </ul>
                                    </OutsideAlerter>
                                    }

                                </div>
                                {/*{taskCreated &&
                                <div className="task-link">
                                    <nav className="ico-lib link-task"></nav>
                                </div>
                                }*/}
                            </React.Fragment>


                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default  WhMsgBox;
