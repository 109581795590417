import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../../actions/ToDo"
import {withRouter} from 'react-router';
import ListCard from "../../views/ListCard"
import {getProfile} from "../../../utilities/AuthService";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import FilterBlock from "../FilterBlock";
import FilerRightBlock from "../FilterRightBlock";
import Modal from "../../views/Modal";
import AddToDoTask from "../AddToDoTask";
import * as projectActions from "../../../actions/Project";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import AlertBar from "./AlertBar";



class ScheduleTasks extends Component {

    constructor(props) {
        super(props);
        this.gettaskList=this.gettaskList.bind(this);
        this.modalOpen=this.modalOpen.bind(this);
        this.state={
            open:false,
            projectList:[],
            limit : 0,
            loadingState: false,
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            scheduledata: [],
            offset: 250
        }
    }



    componentDidMount() {

        this.props.mainClick('task','/task/schedule');
        if(this.props.todoListing.ToSchedule.data.length ===0) {
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
            }
            else {
                this.gettaskList("scheduled",'All');
            }
        }
        else{
            if(this.props.searchData.task.active) {
                this.searchFilterActive()
                this.setState({ scheduledata: this.props.todoListing.ToSchedule.data})
            }
            else {
                this.setState({ scheduledata: this.props.todoListing.ToSchedule.data})

            }
        }

        this.props.userFilter("to-draft",getProfile().id)
        //this.props.toFollowUp('by-me','All',getProfile().id,'','LIST_FOLLOW_UP_DATA')
        const container = document.querySelector('.main-panel-holder');
        container.addEventListener('scroll', this.listenToScroll)

    }

    searchFilterActive(){
        var status = "All"
        if(getUrlSegment(2)==='todo'){
            var category='to-me'
        }
        if(getUrlSegment(2)==='to-follow-up'){
            var category='by-me'
        }
        if(getUrlSegment(2)==='to-oversee'){
            var category='manage-task'
        }
        if(getUrlSegment(2)==='to-know'){
            var category='to-know'
        }
        if(getUrlSegment(2)==='to-comment'){
            var category='Comments'
        }
        if(getUrlSegment(2)==='to-verify'){
            var category='by-me'
            status = "Done"
        }
        if(getUrlSegment(2)==='drafts'){
            var category='to-draft'
            status = "Done"
        }
        if(getUrlSegment(2)==='schedule'){
            var category='scheduled'
        }

        var data = {
            category : category,
            status :this.props.searchData.task.status,
            uid : getProfile().id,
            bydue : '',
            priority :this.props.searchData.task.priority,
            project :this.props.searchData.task.project,
            asigntype : '',
            username :'',
            managetask : 'mytask',
            srchval:this.props.searchData.task.srchval,
            limit : 0,
            offset  : this.state.offset,
            systype : getProfile().usertype,
        }

        this.props.searchType('SEARCH_TASK_DATA',this.props.searchData.task.srchval,"srchval",data)

    }




    componentWillUnmount() {
        const container = document.querySelector('.main-panel-holder');
        container.removeEventListener('scroll', this.listenToScroll)
    }
    listenToScroll = () => {
        const container = document.querySelector('.main-panel-holder')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - (offsetHeight + 1);
        if (contentHeight <= scrollTop) {
            if(this.props.sidebarmenudata.toschedule >= this.props.todoListing.ToSchedule.data.length+1) {
                // this.setState({loading : 1})
                var data = {
                    category : 'scheduled',
                    status : "All" ,
                    uid : getProfile().id,
                    bydue : '',
                    priority : this.props.taskFilters.taskPriorityFilter,
                    project :this.props.taskFilters.taskProjectFilter,
                    asigntype : 'All',
                    managetask : 'mytask',
                    limit: this.props.todoListing.ToSchedule.data.length,
                    offset  : this.state.offset,
                    systype : getProfile().usertype,
                    actionType : 'LOADMORE_SCHEDULE_DATA'
                }
                this.props.toFollowUp(data);
            }
        }
    }
    componentWillUpdate(prevProps,nextProps) {
        if(prevProps.searchData !== this.props.searchData){
            // alert(1)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta =this;

        if(prevProps.taskFilters  !== this.props.taskFilters){
            this.setState({  limit : 0 },
                function() {
                    this.gettaskList("scheduled",'All');
                })
        }
        if(prevProps.saveTodo.todoCreated !== this.props.saveTodo.todoCreated) {
            this.modalOpen();
        }

        if(prevProps.taskSelect.tasksArchived  !== this.props.taskSelect.tasksArchived ){
            setTimeout(function () {
                beta.gettaskList("scheduled",'All');
            }, 1000)
        }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            if(this.props.HistoryPushData.savemode === 'scheduled') {
                var data = {category : 'scheduled', status : 'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', username : this.props.taskFilters.taskAssignedFilter,limit: 0, offset  : this.state.offset, systype :  getProfile().usertype,  actionType : 'LIST_SCHEDULE_DATA' }
                //  this.props.toFollowUp(data);
                //  this.props.history.push("/task/to-follow-up")
                this.setState({open:false})
            }
            if(this.props.HistoryPushData.savemode === 'to-me') {
                var data = {category : 'to-me', status :'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit : 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TODO_DATA' }
                // this.props.todo(data);
                //this.props.history.push("/task/todo")
                this.setState({open:false})
            }
            this.setState({open:false})
        }

        if(prevProps.todoListing  !== this.props.todoListing){
            this.setState({scheduledata: this.props.todoListing.ToSchedule.data});
        }
        /*if(this.props.taskSelect.dateUpdated !== prevProps.taskSelect.dateUpdated) {
            var data = {category : 'to-draft', status : 'All' , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', username : this.props.taskFilters.taskAssignedFilter,limit: 0, offset  : this.state.offset, systype :  getProfile().usertype,  actionType : 'LIST_FOLLOW_UP_DATA' }
            this.props.toFollowUp(data);
            this.props.history.push("/task/to-follow-up")
        }*/
        if(this.props.taskSelect.dateUpdated !== prevProps.taskSelect.dateUpdated) {

            let tasklist = [...this.state.followupdata];
            tasklist.filter(item =>{
                item.duedate = (item.masterid === this.props.taskSelect.taskid) ? this.props.taskSelect.utcduedate : item.duedate;
                return item;
            });
            this.setState({scheduledata : tasklist})
        }
    }

    modalOpen(){
        //////////////console.log(this.props.projects.projectData);//////////////console.log(this.props.projects.tagData);
        this.setState({open:!this.state.open,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData});
        //////////////console.log(this.props.projects.tagData);
    }

    gettaskList(cat,status){

        var data = {category : cat, status : status , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', username : this.props.taskFilters.taskAssignedFilter,limit: 0, offset  : this.state.offset, systype :  getProfile().usertype,  actionType : 'LIST_SCHEDULE_DATA' }
        this.props.scheduledTasks(data);
    }

    selectTasks(id) {
        this.props.selectTasksToMove(id);
    }
    render() {
        //////////////console.log(this.props.todoListing.ToFollowUp.data)
        let ScheduleTasks=this.props.searchData.task.active? this.props.searchData.data:  this.state.scheduledata

        //   let ToFollowUp=this.state.followupdata.filter(item => (item.subject===''?atob(item.Title):atob(item.subject)).toLowerCase().search(this.props.searchData.task.toLowerCase()) >= 0)
        // let ToFollowUp=this.props.todoListing.ToFollowUp.data.filter(item => (item.subject===''?atob(item.Title):atob(item.subject)).toLowerCase().search(this.props.searchData.task.toLowerCase()) >= 0)
        return (



            <>
                <div className='filter-all-wrapper task-home'>
                   {/* <AlertBar
                        history={this.props.history}

                    />*/}
                    <div className='filter-holder'>
                        {this.props.taskSelect.taskSelection === 'no' &&
                        <FilterBlock/>
                        }
                        {/*{this.props.taskSelect.taskSelection === 'no' &&*/}
                        {/*< Topbar gettaskList={this.gettaskList}  topbardata={this.props.topbardata} menuClickData={this.props.menuClickData.subMenu} />*/}
                        {/*}*/}
                        <FilerRightBlock taskCount ={this.state.scheduledata.length} />
                    </div>
                </div>
                {this.props.todoListing.loading &&
                <span className="Loader-holder" style={{'background':'transparent'}}>
                            <LoaderSvg/>
                        </span>
                }
                <div className={this.props.todoListing.loading ? 'main-panel-holder with-loader has-filter' : 'main-panel-holder has-filter'} ref="iScroll">
                    {this.props.todoListing.loading &&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                    }

                    {ScheduleTasks.length === 0 &&
                    <div className='absolute-holder'>
                        <NoData

                            msg={`No Scheduled Tasks`}

                        />
                    </div>
                    }

                    {ScheduleTasks.map((list,index) =>
                        <React.Fragment key={'schedule'+index}>
                            {/*{index === 0 &&
                                    <h4 className={'main-panel-head'}>Unread</h4>
                                    }*/}
                            <ListCard
                                key={'schedule-list-card'+index}
                                index={index}
                                sectionTitle={''}
                                list={list}
                                history={this.props.history}
                                mode='scheduled'
                                taskSelection = {this.props.taskSelect.taskSelection}
                                selectTasks = {this.selectTasks.bind(this)}
                                allChecked = {this.props.taskSelect.allChecked}
                                id={list.masterid}
                                dateupdatetask={this.props.dateupdatetask}
                                tasksource={list.createdfrom}
                                poolenabled={list.poolenabled}
                            />
                        </React.Fragment>
                    )}

                    {/*{Drafts.filter(list => list.readstatus === 'read').map((list,index) =>
                                <React.Fragment key={"to_follow_up_"+index}>
                                    {index === 0 &&
                                    <h4 className={'main-panel-head'}>Read</h4>
                                    }
                                <ListCard
                                    key={'tofollow'+index}
                                    index={index}
                                    sectionTitle={`Read`}
                                    list={list}
                                    history={this.props.history}
                                    mode='to-draft'
                                    taskSelection = {this.props.taskSelect.taskSelection}
                                    selectTasks = {this.selectTasks.bind(this)}
                                    allChecked = {this.props.taskSelect.allChecked}
                                    id={list.masterid}
                                    dateupdatetask={this.props.dateupdatetask}
                                    tasksource={list.createdfrom}
                                    poolenabled={list.poolenabled}
                                />
                                </React.Fragment>
                            )}*/}
                </div>
                {this.state.open &&
                <Modal title={'Create New Task'} Close ={this.modalOpen.bind(this)}>
                    <AddToDoTask />
                </Modal>
                }
                <div className={'float-button-box'} onClick={this.modalOpen}>
                    <span className={'icon-in icon-plus'}></span>
                </div>
            </>




        );
    }
}



const mapStateToProps = state => ({

    todoListing:state.TaskReducer.listtoDo,
    taskFilters:state.TaskReducer.filterTasks,
    taskSelect:state.TaskReducer.getTaskSelection,
    menuClickData:state.CommonReducer.menuClick,
    searchData:state.CommonReducer.searchparamdata,
    topbardata:state.TaskReducer.TofollowupTopbarMenudata,
    listtags:state.TaskReducer.ListAllTags,
    saveTodo : state.TaskReducer.saveTodo,
    projects:state.TaskReducer.GetProjectData,
    sidebarmenudata: state.TaskReducer.SidebarMenudata,
    HistoryPushData:state.TaskReducer.HistoryPushData,

});
export default  withRouter(connect(mapStateToProps, {
    todo:taskActions.listtask,
    scheduledTasks:taskActions.listtask,
    mainClick:rootActions.commonActions.MenuClick,
    selectTasksToMove : taskActions.selectTasksToMove,
    listTags:projectActions.listalltags,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    searchType: rootActions.commonActions.searchType,
    userFilter:taskActions.userFilter,
    dateupdatetask: taskActions.dateupdatetask,
})(ScheduleTasks));


