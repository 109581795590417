import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as usersActions from "../../../actions/Users";
import * as projectActions from "../../../actions/Project";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import moment from "moment";
import LoaderSvg from "../../views/LoaderSVG";


class AddNewNewsMedia extends Component {

    constructor(props) {
        super(props);

        this.state={
            name:'',
            description:'',
            embedcode:'',
            newsid:0,
            type:'',
            loading:0
        };
        this.MediaInputChange = this.MediaInputChange.bind(this);
        this.createNewsMedia = this.createNewsMedia.bind(this);
    }



    componentDidMount() {


    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.GetNewsMediaInfo.newsmediainfo !== this.props.GetNewsMediaInfo.newsmediainfo){
            let newsData = this.props.GetNewsMediaInfo.newsmediainfo.content||[];
            if(newsData){
                this.setState({
                    newsid:newsData[0].Id,
                    name:newsData[0].title,
                    description:decodeURIComponent(escape(atob(newsData[0].description))),
                    embedcode:newsData[0].url ,
                    type :newsData[0].urltype,


                })
            }
        }
    }

    MediaInputChange(e){
        this.setState({[e.target.name]:e.target.value})
        if (e.target.name === "type") {

            if (e.target.value !== '' || e.target.value !== 'select') {
                e.target.classList.remove('val-error');
            }
        } else {
            if (e.target.value !== '') {
                e.target.classList.remove('val-error');
            }
        }
    }
    createNewsMedia(e){
        e.preventDefault();
        var projectid = (atob(getUrlSegment(4)));
        let formInValid = 0;
        var name = this.state.name;
        var description = this.state.description;
        var url = this.state.embedcode;
        var type = this.state.type;
        var newsid = (this.state.newsid === 0 ? moment().valueOf() : this.state.newsid);
        var mode = (this.state.newsid === 0 ? 'save' : 'update');

        if (this.state.name.trim() === '') {
            formInValid++;
            document.getElementsByName('name')[0].classList.add('val-error');
        }

        if (this.state.description.trim() === '') {
            formInValid++;
            document.getElementsByName('description')[0].classList.add('val-error');
        }

        if (this.state.embedcode.trim() === '') {
            formInValid++;
            document.getElementsByName('embedcode')[0].classList.add('val-error');
        }
        if (this.state.type === 'select' || this.state.type === '') {
            formInValid++;
            document.getElementsByName('type')[0].classList.add('val-error');
        }
        if(formInValid === 0) {
            this.setState({loading: 1});
            let params={
                "_id": newsid,
                "projectid": projectid,
                "url": url,
                "type": type,
                "title": name,
                "description": description,
                "mode": mode,
            }
            console.log(params)
        this.props.saveNewsMedia(params);
        }
    }

    render() {
        return (
            <div className="task-content-block">
                {(this.state.loading === 1 || this.props.GetNewsMediaInfo.loading)&&
                <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                }
                <div className="persitant-top with-pad">
                    <div className="task-input-block">
                        <input type="text" id="name" name="name" className="task-input-box--new" defaultValue= {this.state.name} placeholder="Title" onChange={this.MediaInputChange} />
                    </div>
                    <div className="task-input-block text-input">
                            <textarea className="task-textarea--new" id="description" name="description"
                                      placeholder="Description" value = {this.state.description} onChange={this.MediaInputChange}></textarea>
                    </div>
                    <div className="task-input-block text-input">
                            <textarea className="task-textarea--new" id="url" name="embedcode"
                                      placeholder="Embed Code" value = {this.state.embedcode} onChange={(e)=>this.MediaInputChange(e)}></textarea>
                    </div>
                    <div className="split-drop-box">
                        <div className="select-box eq-width">
                            <select id="type" name="type" value={this.state.type} onChange={(e)=>this.MediaInputChange(e)}>
                                <option value="select">Select Type</option>
                                <option value="facebook">Facebook</option>
                                <option value="twitter">Twitter</option>
                                <option value="youtube">Youtube</option>
                                <option value="instagram">Instagram</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="persitant-footer">
                    <div className="group-row">

                    </div>
                    <button data-masterid="0" data-mode="save" type="button" className="general-btn"
                            id="createnewsmedia" onClick={(e)=>this.createNewsMedia(e)}>{this.state.newsid ==0 ? 'Create' : 'Update'}
                    </button>
                </div>
            </div>


        );
    }
}


const mapStateToProps = state => ({
    listUsers:state.UsersReducer.listUsersdata,
    GetNewsMediaInfo:state.ProjectReducer.GetNewsMediaInfo
});
export default  withRouter(connect(mapStateToProps, {
    users:usersActions.listTodoUsers,
    saveNewsMedia:projectActions.saveNewsMedia,
})(AddNewNewsMedia));



