import React from 'react';
import { Draggable, state } from 'react-page-maker';
import {withRouter} from 'react-router';
import {connect} from "react-redux";

import {FormGroup} from "react-bootstrap";
const GoogleStaticMap = React.lazy(() =>  import('../../../../ceshareview/GoogleStaticMap'))

class GoogleMap extends React.Component {
  state = {
    showColorPicker: false,
    longitude: '',
    latitude:""
  };

  handleChangeComplete = (e) => {

    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });

    });
  };
  // onBlur(){
  //   this.props.dragStatus(false)
  // }
  // onClick(){
  //   this.props.dragStatus(true)
  // }
  componentDidMount() {
  //  this.setState({payload: this.props.payload})
  }


  render() {
    let getFormdetails=this.props.getFormdetails
    let showlistField=[]

    if(this.props.showFormModalData.showformdata.length !==0) {

      for (let i = 0; i < this.props.showFormModalData.showformdata.pages.length; i++) {
        (this.props.showFormModalData.showformdata.pages[i].components.filter(list=> list.visibility !=="hidden")).map(list=>{
          showlistField.push(list)

        })
      }
    }

    const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, name, payload
    } = this.props;

    const longitude =  this.state.longitude ||
        payload && payload.longitude || '';
    const latitude =  this.state.latitude ||
        payload && payload.latitude || '';


    if (showBasicContent) {
      return (
          <Draggable { ...this.props } >
            {

              <span className="drag-icon">Google Map</span>
            }
          </Draggable>
      )
    }

    if (showPreview) {

        return (
          <div className="floating-summary-body">
            {latitude && longitude && getFormdetails.length !== 0 && getFormdetails.formvalues.length !== 0 &&

            <GoogleStaticMap
                lat={getFormdetails.formvalues.filter(list => list.masterid === latitude)[0].values[0].value}
                lng={getFormdetails.formvalues.filter(list => list.masterid === longitude)[0].values[0].value}
                zoom={5}
                type={`horizontal`}
            />
            }

          </div>

      );
    }

    return (
        <Draggable { ...this.props } draggable={!this.props.DragDisabled.dragStatus}>
          <React.Fragment>
            <FormGroup className="m-0">
              <label className="col-sm-12">
                <span>{name}</span>
                <div className={`left-container`} >

                  <span>Longitude </span>
                  <select name={`longitude`} onChange={(e)=>this.handleChangeComplete(e)} value={longitude}>
                    {showlistField.map((label, index) =>
                        <option key={`masterid` + index}
                                value={label.masterid}>{label.label}</option>
                    )}

                  </select>
                </div>
                <div className={`left-container`} >
                  <span>Latitude</span>
                  <select name={`latitude`} onChange={(e)=>this.handleChangeComplete(e)} value={latitude}>
                    {showlistField.map((label, index) =>
                        <option key={`masterid` + index}
                                value={label.masterid}>{label.label}</option>
                    )}

                  </select>
                </div>
                <div className="chart-icons">
                  {/*<div className="save-in-icon" onClick={() => this.chartSaving()}> </div>*/}
                  <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}> </div>
                </div>
              </label>
            </FormGroup>
          </React.Fragment>
        </Draggable>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled,
  getFormdetails : state.CollectionsReducer.getFormData,
  showFormModalData:state.EntityReducer.showFormModal,

});
export default  withRouter(connect(mapStateToProps, {
})(GoogleMap));
