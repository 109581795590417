import React from 'react';
import { Draggable, state } from 'react-page-maker';
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import {FormGroup} from "react-bootstrap";
import * as DynamicDashboard from "../../../../../actions/DynamicDashboard";
import * as formsActions from "../../../../../actions/Forms";
import EntityModal from "./EntityModal";
import LoaderSvg from "../../../../views/LoaderSVG";
import {hasExtension} from "../../../../../utilities/CustomFunctions";

class List extends React.Component {
  state = {
    showColorPicker: false,
    label: '',
  };
  componentDidMount() {
    this.setState({payload:this.props.payload})
  }

  checkBoxchange=(e)=> {
    let val;
    if (e.target.value === "false") {
      val = true
    } else {
      val = false
    }
    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = val
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }


  editRecord(col,masterids,type,mId){
    this.props.getselectedcollections({ _id : mId, collectionid : col, metainfo :true});
    this.props.addRecord(col,masterids,type,mId,"mainentity")
  }
  openSetting=()=>{
    this.setState({modalStatus:!this.state.modalStatus})
    //   this.props.dragStatus(!this.state.modalStatus)
  }
  handleChangeComplete = (e) => {
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  };
  render() {
    let getFormdetails=this.props.getFormdetails

    let showlistField=[]

    if(this.props.showFormModalData.showformdata.length !==0) {

      for (let i = 0; i < this.props.showFormModalData.showformdata.pages.length; i++) {
        (this.props.showFormModalData.showformdata.pages[i].components.filter(list=> list.visibility !=="hidden")).map(list=>{
          showlistField.push(list)

        })
      }
    }
    const {
      id, showBasicContent, showPreview,
      dropzoneID, parentID, name, payload
    } = this.props;

    const label =  this.state.label || payload && payload.label || '';
    let  containerData={
      label :  this.state.label || payload && payload.label || '',
      accessbox:true,
      edit :  this.state.edit || payload && payload.edit||false,
      data:true,
      display:true,
      showlistField:showlistField,
      entityField:true

    }

    if (showBasicContent) {
      return (
          <Draggable { ...this.props } >
            {
              <span className="drag-icon">List Card</span>
            }
          </Draggable>
      )
    }

    if (showPreview) {
      return (

          <div className="floating-summary-body">
            {label && getFormdetails.length !== 0 && getFormdetails.formvalues.length !== 0 &&
            <>
              {(getFormdetails.formvalues.filter(list => list.masterid === label)[0].type) === 'url' ?
                  <>
                    <ul>
                      {getFormdetails.formvalues.filter(list => list.masterid === label)[0].values.map((list, index) =>
                          (list.value !== '' && hasExtension(list.value) === false) ?
                              <li><a target="_blank" href={list.value}>{list.value}</a></li>
                              :
                              <li key={`desc` + index} dangerouslySetInnerHTML={{__html: list.value}}/>
                      )}
                    </ul>
                  </>
                  :
                  <>
                    <ul>
                      {getFormdetails.formvalues.filter(list => list.masterid === label)[0].values.map((list, index) =>
                          <li key={`desc` + index} dangerouslySetInnerHTML={{__html: list.value}}/>
                      )}
                    </ul>
                  </>
              }
            </>
            }

          </div>

      );
    }

    return (
        <React.Fragment>
          <Draggable { ...this.props } draggable={!this.props.DragDisabled.dragStatus}>
            <React.Fragment>
              <FormGroup className="m-0">
                <label className="col-sm-12">
                  <div className={`left-container`} >
                    <span>{name}</span>
                    {/*<input onChange={(e)=>this.handleChangeComplete(e)} onClick={()=>this.onClick()} onBlur={()=>this.onBlur()} defaultValue={label} className="layout-header-block"/>*/}
                  </div>
                  <div className="chart-icons">
                    <div className="settings-icon" onClick={() => this.openSetting(id, dropzoneID, parentID)}></div>
                    <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}> </div>
                  </div>
                </label>
              </FormGroup>
            </React.Fragment>
          </Draggable>

          {this.state.modalStatus &&
          <EntityModal
              title={`Single Card  -   Settings`}
              containerData={containerData}
              colorpickerStatus={this.state.colorpickerStatus}
              Close={this.openSetting}
              OKAction={this.chartSaving}
              cancelAction={this.openSetting}
              handleChange={this.handleChange}
              checkBoxchange={this.checkBoxchange}
              handleChangeComplete={this.handleChangeComplete}
          />
          }

        </React.Fragment>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled,
  showFormModalData:state.EntityReducer.showFormModal,
  getFormdetails : state.CollectionsReducer.getFormData,

});
export default  withRouter(connect(mapStateToProps, {
  addRecord:DynamicDashboard.addRecord,
  getselectedcollections : formsActions.getFormData,
})(List));
