import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import LoaderSvg from "./LoaderSVG";

class ProjectDashboardGraph extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    render() {

        return (

            <div className="ibt-graph"
                 style={{display: "flex",alignItems: "center",justifyContent: "center"}}>
                <img src="/assets/images/ibt-graph1.png" />
            </div>

        );
    }


}

export default ProjectDashboardGraph;