import React, {useState, useEffect, lazy} from 'react';
import {FRMSIMG_PATH} from "../../../../constants";
import NoData from "../../../views/NoData";
import {
    getCurrentAge,
    getFileExtension,
    indianDateFormat,
    isImage,
    truncateDecimals
} from "../../../../utilities/CustomFunctions"
import {getProfile} from "../../../../utilities/AuthService";
import {OverlayTrigger, Popover} from "react-bootstrap"
import AddNotes from '../../ceshareview/AddNotes';
import moment from "moment";
import {Scrollbars} from "react-custom-scrollbars";

function ResultTable(Props) {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const container = document.querySelector('.form-table');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        var scrollY = 0;
        var doc = document.getElementById("a");
        if (doc.scrollTop !== scrollY) {
            const container = document.querySelector('.form-table')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            if (contentHeight <= scrollTop) {
                Props.loadmoreCollectionData()
            }
        }
        setIsFetching(true);
    }

    function fetchMoreListItems() {
        Props.loadmoreCollectionData()
    }

    let showlistField=[]
    if(Props.hasOwnProperty('CollectionInfo')){
        if(Props.CollectionInfo.showlist_enabled){

            if (Props.label.showformdata.length !== 0) {
                for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
                    (Props.label.showformdata.pages[i].components.filter(list => (Props.label.showlist_enabled.includes(list.masterid)))).map(list => {
                        showlistField.push(list)
                    })
                }
            }

        }
        else{
            showlistField = Props.showlistField || []
        }

    }
    else {
        showlistField = Props.showlistField || []
    }


    // if (Props.label.showformdata.length !== 0) {
    //     for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
    //         (Props.label.showformdata.pages[i].components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden" && list.type !== "fieldset")).map(list => {
    //             showlistField.push(list)
    //         })
    //     }
    // }

    function onNoteClick(e, id) {
        Props.onNoteClick(e, id)

    }

    let permissionsSelected = Props.permissionsSelected

    function assignUsers() {
        //this.setState({})
        Props.assignToAllUsers(true)
    }

console.log(Props.label.showlist_enabled)
    console.log(Props.CollectionInfo.showlist_enabled)

 console.log(showlistField)

    return (

        <div
            className={`form-table coll-full-height fixed-header starred-field sticky-left ${Props.fieldview.length !== 0 ? 'top-align' : ""}`}
            id="a">

            {Props.label.status === false &&
            <div className='absolute-holder result-table-no-data'>
                <NoData
                    msg={`No Data linked to this collection`}
                />
            </div>
            }

            <table id="a" className="table data-table">

                <thead>
                <tr>
                    <th className="edit-record"/>
                    <th className="select-record">

                        {(showlistField && showlistField.length !== 0 && ((permissionsSelected && permissionsSelected.length !== 0 && (
                                permissionsSelected[0].assign || permissionsSelected[0].delete ||
                                permissionsSelected[0].edit || permissionsSelected[0].export ||
                                permissionsSelected[0].import || permissionsSelected[0].rule ||
                                permissionsSelected[0].addedit))
                            ||
                            (getProfile().usertype === "root" || getProfile().usertype === "Superadmin"
                                || (parseInt(Props.createuser) === parseInt(getProfile().id))))) &&
                        <div className="select-chk-block" style={{display: "block"}}>
                            <label className="control control-checkbox">
                                <input type="checkbox" className="inp-chk"
                                       onClick={Props.handleSelectAll}/>
                                <div className="control-indicator"></div>
                            </label>
                            <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={
                                <Popover id="popover-basic" className="more-option-card">
                                    <Popover.Content className="option-trigger">
                                        <ul>
                                            <li onClick={() => assignUsers()}
                                                className={Props.state.shareToAll ? `active ` : ""}>
                                                <nav>All</nav>
                                            </li>
                                        </ul>
                                    </Popover.Content>
                                </Popover>}>
                                <nav className="all-option"/>
                            </OverlayTrigger>
                        </div>
                        }

                    </th>
                    <th className="col-star">
                        <button className={`starred ${Props.state.starred ? "active" : ""}`}
                                onClick={() => Props.filterStarClick(!Props.state.starred)}></button>
                    </th>


                    {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].notes) &&
                    <th className="col-notes">
                        <span className="note-text">Note</span>
                    </th>
                    }

                    {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].calendar) &&
                    <th className="col-calendar">
                        <span className="note-text"></span>
                    </th>
                    }


                    {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].share) && (showlistField && showlistField.length !== 0 && ((permissionsSelected && permissionsSelected.length !== 0 && (
                            permissionsSelected[0].assign || permissionsSelected[0].delete ||
                            permissionsSelected[0].edit || permissionsSelected[0].export ||
                            permissionsSelected[0].import || permissionsSelected[0].rule ||
                            permissionsSelected[0].addedit))
                        ||
                        (getProfile().usertype === "root" || getProfile().usertype === "Superadmin"
                            || (parseInt(Props.createuser) === parseInt(getProfile().id))))) &&
                    <th className="col-share">

                        <span className="share-text">SHARE</span>

                    </th>
                    }


                    {Props.flags.length !== 0 &&
                    <th>FLAGS</th>
                    }
                    {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].showdate) &&
                    <th>CREATED DATE</th>
                    }
                    {/* <th></th> */}
                    {showlistField.map((title, index) => {

                            let active = ""
                            if (Props.state.sortData.masterid === title.masterid) {
                                active = Props.state.sort === "asc" ? "sorting-ascend" : "sorting-descend"
                            }
                            return (
                                <React.Fragment key={`showlist-field` + index}>
                                    <th className={active} scope="col" onClick={() => Props.sortingData(title)}>
                                        {title.hasOwnProperty('display_label') ? title.display_label : title.label}

                                        <div className="sort-panel">
                                            <span className="ico-lib-v1"> </span>
                                        </div>

                                    </th>
                                </React.Fragment>
                            )

                        }
                    )}

                </tr>
                </thead>
                <tbody>
                {showlistField.length !==0 && Props.collection.map((row, rowIndex) => {

                        return (
                            <tr key={`result-table-content` + rowIndex}
                                className={(Props.state.checkedRow.indexOf(row["id"]) > -1) ? "selected-row" : ""}>

                                {(getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) ?
                                    <td className="edit-record">
                                        <button className="ico-lib-v1 record-edit"
                                                onClick={(e) => Props.showFormModal(e, row['id'])}/>
                                    </td>
                                    :
                                    row['editrecordpermission'] ?
                                        <td className="edit-record">
                                            <button className="ico-lib-v1 record-edit"
                                                    onClick={(e) => Props.showFormModal(e, row['id'])}/>
                                        </td>
                                        :
                                        <td className="edit-record">
                                        </td>
                                }

                                <td className="select-record">

                                    {((permissionsSelected.length !== 0 && (
                                            permissionsSelected[0].assign || permissionsSelected[0].delete ||
                                            permissionsSelected[0].edit || permissionsSelected[0].export ||
                                            permissionsSelected[0].import || permissionsSelected[0].rule ||
                                            permissionsSelected[0].addedit)) ||
                                        (getProfile().usertype === "root" || getProfile().usertype === "Superadmin"
                                            || (parseInt(Props.createuser) === parseInt(getProfile().id)))) &&

                                    <div className="select-chk-block" style={{display: "block"}}>
                                        <label className="control control-checkbox">
                                            <input type="checkbox" className="inp-chk"
                                                   checked={(Props.state.checkedRow.indexOf(row["id"]) > -1) ? "checked" : ""}
                                                   data-val={row['id']}
                                                   onClick={() => Props.handleSelectRow(row['id'])}/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                    }

                                </td>

                                <td className="col-star">
                                    <button className={`starred ${row['isStar'] ? "active" : ""}`}
                                            onClick={(e) => Props.onStarClick(e, row['id'], !row['isStar'])}></button>
                                </td>


                                {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].notes) &&
                                <td className="col-notes">
                                    <OverlayTrigger trigger="click" rootClose placement="right"
                                                    overlay={<Popover id="popover-basic" className="popover-card">
                                                        <Popover.Content>
                                                            <AddNotes
                                                                value={Props.value}
                                                                changeNotes={Props.changeNotes}
                                                            />
                                                        </Popover.Content>
                                                    </Popover>}>
                                        <button className={`notes ${row['has_annotation'] ? "added" : ""}`}
                                            // onMouseEnter={(e) => onNoteClick(e, row['id'])}
                                                onClick={(e) => onNoteClick(e, row['id'])}
                                        />
                                    </OverlayTrigger>
                                </td>
                                }

                                {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].calendar) &&
                                <td className="col-calendar">
                            <span className="ico-lib col-calendar-ico"
                                  onClick={(e) => Props.showBookingCalendar(e, row)}></span>
                                </td>
                                }


                                {((permissionsSelected.length !== 0 && (
                                        permissionsSelected[0].assign || permissionsSelected[0].delete ||
                                        permissionsSelected[0].edit || permissionsSelected[0].export ||
                                        permissionsSelected[0].import || permissionsSelected[0].rule ||
                                        permissionsSelected[0].addedit)) ||
                                    (getProfile().usertype === "root" || getProfile().usertype === "Superadmin"
                                        || (parseInt(Props.createuser) === parseInt(getProfile().id)))) &&

                                (Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].share) &&
                                <td className="col-share">
                                    {row['users'] && Array.isArray(row['users']) && row['users'].length !== 0 &&
                                    <span className="ico-lib user-assigned-ico assigned_user_list"
                                          title="Assigned"
                                          onClick={() => Props.showAssignedUsers(row['users'], row['id'])}>
                                </span>
                                    }
                                    {row['users'] && Array.isArray(row['users']) && row['users'].length === 0 &&
                                    <span className="ico-lib user-assigned-ico assigned_user_list inactive-assign"
                                          title="Not Assigned"> </span>
                                    }
                                </td>

                                }


                                <td key={`result-tr` + rowIndex} className="highlight-td ht-left hide">

                                </td>

                                {Props.flags.length !== 0 &&
                                <td>
                                    {/*<nav className="ico-lib flag-ico" onClick={()=>Props.flagList(rowIndex,row)}/>*/}

                                    {row['flags'].length !== 0 &&
                                    <div className="flag-add-block">
                                        {row['flags'].map((list) =>
                                            <div className="each-added-flag" style={{backgroundColor: list.color}}/>
                                        )}

                                    </div>
                                    }
                                </td>
                                }
                                {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].showdate) &&
                                <td>
                                    {moment(row['createdate']).format('MMM DD, YYYY HH:mm:ss')}
                                </td>
                                }
                                {showlistField.map((title, index) => {
                                        let fieldValue = row[title.masterid];
                                        if (title.type === "attachment") {
                                            fieldValue = FRMSIMG_PATH + row[title.masterid];
                                        }
                                        let rateValarray = [];
                                        let rateVal = fieldValue;
                                        if (title.type === "rating") {
                                            rateVal = fieldValue && fieldValue !== '' ? fieldValue : 0;
                                            let count = 5;
                                            for (var i = 0; i < count; i++) {
                                                rateValarray.push(i)
                                            }
                                        }
                                        let rows = [];
                                        if (title.type === "resources") {
                                            rows = row[title.masterid] !== undefined && row[title.masterid] !== '' ? JSON.parse(row[title.masterid]) : [];
                                            if (rows.length > 0) {
                                                fieldValue = FRMSIMG_PATH + rows[0].thumb;
                                            } else {
                                                fieldValue = '';
                                            }
                                        }
                                        if (title.type === "age") {
                                            let currentDate = new Date();
                                            let birthDate = row[title.currentage];
                                            currentDate = moment(currentDate).format("yyyy-MM-DD");
                                            birthDate = birthDate !== '' ? moment(birthDate).format("yyyy-MM-DD") : '';
                                            if (birthDate !== '') {
                                                // var date1 = new Date(birthDate);
                                                // var date2 = new Date(currentDate);
                                                // var Difference_In_Days = date2.getFullYear() - date1.getFullYear();  //To calculate the year difference of two dates
                                                // Difference_In_Days = Difference_In_Days > 0 ? Difference_In_Days : ''
                                                // var fieldValueSum = Math.abs(Difference_In_Days);
                                                // fieldValue = fieldValueSum;
                                                fieldValue = getCurrentAge(birthDate);
                                            } else {
                                                fieldValue = '';
                                            }
                                        }

                                        return (
                                            <React.Fragment key={`edit-collection-data` + index}>

                                                <td className={`collection-detail-edit ${index === 0 ? "edited-field" : ""} ${Props.fieldview.includes(title.masterid) ? 'column-word-wrap' : ""}`}
                                                    //onDoubleClick={(e) => Props.showPreviewFormModal(e, row['id'], rowIndex)}
                                                    onClick={(e) => Props.handleClicks(e, row['id'], rowIndex)}
                                                >
                                                    <div className={`form-table-edit`}>
                                                        {(title.type === "attachment" || title.type === "signature") ?
                                                            <div className="att-file">
                                                                {fieldValue !== '' &&
                                                                <img src={fieldValue} alt={`attachment`}/>
                                                                }
                                                            </div>
                                                            :
                                                            (title.type === "switch" || title.type === "checkbox") ?

                                                                (title.hasOwnProperty(`optionstatus`) && title.optionstatus === "imageoption" ?
                                                                    <div className="att-file">
                                                                        {fieldValue !== '' &&
                                                                        <img src={fieldValue} alt={`attachment`}/>
                                                                        }
                                                                        {/*<span className="att-text"></span>*/}
                                                                    </div>
                                                                    :
                                                                    <span className="text-overflow">{fieldValue}</span>)

                                                                :


                                                                (title.type === "datepicker") ?
                                                                    <>
                                                            <span
                                                                className="text-overflow">{title.autocapture && title.autocapture === 'Yes' && title.datetime && title.datetime === 'Yes' && fieldValue !== '' ?
                                                                moment.utc(fieldValue).local().format('DD-MM-YYYY hh:mm')
                                                                :
                                                                title.datetime && title.datetime === 'Yes' && fieldValue !== '' ?
                                                                    moment(fieldValue).format('DD-MM-YYYY hh:mm')
                                                                    :
                                                                    indianDateFormat(fieldValue, true)}</span>
                                                                    </>
                                                                    :
                                                                    (title.type === "timepicker") ?
                                                                        <>
                                                                    <span
                                                                        className="text-overflow">{title.autocapture && title.autocapture === 'Yes' ? moment.utc("2020-12-17 " + fieldValue).local().format('HH:mm:ss') : fieldValue}</span>
                                                                        </>
                                                                        :
                                                                        (title.type === "rating") ?
                                                                            <ul className={`rate-star ${title.ratingtype === 'bulb' ? "bulb" : ""}`}>
                                                                                {rateValarray.map((opt, index) =>
                                                                                    <li className={`${index < rateVal ? "active" : ""}`}
                                                                                        key={index}></li>
                                                                                )}
                                                                            </ul>
                                                                            :
                                                                            (title.type === "resources") ?
                                                                                <div className="att-file">
                                                                                    {fieldValue !== '' &&
                                                                                    <img src={fieldValue} alt={`resources`}/>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                (title.type === "editor") ?
                                                                                    <div
                                                                                        dangerouslySetInnerHTML={{__html: fieldValue}}/>
                                                                                    :
                                                                                    (title.type === "text" && title.password === "Yes") ?

                                                                                        <span
                                                                                            className="text-overflow">******</span>
                                                                                        :
                                                                                        (title.type === "computedfield") ?
                                                                                            <span
                                                                                                className="text-overflow">{fieldValue === "" ? "" : title.hasOwnProperty('decimalpoint') && title.decimalpoint !== 0 && title.hasOwnProperty('rounddecimal') && title.rounddecimal === 'Yes' ? parseFloat(fieldValue).toFixed(title.decimalpoint) : title.hasOwnProperty('decimalpoint') && title.decimalpoint !== 0 ? truncateDecimals(fieldValue, title.decimalpoint) : parseFloat(fieldValue).toFixed(2)}</span>
                                                                                            :
                                                                                            <span
                                                                                                className="text-overflow">{fieldValue}</span>
                                                        }


                                                        {/*{index === 0 &&*/}
                                                        {/*<div className="wf-btn-block">*/}
                                                        {/*    {((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].edit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&*/}
                                                        {/*    <button className="ico-lib wf-ed" data-action="editcollection"*/}
                                                        {/*            onClick={(e) => Props.showFormModal(e, row['id'])}>*/}

                                                        {/*    </button>*/}
                                                        {/*    }*/}
                                                        {/*</div>*/}
                                                        {/*}*/}
                                                    </div>
                                                </td>

                                            </React.Fragment>
                                        )
                                    }
                                )}

                            </tr>
                        )
                    }
                )}

                </tbody>
            </table>
        </div>
    )

}

export {ResultTable};
