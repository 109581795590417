import React from 'react';
import axios from 'axios';
import {FormGroup} from 'react-bootstrap';
import { Draggable, state } from 'react-page-maker';
import FeaturedBlock from "../views/content/FeaturedBlock";
import SimpleModal from "../../../views/SimpleModal"
import ColorPicker from "../views/ColorPicker";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {withRouter} from 'react-router';
import {API_PATH,ES_INDEX} from "../../../../constants"
import {getProfile, loggedIn} from '../../../../utilities/AuthService';
import {getUrlSegment} from "../../../../utilities/CustomFunctions";
class FeaturedBlockContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      modalStatus:false,
      showColorPicker:false,
      background:'#fff',
      payload:{
        chartTitle:"Title",
        subTitle:"subtitle",
        subTitleEnabled : false,
        linedata:[],
        cardvalue:0,
        collection:"",
        fields:"",
        x:"",
        labelx:"",
        labely:"",
        labelchartTitle:"",
        y:"",
        areacolor:"#31CC0F",
        barcolr:"#ccc",
      },
      featuredBlock : [],
      showlistField:[]
    }


  }
   componentDidMount() {
    this.setState({payload:this.props.payload})
     this.graphData()
   }
  openSetting=()=>{
    this.setState({modalStatus:!this.state.modalStatus})
    this.props.dragStatus(!this.state.modalStatus)
  }
  handleChangeComplete = (color,param) => {
    //////console.log(param)
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[param]=color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
    //this.setState({ background: color.hex });
  };

  handleInputChange=(e)=>{
    let sel=e.target
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    payload["label"+e.target.name]=sel.options[sel.selectedIndex].text
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }

graphData(){
    this.setState({areacolor:''})
  var obj = this;
  const collection =  this.props.payload && this.props.payload.collection
  const y = this.props.payload && this.props.payload.y
  const labely = this.props.payload && this.props.payload.labely
  if((getUrlSegment(1) === "dashboard") && (collection !=='' && collection !== undefined)) {
    axios({
      method: "GET",
      url: API_PATH + "collection/showform?_id=" + collection+"&index="+ES_INDEX,
      async: false,
       headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
    }).then(function (res) {
      //////console.log(res.data.data)
      obj.setState({showlistField:res.data.data});
      return res;

    }).catch(function (err) {
      ////////console.log("error");
      ////////console.log(err);
      return err;
    });
  }
  if(collection !=="" && collection !== undefined && y!== undefined) {
      let ydata=y.split("|")
      let sharetype = getUrlSegment(1);
     let  uid = '';
    let usertype = '';
    if(sharetype === 'public') {
        uid = 2;
        usertype = 'root';
    }
    else{
        uid = getProfile().id;
        usertype = getProfile().usertype;
    }
    const data = {
      index: ES_INDEX,
      usertype: usertype,
      uid: uid,

      filters: [{"id": ydata[0], "value": [labely]}]
    };
    axios.post(API_PATH+"collection/"+collection+"/collectedinfo?t=1579612748444&index="+ES_INDEX,  data )
     .then(function (res) {
      var carddata = res.data.results.data;
      obj.setState({featuredBlock: carddata});
      return res;
    }).catch(function (err) {
      return err;
    });
  }




}
  checkBoxchange=(e)=> {
    let val;
    if (e.target.value === "false") {
      val = true
    } else {
      val = false
    }

    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = val
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  chartSaving=()=>{
    var prjctdtlid = atob(getUrlSegment(4));
     let sharetype = getUrlSegment(1);
     let  uid = '';
    if(sharetype === 'public') {
        uid = 2;
    }
    else{
        uid = getProfile().id;
    }
    let param={
      "projectid": prjctdtlid,
      "uid": uid,
      "index": ES_INDEX,
      "type": this.props.getdashboardChange.Dashboard,
      "dashboard":state.getStorableState()
      ,
      "id": this.props.getdashboardChange.UpdateId
    }
    this.props.chartSaving(param)
    this.setState({modalStatus:false})
  }
  handleChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }

  handleClick=(status)=>{
    this.setState({colorpickerStatus:status})
  }
render() {
  let showlistField = []
  const {
    id,
    showBasicContent, showPreview,
    dropzoneID,payload, parentID, name
  } = this.props;
  const chartTitle =  this.state.chartTitle || payload && payload.chartTitle;
  const subTitle =  this.state.subTitle || payload && payload.subTitle;
  const subTitleEnabled = this.state.subTitleEnabled || payload && payload.subTitleEnabled;
  const collection =  this.state.collection || payload && payload.collection ;
  let collections=(this.props.listAllCollections.ListAllCollections.data)
  const x =  this.state.x || payload && payload.x ;
  const y =  this.state.y || payload && payload.y ;
  const labely =  this.state.labely || payload && payload.labely ;

  const areacolor =  this.state.areacolor || payload && payload.areacolor||"#3855D6";
  if(this.state.showlistField.length !==0) {
    for (let i = 0; i <this.state.showlistField.pages.length; i++) {
      (this.state.showlistField.pages[i].components).map(list=>{
        showlistField.push(list)
      })
    }
  }
  let feauredData=this.state.featuredBlock.filter(list=>list.collectedform[0].value[0].value===labely).map((list)=>list.collectedform[1])
  let featuredBlock=(feauredData.map((list)=>list.value[0]))
  if (showBasicContent) {
    return (
        <Draggable { ...this.props } >
          {
            <span className="drag-icon">{name}</span>
          }
        </Draggable>
    )
  }

  if (showPreview) {
    return (

             <FeaturedBlock
                 chartTitle={chartTitle}
                 subTitle={subTitle}
                 subTitleEnabled={subTitleEnabled}
                 featuredBlock={featuredBlock}
                 areacolor={areacolor}
             />

    );
  }
  return (
<React.Fragment>
  <Draggable {...this.props}   draggable={!this.props.DragDisabled.dragStatus}>
    <FormGroup className="m-0">
      <label className="col-sm-12">
        <span>{name}</span>
        <div className="chart-icons">
          <div className="settings-icon" onClick={() => this.openSetting(id, dropzoneID, parentID)}></div>
          <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>
        </div>
      </label>
    </FormGroup>
  </Draggable>
  {this.state.modalStatus &&
      <SimpleModal
      Close={this.openSetting}
      title={`Featured Area - Settings`}
      haveFooter={1}
      OKLabel="Save"
      OKAction={this.chartSaving}
      asignedUsershow = {false}
      cancelLabel="Cancel"
      cancelAction={this.openSetting}
  >
    <div className="DashBuilder-modal">
      <div className="task-input-container">
        <div className="new-task-input">
          <input type="text" placeholder="title" id="chartTitle" name="chartTitle" autoComplete="off" value={chartTitle}
                 onChange={(e)=>this.handleChange(e)} />
        </div>

      </div>
      <div className="task-input-container container-flex">
        <div className="new-task-input">
          <input type="text" placeholder="subtitle" id="subTitle" name="subTitle" autoComplete="off" value={subTitle}
                 onChange={(e)=>this.handleChange(e)} />
        </div>
        <div className="select-chk-block" style={{"display": "block"}}>
          <label className="control control-checkbox">Enable Subtitle
            <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`} checked={subTitleEnabled} value={subTitleEnabled} onClick={(e)=>this.checkBoxchange(e)}/>
            <div className="control-indicator"></div>
          </label>
        </div>

      </div>
      <div className="task-input-container">
        <label>Collections:</label>
        <div className="select-box eq-width">
          <select id="priority" name="collection" onChange={(e)=>this.handleInputChange(e)} value={collection}>

            <option value="">Please Select</option>
            {collections.map((list,index)=>
                <option value={list._id}>{atob(list.title)}</option>
            )}
          </select>
        </div>
      </div>



      <div className="graph-proprties">
        <div className="graph-proprties-axis">
              <div className="task-input-container">
                <label>X axis:</label>
                <div className="select-box eq-width">
                  <select id="xaxis" name="x" onChange={(e)=>this.handleInputChange(e)} value={x}>
                    <option value="">Please Select</option>
                    {showlistField.filter(list=>list.type==="dropdown").map((label,index)=>
                        <option key={`xaxis`+index} value={label.masterid}>{label.label}</option>
                    )}
                  </select>
                </div>
              </div>
          {x !== undefined &&
            <div className="task-input-container">
            <label>Y axis:</label>
            <div className="select-box eq-width">
              <select id="yaxis" name="y" onChange={(e) => this.handleInputChange(e)} value={y}>
                <option value="">Please Select</option>
                {showlistField.length !== 0 && showlistField.filter(list => list.masterid === x)[0] !==undefined && showlistField.filter(list => list.masterid === x)[0].option.map((label, index) =>
                    <option key={`yaxis` + index} value={showlistField.filter(list => list.masterid === x)[0].masterid+"|"+label}>{label}</option>
                )}
              </select>
            </div>
          </div>
          }


        </div>




        <div className="graph-proprties-color">
          <div className="task-input-container">
            <div className="stroke-color-select">
              <label>Area Color:</label>
              <ColorPicker
                  stroke={"areacolor"}
                  color={areacolor}
                  onChangeComplete={ this.handleChangeComplete }
                  handleClickStaus={this.handleClick}
              />
            </div>
          </div>




        </div>



      </div>

    </div>


  </SimpleModal>
  }
</React.Fragment>
  )
}
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled

});
export default  withRouter(connect(mapStateToProps, {
  collections: collectionActions.listallcollections,
  showform : collectionActions.showform,
  chartSaving:DynamicDashboard.saveDashboarddata,
  dragStatus :DynamicDashboard.dragStatus,
})(FeaturedBlockContainer));
