import {commonService} from "./common.services";
import {ES_INDEX, API_PATH} from '../constants/index';

export const schedulerService = {
    getCategories,
    eventSearch,
    eventsAsAtendee,
    createEvent,
    deleteEvent,
    calendarData,
    getHosts,
    getAttendees,
    getPatientEvents,
    addToPatientProgramEvent,
    getCalendarSettings,
    saveCalendarSettings,
    getHolidays,
    getLeaves,
    getLeads,
    getBookingsAndLeaves,
    saveCalendarRecord,
    getPatientProgramEvent,
    updatePatientProgramEvent,
    updateEventStatus,
    sendPushNotifications,
    getEntities,
    layoutList,
    userLoginTrack,
    sendOTP,
    verifyOTP,
    findCities,
    findShowrooms,
    findSalespersons,
    findResourceBookings,
    saveResourceBookings,

    getAgentAvailableSlots,
    getTrovaUserInfo,
    updateCallStatus,
    getScheduleCalls,
    saveCallSchedule,
    getUserPassword,
    saveTrovaUserProfile,
    getCollectionData,
    getAllSlots,
    getAgentLeaves,
    saveAgentLeave
};


function calendarData(param) {

    let filter = [{id: "1136635310", value: [param.userRole === 'Health Coach' ? 'Lead' : "Booking"]}];
    let params = {};
    let search = '';
    if (param['name']) {
        search = param['name'];
    }
    if (param['hostId'] && param['hostId'] !== 0) {
        filter.push({"id": "176439646", "value": [param['hostId'], param['emailId']]});
    }
    let today = new Date();
    let m = (parseInt(today.getMonth()) + 1) < 10 ? '0' + (parseInt(today.getMonth()) + 1) : (parseInt(today.getMonth()) + 1);
    let d = (parseInt(today.getDate())) < 10 ? '0' + (parseInt(today.getDate())) : (parseInt(today.getDate()));
    let day = today.getFullYear() + '-' + m + '-' + d;
    params = {
        "_id": ES_INDEX + "10021",
        "index": ES_INDEX,
        "search": search,
        "filters": filter,
        "datefilters": [{id: "749206920", from: day, to: '', label: ""}],
        "numberfilters": [],
        "dayfilters": [],
        "timefilters": [],
        "sort": [],
        "from": 0,
        "size": (param['hostId'] && param['hostId'] !== 0) ? 200 : 400
    };
    return commonService.sendHttpRequest('patients/calendardata', params, "POST", false);
}

function eventSearch(param) {

    let filter = [];
    let params = {};
    let search = '';
    if (param['name']) {
        search = param['name'];
    }
    if (param['hostId'] && param['hostId'] !== 0) {
        //filter.push({"id":"176439646","value":[param['hostId'], '']});
        filter.push({"id": "176439646", "value": [param['hostId'], param['emailId']]});
    }
    /*if(param['month'] && param['month'] !== '') {
        let dt = param['month'].split('-');
        filter.push({"id":"1536506600","value":[dt[0]]});
        filter.push({"id":"1455382640","value":[dt[1]]});
    }*/

    params = {
        "filters": filter,
        "search": search,
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false
    };
    //params['sort'] = [{type: "number", order: 'asc', id: "59977177"}]
    return commonService.sendHttpRequest('collection/' + ES_INDEX + '10021/collectedinfo', params, "POST", false);
}

function getCategories() {
    let params = {
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "uid": 2
    };
    return commonService.sendHttpRequest('collection/' + ES_INDEX + '10020/collectedinfo', params, "POST", false);
}

function getAttendees() {
    /*let params = {
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "uid": 2
    };*/
    //return commonService.sendHttpRequest('collection/1600334248039/collectedinfo', params, "POST", true);
    let params = {
        "page": 1,
        "per_page": 200
    };
    return commonService.sendHttpRequest('patients/patientinfo', params, "GET", false);
}

function getHosts(id, role) {
    let filter = [];
    if (role !== '') {
        filter.push({id: "961935515", value: [role]})
    }
    let params = {
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "uid": 2,
        "from": 0,
        "size": 200,
        "filters": filter
    };
    return commonService.sendHttpRequest('collection/' + ES_INDEX + '10003/collectedinfo', params, "POST", false);
}

function eventsAsAtendee(param) {

    let filter = [];
    let params = {};
    let search = '';
    if (param['name']) {
        search = param['name'];
    }
    if (param['hostId'] && param['hostId'] !== 0) {
        filter.push({"id": "807157770", "value": [param['hostId'], param['emailId']]});
    }
    /*if(param['month'] && param['month'] !== '') {
        let dt = param['month'].split('-');
        filter.push({"id":"1536506600","value":[dt[0]]});
        filter.push({"id":"1455382640","value":[dt[1]]});
    }*/

    params = {
        "filters": filter,
        "search": search,
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false
    };
    //params['sort'] = [{type: "number", order: 'asc', id: "59977177"}]
    return commonService.sendHttpRequest('collection/' + ES_INDEX + '10021/collectedinfo', params, "POST", false);
}

function createEvent(data, action) {//console.log(data)
    let concatFormData = data.attendee_id + " " +
        data.subject + " " +
        data.location + " " +
        data.start_time + " " +
        data.end_time + " " +
        data.description + " " +
        data.month + " " +
        data.year + " " +
        data.date + " " +
        data.time;
    let formData = [
        {
            "type": "number",
            "masterid": "807157770",
            "label": "atendee_id",
            "value": [{"value": data.attendee_id, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "719723961",
            "label": "subject",
            "value": [{"value": data.subject, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "700987138",
            "label": "location",
            "value": [{"value": data.location, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "59977177",
            "label": "start_time",
            "value": [{"value": data.start_time, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "740795586",
            "label": "end_time",
            "value": [{"value": data.end_time, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "809384225",
            "label": "description",
            "value": [{"value": data.description, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "1412520341",
            "label": "recurrence_rule",
            "value": [{"value": data.recurrence_rule, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "number",
            "masterid": "176439646",
            "label": "doctor_id",
            "value": [{"value": data.host_id, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "number",
            "masterid": "1455382640",
            "label": "month",
            "value": [{"value": data.month, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "number",
            "masterid": "1536506600",
            "label": "year",
            "value": [{"value": data.year, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "datepicker",
            "masterid": "749206920",
            "label": "date",
            "value": [{"value": data.date, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "timepicker",
            "masterid": "600885400",
            "label": "time",
            "value": [{"value": data.time, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            external_linked: "no",
            label: "category",
            masterid: "1136635310",
            password: "No",
            type: "dropdown",
            value: [{"value": data.category, "refIndexNo": 0}]
        },
        {
            checkboxoption: "textoption",
            external_linked: "no",
            label: "full_day",
            masterid: "1247503762",
            password: "No",
            type: "toggle",
            value: [{"value": data.full_day, "refIndexNo": 0}]
        }
    ];
    //console.log(formData)
    let params = {
        child: action === "save" ? "" : data.masterid,
        formconcat: concatFormData,
        formdata: JSON.stringify(formData),
        mode: action,
        parent: ES_INDEX + "10021",
        reference: "",
        referenceID: ES_INDEX + "10021",
        uid: "2",
    };
    return commonService.sendHttpRequest('collection/createdata', params, "POST", false);

}

function saveCalendarRecord(data, action) {


    let dt = data.date.valueOf().split('-');
    let tm = data.time.valueOf().split(' ');
    let t = tm[0].split(':');
    let hr = t[0];
    let m = t[1];
    if (tm[1] === 'PM' && t[0] !== '12') {
        hr = parseInt(t[0]) + 12;
    }
    let mon = parseInt(dt[1]) < 10 ? '0' + dt[1] : dt[1];
    let day = parseInt(dt[2]) < 10 ? '0' + dt[2] : dt[2];
    let dueDt = dt[0] + '-' + mon + '-' + day + ' ' + hr + ":" + m + ':00';
    let params = {
        "index": ES_INDEX,
        "cid": ES_INDEX + "10021",
        "uid": 2,
        "taskto": data.host_id,
        "tasktile": "Appointment booked for medical call on " + day + '-' + mon + '-' + dt[0],
        "subject": "Appointment has been booked for the medical call.",
        "duedate": dueDt,
        "priority": "Medium",
        "data": [
            {
                "attendee_id": data.attendee_id,
                "subject": "",
                "location": "",
                "start_time": data.start_time,
                "end_time": data.end_time,
                "description": "appointment",
                "recurrence_rule": "",
                "host_id": data.host_id,
                "month": data.month,
                "year": data.year,
                "date": data.date,
                "time": data.time,
                "category": "Booking",
                "full_day": "No",
                "isfollowup": "no",
                "followup_date": dt[0] + '-' + mon + '-' + day
            }
        ]
    }
    //console.log(params);
    return commonService.sendHttpRequest('patients/savecalendarrecord', params, "POST", false);

}

function saveCalendarSettings(data) {
    let concatFormData = data.wD + ' ' + data.WorkStartTime + " " +
        data.WorkEndTime + " " +
        data.BreakStartTime.join(" ") + " " +
        data.BreakEndTime.join(" ");
    let breakStartTime = [];
    data.BreakStartTime.map((st, indx) => {
        breakStartTime.push({"value": st, "refIndexNo": 0})
    });
    let breakEndTime = [];
    data.BreakEndTime.map((et, indx) => {
        breakEndTime.push({"value": et, "refIndexNo": 0})
    });
    let formData = [
        {
            "type": "checkbox",
            "masterid": "664677143",
            "label": "Working days",
            "value": data.workingHours,
            "external_linked": "no",
            "optionstatus": "textoption"
        },
        {
            "type": "timepicker",
            "masterid": "214589978",
            "label": "Work start time",
            "value": [{"value": data.WorkStartTime, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "timepicker",
            "masterid": "1420244562",
            "label": "Work end time",
            "value": [{"value": data.WorkEndTime, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "timepicker",
            "masterid": "623496003",
            "label": "Break start time",
            "value": breakStartTime,
            "external_linked": "no"
        },
        {
            "type": "timepicker",
            "masterid": "309069867",
            "label": "Break end time",
            "value": breakEndTime,
            "external_linked": "no"
        }
    ];
    //console.log(formData)
    let params = {
        child: "160871213051473",
        formconcat: concatFormData,
        formdata: JSON.stringify(formData),
        mode: "update",
        parent: "2_1286966448000",
        reference: "",
        referenceID: "2_1286966448000",
        uid: "2",
    };
    return commonService.sendHttpRequest('collection/createdata', params, "POST", false);

}

function getPatientProgramEvent(data, action) {
    let params = {
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "filters": [{id: "1505903870", value: [data.ppeid]}, {id: "1354208199", value: [data.eventId]}],
        "uid": 2,
        "index": ES_INDEX
    };
    if (data.actionType && data.actionType === 'Push Notification') {
        params.filters.push({id: "1604469919216", value: ["Push Notification"]});
        //params.filters.push({id: "1604469919216", value: ["Push Notification"]}, {id: "150551411", value: ["Yes"]});
        //params.filters.push({id: "150551411", value: ["Yes"]});
    }
    return commonService.sendHttpRequest('collection/3_516910742488/collectedinfo', params, "POST", false);

}

function updatePatientProgramEvent(data, formData, masterid) {//console.log(data)
    let concatFormData = data.ppid + " " +
        data.ppeid + " " +
        data.eventId + " " +
        data.programId + " " +
        data.subject + " " +
        "Call " +
        data.date + " " +
        data.host_id;

    //console.log(formData)
    /*let params = {
        child: masterid,
        formconcat: concatFormData,
        formdata: JSON.stringify(formData),
        mode: "update",
        parent: "3_516910742488",
        reference: "",
        referenceID: "3_516910742488",
        uid: "2",
    };*/
    let params = {
        "index": ES_INDEX,
        "uid": "2",
        "collectionid": "3_516910742488",
        "recordid": masterid,
        "fields": [
            {
                "external_linked": "no",
                "fieldname": "Event Date",
                "label": "event_date",
                "linked": "No",
                "masterid": "113785782",
                "password": "No",
                "type": "datepicker",
                "value": [{"refIndex": 0, "value": data.date}]
            },
            {
                "external_linked": "no",
                "fieldname": "Event Scheduled Time",
                "label": "event_scheduled_time",
                "linked": "No",
                "masterid": "901074832",
                "password": "No",
                "type": "timepicker",
                "value": [{"refIndex": 0, "value": data.time}]
            }
        ]
    }
    return commonService.sendHttpRequest('collection/fieldupdate', params, "POST", false);

}

function addToPatientProgramEvent(data, action) {//console.log(data)
    let concatFormData = data.ppid + " " +
        data.eventId + " " +
        data.programId + " " +
        data.subject + " " +
        "Call " +
        data.date + " " +
        data.host_id;
    let formData = [
        {
            "type": "dropdown",
            "masterid": "1505903870",
            "label": "ppid",
            "value": [{"value": data.ppid, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "autofill",
            "masterid": "1354208199",
            "label": "event_id",
            "value": [{"value": data.eventId, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "dropdown",
            "masterid": "1604469919180",
            "label": "program_id",
            "value": [{"value": data.programId, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "text",
            "masterid": "1604469919148",
            "label": "event_title",
            "value": [{"value": data.subject, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "editor",
            "masterid": "1607066531942",
            "label": "message",
            "value": [{"value": '', "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "textarea",
            "masterid": "1607066531852",
            "label": "instructions",
            "value": [{"value": '', "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "dropdown",
            "masterid": "1604469919216",
            "label": "action_type",
            "value": [{"value": 'Call', "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "number",
            "masterid": "982845372",
            "label": "event_day",
            "value": [{"value": 0, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "datepicker",
            "masterid": "113785782",
            "label": "event_date",
            "value": [{"value": data.date, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "timepicker",
            "masterid": "901074832",
            "label": "event_scheduled_time",
            "value": [{"value": data.time, "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "datepicker",
            "masterid": "263229068",
            "label": "hold_start_date",
            "value": [{"value": "", "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "datepicker",
            "masterid": "441489017",
            "label": "hold_end_date",
            "value": [{"value": "", "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "type": "dropdown",
            "masterid": "1231518709",
            "label": "hold_reason",
            "value": [{"value": "", "refIndexNo": 0}],
            "external_linked": "no"
        },
        {
            "external_linked": "no",
            "label": "responsible_person",
            "masterid": "1607066836090",
            "password": "No",
            "type": "dropdown",
            "value": [{"value": data.host_id, "refIndexNo": 0}]
        }
    ];
    //console.log(formData)
    let params = {
        child: "",
        formconcat: concatFormData,
        formdata: JSON.stringify(formData),
        mode: "save",
        parent: "3_516910742488",
        reference: "",
        referenceID: "3_516910742488",
        uid: "2",
    };
    return commonService.sendHttpRequest('collection/createdata', params, "POST", false);

}

function deleteEvent(data) {

    let params = {
        collectionid: ES_INDEX + "10021",
        records: [data],
        uid: "2",
    };
    return commonService.sendHttpRequest('collection/deleterecords', params, "POST", false);

}

function getPatientEvents(eventid = '') {
    let params = {
        "page": 1,
        "per_page": 100
    };
    if (eventid && eventid !== '') {
        params['eventid'] = eventid
    }
    return commonService.sendHttpRequest('patients/eventlist', params, "GET", false);
}

function getCalendarSettings() {
    let params = {
        "collectionid": "2_1286966448000",
        "metainfo": true,
        "_id": "160871213051473",
        "index": ES_INDEX
    };

    return commonService.sendHttpRequest('collection/selectedcollection', params, "POST", false);
}

function getHolidays() {
    let params = {
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "from": 0,
        "size": 200,
        "filters": [{id: "1136635310", value: ["Holiday"]}],
        "sort": [{type: "date", order: 'desc', id: "749206920"}],
        "uid": 2
    };
    return commonService.sendHttpRequest('collection/' + ES_INDEX + '10021/collectedinfo', params, "POST", false);
}

function getLeaves(id) {
    let filters = [{id: "1136635310", value: ["Leave"]}]
    if (id !== 0) {
        filters.push({id: "176439646", value: [id]});
    }
    let params = {
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "from": 0,
        "size": 200,
        "filters": filters,
        "sort": [{type: "date", order: 'desc', id: "749206920"}],
        "uid": 2
    };
    return commonService.sendHttpRequest('collection/' + ES_INDEX + '10021/collectedinfo', params, "POST", false);
}

function getLeads() {
    let filters = [];

    let params = {
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "from": 0,
        "size": 200,
        "filters": filters,
        "sort": [{type: "text", order: 'desc', id: "1500062073"}],
        "uid": 2
    };
    return commonService.sendHttpRequest('collection/1600230072115/collectedinfo', params, "POST", false);
}

function getBookingsAndLeaves(id) {
    let filters = [{id: "1136635310", value: ["Leave", "Booking"]}]
    if (id !== 0) {
        filters.push({id: "176439646", value: [id]});
    }
    let params = {
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "from": 0,
        "size": 400,
        "filters": filters,
        "sort": [{type: "date", order: 'desc', id: "749206920"}],
        "uid": 2
    };
    return commonService.sendHttpRequest('collection/' + ES_INDEX + '10021/collectedinfo', params, "POST", false);
}

function updateEventStatus(data, currentEventId) {
    let params = {
        "ppid": data.ppeid,
        "eventid": currentEventId,
        "index": ES_INDEX,
        "param_object": data.date + "," + data.time
    };
    return commonService.sendHttpRequest('patients/updateeventstatus', params, "POST", false);
}

function sendPushNotifications(data) {
    let params = {
        "index": ES_INDEX,
        "patientid": data.patientId,
        "type": "Push Notification",
        "message": data.message,
        "title": data.title,
        "not_details": [
            {
                "patientid": data.patientId,
                "responsible_person": data.hostId
            }
        ]
    };
    return commonService.sendHttpRequest('patients/patientpushnotifications', params, "POST", false);
}

function getEntities(name) {

    let collectionIds = {
        'Meetingrooms': '1618825589542',
        'Facilities': '2_869310539877',
        'Layouts': '2_1284342505797'
    };
    let collection = collectionIds[name];
    let params = {
        "refer": "entity",
        "dashboard": false,
        "facetrequired": false,
        "uid": 2,
        "from": 0,
        "size": 200,
        "allfields": true
    };
    return commonService.sendHttpRequest('collection/' + collection + '/collectedinfo', params, "POST", false);
}

function layoutList(name) {

    let params = {
        "masterid": "2_427526311608",
        "index": ES_INDEX,
        "search": ""
    };
    return commonService.sendHttpRequest('custom/layoutlist', params, "GET", false);
}

function userLoginTrack(param) {
    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + ' ' + time;
    let params = {
        index: ES_INDEX,
        cid: "2_1243023001376",
        data: [{
            Name: param.name,
            Email: param.email,
            Mobile: param.mobile,
            Date: dateTime
        }],
        id: "",
        reference: "collection",
        referenceid: "2_1243023001376"
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/saverecord', params, "POST", true);

}


function sendOTP(param) {
    let params = {};
    params = {
        "collectionid": "397_1614314033",
        "fieldid": "566812281",
        "mobile": param.mobile,
        "index": ES_INDEX,
        "key": "lsuser12345!",
        "service": "twilio",
        "msg": "is your OTP for Virtual Showroom. The OTP will expire in 180 seconds."
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/sendbsynotp', params, "POST", true);
}

function verifyOTP(param) {
    let params = {};
    params = {
        "collectionid": "397_1614314033",
        "mobile": param.mobile,
        "index": ES_INDEX,
        "otp": param.otp
    };

    return commonService.sendHttpRequest(API_PATH + 'collection/verifybsynotp', params, "POST", true);
}

function findCities(param) {
    let params = {};
    params = {
        "index": ES_INDEX,
        "collectionid": "1620711454226",
    };

    return commonService.sendHttpRequest('custom/districtlist', params, "GET", true);
}

function findShowrooms(param) {
    let params = {};
    params = {
        "index": ES_INDEX,
        "collectionid": "1620711146342",
        "districtid": param.district

    };
    return commonService.sendHttpRequest('custom/showroomlist', params, "GET", true);
}

function findSalespersons(param) {
    let params = {};
    params = {
        "index": ES_INDEX,
        "collectionid": "1620720221355",
        "showroomid": param.showroom
    };

    return commonService.sendHttpRequest('custom/meetingroomtimeslots', params, "GET", true);
}

function findResourceBookings(param) {
    console.log(param)
    let today = param.date;
    let y = today.getFullYear()
    let m = parseInt(today.getMonth()) + 1;
    m = m < 10 ? '0' + m : m;
    let d = today.getDate();
    let bookedOn = y + '-' + m + '-' + d;

    let params = {};
    params = {
        "index": ES_INDEX,
        "collectionid": ES_INDEX + "10021",
        "host_id": param.hostId,
        "category": "Booking",
        "date": bookedOn
    };


    return commonService.sendHttpRequest('custom/bookinglist', params, "GET", true);
}

function saveResourceBookings(param) {
    console.log(param)


    let params = {};
    params = {
        "index": ES_INDEX,
        "collectionid": "1620712367576",
        "Leadsdata": param.Leadsdata,
        "data": param.data,
        "uid": 0
    };


    return commonService.sendHttpRequest('custom/savebookingslots', params, "POST", true);
}

function getAgentAvailableSlots(param) {

    console.log(param)

    return commonService.sendHttpRequest('custom/agent_availableslots', param, "GET");
}

function getTrovaUserInfo(param) {

    return commonService.sendHttpRequest('custom/scheduleduser', param, "GET");
}

function updateCallStatus(param) {

    return commonService.sendHttpRequest('custom/updatecallstatus', param, "POST");
}

function getScheduleCalls(param) {

    return commonService.sendHttpRequest('custom/scheduledcalls', param, "GET");
}

function saveCallSchedule(param) {

    return commonService.sendHttpRequest('custom/callscheduling', param, "POST");
}

function getUserPassword(param) {

    return commonService.sendHttpRequest('custom/userpassword', param, "POST");
}

function saveTrovaUserProfile(param) {

    return commonService.sendHttpRequest('collection/fieldupdate', param, "POST");
}

function getCollectionData(params) {

    return commonService.sendHttpRequest('collection/searchautofill', params, "POST");

}

function getAllSlots(params) {
    return commonService.sendHttpRequest('custom/listavailableslots', params, "POST");
}

function getAgentLeaves(params) {
    return commonService.sendHttpRequest('custom/listagentleaves', params, "GET");
}

function saveAgentLeave(params) {
    return commonService.sendHttpRequest('custom/saveleaverequest', params, "POST");
}