import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import {getProfile} from '../../utilities/AuthService';
import * as taskActions from "../../actions/ToDo";
import {ES_INDEX, SHARED_LOGIN, USRIMG_PATH} from "../../constants";
import {
    DateFormatter,
    getUrlSegment,
    showLocalTime,
    timeConvert,
    timeConvertToDays
} from "../../utilities/CustomFunctions";
import * as usersActions from "../../actions/Users";
import * as collectionActions from "../../actions/Collections";
import {Tooltip,OverlayTrigger} from "react-bootstrap"
class PrintTaskDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOption: false,
            showremarksModal: false,
            showtimelogModal: false,
            showmarkasdoneModal: false,
            showverifyModal: false,
            showpreviewmodal: false,
            showReopenConfirm: false,
            showReopenModal: false,
            showPreviewFormModal: false,
            startDate: new Date(),
            log: [],
            date: '',
            hours: '',
            mins: '',
            taskid: '',
            rightTab: 'home-tab',
            taskstatus: "",
            action: '',
            timetaken: '',
            timeLogValues: [],
            childid: '',
            creator: '',
            assignee_status: '',
            attach: '',
            donedate: '',
            verified: 'no',
            disable_verify: 'disable',
            disable_markasdone: 'disable',
            doneStatus: '',
            previewIndex: 0,
            previewableAttachments: [],
            showFormModal: false,
            formPages: [],
            taskmode: '',
            fieldsToDisplay: [],
            timelogloading: 0,
            markasdoneloading: 0,
            verifyloading: 0,
            filename: null,
            uploadedFiles: [],
            remark: '',
            reOpenSubmit: false,
            archive_action: '',
            archived: '',
            previewType: '',
            attach_index: '',
            consolidated_time: '',
            assignee: '',
            fileData: [],
            reference: '',
            mark_as_done_comment_array: [],
            markasDoneTemp: false,
            markasdonedata: '',
            verifytaskdata: '',
            reOpenparam: '',
            dateChoosen: false,

        }
        this.showremarksModal = this.showremarksModal.bind(this);
        this.closeremarksModal = this.closeremarksModal.bind(this);
        this.showtimelogModal = this.showtimelogModal.bind(this);
        this.closetimelogModal = this.closetimelogModal.bind(this);
        this.handleinputChange = this.handleinputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addTimelog = this.addTimelog.bind(this);
        this.getrightTabid = this.getrightTabid.bind(this);
        this.addMoretime = this.addMoretime.bind(this);
        this.showmarkasdoneModal = this.showmarkasdoneModal.bind(this);
        this.closemarkasdoneModal = this.closemarkasdoneModal.bind(this);
        this.showverifyModal = this.showverifyModal.bind(this);
        this.showpreviewmodal = this.showpreviewmodal.bind(this);
        this.closepreviewmodal = this.closepreviewmodal.bind(this);
        this.showFormModal = this.showFormModal.bind(this);
        this.hideFormModal = this.hideFormModal.bind(this);
        this.viewParentTask = this.viewParentTask.bind(this);
        this.showReopenModal = this.showReopenModal.bind(this);
        this.OnlineUsers = this.OnlineUsers.bind(this);
        this.hidePreviewForm = this.hidePreviewForm.bind(this);
        this.previewForm = this.previewForm.bind(this);
        this.showReopenConfirm = this.showReopenConfirm.bind(this);
        this.preview = this.preview.bind(this)
        this.closeReopenModal = this.closeReopenModal.bind(this)
        this.dateSwap = this.dateSwap.bind(this)
        this.updatetask = this.updatetask.bind(this)
        this.updatetaskdata = this.updatetaskdata.bind(this)
        this.goBack = this.goBack.bind(this)
        this.timeConvertToDays = this.timeConvertToDays.bind(this)

    }
    componentDidMount() {
        this.setTaskStatuses();
        let params = {
            "_id": atob(getUrlSegment(3)),
            "uid": getProfile().id,
            "systype": getProfile().usertype,
            "index": ES_INDEX,
            "mode": atob(getUrlSegment(4)),
        }
        this.props.getPrintTask(params);
        this.props.gettimelog({taskid: atob(getUrlSegment(3)), uid: getProfile().id});
        let userdata = {
            uid: getProfile().id
        }
        let that = this
        setTimeout(function () {
            that.props.notificationdata(userdata);
        }, 1000)

        let taskData = this.props.taskdetailsData ? this.props.taskdetailsData.taskdetails : '';
        // ////////console.log(taskData.parent.duedate)
        this.setState({
            duedate: new Date(taskData.parent.duedate)
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if (prevProps.postTaskCompletionasync !== this.props.postTaskCompletionasync) {
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            let params = {_id: taskid, systype: "root", uid: getProfile().id, mode: mode};
            this.props.gettaskdetails(params)
        }
        if (this.props.taskdetailsData.taskdetails.parent.masterid !== prevProps.taskdetailsData.taskdetails.parent.masterid) {
            this.setTaskStatuses()
            this.props.modifyTaskTodefault()
        }
        if (this.props.projects.projectData !== prevProps.projects.projectData) {
            this.setState({projectList: this.props.projects.projectData});
        }
        if (this.props.projects.tagData !== prevProps.projects.tagData) {
            this.setState({tagList: this.props.projects.tagData});
        }
        if (this.props.taskSelect.tasksArchived !== prevProps.taskSelect.tasksArchived) {
            var type = atob(getUrlSegment(4));
            var data = {
                category: type,
                status: "All",
                uid: getProfile().id,
                bydue: '',
                priority: this.props.taskFilters.taskPriorityFilter,
                project: this.props.taskFilters.taskProjectFilter,
                asigntype: 'All',
                username: this.props.taskFilters.taskAssignedFilter,
                managetask: 'mytask',
                limit: 0,
                offset: this.state.offset,
                systype: getProfile().usertype,
                actionType: 'LIST_TODO_DATA'
            }
            this.props.todo(data);
            setTimeout(() => {
                if (type === 'to-me') {
                    window.location.href = "/task/todo";
                    //this.props.history.push('/task/todo')
                }
                if (type === 'by-me') {
                    window.location.href = "/task/to-follow-up";
                    //this.props.history.push('/task/to-follow-up')
                }
                if (type === 'to-know') {
                    window.location.href = "/task/to-know";
                    // this.props.history.push('/task/to-know')
                }
                if (type === 'manage-task') {
                    window.location.href = "/task/to-oversee";
                    //this.props.history.push('/task/to-oversee')
                }
            }, 1000);
        }
        if (this.props.timelogDetails !== prevProps.timelogDetails) {
            let tasklogHits = this.props.timelogDetails.timelog.data;
            let ctime = 0;
            if (tasklogHits) {
                for (var i = 0; i < tasklogHits.length; i++) {
                    ctime += parseInt(tasklogHits[i]['consolidated_time']);
                }
            }
            if (ctime > 0) {
                let consolidated_time = (ctime)
                setTimeout(function () {
                    beta.setState({consolidated_time: consolidated_time, timelogloading: 0});
                }, 1000)
                this.closetimelogModal();
                setTimeout(function () {
                }, 1000)
            }
            this.setState({timelogloading: 0, showtimelogModal: false});
        }
        if (this.props.taskSelect.taskDeleted !== prevProps.taskSelect.taskDeleted) {
            var type = 'to-me';
            setTimeout(() => {
                var data = {
                    category: type,
                    status: "All",
                    uid: getProfile().id,
                    bydue: '',
                    priority: this.props.taskFilters.taskPriorityFilter,
                    project: this.props.taskFilters.taskProjectFilter,
                    asigntype: 'All',
                    username: this.props.taskFilters.taskAssignedFilter,
                    managetask: 'mytask',
                    limit: 0,
                    offset: this.state.offset,
                    systype: getProfile().usertype,
                    actionType: 'LIST_TODO_DATA'
                }
                this.props.todo(data);
            }, 500)
            setTimeout(() => {
                this.setState({deleteTask: false})
                this.props.history.push('/task/todo')
            }, 1000);
        }
        if (this.props.taskSelect.dateUpdated !== prevProps.taskSelect.dateUpdated) {
            this.setState({
                dateChoosen: false
            })
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            let params = {_id: taskid, systype: "root", uid: getProfile().id, mode: mode};
            this.props.gettaskdetails(params)
        }
        if (prevProps.HistoryPushData !== this.props.HistoryPushData) {
            this.setState({open: false})
        }
        if (this.props.modifyTask.task_modified !== prevProps.modifyTask.task_modified) {
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            let params = {_id: taskid, systype: getProfile().usertype, uid: getProfile().id, mode: mode};
            this.setState({markasdoneloading: 0, verifyloading: 0, reopenloading: 0});
            this.closemarkasdoneModal();
            this.closeverifyModal();
            this.closeReopenModal();
            this.setTaskStatuses();
            if (this.props.modifyTask.task_type === 'markasdone') {
                //////////console.log(this.state.markasdonedata)
                this.setState({markasDoneTemp: true})
                this.props.TaskCompletionasync(this.state.markasdonedata);
                this.props.gettimelog({taskid: atob(getUrlSegment(3)), uid: getProfile().id});
                let that = this
                setTimeout(() => {
                    var data = {
                        category: "to-me",
                        status: "All",
                        uid: getProfile().id,
                        bydue: '',
                        priority: this.props.taskFilters.taskPriorityFilter,
                        project: this.props.taskFilters.taskProjectFilter,
                        asigntype: 'All',
                        username: this.props.taskFilters.taskAssignedFilter,
                        managetask: 'mytask',
                        limit: 0,
                        offset: this.state.offset,
                        systype: getProfile().usertype,
                        actionType: 'LIST_TODO_DATA'
                    }
                    this.props.todo(data);
                }, 1000)


            }

            if (this.props.modifyTask.task_type === 'verifytask') {
                //   //////////console.log(this.state.markasdonedata)
                // this.setState({markasDoneTemp:true})
                this.props.TaskCompletionasync(this.state.verifytaskdata);
                this.props.gettimelog({taskid: atob(getUrlSegment(3)), uid: getProfile().id});
                let that = this
                setTimeout(() => {
                    var data = {
                        category: "by-me",
                        status: 'Done',
                        uid: getProfile().id,
                        bydue: 'by-me-done',
                        priority: that.props.taskFilters.taskPriorityFilter,
                        project: that.props.taskFilters.taskProjectFilter,
                        asigntype: 'All',
                        username: that.props.taskFilters.taskAssignedFilter,
                        managetask: 'mytask',
                        limit: 0,
                        offset: 30,
                        systype: getProfile().usertype,
                        actionType: 'LIST_TO_VERIFY_DATA'
                    }
                    that.props.toVerify(data);
                }, 1000)
            }
            if (this.props.modifyTask.task_type === 'reopentask') {
                this.props.gettimelog({taskid: atob(getUrlSegment(3)), uid: getProfile().id});
            }


        }
        if (this.props.reassigntask !== prevProps.reassigntask) {
            this.setState({open: false, showOption: false})
            this.props.history.push("/task/details/" + btoa(this.props.reassigntask.results[0].masterid) + "/" + getUrlSegment(4))
        }

    }

    timeConvertToDays(totalminutes) {
        var exactdays = totalminutes / (60 * 24);
        var days = parseInt(exactdays);
        var remaininghours = totalminutes % (60 * 24);
        var exacthours = remaininghours / 60;
        var hours = parseInt(exacthours);
        var exactminutes = remaininghours % 60;
        var minutes = parseInt(exactminutes);
        var totaltime = '';
        if (days > 0) {
            totaltime = days + " Day" + (days > 1 ? "s " : ' ');
        }
        if (hours > 0) {
            totaltime += hours + " Hr" + (hours > 1 ? "s " : ' ');
        }
        if (minutes > 0) {
            totaltime += minutes + " Min" + (minutes > 1 ? "s " : ' ');
        }

        return totaltime;
    }

    preview(url, name) {
        this.setState({url: url, name: name, previewStatus: !this.state.previewStatus})
    }

    setTaskStatuses() {
        let parent = this.props.taskdetailsData.taskdetails.parent;
        if (parent) {
            if (parent.reassign === 'yes') {
                let uid = ''
                if (parent.createuser !== getProfile().id) {
                    uid = getProfile().id
                }
                this.loadmoreData({taskid: parent.masterid, uid: uid});
            }
            let tasktype = parent.tasktype;
            this.setState({
                tasktype: tasktype,
                taskmode: (parent.createuser === getProfile().id) ? 'by-me' : '',
                disable_verify: (parent.status === 'Done') ? '' : 'disable',
                taskid: parent.masterid,
                verified: parent.verified ? parent.verified : 'no',
                archived: (parent.createuser === getProfile().id && parent.archive === 'yes') ? 'yes' : 'no'
            })
        }

        let assignees = this.props.taskdetailsData.taskdetails.assignees;
        assignees = assignees.filter(
            user => user.assignee.search(getProfile().id) >= 0
        );
        //////////console.log(getProfile().id)
        //////////console.log(assignees)


        if (assignees.length > 0) {

            let assigneestatus = assignees[0].status;
            let time = assignees[0].consolidated_time;
            let shared = assignees[0].share;
            let donedate = assignees[0].donedate;
            let archived = assignees[0].archive;
            this.setState({
                assignee_id: assignees[0].assignee,
                childid: assignees[0].childid,
                assignee_status: assigneestatus,
                shared: shared,
                consolidated_time: time,
                donedate: donedate,
                archived: archived
            })

            ////////////console.log(this.state.consolidated_time)
        }

    }

    showOptions() {
        this.setState({showOption: !this.state.showOption});
    }

    showremarksModal(mark_as_done_comment, reference) {
        this.setState({
            showremarksModal: !this.state.showremarksModal,
            mark_as_done_comment_array: mark_as_done_comment
        });

        // this.props.getremarks(getProfile().id,taskid,reference);

    }

    closeremarksModal() {
        this.setState({
            showremarksModal: false,
            mark_as_done_comment_array: []
        });
    }

    showtimelogModal(id) {
        let assignees = this.props.taskdetailsData.taskdetails.assignees;
        assignees = assignees.filter(
            user => user.assignee.search(getProfile().id) >= 0
        )
        let assigneestatus = assignees[0].status;

        this.setState({
            showtimelogModal: !this.state.showtimelogModal,
            assignee_status: assigneestatus
        });
    }

    closetimelogModal() {
        this.setState({showtimelogModal: false})
    }

    showmarkasdoneModal(id, createuser, reference) {
        this.setState({
            showmarkasdoneModal: !this.state.showmarkasdoneModal,
            action: '',
            reference: reference,
            creator: createuser,
            uploadedFiles: []
        });
        // this.props.gettimelog(id,getProfile().id);
    }

    closemarkasdoneModal() {
        this.setState({
            showmarkasdoneModal: false,
        });
    }

    showverifyModal(id, reference) {
        this.setState({showverifyModal: true, uploadedFiles: [], reference: reference})
    }

    closeverifyModal() {
        this.setState({showverifyModal: false})
    }

    showFormModal(collectionid, formName) {
        this.props.showform({_id: collectionid});
        this.setState({
            formName: formName,
            collectionid: collectionid,
            showFormModal: true
        })
    }

    hideFormModal(id) {
        this.setState({
            showFormModal: false
        })
    }

    previewForm(id, collectionid) {
        this.props.getFormData({_id: id, collectionid: collectionid});
        this.setState({
            collectionid: collectionid,
            showPreviewFormModal: true
        })
    }

    hidePreviewForm() {
        this.setState({
            showPreviewFormModal: false
        })
    }

    showReopenConfirm(id, mainTask, assignee) {
        this.setState({
            taskIdToReopen: id,
            assignee: assignee,
            showReopenConfirm: !this.state.showReopenConfirm,
            mainTask: mainTask
        });
    }

    showReopenModal() {
        this.setState({showReopenConfirm: false, showReopenModal: true, uploadedFiles: []});
    }

    closeReopenModal() {
        this.setState({showReopenModal: false});
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleinputChange(e) {
        this.setState({[e.target.name]: e.target.value})

        if (e.target.value !== '') {
            e.target.classList.remove('val-error');

        }
    }

    saveDiscussion(taskid, childid, reference) {
        let has_attachment = (this.state.uploadedFiles.length > 0) ? "yes" : "no";
        let savediscussiondata = {
            todoid: taskid,
            discussion: this.state.remark,
            reference: reference,
            has_attachment: has_attachment,
            uid: getProfile().id,
            childid: childid,
            filerelator: ""
        };
        this.props.savediscussion(savediscussiondata);
    }

    getrightTabid(tab) {
        this.setState({rightTab: tab})
    }

    addMoretime() {
        this.setState({action: 'addtime'})
    }

    loadmoreData = (params) => {
        this.props.getmoretaskdetails(params)
    }

    showpreviewmodal(index, previewType) {
        this.setState({
            showpreviewmodal: !this.state.showpreviewmodal,
            previewType: previewType,
            attach_index: index
        })
    }

    closepreviewmodal() {
        this.setState({
            showpreviewmodal: false,
        })
    }

    getTimeLogEntries(timeLogValues) {
        this.setState({timeLogValues: timeLogValues});

    }

    addTimelog(saveLog) {
        if (saveLog) {
            this.setState({timelogloading: 1});
        }
        let timeLogValues = [...this.state.timeLogValues];
        ////////////console.log(timeLogValues)

        let invalid = 0;
        if (timeLogValues.length > 0) {
            for (var i = 0; i < timeLogValues.length; i++) {

                // if(timeLogValues[i]['rawdate'] === '') {
                //     document.getElementById('date'+i).classList.add('val-error');
                //     invalid++;
                // }
                if (timeLogValues[i]['rawdate'] === '') {
                    document.getElementById('date' + i).classList.add('val-error');
                    invalid++;
                } else {
                    var el = document.getElementById("date" + i);
                    if (el.classList.contains("val-error")) {
                        el.classList.remove("val-error");
                    }
                }

                if ((timeLogValues[i]['hour'] === '') && timeLogValues[i]['min'] === '') {
                    document.getElementById('hour' + i).classList.add('val-error');
                    document.getElementById('min' + i).classList.add('val-error');
                    invalid++;
                } else {
                    var el = document.getElementById('hour' + i);
                    var e2 = document.getElementById('min' + i);
                    if (el.classList.contains("val-error"))
                        el.classList.remove("val-error");
                    if (e2.classList.contains("val-error"))
                        e2.classList.remove("val-error");
                }

                if (timeLogValues[i]['hour'] !== '' && parseInt(timeLogValues[i]['hour']) > 23) {
                    timeLogValues[i]['hour'] = 23;
                }
                if (timeLogValues[i]['min'] !== '' && parseInt(timeLogValues[i]['min']) > 59) {
                    timeLogValues[i]['min'] = 59;
                }
            }

            if (saveLog) {
                if (invalid === 0) {
                    this.props.addtimelog({
                        uid: getProfile().id,
                        taskid: this.state.taskid,
                        logtime: JSON.stringify(timeLogValues)
                    });
                    this.setState({timeLogValues: []})
                    //this.setState({timeLogValues:JSON.stringify(timeLogValues)})
                } else {
                    this.setState({timelogloading: 0});
                }
            } else {
                return invalid;
            }

        } else {
            this.setState({timelogloading: 0});
            document.getElementById('hour0').classList.add('val-error');
            document.getElementById('min0').classList.add('val-error');
            document.getElementById('date0').classList.add('val-error');

        }
    }


    viewParentTask(id) {
        this.props.history.push('/task/details/' + btoa(id) + '/' + btoa('to-me'))
    }

    OnlineUsers(val) {
        var OnlineStatus = this.props.onlineusers.users.filter(p => val === p.User_ID);
        if (OnlineStatus.length !== 0)
            return true;
        else
            return false;
    }

    handleDateChange(date) {
        ////////////console.log(date)
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD HH:mm:ss');
        ////////////console.log(formattedDate);
        this.setState({
            duedate: date,
            formattedDate: formattedDate
        })
    }

    dateSwap = (dateChosen) => {
        this.setState({
            dateChoosen: !this.state.dateChoosen
        })
    }

    updatetask(date) {
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            duedate: date,
            formattedDate: formattedDate
        })

    }

    updatetaskdata(dateChoosen) {

        this.setState({
            dateChoosen: dateChoosen
        })
        let taskData = this.props.taskdetailsData.taskdetails;
        let taskid = taskData ? taskData.parent.masterid : atob(getUrlSegment(3));
        let params = {
            "taskid": taskid,
            "uid": getProfile().id,
            "duedate": this.state.duedate,
            "index": ES_INDEX
        }
        this.props.dateupdatetask(params)
    }

    goBack() {
        if ((getUrlSegment(5) !== undefined) || getUrlSegment(6) !== undefined) {
            if (atob(getUrlSegment(5) !== "project")) {
                window.location.href = SHARED_LOGIN.redirect_url + "Project/details/" + atob(getUrlSegment(6)) + "#proTask"
            } else {
                this.props.history.goBack();
            }
        } else
            this.props.history.goBack();
    }


    render() {
        ////////console.log(this.props.Getprinttask)
        let printDetailData = this.props.Getprinttask.results ;
        // console.log(printDetailData)
        let task = printDetailData.task ;
        let subtask = printDetailData.subtask ;
        let assignees = printDetailData.assignees;
        let discussion = printDetailData.discussion;
        let activities = printDetailData.activities;
        let timelog = printDetailData.timelog ;

        return (

            <div className={`print-holder`}>
                {task.length !== 0 &&
                <div className="task-detail-panel">
                    <div className="task-details-header-block">
                        <div className="task-detail-header-laeft">
                            <button className="task-mob-back"></button>
                            <ul className="task-details-info-wrap">
                                <li><p>DUE ON </p>
                                    <h5>{task.duedate !== "0000-00-00 00:00:00" ? moment.utc(task.duedate).local().format('DD MMM YYYY hh:mm a').toUpperCase() : ''}</h5>
                                </li>
                                <li><p>PRIORITY</p><h5>{task.priority.toUpperCase()}</h5></li>
                                {task.projectname !== '' &&
                                <>
                                    <li><p>PROJECT</p><h5>{task.projectname.toUpperCase()}</h5></li>
                                </>
                                }
                            </ul>

                        </div>
                        <div className="task-detail-header-right">
                            <div className="due-date-block">
                                {task.status === "Done" &&
                                <React.Fragment>
                                    <p>COMPLETED ON <span>
                                            {moment.utc(task.donedate).local().format('DD MMM YYYY hh:mm a').toUpperCase()} </span>
                                    </p>
                                </React.Fragment>
                                }
                            </div>

                            <div className="task-other-opt">
                                {task.status === "Done" &&
                                    <div className="comp-task">COMPLETED TASK</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="task-detail-panel-content"><span className=" ico-lib cleared  done-mark"></span>
                        <div className="task-detail-user-info">
                            <div className="task-detail-user-info-left">
                                <div className="task-detail-user">
                                    <div
                                        className={`task-detail-user-thumb ${this.OnlineUsers(task.createuser) ? 'online' : ''}`}>
                                        <img src={USRIMG_PATH + task.createuser_img_name}/>
                                    </div>
                                    <div className="task-detail-user-det">
                                        <h5>{task.createuser_name}</h5>
                                        <p>{task.createuser_designation}</p>
                                    </div>
                                    <div className="assign-line">
                                        <span className="ico-lib to-ico"></span>
                                        <div className="assigned-tau-block single-user">
                                            {assignees.map((item, index) =>

                                                <React.Fragment key={`assignee-data-panel` + index}>
                                                    {item.share === "no" &&
                                                    <div className="tau" data-toggle="tooltip"
                                                         data-placement="top"
                                                         title="" data-original-title={item.user_name}
                                                         key={"assig-" + index}>
                                                        <OverlayTrigger
                                                            key={`1`}
                                                            placement={`top`}
                                                            overlay={

                                                                <Tooltip id={`tooltip-top`} className={'test'}>
                                                                    {item.user_name}
                                                                </Tooltip>
                                                            }>
                                                                        <span className="tau-img">
                                                                            <img
                                                                                src={USRIMG_PATH + item.user_img_name}/>
                                                                        </span>
                                                        </OverlayTrigger>
                                                        <span
                                                            className="tau-name">{item.user_name}</span>

                                                    </div>
                                                    }
                                                </React.Fragment>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="task-detail-user-info-right">
                                {task.duedate !== "0000-00-00 00:00:00" &&
                                <div className="due-date-block">
                                    <p>
                                        <span>{moment.utc(task.createdate).local().format('DD MMM YYYY   |   hh:mm a')}</span>
                                    </p>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="task-detail-panel-content-area">
                            <div className="task-detail-panel-content-area-left">
                                <h5>{decodeURIComponent(escape(atob(task.subject)))}</h5>
                                <p dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(atob(task.Title))).replace(/\n/g, '<br />')}}/>
                            </div>
                            <div className="due-date-block">
                                {task.status === "Done" &&
                                <React.Fragment>
                                    <p>COMPLETED ON </p>
                                    <p className="f-b">
                                        {moment.utc(task.donedate).local().format('DD MMM YYYY hh:mm a')}
                                    </p>
                                </React.Fragment>
                                }
                            </div>
                        </div>
                        <div className="task-submit-block">
                            <div className="working-time-section"></div>
                        </div>
                    </div>

                </div>
                }

                {subtask.length !== 0 &&
                <React.Fragment>
                    <div className="subheader-task">
                        <p>Sub Tasks</p>
                    </div>
                    {subtask.map((listitem, index) =>
                        <div className="subtask-detail-panel" key={index}>

                            <div className="task-detail-panel" key={`subtask` + index}>
                                <div className="task-detail-panel-content">

                                    <div className="task-detail-user-info">
                                        <div className="task-detail-user-info-left">
                                            <div className="task-detail-user">
                                                <div className="task-detail-user-det">
                                                    <h5>{listitem.creator_name}</h5>
                                                    <div className="assign-line">
                                                        <span className="ico-lib-v1 to-sub-ico"></span>
                                                        {listitem.assignees.map((assignee, index) =>
                                                            <div className="assigned-tau-block single-user"
                                                                 key={'assignee' + assignee}>
                                                                <div className="tau">
                                                                        <span className="tau-img">
                                                                            <img
                                                                                src={USRIMG_PATH + assignee.assignee_img_name}/>
                                                                        </span>
                                                                    <span
                                                                        className="tau-name">{assignee.assignee_name}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-detail-user-info-right">
                                            <div className="due-date-block">
                                                <p>DUE ON</p>
                                                <p className="f-b">{moment(listitem.duedate).format('DD MMM YYYY hh:mm a')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="task-detail-panel-content-area">
                                        <h5>{atob(listitem.subject)}</h5>
                                        <p>{atob(listitem.title)}</p>
                                    </div>
                                </div>
                                <div className="individual-assigners">
                                    {listitem.assignees.map((key, index) =>
                                        <div className="ind-div-assign" key={index}>
                                            <div className="info-contianer">
                                                <div className="task-detail-user-info-left">
                                                    <div className="task-detail-user-thumb">
                                                        <img src={USRIMG_PATH + key.assignee_img_name}/>
                                                    </div>
                                                    <div className="task-detail-user-det">
                                                        <h5>{key.assignee_name}</h5>
                                                        <p>{key.status}</p>
                                                    </div>
                                                </div>
                                                {key.status === "Done" &&
                                                <div className="task-detail-user-info-right">
                                                    <div className="due-date-block">
                                                        <p>COMPLETED ON </p>
                                                        <p className="f-b">
                                                            {DateFormatter(key.donedate,false,true)}
                                                        </p>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>

                }
                {(assignees.filter(listitem=>listitem.mark_as_done_comment !== undefined && listitem.mark_as_done_comment !== null && listitem.mark_as_done_comment.length).length  !==0) &&
                <div className="subheader-task">
                    <p>Completion Remarks</p>
                </div>
                }
                <div className="completion-remarks-panel">
                    {assignees.map((listitem, index) =>
                         listitem.mark_as_done_comment !== undefined && listitem.mark_as_done_comment !== null && listitem.mark_as_done_comment.length !== 0 &&

                            <div className="task-detail-panel" key={index}>
                                <div className="task-detail-panel-content">
                                    <div className="task-detail-user-info">
                                        <div className="task-detail-user-info-left">
                                            <div className="task-detail-user">
                                                <div
                                                    className={`task-detail-user-thumb ${this.OnlineUsers(listitem.user_name) ? 'online' : ''}`}>
                                                    <img src={USRIMG_PATH + listitem.user_img_name}/>
                                                </div>
                                                <div className="task-detail-user-det">
                                                    <h5>{listitem.user_name}</h5>
                                                    {listitem.status === 'done' &&
                                                    <p>
                                                        <div className={`info-task`}>Completed
                                                            on <span>{moment.utc(listitem.donedate).local().format('DD MMM YYYY hh:mm a')}</span>
                                                        </div>
                                                        <div className={`info-task`}>Time
                                                            Taken <span>{timeConvertToDays(listitem.consolidated_time)}</span>
                                                        </div>
                                                    </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="task-detail-user-info-right">*/}
                                        {/*    <div className="due-date-block">*/}
                                        {/*        <p>DUE ON</p>*/}
                                        {/*        <p className="f-b">{moment(listitem.duedate).format('DD MMM YYYY hh:mm a')}</p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    {listitem.mark_as_done_comment !== undefined && listitem.mark_as_done_comment.map((item, index) =>
                                        <div className='remarks-list' key={`mark-as-done-comment` + index}>
                                            <p>{atob(item.discussion)}</p>

                                        </div>
                                    )}
                                </div>

                            </div>

                    )}
                </div>
                {assignees.length !== 0 &&
                <div className="subheader-task">
                    <p>Users</p>
                </div>
                    }
                <div className="user-detail-panel">
                    <div className="task-detail-panel">
                        <div className="task-detail-panel-content">
                            <div className="task-detail-user-info">
                                <div className="task-detail-user-info-left">
                                    <div className="task-detail-user">
                                        {assignees.filter(item => (item.share === 'no')).length !== 0 &&
                                        <React.Fragment>
                                            <div className="user-tab-head">
                                                <h5> {`Assigned users`}</h5>
                                            </div>
                                            <div className="discuss-inner">
                                                {assignees.filter(item => (item.share === 'no')).map((item, index) =>
                                                    <React.Fragment key={"assignee-tab-" + index}>
                                                        <div className="assigned-block">
                                                            <div className="ind-assigned-user-thumb">
                                                                <a >
                                                                    <img title={item.user_name}
                                                                         src={USRIMG_PATH + item.user_img_name}
                                                                         alt=""/></a>
                                                            </div>
                                                            <div className="ind-assigned-desc">
                                                                <h5>{item.user_name}</h5>
                                                                <p>{item.user_designation}</p>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}


                                            </div>
                                        </React.Fragment>
                                        }

                                        {assignees.filter(item => (item.share === 'yes')).length !== 0 &&
                                        <React.Fragment>
                                            <div className="user-tab-head">
                                                <h5> {`Invited users`}</h5>
                                            </div>
                                            <div className="discuss-inner">
                                                {assignees.filter(item => (item.share === 'yes')).map((item, index) =>
                                                    <React.Fragment key={"assignee-tab-" + index}>
                                                        <div className="assigned-block">
                                                            <div className="ind-assigned-user-thumb">
                                                                <a href="javascript:void(0)">
                                                                    <img title={item.user_name}
                                                                         src={USRIMG_PATH + item.user_img_name}
                                                                         alt=""/></a>
                                                            </div>
                                                            <div className="ind-assigned-desc">
                                                                <h5>{item.user_name}</h5>
                                                                <p>{item.user_designation}</p>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}


                                            </div>
                                        </React.Fragment>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {discussion.length !== 0 &&
                <div className="subheader-task">
                    <p>Discussions</p>
                </div>
                }
                <div className="discussion-detail-panel">
                    <div className="task-detail-panel">
                        <div className="task-detail-panel-content">
                            <div className="task-detail-user-info">
                                <div className="task-detail-user-info-left">
                                    <div className="task-detail-user">
                                        <div className="discuss">
                                            {discussion.map((item, index) =>
                                                <div className="message-in-box " key={index}>
                                                    <figure className="user-image-holder"><img
                                                        src={USRIMG_PATH + item.userhead}
                                                        alt="user-image" className="chat-user-image-in"/></figure>
                                                    <div className="chat-message-detailfalse"><h5
                                                        className="chat-user-name">{item.creator}</h5><span
                                                        className="chat-time-in">{moment.utc(item.crtdate).local().format("DD-MM-YYYY")}</span>
                                                        <p
                                                            className="chat-message-in">{atob(item.discussion)}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {activities.length !== 0 &&
                <div className="subheader-task">
                    <p>Activities</p>
                </div>
                }
                <div className="activities-detail-panel">
                    <div className="task-detail-panel">
                        <div className="task-detail-panel-content">
                            <div className="task-detail-user-info">
                                <div className="task-detail-user-info-left">
                                    <div className="task-detail-user">

                                        <div className="discuss">
                                            {activities.map((item, index) =>
                                                <div className="ind-activities-log" key={index}>
                                                    <div className="activities-log-left"><img
                                                        src={USRIMG_PATH + item.img}/>
                                                    </div>
                                                    <div className="activities-log-right">
                                                        <div className="activities-log-right-top">
                                                            <p> {showLocalTime(item.crtdate)}</p><h5><span
                                                            className="user-highlight">{item.creator}</span> {item.activity}
                                                        </h5>
                                                        </div>
                                                        {item.activity === "Comment added"&&
                                                        <div className="activities-log-right-box">
                                                            <p>{moment.utc(item.crtdate).local().format("DD-MM-YYYY")}</p>
                                                            <h5>{(item.content !== undefined?atob(item.content):'')}</h5>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {timelog.length !== 0 &&
                <div className="subheader-task">
                    <p>Time Logged</p>
                </div>
                }
                {timelog.length !== 0 &&
                <div className="log-detail-panel">
                    <div className="task-detail-panel">

                        <div className="form-table coll-full-height">
                            <table id="a" className="table data-table">
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                {timelog.map((item, index) =>
                                    <tr key={index}>
                                        <td>
                                            <div className="task-detail-user-info-left">
                                                <div className="task-detail-user-thumb"><img
                                                    src={USRIMG_PATH + item.assignee_img_name}/>
                                                </div>
                                                <div className="task-detail-user-det">
                                                    <h5>{item.assigneeName}</h5>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {item.logs.map((list, index) =>
                                                <p className="text-overflow">{DateFormatter(list.log_date, false, true)}</p>
                                            )}
                                        </td>
                                        <td>
                                            {item.logs.map((list, index) => {
                                                let timeLog=parseInt(list.log_hours !==""?(list.log_hours * 60):0) + parseInt(list.log_mins !==""?list.log_mins:0)

                                                return <p className="text-overflow">{timeConvert(timeLog)} (HR : MIN)</p>
                                                }
                                            )}
                                            <p className="text-overflow">Total <span>{timeConvertToDays(item.totaltime)} </span>
                                            </p>
                                        </td>

                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>
                }


            </div>

        );
    }


}
const mapStateToProps = state => ({
    taskdetailsData : state.TaskReducer.TaskDetails,
    moretaskDetailsdata  : state.TaskReducer.MoretaskDetails,
    timelogDetails : state.TaskReducer.Gettimelog,
    remarksData : state.TaskReducer.GetRemarks,
    projects:state.TaskReducer.GetProjectData,
    taskSelect:state.TaskReducer.getTaskSelection,
    modifyTask:state.TaskReducer.ModifyTask,
    taskFilters:state.TaskReducer.filterTasks,
    onlineusers:state.CommonReducer.onlineusers,
    markasdonestatus:state.TaskReducer.MarkAsDoneStatus,
    reassigntask:state.TaskReducer.ReassignTask,
    multiLoader:state.CommonReducer.multiLoader,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    Getprinttask:state.TaskReducer.getPrinttask

});

export default  withRouter(connect(mapStateToProps, {
    toVerify:taskActions.listtask,
    gettaskdetails:taskActions.taskdetails,
    gettaskdetailsAsync:taskActions.gettaskdetailsAsync,
    getmoretaskdetails:taskActions.moretaskdetails,
    markasdone :taskActions.markasdone,
    verifytask :taskActions.verify,
    addtimelog :taskActions.addtimelog,
    gettimelog:taskActions.gettimelog,
    getremarks:taskActions.getremarks,
    savediscussion :taskActions.savediscussion,
    users:usersActions.listUsers,
    showform : collectionActions.showform,
    getFormData : collectionActions.getFormData,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    moveTasksToArchive : taskActions.moveTasksToArchive,
    deleteTaskPermanent : taskActions.deleteTaskPermanent,
    saveToDoAttachments : taskActions.saveToDoAttachments,
    reopenTask:taskActions.reopenTask,
    reopenAllTask : taskActions.reopenAllTask,
    todo:taskActions.listtask,
    getTaskHelper : taskActions.getTaskHelper,
    TaskCompletionasync:taskActions.postTaskCompletionasync,
    notificationdata:usersActions.notificationInfo,
    dateupdatetask: taskActions.dateupdatetask,
    getPrintTask: taskActions.getprinttask

})(PrintTaskDetail));






