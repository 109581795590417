import  rootService from "../services/index";
import {getProfile} from "../utilities/AuthService";
import {urlMetaData} from "../utilities/CustomFunctions"
import {SHARED_LOGIN} from "../constants/index";
import moment from 'moment'

const ListAllUsers = (userid, usertype) => dispatch => {
    let listallusers = rootService.chat.listAllUsers(userid, usertype);
    listallusers.then(listallusers => {
        dispatch({
            type: 'LIST_All_USERS',
            listallusers: listallusers,
        })
    })
};

const listAllGroups = (uid, limit, offset, index) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'sidebarUpdate',
        status:true

    })
    let listallgroups = rootService.chat.listAllGroups(uid, limit, offset, index);
    listallgroups.then(listallgroups => {
        dispatch({
            type: 'LIST_All_GROUPS',
            listallgroups: listallgroups,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'sidebarUpdate',
            status:false

        })
    })
};

const listChatHistory = (sender, receiver, limit, offset, index, type, name, designation, image) => dispatch => {

    dispatch({
        type: 'CHAT_RECEIVER_USERID',
        reeiveruserid: receiver,
        chatType: type,
        name: name,
        designation: designation,
        image: image

    })
    dispatch({
        type: 'CHAT_HISTORY_RESET'

    })
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'chatContainer',
        status:true

    })
    if (type === 'single') {
        let listchathistory = rootService.chat.listChatHistory(sender, receiver, limit, offset, index);
        listchathistory.then(listchathistory => {
            console.log(listchathistory)
            dispatch({
                type: 'LIST_All_CHAT_HISTORY',
                listchathistory: listchathistory,
            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: '',
                status:false

            })
        })
    }
    else {
        let listchathistory = rootService.chat.listGroupChat(sender, receiver, limit, offset, index);
        listchathistory.then(listchathistory => {
            dispatch({
                type: 'LIST_All_CHAT_HISTORY',
                listchathistory: listchathistory,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: '',
                status:false

            })
        })
    }
};


const updateChatHistory = (sender, receiver, limit, offset, type) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'chatContainer',
        status:true

    })

    if (type === 'single') {
        let listchathistory = rootService.chat.listChatHistory(sender, receiver, limit, offset);
        listchathistory.then(listchathistory => {
            dispatch({
                type: 'LOADMORE_CHAT_HISTORY',
                listchathistory: listchathistory,
            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: '',
                status:false

            })
        })
    }
    else {
        let listchathistory = rootService.chat.listGroupChat(sender, receiver, limit, offset);
        listchathistory.then(listchathistory => {
            dispatch({
                type: 'LOADMORE_CHAT_HISTORY',
                listchathistory: listchathistory,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: '',
                status:false

            })
        })
    }
};

const saveChatData = (sender, receiver, replyto, message, has_attachment, index, has_metaurl, socket, metaid, attachment,has_task,task_content,taskid) => dispatch => {

    var metainfo = []

    if (!has_metaurl && has_attachment === 'no'&& has_task !== 'yes') {
        var user = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'no',
            has_metaurl: false,
            reply_task:"no",
            has_task:'no',
            metainfo: [],
            attachment: [],
            toasterdata: getProfile().first_name,
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            type: 'single',
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            taskid:taskid
        }
        var chatData = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'no',
            has_metaurl: false,
            reply_task:"no",
            has_task:'no',
            metainfo: [],
            attachment: [],
            toasterdata: getProfile().first_name,
            chat_id: Math.round(new Date().getTime() + (Math.random() * 100)).toString(),
            concat: "",
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            has_reply: "no",
            readstatus: "unread",
            receiver: receiver,
            relation: "",
            reply: [],
            replyto: replyto,
            sender: sender,
            taskid:taskid,

        }
        dispatch({
            type: 'UPDATE_CHAT_HISTORY',
            data: chatData
        })
        let savechatdata = rootService.chat.saveChatData(sender, receiver, replyto, message, "no", index, false, metainfo,taskid);
        savechatdata.then(savechatdata => {
            ////////////console.log(savechatdata)
            dispatch({
                type: 'SAVE_CHAT_DATA',
                savechatdata: savechatdata,
                messageData: user
            })


        })
        socket.emit('bsynapse_chatting', message, user, receiver, 'web',index);
    }
    else if (has_metaurl) {
        var _id= Math.round(new Date().getTime() + (Math.random() * 100));
        var chatData = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'no',
            has_metaurl: true,
            reply_task:"no",
            has_task:'no',
            metainfo: [],
            attachment: [],
            toasterdata: getProfile().first_name,
            chat_id: _id,
            concat: "",
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            has_reply: "no",
            readstatus: "unread",
            receiver: receiver,
            relation: "",
            reply: [],
            replyto: replyto,
            sender: sender,
        }
        dispatch({
            type: 'UPDATE_CHAT_HISTORY',
            data: chatData
        })
        var link_array = [];
        var j = 0;
        var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        message.replace(urlRegex, function (url) {
            link_array.push(url);
        });
        if(link_array.length === 0){
            let savechatdata = rootService.chat.saveChatData(sender, receiver, replyto, message, has_attachment, index, true, []);
            savechatdata.then(savechatdata => {


            })
            var user = {
                id: getProfile().id,
                username: getProfile().first_name,
                userhead: getProfile().image,
                message: btoa(unescape(encodeURIComponent(message))),
                has_attachment: 'no',
                has_metaurl: true,
                reply_task:"no",
                has_task:'no',
                metainfo: [],
                attachment: [],
                toasterdata: getProfile().first_name,
                createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                creator: getProfile().first_name + ' ' + getProfile().last_name,
                type: 'single'
            }
            socket.emit('bsynapse_chatting', message, user, receiver, 'web', index);


        }
        for (var i = 0; i < link_array.length; i++) {
            let urlData = rootService.chat.urlData(link_array[i]);
            urlData.then(urlData => {
                var metainfo1 = urlMetaData(urlData.data.data, metaid, message)
                metainfo.push({
                    "siteName": metainfo1[0].siteName,
                    "pageUrl": metainfo1[0].pageUrl,
                    "pageTitle": metainfo1[0].pageTitle,
                    "pageUpdated": metainfo1[0].pageUpdated,
                    "pageDescription": metainfo1[0].pageDescription,
                    "pageImage": metainfo1[0].pageImage,
                    "metaid": metainfo1[0].metaid
                });
                j++;
                if (link_array.length === j) {
                    var user = {
                        id: getProfile().id,
                        username: getProfile().first_name,
                        userhead: getProfile().image,
                        message: btoa(unescape(encodeURIComponent(message))),
                        has_attachment: 'no',
                        has_metaurl: true,
                        reply_task:"no",
                        has_task:'no',
                        metainfo: metainfo,
                        attachment: [],
                        toasterdata: getProfile().first_name,
                        createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        creator: getProfile().first_name + ' ' + getProfile().last_name,
                        type: 'single'
                    }
                    var chatData = {
                        metainfo: metainfo,
                    }
                    dispatch({
                        type: 'UPDATE_METAURL_DATA',
                        metaData: chatData,
                        chatid:_id
                    })
                    socket.emit('bsynapse_chatting', message, user, receiver, 'web', index);
                    let savechatdata = rootService.chat.saveChatData(sender, receiver, replyto, message, has_attachment, index, true, metainfo);
                    savechatdata.then(savechatdata => {
                        dispatch({
                            type: 'SAVE_CHAT_DATA',
                            savechatdata: savechatdata,
                            messageData: user
                        })

                    })
                }
            })
        }

    }
    else if (has_attachment === 'yes') {
        console.log(attachment[0])
        for (i = 0; i < (attachment.length ); i++) {
            attachment[i].approved = ""
            attachment[i].creatorid = getProfile().id
            attachment[i].id = getProfile().id
        }


        var user = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'yes',
            has_metaurl: false,
            reply_task:"no",
            has_task:'no',
            metainfo: [],
            attachment: attachment,
            toasterdata: getProfile().first_name,
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            type: 'single',
            creator: getProfile().first_name + ' ' + getProfile().last_name,
        }
        var chatData = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'yes',
            has_metaurl: false,
            reply_task:"no",
            has_task:'no',
            metainfo: [],
            attachment: attachment,
            toasterdata: getProfile().first_name,
            chat_id: "",
            concat: "",
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            has_reply: "no",
            readstatus: "unread",
            receiver: receiver,
            relation: "",
            reply: [],
            replyto: replyto,
            sender: sender,
        }
        dispatch({
            type: 'UPDATE_CHAT_HISTORY',
            data: chatData
        })
        let savechatdata = rootService.chat.saveChatData(sender, receiver, replyto, message, has_attachment, index, has_metaurl, metainfo);
            savechatdata.then(savechatdata => {
            let saveattachments = rootService.chat.saveattachments(savechatdata.data.results.id, sender, btoa(JSON.stringify(attachment)), index);
            saveattachments.then(saveattachments => {
                dispatch({
                    type: 'SAVE_CHAT_DATA',
                    savechatdata: saveattachments,
                    messageData: user
                })

            })
        })
        socket.emit('bsynapse_chatting', message, user, receiver, 'web', index);
    }
    else if (has_task === 'yes') {
        var user = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'no',
            has_metaurl: false,
            reply_task:"no",
            has_task:'yes',
            metainfo: [],
            attachment: [],
            toasterdata: getProfile().first_name,
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            type: 'single',
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            "task_content":task_content
        }
        var chatData = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'no',
            has_metaurl: false,
            reply_task:"no",
            has_task:'yes',
            metainfo: [],
            attachment: attachment,
            toasterdata: getProfile().first_name,
            chat_id: Math.round(new Date().getTime() + (Math.random() * 100)).toString(),
            concat: "",
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            has_reply: "no",
            readstatus: "unread",
            receiver: receiver,
            relation: "",
            reply: [],
            replyto: replyto,
            sender: sender,
            task_content:task_content,
            taskid:taskid
        }
        dispatch({
            type: 'UPDATE_CHAT_HISTORY',
            data: chatData
        })
        ////////console.log(attachment)
        let savechatdata = rootService.chat.saveChatData(sender, receiver, replyto, message, has_attachment, index, has_metaurl, metainfo,has_task,task_content,taskid);
        savechatdata.then(savechatdata => {
            ////////////console.log(savechatdata.data.results.id)
            let saveattachments = rootService.chat.saveattachments(savechatdata.data.results.id, sender, btoa(JSON.stringify(attachment)), index);
            saveattachments.then(saveattachments => {
                // ////////////console.log(saveattachments.data.results.id)
                dispatch({
                    type: 'SAVE_CHAT_DATA',
                    savechatdata: saveattachments,
                    messageData: user
                })

            })
        })
        socket.emit('bsynapse_chatting', message, user, receiver, 'web', index);
    }
};

const groupChat = (groupid, limit, offset, index) => dispatch => {
    let Groupchat = rootService.chat.listGroupChat(groupid, limit, offset, index);
    Groupchat.then(Groupchat => {
        dispatch({
            type: 'LIST_ALL_GROUP_CHAT_DATA',
            GroupChat: Groupchat,

        })
    })
};

const saveGroupChatData = (sender, receiver, replyto, message, has_attachment, index, has_metaurl, socket, metaid, attachment, groupname) => dispatch => {
    var metainfo = []
    if (!has_metaurl && has_attachment === 'no') {
        var user = {
            id: sender,
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'no',
            has_metaurl: false,
            metainfo: [],
            attachment: [],
            toasterdata: groupname,
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            userid:getProfile().id,
            type: 'group'
        }
        var chatData = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message:btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'no',
            has_metaurl: false,
            metainfo: [],
            attachment: [],
            toasterdata: getProfile().first_name,
            chat_id: "",
            concat: "",
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            has_reply: "no",
            readstatus: "unread",
            receiver: receiver,
            relation: "",
            reply: [],
            replyto: replyto,
            sender: sender,
            has_whatsapp:"no",
            whatsappinfo:[]
        }
        dispatch({
            type: 'UPDATE_CHAT_HISTORY',
            data: chatData
        })
        let savechatdata = rootService.chat.saveGroupChat(sender, receiver, replyto, message, "no", index, false, metainfo,"no",[]);
        savechatdata.then(savechatdata => {
            dispatch({
                type: 'SAVE_GROUP_CHAT_DATA',
                savechatdata: savechatdata,
                messageData: user
            })

        })
        socket.emit('bsynapse_group_chatting', message, user, sender, getProfile().first_name,index);
    }
    else if (has_metaurl) {
        var link_array = [];
        var j = 0;
        var _id= Math.round(new Date().getTime() + (Math.random() * 100));
        var chatData = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: 'no',
            has_metaurl: true,
            metainfo: [],
            attachment: [],
            toasterdata: getProfile().first_name,
            chat_id: _id,
            concat: "",
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            has_reply: "no",
            readstatus: "unread",
            receiver: receiver,
            relation: "",
            reply: [],
            replyto: replyto,
            sender: sender,
            has_whatsapp:"no",
            whatsappinfo:[]
        }
        dispatch({
            type: 'UPDATE_CHAT_HISTORY',
            data: chatData
        })
        var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        message.replace(urlRegex, function (url) {
            link_array.push(url);
        });
         if(link_array.length === 0){
             let savechatdata = rootService.chat.saveGroupChat(sender, receiver, replyto, message, has_attachment, index, true, [],"no",[]);
             savechatdata.then(savechatdata => {

             })
             var user = {
                 id: receiver,
                 creator: getProfile().first_name +' ' +getProfile().last_name,
                 userhead: getProfile().image,
                 message: btoa(unescape(encodeURIComponent(message))),
                 has_attachment: 'no',
                 has_metaurl: true,
                 metainfo: [],
                 attachment: [],
                 toasterdata: groupname,
                 createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                 userid:getProfile().id,
                 type: 'group'
             }
             socket.emit('bsynapse_group_chatting', message, user, sender, getProfile().first_name, index);
         }
        for (var i = 0; i < link_array.length; i++) {
            let urlData = rootService.chat.urlData(link_array[i]);
            urlData.then(urlData => {
                var metainfo1 = urlMetaData(urlData.data.data, metaid, message)
                metainfo.push({
                    "siteName": metainfo1[0].siteName,
                    "pageUrl": metainfo1[0].pageUrl,
                    "pageTitle": metainfo1[0].pageTitle,
                    "pageUpdated": metainfo1[0].pageUpdated,
                    "pageDescription": metainfo1[0].pageDescription,
                    "pageImage": metainfo1[0].pageImage,
                    "metaid": metainfo1[0].metaid
                });
                j++;
                if (link_array.length === j) {
                    var user = {
                        id: sender,
                        creator: getProfile().first_name +' ' +getProfile().last_name,
                        userhead: getProfile().image,
                        message: btoa(unescape(encodeURIComponent(message))),
                        has_attachment: 'no',
                        has_metaurl: true,
                        metainfo: metainfo,
                        attachment: [],
                        toasterdata: groupname,
                        createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        userid:getProfile().id,
                        type: 'group'
                    }
                    var chatData = {
                        metainfo: metainfo,

                    }
                    dispatch({
                        type: 'UPDATE_METAURL_DATA',
                        metaData: chatData,
                        chatid:_id
                    })
                    socket.emit('bsynapse_group_chatting', message, user, sender, getProfile().first_name, index);
                    let savechatdata = rootService.chat.saveGroupChat(sender, receiver, replyto, message, has_attachment, index, true, metainfo,"no",[]);
                    savechatdata.then(savechatdata => {
                        dispatch({
                            type: 'SAVE_GROUP_CHAT_DATA',
                            savechatdata: savechatdata,
                            messageData: user
                        })

                    })
                }
            })

        }

    }
    else if (has_attachment === 'yes') {
        var user = {
            id: sender,
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            userhead: getProfile().image,
            message: btoa(unescape(encodeURIComponent(message))),
            has_attachment: "yes",
            has_metaurl: false,
            metainfo: [],
            attachment: attachment,
            toasterdata: groupname,
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            type: 'group',
            userid:getProfile().id
        }
        var chatData = {
            id: getProfile().id,
            username: getProfile().first_name,
            userhead: getProfile().image,
            message: btoa(message),
            has_attachment: 'yes',
            has_metaurl: false,
            metainfo: [],
            attachment: attachment,
            toasterdata: getProfile().first_name,
            chat_id: "",
            concat: "",
            createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            creator: getProfile().first_name + ' ' + getProfile().last_name,
            has_reply: "no",
            readstatus: "unread",
            receiver: receiver,
            relation: "",
            reply: [],
            replyto: replyto,
            sender: sender,
            has_whatsapp:"no",
            whatsappinfo:[]
        }
        dispatch({
            type: 'UPDATE_CHAT_HISTORY',
            data: chatData
        })
        let savechatdata = rootService.chat.saveGroupChat(sender, receiver, replyto, message, has_attachment, index, has_metaurl, metainfo,"no",[]);
        savechatdata.then(savechatdata => {
            ////////////console.log(savechatdata.data.results.id)
            let saveattachments = rootService.chat.savegroupattachments(savechatdata.data.results.id, sender, btoa(JSON.stringify(attachment)), index);
            saveattachments.then(saveattachments => {
                // ////////////console.log(saveattachments.data.results.id)
                dispatch({
                    type: 'SAVE_GROUP_CHAT_DATA',
                    savechatdata: saveattachments,
                    messageData: user
                })

            })

        })
        socket.emit('bsynapse_group_chatting', message, user, sender, 'web', index);
    }




};
const saveWhatsapp = (sender, receiver, replyto, message, has_attachment, index, has_metaurl, socket, metaid, attachment, groupname,type,msg) => dispatch => {
    var user = {
        id: sender,
        creator: getProfile().first_name + ' ' + getProfile().last_name,
        userhead: getProfile().image,
        message: btoa(unescape(encodeURIComponent(message))),
        has_attachment: 'no',
        has_metaurl: false,
        metainfo: [],
        attachment: [],
        toasterdata: groupname,
        createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        userid:getProfile().id,
        type: 'group'
    }
    var chatData = {
        id: getProfile().id,
        username: getProfile().first_name,
        userhead: getProfile().image,
        message:btoa(unescape(encodeURIComponent(message))),
        has_attachment: 'no',
        has_metaurl: false,
        metainfo: [],
        attachment: [],
        toasterdata: getProfile().first_name,
        chat_id: "",
        concat: "",
        createdate: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        creator: getProfile().first_name + ' ' + getProfile().last_name,
        has_reply: "no",
        readstatus: "unread",
        receiver: receiver,
        relation: "",
        reply: [],
        replyto: replyto,
        sender: sender,
        has_whatsapp:"yes",
        whatsappinfo:msg
    }
    dispatch({
        type: 'UPDATE_CHAT_HISTORY',
        data: chatData
    })
    let savechatdata = rootService.chat.saveGroupChat(sender, receiver, replyto, message, "no", index, false, [],true, msg);
    savechatdata.then(savechatdata => {
        dispatch({
            type: 'SAVE_GROUP_CHAT_DATA',
            savechatdata: savechatdata,
            messageData: user
        })

    })
    socket.emit('bsynapse_group_chatting', message, user, sender, getProfile().first_name,index);
}


const createGroup = (id, name, desc, logo, index) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'sidebarUpdate',
        status:true

    })

    let createGroup = rootService.chat.createGroup(id, name, desc, logo, index);
    createGroup.then(createGroup => {
        dispatch({
            type: 'CREATE_GROUP',
            creategroup: createGroup,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: '',
            status:''

        })
    })
};

const chatUsers = (id, index) => dispatch => {

    let chatUsers = rootService.chat.chatUsers(id, index);
    chatUsers.then(chatUsers => {
        dispatch({
            type: 'CHAT_USERS',
            chatusers: chatUsers,

        })
    })


};

const groupInfo = (id) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'groupMembers',
        status:true

    })
    let groupInfo = rootService.chat.groupInfo(id);
    groupInfo.then(groupInfo => {
        dispatch({
            type: 'CHAT_GROUP_INFO',
            groupInfo: groupInfo,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: '',
            status:false

        })
    })


};

const groupDelete = (id, creator, index) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'sidebarUpdate',
        status:true

    })

    let groupDelete = rootService.chat.groupDeactivate(id, creator, index);
    groupDelete.then(groupDelete => {
        dispatch({
            type: 'CHAT_GROUP_DELETE',
            groupDelete: groupDelete,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: '',
            status:false

        })

    })


};

const Toaster = (msgData) => dispatch => {


    var data= [{
        "task_creator": msgData.creator,
        "creator_img": "",
        "_id": "",
        "typeid": "",
        "userid": msgData.id ,
        "taskcreator": "",
        "message": "comment added",
        "status": "unread",
        "type": "todo",
        "messageid": 0,
        "createdate":msgData.createdate,
        "taskinfo": {
            "title": "",
            "subject": "",
            "duedate": "",
            "priority": "",
            "Token": ""
        },
        "messageinfo": [],
        "user": ""
    }]

    dispatch({
        type: 'TOASTER_NOTIFICATION',
        toaster: msgData,

    })
    dispatch({
        type: 'NOTIFICATION_INFO_UPDATED',
        results : data
    });


};

const updateReceiverpeer = (msgData) => dispatch => {



    dispatch({
        type: 'UPDATE_CHAT_HISTORY',
        data: msgData,

    })


};

const addMembersToGroup = (groupid, userid, mode, addedby, index) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'groupMembers',
        status:true

    })

    let addMembersToGroup = rootService.chat.groupAddMembers(groupid, userid.toString(), mode, addedby, index);
    addMembersToGroup.then(addMembersToGroup => {
        dispatch({
            type: 'ADD_MEMBERS_TO_GROUP',
            addMembers: addMembersToGroup,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: '',
            status:false

        })
    })


};

const editGroupDesc = (id, desc, creator, index) => dispatch => {

    let editGroupDesc = rootService.chat.updateGroupDescription(id, desc, creator, index);
    editGroupDesc.then(editGroupDesc => {
        dispatch({
            type: 'UPDATE_GROUP_DESC',
            editGroup: editGroupDesc,

        })
    })


};

const logOut = (id) => dispatch => {

    let logOut = rootService.chat.logOut(id);
    logOut.then(logOut => {
        var d = new Date();
        d.setTime(d.getTime());
        var expires = "expires=" + d.toUTCString();
        document.cookie = SHARED_LOGIN.cookie + "=" + "" + ";domain=.bsynapse.com;path=/;expires=" + expires;
        window.location.href = SHARED_LOGIN.redirect_url
    })


};
const GetAllComments = (taskid) => dispatch => {
    dispatch({
        type: 'GET_ALL_GROUP_DISSCUSSION_LOADER',
        loader: true
    })

    let GetAllComments = rootService.chat.getComments(taskid);
    GetAllComments.then(AllComments => {
        dispatch({
            type: 'GET_ALL_GROUP_DISSCUSSION_DATA',
            getAllComments: AllComments,
            loader: false,
        })
    })




};
const SaveDisscusion = (data) => dispatch => {


    let saveDisscusion = rootService.chat.saveDisscusion(data);
    saveDisscusion.then(saveDisscusion => {
        dispatch({
            type: 'SAVE_CHAT_DISSCUSSION',
            savediscussion: saveDisscusion,

        })
    })

}
const uploadAttachments = (attachments) => dispatch => {
    dispatch({
        type: 'ATTACHMENT_DISCUSSION_LOADER',
        loader: true
    })


    let uploadAttachments = rootService.chat.uploadAttachments(attachments);
    uploadAttachments.then(uploadAttachments => {
        dispatch({
            type: 'UPLOAD_ATTACHMENT_DATA',
            savediscussion: uploadAttachments,

        })
        dispatch({
            type: 'ADD_ATTACHMENT_TODO',
            attachmentAdded: uploadAttachments
        })
    })

}
const updateDisscussion = (msg) => dispatch => {
////////console.log(msg)
    dispatch({
        type: 'UPDATE_CHAT_DISSCUSSION',
        data: msg,

    })
}
const removeUser = (groupid, userid, aadedby, mode) => dispatch => {
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'groupMembers',
                status:true

            })
    let removeUser = rootService.chat.groupAddMembers(groupid, userid, aadedby, mode);
    removeUser.then(removeUser => {
            dispatch({
                type: 'REMOVE_USER_FROM_GROUP',
                removeUser: removeUser,

            })
        dispatch({
            type: 'REMOVE_USER_FROM_GROUP_UPDATION',
            removeUser: removeUser.data.results,
            userid:userid,
            groupid:groupid


        })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: '',
                status:false

            })

        }
    )


};

const addInviteUsers = (members,id,taskid) => dispatch => {
    let addinviteusers = rootService.chat.addInviteUsers(members,id,taskid);
    addinviteusers.then(addinviteusers => {
            dispatch({
                type: 'ADD_INVITE_USERS',
                addinviteusers: addinviteusers,

            })


        }
    )
}
const replyWmess = (param) => dispatch => {
    let replyWmess = rootService.chat.replyWmess(param);

}

const savelinkGroupEntity = (data) => dispatch => {

    let savelinkGroupEntity = rootService.chat.savelinkGroupEntity(data);
    savelinkGroupEntity.then(savelinkGroupEntity => {
        dispatch({
            type: 'SAVE_LINK_ENTITYGROUP',
            savediscussion: savelinkGroupEntity,

        })
    })

}
const deletelinkGroupEntity = (data) => dispatch => {

    let deletelinkGroupEntity = rootService.chat.deletelinkGroupEntity(data);
    deletelinkGroupEntity.then(deletelinkGroupEntity => {
        dispatch({
            type: 'DELETE_LINK_ENTITYGROUP',
            deletelinkGroupEntity: deletelinkGroupEntity,

        })
    })

}
const linkgroupsEntity = (param) => dispatch => {

    let linkgroupsEntity = rootService.chat.listlinkgroupsEntity(param);
    linkgroupsEntity.then(linkgroupsEntity => {
        dispatch({
            type: 'LIST_GROUPENTITY',
            linkgroupsEntity: linkgroupsEntity.data,

        })
    })


};
const linkEntityDetails = (param) => dispatch => {
    dispatch({
        type: 'RESET_LIST_ENTITYDET',

    });
    let linkEntityDetails = rootService.chat.listlinkEntityDetails(param);
    dispatch({
        type: 'LIST_ENTITYDET_LOADER',
        loading:true,

    });
    linkEntityDetails.then(linkEntityDetails => {
        dispatch({
            type: 'LIST_ENTITYDET',
            linkEntityDetails: linkEntityDetails.data,

        })
        dispatch({
            type: 'LIST_ENTITYDET_LOADER',
            loading:false,

        });
    })


};

const resetGpenty = (param) => dispatch => {
    dispatch({
        type: 'RESET_GROUP_ENTITY',

    })
};

const getChatmedia = (param) => dispatch => {

    let getChatmedia = rootService.chat.listChatmedia(param);
    dispatch({
        type: 'GET_CHAT_MEDIA_DATA',
        loader:true,
        media:[],
        total:''

    });
    getChatmedia.then(getChatmedia => {
        dispatch({
            type: 'GET_CHAT_MEDIA_DATA',
            media: getChatmedia.data.data,
            loader:false,
            total:getChatmedia.data.total
        })

    })


};

const updateMediaStatus = (param) => dispatch => {

    let getMediaStatus = rootService.chat.getMediaStatus(param);
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'chatContainer',
        status:true

    })
    getMediaStatus.then(getMediaStatus => {

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: '',
            status:false

        })


        dispatch({
            type: 'UPDATE_CHAT_MEDIA_STATUS',
            data: getMediaStatus.data.data,
            chatid:param.id,
            id:param._id,
            approvedstatus:param.approvedstatus
        })



    })


};


export const chatAction = ({
    ListAllUsers,
    listAllGroups,
    listChatHistory,
    saveChatData,
    groupChat,
    saveGroupChatData,
    createGroup,
    chatUsers,
    groupInfo,
    groupDelete,
    Toaster,
    updateReceiverpeer,
    addMembersToGroup,
    editGroupDesc,
    logOut,
    GetAllComments,
    SaveDisscusion,
    uploadAttachments,
    updateDisscussion,
    removeUser,
    updateChatHistory,
    addInviteUsers,
    replyWmess,
    saveWhatsapp,
    savelinkGroupEntity,
    deletelinkGroupEntity,
    linkgroupsEntity,
    resetGpenty,
    linkEntityDetails,
    getChatmedia,
    updateMediaStatus


});

export default chatAction;
