import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import {getProfile} from '../../../utilities/AuthService';
import * as taskActions from "../../../actions/ToDo";
import SwipeableViews from 'react-swipeable-views';

import {FRMSIMG_PATH, FRMSVID_PATH, FRMSDOC_PATH, ES_INDEX, NEW_VERSION, FRMSPDFDOC_PATH} from "../../../constants";
import {
    getUrlSegment,
    getFileIcon,
    isImage,
    isVideo,
    isAudio,
    getFileExtension,
    getFilePath,
    forceDownload,
    getAttachmentPath,
    formatBytes,
    urlify,
    isDocument,
    DateFormatter
} from "../../../utilities/CustomFunctions";
import {
    TaskDetailBox,
    WorkTimeSection,
    TaskStatusButton,
    TaskDetailContent,
    TaskStatusBar,
    TaskDetailUserInfo,
    TaskDateInfo,
    HeaderBox,
    AttachmentPanelTab,
    NavTab,
    TaskOptionButton,
    TimeLogForm,
    TimeLogList,
    ViewParentPanel,
    UsersPanelTab,
    IndividualAssigners,
    AttachmentPanel,
    CollectionPanel,
    TaskDetailActivities,
    TaskDetailLog,
    FilledFormDataPanel,
    AddNewRelatedTask,
    TaskInfo
} from "./TaskDetailDataNew";
import ChatContainer from "../chat/ChatContainer"
import Modal from "../../views/Modal";
import ShowFormModal from "../../views/ShowFormModal";
import ShowRecordModal from "../ShowRecordModal";
import ShowFormPreview from "../../views/ShowFormPreview";
import AddToDoTask from "../AddToDoTask";
import * as usersActions from "../../../actions/Users";
import * as collectionActions from "../../../actions/Collections";
import AlertModal from "../../views/AlertModal";
import LoaderSvg from "../../views/LoaderSVG";
import UploadFile from "../../../utilities/Upload/UploadFile";
import {UploadFile as FileUpload} from "../../../utilities/FileUpload/UploadFile";
import SimpleModal from "../../views/SimpleModal";
import AlertModalPool from "../../views/AlertModalPool";
import {DateTimeField} from "../../views/FormElements";
import VideoPlayer from "../../views/player/VideoPlayer"
import {debounce} from "throttle-debounce";
import * as whatsappActions from "../../../actions/Whatsapp";

class TaskDetails extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        this.state = {
            showOption: false,
            showPreviewFormModal: false,
            startDate: new Date(),
            log: [],
            date: '',
            hours: '',
            mins: '',
            taskid: '',
            rightTab: 'home-tab',
            taskstatus: "",

            timetaken: '',
            timeLogValues: [],
            childid: '',
            min: '',


            attach: '',
            donedate: '',
            verified: 'no',
            disable_verify: 'disable',
            disable_markasdone: 'disable',
            doneStatus: '',
            previewIndex: 0,
            resPreviewIndex: 0,

            previewableAttachments: [],
            showFormModal: false,
            formPages: [],
            taskmode: '',
            fieldsToDisplay: [],
            timelogloading: 0,
            markasdoneloading: 0,
            verifyloading: 0,
            filename: null,

            remark: '',
            reOpenSubmit: false,
            archive_action: '',
            archived: '',

            consolidated_time: '',
            assignee: '',
            fileData: [],


            buttonStatus: false,
            markasdonedata: '',
            verifytaskdata: '',
            reOpenparam: '',
            dateChoosen: false,
            date_error: false,
            form_collection_id: '',
            form_record_id: '',
            showPreviewAlert: false,
            quitPooltask: false,
            subtaskopen: false,

            changeTaskStatus: false,
            statusOfReopenTask: true,


            showmarkasdoneModal: false,
            showpreviewmodal: false,
            action: '',
            reference: "",
            creator: "",
            uploadedFiles: [],
            showTaskStatus: false,
            statusOfTask: '',
            previewType: '',
            attach_index: '',
            showReopenConfirm: false,
            showReopenModal: false,
            showverifyModal: false,
            showremarksModal: false,
            mark_as_done_comment_array: [],
            showtimelogModal: false,
            assignee_status: '',
            open: false,
            taskAction: "",
            duedate: new Date(),
            dateselected: false,
            showRecordModal: false,
            subtask_added: false,
            redirect_to_subtask: false,
            item: {
                res_type: '',
                res_img: ''

            },
            forceclose: false,
            hasFileAttachment: false,
            fileError: true,
            file: [],
            addRelatedTaskModal: false,
            relatedTasks: [],
            markAsDoneAttachments: [],
            showWhReply: false,
            whcontent: [],
            markAsDoneOnFormSubmit: false,
            mobTab:'Task',
            tabAct:0
        }


        this.markasDone = this.markasDone.bind(this);
        this.showOptions = this.showOptions.bind(this);
        this.showremarksModal = this.showremarksModal.bind(this);
        this.closeremarksModal = this.closeremarksModal.bind(this);
        this.showtimelogModal = this.showtimelogModal.bind(this);
        this.closetimelogModal = this.closetimelogModal.bind(this);
        this.handleinputChange = this.handleinputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addTimelog = this.addTimelog.bind(this);
        this.getrightTabid = this.getrightTabid.bind(this);
        this.addMoretime = this.addMoretime.bind(this);
        this.showmarkasdoneModal = this.showmarkasdoneModal.bind(this);
        this.closemarkasdoneModal = this.closemarkasdoneModal.bind(this);
        this.showverifyModal = this.showverifyModal.bind(this);
        this.verify = this.verify.bind(this);
        this.showpreviewmodal = this.showpreviewmodal.bind(this);
        this.closepreviewmodal = this.closepreviewmodal.bind(this);
        this.setPreviewIndex = this.setPreviewIndex.bind(this);
        this.setResPreviewIndex = this.setResPreviewIndex.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.showFormModal = this.showFormModal.bind(this);
        this.hideFormModal = this.hideFormModal.bind(this);
        this.moveToArchive = this.moveToArchive.bind(this);
        this.removeSelectedAttachment = this.removeSelectedAttachment.bind(this);
        this.viewParentTask = this.viewParentTask.bind(this);
        this.showReopenModal = this.showReopenModal.bind(this);
        this.reopenAllTask = this.reopenAllTask.bind(this);
        this.OnlineUsers = this.OnlineUsers.bind(this);
        this.hidePreviewForm = this.hidePreviewForm.bind(this);
        this.previewForm = this.previewForm.bind(this);
        this.showReopenConfirm = this.showReopenConfirm.bind(this);
        this.downloadAll = this.downloadAll.bind(this);
        this.preview = this.preview.bind(this)
        this.closeReopenModal = this.closeReopenModal.bind(this)
        this.deleteTaskConfirm = this.deleteTaskConfirm.bind(this)
        this.dateSwap = this.dateSwap.bind(this)
        this.updatetask = this.updatetask.bind(this)
        this.updatetaskdata = this.updatetaskdata.bind(this)
        this.getFormEntries = this.getFormEntries.bind(this);
        this.showPreviewFormModal = this.showPreviewFormModal.bind(this);
        this.showPreviewAlert = this.showPreviewAlert.bind(this);
        this.hidePreviewAlert = this.hidePreviewAlert.bind(this);
        this.PreviewAlert = this.PreviewAlert.bind(this);
        this.quitPoolTaskConfirm = this.quitPoolTaskConfirm.bind(this);
        this.hidePoolTaskConfirm = this.hidePoolTaskConfirm.bind(this);
        this.quitPoolTask = this.quitPoolTask.bind(this);
        this.handleMyTaskChange = this.handleMyTaskChange.bind(this);
        this.getMyTaskList = this.getMyTaskList.bind(this);
        this.selectTasks = this.selectTasks.bind(this)
        this.linkToRelated = this.linkToRelated.bind(this)
        this.goBack = this.goBack.bind(this)
        this.getMobTabid = this.getMobTabid.bind(this)
    }

    componentDidMount() {

        let params = {
            "uid": getProfile().id,
            "usertype": getProfile().usertype
        }
        let userdata = {
            uid: getProfile().id
        }
        let taskType = atob(getUrlSegment(4));
        this.setTaskStatuses();
        this.getFormEntries();
        this.props.getTaskHelper(params);
        this.props.gettodolog({_id: atob(getUrlSegment(3)), type: "todo", limit: 1000,});
        let that = this
        setTimeout(function () {
            //that.props.notificationdata(userdata);
        }, 1000)
        let taskData = this.props.taskdetailsData ? this.props.taskdetailsData.taskdetails : '';
        if (taskData) {
            let assignees = taskData.assignees;
            let list = taskData.parent;
            this.props.getRelatedtask({taskid: atob(getUrlSegment(3)), uid: getProfile().id})
            let showTaskMenu = list.status;
            this.setState({showTaskMenu: showTaskMenu, buttonStatus: false});
            let assignedData = taskData.assignedusers;
            let assigneelist = assignedData.length > 0 ? assignedData : [];
            let inviteUsers = assignedData.filter(item => (item.share === 'yes'))
            let inviteStatus = (inviteUsers.map(item => item.assignee).includes(getProfile().id))
            if (list.createuser !== getProfile().id && (assigneelist.length > 0) && ((inviteStatus === false))) {
                this.props.getassigneetimlog({taskid: atob(getUrlSegment(3)), uid: getProfile().id});
                this.props.gettimelog({taskid: atob(getUrlSegment(3)), uid: getProfile().id});
            } else {
                this.props.getassigneetimlog({taskid: atob(getUrlSegment(3))});
                this.props.gettimelog({taskid: atob(getUrlSegment(3))});
            }
            /*******************Record based task ******************/
            if (list.createdfrom === 'collection') {
                let collectionData = taskData.collection;
                if (Array.isArray(collectionData) && collectionData.length > 0) {
                    let loggedUser = [];
                    if (taskData.parent.createuser === getProfile().id) {
                        loggedUser = collectionData[0].users ? collectionData[0].users[0] : [];
                    } else {
                        loggedUser = collectionData[0].users ? collectionData[0].users.filter(user => user.assignee === getProfile().id) : [];
                    }
                    let dataIds = [];
                    if (loggedUser.length > 0) {
                        let recIds = loggedUser[0].fieldid;
                        dataIds = recIds.split(",");
                    }
                    this.setState({dataIds: dataIds})


                }
            }
        }

        this.setState({taskType: taskType})


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let mode;
        let taskid;
        const beta = this;
        if (this.props.taskdetailsData.taskdetails.parent.masterid !== prevProps.taskdetailsData.taskdetails.parent.masterid) {
            this.setTaskStatuses()
            this.getFormEntries()
        }
        if (this.props.forceClose !== prevProps.forceClose) {
            taskid = (atob(getUrlSegment(3)));
            mode = (atob(getUrlSegment(4)));
            let params = {
                _id: taskid,
                systype: getProfile().usertype,
                uid: getProfile().id,
                mode: mode,
                timeLoad: true
            };
            let beta = this;
            setTimeout(function () {
                beta.props.gettaskdetails(params)
            }, 1000)
        }


        if (this.props.taskdetailsData.taskdetails.parent.duedate !== prevProps.taskdetailsData.taskdetails.parent.duedate) {
            let recievedDate = moment.utc(this.props.taskdetailsData.taskdetails.parent.duedate).local().format('YYYY-MM-DD HH:mm:ss');
            this.setState({
                duedate: new Date(recievedDate)
            });
        }
        if (this.props.projects.projectData !== prevProps.projects.projectData) {
            this.setState({projectList: this.props.projects.projectData});
        }
        if (this.props.projects.tagData !== prevProps.projects.tagData) {
            this.setState({tagList: this.props.projects.tagData});
        }

        if (this.props.taskSelect.tasksArchived !== prevProps.taskSelect.tasksArchived) {
            this.fetchtaskDetails(this.state.taskType, 'LIST_TODO_DATA');
            setTimeout(() => {
                let type = this.state.taskType
                if (type === 'to-me') {
                    window.location.href = "/task/todo";
                    //this.props.history.push('/task/todo')
                }
                if (type === 'by-me') {
                    window.location.href = "/task/to-follow-up";
                    //this.props.history.push('/task/to-follow-up')
                }
                if (type === 'to-know') {
                    window.location.href = "/task/to-know";
                    // this.props.history.push('/task/to-know')
                }
                if (type === 'manage-task') {
                    window.location.href = "/task/to-oversee";
                    //this.props.history.push('/task/to-oversee')
                }
            }, 1000);
        }
        if (this.props.taskSelect.taskDeleted !== prevProps.taskSelect.taskDeleted) {
            var type = 'to-me';
            setTimeout(() => {
                this.fetchtaskDetails('to-me', 'LIST_TODO_DATA');
            }, 500)
            setTimeout(() => {
                this.setState({deleteTask: false})
                this.props.history.push('/task/todo')
            }, 1000);
        }


        if (this.props.timelogDetails !== prevProps.timelogDetails) {
            let tasklogHits = this.props.timelogDetails.timelog.data;
            let ctime = 0;
            if (tasklogHits) {
                for (var i = 0; i < tasklogHits.length; i++) {
                    ctime += parseInt(tasklogHits[i]['consolidated_time']);
                }
            }
            // if(ctime > 0) {
            let consolidated_time = (ctime)
            //////////////console.log(consolidated_time)
            setTimeout(function () {
                beta.setState({consolidated_time: consolidated_time, timelogloading: 0});
            }, 1000)

            this.closetimelogModal();
            setTimeout(function () {
                //////////////console.log(beta.state.consolidated_time)
            }, 1000)
            let taskData = this.props.taskdetailsData ? this.props.taskdetailsData.taskdetails : '';
            if (taskData) {
                let list = taskData.parent;
                let assignedData = taskData.assignedusers;
                let assigneelist = assignedData.length > 0 ? assignedData : [];
                let inviteUsers = assignedData.filter(item => (item.share === 'yes'))
                let inviteStatus = (inviteUsers.map(item => item.assignee).includes(getProfile().id))
                if (list.createuser !== getProfile().id && (assigneelist.length > 0) && ((inviteStatus === false))) {
                    let data = {taskid: atob(getUrlSegment(3)), uid: getProfile().id};
                    setTimeout(function () {
                        beta.props.getassigneetimlog(data)
                    }, 1000)
                } else {
                    let datas = {taskid: atob(getUrlSegment(3))};
                    setTimeout(function () {
                        beta.props.getassigneetimlog(datas)
                    }, 1000)
                }
            }

            // }
            this.setState({timelogloading: 0, showtimelogModal: false});
        }
        if (this.props.taskSelect.dateUpdated !== prevProps.taskSelect.dateUpdated) {


            let dueDate = this.props.taskdetailsData.taskdetails.parent
            dueDate['duedate'] = this.state.duedateParam
            let logAraay = {
                activity: "Duedate updated",
                assignee: "",
                assignees: "",
                content: "",
                contentid: "",
                createuser: getProfile().id,
                creator: getProfile().first_name + ' ' + getProfile().last_name,
                creator_img: getProfile().image,
                crtdate: moment.utc().format('MMMM DD, YYYY hh:mm a'),
                logid: ""
            }
            let activitylog = this.props.todolog.logList
            activitylog.push(logAraay)
            this.setState({
                dateChoosen: false, duedateParam: ''
            })
        }
        if (this.props.taskSelect.taskUpdated !== prevProps.taskSelect.taskUpdated) {

            taskid = (atob(getUrlSegment(3)));
            mode = (atob(getUrlSegment(4)));
            let params = {
                _id: taskid,
                systype: getProfile().usertype,
                uid: getProfile().id,
                mode: mode,
                timeLoad: true
            };
            let beta = this;
            setTimeout(function () {
                beta.props.gettaskdetails(params)
            }, 1000)
            this.setState({changeTaskStatus: false})
        }
        if (prevProps.HistoryPushData !== this.props.HistoryPushData) {
            this.setState({open: false})
        }
        if (this.props.modifyTask.task_modified !== prevProps.modifyTask.task_modified) {
            taskid = (atob(getUrlSegment(3)));
            mode = (atob(getUrlSegment(4)));
            let params = {_id: taskid, systype: getProfile().usertype, uid: getProfile().id, mode: mode};
            this.setState({markasdoneloading: 0, verifyloading: 0, reopenloading: 0});
            this.closemarkasdoneModal();
            this.closeverifyModal();
            this.closeReopenModal();
            this.setTaskStatuses();
            if (this.props.modifyTask.task_type === 'markasdone') {
                this.props.TaskCompletionasync(this.state.markasdonedata);
                let taskData = this.props.taskdetailsData ? this.props.taskdetailsData.taskdetails : '';
                if (taskData) {
                    let list = taskData.parent;
                    if (list.poolenabled) {
                        let poolParams = {todoid: taskid, uid: getProfile().id};
                        this.props.completePoolTask(poolParams);
                    }
                    let assignedData = taskData.assignedusers;
                    let assigneelist = assignedData.length > 0 ? assignedData : [];
                    let inviteUsers = assignedData.filter(item => (item.share === 'yes'))
                    let inviteStatus = (inviteUsers.map(item => item.assignee).includes(getProfile().id))
                    if (list.createuser !== getProfile().id && (assigneelist.length > 0) && ((inviteStatus === false))) {
                        this.props.gettimelog({taskid: atob(getUrlSegment(3)), uid: getProfile().id});
                    } else {
                        this.props.gettimelog({taskid: atob(getUrlSegment(3))});
                    }
                }
                let that = this
                setTimeout(() => {
                    this.fetchtaskDetails('to-me', 'LIST_TODO_DATA');
                    that.setState({buttonStatus: false})

                }, 1000)
            }
            if (this.props.modifyTask.task_type === 'verifytask') {
                this.props.TaskCompletionasync(this.state.verifytaskdata);
                let taskData = this.props.taskdetailsData ? this.props.taskdetailsData.taskdetails : '';
                if (taskData) {
                    let list = taskData.parent;
                    let assignedData = taskData.assignedusers;
                    let assigneelist = assignedData.length > 0 ? assignedData : [];
                    let inviteUsers = assignedData.filter(item => (item.share === 'yes'))
                    let inviteStatus = (inviteUsers.map(item => item.assignee).includes(getProfile().id))
                    if (list.createuser !== getProfile().id && (assigneelist.length > 0) && ((inviteStatus === false))) {
                        this.props.gettimelog({taskid: atob(getUrlSegment(3)), uid: getProfile().id});
                    } else {
                        this.props.gettimelog({taskid: atob(getUrlSegment(3))});
                    }
                }

            }
            if (this.props.modifyTask.task_type === 'reopentask') {
                this.setState({statusOfReopenTask: false});
                let taskData = this.props.taskdetailsData ? this.props.taskdetailsData.taskdetails : '';
                if (taskData) {
                    let list = taskData.parent;
                    let assignedData = taskData.assignedusers;
                    let assigneelist = assignedData.length > 0 ? assignedData : [];
                    let inviteUsers = assignedData.filter(item => (item.share === 'yes'))
                    let inviteStatus = (inviteUsers.map(item => item.assignee).includes(getProfile().id))
                    if (list.createuser !== getProfile().id && (assigneelist.length > 0) && ((inviteStatus === false))) {

                        this.props.gettimelog({taskid: atob(getUrlSegment(3)), uid: getProfile().id});
                    } else {

                        this.props.gettimelog({taskid: atob(getUrlSegment(3))});
                    }
                }
            }
            if (this.props.modifyTask.task_type === 'updatetaskform') {

            }
        }
        if (this.props.reassigntask !== prevProps.reassigntask) {
            this.setState({open: false, showOption: false, subtask_added: true})
            //this.props.history.push("/task/details/"+btoa(this.props.reassigntask.results)+"/"+getUrlSegment(4))
        }
        if (this.state.subtask_added !== prevState.subtask_addded && this.state.redirect_to_subtask === true) {
            this.props.history.push("/task/details/" + btoa(this.props.reassigntask.results) + "/" + getUrlSegment(4))

        }
        if (prevProps.poolTasksData.todoPoolTaskQuit !== this.props.poolTasksData.todoPoolTaskQuit) {
            this.hidePoolTaskConfirm()
            this.props.history.push("/task/pool/" + this.props.poolTasksData.poolId);

        }

        /*if(prevProps.whatsappReply.replied !== this.props.whatsappReply.replied){

        }*/


    }

    hideSubTaskMessage = () => {
        this.setState({subtask_added: false, redirect_to_subtask: true})
    }

    fetchtaskDetails(taskType, actionType) {
        var data = {
            category: taskType,
            status: "All",
            uid: getProfile().id,
            bydue: '',
            priority: this.props.taskFilters.taskPriorityFilter,
            project: this.props.taskFilters.taskProjectFilter,
            asigntype: 'All',
            username: this.props.taskFilters.taskAssignedFilter,
            managetask: 'mytask',
            limit: 0,
            offset: this.state.offset,
            systype: getProfile().usertype,
            actionType: actionType
        }

        this.props.todo(data);
    }

    preview(url, name, albumName, download, item) {
        if (item === undefined) {
            item = this.state.item
        }
        this.setState({
            url: url,
            name: name,
            item: item,
            previewStatus: !this.state.previewStatus,
            albumName: albumName,
            download: download
        })
    }

    closePreview = () => {
        this.setState({previewStatus: false, item: ''})
    }

    setTaskStatuses() {
        let parent = this.props.taskdetailsData.taskdetails.parent;
        let tasktype = '';
        let duedate = moment.utc().local().format('YYYY-MM-DD HH:mm:ss');
        if (parent) {
            if (parent.reassign === 'yes') {
                let uid = ''
                if (parent.createuser !== getProfile().id) {
                    uid = getProfile().id
                }
                this.loadmoreData({taskid: parent.masterid, uid: uid});
            }
            tasktype = parent.tasktype;

            duedate = moment.utc(parent.duedate).local().format('YYYY-MM-DD HH:mm:ss');
        }

        let assignees = this.props.taskdetailsData.taskdetails.assignees;
        assignees = assignees.filter(
            (user => user.assignee === getProfile().id)
        );

        let assigneestatus = '';
        let time = '';
        let shared = '';
        let donedate = '';
        let archived = (parent.createuser === getProfile().id && parent.archive === 'yes') ? 'yes' : 'no';
        let childid = '';
        let assignee_id = '';
        if (assignees.length > 0) {
            assigneestatus = assignees[0].status;
            time = assignees[0].consolidated_time;
            shared = assignees[0].share;
            donedate = assignees[0].donedate;
            archived = assignees[0].archive;
            childid = assignees[0].childid;
            assignee_id = assignees[0].assignee;
        }
        let markAsDoneOnFormSubmit = this.props.taskdetailsData.taskdetails.parent.done_onsubmit ? this.props.taskdetailsData.taskdetails.parent.done_onsubmit : false;
        let markasdonedata = {};
        if (markAsDoneOnFormSubmit === true) {
            let reference = 'markasdone';
            var rawdate = new Date();
            var utcStartdate = new moment(rawdate, "YYYY-MM-DDThh:mm").utc();
            rawdate = (utcStartdate.format('YYYY-MM-DD HH:mm:ss'));
            let timeLogValues = []
            let ctime = 0;
            let remark = 'Done';
            let uploadArray = [];
            let parentid = '';
            if (this.props.taskdetailsData.taskdetails.parent.has_parent === "yes" && this.props.taskdetailsData.taskdetails.parent.createuser === getProfile().id) {
                parentid = this.props.taskdetailsData.taskdetails.parent.parent_id;
            }

            markasdonedata = {
                "taskid": parent.masterid,
                "uid": getProfile().id,
                "index": ES_INDEX,
                "reference": reference,
                "timelog": timeLogValues,
                "consolidated_time": ctime,
                "remarks": [{
                    "content": remark,
                    "attachments": uploadArray
                }],
                "redisremarks": [{
                    "discussion": btoa(unescape(encodeURIComponent(remark))),
                    "attachment": uploadArray,
                    "has_attachment": uploadArray.length === 0 ? "no" : "yes"
                }],
                "parentid": parentid,
                "mode": '',
                "redisaction": reference
            }
        }
        this.setState({
            tasktype: tasktype,
            taskmode: (parent.createuser === getProfile().id) ? 'by-me' : '',
            disable_verify: (parent.status === 'Done') ? '' : 'disable',
            taskid: parent.masterid,
            verified: parent.verified ? parent.verified : 'no',
            duedate: duedate,
            statusOfTask: parent.status,
            assignee_id: assignee_id,
            childid: childid,
            assignee_status: assigneestatus,
            shared: shared,
            consolidated_time: time,
            donedate: donedate,
            archived: archived,
            whcontent: this.props.taskdetailsData.taskdetails.parent.whatsappcontent !== '' ? JSON.parse(this.props.taskdetailsData.taskdetails.parent.whatsappcontent) : [],
            markAsDoneOnFormSubmit: markAsDoneOnFormSubmit,
            markasdonedata: markasdonedata
        })


    }

    setFieldValue = (fieldName, fieldValue) => {
        this.setState({[fieldName]: fieldValue});
    }

    /*************For retrieving the form entry - Rule based pool task ************/
    getFormEntries() {
        let parent = this.props.taskdetailsData.taskdetails.parent;
        let form_collection_id = '';
        let form_record_id = '';

        if (parent) {
            if (parent.has_collecteddata === 'yes') {
                form_collection_id = parent.collecteddata;
                form_record_id = parent.collecteddataid;
            }
        }
        this.setState({form_collection_id: form_collection_id, form_record_id: form_record_id});

    }

    showOptions() {
        this.setState({showOption: !this.state.showOption});
    }

    showTaskStatusMenu = (action) => {
        this.setState({
            showTaskStatus: action
        });
    }
    showTaskMenu = (status) => {
        this.setState({
            statusOfTask: status, showTaskStatus: false, changeTaskStatus: !this.state.changeTaskStatus
        });
    }
    showTaskCancel = (status) => {

        this.setState({
            statusOfTask: status === 'InProgress' ? 'New' : 'InProgress',
            showTaskStatus: false,
            changeTaskStatus: !this.state.changeTaskStatus
        });
    }

    showFormModal(collectionid, formName, dataid) {
        this.props.showform({_id: collectionid});
        if (dataid) {
            this.props.getFormData({_id: dataid, collectionid: collectionid, metainfo: true});
        } else {
            this.props.resetForm();
        }
        this.setState({
            formName: formName,
            collectionid: collectionid,
            showFormModal: true,
            editId: dataid
        })
    }

    hideFormModal() {
        this.setState({
            showFormModal: false
        })
    }

    previewForm(dataid, collectionid, index) {
        let ids = dataid.split(',');
        if (ids.length > 0) {
            let id = ids[0];
            this.props.getFormData({_id: id, collectionid: collectionid, metainfo: true});
            this.setState({
                collectionid: collectionid,
                showPreviewFormModal: true,
                index: index,
                _id: id
            })
        } else {
            this.props.resetForm();
        }

    }

    hidePreviewForm() {
        this.setState({
            showPreviewFormModal: false
        })
    }

    showPreviewFormModal(e, id, index, collectionid) {
        this.props.getFormData({_id: id, collectionid: collectionid, metainfo: true});
        this.setState({
            collectionid: collectionid,
            showPreviewForm: true,
            index: index,
            _id: id
        })
    }

    showReopenConfirm(id, mainTask, assignee) {
        this.setState({
            taskIdToReopen: id,
            assignee: assignee,
            showReopenConfirm: !this.state.showReopenConfirm,
            mainTask: mainTask
        });
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleinputChange(e) {
        this.setState({[e.target.name]: e.target.value})

        if (e.target.value !== '') {
            e.target.classList.remove('val-error');

        }
    }

    saveDiscussion(taskid, childid, reference) {
        let has_attachment = (this.state.uploadedFiles.length > 0) ? "yes" : "no";
        let savediscussiondata = {
            todoid: taskid,
            discussion: this.state.remark,
            reference: reference,
            has_attachment: has_attachment,
            uid: getProfile().id,
            childid: childid,
            filerelator: ""
        };
        this.props.savediscussion(savediscussiondata);
    }

    getrightTabid(tab) {
        this.setState({rightTab: tab})
    }

    addMoretime() {
        this.setState({action: 'addtime'})
    }

    loadmoreData = (params) => {
        this.props.getmoretaskdetails(params)
    }

    getTimeLogEntries(timeLogValues) {
        this.setState({timeLogValues: timeLogValues});
    }

    addTimelog(saveLog) {
        if (saveLog) {
            this.setState({timelogloading: 1});
        }
        let timeLogValues = [...this.state.timeLogValues];
        let invalid = 0;
        if (timeLogValues.length > 0) {
            for (var i = 0; i < timeLogValues.length; i++) {
                if (timeLogValues[i]['date'] === " " || timeLogValues[i]['date'] === null || timeLogValues[i]['date'] === undefined) {
                    timeLogValues[i]['log_date'] = moment().format('YYYY-MM-DD')
                    timeLogValues[i]['date'] = moment().format('YYYY-MM-DD')
                }
                if (timeLogValues[i]['rawdate'] === '') {
                } else {
                    var el = document.getElementById("date" + i);
                    if (el.classList.contains("val-error")) {
                        el.classList.remove("val-error");
                    }
                }

                if ((timeLogValues[i]['hour'] === '') && timeLogValues[i]['min'] === '') {
                    document.getElementById('hour' + i).classList.add('val-error');
                    document.getElementById('min' + i).classList.add('val-error');
                    invalid++;
                } else {
                    var el = document.getElementById('hour' + i);
                    var e2 = document.getElementById('min' + i);
                    if (el.classList.contains("val-error"))
                        el.classList.remove("val-error");
                    if (e2.classList.contains("val-error"))
                        e2.classList.remove("val-error");
                }

                if (timeLogValues[i]['hour'] !== '' && parseInt(timeLogValues[i]['hour']) > 23) {
                    timeLogValues[i]['hour'] = 23;
                }
                if (timeLogValues[i]['min'] !== '' && parseInt(timeLogValues[i]['min']) > 59) {
                    timeLogValues[i]['min'] = 59;
                }
            }
//////console.log(timeLogValues)
            if (saveLog) {
                if (invalid === 0) {
                    this.props.addtimelog({
                        uid: getProfile().id,
                        taskid: this.state.taskid,
                        logtime: JSON.stringify(timeLogValues)
                    });
                    this.setState({timeLogValues: []})
                    //this.setState({timeLogValues:JSON.stringify(timeLogValues)})
                } else {
                    this.setState({timelogloading: 0});
                }
            } else {
                return invalid;
            }

        } else {
            this.setState({timelogloading: 0});
            document.getElementById('hour0').classList.add('val-error');
            document.getElementById('min0').classList.add('val-error');
        }
    }

    getAttachments() {
        var uploadedFiles = this.state.uploadedFiles;

        var uploadArray = [];
        var markasdoneAttachArray = [];

        if (uploadedFiles.length > 0) {
            for (var i = 0; i < uploadedFiles.length; i++) {
                var uploadedFile = uploadedFiles[i];
                var fileNameArr = uploadedFile.fileName.split('/');
                var fileName = fileNameArr.pop();

                var fileData = {
                    filetype: uploadedFile.fileType.startsWith('image') ? 'images' : (uploadedFile.fileType.startsWith('video') ? 'videos' : 'documents'),
                    filesize: uploadedFile.fileSize,
                    filetitle: uploadedFile.originalfileName,
                    filename: fileName
                }

                uploadArray.push(fileData);
            }

        }
        if (this.state.markAsDoneAttachments.length > 0) {
            var markasdoneAttachFiles = this.state.markAsDoneAttachments;
            for (var j = 0; j < markasdoneAttachFiles.length; j++) {
                var markasdoneAttachFile = markasdoneAttachFiles[j] || {};
                var data = {
                    filetype: markasdoneAttachFile.res_type === 1 ? 'images' : (markasdoneAttachFile.res_type === 2 ? 'videos' : 'documents'),
                    filesize: markasdoneAttachFile.filesize,
                    filetitle: markasdoneAttachFile.filetitle,
                    filename: markasdoneAttachFile.filename
                }
                markasdoneAttachArray.push(data);
            }
        }

        uploadArray = uploadArray.concat(markasdoneAttachArray)

        return uploadArray;
    }

    saveAttachments(taskid) {
        let uploadArray = this.getAttachments();

        if (uploadArray.length > 0) {
            var fileData = {
                todoid: taskid,
                uid: getProfile().id,
                file: uploadArray
            }

            this.setState({fileData: uploadArray})

            this.props.saveToDoAttachments(fileData);
        }
    }

    setPreviewIndex(type) {
        let attachType = this.state.attachType;
        let previewIndex = (attachType === 'resource') ? this.state.resPreviewIndex : this.state.previewIndex;

        if (type === 'prev') {
            --previewIndex;
        } else {
            ++previewIndex;
        }

        let previewableFiles = (attachType === 'resource') ? this.props.taskdetailsData.taskdetails.parent.resourceinfo : this.props.taskdetailsData.taskdetails.attachment;


        previewableFiles = previewableFiles.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
        // +
        // this.props.taskdetailsData.taskdetails.allfiles.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
        if (previewIndex >= 0 && previewIndex < previewableFiles.length) {
            if (attachType === 'resource') {
                this.setState({resPreviewIndex: previewIndex});
            } else {
                this.setState({previewIndex: previewIndex});
            }

        }

    }

    setResPreviewIndex(type) {
        let resPreviewIndex = this.state.resPreviewIndex;

        if (type === 'prev') {
            --resPreviewIndex;
        } else {
            ++resPreviewIndex;
        }
        let previewableResources = this.props.taskdetailsData.taskdetails.parent.resourceinfo.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
        // +
        // this.props.taskdetailsData.taskdetails.allfiles.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
        if (resPreviewIndex >= 0 && resPreviewIndex < previewableResources.length) {
            this.setState({resPreviewIndex: resPreviewIndex});
        }

    }

    moveToArchive(id) {
        var data = {param: "archive", uid: getProfile().id, _id: id};
        this.props.moveTasksToArchive(data);
    }

    showConfirmArchive = (archiveaction) => {
        this.setState({
            showArchive: !this.state.showArchive,
            archive_action: archiveaction
        });
    }

    deleteTaskConfirm(deleteaction) {
        this.setState({
            deleteTask: !this.state.deleteTask,
            delete_action: deleteaction
        });
    }

    quitPoolTaskConfirm(quit) {
        this.setState({
            quitPooltask: !this.state.quitPooltask,
            //delete_action : deleteaction
        });
    }

    hidePoolTaskConfirm() {
        this.setState({
            quitPooltask: false
            //delete_action : deleteaction
        });
    }

    quitPoolTask() {
        let poolToDoId = atob(getUrlSegment(3));
        var data = {uid: getProfile().id, todoid: poolToDoId};
        this.props.quitTodoPoolTask(data);
    }

    getUploadedFile(uploadedFile) {
        let uploadedFiles = this.state.uploadedFiles;
        uploadedFiles.push(uploadedFile);
        this.setState({
            filename: uploadedFile.fileName,
            uploadedFiles: uploadedFiles
        });
        var element = document.getElementById("progress-bar-wrapper");
        if (element)
            element.parentNode.removeChild(element);
    }

    removeSelectedAttachment(index) {
        let uploadedFiles = this.state.uploadedFiles;
        if (uploadedFiles) {
            uploadedFiles.splice(index, 1);
            this.setState({uploadedFiles: uploadedFiles});
        }
    }

    fileTabUpload = () => {
        this.setState({hasFileAttachment: !this.state.hasFileAttachment})
    }

    removeFileTabUpload(index) {
        let uploadedFiles = this.state.uploadedFiles;
        if (uploadedFiles) {
            uploadedFiles.splice(index, 1);
            this.setState({uploadedFiles: uploadedFiles});
        }
    }

    viewParentTask(id) {
        this.props.history.push('/task/details/' + btoa(id) + '/' + btoa('to-me'))
    }

    OnlineUsers(val) {
        var OnlineStatus = this.props.onlineusers.users.filter(p => val === p.User_ID);
        if (OnlineStatus.length !== 0)
            return true;
        else
            return false;
    }

    handleDateChange = (date) => {
        if (date !== null) {
            let formattedDate = moment(date).utc().local().format('YYYY-MM-DD HH:mm:ss');
            this.setState({
                duedate: date,
                formattedDate: formattedDate
            })
        }

    }
    dateSwap = (duedate) => {
        let startdate = this.props.taskdetailsData.taskdetails.parent.startdate;
        startdate = startdate !== '' ? startdate : new Date();
        let StartDate = moment.utc(startdate).local().format('YYYY-MM-DD HH:mm:ss');
        let recievedDate = moment.utc(this.props.taskdetailsData.taskdetails.parent.duedate).local().format('YYYY-MM-DD HH:mm:ss');

        this.setState({
            dateChoosen: !this.state.dateChoosen,
            taskAction: 'edit',
            min: StartDate,
            duedate: new Date(recievedDate),
        })
    }

    updatetaskdata(dateChoosen) {
        var duedate;
        if (this.state.duedate !== null && this.state.duedate !== '') {
            if (this.state.dateselected === true) {
                duedate = (this.state.duedate && this.state.duedate !== '') ? this.state.duedate : new Date();
            } else {
                duedate = this.state.duedate;
            }
            if (duedate < new Date()) {
                duedate = new Date();
            }

            var utcStart = new moment(duedate, "YYYY-MM-DDTHH:mm").utc();
            duedate = (utcStart.format('YYYY-MM-DD HH:mm:ss'));

            this.setState({
                dateChoosen: dateChoosen, duedateParam: duedate
            })
            let taskData = this.props.taskdetailsData.taskdetails;
            let taskid = taskData ? taskData.parent.masterid : atob(getUrlSegment(3));
            let params = {
                "taskid": taskid,
                "uid": getProfile().id,
                "duedate": duedate,
                "index": ES_INDEX
            }
            this.props.dateupdatetask(params);
        } else {
            this.setState({date_error: true})
        }
    }

    PreviewAlert() {
        this.setState({showPreviewAlert: !this.state.showPreviewAlert})
    }


    markasDone(e) {
        e.preventDefault();
        var reference = "markasdone";
        var mode = "";
        var parentid = ""
        this.setState({markasdoneloading: 1, buttonStatus: true});
        let formInvalid = 0;
        if (this.state.remark.trim() === '') {
            formInvalid++;
            document.getElementsByName('remark')[0].classList.add('val-error');
        }
        if (formInvalid === 0) {
            let assignees = this.props.taskdetailsData.taskdetails.assignees;
            assignees = assignees.filter(
                (user => user.assignee === getProfile().id)
            );
            //  let childid = assignees[0].childid;
            let tasklogHits = this.props.timelogDetails.timelog.data;
            let ctime = 0;
            if (tasklogHits) {
                for (var i = 0; i < tasklogHits.length; i++) {
                    let time = parseInt(tasklogHits[i]['consolidated_time'])
                    if (isNaN(time)) {
                        time = 0;
                    }
                    ctime += time;
                }
            }

            let newTimeLogs = this.state.timeLogValues;
            if (newTimeLogs.length > 0) {
                for (var j = 0; j < newTimeLogs.length; j++) {
                    if (newTimeLogs[j].hour || newTimeLogs[j].min) {
                        var h = parseInt(newTimeLogs[j].hour) * 60;
                        var m = parseInt(newTimeLogs[j].min);
                        if (isNaN(h)) {
                            h = 0;
                        }
                        if (isNaN(m)) {
                            m = 0;
                        }
                        ctime += h + m;
                    }
                }
            }

            let uploadArray = []
            uploadArray = this.getAttachments();
            /* self task */
            if ((getProfile().id === this.props.taskdetailsData.taskdetails.parent.createuser &&
                this.props.taskdetailsData.taskdetails.assignees.length === 1 &&
                this.state.tasktype === 'task' &&
                this.props.taskdetailsData.taskdetails.parent.createuser === getProfile().id &&
                this.props.taskdetailsData.taskdetails.parent.shared !== "yes" &&
                this.props.taskdetailsData.taskdetails.parent.has_parent !== "yes") || this.props.taskdetailsData.taskdetails.parent.assigntype === 'self'
            ) {

                mode = "self";
                reference = "verifyandmarkasdone"
            }
            /* subtask */
            if (this.props.taskdetailsData.taskdetails.parent.has_parent === "yes" &&
                this.props.taskdetailsData.taskdetails.parent.createuser === getProfile().id) {
                parentid = this.props.taskdetailsData.taskdetails.parent.parent_id
                reference = "verifyandmarkasdone"
            }


            let markasdonedata = {
                "taskid": this.state.taskid,
                "uid": getProfile().id,
                "index": ES_INDEX,
                "reference": reference,
                "timelog": this.state.timeLogValues,
                "consolidated_time": ctime,
                "remarks": [{
                    "content": this.state.remark,
                    "attachments": uploadArray
                }],
                "redisremarks": [{
                    "discussion": btoa(unescape(encodeURIComponent(this.state.remark))),
                    "attachment": uploadArray,
                    "has_attachment": uploadArray.length === 0 ? "no" : "yes"
                }],
                "parentid": parentid,
                "mode": mode,
                "redisaction": reference
            }
            this.setState({markasdonedata: markasdonedata})

            this.props.markasdone(markasdonedata);
            this.setState({timeLogValues: [{rawdate: '', hour: '', min: ''}]})

        } else {
            this.setState({markasdoneloading: 0});
        }

    }

    verify() {
        this.setState({markasdoneloading: 1, buttonStatus: true});
        let formInvalid = 0;
        if (this.state.remark === '') {
            formInvalid++;
            document.getElementsByName('remark')[0].classList.add('val-error');
        }

        if (formInvalid === 0) {

            let uploadArray = []
            uploadArray = this.getAttachments();
            let verifytaskdata = {
                "taskid": this.state.taskid,
                "uid": getProfile().id,
                "index": ES_INDEX,
                "reference": this.state.reference,
                "consolidated_time": 0,
                "timelog": [],
                "remarks": [{
                    "content": this.state.remark,
                    "attachments": uploadArray
                }],
                "parentid": "",
                "redisaction": 'verify'
            }
            this.setState({verifytaskdata: verifytaskdata})

            this.props.verifytask(verifytaskdata);
            // this.props.verifytask(verifydata);

            //   this.saveDiscussion(this.state.taskid, this.state.childid, "verify");

            // this.saveAttachments(this.state.taskid);
        }
    }

    reopenAllTask() {
        let uploadArray = []
        uploadArray = this.getAttachments();
        var reOpenparam = {
            "todoid": this.state.taskIdToReopen,
            "uid": getProfile().id,
            "index": ES_INDEX,
            "remarks": [{
                "content": this.state.remark,
                "attachments": uploadArray
            }],
            "assignee": ""
        }
        this.setState({reOpenSubmit: true});
        var reopenData = {todoid: this.state.taskIdToReopen, uid: getProfile().id};
        var formInvalid = 0;

        if (this.state.remark === '') {
            formInvalid++;
        }
        if (formInvalid === 0) {
            this.props.reopenAllTask(reOpenparam);
            //this.saveDiscussion(this.state.taskIdToReopen, this.state.taskIdToReopen, "reopen ");

            // this.saveAttachments(this.state.taskIdToReopen);
        }
    }

    reopenTask() {
        let uploadArray = []
        uploadArray = this.getAttachments();
        this.setState({reOpenSubmit: true});
        var reopenData = {
            cid: this.state.taskIdToReopen,
            pid: this.state.taskid,
            status: 'New',
            view: 'no',
            readstatus: 'unread'
        };


        var formInvalid = 0;

        if (this.state.remark === '') {
            formInvalid++;
        }
        if (formInvalid === 0) {
            var reOpenparam = {
                "todoid": this.state.taskid,
                "taskid": this.state.taskid,
                "uid": getProfile().id,
                "index": ES_INDEX,
                "assignee": this.state.assignee,
                "consolidated_time": 0,
                "remarks": [{
                    "content": this.state.remark,
                    "attachments": uploadArray
                }]
            }
            this.setState({reOpenparam: reOpenparam})
            this.props.reopenTask(reOpenparam);
            //  this.saveDiscussion(this.state.taskid, this.state.taskIdToReopen, "reopen ");

            // this.saveAttachments(this.state.taskIdToReopen);
        }
    }


    downloadAll(e, download, attachType) {
        e.preventDefault();
        if (attachType === 'attachment') {
            let attachmentData = this.props.taskdetailsData.taskdetails.attachment;
            for (var i = 0; i < attachmentData.length; i++) {
                let filename = attachmentData[i].filename;
                //let fileType = getFileIcon(filename);
                let fileType = getFilePath(filename);
                if ((isVideo(filename) && download === "yes") || isVideo(filename) === false) {
                    let filePath = (fileType === 'image') ? FRMSIMG_PATH : ((fileType === 'video') ? FRMSVID_PATH : FRMSDOC_PATH);
                    forceDownload(filePath + filename, attachmentData[i].filetitle);
                }

            }
        } else {
            let attachmentData = this.props.taskdetailsData.taskdetails.parent.resourceinfo;
            for (var i = 0; i < attachmentData.length; i++) {
                let filename = attachmentData[i].res_img;
                //let fileType = getFileIcon(filename);
                let fileType = getFilePath(filename);
                if ((isVideo(filename) && download === "yes") || isVideo(filename) === false) {
                    let filePath = (fileType === 'image') ? FRMSIMG_PATH : ((fileType === 'video') ? FRMSVID_PATH : FRMSDOC_PATH);
                    forceDownload(filePath + filename, attachmentData[i].res_img);
                }

            }
        }
    }

    showmarkasdoneModal(id, createuser, reference) {
        this.setState({
            showmarkasdoneModal: !this.state.showmarkasdoneModal,
            action: '',
            reference: reference,
            creator: createuser,
            uploadedFiles: []
        });
    }

    closemarkasdoneModal() {
        this.setState({showmarkasdoneModal: false, statusOfTask: 'InProgress', showTaskStatus: false});
    }

    showpreviewmodal(index, previewType, attachType) {
        this.setState({
            showpreviewmodal: !this.state.showpreviewmodal,
            previewType: previewType,
            attachType: attachType,
            attach_index: index
        })
    }

    closepreviewmodal() {
        this.setState({showpreviewmodal: false})
    }

    showReopenModal() {
        this.setState({showReopenConfirm: false, showReopenModal: true, uploadedFiles: []});
    }

    closeReopenModal() {
        this.setState({showReopenModal: false});
    }

    showverifyModal(id, reference) {
        this.setState({showverifyModal: true, uploadedFiles: [], reference: reference})
    }

    closeverifyModal() {
        this.setState({showverifyModal: false})
    }

    showremarksModal(mark_as_done_comment, reference) {
        // //console.log(mark_as_done_comment)
        this.setState({
            showremarksModal: !this.state.showremarksModal,
            mark_as_done_comment_array: mark_as_done_comment
        });
    }

    closeremarksModal() {
        this.setState({showremarksModal: false, mark_as_done_comment_array: []});
    }

    forceClose = () => {
        this.setState({forceclose: !this.state.forceclose})
    }
    closebyCreator = () => {
        let param = {
            "taskid": atob(getUrlSegment(3)),
            "uid": getProfile().id
        }
        this.setState({forceclose: false})

        this.props.closedbyCreator(param)
    }

    showtimelogModal(id) {
        let assignees = this.props.taskdetailsData.taskdetails.assignees;
        assignees = assignees.filter(
            (user => user.assignee === getProfile().id)
        );
        let assigneestatus = assignees[0].status;
        this.setState({showtimelogModal: !this.state.showtimelogModal, assignee_status: assigneestatus});
    }

    closetimelogModal() {
        this.setState({showtimelogModal: false})
    }

    modalOpen(action) {
        this.setState({open: !this.state.open, taskAction: action});
    }

    updatetask(date) {
        this.setState({duedate: date, dateselected: true})
    }

    showPreviewAlert() {
        this.setState({showPreviewAlert: true})
    }

    hidePreviewAlert() {
        this.setState({showPreviewAlert: false})
    }

    showCollectionRecords = (collectionid, formName, recIds) => {
        this.props.showform({_id: collectionid});

        let ids = recIds.split(",");
        if (ids.length > 0) {
            let id = ids[0];
            this.props.getFormData({_id: id, collectionid: collectionid, metainfo: true});
            this.setState({
                formName: formName,
                collectionid: collectionid,
                showRecordModal: true,
                collectiondataid: id,
                dataIds: ids
            });
        }
    }
    hideCollectionRecords = () => {
        this.setState({showRecordModal: false})
    }
    handleClap = (clap, assignee) => {
        let param = {
            "uid": getProfile().id,
            "taskid": this.state.taskid,
            "assignee": assignee,
            "points": clap
        }
        this.props.handleClap(param)
    }
    lazyUploadStatus = () => {
        this.setState({displayStatus: "none"})
    }
    handleFiles = (file) => {
        if (file !== '') {
            this.setState({fileError: false, file})
        }

    }
    fileUpload = () => {
        if (this.state.file.length === 0) {
            this.setState({fileError: true})
            return false
        }
        this.setState({hasFileAttachment: false})

        let taskid = (atob(getUrlSegment(3)))
        let params = {"_id": taskid, "reference": "All"}
        const filerelator = Math.floor(Date.now() / 1000);
        const attachments = {
            uid: getProfile().id,
            todoid: this.state.taskid,
            attachment: this.state.file,
            reference: 'attachment',
            filerelator: filerelator,
        };
        this.props.uploadAttachments(attachments, params)
        this.setState({messageData: ''})
    }
    showRelatedTask = () => {
        let beta = this;
        this.setState({addRelatedTaskModal: !this.state.addRelatedTaskModal},
            function () {
                if (this.state.addRelatedTaskModal === true) {
                    beta.getMyTaskList()
                }
            })
    }

    getMyTaskList(search) {
        let params = {
            limit: 0,
            offset: '50',
            systype: getProfile().usertype,
            uid: getProfile().id,
            srchval: search ? search : '',
            "taskid": atob(getUrlSegment(3)),
        }
        this.props.getMytask(params)
    }

    selectTasks(e, id) {
        let relatedTasks = [...this.state.relatedTasks];
        if (relatedTasks) {
            if (this.state.relatedTasks.length !== 0 && this.state.relatedTasks.includes(id)) {
                var index = this.state.relatedTasks.indexOf(id)
                relatedTasks.splice(index, 1);
            } else if (!relatedTasks.includes(id)) {
                relatedTasks.push(id);
            }
        }
        this.setState({relatedTasks: relatedTasks})

    }

    handleMyTaskChange(e, search) {
        let params = {
            limit: 0,
            offset: '50',
            systype: getProfile().usertype,
            uid: getProfile().id,
            srchval: search ? search : '',
            "taskid": atob(getUrlSegment(3)),
        }
        this.props.getMytask(params)
    }

    addRelatedTask = () => {
        this.props.saveReleatedTask({
            "taskid": atob(getUrlSegment(3)),
            "relatedtask": this.state.relatedTasks,
            "uid": getProfile().id,
        })
        this.setState({addRelatedTaskModal: false, relatedTasks: []})
    }
    selectAttachedFiles = (e, item) => {
        let markAsDoneAttachments = [...this.state.markAsDoneAttachments]
        if (markAsDoneAttachments) {
            if (this.state.markAsDoneAttachments.length !== 0 && this.state.markAsDoneAttachments.includes(item)) {
                var index = this.state.markAsDoneAttachments.indexOf(item)
                markAsDoneAttachments.splice(index, 1);
            } else if (!markAsDoneAttachments.includes(item)) {
                markAsDoneAttachments.push(item);
            }
        }
        this.setState({markAsDoneAttachments: markAsDoneAttachments})
    }

    postReplyToWhatsapp = (message) => {

        if (message !== '') {
            let taskParent = this.props.taskdetailsData.taskdetails.parent;
            console.log(taskParent.whatsappcontent)
            let whcontent = this.state.whcontent;
            //let replyto = whcontent[whcontent.length-1].chat_id ? whcontent[whcontent.length-1].chat_id : '';
            let replyto = (taskParent.lastChatId && taskParent.lastChatId !== '') ? taskParent.lastChatId :
                (whcontent[whcontent.length - 1].chat_id ? whcontent[whcontent.length - 1].chat_id : '');

            this.setState({
                showWhReply: false
            })
            //console.log(whcontent.length); return false;
            let params = {
                "recordid": taskParent.leadId,
                "sender": getProfile().id,
                "receiver": taskParent.leadPhoneNumber,
                "message": message,
                "bsynuser": true,
                "sendnotification": true,
                "channelid": taskParent.whatsappChannelId,
                "replyto": replyto
            }
            this.props.saveNewChat(params);

            let updatedWhContent = whcontent;
            updatedWhContent.push({
                "bsynuser": true,
                "chat_id": "",
                "createdate": "2021-03-01 23:27:20",
                "message": message,
                "readstatus": "unread",
                "receiver": taskParent.leadPhoneNumber,
                "sender": getProfile().id,
                "replyto": replyto
            })

            console.log(taskParent)

            let taskParams = {
                "taskid": taskParent.masterid,
                "whatsappcontent": JSON.stringify(updatedWhContent),
                "uid": getProfile().id
            }

            this.props.updateWhContent(taskParams);
            /* this.setState({
                 whcontent : updatedWhContent
             })*/

            //https://apir4dev.bsynapse.com/chat/savewhatsappchat
        }
    }

    replyToMainChat = (message, whatsappcontent, from) => {

        let params = {
            sender: getProfile().id,
            receiver: whatsappcontent[0].receiver,
            replyto: '0',
            message: '',
            has_attachment: "no",
            has_metaurl: false,
            metainfo: [],
            has_task: "no",
            task_content: JSON.stringify(whatsappcontent),
            taskid: '',
            reply_task: "yes",
            reply_content: message

        }
        this.props.saveChatmessage(params, from)

    }

    linkToRelated(masterid) {
        // if (this.props.sectionTitle === "Unread") {
        //     this.props.readStatus(masterid, getUrlSegment(2))
        // }
        this.props.history.push('/task/details/' + masterid + '/' + btoa(getUrlSegment(4)))
    }
    goBack() {
        this.props.resetTaskProps();
        this.props.history.goBack();
    }
    getMobTabid(updatedTab) {
        this.setState({mobTab: updatedTab.label})


    }

    tabClick=(index)=>{
        this.setState({tabAct:index})

    }

    render() {
        let assigneestatus = "";
        let time = "";
        let shared = "";
        let donedate = "";
        let archived = "";
        let assigneesreopen = "";
        let formSource = 'Task';
        let currentTime = new Date()
        let min = this.state.min !== '' ? this.state.min : currentTime;
        let taskData = this.props.taskdetailsData.taskdetails;
        //let list = taskData.parent;
        let parent = taskData.parent;
        // //console.log(this.state.item)

        let share = taskData.assignees.filter((item) => item.assignee === getProfile().id)
        let todologdata = this.props.todolog.logList;
        let logpaneldata = this.props.assigneetimelogdetails.logpaneldetails;
        let taskPermission = (this.props.listUsersPermission.module.filter(module => module.module === "Task")[0] !== undefined ? this.props.listUsersPermission.module.filter(module => module.module === "Task")[0].actions : [])
        let download = taskPermission.filter(permission => permission.action === "Download")[0] !== undefined ? taskPermission.filter(permission => permission.action === "Download")[0].action_permission : ""
        let redisData = this.props.taskdetailsData.taskdetails.redis
        let assigneesData = taskData.assignees;
        let assignedUserData = taskData.assignedusers;
        let attachmentData = taskData.attachment;
        let allfilesData = this.props.attachmentData.attachList;
        /***************Resources attached *************/
        let previewableResources = [];
        let resourceData = [];
        resourceData = taskData.parent.resourceinfo;
        previewableResources = resourceData.length !== 0 && resourceData.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
        /****************For form attached in task  Begin*********/
        let collectionData = taskData.collection;
        /****************Rule based Pool Task - retrieving the filled form entry Begin *********/
        if (taskData.parent.has_collecteddata === 'yes') {
            formSource = 'TaskPool';
        }
        /****************Rule based Pool Task - retrieving the filled form entry End *********/
        /****************Collection based Task - retrieving the selected form entry Begin *********/
        if (taskData.parent.createdfrom === 'collection') {
            formSource = 'CollectionBasedTask';
        }
        /****************Collection based Task - retrieving the selected form entry End *********/
        let timelogData = this.props.timelogDetails.timelog.data;
        let subtaskData = this.props.moretaskDetailsdata.moretaskdetails;
        let remarksData = this.props.remarksData.remarks.hits;
        let previewableAttachments = attachmentData.length !== 0 && attachmentData.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
        let taskid = taskData.parent.masterid;
        let deleteid = taskData.parent.masterid;
        if (taskData.parent.createuser === getProfile().id) {
            taskid = "_parent||" + taskData.parent.masterid;
        } else if (this.state.assignee_id !== '') {
            taskid = this.state.childid + '||' + taskData.parent.masterid;
        }

        let taskmode = (parent.createuser === getProfile().id) ? 'by-me' : '';

        let assignees = this.props.taskdetailsData.taskdetails.assignees;
        assignees = assignees.filter(
            (user => user.assignee === getProfile().id)
        );


        if (assignees.length > 0) {
            assigneestatus = assignees[0].status;
            time = assignees[0].consolidated_time;
            shared = assignees[0].share;
            donedate = assignees[0].donedate;
            archived = assignees[0].archive;
            assigneesreopen = assignees[0].reopen;
        }


        /*if(this.state.taskAction === 'edit') {
            if(this.state.duedate !== null && this.state.duedate < currentTime) {
                min = this.state.duedate;
            }
        }*/

        /************Syncfusion datepicker *************/

        let duedate = moment.utc(taskData.parent.duedate).local().format('YYYY-MM-DD HH:mm:ss');
        min = currentTime;

        // if (this.state.taskAction === 'edit') {
        //     if (duedate < currentTime) {
        //         min = duedate;
        //     }
        // }


        /************Syncfusion datepicker *************/
        /* console.log(this.state.duedate)
         console.log(min)*/

        let worktime = 0
        logpaneldata.map((item) =>
            worktime += parseInt(item.totaltime)
        )

        let previewableFiles = [];
        let previewIndex = 0;
        let previewedFile = '';
        let previewedFileTitle = '';
        if (this.state.attachType === 'resource') {
            previewableFiles = previewableResources;
            previewIndex = this.state.resPreviewIndex;
            previewedFile = previewableFiles.length > 0 ? previewableFiles[previewIndex].res_img : '';
            previewedFileTitle = previewableFiles.length > 0 ? previewableFiles[previewIndex].res_img : '';
        } else {
            previewableFiles = previewableAttachments;
            previewIndex = this.state.previewIndex;
            previewedFile = previewableFiles.length > 0 ? previewableFiles[previewIndex].filename : '';
            previewedFileTitle = previewableFiles.length > 0 ? previewableFiles[previewIndex].filetitle : '';
        }

        let subtaskfiles = this.props.taskdetailsData.taskdetails.assignees.length > 0 ? (this.props.taskdetailsData.taskdetails.assignees[0].mark_as_done_comment !== null &&
            this.props.taskdetailsData.taskdetails.assignees[0].mark_as_done_comment[0] !== undefined &&
            this.props.taskdetailsData.taskdetails.assignees[0].mark_as_done_comment[0].attachment !== undefined &&
            this.props.taskdetailsData.taskdetails.assignees[0].mark_as_done_comment[0].attachment) : [];
            console.log(this.myRef !== undefined && this.myRef)

        return (
            <React.Fragment key={`Task-Detail-data-normal`}>
                <div className='task-inner-wrapper hide-sm new-tab'>
                    <div className="task-left" key={"task-details-main-layout"}>
                        <div className="task-detail-wrapper switch-status-view task-all-done" tabIndex="1">
                            <div className="task-detail-panel">
                                <HeaderBox
                                    list={parent}
                                    state={this.state}
                                    data={this.props}
                                    dateChoosen={this.state.dateChoosen}
                                    assignee={taskData.assignees}
                                    archived={this.state.archived}
                                    redisData={redisData}
                                    updatetask={this.updatetask}
                                    dateSwap={this.dateSwap}
                                    showReopenConfirm={this.showReopenConfirm}
                                    showConfirmArchive={this.showConfirmArchive}
                                    deleteTaskConfirm={this.deleteTaskConfirm}
                                    handleDateChange={this.handleDateChange}
                                    quitPoolTaskConfirm={this.quitPoolTaskConfirm}
                                    modalOpen={this.modalOpen}
                                    share={share}
                                    verified={this.state.verified}
                                    creator={parent.createuser}
                                    assignee_status={assignees.length !== 0 && assignees[0].status}
                                    duedate={this.state.duedate}

                                />
                                <TaskStatusBar
                                    list={parent}
                                    assigneestatus={assigneestatus}
                                    assigneesData={assigneesData}
                                    redisData={redisData}
                                    worktime={worktime}
                                />
                                <div className="task-detail-panel-content">
                                    <span className={"ico-lib"}></span>
                                    <TaskDateInfo
                                        state={this.state}
                                        list={parent}
                                        assigneesData={assigneesData}
                                        OnlineUsers={this.OnlineUsers}
                                        data={this.props}
                                        redisData={redisData}
                                    />
                                    <TaskDetailUserInfo
                                        state={this.state}
                                        list={parent}
                                        assigneesData={assigneesData}
                                        OnlineUsers={this.OnlineUsers}
                                        data={this.props}
                                        assignedData={assignedUserData}
                                    />
                                    <TaskDetailContent
                                        state={this.state}
                                        list={parent}
                                        showremarksModal={this.showremarksModal}
                                        remarksData={remarksData}
                                        whcontent={this.state.whcontent}
                                        showReply={this.state.showWhReply}
                                        postReplyToWhatsapp={this.postReplyToWhatsapp}
                                        replyToMainChat={this.replyToMainChat}

                                    />
                                    {parent.draft !== 'yes' && parent.has_scheduled !== 'yes' &&
                                    <div className="task-submit-block">
                                        <TaskStatusButton
                                            // markasDoneTemp={this.props.modifyTask}
                                            buttonStatus={this.props.taskdetailsData.tempLoading}
                                            redisData={redisData}
                                            list={parent}
                                            assigneestatus={assigneestatus}
                                            craetorInassignee={assignees}
                                            shared={shared}
                                            taskmode={taskmode}
                                            taskData={taskData}
                                            state={this.state}
                                            parent={parent}
                                            showmarkasdoneModal={this.showmarkasdoneModal}
                                            forcrClose={this.forceClose}
                                            showverifyModal={this.showverifyModal}
                                            quitPoolTaskConfirm={this.quitPoolTaskConfirm}
                                            showTaskStatusMenu={this.showTaskStatusMenu}
                                            showTaskStatus={this.state.showTaskStatus}
                                            statusOfTask={this.state.statusOfTask}
                                            showTaskMenu={this.showTaskMenu}
                                            showReopenConfirm={this.showReopenConfirm}
                                            assigneesData={assigneesData}
                                            statusOfReopenTask={this.state.statusOfReopenTask}
                                            assignedData={assignedUserData}
                                            verified={taskData.parent.verified ? taskData.parent.verified : 'no'}
                                            assigneesreopen={assigneesreopen}
                                        />
                                        <WorkTimeSection
                                            list={parent}
                                            state={this.state}
                                            share={share}
                                            data={this.props}
                                            showtimelogModal={this.showtimelogModal}
                                            timelogData={timelogData}
                                            taskAsync={this.props.taskdetailsData}
                                            redisData={redisData}
                                            worktime={worktime}
                                        />
                                    </div>
                                    }
                                </div>
                                <ViewParentPanel
                                    hasparentTask={(parent.createuser === getProfile().id && parent.has_parent === "yes") ? true : false}
                                    viewParentTask={this.viewParentTask}
                                    parentId={parent.parent_id}
                                />
                                <AttachmentPanel
                                    has_attachemnt={parent.has_attachemnt === "yes" ? true : false}
                                    previewableAttachments={previewableAttachments}
                                    attachmentData={attachmentData}
                                    showpreviewmodal={this.showpreviewmodal}
                                    downloadAll={this.downloadAll}
                                    preview={this.preview}
                                    taskPermission={taskPermission}
                                    attachType={'attachment'}
                                />
                                <AttachmentPanel
                                    has_attachemnt={parent.has_attachemnt === "yes" ? true : false}
                                    previewableAttachments={previewableResources}
                                    attachmentData={resourceData}
                                    showpreviewmodal={this.showpreviewmodal}
                                    downloadAll={this.downloadAll}
                                    preview={this.preview}
                                    taskPermission={taskPermission}
                                    attachType={'resource'}
                                />
                                <CollectionPanel
                                    has_collection={parent.has_collection === "yes" ? true : false}
                                    createUser={parent.createuser}
                                    collectionData={collectionData}
                                    showFormModal={this.showFormModal}
                                    previewForm={this.previewForm}
                                    showPreviewAlert={this.showPreviewAlert}
                                    createdFrom={taskData.parent.createdfrom}
                                    showCollectionRecords={this.showCollectionRecords}
                                    taskStatus={assigneestatus}
                                    assignees={assignees}
                                    markAsDoneOnFormSubmit={this.state.markAsDoneOnFormSubmit}
                                    markasdoneparams={this.state.markasdonedata}
                                />
                                <FilledFormDataPanel
                                    has_collection={parent.has_collecteddata === "yes" ? true : false}
                                    createUser={parent.createuser}
                                    formName={parent.collectedformtitle}
                                    collectionId={this.state.form_collection_id}
                                    recordId={this.state.form_record_id}
                                    previewForm={this.previewForm}
                                />
                                <IndividualAssigners
                                    markasDoneTemp={this.props.modifyTask}
                                    redisData={redisData}
                                    assigneesData={assigneesData}
                                    consolidated_time={assigneesData.consolidated_time}
                                    verified={taskData.parent.verified ? taskData.parent.verified : 'no'}
                                    data={parent}
                                    showReopenConfirm={this.showReopenConfirm}
                                    showremarksModal={this.showremarksModal}
                                    handleClap={this.handleClap}

                                />
                            </div>
                            <TaskDetailBox
                                redisData={redisData}
                                creator={parent.createuser}
                                reassign={parent.reassign === "yes" ? true : false}
                                subtaskData={subtaskData !== undefined ? subtaskData : []}
                                loader={this.props.moretaskDetailsdata.loader}
                                showremarksModal={this.showremarksModal}

                            />
                        </div>
                    </div>
                    <div className="task-right">
                        <div className="task-extra-tab-block expand-text">
                            <div className="tasktab-scroll-div">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <NavTab
                                        getrightTabid={this.getrightTabid}
                                        rightTab={this.state.rightTab}
                                        AcTab={'home-tab'}
                                        label={`Disscussion`}
                                        icon={`ico-lib disscussion`}
                                    />
                                </ul>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                {this.state.rightTab === 'home-tab' &&
                                <div className="tab-pane fade show active" id="disscussion" role="tabpanel"
                                     aria-labelledby="disscussion-tab">
                                    <ChatContainer
                                        taskid={atob(getUrlSegment(3))}
                                        assigneesData={assigneesData}
                                        inviteUsers={true}
                                        type={"task"}
                                    />

                                </div>
                                }
                                {this.state.rightTab === 'user-tab' &&
                                <div className="tab-pane fade show active" id="user" role="tabpanel"
                                     aria-labelledby="user-tab">
                                    <div className="user-list-block">
                                        <UsersPanelTab
                                            assigneesData={assignedUserData.filter(item => (item.share === 'no'))}
                                            title={`Assigned users`}
                                            type="assigned"
                                        />
                                        <UsersPanelTab
                                            assigneesData={assignedUserData.filter(item => (item.share === 'yes'))}
                                            title={`Invited users`}
                                            type="invited"
                                        />
                                    </div>
                                </div>
                                }
                                {this.state.rightTab === 'file-tab' &&
                                <div className="tab-pane fade show active" id="attach" role="tabpanel"
                                     aria-labelledby="attach-tab">

                                    <div className="user-list-block" tabIndex="3">
                                        <div className="spliter-task-user">

                                            <div id={`progress-holder`}></div>
                                            <div className="common-tab-header">
                                                <h5 className="split-task-header upload-pr-wrap">Attached Files</h5>
                                                {(taskData.parent.verified ? taskData.parent.verified : 'no') !== "yes" &&
                                                <button className="add-option-button" type="button"
                                                        onClick={() => this.fileTabUpload()}>+</button>
                                                }
                                            </div>

                                            <AttachmentPanelTab
                                                allfilesData={allfilesData}
                                                resourceData={resourceData}
                                                showpreviewmodal={this.showpreviewmodal}
                                                taskPermission={taskPermission}
                                                preview={this.preview}
                                            />
                                            {this.props.Attachmentlist.loader &&
                                            <LoaderSvg/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                }
                                {this.state.rightTab === 'act-tab' &&
                                <div className="tab-pane fade show active" id="activities" role="tabpanel"
                                     aria-labelledby="activities-tab">
                                    <div className="user-list-block" tabIndex="4">
                                        <div className="activities-log-wrapper">
                                            <TaskDetailActivities
                                                todologdata={todologdata}
                                            />
                                        </div>
                                    </div>
                                </div>
                                }
                                {this.state.rightTab === 'log-tab' &&
                                <div className="tab-pane fade show active" id="log" role="tabpanel"
                                     aria-labelledby="log-tab">
                                    <div className="user-list-block log-panel" tabIndex="4">
                                        <div className="spliter-task-user">
                                            <TaskDetailLog
                                                list={parent}
                                                state={this.state}
                                                share={share}
                                                data={this.props}
                                                showtimelogModal={this.showtimelogModal}
                                                timelogData={timelogData}
                                                logpaneldata={logpaneldata}
                                            />
                                        </div>
                                    </div>
                                </div>
                                }

                                {this.state.rightTab === 'rel-tab' &&
                                <div className="tab-pane fade show active" id="log" role="tabpanel"
                                     aria-labelledby="log-tab">
                                    <div className="tab-wrap-header">
                                        <h5>Related Task</h5>
                                        <button className="add-option-button" type="button"
                                                onClick={() => this.showRelatedTask()}>+
                                        </button>
                                    </div>
                                    <div className="tab-wrap-body">
                                        {this.props.getrelatedTask.data.map((list, index) =>

                                            <div className="each-task-tag cursor">
                                                <p onClick={(e) => this.linkToRelated(btoa(list.masterid))}>{atob(list.subject === "" ? list.Title : list.subject)}</p>
                                            </div>
                                        )}

                                        {this.props.getrelatedTask.loader &&

                                        <LoaderSvg/>

                                        }
                                    </div>


                                    {/* <div className="user-list-block log-panel" tabIndex="4">
                                            <div className="common-tab-header">
                                                <h5 className="split-task-header upload-pr-wrap">Add Related Task</h5>
                                                <button className="add-option-button" type="button" onClick={() => this.showRelatedTask()}>+</button>

                                            </div>

                                            <div className="spliter-task-user">
                                                {this.props.getrelatedTask.data.map((list,index)=>

                                                    <p>{atob(list.subject)}</p>
                                                )}

                                                {this.props.getrelatedTask.loader &&

                                                <LoaderSvg/>

                                                }
                                            </div>
                                        </div> */}
                                </div>
                                }
                                {this.state.rightTab === 'info-tab' &&
                                    <div className="tab-pane fade show active" id="log" role="tabpanel"
                                         aria-labelledby="log-tab">
                                        <div className="user-list-block log-panel" tabIndex="5">
                                            <div className="spliter-task-user">
                                                <TaskInfo
                                                    list={parent}
                                                    state={this.state}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className='task-inner-wrapper show-sm'>
                    <div className='task-top-bar'>
                        <div className="task-nav">
                            <div className="task-nav-left bg-back">
                                <button className="backto-list" onClick={this.goBack}>Back To Listing</button>
                            </div>
                        </div>
                    </div>
                    <div className='task-tab-nav'>
                        <nav onClick={() => this.tabClick(0)} className={`each-tab-nav ${this.state.tabAct === 0 ?'active' : ''}`}>Task</nav>
                        <nav onClick={() => this.tabClick(1)} className={`each-tab-nav  ${this.state.tabAct === 1 ?'active' : ''}`}>Discussion</nav>
                        <nav onClick={() => this.tabClick(2)} className={`each-tab-nav ${this.state.tabAct === 2 ?'active' : ''}`}>User</nav>
                        <nav onClick={() => (this.tabClick(3))} className={`each-tab-nav ${this.state.tabAct === 3 ?'active' : ''}`}>Files</nav>
                        <nav onClick={() => (this.tabClick(4))} className={`each-tab-nav ${this.state.tabAct === 4 ?'active' : ''}`}>Related Task</nav>
                        <nav onClick={() => (this.tabClick(5))} className={`each-tab-nav ${this.state.tabAct === 5 ?'active' : ''}`}>Activities</nav>
                        <nav onClick={() => (this.tabClick(6))} className={`each-tab-nav ${this.state.tabAct === 6 ?'active' : ''}`}>Log</nav>
                        <nav onClick={() => (this.tabClick(7))} className={`each-tab-nav ${this.state.tabAct === 7 ?'active' : ''}`}>Info</nav>
                    </div>
                    <div className='task-details-body'>                    
                        <SwipeableViews index={this.state.tabAct} onChangeIndex={(inx)=>this.tabClick(inx)} className="swipe-tab">
                            <div className='each-swipe-card'>
                                <div className="task-detail-panel">
                                    <HeaderBox
                                        list={parent}
                                        state={this.state}
                                        data={this.props}
                                        dateChoosen={this.state.dateChoosen}
                                        assignee={taskData.assignees}
                                        archived={this.state.archived}
                                        redisData={redisData}
                                        updatetask={this.updatetask}
                                        dateSwap={this.dateSwap}
                                        showReopenConfirm={this.showReopenConfirm}
                                        showConfirmArchive={this.showConfirmArchive}
                                        deleteTaskConfirm={this.deleteTaskConfirm}
                                        handleDateChange={this.handleDateChange}
                                        quitPoolTaskConfirm={this.quitPoolTaskConfirm}
                                        modalOpen={this.modalOpen}
                                        share={share}
                                        verified={this.state.verified}
                                        creator={parent.createuser}
                                        assignee_status={assignees.length !== 0 && assignees[0].status}
                                        duedate={this.state.duedate}

                                    />
                                    <TaskStatusBar
                                        list={parent}
                                        assigneestatus={assigneestatus}
                                        assigneesData={assigneesData}
                                        redisData={redisData}
                                        worktime={worktime}
                                    />
                                    <div className="task-detail-panel-content">
                                        <span className={"ico-lib"}></span>
                                        <TaskDateInfo
                                            state={this.state}
                                            list={parent}
                                            assigneesData={assigneesData}
                                            OnlineUsers={this.OnlineUsers}
                                            data={this.props}
                                            redisData={redisData}
                                        />
                                        <TaskDetailUserInfo
                                            state={this.state}
                                            list={parent}
                                            assigneesData={assigneesData}
                                            OnlineUsers={this.OnlineUsers}
                                            data={this.props}
                                            assignedData={assignedUserData}
                                        />
                                        <TaskDetailContent
                                            state={this.state}
                                            list={parent}
                                            showremarksModal={this.showremarksModal}
                                            remarksData={remarksData}
                                            whcontent={this.state.whcontent}
                                            showReply={this.state.showWhReply}
                                            postReplyToWhatsapp={this.postReplyToWhatsapp}
                                            replyToMainChat={this.replyToMainChat}

                                        />
                                        {parent.draft !== 'yes' && parent.has_scheduled !== 'yes' &&
                                        <div className="task-submit-block">
                                            <TaskStatusButton
                                                // markasDoneTemp={this.props.modifyTask}
                                                buttonStatus={this.props.taskdetailsData.tempLoading}
                                                redisData={redisData}
                                                list={parent}
                                                assigneestatus={assigneestatus}
                                                craetorInassignee={assignees}
                                                shared={shared}
                                                taskmode={taskmode}
                                                taskData={taskData}
                                                state={this.state}
                                                parent={parent}
                                                showmarkasdoneModal={this.showmarkasdoneModal}
                                                forcrClose={this.forceClose}
                                                showverifyModal={this.showverifyModal}
                                                quitPoolTaskConfirm={this.quitPoolTaskConfirm}
                                                showTaskStatusMenu={this.showTaskStatusMenu}
                                                showTaskStatus={this.state.showTaskStatus}
                                                statusOfTask={this.state.statusOfTask}
                                                showTaskMenu={this.showTaskMenu}
                                                showReopenConfirm={this.showReopenConfirm}
                                                assigneesData={assigneesData}
                                                statusOfReopenTask={this.state.statusOfReopenTask}
                                                assignedData={assignedUserData}
                                                verified={taskData.parent.verified ? taskData.parent.verified : 'no'}
                                                assigneesreopen={assigneesreopen}
                                            />
                                            <WorkTimeSection
                                                list={parent}
                                                state={this.state}
                                                share={share}
                                                data={this.props}
                                                showtimelogModal={this.showtimelogModal}
                                                timelogData={timelogData}
                                                taskAsync={this.props.taskdetailsData}
                                                redisData={redisData}
                                                worktime={worktime}
                                            />
                                        </div>
                                        }
                                    </div>
                                    <ViewParentPanel
                                        hasparentTask={(parent.createuser === getProfile().id && parent.has_parent === "yes") ? true : false}
                                        viewParentTask={this.viewParentTask}
                                        parentId={parent.parent_id}
                                    />
                                    <AttachmentPanel
                                        has_attachemnt={parent.has_attachemnt === "yes" ? true : false}
                                        previewableAttachments={previewableAttachments}
                                        attachmentData={attachmentData}
                                        showpreviewmodal={this.showpreviewmodal}
                                        downloadAll={this.downloadAll}
                                        preview={this.preview}
                                        taskPermission={taskPermission}
                                        attachType={'attachment'}
                                    />
                                    <AttachmentPanel
                                        has_attachemnt={parent.has_attachemnt === "yes" ? true : false}
                                        previewableAttachments={previewableResources}
                                        attachmentData={resourceData}
                                        showpreviewmodal={this.showpreviewmodal}
                                        downloadAll={this.downloadAll}
                                        preview={this.preview}
                                        taskPermission={taskPermission}
                                        attachType={'resource'}
                                    />
                                    <CollectionPanel
                                        has_collection={parent.has_collection === "yes" ? true : false}
                                        createUser={parent.createuser}
                                        collectionData={collectionData}
                                        showFormModal={this.showFormModal}
                                        previewForm={this.previewForm}
                                        showPreviewAlert={this.showPreviewAlert}
                                        createdFrom={taskData.parent.createdfrom}
                                        showCollectionRecords={this.showCollectionRecords}
                                        taskStatus={assigneestatus}
                                        assignees={assignees}
                                        markAsDoneOnFormSubmit={this.state.markAsDoneOnFormSubmit}
                                        markasdoneparams={this.state.markasdonedata}
                                    />
                                    <FilledFormDataPanel
                                        has_collection={parent.has_collecteddata === "yes" ? true : false}
                                        createUser={parent.createuser}
                                        formName={parent.collectedformtitle}
                                        collectionId={this.state.form_collection_id}
                                        recordId={this.state.form_record_id}
                                        previewForm={this.previewForm}
                                    />
                                    <IndividualAssigners
                                        markasDoneTemp={this.props.modifyTask}
                                        redisData={redisData}
                                        assigneesData={assigneesData}
                                        consolidated_time={assigneesData.consolidated_time}
                                        verified={taskData.parent.verified ? taskData.parent.verified : 'no'}
                                        data={parent}
                                        showReopenConfirm={this.showReopenConfirm}
                                        showremarksModal={this.showremarksModal}
                                        handleClap={this.handleClap}

                                    />
                                </div>
                                <TaskDetailBox
                                    redisData={redisData}
                                    creator={parent.createuser}
                                    reassign={parent.reassign === "yes" ? true : false}
                                    subtaskData={subtaskData !== undefined ? subtaskData : []}
                                    loader={this.props.moretaskDetailsdata.loader}
                                    showremarksModal={this.showremarksModal}

                                />
                            </div>
                            <div className='each-swipe-card'>
                                <div className='task-tab-body'>
                                {this.state.tabAct === 1 &&
                                    <ChatContainer
                                        taskid={atob(getUrlSegment(3))}
                                        assigneesData={assigneesData}
                                        inviteUsers={true}
                                        type={"task"}
                                    />
                                }
                                </div>
                            </div>
                            <div className='each-swipe-card'>
                                <div className='task-tab-body'>
                                    <div class="user-list-block">
                                        <UsersPanelTab
                                            assigneesData={assignedUserData.filter(item => (item.share === 'no'))}
                                            title={`Assigned users`}
                                            type="assigned"
                                        />
                                        <UsersPanelTab
                                            assigneesData={assignedUserData.filter(item => (item.share === 'yes'))}
                                            title={`Invited users`}
                                            type="invited"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='each-swipe-card'>
                                <div className='task-tab-body'>
                                    <div className="user-list-block" tabIndex="3">
                                        <div className="spliter-task-user">

                                            <div id={`progress-holder`}></div>
                                            <div className="common-tab-header">
                                                <h5 className="split-task-header upload-pr-wrap">Attached Files</h5>
                                                {(taskData.parent.verified ? taskData.parent.verified : 'no') !== "yes" &&
                                                <button className="add-option-button" type="button"
                                                        onClick={() => this.fileTabUpload()}>+</button>
                                                }
                                            </div>

                                            <AttachmentPanelTab
                                                allfilesData={allfilesData}
                                                resourceData={resourceData}
                                                showpreviewmodal={this.showpreviewmodal}
                                                taskPermission={taskPermission}
                                                preview={this.preview}
                                            />
                                            {this.props.Attachmentlist.loader &&
                                            <LoaderSvg/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='each-swipe-card'>
                                <div className='task-tab-body'>
                                    <div className="tab-wrap-header">
                                        <h5>Related Task</h5>
                                        <button className="add-option-button" type="button"
                                                onClick={() => this.showRelatedTask()}>+
                                        </button>
                                    </div>
                                    <div className="tab-wrap-body">
                                        {this.props.getrelatedTask.data.map((list, index) =>

                                            <div className="each-task-tag cursor">
                                                <p onClick={(e) => this.linkToRelated(btoa(list.masterid))}>{atob(list.subject === "" ? list.Title : list.subject)}</p>
                                            </div>
                                        )}

                                        {this.props.getrelatedTask.loader &&

                                        <LoaderSvg/>

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='each-swipe-card'>
                                <div className='task-tab-body'>
                                    <div className="user-list-block" tabIndex="4">
                                        <div className="activities-log-wrapper">
                                            <TaskDetailActivities
                                                todologdata={todologdata.length > 0 ? todologdata:[]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='each-swipe-card'>
                                <div className='task-tab-body'>
                                    <div className="user-list-block log-panel" tabIndex="4">
                                        <div className="spliter-task-user">
                                            <TaskDetailLog
                                                list={parent}
                                                state={this.state}
                                                share={share}
                                                data={this.props}
                                                showtimelogModal={this.showtimelogModal}
                                                timelogData={timelogData}
                                                logpaneldata={logpaneldata}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='each-swipe-card'>
                                <div className='task-tab-body'>
                                    <div className="user-list-block" tabIndex="5">
                                        <div className="activities-log-wrapper">
                                            <TaskInfo
                                                list={parent}
                                                state={this.state}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwipeableViews>                        
                    </div>                    
                </div>
                <React.Fragment>
                    {this.state.mark_as_done_comment_array.length !== '0' &&
                    <div className="modal fade bsy-modal s-modal show" tabIndex="-1" role="dialog"
                         aria-labelledby="mngModal"
                         style={{display: this.state.showremarksModal === true ? 'block' : 'none'}}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title ico-lib">Remarks</h5>
                                    <button className="ico-lib-list modal-close" onClick={this.closeremarksModal}/>
                                </div>
                                <div className="modal-body">
                                    <div className="remark-block">

                                        {this.state.mark_as_done_comment_array.map((item, index) =>
                                            <div className='remarks-list' key={`mark-as-done-comment` + index}>
                                                <p dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(urlify(atob(item.discussion)))).replace(/\n/g, '<br />')}}/>
                                                <div className='remarks-attachment-list'>
                                                    {item.has_attachment === 'yes' && item.attachment.map((attach, indx) =>
                                                        <div className="ind-task-added-user-list"
                                                             key={"attach-files-" + indx}>
                                                            <div className="ind-added-file-list-left">
                                                                <div className="ind-added-file-list-left-thumb">
                                                                    <div
                                                                        className={"format " + getFileExtension(attach.filename)}>{getFileExtension(attach.filename)}</div>
                                                                </div>
                                                                <div className="ind-added-file-list-left-info">
                                                                    <h5>{attach.filetitle.split(".")[0]}</h5>
                                                                    <p>{formatBytes(attach.filesize) !== 'NaN undefined' ? formatBytes(attach.filesize) : ''}</p>
                                                                </div>
                                                            </div>
                                                            {/*{console.log(attach)}*/}
                                                            <div className="ind-added-file-list-right">
                                                                <button className="ico-lib-v1 download-ico-file"
                                                                        onClick={((e) => forceDownload(getAttachmentPath(getFileIcon(attach.filename)) + attach.filename, attach.filetitle))}></button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <p style={{textAlign: "right"}}>{DateFormatter(item.crtdate, true, true).toUpperCase()}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {this.state.showtimelogModal === true &&
                    <SimpleModal
                        Close={() => this.closetimelogModal()}
                        title={`Time Log`}
                        haveFooter={false}
                    >
                        <div className="modal-body time-log">
                            <div className="modal-time-log">
                                {this.state.timelogloading === 1 &&
                                <span className="Loader-holder">
                                            <LoaderSvg/>
                                </span>
                                }
                                <div className="time-log-top">
                                    <div className="time-log-sub-list-select">
                                        {this.state.assignee_status !== "Done" &&
                                        <TimeLogForm getTimeLogEntries={this.getTimeLogEntries.bind(this)}/>
                                        }
                                        {timelogData.map((log, index) =>
                                            <TimeLogList index={index} log={log} key={"log-" + index}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {
                                this.closetimelogModal(e)
                            }}>Cancel
                            </button>
                            {this.state.assignee_status !== "Done" &&
                            <button id="add_timelog"
                                    className={"general-btn " + (this.state.timelogloading ? 'disable' : '')}
                                    data-function="markasdone"
                                    onClick={(e) => this.addTimelog(true)}>Add</button>
                            }
                        </div>
                    </SimpleModal>
                    }
                    {this.state.showmarkasdoneModal === true &&
                    <SimpleModal
                        Close={() => this.closemarkasdoneModal()}
                        title={`Mark As Done`}
                        haveFooter={false}
                        extraClass={`mark-as-done-mod`}
                    >
                        <div className="modal-body time-log">
                            <div className="modal-time-log">

                                {this.state.markasdoneloading === 1 &&
                                <span className="Loader-holder">
                              <LoaderSvg/>
                          </span>
                                }

                                <div className="time-log-top full-hgt-log">
                                    {(timelogData.length > 0 && this.state.action !== "addtime") &&
                                    <div className="addmore-top">
                                        <h5>Do you want to log more time? <a href="javascript:void(0);"
                                                                             onClick={(e) => {
                                                                                 this.addMoretime(e)
                                                                             }} className="addmore-click">Add More</a>
                                        </h5>
                                    </div>
                                    }


                                    <div className="time-log-sub-list-select">

                                        <form onSubmit={this.handleSubmit}>
                                            <div className="time-log-sub-list-remark">
                                                <label className="">Your Remarks</label>
                                                <textarea className="log-time-remark" id="markComment" name="remark"
                                                          placeholder="Type your remarks  here..." onChange={(e) => {
                                                    this.handleinputChange(e)
                                                }}></textarea>
                                            </div>

                                            {(timelogData.length === 0 || this.state.action === "addtime") &&
                                            <div className="log-container">
                                                <div className="log-label-block">
                                                    <label>How much time did you work on this?</label>
                                                </div>
                                                <TimeLogForm getTimeLogEntries={this.getTimeLogEntries.bind(this)}/>

                                            </div>
                                            }
                                            {timelogData.map((log, index) =>
                                                <TimeLogList index={index} log={log} key={`timelog${index}`}/>
                                            )}

                                            <div className="time-log-bottom">
                                                <div className="attachment-task-group progress-ctr"
                                                     id="markasdoneFiles">
                                                    <div id="progress-file-done"></div>
                                                    {assigneesData.map((item, sindex) =>
                                                        item.hasOwnProperty("mark_as_done_comment") &&
                                                        item.mark_as_done_comment !== null &&
                                                        item.mark_as_done_comment !== undefined &&
                                                        item.mark_as_done_comment.length !== undefined &&
                                                        item.mark_as_done_comment.length > 0 &&
                                                        item.mark_as_done_comment.map((key, kindex) =>
                                                                key.hasOwnProperty("attachment") && key.attachment.length > 0 && key.attachment.map((list, index) =>
                                                                    <div className="ind-attach-list file"
                                                                         id={"attachment_" + list.filename}
                                                                         key={"attachment_-" + index}>
                                                                        <div className="select-chk-block"
                                                                             style={{display: "block"}}>
                                                                            <label className="control control-checkbox"
                                                                                   data-action="organise">
                                                                                <input type="checkbox" className="inp-chk"
                                                                                       onClick={(e) => this.selectAttachedFiles(e, list)}
                                                                                       name={"task_" + list.filename + "_checked"}
                                                                                    //  checked={!!this.props.relatedTasks.includes(item.masterid)}
                                                                                />
                                                                                <div className="control-indicator"/>
                                                                            </label>
                                                                        </div>
                                                                        <h5>{list.filetitle}</h5>
                                                                    </div>
                                                                )
                                                        )
                                                    )}
                                                    {this.state.uploadedFiles.map((item, index) =>
                                                        <div className="ind-attach-list file"
                                                             id={"attachment_" + item.fileId}
                                                             key={"attachment_-" + index}>
                                                            <h5>{item.originalfileName}</h5>
                                                            <button className="ico-lib cl-fl" data-parent={item.fileId}
                                                                    data-target="#delete-attachfile"
                                                                    data-action="unselectattachfiles"
                                                                    onClick={(e) => this.removeSelectedAttachment(index)}></button>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* <div className="time-log-sub-list">
                                        <div className="time-log-items">
                                            <div className="file-upload">
                                                <UploadFile getUploadedFile={this.getUploadedFile.bind(this)} progressId="progress-file-done"  />
                                                <label>Upload a file</label>
                     1                       </div>
                                        </div>
                                    </div> */}
                                            </div>


                                            {/*<input type="submit" value="Submit" />*/}
                                        </form>
                                    </div>


                                </div>


                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="options-button attach black-shade" type="button">
                                <UploadFile getUploadedFile={this.getUploadedFile.bind(this)}
                                            progressId="progress-file-done"/>
                            </button>
                            <button type="button"
                                    className={"general-btn grey " + (this.state.markasdoneloading === 1 ? ' disable' : '')}
                                    data-dismiss="modal" onClick={(e) => {
                                this.closemarkasdoneModal(e)
                            }}>Cancel
                            </button>
                            <button id="add_timelog" className="general-btn" data-function="markasdone"
                                    onClick={(e) => {
                                        this.markasDone(e)
                                    }}>Done
                            </button>
                        </div>
                    </SimpleModal>
                    }
                    {this.state.showverifyModal === true &&
                    <SimpleModal
                        Close={() => this.closeverifyModal()}
                        title={`Verify Task`}
                        haveFooter={false}
                    >
                        <div className="modal-body">
                            {this.props.taskdetailsData.loading === true &&
                            <span className="Loader-holder">
                               <LoaderSvg/>
                           </span>
                            }
                            <div className="add-lb-nem">
                                <textarea id="verification" className="task-textarea" name="remark"
                                          placeholder="Remarks(Mandatory)" onChange={(e) => {
                                    this.handleinputChange(e)
                                }}></textarea>
                                <div className="mdone-attach-bg ">
                                    <ul className="">
                                        <li className="attach-upload ico-lib">
                                            <label htmlFor="verificationAttach">
                                                <div>Upload a file</div>
                                                <UploadFile getUploadedFile={this.getUploadedFile.bind(this)}
                                                            progressId="progress-file-verify"/>
                                            </label>
                                        </li>
                                        <li className="">
                                            <div className="progress ui-progress hide "
                                                 id="progress_verificationAttach">
                                                <div id="progress-bar_verificationAttach" className="progress-bar"
                                                     role="progressbar" aria-valuenow="0" aria-valuemin="0"
                                                     aria-valuemax="100">
                                                </div>
                                            </div>

                                        </li>
                                    </ul>
                                </div>
                                <div id="progress-result_verificationAttach" className="progress-ctr">
                                    <div id="progress-file-verify"></div>
                                    {this.state.uploadedFiles.map((item, index) =>
                                        <div className="ind-attach-list file" id={"attachment_" + item.fileId}
                                             key={"attachment_" + item.fileId}>
                                            <h5>{item.originalfileName}</h5>
                                            <button className="ico-lib cl-fl" data-parent={item.fileId}
                                                    data-target="#delete-attachfile" data-action="unselectattachfiles"
                                                    onClick={(e) => this.removeSelectedAttachment(index)}></button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer checklist-footer">
                            <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {
                                this.closeverifyModal()
                            }}>Cancel
                            </button>
                            <button type="button" id="postVerify" className="general-btn" onClick={(e) => {
                                this.verify()
                            }}>Verify
                            </button>
                        </div>
                    </SimpleModal>
                    }
                    {this.state.showpreviewmodal === true &&

                    <SimpleModal
                        Close={() => this.closepreviewmodal()}
                        title={``}
                        haveFooter={true}
                    >
                        <div className="preview-area chat-panel">

                            {this.state.previewType === "all" &&
                            <>
                                {previewableFiles.map((item, index) => {
                                        let filename = (this.state.attachType === 'resource') ? item.res_img : item.filename;
                                        return (
                                            <React.Fragment key={`previewattchment${index}`}>
                                                {isImage(filename) &&
                                                <img id="attachPrv" src={FRMSIMG_PATH + filename}
                                                     style={{display: (previewIndex === index) ? 'block' : 'none'}}
                                                     key={"prev-attach-" + index}/>
                                                }

                                                {isVideo(filename) &&
                                                <VideoPlayer display={previewIndex === index ? 'block' : 'none'} width="320"
                                                             height="240" src={FRMSVID_PATH + filename}/>

                                                }
                                            </React.Fragment>
                                        )
                                    }
                                )}
                                {previewableFiles.length > 1 &&
                                <div className="slider-button-block">
                                    <button className="slider-button ico-lib slide-left" data-movement="prv"
                                            data-id="0" style={{opacity: (previewIndex > 0) ? '1' : '0'}}
                                            onClick={(e) => this.setPreviewIndex('prev')}>
                                    </button>
                                    <button className="slider-button ico-lib slide-right" data-movement="next"
                                            data-id="1"
                                            style={{opacity: (previewIndex < previewableFiles.length - 1) ? '1' : '0'}}
                                            onClick={(e) => this.setPreviewIndex('next')}>
                                    </button>
                                </div>
                                }
                            </>
                            }
                        </div>
                        <div className="modal-footer">
                            <>
                                {getFileIcon(previewedFile) === 'image' &&
                                <a className="general-btn-a" id="downloadPrv"
                                   href="javascript:;"
                                   onClick={() => forceDownload(FRMSIMG_PATH + previewedFile, previewedFileTitle)}>Download</a>
                                }
                                {getFileIcon(previewedFile) === 'mp4' && download === "yes" &&
                                <a className="general-btn-a" id="downloadPrv"
                                   href="javascript:;"
                                   onClick={() => forceDownload(FRMSVID_PATH + previewedFile, previewedFileTitle)}>Download</a>
                                }
                            </>
                        </div>
                    </SimpleModal>
                    }
                    {this.state.open &&
                    <Modal title={this.state.taskAction === 'subtask' ? 'subtask' : 'Update Task'}
                           Close={this.modalOpen.bind(this)}>
                        <AddToDoTask
                            modalOpen={this.modalOpen}
                            taskDetailId={atob(getUrlSegment(3))}
                            taskData={taskData}
                            allfilesData={allfilesData}
                            taskAction={this.state.taskAction}
                            secId={taskData.parent && taskData.parent.project_secid ? taskData.parent.project_secid:0}
                        />
                    </Modal>
                    }
                    {this.state.showFormModal &&
                    <ShowFormModal
                        formName={this.state.formName}
                        collectionId={this.state.collectionid}
                        referenceId={this.state.taskid}
                        hideFormModal={this.hideFormModal}
                        formSource='task'
                        editId={this.state.editId}
                        markAsDoneOnFormSubmit={this.state.markAsDoneOnFormSubmit}
                        markasdoneparams={this.state.markasdonedata}
                    />
                    }
                    {this.state.showRecordModal &&
                    <ShowRecordModal
                        formName={this.state.formName}
                        collectionId={this.state.collectionid}
                        referenceId={this.state.taskid}
                        hideRecordModal={this.hideCollectionRecords}
                        formSource='col-task'
                        recordIds={this.state.dataIds}
                        collecteddataid={this.state.collecteddataid}
                    />
                    }
                    {this.state.showPreviewFormModal &&
                    <ShowFormPreview
                        formName={this.state.formName}
                        collectionId={this.state.collectionid}
                        previewForm={this.previewForm}
                        hidePreviewForm={this.hidePreviewForm}
                        state={this.state}
                        slideShow={this.showPreviewFormModal}
                        CollectionList={collectionData}
                        formSource={formSource}
                    />
                    }
                    {this.state.showReopenConfirm &&
                    <AlertModal
                        id="reopen-todo"
                        title="Are you sure you want to reopen this task?"
                        OK_button_text="Yes, Reopen It"
                        OK_action={() => this.showReopenModal()}
                        showModal={this.state.showReopenConfirm}
                        CANCEL_action={() => this.showReopenConfirm()}
                    />
                    }
                    {this.state.quitPooltask &&
                    <AlertModalPool
                        id="quit-pool"
                        title={"Pool Task"}
                        desc="Are you sure you want to quit this task?"
                        OK_button_text="Yes, Quit It"
                        OK_action={this.quitPoolTask}
                        showModal={this.state.quitPooltask}
                        CANCEL_action={() => this.hidePoolTaskConfirm()}
                    />
                    }


                    {this.state.previewStatus &&
                    <SimpleModal
                        Close={this.closePreview}
                        extraClass="m-modal preview-task-at"
                    >
                        <div className="preview-area chat-panel">
                            {isImage(this.state.url) &&
                            <img id="attachPrv" src={this.state.url}
                            />
                            }
                            {/*{//console.log(this.state.item)}*/}
                            {isVideo(this.state.url) &&

                            <VideoPlayer width="320" height="240" resType={this.state.item.res_type}
                                         fileName={this.state.item.res_img} src={this.state.url}/>
                            }

                            {isAudio(this.state.url) &&
                            <audio width="320" height="240" controls
                            >
                                <source src={this.state.url}
                                        type="audio/mpeg">
                                </source>
                            </audio>
                            }
                            {this.state.item !== undefined && this.state.item.res_type === '6' &&
                            <iframe width="500" height="250" src={this.state.item.res_img} alt="Thumb-img"/>
                            }
                            {this.state.albumName !== "videos" && this.state.download === "yes" &&
                            <div className="preview-options">
                                <ul className="resources-filters-icons">
                                    <li><a className="rs-tab-btn ico-lib-v1 rc-download single-option"
                                           onClick={() => forceDownload(this.state.url, this.state.name)}> </a>
                                    </li>
                                </ul>
                            </div>
                            }
                        </div>
                    </SimpleModal>
                    }
                    {this.state.showReopenModal &&
                    <SimpleModal
                        Close={() => this.closeReopenModal()}
                        title={`Reopen task`}
                        haveFooter={false}
                    >
                        <div className="modal-body">
                            <div className="modal-time-log">

                                {this.props.taskdetailsData.loading === true &&
                                <span className="Loader-holder">
                                                 <LoaderSvg/>
                                            </span>
                                }

                                <div className="time-log-top">
                                    <div className="time-log-sub-list-select">
                                        <form onSubmit={this.handleSubmit}>


                                            <div className="time-log-sub-list-remark">
                                                    <textarea
                                                        className={"log-time-remark " + (this.state.reOpenSubmit && (!this.state.remark || this.state.remark === '') ? 'val-error' : '')}
                                                        id="markComment" name="remark"
                                                        placeholder="Remark (Mandatory)" onChange={(e) => {
                                                        this.handleinputChange(e)
                                                    }}></textarea>
                                            </div>

                                            <div className="time-log-bottom">
                                                <div className="attachment-task-group progress-ctr" id="todoFileAssets">
                                                    <div id="progress-file-reopen"></div>
                                                    {this.state.uploadedFiles.map((item, index) =>
                                                        <div className="ind-attach-list file"
                                                             id={"attachment_" + item.fileId}
                                                             key={"attachment_" + item.fileId}>
                                                            <h5>{item.originalfileName}</h5>
                                                            <button className="ico-lib cl-fl" data-parent={item.fileId}
                                                                    data-target="#delete-attachfile"
                                                                    data-action="unselectattachfiles"
                                                                    onClick={(e) => this.removeSelectedAttachment(index)}></button>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="time-log-sub-list">
                                                    <div className="time-log-items">
                                                        <div className="file-upload">
                                                            <UploadFile
                                                                getUploadedFile={this.getUploadedFile.bind(this)}
                                                                progressId="progress-file-reopen"/>
                                                            <label>Upload a file</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {
                                this.closeReopenModal(e)
                            }}>Cancel
                            </button>
                            <button id="reopen_task"
                                    className={"general-btn " + (this.state.reopenloading ? 'disable' : '')}
                                    onClick={(e) => this.state.mainTask ? this.reopenAllTask() : this.reopenTask()}>Reopen
                            </button>
                        </div>
                    </SimpleModal>
                    }
                    {this.state.showArchive &&
                    <AlertModal
                        id="take-pool"
                        title={this.state.archive_action === "archive" ? "Are you sure want to archive this task?" : "Are you sure want to unarchive this task?"}
                        OK_button_text={this.state.archive_action === "archive" ? "Yes, Archive It" : "Yes, Unarchive It"}
                        OK_action={() => this.props.moveTasksToArchive({
                            param: this.state.archive_action,
                            uid: getProfile().id,
                            _id: taskid
                        })}
                        showModal={this.state.showArchive}
                        CANCEL_action={() => this.showConfirmArchive()}
                    />
                    }

                    {this.state.forceclose &&
                    <AlertModal
                        id="reopen-todo"
                        title="Are you sure you want to close this task?"
                        OK_button_text="Yes"
                        OK_action={() => this.closebyCreator()}
                        showModal={true}
                        CANCEL_action={() => this.forceClose()}
                    />
                    }


                    {this.state.deleteTask &&
                    <AlertModal
                        id="delete-pool"
                        title={this.state.delete_action === "delete" ? "Are you sure you want to delete this task?" : "Are you sure you don't want to delete this task?"}
                        OK_button_text={this.state.delete_action === "delete" ? "Yes, Delete It" : "No, Don't delete It"}
                        OK_action={() => this.props.deleteTaskPermanent({
                            _id: deleteid,
                            secretKey: "lsuser12345!",
                            index: ES_INDEX
                        })}
                        showModal={this.state.deleteTask}
                        CANCEL_action={() => this.deleteTaskConfirm()}
                    />
                    }
                    {this.state.changeTaskStatus &&
                    <AlertModal
                        id="change-task"
                        title={this.state.statusOfTask === "New" ? "Are you sure you want to Change the task status to 'New'?" : "Are you sure you want to Change the task status to 'In Progress'"}
                        OK_button_text={this.state.statusOfTask ? "Yes" : "No"}
                        OK_action={() => this.props.updateTaskStatus({
                            taskid: atob(getUrlSegment(3)),
                            status: this.state.statusOfTask,
                            uid: getProfile().id,
                            index: ES_INDEX
                        })}
                        showModal={this.state.changeTaskStatus}
                        CANCEL_action={() => this.showTaskCancel(this.state.statusOfTask)}
                    />
                    }
                    {this.state.dateChoosen &&
                    <SimpleModal
                        Close={() => this.dateSwap(false)}
                        title={`Reschedule Task`}
                        haveFooter={1}
                        cancelAction={() => this.dateSwap(false)}
                        OKAction={() => this.updatetaskdata(false)}
                        OKLabel={`Apply`}
                        modalType={'custom-date-range'}
                    >
                        <div className="reschedule-wrapper">
                            <DateTimeField
                                name='duedate' placeholder="Due Date" id="duedate"
                                value={this.state.duedate !== '' ? this.state.duedate : null}
                                taskAction={this.state.taskAction}
                                setFieldValue={this.setFieldValue}
                                //submitted={this.state.submitted}
                                min={min}
                                updatetask={this.updatetask}
                                dateSwap={this.dateSwap}
                                date_error={this.state.date_error}
                                showTime={true}
                            />

                        </div>


                    </SimpleModal>
                    }
                    {this.state.showPreviewAlert &&
                    <SimpleModal
                        Close={() => this.PreviewAlert()}
                        title={`Preview`}
                        haveFooter={false}
                    >
                        No form data has been filled in yet.

                    </SimpleModal>
                    }

                    {this.state.subtask_added &&
                    <AlertModal
                        id="subtask-add-success"
                        title="Subtask created successfully"
                        OK_button_text="OK"
                        OK_action={this.hideSubTaskMessage}
                        showModal={this.state.subtask_added}
                        CANCEL_button={"hide"}
                    />
                    }

                    {this.state.hasFileAttachment &&
                    <div style={{display: this.state.displayStatus}}>
                        <SimpleModal
                            title={`ADD FILES`}
                            Close={this.fileTabUpload}
                            haveFooter={1}
                            OKAction={() => this.fileUpload()}
                            OKLabel={`Submit`}
                            cancelAction={() => this.fileTabUpload()}
                        >

                            <FileUpload
                                AutoUpdate={true}
                                getUploadedFile={this.handleFiles}
                                multipleFiles={true}
                                overLay={true}
                                dropZone={true}
                                wrapperClass=""
                                progressBar={true}
                                progressContainer={`progress-holder`}
                                validationError={this.state.fileError}
                                secureMode={this.props.secured}
                            />
                        </SimpleModal>
                    </div>
                    }
                    {this.state.addRelatedTaskModal &&
                    <AddNewRelatedTask
                        showRelatedTask={this.showRelatedTask}
                        addRelatedTask={this.addRelatedTask}
                        myTaskListListing={this.props.myTaskListing.data}
                        myTaskLoader={this.props.myTaskListing}
                        selectTasks={this.selectTasks}
                        relatedTasks={this.state.relatedTasks}
                        handleMyTaskChange={this.handleMyTaskChange}
                    />
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }


}

const mapStateToProps = state => ({
    taskdetailsData: state.TaskReducer.TaskDetails,
    moretaskDetailsdata: state.TaskReducer.MoretaskDetails,
    timelogDetails: state.TaskReducer.Gettimelog,
    remarksData: state.TaskReducer.GetRemarks,
    projects: state.TaskReducer.GetProjectData,
    taskSelect: state.TaskReducer.getTaskSelection,
    modifyTask: state.TaskReducer.ModifyTask,
    taskFilters: state.TaskReducer.filterTasks,
    onlineusers: state.CommonReducer.onlineusers,
    markasdonestatus: state.TaskReducer.MarkAsDoneStatus,
    reassigntask: state.TaskReducer.ReassignTask,
    multiLoader: state.CommonReducer.multiLoader,
    HistoryPushData: state.TaskReducer.HistoryPushData,
    todolog: state.TaskReducer.TodoLoglist,
    assigneetimelogdetails: state.TaskReducer.AssigneeTimelog,
    getFormdetails: state.CollectionsReducer.getFormData,
    poolTasksData: state.TaskReducer.getPoolData,
    listUsersPermission: state.UsersReducer.listUsersPermissiondata,
    forceClose: state.TaskReducer.forceClose,
    Attachmentlist: state.TaskReducer.Attachmentlist,
    getrelatedTask: state.TaskReducer.getRelatedtask,
    todoListing: state.TaskReducer.listtoDo,
    myTaskListing: state.TaskReducer.myTaskList,
    whatsappReply: state.TaskReducer.whatsappReply
});

export default withRouter(connect(mapStateToProps, {
    toVerify: taskActions.listtask,
    gettaskdetails: taskActions.taskdetails,
    gettaskdetailsAsync: taskActions.gettaskdetailsAsync,
    getmoretaskdetails: taskActions.moretaskdetails,
    markasdone: taskActions.markasdone,
    verifytask: taskActions.verify,
    addtimelog: taskActions.addtimelog,
    gettimelog: taskActions.gettimelog,
    getremarks: taskActions.getremarks,
    savediscussion: taskActions.savediscussion,
    users: usersActions.listUsers,
    showform: collectionActions.showform,
    getFormData: collectionActions.getFormData,
    projectData: taskActions.getProjectData,
    tagData: taskActions.getTagData,
    moveTasksToArchive: taskActions.moveTasksToArchive,
    deleteTaskPermanent: taskActions.deleteTaskPermanent,
    saveToDoAttachments: taskActions.saveToDoAttachments,
    reopenTask: taskActions.reopenTask,
    reopenAllTask: taskActions.reopenAllTask,
    todo: taskActions.listtask,
    getTaskHelper: taskActions.getTaskHelper,
    TaskCompletionasync: taskActions.postTaskCompletionasync,
    notificationdata: usersActions.notificationInfo,
    dateupdatetask: taskActions.dateupdatetask,
    gettodolog: taskActions.gettodologs,
    getassigneetimlog: taskActions.getassigneetimelog,
    quitTodoPoolTask: taskActions.quitTodoPoolTask,
    updateTaskStatus: taskActions.updateTaskStatus,
    completePoolTask: taskActions.completePoolTask,
    handleClap: taskActions.handleClap,
    closedbyCreator: taskActions.closedByCreator,
    uploadAttachments: taskActions.uploadAttachments,
    getattchmentlist: taskActions.getattchments,
    saveReleatedTask: taskActions.saveReleatedtask,
    getRelatedtask: taskActions.getRelatedtask,
    getMytask: taskActions.getmyTask,
    saveNewChat: whatsappActions.saveNewChat,
    updateWhContent: taskActions.updateWhContent,
    saveChatmessage: taskActions.saveChatmessage,
    resetForm: collectionActions.resetForm,
    resetTaskProps: taskActions.resetTaskProps,

})(TaskDetails));


