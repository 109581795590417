import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {
    Canvas,
    Palette,
    state,
    Trash,
    core,
    Preview,
    registerPaletteElements
} from 'react-page-maker';
import { elements } from '../dashboardBuilder/const';
import DraggableLayoutR3C3 from '../dashboardBuilder/elements/layout/DraggableLayoutR3C3';
import DraggableLayoutR1C2 from '../dashboardBuilder/elements/layout/DraggableLayoutR1C2';
import DraggableLayoutR1C1 from '../dashboardBuilder/elements/layout/DraggableLayoutR1C1';
import DraggableLayoutR1C3 from '../dashboardBuilder/elements/layout/DraggableLayoutR1C3';
import DraggableLayoutR1C4 from '../dashboardBuilder/elements/layout/DraggableLayoutR1C4';
import BarchartContainer from '../dashboardBuilder/elements/container/BarchartContainer';
import AreachartContainer from '../dashboardBuilder/elements/container/AreachartContainer';
import LinechartContainer from '../dashboardBuilder/elements/container/LinechartContainer';
import ScatterChartContainer from "../dashboardBuilder/elements/ScatterChartContainer"
import PieChartTwoLevelContainer from "../dashboardBuilder/elements/PieChartTwoLevelContainer"
import CardContainer from "../dashboardBuilder/elements/container/CardContainer"
import LabelContainer from "../dashboardBuilder/elements/container/LabelContainer"
import HierarchicalContainer from "../dashboardBuilder/elements/container/HierarchicalContainer"
import MultiareaChart from "../dashboardBuilder/elements/container/MultiareaChart"
import FeaturedBlockContainer from "../dashboardBuilder/elements/container/FeaturedBlockContainer"
import AreaBarChartContainer from "../dashboardBuilder/elements/container/AreaBarChartContainer"
import StackedBarChartContainer from "../dashboardBuilder/elements/container/StackedBarChartContainer"
import TreeMapContainer from "../dashboardBuilder/elements/container/TreeMapContainer"
import Radar from "../dashboardBuilder/elements/container/TreeMapContainer"
import {Button, Col,Tab,Tabs , Container, Accordion , Card, Row} from "react-bootstrap";
import RadarchartContainer from "../dashboardBuilder/elements/container/RadarchartContainer";
import RadialBarChartContainer from "../dashboardBuilder/elements/container/RadialBarChartContainer";
import '../dashboardBuilder/App.css';
import {getProfile} from "../../utilities/AuthService";
import * as collectionActions from "../../actions/Collections";
import * as DynamicDashboard from "../../actions/DynamicDashboard";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import axios from "axios";
import {API_PATH, ES_INDEX, SHARED_LOGIN, USRIMG_PATH} from "../../constants";
import rootActions from "../../actions";
import ProjectHeader from "../views/ProjectHeader";
import DashboardMilestoneTile from "../views/DashboardMilestoneTile";
import * as projectActions from "../../actions/Project";
import LoaderSvg from "../views/LoaderSVG";
import BubbleMapContainer from "../dashboardBuilder/elements/container/BubbleMapContainer";
import ColorMapContainer from "../dashboardBuilder/elements/container/ColorMapContainer";
import AreaColorMapContainer from "../dashboardBuilder/elements/container/AreaColorMapContainer";

let initialElements=[]
class ProjectDashboards extends Component {
    constructor(props) {
        super(props);
        registerPaletteElements([
            {
                type: elements.BARCHART,
                component: BarchartContainer,
            },
            {
                type: elements.LINECHART,
                component: LinechartContainer
            },
            {
                type: elements.AREACHART,
                component: AreachartContainer
            },
            {
                type: elements.TREEMAP,
                component: TreeMapContainer
            },
            {
                type: elements.HIERARCHICAL_CONTAINER,
                component: HierarchicalContainer
            },
            {
                type: elements.RADIAL_BAR_CHART_CONTAINER,
                component: RadialBarChartContainer
            },
            {
                type: elements.BUBBLE_MAP_CONTAINER,
                component: BubbleMapContainer
            },
            {
                type: elements.COLOR_MAP_CONTAINER,
                component: ColorMapContainer
            },
            {
                type: elements.AREA_COLOR_MAP_CONTAINER,
                component: AreaColorMapContainer
            },
            {
                type: elements.MULTIAREA_CHART,
                component: MultiareaChart
            },

            {
                type: elements.AREA_BAR_CHART,
                component: AreaBarChartContainer
            },
            {
                type: elements.STACKED_BAR_CHATRT,
                component: StackedBarChartContainer
            },
            {
                type: elements.RADAR_CHART,
                component: RadarchartContainer
            },
            {
                type: elements.FEATURED_BLOCK,
                component: FeaturedBlockContainer
            },
            {
                type: elements.CARDS,
                component: CardContainer
            },
            {
                type: elements.CONTENTHEADER,
                component: LabelContainer
            },
            {
                type: elements.GRID_LAYOUT_3_3,
                component: DraggableLayoutR3C3
            }, {
                type: elements.GRID_LAYOUT_1_2,
                component: DraggableLayoutR1C2
            },
            {
                type: elements.GRID_LAYOUT_1_1,
                component: DraggableLayoutR1C1
            },
            {
                type: elements.GRID_LAYOUT_1_3,
                component: DraggableLayoutR1C3
            },
            {
                type: elements.GRID_LAYOUT_1_4,
                component: DraggableLayoutR1C4
            }

        ]);
        // state.clearState() triggers this event
        state.addEventListener('flush', (e) => {
            console.log('flush', e);
        });
        // state.removeElement() triggers this event
        state.addEventListener('removeElement', (e) => {
            console.log('removeElement', e);
        });
        // state.updateElement() triggers this event
        state.addEventListener('updateElement', (e) => {
            console.log('updateElement', e);
        });
        //this.setState({initialData:[]})
        this.expandHeader = this.expandHeader.bind(this);
        this.showProgress = this.showProgress.bind(this);
        this.changeHeaderView=this.changeHeaderView.bind(this);
        this.state={
            loading:true,
            expandHeader:false
        }
    }

    componentDidMount() {
        this.setState({ type:getUrlSegment(3)})
        this.changeHeaderView();
        initialElements=[]
        let params = {uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : 0, offset : 500};
        this.props.collections(params);
        var projectId = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/dashboard/'+projectId);
        this.fetchData()
        this.props.milestoneList({"projectid":atob(projectId)})
    }
    changeHeaderView(){
        if(getUrlSegment(3) === "dashboard" || getUrlSegment(3) === "cdashboard"){
            this.setState({ expandHeader:true})
        }else{
            this.setState({ expandHeader:false})
        }
    }
    fetchData(){
        //initialElements=[]
        var projectId = atob(getUrlSegment(4));
        var type = getUrlSegment(3);
        let obj=this
        this.setState({ loading:true,type:getUrlSegment(3)})
        axios({
            method: "GET",
            url: API_PATH + "project/dashboard?projectid="+projectId+"&index="+ES_INDEX+"&id=&type="+type,
            async: false,
            headers: {'Content-Type': 'application/json'}
        }).then(function (res) {
            var t=JSON.parse(res.data.results.data[0].dashboard)
            var initialData = t
            console.log(initialData)
            initialElements=initialData
            obj.setState({ loading:false})
            return res;
        }).catch(function (err) {
            console.log(err);
            initialElements=[]
            obj.setState({ loading:false})
            return err;
        });





    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location !== prevProps.location){
            this.fetchData()
        }
        if(prevProps.match !== this.props.match) {
            this.changeHeaderView();
        }
    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }
    showProgress(workDone,totalCount){
        let workProgress = 0;
        if(totalCount > 0){
            let currentProgress = Math.round( ( workDone / totalCount) * 100);
            let remainProgress = currentProgress % 5;
            workProgress = currentProgress - remainProgress;
        }
        return workProgress;
    }
    state = {
        activeTab: '1',
        currentState: [],
        field:1,
        uniqueId:1,
    }

    componentWillMount() {
        state.addEventListener('change', this._stateChange);
    }

    componentWillUnmount() {
        state.removeEventListener('change', this._stateChange);
    }

    _stateChange = (s) => {
        const newState = state.getStorableState();
        this.setState({ currentState: newState }, () => {
           // localStorage.setItem('initialElements', JSON.stringify(newState));
        });
        console.log(JSON.parse(localStorage.getItem('initialElements')))

    }
    // re-hydrate canvas state
   initialElements = initialElements
    // define all palette elements that you want to show
    paletteItemsToBeRendered1 = [
        {
            type: elements.GRID_LAYOUT_1_1,
            name: '1 by 1 Grid Layout',
            id: '1-1-grid'
        },
        {
            type: elements.GRID_LAYOUT_1_2,
            name: '1 by 2 Grid Layout',
            id: '1-2-grid'
        },
        {
            type: elements.GRID_LAYOUT_1_3,
            name: '1 by 3 Grid Layout',
            id: '1-3-grid'
        },
        {
            type: elements.GRID_LAYOUT_1_4,
            name: '1 by 4 Grid Layout',
            id: '1-4-grid'
        },
        {
            type: elements.GRID_LAYOUT_3_3,
            name: '3 by 3 Grid Layout',
            id: '3-3-grid'
        }

    ]
    paletteItemsToBeRendered2 = [
        {
            type: elements.BARCHART,
            name: 'Barchart',
            id: 'barchart-1'
        },
        {
            type: elements.AREACHART,
            name: 'Areachart',
            id: 'areachart-1'
        },
        {
            type: elements.LINECHART,
            name: 'Linechart',
            id: 'linechart-1'
        },
        {
            type: elements.JOINCHART,
            name: 'Joint Scatter',
            id: 'jointchart-1'
        },
        {
            type: elements.SCATTERCHART,
            name: 'Scatter',
            id: 'scatterchart-1'
        },
        {
            type: elements.PIECHARTTWOLEVEL,
            name: 'Pie chart two level',
            id: 'piechart-1'
        },
        {
            type: elements.CARDS,
            name: 'Card View',
            id: 'cards-1'
        }

    ]
    paletteItemsToBeRendered3 = [
        {
            type: elements.CONTENTHEADER,
            name: 'Header Block',
            id: 'content-header'
        }

    ]



    _onDrop = (data, cb) => {
        // no need to ask id and name again
        if (data.payload && data.payload.dropped) {
            return cb(data);
        }
        let name = data.name;
        let uniqueId=localStorage.getItem('uniqueId')?parseInt(localStorage.getItem('uniqueId')):0
        let newid=uniqueId+1
        const id = "id_"+(newid)
        localStorage.setItem('uniqueId', newid);
        const result = cb({
            ...data,
            name,
            id,
            payload: { dropped: true }
        });
    }
    _clearState = () => {
        state.clearState();
        localStorage.setItem('uniqueId', 0);
    }


    render() {
        console.log(initialElements)
        let mileStoneList = this.props.listmilestone.ListAllMilestones ? this.props.listmilestone.ListAllMilestones:[];
        let projectData = this.props.getheaderData.Overviewdata;
        let goals = projectData.goals ? projectData.goals:[];


        if(this.state.loading)
            return (<span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
            )
        else {
            return (
                <div
                    className={`form-detail-container data-view field-form ${(this.state.type === "cdashboard" || (this.state.type === "dashboard" && this.state.expandHeader === true)) ? " " : "overlay-mode"}  ${this.state.expandHeader === true ? "expands" : ""}`}>
                    <ProjectHeader expandHeader={this.expandHeader}/>
                    {(initialElements.length === 0 && this.state.type === "cdashboard" ) &&
                    <div className={`ibt-body  dynamic-dashboard`}>
                    
                        <div>
                            <div className="mt-3">
                                <div className="row row">
                                    <div className="col-sm-12">
                                        <div className="ibt-goal-block">
                                            {goals.length > 0 &&
                                            <h5>SDG Goals({goals.length})</h5>
                                            }
                                            <ul>
                                                {goals.map((item,index)=>
                                                    <li key={index}>
                                                        <img src={USRIMG_PATH+item.imgurl} />
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                </div>
                 }

                    {(initialElements.length !==0) &&
                    <React.Fragment>
                        <div className={`ibt-body  dynamic-dashboard`}>
                        {this.state.type === "cdashboard" &&
                    <div>
                        <div className="mt-3">
                            <div className="row row">
                                <div className="col-sm-12">
                                    <div className="ibt-goal-block">
                                        {goals.length > 0 &&
                                        <h5>SDG Goals({goals.length})</h5>
                                        }
                                        <ul>
                                            {goals.map((item,index)=>
                                                <li key={index}>
                                                    <img src={USRIMG_PATH+item.imgurl} />
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                            <Preview>
                                {
                                    ({children}) => (
                                        <>
                                            {children}
                                        </>
                                    )
                                }
                            </Preview>
                            {(this.state.type === "dashboard" || this.state.type === "cdashboard" ) &&
                            <React.Fragment>
                                {this.state.type === "dashboard" &&
                                <div>
                                    <div className="mt-3">
                                        <div className="row row">
                                            <div className="col-sm-12">
                                    <div className="ibt-goal-block">
                                        {goals.length > 0 &&
                                        <h5>SDG Goals({goals.length})</h5>
                                        }
                                        <ul>
                                            {goals.map((item,index)=>
                                                <li key={index}>
                                                    <img src={USRIMG_PATH+item.imgurl} />
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                <div>

                                    <div className="mt-3">
                                        <div className="row row">
                                            <div className="col-sm-12">
                                                <DashboardMilestoneTile
                                                    mileStoneList={mileStoneList}
                                                    showProgress={this.showProgress}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>

                            }
                        </div>
                        <div style={{"display": "none"}}>
                            <Canvas initialElements={initialElements}/>
                        </div>
                    </React.Fragment>
                    }


                </div>
            );
        }


    }
}
const mapStateToProps = state => ({
    listAllPortfolioData:state.SectorReducer.listallPortfolio,
    SavePortfolioDet : state.SectorReducer.SavePortfolio,
    searchData:state.CommonReducer.searchparamdata,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    listmilestone:state.ProjectReducer.milestonelist,
    getheaderData:state.ProjectReducer.GetOverviewdata,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    collections: collectionActions.listallcollections,
    milestoneList:projectActions.milestoneList
})(ProjectDashboards));
