import React, {Component} from 'react';
import {workflow} from "../../services/workflow.services";
import OutsideAlerter from "./OutsideAlerter";
import {getProfile} from "../../utilities/AuthService";
import AutoComplete from "./AutoComplete";
import DateTimePickerBox from "./DateTimePickerBox";
import moment from "moment";
import AlertModal from "./AlertModal";
import LoaderSvg from "./LoaderSVG";
import {Tabs, Tab, Nav, Col, Row} from 'react-bootstrap'
import {Scrollbars} from "react-custom-scrollbars";
import NoData from "./NoData";
let initialElements =
    [
        {
            "type":"text",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "name":"equal",
                    "value":"equal"
                },
                {
                    "name":"contain",
                    "value":"contain"
                }
            ],
            "placeholder":"Enter Text",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"equal",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"textarea",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "name":"equal",
                    "value":"equal"
                },
                {
                    "name":"contain",
                    "value":"contain"
                }
            ],
            "placeholder":"Enter Text",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"equal",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"number",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "value":"greater",
                    "name":"Greater than ( > )"
                },
                {
                    "value":"greatequal",
                    "name":"Greater than or equal ( >= )"
                },
                {
                    "value":"equal",
                    "name":"Equal ( = )"
                },
                {
                    "value":"less",
                    "name":"Less than ( < )"
                },
                {
                    "value":"lessequal",
                    "name":"Less than or equal ( <= )"
                }
            ],
            "placeholder":"Enter no of days",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"greater",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"email",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "name":"equal",
                    "value":"equal"
                },
                {
                    "name":"contain",
                    "value":"contain"
                }
            ],
            "placeholder":"Enter Text",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"equal",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"dropdown",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"checkbox",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"switch",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"datepicker",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "name":"on",
                    "value":"On"
                },
                {
                    "name":"before",
                    "value":"Before"
                },
                {
                    "name":"after",
                    "value":"After"
                }
            ],
            "placeholder":"Enter no of days",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"on",
                        "value":""
                    }
                ]
            }
        }
    ]

class ScheduleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showlistField:[],
            showFieldList:[],
            showSave: true,
            tab: 'generic',
            action: "generic",
            actionStatus: false,
            ruleData: [],
            generic:false,
            fieldwise:false,
            workflow:false,
            workflowstatus:false,
            genericRule:false,
            fieldwiseRule:false,
            listRule:{generic:[],fieldwise:[]},
            showDeleteGeneric:false,
            removeWorkflowTest:false,
            mode:"save",
            addStatus:'Save',
            child:"",
            workflowid:'',
            errorMsg:'',
            editData:{
                model: "",
                fieldItem: [],
                type: "",
                rule_type: "",
                referenceId: "",
                pattern: [],
                patternItem:[],
                startDate: "",
                endDate: "",
                masterid: "",
                ruleDescription: "",
                ruletitle: "",
                reference: "",
                users: [],
                priority: "",
                status: "",
                creator: "",
                createdate: "",
                id: "",
                customapi:'',
                done_onsubmit:false,
                indvidualtask:false,
            },
            genericChild:'',
            fieldwiseChild:'',
            errors:{
                startDate:false,
                endDate:false,
                ruleDescription:false,
                rule_type:false,
                ruletitle:false,
                patternBox:false,
                customapi:false,
                type:false,
                priority:false,
            },
            options:[]
        }
    }
    componentDidMount() {
        this.setState({listRule:this.props.listRule})
        if(this.props.source === "batch" ){
            this.setState({tab: "generic",action:"generic"})
        }
        this.labelData()
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.listRule !== prevProps.listRule){
            this.setState({listRule:this.props.listRule})
        }
        if(this.props.label.showformdata !== prevProps.label.showformdata){
            this.labelData()
        }

    }
    clearstate(){
        this.setState({
            editData:{
                model: "",
                fieldItem: [],
                type: "",
                rule_type: "",
                referenceId: "",
                pattern: [],
                patternItem:[],
                startDate: "",
                endDate: "",
                masterid: "",
                ruleDescription: "",
                ruletitle: "",
                reference: "",
                users: [],
                priority: "",
                status: "",
                creator: "",
                createdate: "",
                id: "",
                child:"",
                customapi:'',
                done_onsubmit:false,
                indvidualtask:false,

            },errors:{
                startDate:false,
                endDate:false,
                ruleDescription:false,
                rule_type:false,
                ruletitle:false,
                patternBox:false,
                customapi:false,
                type:false,
                priority:false,

            },
            initialElements:initialElements

        })
        this.props.ruleClose('clearUser')
    }
    labelData=()=>{
        let showlistField=[]
        let showFieldList=[]
        if (this.props.label.showformdata.length !== 0) {
            for (let i = 0; i < this.props.label.showformdata.pages.length; i++) {
                (this.props.label.showformdata.pages[i].components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden")).map(list => {
                    showlistField.push(list)
                })
            }
            for (let i = 0; i < this.props.label.showformdata.pages.length; i++) {
                this.props.label.showformdata.pages[i].components.map(list => {
                    showFieldList.push(list)
                })
            }
            this.setState({showlistField:showlistField,showFieldList:showFieldList})
        }
    }
    tabSelect = (tabs) => {
        this.setState({tab: tabs,action:tabs})

    }
    setTab = (act,sTs) => {
        if(act === "generic"){
            if(sTs === "new" || sTs === "cancel"){
                this.setState({genericChild:''})
            }
            this.setState({action: act, generic: !this.state.generic,ruleData:[],fieldwise:'',workflow:'',addStatus:'Save'})
            this.clearstate()
        }
        if(act === "fieldwise"){
            if(sTs === "new" || sTs === "cancel"){
                this.setState({fieldwiseChild:''})
            }
            this.setState({action: act, fieldwise: !this.state.fieldwise,ruleData:[],generic:'',workflow:''})
            this.clearstate()
        }
        if(act === "workflow"){
            this.setState({action: act, workflow: !this.state.workflow,ruleData:[],fieldwise:'',generic:''})
            this.clearstate()
        }
        if(act === "dropdownrule"){
            this.setState({dropdownrule:act})
        }
    }
    handleChange(e, label, type, opt,masterid) {
        let random = Math.round(new Date().getTime() + (Math.random() * 100));
        var h=[]
        h=[...this.state.initialElements]
        let d=Array.from(h.filter(list => (list.type === type)))
        let deepCopyObj = JSON.parse(JSON.stringify(d));
        let mapData = deepCopyObj
        mapData[0][`name`] = "name-" + random
        mapData[0][`masterid`] = masterid.toString()
        mapData[0][`label`] = label
        mapData[0][`opt`] = opt
        let Ruledata = [...this.state.ruleData,mapData[0]]
        this.setState({ruleData: Ruledata,dropdownrule:''})
        this.setTab("")
    }
    removeFromWorkflow = (e, name) => {
        this.setState({removeWorkflowTest : !this.state.removeWorkflowTest, workflowname : name})
    }
    handleRemove = () => {
        let name = this.state.workflowname;
        let Rdata = this.state.ruleData.filter(list => list.name !== name)
        this.setState({ruleData: Rdata})
        this.removeWorkflowConfirm();
    }
    removeWorkflowConfirm = () => {
        this.setState({removeWorkflowTest : false})
    }
    actionStaus=(type,status,id)=>{
        if(type === "workflow"){
            this.setState({workflowstatus:status,id:id})
        }
        if(type === "genericRule"){
            this.setState({genericRule:status,id:id})
        }
        if(type === "fieldwiseRule"){
            this.setState({fieldwiseRule:status,id:id})
        }
    }
    deleteFromGeneric=(wid,type)=>{
        this.setState({showDeleteGeneric : !this.state.showDeleteGeneric, wid : wid, type : type})
    }
    handleDelete=()=>{
        let wid = this.state.wid;
        let type = this.state.type;
        if(type  ==="workflow") {
            let deleteWorkflow = {
                "_id": wid,// linking id
                "uid": getProfile().id, //uid
                "secretKey": "lsuser12345!",// use value lsuser12345!
            }
            this.props.deleteWorkflow(deleteWorkflow,type)
        }
        if(type  ==="genericRule" ||type  ==="fieldwiseRule" ) {

            this.props.deleteWorkflow(wid,type)
        }
        this.showDeleteConfirm();
    }
    showDeleteConfirm=()=>{
        this.setState({showDeleteGeneric : false});
    }
    addGeneralRule=(e)=> {
        e.preventDefault();
        let errors = this.state.errors;
        errors.startDate = this.state.editData.startDate === ""
        errors.endDate = this.state.editData.endDate === ""
        errors.rule_type = this.state.editData.rule_type === ""
        errors.type = this.state.editData.type === ""
        errors.priority = this.state.editData.priority === ""
        if(this.state.editData.rule_type !== 'customapi' && this.state.editData.rule_type !== 'autoassign' && this.state.tab === "generic") {
            errors.ruleDescription = this.state.editData.ruleDescription === ""
        }
        if(this.state.editData.startDate === '' && this.state.tab !== "workflow") {
            document.getElementById('startDate').classList.add('val-error');
        }
        if(this.state.editData.endDate === '' && this.state.tab !== "workflow") {
            document.getElementById('endDate').classList.add('val-error');
        }
        if(this.state.editData.rule_type === 'customapi' && this.state.tab === "fieldwise") {
            errors.customapi = this.state.editData.customapi === ""
        }
        if(this.state.editData.rule_type === '') {
            document.getElementById('rule_type').classList.add('val-error');
        }
        else{
            document.getElementById('rule_type').classList.remove('val-error');
        }
        if(this.state.editData.type === '') {
            document.getElementById('type').classList.add('val-error');
        }
        else{
            document.getElementById('type').classList.remove('val-error');
        }
        if(this.state.editData.priority === '' && this.state.tab !== "workflow") {
            document.getElementById('priority').classList.add('val-error');
        }
        else{
            document.getElementById('priority').classList.remove('val-error');
        }
        if(this.props.selectedDescriptionUsers.length === 0) {
            document.getElementById('selectedDescriptionUsers').classList.add('val-error');
        }
        else{
            document.getElementById('selectedDescriptionUsers').classList.remove('val-error');
        }
        if(this.state.editData.startDate !== '' && this.state.editData.endDate !== '' && this.state.tab !== "workflow") {
            if ((moment(this.state.editData.endDate).isBefore(this.state.editData.startDate)) === true) {
                document.getElementById('startDate').classList.add('val-error');
                document.getElementById('endDate').classList.add('val-error');
                errors.startDate = true
                errors.endDate = true
                this.setState({errorMsg:"The end date must be greater than the start date"})
            } else {
                document.getElementById('startDate').classList.remove('val-error');
                document.getElementById('endDate').classList.remove('val-error');
                errors.startDate = false
                errors.endDate = false
                this.setState({errorMsg:""})
            }
        }


        this.setState({errors})

        if (!errors.startDate && !errors.endDate && !errors.type  && !errors.priority && !errors.ruleDescription && !errors.customapi  && !errors.rule_type  && this.props.selectedDescriptionUsers.length !== 0 ) {
            if (this.state.tab !== "generic") {
                let editData = this.state.editData
                let fieldItem=[]
                this.state.ruleData.map(list=>{
                    fieldItem.push(list.fieldItem)
                })
                editData['fieldItem'] = fieldItem
            }
            if ((this.state.tab === "generic") || (this.state.tab === "fieldwise")) {
                let editData = {...this.state.editData}
                editData['patternItem'] = this.state.editData.patternformat
                editData['pattern'] = this.state.editData.type
                let child=""
                let mode="save"
                if(this.state.tab === "generic"){
                    let genericData = []
                    this.props.selectedDescriptionUsers.map((entry, index) => {
                        genericData[index] = {};
                        genericData[index]['id'] = entry.user_id;
                        genericData[index]['type'] = entry.user_type;
                        genericData[index]['name'] = entry.fullname;
                    })
                    editData['users'] = genericData

                    child=this.state.genericChild
                    if(child === "") {
                        mode = "save"
                    }
                    else{
                        mode = "update"
                    }
                    this.setState({editData,genericChild:''})
                }
                if(this.state.tab === "fieldwise"){
                    let fieldwiseData = []
                    this.props.selectedfieldUsers.map((entry, index) => {
                        fieldwiseData[index] = {};
                        fieldwiseData[index]['id'] = entry.user_id;
                        fieldwiseData[index]['type'] = entry.user_type;
                        fieldwiseData[index]['name'] = entry.fullname;
                    })
                    editData['users'] = fieldwiseData
                    child=this.state.fieldwiseChild
                    if(child === "") {
                        mode = "save"
                    }
                    else{
                        mode = "update"
                    }
                    this.setState({editData,fieldwiseData:""})
                }

                /*Api call */
                let generalRule = {
                    "masterid": this.props.eid,
                    "reference": "entity",
                    "company": 0,
                    "mode": mode,
                    "child":child,
                    "uid": getProfile().id,
                    "first name": getProfile().first_name,
                    "last name": getProfile().last_name,
                    "middle name": getProfile().middle_name,
                    "img_name": getProfile().image,
                    "email": getProfile().email,
                    "recurrenceValue": [editData]

                }
                this.props.createGenericRule(generalRule)
                this.clearstate()
            }
            if (this.state.tab === "generic") {
                this.setState({generic: !this.state.generic})
            }
            if (this.state.tab === "fieldwise") {
                this.setState({fieldwise: !this.state.fieldwise})
            }
            this.setState({generic: !this.state.generic})
        }
        if (this.state.tab === "workflow") {
            let param = {
                "_id":this.props.linkedWorkflow.workflow.length ===0? Math.round(new Date().getTime() + (Math.random() * 100)).toString():this.props.linkedWorkflow.workflow[0]._id, //random no
                "collectionid": this.props.eid,
                "workflowid": this.state.workflowid,
                "fielddata": JSON.stringify([this.state.ruleData[0].fieldItem]),
                "type": "fieldwise",
                "uid": getProfile().id,
                "mode": this.props.linkedWorkflow.workflow.length ===0  ?"save":"update"
            }
            this.props.saveWorkflowrule(param)
            this.clearstate()
            if (this.state.tab === "workflow") {
                this.setState({workflow: !this.state.workflow})
            }
        }
    }
    handleEdit=(id,type)=>{
        let genericData = []
        if(type === "genericRule"){
            this.setState({mode:"update",addStatus:'Update',genericChild:id})
            this.setTab("generic")
            let editData=(this.state.listRule.generic.filter(list=>list.id === id)[0])
            editData['pattern']= editData.pattern === false || editData.pattern.length === 0 ? [] :editData.pattern.split(",")
            this.setState({editData})
            this.setState({addStatus:"Update"})
            editData.users.map((entry, index) => {
                genericData[index] = {};
                genericData[index]['user_id'] = entry.id;
                genericData[index]['user_type'] = entry.type;
                genericData[index]['fullname'] = entry.name;
            })
            this.props.resultDataList(genericData, 'selectedDescriptionUsers', 'user_id')
        }
        if(type === "fieldwiseRule"){
            this.setState({mode:"update",fieldwiseChild:id})
            this.setTab("fieldwise")
            let editData=(this.state.listRule.fieldwise.filter(list=>list.id === id)[0])
            editData['pattern']= editData.pattern === false ?[] :editData.pattern.split(",")
            this.setState({editData})
            let dataFileds=[]
            editData.fieldItem.map((list,index)=> {
                let d=(this.state.showlistField.filter(item => item.masterid === list.field)[0])
                let deepCopyObj = JSON.parse(JSON.stringify(d));

                dataFileds.push(deepCopyObj)

                dataFileds[index]['fieldItem']= editData.fieldItem[index]
                dataFileds[index]['opt']=dataFileds[index].option
                let mapData = initialElements.filter(item => (item.type ===dataFileds[index].type))
                if(dataFileds[index].type !=="switch") {
                    dataFileds[index]['option'] = mapData[0].option
                }
            })
            this.setState({ruleData:dataFileds})
            editData.users.map((entry, index) => {
                genericData[index] = {};
                genericData[index]['user_id'] = entry.id;
                genericData[index]['user_type'] = entry.type;
                genericData[index]['fullname'] = entry.name;
            })
            this.props.resultDataList(genericData, 'selectedfieldUsers', 'user_id')
        }

    }
    handledateChange=(e)=>{
        let newData=this.state.editData
        newData[e.target.name]=e.target.value
        newData["model"]=this.state.tab
        this.setState({editData:newData, [e.target.name]:e.target.value})
    }
    handlelabelChange=(e)=>{
        let newData=this.state.editData
        newData['pattern']=[]
        newData[e.target.name]=e.target.value
        newData["model"]=this.state.tab
        if(newData['rule_type'] !== "customapi"){
            newData['customapi']=''

        }
        if(newData['rule_type'] !== "autoassign"){
            newData['assigned_email']=''

        }
        // if(newData['rule_type'] !== "autoassign"){
        //     newData['autoselectfield']=''
        //     newData['autoselectvalue']=''
        //     }
        //     let showFieldList = this.state.showFieldList;
        //     let options = []
        //     options = showFieldList.filter(item => (item.masterid === newData['autoselectfield']))[0] && showFieldList.filter(item => (item.masterid === newData['autoselectfield']))[0].option || []
        this.setState({editData: newData, [e.target.name]: e.target.value})
    }
    handleSubmitSelect=(e)=>{
        let newData=this.state.editData
        let done_onsubmit = newData.done_onsubmit === true ? false : true;
        newData['done_onsubmit'] = done_onsubmit;
        newData["patternformat"]=newData.pattern
        this.setState({editData: newData})
    }
    handleIndividualTask=(e)=>{
        let newData=this.state.editData
        let indvidualtask = newData.indvidualtask === true ? false : true;
        newData['indvidualtask'] = indvidualtask;
        newData["patternformat"]=newData.pattern;
        this.setState({editData: newData})
    }
    labelChange=(e,name,type)=>{

        let newdata=Array.from(this.state.ruleData.filter(list=>list.name === name))
        newdata[0]["fieldItem"][`field`]= newdata[0].masterid
        newdata[0]["fieldItem"][`field_rule`][0][`value`]= e.target.value
        this.setState({newdata})
    }
    optionChange=(e,name,type)=>{
        let newdata=[]
        if(type === "switch" || type==="checkbox" || type === "dropdown"){
            newdata=this.state.ruleData.filter(list=>list.name === name)
            newdata[0]["fieldItem"][`field`]= newdata[0].masterid
            newdata[0]["fieldItem"][`field_rule`][0][`trigger`]= e.target.value
            newdata[0]["fieldItem"][`field_rule`][0][`value`]= e.target.value
        }
        this.setState({newdata})
    }
    changeWorkflow=(e)=>{
        this.setState({workflowid:e.target.value})
    }
    removeUsers=(id)=>{
        let  newUser=this.state.editData.users.filter(list=>list.id !== id)
        let  _newState=this.state.editData
        _newState["users"]=newUser
        this.setState({_newState})
    }
    handlePattern=(month)=>{
        let pattern = [...this.state.editData.pattern];
        (pattern.indexOf(month) > -1) ? pattern.splice(pattern.indexOf(month), 1) : pattern.push(month);
        let _newPatten={...this.state.editData}
        _newPatten["pattern"]=pattern
        _newPatten["patternformat"]=pattern
        this.setState({editData: _newPatten});
    }
    overlayShow=()=>{
        document.body.classList.add('layover-bg');
    }
    overlayHide=()=>{
        document.body.classList.remove('layover-bg');
    }
    handleFromChange=(date)=>{
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        let newData=this.state.editData
        newData['startDate']=formattedDate
        newData["model"]=this.state.tab
        this.setState({editData:newData, startDate:formattedDate})
        if(date !== '') {
            document.getElementById('startDate').classList.remove('val-error');
        }
    }
    handleToChange=(date)=>{
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        let newData=this.state.editData
        newData['endDate']=formattedDate
        newData["model"]=this.state.tab
        this.setState({editData:newData, endDate:formattedDate})
        if(date !== '') {
            document.getElementById('endDate').classList.remove('val-error');
        }
    }
    render() {
        let listRule = this.state.listRule;
        let showlistField = this.state.showlistField;
        let showFieldList = this.state.showFieldList;
        let generic=listRule.generic;
        let fieldwise=listRule.fieldwise;

        return (
            <div className={`cs-modal fade bsy-modal entity-rules in show`}>
                <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                    <div className="modal-content">
                        <div className="modal-body">

                            {/* New content */}

                            <div className="entity-rule-container">
                                <button className="modal-close ico-lib md-close" onClick={(e) => {this.props.ruleClose()}}></button>

                                {listRule.loader &&
                                <span className="Loader-holder" style={{'background': 'transparent'}}>
                                        <LoaderSvg/>
                                     </span>
                                }

                                {(!this.state.generic) &&
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                    <Row>
                                        <Col className="entity-rule-right" sm={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <div className="entity-rule-right-inner">
                                                        <div className="entity-rule-right-header">
                                                            <h5>General Rules</h5>
                                                            <button className="general-btn"
                                                                    onClick={() => this.setTab("generic", "new")}>+
                                                                New
                                                            </button>
                                                        </div>
                                                        <div className="entity-rule-right-body">
                                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                                <div className="rule-right-body-inner">
                                                                    {generic.length !== 0 && generic.map((list, index) =>
                                                                        <div className="each-list-added">
                                                                            <div className="content-section "
                                                                                 data-value="collist"
                                                                                 key={`generic` + index}>
                                                                                <div className="section-info-main">
                                                                                    <div className="section-info">
                                                                                        {list.rule_type !== "customapi" && list.ruletitle && list.ruletitle !== "" &&
                                                                                        <h5>{list.ruletitle}</h5>
                                                                                        }
                                                                                        <h5>{list.ruleDescription}</h5>
                                                                                        <div
                                                                                            className="form-option-block">
                                                                                            <button
                                                                                                className="ico-lib opt-form-ico"
                                                                                                onClick={() => this.actionStaus("genericRule", !this.state.genericRule, list.id)}></button>
                                                                                        </div>
                                                                                        {this.state.genericRule && this.state.id === list.id &&
                                                                                        <OutsideAlerter
                                                                                            status={this.state.genericRule}
                                                                                            Triggered={() => this.actionStaus("genericRule", !this.state.genericRule)}
                                                                                        >
                                                                                            <ul className="panel-option">
                                                                                                <li>
                                                                                                    <a className="panel-option-click ico-lib form-edit-ico"
                                                                                                       onClick={(e) => {
                                                                                                           this.handleEdit(list.id, "genericRule");
                                                                                                           this.actionStaus("genericRule", !this.state.genericRule)
                                                                                                       }}>Edit
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <a className="panel-option-click ico-lib delete-task-ico"
                                                                                                       onClick={(e) => {
                                                                                                           this.deleteFromGeneric(list.id, "genericRule");
                                                                                                           this.actionStaus("genericRule", !this.state.genericRule)
                                                                                                       }}>Delete
                                                                                                    </a>
                                                                                                </li>

                                                                                            </ul>
                                                                                        </OutsideAlerter>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="section-info-icon">
                                                                                        <ul className="rule-select-opt-block">
                                                                                            <li className="ico-lib notf-ico">{list.rule_type}</li>
                                                                                            <li className="ico-lib date-rule-ico">{moment(list.startDate).format("D MMM YYYY")} to {moment(list.endDate).format("D MMM YYYY")}
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Frequency:</span> {list.type}
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Pattern:</span> {list.pattern}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {generic.length === 0 &&
                                                                    <div className='absolute-holder' style={{'background': 'transparent'}}>
                                                                        <NoData
                                                                            msg={`No Rules Added`}
                                                                        />
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                                }
                                {(this.state.generic) &&
                                <div className="new-modal">
                                    <div className="new-modal-header">
                                        <div className="task-nav">
                                            <div className="task-nav-left bg-back">
                                                <button className="backto-list"
                                                        onClick={() => this.setTab("generic", "cancel")}>Back To
                                                    Listing
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="new-modal-body">
                                        <div className="new-modal-left">
                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                <div className="new-modal-left-inner">
                                                    <div className="rightdateinput">
                                                        <label>Validity Period</label>
                                                        <div className="inputdatefield">
                                                            <div
                                                                className="task-input-block eq-width datefieldright picker-bt">
                                                                {/*<input type="text" name="startDate" placeholder="Start date"*/}
                                                                {/*       value={this.state.editData.startDate}*/}
                                                                {/*       onChange={this.handledateChange}*/}
                                                                {/*       className={`task-input-box date-picker ${this.state.errors.startDate?"val-error":'' }`}*/}
                                                                {/*/>*/}
                                                                <DateTimePickerBox
                                                                    handleDateChange={this.handleFromChange}
                                                                    placeholderText="Start date"
                                                                    id="startDate"
                                                                    timeFormat={false}
                                                                    startDate={this.state.editData.startDate}
                                                                    previousDate={true}
                                                                />
                                                                <button className="ico-lib inputdate"></button>
                                                            </div>
                                                            <div
                                                                className="task-input-block eq-width datefieldright picker-bt">
                                                                {/*<input type="text" name="endDate"*/}
                                                                {/*       placeholder="End date"*/}
                                                                {/*       className={`task-input-box date-picker ${this.state.errors.endDate?"val-error":'' }`}*/}
                                                                {/*       value={this.state.editData.endDate}*/}
                                                                {/*       onChange={this.handledateChange}*/}
                                                                {/*/>*/}
                                                                <DateTimePickerBox
                                                                    handleDateChange={this.handleToChange}
                                                                    placeholderText="End date"
                                                                    id="endDate"
                                                                    timeFormat={false}
                                                                    startDate={this.state.editData.endDate}
                                                                    previousDate={true}
                                                                />
                                                                <button className="ico-lib inputdate"></button>
                                                            </div>
                                                        </div>
                                                        {this.state.errorMsg !== "" &&
                                                        <div className="inputdatefield">
                                                            <p className={``}>{this.state.errorMsg}</p>
                                                        </div>

                                                        }
                                                    </div>
                                                    <div className="rightdateinput">
                                                        <label>Frequency</label>
                                                        <div className="rightinputdropdown">
                                                            <div className="rule-select-box"  id='type'>
                                                                <select name="type" onChange={this.handlelabelChange}
                                                                        value={this.state.editData.type}>
                                                                    <option value="">Select</option>
                                                                    <option value="Daily">Daily</option>
                                                                    <option value="Weekly">Weekly</option>
                                                                    <option value="Biweekly">Biweekly</option>
                                                                    <option value="Monthly">Monthly</option>
                                                                    <option value="Quarterly">Quarterly</option>
                                                                    <option value="HalfYearly">HalfYearly</option>
                                                                    <option value="Annual">Annual</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.editData.type === "Weekly" &&
                                                    <div className="rightdateinput">
                                                        <label>Rule Pattern</label>
                                                        <div className="weekmarkarea">
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> Sunday
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('Sunday')}
                                                                           onClick={() => this.handlePattern("Sunday")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> Monday
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('Monday')}
                                                                           onClick={() => this.handlePattern("Monday")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> Tuesday
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('Tuesday')}
                                                                           onClick={() => this.handlePattern("Tuesday")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> Wednesday
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('Wednesday')}
                                                                           onClick={() => this.handlePattern("Wednesday")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> Thursday
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('Thursday')}
                                                                           onClick={() => this.handlePattern("Thursday")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> Friday
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('Friday')}
                                                                           onClick={() => this.handlePattern("Friday")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> Saturday
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('Saturday')}
                                                                           onClick={() => this.handlePattern("Saturday")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.state.editData.type === "Monthly" &&
                                                    <div className="rightdateinput">
                                                        <label>Rule Pattern</label>
                                                        <div className="weekmarkarea">
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> January
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('January')}
                                                                           onClick={() => this.handlePattern("January")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> February
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('February')}
                                                                           onClick={() => this.handlePattern("February")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> March
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('March')}
                                                                           onClick={() => this.handlePattern("March")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> April
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('April')}
                                                                           onClick={() => this.handlePattern("April")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> May
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('May')}
                                                                           onClick={() => this.handlePattern("May")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> June
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('June')}
                                                                           onClick={() => this.handlePattern("June")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> July
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('July')}
                                                                           onClick={() => this.handlePattern("July")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> August
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('August')}
                                                                           onClick={() => this.handlePattern("August")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> September
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('September')}
                                                                           onClick={() => this.handlePattern("September")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> October
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('October')}
                                                                           onClick={() => this.handlePattern("October")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> November
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('November')}
                                                                           onClick={() => this.handlePattern("November")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                            <div className="select-chk-block checkbox-rules">
                                                                <label
                                                                    className="rulescheckbox control control-checkbox"> December
                                                                    <input type="checkbox" className="inp-chk"
                                                                           defaultChecked={this.state.editData.pattern.includes('December')}
                                                                           onClick={() => this.handlePattern("December")}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </Scrollbars>
                                        </div>
                                        <div className="new-modal-right">
                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                <div className="new-modal-right-inner">
                                                    <div className="each-inp-box">

                                                    <>
                                                        <div className="rightdateinput">
                                                            <label>Action Type</label>
                                                            <div className="rule-select-box" id='rule_type'>
                                                                <select name="rule_type" onChange={this.handlelabelChange}>
                                                                    <option>{this.state.editData.rule_type !== '' ? this.state.editData.rule_type : 'Select'}</option>
                                                                    <option value={`Notification`}>Notification</option>
                                                                    <option value={`Task`}>Task</option>
                                                                </select>
                                                            </div>
                                                            {/*<div className="radio-wrapper-new">*/}
                                                            {/*    <div className="radio-block">*/}
                                                            {/*        <input type="radio" name="rule_type"*/}
                                                            {/*               defaultChecked={this.state.editData.rule_type === "notification"}*/}
                                                            {/*               value={"notification"}*/}
                                                            {/*               onChange={this.handlelabelChange}*/}
                                                            {/*        />*/}
                                                            {/*        <label>Notification</label>*/}
                                                            {/*    </div>*/}
                                                            {/*    <div className="radio-block">*/}
                                                            {/*        <input type="radio" name="rule_type"*/}
                                                            {/*               defaultChecked={this.state.editData.rule_type === "task"}*/}
                                                            {/*               value={`task`}*/}
                                                            {/*               onChange={this.handlelabelChange}*/}
                                                            {/*        />*/}
                                                            {/*        <label>Task</label>*/}
                                                            {/*    </div>*/}
                                                            {/*</div>*/}
                                                        </div>

                                                        {/*{this.state.editData.users.map((list,index)=>*/}
                                                        {/*    <div className="sl-tag">*/}
                                                        {/*        <span className="tag-left">{list.name.charAt(0)}</span>*/}
                                                        {/*        <span className="tag-right">{list.name}<button*/}
                                                        {/*            className="tag-cl" onClick={()=>this.removeUsers(list.id)}>×</button></span>*/}
                                                        {/*    </div>*/}
                                                        {/*)}*/}
                                                        <div className="rightdateinput">
                                                            <label>Assign to</label>
                                                            <div className="assignSection" id='selectedDescriptionUsers'>
                                                        <div className="task-inp-container">
                                                            <AutoComplete
                                                                fieldTitle='To'
                                                                placeholder="Type here"
                                                                sourceDataList={this.props.userList}
                                                                searchField='fullname'
                                                                showIcon={false}
                                                                hideMainLabel={false}
                                                                showSubLabel={false}
                                                                labelFields='fullname'
                                                                valueField='user_id'
                                                                subLabelField='designation'
                                                                showProfile={true}
                                                                reference='selectedDescriptionUsers'
                                                                selectedItems={this.props.selectedDescriptionUsers}
                                                                resultDataList={this.props.resultDataList}
                                                                ignoredValues={this.props.ignoredValues}
                                                                id="to_users"
                                                                actionMethod={this.props.getUsers}
                                                            />
                                                        </div>
                                                            </div>
                                                        </div>

                                                        <div className="rightdateinput">
                                                            <label>Description</label>
                                                        <div className="taskdescsection">
                                                            <div className="assignInputdesc">
                                                            <textarea id="title"
                                                                      name="ruleDescription"
                                                                      placeholder="Description"
                                                                      value={this.state.editData.ruleDescription}
                                                                      onChange={this.handlelabelChange}
                                                                      className={`task-textarea-form ${this.state.errors.ruleDescription ? " val-error" : ''}`}
                                                            >

                                                            </textarea>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        {this.state.editData.rule_type === 'Task' &&
                                                        <div className="rightdateinput">
                                                            <label>Task title</label>
                                                        <div className="taskdescsection">
                                                            <div className="assignInputdesc">
                                                                <input id="task_title"
                                                                       name="ruletitle"
                                                                       placeholder="Task title"
                                                                       value={this.state.editData.ruletitle}
                                                                       onChange={this.handlelabelChange}
                                                                       className={`task-textarea-form ${this.state.errors.ruletitle ? " val-error" : ''}`}
                                                                >

                                                                </input>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        }

                                                        <div className="rightdateinput">
                                                            <label>Priority</label>
                                                            <div className="rightinputdropdown">
                                                                <div className="rule-select-box"  id='priority'>
                                                                    <select name={`priority`}
                                                                            onChange={this.handlelabelChange}
                                                                            value={this.state.editData.priority}>
                                                                        <option value="">Select</option>
                                                                        <option value="Low">Low</option>
                                                                        <option value="Medium">Medium</option>
                                                                        <option value="High">High</option>
                                                                        <option value="Urgent">Urgent</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="rightdateinput">
                                                            <label>Task Done on Form Submit</label>
                                                            <div className="taskdescsection">
                                                                <div className="assignInputdesc">
                                                                    <button type="button" className={`btn-toggle ${this.state.editData.done_onsubmit ? 'active' : ''}`} data-toggle="button" aria-pressed="false" onClick={this.handleSubmitSelect}>
                                                                        <div className="handle"></div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="rightdateinput">
                                                            <div className="select-chk-block" style={{"display": "block"}}>
                                                                <label className="control control-checkbox">Individual Task
                                                                    <input type="checkbox" className="inp-chk" id="text_searchable"
                                                                           value={this.state.editData.indvidualtask}
                                                                           defaultChecked={this.state.editData.indvidualtask} onClick={(e)=>this.handleIndividualTask(e)}/>
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                        </div>


                                                    </>
                                                    </div>

                                                </div>
                                            </Scrollbars>
                                        </div>
                                    </div>
                                    <div className="new-modal-footer">
                                        <button type="button" className="general-btn"
                                                onClick={(e) => this.addGeneralRule(e)}>{this.state.addStatus}
                                        </button>
                                    </div>
                                </div>
                                }
                            </div>










                        </div>
                    </div>
                </div>
                {this.state.showDeleteGeneric &&
                <AlertModal id="take-pool"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_button_style="btn-danger"
                            OK_action={this.handleDelete}
                            showModal = {this.state.showDeleteGeneric}
                            CANCEL_action={this.showDeleteConfirm}
                />
                }
                {this.state.removeWorkflowTest &&
                <AlertModal id="take-pool"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_button_style="btn-danger"
                            OK_action={this.handleRemove}
                            showModal = {this.state.removeWorkflowTest}
                            CANCEL_action={this.removeWorkflowConfirm}
                />
                }
            </div>
        );
    }
}


export default ScheduleModal;
