import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as taskActions from "../../actions/ToDo";
import {getProfile} from "../../utilities/AuthService";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import TaskDetails from "./task/TaskDetailsNew";
import WorkflowTaskDetails from "./workflow-task/WorkFlowtaskDetailsNew";
import rootActions from "../../actions";
import LoaderSvg from "../views/LoaderSVG";
class DetailContainerNew extends Component {

    componentDidMount() {
        this.props.mainClick('task','task/details');
        var taskid = (atob(getUrlSegment(3)));
        var mode = (atob(getUrlSegment(4)));
        this.setState({taskmode : mode});
        //this.props.gettaskdetails(taskid,'root',getProfile().id,mode)
        let data = { _id : taskid, systype: getProfile().usertype, uid : getProfile().id, mode: mode };
        this.props.gettaskdetails(data);
        this.props.getattchmentlist({"_id": taskid, "reference": "All"});
        this.props.getloglist({"_id": taskid, "type": "todo"});


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match !== this.props.match) {
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            this.setState({taskmode : mode});
            let data = { _id : taskid, systype: getProfile().usertype, uid : getProfile().id, mode: mode };
            this.props.gettaskdetails(data);
        }
        if(this.props.modifyTask.task_modified !== prevProps.modifyTask.task_modified){
            var obj = this;


            if(this.props.modifyTask.task_type ===  'updatetaskform') {
                var taskid = (atob(getUrlSegment(3)));
                var mode = (atob(getUrlSegment(4)));
                obj.setState({taskmode : mode});
                setTimeout(function () {
                    let data = {_id: taskid, systype:getProfile().usertype, uid: getProfile().id, mode: mode};
                    obj.props.gettaskdetails(data);
                }, 1000)

            }
            if(this.props.modifyTask.task_type !==  'updatetaskform') {
                var taskid = (atob(getUrlSegment(3)));
                var mode = (atob(getUrlSegment(4)));
                this.setState({taskmode : mode});
                let data = {_id: taskid, systype: getProfile().usertype, uid: getProfile().id, mode: mode, asyncLoad: true};
                this.props.gettaskdetails(data);
            }

        }
    }

    render() {
        let taskData = this.props.taskdetailsData.taskdetails.parent;

        return(
            <div className="right-content-wrapper" key={`right-content-wrapper`}>
                <div className="task-wrapper task-detail-stand" key={`task-wrapper task-detail-stand`}>
                    {this.props.taskdetailsData.loading &&
                    <span className="Loader-holder">
                        <LoaderSvg/>
                    </span>
                    }
                    {Object.entries(taskData).length > 0  &&
                    <React.Fragment key={"task-detail-type-1"}>
                        {(taskData.createdfrom !== "workflow" && taskData.tasktype !== "workflow") &&
                        <TaskDetails
                            taskdetailsData={this.props.taskdetailsData}
                            attachmentData={this.props.attachmentData}
                            logData = {this.props.todologData}
                        />
                        }
                        {(taskData.createdfrom === "workflow" || taskData.tasktype === "workflow")&&
                        <WorkflowTaskDetails
                            taskdetailsData={this.props.taskdetailsData}
                            attachmentData={this.props.attachmentData}
                            logData = {this.props.todologData}/>
                        }
                    </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    taskdetailsData : state.TaskReducer.TaskDetails,
    modifyTask:state.TaskReducer.ModifyTask,
    attachmentData : state.TaskReducer.Attachmentlist,
    todologData : state.TaskReducer.TodoLoglist

});

export default  withRouter(connect(mapStateToProps, {
    gettaskdetails:taskActions.taskdetails,
    mainClick:rootActions.commonActions.MenuClick,
    getattchmentlist : taskActions.getattchments,
    getloglist : taskActions.gettodologs
})(DetailContainerNew));
