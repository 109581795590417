import React from 'react';
import axios from 'axios';
import {FormGroup} from 'react-bootstrap';
import { Draggable, state } from 'react-page-maker';
import { DragDropContext, Droppable, Draggable as Drags } from "react-beautiful-dnd";
import SimpleModal from "../../../views/SimpleModal"
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";

let graphData= [
    {
        "x": "2018 Q1",
        "y": "500",
        "z": "2018 Q1"
    },
    {
        "x": "2018 Q2",
        "y": "620",
        "z": "2018 Q2"
    }
]


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : "#fff",
    ...draggableStyle
});
class HierarchicalContainer extends React.Component {
    constructor(props,context) {
        super(props,context);
        this.state={
            modalStatus:false,
            showColorPicker:false,
            background:'#fff',
            payload:{
                chartTitle:"Title",
                subTitle:"subtitle",
                subTitleEnabled : false,
                linedata:graphData,
                cardvalue:0,
                collection:"",
                fields:"",
                aggregation:'',
                graph:false,
                x:"",
                y:"",
                z:"",
                xstroke:"",
                ystroke:"",
                labelx:"",
                labely:"",
                zstroke:"#fff",
                labelchartTitle:"",
            },
            linedata : [],
            tabledata:[{
                id: "item-1",
                content: "item 1",
                masterid:""
            }],
            condition:[{
                id: "condition-1",
                value: "condition 1",
                masterid:""
            }],
            items:[{
                id: "item-1",
                content: "item 1",
                masterid:""
            }],
            showlistField:[],
        }


    }
    componentDidMount() {
        this.setState({payload:this.props.payload})
        this.graphData()
    }

    openSetting=()=>{
        this.setState({modalStatus:!this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }
    handleChangeComplete = (color,param) => {
        //////console.log(param)
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[param]=color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
        //this.setState({ background: color.hex });
    };
    toggleColorPicker = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    }
    handleInputChange=(e)=>{
        if(e.target.name==="collection"){
            this.props.showform({ _id : e.target.value});
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    graphData(){
        var date = new Date();
        var timestamp = date.getTime();

        var obj = this;
        const collection =  this.props.payload && this.props.payload.collection
        const tabledata = this.props.payload && this.props.payload.tabledata||this.state.items
        const x = this.props.payload && this.props.payload.x
        const y = this.props.payload && this.props.payload.y
        const condition = this.props.payload && this.props.payload.condition||this.state.condition
        //const condition1 = this.props.payload && this.props.payload.condition||this.state.condition
        this.setState({tabledata:tabledata,items:tabledata,condition:condition})
        if((getUrlSegment(1) === "dashboard")&& (collection !=='' && collection !== undefined)) {
            //  this.props.showform({ _id : collection});
            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + collection + "&index=" + ES_INDEX,
                async: false,
                headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
            }).then(function (res) {
                obj.setState({showlistField: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });
        }

        if(collection !=='' || collection !== undefined) {
            var cTemp = []

            for(var i = 0; i < condition.length;i++)
            {
                cTemp[i] = {};
                cTemp[i]["id"] = condition[i]["masterid"]
                cTemp[i]["value"] = condition[i]["value"]
            }
            let sharetype = getUrlSegment(1);
            let  uid = '';
            let usertype = '';
            if(sharetype === 'public') {
                uid = 2;
                usertype = 'root';
            }
            else{
                uid = getProfile().id;
                usertype = getProfile().usertype;
            }
            let param={
                search: "",
                index: ES_INDEX,
                usertype: usertype,
                uid: uid,
                fromdate: "",
                todate: "",
                filters: cTemp,
                datefilters: [],
                numberfilters: [],
                dayfilters: []
            }
            axios.post( API_PATH + "collection/" + collection + "/collectedinfo?index="+ES_INDEX,  param )
                .then(function (res) {
                    var carddata = res.data.results.data[0];
                    obj.setState({carddata: carddata});
                    return res;

                })

                .then(function (res) {
                    var carddata = res.data.results.data[0];
                    obj.setState({carddata: carddata});
                    return res;

                }).catch(function (err) {
                return err;
            });
        }
        else{
            obj.setState({linedata: graphData});

        }
    }
    handleChange=(e)=>{
        if(e.target.name==="collection"){
            this.props.showform({ _id : e.target.value});
        }
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    chartSaving=()=>{
        var prjctdtlid = atob(getUrlSegment(4));
        let sharetype = getUrlSegment(1);
        let  uid = '';
        if(sharetype === 'public') {
            uid = 2;
        }
        else{
            uid = getProfile().id;
        }
        let param={
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard":state.getStorableState()
            ,
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus:false})
    }
    /*  drag and drop functions for hierarchial table */

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    };
    addData=()=>{
        let data=this.state.items
        let counter=data.length+1
        let newData={
            id: "item-"+new Date().getTime(),
            content: "item "
        }
        let _data=data.concat(newData)
        this.setState({items:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["tabledata"]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    removeData=(dragid)=>{
        let data=this.state.items
        let _data=data.filter(item=>item.id !== dragid)
        this.setState({items:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["tabledata"]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    checkBoxchange=(e)=> {
        let val;
        if (e.target.value === "false") {
            val = true
        } else {
            val = false
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    addCondition=()=>{
        let data=this.state.condition
        let counter=data.length+1
        let newData={
            id: "value-"+counter,
            value: "value "+counter
        }
        let _data=data.concat(newData)
        this.setState({condition:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["condition"]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    removeCondition=(dragid)=>{
        let data=this.state.condition
        let _data=data.filter(item=>item.id !== dragid)
        this.setState({condition:_data})
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["condition"]=_data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }





    handleLabelChange=(e,dragid)=>{
        var _newState = this.state.items;
        var index=(_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name]=e.target.value;
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload["tabledata"]=this.state.items
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    conditionchange=(e,dragid)=>{

        var _newState = this.state.condition;
        var index=(_newState.findIndex(obj => obj["id"] === dragid))
        if(e.target.name === "value") {
            _newState[index][e.target.name] = [e.target.value];
        }
        else{
            _newState[index][e.target.name] = e.target.value;
        }
        //////console.log(_newState)
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        //////console.log(payload)
        payload["condition"]=this.state.condition
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    render() {
        const {
            id,
            showBasicContent, showPreview,
            dropzoneID,payload, parentID, name
        } = this.props;


        const chartTitle =  this.state.chartTitle || payload && payload.chartTitle;
        const subTitle =  this.state.subTitle || payload && payload.subTitle;
        const subTitleEnabled = this.state.subTitleEnabled || payload && payload.subTitleEnabled;
        const carddata =  this.state.carddata || [];
        const collection =  this.state.collection || payload && payload.collection ;
        const tabledata =  this.state.items || payload && payload.tabledata ;
        const condition =  this.state.condition || payload && payload.condition ;


        const x =  this.state.x || payload && payload.x ;
        const y =  this.state.y || payload && payload.y ;
        let showlistField = []
        if(this.state.showlistField.length !==0) {
            for (let i = 0; i <this.state.showlistField.pages.length; i++) {
                (this.state.showlistField.pages[i].components).map(list=>{
                    showlistField.push(list)
                })
            }
        }

        let collections=(this.props.listAllCollections.ListAllCollections.data)
        if (showBasicContent) {
            return (
                <Draggable { ...this.props } >
                    {
                        <span className="drag-icon">{name}</span>
                    }
                </Draggable>
            )
        }

        if (showPreview) {
            return (

                <div className="list-common list-row-right">
                    <div className="ibt-progress-table">
                        <div className="ibt-progress-table-header">
                            <h5>{chartTitle}</h5>
                            <p>All figures in Rs</p>
                            {subTitleEnabled &&
                            <p>{subTitle}</p>
                            }
                        </div>
                        <div className="ibt-progress-table-body">
                            <div className="scrollable">
                                <table className="table ibt-table bs-sheet-tb">
                                    <tbody>
                                    {tabledata.map((item, index) =>
                                        <>
                                            {item.level === "level1" &&
                                            <tr className="main-row">
                                                <td>{item.content}</td>
                                                <td align="right">{carddata.length !== 0 && carddata.collectedform.filter(a => a.masterid === item.masterid).length !==0 &&carddata.collectedform.filter(a => a.masterid === item.masterid)[0].value[0].value}</td>

                                            </tr>
                                            }
                                            {item.level === "level2" &&
                                            <tr className="sub-row">
                                                <td>{item.content}</td>
                                                <td align="right">{carddata.length !== 0 && carddata.collectedform.filter(a => a.masterid === item.masterid).length !==0 && carddata.collectedform.filter(a => a.masterid === item.masterid)[0].value[0].value}</td>

                                            </tr>
                                            }
                                            {item.level === "level3" &&
                                            <tr className="nested-sub-row">
                                                <td>{item.content}</td>
                                                <td align="right">{carddata.length !== 0 && carddata.collectedform.filter(a => a.masterid === item.masterid).length !==0 && carddata.collectedform.filter(a => a.masterid === item.masterid)[0].value[0].value}</td>

                                            </tr>
                                            }
                                        </>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


            );
        }
        return (
            <React.Fragment>
                <Draggable {...this.props}  draggable={!this.props.DragDisabled.dragStatus} >
                    <FormGroup className="m-0">
                        <label className="col-sm-12">


                            <span>{name}</span>
                            <div className="chart-icons">
                                <div className="settings-icon" onClick={() => this.openSetting(id, dropzoneID, parentID)}></div>
                                <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>
                            </div>
                        </label>
                    </FormGroup>
                </Draggable>
                {this.state.modalStatus &&
                <SimpleModal
                    Close={this.openSetting}
                    title={`Settings`}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.chartSaving}
                    asignedUsershow = {false}
                    cancelLabel="Cancel"
                    cancelAction={this.openSetting}
                >
                    <div className="DashBuilder-modal">
                        <div className="task-input-container">
                            <div className="new-task-input">
                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle" autoComplete="off" value={chartTitle}
                                       onChange={(e)=>this.handleChange(e)} />
                            </div>

                        </div>
                        <div className="task-input-container container-flex">
                            <div className="new-task-input">
                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle" autoComplete="off" value={subTitle}
                                       onChange={(e)=>this.handleChange(e)} />
                            </div>

                            <div className="select-chk-block" style={{"display": "block"}}>
                                <label className="control control-checkbox">Enable Subtitle
                                    <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`} checked={subTitleEnabled} value={subTitleEnabled} onClick={(e)=>this.checkBoxchange(e)}/>
                                    <div className="control-indicator"></div>
                                </label>
                            </div>

                        </div>
                        <div className="task-input-container">
                            <label>Collections:</label>
                            <div className="select-box eq-width">
                                <select id="priority" name="collection" onChange={(e)=>this.handleInputChange(e)} value={collection}>

                                    <option value="">Option 1</option>
                                    {collections.map((list,index)=>
                                        <option value={list._id}>{atob(list.title)}</option>
                                    )}
                                </select>
                            </div>
                        </div>


                        <div className="graph-proprties">

                            <div className="graph-proprties-color modal-panel-container">
                                <div className="task-input-container">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(droppableProvided, droppableSnapshot) => (
                                                <div
                                                    ref={droppableProvided.innerRef}
                                                    // style={getListStyle(droppableSnapshot.isDraggingOver)}
                                                >
                                                    {tabledata.map((item, index) => (
                                                        <Drags key={item.id} draggableId={item.id} index={index} >
                                                            {(draggableProvided, draggableSnapshot) => (
                                                                <div className={'panel-each'}
                                                                     ref={draggableProvided.innerRef}
                                                                     {...draggableProvided.draggableProps}
                                                                     {...draggableProvided.dragHandleProps}
                                                                     style={getItemStyle(
                                                                         draggableSnapshot.isDragging,
                                                                         draggableProvided.draggableProps.style
                                                                     )}
                                                                >
                                                                    <div className={`settings-panel`}>
                                                                        <input type={`text`} name="content" defaultValue={item.content} onChange={(e)=>this.handleLabelChange(e,item.id)}/>
                                                                        <div className="select-box">
                                                                            <select id="level" name="level" onChange={(e)=>this.handleLabelChange(e,item.id)} value={item.level}>
                                                                                <option value="">Level</option>
                                                                                <option value="level1">Level 1</option>
                                                                                <option value="level2">Level 2</option>
                                                                                <option value="level3">Level 3</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="masterid" onChange={(e)=>this.handleLabelChange(e,item.id)} value={item.masterid}>
                                                                                <option value="">Y axis</option>
                                                                                {showlistField.map((label,index)=>
                                                                                    <option key={`masterid`+index} value={label.masterid}>{label.label}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`settings-panel-option`}>
                                                                        <button className="ico-lib divider-add-button" onClick={()=>this.addData(item.id)}> </button>
                                                                        <button className="ico-lib divider-sub-button" onClick={()=>this.removeData(item.id)}> </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Drags>
                                                    ))}
                                                    {droppableProvided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>


                            </div>
                        </div>



                        <div className="task-input-container" style={{"width":"100%"}}>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(droppableProvided, droppableSnapshot) => (
                                        <div
                                            ref={droppableProvided.innerRef}
                                            // style={getListStyle(droppableSnapshot.isDraggingOver)}
                                        >
                                            {condition.map((item, index) => (
                                                <Drags key={item.id} draggableId={item.id} index={index} >
                                                    {(draggableProvided, draggableSnapshot) => (
                                                        <div className={'panel-each'}
                                                             ref={draggableProvided.innerRef}
                                                             {...draggableProvided.draggableProps}
                                                             {...draggableProvided.dragHandleProps}
                                                             style={getItemStyle(
                                                                 draggableSnapshot.isDragging,
                                                                 draggableProvided.draggableProps.style
                                                             )}
                                                        >
                                                            <div className={`settings-panel`} style={{"width":"100%"}}>
                                                                <div className="select-box">
                                                                    <select id="yaxis" name="masterid" onChange={(e)=>this.conditionchange(e,item.id)} value={item.masterid}>
                                                                        <option value="">Condition</option>
                                                                        {showlistField.map((label,index)=>
                                                                            <option key={`masterid`+index} value={label.masterid}>{label.label}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                <input type={`text`} name="value" defaultValue={item.value} onChange={(e)=>this.conditionchange(e,item.id)}/>


                                                            </div>
                                                            <div className={`settings-panel-option`}>
                                                                <button className="ico-lib divider-add-button" onClick={()=>this.addCondition(item.id)}></button>
                                                                <button className="ico-lib divider-sub-button" onClick={()=>this.removeCondition(item.id)}></button>
                                                            </div>

                                                        </div>
                                                    )}
                                                </Drags>
                                            ))}
                                            {droppableProvided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>



                    </div>


                </SimpleModal>
                }
            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    showFormModal:state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange:state.DynamicDashboard.getDashboard,
    DragDisabled:state.DynamicDashboard.getDragDisabled

});
export default  withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform : collectionActions.showform,
    chartSaving:DynamicDashboard.saveDashboarddata,
    dragStatus :DynamicDashboard.dragStatus,
})(HierarchicalContainer));
