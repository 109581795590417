import {combineReducers} from 'redux';

const listChannelData = (state = {channelList : [], channelCreated :''}, action) => {
    switch (action.type) {

        case "LIST_ALL_CHANNELS":
            return {
                ...state,
                channelList : action.channelList

            };
            break;

        case "SAVE_CHANNEL_DATA" :
            return {
                ...state,
                channelCreated: action.channelCreated
            }

        default:
            return state;
    }
};

const listLeadsData = (state = {chatLeadsList : [],leadsList : [],activeLeadsList : [],newLeadsList :[],folUpLeadsList :[],folUpLeadsListToday :[],  customtemplate_other:[], loading : false}, action) => {
    switch (action.type) {

        case "LIST_CHAT_LEADS":
            return {
                ...state,
                leadsList : action.leadsList,
                activeLeadsList : action.activeLeadsList,
                newLeadsList : action.newLeadsList,
               // folUpLeadsList : action.folUpLeadsList,

            };
            break;
        case "LIST_ALL_LEADS":
            let leads = [...state.leadsList];
            console.log(leads)
            console.log(action.allLeads)
            leads = [].concat.apply(leads,action.allLeads);
            console.log(leads)
            return {
                ...state,
                leadsList : leads,
                customtemplate_other:action.customtemplate_other

            };
            break;
        case "SEARCH_ALL_LEADS":
            return {
                ...state,
                leadsList : action.allLeads
            };
            break;
        case "LIST_ACTIVE_LEADS":
            return {
                ...state,
                activeLeadsList : action.activeLeadsList,
                customtemplate_other:action.customtemplate_other

            };
            break;
        case "LIST_NEW_LEADS":
            return {
                ...state,
                newLeadsList : action.newLeads,
               // customtemplate_other:action.customtemplate_other

            };
            break;
        case "LIST_FOLUP_LEADS":
            return {
                ...state,
                folUpLeadsList : action.folUpLeads,
               // customtemplate_other:action.customtemplate_other

            };
            break;
        case "LIST_FOLUP_TODAY_LEADS":
            return {
                ...state,
                folUpLeadsListToday : action.folUpLeadsListToday,
               // customtemplate_other:action.customtemplate_other

            };
            break;

        case "LEADLIST_LOADER" :
            return {
                ...state,
                loading : action.loading

            };
            break;


        default:
            return state;
    }
};
const listChatData = (state = {chatList : [],userInfo:[], chatSaved : 0, loading : false, programList : [],chatDeleted : ''}, action) => {
    switch (action.type) {

        case "LIST_ALL_CHATS":
            return {
                ...state,
                chatList : action.chatList,
                userInfo : action.userInfo

            };
            break;

        case "CHATLIST_LOADER" :
            return {
                ...state,
                loading : action.loading

            };
            break;
        case "SAVE_CHAT_MESSAGE" :
            let chatList = [...state.chatList];
            //chatList.push(action.newChat);
            return {
                ...state,
                chatSaved : action.chatSaved,
                //chatList : chatList
            };
            break;
         case "UPDATE_CHAT_MESSAGE" :
            let _newMsg = [...state.chatList];
             _newMsg.push(action.newChat);
             return {
                ...state,
                chatSaved : action.chatSaved,
                chatList : _newMsg
            };
            break;


        case "CHAT_TAKEN":
            return {
                ...state,
                chatTaken : !state.chatTaken

            };
            break;
        case "DELETE_WH_MSG" :
            return {
                ...state,
                chatDeleted : action.chatDeleted
            }
        case "PROGRAM_LIST_SUCCESS":
            return {
                ...state,
                programList: action.programList
            }
        case "LEAD_CONVERSION_SUCCESS":
            return {
                ...state,
                converted :action.converted
            }

        default:
            return state;
    }
};

const getFormDetails = (state = {formJson : [], profileValues : [], formValues : [], meta : [], id :[],flags : [], followupCallsList : [], loading :false, taskList : [],leadScores : [], selected_lead_id : '',followupUpdated :[], flagList : [], flagCategories : []}, action) => {
    switch (action.type) {

        case "GET_FORM_JSON":
            let formJson = state.formJson;
            formJson[action.entityId] = action.formJson[action.entityId];
            return {
                ...state,
                formJson : formJson

            };
            break;
        case "GET_FORM_VALUES":
            let formValues = state.formValues;
            let profileValues = state.profileValues;
            let meta = state.meta;
            let id = state.id;
            let flags = state.flags;
            profileValues[action.recordid] = action.profileValues[action.recordid];
            formValues[action.recordid] = action.formValues[action.recordid];
            meta[action.recordid] = action.meta[action.recordid];
            id[action.recordid] = action.id[action.recordid];
            flags[action.recordid] = action.flags[action.recordid];

            return {
                ...state,
                profileValues : profileValues,
                formValues : formValues,
                meta : meta,
                id : id,
                flags : flags
            };
            break;

        case "LIST_ALL_FOLLOWUP_CALLS" :
            return {
                ...state,
                followupCallsList : action.followupCallsList,
            };
            break;
        case "FOLLOWUP_CALLS_LOADER" :
            return {
                ...state,
                loading :action.loading
            }
        case "LIST_LEAD_SCORES" :
            return {
                ...state,
                leadScores : action.leadScores,
                selected_lead_id : action.selected_lead_id,
            };
            break;
        case "LEAD_SCORE_LOADER" :
            return {
                ...state,
                loading :action.loading
            }


        case "GET_FLAG_LIST" :
            return {
                ...state,
                flagList : action.flagList,
                flagCategories : action.flagCategories
            }

        case "RESET_FORM_VALUES":

            return {
                ...state,
                profileValues : [],
                formValues : [],
                meta : [],
                id : [],
                flags : []
            };
            break;
        default:
            return state;
    }
};

const getUpdateFields = (state = {fieldUpdated : {}}, action) => {
    switch (action.type) {
        case "UPDATE_FORM_FIELD" :
            return {
                ...state,
                fieldUpdated: action.fieldUpdated
            }
        default:
            return state;
    }
}
const getUpdateFollowup = (state = {followupUpdated : {}}, action) => {
    switch (action.type) {
        case "UPDATE_FOLLOW_UP" :
            return {
                ...state,
                followupUpdated : action.followupUpdated
            }
        default:
            return state;
    }
}
const taskDetails = (state={taskList:[], loading : false},action) => {

    switch (action.type) {
        case "WH_TASK_LIST" :
            return {
                ...state,
                taskList : action.taskList
            }
        case "WH_TASK_LOADER" :
            return {
                ...state,
                loading : action.loading
            }
        default:
            return state;
    }
}
const whSync = (state={leadSync:''},action) => {

    switch (action.type) {
        case "LEADS_SYNCED" :
            return {
                ...state,
                leadSync : action.leadSync
            }
        case "WH_TASK_LOADER" :
            return {
                ...state,
                loading : action.loading
            }
        default:
            return state;
    }
}

const invoiceDetails = (state = {data:[], loading : false}, action) => {
    switch (action.type) {
        case "INVOICE_LIST":
            return {
                ...state,
                data: action.invoiceList,
            };
            break;
        case "INVOICE_LOADER" :
            return {
                ...state,
                loading :action.loading
            }
        default:
            return state;
    }
};

const paymentDetails = (state = {data:''}, action) => {
    switch (action.type) {
        case "PAYMENT_INFO":
            return {
                ...state,
                data: action.paymentInfo
            };
            break;
        default:
            return state;
    }
};

const LeadProgramList = (state = {data:[], lead:[], program:[], loading:false}, action) => {
    switch (action.type) {
        case "LEAD_PROGRAM":
            return {
                ...state,
                data: action.LeadProgram,
                lead: action.lead,
                program: action.program,
            };
            break;

        case "LEAD_PROGRAM_LOADER" :
            return {
                ...state,
                loading : action.loading

            };
            break;

        default:
            return state;
    }
};

const recieveOrderId = (state = {data:''}, action) => {
    switch (action.type) {
        case "GENERATE_ORDERID":
            return {
                ...state,
                data: action.orderId
            };
            break;
        default:
            return state;
    }
};

const resourceDetail = (state = {data:[], loading : false}, action) => {
    switch (action.type) {
        case "RESOURCE_DETAILS":
            return {
                ...state,
                data: action.resourceDetails.data.results.data
            };
            break;
        case "RESOURCE_LOADER" :
            return {
                ...state,
                loading :action.loading
            }
        default:
            return state;
    }
};
const saveResourcesUpdated = (state = {data:[], loading : false}, action) => {
    switch (action.type) {
        case "RESOURCE_SAVE":
            return {
                ...state,
                data: action.saveResourcesForm.data.results
            };
            break;
        case "SAVE_RESOURCE_LOADER" :
            return {
                ...state,
                loading :action.loading
            }
        default:
            return state;
    }
};
const getSavedResource = (state = {data:[], loading : false}, action) => {
    switch (action.type) {
        case "GET_RESOURCE_SAVE":
            return {
                ...state,
                data: action.getSavedResources.data.results.data
            };
            break;
        case "GET_RESOURCE_LOADER" :
            return {
                ...state,
                loading :action.loading
            }
        default:
            return state;
    }
};
const listHelpText = (state = {data:[], loading : false}, action) => {
    switch (action.type) {
        case "GET_HELP_TEXT":
            return {
                ...state,
                data: action.getHelpText.data.results.data
            };
            break;
        case "GET_HELP_TEXT_LOADER" :
            return {
                ...state,
                loading :action.loading
            }
        default:
            return state;
    }
};

export const Whatsapp = combineReducers({
    listChannelData,
    listLeadsData,
    listChatData,
    getFormDetails,
    invoiceDetails,
    paymentDetails,
    LeadProgramList,
    taskDetails,
    whSync,
    recieveOrderId,
    resourceDetail,
    getSavedResource,
    saveResourcesUpdated,
    listHelpText,
    getUpdateFields,
    getUpdateFollowup

});

export default Whatsapp;


