import {ceshareview} from "../services/ceshareview.services";
import {collections} from "../services/collections.services";

export const importExcelData = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "import-excel",
        status:true

    })
    let importExcelData = ceshareview.importExcelData(param);

    importExcelData.then(importExcelData => {
        dispatch({
            type: 'EXCEL_IMPORT_UPDATE_ALL',
            importExcelData:importExcelData

        })


        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}
export const pcsaveData = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "PC_SETTINGS_DATA",
        status:true

    })
    let pcsaveData = ceshareview.pcsaveData(param);

    pcsaveData.then(pcsaveData => {
        dispatch({
            type: 'PC_COLLECTION_LINKED_DATA',
            pcsaveData:pcsaveData

        })


        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}

export const pcremoveData = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "PC_SETTINGS_DATA",
        status:true

    })
    let pcremoveData = ceshareview.pcremoveData(param);

    pcremoveData.then(pcremoveData => {
        dispatch({
            type: 'PC_COLLECTION_LINKED_DATA_REMOVAL',
            pcremoveData:pcremoveData

        })


        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}
export const getLinkedItems = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "GET_LINKED_ITEMS_LOADER",
        status:true

    })
    let linkeditems = ceshareview.linkedItems(param);

    linkeditems.then(linkeditems => {
        dispatch({
            type: 'GET_LINKED_ITEMS',
            linkeditems:linkeditems

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    }).catch(error => {
        dispatch({
            type: "NET_WORK_ERROR",
            status:true,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        });

    });

}
export const getFieldPropagationlist = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "LIST_FIELD_PROPAGATION_LOADER",
        status:true

    })
    let  fieldPropagationlist = ceshareview.fieldpropagation(param);

    fieldPropagationlist.then(fieldPropagationlist => {
        dispatch({
            type: 'LIST_FIELD_PROPAGATION',
            fieldPropagationlist:fieldPropagationlist

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    }).catch(error => {
        dispatch({
            type: "NET_WORK_ERROR",
            status:true,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        });

    });

}
export const saveFieldPropagation = (param) => dispatch => {
    let saveFieldPropagationData = ceshareview.savefieldpropagation(param);

    saveFieldPropagationData.then(saveFieldPropagationData => {
        dispatch({
            type: 'SAVE_FIELD_PROPAGATION',
            saveFieldPropagationData:saveFieldPropagationData

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}
export const removeFieldPropagation = (param) => dispatch => {
    let removeFieldPropagationData = ceshareview.removefieldpropagation(param);

    removeFieldPropagationData.then(removeFieldPropagationData => {
        dispatch({
            type: 'REMOVE_FIELD_PROPAGATION',
            removeFieldPropagationData:removeFieldPropagationData

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}
export const getFormFields = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "GET_FORM_FIELDS_LOADER",
        status:true

    })
    let formFields = ceshareview.showForm(param);

    formFields.then(formFields => {
        dispatch({
            type: 'GET_FORM_FIELDS',
            formFields:formFields

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    }).catch(error => {
        dispatch({
            type: "NET_WORK_ERROR",
            status:true,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        });

    });

}

export const fieldVersion = (param) => dispatch => {
    dispatch({
        type: 'LOADER_FIELD_VERSION',
        loader:true

    })
    let fieldVersion = ceshareview.fieldVersion(param);
    fieldVersion.then(fieldVersion => {
        dispatch({
            type: 'FIELD_VERSION',
            fieldVer:fieldVersion,
            loader:false
        })

    })

}
export const shareAll = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let sharealldata = ceshareview.shareall(param);

    sharealldata.then(sharealldata => {
        dispatch({
            type: 'SHARE_ALL',
            sharealldata:sharealldata

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    })

}
export const getFlagList = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Flag-List-Loader',
        status:true

    });
    let getFlagList = ceshareview.getFlagList(param);

    getFlagList.then(getFlagList => {
        dispatch({
            type: 'FLAG_LIST',
            getFlagList:getFlagList

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Flag-List-Loader',
            status:false

        });
    })

}
export const saveFlagList = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Flag-Save-Loader',
        status:true

    });
    let saveFlagList = ceshareview.saveFlagList(param);

    saveFlagList.then(saveFlagList => {
        dispatch({
            type: 'FLAG_SAVE',
            saveFlagList:saveFlagList

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Flag-Save-Loader',
            status:false

        });
    })

}
export const deleteFlagData = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Flag-Delete-Loader',
        status:true

    });
    let deleteFlagData = ceshareview.deleteFlagData(param);

    deleteFlagData.then(deleteFlagData => {
        dispatch({
            type: 'FLAG_DELETE',
            deleteFlagData:deleteFlagData

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Flag-Delete-Loader',
            status:false

        });
    })

}
export const deleteFlagCategory = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Category-Delete-Loader',
        status:true

    });
    let deleteFlagCategory = ceshareview.deleteFlagCategory(param);

    deleteFlagCategory.then(deleteFlagCategory => {
        dispatch({
            type: 'CATEGORY_DELETE',
            deleteFlagCategory:deleteFlagCategory

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Category-Delete-Loader',
            status:false

        });
    })

}
export const removeAssignedFlag = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Remove-Assigned-Flag-Loader',
        status:true

    });
    let removeFlagData = ceshareview.removeassignedflag(param);

    removeFlagData.then(removeFlagData => {
        dispatch({
            type: 'REMOVE_ASSIGNED_FLAG',
            removeFlagData:removeFlagData

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Remove-Assigned-Flag-Loader',
            status:false

        });
    })

}
export const assignFlag = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Assign-Flag-Loader',
        status:true

    });
    let assignFlagData = ceshareview.assignflag(param);

    assignFlagData.then(assignFlagData => {
        dispatch({
            type: 'ASSIGN_FLAG',
            assignFlagData:assignFlagData

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Assign-Flag-Loader',
            status:false

        });
    })

}

export const colUpdate = (param) => dispatch => {
    dispatch({
        type: 'FlAG-COL-UPDATE',
        status:Math.random()

    });

}

export const fieldUpdate = (param) => dispatch => {
    let fieldUpdate = ceshareview.fieldUpdate(param);

    fieldUpdate.then(fieldUpdate => {

        dispatch({
            type: 'FIELD_UPDATE_COL_ENTITY',
            data:fieldUpdate

        })
    })

}
export const savingNoteShare = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let savingNoteShare = ceshareview.savingNoteShare(param);

    savingNoteShare.then(savingNoteShare => {

        dispatch({
            type: 'SAVE_NOTE_SHARE',
            savedNoteShare:savingNoteShare

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}
export const listShareNotes = (param) => dispatch => {
    let listShareNotes = ceshareview.listShareNotes(param);

    listShareNotes.then(listShareNotes => {

        dispatch({
            type: 'LIST_NOTE_SHARE',
            listShareNotes:listShareNotes

        })

    })

}
export const saveCategory = (param) => dispatch => {
    let saveCategory = ceshareview.saveCategory(param);

    saveCategory.then(saveCategory => {

        dispatch({
            type: 'SAVE_CATEGORY',
            saveCategory:saveCategory

        })

    })

}
export const getFlagCategory = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Flag-Category-Loader',
        status:true

    });
    let getFlagCategory = ceshareview.getFlagCategory(param);

    getFlagCategory.then(getFlagCategory => {
        dispatch({
            type: 'FLAG_CATEGORY',
            getFlagCategory:getFlagCategory

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Flag-Category-Loader',
            status:false

        });
    })

}
export const saveQuickForm = (param) => dispatch => {
    let saveQuickForm = ceshareview.saveQuickForm(param);

    saveQuickForm.then(saveQuickForm => {

        dispatch({
            type: 'SAVE_QUICKFORM',
            saveQuickForm:saveQuickForm

        })

    })

}

export const quickFormList = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'List-Quickform-Loader',
        status:true

    });
    let quickFormList = ceshareview.quickFormList(param);

    quickFormList.then(quickFormList => {
        dispatch({
            type: 'LIST_QUICKFORM',
            quickFormList:quickFormList

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'List-Quickform-Loader',
            status:false

        });
    })

}
export const quickFormDetail = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Quickform-Detail-Loader',
        status:true

    });
    let quickFormDetail = ceshareview.quickFormDetail(param);

    quickFormDetail.then(quickFormDetail => {
        dispatch({
            type: 'QUICKFORM_DETAIL',
            quickFormDetail:quickFormDetail

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Quickform-Detail-Loader',
            status:false

        });
    })

}
export const deleteQuickForm = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Quickform-Delete-Loader',
        status:true

    });
    let deleteQuickForm = ceshareview.deleteQuickForm(param);

    deleteQuickForm.then(deleteQuickForm => {
        dispatch({
            type: 'QUICKFORM_DELETE',
            deleteQuickForm:deleteQuickForm

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Quickform-Delete-Loader',
            status:false

        });
    })

}
export const assignRoleQuickForm = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Quickform-Role-Loader',
        status:true

    });
    let assignRoleQuickForm = ceshareview.assignRoleQuickForm(param);

    assignRoleQuickForm.then(assignRoleQuickForm => {
        dispatch({
            type: 'QUICKFORM_ROLE',
            assignRoleQuickForm:assignRoleQuickForm

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Quickform-Role-Loader',
            status:false

        });
    })

}
export const manageQuickform = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Manage-Quickform-Loader',
        status:true

    });
    let manageQuickform = ceshareview.manageQuickform(param);

    manageQuickform.then(manageQuickform => {
        dispatch({
            type: 'MANAGE_QUICKFORM',
            manageQuickform:manageQuickform

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Manage-Quickform-Loader',
            status:false

        });
    })

}
export const setdefaultQuickform = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Default-Quickform-Loader',
        status:true

    });
    let setdefaultQuickform = ceshareview.setdefaultQuickform(param);

    setdefaultQuickform.then(setdefaultQuickform => {
        dispatch({
            type: 'DEFAULT_QUICKFORM',
            setdefaultQuickform:setdefaultQuickform

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Default-Quickform-Loader',
            status:false

        });
    })

}

export const templateList = () => dispatch => {
    let templateList = ceshareview.templateList();

    templateList.then(templateList => {

        dispatch({
            type: 'TEMPLATE_LIST',
            templateList:templateList

        })

    })

}


export const saveFilteredView = (param) => dispatch => {
    dispatch({
        type: 'SAVE_FILTERED_VIEW_LOADER',
        loader:true

    });
    let savefilteredview = ceshareview.savefilteredView(param);

    savefilteredview.then(savefilteredview => {

        dispatch({
            type: 'SAVE_FILTERED_VIEW',
            savefilteredview:savefilteredview,
            loader:false

        })

    })

}

export const getFilterdList = (param) => dispatch => {

    dispatch({
        type: 'GET_FILTERED_VIEW_LOADER',
        loader:true

    });
    let getFilterdList = ceshareview.getFilterdList(param);

    getFilterdList.then(getFilterdList => {

        dispatch({
            type: 'GET_FILTERED_VIEW',
            getFilterdList:getFilterdList,
            loader:false

        })

    })

}

export const deleteFilter = (param) => dispatch => {

    dispatch({
        type: 'DELETE_FILTERED_VIEW_LOADER',
        loader:true

    });
    let deleteFilter = ceshareview.deleteFilterView(param);

    deleteFilter.then(deleteFilter => {

        dispatch({
            type: 'DELETE_FILTERED_VIEW',
            deleteFilter:deleteFilter,
            loader:false

        })

    })

}
export const saveFlagOpen = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'SAVE_FLAG_OPEN_LOADER',
        status:true
    });
    let saveFlagOpen = ceshareview.saveFlagOpen(param);
    saveFlagOpen.then(saveFlagOpen => {
        dispatch({
            type: 'SAVE_FLAG_OPEN',
            saveFlagOpen:saveFlagOpen,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'SAVE_FLAG_OPEN_LOADER',
            status:false
        });
    })
}
export const getButtonList = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Button_List_Loader',
        status:true
    });
    let getButtonList = ceshareview.getButtonList(param);
    getButtonList.then(getButtonList => {
        dispatch({
            type: 'BUTTON_LIST',
            getButtonList:getButtonList,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Button_List_Loader',
            status:false
        });
    })
}
export const saveButtonList = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Save_Button_List_Loader',
        status:true
    });
    let saveButtonList = ceshareview.saveButtonList(param);
    saveButtonList.then(saveButtonList => {
        dispatch({
            type: 'SAVE_BUTTON_LIST',
            saveButtonList:saveButtonList,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Save_Button_List_Loader',
            status:false
        });
    })
}
export const deleteButtonData = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Delete_Button_List_Loader',
        status:true
    });
    let deleteButtonData = ceshareview.deleteButtonData(param);
    deleteButtonData.then(deleteButtonData => {
        dispatch({
            type: 'DELETE_BUTTON_LIST',
            deleteButtonData:deleteButtonData,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Delete_Button_List_Loader',
            status:false
        });
    })
}
export const saveRoleList = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Save_Role_List_Loader',
        status:true
    });
    let saveRoleList = ceshareview.saveRoleList(param);
    saveRoleList.then(saveRoleList => {
        dispatch({
            type: 'SAVE_ROLE_LIST',
            saveRoleList:saveRoleList,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Save_Role_List_Loader',
            status:false
        });
    })
}
export const getRoleList = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Role_List_Loader',
        status:true
    });
    let getRoleList = ceshareview.getRoleList(param);
    getRoleList.then(getRoleList => {
        dispatch({
            type: 'ROLE_LIST',
            getRoleList:getRoleList,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Role_List_Loader',
            status:false
        });
    })
}
export const deleteRoleData = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Delete_Role_List_Loader',
        status:true
    });
    let deleteRoleData = ceshareview.deleteRoleData(param);
    deleteRoleData.then(deleteRoleData => {
        dispatch({
            type: 'DELETE_ROLE_LIST',
            deleteRoleData:deleteRoleData,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Delete_Role_List_Loader',
            status:false
        });
    })
}
export const savePermission = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Save_Permission_Loader',
        status:true
    });
    let savePermission = ceshareview.savePermission(param);
    savePermission.then(savePermission => {
        dispatch({
            type: 'SAVE_PERMISSION',
            savePermission:savePermission,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Save_Permission_Loader',
            status:false
        });
    })
}

export const getRolePermission = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Role_List_Permission_Loader',
        status:true
    });
    let getRolePermission = ceshareview.getRolePermission(param);
    getRolePermission.then(getRolePermission => {
        dispatch({
            type: 'LIST_PERMISSION',
            getRolePermission:getRolePermission,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Role_List_Permission_Loader',
            status:false
        });
    })
}



export const getFullwidth = (param) => dispatch => {

    let getFullwidth = ceshareview.getFullwidth(param);
    dispatch({
        type: 'LIST_FULLWIDTH_DATA',
        data:[],
        loader:true
    })
    getFullwidth.then(getFullwidth => {
        dispatch({
            type: 'LIST_FULLWIDTH_DATA',
            data: getFullwidth.data.data.fields,
            loader:false

        })

    })
}
export const saveFullwidth = (param) => dispatch => {
    let saveFullwidthId = ceshareview.saveFullwidth(param);
    dispatch({
        type: 'SAVE_FULLWIDTH_IDS',
        data:[],
        loader:true
    })
    saveFullwidthId.then(saveFullwidthId => {

        dispatch({
            type: 'SAVE_FULLWIDTH_IDS',
            data:saveFullwidthId,
            loader:false

        })

    })

}

export const listPool = (param) => dispatch => {
    let listPool = ceshareview.listPool(param);

    listPool.then(listPool => {
        dispatch({
            type: 'POOL_LIST',
            listPool:listPool
        })
    })
}
export const getResultSet = (param, collectionId) => dispatch => {
    let getResultSet = ceshareview.getResultSet(param, collectionId);

    getResultSet.then(getResultSet => {
        dispatch({
            type: 'RESULT_SET',
            resultSet:getResultSet
        })
    })
}
export const saveSummarySettings = (param) => dispatch => {
    let saveSummarySettings = ceshareview.savesummary(param);
    dispatch({
        type: 'SAVE_SUMMARY_SETTINGS',
        data:{},
        loader:true
    })
    saveSummarySettings.then(saveSummarySettings => {

        dispatch({
            type: 'SAVE_SUMMARY_SETTINGS',
            data:saveSummarySettings.data,
            loader:false

        })

    })

}

export const SaveDate = (param) => dispatch => {
    let SaveDate = ceshareview.savedate(param);
    dispatch({
        type: 'SAVE_BOOKING_DATA',
        data:{},
        loader:true
    })
    SaveDate.then(SaveDate => {

        dispatch({
            type: 'SAVE_BOOKING_DATA',
            data:SaveDate.data,
            loader:false

        })

    })

}

export const sendFieldEmails = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Save_FieldEmails_Loader',
        status:true
    });
    let sendFieldEmails = ceshareview.sendFieldEmails(param);
    sendFieldEmails.then(sendFieldEmails => {
        dispatch({
            type: 'SEND_EMAILS',
            emails:sendFieldEmails,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Save_FieldEmails_Loader',
            status:false
        });
    })
}
export const sendFieldSMS = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Save_FieldSMS_Loader',
        status:true
    });
    let sendFieldSMS = ceshareview.sendFieldSMS(param);
    sendFieldSMS.then(sendFieldSMS => {
        dispatch({
            type: 'SEND_SMS',
            sms:sendFieldSMS,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Save_FieldSMS_Loader',
            status:false
        });
    })
}
export const sendFieldWhatsapp = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Save_FieldWhatsapp_Loader',
        status:true
    });
    let sendFieldWhatsapp = ceshareview.sendFieldWhatsapp(param);
    sendFieldWhatsapp.then(sendFieldWhatsapp => {
        dispatch({
            type: 'SEND_WHATSAPP',
            whatsapp:sendFieldWhatsapp,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Save_FieldWhatsapp_Loader',
            status:false
        });
    })
}

export const savecalSetngs = (param) => dispatch => {
    dispatch({
        type: 'SAVE_CAL_SET_DATA',
        loader:true
    })

    let savecalSetngs = ceshareview.savecalSetngs(param);
    savecalSetngs.then(savecalSetngs => {
        dispatch({
            type: 'SAVE_CAL_SET_DATA',
            setngs:savecalSetngs,
            loader:false
        })

    })
}
export const getSummaryPanelDetails = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Summary_panel_details',
        status:true
    });
    let summaryDetails = ceshareview.getSummaryPanelDetails(param);
    summaryDetails.then(summaryDetails => {
        dispatch({
            type: 'SUMMARY_PANEL_DETAILS',
            summaryDetails:summaryDetails.data.results,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Summary_panel_details',
            status:false
        });
    })
}
export const getSharedForm = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Shared_form_detail',
        status:true
    });
    let sharedFormDetails = ceshareview.getSharedForm(param);
    sharedFormDetails.then(sharedFormDetails => {
        dispatch({
            type: 'SHARED_FORM_DETAILS',
            sharedForms:sharedFormDetails.data,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Shared_form_detail',
            status:false
        });
    })
}
export const saveFlagOrder = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Save_Flagorder_Loader',
        status:true
    });
    let saveFlagOrder = ceshareview.saveflagorder(param);
    saveFlagOrder.then(saveFlagOrder => {
        dispatch({
            type: 'SAVE_FLAG_ORDER',
            saveFlagOrder:saveFlagOrder,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Save_Flagorder_Loader',
            status:false
        });
    })
}
export const saveFlagCategoryOrder = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Save_Flag_category_order_Loader',
        status:true
    });
    let saveFlagCatOrder = ceshareview.saveflagcategoryorder(param);
    saveFlagCatOrder.then(saveFlagCatOrder => {
        dispatch({
            type: 'SAVE_FLAG_CATEGORY_ORDER',
            saveFlagCatOrder:saveFlagCatOrder,
            loader:false
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Save_Flag_category_order_Loader',
            status:false
        });
    })
}

export const collectionRecordDuplicate = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Duplicate_Record_Loader',
        status:true

    });
    let collectionRecordDuplicate = ceshareview.collectionRecordDuplicate(param);

    collectionRecordDuplicate.then(collectionRecordDuplicate => {
        dispatch({
            type: 'DUPLICATE_RECORD',
            collectionRecordDuplicate:collectionRecordDuplicate

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Duplicate_Record_Loader',
            status:false

        });
    })
}

export const getFieldValues = (collectionid, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Field_List',
        status:true

    });
    let getFieldValues = ceshareview.getCollectedInfo( collectionid,params);
    getFieldValues.then( getFieldValues => {
        dispatch({
            type: 'FIELD_LIST_SUCCESS',
            getFieldValues: getFieldValues
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Field_List',
            status:false

        });
    });

};
export const getPaymentData = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Payment_Data_Loader',
        status:true

    });
    let getPaymentData = ceshareview.getPaymentDetail(params);
    getPaymentData.then( getPaymentData => {
        dispatch({
            type: 'PAYMENT_DATA',
            getPaymentData: getPaymentData
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Payment_Data_Loader',
            status:false

        });
    });

};
export const getPaymentDetail = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Payment_Detail_Loader',
        status:true

    });
    let getPaymentDetail = ceshareview.getPaymentList(params);
    getPaymentDetail.then( getPaymentDetail => {
        dispatch({
            type: 'PAYMENT_DETAIL',
            getPaymentDetail: getPaymentDetail
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Payment_Detail_Loader',
            status:false

        });
    });

};
export const savePaymentUrl = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Payment_url_Loader',
        status:true

    });
    let savePaymentUrl = ceshareview.savePaymentUrls(params);
    savePaymentUrl.then( savePaymentUrl => {
        dispatch({
            type: 'SAVE_PAYMENT_URL',
            savePaymentUrl: savePaymentUrl
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Payment_url_Loader',
            status:false

        });
    });

};
export const saveRazorpayPaymentUrl = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Razorpay_Payment_url_Loader',
        status:true

    });
    let savePaymentUrlRazorpay = ceshareview.saveRazorpayPayment(params);
    savePaymentUrlRazorpay.then( savePaymentUrlRazorpay => {
        dispatch({
            type: 'RAZORPAY_SAVE_PAYMENT_URL',
            savePaymentUrlRazorpay: savePaymentUrlRazorpay
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Razorpay_Payment_url_Loader',
            status:false

        });
    });

};
export const paymentInfoSave = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Payment_Info_Loader',
        status:true

    });
    let paymentInfoSave = ceshareview.paymentInfoSave(params);
    paymentInfoSave.then( paymentInfoSave => {
        dispatch({
            type: 'SAVE_PAYMENT_INFO',
            paymentInfoSave: paymentInfoSave.data
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Payment_Info_Loader',
            status:false

        });
    });

};
export const savePrintDetails = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'save_print_details_loader',
        status:true

    });
    let savePrintDetailsInfo = ceshareview.saveprintdetails(params);
    savePrintDetailsInfo.then( savePrintDetailsInfo => {
        dispatch({
            type: 'SAVE_PRINT_DETAILS',
            savePrintDetailsInfo: savePrintDetailsInfo.data
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'save_print_details_loader',
            status:false

        });
    });

};
export const removeprint = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'remove_print_details_loader',
        status:true

    });
    let removePrintDetailsInfo = ceshareview.removeprint(params);
    removePrintDetailsInfo.then( removePrintDetailsInfo => {
        dispatch({
            type: 'REMOVE_PRINT_DETAILS',
            removePrintDetailsInfo: removePrintDetailsInfo.data
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'remove_print_details_loader',
            status:false

        });
    });

};
export const saveprintsettings = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'save_print_settings_loader',
        status:true

    });
    let savePrintSettings = ceshareview.saveprintsettings(params);
    savePrintSettings.then( saveprintsettings => {
        dispatch({
            type: 'SAVE_PRINT_SETTINGS',
            saveprintsettingsInfo: saveprintsettings.data
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'save_print_settings_loader',
            status:false

        });
    });

};
export const printsettingsdetails = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'print_settings_loader',
        status:true

    });
    let printDetails = ceshareview.printsettingsdetails(params);
    printDetails.then( printDetails => {
        dispatch({
            type: 'GET_PRINT_SETTINGS',
            printDetails: printDetails.data.data
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'print_settings_loader',
            status:false

        });
    });

};
export const savePaymentData = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'Save_Payment_loader',
        status:true

    });
    let savePaymentData = ceshareview.savePaymentData(params);
    savePaymentData.then( savePaymentData => {
        dispatch({
            type: 'SAVE_PAYMENT_DATA',
            savePayments: savePaymentData.data
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'Save_Payment_loader',
            status:false

        });
    });

};


export const saveHeadersettings = (param) => dispatch => {
    let saveHeadersettings = ceshareview.saveHeadersettings(param);

    saveHeadersettings.then(getsaveHeadersettings => {
        dispatch({
            type: 'SAVE-HEADER-SETTINGS',
            data:getsaveHeadersettings

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}
export const  saveDisplaySettings = (param) => dispatch => {
    let  saveDisplaySettings = ceshareview.savedisplayset(param);
    dispatch({
        type: 'SAVE_DISPLAY_SETTINGS',
        data:[],
        loader:true
    });
    saveDisplaySettings.then( saveDisplaySettings => {

        dispatch({
            type: 'SAVE_DISPLAY_SETTINGS',
            data: saveDisplaySettings.data,
            loader:false

        });

    });

};
export const destinationLabel = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let showformdata = collections.showForm(param);
    showformdata.then( showformdata => {
        dispatch({
            type: 'SUMMARY_FORM_LABELS',
            showformdata: showformdata.data.data,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })

    });

};
export const  saveAdvanceSumPanel = (param) => dispatch => {
    let  saveAdvanceSumPanel = ceshareview.savesummary(param);
    dispatch({
        type: 'SAVE_ADVANCED_SUMPANEL',
        data:[],
        loader:true
    })
    saveAdvanceSumPanel.then( saveAdvanceSumPanel => {

        dispatch({
            type: 'SAVE_ADVANCED_SUMPANEL',
            data: saveAdvanceSumPanel.data,
            loader:false

        });

    });

};
export const  getCustomapi = (param) => dispatch => {
    let  getCustomapi = ceshareview.getCustomapi(param);
    dispatch({
        type: 'GET_CUSTOM_API',
        data:[],
        loader:true
    })
    getCustomapi.then( getCustomapi => {

        dispatch({
            type: 'GET_CUSTOM_API',
            data: getCustomapi.data.results.data,
            success: getCustomapi.data.results.success,
            loader:false

        });

    });

};
export const saveColorSettings = (param) => dispatch => {
    let saveColorSettings = ceshareview.saveColorSettings(param);

    saveColorSettings.then(getColorsettings => {
        dispatch({
            type: 'SAVE-COLOR-SETTINGS',
            data:getColorsettings

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}

export const advsaveSettings = (param) => dispatch => {

        dispatch({
            type: 'ADVANCED_PANEL_SETTINGS',
            data:param

        })


}
export const  getKanbanSettings = (param) => dispatch => {
    let  kanbanSettings = ceshareview.getKanbanSettings(param);
    dispatch({
        type: 'GET_KANBAN_SETTINGS',
        data:[],
        loader:true
    })
    kanbanSettings.then( kanbanSettings => {
        dispatch({
            type: 'GET_KANBAN_SETTINGS',
            data: kanbanSettings.data.results.data,
            loader:false

        });

    });

};

