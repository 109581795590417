import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import {getProfile} from '../../utilities/AuthService';
import * as taskActions from "../../actions/ToDo";
import {ES_INDEX, FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH, SHARED_LOGIN, USRIMG_PATH, S3_URL} from "../../constants";
import {
    getFileIcon,
    getUrlSegment,
    getAttachmentPath
} from "../../utilities/CustomFunctions";
import * as usersActions from "../../actions/Users";
import ReactToPrint from 'react-to-print';
import DatePickerBox from "../views/DatePickerBox";
import UploadFile from "../../utilities/Upload/UploadFile";
import LoaderSvg from "../views/LoaderSVG";
import * as collectionActions from "../../actions/Collections";
import * as entityActions from "../../actions/Entity";
let assigneestatus ='';
let time = "";
let shared = "";
let donedate = "";
let archived ="";
let taskmode=""
let taskData = "";
let assigneesData = ""
let logData = ""
let commentData = ""
let allfilesData = ""
let attachmentData = ""
let collectionData = ""
let timelogData = ""
let subtaskData = []
let remarksData = ""
let previewableAttachments = ""
let taskid =  ""
let deleteid = ''
let parent =""
let assignees = ""

class CollectionViewPrint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData : [],
            enterpriseData : []
        }
        this.goBack = this.goBack.bind(this)
    }

    componentDidMount() {
        let data = {"index" : ES_INDEX}
        this.props.getEnterprise(data);
        let collecteddataid = atob(getUrlSegment(3));
        let collectionid = atob(getUrlSegment(4));
        if(collecteddataid){
            this.props.getFormData({ _id : collecteddataid, collectionid : collectionid});
        }

        let param={
            from: 0,
            size: 0,
            index: ES_INDEX,
            search:'',
            "uid": getProfile().id,
            "usertype": getProfile().usertype,
            "dashboard":true
        }

        this.props.collectionDetails(collectionid, param)

        this.getFormValues();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        /*if (prevProps.getPrintTask.results !== this.props.getPrintTask.results) {
            let printDetailData = this.props.getPrintTask.results  ;
            let task =  printDetailData.task ;
            let collectionid = task !== '' ? task.collecteddataid : '';
            let param = {"_id" : task.collecteddataid, "collectionid" : task.collecteddata}
            this.props.selectedCollection(param);
        }*/
        if (prevProps.getFormdetails.formvalues !== this.props.getFormdetails.formvalues) {
            this.getFormValues();
        }
        console.log(this.props.enterpriseData)
        if (prevProps.enterprise !== this.props.enterprise) {
            let enterpriseData = this.props.enterprise.results ? this.props.enterprise.results : [];
            this.setState({enterpriseData : enterpriseData});
        }

    }

    getFormValues(){
        let formData = this.props.getFormdetails.formvalues;
        this.setState({formData : formData});
    }

    getAttachmentPath=(type)=> {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }
    goBack() {
        if ((getUrlSegment(5) !== undefined) || getUrlSegment(6) !== undefined) {
            if (atob(getUrlSegment(5) !== "project")) {
                window.location.href = SHARED_LOGIN.redirect_url + "Project/details/" + atob(getUrlSegment(6)) + "#proTask"
            } else {
                this.props.history.goBack();
            }
        } else
            this.props.history.goBack();
    }
    createMarkupeditor=(value)=>{
        let editorvalue = value;
        return {__html: editorvalue};
    }

    renderField = (item, index) => {
        let fieldValue = '';

        if(item.type === 'attachment'){
            for(var i=0; i < item.values.length ; i++) {
                let uniqname = item.values ? item.values[i].uniqvalue : '';
                let filename = item.values ? item.values[i].value : '';
                let iconClass = getFileIcon(uniqname);
                let contentPath = getAttachmentPath(iconClass);

                fieldValue = <React.Fragment>
                    {iconClass !== 'image' &&
                    <div className={"format " + iconClass}>
                        {filename}
                    </div>
                    }
                    {iconClass === 'image' &&
                    <img className="custom-image" src={FRMSIMG_PATH + uniqname}/>
                    }

                </React.Fragment>;
            }

        }
        else if(item.type === 'editor') {
            fieldValue = <React.Fragment>
                {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                    <span key={index} dangerouslySetInnerHTML={this.createMarkupeditor(list.value)} ></span>
                )}
            </React.Fragment>
        }
        else if(item.type === 'imageurl' || item.type === 'signature') {
            fieldValue = <React.Fragment>
                {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                    <img key={index} src={list.value} className="custom-large-image" ></img>
                )}
            </React.Fragment>
        }
        else if(item.type === 'fieldset') {
            fieldValue = <React.Fragment>
                <div className="subheader-task workflow-form-step" key={index}>
                    <p>{item.label}</p>
                </div>

                {item.values.length !== 0 && item.values !== '' && item.values.map((data, index) =>
                    <>
                        {this.renderField(data, index)}
                    </>
                )}
            </React.Fragment>
        }
        else {
            fieldValue = <React.Fragment>
                {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                    <span key={index}>{list.value}</span>
                )}
            </React.Fragment>
        }

        return(
            <React.Fragment>
                {
                    item.type === 'formheading' &&
                    <div className="subheader-task workflow-form-step" key={index}>
                        <p>{item.label}</p>
                    </div>
                }

                {
                    item.type !== 'formheading' && item.type !== 'attachment' &&
                    <table className={`table preview-table ${(item.type === 'imageurl' || item.type === 'signature'|| item.type === 'editor'|| item.type === 'textarea') ? "field-table" : ''}`} key={index}>
                        <tbody id={(item.type !== 'formheading' && item.type !== 'textarea' && item.type !== 'editor' && item.type !== 'fieldset') && "colectionInfo" }>
                        <tr>
                            <th>{item.label}</th>
                            <td>
                                {fieldValue}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                }

                {
                    item.type === 'attachment' &&
                    <table className="table preview-table field-table" key={index}>
                        <tbody id={(item.type !== 'formheading' && item.type !== 'textarea' && item.type !== 'editor' && item.type !== 'fieldset') && "colectionInfo" }>
                        <tr>
                            <th>{item.label}</th>
                            <td>
                                {console.log(item.values)}
                        <React.Fragment>
                            {
                                Array.isArray(item.values) && item.values.map((val, vIndex) => {
                                    let uniqname = val ? val.uniqvalue : '';
                                    let filename = val ? val.value : '';
                                    let iconClass = getFileIcon(uniqname);
                                    let contentPath = getAttachmentPath(iconClass);
                                    return <React.Fragment>
                                        {(iconClass === 'image' || iconClass === 'jpg' || iconClass === 'jpeg'|| iconClass === 'png'|| iconClass === 'gif'|| iconClass === 'bmp' )&&
                                        <img className="custom-large-image" src={FRMSIMG_PATH + uniqname}/>
                                        }
                                        {(iconClass !== 'image' && iconClass !== 'jpg' &&  iconClass !== 'jpeg' && iconClass !== 'png' && iconClass !== 'gif' && iconClass !== 'bmp' )&&
                                        <div className={"format " + iconClass}>
                                            {filename}
                                        </div>
                                        }

                                    </React.Fragment>
                                })
                            }
                        </React.Fragment>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                }
                {
                    item.type === 'fieldset' &&
                    <React.Fragment>
                        {fieldValue}
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }

    render() {
        let collectionTitle = this.props.collectionData.CollectionInfo.title ? this.props.collectionData.CollectionInfo.title  : ''
        let formData = this.state.formData;
        let enterpriseData = this.state.enterpriseData;
        console.log(formData)
        return (
            <React.Fragment>
                <div className={`print-holder workflow-print-form wf-print-ui`}>
                    {enterpriseData !== '' &&
                    <div className="workflow-task-header">

                        <div className="workflow-task-header-details">
                            <h5>{enterpriseData.organization_name}</h5>
                            <p>{enterpriseData.organization_address}</p>
                        </div>
                    </div>
                    }
                    {this.props.getFormdetails.loading === true &&
                    <div className={`holder-adjust`}>
                        <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                    </div>
                    }
                    {formData.length > 0 &&
                    <React.Fragment>
                        <div className="subheader-task main-header">
                            <p>{collectionTitle}</p>
                        </div>
                        {formData.map((item, index) =>
                            <React.Fragment>
                                {this.renderField(item, index)}
                                {/*{item.type === 'formheading' &&
                                <div className="subheader-task workflow-form-step" key={index}>
                                    <p>{item.label}</p>
                                </div>
                                }
                                {item.type === 'textarea' &&
                                <table className="table preview-table field-table" key={index}>
                                    <tbody id="">
                                    <tr>
                                        <th>{item.label}</th>
                                        <td>
                                            {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                                                <span key={index}>{list.value}</span>
                                            )}
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                                }
                                {item.type === 'imageurl' &&
                                <table className="table preview-table field-table" key={index}>
                                    <tbody id="">
                                    <tr>
                                        <th>{item.label}</th>
                                        <td>
                                            {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                                                <img key={index} src={list.value} ></img>
                                            )}
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                                }
                                {item.type === 'attachment' &&
                                <table className="table preview-table field-table" key={index}>
                                    <tbody id="">
                                    <tr>
                                        <th>{item.label}</th>
                                        <td>
                                            {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                                                <img key={index} src={list.value} ></img>
                                            )}
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                                }
                                {item.type === 'editor' &&
                                <table className="table preview-table field-table" key={index}>
                                    <tbody id="">
                                    <tr>
                                        <th>{item.label}</th>
                                        <td>
                                            {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                                                <span key={index} dangerouslySetInnerHTML={this.createMarkupeditor(list.value)} ></span>
                                            )}
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                                }
                                {item.type !== 'formheading' && item.type !== 'textarea' && item.type !== 'editor' && item.type !== 'fieldset' &&
                                <table className="table preview-table" key={index}>
                                    <tbody id="colectionInfo">
                                    <tr>
                                        <th>{item.label}</th>
                                        <td>
                                            {item.values.length !== 0 && item.values !== '' && item.values.map((list, index) =>
                                                <span key={index}>{list.value}</span>
                                            )}
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>
                                }*/}

                                {item.type === 'fieldset' &&
                                <React.Fragment>
                                    <div className="subheader-task workflow-form-step" key={index}>
                                        <p>{item.label}</p>
                                    </div>

                                    {item.values.length !== 0 && item.values !== '' && item.values.map((data, index) =>
                                        <>
                                            {data.type === 'formheading' &&
                                            <div className="subheader-task workflow-form-step" key={index}>
                                                <p>{data.label}</p>
                                            </div>
                                            }
                                            {data.type === 'textarea' &&
                                            <table className="table preview-table field-table" key={index}>
                                                <tbody id="">
                                                <tr>
                                                    <th>{data.label}</th>
                                                    <td>
                                                        {data.values.length !== 0 && data.values !== '' && data.values.map((list, index) =>
                                                            <span key={index}>{list.value}</span>
                                                        )}
                                                    </td>

                                                </tr>
                                                </tbody>
                                            </table>
                                            }
                                            {data.type === 'editor' &&
                                            <table className="table preview-table field-table" key={index}>
                                                <tbody id="">
                                                <tr>
                                                    <th>{data.label}</th>
                                                    <td>
                                                        {data.values.length !== 0 && data.values !== '' && data.values.map((list, index) =>
                                                            <span key={index} dangerouslySetInnerHTML={this.createMarkupeditor(list.value)} ></span>
                                                        )}
                                                    </td>

                                                </tr>
                                                </tbody>
                                            </table>
                                            }
                                            {data.type !== 'formheading' && data.type !== 'textarea' && data.type !== 'editor' && data.type !== 'fieldset' &&
                                            <table className="table preview-table" key={index}>
                                                <tbody id="colectionInfo">
                                                <tr>
                                                    <th>{data.label}</th>
                                                    <td>
                                                        {data.values.length !== 0 && data.values !== '' && data.values.map((list, index) =>
                                                            <span key={index}>{list.value}</span>
                                                        )}
                                                    </td>

                                                </tr>
                                                </tbody>
                                            </table>
                                            }
                                        </>
                                    )}

                                </React.Fragment>
                                }

                            </React.Fragment>
                        )}
                    </React.Fragment>
                    }
                </div>
                <div className={`print-footer`}></div>
            </React.Fragment>
        );
    }


}
const mapStateToProps = state => ({
    collectionData: state.CollectionsReducer.listCollectionDetaildata,
    getFormdetails : state.CollectionsReducer.getFormData,
    enterprise:state.TaskReducer.getEnterprise,
});

export default  withRouter(connect(mapStateToProps, {
    getEnterprise: taskActions.getEnterprise,
    getFormData : collectionActions.getFormData,
    collectionDetails:collectionActions.collectionDetail,

})(CollectionViewPrint));