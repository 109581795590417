import {loginForm} from "../services/login.services";
import {login} from '../utilities/AuthService';
//this.Auth = new AuthService();
export const UserLogin = (username, password) => dispatch => {
    let logindata = loginForm.login(username, password);
    //console.log(logindata);
    logindata.then(logindata => {
        console.log(logindata)
        if (logindata.data.success) {
            //console.log(logindata.data.data.signin[0].Token)
            login(logindata.data.data.signin[0].Token).then(res => {
                window.location = "/";
            })
        } else {
            alert(logindata.data.message)
        }

    })


};


