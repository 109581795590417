import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../utilities/CustomFunctions";
import ShowFormModal from "../views/ShowFormModal";
import * as collectionActions from "../../actions/Collections";
import LoaderSvg from "../views/LoaderSVG";
import {getProfile} from "../../utilities/AuthService";

class FormDataModal extends Component {

    constructor(props) {
        super(props);

        this.state={
            formName: '',
            formDesc: '',
            collectionId : '',
            collecteddataid: '',
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            todotaskdata:[],
            offset: 20,
            successMessage : '',
            submissionCompleted : false

        }
        try{
            getProfile();
            this.state.uid = getProfile().id;
            this.state.usertype = getProfile().usertype;
            this.state.formaccess = 'internal';
        }
        catch (e) {
            this.state.uid = 2;
            this.state.usertype = 'Superadmin';
            this.state.formaccess = 'external';
        }
    }

    componentDidMount() {
        let collectionId = atob(getUrlSegment(2));
        let formName = '';
        let formDesc = '';
        this.props.showform({ _id : collectionId},this.state.formaccess);
        let collecteddataid = getUrlSegment(5) ? getUrlSegment(5) : '';
        if(collecteddataid !== '') {
            this.props.getFormData({ _id : collecteddataid, collectionid : collectionId});

            this.setState({
                formName : formName,
                formDesc : formDesc,
                collectionId : collectionId,
                collecteddataid : collecteddataid
            })
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged) {

            ////console.log("adasdadss")
            this.setState({ submissionCompleted : true, successMessage : "Form Data Saved Successfully"})
        }
    }

    render() {
        let collection = this.props.collectionData;
        return (
            <React.Fragment>
                {this.state.loading === 1 &&
                <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                }
                {!this.state.submissionCompleted &&
                <ShowFormModal
                    formName={this.state.formName}
                    formDesc={this.state.formDesc}
                    collectionId={this.state.collectionId}
                    referenceId={this.state.collectionId}
                    hideFormModal={this.hideFormModal}
                    editId={this.state.collecteddataid}
                    Formtemplate={collection.CollectionInfo.formtemplate}
                    iosForm={true}
                />
                }
                {this.state.submissionCompleted &&
                <CompletedBlock />
                }
            </React.Fragment>

        );
    }
}



const mapStateToProps = state => ({
    collectionData: state.CollectionsReducer.listCollectionDetaildata,
    getSavedForm : state.CollectionsReducer.getSavedForm,
});
export default  withRouter(connect(mapStateToProps, {
    showform : collectionActions.showform,
    getFormData : collectionActions.getFormData,
})(FormDataModal));


function CompletedBlock(Props){
    return(
        <div className="task-details-wrapper niceScroll">
            <div className="task-details-inside">
                <div className="task-split task-split-full">

                    <div className="task-details-body-block">

                        <div className="task-details-body-container">
                            <h5>Form Data Saved Successfully</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {CompletedBlock};