import React from 'react';
import ColorPicker from "../views/ColorPicker";
import {DragDropContext, Draggable as Drags, Droppable} from "react-beautiful-dnd";
import {getUrlSegment} from "../../../../utilities/CustomFunctions";
import SimpleModal from "../../../views/SimpleModal";

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : "#fff",
    ...draggableStyle
});

class FaqSettingsModal extends React.Component {

    render() {
        let collections = this.props.containerData.collectionData
        let collection = this.props.containerData.collection
        let enableyaxis = this.props.containerData.enableyaxis
        let legend = this.props.containerData.legend
        let alldata = this.props.containerData.alldata
        let tabledata = this.props.containerData.tabledata
        let showlistField = this.props.containerData.showlistField.concat({
            type: "datepicker",
            masterid: "101",
            display_label: "CreateDate"
        })
        let condition = this.props.containerData.condition
        let enablegradient = this.props.containerData.enablegradient
        let hidearea = this.props.containerData.hidearea
        let sourceType = this.props.containerData.sourceType
        let faqTitle = this.props.containerData.faqTitle
        let faqDesc = this.props.containerData.faqDesc
        let faqImage = this.props.containerData.faqImage;
        let whereCondition = this.props.containerData.whereCondition;

        //console.log(this.props.containerData)


        return (
            <div className="modal bsy-modal lg-modal body-grey fade show" style={{"display": "block"}}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.title}</h5>
                            <div class="modal-header-options">
                                <button class="modal-option-button" onClick={() => this.props.Close()}><span
                                    class="icon-in icon-close-in"></span></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="chart-settings-container">
                                <div className="chart-settings-splitter">
                                    <div className="chart-settings-splitter-header">
                                        <h5>Data</h5>
                                    </div>

                                    <div className="each-settings-panel-wrap">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Data Set</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="each-inp-field">
                                                <label>Select Type</label>
                                                <div className="radio-wrap">
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Collection
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`sourceType`}
                                                                   checked={sourceType.toString() === "true"}
                                                                   value={true}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Entity
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`sourceType`}
                                                                   checked={sourceType.toString() === "false"}
                                                                   value={false}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {sourceType.toString() === "true" ?
                                                <div className="each-inp-field">
                                                    <label>Choose Collection</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="collection"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={collection}>>
                                                            <option value="">Choose Collection</option>
                                                            {collections.map((list, index) =>
                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list.title)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                :
                                                <div className="each-inp-field">
                                                    <label>Choose Entity</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="collection"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={collection}>
                                                            <option value="">Choose Entity</option>
                                                            {collections.map((list, index) =>

                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list._source.name)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            }


                                        </div>

                                        <div className="each-settings-panel-subheader">
                                            <h5>Condition</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="task-input-container">
                                                {this.props.containerData.hasOwnProperty("whereCondition") &&
                                                <>
                                                    <div className="each-inp-field">
                                                        <label>Conditions</label>
                                                        {this.props.containerData.whereCondition.map((item, index) =>
                                                            <div className={'panel-each'}>
                                                                <div className={`settings-panel`}>
                                                                    {/*<input type={`text`} name="label"*/}
                                                                    {/*       defaultValue={item.label}*/}
                                                                    {/*       onChange={(e) => this.props.handleLabelChange(e, item.id)}*/}
                                                                    {/*/>*/}
                                                                    <div className="select-box">
                                                                        <select id="yaxis" name="field"
                                                                                onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "field")}
                                                                                value={item.field}>
                                                                            <option value="">Conditions</option>
                                                                            {showlistField.filter(list => (list.type === "number" || list.type === "dropdown" || list.type === "checkbox" || list.type === "switch" || list.type === "text" || list.type === "datepicker")).map((label, index) =>
                                                                                <option
                                                                                    key={`multi-area-chart-masterid` + index}
                                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                    showlistField.filter(list => list.masterid === item.field && (!list.optionstatus || (list.optionstatus && list.optionstatus === '')) && (list.type === "switch" || list.type === "dropdown" || list.type === "checkbox"))[0] &&
                                                                    <div className="select-box">
                                                                        <select id="yaxis" name="conditions"
                                                                                onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "conditions")}
                                                                                value={item.conditions}>
                                                                            <option value="">Conditions</option>
                                                                            {showlistField.filter(list => list.masterid === item.field).length !== 0 && showlistField.filter(list => list.masterid === item.field)[0].option.map((lab, index) =>
                                                                                <option
                                                                                    key={`multi-area-chart-masterid` + index}
                                                                                    value={lab}>{lab}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                    }
                                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                    showlistField.filter(list => list.masterid === item.field && (list.optionstatus && list.optionstatus === 'textoption') && (list.type === "switch" || list.type === "dropdown" || list.type === "checkbox"))[0] &&
                                                                    <div className="select-box">
                                                                        <select id="yaxis" name="conditions"
                                                                                onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "conditions")}
                                                                                value={item.conditions}>
                                                                            <option value="">Conditions</option>
                                                                            {showlistField.filter(list => list.masterid === item.field).length !== 0 && showlistField.filter(list => list.masterid === item.field)[0].option.map((lab, index) =>
                                                                                <option
                                                                                    key={`multi-area-chart-masterid` + index}
                                                                                    value={lab.hasOwnProperty('text') ? lab.text : lab}>{lab.hasOwnProperty('text') ? lab.text : lab}</option>
                                                                            )}</select>
                                                                    </div>
                                                                    }

                                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                    showlistField.filter(list => list.masterid === item.field && list.type === "number")[0] &&
                                                                    <>
                                                                        <input type={`text`} name="min"
                                                                               defaultValue={item.min}
                                                                               placeholder={`min`}
                                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "number")}
                                                                        />
                                                                        <input type={`text`} name="max"
                                                                               placeholder={`max`}
                                                                               defaultValue={item.max}
                                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "number")}
                                                                        />
                                                                    </>
                                                                    }

                                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                    showlistField.filter(list => list.masterid === item.field && list.type === "datepicker")[0] &&
                                                                    <>
                                                                        <input type={`date`} name="from"
                                                                               defaultValue={item.from}
                                                                               placeholder={`from`}
                                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "datepicker")}
                                                                        />
                                                                        <input type={`date`} name="to"
                                                                               defaultValue={item.to}
                                                                               placeholder={`to`}
                                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "datepicker")}
                                                                        />
                                                                    </>
                                                                    }
                                                                    {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                    showlistField.filter(list => list.masterid === item.field && list.type === "text")[0] &&
                                                                    <>
                                                                        <input type={`text`} name="text"
                                                                               defaultValue={item.text}
                                                                               placeholder={`text`}
                                                                               onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "text")}
                                                                        />

                                                                    </>
                                                                    }


                                                                </div>
                                                                <div className={`settings-panel-option`}>
                                                                    {index + 1 === whereCondition.length &&
                                                                    <button className="ico-lib divider-add-button"
                                                                            onClick={() => this.props.addData(item.id, "whereCondition")}>

                                                                    </button>
                                                                    }

                                                                    {whereCondition.length !== 1 &&
                                                                    <button className="ico-lib divider-sub-button"
                                                                            onClick={() => this.props.removeData(item.id, "whereCondition")}>

                                                                    </button>
                                                                    }
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="each-inp-field">
                                                        <label>Limit</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Limit" id="limit"
                                                                   name="limit"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.limit}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>

                                                    </div>
                                                </>
                                                }

                                                {this.props.containerData.hasOwnProperty("tablelimit") &&
                                                <div className="each-inp-field">
                                                    <label>Limit</label>
                                                    <div className="new-task-input">
                                                        <input type="text" placeholder="Table Limit" id="limit"
                                                               name="tablelimit"
                                                               autoComplete="off"
                                                               value={this.props.containerData.tablelimit}
                                                               onChange={(e) => this.props.handleChange(e)}/>
                                                    </div>

                                                </div>
                                                }

                                            </div>
                                        </div>
                                        <div className="each-settings-panel-subheader">
                                            <h5>Sorting</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="task-input-container">

                                                {this.props.containerData.hasOwnProperty("condition") &&
                                                <>


                                                    <div className="each-inp-field eq-width-line">
                                                        <label>Sort By</label>
                                                        <div className="select-box eq-width">
                                                            <select id="multi-area-chart-collections" name="field"
                                                                    onChange={(e) => this.props.handleConditionChange(e)}
                                                                    value={condition && condition[0].field + "|" + (condition[0].type === "date" ? "datepicker" : condition[0].type)}>

                                                                <option value="|">Sort By</option>
                                                                {showlistField.filter(item => (item.type === "number" || item.type === "datepicker")).map((label, index) =>
                                                                    <option key={`multi-area-chart-masterid` + index}
                                                                            value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="each-inp-field eq-width-line">
                                                        <label>Sort Type</label>
                                                        <div className="select-box eq-width">
                                                            <select id="multi-area-chart-collections" name="sort"
                                                                    onChange={(e) => this.props.handleConditionChange(e)}
                                                                    value={condition && condition[0].sort}>

                                                                <option value="">ASC/DESC</option>
                                                                <option value="asc">ASC</option>
                                                                <option value="desc">DESC</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                </>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="chart-settings-splitter">
                                    <div className="chart-settings-splitter-header">
                                        <h5>Display Properties</h5>
                                    </div>
                                    {this.props.containerData.hasOwnProperty("chartTitle") &&
                                    <div className="each-settings-panel-wrap">
                                        <div class="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Chart Details</h5>
                                        </div>
                                        <div class="each-settings-panel-wrap-body">
                                            {this.props.containerData.hasOwnProperty("chartTitle") &&
                                            <div className="each-inp-field">
                                                <label>Chart Title</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="title" id="chartTitle"
                                                           name="chartTitle"
                                                           autoComplete="off"
                                                           value={this.props.containerData.chartTitle}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("subTitle") &&
                                            <div className="each-inp-field">
                                                <label>Subtitle</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="subtitle" id="subTitle"
                                                           name="subTitle"
                                                           autoComplete="off" value={this.props.containerData.subTitle}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("subTitleEnabled") &&
                                            <div className="each-inp-field">
                                                <div className="select-chk-block" style={{"display": "block"}}>
                                                    <label className="control control-checkbox">Enable Subtitle
                                                        <input className="inp-chk" type={`checkbox`}
                                                               name={`subTitleEnabled`}
                                                               checked={this.props.containerData.subTitleEnabled}
                                                               value={this.props.containerData.subTitleEnabled}
                                                               onClick={(e) => this.props.checkBoxchange(e)}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    }


                                    <div className="each-settings-panel-wrap ">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Faq Details</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="task-input-container select-line">
                                                <div className="each-inp-field">
                                                    <label>Title</label>
                                                    <div className="select-box eq-width">
                                                        <select id="faqTitle" name="faqTitle"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.faqTitle}>
                                                            <option value="">Please Select</option>
                                                            {showlistField.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Title Colour</label>
                                                    <ColorPicker
                                                        stroke={"titlecolor"}
                                                        color={this.props.containerData.titlecolor}
                                                        onChangeComplete={this.props.handleChangeComplete}
                                                        handleClickStaus={this.props.handleClick}
                                                    />
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Description</label>
                                                    <div className="select-box eq-width">
                                                        <select id="faqDesc" name="faqDesc"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.faqDesc}>
                                                            <option value="">Please Select</option>
                                                            {showlistField.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Desc Colour</label>
                                                    <ColorPicker
                                                        stroke={"desccolor"}
                                                        color={this.props.containerData.desccolor}
                                                        onChangeComplete={this.props.handleChangeComplete}
                                                        handleClickStaus={this.props.handleClick}
                                                    />
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Image</label>
                                                    <div className="select-box eq-width">
                                                        <select id="faqImage" name="faqImage"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.faqImage}>
                                                            <option value="">Please Select</option>
                                                            {showlistField.map((label, index) =>
                                                                <option
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                        {getUrlSegment(1) !== "entity" ?
                            <div class="modal-footer">
                                <button type="button" class="general-btn grey btn btn-primary"
                                        onClick={() => this.props.cancelAction()}>Cancel
                                </button>
                                <button type="submit" class="general-btn btn btn-primary"
                                        onClick={() => this.props.OKAction()}>Apply
                                </button>
                            </div>
                            :
                            <div className="modal-footer">
                                <button type="button" className="general-btn grey btn btn-primary"
                                        onClick={() => this.props.cancelAction()}>Ok
                                </button>

                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }

}


export default FaqSettingsModal
