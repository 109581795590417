import React, {Component} from 'react';
import {ES_INDEX, USRIMG_PATH} from "../../../constants";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {Datecomparison, Datetimecomparison, getUrlSegment} from "../../../utilities/CustomFunctions";
import * as commonActions from "../../../actions/Common";
import rootActions from "../../../actions";
import NoData from "../../views/NoData";
import Modal from "../../views/Modal";
import AddToDoTask from "../AddToDoTask";
import {getProfile} from "../../../utilities/AuthService";
import * as taskActions from "../../../actions/ToDo";
import LoaderSvg from "../../views/LoaderSVG";
import SimpleModal from "../../views/SimpleModal"
import ProjectHeader from "../../views/ProjectHeader";
import ProjectSection from "../../views/ProjectSection";
import {Accordion, Button, ButtonToolbar, Card} from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ProjectTaskCard from "../../views/ProjectTaskCard";
import OutsideAlerter from "../../views/OutsideAlerter";
import AddNewMilestone from "./AddNewMilestone";
import AlertModal from "../../views/AlertModal";
const grid = 8;
const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? "#333333" : "lightgrey",

});

class ProjectTasks extends Component {

    constructor(props) {
        super(props);

        this.state={
            addOptions: false,
            showAddNewTaskModal: false,
            showAddNewSectionModal: false,
            projectList: [],
            sectionName: '',
            loading: 0,
            sectionId: 0,
            milestoneId: '',
            selectedMilestone: 0,
            projectId: 0,
            projectasks: [],
            projectsections: [],
            secId: 0,
            mouseover: 0,
            child:'',
            NewMilestoneModal:false,
            milestoneAction:'',
            showlistOptions:false,
            Delmilestone:false,
            DelSection:false

        }
        this.showAddOptions = this.showAddOptions.bind(this);
        this.showAddNewTaskModal = this.showAddNewTaskModal.bind(this);
        this.showAddNewSectionModal = this.showAddNewSectionModal.bind(this);
        this.closeAddNewTaskModal = this.closeAddNewTaskModal.bind(this);
        this.closeAddSectionTaskModal = this.closeAddSectionTaskModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.addSection = this.addSection.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
      //  this.closeMilestoneModal = this.closeMilestoneModal.bind(this);
        this.resetState = this.resetState.bind(this);
        this.onmouseover = this.onmouseover.bind(this);
       // this.showMilestonekModal = this.showMilestonekModal.bind(this);
        this.expandHeader = this.expandHeader.bind(this)
        this.showCreateNewMilestoneModal = this.showCreateNewMilestoneModal.bind(this);
        this.AddmilestonemodalClose = this.AddmilestonemodalClose.bind(this);
        this.showOptions = this.showOptions.bind(this);
        this.deleteMilestone = this.deleteMilestone.bind(this);
        this.confirmMilestoneDelete = this.confirmMilestoneDelete.bind(this);
        this.cancelMilestonemodal=this.cancelMilestonemodal.bind(this);
        this.showdeleteSectionModal =this.showdeleteSectionModal.bind(this);
        this.confirmSectionDelete =this.confirmSectionDelete.bind(this);
    }


    componentDidMount() {
        var projectId = getUrlSegment(5);
        this.props.mainClick('project', '/project/details/tasks/'+projectId);

        var projectid = atob(projectId);
        this.setState({projectId:projectid});
        var milestoneid=atob(getUrlSegment(4));
        var params={
            projectid: projectid,
            milestoneid : milestoneid
        }
        this.props.getmilestonesSections(params)


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.setState({loading: 1});
        var beta = this;
        var projectid = (atob(getUrlSegment(5)));
        var milestoneid=atob(getUrlSegment(4));
        var beta = this;
        if(prevState.projectId !== projectid) {
            this.setState({projectId: projectid});
        }

        if(prevProps.savesectiondata.saveSection !== this.props.savesectiondata.saveSection ) {
            var params={
                projectid: projectid,
                milestoneid : milestoneid
            }
            setTimeout(function () {
                beta.props.getmilestonesSections(params)
                beta.setState({loading:0});
                beta.closeAddSectionTaskModal();
            }, 1000)

        }



        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            var params={
                projectid: projectid,
                milestoneid : milestoneid
            }
            setTimeout(function () {
                beta.props.getmilestonesSections(params);
                beta.setState({loading: 0});
                beta.showAddNewTaskModal()
            }, 1000)
        }

        if(prevProps.listmilestonesections.milestoneSections != this.props.listmilestonesections.milestoneSections) {
          //  console.log(this.props.listAllProjectDetailData.projectDetails);
            this.setState({projectsections : this.props.listmilestonesections.milestoneSections.section || []});
        }
        if (prevProps.resultMilestone.milestoneDeleted !== this.props.resultMilestone.milestoneDeleted ) {
            var projectId = getUrlSegment(5);
            setTimeout(function () {
                beta.props.history.push('/project/details/management/'+projectId)
            }, 1000)
        }
        if(prevProps.milestoneData.milestonesaved!== this.props.milestoneData.milestonesaved) {
            var params={
                projectid: projectid,
                milestoneid : milestoneid
            }
            setTimeout(function () {
                beta.props.getmilestonesSections(params);
                beta.AddmilestonemodalClose();
                beta.setState({loading:0});
            }, 1000)

        }
        if(prevProps.sectionDeletedata.sectionDeleted !== this.props.sectionDeletedata.sectionDeleted){
            var sparams={
                projectid: projectid,
                milestoneid : milestoneid
            }
            setTimeout(function () {
                beta.props.getmilestonesSections(sparams);
                beta.setState({loading:0,DelSection:false});
            }, 1000)
        }

    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }

    showAddOptions(){
        this.setState({addOptions :!this.state.addOptions})
    }

    onmouseover(e, secId) {
        this.setState({mouseover: secId})
    }

    showAddNewTaskModal(e, secId){
        this.setState({
            showAddNewTaskModal : !this.state.showAddNewTaskModal,
            projectList:this.props.projects.projectData,
            tagList :this.props.projects.tagData,
            secId: secId

        })
    }


    showAddNewSectionModal(e, id){
        if(id !== 0){
            this.setState({loading: 1});
            let editedsection = this.props.listmilestonesections.milestoneSections.section.filter(item => item.sectionid == id);
            if(editedsection.length > 0){
                editedsection = editedsection[0];
                this.setState({loading: 0,sectionName: editedsection.section});
            }
        }
        this.setState({showAddNewSectionModal : !this.state.showAddNewSectionModal,sectionId: id, addOptions: false, loading: 0});
    }

    closeAddNewTaskModal(){
        this.setState({showAddNewTaskModal :false,addOptions: false});

    }

    closeAddSectionTaskModal(){
        this.setState({showAddNewSectionModal : false});
        this.resetState();

    }

    handleInputChange(e){
        this.setState( {[e.target.name] : e.target.value});
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }

    addSection(){

        let formValid = 0;
        let mode = this.state.sectionId === 0 ? "save" : "update";
        if (this.state.sectionName === '') {
            formValid++;
            document.getElementsByName('sectionName')[0].classList.add('val-error');
        }

        if(formValid === 0){
            this.setState({loading: 1});
            var projectid = (atob(getUrlSegment(5)));
            var milestoneid=atob(getUrlSegment(4));
                let sectiondata = {
                    "projectid": projectid,
                    "section": this.state.sectionName,
                    "mode": this.state.sectionId === 0 ? "save" : "update",
                    "sectionid": this.state.sectionId,
                    "milestoneid":milestoneid

                }
                this.props.saveSection(sectiondata)

        }

    }

    onDragEnd = (result) => {

        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if(source.droppableId !== destination.droppableId) {
            let task = {};
            let taskId = result.draggableId;
            let secId = destination.droppableId;

            var index = this.state.projectsections.findIndex(itm => itm.sectionid === source.droppableId);
            var taskIndex = this.state.projectsections[index].task.findIndex(itm => itm.masterid === taskId);
            task = this.state.projectsections[index].task[taskIndex];
            this.state.projectsections[index].task.splice(taskIndex, 1);

            var indexs = this.state.projectsections.findIndex( itm => itm.sectionid === secId );
            this.state.projectsections[indexs].task.push(task);
            var params={
                "tid": taskId,
                "project_secid": secId,
            }
            this.props.updateTaskSection(params);

        }
    }

    showCreateNewMilestoneModal(e,action){
        e.preventDefault()
        var milestoneid=atob(getUrlSegment(4));
        if(action === 'update') {
            var data={
                "_id": milestoneid,
            }
            this.props.getmilesonedetails(data);
        }

        this.setState({NewMilestoneModal:!this.state.NewMilestoneModal,
            milestoneAction:action,
        })

    }
    AddmilestonemodalClose(){
        this.setState({NewMilestoneModal:false})
    }
    showOptions(e, id){
        this.setState({showlistOptions: !this.state.showlistOptions})

    }
    deleteMilestone(milestoneid)
    {
        this.setState({milestoneID: milestoneid, Delmilestone: true });
    }
    confirmMilestoneDelete(){
        this.setState({loading: 1});
        var milestoneid=atob(getUrlSegment(4));
        let projectID =  (atob(getUrlSegment(5)));
        let params = {projectid : projectID,milestone_id : milestoneid}
        this.props.deleteProjectmilestone(params);

    }
    cancelMilestonemodal(){
        this.setState({Delmilestone: false});
    }
    resetState(){
            this.setState({ sectionName: ''})
    }

    showdeleteSectionModal(id){
        this.setState({sectionId:id,DelSection: true});
    }
    cancelSectionModal(){
        this.setState({DelSection: false});
    }
    confirmSectionDelete(){
        this.props.deleteSection();
    }
    render() {
        let milestone_id = '';
        let projectsections = this.state.projectsections || [];
        let users = this.props.listUsers.Users.data;
        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>
                {(this.props.listmilestonesections.loading || this.state.loading  === 1)&&
                <span className="Loader-holder">

                    <LoaderSvg/>
                </span>
                }
                <div className="ibt-body top-bot-pad">

                    <div id="accordion" className="task-tab project-cycle-tab">
                        <ProjectSection projectsections={projectsections}
                                        showAddNewSectionModal={this.showAddNewSectionModal}
                                         history={this.props.history}
                                        showAddNewTaskModal={this.showAddNewTaskModal}
                                        showCreateNewMilestoneModal={this.showCreateNewMilestoneModal}
                                        onmouseover={this.onmouseover}
                                        milestonename={this.props.listmilestonesections.milestoneSections.milestone}
                                        createuser = {this.props.listmilestonesections.milestoneSections.milestonecrtuser}
                                        taskcnt={this.props.listmilestonesections.milestoneSections.taskcnt}
                                        onDragEnd={this.onDragEnd}
                                        showOptions={this.showOptions}
                                        showlistOptions={this.state.showlistOptions}
                                        deleteMilestone={this.deleteMilestone}
                                        showdeleteSectionModal={this.showdeleteSectionModal}
                        />
                    </div>
                    <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Task">
                        <button className="float-button ico-lib float-add" onClick={()=>this.showAddOptions()}></button>
                        {this.state.addOptions === true &&

                        <OutsideAlerter status={this.state.addOptions} Triggered={() => this.showAddOptions()}>
                        <div className="add-users__list--pop-up milestone" id="added-user-popup" style={{display: "block"}}>
                            <ul>
                                {/*<li>*/}
                                {/*    <a className="milestone-plus-new ico-lib ico-lib-new-task" onClick={(e)=>this.showAddNewTaskModal(e, 0)}>New Task</a>*/}
                                {/*</li>*/}
                                <li>
                                    <a className="milestone-plus-new ico-lib ico-lib-new-sec" onClick={(e)=>this.showAddNewSectionModal(e, 0,milestone_id)}>New Task Group</a>
                                </li>

                            </ul>
                        </div>
                        </OutsideAlerter>
                        }
                    </div>
                {this.state.showAddNewTaskModal &&
                <Modal title={'Create New Task'} Close ={this.closeAddNewTaskModal.bind(this)}>
                    <AddToDoTask
                        projectList={this.state.projectList}
                        tagList={this.state.tagList}
                        listtags={this.props.listtags.tags}
                        projectId={this.state.projectId}
                        modalOpen={this.closeAddNewTaskModal}
                        secId={this.state.secId}
                    />

                </Modal>
                }
                {this.state.showAddNewSectionModal &&

                <SimpleModal
                    Close={this.closeAddSectionTaskModal}
                    title={`${this.state.sectionId === 0 ? "Create Task Group" : "Edit Task Group"}`}>
                    {this.state.loading === 1 &&
                    <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                    }
                    <div className="modal-milestone">
                        <div className="milestone-subsection">
                            <div className="input-block">
                                <input type="text" id="sectionname" className="fiels-inp-box" name="sectionName"
                                       placeholder="Enter Task group name" value={this.state.sectionName} onChange={(e) => this.handleInputChange(e)}/>

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer  checklist-footer">
                        <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e)=> this.closeAddSectionTaskModal()}>Cancel</button>
                        <button type="button" className="general-btn" id="fetchCollection" onClick={(e)=> this.addSection(e)}>{this.state.sectionId === 0 ? "Add" : "Update"}</button>
                    </div>
                </SimpleModal>

                }
                {this.state.NewMilestoneModal &&
                <Modal title={this.state.milestoneAction === "create" ? 'Create New Milestone' : 'Update Milestone'} Close ={this.AddmilestonemodalClose}>
                    <AddNewMilestone
                        AddmilestonemodalClose = {this.AddmilestonemodalClose}
                        action={this.state.milestoneAction === "create"? "create":"update"}
                        users={users}
                    />

                </Modal>
                }
                    {this.state.Delmilestone == true &&
                    <AlertModal id="take-pool"
                                title="Are you sure want to delete?"
                                OK_button_text="Yes, Delete It"
                                OK_action={() => this.confirmMilestoneDelete()}
                                showModal = {this.state.Delmilestone}
                                CANCEL_action={() => this.cancelMilestonemodal()}
                    />
                    }
                    {this.state.DelSection == true &&
                    <AlertModal id="delete-section"
                                title="Are you sure want to delete?"
                                OK_button_text="Yes, Delete It"
                                OK_action={() => this.confirmSectionDelete()}
                                showModal = {this.state.DelSection}
                                CANCEL_action={() => this.cancelSectionModal()}
                    />
                    }

            </div>

            </div>



        );
    }
}
const mapStateToProps = state => ({
    listAllProjectDetailData:state.ProjectReducer.listallProjectsdetails,
    projectsectionsdata:state.ProjectReducer.GetProjectSections,
    projects:state.TaskReducer.GetProjectData,
    listtags:state.TaskReducer.ListAllTags,
    savesectiondata:state.ProjectReducer.SaveSection,
    TaskSectionUpdated:state.ProjectReducer.UpdateTaskSection,
    milestoneData:state.ProjectReducer.SaveMilestone,
    sectiondetails : state.ProjectReducer.GetSection,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    listmilestonesections : state.ProjectReducer.GetMilestoneSections,
    listAllPhaseMilestoneData:state.PhaseReducer.getPhasemilestoneDetails,
    resultMilestone:state.ProjectReducer.DeleteMilestone,
    listUsers:state.UsersReducer.listUsersdata,
    sectionDeletedata:state.ProjectReducer.DeleteSections

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    projectdetails:projectActions.listallprojectsdetails,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    saveSection : projectActions.saveSection,
    updateTaskSection : projectActions.updateTaskSection,
    getmilestonelist : projectActions.milestoneList,
    getsectiondetails : projectActions.getSection,
    taskHelper : projectActions.taskHelper,
    getProjectSections : projectActions.getProjectSections,
    getmilestonesSections : projectActions.milestoneSections,
    getmilesonedetails:projectActions.getMilestoneInfo,
    deleteProjectmilestone:projectActions.DeleteProjectMilestone,
    deleteSection:projectActions.deleteSection
})(ProjectTasks));
