import axios from 'axios'
import {ES_INDEX, API_PATH} from '../constants/index'
import {commonService} from "./common.services";


export const forms = {
    listForms,
    GetForms,
	GetCollectionInfo,
    cForm,saveCreateJson
};

function listForms(group,search,uid,usertype,managecollection,limit,offset,has_form,from_type){
    // return axios.post(API_PATH + 'collection/collectionsubgroup',{
    //     "group": group,
    //     "search": search,
    //     "uid": uid,
    //     "usertype": usertype,
    //     "managecollection": managecollection,
    //     "limit": limit,
    //     "offset": offset,
    //     "index": ES_INDEX
    // })

    let params={
        "group": group,
        "search": search,
        "uid": uid,
        "usertype": usertype,
        "managecollection": managecollection,
        "limit": limit,
        "offset": offset,
		"has_form" : has_form,
		"from_type" : from_type
    }

    return commonService.sendHttpRequest('collection/collectionsubgroup', params, "POST");




}
function GetCollectionInfo(params) {
    return commonService.sendHttpRequest('collection/collectioninfo', params, "POST");
}
async function GetForms(uid, usertype,type){
    // return await axios.post(API_PATH + 'collection/getusercategory',{
    //     "uid": uid,
    //     "usertype": usertype,
    //     "index": ES_INDEX
    // })
    let params={
        "uid": uid,
        "usertype": usertype,
        "type":type
    }

    return commonService.sendHttpRequest('collection/getusercategory', params, "POST");

}

function cForm(params) {
    return commonService.sendHttpRequest('collection/form', params, "GET");
}

function saveCreateJson(params) {
    return commonService.sendHttpRequest('collection/buildform', params, "POST");
}

