import React, {Component} from 'react';

import noData from "../pages/no-data-img.png";


class NoData extends Component {

    constructor(props) {
        super(props);
      }



    render() {


         return (


                  <div className='center-box'>
                      <img className='tile-image' src={noData} />
                         {this.props.msg}
                  </div>


        );
    }
}




export default NoData;


