import React, {Component} from 'react';
import Resources from "../../pages/resources/Resources";
import {FRMSDOC_PATH, FRMSIMG_PATH, FRMSPDFDOC_PATH, FRMSVID_PATH} from "../../../constants";
import {Button} from "react-bootstrap";
import {forceDownload, getFilePath, isAudio, isImage, isVideo, checkUrl, getUrl, isDocument} from "../../../utilities/CustomFunctions";
import ResourceAttachments from "./ResourceAttachments";
import SimpleModal from "../SimpleModal";
import VideoPlayer from "../player/VideoPlayer";
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'

class ResourcesFromBrowse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openResource : true,
            previewStatus:false,
            selectedResources : this.props.attributes.value ? this.props.attributes.value : [],
            selectedIds : [],
            selectedFormResources: [],
            ImgName:'',
            Title:'',
            res_type:'0',
            Res_id:'',
            ImgPath:'',
            creator:''
        }
    }
    componentDidMount() {
        let attr = this.props.attributes;
        let selectedResources = attr.value ? attr.value : [];
        this.setState({selectedResources: selectedResources})
    }

    selectResources=(selectedResources, selectedIds, selectedFormResources)=>{
        // console.log(selectedFormResources)
        this.props.selectedResourceItems(selectedFormResources)
        this.props.handleResourceChange(selectedFormResources)
        this.setState({
            selectedResources: selectedResources,
            selectedIds: selectedIds,
            selectedFormResources: selectedFormResources,
            openResource: false
        })

    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(getFilePath(type)) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'video' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }
    preview=(Resource_id,thumb,title,res_type,Imgname,creator)=> {
        this.setState({
            ImgName:thumb,
            Title:title,
            res_type:res_type,
            Res_id:Resource_id,
            creator :creator,
            previewStatus: !this.state.previewStatus
        })
        if(res_type === '1'){
            this.setState({ImgPath:FRMSIMG_PATH})
        } else if (res_type === '3'){
            this.setState({ImgPath:FRMSDOC_PATH})
        } else if(res_type === '2'){
            this.setState({ImgPath:FRMSVID_PATH})
        }else if(res_type === '4'){
            this.setState({ImgPath:FRMSDOC_PATH})
        }else if(res_type === '5'){
            // this.setState({ImgPath:FRMSDOC_PATH})
        }

    }
    closePreview=()=>{
        this.setState({previewStatus: false,
            ImgName:'',
            Title:'',
            res_type:'',
            Res_id:'',
            ImgPath:'',
            creator:''})
    }

    render() {
        let attr = this.props.attributes;
        // let selectedResources = this.state.selectedResources.length > 0 ? this.state.selectedResources : (attr.value !== undefined && attr.value.length > 0 ? attr.value : []);
        let selectedFormResources =  attr.value !== undefined && attr.value.length > 0 ? attr.value : [];
        if(this.props.editId && this.props.editId !== '') {
            selectedFormResources =  this.state.selectedFormResources.length > 0 ? this.state.selectedFormResources :  attr.value !== undefined && attr.value.length > 0 ? attr.value : [];
        }

        return (
            <>

                {/*{this.props.browseType === 'browse' && selectedFormResources.length !== 0 &&*/}
                {/*<div class="form-resource-preview">*/}
                {/*    <div className="form-resource-block">*/}
                {/*        {selectedFormResources.map((list, index) =>*/}
                {/*            <ResourceAttachments*/}
                {/*                item = {list}*/}
                {/*                index={index}*/}
                {/*                preview={this.preview}*/}
                {/*            />*/}


                {/*        )}*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*}*/}

                {this.state.openResource &&
                <div className={`cs-modal fade bsy-modal white-modal full-modal-res in show `}>
                    <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="task-head-text">Resources</h5>
                                <div className="modal-header-options">
                                    <button className="modal-option-button"  onClick={this.props.browseResourceClose}>
                                        <span className="icon-in icon-close-in"></span>
                                    </button>
                                </div>
                            </div>

                            <div className="modal-body">
                                <Resources
                                    // handleInputOtpChange={this.props.handleInputOtpChange}
                                    formsEnable = {true}
                                    selectResources = {this.selectResources}
                                    resourcespermission = {attr.resourcespermission}
                                    // selectedResources = {selectedResources}
                                    selectedIds = {this.state.selectedIds}
                                    selectedFormResources = {selectedFormResources}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                }

                {this.state.previewStatus &&
                <div className="modal fade bsy-modal x-large fl-pv show" id="toModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" aria-hidden="true" style={{display:'block'}} >
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.Title}</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closePreview}></button>
                            </div>
                            <div className="modal-body">
                                <div className="preview-area chat-panel">
                                    {this.state.res_type === '1' &&
                                    <img src={this.state.ImgPath + this.state.ImgName}/>
                                    }
                                    {this.state.res_type === '2' &&
                                    <React.Fragment>
                                        <VideoPlayer
                                            key={this.state.ImgPath + this.state.ImgName}
                                            display={'block'}
                                            width="100%"
                                            height="100%"
                                            src={this.state.ImgPath + this.state.ImgName}
                                        />
                                    </React.Fragment>
                                    }
                                    {this.state.res_type === '3'  &&
                                    <iframe src={FRMSPDFDOC_PATH + this.state.ImgName + '&embedded=true'} width="100%" height="250px"/>
                                    }
                                    {this.state.res_type === '4'  &&
                                    <audio  width="100%" height="100%" controls controlsList="nodownload" key={this.state.ImgPath + this.state.ImgName}>
                                        <source type="audio/mpeg" src={this.state.ImgPath + this.state.ImgName}></source>
                                    </audio >
                                    }
                                    {this.state.res_type === '5' &&
                                    <React.Fragment>
                                        <VideoPlayer
                                            key={this.state.ImgPath + this.state.ImgName}
                                            display={'block'}
                                            width="100%"
                                            height="100%"
                                            src={this.state.ImgPath + this.state.ImgName}
                                            resType='5'
                                            fileName={this.state.ImgName}
                                        />
                                    </React.Fragment>
                                    }
                                    {this.state.res_type === '6'  &&
                                    <iframe width="500" height="250" src={this.state.ImgName} alt="Thumb-img"/>
                                    }
                                    <div className="preview-options">
                                        <ul className="resources-filters-icons">
                                            {this.state.res_type !== 5 &&
                                            <li>
                                                <a className="rs-tab-btn ico-lib-v1 rc-download"
                                                   onClick={(e)=>{forceDownload(this.state.ImgPath + this.state.ImgName, this.state.Title)}}></a>
                                            </li>
                                            }
                                            {this.props.creator === getProfile().id &&
                                            <li><a className="rs-tab-btn ico-lib-v1 rc-delete"
                                                   onClick={(e) => this.props.deleteResource(e)}></a></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }

            </>
        )

    }
}

export default ResourcesFromBrowse;
