import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../actions/ToDo";
import {withRouter} from 'react-router';
import {getProfile} from "../../utilities/AuthService";
import configureStore from "../../configureStore";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import AlertBar from "./task/AlertBar"


class FilterRightBlock extends Component {

    constructor(props) {
        super(props);
        this.state={
            showCheckBox:false,
            allChecked : false,
            disabled_class : 'btn-disable'
        }
        this.moveToArchive = this.moveToArchive.bind(this);

    }

    selectAllTasks(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({allChecked : !this.state.allChecked},
            function(){
                this.props.selectAllTasks(this.state.allChecked);

            });

    }
    showSelect() {
        this.setState({showCheckBox : !this.state.showCheckBox},
            function(){
                this.props.setTaskSelection({status : this.state.showCheckBox? 'yes' :'no'});
            });

    }

    moveToArchive(action) {
        if(action === 'archive'){

            var param = 'unarchive'

        }else{
            var param = 'archive'
        }
        ////////////console.log(this.props.taskSelect);
        if(this.props.taskSelect.selectedTaskIds.length >0 ) {
            var data = {param:param,uid: getProfile().id,_id: this.props.taskSelect.selectedTaskIds.join(',')}
            this.props.moveTasksToArchive(data);
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.taskSelect !== prevProps.taskSelect) {
            if(this.state.allChecked && this.props.taskSelect.selectedTaskIds.length === this.props.taskCount ) {
                this.setState({disabled_class : ''});
            }
            else if(!this.state.allChecked && this.props.taskSelect.selectedTaskIds.length >0 ) {
                this.setState({disabled_class : ''});
            }
            else if(!this.state.allChecked && this.props.taskSelect.selectedTaskIds.length ===0 ) {
                this.setState({disabled_class : ' btn-disable '});
            }
        }
        if(this.props.taskSelect.tasksArchived !== prevProps.taskSelect.tasksArchived) {
            //window.location.reload()
        }
    }


    render() {
        let page = getUrlSegment(2);
        //////////////console.log(page)
        return(
            <>

                {this.props.taskSelect.taskSelection === 'yes' &&
                <ul className="filter-left-wrapper" data-action="organise" id="actionBar">
                    <li>
                        <div className="checkbox-st" onClick={(e) => this.selectAllTasks(e)}>
                            <label className="control control-checkbox">Select All
                                <input type="checkbox" className="inp-chk" id="todo-select-all" checked={this.state.allChecked}/>
                                <div className="control-indicator"></div>
                            </label>
                        </div>
                    </li>
                    <li>
                        <button className={"filter-button ico-lib fil-archv-ico " + this.state.disabled_class} id="_archive" title="Archive" onClick={()=>this.moveToArchive(page)}>{getUrlSegment(2) === 'archive' ? "Move to Restore" :"Move to Archive"}
                        </button>
                    </li>

                </ul>
                }
                <div className='filter-right-block'>

                    {this.props.hasOwnProperty('history') &&
                    <AlertBar
                        history={this.props.history}

                    />}

                    {/*!this.state.showCheckBox &&
                    <div className="pagination-wrapper" id="taskpagination">
                        <div className="dropdown pag-drop">
                            <button className="dropdown-toggle" type="button" id="current-page" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">0-0 of 0
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton"></div>
                        </div>
                    </div>
                    */}
                    {(!this.props.taskSelect.taskSelection || this.props.taskSelect.taskSelection === 'no') &&
                    <button className='button-anchor' onClick={() => this.showSelect()}>
                        <span className='icon-in icon-settings button-settings-icon'></span>
                    </button>
                    }

                    {this.props.taskSelect.taskSelection === 'yes' &&
                    <button className="settings-button ico-lib settings-ico filter-close" data-toggle="tooltip"
                            data-placement="top" title="" data-target="#actionBar" id="oraganise-off"
                            data-original-title="" onClick={()=>this.showSelect()}></button>
                    }
                </div>

            </>
        );
    }
}

const mapStateToProps = state => ({
    taskSelect:state.TaskReducer.getTaskSelection,
    menuClickData: state.CommonReducer.menuClick,
});

export default  withRouter(connect(mapStateToProps, {
    setTaskSelection:taskActions.setTaskSelection,
    selectTasksToMove : taskActions.selectTasksToMove,
    moveTasksToArchive : taskActions.moveTasksToArchive,
    selectAllTasks : taskActions.selectAllTasks
})(FilterRightBlock));
