import React, {Component} from 'react';
import {connect} from "react-redux";
import * as collectionActions from "../../../actions/Collections"
import {withRouter} from 'react-router';
import {getProfile} from "../../../utilities/AuthService";
import FormPanel from '../../views/FormPanel';
import Modal from "../../views/Modal"
import AddToDoTask from "../AddToDoTask"
import SimpleModal from "../../views/SimpleModal"
import AddNewForm from "../project/AddNewForm"
import AutoComplete from "../../views/AutoComplete";
import * as usersActions from "../../../actions/Users";
import LoaderSvg from "../../views/LoaderSVG";
import rootActions from "../../../actions";
import {debounce} from "throttle-debounce";

class Collections extends Component {

    constructor(props) {
        super(props);
        this.state = {
            optionOpened: 0,
            action: '',
            search: '',
            group: 'All',
            selectedCollection: {},
            selectedTags: [],
            selectedToUsers: [],
            tags: [],
            newTags: [],
            lastOffset: 0,
            totalitems: 0,
            collections: [],
            offset: 50,
            createNewForm: false,
            Collections: [],
            formLabel: 'Forms',

        }
        this.manageOptionsDropDown = this.manageOptionsDropDown.bind(this);
        this.updateAction = this.updateAction.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.shareCollection = this.shareCollection.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.showCreateNewFormModal = this.showCreateNewFormModal.bind(this);
        this.closeCreateNewFormModal = this.closeCreateNewFormModal.bind(this);
        this.listenToScroll = debounce(500, this.listenToScroll);
    }

    componentDidMount() {
        this.props.mainClick('collections', '');
        let params = {
            group: this.state.group,
            search: this.state.search,
            uid: getProfile().id,
            usertype: getProfile().usertype,
            managecollection: '',
            limit: 0,
            offset: this.state.offset,
            has_form: "yes"
        };
        this.props.collections(params);
        //this.props.users(getProfile().id,'User','',ES_INDEX);
        this.getUsers();
        this.props.getforms(getProfile().id, getProfile().usertype, "collection")

        const container = document.querySelector('.tile-main-panel');
        container.addEventListener('scroll', this.listenToScroll)

    }

    componentWillUnmount() {
        const container = document.querySelector('.tile-main-panel');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.tile-main-panel')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - (offsetHeight + 1);
        if (contentHeight <= scrollTop) {
            if (!this.props.listAllCollections.searchEnable) {
                let params = {
                    group: this.props.listAllCollections.Group || this.state.group,
                    search: this.state.search,
                    uid: getProfile().id,
                    usertype: getProfile().usertype,
                    managecollection: this.props.listAllCollections.managecollection,
                    limit: this.props.listAllCollections.ListAllCollections.data.length,
                    offset: this.state.offset,
                    has_form: "yes"
                };
                this.props.updatecollections(params);
            } else {
                let params = {
                    group: this.props.listAllCollections.Group || this.state.group,
                    search: this.props.listAllCollections.Search,
                    uid: getProfile().id,
                    usertype: getProfile().usertype,
                    managecollection: this.props.listAllCollections.managecollection,
                    limit: this.props.listAllCollections.searchResult.data.length,
                    offset: this.state.offset,
                    has_form: "yes"
                };
                this.props.moreSearchCollections(params)
            }

        }
    }

    updateAction(e, act, collection, id) {
        e.stopPropagation();
        this.setState({
            action: act,
            collectionId: id,
            optionOpened: 0,
            selectedCollection: [collection],
            selectedToUsers: []
        });
    }

    modalOpen() {
        this.setState({action: 'close'});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.sharedCollection.shared !== prevProps.sharedCollection.shared) {
            let params = {
                group: this.state.group,
                search: this.state.search,
                uid: getProfile().id,
                usertype: getProfile().usertype,
                managecollection: 0,
                limit: 0,
                offset: this.state.offset,
                has_form: "yes"
            };
            this.props.collections(params);
        }

        if (prevState.action !== this.state.action && this.state.action === "share") {

            this.props.getsharedSettingsData({_id: this.state.collectionId})
        }
        if (this.props.sharedCollectionData.collectionsettings !== prevProps.sharedCollectionData.collectionsettings) {

            let sharedData = this.props.sharedCollectionData.collectionsettings;
            if (sharedData) {
                this.setState({selectedToUsers: sharedData})
            }
        }

        if (prevProps.listAllCollections.ListAllCollections !== this.props.listAllCollections.ListAllCollections) {
            this.setState({
                Collections: this.props.listAllCollections.data,
                loading: 0
            });
            this.closeCreateNewFormModal();
        }


        if ((prevProps.listAllCollections.Search !== this.props.listAllCollections.Search && (this.props.listAllCollections.Search === '')) || (prevProps.listAllCollections.ListAllCollections.Collections !== this.props.listAllCollections.ListAllCollections.Collections)) {
            this.setState({
                Collections: this.props.listAllCollections.ListAllCollections.data,
                loading: 0
            });
        } else if ((prevProps.listAllCollections.Search !== this.props.listAllCollections.Search && (this.props.listAllCollections.Search !== '')) || (prevProps.listAllCollections.ListAllCollections.searchResult !== this.props.listAllCollections.ListAllCollections.searchResult)) {
            this.setState({
                Collections: this.props.listAllCollections.searchResult.data,
                loading: 0
            });

        }

    }

    handleInputChange = (e) => {
        this.props.searchType('SEARCH_COLLECTION_DATA', this.props.listAllCollections.Search, "collections", this.props.listAllCollections.Group, e.target.value, 0, 50)
        let label;
        if (e.target.value === 'sharebyme') {
            label = 'My Forms';
        } else if (e.target.value === 'sharewithme') {
            label = 'Shared ';
        } else if (e.target.value === 'managedbyme') {
            label = 'Managed';
        } else {
            label = 'Forms';
        }
        this.setState({
            formLabel: label
        });
    }

    getUsers(searchText) {
        var user_data = {
            uid: "0",
            search: searchText ? searchText : '',
            offset: 100,
            ignoreUsers: true,
            ignoreArrays: [],
            valueField: 'uniqueId'
        };
        this.props.users(user_data);
    }

    manageOptionsDropDown(e, id) {
        e.stopPropagation();
        this.setState({optionOpened: id});
    }

    resultDataList(result, reference) {
        this.setState({[reference]: result});
    }

    shareCollection() {
        let users = [];
        let cnt = 0;
        this.state.selectedToUsers.map(a => {
            let user = {"type": a.userType, "id": +a._id};
            users.push(user);
        });
        let sharedata = {
            "collectionid": this.state.collectionId,
            "uid": getProfile().id,
            "users": users
        }
        this.props.sharecollection(sharedata);
        this._interval = setInterval(() => {
            if (this.props.sharedCollection.shared === 1) {
                if (this.props.sharedCollection.msg === '') {
                    this.setState({action: ''});
                } else {
                    alert(this.props.sharedCollection.msg)
                }
                clearInterval(this._interval);
            }
        }, 500);

    }


    showCreateNewFormModal() {
        this.setState({createNewForm: !this.state.createNewForm})
    }

    closeCreateNewFormModal() {
        this.setState({createNewForm: false})
    }

    render() {
        let Collections = [];
        let total = ''
        if (!this.props.listAllCollections.searchEnable) {
            Collections = this.props.listAllCollections.ListAllCollections.data;
            total = this.props.listAllCollections.ListAllCollections.total;
        } else {
            Collections = this.props.listAllCollections.searchResult.data;
            total = this.props.listAllCollections.searchResult.total;
        }
        let Collectionlist = Collections.filter(list => (list.has_form === 'yes'));

        // // let entityAddPermission = getUserActionPermission(23, 1);
        // let listUsersPermission = this.props.listUsersPermission.UsersPermission;
        // // console.log(listUsersPermission)
        //
        // var colAddPermission = 'no';
        // var colEditPermission = 'no';
        // var colDeletePermission = 'no';
        // if (listUsersPermission.length > 0) {
        //     let perms = listUsersPermission.filter(per => per.module_id === 18);
        //     // console.log(perms)
        //     if (perms.length > 0) {
        //         let actions = perms[0].actions;
        //
        //         let modActonArr1 = actions.filter(ac => ac.action_id === 1 && ac.action_permission === 'yes'); // Add case
        //         console.log(modActonArr1);
        //         if (modActonArr1.length > 0) {
        //             colAddPermission = 'yes';
        //         }
        //         let modActonArr2 = actions.filter(ac => ac.action_id === 2 && ac.action_permission === 'yes'); // Add case
        //         console.log(modActonArr2);
        //         if (modActonArr2.length > 0) {
        //             colEditPermission = 'yes';
        //         }
        //         let modActonArr3 = actions.filter(ac => ac.action_id === 3 && ac.action_permission === 'yes'); // Add case
        //         console.log(modActonArr3);
        //         if (modActonArr3.length > 0) {
        //             colDeletePermission = 'yes';
        //         }
        //     }
        // }
        //
        // console.log(colAddPermission)
        // console.log(colEditPermission)
        // console.log(colDeletePermission)


        return (
            <React.Fragment>
                {getProfile().usertype.toLowerCase() === "user" &&
                <div className="inner-content-area">
                    <div className="filter-wrapper">
                        <div>
                            <h5 className="subhead">{this.state.formLabel} ({total})</h5>
                        </div>
                        <ul className="filter-left-wrapper" id="searchBar" data-action="filter">
                            <li>
                                <div className="select-box">
                                    <select id="c_status" onChange={(e) => this.handleInputChange(e)}>
                                        <option value="">Choose One</option>
                                        <option value="sharebyme">My Forms</option>
                                        <option value="sharewithme">Shared with me</option>
                                        {getProfile().overseeStatus === "1" &&
                                        <option value="managedbyme">Owned by Reportees</option>
                                        }
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                }
                <div
                    className={`tile-main-panel ${getProfile().usertype.toLowerCase() === 'user' ? 'with-filter' : ''}`}>
                    {this.props.loader.loaderType === 'collection-list' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                    }
                    <div className="tile-all-holder list-viewer">
                        {Collectionlist.map((list, index) =>
                            <FormPanel
                                key={`form-panel${index}`}
                                list={list}
                                history={this.props.history}
                                optionOpened={this.state.optionOpened}
                                manageOptionsDropDown={this.manageOptionsDropDown}
                                updateAction={this.updateAction}
                            />
                        )}
                    </div>
                    {/*<div className="float-button-block">*/}
                    {/*    <button className="float-button ico-lib float-add" data-toggle="modal" data-target="#addField" onClick={this.showCreateNewFormModal}> </button>*/}
                    {/*</div>*/}
                    {this.state.action === 'assign' &&
                    <Modal title={'Create New Task'} Close={this.modalOpen.bind(this)}>
                        <AddToDoTask
                            selectedCollections={this.state.selectedCollection}
                            modalOpen={this.modalOpen}
                        />
                    </Modal>
                    }
                    {this.state.action === 'share' &&
                    <SimpleModal
                        Close={this.modalOpen}
                        title={`Share Form`}
                        haveFooter={1}
                        OKLabel="Save"
                        OKAction={this.shareCollection}
                        cancelLabel="Cancel"
                        cancelAction={this.modalOpen}
                    >
                        {this.props.loader.loaderType === 'share-collection' && this.props.loader.status === true &&
                        <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                        }
                        <div className="task-tg">

                            <p className="remove-txt-gp">Assign to Individual, Team, Organization, etc (Ignore this for
                                self tasks)</p>
                            <div className="task-inp-container">

                                <AutoComplete
                                    fieldTitle='To'
                                    sourceDataList={this.props.listUsers.todoUsers}
                                    searchField='firstName'
                                    showIcon={true}
                                    showSubLabel={true}
                                    labelFields='firstName,lastName'
                                    valueField='uniqueId'
                                    subLabelField='designation'
                                    showProfile={false}
                                    reference='selectedToUsers'
                                    selectedItems={this.state.selectedToUsers}
                                    resultDataList={this.resultDataList.bind(this)}
                                    id="to_users"
                                    instantSearch={true}
                                    actionMethod={this.getUsers}
                                    preventInitialApiCall={true}
                                />

                            </div>

                        </div>

                    </SimpleModal>
                    }
                    {this.state.createNewForm &&
                    <Modal title={'Create New Form'} Close={this.showCreateNewFormModal.bind(this)}>
                        <AddNewForm/>
                    </Modal>

                    }
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => ({
    searchData: state.CommonReducer.searchparamdata,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    listUsers: state.UsersReducer.listUsersdata,
    haveLoader: state.CollectionsReducer.manageoader,
    sharedCollection: state.CollectionsReducer.sharedCollection,
    loader: state.CommonReducer.loader,
    HistoryPushData: state.TaskReducer.HistoryPushData,
    sharedCollectionData: state.CollectionsReducer.collectionSettingsData
});

export default withRouter(connect(mapStateToProps, {
    mainClick: rootActions.commonActions.MenuClick,
    searchType: rootActions.commonActions.searchType,
    collections: collectionActions.listallcollections,
    updatecollections: collectionActions.updatecollections,
    moreCollections: collectionActions.updatecollections,
    moreSearchCollections: collectionActions.updatesearchcollections,
    sharecollection: collectionActions.sharecollection,
    getforms: rootActions.commonActions.GetForms,
    users: usersActions.listTodoUsers,
    getsharedSettingsData: collectionActions.getCollectionSettings
})(Collections));


