import axios from 'axios'
import {commonService} from "./common.services";
import {ES_INDEX, API_PATH,SHARED_LOGIN} from '../constants/index'

export const entity = {
    listCollections,
    listCollectionDetail,
    selectedCollections,
    saveMetaform,
    showForm,
    deleteCollectionRecords,
    shareCollection,
    getExternalValues,
    getAggregate,
    saveForm,
    getFormData,
    listTodoCollections,
    getCollectionSettings,
    formFilter,
    searchEntity,
    externalSearchEntity,
    entitydetails,
    deleteEntity,
    duplicateEntity,
    downloadEntity,
    getCategory,
    createCollections,
    createEntity,
    createCategory,
    updatetodoCollection,
    exportField,
    saveExportFieldData,
    getcollectionconfig,
    savaDataView,
    savaDataSettings,
    singlerecorddAssign,
    saveFilter,
    saveRightSidePanel,
    importCollectionData,
    saveAssignData,
    saveShareData,
    savelinkingpermission,
    selectExport,
    exportall,
    startcronjob,
    startworkflow,
    entityCollections,
    collectionSaveTemplate,
    getRule,
    listingWorkflow,
    linkedWorkflow,
    deletelinkedWorkflow,
    createGenericRule,
    genericRuleDelted,
    saveWorkflowrule,
    importSystemCollectionData,
    starClick,
    listAnnotation,
    saveAnnotation,
    saveEnitityInvokeWorkflow
};
function startcronjob(params){
    return commonService.sendHttpRequest('collection/startcronjob', params, "GET");
}
function startworkflow(params){
    return commonService.sendHttpRequest('workflow/startworkflow', params, "GET");
}

function selectExport(params){
    return commonService.sendHttpRequest('/export/exportexcel', params, "POST");
}


function savaDataSettings(params){
    return commonService.sendHttpRequest('/collection/settingscollection', params, "POST");
}
function singlerecorddAssign(params){
    return commonService.sendHttpRequest('/collection/savedataaccessusers', params, "POST");
}

function saveAssignData(params){
    return commonService.sendHttpRequest('/collection/savedataaccessusers', params, "POST");
}
function saveShareData(params){
    return commonService.sendHttpRequest('/collection/sharecollection', params, "POST");
}
function saveFilter(params){
    return commonService.sendHttpRequest('collection/savefiltersettings', params, "POST");
}
function saveRightSidePanel(params){
    return commonService.sendHttpRequest('custom/savepanelsettings', params, "POST");
}
function savelinkingpermission(params){
    return commonService.sendHttpRequest('collection/save_linkedpermission', params, "POST");
}
function savaDataView(params){
    return commonService.sendHttpRequest('collection/savedataview_permission', params, "POST");
}
function importCollectionData(params){
    return commonService.sendHttpRequest('collection/importcsv', params, "POST");
}
function collectionSaveTemplate(collectionId,params){
    return commonService.sendHttpRequest('collection/savetemplate_view/'+collectionId, params, "POST");
}


function listCollections(params){
    return commonService.sendHttpRequest('collection/collectionsubgroup', params, "GET");

    /*return axios.post(API_PATH + 'collection/collectionsubgroup',{
        "group": group,
        "search": search,
        "uid": uid,
        "usertype": usertype,
        "managecollection": managecollection,
        "limit": limit,
        "offset": offset,
        "index": ES_INDEX,
        "has_form": "yes"


    });*/
}
function saveMetaform(params){
    return commonService.sendHttpRequest('collection/savemetaform', params, "POST");
    /*return axios.post(API_PATH + 'collection/savemetaform',{
        "metaresult": [
            metaresult
        ],
        "uid": uid,
        "todoid": todoid,
        "index": "lsinternalcopy"


    });*/

}

function selectedCollections(params){
    // return axios.post(API_PATH + 'collection/selectedcollection',{
    //     "_id": id,
    //     "index": ES_INDEX
    // });
    return commonService.sendHttpRequest('collection/selectedcollection', params, "POST");
}

function deleteCollectionRecords(collectionid, uid, records){
    // return axios.post(API_PATH + 'collection/deleterecords',{
    //     "collectionid": collectionid,
    //     "uid": uid,
    //     "records": records,
    //     "index": ES_INDEX
    // });
    let params={
        "collectionid": collectionid,
        "uid": uid,
        "records": records,
    }

    return commonService.sendHttpRequest('collection/deleterecords', params, "POST");
}

function exportField(collectionid){

    return commonService.sendHttpRequest('collection/displayfields', collectionid, "GET");
}



function showForm(params) {
    return commonService.sendHttpRequest('collection/form', params, "GET");
    // return axios.get(API_PATH + 'collection/showform?_id=' + id + '&index=' + ES_INDEX);
}

function getFormData(params) {
    ////console.log(params)
    return commonService.sendHttpRequest('collection/selectedcollection', params, "post");
}

function getcollectionconfig(params) {
    ////console.log(params)
    return commonService.sendHttpRequest('collection/collectionconfig', params, "GET");
}

function listCollectionDetail(id, params){
    return commonService.sendHttpRequest('collection/' + id + '/collectedinfo', params, "POST");
}

function shareCollection(params){
    return commonService.sendHttpRequest('collection/sharecollection', params, "POST");
}

function getExternalValues(params) {

    return commonService.sendHttpRequest('collection/searchautofill', params, "POST");

}
function getAggregate(params) {

    return commonService.sendHttpRequest('collection/calculateaggs', params, "GET");

}


function saveForm(params){
    ////////console.log(params);
    return commonService.sendHttpRequest('collection/createdata', params, "POST");
}
function listTodoCollections(params){
    return commonService.sendHttpRequest('collection/collectionsubgroup', params, "GET");
}
function getCollectionSettings(params) {
    return commonService.sendHttpRequest('collection/collectionsettings', params, "GET");
}
function formFilter(params){
    return commonService.sendHttpRequest('collection/formfilter', params, "GET");
}

function searchEntity(params){
    return commonService.sendHttpRequest('collection/searchentity', params, "GET");
}
function externalSearchEntity(params){
    return commonService.sendHttpRequest('collection/externalsearchentity', params, "GET");
}
function entitydetails(params){
    return commonService.sendHttpRequest('collection/entitydetails', params, "GET");
}
function deleteEntity(params){
    return commonService.sendHttpRequest('collection/deleteentity', params, "GET");
}

function duplicateEntity(entityid, UserId, index, projectId){
    return axios.get(SHARED_LOGIN.redirect_url +'Collection/duplicateEntity/'+entityid+'/'+UserId+'/'+index+'/'+projectId);
}
function downloadEntity(params){
    return axios.get(SHARED_LOGIN.CISERVICE + 'Collection/downloadfolder/'+params.id+"/"+params.collection+"/"+params.index);
}

function saveExportFieldData(params){
    return commonService.sendHttpRequest('collection/save_exoprtsettings', params, "POST");
}
function exportall(params){
    return commonService.sendHttpRequest('export/exportall', params, "POST");
}

function getCategory(params){
    return commonService.sendHttpRequest('collection/getcategory', params, "POST");
}

function createCollections(params){
    return commonService.sendHttpRequest('collection/createcollections', params, "POST");
}
function createEntity(params){
    return commonService.sendHttpRequest('collection/saveentity', params, "POST");
}
function entityCollections(params){
    return commonService.sendHttpRequest('collection/entitycollections', params, "GET");
}

function createCategory(params){
    return commonService.sendHttpRequest('collection/savecategory', params, "POST");
}
function updatetodoCollection(params){
    return commonService.sendHttpRequest('task/updatetodocollection', params, "POST");
}

/* rule */
function getRule(params){
    return commonService.sendHttpRequest('collection/rulerecurrence', params, "POST");
}
function listingWorkflow(params){
    return commonService.sendHttpRequest('workflow/noformworkflow', params, "GET");
}
function linkedWorkflow(cid,params){
     params.collectionid=cid
    return commonService.sendHttpRequest('workflow/linkedworkflow/', params, "GET");
}
function deletelinkedWorkflow(params){
    return commonService.sendHttpRequest('workflow/deleteworkflowlink/', params, "POST");
}
function createGenericRule(params){
    return commonService.sendHttpRequest('pool/saverecurrence/', params, "POST");
}
function genericRuleDelted(id){
    return commonService.sendHttpRequest('collection/deletrecurrence/'+id, {}, "GET");
}
function saveWorkflowrule(param){
    return commonService.sendHttpRequest('workflow/linkworkflowcollection/', param, "POST");
}
function importSystemCollectionData(params){
    return commonService.sendHttpRequest('collection/importsystemcsv', params, "POST");
}
function starClick(params){
    return commonService.sendHttpRequest('collection/saverecordstar', params, "POST");
}
function listAnnotation(params){
    return commonService.sendHttpRequest('collection/annotation', params, "GET");
}
function saveAnnotation(params){
    return commonService.sendHttpRequest('collection/saveannotation', params, "POST");
}
function saveEnitityInvokeWorkflow(params){
    return commonService.sendHttpRequest('workflow/invokeworkflow', params, "POST");
}

