import React, {Component} from 'react';
import {
    Treemap,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Area,
    Legend,
    ResponsiveContainer,
    LabelList
} from 'recharts';
function TreeMapChart(props) {
    return (
        <React.Fragment>
            <ResponsiveContainer width="100%" height={300} >
                <Treemap
                    width={400}
                    height={200}
                    data={props.data}
                    dataKey="size"
                    ratio={4/3}
                    stroke="#fff"
                    fill="#8884d8"
                />
            </ResponsiveContainer>

        </React.Fragment>
    );
}

export default TreeMapChart;