import React, { useState, useEffect  } from 'react';
import moment from "moment";
import {getProfile} from "../../../utilities/AuthService";
import OutsideAlerter from "../../views/OutsideAlerter";
import {getFileformat, isAudio, isImage, isVideo} from "../../../utilities/CustomFunctions";
import {FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH} from "../../../constants";
import SimpleModal from "../../views/SimpleModal";
const GoogleMap = React.lazy(() =>  import('../../views/GoogleMap'))
const GoogleStaticMap = React.lazy(() =>  import('../../pages/ceshareview/GoogleStaticMap'))
function Template5(Props) {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const container = document.querySelector('.card-container');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        var scrollY = 0;
        var scrollX = 0;
        var doc = document.getElementById("a");
        if(doc.scrollTop  !== scrollY) {
            const container = document.querySelector('.card-container')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            if (contentHeight <= scrollTop) {
                Props.loadmoreCollectionData()
            };
        }
        setIsFetching(true);
    }

    function fetchMoreListItems() {
        Props.loadmoreCollectionData()
    }
    let showlistField = []
    if(Props.label.showformdata.length !==0) {
        for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
            (Props.label.showformdata.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden")).map(list=>{
                showlistField.push(list)

            })
        }
    }
    let viewtemplate=Props.longcontent
    let lon=showlistField.filter(list=>list.type === "longitude")
    let lat=showlistField.filter(list=>list.type === "latitude")
    let perStat=((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].addedit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()))

    return (
            <div className={`card-container list-view template-collection h-view ${Props.horizontalImage? `with-image`:''}`} id="a" >
                {Props.collection.CollectionData.map((row, rowIndex) =>
                    <React.Fragment>
                        {viewtemplate.map((list,index)=>
                            <div className="ind-collection-card"
                                // onClick={(e) => Props.handleClicks(e, row['id'], rowIndex,perStat)}
                                 onDoubleClick={(e) => Props.showPreviewFormModal(e, row['id'], rowIndex)}
                            >
                                <div className="collection-card-left">

                                    {!Props.horizontalImage && !Props.horizontalMapenabled &&
                                    <>
                                        <p>{list.item1.label}</p>
                                        <h5>{list.item1.type ==="datepicker"?
                                            moment(row[list.item1.masterid]).format("MMMM DD, YYYY"):
                                            row[list.item1.masterid]}
                                        </h5>
                                    </>
                                    }
                                    {Props.horizontalImage &&
                                    <AttachmentBox   filename={row[list.item1.masterid]} type={list.item1.type}/>
                                    }
                                    {!Props.horizontalImage && Props.horizontalMapenabled && list.hasOwnProperty("item10") &&
                                    <>
                                        {list.item13.masterid === "static" ?

                                            <GoogleStaticMap
                                                lat={row[list.item10.masterid]}
                                                lng={row[list.item11.masterid]}
                                                zoom={list.item12.masterid}
                                                type={`horizontal`}
                                            />
                                            :
                                            <GoogleMap
                                                lat={row[list.item10.masterid]}
                                                lng={row[list.item11.masterid]}
                                                zoom={list.item12.masterid}
                                                type={`horizontal`}
                                            />

                                        }


                                    </>

                                    }

                                </div>
                                <div className="collection-card-right">
                                <div className="form-option-block">
                                        {((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].addedit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&
                                        <button className="ico-lib opt-form-ico" onClick={(e)=>Props.showOption(e, row['id'])}></button>
                                        }
                                        {Props.showtemplateOptions && (Props.collectededitid === row['id']) &&
                                        <OutsideAlerter
                                            status={Props.showtemplateOptions}
                                            Triggered={() => Props.showOption(!Props.showtemplateOptions)}
                                        >
                                            <ul className="panel-option" id="option-list">
                                                <li><nav className="panel-option-click ico-lib form-edit-ico" onClick={(e) => Props.showFormModal(e, row['id'])} >Edit</nav></li>
                                            </ul>
                                        </OutsideAlerter>
                                        }
                                    </div>
                                    {!Props.horizontalImage &&
                                    <div className="collection-card-right-header">
                                        <p>{list.item2.label}</p>
                                        <h5>{list.item2.type === "datepicker" ?
                                            moment(row[list.item2.masterid]).format("MMMM DD, YYYY") :
                                            row[list.item2.masterid]}
                                        </h5>
                                    </div>
                                    }
                                    <div className="collection-card-right-content">
                                        <p>{list.item3.label}</p>
                                        <h5>{list.item3.type ==="datepicker"?
                                            moment(row[list.item3.masterid]).format("MMMM DD, YYYY"):
                                            row[list.item3.masterid]}
                                        </h5>
                                    </div>
                                    <div className="collection-card-right-footer">
                                        <ul>
                                            <li>
                                                <p>{list.item4.label}</p>
                                                <h5>{list.item4.type ==="datepicker"?
                                                    moment(row[list.item4.masterid]).format("MMMM DD, YYYY"):
                                                    row[list.item4.masterid]}
                                                </h5>
                                            </li>
                                            <li>
                                                <p>{list.item5.label}</p>
                                                <h5>{list.item5.type ==="datepicker"?
                                                    moment(row[list.item5.masterid]).format("MMMM DD, YYYY"):
                                                    row[list.item5.masterid]}
                                                </h5>
                                            </li>
                                            <li>
                                                <p>{list.item6.label}</p>
                                                <h5 dangerouslySetInnerHTML={{__html:list.item6.type ==="datepicker"? moment(row[list.item6.masterid]).format("MMMM DD, YYYY"): row[list.item6.masterid] }} />
                                            </li>
                                            <li>
                                                <p>{list.item7.label}</p>
                                                <h5>{list.item7.type ==="datepicker"?
                                                    moment(row[list.item7.masterid]).format("MMMM DD, YYYY"):
                                                    row[list.item7.masterid]}
                                                </h5>
                                            </li>
                                            <li>
                                                <p>{list.item8.label}</p>
                                                <h5>{list.item8.type ==="datepicker"?
                                                    moment(row[list.item8.masterid]).format("MMMM DD, YYYY"):
                                                    row[list.item8.masterid]}
                                                </h5>
                                            </li>
                                            <li>
                                                <p>{list.item9.label}</p>
                                                <h5>{list.item9.type ==="datepicker"?
                                                    moment(row[list.item9.masterid]).format("MMMM DD, YYYY"):
                                                    row[list.item9.masterid]}
                                                </h5>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
    )

}


function HorizonalLongContent(Props) {
//console.log(Props)
    let viewtemplate=Props.longcontent
    let showlistField = []
    if(Props.label.showformdata.length !==0) {
        for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
            (Props.label.showformdata.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden")).map(list=>{
                showlistField.push(list)

            })
        }
    }

    return (
        <div className={`card-container list-view template-collection ${Props.horizontalImage? `with-image`:''}`} id="a">
            {Props.collection.CollectionData.slice(0, 1).map((row, rowIndex) =>
                <React.Fragment>
                    {viewtemplate.map((list,index)=>
                        <div className="ind-collection-card">
                            <div className="collection-card-left">

                                {!Props.horizontalImage && !Props.horizontalMapenabled &&
                                <>
                                    <p>{list.item1.label}</p>
                                    <h5>{list.item1.type ==="datepicker"?
                                        moment(row[list.item1.masterid]).format("MMMM DD, YYYY"):
                                        row[list.item1.masterid]}
                                    </h5>
                                </>
                                }
                                {Props.horizontalImage &&
                                <AttachmentBox   filename={row[list.item1.masterid]} type={list.item1.type}/>
                                }
                                {!Props.horizontalImage && Props.horizontalMapenabled &&

                                <GoogleMap
                                    lat={row[list.item10.masterid]}
                                    lng={row[list.item11.masterid]}
                                    zoom={list.item12.masterid}
                                    type={`horizontal`}
                                />
                                }


                            </div>
                            <div className="collection-card-right">
                                {!Props.horizontalImage &&
                                <div className="collection-card-right-header">
                                    <p>{list.item2.label}</p>
                                    <h5>{list.item2.type === "datepicker" ?
                                        moment(row[list.item2.masterid]).format("MMMM DD, YYYY") :
                                        row[list.item2.masterid]}
                                    </h5>
                                </div>
                                }


                                <div className="collection-card-right-content">
                                    <p>{list.item3.label}</p>
                                    <h5>{list.item3.type ==="datepicker"?
                                        moment(row[list.item3.masterid]).format("MMMM DD, YYYY"):
                                        row[list.item3.masterid]}
                                    </h5>
                                </div>
                                <div className="collection-card-right-footer">
                                    <ul>
                                        <li>
                                            <p>{list.item4.label}</p>
                                            <h5>{list.item4.type ==="datepicker"?
                                                moment(row[list.item4.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item4.masterid]}
                                            </h5>
                                        </li>
                                        <li>
                                            <p>{list.item5.label}</p>
                                            <h5>{list.item5.type ==="datepicker"?
                                                moment(row[list.item5.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item5.masterid]}
                                            </h5>
                                        </li>
                                        <li>
                                            <p>{list.item6.label}</p>
                                            <h5>{list.item6.type ==="datepicker"?
                                                moment(row[list.item6.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item6.masterid]}
                                            </h5>
                                        </li>
                                        <li>
                                            <p>{list.item7.label}</p>
                                            <h5>{list.item7.type ==="datepicker"?
                                                moment(row[list.item7.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item7.masterid]}
                                            </h5>
                                        </li>
                                        <li>
                                            <p>{list.item8.label}</p>
                                            <h5>{list.item8.type ==="datepicker"?
                                                moment(row[list.item8.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item8.masterid]}
                                            </h5>
                                        </li>
                                        <li>
                                            <p>{list.item9.label}</p>
                                            <h5>{list.item9.type ==="datepicker"?
                                                moment(row[list.item9.masterid]).format("MMMM DD, YYYY"):
                                                row[list.item9.masterid]}
                                            </h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    )

}

function AttachmentBox(Props) {
    const [status, setPreview] = useState({ filename: '',status:false });
    return(
        <React.Fragment>
            {Props.type !== "imageurl" && Props.filename !==  undefined ?

                <React.Fragment>
                    {isImage(Props.filename) ?
                        <div className="collection-card-image">

                            <img src={FRMSIMG_PATH + "/" + Props.filename}/>
                        </div>
                        :



                        <div className={`collection-card-image`}>
                            {isVideo(Props.filename) || isAudio(Props.filename) ?
                                <span
                                    className={`format ${getFileformat(Props.filename).toLowerCase()}`}
                                    onClick={() => setPreview({
                                        filename: Props.filename,
                                        status: !status.status
                                    })}>{getFileformat(Props.filename).toLowerCase()} </span>
                                :
                                Props.filename !==""?
                                <span
                                    className={`format ${getFileformat(Props.filename).toLowerCase()}`}> {getFileformat(Props.filename).toLowerCase()} </span>
                                    :
                                    <span className={`format`}> {Props.filename} </span>
                            }

                        </div>
                    }




                    {status.status &&
                    <SimpleModal
                        Close={() => setPreview(!status)}
                    >
                        {isVideo(Props.filename) ?
                            <video width="100%" height={`100%`} controls>
                                <source src={FRMSVID_PATH + "/" + status.filename} type="video/mp4"/>
                            </video>
                            :
                            isAudio(Props.filename) &&
                            <div className="collection-card-image">
                                <audio controls>
                                    <source src={FRMSDOC_PATH + "/" + status.filename} type="video/mp4"/>
                                </audio>
                            </div>

                        }
                    </SimpleModal>

                    }
                </React.Fragment>
                :
                <div className="collection-card-image">
                    <img src={Props.filename}/>
                </div>

            }
        </React.Fragment>

    )

}
export  {Template5,HorizonalLongContent};
