import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import Overview from "../../views/syncfusion/FileManager/Overview"
import SimpleModal from "../../views/SimpleModal";
import TimelineResource from "../../views/syncfusion/scheduler"
class PdfBuilder extends Component {
    constructor(props) {
        super(props);
        this.state={

        }

    }
    componentDidMount() {


    }



    render() {
        return (

            <div className="details-wrapper">
                 {/*<Overview/>*/}
                <SimpleModal
                    Close={this.openSetting}
                    title={`test`}
                    haveFooter={1}
                    OKLabel="Save"
                    OKAction={this.chartSaving}
                    asignedUsershow = {false}
                    cancelLabel="Cancel"
                    cancelAction={this.openSetting}
                >


<TimelineResource/>

                </SimpleModal>

            </div>


        );
    }
}



const mapStateToProps = state => ({

});
export default  withRouter(connect(mapStateToProps, {


})(PdfBuilder));


