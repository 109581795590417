import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import SimpleModal from "../views/SimpleModal"
import CustomFields from "../views/CustomFields"
import * as WorkFlowActions from "../../actions/WorkFlow";
import {ListAllCustomFields} from "./WorkflowData"
import * as commonActions from "../../actions/Common";
import {getProfile} from "../../utilities/AuthService";
import {getUrlSegment} from "../../utilities/CustomFunctions"
import {USRIMG_PATH} from "../../constants";
import * as collectionActions from "../../actions/Collections";
import {Scrollbars} from "react-custom-scrollbars";
import moment from 'moment';
import rootActions from "../../actions";

class WorkflowDetails extends Component {

    constructor(props) {
        super(props);
        this.state={
            stage:'input',
            showModalStage : false,
            editStage:'task',
            settngs : 'display',
            items: [],
            modalStatus: false,
            metaform: [],
            metaform1: [],
            mode: '',
            addTask:false,
            stagename:'',
            displayStngsId:'',
            Displabel:'Save',
            selectForm: false,
            selectInputForm:false,
            formidArr:[],
            SlctdformidArr:[],
            importForm: false,
            stageforward:'',
            statusMap:'',
            clctnid:'',
            saveInputForm : false,
        }
        this.metaForm = this.metaForm.bind(this)
        this.updateMetaFormField=this.updateMetaFormField.bind(this)
        this.saveTaskData = this.saveTaskData.bind(this)
        this.getCustomField=this.getCustomField.bind(this)
        this.removeClick=this.removeClick.bind(this)
        this.saveDisplaysettings=this.saveDisplaysettings.bind(this);
        this.formSelected=this.formSelected.bind(this);
        this.addStageForm = this.addStageForm.bind(this);
        this.Nextstageforward = this.Nextstageforward.bind(this);
        this.addInputForm = this.addInputForm.bind(this);
        this.getWorkFlowdetails = this.getWorkFlowdetails.bind(this);
    }



    componentDidMount() {
        let workflowid=(atob(getUrlSegment(3)))
        this.props.mainClick('workflow','')
        this.props.workflowstageinfo(workflowid,'',0,25,true,'lsinternalcopy')
        this.props.workflowuserinfo(workflowid,0,25,'lsinternalcopy')
        this.props.workflowdetails(workflowid,'lsinternalcopy')
        this.getWorkFlowdetails();
        this.props.workflowinvokedetails(workflowid,'lsinternalcopy')
        this.props.collections('All', "", getProfile().id, getProfile().usertype, null, "0", 750, "lsinternalcopy")
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let beta = this;
            if(prevProps.saveWorkflowCollectionData.workflowcollection_save !== this.props.saveWorkflowCollectionData.workflowcollection_save ) {
                setTimeout(function () {
                    beta.getWorkFlowdetails();
                }, 1000)

            }

    }
    getWorkFlowdetails() {
        let workflowid=(atob(getUrlSegment(3)));
        let savewrkflwdata = {wid : workflowid, uid : getProfile().id, usertype : getProfile().usertype, requiredcollectioninfo : 'yes'}
        this.props.workflowformdetails(savewrkflwdata);

    }



    handleStateChange(val){
        this.setState({stage:val})
    }
    handleSettingChange(val){
        this.setState({settngs:val})
    }
    handleStateStage(stage){
        this.setState({editStage : stage});
    }
    editstgModalclose(){
        this.setState({showModalStage: !this.state.showModalStage})
    }

    workFlowDetails(workflowid,wname){
        this.setState({showWorkflowid: workflowid});
        this.setState({showWorkflowname: wname});
        this.setState({showModalStage: !this.state.showModalStage})
        this.props.workflowstagetaskinfo(workflowid,'lsinternalcopy')

    }
    handleDisplaystngs(displayid){

        this.setState({displayStngsId: displayid})
    }
    saveDisplaysettings(){
        let workflowid=(atob(getUrlSegment(3)));
        let dispid = this.state.displayStngsId;
     this.props.SaveDisplaysetngs(workflowid,dispid,'lsinternalcopy');
        this.setState({Displabel: "Update"});
    }

    getCustomField = (errormessage, id, label, type, required, options, mode,index) => {
        this.setState({
            modalStatus: !this.state.modalStatus,
            metaform: {
                errormessage: errormessage,
                id: id,
                label: label,
                type: type,
                required: required,
                options: options,
                mode: mode,
                index:index
            },
            mode: mode,

        })
    }
    saveFormData = (metaform1) => {
        //console.log(this.props.workflowStageTaskInfo.stagetaskinfo.metaform.concat(metaform1))
        this.setState({metaform1: metaform1})
        this.props.updateMetaform(this.props.workflowStageTaskInfo.stagetaskinfo.metaform.concat(metaform1))
    }
    saveTaskData() {
        let stageData = {
            metaform: this.props.workflowStageTaskInfo.stagetaskinfo.metaform ,
            stageid: parseInt(this.props.workflowStageTaskInfo.stagetaskinfo.task[0].stageid),
            taskid: parseInt(this.props.workflowStageTaskInfo.stagetaskinfo.task[0].taskid),
            workflowid: parseInt(this.props.workflowStageTaskInfo.stagetaskinfo.task[0].workflowid),
            subject: atob(this.props.workflowStageTaskInfo.stagetaskinfo.task[0].subject),
            priority: this.props.workflowStageTaskInfo.stagetaskinfo.task[0].priority,
            instruction: atob(this.props.workflowStageTaskInfo.stagetaskinfo.task[0].instruction),
            stageforward:this.state.stageforward == '' ? this.props.workflowStageTaskInfo.stagetaskinfo.task[0].stageforward : this.state.stageforward,
            nextstage: this.props.workflowStageTaskInfo.stagetaskinfo.task[0].nextstage,
            tagsid: [],
            tagsname: this.props.workflowStageTaskInfo.stagetaskinfo.task[0].tagsname,
            forms: (this.state.formidArr).toString(),
            duein: this.props.workflowStageTaskInfo.stagetaskinfo.task[0].duein,
            to: "user_55,user_39",
            cc: "",
            formstatus: this.state.statusMap == '' ? this.props.workflowStageTaskInfo.stagetaskinfo.task[0].formstatus : this.state.statusMap,
            mode: "update",
            uid: 2,
            index: 'lsinternalcopy'

        }
        this.props.SavestageTask(stageData)
        //console.log(stageData)
    }
    metaForm(metadata) {
        //console.log(metadata)
        this.setState({metaform: metadata})
        this.props.updateMetaform(metadata)
    }
    updateMetaFormField(name,value,index){
        this.props.updateMetaFormField(name,value,index)
    }
    removeClick(i) {
        //console.log(this.props.workflowStageTaskInfo)
        let options = this.props.workflowStageTaskInfo.stagetaskinfo.metaform;
        options.splice(i, 1);
        this.props.updateMetaform(options)
    }
    Close(){
        this.setState({previewStatus:!this.state.previewStatus})
    }

    updateTask =(e)=>{
          let value=''
        if(e.target.name ==='subject' || e.target.name==='instruction' ){
             value=btoa(e.target.value)
        }
        else{
             value=(e.target.value)
        }
        this.props.updateTask(e.target.name,value)
    }

    showModal =(modalType)=>{

        this.setState({[modalType]:!this.state[modalType]})
    }


    addStage=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    saveStage(){
        if(this.state.stagename !==''){
            this.props.saveStage(this.state.stagename)
        }
    }

    WorkflowFormAdd(formsection){
        if(formsection == 'stageforms') {
            this.setState({importForm: true})
            this.setState({selectForm: true})
        } else if (formsection == 'Inputforms'){
            this.setState({saveInputForm: true})
            this.setState({selectInputForm: true})
        } else if (formsection == 'InputformsUpd'){
            this.setState({saveInputForm: false})
            this.setState({selectInputForm: true})
        }
    }
    importformclose(){
        this.setState({selectInputForm: false})
    }
    formSelected(id){

        var fid = id;
        let formidArr = this.state.formidArr;
        formidArr.push(fid);
        this.setState({formidArr:formidArr})
        console.log((formidArr).toString());
    }
    addStageForm(){

        this.setState({importForm:true})
        this.setState({selectForm: false})
    }

    Nextstageforward(type){
        var stageid = type;
        this.setState({stageforward: stageid});
    }

    statusMap(status){
        this.setState({statusMap: status});
    }
    InputformSelected(id){
        this.setState({clctnid: id});
    }

    addInputForm(){
        let iformid = '';
        alert(this.state.saveInputForm);
        if (this.state.saveInputForm == true)
            {
                let iformid = moment().unix();
                let workflowid = (atob(getUrlSegment(3)));
                let collectionid = this.state.clctnid;
                let fielddata = (btoa('[]'));
                let savedata = {collectionid : collectionid, workflowid : workflowid, fielddata : fielddata, mode : 'save', _id : iformid, uid : getProfile().id,  type : 'generic' }
                this.props.Saveworkflowcollection(savedata);

              //  this.props.Saveworkflowcollection(collectionid, workflowid, fielddata, 'save', iformid, getProfile().id, 'generic')
                this.setState({saveInputForm: false});
                this.importformclose()
        }

        else if(this.state.saveInputForm == false)
        {
            let iformid='';
            let workflowFormDetails = this.props.workflowFormDetailsData.WorkFlowFormDetails;
            //console.log(workflowFormDetails.data);
            {workflowFormDetails.data.map((list,index) => {
                iformid = list._id;
                this.setState({inputfid: list._id})

            } )}
            //console.log(this.state.inputfid);


            let workflowid = (atob(getUrlSegment(3)));
            let collectionid = this.state.clctnid;
            let fielddata = (btoa('[]'));
            let savedata = {collectionid : collectionid, workflowid : workflowid, fielddata : fielddata, mode : 'edit', _id : iformid, uid : getProfile().id,  type : 'generic' }
            this.props.Saveworkflowcollection(savedata);

                   }
    }

    render() {
        let workflowStageinfoData=this.props.workflowStageinfoData.UsersAllpermission;
        let workflowUserinfo = this.props.workflowUserinfoData.WorkFlowUserinfo.data;
        let workflowFormDetails = this.props.workflowFormDetailsData.WorkFlowFormDetails;
        let workflowInvokeDetails=this.props.workflowInvokeDetailsData.invokedetails;
        let task=this.props.workflowStageTaskInfo.stagetaskinfo.task
        let Collections = this.props.listAllCollectionsData.Collections.data;
        let stageDetails  = this.props.workflowStageTaskInfo.stagetaskinfo.task[0];

//console.log(workflowFormDetails);

        //console.log(this.props);
        return (
            <div className="right-content-wrapper">
                <header className="header-block hide-header">
                    <div className="header-wrapper">
                        <div className="search-block">
                            <input type="text" name="" className="search-box" placeholder="Search User, Task, etc..."/>
                            <span className="ico-lib search-ico"></span>
                        </div>
                        <div className="user-othe-info">
                            <div className="user-info-click mob-area">
                                <div className="mobile-toggle">
                                    <a href="javascript:void(0);" className="toggle-mnu hidden-lg">
                                        <span></span>
                                    </a>
                                </div>
                            </div>
                            <div className="user-info-click">
                                <a href="javascript:void(0);" className="ico-lib qa-ico" data-active="#qa-in"></a>
                            </div>
                        </div>
                    </div>
                    <div className="float-panel-wrapper" id="notf-in">
                        <div className="float-panel-header">
                            <h5 className="subheader">Notifications</h5>
                            <button className="ico-lib fly-close"></button>
                        </div>

                    </div>
                    <div className="float-panel-wrapper" id="user-in">
                        <div className="float-panel-header">
                            <h5 className="subheader">User Profile</h5>
                            <button className="ico-lib fly-close"></button>
                        </div>
                        <div className="float-panel-content-holder auto-stretch">
                            <div className="user-profile-info">
                                <div className="user-profile-image"><img src="assets/images/user.jpg"/></div>
                                <div className="user-profile-details">
                                    <h4 className="subheader">John Doe</h4>
                                    <ul className="user-profile-det-list">
                                        <li className="ico-lib prof-user-ico">CEO</li>
                                        <li className="ico-lib mail-ico">john@logicalsteps.net</li>
                                        <li className="ico-lib phone-ico">+91 1234567890</li>
                                        <li className="ico-lib loc-ico">Kochi, India</li>
                                    </ul>
                                    <div className="profile-other-opt">
                                        <div className="profile-other-left">
                                            <button className="trans-button ico-lib pro-edit-ico">Edit Profile</button>
                                        </div>
                                        <div className="profile-other-right">
                                            <button className="trans-button ico-lib logout-ico">Logout</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="float-panel-wrapper" id="qa-in">
                        <div className="float-panel-header">
                            <h5 className="subheader">Help Topics</h5>
                            <button className="ico-lib fly-close"></button>
                        </div>

                    </div>
                </header>
                <div className="right-content-wrapper project-tab-rise">
                    <div className="form-detail-container data-view project-form">
                        <div className="form-detail-panel">
                            <div className="form-detail-right">


                                <div className="form-detail-top wf-top">
                                    <div className="wf-detail-top">
                                        <div className="wf-detail-top-left">
                                            <button className="proj-back" onClick={(e)=>this.props.history.push('/workflow')}></button>
                                            {workflowInvokeDetails.workflowinfo.map((wname,index) =>
                                                <h5>{atob(wname._source.name)}</h5>
                                            )}

                                        </div>
                                        <div className="wf-detail-top-right">
                                            <p>2 Instances <a href="invoked.html" className="view-click">Invoked View</a></p>
                                            <div className="form-option-block">
                                                <button className="ico-lib opt-form-ico"></button>
                                                <ul className="panel-option">
                                                    <li>
                                                        <a href="javascript:void(0);"
                                                           className="panel-option-click ico-lib form-assign-ico">Assign</a>
                                                    </li>
                                                    <li className="list-elements new-set-features">
                                                        <a href="javascript:void(0);"
                                                           className="panel-option-click ico-lib form-share-ico">Share</a>
                                                    </li>
                                                    <li>
                                                        <a className="panel-option-click ico-lib form-set-ico"
                                                           data-toggle="modal" data-target="#settingsModal">Settings</a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:void(0);"
                                                           className="panel-option-click ico-lib delete-task-ico">Delete</a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:void(0);"
                                                           className="panel-option-click ico-lib form-rule-ico">Rules</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wf-detail-bottom">
                                        {workflowInvokeDetails.workflowinfo.map((invhdr,index) =>
                                        <p>{atob(invhdr._source.description)}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="form-detail-bottom wf-bottom">
                                    <div className="form-detail-bottom-left">
                                        <ul className="detail-tab">

                                            <li>
                                                <a onClick={()=>this.handleStateChange('input')} className="detail-tab-click input active"
                                                   data-tab="proInput">Input</a>
                                            </li>
                                            <li>
                                                <a  onClick={()=>this.handleStateChange('stages')} className="detail-tab-click wf-stages"
                                                    data-tab="prostage">Stages</a>
                                            </li>
                                            <li>
                                                <a onClick={()=>this.handleStateChange('users')} className="detail-tab-click team"
                                                   data-tab="proUsers">Users & Permissions</a>
                                            </li>
                                            <li>
                                                <a onClick={()=>this.handleStateChange('automation')} className="detail-tab-click wf-auto"
                                                   data-tab="proAutomation">Automation</a>
                                            </li>
                                            <li>
                                                <a onClick={()=>this.handleStateChange('settings')} className="detail-tab-click wf-set"
                                                   data-tab="proSettings">Settings</a>
                                            </li>
                                            <li>
                                                <a onClick={()=>this.handleStateChange('actiontemplates')} className="detail-tab-click wf-temp"
                                                   data-tab="proAction">Action Templates</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.stage === 'stages' &&
                        <div className="data-holder wf-hold" id="prohandleStateChange">
                            <div className="tab-in-container">
                                {this.props.workflowDetailsData.WorkFlowDetails.data.stageinfo.map((list,index) =>
                                    <div className="ind-set-list" onClick={()=>this.workFlowDetails(list._id, list._source.name)}>
                                        <div className="set-count">{index+1}</div>
                                        <div className="set-desc">
                                        <h5>{list._source.name}</h5>
                                        <ul className="bt-text">
                                            <li className={`ico-lib-v1 ${list._source.has_task  === 'yes'?'tick':''}`}>Task</li>
                                            <li className={`ico-lib-v1 ${list._source.setnextstage ==='yes'?'tick':''}`}>Next Stage</li>
                                            <li className={`ico-lib-v1 ${list._source.setformstatus ==='yes'?'tick':''}`}>Status Mapping</li>
                                        </ul>
                                        <div className="form-option-block">
                                            <button className="ico-lib opt-form-ico"></button>
                                            <ul className="panel-option">
                                                <li>
                                                    <a href="javascript:void(0);"
                                                       className="panel-option-click ico-lib form-assign-ico">Assign</a>
                                                </li>
                                                <li className="list-elements new-set-features">
                                                    <a href="javascript:void(0);"
                                                       className="panel-option-click ico-lib form-share-ico">Share</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);"
                                                       className="panel-option-click ico-lib form-set-ico"
                                                       data-toggle="modal"
                                                       data-target="#settingsModal">Settings</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);"
                                                       className="panel-option-click ico-lib delete-task-ico">Delete</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0);"
                                                       className="panel-option-click ico-lib form-rule-ico">Rules</a>
                                                </li>
                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Task">
                                <button className="float-button ico-lib float-add" onClick={()=>this.showModal('addTask')}></button>
                            </div>

                        </div>
                        }
                        {this.state.stage === 'input' &&
                        <div className="data-holder wf-hold input-wrap " id="proInput">

                            <div className="default-placeholder">
                                <img src="assets/images/input.png"/>
                                <h5>No Forms Imported</h5>
                            </div>
                            <div className="imported-input-wrapper">
                                <div className="imported-input-header">
                                {workflowFormDetails.data.length !== 0 && this.props.workflowDetailsData.WorkFlowDetails.data.invokeditems == '' &&
                                     workflowFormDetails.data.map((formname, index) =>
                                            <>
                                            <h5>{atob(formname.collectioninfo.title)}</h5>
                                                {this.props.workflowDetailsData.WorkFlowDetails.data.invokeditems !== '' &&

                                                <button className="change-button" data-toggle="modal"
                                                    data-target="#newInput" >Preview
                                                    </button>

                                                }
                                         <button className="change-button" data-toggle="modal"
                                             data-target="#newInput" onClick={(e)=>this.WorkflowFormAdd('InputformsUpd')}>Change Input Form
                                         </button>
                                            </>
                                    )}

                                    {workflowFormDetails.data.length === 0 &&
                                        <>
                                        <h5>Form no chosen</h5>
                                    <button className="change-button" data-toggle="modal"
                                        data-target="#newInput"  onClick={(e)=>this.WorkflowFormAdd('Inputforms')}>Choose Input Form
                                        </button>
                                        </>
                                    }

                                </div>


                                        <div className="stage-steps" id="stInputmeta">
                                            <div className="stage-form-container">
                                                {workflowFormDetails.data.map ((fields,index) =>
                                                <div className="form-block-container">
                                                    <div className="form-block-left">
                                                        {fields.form.pages.map((datalist,index) =>
                                                            <div className="form-list-wrap">
                                                                {datalist.components.map((datalabel,index) =>
                                                                    <>
                                                                  {datalabel.linked == "No" &&
                                                                        <div className="ind-field-list">
                                                                                    <div className="counter-field">{index + 1}</div>

                                                                                    <div className="field-type-name">
                                                                                        <p>{datalabel.label}</p>
                                                                                    </div>
                                                                                </div>
                                                                    }
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                ) }
                                            </div>
                                        </div>
                                    </div>


                            <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Input">
                                <button className="float-button ico-lib float-add" data-toggle="modal"
                                        data-target="#newInput">ee</button>
                            </div>
                        </div>
                        }
                        {this.state.stage === 'users' &&
                        <div className="data-holder wf-hold task-wrap" id="proUsers" >
                            <div className="main-panel-holder">

                                <div className="card-holder">
                                {workflowUserinfo.length !==0 &&workflowUserinfo.users.map((list,index) =>
                                    <div className="team-card" >
                                        <div className="user-thumb">
                                            <img className="user-image-in" src= {USRIMG_PATH + list._source.img_name} />
                                            <div className="hover-in-button">
                                                <div className="button-in">
                                                    <span className="icon-in icon-chat-sm"></span>
                                                    <span className="botton-text">Chat</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-description">
                                            <h5 className="card-user-head">{list._source.first_name} {list._source.last_name}</h5>
                                            {list._source.designation &&
                                            <p className="designation-in">{list._source.designation}</p>
                                            }
                                            {list._source.email &&
                                            <p className="mail-in">{list._source.email}</p>
                                            }
                                            {list._source.phone_mobile &&
                                            <p className="contant-no-in">{list._source.phone_mobile}</p>
                                            }

                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>

                        </div>
                        }
                        {this.state.stage === 'automation' &&
                        <div className="data-holder wf-hold team-wrap" id="proAutomation" >
                        </div>
                        }


                        {this.state.stage === 'settings' &&

                        <div className="data-holder wf-hold settings-wrap" id="proSettings" >
                            <div className="side-tab">
                                <div className="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className= {this.state.settngs === 'display' ? 'nav-link-side active' : 'nav-link-side' } id="v-pills-home-tab" data-toggle="pill"
                                       onClick={()=>this.handleSettingChange('display')} role="tab" aria-controls="v-pills-home" aria-selected={this.state.settngs === 'display' ? 'true' : 'false' }>Display
                                        Settings</a>
                                    <a className = {this.state.settngs === 'permission' ? 'nav-link-side active' : 'nav-link-side' } id="v-pills-profile-tab" data-toggle="pill"
                                       onClick={()=>this.handleSettingChange('permission')} role="tab" aria-controls="v-pills-profile"
                                       aria-selected= {this.state.settngs === 'permission' ? 'true' : 'false' }>Permissions</a>
                                    <a className= {this.state.settngs === 'advanced' ? 'nav-link-side active' : 'nav-link-side' } id="v-pills-messages-tab" data-toggle="pill"
                                       onClick={()=>this.handleSettingChange('advanced')} role="tab" aria-controls="v-pills-messages"
                                       aria-selected= {this.state.settngs === 'advanced' ? 'true' : 'false' }>Advanced Settings</a>
                                </div>
                                <div className="tab-content" id="v-pills-tabContent">
                                    {this.state.settngs === 'display' &&
                                    <div className = {this.state.settngs === 'display' ? 'tab-pane fade  show active' : 'tab-pane fade' } id="v-pills-home" role="tabpanel"
                                         aria-labelledby="v-pills-home-tab">
                                        <div className="tab-wrap-block">
                                            <h5>Display Settings</h5>
                                            <p>Select the fields to be displayed in the workflow instances</p>

                                            <div className="next-stage-selector">
                                                {workflowFormDetails.data.map ((fields,index) =>
                                                    <>
                                                {fields.form.pages.map((datalist,index) =>
                                                    <>
                                                        {datalist.components.map((datalabel,index) =>
                                                            <>
                                                                {workflowInvokeDetails.workflowinfo.map((invhdr,index) =>

                                                            <div  className = 'select-chk-block' style={{display: 'block'}}>
                                                                <label className="control control-checkbox">{datalabel.label}

                                                                <input type="radio" className="inp-chk"
                                                                           name="text_searchable" onChange={e => this.handleDisplaystngs(datalabel.masterid)} value= {datalabel.masterid} defaultChecked={invhdr._source.invoke_show_header == datalabel.masterid ? 'checked' : ''}/>


                                                                        <div className="control-indicator"></div>
                                                                </label>
                                                            </div>

                                                                    )}
                                                            </>

                                                        )}
                                                    </>
                                                )}
                                                </>
                                                )}

                                                <div className="task-submit-block" style={{display: this.state.displayStngsId === '' ? 'none' : 'block' }}>
                                                    <button className="general-btn" onClick={e => this.saveDisplaysettings()}
                                                            id="saveinvokeheader">{this.state.Displabel}
                                                    </button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {this.state.settngs === 'permission' &&
                                    <div  className = {this.state.settngs === 'permission' ? 'tab-pane fade  show active' : 'tab-pane fade' }  id="v-pills-profile" role="tabpanel"
                                         aria-labelledby="v-pills-profile-tab">..B.
                                    </div>
                                    }
                                    {this.state.settngs === 'advanced' &&
                                    <div  className = {this.state.settngs === 'advanced' ? 'tab-pane fade  show active' : 'tab-pane fade' } id="v-pills-messages" role="tabpanel"
                                         aria-labelledby="v-pills-messages-tab">.C..
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        }


                        {this.state.stage === 'actiontemplates' &&
                        <div className="data-holder wf-hold file-wrap" id="proAction">
                        </div>
                        }
                    </div>
                </div>

                <div className="modal bsy-modal medium-lg-mod full-sc-mod" id="settingsModal" tabIndex="-1"
                     role="dialog" aria-labelledby="mngModal" aria-hidden="true" style={{display: this.state.showModalStage === true ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="mngModal">{this.state.showWorkflowname}</h5>
                                <button className="ico-lib-list modal-close" onClick={(e)=>this.editstgModalclose()}
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="stage-container">
                                    <div className="stage-left">
                                        <ul className="step-list">
                                            <li className={this.state.editStage === 'task' ? 'active' : 'completed' }>
                                                <a onClick={()=>this.handleStateStage('task')} className="step-click">Task</a>
                                            </li>
                                            <li className={this.state.editStage === 'forms' ? 'active' : 'completed' }>
                                                <a onClick={()=>this.handleStateStage('forms')} className="step-click">Forms</a>
                                            </li>
                                            <li className={this.state.editStage === 'custom' ? 'active' : 'completed' }>
                                                <a onClick={()=>this.handleStateStage('custom')} className="step-click">Custom Fields</a>
                                            </li>
                                            <li className={this.state.editStage === 'autoaction' ? 'active' : 'completed'}>
                                                <a onClick={()=>this.handleStateStage('autoaction')} className="step-click">Automated
                                                    Actions</a>
                                            </li>
                                            <li className={this.state.editStage === 'nextstage' ? 'active' : 'completed'}>
                                                <a onClick={()=>this.handleStateStage('nextstage')} className="step-click">Next Stage</a>
                                            </li>
                                            <li className={this.state.editStage === 'statusmapp' ? 'active' : 'completed'}>
                                                <a onClick={()=>this.handleStateStage('statusmapp')} className="step-click">Status Mapping</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="stage-right">
                                        {this.state.editStage === "task" &&
                                        <div className="stage-steps" id="stTask" >
                                            <div className="stage-form-container">
                                                <div className="task-inp-container">
                                                    <div className="ind-task-input">
                                                        <span className="lb-tx">To</span>
                                                        <div className="tag-wrapper-bl">
                                                            <div className="sl-tag">
                                                                <span className="tag-left">T</span>
                                                                <span className="tag-right">Trevor Hansen <button
                                                                    className="tag-cl">×</button></span>
                                                            </div>
                                                            <input type="text" className="width-dynamic proba dva"
                                                                   placeholder="" style={{width: "0px"}}/>
                                                        </div>
                                                        <ul className="select-drop" style={{display: "none"}}>
                                                            <li>
                                                                <a href="javascript:void(0);"
                                                                   className="select-drop-click">
                                                                    <div className="select-drop-click-left">
                                                                        <img src="assets/images/man.png"/>
                                                                    </div>
                                                                    <div className="select-drop-click-right">
                                                                        <h5>Dhanya Ninan</h5>
                                                                        <p>Senior Developer, LogicalSteps</p>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="javascript:void(0);"
                                                                   className="select-drop-click">
                                                                    <div className="select-drop-click-left">
                                                                        <img src="assets/images/man.png"/>
                                                                    </div>
                                                                    <div className="select-drop-click-right">
                                                                        <h5>Mahesh Babu</h5>
                                                                        <p>Senior Manager, Aditya Birla Money</p>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="ind-task-input" style={{display: "none"}} id="cc">
                                                        <span className="lb-tx">Cc</span>
                                                        <div className="tag-wrapper-bl">
                                                            <input type="text" className="width-dynamic proba dva"
                                                                   placeholder="" style={{width: "0px;"}}/>
                                                        </div>
                                                    </div>
                                                    <div className="create-button-block">
                                                        <button className="trans-cr-button cc">Cc</button>
                                                    </div>
                                                </div>
                                                <div className="new-task-input">
                                                    <input type="text" name="subject" placeholder="Subject" onChange={this.updateTask} value={atob(task.length !==0?task[0].subject:'')}/>
                                                </div>
                                                <div className="task-option-container">
                                                    <div className="duedate-block">
                                                        <p>Due in <span className="due-count"></span></p>
                                                    </div>
                                                    <div className="input-count-block">
                                                        <input type="number" min="0" id="stage_due" name={`duein`} onChange={this.updateTask} value={(task.length !==0?task[0].duein:'')}/>
                                                    </div>
                                                    <div className="select-box">
                                                        <select id="stage_task_priority" name="priority" value={task.length !==0?task[0].priority:''} onChange={this.updateTask}>
                                                            <option value="All">Priority</option>
                                                            <option value="Low">Low</option>
                                                            <option value="Medium">Medium</option>
                                                            <option value="High">High</option>
                                                            <option value="Urgent">Urgent</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="task-option-container wf-tags">
                                                    <div className="multi-select-block drop-top texttag" id="">
                                                        <select multiple>
                                                            <option value="">Select Tags</option>
                                                            <option value="1">Light Space</option>
                                                            <option value="2">Silverbullet</option>
                                                            <option value="3">Vignets</option>
                                                            <option value="4">Bsynapse</option>
                                                            <option value="5">Onedeck</option>
                                                            <option value="6">Light Space</option>
                                                            <option value="7">Silverbullet</option>
                                                            <option value="8">Vignets</option>
                                                            <option value="9">Bsynapse</option>
                                                            <option value="10">Onedeck</option>
                                                            <option value="1">Light Space</option>
                                                            <option value="12">Silverbullet</option>
                                                            <option value="13">Vignets</option>
                                                            <option value="14">Bsynapse</option>
                                                            <option value="15">Onedeck</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="new-task-input text-input">
                                                    <textarea className="task-textarea" name={`instruction`}
                                                              placeholder="instruction" onChange={this.updateTask}  value={atob(task.length !==0?task[0].instruction:'')}></textarea>
                                                </div>
                                            </div>
                                            <div className="stage-footer">
                                                <div className="dropdown">
                                                    <button className="options-button attach black-shade" type="button"
                                                            id="dropdownMenuoption" data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false"></button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuoption">
                                                        <a className="dropdown-item ico-lib fm-attach" href="#">Attach
                                                            File</a>
                                                        <a className="dropdown-item ico-lib fm-check" href="#">Add
                                                            Checklist</a>
                                                        <a className="dropdown-item ico-lib fm-form" href="#">Attach
                                                            Form</a>
                                                        <a className="dropdown-item ico-lib fm-res" href="#">Attach
                                                            Resources</a>
                                                        <a className="dropdown-item ico-lib fm-tp" href="#">Tag
                                                            Product</a>
                                                        <a className="dropdown-item ico-lib fm-tpro" href="#">Tag
                                                            Project</a>
                                                    </div>
                                                </div>
                                                <div className="dropdown builder-pop">
                                                    <button className="options-button build" type="button"
                                                            id="dropdownMenuBuild" data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false"></button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuBuild">
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib fs-ico"></span>
                                                            <span className="field-shade-text">Fieldset</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib tx-ico"></span>
                                                            <span className="field-shade-text">Text</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib ta-ico"></span>
                                                            <span className="field-shade-text">Text Area</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib rb-ico"></span>
                                                            <span className="field-shade-text">Radio Button</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib cb-ico"></span>
                                                            <span className="field-shade-text">Checkbox</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib dp-ico"></span>
                                                            <span className="field-shade-text">Datepicker</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib dt-ico"></span>
                                                            <span className="field-shade-text">Date &amp; Time</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib nm-ico"></span>
                                                            <span className="field-shade-text">Number</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib rt-ico"></span>
                                                            <span className="field-shade-text">Rich Text</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib dd-ico"></span>
                                                            <span className="field-shade-text">Dropdown</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib fu-ico"></span>
                                                            <span className="field-shade-text">File Upload</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib pg-ico"></span>
                                                            <span className="field-shade-text">Paragraph</span>
                                                        </button>
                                                        <button className="ind-field-button-type">
                                                            <span className="field-shade ico-lib hd-ico"></span>
                                                            <span className="field-shade-text">Header</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {this.state.editStage === "forms" &&
                                        <div className="stage-steps form-im-wrap" id="stInputform">
                                            <div className="stage-form-container">
                                                <div className="default-placeholder">
                                                    <h5>Import Form. Embed to Stage</h5>
                                                    <p>Import share forms or embed forms in your stages</p>
                                                </div>
                                                <div className="stage-form-block">
                                                    <div className="stage-form-left">
                                                        <div className="form-list-wrapper">
                                                            {Collections.map((list, index) =>
<>

                                                                {this.state.selectForm == false  && this.state.formidArr.includes(list.cid) ?
                                                                    <div className="ind-form-list">
                                                                <div className="resources-card-check">
                                                                    <label
                                                                        className="control control-checkbox list-check-rc">
                                                                        <input type="checkbox" className="inp-chk" id={list.cid}/>
                                                                        <div className="control-indicator"></div>
                                                                    </label>
                                                                </div>
                                                                <div className="form-info-side">
                                                                    <h5>{atob(list.title)}</h5>
                                                                    <ul className="form-info">
                                                                        <li className="ico-lib form-fm-ico">{list.childs}</li>
                                                                        <li className="ico-lib share-fm-ico">{list.share}</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="action-block">
                                                                    <button className="ico-lib remove-minus"></button>
                                                                </div>
                                                                    </div> :
                                                                    ''
                                                                    }

</>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="stage-form-right">
                                                        <div className="imported-input-detail">
                                                            <div className="form-preview-container">
                                                                <div className="preview-header">
                                                                    <h5>Form Name Here</h5>
                                                                </div>
                                                                <div className="ind-form-field">
                                                                    <div className="closed-box">
                                                                        <div className="input-block">
                                                                            <input type="text" name=""
                                                                                   className="fiels-inp-box"
                                                                                   placeholder="Full Name Here"/>
                                                                        </div>
                                                                        <div className="input-block">
                                                                            <input type="text" name=""
                                                                                   className="fiels-inp-box"
                                                                                   placeholder="Phone Number"/>
                                                                        </div>
                                                                        <div className="input-block non-text">
                                                                            <label>E-Mail Address</label>
                                                                            <input type="text" name=""
                                                                                   className="fiels-inp-box"
                                                                                   placeholder="Enter your email address here"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="field-set">
                                                                    <div className="ind-form-field">
                                                                        <h5>Fieldset Name Here</h5>
                                                                        <div className="closed-box">
                                                                            <div className="input-block">
                                                                                <input type="text" name=""
                                                                                       className="fiels-inp-box"
                                                                                       placeholder="Text Box Name Here"/>
                                                                            </div>
                                                                            <div className="input-block">
                                                                                <input type="text" name=""
                                                                                       className="fiels-inp-box"
                                                                                       placeholder="Alternate Phone Number"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stage-footer">
                                                <button className="options-button plus" type="button" id = "taskFormAdd"  onClick={(e)=>this.WorkflowFormAdd('stageforms')}
                                                        data-toggle="modal" data-target="#newInput">ddd</button>
                                            </div>
                                        </div>
                                        }
                                        {this.state.editStage === "custom" &&
                                        <div className="stage-steps" id="stInputmeta">

                                        <ListAllCustomFields
                                            allcustomfields={this.state.mode === 'new' ? this.props.workflowStageTaskInfo.stagetaskinfo.metaform : this.props.workflowStageTaskInfo.stagetaskinfo.metaform}
                                            metaForm={this.metaForm}
                                            getCustomField={this.getCustomField}
                                            removeClick={this.removeClick}
                                        />
                                            <div className="stage-footer">
                                                <button onClick={() => this.getCustomField('', '', '', '', '', '', 'new','')} className="options-button plus" type="button"
                                                        data-toggle="modal" data-target="#addField"></button>
                                            </div>
                                        </div>
                                        }



                                        <div className="stage-steps" id="stAction" style={{display: "none"}}>
                                            <div className="stage-form-container">

                                            </div>
                                            <div className="stage-footer">
                                                <div className="field-add-block">
                                                    <div className="dropdown">
                                                        <button className="options-button plus" type="button"
                                                                id="dropdownMenu2" data-toggle="dropdown"
                                                                aria-haspopup="true" aria-expanded="false"></button>
                                                        <div className="dropdown-menu action-menu"
                                                             aria-labelledby="dropdownMenu2" x-placement="bottom-start"
                                                             style={{position: "absolute;", transform: "translate3d(0px, 55px, 0px);", top: "0px;", left: "0px"}}>
                                                            <button className="ind-field-button-type">
                                                                <span className="field-shade ico-lib em-ico"></span>
                                                                <span className="field-shade-text">E-Mail</span>
                                                            </button>
                                                            <button className="ind-field-button-type">
                                                                <span className="field-shade ico-lib sms-ico"></span>
                                                                <span className="field-shade-text">SMS</span>
                                                            </button>
                                                            <button className="ind-field-button-type">
                                                                <span className="field-shade ico-lib api-ico"></span>
                                                                <span className="field-shade-text">API</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stage-steps" id="stTemplate" style={{display: "none"}}>
                                            <div className="stage-form-container">
                                                <div className="stage-steps-header">
                                                    <div className="stage-steps-header-left">
                                                        <h5>Choose E-Mail Template</h5>
                                                    </div>
                                                    <div className="search-box-template">
                                                        <input type="text" name="" placeholder="Search Templates…"/>
                                                    </div>
                                                </div>
                                                <div className="stage-steps-under">
                                                    <div className="template-container">
                                                        <div className="ind-template-list">
                                                            <div className="template-thumb">
                                                                <img src="assets/images/animal.jpg"/>
                                                            </div>
                                                            <div className="template-desc">
                                                                <h5>E-Mail Template Name Here</h5>
                                                            </div>
                                                        </div>
                                                        <div className="ind-template-list active">
                                                            <div className="template-thumb">
                                                                <img src="assets/images/animal.jpg"/>
                                                            </div>
                                                            <div className="template-desc">
                                                                <h5>E-Mail Template Name Here</h5>
                                                            </div>
                                                        </div>
                                                        <div className="ind-template-list">
                                                            <div className="template-thumb">
                                                                <img src="assets/images/animal.jpg"/>
                                                            </div>
                                                            <div className="template-desc">
                                                                <h5>E-Mail Template Name Here</h5>
                                                            </div>
                                                        </div>
                                                        <div className="ind-template-list">
                                                            <div className="template-thumb">
                                                                <img src="assets/images/animal.jpg"/>
                                                            </div>
                                                            <div className="template-desc">
                                                                <h5>E-Mail Template Name Here</h5>
                                                            </div>
                                                        </div>
                                                        <div className="ind-template-list">
                                                            <div className="template-thumb">
                                                                <img src="assets/images/animal.jpg"/>
                                                            </div>
                                                            <div className="template-desc">
                                                                <h5>E-Mail Template Name Here</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stage-footer">
                                                <div className="field-add-block">
                                                    <div className="dropdown">
                                                        <button className="options-button plus" type="button"
                                                                id="dropdownMenu2" data-toggle="dropdown"
                                                                aria-haspopup="true" aria-expanded="false"></button>
                                                        <div className="dropdown-menu action-menu"
                                                             aria-labelledby="dropdownMenu2" x-placement="bottom-start"
                                                             style={{ position: "absolute;", transform: "translate3d(0px, 55px, 0px);", top: "0px;", left: "0px;" }}>
                                                            <button className="ind-field-button-type">
                                                                <span className="field-shade ico-lib em-ico"></span>
                                                                <span className="field-shade-text">E-Mail</span>
                                                            </button>
                                                            <button className="ind-field-button-type">
                                                                <span className="field-shade ico-lib sms-ico"></span>
                                                                <span className="field-shade-text">SMS</span>
                                                            </button>
                                                            <button className="ind-field-button-type">
                                                                <span className="field-shade ico-lib api-ico"></span>
                                                                <span className="field-shade-text">API</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stage-steps action-wrap" id="stAutoAction"
                                             style={{display: "none"}} >
                                            <div className="stage-form-container">
                                                <div className="stage-steps-header">
                                                    <div className="stage-steps-header-left">
                                                        <h5>Create New Template</h5>
                                                    </div>
                                                </div>
                                                <div className="stage-steps-under no-pad">
                                                    <div className="tab-container-step">
                                                        <div className="template-input">
                                                            <input type="text" name="" placeholder="Template Name"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stage-footer">
                                                <button type="button" className="general-btn">Save Template</button>
                                            </div>
                                        </div>
                                        {this.state.editStage === "autoaction" &&
                                        <div className="stage-steps action-wrap" id="stAutoAction">
                                            <div className="stage-form-container">
                                                <div className="stage-steps-header">
                                                    <div className="stage-steps-header-left">
                                                        <h5>Actions Template</h5>
                                                    </div>
                                                </div>
                                                <div className="stage-steps-under">
                                                    <div className="accordion step-accord" id="accordionExample">
                                                        <div className="card">
                                                            <div className="card-header" id="headingOne">
                                                                <h5 className="mb-0">
                                                                    <button className="template-tab ico-lib url-ico"
                                                                            type="button" data-toggle="collapse"
                                                                            data-target="#collapseOne"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapseOne">Send SMS
                                                                    </button>
                                                                </h5>
                                                            </div>
                                                            <div id="collapseOne" className="collapse show"
                                                                 aria-labelledby="headingOne"
                                                                 data-parent="#accordionExample">
                                                                <div className="card-body">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingTwo">
                                                                <h5 className="mb-0">
                                                                    <button
                                                                        className="template-tab ico-lib url-ico collapsed"
                                                                        type="button" data-toggle="collapse"
                                                                        data-target="#collapseTwo" aria-expanded="false"
                                                                        aria-controls="collapseTwo">Send E-Mail
                                                                    </button>
                                                                </h5>
                                                            </div>
                                                            <div id="collapseTwo" className="collapse"
                                                                 aria-labelledby="headingTwo"
                                                                 data-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    <div className="tab-container-step">
                                                                        <div className="template-input">
                                                                            <input type="text" name=""
                                                                                   placeholder="From"/>
                                                                        </div>
                                                                        <div className="template-input">
                                                                            <input type="text" name=""
                                                                                   placeholder="Subject"/>
                                                                        </div>
                                                                        <div className="template-input text-block">
                                                                            <textarea></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header" id="headingThree">
                                                                <h5 className="mb-0">
                                                                    <button
                                                                        className="template-tab ico-lib url-ico collapsed"
                                                                        type="button" data-toggle="collapse"
                                                                        data-target="#collapseThree"
                                                                        aria-expanded="false"
                                                                        aria-controls="collapseThree">Invoke API
                                                                    </button>
                                                                </h5>
                                                            </div>
                                                            <div id="collapseThree" className="collapse"
                                                                 aria-labelledby="headingThree"
                                                                 data-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    <div className="tab-container-step">
                                                                        <div className="split-input">
                                                                            <div className="float-select">
                                                                                <label htmlFor="fl-sl">Select
                                                                                    Assignee</label>
                                                                                <select name="fl-sl"
                                                                                        placeholder="Select Assignee"
                                                                                        onChange="changeFloatSelect(this)">
                                                                                    <option value=""
                                                                                            selected="selected"></option>
                                                                                    <option value="1">one</option>
                                                                                    <option value="2">two</option>
                                                                                    <option value="3">three</option>
                                                                                    <option value="4">four</option>
                                                                                    <option value="5">five</option>
                                                                                    <option value="6">six</option>
                                                                                    <option value="7">seven</option>
                                                                                    <option value="8">eight</option>
                                                                                    <option value="9">nine</option>
                                                                                    <option value="10">ten</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="template-input">
                                                                                <input type="text" name=""
                                                                                       placeholder="API URL (API to get assignees)"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="split-input eq-wd">
                                                                            <div className="template-input">
                                                                                <input type="text" name=""
                                                                                       placeholder="Parameter Name"/>
                                                                            </div>
                                                                            <div className="template-input">
                                                                                <input type="text" name=""
                                                                                       placeholder="API URL (API to get assignees)"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="split-input eq-wd">
                                                                            <div className="template-input">
                                                                                <input type="text" name=""
                                                                                       placeholder="Parameter Name"/>
                                                                            </div>
                                                                            <div className="template-input">
                                                                                <input type="text" name=""
                                                                                       placeholder="API URL (API to get assignees)"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stage-footer">
                                                <div className="field-add-block">
                                                    <div className="dropdown">
                                                        <button className="options-button plus" type="button"
                                                                id="dropdownMenu2" data-toggle="dropdown"
                                                                aria-haspopup="true" aria-expanded="false"></button>
                                                        <div className="dropdown-menu action-menu"
                                                             aria-labelledby="dropdownMenu2" x-placement="bottom-start"
                                                             style={{position: "absolute;" ,  transform: "translate3d(0px, 55px, 0px);" , top: "0px;" , left: "0px;"}}>
                                                            <button className="ind-field-button-type">
                                                                <span className="field-shade ico-lib em-ico"></span>
                                                                <span className="field-shade-text">E-Mail</span>
                                                            </button>
                                                            <button className="ind-field-button-type">
                                                                <span className="field-shade ico-lib sms-ico"></span>
                                                                <span className="field-shade-text">SMS</span>
                                                            </button>
                                                            <button className="ind-field-button-type">
                                                                <span className="field-shade ico-lib api-ico"></span>
                                                                <span className="field-shade-text">API</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {this.state.editStage === "nextstage" &&
                                        <div className="stage-steps" id="stFinal">
                                            <div className="stage-form-container">
                                                <div className="stage-steps-header">
                                                    <div className="stage-steps-header-left">
                                                        <h5>Select the next possible stage(s)</h5>
                                                    </div>
                                                    <div className="radio-wrapper-new">
                                                        <div className="radio-block">
                                                            <input type="radio" id="singlestep" className="off_type"
                                                                   name="stageforward"  value="single" onClick={(e)=>this.Nextstageforward('single')}/>
                                                                <label htmlFor="singlestep">Single</label>
                                                        </div>
                                                        <div className="radio-block">
                                                            <input type="radio" id="parallelstep" className="off_type"
                                                                   name="stageforward"  value="parallel" onClick={(e)=>this.Nextstageforward('parallel')} />
                                                                <label htmlFor="parallelstep">Parallel</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stage-steps-under">
                                                    <div className="next-stage-selector">
                                                        {this.props.workflowDetailsData.WorkFlowDetails.data.stageinfo.map((list,index) =>

                                                        <div className= {this.state.showWorkflowid == list._id  ? "select-chk-block selected" : "select-chk-block"}
                                                             style={{display: "block"}}>
                                                            <label className="control control-checkbox">{list._source.name}
                                                                { this.state.showWorkflowid == list._id ?
                                                                    <span
                                                                        className="sl-txt vanishStage">Current Stage</span>:''
                                                                }
                                                                <p>{stageDetails.nextstage[0]}</p>
                                                            <input type="checkbox" className="inp-chk"
                                                                       id="text_searchable"  defaultChecked={stageDetails.nextstage[0].includes(list._id)  ||  this.state.showWorkflowid == list._id ? 'checked' : ''}  value={list._id}
                                                                      />
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stage-footer">
                                                <div className="field-add-block">
                                                    <button className="options-button plus" type="button"
                                                            id="dropdownMenu2" data-toggle="modal"
                                                            data-target="#addStage"></button>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        {this.state.editStage === "statusmapp" &&
                                        <div className="stage-steps" id="stFinal">
                                            <div className="stage-form-container">
                                                <div className="stage-steps-header">
                                                    <div className="stage-steps-header-left">
                                                        <h5>Select the status to update</h5>
                                                    </div>
                                                </div>
                                                <div className="stage-steps-under">
                                                    <div className="next-stage-selector">
                                                        {workflowFormDetails.data.map ((statusmap,index) =>
                                                            <>
                                                        {statusmap.collectioninfo.formstatus.map((datalist,index) =>
                                                        <div className="select-chk-block"
                                                             style={{display: "block"}}>

                                                            <label className="control control-checkbox">{datalist.value}
                                                                <input type="radio" className="inp-chk"
                                                                       name = "text_searchable"  onClick={(e)=>this.statusMap(datalist.value)} value={datalist.value}
                                                                       />
                                                                <div className="control-indicator"></div>
                                                            </label>

                                                        </div>
                                                        )}
                                                        </>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="stage-footer">
                                                <div className="field-add-block">
                                                    <button className="options-button plus" type="button"
                                                            id="dropdownMenu2" data-toggle="modal"
                                                            data-target="#addStage"></button>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="general-btn grey" data-dismiss="modal"  onClick={(e)=>this.editstgModalclose()}>Cancel</button>
                                <button type="button" className="general-btn" onClick={this.saveTaskData}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.modalStatus &&
                <SimpleModal
                    Close={this.getCustomField}
                >
                    <CustomFields
                        metaform={this.state.metaform}
                        savedata={this.saveFormData}
                        updateMetaFormField={this.updateMetaFormField}
                    />
                </SimpleModal>
                }

                {this.state.addTask &&
                <SimpleModal
                    Close={''}
                >
                    <div className="task-content-block single-block">
                        <div className="new-task-input">
                            <input type="text" id="stagename" name="stagename" placeholder="Name"/>
                        </div>
                        <div className="task-button-block">
                            <button id="createstage" data-workflow="1561221623450" data-mode="save" type="button"
                                    className="general-btn" onClick={this.addStage}>Create
                            </button>
                        </div>
                    </div>



                </SimpleModal>

                }
                {this.state.selectForm == true &&
                <SimpleModal
                    Close={this.Close}
                    title={'Import Form' }
                >
                    <Scrollbars style={{ width: 750, height: 550 }}>
                    <div>
                    {Collections.map((list, index) =>
                        <div className="ind-form-list">
                                <div className="resources-card-check">
                                    <label
                                        className="control control-checkbox list-check-rc" >
                                        <input type="radio" className="inp-chk" name = "formslect" id={list.cid} />
                                        <div className="control-indicator"  id={list.cid}  onClick={(e)=>this.formSelected(list.cid)} ></div>
                                    </label>
                                </div>
                                <div className="form-info-side">
                                    <h5>{atob(list.title)}</h5>
                                    <ul className="form-info">
                                        <li className="ico-lib form-fm-ico">{list.childs}</li>
                                        <li className="ico-lib share-fm-ico">{list.share}</li>
                                    </ul>
                                </div>
                        </div>
                    )}

                        <div className="modal-footer">
                            <button type="button" className="general-btn grey" data-dismiss="modal">Cancel</button>
                            <button type="button" className="general-btn" data-mode="save" id="saveforminput" onClick={this.addStageForm()}>Import
                            </button>
                        </div>
                    </div>
                    </Scrollbars>
                </SimpleModal>
                }
                {this.state.selectInputForm == true &&
                <SimpleModal
                    Close ={()=> this.importformclose()}
                    title={'Import Form' }
                >
                    <Scrollbars style={{ width: 750, height: 550 }}>
                        <div>
                            {Collections.map((list, index) =>
                                <div className="ind-form-list">
                                    <div className="resources-card-check">
                                        <label
                                            className="control control-checkbox list-check-rc" >
                                            <input type="radio" className="inp-chk" name = "formslect" id={list.cid} />
                                            <div className="control-indicator"  id={list.cid}  onClick={(e)=>this.InputformSelected(list.cid)} ></div>
                                        </label>
                                    </div>
                                    <div className="form-info-side">
                                        <h5>{atob(list.title)}</h5>
                                        <ul className="form-info">
                                            <li className="ico-lib form-fm-ico">{list.childs}</li>
                                            <li className="ico-lib share-fm-ico">{list.share}</li>
                                        </ul>
                                    </div>
                                </div>
                            )}

                            <div className="modal-footer">
                                <button type="button" className="general-btn grey" data-dismiss="modal">Cancel</button>
                                <button type="button" className="general-btn" data-mode="save" id="saveforminput" onClick={this.addInputForm}>Import
                                </button>
                            </div>
                        </div>
                    </Scrollbars>
                </SimpleModal>
                }




            </div>

        );
    }
}



const mapStateToProps = state => ({

    listAllWorkFlowData:state.workflowReducer.listallWorkFlow,
    workflowStageinfoData:state.workflowReducer.workflowStageinfo,
    workflowUserinfoData:state.workflowReducer.workflowUserinfo,
    workflowDetailsData:state.workflowReducer.workflowDetails,
    workflowFormDetailsData:state.workflowReducer.workflowFormDetails,
//    workflowStageTaskInfo:state.workflowReducer.workflowStageTaskInfo,
    workflowInvokeDetailsData:state.workflowReducer.workflowInvokeDetails,
    workflowStageTaskInfo: state.workflowReducer.workflowStageTaskData,
    listAllCollectionsData: state.CollectionsReducer.listCollectionsdata,
    saveWorkflowCollectionData : state.workflowReducer.saveWorkflowCollection

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    listworkflow:WorkFlowActions.listWorkFlow,
    workflowstageinfo:WorkFlowActions.WorkflowStageinfo,
    workflowuserinfo:WorkFlowActions.WorkflowUserinfo,
    workflowdetails:WorkFlowActions.WorkflowDetails,
    workflowformdetails:WorkFlowActions.WorkflowFormDetails,
    workflowstagetaskinfo:WorkFlowActions.StageTaskInfo,
    workflowinvokedetails:WorkFlowActions.InvokeDetails,
    Saveworkflowcollection:WorkFlowActions.Saveworkflowcollection,
    SavestageTask: WorkFlowActions.SavestageTask,
    SaveDisplaysetngs: WorkFlowActions.SaveDisplaysettings,
    updateMetaform:WorkFlowActions.UpdateMetaTask,
    updateMetaFormField:WorkFlowActions.updateMetaFormField,
    updateTask:WorkFlowActions.updateTask,
    collections: collectionActions.listallcollections
})(WorkflowDetails));


