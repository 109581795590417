import React from 'react';
import Signature from '../../lib/react-s3/Signature'
import Policy from '../../lib/react-s3/Policy'
import {xAmzDate, dateYMD} from '../../lib/react-s3/Date'
import PropTypes, {element} from 'prop-types';
import ReactDOM from 'react-dom';
import ProgressBars from './ProgressBar';
import { BUCKET_CONFIG } from '../../constants/index';
import { withRouter } from 'react-router';
import * as uploadActions from "../../actions/Upload";
import {connect} from "react-redux";
import {containerIsInView} from "../CustomFunctions"

// import * as uploadReducers from "../../reducers/Upload";


var progressPercentage =0;
var progressObj;
var progressId = 'progress-bar-wrapper';
class ReactS3 {
    static upload(file, config, fn, progressId) {

        const fd = new FormData();
        const key = `${config.albumName ? config.albumName + '/' : ''}${fn}`;
        const url = `https://${config.bucketName}.s3.amazonaws.com/`
        fd.append('key', key);
        fd.append('acl', 'public-read');
        fd.append('Content-Type', file.type);
        fd.append('x-amz-meta-uuid', '14365123651274');
        fd.append('x-amz-server-side-encryption', 'AES256')
        fd.append('X-Amz-Credential', `${config.accessKeyId}/${dateYMD}/${config.region}/s3/aws4_request`);

        fd.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
        fd.append('X-Amz-Date', xAmzDate)
        fd.append('x-amz-meta-tag', '');
        fd.append('Policy', Policy.getPolicy(config))
        fd.append('X-Amz-Signature', Signature.getSignature(config, dateYMD, Policy.getPolicy(config)));
        fd.append("file", file);

        return new Promise(async(resolve, reject) => {

            let xhr = new XMLHttpRequest();

            xhr.addEventListener("progress", updateProgress);
            xhr.addEventListener("timeout", timeOutUpload);

            xhr.upload.addEventListener("loadstart", startUpload, false);
            xhr.upload.addEventListener("progress", updateProgress, false);
            xhr.upload.addEventListener("load", uploadCompleted, false)


            xhr.open('POST', url);
            xhr.onload = function () {
                console.log(config)
                if (this.status >= 200 && this.status < 300) {
                    //resolve(xhr.response);
                    resolve({
                        bucket: config.bucketName,
                        key: `${config.albumName ? config.albumName + '/' : ''}${fn}`,
                        location: `${url}${config.albumName ? config.albumName +'/' : ''}${fn}`,
                        result: xhr.response
                    })

                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send(fd);


            function startUpload (e) {
                if(document.getElementById(progressId))  {
                    progressObj = ReactDOM.render(React.createElement(ProgressBars), document.getElementById(progressId));
                }
                else{
                    var node = document.createElement("div");
                    node.id = progressId;
                    if(document.getElementById('todoFileAssets')) {
                        document.getElementById('todoFileAssets').append(node);
                        progressObj = ReactDOM.render(React.createElement(ProgressBars), document.getElementById(progressId));
                    }

                }

                var elmnt = document.getElementById(progressId);
                if(elmnt) {
                    !containerIsInView( elmnt ) && elmnt.scrollIntoView();
                }
            }

            function updateProgress (e) {
                if (e.lengthComputable) {
                    var percentComplete = e.loaded / e.total * 100;
                    if(progressObj)
                    progressObj.onProgress( percentComplete);

                } else {
                    // Unable to compute progress information since the total size is unknown
                }
            }

            function uploadCompleted (e) {
                ////////console.log("üpload completed");
            }

            function timeOutUpload() {

                document.body.classList.remove('layover-bg');
                var element = document.getElementById(progressId);
                ReactDOM.unmountComponentAtNode(element);
            }
        })

    }
}

class UploadFile extends React.Component{
    constructor(props){
        super();
        this.state = {
            qData: '',
            uploadQ: {},
            imageHolder:true,
            selectedFile: null,
            completed: 5

        }
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
        // this.onUploadProgress = this.onUploadProgress.bind(this);
    }
    componentWillMount(){
        this.setState({uploadQ:{}})
    }
    componentDidMount() {
        let dat={}
        this.setState({uploadQ:dat})
        this.setState({imgurl:this.props.imgurl})
    }


    onUploadSuccess(r){
        let uploadedFile = { originalfileName: this.state.selectedFile.name,  fileName : r.location, fileSize : this.state.selectedFile.size, fileType : this.state.selectedFile.type , fileId : new Date().getTime()}
//console.log(uploadedFile)
        this.setState({uploadedFile : uploadedFile});

        var index = this.props.fileIndex?this.props.fileIndex:0;
        let attributesValue = this.props.attributesValue ? this.props.attributesValue : '';
        console.log(attributesValue)
        this.props.getUploadedFile(uploadedFile,index,'completed', attributesValue);
//console.log("after")
        var element = document.getElementById(progressId);
        if(element)
            ReactDOM.unmountComponentAtNode(element);

        document.body.classList.remove('layover-bg');

       // ////////console.log("this.state.imgurl"+this.state.imgurl);
        this.setState({completed:100});

    }

    /*onUploadProgress(percent) {
        this.state.completed = percent;
        ////////console.log(this.state.completed);
    }*/

    onUploadError(r){
        ////////console.log(r);
    }

    fileDimensions=(fileWidth, fileHeight)=>{
        this.setState({fileWidth:fileWidth})
        this.setState({fileHeight:fileHeight})
    }

    fileChangedHandler(e){


        const file = e.target.files[0];
        this.setState({selectedFile: file, uploadstatus: 'uploading'});

        let invalid = 0;
        let errormessage = '';

        document.body.classList.add('layover-bg');
        this.props.uploadErrors({'error' : '', errormessage : '', fileType : ''});

        const config = BUCKET_CONFIG;
        let imageTypes =  ['jpg', 'jpeg', 'png', 'bmp', 'gif'];
        let videoTypes =  ['mp4'];
        let documentTypes = ['pdf','ppt','xlsx','doc','docx','xls'];

        const fnarr = file.name.toLowerCase().split('.');
        var ext = fnarr[fnarr.length-1];

        let albumName = 'documents';

        if(imageTypes.includes(ext)) {
            albumName = 'images';
        }
        else if(videoTypes.includes(ext)) {
            albumName = 'videos';
        }
        config.albumName = albumName;

        let filesize = (file.size)/1000;
        let attachmentsize = parseInt(this.props.attachmentsize);
        let disallowedFileTypes = this.props.disallowedFiles ?this.props.disallowedFiles:[];
        var fileWidth;
        var fileHeight;
        let attachmentwidth = parseInt(this.props.attachmentwidth);
        let attachmentheight = parseInt(this.props.attachmentheight);

        var index = this.props.fileIndex?this.props.fileIndex:0;
        //this.startS3Upload(file,config,ext)

        var that = this;
        if (e.target.files && e.target.files[0]) {

            if(disallowedFileTypes.length > 0 && disallowedFileTypes !== '' && disallowedFileTypes.includes(ext)){
                errormessage = 'Invalid file type';
                this.props.uploadErrors({'error' : 'invalid', 'errormessage' : errormessage, fileType : 'image'});
                this.props.showUploadErrors(errormessage, index);
                document.body.classList.remove('layover-bg');
            }
            else if(attachmentsize >0 && filesize > attachmentsize){
                errormessage = 'The size should be less than '+ attachmentsize;
                this.props.uploadErrors({'error' : 'invalid', 'errormessage' : errormessage, fileType : 'image'});
                this.props.showUploadErrors(errormessage, index);
                document.body.classList.remove('layover-bg');
            }
            else if(albumName === 'images') {
                console.log("images")
                var img = document.createElement("img");
                this.state.backgroundImageFile = e.target.files[0];
                document.body.classList.add('layover-bg');
                /******** Width and Height checking ******/
                img.onload = function () {
                    console.log("onload")
                    console.log(this.width + " " + this.height);
                    fileWidth = this.width;
                    fileHeight = this.height;

                    if (attachmentwidth > 0 || attachmentheight > 0) {
                        console.log(attachmentwidth)
                        if (fileWidth > attachmentwidth) {
                            invalid += 1;
                            errormessage = 'The width of the image should be less than ' + attachmentwidth;
                        } else if (fileHeight > attachmentheight) {
                            invalid += 1;
                            errormessage = 'The height of the image should be less than ' + attachmentheight;
                        }
                    }
                    if (invalid > 0) {
                        console.log('there is error' + invalid)
                        that.props.uploadErrors({'error': 'invalid', errormessage : errormessage, fileType: 'image'});
                        that.props.showUploadErrors(errormessage, index);
                        document.body.classList.remove('layover-bg');
                    }
                    else {
                        that.startS3Upload(file,config,ext)
                    }
                }
                var reader = new FileReader();
                reader.onloadend = function (ended) {
                    img.src = ended.target.result;
                }
                reader.readAsDataURL(e.target.files[0]);
            }
            else {
                this.startS3Upload(file,config,ext)
            }

        }
    }

    startS3Upload = (file, config, ext) => {
        console.log("**************************")
        const uniq_id = new Date().getTime() + "." + ext;
        const fn = uniq_id;

        let tmpobj = {'stat': 'uploading', 'fn': fn}

        this.state.uploadQ[fn] = tmpobj;

        progressId = this.props.progressId ? this.props.progressId : progressId;

        ReactS3.upload(file, config, fn, progressId)
            .then((data) => this.onUploadSuccess(data))
            .catch((err) => this.onUploadError(err))
    }
    render(){
        return(
            <input type="file" id="TodoFile" name="TodoFile[]" multiple="" onChange={this.fileChangedHandler} id={this.props.fileIndex} />
        )
    }
}

UploadFile.propTypes = {
    getUploadedFile : PropTypes.func,
    albumName: PropTypes.string,
    fileIndex : PropTypes.number,
    progressId : PropTypes.string,
    fileType : PropTypes.string,
    index : PropTypes.number
}


function mapStateToProps(state) {
    return {
        uploadData : state.UploadReducer.uploadData
    };
}

export default  withRouter(connect(mapStateToProps, {
    uploadErrors: uploadActions.uploadErrors
})(UploadFile));

export function  DirectFileUpload(file, ext){
    let imageTypes =  ['jpg', 'jpeg', 'png', 'bmp', 'gif'];
    let videoTypes =  ['mp4'];
    const config = BUCKET_CONFIG;
    let albumName="images";
    if(videoTypes.includes(ext)) {
        albumName = 'videos'
    }
    config.albumName = albumName;
    const filename = "fn"+new Date().getTime() + "."+ext;
    return ReactS3.upload(file, config, filename)
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.log(err);
        })

}

