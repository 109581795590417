import axios from 'axios'
import {commonService} from "./common.services";
import {ES_INDEX, NEW_VERSION,SHARED_LOGIN} from '../constants/index'

export const whatsapp = {
    getCollectedInfo,
    getUserChats,
    saveNewChat,
    syncToWhatsapp,
    getChatLeads,
    getActiveLeads,
    getAllLeads,
    getNewLeads,
    getFolUpLeads,
    takeChat,
    followUp,
    convertLeadToPatient,
    paymentInfo,
    LeadProgram,
    invoiceList,
    deleteWhatsappChat,
    getWhatsappTasks,
    generateOrderId,
    getFollowupCallsToday,
    resourceDetails,
    saveResourcesForm,
    getHelpText,
    getFlagList,
    sendInvoice
};


function getCollectedInfo(id, params){
    return commonService.sendHttpRequest('collection/' + id + '/collectedinfo', params, "POST");
}
function getUserChats(params){
    return commonService.sendHttpRequest('chat/whatsappchat', params, "GET");
}
function saveNewChat(params){
           let param={
               wMes:params.message,
               wTo:"whatsapp:"+params.receiver,
               index : ES_INDEX
           }
           axios.post("https://bsyntwilio.bsynapse.com/whatsreplymessaage",param)
    return commonService.sendHttpRequest('chat/savewhatsappchat', params, "POST");
}

function syncToWhatsapp(params){
    return commonService.sendHttpRequest('chat/synctowhatsapp', params, "POST");
}
function getChatLeads(params){
    return commonService.sendHttpRequest('chat/userlist', params, "GET");
}
function getActiveLeads(params){
    return commonService.sendHttpRequest('chat/mychat', params, "GET");
}
function getAllLeads(params){
    return commonService.sendHttpRequest('chat/channelusers', params, "GET");
}
function getNewLeads(params){
    return commonService.sendHttpRequest('chat/newsession', params, "GET");
}
function getFolUpLeads(params){
    return commonService.sendHttpRequest('patients/followupcalls', params, "GET");
}
function takeChat(params){
    return commonService.sendHttpRequest('chat/takechat', params, "POST");
}
function followUp(params){
    return commonService.sendHttpRequest('chat/movetofollow', params, "POST");
}
function convertLeadToPatient(params){
    return commonService.sendHttpRequest('patients/migrateleadtopatient', params, "GET");
}
function paymentInfo(params){
    return commonService.sendHttpRequest('patients/savepaymentinfo', params, "POST");
}
function invoiceList(params){
    return commonService.sendHttpRequest('custom/paymentlist', params, "GET");
}
function LeadProgram(params){
    return commonService.sendHttpRequest('patients/leadprogram', params, "GET");
}
function deleteWhatsappChat(params){
    return commonService.sendHttpRequest('chat/deletewhatsappchat', params, "POST");
}
function getWhatsappTasks(params){
    return commonService.sendHttpRequest('task/whatsapptask', params, "GET");
}
function generateOrderId(params){
    return commonService.sendHttpRequest('patients/generateorderid', params, "GET");
}
function getFollowupCallsToday(params){
    return commonService.sendHttpRequest('patients/followuptodayscall', params, "GET");
}
function resourceDetails(id, params){
    return commonService.sendHttpRequest('collection/' + id + '/collectedinfo', params, "POST");
}
function saveResourcesForm(params){
    return commonService.sendHttpRequest('collection/createdata', params, "POST");
}
function getHelpText(id, params){
    return commonService.sendHttpRequest('collection/' + id + '/collectedinfo', params, "POST");
}
function getFlagList(params) {
    return commonService.sendHttpRequest('collection/flagsettingslist', params, "GET");
}
function sendInvoice(params){
    return commonService.sendHttpRequest('patients/sendinvoice', params, "POST");
}