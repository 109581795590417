import React from 'react';
import axios from 'axios';
import {FormGroup} from 'react-bootstrap';
import { Draggable, state } from 'react-page-maker';
import RadialBarChart from "../views/charts/RadialChart";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment, isValidDate} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {ViewData} from "./ViewData";
import SettingsModal from "./SettingsModal";
const COLORS = [{color:'#B0B0B0'}, {color:'#6ED4B9'}, {color:'#76A6F0'},{color:'#DB1238'}];

let graphData= [
  {
    "x": "2018 Q1",
    "y": "500",
    "z": "2018 Q1"
  },
  {
    "x": "2018 Q2",
    "y": "620",
    "z": "2018 Q2"
  },
  {
    "x": "2018 Q3",
    "y": "750",
    "z": "2018 Q3"
  },
]


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class RadialBarChartContainer extends React.Component {
  constructor(props,context) {
    super(props,context);
    var d = new Date();
    var cid = d.getTime();
    this.state={
      modalStatus:false,
      showColorPicker:false,
      background:'green',
      payload:{
        chartTitle:"Title",
        subTitle:"subtitle",
        subTitleEnabled : false,
        radialData:graphData,
        cardvalue:0,
        collection:"",
        fields:"",
        aggregation:'',
        graph:false,
        bordercolor:"",
        x:"",
        y:"",
        z:"",
        xstroke:"",
        ystroke:"",
        labelx:"",
        labely:"",
        zstroke:"red",
        labelchartTitle:"",
      },
      radialData : [],
      tabledata:[
        {
          id: "item-1",
          label: "X Axis",
          field:"",
          areaLabel:'Area Color',
          color:"#cc332b"
        },
        {
          id: "item-2",
          label: "Y Axis",
          field:"",
          areaLabel:'Bar Color',
          color:"#cc332b"
        }

      ],
      Color:[
        {
          id: "item-1",
          color:"",
        },
      ],
      items:[

        {
          id: "item-1",
          label: "X Axis",
          field:"",
          areaLabel:'Area Color',
          color:"#cc332b"
        },
        {
          id: "item-2",
          label: "Y Axis",
          field:"",
          areaLabel:'Bar Color',
          color:"#cc332b"
        }


      ],
      showlistField:[],

      condition :[ {
        field : "",
        type : "",
        sort :""
      } ],
      cid: cid,
      enableyaxis:false,
      legend:false,
      alldata:false,
      unit:'',
    }
  }
  componentDidMount() {
    this.setState({payload:this.props.payload})
    this.graphData()
  }

  openSetting=()=>{
    this.setState({modalStatus:!this.state.modalStatus})
    this.props.dragStatus(!this.state.modalStatus)
  }
  handleChangeComplete = (color,param) => {
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[param]=color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  };
  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }
  borderColorChange=(color,param)=>{

    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["bordercolor"] = color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleInputChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  graphData(){
    var obj = this;
    const collection =  this.props.payload && this.props.payload.collection
    const tabledata = this.props.payload && this.props.payload.graph||this.state.items
    const condition = this.props.payload && this.props.payload.condition||this.state.condition
    const bordercolor = this.props.payload && this.props.payload.bordercolor
    const Color = (this.props.payload && this.props.payload.Color)|| COLORS;
    this.setState({tabledata:tabledata,Color:Color,bordercolor:bordercolor,items:tabledata,condition:condition})
    if((collection !=='' && collection !== undefined && tabledata !== undefined ) || getUrlSegment(1)==="collections") {
      let collections =(getUrlSegment(1)==="collections")? atob(getUrlSegment(4) ) :collection
      let data = {
        collectionid: collections,
        "index": ES_INDEX,
        "graph": true,
        "fieldid": "0",
        "components": tabledata,
        "condition":condition
      }
      axios.post(API_PATH+"project/datacard", data)
          .then(function (res) {
            var carddata = res.data.results.data;
            let areadata = carddata.graph.map(function (item, index){
              ////console.log(item)
              let firstKey = Object.keys(item)[0];
              item[firstKey] = isValidDate(item[firstKey]);

              for (var key in item) {

                if (item.hasOwnProperty(key) && key !== firstKey) {
                  item[key] = parseInt(item[key]);
                }
              }
              let cindex = index;
              if(index > Color.length-1){
                cindex = index % Color.length;
              }
              item.fill = Color[cindex]?Color[cindex].color :'#f0f0f0';

              if(item[firstKey] === 'Migrated') {
                item.fill = '#B0B0B0';
              }
              else if(item[firstKey] === 'Expired') {
                item.fill = '#DB1238';
              }
              else if(item[firstKey] === 'Active') {
                item.fill = '#76A6F0';
              }
              else if(item[firstKey] === 'Recovered' || item[firstKey] === 'Cured') {
                item.fill = '#6ED4B9';
              }

              item.name = item[firstKey];
              return item;
            });
            var d = new Date();
            var cid = d.getTime();
            obj.setState({radialData: areadata, cid: cid});
            return res;

          }).catch(function (err) {
        return err;
      });
    }



    if((collection !=='' && collection !== undefined) || getUrlSegment(1)==="collections") {
      let collections =(getUrlSegment(1)==="collections")? atob(getUrlSegment(4) ) :collection
      axios({
        method: "GET",
        url: API_PATH + "collection/showform?_id=" + collections + "&index=" + ES_INDEX,
        async: false,
         headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        obj.setState({showlistField: res.data.data});
        return res;

      }).catch(function (err) {
        return err;
      });
      this.setState({cId:collection})
    }
    if(collection !=='' || collection !== undefined) {
      axios({
        method: "GET",
        url: API_PATH + "collection/" + collection + "/collectedinfo?index="+ES_INDEX,
        async: false,
         headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        var carddata = res.data.results.data[0];
        obj.setState({carddata: carddata});
        return res;

      }).catch(function (err) {
        return err;
      });
    }
    else{
      obj.setState({radialData: graphData});

    }
  }
  handleChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  chartSaving=()=>{
    var prjctdtlid = atob(getUrlSegment(4));
    let sharetype = getUrlSegment(1);
    let  uid = '';
    if(sharetype === 'public') {
        uid = 2;
    }
    else{
        uid = getProfile().id;
    }
    let param={
      "projectid": prjctdtlid,
      "uid": uid,
      "index": ES_INDEX,
      "type": this.props.getdashboardChange.Dashboard,
      "dashboard":state.getStorableState()
      ,
      "id": this.props.getdashboardChange.UpdateId
    }
    this.props.chartSaving(param)
    this.setState({modalStatus:false})
  }
  /*  drag and drop functions for hierarchial table */

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
    );

    this.setState({
      items
    });
  };
  addData=(Id,updateParam)=>{
    let data=this.state[updateParam]
    let newData={
      id: "item-"+new Date().getTime(),
      label: "item "
    }
    let _data=data.concat(newData)
    this.setState({[updateParam]:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[updateParam]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  removeData=(dragid,updateParam)=>{
    let data=this.state[updateParam]
    let _data=data.filter(item=>item.id !== dragid)
    this.setState({[updateParam]:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[updateParam]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleLabelChange=(e,dragid)=>{
    var _newState = this.state.items;
    var index=(_newState.findIndex(obj => obj["id"] === dragid))
    _newState[index][e.target.name]=e.target.value;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=this.state.items
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }

  handleColorChange=(color,param,label)=>{
    if(label=== "graph") {
      var _newState = this.state.items;
    }
    else{
      var _newState = this.state[label];

    }
    var index=(_newState.findIndex(obj => obj["id"] === param))
    _newState[index]["color"]=color;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    if(label=== "graph") {
      payload[label]=this.state.items
    }
    else{
      payload[label]=this.state[label]
    }
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }

  handleClick=(status)=>{
    this.setState({colorpickerStatus:status})
  }

  handleConditionChange=(e)=>{

    var _newState = this.state.condition;

    if(e.target.name ==='field') {
      let t = e.target.value.split("|");
      _newState[0]["field"] = t[0];
      _newState[0]["type"] =  t[1] === "datepicker" ?"date":t[1];
    }
    else{
      _newState[0][e.target.name] = e.target.value;
    }


    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["condition"]=this.state.condition
    //////console.log(payload)
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });



  }

  radioBoxchange=(e)=> {
    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }

  checkBoxchange=(e)=> {
    let val;
    if (e.target.value === "false") {
      val = true
    } else {
      val = false
    }

    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = val
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }


  render() {
    const {
      id,
      showBasicContent, showPreview,
      dropzoneID,payload, parentID, name
    } = this.props;
    let sourceType= (payload && payload.sourceType)||false

    let showlistField = []
    let sourceData=[]
    if(this.state.showlistField.length !==0) {
      for (let i = 0; i <this.state.showlistField.pages.length; i++) {
        (this.state.showlistField.pages[i].components).map(list=>{
          showlistField.push(list)
        })
      }
    }
    if(sourceType.toString() === "true"){
      sourceData=  this.props.listAllCollections.ListAllCollections.data
    }
    else{
      sourceData=  this.props.EntityData.Entity.data
    }
    let containerData={
      chartTitle :  this.state.chartTitle || payload && payload.chartTitle,
      subTitle :  this.state.subTitle || payload && payload.subTitle,
      subTitleEnabled : this.state.subTitleEnabled || payload && payload.subTitleEnabled,
      data :  this.state.radialData || [],
      collection :  this.state.collection || payload && payload.collection ,
      tabledata :  this.state.items || payload && payload.graph ,
      legend :  this.state.legend || payload && payload.legend||false,
      alldata :  this.state.alldata || payload && payload.alldata||false,
      unit :  this.state.unit || payload && payload.unit,
      graphHeight :  this.state.graphHeight || payload && payload.graphHeight,
      condition : this.props.payload && this.props.payload.condition||this.state.condition,
      showlistField:showlistField,
      collectionData:sourceData,
      bordercolor :  (this.props.payload && this.props.payload.bordercolor) || "#fff",
      sourceType:  sourceType,
      Color :  this.state.Color || payload && payload.Color ,

    }
    const type =  this.state.type || payload && payload.type||false;
    if (showBasicContent) {
      return (
          <Draggable { ...this.props } >
            {
              <span className="drag-icon">{name}</span>
            }
          </Draggable>
      )
    }

    if (showPreview) {
      return (
          <ViewData
              containerData={containerData}
              viewStatus={this.state.viewStatus}
              settingStatus={this.settingStatus}
              cid={this.state.cId}
              state={this.state}
              key={`result-table`}
              chart={ <RadialBarChart containerData={containerData} />}
          />



      );
    }
    return (
        <React.Fragment>
          <Draggable {...this.props}  draggable={!this.props.DragDisabled.dragStatus} >
            <FormGroup className="m-0">
              <label className="col-sm-12">
                <span>{name}</span>
                <div className="chart-icons">
                  <div className="settings-icon" onClick={() => this.openSetting(id, dropzoneID, parentID)}></div>
                  <div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>
                </div>
              </label>
            </FormGroup>
          </Draggable>
          {this.state.modalStatus &&
          <SettingsModal
              title={`Radial Bar Chart - Settings`}
              containerData={containerData}
              colorpickerStatus={this.state.colorpickerStatus}
              modaltype={8}
              radialbarchartType={type}
              Close={this.openSetting}
              OKAction={this.chartSaving}
              cancelAction={this.openSetting}
              handleChange={this.handleChange}
              checkBoxchange={this.checkBoxchange}
              radioBoxchange={this.radioBoxchange}
              handleConditionChange={this.handleConditionChange}
              handleLabelChange={this.handleLabelChange}
              handleColorChange={this.handleColorChange}
              handleInputChange={this.handleInputChange}
              borderColorChange={this.borderColorChange}
              handleClick={this.handleClick}
              addData={this.addData}
              removeData={this.removeData}
              onDragEnd={this.onDragEnd}
          />
          }

        </React.Fragment>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled,
  EntityData:state.EntityReducer.GetEntity,


});
export default  withRouter(connect(mapStateToProps, {
  collections: collectionActions.listallcollections,
  showform : collectionActions.showform,
  chartSaving:DynamicDashboard.saveDashboarddata,
  dragStatus :DynamicDashboard.dragStatus,

})(RadialBarChartContainer));
