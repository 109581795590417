import React, {Component} from 'react';
import {
    BarChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Area,
    Legend,
    ResponsiveContainer
} from 'recharts';

const CustomTooltip = ({active, payload, label, unit}) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                {payload && payload.map(item =>
                    <p className="intro"
                       style={{color: item.color}}>{`${new Intl.NumberFormat('en-IN', {maximumFractionDigits: 2}).format(item.value)} ${unit ? unit : ''}`}</p>
                )}
            </div>
        );
    }

    return null;
};

function BarChartCard(props) {
    // console.log(props)
    let xData = props.containerData.tabledata.slice(0, 1);
    let barData = props.containerData.tabledata.slice(1, 2);
    let layout = props.containerData.layout ? props.containerData.layout : 'horizontal';
    let xwidth = props.containerData.xwidth && parseInt(props.containerData.xwidth);
    let xheight = props.containerData.xheight && parseInt(props.containerData.xheight);
    let yheight = props.containerData.yheight && parseInt(props.containerData.yheight);
    let ywidth = props.containerData.ywidth && parseInt(props.containerData.ywidth);
    let graphHeight = props.containerData.graphHeight ? parseInt(props.containerData.graphHeight) : 200;
    let xAngle = props.containerData.xAngle ? parseInt(props.containerData.xAngle) : 0;
    let yAngle = props.containerData.yAngle ? parseInt(props.containerData.yAngle) : 0;
    let borderColor = props.containerData.bordercolor && props.containerData.bordercolor;

    return (
        <React.Fragment>
            <ResponsiveContainer width={props.containerData.legend ? "95%" : "100%"} height={graphHeight}>
                <BarChart margin={{top: 35, right: 15, left: 0, bottom: 0}}
                          data={props.containerData.data} layout={layout}>
                    <CartesianGrid vertical={false} stroke="#f0f0f0"/>
                    {layout === 'horizontal' && xData.map((list, index) =>
                        <XAxis dataKey={list.label} padding={{left: 0, right: 0}} axisLine={false} tickLine={false}
                               angle={-xAngle} fontSize={12}
                               interval={props.containerData.alldata ? 0 : 'preserveStartEnd'}
                               textAnchor={xAngle > 0 ? 'end' : 'middle'}
                               width={xwidth} height={xheight}/>
                    )}
                    {layout === 'horizontal' && barData.map((list, index) =>
                        <YAxis dataKey={list.label} type={'number'}
                               hide={props.containerData.enableyaxis ? false : true} angle={-yAngle} tickLine={false}
                               axisLine={false} position="insideBottom"
                               width={ywidth} height={yheight}/>
                    )}


                    {layout === 'vertical' && xData.map((list, index) =>
                        <YAxis dataKey={list.label} type={'category'} fontSize={12} tickLine={false} angle={-yAngle}
                               hide={props.containerData.enableyaxis ? false : true}
                               width={ywidth} height={yheight}
                               interval={props.containerData.alldata ? 0 : 'preserveStartEnd'} padding={{left: 0}}/>
                    )}
                    {layout === 'vertical' && barData.map((list, index) =>
                        <XAxis dataKey={list.label} type={'number'} angle={-xAngle}
                               interval={props.containerData.alldata ? 0 : 'preserveStartEnd'}
                               textAnchor={xAngle > 0 ? 'end' : 'middle'} width={xwidth} height={xheight}/>
                    )}


                    <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip unit={props.containerData.unit}/>}/>
                    {props.containerData.legend &&
                    <Legend className={"legend-limit"} iconType={`circle`} iconSize={8} layout='vertical'
                            verticalAlign={`middle`}
                            align={`right`}/>
                    }
                    {barData.map((list, index) =>
                        <Bar dataKey={list.label} fill={list.color} maxBarSize={20} stroke={borderColor}/>
                    )}
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}

export default BarChartCard;
