import {commonService} from "./common.services";

export const ceshareview = {
    importExcelData,
    pcsaveData,
    pcremoveData,
    linkedItems,
    fieldpropagation,
    savefieldpropagation,
    removefieldpropagation,
    showForm,
    fieldVersion,
    shareall,
    getFlagList,
    saveFlagList,
    deleteFlagData,
    deleteFlagCategory,
    removeassignedflag,
    assignflag,
    fieldUpdate,
    savingNoteShare,
    listShareNotes,
    getFlagCategory,
    saveCategory,
    saveQuickForm,
    quickFormList,
    quickFormDetail,
    deleteQuickForm,
    assignRoleQuickForm,
    manageQuickform,
    setdefaultQuickform,
    templateList,
    savefilteredView,
    getFilterdList,
    deleteFilterView,
    saveFlagOpen,
    getButtonList,
    saveButtonList,
    deleteButtonData,
    saveRoleList,
    getRoleList,
    deleteRoleData,
    savePermission,
    getRolePermission,
    getFullwidth,
    saveFullwidth,
    listPool,
    getResultSet,
    savesummary,
    savedisplayset,
    savedate,
    sendFieldEmails,
    sendFieldSMS,
    savecalSetngs,
    getSummaryPanelDetails,
    saveflagorder,
    saveflagcategoryorder,
    collectionRecordDuplicate,
    getCollectedInfo,
    savePaymentUrls,
    saveRazorpayPayment,
    getPaymentDetail,
    getPaymentList,
    paymentInfoSave,
    sendFieldWhatsapp,
    saveprintdetails,
    removeprint,
    saveprintsettings,
    printsettingsdetails,
    savePaymentData,
    saveHeadersettings,
    getSharedForm,
    getCustomapi,
    saveColorSettings,
    getKanbanSettings
};

function importExcelData(params){
    return commonService.sendHttpRequest('export/import', params, "POST");
}
function pcsaveData(params){
    return commonService.sendHttpRequest('collection/saveprojectsettings', params, "POST");
}

function pcremoveData(params){
    return commonService.sendHttpRequest('collection/removeprojectshare', params, "POST");
}
function linkedItems(params){
    return commonService.sendHttpRequest('collection/linkeditems', params, "GET");
}
function fieldpropagation(params){
    return commonService.sendHttpRequest('collection/fieldpropagation', params, "GET");
}
function savefieldpropagation(params){
    return commonService.sendHttpRequest('collection/savefieldpropagation', params, "POST");
}
function removefieldpropagation(params){
    return commonService.sendHttpRequest('collection/removefieldpropagation', params, "POST");
}
function showForm(params){
    return commonService.sendHttpRequest('collection/showform', params, "GET");
}
function fieldVersion(params){
    return commonService.sendHttpRequest('collection/recordversions', params, "GET");
}
function shareall(params){
    return commonService.sendHttpRequest('collection/shareall', params, "POST");
}
function getFlagList(params){
    return commonService.sendHttpRequest('collection/categoryflags', params, "GET");
}
function saveFlagList(params){
    return commonService.sendHttpRequest('collection/saveflag', params, "POST");
}
function deleteFlagData(params){
    return commonService.sendHttpRequest('collection/removeflag', params, "POST");
}
function deleteFlagCategory(params){
    return commonService.sendHttpRequest('collection/removeflagcategory', params, "POST");
}
function removeassignedflag(params){
    return commonService.sendHttpRequest('collection/removeassignedflag', params, "POST");
}
function assignflag(params){
    return commonService.sendHttpRequest('collection/assignflag', params, "POST");
}
function fieldUpdate(params){
    return commonService.sendHttpRequest('collection/fieldupdate', params, "POST");
}
function savingNoteShare(params){
    return commonService.sendHttpRequest('collection/savesettings', params, "POST");
}
function listShareNotes(params){
    return commonService.sendHttpRequest('collection/listsettings', params, "GET");
}
function getFlagCategory(params){
    return commonService.sendHttpRequest('collection/flagcategory', params, "GET");
}
function saveCategory(params){
    return commonService.sendHttpRequest('collection/saveflagcategory', params, "POST");
}
function saveQuickForm(params){
    return commonService.sendHttpRequest('collection/savequickform', params, "POST");
}
function quickFormList(params){
    return commonService.sendHttpRequest('collection/quickformlist', params, "GET");
}
function quickFormDetail(params){
    return commonService.sendHttpRequest('collection/quickform', params, "GET");
}
function deleteQuickForm(params){
    return commonService.sendHttpRequest('collection/removequickform', params, "POST");
}
function assignRoleQuickForm(params){
    return commonService.sendHttpRequest('collection/assignquickform', params, "POST");
}
function templateList(params){
    return commonService.sendHttpRequest('collection/template', params, "GET");
}
function manageQuickform(params){
    return commonService.sendHttpRequest('general/managequickform', params, "POST");
}
function setdefaultQuickform(params){
    return commonService.sendHttpRequest('general/setdefaultquickform', params, "POST");
}

function savefilteredView(params){
    return commonService.sendHttpRequest('custom/savefilteredview', params, "POST");
}

function getFilterdList(params){
    return commonService.sendHttpRequest('custom/filteredviewlist', params, "GET");
}

function deleteFilterView(params){
    return commonService.sendHttpRequest('custom/removefilteredview', params, "POST");
}
function saveFlagOpen(params){
    return commonService.sendHttpRequest('collection/saveflagopen', params, "POST");
}
function getButtonList(params){
    return commonService.sendHttpRequest('collection/buttonlist', params, "GET");
}
function saveButtonList(params){
    return commonService.sendHttpRequest('collection/savebuttonsettings', params, "POST");
}
function deleteButtonData(params){
    return commonService.sendHttpRequest('collection/removebutton', params, "POST");
}
function saveRoleList(params){
    return commonService.sendHttpRequest('collection/savecollectionrole', params, "POST");
}
function getRoleList(params){
    return commonService.sendHttpRequest('collection/rolelist', params, "GET");
}
function deleteRoleData(params){
    return commonService.sendHttpRequest('collection/removerole', params, "POST");
}
function savePermission(params){
    return commonService.sendHttpRequest('collection/saverolesettings', params, "POST");
}
function getRolePermission(params){
    return commonService.sendHttpRequest('collection/rolesettings', params, "GET");
}
function getFullwidth(params){
    return commonService.sendHttpRequest('collection/listfieldView', params, "GET");
}
function saveFullwidth(params){
    return commonService.sendHttpRequest('collection/savefieldview', params, "POST");
}
function listPool(params){
    return commonService.sendHttpRequest('pool/allpools', params, "POST");
}
function getResultSet(params, collectionId){
    return commonService.sendHttpRequest('collection/resultset/' + collectionId, params, "GET");
}
function savesummary(params, collectionId){
    return commonService.sendHttpRequest('collection/savesummary', params, "POST");
}
function  savedisplayset(params, collectionId){
    return commonService.sendHttpRequest('custom/saverecordfields', params, "POST");
}
function savedate(params){
    return commonService.sendHttpRequest('collection/savebookingtime' , params, "POST");
}
function sendFieldEmails(params){
    return commonService.sendHttpRequest('general/sendemail' , params, "POST");
}
function sendFieldSMS(params){
    return commonService.sendHttpRequest('general/sendsms' , params, "POST");
}
function savecalSetngs(params){
    return commonService.sendHttpRequest('/collection/savebookingtime' , params, "POST");
}
function getSummaryPanelDetails(params){
    return commonService.sendHttpRequest('collection/summarypanel' , params, "GET");
}
function saveflagorder(params){
    return commonService.sendHttpRequest('collection/saveflagorder' , params, "POST");
}
function saveflagcategoryorder(params){
    return commonService.sendHttpRequest('collection/saveflagcategoryorder' , params, "POST");
}

function collectionRecordDuplicate(params){
    return commonService.sendHttpRequest('general/duplicaterecord' , params, "POST");
}
function getCollectedInfo(id, params){
    return commonService.sendHttpRequest('collection/' + id + '/collectedinfo', params, "POST");
}
function savePaymentUrls(params){
    return commonService.sendHttpRequest('custom/savepaymentrecord', params, "POST");
}
function saveRazorpayPayment(params){
    return commonService.sendHttpRequest('custom/generateurl', params, "POST");
}
function getPaymentDetail(params){
    return commonService.sendHttpRequest('custom/paymentdata', params, "GET");
}
function getPaymentList(params){
    return commonService.sendHttpRequest('custom/uniquepayment', params, "GET");
}
function paymentInfoSave(params){
    return commonService.sendHttpRequest('custom/savepaymentinformation', params, "POST");
}
function sendFieldWhatsapp(params){
    return commonService.sendHttpRequest('custom/sendwhatsapp' , params, "GET");
}
function saveprintdetails(params){
    return commonService.sendHttpRequest('custom/saveprintdetails' , params, "POST");
}
function removeprint(params){
    return commonService.sendHttpRequest('custom/removeprint' , params, "POST");
}
function saveprintsettings(params){
    return commonService.sendHttpRequest('custom/saveprintsettings' , params, "POST");
}
function printsettingsdetails(params){
    return commonService.sendHttpRequest('custom/printsettingsdetails' , params, "GET");
}
function savePaymentData(params){
    return commonService.sendHttpRequest('custom/savetemplatesettings' , params, "POST");
}
function saveHeadersettings(params){
    return commonService.sendHttpRequest('custom/savecollectionname_settings' , params, "POST");
}
function getSharedForm(params){
    return commonService.sendHttpRequest('custom/mailurlsend' , params, "GET");
}

function saveColorSettings(params){
    return commonService.sendHttpRequest('custom/savecollectioncolorcode' , params, "POST");
}
function getCustomapi(params){
    return commonService.sendHttpRequest('custom/listcustomapi' , params, "POST");
}
function getKanbanSettings(params){
    return commonService.sendHttpRequest('custom/kanban_settings' , params, "GET");
}

