import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../actions/ToDo"
import {withRouter} from 'react-router';
import ListCard from "../views/ListCard"
import * as commonActions from "../../actions/Common";
import LoaderSvg from "../views/LoaderSVG"
import Modal from "../views/Modal"
import AddToDoTask from "../pages/AddToDoTask"
import {getProfile} from '../../utilities/AuthService';
import * as projectActions from "../../actions/Project";
import NoData from "../views/NoData";
import Topbar from "./Topbar";
import FilterBlock from "./FilterBlock";
import FilerRightBlock from "./FilterRightBlock";
import rootActions from "../../actions";


class Archive extends Component {

    constructor(props) {
        super(props);
        this.modalOpen=this.modalOpen.bind(this)
        this.gettaskList=this.gettaskList.bind(this);
        this.loadtasks = this.loadtasks.bind(this);
        this.createmodalClose = this.createmodalClose.bind(this)
        this.state={
            open:false,
            projectList:[],
            limit : 0,
            loadingState: false,
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            archiveddata: [],
            offset: 50
        }

    }

    componentDidMount() {

        this.props.mainClick('task','/task/archive');
        this.gettaskList('All');

        const container = document.querySelector('.main-panel-holder');
        container.addEventListener('scroll', this.listenToScroll)
    }
    componentWillUnmount() {
        const container = document.querySelector('.main-panel-holder');
        container.removeEventListener('scroll', this.listenToScroll)
    }
    listenToScroll = () => {
        const container = document.querySelector('.main-panel-holder')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;
        if (contentHeight <= scrollTop) {
            if(this.state.lastOffset + 1 < this.state.totalitems) {
                this.setState({loading : 1})
                var data = {category : 'archive', status : "All" , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', limit: this.state.lastOffset, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TO_ALL_ARCHIVED_DATA' }
                this.props.archived(data);
            }
        }
    }
    loadMoreItems() {
        this.setState({ loadingState: true, limit : this.state.limit + 50 },
            function() {
                this.gettaskList('All');
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // if(prevProps.todoListing  !== this.props.todoListing){
        //     let modifiedTaskList = [];
        //
        //     modifiedTaskList = this.props.todoListing.AllArchived.data;
        //     //}
        //     this.setState({ taskList: modifiedTaskList, loadingState : false })
        //
        // }

        if(prevProps.taskFilters  !== this.props.taskFilters){
            this.setState({  limit : 0 },
                function() {
                    this.gettaskList('All');
                })
        }
        var beta = this;
        if(prevProps.taskSelect.tasksArchived  !== this.props.taskSelect.tasksArchived ){
            setTimeout(function () {
                beta.gettaskList('All');
            }, 1000)
        }

        if(prevProps.todoListing  !== this.props.todoListing){
            this.setState({archiveddata: this.props.todoListing.AllArchived.data});
        }

       /* if(prevProps.todoListing.AllArchived.data !== this.props.todoListing.AllArchived.data) {
            let cnt = parseInt(this.state.lastOffset) + (this.props.todoListing.AllArchived.data.length);
            this.setState({
                lastOffset: cnt,
                totalitems: this.props.todoListing.AllArchived.total,
            });
            let archiveddata = [...this.state.archiveddata];
            if(this.props.taskFilters.taskPriorityFilter === 'All' && this.props.taskFilters.taskProjectFilter === 'All' && this.props.taskFilters.taskAssignedFilter === '') {
                archiveddata.push(...this.props.todoListing.AllArchived.data)
            }else{
                this.setState({lastOffset: 0});
                archiveddata = [...this.props.todoListing.AllArchived.data];
            }
            this.setState({archiveddata: archiveddata});
            this.setState({loading:0})
        }*/
    }

    modalOpen(){
        this.setState({open:!this.state.open,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData});
    }

    gettaskList(status){

        var data = {category : 'archive', status : status , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', username : this.props.taskFilters.taskAssignedFilter, managetask : 'mytask', limit: 0, offset  : this.state.offset, systype : getProfile().usertype,  actionType : 'LIST_TO_ALL_ARCHIVED_DATA' }
        this.props.archived(data);
    }

    selectTasks(id) {
        this.props.selectTasksToMove(id);
    }

    loadtasks() {
        this.setState({limit : this.state.limit++},
            function() {
                this.gettaskList('All');
            })
    }
    createmodalClose(){
        this.setState({open : false});
    }

    render() {

        //////////////console.log(this.props.taskSelect.allChecked);

        let ArchivedData=this.state.archiveddata
        return (

            <>

                <div className='filter-all-wrapper task-home'>
                    <div className='filter-holder'>
                        {this.props.taskSelect.taskSelection === 'no' &&
                        <FilterBlock/>
                        }
                        {/*{this.props.taskSelect.taskSelection === 'no' &&*/}
                        {/*< Topbar gettaskList={this.gettaskList}  topbardata={this.props.topbardata} menuClickData={this.props.menuClickData.subMenu} />*/}
                        {/*}*/}
                        <FilerRightBlock />
                    </div>
                </div>
                <div className={this.props.todoListing.loading ? 'main-panel-holder with-loader has-filter' : 'main-panel-holder has-filter'} ref="iScroll">
                    {this.props.todoListing.loading &&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                    }

                    {ArchivedData.length === 0 &&
                    <div className='absolute-holder'>
                        <NoData

                            msg={`No task assigned to you`}

                        />
                    </div>
                    }

                    {ArchivedData.filter(list => list.readstatus === 'unread').map((list,index) =>
                        <React.Fragment>
                            {index === 0 &&
                            <h4 className={'main-panel-head'}>Unread</h4>
                            }
                            <ListCard
                                key={'todo'+index}
                                index={index}
                                list = {list}
                                history={this.props.history}
                                mode='to-me'
                                taskSelection = {this.props.taskSelect.taskSelection}
                                selectTasks = {this.selectTasks.bind(this)}
                                allChecked = {this.props.taskSelect.allChecked}
                                id={list.masterid}
                                tasksource={list.createdfrom}
                                poolenabled={list.poolenabled}
                            />
                        </React.Fragment>
                    )}

                    {ArchivedData.filter(list => list.readstatus === 'read').map((list,index) =>
                        <React.Fragment>
                            {index === 0 &&
                            <h4 className={'main-panel-head'}>Read</h4>
                            }
                            <ListCard
                                key={'todo'+index}
                                index={index}
                                list = {list}
                                history={this.props.history}
                                mode='to-me'
                                taskSelection = {this.props.taskSelect.taskSelection}
                                selectTasks = {this.selectTasks.bind(this)}
                                allChecked = {this.props.taskSelect.allChecked}
                                id={list.masterid}
                                tasksource={list.createdfrom}
                                poolenabled={list.poolenabled}
                            />
                        </React.Fragment>

                    )}
                </div>
                {this.state.open &&
                <Modal title={'Create New Task'} Close ={this.modalOpen.bind(this)}>
                    <AddToDoTask
                        projectList={this.state.projectList}
                        tagList={this.state.tagList}
                        listtags={this.props.listtags.tags}
                        // modalOpen = {this.modalOpen}
                    />

                </Modal>
                }
                <div className={'float-button-box'} onClick={this.modalOpen}>
                    <span className={'icon-in icon-plus'}></span>
                </div>


            </>



        );
    }
}



const mapStateToProps = state => ({
    listAllProjectData:state.ProjectReducer.listallProjects,
    todoListing:state.TaskReducer.listtoDo,
    taskFilters:state.TaskReducer.filterTasks,
    taskSelect:state.TaskReducer.getTaskSelection,
    menuClickData:state.CommonReducer.menuClick,
    searchData:state.CommonReducer.searchparamdata,
    CreateTask:state.TaskReducer.CreateTask,
    topbardata:state.TaskReducer.TodoTopbarMenudata,
    listtags:state.TaskReducer.ListAllTags,
    saveTodo : state.TaskReducer.saveTodo,
    projects:state.TaskReducer.GetProjectData,

});
export default  withRouter(connect(mapStateToProps, {
    archived:taskActions.listtask,
    toFollowUp:taskActions.listtask,
    mainClick:rootActions.commonActions.MenuClick,
    createTask:taskActions.createtask,
    listTags:projectActions.listalltags,
    selectTasksToMove : taskActions.selectTasksToMove,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData
})(Archive));


