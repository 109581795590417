import React from 'react';
import axios from 'axios';
import { Draggable, state } from 'react-page-maker';
import {connect} from "react-redux";
import * as collectionActions from "../../../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../../../constants";
import {getSubdomain, getUrlSegment} from "../../../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../../../utilities/AuthService";
import {SettingsPanel} from "../../../../../dashboardBuilder/elements/container/ViewData";
import EntityModal from "./EntityModal";

import * as formsActions from "../../../../../../actions/Forms";
import * as entityActions from "../../../../../../actions/Entity";
// a little function to help us with reordering the result
class ButtonblockContainer extends React.Component {
  constructor(props,context) {
    super(props,context);
    this.state={
      modalStatus:false,
      showColorPicker:false,
      background:'#FF1B40',
      cId:'',
      payload:{
        chartTitle:"Title",
        subTitle:"subtitle",
        subTitleEnabled : false,
        bubblechartdata:[],
        cardvalue:0,
        collection:"",
        fields:"",
        aggregation:'',
        graph:false,
        x:"",
        y:"",
        z:"",
        xstroke:"",
        ystroke:"",
        labelx:"",
        labely:"",
        zstroke:"#fff",
        labelchartTitle:"",
        xwidth:"0",
        xheight:"30",
        ywidth:"60",
        yheight:"0",
        graphHeight:"200",
        xAngle:"0",
        yAngle:"0"
      },
      items:[{
        id: "item-1",
        label: "item 1",
        field:"",
        color:"#cc332b"
      }],
      whereCondition:[
        {
          id: "item-1",
          label: "",
          field:"",
          conditions:"",
          min:'',
          max:'',
          from:'',
          to:'',
          text:"",
          type:""
        },
      ],
      layout:"",
      showlistField:[],
      condition :[ {
        field : "",
        type : "",
        sort :""
      } ],
      loading:true,
      colorpickerStatus:false,
      limit : '100',
      basedon:"",
      ownFilter:false,
      relFilCnt:0,
      relFltr:false,
      setFields:[
        {
          id: "item-1",
          masterid:"",
          default:""
        },
      ],
      bubbleLabel : '',
      colorAxis : 'z',
      rom:0,
      size:300,

    }
  }
  componentDidMount() {
    let ownid="table-block"+Math.round(new Date().getTime() + (Math.random() * 100));
    let params = {uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : 0, offset : 500};
    this.props.collections(params);

    this.setState({ownid:ownid,payload:this.props.payload})
    this.graphData()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.getsyncRec !== prevProps.getsyncRec){
      this.graphData()
    }
  }

  openSetting=()=>{
    this.setState({modalStatus:!this.state.modalStatus})
    this.props.dragStatus(!this.state.modalStatus)
  }
  handleChangeComplete = (color,param) => {
    //////console.log(param)
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[param]=color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
    //this.setState({ background: color.hex });
  };
  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }
  handleInputChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  graphData(){

    var obj = this;
     const collection =  this.props.payload && this.props.payload.collection
     const tabledata = this.props.payload && this.props.payload.graph||this.state.items
     const whereCondition = this.props.payload && this.props.payload.whereCondition||this.state.whereCondition
     const condition = this.props.payload && this.props.payload.condition||this.state.condition
     const limit = this.props.payload && this.props.payload.limit
     const groupbyEnable =  this.props.payload && this.props.payload.groupbyEnable
     const setFields = this.props.payload && this.props.payload.setFields ||this.state.setFields
     this.setState({collection:collection,setFields:setFields,limit:limit,tabledata:tabledata,whereCondition:whereCondition,condition:condition,groupbyEnable:groupbyEnable})
    if(collection !=='' || collection !== undefined) {
      let collections = collection
      let bubbleLabel=""
      let groupby = '';
      let groupbyLabel = "";
      let components = tabledata;
      if(tabledata.length > 0) {
        bubbleLabel = tabledata[0].label;
        if(groupbyEnable) {
          groupbyLabel = tabledata[0].label;
          groupby = tabledata[0].field;
          components =tabledata.slice(1,tabledata.length);
        }
      }
      let filters = [];
      let numberfilters = [];
      let datefilters = [];
      if(whereCondition.length > 0) {
        for( var i=0; i< whereCondition.length;i++){
          let conditionEntry = whereCondition[i];
          if(conditionEntry && conditionEntry.field&& conditionEntry.field !== '') {
            let conditionValue = '';
            if(conditionEntry.type === 'text') {
              conditionValue = conditionEntry.text;
            }
            else if(conditionEntry.type === 'conditions') {
              conditionValue = conditionEntry.conditions;
            }
            let conditionArr = conditionValue !== '' ? conditionValue.split(',') :[];

            if(conditionEntry.type === 'datepicker') {
              let datefilter = {
                "id" : conditionEntry.field,
                "from" : conditionEntry.from,
                "to" : conditionEntry.to
              }
              datefilters.push(datefilter)
            }
            else if(conditionEntry.type === 'number') {
              let numberfilter = {
                "id" : conditionEntry.field,
                "min" : conditionEntry.min,
                "max" : conditionEntry.max
              }
              numberfilters.push(numberfilter)
            }
            else {
              if(conditionArr.length > 0) {
                let filter = {
                  "id" : conditionEntry.field,
                  "value" : conditionArr
                }
                filters.push(filter);
              }

            }

          }

        }
      }let sortCondition = [];
      if(condition.length > 0) {
        for(var j=0; j<condition.length; j++) {
          if( condition[j].field && condition[j].field !== '' && condition[j].sort && condition[j].sort !== ''
              && condition[j].type && condition[j].type !== '') {
            sortCondition.push(condition[j]);
          }
        }
      }

      this.setState({cId:collections})
      var data = {
        collectionid: collections,
        "index": ES_INDEX,
        "graph": true,
        "fieldid": "0",
        "components": components,
        "condition":sortCondition,
        "filters" : filters,
        "numberfilters" : numberfilters,
        "datefilters" : datefilters,
        "size" : limit,
        "linkedids":this.props.linkedId.param
      }
      if(groupbyEnable) {
        data.groupbyEnable = groupbyEnable;
        data.groupby = groupby;
        data.groupbyLabel = groupbyLabel;
      }


    }



    if((collection !=='' && collection !== undefined) || getUrlSegment(1)==="collections") {
      let collections =(getUrlSegment(1)==="collections")? atob(getUrlSegment(4) ) :collection
      this.setState({cId:collections})
      //  this.props.showform({ _id : collection});
      axios({
        method: "GET",
        url: API_PATH + "collection/showform?_id=" + collections+"&index="+ES_INDEX,
        async: false,
        headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        obj.setState({showlistField:res.data.data});
        return res;
      }).catch(function (err) {
        return err;
      });


      axios({
        method: "POST",
        url: API_PATH + "collection/" + collection + "/collectedinfo?index="+ES_INDEX,
        async: false,
        data:data,
        headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        //console.log(res)
        let from=0
        let results = (res.data.results)
        var totalchilds = results.totalchilds
        var collectionData = [];
        var collectionIDs = [];
        var collectionFilter = [];
        var cnt = 0;
        if (results.data.length > 0) {
          results.data.map((entry, index) => {
            collectionData[index] = {};
            collectionData[index]['id'] = entry.id;
            collectionData[index]['users'] = entry.users;
            collectionIDs.push(entry.id);
            if (entry.collectedform) {
              entry.collectedform.map(a => {
                //.filter((a) => a.showlist == 'Yes')
                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['value'] : '';
                if (a.type === "attachment") {
                  collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                  collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                }

                if (a.searchable === 'Yes') {
                  if (collectionFilter[a.label] === undefined) {
                    collectionFilter[a.label] = [];
                  }
                  cnt = collectionFilter[a.label].length;
                  collectionFilter[a.label][cnt] = a.value[0]['value'];
                }
              });
            }
          });
        }
        if (from === 0) {
          obj.setState({collectionData: collectionData, totalchilds: totalchilds});
        } else {
          collectionData = obj.state.collectionData.concat(collectionData)
          //////console.log(collectionData)
          obj.setState({collectionData: collectionData});
        }
        return res;

      }).catch(function (err) {
          return err;
      });
    }

  }
  handleChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  chartSaving=()=>{
    var prjctdtlid = getUrlSegment(4) ? atob(getUrlSegment(4)) : '';
    let sharetype = getUrlSegment(1);
    let  uid = '';
    if(sharetype === 'public' || sharetype === 'state') {
      uid = 2;
    }
    else{
      uid = getProfile().id;
    }
    let param={
      "projectid": prjctdtlid,
      "uid": uid,
      "index": ES_INDEX,
      "type": this.props.getdashboardChange.Dashboard,
      "dashboard":state.getStorableState()
      ,
      "id": this.props.getdashboardChange.UpdateId
    }
    this.props.chartSaving(param)
    this.setState({modalStatus:false})
  }
  checkBoxchange=(e)=> {
    let val;
    if (e.target.value === "false") {
      val = true
    } else {
      val = false
    }
    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = val
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }

  addData=(Id,updateParam)=>{
    let data=this.state[updateParam]
    let newData={
      id: "item-"+new Date().getTime(),
      label: "item "
    }
    let _data=data.concat(newData)
    this.setState({[updateParam]:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[updateParam]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  removeData=(dragid,updateParam)=>{
    let data=this.state[updateParam]
    let _data=data.filter(item=>item.id !== dragid)
    this.setState({[updateParam]:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[updateParam]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleLabelChange=(e,dragid,whereCondition,type)=>{
    var name=''
    var fType=''
    if(e.target.name === "masterid"){
        var data = e.target.value.split("|");
        name =data[0]
        fType=data[1]
    }
    else{
      name =e.target.name
    }

    let sel=e.target
    var updatedParam = whereCondition;
    var _newState = this.state[updatedParam];
    var index=(_newState.findIndex(obj => obj["id"] === dragid))
    _newState[index][e.target.name]=name;
    _newState[index]["label"]=sel.options[sel.selectedIndex].text ==="Please Select" ? "":sel.options[sel.selectedIndex].text;
    if(e.target.name ==="masterid"){
      _newState[index]["type"]=fType;
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[whereCondition]=this.state[updatedParam]
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }


  handleDefaultField=(e,dragid,updatedParam,type)=>{
    var _newState = this.state[updatedParam];
    var index=(_newState.findIndex(obj => obj["id"] === dragid))
    _newState[index][e.target.name]=e.target.value;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[updatedParam]=this.state[updatedParam]
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }

  radioBoxchange=(e)=> {
    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  handleConditionChange=(e)=>{
    var _newState = this.state.condition;
    if(e.target.name ==='field') {
      let t = e.target.value.split("|");
      _newState[0]["field"] = t[0];
      _newState[0]["type"] =  t[1] === "datepicker" ?"date":t[1];
    }
    else{
      _newState[0][e.target.name] = e.target.value;
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["condition"]=this.state.condition
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }
  colorChange=(color,param,label)=>{
    var _newState = this.state[label];
    var index=(_newState.findIndex(obj => obj["id"] === param))
    _newState[index]["color"]=color;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["bubblecolor"]=this.state.bubblecolor
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleColorChange=(color,param)=>{
    var _newState = this.state.items;
    var index=(_newState.findIndex(obj => obj["id"] === param))
    _newState[index]["color"]=color;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=this.state.items
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }
  ColorChange=(color,param)=>{
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[param] = color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }

  handleClick=(status)=>{
    this.setState({colorpickerStatus:status})
  }

  editRecord=(col,masterids,type,mId)=>{
    this.props.getselectedcollections({ _id : mId, collectionid :col});
    this.props.addRecord(col,masterids,type,mId)
  }
  copyData=(copyText)=>{
    var input = document.createElement('input');
    input.setAttribute('value', copyText);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    this.setState({copy:"Link copied successfully"})
  }
  render() {
    const {
      id,
      showBasicContent, showPreview,
      dropzoneID,payload, parentID, name
    } = this.props;
    let sourceType= (payload && payload.sourceType)||false
    let showlistField = []
    let sourceData=[]
    if(this.state.showlistField.length !==0) {
      for (let i = 0; i <this.state.showlistField.pages.length; i++) {
        (this.state.showlistField.pages[i].components).map(list=>{
          showlistField.push(list)
        })
      }
    }
    if(sourceType.toString() === "true"){
      sourceData=  this.props.listAllCollections.ListAllCollections.data
    }
    else{
      sourceData=  this.props.EntityData.Entity.data
    }
    let containerData={
      chartTitle :  this.state.chartTitle || payload && payload.chartTitle,
      buttonUrl:this.state.buttonUrl || payload && payload.buttonUrl,
      add :  this.state.add || payload && payload.add||false,
      edit :  this.state.edit || payload && payload.edit||false,
      data :  this.state.collectionData || [],
      collection :  this.state.collection || payload && payload.collection ,
      showlistField:showlistField,
      collectionData:sourceData,
      groupby: this.state.groupby || payload && payload.groupby,
      groupbyEnable: this.state.groupbyEnable || payload && payload.groupbyEnable,
      whereCondition :  this.state.whereCondition || payload && payload.whereCondition ,
      limit :  this.state.limit || payload && payload.limit,
      sourceType:  sourceType,
      setFields :  this.state.setFields || payload && payload.setFields ,
      display:true,
      defaultfields:this.props.getFormdetails.length !== 0 ? this.props.getFormdetails.formvalues:[],
      bkColr :  this.state.bkColr || payload && payload.bkColr,
      TxtColr :  this.state.TxtColr || payload && payload.TxtColr,
      buttoncolor:true

    }
    console.log(sourceData)
    if (showBasicContent) {
      return (
          <Draggable { ...this.props } >
            {
              <span className="drag-icon">{name}</span>
            }
          </Draggable>
      )
    }

    if (showPreview) {
      return (

          <div className="wa-payment-wrap">
            <div className="ind-panel-options single-row">
              <label>Select Type</label>
              <div className="radio-wrap">
                <div className="radio-block">
                  <input className="inp-chk" type={`radio`} name={`sourceType`}
                         checked={containerData.sourceType.toString() === "true"} value={true}
                         onClick={(e) => this.radioBoxchange(e)}/>
                  <label>Collection</label>
                </div>
                <div className="radio-block">
                  <input className="inp-chk" type={`radio`} name={`sourceType`}
                         checked={sourceType.toString() === "false"}
                         value={false}
                         onClick={(e) => this.radioBoxchange(e)}/>
                  <label>Entity</label>
                </div>
              </div>
            </div>
            <div className="ind-panel-options single-row">
              {sourceType.toString() === "true" ?
                  <div className="select-box">
                    <select id="field-select-box" name="collection"
                            name="collection"
                            onChange={(e) => this.handleInputChange(e)}
                            value={containerData.collection}>>
                      <option value="">Choose Collection</option>
                      {sourceData.map((list, index) =>
                          <option key={`multi-area-chart-${index}`}
                                  value={list._id}>{atob(list.title)}</option>
                      )}
                    </select>


                  </div>
                  :
                  <div className="select-box">
                  <select id="field-select-box" name="collection"
                          onChange={(e) => this.handleInputChange(e)}
                          value={containerData.collection}>
                    <option value="">Choose Entity</option>
                    {sourceData.map((list, index) =>

                        <option key={`multi-area-chart-${index}`}
                                value={list._id}>{atob(list._source.name)}</option>
                    )}
                  </select>
                  </div>
              }
            </div>
            {containerData.collection !== undefined &&
            <div className="ind-panel-options single-row">
              <p className="copy-text">{getSubdomain(window.location.hostname)}/{containerData.buttonUrl}/{btoa(containerData.collection)}/{btoa(this.props.linkedId.param)}</p>
              <p className="copy-text">{this.state.copy !==undefined?this.state.copy:""}</p>
              <div className="pay-button-block">
                <button onClick={()=>this.copyData(getSubdomain(window.location.hostname)+"/"+containerData.buttonUrl+"/"+btoa(containerData.collection)+"/"+btoa(this.props.linkedId.param))}  style={{backgroundColor: containerData.bkColr,border: "none",color: containerData.TxtColr}} className="general-btn" >
                  {containerData.chartTitle}
                </button>
              </div>
            </div>
            }
          </div>
      );
    }
    return (
        <React.Fragment>

          <Draggable {...this.props}
                     draggable={!this.props.DragDisabled.dragStatus}
          >
            <SettingsPanel
                id={id}
                name={containerData.chartTitle?containerData.chartTitle:name}
                dropzoneID={dropzoneID}
                parentID={parentID}
                openSetting={this.openSetting}
                removeElement={<div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
            />
          </Draggable>
          {this.state.modalStatus &&
          <EntityModal
              title={`Button  -   Settings`}
              containerData={containerData}
              colorpickerStatus={this.state.colorpickerStatus}
              Close={this.openSetting}
              OKAction={this.chartSaving}
              cancelAction={this.openSetting}
              handleChange={this.handleChange}
              checkBoxchange={this.checkBoxchange}
              radioBoxchange={this.radioBoxchange}
              handleConditionChange={this.handleConditionChange }
              handleLabelChange={this.handleLabelChange}
              handleDefaultField={this.handleDefaultField}
              handleBubblecolorChange={this.colorChange}
              handleColorChange={this.handleColorChange}
              handleInputChange={this.handleInputChange}
              handleClick={this.handleClick}
              onChangeComplete ={this.ColorChange}
              addData={this.addData}
              removeData={this.removeData}
              onDragEnd={this.onDragEnd}
          />
          }


        </React.Fragment>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled,
  EntityData:state.EntityReducer.GetEntity,
  filterCnt:state.DynamicDashboard.getFilterCnt,
  linkedId: state.EntityReducer.LinkedId,
  getsyncRec:state.DynamicDashboard.getsyncRec,
  getFormdetails : state.CollectionsReducer.getFormData,

});
export default  withRouter(connect(mapStateToProps, {
  collections: collectionActions.listallcollections,
  showform : collectionActions.showform,
  chartSaving:DynamicDashboard.saveDashboarddata,
  dragStatus :DynamicDashboard.dragStatus,
  addRecord:DynamicDashboard.addRecord,
  getselectedcollections : formsActions.getFormData,
  saveData:collectionActions.saveData,
  searchEntity : entityActions.Entity,

})(ButtonblockContainer));
