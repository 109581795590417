import React, {Component} from 'react';
import FinancialTile from "./FinancialTile";
import BarChartTile from "./BarChartTile";

function FinancialBlock() {
    return (
        <React.Fragment>
            <div className="stakeholders-header">
                <h5>Financial Snapshot</h5>
            </div>
            <div className="ibt-split-table">

                <FinancialTile title={'Return on Assets'} type={"line_area_bar"} color={"#afce98"} color2={"#7ccbec"}/>
                <FinancialTile title={'Return on Equity'} type={"multi_area"}  color={"#7ccbec"} color2={"#afce98"} />
                <FinancialTile title={'Working Capital Ratio'} type={"line_area"}  color={"#dcddde"} color2={"#ebf2f7"}/>
                <FinancialTile title={'Debt Equity Ratio'} type={"line_area"} color={"#F9B981"} color2={"#ebf2f7"}/>
                
            </div>
        </React.Fragment>


    );

}

export default FinancialBlock;