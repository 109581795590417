import {combineReducers} from 'redux';

const HostEvents = (state = {list: [], users: []}, action) => {
    switch (action.type) {
        case "HOST_EVENTS":
            return {
                ...state,
                list: action.list,
                users: action.users,
                patientprogram: action.patientprogram
            }

        default:
            return state;
    }
}
const SelectedHostEvents = (state = {list: [], users: []}, action) => {
    switch (action.type) {
        case "SELECTED_HOST_EVENTS":
            return {
                ...state,
                list: action.list,
                users: action.users,
                patientprogram: action.patientprogram
            }

        default:
            return state;
    }
}


const Loader = (state = {status: false, element: ''}, action) => {
    switch (action.type) {
        case "UPDATE_LOADER":
            return {
                ...state,
                status: action.status,
                element: action.element
            }

        default:
            return state;
    }
}


const CalendarSettings = (state = {list: []}, action) => {

    switch (action.type) {
        case "CALENDAR_SETTINGS":
            return {
                ...state,
                list: action.list
            }

        default:
            return state;
    }
}
const CalendarHolidays = (state = {list: []}, action) => {

    switch (action.type) {
        case "CALENDAR_HOLIDAYS":
            return {
                ...state,
                list: action.list
            }

        default:
            return state;
    }
}
const HostLeaves = (state = {list: []}, action) => {

    switch (action.type) {
        case "HOST_LEAVES":
            return {
                ...state,
                list: action.list
            }

        default:
            return state;
    }
}

const CalendarMessage = (state = {message: '', show: false}, action) => {

    switch (action.type) {
        case "CALENDAR_MESSAGE":
            return {
                ...state,
                message: action.message,
                show: action.show
            }

        default:
            return state;
    }
}
const BookingStatus = (state = {status: ''}, action) => {

    switch (action.type) {
        case "BOOKED":
            return {
                ...state,
                status: action.status
            }

        default:
            return state;
    }
}

const ErrorMessage = (state = {status: false, element: '', message: ''}, action) => {
    switch (action.type) {
        case "ERROR_MESSAGE":
            return {
                ...state,
                status: action.status,
                element: action.element,
                message: action.message
            }

        default:
            return state;
    }
}


const OTPSend = (state = {status: '', message: ''}, action) => {
    switch (action.type) {
        case "SEND_OTP":
            return {
                ...state,
                status: action.status,
                message: action.message
            }

        default:
            return state;
    }
}
const OTPverified = (state = {status: '', message: ''}, action) => {
    switch (action.type) {
        case "VERIFY_OTP":
            return {
                ...state,
                status: action.status,
                message: action.message
            }

        default:
            return state;
    }
}
const Cities = (state = {data: []}, action) => {
    switch (action.type) {
        case "CITIES":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const Showrooms = (state = {data: []}, action) => {
    switch (action.type) {
        case "SHOWROOMS":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const Salespersons = (state = {data: []}, action) => {
    switch (action.type) {
        case "SALESPERSONS":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const ResourceBooking = (state = {data: []}, action) => {
    switch (action.type) {
        case "RESOURCE_BOOKINGS":
            return {
                ...state,
                data: action.data
            }

        default:
            return state;
    }
}
const ResourceBookingStatus = (state = {success: '', message: '', data: '', code: ''}, action) => {
    switch (action.type) {
        case "SAVE_RESOURCE_BOOKING":
            return {
                ...state,
                success: action.success,
                message: action.message,
                data: action.data,
                code: action.code
            }

        default:
            return state;
    }
}


const BookingSlots = (state = {
    slotList: [],
    allSlots: [],
    availableSlots: [],
    bookedSlots: [],
    leaveList: []
}, action) => {
    switch (action.type) {
        case "GET_ALL_SLOTS":
            return {
                ...state,
                slotList: action.slotList
            }
        case "AGENT_AVAIL_SLOTS":
            return {
                ...state,
                allSlots: action.allSlots,
                availableSlots: action.availableSlots,
                bookedSlots: action.bookedSlots,
            }
        case "AGENT_ALL_LEAVES":
            return {
                ...state,
                leaveList: action.data
            }
        default:
            return state;
    }
}
const LeaveData = (state = {
    leaveList: [],
    leaveDates: [],
    datewiseSlots: []
}, action) => {
    switch (action.type) {
        case "AGENT_ALL_LEAVES":
            return {
                ...state,
                leaveList: action.data,
                leaveDates: action.leavedates,
                datewiseSlots: action.datewiseSlots
            }
        default:
            return state;
    }
}
const GetCallData = (state = {
    updated: false,
    userdata: [],
    callsaved: false,
    scheduleddates: [],
    user_pass: '',
    leaveSaved: false
}, action) => {
    switch (action.type) {
        case "SCHEDULE_LOADER":
            return {
                ...state,
                loader: false
            }
        case "GET_SCHEDULE_CALLS":
            return {
                ...state,
                todayscalls: action.todayscalls,
                allcalls: action.allcalls,
                scheduleddates: action.scheduleddates
            }
        case "UPDATE_CALL_STATUS":
            return {
                ...state,
                updated: action.updated
            }
        case "GET_TROVA_USERINFO":
            return {
                ...state,
                userdata: action.data
            }
        case "SAVE_CALL_SCHEDULE":
            return {
                ...state,
                callsaved: action.saved
            }
        case "SAVE_AGENT_LEAVE":
            return {
                ...state,
                leaveSaved: action.leaveSaved
            }
        case "GET_USER_PASS":
            return {
                ...state,
                user_pass: action.user_pass
            }
        default:
            return state;
    }
}

const GetProfileData = (state = {
    loader: false,
    stateList: [],
    cityList: [],
    leadList: [],
    usersaved: false,
    formJsonArray: []
}, action) => {
    switch (action.type) {
        case "TROVA_PROFILE_LOADER":
            return {
                ...state,
                loader: action.loader
            }
        case "GET_TROVA_FORM_JSON":
            let formJson = state.formJsonArray;
            formJson[action.entityId] = action.formJson[action.entityId];
            return {
                ...state,
                formJsonArray: formJson

            };
            break;
        case "GET_TROVA_STATES":
            return {
                ...state,
                stateList: action.stateList
            }
        case "GET_TROVA_CITIES":
            return {
                ...state,
                cityList: action.cityList
            }
        case "GET_TROVA_LEADS":
            return {
                ...state,
                leadList: action.leadList
            }
        case "SAVE_TROVA_USER":
            return {
                ...state,
                usersaved: action.usersaved,
                loader: action.loader
            }
        default:
            return state;
    }
}
const GetFormJsonData = (state = {
    formJson: []
}, action) => {
    switch (action.type) {

        case "GET_TROVA_FORM_JSON":
            let formJson = state.formJson;
            formJson[action.entityId] = action.formJson[action.entityId];
            return {
                ...state,
                formJson: formJson

            };
            break;
        default:
            return state;
    }
}

export const Scheduler = combineReducers({
    HostEvents,
    SelectedHostEvents,
    Loader,
    CalendarSettings,
    CalendarHolidays,
    HostLeaves,
    CalendarMessage,
    BookingStatus,
    ErrorMessage,
    OTPSend,
    OTPverified,
    Cities,
    Showrooms,
    Salespersons,
    ResourceBooking,
    ResourceBookingStatus,

    BookingSlots,
    GetCallData,
    GetProfileData,
    GetFormJsonData,
    LeaveData

})
export default Scheduler;