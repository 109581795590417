import React, {Component} from 'react';
import {Navbar,Form,Collapse} from "react-bootstrap";
import sidebarLoader from "../images/sidebar-loader.svg";
import CustomFunctions from "../../../utilities/CustomFunctions"
import {getProfile} from "../../../utilities/AuthService";
import {getUrlSegment} from "../../../utilities/CustomFunctions"
import {ES_INDEX} from "../../../constants/index";
import {AddGroup,AddMembers} from "../FormsData";
import SimpleModal from "../../views/SimpleModal"
import {Scrollbars} from "react-custom-scrollbars";
import { Accordion, Card, Button } from 'react-bootstrap';
import {Spinner} from "react-bootstrap";
class NavBarMenu extends Component{
    constructor(props) {
        super(props);
        this.state={
            toggleStatus:true,
            filterMenu:''
        }
        this.CustomFunctions = new CustomFunctions();
        this.menuToggle=this.menuToggle.bind(this)
    }
    menuToggle(){

        this.setState({toggleStatus:!this.state.toggleStatus})
    }

    render(){
        return(
        <Navbar className={'side-nav-in'}>
            <span className={'list-all-head'}>{this.props.title}</span>

        </Navbar>
        )
    }
}
export default NavBarMenu;

class Task   extends Component {
    constructor(props) {
        super(props);
        this.state={
            filterMenu:'',
            showPoolSub: false
        }
    }

    menuSubClick(e, filterMenu){
        this.props.gettaskList(filterMenu)
        this.setState({filterMenu : filterMenu});
    }
    toggleTaskMenu(value, name) {
        this.setState({[value]: !this.state[[value]]});
    }
    render() {
        let poolMenu = this.props.poolMenu.poolMenu;
        let poolMenus = poolMenu !== undefined ? poolMenu : '';
        let sideBarMenuData=this.props.searchData.task.active?this.props.sidebarsearchmenudata:this.props.sidebarmenudata;
        let prjctdtlid = getUrlSegment(3) !== undefined ? getUrlSegment(3) : '';
        return (
            <div className={`side-menu-right w-pad`}>
                <NavBarMenu
                    menuToggle={this.props.menuToggle}
                    toggleStatus={this.props.toggleStatus}
                    title="Task"
                />
                <Scrollbars
                    style={{ width: 220,height: 'calc(100% - 110px)'}}
                >
                    <div className={'menu-section active'}>
                        <h5 className={'nav-menu-in-head'}>By Action</h5>
                        <ul className={'ind-menu-section'}>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/task/todo')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/task/todo' ? "active" : ""}`}>My ToDo
                                    <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/>  :
                                        sideBarMenuData.todo
                                    })</span>
                                </Navbar>
                            </li>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/task/to-follow-up')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/task/to-follow-up' ? "active" : ""}`}>To Follow Up
                                    <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.tofollowup})</span>
                                </Navbar>
                            </li>

                            {/*<li>*/}
                            {/*    <Navbar onClick={(e) => this.props.mainClick('task', '/task/to-verify')}*/}
                            {/*            className={`menu-sec-click ${this.props.menuClickData === '/task/to-verify' ? "active" : ""}`}>To*/}
                            {/*        Verify <span className="left-filter-count">({sideBarMenuData.loading ?*/}
                            {/*            <img src={sidebarLoader}/> : sideBarMenuData.toverify})</span>*/}
                            {/*    </Navbar>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Navbar onClick={(e) => this.props.mainClick('task', '/task/to-comment')}*/}
                            {/*            className={`menu-sec-click ${this.props.menuClickData === '/task/to-comment' ? "active" : ""}`}>To*/}
                            {/*        Comment <span className="left-filter-count">({sideBarMenuData.loading ?*/}
                            {/*            <img src={sidebarLoader}/> : sideBarMenuData.comments})</span>*/}
                            {/*    </Navbar>*/}
                            {/*</li>*/}

                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/task/to-respond')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/task/to-respond' ? "active" : ""}`}>
                                    To Respond <span className="left-filter-count">({sideBarMenuData.loading ?
                                    <Spinner animation="border" size="sm"/> : sideBarMenuData.responds})</span>
                                </Navbar>
                            </li>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/task/to-know')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/task/to-know' ? "active" : ""}`}>To
                                    Know <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.toknow})</span>
                                </Navbar>
                            </li>
                            {getProfile().overseeStatus === "1" &&
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/task/to-oversee')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/task/to-oversee' ? "active" : ""}`}>To
                                    Oversee <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.tooversee})</span>
                                </Navbar>
                            </li>
                            }

                        </ul>
                    </div>
                    <div className={'menu-section'}>
                        <h5 className={'nav-menu-in-head'}>By Date</h5>
                        <ul className={'ind-menu-section'}>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/task/due-today')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/task/due-today' ? "active" : ""}`}>Due Today
                                    <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.duetoday})</span>
                                </Navbar>
                            </li>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/task/due-tomorrow')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/task/due-tomorrow' ? "active" : ""}`}>Due Tomorrow
                                    <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.duetomorrow})</span>
                                </Navbar>
                            </li>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/task/due-in-seven-days')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/task/due-in-seven-days' ? "active" : ""}`}>Due in 7 days
                                    <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.dueinsevendays})</span>
                                </Navbar>
                            </li>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/task/due-this-month')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/task/due-this-month' ? "active" : ""}`}>Due This Month
                                    <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.duethismaonth})</span>
                                </Navbar>
                            </li>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('task', '/overdue/customdaterange')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/overdue/customdaterange' ? "active" : ""}`}>
                                    Custom Date Range  <span className="left-filter-count"></span>
                                </Navbar>
                            </li>
                        </ul>
                    </div>

                    <div className={'menu-section'}>
                        <h5 className={'nav-menu-in-head'} onClick={(e) => this.toggleTaskMenu('showOverdueSub', 'overdue')}>OVERDUE</h5>
                        <Collapse in={this.state.showOverdueSub}>
                            <ul className={'ind-menu-section'}>
                                <li>
                                    <Navbar onClick={(e) => this.props.mainClick('task', '/due/one-to-seven-days')}
                                            className={`menu-sec-click ${this.props.menuClickData === '/due/one-to-seven-days' ? "active" : ""}`}>
                                        1-7 Days <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.overdueinsevendays})</span>
                                    </Navbar>
                                </li>
                                <li>
                                    <Navbar onClick={(e) => this.props.mainClick('task', '/due/seven-to-fourteen-days')}
                                            className={`menu-sec-click ${this.props.menuClickData === '/due/seven-to-fourteen-days' ? "active" : ""}`}>
                                        7-14 Days <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.overdueinfifteendays})</span>
                                    </Navbar>
                                </li>
                                <li>
                                    <Navbar onClick={(e) => this.props.mainClick('task', '/due/fifteen-to-thirty-days')}
                                            className={`menu-sec-click ${this.props.menuClickData === '/due/fifteen-to-thirty-days' ? "active" : ""}`}>
                                        15-30 Days <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.overdueinthirtydays})</span>
                                    </Navbar>
                                </li>
                                <li>
                                    <Navbar onClick={(e) => this.props.mainClick('task', '/due/thirty-to-sixty-days')}
                                            className={`menu-sec-click ${this.props.menuClickData === '/due/thirty-to-sixty-days' ? "active" : ""}`}>
                                        30-60 Days <span className="left-filter-count">({sideBarMenuData.loading ?
                                        <Spinner animation="border" size="sm"/> : sideBarMenuData.overdueinsixtydays})</span>
                                    </Navbar>
                                </li>
                                <li>
                                    <Navbar onClick={(e) => this.props.mainClick('task', '/task/all-overdues')}
                                            className={`menu-sec-click ${this.props.menuClickData === '/task/all-overdues' ? "active" : ""}`}>All Overdues
                                        <span className="left-filter-count">({sideBarMenuData.loading ?
                                            <Spinner animation="border" size="sm"/> : sideBarMenuData.alloverdues})</span>
                                    </Navbar>
                                </li>
                            </ul>
                        </Collapse>
                    </div>






                    <div className={'menu-section'}>
                        <h5 className={'nav-menu-in-head'}  onClick={(e) => this.toggleTaskMenu('showPoolSub', 'pool')}>Task Pool</h5>
                        <Collapse in={this.state.showOverdueSub}>
                        <ul className={'ind-menu-section'}>
                            {poolMenu.map((item, index) =>
                                <li>
                                    <Navbar onClick={(e) => this.props.mainClick('task', '/task/pool/' + item['_id'])}
                                            className={`menu-sec-click ${this.props.menuClickData === '/task/pool/' + item['_id'] ? "active" : ""}`}> {item['title']} ({item['task_count']})
                                    </Navbar>
                                </li>
                            )}
                        </ul>
                        </Collapse>
                    </div>
                    <div className={'menu-section'} onClick={(e) => this.props.mainClick('task', '/task/archive')}>
                        <h5 className={'nav-menu-in-head'}>Archive <span className="left-filter-count">({sideBarMenuData.loading ?
                            <Spinner animation="border" size="sm"/> : sideBarMenuData.archive})</span></h5>
                    </div>
                </Scrollbars>
                <div className="side-menu-footer"><p>© Zynapse <span>2022</span></p></div>
            </div>
        );
    }
}
class Forms   extends Component {
    constructor(props) {
        super(props);
        this.FormFilter=this.FormFilter.bind(this)
        this.Catagory = []
    }
    FormFilter(e){
        var existCatagory = this.Catagory.indexOf(e.target.value);
        if(existCatagory === -1){
            this.Catagory.push(e.target.value)
        }
        else{
            var index = this.Catagory.indexOf(e.target.value);
            this.Catagory.splice(index, 1);
        }
      this.props.formsFilter(this.Catagory.toString() === '' ? 'All' :this.Catagory.toString(),this.props.listAllFormsData.Search,"2",'root',0,"0",20,"lsinternal")
    }

    render() {
        return (

            <div className={`side-menu-right w-pad`}>

                <NavBarMenu
                    menuToggle={this.props.menuToggle}
                    toggleStatus={this.props.toggleStatus}
                    title="Forms"
                />
                <Scrollbars
                    //removeTracksWhenNotUsed={false}
                    //trackYProps={{style: {opacity: 0}}}
                    style={{ width: 220}}
                >
                {this.props.getforms.getForms.data.map((list,index) =>
                    <Form.Check inline label={list.name} value={list.value} type={`checkbox`} id={`inline-${`checkbox`}-1`} onClick={this.FormFilter}/>


                )}
                </Scrollbars>
            </div>


                );
    }
}

class Collections   extends Component {
    constructor(props) {
        super(props);
        this.CollectionFilter = this.CollectionFilter.bind(this);
        this.toggleCat = this.toggleCat.bind(this);
        this.Catagory = [];
        this.state = {opened: true};
    }
    CollectionFilter(e){
        var existCatagory = this.Catagory.indexOf(e.target.value);
        if(existCatagory === -1) {
            this.Catagory.push(e.target.value)
        } else {
            var index = this.Catagory.indexOf(e.target.value);
            this.Catagory.splice(index, 1);
        }
        this.props.collectionsFilter(this.Catagory.toString() === '' ? 'All' :this.Catagory.toString(),this.props.listAllCollections.Search,"2",'root',0,"0",20,"lsinternal")
    }
    toggleCat() {
        this.setState({opened: !this.state.opened});
    }
    render() {
         return (

            <div className={`side-menu-right w-pad`}>

                <NavBarMenu
                    menuToggle={this.props.menuToggle}
                    toggleStatus={this.props.toggleStatus}
                    title="Collections"
                />
                <Scrollbars
                    //removeTracksWhenNotUsed={false}
                    //trackYProps={{style: {opacity: 0}}}
                    style={{ width: 220}}
                >
                    <div className="menu-section active">
                        <h5 className="nav-menu-in-head">Category</h5>
                        <div className="select-wrap">

                            {this.props.categories.map((list,index) =>
                                <div className="select-chk-block" id="collection_menu" style={{"display": "block"}}>
                                    <label className="control control-checkbox"> {list.categoryName}
                                        <input type="checkbox" className="inp-chk" value={list.categoryValue}
                                               name="filter_cat" onClick={this.CollectionFilter}/>

                                        <div className="control-indicator"></div>
                                    </label>

                                </div>
                            )}




                        </div>

                    </div>
                </Scrollbars>
            </div>


        );
    }
}

class Chat   extends Component {
    constructor(props) {
        super(props);
        this.state={
            user_id:'',
            type:'',
            status:''
        }
        this.ChatHistory=this.ChatHistory.bind(this)
        this.handleAddTeam=this.handleAddTeam.bind(this)
        this.addTeam=this.addTeam.bind(this)
        this.Close=this.Close.bind(this)
        this.OnlineUsers=this.OnlineUsers.bind(this)
    }

    ChatHistory(uid,user_id,limit,offset,index,type,name,designation,image){
        this.setState({userid:user_id,status:false})
        this.props.loadChatHistory(uid, user_id, limit, offset, ES_INDEX, type,name,designation,image)
        if(type ==='group'){
            this.props.groupInfo(user_id,index)
            this.props.allGroups(getProfile().id,0,500,ES_INDEX);
        }
        this.props.loadSidebarUsers()
    }
    handleAddTeam(Type,Status){
        this.setState({type:Type,status:Status})
    }

    addTeam(id,name,desc,logo,index,type){
        if(type==='group'){
            this.props.createGroup(id,name,desc,logo,ES_INDEX)
        }
    }

    Close(){
        this.setState({type:'',status:false})
    }

    componentDidMount() {


    }
    OnlineUsers(val) {
        //console.log(this.props.onlineUsers.users.filter(p => val.toString() === p.User_ID))
        var OnlineStatus= this.props.onlineUsers.users.filter(p => val.toString() === p.User_ID);
        if(OnlineStatus.length !==0)
            return true;
        else
            return false;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <div className={`side-menu-right w-pad`}>
                <NavBarMenu
                    menuToggle={this.props.menuToggle}
                    toggleStatus={this.props.toggleStatus}
                />
                <Scrollbars
                    style={{ width: 220}}
                >
                    <div className="chat-option-section-holder">
                        <div className="chat-option-section">
                            <div className="chat-header-block">
                                <h5 className="chat-type-head">Channel
                                   <span className={`loader-span`}>
                                       {this.props.loader.loaderType === 'sidebarUpdate' && this.props.loader.status &&
                                       <img src={sidebarLoader}/>
                                    }
                                   </span>
                                </h5>
                                {((getProfile().systype === 'root') || (getProfile().systype === 'Superadmin') || getProfile().channel_permission === "1") &&
                                <button className="icon-in icon-add-in add-button" onClick={()=>this.handleAddTeam('group',true)}></button>
                                }
                            </div>
                            <ul className="chat-option-list chat-option-group">
                                {this.props.groupData.ListAllgroups.data.map((list, index)=>
                                    <li id={`user-${list.groupid}`} className={`chat-option-list-items ${this.props.chatUserId.ReceiverUserid===list.groupid? 'chat-selected' :''}`} key={'channel-listing-'+index}>
                                        <p
                                            className={`chat-list-user ${list.unreadmessage !==0 ? 'new-message-in' : ''}`}
                                            onClick={()=>this.ChatHistory("",list.groupid,0,100,ES_INDEX,'group',list.groupname,'','')}>
                                            <span className={`chat-user`} >{atob(list.groupname)}</span>
                                        </p>

                                    </li>

                                )}

                            </ul>
                        </div>

                        <div className="chat-option-section">
                            <div className="chat-header-block">
                                <h5 className="chat-type-head">Users</h5>
                                <button className="icon-in icon-add-in add-button" onClick={()=>this.handleAddTeam('users',true)}></button>

                            </div>
                            <ul className="chat-option-list chat-option-user">
                                {this.props.chatUsers.users.map((list,index) => {
                                        this['chatcount'+list.userid]=list.unreadmessage
                                        return(
                                            <li id={`user-${list.userid}`} key={`channel-users-`+index}
                                                className={`chat-option-list-items ${this.state["user-"+list.userid] !== undefined && this.state["user-"+list.userid]} ${this.props.chatUserId.ReceiverUserid.toString() === list.userid.toString() ? 'chat-selected' : ''} ${this.OnlineUsers(list.userid) ? 'user-active' : ''}`}>
                                                <p className="chat-list-user"
                                                   onClick={() => this.ChatHistory(getProfile().id, list.userid, 0, 100, ES_INDEX, 'single', list.firstname + ' ' + list.lastname, list.designation, list.userhead)}><span className={`chat-user`} >{list.firstname}</span>
                                                    <span className="noti_count" id={`chat`+list.userid} style={(this.state.userid !== list.userid && list.unreadmessage !==0)   ?{dispaly:'block'}:{display:'none'}}>
                                                     <span  className="chat-new-count"> {list.unreadmessage}</span>
                                            </span>

                                                </p>
                                            </li>

                                    )

                                    }
                                )}


                            </ul>
                        </div>
                    </div>
                </Scrollbars>

                {this.state.status === true &&
                <SimpleModal
                    Close={this.Close}
                    title={this.state.type === 'users'? 'Direct Messages':'Create Channel'}
                >
                    {this.state.type === 'users' &&
                    <AddMembers
                        allUsers={this.props.allusers.ListAllUsers}
                        chatUsers={this.props.chatUsers}
                        ChatHistory={this.ChatHistory}
                    />
                    }
                    {this.state.type === 'group' &&
                    <AddGroup
                        addTeam={this.addTeam}
                        Close={this.Close}


                    />
                    }


                </SimpleModal>
                }

            </div>


        );
    }
}

class Project  extends Component {
    constructor(props) {
        super(props);
        this.state={
            Countloading:0,
            showMetricSub : false,
            showDataSub:false,
            showCommuSub:false,
            showUserSub:false,
            sectors : [],
            vertical :[]
        }
        this.Catagory = []
    }

    toggleMenu(value, name, url) {
        this.setState({[value] : !this.state[[value]]});
        let prjctdtlid = getUrlSegment(5)?getUrlSegment(5):getUrlSegment(4);
    }


    render() {
        let formdtls= getUrlSegment(1);
        let formdetails= getUrlSegment(2);
        let formnotes= getUrlSegment(3);
        // let prjctdtlid= getUrlSegment(4);
        let prjctdtlid = getUrlSegment(5)?getUrlSegment(5):getUrlSegment(4);
        if(formnotes === 'collectionsdetails' ) {
            prjctdtlid = '';
        }


        let Ownprjct = this.props.allprojects.ListAllProjects.owncount;
        let sharedprjct = this.props.allprojects.ListAllProjects.sharedcount;
        let sideBarProjectCnt = this.props.allprojects.ListAllProjects ? this.props.sidebarsearchmenudata:this.props.sidebarmenudata;
        let sectorList = this.props.sectorList? this.props.sectorList:[];
        let verticalList = this.props.VerticalList ? this.props.VerticalList:[];
       // let collections = this.props.listAllCollections.ListAllCollections ? this.props.listAllCollections.ListAllCollections.data : []
        let projectmenuData = this.props.projectmenuData;
         let submoduleData = this.props.projectmenuData.length !==0  ? this.props.projectmenuData[0].submodule : [];

        let hasDashAccess = false;
        let DashData = submoduleData.filter(item => (item.module === "Dashboard" && item.module_permission === "yes"));
        if(DashData.length > 0) {
            hasDashAccess = true;
        }
        let hasCDashAccess = false;
        let cDashData = submoduleData.filter(item => (item.module === "Cdashboard" && item.module_permission === "yes"));
        if(cDashData.length > 0) {
            hasCDashAccess = true;
        }

        return (

            <div className={`side-menu-right w-pad`}>
                <Navbar className={'side-nav-in'}>
                    <button className="toggle-btn ico-lib switch-right-ico no-ev">Projects</button>
            </Navbar>
                {formdtls.toLowerCase() === 'project' && formdetails !== 'details' && formnotes !== 'notes' &&
                <Scrollbars style={{"height":"calc(100% - 100px)"}}>

                <div className={'menu-section active'}>
                    <Navbar onClick={(e) => this.props.projectSubClick("All")} className={`filter-header ${this.props.menuClickData === '/project' ? "active" : ""}`}>All Projects
                        <span className="left-filter-count">({this.props.allprojects.loading ?
                            <img src={sidebarLoader}/> :
                            Ownprjct
                        })</span>

                    </Navbar>
                    {/*<h5 className={'nav-menu-in-head'}>By Action</h5>
                    <ul className={'ind-menu-section'}>
                        <li>
                            <Navbar onClick={(e) => this.props.mainClick('project', '/project/my_projects')} className={`menu-sec-click ${this.props.menuClickData === '/project/my_projects' ? "active" : ""}`}>My Projects
                                <span className="left-filter-count">({this.props.allprojects.loading ?
                                    <img src={sidebarLoader}/> :
                                    Ownprjct
                                })</span>

                            </Navbar>
                        </li>
                        <li>
                            <Navbar onClick={(e) => this.props.mainClick('project', '/project/shared_projects')}
                                    className={`menu-sec-click ${this.props.menuClickData === '/project/shared_projects' ? "active" : ""}`}>

                                Other Projects
                                <span className="left-filter-count">({this.props.allprojects.loading ?
                                    <img src={sidebarLoader}/> :
                                    sharedprjct
                                })</span>

                            </Navbar>
                        </li>


                    </ul>*/}


                    <Accordion className="accordian-filter">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <h5 className={'nav-menu-in-head'}>By Sector</h5>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <ul className={'ind-menu-section'}>
                                        {sectorList.map((list,index)=>
                                            <li key={index}>
                                                <Navbar onClick={(e) => this.props.projectSubClick("sector", list.portfolioId)}
                                                        className={`menu-sec-click ${this.props.sectors.includes(list.portfolioId)  ? "active" : ""}`}
                                                >
                                                    {list.portfolioName} ({list.projectCount})

                                                </Navbar>
                                            </li>
                                        ) }
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    <h5 className={'nav-menu-in-head'}>By Vertical</h5>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1" >
                                <Card.Body>
                                    <ul className={'ind-menu-section'}>
                                        {verticalList.map((list,index)=>
                                            <li key={index}>
                                                <Navbar  onClick={(e) => this.props.projectSubClick("vertical", list.programId)}
                                                         className={`menu-sec-click ${this.props.vertical.includes(list.programId) ? "active" : ""}`}
                                                >
                                                 {list.programName} ({list.activeProjects})


                                                </Navbar>
                                            </li>
                                        ) }

                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
                </Scrollbars>
                }

                {formdetails == 'details' && formnotes !== 'notes' &&
                    <React.Fragment>
                        {submoduleData.length > 0 &&
                            <Scrollbars style={{"height":"calc(100% - 100px)"}}>
                                    <div className="sub-menu-right-block nicescroll ibt-drop">
                                        {!hasDashAccess && hasCDashAccess &&
                                        <div
                                            className={`menu-section no-drop-val ${this.props.menuClickData === '/project/details/cdashboard/' + prjctdtlid ? "active" : ""}`}>
                                            <h5 onClick={(e) => this.props.mainClick('project', '/project/details/cdashboard/' + prjctdtlid)}
                                                className={`sub-menu ico-lib-v1 pj-ov-ico ${this.props.menuClickData === '/project/details/cdashboard/' + prjctdtlid ? "active" : ""}`}>Dashboard</h5>
                                        </div>
                                        }
                                        {submoduleData.map((list,index)=>

                                            {

                                                return (
                                                    <React.Fragment key={index}>

                                                        {hasDashAccess && list.module === "Dashboard" && list.module_permission === "yes" &&
                                                        <div
                                                            className={`menu-section no-drop-val ${this.props.menuClickData === '/project/details/dashboard/' + prjctdtlid ? "active" : ""}`}>
                                                            <h5 onClick={(e) => this.props.mainClick('project', '/project/details/dashboard/' + prjctdtlid)}
                                                                className={`sub-menu ico-lib-v1 pj-ov-ico ${this.props.menuClickData === '/project/details/dashboard/' + prjctdtlid ? "active" : ""}`}>Dashboard</h5>
                                                        </div>
                                                        }
                                                        {list.module === "Project Metrics" && list.module_permission === "yes" &&
                                                        <div
                                                            className={`menu-section ${this.state.showMetricSub === true ? "active" : ""}`}>
                                                            <h5 onClick={(e) => this.toggleMenu('showMetricSub', 'metrics', '/project/details/metrics/' + prjctdtlid)}
                                                                className={`sub-menu ico-lib-v2 pj-metrics-ico ${this.state.showMetricSub === true ? "active" : ""}`}>Project
                                                                Metrics</h5>
                                                            <Collapse in={this.state.showMetricSub}>
                                                                <ul className="ind-menu-section">
                                                                    {list.submodule && list.submodule.map((sublist,index)=>
                                                                        <React.Fragment key={index}>
                                                                            {sublist.module === "Impact" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => this.props.mainClick('project', '/project/details/impact/' + prjctdtlid)}
                                                                                   className={`menu-sec-click ${this.props.menuClickData === '/project/details/impact/' + prjctdtlid ? "active" : ""}`}>Impact </a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Financial" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => this.props.mainClick('project', '/project/details/financial/' + prjctdtlid)}
                                                                                   className={`menu-sec-click ${this.props.menuClickData === '/project/details/financial/' + prjctdtlid ? "active" : ""}`}>Financial </a>
                                                                            </li>
                                                                            }
                                                                        </React.Fragment>
                                                                    )}
                                                                </ul>
                                                            </Collapse>

                                                        </div>
                                                        }
                                                        {list.module === "Team" && list.module_permission === "yes" &&
                                                        <div
                                                            className={`menu-section no-drop-val ${this.props.menuClickData === '/project/details/team/' + prjctdtlid ? "active" : ""}`}>
                                                            <h5 onClick={(e) => this.props.mainClick('project', '/project/details/team/' + prjctdtlid)}
                                                                className={`sub-menu ico-lib-v1 pj-team-ico ${this.props.menuClickData === '/project/details/team/' + prjctdtlid ? "active" : ""}`}>Team</h5>
                                                        </div>
                                                        }
                                                        {list.module === "Project Management" && list.module_permission === "yes" &&
                                                        <div
                                                            className={`menu-section ${(this.props.menuClickData === '/project/details/management/' + prjctdtlid || this.props.menuClickData === '/project/details/tasks/' + prjctdtlid) ? "active" : ""}`}>
                                                            <h5 onClick={(e) => this.props.mainClick('project', '/project/details/management/' + prjctdtlid)}
                                                                className={`sub-menu ico-lib-v2 pj-circle-ico ${(this.props.menuClickData === '/project/details/management/' + prjctdtlid || this.props.menuClickData === '/project/details/tasks/' + prjctdtlid) ? "active" : ""}`}>Project
                                                                Management</h5>
                                                        </div>
                                                        }
                                                        {list.module === "Stakeholders" && list.module_permission === "yes" &&
                                                        <div
                                                            className={`menu-section ${this.state.showUserSub === true ? "active" : ""}`}>

                                                            <h5 onClick={(e) => this.toggleMenu('showUserSub', 'stakeholder', '/project/details/stakeholders/' + prjctdtlid)}
                                                                className={`sub-menu ico-lib-v2 pj-sh-ico ${this.state.showUserSub === true ? "active" : ""}`}>Stakeholders</h5>
                                                            <Collapse in={this.state.showUserSub}>
                                                                <h6 className="nested-sub-header">Capital Structure</h6>
                                                            </Collapse>
                                                            <Collapse in={this.state.showUserSub}>
                                                                <ul className="ind-menu-section">
                                                                    {list.submodule && list.submodule.map((sublist,index)=>
                                                                        <React.Fragment  key={index}>
                                                                            {sublist.module === "Equity" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Equity') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('870100517', 'Equity')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "Equity" ? "menu-sec-click active" : "menu-sec-click"}>Equity</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Convertible" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Convertible') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('870100517', 'Convertible')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "Convertible" ? "menu-sec-click active" : "menu-sec-click"}>Convertible</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Mezzanine Debt" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Mezzanine Debt') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('870100517', 'Mezzanine Debt')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "Mezzanine Debt" ? "menu-sec-click active" : "menu-sec-click"}>Mezzanine
                                                                                    Debt</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Debt" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Debt') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('870100517', 'Debt')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "Debt" ? "menu-sec-click active" : "menu-sec-click"}>Debt</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Others" && sublist.module_permission === "yes" && sublist.subtype === "Capital Structure" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Others') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('870100517', 'Others')
                                                                                }}
                                                                                   className={(this.props.Stakeholdermenudata.title === "Others" && this.props.Stakeholdermenudata.masterId === '870100517') ? "menu-sec-click active" : "menu-sec-click"}>Others</a>
                                                                            </li>
                                                                            }
                                                                        </React.Fragment>
                                                                    )}
                                                                </ul>
                                                            </Collapse>
                                                            <Collapse in={this.state.showUserSub}>
                                                                <h6 className="nested-sub-header">Project Management</h6>
                                                            </Collapse>
                                                            <Collapse in={this.state.showUserSub}>
                                                                <ul className="ind-menu-section">
                                                                    {list.submodule && list.submodule.map((sublist,index)=>
                                                                        <React.Fragment  key={index}>
                                                                            {sublist.module === "EPC" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('EPC') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('429353536', 'EPC')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "EPC" ? "menu-sec-click active" : "menu-sec-click"}>EPC</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Contractors" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Contractors') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('429353536', 'Contractors')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "Contractors" ? "menu-sec-click active" : "menu-sec-click"}>Contractors</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Advisors" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Advisors') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('429353536', 'Advisors')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "Advisors" ? "menu-sec-click active" : "menu-sec-click"}>Advisors</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "O&M Contractor" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('O&M Contractor') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('429353536', 'O&M Contractor')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "O&M Contractor" ? "menu-sec-click active" : "menu-sec-click"}>O&M Contractor</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Insurance" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Insurance') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('429353536', 'Insurance')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "Insurance" ? "menu-sec-click active" : "menu-sec-click"}>Insurance</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Local Authority" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Local Authority') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('429353536', 'Local Authority')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "Local Authority" ? "menu-sec-click active" : "menu-sec-click"}>Local Authority</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Local Community" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa(' Local Community') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('429353536', 'Local Community')
                                                                                }}
                                                                                   className={this.props.Stakeholdermenudata.title === "Local Community" ? "menu-sec-click active" : "menu-sec-click"}>Local Community</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Others" && sublist.module_permission === "yes" && sublist.subtype === "Project Management" &&
                                                                            <li>
                                                                                <a onClick={(e) => {
                                                                                    this.props.mainClick('project', '/project/details/stakeholders/' + btoa('Others') + '/' + prjctdtlid);
                                                                                    this.props.stakeholdermenuUpdate('429353536', 'Others')
                                                                                }}
                                                                                   className={(this.props.Stakeholdermenudata.title === "Others" && this.props.Stakeholdermenudata.masterId === '429353536') ? "menu-sec-click active" : "menu-sec-click"}>Others</a>
                                                                            </li>
                                                                            }

                                                                        </React.Fragment>
                                                                    )}
                                                                </ul>
                                                            </Collapse>
                                                        </div>
                                                        }
                                                        {list.module === "Data" && list.module_permission === "yes" &&
                                                        <div
                                                            className={`menu-section ${this.state.showDataSub === true ? "active" : ""}`}>
                                                            <h5 className={`sub-menu ico-lib-v2 pj-dataroom-ico ${this.state.showDataSub === true ? "active" : ""}`}
                                                                onClick={(e) => this.toggleMenu('showDataSub', 'data', '/project/details/metric/' + prjctdtlid)}>Data</h5>
                                                            <Collapse in={this.state.showDataSub}>
                                                                <ul className="ind-menu-section">
                                                                    {list.submodule && list.submodule.map((sublist,index)=>
                                                                        <React.Fragment>
                                                                            {sublist.module === "Data Room" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a className={`menu-sec-click ${this.props.menuClickData === '/project/details/files/' + prjctdtlid ? "active" : ""}`}
                                                                                   onClick={(e) => this.props.mainClick('project', '/project/details/files/' + prjctdtlid)}>Data
                                                                                    Room </a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Data Collection" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a className={`menu-sec-click ${this.props.menuClickData === '/project/details/collections/' + prjctdtlid ? "active" : ""}`}
                                                                                   onClick={(e) => this.props.mainClick('project', '/project/details/collections/' + prjctdtlid)}>Data
                                                                                    Collection</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "IOT Collection" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a className={`menu-sec-click ${this.props.menuClickData === '/project/details/iotcollections/' + prjctdtlid ? "active" : ""}`}
                                                                                   onClick={(e) => this.props.mainClick('project', '/project/details/iotcollections/' + prjctdtlid)}>IOT
                                                                                    Collection </a>
                                                                            </li>
                                                                            }
                                                                            {/*<li>*/}
                                                                            {/*    <a className={`menu-sec-click ${this.props.menuClickData === '/project/details/insights/'+prjctdtlid  ? "active" :""}`} onClick={(e) => this.props.mainClick('project', '/project/details/insights/'+prjctdtlid)}>Insights </a>*/}
                                                                            {/*</li>*/}
                                                                        </React.Fragment>
                                                                    )}
                                                                </ul>
                                                            </Collapse>

                                                        </div>
                                                        }
                                                        {list.module === "Communication" && list.module_permission === "yes" &&
                                                        <div
                                                            className={`menu-section ${this.state.showCommuSub === true ? "active" : ""}`}>
                                                            <h5 className={`sub-menu ico-lib-v2 pj-com-ico ${this.state.showCommuSub === true ? "active" : ""}`}
                                                                onClick={(e) => this.toggleMenu('showCommuSub', 'communication', '/project/details/communication/' + prjctdtlid)}>Communication</h5>
                                                            <Collapse in={this.state.showCommuSub}>
                                                                <ul className="ind-menu-section">
                                                                    {list.submodule && list.submodule.map((sublist,index)=>
                                                                        <React.Fragment>
                                                                            {sublist.module === "Chat" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a className={`menu-sec-click ${this.props.menuClickData === '/project/details/chat/' + prjctdtlid ? "active" : ""}`}
                                                                                   onClick={(e) => this.props.mainClick('project', '/project/details/chat/' + prjctdtlid)}>Chat</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "News and Media" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a className={`menu-sec-click ${this.props.menuClickData === '/project/details/newsmedia/' + prjctdtlid ? "active" : ""}`}
                                                                                   onClick={(e) => this.props.mainClick('project', '/project/details/newsmedia/' + prjctdtlid)}>News
                                                                                    and Media</a>
                                                                            </li>
                                                                            }
                                                                            {sublist.module === "Community" && sublist.module_permission === "yes" &&
                                                                            <li>
                                                                                <a className={`menu-sec-click ${this.props.menuClickData === '/project/details/community/' + prjctdtlid ? "active" : ""}`}
                                                                                   onClick={(e) => this.props.mainClick('project', '/project/details/community/' + prjctdtlid)}>Community</a>
                                                                            </li>
                                                                            }
                                                                        </React.Fragment>
                                                                    )}
                                                                </ul>
                                                            </Collapse>
                                                        </div>

                                                        }
                                                        {list.module === "Notifications" && list.module_permission === "yes" &&
                                                        <div
                                                            className={`menu-section ${this.props.menuClickData === '/project/details/notification/' + prjctdtlid ? "active" : ""}`}>
                                                            <h5 className={`sub-menu ico-lib-v2 pj-notf-ico ${this.props.menuClickData === '/project/details/notification/' + prjctdtlid ? "active" : ""}`}
                                                                onClick={(e) => this.props.mainClick('project', '/project/details/notification/' + prjctdtlid)}>Notifications</h5>
                                                        </div>

                                                        }
                                                        {list.module === "Settings" && list.module_permission === "yes" &&
                                                        <div
                                                            className={`menu-section ${this.props.menuClickData === '/project/details/settings/' + prjctdtlid ? "active" : ""}`}>
                                                            <h5 className={`sub-menu ico-lib-v2 pj-set-ico ${this.props.menuClickData === '/project/details/settings/' + prjctdtlid ? "active" : ""}`}
                                                                onClick={(e) => this.props.mainClick('project', '/project/details/settings/' + prjctdtlid)}>Settings</h5>
                                                        </div>

                                                        }
                                                    </React.Fragment>
                                                )
                                            }


                                            )}

                                     </div>
                             </Scrollbars>
                        }
                    </React.Fragment>
                }

                {formnotes == 'notes' &&
                <div className="chat-side-bar pool-side">
                    <div className="ind-chat-section">
                        <div className="chat-item-header-block">
                            <h5>Pinned Notes</h5>
                        </div>
                        <ul className="chat-item-block">
                            <li>
                                <a className="chat-item ico-lib-v1 name-pen">Dhanya Ninan</a>
                            </li>
                            <li>
                                <a className="chat-item ico-lib-v1 private-data">Private
                                    Data</a>
                            </li>
                        </ul>
                    </div>

                    <div className="ind-chat-section">
                        <div className="chat-item-header-block">
                            <h5>Notes List</h5>
                            <button className="ico-lib add-chat-button"></button>
                        </div>
                        <ul className="chat-item-block">
                            <li>
                                <a  className="chat-item ico-lib-v1 analytics-reports">Analytics
                                    Reports</a>
                            </li>
                            <li>
                                <a className="chat-item ico-lib-v1 event-schedules">Event
                                    Schedules</a>
                            </li>
                            <li>
                                <a className="chat-item ico-lib-v1 server-details">Server
                                    Details</a>
                            </li>
                            <li>
                                <a className="chat-item ico-lib-v1 legal-notes">Legal
                                    Notes</a>
                            </li>
                            <li className="active-nt">
                                <a className="chat-item ico-lib-v1 confidential-notes">Confidential
                                    Notes</a>
                            </li>
                            <li>
                                <a className="chat-item ico-lib-v1 project-notes">Project
                                    Notes</a>
                            </li>
                        </ul>
                    </div>
                </div>
                }
            </div>


        );
    }
}
class Entity   extends Component {
    constructor(props) {
        super(props);
        this.CollectionFilter = this.CollectionFilter.bind(this);
        this.toggleCat = this.toggleCat.bind(this);
        this.Catagory = [];
        this.state = {opened: true};
    }
    CollectionFilter(e){
        var existCatagory = this.Catagory.indexOf(e.target.value);
        if(existCatagory === -1) {
            this.Catagory.push(e.target.value)
        } else {
            var index = this.Catagory.indexOf(e.target.value);
            this.Catagory.splice(index, 1);
        }
        let params = {
            group : this.Catagory.toString() === '' ? 'All':this.Catagory.toString(),
            search : this.props.listAllCollections.Search,
            uid : getProfile().id,
            usertype : getProfile().usertype,
            managecollection :  this.props.listAllCollections.managecollection ===""?"": this.props.listAllCollections.managecollection,
            limit : 0,
            offset : 20
        };
        this.props.collectionsFilter(params);
        //this.props.collectionsFilter(this.Catagory.toString() === '' ? 'All' :this.Catagory.toString(),this.props.listAllCollections.Search,"2",'root',0,"0",20,"lsinternal")
    }
    toggleCat() {
        this.setState({opened: !this.state.opened});
    }
    render() {
        return (

            <div className={`side-menu-right w-pad`}>

                <NavBarMenu
                    menuToggle={this.props.menuToggle}
                    toggleStatus={this.props.toggleStatus}
                    title= {getUrlSegment(1) === "services"? "Services":"Entity"}
                />
                <Scrollbars
                    //removeTracksWhenNotUsed={false}
                    //trackYProps={{style: {opacity: 0}}}
                    style={{ width: 220,height: 'calc(100% - 110px)'}}
                >
                    <div className="menu-section active">
                        <h5 className="nav-menu-in-head">Category
                            <span className={`loader-span`}>
                               {this.props.loader.loaderType === 'Category_List_Loader' && this.props.loader.status &&
                               <img src={sidebarLoader}/>
                               }
                           </span>
                        </h5>
                        <div className="select-wrap">

                            {this.props.categories.map((list,index) =>
                                <div className="select-chk-block" key={`collection-listings-cat`+index} id="collection_menu" style={{"display": "block"}}>
                                    <label className="control control-checkbox"> {list.categoryName}
                                        <input type="checkbox" className="inp-chk" value={list.categoryValue}
                                               name="filter_cat" onClick={this.CollectionFilter}/>

                                        <div className="control-indicator"></div>
                                    </label>
                                </div>
                            )}




                        </div>

                    </div>
                </Scrollbars>
                <div className="side-menu-footer"> <p>© Zynapse <span>2022</span></p>  </div>
            </div>


        );
    }
}
class Settings  extends Component {
    constructor(props) {
        super(props);

    }



    render() {
        let mainpage= getUrlSegment(1);
        let pagename= getUrlSegment(2);
        let subpage = getUrlSegment(3);

        return (

            <div className={`side-menu-right w-pad`}>
                <Navbar className={'side-nav-in'}>
                    <span className={'list-all-head'}>Settings</span>
                </Navbar>
                {mainpage === 'settings' &&
                <div>
                    <div className={'menu-section active'}>
                        <ul className={'ind-menu-section'}>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('settings', '/settings/phases')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/settings/phases' ? "active" : ""}`}>Phases
                                </Navbar>
                            </li>
                            <li>
                                <Navbar onClick={(e) => this.props.mainClick('settings', '/settings/roles')}
                                        className={`menu-sec-click ${this.props.menuClickData === '/settings/roles' ? "active" : ""}`}>Roles
                                </Navbar>
                            </li>
                        </ul>
                    </div>
                </div>
                }
            </div>


        );
    }
}
class Team  extends Component {
    constructor(props) {
        super(props);
        this.state={
            Countloading:0,
            showCompanySub:(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === 'root') ? true : false,
            showTeamSub:(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === 'root') ? false : true,
            showDesignationSub:false,
            showReporteesSub:false,
            company:[],
            designation:[],
            reportees:[],
            team:[]
        }
        this.userFilter=this.userFilter.bind(this);
        this.permissionFilter=this.permissionFilter.bind(this);
    }

    toggleMenu(value, name) {
        this.setState({[value] : !this.state[[value]]});
    }
    userFilter(e,id,filterType){
        let company= [...this.state.company];
        let designation = [...this.state.designation];
        let reportees = [...this.state.reportees];
        let team = [...this.state.team];

        if(filterType === 'company'){
            if (company.includes(id)) {
                company = [];
            } else {
                company = [];
                company.push(id);
            }
            this.setState({company : company})
        }
        else if(filterType === 'designation'){
            if (designation.includes(id)) {
                const index = designation.indexOf(id);
                if (index > -1) {
                    designation.splice(index, 1);

                }
            } else {
                designation.push(id);
            }
            this.setState({designation : designation})
        }
        else if(filterType === 'reportees'){
            if (reportees.includes(id)) {
                const index = reportees.indexOf(id);
                if (index > -1) {
                    reportees.splice(index, 1);

                }
            } else {
                reportees.push(id);
            }
            this.setState({reportees : reportees})
        }
        else if(filterType === 'team'){
            if (team.includes(id)) {
                const index = team.indexOf(id);
                if (index > -1) {
                    team.splice(index, 1);

                }
            } else {
                team.push(id);
            }
            this.setState({team : team})
        }
        let filter= this.props.listUsers.searchParam
        let params = {
            "uid" : getProfile().id,
            "search" :filter.search,
            "team":team.toString(),
            "company":company.toString(),
            "reportees":reportees.toString(),
            "designation":designation.toString(),
            "page": 1,
            "per_page": 100
        };
        this.props.searchType('SEARCH_USERS_DATA', params,"user")

    }
    permissionFilter(e,id,filterType){
        let company= [...this.state.company];
        let designation = [...this.state.designation];
        let reportees = [...this.state.reportees];
        let team = [...this.state.team];

        if(filterType === 'company'){
            if (company.includes(id)) {
                company = [];
            } else {
                company = [];
                company.push(id);
            }
            this.setState({company : company})
        }
        else if(filterType === 'designation'){
            if (designation.includes(id)) {
                const index = designation.indexOf(id);
                if (index > -1) {
                    designation.splice(index, 1);

                }
            } else {
                designation.push(id);
            }
            this.setState({designation : designation})
        }
        else if(filterType === 'reportees'){
            if (reportees.includes(id)) {
                const index = reportees.indexOf(id);
                if (index > -1) {
                    reportees.splice(index, 1);

                }
            } else {
                reportees.push(id);
            }
            this.setState({reportees : reportees})
        }
        else if(filterType === 'team'){
            if (team.includes(id)) {
                const index = team.indexOf(id);
                if (index > -1) {
                    team.splice(index, 1);

                }
            } else {
                team.push(id);
            }
            this.setState({team : team})
        }
        let filter= this.props.listPermissions.searchParam
        let params = {
            "userid" : getProfile().id,
            "search" :filter.search,
            "team":team.toString(),
            "company":company.toString(),
            "reportees":reportees.toString(),
            "designation":designation.toString(),
            "page": 1,
            "per_page": 20
        };
        this.props.searchType('SEARCH_USERPERMISSION_DATA', params,"userpermission")

    }
    render() {

        let mainpage= getUrlSegment(1);
        let pagename= getUrlSegment(2);
        let subpage = getUrlSegment(3);
        let allcompanies = this.props.allcompanies||[];
        let allDesignation = this.props.allDesignation||[];
        let allReportees = this.props.allReportees||[];
        let userdetail = this.props.userdetail||[];
        let team = userdetail.team||[];
        return (

            <div className={`side-menu-right w-pad`}>
                <Navbar className={'side-nav-in'}>
                    <button className="toggle-btn ico-lib switch-right-ico no-ev">TEAM</button>
                </Navbar>
                {mainpage.toLowerCase() === 'team' &&  pagename !== 'details' && pagename !== 'permissionsettings' &&
                <Scrollbars style={{"height":"calc(100% - 100px)"}}>
                    {(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === 'root' || getProfile().groupEnable === "1" )&&
                    <React.Fragment>
                        <div className={'menu-section'}>
                            <h5  className={'nav-menu-in-head'} onClick={(e) => this.props.history.push('/group')}>Group</h5>
                        </div>
                    </React.Fragment>
                    }
                    {getUrlSegment(1) === "team" &&
                    <React.Fragment>
                        {(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === 'root') &&
                        <div className={`menu-section ${this.state.showCompanySub === true ? "active" : ""}`}>
                            <h5 className="nav-menu-in-head"
                                onClick={(e) => this.toggleMenu('showCompanySub', 'company')}>Company</h5>
                            <Collapse in={this.state.showCompanySub}>
                                <div className="select-wrap">

                                    {allcompanies.map((item, itmIndex) =>
                                        <div className="select-chk-block" key={"filter-fields-label" + itmIndex}
                                             id={item.organizationid} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> {item.organization}
                                                <input type="checkbox" className="inp-chk"  checked={this.state.company.includes(item.organizationid)? true:false}
                                                       onChange={(e) => this.userFilter(e, item.organizationid, 'company')}/>
                                                <div className="control-indicator"></div>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </Collapse>
                        </div>
                        }
                        <div className={`menu-section ${this.state.showTeamSub === true ? "active" : ""}`}>
                            <h5 className="nav-menu-in-head" onClick={(e) => this.toggleMenu('showTeamSub', 'team')}>Team</h5>
                            <Collapse in={this.state.showTeamSub}>
                                <div className="select-wrap">

                                    {(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === 'root') &&
                                    <React.Fragment>
                                        <div className="select-chk-block" key={"filter-fields-label-user"} id={'1'} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> Users
                                                <input type="checkbox" className="inp-chk"   checked={this.state.team.includes("myteam,other")? true:false} onChange={(e) => this.userFilter(e, "myteam,other", 'team')}  />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>

                                        <div className="select-chk-block" key={"filter-fields-label-reportees"} id={'2'} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> Reportees
                                                <input type="checkbox" className="inp-chk" checked={this.state.team.includes('leader')? true:false}   onChange={(e) => this.userFilter(e, 'leader', 'team')} />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                    </React.Fragment>
                                    }
                                    {getProfile().usertype === "User" && getProfile().overseeStatus !== "1" &&

                                    <div className="select-chk-block" key={"filter-fields-label-users1"} id={'1'} style={{"display": "block"}}>
                                        <label className="control control-checkbox"> Users
                                            <input type="checkbox" className="inp-chk"  checked={this.state.team.includes("myteam,other")? true:false}   onChange={(e) => this.userFilter(e, "myteam,other", 'team')}  />
                                            <div className="control-indicator"> </div>
                                        </label>
                                    </div>
                                    }
                                    {getProfile().usertype === "User" && getProfile().overseeStatus === "1" &&
                                    <React.Fragment>
                                        <div className="select-chk-block" key={"filter-fields-label-team"} id={'1'} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> My Team
                                                <input type="checkbox" className="inp-chk" checked={this.state.team.includes("myteam")? true:false} onChange={(e) => this.userFilter(e, 'myteam', 'team')}  />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                        <div className="select-chk-block" key={"filter-fields-label-others"} id={'2'} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> Others
                                                <input type="checkbox" className="inp-chk"  checked={this.state.team.includes("other")? true:false} onChange={(e) => this.userFilter(e, 'other', 'team')} />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                    </React.Fragment>
                                    }

                                </div>
                            </Collapse>
                        </div>
                        <div className={`menu-section ${this.state.showDesignationSub === true ? "active" : ""}`}>
                            <h5 className="nav-menu-in-head" onClick={(e) => this.toggleMenu('showDesignationSub', 'desgnation')}>Designation</h5>
                            <Collapse in={this.state.showDesignationSub}>
                                <div className="select-wrap">
                                    {allDesignation.map((item, itmIndex) =>
                                        <div className="select-chk-block" key={"filter-fields-label"+itmIndex} id={item.id} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> {item.name}
                                                <input type="checkbox" className="inp-chk" value={item.id}  checked={this.state.designation.includes(item.id)? true:false} onChange={(e)=>this.userFilter(e,item.id,'designation')} />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                    )}

                                </div>
                            </Collapse>
                        </div>
                        <div className={`menu-section ${this.state.showReporteesSub === true ? "active" : ""}`}>
                            <h5 className="nav-menu-in-head" onClick={(e) => this.toggleMenu('showReporteesSub', 'reportees')}>Reportees</h5>
                            <Collapse in={this.state.showReporteesSub}>
                                <div className="select-wrap">
                                    {allReportees.map((item, itmIndex) =>
                                        <div className="select-chk-block" key={"filter-fields-label"+itmIndex} id={item.id} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> {item.name}
                                                <input type="checkbox" className="inp-chk" value={item.id} checked={this.state.reportees.includes(item.id)? true:false} onChange={(e)=>this.userFilter(e,item.id,'reportees')}  />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                    )}

                                </div>
                            </Collapse>
                        </div>
                    </React.Fragment>

                    }

                </Scrollbars>
                }
                {mainpage === 'team' && pagename === 'details' &&
                <React.Fragment>
                    {(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" ||  getProfile().usertype === 'root' || getProfile().overseeStatus === "1") &&
                    <Scrollbars style={{width: 220}}>
                        <div className="label-menu-right-block filter-top">
                            <ul className={'sub-menu-right'}>
                                <li>
                                    <div
                                        className={`sub-menu ico-lib-v1 pj-ov-ico ${this.props.teamClickData === 'overview' ? 'active' : ''}`}
                                        onClick={(e) => this.props.teamClick(e, 'overview')}>Overview
                                    </div>

                                </li>
                                <li>
                                    <div
                                        className={`sub-menu ico-lib-v1 pj-ra-ico ${this.props.teamClickData === 'activities' ? 'active' : ''}`}
                                        onClick={(e) => this.props.teamClick(e, 'activities')}>Recent Activities
                                    </div>

                                </li>
                                <li>
                                    <div
                                        className={`sub-menu ico-lib-v1 pj-permission-ico ${this.props.teamClickData === 'permissions' ? 'active' : ''}`}
                                        onClick={(e) => this.props.teamClick(e, 'permissions')}>Permissions
                                    </div>

                                </li>
                                {team && team.length > 0 &&
                                <li>
                                    <div
                                        className={`sub-menu ico-lib-v1 pj-team-ico ${this.props.teamClickData === 'team' ? 'active' : ''}`}
                                        onClick={(e) => this.props.teamClick(e, 'team')}>Team
                                    </div>
                                </li>
                                }
                                <li>
                                    <div
                                        className={`sub-menu ico-lib-v1 pj-team-ico ${this.props.teamClickData === 'relatedgroups' ? 'active' : ''}`}
                                        onClick={(e) => this.props.teamClick(e, 'relatedgroups')}>Groups
                                    </div>

                                </li>
                            </ul>
                        </div>

                    </Scrollbars>
                    }
                </React.Fragment>
                }
                {mainpage === 'team' && pagename === 'permissionsettings' &&
                <Scrollbars style={{"height":"calc(100% - 100px)"}}>
                    {(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === 'root' || getProfile().groupEnable === "1" )&&
                    <React.Fragment>
                        <div className={'menu-section'}>
                            <h5  className={'nav-menu-in-head'} onClick={(e) => this.props.history.push('/group')}>Group</h5>
                        </div>
                    </React.Fragment>
                    }
                    {getUrlSegment(1) === "team" &&
                    <React.Fragment>
                        {(getProfile().usertype === "Superadmin" || getProfile().usertype === 'root') &&
                        <div className={`menu-section ${this.state.showCompanySub === true ? "active" : ""}`}>
                            <h5 className="nav-menu-in-head"
                                onClick={(e) => this.toggleMenu('showCompanySub', 'company')}>Company</h5>
                            <Collapse in={this.state.showCompanySub}>
                                <div className="select-wrap">

                                    {allcompanies.map((item, itmIndex) =>
                                        <div className="select-chk-block" key={"filter-fields-label" + itmIndex}
                                             id={item.organizationid} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> {item.organization}
                                                <input type="checkbox" className="inp-chk" value={item.organizationid} checked={this.state.company.includes(item.organizationid)? true:false}
                                                       onChange={(e) => this.permissionFilter(e, item.organizationid, 'company')}/>
                                                <div className="control-indicator"></div>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </Collapse>
                        </div>
                        }
                        <div className={`menu-section ${this.state.showTeamSub === true ? "active" : ""}`}>
                            <h5 className="nav-menu-in-head" onClick={(e) => this.toggleMenu('showTeamSub', 'team')}>Team</h5>
                            <Collapse in={this.state.showTeamSub}>
                                <div className="select-wrap">

                                    {(getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === 'root') &&
                                    <React.Fragment>
                                        <div className="select-chk-block" key={"filter-fields-label-user"} id={'1'} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> Users
                                                <input type="checkbox" className="inp-chk"   onChange={(e) => this.permissionFilter(e, "myteam,other", 'team')}  />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>

                                        <div className="select-chk-block" key={"filter-fields-label-reportees"} id={'2'} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> Reportees
                                                <input type="checkbox" className="inp-chk"   onChange={(e) => this.permissionFilter(e, 'leader', 'team')} />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                    </React.Fragment>
                                    }
                                    {getProfile().usertype === "User" && getProfile().overseeStatus !== "1" &&

                                    <div className="select-chk-block" key={"filter-fields-label-users1"} id={'1'} style={{"display": "block"}}>
                                        <label className="control control-checkbox"> Users
                                            <input type="checkbox" className="inp-chk"  onChange={(e) => this.permissionFilter(e, "myteam,other", 'team')}  />
                                            <div className="control-indicator"> </div>
                                        </label>
                                    </div>
                                    }
                                    {getProfile().usertype === "User" && getProfile().overseeStatus === "1" &&
                                    <React.Fragment>
                                        <div className="select-chk-block" key={"filter-fields-label-team"} id={'1'} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> My Team
                                                <input type="checkbox" className="inp-chk"  onChange={(e) => this.permissionFilter(e, 'myteam', 'team')}  />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                        <div className="select-chk-block" key={"filter-fields-label-others"} id={'2'} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> Others
                                                <input type="checkbox" className="inp-chk"   onChange={(e) => this.permissionFilter(e, 'other', 'team')} />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                    </React.Fragment>
                                    }

                                </div>
                            </Collapse>
                        </div>
                        <div className={`menu-section ${this.state.showDesignationSub === true ? "active" : ""}`}>
                            <h5 className="nav-menu-in-head" onClick={(e) => this.toggleMenu('showDesignationSub', 'desgnation')}>Designation</h5>
                            <Collapse in={this.state.showDesignationSub}>
                                <div className="select-wrap">
                                    {allDesignation.map((item, itmIndex) =>
                                        <div className="select-chk-block" key={"filter-fields-label"+itmIndex} id={item.id} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> {item.name}
                                                <input type="checkbox" className="inp-chk" value={item.id} onChange={(e)=>this.permissionFilter(e,item.id,'designation')} />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                    )}

                                </div>
                            </Collapse>
                        </div>
                        <div className={`menu-section ${this.state.showReporteesSub === true ? "active" : ""}`}>
                            <h5 className="nav-menu-in-head" onClick={(e) => this.toggleMenu('showReporteesSub', 'reportees')}>Reportees</h5>
                            <Collapse in={this.state.showReporteesSub}>
                                <div className="select-wrap">
                                    {allReportees.map((item, itmIndex) =>
                                        <div className="select-chk-block" key={"filter-fields-label"+itmIndex} id={item.id} style={{"display": "block"}}>
                                            <label className="control control-checkbox"> {item.name}
                                                <input type="checkbox" className="inp-chk" value={item.id} onChange={(e)=>this.permissionFilter(e,item.id,'reportees')}  />
                                                <div className="control-indicator"> </div>
                                            </label>
                                        </div>
                                    )}

                                </div>
                            </Collapse>
                        </div>
                    </React.Fragment>

                    }

                </Scrollbars>
                }
                <div className="side-menu-footer"><p>© Zynapse <span>2022</span></p></div>
            </div>


        );
    }
}
class Group  extends Component {
    render() {

        let mainpage= getUrlSegment(1);
        let pagename= getUrlSegment(2);
        return (

            <div className={`side-menu-right w-pad`}>
                <Navbar className={'side-nav-in'}>
                    <button className="toggle-btn ico-lib switch-right-ico no-ev">GROUP</button>
                </Navbar>
                {mainpage.toLowerCase() === 'group' && pagename !== 'details' &&
                <Scrollbars style={{"height":"calc(100% - 100px)"}}>

                    <React.Fragment>
                        <div className={'menu-section active'}>
                            <h5  className={'nav-menu-in-head'} onClick={(e) => this.props.history.push('/team')}>Team</h5>
                        </div>
                    </React.Fragment>


                </Scrollbars>
                }
                <div className="side-menu-footer"><p>© Zynapse <span>2022</span></p></div>
            </div>


        );
    }
}


export {Task,Forms,Chat, Collections, Project, Settings,Entity,Team,Group}
