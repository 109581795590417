import React, { Component } from 'react';
import ReactHLS from 'react-hls-player';
import {BUCKET_CONFIG_SECURED}  from "../../../constants"
export class VideoPlayer extends Component {

    componentDidMount() {
        // var myVideo = document.getElementById("myVideo");
        // if (myVideo.addEventListener) {
        //     myVideo.addEventListener('contextmenu', function(e) {
        //         e.preventDefault();
        //     }, false);
        // } else {
        //     myVideo.attachEvent('oncontextmenu', function() {
        //         window.event.returnValue = false;
        //     });
        // }
    }
    render() {
        let playerType="videoplayer"
        let newSrc=""
        let filepath=""
        if(this.props.hasOwnProperty("resType") && this.props.resType === "5" ){
             filepath=this.props.fileName.split('.').slice(0, -1).join('.')
             playerType="hlsplayer"
             newSrc=BUCKET_CONFIG_SECURED.cloudfont+filepath+"/Default/HLS/"+filepath+"_360.m3u8"
        }
        let style =this.props.hasOwnProperty("display")?this.props.display:"block"
        let key =this.props.hasOwnProperty("key")?this.props.key:"video-key"
        return (
            <React.Fragment>
             {(playerType === "hlsplayer")?
                 <ReactHLS   url={newSrc} width={this.props.width} height={this.props.height}/>
                 :
                 <video  style={{display:style}} key={key} controls controlsList="nodownload" id="myVideo" width={this.props.width} height={this.props.height}>
                     <source src={this.props.src} type="video/mp4"/>
                 </video>
             }
            </React.Fragment>

        );
    }
}

export default  VideoPlayer;