import React, {Component} from 'react';
import {ES_INDEX, FRMSIMG_PATH, USRIMG_PATH} from "../../../constants";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {Datecomparison, Datetimecomparison, getUrlSegment} from "../../../utilities/CustomFunctions";
import * as commonActions from "../../../actions/Common";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal";
import AddNewProjectDiscussion from "./AddNewProjectDiscussion";
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import ProjectAddCard from "../ProjectAddCard";
import ProjectBoardList from "./ProjectBoardList";
import ProjectAddBoard from "./ProjectAddBoard";
import AddNewProjectBoard from "./AddNewProjectBoard";

class ProjectBoard extends Component {

    constructor(props) {
        super(props);

        this.state={
            projectid:'',
            Addopen:false,
            Editopen:false,
            userlist: 'false',
            showremarksModal: true,
        }
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);
    }



    componentDidMount() {
        this.setState({loading: 1});
        var prjctdtlid = getUrlSegment(4);
        var projectid = (atob(getUrlSegment(4)));
        this.props.mainClick('project', '/project/details/board/'+prjctdtlid);
        //console.log(projectid)
        this.setState({projectid: projectid})

        let Ddata ={
            "projectid": projectid,
            "systype": getProfile().usertype,
            "uid": getProfile().id,
            "from": 0,
            "size": 50,

        }
        this.props.getprojectDiscussions(Ddata)
        let Pdata ={
            "projectid": projectid,
        }
        this.props.projectuserdetail(Pdata);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        var projectid = (atob(getUrlSegment(4)));
        //console.log( projectid)
        var  beta =  this;
        if(prevProps.discussionAdddata.project_discussion !== this.props.discussionAdddata.project_discussion ) {
            setTimeout(function () {
                let Ddata ={
                    "projectid": projectid,
                    "systype": getProfile().usertype,
                    "uid": getProfile().id,
                    "from": 0,
                    "size": 50,

                }
                beta.props.getprojectDiscussions(Ddata);
            }, 1000)
            this.AddmodalClose();


        }

    }

    AddmodalOpen(e, pid){
        e.stopPropagation();

        if(pid){
            this.setState({prjct_id : pid});
            let projectdata = {_id: pid}
            // this.props.projectInfo(projectdata);
        }
        this.setState({Addopen : true});
    }

    AddmodalClose(){
        this.setState({Addopen : false});
    }

    CreateProject(event) {
        event.preventDefault();
        let formValid = 0;

        ////console.log(this.state.assignee_ids )
        var assignee_ids = this.state.assignee_ids;
        var projectname = this.state.name;
        var description = this.state.description;

        var projectid = (this.state.project_id =='0' ? moment().valueOf() : this.state.project_id);
        var mode = (this.state.project_id =='0' ? 'save' : 'update');


        if (this.state.name === '') {
            formValid++;
            document.getElementsByName('name')[0].classList.add('val-error');
        }

        if (this.state.description === '') {
            formValid++;
            document.getElementsByName('description')[0].classList.add('val-error');
        }

        if(formValid === 0){
            this.setState({newDiscussionLoading: 1})
            let discussiontopic = {
                projectid: projectid,
                topic: projectname,
                description: description,
                type: getProfile().usertype,
                uid: getProfile().id,
                assignees: assignee_ids,
            };

            ////console.log(discussiontopic);
            this.props.saveprojectdiscussion(discussiontopic);
            // this.props.AddmodalClose();
        }
    }

    render() {

        let projectdiscussions = this.props.projectdiscussionsdata.getprojectdiscussions;
        let userlength = projectdiscussions.length;
        let userdetail = this.props.listAllProjectUserData.getprojectusers;
        //console.log(this.state.projectid)
        //console.log(projectdiscussions)
        //console.log(userdetail);
        //console.log(this.state.assigneeslist);

        return(

            <div className="list-wrapper">
                {/*{this.props.projectdiscussionsdata.loading &&*/}
                {/*    <span className="Loader-holder">*/}
                {/*        <LoaderSvg/>*/}
                {/*    </span>*/}
                {/*}*/}
                <div className="project-content-wrapper full-height-collection hide-scroll">
                    <div className="board-wrapper">

                            <ProjectAddBoard
                                AddmodalClose = {this.AddmodalClose}
                                listtags={userdetail}
                                userlength = {userlength}
                            />
                            <ProjectAddBoard
                                AddmodalClose = {this.AddmodalClose}
                                listtags={userdetail}
                                userlength = {userlength}
                            />
                            <ProjectAddBoard
                                AddmodalClose = {this.AddmodalClose}
                                listtags={userdetail}
                                userlength = {userlength}
                            />
                            <ProjectAddBoard
                                AddmodalClose = {this.AddmodalClose}
                                listtags={userdetail}
                                userlength = {userlength}
                            />
                            <ProjectAddBoard
                                AddmodalClose = {this.AddmodalClose}
                                listtags={userdetail}
                                userlength = {userlength}
                            />

                    </div>
                </div>

                <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Discussion"
                     onClick={(e)=>this.AddmodalOpen(e, this.state.projectid)}>
                    <button className="float-button ico-lib float-add"></button>
                </div>

                {this.state.Addopen &&
                    <Modal title={(this.state.prjct_id ? 'New Board' : 'New Board')} Close ={this.AddmodalClose.bind(this)}>
                        <AddNewProjectBoard
                            AddmodalClose = {this.AddmodalClose}
                            listtags={userdetail}
                            userlength = {userlength}
                        />

                    </Modal>
                }

            </div>



        );
    }
}
const mapStateToProps = state => ({
    projectdiscussionsdata:state.ProjectReducer.GetProjectDiscussions,
    discussionAdddata:state.ProjectReducer.SaveProjectDiscussion,
    listAllProjectUserData:state.ProjectReducer.GetProjectUsers,
    userlistAssignmoredata:state.ProjectReducer.SaveAssignedUsers,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getprojectDiscussions : projectActions.getProjectDiscussions,
    projectuserdetail:projectActions.getProjectUsers,
    saveAssignedusers:projectActions.saveAssignedUsers,
})(ProjectBoard));
