import React from 'react';
import ColorPicker from "../views/ColorPicker";
import {DragDropContext, Draggable as Drags, Droppable} from "react-beautiful-dnd";
import {getUrlSegment} from "../../../../utilities/CustomFunctions";

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : "#fff",
    ...draggableStyle
});

class SettingsModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabledata: []
        }
    }

    componentDidMount() {
        this.setTableData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.containerData.tabledata !== prevProps.containerData.tabledata) {
            this.setTableData()
        }
    }

    setTableData = () => {
        this.setState({
            tabledata: this.props.containerData.tabledata
        }, function () {
            this.applyDateFilter()
        })
    }

    applyDateFilter = () => {
        let tabledata = this.state.tabledata || [];
        tabledata = tabledata.map(tbl => {
            let fieldData = this.props.containerData.showlistField.filter(item => item.masterid === tbl.field && item.type === 'datepicker' && item.datetime === 'Yes');
            if (fieldData.length > 0) {
                tbl.datewithtime = true;
            } else {
                tbl.datewithtime = false;
            }
            return tbl;
        });

        this.setState({
            tabledata: tabledata
        })
    }

    handleLabelChange = (e, itemId, type) => {
        let fieldId = e.target.value;
        let tabledata = this.state.tabledata || [];

        let comps = tabledata.filter(tbl => tbl.id === itemId);
        //console.log(comps)

        if (comps.length > 0) {
            comps[0].field = fieldId;

            let fieldData = this.props.containerData.showlistField.filter(item => item.masterid.toString() === fieldId.toString() && item.type === 'datepicker' && item.datetime === 'Yes');

            if (fieldData.length > 0) {
                comps[0].datewithtime = true;
            } else {
                comps[0].datewithtime = false;
            }
        }
        this.props.handleLabelChange(e, itemId, type);
    }

    render() {
        let collections = this.props.containerData.collectionData
        let collection = this.props.containerData.collection
        let enableyaxis = this.props.containerData.enableyaxis
        let legend = this.props.containerData.legend
        let alldata = this.props.containerData.alldata
        let tabledata = this.state.tabledata;
        let showlistField = this.props.containerData.showlistField.concat({
            type: "datepicker",
            masterid: "101",
            display_label: "CreateDate"
        })
        let condition = this.props.containerData.condition
        let enablegradient = this.props.containerData.enablegradient
        let hidearea = this.props.containerData.hidearea
        let sourceType = this.props.containerData.sourceType
        let whereCondition = this.props.containerData.whereCondition;
        let globalFilterComp = this.props.containerData.globalFilterComp;
        let globalFilter = this.props.containerData.globalFilter;
        let globalFilterIds = this.props.containerData.selectedGlobalFilters;

        console.log(globalFilterComp)
        console.log(globalFilterIds)

        return (
            <div className="modal bsy-modal lg-modal builder-mod body-grey fade show" style={{"display": "block"}}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.title}</h5>
                            <div className="modal-header-options">
                                <button className="modal-option-button" onClick={() => this.props.Close()}><span
                                    className="icon-in icon-close-in"/></button>
                            </div>
                        </div>
                        {this.props.hasOwnProperty('modaltype') && this.props.modaltype !== 'datetime' &&
                        <div className="modal-body">
                            <div className="chart-settings-container">
                                <div className="chart-settings-splitter">
                                    <div className="chart-settings-splitter-header">
                                        <h5>Data</h5>
                                    </div>

                                    <div className="each-settings-panel-wrap">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Data Set</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="each-inp-field">
                                                <label>Select Type</label>
                                                <div className="radio-wrap">
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Collection
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`sourceType`}
                                                                   checked={sourceType.toString() === "true"}
                                                                   value={true}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Entity
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`sourceType`}
                                                                   checked={sourceType.toString() === "false"}
                                                                   value={false}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {sourceType.toString() === "true" ?
                                                <div className="each-inp-field">
                                                    <label>Choose Collection</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="collection"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={collection}>>
                                                            <option value="">Choose Collection</option>
                                                            {collections.map((list, index) =>
                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list.title)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                :
                                                <div className="each-inp-field">
                                                    <label>Choose Entity</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="collection"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={collection}>
                                                            <option value="">Choose Entity</option>
                                                            {collections.map((list, index) =>

                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list._source.name)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {this.props.containerData.hasOwnProperty("whereCondition") &&
                                        <>
                                            <div className="each-settings-panel-subheader">
                                                <h5>Condition</h5>
                                            </div>
                                            <div className="each-settings-panel-wrap-body">
                                                <div className="task-input-container">
                                                    <>
                                                        <div className="each-inp-field">
                                                            <label>Conditions</label>
                                                            {this.props.containerData.whereCondition.map((item, index) =>
                                                                <div key={"cond_wh" + index} className={'panel-each'}>
                                                                    <div className={`settings-panel`}>
                                                                        {/*<input type={`text`} name="label"*/}
                                                                        {/*       defaultValue={item.label}*/}
                                                                        {/*       onChange={(e) => this.props.handleLabelChange(e, item.id)}*/}
                                                                        {/*/>*/}
                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="field"
                                                                                    onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "field")}
                                                                                    value={item.field}>
                                                                                <option value="">Conditions</option>
                                                                                {showlistField.filter(list => (list.type === "number" || list.type === "dropdown" || list.type === "checkbox" || list.type === "switch" || list.type === "text" || list.type === "datepicker")).map((label, index) =>
                                                                                    <option
                                                                                        key={`multi-area-chart-masterid` + index}
                                                                                        value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                        showlistField.filter(list => list.masterid === item.field && (!list.optionstatus || (list.optionstatus && list.optionstatus === '')) && (list.type === "switch" || list.type === "dropdown" || list.type === "checkbox"))[0] &&
                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="conditions"
                                                                                    onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "conditions")}
                                                                                    value={item.conditions}>
                                                                                <option value="">Conditions</option>
                                                                                {showlistField.filter(list => list.masterid === item.field).length !== 0 && showlistField.filter(list => list.masterid === item.field)[0].option.map((lab, index) =>
                                                                                    <option
                                                                                        key={`multi-area-chart-masterid` + index}
                                                                                        value={lab}>{lab}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        }
                                                                        {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                        showlistField.filter(list => list.masterid === item.field && (list.optionstatus && list.optionstatus === 'textoption') && (list.type === "switch" || list.type === "dropdown" || list.type === "checkbox"))[0] &&
                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="conditions"
                                                                                    onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "conditions")}
                                                                                    value={item.conditions}>
                                                                                <option value="">Conditions</option>
                                                                                {showlistField.filter(list => list.masterid === item.field).length !== 0 && showlistField.filter(list => list.masterid === item.field)[0].option.map((lab, index) =>
                                                                                    <option
                                                                                        key={`multi-area-chart-masterid` + index}
                                                                                        value={lab.hasOwnProperty('text') ? lab.text : lab}>{lab.hasOwnProperty('text') ? lab.text : lab}</option>
                                                                                )}</select>
                                                                        </div>
                                                                        }

                                                                        {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                        showlistField.filter(list => list.masterid === item.field && list.type === "number")[0] &&
                                                                        <>
                                                                            <input type={`text`} name="min"
                                                                                   defaultValue={item.min}
                                                                                   placeholder={`min`}
                                                                                   onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "number")}
                                                                            />
                                                                            <input type={`text`} name="max"
                                                                                   placeholder={`max`}
                                                                                   defaultValue={item.max}
                                                                                   onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "number")}
                                                                            />
                                                                        </>
                                                                        }

                                                                        {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                        showlistField.filter(list => list.masterid === item.field && list.type === "datepicker")[0] &&
                                                                        <>
                                                                            <input type={`date`} name="from"
                                                                                   defaultValue={item.from}
                                                                                   placeholder={`from`}
                                                                                   onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "datepicker")}
                                                                            />
                                                                            <input type={`date`} name="to"
                                                                                   defaultValue={item.to}
                                                                                   placeholder={`to`}
                                                                                   onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "datepicker")}
                                                                            />
                                                                        </>
                                                                        }
                                                                        {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                        showlistField.filter(list => list.masterid === item.field && list.type === "text")[0] &&
                                                                        <>
                                                                            <input type={`text`} name="text"
                                                                                   defaultValue={item.text}
                                                                                   placeholder={`text`}
                                                                                   onChange={(e) => this.props.handleLabelChange(e, item.id, "whereCondition", "text")}
                                                                            />

                                                                        </>
                                                                        }


                                                                    </div>
                                                                    <div className={`settings-panel-option`}>
                                                                        {index + 1 === whereCondition.length &&
                                                                        <button className="ico-lib divider-add-button"
                                                                                onClick={() => this.props.addData(item.id, "whereCondition")}>

                                                                        </button>
                                                                        }

                                                                        {whereCondition.length !== 1 &&
                                                                        <button className="ico-lib divider-sub-button"
                                                                                onClick={() => this.props.removeData(item.id, "whereCondition")}>

                                                                        </button>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="each-inp-field">
                                                            <label>Limit</label>
                                                            <div className="new-task-input">
                                                                <input type="text" placeholder="Limit" id="limit"
                                                                       name="limit"
                                                                       autoComplete="off"
                                                                       value={this.props.containerData.limit}
                                                                       onChange={(e) => this.props.handleChange(e)}/>
                                                            </div>

                                                        </div>
                                                    </>


                                                    {this.props.containerData.hasOwnProperty("tablelimit") &&
                                                    <div className="each-inp-field">
                                                        <label>Limit</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Table Limit" id="limit"
                                                                   name="tablelimit"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.tablelimit}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>

                                                    </div>
                                                    }

                                                </div>
                                            </div>

                                        </>
                                        }
                                        {this.props.containerData.hasOwnProperty("condition") &&
                                        <>
                                            <div className="each-settings-panel-subheader">
                                                <h5>Sorting</h5>
                                            </div>
                                            <div className="each-settings-panel-wrap-body">
                                                <div className="task-input-container">
                                                    <>
                                                        <div className="each-inp-field eq-width-line">
                                                            <label>Sort By</label>
                                                            <div className="select-box eq-width">
                                                                <select id="multi-area-chart-collections" name="field"
                                                                        onChange={(e) => this.props.handleConditionChange(e)}
                                                                        value={condition && condition[0].field + "|" + (condition[0].type === "date" ? "datepicker" : condition[0].type)}>

                                                                    <option value="|">Sort By</option>
                                                                    {showlistField.filter(item => (item.type === "number" || item.type === "datepicker")).map((label, index) =>
                                                                        <option
                                                                            key={`multi-area-chart-masterid` + index}
                                                                            value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-field eq-width-line">
                                                            <label>Sort Type</label>
                                                            <div className="select-box eq-width">
                                                                <select id="multi-area-chart-collections" name="sort"
                                                                        onChange={(e) => this.props.handleConditionChange(e)}
                                                                        value={condition && condition[0].sort}>

                                                                    <option value="">ASC/DESC</option>
                                                                    <option value="asc">ASC</option>
                                                                    <option value="desc">DESC</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                </div>
                                            </div>
                                        </>
                                        }


                                        {this.props.containerData.hasOwnProperty("setDate") &&
                                        <>
                                            <div className="each-settings-panel-subheader">
                                                <h5>Date Filter</h5>
                                            </div>
                                            <div className="each-settings-panel-wrap-body">
                                                <div className="task-input-container">

                                                    <>
                                                        <div className="each-inp-field eq-width-line">
                                                            <label>Set By</label>
                                                            <div className="select-box eq-width">
                                                                <select id="multi-area-chart-collections" name="setDate"
                                                                        onChange={(e) => this.props.setDate(e)}
                                                                        value={this.props.containerData.setDate}>

                                                                    <option value="">Please Select</option>
                                                                    <option value="todays">Today</option>
                                                                    <option value="lastsevendays">Last Seven days
                                                                    </option>
                                                                    <option value="thismonth">This Month</option>
                                                                    <option value="last6month">Last 6 months</option>
                                                                    <option value="daterange">Date Range</option>
                                                                    <option value="dateFrom">Last (-) Days</option>

                                                                </select>
                                                            </div>
                                                        </div>

                                                        {this.props.containerData.setDate === "daterange" &&
                                                        <>
                                                            <div className="each-inp-field">
                                                                <label>From</label>
                                                                <div className="new-task-input">
                                                                    <input type="date" placeholder="From"
                                                                           name="setFrom"
                                                                           autoComplete="off"
                                                                           value={this.props.containerData.setFrom}
                                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                                </div>
                                                            </div>
                                                            <div className="each-inp-field">
                                                                <label>To</label>
                                                                <div className="new-task-input">
                                                                    <input type="date" placeholder="To"
                                                                           name="setTo"
                                                                           autoComplete="off"
                                                                           value={this.props.containerData.setTo}
                                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                                </div>
                                                            </div>
                                                        </>
                                                        }

                                                        {this.props.containerData.setDate === "dateFrom" &&
                                                        <>
                                                            <div className="each-inp-field">
                                                                <label>From</label>
                                                                <div className="new-task-input">
                                                                    <input type="number" placeholder="Date From"
                                                                           name="dateFrom"
                                                                           autoComplete="off"
                                                                           value={this.props.containerData.dateFrom}
                                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                                </div>
                                                            </div>
                                                        </>
                                                        }

                                                    </>
                                                </div>
                                            </div>
                                        </>
                                        }

                                        {this.props.containerData.hasOwnProperty("getFlagData") &&
                                        <>
                                            <div className="each-settings-panel-subheader">
                                                <h5>Flag Filter</h5>
                                            </div>
                                            <div className="each-settings-panel-wrap-body">
                                                <div className="task-input-container">
                                                    <div className="flag-settings-content">

                                                        <ul>

                                                            {this.props.containerData.getFlagData.map((item, itmIndex) =>
                                                                <li>


                                                                    <div className="select-chk-block"
                                                                         style={{"display": "block"}}>
                                                                        <label className="control control-checkbox"
                                                                               style={{"textAlign": "left"}}>{item.title}
                                                                            <input
                                                                                checked={(this.props.containerData.flagId.includes(item._id))}
                                                                                type="checkbox" className="inp-chk"
                                                                                name="dataview"
                                                                                onChange={() => this.props.handleFlagSet(item._id)}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>


                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        }

                                        {this.props.containerData.hasOwnProperty("datewithtime") &&
                                        <>
                                            <div className="each-settings-panel-subheader">
                                                <h5>Date With Time</h5>
                                            </div>
                                            <div className="each-settings-panel-wrap-body">
                                                <div className="task-input-container">
                                                    <div className="flag-settings-content">
                                                        <div className="select-box eq-width"
                                                             style={{"display": "block"}}>
                                                            <select name={`datewithtime`}
                                                                    onChange={(e) => this.props.handleChange(e)}
                                                                    value={this.props.containerData.datewithtime}>

                                                                <option value="yes">Yes</option>
                                                                <option value="no">No</option>

                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        }
                                        {(this.props.containerData.hasOwnProperty("cardcolor") ||
                                            this.props.containerData.hasOwnProperty("labelcolor") ||
                                            this.props.containerData.hasOwnProperty("valuecolor")
                                        ) &&
                                        <>
                                            <div className="each-settings-panel-subheader">
                                                <h5>Color Settings</h5>
                                            </div>
                                            <div className="each-settings-panel-wrap-body">
                                                <div className="task-input-container">
                                                    {this.props.containerData.hasOwnProperty("cardcolor") &&
                                                    <div className="each-inp-field">
                                                        <label>Card Colour</label>
                                                        <ColorPicker
                                                            stroke={"cardcolor"}
                                                            color={this.props.containerData.cardcolor}
                                                            onChangeComplete={this.props.handleChangeComplete}
                                                            handleClickStaus={this.props.handleClick}
                                                        />
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("labelcolor") &&
                                                    <div className="each-inp-field">
                                                        <label>Label Colour</label>
                                                        <ColorPicker
                                                            stroke={"labelcolor"}
                                                            color={this.props.containerData.labelcolor}
                                                            onChangeComplete={this.props.handleChangeComplete}
                                                            handleClickStaus={this.props.handleClick}
                                                        />
                                                    </div>
                                                    }

                                                    {this.props.containerData.hasOwnProperty("valuecolor") &&
                                                    <div className="each-inp-field">
                                                        <label>Value Colour</label>
                                                        <ColorPicker
                                                            stroke={"valuecolor"}
                                                            color={this.props.containerData.valuecolor}
                                                            onChangeComplete={this.props.handleChangeComplete}
                                                            handleClickStaus={this.props.handleClick}
                                                        />
                                                    </div>
                                                    }


                                                </div>
                                            </div>
                                        </>
                                        }

                                    </div>

                                    {this.props.containerData.hasOwnProperty("tabledata") &&
                                    <div className="each-settings-panel-wrap">
                                        <div class="task-input-container">
                                            <div className="each-settings-panel-wrap-header">
                                                <h5 className="each-settings-panel-header">Graph axis</h5>
                                            </div>

                                            <div className="each-settings-panel-wrap-body">
                                                <DragDropContext onDragEnd={this.onDragEnd}>
                                                    <Droppable droppableId="droppable">
                                                        {(droppableProvided, droppableSnapshot) => (
                                                            <div ref={droppableProvided.innerRef}>
                                                                {tabledata.map((item, index) => (
                                                                    <Drags key={item.id} draggableId={item.id}
                                                                           index={index}
                                                                           isDragDisabled={this.props.colorpickerStatus}>
                                                                        {(draggableProvided, draggableSnapshot) => (
                                                                            <div className={'panel-each'}
                                                                                 ref={draggableProvided.innerRef}
                                                                                 {...draggableProvided.draggableProps}
                                                                                 {...draggableProvided.dragHandleProps}
                                                                                 style={getItemStyle(
                                                                                     draggableSnapshot.isDragging,
                                                                                     draggableProvided.draggableProps.style
                                                                                 )}
                                                                                 isDragDisabled={this.props.colorpickerStatus}
                                                                            >
                                                                                <div className={`settings-panel`}>
                                                                                    <input type={`text`} name="label"
                                                                                           defaultValue={item.label}
                                                                                           onChange={(e) => this.props.handleLabelChange(e, item.id, "graph")}/>


                                                                                    <div className="select-box">
                                                                                        <select id="yaxis" name="field"
                                                                                                onChange={(e) => this.handleLabelChange(e, item.id, "graph")}
                                                                                                value={item.field}>{item.type}
                                                                                            <option value="">Y axis
                                                                                            </option>
                                                                                            {showlistField.map((label, index) =>
                                                                                                <option
                                                                                                    key={`multi-area-chart-masterid` + index}
                                                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>

                                                                                    {item.datewithtime &&
                                                                                    <div className="select-box eq-width"
                                                                                         style={{"display": "block"}}>
                                                                                        <select name={`datewithtime`}
                                                                                                onChange={(e) => this.props.handleChange(e)}
                                                                                                value={this.props.containerData.datewithtime}>

                                                                                            <option value="yes">Yes
                                                                                            </option>
                                                                                            <option value="no">No
                                                                                            </option>

                                                                                        </select>
                                                                                    </div>
                                                                                    }


                                                                                    {!this.props.hasOwnProperty('type') &&
                                                                                    <ColorPicker
                                                                                        stroke={item.id}
                                                                                        color={item.color}
                                                                                        label={`graph`}
                                                                                        onChangeComplete={this.props.handleColorChange}
                                                                                        handleClickStaus={this.props.handleClick}
                                                                                    />
                                                                                    }
                                                                                    {this.props.hasOwnProperty('type') &&
                                                                                    <input type={`text`} name="unit"
                                                                                           placeholder={`unit`}
                                                                                           defaultValue={item.unit}
                                                                                           onChange={(e) => this.props.handleLabelChange(e, item.id, "graph")}/>
                                                                                    }


                                                                                    {this.props.containerData.hasOwnProperty("Aggregate") && index === 0 &&
                                                                                    <div class="select-chk-block"
                                                                                         style={{display: "block"}}>
                                                                                        <label
                                                                                            className="control control-checkbox">
                                                                                            <input className="inp-chk"
                                                                                                   type={`checkbox`}
                                                                                                   name={`groupbyEnable`}
                                                                                                   checked={this.props.containerData.groupbyEnable}
                                                                                                   value={this.props.containerData.groupbyEnable}
                                                                                                   onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                                            <div
                                                                                                className="control-indicator"></div>
                                                                                            Group By
                                                                                        </label>
                                                                                    </div>

                                                                                    }
                                                                                    {this.props.containerData.hasOwnProperty("Aggregate") && index !== 0 &&
                                                                                    <>
                                                                                        <div className="select-box">
                                                                                            <select
                                                                                                id="multi-area-chart-collections"
                                                                                                name="aggs"
                                                                                                onChange={(e) => this.props.handleLabelChange(e, item.id, "graph")}

                                                                                                value={item.aggs}>
                                                                                                <option
                                                                                                    value="">Aggregate
                                                                                                </option>

                                                                                                <option
                                                                                                    key={`agg_ch_0`}
                                                                                                    value={"sum"}>Sum
                                                                                                </option>
                                                                                                <option
                                                                                                    key={`agg_ch_1`}
                                                                                                    value={"avg"}>Avg
                                                                                                </option>
                                                                                                <option
                                                                                                    key={`agg_ch_2`}
                                                                                                    value={"min"}>Min
                                                                                                </option>
                                                                                                <option
                                                                                                    key={`agg_ch_3`}
                                                                                                    value={"max"}>Max
                                                                                                </option>
                                                                                                <option
                                                                                                    key={`agg_ch_4`}
                                                                                                    value={"count"}>Count
                                                                                                </option>

                                                                                            </select>
                                                                                        </div>
                                                                                        {item.aggs && item.aggs !== '' &&
                                                                                        <div className="select-box">
                                                                                            <select
                                                                                                id="multi-area-chart-collections"
                                                                                                name="field_sort"
                                                                                                onChange={(e) => this.props.handleLabelChange(e, item.id, "graph")}
                                                                                                value={item.field_sort}>
                                                                                                <option value="">Sort
                                                                                                    Result
                                                                                                </option>

                                                                                                <option
                                                                                                    key={`multi-area-chart-sum0`}
                                                                                                    value={"none"}>None
                                                                                                </option>
                                                                                                <option
                                                                                                    key={`multi-area-chart-sum1`}
                                                                                                    value="asc">ASC
                                                                                                </option>
                                                                                                <option
                                                                                                    key={`multi-area-chart-sum2`}
                                                                                                    value="desc">DESC
                                                                                                </option>

                                                                                            </select>
                                                                                        </div>
                                                                                        }
                                                                                    </>


                                                                                    }
                                                                                </div>
                                                                                {(this.props.hasOwnProperty("repeater") && !this.props.repeater) || this.props.repeater || !this.props.hasOwnProperty("repeater") &&
                                                                                <div
                                                                                    className={`settings-panel-option`}>
                                                                                    {index + 1 === tabledata.length &&
                                                                                    <button
                                                                                        className="ico-lib divider-add-button"
                                                                                        onClick={() => this.props.addData(item.id, "items")}></button>
                                                                                    }

                                                                                    {tabledata.length !== 1 &&
                                                                                    <button
                                                                                        className="ico-lib divider-sub-button"
                                                                                        onClick={() => this.props.removeData(item.id, "items")}></button>
                                                                                    }
                                                                                </div>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Drags>
                                                                ))}
                                                                {droppableProvided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="each-settings-panel-wrap">
                                        <div class="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Filters</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            {this.props.containerData.hasOwnProperty("globalFilterComp") &&
                                            <div className="task-input-container">
                                                <div className="each-inp-field">
                                                    <label>Global filter</label>

                                                    <div className={'panel-each'}>
                                                        <div className={`settings-panel`}>
                                                            {globalFilterComp.map((item, glid) =>
                                                                <div className="checkbox-wrap" key={"gi_" + glid}>
                                                                    <div className="checkbox-block">
                                                                        <label
                                                                            className="control control-checkbox">{item.name}
                                                                            <input className="inp-chk" type={`checkbox`}
                                                                                   name={`global_filters`}
                                                                                   checked={globalFilterIds.includes(item.id)}
                                                                                   value={item.id}
                                                                                   onClick={(e) => this.props.globalFilterchange(e)}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {/*<div className={`settings-panel-option`}>*/}
                                                        {/*    {index + 1 === this.props.containerData.globalFilter.length &&*/}
                                                        {/*    <button className="ico-lib divider-add-button"*/}
                                                        {/*            onClick={() => this.props.addData(item.id, "globalFilter")}>*/}

                                                        {/*    </button>*/}
                                                        {/*    }*/}

                                                        {/*    {this.props.containerData.globalFilter.length !== 1 &&*/}
                                                        {/*    <button className="ico-lib divider-sub-button"*/}
                                                        {/*            onClick={() => this.props.removeData(item.id, "globalFilter")}>*/}

                                                        {/*    </button>*/}
                                                        {/*    }*/}
                                                        {/*</div>*/}

                                                    </div>


                                                </div>
                                            </div>
                                            }
                                        </div>


                                        <div class="each-settings-panel-subheader">
                                            <h5>Chart Filters</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            {(this.props.containerData.hasOwnProperty("ownFilter") || this.props.containerData.hasOwnProperty("filterName")) &&
                                            <div className="task-input-container">
                                                {this.props.containerData.hasOwnProperty("ownFilter") &&
                                                <div className="radio-wrap">
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Chart Filter
                                                            <input className="inp-chk" type={`checkbox`}
                                                                   name={`ownFilter`}
                                                                   checked={this.props.containerData.ownFilter}
                                                                   value={this.props.containerData.ownFilter}
                                                                   onClick={(e) => this.props.checkBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                </div>
                                                }
                                                {this.props.containerData.hasOwnProperty("selectFilter") &&
                                                <div className="each-inp-field">
                                                    <label>Filter Display Fields</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="selectFilter"
                                                                onChange={(e) => this.props.handleChange(e)}
                                                                value={this.props.containerData.selectFilter}
                                                        >

                                                            <option value="|">Please select</option>
                                                            {showlistField.map((label, index) =>
                                                                <option key={`multi-area-chart-masterid` + index}
                                                                        value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                }

                                                {this.props.containerData.hasOwnProperty("filterName") &&
                                                <div className="each-inp-field">
                                                    <label>Filter Name</label>
                                                    <div className="new-task-input">
                                                        <input type="text" placeholder="Filter Name" id="filterName"
                                                               name="filterName"
                                                               autoComplete="off"
                                                               value={this.props.containerData.filterName}
                                                               onChange={(e) => this.props.handleChange(e)}/>
                                                    </div>
                                                </div>
                                                }
                                                {this.props.containerData.hasOwnProperty("ownFilter") &&
                                                <div className="each-inp-field">
                                                    {/*<label>Own Filter</label>*/}
                                                    {this.props.containerData.ownFilterList.map((item, index) =>
                                                        <div className={'panel-each'}>
                                                            <div className={`settings-panel`}>
                                                                <div className="select-box">
                                                                    <select id="yaxis" name="field"
                                                                            onChange={(e) => this.props.handleLabelChange(e, item.id, "ownFilterList", "field")}
                                                                            value={item.field}>
                                                                        <option value="">Filter By</option>
                                                                        {showlistField.filter(list => (list.type === "number" || list.type === "autofill" || list.type === "switch" || list.type === "dropdown" || list.type === "checkbox" || list.type === "text" || list.type === "datepicker")).map((label, index) =>
                                                                            <option
                                                                                key={`multi-area-chart-masterid` + index}
                                                                                value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                        )}
                                                                    </select>
                                                                </div>

                                                            </div>


                                                        </div>
                                                    )}
                                                </div>
                                                }
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="chart-settings-splitter">
                                    <div className="chart-settings-splitter-header">
                                        <h5>Display Properties</h5>
                                    </div>
                                    {this.props.containerData.hasOwnProperty("chartTitle") &&
                                    <div className="each-settings-panel-wrap">
                                        <div class="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Chart Details</h5>
                                        </div>
                                        <div class="each-settings-panel-wrap-body">
                                            {this.props.containerData.hasOwnProperty("chartTitle") &&
                                            <div className="each-inp-field">
                                                <label>Chart Title</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="title" id="chartTitle"
                                                           name="chartTitle"
                                                           autoComplete="off"
                                                           value={this.props.containerData.chartTitle}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("subTitle") &&
                                            <div className="each-inp-field">
                                                <label>Subtitle</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="subtitle" id="subTitle"
                                                           name="subTitle"
                                                           autoComplete="off" value={this.props.containerData.subTitle}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("subTitleEnabled") &&
                                            <div className="select-chk-block" style={{"display": "block"}}>
                                                <label className="control control-checkbox">Enable Subtitle
                                                    <input className="inp-chk" type={`checkbox`}
                                                           name={`subTitleEnabled`}
                                                           checked={this.props.containerData.subTitleEnabled}
                                                           value={this.props.containerData.subTitleEnabled}
                                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                                    <div className="control-indicator"></div>
                                                </label>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    }
                                    <div className="chart-settings-container for-small">
                                        {(this.props.containerData.hasOwnProperty("enableyaxis") || this.props.containerData.hasOwnProperty("legend")) &&
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap ">
                                                <div class="each-settings-panel-wrap-header">
                                                    <h5 className="each-settings-panel-header">Chart Details</h5>
                                                </div>
                                                <div class="each-settings-panel-wrap-body">
                                                    <div className="task-input-container select-line">

                                                        {this.props.containerData.hasOwnProperty("enableyaxis") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Enable Y Axis
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`enableyaxis`}
                                                                       checked={enableyaxis} value={enableyaxis}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }
                                                        {this.props.containerData.hasOwnProperty("legend") &&

                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Legend
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`legend`}
                                                                       checked={legend}
                                                                       value={legend}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>

                                                        }
                                                        {this.props.containerData.hasOwnProperty("alldata") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">All Data
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`alldata`}
                                                                       checked={alldata}
                                                                       value={alldata}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }

                                                        {this.props.containerData.hasOwnProperty("stacked") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Stacked
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`stacked`}
                                                                       checked={this.props.containerData.stacked}
                                                                       value={this.props.containerData.stacked}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }
                                                        {this.props.containerData.hasOwnProperty("enablegradient") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Enable Gradient
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`enablegradient`}
                                                                       checked={enablegradient}
                                                                       value={enablegradient}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }
                                                        {this.props.containerData.hasOwnProperty("hidearea") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Hide Area
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`hidearea`}
                                                                       checked={hidearea}
                                                                       value={hidearea}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }

                                                        {this.props.containerData.hasOwnProperty("showxaxislabel") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Show x axis
                                                                label
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`showxaxislabel`}
                                                                       checked={this.props.containerData.showxaxislabel}
                                                                       value={this.props.containerData.showxaxislabel}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }

                                                        {this.props.containerData.hasOwnProperty("showyaxislabel") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Show y axis
                                                                label
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`showyaxislabel`}
                                                                       checked={this.props.containerData.showyaxislabel}
                                                                       value={this.props.containerData.showyaxislabel}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }


                                                        {this.props.containerData.hasOwnProperty("dynamicaxis") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Dynamic Axis
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`dynamicaxis`}
                                                                       checked={this.props.containerData.dynamicaxis}
                                                                       value={this.props.containerData.dynamicaxis}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {(this.props.containerData.hasOwnProperty("xwidth") || this.props.containerData.hasOwnProperty("graphHeight")) &&
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap ">
                                                <div class="each-settings-panel-wrap-header">
                                                    <h5 className="each-settings-panel-header">Axis Range</h5>
                                                </div>
                                                <div class="each-settings-panel-wrap-body">
                                                    {this.props.containerData.hasOwnProperty("min") &&
                                                    <div className="each-inp-field">
                                                        <label>Min</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Min" id="min" name="min"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.min}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>

                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("max") &&
                                                    <div className="each-inp-field">
                                                        <label>Max</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Max" id="max" name="max"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.max}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>

                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("xwidth") &&
                                                    <div className="each-inp-field">
                                                        <label>X Width</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="X width" id="xwidth"
                                                                   name="xwidth"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.xwidth}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("xheight") &&
                                                    <div className="each-inp-field">
                                                        <label>X Height</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="X Height" id="xheight"
                                                                   name="xheight"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.xheight}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("ywidth") &&
                                                    <div className="each-inp-field">
                                                        <label>Y Width</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Y Width" id="ywidth"
                                                                   name="ywidth"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.ywidth}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("yheight") &&
                                                    <div className="each-inp-field">
                                                        <label>Y Height</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Y Height" id="yheight"
                                                                   name="yheight"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.yheight}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("graphHeight") &&
                                                    <div className="each-inp-field">
                                                        <label>Graph Height</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Graph Height"
                                                                   id="graphHeight"
                                                                   name="graphHeight"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.graphHeight}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("xAngle") &&
                                                    <div className="each-inp-field">
                                                        <label>X Angle</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="X Angle" id="xAngle"
                                                                   name="xAngle"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.xAngle}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("yAngle") &&
                                                    <div className="each-inp-field">
                                                        <label>Y Angle</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Y Angle" id="yAngle"
                                                                   name="yAngle"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.yAngle}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("transparancy") &&
                                                    <div className="each-inp-field">
                                                        <label>Transperency</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="transparancy"
                                                                   id="transparancy"
                                                                   name="transparancy" autoComplete="off"
                                                                   value={this.props.containerData.transparancy}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }

                                                    {this.props.containerData.hasOwnProperty("bordercolor") &&
                                                    <div className="each-inp-field">
                                                        <label> Border Color</label>
                                                        <div className="new-task-input">
                                                            <ColorPicker
                                                                stroke={`bordercolor`}
                                                                color={this.props.containerData.bordercolor}
                                                                onChangeComplete={this.props.borderColorChange}
                                                                handleClickStaus={this.props.handleClick}
                                                            />
                                                        </div>
                                                    </div>
                                                    }

                                                    {this.props.containerData.hasOwnProperty("bubblecolor") &&
                                                    <>
                                                        <div className="main-container-wrap">
                                                            <label
                                                                className="task-input-container container-flex"> Bubble
                                                                color</label>
                                                        </div>
                                                        <div className="graph-proprties">
                                                            <div
                                                                className="graph-proprties-color modal-panel-container">
                                                                <div className="task-input-container">
                                                                    <div>
                                                                        {this.props.containerData.bubblecolor.map((item, index) => (
                                                                            <div className={'panel-each'}>
                                                                                <div className={`settings-panel`}>
                                                                                    <input type={`text`} name="label"
                                                                                           defaultValue={item.label}
                                                                                           onChange={(e) => this.props.handleLabelChange(e, item.id, "bubblecolor")}
                                                                                    />
                                                                                    &nbsp;&nbsp;
                                                                                    <ColorPicker
                                                                                        stroke={item.id}
                                                                                        color={item.color}
                                                                                        label={`bubblecolor`}
                                                                                        onChangeComplete={this.props.handleBubblecolorChange}
                                                                                        handleClickStaus={this.props.handleClick}
                                                                                    />

                                                                                </div>
                                                                                <div
                                                                                    className={`settings-panel-option`}>
                                                                                    {index + 1 === this.props.containerData.bubblecolor.length &&
                                                                                    <button
                                                                                        className="ico-lib divider-add-button"
                                                                                        onClick={() => this.props.addData(item.id, "bubblecolor")}></button>
                                                                                    }
                                                                                    {this.props.containerData.bubblecolor.length !== 1 &&
                                                                                    <button
                                                                                        className="ico-lib divider-sub-button"
                                                                                        onClick={() => this.props.removeData(item.id, "bubblecolor")}></button>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        ))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }

                                                    {this.props.containerData.hasOwnProperty("strokecolor") &&
                                                    <>
                                                        <div className="main-container-wrap">
                                                            <label
                                                                className="task-input-container container-flex"> Stroke
                                                                color</label>
                                                        </div>
                                                        <div className="graph-proprties">
                                                            <div
                                                                className="graph-proprties-color modal-panel-container">
                                                                <div className="task-input-container">
                                                                    <div>
                                                                        {this.props.containerData.strokecolor.map((item, index) => (
                                                                            <div className={'panel-each'}>
                                                                                <div className={`settings-panel`}>
                                                                                    <ColorPicker
                                                                                        stroke={item.id}
                                                                                        color={item.color}
                                                                                        label={`strokecolor`}
                                                                                        onChangeComplete={this.props.handleBubblecolorChange}
                                                                                        handleClickStaus={this.props.handleClick}
                                                                                    />

                                                                                </div>
                                                                                <div
                                                                                    className={`settings-panel-option`}>
                                                                                    {index + 1 === this.props.containerData.strokecolor.length &&
                                                                                    <button
                                                                                        className="ico-lib divider-add-button"
                                                                                        onClick={() => this.props.addData(item.id, "strokecolor")}></button>
                                                                                    }
                                                                                    {this.props.containerData.strokecolor.length !== 1 &&
                                                                                    <button
                                                                                        className="ico-lib divider-sub-button"
                                                                                        onClick={() => this.props.removeData(item.id, "strokecolor")}></button>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        ))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }

                                                    {this.props.containerData.hasOwnProperty("fillcolor") &&
                                                    <>
                                                        <div className="main-container-wrap">
                                                            <label className="task-input-container container-flex"> Fill
                                                                color</label>
                                                        </div>
                                                        <div className="graph-proprties">
                                                            <div
                                                                className="graph-proprties-color modal-panel-container">
                                                                <div className="task-input-container">
                                                                    <div>
                                                                        {this.props.containerData.fillcolor.map((item, index) => (
                                                                            <div className={'panel-each'}>
                                                                                <div className={`settings-panel`}>
                                                                                    <ColorPicker
                                                                                        stroke={item.id}
                                                                                        color={item.color}
                                                                                        label={`fillcolor`}
                                                                                        onChangeComplete={this.props.handleBubblecolorChange}
                                                                                        handleClickStaus={this.props.handleClick}
                                                                                    />

                                                                                </div>
                                                                                <div
                                                                                    className={`settings-panel-option`}>
                                                                                    {index + 1 === this.props.containerData.fillcolor.length &&
                                                                                    <button
                                                                                        className="ico-lib divider-add-button"
                                                                                        onClick={() => this.props.addData(item.id, "fillcolor")}></button>
                                                                                    }
                                                                                    {this.props.containerData.fillcolor.length !== 1 &&
                                                                                    <button
                                                                                        className="ico-lib divider-sub-button"
                                                                                        onClick={() => this.props.removeData(item.id, "fillcolor")}></button>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        ))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("basedon") &&
                                                    <>
                                                        <div className="main-container-wrap">
                                                            <label
                                                                className="task-input-container container-flex"> Based
                                                                on</label>
                                                        </div>
                                                        <div className="task-input-container">
                                                            <div className="select-box eq-width">
                                                                <select id="multi-area-chart-collections" name="basedon"
                                                                        onChange={(e) => this.props.handleChange(e)}
                                                                        value={this.props.containerData.basedon}>

                                                                    <option value="|">Please select</option>
                                                                    {showlistField.map((label, index) =>
                                                                        <option
                                                                            key={`multi-area-chart-masterid` + index}
                                                                            value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                        }


                                    </div>
                                    {this.props.containerData.hasOwnProperty("linechart") &&
                                    <div className="chart-settings-container for-small">
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap ">
                                                <div className="each-settings-panel-wrap-body">
                                                    <div className="task-input-container select-line">
                                                        <div className="each-inp-field">
                                                            <label>Fields</label>
                                                            <div className="select-box eq-width">
                                                                <select id="priority" name="fields"
                                                                        onChange={(e) => this.props.handleInputChange(e)}
                                                                        value={this.props.containerData.fields}>
                                                                    <option value="">Please Select</option>
                                                                    <option value="101">CreateDate</option>
                                                                    {showlistField.map((label, index) =>
                                                                        <option
                                                                            value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-field">
                                                            <label>Aggregation:</label>
                                                            <div className="select-box eq-width">
                                                                <select id="priority" name="aggregation"
                                                                        onChange={(e) => this.props.handleInputChange(e)}
                                                                        value={this.props.containerData.aggregation}>
                                                                    <option value="">Please Select</option>
                                                                    <option value="sum">Sum</option>
                                                                    <option value="average">Average</option>
                                                                    <option value="max">Max</option>
                                                                    <option value="min">Min</option>
                                                                    <option value="count">Count</option>

                                                                </select>
                                                            </div>

                                                        </div>
                                                        {this.props.containerData.hasOwnProperty("linecolor") &&
                                                        <div className="each-inp-field">
                                                            <label>Line Colour</label>
                                                            <ColorPicker
                                                                stroke={"linecolor"}
                                                                color={this.props.containerData.linecolor}
                                                                onChangeComplete={this.props.handleChangeComplete}
                                                                handleClickStaus={this.props.handleClick}
                                                            />
                                                        </div>
                                                        }
                                                        {this.props.containerData.hasOwnProperty("checked") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Enable Graph
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`graph`}
                                                                       checked={this.props.containerData.checked}
                                                                       value={this.props.containerData.checked}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>

                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {this.props.containerData.hasOwnProperty("min") &&
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap ">
                                                <div className="each-settings-panel-wrap-body">
                                                    <div className="task-input-container select-line">
                                                        <div className="each-inp-field">
                                                            <label>Min</label>
                                                            <div className="new-task-input">
                                                                <input type="text" placeholder="Min" id="min" name="min"
                                                                       autoComplete="off"
                                                                       value={this.props.containerData.min}
                                                                       onChange={(e) => this.props.handleChange(e)}/>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-field">
                                                            <label>Max</label>
                                                            <div className="new-task-input">
                                                                <input type="text" placeholder="Max" id="max" name="max"
                                                                       autoComplete="off"
                                                                       value={this.props.containerData.max}
                                                                       onChange={(e) => this.props.handleChange(e)}/>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-field">
                                                            <label>X axis</label>
                                                            <div className="select-box eq-width">
                                                                <select id="xaxis" name="x"
                                                                        onChange={(e) => this.props.handleInputChange(e)}
                                                                        value={this.props.containerData.x}>
                                                                    <option value="">X axis</option>
                                                                    {showlistField.map((label, index) =>
                                                                        <option
                                                                            value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-field">
                                                            <label>Y axis</label>
                                                            <div className="select-box eq-width">
                                                                <select id="yaxis" name="y"
                                                                        onChange={(e) => this.props.handleInputChange(e)}
                                                                        value={this.props.containerData.y}>
                                                                    <option value="">Y axis</option>
                                                                    {showlistField.map((label, index) =>
                                                                        <option key={`line-chart-${index}`}
                                                                                value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }


                                    </div>
                                    }

                                    {this.props.containerData.hasOwnProperty("seriesLabel") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="task-input-container">
                                            {/*<div className="each-settings-panel-wrap-header">*/}
                                            {/*    <h5 className="each-settings-panel-header">Graph axis</h5>*/}
                                            {/*</div>*/}
                                            <div className="each-settings-panel-wrap-body">
                                                <div className="each-inp-field">
                                                    <label>Series Name</label>
                                                    <div className="new-task-input">
                                                        <input type="text" placeholder="series label" id="seriesLabel"
                                                               name="seriesLabel"
                                                               autoComplete="off"
                                                               value={this.props.containerData.seriesLabel}
                                                               onChange={(e) => this.props.handleChange(e)}/>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Field Name</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="seriesField"
                                                                onChange={(e) => this.props.handleChange(e)}
                                                                value={this.props.containerData.seriesField}>

                                                            <option value="|">Please select</option>
                                                            {showlistField.map((label, index) =>
                                                                <option key={`multi-area-chart-masterid` + index}
                                                                        value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">

                                                    {this.props.containerData.serColor.map((item, index) => (
                                                        <div className={'panel-each'}>
                                                            <div className={`settings-panel dynamic-cp`}>

                                                                <ColorPicker
                                                                    stroke={item.id}
                                                                    color={item.color}
                                                                    label={`serColor`}
                                                                    onChangeComplete={this.props.serColorChange}
                                                                    handleClickStaus={this.props.handleClick}
                                                                />

                                                            </div>
                                                            <div className={`settings-panel-option`}>
                                                                {index + 1 === this.props.containerData.serColor.length &&
                                                                <button className="ico-lib divider-add-button"
                                                                        onClick={() => this.props.addData(item.id, "serColor")}></button>
                                                                }
                                                                {this.props.containerData.serColor.length !== 1 &&
                                                                <button className="ico-lib divider-sub-button"
                                                                        onClick={() => this.props.removeData(item.id, "serColor")}></button>
                                                                }
                                                            </div>
                                                        </div>

                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    }

                                    {this.props.containerData.hasOwnProperty("Color") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="task-input-container">
                                            <div className="each-settings-panel-wrap-header">
                                                <h5 className="each-settings-panel-header">Axis Color</h5>
                                            </div>


                                            <div className="each-settings-panel-wrap-body">

                                                {this.props.containerData.Color.map((item, index) =>
                                                    <div className={'panel-each'}>
                                                        <div className={`settings-panel`}>
                                                            <ColorPicker
                                                                stroke={item.id}
                                                                color={item.color}
                                                                label={`Color`}
                                                                onChangeComplete={this.props.handleColorChange}
                                                                handleClickStaus={this.props.handleClick}
                                                            />

                                                        </div>


                                                        <div className={`settings-panel-option`}>
                                                            {index + 1 === this.props.containerData.Color.length &&
                                                            <button className="ico-lib divider-add-button"
                                                                    onClick={() => this.props.addData(item.id, "Color")}>

                                                            </button>
                                                            }

                                                            {this.props.containerData.Color.length !== 1 &&
                                                            <button className="ico-lib divider-sub-button"
                                                                    onClick={() => this.props.removeData(item.id, "Color")}>

                                                            </button>
                                                            }
                                                        </div>

                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    }


                                    {this.props.containerData.hasOwnProperty("rangeColor") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="task-input-container">
                                            <div className="each-settings-panel-wrap-header">
                                                <h5 className="each-settings-panel-header">Color By Value</h5>
                                            </div>


                                            <div className="each-settings-panel-wrap-body">
                                                {this.props.containerData.hasOwnProperty("colorAxis") &&
                                                <div className="each-inp-field">
                                                    <label>Based on</label>
                                                    <div className="select-box eq-width">
                                                        <select id="layout"
                                                                name="colorAxis"
                                                                onChange={(e) => this.props.handleChange(e)}
                                                                value={this.props.containerData.colorAxis}>
                                                            <option value="">Choose Axis</option>
                                                            <option value={"x"}>X</option>
                                                            <option value={"y"}>Y</option>
                                                            <option value={"z"}>Z</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                }
                                                {this.props.containerData.rangeColor.map((item, index) =>
                                                    <div className={'panel-each'}>
                                                        <div className={`settings-panel`}>
                                                            <ColorPicker
                                                                stroke={item.id}
                                                                color={item.color}
                                                                label={`rangeColor`}
                                                                onChangeComplete={this.props.handleBubblecolorChange}
                                                                handleClickStaus={this.props.handleClick}
                                                            />
                                                            <div className="each-inp-field">
                                                                <div className="new-task-input">
                                                                    <input
                                                                        onChange={(e) => this.props.handleLabelChange(e, item.id, "rangeColor", "from")}
                                                                        value={item.from}
                                                                        type="text" placeholder="From" name="from"
                                                                        autoComplete="off"/>
                                                                </div>
                                                            </div>
                                                            <div className="each-inp-field">
                                                                <div className="new-task-input">
                                                                    <input
                                                                        onChange={(e) => this.props.handleLabelChange(e, item.id, "rangeColor", "to")}
                                                                        value={item.to}
                                                                        type="text" placeholder="To" name="to"
                                                                        autoComplete="off"/>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className={`settings-panel-option`}>
                                                            {index + 1 === this.props.containerData.rangeColor.length &&
                                                            <button className="ico-lib divider-add-button"
                                                                    onClick={() => this.props.addData(item.id, "rangeColor")}>

                                                            </button>
                                                            }

                                                            {this.props.containerData.rangeColor.length !== 1 &&
                                                            <button className="ico-lib divider-sub-button"
                                                                    onClick={() => this.props.removeData(item.id, "rangeColor")}>

                                                            </button>
                                                            }
                                                        </div>

                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.props.containerData.hasOwnProperty("unit") &&
                                    <div className="each-settings-panel-wrap panel-lg-height">
                                        <div class="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Unit Details</h5>
                                        </div>
                                        <div class="each-settings-panel-wrap-body">
                                            {this.props.containerData.hasOwnProperty("unit") &&
                                            <div className="each-inp-field">
                                                <label>Unit</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="Unit" id="unit" name="unit"
                                                           autoComplete="off"
                                                           value={this.props.containerData.unit}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("layout") &&
                                            <div className="each-inp-field">
                                                <label>Layout</label>
                                                <div className="select-box eq-width">
                                                    <select id="layout"
                                                            name="layout"
                                                            onChange={(e) => this.props.handleChange(e)}
                                                            value={this.props.containerData.layout}>
                                                        <option value="">none</option>
                                                        <option value={"horizontal"}>horizontal</option>
                                                        <option value={"vertical"}>vertical</option>
                                                    </select>
                                                </div>
                                            </div>
                                            }

                                            {this.props.hasOwnProperty("radialbarchartType") &&
                                            <div className="each-inp-field">
                                                <label>Type</label>
                                                <div className="select-box eq-width">
                                                    <select id="multi-area-chart-collections" name="type"
                                                            onChange={(e) => this.props.handleChange(e)}
                                                            value={this.props.radialbarchartType}>
                                                        <option value="">Please Select</option>
                                                        <option value="normal">Normal</option>
                                                        <option value="custom">Custom</option>

                                                    </select>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    }
                                    {this.props.containerData.hasOwnProperty("setFields") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="task-input-container">
                                            <div className="each-settings-panel-wrap-header">
                                                <h5 className="each-settings-panel-header">Set Fields</h5>
                                            </div>


                                            <div className="each-settings-panel-wrap-body">
                                                {this.props.containerData.setFields.map((item, index) =>
                                                    <div className={'panel-each'}>
                                                        <div className={`settings-panel`}>
                                                            <div className="each-inp-field">
                                                                <label>Fields</label>
                                                                <div className="select-box eq-width">
                                                                    <select id="priority" name="masterid"
                                                                            onChange={(e) => this.props.handleLabelChange(e, item.id, "setFields")}
                                                                            value={item.masterid}>
                                                                        <option value="">Please Select</option>
                                                                        {showlistField.map((label, index) =>
                                                                            <option
                                                                                value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className={`settings-panel-option`}>
                                                            {index + 1 === this.props.containerData.setFields.length &&
                                                            <button className="ico-lib divider-add-button"
                                                                    onClick={() => this.props.addData(item.id, "setFields")}>

                                                            </button>
                                                            }

                                                            {this.props.containerData.setFields.length !== 1 &&
                                                            <button className="ico-lib divider-sub-button"
                                                                    onClick={() => this.props.removeData(item.id, "setFields")}>

                                                            </button>
                                                            }
                                                        </div>

                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    }


                                </div>

                            </div>
                        </div>
                        }

                        {this.props.hasOwnProperty('modaltype') && this.props.modaltype === 'datetime' &&

                        <div className="modal-body">
                            <div className="chart-settings-container">
                                <div className="chart-settings-splitter">
                                    <div className="chart-settings-splitter-header">
                                        <h5>Data</h5>
                                    </div>

                                    <div className="each-settings-panel-wrap">

                                        <div className="each-settings-panel-wrap">
                                            <div class="each-settings-panel-wrap-header">
                                                <h5 className="each-settings-panel-header">Chart Details</h5>
                                            </div>
                                            <div class="each-settings-panel-wrap-body">
                                                <div className="each-inp-field">
                                                    <label>Chart Title</label>
                                                    <div className="new-task-input">
                                                        <input type="text" placeholder="title" id="chartTitle"
                                                               name="chartTitle"
                                                               autoComplete="off"
                                                               value={this.props.containerData.chartTitle}
                                                               onChange={(e) => this.props.handleChange(e)}/>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="each-settings-panel-wrap-body">
                                            <div className="task-input-container">


                                                <div className="each-inp-field">
                                                    <label>Format</label>
                                                    <div className="new-task-input">
                                                        <input type="text" placeholder=""
                                                               name="format"
                                                               autoComplete="off"
                                                               value={this.props.containerData.format}
                                                               onChange={(e) => this.props.handleChange(e)}/>
                                                    </div>

                                                </div>

                                                <div className="each-inp-field">
                                                    <label>Display Time</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="timeFormat"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.timeFormat}>
                                                            <option value="no">no</option>

                                                            <option value="yes">yes</option>

                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="each-inp-field">
                                                    <label>Display live Clock</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="dispClock"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={this.props.containerData.dispClock}>
                                                            <option value="no">no</option>

                                                            <option value="yes">yes</option>

                                                        </select>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        <>
                            {getUrlSegment(1) !== "entity" ?
                                <div class="modal-footer">
                                    <button type="button" class="general-btn grey btn btn-primary"
                                            onClick={() => this.props.cancelAction()}>Cancel
                                    </button>
                                    <button type="submit" class="general-btn btn btn-primary"
                                            onClick={() => this.props.OKAction()}>Apply
                                    </button>
                                </div>
                                :
                                <div className="modal-footer">
                                    <button type="button" className="general-btn grey btn btn-primary"
                                            onClick={() => this.props.cancelAction()}>Ok
                                    </button>

                                </div>
                            }
                        </>


                    </div>
                </div>
            </div>
        )
    }

}


export default SettingsModal
