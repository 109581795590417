import React, {Component} from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis,CartesianGrid, Tooltip, Area, Legend } from 'recharts';
function BarChartCard(props) {
    return (
        <React.Fragment>
            <ComposedChart
                width={240} height={180} data={props.data}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey="x" padding={{left:0, right:0}} domain={[0, 500]}/>
                <YAxis dataKey="y" hide={true}  />
                <Tooltip />
                <Bar dataKey="y" fill={props.color} radius={[5, 5, 0, 0]} maxBarSize={25} />

                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={props.color2}  stopOpacity={0.5}/>
                        <stop offset="95%" stopColor={props.color2}  stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <Area type="monotone" dataKey="z" fill={props.color2} stroke={props.color2}
                      fillOpacity={1} fill="url(#colorUv)" dot={{strokeWidth : 2}} />
                {<Line type="monotone" dataKey="z" stroke={props.color2} />}
            </ComposedChart>
        </React.Fragment>
    );
}

export default BarChartCard;