import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {Datetimecomparison, getUrlSegment} from "../../../utilities/CustomFunctions";
import {getProfile} from "../../../utilities/AuthService";
import {USRIMG_PATH} from "../../../constants";
import {
    ComposedChart, Line, Area, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend, Scatter, ResponsiveContainer,
} from 'recharts';
import Modal from "../../views/Modal";
import AutoComplete from "../../views/AutoComplete"
import * as usersActions from "../../../actions/Users";
import LoaderSvg from "../../views/LoaderSVG";
import rootActions from "../../../actions";
import AlertModal from "../../views/AlertModal";
import NoData from "../../views/NoData";
import ProjectHeader from "../../views/ProjectHeader";
import StakeholderTile from "../../views/StakeholderTile";

class ProjectTeam extends Component {

    constructor(props) {
        super(props);

        this.state={
            overviewtab:"home-tab",
            viewtyp:"default",
            Addteam:false,
            selectedRespUser:[],
            selectedAccountUser:[],
            selectedConsultUser:[],
            selectedInformUser:[],
            loading: 0,
            DelteamUser:false,
            assignuser_err:false,
            teamUser:'',
            teamUsertyp:'',
            projectid:0,
            userdetail:[],
            expandHeader:false
        }
        this.Teamview= this.Teamview.bind(this);
        this.formatXAxis=this.formatXAxis.bind(this);
        this.AddProjectTeam=this.AddProjectTeam.bind(this);
        this.CloseProjectTeam=this.CloseProjectTeam.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.AddProjectUser=this.AddProjectUser.bind(this);
        this.reset=this.reset.bind(this);
        this.deleteModal=this.deleteModal.bind(this);
        this.delProjectUser=this.delProjectUser.bind(this);
        this.cancelDeleteUser=this.cancelDeleteUser.bind(this);
        this.getUserId=this.getUserId.bind(this);
        this.usersList = this.usersList.bind(this);
        this.expandHeader = this.expandHeader.bind(this);
    }

    usersList(searchText) {
        var user_data = { uid : "0", search : searchText? searchText : '',  offset : 100 , ignoreUsers : true, ignoreArrays :[], valueField : 'uniqueId', userOnly : true};
        this.props.users(user_data);
    }

    componentDidMount() {
        this.setState({loading: 1});
        var prjctdtlid = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/team/'+prjctdtlid);
        this.setState({loading: 0});
        this.usersList();
        var pagename = getUrlSegment(3);
        this.setState({pagename : pagename});
        let projectID = atob(prjctdtlid)
        let data ={
            "_id": projectID,
            pagename : pagename
        }
        this.props.projectInfo(data);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevProps.userAdddata.projectuser_saved !== this.props.userAdddata.projectuser_saved) || (prevProps.userAdddata.project_deleted !== this.props.userAdddata.project_deleted) ) {
            var beta = this;
            this.setState({userdetail: this.props.ProjectUsersData.ProjectInfo.projectusers||[]})
            var projectId = getUrlSegment(4);
            var projectid = (atob(projectId));
            var pagename = getUrlSegment(3);
            let data ={
                "_id": projectid,
                pagename : pagename
            }
            setTimeout(function () {
                beta.props.projectInfo(data);
                beta.setState({DelteamUser : false});
                beta.CloseProjectTeam();
                beta.setState({loading: 0});
            }, 1000)

        }

        if(prevProps.ProjectUsersData.ProjectInfo !== this.props.ProjectUsersData.ProjectInfo){
            let userData= this.props.ProjectUsersData.ProjectInfo.projectusers;
            let selectedAccountUser = [];
            let selectedConsultUser = [];
            let selectedInformUser = [];
            let selectedRespUser = [];



            if(userData) {
                selectedAccountUser  = userData.filter(item => item.type === 'accountable');
                selectedConsultUser  = userData.filter(item => item.type === 'consultant');
                selectedInformUser  = userData.filter(item => item.type === 'informer');
                selectedRespUser  = userData.filter(item => item.type === 'responsible');
            }

            this.setState({userdetail: this.props.ProjectUsersData.ProjectInfo.projectusers||[],
                    selectedAccountUser : selectedAccountUser,
                    selectedConsultUser : selectedConsultUser,
                    selectedInformUser : selectedInformUser,
                    selectedRespUser : selectedRespUser


                    }
                )
        }

    }

    Teamview(e,v_typ) {
        this.setState({viewtyp: v_typ});
    }

    formatXAxis(date) {
        return moment(date).format("ddd");
    }

    AddProjectTeam(){
        this.setState({Addteam: true});
    }

    CloseProjectTeam(){
        this.setState({assignuser_err: false});
        this.setState({Addteam: false});
        this.reset();
    }

    Close=()=>{
        this.setState({assignuser_err: false});
        this.setState({Addteam: false});

    }
    reset(){
        this.setState({selectedRespUser: [], selectedAccountUser: [], selectedConsultUser: [], selectedInformUser: []});
    }
    deleteModal(id,type){
        this.setState({teamUser: id, teamUsertyp: type, DelteamUser: true });
    }
    cancelDeleteUser(){
        this.setState({DelteamUser: false});
        this.setState({assignuser_err: false});

    }
    delProjectUser(){
        this.setState({loading: 1});
        let pUserid =  (atob(getUrlSegment(4)));
        let deletedddata = {projectid : pUserid, user : this.state.teamUser, type : this.state.teamUsertyp}
        this.props.deleteProjectuser(deletedddata);

    }
    resultDataList( result, reference) {
        this.setState({ [reference] : result });
    }
    AddProjectUser(){
        this.setState({loading: 1});
        let formValid = 0;
        var RespUser = '';
        var AccUser = '';
        var ConsltUser = '';
        var InfUser = '';
        var projectid = (atob(getUrlSegment(4)));
        var selectedRespUser = this.state.selectedRespUser;
        var selectedAccountUser = this.state.selectedAccountUser;
        var selectedConsultUser = this.state.selectedConsultUser;
        var selectedInformUser = this.state.selectedInformUser;

        if (selectedRespUser.length > 0) {
            for (var i = 0; i < selectedRespUser.length; i++) {
                RespUser = (RespUser !== '') ? (RespUser + ",") : "";
                RespUser += selectedRespUser[i].userId;
            }
        }
        if (selectedAccountUser.length > 0) {
            for (var i = 0; i < selectedAccountUser.length; i++) {
                AccUser = (AccUser !== '') ? (AccUser + ",") : "";
                AccUser += selectedAccountUser[i].userId;
            }
        }
        if (selectedConsultUser.length > 0) {
            for (var i = 0; i < selectedConsultUser.length; i++) {
                ConsltUser = (ConsltUser !== '') ? (ConsltUser + ",") : "";
                ConsltUser += selectedConsultUser[i].userId;
            }
        }
        if (selectedInformUser.length > 0) {
            for (var i = 0; i < selectedInformUser.length; i++) {
                InfUser = (InfUser !== '') ? (InfUser + ",") : "";
                InfUser += selectedInformUser[i].userId;
            }
        }

        if(selectedRespUser.length == '0' && selectedAccountUser.length == '0' && selectedConsultUser.length == '0' && selectedInformUser.length == '0'){
            formValid++;
            this.setState({assignuser_err: true});
            this.setState({loading: 0});
        }

        if(formValid !== 1) {
            let PrjctUser = {
                "i": projectid,
                "uid": getProfile().id,
                "responsible": RespUser,
                "accountable": AccUser,
                "consultant": ConsltUser,
                "informer": InfUser

            }

            this.props.saveprojectuser(PrjctUser);

        }
    }
    getUserId(p_uid){
        const prjct_assign = [];
        let Project_id = p_uid;
        if (prjct_assign.includes(p_uid) === false) prjct_assign.push(p_uid);

    }
    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }
    render() {
        let userdetail = this.state.userdetail;
        return(
                <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>

                    <ProjectHeader expandHeader={this.expandHeader}/>
                    <div className="ibt-body">
                        <div className="stakeholders-wrapper">
                            <div className="stakeholders-row">
                                {(this.props.ProjectUsersData.ProjectInfo.loading || this.state.loading === 1) &&
                                <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                                }
                                <div className="stakeholders-lister-block">
                                    {userdetail.length === 0 &&
                                    <div className='absolute-holder'>
                                        <NoData
                                            msg={`No Users assigned`}

                                        />
                                    </div>
                                    }
                                    {userdetail.map((list,index)=> {
                                        let designation = '';
                                        if(list.designation && list.organization)
                                        {
                                            designation = list.designation + ', ' + list.organization;
                                        }
                                        else if(list.designation !=='' && list.organization ==='')
                                        {
                                            designation = list.designation;
                                        }
                                        else if(list.designation ==='' && list.organization !=='')
                                        {
                                            designation = list.organization;
                                        }
                                        return(
                                        <div className="ind-stakeholders-list" key={this.props.index}>
                                            <div className="profile-preview-block">
                                                <div className="profile-preview-header">
                                                    <h5>{list.organization}</h5>
                                                </div>
                                                <div className="profile-preview-body">
                                                    <div className="user-profiles-image">
                                                        <img src={USRIMG_PATH + list.image}/>
                                                    </div>
                                                    <div className="user-profile-det">
                                                        <h5>{list.firstName}  {list.lastName}</h5>
                                                        {designation &&
                                                        <p className="team-info">{designation} </p>
                                                        }
                                                        {list.email &&
                                                        <p className="team-info">{list.email}</p>
                                                        }
                                                        {list.type &&
                                                        <p className="team-info">{list.type}</p>
                                                        }
                                                        <p className="pnone-numb">{list.mob}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    )}
                                </div>
                            </div>
                            <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Team" onClick={this.AddProjectTeam} >
                                <button className="float-button ico-lib float-add"  ></button>
                            </div>
                        </div>
                    </div>
                    {this.state.Addteam === true &&
                    <Modal title={'Assign new user to the project'} Close={this.Close}>
                        <div className="task-content-block  dynamic-creation">
                            <div className="persitant-top">
                                {this.state.loading === 1 &&
                                <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                                }
                                <div className="task-input-block">
                                    <div className="task-inp-container">
                                        <AutoComplete
                                            fieldTitle = ''
                                            placeholder = 'Resposible'
                                            sourceDataList = {this.props.listUsers.todoUsers}
                                            searchField = 'firstName'
                                            showIcon={true}
                                            showSubLabel = {true}
                                            labelFields = 'firstName,lastName'
                                            valueField ='userId'
                                            subLabelField = 'designation'
                                            showProfile = {true}
                                            reference='selectedRespUser'
                                            selectedItems = {this.state.selectedRespUser}
                                            resultDataList = {this.resultDataList.bind(this)}
                                            id="responsible_users"
                                            instantSearch={true}
                                            actionMethod={this.usersList}
                                        />
                                    </div>
                                    <div className="task-inp-container">
                                        <AutoComplete
                                            fieldTitle = ''
                                            placeholder = 'Accountable'
                                            sourceDataList = {this.props.listUsers.todoUsers}
                                            searchField = 'firstName'
                                            showIcon={true}
                                            showSubLabel = {true}
                                            labelFields = 'firstName,lastName'
                                            valueField ='userId'
                                            subLabelField = 'designation'
                                            showProfile = {true}
                                            reference='selectedAccountUser'
                                            selectedItems = {this.state.selectedAccountUser}
                                            resultDataList = {this.resultDataList.bind(this)}
                                            id="accountable_users"
                                            instantSearch={true}
                                            actionMethod={this.usersList}
                                        />
                                    </div>
                                    <div className="task-inp-container">
                                        <AutoComplete
                                            fieldTitle = ''
                                            placeholder = 'Consultant'
                                            sourceDataList = {this.props.listUsers.todoUsers}
                                            searchField = 'firstName'
                                            showIcon={true}
                                            showSubLabel = {true}
                                            labelFields = 'firstName,lastName'
                                            valueField ='userId'
                                            subLabelField = 'designation'
                                            showProfile = {true}
                                            reference='selectedConsultUser'
                                            selectedItems = {this.state.selectedConsultUser}
                                            resultDataList = {this.resultDataList.bind(this)}
                                            id="consultant_users"
                                            instantSearch={true}
                                            actionMethod={this.usersList}
                                        />
                                    </div>
                                    <div className="task-inp-container">
                                        <AutoComplete
                                            fieldTitle = ''
                                            placeholder = 'Informer'
                                            sourceDataList = {this.props.listUsers.todoUsers}
                                            searchField = 'firstName'
                                            showIcon={true}
                                            showSubLabel = {true}
                                            labelFields = 'firstName,lastName'
                                            valueField ='userId'
                                            subLabelField = 'designation'
                                            showProfile = {true}
                                            reference='selectedInformUser'
                                            selectedItems = {this.state.selectedInformUser}
                                            resultDataList = {this.resultDataList.bind(this)}
                                            id="informer_users"
                                            instantSearch={true}
                                            actionMethod={this.usersList}
                                        />
                                    </div>
                                    <label className = "assign-user-validation-err" style={{display: this.state.assignuser_err === false ? 'none' : 'block' }}>Please provide any of the values</label>
                                </div>



                            </div>
                            <div className="persitant-footer">
                                <div className="group-row">

                                </div>
                                <button data-masterid="0" data-mode="save" type="button" className="general-btn" onClick={(e)=> this.AddProjectUser()}
                                        id="createproject">{'Update'}
                                </button>
                            </div>
                        </div>

                    </Modal>
                    }
                    {this.state.DelteamUser == true &&
                    <AlertModal id="take-pool"
                                title="Are you sure want to delete?"
                                OK_button_text="Yes, Delete It"
                                OK_action={() => this.delProjectUser()}
                                showModal = {this.state.DelteamUser}
                                CANCEL_action={() => this.cancelDeleteUser()}
                    />
                    }
                </div>
        );
    }
}
const mapStateToProps = state => ({
    ProjectUsersData:state.ProjectReducer.ProjectInfo,
    listUsers:state.UsersReducer.listUsersdata,
    userAdddata:state.ProjectReducer.SaveProjectUser


});
export default  withRouter(connect(mapStateToProps, {
    users:usersActions.listTodoUsers,
    mainClick:rootActions.commonActions.MenuClick,
    projectdetail:projectActions.listallprojectsdetails,
    projectuserdetail:projectActions.getProjectUsers,
    saveprojectuser:projectActions.saveProjectUser,
    deleteProjectuser:projectActions.deleteProjectUser,
    projectInfo:projectActions.projectInfo

})(ProjectTeam));
