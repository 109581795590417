import {commonService} from "./common.services";

export const resources = {
    listResources,
    DelResources,
    saveGallery,
    getResourceTags,
    getResourcesInfo
};


function listResources(params){
    return commonService.sendHttpRequest('library/resourceslist', params, "GET");
}

function DelResources(params){
    return commonService.sendHttpRequest('library/deleteresources', params, "POST");
}
function saveGallery(params){
    return commonService.sendHttpRequest('library/savegallery', params, "POST");
}
function getResourceTags(params) {
    return commonService.sendHttpRequest('task/todotags', params, "GET");
}
function getResourcesInfo(params){
    return commonService.sendHttpRequest('library/resourceslist', params, "GET");
}



