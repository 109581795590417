import React, {Component} from 'react';
import {Card,Tooltip,OverlayTrigger} from "react-bootstrap"
class Modal extends Component {

    constructor(props) {
        super(props);
        this.Minimize=this.Minimize.bind(this)
        this.Maximise=this.Maximise.bind(this)
        this.Close=this.Close.bind(this)
        this.state={
            miniMize:false,
            Maximise:false,
            Close:false

        }
      }



    componentDidMount() {
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    Minimize(){
        this.setState({Maximise:false,miniMize:true})
    }
    Maximise(){
        if(!this.state.miniMize) {
            this.setState({Maximise: !this.state.Maximise, miniMize: false})
        }
        else{
            this.setState({Maximise: false, miniMize: false})
        }
    }
    Close() {
        this.props.Close();
    }


    render() {

//////////////console.log(this.props.index)
         return (

             <div style={{"z-index":"9999999"}} className={`task-toggler-wrapper task-tg ${this.state.Maximise ? 'maximized': ''} ${this.state.miniMize ? 'minimized': ''}  `}>
                 <div className="task-toggler-header">
                     <h5 className="task-head-text">{this.props.title}</h5>
                     <div className="task-header-options">
                         {this.state.miniMize === false &&
                         <button className="task-option-button minimize-button"  onClick={this.Minimize}>
                             <span className="icon-in icon-minimize"></span>
                         </button>
                         }
                         <button className="task-option-button" onClick={this.Maximise}>
                             <span className="icon-in icon-expand-toggler"></span>
                         </button>
                         <button className="task-option-button"  onClick={()=> this.Close()}>
                             <span className="icon-in icon-close-in"  ></span>
                         </button>


                     </div>
                 </div>

                 {this.props.children}

             </div>



        );
    }
}



export default Modal;


