import React, {Component} from 'react';
import {getUrlSegment} from "../../utilities/CustomFunctions";

class ProjectCycleCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
    let milestones = this.props.milestonelist ? this.props.milestonelist : [];
        return (

            <div className="card-body project-cycle-body">
                <div className="task-list-wrapper">
                    {milestones.map((list,index)=> {
                        let perCalculated= this.props.showProgress(list.completedtask,list.totaltask);
                        let completedmilestone = list.completedmilestone;
                        return(
                        <div className="ind-task-list project-cycle-info-list" index={"milestones"+index}>
                            <a onClick={(e) => this.props.history.push('/project/details/tasks/' + btoa(list.milestone_id) + '/' +getUrlSegment(4))}
                               className="ind-task-list-click">
                                <div className="ind-task-list-left">
                                    <div className="progress-wrapper">
                                        <h5>{list.milestone_name}</h5>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{width: (completedmilestone===true ? "100%" : perCalculated+'%')}}
                                                 aria-valuenow="84" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>{completedmilestone===true ? "100%" : perCalculated+'%'}</p>
                                    </div>
                                </div>
                                <div className="ind-task-list-right">
                                    <p className="status-mark">{list.completedmilestone === true ? "Completed" : "Not Completed"}</p>
                                </div>
                            </a>
                        </div>
                    )}
                    )}
                </div>
            </div>

        );
    }


}

export default ProjectCycleCard;