import React, {Component} from 'react';
import {getProfile} from "../../utilities/AuthService";
import {withRouter} from 'react-router';
import UploadFile from '../../utilities/Upload/UploadFile';
import {formatDate,getSchedulerDates} from "../../utilities/CustomFunctions";
import {connect} from "react-redux";
import * as collectionActions from "../../actions/Collections";
import * as resourceActions from "../../actions/Resources";
import * as taskActions from "../../actions/ToDo";
import  TextEditor from  "../views/TextEditor"
import * as projectActions from "../../actions/Project";
import {TextField, TextArea, DateTimeField,DropDownBox} from "../views/FormElements";
import AutoComplete from "../views/AutoComplete";
import * as usersActions from "../../actions/Users";
import LoaderSvg from "../views/LoaderSVG";
import AlertModal from "../views/AlertModal";
import {FormTodoTile} from "./task/TaskDetailDataNew";
import {ResourceTodoTile} from "./task/TaskDetailDataNew";
import moment from "moment";
import OutsideAlerter from "../views/OutsideAlerter";
import TextareaAutosize from "react-textarea-autosize";
import {debounce} from 'throttle-debounce';
import {NEW_VERSION} from "../../constants";
import WhMsgBox from "./whatsapp/WhMsgBox";
import { renderToString } from 'react-dom/server';
import {Button, ButtonGroup } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import SimpleModal from "../views/SimpleModal";

class AddToDoTask extends Component {
    listRef = null;

    constructor(props) {
        super(props);
        this.onSubmit=this.onSubmit.bind(this)
        this.selectForm = this.selectForm.bind(this);

        this.state={
            assignto:'',
            subject:'',
            title:'',
            priority : 'Medium',
            duedate : '',
            startdate:'',
            projectList:[],
            open:true,
            addcc:false,
            filename: null,
            uploadedFiles : [],
            showFormModal : false,
            attachform:false,
            selectedForms:[],
            selectedFormIds:[],
            tempForms:[],
            tempFormIds:[],
            combinedFormIds : [],
            combinedForms : [],
            selectedResources:[],
            selectedResourceIds:[],
            tempResources:[],
            tempResourceIds:[],
            combinedResourceIds : [],
            combinedResources : [],
            listToUsers : [],
            listCcUsers : [],
            selectedToUsers : [],
            selectedCcUsers : [],
            CollectionAssets:false,
            selectedProjects : [],
            selectedTags : [],
            tags: [],
            newTags:[],
            miniMize:false,
            Maximise:false,
            Close:false,
            loading: 0,
            saveDraftConfirm : false,
            selftask:false,
            submitted : false,
            showProjectModal : false,
            projectAdded : false,
            showAttachCtr : false,
            showResourceModal : false,
            date_error : false,
            startdate_error:false,
            createdfrom :'',
            collectionRecord : {},
            priorityOptions : [{label: "Priority", value :"All"},
                {label: "Low", value :"Low"},
                {label: "Medium", value :"Medium"},
                {label: "High", value :"High"},
                {label: "Urgent", value :"Urgent"}],
            searchText:'',
            resType:0,
            enable_verify : 0,
            whatsappcontent : [],
            leadId : '',
            whatsappChannelId : '',
            leadPhoneNumber : '',
            subTaskType:'individual',
            schedule_date : '',
            schedule_task : "no",
            showScheduleStatus : false

        };
        this.ProjectList=[];
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.attachSelectedForms = this.attachSelectedForms.bind(this);
        this.removeSelectedForm = this.removeSelectedForm.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.getProjects = this.getProjects.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.getTags = this.getTags.bind(this);
        this.setFieldValue = this.setFieldValue.bind(this);
        this.showProjectAddModal = this.showProjectAddModal.bind(this);
        this.addProjectToTask = this.addProjectToTask.bind(this);
        this.toggleAttachCtr = this.toggleAttachCtr.bind(this);
        this.hideAttachCtr = this.hideAttachCtr.bind(this);
        this.getResources = debounce(500, this.getResources);
        this.renderChat = this.renderChat.bind(this)
        this.handletaskType=this.handletaskType.bind(this)
    }

    handleCheckChange =(e) =>{
        var fieldName = [e.target.id];
        var fieldValue = e.target.value;
        if(e.target.checked) {
            fieldValue = 'yes';
        }
        else {
            fieldValue = 'no';
        }
        this.setState({ [fieldName] : fieldValue});

        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }

    handleInputChange(id,val) {
        var fieldName = id;
        var fieldValue =val;

        this.setState({ [id] : fieldValue});

        if(fieldValue !== '') {
            //  e.target.classList.remove('val-error');
        }
    }

    componentDidMount() {
        console.log(getProfile())
        this.populateData();
        this.props.resetSelectedItems();
        if(this.props.hasOwnProperty("type")){
            if(this.props.type ==="user"){
                let uniqueIds =  this.props.selectedToUsers.map(item => item["uniqueId"]);
                this.props.setSelectedItems("selectedToUsers", uniqueIds);
                this.setState({ ["selectedToUsers"] : this.props.selectedToUsers , uniqueIds : uniqueIds });
                this.setState({"selectedToUsers":this.props.selectedToUsers})
            }
            if(this.props.type ==="mainchat") {
                this.setState({subject:this.props.subject})
            }

        }

        let params ={
            "uid": getProfile().id,
            "usertype": getProfile().usertype,
            "size" : 500
        }
        if(this.props.created_from ==='project'){
            this.setState({createdfrom : 'project'});
            if(getProfile().projectusers && getProfile().projectusers === 'projectusers'){
                params.projectid = this.props.projectId;
            }
        }

        this.props.getTaskHelper(params);
        if(document.getElementById('createtask') )
                document.getElementById('createtask').disabled = false;

        if(this.props.createdfrom === 'prjcol') {
            this.tagProjectFromCollection()
        }
        if(this.props.createdfrom ==='whatsapp'){
            console.log(this.props.selectedChats)
            let chatScreen = this.renderChat();
            let chatScreenStr = renderToString(chatScreen);
            console.log(chatScreenStr)
            let showCalendar = this.props.showCalendar;
            this.setState({createdfrom : 'whatsapp', selectedChats : this.props.selectedChats, showCalendar : showCalendar});
        }

        if(this.props.createdfrom ==='course'){

            this.calculateDueDate();
        }

    }
    renderChat = () => {
        let selectedChats = this.props.selectedChats ? this.props.selectedChats : [];
        if(this.state.createdfrom ==='whatsapp' && this.props.taskAction === 'edit'){
            selectedChats = this.state.whatsappcontent;
        }

        return (
            <React.Fragment>
                {selectedChats && selectedChats.map((item, index) =>
                    <WhMsgBox createdfrom={this.props.createdfrom} chat={item} index={index} reference={'task'}/>
                )
                }
            </React.Fragment>
        )

    }


    tagProjectFromCollection = () => {
        let selectedProjects = [];
        selectedProjects.push(this.props.taggedProject);
        this.setState({selectedProjects : selectedProjects})
    }

    setFieldValue(fieldName, fieldValue) {
        this.setState({ [fieldName] : fieldValue});
        if(fieldName === 'schedule_date' && fieldValue !== '') {
            this.setState({
                schedule_task : 'yes'
            })
        }
    }

    populateData() {
        let taskData = this.props.taskData;

        let allfilesData=this.props.allfilesData||[];
        if(taskData) {
            console.log(taskData);
            let selectedToUsers = [];
            let selectedCcUsers = [];
            if (this.props.taskAction !== 'subtask') {
                // let users = this.props.listUsers.todoUsers;
                let assignees = taskData.assignees;
                selectedToUsers = assignees.filter(a => a.share === 'no');
                selectedCcUsers = assignees.filter(a => a.share === 'yes');
            }
            /********************Forms ***************/
            let selectedForms = [];
            let selectedFormIds = [];
            if (taskData.parent.has_collection === 'yes') {
                selectedForms = taskData.collection && Array.isArray(taskData.collection) ? taskData.collection : [];

                selectedFormIds = selectedForms.map(item =>{ return item.cid.toString()});
            }
            // this.setState({selectedForms: selectedForms});

            /*******************Resources ****************/
            let selectedResourceIds = [];
            let selectedResources = [];
            selectedResourceIds = taskData.parent.resource;
            if(selectedResourceIds.length > 0) {
                if(selectedResourceIds[0] === 'Nil') {
                    selectedResourceIds.shift();
                    ////console.log(selectedResourceIds)
                }
            }
            selectedResources = taskData.parent.resourceinfo;


            /******************Projectdata************/
            let selectedProjects = taskData.parent.projectinfo;

            //this.setState({ selectedProjects: selectedProjects, projectAdded: true});

            /*****************Tag data *******************/
            let selectedTags = taskData.parent.taginfo;



            /*****************Atachment data *******************/
            let uploadedFiles = [];

            if(allfilesData) {
                let attachments =[];
                if(this.props.taskAction === 'subtask'){
                    attachments =allfilesData;
                }else{
                    attachments =taskData.attachment;
                }

                for(var i=0; i< attachments.length; i++) {
                    let uploadFile = {
                        originalfileName: attachments[i].filetitle,
                        fileName : attachments[i].filename,
                        fileSize : attachments[i].filesize,
                        fileType : attachments[i].filetype,
                        fileId : attachments[i].attachmentid
                    }
                    uploadedFiles.push(uploadFile);
                }
            }

            let collectionRecord={};

            if(taskData.collection) {
                let collections = taskData.collection;
                //console.log(collections)
                if(collections.length > 0) {
                    let myCollection = collections.filter(item =>
                        (item.users && item.users.length > 0)
                    );
                    let fieldArr =[];
                    if(myCollection.length > 0) {
                        let collFields = (myCollection[0].users && myCollection[0].users.length >0) ? myCollection[0].users[0].fieldid : '';
                        fieldArr = collFields.split(',');
                    }
                    collectionRecord = {
                        cId: collections[0].cid,
                        cName: collections[0].title,
                        recordId : fieldArr
                    }
                }

            }


            let recievedDate = moment.utc(taskData.parent.duedate).local().format('YYYY-MM-DD HH:mm:ss');
            let startDate =taskData.parent.startdate ? (moment.utc(taskData.parent.startdate).local().format('YYYY-MM-DD HH:mm:ss')) : (moment.utc(taskData.parent.createdate).local().format('YYYY-MM-DD HH:mm:ss'));
            let scheduleDate = taskData.parent.scheduleddate ? moment.utc(taskData.parent.scheduleddate).local().format('YYYY-MM-DD HH:mm:ss'):'';


            this.setState({
                title : decodeURIComponent(escape(atob(taskData.parent.Title))),
                subject : atob(taskData.parent.subject),
                priority : taskData.parent.priority,
                duedate : this.props.taskAction !=="subtask"?new Date(recievedDate):null,
                startdate : this.props.taskAction !=="subtask"?new Date(startDate):null,
                uploadedFiles : uploadedFiles,
                createdfrom : taskData.parent.createdfrom,
                selectedToUsers: selectedToUsers, selectedCcUsers: selectedCcUsers, addcc: selectedCcUsers.length > 0 ? true : false,
                selectedForms: selectedForms, selectedFormIds: selectedFormIds, combinedForms: selectedForms, combinedFormIds : selectedFormIds,
                selectedResources : selectedResources, selectedResourceIds : selectedResourceIds, combinedResources : selectedResources,
                combinedResourceIds : selectedResourceIds, selectedProjects: selectedProjects , selectedTags: selectedTags,
                collectionRecord : collectionRecord,
                boardID :taskData.parent.boardid,
                itemID :taskData.parent.itemid,
                whatsappcontent : taskData.parent.whatsappcontent !==''? JSON.parse(taskData.parent.whatsappcontent): [],
                leadId : taskData.parent.leadId,
                whatsappChannelId :taskData.parent.whatsappChannelId,
                leadPhoneNumber : taskData.parent.leadPhoneNumber,
                schedule_task : taskData.parent.has_scheduled ? taskData.parent.has_scheduled : 'no',
                schedule_date : scheduleDate!== '' ? new Date(scheduleDate) : ''

            });
        }

    }

    getUsers(searchText) {
        let ignoreUsers = [].concat.apply([], [this.state.selectedToUsers,this.state.selectedCcUsers]);

        var user_data = { uid : "0", search : searchText? searchText : '',  offset : 100 , ignoreUsers : true, ignoreArrays :['selectedToUsers','selectedCcUsers'], valueField : 'uniqueId' };
        this.props.users(user_data);
    }
    getProjectUsers = (searchText) => {
        //let ignoreUsers = [].concat.apply([], [this.state.selectedToUsers,this.state.selectedCcUsers]);

        var user_data = { search : searchText? searchText : '',  projectid : this.props.projectId };
        this.props.projectusers(user_data);
    }

    getProjects(searchText) {
        let data={
            "type": "PROJECT",
            "count": 100,
            "uid": getProfile().id,
            "systype": getProfile().usertype,
            "category": "",
            "search": searchText? searchText : ''
        }
        this.props.projectData(data);
    }
    getTags(searchText){
        let data={
            "search": searchText? searchText : ''
        }
        this.props.tagData(data);
    }

    getForms(search) {
        let params = {group : 'All', search : search, uid : getProfile().id, usertype : getProfile().usertype, managecollection : null, limit : 0, offset : 1000, has_form : "yes","taskform":"yes"};
        this.props.collections(params);
    }

    getResources(search,restype) {
        let params = {filter_type : 'All', srchval : search, uid : getProfile().id, systype : getProfile().usertype, res_type : restype, limit : 0, offset : 1000};
        this.props.getResources(params);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let taskData = this.props.taskData;
        if (this.props.taskData !== prevProps.taskData) {
            this.populateData();
        }

        if(prevProps.HistoryPushData !== this.props.HistoryPushData ) {

            this.setState({selftask: false, saveDraftConfirm: false});
        }
        if(prevProps.taggedProject !== this.props.taggedProject) {
            this.tagProjectFromCollection();
        }

    }

    addCc = () => {
        this.setState({addcc: !this.state.addcc});
    }

    handleDateChange(date){
        this.setState({
            duedate : date

        },function(){
            ////////////console.log(date);
        })

        if(date !== '') {
            this.setState({
                date_error : false
            })
            document.getElementById('duedate').classList.remove('val-error');
        }
    }

    getUploadedFile(uploadedFile) {
        let uploadedFiles = [... this.state.uploadedFiles];
        uploadedFiles.push(uploadedFile);
        this.setState({
            filename: uploadedFile.fileName,
            uploadedFiles : uploadedFiles
        });
        this.hideAttachCtr();
//alert('attachment_'+uploadedFile.fileId)
        //document.getElementById('attachment_'+uploadedFile.fileId).focus({preventScroll:false})

    }
    removeSelectedAttachment(index) {
        let uploadedFiles = this.state.uploadedFiles;
        if(uploadedFiles) {
            uploadedFiles.splice(index, 1);
            this.setState({uploadedFiles: uploadedFiles});
        }
    }

    showFormlist =()=>{
        this.setState({showFormModal : true, showAttachCtr:false, tempForms :[], tempFormIds : []});
    }
    hideFormlist =() =>{
        this.setState({showFormModal : false, showAttachCtr:false});
    }

    showResourceList = () => {
        this.setState({showResourceModal : true, showAttachCtr:false, tempResources :[], tempResourceIds : []});
    }

    hideResourceList = () => {
        this.setState({showResourceModal : false, showAttachCtr:false,searchText:'',resType:0});
        this.getResources('',0);
    }
    handleChange(e) {
        var fieldValue = e.target.value;
        this.getForms(fieldValue);
    }
    handleResourceChange =(e, search,restype) => {
        // var fieldValue = e.target.value;
        this.setState({
            searchText:search,
            resType:restype
        })
        this.getResources(search,restype);
    }
    selectForm(form ){

        let tempForms = this.state.tempForms;
        let tempFormIds = this.state.tempFormIds;


        if(tempFormIds && Array.isArray(tempFormIds)) {
            let cid = form.cid.toString();
            if(tempFormIds.includes(cid)) {
                var index = tempFormIds.indexOf(cid)
                tempFormIds.splice(index, 1);
                tempForms.splice(index, 1);
            }
            else if(!tempFormIds.includes(cid)) {
                tempFormIds.push(cid);
                tempForms.push(form);
                ////console.log(tempForms)
            }
        }
        ////console.log(tempForms)
        let selectedFormIds = this.state.selectedFormIds;
        let selectedForms = this.state.selectedForms;

        /*//console.log(selectedForms)
        //console.log(tempForms)*/
        //[tempFormIds, tempForms, selectedFormIds, selectedResources] = this.makeArrayUnique(tempFormIds, tempForms, selectedFormIds, selectedResources);
        let result = this.makeArrayUnique(tempFormIds, tempForms, selectedFormIds, selectedForms);

        tempFormIds = result[0];
        tempForms = result[1];
        selectedFormIds = result[2];
        selectedForms = result[3];

        let combinedFormIds = selectedFormIds.concat(tempFormIds)
        let combinedForms = selectedForms.concat(tempForms);
        /*//console.log(combinedFormIds)
        //console.log(combinedForms)*/

        this.setState({ tempFormIds : tempFormIds, tempForms :tempForms, combinedFormIds : combinedFormIds, combinedForms : combinedForms })


        /*let selectedForms = this.state.selectedForms;
        let selectedFormIds = this.state.selectedFormIds;
        if(selectedFormIds && Array.isArray(selectedFormIds)) {
            let cid = form.cid.toString();
            if(selectedFormIds.includes(cid)) {
                var index = selectedFormIds.indexOf(cid)
                selectedFormIds.splice(index, 1);
                selectedForms.splice(index, 1);
            }
            else if(!selectedFormIds.includes(cid)) {
                selectedFormIds.push(cid);
                selectedForms.push(form);
            }
        }
        this.setState({selectedForms: selectedForms, selectedFormIds : selectedFormIds})*/

    }
    attachSelectedForms(){

        let combinedForms = this.state.combinedForms;
        let combinedFormIds = this.state.combinedFormIds;

        this.setState({ selectedForms : combinedForms, selectedFormIds : combinedFormIds, tempForms : [], tempFormIds : []})
        this.hideAttachCtr();
        this.hideFormlist();
        document.getElementById('action_assets').focus();
    }
    removeSelectedForm(index){
        let selectedForms = this.state.selectedForms;
        let selectedFormIds = this.state.selectedFormIds;

        if(selectedForms) {
            selectedForms.splice(index, 1);
            selectedFormIds.splice(index, 1);
            this.setState({selectedForms: selectedForms, selectedFormIds: selectedFormIds});
        }
    }


    selectResource = (resource ) => {
        //console.log(resource)

        let tempResources = this.state.tempResources;
        let tempResourceIds = this.state.tempResourceIds;

        if(tempResourceIds && Array.isArray(tempResourceIds)) {
            if(tempResourceIds.includes(resource.resource_id)) {
                var index = tempResourceIds.indexOf(resource.resource_id)
                tempResourceIds.splice(index, 1);
                tempResources.splice(index, 1);
            }
            else if(!tempResourceIds.includes(resource.resource_id)) {
                tempResourceIds.push(resource.resource_id);
                tempResources.push(resource);
                //console.log(tempResources)
            }
        }
        //console.log(tempResources)
        let selectedResourceIds = this.state.selectedResourceIds;
        let selectedResources = this.state.selectedResources;

        //console.log(selectedResources)
        //console.log(tempResources)
        //[tempResourceIds, tempResources, selectedResourceIds, selectedResources] = this.makeArrayUnique(tempResourceIds, tempResources, selectedResourceIds, selectedResources);
        let result = this.makeArrayUnique(tempResourceIds, tempResources, selectedResourceIds, selectedResources);

        tempResourceIds = result[0];
        tempResources = result[1];
        selectedResourceIds = result[2];
        selectedResources = result[3];

        let combinedResourceIds = selectedResourceIds.concat(tempResourceIds)
        let combinedResources = selectedResources.concat(tempResources);
        //console.log(combinedResourceIds)
        //console.log(combinedResources)

        this.setState({ tempResourceIds : tempResourceIds, tempResources :tempResources, combinedResourceIds : combinedResourceIds, combinedResources : combinedResources })
    }


    attachSelectedResources =() =>{

        let combinedResources = this.state.combinedResources;
        let combinedResourceIds = this.state.combinedResourceIds;

        this.setState({ selectedResources : combinedResources, selectedResourceIds : combinedResourceIds, tempResources : [], tempResourceIds : []})

        this.hideAttachCtr();
        this.hideResourceList();
        /*
               // document.getElementById('action_res_assets').focus();
        */
    }

    removeSelectedResource(index){
        let selectedResources = this.state.selectedResources;
        let selectedResourceIds = this.state.selectedResourceIds;

        if(selectedResources) {
            selectedResources.splice(index, 1);
            selectedResourceIds.splice(index, 1);
            this.setState({selectedResources: selectedResources, selectedResourceIds: selectedResourceIds});
        }
    }

    makeArrayUnique = (tempIds, tempObjs, selectedIds, selectedObjs) => {
        let elsToRemove = tempIds.filter(function(val) {
            return selectedIds.indexOf(val) > -1;
        });

        if(elsToRemove.length > 0) {
            for(var i=0; i<elsToRemove.length; i++){
                var tmpindex= tempIds.indexOf(elsToRemove[i]);
                tempIds.splice(tmpindex, 1);
                if(tempObjs.length > 0){
                    tempObjs.splice(tmpindex, 1);
                }

                var index= selectedIds.indexOf(elsToRemove[i]);
                selectedIds.splice(index, 1);
                if(selectedObjs.length > 0){
                    selectedObjs.splice(index, 1);
                }

            }
        }

        let result = [tempIds, tempObjs, selectedIds, selectedObjs];

        return result;

    }

    resultDataList( result, reference, valueField) {
        let uniqueIds = result.map(item => item[valueField]);
        this.props.setSelectedItems(reference, uniqueIds);
        this.setState({ [reference] : result , uniqueIds : uniqueIds });
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && this.state.title !== '') {
            var fieldValue = e.target.value;
            this.setState({[e.target.name] : fieldValue})
        }
    }

    onSubmit(event){
        event.preventDefault()
        event.stopPropagation();
        this.setState({submitted : true});
        var submitBtn = event.target.id;
        var selectedToUsers = [...this.state.selectedToUsers];
        let tasktype = 'task';

        let formInvalid = this.validateTaskSubmission(tasktype);
        if( formInvalid === 0) {
            if (selectedToUsers.length === 0) {
                this.showDraftConfirm();
            }
            else {

                this.saveTask(submitBtn,tasktype);
            }
        }

    }

    validateTaskSubmission = (tasktype) => {
        let formInvalid = 0;
        var selectedToUsers = [...this.state.selectedToUsers];
        var concat = "";
        var subject = document.getElementById('subject').value;
        this.setState({subject : subject})

        /*let duedate = this.state.duedate;
        if( this.state.createdfrom === 'course' && this.props.taskAction !== 'edit') {
            duedate = this.calculateDueDate();
        }*/

        if(this.props.taskAction === 'subtask' || tasktype === 'schedule') {
            if( selectedToUsers.length === 0 ) {
                formInvalid++;
                document.getElementsByName('searchInput_selectedToUsers')[0].classList.add('val-error');
            }

        }
        if( subject === '') {
            formInvalid++;
            document.getElementsByName('subject')[0].classList.add('val-error');
        }
        else{
            concat += subject;
        }

        //if( this.state.createdfrom !== 'course' && (this.state.duedate === ''||  this.state.duedate === null)) {
        if( this.state.duedate === ''||  this.state.duedate === null) {
            formInvalid++;
            this.setState({date_error : true})
        }
        else{
            this.setState({date_error : false})
            concat += this.state.duedate;
        }

        if(this.state.createdfrom === 'project'){
            if(this.state.startdate !== '' && this.state.startdate !== undefined) {
                var start_date = formatDate(this.state.startdate);
                var end_date = formatDate(this.state.duedate);
                if(end_date < start_date){
                    formInvalid++;
                    this.setState({startdate_error:true})

                }
            }
            else{
                formInvalid++;
                this.setState({startdate_error : true})
            }

        }


        if( this.state.priority !== '') {
            concat += " "+this.state.priority;
        }
        if( this.state.title !== '') {
            concat += " "+this.state.title;
        }

        if(this.state.schedule_task === 'yes') {
            if( this.state.schedule_date === ''||  this.state.schedule_date === null) {
                formInvalid++;
                this.setState({sc_date_error : true, sc_err_msg : 'Please select a schedule date', showScheduleStatus : true})
            }
            else{
                if(this.state.schedule_date >= this.state.duedate){
                    formInvalid++;
                    this.setState({sc_date_error : true, sc_err_msg : 'Schedule date should be less than due date', showScheduleStatus : true})
                }
                else {
                    this.setState({sc_date_error : false, sc_err_msg : ''})
                    concat += this.state.schedule_date;
                }
            }
        }


        this.setState({"concat" : concat, formInvalid : formInvalid});

        return formInvalid;
    }

    saveAsDraft = (event) => {
        var submitBtn = event.target.id;
        this.saveTask(submitBtn, 'draft')
    }

    saveTask = (submitBtn, tasktype) =>{
        console.log(this.state.enable_verify);
        this.setState({loading: 1});
        var duedate = (this.state.duedate && this.state.duedate !== '')? this.state.duedate : (tasktype === 'draft' ? '' : new Date());
        if(duedate !== '') {
            var utcStart = new moment(duedate, "YYYY-MM-DDThh:mm").utc();
            duedate=(utcStart.format('YYYY-MM-DD HH:mm:ss'));
        }

        var startdate = '';
        if(this.state.createdfrom === 'project'){
            var startdate = (this.state.startdate && this.state.startdate !== '') ? this.state.startdate : new Date();
            var utcStartdate = new moment(startdate, "YYYY-MM-DDThh:mm").utc();
            startdate=(utcStartdate.format('YYYY-MM-DD HH:mm:ss'));
        }

        var schedule_date = (this.state.schedule_date && this.state.schedule_date !== '')? this.state.schedule_date : '';
        if(schedule_date !== '') {
            var scheduleStart = new moment(schedule_date, "YYYY-MM-DDThh:mm").utc();
            schedule_date=(scheduleStart.format('YYYY-MM-DD HH:mm:ss'));
        }


        let concat = this.state.title+ " "+this.state.subject+" "+this.state.priority+" "+ this.state.duedate+" "+ this.state.startdate;
        var selectedToUsers = [...this.state.selectedToUsers];
        selectedToUsers = (selectedToUsers.length === 0) ? [{"uniqueId" : (getProfile().usertype === 'Superadmin' ? 'user' : getProfile().usertype.toLowerCase())+"_"+getProfile().id, "userId" : getProfile().id, "firstName" : getProfile().first_name, "lastName" : getProfile().last_name }] : selectedToUsers;

        var assignees = '';
        if (selectedToUsers.length > 0) {
            for (var i = 0; i < selectedToUsers.length; i++) {
                assignees = (assignees !== '') ? (assignees + ",") : "";
                assignees += selectedToUsers[i].uniqueId;
                concat += " "+selectedToUsers[i].firstName + " "+selectedToUsers[i].lastName;
            }
        }

        var inviteusers = '';
        var selectedCcUsers = this.state.selectedCcUsers;
        if (selectedCcUsers.length > 0) {
            for (var i = 0; i < selectedCcUsers.length; i++) {
                inviteusers = (inviteusers !== '') ? (inviteusers + ",") : "";
                inviteusers += selectedCcUsers[i].uniqueId;
                concat += " "+selectedCcUsers[i].firstName + " "+selectedCcUsers[i].lastName;
            }
        }
        //  let users =
        if(this.state.subTaskType === "manageable"){
            var assignedusers=this.props.taskData.assignedusers||[];
            let selectedAssignedusers='';
            if (assignedusers.length > 0) {
                for (var i = 0; i < assignedusers.length; i++) {
                    if(assignedusers[i].share === "no" && assignedusers[i].assignee !== getProfile().id){
                        selectedAssignedusers = (selectedAssignedusers !== '') ? (selectedAssignedusers + ",") : "";
                        selectedAssignedusers += assignedusers[i].uniqueId;
                        concat += " " + assignedusers[i].firstName + " " + assignedusers[i].lastName;
                    }
                }
            }
            inviteusers = (inviteusers !== "") ? (inviteusers + "," ):"";
            inviteusers+=(selectedAssignedusers)
        }
        var projects = '';
        if(this.props.projectId && this.props.projectId !== '') {
            projects = this.props.projectId;
        } else {
            var selectedProjects = this.state.selectedProjects;
            if (selectedProjects.length > 0) {
                for (var i = 0; i < selectedProjects.length; i++) {
                    projects = (projects !== '') ? (projects + ",") : "";
                    projects += selectedProjects[i].project_id;
                    concat += " " + selectedProjects[i].project_name;
                }
            }
        }

        var tagids = '';
        var tagnames = '';
        var selectedTags = this.state.selectedTags;
        if (selectedTags.length > 0) {
            for (var i = 0; i < selectedTags.length; i++) {
                if( selectedTags[i].tag_id && selectedTags[i].tag_id !== '' ) {
                    tagids = (tagids !== '') ? (tagids + ",") : "";
                    tagids += selectedTags[i].tag_id;
                }
                else {
                    tagnames = (tagnames !== '') ? (tagnames + ",") : "";
                    tagnames += selectedTags[i].tag_name;
                }
                concat += " "+selectedTags[i].tag_name;

            }
        }

        var collection = "";
        var selectedForms = this.state.selectedForms;
        if (selectedForms.length > 0) {
            for (var i = 0; i < selectedForms.length; i++) {
                collection = (collection !== '') ? (collection + "*") : "";
                collection += selectedForms[i].cid + "||";
            }
        }

        var timestamp = new Date().getTime();
        var uid = getProfile().id;
        var new_id =  uid + '_' + timestamp;
        var taskid = this.props.taskAction === 'edit' ? this.props.taskDetailId : new_id;
        var savemode = this.props.taskAction === 'subtask' ? "reassign" : (this.props.taskAction === 'edit' ? "update" :"create");
        if((this.props.taskAction === 'edit' && this.props.taskData && this.props.taskData.draft === 'yes') && tasktype === 'task') {
            savemode = 'create';
        }
        var grandmaster = this.props.taskAction === 'subtask' ? this.props.taskDetailId : "1";
        var secId = this.props.secId || 0;
        var hasBoard ='';
        var hasCollection ='';
        var itemID ='';
        var boardID ='';
        var createdfrom ='';
        var collectionRecord=[];
        var courseID=this.props.courseid||'';
        let whatsappcontent= this.props.selectedChats || this.state.whatsappcontent || "";
        hasCollection= this.state.selectedForms.length > 0 ? "yes" : "no";
        //console.log(this.props.leadId)
        let leadId = (this.props.taskAction === 'edit') ? this.state.leadId : (this.props.createdfrom === 'whatsapp' ? this.props.leadId : '');
        let whatsappChannelId = (this.props.taskAction === 'edit') ? this.state.whatsappChannelId : ((this.props.createdfrom === 'whatsapp' || this.props.createdfrom === 'externalchat' || this.props.createdfrom === 'singlemainchat') ? this.props.whatsappChannelId : '');
        let leadPhoneNumber = (this.props.taskAction === 'edit') ? this.state.leadPhoneNumber : (this.props.createdfrom === 'whatsapp' ? this.props.leadPhoneNumber : '');
        let lastChatId = '';
        if(whatsappcontent && whatsappcontent !== '' &&  Array.isArray(whatsappcontent) && whatsappcontent.length >0){
            console.log(whatsappcontent)
            lastChatId = whatsappcontent[whatsappcontent.length-1].chat_id;
        }

            //console.log(leadId)
        /* Board Task checking*/
        if(this.props.type === 'board') {
            hasBoard = "yes";
            itemID = this.props.itemID;
            boardID = this.props.boardID;
            createdfrom ='board'
        }
        if(this.state.createdfrom === 'board') {
            hasBoard = "yes";
            itemID = this.state.itemID;
            boardID = this.state.boardID;
            createdfrom ='board'
        }
        if(this.state.createdfrom === 'project'){
            createdfrom ='project'
        }

        if(this.props.hasOwnProperty("collectionRecord")){
            collectionRecord = this.props.collectionRecord ? this.props.collectionRecord : this.state.collectionRecord;
            collection = collectionRecord.cId + "||";
            createdfrom ='collection'
            hasCollection='yes'
        }

        if(this.state.createdfrom === 'collection'){
            collectionRecord=this.state.collectionRecord
            createdfrom ='collection'
            hasCollection='yes'
        }
        if(this.props.createdfrom && this.props.createdfrom !== ''){
            createdfrom = this.props.createdfrom;
        }

        let resources = this.state.selectedResourceIds;
        /*let formatted_resources = [];
        if(raw_resources.length > 0) {
            for( var j=0; j< raw_resources.length; j++) {
                let f_res = {
                    fileID : raw_resources[j].resource_id,
                    filetype : raw_resources[j].filetype,
                    filesize : raw_resources[j].filesize,
                    filetitle : raw_resources[j].title,
                    filename : raw_resources[j].res_img,
                    res_type : raw_resources[j].res_type,
                }
                formatted_resources.push(f_res);
            }
        }*/

        var data = {
            uid: uid,
            i: taskid,
            title: this.state.title,
            priority: this.state.priority,
            mode: "other",
            concat: concat,
            assignmentType: "oneto-many",
            savemode: savemode,
            assignees: assignees,
            inviteusrs: inviteusers,
            subject: this.state.subject,
            duedate: duedate,
            tagsid: tagids,
            tagsname: tagnames,
            projectid: projects,
            projectname: "",
            has_attachemnt: this.state.uploadedFiles.length > 0 ? "yes" : "no",
            has_collection: hasCollection,
            grandmaster: grandmaster,
            flood: "",
            tasktype: "task",
            collection: collection,
            resources : resources,
            todo_type: "",
            addedtype: "1",
            section_id: secId,
            selftask:this.state.selftask,
            has_board:hasBoard,
            itemid:itemID,
            boardid:boardID,
            createdfrom :createdfrom,
            collectionRecord:collectionRecord,
            startdate:startdate,
            firstname : selectedToUsers[0].firstName,
            courseid:courseID,
            has_scheduled  : this.state.schedule_task,
            scheduleddate : schedule_date,
            whatsappcontent:JSON.stringify(whatsappcontent),
            leadId : leadId,
            whatsappChannelId : whatsappChannelId,
            leadPhoneNumber : leadPhoneNumber,
            draft : (tasktype === 'draft') ? 'yes' : 'no',
            lastChatId : lastChatId
        };
        if(this.state.enable_verify && this.state.enable_verify === 'yes') {
            data.verifyeabled = this.state.enable_verify;
        }
        if(this.props.taskAction && this.props.taskAction === 'subtask') {
            data.subtaskcategory = this.state.subTaskType;
        }
        var params = {
            i: taskid,
            title: this.state.title,
            subject: this.state.subject,
            assignees : assignees,
            uid: uid,
            mode: "other",
            priority: this.state.priority,
            projectid: projects,
            projectname: "",
            duedate: duedate,
            savemode: savemode,
            grandmaster: grandmaster,
            inviteusrs: inviteusers
        };

        this.setState({selftask:false})
        submitBtn = submitBtn ? submitBtn : 'createtask';
        document.getElementById(submitBtn).disabled = true;
        //console.log(data); return false;
        this.props.addTodo(data, params, tasktype);
        var uploadedFiles = this.state.uploadedFiles;
        var uploadArray = [];
        if (uploadedFiles.length > 0) {
            for (var i = 0; i < uploadedFiles.length; i++) {
                var uploadedFile = uploadedFiles[i];
                var fileNameArr = uploadedFile.fileName.split('/');
                var fileName = fileNameArr.pop();

                var fileData = {
                    filetype: uploadedFile.fileType.startsWith('image') ? 'images' : (uploadedFile.fileType.startsWith('video') ? 'videos' : 'documents'),
                    filesize: uploadedFile.fileSize,
                    filetitle: uploadedFile.originalfileName,
                    filename: fileName
                }
                uploadArray.push(fileData);
            }
        }

        var fileData = {
            todoid: taskid,
            uid: uid,
            file :uploadArray
        }
        this.props.saveToDoAttachments(fileData);

    }

    calculateDueDate = () => {
        let courseDueDate = new Date();
        let prefix = this.props.duration_prefix;
        let duration = this.props.course_duration;
        if(prefix === 'Hour') {
            courseDueDate = moment().add(duration, 'hours');
            courseDueDate = moment.utc(courseDueDate).local().format('YYYY-MM-DD HH:mm:ss')
        }
        else if(prefix === 'minutes') {
            courseDueDate = moment().add(duration, 'minutes');
            courseDueDate = moment.utc(courseDueDate).local().format('YYYY-MM-DD HH:mm:ss')
        }
        else if(prefix === 'Days') {
            courseDueDate = moment().add((duration-1), 'days');
            courseDueDate = moment.utc(courseDueDate).local().format('YYYY-MM-DD HH:mm:ss')
        }
        let duedate = new Date(courseDueDate);
        // let duedate = this.props.courseDueDate;
        // console.log(new Date(duedate))
        this.setState({createdfrom : 'course', duedate : duedate});
        return duedate;
    }

    showDraftConfirm = () =>{
        this.setState({selftask:true,saveDraftConfirm : true});
    }

    hideDraftConfirm = () =>{
        this.setState({selftask:false,saveDraftConfirm : false});
    }
    showProjectAddModal() {
        this.setState({showProjectModal : true})
    }
    hideProjectAddModal() {
        this.setState({showProjectModal : false})
    }
    addProjectToTask(e) {
        this.setState({showProjectModal : false, projectAdded : true});
        // this.hideAttachCtr();
    }
    removeSelectedProject(e, index) {
        let selectedProjects = this.state.selectedProjects;

        if(selectedProjects) {
            selectedProjects.splice(index, 1);
            this.setState({selectedProjects: selectedProjects});
        }
    }

    toggleAttachCtr() {
        this.setState({
            showAttachCtr : !this.state.showAttachCtr
        })
    }
    hideAttachCtr() {
        this.setState({
            showAttachCtr : !this.state.showAttachCtr
        })
    }
    handletaskType(type){
        this.setState({subTaskType:type})
    }

    showScheduleModal = (e) => {
        let formInvalid = this.validateTaskSubmission(e, 'schedule');
        if(formInvalid === 0) {
            this.setState({
                showScheduleStatus : true
            })
        }

    }
    hideScheduleModal = () => {
        this.setState({
            showScheduleStatus : false,
            //schedule_task : 'no'
        })
    }

    setScheduleDate = (date,e) => {
        this.setState({
            schedule_date : date,
            schedule_task : 'yes'
        },function() {
            this.scheduleSend(e)
        })
    }

    scheduleSend =(e) => {

        let scheduleInvalid = 0;
        let concat = this.state.concat;

        //if(NEW_VERSION) {
            if( this.state.schedule_date === ''||  this.state.schedule_date === null) {
                scheduleInvalid++;
                this.setState({sc_date_error : true, sc_err_msg : 'Please select a schedule date'})
            }
            else{
                if(this.state.schedule_date >= this.state.duedate){
                    scheduleInvalid++;
                    this.setState({sc_date_error : true, sc_err_msg : 'Schedule date should be less than due date'})
                }
                else {
                    this.setState({sc_date_error : false, sc_err_msg : ''})
                    concat += this.state.schedule_date;
                }
            }
        //}
        if(scheduleInvalid === 0) {
            this.saveTask('createtask', 'schedule')
        }

    }

    render() {

        let Collections = this.props.listAllCollectionsData.collectionsubgroup;
        let resources = this.props.resources.ListAllResources;
        let projectlist=this.props.projectList;
        let projectId = this.props.projectId || 0;

        var todaytime = moment().endOf('day')

        let currentTime = new Date()
        let min = currentTime;
        if(this.props.taskAction === 'edit') {
            if(this.state.duedate !== null && this.state.duedate < currentTime) {
                min = this.state.duedate;
            }
        }
        let sc_min = currentTime;
        if(this.props.taskAction === 'edit' ) {
            if(this.state.schedule_date !== null && this.state.schedule_date < currentTime) {
                sc_min = this.state.schedule_date;
            }
        }
        let projectwiselist = false;
        let createdfrom = this.state.createdfrom;
        if(createdfrom === 'project' && getProfile().projectusers && getProfile().projectusers === 'projectusers'){
            projectwiselist =  true;
        }

        let schedule_date_options = getSchedulerDates();

        let ignoredUsers = [].concat.apply([],this.props.selectedItems.selectedCcUsers, this.props.selectedItems.selectedToUsers);
        if(createdfrom === 'course') {
            ignoredUsers = [].concat.apply([],this.props.assignedCourseUsers);
        }

        console.log(ignoredUsers)


        return (
            <>
                <div className="task-content-block dynamic-creation">


                    <div className="persitant-top">
                        {this.state.loading === 1 &&
                        <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                        }
                        <div className="task-inp-container input-auto-complete">
                            <AutoComplete
                                fieldTitle = 'TO'
                                placeholder= "Type here"
                                sourceDataList = {projectwiselist ? this.props.projectTodoUsers.userList:this.props.listUsers.todoUsers}
                                searchField = 'firstName'
                                showIcon={true}
                                showSubLabel = {true}
                                labelFields = 'firstName,lastName'
                                valueField ='uniqueId'
                                subLabelField = 'designation'
                                showProfile = {true}
                                reference='selectedToUsers'
                                selectedItems = {this.state.selectedToUsers}
                                ignoredValues = {createdfrom === 'course' ? ignoredUsers : this.props.selectedItems.selectedCcUsers}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="to_users"
                                instantSearch = {true}
                                actionMethod = {projectwiselist ? this.getProjectUsers :this.getUsers}
                                preventInitialApiCall = {true}
                            />

                            {this.state.addcc &&
                            <AutoComplete
                                fieldTitle = 'CC'
                                placeholder= "Type here"
                                sourceDataList = {projectwiselist ? this.props.projectTodoUsers.userList:this.props.listUsers.todoUsers}
                                searchField = 'firstName'
                                showIcon={true}
                                showSubLabel = {true}
                                labelFields = 'firstName,lastName'
                                valueField ='uniqueId'
                                subLabelField = 'designation'
                                showProfile = {true}
                                reference='selectedCcUsers'
                                selectedItems = {this.state.selectedCcUsers}
                                ignoredValues = {this.props.selectedItems.selectedToUsers}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="cc_users"
                                instantSearch = {true}
                                actionMethod = {projectwiselist ? this.getProjectUsers :this.getUsers}
                                preventInitialApiCall = {true}
                            />
                            }
                            {this.state.createdfrom !== 'course' &&
                            <div className="create-button-block">
                                <button className="trans-cr-button cc"
                                        onClick={(e) => this.addCc()}>Cc {this.state.addcc}
                                </button>
                            </div>
                            }
                        </div>

                        <TextField placeholder="Subject" id="subject" name="subject" value={this.state.subject} setFieldValue={this.setFieldValue} submitted={this.state.submitted}/>

                        <div className="input-block expandable-textarea">
                            <TextEditor
                                placeholder="Description"
                                className="task-text-area-expandable"
                                name="title"
                                id="title"
                                value={this.state.title}
                                handleChange={this.handleInputChange}
                                bounds={'.expandable-textarea'}
                            />
                        </div>
                        <div>
                            {this.renderChat()}
                        </div>
                        {/*<div>
                            {this.state.showCalendar &&
                                <a style={{display:'contents'}} href={`http://localhost:3005/calendar?id=${getProfile().id}&attendee=${this.props.leadId}&event=${this.props.eventId}&ppid=${this.props.programId}`} rel="noopener noreferrer" target="_blank">Click here to schedule</a>
                            }
                        </div>*/}
                        {this.props.taskAction === 'subtask' &&
                        <div className="task-input-flex-block">
                            <div className="ind-panel-options switch-mode radio-wrapper">
                                <div className="radio-block">
                                    <input type="radio" name="subTaskType" id="individual"  defaultValue="individual"
                                           checked={this.state.subTaskType === 'individual' ? true : false} onClick={()=>this.handletaskType("individual")}/><label>Individual</label>
                                </div>
                                <div className="radio-block">
                                    <input type="radio" name="subTaskType" id="manageable"  defaultValue="manageable"
                                           checked={this.state.subTaskType === 'manageable' ? true : false} onClick={()=>this.handletaskType("manageable")}/><label>Manageable</label>
                                </div>
                            </div>
                        </div>

                        }
                        {getProfile().enableVerify === 'No' &&
                        <div className="select-chk-block task-select" style={{display: "block"}}>
                            <label className="control control-checkbox" data-action="organise"> Enable verify button
                                <input type="checkbox" className="inp-chk"
                                       name="enable_verify" id="enable_verify" value="yes" onChange={(e) => this.handleCheckChange(e)}/>
                                <div className="control-indicator"></div>
                            </label>
                        </div>
                        }

                        <div className="attachment-task-group" id="todoFileAssets">
                            { this.state.uploadedFiles.map((item, index) =>
                                <div className="ind-attach-list file" id={"attachment_"+item.fileId}>
                                    <h5>{item.originalfileName}</h5>
                                    <button className="ico-lib cl-fl" data-parent={item.fileId}
                                            data-target="#delete-attachfile" data-action="unselectattachfiles" onClick={(e)=>this.removeSelectedAttachment(index)}></button>
                                </div>
                            )}

                            <div className="collection-list-area cf task-input-block" id="action_assets">
                                {this.state.selectedForms.map((form,index)=>
                                    <div id="todoCollectionAssets">
                                        <div className="ind-attach-list form" data-active="collection">
                                            <h5 data-description="form description" title="test form">{atob(form.title)}</h5>
                                            <div className="form-right-portion">
                                                <button className="ico-lib cl-fl" data-parent={form.cid} onClick={(e)=>this.removeSelectedForm(index)}></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                            <div className="collection-list-area cf task-input-block" id="action_res_assets">
                                {this.state.selectedResources.map((resource,index)=>
                                    <div id="todoResourceAssets" >
                                        <div className="ind-attach-list form" data-active="resource">
                                            <h5 data-description="form description" title="test form">{resource.title}</h5>
                                            <div className="form-right-portion">
                                                <button className="ico-lib cl-fl" data-parent={resource.resource_id} onClick={(e)=>this.removeSelectedResource(index)}></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                            <div className="collection-list-area cf task-input-block" id="action_projects">
                                {this.state.projectAdded &&  this.state.selectedProjects.map((project,index)=>
                                    <div id="todoProjectAssets">
                                        <div className="ind-attach-list project" data-active="collection">
                                            <h5 data-description="form description" title="project">{project.project_name}</h5>
                                            <div className="form-right-portion">
                                                <button className="ico-lib cl-fl" data-parent={project.project_id} onClick={(e)=>this.removeSelectedProject(index)}></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                            </div>


                            {this.props.hasOwnProperty("collectionRecord")&& this.props.collectionRecord.length !== 0 && this.props.collectionRecord.recordId.length !==0&&
                            <div className="ind-attach-list file" >
                                <h5>{this.props.collectionRecord.cName}({this.props.collectionRecord.recordId.length})</h5>
                                <button className="ico-lib cl-fl"
                                        data-target="#delete-attachfile" data-action="unselectattachfiles" onClick={(e)=>this.props.removeSelectedRecord(this.props.collectionRecord.recordId[0])}

                                >
                                </button>
                            </div>
                            }


                        </div>

                        <div className="attach-button-container">
                            <button className="options-button attach black-shade" type="button" onClick={this.toggleAttachCtr}></button>
                            {projectId === 0 && this.state.createdfrom !== 'prjcol' &&
                            <button className="options-button project black-shade" type="button"
                                    onClick={this.showProjectAddModal}></button>
                            }
                            {this.state.showAttachCtr &&
                            <OutsideAlerter
                                status={this.state.showAttachCtr}
                                Triggered={() => this.toggleAttachCtr()}
                            >
                                <ul className="attach-toggler">
                                    <li>
                                        <nav className="toggle-icon ico-lib toggle-file-ico">Attach File
                                            <UploadFile getUploadedFile={this.getUploadedFile.bind(this)}/>
                                        </nav>
                                    </li>
                                    <li>
                                        <nav className="toggle-icon ico-lib toggle-form-ico" data-toggle="modal"
                                             data-target="#collectionModal" onClick={(e) => {
                                            this.showFormlist(e)
                                        }}>Attach Form
                                        </nav>
                                    </li>
                                    <li>
                                        <nav className="toggle-icon ico-lib toggle-resource-ico" data-toggle="modal"
                                             data-target="#resourceModal" onClick={(e) => {
                                            this.showResourceList(e)
                                        }}>Attach Resources
                                        </nav>
                                    </li>

                                </ul>
                            </OutsideAlerter>
                            }
                        </div>

                    </div>



                    {/* <div className="schedule-wrapper">
                        {NEW_VERSION && (this.state.schedule_task === 'yes' || ( this.props.taskAction !== 'edit' && this.props.taskAction !== 'subtask' && this.props.taskAction !== 'reassign' )) &&
                        <div className="schedule-panel">
                            <div className="select-chk-block task-select" style={{display: "block"}}>
                                <label className="control control-checkbox" data-action="organise"> Schedule Task
                                    <input type="checkbox" className="inp-chk"
                                        name="schedule_task" id="schedule_task" value="yes"
                                        onChange={(e) => this.handleCheckChange(e)}
                                            checked={this.state.schedule_task === 'yes'}
                                    />
                                    <div className="control-indicator"></div>
                                </label>
                            </div>
                            {this.state.schedule_task === 'yes' &&

                                <DateTimeField
                                    name='schedule_date' placeholder="Schedule Date" id="schedule_date"
                                    value={this.state.schedule_date !== '' ? this.state.schedule_date : null}
                                    taskAction={this.props.taskAction}
                                    setFieldValue={this.setFieldValue}
                                    submitted={this.state.submitted}
                                    min={sc_min}
                                    date_error={this.state.sc_date_error}
                                />

                            }
                        </div>
                        }
                    </div> */}

                    <div className="persitant-footer align-small">
                        <div className="group-row major-area">
                            <div className="group-container align-equal">
                                {this.state.createdfrom !== 'course' &&
                                    <div className={`${this.state.createdfrom === 'project' ? "date-container-block double-block" : "date-container-block single-block"}`}>
                                    {this.state.createdfrom === 'project' &&

                                    <DateTimeField
                                        name='startdate' placeholder="Start Date" id="startdate"
                                        value={this.state.startdate !== '' ? this.state.startdate : null}
                                        taskAction={this.props.taskAction}
                                        setFieldValue={this.setFieldValue}
                                        submitted={this.state.submitted}
                                        min={min}
                                        date_error={this.state.startdate_error}
                                    />
                                    }
                                    <DateTimeField
                                        name='duedate' placeholder="Due Date" id="duedate"
                                        value={this.state.duedate !== '' ? this.state.duedate : null}
                                        taskAction={this.props.taskAction}
                                        setFieldValue={this.setFieldValue}
                                        submitted={this.state.submitted}
                                        min={min}
                                        date_error={this.state.date_error}
                                        date_source="assign-course"
                                    />
                                </div>
                                }

                                <div className="label-flex-container eq-wid">

                                    {/*<SelectField placeholder="Priority" id="priority" name="priority" value={this.state.priority} setFieldValue={this.setFieldValue} submitted={this.state.submitted}*/}
                                    {/*options={this.state.priorityOptions}/> */}

                                    <DropDownBox
                                        name={`priority`}
                                        id="priority"
                                        label={`Priority`}
                                        value={this.state.priority}
                                        options={this.state.priorityOptions}
                                        setFieldValue={this.setFieldValue}
                                        submitted={this.state.submitted}

                                    />
                                </div>
                            </div>
                        </div>
                        {/*{NEW_VERSION &&*/}
                        {/*<button type="button" className="general-btn button-lg" id="saveasdraft"*/}
                        {/*        onClick={(e) => this.saveAsDraft(e)}>Save As Draft</button>*/}
                        {/*}*/}

                        {(this.state.schedule_task === 'yes' || ( this.props.taskAction !== 'edit' && this.props.taskAction !== 'reassign' )) &&

                        <Dropdown as={ButtonGroup} className="submit-group">
                            <Button id="createtask" className="general-btn" onClick={(e) => this.onSubmit(e)}
                                    disabled={this.props.listUsers.loading ? 'disabled' : ''}>Submit</Button>
                            <Dropdown.Toggle split id="schedule" className="split-bsy"/>
                            <Dropdown.Menu className="custom-drop-menu">
                                <Dropdown.Item eventKey="1"
                                               className="panel-option-click ico-lib-v1 form-update-all-ico"
                                               onClick={(e) => this.showScheduleModal(e)}>Schedule Task</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        }

                        {(( this.props.taskAction === 'edit' ||  this.props.taskAction === 'reassign') && this.props.taskData.parent.has_scheduled === 'no' ) &&

                            <button type="button" className="general-btn button-lg" id="createtask" onClick={(e)=> this.onSubmit(e)} disabled={this.props.listUsers.loading? 'disabled' :''}>Submit</button>
                        }
                    </div>
                </div>


                {this.state.showProjectModal === true &&

                <div className="modal bsy-modal x-large show" id="collectionModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: "block"}}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib ">Add Projects</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={(e) => {this.hideProjectAddModal(e)}}></button>
                            </div>
                            <div className="modal-body  small-height modal-body-heighted">
                                <div className="task-inp-container">
                                    <AutoComplete
                                        eleClass='selectize-custom'
                                        fieldTitle=''
                                        placeholder = {'Projects'}
                                        hideTitle={true}
                                        sourceDataList={this.props.projects.projectData}
                                        searchField='project_name'
                                        showIcon={false}
                                        hideMainLabel={true}
                                        showSubLabel={true}
                                        labelFields='project_name'
                                        valueField='project_id'
                                        subLabelField='project_name'
                                        showProfile={false}
                                        reference='selectedProjects'
                                        selectedItems={this.state.selectedProjects}
                                        resultDataList={this.resultDataList.bind(this)}
                                        id="projects"
                                        instantSearch = {true}
                                        actionMethod = {this.getProjects}
                                        multiSelect = {false}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer  checklist-footer">
                                {/*{this.state.selectedForms.length > 0 && <span id="collectionCount">{this.state.selectedForms.length} items selected</span>}*/}
                                <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {this.hideProjectAddModal(e)}}>Cancel
                                </button>
                                <button type="button" className="general-btn" id="addProject" onClick={(e)=>this.addProjectToTask(e)}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>

                }
                {this.state.showFormModal === true &&
                <div className="modal bsy-modal x-large show" id="collectionModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: "block"}}>
                    <div className="modal-dialog modal-dialog-centered modal-lg modal-lg--x" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title ico-lib ">Attach Forms</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={(e) => {this.hideFormlist(e)}}></button>
                            </div>
                            <div className="modal-body  modal-in-colored modal-body-heighted">
                                <div className="mang-lbl-block mang-lbl-block-mod-in">
                                    <div className="lbl-panel-search-block">
                                        <input data-target="#dropCollection" data-action="search" type="text"
                                               placeholder="Search forms..." className="lbl-search-box" onChange={(e)=> this.handleChange(e)} />
                                        <span className="ico-lib lbl-search-ico"></span>
                                    </div>
                                    <ul className="grid-list-switch res-modal">
                                        <li><a className="icon-list" data-switch="list-grid" data-target="1"></a>
                                        </li>
                                        <li><a className="icon-grid active" data-switch="list-grid"
                                               data-target="2"></a>
                                        </li>
                                    </ul>
                                    <div className="content-main-in" id="dropCollection">
                                        {Collections.map((item,index)=>
                                            <FormTodoTile item={item} index={index} selectForm = {this.selectForm} selected combinedFormIds={this.state.combinedFormIds}  />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer  checklist-footer">
                                {this.state.combinedForms.length > 0 && <span id="collectionCount">{this.state.combinedForms.length} items selected</span>}
                                <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {this.hideFormlist(e)}}>Cancel
                                </button>
                                <button type="button" className="general-btn" id="fetchCollection" onClick={(e)=>this.attachSelectedForms(e)}>Attach</button>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.showResourceModal === true &&
                <React.Fragment>
                    <div className="modal bsy-modal x-large show" id="resourceModal" tabIndex="-1" role="dialog"
                         aria-labelledby="mngModal" style={{display: "block"}}>
                        <div className="modal-dialog modal-dialog-centered modal-lg modal-lg--x" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title ico-lib ">Attach Resources</h5>
                                    <button className="ico-lib-list modal-close" data-dismiss="modal"
                                            aria-label="Close" onClick={(e) => {this.hideResourceList(e)}}></button>
                                </div>
                                <div className="modal-body  modal-in-colored modal-body-heighted padding-zero">
                                    <div className="mang-lbl-block mang-lbl-block-mod-in">
                                        <div class="resource-filter-modal-header">
                                            <div className="lbl-panel-search-block">
                                                <input data-target="#dropResources" data-action="search" type="text"
                                                       placeholder="Search Resources..." className="lbl-search-box" onChange={(e)=> this.handleResourceChange(e,e.target.value,this.state.resType)} />
                                                <span className="ico-lib lbl-search-ico"></span>
                                            </div>
                                            <div className="select-box">
                                                <select
                                                    onChange={(e) => this.handleResourceChange(e, this.state.searchText, e.target.value)}>
                                                    <option value={'0'}>Select</option>
                                                    <option value={'1'}>Images</option>
                                                    <option value={'3'}>Documents</option>
                                                    <option value={'2'}>Video</option>
                                                    <option value={'4'}>Audio</option>
                                                    <option value={'5'}>Streaming Video</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="resource-filter-modal-body">
                                            <ul className="grid-list-switch res-modal">
                                                <li><a className="icon-list" data-switch="list-grid" data-target="1"/>
                                                </li>
                                                <li><a className="icon-grid active" data-switch="list-grid" data-target="2"/>
                                                </li>
                                            </ul>
                                            <div className="content-main-in" id="dropResources">
                                                {resources.map((item,index)=>
                                                    <ResourceTodoTile key={`resource_${index}`} item={item} index={index} selectResource = {this.selectResource} combinedResourceIds={this.state.combinedResourceIds}  />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer  checklist-footer">
                                    {this.state.combinedResources.length > 0 && <span id="resourceCount">{this.state.combinedResources.length} items selected</span>}
                                    <button type="button" className="general-btn grey" data-dismiss="modal" onClick={(e) => {this.hideResourceList(e)}}>Cancel
                                    </button>
                                    <button type="button" className="general-btn" id="fetchResources" onClick={(e)=>this.attachSelectedResources(e)}>Attach</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>

                }

                {this.state.saveDraftConfirm === true &&
                <AlertModal id="save-draft-todo"
                            title="No user assigned, this task is saved as self."
                            showModal = {this.state.saveDraftConfirm}
                            OK_action={this.saveTask}
                            CANCEL_action={this.hideDraftConfirm}

                />
                }

                {this.state.showScheduleStatus &&
                <SimpleModal
                    Close={()=>this.hideScheduleModal()}
                    title={`Schedule Task`}
                    haveFooter={1}
                    cancelAction={()=>this.hideScheduleModal()}
                    OKAction={(e)=>this.scheduleSend(e)}
                    OKLabel={`Schedule Send`}
                    modalType={'custom-date-range'}
                    extraClass={'schedule-modal'}
                >
                    <div className="reschedule-wrapper">
                        <ul className="default-times">
                            <li onClick={(e)=>this.setScheduleDate(schedule_date_options[0],e)}>
                                <nav>{schedule_date_options[2]} morning <span> {moment(schedule_date_options[0]).format('MMMM D YYYY, hh:mm A')}</span></nav>
                            </li>
                            <li onClick={(e)=>this.setScheduleDate(schedule_date_options[1],e)}>
                                <nav>{schedule_date_options[3]} afternoon <span> {moment(schedule_date_options[1]).format('MMMM D YYYY, hh:mm A')}</span></nav>
                            </li>
                        </ul>

                        <DateTimeField
                            name='schedule_date' placeholder="Schedule Date" id="schedule_date"
                            value={this.state.schedule_date !== '' ? this.state.schedule_date : null}
                            taskAction={this.props.taskAction}
                            setFieldValue={this.setFieldValue}
                            submitted={this.state.submitted}
                            min={sc_min}
                            date_error={this.state.sc_date_error}
                        />
                        <div style={{'font-size': 'smaller','color': 'red', 'padding': '10px 0 0 0','display': 'inline-flex'}}>{this.state.sc_err_msg}</div>


                    </div>


                </SimpleModal>
                }

            </>


        );
    }
}


const mapStateToProps = state => ({
    listAllCollectionsData: state.CollectionsReducer.listAllCollections,
    listUsers:state.UsersReducer.listUsersdata,
    projectTodoUsers : state.TaskReducer.getProjectTodoUsers,
    todoAction : state.TaskReducer.saveTodo,
    listtags:state.TaskReducer.ListAllTags,
    projects:state.TaskReducer.GetProjectData,
    selectedItems : state.TaskReducer.getSelectedItems,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    resources : state.resourcesReducer.listallResources
});
export default  withRouter(connect(mapStateToProps, {
    getTaskHelper : taskActions.getTaskHelper,
    collections: collectionActions.listTodoCollections,
    getResources : resourceActions.listResources,
    addTodo : taskActions.addTodo,
    saveToDoAttachments : taskActions.saveToDoAttachments,
    projectData:taskActions.getProjectData,
    users:usersActions.listTodoUsers,
    projectusers:taskActions.listProjectUsers,
    tagData : taskActions.getTagData,
    setSelectedItems : taskActions.setSelectedItems,
    resetSelectedItems : taskActions.resetSelectedItems,

})(AddToDoTask));
