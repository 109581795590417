import React from 'react';
import {ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Area, Legend, ResponsiveContainer} from 'recharts';

const CustomTooltip = ({active, payload, label, unit}) => {
    if (active) {
        //////console.log(payload)
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                {payload && payload.map(item =>
                    <p className="intro"
                       style={{color: '#000000'}}>{`${item.name} : ${new Intl.NumberFormat('en-IN', {maximumFractionDigits: 2}).format(item.value)} ${unit ? unit : ''}`}</p>
                )}

            </div>
        );
    }

    return null;
};

function MultiAreaChart(props) {

    let xwidth = props.containerData.xwidth && parseInt(props.containerData.xwidth);
    let xheight = props.containerData.xheight && parseInt(props.containerData.xheight);
    let yheight = props.containerData.yheight && parseInt(props.containerData.yheight);
    let ywidth = props.containerData.ywidth && parseInt(props.containerData.ywidth);
    let graphHeight = props.containerData.graphHeight ? parseInt(props.containerData.graphHeight) : 200;
    let xAngle = props.containerData.xAngle ? parseInt(props.containerData.xAngle) : 0;
    let yAngle = props.containerData.yAngle ? parseInt(props.containerData.yAngle) : 0;
    let hidearea = props.containerData.hidearea ? props.containerData.hidearea : false;
    let enablegradient = props.containerData.enablegradient ? props.containerData.enablegradient : false;
    let borderColor = props.containerData.bordercolor && props.containerData.bordercolor;


    //console.log(props.containerData)
    return (
        <React.Fragment>
            <ResponsiveContainer width={props.containerData.legend ? "95%" : "100%"} height={graphHeight}>
                <ComposedChart
                    data={props.containerData.data}>
                    <CartesianGrid vertical={false} stroke="#f0f0f0"/>
                    {props.containerData.tabledata.slice(0, 1).map((list, index) =>
                        <XAxis dataKey={list.label} width={xwidth} height={xheight} angle={-xAngle}
                               padding={{left: 0, right: 0}} axisLine={false} tickLine={false} fontSize={12}
                               interval={props.containerData.alldata ? 0 : 'preserveStartEnd'} textAnchor={'end'}/>
                    )}

                    <YAxis hide={props.containerData.enableyaxis ? false : true} width={ywidth} height={yheight}
                           angle={-yAngle} tickLine={false} axisLine={false}/>
                    <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip unit={props.containerData.unit}/>}/>
                    {enablegradient &&
                    <defs>
                        {props.containerData.tabledata.map((list, index) =>
                            <linearGradient id={`colorUv${index}`} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={list.color} stopOpacity={0.5}/>
                                <stop offset="95%" stopColor={list.color} stopOpacity={0}/>
                            </linearGradient>
                        )}

                    </defs>
                    }
                    {!hidearea && props.containerData.tabledata.slice(1).map((list, index) =>

                        <Area name={list.label} type="monotone" dataKey={list.label}
                              fill={enablegradient ? `url(#colorUv${index})` : list.color} stroke={borderColor}
                              fillOpacity={0.2} dot={false}/>
                    )}
                    {hidearea && props.containerData.tabledata.slice(1).map((list, index) =>
                        <Line name={list.label} type="monotone" dataKey={list.label} stroke={list.color} dot={false}>
                        </Line>
                    )}
                    {props.containerData.legend &&
                    <Legend height={36} iconType={'circle'} layout='vertical' verticalAlign={`middle`} align={`right`}/>
                    }
                </ComposedChart>
            </ResponsiveContainer>

        </React.Fragment>
    );
}

export default MultiAreaChart;