import {combineReducers} from 'redux';
const listallRoles = (state = {roles:{data:[]}}, action) => {
    var _newState;
    switch (action.type) {
        case "LIST_All_ROLE":
            return {
                ...state,
                roles: {
                    data : action.listallroles.data.results.data
                },
            };
            break;
        case "UPDATE_ROLE":
            let roles = state.roles.data;
            if(action.newRole.mode !== 'update'){
                roles.unshift(action.newRole);
            }else{
                roles = roles.map((item) => { return ( parseInt(item.roleId) === parseInt(action.newRole.roleId)) ? action.newRole: item; });

            }

            return {
                ...state,
                roles: {
                    data : roles

                },
            };
            break;

        case "ROLELIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        case "SEARCH_ROLE_DATA":
            return {
                ...state,
                searchResult: {
                    data : action.listallroles.data.results.data
                },
                searchValue: action.value
            };
            break;

        case "LOAD_MORE_ROLES":
            _newState = {...state};
            _newState['roles'].data = _newState.roles.data.concat(action.listmoreroles.data.results.data);
            return _newState;
            break;

        default:
            return state;
    }
};

const getRoleDetails = (state = {roleDet:[]}, action) => {
    switch (action.type) {
        case "GET_ROLEDET":
            return {
                ...state,
                roleDet: action.roleDet.data.results.data,
            };
            break;
        case "GET_ROLEDET_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};
const getProjectRole = (state = {projectRoles:[]}, action) => {
    switch (action.type) {
        case "GET_PROJECT_ROLES":
            return {
                ...state,
                projectRoles: action.projectRoles.data.results.data,
            };
            break;
        case "PROJECT_ROLES_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};
const getRolestobeAssignedlist= (state = {rolestobeAssigned:[]}, action) => {
    switch (action.type) {
        case "ROLES_TO_BE_ASSIGNED":
            return {
                ...state,
                rolestobeAssigned: action.rolestobeassignedlist.data.results.data,
            };
            break;
        case "ROLES_TO_BE_ASSIGNED_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};
const assignRoles = (state = {roleassigned:false}, action) => {
    switch (action.type) {
        case "ASSIGN_ROLES_TO_PROJECT":
            return {
                ...state,
                roleassigned: !state.roleassigned,
            };
            break;
        case "ASSIGN_ROLES_TO_PROJECT_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};
const usersRolelist = (state = {usersrolelistdetails:[]}, action) => {
    switch (action.type) {
        case "USERS_ROLE_LIST":
            return {
                ...state,
                usersrolelistdetails: action.usersrolelistdetails.data.results.data,
            };
            break;
        case "USERS_ROLE_LIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};
const assignUserRolesDet = (state = {userroleupdated:false}, action) => {
    switch (action.type) {
        case "USERS_ROLE_UPDATED_LIST":
            return {
                ...state,
                userroleupdated: !state.userroleupdated,
            };
            break;
        case "USERS_ROLE_UPDATED_LIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};
const DeleteRole = (state = {roleDeleted : false }, action) => {
    switch (action.type) {
        case "DELETE_ROLE":
            return {
                ...state,
                roleDeleted: !state.roleDeleted
            };
            break;
        default:
            return state;
    }
};
export const Roles = combineReducers({
    listallRoles,
    getRoleDetails,
    getProjectRole,
    assignRoles,
    usersRolelist,
    assignUserRolesDet,
    getRolestobeAssignedlist,
    DeleteRole
});
export default Roles;