import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../../actions/ToDo"
import {withRouter} from 'react-router';
import ListCard from "../../views/ListCard"
import {getProfile} from "../../../utilities/AuthService";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import FilterBlock from "../FilterBlock";
import FilerRightBlock from "../FilterRightBlock";
import * as projectActions from "../../../actions/Project";
import Modal from "../../views/Modal";
import AddToDoTask from "../AddToDoTask";
import rootActions from "../../../actions";
import AlertBar from "./AlertBar";



class ToOverSee extends Component {

    constructor(props) {
        super(props);
        this.gettaskList=this.gettaskList.bind(this);
        this.modalOpen=this.modalOpen.bind(this);
        this.state={
            open:false,
            projectList:[],
            limit : 0,
            loadingState: false,
            taskList :[],
            loading:0,
            lastOffset: 0,
            totalitems: 0,
            overseedata: [],
            offset: 250
        }
      }



    componentDidMount() {
        this.props.mainClick('task','/task/to-oversee')
        if(this.props.todoListing.ToOversee.data.length ===0)
            this.gettaskList('All');
        else
            this.setState({overseedata: this.props.todoListing.ToOversee.data});
        this.props.userFilter("manage-task",getProfile().id)
        const container = document.querySelector('.main-panel-holder');
        container.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        const container = document.querySelector('.main-panel-holder');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.main-panel-holder')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;

        var contentHeight = scrollHeight - (offsetHeight + 1);
        //////////////console.log(contentHeight + 'scrolltop' + scrollTop + "H" + offsetHeight)
        if (contentHeight <= scrollTop) {
            if(this.props.sidebarmenudata.tooversee > this.props.todoListing.ToOversee.data.length+1) {
                var data = {
                    category: 'manage-task',
                    status: "All",
                    uid: getProfile().id,
                    bydue: '',
                    priority: this.props.taskFilters.taskPriorityFilter,
                    project: this.props.taskFilters.taskProjectFilter,
                    asigntype: 'All',
                    managetask: 'mytask',
                    limit: this.props.todoListing.ToOversee.data.length,
                    offset: this.state.offset,
                    systype: getProfile().usertype,
                    actionType: 'LOADMORE_OVERSEE_DATA'
                }
                this.props.tooversee(data);

            }
        }

    }
    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            this.props.history.push("/task/to-follow-up")
        }
        if(prevProps.taskFilters  !== this.props.taskFilters){
            this.gettaskList('All');
        }

        var beta = this;
        if(prevProps.taskSelect.tasksArchived  !== this.props.taskSelect.tasksArchived ){
            setTimeout(function () {
                beta.gettaskList('All');
            }, 1000)
        }

        if(prevProps.todoListing  !== this.props.todoListing){
            this.setState({overseedata: this.props.todoListing.ToOversee.data});
        }

    }

    modalOpen(){
        ////////////////console.log(this.props.projects.projectData);//////////////console.log(this.props.projects.tagData);
        this.setState({open:!this.state.open,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData});
        ////////////////console.log(this.props.projects.tagData);
    }
    gettaskList(status){
        var data = {category : 'manage-task', status : status , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', limit: 0, offset  : this.state.offset, systype :  getProfile().usertype,  actionType : 'LIST_TO_OVERSEE_DATA' }
        this.props.tooversee(data);
    }

    selectTasks(id) {
        this.props.selectTasksToMove(id);
    }
    render() {
        //////////////console.log(this.props.taskFilters.taskAssignedFilter)
        //let ToOversee=this.props.todoListing.ToOversee.data.filter(item => (item.subject===''?atob(item.Title):atob(item.subject)).toLowerCase().search(this.props.searchData.task.toLowerCase()) >= 0)
        //let ToOversee=this.state.overseedata.filter(item => (item.subject===''?atob(item.Title):atob(item.subject)).toLowerCase().search(this.props.searchData.task.toLowerCase()) >= 0)
        let ToOversee=this.props.searchData.task.active? this.props.searchData.data:this.state.overseedata

         return (



                     <>
                         <div className='filter-all-wrapper task-home'>
                             {/*<AlertBar*/}
                             {/*    history={this.props.history}*/}

                             {/*/>*/}
                             <div className='filter-holder'>
                                 {this.props.taskSelect.taskSelection === 'no' &&
                                 <FilterBlock/>
                                 }
                                {getProfile().overseeStatus !== "1" &&
                                 <FilerRightBlock
                                     history={this.props.history}
                                     taskCount={this.state.overseedata.length}/>
                                 }
                             </div>
                         </div>
                         {this.state.loading === 1 &&
                         <span className="Loader-holder" style={{'background':'transparent'}}>
                                <LoaderSvg/>
                            </span>
                         }
                       <div className={this.props.todoListing.loading ? 'main-panel-holder with-loader has-filter' : 'main-panel-holder has-filter'}>
                           {this.props.todoListing.loading &&
                           <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                           }

                           {ToOversee.length === 0 &&
                               <div className='absolute-holder'>
                                   <NoData

                                       msg={`No task assigned to you`}

                                   />
                               </div>
                           }
                            {ToOversee.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'unread').map((list,index) =>
                                <React.Fragment key={'oversee'+index}>
                                    {index === 0 &&
                                    <h4 className={'main-panel-head'}>Unread</h4>
                                    }
                                    <ListCard
                                        key={'oversee-list-card'+index}
                                        index={index}
                                        sectionTitle={`Unread`}
                                        list={list}
                                        history={this.props.history}
                                        mode='manage-task'
                                        taskSelection = {this.props.taskSelect.taskSelection}
                                        selectTasks = {this.selectTasks.bind(this)}
                                        allChecked = {this.props.taskSelect.allChecked}
                                        id={list.masterid}
                                    />
                                </React.Fragment>
                            )}

                            {ToOversee.filter(list => list.inner_hits.childtodo.hits.hits[0]._source.readstatus === 'read').map((list,index) =>
                                <React.Fragment>
                                    {index === 0 &&
                                    <h4 className={'main-panel-head'}>Read</h4>
                                    }
                                <ListCard
                                    key={'oversee'+index}
                                    index={index}
                                    sectionTitle={`Read`}
                                    list={list}
                                    history={this.props.history}
                                    mode='manage-task'
                                    taskSelection = {this.props.taskSelect.taskSelection}
                                    selectTasks = {this.selectTasks.bind(this)}
                                    allChecked = {this.props.taskSelect.allChecked}
                                    id={list.masterid}
                                />
                                </React.Fragment>
                            )}
                        </div>
                         {this.state.open &&
                         <Modal title={'Create New Task'} Close ={this.modalOpen.bind(this)} >
                             <AddToDoTask />

                         </Modal>
                         }
                        <div className={'float-button-box'} onClick={this.modalOpen}>
                            <span className={'icon-in icon-plus'}></span>
                        </div>
                     </>


        );
    }
}



const mapStateToProps = state => ({

   todoListing:state.TaskReducer.listtoDo,
    taskFilters:state.TaskReducer.filterTasks,
    taskSelect:state.TaskReducer.getTaskSelection,
    menuClickData:state.CommonReducer.menuClick,
    searchData:state.CommonReducer.searchparamdata,
    topbardata:state.TaskReducer.ToOverseeTopbarMenudata,
    listtags:state.TaskReducer.ListAllTags,
    saveTodo : state.TaskReducer.saveTodo,
    projects:state.TaskReducer.GetProjectData,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    sidebarmenudata: state.TaskReducer.SidebarMenudata,
});
export default  withRouter(connect(mapStateToProps, {
    tooversee:taskActions.listtask,
    mainClick:rootActions.commonActions.MenuClick,
    selectTasksToMove : taskActions.selectTasksToMove,
    listTags:projectActions.listalltags,
    projectData:taskActions.getProjectData,
    tagData : taskActions.getTagData,
    userFilter:taskActions.userFilter,

})(ToOverSee));


