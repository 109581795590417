import React, {Component} from 'react';
import { AreaChart, Line, Bar, XAxis, YAxis,CartesianGrid, Tooltip, Area, Legend, ResponsiveContainer } from 'recharts';
/*const data = [
    {
        "x": "Page A",
        "y1": 4000,
        "y2": 2400,
        "y3": 2400
    },
    {
        "x": "Page B",
        "y1": 3000,
        "y2": 1398,
        "y3": 2210
    },
    {
        "x": "Page C",
        "y1": 2000,
        "y2": 9800,
        "y3": 2290
    },
    {
        "x": "Page D",
        "y1": 2780,
        "y2": 3908,
        "y3": 2000
    },
    {
        "x": "Page E",
        "y1": 1890,
        "y2": 4800,
        "y3": 2181
    },
    {
        "x": "Page F",
        "y1": 2390,
        "y2": 3800,
        "y3": 2500
    },
    {
        "x": "Page G",
        "y1": 3490,
        "y2": 4300,
        "y3": 2100
    }
]*/

function MultiAreaChart(props) {
    return (
        <React.Fragment>
            <ResponsiveContainer width="100%" height={300} >
                <AreaChart
                    data={props.data}>
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="x" padding={{left:0, right:0}} domain={[0, 500]}/>
                    <YAxis hide={true}  />
                    <Tooltip />

                    <defs>
                        <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={props.color1}  stopOpacity={0.5}/>
                            <stop offset="95%" stopColor={props.color1}  stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={props.color2}  stopOpacity={0.5}/>
                            <stop offset="95%" stopColor={props.color2}  stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={props.color3}  stopOpacity={0.5}/>
                            <stop offset="95%" stopColor={props.color3}  stopOpacity={0}/>
                        </linearGradient>
                    </defs>

                    <Area name="Men" type="linear" dataKey="y" fill={props.color1} stroke={props.color1}
                          fillOpacity={1} fill="url(#colorUv1)" dot={{strokeWidth : 2}} />
                    <Line name="Men" type="monotone" dataKey="y" stroke={props.color1} />
                    <Area name="Women" type="linear" dataKey="z" fill={props.color2} stroke={props.color2}
                          fillOpacity={1} fill="url(#colorUv2)" dot={{strokeWidth : 2}} />
                    <Line name="Women" type="monotone" dataKey="z" stroke={props.color2} />

                    <Area name="Children" type="linear" dataKey="y3" fill={props.color3} stroke={props.color3}
                          fillOpacity={1} fill="url(#colorUv3)" dot={{strokeWidth : 2}} />
                    <Line name="Children" type="monotone" dataKey="y3" stroke={props.color3} />
                    <Legend align={'left'} verticalAlign="top" height={36} iconType={'circle'}/>
                </AreaChart >
            </ResponsiveContainer>

        </React.Fragment>
    );
}

export default MultiAreaChart;