import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import * as collectionActions from "../../../actions/Collections";
import * as CustomFunctions from "../../../utilities/CustomFunctions";
import {FRMSIMG_PATH, FRMSDOC_PATH, FRMSVID_PATH, ES_INDEX} from "../../../constants";
import UploadFile from "../../../utilities/Upload/UploadFile";
import {getProfile} from "../../../utilities/AuthService";
import * as WorkFlowActions from "../../../actions/WorkFlow";
import AlertModal from "../../views/AlertModal";
import {getUrlSegment, formatDate} from "../../../utilities/CustomFunctions";
import * as taskActions from "../../../actions/ToDo";
import LoaderSvg from "../../views/LoaderSVG";
import DatePickerBox from "../../views/DatePickerBox";
import {getFileIcon} from "../../../utilities/CustomFunctions";
import {forceDownload} from "../../../utilities/CustomFunctions";
import ChatContainer from "../chat/ChatContainer"
class DetailContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabstate :"taks-tab",
            showStage : false,
            meta_data : [],
            filename: null,
            uploadedFiles : [],
            submitted : false,
            validationErrormodal : false,
            proceedModal : false,
            proceed : false,
            nextstageid:'',
            fileType:'',
            file_name:'',
            selectedcollections:[],
            loading:0,
            saveloading:0,
            taskStatus : ''
        }
        this.getTabstate = this.getTabstate.bind(this);
        this.showStages = this.showStages.bind(this);
        this.handleInputchange = this.handleInputchange.bind(this);
        this.removeSelectedAttachment = this.removeSelectedAttachment.bind(this);
        this.showValidationErrormodal = this.showValidationErrormodal.bind(this);
        this.showProceedmodal = this.showProceedmodal.bind(this);
        this.hideProceedmodal = this.hideProceedmodal.bind(this);
        this.proceedSubmit = this.proceedSubmit.bind(this);
        this.submitWorkFlow = this.submitWorkFlow.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.showpreviewmodal = this.showpreviewmodal.bind(this);
        this.closepreviewmodal = this.closepreviewmodal.bind(this);
        this.getAttachmentPath = this.getAttachmentPath.bind(this);
        this.getFormValues = this.getFormValues.bind(this);
    }

    componentDidMount() {
        this.setState({loading:1})
        let id = this.props.taskdetailsData.taskdetails.parent.collecteddataid;
        let collectionid = this.props.taskdetailsData.taskdetails.parent.collecteddata;
        this.props.getselectedcollections({ _id : id, collectionid : collectionid});
        let meta_data = this.props.taskdetailsData.taskdetails.parent.meta_form;
        this.setState({meta_data : meta_data, taskStatus:this.props.taskdetailsData.taskdetails.parent.status});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.postTaskCompletionasync !== this.props.postTaskCompletionasync) {
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            let params = { _id : taskid, systype: "root", uid : getProfile().id, mode: mode };
            this.props.gettaskdetails(params)
        }
        if(this.props.getProcessWorkFlowDetails.processWorkflow !== prevProps.getProcessWorkFlowDetails.processWorkflow ) {
            this.hideProceedmodal()
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));

            this.setState({taskmode : mode, taskStatus : 'Done' , saveloading : 0});
            //let data = { _id : taskid, systype: "root", uid : getProfile().id, mode: mode };

            //this.props.gettaskdetails(data);

            let verifytaskdata = {
                "taskid": taskid,
                "uid": getProfile().id,
                "index": ES_INDEX,
                "reference": 'markasdone',
                "consolidated_time":0,
                "timelog": [],
                "remarks": [{
                    "content": '',
                    "attachments": []
                }],
                "parentid": ""
            }
            this.props.TaskCompletionasync(verifytaskdata);

        }
        if(this.props.getFormdetails.formvalues !== prevProps.getFormdetails.formvalues) {
            this.getFormValues();
            this.setState({loading:0})
        }
        if(this.props.taskdetailsData.taskdetails !== prevProps.taskdetailsData.taskdetails) {
            this.setState({taskStatus:this.props.taskdetailsData.taskdetails.parent.status})
        }

        /*if(this.props.getProcessWorkFlowDetails.processWorkflow !== prevProps.getProcessWorkFlowDetails.processWorkflow) {
            //if(this.state.verifytaskdata.taskid) {
                this.props.TaskCompletionasync(this.state.verifytaskdata);
            //}
        }*/



    }

    getFormValues(){
        let selectedcollections = this.props.getFormdetails.formvalues ? this.props.getFormdetails.formvalues : [];
        this.setState({selectedcollections : selectedcollections});
    }

    getTabstate(id){
        this.setState({tabstate:id})
    }
    showStages(){
        this.setState({ showStage : !this.state.showStage});
        ////////////////console.log(this.state.showStage)

    }
    handleDateChange(date, index){

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var

        meta_data[index]['value'] = meta_data[index]['value']?meta_data[index]['value']:[];

        meta_data[index]['value'] =[{value : formatDate(date)}];


        //////////console.log(meta_data);

        this.setState({
            [meta_data[index]['id']] : date
        });

        this.setState({meta_data : meta_data});


    }
    handleInputchange(e, index , itemIndex){

        this.setState({
            [e.target.name] : e.target.value
        });

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var

        meta_data[index]['value'] = meta_data[index]['value']?meta_data[index]['value']:[];

        if(e.target.type === 'checkbox'){
            let optionVal = {value : '' };
            if(e.target.checked) {
                optionVal.value = e.target.value;
            }

            meta_data[index]['value'].push(optionVal);
        }
        else {
            meta_data[index]['value'] =[{value : e.target.value}];

        }

        this.setState({meta_data : meta_data});
    }


    getUploadedFile(uploadedFile, index) {

        let uploadedFiles = this.state.uploadedFiles;
        uploadedFiles.push(uploadedFile);
        this.setState({
            uploadedFiles : uploadedFiles
        });
        var element = document.getElementById("progress-bar-wrapper");
        element.parentNode.removeChild(element);

        let fileType = CustomFunctions.getFileIcon(uploadedFile.fileName);
        let extension = (fileType=== 'image')? '1':(fileType=== 'mp4' ? '2': '3');

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var

        meta_data[index]['value'] = meta_data[index]['value']?meta_data[index]['value']:[];

        let fileOptions = {
            extension : extension,
            uniqval : uploadedFile.fileName.split('/').pop(),
            value : uploadedFile.originalfileName,
            fileId : uploadedFile.fileId
        };

        meta_data[index]['value'].push(fileOptions);

        this.setState({meta_data : meta_data});

    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }

    submitWorkFlowAction(id) {
        this.setState({
            submitted : true,
            nextstageid :id
        });
        let formInvalid = 0;

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var
        //////////console.log(this.state);
        if(meta_data.length > 0) {
            for(var i=0; i<meta_data.length; i++) {
                //////////console.log(meta_data);
                if (meta_data[i]['required'] === 'yes') {

                    if (meta_data[i]['type'] !== "attachment") {

                        if (!this.state[meta_data[i]['id']] || this.state[meta_data[i]['id']] === '') {
                            formInvalid++;
                        }
                    } else {
                        if (this.state.uploadedFiles.length === 0) {

                            formInvalid++
                        }
                    }
                }
            }
        }


        if(formInvalid === 0){

            this.showProceedmodal();


        }else{

           // this.showValidationErrormodal();
        }

    }
    submitWorkFlow(){
        this.setState({saveloading : 1});
        let todoid = this.props.taskdetailsData.taskdetails.parent.masterid;
        let workflowid = this.props.taskdetailsData.taskdetails.parent.workflowid;
        let fieldid = this.props.taskdetailsData.taskdetails.parent.collecteddataid;
        let collectionid = this.props.taskdetailsData.taskdetails.parent.collecteddata;
        let childtasks = this.props.taskdetailsData.taskdetails.assignees.filter( a =>  a.assignee === getProfile().id);
        let creator = this.props.taskdetailsData.taskdetails.parent.createuser;

        let nextstageid = this.state.nextstageid;
        let nextstages = this.props.taskdetailsData.taskdetails.parent.nextstagebutton;

        if(nextstageid === '') {
            if(this.props.taskdetailsData.taskdetails.parent.stageforward === "parallel") {
                for(var i=0; i<nextstages.length; i++) {
                    nextstageid += nextstages[i].id+",";
                }
                nextstageid = nextstageid.replace(/(^,)|(,$)/g, "");
            }
            else{
                nextstageid = nextstages[0].id;
            }
        }
        ////////////console.log(nextstageid); //return false;

        let currentstage = (this.props.taskdetailsData.taskdetails.parent.nextstagebutton[0].id === "" ) ? 'final' :'';

        let closeworkflowparams = {previoustask : todoid, stageid: nextstageid, fieldid : fieldid, collectionid : collectionid, workflowid : workflowid, stage : currentstage};
        if(currentstage === 'final' ) {
            let parentid = this.props.taskdetailsData.taskdetails.parent.parent_id;

            closeworkflowparams = {taskid : todoid, uid : getProfile().id , reference : 'markasdone', parentid: parentid,   mode : "",  stage : currentstage, consolidated_time :'0'};


        }



        if( this.props.taskdetailsData.taskdetails.parent.meta_form.length > 0) {

            // If form exists
            let metaformdata = {
                "metaresult": this.state.meta_data,
                "uid": getProfile().id,
                "todoid": todoid
            }
            this.props.savemetaform(metaformdata, 'task', closeworkflowparams);

            var uploadedFiles = this.state.uploadedFiles;

            var uploadArray = [];

            if (uploadedFiles.length > 0) {
                for (var i = 0; i < uploadedFiles.length; i++) {
                    var uploadedFile = uploadedFiles[i];
                    var fileNameArr = uploadedFile.fileName.split('/');
                    var fileName = fileNameArr.pop();

                    var fileData = {
                        filetype: uploadedFile.fileType.startsWith('image') ? 'images' : (uploadedFile.fileType.startsWith('video') ? 'videos' : 'documents'),
                        filesize: uploadedFile.fileSize,
                        filetitle: uploadedFile.originalfileName,
                        filename: fileName
                    }

                    uploadArray.push(fileData);

                }

                var fileData = {
                    todoid: todoid,
                    uid: getProfile().id,
                    file: uploadArray,
                    refer: 'markasdone',
                    uniqid: new Date().getTime()
                }
                this.props.saveToDoAttachments(fileData);
            }

        }
        else {
            // If no form exists
            this.props.processworkflow(closeworkflowparams);
        }
        this.proceedSubmit()

    }
    removeSelectedAttachment(index) {
        let uploadedFiles = this.state.uploadedFiles;

        if(uploadedFiles) {
            uploadedFiles.splice(index, 1);
            this.setState({uploadedFiles: uploadedFiles});
        }
    }

    showValidationErrormodal(){
        this.setState({validationErrormodal : !this.state.validationErrormodal})
    }

    showProceedmodal(){
        this.setState({proceedModal : true})
    }
    hideProceedmodal(){
        this.setState({proceedModal : false})
    }

    proceedSubmit(){
        this.setState({proceed : !this.state.proceed})
    }

    showpreviewmodal(filename){
        this.setState({showpreviewmodal : !this.state.showpreviewmodal,
            fileType:getFileIcon(filename),
            file_name:filename
        })
    }
    closepreviewmodal(){
        this.setState({showpreviewmodal : false,
        })
    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }

    renderFields(field, index, childIndex) {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index) :this.showFieldValue(field, index, childIndex);
    }

    showFieldValue(field, index, childIndex) {
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
            //////////////console.log(field.value[childIndex]);
        }

        return <tr key={index}>
            <td>{field.label}</td>
            {field.type === 'formheading' ?
                <td>&nbsp;</td>
                :
                <td> {field.linked === 'No' && field.values &&
                field.values.map((value, itemIndex) =>
                    this.renderFieldType(value, field.type)
                )
                }
                    { field.linked === 'Yes' && field.values && field.values.length > 0 &&
                    this.renderFieldType(field.values[childIndex], field.type)
                    }

                </td>}

        </tr>;
    }

    renderFieldType(values, fieldType) {
        let fieldValue = '';
        if(fieldType === 'attachment') {
            let iconClass = CustomFunctions.getFileIcon(values.uniqvalue);
            let contentPath = this.getAttachmentPath(iconClass);
            fieldValue = <a href="javascript:void(0);" className="attach-click"
                            onClick={(e) => CustomFunctions.forceDownload(contentPath + values.uniqvalue, values.uniqvalue)}
                            data-toggle="tooltip"
                            data-placement="bottom" data-original-title="dummy">
                {iconClass !== 'image' &&
                <div className={"format " + iconClass}>
                    {iconClass}
                </div>
                }
                {iconClass === 'image' &&
                <img className="custom-image" src={FRMSIMG_PATH + values.uniqvalue}/>
                }

            </a>;
        }
        else {
            fieldValue = values.value;
        }

        return fieldValue;

    }

    showFieldSetValue(field, index) {

        let children = field.values[0].value;

        return <>
            <tr key={index}><td colSpan="2" style={{color: "#3FA9F5"}}>{field.label}</td></tr>
            {children.length > 0 &&
            this.renderChildFields(children, index)
            }
        </>;
    }


    renderChildFields(children, index){
        let childComponents = this.state.selectedcollections.filter((item)=> children.includes(item.masterid));
        let fieldSetVal = childComponents.map((item) => item.value ? item.value.length:0);
        let fieldSetCount = Math.max.apply(null, fieldSetVal) // 4

        var rows = [];
        for (var i = 0; i < fieldSetCount; i++) {
            for(var j=0; j< childComponents.length; j++) {
                rows.push(this.renderFields(childComponents[j], j, i ));
            }
        }
        return rows;

    }

    render() {
        let taskData = this.props.taskdetailsData.taskdetails;
        let parentData = taskData.parent.meta_form;
        let assigneesData = this.props.taskdetailsData.taskdetails.assignees;
        let logData = this.props.taskdetailsData.taskdetails.log;
        let commentData = this.props.taskdetailsData.taskdetails.comment;
        let allfilesData = this.props.taskdetailsData.taskdetails.allfiles;
        let subtaskData = this.props.taskdetailsData.taskdetails.subtask;
        let attachmentData = this.props.attachmentData.attachList;
        let collectionData = this.props.taskdetailsData.taskdetails.collection;
        let previoustasksData = this.props.taskdetailsData.taskdetails.previoustasks;

        let selectedcollections = this.state.selectedcollections;


        let previewableAttachments = attachmentData.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
        let list = taskData.parent;

        return(
            <>
                    {this.props.taskdetailsData.loading &&
                            <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                    }
                {this.state.loading === 1 &&
                <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                }{this.state.saveloading === 1 &&
                <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                }
                    <div className="workflow-addon">
                        <div className="workflow-addon-left">
                            <div className="scroll-div">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" onClick={(e)=>this.getTabstate("taks-tab")}>
                                        <a className={this.state.tabstate === "taks-tab" ? "nav-link active" : "nav-link"} id="taks-tab" data-toggle="tab"
                                           role="tab" aria-controls="taks" aria-selected="false">
                                            <span className="ico-lib-v1 tasks"></span>
                                            <span className="tab-text">Task</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={(e)=>this.getTabstate("disscussion-tab")}>
                                        <a className={this.state.tabstate === "disscussion-tab" ? "nav-link active" : "nav-link"} id="disscussion-tab" data-toggle="tab"
                                           role="tab" aria-controls="disscussion"
                                           aria-selected="true">
                                            <span className="ico-lib-v1 disscussion"></span>
                                            <span className="tab-text">Disscussion</span>
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={(e)=>this.getTabstate("file-tab")}>
                                        <a className={this.state.tabstate === "file-tab" ? "nav-link active" : "nav-link"} id="file-tab" data-toggle="tab" role="tab"
                                           aria-controls="att" aria-selected="false">
                                            <span className="ico-lib-v1 file"></span>
                                            <span className="tab-text">Files</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                {this.state.tabstate === "taks-tab" &&
                                    <div className="tab-pane tab-scroll fade show active" id="task" role="tabpanel"
                                         aria-labelledby="task-tab">
                                        <div className="task-wf-wrapper">
                                            {list &&
                                                    <div className="ind-task-wf">
                                                {/*<button className="task-wf-header">*/}
                                                {/*    <h5>Stage 3 - <span className="font-bold-600">Payment</span></h5>*/}
                                                {/*</button>*/}
                                                <div className="task-wf-body">
                                                    <div className="task-wf-body-header">
                                                        <p className="header-left-text wf-med wf-high wf-low wf-urgent">{list.priority}</p>
                                                        <p className="header-right-text">DUE DATE {moment(list.duedate).format('DD MMM YYYY')}</p>
                                                    </div>
                                                    <h5>{atob(list.subject)}</h5>
                                                    <p className="body-text">{atob(list.Title)}</p>
                                                </div>
                                                        {collectionData.length > 0 &&
                                                        <div className="task-wf-forms">
                                                            <h5>Forms ({collectionData.length})</h5>
                                                            <ul className="forms-list">
                                                                {collectionData.map((item, index) =>
                                                                    <li>
                                                                        <a href="javascript:void(0);"
                                                                           className="ico-lib-v1 fm-ico">{atob(item.title)}</a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                        }
                                            </div>
                                            }
                                                {previoustasksData.map((item,index)=>
                                                    <div className="ind-task-wf">
                                                        <button className="task-wf-header with-clickable approved">
                                                            <h5>{item.stagename}<span
                                                                className="font-bold-600"></span></h5>

                                                        </button>
                                                        <div className="task-wf-feed">
                                                            { item.meta_form.map((metalist,index)=>

                                                                <div className={(metalist.type === "attachment" && metalist.value !== undefined && metalist.value !== null && metalist.value.length > 0) ? "file-tag" : "feed-list"}>
                                                                    {metalist.type !== "attachment" &&
                                                                    <h5>{metalist.label}</h5>
                                                                    }
                                                                    {metalist.value !== undefined && metalist.value !== null && metalist.value.map((values, index) =>
                                                                        <p>{values.value}</p>
                                                                    )}
                                                                </div>

                                                                )}

                                                        </div>
                                                        {/*<div className="task-wf-body">*/}
                                                        {/*    <div className="task-wf-body-header">*/}
                                                        {/*        <p className="header-left-text wf-med wf-high wf-low wf-urgent">Medium</p>*/}
                                                        {/*        <p className="header-right-text">COMPLETE WITHIN 20*/}
                                                        {/*            Jan 2018</p>*/}
                                                        {/*    </div>*/}
                                                        {/*    <h5>Corporate clients corder a publication to be made*/}
                                                        {/*        and presented*/}
                                                        {/*        Corporate clients corder a publication</h5>*/}
                                                        {/*    <p className="body-text">In a professional context it*/}
                                                        {/*        often happens*/}
                                                        {/*        that*/}
                                                        {/*        private or corporate clients corder a publication to*/}
                                                        {/*        be made and*/}
                                                        {/*        presented with the actual content still not being*/}
                                                        {/*        ready*/}
                                                        {/*        publication*/}
                                                        {/*        to be made often happens.</p>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="task-wf-forms">*/}
                                                        {/*    <h5>Forms (2)</h5>*/}
                                                        {/*    <ul className="forms-list">*/}
                                                        {/*        <li>*/}
                                                        {/*            <a href="javascript:void(0);"*/}
                                                        {/*               className="ico-lib-v1 fm-ico">Form*/}
                                                        {/*                Name Here</a>*/}
                                                        {/*        </li>*/}
                                                        {/*        <li>*/}
                                                        {/*            <a href="javascript:void(0);"*/}
                                                        {/*               className="ico-lib fm-ico-ac">Form*/}
                                                        {/*                Name Here</a>*/}
                                                        {/*        </li>*/}
                                                        {/*    </ul>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                )}

                                        </div>
                                    </div>
                                }
                                {this.state.tabstate === "disscussion-tab" &&
                                    <div className="tab-pane tab-scroll chat-panel show active" id="disscussion" role="tabpanel"
                                         aria-labelledby="disscussion-tab">
                                        <ChatContainer
                                            taskid={atob(getUrlSegment(3))}
                                            assigneesData={assigneesData}
                                        />
                                    </div>
                                }
                                {this.state.tabstate === "file-tab" &&
                                    <div className="tab-pane tab-scroll chat-panel split show active" id="file" role="tabpanel"
                                         aria-labelledby="file-tab">
                                        <div className="ind-task-wf">
                                            <div className="task-wf-body">
                                                {/*{allfilesData.map((item,index) =>{*/}
                                                {/*    let iconClass = item.filename!== null ? getFileIcon(item.filename):'';*/}
                                                {/*    let contentPath = this.getAttachmentPath(iconClass);*/}
                                                {/*    return(*/}
                                                {/*        <p className="ind-file-list file-div">*/}
                                                {/*            <a href="javascript:void(0);" className="attach-click attachlink"  onClick={(item.filetype.startsWith('image') || item.filetype.startsWith('video'))? ((e) => this.showpreviewmodal(item.filename)) : ((e) => forceDownload(contentPath + item.filename, item.filename))}>*/}
                                                {/*            <span className="attach-click-thumb">{iconClass !== 'image' &&*/}
                                                {/*            <div className={"format " + iconClass}>*/}
                                                {/*                {iconClass}*/}
                                                {/*            </div>*/}
                                                {/*            }*/}
                                                {/*                {iconClass === 'image' &&*/}
                                                {/*                <img style={{ width: "20px"}}src={contentPath+ item.filename} />*/}
                                                {/*                }</span> {item.filetitle.split(".")[0]}*/}
                                                {/*            </a>*/}
                                                {/*            <span className="file-size">{item.filesize}</span>*/}
                                                {/*        </p>*/}
                                                {/*    )}*/}
                                                {/*    )}*/}
                                                {attachmentData.map((item,index) =>{
                                                    let iconClass = item.filename!== null ? getFileIcon(item.filename):'';
                                                    let contentPath = this.getAttachmentPath(iconClass);
                                                    return(
                                                        <p className="ind-file-list file-div">
                                                            <a href="javascript:void(0);" className="attach-click attachlink"  onClick={(item.filetype.startsWith('image') || item.filetype.startsWith('video'))? ((e) => this.showpreviewmodal(item.filename)) : ((e) => forceDownload(contentPath + item.filename, item.filename))}>
                                                            <span className="attach-click-thumb">{iconClass !== 'image' &&
                                                            <div className={"format " + iconClass}>
                                                                {iconClass}
                                                            </div>
                                                            }
                                                                {iconClass === 'image' &&
                                                                <img style={{ width: "20px"}}src={contentPath+ item.filename} />
                                                                }</span> {item.filetitle.split(".")[0]}
                                                            </a>
                                                            <span className="file-size">{item.filesize}</span>
                                                        </p>
                                                    )}
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="workflow-addon-center">
                            {list &&
                            <div className="addon-center-header">
                                <h5>{list.inputformname}</h5>
                                <ul className="inv-block">
                                    <li className="ico-lib-v1 date-inv">{moment(list.createdate).format('DD MMM YYYY')}</li>
                                    {/*<li className="ico-lib-v1 fm-inv">3</li>*/}
                                </ul>
                            </div>
                            }
                            {selectedcollections.length > 0 &&
                                <div className="addon-center-body">
                                    <table className="table invoke-table">
                                        <tbody>
                                        {selectedcollections.filter((item) => item.linked== 'No').map((data,index) =>
                                            <>
                                                {this.renderFields(data, index)}
                                                {/*{item.type === "attachment" &&*/}
                                                {/*<tr>*/}
                                                {/*    <td>{item.label}</td>*/}
                                                {/*    {item.values !== null && item.values !== undefined && item.values.map((value, index) => {*/}
                                                {/*            let iconClass = CustomFunctions.getFileIcon(value.uniqvalue);*/}
                                                {/*        let contentPath = this.getAttachmentPath(iconClass);*/}
                                                {/*            return (*/}
                                                {/*                <td>*/}
                                                {/*                    <a href="javascript:void(0);" className="attach-click" onClick={(e)=>CustomFunctions.forceDownload(contentPath + value.uniqvalue, value.uniqvalue)}*/}
                                                {/*                       data-toggle="tooltip"*/}
                                                {/*                       data-placement="bottom" data-original-title="dummy">*/}
                                                {/*                        {iconClass !== 'image' &&*/}
                                                {/*                        <div className={"format " + iconClass}>*/}
                                                {/*                            {iconClass}*/}
                                                {/*                        </div>*/}
                                                {/*                        }*/}
                                                {/*                        {iconClass === 'image' &&*/}
                                                {/*                        <img className="custom-image" src={FRMSIMG_PATH + value.uniqvalue}/>*/}
                                                {/*                        }*/}

                                                {/*                    </a>*/}
                                                {/*                </td>*/}
                                                {/*            )*/}
                                                {/*        }*/}
                                                {/*    )}*/}
                                                {/*</tr>*/}
                                                {/*}*/}
                                                {/*{item.type !== "fieldset" && item.type !== "attachment" &&*/}
                                                {/*<tr>*/}
                                                {/*    <td>{item.label}</td>*/}
                                                {/*    {item.values !== null && item.values !== undefined && item.values.map((value, index) =>*/}
                                                {/*        <td>{value.value}</td>*/}
                                                {/*    )}*/}
                                                {/*</tr>*/}
                                                {/*}*/}
                                                {/*{item.type === "fieldset" &&*/}
                                                {/*<>*/}
                                                {/*    {item.values !== null && item.values !== undefined && item.values[0].map((valuelist, index) =>*/}
                                                {/*        <tr>*/}
                                                {/*            <td>{valuelist.label}</td>*/}
                                                {/*            <td>{valuelist.values.value}</td>*/}
                                                {/*        </tr>*/}
                                                {/*    )}*/}
                                                {/*</>*/}
                                                {/*}*/}
                                            </>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                        <div className="workflow-addon-right">
                            <div className="addon-right-header">
                                <h5>Action Required</h5>
                            </div>
                            <div className="addon-right-body">
                                {this.state.meta_data.map((meta,index) =>
                                    <>
                                        {meta.type !== "attachment" &&
                                            <div className={meta.type === "dropdown" ? "drop-wrapper" : "input-block"}
                                                key={index}>
                                                {meta.type !== "attachment" &&
                                                <h5>{meta.label}</h5>
                                                }
                                                {meta.type === "checkbox" &&
                                                <ul className="select-lister">
                                                    {meta.options !== null && meta.options !== undefined && meta.options.map((option, opt_index) =>
                                                        <li>
                                                            <div className="select-chk-block"
                                                                 style={{display: "block"}}>
                                                                <label className="control control-checkbox">
                                                                    {option}
                                                                    <input type="checkbox"
                                                                           name={meta.id }
                                                                           className="inp-chk" value={option}
                                                                           onChange={(e) => this.handleInputchange(e, index, opt_index)}
                                                                           required={meta.required=== 'yes' ? true : false}
                                                                           checked={(meta.value ? (meta.value[0].value === option) : false)}
                                                                    />
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>

                                                }
                                                {meta.type === "switch" &&
                                                <ul className="select-lister">
                                                    {meta.options !== null && meta.options !== undefined && meta.options.map((option) =>
                                                        <li>
                                                            <div className="radio-block">
                                                                <input type="radio" id="singlestep" className="off_type"
                                                                       name={meta.id} value={option}
                                                                       onChange={(e) => this.handleInputchange(e, index)}
                                                                       required={meta.required=== 'yes' ? true : false}
                                                                       checked={(meta.value ? (meta.value[0].value === option) : false)}
                                                                />
                                                                <label htmlFor="singlestep">{option}</label>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                                }
                                                {meta.type === "text" &&
                                                <input type="text" name={meta.id} className="input-box"
                                                       placeholder="Input here"
                                                       onChange={(e) => this.handleInputchange(e, index)}
                                                       required={meta.required=== 'yes' ? true : false}
                                                       defaultValue={meta.value ? meta.value[0].value : ''}
                                                />
                                                }
                                                {meta.type === "dropdown" &&
                                                <div className="select-box">
                                                    <select name={meta.id}
                                                            onChange={(e) => this.handleInputchange(e, index)} required={meta.required=== 'yes' ? true : false} value={meta.value ? meta.value[0].value :''}>
                                                        <option value="">Select</option>
                                                        {meta.options !== null && meta.options !== undefined && meta.options.map((option) =>
                                                            <option value={option}>{option}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                }
                                                {meta.type === "datepicker" &&
                                                <div className="input-box" style={{background : "white"}}>
                                                <DatePickerBox handleDateChange={this.handleDateChange} startDate = {meta.value ? new Date(meta.value[0].value) : this.state.duedate} id ={meta.id} name={meta.id} required={meta.required=== 'yes' ? true : false} index={index}/>
                                                </div>
                                                }
                                                {meta.type === "number" &&
                                                <input type="number" name={meta.id} className="input-box"
                                                       placeholder="Input here"
                                                       onChange={(e) => this.handleInputchange(e, index)}
                                                       required={meta.required=== 'yes' ? true : false}
                                                       defaultValue={meta.value ? meta.value[0].value : ''}
                                                />
                                                }
                                                {meta.type === "textarea" &&
                                                <textarea className="textarea" name={meta.id} placeholder="textarea" onChange={(e) => this.handleInputchange(e, index)}
                                                          required={meta.required=== 'yes' ? true : false} defaultValue={meta.value ? meta.value[0].value : ''}></textarea>
                                                }

                                                <label className={"form-error-text form-error-label "+ ((this.state.submitted && (!this.state[meta.id] || this.state[meta.id]=== '') && meta.required=== 'yes')?" " : "hide")}> Please fill the field</label>
                                            </div>

                                        }

                                        {meta.type === "attachment" &&
                                        <>
                                            <div className= "attach-block">
                                                <div id="todoFileAssets">
                                                    <label className="ico-lib att">
                                                        <UploadFile name={meta.id} getUploadedFile={this.getUploadedFile.bind(this)}  fileIndex={index} required={meta.required=== 'yes' ? true : false}/>
                                                        files
                                                    </label>
                                                </div>
                                                <label className={"form-error-text form-error-label "+ ((this.state.submitted && (this.state.uploadedFiles.length === 0)?"" : "hide"))}> Please attach files</label>
                                            </div>
                                            <div className="attached-file">
                                                {/*this.state.uploadedFiles && this.state.uploadedFiles.map((item, opt_index) =>
                                                    <div className="file-tag" id={"attachment_"+item.fileId}>{item.originalfileName} <button className="ico-lib cl-fl" onClick={(e)=>this.removeSelectedAttachment(opt_index)}></button>
                                                    </div>
                                                )*/}
                                                {meta.value !== null && meta.value !== undefined && meta.value.map((value, opt_index) =>
                                                    <div className="file-tag" id={"attachment_"+value.fileId}>{value.value} <button className="ico-lib cl-fl" onClick={(e)=>this.removeSelectedAttachment(opt_index)}></button>
                                                    </div>
                                                )}
                                            </div>

                                        </>
                                            }
                                </>
                                )}
                            </div>
                            {(this.props.taskdetailsData.taskdetails.parent.status !== "Done" && this.state.taskStatus !== 'Done' )&&
                            <div className="addon-footer">

                                <div className="dropdown invoke-pop">

                                    {this.props.taskdetailsData.taskdetails.parent.stageforward === "parallel" &&
                                    <button className="general-btn" type="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            onClick={(e) => this.submitWorkFlowAction('')}>Submit
                                    </button>
                                    }
                                    {this.props.taskdetailsData.taskdetails.parent.stageforward !== "parallel" && this.props.taskdetailsData.taskdetails.parent.nextstagebutton[0].id !== "" &&
                                    <button className="general-btn" type="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            onClick={(e) => {
                                                this.showStages(e)
                                            }}>Submit
                                    </button>
                                    }
                                    {this.props.taskdetailsData.taskdetails.parent.stageforward !== "parallel" && this.props.taskdetailsData.taskdetails.parent.nextstagebutton[0].id === "" &&
                                    <button className="general-btn" type="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            onClick={(e) => this.submitWorkFlowAction('')}>Submit
                                    </button>
                                    }
                                    {this.state.showStage === true &&
                                    <div className="add-users__list--pop-up milestone" id="added-user-popup" style={{display: "block"}}>
                                    <ul>
                                        {this.props.taskdetailsData.taskdetails.parent.nextstagebutton.map((stage) =>
                                            <li className="milestone-plus-new" data-id={stage.id}
                                                onClick={(e) => this.submitWorkFlowAction(stage.id)}>{stage.title}</li>
                                        )}
                                    </ul>
                                    </div>
                                    }
                                </div>
                            </div>
                            }

                        </div>
                        {this.state.validationErrormodal &&
                            <AlertModal id="fill-metadata"
                                        title="Please fill the meta info!"
                                        OK_button = "hide"
                                        showModal = {this.state.validationErrormodal}
                                        CANCEL_action={() => this.showValidationErrormodal()}
                            />
                        }
                        {this.state.proceedModal &&
                        <AlertModal id="proceed"
                                    title="Are you sure to proceed?"
                                    OK_button_text="Yes, Proceed"
                                    OK_action={() => this.submitWorkFlow()}
                                    showModal = {this.state.proceedModal}
                                    CANCEL_action={() => this.hideProceedmodal()}
                        />
                        }
                    </div>
                {this.state.showpreviewmodal === true &&
                <div className="modal bsy-modal att-preview x-large in show" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: this.state.showpreviewmodal === true ? 'block' : 'none' }} >
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header ">
                                <h5 className="modal-title ico-lib "></h5>
                                <div className="close-section">
                                    <button className="ico-lib-list modal-close" data-dismiss="modal"
                                            aria-label="Close" onClick={(e)=>this.closepreviewmodal(e)}></button>
                                </div>

                            </div>
                            <div className="modal-body">

                                {this.state.fileType === 'image' ?
                                    <img id="attachPrv" src={FRMSIMG_PATH + this.state.file_name}
                                         style={{display: 'block'}}/>
                                    :
                                    <video width="320" height="240" controls
                                           style={{display: 'block'}}>
                                        <source src={FRMSVID_PATH + this.state.file_name}
                                                type="video/mp4"></source>
                                    </video>
                                }



                            </div>
                            <div className="modal-footer">
                                {this.state.fileType === 'image'  &&
                                <a className="general-btn-a" id="downloadPrv"
                                   href="javascript:;" onClick={()=>forceDownload(FRMSIMG_PATH + this.state.file_name, this.state.file_name)}>Download</a>
                                }
                                {this.state.fileType === 'mp4'  &&
                                <a className="general-btn-a" id="downloadPrv"
                                   href="javascript:;" onClick={()=>forceDownload(FRMSVID_PATH + this.state.file_name, this.state.file_name)}>Download</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
                </>

        );
    }
}

const mapStateToProps = state => ({
    taskdetailsData : state.TaskReducer.TaskDetails,
    getFormdetails : state.CollectionsReducer.getFormData,
    getProcessWorkFlowDetails : state.workflowReducer.getProcessWorkFlow,
    getSaveMetaForm : state.CollectionsReducer.saveMetaForm,
});

export default  withRouter(connect(mapStateToProps, {
    gettaskdetails:taskActions.taskdetails,
    getselectedcollections : collectionActions.getFormData,
    savemetaform : collectionActions.savemetaform,
    processworkflow : WorkFlowActions.ProcessWorkFlowtask,
    saveToDoAttachments : taskActions.saveToDoAttachments,
    TaskCompletionasync:taskActions.postTaskCompletionasync,
})(DetailContainer));
