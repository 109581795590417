import React, {Component} from 'react';
import {getFileExtension, forceDownload,isVideo} from "../../utilities/CustomFunctions";
import VideoPlayer from "../views/player/VideoPlayer"

class ImagePreviewModalview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: '',
            ImgPathLink : ''
        }

    }


    componentDidMount() {
        this.setState({
            title: this.props.title?this.props.title: this.state.title ,
            ImgPathLink: this.props.ImgPathLink?this.props.ImgPathLink: this.state.ImgPathLink
        });
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    OK_action() {
        this.props.OK_action();
    }

    CANCEL_action() {
        this.props.CANCEL_action();
    }

    render() {
//////////////console.log(getFileExtension(this.props.title));
let Ftype = getFileExtension(this.props.title);
let download=this.props.downloadPermission
        //console.log(download);
        return (


<div className="modal fade bsy-modal x-large fl-pv show" id="toModal" tabIndex="-1" role="dialog"
     aria-labelledby="mngModal" style= {{display: 'block' , paddingLeft: '17px'}}>
    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">
                    {this.props.title}
                </h5>
                <button className="ico-lib-list modal-close" data-dismiss="modal" aria-label="Close" onClick={()=> this.props.PreviewClose()}></button>
            </div>
            <div className="modal-body">
                <div className="preview-area">
                     { Ftype == 'jpg' || Ftype == 'gif' || Ftype == 'jpeg' || Ftype == 'png' ?
                        <img src={this.props.ImgPathLink}/> :
                         Ftype == 'pdf' || Ftype == 'xls' || Ftype == 'docx' || Ftype == 'pptx'|| Ftype == 'doc' ?
                            <img />:
                             Ftype == 'mp4' || Ftype == 'mp3' ?

                             <VideoPlayer  width="100%" height="100%" src={this.props.ImgPathLink}/>
                          :
                            <p></p>
                    }

                    {(isVideo(this.props.title) && download==="yes") ||  (isVideo(this.props.title) === false) &&
                        <div className="preview-options">
                            <ul className="resources-filters-icons">
                                <li><a  className="rs-tab-btn ico-lib-v1 rc-download"
                                        onClick={(e) =>(forceDownload(this.props.ImgPathLink, this.props.title))}>

                                </a>
                                </li>
                                {/*<li><a onClick={(e) => this.props.deleteImage(this.props.ImgPathLink, this.props.title)} className="rs-tab-btn ico-lib-v1 rc-delete"></a></li>*/}
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</div>

    );


    }
    }
    export default ImagePreviewModalview;
