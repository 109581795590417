import {combineReducers} from 'redux';
const getUserStatics =  (state = {Dashboard:{},results:{}}, action) => {
    switch (action.type) {
        case "LIST_All_STATICS":
            return {
                ...state,
                Dashboard: action.listallstatics.data,
            };
            break;
        default:
            return state;
    }
};
const getDashboardStatistics =  (state = {Dashboard:[]}, action) => {
    switch (action.type) {
        case "LIST_All_DASHBOARD_STATICS":
            return {
                ...state,
                Dashboard: action.listdashboardstatics.data.results,
            };
            break;
        default:
            return state;
    }
};

const getCalendarTasks =  (state = {pendingTasks:[],upComingTasks:[]}, action) => {
    switch (action.type) {
        case "LIST_PENDING_TASKS_CNT":
            if(action.status === 'Pending') {
                return {
                    ...state,
                    pendingTasks: action.dueTasks,
                };
            }
            if(action.status === 'Upcoming') {
                return {
                    ...state,
                    upComingTasks: action.dueTasks,
                };
            }
            break;
        default:
            return state;
    }
};

const getWeeklyStatistics =  (state = {weeklyStat:{}}, action) => {
    switch (action.type) {
        case "LIST_WEEKLY_STAT":
            return {
                ...state,
                weeklyStat: action.weeklyStat,
            };
            break;
        default:
            return state;
    }
};

export const Dashboard = combineReducers({
    getUserStatics,
    getCalendarTasks,
    getWeeklyStatistics,
    getDashboardStatistics
});

export default Dashboard;

