import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import moment from 'moment';
import LoaderSvg from "../../views/LoaderSVG";
import * as portfolioActions from "../../../actions/Portfolio";
class AddNewSector extends Component {

    constructor(props) {
        super(props);
        this.state={
            sectorname:'',
            sectordescription : '',
            portfolioID :0,
            loading:0
        };
        this.PortfolioInputChange= this.PortfolioInputChange.bind(this);
        this.CreateSector=this.CreateSector.bind(this);

    }

    PortfolioInputChange(e) {
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
       if(prevProps.portfolioData !== this.props.portfolioData){
           let portfolioDetails = this.props.portfolioData.portfolioDet;

           if(portfolioDetails){
               this.setState({
                   portfolioID:portfolioDetails[0].portfolioId,
                   sectorname:portfolioDetails[0].portfolioName,
                   sectordescription:decodeURIComponent(escape(atob(portfolioDetails[0].description))),
               })
           }
       }
    }

    CreateSector(event) {
        event.preventDefault();
        let formValid = 0;
        var sectorname = this.state.sectorname;
        var sectordescription = this.state.sectordescription;
        var portfolioID = (this.state.portfolioID =='0' ? moment().unix() : this.state.portfolioID);
        var mode = (this.state.portfolioID =='0' ? 'save' : 'update');

        if (this.state.sectorname.trim() === '') {
            formValid++;
            document.getElementsByName('sectorname')[0].classList.add('val-error');
        }

        if (this.state.sectordescription.trim() === '') {
            formValid++;
            document.getElementsByName('sectordescription')[0].classList.add('val-error');
        }
        if(formValid === 0){
            this.setState({loading: 1})
        let sectordata = {
            _id: portfolioID,
            portfolioName: sectorname,
            description: sectordescription,
            uid: getProfile().id,
            mode: mode,
            creator:getProfile().id
        };
        this.props.saveportfolio(sectordata);

        }
    }

    render() {

        return (
                <div className="task-content-block">
                    <div className="persitant-top">
                        {(this.props.portfolioData.loading || this.state.loading === 1)&&
                        <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                        }
                        <div className="task-input-block">
                            <input type="text"  name="sectorname" className="task-input-box--new"  defaultValue={this.state.sectorname} placeholder="Name" onChange={this.PortfolioInputChange} />
                        </div>
                        <div className="task-input-block text-input">
                            <textarea className="task-textarea--new" name="sectordescription" defaultValue={this.state.sectordescription}
                                      placeholder="Description" onChange={this.PortfolioInputChange}></textarea>
                        </div>
                    </div>
                    <div className="persitant-footer">
                        <div className="group-row">
                        </div>
                        <button data-masterid="0" data-mode="save" type="button" className="general-btn" onClick={this.CreateSector}
                                id="createportfolio">{this.state.portfolioID =='0' ? 'Create' : 'Update'}
                        </button>
                    </div>
                </div>
        );
    }
}
const mapStateToProps = state => ({
    PortfolioInfoDet : state.SectorReducer.listallPortfolio,
    SavePortfolioDet : state.SectorReducer.SavePortfolio,
    portfolioData:state.SectorReducer.getPortfolioDetails,
});
export default  withRouter(connect(mapStateToProps, {
    saveportfolio:portfolioActions.SavePortfolio,

})(AddNewSector));



