import {combineReducers} from 'redux';

const ListALLFields = (state = {}, action) => {

    switch (action.type) {

        case "LIST_ALL_FIELDS":
            return {
                ...state,
                listallfields: action.listallfields.data,


            };
            break;



        default:
            return state;
    }
};




export const PdfBuilderReducer = combineReducers({
    ListALLFields
});

export default PdfBuilderReducer;


