import React, {Component} from 'react';
import {connect} from "react-redux";
import * as logintActions from "../../actions/Login"
import {withRouter} from 'react-router';
//import AuthService from "../../utilities/AuthService"

class Login extends Component {

    constructor(props) {
        super(props);
      //  this.AuthService = new AuthService();
        this.Login=this.Login.bind(this)
        this.formHandler=this.formHandler.bind(this)
        this.state={
            username:'',
            password:'',
            userError:false,
            passwordError:false
        }
      }



    componentDidMount() {
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    formHandler(e){

        this.setState({[e.target.name]:e.target.value})
    }
    Login(e){
        e.preventDefault();
        if(this.state.username === ''){
            this.setState({userError:true})
        }
        else{
            this.setState({userError:false})
        }
        if(this.state.password===''){
            this.setState({passwordError:true})
        }
        else{
            this.setState({passwordError:false})
        }
        if(this.state.username !=='' && this.state.password !==''  ){
          this.props.login(this.state.username,this.state.password)
        }



    }


    render() {
////console.log(this.props)
         return (

             <div className="wrapper">
                 <section className="login-area">
                     <div className="login-bg">
                         <div className="login-block">

                             <h1>Knowledge <span className="highlight">@ Work</span></h1>
                             <div className="logo-brand">
                                 <img src="assets/images/brand-logo-black.svg"/>
                             </div>
                             <form onSubmit={this.Login}>
                             <div className="login-input-block int">
                                 <input type="text" name="username" className={`login-input ${this.state.userError?'error':''}`} placeholder="User Name" onChange={this.formHandler} />
                             </div>
                             <div className="login-input-block">
                                 <input type="Password" name="password" className={`login-input ${this.state.passwordError?'error':''}`} placeholder="Password" onChange={this.formHandler}/>
                             </div>
                             <div className="login-button-block" >
                                 <button className="login-button">Login</button>
                             </div>
                             </form>

                         </div>
                     </div>
                 </section>
             </div>


        );
    }
}



const mapStateToProps = state => ({



});
export default  withRouter(connect(mapStateToProps, {
login:logintActions.UserLogin
})(Login));


