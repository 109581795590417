import React from 'react';
import axios from 'axios';
import {Draggable, state} from 'react-page-maker';
import {connect} from "react-redux";
import * as collectionActions from "../../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../../constants";
import {getUrlSegment} from "../../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../../utilities/AuthService";
import {SettingsPanel} from "../ViewData";
import TableBlock from "../../views/content/TableBlock";
import EntityModal from "./EntityModal";
import * as formsActions from "../../../../../actions/Forms";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

class TableblockContainer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            modalStatus: false,
            showColorPicker: false,
            background: '#FF1B40',
            cId: '',
            payload: {
                chartTitle: "Title",
                subTitle: "subtitle",
                subTitleEnabled: false,
                bubblechartdata: [],
                cardvalue: 0,
                collection: "",
                fields: "",
                aggregation: '',
                graph: false,
                x: "",
                y: "",
                z: "",
                xstroke: "",
                ystroke: "",
                labelx: "",
                labely: "",
                zstroke: "#fff",
                labelchartTitle: "",
                xwidth: "0",
                xheight: "30",
                ywidth: "60",
                yheight: "0",
                graphHeight: "200",
                xAngle: "0",
                yAngle: "0",
                filter_list: []
            },
            items: [{
                id: "item-1",
                label: "item 1",
                field: "",
                color: "#cc332b"
            }],
            whereCondition: [
                {
                    id: "item-1",
                    label: "",
                    field: "",
                    conditions: "",
                    min: '',
                    max: '',
                    from: '',
                    to: '',
                    text: "",
                    type: ""
                },
            ],
            layout: "",
            showlistField: [],
            condition: [{
                field: "",
                type: "",
                sort: ""
            }],
            loading: true,
            colorpickerStatus: false,
            limit: '100',
            basedon: "",
            ownFilter: false,
            relFilCnt: 0,
            relFltr: false,
            setFields: [
                {
                    id: "item-1",
                    masterid: "",
                    default: ""
                },
            ],
            bubbleLabel: '',
            colorAxis: 'z',
            rom: 0,
            size: 300,
            datafilters: [],
            filter_list: [],
            filterIds: [],
            filterApplied: false

        }


    }

    componentDidMount() {
        let ownid = "table-block" + Math.round(new Date().getTime() + (Math.random() * 100));
        this.setState({ownid: ownid, payload: this.props.payload})
        this.graphData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.getsyncRec !== prevProps.getsyncRec) {
            this.graphData()
        }
    }

    openSetting = () => {
        this.setState({modalStatus: !this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }
    handleChangeComplete = (color, param) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[param] = color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
        //this.setState({ background: color.hex });
    };
    toggleColorPicker = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    }
    handleInputChange = (e) => {
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    graphData() {

        //console.log(this.props.payload)
        var date = new Date();
        var timestamp = date.getTime();
        var obj = this;
        const collection = this.props.payload && this.props.payload.collection
        const tabledata = this.props.payload && this.props.payload.graph || this.state.items
        const whereCondition = this.props.payload && this.props.payload.whereCondition || this.state.whereCondition
        const condition = this.props.payload && this.props.payload.condition || this.state.condition
        const limit = this.props.payload && this.props.payload.limit
        const groupbyEnable = this.props.payload && this.props.payload.groupbyEnable
        const setFields = this.props.payload && this.props.payload.setFields || this.state.setFields
        const filter_list = this.props.payload && this.props.payload.filter_list || this.state.filter_list
        this.setState({
            collection: collection,
            setFields: setFields,
            limit: limit,
            tabledata: tabledata,
            whereCondition: whereCondition,
            condition: condition,
            groupbyEnable: groupbyEnable,
            filter_list: filter_list
        })
        // this.setState({ownFilterList:ownFilterList,rangeColor:rangeColor, colorAxis : colorAxis, tabledata:tabledata,strokecolor:strokecolor,fillcolor:fillcolor,basedon:basedon,bubblecolor:bubblecolor,items:tabledata,condition:condition,whereCondition:whereCondition, limit : limit})
        if (collection !== '' || collection !== undefined) {
            let collections = collection
            let bubbleLabel = ""
            let groupby = '';
            let groupbyLabel = "";
            let components = tabledata;
            if (tabledata.length > 0) {
                bubbleLabel = tabledata[0].label;
                if (groupbyEnable) {
                    groupbyLabel = tabledata[0].label;
                    groupby = tabledata[0].field;
                    components = tabledata.slice(1, tabledata.length);
                }
            }

            let filters = this.state.filters || [];
            let numberfilters = [];
            let datefilters = [];
            if (whereCondition.length > 0) {
                for (var i = 0; i < whereCondition.length; i++) {
                    let conditionEntry = whereCondition[i];
                    if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                        let conditionValue = '';
                        if (conditionEntry.type === 'text') {
                            conditionValue = conditionEntry.text;
                        } else if (conditionEntry.type === 'conditions') {
                            conditionValue = conditionEntry.conditions;
                        }
                        let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];

                        if (conditionEntry.type === 'datepicker') {
                            let datefilter = {
                                "id": conditionEntry.field,
                                "from": conditionEntry.from,
                                "to": conditionEntry.to
                            }
                            datefilters.push(datefilter)
                        } else if (conditionEntry.type === 'number') {
                            let numberfilter = {
                                "id": conditionEntry.field,
                                "min": conditionEntry.min,
                                "max": conditionEntry.max
                            }
                            numberfilters.push(numberfilter)
                        } else {
                            if (conditionArr.length > 0) {
                                let filter = {
                                    "id": conditionEntry.field,
                                    "value": conditionArr
                                }
                                filters.push(filter);
                            }

                        }

                    }

                }
            }
            let sortCondition = [];
            if (condition.length > 0) {
                for (var j = 0; j < condition.length; j++) {
                    if (condition[j].field && condition[j].field !== '' && condition[j].sort && condition[j].sort !== ''
                        && condition[j].type && condition[j].type !== '') {
                        sortCondition.push(condition[j]);
                    }
                }
            }

            this.setState({cId: collections})

            var linkedfrom = '';
            var data = {
                collectionid: collections,
                "index": ES_INDEX,
                "graph": true,
                "fieldid": "0",
                "components": components,
                "condition": sortCondition,
                "filters": filters,
                "numberfilters": numberfilters,
                "datefilters": datefilters,
                "size": limit,
                "linkedids": this.props.linkedId.param,
                "linkedfrom": linkedfrom,
                "allfields": true,
                "facetrequired": true
            }
            if (groupbyEnable) {
                data.groupbyEnable = groupbyEnable;
                data.groupby = groupby;
                data.groupbyLabel = groupbyLabel;
            }
        }


        if ((collection !== '' && collection !== undefined) || getUrlSegment(1) === "collections") {
            let collections = (getUrlSegment(1) === "collections") ? atob(getUrlSegment(4)) : collection
            this.setState({cId: collections})
            //  this.props.showform({ _id : collection});

            let parentEntityId = (getUrlSegment(1) === "entity") ? atob(getUrlSegment(3)) : '';
            if (parentEntityId === collections) {
                data.linkedfrom = "same";
            }


            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + collections + "&index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                obj.setState({showlistField: res.data.data});
                return res;
            }).catch(function (err) {
                return err;
            });


            axios({
                method: "POST",
                url: API_PATH + "collection/" + collection + "/collectedinfo?index=" + ES_INDEX + "&t=122212",
                async: false,
                data: data,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                let from = 0
                let results = (res.data.results)
                var totalchilds = results.totalchilds
                var collectionData = [];
                var collectionIDs = [];
                var collectionFilter = obj.state.collectionFilter || [];
                var selectedCollectionFilter = obj.state.selectedCollectionFilter || [];
                var cnt = 0;

                let filter_values = [];
                let filter_value_arr = [];

                if (results.data.length > 0) {
                    results.data.map((entry, index) => {
                        collectionData[index] = {};
                        collectionData[index]['id'] = entry.id;
                        collectionData[index]['users'] = entry.users;
                        collectionIDs.push(entry.id);
                        if (entry.collectedform) {
                            entry.collectedform.map(a => {
                                //.filter((a) => a.showlist == 'Yes')
                                collectionData[index][a.masterid] = '';


                                if (a.value && a.value[0] !== undefined) {
                                    for (var t = 0; t < a.value.length; t++) {

                                        if (a.type === "attachment") {
                                            collectionData[index][a.masterid + "uniquevalue"] = (a.value && a.value[t] !== undefined) ? a.value[t]['uniqvalue'] : '';
                                            collectionData[index][a.masterid] += (a.value && a.value[t] !== undefined) ? a.value[t]['uniqvalue'] : '';
                                            collectionData[index][a.masterid + "filename"] = (a.value && a.value[t] !== undefined) ? a.value[t]['value'] : '';
                                        } else {
                                            collectionData[index][a.masterid] += (a.value && a.value[t] !== undefined) ? a.value[t]['value'] : '';
                                        }
                                        if (t < a.value.length - 1) {
                                            collectionData[index][a.masterid] += ", ";
                                        }

                                    }

                                    collectionData[index][a.masterid] = collectionData[index][a.masterid].replace(/(^,)|(,$)/g, "");
                                }

                                if (filter_list.includes(a.masterid.toString())) {
                                    filter_values[a.masterid] = filter_values[a.masterid] || [];
                                    filter_value_arr[a.masterid] = filter_value_arr[a.masterid] || [];
                                    if (!filter_value_arr[a.masterid].includes(collectionData[index][a.masterid])) {
                                        filter_values[a.masterid].push({"label": collectionData[index][a.masterid]});
                                        filter_value_arr[a.masterid].push(collectionData[index][a.masterid]);
                                    }
                                }

                                /*collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['value'] : '';
                                if (a.type === "attachment") {
                                    collectionData[index][a.masterid + "uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                                    collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                                    collectionData[index][a.masterid + "filename"] = (a.value && a.value[0] !== undefined) ? a.value[0]['value'] : '';

                                }*/

                                // if (a.searchable === 'Yes') {
                                //     if (collectionFilter[a.label] === undefined) {
                                //         collectionFilter[a.label] = [];
                                //     }
                                //     cnt = collectionFilter[a.label].length;
                                //     collectionFilter[a.label][cnt] = a.value[0]['value'];
                                // }
                            });
                        }
                    });
                }

                if (results.facet.length > 0) {
                    if (obj.state.filterApplied) {
                        //collectionFilter = obj.state.collectionFilter;
                        selectedCollectionFilter = obj.state.selectedCollectionFilter;
                        //console.log(collectionFilter)
                    } else {
                        collectionFilter = results.facet;

                        let facets = results.facet.filter(fc => filter_list.includes(fc.masterid.toString()));
                        for (var fc = 0; fc < facets.length; fc++) {
                            facets[fc].filter_fields = filter_values[facets[fc].masterid]

                        }
                        selectedCollectionFilter = facets;
                        // console.log(facets);
                    }

                } else {
                    collectionFilter = [];
                    selectedCollectionFilter = [];
                }
                if (from === 0) {

                    obj.setState({
                        collectionData: collectionData,
                        totalchilds: totalchilds,
                        collectionFilter: collectionFilter,
                        selectedCollectionFilter: selectedCollectionFilter
                    });
                } else {
                    collectionData = obj.state.collectionData.concat(collectionData)
                    obj.setState({
                        collectionData: collectionData,
                        collectionFilter: collectionFilter,
                        selectedCollectionFilter: selectedCollectionFilter
                    });
                }
                return res;

            }).catch(function (err) {
                return err;
            });
        }

    }

    handleChange = (e) => {
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    chartSaving = () => {
        var prjctdtlid = atob(getUrlSegment(4));
        let sharetype = getUrlSegment(1);
        let uid = '';
        if (sharetype === 'public' || sharetype === 'state') {
            uid = 2;
        } else {
            uid = getProfile().id;
        }
        let param = {
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard": state.getStorableState()
            ,
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus: false})
    }
    checkBoxchange = (e) => {
        let val;
        if (e.target.value === "false") {
            val = true
        } else {
            val = false
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    filterCheckBoxChange = (e) => {
        let filter_list = this.state.filter_list;
        if (e.target.checked) {
            filter_list.push(e.target.value)
        } else {
            let ind = filter_list.indexOf(e.target.value);
            filter_list.splice(ind, 1);
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = filter_list;
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }


    /*  drag and drop functions for hierarchial table */

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    };
    addData = (Id, updateParam) => {
        let data = this.state[updateParam]
        let newData = {
            id: "item-" + new Date().getTime(),
            label: "item "
        }
        let _data = data.concat(newData)
        this.setState({[updateParam]: _data})
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[updateParam] = _data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    removeData = (dragid, updateParam) => {
        let data = this.state[updateParam]
        let _data = data.filter(item => item.id !== dragid)
        this.setState({[updateParam]: _data})
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[updateParam] = _data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }

    handleLabelChange = (e, dragid, whereCondition, type) => {

        var name = ''
        var fType = ''
        if (e.target.name === "masterid") {
            var data = e.target.value.split("|");
            name = data[0]
            fType = data[1]
        } else {
            name = e.target.name
        }

        let sel = e.target
        var updatedParam = whereCondition;
        var _newState = this.state[updatedParam];
        var index = (_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name] = name;
        _newState[index]["label"] = sel.options[sel.selectedIndex].text === "Please Select" ? "" : sel.options[sel.selectedIndex].text;
        if (e.target.name === "masterid") {
            _newState[index]["type"] = fType;
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[whereCondition] = this.state[updatedParam]
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    handleConditionLabelChange = (e, dragid, whereCondition, type) => {
        var updatedParam = "items";
        if (whereCondition === "graph") {
            updatedParam = "items";
        } else {
            updatedParam = whereCondition;
        }

        var _newState = this.state[updatedParam];
        var index = (_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name] = e.target.value;
        if ((whereCondition === "whereCondition" || whereCondition === "globalFilter" || whereCondition === "ownFilterList") && type !== "field") {
            _newState[index]["type"] = type;
        }

        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[whereCondition] = this.state[updatedParam]
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }


    handleDefaultField = (e, dragid, updatedParam, type) => {
        var _newState = this.state[updatedParam];
        var index = (_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name] = e.target.value;
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[updatedParam] = this.state[updatedParam]
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }

    radioBoxchange = (e) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    handleConditionChange = (e) => {
        var _newState = this.state.condition;
        if (e.target.name === 'field') {
            let t = e.target.value.split("|");
            _newState[0]["field"] = t[0];
            _newState[0]["type"] = t[1] === "datepicker" ? "date" : t[1];
        } else {
            _newState[0][e.target.name] = e.target.value;
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["condition"] = this.state.condition
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    colorChange = (color, param, label) => {
        var _newState = this.state[label];
        var index = (_newState.findIndex(obj => obj["id"] === param))
        _newState[index]["color"] = color;
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["bubblecolor"] = this.state.bubblecolor
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    handleColorChange = (color, param) => {
        var _newState = this.state.items;
        var index = (_newState.findIndex(obj => obj["id"] === param))
        _newState[index]["color"] = color;
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["graph"] = this.state.items
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    handleClick = (status) => {
        this.setState({colorpickerStatus: status})
    }

    editRecord = (col, masterids, type, mId) => {
        this.props.getselectedcollections({_id: mId, collectionid: col, metainfo: true});

        this.props.addRecord(col, masterids, type, mId)
    }

    applyFilter = (e, fieldId) => {
        let filterIds = this.state.filterIds;

        let filters = [];
        if (e.target.value !== '') {
            filters.push({id: fieldId, value: [e.target.value]})
            filterIds.push(fieldId)
        } else {
            let remIndex = filterIds.indexOf(fieldId);
            filterIds.splice(remIndex, 0);

        }
        this.setState({
            filters: filters,
            filterApplied: true
        }, function () {
            this.graphData();

        })

    }

    render() {
        const {
            id,
            showBasicContent, showPreview,
            dropzoneID, payload, parentID, name
        } = this.props;
        let sourceType = (payload && payload.sourceType) || false
        let showlistField = []
        let sourceData = []
        if (this.state.showlistField.length !== 0) {
            for (let i = 0; i < this.state.showlistField.pages.length; i++) {
                (this.state.showlistField.pages[i].components).filter(item => item.type !== 'fieldset' && item.type !== 'columnbreak').map(list => {
                    showlistField.push(list)
                })
            }
        }
        if (sourceType.toString() === "true") {
            sourceData = this.props.listAllCollections.ListAllCollections.data
        } else {
            sourceData = this.props.EntityData.Entity.data
        }

        let formFields = this.props.getFormdetails.length !== 0 ? this.props.getFormdetails.formvalues : [];
        //console.log(formFields)

        let defaultfields = formFields.filter(item => item.type !== 'fieldset' && item.type !== 'columnbreak');
        let childFields = [];
        // console.log(defaultfields)
        let mergedFields = [];
        if (formFields.length > 0) {
            childFields = formFields.filter(item => item.type === 'fieldset' && item.type === 'columnbreak').map(item => {
                return item.values;
            });
            //console.log(defaultfields)
            childFields = [].concat.apply([], childFields);
        }
        mergedFields = defaultfields.concat(childFields);
        // console.log(defaultfields)
        // console.log(mergedFields)

        let containerData = {
            chartTitle: this.state.chartTitle || payload && payload.chartTitle,
            accessbox: true,
            filterbox: true,
            add: this.state.add || payload && payload.add || false,
            edit: this.state.edit || payload && payload.edit || false,
            // subTitle :  this.state.subTitle || payload && payload.subTitle,
            //subTitleEnabled : this.state.subTitleEnabled || payload && payload.subTitleEnabled,
            data: this.state.collectionData || [],
            collection: this.state.collection || payload && payload.collection,
            condition: this.props.payload && this.props.payload.condition || this.state.condition,
            showlistField: showlistField,
            collectionData: sourceData,
            collectionFilter: this.state.collectionFilter,
            selectedCollectionFilter: this.state.selectedCollectionFilter,
            groupby: this.state.groupby || payload && payload.groupby,
            groupbyEnable: this.state.groupbyEnable || payload && payload.groupbyEnable,
            whereCondition: this.state.whereCondition || payload && payload.whereCondition,
            limit: this.state.limit || payload && payload.limit,
            sourceType: sourceType,
            setFields: this.state.setFields || payload && payload.setFields,
            display: true,
            defaultfields: mergedFields,
            filter_list: this.state.filter_list || payload && payload.filter_list

        }
        if (showBasicContent) {
            return (
                <Draggable {...this.props} >
                    {
                        <span className="drag-icon">{name}</span>
                    }
                </Draggable>
            )
        }

        if (showPreview) {
            return (
                <div className="each-builder-row">
                    <div class="each-growth-block txt-block margin-0">
                        <div class="growth-boiler">
                            <div className="growth-boiler-details-header body-pointer">
                                <div>
                                    <h5>{containerData.chartTitle}</h5>
                                    <div className="ge-filter">
                                        {containerData.selectedCollectionFilter && containerData.selectedCollectionFilter.map((cf, ind) =>


                                            <div className="select-box">
                                                <select name={`filter-${containerData.collection}`}
                                                        onChange={(e) => this.applyFilter(e, cf.masterid)}>
                                                    <option value="">{cf.title}</option>
                                                    {cf.filter_fields.map((opt, i) =>
                                                        <option value={opt.label}>{opt.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {containerData.add &&
                                <nav className="ico-lib-v1 add-block"
                                     onClick={() => this.props.addRecord(this.state.cId, this.state.setFields, "add")}>Add </nav>
                                }
                            </div>

                            <TableBlock
                                containerData={containerData}
                                showlistField={this.state.setFields}
                                addRecord={this.editRecord}
                                collId={this.state.cId}
                            />
                        </div>
                    </div>
                </div>


            );
        }
        return (
            <React.Fragment>

                <Draggable {...this.props}
                           draggable={!this.props.DragDisabled.dragStatus}
                >
                    <SettingsPanel
                        id={id}
                        name={containerData.chartTitle ? containerData.chartTitle : name}
                        dropzoneID={dropzoneID}
                        parentID={parentID}
                        openSetting={this.openSetting}
                        removeElement={<div className="delete-icon"
                                            onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
                    />
                </Draggable>
                {this.state.modalStatus &&
                <EntityModal
                    title={`Table Block  -  Settings`}
                    containerData={containerData}
                    colorpickerStatus={this.state.colorpickerStatus}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    handleChange={this.handleChange}
                    checkBoxchange={this.checkBoxchange}
                    radioBoxchange={this.radioBoxchange}
                    handleConditionChange={this.handleConditionChange}
                    handleLabelChange={this.handleLabelChange}
                    handleConditionLabelChange={this.handleConditionLabelChange}
                    handleDefaultField={this.handleDefaultField}
                    handleBubblecolorChange={this.colorChange}
                    handleColorChange={this.handleColorChange}
                    handleInputChange={this.handleInputChange}
                    handleClick={this.handleClick}
                    addData={this.addData}
                    removeData={this.removeData}
                    onDragEnd={this.onDragEnd}
                    filterCheckBoxChange={this.filterCheckBoxChange}
                />
                }


            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    showFormModal: state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange: state.DynamicDashboard.getDashboard,
    DragDisabled: state.DynamicDashboard.getDragDisabled,
    EntityData: state.EntityReducer.GetEntity,
    filterCnt: state.DynamicDashboard.getFilterCnt,
    linkedId: state.EntityReducer.LinkedId,
    getsyncRec: state.DynamicDashboard.getsyncRec,
    getFormdetails: state.CollectionsReducer.getFormData,
});
export default withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform: collectionActions.showform,
    chartSaving: DynamicDashboard.saveDashboarddata,
    dragStatus: DynamicDashboard.dragStatus,
    addRecord: DynamicDashboard.addRecord,
    getselectedcollections: formsActions.getFormData,

})(TableblockContainer));
