import React, {Component} from 'react';
import {Card,Tooltip,OverlayTrigger, Button} from "react-bootstrap"
class SimpleModal extends Component {

    constructor(props) {
        super(props);
        this.Minimize=this.Minimize.bind(this)
        this.Maximise=this.Maximise.bind(this)
        this.Close=this.Close.bind(this)
        this.state={
            miniMize:false,
            Maximise:false,
            Close:false,
            showSave : true,
            extraClass : ''

        }
      }



    componentDidMount() {
        var disPop = document.getElementById("dis-pop");
        if (disPop.addEventListener) {
            disPop.addEventListener('contextmenu', function(e) {
                e.preventDefault();
            }, false);
        } else {
            disPop.attachEvent('oncontextmenu', function() {
                window.event.returnValue = false;
            });
        }
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    Minimize(){
        this.setState({Maximise:false,miniMize:true})
    }
    Maximise(){
        if(!this.state.miniMize) {
            this.setState({Maximise: !this.state.Maximise, miniMize: false})
        }
        else{
            this.setState({Maximise: false, miniMize: false})
        }
    }
    Close(){

    }


    render() {

        let mainClass= (this.props.modalType && this.props.modalType === 'custom-date-range') ? ` date-mod ` : ((this.props.modalType && this.props.modalType === 'dataload') ? 'lg-mod ' : '');
        let modalSize = (this.props.modalType && this.props.modalType === 'custom-date-range') ? `modal-sm` : 'modal-lg';
        let extraClass = this.props.extraClass ? this.props.extraClass : '';
        let zIndex = this.props.zIndex ? this.props.zIndex : '';

        return (

             <div id={`dis-pop`} className={`cs-modal fade bsy-modal ${mainClass}  ${extraClass} in show `} >
                <div className={"modal-dialog modal-dialog-centered "+ modalSize}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="task-head-text">{this.props.title}</h5>
                            <div className="modal-header-options">
                                <button className="modal-option-button"  onClick={this.props.Close}>
                                    <span className="icon-in icon-close-in"></span>
                                </button>
                            </div>
                        </div>

                        <div className="modal-body">
                            {this.props.children}
                        </div>
                        {this.props.haveFooter && this.props.haveFooter === 1 &&
                        <div className="modal-footer">
                            <Button className="general-btn grey" onClick={this.props.cancelAction}>{this.props.cancelLabel || 'Cancel'}</Button>
                            {this.props.OKLabel &&
                            <Button type="submit" className="general-btn"
                                    onClick={this.props.OKAction}>{this.props.OKLabel || 'OK'}</Button>
                            }
                        </div>
                        }
                    </div>
                </div>
             </div>

        );
    }
}



export default SimpleModal;


