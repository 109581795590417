import {phases} from '../services/phases.services'
import {getProfile} from "../utilities/AuthService";
import {programs} from "../services/programs.services";



export const listallphases= (data) => dispatch => {
    let listallphases = phases.listphases(data);
    dispatch({
        type: 'PHASELIST_LOADER',
        loading:true,

    });
    listallphases.then(listallphases => {
        dispatch({
            type: 'LIST_All_PHASE',
            listallphases: listallphases,
            clearList : data.clearList

        })
        dispatch({
            type: 'PHASELIST_LOADER',
            loading:false,

        });
    })

};
export const updatephaseslist= (data) => dispatch => {
    let listmorephases = phases.listphases(data);
    // dispatch({
    //     type: 'UPDATE_PHASE_LIST_LOADER',
    //     loading:true,
    //
    // });
    listmorephases.then(listmorephases => {
        dispatch({
            type: 'UPDATE_PHASE_LIST',
            listmorephases: listmorephases,

        })
        // dispatch({
        //     type: 'UPDATE_PHASE_LIST_LOADER',
        //     loading:false,
        //
        // });
    })

};
export const getphasedetails= (data) => dispatch => {
    let phasedetails = phases.listphases(data);
    dispatch({
        type: 'GET_PHASEDET_LOADER',
        loading:true,

    });
    phasedetails.then(phasedetails => {
        dispatch({
            type: 'GET_PHASE_DET',
            phaseDet: phasedetails,


        })
        dispatch({
            type: 'GET_PHASEDET_LOADER',
            loading:false,

        });
    })

};
export const SavePhase= (params) => dispatch => {
    dispatch({
        type: 'PHASESAVE_LOADER',
        loading:true,

    });
    let phaseSaved = phases.SavePhases(params);

    /*let newPhase = {
        "phaseId" : params._id,
        "phaseName": params.phaseName,
        "description": btoa(params.description),
        "uid": getProfile().id,
        "mode": params.mode
    };*/


    phaseSaved.then(phaseSaved => {

        dispatch({
            type: 'UPDATE_PHASE',
            phaseSaved: phaseSaved

        })
        dispatch({
            type: 'PHASESAVE_LOADER',
            loading:false,

        });
    })
};
export const listprojectphases= (data) => dispatch => {
    let listprojectphase = phases.listprojectphase(data);
    dispatch({
        type: 'PROJECTPHASELIST_LOADER',
        loading:true,

    });
    listprojectphase.then(listprojectphase => {
        dispatch({
            type: 'LIST_All_PROJECTPHASE',
            listprojectphase: listprojectphase,

        })
        dispatch({
            type: 'PROJECTPHASELIST_LOADER',
            loading:false,

        });
    })

};
export const listphasesmilestone = (data) => dispatch => {
    let listphasemilestone = phases.listphasemilestone(data);
    dispatch({
        type: 'PROJECTPHASEMILESTONELIST_LOADER',
        loading:true,

    });
    listphasemilestone.then(listphasemilestone => {
        dispatch({
            type: 'LIST_All_PROJECTPHASEMILESTONELIST',
            listphasemilestone: listphasemilestone,

        })
        dispatch({
            type: 'PROJECTPHASEMILESTONELIST_LOADER',
            loading:false,

        });
    })

};
export const deletePhase = (params) => dispatch => {

    let phasedeleted = phases.DeletePhases(params);


    phasedeleted.then(phasedeleted => {

        dispatch({
            type: 'DELETE_PHASE',
        })
    })
};

