import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal";
import AddNewPhase from "../phase/AddNewPhase";
import * as roleActions from "../../../actions/Role";
import * as usersActions from "../../../actions/Users";
import ProjectHeader from "../../views/ProjectHeader";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import OutsideAlerter from "../../views/OutsideAlerter";
import {TimeLogForm, TimeLogList} from "../task/TaskDetailDataNew";
import UploadFile from "../../../utilities/Upload/UploadFile";
import AutoComplete from "../../views/AutoComplete";
import NoData from "../../views/NoData";

class ProjectSettings extends Component {
    constructor(props) {
        super(props);
        this.state={
            expandHeader:false,
            tabState:'assignroles',
            showAssignModal:false,
            selectedRoles:[],
            roleError:false,
            loading:0,
            userroles:[],
            userRoles:[]
        }
        this.expandHeader = this.expandHeader.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.showAssignModal = this.showAssignModal.bind(this);
        this.closeAssignModal = this.closeAssignModal.bind(this);
        this.Roleslist = this.Roleslist.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.assignProjectRoles=this.assignProjectRoles.bind(this);
        this.assignUserRoles = this.assignUserRoles.bind(this);
        this.handlePermission = this.handlePermission.bind(this);
    }

    componentDidMount() {
        var projectId = getUrlSegment(4);
        this.props.mainClick('project','/project/details/settings/'+projectId);
        var roleparams={
            "projectid":atob(projectId)
        }
        this.props.getprojectroles(roleparams);
        var assignedroleparams={
            "projectid":atob(projectId)
        }
        this.props.getusersrolelist(assignedroleparams)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.roleAssignedstatus.roleassigned !== this.props.roleAssignedstatus.roleassigned){
            var roleparams={
                "projectid":atob(getUrlSegment(4))
            }
            var beta = this;
            setTimeout(function () {
                beta.props.getprojectroles(roleparams);
                beta.closeAssignModal();
                beta.setState({loading: 0});
            }, 1000)

        }
        if(prevProps.assignedUserRoles.usersrolelistdetails  !== this.props.assignedUserRoles.usersrolelistdetails ){
            this.setState({userRoles:this.props.assignedUserRoles.usersrolelistdetails  || []})
        }

        if(prevProps.userRolesStatus.userroleupdated !== this.props.userRolesStatus.userroleupdated || prevProps.roleAssignedstatus.roleassigned !== this.props.roleAssignedstatus.roleassigned){
            var assignedroleparams={
                "projectid":atob(getUrlSegment(4))
            }
            var beta = this;
            setTimeout(function () {
                beta.props.getusersrolelist(assignedroleparams);
                beta.setState({loading: 0});
            }, 1000)
        }
    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }

    changeTab(e, item) {
        this.setState({tabState : item});
    }

    showAssignModal(){
        this.setState({showAssignModal:true})
        this.Roleslist();
    }

    closeAssignModal(){
        this.setState({showAssignModal:false,selectedRoles:[]})
    }

    Roleslist(searchText){
        var projectId = atob(getUrlSegment(4));
        var params={
            "projectid":projectId,
            "search":searchText?searchText:''
        }
        this.props.getrolestobeassigned(params);
    }

    resultDataList( result, reference) {
        //console.log(result);
        this.setState({ [reference] : result });
    }

    assignProjectRoles(e){
        e.preventDefault();

        let formValid = 0;
        var projectid = (atob(getUrlSegment(4)));
        var selectedRoles = this.state.selectedRoles;
        var Roles = [];
        var Rolenames = [];
        if (selectedRoles.length  === 0) {
            formValid++;
            this.setState({roleError:true})
        }
        else{
            this.setState({roleError:false})
        }

        if(formValid === 0) {

            this.setState({loading: 1});
            if (selectedRoles.length > 0) {

                for (var i = 0; i < selectedRoles.length; i++) {
                    if( selectedRoles[i].roleId && selectedRoles[i].roleId !== '' ) {
                        Roles.push(selectedRoles[i].roleId);
                    }else{
                        Rolenames.push(selectedRoles[i].role);
                    }
                }

            }

            let roledata = {
                "project_id": projectid,
                "roles": Roles,
                "rolename":Rolenames,
                "uid":getProfile().id
            }
            //console.log(roledata)
            this.props.assignroles(roledata)
        }
    }

    handlePermission(e,roleId,userId){
        let userroles= this.state.userRoles;
        let userData =  userroles.filter( user => user.userId === userId);
        let roles = userData[0].roles.filter( role => role.roleId === roleId);
        if(roles.status === "Yes"){
            roles.status = "No"
        }
        else{
            roles.status = "Yes"
        }
        let userRoles = [...this.state.userRoles];
        userRoles.map((item,index)=> {
                if (item.userId === userId) {
                    roles = item.roles.filter(role => role.roleId === roleId)
                    roles[0].status = roles[0].status === 'Yes' ? 'No' : 'Yes';
                }
            })
        this.setState({userRoles: userRoles});
    }

    assignUserRoles(){
        this.setState({loading:1})
        var projectid = (atob(getUrlSegment(4)));

        let userroles = [...this.state.userRoles]
        let assignedroles=[];

        userroles.map(userrole => {
            let roles = [];
            userrole.roles.map(usrrolr => {
                if(usrrolr.status === "Yes"){
                    roles.push(usrrolr.roleId);
                }

            });

            assignedroles.push({
                user_id: userrole.userId,
                roles: roles
            });

        });

        var assignparams = {
            "project_id": projectid,
            "roles": assignedroles,
        }

       this.props.assignuserroles(assignparams);
    }

    render() {

        let projectRoles = this.props.projectRolesData.projectRoles ? this.props.projectRolesData.projectRoles : [];
        let UserRoles = this.state.userRoles;
        //console.log(this.state.selectedRoles)


        return (

            <React.Fragment>
                <ProjectHeader expandHeader={this.expandHeader}/>
                    <div className="tab-holder proj-set">
                        <ul className="tab-list">
                            <li className={this.state.tabState === "assignroles" ? "tab-list-items active-in" : "tab-list-items"} onClick={(e) => this.changeTab(e, 'assignroles')}>
                                <span className="icon-in icon-statics" ></span>
                                Manage Roles
                            </li>
                            <li className={this.state.tabState === "manageroles" ? "tab-list-items active-in" : "tab-list-items"} onClick={(e) => this.changeTab(e, 'manageroles')}>
                                <span className="icon-in icon-permissions"></span>
                                Manage Users
                            </li>
                        </ul>

                        <div className="tab-in-content">
                            {this.state.loading === 1 &&
                            <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                            }
                            {this.state.tabState === "assignroles" &&
                            <div className="tab-pane">
                                <div className="tab-wrapper">
                                    <div className="project-summary-block">
                                        <div className="tab-head-in">
                                            <h5 className="tab-head-text">Project Roles</h5>
                                        </div>

                                        <div className="project-status">
                                            {this.props.projectRolesData.loading &&
                                            <span className="Loader-holder">
                                                <LoaderSvg/>
                                            </span>
                                            }
                                            {projectRoles.length === 0 &&
                                            <div className='absolute-holder'>
                                                <NoData
                                                    msg={`No Roles added yet`}

                                                />
                                            </div>
                                            }
                                            {projectRoles.map((list, index) =>
                                                <div className="project-status-block status-finish" key={index}>
                                                    <h5 className="status-text">{list.role}</h5>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Task">
                                    <button className="float-button ico-lib float-add" onClick={()=>this.showAssignModal()}></button>
                                </div>
                            </div>
                            }
                            {this.state.tabState === "manageroles" &&
                            <div className="tab-pane">
                                <div className="tab-wrapper">
                                    {projectRoles.length === 0 ?
                                        <div className='absolute-holder'>
                                            <NoData
                                                msg={`No Roles assigned yet`}

                                            />
                                        </div>
                                        :
                                        <React.Fragment>
                                            <div className="scrollable-in">
                                                <table className="permission-table table table-scrollable-in">
                                                    <thead>
                                                    <tr>
                                                        <th>
                                                            <div className="has-checkbox">
                                                                {/*<label className="checkbox-in">*/}
                                                                {/*    <input type="checkbox" className="cs-checkbox" checked={`${this.state.selectAll === 1 ? "checked" : ""}`}*/}
                                                                {/*           value="all-permission" id="all-select" onChange={this.handleSelectAll}/>*/}
                                                                {/*    <span className="cs-checkbox-pl"></span>*/}
                                                                {/*</label>*/}
                                                                <label className="checkbox-text checkbox-text-b"
                                                                       htmlFor="all-select">User
                                                                    Roles</label>
                                                            </div>
                                                        </th>
                                                        {projectRoles.map((userinfo, index) =>
                                                            <React.Fragment key={index}>
                                                                {/*{userinfo.roles && userinfo.roles.map((userroles, index) =>*/}
                                                                <th>{userinfo.role}</th>
                                                                {/*)}*/}
                                                            </React.Fragment>
                                                        )}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {UserRoles.map((list, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="has-checkbox">
                                                                    {/*<label className="checkbox-in">*/}
                                                                    {/*    <input type="checkbox" className="cs-checkbox"*/}
                                                                    {/*           value="all-permission" onChange ={(e) => {this.handleModulePermission(e, list.roleId)}} checked={`${list.status === "Yes" ? "checked" : ""}`}/>*/}
                                                                    {/*    <span className="cs-checkbox-pl"></span>*/}
                                                                    {/*</label>*/}
                                                                    <label className="checkbox-text"
                                                                           htmlFor="dashboard">{list.firstName} {list.lastName}</label>
                                                                </div>
                                                            </td>
                                                            {list.roles && list.roles.map((roles, index) =>
                                                                <td key={index}>
                                                                    <label className="checkbox-in">
                                                                        <input type="checkbox" className="cs-checkbox"
                                                                               value=""
                                                                               checked={`${roles.status === 'Yes' ? "checked" : ""}`}
                                                                               onChange={(e) => {
                                                                                   this.handlePermission(e, roles.roleId, list.userId)
                                                                               }}/>
                                                                        <span className="cs-checkbox-pl"></span>
                                                                    </label>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    )}

                                                    </tbody>
                                                </table>
                                            </div>
                                            < button className="button submit-button" onClick={(e)=>this.assignUserRoles(e)}>Save</button>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                        {this.state.showAssignModal &&

                        <div className="modal fade bsy-modal show" id="mdone" tabIndex="-1" role="dialog"
                             aria-labelledby="mngModal"
                             style={{display: this.state.showAssignModal === true ? 'block' : 'none'}}>
                            <div className="modal-dialog  modal-dialog-centered modal-lg" role="document"
                                 id="bsynapsemodal-container">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title ico-lib">Assign Roles</h5>
                                        <button className="ico-lib-list modal-close" data-dismiss="modal"
                                                aria-label="Close" onClick={(e) => {
                                            this.closeAssignModal(e)
                                        }}></button>
                                    </div>
                                    <div className="modal-body">
                                        {this.state.loading === 1 &&
                                        <span className="Loader-holder">
                                            <LoaderSvg/>
                                        </span>
                                        }
                                        <div className="task-inp-container pj-inp-container">
                                                    <AutoComplete
                                                        fieldTitle = ' '
                                                        sourceDataList = {this.props.rolestobeassigned.rolestobeAssigned}
                                                        searchField = 'role'
                                                        showIcon={false}
                                                        showSubLabel = {false}
                                                        labelFields = 'role'
                                                        valueField ='roleId'
                                                        subLabelField = 'description'
                                                        showProfile = {true}
                                                        reference='selectedRoles'
                                                        selectedItems = {this.state.selectedRoles}
                                                        resultDataList = {this.resultDataList.bind(this)}
                                                        id="assign_roles"
                                                        addNewSuggestion={true}
                                                        instantSearch={true}
                                                        actionMethod={this.Roleslist}

                                                    />
                                            <label className = "assign-user-validation-err" style={{display: this.state.roleError === false ? 'none' : 'block' }}>Please provide select atleast one Role.</label>
                                        </div>

                                        <div className="modal-footer">
                                            <button type="button"
                                                    className={"general-btn grey "}
                                                    data-dismiss="modal" onClick={(e) => {
                                                this.closeAssignModal(e)
                                            }}>Cancel
                                            </button>
                                            <button id="add_timelog" className="general-btn" data-function="markasdone"
                                                    onClick={(e) => {this.assignProjectRoles(e)}}>Assign
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    projectRolesData:state.RoleReducer.getProjectRole,
    rolestobeassigned:state.RoleReducer.getRolestobeAssignedlist,
    roleAssignedstatus:state.RoleReducer.assignRoles,
    assignedUserRoles:state.RoleReducer.usersRolelist,
    userRolesStatus:state.RoleReducer.assignUserRolesDet

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getprojectroles : roleActions.getprojectroles,
    getrolestobeassigned : roleActions.rolestobeassigned,
    assignroles : roleActions.assignroles,
    getusersrolelist : roleActions.getusersrolelist,
    assignuserroles : roleActions.assignuserroles,
})(ProjectSettings));
