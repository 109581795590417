import React, {Component} from 'react';

class AlertModalPool extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id : 'show-modal',
            title: '',
            desc:'',
            OK_button : "show",
            CANCEL_button : "show",
            OK_button_text : 'Ok',
            OK_button_style : 'btn-success',
            CANCEL_button_text : 'Cancel',
            modalStyle : {display:'block'}
        }

    }


    componentDidMount() {
        this.setState({
            id: this.props.id?this.props.id: this.state.id ,
            title: this.props.title?this.props.title: this.state.title ,
            desc:this.props.desc?this.props.desc: this.state.desc ,
            OK_button : this.props.OK_button ? this.props.OK_button : this.state.OK_button,
            CANCEL_button : this.props.CANCEL_button ? this.props.CANCEL_button : this.state.CANCEL_button,
            OK_button_text : this.props.OK_button_text ? this.props.OK_button_text : this.state.OK_button_text,
            OK_button_style : this.props.OK_button_style ? this.props.OK_button_style : this.state.OK_button_style,
            CANCEL_button_text : this.props.CANCEL_button_text ? this.props.CANCEL_button_text : this.state.CANCEL_button_text,
            modalStyle : this.props.showModal ? {display:'block'} :  {display:'none'}
        });
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.state !== nextState || this.props !== nextProps) {
            return true;
        }
    }

    OK_action() {
        this.props.OK_action();
    }

    CANCEL_action() {
        this.props.CANCEL_action();
    }

    render() {

        return (

            <div className="bs-modal pool-modal show" id={this.state.id} style={this.state.modalStyle} >
                <div className="bs-modal-dialog">
                    <div className="bs-modal-content text-center">
                        <div className="bs-modal-title"><h3>{this.state.title}</h3></div>
                        <div className="bs-modal-body">
                            <p>{this.state.desc}</p>
                            {this.state.CANCEL_button === "show" &&
                            <button className="btn btn-default"
                                    data-dismiss="modal" onClick={()=> this.CANCEL_action()} >{this.state.CANCEL_button_text}</button>
                            }
                            {this.state.OK_button === "show" &&
                            <button data-property="collection" data-action="delete" data-target=""
                                    id="delete_collection" className={`btn btn-success ${this.state.OK_button_style}`} onClick={()=>this.OK_action()} >{this.state.OK_button_text}
                            </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );


    }
}
export default AlertModalPool;
