import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import LoaderSvg from "../../views/LoaderSVG";
import * as portfolioActions from "../../../actions/Portfolio";
import * as programActions from "../../../actions/Program";
import moment from "moment";
class AddNewVertical extends Component {
    constructor(props) {
        super(props);
        this.state={
            verticalname:'',
            verticaldescription : '',
            verticalId:'0',
            portfolioId:''

        };
        this.VerticalInputChange = this.VerticalInputChange.bind(this);
        this.createVertical =this.createVertical.bind(this)

    }

    componentDidMount() {
        this.getPortfolioList();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.programData !== this.props.programData){
            let programDetails = this.props.programData.programDet;

            if(programDetails){
                this.setState({
                    verticalId:programDetails[0].programId,
                    portfolioId:programDetails[0].portfolioId,
                    verticalname:programDetails[0].programName,
                    verticaldescription:decodeURIComponent(escape(atob(programDetails[0].description))),
                })
            }
        }

    }

    getPortfolioList() {
        var portfolioParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            per_page: '1000'
        }
        this.props.portfolio(portfolioParams);
    }

    VerticalInputChange(e){
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }

    createVertical(event){
        event.preventDefault();
        let formValid = 0;
        var verticalname = this.state.verticalname;
        var verticaldescription = this.state.verticaldescription;
        var portfolioId = this.state.portfolioId;
        var verticalId = this.state.verticalId === '0' ? moment().unix() : this.state.verticalId;
        var mode = this.state.verticalId === '0' ? 'save' : 'update';

        if (this.state.verticalname.trim() === '') {
            formValid++;
            document.getElementsByName('verticalname')[0].classList.add('val-error');
        }

        if (this.state.verticaldescription.trim() === '') {
            formValid++;
            document.getElementsByName('verticaldescription')[0].classList.add('val-error');
        }

        if (this.state.portfolioId === '') {
            formValid++;
            document.getElementsByName('portfolioId')[0].classList.add('val-error');
        }

        if(formValid === 0){
            let portfolioName = this.props.listAllPortfolioData.portfolios.data.filter(sector => sector.portfolioId === portfolioId).map(item => item.portfolioName);
            let verticaldata = {
                "_id": verticalId,
                "portfolioId": portfolioId,
                "portfolioName" : portfolioName,
                "programName": verticalname,
                "description": verticaldescription,
                "uid": getProfile().id,
                "mode": mode,
                "creator":getProfile().id
            }

            this.props.saveVertical(verticaldata);

        }
    }

    render() {
        let Portfolio = this.props.listAllPortfolioData.portfolios.data;
        console.log(Portfolio)
        return (
                <div className="task-content-block">
                    {this.props.SaveVerticalDet.loading &&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                    }
                    <div className="persitant-top">
                        <div className="task-input-block">
                            <input type="text" id="verticalname" name="verticalname" className="task-input-box--new" defaultValue={this.state.verticalname} placeholder="Name" onChange={this.VerticalInputChange} />
                        </div>
                        <div className="select-box eq-width">
                            <select id="portfolioId" name="portfolioId" value={this.state.portfolioId} onChange={this.VerticalInputChange}>
                                <option value="All">Sector</option>
                                {Portfolio.map((list,index)=>
                                        <option value={list.portfolioId} key={index}>{list.portfolioName}</option>
                                    )}
                            </select>
                        </div>
                        <div className="task-input-block text-input">
                            <textarea className="task-textarea--new" id="verticaldescription" defaultValue={this.state.verticaldescription} name="verticaldescription"
                                      placeholder="Description" onChange={this.VerticalInputChange}></textarea>
                        </div>
                    </div>
                    <div className="persitant-footer">
                        <div className="group-row">
                        </div>
                        <button data-masterid="0" data-mode="save" type="button" className="general-btn" onClick={this.createVertical}
                                id="createprogram">{this.state.verticalId =='0' ? 'Create' : 'Update'}
                        </button>
                    </div>
                </div>

        );
    }
}
const mapStateToProps = state => ({
    listAllPortfolioData:state.SectorReducer.listallPortfolio,
    SaveVerticalDet : state.VerticalReducer.SaveVertical,
    programData:state.VerticalReducer.getProgramDet,
});
export default  withRouter(connect(mapStateToProps, {
    saveVertical : programActions.saveVertical,
    portfolio:portfolioActions.listallportfolio,

})(AddNewVertical));



