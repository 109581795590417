 import React, {Component} from 'react';
 import {connect} from "react-redux";
 import {USRIMG_PATH} from '../../constants/index'
 import {withRouter} from 'react-router';
 class DashboardUser extends Component {
     constructor(props) {
         super(props);

     }

         render()
         {
             var Userdata = this.props.list;
             var creatorname=Userdata.createuser_name;
             var creatorname=creatorname.split(" ");
             return (
                 <div className="task-user-block">
                     <span className="task-user">{creatorname[0]}</span>
                     <div className="user-popover-block">
                         <div className="user-popover-left">
                             <div className="user-popover-img online">
                                 <img src={Userdata.createuser_img_name === '' ? USRIMG_PATH + 'person_avatar.gif' : USRIMG_PATH + Userdata.createuser_img_name }/>
                             </div>
                             <div className="user-popover-btn-block">
                                 <button className="commun-button ico-lib chat-ico">Chat
                                 </button>
                             </div>
                         </div>
                         <div className="user-popover-right">
                             <div className="user-profile-details">
                                 <h4 className="subheader">{Userdata.createuser_name}</h4>
                                 <ul className="user-profile-det-list">
                                     <li className="ico-lib prof-user-ico">{Userdata.createuser_designation}</li>
                                     <li className="ico-lib mail-ico">{Userdata.createuser_email}</li>
                                     <li className="ico-lib phone-ico">{Userdata.createuser_mob}</li>
                                     <li className="ico-lib loc-ico">Kochi, India</li>
                                 </ul>
                             </div>
                         </div>
                     </div>
                 </div>

             );
         }



 }
 export default  withRouter(connect(null, {

 })(DashboardUser));
