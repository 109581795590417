import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getProfile} from "../../utilities/AuthService";
import * as commonActions from "../../actions/Common";
import * as dashboardActions from "../../actions/Dashboard";
import rootActions from "../../actions";
class Selectbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: ''
        }

    }
    handleChange(e) {
        this.setState({selected: e.target.value});
        this.props.handleSelect(e.target.value);
    }
    componentDidMount() {
        if(this.props.initialValue){
            this.setState({selected: this.props.initialValue})
        }
    }

    render() {
        return(
            <div className="select-box">
                <select onChange={(e)=> this.handleChange(e)} value={this.state.selected}>
                    {this.props.dropDown.map((item,index) =>
                        <option value={item.value} key={index}>{item.label}</option>
                    )}
                </select>
            </div>
        );
    }
}

export default Selectbox;
