import React from 'react';
import axios from 'axios';
import { FormGroup, Label, Input, Col, Row } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { Draggable, state } from 'react-page-maker';
import PieChartTwoLevel from "./views/charts/PieChartTwoLevel";
class PieChartTwoLevelContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      id,
      showBasicContent, showPreview,
      dropzoneID, parentID, name
    } = this.props;
    if(showBasicContent) {
      return (
          <Draggable {...this.props} >
            {
              <span>{name}</span>
            }
          </Draggable>
      )
    }

    if(showPreview) {

      return (
          <PieChartTwoLevel/>
      );
    }
    return (
        <Draggable {...this.props} >
          <FormGroup className="m-0">
            <label className="col-sm-12">
              <span>{name}</span>
              <FaTrash
                  className="pull-right"
                  color="#dc3545"
                  onClick={() => state.removeElement(id, dropzoneID, parentID)}
              />
              <FiSettings
                  className="pull-right"
                  color="#dc3545"
                  onClick={() => state.removeElement(id, dropzoneID, parentID)}
              />
              {/*<span>Collections</span>*/}
              {/*<Input type="select">*/}
              {/*  <option>1</option>*/}
              {/*  <option>2</option>*/}
              {/*  <option>3</option>*/}
              {/*</Input>*/}
              {/*<span>Fields</span>*/}
              {/*<Input type="select">*/}
              {/*  <option>1</option>*/}
              {/*  <option>2</option>*/}
              {/*  <option>3</option>*/}
              {/*</Input>*/}
            </label>
          </FormGroup>
        </Draggable>
    )
  };
}

export default PieChartTwoLevelContainer;