import React, {Component, useState } from 'react';
import 'react-selectize/themes/index.css'
import * as CustomFunctions from "../../../utilities/CustomFunctions";
import moment from 'moment';
import {USRIMG_PATH, FRMSIMG_PATH, FRMSVID_PATH, FRMSDOC_PATH, S3_URL} from "../../../constants";
import {forceDownload} from "../../../utilities/CustomFunctions";
import DatePickerBox from "../../views/DatePickerBox";
import {timeConvert,formatDate} from "../../../utilities/CustomFunctions";
import {getProfile} from "../../../utilities/AuthService";
import {showLocalTime} from "../../../utilities/CustomFunctions";
import OutsideAlerter from "../../views/OutsideAlerter";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import sidebarLoader from "../images/sidebar-loader.svg";
import {getFileIcon,getAttachmentPath} from "../../../utilities/CustomFunctions";
import LoaderSvg from "../../views/LoaderSVG";
import Coverimage from "../../../images/placeholders/Coverimage.jpg";
import {isImage,getFileExtension,isVideo,formatBytes} from "../../../utilities/CustomFunctions";
import {isAudio} from "../../../utilities/CustomFunctions";
import {previewModalFormat} from "../../../utilities/CustomFunctions";
import {getFilePath} from "../../../utilities/CustomFunctions";
import DateTimePickerBox from "../../views/DateTimePickerBox";
import {getUrlSegment} from "../../../utilities/CustomFunctions";

function HeaderBox(Props) {

    return(

        <React.Fragment>

            <div className="task-details-header-block">
                <div className="task-detail-header-laeft">
                    <button className="task-mob-back" onClick={Props.goBack}></button>
                    <ul className="task-details-info-wrap">
                        <li>
                            {Props.list.createuser === getProfile().id && Props.list.status !== "Done" &&
                                <p>DUE ON </p>
                            }

                            <h5>{Props.list.duedate !== "0000-00-00 00:00:00" ? moment.utc(Props.list.duedate).local().format('DD MMM YYYY hh:mm a').toUpperCase() : ''}
                                {Props.dateChoosen === false && Props.list.createuser === getProfile().id && Props.list.status !== "Done" &&
                                <OverlayTrigger
                                    key={ `top`}
                                    placement={ `right` }
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Reschedule this task
                                        </Tooltip>
                                    }
                                >
                                <button className="ico-lib edit-time-ico" onClick={Props.dateSwap}></button>
                                </OverlayTrigger>
                                }


                            </h5>

                            {/*{Props.dateChoosen === true &&*/}
                            {/*<p><span><DateTimePickerBox handleDateChange={Props.handleDateChange}  placeholderText="Due date" id="duedate" fromTaskDetail={true} startDate = {Props.duedate ? Props.duedate : new Date() } updatetask = {Props.updatetask}/></span></p>*/}
                            {/*}*/}
                        </li>

                        <li>
                            <p>PRIORITY</p>
                            <h5>{Props.list.priority.toUpperCase()}</h5>
                        </li>
                        <li>
                            {Props.list.projectname !== '' &&
                                <>
                                <p>PROJECT</p>
                                <h5>{Props.list.projectname.toUpperCase()}</h5>
                                </>
                            }
                        </li>
                    </ul>
                </div>
                <div className="task-detail-header-right">


                    {Props.state.verified === "yes" &&
                    <div className="task-status-ifo done">
                        <p>Verified On &nbsp; <span className="font-bold">{moment.utc(Props.data.verifieddate).local().format('DD MMM YYYY hh:mm a')}</span></p>
                    </div>
                    }

                    {Props.taskoptionbutton}

                </div>
            </div>


        </React.Fragment>
    )

}
function TaskDetailUserInfo(Props) {
    return (
      <React.Fragment>
          <div className="task-detail-user-info-left">
              <div className="task-detail-user">
                  <div className={`task-detail-user-thumb ${Props.OnlineUsers(Props.list.createuser)?'online':''}`}>
                      <img src={USRIMG_PATH+Props.list.createuser_img_name} />
                  </div>
                  <div className="task-detail-user-det">
                      <h5>{Props.list.createuser_name}</h5>
                      <div className="assign-line">
                          <span className="ico-lib to-ico"></span>
                          <div className="assigned-tau-block single-user">
                              {Props.assigneesData.map((item,index) =>

                                  <React.Fragment key={`assignee-data-panel`+index}>
                                      {item.share === "no" &&
                                      <div className="tau" data-toggle="tooltip"
                                           data-placement="top"
                                           title="" data-original-title={item.user_name}
                                           key={"assig-"+index}>
                                          <OverlayTrigger
                                              key={`1`}
                                              placement={`top`}
                                              overlay={

                                                  <Tooltip id={`tooltip-top`} className={'test'}>
                                                      {item.user_name}
                                                  </Tooltip>
                                              }>
                                                                        <span className="tau-img">
                                                                            <img src={USRIMG_PATH + item.user_img_name}/>
                                                                        </span>
                                          </OverlayTrigger>
                                          <span
                                              className="tau-name">{item.user_name}</span>

                                      </div>
                                      }
                                  </React.Fragment>

                              )}
                          </div>

                      </div>
                  </div>
              </div>
          </div>
          <div className="task-detail-user-info-right">
              {/*{Props.state.assignee_status !== "Done" && Props.list.duedate !== "0000-00-00 00:00:00" &&*/}
              {Props.list.duedate !== "0000-00-00 00:00:00" &&
              <div className="due-date-block">
                  <p><span>{moment.utc(Props.list.createdate).local().format('DD MMM YYYY   |   hh:mm a')}</span></p>
              </div>
              }
          </div>
      </React.Fragment>


    )

}
function TaskDetailContent(Props) {
    return(
        <React.Fragment>
            <div className="task-detail-panel-content-area-left">
                <h5>{decodeURIComponent(escape(atob(Props.list.subject)))}</h5>
                <p dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(atob(Props.list.Title))).replace(/\n/g, '<br />')}} />
            </div>
            <div className="due-date-block">
                {Props.list.status === "Done" &&
                <React.Fragment>
                    <p>COMPLETED ON </p>
                    <p className="f-b">
                        {moment.utc(Props.list.donedate).local().format('DD MMM YYYY hh:mm a')}
                    </p>
                </React.Fragment>
                }

                {/*{remarksData.length !== '0' &&*/}
                {/*<button className="remark-btn" data-toggle="modal"*/}
                {/*        onClick={(e) => {Props.showremarksModal(remarksData, "markasdone")}}>$*/}
                {/*</button>*/}
                {/*}*/}
            </div>
        </React.Fragment>

    )

}
function TaskStatusButton(Props) {
//////////console.log(Props)
    return(


         <div className="btn-section-left">

             {Props.markasDoneTemp.task_type === '' && !Props.taskAsync.tempLoading &&
              <React.Fragment>
                 {(
                     (  /*Creator to Creator as assignee  (self task)*/
                         Props.state.tasktype === 'task' &&
                         Props.list.createuser === getProfile().id &&
                         Props.assigneestatus !== "Done" &&
                         Props.shared !== "yes" &&
                         Props.parent.status !== "Done" &&
                         (Props.craetorInassignee.length !== 0 && Props.assigneestatus !== "Done"))
                     ||

                     ( /*Creator to Assignee*/
                         Props.state.tasktype === 'task' &&
                         Props.list.createuser !== getProfile().id &&
                         Props.assigneestatus !== "Done" &&
                         Props.shared !== "yes" &&
                         Props.parent.status !== "Done" &&
                         (Props.craetorInassignee.length  !== 0 && Props.craetorInassignee[0].reassign !== "yes")
                     )

                 ) &&
                 <button className="general-btn-green ico-lib verify-button"
                         onClick={(e) => {
                             Props.showmarkasdoneModal(Props.list.masterid, Props.list.createuser)
                         }}>
                     {Props.state.tasktype === 'task' ? 'Mark As Done' : 'Mark As Read'}
                 </button>
                 }


                 {((Props.list.createuser === getProfile().id) &&
                     Props.list.verified !== "yes" &&
                     ((Props.craetorInassignee.length === 0) ||
                         (Props.craetorInassignee.length !== 0 && Props.assigneestatus === "Done") ||
                         (Props.craetorInassignee.length !== 0 && Props.shared === "yes")
                     )
                 ) &&
                             <button
                     className={`general-btn-green ico-lib verify-button ${Props.parent.status === "Done" ? "" : "disable"}`}
                     onClick={(e) => {
                         Props.list.has_parent === "yes" ? Props.showmarkasdoneModal(Props.list.masterid, Props.list.createuser, "verifyandmarkasdone") : Props.showverifyModal(Props.list.masterid, 'verify')
                     }}>
                     {Props.list.has_parent === "yes" ? 'Verify & Mark As Done' : 'Verify task'}
                 </button>
                 }

             </React.Fragment>
             }


        </div>

    )
}
function WorkTimeSection(Props) {
    //////////console.log(Props.share)
    return(
        <React.Fragment>
            {((Props.data.multiLoader.loader['time-log-data'] && Props.data.multiLoader.loader['time-log-data'] === true) && (Props.share[0] !== undefined && Props.share[0].share !== "yes")) ?
                <span className={`loader-span`}>
                  <img src={sidebarLoader}/>
                </span>
                :
                <>
                    {Props.timelogData.length > 0 &&
                    <div className="time-log-sub-list-right">
                        <p>{Props.list.status ==="Done"? "WORKING TIME":"TIME TAKEN"} <span> {timeConvert(Props.state.consolidated_time)}</span> Hrs
                        </p>
                    </div>
                    }
                    {((Props.list.createuser !== getProfile().id || Props.list.assigntype ==='self') && (Props.share[0] !== undefined && Props.share[0].share !== "yes"))&&
                    <button className="general-btn ico-lib-v1 working-time-btn" data-toggle="modal"
                            data-target="#extra-time-log" onClick={(e) => {
                        Props.showtimelogModal(Props.list.masterid)
                    }}></button>
                    }
                </>
            }
        </React.Fragment>

    )
}
function TaskDetailBox(list)  {
    return (
        <React.Fragment>
            {list.loader &&
            <span className={`loader-span`}>
                  <LoaderSvg/>
            </span>
            }
            {list.reassign &&
            <React.Fragment>
                {list.subtaskData.map((listitem, index) =>
                        <div className="subtask-detail-panel">
                            <span className="ico-lib-v1 sub-icon"></span>
                    <div className="task-detail-panel" key={`subtask`+index}>
                        <div className="task-detail-panel-content">
                            {listitem.assignees.map((key, index) =>

                                key.status === "Done" &&
                                <span className="done-mark-sm ico-lib-v1 cleared"></span>

                            )}


                            <div className="task-detail-user-info">
                                <div className="task-detail-user-info-left">
                                    <div className="task-detail-user">
                                        <div className="task-detail-user-det">
                                            <h5>{listitem.creator_name}</h5>
                                            <div className="assign-line">
                                                <span className="ico-lib-v1 to-sub-ico"></span>
                                                {listitem.assignees.map((assignee, index) =>
                                                    <AssigneeBox index={index} assignee={assignee}/>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="task-detail-user-info-right">
                                    <div className="due-date-block">
                                        <p>DUE ON</p>
                                        <p className="f-b">{moment(listitem.duedate).format('DD MMM YYYY hh:mm a')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="task-detail-panel-content-area">
                                <h5>{atob(listitem.subject)}</h5>
                                <p>{atob(listitem.title)}</p>
                            </div>
                        </div>
                        <div className="individual-assigners">
                            {listitem.assignees.map((key, index) =>
                                <div
                                    className={key.status === "Done" ? "ind-div-assign t-d-st" : "ind-div-assign t-p-st"}>
                                    <div className="info-contianer">
                                        <div className="task-detail-user-info-left">
                                            <div className="task-detail-user-thumb">
                                                <img src={USRIMG_PATH + key.assignee_img_name}/>
                                            </div>
                                            <div className="task-detail-user-det">
                                                <h5>{key.assignee_name}</h5>
                                                <p>{key.status}</p>
                                            </div>
                                        </div>
                                        {key.status === "Done" &&
                                        <div className="task-detail-user-info-right">
                                            <div className="due-date-block">
                                                <p>COMPLETED ON </p>
                                                <p className="f-b">
                                                    {moment.utc(key.donedate).local().format('DD MMM YYYY')}
                                                </p>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                        </div>
                )}
            </React.Fragment>

            }
        </React.Fragment>
    );

}
function IndividualAssigners(list){
    ////////////console.log(list.consolidated_time +'sas')
    return (

        <React.Fragment>
            { list.assigneesData.map((item,index) =>
                <React.Fragment key={`individual-assigners`+index}>
                    {item.share === "no" &&
                    <div
                        className={item.status === "Done" ? "ind-div-assign t-d-st" : (item.status === "New"?"ind-div-assign t-n-st":'ind-div-assign t-p-st')}
                        key={"assignee-"+index}>
                        {/*item.status === "Done" &&
                                            <span className="done-mark-sm ico-lib-v1 cleared"></span>
                                            */}
                        <div className="info-contianer">
                            <div className="task-detail-user-info-left">
                                <div className="task-detail-user-thumb">
                                    <img src={USRIMG_PATH + item.user_img_name}/>
                                </div>
                                <div className="task-detail-user-det">
                                    <h5>{item.user_name}</h5>
                                    {item.status === "Done" && list.markasDoneTemp.task_type === '' && !list.taskAsync.tempLoading &&
                                    <div className="due-date-block">
                                        <p>COMPLETED ON&nbsp; {list.markasDoneTemp.task_type === '' ? moment.utc(item.donedate).local().format('DD MMM YYYY hh:mm a') : ''}</p>
                                        {list.markasDoneTemp.task_type !== '' &&
                                        <p className="f-b">
                                            <img src={sidebarLoader}/>
                                        </p>
                                        }

                                        {/*{list.markasDoneTemp.task_type === '' &&*/}
                                        {/*<p className="f-b">*/}
                                        {/*    {moment.utc(item.donedate).local().format('DD MMM YYYY hh:mm a')}*/}
                                        {/*</p>*/}
                                        {/*}*/}

                                    </div>
                                    }
                                    {list.markasDoneTemp.task_type === '' && item.status !== "Done" &&
                                    <p>{item.status} </p>
                                    }
                                    {list.markasDoneTemp.task_type !== '' &&
                                    <p><img src={sidebarLoader}/></p>
                                    }

                                </div>
                            </div>
                            {item.status === "Done" && list.markasDoneTemp.task_type === '' && !list.taskAsync.tempLoading &&
                            <div className="task-detail-user-info-right jus-btn-block ">
                                {item.createuser === getProfile().id && list.verified !== "yes" && list.data.archive === "no" && list.data.assigntype !== "self" &&
                                <button className="general-btn-green ico-lib"
                                        onClick={(e) => {
                                            list.showReopenConfirm(item.childid, false,item.assignee)
                                        }}>Reopen</button>
                                }

                                {item.mark_as_done_comment.length > 0 &&
                                <button className="remark-btn" data-toggle="modal"
                                        data-target="#dollarM" onClick={(e) => {
                                    list.showremarksModal(item.mark_as_done_comment,'reopen')
                                }}>$
                                </button>
                                }
                                <div className="due-date-block">
                                    {item.status === "Done" &&
                                    <p>Time taken&nbsp;
                                        {list.markasDoneTemp.task_type === '' &&
                                        <span className="f-b">
                                          {timeConvert(item.consolidated_time)} (HR : MIN)
                                        </span>
                                        }

                                    </p>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </React.Fragment>
            )}

        </React.Fragment>
    )

}
function AttachmentPanel(list){
    if(list.attachmentData.length !== 0) {
        return (
            <div className="task-detail-panel-attachment">
                <div className="attachment-header">
                    <div className="attachment-header-left">
                        <h5>Attachment <span className="count">({list.attachmentData.length})</span></h5>
                    </div>
                    <div className="attachment-header-right">
                        {list.previewableAttachments.length > 0 &&
                        <React.Fragment>
                            <a href="javascript:;" className="download-button"
                               id="preview_All" onClick={(e) => list.showpreviewmodal(4, "all")}>Preview All</a>

                            <a href="javascript:void(0);" className="download-button preview-slash">|</a>
                        </React.Fragment>

                        }
                        <a href="javascript:void(0);" className="download-button" id="download_All"
                           onClick={(e) => list.downloadAll(e)}>Download
                            All</a>
                    </div>
                </div>
                <div className="attachment-list-wrapper">
                    <ul className="attachment-list">
                        {list.attachmentData !== undefined && list.attachmentData.map((item, index) =>
                            <AttachmentBox item={item} index={index} key={"attach-" + index} preview={list.preview} />
                        )}

                    </ul>
                </div>

            </div>
        )
    }
    return null

}
function CollectionPanel(list){
    if(list.has_collection){
        return (
            <div className="task-detail-panel-attachment white-panel">
                <div className="attachment-header">
                    <div className="attachment-header-left">
                        <h5>Forms <span className="count">({list.collectionData.length})</span></h5>
                    </div>
                </div>
                <div className="attachment-list-wrapper">
                    <ul className="attachment-list attach_forms">
                        {list.collectionData.map((data,index) => {
                                let formName = atob(data.title);
                                if(list.createUser === getProfile().id) {
                                    return( <li className="ind-forms-file" key={"collection-"+index}>
                                        <a className="forms-header ico-lib" href="javascript:void(0);" onClick={(e)=>{(data.users[0]['has_data'] === 'yes' && data.users[0]['status'] === 'done') ? list.previewForm(data.users[0]['fieldid'], data.collection) : list.showFormModal(data.collection, formName)}}>{formName}</a>
                                    </li>)
                                }
                                else {
                                    let loggedUsers = data.users.filter(user => user.assignee=== getProfile().id);
                                    return(<li className="ind-forms-file" key={"collection-"+index}>
                                        <a className="forms-header ico-lib" href="javascript:void(0);" onClick={(e)=>{(loggedUsers[0]['has_data'] === 'yes' && loggedUsers[0]['status'] === 'done') ? list.previewForm(loggedUsers[0]['fieldid'], data.collection) : list.showFormModal(data.collection, formName)}}>{formName}</a>
                                    </li>)
                                }


                            }

                        )}
                    </ul>
                </div>
            </div>
        )
    }

    else
        return null

}
function ViewParentPanel(list){
    return (
        <React.Fragment >
            {list.hasparentTask &&
              <div className="task-detail-panel-parent" align="center">
                                <span className="angle-right-ico">
                                    <a href="javascript:void(0);" data-target="parent-task"
                                       onClick={(e) => list.viewParentTask(list.parentId)}>View parent task </a>
                                </span>
            </div>
            }
        </React.Fragment>
    )
}
function TaskOptionButton(Props){
    const [status, showOptions] = useState(false);
    return(

        <div className="task-other-opt">
            <button className="ico-lib option-button" onClick={()=>showOptions(!status)}></button>
            {status &&
            <OutsideAlerter
                status={status}
                Triggered={() => showOptions(!status)}
            >

                <ul className="panel-option">

                    {Props.list.status === "Done" && Props.list.createuser === getProfile().id && Props.verified !== "yes" && Props.archived === "no" && Props.list.archive === "no" && Props.list.assigntype !== "self" &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib re-open-ico"
                           onClick={(e) => Props.showReopenConfirm(Props.list.masterid, true)}>Reopen All</a>
                    </li>
                    }

                    {Props.archived === "no" && Props.list.archive === "no" &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib archieve-ico" onClick={(e)=>Props.showConfirmArchive("archive")} >Archive</a>
                    </li>
                    }
                    {(Props.archived === "yes" || Props.list.archive === "yes") &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib archieve-ico" onClick={(e)=>Props.showConfirmArchive("unarchive")}>Restore</a>
                    </li>
                    }

                    {Props.list.verified !== "yes" &&
                    Props.assignee_status !== 'Done' &&
                    Props.archived === "no" && Props.list.archive === "no"
                    &&  (getProfile().id !== Props.creator) &&
                    Props.share[0].share !== "yes" &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib sub-task-ico" onClick={() => Props.modalOpen('subtask')}>Sub Task</a>
                    </li>
                    }
                    { Props.list.createuser === getProfile().id  && Props.list.status !== "Done" && Props.archived === "no" && Props.list.archive === "no" &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib edit-task-ico" onClick={() => Props.modalOpen('edit')}>Edit</a>
                    </li>
                    }
                    {Props.list.createuser === getProfile().id && Props.list.status === 'New' &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib delete-task-ico" onClick={() => Props.deleteTaskConfirm('delete')}>Delete</a>
                    </li>
                    }
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib print-ico" onClick={() =>window.location.href = ('/task/print/' + getUrlSegment(3) + '/' + getUrlSegment(4))} >Task Print </a>
                    </li>
                </ul>


            </OutsideAlerter>
            }
        </div>
    )


}
function NavTab(Props){
    var dataNav=[
            {
             "AcTab": "home-tab",
             "label":"Discussion",
             "icon" :"ico-lib disscussion"
            },
            {
                "AcTab": "user-tab",
                "label":"User",
                "icon" :"ico-lib user"
            },
            {
                "AcTab": "file-tab",
                "label":"Files",
                "icon" :"ico-lib file"
            },
            {
                "AcTab": "act-tab",
                "label":"Activities",
                "icon" :"ico-lib act"
            }

    ]
    return (
        <React.Fragment>
        {dataNav.map((label,index) =>
            <li className="nav-item" key={"nav_tab_"+index} onClick={(e) => {Props.getrightTabid(label.AcTab)}}>
                <a className={Props.rightTab ===  label.AcTab ? 'nav-link active': 'nav-link'} id="act-tab" data-toggle="tab" role="tab"
                   aria-controls="act" aria-selected={Props.rightTab === label.AcTab ? 'true': 'false'} >
                    <span className={label.icon}></span>
                    <span className="tab-text">{label.label}</span>
                </a>
            </li>
        )}






        </React.Fragment>
    )
}
function ActivityPanel(list){
    ////////console.log(list)
    return (
        <React.Fragment>
            {list.logData.map((item,index) => {
                var activityType=""
                var taskHighlight=""
                var taskType=""
                if(item.activity === "invited to task"){
                     activityType="newtask-assign"
                     taskHighlight="newtask-assign-highlight"
                     taskType= `invited ${item.assignees} to task`
                }
                else if(item.activity === "Marked as Done"){
                     activityType="task-markdone"
                     taskHighlight="markdone-highlight"
                    taskType= [item.activity]
                }
                else if(item.activity === "reopen the Todo"){
                    activityType="task-reopen"
                    taskHighlight="reopen-highlight"
                    taskType= [item.activity]
                }
                else{
                     activityType="newtask-assign"
                     taskHighlight="newtask-assign-highlight"
                     taskType= [item.activity]
                }


                 return(
                     <li key={'activity-panel-box' + index}>
                        <div className={`task-activities-items ${activityType}`}>
                            <p>
                                <span className={activityType}>{showLocalTime(item.crtdate)}</span>
                            </p>
                            <h5>
                                <span className="font-bold">{item.creator} </span>
                                <span className={taskHighlight}> {taskType}</span>
                            </h5>
                        </div>
                    </li>
                 )

                }

            )}
        </React.Fragment>
    )
}
function ActivityPanelPrint(list){
    let logData = list && list.logData && list.logData !== '' && list !== '' ? list.logData : '';
    ////////console.log(logData)
    return (
        <React.Fragment>
            {/*{logData !== '' && logData.map((item,index) => {*/}
            {/*        var activityType=""*/}
            {/*        var taskHighlight=""*/}
            {/*        var taskType=""*/}
            {/*        if(item.activity === "invited to task"){*/}
            {/*            activityType="newtask-assign"*/}
            {/*            taskHighlight="newtask-assign-highlight"*/}
            {/*            taskType= `invited ${item.assignees} to task`*/}
            {/*        }*/}
            {/*        else if(item.activity === "Marked as Done"){*/}
            {/*            activityType="task-markdone"*/}
            {/*            taskHighlight="markdone-highlight"*/}
            {/*            taskType= [item.activity]*/}
            {/*        }*/}
            {/*        else if(item.activity === "reopen the Todo"){*/}
            {/*            activityType="task-reopen"*/}
            {/*            taskHighlight="reopen-highlight"*/}
            {/*            taskType= [item.activity]*/}
            {/*        }*/}
            {/*        else{*/}
            {/*            activityType="newtask-assign"*/}
            {/*            taskHighlight="newtask-assign-highlight"*/}
            {/*            taskType= [item.activity]*/}
            {/*        }*/}

            {/*        return(*/}
            {/*            <li key={'activity-panel-box' + index}>*/}
            {/*                <div className={`task-activities-items ${activityType}`}>*/}
            {/*                    <p>*/}
            {/*                        <span className={activityType}>{showLocalTime(item.crtdate)}</span>*/}
            {/*                    </p>*/}
            {/*                    <h5>*/}
            {/*                        <span className="font-bold">{item.creator} </span>*/}
            {/*                        <span className={taskHighlight}> {taskType}</span>*/}
            {/*                    </h5>*/}
            {/*                </div>*/}
            {/*            </li>*/}
            {/*        )*/}

            {/*    }*/}

            {/*)}*/}
        </React.Fragment>
    )
}
function UsersPanelTab(props){
    if(props.assigneesData.length !==0 )
        return (
            <React.Fragment>
                <div className="user-tab-head">
                    <h5>{props.title}</h5>
                </div>
                <div className="discuss-inner">
                    {props.assigneesData.map((item, index) =>
                        <React.Fragment key={"assignee-tab-" + index}>
                            <div className="assigned-block">
                                <div className="ind-assigned-user-thumb">
                                    <a href="javascript:void(0)">
                                        <img title={item.user_name} src={USRIMG_PATH + item.user_img_name}
                                             alt=""/></a>
                                </div>
                                <div className="ind-assigned-desc">
                                    <h5>{item.user_name}</h5>
                                    <p>{item.user_designation}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        )
    else
        return null

}
function AttachmentPanelTab(props){
    if(props.allfilesData.length !==0 )
        return (
            <React.Fragment>
                {props.allfilesData.map((item,index) => {
                    let iconClass = getFilePath(item.filename);
                    let filename=getFilePath(item.filename)
                    let contentPath = getAttachmentPath(filename);
                    return(
                        <p className="ind-file-list file-div" key={"attach-files-"+index}>

                            <a href="javascript:void(0);" className="attach-click attachlink"
                               onClick={ ()=>((forceDownload(contentPath + item.filename, item.filetitle)))} >
                                            <span className="attach-click-thumb">{iconClass !== 'image' &&
                                            <div className={"format " + getFileExtension(item.filename)}>
                                                {getFileExtension(item.filename)}
                                            </div>
                                            }
                                                {iconClass === 'image' &&
                                                <img style={{ width: "20px"}}src={contentPath+item.filename} />
                                                }</span>  <span className={`file-click-title`}>{item.filetitle.split(".")[0]}</span>
                            </a>
                            <span className="file-size">{formatBytes(item.filesize)}</span>
                        </p>
                    )}
                )}
            </React.Fragment>
        )
    else
        return null

}


class AttachmentBox extends Component {

    constructor(props) {
        super(props);
        this.getAttachmentPath = this.getAttachmentPath.bind(this);
    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(getFilePath(type)) {
            case 'image' :  filePath = FRMSIMG_PATH;
            break;
            case 'video' : filePath = FRMSVID_PATH;
            break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }
    render() {
        let index = this.props.index;
        let item = this.props.item;
        let iconClass = CustomFunctions.getFileExtension(item.filename);
        let contentPath = this.getAttachmentPath(item.filename);
        var albumName;
        if (isImage(item.filename)) {
            albumName = 'images/'
        } else if (isVideo(item.filename)) {
            albumName = 'videos/'
        } else {
            albumName = 'documents/'
        }
        return (

            <li className="active" key={index}>
                <a href="javascript:void(0);" className="attach-click" onClick={(e)=>forceDownload(contentPath + item.filename, item.filetitle)}
                   data-toggle="tooltip"
                   data-placement="bottom" data-original-title="dummy">
                    <span className="attach-attach-thumb">
                        {iconClass !== 'image' &&
                        <div className={"format " + iconClass}>
                            {iconClass}
                        </div>
                        }
                        {iconClass === 'image' &&
                        <img src={contentPath+ item.filename} />
                        }
                    </span>
                </a>
                <div className={`attach-click-toggle ${previewModalFormat(item.filename) === false ? 'single-opt' : ''}`}>
                    {previewModalFormat(item.filename) &&
                    <>
                    <button onClick="javascript:void(0)" title={item.filetitle} onClick= {(previewModalFormat(item.filename)) ? ((e) => this.props.preview(contentPath + item.filename, item.filetitle)) : ((e) => forceDownload(contentPath + item.filename, item.filetitle))} data-toggle="tooltip" >Preview
                    </button>
                    |
                    </>
                    }

                    <button><a data-id="2" data-action="download" onClick={(e)=>forceDownload(contentPath + item.filename, item.filetitle)}
                                 href="javascript:void(0);"
                                 className="download_data2">Download
                    </a></button>
                </div>
            </li>

        );
    }
}
class AssigneeBox extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let index = this.props.index;
        let assignee = this.props.assignee;



        return (

            <div className="assigned-tau-block single-user" key={'assignee'+assignee}>
                <div className="tau">
                    <span className="tau-img">
                        <img
                            src={USRIMG_PATH + assignee.assignee_img_name}/>
                    </span>
                    <span className="tau-name">{assignee.assignee_name}</span>
                </div>
            </div>
        );
    }
}
class ActivityBox extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(this.props.assignee.reassign === 'yes') {
            this.props.loadSubTask(this.props.assignee.masterid);
        }
    }

    render() {
        let index = this.props.index;
        let assignee = this.props.assignee;



        return null;
    }
}
class TimeLogForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timeLogValues: [{ rawdate: '', hour : '' , min : '' }]
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.timeLogValues !== this.state.timeLogValues) {
            this.props.getTimeLogEntries(this.state.timeLogValues);
        }
    }

    handleDateChange(date, i){


        let timeLogValues = [...this.state.timeLogValues];
        timeLogValues[i]['rawdate'] = date;
        timeLogValues[i]['date'] =  formatDate(date);

        this.setState({
            timeLogValues : timeLogValues
        });
        document.getElementById('date'+i).classList.remove('val-error');

    }

    handleTimeLogChange(i, event) {

        var fieldId = event.target.id;
        var fieldName = event.target.name;
        var fieldValue = event.target.value;


        let timeLogValues = [...this.state.timeLogValues];

        if(timeLogValues[i][fieldName] === undefined) {
            timeLogValues[i][fieldName] = [];
        }
        timeLogValues[i][fieldName] = fieldValue;

        delete timeLogValues[i].value;
        this.setState({ timeLogValues });

        if(fieldValue !== '') {
            if( fieldValue > parseInt(event.target.max) ) {
               document.getElementById(fieldId).value = event.target.max;
            }
            else{
                document.getElementById(fieldId).classList.remove('val-error');
            }

        }

    }

    addNewLog() {
        this.setState(prevState => ({
            timeLogValues: [...prevState.timeLogValues, { rawdate: '', hour : '', min : '' }]
        }));
    }

    removeLog(i) {
        let timeLogValues = [...this.state.timeLogValues];
        if(timeLogValues.length > 1) {
            timeLogValues.splice(i, 1);
            this.setState({ timeLogValues });
        }

    }



    render() {
        let index = this.props.index;
        let assignee = this.props.assignee;


        return (
            <>
                {this.state.timeLogValues.map((item, i) => (
                    <div key={i} className="row-list">
                        <div className="time-log-items one-third">
                            <div className="input-log-time date">
                                <DatePickerBox id={"date" + i} handleDateChange={this.handleDateChange.bind(this)}
                                               startDate={item['rawdate']} index={i}  maxDate={new Date()}
                                               placeholderText="Date"
                                />
                            </div>
                        </div>
                        <div className="time-log-items one-third">
                            <div className="input-log-time">
                                <input
                                    type="number"
                                    name="hour"
                                    id={"hour" + i}
                                    max="23"
                                    className="log-time-input"
                                    placeholder="Hours"
                                    onChange={e => this.handleTimeLogChange(i, e)}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="time-log-items one-third">
                            <div className="input-log-time addition">
                                <input
                                    type="number"
                                    name="min"
                                    id={"min" + i}
                                    max="59"
                                    className="log-time-input"
                                    placeholder="Minutes"
                                    onChange={e => this.handleTimeLogChange(i, e)}
                                />
                            </div>
                        </div>
                        <div className="log-addition">
                            {i > 0 &&
                            <input
                                type="button"
                                value="-"
                                onClick={() => this.removeLog(i)}
                                className="log-addition-link"
                            />
                            }
                            <input type="button" className="log-addition-link"
                                   value="+" onClick={() => this.addNewLog()}/>
                        </div>

                    </div>
                ))}
            </>
        )
    }
}
class TimeLogList extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let index = this.props.index;
        let log = this.props.log;



        return (

            <div className="time-log-count" key={index}>
                <div className="time-log-sub-list-left">
                    <p> {moment(log.log_date).format('dddd,  DD MMM YYYY')}</p>
                </div>
                <div className="time-log-sub-list-right">
                    <p>Time
                        Taken <span>{log.log_hours}:{log.log_mins} </span>Hrs
                    </p>
                </div>
            </div>
        );
    }
}

function FormTodoTile(props) {

    const [selected, selectForm] = useState(
        props.selected
    );

    React.useEffect(() => {
        props.selectForm(props.item, selected);
    }, [selected]);
////////console.log(props)
    return(
        <div className="content-section  form-list-item " data-action="filter" onClick={ (e) => selectForm(selected === '' ? 'selected' : '')}>
            <a href="javascript:void(0)"  className={"content-click "+ selected}>
                <div className="section-image">{props.item.filename ?
                    <img src={FRMSIMG_PATH + props.item.filename} alt="no-image" /> :
                    <img src={Coverimage} alt="no-image" />
                }
                    <div className="checkbox-wrap">
                        <input type="checkbox" name="" className="chk-opt" id={props.item.cid}/>
                        <label className="chk-txt-opt"></label></div>
                </div>
                <div className="section-info-main">
                    <div className="section-info"><h5>{atob(props.item.title)}</h5></div>
                    <div className="section-info-icon">
                        <span className="ico-lib icon-form-task icon-style">{props.item.childs}</span>
                        <span className="ico-lib icon-form-share icon-style">{props.item.share}</span>
                    </div>
                </div>
            </a></div>
    );
}

function FormTodoTileSystemTask(props) {

    const [selected, selectForm] = useState(
        props.selected
    );

    React.useEffect(() => {
        props.selectForm(props.item, selected);
    }, [selected]);
////////console.log(props)
    return(
        <div className="content-section  form-list-item " data-action="filter" onClick={ (e) => selectForm(selected === '' ? 'selected' : '')}>
            <a href="javascript:void(0)"  className={"content-click "+ selected}>
                <div className="section-image">{props.item.filename ?
                    <img src={FRMSIMG_PATH + props.item.filename} alt="no-image" /> :
                    <img src={Coverimage} alt="no-image" />
                }
                    <div className="checkbox-wrap">
                        <input type="checkbox" name="" className="chk-opt" id={props.item.cid}/>
                        <label className="chk-txt-opt"></label></div>
                </div>
                <div className="section-info-main">
                    <div className="section-info"><h5>{props.item.title}</h5></div>
                    <div className="section-info-icon">
                        <span className="ico-lib icon-form-task icon-style">{props.item.childs}</span>
                        <span className="ico-lib icon-form-share icon-style">{props.item.share}</span>
                    </div>
                </div>
            </a></div>
    );
}
export {AttachmentBox,WorkTimeSection,HeaderBox,TaskOptionButton,TaskDetailUserInfo,TaskDetailContent,NavTab,TaskStatusButton, TaskDetailBox,UsersPanelTab,AttachmentPanelTab, ActivityBox, TimeLogForm, TimeLogList,ActivityPanel, IndividualAssigners, AttachmentPanel, ViewParentPanel, CollectionPanel, FormTodoTile,FormTodoTileSystemTask, ActivityPanelPrint}


