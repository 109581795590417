import React, {Component} from 'react';
import 'react-selectize/themes/index.css'
import {FRMSPDFDOC_PATH, FRMSVID_PATH} from "../../../constants";
import {forceDownload} from "../../../utilities/CustomFunctions";
import VideoPlayer from "../../views/player/VideoPlayer";
import {getProfile} from "../../../utilities/AuthService";

class PreviewModal extends Component {

    render() {

        return (
            <div className="modal fade bsy-modal x-large fl-pv show" id="toModal" tabIndex="-1" role="dialog"
                 aria-labelledby="mngModal" aria-hidden="true" style={{display:'block'}} >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.Title}</h5>
                            <button className="ico-lib-list modal-close" data-dismiss="modal"
                                    aria-label="Close" onClick={(e)=>this.props.closePreviewModal(e)}></button>
                        </div>
                        <div className="modal-body">
                            <div className="preview-area chat-panel">
                                {this.props.res_type === 1 &&
                                <img src={this.props.ImgPath + this.props.ImgName}/>
                                }
                                {this.props.res_type === 2 &&
                                <React.Fragment>
                                    <VideoPlayer
                                        key={this.props.ImgPath + this.props.ImgName}
                                        display={'block'}
                                        width="100%"
                                        height="100%"
                                        src={this.props.ImgPath + this.props.ImgName}
                                        />
                                </React.Fragment>
                                }
                                {this.props.res_type === 3  &&
                                    <iframe src={FRMSPDFDOC_PATH + this.props.ImgName + '&embedded=true'} width="100%" height="250px"/>
                                }
                                {this.props.res_type === 4  &&
                                <audio  width="100%" height="100%" controls controlsList="nodownload" key={this.props.ImgPath + this.props.ImgName}>
                                    <source type="audio/mpeg" src={this.props.ImgPath + this.props.ImgName}></source>
                                </audio >
                                }
                                {this.props.res_type === 5 &&
                                <React.Fragment>
                                    <VideoPlayer
                                        key={this.props.ImgPath + this.props.ImgName}
                                        display={'block'}
                                        width="100%"
                                        height="100%"
                                        src={this.props.ImgPath + this.props.ImgName}
                                        resType='5'
                                        fileName={this.props.ImgName}
                                    />
                                </React.Fragment>
                                }
                                <div className="preview-options">
                                    <ul className="resources-filters-icons">
                                        {this.props.res_type !== 5 &&
                                        <li>
                                            <a className="rs-tab-btn ico-lib-v1 rc-download"
                                               onClick={(e)=>{forceDownload(this.props.ImgPath + this.props.ImgName, this.props.Title);this.props.downloadTracking()}}></a>
                                        </li>
                                        }
                                        {this.props.creator === getProfile().id &&
                                            <li><a className="rs-tab-btn ico-lib-v1 rc-delete"
                                                   onClick={(e) => this.props.deleteResource(e)}></a></li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PreviewModal;



