import {combineReducers} from 'redux';
import {getProfile} from "../utilities/AuthService";

const listallusers = (state = {ListAllUsers:[]}, action) => {
    switch (action.type) {
        case "LIST_All_USERS":
            return {
                ...state,
                ListAllUsers: action.listallusers.data.results.data,
            };
        default:
            return state;
    }
};
const listallgroups = (state = {ListAllgroups:{data:[]}}, action) => {
    var _newState;
    var groupDetails;
    switch (action.type) {
        case "LIST_All_GROUPS":
            return {
                ...state,
                ListAllgroups: action.listallgroups.data,

            };
            break;
        case "CHAT_GROUP_DELETE":
            _newState = {...state};
            _newState[`ListAllgroups`][`data`]=_newState.ListAllgroups.data.filter((item) => item.groupid !== action.groupDelete.data.results.groupid);
            return _newState;
            break;
        case "CREATE_GROUP":
            ////////////console.log(action.creategroup)
            groupDetails={
                "groupid": action.creategroup.data.results.id,
                "groupname": btoa(action.creategroup.data.results.groupname),
                "groupdesc": "dGVzdA==",
                "status": "active",
                "has_icon": "no",
                "creator": "",
                "createdate": "",
                "unreadmessage": 0
            }

            _newState = {...state};
            ////////////console.log(_newState)
            _newState[`ListAllgroups`][`data`]=_newState.ListAllgroups.data.concat(groupDetails);
            return _newState;
            break;
        default:
            return state;
    }
};

const chatUserId = (state = {ReceiverUserid:'',chatType:'',name:'',designation:'',image:''}, action) => {
    switch (action.type) {
        case "CHAT_RECEIVER_USERID":
            return {
                ...state,
                ReceiverUserid: action.reeiveruserid,
                chatType:action.chatType,
                name:action.name,
                designation:action.designation,
                image:action.image
            };
            break;
        case "CHAT_RESET":
            return {
                ...state,
                ReceiverUserid: '',
                chatType:'',
                name:'',
                designation:'',
                image:''

            };
            break;
        default:
            return state;
    }
};

const listchathistory = (state = {ListChatHistory:[]}, action) => {
    var _newState;
    switch (action.type) {
        case "LIST_All_CHAT_HISTORY":
            return {
                ...state,
                ListChatHistory: action.listchathistory.data.data,

            };
        case "UPDATE_CHAT_HISTORY":
            _newState = {...state};
            _newState[`ListChatHistory`]=_newState.ListChatHistory.concat(action.data);
            return _newState;
        case "CHAT_HISTORY_RESET":
            return {
                ...state,
                ListChatHistory: [],

            };
        case "UPDATE_METAURL_DATA":
            _newState = {...state};
            var index=(_newState.ListChatHistory.findIndex(obj => obj["chat_id"] === action.chatid))
            _newState[`ListChatHistory`][index]['metainfo']=action.metaData.metainfo;
            return _newState;
        case "UPDATE_CHAT_MEDIA_STATUS":
            _newState = {...state};
            var index=(_newState.ListChatHistory.findIndex(obj => obj["chat_id"] === action.chatid))
            var ind=(_newState.ListChatHistory[index][`attachment`].findIndex(obj => obj["id"] === action.id))
            _newState[`ListChatHistory`][index][`attachment`][ind]['approved']=action.approvedstatus;
            return _newState;


        case "LOADMORE_CHAT_HISTORY":
            _newState = {...state};
            _newState[`ListChatHistory`]=action.listchathistory.data.data.concat(_newState.ListChatHistory);
            return _newState;

        default:
            return state;
    }
};


const savechatdata = (state = {SaveChatHistory:{data:[]},messageData:{id:'', username:'',message:"", has_attachment:"no", has_metaurl:true, metainfo:[]}}, action) => {
    switch (action.type) {
        case "SAVE_CHAT_DATA":
            return {
                ...state,
                SaveChatHistory: action.savechatdata.data,
                messageData:action.messageData
            };
            break;
        default:
            return state;
    }
};

const saveGroupChat = (state = {saveGroupChat:{data:[]},messageData:{id:'', username:'',message:"", has_attachment:"no", has_metaurl:true, metainfo:[]}}, action) => {
    switch (action.type) {
        case "SAVE_GROUP_CHAT_DATA":
            return {
                ...state,
                saveGroupChat: action.savechatdata.data,
                messageData:action.messageData
            };
            break;
        default:
            return state;
    }
};

const createGroupData = (state = {createGroup:{data:[]}}, action) => {
    switch (action.type) {
        case "CREATE_GROUP":
            return {
                ...state,
                createGroup: action.creategroup.data,
            };
            break;

        default:
            return state;
    }
};

const toasterNotification = (state = {notification:[]}, action) => {
    switch (action.type) {
        case "TOASTER_NOTIFICATION":
            return {
                ...state,
                notification: action.toaster,
            };
            break;
        default:
            return state;
    }
};

const ChatUsers = (state = {users:[]}, action) => {
    switch (action.type) {
        case "CHAT_USERS":
            return {
                ...state,
                users: action.chatusers.data.results.data,
            };
        default:
            return state;
    }
};

const GroupInfo = (state = {info:[]}, action) => {
    var _newState;
    switch (action.type) {
        case "CHAT_GROUP_INFO":
            return {
                ...state,
                info: action.groupInfo.data.data,

            };
            break;
        case "ADD_MEMBERS_TO_GROUP":
            _newState = {...state};
            _newState[`info`][0][`groupmembers`]=_newState.info[0].groupmembers.concat(action.addMembers.data.results.memberinfo);
            return _newState;
            break;
        case "REMOVE_USER_FROM_GROUP":
            _newState = {...state};
            _newState[`info`][0][`groupmembers`]=_newState.info[0].groupmembers.filter((item) => item.userid.toString() !== action.removeUser.data.results.userid);
            return _newState;
            break;
        default:
            return state;
    }
};




const RemoveUserFromGroup = (state = {info:[],groupid:'',userid:''}, action) => {
    switch (action.type) {
        case "REMOVE_USER_FROM_GROUP_UPDATION":
            return {
                ...state,
                info: action.removeUser,
                groupid:action.groupid,
                userId:action.userid
            };
            break;
        default:
            return state;
    }
};

const GroupDelete = (state = {info:[]}, action) => {
    switch (action.type) {
        case "CHAT_GROUP_DELETE":
            return {
                ...state,
                info: action.groupDelete.data,
            };
            break;
        default:
            return state;
    }
};

const EditGroup = (state = {info:[]}, action) => {
    switch (action.type) {
        case "UPDATE_GROUP_DESC":
            return {
                ...state,
                info: action.editGroup.data,
            };
            break;
        default:
            return state;
    }
};


const MembersStatus = (state = {data:[]}, action) => {
    switch (action.type) {
        case "ADD_MEMBERS_TO_GROUP":
            return {
                ...state,
                info: action.addMembers,

            };
            break;
        case "REMOVE_USER_FROM_GROUP":
            return {
                ...state,
                info: action.removeUser,

            };
            break;
        default:
            return state;
    }
};



const GetComments = (state = {data:[],loader:false}, action) => {
    var _newState;
    var commentArray={}
    switch (action.type) {


        case "GET_ALL_GROUP_DISSCUSSION_LOADER":
            return {
                ...state,
                loader:action.loader
            };
            break;
        case "GET_ALL_GROUP_DISSCUSSION_DATA":
            return {
                ...state,
                data: action.getAllComments.data.data,
                loader:action.loader

            };
            break;
        case "UPDATE_CHAT_DISSCUSSION":
            _newState = {...state};
            ////////////console.log(_newState)
            _newState[`data`]=( _newState.data.filter((item) => item.discussionid !== action.data.discussionid).concat(action.data));
            // _newState[`stagetaskinfo`][`metaform`][action.index][action.name] = action.value;
            // stagetaskinfo: action.stagetaskinfo.data.results.data,
            ////////////console.log(_newState)
            return _newState;
            break;
        default:
            return state;
    }
};


const SaveDisscussion = (state = {data:[]}, action) => {
    switch (action.type) {
        case "SAVE_CHAT_DISSCUSSION":
            return {
                ...state,
                data: action.savediscussion,

            };
            break;
        default:
            return state;
    }
};

const savelinkGroupEntity = (state = {data:[]}, action) => {
    switch (action.type) {
        case "SAVE_LINK_ENTITYGROUP":
            return {
                ...state,
                data: action.savelinkgroupentity,

            };
            break;
        default:
            return state;
    }
};
const deletelinkGroupEntity = (state = {data:[]}, action) => {
    switch (action.type) {
        case "DELETE_LINK_ENTITYGROUP":
            return {
                ...state,
                data: action.deletelinkGroupEntity.data.results,

            };
            break;
        default:
            return state;
    }
};


const listlinkgroupsEntity = (state = {data:[]}, action) => {
    switch (action.type) {
        case "LIST_GROUPENTITY":
            return {
                ...state,
                data: action.linkgroupsEntity.data,
            };
            break;
        case "RESET_GROUP_ENTITY":
            return {
                ...state,
                data: [],
            };
            break;

        default:
            return state;
    }
};
const listlinkEntityDetails = (state = {data:[]}, action) => {
    switch (action.type) {
        case "RESET_LIST_ENTITYDET":
            return {
                ...state,
                data: [],
            };
            break;
        case "LIST_ENTITYDET":
            return {
                ...state,
                data: action.linkEntityDetails.data,
            };
            break;
            case "LIST_ENTITYDET_LOADER":
            return {
                ...state,
                loading: action.loading,
            };
            break;
        default:
            return state;
    }
};

const listChatmedia = (state = {media:[],loader:false,total:""}, action) => {
    var _newState;
    switch (action.type) {

        case "GET_CHAT_MEDIA_DATA":
            return {
                ...state,
                media:action.media,
                loader:action.loader,
                total:action.total,
            };
        case "UPDATE_CHAT_MEDIA_DATA":

            _newState = {...state};
            var index=(_newState.media.findIndex(obj => obj["id"] === action.id))
            _newState[`media`][index]['approved']=action.approvedstatus;
            return _newState;




        default:
            return state;
    }


}

export const Chat = combineReducers({
    listallusers,
    listallgroups,
    listchathistory,
    savechatdata,
    chatUserId,
    saveGroupChat,
    createGroupData,
    toasterNotification,
    ChatUsers,
    GroupInfo,
    GroupDelete,
    EditGroup,
    MembersStatus,
    GetComments,
    SaveDisscussion,
    savelinkGroupEntity,
    deletelinkGroupEntity,
    RemoveUserFromGroup,
    listlinkgroupsEntity,
    listlinkEntityDetails,
    listChatmedia

});

export default Chat;
