import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../../../actions/ToDo";
import {withRouter} from 'react-router';
import {getProfile} from "../../../../utilities/AuthService";
import Highlighter from "react-highlight-words";
import {debounce} from 'throttle-debounce';
import moment from "moment";
import OutsideAlerter from "../../../views/OutsideAlerter";
class HeaderSearch extends Component {

    constructor(props) {
        super(props);
        this.state={
            Todo:3,
            ToFollowUp:3,
            ToVerify:3,
            open:false
        }
        this.searchDataLimitHandler = debounce(500, this.searchDataLimitHandler);
      }
    searchData=(e)=>{
        let param={
            srchval:e.target.value,
            usertype:getProfile().usertype,
            uid:getProfile().id,
        }
        this.setState({open:true})
       this.searchDataLimitHandler(param)
    }
    searchDataLimitHandler(param){
        this.props.tasksearch(param)
    }
    loadMore=(type)=>{
        let data=''
        let length=''
        if(type === "tome") {
            data = this.props.taskSearch.tome
            length=this.props.taskSearch.tome.length
        }
        if(type === "byme") {
            data =this.props.taskSearch.byme
            length=this.props.taskSearch.byme.length
        }
        if(type === "toverify") {
            data=this.props.taskSearch.toverify
            length=this.props.taskSearch.toverify.length
        }
        if(type === "toverified") {
            data=this.props.taskSearch.toverified
            length=this.props.taskSearch.toverified.length
        }

        let param={
            srchval:this.props.taskSearch.searchParam,
            usertype:getProfile().usertype,
            uid:getProfile().id,
            mode:type,
            limit:length,
            offset:100
        }



      this.props.loaderTask(param)

    }

    handleSearchoption=(masterid,type)=>{
        this.props.history.push('/task/details/'+btoa(masterid)+'/'+btoa(type))
        this.setState({open:true})
    }
    handleClose=(open)=>{
        this.setState({open:false})
    }
    mobSearchToggle(){
        this.setState({open:false})
        this.props.mobSearchToggle()
    }

    render() {

        let Todo=this.props.taskSearch.tome
        let ToFollowUp=this.props.taskSearch.byme
        let ToVerify=this.props.taskSearch.toverify
        let ToVerified=this.props.taskSearch.toverified
        let srchval=this.props.taskSearch.searchParam
        let tome_count=parseInt(this.props.taskSearch.tome_count)
        let byme_count=parseInt(this.props.taskSearch.byme_count)
        let toverify_count=parseInt(this.props.taskSearch.toverify_count)
        let toverified_count=parseInt(this.props.taskSearch.toverified_count)
        //console.log(this.props.taskSearch)
        //console.log(this.state)

         return (

             <OutsideAlerter status={this.state.open} Triggered={() => this.handleClose(!this.state.open)}>
                 <div className={"search-block"+ (this.props.state.activesearch? ' active ': '')}>
                 <input type="text" name="srch_tval" id="srch_tval" className="search-box"
                        defaultValue={this.props.taskSearch.searchParam}
                        placeholder={`Search User, Task, etc...`}
                        onChange={this.searchData} autoComplete={'off'}/>
                 <span className="icon-in icon-search search-box-icon"> </span>
                 <button className="ico-lib mobile-toggle-search" onClick={()=>this.mobSearchToggle()}></button>
             </div>

                 {this.props.taskSearch.loader &&
                 <div className="search-result" style={{zIndex:999999999999}}>
                     <div className="result-subhead">
                         <h5>Searching…</h5>
                     </div>
                     <div className="result-container search-progress">
                         <div className="result-item">
                             <div className="left-icon ico-lib-v1"></div>
                             <div className="right-description">
                                 <h5></h5>
                                 <p></p>
                             </div>
                         </div>
                         <div className="result-item">
                             <div className="left-icon ico-lib-v1"></div>
                             <div className="right-description">
                                 <h5></h5>
                                 <p></p>
                             </div>
                         </div>
                         <div className="result-item">
                             <div className="left-icon ico-lib-v1"></div>
                             <div className="right-description">
                                 <h5></h5>
                                 <p></p>
                             </div>
                         </div>
                     </div>
                 </div>
                 }
                 {this.props.taskSearch.searchParam !=="" && this.state.open &&
                 <div className="search-result nicescroll">
                     {Todo.length !== 0 &&
                     <div className="result-subhead">
                         <h5>Todo</h5>
                     </div>
                     }
                     <div className="result-container recently">
                         {Todo.map((list, index) =>
                             <div className="result-item" key={`header-search-list`+index}>
                                 <div className="left-icon ico-lib-v1"></div>
                                 <div className="right-description" onClick={(e)=>this.handleSearchoption(list.masterid,"to-me")}>
                                     <h5>
                                         <Highlighter
                                         highlightClassName="matching-text"
                                         searchWords={[srchval]}
                                         autoEscape={true}
                                         textToHighlight={atob(list.subject)}
                                     />
                                     </h5>
                                     <p>{moment(list.createdate).format('DD MMM')} from {list.createuser_name} | Priority : {list.priority} | {list.status} </p>
                                 </div>
                             </div>
                         )}

                         {tome_count > (Todo.length) &&
                         <div className="result-subhead">
                             {/* <h5></h5> */}
                             <button className="show-all-btn" onClick={() => this.loadMore("tome")}>Show all</button>
                         </div>
                         }


                     </div>

                     {ToFollowUp.length !== 0 &&
                     <div className="result-subhead">
                         <h5>To Follow Up</h5>
                     </div>
                     }

                     <div className="result-container recently">
                         {ToFollowUp.map((list, index) =>
                             <div className="result-item" key={`header-search-list`+index}>
                                 <div className="left-icon ico-lib-v1"></div>
                                 <div className="right-description" onClick={(e)=>this.handleSearchoption(list.masterid,"to-me")}>
                                     <h5>
                                         <Highlighter
                                             highlightClassName="matching-text"
                                             searchWords={[srchval]}
                                             autoEscape={true}
                                             textToHighlight={atob(list.subject)}
                                         />
                                     </h5>
                                     <p>{moment(list.createdate).format('DD MMM')} from {list.createuser_name} | Priority : {list.priority} | {list.status} </p>
                                 </div>
                             </div>
                         )}
                         {byme_count > ToFollowUp.length &&
                         <div className="result-subhead">
                             <h5></h5>
                             <button className="show-all-btn" onClick={() => this.loadMore("byme")}>Show all</button>
                         </div>
                         }

                     </div>


                     {ToVerify.length !== 0 &&
                     <div className="result-subhead">
                         <h5>To Verify</h5>
                     </div>
                     }

                     <div className="result-container recently">
                         {ToVerify.map((list, index) =>
                             <div className="result-item" key={`header-search-list`+index}>
                                 <div className="left-icon ico-lib-v1"></div>
                                 <div className="right-description" onClick={(e)=>this.handleSearchoption(list.masterid,"to-me")}>
                                     <h5>
                                         <Highlighter
                                             highlightClassName="matching-text"
                                             searchWords={[srchval]}
                                             autoEscape={true}
                                             textToHighlight={atob(list.subject)}
                                         />
                                     </h5>
                                     <p>{moment(list.createdate).format('DD MMM')} from {list.createuser_name} | Priority : {list.priority} | {list.status} </p>
                                 </div>
                             </div>
                         )}
                     </div>
                     {toverify_count > ToVerify.length &&
                     <div className="result-subhead">
                         <h5></h5>
                         <button className="show-all-btn" onClick={() => this.loadMore("toverify")}>Show all</button>
                     </div>
                     }

                     {ToVerified.length !== 0 &&
                     <div className="result-subhead">
                         <h5>To Verified</h5>
                     </div>
                     }

                     <div className="result-container recently">
                         {ToVerified.map((list, index) =>
                             <div className="result-item" key={`header-search-list`+index}>
                                 <div className="left-icon ico-lib-v1"></div>
                                 <div className="right-description" onClick={(e)=>this.handleSearchoption(list.masterid,"to-me")}>
                                     <h5>
                                         <Highlighter
                                             highlightClassName="matching-text"
                                             searchWords={[srchval]}
                                             autoEscape={true}
                                             textToHighlight={atob(list.subject)}
                                         />
                                     </h5>
                                     <p>{moment(list.createdate).format('DD MMM')} from {list.createuser_name} | Priority : {list.priority} | {list.status} </p>
                                 </div>
                             </div>
                         )}
                     </div>
                     {toverified_count > ToVerified.length &&
                     <div className="result-subhead">
                         <h5></h5>
                         <button className="show-all-btn" onClick={() => this.loadMore("toverified")}>Show all</button>
                     </div>
                     }


                 </div>
                 }
             </OutsideAlerter>

        );
    }
}




const mapStateToProps = state => ({
    taskSearch: state.TaskReducer.taskSearch
});
export default withRouter(connect(mapStateToProps, {

    tasksearch: taskActions.tasksearch,
    loaderTask:taskActions.loaderTask
})(HeaderSearch));


