import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
class PerformanceDetail extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        var list = this.props.dataList;
        var perc = list.value + '%'
        return(
            <div className="ind-static-md">
                <p>{list.label}</p>
                <div className="progress">
                    <div className="progress-bar green" style={{width: perc}}></div>
                </div>
                <h5>{list.value}</h5>
            </div>
        );
    }
}


export default PerformanceDetail;