//import axios from 'axios';
import {commonService} from "./common.services";
import {API_PATH , ES_INDEX} from '../constants/index'
import {getProfile} from "../utilities/AuthService";
import moment from "moment";
import momenttz from "moment-timezone";
export const task = {
    listTodo,
    createTask,
    taskDetailsList,
    moretaskDetailsList,
    MarkAsDone,
    Verify,
    reopenTask,
    reopenAllTask,
    AddTimeLog,
    getTimeLog,
    getRemarks,
    saveDiscussion,
    getPoolMenu,
    archiveTasks,
    deleteTasks,
    updateTaskStatus,
    searchPoolTasks,
    takePoolTask,
    quitPoolTask,
    quitTodoPoolTask,
    addTodo,
    addTodoAsync,
    addTodoUpdate,
    saveToDoAttachments,
    getUserData,
    getTags,
    getAttachment,
    getToDoLogs,
    getTaskHelper,
    tasksidebarCount,
    listtaskStatus,
    postTaskCompletionasync,
    userFilter,
    dateupdatetask,
    assigneetimelog,
    savePoolMembers,
    getprinttask,
    getselectedCollection,
    taskCompletionExternal,
    isTaskOpen,
    getEnterprise,
    completePoolTask,
    tasksearch,
    loaderTask,
    taskAlert,
    handleClap,
    closedByCreator,
    uploadAttachments,
    saveReleatedtask,
    listrelatedTask,
    myTask
};

function postTaskCompletionasync(params) {

    return commonService.sendHttpRequest('task/taskcompletionasync_react', params, "POST");
}
function dateupdatetask(params){
    return commonService.sendHttpRequest('task/updatetask', params, "POST");
}
function getprinttask(params){
    return commonService.sendHttpRequest('task/printtask', params, "GET");
}
function getselectedCollection(params){
    return commonService.sendHttpRequest('collection/selectedcollection', params, "POST");
}
function getEnterprise(params){
    return commonService.sendHttpRequest('login/enterprise', params, "GET");
}
async function listTodo(params){
  //  params["index"]=ES_INDEX
//    params["t"]=Math.floor(Date.now() / 1000)
    params["user_tz"]=moment.tz.guess()
    //return await axios.get(API_PATH+'task/tasklist', {params});
    return commonService.sendHttpRequest('task/tasklist', params, "GET");
}
async function listtaskStatus(params){
   // params["index"]=ES_INDEX
  //  params["t"]=Math.floor(Date.now() / 1000)
   // return await axios.get(API_PATH+'task/taskactions', {params});

    return commonService.sendHttpRequest('task/taskactions', params, "GET");
}


function addTodoUpdate(params){
    return commonService.sendHttpRequest('task/createtaskv1', params, "POST");
}

function addTodo(params){
    return commonService.sendHttpRequest('task/createtaskv1', params, "POST");
    //return commonService.sendHttpRequest('task/createtask', params, "POST");
}
function addTodoAsync(params){
    return commonService.sendHttpRequest('task/createtaskasync', params, "POST");
}


function userFilter(cat,userid){
    //return axios.get(API_PATH + 'task/userfilter?uid='+userid+'&category='+cat+'&index='+ES_INDEX)

    let params={
        uid:userid,
        category:cat,

    }

    return commonService.sendHttpRequest('task/userfilter', params, "GET");
}



function saveToDoAttachments(params){
    return commonService.sendHttpRequest('task/savetodoattachments', params, "POST");
}

function createTask(to,sub,description,userid){
    var  tid= Math.round(new Date().getTime() + (Math.random() * 100));
    var taskid=2+'_'+tid;
    return commonService.sendHttpRequest( 'task/createtodo',{
        "uid": userid,
        "i": taskid,
        "title": "test API",
        "priority": "Medium",
        "mode": "other",
        "concat": "API Testing only ",
        "assignmentType": "oneto-many",
        "savemode": "create",
        "assignees": "user_"+to,
        "subject": sub,
        "duedate": "2018-11-13",
        "tagsid": "",
        "tagsname": "tagAPI,tagAPI2",
        "projectid": "",
        "projectname": "projectAPI",
        "has_attachemnt": "no",
        "has_collection": "no",
        "grandmaster": "",
        "flood": "",
        "tasktype": "task",
        "collection": "",
        "index": ES_INDEX


    },"POST")
}
async function taskDetailsList(params){
   // params["index"]=ES_INDEX
   // params["t"]=Math.floor(Date.now() / 1000)
    return commonService.sendHttpRequest('task/maintaskdetails', params, "GET");
   // return await axios.get(API_PATH+'task/maintaskdetails',{ params});

}
async function tasksidebarCount(params){
    //params["index"]=ES_INDEX
    //params["t"]=Math.floor(Date.now() / 1000)
    params["systype"]="user"
  //  return await axios.get(API_PATH+'task/taskcount', {params});
    return commonService.sendHttpRequest('task/taskcount', params, "GET");
}


function moretaskDetailsList(params){

    return commonService.sendHttpRequest('task/subtask', params, "GET");

}
// function MarkAsDone(params) {
//     return commonService.sendHttpRequest('task/markasdone', params, "POST");
// }

function MarkAsDone(params) {
    return commonService.sendHttpRequest('task/task_completion_react', params, "POST");
}
function Verify(params) {

    return commonService.sendHttpRequest('task/verify', params, "POST");
}
function AddTimeLog(params) {
    return commonService.sendHttpRequest('task/addtimelog', params, "POST");
}
async function getTimeLog(params) {
//    params["index"]=ES_INDEX
  //  params["t"]=Math.floor(Date.now() / 1000)
   // return await axios.get(API_PATH+'task/timelog', {params});

    return commonService.sendHttpRequest('task/timelog', params, "GET");
}
function getRemarks(uid,taskid,reference){
    let params={
        uid:uid,
        taskid:taskid,
        reference:reference

    }
    return commonService.sendHttpRequest('task/remarks', params, "GET");
  //  return axios.get(API_PATH + 'task/remarks?uid='+uid+'&taskid='+taskid+'&reference='+reference+'&index='+ES_INDEX)
}
function saveDiscussion(params){
    return commonService.sendHttpRequest('task/savediscussion', params, "POST");
}
function getPoolMenu(params) {
    return commonService.sendHttpRequest('pool/searchpoolmenu', params, "POST");
}
function archiveTasks(params) {
    return commonService.sendHttpRequest('task/removetask', params, "POST");
}
function deleteTasks(params) {
    return commonService.sendHttpRequest('task/deletetaskpermanent', params, "POST");
}
function updateTaskStatus(params) {
    return commonService.sendHttpRequest('task/updatetaskstatus', params, "POST");
}
function searchPoolTasks(params) {
    return commonService.sendHttpRequest('pool/searchpooltasks', params, "POST");
}
function takePoolTask(params) {
    return commonService.sendHttpRequest('pool/takepooltask', params, "POST");
}
function quitPoolTask(params) {
    return commonService.sendHttpRequest('pool/quitpooltask', params, "POST");
}
function quitTodoPoolTask(params) {
    return commonService.sendHttpRequest('pool/quittodotask', params, "POST");
}
async function getUserData(params) {
  //  params["index"]=ES_INDEX
//    params["t"]=Math.floor(Date.now() / 1000)
   // return await axios.get(API_PATH+'task/userdata', {params});
    return commonService.sendHttpRequest('task/userdata', params, "GET");
}
function getTags(params) {
   // params["index"]=ES_INDEX
   // params["t"]=Math.floor(Date.now() / 1000)
   // return axios.get(API_PATH+'task/todotags', {params});

    return commonService.sendHttpRequest('task/todotags', params, "GET");
}
// function reopenTask(params) {
//     return commonService.sendHttpRequest('todo/update_status_child', params, "POST");
// }
function reopenTask(params) {
    return commonService.sendHttpRequest('task/reopen', params, "POST");
}
function reopenAllTask(params) {
    return commonService.sendHttpRequest('task/reopen', params, "POST");
}
function getAttachment(params) {
    return commonService.sendHttpRequest('task/getattachment', params, "POST");
}
function getToDoLogs(params) {
    return commonService.sendHttpRequest('task/gettodolog', params, "POST");
}

function getTaskHelper(params) {
 //   params["index"] = ES_INDEX
 //   params["t"]=Math.floor(Date.now() / 1000)
    //return axios.get(API_PATH+'task/taskhelper', {params});
    return commonService.sendHttpRequest('task/taskhelper', params, "GET");
}
function assigneetimelog(params) {
    return commonService.sendHttpRequest('task/taskassigneetimelog', params, "GET");
}
function savePoolMembers(params) {
    return commonService.sendHttpRequest('pool/savepoolmembers', params, "POST");
}
function taskCompletionExternal(params) {
    return commonService.sendHttpRequest('todo/task_completion', params, "POST");
}
function isTaskOpen(params) {
    return commonService.sendHttpRequest('pool/istaskopen', params, "GET");
}
function completePoolTask(params) {
    return commonService.sendHttpRequest('pool/completepooltask', params, "POST");
}
function tasksearch(params) {
    return commonService.sendHttpRequest('task/globalsearch', params, "GET");
}
function loaderTask(params) {
    return commonService.sendHttpRequest('task/globalsearchv2', params, "GET");
}
async function listProjectUsers(params){
    // params["index"]=ES_INDEX
    //params["t"]=Math.floor(Date.now() / 1000)
    // return await axios.get(API_PATH+'task/allusers', {params});
    return commonService.sendHttpRequest('task/projectusers', params, "GET");
}

function taskAlert(params) {
    return commonService.sendHttpRequest('task/pendingalert', params, "GET");
}
function handleClap(params) {
    return commonService.sendHttpRequest('task/updateclap', params, "POST");
}
function closedByCreator(params) {
    return commonService.sendHttpRequest('task/closedtask', params, "POST");
}
function uploadAttachments(data){
    let params={
        todoid: data.todoid,
        uid:data.uid,
        file:data.attachment,
        uniqid:data.filerelator,
        refer:data.reference !== undefined ? 'attachment' :'',
    }
    return commonService.sendHttpRequest('task/savetodoattachments', params, "POST");
}

function saveReleatedtask(params){
    return commonService.sendHttpRequest('task/assignrelatedtask', params, "POST");

}

function listrelatedTask(params){
    return commonService.sendHttpRequest('task/relatedtask', params, "GET");

}
function myTask(params){
    return commonService.sendHttpRequest('task/mytask', params, "GET");

}



