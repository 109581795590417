import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import LoaderSvg from "./LoaderSVG";

class FundamentalsTile extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    render() {

        return (
            <div className="ibt-rating">
                <div className="ind-ibt-rating">
                    <div className="ibt-rating-top">
                        <h5>Project's Fundamentals</h5>
                    </div>
                    <div className="ibt-rating-bottom">
                        <h5>4/5</h5>
                        <ul className="ibt-rate-star">
                            <li className="active"></li>
                            <li className="active"></li>
                            <li className="active"></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div className="ind-ibt-rating">
                    <div className="ibt-rating-top">
                        <h5>Project's Fundamentals</h5>
                    </div>
                    <div className="ibt-rating-bottom">
                        <h5>4/5</h5>
                        <ul className="ibt-rate-star">
                            <li className="active"></li>
                            <li className="active"></li>
                            <li className="active"></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div className="ind-ibt-rating">
                    <div className="ibt-rating-top">
                        <h5>Project's Fundamentals</h5>
                    </div>
                    <div className="ibt-rating-bottom">
                        <h5>4/5</h5>
                        <ul className="ibt-rate-star">
                            <li className="active"></li>
                            <li className="active"></li>
                            <li className="active"></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div className="ind-ibt-rating">
                    <div className="ibt-rating-top">
                        <h5>Project's Fundamentals</h5>
                    </div>
                    <div className="ibt-rating-bottom">
                        <h5>4/5</h5>
                        <ul className="ibt-rate-star">
                            <li className="active"></li>
                            <li className="active"></li>
                            <li className="active"></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div className="ind-ibt-rating">
                    <div className="ibt-rating-top">
                        <h5>Project's Fundamentals</h5>
                    </div>
                    <div className="ibt-rating-bottom">
                        <h5>4/5</h5>
                        <ul className="ibt-rate-star">
                            <li className="active"></li>
                            <li className="active"></li>
                            <li className="active"></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>

        );
    }


}

export default FundamentalsTile;