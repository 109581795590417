import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import {getProfile} from "../../../utilities/AuthService";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";
import StakeholderTile from "../../views/StakeholderTile";
import AddNewStakeholder from "./AddNewstakeholder";
import * as collectionActions from "../../../actions/Collections";
import {ES_INDEX, FRMSIMG_PATH,USRIMG_PATH} from '../../../constants';
import Modal from "../../views/Modal";
import * as projectActions from "../../../actions/Project";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";


class ProjectStakeholder extends Component {

    constructor(props) {
        super(props);

        this.state={
            expandHeader:false,
            showAddTeamModal : false,
            loadmorecount:50,
            collectionId:''
        }
        this.expandHeader = this.expandHeader.bind(this)
        this.showAddTeamModal = this.showAddTeamModal.bind(this)
        this.closeAddTeamModal = this.closeAddTeamModal.bind(this)
        this.getstakeholderInfo=this.getstakeholderInfo.bind(this)
    }

    componentDidMount() {
        var projectId = getUrlSegment(5);
        this.props.mainClick('project', '/project/details/stakeholders/'+projectId);
        let collectionid = "1579499227771";
        this.setState({collectionId : collectionid });
        let param={
            "from": 0,
            "size": this.state.loadmorecount,
            "index": ES_INDEX,
            "search":'',
            "uid": getProfile().id,
            "usertype": getProfile().usertype.toLocaleLowerCase(),
            "projectid": atob(projectId)
        }
        this.props.getstakeholderlist(collectionid, param);
        this.getstakeholderInfo();

    }
    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }
    showAddTeamModal(){
        this.setState({showAddTeamModal:true})
    }

    closeAddTeamModal(){
        this.setState({showAddTeamModal:false})
    }
    getstakeholderInfo(){
        let filtertype = atob(getUrlSegment(4))
        let id = ''
        if(filtertype=== 'Equity'){
            id = '870100517'
        }
        else if(filtertype=== 'Convertible'){
            id = '870100517'
        }
        else if(filtertype=== 'Debt'){
            id = '870100517'
        }
        else if(filtertype=== 'Mezzanine Debt'){
            id = '870100517'
        }
        else if(filtertype=== 'Others'){
            id = '870100517'
        }
        else if(filtertype=== 'EPC'){
            id = '429353536'
        }
        else if(filtertype=== 'Contractors'){
            id = '429353536'
        }
        else if(filtertype=== 'Advisors'){
            id = '429353536'
        }
        else if(filtertype=== 'O&M Contractor'){
            id = '429353536'
        }
        else if(filtertype=== 'Insurance'){
            id = '429353536'
        }
        else if(filtertype=== 'Local Authority'){
            id = '429353536'
        }
        else if(filtertype=== 'Local Community'){
            id = '429353536'
        }
        else if(filtertype=== 'Others'){
            id = '429353536'
        }
        this.props.stakeholdermenuUpdate(id, filtertype)
        }


    render() {
        let Stakeholderlist = this.props.Stakeholderlist ? this.props.Stakeholderlist.CollectionData :[];
        let Stakeholderlists=[];
        if(this.props.Stakeholdermenudata.masterId === "870100517"){
            Stakeholderlists = Stakeholderlist.filter(list=>(list[870100517]=== this.props.Stakeholdermenudata.title));
        }else{
            Stakeholderlists = Stakeholderlist.filter(list=>(list[429353536]=== this.props.Stakeholdermenudata.title));
        }
        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>
                <div className="ibt-body">
                    <div className="stakeholders-wrapper equity-team">
                        <div className="stakeholders-row">
                            {this.props.loader.loaderType === 'collection-details' && this.props.loader.status === true &&
                            <span className="Loader-holder">
                                        <LoaderSvg/>
                                    </span>
                            }
                            <div className="stakeholders-header">
                                <h5>{this.props.Stakeholdermenudata.title}</h5>
                            </div>
                            <div className="stakeholders-lister-block">
                                {Stakeholderlists.length === 0 &&
                                <div className='absolute-holder'>
                                    <NoData
                                        msg={`No Stakeholder added yet`}

                                    />
                                </div>
                                }
                                {Stakeholderlists.map((list,index)=>
                                <StakeholderTile
                                index={index}
                                list={list}
                                />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Task" onClick={(e)=>this.showAddTeamModal(e, 0)}>*/}
                {/*    <button className="float-button ico-lib float-add"></button>*/}
                {/*</div>*/}
                {/*{this.state.showAddTeamModal &&*/}
                {/*<Modal title="Add New User" Close ={this.closeAddTeamModal.bind(this)}>*/}
                {/*    <AddNewStakeholder*/}
                {/*    AddModalClose={this.closeAddTeamModal}*/}
                {/*    />*/}
                {/*</Modal>*/}
                {/*}*/}
            </div>



        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
    Stakeholderlist:state.CollectionsReducer.listCollectionDetaildata,
    Stakeholdermenudata:state.ProjectReducer.Stakeholdermenudata,
    loader:state.CommonReducer.loader,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getstakeholderlist:collectionActions.collectionDetail,
    stakeholdermenuUpdate:projectActions.StakeholderClick
})(ProjectStakeholder));
