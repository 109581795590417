import React, {Component} from 'react';
import {connect} from "react-redux";
import * as usersActions from "../../../actions/Users"
import {withRouter} from 'react-router';
import rootActions from "../../../actions";
import Modal from "../../views/Modal";
import AddNewGroup from "../team/AddNewGroup";
import {USRIMG_PATH} from "../../../constants";
import SimpleModal from "../../views/SimpleModal";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import OutsideAlerter from "../../views/OutsideAlerter";
import AlertModal from "../../views/AlertModal";
import AddToDoTask from "../AddToDoTask";
import * as taskActions from "../../../actions/ToDo";
import {getProfile} from "../../../utilities/AuthService";

class UserGroup extends Component {

    constructor(props) {
        super(props);

        this.state={
            groupId:0,
            showAddNewGroupModal:false,
            AddMoreUsersModal:false,
            selectedUsers:[],
            assignedUsers:[],
            userError:false,
            fieldID: 0,
            alerterstatus:false,
            showDeleteConfirm:false,
            deleteGroupId:'',
            addNewTask:false,
            projectList:[],
            selectedToUsers:[],

        }
        this.showAddNewGroupModal = this.showAddNewGroupModal.bind(this);
        this.closeAddNewGroupModal = this.closeAddNewGroupModal.bind(this);
        this.showAddMoreUsersModal = this.showAddMoreUsersModal.bind(this);
        this.closeAddMoreUsersModal = this.closeAddMoreUsersModal.bind(this);
        this.usersList = this.usersList.bind(this);
        this.addNewUsers = this.addNewUsers.bind(this);
        this.showOptions = this.showOptions.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.closeDeleteConfirm = this.closeDeleteConfirm.bind(this);
        this.addNewTask = this.addNewTask.bind(this);
        this.closeNewTask = this.closeNewTask.bind(this);
    }



    componentDidMount() {
        this.props.mainClick('group','');
        let params={
            "uid": getProfile().id,
            "usertype" : getProfile().usertype
        }
        this.props.listusergroup(params);
        this.usersList();
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "grouplisting",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if(prevProps.userGroupSaved.userGroupcreated !== this.props.userGroupSaved.userGroupcreated ||
            prevProps.userGroupSaved.userGroupedited !== this.props.userGroupSaved.userGroupedited ){
                    setTimeout(function () {
                        beta.props.listusergroup();
                        beta.closeAddNewGroupModal();
                        beta.setState({loading:0,groupId:0})
                    }, 1000)
                }
        if(prevProps.userGroupSaved.userGroupdeleted !== this.props.userGroupSaved.userGroupdeleted){
                    setTimeout(function () {
                        beta.props.listusergroup();
                        beta.closeDeleteConfirm();
                        beta.setState({loading:0,groupId:0})
                    }, 1000)
                }
        if(this.props.groupDetails.groupdetails !== prevProps.groupDetails.groupdetails){
            let groupInfo = this.props.groupDetails.groupdetails || [];
            let assignees = groupInfo[0].assignees||[];
            if(assignees){
                assignees=assignees.map(item => {
                    item.uniqueId = item.uniqueId ? item.uniqueId : 'user_'+item.assigneeId;
                    item.firstName = item.firstName ? item.firstName : item.assigneeName;
                    item.lastName = item.lastName ? item.lastName : '';
                    item.userId = item.id ? item.id : item.assigneeId;
                    item.userType = item.type ? item.type : 'User';
                    return item;
                })
            }
            this.setState({assignedUsers:assignees})
        }
        if(prevProps.addmoreUserGroup.moreusersadded !== this.props.addmoreUserGroup.moreusersadded ){
                    setTimeout(function () {
                        beta.props.listusergroup();
                        beta.closeAddMoreUsersModal();
                        beta.setState({loading:0,groupId:0,selectedUsers:[]})
                    }, 1000)
                }
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ){
            beta.props.listusergroup();
            beta.closeNewTask();
            beta.setState({loading:0,groupId:0})

        }
    }
    showAddNewGroupModal(e,id){
        this.setState({showAddNewGroupModal:true,groupId:id})
        if(id !== 0){
            this.props.getgroupDetails({groupid:id});
        }
        let action=( id === 0?'addbuttonclick':'editbuttonclick');
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": action,
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);

    }
    closeAddNewGroupModal(){
        this.setState({showAddNewGroupModal:false,groupId:0})

    }
    showAddMoreUsersModal(e,groupId){
        this.props.getgroupDetails({groupid:groupId});
        this.setState({AddMoreUsersModal:true,groupId:groupId})

    }
    closeAddMoreUsersModal(){
        this.setState({AddMoreUsersModal:false})
    }
    usersList(searchText) {
        var user_data = { uid : "0", search : searchText? searchText : '',  offset : 100 , ignoreUsers : true, ignoreArrays :[], valueField : 'uniqueId', userOnly : true};
        this.props.users(user_data);
    }
    resultDataList( result, reference) {
        this.setState({ [reference] : result });

        if(reference === "selectedUsers"){
            if (result.length  === 0) {
                this.setState({userError:true})
            }else{
                this.setState({userError:false})
            }
        }
    }
    showOptions(ID){
        this.setState({alerterstatus :!this.state.alerterstatus,fieldID :ID})
    }
    addNewUsers(e){
        e.preventDefault();
        let formValid = 0;
        var selectedUsers = this.state.selectedUsers;
        var assignees = '';
        if (selectedUsers.length  === 0) {
            formValid++;
            this.setState({userError:true})
        }
        else{
            this.setState({userError:false})
        }
        if(formValid === 0){
            this.setState({loading: 1});
            if (selectedUsers.length > 0) {

                for (var i = 0; i < selectedUsers.length; i++) {
                    assignees = (assignees !== '') ? (assignees + ",") : "";
                    assignees += selectedUsers[i].uniqueId;
                }

            }
            let params = {
                _id: this.state.groupId,
                assignees:assignees

            };
            //////////console.log(params)
            this.props.addmoreUsers(params);
        }
    }
    showDeleteConfirm(e,id) {
        this.setState({showDeleteConfirm : !this.state.showDeleteConfirm,deleteGroupId:id});
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "deletebuttonclick",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }
    closeDeleteConfirm() {
        this.setState({showDeleteConfirm : false});
    }
    handleDelete() {
        this.setState({loading:1})
        this.props.deleteGroup({_id:  this.state.deleteGroupId});
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "deletegroup",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }
    addNewTask(e,groupid){
        let groupinfo = [];
        if(groupid){
            groupinfo =  this.props.listgroups.userGrouplist.filter(item=>item.groupId === groupid)
        }
        let selectedToUsers=[{
            phone: "",
            userType: "group",
            image: "",
            userId: groupinfo[0].groupId,
            systemType: "",
            organizationId: "",
            lastName: "",
            designation: "",
            department: groupinfo[0].groupName,
            firstName: groupinfo[0].groupName,
            email: "",
            gender: "",
            description: "",
            _id: "",
            creator: getProfile().id,
            createddate: "",
            uniqueId: "group_"+groupinfo[0].groupId
        }]
        this.setState({addNewTask:true,selectedToUsers:selectedToUsers,projectList:this.props.projects.projectData, tagList :this.props.projects.tagData})
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": "addtaskbuttonclick",
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }
    closeNewTask(){
        this.setState({addNewTask:false})
    }




    render() {
        let userGroups = this.props.listgroups.userGrouplist||[];
        let userPermissiondata = this.props.listUsersPermission.UsersPermission;
        let groupPermission=false;
        let addgroupPermission=false;
        let edtgroupPermission=false;
        let dltgroupPermission=false;
//console.log(userPermissiondata);
        userPermissiondata.forEach(
		
            function iterator(item,index){
                if(item.module === "Team" && item.module_permission === "yes"){
                    let permission = item.submodule.filter(subitem =>subitem.module === "Group" && subitem.module_permission === "yes")[0]||[];
                    if(permission.module_permission === 'yes') {
                        groupPermission = true;
                    }
					

					 let grppermission = permission.actions.filter(subitem =>subitem.action === "Add")[0]||[];
                    if(grppermission.action_permission === 'yes') {
                        addgroupPermission = true;
                    }

					let grpeditpermission = permission.actions.filter(subitem =>subitem.action === "Edit")[0]||[];
                    if(grpeditpermission.action_permission === 'yes') {
						
                        edtgroupPermission = true;
                    }
				
					let grpdltpermission = permission.actions.filter(subitem =>subitem.action === "Delete")[0]||[];
                    if(grpdltpermission.action_permission === 'yes') {
                        dltgroupPermission = true;
                    }
					
                }
            }
        );
		
		//console.log(addgroupPermission);
		//console.log(edtgroupPermission);
		//console.log(dltgroupPermission);
        return (

            <React.Fragment>
                <div className="tile-main-panel" id="content_list">
                    {((this.props.loader.loaderType === 'usergroup-list' && this.props.loader.status === true) || this.state.loading === 1 )&&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                             </span>
                    }

                    {groupPermission == true ?
                        <>
                            {userGroups.length === 0 &&
                            <div className='absolute-holder'>
                                <NoData
                                    msg={`No Groups added yet`}

                                />
                            </div>}
                        </> :
                        <div className='absolute-holder'>
                            <NoData
                                msg={`No permission to access`}

                            />
                        </div>
                    }
                    {groupPermission == true && 
                    <div className="group-wrapper">
                        {userGroups.map((list,index)=>{
                        let assignees = list.assignees? list.assignees:[];
                            return(
                            <div className="ind-group-list" key={index}>
                                <div className="group-list-header">
                                    <ul className="pj-added-user">
                                        {assignees.map((assignee,index)=>
                                        <li key={'assignee'+index}>
                                            <div className="ind-added-user-list">
                                                <img
                                                    src={USRIMG_PATH+assignee.assigneeImage}/>
                                            </div>
                                        </li>
                                       )}
                                        <li>
                                            <div className="ind-added-user-list">
                                                <button className={'assigned-user-task'} onClick={(e)=>this.showAddMoreUsersModal(e,list.groupId)} id="add-user-group"></button>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="form-option-block nav-option">
                                        <button className="ico-lib option-button"
                                                onClick={() => this.showOptions(list.groupId)}></button>
                                        {this.state.alerterstatus && (this.state.fieldID === list.groupId) &&
                                        <OutsideAlerter
                                            status={this.state.alerterstatus}
                                            Triggered={() => this.showOptions(!this.state.alerterstatus)}>
                                            <ul className="panel-option">
                                                <li>
                                                    <nav className="panel-option-click ico-lib form-assign-ico"
                                                         onClick={(e) => {
                                                             this.addNewTask(e, list.groupId);
                                                             this.showOptions(!this.state.alerterstatus)
                                                         }}
                                                    >Assign
                                                    </nav>
                                                </li>
											
											{(list.creatorid === getProfile().id || getProfile().usertype === "Superadmin" || getProfile().usertype === "Admin" || getProfile().usertype === "root" )&& 
												<>
                                                <li>
                                                    <nav className="panel-option-click ico-lib form-edit-ico"
                                                         onClick={(e) => {
                                                             this.showAddNewGroupModal(e, list.groupId);
                                                             this.showOptions(!this.state.alerterstatus)
                                                         }}>Edit
                                                    </nav>
                                                </li>
												
												
                                                <li>
                                                    <nav onClick={(e) => {
                                                        this.showDeleteConfirm(e,list.groupId);
                                                        this.showOptions(!this.state.alerterstatus)
                                                    }} className="panel-option-click ico-lib delete-task-ico">Delete
                                                    </nav>
                                                </li>
												
												</>
												}									
												{list.creatorid !== getProfile().id && getProfile().usertype === "User" && edtgroupPermission === true &&
												 <li>
                                                    <nav className="panel-option-click ico-lib form-edit-ico"
                                                         onClick={(e) => {
                                                             this.showAddNewGroupModal(e, list.groupId);
                                                             this.showOptions(!this.state.alerterstatus)
                                                         }}>Edit
                                                    </nav>
                                                </li>
												}
												{list.creatorid !== getProfile().id && getProfile().usertype === "User" && dltgroupPermission === true &&
												 <li>
                                                    <nav onClick={(e) => {
                                                        this.showDeleteConfirm(e,list.groupId);
                                                        this.showOptions(!this.state.alerterstatus)
                                                    }} className="panel-option-click ico-lib delete-task-ico">Delete
                                                    </nav>
                                                </li>
												}
                                            </ul>
                                        </OutsideAlerter>
                                        }
                                    </div>
                                </div>
                                <div className="group-block-wrap cursor" onClick={(e) => this.props.history.push("/group/details/"+btoa(list.groupId))}>
                                    <h5>{list.groupName}</h5>
                                    <p>{list.assigneeCnt} Users</p>
                                    <p>Created By <span className="mention-class">{list.creator} </span></p>
                                </div>
                            </div>
                            )}
                        )}
                    </div>
                       }
                    {groupPermission === true && addgroupPermission === true &&
                    <div className={'float-button-box'} onClick={(e) => this.showAddNewGroupModal(e, 0)}
                         title="New group">
                        <span className={'icon-in icon-plus'}></span>
                    </div>
                    }
                    {this.state.showAddNewGroupModal &&
                    <Modal title={(this.state.groupId === 0 ? 'Create New Group' : 'Update Group')} Close ={this.closeAddNewGroupModal.bind(this)}>
                        <AddNewGroup
                            AddmodalClose = {this.closeAddNewGroupModal}
                            action={this.state.groupId === 0? "create":"update"}
                            loader={this.props.loader.loaderType}
                        />

                    </Modal>
                    }
                    {this.state.AddMoreUsersModal &&

                        <div id={`dis-pop`} className={`cs-modal fade bsy-modal in show `}>
                            <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="task-head-text">Group Users</h5>
                                        <div className="modal-header-options">
                                            <button className="modal-option-button"  onClick={this.closeAddMoreUsersModal}>
                                                <span className="icon-in icon-close-in"></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="modal-body small-height-mod">
                                        {(this.props.loader.loaderType === 'usergroup-details' && this.props.loader.status === true)&&
                                        <span className="Loader-holder">
                                            <LoaderSvg/>
                                         </span>
                                        }
                                        <div id="user_alredy">
                                            {this.state.assignedUsers.map((item,index)=>
                                                <div className="sl-tag" key={index}><span className="tag-left">{item.firstName[0]}</span> <span
                                                    className="tag-right">{item.firstName}</span></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.showDeleteConfirm &&
                    <AlertModal id="delete-group"
                                title="Are you sure you want to delete?"
                                OK_button_text="Yes, Delete It"
                                OK_action={this.handleDelete}
                                showModal = {this.state.showDeleteConfirm}
                                CANCEL_action={this.closeDeleteConfirm}
                    />
                    }
                    {this.state.addNewTask &&
                    <Modal title={'Create New Task'} Close ={this.closeNewTask}>
                        <AddToDoTask
                            projectList={this.state.projectList}
                            tagList={this.state.tagList}
                            type={'user'}
                            selectedToUsers={this.state.selectedToUsers}
                        />

                    </Modal>
                    }
                </div>

            </React.Fragment>





        );
    }
}



const mapStateToProps = state => ({
    loader:state.CommonReducer.loader,
    listgroups:state.UsersReducer.listUserGroup,
    userGroupSaved:state.UsersReducer.saveUserGroup,
    listUsers:state.UsersReducer.listUsersdata,
    addmoreUserGroup:state.UsersReducer.addmoreUserGroup,
    groupDetails:state.UsersReducer.groupDetails,
    projects:state.TaskReducer.GetProjectData,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    listUsersPermission:state.UsersReducer.listUsersPermissiondata,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    users:usersActions.listTodoUsers,
    listusergroup:usersActions.listGroup,
    addmoreUsers:usersActions.addmoreGroupUsers,
    getgroupDetails:usersActions.getgroupDetails,
    deleteGroup:usersActions.deleteGroup,
    projectData:taskActions.getProjectData,
    activityTracking:rootActions.commonActions.activityTracking
})(UserGroup));


