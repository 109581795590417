import React, {Component} from 'react';

let initialElements=[
    {
    "type":"text",
        option:[
            {
                "value": "equal",
            },
            {
                "value":"contain"
            }
        ],
   "placeholder":"Enter Text"
},
    {
        "type":"textarea",
        option:[
            {
                "value": "equal",
            },
            {
                "value":"contain"
            }
        ],
        "placeholder":"Enter Text"
 },
    {
        "type":"switch",

    },
    {
        "type":"checkbox"
    },
    {
        "type":"datepicker",
        option:[
            {
                "on": "On",
                "before":"Before",
                "after":"After"
            }
        ],
        "placeholder":"Enter no of days"
    },
    {
        "type":"number",
        option:[
            {
                "greater": "Greater than ( &gt; )",
                "greatequal":"Greater than or equal ( &gt;= )",
                "equal":"Equal ( = )",
                "less":"Less than ( &lt; )",
                "lessequal":"Less than or equal ( &lt;= )"

            }
        ],
        "placeholder":"Enter no of days"
    },
    {
        "type":"email",
        option:[
            {
                "equal": "equal",
                "contain":"contain"
            }
            ],
        "placeholder":"Enter Text"
    },
    {
        "type":"dropdown"
    }

]
class Rule extends Component{
    constructor(props){
        super(props)
        this.state={
           ruleData:[]
        }
    }
    handleChange(e){
        let random=Math.round(new Date().getTime() + (Math.random() * 100));
        ////////console.log(initialElements.filter(list=>(list.type === e.target.value)))
        let mapData=initialElements.filter(list=>(list.type === e.target.value))
            mapData[0][`name`]="name-"+random

        let Ruledata=this.state.ruleData.concat(mapData)
        this.setState({ruleData:Ruledata})
    }
    labelChange=(e,index)=>{


    }
    optionChange=(e,index)=>{
alert(index)

    }

    handleRemove=(e,name)=>{
        let Rdata=this.state.ruleData.filter(list=>list.name !== name)
        this.setState({ruleData:Rdata})

    }
    render() {
        ////////console.log(this.state.ruleData)
        return(<React.Fragment>
            <select onChange={(e)=>this.handleChange(e)}>
                <option>Select</option>
                <option value={`text`}>text</option>
                <option value={`textarea`}>textarea</option>
                <option value={`number`}>number</option>
                <option value={`datepicker`}>Datepicker</option>
                <option value={`email`}>Email</option>
                <option value={`switch`}>Switch</option>
                <option value={`dropdown`}>Dropdown</option>
                <option value={`checkbox`}>Checkbox</option>
            </select>

            {this.state.ruleData.map((list,index)=>
                <React.Fragment>
                    {list.type === "checkbox" || list.type === "switch" || list.type === "dropdown" ?

                        <select onChange={(e)=>this.optionChange(e,list.name)}>
                                {list.option.map((item, index) =>
                                    <option key={`option`+index} value={item.value}>{item.value}</option>
                                )}
                            </select>

                            :
                        <React.Fragment>
                            <select onChange={(e)=>this.optionChange(e,list.name)}>
                                {list.option.map((item, index) =>
                                    <option key={`option`+index} value={item.value}>{item.value}</option>
                                )}
                            </select>

                            <input type="text" placeholder={list.placeholder} name={`name`+index} onChange={(e)=>this.labelChange(e,list.name)}/>
                        </React.Fragment>
                    }
                    <p onClick={(e)=>this.handleRemove(e,list.name)}>X</p>
                        </React.Fragment>



            )}
        </React.Fragment>)
    }

}


export default Rule