import React, { Component } from 'react';
import {withRouter} from 'react-router';
import {connect} from "react-redux";

import {getProfile} from "../../../utilities/AuthService";
import * as taskActions from "../../../actions/ToDo";
class AlertBar extends Component {
    constructor(props) {
        super(props);
        this.state={
            status:true
        }
    }
    componentDidMount() {
        let param={
            uid:getProfile().id
        }
    this.props.listingtaskAlert(param)
    }
    close=()=>{
      //  this.setState({status:false})
        this.props.showAlert(false)
    }

    render() {
        //console.log(this.props.getTaskalert)
        if(this.state.status && this.props.staAlert.sta && this.props.staAlert && (this.props.getTaskalert.length !==0 && this.props.getTaskalert.alert.success && this.props.getTaskalert.alert.data.overdue >10)){
            return (
                <button className="overdue-task-alert"  onClick={() => this.props.history.push("/task/all-overdues")}>{this.props.getTaskalert.alert.data.overdue} Overdue</button>

            );
        }
        else{
            return null
        }

    }
}
const mapStateToProps = state => ({
    getTaskalert:state.TaskReducer.getTaskalert,
    staAlert:state.TaskReducer.staAlert

});
export default  withRouter(connect(mapStateToProps, {
    listingtaskAlert:taskActions.taskAlert,
    showAlert:taskActions.showAlert
})(AlertBar));
