import React, {Component} from 'react';
import {Form} from "react-bootstrap";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as taskActions from "../../actions/ToDo"
import * as projectActions from "../../actions/Project";
import {getProfile} from "../../utilities/AuthService";
import * as usersActions from "../../actions/Users";
import {ES_INDEX} from "../../constants";
import {getUrlSegment,getAssignLabel} from "../../utilities/CustomFunctions";
import rootActions from "../../actions";
import AlertBar from "./task/AlertBar";


class FilterBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            taskUsers : [],
            projectList : []
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {

        let params ={
            "uid": getProfile().id,
            "usertype": getProfile().usertype
        }
        let that=this
        setTimeout(function () {
            that.props.getTaskHelper(params);
        }, 100)
        /*let data={
            "type": "PROJECT",
            "uid": getProfile().id,
            "systype": "root",
            "index": ES_INDEX,
        }
        this.props.projectData(data);

        let tag_data={}
        this.props.tagData(tag_data);
        var user_data = { uid : 0, usertype : 'User' , srchval : '' };
        this.props.users(user_data);*/
    }

    handleChange(e) {
        var fieldName = [e.target.id];
        var fieldValue = e.target.value;
        if(getUrlSegment(2)==='todo'){
            var category='to-me'
        }
        if(getUrlSegment(2)==='to-follow-up'){
            var category='by-me'
        }
        if(getUrlSegment(2)==='to-oversee'){
            var category='manage-task'
        }
        if(getUrlSegment(2)==='to-know'){
            var category='to-know'
        }
        if(getUrlSegment(2)==='to-comment'){
            var category='Comments'
        }
        if(getUrlSegment(2)==='to-verify'){
            var category='by-me'
            status = "Done"
        }
        if(getUrlSegment(2)==='to-respond'){
            var category='respond'
        }
        if(getUrlSegment(2)==='drafts'){
            var category='to-draft'
        }
        if(getUrlSegment(2)==='one-to-seven-days'){
            var category='by-date'
            bydue="Sevendays"
        }
        if(getUrlSegment(2)==='seven-to-fourteen-days'){
            var category='by-date'
            bydue="Fifteendays"
        }
        if(getUrlSegment(2)==='fifteen-to-thirty-days'){
            var category='by-date'
            bydue="Thirtydays"
        }
        if(getUrlSegment(2)==='thirty-to-sixty-days'){
            var category='by-date'
            bydue="Sixtydays"
        }


        if(getUrlSegment(2)==='due-today'){
            var category='by-date'
            bydue="Worktoday"
        }
        if(getUrlSegment(2)==='due-in-seven-days'){
            var category='by-date'
            bydue="Dueinsevendays"
        }
        if(getUrlSegment(2)==='due-tomorrow'){
            var category='by-date'
            bydue="Duetomorrow"
        }
        if(getUrlSegment(2)==='all-overdues'){
            var category='by-date'
            bydue="Pending"
        }
        if(getUrlSegment(2)==='due-this-month'){
            var category='by-date'
            bydue="Duemonth"
        }
        this.setState({category:category,bydue:bydue})
        if(e.target.id === 'assigned' ) {
            let  taskUsers = [];
            if(fieldValue !== '') {
                let users = this.props.listUsers.Users;
                taskUsers = users.filter( user => user.firstname.toLowerCase().includes(fieldValue.toLowerCase()) );
            }
            else{
                this.props.setFilter({ assigned : '' });
            }
            this.setState({taskUsers : taskUsers});
            if(e.target.value ===""){
                var data = {
                    category : category,
                    status :this.props.searchData.task.status,
                    uid : getProfile().id,
                    bydue : this.props.searchData.task.bydue,
                    priority :this.props.searchData.task.priority,
                    project :this.props.searchData.task.project,
                    asigntype : '',
                    username :"",
                    managetask : 'mytask',
                    srchval:this.props.searchData.task.srchval,
                    limit : 0,
                    offset  : 20,
                    systype : getProfile().usertype,
                }

                this.props.searchType('SEARCH_TASK_DATA', "","",data)
            }

        }

        else {
            this.setState({ [fieldName] : fieldValue});
            var status = "All"
            var bydue=''
            var data = {
                category : category,
                status :e.target.id==='status'?e.target.value: (this.props.searchData.task.status !== ''?this.props.searchData.task.status:status),
                uid : getProfile().id,
                bydue : bydue,
                priority : e.target.id==='priority'?e.target.value:this.props.searchData.task.priority,
                project :e.target.id==='project'?e.target.value:this.props.searchData.task.project,
                asigntype : '',
                username :this.state.user_name,
                managetask : 'mytask',
                srchval:this.props.searchData.task.srchval,
                limit : 0,
                offset  : 20,
                systype : getProfile().usertype,
            }

            this.props.searchType('SEARCH_TASK_DATA', e.target.value,e.target.id,data)
        }
    }

    selectUser(id, name) {
        //////////console.log(this.props.searchData.task.category)
        this.setState({ assigned : name,user_name:id, taskUsers : []}, function (){
                document.getElementById('assigned').value = name;
                this.props.setFilter({ assigned : id });

        var data = {
            category : this.state.category,
            status :this.props.searchData.task.status,
            uid : getProfile().id,
            bydue : this.props.searchData.task.bydue,
            priority :this.props.searchData.task.priority,
            project :this.props.searchData.task.project,
            asigntype : '',
            username :id,
            managetask : 'mytask',
            srchval:this.props.searchData.task.srchval,
            limit : 0,
            offset  : 20,
            systype : getProfile().usertype,
        }

        this.props.searchType('SEARCH_TASK_DATA', "","",data)


        });
    }

    emptyUser(){
        //alert(1)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.projects.projectData !== this.props.projects.projectData) {
            let projectList=this.props.projects.projectData;
            this.setState({ projectList : projectList});

        }
    }


    render() {
       ////////////console.log(this.props.listUsers.Users);

        let taskUsers = this.state.taskUsers;
        let urlsegment=getUrlSegment(2);
        return(
            <div className='filter-left-block'>


                <div className='select-box-holder'>
                    <Form.Group controlId="priority" className='c-select-in'>
                        <Form.Control as="select" value={this.props.searchData.task.priority}onChange={(e)=> this.handleChange(e)} _ref="priority">
                            <option value="">Priority</option>
                            <option value='Low'>Low</option>
                            <option value='Medium'>Medium</option>
                            <option value='High'>High</option>
                            <option value='Urgent'>Urgent</option>
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='select-box-holder'>
                    <Form.Group controlId="project" className='c-select-in'>
                        <Form.Control as="select" value={this.props.searchData.task.project} onChange={(e)=> this.handleChange(e)} _ref="project">
                            <option value="">Project</option>
                            {this.state.projectList.map((list,index)=>
                            <option value={list.project_id} key={`project-list`+index}>{list.project_name}</option>
                                )}
                        </Form.Control>
                    </Form.Group>
                </div>
                <div className='select-box-holder auto-complete-wrapper'>
                    <Form.Group controlId="assigned" className=''>
                        <Form.Control className="filter-input-assign" name="assigned" placeholder={getAssignLabel(urlsegment)} _ref="assigned"  onChange={(e)=> this.handleChange(e)}>
                        </Form.Control>
                    </Form.Group>
                    {taskUsers.length > 0 &&
                        <ul className="auto-complete-list">
                            {taskUsers.map((user) =>
                                <li  onClick={()=>this.selectUser(user.userid, user.firstname+" " + user.lastname)} data-id={user.userId}>{user.firstname+" " + user.lastname}</li>
                            )}
                        </ul>
                    }


                </div>
                {getUrlSegment(2) !== "to-respond" &&
                    <div className='select-box-holder'>
                        <Form.Group controlId="status" className='c-select-in'>
                            <Form.Control as="select" value={this.props.searchData.task.status}
                                          onChange={(e) => this.handleChange(e)} _ref="status">
                                <option value="">Status</option>
                                <option value='New'>New</option>
                                <option value='InProgress'>InProgress</option>
                                <option value='Done'>Completed</option>
                                <option value='Verify'>Verified</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                }
            </div>
        );
    }
}
const mapStateToProps = state => ({
    projects:state.TaskReducer.GetProjectData,
    listUsers:state.TaskReducer.listFilterUserdata,
    searchData: state.CommonReducer.searchparamdata
});
export default  withRouter(connect(mapStateToProps, {
    getTaskHelper : taskActions.getTaskHelper,
    setFilter : taskActions.setFilter,
    projectData:taskActions.getProjectData,
    users:usersActions.listTodoUsers,
    tagData : taskActions.getTagData,
    searchType: rootActions.commonActions.searchType,
})(FilterBlock));
