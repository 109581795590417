import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import moment from 'moment';
import LoaderSvg from "../../views/LoaderSVG";
import * as phaseActions from "../../../actions/Phases";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
class AddNewPhase extends Component {

    constructor(props) {
        super(props);
        this.state={
            phasename:'',
            status:"",
            phasedescription:'',
            phaseID:0,
            loading:0,
            projectId:'',
        };
        this.PhaseInputChange= this.PhaseInputChange.bind(this);
        this.CreatePhases=this.CreatePhases.bind(this);
    }

    componentDidMount() {
        if(getUrlSegment(1) === "project")
        {
            let projectId = atob(getUrlSegment(4));
            this.setState({projectId:projectId})
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.phaseData !== this.props.phaseData){
            let phaseDetails = this.props.phaseData.phaseDet;

            if(phaseDetails){
                this.setState({
                    phaseID:phaseDetails[0].phaseId,
                    phasename:phaseDetails[0].phaseName,
                    phasedescription:decodeURIComponent(escape(atob(phaseDetails[0].description))),
                })
            }
        }
    }

    PhaseInputChange(e) {
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }

    CreatePhases(event) {
        event.preventDefault();
        let formValid = 0;
        var phasename = this.state.phasename;
        var status = this.state.status;
        var phasedescription = this.state.phasedescription;
        var phaseID = (this.state.phaseID =='0' ? moment().unix() : this.state.phaseID);
        var mode = (this.state.phaseID =='0' ? 'save' : 'update');
        var projectId = this.state.projectId;
        if (this.state.phasename.trim() === '') {
            formValid++;
            document.getElementsByName('phasename')[0].classList.add('val-error');
        }

        if (this.state.phasedescription.trim() === '') {
            formValid++;
            document.getElementsByName('phasedescription')[0].classList.add('val-error');
        }
        if(formValid === 0){
            this.setState({loading:1})
            let phasedata = {
                "_id": phaseID,
                "phaseName": phasename,
                "description": phasedescription,
                "uid": getProfile().id,
                "mode": mode,
                "project_id" : projectId
            };
            console.log(phasedata)
           this.props.savephase(phasedata);

        }
    }

    render() {
        return (
            <div className="task-content-block">
                <div className="persitant-top">
                    {(this.props.phaseData.loading || this.state.loading === 1) &&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                    }
                    <div className="task-input-block">
                        <input type="text"  name="phasename" className="task-input-box--new" defaultValue={this.state.phasename}  placeholder="Name" onChange={this.PhaseInputChange} />
                    </div>
                    <div className="task-input-block text-input">
                            <textarea className="task-textarea--new" name="phasedescription" defaultValue={this.state.phasedescription}
                                      placeholder="Description" onChange={this.PhaseInputChange}></textarea>
                    </div>
                </div>
                <div className="persitant-footer">
                    <div className="group-row">
                    </div>
                    <button data-masterid="0" data-mode="save" type="button" className="general-btn" onClick={this.CreatePhases}
                            id="createportfolio">{this.state.phaseID === 0 ? 'Create' : 'Update'}
                    </button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    PortfolioInfoDet : state.SectorReducer.listallPortfolio,
    SavePhaseDet : state.PhaseReducer.SavePhase,
    phaseData : state.PhaseReducer.getPhaseDetails,
});
export default  withRouter(connect(mapStateToProps, {
    savephase:phaseActions.SavePhase,

})(AddNewPhase));



