import React, {Component} from "react";
import moment from "moment";
import OutsideAlerter from "../../views/OutsideAlerter";
import InstagramEmbed from 'react-instagram-embed';
import { TwitterTweetEmbed } from 'react-twitter-embed';

class FilterAccordian extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let count = this.props.count? this.props.count:{};
        return (
            <React.Fragment>
            
            <ul className="socia-filter">
                <li className={this.props.searchparams === "facebook" ? "active":''}>
                    <nav className="social-link fb-ico" onClick={(e)=>this.props.handleInputChange('facebook')} >Facebook ({count.facebook?count.facebook:0})</nav>
                </li>
                <li className={this.props.searchparams === "twitter" ? "active":''}>
                    <nav className="social-link tw-ico" onClick={(e)=>this.props.handleInputChange("twitter")}>Twitter ({count.twitter?count.twitter:0})</nav>
                </li>
                <li className={this.props.searchparams === "youtube" ? "active":''}>
                    <nav className="social-link ytb-ico" onClick={(e)=>this.props.handleInputChange("youtube")}>Youtube ({count.youtube?count.youtube:0})</nav>
                </li>
                <li className={this.props.searchparams === "instagram" ? "active":''}>
                    <nav className="social-link insta-ico" onClick={(e)=>this.props.handleInputChange("instagram")}>Instagram ({count.instagram?count.instagram:0})</nav>
                </li>
                {/*<li className={this.props.searchparams === "date" ? "active":''}>*/}
                {/*    <nav className="social-link bydate-ico" onClick={(e)=>this.props.showDateFilter(e,'date')}>By Date</nav>*/}
                {/*</li>*/}
            </ul>
            </React.Fragment>
        );
    }


}
class NewsMediaTile extends Component {

    render() {
        let newsmedialist = this.props.newsmedialist?this.props.newsmedialist:[];
        return (
            <React.Fragment>
                {newsmedialist.map((list,index)=>{
                   var urltype = list.urltype;
                   if(urltype === 'facebook'){
                       var type = 'fb'
                   }else if(urltype ==='youtube'){
                       var type = 'yt'
                   }else if(urltype ==='twitter'){
                       var type = 'tw'
                   }else if(urltype ==='instagram'){
                       var type = 'insta'
                   }else{
                       var type = ''
                   }
                let description = decodeURIComponent(escape(atob(list.description)))
                  return(
                      <React.Fragment key={index}>
                            <div className="ind-news-media">
                                <div className="news-media-header">

                                    <div className={`social-header ${(type === 'fb' ? "fb" : (type === 'tw' ? 'tw' : (type === 'yt' ? 'yt':(type === 'insta' ? 'insta':''))))}`}>
                                        <h5>{type === 'fb' ? "facebook.com/username" : (type === 'tw' ? 'twitter.com/username' : (type === 'yt' ? 'youtube.com/username':(type === 'insta' ? 'instagram.com/username':'')) )}
                                        </h5>
                                        <p>Posted on <span>{moment.utc(list.createdDate).format('MMM DD, YYYY')}</span>
                                        </p>
                                    </div>
                                    <div className="form-option-block">
                                        <button className="ico-lib opt-form-ico" onClick={()=>this.props.showOptions(list.Id,index)}></button>
                                        {this.props.showOption === index &&
                                        <OutsideAlerter status={this.props.showOption}
                                                        Triggered={() => this.props.showOptions(!this.props.showOption)}>
                                            <ul className="panel-option">
                                                <li>
                                                    <a className="panel-option-click ico-lib form-edit-ico"  onClick={(e)=>this.props.AddmodalOpen(e, list.Id)}>Edit</a>
                                                </li>
                                            </ul>
                                        </OutsideAlerter>
                                        }

                                    </div>
                                </div>
                                {(type === 'fb' || type === 'yt') &&
                                <div className="news-media-thumb" dangerouslySetInnerHTML={{__html: list.url}}>
                                </div>
                                }
                                {(type === 'tw') &&
                                <TwitterTweetEmbed
                                    tweetId={list.url}
                                />

                                }
                                {(type === 'insta') &&
                                <div className="news-media-thumb-if">
                                    <InstagramEmbed
                                        url={list.url}
                                        maxWidth={320}
                                        hideCaption={false}
                                        containerTagName='div'
                                        protocol=''
                                        injectScript
                                        onLoading={() => {
                                        }}
                                        onSuccess={() => {
                                        }}
                                        onAfterRender={() => {
                                        }}
                                        onFailure={() => {
                                        }}
                                    />
                                </div>
                                }
                                <div className="news-media-main">
                                    <h5>{list.title} <div className="wf-btn-block">
                                        <button className="ico-lib wf-ed" data-action="editworkflow"></button>
                                    </div></h5>
                                    <p>{description}</p>
                                </div>
                            </div>
                      </React.Fragment>
                    )}
                    )}

            </React.Fragment>
        );
    }


}
export {FilterAccordian,NewsMediaTile}