import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import UploadFile from '../../../utilities/Upload/UploadFile';
import AutoComplete from "../../views/AutoComplete"
import moment from 'moment';
import {SelectFieldsModal,CreateCategoryModal} from "./ProjectDetailData";
import {formatDate, getUrlSegment} from "../../../utilities/CustomFunctions";
import LoaderSvg from "../../views/LoaderSVG";
import * as collectionActions from "../../../actions/Collections";
import * as taskActions from "../../../actions/ToDo";
import AlertModal from "../../views/AlertModal";

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class AddNewForm extends Component {

    constructor(props) {
        super(props);

        this.state={
            inheritField:'',
            collection_name:'',
            collection_description:'',
            complaincecategory:'',
            selectedEntities : [],
            selectedCollections : [],
            selectedInheritedEntities : [],
            selectedInheritCollections : [],
            showFieldsModal : false,
            collectionid:0,
            filename: null,
            uploadedFiles : [],
            selectedTags : [],
            tags: [],
            newTags:[],
            selectedFields:[],
            showCreateCategoryModal:false,
            loading:0,
            proceedModal:false,
            collectionType:'',

        };
        this.showInheritField = this.showInheritField.bind(this);
        this.getEntities = this.getEntities.bind(this);
        this.getCollections = this.getCollections.bind(this);
        this.showFieldsModal = this.showFieldsModal.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.createCollection = this.createCollection.bind(this);
        this.getUploadedFile = this.getUploadedFile.bind(this);
        this.removeSelectedAttachment = this.removeSelectedAttachment.bind(this);
        this.getTags = this.getTags.bind(this);
        this.showFieldsModal = this.showFieldsModal.bind(this);
        this.selectedFields = this.selectedFields.bind(this);
        this.showCreateCategoryModal = this.showCreateCategoryModal.bind(this);
        this.createCategory = this.createCategory.bind(this);
        this.showProceedmodal =  this.showProceedmodal.bind(this);
        this.hideProceedmodal = this.hideProceedmodal.bind(this);
        this.proceedAddNewForm = this.proceedAddNewForm.bind(this);
        this.selectType = this.selectType.bind(this);


    }



    componentDidMount() {
        this.setState({loading: 1})
        this.props.getCategory();
        this.getEntities();
        this.getCollections();
        this.getTags();
        this.setState({loading: 0})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.createCategoryData.categoryCreated !== this.props.createCategoryData.categoryCreated){
            this.props.getCategory();
        }
        if(prevProps.collectionInfo !== this.props.collectionInfo){
            let collectionInfo = this.props.collectionInfo.collectionInfo?this.props.collectionInfo.collectionInfo:{};
            let linkedentityinfo =this.props.collectionInfo.linkedentitys ? this.props.collectionInfo.linkedentitys :[];
            let linkedinheritance =this.props.collectionInfo.linkedinheritance ? this.props.collectionInfo.linkedinheritance :[];
            let selectedCollections=[];
            let selectedEntities=[];
            let selectedInheritCollections=[];
            let selectedInheritedEntities=[];
            let uploadedFiles = this.props.collectionInfo.uploadedFiles !== '' ? this.props.collectionInfo.uploadedFiles:'';
            if(collectionInfo){
                 selectedCollections = linkedentityinfo.filter(item=> item.category === 'collection');
                 selectedEntities = linkedentityinfo.filter(item=> item.category === 'entity');
                 selectedInheritCollections=linkedinheritance.filter(item=> item.category === 'collection');
                 selectedInheritedEntities=linkedinheritance.filter(item=> item.category === 'entity')
                console.log(selectedEntities)
                this.setState({
                    inheritField:collectionInfo.inheritancetype,
                    collection_name:atob(collectionInfo.title),
                    collection_description:atob(collectionInfo.description),
                    complaincecategory:collectionInfo.complaincecategory,
                    selectedEntities : selectedEntities,
                    selectedCollections : selectedCollections,
                    selectedInheritedEntities : selectedInheritedEntities,
                    selectedInheritCollections : selectedInheritCollections,
                    collectionid:collectionInfo.cid,
                    uploadedFiles : uploadedFiles,
                    // selectedTags : collectionInfo.tagsID,
                    // selectedFields:[],
                    collectionType:collectionInfo.collectiontype,
                    selectedFields:collectionInfo.inheritedfields,

                })
            }
        }
    }


    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value})
        if (e.target.name === "complaincecategory") {

            if (e.target.value !== '' || e.target.value !== 'select') {
                e.target.classList.remove('val-error');
            }
        } else {
            if (e.target.value !== '') {
                e.target.classList.remove('val-error');
            }
        }

    }


    resultDataList( result, reference) {
        //console.log(result);
        this.setState({ [reference] : result });
    }


    showInheritField(field){
        this.setState({inheritField:field})
    }
    selectType(field)
    {
        this.setState({collectionType:field})
        console.log(field)
    }

    getEntities(searchtext) {
        this.props.getEntity({uid : getProfile().id, usertype : getProfile().usertype, searchtext : searchtext?searchtext:'', page :1, per_page : 100 });
    }

    getCollections(search) {
        let params = {group : 'All', search :search ? search : '', uid : getProfile().id, usertype : getProfile().usertype, managecollection : 0, limit : 0, offset : 100};
        this.props.collections(params);
    }

    showFieldsModal(action){
        this.setState({showFieldsModal : !this.state.showFieldsModal})
        if(this.state.inheritField === "entity"){
            var selectedInheritedEntities = this.state.selectedInheritedEntities;
            var InheritedEntities = [];
            if (selectedInheritedEntities.length > 0) {
                for (var i = 0; i < selectedInheritedEntities.length; i++) {
                    InheritedEntities.push(selectedInheritedEntities[i].id);
                }
            }
            var id = InheritedEntities ? InheritedEntities[0] : '';
        }
        else{
            var selectedInheritCollections = this.state.selectedInheritCollections;
            var InheritCollections = [];
            if (selectedInheritCollections.length > 0) {
                for (var i = 0; i < selectedInheritCollections.length; i++) {
                    //  InheritCollections = (InheritCollections !== '') ? (InheritCollections + ",") : "";
                    InheritCollections.push(selectedInheritCollections[i]._id);
                }
            }
            var id = InheritCollections ? InheritCollections[0] : '';
        }

        if(action === 'open') {
            this.props.getFormFields({_id : id});
        }
    }

    getUploadedFile(uploadedFile) {

       // let uploadedFiles = [... this.state.uploadedFiles];

       // uploadedFiles.push(uploadedFile);
       // let uploadedFiles = [uploadedFile];
        this.setState({
            filename: uploadedFile.fileName,
            uploadedFiles : uploadedFile
        });


    }

    removeSelectedAttachment() {
            this.setState({uploadedFiles: ''});
    }

    getTags(searchText){
        let data={
            "search": searchText? searchText : ''
        }
        this.props.tagData(data);
    }

    selectedFields(fields){
        this.setState({selectedFields:fields})

    }

    createCategory(data){
        this.props.createCategory(data);

    }

    showProceedmodal(){
        this.setState({proceedModal : true})
    }

    hideProceedmodal(){
        this.setState({proceedModal : false})
    }

    createCollection(e){
        e.preventDefault();

        let formValid = 0;

        if (this.state.collection_name === '') {
            formValid++;
            document.getElementsByName('collection_name')[0].classList.add('val-error');
        }
        if (this.state.collection_description === '') {
            formValid++;
            document.getElementsByName('collection_description')[0].classList.add('val-error');
        }

        if(formValid === 0){
            this.showProceedmodal();
        }

    }

    proceedAddNewForm(){
        this.setState({loading:1})
        var projectid = (atob(getUrlSegment(4)));
        var collectionid = this.state.collectionid === 0 ? moment().valueOf() : this.state.collectionid;
        var complaincecategory = this.state.complaincecategory;
        var collectionname = this.state.collection_name;
        var collectiondescription = this.state.collection_description;
        var mode = this.state.collectionid === 0? "save":"update";
        let uploadedFiles = this.state.uploadedFiles;
        var inheritField = this.state.inheritField;
        var selectedEntities = this.state.selectedEntities;
        var Entities = [];
        var selectedCollections = this.state.selectedCollections;
        var Collections = [];
        var selectedInheritedEntities = this.state.selectedInheritedEntities;
        var InheritedEntities = [];
        var selectedInheritCollections = this.state.selectedInheritCollections;
        var InheritCollections = [];
        var collectiontype = this.state.collectionType === '' ? 'default':this.state.collectionType;
        if (selectedEntities.length > 0) {
            for (var i = 0; i < selectedEntities.length; i++) {
                // Entities = (Entities !== '') ? (Entities + ",") : '';
                Entities.push(selectedEntities[i].id);
            }
        }
        if (selectedCollections.length > 0) {
            for (var i = 0; i < selectedCollections.length; i++) {
                // Collections = (Collections !== '') ? (Collections + ",") : "";
                Collections.push(selectedCollections[i]._id);
            }
        }
        if (selectedInheritedEntities.length > 0) {
            for (var i = 0; i < selectedInheritedEntities.length; i++) {
                // InheritedEntities = (InheritedEntities !== '') ? (InheritedEntities + ",") : "";
                InheritedEntities.push(selectedInheritedEntities[i].id);
            }
        }
        if (selectedInheritCollections.length > 0) {
            for (var i = 0; i < selectedInheritCollections.length; i++) {
                //  InheritCollections = (InheritCollections !== '') ? (InheritCollections + ",") : "";
                InheritCollections.push(selectedInheritCollections[i]._id);
            }
        }
        var inheritanceItem = (inheritField === "entity" ? InheritedEntities : InheritCollections);

        var tagids = '';
        var tagnames = '';
        var selectedTags = this.state.selectedTags;
        if (selectedTags.length > 0) {
            for (var i = 0; i < selectedTags.length; i++) {
                tagids = (tagids !== '') ? (tagids + ",") : "";
                if( selectedTags[i].id !== '') {
                    tagids = (tagids !== '') ? (tagids + ",") : "";
                    tagids += selectedTags[i].id;
                }
                else {
                    tagnames = (tagnames !== '') ? (tagnames + ",") : "";
                    tagnames += selectedTags[i].tag_name;
                }

            }
        }

        var fileNameArr = ( uploadedFiles &&  uploadedFiles.fileName) ? uploadedFiles.fileName.split('/'):'';
        var fileName = fileNameArr? fileNameArr.pop():'';
        var collectiondata ={
            "uid": getProfile().id,
            "collectionid": collectionid,
            "title": collectionname,
            "description": collectiondescription,
            "mode": mode,
            "complaincecategory": complaincecategory,
            "subname": "",
            "filetitle": uploadedFiles ? uploadedFiles.originalfileName : '',
            "filename": fileName,
            "filetype": uploadedFiles ? uploadedFiles.fileType:'',
            "filesize": uploadedFiles ? uploadedFiles.fileSize:'',
            "linknode": Collections,
            "linkentity": Entities,
            "tagsid": tagids,
            "tagsname": tagnames,
            "inheritancetype": inheritField,
            "inheritanceitem": inheritanceItem,
            "inheritedfields": this.state.selectedFields,
            "projectid": projectid,
            "collectiontype": collectiontype,
            "creatorname":getProfile().first_name + getProfile().last_name,
            "createdate":new Date()
        }
    this.props.saveCollections(collectiondata)

    }

    showCreateCategoryModal(){

       this.setState({showCreateCategoryModal:!this.state.showCreateCategoryModal})
    }

    render() {
        let categorylist = this.props.categorydata.categoryList;
        let formInfo = this.props.formInfo.showformdata ? this.props.formInfo.showformdata.pages : [];
        return (
            <div className="task-content-block">
                <div className="persitant-top">
                    {(this.state.loading === 1 || this.props.categorydata.loading || this.props.entitydata.loading ) &&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                    }
                    <div className="task-input-container mb-15">
                        <div className="select-box">
                            <select id="complaincecategory" name="complaincecategory" value={this.state.complaincecategory} onChange={this.handleInputChange}>
                                <option value="select">Category</option>
                                {categorylist.map((list,index) =>
                                    <option value={list._source.categoryValue} key={"list-"+index} >{list._source.categoryName}</option>
                                )}
                            </select>
                        </div>
                        <div className="append-icn-area">
                            <span className="ico-lib cat-add" data-toggle="modal" data-target="#addcategory" onClick={()=>this.showCreateCategoryModal()}></span>
                        </div>
                    </div>
                    <div className="task-inp-container" id="linkentity">
                        <div className="ind-task-input">

                            <AutoComplete
                                fieldTitle = ''
                                placeholder = 'Connect to entity'
                                sourceDataList = {this.props.entitydata.entityList}
                                searchField = 'name'
                                showIcon={false}
                                showSubLabel = {true}
                                labelFields = 'name'
                                valueField ='id'
                                subLabelField = ''
                                showProfile = {true}
                                reference='selectedEntities'
                                selectedItems = {this.state.selectedEntities}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="to_users"
                                instantSearch = {true}
                                actionMethod = {this.getEntities}
                            />
                        </div>
                    </div>

                    <div className="task-inp-container" id="linkcategory">
                        <div className="ind-task-input">

                            <AutoComplete
                                fieldTitle = ''
                                placeholder = 'Connect to another collection'
                                sourceDataList = {this.props.listAllCollections.collectionsubgroup}
                                searchField = 'title'
                                showIcon={false}
                                showSubLabel = {true}
                                labelFields = 'title'
                                valueField ='_id'
                                subLabelField = ''
                                showProfile = {true}
                                reference='selectedCollections'
                                selectedItems = {this.state.selectedCollections}
                                resultDataList = {this.resultDataList.bind(this)}
                                id="to_users"
                                instantSearch = {true}
                                actionMethod = {this.getCollections}
                            />

                        </div>
                    </div>

                    <div className="ind-panel-options radio-wrapper">
                        <label>Inheritance</label>
                        <div className="radio-block" >
                            <input type="radio" name="fieldinherit" defaultValue="entity" id="filed_entity" onClick={()=>this.showInheritField("entity")}  checked={this.state.inheritField === 'entity'? 'checked':''}/>
                                <label>Inherit Entity</label>
                        </div>
                        <div className="radio-block" >
                            <input type="radio" name="fieldinherit" defaultValue="collection" id="filed_collection"  onClick={()=>this.showInheritField("collection")} checked={this.state.inheritField === 'collection'? 'checked':''}/>
                                <label>Inherit Collection</label>
                        </div>
                        <div className="radio-block" >
                            <input type="radio" name="fieldinherit" defaultValue="none" id="filed_none"  onClick={()=>this.showInheritField("none")} />
                                <label>None</label>
                        </div>
                    </div>
                    {this.state.inheritField === "entity" &&
                        <div className="ind-task-input">
                            <AutoComplete
                                fieldTitle=''
                                placeholder = 'Search Entity'
                                sourceDataList={this.props.entitydata.entityList}
                                searchField='name'
                                showIcon={false}
                                showSubLabel={true}
                                labelFields='name'
                                valueField='id'
                                subLabelField=''
                                showProfile={true}
                                reference='selectedInheritedEntities'
                                selectedItems={this.state.selectedInheritedEntities}
                                resultDataList={this.resultDataList.bind(this)}
                                id="to_users"
                                instantSearch={true}
                                actionMethod={this.getEntities}
                                multiSelect ={false}
                            />
                        </div>
                    }
                    {this.state.inheritField === "collection" &&
                    <div className="ind-task-input">
                        <AutoComplete
                            fieldTitle=''
                            placeholder='Search Collection'
                            sourceDataList={this.props.listAllCollections.collectionsubgroup}
                            searchField='title'
                            showIcon={false}
                            showSubLabel={true}
                            labelFields='title'
                            valueField='_id'
                            subLabelField=''
                            showProfile={true}
                            reference='selectedInheritCollections'
                            selectedItems={this.state.selectedInheritCollections}
                            resultDataList={this.resultDataList.bind(this)}
                            id="to_users"
                            instantSearch={true}
                            actionMethod={this.getCollections}
                            multiSelect ={false}
                        />

                    </div>
                    }
                    {(this.state.selectedInheritCollections.length > 0 || this.state.selectedInheritedEntities.length > 0) &&
                    < div className="group-row" id="inheritfields" >
                        <span onClick={()=> this.showFieldsModal('open')}><h5>Choose fields </h5></span>
                        </div>
                    }

                    <div className="task-input-block">
                        <input type="text" placeholder="Title" name="collection_name" defaultValue={this.state.collection_name} onChange={this.handleInputChange}  className="task-input-box" />
                    </div>


                    <div className="new-task-input text-input">
                        <textarea className="task-textarea" name="collection_description" defaultValue={this.state.collection_description} onChange={this.handleInputChange}  placeholder="Description"></textarea>
                    </div>
                    <div className="ind-panel-options radio-wrapper">
                        <label>Data Related to</label>
                        <div className="radio-block" >
                            <input type="radio" name="collectionType" defaultValue="default" id="default" onClick={()=>this.selectType("default")} checked={this.state.collectionType === 'default'?'checked':''} />
                            <label>Project</label>
                        </div>
                        <div className="radio-block" >
                            <input type="radio" name="collectionType" defaultValue="iotcollection" id="iotcollection"  onClick={()=>this.selectType("iotcollection")} checked={this.state.collectionType === 'iotcollection'?'checked':''} />
                            <label>IOT</label>
                        </div>
                    </div>
                    <div className="task-inp-container">
                        <AutoComplete
                            eleClass = 'selectize-custom'
                            fieldTitle = ''
                            placeholder = 'Select tags or add new'
                            hideTitle = {false}
                            sourceDataList = {this.props.projects.tagData}
                            searchField = 'tag_name'
                            showIcon={false}
                            hideMainLabel = {true}
                            showSubLabel = {true}
                            labelFields = 'tag_name'
                            valueField ='id'
                            subLabelField = 'tag_name'
                            showProfile = {false}
                            reference='selectedTags'
                            selectedItems = {this.state.selectedTags}
                            resultDataList = {this.resultDataList.bind(this)}
                            addNewSuggestion = {true}
                            id="tags"
                            actionMethod = {this.getTags}
                        />
                    </div>
                    <div className="attachment-task-group" id="todoFileAssets">
                        { this.state.uploadedFiles.length  !== 0 && this.state.uploadedFiles.fileName  !=='' &&
                            <div className="ind-attach-list file" id={"attachment_"+this.state.uploadedFiles.fileId} key={"attachment_"+this.state.uploadedFiles.fileId}>
                                <h5>{this.state.uploadedFiles.originalfileName}</h5>
                                <button className="ico-lib cl-fl" data-parent={this.state.uploadedFiles.fileId}
                                        data-target="#delete-attachfile" data-action="unselectattachfiles" onClick={(e)=>this.removeSelectedAttachment()}></button>
                            </div>
                        }


                    </div>
                </div>
                <div className="persitant-footer">
                    <div className="group-row">
                        <h5>Upload cover Photo</h5>
                        <ul className="attach-lister">
                            <li>
                                <button className="ico-lib attach-at">
                                    <UploadFile  getUploadedFile={this.getUploadedFile.bind(this)} />
                                </button>
                            </li>
                        </ul>
                    </div>
                    <button data-reference="" data-function="validate-collection" type="button" id="createMain" className="general-btn" onClick={this.createCollection}>{this.state.collectionid === 0? 'Create':'Update'}</button>
                </div>
                {this.state.showFieldsModal &&


                <SelectFieldsModal
                    formInfo={formInfo}
                    showFieldsModal={this.showFieldsModal}
                    getselectedFields={this.selectedFields}
                    selectedFields={this.state.selectedFields}
                    loaderdata={this.props.formInfo.loading}
                />
                }
                {this.state.showCreateCategoryModal &&
                   <CreateCategoryModal
                        showCreateCategoryModal = {this.showCreateCategoryModal}
                        addCategory ={this.addCategory}
                        createCategory={this.createCategory}
                   />
                }
                {this.state.proceedModal &&
                <AlertModal id="fill-metadata"
                            title={this.state.collectionid === 0? 'Ready to create the form?':'Ready to update the form?'}
                            OK_button_text="Yes"
                            OK_action={() => this.proceedAddNewForm()}
                            showModal = {this.state.proceedModal}
                            CANCEL_action={() => this.hideProceedmodal()}
                />
                }
            </div>

        );
    }
}


const mapStateToProps = state => ({
    categorydata: state.CollectionsReducer.CategoryList,
    entitydata : state.EntityReducer.EntityList,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    formInfo : state.CollectionsReducer.showFormModal,
    listtags:state.TaskReducer.ListAllTags,
    projects:state.TaskReducer.GetProjectData,
    createCategoryData:state.CollectionsReducer.categoryCreated,
    collectionInfo:state.ProjectReducer.GetCollectionInfoDet,
});
export default  withRouter(connect(mapStateToProps, {
    collections: collectionActions.listTodoCollections,
    getCategory : collectionActions.getCategory,
    getEntity : collectionActions.searchEntity,
    getFormFields : collectionActions.showform,
    saveCollections : collectionActions.createCollections,
    tagData : taskActions.getTagData,
    createCategory : collectionActions.createCategory,
})(AddNewForm));



