import React from 'react';
import axios from 'axios';
import { Draggable, state } from 'react-page-maker';
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment, isValidDate} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {SettingsPanel, ViewData} from "./ViewData";
import SettingsModal from "./SettingsModal";
import CeTableView from "../views/content/CeTableView.js";

let graphData= [
  {
    "x": "2018 Q1",
    "y": "500",
    "z": "2018 Q1"
  },
  {
    "x": "2018 Q2",
    "y": "620",
    "z": "2018 Q2"
  }
]
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
class CeTable extends React.Component {
  constructor(props,context) {
    super(props,context);
    this.state={
      modalStatus:false,
      showColorPicker:false,
      background:'green',
      payload:{
        chartTitle:"Title",
        subTitle:"subtitle",
        subTitleEnabled : false,
        multiarachart:graphData,
        bordercolor:"",
        cardvalue:0,
        collection:"",
        fields:"",
        aggregation:'',
        graph:false,
        x:"",
        y:"",
        z:"",
        xstroke:"",
        ystroke:"",
        labelx:"",
        labely:"",
        zstroke:"red",
        labelchartTitle:"",
      },
      multiarachart : [],
      tabledata:[{
        id: "item-1",
        label: "item 1",
        field:"",
        color:"#cc332b"
      }],
      items:[{
        id: "item-1",
        label: "item 1",
        field:"",
        color:"#cc332b"
      }],
      whereCondition:[
        {
          id: "item-1",
          label: "",
          field:"",
          conditions:"",
          min:'',
          max:'',
          from:'',
          to:'',
          text:"",
          type:""
        },
      ],
      globalFilter:[
        {
          id: "item-1",
          label: "",
          field:"",
          conditions:"",
          min:'',
          max:'',
          from:'',
          to:'',
          text:"",
          type:""
        },
      ],
      ownFilterList:[
        {
          id: "item-1",
          label: "",
          field:"",
          conditions:"",
          min:'',
          max:'',
          from:'',
          to:'',
          text:"",
          type:""
        },
      ],
      showlistField:[],
      enableyaxis:false,
      enablegradient:false,
      hidearea:false,
      legend:false,
      alldata:false,
      unit:'',
      condition :[ {
        field : "",
        type : "",
        sort :""
      } ],
      tablelimit :'',
      ownFilter:false,
      relFilCnt:0,
      relFltr:false,
      setFields:[
        {
          id: "item-1",
          masterid:"",
          default:""
        },
      ]
    }


  }
  componentDidMount() {
    let ownid="multiarea"+Math.round(new Date().getTime() + (Math.random() * 100));
    this.setState({ownid:ownid,payload:this.props.payload})
    this.graphData()
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.filterCnt.date !== this.props.filterCnt.date){
      // if(this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") {
      this.graphData()
      // }
      this.setState({relFltr:true,relFilCnt:0})
    }
    if(prevProps.filterCnt.select !== this.props.filterCnt.select){
      this.graphData()
      this.setState({relFltr:true,relFilCnt:0})
    }
  }
  openSetting=()=>{
    this.setState({modalStatus:!this.state.modalStatus})
    this.props.dragStatus(!this.state.modalStatus)
  }
  handleChangeComplete = (color,param) => {
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[param]=color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  };
  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }
  borderColorChange=(color,param)=>{
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["bordercolor"] = color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleInputChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  graphData(){
    var obj = this;
    const collection =  this.props.payload && this.props.payload.collection
    const tabledata = this.props.payload && this.props.payload.graph||this.state.items
    const whereCondition = this.props.payload && this.props.payload.whereCondition||this.state.whereCondition
    const condition = this.props.payload && this.props.payload.condition||this.state.condition
    const tablelimit = this.props.payload && this.props.payload.tablelimit;
    const groupbyEnable =  this.props.payload && this.props.payload.groupbyEnable
    const bordercolor = this.props.payload && this.props.payload.bordercolor
    const globalFilter = this.props.payload && this.props.payload.globalFilter||this.state.globalFilter
    const ownFilterList = this.props.payload && this.props.payload.ownFilterList||this.state.ownFilterList
    const setFields = this.props.payload && this.props.payload.setFields ||this.state.setFields

    this.setState({ownFilterList:ownFilterList,setFields:setFields,tablelimit:tablelimit,tabledata:tabledata,bordercolor:bordercolor,items:tabledata,condition:condition,whereCondition:whereCondition})
    if(collection !=='' || collection !== undefined) {
      let collections = collection
      let bubbleLabel=""
      let groupby = '';
      let groupbyLabel = "";
      let components = tabledata;
      if(tabledata.length > 0) {
        bubbleLabel = tabledata[0].label;
        if(groupbyEnable) {
          groupbyLabel = tabledata[0].label;
          groupby = tabledata[0].field;
          components =tabledata.slice(1,tabledata.length);
        }
      }

      let filters = [];
      let numberfilters = [];
      let datefilters = [];
      if(whereCondition.length > 0) {
        for( var i=0; i< whereCondition.length;i++){
          let conditionEntry = whereCondition[i];
          if(conditionEntry && conditionEntry.field&& conditionEntry.field !== '') {
            let conditionValue = '';
            if(conditionEntry.type === 'text') {
              conditionValue = conditionEntry.text;
            }
            else if(conditionEntry.type === 'conditions') {
              conditionValue = conditionEntry.conditions;
            }
            let conditionArr = conditionValue !== '' ? conditionValue.split(',') :[];

            if(conditionEntry.type === 'datepicker') {
              let datefilter = {
                "id" : conditionEntry.field,
                "from" : conditionEntry.from,
                "to" : conditionEntry.to
              }
              datefilters.push(datefilter)
            }
            else if(conditionEntry.type === 'number') {
              let numberfilter = {
                "id" : conditionEntry.field,
                "min" : conditionEntry.min,
                "max" : conditionEntry.max
              }
              numberfilters.push(numberfilter)
            }
            else {
              if(conditionArr.length > 0) {
                let filter = {
                  "id" : conditionEntry.field,
                  "value" : conditionArr
                }
                filters.push(filter);
              }

            }

          }

        }
      }let sortCondition = [];
      if(condition.length > 0) {
        for(var j=0; j<condition.length; j++) {
          if( condition[j].field && condition[j].field !== '' && condition[j].sort && condition[j].sort !== ''
              && condition[j].type && condition[j].type !== '') {
            sortCondition.push(condition[j]);
          }
        }
      }

      this.setState({cId:collections})
      var data = {
        collectionid: collections,
        "index": ES_INDEX,
        "graph": true,
        "fieldid": "0",
        "components": components,
        "condition":sortCondition,
        "filters" : filters,
        "numberfilters" : numberfilters,
        "datefilters" : datefilters,
        "size" : tablelimit,
      }
      if(groupbyEnable) {
        data.groupbyEnable = groupbyEnable;
        data.groupby = groupby;
        data.groupbyLabel = groupbyLabel;
      }


    }



    if((collection !=='' && collection !== undefined) || getUrlSegment(1)==="collections") {
      let collections =(getUrlSegment(1)==="collections")? atob(getUrlSegment(4) ) :collection
      this.setState({cId:collections})
      //  this.props.showform({ _id : collection});
      axios({
        method: "GET",
        url: API_PATH + "collection/showform?_id=" + collections+"&index="+ES_INDEX,
        async: false,
        headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        //////console.log(res.data.data)
        obj.setState({showlistField:res.data.data});
        return res;
      }).catch(function (err) {
        return err;
      });


      axios({
        method: "POST",
        url: API_PATH + "collection/" + collection + "/collectedinfo?index="+ES_INDEX,
        async: false,
        data:data,
        headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        //console.log(res)
        let from=0
        let results = (res.data.results)
        var totalchilds = results.totalchilds
        var collectionData = [];
        var collectionIDs = [];
        var collectionFilter = [];
        var cnt = 0;
        if (results.data.length > 0) {
          results.data.map((entry, index) => {
            collectionData[index] = {};
            collectionData[index]['id'] = entry.id;
            collectionData[index]['users'] = entry.users;
            collectionIDs.push(entry.id);
            if (entry.collectedform) {
              entry.collectedform.map(a => {
                //.filter((a) => a.showlist == 'Yes')
                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['value'] : '';
                if (a.type === "attachment") {
                  collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                  collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                }

                if (a.searchable === 'Yes') {
                  if (collectionFilter[a.label] === undefined) {
                    collectionFilter[a.label] = [];
                  }
                  cnt = collectionFilter[a.label].length;
                  collectionFilter[a.label][cnt] = a.value[0]['value'];
                }
              });
            }
          });
        }
        if (from === 0) {
          obj.setState({collectionData: collectionData, totalchilds: totalchilds});
        } else {
          collectionData = obj.state.collectionData.concat(collectionData)
          //////console.log(collectionData)
          obj.setState({collectionData: collectionData});
        }
        return res;

      }).catch(function (err) {
        return err;
      });
    }

  }





  handleChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  chartSaving=()=>{
    var prjctdtlid = atob(getUrlSegment(4));
    let sharetype = getUrlSegment(1);
    let  uid = '';
    if(sharetype === 'public') {
      uid = 2;
    }
    else{
      uid = getProfile().id;
    }
    let param={
      "projectid": prjctdtlid,
      "uid": uid,
      "index": ES_INDEX,
      "type": this.props.getdashboardChange.Dashboard,
      "dashboard":state.getStorableState()
      ,
      "id": this.props.getdashboardChange.UpdateId
    }
    this.props.chartSaving(param)
    this.setState({modalStatus:false})
  }
  onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
    );

    this.setState({
      items
    });
  };
  addData=(Id,updateParam)=>{
    let data=this.state[updateParam]
    let newData={
      id: "item-"+new Date().getTime(),
      label: "item "
    }
    let _data=data.concat(newData)
    this.setState({[updateParam]:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    ////console.log(payload)
    payload[updateParam]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  removeData=(dragid,updateParam)=>{
    let data=this.state[updateParam]
    let _data=data.filter(item=>item.id !== dragid)
    this.setState({[updateParam]:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    ////console.log(payload)
    payload[updateParam]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleLabelChange=(e,dragid,whereCondition,type)=>{
    var name=''
    var fType=''
    if(e.target.name === "masterid"){
      var data = e.target.value.split("|");
      name =data[0]
      fType=data[1]
    }
    else{
      name =e.target.name
    }

    let sel=e.target
    var updatedParam = whereCondition;
    var _newState = this.state[updatedParam];
    var index=(_newState.findIndex(obj => obj["id"] === dragid))
    _newState[index][e.target.name]=name;
    _newState[index]["label"]=sel.options[sel.selectedIndex].text ==="Please Select" ? "":sel.options[sel.selectedIndex].text;
    if(e.target.name ==="masterid"){
      _newState[index]["type"]=fType;
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[whereCondition]=this.state[updatedParam]
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }

  handleColorChange=(color,param)=>{
    var _newState = this.state.items;
    var index=(_newState.findIndex(obj => obj["id"] === param))
    _newState[index]["color"]=color;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=this.state.items
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }

  handleClick=(status)=>{
    this.setState({colorpickerStatus:status})
  }

  checkBoxchange=(e)=> {
    let val;
    if (e.target.value === "false") {
      val = true
    } else {
      val = false
    }
    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = val
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }

  radioBoxchange=(e)=> {
    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }

  handleConditionChange=(e)=>{
    var _newState = this.state.condition;
    if(e.target.name ==='field') {
      let t = e.target.value.split("|");
      _newState[0]["field"] = t[0];
      _newState[0]["type"] =  t[1] === "datepicker" ?"date":t[1];
    }
    else{
      _newState[0][e.target.name] = e.target.value;
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["condition"]=this.state.condition
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }



  render() {
    const {
      id,
      showBasicContent, showPreview,
      dropzoneID,payload, parentID, name
    } = this.props;

    let sourceType= (payload && payload.sourceType)||false
    let showlistField = []
    let sourceData=[]
    if(this.state.showlistField.length !==0) {
      for (let i = 0; i <this.state.showlistField.pages.length; i++) {
        (this.state.showlistField.pages[i].components).map(list=>{
          showlistField.push(list)
        })
      }
    }
    if(sourceType.toString() === "true"){
      sourceData=  this.props.listAllCollections.ListAllCollections.data
    }
    else{
      sourceData=  this.props.EntityData.Entity.data
    }
    let containerData={
      chartTitle :  this.state.chartTitle || payload && payload.chartTitle,
      subTitle :  this.state.subTitle || payload && payload.subTitle,
      subTitleEnabled : this.state.subTitleEnabled || payload && payload.subTitleEnabled,
      data : this.state.collectionData || [],
      collection :  this.state.collection || payload && payload.collection ,
      sourceType:  sourceType,
      collectionData:sourceData,
      tablelimit :  this.state.tablelimit || payload && payload.tablelimit,
      setFields :  this.state.setFields || payload && payload.setFields ,
      showlistField:showlistField

    }
//console.log(containerData)
    if (showBasicContent) {
      return (
          <Draggable { ...this.props } >
            {
              <span  className="drag-icon">{name}</span>
            }
          </Draggable>
      )
    }

    if (showPreview) {
      return (


          <CeTableView
          containerData={containerData}
          showlistField={this.state.setFields}

      />



      );
    }
    return (
        <React.Fragment>
          <Draggable {...this.props}  draggable={!this.props.DragDisabled.dragStatus} >
            <SettingsPanel
                id={id}
                name={containerData.chartTitle?containerData.chartTitle:name}
                dropzoneID={dropzoneID}
                parentID={parentID}
                openSetting={this.openSetting}
                removeElement={<div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
            />
          </Draggable>
          {this.state.modalStatus &&
          <SettingsModal
              title={`Table  -   Settings`}
              containerData={containerData}
              colorpickerStatus={this.state.colorpickerStatus}
              modaltype={8}
              Close={this.openSetting}
              OKAction={this.chartSaving}
              cancelAction={this.openSetting}
              handleChange={this.handleChange}
              checkBoxchange={this.checkBoxchange}
              radioBoxchange={this.radioBoxchange}
              handleConditionChange={this.handleConditionChange }
              handleLabelChange={this.handleLabelChange}
              handleColorChange={this.handleColorChange}
              borderColorChange={this.borderColorChange}
              handleInputChange={this.handleInputChange}
              handleClick={this.handleClick}
              addData={this.addData}
              removeData={this.removeData}
              onDragEnd={this.onDragEnd}
          />

          }
        </React.Fragment>
    )
  }
}
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled,
  EntityData:state.EntityReducer.GetEntity,
  filterCnt:state.DynamicDashboard.getFilterCnt,


});
export default  withRouter(connect(mapStateToProps, {
  collections: collectionActions.listallcollections,
  showform : collectionActions.showform,
  chartSaving:DynamicDashboard.saveDashboarddata,
  dragStatus :DynamicDashboard.dragStatus,

})(CeTable));
