import React, {Component} from 'react';
import {Card,Tooltip,OverlayTrigger} from "react-bootstrap";
import {withRouter} from 'react-router';
import AlertModal from "./AlertModal";
import {getProfile} from "../../utilities/AuthService";
import {connect} from "react-redux";
import * as taskActions from "../../actions/ToDo";
import rootActions from "../../actions";
import * as projectActions from "../../actions/Project";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import {USRIMG_PATH} from "../../constants";
import AlertModalPool from "./AlertModalPool";
class ListCardPool extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checked : false,
            poolTaskId :'',
            poolId : '',
            poolToDoId :''
        }
       // this.manageAction = this.manageAction.bind(this);
        this.takePoolTask = this.takePoolTask.bind(this);
    }

    selectTasks(e,id) {
        e.preventDefault();
        e.stopPropagation();
        this.props.selectTasks(id);
        this.setState({ checked : !this.state.checked});
    }

    takePoolTask() {
        var data = {uid: getProfile().id, taskid: this.props.poolTaskId, poolid : this.props.poolId, workflowid : this.props.workflowId, stageid : this.props.stageId};
        this.props.takePoolTask(data);

    }

    quitPoolTask() {
        var data = {uid: getProfile().id, taskid: this.props.poolTaskId, poolid : this.props.poolId, todoid : this.props.poolToDoId, workflowid : this.props.workflowId, stageid : this.props.stageId};
        this.props.quitPoolTask(data);
    }

    navigateToTask(e, todo_id) {
        if(todo_id !== '') {
            this.props.history.push('/task/details/'+btoa(todo_id)+'/'+btoa('to-me'))
        }

    }

    render() {
        var list = this.props.list;
        let taskid = '';
        let Title = '';
        let loggedInUser = getProfile().id;
        let workflowId = '';
        let stageId = '';
        if(list) {
            Title = list.subject;

            taskid = list.masterid;
            if (list.createuser === loggedInUser) {
                taskid = "_parent||" + list.masterid;
            } else if (list.assigneeuser_id === loggedInUser) {
                taskid = list.inner_hits.childtodo.hits.hits[0]['_id'] + '||' + list.masterid;
            }

            var poolTaskId = this.props.itemId;

            var poolId = list['poolid'][0] ? list['poolid'][0]['id'] :list['poolid']['id'];
            var poolToDoId = list['todo_id'];
            workflowId = list['workflowid'];
            stageId = list['stageid'];
        }
        return (
            <React.Fragment>
            {list &&
                <React.Fragment>
                    <span key={list.Title}>
                          {this.props.index === 0 &&
                          <h4 className={'main-panel-head'}>{this.props.sectionTitle}</h4>
                          }

                        <Card className={`ind-task-list`} onClick={(e)=>this.navigateToTask(e,(list.todo_id? list.todo_id : ''))}>
                            <Card.Body
                                className={`ind-task-list-click`}>
                            <div className="ind-task-list-left">
                                {/*this.props.taskSelection === 'yes' &&
                                    <div className="select-chk-block" style={{display: "block"}}
                                         onClick={(e) => this.selectTasks(e, taskid)}>
                                        <label className="control control-checkbox" data-action="organise">
                                            <input type="checkbox" className="inp-chk"
                                                   data-child="AWuYGA-J_-zFnTCqK10r"
                                                   data-attribute="39_1561624841026" data-action="organise"
                                                   checked={this.state.checked}/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                */}

                                <OverlayTrigger
                                    key={`1`}
                                    placement={`top`}
                                    overlay={

                                        <Tooltip id={`tooltip-top`} className={'test'}>
                                            {list.priority}
                                        </Tooltip>
                                    }>
                                    <span
                                        className={`act-priority task-priority  ${list.priority.toLowerCase()}`}>{list.priority.charAt(0)}</span>
                                </OverlayTrigger>
                                <div className="task-user-block __usercard">
                                    <span className="task-user">{(list.status === "taken" || list.status === "done") ? list.status.toLowerCase()+" By "+list.assignee_name : list.status.toLowerCase()}</span>
                                    {list.status === "taken" &&
                                        <div className="user-popover-block">
                                                    <div className="user-popover-left">
                                                        <div className="user-popover-img online">
                                                            <img src={USRIMG_PATH + list.assignee_details.img_name} />
                                                        </div>
                                                        <div className="user-popover-btn-block">
                                                            <button
                                                                className="commun-button ico-lib chat-ico">Chat</button>
                                                        </div>
                                                    </div>
                                                    <div className="user-popover-right">
                                                        <div className="user-profile-details">
                                                            <h4 className="subheader">{list.assignee_details.first_name} {list.assignee_details.last_name}</h4>
                                                            <ul className="user-profile-det-list">
                                                                <li className="ico-lib prof-user-ico">{list.assignee_details.designation}</li>
                                                                <li className="ico-lib mail-ico">{list.assignee_details.email}</li>
                                                                {list.assignee_details.phone_mobile !== "" && <li className="ico-lib phone-ico">{list.assignee_details.phone_mobile}</li>}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                    </div>
                            </div>
                            <div className="ind-task-list-center">
                                {list.reassign === 'yes' && <span className="icon-in icon-forward task-forward-btn"></span>}
                                <span className="task-text">{Title}</span>
                            </div>
                            <div className="ind-task-list-right">
                                <div className="task-more-block">
                                    {list.status === 'open' &&
                                    <button data-action="take-pool-task" data-target="#take-pool" data-toggle="modal"
                                            data-pop="show-popup"
                                            type="button" className="btn btn-info pool-action-btn"
                                            onClick={(e) => this.props.manageAction(e,'take', poolTaskId, poolId, '', workflowId, stageId)}>Take</button>
                                    }
                                    {/*{list.status === 'taken' && list.assignee === loggedInUser &&*/}
                                    {/*<button data-action="quit-pool-task" data-target="#quit-pool" data-toggle="modal"*/}
                                    {/*        data-pop="show-popup"*/}
                                    {/*        type="button" className="btn btn-danger pool-action-btn"*/}
                                    {/*        onClick={(e) => this.manageAction(e,'quit', poolTaskId, poolId, poolToDoId)}>Quit</button>*/}
                                    {/*}*/}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    {this.props.showTakeConfirm && this.props.poolTaskId === poolTaskId &&
                        <AlertModalPool id="take-pool"
                                    title={"Pool Task"}
                                        desc={"Are you sure want to take this task?"}
                                    OK_button_text="Take"
                                    OK_action={() => this.takePoolTask()}
                                    showModal={this.props.showTakeConfirm}
                                    CANCEL_action={() => this.props.showTakePoolModalclose()}
                        />
                    }
                    {this.props.showQuitConfirm && this.props.poolTaskId === poolTaskId &&

                        <AlertModalPool id="quit-pool"
                                    title="Are you sure want to quit this task?"
                                    OK_button_text="Yes, Quit It"
                                    OK_action={() => this.quitPoolTask()}
                                    showModal={this.props.showQuitConfirm}
                                    CANCEL_action={() => this.props.showQuitPoolModalclose()}

                        />
                    }

                </span>

            </React.Fragment>

        }
    </React.Fragment>


        );
    }


}

const mapStateToProps = state => ({
    poolTasksData : state.TaskReducer.getPoolData
    //  menuClickData:state.CommonReducer.menuClick,
});
export default  withRouter(connect(mapStateToProps, {
    takePoolTask : taskActions.takePoolTask,
    quitPoolTask : taskActions.quitPoolTask,
})(ListCardPool));
