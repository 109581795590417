import {portfolio} from '../services/portfolio.services'
import {getProfile} from "../utilities/AuthService";
export const listallportfolio = (data) => dispatch => {
    let listallportfolio = portfolio.listportfolio(data);
    dispatch({
        type: 'PORTFOLIO_LOADER',
        loading:true,

    });
    listallportfolio.then(listallportfolio => {
        dispatch({
            type: 'LIST_All_PORTFOLIO',
            listallportfolio: listallportfolio,
            clearList : data.clearList

        })
        dispatch({
            type: 'PORTFOLIO_LOADER',
            loading:false,

        });
    })

};
export const updatesectorlist = (data) => dispatch => {
    let listmoreportfolio = portfolio.listportfolio(data);
    listmoreportfolio.then(listmoreportfolio => {
        dispatch({
            type: 'LIST_MORE_PORTFOLIO',
            listmoreportfolio: listmoreportfolio

        })
    })

};

export const getportfoliodetails = (data) => dispatch => {
    let portfoliodet = portfolio.listportfolio(data);
    dispatch({
        type: 'PORTFOLIODET_LOADER',
        loading:true,

    });
    portfoliodet.then(portfoliodet => {
        dispatch({
            type: 'GET_PORTFOLIO_DETAILS',
            portfolioDet: portfoliodet,

        })
        dispatch({
            type: 'PORTFOLIODET_LOADER',
            loading:false,

        });
    })

};
export const SavePortfolio = (params) => dispatch => {
    dispatch({
        type: 'PORTFOLIOSAVE_LOADER',
        loading:true,

    });
    let portfolioSaved = portfolio.SavePortfolio(params);

    let newPortfolio = {
        "portfolioId" : params._id,
        "portfolioName": params.portfolioName,
        "description":btoa(unescape(encodeURIComponent(params.description))),
        "projectCount": 0,
        "programCount": 0,
        "uid": getProfile().id,
        "mode": params.mode,
        "creator":params.creator
       };


    portfolioSaved.then(portfolioSaved => {

        dispatch({
            type: 'UPDATE_PORTFOLIO',
            newPortfolio: newPortfolio

        })
        dispatch({
            type: 'PORTFOLIOSAVE_LOADER',
            loading:false,

        });
    })
};

export const deleteSector = (params) => dispatch => {

    let portfoliodeleted = portfolio.DeletePortfolio(params);


    portfoliodeleted.then(portfoliodeleted => {

        dispatch({
            type: 'DELETE_PORTFOLIO',
        })
    })
};

