import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as collectionActions from "../../../actions/Collections";
import {getUrlSegment, isImage, isVideo} from "../../../utilities/CustomFunctions"
import AlertModal from "../../views/AlertModal";
import {FilterAccordion,ActionButton,SearchOptionsPanel,NewUser} from "../collections/CollectionDetailData";
import {ResultTable} from "../collections/templates/ResultTable"
import {getProfile} from "../../../utilities/AuthService";
import LoaderSvg from "../../views/LoaderSVG";
import Modal from "../../views/Modal"
import * as usersActions from "../../../actions/Users";
import AddToDoTask from "../AddToDoTask"
import SimpleModal from "../../views/SimpleModal"
import AutoComplete from "../../views/AutoComplete";
import {ES_INDEX,API_PATH, FRMSIMG_PATH, SHARED_LOGIN,USRIMG_PATH} from '../../../constants'
import rootActions from "../../../actions";
import ShowFormModal from "../../views/ShowFormModal";
import ShowFormPreview from "../../views/ShowFormPreview";
import {FileUploadHandler} from "../../../utilities/FileUpload";
import moment from "moment";
import NoData from "../../views/NoData";
/* settings nav list */
var dataNav=[
    {
        "AcTab": "dataview-tab",
        "label":"Data View",
    },
    {
        "AcTab": "exportfield-tab",
        "label":"Export Field",
    },
    {
        "AcTab": "listingfilter-tab",
        "label":"Listing & Filter",
    },
    {
        "AcTab": "permissions-tab",
        "label":"Data Level Permissions",
    },
    {
        "AcTab": "linkpermission-tab",
        "label":"Entity Permissions",
    }
]
class ProjectCollectionDetails extends Component {
    constructor(props) {
        super(props);
        this.state={
            stage: 'data',
            selectAll: false,
            checkedRow: [],
            showDeleteConfirm : false,
            collapsed: -1,
            search: '',
            filter: [],
            optionOpened: 0,
            fixedScroll: false,
            showFormModal: false,
            showPreviewFormModal : false,
            collectionid:[],
            rightTab:"dataview-tab",
            fromdate:"",
            todate:"",
            index:0,
            saveExportField:[],
            savedataView:[],
            own_record:'',
            creator_group:'',
            report_person:'',
            saveSettings:[],
            showdatefilter:'',
            csvImportMsg:'',
            savedataview: [],
            AddNew:'',
            assignUserSelect:false,
            assignUsers:[],
            showpermissionsarray:[],
            entity_perm: false,
            loadmorecount:50,
            datalevelSelect: false,
            filterDate:"",
            numberFilter:[],
            filterUserdata:[],
            recId:''
        }
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectRow = this.handleSelectRow.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.toggleAccordion = this.toggleAccordion.bind(this);
        this.handlefilterSearch = this.handlefilterSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.manageOptionsDropDown = this.manageOptionsDropDown.bind(this);
        this.shareCollection = this.shareCollection.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.goBack = this.goBack.bind(this);
        this.showFormModal = this.showFormModal.bind(this);
        this.hideFormModal = this.hideFormModal.bind(this);
        this.showPreviewFormModal = this.showPreviewFormModal.bind(this);
        this.hidePreviewForm = this.hidePreviewForm.bind(this)
        this.getUsers = this.getUsers.bind(this);
        this.showAssignedUsers = this.showAssignedUsers.bind(this);
        this.AddnewhandleAction = this.AddnewhandleAction.bind(this);
        this.linkingPermission = this.linkingPermission.bind(this);
        this.showlinkingPermission = this.showlinkingPermission.bind(this);
        this.showSettingsModal = this.showSettingsModal.bind(this);
        this.showdatalevelAssignedUsers = this.showdatalevelAssignedUsers.bind(this);
        this.saveDataLevel = this.saveDataLevel.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);

    }
    componentDidMount() {
        let collectionid = (atob(getUrlSegment(4)));
        this.setState({collectionId : collectionid });
        let paramet={
            _id:collectionid,
            uid:getProfile().id,
        }
        let param={
            from: 0,
            size: this.state.loadmorecount,
            index: ES_INDEX,
            search:'',
            "uid": getProfile().id,
            "usertype": getProfile().usertype.toLocaleLowerCase()
        }
        let params = {
            "collectionid": collectionid

        }
        this.props.getsharedSettingsData(paramet)
        this.props.collectionDetails(collectionid, param)
        this.props.collectionFilter(collectionid, param)
        this.props.getCollectionconfig(params);
        this.props.getFilterData({collectionid:collectionid})
        this.props.users(getProfile().id,'User','',ES_INDEX);
        this.props.showform({ _id : collectionid});
        const container = document.querySelector('.infinte-scroll');
        if(container) {
            container.addEventListener('scroll', this.listenToScroll);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        let collectionid = this.state.collectionId
        if(prevProps.collectionDataChanged.collection_data_modified !== this.props.collectionDataChanged.collection_data_modified ) {
            setTimeout(function () {
                beta.handleDataSearch();
            }, 1000)
        }
        if(prevProps.saveAssigndata.results !== this.props.saveAssigndata.results ) {
            this.setState({action:'',assignUserSelect:false,checkedRow:''});
            let param={
                from: 0,
                size: this.state.loadmorecount,
                index: ES_INDEX,
                search:'',
                subsearch: '',
                "uid": getProfile().id,
                "usertype": getProfile().usertype.toLocaleLowerCase()
            }
            setTimeout(function () {
                beta.props.collectionDetails(collectionid, param)

            }, 1000)
        }

        if(prevProps.csvImportData !== this.props.csvImportData) {
            console.log(this.props.csvImportData)

            if(collectionid === this.props.csvImportData.csvstatus.collectionid) {
                let param={
                    from: 0,
                    size: this.state.loadmorecount,
                    index: ES_INDEX,
                    search:'',
                    subsearch: '',
                    "uid": getProfile().id,
                    "usertype": getProfile().usertype.toLocaleLowerCase()
                }
                setTimeout(function () {
                    beta.props.collectionDetails(collectionid, param);
                }, 1000)
            }
        }

        if(this.props.getSavedForm.isFormChanged !== prevProps.getSavedForm.isFormChanged) {
            // this.props.collectionFilter(this.state.collectionId, '', []);
            let param={
                from: 0,
                size: this.state.loadmorecount,
                index: ES_INDEX,
                search:'',
                subsearch: '',
                "uid": getProfile().id,
                "usertype": getProfile().usertype.toLocaleLowerCase()
            }
            setTimeout(function () {
                beta.props.collectionFilter(collectionid, param);
                beta.props.collectionDetails(collectionid, param);
            }, 1000)
        }
        if(this.props.saveLinkingPermission.savelinkingpermission !== prevProps.saveLinkingPermission.savelinkingpermission) {
            this.setState({entity_perm: true})
            let param={
                from: 0,
                size: this.state.loadmorecount,
                index: ES_INDEX,
                search:'',
                subsearch: '',
                "uid": getProfile().id,
                "usertype": getProfile().usertype.toLocaleLowerCase()
            }
            setTimeout(function () {
                beta.props.collectionDetails(collectionid, param);
                beta.setState({entity_perm: false})
            }, 1000)
        }
        if(this.props.savadataSettings.savadatalevelSettings !== prevProps.savadataSettings.savadatalevelSettings) {
            this.setState({datalevelSelect : false});
            let data = {
                "collectionid": collectionid
            }
            setTimeout(function () {
                beta.props.getCollectionconfig(data);
            }, 1000)

        }
    }
    componentWillUnmount() {
        const container = document.querySelector('.infinte-scroll');
        if(container) {
            container.removeEventListener('scroll', this.listenToScroll)
        }
    }
    handleFromChange(){
        console.log(this.props.dateShowFrom)
        // this.setState({dateShowFrom: this.props.dateShowFrom})
    }
    showFormModal(e, collecteddataid) {

        let collectionid = this.state.collectionId
        //this.props.showform({ _id : collectionid});
        this.props.resetForm();
        if(collecteddataid){
            this.props.getFormData({ _id : collecteddataid, collectionid : collectionid});
        }

        this.setState({
            formName : this.props.collectionData.CollectionInfo.title,
            collectionid : collectionid,
            collecteddataid : collecteddataid,
            showFormModal: true
        })
    }
    showSettingsModal(){

        this.setState({
            assignUserSelect: true
        })
    }
    hideFormModal(id) {
        this.setState({
            showFormModal: false
        })
    }
    showPreviewFormModal(e,id,index){
        let collectionid = this.state.collectionId
        this.props.getFormData({ _id : id, collectionid : collectionid});
        this.setState({
            collectionid : collectionid,
            showPreviewForm : true,
            index:index,
            _id : id
        })
    }
    hidePreviewForm(){
        this.setState({
            showPreviewForm: false
        })
    }
    showDeleteConfirm() {
        this.setState({showDeleteConfirm : !this.state.showDeleteConfirm});
    }
    handleDelete() {
        this.showDeleteConfirm();
        let collectionid = this.state.collectionId
        let that = this;
        this.props.collectionDataDelete(collectionid,  getProfile().id, this.state.checkedRow);
        setTimeout(function () {
            that.props.collectionFilter(collectionid, '', []);
            that.setState({
                'search': '',
                'filter': []
            });
        }, 3000);
    }
    selectExport=()=>{
        let title=(this.props.collectionData.CollectionInfo.title)
        let  collectionid = (atob(getUrlSegment(4)));
        var url = API_PATH+"/export/exportexcel";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.responseType = "blob";

        xhr.onreadystatechange = function() {

            if (this.readyState == 4 && this.status == 200) {
                var blob = this.response; console.log(blob)
                var header = xhr.getResponseHeader('Content-Disposition');
                var filename = title+".xls";
                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else{
                    var downloadLink = window.document.createElement('a');
                    var contentTypeHeader = xhr.getResponseHeader("Content-Type");
                    downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
                    downloadLink.download = filename;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
            }
        };
        xhr.send("ids="+this.state.checkedRow+"&collectionid="+collectionid+"&index="+ES_INDEX);

    }
    handleSearch(e) {
        let q = e.target.value;
        this.setState({search: q});
        this.handleDataSearch(q, this.state.filter);
    }
    handleSelectAll() {
        let checkedRow = [];
        if(this.state.selectAll === false) {
            checkedRow = this.props.collectionData.collectionIDs;
        }
        this.setState({
            selectAll: !this.state.selectAll,
            checkedRow: checkedRow
        });
    }
    handleSelectRow(id) {
        let checkedRow = [...this.state.checkedRow];
        (checkedRow.indexOf(id) > -1) ? checkedRow.splice(checkedRow.indexOf(id), 1) : checkedRow.push(id);
        this.setState({checkedRow: checkedRow});
    }
    showdatalevelAssignedUsers() {
        console.log(this.state.saveSettings)
        let saveSettings = [];
        this.state.saveSettings.map(item=> { item.name = (item.name ? item.name.toLowerCase(): '');
            item.user_id = item.permissionto;
            item.user_type = item.category;
            return item;});
        this.setState({datalevelSelect: !this.state.datalevelSelect});
    }
    /* assigned users popup */
    showAssignedUsers(assignUsers,recId) {
        let newUsers=[]
        assignUsers.map((list)=>
            newUsers.push(this.props.listUsers.data && this.props.listUsers.data.filter(item =>(item.user_id === list.toString() && item.category === "user")))
        )
        let filterUserdata=[]
        for(var i=0; i< newUsers.length ; i++) {
            filterUserdata.push(newUsers[i][0])
        }
        this.setState({assignUserSelect: !this.state.assignUserSelect, assignUsers: assignUsers,filterUserdata:filterUserdata,recId:[recId]});
    }
    handlefilterSearch(item,masterid) {
        console.log(masterid)
        if(item !=='clear') {
            let filter = [...this.state.filter];
            (filter.indexOf(item) > -1) ? filter.splice(filter.indexOf(item), 1) : filter.push(item);
            this.setState({filter: filter});
            this.handleDataSearch(this.state.search, filter,masterid);
        }
        else{
            this.setState({filter: []});
            this.handleDataSearch(this.state.q, '');
        }

    }
    toggleAccordion(id) {
        let collapsed = (this.state.collapsed === id) ? -1 : id;
        this.setState({collapsed: collapsed})
    }
    handleDataSearch=(search = '', filter = [],masterid="")=> {
        let  collectionid = (atob(getUrlSegment(4)));
        let flterData=[]
        if(filter.length !==0) {
            flterData= [{"id": masterid, "value": filter}]
        }
        let param={
            "search":search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid":getProfile().id,
            "fromdate": "",
            "todate": "",
            "filters": flterData,
            "datefilters": [],
            "numberfilters": [],
            "dayfilters": [],

        }
        this.props.collectionDetails(collectionid, param)


    }
    dateWise=(e,period,label)=>{
        let  collectionid = (atob(getUrlSegment(4)));
        let fromdate=this.state.fromdate
        let todate=this.state.todate

        if(period === "reset") {
            fromdate= ""
            this.setState({filterDate: "" });
        }
        if(label === "fromdate") {
            fromdate= ""
            this.setState({fromdate: "" });
        }
        if(label === "todate") {
            todate= ""
            this.setState({todate: "" });
        }
        if(e.target.value === "today" && period !== "reset" && label !== "fromdate" && label !== "todate"){
            fromdate= moment.utc().format('YYYY-MM-DD')
            this.setState({filterDate: e.target.value ,fromdate: "" ,todate: ""});
        }
        if(e.target.value !== "today" && period !== "reset" && label !== "fromdate" && label !== "todate"){
            fromdate= moment().subtract(e.target.value, period).format('YYYY-MM-DD');
            this.setState({filterDate: label,fromdate: "" ,todate: ""});
        }
        let param={
            "search":this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid":getProfile().id,
            "fromdate": fromdate,
            "todate": todate,
            "filters": [],
            "datefilters": [],
            "numberfilters": [],
            "dayfilters": [],

        }
        this.props.collectionDetails(collectionid, param)
    }
    dateRange=(formattedDate,label)=>{
        this.setState({filterDate: "" ,[label]:formattedDate})
        let fromdate=(label==="fromdate"?formattedDate:this.state.fromdate)
        let todate=(label==="todate"?formattedDate:this.state.todate)
        let param={
            "search":this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid":getProfile().id,
            "fromdate": fromdate,
            "todate": todate,
            "filters": [],
            "datefilters": [],
            "numberfilters": [],
            "dayfilters": [],

        }
        let  collectionid = (atob(getUrlSegment(4)));
        this.props.collectionDetails(collectionid, param)
    }
    numberFilter=(e,master,range)=>{
        let numberFilter=[]
        /* To get existing total filter values */
        numberFilter = [...this.state.numberFilter];
        console.log(numberFilter)
        let numberFilterNew=[]
        let numberFilterOld=[]
        var num=[]
        /* To get Existing filter value based on master id*/
        numberFilterNew=numberFilter.filter((item) => item.id.toString() === master.toString())
        /* create or update array based results*/
        if(numberFilterNew.length ===0){
            num= {
                "id" :master,
                "min" :range ==="min"?e.target.value:'' ,
                "max" :range ==="max"?e.target.value:''
            }
        }else{
            numberFilterNew[0][range]=e.target.value
            num=numberFilterNew
        }

        numberFilterOld= numberFilter.filter((item) => item.id.toString() !== master.toString())
        let newResult=numberFilterOld.concat(num)
        this.setState({numberFilter:newResult})
        let param={
            "search":this.state.search,
            "index": ES_INDEX,
            "usertype": getProfile().usertype,
            "uid":getProfile().id,
            "fromdate": this.state.fromdate,
            "todate": this.state.todate,
            "filters": [],
            "datefilters": [],
            "numberfilters": newResult,
            "dayfilters": [],

        }
        let collectionid = this.state.collectionId
        this.props.collectionDetails(collectionid, param)
        // console.log(numberFilter.push(num))
    }
    getdatalevelUsers(searchText) {
        console.log(searchText)
        var user_data = { uid : "0", search : searchText? searchText : '',  offset : 100 , ignoreUsers : true, valueField : 'uniqueId' };
        this.props.users(user_data);
    }
    getUsers(searchText) {
        var user_data = { uid : "0", search : searchText? searchText : '',  offset : 100 , ignoreUsers : true, valueField : 'uniqueId' };
        this.props.users(user_data);
    }
    listenToScroll = () => {
        const container = document.querySelector('.infinte-scroll')
        var scroll = container.scrollTop;
        var formTopHt = document.querySelector('.form-detail-top').offsetHeight;
        if(scroll > formTopHt) {
            this.setState({fixedScroll: true});
        } else {
            if(scroll == 0) {
                this.setState({fixedScroll: false});
            }
        }
    }
    loadmoreCollectionData=()=>{
        if(this.props.collectionData.totalchilds >=this.props.collectionData.CollectionData.length ){
            let collectionid = this.state.collectionId
            let params={
                from: this.props.collectionData.CollectionData.length,
                size: this.props.collectionData.CollectionData.length + this.state.loadmorecount,
                index: ES_INDEX,
                search:'',
                subsearch: ''
            }
            this.props.loadmoreCollectionDetails(collectionid, params)
        }
    }
    manageOptionsDropDown() {
        this.setState({optionOpened: !this.state.optionOpened});
    }
    handleAction =(e, act) =>{
        let collection = Object.assign({}, this.props.collectionData.CollectionInfo);
        collection._id = collection.masterid;
        collection.title = btoa(collection.title);
        console.log(collection.linked_formpermission)
        let id = collection._id;
        e.stopPropagation();
        this.setState({
            action:act,
            collectionId: id,
            optionOpened: 0,
            selectedCollection: [collection],
            selectedToUsers: [],
            showpermissionsarray: collection.linked_formpermission
        });
        let param={
            collectionid:id
        }
        this.props.exportField(param)
    }
    AddnewhandleAction =(e, act, addnew) =>{
        this.setState({
            action:act,
            AddNew: addnew,
        });
    }
    modalOpen(){
        this.setState({action:'', assignUserSelect : false, datalevelSelect : false});
    }
    saveAssign=()=>{
        let assignees = []
        let result = this.state.newData;
        console.log(result)
        for (var i=0; i < result.length; i++) {
            let newElem = {
                "id": result[i].user_id,
                "type": result[i].user_type,
            };
            assignees.push(newElem);
        }
        let collectionid = this.state.collectionId
        let param={
            "collectionid": collectionid,
            "assignees":assignees,
            "index": ES_INDEX,
            "uid": getProfile().id,
            "records":this.state.checkedRow,
        }
        this.props.saveAssignData(param)
    }
    resultDataList=( result, reference)=> {
        this.setState({ [reference] : result , newData : result});
    }
    saveDataLevel=( result, reference)=> {
        this.setState({ [reference] : result , newData : result,filterUserdata:result});
    }
    shareCollection() {
        let users = [];
        let cnt = 0;
        this.state.selectedToUsers.map(a => {
            let user = {"type": a.user_type ,"id": + a.id };
            users.push(user);
        });
        this.props.sharecollection(this.state.collectionId, getProfile().id, users);
        this._interval = setInterval(() => {
            if(this.props.sharedCollection.shared === 1) {
                if(this.props.sharedCollection.msg === '') {
                    this.setState({action: ''});
                    this.props.collectionFilter(this.state.collectionId, '', []);

                } else {
                    alert(this.props.sharedCollection.msg)
                }
                clearInterval(this._interval);
            }
        }, 500);

    }
    goBack() {
        this.props.history.goBack();
    }
    getrightTabid=(tab)=>{
        console.log(this.props.GetCollectionconfig)
        let saveSettings = this.props.GetCollectionconfig && this.props.GetCollectionconfig.getcollectionconfig && this.props.GetCollectionconfig.getcollectionconfig.settings ? this.props.GetCollectionconfig.getcollectionconfig.settings.data : '';
        console.log(saveSettings)
        saveSettings.map(item => {
            item.id = item.id ? item.id : item.permissionto;
            item.name = item.name? item.name: item.fullname;
            item.user_type = item.user_type? item.user_type: item.category;
            item.uniqueId = item.uniqueId? item.uniqueId: item.category.toLowerCase()+"_"+item.id;
            return item;
        })
        this.setState({rightTab : tab, saveSettings : saveSettings})
    }
    changeExportField=(masterid)=>{
        console.log(masterid)
        let data =this.state.saveExportField.length===0?this.props.exportfield.data:this.state.saveExportField
        console.log(data)
        let indx=(data.findIndex(obj => obj["masterid"] === masterid))
        data[indx]["selected"]=!data[indx].selected
        this.setState({saveExportField:data})
    }
    saveExportField=()=>{
        let data=this.state.saveExportField.filter((item) => item.selected === true);
        var dataString = JSON.stringify(data);
        let param={
            collectionid: atob(getUrlSegment(4)),
            uid:getProfile().id,
            rec:dataString,
            "index": ES_INDEX
        }
        this.props.saveFieldData(param)
    }
    dataView=(e,type)=>{
        let status= e.target.value ==="true"?false:true
        this.setState({[type]:status})

        let data =this.state.savedataView.length===0?this.props.GetCollectionconfig.getcollectionconfig.dataview.data:this.state.savedataView
        if(data.length ===0){
            let collectionid = this.state.collectionId
            data=[ {
                "collectionid": collectionid,
                "own_record": false,
                "creator_group": false,
                "report_person": false,
            }]
        }
        data[0][type]=status
        this.setState({savedataView:data})
    }
    saveDataView=()=>{
        let dataView=[]
        let collectionid = this.state.collectionId
        if(this.state.savedataView.length !==0) {
            if (this.state.savedataView[0].creator_group) {
                dataView.push("creator_group")
            }
            if (this.state.savedataView[0].own_record) {
                dataView.push("own_record")
            }
            if (this.state.savedataView[0].report_person) {
                dataView.push("report_person")
            }
            let param = {
                "collectionid": collectionid,
                "premission": dataView,
                "index": ES_INDEX,
                "uid": getProfile().id
            }
            this.props.savaDataView(param)
        }

    }
    permission=(e,type,index)=>{
        let status= (e.target.value ==="true")?false:true;
        let data = this.state.saveSettings;
        data[index][type] = status
        this.setState({saveSettings:data})

    }
    savePermission=()=>{
        console.log(this.state.saveSettings)
        let savedataview = []
        let data = this.state.saveSettings;
        for (var i=0; i < data.length; i++) {
            let masterArr = [];
            if (data[i].addedit === true) {
                masterArr.push('Add')
            }
            if (data[i].export === true) {
                masterArr.push('Export')
            }
            if (data[i].assign === true) {
                masterArr.push('Assign')
            }
            if (data[i].alldata === true) {
                masterArr.push('Alldata')
            }
            if (data[i].rule === true) {
                masterArr.push('Rule')
            }
            if (data[i].delete === true) {
                masterArr.push('Delete')
            }
            let newElem = {
                "id": data[i].permissionto,
                "category": data[i].category,
                "master": masterArr
            };
            savedataview.push(newElem);
        }
        console.log(savedataview)
        this.setState({dataView:savedataview})
        if(savedataview.length !== 0){
            let collectionid=this.state.saveSettings[0].master_id;
            let param= {
                "collectionid": collectionid,
                "PermissionVals":savedataview,
                "index": ES_INDEX,
                "uid": getProfile().id,
                "flag": 0
            }
            this.props.savaDataSettings(param)
        }


    }
    showlinkingPermission=(e, id)=> {

        let showpermissionsarray = [...this.state.showpermissionsarray];
        (showpermissionsarray.indexOf(id) > -1) ? showpermissionsarray.splice(showpermissionsarray.indexOf(id), 1) : showpermissionsarray.push(id);
        this.setState({showpermissionsarray: showpermissionsarray, entity_perm: false});
        console.log(showpermissionsarray)

    }
    linkingPermission=()=> {
        let showlinkingpermissions = []
        console.log(this.props.showFormModal.showformdata)
        this.setState({entity_perm: false})
        let param={
            "_id": atob(getUrlSegment(4)),
            "index": ES_INDEX,
            "premission": this.state.showpermissionsarray,
        }
        this.props.savelinkingpermission(param)
    }
    showFilter=(e)=>{
        let status= e.target.value ==="true"?false:true
        this.setState({"showdatefilter":status})
    }
    saveFilter=()=> {
        let param={
            "collectionid": this.props.collectionData.CollectionInfo.masterid,
            "index": ES_INDEX,
            "showdatefilter": this.state.showdatefilter,
            "category": "collection"
        }
        this.props.saveFilter(param)
    }
    exportAll =()=>{
        let title=(this.props.collectionData.CollectionInfo.title)
        let  collectionid = (atob(getUrlSegment(4)));
        var url = API_PATH+"/export/exportall";
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhr.responseType = "blob";
        xhr.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                var blob = this.response; console.log(blob)
                var header = xhr.getResponseHeader('Content-Disposition');
                var filename = title+".xls";
                if(window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                }
                else{
                    var downloadLink = window.document.createElement('a');
                    var contentTypeHeader = xhr.getResponseHeader("Content-Type");
                    downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
                    downloadLink.download = filename;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
            }
        };
        xhr.send("_id="+this.props.collectionData.CollectionInfo.masterid+"&index="+ES_INDEX+"&uid="+getProfile().id+"&usertype="+getProfile().usertype);

    }
    handleFile=e=> {
        let name =""
        const file = e.target.files;
        var fileData = []
        var j = 0;
        var _id= Math.round(new Date().getTime() + (Math.random() * 100));
        document.getElementById("progress-panel-container").style.display = "block";
        var progressContainer = document.getElementById('progress-holder')
        for (var i = 0; i < file.length; i++) {
            // FileUploadHandler(bucketName, albumName, region, accessKeyId, secretAccessKey, name, file[i], "discussion", file.length, i + 1)
            FileUploadHandler(name, file[i], progressContainer, file.length, i + 1)
                .then(data => {
                    this.setState({csvImportMsg:"CSV Import Is Under Process."})
                    document.getElementById("progress-panel-container").style.display = "none";
                    let param={
                        collectionid:this.props.collectionData.CollectionInfo.masterid,
                        filepath:data.location,
                        index:ES_INDEX,
                        uid:getProfile().id
                    }
                    this.props.importCollectionData(param)
                    let that=this
                    setTimeout(function()
                    {
                        that.setState({csvImportMsg:''})
                        that.modalOpen()
                    }, 4000);

                })
                .catch(err => {
                    this.setState({messageData:''})
                })



        }

    }
    backtoListing=()=>{
        this.setState({action:""})
    }
    /* Assign data from settings Permissions */
    savedatalevelUsers=()=>{
        // console.log(this.state.checkedRow)
        console.log(this.state.saveSettings)
        let collectionid = this.state.collectionId
        let users = this.state.saveSettings;
        let permissionVal = [];
        for(var i=0; i< users.length ; i++) {
            let masterArr = [];
            if(users[i].user_type === "user") {
                if (users[i].addedit === true) {
                    masterArr.push('Add')
                }
                if (users[i].export === true) {
                    masterArr.push('Export')
                }
                if (users[i].assign === true) {
                    masterArr.push('Assign')
                }
                if (users[i].alldata === true || users[i].system_type) {
                    masterArr.push('Alldata')
                }
                if (users[i].rule === true) {
                    masterArr.push('Rule')
                }
                if (users[i].delete === true) {
                    masterArr.push('Delete')
                }
            }
            if(users[i].user_type === "group") {
                masterArr.push('Alldata')
            }
            if(users[i].user_type === "function") {
                masterArr.push('Alldata')
            }

            let user = {
                "id": users[i].user_id,
                "category": users[i].user_type,
                "master": masterArr
            };
            permissionVal.push(user);

        }

        let data = {
            "collectionid": collectionid,
            "index": ES_INDEX,
            "uid": getProfile().id ,
            "flag": 0,
            "PermissionVals": permissionVal
        }
        this.props.savaDatalevelSettings(data);

    }
    /* Assign users from resul table icon */
    singleRecorddAssign=()=>{
        let collectionid = this.state.collectionId
        let users = this.state.saveSettings;
        let permissionVal = [];
        for(var i=0; i< users.length ; i++) {
            let user = {
                "id": users[i].user_id,
                "type": users[i].user_type,
            };
            permissionVal.push(user);
        }

        let data = {
            "collectionid": collectionid,
            "index": ES_INDEX,
            "uid": getProfile().id ,
            "assignees": permissionVal,
            "records":this.state.recId
        }
        this.props.saveAssignData(data);

    }
    /* Multi User Assign */
    render() {
        let collection = this.props.collectionData;
        let dataview = this.props.GetCollectionconfig.getcollectionconfig.dataview;
        let settings = this.props.GetCollectionconfig.getcollectionconfig.settings;
        let collectionFilter = this.props.filterDatadetails.filterData
        let listuser = this.props.listUsers.data;
        let datausers = this.props.listUsers.Users.length > 0 ? this.props.listUsers.Users : '';
        let showlinkingpermission = []
        let permissionsSelected = this.props.sharedCollectionData ? this.props.sharedCollectionData.collectionsettings : '';
        if(this.props.showFormModal.showformdata.length !==0) {

            for (let i = 0; i < this.props.showFormModal.showformdata.pages.length; i++) {
                (this.props.showFormModal.showformdata.pages[i].components.filter(list=>list.externallink === 'Yes')).map(list=>{
                    showlinkingpermission.push(list)

                })
            }
        }
        let collectionid = this.state.collectionId
        let Userpermission=((permissionsSelected.length > 0 &&  permissionsSelected[0].assign) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || (collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser.toString()) === getProfile().id.toString()))
        console.log(this.state.selectedToUsers)
        console.log(this.props.listUsers.Users)
        return (
            <React.Fragment>
                <div className={`right-content-wrapper${this.state.action === 'settings' ? " project-tab-rise":""}`} key={`collection-details-data`}>
                    <div className="form-detail-container data-view field-form small-no-tab with-opt with-pj-sidebar">
                        <div className="form-detail-panel">
                            <div className="form-detail-right">
                                <div className={`form-detail-top project-top`}>
                                    <span className="form-detail-top-left common-bk-button" onClick={this.goBack}>
                                        <h5>{collection.CollectionInfo.title}</h5>
                                        <p>{collection.CollectionInfo.description}</p>

                                    </span>
                                    {this.state.action === 'settings' &&
                                    <div className="task-nav-left bg-back bread-crumb">
                                        <button className="backto-list"
                                                onClick={(e)=>this.backtoListing(e)}>Back To Listing</button>
                                    </div>
                                    }

                                    <div className="form-detail-top-right">
                                        <SearchOptionsPanel
                                            handleSearch={this.handleSearch}
                                            state={this.state}
                                            showDeleteConfirm={this.showDeleteConfirm}
                                            selectExport={this.selectExport}
                                            handleAction={this.handleAction}
                                            permissionsSelected={permissionsSelected}
                                            createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                        />
                                        {this.state.checkedRow.length === 0 &&
                                        <div onClick={this.exportAll}
                                             id="exportExcel" className="general-btn ico-lib ex-cel-ico" target={"_blank"}>Export
                                            All
                                        </div>
                                        }
                                        <ActionButton
                                            handleAction={this.handleAction}
                                            collectionid={collectionid}
                                            createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.action !== 'settings' &&
                        <div className={`data-holder form-wrap ${this.props.haveLoader.loading === 1 ? 'with-loader' : ''}`} id="formData" style={{display: this.state.stage === 'data' ? "block" : "none"}}>
                            {this.props.loader.loaderType === 'collection-details' && this.props.loader.status === true &&
                            <span className="Loader-holder">
                                        <LoaderSvg/>
                                    </span>
                            }
                            <div className="side-tab">
                                <div className="data-holder-left">
                                    <div className="mob-filter-header">
                                        <h5>Filter By</h5>
                                        <button className="close-ico" data-action="filter-close"></button>
                                    </div>
                                    {this.state.action !== 'settings' &&
                                    <FilterAccordion
                                        totalCount={collection.totalchilds}
                                        filterData={collectionFilter}
                                        handlefilterSearch={this.handlefilterSearch}
                                        dateWise={this.dateWise}
                                        dateRange={this.dateRange}
                                        filter={this.state.filter}
                                        fromdate={this.state.fromdate}
                                        todate={this.state.todate}
                                        filterDate={this.state.filterDate}
                                        numberFilter={this.numberFilter}
                                        showdatefilter={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.showdatefilter}
                                        handleFromChange={this.handleFromChange()}
                                    />
                                    }

                                    <div className="mob-filter-footer">
                                        <button type="button" data-action="filter-close" className="general-btn">Apply</button>
                                    </div>
                                </div>
                                <div className="data-holder-right">
                                    {this.state.action !== 'settings' &&
                                    <ResultTable
                                        label={this.props.showFormModal}
                                        collection={collection}
                                        handleSelectAll={this.handleSelectAll}
                                        showPreviewFormModal={this.showPreviewFormModal}
                                        state={this.state}
                                        handleSelectRow={this.handleSelectRow}
                                        showAssignedUsers={this.showAssignedUsers}
                                        loadmoreCollectionData={this.loadmoreCollectionData}
                                        showFormModal = {this.showFormModal}
                                        key={`result-table`}
                                        permissionsSelected = {permissionsSelected}
                                        createuser={collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser}
                                    />
                                    }


                                </div>
                            </div>

                        </div>
                        }
                        {(this.state.action === 'settings' || this.state.AddNew === 'addnew') &&
                        <div className="data-holder form-set" >
                            {this.props.loader.loaderType === 'collection-details' && this.props.loader.status === true &&
                            <span className="Loader-holder">
                                        <LoaderSvg/>
                                    </span>
                            }
                            <div className="side-tab">
                                <div className="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                    {dataNav.map((label,index) =>

                                        <nav key={`nav-link`+index} onClick={(e) => {this.getrightTabid(label.AcTab)}} className={this.state.rightTab ===  label.AcTab ? 'nav-link-side  active': 'nav-link-side'} id="v-pills-home-tab" data-toggle="pill"  role="tab" aria-controls="v-pills-home" aria-selected="true" style={{"cursor":"pointer"}}>
                                            {label.label}</nav>

                                    )}

                                </div>
                                <div className="tab-content" id="v-pills-tabContent">
                                    {(this.state.rightTab === "dataview-tab") &&
                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                         aria-labelledby="v-pills-home-tab">
                                        <div className="tab-wrap-block">
                                            <h5>Display Settings</h5>
                                            <p>Select the viewing permission of collection data</p>
                                            <div className="next-stage-selector">
                                                <div className="select-chk-block" style={{"display": "block"}}>
                                                    <label className="control control-checkbox">Own Records
                                                        <input type="checkbox" className="inp-chk" id="text_searchable" name="dataview" value={this.state.own_record !==''?this.state.own_record: (dataview.data.length !==0? dataview.data[0].own_record:this.state.own_record)}
                                                               defaultChecked={dataview.data.length !==0&&dataview.data[0].own_record} onClick={(e)=>this.dataView(e,"own_record")}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                                <div className="select-chk-block" style={{"display": "block"}}>
                                                    <label className="control control-checkbox">Creator Groups
                                                        <input type="checkbox" className="inp-chk" id="text_searchable" name="dataview"
                                                               value={this.state.creator_group !==''?this.state.creator_group:(dataview.data.length !==0? dataview.data[0].creator_group:'') }
                                                               defaultChecked={dataview.data.length !==0 &&dataview.data[0].creator_group}
                                                               onClick={(e)=>this.dataView(e,"creator_group")} />
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>
                                                <div className="select-chk-block" style={{"display": "block"}}>
                                                    <label className="control control-checkbox">Creator Reporting Person
                                                        <input type="checkbox" className="inp-chk" id="text_searchable" name="dataview"
                                                               value={this.state.report_person !== ''? this.state.report_person: (dataview.data.length !==0? dataview.data[0].report_person:'')}
                                                               defaultChecked={dataview.data.length !==0 && dataview.data[0].report_person} onClick={(e)=>this.dataView(e,"report_person")}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>

                                            </div>
                                            <div className="task-submit-block">
                                                <div></div>
                                                <button className="general-btn  focus-resolve" id="savedata_viewpermission" onClick={this.saveDataView}>Save</button>
                                            </div>
                                        </div>
                                    </div>

                                    }
                                    {(this.state.rightTab === "exportfield-tab" && this.props.exportfield.data.length !==0) ?
                                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                             aria-labelledby="v-pills-home-tab">
                                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                                 aria-labelledby="v-pills-home-tab">
                                                <div className="tab-wrap-block">
                                                    <h5>Export Field</h5>
                                                    <p>Select the fields to be exported from linked entity</p>
                                                    <div className="next-stage-selector">
                                                        {this.props.exportfield.data.map((list,index)=>
                                                            <div className="select-chk-block" style={{"display": "block"}}>
                                                                <label className="control control-checkbox">{list.label}
                                                                    <input type="checkbox" className="inp-chk" id="text_searchable" value={list.selected} defaultChecked={list.selected} onClick={()=>this.changeExportField(list.masterid)} />
                                                                    <div className="control-indicator"></div>
                                                                </label>
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="task-submit-block">
                                                        <div></div>
                                                        <button className="general-btn  focus-resolve" id="savedata_viewpermission" onClick={this.saveExportField}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        (this.state.rightTab === "exportfield-tab" && this.props.exportfield.data.length ===0) ?
                                            <div className='absolute-holder'>
                                                <NoData
                                                    msg={`No Data linked to this collection`}
                                                />
                                            </div> : ''
                                    }

                                    {this.state.rightTab === "listingfilter-tab" &&
                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                         aria-labelledby="v-pills-home-tab">

                                        <div className="tab-wrap-block">
                                            <h5>Listing & Filter</h5>
                                            <div className="next-stage-selector">
                                                <div className="select-chk-block" style={{"display": "block"}}>
                                                    <label className="control control-checkbox">Datewise Filter
                                                        <input type="checkbox" className="inp-chk" id="text_searchable"
                                                               value={this.state.showdatefilter !==''?this.state.showdatefilter:collection.CollectionInfo.showdatefilter}
                                                               defaultChecked={collection.CollectionInfo.showdatefilter} onClick={(e)=>this.showFilter(e)}/>
                                                        <div className="control-indicator"></div>
                                                    </label>
                                                </div>


                                            </div>
                                            <div className="task-submit-block">
                                                <div></div>
                                                <button className="general-btn  focus-resolve" id="savedata_viewpermission" onClick={this.saveFilter}>Save</button>
                                            </div>
                                        </div>



                                    </div>
                                    }
                                    {(this.state.rightTab === "permissions-tab" && settings.data.length !==0) ?
                                        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                             aria-labelledby="v-pills-home-tab">
                                            <div className="tab-wrap-block">
                                                <h5>Data Level Permissions</h5>
                                                <p>Click the + icon to select users and set the permissions</p>
                                                <div className="scrollable">
                                                    <div className="form-table" style={{border: '0px'}}>
                                                        <table className="table set-permission">
                                                            <tbody>
                                                            {settings.data.map((list, index) =>
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="team-perform-left">
                                                                            <div className="team-perform-thumb">
                                                                                <img src={USRIMG_PATH + list.img_name} alt="User Image" />
                                                                            </div>
                                                                            <div className="team-perform-det">
                                                                                <h6>{list.fullname}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="select-chk-block" style={{display: 'block'}}>
                                                                            <label className="control control-checkbox">All Data
                                                                                <input type="checkbox" className="inp-chk"  name="alldata" id="alldata" value={list.alldata} defaultChecked={list.alldata} onClick={(e)=>this.permission(e,'alldata', index)}/>
                                                                                <div className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="select-chk-block" style={{display: 'block'}}>
                                                                            <label className="control control-checkbox">Add &amp; Edit
                                                                                <input type="checkbox" className="inp-chk" name="addedit" id="addedit" value={list.addedit} defaultChecked={list.addedit} onClick={(e)=>this.permission(e,'addedit', index)} />
                                                                                <div className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="select-chk-block" style={{display: 'block'}}>
                                                                            <label className="control control-checkbox">export
                                                                                <input type="checkbox" className="inp-chk"name="export" id="export" value={list.export} defaultChecked={list.export} onClick={(e)=>this.permission(e,'export', index)} />
                                                                                <div className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="select-chk-block" style={{display: 'block'}}>
                                                                            <label className="control control-checkbox">Assign
                                                                                <input type="checkbox" className="inp-chk" name="assign" id="assign" value={list.assign} defaultChecked={list.assign} onClick={(e)=>this.permission(e,'assign', index)} />
                                                                                <div className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="select-chk-block" style={{display: 'block'}}>
                                                                            <label className="control control-checkbox">rule
                                                                                <input type="checkbox" className="inp-chk"  name="rule" id="rule" value={list.rule} defaultChecked={list.rule} onClick={(e)=>this.permission(e,'rule', index)} />
                                                                                <div className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="select-chk-block" style={{display: 'block'}}>
                                                                            <label className="control control-checkbox">Delete
                                                                                <input type="checkbox" className="inp-chk"  name="delete" id="delete" value={list.delete} defaultChecked={list.delete} onClick={(e)=>this.permission(e,'delete', index)} />
                                                                                <div className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                            </tbody>
                                                        </table>
                                                        <div className="pull-right">
                                                            <button className="general-btn  focus-resolve" id="savedata_viewpermission" onClick={this.savePermission}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <NewUser
                                                showFormModal={(e)=>this.AddnewhandleAction(e, 'share', 'AddNew')}
                                            />
                                        </div> :
                                        (this.state.rightTab === "permissions-tab" && settings.data.length === 0) ?
                                            <div className='absolute-holder'>
                                                <NoData
                                                    msg={`No Data linked to this collection`}
                                                />
                                            </div> : ''
                                    }
                                    {this.state.rightTab === "linkpermission-tab" &&
                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                         aria-labelledby="v-pills-home-tab">

                                        <div className="tab-wrap-block">
                                            <h5>Entity Permissions</h5>
                                            <p>Following are the entities connected to the collection. Tick mark if the entity level data access permissions need to be inherited here.</p>



                                            <div className="next-stage-selector">
                                                {showlinkingpermission.map((title, index) =>
                                                    <div className="select-chk-block" style={{"display": "block"}}>

                                                        <label className="control control-checkbox">{title.label}
                                                            <input type="checkbox" className="inp-chk" id="text_searchable"
                                                                   value={title.masterid}
                                                                   defaultChecked={collection.CollectionInfo.linked_formpermission.filter((item)=> item.toString() === title.masterid.toString()).length !== 0} onClick={(e)=>this.showlinkingPermission(e, title.masterid)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                )}

                                            </div>
                                            {this.state.entity_perm === true &&
                                            <p>Succesfully updated..!</p>
                                            }
                                            {settings.data.length !== 0 && showlinkingpermission.length !== 0 &&
                                            <div className="task-submit-block">
                                                <div></div>
                                                <button className="general-btn focus-resolve" id="savedata_viewpermission"
                                                        onClick={this.linkingPermission}>Update
                                                </button>
                                            </div>
                                            }


                                        </div>

                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                /* Modal Sections */
                <React.Fragment>
                    {this.state.showPreviewForm &&
                    <ShowFormPreview
                        formName={this.state.formName}
                        collectionId ={this.state.collectionid}
                        previewForm={this.previewForm}
                        hidePreviewForm={this.hidePreviewForm}
                        state={this.state}
                        slideShow={this.showPreviewFormModal}
                    />
                    }
                    {this.state.showFormModal &&
                    <ShowFormModal
                        formName={this.state.formName}
                        collectionId ={this.state.collectionId}
                        referenceId ={this.state.collectionId}
                        hideFormModal={this.hideFormModal}
                        editId = {this.state.collecteddataid}
                    />
                    }
                    {this.state.showDeleteConfirm &&
                    <AlertModal id="take-pool"
                                title="Are you sure you want to delete?"
                                OK_button_text="Yes, Delete It"
                                OK_button_style="btn-danger"
                                OK_action={this.handleDelete}
                                showModal = {this.state.showDeleteConfirm}
                                CANCEL_action={this.showDeleteConfirm}
                    />
                    }
                    {this.state.action === 'assign' &&
                    <Modal title={'Create New Task'} Close ={this.modalOpen.bind(this)}>
                        <AddToDoTask
                            selectedCollections={this.state.selectedCollection}
                            modalOpen = {this.modalOpen}
                        />

                    </Modal>
                    }
                    {this.state.action === 'share' &&
                    <SimpleModal
                        Close={this.modalOpen}
                        title={`Share Form`}
                        haveFooter={1}
                        OKLabel="Save"
                        OKAction={this.saveAssign}
                        cancelLabel="Cancel"
                        cancelAction={this.modalOpen}
                    >
                        {this.props.loader.loaderType === 'share-collection' && this.props.loader.status === true &&
                        <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                        }
                        <div className="task-tg">

                            <p className="remove-txt-gp">Assign to Individual, Team, Organization, etc (Ignore this for self tasks)</p>
                            <div className="task-inp-container">

                                <AutoComplete
                                    eleClass = ''
                                    fieldTitle = 'To'
                                    sourceDataList = {this.props.listUsers.Users}
                                    searchField = 'fullname'
                                    showIcon={false}
                                    hideTitle = {true}
                                    hideMainLabel = {false}
                                    showSubLabel = {false}
                                    labelFields = 'name'
                                    valueField ='user_id'
                                    subLabelField = 'designation'
                                    showProfile = {true}
                                    reference='selectedToUsers'
                                    selectedItems = {this.state.selectedToUsers}
                                    resultDataList = {this.resultDataList}
                                    id="to_users"
                                    actionMethod = {this.getUsers}
                                />
                            </div>

                        </div>

                    </SimpleModal>
                    }
                    {this.state.datalevelSelect === true &&
                    <SimpleModal
                        Close={this.modalOpen}
                        title={`Form Settings`}
                        haveFooter={1}
                        OKLabel="Save"
                        OKAction={this.savedatalevelUsers}
                        cancelLabel="Cancel"
                        cancelAction={this.modalOpen}
                    >
                        {this.props.loader.loaderType === 'share-collection' && this.props.loader.status === true &&
                        <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                        }
                        <div className="task-tg">

                            <p className="remove-txt-gp">Assign to Individual, Team, Organization, etc (Ignore this for self tasks)</p>
                            <div className="task-inp-container">

                                <AutoComplete
                                    eleClass = ''
                                    fieldTitle = 'To'
                                    sourceDataList = {datausers}
                                    searchField = 'fullname'
                                    showIcon={false}
                                    hideTitle = {true}
                                    hideMainLabel = {false}
                                    showSubLabel = {false}
                                    labelFields = 'fullname'
                                    valueField ='uniqueId'
                                    subLabelField = 'designation'
                                    showProfile = {true}
                                    reference='saveSettings'
                                    selectedItems = {this.state.saveSettings}
                                    resultDataList = {this.saveDataLevel}
                                    id="to_datalevel"
                                    actionMethod = {this.getdatalevelUsers}
                                />
                            </div>


                        </div>

                    </SimpleModal>
                    }
                    {this.state.action === 'embed' &&
                    <div className="bs-modal show" id="generate-link" style={{"display": "block"}}>
                        <div className="bs-modal-dialog">
                            <div className="bs-modal-content text-center">
                                <div className="bs-modal-title">
                                    <h3>Form Generated Link</h3>
                                    <button className="modal-option-button" onClick={()=>this.modalOpen()}><span className="icon-in icon-close-in"></span>
                                    </button>
                                </div>

                                <div className="bs-modal-body">
                                    <textarea className="input-text-area" id="embedlink" value={`<iframe src="https://bsynupgrade.bsynapse.com/embed/form/2_1159523840736" style="border:1px #E4E6E9 solid;" name="bsynapseform" scrolling="yes" frameborder="1" marginheight="0px" marginwidth="1px" height="1080px" width="750px" allowfullscreen=""></iframe>`}>

                                    </textarea>
                                    <button className="btn btn-default" data-dismiss="modal" onClick={()=>this.modalOpen()}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {this.state.action === 'import' &&
                    <div className="bs-modal show" id="generate-link" style={{"display": "block"}}>
                        <div id={`progress-panel-container`} className={`progress-panel-holder`} >
                            <div id={`progress-holder`} className={'upload-pr-wrap'}></div>
                        </div>
                        <div className="bs-modal-dialog">
                            <div className="bs-modal-content text-center">
                                <div className="bs-modal-title"><h3>Import CSV</h3>

                                    {this.state.csvImportMsg === '' &&
                                    <button className="modal-option-button" onClick={() => this.modalOpen()}><span
                                        className="icon-in icon-close-in"></span>
                                    </button>
                                    }

                                </div>
                                <div className="bs-modal-body">
                                    {/* <input type={`file`} onChange={this.handleFile}/> */}

                                    {this.state.csvImportMsg !== '' &&
                                    <span className={`msg-csv-import`}>{this.state.csvImportMsg}</span>
                                    }
                                    {this.state.csvImportMsg === '' &&

                                    <div className="attach-block">
                                        <input type={`file`} onChange={this.handleFile}/>
                                        <label className="ico-lib att">Attach File Here</label>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {this.state.assignUserSelect === true &&
                    <SimpleModal
                        Close={this.modalOpen}
                        title={`Assigned Users`}
                        haveFooter={1}
                        OKLabel={Userpermission?"Save":""}
                        OKAction={this.singleRecorddAssign}
                        asignedUsershow = {true}
                        cancelLabel="Cancel"
                        cancelAction={this.modalOpen}
                    >
                        {this.props.loader.loaderType === 'share-collection' && this.props.loader.status === true &&
                        <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                        }
                        <div className="task-tg">
                            <div id="assigned_users_list">

                                {!Userpermission && this.state.assignUsers &&

                                this.state.filterUserdata.map((item, indx) =>
                                    <div className="sl-tag">
                                        <span className="tag-left"> {item.fullname.charAt(0).toUpperCase()}</span>
                                        <span className="tag-right" key={indx}>{item.fullname}</span> &nbsp;
                                    </div>



                                )}

                                {Userpermission &&
                                <AutoComplete
                                    eleClass=''
                                    fieldTitle='To'
                                    sourceDataList={datausers}
                                    searchField='fullname'
                                    showIcon={false}
                                    hideTitle={true}
                                    hideMainLabel={false}
                                    showSubLabel={false}
                                    labelFields='fullname'
                                    valueField='uniqueId'
                                    subLabelField='designation'
                                    showProfile={true}
                                    reference='saveSettings'
                                    selectedItems={this.state.filterUserdata}
                                    resultDataList={this.saveDataLevel}
                                    id="to_datalevel"
                                    actionMethod={this.getdatalevelUsers}
                                />
                                }



                            </div>

                        </div>

                    </SimpleModal>
                    }
                    {this.state.action !== 'settings' && ((permissionsSelected.length > 0 &&  permissionsSelected[0].addedit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || (collection.CollectionInfo.length !== 0 && collection.CollectionInfo.createuser.toString()) === getProfile().id.toString())) &&
                    <div className="float-button-block">
                        <button className="float-button ico-lib float-add" data-toggle="modal" data-target="#addField"
                                onClick={(e) => this.showFormModal(e)}></button>
                    </div>
                    }
                    {this.state.action === 'settings' && this.state.rightTab === "permissions-tab" &&
                    <div className="float-button-block">
                        <button className="float-button ico-lib float-add" data-toggle="modal" data-target="#addField"
                                onClick={(e) => this.showdatalevelAssignedUsers(e)}> </button>
                    </div>
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }
}



const mapStateToProps = state => ({
    collectionData: state.CollectionsReducer.listCollectionDetaildata,
    collectionFilterData: state.CollectionsReducer.listCollectionFilterData,
    collectionDataChanged : state.CollectionsReducer.getChangedCollectionData,
    haveLoader : state.CollectionsReducer.manageoader,
    listUsers:state.UsersReducer.listAllUsersdata,
    sharedCollection : state.CollectionsReducer.sharedCollection,
    loader:state.CommonReducer.loader,
    filterDatadetails:state.CollectionsReducer.filterDatadetails,
    exportfield:state.CollectionsReducer.exportfield,
    GetCollectionconfig:state.CollectionsReducer.GetCollectionConfig,
    showFormModal:state.CollectionsReducer.showFormModal,
    saveAssigndata:state.CollectionsReducer.saveAssigndata,
    csvImportData:state.CollectionsReducer.csvImportData,
    getSavedForm : state.CollectionsReducer.getSavedForm,
    saveLinkingPermission : state.CollectionsReducer.savelinkingpermission,
    sharedCollectionData:state.CollectionsReducer.collectionSettingsData,
    savadataSettings:state.CollectionsReducer.savadataSettings,


});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    collectionDetails:collectionActions.collectionDetail,
    collectionFilter:collectionActions.collectionFilter,
    collectionDataDelete:collectionActions.collectionDataDelete,
    users:usersActions.listAllUsers,
    sharecollection: collectionActions.sharecollection,
    showform : collectionActions.showform,
    getFormData : collectionActions.getFormData,
    getFilterData : collectionActions.getFilterData,
    exportField:collectionActions.exportField,
    saveFieldData:collectionActions.saveExportFieldData,
    getCollectionconfig:collectionActions.getcollectionconfig,
    savaDataView:collectionActions.savaDataView,
    savaDataSettings:collectionActions.savaDataSettings,
    savaDatalevelSettings:collectionActions.savaDataSettings,
    singlerecorddAssign:collectionActions.singlerecorddAssign,
    saveFilter:collectionActions.saveFilter,
    importCollectionData:collectionActions.importCollectionData,
    saveAssignData:collectionActions.saveAssignData,
    savelinkingpermission:collectionActions.savelinkingpermission,
    selectExport:collectionActions.selectExport,
    loadmoreCollectionDetails:collectionActions.loadmoreCollectionDetails,
    getsharedSettingsData : collectionActions.getCollectionSettings,
    resetForm : collectionActions.resetForm,
    exportall : collectionActions.exportall
})(ProjectCollectionDetails));


