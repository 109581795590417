import React from 'react';
import {Draggable, state} from 'react-page-maker';
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {FormGroup} from "react-bootstrap";
import DateTimePickerBox from "../../../views/DateTimePickerBox";
import moment from "moment";
import {SettingsPanel} from "./ViewData";
import SettingsModal from "./SettingsModal";
import axios from "axios";

class DateFilterContainer extends React.Component {
    state = {
        modalStatus: false,
        showColorPicker: false,
        label: '',
        from: "",
        to: "",
        showlistField: [],
        sourceType: false,
        filterFields: [],
        filterData: []
        //filterName:''
    };

    componentDidMount() {
        this.setState({payload: this.props.payload})
        this.graphData()
    }

    openSetting = () => {
        this.setState({modalStatus: !this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }

    graphData = () => {
        console.log(this.props.payload)
        var date = new Date();
        var obj = this;
        const collection = this.props.payload && this.props.payload.collection
        const tabledata = this.props.payload && this.props.payload.graph || this.state.items
        const whereCondition = this.props.payload && this.props.payload.whereCondition || this.state.whereCondition
        const condition = this.props.payload && this.props.payload.condition || this.state.condition
        const groupbyEnable = this.props.payload && this.props.payload.groupbyEnable
        const limit = this.props.payload && this.props.payload.limit
        const bordercolor = this.props.payload && this.props.payload.bordercolor
        const globalFilter = this.props.payload && this.props.payload.globalFilter || this.state.globalFilter
        const selectFilter = this.state.selectFilter || this.props.payload && this.props.payload.selectFilter
        const filterName = this.state.filterName || this.props.payload && this.props.payload.filterName
        this.setState({
            filterName: filterName,
            selectFilter: selectFilter,
            tabledata: tabledata,
            globalFilter: globalFilter,
            bordercolor: bordercolor,
            items: tabledata,
            condition: condition,
            whereCondition: whereCondition,
            limit: limit
        })

        if ((collection !== '' && collection !== undefined) || getUrlSegment(1) === "collections") {
            let collections = (getUrlSegment(1) === "collections") ? atob(getUrlSegment(4)) : collection

            if (getUrlSegment(1) === "collections") {
                this.setState({cId: collections})
            }
            //  this.props.showform({ _id : collection});
            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + collections + "&index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                obj.setState({showlistField: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });

        } else {
            obj.setState({showlistField: []});

        }
    }


    handleChangeComplete = (e) => {
        let labelValue = e.target.value
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({[e.target.name]: labelValue}, () => {
            state.updateElement(id, dropzoneID, parentID, {});
        });
    };

    handleFromDateChange = (date) => {
        let from = date === "" ? "" : moment(date).format("yyyy-MM-DD")
        this.setState({
            from: from
        })
        this.handleLabelChange(from, "from")
    }
    handleToDateChange = (date) => {
        let to = date === "" ? "" : moment(date).format("yyyy-MM-DD")
        this.setState({
            to: to
        })
        this.handleLabelChange(to, "to")

    }
    handleLabelChange = (date, type) => {
        let dtePm = {
            from: type === "from" ? date : this.state.from,
            to: type === "to" ? date : this.state.to,
            field: '',
            filtertype: 'globalFilter'

        }
        //this.props.filterDate(dtePm, "dateFilter", this.state.filterName)
        this.props.setGlobalFilter(dtePm, "globaldate")


    }

    handleChange = (e) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({[e.target.name]: e.target.value, payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    handleInputChange = (e) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }


    chartSaving = () => {
        var prjctdtlid = atob(getUrlSegment(4));
        let sharetype = getUrlSegment(1);
        let uid = '';
        if (sharetype === 'public') {
            uid = 2;
        } else {
            uid = getProfile().id;
        }
        let param = {
            "projectid": prjctdtlid,
            "uid": uid,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard": state.getStorableState(),
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus: false})
    }

    clearDates = () => {
        this.setState({
            from: "",
            to: ""
        }, function () {

            this.props.resetGlobalFilter("globaldate")
        })
    }

    render() {
        const {
            id,
            showBasicContent, showPreview,
            dropzoneID, payload, parentID, name
        } = this.props;
        let sourceType = (payload && payload.sourceType) || false
        let showlistField = []
        let sourceData = []
        if (this.state.showlistField.length !== 0) {
            for (let i = 0; i < this.state.showlistField.pages.length; i++) {
                (this.state.showlistField.pages[i].components).map(list => {
                    showlistField.push(list)
                })
            }
        }
        let fieldDta = this.state.filterFields.filter(list => list.masterid === this.state.selectFilter)
        if (sourceType.toString() === "true") {
            sourceData = this.props.listAllCollections.ListAllCollections.data
        } else {
            sourceData = this.props.EntityData.Entity.data
        }
        let containerData = {
            collection: this.state.collection || payload && payload.collection,
            showlistField: showlistField,
            collectionData: sourceData,
            sourceType: sourceType,
            selectFilter: this.state.selectFilter || payload && payload.selectFilter,
            filterName: this.state.filterName || payload && payload.filterName,
        }

        let filterData = this.state.filterData;


        if (showBasicContent) {
            return (
                <Draggable {...this.props} >
                    {
                        <span className="drag-icon">Date Filter</span>
                    }
                </Draggable>
            )
        }

        if (showBasicContent) {
            return (
                <Draggable {...this.props} >
                    {

                        <span className="drag-icon">Date Filter</span>
                    }
                </Draggable>
            )
        }

        if (showPreview) {
            return (
                <div className="stakeholders-header">
                    <div className="task-input-block filter-width" id="fromdate">
                        <DateTimePickerBox
                            addErrorClass={this.state.strtdate_err}
                            placeholderText={'from'}
                            timeFormat={false}
                            handleDateChange={this.handleFromDateChange}
                            minDate={new Date()}
                            previousDate={true}
                            showClearButton={true}
                            startDate={this.state.from}/>

                    </div>
                    <div className="task-input-block filter-width" id="todate">

                        <DateTimePickerBox
                            addErrorClass={this.state.strtdate_err}
                            placeholderText={'to'}
                            timeFormat={false}
                            handleDateChange={this.handleToDateChange}
                            minDate={new Date()}
                            previousDate={true}
                            showClearButton={true}
                            startDate={this.state.to}/>

                    </div>
                    <button className="btn general-btn" onClick={() => this.clearDates()}> Clear</button>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Draggable {...this.props} draggable={!this.props.DragDisabled.dragStatus}>
                    <SettingsPanel
                        id={id}
                        name={containerData.chartTitle ? containerData.chartTitle : name}
                        dropzoneID={dropzoneID}
                        parentID={parentID}
                        openSetting={this.openSetting}
                        removeElement={<div className="delete-icon"
                                            onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
                    />
                </Draggable>
                {this.state.modalStatus &&
                <SettingsModal
                    title={`Date Filter  -   Settings`}
                    containerData={containerData}
                    colorpickerStatus={this.state.colorpickerStatus}
                    modaltype={8}
                    repeater={false}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    handleChange={this.handleChange}
                    handleInputChange={this.handleInputChange}
                    radioBoxchange={this.radioBoxchange}
                    handleLabelChange={this.handleLabelChange}

                />
                }
            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    showFormModal: state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange: state.DynamicDashboard.getDashboard,
    DragDisabled: state.DynamicDashboard.getDragDisabled,
    EntityData: state.EntityReducer.GetEntity,
    filterCnt: state.DynamicDashboard.getFilterCnt,
});
export default withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform: collectionActions.showform,
    chartSaving: DynamicDashboard.saveDashboarddata,
    dragStatus: DynamicDashboard.dragStatus,
    filterDate: DynamicDashboard.getFilterData,
    setGlobalFilter: DynamicDashboard.getGlobalFilterData,
    resetGlobalFilter: DynamicDashboard.resetGlobalFilterData
})(DateFilterContainer));