import {combineReducers} from 'redux';

const getDashboard = (state = {Dashboard: "dashboard", UpdateId: '', data: {}, loader: false}, action) => {
    switch (action.type) {
        case "SAVE_DASHBOARD_LOADER":
            return {
                ...state,
                loader: action.loader,
            };
            break;
        case "CHANGE_DASHBOARD_TYPE":
            return {
                ...state,
                Dashboard: action.dashType,
            };
            break;
        case "CHANGE_UPDATE_ID":
            return {
                ...state,
                UpdateId: action.Updateid,
            };
        case "SAVE_DASHBOARD_DATA":
            return {
                ...state,
                data: action.data,
                loader: action.loader
            };
            break;

        default:
            return state;
    }
};

const getDragDisabled = (state = {dragStatus: false}, action) => {
    switch (action.type) {
        case "DRAGABLE_STATUS":
            return {
                ...state,
                dragStatus: action.dragStatus,
            };
            break;

        default:
            return state;
    }
};
const projectDashBoard = (state = {projectdetails: []}, action) => {
    switch (action.type) {
        case "PROJECT_DETAILS_DATA":
            return {
                ...state,
                projectdetails: action.projectdetails,
            };
            break;

        default:
            return state;
    }
};
const DashboardMenus = (state = {menus: []}, action) => {
    switch (action.type) {
        case "GET_NEW_DASHBOARD_MENU":
            return {
                ...state,
                menus: action.addDashboard,
            };
            break;

        default:
            return state;
    }
};


const SaveDashboardMenus = (state = {menus: []}, action) => {
    switch (action.type) {
        case "ADD_NEW_DASHBOARD_MENU":
            return {
                ...state,
                menus: action.addDashboard,
            };
            break;

        default:
            return state;
    }
};

const getFilterCnt = (state = {date: [], select: "", filterName: "", filterType: '', datereset: false}, action) => {
    switch (action.type) {
        case "GET_FILTER_DATE":
            return {
                ...state,
                date: action.date,
                filterName: action.filterName,
                filterType: action.filterType
            };
            break;

        case "FILTER_SELECT":
            return {
                ...state,
                select: action.select,
                filterName: action.filterName,
                filterType: action.filterType
            };
            break;

        default:
            return state;
    }
};
const getGlobalFilterCnt = (state = {
    date: {"from": "", "to": "", "filtertype": ""},
    select: "",
    filterType: ''
}, action) => {
    switch (action.type) {
        case "GET_GLOBAL_FILTER_DATE":
            return {
                ...state,
                date: action.date,
                filterType: action.filterType
            };
            break;

        case "GET_GLOBAL_FILTER_SELECT":
            return {
                ...state,
                select: action.select,
                filterType: action.filterType
            };
            break;
        case "RESET_GLOBAL_FILTER_DATE":
            return {
                ...state,
                date: [],
                filterType: '',
                datereset: false
            };
            break;

        case "RESET_GLOBAL_FILTER_SELECT":
            return {
                ...state,
                select: '',
                filterType: ''
            };
            break;
        default:
            return state;
    }
};

const GetentityCollection = (state = {linkedDetails: []}, action) => {
    switch (action.type) {
        case "LINKED_ENTITY_DASHBOARD":
            return {
                ...state,
                linkedDetails: action.entityLinkedList.data.results.data
            };
            break;
        default:
            return state;
    }
};

const getaddRecord = (state = {cid: "", masterid: [], type: "", refId: ""}, action) => {
    switch (action.type) {
        case "ADD_LINKED_REC":
            return {
                ...state,
                cid: action.cid,
                masterid: action.masterid,
                act: action.act,
                refId: action.refId,
                syncType: action.syncType
            };
            break;
        case "RESET_LINKED_REC":
            return {
                ...state,
                cid: "",
                masterid: [],
                act: "",
                refId: "",
                syncType: ""
            };
            break;
        default:
            return state;
    }
};

const getsyncRec = (state = {sta: ""}, action) => {
    switch (action.type) {
        case "SYNC_REC":
            return {
                ...state,
                sta: action.sta
            };
            break;
        default:
            return state;
    }
};

const getScrollElement = (state = {id: ""}, action) => {
    switch (action.type) {
        case "SCROLL_ELEMENT":
            return {
                ...state,
                id: action.id
            };
            break;
        default:
            return state;
    }
};

const getToggle = (state = {id: ""}, action) => {
    switch (action.type) {
        case "TOGGLE_ACCORDION":
            return {
                ...state,
                status: action.status
            };
            break;
        default:
            return state;
    }
};

const getFlagData = (state = {getFlag: []}, action) => {
    switch (action.type) {
        case "GET_FLAG_DASH_DATA":
            return {
                ...state,
                getFlag: action.getFlag.data.data
            };
        default:
            return state;
    }
};

const getGlobalFilters = (state = {globalFilterComp: []}, action) => {
    switch (action.type) {
        case "SET_GLOBAL_FILTERS":
            return {
                ...state,
                globalFilterComp: action.globalFilterComp
            };
            break;
        default:
            return state;
    }
};


export const DynamicDashboard = combineReducers({
    getDashboard,
    getDragDisabled,
    projectDashBoard,
    DashboardMenus,
    SaveDashboardMenus,
    getFilterCnt,
    GetentityCollection,
    getaddRecord,
    getsyncRec,
    getScrollElement,
    getToggle,
    getFlagData,
    getGlobalFilters,
    getGlobalFilterCnt
});

export default DynamicDashboard;

