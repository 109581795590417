import React, {Component} from 'react';


class DataRoomLeft extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        return (

            <div className="data-room-left">
                <div className="mob-header">
                    <h5>Filter by:</h5>
                    <button className="ico-lib-v1 close-filter"></button>
                </div>
                <div id="accordion" className="dataroom-accord">
                    <div className="card">
                        <div className="card-header" id="cardOne" data-toggle="collapse"
                             data-target="#collapseOne" aria-expanded="true"
                             aria-controls="collapseOne">Type
                        </div>
                        <div id="collapseOne" className="collapse show" aria-labelledby="cardOne"
                             data-parent="#accordion">
                            <div className="card-body">
                                <div className="select-wrapper">
                                    <div className="select-chk-block" style={{display: "block"}}>
                                        <label className="control control-checkbox"> Project Related
                                            <input type="checkbox" className="inp-chk"/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                    <div className="select-chk-block" style={{display: "block"}}>
                                        <label className="control control-checkbox"> Approvals and
                                            Concessions
                                            <input type="checkbox" className="inp-chk"/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                    <div className="select-chk-block" style={{display: "block"}}>
                                        <label className="control control-checkbox"> Permits
                                            <input type="checkbox" className="inp-chk"/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                    <div className="select-chk-block" style={{display: "block"}}>
                                        <label className="control control-checkbox">Agreements
                                            <input type="checkbox" className="inp-chk" checked=""/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header collapsed" id="cardTwo" data-toggle="collapse"
                             data-target="#collapseTwo" aria-expanded="false"
                             aria-controls="collapseTwo">Format
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="cardTwo"
                             data-parent="#accordion">
                            <div className="card-body">
                                <div className="select-wrapper">
                                    <div className="select-chk-block" style={{display: "block"}}>
                                        <label className="control control-checkbox"> Aditya Birla
                                            <input type="checkbox" className="inp-chk" checked="" />
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header collapsed" id="cardThree" data-toggle="collapse"
                             data-target="#collapseThree" aria-expanded="false"
                             aria-controls="collapseThree">Document Type
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="cardThree"
                             data-parent="#accordion">
                            <div className="card-body">
                                <div className="select-wrapper">
                                    <div className="select-chk-block">
                                        <label className="control control-checkbox"> Aditya Birla
                                            <input type="checkbox" className="inp-chk" checked=""/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header collapsed" id="cardFour" data-toggle="collapse"
                             data-target="#collapseFour" aria-expanded="false"
                             aria-controls="collapseFour">Date
                        </div>
                        <div id="collapseFour" className="collapse" aria-labelledby="cardFour"
                             data-parent="#accordion">
                            <div className="card-body">
                                <div className="select-wrapper">
                                    <div className="select-chk-block">
                                        <label className="control control-checkbox"> Aditya Birla
                                            <input type="checkbox" className="inp-chk" checked=""/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header collapsed" id="cardFour" data-toggle="collapse"
                             data-target="#collapseFive" aria-expanded="false"
                             aria-controls="collapseFive">User
                        </div>
                        <div id="collapseFive" className="collapse" aria-labelledby="cardFour"
                             data-parent="#accordion">
                            <div className="card-body">
                                <div className="select-wrapper">
                                    <div className="select-chk-block">
                                        <label className="control control-checkbox"> Aditya Birla
                                            <input type="checkbox" className="inp-chk" checked=""/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }


}
class DataRoomRight extends Component {
    constructor(props) {
        super(props);
        this.state={
            showCloseButton : false
        }

        this.OrganizeMetrics = this.OrganizeMetrics.bind(this);
    }

    OrganizeMetrics(){
        this.setState({showCloseButton : !this.state.showCloseButton})
    }
    render() {

        return (

            <div className="data-room-right">
                <div className="dataroom-manager">
                    <DataRoomHeader/>
                    <div className="dataroom-manager-body">
                        <div className="dataroom-manager-table-header">
                            <div className="dataroom-manager-table-row">
                                <div className="dataroom-manager-table-data sort-to-top">Agreements
                                </div>
                                <div className="dataroom-manager-table-data">Type</div>
                                <div className="dataroom-manager-table-data">Format</div>
                                <div className="dataroom-manager-table-data">Size</div>
                                <div className="dataroom-manager-table-data">Modified</div>
                            </div>
                        </div>
                        <div className="dataroom-manager-table-body">
                            <div className="dataroom-manager-table-row">
                                <div className="dataroom-manager-table-data">
                                    <div className="file-type-view">
                                        <span className="ico-lib-v2 folder-ico"></span>
                                        <div className="file-type-name">Project Agreements</div>
                                    </div>
                                    <div className="resources-card-check">
                                        <label className="control control-checkbox list-check-rc">
                                            <input type="checkbox" className="inp-chk"/>
                                            <div className="control-indicator rc-listed"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="remain-block">
                                    <div className="dataroom-manager-table-data">Folder</div>
                                    <div className="dataroom-manager-table-data"></div>
                                    <div className="dataroom-manager-table-data"></div>
                                    <div className="dataroom-manager-table-data">Feb 23, 2018</div>
                                </div>
                            </div>
                            <div className="dataroom-manager-table-row">
                                <div className="dataroom-manager-table-data">
                                    <div className="file-type-view">
                                        <span className="ico-lib-v2 folder-ico"></span>
                                        <div className="file-type-name">Construction Related
                                            Agreements
                                        </div>
                                    </div>
                                    <div className="resources-card-check">
                                        <label className="control control-checkbox list-check-rc">
                                            <input type="checkbox" className="inp-chk"/>
                                            <div className="control-indicator rc-listed"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="remain-block">
                                    <div className="dataroom-manager-table-data">Folder</div>
                                    <div className="dataroom-manager-table-data"></div>
                                    <div className="dataroom-manager-table-data"></div>
                                    <div className="dataroom-manager-table-data">Feb 23, 2018</div>
                                </div>
                            </div>
                            <div className="dataroom-manager-table-row">
                                <div className="dataroom-manager-table-data">
                                    <div className="file-type-view">
                                        <span className="ico-lib-v2 folder-ico"></span>
                                        <div className="file-type-name">Agreement Backup</div>
                                    </div>
                                    <div className="resources-card-check">
                                        <label className="control control-checkbox list-check-rc">
                                            <input type="checkbox" className="inp-chk"/>
                                            <div className="control-indicator rc-listed"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="remain-block">
                                    <div className="dataroom-manager-table-data">Folder</div>
                                    <div className="dataroom-manager-table-data"></div>
                                    <div className="dataroom-manager-table-data"></div>
                                    <div className="dataroom-manager-table-data">Feb 23, 2018</div>
                                </div>
                            </div>
                            <div className="dataroom-manager-table-row">
                                <div className="dataroom-manager-table-data">
                                    <div className="file-type-view">
                                        <span className="format docx">doc</span>
                                        <div className="file-type-name">Share Agreement</div>
                                    </div>
                                    <div className="resources-card-check">
                                        <label className="control control-checkbox list-check-rc">
                                            <input type="checkbox" className="inp-chk"/>
                                            <div className="control-indicator rc-listed"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="remain-block">
                                    <div className="dataroom-manager-table-data">Agreement</div>
                                    <div className="dataroom-manager-table-data">DOCX</div>
                                    <div className="dataroom-manager-table-data">256 KB</div>
                                    <div className="dataroom-manager-table-data">Feb 23, 2018</div>
                                </div>
                            </div>
                            <div className="dataroom-manager-table-row active">
                                <div className="dataroom-manager-table-data">
                                    <div className="file-type-view">
                                        <span className="format xlx">XLXS</span>
                                        <div className="file-type-name">Partnership Agreement</div>
                                    </div>
                                    <div className="resources-card-check">
                                        <label className="control control-checkbox list-check-rc">
                                            <input type="checkbox" className="inp-chk" checked=""/>
                                            <div className="control-indicator rc-listed"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="remain-block">
                                    <div className="dataroom-manager-table-data">Agreement</div>
                                    <div className="dataroom-manager-table-data">XLXS</div>
                                    <div className="dataroom-manager-table-data">256 KB</div>
                                    <div className="dataroom-manager-table-data">Feb 23, 2018</div>
                                </div>
                            </div>
                            <div className="dataroom-manager-table-row">
                                <div className="dataroom-manager-table-data">
                                    <div className="file-type-view">
                                        <span className="format pdf">pdf</span>
                                        <div className="file-type-name">Partnership Agreement</div>
                                    </div>
                                    <div className="resources-card-check">
                                        <label className="control control-checkbox list-check-rc">
                                            <input type="checkbox" className="inp-chk"/>
                                            <div className="control-indicator rc-listed"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="remain-block">
                                    <div className="dataroom-manager-table-data">Agreement</div>
                                    <div className="dataroom-manager-table-data">PDF</div>
                                    <div className="dataroom-manager-table-data">256 KB</div>
                                    <div className="dataroom-manager-table-data">Feb 23, 2018</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }


}

class DataRoomHeader extends Component {
    constructor(props) {
        super(props);
        this.state={
            showCloseButton : false
        }

        this.OrganizeMetrics = this.OrganizeMetrics.bind(this);
    }

    OrganizeMetrics(){
        this.setState({showCloseButton : !this.state.showCloseButton})
    }
    render() {

        return (

                <div className="dataroom-manager-header">
                    <div className="dataroom-manager-header-left">
                        <h5>Data Room</h5>
                        <p>You can manage project related documents here</p>
                    </div>
                    <div className="dataroom-manager-header-center">
                        <div className="search-dataroom">
                            <input type="text" name="search" placeholder="Search Documents…"/>
                        </div>
                    </div>
                    <div className="dataroom-manager-header-right">
                        <ul className="dataroom-option">
                            <li className="mobi-only">
                                <a href="javascript:void(0);" className="ico-lib-v3 dr-filter"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0);"
                                   className="ico-lib-v2 dr-download"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" className="ico-lib-v2 dr-preview"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" className="ico-lib-v2 dr-url"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" className="ico-lib-v2 dr-delete"></a>
                            </li>
                            <li>
                                <a href="javascript:void(0);"
                                   className="ico-lib-v2 dr-extra-opt"></a>
                            </li>
                        </ul>
                    </div>
                </div>


        );
    }


}
export {DataRoomLeft,DataRoomRight,DataRoomHeader};