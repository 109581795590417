import React, {Component} from 'react';
import {FRMSIMG_PATH} from '../../constants/index'
import Coverimage from '../../images/placeholders/Coverimage.jpg';
class FormTile extends Component {

    constructor(props) {
        super(props);
        this.Minimize=this.Minimize.bind(this)
        this.Maximise=this.Maximise.bind(this)
        this.Close=this.Close.bind(this)
        this.state={
            miniMize:false,
            Maximise:false,
            Close:false
        }
    }



    componentDidMount() {
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    handleAction(e, act, id) {
        this.props.updateAction(e, act, this.props.list, id);
    }
    Minimize(){
        this.setState({Maximise:false,miniMize:true})
    }
    Maximise(){
        if(!this.state.miniMize) {
            this.setState({Maximise: !this.state.Maximise, miniMize: false})
        }
        else{
            this.setState({Maximise: false, miniMize: false})
        }
    }
    Close(){

    }

    render() {
         var list = this.props.list;
         return (
             <div className="tile-each" onClick={(e)=>this.props.history.push('/collections/details/' + btoa(list._id))} key={btoa(list._id)}>
                 <div className="tile-head-all">
                     {list.filename ?
                         <img src={FRMSIMG_PATH + list.filename} alt="no-image" /> :
                         <img src={Coverimage} alt="no-image" />
                     }
                     <div className="option-toppper">
                         <button className="ico-lib image-option" onClick={(e)=>this.props.manageOptionsDropDown(e, list._id)}></button>
                         <ul className={`panel-option ${this.props.optionOpened === list._id ? "active" : ''}`} id="option-list">
                             <li>
                                <a href="javascript:void(0);" className="panel-option-click ico-lib-v1 form-dup-ico" onClick={(e) => {this.handleAction(e, 'assign', list._id)}}>Assign</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" className="panel-option-click ico-lib form-share-ico" onClick={(e) => {this.handleAction(e, 'share', list._id)}}>Share</a>
                            </li>
                         </ul>
                     </div>
                     {list.has_form == 'no' &&
                     <button className="form-btn form-build">Build Form</button>
                     }

                 </div>
                 <div className="tile-info-all">
                     <div className="tile-description-box">
                         <h5>{atob(list.title)}</h5>
                         <p>{atob(list.description)}</p>
                     </div>
                     <div className="tile-options-in">
                         <div className="span-box-in">
                             <span className="icon-in icon-stack-filled"></span>
                             <span className="item-count-in">{list.childs}</span>
                         </div>

                         <div className="span-box-in">
                             <span className="icon-in icon-share-in"></span>
                             <span className="item-count-in">{list.share}</span>
                         </div>
                     </div>
                 </div>
             </div>



        );
    }
}

export default FormTile;