import React from 'react';
import axios from 'axios';
import {Draggable, state} from 'react-page-maker';
import PieChartCustom from "../views/charts/PieChart";
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment, isValidDate} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {SettingsPanel, ViewData} from "./ViewData";
import SettingsModal from "./SettingsModal";
import moment from "moment";

let graphData = [
    {
        "x": "2018 Q1",
        "y": "500",
        "z": "2018 Q1"
    },
    {
        "x": "2018 Q2",
        "y": "620",
        "z": "2018 Q2"
    }]


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

class PieChartContainer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            modalStatus: false,
            showColorPicker: false,
            background: 'green',
            payload: {
                chartTitle: "Title",
                subTitle: "subtitle",
                subTitleEnabled: false,
                multiarachart: graphData,
                bordercolor: "",
                cardvalue: 0,
                collection: "",
                fields: "",
                aggregation: '',
                graph: false,
                x: "",
                y: "",
                z: "",
                xstroke: "",
                ystroke: "",
                labelx: "",
                labely: "",
                zstroke: "red",
                labelchartTitle: "",
            },
            multiarachart: [],
            Color: [
                {
                    id: "item-1",
                    color: "",
                },
            ],
            tabledata: [{
                id: "item-1",
                label: "item 1",
                field: "",
                color: "#cc332b"
            }],
            items: [{
                id: "item-1",
                label: "item 1",
                field: "",
                color: "#cc332b"
            }],
            whereCondition: [
                {
                    id: "item-1",
                    label: "",
                    field: "",
                    conditions: "",
                    min: '',
                    max: '',
                    from: '',
                    to: '',
                    text: "",
                    type: ""
                },
            ],
            globalFilter: [],
            ownFilterList: [
                {
                    id: "item-1",
                    label: "",
                    field: "",
                    conditions: "",
                    min: '',
                    max: '',
                    from: '',
                    to: '',
                    text: "",
                    type: ""
                },
            ],

            showlistField: [],
            enableyaxis: false,
            legend: false,
            alldata: false,
            unit: '',
            condition: [{
                field: "",
                type: "",
                sort: ""
            }],
            limit: "",
            ownFilter: false,
            relFilCnt: 0,
            relFltr: false,
            linecolor: "",
            fields: "",
            aggregation: '',
            aggValue: '',
            flagId: [],
            globalFilterComp: [],
            selectedGlobalFilters: []

        }


    }

    componentDidMount() {
        let ownid = "piechart" + Math.round(new Date().getTime() + (Math.random() * 100));
        this.setState({
            ownid: ownid,
            payload: this.props.payload,
            globalFilterComp: this.props.globalFilters.globalFilterComp
        })
        this.graphData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterCnt.date !== this.props.filterCnt.date) {
            if (this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") {
                if (this.props.filterCnt.filterType !== 'globalFilter') {
                    if (this.props.filterCnt.filterName === this.state.ownid) {
                        this.graphData();
                    }

                } else {

                    this.graphData()
                }
            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.filterCnt.select !== this.props.filterCnt.select) {
            if (this.props.filterCnt.filterType !== 'globalFilter') {
                if (this.props.filterCnt.filterName === this.state.ownid) {
                    this.graphData();
                }

            } else {
                this.graphData()

            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.globalFilterCnt.select !== this.props.globalFilterCnt.select) {
            if (this.state.selectedGlobalFilters.length > 0) {
                this.graphData();
            }
            this.setState({relFltr: true, relFilCnt: 0})
        }

        if (prevProps.globalFilterCnt.date.from !== this.props.globalFilterCnt.date.from || prevProps.globalFilterCnt.date.to !== this.props.globalFilterCnt.date.to) {
            if (this.state.selectedGlobalFilters.length > 0) {
                this.graphData();
            }
            this.setState({relFltr: true, relFilCnt: 0})
        }
        if (prevProps.globalFilterCnt.resetdate !== this.props.globalFilterCnt.resetdate) {
            if (this.state.selectedGlobalFilters.length > 0) {
                this.graphData();
            }
            this.setState({relFltr: false, relFilCnt: 0})
        }
        if (prevProps.globalFilters.globalFilterComp !== this.props.globalFilters.globalFilterComp) {
            this.setState({
                globalFilterComp: this.props.globalFilters.globalFilterComp
            })
        }
    }

    openSetting = () => {
        this.setState({modalStatus: !this.state.modalStatus})
        this.props.dragStatus(!this.state.modalStatus)
    }
    handleChangeComplete = (color, param) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[param] = color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    };
    toggleColorPicker = () => {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    }
    handleInputChange = (e) => {
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    graphData() {
        var obj = this;
        const collection = this.props.payload && this.props.payload.collection;
        const tabledata = this.props.payload && this.props.payload.graph || this.state.items
        const condition = this.props.payload && this.props.payload.condition || this.state.condition
        const whereCondition = this.props.payload && this.props.payload.whereCondition || this.state.whereCondition;
        const aggregation = this.props.payload && this.props.payload.aggregation || ''
        const groupbyEnable = this.props.payload && this.props.payload.groupbyEnable
        const limit = this.props.payload && this.props.payload.limit;
        const globalFilter = this.props.payload && this.props.payload.globalFilter || this.state.globalFilter
        const selectFilter = this.state.selectFilter || this.props.payload && this.props.payload.selectFilter
        const filterName = this.state.filterName || this.props.payload && this.props.payload.filterName
        const ownFilterList = this.props.payload && this.props.payload.ownFilterList || this.state.ownFilterList
        const unit = this.props.payload && this.props.payload.unit || this.state.unit
        const Color = this.props.payload && this.props.payload.Color || this.state.Color
        const flagId = this.props.payload && this.props.payload.flagId || this.state.flagId
        const setDate = this.props.payload && this.props.payload.setDate || this.state.setDate
        const setFrom = this.props.payload && this.props.payload.setFrom || this.state.setFrom
        const setTo = this.props.payload && this.props.payload.setTo || this.state.setTo
        const dateFrom = this.props.payload && this.props.payload.dateFrom || this.state.dateFrom
        const groupbyProps = this.props.payload && this.props.payload.datewithtime || this.state.datewithtime

        let selectedGlobalFilters = [];
        if (globalFilter.length > 0) {
            selectedGlobalFilters = globalFilter.filter(item => item.collection && item.collection !== '' && item.field && item.field !== '').map(fil => {
                return fil.id;
            })
            selectedGlobalFilters = [].concat.apply([], selectedGlobalFilters);
        }

        this.setState({
            ownFilterList: ownFilterList,
            flagId: flagId,
            Color: Color,
            tabledata: tabledata,
            items: tabledata,
            whereCondition: whereCondition,
            unit: unit,
            globalFilter: globalFilter,
            filterName: filterName,
            selectFilter: selectFilter,
            selectedGlobalFilters: selectedGlobalFilters

        })
        if ((collection !== '' && collection !== undefined && tabledata !== undefined) || getUrlSegment(1) === "collections") {
            let collections = (getUrlSegment(1) === "collections") ? atob(getUrlSegment(4)) : collection

            let groupby = '';
            let groupbyLabel = "";
            let groupdatewithtime = "";
            let components = tabledata;
            if (tabledata.length > 0) {
                if (groupbyEnable) {
                    groupbyLabel = tabledata[0].label;
                    groupby = tabledata[0].field;
                    groupdatewithtime = tabledata[0].datewithtime;
                    components = tabledata.slice(1, tabledata.length);
                }
            }


            let filters = [];
            let numberfilters = [];
            let datefilters = [];
            let globalFilters = [];
            if (whereCondition.length > 0) {
                for (var i = 0; i < whereCondition.length; i++) {
                    let conditionEntry = whereCondition[i];
                    if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                        let conditionValue = '';
                        if (conditionEntry.type === 'text') {
                            conditionValue = conditionEntry.text;
                        } else if (conditionEntry.type === 'conditions') {
                            conditionValue = conditionEntry.conditions;
                        }
                        let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];

                        if (conditionEntry.type === 'datepicker') {
                            let datefilter = {
                                "id": conditionEntry.field,
                                "from": conditionEntry.from,
                                "to": conditionEntry.to
                            }
                            datefilters.push(datefilter)
                        } else if (conditionEntry.type === 'number') {
                            let numberfilter = {
                                "id": conditionEntry.field,
                                "min": conditionEntry.min,
                                "max": conditionEntry.max
                            }
                            numberfilters.push(numberfilter)
                        } else {
                            if (conditionArr.length > 0) {
                                let filter = {
                                    "id": conditionEntry.field,
                                    "value": conditionArr
                                }
                                filters.push(filter);
                            }

                        }

                    }

                }
            }
            if ((this.props.filterCnt.date.from !== "" && this.props.filterCnt.date.to !== "") || this.props.filterCnt.select !== "") {

                if (globalFilter.filter(list => list.text === this.props.filterCnt.filterName).length > 0) {
                    for (var i = 0; i < globalFilter.length; i++) {
                        let conditionEntry = globalFilter[i];
                        if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                            let conditionValue = '';
                            if (conditionEntry.type === 'text') {
                                conditionValue = conditionEntry.text;
                            } else if (conditionEntry.type === 'conditions') {
                                conditionValue = conditionEntry.conditions;
                            }
                            let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];
                            if (conditionEntry.type === 'datepicker' && this.props.filterCnt.date.to !== "") {
                                let datefilter = {
                                    "id": conditionEntry.field,
                                    "from": this.props.filterCnt.date.from,
                                    "to": this.props.filterCnt.date.to
                                }
                                datefilters.push(datefilter)
                            } else if (conditionEntry.type === 'number') {
                                let numberfilter = {
                                    "id": conditionEntry.field,
                                    "min": conditionEntry.min,
                                    "max": conditionEntry.max
                                }
                                numberfilters.push(numberfilter)
                            } else {
                                if (this.props.filterCnt.select !== "") {
                                    let filter = {
                                        "id": conditionEntry.field,
                                        "value": [this.props.filterCnt.select]
                                    }
                                    filters.push(filter);
                                }

                            }

                        }
                        relFilCnt = this.state.relFilCnt + 1
                    }
                }
            }
            if (this.state.ownid === this.props.filterCnt.filterName) {
                for (var i = 0; i < ownFilterList.length; i++) {
                    let conditionEntry = ownFilterList[i];
                    if (conditionEntry && conditionEntry.field && conditionEntry.field !== '') {
                        let conditionValue = '';
                        if (conditionEntry.type === 'text') {
                            conditionValue = conditionEntry.text;
                        } else if (conditionEntry.type === 'conditions') {
                            conditionValue = conditionEntry.conditions;
                        }
                        let conditionArr = conditionValue !== '' ? conditionValue.split(',') : [];
                        if (conditionEntry.type === 'datepicker' && this.props.filterCnt.date.to !== "") {
                            let datefilter = {
                                "id": conditionEntry.field,
                                "from": this.props.filterCnt.date.from,
                                "to": this.props.filterCnt.date.to
                            }
                            datefilters.push(datefilter)
                        } else if (conditionEntry.type === 'number') {
                            let numberfilter = {
                                "id": conditionEntry.field,
                                "min": conditionEntry.min,
                                "max": conditionEntry.max
                            }
                            numberfilters.push(numberfilter)
                        } else {
                            if (this.props.filterCnt.select !== "") {
                                let filter = {
                                    "id": conditionEntry.field,
                                    "value": [this.props.filterCnt.select]
                                }
                                filters.push(filter);
                            }

                        }

                    }
                    var relFilCnt = this.state.relFilCnt + 1
                }
                relFilCnt = this.state.relFilCnt + 1

            }


            if (selectedGlobalFilters.length > 0) {
                for (var i = 0; i < selectedGlobalFilters.length; i++) {
                    let gFilterVal = selectedGlobalFilters[i];
                    let gFilterArr = globalFilter.filter(gf => gf.id === gFilterVal);
                    if (gFilterArr.length > 0 && gFilterArr[0].collection && gFilterArr[0].collection !== '' && this.props.globalFilterCnt.select && this.props.globalFilterCnt.select !== '') {

                        globalFilters.push({
                            "type": "select",
                            "collectionid": gFilterArr[0].collection,
                            "recordid": this.props.globalFilterCnt.select
                        });
                        relFilCnt = this.state.relFilCnt + 1
                    }

                    if (gFilterArr.length > 0 && gFilterArr[0].type === 'DATE' && (this.props.globalFilterCnt.date.from !== '' || this.props.globalFilterCnt.date.to !== '')) {

                        globalFilters.push({
                            "type": "date",
                            "from": this.props.globalFilterCnt.date.from,
                            "to": this.props.globalFilterCnt.date.to,
                            "field": gFilterArr[0].field
                        });
                        relFilCnt = this.state.relFilCnt + 1

                    }
                }
            }


            let sortCondition = [];
            if (condition.length > 0) {
                for (var j = 0; j < condition.length; j++) {
                    if (condition[j].field && condition[j].field !== '' && condition[j].sort && condition[j].sort !== ''
                        && condition[j].type && condition[j].type !== '') {
                        sortCondition.push(condition[j]);
                    }
                }
            }

            let fields = 0;
            if (tabledata.length > 1) {
                fields = tabledata[1].field;
            }

            let data = {
                collectionid: collections,
                "index": ES_INDEX,
                "graph": true,
                "fieldid": fields,
                "components": components,
                "condition": sortCondition,
                "filters": filters,
                "numberfilters": numberfilters,
                "datefilters": datefilters,
                "globalFilters": globalFilters,
                "size": limit,
                "linkedid": this.props.linkedId.param,
                "flags": flagId
            }
            if (flagId.length !== 0) {
                data.fieldid = "101"
            }

            if (setDate) {
                if (setDate === 'todays') {

                    data.fromdate = moment().format('YYYY-MM-DD');
                    data.todate = moment().format('YYYY-MM-DD');
                }
                if (setDate === 'lastsevendays') {
                    data.fromdate = moment().add(-7, 'days').format('YYYY-MM-DD');
                    data.todate = moment().format('YYYY-MM-DD')
                }

                if (setDate === 'thismonth') {
                    let thisMoment = moment();
                    data.fromdate = moment().moment(thisMoment).startOf('month').format('YYYY-MM-DD')
                    data.todate = moment().format('YYYY-MM-DD')
                }

                if (setDate === 'last6month') {
                    data.fromdate = moment().subtract(6, 'months').format('YYYY-MM-DD')
                    data.todate = moment().format('YYYY-MM-DD')
                }
                if (setDate === 'daterange') {
                    data.fromdate = moment(setFrom).format('YYYY-MM-DD')
                    data.todate = moment(setTo).format('YYYY-MM-DD')
                }
                if (setDate === 'dateFrom') {
                    data.fromdate = moment().add('-' + dateFrom, 'days').format('YYYY-MM-DD')
                    data.todate = moment().format('YYYY-MM-DD')
                }
            }
            if (groupbyProps) {
                data.groupbyProps = [
                    {
                        "type": "datetime",
                        "dateOnly": true
                    }
                ]
            }
            if (groupbyEnable) {
                data.groupbyEnable = groupbyEnable;
                data.groupby = groupby;
                data.groupbyLabel = groupbyLabel;
                data.groupdatewithtime = groupdatewithtime;
            }
            if ((relFilCnt !== 0 && this.state.relFltr) || !this.state.relFltr) {
                if (data.collectionid) {
                    axios.post(API_PATH + "project/datacard", data)
                        .then(function (res) {
                            var carddata = res.data.results.data;
                            var aggregatevalue = carddata.aggregation;

                            let areadata = carddata.graph.map(function (item) {
                                let firstKey = Object.keys(item)[0];
                                item[firstKey] = isValidDate(item[firstKey])
                                for (var key in item) {
                                    if (item.hasOwnProperty(key) && key !== firstKey) {
                                        item[key] = parseInt(item[key]);
                                    }
                                }

                                return item;
                            });
                            obj.setState({
                                multiarachart: areadata,
                                aggValue: aggregatevalue.length > 0 ? Math.round(aggregatevalue[0][aggregation]) : ''
                            });
                            return res;

                        }).catch(function (err) {
                        return err;
                    });
                }

            }
        }


        if ((collection !== '' && collection !== undefined) || getUrlSegment(1) === "collections") {
            let collections = (getUrlSegment(1) === "collections") ? atob(getUrlSegment(4)) : collection

            this.setState({cId: collections})
            axios({
                method: "GET",
                url: API_PATH + "collection/showform?_id=" + collections + "&index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                obj.setState({showlistField: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });


            axios({
                method: "GET",
                url: API_PATH + "collection/flagsettingslist?collection=" + collections + "&index=" + ES_INDEX + "&uid=" + 2,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                //console.log(res.data.data)
                obj.setState({getFlagData: res.data.data});
                return res;

            }).catch(function (err) {
                return err;
            });


        }
        if (collection !== '' || collection !== undefined) {
            axios({
                method: "GET",
                url: API_PATH + "collection/" + collection + "/collectedinfo?index=" + ES_INDEX,
                async: false,
                headers: {'Content-Type': 'application/json', "X_API_KEY": getProfile().token}
            }).then(function (res) {
                var carddata = res.data.results.data[0];
                obj.setState({carddata: carddata});
                return res;

            }).catch(function (err) {
                return err;
            });
        } else {
            obj.setState({multiarachart: graphData});

        }


    }

    handleChange = (e) => {
        if (e.target.name === "collection") {
            this.props.showform({_id: e.target.value});
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    chartSaving = () => {
        var prjctdtlid = atob(getUrlSegment(4));
        let param = {
            "projectid": prjctdtlid,
            "uid": getProfile().id,
            "index": ES_INDEX,
            "type": this.props.getdashboardChange.Dashboard,
            "dashboard": state.getStorableState()
            ,
            "id": this.props.getdashboardChange.UpdateId
        }
        this.props.chartSaving(param)
        this.setState({modalStatus: false})
    }
    /*  drag and drop functions for hierarchial table */

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        });
    };
    setDate = (e) => {

        let sel = e.target
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        payload["label" + e.target.name] = sel.options[sel.selectedIndex].text
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    addData = (Id, updateParam) => {
        let data = this.state[updateParam]
        let newData = {
            id: "item-" + new Date().getTime(),
            label: "item "
        }
        let _data = data.concat(newData)
        this.setState({[updateParam]: _data})
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[updateParam] = _data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    removeData = (dragid, updateParam) => {
        let data = this.state[updateParam]
        let _data = data.filter(item => item.id !== dragid)
        this.setState({[updateParam]: _data})
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[updateParam] = _data
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    handleLabelChange = (e, dragid, whereCondition, type) => {
        var updatedParam = "items";
        if (whereCondition === "graph") {
            updatedParam = "items";
        } else {
            updatedParam = whereCondition;
        }

        var _newState = this.state[updatedParam];
        var index = (_newState.findIndex(obj => obj["id"] === dragid))
        _newState[index][e.target.name] = e.target.value;
        if (whereCondition === "whereCondition" && type !== "field") {
            _newState[index]["type"] = type;
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[whereCondition] = this.state[updatedParam]
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    handleColorChange = (color, param, label) => {
        if (label === "graph") {
            var _newState = this.state.items;
        } else {
            var _newState = this.state[label];

        }
        var index = (_newState.findIndex(obj => obj["id"] === param))
        _newState[index]["color"] = color;
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        if (label === "graph") {
            payload[label] = this.state.items
        } else {
            payload[label] = this.state[label]
        }
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    borderColorChange = (color, param) => {

        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["bordercolor"] = color
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });

    }
    handleClick = (status) => {
        this.setState({colorpickerStatus: status})
    }


    checkBoxchange = (e) => {
        let val;
        if (e.target.value === "false") {
            val = true
        } else {
            val = false
        }

        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = val
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }
    radioBoxchange = (e) => {
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload[e.target.name] = e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });
    }

    handleConditionChange = (e) => {
        var _newState = this.state.condition;
        if (e.target.name === 'field') {
            let t = e.target.value.split("|");
            _newState[0]["field"] = t[0];
            _newState[0]["type"] = t[1] === "datepicker" ? "date" : t[1];
        } else {
            _newState[0][e.target.name] = e.target.value;
        }
        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["condition"] = this.state.condition
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    }
    handleFlagSet = (fid) => {
        // Get the index of id in the array
        const index = this.state.flagId.indexOf(fid);
        if (index > -1) {
            // This means id is present in the array, so remove it
            this.state.flagId.splice(index, 1);
        } else {
            // This means id is not present in the array, so add it
            this.state.flagId.push(fid);
        }

        // You can use this to debug purpose

        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload["flagId"] = this.state.flagId
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
            this.graphData()
        });

    }

    globalFilterchange = (e) => {
        let val = e.target.value;

        let globalFilter = this.state.globalFilter || [];
        let selectedGlobalFilters = this.state.selectedGlobalFilters;
        let globalFilterComp = this.state.globalFilterComp;

        if (selectedGlobalFilters.includes(val)) {
            var index = selectedGlobalFilters.indexOf(val);
            selectedGlobalFilters.splice(index, 1)
            globalFilter.splice(index, 1)
        } else {
            selectedGlobalFilters.push(val);
            globalFilterComp = globalFilterComp.filter(comp => comp.id === val);

            if (globalFilterComp.length > 0) {
                if (globalFilterComp[0].type === "DATE_FILTER_CONTAINER") {
                    globalFilter.push({
                        "type": "DATE",
                        "id": globalFilterComp[0].id,
                        "from": this.props.globalFilterCnt.date.from,
                        "to": this.props.globalFilterCnt.date.to,
                        "field": globalFilterComp[0].payload.selectFilter
                    })
                } else {
                    globalFilter.push({
                        "type": "SELECT",
                        "id": globalFilterComp[0].id,
                        "collection": globalFilterComp[0].payload.collection,
                        "field": globalFilterComp[0].payload.selectFilter
                    })
                }

            }
        }

        const {id, dropzoneID, parentID} = this.props;
        let payload = this.state.payload
        payload['globalFilter'] = globalFilter;
        //delete payload['[object Object]'];

        this.setState({
            selectedGlobalFilters: selectedGlobalFilters,
            globalFilter: globalFilter
        })
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        }, function () {
            this.graphData()
        });
    }


    render() {

        const {
            id,
            showBasicContent, showPreview,
            dropzoneID, payload, parentID, name
        } = this.props;
        let sourceType = (payload && payload.sourceType) || false
        let showlistField = []
        let sourceData = []
        if (this.state.showlistField.length !== 0) {
            for (let i = 0; i < this.state.showlistField.pages.length; i++) {
                (this.state.showlistField.pages[i].components).map(list => {
                    showlistField.push(list)
                })
            }
        }
        if (sourceType.toString() === "true") {
            sourceData = this.props.listAllCollections.ListAllCollections.data
        } else {
            sourceData = this.props.EntityData.Entity.data
        }


        let containerData = {
            chartTitle: this.state.chartTitle || payload && payload.chartTitle,
            subTitle: this.state.subTitle || payload && payload.subTitle,
            subTitleEnabled: this.state.subTitleEnabled || payload && payload.subTitleEnabled,
            data: this.state.multiarachart || [],
            collection: this.state.collection || payload && payload.collection,
            tabledata: this.state.items || payload && payload.graph,
            enableyaxis: this.state.enableyaxis || payload && payload.enableyaxis || false,
            legend: this.state.legend || payload && payload.legend || false,
            alldata: this.state.alldata || payload && payload.alldata || false,
            unit: this.state.unit || payload && payload.unit,
            graphHeight: this.state.graphHeight || payload && payload.graphHeight,
            condition: this.props.payload && this.props.payload.condition || this.state.condition,
            showlistField: showlistField,
            collectionData: sourceData,
            groupby: this.state.groupby || payload && payload.groupby,
            groupbyEnable: this.state.groupbyEnable || payload && payload.groupbyEnable,
            Aggregate: this.state.Aggregate || payload && payload.Aggregate,
            whereCondition: this.state.whereCondition || payload && payload.whereCondition,
            limit: this.state.limit || payload && payload.limit,
            sourceType: sourceType,
            bordercolor: (this.props.payload && this.props.payload.bordercolor) || "#fff",
            globalFilter: this.state.globalFilter || payload && payload.globalFilter,
            ownFilter: this.state.ownFilter || payload && payload.ownFilter,
            ownFilterList: this.state.ownFilterList || payload && payload.ownFilterList,
            globalFilterComp: this.state.globalFilterComp || [],
            selectedGlobalFilters: this.state.selectedGlobalFilters || [],
            fields: this.state.fields || payload && payload.fields,
            aggregation: this.state.aggregation || payload && payload.aggregation,
            linecolor: this.state.linecolor || payload && payload.linecolor || "#3AFF2D",
            aggValue: this.state.aggValue,
            linechart: true,
            Color: this.state.Color || payload && payload.Color,
            getFlagData: this.state.getFlagData || this.props.getFlagData.getFlag,
            flagId: this.state.flagId,
            setDate: this.state.setDate || payload && payload.setDate,
            setFrom: this.state.setFrom || payload && payload.setFrom,
            setTo: this.state.setTo || payload && payload.setTo,
            dateFrom: this.state.dateFrom || payload && payload.dateFrom,
            datewithtime: this.state.datewithtime || payload && payload.datewithtime || "no",

        }
        if (showBasicContent) {
            return (
                <Draggable {...this.props} >
                    {
                        <span className="drag-icon">{name}</span>
                    }
                </Draggable>
            )
        }

        if (showPreview) {
            return (


                <ViewData
                    loading={this.state.loading}
                    viewStatus={this.state.viewStatus}
                    containerData={containerData}
                    settingStatus={this.settingStatus}
                    cid={this.state.cId}
                    showlistField={showlistField}
                    state={this.state}
                    linkedId={this.props.linkedId.param}
                    key={`result-table`}
                    chart={<PieChartCustom
                        containerData={containerData}

                    />}
                />


            );
        }
        return (
            <React.Fragment>
                <Draggable {...this.props} draggable={!this.props.DragDisabled.dragStatus}>
                    <SettingsPanel
                        id={id}
                        name={containerData.chartTitle ? containerData.chartTitle : name}
                        dropzoneID={dropzoneID}
                        parentID={parentID}
                        openSetting={this.openSetting}
                        removeElement={<div className="delete-icon"
                                            onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
                    />
                </Draggable>
                {this.state.modalStatus &&
                <SettingsModal
                    title={`Pie Chart  -   Settings`}
                    containerData={containerData}
                    colorpickerStatus={this.state.colorpickerStatus}
                    modaltype={8}
                    Close={this.openSetting}
                    OKAction={this.chartSaving}
                    cancelAction={this.openSetting}
                    handleChange={this.handleChange}
                    checkBoxchange={this.checkBoxchange}
                    radioBoxchange={this.radioBoxchange}
                    handleConditionChange={this.handleConditionChange}
                    handleLabelChange={this.handleLabelChange}
                    handleColorChange={this.handleColorChange}
                    borderColorChange={this.borderColorChange}
                    handleFlagSet={this.handleFlagSet}
                    handleInputChange={this.handleInputChange}
                    handleClick={this.handleClick}
                    globalFilterchange={this.globalFilterchange}
                    addData={this.addData}
                    removeData={this.removeData}
                    onDragEnd={this.onDragEnd}
                    setDate={this.setDate}
                />

                }
            </React.Fragment>
        )
    }
};
const mapStateToProps = state => ({
    showFormModal: state.CollectionsReducer.showFormModal,
    listAllCollections: state.CollectionsReducer.listAllCollections,
    getdashboardChange: state.DynamicDashboard.getDashboard,
    DragDisabled: state.DynamicDashboard.getDragDisabled,
    EntityData: state.EntityReducer.GetEntity,
    filterCnt: state.DynamicDashboard.getFilterCnt,
    linkedId: state.EntityReducer.LinkedId,
    getFlagData: state.DynamicDashboard.getFlagData,
    globalFilterCnt: state.DynamicDashboard.getGlobalFilterCnt,
    globalFilters: state.DynamicDashboard.getGlobalFilters
});
export default withRouter(connect(mapStateToProps, {
    collections: collectionActions.listallcollections,
    showform: collectionActions.showform,
    chartSaving: DynamicDashboard.saveDashboarddata,
    dragStatus: DynamicDashboard.dragStatus,
    getFlag: DynamicDashboard.getFlag

})(PieChartContainer));
