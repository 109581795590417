import {workflow} from '../services/workflow.services'
import {getProfile} from "../utilities/AuthService";
import {task} from "../services/task.services";
import {resources} from "../services/Resources.services";
import {systemtask} from "../services/systemtask.services";


export const listWorkFlow = (params) => dispatch => {
    let listWorkFlow = workflow.listWorkFlow(params);
    dispatch({
        type: 'WORKFLOW_LOADER',
        loading: true,

    })
    listWorkFlow.then(listWorkFlow => {
        dispatch({
            type: 'LIST_All_WORKFLOW',
            listworkflow: listWorkFlow,

        })
        dispatch({
            type: 'WORKFLOW_LOADER',
            loading: false,

        })
    })
};

export const batchWorkFlow = (params) => dispatch => {
    let listWorkFlow = workflow.listWorkFlow(params);
    listWorkFlow.then(listWorkFlow => {
        let batchWorkFlow = listWorkFlow.data.results.data;
        let batchWorkFlowdata = batchWorkFlow.map(item=> { item['name'] = atob(item['name']); return item;});
        dispatch({
            type: 'LIST_BATCH_WORKFLOW',
            batchWorkFlowdata: batchWorkFlowdata

        })
    })
};
export const getWorkFlowdata = (params) => dispatch => {
    let workFlowData = workflow.listWorkFlow(params);
    dispatch({
        type: 'WORKFLOW_DATA_LOADER',
        loading: true,

    });
    workFlowData.then(workFlowData => {
        dispatch({
            type: 'WORKFLOW_DATA',
            workFlowData: workFlowData,

        })
        dispatch({
            type: 'WORKFLOW_DATA_LOADER',
            loading: false,

        });
    })
};
export const WorkflowStageinfo = (wid,search,limit,offset,initialstage,index) => dispatch => {
    let WorkflowStageinfo = workflow.WorkflowStageinfo(wid,search,limit,offset,initialstage,index);
    WorkflowStageinfo.then(WorkflowStageinfo => {
        ////////////console.log(WorkflowStageinfo)
        dispatch({
            type: 'WORKFLOW_STAGEINFO',
            workflowstageinfo: WorkflowStageinfo,

        })
    })



};
export const WorkflowUserinfo = (wid,limit,offset,index) => dispatch => {
    let WorkflowUserinfo = workflow.WorkflowUserinfo(wid,limit,offset,index);
    WorkflowUserinfo.then(WorkflowUserinfo => {
        dispatch({
            type: 'WORKFLOW_USERINFO',
            workflowuserinfo: WorkflowUserinfo,

        })
    })



};
export const WorkflowDetails = (wid,index) => dispatch => {
    let WorkflowDetails = workflow.WorkflowDetails(wid,index);
    WorkflowDetails.then(WorkflowDetails => {
        dispatch({
            type: 'WORKFLOW_DETAILS',
            workflowdetails: WorkflowDetails,

        })
    })
};
export const WorkflowFormDetails = (params) => dispatch => {

    let WorkflowFormDetails = workflow.WorkflowFormDetails(params);
    WorkflowFormDetails.then(WorkflowFormDetails => {
        dispatch({
            type: 'WORKFLOW_FORMDETAILS',
            workflowformdetails: WorkflowFormDetails,

        })
    })
};

export const StageTaskInfo = (stageid,index) => dispatch => {
    let StageTaskInfo = workflow.stagetaskinfo(stageid,index);
    StageTaskInfo.then(StageTaskInfo => {
        dispatch({
            type: 'STAGE_TASK_INFO',
            stagetaskinfo: StageTaskInfo,

        })
    })
};

export const InvokeDetails = (wid,index) => dispatch => {
    let InvokeDetails = workflow.InvokeDetails(wid,index);
    InvokeDetails.then(InvokeDetails => {
        dispatch({
            type: 'INVOKE_DETAILS',
            invokedetails: InvokeDetails,

        })
    })
};
export const SavestageTask = (wid,stageid,taskid,subject,uid,priority,mode,duein,instruction,to,cc,forms,tagsid,tagsname,files,nextstage,metaform,fstatus,index) => dispatch => {
    let SavestageTask = workflow.SavestageTask(wid,stageid,taskid,subject,uid,priority,mode,duein,instruction,to,cc,forms,tagsid,tagsname,files,nextstage,metaform,fstatus,index);
    SavestageTask.then(SavestageTask => {
        dispatch({
            type: 'SAVE_STAGE_TASK',
            savestagetask: SavestageTask,

        })
    })

};
export const UpdateMetaTask = (metaform) => dispatch => {

        dispatch({
            type: 'UPDATE_META_FORM',
            metaform: metaform,

        })


};

export const updateMetaFormField = (name,value,index) => dispatch => {
    dispatch({
        type: 'UPDATE_META_FORM_SPECIFIC_FIELD',
        name: name,
        value:value,
        index:index

    })
};
export const updateTask = (name,value) => dispatch => {
    dispatch({
        type: 'UPADATE_TASK_DATA',
        name: name,
        value:value,

    })
};
export const SaveDisplaysettings = (wid,dispid,index) => dispatch => {
    let SaveDisplaySettings = workflow.SaveDisplaySettings(wid,dispid,index);
    SaveDisplaySettings.then(StageTaskInfo => {
        dispatch({
            type: 'SAVE_DISPLAY_SETTINGS',
            workflowid: wid,
            fields:dispid,
            index:index

        })
    })
};

export const ProcessWorkFlowtask = (params, closeworkflowparams,asyncparams) => dispatch => {
    let wftasktriggered = workflow.triggerwftask(params);
    wftasktriggered.then(wftasktriggered => {

        dispatch({
            type: 'SAVE_FORM_LOADING',
            loading: false,

        });
        dispatch({
            type: 'PROCESS_WORKFLOW_TASK',
            processWorkflowtask: wftasktriggered.data.data

        })
        let Processworkflowtask = workflow.ProcessNextStage(closeworkflowparams);
        Processworkflowtask.then(Processworkflowtask => {
            if(closeworkflowparams.stage === 'final'){
                let postTaskCompletionasync = task.postTaskCompletionasync(asyncparams);

            }

        });

    })
}
export const ProcessWorkFlowtaskEmbed = (params, closeworkflowparams, asyncparams) => dispatch => {
    let wftasktriggered = workflow.triggerwftask(params);
    wftasktriggered.then(wftasktriggered => {



        let Processworkflowtask = workflow.ProcessNextStage(closeworkflowparams);
        Processworkflowtask.then(Processworkflowtask => {
            dispatch({
                type: 'SAVE_FORM_LOADING',
                loading: false,

            });
            dispatch({
                type: 'PROCESS_WORKFLOW_TASK',
                processWorkflowtask: wftasktriggered.data.data

            })

            if(closeworkflowparams.stage === 'final') {
                let postTaskCompletionasync = task.taskCompletionExternal(asyncparams);
            }


        });

    })
}
/*export const ProcessWorkFlowtask = (params) => dispatch => {
    let Processworkflowtask = workflow.ProcessNextStage(params);
    Processworkflowtask.then(Processworkflowtask => {

        dispatch({
            type: 'PROCESS_WORKFLOW_TASK',
            processWorkflowtask: Processworkflowtask

        })
        dispatch({
            type: 'SAVE_FORM_LOADING',
            loading: false,

        });
    })
};*/

export const ProcessThroughApi = (params) => dispatch => {
    let processResult = workflow.ProcessThroughApi(params);
    processResult.then(processResult => {

        dispatch({
            type: 'PROCESS_THROUGH_API',
            processResult: processResult

        })
        dispatch({
            type: 'SAVE_FORM_LOADING',
            loading: false,

        });
    })
};

export const SaveWorkflow = (params) => dispatch => {
    let SaveWorkflow = workflow.SaveWorkflow(params);
    SaveWorkflow.then(SaveWorkflow => {
        dispatch({
            type: 'MODIFY_WORKFLOW'

        })
    })
};
export const Saveworkflowcollection = (params) => dispatch => {
    let Saveworkflowcollection = workflow.Saveworkflowcollection(params);
    Saveworkflowcollection.then(Saveworkflowcollection => {
        dispatch({
            type: 'SAVE_WORKFLOW_COLLECTION',
            saveworkflowcollection: Saveworkflowcollection,

        })
    })

};
export const DeleteWorkflow = (params) => dispatch => {
    let DeleteWorkflow = workflow.DeleteWorkflow(params);
    DeleteWorkflow.then(DeleteWorkflow => {
        dispatch({
            type: 'MODIFY_WORKFLOW',
            DeleteWorkflow: DeleteWorkflow,

        })
    })
};
export const StageTaskInfoDet = (params) => dispatch => {
    let stagetaskinfodet = workflow.Getstagetaskinfo(params);
    dispatch({
        type: 'STAGE_INFO_LOADER',
        loading: true,

    });
    stagetaskinfodet.then(stagetaskinfodet => {
        dispatch({
            type: 'GET_STAGE_TASK_INFO',
            stagetaskinfodet: stagetaskinfodet,

        })
        dispatch({
            type: 'STAGE_INFO_LOADER',
            loading: false,

        });
    })
};
export const listallworkflowtags = (params) => dispatch => {
    let WorkFlowtaglist = workflow.getWorkFlowTags(params);
    dispatch({
        type: 'WORKFLOW_TAG_LOADER',
        loading:true,

    });
    WorkFlowtaglist.then(WorkFlowtaglist => {
        dispatch({
            type: 'LIST_All_WORKFLOW_TAGS',
            WorkFlowtaglist: WorkFlowtaglist.data.results.data
        });
        dispatch({
            type: 'WORKFLOW_TAG_LOADER',
            loading:false,

        });
    })

};
export const saveWorkflowBatch = (params) => dispatch => {
    dispatch({
        type: 'WORK_FLOW_LOADER',
        loading:true,

    });
    let saveWorkflowBatch = workflow.saveWorkflowBatch(params);
    saveWorkflowBatch.then(saveWorkflowBatch => {
        dispatch({
            type: 'SAVE_WORKFLOW_BATCH',
            saveworkflowbatch: saveWorkflowBatch,
        })

    })
};

export const listWorkflowBatch = (params) => dispatch => {
    dispatch({
        type: 'WORK_FLOW_LOADER',
        loading:true,

    });
    let listWorkflowBatch = workflow.listWorkflowBatch(params);
    listWorkflowBatch.then(listWorkflowBatch => {
        dispatch({
            type: 'LIST_All_WORKFLOW_BATCH',
            listworkflowbatch: listWorkflowBatch,

        })
        dispatch({
            type: 'WORK_FLOW_LOADER',
            loading:false,

        });
    }).catch(error => {
        dispatch({
            type: 'LIST_All_WORKFLOW_BATCH',
            listworkflowbatch: {data: {results: {data:[]}}},

        })
        dispatch({
            type: 'WORK_FLOW_LOADER',
            loading:false,

        });
    })
};

export const deleteWorkflowBatch = (params) => dispatch => {
    dispatch({
        type: 'WORK_FLOW_LOADER',
        loading:true,

    });
    let deleteWorkflowBatch = workflow.deleteWorkflowBatch(params);
    deleteWorkflowBatch.then(deleteWorkflowBatch => {
        dispatch({
            type: 'DELETE_WORKFLOW_BATCH',
            deleteworkflowbatch: deleteWorkflowBatch,

        })

    })
};
export const activateWorkflowBatch = (params) => dispatch => {
    dispatch({
        type: 'WORK_FLOW_LOADER',
        loading:true,

    });
    let activateWorkflowBatch = workflow.activateWorkflowBatch(params);
    activateWorkflowBatch.then(activateWorkflowBatch => {
        dispatch({
            type: 'ACTIVATE_WORKFLOW_BATCH',
            activateWorkflowBatch: activateWorkflowBatch,
        })

    })
};
export const noformWorkFlow = (params) => dispatch => {
    dispatch({
        type: 'NOFORM_WORKFLOW_LOADER',
        loading:true,

    });
    let noformWorkFlow = workflow.noformWorkFlow(params);
    noformWorkFlow.then(noformWorkFlow => {
        dispatch({
            type: 'NO_FORM_WORKFLOW',
            noformworkflow: noformWorkFlow,
        })
        dispatch({
            type: 'NOFORM_WORKFLOW_LOADER',
            loading:false,

        });
    })
};

export const saveWorkflowMetaForm = (params) => dispatch => {
    dispatch({
        type: 'WORKFLOW_META_LOADER',
        loading:true,

    });
    let savedMeta = workflow.saveWorkflowMetaForm(params);
    savedMeta.then(savedMeta => {
        dispatch({
            type: 'WORKFLOW_META_SAVE',
            savedMeta: savedMeta.data.results.data,
        })
        dispatch({
            type: 'WORKFLOW_META_LOADER',
            loading:false,

        });
    })
};
export const processAssignedNextStage = (params) => dispatch => {

    dispatch({
        type: 'ASSIGNED_NEXTSTAGE_LOADER',
        loading:true,

    });
    let processed = workflow.processAssignedNextStage(params);
    processed.then(processed => {
        dispatch({
            type: 'PROCESS_ASSIGNED_NEXTSTAGE',
            processed: processed.data.success,
        })
        dispatch({
            type: 'ASSIGNED_NEXTSTAGE_LOADER',
            loading:false,

        });
    })
};

export const closeRecordWorkflow = (params) => dispatch => {

    dispatch({
        type: 'ASSIGNED_NEXTSTAGE_LOADER',
        loading:true,

    });
    let closed = workflow.closeRecordWorkflow(params);
    closed.then(closed => {
        dispatch({
            type: 'CLOSE_RECORD_WORKFLOW',
            closed: closed.data.success,
        })
        dispatch({
            type: 'ASSIGNED_NEXTSTAGE_LOADER',
            loading:false,

        });
    })
};