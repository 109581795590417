import moment from 'moment'
import {FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH} from "../constants";
import {DirectFileUpload} from "../utilities/Upload/UploadFile";
import configureStore from '../configureStore';
export default class CustomFunctions {
    // Initializing important variables
    constructor() {
        this.formatBytes = this.formatBytes.bind(this);

    }

    formatBytes(bytes, decimals) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    ArrayExist(existCatagory) {

    }

}

export function urltoFile(url, filename, mimeType) {
    return (fetch(url)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], filename, {type: mimeType});
            })
    );
}

export function Datecomparison(dueDate, curentDate, menu) {

    //   var curentDate = new Date(curentDate);
    //  var dueDate = new Date(dueDate);
    var DaysDiff;
    if (moment(curentDate).isAfter(dueDate)) {
        //var TimeDiff = curr_date.getTime() - user_date.getTime();
        DaysDiff = moment(curentDate).diff(dueDate, 'days');
        // if(menu == 'task') {
        //     return 'overdue';
        // }
        // else{
        if (DaysDiff === 1)
            DaysDiff = DaysDiff + ' ' + 'Day Overdue';
        else
            DaysDiff = DaysDiff + ' ' + 'Days Overdue'
        return DaysDiff;
        // }
    }
        // else if(user_date == curr_date){
        //
        //     if(menu == 'task') {
        //
        //         return 'Due Today';
        //
        //     }else {
        //
        //         return 'Today';
        //     }
    // }
    else {
        // if(menu == 'task') {
        //
        //     return user_date.toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'});
        //
        // }else {
        //     var TimeDiff = user_date.getTime() - curr_date.getTime();
        //     var DaysDiff = Math.round(TimeDiff / (1000 * 3600 * 24));
        //     return DaysDiff + ' ' + 'days';
        // }
        DaysDiff = moment(dueDate).format('DD MMM, YYYY')
        return DaysDiff;

    }

}

export function Datecomparisondue(dueDate, curentDate, menu) {

    //   var curentDate = new Date(curentDate);
    //  var dueDate = new Date(dueDate);
    var DaysDiff;
    if (moment(curentDate).isAfter(dueDate)) {
        //var TimeDiff = curr_date.getTime() - user_date.getTime();
        DaysDiff = moment(curentDate).diff(dueDate, 'days');
        // if(menu == 'task') {
        //     return 'overdue';
        // }
        // else{
        if (DaysDiff === 1)
            DaysDiff = DaysDiff + ' ' + 'Day Overdue';
        else
            DaysDiff = DaysDiff + ' ' + 'Days Overdue'
        return DaysDiff;
        // }
    }
        // else if(user_date == curr_date){
        //
        //     if(menu == 'task') {
        //
        //         return 'Due Today';
        //
        //     }else {
        //
        //         return 'Today';
        //     }
    // }
    else {
        // if(menu == 'task') {
        //
        //     return user_date.toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric'});
        //
        // }else {
        //     var TimeDiff = user_date.getTime() - curr_date.getTime();
        //     var DaysDiff = Math.round(TimeDiff / (1000 * 3600 * 24));
        //     return DaysDiff + ' ' + 'days';
        // }
        DaysDiff = 'Due on  ' + moment(dueDate).format('DD MMM')
        return DaysDiff;

    }

}

export function Datecompletedue(dueDate, curentDate, menu) {

    //   var curentDate = new Date(curentDate);
    //  var dueDate = new Date(dueDate);
    var DaysDiff;
    if (moment(curentDate).isAfter(dueDate)) {
        //var TimeDiff = curr_date.getTime() - user_date.getTime();
        DaysDiff = moment(curentDate).diff(dueDate, 'days');
        // if(menu == 'task') {
        //     return 'overdue';
        // }
        // else{
        if (DaysDiff === 1)
            DaysDiff = '(' + DaysDiff + ' ' + 'day' + ')';
        else
            DaysDiff = '(' + DaysDiff + ' ' + 'days' + ')'
        return DaysDiff;
        // }
    }
    else {

        DaysDiff = ''
        return DaysDiff;

    }

}

export function Basicimgtyp(filetype, listtyp) {
    var ftyp = filetype.toLowerCase();
    var ltyp = listtyp;
    if (ftyp == 'pdf') {
        if (ltyp == 'G') {
            return "assets/images/pdf-lg.png";
        } else if (ltyp == 'L') {
            return "assets/images/default_pdf.png";
        }

    } else if (ftyp == 'xls') {
        if (ltyp == 'G') {
            return "assets/images/xlx-lg.png";
        } else if (ltyp == 'L') {
            return "assets/images/xlx.png";
        }
    } else if (ftyp == 'docx') {
        if (ltyp == 'G') {
            return "assets/images/doc-lg.png";
        } else if (ltyp == 'L') {
            return "assets/images/doc.png";
        }
    } else if (ftyp == 'doc') {
        if (ltyp == 'G') {
            return "assets/images/doc-lg.png";
        } else if (ltyp == 'L') {
            return "assets/images/doc.png";
        }
    } else if (ftyp == 'mp4') {
        if (ltyp == 'G') {
            return "assets/images/mp4-lg.png";
        } else if (ltyp == 'L') {
            return "assets/images/mp4.png";
        }
    } else if (ftyp == 'jpg') {
        return "assets/images/jpg.png";
    } else {
        return "assets/images/Noimage.png";
    }
}

export function getSubdomain(hostname) {
    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(hostname);
    return hostname.replace(urlParts[0], '').slice(0, -1);
}

export function hasExtension( url ) {
    var parts = url.split('/'),
        last  = parts.pop();
    return ( parts.length > 3 ) && ( last.indexOf('.') != -1 );
}

export function getFileExtension(filename) {

    filename = (filename !== undefined) ? filename.toLowerCase() : 'image';
    return filename.split('.').pop();
}

export function getFileIcon(filename) {
    var ext = getFileExtension(filename);
    var icon = '';
    switch (ext) {
        case 'pdf':
            icon = 'pdf';
            break;
        case 'ppt':
            icon = 'ppt';
            break;
        case 'pptx':
            icon = 'ppt';
            break;
        case 'doc':
            icon = 'docx';
            break;
        case 'docx':
            icon = 'docx';
            break;
        case 'bmp':
            icon = 'bmp';
            break;
        case 'mp4':
            icon = 'mp4';
            break;
        case 'xls':
            icon = 'xlx';
            break;
        case 'xlsx':
            icon = 'xlx';
            break;
        case 'gif':
            icon = 'gif';
            break;
        case 'png':
            icon = 'png';
            break;
        case 'jpg':
            icon = 'jpg';
            break;
        case 'jpeg':
            icon = 'jpeg';
            break;
        default :
            icon = 'image';
            break;
    }

    return icon;

}


export function getFileExtensionImage(filename) {
    filename = filename.toLowerCase();
    if (filename.split('.').pop() === 'pdf') {
        return 'fa-file-pdf-o';
    }
    if (filename.split('.').pop() === 'png') {
        return 'fa-file-image-o';
    }
    if (filename.split('.').pop() === 'jpg') {
        return 'fa-file-image-o';
    }
    if (filename.split('.').pop() === 'jpeg') {
        return 'fa-file-image-o';
    }
    if (filename.split('.').pop() === 'xlsx') {
        return 'fa-file-excel-o';
    }
    if (filename.split('.').pop() === 'docx') {
        return 'fa-file-word-o';
    }
    if (filename.split('.').pop() === 'doc') {
        return 'fa-file-word-o';
    }
    if (filename.split('.').pop() === 'xls') {
        return 'fa-file-excel-o';
    }

    if (filename.split('.').pop() === 'pptx') {
        return 'fa-file-powerpoint-o';
    } else {
        return 'fa-file-o'
    }
}

export function forceDownload(url, fileName) {

    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
}

export function urlMetaData(urlData, metaid, message) {
    var metainfo = []
    var siteName = '',
        pageUrl = '',
        pageTitle = '',
        pageUpdated = '',
        pageDescription = '',
        pageImage = ''

    if (urlData.length !== 0) {

        if (urlData.metaProperties.length !== 0) {
            if (urlData.metaProperties[`og:site_name`] && urlData.metaProperties[`og:site_name`].value !== undefined) {
                siteName = urlData.metaProperties[`og:site_name`].value;
            }
            if (siteName === "") {
                if (urlData.metaProperties["al:android:app_name"] !== undefined) {
                    var siteName = urlData.metaProperties["al:android:app_name"]["value"];
                } else if (urlData.metaProperties["al:ios:app_name"] !== undefined) {
                    var siteName = urlData.metaProperties["al:ios:app_name"]["value"]
                }
            }
            if (urlData.metaProperties["og:url"] != undefined) {
                pageUrl = urlData.metaProperties["og:url"]["value"];
            }
            if (pageUrl === '') {
                pageUrl = message
            }


            if (urlData.metaProperties["og:updated_time"] != undefined) {
                pageUpdated = moment(urlData.metaProperties["og:updated_time"]["value"]).format('DD MMM YYYY');
            }

            if (urlData.metaProperties["og:image"] != undefined) {
                pageImage = urlData.metaProperties["og:image"]["value"];
            }


            if (pageImage == '') {
                if (urlData.metaTags["twitter:image:src"] != undefined) {
                    pageImage = urlData.metaTags["twitter:image:src"]["value"]
                } else {
                    pageImage = 'https://' + window.location.hostname + '/assets/images/website-icon.png';
                }

            }


            if (urlData.metaProperties["description"] !== undefined) {
                pageDescription = urlData.metaProperties["description"]["value"];
            }
            if (pageDescription === "") {
                if (urlData.metaTags["twitter:description"] !== undefined) {
                    pageDescription = urlData.metaTags["twitter:description"]["value"]
                }

            }
        }
        if (urlData.title !== undefined) {
            pageTitle = urlData.title;
        }
    }
    //metainfo=(siteName,pageUrl,pageTitle,pageUpdated,pageDescription,pageImage,metaid)
    metainfo.push({
        "siteName": siteName,
        "pageUrl": pageUrl,
        "pageTitle": pageTitle,
        "pageUpdated": pageUpdated,
        "pageDescription": pageDescription,
        "pageImage": pageImage,
        "metaid": metaid
    });
    return metainfo

}

export function formatBytes(bytes, decimals) {
    // ////console.log(bytes)
    if (bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getFileformat(filename) {
    if (filename) {
        return filename.split('.').pop();
    }

}

export function getFilePath(filename) {
    // ////console.log(filename);
    if (filename.match(/.(jpg|jpeg|png|gif)$/i)) {
        return 'image'
    }
    if (filename.match(/.(m4v|avi|mpg|mp4)$/i)) {
        return 'video'
    }
    if (filename.match(/.(ogg|wav|wma|mp3)$/i)) {
        return 'audio'
    } else {
        return 'document'
    }

}

export function isImage(filename) {
    ////console.log(filename);
    if (filename) {
        if (filename.match(/.(jpg|jpeg|png|gif)$/i)) {
            return true
        } else {
            return false
        }
    }

}


export function isDocument(filename) {
    //////console.log(filename);
    if (filename) {
        return !!filename.match(/.(pdf|csv|xslx|docx|doc|ppt|pptx|xls|xlsx)$/i);
    }

}


export function previewModalFormat(filename) {
    ////console.log(filename)
    if (filename) {
        if (filename.match(/.(jpg|jpeg|png|gif|m4v|flv|avi|mpg|mp4|3gp|ogg|wav|wma|mp3)$/i)) {
            return true
        } else {
            return false
        }
    }

}

export function fileviewrSupportedFormat(filename) {
    if (filename) {
        if (filename.match(/.(pdf|csv|xslx|docx|doc|mp4|mp3|webm)$/i)) {
            return true
        } else {
            return false
        }
    }

}

export function isVideo(filename) {

    if (filename && filename.match(/.(m4v|avi|mpg|mp4|mkv)$/i)) {
        return true
    } else {
        return false
    }
}

export function isAudio(filename) {

    if (filename && filename.match(/.(ogg|wav|wma|mp3)$/i)) {
        return true
    } else {
        return false
    }
}

export function urlExists(url) {

    var http = new XMLHttpRequest();

    http.open('HEAD', url, false);
    http.send();

    return http.status != 404;

}
export function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}
export function timeConvertToDays(totalminutes) {
    var exactdays = totalminutes / (60 * 24);
    var days = parseInt(exactdays);

    //console.log(days);

    var remaininghours = totalminutes % (60 * 24);
    //console.log("remaininghours " + remaininghours);
    var exacthours = remaininghours / 60;
    var hours = parseInt(exacthours);

    var exactminutes = remaininghours % 60;
    var minutes = parseInt(exactminutes);


    var totaltime = '';
    if (days > 0) {
        totaltime = days + " Day" + (days > 1 ? "s " : ' ');
    }
    if (hours > 0) {
        totaltime += hours + " Hr" + (hours > 1 ? "s " : ' ');
    }
    if (minutes > 0) {
        totaltime += minutes + " Min" + (minutes > 1 ? "s " : ' ');
    }

    return totaltime;
}

export function checkUrl(test_url) {
    let url = test_url;
    let what;
    if (url.indexOf('youtube.com') !== -1) {
        what='Youtube';
    }else if (url.indexOf('vimeo.com') !== -1) {
        what='Vimeo';
    }
    else if( url.search(/.mp4/i) > -1 ){
        what = 'Video';
    }
    else{
        what='None';
    }
    return what;
}

export function getUrl(url) {
    var videoID = url.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=))([\w\-]{10,12})\b/)[1];

    return "https://www.youtube.com/embed/"+videoID;
}


export function timeConvert(time) {
    //console.log(time)
    if(time) {
        var num = time;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        if (rminutes >= '10') {
            return rhours + ":" + rminutes;
        } else {
            return rhours + ":" + '0' + rminutes;
        }
    }
    else{
        return '';
    }

}
export function DateFormatter(date,timeEnable,dateEnable){
    if(timeEnable && dateEnable){
        return   moment.utc(date).local().format('MMMM DD, YYYY hh:mm a')

    }
    else if(timeEnable){
        return   moment.utc(date).local().format('hh:mm a')

    }
    else{
        return   moment.utc(date).local().format('MMMM DD, YYYY')

    }
}


export function UrlBuilder(message) {
    var link_array = [];
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    message.replace(urlRegex, function (url) {
        link_array.push(url);
    });

    var msg = ''
    for (var i = 0; i < link_array.length; i++) {
        msg += '<a href=' + link_array[i] + ' target="_blank">' + link_array[i] + '</a></br>'

    }
    return msg;

}

export function urlify(message) {

    var urlRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    return message.replace(urlRegex, function (message) {
        if (!/^(?:f|ht)tps?\:\/\//.test(message)) {
            var formattedUrl = "http://" + message;
            return '<a target="_blank" href="' + formattedUrl + '">' + message + '</a>&nbsp;&nbsp;&nbsp;';
        } else {
            return '<a target="_blank" href="' + message + '">' + message + '</a>&nbsp;&nbsp;&nbsp;';
        }

    })

}

export function getUrlSegment(segment) {
    var url = window.location.pathname.split('/');
    return url[segment];
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function checkAvailability(arr, val) {
    alert(val);
    var tagMap = {};
    var i = null;
    for (i = 0; arr.length > i; i += 1) {
        tagMap[arr[i].tag_name] = arr[i];
    }
    return hasTag(tagMap, val);
}

function hasTag(tagMap, tagName) {
    return tagMap[tagName];
};

export function Datetimecomparison(usr_date, curr_date) {

    var user_date = new Date(usr_date);
    var curr_date = new Date(curr_date);

    var start_date = moment(user_date, 'YYYY-MM-DD HH:mm:ss');
    var end_date = moment(curr_date, 'YYYY-MM-DD HH:mm:ss');
    var duration = moment.duration(end_date.diff(start_date));
    var years = duration.asYears();
    var resultyears = parseInt(years);
    var months = duration.asMonths();
    var resultmonths = parseInt(months);
    var weeks = duration.asWeeks();
    var resultweeks = parseInt(weeks)
    var days = duration.asDays();
    let resultdays = parseInt(days);
    var hours = duration.asHours();
    var resulthours = parseInt(hours);
    var minutes = hours / 60;
    var resultminutes = parseInt(minutes);
    var seconds = minutes / 60;
    var resultseconds = parseInt(seconds);


    if (resultyears >= 1) {

        if (resultyears > 1) {

            return resultyears + ' ' + ' years ago';

        } else {
            return resultyears + ' ' + ' year ago';
        }

    } else {

        if (resultmonths >= 1) {

            if (resultmonths > 1) {

                return resultmonths + ' ' + ' months ago';

            } else {

                return resultmonths + ' ' + ' month ago';
            }

        } else {

            // if(resultweeks >= 1){
            //
            //     if(resultmonths > 1 ){
            //
            //         return resultweeks + ' ' + ' weeks ago';
            //
            //     } else{
            //
            //         return resultweeks + ' ' + ' week ago';
            //     }
            //
            // } else{

            if (resultdays >= 1) {

                if (resultdays > 1) {

                    return resultdays + ' ' + ' days ago';

                } else {

                    return resultdays + ' ' + ' day ago';
                }

            } else {

                if (resulthours >= 1) {

                    if (resulthours > 1) {

                        return resulthours + ' ' + ' hours ago';

                    } else {

                        return resulthours + ' ' + ' hour ago';
                    }

                } else {
                    if (resultminutes >= 1) {

                        if (resultminutes > 1) {

                            return resultminutes + ' ' + ' minutes ago';

                        } else {

                            return resultminutes + ' ' + ' minute ago';
                        }
                    } else {

                        if (resultseconds >= 1) {

                            if (resultminutes > 1) {

                                return resultseconds + ' ' + ' seconds ago';

                            } else {

                                return "just now";
                            }

                        }

                    }
                }

            }

            // }

        }

    }
}

export function getAttachmentPath(type) {
    let filePath = FRMSIMG_PATH;
    switch (type) {
        case 'image' :
        case 'jpg' :
        case 'jpeg' :
        case 'png' :
        case 'bmp' :
        case 'gif' :
            filePath = FRMSIMG_PATH;
            break;
        case 'video' :
        case 'mp4' :
            filePath = FRMSVID_PATH;
            break;
        default :
            filePath = FRMSDOC_PATH;
            break;
    }

    return filePath;
}

export function showLocalTime(theDate) {
    //var theDate = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    var localTime = moment.utc(theDate).toDate();
    localTime = moment(localTime).format('DD MMM YYYY   |   hh:mm a');
    return localTime
}

export function getAssignLabel(urlsegment) {
    if (urlsegment === 'todo') {
        return 'Assigned By'
    } else if (urlsegment === 'to-follow-up') {
        return 'Assigned To'
    } else if (urlsegment === 'to-verify') {
        return 'Assigned To'
    } else if (urlsegment === 'to-comment') {
        return 'Assigned By'
    } else if (urlsegment === 'to-know') {
        return 'Assigned By'
    } else if (urlsegment === 'to-oversee') {
        return 'By'
    }
}

export function ruleCheck(metavalue,rulevalue,trigger){
    //console.log(metavalue+''+rulevalue)
    // if(trigger === "equal"){
    // 	if(metavalue.toString() === rulevalue.toString()){
    // 		return true;
    // 	}else{
    // 		return false;
    // 	}
    // }
    // if(trigger === "contain"){
    // 	if(metavalue.includes(rulevalue)){
    // 		return true;
    // 	}else{
    // 		return false;
    // 	}
    // }
    var time1 = moment(metavalue).format('YYYY-MM-DD');
    var time2 = moment(rulevalue).format('YYYY-MM-DD');
    switch (trigger) {

        case 'equal':
            if(metavalue.toString() === rulevalue.toString()){
                return true;
            }
            break;

        case 'greater':
            if(metavalue.toString() > rulevalue.toString()){
                return true;
            }
            break;

        case 'lessequal':
            if(metavalue.toString() <= rulevalue.toString()){
                return true;
            }
            break;

        case 'greatequal':
            if(metavalue.toString() >= rulevalue.toString()){
                return true;
            }
            break;

        case 'less':

            if(metavalue.toString() < rulevalue.toString()){
                return true;
            }

            break;

        case 'contain':

            if(metavalue.includes(rulevalue)){
                return true;
            }

            break;

        case 'before':
            if(moment(metavalue).isBefore(rulevalue)){
                return true
            }
            break;

        case 'after':
            if(moment(metavalue).isAfter(rulevalue)){
                return true
            }
            break;

        case 'on':
            if(moment(metavalue).isSame(rulevalue)){
                return true
            }
            break;

        default:
            if(metavalue.toString() === rulevalue.toString()){
                return true;
            }
            else {
                return false;
            }

    }
}

export function removeDuplicates(array, key) {
    let lookup = {};
    return array.filter(obj => !lookup[obj[key]] && (lookup[obj[key]] = true));
}
export function isValidDate(dateString = '') {
    //var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    var regEx1 = /^\d{4}-\d{2}-\d{2}$/;
    var regEx2 = /^\d{4}-\d{2}-\d{1}$/;
    var regEx3 = /^\d{4}-\d{1}-\d{1}$/;
    var regEx4 = /^\d{4}-\d{1}-\d{2}$/;
    if(String(dateString).match(regEx1)){
        return moment(dateString).format('DD-MM-YYYY')
    }
    else if(String(dateString).match(regEx2)){
        return moment(dateString).format('DD-MM-YYYY')
    }
    else if(String(dateString).match(regEx3)){
        return moment(dateString).format('DD-MM-YYYY')
    }
    else if(String(dateString).match(regEx4)){
        return moment(dateString).format('DD-MM-YYYY');
    }
    else {
        return dateString;
    }
}
export function formatSizeUnits(bytes){
    if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1)           { bytes = bytes + " bytes"; }
    else if (bytes == 1)          { bytes = bytes + " byte"; }
    else                          { bytes = "0 bytes"; }
    return bytes;
}
export function convertb64toFile(b64data, contentType) {
    return urltoFile(b64data, 'phcapture.jpg', 'image/jpeg')
        .then(function(file){
            return DirectFileUpload(file,'jpg')

        })
}
export function convertb64VideotoFile(b64data, contentType) {
    return urltoFile(b64data, 'vidcapture.mp4', 'video/mp4')
        .then(function(file){
            return DirectFileUpload(file,'mp4')

        })
}

export const containerIsInView = el => {
    const scroll = window.scrollY || window.pageYOffset
    const boundsTop = el.getBoundingClientRect().top + scroll

    const viewport = {
        top: scroll,
        bottom: scroll + window.innerHeight,
    }

    const bounds = {
        top: boundsTop,
        bottom: boundsTop + el.clientHeight,
    }

    return ( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom )
        || ( bounds.top <= viewport.bottom && bounds.top >= viewport.top );
}
export const indianDateFormat =(val,stat)=>{
    if(stat){
        if(val !=="" && val !== undefined && val !== null)
            return moment(val).format('DD-MM-YYYY')
        else
            return ""

    }
    else{
        return   moment(val).format('DD-MM-YYYY')
    }

}

export const isValidImageUrl =(uri)=>{

//     if(uri !== 1) {
//         console.log(uri +"in")
//         uri = uri.split('?')[0];
//         console.log(uri)
// //moving on now
//         var parts = uri.split('.');
//         var extension = parts[parts.length - 1];
//         var imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp', 'svg']
//         if (imageTypes.indexOf(extension) !== -1) {
//             return true;
//         }
//     }

}
export const parseQueryStr = (queryStr) => {
    let prefilledVals = [];
    queryStr = queryStr.replace('?','');
    console.log(queryStr)
    let queryObj = JSON.parse('{"' + decodeURI(queryStr.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
    console.log(queryObj)
    let presetVals = queryObj.preset;
    if(presetVals) {
        let prefillArr = presetVals.split(',');
        if(prefillArr.length > 0) {
            for( var i=0; i<prefillArr.length; i++) {
                let fieldValArr = prefillArr[i].split('_');
                if(fieldValArr.length>1){
                    let valueArr = fieldValArr[1].split('*');
                    let text = '';
                    if(valueArr.length >1){ // IF both value and recordid is present
                        text = valueArr[1];
                    }
                    prefilledVals.push({ field : fieldValArr[0], value: valueArr[0], text : text});
                }
            }
        }
    }

    return prefilledVals;
}

export const getMetaId = (queryStr) => {
    let prefilledVals = [];
    queryStr = queryStr.replace('?','');
    let queryObj = JSON.parse('{"' + decodeURI(queryStr.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
    // console.log(queryObj)
    let metId = '';
    if(queryObj.metaid) {
        metId = queryObj.metaid;
    }

    return metId;
}

export const getDataId = (queryStr) => {
    let prefilledVals = [];
    queryStr = queryStr.replace('?','');
    let queryObj = JSON.parse('{"' + decodeURI(queryStr.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
    // console.log(queryObj)
    let metId = '';
    if(queryObj.metaid) {
        metId = queryObj.metaid;
    }

    return metId;
}

export const getRedirectUrlForEmbedForms = (queryStr) => {
    let prefilledVals = [];
    queryStr = queryStr.replace('?','');
    let queryObj = JSON.parse('{"' + decodeURI(queryStr.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
    // console.log(queryObj)
    let redUrl = '';
    if(queryObj.redUrl) {
        redUrl = queryObj.redUrl;
    }

    return redUrl;
}


export const formatMsgDate = (msg_date) => {
    //console.log(msg_date);
    let dateStr = '';
    if(msg_date != '') {
        let formattedDate = moment(msg_date).calendar();
        let strArr = formattedDate.split(' ');
        dateStr = strArr.length >0 ? strArr[0] : '';
        if(dateStr !=='Today' && dateStr !== 'Yesterday'){
            dateStr = moment(msg_date).format('L');
        }
    }

    return dateStr;
}

export const showCallTime = (call_date) => {
    //2021-05-28T18:29:00.000Z"



    let dateStr = '';
    if(call_date != '') {
        let extractedTime = moment(call_date).format('LT');   // 6:56 PM
        if(extractedTime !== '11:59 PM') {
            dateStr = moment(call_date).utc(call_date).local().format('LT');
        }
    }

    return dateStr;
}

export function getEvents(data, param) {
    let userInfo = [];
    data.map((d, indx) => {
        userInfo[indx] = {id: indx, masterid: d.id};
        d.collectedform.map((c, cIndx) => {
            let val = "";
            c.value.map((v, vIndx) => {
//val.push(v.value);
                val = v.value;
            });
            userInfo[indx][c.label] = val;
        });
        userInfo[indx]['IsAllDay'] = false;
        if(userInfo[indx]['category'] === 'Holiday' && param.permissions.holiday === false) {
            userInfo[indx]['isReadonly'] = true;
            userInfo[indx]['IsBlock'] = true;
        }
        if(userInfo[indx]['category'] === 'Leave' && param.permissions.leave === false) {
            userInfo[indx]['IsBlock'] = true;
            userInfo[indx]['isReadonly'] = true;
        }
        if(userInfo[indx]['category'] === 'Booking' && param.permissions.booked === false) {
//userInfo[indx]['IsBlock'] = true;
            userInfo[indx]['isReadonly'] = true;
        }
        if(userInfo[indx]['category'] === 'Booking' && userInfo[indx]['full_day'] === 'Yes') {
            userInfo[indx]['IsAllDay'] = true;
        }
    });
    return userInfo;
}

export function getSchedulerDates() {
    let now = moment();
    let first_date = setSchedulerTime(now,8,0,0)
    let second_date = setSchedulerTime(now,13,0,0)
    let first_day_text = 'This ';
    let second_day_text = 'This ';


    if(now> first_date || now> second_date) {
        let tomorrow  = moment().add(1,'days');
        if(now> first_date) {
            first_day_text = 'Tomorrow';
            first_date = setSchedulerTime(tomorrow,8,0,0)
        }
        if(now> second_date) {
            second_day_text = 'Tomorrow';
            second_date = setSchedulerTime(tomorrow,13,0,0)
        }

    }


    return [first_date, second_date, first_day_text, second_day_text];
}

function setSchedulerTime(scheduled_date, hour, min, sec) {
    let scheduled_datetime = moment(scheduled_date).set({'hour': hour, 'minute': min, 'second' : sec});
    return scheduled_datetime;
}

export function parseQuillEditorContent(content) {
    console.log(content)
    content = content.replace(/<p><br\s*\/?><\/p>/gi,'<p> </p>');
    return content;
}

/**
 * Return an
 * @param {DOMElement} el A dom element of a textarea or input text.
 * @return {Object} reference Object with 2 properties (start and end) with the identifier of the location of the cursor and selected text.
 **/
export function getInputSelection(el) {
    var start = 0, end = 0, normalizedValue, range,
        textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
        start = el.selectionStart;
        end = el.selectionEnd;
    } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
            len = el.value.length;
            normalizedValue = el.value.replace(/\r\n/g, "\n");

            // Create a working TextRange that lives only in the input
            textInputRange = el.createTextRange();
            textInputRange.moveToBookmark(range.getBookmark());

            // Check if the start and end of the selection are at the very end
            // of the input, since moveStart/moveEnd doesn't return what we want
            // in those cases
            endRange = el.createTextRange();
            endRange.collapse(false);

            if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                start = end = len;
            } else {
                start = -textInputRange.moveStart("character", -len);
                start += normalizedValue.slice(0, start).split("\n").length - 1;

                if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                    end = len;
                } else {
                    end = -textInputRange.moveEnd("character", -len);
                    end += normalizedValue.slice(0, end).split("\n").length - 1;
                }
            }
        }
    }

    return {
        start: start,
        end: end
    };
}

export function parseDataDetail(formdata) {
    let record = {};
    for(var j=0; j< formdata.length; j++) {
        let value = '';
        if(formdata[j].values && formdata[j].values.length > 0) {
            for(var k=0; k< formdata[j].values.length; k++) {
                if(formdata[j].external_linked === 'yes') {
                    value += formdata[j].values[k].recordid;
                }
                else {
                    value += formdata[j].values[k].value;
                }
                if(k < formdata[j].values.length-1) {
                    value += ',';
                }
            }

        }
        record[formdata[j].masterid] = value;

    }
    return record;
}
export function parseDataList(dataset) {
    let resultset = [];
    if (dataset) {
        for (var i = 0; i < dataset.length; i++) {
            let formdata = dataset[i].collectedform;
            let record = {};
            for (var j = 0; j < formdata.length; j++) {
                let value = (formdata[j].value && formdata[j].value.length > 0) ? formdata[j].value[0].value : '';
                if (formdata[j].type === 'attachment') {
                    value = (formdata[j].value && formdata[j].value.length > 0) ? formdata[j].value[0].uniqvalue : '';
                }
                record[formdata[j].masterid] = value;
            }
            resultset.push(record);
        }
    }
    return resultset;
}
export function removeBackSlashes(str) {
    str = str.replace(/\\/g, '');

    return str;

}

/*..............can be used to validate any email id.............*/
export function validateEmail(email)
{
    var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;
    if (pattern.test(email)) {
        return true;
    } else {
        return false;
    }
}
/*..............Exclusively for Mention plugin(To send mention data to Api).............*/
export function extractString(text, initChar, finalChar) {
    let i = 0;
    let data = [];
    do {
        if (text[i] == initChar) {
            for (let j = i + 1; j < text.length; j++) {
                if (text[j] == finalChar) {
                    data[data.length] = text.slice(i + 1, j);
                    i = j + 1;
                    break;
                }
            }
        }
    }
    while (++i < text.length);
    text=replaceString(text,data);
    return text;
}
function replaceString(text,data) {
    for (var k =0; k < data.length; k++) {
        text = text.replace(data[k], data[k].split('||')[0].trim());
    }
    return text;
}

/*..............Exclusively for Mention plugin(When retrieving data from Api).............*/
export function includeString(labelArray, text, initChar, finalChar) {
    // console.log(text)
    if(text !== null && text !== undefined) {
        let i = 0;
        let data = [];
        do {
            if (text[i] == initChar) {
                for (let j = i + 1; j < text.length; j++) {
                    if (text[j] == finalChar) {
                        data[data.length] = text.slice(i + 1, j);
                        i = j + 1;
                        break;
                    }
                }
            }
        }
        while (++i < text.length);
        text = includeReplacedString(labelArray, text, data);
        return text;
    }
    else{
        return ""
    }
}
function includeReplacedString(labelArray,text,data) {
    for (var k =0; k < data.length; k++) {
        text = text.replace("{"+data[k]+"}", "{"+data[k]+"||"+labelArray[data[k]]+"}");
    }
    return text;
}

/*..............Compare any 2 arrays and get a 3rd filtered array.............*/
export function compareArrays(array1, array2) {
    var missings = [];
    var matches = false;
    if(array1.length !== 0 && array2.length !== 0){
        for ( var i = 0; i < array1.length; i++ ) {
            matches = false;
            for ( var e = 0; e < array2.length; e++ ) {
                if ( array1[i] === array2[e] ) matches = true;
            }
            if(!matches) missings.push( array1[i] );
        }
    }
    return missings;
}
export function   findMaxLength (arr){
    let maxVal = 0;
    for(var u=0; u< arr.length; u++) {
        if(arr[u].values.length > maxVal) {
            maxVal = arr[u].values.length;
        }
    }

    return maxVal;
}

export function   processFieldsetValues (data){
    let fldArr = [];
    if(data.values !== null && Array.isArray(data.values) ){
        let maxlength = findMaxLength(data.values);
        let t=0;
        for(var u= 0; u < maxlength; u++) {
            fldArr[t] = [];
            for(var k=0; k< data.values.length; k++) {
                //console.log(data.values[k])

                if(data.values[k].values.length > 0 && data.values[k].values[u] && data.values[k].values[u].value) {
                    //fldArr[u][[data.values[k].label]] = data.values[k].values[u].value;
                    let fldEle = {
                        "masterid":data.values[k].masterid,
                        "type" : data.values[k].type,
                        "label" : data.values[k].fieldname ? data.values[k].fieldname : data.values[k].label,
                        "value" : data.values[k].values[u].value,
                        "uniqvalue" : data.values[k].values[u].uniqvalue ? data.values[k].values[u].uniqvalue:''
                    }
                    fldArr[t].push(fldEle)
                }
            }
            t++;
        }
    }

    //console.log(fldArr);
    return fldArr;
}

export function   processFieldsetValuesForDatabinding (data){
    let fldArr = [];
    console.log(data)
    if(data.values !== null && Array.isArray(data.values) ){
        let maxlength = findMaxLength(data.values);
        let t=0;
        for(var u= 0; u < maxlength; u++) {
            fldArr[t] = [];
            for(var k=0; k< data.values.length; k++) {

                if(data.values[k].values.length > 0 && data.values[k].values[u]) {
                    //fldArr[u][[data.values[k].label]] = data.values[k].values[u].value;
                    let fldEle = {
                        "masterid":data.values[k].masterid,
                        "type" : data.values[k].type,
                        "label" : data.values[k].fieldname ? data.values[k].fieldname : data.values[k].label,
                        "value" : data.values[k].values[u].value,
                        "uniqvalue" : data.values[k].values[u].uniqvalue ? data.values[k].values[u].uniqvalue:''
                    }
                    fldArr[t].push(fldEle)
                }
            }
            t++;
        }
    }

    //console.log(fldArr);
    return fldArr;
}
export function isJsonObjectEmpty(obj) {
    if(!obj ||(obj
        && Object.keys(obj).length === 0
        && Object.getPrototypeOf(obj) === Object.prototype)){
        return true;
    }
    else {
        return false;
    }
}
export function base64encode(str) {
    var Base64 = {
        _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", encode: function (e) {
            var t = "";
            var n, r, i, s, o, u, a;
            var f = 0;
            e = Base64._utf8_encode(e);
            while (f < e.length) {
                n = e.charCodeAt(f++);
                r = e.charCodeAt(f++);
                i = e.charCodeAt(f++);
                s = n >> 2;
                o = (n & 3) << 4 | r >> 4;
                u = (r & 15) << 2 | i >> 6;
                a = i & 63;
                if (isNaN(r)) {
                    u = a = 64
                } else if (isNaN(i)) {
                    a = 64
                }
                t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
            }
            return t
        }, decode: function (e) {
            var t = "";
            var n, r, i;
            var s, o, u, a;
            var f = 0;
            e = e.replace(/[^A-Za-z0-9+/=]/g, "");
            while (f < e.length) {
                s = this._keyStr.indexOf(e.charAt(f++));
                o = this._keyStr.indexOf(e.charAt(f++));
                u = this._keyStr.indexOf(e.charAt(f++));
                a = this._keyStr.indexOf(e.charAt(f++));
                n = s << 2 | o >> 4;
                r = (o & 15) << 4 | u >> 2;
                i = (u & 3) << 6 | a;
                t = t + String.fromCharCode(n);
                if (u != 64) {
                    t = t + String.fromCharCode(r)
                }
                if (a != 64) {
                    t = t + String.fromCharCode(i)
                }
            }
            t = Base64._utf8_decode(t);
            return t
        }, _utf8_encode: function (e) {
            e = e.replace(/rn/g, "n");
            var t = "";
            for (var n = 0; n < e.length; n++) {
                var r = e.charCodeAt(n);
                if (r < 128) {
                    t += String.fromCharCode(r)
                } else if (r > 127 && r < 2048) {
                    t += String.fromCharCode(r >> 6 | 192);
                    t += String.fromCharCode(r & 63 | 128)
                } else {
                    t += String.fromCharCode(r >> 12 | 224);
                    t += String.fromCharCode(r >> 6 & 63 | 128);
                    t += String.fromCharCode(r & 63 | 128)
                }
            }
            return t
        }, _utf8_decode: function (e) {
            var t = "";
            var n = 0;
            var r = 0;
            var c1 = 0;
            var c2 = 0;
            var c3 = 0;
            while (n < e.length) {
                r = e.charCodeAt(n);
                if (r < 128) {
                    t += String.fromCharCode(r);
                    n++
                } else if (r > 191 && r < 224) {
                    c2 = e.charCodeAt(n + 1);
                    t += String.fromCharCode((r & 31) << 6 | c2 & 63);
                    n += 2
                } else {
                    c2 = e.charCodeAt(n + 1);
                    c3 = e.charCodeAt(n + 2);
                    t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
                    n += 3
                }
            }
            return t
        }
    }

    return Base64.encode(str)
}
export function getFormComponentList(formJson) {
    let componentList = [];
    if (formJson && formJson.pages && formJson.pages.length > 0) {

        let pageComponents = formJson.pages.map((page, pageIndex) => {
            return page.components;
        });
        componentList = [].concat.apply([], pageComponents);

        console.log(componentList)
    }
    return componentList;
}
export function getFieldOptions(components, masterid) {
    let componentArr = components.filter(item => item.masterid === masterid);
    let options = [];
    if (componentArr.length > 0) {
        let optionsArr = componentArr[0].option;
        if (optionsArr.length > 0 && optionsArr[0].hasOwnProperty('text')) {
            options = optionsArr;
        } else {
            options = optionsArr.map(opt => {
                let optObj = {"text": opt};
                return optObj;
            })
        }
    }
    console.log(options)
    return options;
}

export function buildFieldJsonFromForm(componentList, compIds, compValues) {
    let componentArr = componentList.filter(item => compIds.includes(item.masterid));
    let fieldJson = [];
    if (componentArr.length > 0) {
        for (var i = 0; i < componentArr.length; i++) {

            let fieldValue = compValues[componentArr[i].masterid] ? compValues[componentArr[i].masterid] : ''

            let fieldObj = createFieldJsonObj(componentArr[i], fieldValue)

            fieldJson.push(fieldObj);
        }
    }
    return fieldJson;
}

function createFieldJsonObj(component, fieldValue) {
    let valueArray = [];
    if (fieldValue !== '') {
        if ((component.type === 'fieldset') || (component.multipleValues && component.multipleValues === 'Yes') || (component.type === 'attachment') || (component.type === 'photocapture') || (component.type === 'videocapture') || (component.type === 'dataload') || (component.type === 'autofill')) {
            valueArray = fieldValue;
        } else if (component.externallink && component.externallink === 'Yes') {
            valueArray = fieldValue;
        } else {
            valueArray.push({value: fieldValue, refIndexNo: 0});
        }
    }
    let formEle = {
        type: component.type,
        masterid: component.referenceId ? component.referenceId : component.masterid,
        label: component.label,
        value: valueArray,
        external_linked: (component.externallink && component.externallink === "Yes") ? "yes" : "no"
    };
    if (component.type === 'uniqueid' && component.text_random) {
        formEle.text_random = component.text_random;
    }
    if ((component.type === 'checkbox' || component.type === 'switch') && component.optionstatus) {
        formEle.optionstatus = component.optionstatus ? component.optionstatus : '';
    }
    if (component.type === 'datepicker' && component.autocapture && component.autocapture === 'Yes') {
        formEle.datechange = component.datechange;
    }
    return formEle;
}

export function buildTimeFromSlots(slot) {

    let slotTimeString = "";
    let slotStr = slot.split("-");
    if (slotStr.length > 1) {
        slotTimeString = slotStr[0] + ":00 to " + slotStr[1] + ":00";
    }

    return slotTimeString;
}

export function getUserActionPermission() {


    var actionPermission = 'no';

    let ReduxStates = configureStore().getState()//;.props.listUsersPermission ? this.props.listUsersPermission.UsersPermission : [];
    if (ReduxStates) {
        let permissionData = ReduxStates.UsersReducer.listUsersPermissiondata;
        console.log(ReduxStates.UsersReducer)
        console.log(permissionData)
        let listUsersPermission = permissionData.listUsersPermission;
        console.log(listUsersPermission)
        if (listUsersPermission.length > 0) {
            let perms = listUsersPermission.filter(per => per.module === 'Entity');
            if (perms.length > 0) {
                let actions = perms[0].actions;

                let modActonArr = actions.filter(ac => ac.action_id === 1 && ac.action_permission === 'yes'); // Add case
                console.log(modActonArr);
                if (modActonArr.length > 0) {
                    actionPermission = 'yes';
                }
            }
        }
    }
    return actionPermission;
}
export function getCurrentAge(dateString) {
    var userinput = dateString;
    var dob = new Date(userinput);
    var ageString = "";

    //check user provide input or not
    if(userinput==null || userinput==''){
        ageString = "";
    }

    //execute if the user entered a date
    else {
        //extract the year, month, and date from user date input
        var dobYear = dob.getYear();
        var dobMonth = dob.getMonth();
        var dobDate = dob.getDate();

        //get the current date from the system
        var now = new Date();
        //extract the year, month, and date from current date
        var currentYear = now.getYear();
        var currentMonth = now.getMonth();
        var currentDate = now.getDate();

        //declare variables to collect the age in year, month, and days
        var age = {};
        var yearAge = "";
        var monthAge = "";
        var dateAge = "";

        //get years
        yearAge = currentYear - dobYear;

        //get months
        if (currentMonth >= dobMonth)
            //get months when current month is greater
            monthAge = currentMonth - dobMonth;
        else {
            yearAge--;
            monthAge = 12 + currentMonth - dobMonth;
        }

        //get days
        if (currentDate >= dobDate)
            //get days when the current date is greater
            dateAge = currentDate - dobDate;
        else {
            monthAge--;
            dateAge = 31 + currentDate - dobDate;

            if (monthAge < 0) {
                monthAge = 11;
                yearAge--;
            }
        }

        //group the age in a single variable
        age = {
            years: yearAge,
            months: monthAge,
            days: dateAge
        };


        if ( (age.years > 0) && (age.months > 0) && (age.days > 0))
            ageString = age.years + " years " + age.months + " months " + age.days + " days";
        else if ( (age.years == 0) && (age.months == 0) && (age.days > 0))
            ageString = age.days + " days";
        //when current month and date is same as birth date and month
        else if ( (age.years > 0) && (age.months == 0) && (age.days == 0))
            ageString = age.years +  " years";
        else if ( (age.years > 0) && (age.months > 0) && (age.days == 0))
            ageString = age.years + " years " + age.months + " months";
        else if ( (age.years == 0) && (age.months > 0) && (age.days > 0))
            ageString = age.months + " months " + age.days + " days";
        else if ( (age.years > 0) && (age.months == 0) && (age.days > 0))
            ageString = age.years + " years " + age.days + " days";
        else if ( (age.years == 0) && (age.months > 0) && (age.days == 0))
            ageString = age.months + " months";
        //when current date is same as dob(date of birth)
        else ageString = "";

        //return the calculated age
        return ageString;

    }
}
export function truncateDecimals(number, digits) {
    var multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

    return truncatedNum / multiplier;
};