import {forms} from '../services/forms.services'
import {collections} from "../services/collections.services";
import {entity} from "../services/entity.services";

export const listallforms = (group,search,uid,usertype,managecollection,limit,offset) => dispatch => {
    let listallforms = forms.listForms(group,search,uid,usertype,managecollection,limit,offset);
    listallforms.then(listallforms => {
        dispatch({
            type: 'LIST_All_FORMS',
            listallforms: listallforms,
            group:group,
            search:search

        })
    })



};

 export const GetForms = (uid, usertype,type) => dispatch => {
    let getFormsdata = forms.GetForms(uid, usertype,type);

        getFormsdata.then(getFormsdata => {
        dispatch({
            type: 'LIST_ALL_FORMS_CATEGORY',
            getForms: getFormsdata,

        })
    })



};
export const getCollectionInfo = (params) => dispatch => {

    let getCollectionInfo = forms.GetCollectionInfo(params);
    dispatch({
        type: 'GET_COLLECTION_INFO_LOADER',
        loading:true

    });
    getCollectionInfo.then(getCollectionInfo => {
        dispatch({
            type: 'GET_COLLECTION_INFO',
            collectionInfo: getCollectionInfo

        })
        dispatch({
            type: 'GET_COLLECTION_INFO_LOADER',
            loading:false

        });
    })
};
export const updateFormData = (formState) => dispatch => {
    //////console.log(formState)
        dispatch({
            type: 'UPDATE_FORM_DATA',
            cform: formState,

        })
};
export const updateLabelChange = (param) => dispatch => {
    dispatch({
        type: 'UPDATE_LABEL_CHANGE',
        param: param,

    })
};

export const optionChange = (param) => dispatch => {
    dispatch({
        type: 'UPDATE_OPTION_CHANGE',
        param: param,

    })
};






export const updateComponent = (txt,activePage) => dispatch => {
    dispatch({
        type: 'UPDATE_FORM_COMPONENT',
        txt: txt,
        activePage:activePage
    })



};

export const cForm = (param) => dispatch => {
    let getFormsdata = forms.cForm(param);

    getFormsdata.then(getFormsdata => {
        dispatch({
            type: 'LIST_FORM_BUILDER_DATA',
            getForms: getFormsdata,

        })
    })



};

export const saveCreateJson = (param) => dispatch => {
    let saveCreateJson = forms.saveCreateJson(param);

    saveCreateJson.then(saveCreateJson => {
        dispatch({
            type: 'SAVE_CREATE_FORM_JSON',
            getForms: saveCreateJson,

        })
    })



};



export const getFormData = (data) => dispatch => {
    let formData = collections.getFormData(data);

    dispatch({
        type: 'GET_FORM_LIST_DATA_LOADER',
        loading:true,

    });
    formData.then(formData => {
        dispatch({
            type: 'GET_FORM_LIST_DATA',
            formvalues: formData.data.results.data,
            meta:formData.data.results.meta,
            id: formData.data.results.id
        })
        dispatch({
            type: 'GET_FORM_LIST_DATA_LOADER',
            loading:false,

        });
    })
};


export const showform = (data) => dispatch => {
    let showformdata = entity.showForm(data);
    showformdata.then(showformdata => {
        if(data.source) {
            dispatch({
                type: 'GET_FORM_METAFORM',
                getformdata: showformdata.data.data,

            })
        }
        else {
            dispatch({
                type: 'SHOW_FORM_META_FORM',
                showformdata: showformdata.data.data,
                template : showformdata.data.template,
                templateInfo : showformdata.data.templateinfo,
                width : showformdata.data.template_width,
                status : showformdata.data.success,
                thankyoustatus : showformdata.data.thankyoustatus,
                thankyoulink : showformdata.data.thankyoulink,
                thankyoucontent : showformdata.data.thankyoucontent,
                header : showformdata.data.header,
                footer : showformdata.data.footer,
            })
        }

    })
};


export const resetForm = () => dispatch => {
    dispatch({
        type: 'RESET_FORM_LIST_DATA'
    });

};