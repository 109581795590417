import React, {Component} from 'react';
import {
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar,
    CartesianGrid,
    Tooltip,
    Area,
    Legend,
    ResponsiveContainer
} from 'recharts';

const data = [
    {"Construct": "Customer", "Company A": "8.6", "Company B": "7.5", "Company C": "6"},
    {"Construct": "Leadership", "Company A": "9.6", "Company B": "10.1", "Company C": "5.4"},
    {"Construct": "Finance", "Company A": "12.4", "Company B": "8.5", "Company C": "6.3"},
    {"Construct": "Process", "Company A": "19.2", "Company B": "5.8", "Company C": "8.3"},
]

function RadarChartCard(props) {
    let xData = props.containerData.tabledata.slice(0, 1);
    let radarData = props.containerData.tabledata.slice(1, props.containerData.tabledata.length);
    let graphHeight = props.containerData.graphHeight ? parseInt(props.containerData.graphHeight) : 300;

    let yFields = props.containerData.yFields;
    let colors = props.containerData.Color;

    let graphData = props.containerData.data;

    return (
        <React.Fragment>
            <ResponsiveContainer width={props.containerData.legend ? "95%" : "100%"} height={graphHeight}>
                <RadarChart outerRadius={(graphHeight - 100) / 2} width={730} data={graphData}>
                    <PolarGrid/>
                    {xData.map((fld, index) =>
                        <PolarAngleAxis dataKey={fld.label}/>
                    )}

                    <PolarRadiusAxis angle={30} domain={[0, 'dataMax']} tickCount={4}/>

                    {radarData.map((list, index) =>
                        <Radar name={list.label} dataKey={list.label} stroke={colors[index] && colors[index].color}
                               fill={'#fff'} fillOpacity={0.6}/>
                    )}
                    {props.containerData.legend &&
                    <Legend layout='vertical' verticalAlign={`middle`} align={`right`}/>
                    }
                    <Tooltip/>
                </RadarChart>

                {/*<RadarChart outerRadius={(graphHeight-100)/2} width={730} data={data}>
                    <PolarGrid />
                        <PolarAngleAxis dataKey="Construct" />

                    <PolarRadiusAxis angle={30} domain={[0, 10]} />
                        <Radar name="Company A" dataKey="Company A"  stroke={'#bd10e0'} fill={'#fff'} fillOpacity={0.6} />
                        <Radar name="Company B" dataKey="Company B"  stroke={'#f8e71c'} fill={'#fff'} fillOpacity={0.6} />
                        <Radar name="Company C" dataKey="Company C"  stroke={'#d0021b'} fill={'#fff'} fillOpacity={0.6} />

                    {props.containerData.legend &&
                    <Legend layout='vertical' verticalAlign={`middle`} align={`right`}/>
                    }
                    <Tooltip />
                </RadarChart>*/}
            </ResponsiveContainer>
        </React.Fragment>
    );
}

export default RadarChartCard;