import React, {Component, useState, useEffect} from 'react';
import {FRMSIMG_PATH} from "../../../../constants";
import NoData from "../../../views/NoData";
import {getProfile} from "../../../../utilities/AuthService";
import * as CustomFunctions from "../../../../utilities/CustomFunctions";
import {getCurrentAge, getUrlSegment, indianDateFormat, truncateDecimals} from "../../../../utilities/CustomFunctions";
import {OverlayTrigger, Popover} from "react-bootstrap";
import AddNotes from "../../ceshareview/AddNotes";
import moment from "moment";

function ResultTable(Props) {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const container = document.querySelector('.form-table');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        var scrollY = 0;
        var scrollX = 0;
        var doc = document.getElementById("a");
        if (doc.scrollTop !== scrollY) {
            const container = document.querySelector('.form-table')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            if (contentHeight <= scrollTop) {
                Props.loadmoreCollectionData()
            }
            ;
        }
        setIsFetching(true);
    }

    function fetchMoreListItems() {
        Props.loadmoreCollectionData()
    }

    ////////console.log(Props.label.showformdata.pages)


    let showlistField = []
    if (Props.hasOwnProperty('CollectionInfo')) {
        if (Props.CollectionInfo.showlist_enabled) {
            if (Props.label.showformdata.length !== 0) {
                for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
                    (Props.label.showformdata.pages[i].components.filter(list => (Props.label.showlist_enabled.includes(list.masterid)))).map(list => {
                        showlistField.push(list)
                    })
                }
            }

        } else {
            if (Props.label.showformdata.length !== 0) {
                for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
                    (Props.label.showformdata.pages[i].components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden" && list.type !== "fieldset")).map(list => {
                        showlistField.push(list)

                    })
                }
            }
        }

    } else {
        if (Props.label.showformdata.length !== 0) {
            for (let i = 0; i < Props.label.showformdata.pages.length; i++) {
                (Props.label.showformdata.pages[i].components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden" && list.type !== "fieldset")).map(list => {
                    showlistField.push(list)

                })
            }
        }
    }


    function onNoteClick(e, id) {
        Props.onNoteClick(e, id)

    }

    function assignUsers() {
        //this.setState({})
        Props.assignToAllUsers(true)
    }

    return (
        <div
            className={`form-table coll-full-height fixed-header starred-field ${Props.fieldview.length !== 0 ? 'top-align' : ""}`}
            id="a">
            {showlistField.length === 0 &&
            <div className='absolute-holder result-table-no-data'>
                <NoData
                    msg={`No Data linked to this collection`}
                />
            </div>
            }
            <table id="a" className={`table data-table ${showlistField.length === 0 ? "hide" : ""}`}>
                <thead>
                <tr>
                    <th className="edit-record"/>

                    {showlistField.length !== 0 &&
                    <React.Fragment>
                        <th scope="col" className="select-record">
                            <div className="select-chk-block" style={{display: "block"}}>
                                <label className="control control-checkbox">
                                    <input type="checkbox" className="inp-chk"
                                           onClick={Props.handleSelectAll}/>
                                    <div className="control-indicator"/>
                                </label>
                                <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={
                                    <Popover id="popover-basic" className="more-option-card">
                                        <Popover.Content className="option-trigger">
                                            <ul>
                                                <li onClick={() => assignUsers()}
                                                    className={Props.state.shareToAll ? `active ` : ""}>
                                                    <nav>All</nav>
                                                </li>
                                            </ul>
                                        </Popover.Content>
                                    </Popover>}>
                                    <nav className="all-option"/>
                                </OverlayTrigger>
                            </div>
                        </th>
                        <th className="col-star">
                            <button className={`starred ${Props.state.starred ? "active" : ""}`}
                                    onClick={(e) => Props.filterStarClick(!Props.state.starred)}></button>
                        </th>

                        {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].notes) &&
                        <th className="col-notes">
                            <span className="note-text">Note</span>
                        </th>
                        }


                        {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].calendar) &&
                        <th className="col-calendar">
                            <span className="note-text"></span>
                        </th>
                        }


                        {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].share) &&
                        <th className="col-share">
                            <span className="share-text">SHARE</span>
                        </th>
                        }
                        {(getUrlSegment(1) === "bookings" || (Props.type && Props.type === 'booking')) &&
                        <>
                            <th className="col-setting">
                                <span className="share-text"/>
                            </th>

                            <th className="col-calendar">
                                <span className="share-text"/>
                            </th>
                        </>
                        }

                        {Props.flags.length !== 0 &&
                        <th>FLAGS</th>
                        }
                        {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].showdate) &&
                        <th>CREATED DATE</th>
                        }
                    </React.Fragment>
                    }


                    {showlistField.map((title, index) => {

                            let active = ""
                            if (Props.state.sortData.masterid === title.masterid) {
                                active = Props.state.sort === "asc" ? "sorting-ascend" : "sorting-descend"
                            }
                            return (
                                <React.Fragment key={`showlist-field` + index}>
                                    <th className={active} scope="col" onClick={() => Props.sortingData(title)}>
                                        {title.hasOwnProperty('display_label') ? title.display_label : title.label}

                                        <div className="sort-panel">
                                            <span className="ico-lib-v1"> </span>
                                        </div>

                                    </th>
                                </React.Fragment>
                            )

                        }
                    )}

                </tr>
                </thead>
                <tbody>
                {Props.collection.map((row, rowIndex) =>
                    <tr key={`result-table-content-data` + rowIndex}
                        className={(Props.state.checkedRow.indexOf(row["id"]) > -1) ? "selected-row" : ""}>

                        {(getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()) ?
                            <td className="edit-record">
                                <button className="ico-lib-v1 record-edit"
                                        onClick={(e) => Props.showFormModal(e, row['id'])}/>
                            </td>
                            :
                            row['editrecordpermission'] ?


                                <td className="edit-record">
                                    <button className="ico-lib-v1 record-edit"
                                            onClick={(e) => Props.showFormModal(e, row['id'])}/>
                                </td>

                                :

                                <td className="edit-record">
                                </td>


                        }

                        {/*{((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].edit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) ?*/}

                        {/*    <>*/}
                        {/*        {row['editPermissionusers'].length > 0 ?*/}
                        {/*            <>*/}
                        {/*                {((row['editPermissionusers'].indexOf(row['createuser'].toString()) > -1) || (row['editPermissionusers'].includes(getProfile().id))) ?*/}
                        {/*                    <td className="edit-record   1111111111111111111">*/}
                        {/*                        <button className="ico-lib-v1 record-edit"*/}
                        {/*                                onClick={(e) => Props.showFormModal(e, row['id'])}/>*/}
                        {/*                    </td> :*/}
                        {/*                    <td className="edit-record 222222"/>*/}
                        {/*                }*/}
                        {/*            </>*/}
                        {/*            :*/}
                        {/*            <td className="edit-record 3333333333">*/}
                        {/*                <button className="ico-lib-v1 record-edit"*/}
                        {/*                        onClick={(e) => Props.showFormModal(e, row['id'])}/>*/}
                        {/*            </td>*/}
                        {/*        }*/}
                        {/*    </>*/}

                        {/*    :*/}
                        {/*    <td className="edit-record"></td>*/}


                        {/*}*/}
                        <td key={`result-tr-data` + rowIndex} className="select-record">
                            <div className="select-chk-block" style={{display: "block"}}>
                                <label className="control control-checkbox">
                                    <input type="checkbox" className="inp-chk"
                                           checked={(Props.state.checkedRow.indexOf(row["id"]) > -1) ? "checked" : ""}
                                           data-val={row['id']}
                                           onClick={(e) => Props.handleSelectRow(row['id'])}/>
                                    <div className="control-indicator"/>
                                </label>
                            </div>


                        </td>

                        <td className="col-star">
                            <button className={`starred ${row['isStar'] ? "active" : ""}`}
                                    onClick={(e) => Props.onStarClick(e, row['id'], !row['isStar'])}></button>
                        </td>
                        {(getUrlSegment(1) === "bookings" || (Props.type && Props.type === 'booking')) &&
                        <>
                            <td className="col-setting">
                                <span className="ico-lib col-setting-ico"
                                      onClick={() => Props.calendarSettings(row.booking_start_time, row.booking_end_time, row['id'])}> </span>
                            </td>

                            <td className="col-calendar">
                                {/*<a className="ico-lib col-calendar-ico" target={`_blank`} href={`/bookingcalendar/?resourceid=10&interval=60&starttime=`+row.booking_start_time+`&endtime=`+row.booking_end_time+`&collectionid=`+atob(getUrlSegment(3))+`&recordid=`+row['id']}> </a>*/}
                                <span className="ico-lib col-calendar-ico"
                                      onClick={() => Props.showCalendarModal(row.booking_start_time, row.booking_end_time, row['id'])}
                                      href={`/bookingcalendar/?resourceid=10&interval=60&starttime=` + row.booking_start_time + `&endtime=` + row.booking_end_time + `&collectionid=` + atob(getUrlSegment(3)) + `&recordid=` + row['id']}> </span>
                            </td>
                        </>
                        }


                        {(Props.showlist.length !== 0 && Props.showlist[0].notes) &&
                        <td className="col-notes">
                            <OverlayTrigger trigger="click" rootClose placement="right"
                                            overlay={<Popover id="popover-basic" className="popover-card">
                                                <Popover.Content>
                                                    <AddNotes
                                                        value={Props.value}
                                                        changeNotes={Props.changeNotes}
                                                    />
                                                </Popover.Content>
                                            </Popover>}>
                                <button className={`notes ${row['has_annotation'] ? "added" : ""}`}
                                    // onMouseEnter={(e) => onNoteClick(e, row['id'])}
                                        onClick={(e) => onNoteClick(e, row['id'])}></button>
                            </OverlayTrigger>
                        </td>
                        }


                        {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].calendar) &&
                        <td className="col-calendar">
                            <span
                                className={`ico-lib ${row['1054439887'] && row['1054439887'] === "Call Back Request" && (!row['479543011'] || (row['479543011'] && row["479543011"] === '')) && 'col-calendar-ico'}`}
                                onClick={(e) => Props.showBookingCalendar(e, row)}></span>

                        </td>
                        }

                        {(Props.showlist.length !== 0 && Props.showlist[0].share) &&
                        <td className="col-share">
                            {row['users'].length !== 0 &&
                            <span className="ico-lib user-assigned-ico assigned_user_list"
                                  title="Assigned" onClick={(e) => Props.showAssignedUsers(row['users'], row['id'])}>
                                </span>
                            }
                            {row['users'].length === 0 &&
                            <span className="ico-lib user-assigned-ico assigned_user_list inactive-assign"
                                  title="Not Assigned"></span>
                            }
                        </td>
                        }


                        {Props.flags.length !== 0 &&
                        <td>
                            {/*<nav className="ico-lib flag-ico" onClick={()=>Props.flagList(rowIndex,row)}/>*/}

                            {row['flags'].length !== 0 &&
                            <div className="flag-add-block">
                                {row['flags'].map((list) =>
                                    <div className="each-added-flag" style={{backgroundColor: list.color}}/>
                                )}

                            </div>
                            }
                        </td>
                        }
                        {(Props.showlist && Props.showlist.length !== 0 && Props.showlist[0].showdate) &&
                        <td>
                            {moment(row['createdate']).format('MMM DD, YYYY HH:mm:ss')}
                        </td>
                        }
                        {showlistField.map((title, index) => {
                                let fieldValue = row[title.masterid];
                                if (title.type === "attachment") {
                                    fieldValue = row[title.masterid] !== undefined ? FRMSIMG_PATH + row[title.masterid] : '';
                                }

                                let perStat = ((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].edit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString()))
                                let rateValarray = [];
                                let rateVal = fieldValue;
                                if (title.type === "rating") {
                                    rateVal = fieldValue && fieldValue !== '' ? fieldValue : 0;
                                    let count = 5;
                                    for (var i = 0; i < count; i++) {
                                        rateValarray.push(i)
                                    }
                                }
                                let rows = [];
                                if (title.type === "resources") {
                                    rows = row[title.masterid] !== undefined && row[title.masterid] !== '' ? JSON.parse(row[title.masterid]) : [];
                                    if (rows.length > 0) {
                                        fieldValue = FRMSIMG_PATH + rows[0].thumb;
                                    } else {
                                        fieldValue = '';
                                    }
                                }
                                if (title.type === "age") {
                                    let currentDate = new Date();
                                    let birthDate = row[title.currentage];
                                    currentDate = moment(currentDate).format("yyyy-MM-DD");
                                    birthDate = birthDate !== '' ? moment(birthDate).format("yyyy-MM-DD") : '';
                                    if (birthDate !== '') {
                                        // var date1 = new Date(birthDate);
                                        // var date2 = new Date(currentDate);
                                        // var Difference_In_Days = date2.getFullYear() - date1.getFullYear();  //To calculate the year difference of two dates
                                        // Difference_In_Days = Difference_In_Days > 0 ? Difference_In_Days : ''
                                        // var fieldValueSum = Math.abs(Difference_In_Days);
                                        // fieldValue = fieldValueSum;
                                        fieldValue = getCurrentAge(birthDate);
                                    } else {
                                        fieldValue = '';
                                    }
                                }

                                return (
                                    <React.Fragment key={`edit-collection-data` + index}>

                                        <td className={`collection-detail-edit ${index === 0 ? "edited-field" : ""} ${Props.fieldview.includes(title.masterid) ? 'column-word-wrap' : ""}`}
                                            onClick={(e) => Props.handleClicks(e, row['id'], rowIndex)}
                                            // onDoubleClick={(e) => Props.showPreviewFormModal(e, row['id'], rowIndex)}
                                        >
                                            <div className={`form-table-edit`}>
                                                {(title.type === "attachment" || title.type === "signature") ?
                                                    <div className="att-file">
                                                        {fieldValue !== '' &&
                                                        <img src={fieldValue}/>
                                                        }
                                                        {/*<span className="att-text"></span>*/}
                                                    </div>
                                                    :
                                                    (title.type === "datepicker") ?
                                                        <>
                                                            <span
                                                                className="text-overflow">{title.autocapture && title.autocapture === 'Yes' && title.datetime && title.datetime === 'Yes' && fieldValue !== '' ?
                                                                moment.utc(fieldValue).local().format('DD-MM-YYYY hh:mm')
                                                                :
                                                                title.datetime && title.datetime === 'Yes' && fieldValue !== '' ?
                                                                    moment(fieldValue).format('DD-MM-YYYY hh:mm')
                                                                    :
                                                                    indianDateFormat(fieldValue, true)}</span>
                                                        </>
                                                        :
                                                        (title.type === "rating") ?
                                                            <ul className={`rate-star ${title.ratingtype === 'bulb' ? "bulb" : ""}`}>
                                                                {rateValarray.map((opt, index) =>
                                                                    <li className={`${index < rateVal ? "active" : ""}`}
                                                                        key={index}></li>
                                                                )}
                                                            </ul>
                                                            :
                                                            (title.type === "resources") ?
                                                                <div className="att-file">
                                                                    {fieldValue !== '' &&
                                                                    <img src={fieldValue} alt={`resources`}/>
                                                                    }
                                                                </div>
                                                                :
                                                                (title.type === "text" && title.password === "Yes") ?
                                                                    <span className="text-overflow">******</span>
                                                                    :
                                                                    (title.type === "computedfield") ?
                                                                        <span
                                                                            className="text-overflow">{fieldValue === "" ? "" : title.hasOwnProperty('decimalpoint') && title.decimalpoint !== 0 && title.hasOwnProperty('rounddecimal') && title.rounddecimal === 'Yes' ? parseFloat(fieldValue).toFixed(title.decimalpoint) : title.hasOwnProperty('decimalpoint') && title.decimalpoint !== 0 ? truncateDecimals(fieldValue, title.decimalpoint) : parseFloat(fieldValue).toFixed(2)}</span>
                                                                        :
                                                                        <span className="text-overflow">{fieldValue}</span>
                                                }
                                                {/*{index === 0 &&*/}
                                                {/*<div className="wf-btn-block">*/}
                                                {/*    {((Props.permissionsSelected.length > 0 && Props.permissionsSelected[0].edit) || (getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || Props.createuser.toString() === getProfile().id.toString())) &&*/}
                                                {/*    <button className="ico-lib wf-ed" data-action="editcollection"*/}
                                                {/*            onClick={(e) => Props.showFormModal(e, row['id'])}>*/}

                                                {/*    </button>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                                {/*}*/}
                                            </div>
                                        </td>

                                    </React.Fragment>
                                )
                            }
                        )}

                    </tr>
                )}

                </tbody>
            </table>
        </div>
    )

}

export {ResultTable};
