import {combineReducers} from 'redux';



const menuClick = (state = {mainMenu:'',subMenu:""}, action) => {

    switch (action.type) {

        case "MENU_UPDATE_SUCCESS":
            return {
                ...state,
                mainMenu: action.mainMenu,
                subMenu: action.subMenu,
            };
            break;
               default:
            return state;
    }
};
const onlineusers = (state = {users:[],}, action) => {

    switch (action.type) {

        case "ONLINE_USERS":
            return {
                ...state,
                users: action.onlineUsers,
            };
            break;
        default:
            return state;
    }
};



const searchparamdata = (state = {task:{priority:'',project:'',asigntype:'',status:'All',srchval:'',active:false},user:'',data:[]}, action) => {
    var _newState;
    switch (action.type) {

        case "SEARCH_TASK_DATA":
            _newState = {...state};
            _newState['task'][action.fieldType]=action.value;

            if(action.data.srchval !== '' || action.data.status !=='All' || action.data.asigntype !== ''  || action.data.user_name !== ''  || (action.data.project !== '') || (action.data.priority !== '') ) {
                _newState['task'][`active`] = true
            }
            else
            {
                _newState['task'][`active`] = false
            }
            return _newState;
            break;
        case "SEARCH_USERS_DATA":
            return {
                ...state,
                user: action.value,
            };
            break;
        case "SEARCH_PORTFOLIO_DATA":
        return {
            ...state,
            user: action.value,
        };
        break;

        case "LIST_ALL_SEARCH_DATA":
            _newState = {...state};
            _newState['data']=action.results.content;
            return _newState;
            break;

        default:
            return state;
    }
};

const formsCatagory = (state = {getForms:{data:[]}}, action) => {
    switch (action.type) {

        case "LIST_ALL_FORMS_CATEGORY":
            return {
                ...state,
                getForms: action.getForms.data,

            };
            break;
        default:
            return state;
    }
};

const mobileTogle = (state = {toggle:false,filterToggle:false}, action) => {

    switch (action.type) {

        case "MOBILE_MENU_UPDATE":
            return {
                ...state,
                toggle: action.toggle,

            };
            break;
        case "MOBILE_FILTER_UPDATE":
            return {
                ...state,
                filterToggle: action.toggle,

            };
            break;

        default:
            return state;
    }
};

const loader = (state = {loaderType:'',status:false}, action) => {

    switch (action.type) {

        case "LOADER_UPDATE_STATUS":
            return {
                ...state,
                loaderType: action.loaderType,
                status: action.status,

            };
            break;


        default:
            return state;
    }
};
const multiLoader = (state = {loader: {}}, action) => {

    switch (action.type) {

        case "MULTI_LOADER_UPDATE_STATUS":
            let multiLoader = {...state.loader};
            multiLoader[action.loaderType] = action.status;
            return {
                ...state,
                loader: multiLoader
            };
            break;

        default:
            return state;
    }
};
const updateDimensions = (state = {wheight:'',wwidth:''}, action) =>  {
    switch (action.type) {

        case "SCREEN_WIDTH_HEIGHT":
            return {
                ...state,
                wheight: action.wheight,
                wwidth: action.wwidth,

            };
            break;


        default:
            return state;
    }

};
const loadHeadline = (state = {title:''}, action) => {
    switch (action.type) {

        case "PAGE_HEADLINE":
            return {
                ...state,
                title: action.title

            };
            break;


        default:
            return state;
    }

};
const overduePopup = (state = {duedata:false}, action) => {
    switch (action.type) {

        case "OVER_DUE_POPUP":
            return {
                ...state,
                duedata: action.overduepopup
            };
            break;


        default:
            return state;
    }
};
const teamClick = (state = {subMenu:"overview"}, action) => {

    switch (action.type) {

        case "TEAM_MENU_UPDATE_SUCCESS":
            return {
                ...state,
                subMenu: action.subMenu,
            };
            break;
        default:
            return state;
    }
};
export const CommonReducer = combineReducers({
    menuClick,
    searchparamdata,
    formsCatagory,
    mobileTogle,
    loader,
    updateDimensions,
    loadHeadline,
    onlineusers,
    multiLoader,
    overduePopup,
    teamClick
});

export default CommonReducer;


