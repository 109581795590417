import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import moment from 'moment';
import LoaderSvg from "../../views/LoaderSVG";
import * as roleActions from "../../../actions/Role";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
class AddNewRole extends Component {

    constructor(props) {
        super(props);
        this.state={
            rolename:'',
            status:"",
            roledescription:'',
            roleID:0,
            loading:0,
            projectId:'',
        };
        this.RoleInputChange= this.RoleInputChange.bind(this);
        this.CreateRole=this.CreateRole.bind(this);
    }

    componentDidMount() {
        if(getUrlSegment(1) === "project")
        {
            let projectId = atob(getUrlSegment(4));
            this.setState({projectId:projectId})
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.roleData !== this.props.roleData){
            let roleDetails = this.props.roleData.roleDet;

            if(roleDetails){
                this.setState({
                    roleID:roleDetails[0].roleId,
                    rolename:roleDetails[0].role,
                    roledescription:decodeURIComponent(escape(atob(roleDetails[0].description))),
                })
            }
        }
    }

    RoleInputChange(e) {
        this.setState({[e.target.name]:e.target.value})
        if(e.target.value !== '') {
            e.target.classList.remove('val-error');
        }
    }

    CreateRole(event) {
        event.preventDefault();
        let formValid = 0;
        var rolename = this.state.rolename;
        var status = this.state.status;
        var roledescription = this.state.roledescription;
        var roleID = (this.state.roleID =='0' ? moment().unix() : this.state.roleID);
        var mode = (this.state.roleID =='0' ? 'save' : 'update');
        var projectId = this.state.projectId;
        if (this.state.rolename.trim() === '') {
            formValid++;
            document.getElementsByName('rolename')[0].classList.add('val-error');
        }

        if (this.state.roledescription.trim() === '') {
            formValid++;
            document.getElementsByName('roledescription')[0].classList.add('val-error');
        }
        if(formValid === 0){
            this.setState({loading:1})
            let roledata = {
                "_id": roleID,
                "role": rolename,
                "description": roledescription,
                "uid": getProfile().id,
                "mode": mode,
                "creator":getProfile().id
            };
            this.props.saverole(roledata);

        }
    }

    render() {
        return (
            <div className="task-content-block">
                <div className="persitant-top">
                    {(this.props.roleData.loading || this.state.loading === 1) &&
                    <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                    }
                    <div className="task-input-block">
                        <input type="text"  name="rolename" className="task-input-box--new" defaultValue={this.state.rolename}  placeholder="Name" onChange={this.RoleInputChange} />
                    </div>
                    <div className="task-input-block text-input">
                            <textarea className="task-textarea--new" name="roledescription" defaultValue={this.state.roledescription}
                                      placeholder="Description" onChange={this.RoleInputChange}></textarea>
                    </div>
                </div>
                <div className="persitant-footer">
                    <div className="group-row">
                    </div>
                    <button data-masterid="0" data-mode="save" type="button" className="general-btn" onClick={this.CreateRole}
                            id="createrole">{this.state.roleID === 0 ? 'Create' : 'Update'}
                    </button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    PortfolioInfoDet : state.SectorReducer.listallPortfolio,
    SaveroleDet : state.RoleReducer.Saverole,
    roleData : state.RoleReducer.getRoleDetails,
});
export default  withRouter(connect(mapStateToProps, {
    saverole:roleActions.SaveRole,

})(AddNewRole));



