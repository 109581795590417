import React, {Component, useState} from "react";
import moment from "moment";
import {
    Datecomparison, formatDate,
    forceDownload,
    getFileIcon, getUrlSegment
} from "../../../utilities/CustomFunctions";
import { USRIMG_PATH} from "../../../constants";

import DatePickerBox from "../../views/DatePickerBox";
import UploadFile from "../../../utilities/Upload/UploadFile";
import {getProfile} from "../../../utilities/AuthService";
import OutsideAlerter from "../../views/OutsideAlerter";

function WorkFlowTaskHeaderBlock(Props) {
    var curentDate = moment.utc().local().format('YYYY-MM-DD')
    var dueDate = moment.utc(Props.list.duedate).local().format('YYYY-MM-DD')

    let due = Props.list.duedate !== '0000-00-00 00:00:00' ? Datecomparison(dueDate, curentDate, 'task') : '';
    const [status, showOptions] = useState(false);
    return (
        <div className="task-details-header-block">
            <div className="task-detail-header-left">
                {moment(curentDate).isAfter(dueDate) &&
                <div className="due-mount">
                    <p>Overdue</p>
                    <p>{due.replace("Overdue", " ")}</p>
                </div>
                }
                <ul className="task-details-info-wrap">
                    <li>
                        <p>Due on</p>
                        <h5>{moment.utc(Props.list.duedate).local().format('DD MMM YYYY')}</h5>
                    </li>
                    <li>
                        <p>Priority</p>
                        <h5>{Props.list.priority}</h5>
                    </li>
                    {Props.list.projectname !== "" &&
                    <li>
                        <p>Project</p>
                        <h5>{Props.list.projectname}</h5>
                    </li>
                    }
                    {Props.list.workFlowname &&
                    <li>
                        <p>Workflow</p>
                        <h5>{atob(Props.list.workFlowname)}</h5>
                    </li>
                    }
                </ul>
            </div>
            <div className="task-detail-header-right">

                {Props.taskoptionbutton}

            </div>
        </div>
    )
}

function WorkflowOptionButton(Props) {
    const [status, showOptions] = useState(false);
    return (

        <div className="task-other-opt">
            <button className="ico-lib option-button" onClick={() => showOptions(!status)}></button>
            {status &&
            <OutsideAlerter
                status={status}
                Triggered={() => showOptions(!status)}
            >

                <ul className="panel-option">
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib print-ico"
                           onClick={() => window.location.href = ('/workflow/print/' + getUrlSegment(3) + '/' + getUrlSegment(4))}>Task
                            Print </a>
                    </li>
                </ul>

            </OutsideAlerter>
            }
        </div>
    )


}

function WorkFlowTaskDetailsBlock(Props) {
    return (
        <div className="task-details-body-block">

            <div className="workflow-task-body-container">
                <div className="workflow-task-footer-bottom">
                    <h5>Stage {Props.taskstages.map((stage, index) =>
                        <React.Fragment key={index}>
                            {stage._id === Props.currentstageId &&
                            index + 1
                            }
                        </React.Fragment>
                    )} : {Props.list.stagename}</h5>
                    {/*<p>{moment.utc(Props.list.duedate).local().format('DD MMM YYYY   |   hh:mm a')}</p>*/}
                </div>
                <h5>{atob(Props.list.subject)}</h5>
                <p>{atob(Props.list.Title)}</p>
                {/*<p className="fb">*/}
                {/*    <a href="javascript:void(0);" className="readmore-link">Read More</a>*/}
                {/*</p>*/}


            </div>
        </div>
    )
}

function SubmitBlock(Props) {
    //  ////////console.log(Props.parentTask)
    let collectionid = Props.parentTask.collecteddata;
    let collecteddataid = Props.parentTask.collecteddataid;
    let formName = Props.parentTask.collectedformtitle ? atob(Props.parentTask.collectedformtitle) : '';
    let formDesc = Props.parentTask.collectedformtitle ? atob(Props.parentTask.collectedformtitle) : '';
    let pageSettings = Props.parentTask.workFlowformsettings.results.data;
    let stageid = Props.parentTask.stageid;
    let currentPageSettings = pageSettings.filter(item => item.stageid === stageid);
    let settingsType = '';
    let showPreview = true;
    let hiddenFields = [];
    let readOnlyFields = [];
    let writableFields = [];
    let noViewFormPermission = false;
    if (currentPageSettings.length > 0 && getUrlSegment(1) !== "workflowci") {
        currentPageSettings = currentPageSettings[0];
        settingsType = currentPageSettings.type;
        hiddenFields = currentPageSettings.hide;
        readOnlyFields = currentPageSettings.readonly;
        writableFields = currentPageSettings.write;
        if (hiddenFields.length > 0 || readOnlyFields.length > 0 || writableFields.length > 0) {
            showPreview = false;
        }
        let formpages = Props.formPages ? Props.formPages : [];
        let componentsLength = 0;
        //console.log("hiddenFields.length ---" + hiddenFields.length + "----" + formpages.length)
        //console.log(settingsType)

        if (formpages.length > 0) {
            if (settingsType === 'field') {
                for (var i = 0; i < formpages.length; i++) {
                    componentsLength += formpages[i].components.filter(item => ((((!item.conditionalhide || item.conditionalhide === 'No')) || writableFields.includes(item.masterid) || readOnlyFields.includes(item.masterid)) && item.linked === 'No')).length;
                }
                //console.log(componentsLength)
                if (hiddenFields.length === componentsLength) {
                    noViewFormPermission = true;
                }
            } else {
                if (hiddenFields.length === formpages.length) {
                    noViewFormPermission = true;
                }
            }

        }

        /*let visibleFields = Props.formPages.map((page, pageIndex) => {
            let componentsLength = page.components.length;
            let visibleComponentsLength = page.components.filter(item => ((((!item.visibility || item.visibility === 'visible') && (!item.conditionalhide || item.conditionalhide === 'No')) || writableFields.includes(item.masterid) || readOnlyFields.includes(item.masterid)) && item.linked === 'No')).length;

        })
        }*/

    }

    return (
        <div className="task-details-body-block sub-block">
            <div className="workflow-task-body-container">
                <h5 className="ico-lib ">{/*{Props.step}. */}Preview the form</h5>
                <div className="nested-sub-block" onClick={(e) => {
                    noViewFormPermission ? Props.showNoPermissionModal() : (showPreview ?
                        Props.previewForm(collecteddataid, collectionid, 0)
                        : Props.showFormModal(collectionid, formName, formDesc, collecteddataid, hiddenFields, readOnlyFields, writableFields, settingsType))
                }}>
                    <div className="stage-name">
                        <div className="ico-lib-v1 nested-form"></div>
                        <div className="nested-form-content">
                            <h5>{formName}</h5>
                            <p>{formDesc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )

}

function CollectionBlock(list) {
    if (list.has_collection) {
        return (
            <div className="task-details-body-block sub-block">
                <div className="workflow-task-body-container">
                    <h5 className="ico-lib ">{/*{Props.step}. */}Submit form</h5>
                    <div className="nested-sub-block">



                            {/*<div className="ico-lib-v1 nested-form"></div>*/}
                                {list.collectionData.map((data, index) => {
                                    let formName = atob(data.title);
                                    let formDesc = atob(data.description);
                                    let loggedUsers = data.users.filter(user => user.assignee === getProfile().id);

                                    // ////////console.log(formDesc)

                                    if (list.createUser === getProfile().id && loggedUsers.length === 0) { // Creator only

                                        /***************** Whether to show preview form or show form or Error message Begin************/
                                        let displayPreview = false;
                                        let filledUsers = data.users.filter(user => user.has_data === 'yes');
                                        let filledFormCount = filledUsers.length;
                                        if (filledFormCount > 0) {
                                            displayPreview = true;
                                        }

                                        /***************** Whether to show preview form or show form or Error message End************/

                                        return (

                                            <div className="stage-name" style={{marginRight : '5px', marginBottom : '5px'}} key={"collection-" + index} onClick={(e) => {
                                                displayPreview === true ? list.previewForm(filledUsers[0]['fieldid'], data.collection, 0) : list.showPreviewAlert()
                                            }}>
                                                <div className="ico-lib-v1 nested-form"></div>
                                                <div className="nested-form-content">
                                                    <h5>{formName}</h5>
                                                    <p>{formDesc}</p>
                                                </div>
                                            </div>

                                            /*<li className="ind-forms-file" key={"collection-" + index}>
                                            <a className="forms-header ico-lib" href="javascript:void(0);"
                                               onClick={(e) => {
                                                   displayPreview === true ? list.previewForm(filledUsers[0]['fieldid'], data.collection, 0) : list.showPreviewAlert()
                                               }}>
                                                {formName}
                                            </a>
                                        </li>*/
                                    )
                                    } else if (list.createUser === getProfile().id && loggedUsers.length > 0) { // Creator and assignee together
                                        return (
                                            <div className="stage-name" style={{marginRight : '5px', marginBottom : '5px'}} key={"collection-" + index} onClick={(e) => {
                                                (loggedUsers[0]['has_data'] === 'yes' && data.users[0]['status'] === 'done') ? list.previewCollection(data.users[0]['fieldid'], data.collection, 0) : list.showCollectionModal(data.collection, formName, formDesc)
                                            }}>
                                                <div className="ico-lib-v1 nested-form"></div>
                                                <div className="nested-form-content">
                                                    <h5>{formName}</h5>
                                                    <p>{formDesc}</p>
                                                </div>
                                            </div>


                                            /*<li className="ind-forms-file" key={"collection-" + index}>
                                            <a className="forms-header ico-lib" href="javascript:void(0);"
                                               onClick={(e) => {
                                                   (loggedUsers[0]['has_data'] === 'yes' && data.users[0]['status'] === 'done') ? list.previewCollection(data.users[0]['fieldid'], data.collection, 0) : list.showCollectionModal(data.collection, formName, formDesc)
                                               }}>{formName}</a>
                                        </li>*/
                                        )
                                    } else if (list.createUser !== getProfile().id && loggedUsers.length > 0) { // Assignee only
                                        return (

                                            <div className="stage-name" style={{marginRight : '5px', marginBottom : '5px'}} key={"collection-" + index} onClick={(e) => {
                                                (loggedUsers[0]['has_data'] === 'yes' && loggedUsers[0]['status'] === 'done') ? list.previewCollection(loggedUsers[0]['fieldid'], data.collection, 0) : list.showCollectionModal(data.collection, formName, formDesc)
                                            }}>
                                                <div className="ico-lib-v1 nested-form"></div>
                                                <div className="nested-form-content">
                                                    <h5>{formName}</h5>
                                                    <p>{formDesc}</p>
                                                </div>
                                            </div>


                                            /*<li className="ind-forms-file" key={"collection-" + index}>
                                            <a className="forms-header ico-lib" href="javascript:void(0);"
                                               onClick={(e) => {
                                                   (loggedUsers[0]['has_data'] === 'yes' && loggedUsers[0]['status'] === 'done') ? list.previewCollection(loggedUsers[0]['fieldid'], data.collection, 0) : list.showCollectionModal(data.collection, formName, formDesc)
                                               }}>{formName}</a>
                                        </li>*/
                                        )
                                    }
                                })}
                    </div>
                </div>
            </div>
        )
    } else
        return null
}

function FormDataBlock(Props) {
    //console.log(Props)
    let assigneeStatus = (Props.indAssignee.length !== 0 && Props.indAssignee[0].status === "Done") ? "Done" : (Props.assigneeTaskStatus && Props.assigneeTaskStatus === 'Done' ? "Done" : "NotDone");
    let Assigneerole = (Props.indAssignee.length !== 0 && Props.indAssignee[0].share === "yes") ? "shared" : "notshared";
    //console.log(assigneeStatus )
    return (
        <div className={(Assigneerole === "shared") ? "task-details-body-block sub-block disabled" : "task-details-body-block sub-block"}>
            <div className="workflow-task-body-container">
                <h5 className="ico-lib">{/*{Props.step}.*/} Submit Data</h5>
                <div className="nested-sub-block">
                    {Props.meta_data.map((meta, index) =>
                        <React.Fragment>
                            {meta.type !== "attachment" &&
                            <div className="input-block" key={index}>
                                <h5>{meta.label}</h5>
                                {meta.type === "checkbox" &&
                                <ul className="select-lister">
                                    {meta.options !== null && meta.options !== undefined && meta.options.map((option, opt_index) => {
                                            let checkedVal = meta.value ? meta.value.filter(item => item === option) : false;
                                            return (
                                                <li>
                                                    <div className="select-chk-block"
                                                         style={{display: "block"}}>
                                                        <label className="control control-checkbox">
                                                            {option}
                                                            <input type="checkbox"
                                                                   name={meta.id}
                                                                   className="inp-chk" value={option}
                                                                   onChange={(e) => Props.handleInputchange(e, index, opt_index)}
                                                                   required={meta.required === 'yes' ? true : false}
                                                                   defaultChecked={checkedVal}
                                                                   disabled={(Props.indAssignee.length === 0 || assigneeStatus === "Done") ? "disabled" : ''}

                                                            />
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                </li>
                                            )
                                        }
                                    )}

                                </ul>
                                }
                                {meta.type === "switch" &&
                                <ul className="select-lister">
                                    {meta.options !== null && meta.options !== undefined && meta.options.map((option, option_index) =>
                                        <li>
                                            <div className="radio-block">
                                                <input type="radio" id="singlestep" className="off_type"
                                                       name={meta.id} value={option}
                                                       onChange={(e) => Props.handleInputchange(e, index)}
                                                       required={meta.required === 'yes' ? true : false}
                                                       checked={(meta.value ? (meta.value[0].value === option) : false)}
                                                       disabled={(Props.indAssignee.length === 0 || assigneeStatus === "Done") ? "disabled" : ''}
                                                />
                                                <label htmlFor="singlestep">{option}</label>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                                }
                                {meta.type === "text" &&
                                <input type="text" name={meta.id} className="input-box"
                                       placeholder="Input here"
                                       onChange={(e) => Props.handleInputchange(e, index)}
                                       required={meta.required === 'yes' ? true : false}
                                       defaultValue={meta.value ? meta.value[0].value : ''}
                                       disabled={(Props.indAssignee.length === 0 || assigneeStatus === "Done") ? "disabled" : ''}
                                />
                                }
                                {meta.type === "loggedin" &&
                                <input type="text" name={meta.id} className="input-box"
                                       placeholder="Input here"
                                       required={meta.required === 'yes' ? true : false}
                                       defaultValue={meta.value ? meta.value[0].value : getProfile().first_name+" "+ getProfile().last_name}
                                       readOnly={true}
                                       onFocus={(e) => Props.handleInputchange(e, index)}
                                />
                                }
                                {meta.type === "dropdown" &&
                                <div className="select-box">
                                    <select name={meta.id}
                                            disabled={(Props.indAssignee.length === 0 || assigneeStatus === "Done") ? "disabled" : ''}
                                            onChange={(e) => Props.handleInputchange(e, index)}
                                            required={meta.required === 'yes' ? true : false}
                                            value={meta.value ? meta.value[0].value : ''}>
                                        <option value="">Select</option>
                                        {meta.options !== null && meta.options !== undefined && meta.options.map((option) =>
                                            <option value={option}>{option}</option>
                                        )}
                                    </select>
                                </div>
                                }
                                {meta.type === "datepicker" &&
                                <div className="input-box date-box " style={{background: "white"}}>
                                    <DatePickerBox handleDateChange={Props.handleDateChange}
                                                   startDate={meta.value ? new Date(meta.value[0].value) : Props.state.duedate}
                                                   id={meta.id} name={meta.id}
                                                   required={meta.required === 'yes' ? true : false} index={index}/>
                                </div>
                                }
                                {meta.type === "number" &&
                                <input type="number" name={meta.id} className="input-box"
                                       placeholder="Input here"
                                       onChange={(e) => Props.handleInputchange(e, index)}
                                       required={meta.required === 'yes' ? true : false}
                                       defaultValue={meta.value ? meta.value[0].value : ''}
                                       disabled={(Props.indAssignee.length === 0 || assigneeStatus === "Done") ? "disabled" : ''}
                                />
                                }
                                {meta.type === "textarea" &&
                                <textarea
                                    disabled={(Props.indAssignee.length === 0 || assigneeStatus === "Done") ? "disabled" : ''}
                                    className="textarea" name={meta.id} placeholder="textarea"
                                    onChange={(e) => Props.handleInputchange(e, index)}
                                    required={meta.required === 'yes' ? true : false}
                                    defaultValue={meta.value ? meta.value[0].value : ''}></textarea>
                                }

                                <label
                                    className={"form-error-text form-error-label " + ((Props.state.submitted && (!Props.state[meta.id] || Props.state[meta.id] === '') && meta.required === 'yes') ? " " : "hide")}> Please
                                    fill the field</label>
                            </div>
                            }
                            {meta.type === "attachment" &&
                            <>
                                <div
                                    className={(Props.indAssignee.length === 0 || assigneeStatus === "Done") ? "attach-block disabled" : "attach-block "}>
                                    <div id="todoFileAssets" className="file-upload-wf">
                                        <label className="ico-lib att">
                                            <UploadFile name={meta.id} getUploadedFile={Props.getUploadedFile}
                                                        fileIndex={index}
                                                        required={meta.required === 'yes' ? true : false}/>
                                            {meta.label}
                                        </label>
                                    </div>
                                    <label
                                        className={"form-error-text form-error-label " + ((Props.state.submitted && meta.required === 'yes' && (Props.state.uploadedFiles.length === 0) ? "" : "hide"))}> Please
                                        attach files</label>
                                </div>
                                <div className="attached-file round-edge">
                                    {/*this.state.uploadedFiles && this.state.uploadedFiles.map((item, opt_index) =>
                                                    <div className="file-tag" id={"attachment_"+item.fileId}>{item.originalfileName} <button className="ico-lib cl-fl" onClick={(e)=>this.removeSelectedAttachment(opt_index)}></button>
                                                    </div>
                                                )*/}
                                    {meta.value !== null && meta.value !== undefined && meta.value.map((value, opt_index) =>
                                        <div className="file-tag" id={"attachment_" + value.fileId}>{value.value}
                                            <button className="ico-lib cl-fl"
                                                    onClick={(e) => Props.removeSelectedAttachment(opt_index)}></button>
                                        </div>
                                    )}
                                </div>

                            </>
                            }
                        </React.Fragment>
                    )}
                    {(Props.indAssignee.length !== 0 && Props.indAssignee[0].status !== "Done") && getUrlSegment(1) !== "workflowci" &&
                    <React.Fragment>
                        {Props.state.metasubmitted === false &&
                        <div className="btn-section-left">
                            <button className="general-btn-green ico-lib verify-button"
                                    onClick={(e) => Props.submitWorkFlowAction(e, '')}>{Props.submissiontype === "withoutdecision" ? "Close Task" : "Proceed"}
                            </button>

                            {Props.list.poolenabled === true && Props.submissiontype === "withoutdecision" &&
                            <button className="general-btn-green ico-lib quit"
                                    onClick={() => Props.quitPoolTaskConfirm('quit')}>Return to Task Pool
                            </button>
                            }
                        </div>
                        }
                        {(Props.state.metasubmitted === true && assigneeStatus !== "Done")  &&
                        <div className="btn-section-left">
                            <button className="general-btn-green ico-lib verify-button"
                                    onClick={(e) => Props.submitWorkFlowAction(e, '')}>Edit
                            </button>
                        </div>
                        }
                    </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

function DecisionBlock(Props) {
    let taskstatus = Props.taskstatus;
    let assigneeStatus = (Props.indAssignee.length !== 0 && Props.indAssignee[0].status === "Done") ? "Done" : (Props.assigneeTaskStatus && Props.assigneeTaskStatus === 'Done' ? "Done" : "NotDone");
    let Assigneerole = (Props.indAssignee.length !== 0 && Props.indAssignee[0].share === "yes") ? "shared" : "notshared";
    // let statusmeta = Props.decisionmeta_data_result !== "" ? Props.decisionmeta_data_result : taskstatus;
    let formValues = Props.parent.wf_status_info ? Props.parent.wf_status_info.meta : [];
    let submission_statusid = Props.parent.wf_status_info ? Props.parent.wf_status_info.submission_statusid : '';
    //////////console.log(taskstatus)
    ////////console.log(Props.submission_decision_statusid )
    return (
        <div
            className={(Props.meta_data.length > 0 && Props.formdata.metasubmitted === false) || (Assigneerole === "shared") ? "task-details-body-block sub-block disabled" : "task-details-body-block sub-block"}>
            <div className="workflow-task-body-container">
                <h5 className="ico-lib">{/*{Props.step}.*/} Mark the decision</h5>
                <div className="nested-sub-block">
                    {taskstatus &&
                    <React.Fragment>
                        <div className="hint-box">
                            <ul>
                                {taskstatus.map((stat, index) =>
                                    stat.hasOwnProperty("hint") && <li>{stat.hint}&nbsp;</li>
                                )}
                            </ul>
                        </div>
                        <ul className="select-lister rejection-lister">
                            {taskstatus.map((stat, index) =>
                                <React.Fragment>
                                    {stat.has_rule === false &&
                                    <li>
                                        <div
                                            className={`radio-block ${(stat._id === submission_statusid || Props.submission_decision_statusid === stat._id) ? " active" : ""}`}
                                            key={'status' + index}>
                                            <input type="radio" id={"singlestep" + stat._id}
                                                   className="off_type"
                                                   name="stageforward"
                                                   onChange={(e) => Props.handleDecisionInputchange(e, stat.meta, stat.nextstage, stat.status, stat._id)}
                                                   value={stat._id}
                                                   defaultChecked={stat._id === submission_statusid}
                                                   disabled={assigneeStatus === "Done"}
                                            />
                                            <label htmlFor={"singlestep" + stat._id}>{stat.status}</label>
                                        </div>
                                    </li>
                                    }
                                    {stat.has_rule === true &&
                                    Props.renderDecisionField(Props.taskstatus[index].rule, Props.taskstatus[index].status, stat, submission_statusid)
                                    }
                                </React.Fragment>
                            )}
                        </ul>

                        {taskstatus.map((stat, index) =>
                            <React.Fragment>
                                {stat.meta !== null && stat.meta.map((field, fieldIndex) => {
                                        let fields = formValues.filter(item => item.id === field.id);
                                        let currentField = (fields.length > 0) ? fields[0] : undefined;
                                        let fieldValue = (currentField && currentField.value && currentField.value.length > 0) ? currentField.value[0].value : '';
                                        return (
                                            <React.Fragment>
                                                {(Props.formdata.stageforward === stat._id || submission_statusid === stat._id) &&
                                                <React.Fragment>
                                                    {field.type !== "attachment" &&
                                                    <div className="input-block" key={fieldIndex}>
                                                        <h5>{field.label}</h5>
                                                        {field.type === "checkbox" &&
                                                        <ul className="select-lister">
                                                            {field.options !== null && field.options !== undefined && field.options.map((option, opt_index) =>
                                                                <li>
                                                                    <div className="select-chk-block"
                                                                         style={{display: "block"}}>
                                                                        <label className="control control-checkbox">
                                                                            {option}
                                                                            <input type="checkbox"
                                                                                   name={field.id}
                                                                                   className="inp-chk" value={option}
                                                                                   onChange={(e) => Props.handleInputDecisionchange(e, fieldIndex, opt_index)}
                                                                                   required={field.required === 'yes' ? true : false}
                                                                                   defaultChecked={fieldValue === option}
                                                                                   disabled={assigneeStatus === "Done" ? "disabled" : ''}
                                                                            />
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            )}

                                                        </ul>
                                                        }

                                                        {field.type === "switch" &&
                                                        <ul className="select-lister">
                                                            {field.options !== null && field.options !== undefined && field.options.map((option, option_index) =>
                                                                <li>
                                                                    <div className="radio-block">
                                                                        <input type="radio" id={"singlestep_" + field.id}
                                                                               className="off_type"
                                                                               name={field.id} value={option}
                                                                               onChange={(e) => Props.handleInputDecisionchange(e, fieldIndex)}
                                                                               required={field.required === 'yes' ? true : false}
                                                                               defaultChecked={fieldValue === option}
                                                                               disabled={assigneeStatus === "Done" ? "disabled" : ''}
                                                                        />
                                                                        <label
                                                                            htmlFor={"singlestep_" + field.id}>{option}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                        }
                                                        {field.type === "text" &&
                                                        <input type="text" name={field.id} className="input-box"
                                                               placeholder="Input here"
                                                               onChange={(e) => Props.handleInputDecisionchange(e, fieldIndex)}
                                                               required={field.required === 'yes' ? true : false}
                                                               defaultValue={fieldValue}
                                                               disabled={assigneeStatus === "Done" ? "disabled" : ''}
                                                        />
                                                        }
                                                        {field.type === "dropdown" &&
                                                        <div className="select-box"
                                                             disabled={assigneeStatus === "Done" ? "disabled" : ''}>
                                                            <select name={field.id}
                                                                    onChange={(e) => Props.handleInputDecisionchange(e, fieldIndex)}
                                                                    required={field.required === 'yes' ? true : false}
                                                                    value={fieldValue}>
                                                                <option value="">Select</option>
                                                                {field.options !== null && field.options !== undefined && field.options.map((option) =>
                                                                    <option value={option}>{option}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        }
                                                        {field.type === "datepicker" &&
                                                        <div className="input-box date-box " style={{background: "white"}}>
                                                            <DatePickerBox handleDateChange={Props.handleDateDecisionchange}
                                                                           startDate={fieldValue !== '' ? new Date(fieldValue) : Props.state.duedate}
                                                                           id={field.id} name={field.id}
                                                                           required={field.required === 'yes' ? true : false}
                                                                           index={fieldIndex}/>
                                                        </div>
                                                        }
                                                        {field.type === "number" &&
                                                        <input type="number" name={field.id} className="input-box"
                                                               placeholder="Input here"
                                                               onChange={(e) => Props.handleInputDecisionchange(e, fieldIndex)}
                                                               required={field.required === 'yes' ? true : false}
                                                               defaultValue={fieldValue}
                                                               disabled={assigneeStatus === "Done" ? "disabled" : ''}
                                                        />
                                                        }
                                                        {field.type === "textarea" &&
                                                        <textarea disabled={assigneeStatus === "Done" ? "disabled" : ''}
                                                                  className="textarea" name={field.id}
                                                                  placeholder="textarea"
                                                                  onChange={(e) => Props.handleInputDecisionchange(e, fieldIndex)}
                                                                  required={field.required === 'yes' ? true : false}
                                                                  defaultValue={fieldValue}></textarea>
                                                        }

                                                        <label
                                                            className={"form-error-text form-error-label " + ((Props.formdata.decisionsubmitted && (!Props.formdata[field.id] || Props.formdata[field.id] === '') && field.required === 'yes') ? " " : "hide")}> Please
                                                            fill the field</label>
                                                    </div>
                                                    }
                                                    {field.type === "attachment" &&
                                                    <>
                                                        <div
                                                            className={assigneeStatus === "Done" ? "attach-block disabled" : "attach-block "}>
                                                            <div id="todoFileAssets" className="file-upload-wf">
                                                                <label className="ico-lib att">
                                                                    <UploadFile name={field.id}
                                                                                getUploadedFile={Props.getUploadedFileDecision}
                                                                                fileIndex={fieldIndex}
                                                                                required={field.required === 'yes' ? true : false}/>
                                                                    {field.label}
                                                                </label>
                                                            </div>
                                                            <label
                                                                className={"form-error-text form-error-label " + ((this.formdata.decisionsubmitted && field.required === 'yes' && (this.formdata.uploadedFiles.length === 0) ? "" : "hide"))}> Please
                                                                attach files</label>
                                                        </div>
                                                        <div className="attached-file round-edge">
                                                            {fieldValue !== null && fieldValue !== undefined && fieldValue.map((value, opt_index) =>
                                                                <div className="file-tag"
                                                                     id={"attachment_" + value.fileId}>{value.value}
                                                                    <button className="ico-lib cl-fl"
                                                                            onClick={(e) => Props.removeSelectedAttachment(opt_index)}></button>
                                                                </div>
                                                            )}
                                                        </div>

                                                    </>
                                                    }
                                                </React.Fragment>
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                )}

                            </React.Fragment>
                        )}
                        {Props.indAssignee.length !== 0 && (Props.indAssignee[0].status !== "Done" && Props.assigneeTaskStatus !== "Done") &&
                        getUrlSegment(1) !== "workflowci" &&

                        <div className="btn-section-left">
                            <button className="general-btn-green ico-lib verify-button"
                                    onClick={(e) => (Props.submissiontype === "withdecision") ? Props.submitWorkFlowDecisionAction(e) : Props.submitWorkFlowAction(e, '')}>Close Task
                            </button>
                            {Props.parent.poolenabled === true &&
                            <button className="general-btn-green ico-lib quit"
                                    onClick={() => Props.quitPoolTaskConfirm('quit')}>Return to Task Pool
                            </button>
                            }
                        </div>
                        }
                    </React.Fragment>
                    }

                </div>
            </div>
        </div>
    )

}

function NavTab(Props) {
    /* for codeigniter invoke view */
    if (getUrlSegment(1) === "workflowci") {
        var dataNav = [

            {
                "AcTab": "attach-tab",
                "label": "Previous Stages",
                "icon": "ico-lib-v1 file-stage-ico"
            }
        ]
    } else {
        var dataNav = [
            {
                "AcTab": "home-tab",
                "label": "Discussion",
                "icon": "ico-lib-v1 disscussion-tab-ico"
            },
            {
                "AcTab": "attach-tab",
                "label": "Previous Stages",
                "icon": "ico-lib-v1 file-stage-ico"
            },
            {
                "AcTab": "user-tab",
                "label": "User",
                "icon": "ico-lib-v1 user-tab-ico"
            },
        ]
    }
    return (
        <React.Fragment>
            {dataNav.map((label, index) =>
                <li className="nav-item" key={"nav_tab_" + index} onClick={(e) => {
                    Props.getrightTabid(label.AcTab)
                }}>
                    <a className={Props.rightTab === label.AcTab ? 'nav-link active' : 'nav-link'} id="act-tab"
                       data-toggle="tab" role="tab"
                       aria-controls="act" aria-selected={Props.rightTab === label.AcTab ? 'true' : 'false'}>
                        <span className={label.icon}></span>
                        <span className="tab-text">{label.label}</span>
                    </a>
                </li>
            )}


        </React.Fragment>
    )
}

function UsersPanelTab(props) {
    if (props.assigneesData.length !== 0)
        return (
            <React.Fragment>
                <div className="spliter-task-user">
                    <h5 className="split-task-header">{props.title} ({props.assigneesData.length})</h5>
                    {props.assigneesData.map((item, index) =>
                        <div className="ind-task-added-user-list">
                            <React.Fragment key={"assignee-tab-" + index}>
                                <div className="ind-added-user-list-left">
                                    <div className="ind-added-user-list-left-thumb">
                                        <img title={item.user_name} src={USRIMG_PATH + item.user_img_name} alt=""/>
                                    </div>
                                    <div className="ind-added-user-list-left-info">
                                        <h5>{item.user_name}</h5>
                                        <p>{item.user_designation}</p>
                                        {props.type === "assigned" &&
                                        <div className="task-status-meter">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar"
                                                     style={{width: item.status === 'Done' ? '100%' : '50%'}}
                                                     aria-valuenow="25" aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                            <p>{item.status === 'Done' ? '100%' : '50%'}</p>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="ind-added-user-list-right">
                                    {/*<div className="date-user-task">*/}
                                    {/*    <h5>Time Taken</h5>*/}
                                    {/*    <p>1 Day and 21 Hrs</p>*/}
                                    {/*</div>*/}
                                    <button className="ico-lib-v1 chat-ico-task"></button>
                                </div>
                            </React.Fragment>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    else {
        return null
    }
}

function PreviousStagesPanel(props) {

    if (props.previoustasksData.length !== 0) {
        let previoustasksData = props.previoustasksData;
        let prevStageCount = previoustasksData.length;


        return (
            <React.Fragment>
                <div className="spliter-task-user">
                    <div className="accordion stage-accordion milestone-type" id="accordionExample">
                        {previoustasksData.map((data, index) => {
                                let completed_by = '';
                                let completed_by_designation = '';

                                if (data.assignees && data.assignees.length > 0) {
                                    //console.log(data.assignees)
                                    completed_by = data.assignees[0].user_name;
                                    completed_by_designation = data.assignees[0].user_designation;
                                }
                                return (<div className="card" key={"prev" + index}>
                                        <div
                                            className={props.state.showAccordian === index ? "card-header" : "card-header collapsed"}
                                            id="headingOne" data-toggle="collapse"
                                            data-target="#collapseOne"
                                            aria-expanded={props.state.showAccordian === true ? "true" : "false"}
                                            aria-controls="collapseOne">
                                            <div className="stage-accord-header without-left-info"
                                                 onClick={(e) => props.openAccordian(e, index)}>
                                                {/*<div className="stage-accord-header-left">
                                            <h5>Stage</h5>
                                            <p>{prevStageCount - index}</p>
                                        </div>*/}
                                                <div className="stage-accord-header-right">
                                                    <h5>STAGE {prevStageCount - index} | {data.stagename}</h5>
                                                    <p>Completed
                                                        On {moment.utc(data.donedate).local().format('DD MMM YYYY hh:mm a')} By {completed_by} {completed_by_designation !== '' ? "(" + completed_by_designation + ")" : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="collapseOne"
                                             className={props.state.showAccordian === index ? "collapse show" : "collapse"}
                                             aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body">

                                                <div className="workflow-task-check-block">
                                                    <div className="task-check-container">
                                                        {data.collection && data.collection.map((collectiondata, index) =>
                                                            <React.Fragment key={"collecdata" + index}>
                                                                <div className="ind-task-check">
                                                                    <div className="task-check-header">
                                                                        <h5 className="checkpoint checked">Verified
                                                                            Form</h5>
                                                                    </div>
                                                                    <div className="task-check-body">
                                                                        <h5 className="ico-lib form-small-icon">{atob(collectiondata.title)}</h5>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                        {data.meta_form.length > 0 &&
                                                        <div className="ind-task-check">
                                                            <div className="task-check-header">
                                                                <h5 className="checkpoint checked">Submitted Data</h5>
                                                            </div>
                                                            <div className="task-check-body">
                                                                <div className="split-data">
                                                                    {data.meta_form.map((metalist, index) =>
                                                                            <div
                                                                                className={(metalist.type === 'textarea' || metalist.type === 'text') ? "split-data-row textarea-block" : "split-data-row"}>
                                                                                <div
                                                                                    className="split-data-left">{metalist.label}{(metalist.type !== 'textarea' && metalist.type !== 'text') && ''}
                                                                                </div>
                                                                                <div
                                                                                    className="split-data-right"> {metalist.value !== undefined && metalist.value !== null && metalist.value.map((values, index) => {
                                                                                        let contentPath = ''
                                                                                        if (metalist.type === 'attachment') {
                                                                                            let iconClass = getFileIcon(values.uniqval);
                                                                                            contentPath = props.getAttachmentPath(iconClass);
                                                                                        }
                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                {metalist.type === 'attachment' &&
                                                                                                <span
                                                                                                    onClick={(e) => forceDownload(contentPath + values.uniqval, values.value)}>
                                                                        {values.value}
                                                                    </span>
                                                                                                }
                                                                                                {(metalist.type !== 'attachment') &&

                                                                                                values.value
                                                                                                }
                                                                                                {(index < metalist.value.length - 1 ? ", " : "")}
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    }
                                                                                )}</div>
                                                                            </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        {data.wf_status_info && data.wf_status_info.submission_status &&
                                                        <div className="ind-task-check">
                                                            <div className="task-check-header">
                                                                <h5 className="checkpoint checked">Decision</h5>
                                                            </div>
                                                            <div className="task-check-body">
                                                                <div className="split-data">
                                                                    <div className="split-data-row">
                                                                        <div className="split-data-left">Option</div>
                                                                        <div
                                                                            className="split-data-right">{data.wf_status_info && data.wf_status_info.submission_status}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="split-data">
                                                                    {data.wf_status_info && data.wf_status_info.meta && data.wf_status_info.meta.map((metalist, index) =>
                                                                        <div
                                                                            className={(metalist.type === 'textarea' || metalist.type === 'text') ? "split-data-row textarea-block" : "split-data-row"}>
                                                                            <div
                                                                                className="split-data-left">{metalist.label}</div>
                                                                            {metalist.value !== undefined && metalist.value !== null && metalist.value.map((values, index) => {
                                                                                    return (
                                                                                        <React.Fragment>

                                                                                            <div
                                                                                                className="split-data-right">{values.value}</div>


                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                                {data.collection && data.collection.map((collectiondata, index) =>
                                                    <div className="workflow-user-wrapper">
                                                        {collectiondata.users && collectiondata.users.map((user, index) =>
                                                            <div className="workflow-user-row" key={"user" + index}>
                                                                <div className="workflow-user-img">
                                                                    <img src={USRIMG_PATH + user.img_name}/>
                                                                </div>
                                                                <div className="workflow-user-details">
                                                                    <h5>{user.name}</h5>
                                                                    {user.designation !== null &&
                                                                    <p>{user.designation}</p>
                                                                    }
                                                                </div>
                                                            </div>)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    } else {
        return null
    }

}

class TimeLogForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timeLogValues: [{rawdate: '', hour: '', min: ''}]
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.timeLogValues !== this.state.timeLogValues) {
            this.props.getTimeLogEntries(this.state.timeLogValues);
        }
    }

    handleDateChange(date, i) {


        let timeLogValues = [...this.state.timeLogValues];
        timeLogValues[i]['rawdate'] = date;
        timeLogValues[i]['date'] = formatDate(date);

        this.setState({
            timeLogValues: timeLogValues
        });
        document.getElementById('date' + i).classList.remove('val-error');

    }

    handleTimeLogChange(i, event) {

        var fieldId = event.target.id;
        var fieldName = event.target.name;
        var fieldValue = event.target.value;


        let timeLogValues = [...this.state.timeLogValues];

        if (timeLogValues[i][fieldName] === undefined) {
            timeLogValues[i][fieldName] = [];
        }
        timeLogValues[i][fieldName] = fieldValue;

        delete timeLogValues[i].value;
        this.setState({timeLogValues});

        if (fieldValue !== '') {
            if (fieldValue > parseInt(event.target.max)) {
                document.getElementById(fieldId).value = event.target.max;
            } else {
                document.getElementById(fieldId).classList.remove('val-error');
            }

        }

    }

    addNewLog() {
        this.setState(prevState => ({
            timeLogValues: [...prevState.timeLogValues, {rawdate: '', hour: '', min: ''}]
        }));
    }

    removeLog(i) {
        let timeLogValues = [...this.state.timeLogValues];
        if (timeLogValues.length > 1) {
            timeLogValues.splice(i, 1);
            this.setState({timeLogValues});
        }

    }


    render() {
        let index = this.props.index;
        let assignee = this.props.assignee;


        return (
            <>
                {this.state.timeLogValues.map((item, i) => (
                    <div key={i} className="row-list">
                        <div className="time-log-items one-third">
                            <div className="input-log-time date">
                                <DatePickerBox id={"date" + i} handleDateChange={this.handleDateChange.bind(this)}
                                               startDate={item['rawdate']} index={i} maxDate={new Date()}
                                               placeholderText="Date"
                                />
                            </div>
                        </div>
                        <div className="time-log-items one-third">
                            <div className="input-log-time">
                                <input
                                    type="number"
                                    name="hour"
                                    id={"hour" + i}
                                    max="23"
                                    className="log-time-input"
                                    placeholder="Hours"
                                    onChange={e => this.handleTimeLogChange(i, e)}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="time-log-items one-third">
                            <div className="input-log-time addition">
                                <input
                                    type="number"
                                    name="min"
                                    id={"min" + i}
                                    max="59"
                                    className="log-time-input"
                                    placeholder="Minutes"
                                    onChange={e => this.handleTimeLogChange(i, e)}
                                />
                            </div>
                        </div>
                        <div className="log-addition">
                            {i > 0 &&
                            <input
                                type="button"
                                value="-"
                                onClick={() => this.removeLog(i)}
                                className="log-addition-link"
                            />
                            }
                            <input type="button" className="log-addition-link"
                                   value="+" onClick={() => this.addNewLog()}/>
                        </div>

                    </div>
                ))}
            </>
        )
    }
}

class TimeLogList extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let index = this.props.index;
        let log = this.props.log;


        return (

            <div className="time-log-count" key={index}>
                <div className="time-log-sub-list-left">
                    <p> {moment(log.log_date).format('dddd,  DD MMM YYYY')}</p>
                </div>
                <div className="time-log-sub-list-right">
                    <p>Time
                        Taken <span>{log.log_hours}:{log.log_mins} </span>Hrs
                    </p>
                </div>
            </div>
        );
    }
}

export {
    WorkFlowTaskHeaderBlock,
    WorkFlowTaskDetailsBlock,
    SubmitBlock,
    FormDataBlock,
    DecisionBlock,
    NavTab,
    UsersPanelTab,
    PreviousStagesPanel,
    TimeLogForm,
    TimeLogList,
    CollectionBlock,
    WorkflowOptionButton
};