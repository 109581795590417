import React, {Component} from 'react';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import "./syncfusion/Gantt.css"
import moment from "moment";

class DateTimePickerNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            index : 0,
            fromTaskDetail:false,
            id :'date',
            min : new Date(1900, '00', '01'),
            max : new Date(2099, 11, 31),
            step:15,
            action : '',
            widgetOpened : false,
            errorMessage : '',
            placeholder : 'Date'

        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange =(changeddate) => {
        if(!changeddate ||  (changeddate && changeddate.value === null)) {
            let startDate = null;
            this.props.handleDateChange(startDate, this.props.index)
            this.setState({startDate: startDate});
        }
        else{
            let startDate = this.state.startDate;
            let errorMessage = '';
            let minDate = moment().format('MMMM D YYYY, h:mm a');
            if(this.state.min){
                minDate = moment(this.state.min).format('MMMM D YYYY, h:mm a');
                //console.log(minDate)
            }
            let min = null;
            let n = new Date();

            if(this.state.action === 'edit' && this.state.widgetOpened){
                min = minDate;
            }
            else if(this.state.action === 'edit' && !this.state.widgetOpened){
                min = changeddate;
                if(typeof(changeddate) === 'object'){
                    min = minDate;
                }
            }
            if(this.state.widgetOpened) {
                if(this.state.startDate === null ) {
                    n = new Date(changeddate.value);
                    n.setHours(23, 59);
                    startDate = n;
                }
                else{
                    startDate = changeddate.value;
                }

                let formatteddate = moment(startDate).format('MMMM D YYYY, h:mm a');
                if(formatteddate < minDate) {
                    errorMessage = 'Past date/time is not allowed';
                }
                else{
                    errorMessage = '';
                }

                if(this.state.fromTaskDetail === true){
                    this.props.updatetask(startDate)
                }
                else{
                    this.props.handleDateChange(startDate, this.props.index)
                }


            }
            this.setState({ min : min,errorMessage : '', startDate: startDate});
        }
    }

    openwidget = () => {
        this.setState({widgetOpened : true})
    }

    componentDidMount() {
        this.setState({
            id : this.props.id ? this.props.id : this.state.id ,
            fromTaskDetail: this.props.fromTaskDetail,
            min : this.props.min ? this.props.min : this.state.min,
            action : this.props.action,
            placeholder : this.props.placeholder,
            startDate : this.props.startDate
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.startDate !== this.props.startDate){
            this.setState({startDate: this.props.startDate});
        }
        if(prevProps.id !== this.props.id){
            this.setState({id: this.props.id});
        }
        if(prevProps.min !== this.props.min){
            this.setState({ min: this.props.min});
        }
    }

    render() {
        return (
            <div className='control-pane'>
                <div className='control-section'>
                    <div className={`datetimepicker-control-section ${this.state.widgetOpened && this.state.errorMessage ? 'val-error' :''} `}>
                        <DateTimePickerComponent
                            placeholder={this.state.placeholder}
                            min={this.state.min}
                            max={this.state.max}
                            change={this.handleChange}
                            value={this.state.startDate}
                            strictMode={true}
                            open={this.openwidget}
                            format={'dd MMM, yyyy h:mm a'}
                            showTodayButton={false}
                        ></DateTimePickerComponent>

                    </div>
                </div>
                <span className="error-label">{this.state.widgetOpened && this.state.errorMessage}</span>
            </div>
        );
    }
}

export default DateTimePickerNew
