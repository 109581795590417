import React, {Component} from 'react';
import { LineChart, Line, Bar, XAxis, YAxis,CartesianGrid, Tooltip, Area } from 'recharts';
function LineChartCard(props) {
    //console.log(props.data)
    return (
        <React.Fragment>
            <LineChart width={135} height={25} data={props.data}
                       margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey="x" hide={true}/>
                <YAxis dataKey="y" hide={true}/>
                <Tooltip />
                <Line type="linear" dataKey="y" stroke="#8884d8" dot={false} />
            </LineChart>
        </React.Fragment>
    );
}

export default LineChartCard;