import React, {PureComponent} from 'react';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Area,
    Legend,
    ResponsiveContainer, AreaChart
} from 'recharts';

const CustomTooltip = ({active, payload, label, unit, hidearea}) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                {payload && payload.map(item =>
                    <p className="intro"
                       style={{color: hidearea ? item.color : item.fill}}>{`${item.name} : ${new Intl.NumberFormat('en-IN', {maximumFractionDigits: 2}).format(item.value)} ${unit ? unit : ''}`}</p>
                )}
            </div>
        );
    }

    return null;
};

class CustomizedLabel extends PureComponent {
    render() {
        const {
            x, y, stroke, value, name, count
        } = this.props;
        return <text className="" x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="end">{name}</text>;

    }
}

function AreaLineChart(props) {
    let xData = props.containerData.tabledata.slice(0, 1);
    let areaData = props.containerData.tabledata.slice(1, 2);
    let seriesEnable = false;
    if (props.containerData.seriesLabel && props.containerData.seriesLabel !== '') {
        seriesEnable = true;
    }
    let axisData = props.containerData.axisData;
    let gradData = props.containerData.tabledata.slice(1, 2);
    let xwidth = props.containerData.xwidth && parseInt(props.containerData.xwidth);
    let xheight = props.containerData.xheight && parseInt(props.containerData.xheight);
    let yheight = props.containerData.yheight && parseInt(props.containerData.yheight);
    let ywidth = props.containerData.ywidth && parseInt(props.containerData.ywidth);
    let graphHeight = props.containerData.graphHeight ? parseInt(props.containerData.graphHeight) : 200;
    let xAngle = props.containerData.xAngle ? parseInt(props.containerData.xAngle) : 0;
    let yAngle = props.containerData.yAngle ? parseInt(props.containerData.yAngle) : 0;
    let hidearea = props.containerData.hidearea ? props.containerData.hidearea : false;
    let enablegradient = props.containerData.enablegradient ? props.containerData.enablegradient : false;
    let transparancy = props.containerData.transparancy ? props.containerData.transparancy : 0;
    let areaOpacity = 1 - parseFloat(transparancy);
    let borderColor = props.containerData.bordercolor && props.containerData.bordercolor;
    //console.log(props.containerData.data)
    return (
        <React.Fragment>
            <ResponsiveContainer width={props.containerData.legend ? "95%" : "100%"} height={graphHeight}>
                <ComposedChart
                    data={props.containerData.data} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                    <CartesianGrid vertical={false} stroke="#f0f0f0"/>
                    {xData.map((list, index) =>
                        <XAxis dataKey={list.label} width={xwidth} height={xheight} angle={-xAngle} padding={{left: 0}}
                               axisLine={false} tickLine={false} fontSize={12}
                               interval={props.containerData.alldata ? 0 : 'preserveStartEnd'} textAnchor={'start'}/>
                    )}
                    <YAxis width={ywidth} height={yheight} angle={-yAngle}
                           hide={props.containerData.enableyaxis ? false : true} axisLine={false} tickLine={false}
                           position="insideBottom"/>
                    <Tooltip cursor={{fill: 'transparent'}}
                             content={<CustomTooltip hidearea={hidearea} unit={props.containerData.unit}/>}/>
                    <defs>
                        {enablegradient && gradData.map((list, index) =>

                            <linearGradient id={`colorUv${props.containerData.cid + index}`} x1="0" y1="0" x2="0"
                                            y2="1">
                                <stop offset="5%" stopColor={list.color} stopOpacity={0.5}/>
                                <stop offset="95%" stopColor={list.color} stopOpacity={0}/>
                            </linearGradient>
                        )}
                    </defs>
                    {!hidearea && !seriesEnable && areaData.map((list, index) =>

                        <Area type="monotone" name={list.label} dataKey={list.label}
                              fillOpacity={areaOpacity}
                              fill={enablegradient ? `url(#colorUv${props.containerData.cid + index})` : list.color}
                              stroke={borderColor ? borderColor : list.color}/>
                    )}
                    {!hidearea && seriesEnable && axisData.map((list, index) =>
                        <Area type="monotone" name={list.label} dataKey={list.label}
                              fillOpacity={areaOpacity}
                              fill={enablegradient ? `url(#colorUv${props.containerData.cid + index})` : list.color}
                              stroke={borderColor ? borderColor : list.color}/>
                    )}

                    {hidearea && !seriesEnable && areaData.map((list, index) =>
                        <Line type="monotone" name={list.label} dataKey={list.label} stroke={list.color} dot={false}/>
                    )}

                    {hidearea && seriesEnable && axisData.map((list, index) =>
                        <Line type="monotone" name={list.label} dataKey={list.label} stroke={list.color}
                              dot={false}  /*label={<CustomizedLabel name={list.label} count={props.containerData.data.length} />}*/ />
                    )}

                    {props.containerData.legend &&
                    <Legend iconType={`circle`} iconSize={8} height={36} layout='vertical' verticalAlign={`middle`}
                            align={`right`}/>
                    }

                </ComposedChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}

export default AreaLineChart;