import React, {Component} from 'react';
import {getProfile} from "../../utilities/AuthService";
import {ES_INDEX, USRIMG_PATH} from '../../constants/index';
import {connect} from "react-redux";
import * as taskActions from "../../actions/ToDo";
import {withRouter} from 'react-router';
import rootActions from "../../actions";
import * as usersActions from "../../actions/Users"
import moment from "moment";
class NotificationPanel extends Component {

    constructor(props) {
        super(props);
        this.state={
            modalStatus:false,
            desc:''
        }
        this.modalChange=this.modalChange.bind(this);
        this.history=this.history.bind(this);
        this.clearAll=this.clearAll.bind(this);
    }



    componentDidMount() {
        let userdata = {
            uid: getProfile().id
    }
        this.props.notificationdata(userdata);
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        var beta = this;
        let userdata = {
            uid: getProfile().id
        }
        if(prevProps.NotificationData.clearNotifications !== this.props.NotificationData.clearNotifications ) {
            setTimeout(function () {
                beta.props.notificationdata(userdata);
                beta.props.notifyClose("notification","");
            }, 500)
        }

    }
    clearAll(){
        let param={
            "index": ES_INDEX,
            "uid": getProfile().id,
        }
        this.props.clearAllNotifications(param)

    }
    history(url, type,typeid){

        if(type === 'todo')
        {
            this.props.notifyClose("notification","");

            this.props.history.push('/task/details/'+btoa(typeid)+'/'+btoa("by-me"))
        }
       else  if(type === 'One to One')
        {
            this.props.notifyClose("notification","");

            this.props.history.push('/chat');
        }

        else if(type === 'Group Chat')
        {
            // this.props.history.push('/task/details/'+btoa(list.typeid)+'/'+btoa("by-me"))
            this.props.notifyClose("notification","");
            this.props.history.push('/chat')

        }
        else{
            this.props.notifyClose("notification","");
            this.props.history.push(url)
        }

    }
    groupEdit(desc,groupid,creator){

        this.setState({desc:desc,modalStatus:true,groupid:groupid,creator:creator})

    }

    modalChange(){
        this.setState({modalStatus:!this.state.modalStatus})
    }
    editprofile=()=>{
        this.setState({modalStatus:!this.state.modalStatus})
    }
    modalChange(){
        this.setState({modalStatus:!this.state.modalStatus})
    }


    render() {
        ////////////console.log(this.props)
        let UserNotfctn = this.props.NotificationData.NotificationInfo;
        //////////console.log(UserNotfctn);
        return (

            <>


                <div className="float-panel-header">
                    <h5 className="subheader">Notifications</h5>
                    <div className="float-panel-header-option">
                        <button className="general-btn btn-sm" onClick={this.clearAll}>Clear all</button>
                        <button className="ico-lib fly-close" onClick={()=>this.props.notifyClose("notification","")}></button>
                    </div>
                </div>
                <div className="float-panel-content-holder">

                    <div className="notify-panel">
                        {UserNotfctn !== undefined && UserNotfctn.map((list,index) =>
                        <>
                                    {list.message === 'task added' &&
                                        <div className={list.status === 'unread' ? "ind-not-panel" : "ind-not-panel viewed"}>
                                        <div  className="ind-not-panel-click" onClick={(e)=>{this.history('/task/details/'+btoa(list.typeid)+'/'+btoa("by-me"), '')}}>
                                            <div className="ind-not-left">
                                            <p className="hide-over"><span className="font-bold">{list.task_creator}</span>
                                                 assigned you a<span className="item-tag task">task</span></p>
                                                <p className="descript-text hide-over">{list.taskinfo.subject ? (atob([list.taskinfo.subject])) : atob([list.taskinfo.title])}</p>
                                            </div>
                                            <div className="ind-not-right">
                                                <p className = "right-date">{moment.utc(list.createdate).format('Do MMM YYYY')} </p>
                                                <p>{moment.utc(list.createdate).local().format('LT')}</p>
                                            </div>
                                        </div>
                                        </div>
                                    }
                                    {list.message === 'task reassigned' &&
                                        <div className={list.status === 'unread' ? "ind-not-panel" : "ind-not-panel viewed"}>
                                                <div  className="ind-not-panel-click" onClick={(e)=>this.history('/task/details/'+btoa(list.typeid)+'/'+btoa("by-me"), '')}>
                                                    <div className="ind-not-left">
                                                <p className="hide-over"><span className="font-bold">{list.task_creator}</span>
                                                    reassigned you a <span className="item-tag task">task</span></p>
                                                        <p className="descript-text hide-over">{list.taskinfo.subject ? (atob(([list.taskinfo.subject]))) : (atob(([list.taskinfo.title])))}</p>
                                                    </div>
                                                    <div className="ind-not-right">
                                                        <p className = "right-date">08-08-2019</p>
                                                        <p>{moment.utc(list.createdate).local().format('LT')}</p>
                                                    </div>
                                                </div>
                                        </div>
                                    }
                                    {list.message === 'task shared' &&
                                        <div className={list.status === 'unread' ? "ind-not-panel" : "ind-not-panel viewed"}>
                                        <div  className="ind-not-panel-click" onClick={(e)=>this.history('/task/details/'+btoa(list.typeid)+'/'+btoa("by-me"), '')}>
                                            <div className="ind-not-left">
                                        <p className="hide-over"><span className="font-bold">{list.task_creator}</span>
                                            shared a <span className="item-tag task">task</span> with you</p>
                                        <p className="descript-text hide-over">{list.taskinfo.subject ? (atob(([list.taskinfo.subject]))) : (atob(([list.taskinfo.title])))}</p>
                                            </div>
                                            <div className="ind-not-right">
                                                <p className = "right-date">{moment.utc(list.createdate).format('Do MMM YYYY')} </p>
                                            <p>{moment.utc(list.createdate).local().format('LT')}</p>
                                            </div>
                                            </div>
                                        </div>
                                    }
                                    {list.message === 'mark as done' &&
                                        <div className={list.status === 'unread' ? "ind-not-panel" : "ind-not-panel viewed"}>
                                        <div  className="ind-not-panel-click" onClick={(e)=>this.history('/task/details/'+btoa(list.typeid)+'/'+btoa("by-me"), '')}>
                                            <div className="ind-not-left">
                                         <p className="hide-over"><div className="small-overflow"><span className="font-bold">{list.task_creator}</span>
                                             marked the task as done</div> <span className="item-tag done">Done</span></p>
                                            <p className="descript-text hide-over">{list.taskinfo.subject ? (atob(([list.taskinfo.subject]))) : (atob(([list.taskinfo.title])))}</p>
                                            </div>
                                            <div className="ind-not-right">
                                                <p className = "right-date">{moment.utc(list.createdate).format('Do MMM YYYY')} </p>
                                            <p>{moment.utc(list.createdate).local().format('LT')}</p>
                                            </div>
                                            </div>
                                        </div>
                                     }
                                     {list.message === 'comment added' &&
                                         <div className={list.status === 'unread' ? "ind-not-panel" : "ind-not-panel viewed"}>
                                         <div  className="ind-not-panel-click" onClick={(e)=>this.history('', list.type,list.typeid)}>
                                             <div className="ind-not-left">
                                            <p className="hide-over"><span className="font-bold">{list.task_creator}</span>
                                                added a comment<span className="item-tag comments">Post</span></p>
                                            <p className="descript-text hide-over">{list.taskinfo.subject ? (atob(([list.taskinfo.subject]))) : (atob([list.taskinfo.title]))}</p>
                                            </div>
                                            <div className="ind-not-right">
                                                <p className = "right-date">{moment.utc(list.createdate).format('Do MMM YYYY')} </p>
                                            <p>{moment.utc(list.createdate).local().format('LT')}</p>
                                            </div>
                                            </div>
                                         </div>
                                     }
                                    {list.message === 'verified the task' &&
                                        <div className={list.status === 'unread' ? "ind-not-panel" : "ind-not-panel viewed"}>
                                        <div  className="ind-not-panel-click" onClick={(e)=>this.history('/task/details/'+btoa(list.typeid)+'/'+btoa("by-me"), '')}>
                                            <div className="ind-not-left">
                                        <p className="hide-over"><span className="font-bold">{list.task_creator}</span>
                                            verified the task<span className="item-tag verify">verified</span></p>
                                            <p className="descript-text hide-over">{list.taskinfo.subject ? (atob(([list.taskinfo.subject]))) : (atob([list.taskinfo.title]))}</p>
                                            </div>
                                            <div className="ind-not-right">
                                                <p className = "right-date">{moment.utc(list.createdate).format('Do MMM YYYY')} </p>
                                            <p>{moment.utc(list.createdate).local().format('LT')}</p>
                                            </div>
                                            </div>
                                        </div>
                                    }
                                    {list.message === 'read' &&
                                        <div className={list.status === 'unread' ? "ind-not-panel" : "ind-not-panel viewed"}>
                                        <div  className="ind-not-panel-click" onClick={(e)=>this.history('/task/details/'+btoa(list.typeid)+'/'+btoa("by-me"), '')}>
                                            <div className="ind-not-left">
                                        <p className="hide-over"><span className="font-bold">{list.task_creator}</span>
                                            read the task<span className="item-tag read">Read</span></p>
                                            <p className="descript-text hide-over">{list.taskinfo.subject ? (atob(([list.taskinfo.subject]))) : atob([list.taskinfo.title])}</p>
                                            </div>
                                            <div className="ind-not-right">
                                                <p className = "right-date">{moment.utc(list.createdate).format('Do MMM YYYY')} </p>
                                            <p>{moment.utc(list.createdate).local().format('LT')}</p>
                                            </div>
                                            </div>
                                        </div>
                                    }
                                    {list.message === '' &&
                                        <div className={list.status === 'unread' ? "ind-not-panel" : "ind-not-panel viewed"}>
                                        <div  className="ind-not-panel-click" >
                                            <div className="ind-not-left">
                                        <p className="hide-over"><span className="font-bold">{list.task_creator}</span></p>
                                            <p className="descript-text hide-over">{list.taskinfo.subject ? (atob(([list.taskinfo.subject]))) : (atob([list.taskinfo.title]))}</p>
                                            </div>
                                            <div className="ind-not-right">
                                                <p className = "right-date">{moment.utc(list.createdate).format('Do MMM YYYY')} </p>
                                            <p>{moment.utc(list.createdate).local().format('LT')}</p>
                                            </div>
                                            </div>
                                        </div>
                                    }
                      </>
                        )}

                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProps = state => ({
    NotificationData:state.UsersReducer.notificationInfodata,
    menuClickData:state.CommonReducer.menuClick,
    sidebarmenudata: state.TaskReducer.SidebarMenudata,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    projectData:taskActions.getProjectData,
    notificationdata:usersActions.notificationInfo,
    clearAllNotifications:usersActions.clearNotifications
})(NotificationPanel));



