import React, {Component} from 'react';
import {workflow} from "../../services/workflow.services";
import OutsideAlerter from "./OutsideAlerter";
import {getProfile} from "../../utilities/AuthService";
import AutoComplete from "./AutoComplete";
import DateTimePickerBox from "./DateTimePickerBox";
import moment from "moment";
import {extractString, includeString, compareArrays} from "../../utilities/CustomFunctions"
import AlertModal from "./AlertModal";
import LoaderSvg from "./LoaderSVG";
import {Tabs, Tab, Nav, Col, Row} from 'react-bootstrap'
import {Scrollbars} from "react-custom-scrollbars";
import NoData from "./NoData";
import Mentions from "./Mentions";
let initialElements =
    [
        {
            "type":"text",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "name":"equal",
                    "value":"equal"
                },
                {
                    "name":"contain",
                    "value":"contain"
                }
            ],
            "placeholder":"Enter Text",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"equal",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"textarea",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "name":"equal",
                    "value":"equal"
                },
                {
                    "name":"contain",
                    "value":"contain"
                }
            ],
            "placeholder":"Enter Text",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"equal",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"number",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "value":"greater",
                    "name":"Greater than ( > )"
                },
                {
                    "value":"greaterequal",
                    "name":"Greater than or equal ( >= )"
                },
                {
                    "value":"equal",
                    "name":"Equal ( = )"
                },
                {
                    "value":"less",
                    "name":"Less than ( < )"
                },
                {
                    "value":"lessequal",
                    "name":"Less than or equal ( <= )"
                }
            ],
            "placeholder":"Enter no of days",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"greater",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"computedfield",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "value":"greater",
                    "name":"Greater than ( > )"
                },
                {
                    "value":"greaterequal",
                    "name":"Greater than or equal ( >= )"
                },
                {
                    "value":"equal",
                    "name":"Equal ( = )"
                },
                {
                    "value":"less",
                    "name":"Less than ( < )"
                },
                {
                    "value":"lessequal",
                    "name":"Less than or equal ( <= )"
                }
            ],
            "placeholder":"Enter no of days",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"greater",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"email",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "name":"equal",
                    "value":"equal"
                },
                {
                    "name":"contain",
                    "value":"contain"
                }
            ],
            "placeholder":"Enter Text",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"equal",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"dropdown",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"checkbox",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"switch",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"",
                        "value":""
                    }
                ]
            }
        },
        {
            "type":"datepicker",
            "option":[
                {
                    "name":"Select",
                    "value":""
                },
                {
                    "name":"on",
                    "value":"On"
                },
                {
                    "name":"before",
                    "value":"Before"
                },
                {
                    "name":"after",
                    "value":"After"
                }
            ],
            "placeholder":"Enter no of days",
            "fieldItem":{
                "field":"",
                "field_rule":[
                    {
                        "trigger":"on",
                        "value":""
                    }
                ]
            }
        }
    ]

class RuleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formPages : [],
            showlistField:[],
            showFieldList:[],
            showSave: true,
            tab: 'fieldwise',
            tabAdd: 'fieldwise',
            action: "fieldwise",
            actionStatus: false,
            ruleData: [],
            generic:false,
            fieldwise:false,
            workflow:false,
            workflowstatus:false,
            genericRule:false,
            fieldwiseRule:false,
            listRule:{generic:[],fieldwise:[]},
            showDeleteGeneric:false,
            removeWorkflowTest:false,
            multiCondition:false,
            mode:"save",
            child:"",
            workflowid:'',
            workflowindex:'',
            stageList:[],
            workflowids:'',
            workflowEditId:'',
            workflowNames:'',
            errorMsg:'',
            workflowtriggeron:'save',
            addStatus:'Save',
            editData:{
                model: "",
                fieldItem: [],
                type: "",
                rule_type: "",
                referenceId: "",
                pattern: [],
                patternItem:[],
                workflowList:[],
                startDate: "",
                endDate: "",
                masterid: "",
                ruleDescription: "",
                ruletitle: "",
                ruleEmailsubject: "",
                external_to: "",
                external_cc: "",
                mailSubject: "",
                mailcontent: "",
                reference: "",
                users: [],
                cc: [],
                priority: "",
                status: "",
                creator: "",
                createdate: "",
                id: "",
                indvidualtask:false,
                has_externalmail:false,
                triggeron:"save",
                customapi:'',
                customapimethod:"get",
                customapiparam: [{
                    param:"",
                    value:"",
                }],
                customapi_type : "internal",
                save_response : "no",
                responseparam : [
                    {
                        param:"status_field",
                        value:"",
                    },
                    {
                        param:"message_field",
                        value:"",
                    },
                    {
                        param:"response_field",
                        value:"",
                    }
                ],
                success_response : "Success",
                fail_response : "Failure"

            },
            genericChild:'',
            fieldwiseChild:'',
            workflowChild:'',
            errors:{
                startDate:false,
                endDate:false,
                ruleDescription:false,
                rule_type:false,
                ruletitle:false,
                ruleEmailsubject:false,
                external_to: false,
                external_cc: false,
                mailSubject:false,
                mailcontent:false,
                patternBox:false,
                customapi:false,
                type:false,
                priority:false
            },
            options:[],
            userList: this.props.userList||[],
            paramArray:[{
                param:"",
                value:"",
            }],

        }
    }
    componentDidMount() {
        this.setState({listRule:this.props.listRule, workflowList:this.props.getWorkflow.workflow})
        if(this.props.source === "batch" ){
            this.setState({tab: "fieldwise",action:"fieldwise"})
        }
        this.labelData()
        let param = {
            "uid": getProfile().id
        }
        this.props.listPool(param)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.listRule !== prevProps.listRule){
            this.setState({listRule:this.props.listRule})
        }
        if(this.props.label.showformdata !== prevProps.label.showformdata){
            this.labelData()
        }
        if(this.props.getWorkflow !== prevProps.getWorkflow){
            this.setState({workflowList:this.props.getWorkflow.workflow})
        }
        if(this.props.externalData !== prevProps.externalData) {
            if (this.props.label.showformdata.length !== 0) {
                let showlistField=[]
                let formPages = this.state.formPages;
                formPages.map(page => page.components.filter(comp => (comp.externallink && comp.externallink === 'Yes' && (parseInt(comp.masterid) === parseInt(this.props.externalData.externalFields.masterField) || parseInt(comp.referenceId) === parseInt(this.props.externalData.externalFields.masterField)))).map(item => {
                    let externalValues = this.props.externalData.externalFields.values;
                    item.externalValues = externalValues;
                    let collection = item.externalfield[0].collection;
                    let optArray = [];
                    if (Array.isArray(externalValues)) {
                        optArray = externalValues.map(external => {
                            external.value[0]= external.value[0].value;
                            // external.value[0]['displayvalue'] = '';
                            // external.value[0]['additionaltext'] = '';
                            if (external.displayvalue !== false && external.displayvalue !== '' && external.displayvalue !== null) {
                                if (item.type === 'checkbox' || item.type === 'switch' || item.type === 'dropdown') {
                                    external.value[0] += ", " + external.displayvalue;
                                }
                                // else if (item.type === 'autofill') {
                                //     external.value[0]['displayvalue'] = external.displayvalue;
                                //     external.value[0]['autofill'] = external.autofill;
                                // }

                            }

                            // if (external.additionaltext !== false && external.additionaltext !== '' && external.additionaltext !== null && item.type === 'autofill') {
                            //     external.value[0]['additionaltext'] = external.additionaltext;
                            //
                            // }

                            // external.value[0]['recordid'] = external.recordid;
                            // external.value[0]['collection'] = collection.toString();
                            // external.value[0]['fieldid'] = external.fieldid;
                            return external.value;
                        });
                    }

                    let optValues = [].concat.apply([], optArray);

                    item.option = optValues;

                    return item;
                }));
                for (let i = 0; i < formPages.length; i++) {
                    (formPages[i].components.filter(item => item.visibility !== "hidden")).map(item => {
                        showlistField.push(item)
                    })
                }
                this.setState({showlistField: showlistField, formPages: formPages});
            }
        }
    }


    clearstate(){

        this.setState({
            editData:{
                model: "",
                fieldItem: [],
                type: "",
                rule_type: "",
                referenceId: "",
                pattern: [],
                patternItem:[],
                startDate: "",
                endDate: "",
                masterid: "",
                ruleDescription: "",
                ruletitle: "",
                ruleEmailsubject: "",
                external_to: "",
                external_cc: "",
                mailSubject: "",
                mailcontent: "",
                reference: "",
                users: [],
                cc: [],
                priority: "",
                status: "",
                creator: "",
                createdate: "",
                id: "",
                child:"",
                indvidualtask:false,
                has_externalmail:false,
                triggeron:"save",
                customapi:'',
                customapimethod:"get",
                customapiparam: [{
                    param:"",
                    value:"",
                }],
                customapi_type : "internal",
                save_response : "no",
                responseparam : [
                    {
                        param:"status_field",
                        value:"",
                    },
                    {
                        param:"message_field",
                        value:"",
                    },
                    {
                        param:"response_field",
                        value:"",
                    }
                ],
                success_response : "Success",
                fail_response : "Failure"

            },
            errors:{
                startDate:false,
                endDate:false,
                ruleDescription:false,
                rule_type:false,
                ruletitle:false,
                ruleEmailsubject:false,
                external_to: false,
                external_cc: false,
                mailSubject:false,
                mailcontent:false,
                patternBox:false,
                customapi:false,
                type:false,
                priority:false

            },
            initialElements:initialElements
        })

        this.props.ruleClose('clearUser')
    }
    labelData=()=>{
        let showlistField=[]
        let showFieldList=[{
            label:"_id",
            type:"_id",
            masterid:"101",
            },
            {
            label:"_uid",
            type:"_uid",
            masterid:"102",
            },
            {
            label:"_metaid",
            type:"_metaid",
            masterid:"103",
            }]
        let showEmailList=[]
        if (this.props.label.showformdata.length !== 0) {
            let formPages = this.props.label.showformdata.pages;
            for (let i = 0; i < this.props.label.showformdata.pages.length; i++) {
                (this.props.label.showformdata.pages[i].components.filter(item => item.visibility !== "hidden")).map(item => {
                    if(item.externallink && item.externallink === 'Yes' ) {
                        let searchtext = item.searchtext ? item.searchtext : '';
                        this.getExternalValues(item.masterid, item.externalfield, searchtext)
                        if(item.type==='dropdown' || item.type === 'checkbox'  || item.type=== 'check_image') {
                            item.multipleValues = "Yes";
                        }

                    }
                    showlistField.push(item)
                })
            }
            for (let i = 0; i < this.props.label.showformdata.pages.length; i++) {
                this.props.label.showformdata.pages[i].components.map(list => {
                    showFieldList.push(list)
                })
            }
            if(showFieldList.length !== 0){
                // for (let i = 0; i < showFieldList.length; i++) {
                showFieldList.filter(list => list.type === "email").map(list => {
                    showEmailList.push(list)
                })
                // }
            }

            this.setState({showlistField:showlistField,showFieldList:showFieldList,showEmailList:showEmailList, formPages: formPages})
        }
    }
    getExternalValues = (masterid, externals, search = '') => {
        //console.log(masterid)

            let collectionIds = [];
            let fieldIds = [];
            let displayFieldIds = [];
            let additionalvaluesIds = [];
            let filterFields = [];
            if(externals.length > 0) {
                for(var i=0; i<externals.length ; i++) {
                    collectionIds.push(externals[i].collection);
                    fieldIds.push(externals[i].field);
                    if(externals[i].display && externals[i].display.length > 0) {
                        displayFieldIds = displayFieldIds.concat(externals[i].display);
                    }
                    if(externals[i].displayvalue && externals[i].displayvalue.length > 0) {
                        additionalvaluesIds = additionalvaluesIds.concat(externals[i].displayvalue);
                    }
                    let filterField = {};
                    if(externals[i].filter && externals[i].filter.length > 0) {
                        filterField.filters=[];
                        filterField.collection=externals[i].collection;

                        externals[i].filter.map((field, fieldIndex) =>{
                            var item = {
                                field: field.filterfield,
                                type:field.type,
                                values:field.filtervalues
                            }
                            filterField.filters.push(item);
                        })
                        filterFields.push(filterField);
                    }
                }
            }

            let displayfields = "";
            let additionalvalues = "";
            let filterfieldValues = filterFields;
            if (displayFieldIds.length > 0) {
                displayfields = displayFieldIds.toString();
            }
            if (additionalvaluesIds.length > 0) {
                additionalvalues = additionalvaluesIds.toString();
            }

            let data = {
                masterid: masterid,
                collection: collectionIds.toString(),
                field: fieldIds.toString(),
                displayfields: displayfields,
                additionalvalues: additionalvalues,
                filterfield: filterfieldValues,
                search : search,
                size : 500
            };

            this.props.getExternalValues(data);


    }
    tabSelect = (tabs) => {
        this.setState({tab: tabs,action:tabs,tabAdd:tabs})
        if(tabs === 'workflow'){
            let workflowList = this.props.getWorkflow.workflow.length !== 0 ? this.props.getWorkflow.workflow:[];
            let savedWorkflow = this.props.linkedWorkflow.workflow.length !== 0 ? this.props.linkedWorkflow.workflow:[];
            let workflowData = [];
            if(workflowList.length !== 0 && savedWorkflow.length !== 0){
                var matches = false;
                for ( var i = 0; i < workflowList.length; i++ ) {
                    matches = false;
                    for ( var e = 0; e < savedWorkflow.length; e++ ) {
                        if ( workflowList[i].id === savedWorkflow[e].workflowid ) matches = true;
                    }
                    if(!matches) workflowData.push( workflowList[i] );
                }
            }
            this.setState({workflowList: workflowData.length !== 0 ? workflowData : workflowList})
        }
    }
    tabSelectAdd = (tabs) => {
        this.setState({tabAdd: tabs, tab: tabs})
        if(tabs === 'workflow'){
            let workflowList = this.props.getWorkflow.workflow.length !== 0 ? this.props.getWorkflow.workflow:[];
            let savedWorkflow = this.props.linkedWorkflow.workflow.length !== 0 ? this.props.linkedWorkflow.workflow:[];
            let workflowData = [];
            if(workflowList.length !== 0 && savedWorkflow.length !== 0){
                var matches = false;
                for ( var i = 0; i < workflowList.length; i++ ) {
                    matches = false;
                    for ( var e = 0; e < savedWorkflow.length; e++ ) {
                        if ( workflowList[i].id === savedWorkflow[e].workflowid ) matches = true;
                    }
                    if(!matches) workflowData.push( workflowList[i] );
                }
            }
            this.setState({workflowList: workflowData.length !== 0 ? workflowData : workflowList})
        }
    }
    triggeronSelect = (val) => {
        let newData=this.state.editData
        newData['triggeron'] = val;
        newData["patternformat"]=newData.pattern;
        this.setState({editData: newData})
    }
    conditionSelect = (val) => {
        let newData=this.state.editData;
        let ruleData = this.state.ruleData;
        newData['multicondition'] = val;
        newData["patternformat"]=newData.pattern;
        if(val === true){
                ruleData = [{
                    "trigger": "save",
                    "stage": "",
                    "condition": []
                }]
        }
        else{
                ruleData = []
        }
        this.setState({editData: newData, multiCondition:val, ruleData: ruleData})
    }
    workflowtriggeronSelect = (val) => {
        let workflowtriggeron = val;
        this.setState({workflowtriggeron: workflowtriggeron, tab: "workflow"})
    }
    workflowtriggeronSelectMultiple = (val, index) => {
        let ruleData = this.state.ruleData;
        ruleData[index]['trigger'] = val;
        this.setState({ruleData: ruleData, tab: "workflow"})
    }
    setTab = (act,sTs) => {
        if(act === "generic"){
            if(sTs === "new" || sTs === "cancel"){
                this.setState({genericChild:''})
            }
            this.setState({action: act, generic: !this.state.generic,ruleData:[],fieldwise:'',workflow:''})
            this.clearstate()
        }
        else if(act === "fieldwise"){
            if(sTs === "new" || sTs === "cancel"){
                this.setState({fieldwiseChild:'', tabAdd: 'fieldwise'})
            }
            this.setState({action: act, tab: act, tabAdd: 'fieldwise',addStatus:'Save', fieldwise: !this.state.fieldwise,ruleData:[],generic:'',workflow:''})
            this.clearstate()
        }
        else if(act === "workflow"){
            if(sTs === "new" || sTs === "cancel"){
                this.setState({tabAdd: 'workflow', workflowNames:'', workflowids:'', workflowChild:'', workflowEditId:'', workflowtriggeron:'save',multiCondition:false, stageList:[]})
            }
            if(act === 'workflow'){
                let workflowList = this.props.getWorkflow.workflow.length !== 0 ? this.props.getWorkflow.workflow:[];
                let savedWorkflow = this.props.linkedWorkflow.workflow.length !== 0 ? this.props.linkedWorkflow.workflow:[];
                let workflowData = [];
                if(workflowList.length !== 0 && savedWorkflow.length !== 0){
                    var matches = false;
                    for ( var i = 0; i < workflowList.length; i++ ) {
                        matches = false;
                        for ( var e = 0; e < savedWorkflow.length; e++ ) {
                            if ( workflowList[i].id === savedWorkflow[e].workflowid ) matches = true;
                        }
                        if(!matches) workflowData.push( workflowList[i] );
                    }
                }
                this.setState({workflowList: workflowData.length !== 0 ? workflowData : workflowList})
            }
            this.setState({action: act, workflow: !this.state.workflow, fieldwise: !this.state.fieldwise,ruleData:[],generic:'',multiCondition:false, stageList:[]})
            this.clearstate()
        }
        else if(act === "dropdownrule"){
            this.setState({dropdownrule:act})
        }
        else{
            this.setState({dropdownrule:act})
        }
    }
    handleChange(e, label, type, opt,masterid) {
        let random = Math.round(new Date().getTime() + (Math.random() * 100));
        var h=[]
        h=[...this.state.initialElements]
        let d=Array.from(h.filter(list => (list.type === type)))
        let deepCopyObj = JSON.parse(JSON.stringify(d));
        let mapData = deepCopyObj
        if(mapData.length !== 0){
            mapData[0][`name`] = "name-" + random
            mapData[0][`masterid`] = masterid.toString()
            mapData[0][`label`] = label
            mapData[0][`opt`] = opt
            let Ruledata = [...this.state.ruleData,mapData[0]]
            this.setState({ruleData: Ruledata,dropdownrule:''})
        }
        this.setTab("")
    }
    handleChangeMultipleWorkflow(e, label, type, opt,masterid, index) {
        let random = Math.round(new Date().getTime() + (Math.random() * 100));
        var h=[]
        h=[...this.state.initialElements]
        let d=Array.from(h.filter(list => (list.type === type)))
        let deepCopyObj = JSON.parse(JSON.stringify(d));
        let mapData = deepCopyObj
        if(mapData.length !== 0){
            mapData[0][`name`] = "name-" + random
            mapData[0][`masterid`] = masterid.toString()
            mapData[0][`label`] = label
            mapData[0][`opt`] = opt
            let Ruledata = [...this.state.ruleData]
            Ruledata[index].condition = [...this.state.ruleData[index].condition,mapData[0]]
            this.setState({ruleData: Ruledata,dropdownrule:''})
        }
        this.setTab("")
    }
    removeFromWorkflow = (e, name, index) => {
        this.setState({removeWorkflowTest : !this.state.removeWorkflowTest, workflowname : name, workflowindex : index})
    }
    handleRemove = () => {
        let name = this.state.workflowname;
        let index = this.state.workflowindex;
        let Rdata = this.state.ruleData;
        if(this.state.multiCondition === true){
            Rdata[index].condition = this.state.ruleData[index].condition.filter(list => list.name !== name)
        }
        else{
            Rdata = this.state.ruleData.filter(list => list.name !== name);
        }
        this.setState({ruleData: Rdata})
        this.removeWorkflowConfirm();
    }
    removeWorkflowConfirm = () => {
        this.setState({removeWorkflowTest : false})
    }
    actionStaus=(type,status,id)=>{
        if(type === "workflow"){
            this.setState({workflowstatus:status,id:id})
        }
        if(type === "genericRule"){
            this.setState({genericRule:status,id:id})
        }
        if(type === "fieldwiseRule"){
            this.setState({fieldwiseRule:status,id:id, tabAdd:'fieldwise'})
        }
    }
    deleteFromGeneric=(wid,type)=>{
        this.setState({showDeleteGeneric : !this.state.showDeleteGeneric, wid : wid, type : type})
    }
    editFromGeneric=(id,type)=>{
        // console.log(this.props.linkedWorkflow.workflow.filter(list=>list._id === id))
    }
    handleDelete=()=>{
        let wid = this.state.wid;
        let type = this.state.type;
        if(type  ==="workflow") {
            let deleteWorkflow = {
                "_id": wid,// linking id
                "uid": getProfile().id, //uid
                "secretKey": "lsuser12345!",// use value lsuser12345!
            }
            this.props.deleteWorkflow(deleteWorkflow,type)
        }
        if(type  ==="genericRule" ||type  ==="fieldwiseRule" ) {

            this.props.deleteWorkflow(wid,type)
        }
        this.showDeleteConfirm();
    }
    showDeleteConfirm=()=>{
        this.setState({showDeleteGeneric : false});
    }
    addGeneralRule=(e)=> {
        e.preventDefault();
        let errors = this.state.errors;
        errors.startDate = this.state.editData.startDate === ""
        errors.endDate = this.state.editData.endDate === ""
        errors.rule_type = this.state.editData.rule_type === ""
        errors.type = this.state.editData.type === ""
        if(this.state.editData.rule_type !== 'customapi' && this.state.editData.rule_type !== 'autoassign' && this.state.tab === "fieldwise") {
            errors.priority = this.state.editData.priority === ""
        }
        if(this.state.editData.rule_type !== 'customapi' && this.state.editData.rule_type !== 'autoassign' && this.state.tab === "fieldwise") {
            errors.ruleDescription = this.state.editData.ruleDescription === ""
        }
        // if(this.state.editData.rule_type === 'autoassign' && this.state.tab === "fieldwise" && this.state.editData.assigned_email === "yes") {
        //     errors.ruleEmailsubject = this.state.editData.ruleEmailsubject === ""
        //     errors.mailcontent = this.state.editData.mailcontent === ""
        // }
        if(this.state.editData.startDate === '' && this.state.tab !== "workflow") {
            document.getElementById('startDate').classList.add('val-error');
        }
        if(this.state.editData.endDate === '' && this.state.tab !== "workflow") {
            document.getElementById('endDate').classList.add('val-error');
        }
        if(this.state.editData.rule_type === '' && this.state.tab !== "workflow") {
            document.getElementById('rule_type').classList.add('val-error');
        }
        if(this.state.editData.type === '' && this.state.tab !== "workflow") {
            document.getElementById('type').classList.add('val-error');
        }
        if(this.state.editData.priority === '' && this.state.tab !== "workflow" && this.state.editData.rule_type !== 'customapi' && this.state.editData.rule_type !== 'autoassign' && this.state.tab === "fieldwise") {
            document.getElementById('priority').classList.add('val-error');
        }

        if(this.state.tab !== "workflow"){
            if(this.props.selectedfieldUsers.length === 0 && this.state.editData.has_externalmail === 'false' && this.state.editData.rule_type !== 'customapi') {
                document.getElementById('selectedfieldUsers').classList.add('val-error');
            }
            else {
                if(document.getElementById('selectedfieldUsers') !== null){
                    document.getElementById('selectedfieldUsers').classList.remove('val-error');
                }
            }
        }

        if(this.state.editData.rule_type === 'customapi' && this.state.tab === "fieldwise") {
            errors.customapi = this.state.editData.customapi === ""
        }

        // if(this.state.editData.rule_type === 'autoassign' && this.state.tab === "fieldwise") {
        //     errors.autoselectfield = this.state.editData.autoselectfield === ""
        //     if(this.state.editData.autoselectfield !== ""){
        //         errors.autoselectvalue = this.state.editData.autoselectvalue === ""
        //     }
        // }
        if(this.state.editData.startDate !== '' && this.state.editData.endDate !== '' && this.state.tab !== "workflow") {
            if ((moment(this.state.editData.endDate).isBefore(this.state.editData.startDate)) === true) {
                document.getElementById('startDate').classList.add('val-error');
                document.getElementById('endDate').classList.add('val-error');
                errors.startDate = true
                errors.endDate = true
                this.setState({errorMsg:"The end date must be greater than the start date"})
            } else {
                document.getElementById('startDate').classList.remove('val-error');
                document.getElementById('endDate').classList.remove('val-error');
                errors.startDate = false
                errors.endDate = false
                this.setState({errorMsg:""})
            }
        }


        this.setState({errors})

        if (!errors.startDate && !errors.endDate && !errors.ruleDescription && !errors.rule_type  && !errors.type  && !errors.priority && ((this.props.selectedfieldUsers.length !== 0 && (this.state.editData.has_externalmail === 'false' || this.state.editData.has_externalmail === false)) || this.state.editData.has_externalmail === 'true' ||  this.state.editData.rule_type === 'customapi') ) {
            if (this.state.tab !== "generic") {
                let editData = this.state.editData
                let fieldItem=[]
                this.state.ruleData.map(list=>{
                    fieldItem.push(list.fieldItem)
                })
                editData['fieldItem'] = fieldItem
            }
            if ((this.state.tab === "generic") || (this.state.tab === "fieldwise")) {
                let editData = {...this.state.editData}
                editData['patternItem'] = this.state.editData.patternformat
                editData['pattern'] = this.state.editData.type
                if(editData.ruleEmailsubject !== '' && editData.rule_type === 'autoassign' && editData.assigned_email === "yes") {
                    editData['ruleEmailsubject'] = extractString(editData.ruleEmailsubject,"{","}");
                }
                if(editData.mailcontent !== '' && editData.rule_type === 'autoassign' && editData.assigned_email === "yes") {
                    editData['mailcontent'] = extractString(editData.mailcontent,"{","}");
                }

                if(editData.ruleDescription !== '' && editData.rule_type === 'email') {
                    editData['ruleDescription'] = extractString(editData.ruleDescription,"{","}");
                    editData['ruleEmailsubject'] = extractString(editData.ruleEmailsubject,"{","}");
                }
                if(editData.external_to !== '' && editData.rule_type === 'email' && editData.has_externalmail === 'true') {
                    editData['external_to'] = extractString(editData.external_to,"{","}");
                }
                if(editData.external_cc !== '' && editData.rule_type === 'email' && editData.has_externalmail === 'true') {
                    editData['external_cc'] = extractString(editData.external_cc,"{","}");
                }

                if(editData.rule_type === 'customapi'){
                    let customapiparam = editData.customapiparam;
                    customapiparam.length !== 0 && customapiparam.map((list, index) => {
                        if(list.value !== ''){
                            list.value = extractString(list.value,"{","}");
                        }
                    })
                    editData['customapiparam'] = customapiparam;

                    let responseparam = editData.responseparam;
                    responseparam.length !== 0 && responseparam.map((list, index) => {
                        if(list.value !== ''){
                            list.value = extractString(list.value,"{","}");
                        }
                    })
                    editData['responseparam'] = responseparam;
                    editData['customapi_type'] = editData.customapi_type;
                }
                let child=""
                let mode="save"
                // if(this.state.tab === "generic"){
                //     let genericData = []
                //     this.props.selectedDescriptionUsers.map((entry, index) => {
                //         genericData[index] = {};
                //         genericData[index]['id'] = entry.user_id;
                //         genericData[index]['type'] = entry.user_type;
                //         genericData[index]['name'] = entry.fullname;
                //     })
                //     editData['users'] = genericData
                //
                //     child=this.state.genericChild
                //     if(child === "") {
                //         mode = "save"
                //     }
                //     else{
                //         mode = "update"
                //     }
                //     this.setState({editData,genericChild:''})
                // }
                if(this.state.tab === "fieldwise"){
                    let fieldwiseData = []
                    this.props.selectedfieldUsers.map((entry, index) => {
                        fieldwiseData[index] = {};
                        fieldwiseData[index]['id'] = entry.user_id;
                        fieldwiseData[index]['type'] = entry.user_type;
                        fieldwiseData[index]['name'] = entry.fullname;
                    })
                    editData['users'] = fieldwiseData

                    let fieldwiseDataCc = []
                    this.props.selectedfieldUsersCc.map((entry, index) => {
                        fieldwiseDataCc[index] = {};
                        fieldwiseDataCc[index]['id'] = entry.user_id;
                        fieldwiseDataCc[index]['type'] = entry.user_type;
                        fieldwiseDataCc[index]['name'] = entry.fullname;
                    })
                    editData['cc'] = fieldwiseDataCc
                    child=this.state.fieldwiseChild
                    if(child === "") {
                        mode = "save"
                    }
                    else{
                        mode = "update"
                    }
                    this.setState({editData,fieldwiseData:""})
                }

                /*Api call */
                let generalRule = {
                    "masterid": this.props.eid,
                    "reference": this.props.reference,
                    "company": 0,
                    "mode": mode,
                    "child":child,
                    "uid": getProfile().id,
                    "first name": getProfile().first_name,
                    "last name": getProfile().last_name,
                    "middle name": getProfile().middle_name,
                    "img_name": getProfile().image,
                    "email": getProfile().email,
                    "recurrenceValue": [editData]

                }
                this.props.createGenericRule(generalRule)
                this.clearstate()
            }
            if (this.state.tab === "generic") {
                this.setState({generic: !this.state.generic})
            }
            if (this.state.tab === "fieldwise") {
                this.setState({fieldwise: !this.state.fieldwise, action: 'fieldwise'})
            }

        }
        if (this.state.tab === "workflow") {
            let mode="save";
            let child=this.state.workflowChild;
            if(child === "") {
                mode = "save";
            }
            else{
                mode = "update";
            }

            let fieldItem=[];
            let conditions=[];
            if(this.state.multiCondition === true){
                conditions=this.state.ruleData;
                if(conditions.length !== 0){
                    conditions.map(list=>{
                        let condition = [];
                        if(list.condition.length !== 0){
                            list.condition.map(item=>{
                                condition.push(item.fieldItem);
                            })
                        }
                        list.condition = btoa(JSON.stringify(condition))
                    })
                }
            }
            else{
                if(this.state.ruleData.length !== 0){
                    this.state.ruleData.map(list=>{
                        fieldItem.push(list.fieldItem)
                    })
                }
            }

            let param = {
                "_id": mode === 'update' ? this.state.workflowEditId : "", //Edit id
                "collectionid": this.props.eid,
                "workflowid": this.state.workflowids,
                "fielddata": this.state.ruleData.length !== 0 ? btoa(JSON.stringify(fieldItem)) : btoa(JSON.stringify(this.state.ruleData)),
                "type": fieldItem.length !== 0 ? "fieldwise" : "generic",
                "uid": getProfile().id,
                "mode": mode,
                "triggeron":this.state.workflowtriggeron,
                "multicondition":this.state.multiCondition,
                "inputform":this.props.reference,
                "conditions":conditions
            }
            this.props.saveWorkflowrule(param)
            this.clearstate()

            // if (this.state.tab === "workflow") {
            //     this.setState({workflow: !this.state.workflow})
            // }
            if (this.state.tab === "workflow") {
                this.setState({fieldwise: !this.state.fieldwise, action: 'workflow'})
            }
        }
    }
    handleEdit=(id,type, workflowNames, workflowid)=>{
        let genericData = []
        let genericDataCc = []

        if(type === "fieldwiseRule"){
            this.props.getRule()
            this.setState({mode:"update",fieldwiseChild:id})
            this.setTab("fieldwise")
            let editData=(this.state.listRule.fieldwise.filter(list=>list.id === id)[0])
            editData['pattern']= editData.pattern === false || editData.pattern.length === 0 ?[] :editData.pattern.split(",");
            editData['triggeron']= editData.triggeron ? editData.triggeron : "save";
            if(this.state.showFieldList.length !== 0){
                let userMentionData= {};
                this.state.showFieldList.map((myUser,index)=> {
                    userMentionData[myUser.masterid] = myUser.label
                })
                editData['ruleEmailsubject']= editData.mailSubject === false ? '' :includeString(userMentionData, editData.mailSubject,"{","}");
                editData['mailcontent']= editData.mailcontent === false ? '' :includeString(userMentionData, editData.mailcontent,"{","}");
                if(editData.rule_type === 'email'){
                    editData['ruleDescription']= editData.ruleDescription === false ? '' :includeString(userMentionData, editData.ruleDescription,"{","}");
                    editData['external_to']= editData.external_to === false ? '' :includeString(userMentionData, editData.external_to,"{","}");
                    editData['external_cc']= editData.external_cc === false ? '' :includeString(userMentionData, editData.external_cc,"{","}");
                }
                if(editData.rule_type === 'customapi'){
                    let customapiparam = editData.customapiparam && editData.customapiparam.length !== 0 ? editData.customapiparam : this.state.paramArray;
                    console.log(customapiparam)

                    customapiparam.length !== 0 && customapiparam.map((list, index) => {
                        if(list.value !== ''){
                            list.value = includeString(userMentionData, list.value,"{","}");
                        }
                    })
                    editData['customapiparam'] = customapiparam;

                    let responseparam = editData.responseparam && editData.responseparam.length !== 0 ? editData.responseparam : this.state.paramArray;
                    console.log(responseparam)

                    responseparam.length !== 0 && responseparam.map((list, index) => {
                        if(list.value !== ''){
                            list.value = includeString(userMentionData, list.value,"{","}");
                        }
                    })
                    editData['responseparam'] = responseparam;
                }
            }

           this.setState({editData})
            this.setState({addStatus:"Update"})
            let dataFileds=[]
            editData.fieldItem !== null && editData.fieldItem.length !== 0 && editData.fieldItem.map((list,index)=> {
                let d=(this.state.showlistField.filter(item => item.masterid === list.field)[0])
                let deepCopyObj = JSON.parse(JSON.stringify(d));

                dataFileds.push(deepCopyObj)

                dataFileds[index]['fieldItem']= editData.fieldItem[index]
                dataFileds[index]['opt']=dataFileds[index].option
                let mapData = initialElements.filter(item => (item.type ===dataFileds[index].type))
                if(dataFileds[index].type !=="switch") {
                    dataFileds[index]['option'] = mapData[0].option
                }
            })
            this.setState({ruleData:dataFileds})
            editData.users && editData.users.map((entry, index) => {
                genericData[index] = {};
                genericData[index]['user_id'] = entry.id;
                genericData[index]['user_type'] = entry.type;
                genericData[index]['fullname'] = entry.name;
            })
            this.props.resultDataList(genericData, 'selectedfieldUsers', 'user_id')

            editData.users_cc && editData.users_cc.map((entry, index) => {
                genericDataCc[index] = {};
                genericDataCc[index]['user_id'] = entry.id;
                genericDataCc[index]['user_type'] = entry.type;
                genericDataCc[index]['fullname'] = entry.name;
            })
            this.props.resultDataList(genericDataCc, 'selectedfieldUsersCc', 'user_id')
        }

        if(type === "workflow"){
            this.setState({mode:"update",workflowChild:id})
            this.setTab("workflow")
            let selectedWorkflow = this.props.getWorkflow.workflow.filter(list=>list.id === workflowid)
            let stageList = selectedWorkflow.length !== 0 && selectedWorkflow[0].stage_info ? selectedWorkflow[0].stage_info : [];
            let editData=(this.props.linkedWorkflow.workflow.filter(list=>list._id === id)[0])
         //   editData['pattern']= editData.pattern === false ?[] :editData.pattern.split(",")
           this.setState({editData})
            let workflowtriggeron = editData.triggeron ? editData.triggeron : 'save';
            let multiCondition = editData.multicondition ? editData.multicondition : false;
            this.setState({addStatus:"Update", tabAdd: 'workflow', workflowNames:workflowNames, workflowids: workflowid, workflowtriggeron: workflowtriggeron, workflowEditId: id, stageList:stageList})
            let dataFileds=[];
            let dataFields=[];
            if(multiCondition === true){
                dataFields = editData.conditions;
                if(dataFields.length !== 0){
                    dataFields.map(item=>{
                        dataFileds=[];
                        let fieldata = !Array.isArray(item.condition) ? atob(item.condition) : '';
                        if(fieldata && fieldata !== ''){
                            let fieldItem=JSON.parse(fieldata)
                            fieldItem.map((list,index)=> {
                                if(list.field !== '') {
                                    let d = (this.state.showlistField.filter(item => item.masterid === list.field)[0])
                                    let deepCopyObj = JSON.parse(JSON.stringify(d));
                                    dataFileds.push(deepCopyObj)
                                    dataFileds[index]['fieldItem'] = fieldItem[index]
                                    dataFileds[index]['opt'] = dataFileds[index].option
                                    let mapData = initialElements.filter(item => (item.type === dataFileds[index].type))
                                    if (dataFileds[index].type !== "switch") {
                                        dataFileds[index]['option'] = mapData[0].option
                                    }
                                }
                            })
                        }
                        item.condition = dataFileds;
                    })
                }
                this.setState({ruleData:dataFields, multiCondition:multiCondition})
            }
            else{
                let fieldata = atob(editData.fielddata);
                if(fieldata){
                    let fieldItem=JSON.parse(fieldata)
                    fieldItem.map((list,index)=> {
                        if(list.field !== '') {
                            let d = (this.state.showlistField.filter(item => item.masterid === list.field)[0])
                            let deepCopyObj = JSON.parse(JSON.stringify(d));
                            dataFileds.push(deepCopyObj)
                            dataFileds[index]['fieldItem'] = fieldItem[index]
                            dataFileds[index]['opt'] = dataFileds[index].option
                            let mapData = initialElements.filter(item => (item.type === dataFileds[index].type))
                            if (dataFileds[index].type !== "switch") {
                                dataFileds[index]['option'] = mapData[0].option
                            }
                        }
                    })
                }
                this.setState({ruleData:dataFileds, multiCondition:multiCondition})
            }
            // editData.users.map((entry, index) => {
            //     genericData[index] = {};
            //     genericData[index]['user_id'] = entry.id;
            //     genericData[index]['user_type'] = entry.type;
            //     genericData[index]['fullname'] = entry.name;
            // })
            this.props.resultDataList(genericData, 'selectedfieldUsers', 'user_id')
        }

    }
    handledateChange=(e)=>{
        let newData=this.state.editData
        newData[e.target.name]=e.target.value
        newData["model"]=this.state.tab
        this.setState({editData:newData, [e.target.name]:e.target.value})
    }
    handlePostParamChange=(e, name, value, index)=>{
        let newData=this.state.editData
        let customapiparam = newData.customapiparam;
        if(customapiparam.length !== 0){
            if(name === 'param'){
                customapiparam[index].param = e.target.value;
            }
            else{
                customapiparam[index].value = value;
            }
        }
        newData.customapiparam = customapiparam;
        newData["model"]=this.state.tab
        this.setState({editData:newData})
    }
    handleFieldMappingChange=(e, name, value, index)=>{
        let newData=this.state.editData
        let responseparam = newData.responseparam;
        if(responseparam.length !== 0){
            responseparam[index].param = name;
            responseparam[index].value = value;

        }
        newData.responseparam = responseparam;
        newData["model"]=this.state.tab
        this.setState({editData:newData})
    }
    appendParamBox=(e, index)=>{
        let newData=this.state.editData
        let customapiparam = newData.customapiparam;
        let items = {
            param:"",
            value:""
        }
        customapiparam.push(items);
        newData.customapiparam = customapiparam;
        newData["model"]=this.state.tab
        this.setState({editData:newData})
    }
    removeParamBox=(e, index)=>{
        let newData=this.state.editData
        let customapiparam = newData.customapiparam;
        customapiparam.splice(index, 1);
        newData.customapiparam = customapiparam;
        newData["model"]=this.state.tab
        this.setState({editData:newData})
    }
    handlechangeValue=(e,name, value)=>{
        let newData=this.state.editData
        newData[name]=value;
        newData["model"]=this.state.tab
        this.setState({editData:newData})

    }
    handlelabelChange=(e)=>{
        let userList = this.props.userList;
        let newData=this.state.editData;
        newData['pattern']=[]
        newData[e.target.name]=e.target.value
        newData["model"]=this.state.tab
        if(newData['rule_type'] !== "customapi"){
            newData['customapi']=''

        }
        if(newData['rule_type'] !== "autoassign"){
            newData['assigned_email']=''
        }
        if(newData['rule_type'] !== "email"){
            newData['has_externalmail']=false
        }
        if(e.target.value === "pool"){
            userList = this.props.ListAllPool||[];
            if(userList.length !== 0){
                userList.map((data, index) => {
                    data.fullname = data.title;
                    data.user_id = data.id;
                })
            }
        }
        // if(newData['rule_type'] !== "autoassign"){
        //     newData['autoselectfield']=''
        //     newData['autoselectvalue']=''
        //     }
        //     let showFieldList = this.state.showFieldList;
        //     let options = []
        //     options = showFieldList.filter(item => (item.masterid === newData['autoselectfield']))[0] && showFieldList.filter(item => (item.masterid === newData['autoselectfield']))[0].option || []
        this.setState({editData: newData, [e.target.name]: e.target.value, userList: userList})
    }
    handleIndividualTask=(e)=>{
        let newData=this.state.editData
        let indvidualtask = newData.indvidualtask === true ? false : true;
        newData['indvidualtask'] = indvidualtask;
        newData["patternformat"]=newData.pattern;
        this.setState({editData: newData})
    }
    labelChange=(e,name,type)=>{
        let newdata=Array.from(this.state.ruleData.filter(list=>list.name === name))
        newdata[0]["fieldItem"][`field`]= newdata[0].masterid
        newdata[0]["fieldItem"][`field_rule`][0][`value`]= e.target.value
        this.setState({newdata})
    }
    optionChange=(e,name,type)=>{
        if(e.target.value !== ''){
            let newdata=[]
            if(type === "switch" || type==="checkbox" || type === "dropdown"){
                newdata=this.state.ruleData.filter(list=>list.name === name)
                newdata[0]["fieldItem"][`field`]= newdata[0].masterid
                newdata[0]["fieldItem"][`field_rule`][0][`trigger`]= e.target.value
                newdata[0]["fieldItem"][`field_rule`][0][`value`]= e.target.value
            }
            this.setState({newdata})
        }

    }
    labelChangeWorkflow=(e,name,type,index)=>{
        let newdata=Array.from(this.state.ruleData[index].condition.filter(list=>list.name === name))
        newdata[0]["fieldItem"][`field`]= newdata[0].masterid
        newdata[0]["fieldItem"][`field_rule`][0][`value`]= e.target.value
        this.setState({newdata})
    }
    optionChangeWorkflow=(e,name,type,index)=>{
        if(e.target.value !== ''){
            let newdata=[]
            if(type === "switch" || type==="checkbox" || type === "dropdown"){
                newdata=this.state.ruleData[index].condition.filter(list=>list.name === name)
                newdata[0]["fieldItem"][`field`]= newdata[0].masterid
                newdata[0]["fieldItem"][`field_rule`][0][`trigger`]= e.target.value
                newdata[0]["fieldItem"][`field_rule`][0][`value`]= e.target.value
            }
            this.setState({newdata})
        }

    }
    changeWorkflow=(e)=>{
        let selectedWorkflow = this.state.workflowList.filter(list=>list.id === e.target.value)
        let stageList = selectedWorkflow.length !== 0 && selectedWorkflow[0].stage_info ? selectedWorkflow[0].stage_info : [];
        this.setState({workflowids:e.target.value, tab: "workflow", stageList: stageList})
    }
    removeUsers=(id)=>{
        let  newUser=this.state.editData.users.filter(list=>list.id !== id)
        let  _newState=this.state.editData
        _newState["users"]=newUser
        this.setState({_newState})
    }
    handlePattern=(month)=>{
        let pattern = [...this.state.editData.pattern];
        (pattern.indexOf(month) > -1) ? pattern.splice(pattern.indexOf(month), 1) : pattern.push(month);
        let _newPatten={...this.state.editData}
        _newPatten["pattern"]=pattern
        _newPatten["patternformat"]=pattern
        this.setState({editData: _newPatten});
    }
    overlayShow=()=>{
        document.body.classList.add('layover-bg');
    }
    overlayHide=()=>{
        document.body.classList.remove('layover-bg');
    }
    handleFromChange=(date)=>{
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        let newData=this.state.editData
        newData['startDate']=formattedDate
        newData["model"]=this.state.tab
        this.setState({editData:newData, startDate:formattedDate})
        if(date !== '') {
            document.getElementById('startDate').classList.remove('val-error');
        }
    }
    handleToChange=(date)=>{
        let formattedDate = moment(date._d).utc().local().format('YYYY-MM-DD');
        let newData=this.state.editData
        newData['endDate']=formattedDate
        newData["model"]=this.state.tab
        this.setState({editData:newData, endDate:formattedDate})
        if(date !== '') {
            document.getElementById('endDate').classList.remove('val-error');
        }
    }

    getTaglist=(search)=>{
        // let params={
        //     search:search?search:''
        // }
    }
    appendConditions=(e, index, id)=>{
        let ruleData = this.state.ruleData;
        let conditions = {
            "trigger": "save",
            "stage": "",
            "condition": []
        }
        ruleData.push(conditions);
        this.setState({ruleData: ruleData})
    }
    removeConditions=(e, index, id)=>{
        let ruleData = this.state.ruleData;
        ruleData.splice(index, 1);
        this.setState({ruleData: ruleData})
    }
    handleOptionChange=(e, index)=>{
        let ruleData = this.state.ruleData;
        ruleData[index]['stage'] = e.target.value;
        let stages = this.state.stageList.filter(list=>list.id === e.target.value);
        ruleData[index]['name'] = stages[0].name;
        this.setState({ruleData: ruleData, tab: "workflow"})
    }

    render() {
        // console.log(this.state)
        let listRule = this.state.listRule;
        let showlistField = this.state.showlistField;
        let showFieldList = this.state.showFieldList;
        let generic=listRule.generic;
        let fieldwise=listRule.fieldwise;
        let customapiparam=this.state.editData.customapiparam;
        // console.log(this.state.ruleData)
        // console.log(this.state.editData)
        // console.log(this.state.newData)

        return (
            <div className={`cs-modal fade bsy-modal entity-rules in show`}>
                <div className={"modal-dialog modal-dialog-centered modal-lg"}>
                    <div className="modal-content">
                        <div className="modal-body">

                            {/* New content */}

                            <div className="entity-rule-container">
                                <button className="modal-close ico-lib md-close" onClick={(e) => {this.props.ruleClose()}}></button>

                                {listRule.loader &&
                                <span className="Loader-holder" style={{'background': 'transparent'}}>
                                        <LoaderSvg/>
                                     </span>
                                }


                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col className="entity-rule-left" sm={3}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first" onClick={(e) => {
                                                        this.tabSelect('fieldwise')
                                                    }}
                                                    >Rules</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second" onClick={(e) => {
                                                        this.tabSelect('workflow')
                                                    }}
                                                    >Workflow</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col className="entity-rule-right" sm={9}>
                                            <Tab.Content>

                                                <Tab.Pane eventKey="first">
                                                    <div className="entity-rule-right-inner">
                                                        <div className="entity-rule-right-header">
                                                            <h5>Rules</h5>
                                                            <button className="general-btn"
                                                                    onClick={() => this.setTab("fieldwise", "new")}>+ New
                                                            </button>
                                                        </div>
                                                        <div className="entity-rule-right-body">
                                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                                <div className="rule-right-body-inner">

                                                                    {fieldwise.length !== 0 && fieldwise.map((list, index) =>
                                                                        <div className="each-list-added">
                                                                            <div className="content-section "
                                                                                 key={`fieldwise` + index}>
                                                                                <div className="section-info-main">
                                                                                    <div className="section-info">
                                                                                        {list.rule_type !== "customapi" && list.ruletitle && list.ruletitle !== "" &&
                                                                                        <h5>{list.ruletitle}</h5>
                                                                                        }
                                                                                        {list.rule_type !== "customapi" && list.rule_type !== "email" &&
                                                                                        <h5>{list.ruleDescription}</h5>
                                                                                        }
                                                                                        {list.rule_type === "customapi" &&
                                                                                        <h5>{list.customapi}</h5>
                                                                                        }
                                                                                        {list.rule_type === "email" &&
                                                                                            <>
                                                                                                {list.users.length !== 0 && list.users.map((item, index) =>
                                                                                                        <span key={index}>{((list.users.length-1) === index) ? item.name :item.name+",  "}</span>
                                                                                                )}
                                                                                            </>
                                                                                        }
                                                                                        <div
                                                                                            className="form-option-block">
                                                                                            <button
                                                                                                className="ico-lib opt-form-ico"
                                                                                                onClick={() => this.actionStaus("fieldwiseRule", !this.state.fieldwiseRule, list.id)}></button>
                                                                                        </div>

                                                                                        {this.state.fieldwiseRule && this.state.id === list.id &&
                                                                                        <OutsideAlerter
                                                                                            status={this.state.fieldwiseRule}
                                                                                            Triggered={() => this.actionStaus("fieldwiseRule", !this.state.fieldwiseRule)}
                                                                                        >

                                                                                            <ul className="panel-option">
                                                                                                <li>
                                                                                                    <a className="panel-option-click ico-lib form-edit-ico"
                                                                                                       onClick={(e) => {
                                                                                                           this.handleEdit(list.id, "fieldwiseRule");
                                                                                                           this.actionStaus("fieldwiseRule", !this.state.fieldwiseRule)
                                                                                                       }}>Edit
                                                                                                    </a>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <a className="panel-option-click ico-lib delete-task-ico"
                                                                                                       onClick={(e) => {
                                                                                                           this.deleteFromGeneric(list.id, "fieldwiseRule");
                                                                                                           this.actionStaus("fieldwiseRule", !this.state.fieldwiseRule)
                                                                                                       }}>
                                                                                                        Delete
                                                                                                    </a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </OutsideAlerter>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="section-info-icon">
                                                                                        <ul className="rule-select-opt-block">
                                                                                            <li className="ico-lib notf-ico">{list.rule_type}</li>
                                                                                            <li className="ico-lib date-rule-ico">{moment(list.startDate).format("D MMM YYYY")} to {moment(list.endDate).format("D MMM YYYY")}
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Frequency:</span> {list.type}
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Pattern:</span> {list.pattern}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {fieldwise.length === 0 &&
                                                                    <div className='absolute-holder' style={{'background': 'transparent'}}>
                                                                        <NoData
                                                                            msg={`No Rules Added`}
                                                                        />
                                                                    </div>
                                                                    }

                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="second">
                                                    <div className="entity-rule-right-inner">
                                                        <div className="entity-rule-right-header">
                                                            <h5>Workflow</h5>
                                                            <button className="general-btn"
                                                                    onClick={() => this.setTab("workflow", "new")}>+ New
                                                            </button>
                                                        </div>
                                                        <div className="entity-rule-right-body">
                                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                                <div className="rule-right-body-inner">

                                                                    {this.props.linkedWorkflow.workflow.length !== 0 && this.props.linkedWorkflow.workflow.map((list, index) =>
                                                                        <div className="each-list-added">
                                                                            <div className="content-section "
                                                                                 data-value="collist"
                                                                                 key={`workflow` + index}>
                                                                                <div className="section-info-main">
                                                                                    <div className="section-info">
                                                                                        <h5>{list.workflowname !== '' ? atob(list.workflowname) : list.workflowname}</h5>
                                                                                        {/*<p>{list.ruleDescription}</p>*/}
                                                                                        <div
                                                                                            className="form-option-block">
                                                                                            <button
                                                                                                className="ico-lib opt-form-ico"
                                                                                                onClick={() => this.actionStaus("workflow", !this.state.workflowstatus, list._id)}></button>
                                                                                        </div>

                                                                                        {this.state.workflowstatus && this.state.id === list._id &&
                                                                                        <OutsideAlerter
                                                                                            status={this.state.workflowstatus}
                                                                                            Triggered={() => this.actionStaus("workflow", !this.state.workflowstatus)}
                                                                                        >

                                                                                            <ul className="panel-option">
                                                                                                <li>
                                                                                                    <a className="panel-option-click ico-lib delete-task-ico"
                                                                                                       onClick={(e) => {
                                                                                                           this.deleteFromGeneric(list._id, "workflow");
                                                                                                           this.actionStaus("workflow", !this.state.workflowstatus)
                                                                                                       }}>Delete</a>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <a className="panel-option-click ico-lib  form-edit-ico"
                                                                                                       onClick={(e) => {
                                                                                                           this.handleEdit(list._id, "workflow", atob(list.workflowname), list.workflowid);
                                                                                                           this.actionStaus("workflow", !this.state.workflowstatus)
                                                                                                       }}>Edit</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </OutsideAlerter>
                                                                                        }


                                                                                    </div>
                                                                                    <div className="section-info-icon">
                                                                                        <ul className="rule-select-opt-block">
                                                                                            <li className="ico-lib notf-ico">Workflow</li>
                                                                                            <li className="ico-lib date-rule-ico">{moment(list.createdate).format("D MMM YYYY")}
                                                                                            </li>
                                                                                            <li><span>Act On:</span> {list.triggeron === 'save' ? 'insert' : list.triggeron}</li>
                                                                                            {/*<li><span>Frequency:</span> {list.type}</li>*/}
                                                                                            {/*<li><span>Pattern:</span> {list.pattern}</li>*/}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {this.props.linkedWorkflow.workflow.length === 0 &&
                                                                    <div className='absolute-holder' style={{'background': 'transparent'}}>
                                                                        <NoData
                                                                            msg={`No Workflow Added`}
                                                                        />
                                                                    </div>
                                                                    }

                                                                </div>
                                                            </Scrollbars>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>

                                {this.state.tabAdd === "fieldwise" && this.state.fieldwise &&
                                <div className="new-modal ">
                                    <div className="new-modal-header">
                                        <div className="task-nav">
                                            <div className="task-nav-left bg-back">
                                                <button className="backto-list"
                                                        onClick={() => this.setTab("fieldwise", "cancel")}>Back To
                                                    Listing
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="new-modal-body">
                                        <div className="new-modal-left">
                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                <div className="new-modal-left-inner">
                                                    {this.state.ruleData.map((list, index) =>
                                                        <React.Fragment>
                                                            {list.type === "checkbox" || list.type === "switch" ?
                                                                <React.Fragment>
                                                                    <div className="leftContentbox">
                                                                        <div className="leftContentboxbody">

                                                                            <div className="leftContentHead">
                                                                                <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                                            </div>
                                                                            <div className="leftInputSection">
                                                                                <div className="split-rule-block">
                                                                                    <div className="rule-select-box">
                                                                                        <select
                                                                                            value={list.fieldItem.field_rule[0].trigger}
                                                                                            onChange={(e) => this.optionChange(e, list.name, list.type)}>
                                                                                            <option value="">Select</option>
                                                                                            {list.opt.map((item, index) =>
                                                                                                <option
                                                                                                    key={`option` + index}
                                                                                                    value={item.text ? item.text : item}>{item.text ? item.text : item}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="split-rule-block">

                                                                                    <div className="leftInputaddIcons">
                                                                                        <button
                                                                                            className="btnsubIconsadd ico-lib">
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="leftInputaddIcons">
                                                                                        <button
                                                                                            className="btnsubIcon ico-lib"
                                                                                            onClick={(e) => this.removeFromWorkflow(e, list.name)}>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="lefttext">
                                                                            <p>and</p>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                                    :
                                                                    list.type === "dropdown" ?
                                                                    <React.Fragment>
                                                                        <div className="leftContentbox">
                                                                            <div className="leftContentboxbody">

                                                                                <div className="leftContentHead">
                                                                                    <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                                                </div>
                                                                                <div className="leftInputSection">
                                                                                    <div className="split-rule-block">
                                                                                        <div className="rule-select-box">
                                                                                            <select
                                                                                                value={list.fieldItem.field_rule[0].trigger}
                                                                                                onChange={(e) => this.optionChange(e, list.name, list.type)}>
                                                                                                <option value="">Select</option>
                                                                                                {list.opt.map((item, index) =>
                                                                                                    <option
                                                                                                        key={`option` + index}
                                                                                                        value={item.text ? item.text : item}>{item.text ? item.text : item}</option>
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="split-rule-block">

                                                                                        <div className="leftInputaddIcons">
                                                                                            <button
                                                                                                className="btnsubIconsadd ico-lib">
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="leftInputaddIcons">
                                                                                            <button
                                                                                                className="btnsubIcon ico-lib"
                                                                                                onClick={(e) => this.removeFromWorkflow(e, list.name)}>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="lefttext">
                                                                                <p>and</p>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                        :
                                                                        <React.Fragment>
                                                                            <div className="leftContentbox">
                                                                                <div className="leftContentboxbody">
                                                                                    <div className="leftContentHead">
                                                                                        <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                                                    </div>
                                                                                    <div className="leftInputSection">
                                                                                        <div className="split-rule-block">
                                                                                            <div className="rule-select-box">
                                                                                                <select
                                                                                                    onChange={(e) => this.optionChange(e, list.name, "switch")}
                                                                                                    value={list.fieldItem.field_rule[0].trigger}
                                                                                                >
                                                                                                    {list.option.map((item, index) =>
                                                                                                        <option
                                                                                                            key={`option` + index}
                                                                                                            value={item.value}>{item.name}</option>
                                                                                                    )}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="split-rule-block">
                                                                                            <div className="rule-input-block">
                                                                                                <input type="text"
                                                                                                       placeholder={list.placeholder}
                                                                                                       name={`name` + index}
                                                                                                       value={list.fieldItem.field_rule[0].value}
                                                                                                       onChange={(e) => this.labelChange(e, list.name, list.type)}/>
                                                                                            </div>
                                                                                            <div className="leftInputaddIcons">
                                                                                                <button
                                                                                                    className="btnsubIcon ico-lib"
                                                                                                    onClick={(e) => this.removeFromWorkflow(e, list.name)}>

                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="lefttext">
                                                                                        <p>and</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </React.Fragment>
                                                            }


                                                        </React.Fragment>
                                                    )}
                                                    <div className="rule-field-btn-block">
                                                        <div className="dropdown show active">
                                                            <button className="dropdown-toggle" type="button"
                                                                    onClick={() => this.setTab("dropdownrule")}>Choose <span
                                                                className="blue-bold"
                                                            >Field</span>
                                                            </button>
                                                            {this.state.dropdownrule === "dropdownrule" &&

                                                            <div className="dropdown-menu show active"
                                                                 aria-labelledby="dropdownMenu2">

                                                                {showlistField.map((list, index) =>
                                                                    <button className="dropdown-item" type="button"
                                                                            onClick={(e) => this.handleChange(e, list.label, list.type, list.option, list.masterid)}>{list.label}
                                                                    </button>
                                                                )}

                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Scrollbars>
                                        </div>
                                        <div className="new-modal-right">
                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                <div className="new-modal-right-inner">
                                                    <div className="each-inp-box">
                                                        <div className="rightdateinput">
                                                            <label>Choose Type</label>
                                                            <div className="radio-wrap">
                                                                <div className="radio-block">
                                                                    <input type="radio" name="tabSelect"
                                                                           checked={this.state.tabAdd === "fieldwise"}
                                                                           onClick={(e) => {
                                                                               this.tabSelectAdd('fieldwise')
                                                                           }}/>
                                                                    <label>Rule</label>
                                                                </div>
                                                                <div className="radio-block">
                                                                    <input type="radio" name="tabSelect"
                                                                           checked={this.state.tabAdd === "workflow"}
                                                                           onClick={(e) => {
                                                                               this.tabSelectAdd('workflow')
                                                                           }}/>
                                                                    <label>Workflow</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(this.state.tabAdd === "fieldwise" && this.state.fieldwise) &&
                                                        <>
                                                            <div className="rightdateinput">
                                                                <label>Validity Period</label>
                                                                <div className="inputdatefield">
                                                                    <div
                                                                        className="task-input-block eq-width datefieldright picker-bt">
                                                                        <DateTimePickerBox
                                                                            handleDateChange={this.handleFromChange}
                                                                            placeholderText="Start date"
                                                                            id="startDate"
                                                                            timeFormat={false}
                                                                            startDate={this.state.editData.startDate}
                                                                            previousDate={true}
                                                                        />
                                                                        <button className="ico-lib inputdate"></button>
                                                                    </div>
                                                                    <div
                                                                        className="task-input-block eq-width datefieldright picker-bt">
                                                                        <DateTimePickerBox
                                                                            handleDateChange={this.handleToChange}
                                                                            placeholderText="End date"
                                                                            id="endDate"
                                                                            timeFormat={false}
                                                                            startDate={this.state.editData.endDate}
                                                                            previousDate={true}
                                                                        />
                                                                        <button className="ico-lib inputdate"></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rightdateinput">
                                                                <label>Frequency</label>
                                                                <div className="rightinputdropdown">
                                                                    <div className="rule-select-box"  id='type'>
                                                                        <select name="type"
                                                                                onChange={this.handlelabelChange}
                                                                                value={this.state.editData.type}>
                                                                            <option value="">Select</option>
                                                                            <option value="Daily">Daily</option>
                                                                            <option value="Weekly">Weekly</option>
                                                                            <option value="Biweekly">Biweekly</option>
                                                                            <option value="Monthly">Monthly</option>
                                                                            <option value="Quarterly">Quarterly</option>
                                                                            <option value="HalfYearly">HalfYearly
                                                                            </option>
                                                                            <option value="Annual">Annual</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.editData.type === "Weekly" &&
                                                            <div className="rightdateinput">
                                                                <label>Rule Pattern</label>
                                                                <div className="weekmarkarea">
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Sunday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Sunday')}
                                                                                   onClick={() => this.handlePattern("Sunday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Monday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Monday')}
                                                                                   onClick={() => this.handlePattern("Monday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Tuesday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Tuesday')}
                                                                                   onClick={() => this.handlePattern("Tuesday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Wednesday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Wednesday')}
                                                                                   onClick={() => this.handlePattern("Wednesday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Thursday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Thursday')}
                                                                                   onClick={() => this.handlePattern("Thursday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Friday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Friday')}
                                                                                   onClick={() => this.handlePattern("Friday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Saturday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Saturday')}
                                                                                   onClick={() => this.handlePattern("Saturday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            }
                                                            {this.state.editData.type === "Monthly" &&
                                                            <div className="rightdateinput">
                                                                <label>Rule Pattern</label>
                                                                <div className="weekmarkarea">
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> January
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('January')}
                                                                                   onClick={() => this.handlePattern("January")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> February
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('February')}
                                                                                   onClick={() => this.handlePattern("February")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> March
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('March')}
                                                                                   onClick={() => this.handlePattern("March")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> April
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('April')}
                                                                                   onClick={() => this.handlePattern("April")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> May
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('May')}
                                                                                   onClick={() => this.handlePattern("May")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> June
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('June')}
                                                                                   onClick={() => this.handlePattern("June")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> July
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('July')}
                                                                                   onClick={() => this.handlePattern("July")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> August
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('August')}
                                                                                   onClick={() => this.handlePattern("August")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> September
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('September')}
                                                                                   onClick={() => this.handlePattern("September")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> October
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('October')}
                                                                                   onClick={() => this.handlePattern("October")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> November
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('November')}
                                                                                   onClick={() => this.handlePattern("November")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> December
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('December')}
                                                                                   onClick={() => this.handlePattern("December")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            }

                                                            <div className="rightdateinput">
                                                                <label>Action Type</label>
                                                                <div className="rule-select-box" id='rule_type'>
                                                                    <select name="rule_type"
                                                                            onChange={this.handlelabelChange}>
                                                                        <option>{this.state.editData.rule_type !== '' ? (this.state.editData.rule_type === 'pool' ? 'Pool Task' : this.state.editData.rule_type === 'customapi' ? 'Custom API' : this.state.editData.rule_type === 'autoassign' ? 'Auto Assign' : this.state.editData.rule_type) : 'Select'}</option>
                                                                        <option value={`Notification`}>Notification
                                                                        </option>
                                                                        <option value={`Task`}>Task</option>
                                                                        <option value={`pool`}>Pool Task</option>
                                                                        <option value={`customapi`}>Custom API</option>
                                                                        <option value={`autoassign`}>Auto Assign</option>
                                                                        <option value={`email`}>Email</option>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                            {this.state.editData.rule_type === 'customapi' &&
                                                            <React.Fragment>
                                                            <div className="taskdescsection">
                                                                <div className="assignInputdesc">
                                                                    <textarea id="customapi"
                                                                              name="customapi" placeholder="Enter Custom API Url"
                                                                              value={this.state.editData.customapi}
                                                                              onChange={this.handlelabelChange}
                                                                              className={`task-textarea-form ${this.state.errors.customapi ? " val-error" : ''}`}
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>
                                                            <div className="rightdateinput">
                                                                <label>Mode</label>
                                                                <div className="radio-wrap">
                                                                    <div className="radio-block">
                                                                        <input type="radio" value='internal' name="customapi_type"
                                                                               defaultChecked={this.state.editData.customapi_type && this.state.editData.customapi_type === "internal"}
                                                                               onChange={this.handlelabelChange} />
                                                                        <label>Internal</label>
                                                                    </div>
                                                                    <div className="radio-block">
                                                                        <input type="radio" value='external' name="customapi_type"
                                                                               defaultChecked={this.state.editData.customapi_type && this.state.editData.customapi_type === "external"}
                                                                               onChange={this.handlelabelChange} />
                                                                        <label>External</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rightdateinput">
                                                                <label>Method</label>
                                                                <div className="radio-wrap">
                                                                    <div className="radio-block">
                                                                        <input type="radio" value='get' name="customapimethod"
                                                                               defaultChecked={this.state.editData.customapimethod && this.state.editData.customapimethod === "get"}
                                                                               onChange={this.handlelabelChange} />
                                                                        <label>GET</label>
                                                                    </div>
                                                                    <div className="radio-block">
                                                                        <input type="radio" value='post' name="customapimethod"
                                                                               defaultChecked={this.state.editData.customapimethod && this.state.editData.customapimethod === "post"}
                                                                               onChange={this.handlelabelChange} />
                                                                        <label>POST</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="rightdateinput">
                                                                <label>Params</label>
                                                                {this.state.editData.customapiparam && this.state.editData.customapiparam.map((list, index) =>

                                                                        <div className="post-param-panel">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="ac-text-box email-sub">
                                                                                    <input type="text" placeholder="param" className="e-field"
                                                                                        value={list.param} name="param"
                                                                                        onChange={(e)=>this.
                                                                                        handlePostParamChange(e, "param", list.param,  index)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="taskdescsection">
                                                                                    <div className="assignInputdesc">
                                                                                        <Mentions
                                                                                            showFieldList={this.state.showFieldList}
                                                                                            value={list.value}
                                                                                            index={index}
                                                                                            name="value"
                                                                                            handlechangeValue={this.handlePostParamChange}
                                                                                            singleLine={true}
                                                                                            placeholder={"value"}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="add-multiple-box">
                                                                                {index > 0 &&
                                                                                <button className="btnsubIcon ico-lib" onClick={(e) => this.removeParamBox(e, index)}></button>
                                                                                }
                                                                                {index === this.state.editData.customapiparam.length-1 &&
                                                                                <button className="btnsubIconsadd ico-lib"
                                                                                        onClick={(e) => this.appendParamBox(e, index)}></button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="rightdateinput">
                                                                    <label>TRIGGER ON</label>
                                                                    <div className="radio-wrapper-new">
                                                                        <div className="radio-block">
                                                                            <input type="radio" name="customapi_trigger"
                                                                                   defaultChecked={this.state.editData.customapi_trigger === "submit"}
                                                                                   value={"submit"}
                                                                                   onChange={this.handlelabelChange}
                                                                            />
                                                                            <label>Submit</label>
                                                                        </div>
                                                                        <div className="radio-block">
                                                                            <input type="radio" name="customapi_trigger"
                                                                                   defaultChecked={this.state.editData.customapi_trigger === "draft"}
                                                                                   value={`draft`}
                                                                                   onChange={this.handlelabelChange}
                                                                            />
                                                                            <label>Save As Draft</label>
                                                                        </div>

                                                                        {/*<div className="radio-block">*/}
                                                                        {/*    <input type="radio" name="trigeron"*/}
                                                                        {/*           defaultChecked={this.state.editData.trigeron === "none"}*/}
                                                                        {/*           value={``}*/}
                                                                        {/*           onChange={this.handlelabelChange}*/}
                                                                        {/*    />*/}
                                                                        {/*    <label>None</label>*/}
                                                                        {/*</div>*/}

                                                                    </div>
                                                                </div>
                                                                {this.state.editData.customapi_type === "external" &&
                                                                <>
                                                                    <div className="rightdateinput">
                                                                        <label>Save Api response</label>
                                                                        <div className="radio-wrapper-new">
                                                                            <div className="radio-block">
                                                                                <input type="radio" name="save_response"
                                                                                       defaultChecked={this.state.editData.save_response === "yes"}
                                                                                       value={"yes"}
                                                                                       onChange={this.handlelabelChange}
                                                                                />
                                                                                <label>Yes</label>
                                                                            </div>
                                                                            <div className="radio-block">
                                                                                <input type="radio" name="save_response"
                                                                                       defaultChecked={this.state.editData.save_response === "no"}
                                                                                       value={`no`}
                                                                                       onChange={this.handlelabelChange}
                                                                                />
                                                                                <label>No</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.editData.save_response === "yes" &&
                                                                        <>
                                                                            <div className="rightdateinput">
                                                                                <label>Response Field Mapping</label>

                                                                                <div className="post-param-panel">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <div
                                                                                                className="ac-text-box email-sub">
                                                                                                Status Field
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="taskdescsection">
                                                                                                <div
                                                                                                    className="assignInputdesc">
                                                                                                    <Mentions
                                                                                                        showFieldList={this.state.showFieldList}
                                                                                                        value={this.state.editData.responseparam[0].value}
                                                                                                        index={0}
                                                                                                        name="status_field"
                                                                                                        handlechangeValue={this.handleFieldMappingChange}
                                                                                                        singleLine={true}
                                                                                                        placeholder={"Status Field"}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="post-param-panel">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <div
                                                                                                className="ac-text-box email-sub">
                                                                                                Message Field
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="taskdescsection">
                                                                                                <div
                                                                                                    className="assignInputdesc">
                                                                                                    <Mentions
                                                                                                        showFieldList={this.state.showFieldList}
                                                                                                        value={this.state.editData.responseparam[1].value}
                                                                                                        index={1}
                                                                                                        name="message_field"
                                                                                                        handlechangeValue={this.handleFieldMappingChange}
                                                                                                        singleLine={true}
                                                                                                        placeholder={"Message Field"}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="post-param-panel">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <div
                                                                                                className="ac-text-box email-sub">
                                                                                                Response Field
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="taskdescsection">
                                                                                                <div
                                                                                                    className="assignInputdesc">
                                                                                                    <Mentions
                                                                                                        showFieldList={this.state.showFieldList}
                                                                                                        value={this.state.editData.responseparam[2].value}
                                                                                                        index={2}
                                                                                                        name="response_field"
                                                                                                        handlechangeValue={this.handleFieldMappingChange}
                                                                                                        singleLine={true}
                                                                                                        placeholder={"Response Field"}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="rightdateinput">

                                                                                <div className="post-param-panel">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <div
                                                                                                className="ac-text-box email-sub">
                                                                                                Success Status
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="taskdescsection">
                                                                                                <input type="text"
                                                                                                       placeholder="Success"
                                                                                                       name={`success_response`}
                                                                                                       value={this.state.editData.success_response}
                                                                                                       onChange={(e) => this.handlechangeValue(e, 'success_response', e.target.value)}/>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="post-param-panel">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6">
                                                                                            <div
                                                                                                className="ac-text-box email-sub">
                                                                                                Failure Status
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-6">
                                                                                            <div className="taskdescsection">
                                                                                                <input type="text"
                                                                                                       placeholder="Failure"
                                                                                                       name={`failure_response`}
                                                                                                       value={this.state.editData.fail_response}
                                                                                                       onChange={(e) => this.handlechangeValue(e, 'fail_response', e.target.value)}/>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                </>
                                                                }


                                                            </React.Fragment>
                                                            }
                                                            {this.state.editData.rule_type !== 'customapi' &&
                                                            <React.Fragment>
                                                                <div className="rightdateinput">
                                                                    <label>Assign to</label>
                                                                    <div className="assignSection" id='selectedfieldUsers'>
                                                                        {/*<div className="assignsectionTitle">*/}
                                                                        {/*    <p>Assign To</p> <a className="assignImport"*/}
                                                                        {/*                        href="javascript:void(0);">Import Field</a>*/}
                                                                        {/*</div>*/}
                                                                        <div className="task-inp-container">
                                                                            <AutoComplete
                                                                                fieldTitle='To'
                                                                                placeholder="Type here"
                                                                                sourceDataList={this.state.userList}
                                                                                searchField='fullname'
                                                                                showIcon={false}
                                                                                hideMainLabel={false}
                                                                                showSubLabel={false}
                                                                                labelFields='fullname'
                                                                                valueField='user_id'
                                                                                subLabelField='designation'
                                                                                showProfile={true}
                                                                                reference='selectedfieldUsers'
                                                                                selectedItems={this.props.selectedfieldUsers}
                                                                                resultDataList={this.props.resultDataList}
                                                                                ignoredValues={this.props.ignoredfieldValues}
                                                                                id="to_users"
                                                                                actionMethod={this.props.getUsers}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    {this.state.editData.rule_type !== 'email' &&
                                                                    <div className="rightdateinput">
                                                                        <label>Description</label>
                                                                        <div className="taskdescsection">
                                                                            <div className="assignInputdesc">
                                                                                <textarea id="title"
                                                                                          name="ruleDescription"
                                                                                          placeholder="Type Here"
                                                                                          value={this.state.editData.ruleDescription}
                                                                                          onChange={this.handlelabelChange}
                                                                                          className={`task-textarea-form ${this.state.errors.ruleDescription ? " val-error" : ''}`}
                                                                                >

                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                    {(this.state.editData.rule_type === 'Task' || this.state.editData.rule_type === 'autoassign') &&
                                                                    <div className="rightdateinput">
                                                                        <label>Title</label>
                                                                        <div className="taskdescsection">
                                                                            <div className="assignInputdesc">
                                                                                <input id="task_title"
                                                                                       name="ruletitle"
                                                                                       placeholder="Task title"
                                                                                       value={this.state.editData.ruletitle}
                                                                                       onChange={this.handlelabelChange}
                                                                                       className={`task-textarea-form ${this.state.errors.ruletitle ? " val-error" : ''}`}
                                                                                >

                                                                                </input>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }


                                                                {this.state.editData.rule_type === "email" &&
                                                                <React.Fragment>
                                                                    <div className="rightdateinput">
                                                                        <label>CC</label>
                                                                        <div className="assignSection" id='selectedfieldUsersCc'>
                                                                            <div className="task-inp-container">
                                                                                <AutoComplete
                                                                                    fieldTitle='cc'
                                                                                    placeholder="Type here"
                                                                                    sourceDataList={this.state.userList}
                                                                                    searchField='fullname'
                                                                                    showIcon={false}
                                                                                    hideMainLabel={false}
                                                                                    showSubLabel={false}
                                                                                    labelFields='fullname'
                                                                                    valueField='user_id'
                                                                                    subLabelField='designation'
                                                                                    showProfile={true}
                                                                                    reference='selectedfieldUsersCc'
                                                                                    selectedItems={this.props.selectedfieldUsersCc}
                                                                                    resultDataList={this.props.resultDataList}
                                                                                    ignoredValues={this.props.ignoredfieldValuesCc}
                                                                                    id="to_usersCc"
                                                                                    actionMethod={this.props.getUsers}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="rightdateinput">
                                                                        <label>External Mail</label>
                                                                        <div className="radio-wrapper-new">
                                                                            <div className="radio-block">
                                                                                <input type="radio" name="has_externalmail"
                                                                                       defaultChecked={this.state.editData.has_externalmail === 'true' || this.state.editData.has_externalmail === true}
                                                                                       value={true}
                                                                                       onChange={this.handlelabelChange}
                                                                                />
                                                                                <label>Yes</label>
                                                                            </div>
                                                                            <div className="radio-block">
                                                                                <input type="radio" name="has_externalmail"
                                                                                       defaultChecked={this.state.editData.has_externalmail === 'false' || this.state.editData.has_externalmail === false}
                                                                                       value={false}
                                                                                       onChange={this.handlelabelChange}
                                                                                />
                                                                                <label>No</label>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    {this.state.editData.has_externalmail === 'true' &&
                                                                    <React.Fragment>
                                                                        <div className="rightdateinput">
                                                                            <label>To</label>
                                                                            <div className="taskdescsection">
                                                                                <div className="assignInputdesc">
                                                                                    <Mentions
                                                                                        showFieldList={this.state.showEmailList}
                                                                                        value={this.state.editData.external_to}
                                                                                        name={'external_to'}
                                                                                        handlechangeValue={this.handlechangeValue}
                                                                                        singleLine={true}
                                                                                        placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="rightdateinput">
                                                                            <label>CC</label>
                                                                            <div className="taskdescsection">
                                                                                <div className="assignInputdesc">
                                                                                    <Mentions
                                                                                        showFieldList={this.state.showEmailList}
                                                                                        value={this.state.editData.external_cc}
                                                                                        name={'external_cc'}
                                                                                        handlechangeValue={this.handlechangeValue}
                                                                                        singleLine={true}
                                                                                        placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                    }

                                                                    <div className="rightdateinput">
                                                                        <label>Email Subject</label>
                                                                        <div className="taskdescsection">
                                                                            <div className="assignInputdesc">
                                                                                <Mentions
                                                                                    showFieldList={this.state.showFieldList}
                                                                                    value={this.state.editData.ruleEmailsubject}
                                                                                    name={'ruleEmailsubject'}
                                                                                    handlechangeValue={this.handlechangeValue}
                                                                                    singleLine={true}
                                                                                    placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="rightdateinput">
                                                                        <label>Email Content</label>
                                                                        <div className="mention-textarea">
                                                                                <Mentions
                                                                                    showFieldList={this.state.showFieldList}
                                                                                    value={this.state.editData.ruleDescription}
                                                                                    name={'ruleDescription'}
                                                                                    handlechangeValue={this.handlechangeValue}
                                                                                    singleLine={false}
                                                                                    placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                                />
                                                                        </div>
                                                                    </div>

                                                                </React.Fragment>
                                                                }

                                                                {this.state.editData.rule_type !== 'autoassign' &&
                                                                <React.Fragment>
                                                                    <div className="rightdateinput">
                                                                        <label>Priority</label>
                                                                        <div className="rightinputdropdown">
                                                                            <div className="rule-select-box"  id='priority'>
                                                                                <select name={`priority`}
                                                                                        onChange={this.handlelabelChange}
                                                                                        value={this.state.editData.priority}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="Low">Low</option>
                                                                                    <option value="Medium">Medium
                                                                                    </option>
                                                                                    <option value="High">High</option>
                                                                                    <option value="Urgent">Urgent
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.editData.rule_type !== "email" &&
                                                                    <div className="rightdateinput">
                                                                        <div className="select-chk-block"
                                                                             style={{"display": "block"}}>
                                                                            <label className="control control-checkbox">Individual
                                                                                Task
                                                                                <input type="checkbox"
                                                                                       className="inp-chk"
                                                                                       id="text_searchable"
                                                                                       value={this.state.editData.indvidualtask}
                                                                                       defaultChecked={this.state.editData.indvidualtask}
                                                                                       onClick={(e) => this.handleIndividualTask(e)}/>
                                                                                <div
                                                                                    className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            }

                                                            <div className="rightdateinput">
                                                                <label>Rule Act On</label>
                                                                <div className="radio-wrap">
                                                                    <div className="radio-block">
                                                                        <input type="radio" name="triggeron" value="save"
                                                                               defaultChecked={this.state.editData.triggeron === "save"}
                                                                               onClick={(e) => {
                                                                                   this.triggeronSelect('save')
                                                                               }}/>
                                                                        <label>Insert</label>
                                                                    </div>
                                                                    <div className="radio-block">
                                                                        <input type="radio" name="triggeron" value="update"
                                                                               defaultChecked={this.state.editData.triggeron === "update"}
                                                                               onClick={(e) => {
                                                                                   this.triggeronSelect('update')
                                                                               }}/>
                                                                        <label>Update</label>
                                                                    </div>
                                                                    <div className="radio-block">
                                                                        <input type="radio" name="triggeron" value="save"
                                                                               defaultChecked={this.state.editData.triggeron === "both"}
                                                                               onClick={(e) => {
                                                                                   this.triggeronSelect('both')
                                                                               }}/>
                                                                        <label>Both</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {this.state.editData.rule_type === "autoassign" &&
                                                            <React.Fragment>
                                                                <div className="rightdateinput">
                                                                <label>SEND EMAIL NOTIFICATION</label>
                                                                <div className="radio-wrapper-new">
                                                                    <div className="radio-block">
                                                                        <input type="radio" name="assigned_email"
                                                                               defaultChecked={this.state.editData.assigned_email === "yes"}
                                                                               value={"yes"}
                                                                               onChange={this.handlelabelChange}
                                                                        />
                                                                        <label>yes</label>
                                                                    </div>
                                                                    <div className="radio-block">
                                                                        <input type="radio" name="assigned_email"
                                                                               defaultChecked={this.state.editData.assigned_email === "no"}
                                                                               value={`no`}
                                                                               onChange={this.handlelabelChange}
                                                                        />
                                                                        <label>no</label>
                                                                    </div>

                                                                    {/*<div className="radio-block">*/}
                                                                    {/*    <input type="radio" name="trigeron"*/}
                                                                    {/*           defaultChecked={this.state.editData.trigeron === "none"}*/}
                                                                    {/*           value={``}*/}
                                                                    {/*           onChange={this.handlelabelChange}*/}
                                                                    {/*    />*/}
                                                                    {/*    <label>None</label>*/}
                                                                    {/*</div>*/}

                                                                </div>
                                                            </div>
                                                            {this.state.editData.assigned_email === "yes" &&
                                                            <React.Fragment>
                                                                <div className="rightdateinput">
                                                                    <label>CC</label>
                                                                    <div className="assignSection" id='selectedfieldUsersCc'>
                                                                        <div className="task-inp-container">
                                                                            <AutoComplete
                                                                                fieldTitle='cc'
                                                                                placeholder="Type here"
                                                                                sourceDataList={this.state.userList}
                                                                                searchField='fullname'
                                                                                showIcon={false}
                                                                                hideMainLabel={false}
                                                                                showSubLabel={false}
                                                                                labelFields='fullname'
                                                                                valueField='user_id'
                                                                                subLabelField='designation'
                                                                                showProfile={true}
                                                                                reference='selectedfieldUsersCc'
                                                                                selectedItems={this.props.selectedfieldUsersCc}
                                                                                resultDataList={this.props.resultDataList}
                                                                                ignoredValues={this.props.ignoredfieldValuesCc}
                                                                                id="to_usersCc"
                                                                                actionMethod={this.props.getUsers}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="rightdateinput">
                                                                    <label>Email Subject</label>
                                                                    <div className="taskdescsection">
                                                                        <div className="assignInputdesc">
                                                                            <Mentions
                                                                                showFieldList={this.state.showFieldList}
                                                                                value={this.state.editData.ruleEmailsubject}
                                                                                name={'ruleEmailsubject'}
                                                                                handlechangeValue={this.handlechangeValue}
                                                                                singleLine={true}
                                                                                placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="rightdateinput">
                                                                    <label>Email Content</label>
                                                                    <div className="taskdescsection">
                                                                        <div className="assignInputdesc">
                                                                            <Mentions
                                                                                showFieldList={this.state.showFieldList}
                                                                                value={this.state.editData.mailcontent}
                                                                                name={'mailcontent'}
                                                                                handlechangeValue={this.handlechangeValue}
                                                                                singleLine={false}
                                                                                placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                            }
                                                            </React.Fragment>
                                                            }

                                                        </>
                                                        }
                                                        {this.state.tabAdd === "workflow" && this.state.fieldwise &&
                                                        <>
                                                        <div className="rightdateinput">
                                                            <div className="rightinputdropdown">
                                                                <div className="rule-select-box">
                                                                    <select onChange={(e)=>this.changeWorkflow(e)} >
                                                                        <option value={this.state.workflowids}>{this.state.workflowNames !== '' ? this.state.workflowNames : 'Select'}</option>
                                                                        {this.state.workflowList.map((getWorkflow, index) =>
                                                                            <option key={`get-work-flow` + index}
                                                                                    value={getWorkflow.id}>{getWorkflow.name}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="rightdateinput">
                                                            <label>Workflow Act On</label>
                                                            <div className="radio-wrap">
                                                                <div className="radio-block">
                                                                    <input type="radio" name="triggeron" value="save"
                                                                           defaultChecked={this.state.workflowtriggeron === "save"}
                                                                           onClick={(e) => {
                                                                               this.workflowtriggeronSelect('save')
                                                                           }}/>
                                                                    <label>Insert</label>
                                                                </div>
                                                                <div className="radio-block">
                                                                    <input type="radio" name="triggeron" value="update"
                                                                           defaultChecked={this.state.workflowtriggeron === "update"}
                                                                           onClick={(e) => {
                                                                               this.workflowtriggeronSelect('update')
                                                                           }}/>
                                                                    <label>Update</label>
                                                                </div>
                                                                <div className="radio-block">
                                                                    <input type="radio" name="triggeron" value="save"
                                                                           defaultChecked={this.state.workflowtriggeron === "both"}
                                                                           onClick={(e) => {
                                                                               this.workflowtriggeronSelect('both')
                                                                           }}/>
                                                                    <label>Both</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       </>
                                                        }

                                                    </div>

                                                </div>
                                            </Scrollbars>
                                        </div>
                                    </div>

                                    <div className="new-modal-footer">
                                        <button type="button" className="general-btn"
                                                onClick={(e) => this.addGeneralRule(e)}>{this.state.addStatus}
                                        </button>
                                    </div>

                                </div>
                                }
                                {this.state.tabAdd === "workflow" && this.state.fieldwise &&
                                <div className="new-modal ">
                                    <div className="new-modal-header">
                                        <div className="task-nav">
                                            <div className="task-nav-left bg-back">
                                                <button className="backto-list"
                                                        onClick={() => this.setTab("fieldwise", "cancel")}>Back To
                                                    Listing
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="new-modal-body">
                                        <div className="new-modal-left">
                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                {this.state.multiCondition === false &&
                                                <div className="new-modal-left-inner">
                                                    {this.state.ruleData.map((list, index) =>
                                                        <React.Fragment>
                                                            {list.type === "checkbox" || list.type === "switch" ?
                                                                <React.Fragment>
                                                                    <div className="leftContentbox">
                                                                        <div className="leftContentboxbody">

                                                                            <div className="leftContentHead">
                                                                                <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                                            </div>
                                                                            <div className="leftInputSection">
                                                                                <div className="split-rule-block">
                                                                                    <div className="rule-select-box">
                                                                                        <select
                                                                                            value={list.fieldItem.field_rule[0].trigger}
                                                                                            onChange={(e) => this.optionChange(e, list.name, list.type)}>
                                                                                            <option value="">Select
                                                                                            </option>
                                                                                            {list.opt.map((item, index) =>
                                                                                                <option
                                                                                                    key={`option` + index}
                                                                                                    value={item.text ? item.text : item}>{item.text ? item.text : item}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="split-rule-block">

                                                                                    <div className="leftInputaddIcons">
                                                                                        <button
                                                                                            className="btnsubIconsadd ico-lib">
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="leftInputaddIcons">
                                                                                        <button
                                                                                            className="btnsubIcon ico-lib"
                                                                                            onClick={(e) => this.removeFromWorkflow(e, list.name)}>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="lefttext">
                                                                            <p>and</p>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                list.type === "dropdown" ?
                                                                    <React.Fragment>
                                                                        <div className="leftContentbox">
                                                                            <div className="leftContentboxbody">

                                                                                <div className="leftContentHead">
                                                                                    <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                                                </div>
                                                                                <div className="leftInputSection">
                                                                                    <div className="split-rule-block">
                                                                                        <div
                                                                                            className="rule-select-box">
                                                                                            <select
                                                                                                value={list.fieldItem.field_rule[0].trigger}
                                                                                                onChange={(e) => this.optionChange(e, list.name, list.type)}>
                                                                                                <option
                                                                                                    value="">Select
                                                                                                </option>
                                                                                                {list.opt.map((item, index) =>
                                                                                                    <option
                                                                                                        key={`option` + index}
                                                                                                        value={item.text ? item.text : item}>{item.text ? item.text : item}</option>
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="split-rule-block">

                                                                                        <div
                                                                                            className="leftInputaddIcons">
                                                                                            <button
                                                                                                className="btnsubIconsadd ico-lib">
                                                                                            </button>
                                                                                        </div>
                                                                                        <div
                                                                                            className="leftInputaddIcons">
                                                                                            <button
                                                                                                className="btnsubIcon ico-lib"
                                                                                                onClick={(e) => this.removeFromWorkflow(e, list.name)}>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="lefttext">
                                                                                <p>and</p>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <div className="leftContentbox">
                                                                            <div className="leftContentboxbody">
                                                                                <div className="leftContentHead">
                                                                                    <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                                                </div>
                                                                                <div className="leftInputSection">
                                                                                    <div className="split-rule-block">
                                                                                        <div
                                                                                            className="rule-select-box">
                                                                                            <select
                                                                                                onChange={(e) => this.optionChange(e, list.name, "switch")}
                                                                                                value={list.fieldItem && list.fieldItem.field_rule[0].trigger}
                                                                                            >
                                                                                                {list.option && list.option.map((item, index) =>
                                                                                                    <option
                                                                                                        key={`option` + index}
                                                                                                        value={item.value}>{item.name}</option>
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="split-rule-block">
                                                                                        <div
                                                                                            className="rule-input-block">
                                                                                            <input type="text"
                                                                                                   placeholder={list.placeholder}
                                                                                                   name={`name` + index}
                                                                                                   value={list.fieldItem && list.fieldItem.field_rule[0].value}
                                                                                                   onChange={(e) => this.labelChange(e, list.name, list.type)}/>
                                                                                        </div>
                                                                                        <div
                                                                                            className="leftInputaddIcons">
                                                                                            <button
                                                                                                className="btnsubIcon ico-lib"
                                                                                                onClick={(e) => this.removeFromWorkflow(e, list.name)}>

                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="lefttext">
                                                                                    <p>and</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </React.Fragment>
                                                            }


                                                        </React.Fragment>
                                                    )}
                                                    <div className="rule-field-btn-block">
                                                        <div className="dropdown show active">
                                                            <button className="dropdown-toggle" type="button"
                                                                    onClick={() => this.setTab("dropdownrule")}>Choose <span
                                                                className="blue-bold"
                                                            >Field</span>
                                                            </button>
                                                            {this.state.dropdownrule === "dropdownrule" &&

                                                            <div className="dropdown-menu show active"
                                                                 aria-labelledby="dropdownMenu2">

                                                                {showlistField.map((list, index) =>
                                                                    <button className="dropdown-item" type="button"
                                                                            onClick={(e) => this.handleChange(e, list.label, list.type, list.option, list.masterid)}>{list.label}
                                                                    </button>
                                                                )}

                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {this.state.multiCondition === true &&
                                                <div className="new-modal-left-inner">
                                                    <div className="each-condition-continer">
                                                    {this.state.ruleData.map((list, indx) =>
                                                    
                                                    
                                                    <div className="each-multiple-wf">
                                                        <div className="each-inp-box">
                                                            <label>Workflow Act On</label>
                                                            <div className="radio-wrap">
                                                                <div className="radio-block">
                                                                    <input type="radio" name={"wftriggeron_"+indx} value="save"
                                                                           defaultChecked={list.trigger === "save"}
                                                                           onClick={(e) => {
                                                                               this.workflowtriggeronSelectMultiple('save', indx)
                                                                           }}/>
                                                                    <label>Insert</label>
                                                                </div>
                                                                <div className="radio-block">
                                                                    <input type="radio" name={"wftriggeron_"+indx} value="update"
                                                                           defaultChecked={list.trigger === "update"}
                                                                           onClick={(e) => {
                                                                               this.workflowtriggeronSelectMultiple('update', indx)
                                                                           }}/>
                                                                    <label>Update</label>
                                                                </div>
                                                                <div className="radio-block">
                                                                    <input type="radio" name={"wftriggeron_"+indx} value="save"
                                                                           defaultChecked={list.trigger === "both"}
                                                                           onClick={(e) => {
                                                                               this.workflowtriggeronSelectMultiple('both', indx)
                                                                           }}/>
                                                                    <label>Both</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-box">
                                                            <label>Choose Stage</label>
                                                            <div className="inline-select" >
                                                                <div className="select-box">
                                                                    <select id="optionField" name="optionField" onChange={(e)=>this.handleOptionChange(e, indx)} >
                                                                        <option value={list.stage}>{list.name && list.name !== '' ? list.name : 'Select'}</option>
                                                                        {this.state.stageList.length !== 0 && this.state.stageList.map((data,index) =>
                                                                            <>
                                                                            {list.stage !== data.id &&
                                                                            <option value={data.id} key={index}>{data.name}</option>
                                                                            }
                                                                            </>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="add-multiple-workflow-box">
                                                                {indx > 0 &&
                                                                <button className="btnsubIcon ico-lib" onClick={(e) => this.removeConditions(e, indx, list)}></button>
                                                                }
                                                                {indx === this.state.ruleData.length-1 &&
                                                                <button className="btnsubIconsadd ico-lib"
                                                                        onClick={(e) => this.appendConditions(e, indx, list)}></button>
                                                                }
                                                            </div>
                                                        <>
                                                        {list.condition.length !== 0 && list.condition.map((list, index) =>
                                                            <React.Fragment>
                                                                {list.type === "checkbox" || list.type === "switch" ?
                                                                    <React.Fragment>
                                                                        <div className="leftContentbox">
                                                                            <div className="leftContentboxbody">

                                                                                <div className="leftContentHead">
                                                                                    <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                                                </div>
                                                                                <div className="leftInputSection">
                                                                                    <div className="split-rule-block">
                                                                                        <div className="rule-select-box">
                                                                                            <select
                                                                                                value={list.fieldItem.field_rule[0].trigger}
                                                                                                onChange={(e) => this.optionChangeWorkflow(e, list.name, list.type, indx)}>
                                                                                                <option value="">Select
                                                                                                </option>
                                                                                                {list.opt.map((item, index) =>
                                                                                                    <option
                                                                                                        key={`option` + index}
                                                                                                        value={item.text ? item.text : item}>{item.text ? item.text : item}</option>
                                                                                                )}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="split-rule-block">

                                                                                        <div className="leftInputaddIcons">
                                                                                            <button
                                                                                                className="btnsubIconsadd ico-lib">
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="leftInputaddIcons">
                                                                                            <button
                                                                                                className="btnsubIcon ico-lib"
                                                                                                onClick={(e) => this.removeFromWorkflow(e, list.name, indx)}>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="lefttext">
                                                                                <p>and</p>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                    :
                                                                    list.type === "dropdown" ?
                                                                        <React.Fragment>
                                                                            <div className="leftContentbox">
                                                                                <div className="leftContentboxbody">

                                                                                    <div className="leftContentHead">
                                                                                        <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                                                    </div>
                                                                                    <div className="leftInputSection">
                                                                                        <div className="split-rule-block">
                                                                                            <div
                                                                                                className="rule-select-box">
                                                                                                <select
                                                                                                    value={list.fieldItem.field_rule[0].trigger}
                                                                                                    onChange={(e) => this.optionChangeWorkflow(e, list.name, list.type, indx)}>
                                                                                                    <option
                                                                                                        value="">Select
                                                                                                    </option>
                                                                                                    {list.opt.map((item, index) =>
                                                                                                        <option
                                                                                                            key={`option` + index}
                                                                                                            value={item.text ? item.text : item}>{item.text ? item.text : item}</option>
                                                                                                    )}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="split-rule-block">

                                                                                            <div
                                                                                                className="leftInputaddIcons">
                                                                                                <button
                                                                                                    className="btnsubIconsadd ico-lib">
                                                                                                </button>
                                                                                            </div>
                                                                                            <div
                                                                                                className="leftInputaddIcons">
                                                                                                <button
                                                                                                    className="btnsubIcon ico-lib"
                                                                                                    onClick={(e) => this.removeFromWorkflow(e, list.name, indx)}>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="lefttext">
                                                                                    <p>and</p>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                        :
                                                                        <React.Fragment>
                                                                            <div className="leftContentbox">
                                                                                <div className="leftContentboxbody">
                                                                                    <div className="leftContentHead">
                                                                                        <p className="ico-lib-v1 txtboxHead">{list.label}</p>
                                                                                    </div>
                                                                                    <div className="leftInputSection">
                                                                                        <div className="split-rule-block">
                                                                                            <div
                                                                                                className="rule-select-box">
                                                                                                <select
                                                                                                    onChange={(e) => this.optionChangeWorkflow(e, list.name, "switch", indx)}
                                                                                                    value={list.fieldItem.field_rule[0].trigger}
                                                                                                >
                                                                                                    {list.option.map((item, index) =>
                                                                                                        <option
                                                                                                            key={`option` + index}
                                                                                                            value={item.value}>{item.name}</option>
                                                                                                    )}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="split-rule-block">
                                                                                            <div
                                                                                                className="rule-input-block">
                                                                                                <input type="text"
                                                                                                    placeholder={list.placeholder}
                                                                                                    name={`name` + index}
                                                                                                    value={list.fieldItem.field_rule[0].value}
                                                                                                    onChange={(e) => this.labelChangeWorkflow(e, list.name, list.type, indx)}/>
                                                                                            </div>
                                                                                            <div
                                                                                                className="leftInputaddIcons">
                                                                                                <button
                                                                                                    className="btnsubIcon ico-lib"
                                                                                                    onClick={(e) => this.removeFromWorkflow(e, list.name, indx)}>

                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="lefttext">
                                                                                        <p>and</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </React.Fragment>
                                                                }


                                                            </React.Fragment>
                                                        )}
                                                        </>

                                                        <div className="rule-field-btn-block">
                                                            <div className="dropdown show active">
                                                                <button className="dropdown-toggle" type="button"
                                                                        onClick={() => this.setTab("dropdownrule_"+indx)}>Choose <span
                                                                    className="blue-bold"
                                                                >Field</span>
                                                                </button>
                                                                {this.state.dropdownrule === "dropdownrule_"+indx &&

                                                                <div className="dropdown-menu show active"
                                                                    aria-labelledby="dropdownMenu2">

                                                                    {showlistField.map((list, index) =>
                                                                        <button className="dropdown-item" type="button"
                                                                                onClick={(e) => this.handleChangeMultipleWorkflow(e, list.label, list.type, list.option, list.masterid, indx)}>{list.label}
                                                                        </button>
                                                                    )}

                                                                </div>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                    

                                                    
                                                    )}
                                                    </div>
                                                </div>
                                                }
                                            </Scrollbars>
                                        </div>
                                        <div className="new-modal-right">
                                            <Scrollbars autoHide autoHideTimeout={1000}>
                                                <div className="new-modal-right-inner">
                                                    <div className="each-inp-box">
                                                        <div className="rightdateinput">
                                                            <label>Choose Type</label>
                                                            <div className="radio-wrap">
                                                                <div className="radio-block">
                                                                    <input type="radio" name="tabSelect"
                                                                           defaultChecked={this.state.tabAdd === "fieldwise"}
                                                                           onClick={(e) => {
                                                                               this.tabSelectAdd('fieldwise')
                                                                           }}/>
                                                                    <label>Rule</label>
                                                                </div>
                                                                <div className="radio-block">
                                                                    <input type="radio" name="tabSelect"
                                                                           defaultChecked={this.state.tabAdd === "workflow"}
                                                                           onClick={(e) => {
                                                                               this.tabSelectAdd('workflow')
                                                                           }}/>
                                                                    <label>Workflow</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(this.state.tabAdd === "fieldwise" && this.state.fieldwise) &&
                                                        <>
                                                            <div className="rightdateinput">
                                                                <label>Validity Period</label>
                                                                <div className="inputdatefield">
                                                                    <div
                                                                        className="task-input-block eq-width datefieldright picker-bt">
                                                                        <DateTimePickerBox
                                                                            handleDateChange={this.handleFromChange}
                                                                            placeholderText="Start date"
                                                                            id="startDate"
                                                                            timeFormat={false}
                                                                            startDate={this.state.editData.startDate}
                                                                            previousDate={true}
                                                                        />
                                                                        <button className="ico-lib inputdate"></button>
                                                                    </div>
                                                                    <div
                                                                        className="task-input-block eq-width datefieldright picker-bt">
                                                                        <DateTimePickerBox
                                                                            handleDateChange={this.handleToChange}
                                                                            placeholderText="End date"
                                                                            id="endDate"
                                                                            timeFormat={false}
                                                                            startDate={this.state.editData.endDate}
                                                                            previousDate={true}
                                                                        />
                                                                        <button className="ico-lib inputdate"></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rightdateinput">
                                                                <label>Frequency</label>
                                                                <div className="rightinputdropdown">
                                                                    <div className="rule-select-box"  id='type'>
                                                                        <select name="type"
                                                                                onChange={this.handlelabelChange}
                                                                                value={this.state.editData.type}>
                                                                            <option value="">Select</option>
                                                                            <option value="Daily">Daily</option>
                                                                            <option value="Weekly">Weekly</option>
                                                                            <option value="Biweekly">Biweekly</option>
                                                                            <option value="Monthly">Monthly</option>
                                                                            <option value="Quarterly">Quarterly</option>
                                                                            <option value="HalfYearly">HalfYearly
                                                                            </option>
                                                                            <option value="Annual">Annual</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.editData.type === "Weekly" &&
                                                            <div className="rightdateinput">
                                                                <label>Rule Pattern</label>
                                                                <div className="weekmarkarea">
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Sunday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Sunday')}
                                                                                   onClick={() => this.handlePattern("Sunday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Monday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Monday')}
                                                                                   onClick={() => this.handlePattern("Monday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Tuesday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Tuesday')}
                                                                                   onClick={() => this.handlePattern("Tuesday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Wednesday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Wednesday')}
                                                                                   onClick={() => this.handlePattern("Wednesday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Thursday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Thursday')}
                                                                                   onClick={() => this.handlePattern("Thursday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Friday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Friday')}
                                                                                   onClick={() => this.handlePattern("Friday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> Saturday
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('Saturday')}
                                                                                   onClick={() => this.handlePattern("Saturday")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            }
                                                            {this.state.editData.type === "Monthly" &&
                                                            <div className="rightdateinput">
                                                                <label>Rule Pattern</label>
                                                                <div className="weekmarkarea">
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> January
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('January')}
                                                                                   onClick={() => this.handlePattern("January")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> February
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('February')}
                                                                                   onClick={() => this.handlePattern("February")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> March
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('March')}
                                                                                   onClick={() => this.handlePattern("March")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> April
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('April')}
                                                                                   onClick={() => this.handlePattern("April")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> May
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('May')}
                                                                                   onClick={() => this.handlePattern("May")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> June
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('June')}
                                                                                   onClick={() => this.handlePattern("June")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> July
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('July')}
                                                                                   onClick={() => this.handlePattern("July")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> August
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('August')}
                                                                                   onClick={() => this.handlePattern("August")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> September
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('September')}
                                                                                   onClick={() => this.handlePattern("September")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> October
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('October')}
                                                                                   onClick={() => this.handlePattern("October")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> November
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('November')}
                                                                                   onClick={() => this.handlePattern("November")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="select-chk-block checkbox-rules">
                                                                        <label
                                                                            className="rulescheckbox control control-checkbox"> December
                                                                            <input type="checkbox" className="inp-chk"
                                                                                   defaultChecked={this.state.editData.pattern.includes('December')}
                                                                                   onClick={() => this.handlePattern("December")}/>
                                                                            <div className="control-indicator"></div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            }

                                                            <div className="rightdateinput">
                                                                <label>Action Type</label>
                                                                <div className="rule-select-box" id='rule_type'>
                                                                    <select name="rule_type"
                                                                            onChange={this.handlelabelChange}>
                                                                        <option>{this.state.editData.rule_type !== '' ? (this.state.editData.rule_type === 'pool' ? 'Pool Task' : this.state.editData.rule_type === 'customapi' ? 'Custom API' : this.state.editData.rule_type === 'autoassign' ? 'Auto Assign' : this.state.editData.rule_type) : 'Select'}</option>
                                                                        <option value={`Notification`}>Notification
                                                                        </option>
                                                                        <option value={`Task`}>Task</option>
                                                                        <option value={`pool`}>Pool Task</option>
                                                                        <option value={`customapi`}>Custom API</option>
                                                                        <option value={`autoassign`}>Auto Assign</option>
                                                                        <option value={`email`}>Email</option>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                            {this.state.editData.rule_type === 'customapi' &&
                                                            <React.Fragment>
                                                            <div className="taskdescsection">
                                                                <div className="assignInputdesc">
                                                                    <textarea id="customapi"
                                                                              name="customapi" placeholder="Enter Custom API Url"
                                                                              value={this.state.editData.customapi}
                                                                              onChange={this.handlelabelChange}
                                                                              className={`task-textarea-form ${this.state.errors.customapi ? " val-error" : ''}`}
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>
                                                            <div className="rightdateinput">
                                                                <label>Method</label>
                                                                <div className="radio-wrap">
                                                                    <div className="radio-block">
                                                                        <input type="radio" value='get' name="customapimethod"
                                                                               defaultChecked={this.state.editData.customapimethod && this.state.editData.customapimethod === "get"}
                                                                               onChange={this.handlelabelChange} />
                                                                        <label>GET</label>
                                                                    </div>
                                                                    <div className="radio-block">
                                                                        <input type="radio" value='post' name="customapimethod"
                                                                               defaultChecked={this.state.editData.customapimethod && this.state.editData.customapimethod === "post"}
                                                                               onChange={this.handlelabelChange} />
                                                                        <label>POST</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="rightdateinput">
                                                                <label>Params</label>
                                                                {this.state.editData.customapiparam && this.state.editData.customapiparam.map((list, index) =>

                                                                        <div className="post-param-panel">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="ac-text-box email-sub">
                                                                                    <input type="text" placeholder="param" className="e-field"
                                                                                        value={list.param} name="param"
                                                                                        onChange={(e)=>this.handlePostParamChange(e, "param", list.param,  index)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="taskdescsection">
                                                                                    <div className="assignInputdesc">
                                                                                        <Mentions
                                                                                            showFieldList={this.state.showFieldList}
                                                                                            value={list.value}
                                                                                            index={index}
                                                                                            name="value"
                                                                                            handlechangeValue={this.handlePostParamChange}
                                                                                            singleLine={true}
                                                                                            placeholder={"value"}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="add-multiple-box">
                                                                                {index > 0 &&
                                                                                <button className="btnsubIcon ico-lib" onClick={(e) => this.removeParamBox(e, index)}></button>
                                                                                }
                                                                                {index === this.state.editData.customapiparam.length-1 &&
                                                                                <button className="btnsubIconsadd ico-lib"
                                                                                        onClick={(e) => this.appendParamBox(e, index)}></button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="rightdateinput">
                                                                    <label>TRIGGER ON</label>
                                                                    <div className="radio-wrapper-new">
                                                                        <div className="radio-block">
                                                                            <input type="radio" name="customapi_trigger"
                                                                                   defaultChecked={this.state.editData.customapi_trigger === "submit"}
                                                                                   value={"submit"}
                                                                                   onChange={this.handlelabelChange}
                                                                            />
                                                                            <label>Submit</label>
                                                                        </div>
                                                                        <div className="radio-block">
                                                                            <input type="radio" name="customapi_trigger"
                                                                                   defaultChecked={this.state.editData.customapi_trigger === "draft"}
                                                                                   value={`draft`}
                                                                                   onChange={this.handlelabelChange}
                                                                            />
                                                                            <label>Save As Draft</label>
                                                                        </div>

                                                                        {/*<div className="radio-block">*/}
                                                                        {/*    <input type="radio" name="trigeron"*/}
                                                                        {/*           defaultChecked={this.state.editData.trigeron === "none"}*/}
                                                                        {/*           value={``}*/}
                                                                        {/*           onChange={this.handlelabelChange}*/}
                                                                        {/*    />*/}
                                                                        {/*    <label>None</label>*/}
                                                                        {/*</div>*/}

                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                            }
                                                            {this.state.editData.rule_type !== 'customapi' &&
                                                            <React.Fragment>
                                                                <div className="rightdateinput">
                                                                    <label>Assign to</label>
                                                                    <div className="assignSection" id='selectedfieldUsers'>
                                                                        {/*<div className="assignsectionTitle">*/}
                                                                        {/*    <p>Assign To</p> <a className="assignImport"*/}
                                                                        {/*                        href="javascript:void(0);">Import Field</a>*/}
                                                                        {/*</div>*/}
                                                                        <div className="task-inp-container">
                                                                            <AutoComplete
                                                                                fieldTitle='To'
                                                                                placeholder="Type here"
                                                                                sourceDataList={this.state.userList}
                                                                                searchField='fullname'
                                                                                showIcon={false}
                                                                                hideMainLabel={false}
                                                                                showSubLabel={false}
                                                                                labelFields='fullname'
                                                                                valueField='user_id'
                                                                                subLabelField='designation'
                                                                                showProfile={true}
                                                                                reference='selectedfieldUsers'
                                                                                selectedItems={this.props.selectedfieldUsers}
                                                                                resultDataList={this.props.resultDataList}
                                                                                ignoredValues={this.props.ignoredfieldValues}
                                                                                id="to_users"
                                                                                actionMethod={this.props.getUsers}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    {this.state.editData.rule_type !== 'email' &&
                                                                    <div className="rightdateinput">
                                                                        <label>Description</label>
                                                                        <div className="taskdescsection">
                                                                            <div className="assignInputdesc">
                                                                                <textarea id="title"
                                                                                          name="ruleDescription"
                                                                                          placeholder="Type Here"
                                                                                          value={this.state.editData.ruleDescription}
                                                                                          onChange={this.handlelabelChange}
                                                                                          className={`task-textarea-form ${this.state.errors.ruleDescription ? " val-error" : ''}`}
                                                                                >

                                                                                </textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                    {(this.state.editData.rule_type === 'Task' || this.state.editData.rule_type === 'autoassign') &&
                                                                    <div className="rightdateinput">
                                                                        <label>Title</label>
                                                                        <div className="taskdescsection">
                                                                            <div className="assignInputdesc">
                                                                                <input id="task_title"
                                                                                       name="ruletitle"
                                                                                       placeholder="Task title"
                                                                                       value={this.state.editData.ruletitle}
                                                                                       onChange={this.handlelabelChange}
                                                                                       className={`task-textarea-form ${this.state.errors.ruletitle ? " val-error" : ''}`}
                                                                                >

                                                                                </input>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    }


                                                                {this.state.editData.rule_type === "email" &&
                                                                <React.Fragment>
                                                                    <div className="rightdateinput">
                                                                        <label>CC</label>
                                                                        <div className="assignSection" id='selectedfieldUsersCc'>
                                                                            <div className="task-inp-container">
                                                                                <AutoComplete
                                                                                    fieldTitle='cc'
                                                                                    placeholder="Type here"
                                                                                    sourceDataList={this.state.userList}
                                                                                    searchField='fullname'
                                                                                    showIcon={false}
                                                                                    hideMainLabel={false}
                                                                                    showSubLabel={false}
                                                                                    labelFields='fullname'
                                                                                    valueField='user_id'
                                                                                    subLabelField='designation'
                                                                                    showProfile={true}
                                                                                    reference='selectedfieldUsersCc'
                                                                                    selectedItems={this.props.selectedfieldUsersCc}
                                                                                    resultDataList={this.props.resultDataList}
                                                                                    ignoredValues={this.props.ignoredfieldValuesCc}
                                                                                    id="to_usersCc"
                                                                                    actionMethod={this.props.getUsers}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="rightdateinput">
                                                                        <label>External Mail</label>
                                                                        <div className="radio-wrapper-new">
                                                                            <div className="radio-block">
                                                                                <input type="radio" name="has_externalmail"
                                                                                       defaultChecked={this.state.editData.has_externalmail === 'true' || this.state.editData.has_externalmail === true}
                                                                                       value={true}
                                                                                       onChange={this.handlelabelChange}
                                                                                />
                                                                                <label>Yes</label>
                                                                            </div>
                                                                            <div className="radio-block">
                                                                                <input type="radio" name="has_externalmail"
                                                                                       defaultChecked={this.state.editData.has_externalmail === 'false' || this.state.editData.has_externalmail === false}
                                                                                       value={false}
                                                                                       onChange={this.handlelabelChange}
                                                                                />
                                                                                <label>No</label>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    {this.state.editData.has_externalmail === 'true' &&
                                                                    <React.Fragment>
                                                                        <div className="rightdateinput">
                                                                            <label>To</label>
                                                                            <div className="taskdescsection">
                                                                                <div className="assignInputdesc">
                                                                                    <Mentions
                                                                                        showFieldList={this.state.showEmailList}
                                                                                        value={this.state.editData.external_to}
                                                                                        name={'external_to'}
                                                                                        handlechangeValue={this.handlechangeValue}
                                                                                        singleLine={true}
                                                                                        placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="rightdateinput">
                                                                            <label>CC</label>
                                                                            <div className="taskdescsection">
                                                                                <div className="assignInputdesc">
                                                                                    <Mentions
                                                                                        showFieldList={this.state.showEmailList}
                                                                                        value={this.state.editData.external_cc}
                                                                                        name={'external_cc'}
                                                                                        handlechangeValue={this.handlechangeValue}
                                                                                        singleLine={true}
                                                                                        placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                    }

                                                                    <div className="rightdateinput">
                                                                        <label>Email Subject</label>
                                                                        <div className="taskdescsection">
                                                                            <div className="assignInputdesc">
                                                                                <Mentions
                                                                                    showFieldList={this.state.showFieldList}
                                                                                    value={this.state.editData.ruleEmailsubject}
                                                                                    name={'ruleEmailsubject'}
                                                                                    handlechangeValue={this.handlechangeValue}
                                                                                    singleLine={true}
                                                                                    placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="rightdateinput">
                                                                        <label>Email Content</label>
                                                                        <div className="mention-textarea">
                                                                                <Mentions
                                                                                    showFieldList={this.state.showFieldList}
                                                                                    value={this.state.editData.ruleDescription}
                                                                                    name={'ruleDescription'}
                                                                                    handlechangeValue={this.handlechangeValue}
                                                                                    singleLine={false}
                                                                                    placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                                />
                                                                        </div>
                                                                    </div>

                                                                </React.Fragment>
                                                                }
                                                                <div className="rightdateinput">
                                                                    <label>Rule Act On</label>
                                                                    <div className="radio-wrap">
                                                                        <div className="radio-block">
                                                                            <input type="radio" name="triggeron" value="save"
                                                                                   defaultChecked={this.state.editData.triggeron === "save"}
                                                                                   onClick={(e) => {
                                                                                       this.triggeronSelect('save')
                                                                                   }}/>
                                                                            <label>Insert</label>
                                                                        </div>
                                                                        <div className="radio-block">
                                                                            <input type="radio" name="triggeron" value="update"
                                                                                   defaultChecked={this.state.editData.triggeron === "update"}
                                                                                   onClick={(e) => {
                                                                                       this.triggeronSelect('update')
                                                                                   }}/>
                                                                            <label>Update</label>
                                                                        </div>
                                                                        <div className="radio-block">
                                                                            <input type="radio" name="triggeron" value="save"
                                                                                   defaultChecked={this.state.editData.triggeron === "both"}
                                                                                   onClick={(e) => {
                                                                                       this.triggeronSelect('both')
                                                                                   }}/>
                                                                            <label>Both</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {this.state.editData.rule_type !== 'autoassign' &&
                                                                <React.Fragment>
                                                                    <div className="rightdateinput">
                                                                        <label>Priority</label>
                                                                        <div className="rightinputdropdown">
                                                                            <div className="rule-select-box"  id='priority'>
                                                                                <select name={`priority`}
                                                                                        onChange={this.handlelabelChange}
                                                                                        value={this.state.editData.priority}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="Low">Low</option>
                                                                                    <option value="Medium">Medium
                                                                                    </option>
                                                                                    <option value="High">High</option>
                                                                                    <option value="Urgent">Urgent
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.editData.rule_type !== "email" &&
                                                                    <div className="rightdateinput">
                                                                        <div className="select-chk-block"
                                                                             style={{"display": "block"}}>
                                                                            <label className="control control-checkbox">Individual
                                                                                Task
                                                                                <input type="checkbox"
                                                                                       className="inp-chk"
                                                                                       id="text_searchable"
                                                                                       value={this.state.editData.indvidualtask}
                                                                                       defaultChecked={this.state.editData.indvidualtask}
                                                                                       onClick={(e) => this.handleIndividualTask(e)}/>
                                                                                <div
                                                                                    className="control-indicator"></div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                            }

                                                            {this.state.editData.rule_type === "autoassign" &&
                                                            <React.Fragment>
                                                                <div className="rightdateinput">
                                                                <label>SEND EMAIL NOTIFICATION</label>
                                                                <div className="radio-wrapper-new">
                                                                    <div className="radio-block">
                                                                        <input type="radio" name="assigned_email"
                                                                               defaultChecked={this.state.editData.assigned_email === "yes"}
                                                                               value={"yes"}
                                                                               onChange={this.handlelabelChange}
                                                                        />
                                                                        <label>yes</label>
                                                                    </div>
                                                                    <div className="radio-block">
                                                                        <input type="radio" name="assigned_email"
                                                                               defaultChecked={this.state.editData.assigned_email === "no"}
                                                                               value={`no`}
                                                                               onChange={this.handlelabelChange}
                                                                        />
                                                                        <label>no</label>
                                                                    </div>

                                                                    {/*<div className="radio-block">*/}
                                                                    {/*    <input type="radio" name="trigeron"*/}
                                                                    {/*           defaultChecked={this.state.editData.trigeron === "none"}*/}
                                                                    {/*           value={``}*/}
                                                                    {/*           onChange={this.handlelabelChange}*/}
                                                                    {/*    />*/}
                                                                    {/*    <label>None</label>*/}
                                                                    {/*</div>*/}

                                                                </div>
                                                            </div>
                                                            {this.state.editData.assigned_email === "yes" &&
                                                            <React.Fragment>
                                                                <div className="rightdateinput">
                                                                    <label>CC</label>
                                                                    <div className="assignSection" id='selectedfieldUsersCc'>
                                                                        <div className="task-inp-container">
                                                                            <AutoComplete
                                                                                fieldTitle='cc'
                                                                                placeholder="Type here"
                                                                                sourceDataList={this.state.userList}
                                                                                searchField='fullname'
                                                                                showIcon={false}
                                                                                hideMainLabel={false}
                                                                                showSubLabel={false}
                                                                                labelFields='fullname'
                                                                                valueField='user_id'
                                                                                subLabelField='designation'
                                                                                showProfile={true}
                                                                                reference='selectedfieldUsersCc'
                                                                                selectedItems={this.props.selectedfieldUsersCc}
                                                                                resultDataList={this.props.resultDataList}
                                                                                ignoredValues={this.props.ignoredfieldValuesCc}
                                                                                id="to_usersCc"
                                                                                actionMethod={this.props.getUsers}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="rightdateinput">
                                                                    <label>Email Subject</label>
                                                                    <div className="taskdescsection">
                                                                        <div className="assignInputdesc">
                                                                            <Mentions
                                                                                showFieldList={this.state.showFieldList}
                                                                                value={this.state.editData.ruleEmailsubject}
                                                                                name={'ruleEmailsubject'}
                                                                                handlechangeValue={this.handlechangeValue}
                                                                                singleLine={true}
                                                                                placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="rightdateinput">
                                                                    <label>Email Content</label>
                                                                    <div className="taskdescsection">
                                                                        <div className="assignInputdesc">
                                                                            <Mentions
                                                                                showFieldList={this.state.showFieldList}
                                                                                value={this.state.editData.mailcontent}
                                                                                name={'mailcontent'}
                                                                                handlechangeValue={this.handlechangeValue}
                                                                                singleLine={false}
                                                                                placeholder={"Type anything, use the @ symbol to mention form fields."}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                            }
                                                            </React.Fragment>
                                                            }

                                                        </>
                                                        }
                                                        {this.state.tabAdd === "workflow" && this.state.fieldwise &&
                                                        <>
                                                        <div className="rightdateinput">
                                                            <div className="rightinputdropdown">
                                                                <div className="rule-select-box">
                                                                    <select onChange={(e)=>this.changeWorkflow(e)} >
                                                                        <option value={this.state.workflowids}>{this.state.workflowNames !== '' ? this.state.workflowNames : 'Select'}</option>
                                                                        {this.state.workflowList.map((getWorkflow, index) =>
                                                                            <option key={`get-work-flow` + index}
                                                                                    value={getWorkflow.id}>{getWorkflow.name}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="rightdateinput">
                                                            <label>Choose Condition Type</label>
                                                            <div className="radio-wrap">
                                                                <div className="radio-block">
                                                                    <input type="radio" name="multiCondition"
                                                                           defaultChecked={this.state.multiCondition === false}
                                                                           onClick={(e) => {
                                                                               this.conditionSelect(false)
                                                                           }}/>
                                                                    <label>Single</label>
                                                                </div>
                                                                <div className="radio-block">
                                                                    <input type="radio" name="multiCondition"
                                                                           defaultChecked={this.state.multiCondition === true}
                                                                           onClick={(e) => {
                                                                               this.conditionSelect(true)
                                                                           }}/>
                                                                    <label>Multiple</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.multiCondition === false &&
                                                        <div className="rightdateinput">
                                                            <label>Workflow Act On</label>
                                                            <div className="radio-wrap">
                                                                <div className="radio-block">
                                                                    <input type="radio" name="triggeron" value="save"
                                                                           defaultChecked={this.state.workflowtriggeron === "save"}
                                                                           onClick={(e) => {
                                                                               this.workflowtriggeronSelect('save')
                                                                           }}/>
                                                                    <label>Insert</label>
                                                                </div>
                                                                <div className="radio-block">
                                                                    <input type="radio" name="triggeron" value="update"
                                                                           defaultChecked={this.state.workflowtriggeron === "update"}
                                                                           onClick={(e) => {
                                                                               this.workflowtriggeronSelect('update')
                                                                           }}/>
                                                                    <label>Update</label>
                                                                </div>
                                                                <div className="radio-block">
                                                                    <input type="radio" name="triggeron" value="save"
                                                                           defaultChecked={this.state.workflowtriggeron === "both"}
                                                                           onClick={(e) => {
                                                                               this.workflowtriggeronSelect('both')
                                                                           }}/>
                                                                    <label>Both</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                       </>
                                                        }

                                                    </div>

                                                </div>
                                            </Scrollbars>
                                        </div>
                                    </div>

                                    <div className="new-modal-footer">
                                        <button type="button" className="general-btn"
                                                onClick={(e) => this.addGeneralRule(e)}>{this.state.addStatus}
                                        </button>
                                    </div>

                                </div>
                                }

                            </div>


                        </div>
                    </div>
                </div>
                {this.state.showDeleteGeneric &&
                <AlertModal id="take-pool"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_button_style="btn-danger"
                            OK_action={this.handleDelete}
                            showModal = {this.state.showDeleteGeneric}
                            CANCEL_action={this.showDeleteConfirm}
                />
                }
                {this.state.removeWorkflowTest &&
                <AlertModal id="take-pool"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_button_style="btn-danger"
                            OK_action={this.handleRemove}
                            showModal = {this.state.removeWorkflowTest}
                            CANCEL_action={this.removeWorkflowConfirm}
                />
                }
            </div>
        );
    }
}


export default RuleModal;
