
export const elements = {
  TEXTBOX: 'TEXTBOX',
  SLIDER: 'SLIDER',
  HEADER: 'HEADER',
  LABEL:"LABEL",
  TITLE:"TITLE",
  DESCRIPTION:"DESCRIPTION",
  IMAGE:"IMAGE",
  GOOGLEMAP:"GOOGLEMAP",
  LIST:"LIST",
  FLEXIBLECONTENT:"FLEXIBLECONTENT",
  DROPDOWN: 'DROPDOWN',
  GRID_LAYOUT_3_3: 'GRID_LAYOUT_3_3',
  GRID_LAYOUT_1_2: 'GRID_LAYOUT_1_2',
  CARD_LAYOUT:'CARD_LAYOUT',
  LISTCARD:"LISTCARD",
  VIDEOCARD:"VIDEOCARD",
  BAR_CHART_CONTAINER:"BAR_CHART_CONTAINER",
  PIE_CHART_CONTAINER:"PIE_CHART_CONTAINER",
  AREA_CHART_CONTAINER:"AREA_CHART_CONTAINER",
  AREA_BAR_CHART_CONTAINER:"AREA_BAR_CHART_CONTAINER",
  LINE_CHART_CONTAINER:"LINE_CHART_CONTAINER",
  RESIZABLE_BOX_CONTAINER:"RESIZABLE_BOX_CONTAINER",
  ACCORDION:"ACCORDION",
  BUTTON_BLOCK:"BUTTON_BLOCK",
  SUMMMARYCARD: 'SUMMMARYCARD',
  PAYMENT_CONTAINER:"PAYMENT_CONTAINER",
  FAQ_CONTAINER:"FAQ_CONTAINER",
  GROUP_TABLE:"GROUP_TABLE",
};
