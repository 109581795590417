import React, {Component} from "react";
import FormFields from "./FormFields";
import AutoComplete from "../AutoComplete";
import {Dropdown} from "./FormFieldData";

class ColumnBreak extends Component {

    constructor(props) {
        super(props);
        this.addNewFieldSet = this.addNewFieldSet.bind(this)
    }

    addNewFieldSet = (e, refElementIndex, refElement) => {
        //console.log(refElementIndex)
        e.preventDefault()
        e.stopPropagation();
        this.props.appendElement(this.props.pageIndex, refElementIndex, refElement, -1,'yes')
    }

    render() {

        let children = this.props.children;
        ////console.log(children)

        let attributes = this.props.attributes;
        let pageIndex = this.props.pageIndex;
        let page = this.props.page;


        ////console.log(page);
        return (

            <div className={`ind-form-field column-break ${(attributes.showInForm === 'No')  && 'hide '} ${(attributes.focus && attributes.focus === 'Yes')  && ' focus-ele '} ${(attributes.fieldsetview === 'vertical'? " flex-box ": " ")}  ${(attributes.singlepanel && attributes.singlepanel === 'yes') && " singlepanel-fieldset "}`} data-id={attributes.masterid} id={attributes.masterid} >
                <div className="form-header-block">
                    <div className="style-view">
                        <div className="row">
                            {children.map((child, fieldIndex)=>
                                <React.Fragment key={"fieldset-"+fieldIndex}>
                                        <div className="col">
                                            {this.props.renderFormFields(child, parseInt(this.props.fieldIndex) + parseInt(fieldIndex)+1, page, pageIndex)}
                                        </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ColumnBreak;