import React, {Component} from 'react';
import moment from "moment";
import {Datecomparison, formatDate} from "../../utilities/CustomFunctions";
import {getProfile} from "../../utilities/AuthService";
import OutsideAlerter from "./OutsideAlerter";


class VerticalTile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showOption : false
        }
        this.showOptions = this.showOptions.bind(this);
    }
    componentDidMount() {
    }

    showOptions(){
        this.setState({showOption : !this.state.showOption});
    }
    render() {
        var list = this.props.list ? this.props.list : '';
        var currentDate = moment().utc().local();
        var endDate = this.props.endDate;
        let description = decodeURIComponent(escape(atob(list.description)))
        return(
            <div className="ind-project-list" key={list.project_id}>
                <div className="project-list-header">
                    <h5>{list.portfolioName}</h5>
                    {(getProfile().usertype === 'root' || getProfile().id === list.creator) &&
                    <div className="project-option-block">
                        <button className="ico-lib-v1 more-sym" onClick={() => this.showOptions()}
                                style={{pointerEvents: this.state.showOption === "true" ? "none" : ''}}></button>
                        {this.state.showOption &&
                        <OutsideAlerter status={this.state.showOption} Triggered={() => this.showOptions()}>
                            <ul className="panel-option" style={{display: "block"}}>
                                <li>
                                    <a className="panel-option-click ico-lib form-edit-ico"
                                       onClick={(e) => this.props.AddmodalOpen(e, list.programId)}>Edit</a>
                                </li>
                                {/*{list.activeProjects === 0 &&*/}
                                {/*<li>*/}
                                {/*    <a className="panel-option-click ico-lib delete-task-ico"*/}
                                {/*       onClick={(e) => this.props.DelmodalOpen(e, list.programId)}>Delete</a>*/}
                                {/*</li>*/}
                                {/*}*/}
                            </ul>
                        </OutsideAlerter>
                        }
                    </div>
                    }
                </div>
                <div className="project-list-body">
                    <div className="project-list-desc-block">
                        <h5>{list.programName}</h5>
                        <p dangerouslySetInnerHTML={{__html: ((((description.length > 100) ? (description.substr(0, 100)+'...') : description)))}} />
                    </div>
                </div>
                <div className="project-list-footer">
                    <h5>No: of Projects :  {list.activeProjects}</h5>
                    <p>Created On {moment.utc(list.createdDate).format('MMM DD, YYYY')}</p>
                </div>
            </div>
        );
    }
}

export default VerticalTile;