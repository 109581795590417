import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import {Draggable, Dropzone, state} from 'react-page-maker';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../../../actions/DynamicDashboard";

class GeMobileAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iniData: [{
                id: 1
            }],

        }
    }
    componentDidMount() {

        this.setState({payload:this.props.payload})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.getToggle !== prevProps.getToggle){

            if( this.state.payload !== undefined  &&  this.props.getToggle.status !== this.props.id) {
                const {id, dropzoneID, parentID} = this.props;
                let payload = this.state.payload
                payload.status = "closed"
                this.setState({payload}, () => {
                    state.updateElement(id, dropzoneID, parentID, {
                        payload: payload
                    });
                });
            }
        }
    }

    _onDrop = (data, cb) => {
        var date = new Date();
        var timestamp = date.getTime();
        // no need to ask id and name again
        if (data.payload && data.payload.dropped) {
            return cb(data);
        }

        // This can be an async call or some modal to fetch data
        let name = data.name;

        const id = timestamp

        const result = cb({
            ...data,
            name,
            id,
            payload: {dropped: true}
        });
    };
    handleChangeComplete = (e) => {
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload[e.target.name]=e.target.value
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
    };

    toggle=(sta,dId)=>{
        const { id, dropzoneID, parentID }= this.props;
        let payload=this.state.payload
        payload.status = (sta ==="closed"?"open":"closed")
        this.setState({payload}, () => {
            state.updateElement(id, dropzoneID, parentID, {
                payload: payload
            });
        });
        this.props.toggle(dId)
    }

    render() {
        // make sure you are passing `parentID` prop to dropzone
        // it help to mainatain the state to meta data
        const {
            dropzoneID,
            parentID,
            showBasicContent,
            showPreview,
            id,
            payload,
            dropzoneProps,
            initialElements,
            ...rest
        } = this.props;
        let title=payload && payload.title
        let status=payload && payload.status
        let timestamp=this.state.timestamp
        // console.log(this.props.payload)
        if (showBasicContent) {
            return (
                <Draggable {...this.props} >
                    <span className="drag-icon">{rest.name}</span>
                </Draggable>
            );
        }

        const  filterInitialElements = (dID) => {
            return initialElements.filter(e => e.dropzoneID === dID) || [];
        };

        if (showPreview) {

            return (
                <div className="floating-summary ge-accordion">
                    <>
                        <div className={`accordion-header ${status==="closed"?"":"active"}`}>
                            <h5 onClick={()=>this.toggle(status,id)}>{title}</h5>
                        </div>
                        <div className={`accordion-body`} style={{display:(status==="closed"?"none":"block")}}>
                            {rest.childNode['accordion-1-1']}
                        </div>
                    </>

                </div>
            )
        }





        return (
            <Draggable id="grid-layout-builder" {...this.props} draggable={!this.props.DragDisabled.dragStatus}>
                <div className="layout-builder-titles">{rest.name}</div>
                <div className="mt-3 inner-layout-builder">
                    <FaTrash
                        className="pull-right"
                        color="#dc3545"
                        onClick={() => state.removeElement(id, dropzoneID, parentID)}
                    />
                    <Row className="row inner-layout-builder">
                        <Col sm="12">
                            <div className="builder-added-options">
                                <input type={`text`} name={`title`} value={title} onChange={(e)=>this.handleChangeComplete(e)} className="layout-header-block"/>
                                <select name={`status`} value={status} onChange={(e)=>this.handleChangeComplete(e)}>
                                    <option value={``}>Select</option>
                                    <option value={`open`}>Open</option>
                                    <option value={`closed`}>Closed</option>
                                </select>
                            </div>
                        </Col>
                        <Col sm="12">
                            <Dropzone
                                {...dropzoneProps}
                                initialElements={filterInitialElements('accordion-1-1')}
                                id={`accordion-1-1`}
                                onDrop={this._onDrop}
                                placeholder="Drop Here"
                            />
                        </Col>
                    </Row>
                </div>
            </Draggable>
        );
    }
}


const mapStateToProps = state => ({
    DragDisabled:state.DynamicDashboard.getDragDisabled,
    getToggle:state.DynamicDashboard.getToggle



});
export default  withRouter(connect(mapStateToProps, {
    toggle:DynamicDashboard.toggle
})(GeMobileAccordion));



