import {programs} from '../services/programs.services'
import {getProfile} from "../utilities/AuthService";
import {portfolio} from "../services/portfolio.services";
export const listallprograms = (data) => dispatch => {
    let listallprograms = programs.listProgram(data);
    dispatch({
        type: 'PROGRAMLIST_LOADER',
        loading:true,

    });
    listallprograms.then(listallprograms => {
        dispatch({
            type: 'LIST_All_PROGRAMS',
            listallprograms: listallprograms,
            clearList : data.clearList

        })
        dispatch({
            type: 'PROGRAMLIST_LOADER',
            loading:false,

        });
    })

};
export const updateprogramlist = (data) => dispatch => {
    let listmoreprograms = programs.listProgram(data);
    listmoreprograms.then(listmoreprograms => {
        dispatch({
            type: 'LIST_MORE_PROGRAMS',
            listmoreprograms: listmoreprograms,
        })

    })

};
export const getprogramdetails = (data) => dispatch => {
    let programdetails= programs.listProgram(data);
    dispatch({
        type: 'PROGRAM_DET_LOADER',
        loading:true,

    });
    programdetails.then(programdetails => {
        dispatch({
            type: 'GET_PROGRAM_DETAILS',
            programDet: programdetails,

        })
        dispatch({
            type: 'PROGRAM_DET_LOADER',
            loading:false,

        });
    })

};
export const saveVertical = (params) => dispatch => {
    dispatch({
        type: 'PROGRAMSAVE_LOADER',
        loading:true,

    });

    let verticalSaved = programs.saveVertical(params);
    let newVertical= {
        "programId": params._id,
        "portfolioId": params.portfolioId,
        "programName":params.programName,
        "description": btoa(unescape(encodeURIComponent(params.description))),
        "uid":  getProfile().id,
        "mode": params.mode,
        "activeProjects":0,
        "portfolioName":params.portfolioName,
        "creator":params.creator
    };

    verticalSaved.then(verticalSaved => {

        dispatch({
            type: 'UPDATE_VERTICAL',
            newVertical: newVertical

        })
        dispatch({
            type: 'PROGRAMSAVE_LOADER',
            loading:false,

        });


    })
};
export const deleteVertical = (params) => dispatch => {

    let programdeleted = programs.DeleteProgram(params);


    programdeleted.then(programdeleted => {

        dispatch({
            type: 'DELETE_PROGRAM',
        })
    })
};



