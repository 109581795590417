import React, {Component} from 'react';
import {connect} from "react-redux";
import * as collectionActions from "../../../actions/Collections"
import * as entityActions from "../../../actions/Entity"
import {withRouter} from 'react-router';
import * as commonActions from "../../../actions/Common";
import {getProfile} from "../../../utilities/AuthService";
import FormPanelEntity from '../../views/FormPanelEntity';
import Modal from "../../views/Modal"

import AddToDoTask from "../AddToDoTask"
import SimpleModal from "../../views/SimpleModal"
import AddEntityForm from "./AddEntityForm"
import {Accordion, Button, ButtonToolbar, Card} from "react-bootstrap";
import AutoComplete from "../../views/AutoComplete";
import {ES_INDEX, FRMSIMG_PATH, NEW_VERSION} from '../../../constants'
import * as usersActions from "../../../actions/Users";
import LoaderSvg from "../../views/LoaderSVG";
import rootActions from "../../../actions";
import AlertModal from "../../views/AlertModal";
import NoData from "../../views/NoData";
import {debounce} from "throttle-debounce";

//import {getUrlSegment, getUserActionPermission} from "../../../utilities/CustomFunctions";

class Entity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            optionOpened: 0,
            action: '',
            search: '',
            group: 'All',
            selectedCollection: {},
            selectedTags: [],
            selectedToUsers: [],
            tags: [],
            newTags: [],
            lastOffset: 1,
            totalitems: 0,
            collections: [],
            offset: 50,
            createNewForm: false,
            Collections: [],
            // createEditForm: false,
            formLabel: 'Forms',
            showDeleteEntity: false,
            showDuplicateEntity: false
        }
        this.manageOptionsDropDown = this.manageOptionsDropDown.bind(this);
        this.updateAction = this.updateAction.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.resultDataList = this.resultDataList.bind(this);
        this.shareCollection = this.shareCollection.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.showCreateNewFormModal = this.showCreateNewFormModal.bind(this);
        this.closeCreateNewFormModal = this.closeCreateNewFormModal.bind(this);
        this.deleteFromEntity = this.deleteFromEntity.bind(this);
        this.duplicateEntity = this.duplicateEntity.bind(this);
        this.duplicateEntityConfirm = this.duplicateEntityConfirm.bind(this);
        this.deleteEntity = this.deleteEntity.bind(this);
        this.listenToScroll = debounce(500, this.listenToScroll);
    }

    componentDidMount() {
        this.props.mainClick('entity', '');
        // let params = {
        //     group : this.state.group,
        //     search : this.state.search,
        //     uid : getProfile().id,
        //     usertype : getProfile().usertype,
        //     managecollection : '',
        //     limit : 0,
        //     offset : this.state.offset
        // };
        // this.props.collections(params);
        let param = {
            uid: getProfile().id,
            group: this.state.group,
            usertype: getProfile().usertype,
            searchtext: this.state.search,
            page: 1,
            per_page: this.state.offset,
            index: ES_INDEX,
            managecollection: ''
        };
        this.props.searchEntity(param);
        //this.props.users(getProfile().id,'User','',ES_INDEX);
        this.getUsers();
        this.props.getforms(getProfile().id, getProfile().usertype, "entity")
        const container = document.querySelector('.tile-main-panel');
        container.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        const container = document.querySelector('.tile-main-panel');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        // //console.log(this.props.EntityData.Entity.data.length)
        // //console.log(this.props.EntityData.Entity.total)
        //console.log(this.props.EntityData)
        const container = document.querySelector('.tile-main-panel')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - (offsetHeight + 1);
        if (contentHeight <= scrollTop) {
            if (!this.props.EntityData.searchEnable) {
                let params = {
                    search: this.state.search, uid: getProfile().id,
                    usertype: getProfile().usertype, managecollection: this.props.EntityData.managecollection,
                    page: this.state.lastOffset + 1,
                    per_page: this.state.offset,
                    group: this.props.EntityData.group
                };
                if (parseInt(this.props.EntityData.Entity.data.length) != parseInt(this.props.EntityData.Entity.total)) {
                    let lastOffset = this.state.lastOffset + 1;
                    this.setState({lastOffset: lastOffset})
                    this.props.updateEntity(params);
                }
            } else {
                let params = {
                    search: this.props.EntityData.Search,
                    uid: getProfile().id, usertype: getProfile().usertype,
                    managecollection: this.props.EntityData.managecollection,
                    page: this.state.lastOffset + 1,
                    per_page: this.state.offset,
                    group: this.props.EntityData.group
                };
                // //console.log(this.props.EntityData.searchResult)
                if (this.props.EntityData.searchResult.total >= this.props.EntityData.searchResult.data.length) {
                    let lastOffset = this.state.lastOffset + 1;
                    this.setState({lastOffset: lastOffset})
                    this.props.moreSearchEntity(params)
                }


            }

        }
    }

    updateAction(e, act, collection, id) {
        e.stopPropagation();
        this.setState({
            action: act,
            collectionId: id,
            optionOpened: 0,
            selectedCollection: [collection],
            selectedToUsers: []
        });
    }

    modalOpen() {
        this.setState({action: 'close'});
    }

    componentWillUpdate(prevProps, nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if (this.props.sharedCollection.shared !== prevProps.sharedCollection.shared) {
            let params = {
                group: this.state.group,
                search: this.state.search,
                uid: getProfile().id,
                usertype: getProfile().usertype,
                managecollection: '',
                limit: 0,
                offset: this.state.offset
            };
            this.props.collections(params);
        }

        if (prevState.action !== this.state.action && this.state.action === "share") {

            this.props.getsharedSettingsData({_id: this.state.collectionId})
        }
        if (this.props.sharedCollectionData.collectionsettings !== prevProps.sharedCollectionData.collectionsettings) {

            let sharedData = this.props.sharedCollectionData.collectionsettings;
            ////////////console.log(sharedData)
            if (sharedData) {
                this.setState({selectedToUsers: sharedData})
            }
        }

        if (prevProps.entitydetail.EntityDetails !== this.props.entitydetail.EntityDetails) {
            let entity = this.props.entitydetail.EntityDetails;
            if (entity) {
                this.setState({entity: entity})
            }
        }

        if (prevProps.EntityData.ListAllEntity !== this.props.EntityData.ListAllEntity) {
            this.setState({createNewForm: false})

            let param = {
                uid: getProfile().id,
                usertype: getProfile().usertype,
                searchtext: this.state.search,
                page: 1,
                per_page: 50,
                index: ES_INDEX,
                managecollection: ''
            };

            setTimeout(() => {
                beta.props.searchEntity(param);
                beta.props.getforms(getProfile().id, getProfile().usertype, "entity")

            }, 800)

        }
        if (prevProps.EntityData.Entity !== this.props.EntityData.Entity) {
            this.setState({
                proceedModal: false,
                Collections: this.props.EntityData.data,
                loading: 0,

            })
            //////////console.log( this.props.EntityData.Entity)
            this.closeCreateNewFormModal();
        }
        if (prevProps.deletedEntity.deletedEntity !== this.props.deletedEntity.deletedEntity) {
            this.setState({showDeleteEntity: false})
            //////////console.log( this.props.EntityData.Entity)
            let param = {
                uid: getProfile().id,
                usertype: getProfile().usertype,
                searchtext: this.state.search,
                page: 1,
                per_page: 50,
                index: ES_INDEX,
                managecollection: ''
            };
            this.closeCreateNewFormModal();
            setTimeout(() => {
                beta.props.searchEntity(param);
                beta.props.getforms(getProfile().id, getProfile().usertype, "entity")
            }, 1000)
        }
        if (prevProps.duplicatedEntity.duplicatedEntity !== this.props.duplicatedEntity.duplicatedEntity) {
            // this.setState({showEntityOptions : false})
            //////////console.log( this.props.EntityData.Entity)
            let param = {
                uid: getProfile().id,
                usertype: getProfile().usertype,
                searchtext: this.state.search,
                page: 1,
                per_page: 50,
                index: ES_INDEX,
                managecollection: ''
            };
            this.closeCreateNewFormModal();
            setTimeout(() => {
                beta.props.searchEntity(param);
            }, 500)
        }

        if ((prevProps.EntityData.Search !== this.props.EntityData.Search && (this.props.EntityData.Search === '')) || (prevProps.EntityData.Entity.Collections !== this.props.EntityData.Entity.Collections)) {
            this.setState({
                Collections: this.props.EntityData.Entity.data,
                loading: 0
            });
        } else if ((prevProps.EntityData.Search !== this.props.EntityData.Search && (this.props.EntityData.Search !== '')) || (prevProps.EntityData.Entity.searchResult !== this.props.EntityData.Entity.searchResult)) {
            this.setState({
                Collections: this.props.EntityData.searchResult.data,
                loading: 0
            });

        }

    }

    handleInputChange = (e) => {
        this.props.searchType('SEARCH_ENTITY_DATA', this.props.EntityData.Search, "entity", this.props.EntityData.group, e.target.value)
        let label;
        if (e.target.value === 'sharebyme') {
            label = 'My Forms';
        } else if (e.target.value === 'sharewithme') {
            label = 'Shared ';
        } else if (e.target.value === 'managedbyme') {
            label = 'Managed';
        } else {
            label = 'Forms';
        }
        this.setState({
            formLabel: label,

        });
    }

    getUsers(searchText) {
        //let ignoreUsers = [].concat.apply([], [this.state.selectedToUsers,this.state.selectedCcUsers]);
        var user_data = {
            uid: "0",
            search: searchText ? searchText : '',
            offset: 100,
            ignoreUsers: true,
            ignoreArrays: [],
            valueField: 'uniqueId'
        };
        this.props.users(user_data);
    }

    manageOptionsDropDown(e, id) {
        e.stopPropagation();
        this.setState({optionOpened: id});
    }

    resultDataList(result, reference) {
        this.setState({[reference]: result});
    }

    shareCollection() {
        let users = [];
        let cnt = 0;
        this.state.selectedToUsers.map(a => {
            let user = {"type": a.userType, "id": +a._id};
            users.push(user);
        });
        let sharedata = {
            "collectionid": this.state.collectionId,
            "uid": getProfile().id,
            "users": users
        }
        this.props.sharecollection(sharedata);
        this._interval = setInterval(() => {
            if (this.props.sharedCollection.shared === 1) {
                if (this.props.sharedCollection.msg === '') {
                    this.setState({action: ''});
                } else {
                    alert(this.props.sharedCollection.msg)
                }
                clearInterval(this._interval);
            }
        }, 500);

    }


    showCreateNewFormModal(create, id) {
        this.setState({createNewForm: !this.state.createNewForm, create: create, entityid: id})
        if (create === 'update') {
            let param = {
                entityid: id,
                linkedentityinfo: 'yes',
                showinheritanceinfo: 'yes',
                linkedcollectioninfo: 'yes',
                index: ES_INDEX
            };
            this.props.entityDetails(param);
        }
    }

    deleteFromEntity(id, delete_action) {
        this.setState({showDeleteEntity: !this.state.showDeleteEntity, entityid: id, delete_action: delete_action})
    }

    deleteEntity() {
        let param = {entityid: this.state.entityid, index: ES_INDEX};
        this.props.deleteEntity(param);
    }

    duplicateEntity(id, duplicate_action) {
        this.setState({
            showDuplicateEntity: !this.state.showDuplicateEntity,
            duplicateid: id,
            duplicate_action: duplicate_action
        })
    }

    duplicateEntityConfirm() {
        let param = {
            entityid: this.state.duplicateid,
            UserId: getProfile().id,
            index: ES_INDEX
        };
        let entityid = this.state.duplicateid
        let UserId = getProfile().id
        let index = ES_INDEX
        this.props.duplicateEntity(entityid, UserId, index, 0);
        this.setState({showDuplicateEntity: false})
        let beta = this;
        let params = {
            uid: getProfile().id,
            usertype: getProfile().usertype,
            searchtext: this.state.search,
            page: 1,
            per_page: 50,
            index: ES_INDEX,
            managecollection: ''
        };
        this.closeCreateNewFormModal();
        setTimeout(() => {
            beta.props.searchEntity(params);
        }, 500)
    }

    closeCreateNewFormModal() {
        this.setState({createNewForm: false})
    }

    render() {
        let Collections = [];
        let total = ""
        if (!this.props.EntityData.searchEnable) {
            Collections = this.props.EntityData.Entity.data;
            total = this.props.EntityData.Entity.total;
        } else {
            Collections = this.props.EntityData.searchResult.data;
            total = this.props.EntityData.searchResult.total;
        }
        let entity = Collections;

        // let entityAddPermission = getUserActionPermission(23, 1);
        let listUsersPermission = this.props.listUsersPermission.UsersPermission;
        // console.log(listUsersPermission)

        var entityAddPermission = 'no';
        var entityEditPermission = 'no';
        var entityDeletePermission = 'no';
        if (listUsersPermission.length > 0) {
            let perms = listUsersPermission.filter(per => per.module_id === 23);
            // console.log(perms)
            if (perms.length > 0) {
                let actions = perms[0].actions;

                let modActonArr1 = actions.filter(ac => ac.action_id === 1 && ac.action_permission === 'yes'); // Add case
                //console.log(modActonArr1);
                if (modActonArr1.length > 0) {
                    entityAddPermission = 'yes';
                }
                let modActonArr2 = actions.filter(ac => ac.action_id === 2 && ac.action_permission === 'yes'); // Add case
                //console.log(modActonArr2);
                if (modActonArr2.length > 0) {
                    entityEditPermission = 'yes';
                }
                let modActonArr3 = actions.filter(ac => ac.action_id === 3 && ac.action_permission === 'yes'); // Add case
                //console.log(modActonArr3);
                if (modActonArr3.length > 0) {
                    entityDeletePermission = 'yes';
                }
            }
        }

        // console.log(entityAddPermission)
        // console.log(entityEditPermission)
        // console.log(entityDeletePermission)
        return (
            <React.Fragment>

                {getProfile().usertype.toLowerCase() === "user" && entity.length !== 0 &&
                <div className="inner-content-area">
                    <div className="filter-wrapper">
                        <div>
                            <h5 className="subhead">{this.state.formLabel} ({total})</h5>
                        </div>
                        <ul className="filter-left-wrapper" id="searchBar" data-action="filter">
                            <li>
                                <div className="select-box">
                                    <select id="c_status" onChange={(e) => this.handleInputChange(e)}>
                                        <option value="">Choose One</option>
                                        <option value="sharebyme">My Forms</option>
                                        <option value="sharewithme">Shared with me</option>
                                        {getProfile().overseeStatus === "1" &&
                                        <option value="managedbyme">Owned by Reportees</option>
                                        }
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                }

                <div
                    className={`tile-main-panel ${getProfile().usertype.toLowerCase() === 'user' ? 'with-filter' : ''}`}>
                    {this.props.loader.loaderType === 'entity-list' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                    }
                    {entity.length !== 0 ?
                        <div className="tile-all-holder list-viewer">
                            {entity && entity.map((list, index) =>
                                <FormPanelEntity
                                    key={`form-panel${index}`}
                                    list={list}
                                    history={this.props.history}
                                    optionOpened={this.state.optionOpened}
                                    manageOptionsDropDown={this.manageOptionsDropDown}
                                    updateAction={this.updateAction}
                                    entitydetail={this.state.entity}
                                    editEntity={this.showCreateNewFormModal}
                                    deleteEntity={this.deleteFromEntity}
                                    duplicateEntity={this.duplicateEntity}
                                    entityEditPermission={entityEditPermission}
                                    entityDeletePermission={entityDeletePermission}
                                />
                            )}
                        </div>
                        :
                        <div className='absolute-holder'>
                            <NoData
                                msg={`No Data linked to this collection`}
                            />
                        </div>
                    }
                    {entityAddPermission === 'yes' &&
                    <div className="float-button-block">
                        <button className="float-button ico-lib float-add" data-toggle="modal" data-target="#addField"
                                onClick={() => this.showCreateNewFormModal('add')}></button>
                    </div>
                    }
                    {this.state.action === 'assign' &&
                    <Modal title={'Create New Task'} Close={this.modalOpen.bind(this)}>
                        <AddToDoTask
                            selectedCollections={this.state.selectedCollection}
                            modalOpen={this.modalOpen}
                        />
                    </Modal>
                    }
                    {this.state.action === 'share' &&
                    <SimpleModal
                        Close={this.modalOpen}
                        title={`Share Form`}
                        haveFooter={1}
                        OKLabel="Save"
                        OKAction={this.shareCollection}
                        cancelLabel="Cancel"
                        cancelAction={this.modalOpen}
                    >
                        {this.props.loader.loaderType === 'share-collection' && this.props.loader.status === true &&
                        <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                        }
                        <div className="task-tg">

                            <p className="remove-txt-gp">Assign to Individual, Team, Organization, etc (Ignore this for
                                self tasks)</p>
                            <div className="task-inp-container">

                                <AutoComplete
                                    fieldTitle='To'
                                    sourceDataList={this.props.listUsers.todoUsers}
                                    searchField='firstName'
                                    showIcon={true}
                                    showSubLabel={true}
                                    labelFields='firstName,lastName'
                                    valueField='uniqueId'
                                    subLabelField='designation'
                                    showProfile={false}
                                    reference='selectedToUsers'
                                    selectedItems={this.state.selectedToUsers}
                                    resultDataList={this.resultDataList.bind(this)}
                                    id="to_users"
                                    instantSearch={true}
                                    actionMethod={this.getUsers}
                                    preventInitialApiCall={true}
                                />

                            </div>

                        </div>

                    </SimpleModal>
                    }
                    {this.state.showDuplicateEntity &&
                    <AlertModal
                        id="duplicate-entity"
                        title={this.state.duplicate_action === "delete" ? "Are you sure you want to duplicate this Entity?" : "Are you sure you don't want to duplicate this Entity?"}
                        OK_button_text={this.state.duplicate_action === "delete" ? "Yes, duplicate It" : "No, Don't duplicate It"}
                        OK_action={() => this.duplicateEntityConfirm()}
                        showModal={this.state.showDuplicateEntity}
                        CANCEL_action={() => this.duplicateEntityConfirm()}
                    />
                    }
                    {this.state.createNewForm &&
                    <Modal title={this.state.create === 'update' ? 'Update Entity' : 'New Entity'}
                           Close={this.closeCreateNewFormModal}>
                        <AddEntityForm
                            entity={this.state.create === 'update' ? this.state.entity : entity}
                            create={this.state.create}
                            entityid={this.state.entityid}
                        />
                    </Modal>

                    }
                    {this.state.showDeleteEntity &&
                    <AlertModal
                        id="delete-entity"
                        title={this.state.delete_action === "delete" ? "Are you sure you want to delete this Entity?" : "Are you sure you don't want to delete this Entity?"}
                        OK_button_text={this.state.delete_action === "delete" ? "Yes, Delete It" : "No, Don't delete It"}
                        OK_action={() => this.deleteEntity()}
                        showModal={this.state.showDeleteEntity}
                        CANCEL_action={() => this.deleteFromEntity()}
                    />
                    }

                </div>

            </React.Fragment>
        );
    }
}


const mapStateToProps = state => ({
    searchData: state.CommonReducer.searchparamdata,
    // listAllCollections: state.CollectionsReducer.listAllCollections,
    listUsers: state.UsersReducer.listUsersdata,
    haveLoader: state.EntityReducer.manageoader,
    sharedCollection: state.EntityReducer.sharedCollection,
    loader: state.CommonReducer.loader,
    HistoryPushData: state.TaskReducer.HistoryPushData,
    sharedCollectionData: state.EntityReducer.collectionSettingsData,
    EntityData: state.EntityReducer.GetEntity,
    entitydetail: state.EntityReducer.GetEntityDetails,
    deletedEntity: state.EntityReducer.Deleteentity,
    duplicatedEntity: state.EntityReducer.Duplicateentity,
    listUsersPermission: state.UsersReducer.listUsersPermissiondata,
});

export default withRouter(connect(mapStateToProps, {
    mainClick: rootActions.commonActions.MenuClick,
    searchType: rootActions.commonActions.searchType,
    // collections: collectionActions.listallcollections,
    updateEntity: entityActions.updateEntity,
    moreEntity: entityActions.updateEntity,
    moreSearchEntity: entityActions.updatesearchentity,
    sharecollection: entityActions.sharecollection,
    //users:usersActions.listAllUsers,
    getforms: rootActions.commonActions.GetForms,
    users: usersActions.listTodoUsers,
    getsharedSettingsData: entityActions.getCollectionSettings,
    searchEntity: entityActions.Entity,
    entityDetails: entityActions.entityDetails,
    deleteEntity: entityActions.deleteEntity,
    duplicateEntity: entityActions.duplicateEntity,

})(Entity));


