import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";

class ProjectIOTCollection extends Component {

    constructor(props) {
        super(props);

        this.state={
        }
        this.expandHeader = this.expandHeader.bind(this)
    }
    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }


    componentDidMount() {
        this.setState({loading: 1});
        var projectId = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/iotcollections/'+projectId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {

        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>
                <div className="ibt-body">
                    <div className="iot-worldmap">
                        <img src="/assets/images/iot-worldmap.JPG" alt="world-map" />
                    </div>
                    <div className="ibt-rating">
                        <div className="ind-ibt-rating iot">
                            <div className="ibt-rating-top">
                                <h5>Products</h5>
                            </div>
                            <div className="ibt-rating-bottom">
                                <h5>10,875</h5>
                            </div>
                        </div>
                        <div className="ind-ibt-rating iot">
                            <div className="ibt-rating-top">
                                <h5>IOT Devices</h5>
                            </div>
                            <div className="ibt-rating-bottom">
                                <h5>10,000</h5>
                            </div>
                        </div>
                        <div className="ind-ibt-rating iot">
                            <div className="ibt-rating-top">
                                <h5>Households</h5>
                            </div>
                            <div className="ibt-rating-bottom">
                                <h5>3,000</h5>
                            </div>
                        </div>
                        <div className="ind-ibt-rating iot">
                            <div className="ibt-rating-top">
                                <h5>Locations</h5>
                            </div>
                            <div className="ibt-rating-bottom">
                                <h5>100</h5>
                            </div>
                        </div>
                        <div className="ind-ibt-rating iot">
                            <div className="ibt-rating-top">
                                <h5>Energy Generated</h5>
                            </div>
                            <div className="ibt-rating-bottom">
                                <h5>89.93 kWh</h5>
                            </div>
                        </div>
                        <div className="ind-ibt-rating iot">
                            <div className="ibt-rating-top">
                                <h5>Power Consumed</h5>
                            </div>
                            <div className="ibt-rating-bottom">
                                <h5>71.52 Wh</h5>
                            </div>
                        </div>
                        <div className="ind-ibt-rating iot">
                            <div className="ibt-rating-top">
                                <h5>Avg Temp</h5>
                            </div>
                            <div className="ibt-rating-bottom">
                                <h5>-10°C</h5>
                            </div>
                        </div>
                    </div>
                    <div className="ibt-progress-table iot">
                        <div className="ibt-progress-table-body">
                            <div className="scrollable">
                                <table className="table ibt-table iot">
                                    <thead>
                                    <tr>
                                        <th>States</th>
                                        <th>No. of Households</th>
                                        <th>IOT Devices</th>
                                        <th>Products</th>
                                        <th>Avg Temp</th>
                                        <th>Locations/Villages</th>
                                        <th>Energy Generated(kWh)</th>
                                        <th className="right-aln">Power Consumed(Wh)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <div className="progress-wrapper">
                                                <h5>UP</h5>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{width: "84%"}}
                                                         aria-valuenow="84" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>750</td>
                                        <td>2500</td>
                                        <td>5000</td>
                                        <td>38°C</td>
                                        <td>50</td>
                                        <td>10.79</td>
                                        <td className="right-aln">10.79</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="progress-wrapper">
                                                <h5>Kerala</h5>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{width: "50%"}}
                                                         aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>675</td>
                                        <td>1500</td>
                                        <td>3000</td>
                                        <td>31°C</td>
                                        <td>40</td>
                                        <td>18.70</td>
                                        <td className="right-aln">18.70</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="progress-wrapper">
                                                <h5>Tamilnadu</h5>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{width: "84%"}}
                                                         aria-valuenow="84" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>750</td>
                                        <td>2500</td>
                                        <td>5000</td>
                                        <td>38°C</td>
                                        <td>50</td>
                                        <td>10.79</td>
                                        <td className="right-aln">10.79</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="progress-wrapper">
                                                <h5>MP</h5>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{width: "50%"}}
                                                         aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>675</td>
                                        <td>1500</td>
                                        <td>3000</td>
                                        <td>31°C</td>
                                        <td>40</td>
                                        <td>18.70</td>
                                        <td className="right-aln">18.70</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="progress-wrapper">
                                                <h5>Bihar</h5>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" style={{width: "84%"}}
                                                         aria-valuenow="84" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>750</td>
                                        <td>2500</td>
                                        <td>5000</td>
                                        <td>38°C</td>
                                        <td>50</td>
                                        <td>10.79</td>
                                        <td className="right-aln">10.79</td>
                                    </tr>
                                    <tr className="iot-table-total">
                                        <td className="right-aln">Total</td>
                                        <td>3115</td>
                                        <td>10130</td>
                                        <td>19700</td>
                                        <td></td>
                                        <td>216</td>
                                        <td>81.6</td>
                                        <td className="right-aln">81.6</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="iot-days-filter">
                        <ul className="iot-filter-list">
                            <li>Today</li>
                            <li>Yesterday</li>
                            <li>Last week</li>
                            <li>Last 14 days</li>
                            <li>Last 28 days</li>
                            <li className="active">Custom</li>
                        </ul>
                    </div>
                </div>
            </div>



        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
})(ProjectIOTCollection));
