import React, {Component} from "react";

import {PieChart, Pie, Tooltip, Sector, Cell, ResponsiveContainer, Legend, Label} from "recharts";

const dataMain = [{name: "Droobi", value: 100}];
const renderLegend = (props) => {
    const {
        cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value, tooltipPayload
    } = props;
    const spaces = "    ";
    return (
        <ul className={'legend-limit'}>
            {
                payload.map((entry, index) => {
                        ////console.log(entry)
                        let entrypayload = entry.payload && entry.payload.payload;
                        let legendData = entrypayload.payload
                        var legLabel = '';
                        var legVal = '';
                        if (legendData) {
                            let keys = Object.keys(legendData);
                            if (keys.length > 1) {
                                let firstKey = keys[0];
                                let secKey = keys[1];
                                legLabel = legendData[firstKey];
                                legVal = legendData[secKey];
                                return (
                                    <li key={`item-${index}`}>
                                        <div className="leg-icon">
                                            <span className="leg-per"
                                                  style={{background: entrypayload.fill}}>{percent}</span>
                                            <span className="leg-label recharts-legend-item-text">{legLabel}</span>
                                            <span className="leg-val recharts-legend-item-text">{legVal}</span>
                                        </div>
                                    </li>
                                )
                            }

                        }
                    }
                )
            }
        </ul>
    );
}

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value, tooltipPayload
    } = props;
    var xlabel = '';
    var ylabel = '';

    if (tooltipPayload.length > 0) {
        ylabel = tooltipPayload[0].dataKey;
    }

    var payloadkeys = Object.keys(tooltipPayload);
    var this_payload = payload.payload;
    for (var key in this_payload) {
        if (this_payload.hasOwnProperty(key)) {
            xlabel = this_payload[key];
            break;
        }
    }

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{xlabel}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}
                  fill="#333">{`${ylabel} - ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const CustomTooltip = ({active, payload, label, unit}) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                {payload && payload.map(item => {
                        let tooltipdata = item.payload && item.payload.payload;
                        //console.log(tooltipdata)

                        var tooltipLabel = '';
                        var tooltipVal = '';
                        if (tooltipdata) {
                            let keys = Object.keys(tooltipdata);
                            if (keys.length > 1) {
                                let firstKey = keys[0];
                                let secKey = keys[1];
                                tooltipLabel = tooltipdata[firstKey];
                                tooltipVal = tooltipdata[secKey];
                                return (
                                    <React.Fragment>
                                        <p className="label">{tooltipLabel}</p>
                                        <p className="intro"
                                           style={{color: item.color}}> {`${new Intl.NumberFormat('en-IN', {maximumFractionDigits: 2}).format(tooltipVal)} ${unit ? unit : ''}`}</p>
                                    </React.Fragment>
                                )
                            }
                        }
                    }
                )}
            </div>
        );
    }

    return null;
};
//const COLORS = ['#76AAD4', '#D3E0EB'];
//const COLORS = ['#0F3871', '#194F88', '#286FA9', '#3A93CA', '#50BAEC', '#7AD4F3', '#96E6F9', '#BAF4FD', '#DCFCFE' ];
const COLORS = [{color: '#BAF4FD'}, {color: '#50BAEC'}, {color: '#DCFCFE'}, {color: '#96E6F9'}, {color: '#7AD4F3'}, {color: '#3A93CA'}, {color: '#286FA9'}, {color: '#194F88'}, {color: '#0F3871'}];

export default class PieChartCustom extends Component {
    state = {
        activeIndex: 0
    };

    onPieEnter = (data, index) => {
        this.setState({activeIndex: index});
    };

    render() {
        /*let xwidth = this.props.containerData.xwidth && parseInt(this.props.containerData.xwidth);
        let xheight =  this.props.containerData.xheight && parseInt(this.props.containerData.xheight);
        let yheight = this.props.containerData.yheight && parseInt(this.props.containerData.yheight);
        let ywidth = this.props.containerData.ywidth && parseInt(this.props.containerData.ywidth);

        let xAngle =  this.props.containerData.xAngle ? parseInt(this.props.containerData.xAngle) : 0;
        let yAngle = this.props.containerData.yAngle ? parseInt(this.props.containerData.yAngle) : 0;
        let bardata = this.props.containerData.tabledata.slice(0);*/
        let graphHeight = this.props.containerData.graphHeight ? parseInt(this.props.containerData.graphHeight) : 300;
        let grpahData = this.props.containerData.data;
        let borderColor = this.props.containerData.bordercolor && this.props.containerData.bordercolor;
        let aggValue = this.props.containerData.aggValue && this.props.containerData.aggValue;
        let unit = this.props.containerData.unit ? this.props.containerData.unit : '';

        let colors = this.props.containerData.Color ? this.props.containerData.Color : COLORS;

        return (
            <ResponsiveContainer width={'100%'} height={graphHeight}>
                <PieChart>

                    {this.props.containerData.tabledata.slice(1).map((list, index) => {
                            let innerRadius = "75%";
                            let outerRadius = "95%";
                            return (
                                <Pie key={"pie_ch_" + index}
                                     activeIndex={this.state.activeIndex}
                                     data={grpahData}
                                     cx="50%"
                                     cy="50%"
                                     innerRadius={innerRadius}
                                     outerRadius={outerRadius}
                                     fill={list.color}
                                     dataKey={list.label}
                                     onMouseEnter={this.onPieEnter}
                                     stroke={borderColor}
                                >
                                    <Label value={aggValue + " " + unit} position="center"/>

                                    {
                                        this.props.containerData.data.map((entry, index) => <Cell key={`cell-${index}`}
                                                                                                  fill={colors[index % colors.length].color}/>)
                                    }

                                </Pie>
                            )
                        }
                    )}
                    {this.props.containerData.legend &&
                    <Legend content={renderLegend} layout='vertical' verticalAlign={`middle`} align={`right`}/>

                    }
                    <Tooltip cursor={{fill: 'transparent'}}
                             content={<CustomTooltip unit={this.props.containerData.unit}/>}/>
                </PieChart>
            </ResponsiveContainer>

        );
    }
}
