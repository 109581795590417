 import React, {Component} from 'react';
 import {connect} from "react-redux";
 import {withRouter} from 'react-router';
 import { Scrollbars } from 'react-custom-scrollbars';
 import ListDashboardtask from './ListDashboardtask'
 import LoaderSvg from "./LoaderSVG";
 class DailyTask extends Component {
     constructor(props) {
         super(props);
     }
     componentWillMount() {

     }
     render() {
         return (
             <div className="task-list-wrapper">
                 {this.props.multiLoader.loader['dashboard-userstat'] && this.props.multiLoader.loader['dashboard-userstat'] === true &&
                 <span className="Loader-holder">
                    <LoaderSvg/>
                </span>
                 }
                 <div className="task-split-header">
                     <h5>{(this.props.type === 'todaystask') ? 'Todays Tasks': 'My Overdue Tasks' }</h5>
                     <p> {this.props.taskCount}  {(this.props.taskCount > 1) ? 'Tasks': 'Task' }</p>
                 </div>
                 <Scrollbars
                     //removeTracksWhenNotUsed={false}
                     //trackYProps={{style: {opacity: 0}}}
                     style={{ width: '100%'}}
                     autoHide
                 >
                 <div className="task-split-bottom">
                     {this.props.dataList.map((val, index) =>
                        <ListDashboardtask key={index} dataList={val} />
                     )}

                 </div>
                 </Scrollbars>
             </div>

         );
     }


 }

 export default DailyTask;