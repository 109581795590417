import React, {Component} from 'react';
import AreaBarChart from "../General/AreaBarChart";
import axios from "axios";
import {API_PATH, ES_INDEX} from "../../../constants/index";
import LoaderSvg from "../LoaderSVG";
import AreaLineChart from "../General/AreaLineChart";
import MultiAreaChart from "../General/MultiAreaChart";
import StackedBarChart from "../General/StackedBarChart";
import TreeMap from "../General/TreeMap";
/*const bardata = [
    {
        "name": "Q1 2008",
        "roa": 15,
        "troa": 15
    },
    {
        "name": "Q2 2008",
        "roa": 18,
        "troa": 15
    },
    {
        "name": "Q3 2008",
        "roa": 18,
        "troa": 15
    },
    {
        "name": "Q4 2008",
        "roa": 15,
        "troa": 15
    },
    {
        "name": "Q1 2009",
        "roa": 18,
        "troa": 15
    },
    {
        "name": "Q2 2009",
        "roa": 23,
        "troa": 15
    }
];
const arealinedata = [
    {
        "name": "Q1 2008",
        "ratio": 3
    },
    {
        "name": "Q2 2008",
        "ratio": 5
    },
    {
        "name": "Q3 2008",
        "ratio": 10
    },
    {
        "name": "Q4 2008",
        "ratio": 10
    },
    {
        "name": "Q1 2009",
        "ratio": 8
    },
    {
        "name": "Q2 2009",
        "ratio": 10
    }
];*/
class FinancialTile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bardata : [],
            arealinedata : [],
            multiareadata : [],
            stackedbardata : [],
            treemapdata :[]
        }
    }

    componentDidMount() {
       // this.setState({loading: 1});
        var date = new Date();
        var timestamp = date.getTime();

        var obj = this;

        if(this.props.type === 'line_area_bar' ) {
            // Send an HTTP  request
             axios({
                method: "POST",
                 url :"https://apir4dev.bsynapse.com/project/datacard?collectionid=1578573923150&fieldid=452568428&index=ibtstaging&graph=true&x=502228588,473309187&y=452568428&z=763805780",
                async:false,
                headers : {'Content-Type' : 'application/json'}
            }).then(function(res){
                 var carddata = res.data.results.data;
                 var aggregatevalue = carddata.aggregation;
                 obj.setState({bardata : carddata.graph});
                 return res;


             }).catch(function(err){
                //console.log("error");
                //console.log(err);
                return err;
            });
        }

         if(this.props.type === 'line_area' ) {
             // Send an HTTP  request
             axios({
                 method: "POST",
                 url :"https://apir4dev.bsynapse.com/project/datacard?collectionid=2_353633199360&fieldid=620484745&index=ibtstaging&graph=true&x=1483781289,1451382620&y=620484745&z=620484745",
                 async:false,
                 headers : {'Content-Type' : 'application/json'}
             }).then(function(res){
                 var carddata = res.data.results.data;
                 var aggregatevalue = carddata.aggregation;
                 obj.setState({arealinedata : carddata.graph});
                 return res;

             }).catch(function(err){
                 //console.log("error");
                 //console.log(err);
                 return err;
             });
         }


        if(this.props.type === 'multi_area') {
            // Send an HTTP  request
            axios({
                method: "POST",
                url :API_PATH+"project/datacard?collectionid=2_1365967501565&fieldid=101186181&index="+ES_INDEX+"&graph=true&x=878822554&y=101186181&z=1426515294",
                async:false,
                headers : {'Content-Type' : 'application/json'}
            }).then(function(res){
                var carddata = res.data.results.data;
                var aggregatevalue = carddata.aggregation;
                obj.setState({multiareadata : carddata.graph});
                return res;

            }).catch(function(err){
                //console.log("error");
                //console.log(err);
                return err;
            });
        }

        if(this.props.type === 'stacked_bar') {
            // Send an HTTP  request
            axios({
                method: "POST",
                url :API_PATH+"project/datacard",
                async:false,
                headers : {'Content-Type' : 'application/json'},
                params : {collectionid: "2_1365967501565",fieldid :"101186181" , index: ES_INDEX, graph: true, x : "101186181", y:"38199793", z: "1426515294"}
            }).then(function(res){

                var carddata = res.data.results.data;
                var aggregatevalue = carddata.aggregation;
                obj.setState({stackedbardata : carddata.graph});
                return res;

            }).catch(function(err){
                //console.log("error");
                //console.log(err);
                return err;
            });
        }


        if(this.props.type === 'tree_map') {
            // Send an HTTP  request
            axios({
                method: "POST",
                url :API_PATH+"project/datacard",
                async:false,
                data : {
                    "collectionid":"2_964597778248",
                    "index":ES_INDEX,
                    "graph":true,
                    "fieldid":"322236917",
                    "components":[
                        {
                            "label":"name",
                            "field":"1018507210"
                        },
                        {
                            "label":"children",
                            "field":[
                                {
                                    "label":"name",
                                    "field":"1018507210"
                                },
                                {
                                    "label":"sum",
                                    "field":"322236917"
                                }
                            ]
                        }
                    ]
                },
                headers : {'Content-Type' : 'application/json'}
            }).then(function(res){

                var treemapdata = res.data.results.data;
                obj.setState({treemapdata : treemapdata});
                return res;

            }).catch(function(err){
                //console.log("error");
                //console.log(err);
                return err;
            });
        }

    }

    render() {
        return (
            <div className="blue-lister">
                <div className="grid-header">
                    <h5>{this.props.title}</h5>
                </div>
                <div className="blue-list-body">

                    <div className="graph-wrap">
                        {!this.state.bardata &&
                            <LoaderSvg/>
                        }
                        {this.state.bardata && this.props.type === 'line_area_bar' &&
                            <AreaBarChart data={this.state.bardata} color={this.props.color} color2={this.props.color2}/>
                        }
                        {this.state.arealinedata && this.props.type === 'line_area' &&
                        <AreaLineChart data={this.state.arealinedata} color={this.props.color} color2={this.props.color2}/>
                        }
                        {this.state.multiareadata && this.props.type === 'multi_area' &&
                        <MultiAreaChart data={this.state.multiareadata} color1={this.props.color} color2={this.props.color2} color3={this.props.color3}/>
                        }
                        {this.state.stackedbardata && this.props.type === 'stacked_bar' &&
                        <StackedBarChart data={this.state.stackedbardata} color1={this.props.color} color2={this.props.color2} color3={this.props.color3}/>
                        }
                        {this.state.treemapdata && this.props.type === 'tree_map' &&
                        <TreeMap data={this.state.treemapdata} color1={this.props.color} color2={this.props.color2} color3={this.props.color3}/>
                        }
                        {this.state.stackedbardata && this.props.type === 'multi_bar' &&
                        <StackedBarChart data={this.state.multibardata} color1={this.props.color} color2={this.props.color2} color3={this.props.color3}/>
                        }

                    </div>
                </div>
            </div>
        );
    }

}

export default FinancialTile;