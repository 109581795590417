import {collections} from '../services/collections.services'
import {workflow} from "../services/workflow.services";
import {API_PATH, AUTHENTICATION, ES_INDEX} from "../constants";
import * as workflowActions from "./WorkFlow";
import {resources} from "../services/Resources.services";
import {entity} from "../services/entity.services";
import {task} from "../services/task.services";

export const listallcollections = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-list',
        status:true

    });
    let listallcollections = collections.listCollections(params);
    listallcollections.then(listallcollections => {
        dispatch({
            type: 'LIST_COLLECTIONS',
            listallcollections: listallcollections.data.results.data,
            group:params.group,
            search:params.search,
            optionOpened: 0

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-list',
            status: false

        });
    })
        .catch(error => {
            dispatch({
                type: 'LIST_COLLECTIONS',
                listallcollections: {collection:[], totalitems : 0},
                group:[],
                search:[],
                optionOpened: 0

            })
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'collection-list',
                status: false

            });
        })

};

export const listProjectCollections = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-list',
        status:true

    });
    let listallcollections = collections.listProjectCollections(params);
    listallcollections.then(listallcollections => {
        dispatch({
            type: 'LIST_COLLECTIONS',
            listallcollections: listallcollections.data.results.data,
            group:params.group,
            search:params.search,
            optionOpened: 0

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-list',
            status: false

        });
    })
        .catch(error => {
            dispatch({
                type: 'LIST_COLLECTIONS',
                listallcollections: {collection:[], totalitems : 0},
                group:[],
                search:[],
                optionOpened: 0

            })
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'collection-list',
                status: false

            });
        })

};

export const updatecollections = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-list',
        status:true

    });

    if(params.type === "project"){
        var listallcollections = collections.listProjectCollections(params);
    }
    else{
        var listallcollections = collections.listCollections(params);

    }
    listallcollections.then(listallcollections => {
        dispatch({
            type: 'LOAD_MORE_COLLECTIONS',
            listallcollections: listallcollections.data.results.data,
            group:params.group,
            search:params.search,
            optionOpened: 0

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-list',
            status: false

        });
    })

};
export const updatesearchcollections = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-list',
        status:true

    });
    let listallcollections = collections.listCollections(params);
    listallcollections.then(listallcollections => {
        ////////console.log(listallcollections.data.results.data.collection)
        dispatch({
            type: 'LOAD_MORE_SEARCH_COLLECTIONS',
            listallcollections: listallcollections.data.results.data.collection,
            group:params.group,
            search:params.search,
            optionOpened: 0

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-list',
            status: false

        });
    })

};

export const selectedcollections = (params) => dispatch => {
    let selectedcollections = collections.selectedCollections(params);
    selectedcollections.then(selectedcollections => {
        dispatch({
            type: 'LIST_SELECTED_COLLECTIONS',
            selectedcollections: selectedcollections
        })
    })

};


export const collectionActions = () => dispatch => {
    dispatch({
        type: 'RESET_COLLECTION_DATA',
    });


};


export const collectionDetail = (id, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let listCollectionDetail = params.projectid ? collections.listProjectCollectionDetail(id, params): collections.listCollectionDetail(id, params);

    listCollectionDetail.then(listCollectionDetail => {
        ////////console.log(listCollectionDetail)
        dispatch({
            type: 'LIST_COLLECTIONDETAIL',
            listCollectionDetail: listCollectionDetail,
            listdata:params.listdata
        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    })
        .catch(error => {
            dispatch({
                type: 'LIST_COLLECTIONDETAIL',
                listCollectionDetail: {data:{results: {parentinfo:[],dashboard:[],data:[]}}},
                listdata:params.listdata
            });
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'collection-details',
                status:false

            });
        })
};
export const loadmoreCollectionDetails = (id, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let listCollectionDetail = collections.listCollectionDetail(id, params);

    listCollectionDetail.then(listCollectionDetail => {
        dispatch({
            type: 'LOADMORE_COLLECTIONDETAIL',
            listCollectionDetail: listCollectionDetail,
            // listdata:params.listdata

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    })
};




export const viewChangelimit = () => dispatch => {
    dispatch({
        type: 'LIST_VIEW_COLLECTION_LIMIT',
    });
};



export const collectionFilter = (id, params) => dispatch => {
    // dispatch({
    //     type: 'LOADER_UPDATE_STATUS',
    //     loaderType: 'collection-details',
    //     status:true
    //
    // });
    // let listCollectionDetail = collections.listCollectionDetail(id, params);
    //
    // listCollectionDetail.then(listCollectionDetail => {
    //     dispatch({
    //         type: 'PAGE_HEADLINE',
    //         title: listCollectionDetail.data.results.parentinfo[0].title,
    //     });
    //     dispatch({
    //         type: 'LIST_COLLECTIONFILTER',
    //         listCollectionFilter: listCollectionDetail,
    //     })
    //     dispatch({
    //         type: 'LOADER_UPDATE_STATUS',
    //         loaderType: 'collection-details',
    //         status:false
    //
    //     });
    // })
    //
    //     .catch(error => {
    //         dispatch({
    //             type: 'PAGE_HEADLINE',
    //             title: [],
    //         });
    //         dispatch({
    //             type: "NET_WORK_ERROR",
    //             status:true,
    //
    //         })
    //         dispatch({
    //             type: 'LIST_COLLECTIONFILTER',
    //             listCollectionFilter: {data:{results:{data:[]}}},
    //         })
    //         dispatch({
    //             type: 'LOADER_UPDATE_STATUS',
    //             loaderType: 'collection-details',
    //             status:false
    //
    //         });
    //     })
};

export const collectionDataDelete = (collectionid, uid, records) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let deleteCollectionData = collections.deleteCollectionRecords(collectionid, uid, records);
    deleteCollectionData.then(deleteCollectionData => {
        dispatch({
            type: 'MODIFY_COLLECTION_RECORDS',
            deleteCollectionData: deleteCollectionData,

        });

    })
};

export const savemetaform = (params, type, params2) => dispatch => {
    //////////console.log(params);
    dispatch({
        type: 'SAVE_FORM_LOADING',
        loading: true,

    });
    let metaformdata = collections.saveMetaform(params);

    metaformdata.then(metaformdata => {

        dispatch({
            type: 'SAVE_FORM_LOADING',
            loading: false,

        });

        /*if(type && type === 'withoutdecision') {

            let wftasktriggered = workflow.triggerwftask(params);
            wftasktriggered.then(wftasktriggered => {
                dispatch({
                    type: 'PROCESS_WORKFLOW_TASK',
                    processWorkflowtask: wftasktriggered.data.data

                });
                dispatch({
                    type: 'SAVE_METAFORM',
                    metaformdata: metaformdata,

                })
                dispatch({
                    type: 'SAVE_FORM_LOADING',
                    loading: false,

                });
                let Processworkflowtask = workflow.ProcessNextStage(closeworkflowparams);
                Processworkflowtask.then(triggerwftask => {
                    let postTaskCompletionasync = task.postTaskCompletionasync(asyncparams);

                });

            })

            let Processworkflowtask = workflow.ProcessNextStage(params2);
            Processworkflowtask.then(Processworkflowtask => {

                    dispatch({
                        type: 'PROCESS_WORKFLOW_TASK',
                        processWorkflowtask: Processworkflowtask

                    })
                    dispatch({
                        type: 'SAVE_METAFORM',
                        metaformdata: metaformdata,

                    })
                    dispatch({
                        type: 'SAVE_FORM_LOADING',
                        loading: false,

                    });
            })
        }
        else*/ if(type && type === 'throughapi') {

            let Processworkflowtask = workflow.ProcessThroughApi(params2);

            Processworkflowtask.then(Processworkflowtask => {

                dispatch({
                    type: 'PROCESS_THROUGH_API',
                    processResult: Processworkflowtask

                })

                dispatch({
                    type: 'SAVE_METAFORM',
                    metaformdata: metaformdata,

                })
                dispatch({
                    type: 'SAVE_FORM_LOADING',
                    loading: false,

                });
            })
        }
        else{
            dispatch({
                type: 'SAVE_METAFORM',
                metaformdata: metaformdata,

            })
        }

    })
};
export const showform = (data, formaccess='') => dispatch => {
    if(formaccess === 'external') {
        let showformdata = collections.externalShowForm(data);
        showformdata.then(showformdata => {
            if(data.source) {
                dispatch({
                    type: 'GET_METAFORM',
                    getformdata: showformdata.data.data

                })
            }
            else {
                //console.log(showformdata.data.templateinfo)
                dispatch({
                    type: 'SHOW_METAFORM',
                    showformdata: showformdata.data.data,
                    template : showformdata.data.template,
                    templateInfo : showformdata.data.templateinfo,
                    pagelock : showformdata.data.pagelock,
                    width : showformdata.data.template_width,
                    status : showformdata.data.success,
                    thankyoustatus : showformdata.data.thankyoustatus,
                    thankyoulink : showformdata.data.thankyoulink,
                    thankyoucontent : showformdata.data.thankyoucontent,
                    thankyoulinkfield : showformdata.data.thankyoulinkfield,
                    thankyouimgcontent : showformdata.data.thankyouimgcontent,
                    profileimgcontent : showformdata.data.profileimgcontent,
                    header : showformdata.data.header,
                    footer : showformdata.data.footer,
                    auth : showformdata.data.auth,
                    collaborator : showformdata.data.collaborator,
                    jsenabled : showformdata.data.jsenabled,
                    saveasdraft : showformdata.data.saveasdraft,
                    buttonlist : showformdata.data.buttonlist,
                    rolesettings : showformdata.data.rolesettings,
                    master_published : showformdata.data.master_published,
                    quickform : showformdata.data.quickform,
                    thankyou_settings : showformdata.data.thankyou_settings,
                    showlist_enabled:showformdata.data.showlist_enabled
                })
            }

        })
            .catch(error => {
                dispatch({
                    type: 'SHOW_METAFORM',
                    showformdata: [],
                    template: [],
                    width: [],


                })
            })
    }
    else{
        let showformdata = collections.showForm(data);
        showformdata.then(showformdata => {
            if(data.source) {
                dispatch({
                    type: 'GET_METAFORM',
                    getformdata: showformdata.data.data

                })
            }
            else {
                dispatch({
                    type: 'SHOW_METAFORM',
                    showformdata: showformdata.data.data,
                    template : showformdata.data.template,
                    templateInfo : showformdata.data.templateinfo,
                    pagelock : showformdata.data.pagelock,
                    width : showformdata.data.template_width,
                    status : showformdata.data.success,
                    thankyoustatus : showformdata.data.thankyoustatus,
                    thankyoulink : showformdata.data.thankyoulink,
                    thankyoucontent : showformdata.data.thankyoucontent,
                    thankyoulinkfield : showformdata.data.thankyoulinkfield,
                    thankyouimgcontent : showformdata.data.thankyouimgcontent,
                    profileimgcontent : showformdata.data.profileimgcontent,
                    header : showformdata.data.header,
                    footer : showformdata.data.footer,
                    auth : showformdata.data.auth,
                    collaborator : showformdata.data.collaborator,
                    jsenabled : showformdata.data.jsenabled,
                    saveasdraft : showformdata.data.saveasdraft,
                    buttonlist : showformdata.data.buttonlist,
                    rolesettings : showformdata.data.rolesettings,
                    master_published : showformdata.data.master_published,
                    quickform : showformdata.data.quickform,
                    thankyou_settings : showformdata.data.thankyou_settings,
                    showlist_enabled:showformdata.data.showlist_enabled

                })
            }

        })
            .catch(error => {
                dispatch({
                    type: 'SHOW_METAFORM',
                    showformdata: [],
                    template: [],
                    width: []

                })
            })
    }




};


export const showformLinkedExternally = (data, formaccess='') => dispatch => {
    if(formaccess === 'external') {
        let extformdata = collections.externalShowForm(data);
        extformdata.then(extformdata => {
            if(data.source) {
                dispatch({
                    type: 'EXT_METAFORM',
                    getformdata: extformdata.data.data

                })
            }
            else {
                console.log(extformdata.data.templateinfo)
                dispatch({
                    type: 'SHOW_EXTFORM',
                    extformdata: extformdata.data.data,
                    template : extformdata.data.template,
                    templateInfo : extformdata.data.templateinfo,
                    pagelock : extformdata.data.pagelock,
                    width : extformdata.data.template_width,
                    status : extformdata.data.success,
                    thankyoustatus : extformdata.data.thankyoustatus,
                    thankyoulink : extformdata.data.thankyoulink,
                    thankyoucontent : extformdata.data.thankyoucontent,
                    thankyoulinkfield : extformdata.data.thankyoulinkfield,
                    thankyouimgcontent : extformdata.data.thankyouimgcontent,
                    profileimgcontent : extformdata.data.profileimgcontent,
                    header : extformdata.data.header,
                    footer : extformdata.data.footer,
                    auth : extformdata.data.auth,
                    collaborator : extformdata.data.collaborator,
                    saveasdraft : extformdata.data.saveasdraft,
                    buttonlist : extformdata.data.buttonlist,
                    rolesettings : extformdata.data.rolesettings,
                    master_published : extformdata.data.master_published,
                    quickform : extformdata.data.quickform,
                    thankyou_settings : extformdata.data.thankyou_settings
                })
            }

        })
            .catch(error => {
                dispatch({
                    type: 'SHOW_EXTFORM',
                    extformdata: [],
                    template: [],
                    width: [],


                })
            })
    }
    else{
        let extformdata = collections.showForm(data);
        extformdata.then(extformdata => {
            if(data.source) {
                dispatch({
                    type: 'EXT_METAFORM',
                    getformdata: extformdata.data.data

                })
            }
            else {
                dispatch({
                    type: 'SHOW_EXTFORM',
                    extformdata: extformdata.data.data,
                    template : extformdata.data.template,
                    templateInfo : extformdata.data.templateinfo,
                    pagelock : extformdata.data.pagelock,
                    width : extformdata.data.template_width,
                    status : extformdata.data.success,
                    thankyoustatus : extformdata.data.thankyoustatus,
                    thankyoulink : extformdata.data.thankyoulink,
                    thankyoucontent : extformdata.data.thankyoucontent,
                    thankyoulinkfield : extformdata.data.thankyoulinkfield,
                    thankyouimgcontent : extformdata.data.thankyouimgcontent,
                    profileimgcontent : extformdata.data.profileimgcontent,
                    header : extformdata.data.header,
                    footer : extformdata.data.footer,
                    auth : extformdata.data.auth,
                    collaborator : extformdata.data.collaborator,
                    saveasdraft : extformdata.data.saveasdraft,
                    buttonlist : extformdata.data.buttonlist,
                    rolesettings : extformdata.data.rolesettings,
                    master_published : extformdata.data.master_published,
                    quickform : extformdata.data.quickform,
                    thankyou_settings : extformdata.data.thankyou_settings
                })
            }

        })
            .catch(error => {
                dispatch({
                    type: 'SHOW_EXTFORM',
                    extformdata: [],
                    template: [],
                    width: []

                })
            })
    }

};

export const authenticateForm = (params) => dispatch => {

    let authenticated = collections.getFormAuthentication(params);
    authenticated.then(authenticated => {
        dispatch({
            type: 'FORM_AUTH_DATA',
            authSuccess: authenticated.data.success

        })

    });

}
export const getFormJson = (data, formaccess='') => dispatch => {

//console.log(data)
    dispatch({
        type: 'SHOW_METAFORM',
        showformdata: data.data,
        template : data.template,
        width : data.template_width,
        templateInfo : data.templateinfo,
        pagelock : data.pagelock,
        status : true

    })

    // axios({
    //     method: "GET",
    //     url: "../formjson/formdata.json",
    //     headers : {'Content-Type' : 'application/json',"X_API_KEY":AUTHENTICATION?(typeof  getProfile() !== "undefined"?getProfile().token:''):''}
    // }).then(function(res){
    //    //console.log(res)
    //     let showformdata = res;
    //     if(showformdata){
    //         //console.log(showformdata.data)
    //         dispatch({
    //             type: 'SHOW_METAFORM',
    //             showformdata: showformdata.data.data,
    //             template : showformdata.data.template,
    //             width : showformdata.data.template_width,
    //             templateInfo : showformdata.data.templateinfo,
    //             pagelock : showformdata.data.pagelock,
    //             status : true
    //
    //         })
    //     }
    //
    // }).catch(function(error){
    //    // //console.log(error)
    //     return error;
    // });
    /*window.onmessage = function(e) {
        var payload = JSON.parse(e.data);
        //console.log(payload)
        localStorage.setItem(payload.key, payload.data);

    };
    //console.log(data)
    //console.log(window.localStorage)
    let formData = window.localStorage.getItem(data._id);
    //console.log(formData)*/



};

export const getcollectionconfig = (param) => dispatch => {
    let collectionconfig = collections.getcollectionconfig(param);

    collectionconfig.then(collectionconfig => {

        dispatch({
            type: 'COLLECTION_CONFIQ',
            collectionconfig: collectionconfig,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status: false

        })

    }).catch(error => {
        dispatch({
            type: 'COLLECTION_CONFIQ',
            collectionconfig: {data: {dataview: [],settings:[],propagation:[],summary:[]}},

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status: false

        })
    })
};
export const savaDataView = (param) => dispatch => {
    let savaDataView = collections.savaDataView(param);



    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })

    savaDataView.then(savaDataView => {
        ////////////console.log(showformdata);
        dispatch({
            type: 'SAVE_DATA_VIEW',
            savaDataView: savaDataView,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })



    })
};
export const savaDataSettings = (param) => dispatch => {

    return new Promise((resolve,reject) => {
        let savaDataSettings = collections.savaDataSettings(param);
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status: true

        })
        savaDataSettings.then(savaDataSettings => {
            ////////////console.log(showformdata);
            resolve(1)
            dispatch({
                type: 'SAVE_DATA_SETTINGS',
                savaDataSettings: savaDataSettings,

            })
            // dispatch({
            //     type: 'LOADER_UPDATE_STATUS',
            //     loaderType: "",
            //     status: false
            //
            // })
        })
    })


};
export const singlerecorddAssign = (param) => dispatch => {
    let singlerecorddAssign = collections.singlerecorddAssign(param);
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    singlerecorddAssign.then(savaDataSettings => {
        ////////////console.log(showformdata);
        dispatch({
            type: 'SAVE_DATA_SETTINGS',
            savaDataSettings: savaDataSettings,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })
};

export const saveShareData = (param) => dispatch => {
    let saveShareData = collections.saveShareData(param);

    saveShareData.then(saveShareData => {
        ////////////console.log(showformdata);
        dispatch({
            type: 'SAVE_SHARE_DATA',
            saveShareData: saveShareData,

        })
    })
};


export const saveAssignData = (param) => dispatch => {
    let saveAssignData = collections.saveAssignData(param);

    saveAssignData.then(saveAssignData => {
        ////////////console.log(showformdata);
        dispatch({
            type: 'SAVE_ASSIGN_DATA',
            saveAssignData: saveAssignData,

        })
    })
};

export const saveFilter = (param) => dispatch => {
    return new Promise((resolve,reject) => {
        let saveFilter = collections.saveFilter(param);
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:true

        })

        saveFilter.then(saveFilter => {
            dispatch({
                type: 'SAVE_DATA_FILTER',
                saveFilter: saveFilter,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: "",
                status:false

            })

        })
    })


};

export const saveRightSidePanel = (param) => dispatch => {
    return new Promise((resolve,reject) => {
        let saveRightPanel = collections.saveRightSidePanel(param);
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:true

        })

        saveRightPanel.then(saveRightPanel => {
            // console.log(saveRightPanel);
            dispatch({
                type: 'SAVE_RIGHT_PANEL',
                saveRightPanel: saveRightPanel,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: "",
                status:false

            })

        })
    })


};

export const savelinkingpermission = (param) => dispatch => {
    let savelinkingpermission = collections.savelinkingpermission(param);

    savelinkingpermission.then(savelinkingpermission => {
        dispatch({
            type: 'SAVE_LINKING_PERMISSIONS',
            savelinkingpermission: savelinkingpermission,

        })
    })
};

export const importCollectionData = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "import-csv",
        status:true

    })
    let importCollectionData = collections.importCollectionData(param);
//console.log(importCollectionData)
    importCollectionData.then(importCollectionData => {
        dispatch({
            type: 'IMPORT_CSV_GET',
            importCSVData: importCollectionData.data,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}
export const importExcelData = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "import-excel",
        status:true

    })
    let importExcelData = collections.importExcelData(param);

    importExcelData.then(importExcelData => {
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })
    })

}
export const sendBsynOtp = (param) => dispatch => {

    let sendBsynOtp = collections.sendBsynOtp(param);

    sendBsynOtp.then(sendBsynOtp => {
        dispatch({
            type: 'GET_OTP',
            BsynOtp: (sendBsynOtp.data) ? sendBsynOtp.data.success : '',
            data: sendBsynOtp.data
        })

    })

}
export const verifyBsynotp = (param) => dispatch => {

    let verifyBsynotp = collections.verifyBsynotp(param);

    verifyBsynotp.then(verifyBsynotp => {
        dispatch({
            type: 'VERIFY_OTP',
            verifyOtp: (verifyBsynotp.data) ? verifyBsynotp.data.success : '',
            data: verifyBsynotp.data
        })
    })

}
export const paymentSend = (Param) => dispatch => {

    let paymentSend = collections.paymentSend(Param);
    //console.log(paymentSend);
    paymentSend.then(paymentSend => {
        dispatch({
            type: 'GET_PAYMENT',
            paymentSend: paymentSend
        })

    })

}
export const updateImportCsvtStram = (param) => dispatch => {
    dispatch({
        type: 'IMPORT_CSV_UPDATE',
        csvstatus: param,
    })
}
// export const updateImportExceltStram = (param) => dispatch => {
//         dispatch({
//             type: 'IMPORT_EXCEL_UPDATE',
//             excelstatus: param,
//         })
// }

export const getFormData = (data) => dispatch => {
    let formData = collections.getFormData(data);
    dispatch({
        type: 'GET_FORM_DATA_LOADER',
        loading:true,

    });
    dispatch({
        type: 'RESET_FORM_DATA',
    });
    formData.then(formData => {
        dispatch({
            type: 'GET_FORM_DATA',
            formvalues: formData.data.results.data,
            meta:formData.data.results.meta,
            wfinfo : formData.data.results.wfinfo,
            right_panel : formData.data.results.right_panel,
            wf_meta_users : formData.data.results.wf_meta_users,
            versions:formData.data.results.versions,
            id: formData.data.results.id,
            draft : formData.data.results.draft
        })
        dispatch({
            type: 'GET_FORM_DATA_LOADER',
            loading:false,

        });
    })
};


export const sharecollection = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'share-collection',
        status:true

    });
    let shareCollection = collections.shareCollection(params);
    shareCollection.then(selectedcollections => {
        dispatch({
            type: 'SHARE_COLLECTION',
            shared: 1,
            msg: ''

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'share-collection',
            status:false

        });

    }).catch(error => {
            dispatch({
                type: 'SHARE_COLLECTION',
                shared: 1,
                msg: error.message

            });
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'share-collection',
                status: false

            });
        }

    )

};

export const getExternalValues = (data) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "Dropdown-Filter-Autofill",
        status:true

    })
    let externalValues = collections.getExternalValues(data);
    externalValues.then( externalValues => {
        if( externalValues.data.results) {
            let formData = externalValues.data.results.data;
////////console.log(formData);
            dispatch({
                type: 'GET_EXTERNAL_FORM_DATA',
                formData:formData,
                masterid : data.masterid
            });
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: "Dropdown-Filter-Autofill",
                status:false
            });
        }
    });

};

export const getDatabindingValues = (data) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "Data-Binding-Loader",
        status:true

    })
    let DatabindingValues = collections.getDatabindingValues(data);
    DatabindingValues.then( DatabindingValues => {
        if( DatabindingValues.data.results) {
            let formData = DatabindingValues.data.results.data;
            let id = DatabindingValues.data.results.id;
            dispatch({
                type: 'GET_DATA_BINDING_FORM_DATA',
                bindingData:formData,
                masterid : id
            });
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: "Data-Binding-Loader",
                status:false

            });
        }
    });

};

export const getAggregate = (data) => dispatch => {
    let aggregateValue = collections.getAggregate(data);
    aggregateValue.then( aggregateValue => {
        if( aggregateValue.data.data) {
            let aggData = aggregateValue.data.data;
            dispatch({
                type: 'GET_AGGREGATE_DATA',
                aggData:aggData,
                refparams : data,
                computedfield:aggregateValue
            });
        }
        else{
            dispatch({
                type: 'GET_AGGREGATE_DATA',
                computedfield:aggregateValue
            });
        }
    });

};

export const saveForm = (data) => dispatch => {

    dispatch({
        type: 'SAVE_FORM_DRAFT',
        draftSaved : 'no',
        draftId : ''
    });
    let formSaved = collections.saveForm(data);

    formSaved.then( formSaved => {
        if(formSaved.data) {
            if(formSaved.data.results.success) {
                if(data.draft) {
                    dispatch({
                        type: 'SAVE_FORM_DRAFT',
                        draftSaved : 'yes',
                        draftId :formSaved.data.results.data
                    });

                }
                else {
                    dispatch({
                        type: 'SAVE_FORM_DATA',
                        formSaved :formSaved.data.results.data,
                        formSavedRes :formSaved.data.results

                    });
                    window.parent.postMessage({ message: "recordId", value: formSaved.data.results.data }, "*");


                    let params={
                        "taskid": data.referenceID,
                        "col_id": data.parent,
                        "dataid": formSaved.data.results.data,
                        "uid": data.uid
                    };
                    let para1={
                        "masterid":data.parent,
                        "childid":formSaved.data.results.data,
                        "index":ES_INDEX
                    }
                    let para2={
                        "uid":data.uid,
                        "usertype":data.usertype,
                        "collectionid":data.parent,
                        "collectionformid":formSaved.data.results.data,
                        index:ES_INDEX
                    }
                    setTimeout(function () {
                        if(data.mode === 'save') {
                            // collections.startcronjob(para1);
                            // collections.startworkflow(para2);
                        }
                        if(data.formtype === "embed"){
                            dispatch({
                                type: 'UPDATE_EMBED_FORM',
                                saved:true
                            });
                        }
                        //Workflow tasksubmission
                        if(data.closeTaskOnFormSubmit) {
                            let closeworkflowparams = JSON.parse(data.closeworkflowparams);
                            let todoid = closeworkflowparams.previoustask ? closeworkflowparams.previoustask : closeworkflowparams.taskid;
                            let uid = closeworkflowparams.uid;
                            var params = {
                                previoustask: todoid,
                                uid : uid,
                                redis_value : [
                                    {
                                        meta: [],
                                        submission_status : '',
                                        submission_statusid: '',
                                        submitted_user: uid,
                                        taskdone : true
                                    }
                                ]
                            }

                            let wftasktriggered = workflow.triggerwftask(params);
                            wftasktriggered.then(wftasktriggered => {

                                dispatch({
                                    type: 'SAVE_FORM_LOADING',
                                    loading: false,

                                });
                                dispatch({
                                    type: 'PROCESS_WORKFLOW_TASK',
                                    processWorkflowtask: wftasktriggered.data.data

                                })
                                let Processworkflowtask = workflow.ProcessNextStage(closeworkflowparams);
                                Processworkflowtask.then(triggerwftask => {

                                    // let postTaskCompletionasync = task.postTaskCompletionasync(asyncparams);

                                });

                            })
                        }

                        //Normal task done on form submission - Daily work report case
                        if(data.markAsDoneOnFormSubmit) {
                            let markasdoneparams = JSON.parse(data.markasdoneparams);

                            let markasdone = task.MarkAsDone(markasdoneparams);
                            dispatch({
                                type: 'TEMP_DATA_ASYNC_CALL',
                                taskasynccall:true,

                            })

                            markasdone.then(markasdone => {

                                dispatch({
                                    type: 'MARK_AS_DONE',
                                    results:markasdone
                                })

                                dispatch({
                                    type: "UPDATE_MARK_AS_DONE_SIDEBAR_MENU_COUNT"
                                })
                                dispatch({
                                    type: "UPDATE_MARK_AS_DONE_COUNT",
                                    results:markasdone
                                })



                            })
                        }



                    }, 2000)

                    if(formSaved.data && formSaved.data.results.success) {
                        let updatecollectiontodo = collections.updatetodoCollection(params);
                        updatecollectiontodo.then(updatecollectiontodo => {
                            dispatch({
                                type: 'UPDATE_TODO_COLLECTION',
                                updatecollectiontodo
                            });

                        });
                    }

                    if(data.formSource === 'task') {
                        dispatch({
                            type: 'UPDATE_TASK_FORM',
                            saved:true,
                            formId : data.parent
                        });
                    }
                }
            }
            else {
                dispatch({
                    type: 'ERROR_FORM_SAVING',
                    formSaveError : true,
                    errorMessage :formSaved.data.results.message,
                    errorCode :formSaved.data.results.code,
                    formSaveStatus :formSaved.data.results
                });
            }


        }
        else {
            dispatch({
                type: 'ERROR_FORM_SAVING',
                formSaveError : true,
                errorMessage : formSaved.data.results.message,
                errorCode :formSaved.data.results.code,
                formSaveStatus :formSaved.data.results
            });
        }


    });


};

export const linkedsaveForm = (data) => dispatch => {
    let lnkedformSaved = collections.saveForm(data);
    lnkedformSaved.then( lnkedformSaved => {
        if(lnkedformSaved.data) {
            dispatch({
                type: 'SAVE_LINKED_FORM_DATA',
                lnkedformSaved :lnkedformSaved.data.results.data

            });

            let params={
                "taskid": data.referenceID,
                "col_id": data.parent,
                "dataid": lnkedformSaved.data.results.data,
                "uid": data.uid
            };
            let para1={
                "masterid":data.parent,
                "childid":lnkedformSaved.data.results.data,
                "index":ES_INDEX
            }
            let para2={
                "uid":data.uid,
                "usertype":data.usertype,
                "collectionid":data.parent,
                "collectionformid":lnkedformSaved.data.results.data,
                index:ES_INDEX
            }
            setTimeout(function () {
                if(data.mode === 'save') {
                    // collections.startcronjob(para1);
                    // collections.startworkflow(para2);
                }
                if(data.formtype === "embed"){
                    dispatch({
                        type: 'UPDATE_EMBED_FORM',
                        saved:true
                    });
                }

            }, 2000)

            let updatecollectiontodo = collections.updatetodoCollection(params);
            updatecollectiontodo.then( updatecollectiontodo => {
                dispatch({
                    type: 'UPDATE_TODO_COLLECTION',
                    updatecollectiontodo
                });

            });



            if(data.formSource === 'task') {
                dispatch({
                    type: 'UPDATE_TASK_FORM',
                    saved:true,
                    formId : data.parent
                });
            }
        }
        else {
            dispatch({
                type: 'ERROR_LINKED_FORM_SAVING',
                linkformSaveError : true

            });
        }


    });


};

export const saveReadonlyForm = () => dispatch => {
    dispatch({
        type: 'UPDATE_EMBED_FORM',
        saved:true
    });
};

export const checkIfUnique = (data, type) => dispatch => {
    let uniqueValue = collections.checkIfUnique(data);
    uniqueValue.then( uniqueValue => {
        //console.log(uniqueValue.data.results.success)
        // console.log(type)
        if(type && type === 'linked') { //This for linked form modal elemnts
            dispatch({
                type: 'LK_VALUE_EXISTS',
                lk_fieldid : data.fieldid,
                [data.fieldid+'_lk_valueExists'] : uniqueValue.data.results.success,
                lk_recordid : uniqueValue.data.results.recordid,
                lk_value : data.value
            });
        }
        else {
            dispatch({
                type: 'VALUE_EXISTS',
                fieldid : data.fieldid,
                [data.fieldid+'_valueExists'] : uniqueValue.data.results.success,
                recordid : uniqueValue.data.results.recordid,
                value : data.value
            });
        }

    });
};
export const resetUniqueValues = (fieldid) => dispatch => {
    dispatch({
        type: 'RESET_VALUE_EXISTS',
        fieldid : fieldid,
    });
};
export const resetLKUniqueValues = (fieldid) => dispatch => {

    dispatch({
        type: 'RESET_LK_VALUE_EXISTS',
        lk_fieldid : fieldid,
    });
};


export const listTodoCollections = (params, formaccess='') => dispatch => {

    if(formaccess === 'external') {
        let listallcollections = collections.externalListTodoCollections(params);

        listallcollections.then(listallcollections => {
            let collections = listallcollections.data.results.data.collection;
            let  collectionsubgroup = collections;
            if( !params.taskform || params.taskform !== 'yes') {
                collectionsubgroup = collections.map(item=> { item['title'] = atob(item['title']); return item;})
            }


            dispatch({
                type: 'LIST_TODO_COLLECTIONS',
                collectionsubgroup: collectionsubgroup
            });
        }).catch(error => {
            dispatch({
                type: 'LIST_TODO_COLLECTIONS',
                collectionsubgroup: []
            });
        })
    }
    else {
        let listallcollections = collections.listTodoCollections(params);

        listallcollections.then(listallcollections => {
            let collections = listallcollections.data.results.data.collection;
            let  collectionsubgroup = collections;
            if( !params.taskform || params.taskform !== 'yes') {
                collectionsubgroup = collections.map(item=> { item['title'] = atob(item['title']); return item;})
            }


            dispatch({
                type: 'LIST_TODO_COLLECTIONS',
                collectionsubgroup: collectionsubgroup
            });
        }).catch(error => {
            dispatch({
                type: 'LIST_TODO_COLLECTIONS',
                collectionsubgroup: []
            });
        })
    }



};
export const getCollectionSettings = (params) => dispatch => {
    dispatch({
        type: 'COLLECTION_SETTINGS_LOADER',
        loading:true

    });
    let collectionsettings = collections.getCollectionSettings(params);

    collectionsettings.then(collectionsettings => {
        dispatch({
            type: 'COLLECTION_SETTINGS',
            collectionsettings: collectionsettings.data.data
        });
        dispatch({
            type: 'COLLECTION_SETTINGS_LOADER',
            loading:false

        });
    })
        .catch(error => {
            dispatch({
                type: 'COLLECTION_SETTINGS',
                collectionsettings: []
            });
            dispatch({
                type: 'COLLECTION_SETTINGS_LOADER',
                loading:false

            });
        })

};

export const getFilterData= (data) => dispatch => {
    let filterData = collections.formFilter(data);

    filterData.then(filterData => {
        dispatch({
            type: 'FORM_FILTER_DATA',
            filterData:filterData
        })

    })
        .catch(error => {
            dispatch({
                type: 'FORM_FILTER_DATA',
                filterData: {data:{results:{data:[]}}}
            })
        })
};

export const exportField= (data) => dispatch => {
    let exportfield = collections.exportField(data);

    exportfield.then(exportField => {
        dispatch({
            type: 'EXPORT_DISPLAY_DATA',
            exportfield:exportField
        })

    })
};

export const saveExportFieldData= (data) => dispatch => {
    let saveExportFieldData = collections.saveExportFieldData(data);
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    saveExportFieldData.then(saveExportFieldData => {
        dispatch({
            type: 'SAVE_EXPORT_DISPLAY_DATA',
            saveexportfielddata:saveExportFieldData
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "",
            status:false

        })

    })
};

export const exportall= (data) => dispatch => {
    let exportall = collections.exportall(data);
    dispatch({
        type: 'EXPORT_ALL_STATUS',
        loaderType: "EXPORT-ALL",
        status: true

    })
}

export const searchEntity = (data) => dispatch => {

    let entityList = collections.searchEntity(data);
    dispatch({
        type: 'GET_ENTITY_LOADER',
        loading:true,

    });
    entityList.then( entityList => {
        let entityData= entityList.data.data;
        let modifiedList = entityData.map(a => { let id = a['_id']; a = a['_source']; a['id'] = id; a['name'] = atob(a['name']); return a;});

        dispatch({
            type: 'LIST_ENTITY',
            entityList : modifiedList
        });
        dispatch({
            type: 'GET_ENTITY_LOADER',
            loading:false,

        });
    });

};


export const getCategory = (data) => dispatch => {

    let categoryList = collections.getCategory(data);
    dispatch({
        type: 'GET_CATEGORY_LOADER',
        loading:true,

    });
    categoryList.then( categoryList => {
        dispatch({
            type: 'LIST_CATEGORY',
            categoryList
        });
        dispatch({
            type: 'GET_CATEGORY_LOADER',
            loading:false,

        });
    });

};

export const createCollections = (data) => dispatch => {

    let collectionCreated = collections.createCollections(data);

    // let newCollection =  {
    //     "_id": data.collectionid,
    //     "share": 0,
    //     "filetitle": data.filetitle,
    //     "filename": data.filename,
    //     "complaincecategory": data.complaincecategory,
    //     "has_form": "no",
    //     "subname": "",
    //     "description": btoa(data.description),
    //     "filesize": data.filesize,
    //     "title": btoa(data.title),
    //     "createuser":getProfile().id,
    //     "cid": data.collectionid,
    //     "childs": 0
    // }
    collectionCreated.then( collectionCreated => {
        // dispatch({
        //     type: 'UPDATE_COLLECTION',
        //     newCollection:newCollection
        // });
        dispatch({
            type: 'COLLECTION_CREATED',
        });
    });

};

export const createCategory= (data) => dispatch => {
    let CategoryCreated = collections.createCategory(data);

    CategoryCreated.then(CategoryCreated => {
        dispatch({
            type: 'CATEGORY_CREATED',
            params:data
        })

    })
};
export const selectExport= (data) => dispatch => {
    let selectExport = collections.selectExport(data);


};
export const resetForm = () => dispatch => {
    dispatch({
        type: 'RESET_FORM_DATA'
    });
};

export const resetExternalFormData = () => dispatch => {
    dispatch({
        type: 'RESET_EXTERNALFORM_DATA',
    });


};

export const CollectionTemplateSaving = (cId,param) => dispatch => {

    let collectionTemplate = collections.collectionSaveTemplate(cId,param);
    dispatch({
        type: 'COLLECTION_TEMPLATE_LOADER',
        loading:true,

    });
    collectionTemplate.then( collectionTemplate => {
        dispatch({
            type: 'COLLECTION_SAVE_TEMPLATE',
            collectionTemplate: collectionTemplate
        });
        dispatch({
            type: 'COLLECTION_TEMPLATE_LOADER',
            loading:false,

        });
    });

};

export const listingWorkflow = (param) => dispatch => {

    let listingWorkflow = collections.listingWorkflow(param);

    listingWorkflow.then( listingWorkflow => {
        dispatch({
            type: 'LISTING_WORKFLOW',
            listingWorkflow: listingWorkflow.data.data
        });
        dispatch({
            type: 'COLLECTION_RULE_LOADER',
            loader: false
        });

    });

};
export const linkedWorkflow = (cid,param) => dispatch => {
    dispatch({
        type: 'COLLECTION_RULE_LOADER',
        loader: true
    });
    let linkedWorkflow = collections.linkedWorkflow(cid,param);
    linkedWorkflow.then( linkedWorkflow => {
        dispatch({
            type: 'LISTING_LINKED_WORKFLOW',
            linkedWorkflow: linkedWorkflow.data.results.data
        });
        dispatch({
            type: 'COLLECTION_RULE_LOADER',
            loader: false
        });


    });

};
export const deletelinkedWorkflow = (param) => dispatch => {
    dispatch({
        type: 'COLLECTION_RULE_LOADER',
        loader: true
    });
    let deletelinkedWorkflow = collections.deletelinkedWorkflow(param);
    deletelinkedWorkflow.then( deletelinkedWorkflow => {
        dispatch({
            type: 'DELETE_WORKFLOW',
            deletelinkedWorkflow: deletelinkedWorkflow
        });

    });

};

/* collection rule */

export const getRule = (param) => dispatch => {

    let getRule = collections.getRule(param);
    dispatch({
        type: 'COLLECTION_RULE_LOADER',
        loader: true
    });
    getRule.then( getRule => {
        dispatch({
            type: 'COLLECTION_GET_RULE',
            getrule: getRule.data.data
        });
        dispatch({
            type: 'COLLECTION_RULE_LOADER',
            loader: false
        });
    });

};
export const createGenericRule = (param) => dispatch => {
    dispatch({
        type: 'COLLECTION_RULE_LOADER',
        loader: true
    });
    let createGenericRule = collections.createGenericRule(param);
    createGenericRule.then( createGenericRule => {
        dispatch({
            type: 'CREATE_GENERAL_RULE',
            createGenericRule: createGenericRule
        });

    });

};

export const genericRuleDelted = (param,wID) => dispatch => {
    let genericRuleDelted = collections.genericRuleDelted(param,wID);
    dispatch({
        type: 'COLLECTION_RULE_LOADER',
        loader: true
    });
    genericRuleDelted.then( genericRuleDelted => {
        dispatch({
            type: 'DELETE_GENERIC_RULE',
            deletegenericWorkflow: genericRuleDelted
        });

    });

};
export const fieldwiseRuleDelted = (param,wID) => dispatch => {
    let fieldwiseRuleDelted = collections.genericRuleDelted(param,wID);
    dispatch({
        type: 'COLLECTION_RULE_LOADER',
        loader: true
    });
    fieldwiseRuleDelted.then( fieldwiseRuleDelted => {
        dispatch({
            type: 'DELETE_FIELDWISE_RULE',
            fieldwiseRuleDelted: fieldwiseRuleDelted
        });

    });

};
export const saveWorkflowrule = (param) => dispatch => {
    let saveWorkflowrule = collections.saveWorkflowrule(param);
    dispatch({
        type: 'COLLECTION_RULE_LOADER',
        loader: true
    });
    saveWorkflowrule.then( saveWorkflowrule => {
        dispatch({
            type: 'SAVE_WORK_FLOW_RULE',
            saveWorkflowrule: saveWorkflowrule
        });

    });

};

export const trackCollection = (data) => dispatch => {
    data.useragent = navigator.userAgent;
    data.browser = navigator.appName;

    let tracked = collections.trackCollection(data);
};


export const formLabels = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let showformdata = collections.showForm(param);
    showformdata.then( showformdata => {
        dispatch({
            type: 'SHOW_META_FORM_LABELS',
            showformdata: showformdata.data.data,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })

    });

};


export const savePropagation = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let savepropagation = collections.savePropagation(param);
    savepropagation.then( savepropagation => {
        dispatch({
            type: 'SAVE_PROPAGATION_DATA',
            savepropagation:savepropagation.data

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })
    });

};
export const deletePropagation = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let deletePropagation = collections.deletePropagation(param);
    deletePropagation.then( deletePropagation => {
        dispatch({
            type: 'DELETE_PROPAGATION_DATA',
            data:deletePropagation

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })
    });

};

export const listPropgationCollections = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let listPropgationCollections = collections.listPropgationCollections(param);
    listPropgationCollections.then( listPropgationCollections => {
        dispatch({
            type: 'LIST_PROPAGATION_COLLECTION_DATA',
            list:listPropgationCollections.data.data

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })
    });

};

export const deleteAll = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let deleteAllrecords = collections.deleteAllrecords(param);
    deleteAllrecords.then( deleteAllrecords => {
        //console.log(deleteAllrecords)
        dispatch({
            type: 'DELETE_ALL_RECORDS',
            status:deleteAllrecords.data

        })
        if(deleteAllrecords.data.success) {
            dispatch({
                type: 'LIST_COLLECTIONDETAIL',
                listCollectionDetail: {
                    data: {
                        results: {
                            parentinfo: [],
                            dashboard: [],
                            data: [],
                            totalchilds: 0,
                            facet:[]
                        }
                    }
                },
            });
        }

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })
    });

};

export const maploadmoreCollectionDetails = (id, params) => dispatch => {
    dispatch({
        type: 'COLLECTION_DETAIL_MAP',
        listCollectionDetail: [],

    });
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-map-details',
        status:true

    });
    let maplistCollectionDetail = collections.maplistCollectionDetail(id, params);

    maplistCollectionDetail.then(maplistCollectionDetail => {
        dispatch({
            type: 'COLLECTION_DETAIL_MAP',
            listCollectionDetail: maplistCollectionDetail,

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-map-details',
            status:false

        });
    })
};

export const loadmoremapData = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let loadmoremapData = collections.loadmoremapData(params);

    loadmoremapData.then(loadmoremapData => {
        dispatch({
            type: 'LOAD_MORE_COLLECTION_DETAIL_MAP',
            listCollectionDetail: loadmoremapData,

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    })
};


export const getFilterSettings = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'collection-details',
        status:true

    });
    let getFilterSettings = collections.getFilterSettings(params);

    getFilterSettings.then(getFilterSettings => {
        if(getFilterSettings.data.results.success) {
            dispatch({
                type: 'FILTER_SETTINGS_DATA',
                settings: getFilterSettings.data.results.data,

            });
        }
        else{
            dispatch({
                type: 'FILTER_SETTINGS_DATA',
                settings: [],

            });
        }
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-details',
            status:false

        });
    })
};



export const saveFilterSettings = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-settings-data",
        status:true

    })
    let saveFilterSettings = collections.saveFilterSettings(params);

    saveFilterSettings.then(saveFilterSettings => {

        dispatch({
            type: 'SAVE_FILTER_SETTINGS_DATA',
            settings: saveFilterSettings,

        });


        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-settings-data',
            status:false

        });
    })
};


export const saveData = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-settings-data",
        status:true

    })
    let saveData = collections.saveData(params);

    saveData.then(saveData => {

        dispatch({
            type: 'SAVE_TEMPLATE_SETTINGS_DATA',
            saveData: saveData,

        });


        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'collection-settings-data',
            status:false

        });
    })
};


export const starClick = (params) => dispatch => {
    let starClick = collections.starClick(params);
};

export const getIdFromUniqueId = (id, params) => dispatch => {

    let collectionInfo = collections.getCollectedInfo(id, params);

    collectionInfo.then(collectionInfo => {
        //console.log(collectionInfo.data.results)
        let formRecords = collectionInfo.data.results ? collectionInfo.data.results.data : [];
        let formRecordId = formRecords.length > 0 ? formRecords[0].id  : '';


        dispatch({
            type: 'GET_RECORD_ID_UNIQUEID',
            formRecordId: formRecordId
        });
    })
};



export const listNotes = (params) => dispatch => {
    if(params.recordid !== undefined && params.recordid !== '' ) {

        dispatch({
            type: 'LIST_ANNOTATION',
            listAnn: [],
            loader: true

        });

        let listAnnotation = collections.listAnnotation(params);

        listAnnotation.then(listAnnotation => {
            dispatch({
                type: 'LIST_ANNOTATION',
                listAnn: listAnnotation.data.data,
                loader: false

            });

        })
    }
};
export const saveNotes = (params) => dispatch => {
    dispatch({
        type: 'SAVE_ANNOTATION',
        saveAnn:[],


    });

    let saveAnnotation = collections.saveAnnotation(params);
    saveAnnotation.then(saveAnnotation => {
        dispatch({
            type: 'SAVE_ANNOTATION',
            saveAnn: saveAnnotation,


        });

    })
};
export const listSystemEntity = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let listallsystementities = collections.listsystementities(param);
    listallsystementities.then( listallsystementities => {

        let entityList= listallsystementities.data.data;
        dispatch({
            type: 'LIST_SYSTEM_ENTITY_DATA',
            entityList:entityList

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })
    });

};
export const listEntityCollections = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let listentityCollections = collections.listentityCollections(param);
    listentityCollections.then( listentityCollections => {
        dispatch({
            type: 'LIST_ENTITY_COLLECTION_DATA',
            list:listentityCollections.data.dataview.data

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })
    });

};
export const saveEntityMapping = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let savemapping = collections.saveentitymapping(param);
    savemapping.then( savemapping => {
        dispatch({
            type: 'SAVE_ENTITY_DATA',
            data:savemapping

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })
    });

};
export const deleteEntityMapping = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "collection-details",
        status:true

    })
    let deleteMapping = collections.deleteentitymapping(param);
    deleteMapping.then( deleteMapping => {
        dispatch({
            type: 'DELETE_ENTITY_DATA',
            data:deleteMapping

        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "collection-details",
            status:false

        })
    });

};
export const trackVersion = (param) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: "track-version",
        status:true

    })
    let trackVersion = collections.fieldVersion(param);
    trackVersion.then( trackVersion => {
        dispatch({
            type: 'TRACK_PREVIOUS_VERSION',
            previousList: trackVersion.data && trackVersion.data.data ? trackVersion.data.data : [],
            userinfo: trackVersion.data && trackVersion.data.userinfo ? trackVersion.data.userinfo : '',
        })

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: "track-version",
            status:false

        })
    });

};
export const saveCalendarSettings = (param) => dispatch => {
    let saveCalendarSettings = collections.saveCalendarSettings(param);

    saveCalendarSettings.then(saveCalendarSettings => {

        dispatch({
            type: 'SAVE_CALENDAR_SET',
            saveCalendarSettings: saveCalendarSettings,

        })
    })
};

export const deleteCalendarSettings = (data) => dispatch => {

    let deleteCalendarSettings = collections.deleteCalendarSettings(data);
    deleteCalendarSettings.then(deleteCalendarSettings => {
        dispatch({
            type: 'DELETE_CALENDAR_SET',
            deleteCalendarSettings: deleteCalendarSettings,

        })
    })

}
export const listCalendarSettings = (data) => dispatch => {

    let listCalendarSettings = collections.listCalendarSettings(data);
    listCalendarSettings.then(listCalendarSettings => {
        //// console.log(listCalendarSettings);
        dispatch({
            type: 'LIST_CALENDAR_SET',
            listCalendarSettings: listCalendarSettings.data.data,

        })
    })

}
export const saveInvokeWorkflow = (param) => dispatch => {
    let saveInvokeWorkflow = collections.saveInvokeWorkflow(param);
    saveInvokeWorkflow.then(saveInvokeWorkflow => {
        dispatch({
            type: 'INVOKE_WORKFLOW',
            saveInvokeWorkflow: saveInvokeWorkflow,

        })
    })
};
export const saveResourcesFromEditor = (param) => dispatch => {
    let resourceSaved = resources.saveGallery(param);
    resourceSaved.then(resourceSaved => {
        dispatch({
            type: 'EDITOR_RESOURCE_SAVED',
            resourceSaved: resourceSaved,

        })
    })
};

export const triggerCustomApi = (data, fieldid) => dispatch => {
    console.log(data)

    let customApiResult = collections.triggerCustomApi(data.url, data.method, data.args);
    customApiResult.then( customApiResult => {
        console.log(customApiResult)
        if( customApiResult.data.data) {
            let customApiData = customApiResult.data.data;
            console.log(customApiData);
            console.log(customApiResult.data);
            dispatch({
                type: 'GET_CUSTOM_API_DATA',
                [fieldid+'_customApiData'] : customApiData,
                fieldId : fieldid
            });
        }
    });

};


