import decode from 'jwt-decode';
import {SHARED_LOGIN,AUTHENTICATION} from "../constants";

export function loggedIn() {
    if (!SHARED_LOGIN.status) {
        // Checks if there is a saved token and it's still valid
        const token = getToken()
        // GEtting token from localstorage
        return !!token && !isTokenExpired(token) // handwaiving here
    } else {
        /*  Codeigniter Shared Login*/
        if (check_cookie_name(SHARED_LOGIN.cookie) !== undefined) {
            return true;
        }
    }
}

export function getProfile() {
    /* get Jwt Token Data from local storage */
    if (!SHARED_LOGIN.status) {
        // Using jwt-decode npm package to decode the token
        return JSON.parse(decode(getToken()).user_id);
    } else {
        /* codeigniter shared login */
        return (getToken())
    }
}

export function logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem('id_token');
}

export function login(token) {
    setToken(token)
    return Promise.resolve(token);
}

function setToken(idToken) {
    localStorage.setItem('id_token', idToken)
}

function fetch(url, options) {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    if (this.loggedIn()) {
        headers['Authorization'] = 'Bearer ' + getToken()
    }

    return fetch(url, {
        headers,
        ...options
    })
        .then(_checkStatus)
        .then(response => response.json())
}

function _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}

function isTokenExpired(token) {
    try {
        const decoded = decode(token);
        if (decoded.exp < Date.now() / 1000) {
            return true;
        } else
            return false;
    } catch (err) {
        return false;
    }
}

function getToken() {
    /* Codeigniter shared login data */
    if (SHARED_LOGIN.status) {
        return check_cookie_name(SHARED_LOGIN.cookie);
    } else {
        /* react JWT TOKEN */
        return localStorage.getItem("id_token")
    }
}

function check_cookie_name(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        /* remove unnecessay whitespace in codeigniter cookie creation for react app */
        var scookie = match[2].replace(/\%3D/g, '');

        var match = match[2].replace(/\%3D/g, '');
        let Data = atob(match).split("|");
        var t = [];
        t['id'] = Data[0];
        t['username'] = Data[1];
        t['systype'] = Data[2];
        t['usertype'] = Data[2];
        t['pjtrole'] = Data[3];
        t['first_name'] = Data[4];
        t['last_name'] = Data[5];
        t['middle_name'] = Data[6];
        t['image'] = Data[7];
        t['email'] = Data[8];
        t['phone'] = Data[9];
        t['cookie'] = Data[10];
        t['channel_permission'] = Data[11];
        t['scookie'] = scookie;
        t['overseeStatus'] = Data[12];
        t['groupEnable'] = Data[13];
        // t['token1'] = Data[14];
        t['token'] =AUTHENTICATION? Data[15]:"";
        t['projectusers'] =AUTHENTICATION? Data[16]:"";
        t['enableVerify'] =Data[17];
        t['organization_id'] =Data[18];
        t['userRole'] =Data[19];
        return t;
    } else {

       // window.location.replace(SHARED_LOGIN.redirect_url+'login')

    }
}
