import React, {Component} from 'react';
import { LineChart, Line, Bar, XAxis, YAxis,CartesianGrid, Tooltip, Area } from 'recharts';
function LineChartCard(props) {
//console.log(props)
    let min = props.min ? props.min : 0;
    let max = props.max ? props.max : 1000;
    min = parseInt(min);
    max = parseInt(max);
    return (
        <React.Fragment>
            <LineChart width={200} height={35} data={props.data}
                       margin={{ top: 0, right: 0, left: 0, bottom: 13 }}>
                <XAxis dataKey="x" hide={true}/>
                <YAxis dataKey="y" hide={true}  domain={[min,max]}/>
                <Line type="monotone" dataKey="y" stroke={props.color} dot={false} strokeWidth={1} />
            </LineChart>
        </React.Fragment>
    );
}

export default LineChartCard;