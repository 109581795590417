import React from "react"
import FileViewer from 'react-file-viewer';

class FileViewers extends React.Component{
    constructor(props) {
        super(props);
    }
// componentDidUpdate(prevProps, prevState, snapshot) {
//         if(this.props.url === prevProps.url){
//             alert(1)
//             return false
//         }
// }
    shouldComponentUpdate(nextProps, nextState){
        return !(nextProps, this.props); // equals() is your implementation
    }


    render(){
        return(
            <div>

            <FileViewer
                fileType={this.props.type}
                filePath={this.props.url}
           //     onError={this.onError}
                height={this.props.height ? this.props.height : "500px"}
            />
            </div>



        )
    }
}


export default FileViewers
