import {task} from '../services/task.services'
import {getProfile} from "../utilities/AuthService";
import {users} from "../services/users.services";
import rootService from "../services";
import {commonService} from "../services/common.services";

const  TASKACTIONS={
    MARKASDONE:"MARK_AS_DONE",
    TODO:"TO_DO",
    FOLLOWUP:"FOLLOW_UP",
    UPDATE:"UPDATE_",
    SIDEBAR:"SIDEBAR_MENU",
    COUNT:"_COUNT",
    DATA:"_DATA",
    LIST:"_LIST",
    LOADER:"_LOADER",
    LOADMORE:"LOADMORE"


}
export const taskStatus = (params) => dispatch => {
    let taskStatusResult = task.listtaskStatus(params);

    dispatch({
        type: 'CONTENT_STATUS_PANEL',
        loading:true,

    })

    taskStatusResult.then(taskStatusResult => {
        let taskStatusData = taskStatusResult.data.results.data;
        dispatch({
            type: 'CONTENT_STATUS_DATA',
            taskData:taskStatusData,

        });
        dispatch({
            type: 'CONTENT_STATUS_PANEL',
            loading:false,

        })






    })


};

export const listtask = (params) => dispatch => {
    let taskResult = task.listTodo(params);
            dispatch({
                type: TASKACTIONS.SIDEBAR+TASKACTIONS.LOADER,
                loading:true,

            })
            dispatch({
                type: 'CONTENT_LOADER',
                loading:true,

            })

    taskResult.then(taskResult => {
        let taskData = taskResult.data.results.data;
        dispatch({
            type: params.actionType,
            taskData:taskData,

        });


        dispatch({
            type: "SIDEBAR_MENU_COUNT",
            archive:taskData.archive,
            comments:taskData.comments,
            todo:taskData.todo,
            tofollowup:taskData.followup,
            toverify:taskData.toverify,
            toknow:taskData.toknow,
            tooversee:taskData.oversee,
            todraft:taskData.todraft,
            toschedule:taskData.toscheduledcnt,
            duetoday:taskData.today_in,
            duetomorrow:taskData.tomorrow_in,
            dueinsevendays:taskData.week_in,
            duethismaonth:taskData.month_in,
            alloverdues:taskData.overdue_in,
            overdueinsevendays:taskData.overdueinsevendays,
            overdueinfifteendays:taskData.overdueinfifteendays,
            overdueinthirtydays:taskData.overdueinthirtydays,
            overdueinsixtydays:taskData.overdueinsixtydays,
            responds:taskData.responds
        })
        dispatch({
            type: TASKACTIONS.SIDEBAR+TASKACTIONS.LOADER,
            loading:false,

        });
        ////////////console.log("999999999999999999999");//////////console.log(taskData);
        /*dispatch({
            type: "TODO_TOP_BAR_DATA",
            newtask : taskData.todo,
            inprogress : taskData.followup,
            completed : taskData.statuswise_in.filter(item => item.key == 'Done')[0].doc_count,
            verified : taskData.verified_in,
            overdues : taskData.overdue_in,
        });

        dispatch({
            type: "TOFOLLOWUP_TOP_BAR_DATA",
            newtask : taskData.statuswise_out.filter(item => item.key == 'New')[0].doc_count,
            inprogress : taskData.statuswise_out.filter(item => item.key == 'InProgress')[0].doc_count,
            completed : taskData.statuswise_out.filter(item => item.key == 'Done')[0].doc_count,
            verified : taskData.verified_out,
            overdues : taskData.overdue_out,
        })

        dispatch({
            type: "TOCOMMENT_TOP_BAR_DATA",
            newtask : taskData.statuswise_comments.filter(item => item.key == 'New')[0].doc_count,
            inprogress : taskData.statuswise_comments.filter(item => item.key == 'InProgress')[0].doc_count,
            completed : taskData.statuswise_comments.filter(item => item.key == 'Done')[0].doc_count,
            verified : taskData.verified_comments,
            overdues : taskData.overdue_comments,
        })
        dispatch({
            type: "TOKNOW_TOP_BAR_DATA",
            newtask : taskData.statuswise_know.filter(item => item.key == 'New')[0].doc_count,
            inprogress : taskData.statuswise_know.filter(item => item.key == 'InProgress')[0].doc_count,
            completed : taskData.statuswise_know.filter(item => item.key == 'Done')[0].doc_count,
            verified : taskData.verified_know,
            overdues : taskData.overdue_know,
        })

        dispatch({
            type: "TOOVERSEE_TOP_BAR_DATA",
            newtask : taskData.statuswise_manage.filter(item => item.key == 'New')[0].doc_count,
            inprogress : taskData.statuswise_manage.filter(item => item.key == 'InProgress')[0].doc_count,
            completed : taskData.statuswise_manage.filter(item => item.key == 'Done')[0].doc_count,
            verified : taskData.verified_manage,
            overdues : taskData.overdue_manage,
        })
        dispatch({
            type: "DUETODAY_TOP_BAR_DATA",
            newtask : taskData.statuswise_todayin.filter(item => item.key == 'New')[0].doc_count,
            inprogress : taskData.statuswise_todayin.filter(item => item.key == 'InProgress')[0].doc_count,
        })
        dispatch({
            type: "DUETOMORROW_TOP_BAR_DATA",
            newtask : taskData.statuswise_tomorrowin.filter(item => item.key == 'New')[0].doc_count,
            inprogress : taskData.statuswise_tomorrowin.filter(item => item.key == 'InProgress')[0].doc_count,
        })
        dispatch({
            type: "DUEMONTH_TOP_BAR_DATA",
            newtask : taskData.statuswise_monthin.filter(item => item.key == 'New')[0].doc_count,
            inprogress : taskData.statuswise_monthin.filter(item => item.key == 'InProgress')[0].doc_count,
        })
        dispatch({
            type: "ALLOVERDUE_TOP_BAR_DATA",
            newtask : taskData.statuswise_overduein.filter(item => item.key == 'New')[0].doc_count,
            inprogress : taskData.statuswise_overduein.filter(item => item.key == 'InProgress')[0].doc_count,
        })
        dispatch({
            type: "ALLARCHIVE_TOP_BAR_DATA",
            newtask : taskData.statuswise_archive.filter(item => item.key == 'New')[0].doc_count,
            inprogress : taskData.statuswise_archive.filter(item => item.key == 'InProgress')[0].doc_count,
            completed : taskData.statuswise_archive.filter(item => item.key == 'Done')[0].doc_count,
            verified : taskData.verified_archive,
            overdues : taskData.overdue_archive,
        })*/

        dispatch({
            type: 'CONTENT_LOADER',
            loading:false,

        });

        var data = {status : "no"};
        dispatch({
            type: 'SET_TASK_SELECTION',
            data

        })


    })
        .catch(error => {
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: TASKACTIONS.SIDEBAR+TASKACTIONS.LOADER,
                loading:false,

            });

            dispatch({
                type: 'CONTENT_LOADER',
                loading:false,

            });
        });


};

export const searchType = (type,value) => dispatch => {

        dispatch({
            type: type,
            value:value,

        })



};

export const addTodo = (data, params, tasktype) => dispatch => {
    //console.log(data)
    //console.log(params).
    let tempTaskType=params.assignees.split(",")
    let selftask=data.selftask
    if(!selftask){
        if(tempTaskType.length ===1) {
            let _newtasktype = tempTaskType.filter(list => list === "user_"+getProfile().id)
            if(_newtasktype.length===1){
                selftask=true
            }
        }

    }

    let todoCreated;
     /*if(data.savemode === 'update' || data.savemode === 'reassign'){
         todoCreated = task.addTodoUpdate(data);
     }
     else{*/
         todoCreated = task.addTodo(data);
     //}

    let secId = data.section_id;
    let that=this
    todoCreated.then(todoCreated => {

       var taskData= todoCreated.data.data;
        //console.log(taskData)
        //Async call
        let  results={
            "has_collection": data.has_collection,
            "subject": btoa(((data.subject))),
            "assigntype": data.assignmentType,
            "createdfrom": data.createdfrom,
            "project": ["Nil"],
            "createdate": "2020-04-21 22:07:04",
            "reassign": "no",
            "readstatus": "read",
            "startdate": "",
            "assignmentType": "self",
            "masterid": params.i,
            "view": "no",
            "readdate": "2020-04-21 22:07:04",
            "share": "no",
            "fileID": "",
            "tasktype":  data.tasktype,
            "formconcat": data.concat,
            "visibility": "yes",
            "has_attachemnt": data.has_attachemnt,
            "Title": btoa(encodeURIComponent(escape(data.title))),
            "archive": "no",
            "priority": data.priority,
            "createuser": getProfile().id,
            "tags": [

            ],
            "duedate": params.duedate,
            "status": "New",
            "projectcode": [],
            "inner_hits": {
                "childtodo": {
                    "hits": {
                        "total": 1,
                        "max_score": 2.8824878,
                        "hits": [
                                {
                                    "_type": "childtodo",
                                    "_id": "AXGex8RI8nrP4TrVisKs",
                                    "_score": 2.8824878,
                                    "_routing": "2_1587506823722",
                                    "_parent": "2_1587506823722",
                                    "_source": {
                                        "readstatus": "unread",
                                        "assignee": "2",
                                        "status": "New"
                                    }
                                }
                        ]
                    }
                }
            },
            "assigneeuser_id": "",
            "assigneeuser_name":data.firstname,
            "assigneeuser_email": "",
            "assigneeuser_designation": "",
            "assigneeuser_mob": "",
            "assigneeuser_img_name": "",
            "createuser_name": data.firstname,
            "createuser_email": "",
            "createuser_designation": "",
            "createuser_mob": "",
            "createuser_img_name": "",
            "unreadmsg": 0
        }

        if(tasktype === 'draft') {
            /*if(tasktype === 'schedule') {
                dispatch({
                    type: "SCHEDULE_SAVED",
                    scheduleSaved: true
                });
            }*/

        }
        else {
            if(data.savemode==='create') {

                let savemode= (data.selftask && tasktype !== 'schedule')?'to-me':'by-me'


                if(selftask && tasktype !== 'schedule') {
                    dispatch({
                        type: "UPDATE_TO_DO_DATA",
                        results: results
                    });
                    dispatch({
                        type: 'SIDEBAR_TO_DO_OUNT_UPDATE',
                        results : 1
                    });
                }
                else{
                    if(tasktype === 'schedule') {
                        dispatch({
                            type: "UPDATE_SCHEDULED_DATA",
                            results: results
                        });
                        dispatch({
                            type: 'SIDEBAR_TO_SCHEDULE_COUNT_UPDATE',
                            results: 1
                        })
                    }
                    else {
                        dispatch({
                            type: "UPDATE_FOLLOW_UP_DATA",
                            results: results
                        });
                        dispatch({
                            type: 'SIDEBAR_TO_FOLLOW_UP_COUNT_UPDATE',
                            results: 1
                        })
                    }

                }


                dispatch({
                    type: 'ADD_TODO_HISTORY_PUSH',
                    results: taskData,
                    secId : secId,
                    savemode:savemode,

                })
            }
            else if(data.savemode === 'update'){
                dispatch({
                    type: "UPDATE_TASK_STATUS"
                })
                dispatch({
                    type: 'ADD_TODO_HISTORY_PUSH',
                    results: taskData,
                    secId : secId,
                    savemode:"update"
                })

                dispatch({
                    type: "EDIT_TASK_CONTENT",
                    results: taskData
                })

            }
            else{
                var grandparent = data.grandmaster;
                var res = grandparent.split("_");
                if(data.savemode==='reassign' && res[0] !== getProfile().id) {

                    // postEventStream(taskData, todoCreated.data.taskdata[0].inner_hits.childtodo.hits.hits.map((list) => list._source.assignee))
                    dispatch({
                        type: 'SIDEBAR_TO_FOLLOW_UP_COUNT_UPDATE',
                        results: 1
                    })

                    dispatch({
                        type: 'RE_ASSIGN_TASK',
                        results: taskData,
                        savemode:"reassign"
                    })


                }
                else{

                    //    postEventStream(taskData, todoCreated.data.taskdata[0].inner_hits.childtodo.hits.hits.map((list) => list._source.assignee))
                    dispatch({
                        type: TASKACTIONS.UPDATE + TASKACTIONS.FOLLOWUP + TASKACTIONS.DATA,
                        results: taskData
                    })
                    dispatch({
                        type: 'SIDEBAR_TO_FOLLOW_UP_COUNT_UPDATE',
                        results: 1
                    })

                    dispatch({
                        type: 'ADD_TODO_HISTORY_PUSH',
                        results: taskData,
                        savemode:"reassign"
                    })

                }


            }

            if(tasktype !== 'schedule') {
                let todoAsyncCreated = task.addTodoAsync(params);
            }

        }





    })



};

export const saveToDoAttachments = (data) => dispatch => {
    let attachmentAdded = task.saveToDoAttachments(data);
    attachmentAdded.then(attachmentAdded => {
        dispatch({
            type: 'ADD_ATTACHMENT_MAIN_TASK',
            attachmentAdded: attachmentAdded
        })
    })



};

export const overDuePopup = (status) => dispatch => {
        dispatch({
            type: 'OVER_DUE_POPUP',
            overduepopup: status
        })



};



export const createtask = (to,sub,description,userid) => dispatch => {
    let createtask = task.createTask(to,sub,description,userid);
    createtask.then(createtask => {
        dispatch({
            type: 'CREATE_TASK',
            createtask: createtask,

        })
    })



};


export const gettaskdetailsAsync = (params) => dispatch => {

    dispatch({
        type: 'TEMP_DATA_ASYNC_CALL',
        taskasynccall:true,

    })

    let taskdetails = task.taskDetailsList(params);
    taskdetails.then(taskdetails => {
        dispatch({
            type: 'GET_TASK_DETAILS_ASYNC_CALL',
            taskdetails:taskdetails,

        })
        dispatch({
            type: 'TEMP_DATA_ASYNC_CALL',
            taskasynccall:false,

        })



    })
};

export const taskdetails = (params) => dispatch => {
    dispatch({
        type: params.mode.toUpperCase(),
        masterid:params._id,

    });

    if(params.hasOwnProperty("asyncLoad")) {
        dispatch({
            type: 'TASKDETAILS_LOADER_ASYNC',
            asyncLoad:true,

        });
     }
     else   if(params.hasOwnProperty("timeLoad")) {
        dispatch({
            type: 'TASKDETAILS_TIME_UPDATE',
            timeLoad:true,

        });
        dispatch({
            type: 'TASKDETAILS_LOADER',
            loading:true,

        });
    }
    else{
        dispatch({
            type: 'RESET_TASK_DETAILS_DATA',
        });
        dispatch({
            type: 'TASKDETAILS_LOADER',
            loading:true,

        });

    }


    let taskdetails = task.taskDetailsList(params);

    var data = {status : "no"};
    dispatch({
        type: 'SET_TASK_SELECTION',
        data

    });

    taskdetails.then(taskdetails => {
        dispatch({
            type: 'GET_TASK_DETAILS',
            taskdetails:taskdetails,

        })

        dispatch({
            type: 'TASKDETAILS_LOADER',
            loading:false,

        })
        dispatch({
            type: 'TASKDETAILS_LOADER_ASYNC',
            asyncLoad:false,

        });
        dispatch({
            type: 'TASKDETAILS_TIME_UPDATE',
            timeLoad:false,

        });
    })
        .catch(error => {
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: 'TASKDETAILS_LOADER',
                loading:false,

            })
        });



};

export const resetTaskProps = () => dispatch => {
    dispatch({
        type: 'RESET_TASK_DETAILS_DATA',
    });
}
export const moretaskdetails = (params) => dispatch => {
    let moretaskdetails = task.moretaskDetailsList(params);
        dispatch({
            type: 'MORE_TASK_DETAILS_LOADER',
            loader:true

        })

    moretaskdetails.then(moretaskdetails => {
            dispatch({
            type: 'MORE_TASK_DETAILS',
            moretaskdetails:moretaskdetails,
            loader:false

        })
        dispatch({
            type: 'MORE_TASK_DETAILS_LOADER',
            loader:false

        })
    })
};
export const markasdone = (data) => dispatch => {
    let markasdone = task.MarkAsDone(data);
    dispatch({
        type: 'TEMP_DATA_ASYNC_CALL',
        taskasynccall:true,

    })

    markasdone.then(markasdone => {

        dispatch({
            type: 'MARK_AS_DONE',
            results:markasdone
        })

        dispatch({
            type: "UPDATE_MARK_AS_DONE_SIDEBAR_MENU_COUNT"
        })
        dispatch({
            type: "UPDATE_MARK_AS_DONE_COUNT",
            results:markasdone
        })



    })
};

export const modifyTaskTodefault = () => dispatch => {


        dispatch({
            type: 'SET_TO_DEFAULT',

        })



};


export const verify = (params) => dispatch => {
    let verify = task.MarkAsDone(params);
    dispatch({
        type: 'TASKDETAILS_LOADER',
        loading:true,

    });
    dispatch({
        type: 'TEMP_DATA_ASYNC_CALL',
        taskasynccall:true,

    })
    verify.then(verify => {
        dispatch({
            type: 'VERIFY_TASK',
            results:verify
        })

        ////////////console.log(verify);
        var taskData = verify.data.results.data[0]

        var creator =[]
        creator[0]=verify.data.results.data[0].assigneeuser_id;
        ////////////console.log(verify.data.results.data[0].inner_hits.childtodo.hits.hits.map((list)=>list._source.assignee));
      //  postEventStream(taskData,(verify.data.results.data[0].inner_hits.childtodo.hits.hits.map((list)=>list._source.assignee)))

        dispatch({
            type: 'TASKDETAILS_LOADER',
            loading:false,

        });





    })
};

export const reopenTask = (params) => dispatch => {

    dispatch({
        type: 'TASKDETAILS_LOADER',
        loading:true,

    });
    let reopened = task.reopenTask(params);

    reopened.then(reopened => {
        dispatch({
            type: 'REOPEN_TASK',
            results:reopened
        })
        ////////////console.log(reopened);
        var taskData = reopened.data.results.data[0]
        // taskData["createuser_name"]=getProfile().first_name
        // taskData["inner_hits"]={"childtodo":{"hits":{"hits":[{"_source": {
        //                     "readstatus": "unread",
        //                     "assignee": "",
        //                     "status": "New"
        //                 }}]}
        //     }
        // }
        // taskData["assigneeuser_name"]="test"


            //  var creator =[]
       // creator[0]=reopened.results.data.data[0].assigneeuser_id;
      //  ////////////console.log(reopened.results.data.data[0].inner_hits.childtodo.hits.hits.map((list)=>list._source.assignee));
        //postEventStream(taskData,(reopened.data.data[0].inner_hits.childtodo.hits.hits.map((list)=>list._source.assignee)))
        dispatch({
            type: 'TASKDETAILS_LOADER',
            loading:false,

        });



    })
};

export const reopenAllTask = (params) => dispatch => {
    let reopened = task.reopenAllTask(params);
    dispatch({
        type: 'TASKDETAILS_LOADER',
        loading:true,

    });

    reopened.then(reopened => {
        dispatch({
            type: 'REOPEN_TASK',
            results:reopened

        })

        var taskData = reopened.data.results.data[0]
        var creator =[]
        creator[0]=reopened.data.results.data[0].assigneeuser_id;
        ////////////console.log(reopened.data.results.data[0].inner_hits.childtodo.hits.hits.map((list)=>list._source.assignee));
        //postEventStream(taskData,(reopened.data.results.data[0].inner_hits.childtodo.hits.hits.map((list)=>list._source.assignee)))

        dispatch({
            type: 'TASKDETAILS_LOADER',
            loading:false,

        });

    })



};


export const addtimelog = (params) => dispatch => {
    dispatch({
        type: 'MULTI_LOADER_UPDATE_STATUS',
        loaderType: 'time-log-data',
        status: true

    });
    let addlog = task.AddTimeLog(params);
    addlog.then(addlog => {
        dispatch({
            type: 'ADD_TIME_LOG',
            addlog:addlog,

        })
        setTimeout(function () {
        dispatch({
            type: 'MULTI_LOADER_UPDATE_STATUS',
            loaderType: 'time-log-data',
            status: false

        });
        }, 1000)

    })
};
export const gettimelog = (params) => dispatch => {

    dispatch({
        type: 'MULTI_LOADER_UPDATE_STATUS',
        loaderType: 'time-log-data',
        status: true

    });
    let timelog = task.getTimeLog(params);

    timelog.then(timelog => {
        dispatch({
            type: 'GET_TIME_LOG',
            timelog:timelog,

        })
        setTimeout(function () {
        dispatch({
            type: 'MULTI_LOADER_UPDATE_STATUS',
            loaderType: 'time-log-data',
            status: false

        });
        }, 1000)
    })
};
export const getremarks = (uid,taskid,reference) => dispatch => {
    let remarks = task.getRemarks(uid,taskid,reference);

    remarks.then(remarks => {
        dispatch({
            type: 'SAVE_REMARKS',
            remarks:remarks,

        })
    })
};
export const savediscussion = (params) => dispatch => {
    let discussion = task.saveDiscussion(params);

    discussion.then(discussion => {
        dispatch({
            type: 'SAVE_DISCUSSION',
            discussion:discussion,

        })
    })
};


export const setFilter = (data) => dispatch => {
    dispatch({
        type: 'SET_TASK_FILTER',
        data

    })
};
export const setTaskSelection = (data) => dispatch => {
    dispatch({
        type: 'SET_TASK_SELECTION',
        data

    })
};


export const selectTasksToMove = (id)=> dispatch => {
    dispatch({
        type: 'SET_TASK_TO_MOVE',
        id : id

    });
}

export const getSelectedTasks = () => dispatch => {
    dispatch({
        type: 'TASK_SELECTED'

    })
};


export const moveTasksToArchive = (data) => dispatch => {
    let moved = task.archiveTasks(data);
    moved.then(moved => {
        dispatch({
            type: 'MOVE_TASKS_TO_ARCHIVE',
            data

        })
    });
};

export const deleteTaskPermanent = (data) => dispatch => {
    let deleted = task.deleteTasks(data);
    deleted.then(deleted => {
        dispatch({
            type: 'DELETE_TASK_PERMANENT',
            data

        })
    });
};

export const getPoolMenu = (uid) => dispatch => {
    let poolMenu = task.getPoolMenu(uid);

    poolMenu.then(poolMenu => {
        dispatch({
            type: 'GET_POOL_MENU',
            poolMenu : poolMenu.data

        })
    })
        .catch(error => {
            dispatch({
                type: 'GET_POOL_MENU',
                poolMenu : []

            })
        });
};

export const searchPoolTasks = (data) => dispatch => {
    let PoolTasks = task.searchPoolTasks(data);

    PoolTasks.then(PoolTasks => {
        dispatch({
            type: 'GET_POOL_TASKS',
            poolTasks : PoolTasks.data.data

        })
    })
};

export const takePoolTask = (data) => dispatch => {
    dispatch({
            type: 'POOL_TASKS_LOADING',
            loading: true
        })
    let taken = task.takePoolTask(data);
    taken.then( taken => {
        dispatch({
            type: 'TAKE_POOL_TASK',
            poolResponse: taken
        })
        dispatch({
            type: 'POOL_TASKS_LOADING',
            loading: false
        })
    });

};
export const userFilter = (type,userid) => dispatch => {
    let userdata = task.userFilter(type,userid);
    userdata.then( userdata => {
        dispatch({
            type: 'USER_FILTER_DATA',
            userdata: userdata
        })
    });

};





export const quitPoolTask = (data) => dispatch => {
    let taken = task.quitPoolTask(data);
    taken.then( taken => {
        dispatch({
            type: 'QUIT_POOL_TASK'
        })
    });

};
export const quitTodoPoolTask = (data) => dispatch => {
    let quit = task.quitTodoPoolTask(data);
    quit.then( quit => {
        dispatch({
            type: 'QUIT_TODO_POOL_TASK',
            quit
        })
    });

};
export const completePoolTask = (data) => dispatch => {
    let completed = task.completePoolTask(data);
    /*completed.then( completed => {
        dispatch({
            type: 'COMPLETE_POOL_TASK',
            completed
        })
    });*/

};
export const handleClap = (data) => dispatch => {
    let handleClap = task.handleClap(data);


};




export const closedByCreator = (data) => dispatch => {
    dispatch({
        type: 'TASKDETAILS_LOADER',
        loading:true

    });
    let closedByCreator = task.closedByCreator(data);
    closedByCreator.then( closedByCreator => {
        dispatch({
            type: 'FORCE_CLOSE_BY_CREATOR',
            closed:closedByCreator
        })

    });

};

export const updateTaskStatus = (data) => dispatch => {
    let updateTask = task.updateTaskStatus(data);
    updateTask.then( updateTask => {
        dispatch({
            type: 'UPDATE_TASK_STATUS',
            updateTask
        })
    });

};
export const getProjectData = (data) => dispatch => {
    let projectResult = task.getUserData(data);
    projectResult.then( projectResult => {

        let projectList = projectResult.data.results.data;

        dispatch({
            type: 'GET_USER_PROJECT_DATA',
            projectData:projectList,
            search :  data.search
        });
    });

};
export const getTagData = (data) => dispatch => {
    let tagResult = task.getTags(data);
    tagResult.then( tagResult => {
        let tagList = tagResult.data.results.data;

        dispatch({
            type: 'GET_USER_TAG_DATA',
            tagData: tagList,
            search : data.search
        });
    });

};

export const selectAllTasks = (checked) => dispatch => {

    dispatch({
        type: 'SELECT_ALL_TASKS',
        checked : checked
    });

};

export const updateCreateEventStram = (results) => dispatch => {

    var     data= [{
            "task_creator": results.createuser_name,
            "creator_img": results.createuser_img_name,
            "_id": "",
            "typeid": results.masterid,
            "userid": results.assigneeuser_id ,
            "taskcreator": results.createuser,
            "message": "task added",
            "status": "unread",
            "type": "todo",
            "messageid": 0,
            "createdate":results.createdate,
            "taskinfo": {
                "title": (results.Title),
                "subject": (results.subject),
                "duedate": results.duedate,
                "priority": results.priority,
                "Token": ""
            },
            "messageinfo": [],
            "user": results.assigneeuser_name
        }]
    dispatch({
        type: "UPDATE_TO_DO_DATA",
        results : results
    });

    dispatch({
        type: 'SIDEBAR_TO_DO_OUNT_UPDATE',
        results : 1
    });
    dispatch({
        type: 'NOTIFICATION_INFO_UPDATED',
        results : data
    });

    let userdata = {
        uid: getProfile().id
    }
    let notificationInfo = users.notificationInfo(userdata);
    notificationInfo.then(notificationInfo => {
        dispatch({
            type: 'NOTIFICATION_INFO',
            notificationInfo: notificationInfo,

        })
    })



};

export const updateMarkAsDoneEventStram = (results) => dispatch => {

    dispatch({
        type: 'LIST_MARKASDONE_DATA_EVENT_STREAM_UPDATE',
        results : results
    });
    dispatch({
        type: 'SIDEBAR_TOVERIFY_COUNT_EVENT_STREAM_UPDATE',
        results : 1
    });
    dispatch({
        type: 'UPDATE_TASK_DETAILS_WITH_EVENT_STREAM',
        results : results
    });

    var  data= [{
        "task_creator": results.createuser_name,
        "creator_img": results.createuser_img_name,
        "_id": "",
        "typeid": results.masterid,
        "userid": results.assigneeuser_id ,
        "taskcreator": results.createuser,
        "message": "mark as done",
        "status": "unread",
        "type": "todo",
        "messageid": 0,
        "createdate":results.createdate,
        "taskinfo": {
            "title": (results.Title),
            "subject": (results.subject),
            "duedate": results.duedate,
            "priority": results.priority,
            "Token": ""
        },
        "messageinfo": [],
        "user": results.assigneeuser_name
    }]
    dispatch({
        type: 'NOTIFICATION_INFO_UPDATED',
        results : data
    });

    let userdata = {
        uid: getProfile().id
    }
    let notificationInfo = users.notificationInfo(userdata);
    notificationInfo.then(notificationInfo => {
        dispatch({
            type: 'NOTIFICATION_INFO',
            notificationInfo: notificationInfo,

        })
    })


};


export const updateReOpenEvntStream = (results) => dispatch => {

    dispatch({
        type: 'LIST_REOPEN_DATA_EVENT_STREAM_UPDATE',
        results : results
    });
    dispatch({
        type: 'SIDEBAR_REOPEN_COUNT_UPDATE',
        results : 1
    });
    var     data= [{
        "task_creator": results.createuser_name,
        "creator_img": results.createuser_img_name,
        "_id": "",
        "typeid": results.masterid,
        "userid": results.assigneeuser_id ,
        "taskcreator": results.createuser,
        "message": "verified the task",
        "status": "unread",
        "type": "todo",
        "messageid": 0,
        "createdate":results.createdate,
        "taskinfo": {
            "title": (results.Title),
            "subject": (results.subject),
            "duedate": results.duedate,
            "priority": results.priority,
            "Token": ""
        },
        "messageinfo": [],
        "user": results.assigneeuser_name
    }]
    dispatch({
        type: 'NOTIFICATION_INFO_UPDATED',
        results : data
    });

    let userdata = {
        uid: getProfile().id
    }
    let notificationInfo = users.notificationInfo(userdata);
    notificationInfo.then(notificationInfo => {
        dispatch({
            type: 'NOTIFICATION_INFO',
            notificationInfo: notificationInfo,

        })
    })

};

export const updateVerifyEventstram = (results) => dispatch => {

    // dispatch({
    //     type: 'VERIFY_EVENT_UPDATE_STREAM',
    //     results : results
    // });
    // dispatch({
    //     type: 'SIDEBAR_VERIFY_COUNT_EVENT_STRAM_UPDATE',
    //     results : 1
    // });
    var     data= [{
        "task_creator": results.createuser_name,
        "creator_img": results.createuser_img_name,
        "_id": "",
        "typeid": results.masterid,
        "userid": results.assigneeuser_id ,
        "taskcreator": results.createuser,
        "message": "verified the task",
        "status": "unread",
        "type": "todo",
        "messageid": 0,
        "createdate":results.createdate,
        "taskinfo": {
            "title": (results.Title),
            "subject": (results.subject),
            "duedate": results.duedate,
            "priority": results.priority,
            "Token": ""
        },
        "messageinfo": [],
        "user": results.assigneeuser_name
    }]
    dispatch({
        type: 'NOTIFICATION_INFO_UPDATED',
        results : data
    });
    let userdata = {
        uid: getProfile().id
    }
    let notificationInfo = users.notificationInfo(userdata);
    notificationInfo.then(notificationInfo => {
        dispatch({
            type: 'NOTIFICATION_INFO',
            notificationInfo: notificationInfo,

        })
    })


};




export const updateTaskDetailEventStram = (results) => dispatch => {
    dispatch({
        type: 'UPDATE_TASK_DETAIL_EVENT_STREAM_UPDATE',
        results : results
    });

    var     data= [{
        "task_creator": results.createuser_name,
        "creator_img": results.createuser_img_name,
        "_id": "",
        "typeid": results.masterid,
        "userid": results.assigneeuser_id ,
        "taskcreator": results.createuser,
        "message": "verified the task",
        "status": "unread",
        "type": "todo",
        "messageid": 0,
        "createdate":results.createdate,
        "taskinfo": {
            "title": (results.Title),
            "subject": (results.subject),
            "duedate": results.duedate,
            "priority": results.priority,
            "Token": ""
        },
        "messageinfo": [],
        "user": results.assigneeuser_name
    }]
    dispatch({
        type: 'NOTIFICATION_INFO_UPDATED',
        results : data
    });
    let userdata = {
        uid: getProfile().id
    }
    let notificationInfo = users.notificationInfo(userdata);
    notificationInfo.then(notificationInfo => {
        dispatch({
            type: 'NOTIFICATION_INFO',
            notificationInfo: notificationInfo,

        })
    })

};


function postEventStream(taskData,assigness){
    ////////////console.log(assigness)
    for(let i = 0; i < assigness.length; i++){
    const request = new XMLHttpRequest();
    const url = 'http://events.bsynapse.com:3000/hub';
    const DEMO_JWT = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6W10sInB1Ymxpc2giOlsiKiJdfX0.iTVjHoLv9bB-O5RNnTtzOFxIW-YECk2JXZeMekZ4GwA';
    request.open("POST", url);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.setRequestHeader('Authorization', 'Bearer ' + DEMO_JWT);

////////////console.log(assigness[i])
        request.send('topic=hedge/'+assigness[i]+'&data='+ JSON.stringify(taskData));

    }
}



export const postTaskCompletionasync = (params) => dispatch => {
    let postTaskCompletionasync = task.postTaskCompletionasync(params);
    postTaskCompletionasync.then( postTaskCompletionasync => {
        // dispatch({
        //     type: 'CLAEAR_MODIFY_TEMP_DATA',
        //
        // });
        // dispatch({
        //     type: 'TASK_COMPLETION_ASYNC',
        //     status : true
        // })
    });

};

export const tasksidebarCount = (params) => dispatch => {
    let tasksidebarCount = task.tasksidebarCount(params);
    tasksidebarCount.then( tasksidebarCount => {
        dispatch({
            type: 'TASK_SIDEBAR_COUNT_CHECKING',
            tasksidebarCount :tasksidebarCount
        })
    });

};

export const dateupdatetask = (params) => dispatch => {
    dispatch({
        type: 'TASKDETAILS_LOADER',
        loading:true

    });
    dispatch({
        type: 'CONTENT_LOADER',
        loading:true,

    })
    let dateupdatetask = task.dateupdatetask(params);
    dateupdatetask.then(updatetask => {
        dispatch({
            type: 'TASK_DATE_UPDATE',
            dateupdatetask: dateupdatetask,
            utcduedate : params.duedate,
            taskid : params.taskid

        });
        dispatch({
            type: 'TASKDETAILS_LOADER',
            loading:false

        });
        dispatch({
            type: 'CONTENT_LOADER',
            loading:false,

        })
    })
};

export const getprinttask = (params) => dispatch => {
    let getprinttask = task.getprinttask(params);
    getprinttask.then(printtask => {
        dispatch({
            type: 'PRINT_DATA',
            getprinttask: printtask,

        })
    })
};
export const getselectedCollection = (params) => dispatch => {
    dispatch({
        type: 'FORMPREVIEW_LOADER',
        loader:true

    })
    let getselectedCollection = task.getselectedCollection(params);
    getselectedCollection.then(selectedCollection => {
        dispatch({
            type: 'SELECTED_COLLECTION_DATA',
            getselectedCollection: selectedCollection,

        })
        dispatch({
            type: 'FORMPREVIEW_LOADER',
            loader:false

        })
    })
};
export const getEnterprise = (params) => dispatch => {
    dispatch({
        type: 'ENTERPRISE_LOADER',
        loader:true

    })
    let getEnterprise = task.getEnterprise(params);
    getEnterprise.then(Enterprise => {
        dispatch({
            type: 'ENTERPRISE',
            getEnterprise: Enterprise,
        })
        dispatch({
            type: 'ENTERPRISE_LOADER',
            loader:false

        })
    })

};
export const getattchments = (data) => dispatch => {
    let attachmentlist = task.getAttachment(data);
    attachmentlist.then( attachmentlist => {
        dispatch({
            type: 'GET_ALL_ATTACHMENT_DATA',
            listattachment :attachmentlist
        })
    })
        .catch(error => {
            dispatch({
                type: 'GET_ALL_ATTACHMENT_DATA',
                listattachment : []

            })
        });

};

export const gettodologs = (data) => dispatch => {
    let todologs = task.getToDoLogs(data);
    todologs.then( todologs => {
        dispatch({
            type: 'GET_ALL_TO_DO_LOGS',
            todologs:todologs

        })
    })
        .catch(error => {
            dispatch({
                type: 'GET_ALL_TO_DO_LOGS',
                todologs:[]

            })
        });



};
export const getassigneetimelog = (data) => dispatch => {
    let logpaneldetails = task.assigneetimelog(data);
    logpaneldetails.then( logpaneldetails => {
        dispatch({
            type: 'GET_ASSIGNEE_TIME_LOG',
            logpaneldetails:logpaneldetails

        })
    })
        .catch(error => {
            dispatch({
                type: "GET_ASSIGNEE_TIME_LOG",
                logpaneldetails:[],

            })

        });

    ;

};
export const savePoolMembers = (data) => dispatch => {
    let savePoolMembers = task.savePoolMembers(data);
    savePoolMembers.then( savePoolMembers => {
        dispatch({
            type: 'GET_POOL_MEMBERS',
            savePoolMembers:savePoolMembers

        })
    });

};
export const setSelectedItems = (name, value) => dispatch => {
    dispatch({
        type: 'SET_SELECTED_ITEMS',
        name : name,
        value : value
    })
}
export const resetSelectedItems = () => dispatch => {
    dispatch({
        type: 'RESET_SELECTED_ITEMS'

    })
}

export const getTaskHelper = (params) => dispatch => {
    let taskHelperData = task.getTaskHelper(params);

    taskHelperData.then( taskHelperData => {
        /************* User list ***************/
        let listUsers = taskHelperData.data.results.data.users;
        if(listUsers) {
            /*let userList = listUsers.user;
            let organizationList = listUsers.organization;
            let department = listUsers.department;
            let group = listUsers.group;

            userList = userList.map(item=> { item.uniqueId = item.userType.toLowerCase()+"_"+item.userId; return item;});
            organizationList = organizationList.map(item=> {return {firstName : item.name, lastName : '', designation : '', department : '', userType :'organization', userId :item.id, uniqueId : "organization_"+item.id  }});
            department = department.map(item=> {return {firstName : item.dep_name, lastName : '', designation : '', department : '', userType :'department', userId :item.id , uniqueId : "department_"+item.id }});
            group = group.map(item=> {return {firstName : item.group_name, lastName : '', designation : '', department : '', userType :'group', userId :item.group_id, uniqueId : "group_"+item.group_id }});
            let combinedList = [].concat.apply([], [userList,organizationList,department,group]);*/

            let combinedList = listUsers;

            if(params.projectid && params.projectid !== '') {
                dispatch({
                    type: 'LIST_PROJECT_TODO_USERS',
                    listUsers: combinedList,
                    search : params.search,
                });
            }
            else {
                dispatch({
                    type: 'LIST_TODO_USERS_DATA',
                    listUsers: combinedList,
                    search : params.search

                });
            }

        }
        /************* User list ***************/
        /************* Project list ***************/

        let projectList =  taskHelperData.data.results.data.projects;
        if(projectList) {
            dispatch({
                type: 'GET_USER_PROJECT_DATA',
                projectData: projectList
            });
        }
        /************* Project list ***************/

        /************* Tag list ***************/

        let tagList = taskHelperData.data.results.data.tags;

        if( tagList) {
            dispatch({
                type: 'GET_USER_TAG_DATA',
                tagData:tagList
            });
        }
        /************* Tag list ***************/

        /************* Collection  list ***************/

        let collectionsubgroup = taskHelperData.data.results.data.collectionsubgroup;

        if( collectionsubgroup) {
            dispatch({
                type: 'LIST_TODO_COLLECTIONS',
                collectionsubgroup: collectionsubgroup
            });
        }
        /************* Collection list ***************/

        /************* Resources  list ***************/

        let resources = taskHelperData.data.results.data.resources;

        if( resources) {
            dispatch({
                type: 'LIST_TODO_RESOURCES',
                resources: resources,
                searchParam : ''
            });
        }
        /************* Resources list ***************/

    });

};

export const postTaskCompletionExternal = (params) => dispatch => {
    let postTaskCompletionExternal = task.taskcompletionexternal(params);
    postTaskCompletionExternal.then( postTaskCompletionExternal => {
        dispatch({
            type: 'EXTERNAL_TASK_COMPLETION',

        })
    });

};

export const isTaskOpen = (params) => dispatch => {
    let taskStatus = task.isTaskOpen(params);
    taskStatus.then( taskStatus => {
        dispatch({
            type: 'IS_TASK_OPEN',
            taskStatus : taskStatus
        })
    });
}
export const resetPoolTaskStatus = () => dispatch => {
    dispatch({
        type: 'RESET_POOLTASK_STATUS'
    })
}

export const tasksearch = (params) => dispatch => {
    dispatch({
        type: 'TASK_DATA_SEARCH_LIST_LOADER',
        loader:true
    })
    let tasksearch = task.tasksearch(params);
    tasksearch.then( tasksearch => {
        dispatch({
            type: 'TASK_DATA_SEARCH_LIST',
            tasksearch : tasksearch,
            srchval:params.srchval
        })

        dispatch({
            type: 'TASK_DATA_SEARCH_LIST_LOADER',
            loader:false
        })
    });
}

export const loaderTask = (params) => dispatch => {
    dispatch({
        type: 'TASK_DATA_SEARCH_LIST_LOADER',
        loader:true
    })
    let loaderTask = task.loaderTask(params);
    loaderTask.then( loaderTask => {
        dispatch({
            type: 'TASK_DATA_SEARCH_LIST_LOAD_MORE',
            loaderTask : loaderTask,
            srchval:params.srchval,
            mode:params.mode
        })
        dispatch({
            type: 'TASK_DATA_SEARCH_LIST_LOADER',
            loader:false
        })
    });
}
export const listProjectUsers = (params) => dispatch => {
    dispatch({
        type: 'USER_LOADING',
        loading : 1
    });
    let listUsers = task.listProjectUsers(params);
    listUsers.then(listUsers => {

        let userList = listUsers.data.results.data.users;

        dispatch({
            type: 'LIST_PROJECT_TODO_USERS',
            listUsers: userList,
            search : params.search,
        });

        dispatch({
            type: 'USER_LOADING',
            loading : 0
        });
    })

};

export const taskAlert = (params) => dispatch => {
    let taskAlert = task.taskAlert(params);
    taskAlert.then(taskAlert => {
        let taskalert = taskAlert.data.results

        dispatch({
            type: 'LIST_TASK_ALERT',
            taskalert: taskalert,
        });


    })

};


export const showAlert = (sta) => dispatch => {
    dispatch({
        type: 'TASK_ALERT_DISPLAY',
        alert: sta,
    });



};


export const readStatus = (masterid,from) => dispatch => {

    dispatch({
        type: 'READ_STATUS_UPDATE',
        masterid: atob(masterid),
        from:from
    });
};

export const uploadAttachments = (attachments, params) => dispatch => {
    dispatch({
        type: 'TASKDETAILS_LOADER',
        loading:true,

    });
    let uploadAttachments = task.uploadAttachments(attachments);
    uploadAttachments.then(uploadAttachments => {
        let getAttachmentData = task.getAttachment(params);
            getAttachmentData.then(getAttachmentData => {
            dispatch({
                type: 'UPLOAD_ATTACHMENT_DATA_FILE',
                savediscussion: getAttachmentData,

            })
            dispatch({
                type: 'TASKDETAILS_LOADER',
                loading:false,

            });
        })
    })

}


export const saveReleatedtask = (params) => dispatch => {
    dispatch({
        type: 'RELATED_TASK_LOADER',
        loader:true,

    });
    let saveReleatedtask = task.saveReleatedtask(params);
    saveReleatedtask.then(saveReleatedtask => {
        let listrelatedTask = task.listrelatedTask(params);
        setTimeout(function () {
            listrelatedTask.then(listRelatedTask => {
                dispatch({
                    type: 'LIST_RELATED_TASK',
                    data: listRelatedTask.data.results.data,
                    loader: false,
                })
            },1000)
        })
    })

}

export const getRelatedtask = (params) => dispatch => {
            dispatch({
                type: 'RELATED_TASK_LOADER',
                loader:true,

            });
        let listrelatedTask = task.listrelatedTask(params);
        listrelatedTask.then(listRelatedTask => {
            dispatch({
                type: 'LIST_RELATED_TASK',
                data: listRelatedTask.data.results.data,
                loader:false,
            })

        })


}
export const getmyTask = (params) => dispatch => {
            dispatch({
                type: 'MY_TASK_LOADER',
                loader:true,

            });
        let listMyTask = task.myTask(params);
    listMyTask.then(listMyTask => {
            dispatch({
                type: 'LIST_MY_TASK',
                data: listMyTask.data.results.data,
                loader:false,
            })

        })


}
export const getFollowupTasks = (params) => dispatch => {
    dispatch({
        type: 'FOLLOWUP_TASK_LOADER',
        loader:true,
    })
    let followupTaskData = task.leadTasks(params);
    followupTaskData.then(followupTaskData => {
        console.log(followupTaskData)
        dispatch({
            type: 'FOLLOWUP_TASK_LIST',
            followupTaskList: followupTaskData.data.results.data,
            loader:false,
        })

    })


}

export  const updateWhContent = (params) => dispatch => {
    dispatch({
        type: 'REPLY_RESET',
        replied : '',
        loader:true,
    })
    let replied = task.replyToWhatsapp(params);
    replied.then(replied => {
        console.log(replied)
        dispatch({
            type: 'REPLY_ADDED',
            replied: replied.data.results,
            loader:false,
        })

    })

}

export const saveChatmessage=(params,from) => dispatch => {

if(from === 'singlemainchat') {
    var save = task.saveChatTask(params);
}
else{
    var save = task.saveChatTaskExternal(params);


}
    save.then(save => {
        console.log(save)
        dispatch({
            type: 'SAVE_TASK_SAVE_CHAT',
            replied: save.data.results,
            loader:false,
        })

    })

}
