import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import {ES_INDEX} from "../../../constants";
import {getProfile} from "../../../utilities/AuthService";
import LoaderSvg from "../../views/LoaderSVG";
import * as CeshareviewActions from "../../../actions/CeShareView";
class ImportExcel extends Component {
        constructor(props) {
            super(props);
            this.state={
                excelimportstep: 1,
                selectedFile:null,
                excelImportMsg:'',
                excelImportfalse:''
            }
        }

    componentDidUpdate(prevProps, prevState, snapshot) {
            if(this.props.excelImportStatus !== prevProps.excelImportStatus){
                let that=this;
                let excelStatusData = this.props.excelImportStatus.data && this.props.excelImportStatus.data.data ? this.props.excelImportStatus.data.data : '';
                if(this.props.excelImportStatus.data.status === 200){
                    that.setState({excelImportMsg:'',selectedFile:[],Stat:'',upload:false})
                    that.props.modalOpen()
                }
              else {
                    that.setState({Stat:true,excelImportfalse:this.props.excelImportStatus.data.response.data.error.message})
                }
              this.props.excelImportStatusData(excelStatusData);

            }
    }
    fileSelect = event => {
            if(event.target.files[0].name.split('.').pop() === 'xlsx'){
                this.setState({selectedFile: event.target.files[0],excelImportMsg:true,Stat:false,excelImportfalse:''})

            }
            else{
                this.setState({excelImportfalse:'Please select valid xlsx file.',Stat:true})
            }
        //console.log(event.target.files[0])
    }
    fileUpload = () => {
        const fd = new FormData();
        let projectId = getUrlSegment(5) ? (atob(getUrlSegment(5))): '';
        let collectionid=''
        if (getUrlSegment(1) === 'project') {
            collectionid = (atob(getUrlSegment(4)));
        }
        else {
            collectionid = (atob(getUrlSegment(3)));
        }

        fd.append('excel', this.state.selectedFile);
        fd.append('collectionid', collectionid);
        fd.append('index', ES_INDEX);
        fd.append('uid', getProfile().id);
        if(getUrlSegment(1) === 'project') {
            fd.append('projectid',projectId);
        }
        // axios.post('https://apir4dev.bsynapse.com/export/import', fd
        //
        // ).then(res=>
        //     {
        //         //console.log(res);
        //     }
        // );
        this.props.importExcelData(fd)
        this.setState({Stat:false,upload:true,excelImportMsg:"",excelImportfalse:"Excel Import Is Under Process."})
        // let that=this
        // setTimeout(function()
        // {
        //     that.setState({upload:false})
        //
        // }, 1000);
        // setTimeout(function()
        // {
        //     that.setState({excelImportMsg:'',selectedFile:[],Stat:'',upload:false})
        //     that.modalOpen()
        // }, 3000);
    }
    importStagesNew=(e, stages)=>{
        let excelimportstep = this.state.excelimportstep;
        if(stages === 'next'){
            excelimportstep = excelimportstep+1;
        }
        else{
            excelimportstep = excelimportstep-1;
        }
        this.setState({excelimportstep : excelimportstep})
    }

    modalOpen=()=>{
        this.setState({action:'',csvImportMsg:"", excelImportMsg:"", upload:false, assignUserSelect : false, datalevelSelect : false, importstep:1, csvImportfalse:'', excelImportfalse:'', excelimportstep:1});
    }
    render() {
        return (
            <React.Fragment>
                {this.props.loader.loaderType === 'import-excel' && this.props.loader.status === true &&
                <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                }
                <div className="task-content-block dynamic-creation">
                    <div className="persitant-top">
                        {/* step1 */}
                        {this.state.excelimportstep === 1 &&
                        <div className="instruction-model">
                            <h5>Make sure the steps to Upload Excel:</h5>
                            <p>Before you proceed with importing the Excel, kindly make sure that the columns are in the right order.</p>
                            <p>If in doubt, please refer to the exported template and verify the Excel columns.</p>
                            <p>For the columns with options (dropdowns, check boxes and radio buttons), the values given in Excel must be the same as the ones given in the template or otherwise the right options will not get selected while importing.</p>
                            <p>This results in collection records with wrong values selected or with blank values.</p>
                        </div>
                        }
                        {/* step2 */}
                        {this.state.excelimportstep === 2 &&
                        <div className="drag-drop-area">
                            <div id={`progress-panel-container`} className={`progress-panel-holder`}>
                                <div id={`progress-holder`} className={'upload-pr-wrap'}></div>
                            </div>
                            <div className="drag-drop-container">
                                {/*<UploadFile*/}
                                {/*    AutoUpdate={true}*/}
                                {/*    getUploadedFile={this.fileSelect}*/}
                                {/*    multipleFiles={false}*/}
                                {/*    overLay={false}*/}
                                {/*    dropZone={false}*/}
                                {/*    wrapperClass=""*/}
                                {/*    progressBar={true}*/}
                                {/*    progressContainer={`progress-holder`}*/}
                                {/*    acceptedFiles={"\.xlsx"}*/}
                                {/*    ErrorMessage={this.messageErrorNew}*/}
                                {/*    fileDetails={`excel`}*/}
                                {/*/>*/}
                                <input type="file" accept=".xlsx"  onChange = {this.fileSelect} />
                                {/*<input type="file" name="csv" onChange={this.handleFileImport} />*/}
                                <label className="ico-lib upload-csv">Upload Excel</label>
                            </div>
                            <div className="attachment-task-group" id="todoFileAssets">
                                {this.state.selectedFile !== null &&
                                <div className="ind-attach-list file">
                                    <span className={`msg-csv-import`}>{this.state.selectedFile.name}</span>
                                </div>
                                }
                                {this.state.excelImportfalse !== '' &&
                                <p className={`infom-msg ${this.state.Stat?"error":this.state.upload?"":"sucess"}`}>{this.state.excelImportfalse}</p>
                                }
                            </div>
                        </div>
                        }
                    </div>
                    <div className="persitant-footer align-small">
                        <div className="group-row ">

                        </div>
                        <div className="more-button-panel">
                            {this.state.excelimportstep === 2 &&
                            <React.Fragment>
                                <button type="button" className="general-btn button-lg grey" id="createtask"
                                        onClick={(e) => this.importStagesNew(e, 'previous')}>Previous
                                </button>
                                {this.state.excelImportMsg !=="" &&
                                <button type="button" className="general-btn button-lg" id="createtask"
                                        onClick={this.fileUpload}>Submit
                                </button>
                                }
                            </React.Fragment>
                            }
                            {this.state.excelimportstep === 1 &&
                            <button type="button" className="general-btn button-lg" id="createtask" onClick={(e) => this.importStagesNew(e, 'next')}>
                                Next
                            </button>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    excelImportStatus: state.CeShareView.excelImportStatus,
});
export default  withRouter(connect(mapStateToProps, {
    importExcelData:CeshareviewActions.importExcelData,
})(ImportExcel));
