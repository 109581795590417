import React, {Component} from 'react';
import {connect} from "react-redux";
import * as projectActions from "../../../actions/Project"
import {withRouter} from 'react-router';
import * as commonActions from "../../../actions/Common";
import ProjectReducer from "../../../reducers/ProjectReducer";
import {Datecomparison, formatDate, getUrlSegment} from "../../../utilities/CustomFunctions";
import Modal from "../../views/Modal";
import AddNewProject from "./AddNewProject";
import {getProfile} from "../../../utilities/AuthService";
import * as usersActions from "../../../actions/Users";
import ProjectTile from "../../views/ProjectTile";
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import moment from 'moment'
import * as portfolioActions from "../../../actions/Portfolio";
import AlertModal from "../../views/AlertModal";
import NoData from "../../views/NoData";

class Projects extends Component {

    constructor(props) {
        super(props);
        this.state={
            Addopen:false,
            Editopen:false,
            Delopen : false,
            prjct_id : '0',
            current_url:'',
            projectCatg:false,
            loading: 0,
            offset: 30,
            lastOffset: 0,
            totalitems: 0,
            projectlist: [],
            current_prjcturl:'',
            search:'',
            clearList : true

        }
        this.showProgress = this.showProgress.bind(this);
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.getProjectList = this.getProjectList.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);
        this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
        this.DelmodalOpen = this.DelmodalOpen.bind(this);
        this.deleteproject = this.deleteproject.bind(this);
        this.listenToScroll=this.listenToScroll.bind(this);
    }



    componentDidMount() {
       // console.log(this.props.listAllProjectData.ListAllProjects.data.length);
       //  if(this.props.listAllProjectData.ListAllProjects.data.length === 0) {
       //      this.getProjectList();alert(1)
       //  }
       //  else {alert(2)
       //      this.setState({
       //          projectlist: this.props.listAllProjectData.ListAllProjects.data,
       //      });
       //  }

        this.getProjectList()

        var user_data = { uid : getProfile().id, usertype : getProfile().usertype , srchval : '' };
        this.props.users(user_data);
        this.props.mainClick('project','');

        const container = document.querySelector('.project-container');
        container.addEventListener('scroll', this.listenToScroll)

    }
    componentWillUnmount() {
        const container = document.querySelector('.project-container');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.project-container')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;

       // //console.log(scrollTop+"----"+scrollHeight+"----"+offsetHeight+"-----"+contentHeight);

        if (contentHeight <= scrollTop) {

            let lastOffset = this.state.lastOffset + 1;

            if( (lastOffset*this.state.offset) < this.state.totalitems) {

                this.setState({lastOffset : lastOffset,clearList:false},
                    function(){
                        this.getProjectList();
                    })
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        if(prevProps.SaveProjectDet.projectSaved !== this.props.SaveProjectDet.projectSaved ) {
            // let projectlist = [...this.state.projectlist];
            // let mode=this.props.SaveProjectDet.newProject.mode;
            // if(mode!=='update')
            // {
            //     projectlist.unshift(this.props.SaveProjectDet.newProject);
            // }
            // else {
            //     projectlist = projectlist.map((item) => { return ( parseInt(item.project_id) === parseInt(this.props.SaveProjectDet.newProject.project_id)) ? this.props.SaveProjectDet.newProject: item; });
            //     console.log(projectlist)
            // }
            this.setState({clearList : true, lastOffset : 0})
            var beta = this;
            setTimeout(function () {
                beta.getProjectList();
                beta.setState({loading: 0});
                beta.AddmodalClose();
            }, 1000)
        }

        if(prevProps.SaveProjectDet.projectDeleted !== this.props.SaveProjectDet.projectDeleted) {
            this.setState({clearList : true, lastOffset : 0})
            var beta = this;
            setTimeout(function () {
                beta.getProjectList();
                beta.setState({loading: 0});
            }, 1000)
        }
        if(prevProps.match.path !== this.props.match.path) {
            this.setState({clearList : true, lastOffset : 0},
                function(){
                    this.getProjectList();
                })
        }

        if ((prevProps.listAllProjectData.searchValue !== this.props.listAllProjectData.searchValue && (this.props.listAllProjectData.searchValue!== '')) || (prevProps.listAllProjectData.searchResult !== this.props.listAllProjectData.searchResult )) {
                this.setState({
                    totalitems: this.props.listAllProjectData.searchResult.total,
                    projectlist: this.props.listAllProjectData.searchResult.data,
                });
        }
        else if ((prevProps.listAllProjectData.searchValue !== this.props.listAllProjectData.searchValue && ( this.props.listAllProjectData.searchValue === '') ) || (prevProps.listAllProjectData.ListAllProjects !== this.props.listAllProjectData.ListAllProjects)) {
                this.setState({
                    totalitems: this.props.listAllProjectData.ListAllProjects.total,
                    projectlist: this.props.listAllProjectData.ListAllProjects.data,
                    loading: 0
                });

        }

    }

    getProjectList() {
        var current_url = getUrlSegment(2);
        let projectType = (current_url === 'my_projects' ? 'own' : ( current_url === 'shared_projects' ? 'shared' : ''));

        var projectParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: this.state.search,
            page : this.state.lastOffset + 1,
            per_page: this.state.offset,
            type : projectType,
            clearList : this.state.clearList
        }
        this.props.project(projectParams);

    }


    showProgress(workDone,totalCount){
        let workProgress = 0;
        if(totalCount > 0){
            let currentProgress = Math.round( ( workDone / totalCount) * 100);
            let remainProgress = currentProgress % 5;
            workProgress = currentProgress - remainProgress;
        }
        return workProgress;
    }


    AddmodalOpen(e, pid){
        e.stopPropagation();

        if(pid){
            this.setState({prjct_id : pid});
            let projectdata = {_id: pid}
            this.props.projectInfo(projectdata);
        }
        this.setState({Addopen : true});
    }

    AddmodalClose(){

        this.setState({Addopen : false , prjct_id :0});
    }

    DelmodalOpen(e, pid){
        e.stopPropagation();
        this.setState({
            Delopen : true ,
            projectdelid : pid
        });
    }
    deleteproject(){
        this.setState({loading : 1})
        let project_id = this.state.projectdelid;

        let deletedddata = {projectid : project_id};
        this.props.deleteProject(deletedddata);

        this.setState({Delopen : false});
        //this.props.history.push('/project');
    }

    cancelDeleteModal(){
        this.setState({Delopen : false});

    }

    render() {

        var currentDate = moment().utc().local();

        let Project=this.state.projectlist;//.filter(item => item.concat.toLowerCase().search('bsy') >= 0)
        let ProjectInfo=this.props.ProjectInfoDet.ProjectInfo;

        let current_url = getUrlSegment(2);
        let projectmenuData = this.props.listUsersPermission.UsersPermission.filter((item=> item.module === "Project"));
        let submoduleData = projectmenuData.length !==0  ? projectmenuData[0].submodule : [];

        let hasDashAccess = false;
        let DashData = submoduleData.filter(item => (item.module === "Dashboard" && item.module_permission === "yes"));
        if(DashData.length > 0) {
            hasDashAccess = true;
        }
        let hasCDashAccess = false;
        let cDashData = submoduleData.filter(item => (item.module === "Cdashboard" && item.module_permission === "yes"));
        if(cDashData.length > 0) {
            hasCDashAccess = true;
        }
        return (

            <div className="form-detail-container data-view field-form">
                {(this.props.listAllProjectData.loading || this.state.loading === 1) &&
                <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                }
                <div className="project-container">
                    {Project.length === 0 &&
                    <div className='absolute-holder'>
                        <NoData
                            msg={`No Projects added yet`}

                        />
                    </div>
                    }
                    {Project.map((list,index) => {
                        let description = atob(list.description);
                        var endDate = moment(list.enddate).utc().local();
                        return(
                            <ProjectTile
                                key={list.project_id}
                                list={list}
                                history={this.props.history}
                                endDate={endDate}
                                AddmodalOpen={this.AddmodalOpen}
                                DelmodalOpen={this.DelmodalOpen}
                                hasCDashAccess={hasCDashAccess}
                                hasDashAccess={hasDashAccess}
                            />
                        )
                        }
                    )}
                </div>
                <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="" data-original-title="New Project" onClick={(e)=>this.AddmodalOpen(e, 0)}>
                    <button className="float-button ico-lib float-add"></button>
                </div>
                {this.state.Addopen &&
                <Modal title={(this.state.prjct_id == '0' ? 'Create New Project' : 'Update Project')} Close ={this.AddmodalClose.bind(this)}>
                    <AddNewProject
                        AddmodalClose = {this.AddmodalClose}
                        listtags={this.props.listUsers.Users.data}
                        action={this.state.prjct_id == "0"? "create":"update"}
                    />

                </Modal>
                }
                {this.state.Delopen === true &&
                <AlertModal id="delete-project"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_action={() => this.deleteproject()}
                            showModal = {this.state.Delopen}
                            CANCEL_action={() => this.cancelDeleteModal()}
                />
                }
            </div>

        );
    }
}



const mapStateToProps = state => ({
    listAllProjectData:state.ProjectReducer.listallProjects,
    SaveProjectDet : state.ProjectReducer.SaveProject,
    ProjectInfoDet : state.ProjectReducer.ProjectInfo,
    listUsers:state.UsersReducer.listUsersdata,
    listUsersPermission:state.UsersReducer.listUsersPermissiondata,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    project:projectActions.listallprojects,
    projectInfo:projectActions.projectInfo,
    users:usersActions.listUsers,
    deleteProject:projectActions.DeleteProject
})(Projects));
