import React, {Component} from 'react';
import {connect} from "react-redux";
import * as taskActions from "../../actions/ToDo"
import {withRouter} from 'react-router';
import ListCardPool from "../views/ListCardPool"
import LoaderSvg from "../views/LoaderSVG"
import {getProfile} from '../../utilities/AuthService';
import * as projectActions from "../../actions/Project";
import NoData from "../views/NoData";
import {getUrlSegment} from "../../utilities/CustomFunctions";
import rootActions from "../../actions";
import AlertModalPool from "../views/AlertModalPool";

class PoolToDo extends Component {

    constructor(props) {
        super(props);
        this.modalOpen=this.modalOpen.bind(this)
        this.formSubmission=this.formSubmission.bind(this)
        this.gettaskList=this.gettaskList.bind(this);

        this.state={
            open:false,
            projectList:[],
            showTakeConfirm:false,
            showQuitConfirm : false,
            showTakenModal : false,
            assignee : ''
        }
        this.manageAction = this.manageAction.bind(this);
        this.showTakePoolModal=this.showTakePoolModal.bind(this)
        this.showQuitPoolModal=this.showQuitPoolModal.bind(this)
        this.showTakePoolModalclose = this.showTakePoolModalclose.bind(this);
        this.showQuitPoolModalclose = this.showQuitPoolModalclose.bind(this);
        this.showTakenModal = this.showTakenModal.bind(this);
    }

    componentDidMount() {
         var prjctdtlid = getUrlSegment(3);
        this.props.mainClick('task','/task/pool/' + prjctdtlid);
        this.gettaskList('All');
         /*var projctlist  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            page : 1,
            per_page: 1000

        }
        this.props.project(projctlist);
        this.props.listTags("TAGS",100)
        var menu = {userid : getProfile().id};
          this.props.getPoolMenu(menu);*/


    }
    componentWillUpdate(nextProps) {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.poolTasksData.poolTaskTaken !== this.props.poolTasksData.poolTaskTaken ) {
            this.showTakePoolModalclose();
            this.props.history.push("/task/details/"+btoa(this.props.poolTasksData.takenTaskId)+"/"+btoa('to-me'));
            //this.gettaskList('All');

        }
        if(prevProps.poolTasksData.poolTaskQuit !== this.props.poolTasksData.poolTaskQuit ) {
            this.showQuitPoolModalclose()
            this.gettaskList('All');

        }
        if(prevProps.menuClickData.subMenu !== this.props.menuClickData.subMenu ) {
            var prjctdtlid = getUrlSegment(3);
            this.props.mainClick('task','/task/pool/' + prjctdtlid);
            /*var data = {pool_id : getUrlSegment(3), uid : getProfile().id}
            this.props.searchpooltask(data);*/
            this.gettaskList('All');
        }
        if(this.props.poolTasksData.poolTaskAlreadyTaken !== prevProps.poolTasksData.poolTaskAlreadyTaken){
            //////console.log(this.props.poolTasksData.poolTaskAlreadyTaken)
            if(this.props.poolTasksData.poolTaskAlreadyTaken === 0) {
                this.showTakePoolModal(this.state.poolTaskId, this.state.poolId, this.state.workflowId, this.state.stageId);
            }
            else if(this.props.poolTasksData.poolTaskAlreadyTaken === 1) {
                this.setState({assignee :this.props.poolTasksData.poolTaskTakenBy })
                this.showTakenModal();
            }
        }


    }

    manageAction(e, action = '', poolTaskId, poolId, poolToDoId, workflowId, stageId) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({poolTaskId : poolTaskId, poolId : poolId, poolToDoId :poolToDoId, workflowId : workflowId , stageId : stageId}, function(){
            if(action === 'take'){
                this.props.isTaskOpen({poolTaskId : poolTaskId});
                //this.props.showTakePoolModal(poolTaskId, poolId, workflowId, stageId);
            }
            if(action === 'quit'){
                this.props.showQuitPoolModal(poolTaskId, poolId, poolToDoId, workflowId, stageId);
            }
        })

    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps !== this.props || nextState !== this.state) {
            return true
        }
        return false;
    }

    modalOpen(){
        this.setState({open:true,projectList:this.props.listAllProjectData.ListAllProjects.data})
    }
    formSubmission(to,sub,description,userid){
        this.setState({open:false})
        this.props.createTask(to,sub,description,userid)
        ////////////console.log(this.state.projectList)

    }
    gettaskList(status){

        var data = {pool_id : getUrlSegment(3), uid : getProfile().id}
        this.props.searchpooltask(data);

        var data = {category : 'to-me', status : status , uid : getProfile().id, bydue : '',  priority : this.props.taskFilters.taskPriorityFilter, project :this.props.taskFilters.taskProjectFilter, asigntype : 'All', managetask : 'mytask', offset  : 50, systype : 'root',  actionType : 'LIST_TODO_DATA' }
        this.props.todo(data);
    }

    selectTasks(id) {
        this.props.selectTasksToMove(id);
    }

    showTakePoolModal(poolTaskId, poolId, workflowId, stageId) {
        this.setState({showTakeConfirm : true, poolTaskId : poolTaskId, poolId : poolId, workflowId : workflowId,stageId:stageId });
    }
    showTakePoolModalclose() {
        this.setState({showTakeConfirm : false});
    }

    showQuitPoolModal(poolTaskId, poolId, poolToDoId, workflowId, stageId) {
        this.setState({showQuitConfirm : true, poolTaskId : poolTaskId, poolId : poolId, poolToDoId: poolToDoId, workflowId : workflowId,stageId:stageId });
    }
    showQuitPoolModalclose() {
        this.setState({showQuitConfirm : false});
    }
    showTakenModal() {
        this.setState({showTakenModal : true})
    }
    hideTakenModal() {
        this.setState({showTakenModal : false})
    }

    render() {
        //////////console.log(this.props.poolTasksData.poolTaskList)
        let poolTasks = this.props.poolTasksData.poolTaskList;
        return (

            <>

                <div className='filter-all-wrapper'>

                </div>
                <div className={this.props.todoListing.loading ? 'main-panel-holder with-loader has-filter' : 'main-panel-holder has-filter'}>
                            {(this.props.todoListing.loading ||  this.props.poolTasksData.loading)  &&
                            <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                            }

                            {poolTasks.length === 0 &&
                                <div className='absolute-holder'>
                                <NoData

                                    msg={`No task assigned to you`}

                                />
                                </div>
                            }

                            {poolTasks.map((list,index) =>
                                    <ListCardPool
                                        key={'todo'+index}
                                         index={index}
                                         list = {list}
                                         itemId = {list['_id']}
                                         history={this.props.history}
                                        gettaskList={this.gettaskList}
                                        showTakeConfirm={this.state.showTakeConfirm}
                                        showQuitConfirm={this.state.showQuitConfirm}
                                        showTakePoolModal={this.showTakePoolModal}
                                        showQuitPoolModal={this.showQuitPoolModal}
                                        showTakePoolModalclose={this.showTakePoolModalclose}
                                        showQuitPoolModalclose={this.showQuitPoolModalclose}
                                        poolId = {this.state.poolId}
                                        poolTaskId = {this.state.poolTaskId}
                                        poolToDoId = {this.state.poolToDoId}
                                        workflowId = {this.state.workflowId}
                                        stageId = {this.state.stageId}
                                        manageAction = {this.manageAction}
                                    />
                            )}

                        </div>
                {this.state.showTakenModal &&

                <AlertModalPool id="taken-pool"
                                title={"The task is already taken by "+ this.state.assignee}
                                OK_button_text="OK"
                                OK_action={() => this.hideTakenModal()}
                                showModal={this.state.showTakenModal}
                                CANCEL_button = {"hide"}

                />
                }

            </>



        );
    }
}



const mapStateToProps = state => ({
    listAllProjectData:state.ProjectReducer.listallProjects,
    todoListing:state.TaskReducer.listtoDo,
    taskFilters:state.TaskReducer.filterTasks,
    taskSelect:state.TaskReducer.getTaskSelection,
    menuClickData:state.CommonReducer.menuClick,
    searchData:state.CommonReducer.searchparamdata,
    CreateTask:state.TaskReducer.CreateTask,
    topbardata:state.TaskReducer.TodoTopbarMenudata,
    listtags:state.TaskReducer.ListAllTags,
    poolTasksData : state.TaskReducer.getPoolData

});
export default  withRouter(connect(mapStateToProps, {
    todo:taskActions.listtask,
    toFollowUp:taskActions.listtask,
    mainClick:rootActions.commonActions.MenuClick,
    createTask:taskActions.createtask,
    project:projectActions.listallprojects,
    listTags:projectActions.listalltags,
    selectTasksToMove : taskActions.selectTasksToMove,
    searchpooltask : taskActions.searchPoolTasks,
    getPoolMenu : taskActions.getPoolMenu,
    isTaskOpen : taskActions.isTaskOpen

})(PoolToDo));


