import {combineReducers} from 'redux';

const listallPrograms = (
    state = {programs:{data:[]}}, action) => {
    var _newState;
    switch (action.type) {
        case "LIST_All_PROGRAMS":
            return {
                ...state,
                programs: {
                    data : action.listallprograms.data.results.data
                },
            };
            break;
        case "UPDATE_VERTICAL":
            let programs = state.programs.data;
            if(action.newVertical.mode !== 'update') {
                programs.unshift(action.newVertical);
            }else{
                programs = programs.map((item) => { return ( parseInt(item.programId) === parseInt(action.newVertical.programId)) ? action.newVertical: item; });
            }
            return {
                ...state,
                programs: {
                    data : programs
                },
            };
            break;

        case "PROGRAMLIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        case "SEARCH_PROGRAM_DATA":
            return {
                ...state,
                searchResult: {
                    data : [].concat.apply([],[action.clearList ? [] :state.searchResult.data, action.listallprograms.data.results.data])
                },
                searchValue: action.value
            };
            break;

        case "LIST_MORE_PROGRAMS":
            _newState = {...state};
            _newState['programs'].data = _newState.programs.data.concat(action.listmoreprograms.data.results.data);
            return _newState;
            break;

        default:
            return state;
    }
};
const getProgramDet = (state = {programDet:{data:[]}}, action) => {
    switch (action.type) {
        case "GET_PROGRAM_DETAILS":
            return {
                ...state,
                    programDet : action.programDet.data.results.data
            };
            break;
        case "PROGRAM_DET_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        default:
            return state;
    }
};

const SaveVertical = (state = {verticalSaved : false, loading : false }, action) => {
    switch (action.type) {
        case "PROGRAMSAVE_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;
        case "SAVE_VERTICAL":
            return {
                ...state,
                verticalSaved: action.verticalSaved.data.results,
            };
            break;
        default:
            return state;
    }
};
const DeleteProgram = (state = {programDeleted : false }, action) => {
    switch (action.type) {
        case "DELETE_PORTFOLIO":
            return {
                ...state,
                programDeleted: !state.programDeleted
            };
            break;
        default:
            return state;
    }
};
export const Programs = combineReducers({
    listallPrograms,
    SaveVertical,
    getProgramDet,
    DeleteProgram
});

export default Programs;

