import React, {Component} from 'react';
import ImpactSnapshotCard from "./ImpactSnapshotCard";

function ImpactSnapshotBlock() {
    return (
        <React.Fragment>
            <div className="stakeholders-header">
                <h5>Impact Snapshot</h5>
            </div>
            <div className="small-tab-graph-block">

                <ImpactSnapshotCard title={'Beneficiaries'} value={53}  />
                <ImpactSnapshotCard title={'Clean Energy Capacity'} value={457.251} />
                <ImpactSnapshotCard title={'No. of Days Served'} value={400} />
                <ImpactSnapshotCard title={'People Employed'} value={464} />
                <ImpactSnapshotCard title={'Entrepreneurs'} value={250} />
                <ImpactSnapshotCard title={'Increased Income for'} value={'60%'} />
                <ImpactSnapshotCard title={'Reduction of GHG Emission'} value={500} />
                <ImpactSnapshotCard title={'Household cost saving For'} value={'70%'} />


            </div>
        </React.Fragment>


    );

}

export default ImpactSnapshotBlock;