import React, {Component} from 'react';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"


class RangeSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: { min: 0, max: 10 },
        };
        this.numberFilter=this.numberFilter.bind(this)
    }

    componentDidMount() {
       this.setState( {value: { min: parseInt(this.props.min), max: parseInt(this.props.max) }})
    }
    numberFilter=(value)=>{
        console.log(value)
        this.setState({ value })
        this.props.rangeFilter(value, this.props.masterid)
    }

    render() {
        console.log(this.props)
        return (
            <InputRange
                maxValue={this.props.max}
                minValue={this.props.min}
                value={this.state.value}
                onChange={value =>this.numberFilter(value)}


            />
        );
    }
}


export default RangeSlider;


