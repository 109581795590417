
const TASK_CREATION_MESSAGE='assigned a task';
const TASK_MARK_AS_DONE_MESSAGE='has completed the task';
const TASK_COMMENT_MESSAGE='added a comment for the task';
const TASK_REOPEN_MESSAGE='has reopened the task';
const TASK_VERIFY_MESSAGE='has verified the task';
const TASK_UPDATE_MESSAGE='updated a task'

export const Language = {
    TASK_CREATION_MESSAGE,
    TASK_MARK_AS_DONE_MESSAGE,
    TASK_COMMENT_MESSAGE,
    TASK_REOPEN_MESSAGE,
    TASK_VERIFY_MESSAGE,
    TASK_UPDATE_MESSAGE
};


