import React, {Component, useState } from 'react';
import 'react-selectize/themes/index.css'
import * as CustomFunctions from "../../../utilities/CustomFunctions";
import moment from 'moment';
import {USRIMG_PATH, FRMSIMG_PATH, FRMSVID_PATH, FRMSDOC_PATH, S3_URL, NEW_VERSION} from "../../../constants";
import {Datecomparisondue, forceDownload, isDocument} from "../../../utilities/CustomFunctions";
import DatePickerBox from "../../views/DatePickerBox";
import {timeConvert,formatDate,DateFormatter,timeConvertToDays} from "../../../utilities/CustomFunctions";
import {getProfile} from "../../../utilities/AuthService";
import {showLocalTime} from "../../../utilities/CustomFunctions";
import OutsideAlerter from "../../views/OutsideAlerter";
import {Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import sidebarLoader from "../images/sidebar-loader.svg";
import {getAttachmentPath} from "../../../utilities/CustomFunctions";
import LoaderSvg from "../../views/LoaderSVG";
import {isImage,getFileExtension,isVideo,formatBytes} from "../../../utilities/CustomFunctions";
import {previewModalFormat} from "../../../utilities/CustomFunctions";
import {getFilePath,getUrlSegment,urlify} from "../../../utilities/CustomFunctions";
import Coverimage from "../../../images/placeholders/Coverimage.jpg";
import {debounce} from "throttle-debounce";
import ListCard from "../../views/ListCard";
import SimpleModal from "../../views/SimpleModal";
import {DateTimeField} from "../../views/FormElements";
import WhMsgBox from "../whatsapp/WhMsgBox";

function HeaderBox(Props) {
    let userdonestatus=Props.assignee.filter(list=>list.status === "Done").length
    let redisData=Props.redisData
    let completedTime=redisData.length !==0 ?redisData[0].redis_created_at:Props.list.verifieddate
    let taskStatus=redisData.length !==0 ?redisData[0].reference:'';

    return(

        <div className="task-details-header-block">
                <div className="task-detail-header-laeft">
                    <ul className="task-details-info-wrap">
                        {Props.list.draft !== 'yes' && Props.list.duedate !== "0000-00-00 00:00:00" &&
                        <li>
                            <p>DUE ON </p>
                            <h5>{DateFormatter(Props.list.duedate, true, true).toUpperCase()}
                                {Props.dateChoosen === false && Props.list.createuser === getProfile().id && userdonestatus === 0 && Props.list.status !== "Done" && Props.archived === "no" && Props.list.archive === "no" && Props.list.has_scheduled === "no" &&
                                <OverlayTrigger
                                    key={`top`}
                                    placement={`right`}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Reschedule this task
                                        </Tooltip>
                                    }
                                >
                                    <button className="ico-lib edit-time-ico" onClick={Props.dateSwap}></button>
                                </OverlayTrigger>
                                }


                            </h5>
                        </li>
                        }
                        <li>
                            <p>PRIORITY</p>
                            <h5>{Props.list.priority.toUpperCase()}</h5>
                        </li>

                        {Props.list.has_scheduled === 'yes' && Props.list.scheduleddate !== "0000-00-00 00:00:00" &&
                        <li>
                            <p>SCHEDULED ON </p>
                            <h5>
                                {DateFormatter(Props.list.scheduleddate, true, true).toUpperCase()}
                            </h5>
                        </li>
                        }
                        {Props.list.draft === 'yes' &&
                        <li>
                            <p>STATUS</p>
                            <h5>{'DRAFT'}</h5>
                        </li>
                        }
                        <li>
                            {Props.list.projectname !== '' &&
                            <>
                                <p>PROJECT</p>
                                <h5>{Props.list.projectname.toUpperCase()}<span>
                                    {Props.list.projectinfo.length > 0 && Props.list.projectinfo[0].project_code!== null && Props.list.projectinfo[0].project_code !== '' ? '(' + Props.list.projectinfo[0].project_code + ')' : ''}</span>
                                </h5>
                            </>
                            }
                        </li>
                    </ul>
                </div>
                <div className="task-detail-header-right">
                    {(Props.state.verified === "yes" || taskStatus === "verifyandmarkasdone" || taskStatus === "verify") &&
                    <div className="task-status-ifo done">
                        <p>Verified On &nbsp;
                            <span className="font-bold">
                            {DateFormatter(completedTime,true,true)}
                           </span>
                        </p>
                    </div>
                    }
                    <TaskOptionButton
                        list={Props.list}
                        share={Props.share}
                        verified={Props.verified}
                        archived={Props.archived}
                        creator={Props.createuser}
                        assignee_status={Props.assignee_status}
                        assignee={Props.assignee}
                        modalOpen={Props.modalOpen}
                        duedate = {Props.duedate}
                        showReopenConfirm={Props.showReopenConfirm}
                        showConfirmArchive={Props.showConfirmArchive}
                        deleteTaskConfirm = {Props.deleteTaskConfirm}
                        handleDateChange = {Props.handleDateChange}
                        quitPoolTaskConfirm={Props.quitPoolTaskConfirm}



                    />
                </div>
            </div>
    )

}
function TaskOptionButton(Props){
    const [status, showOptions] = useState(false);
    const userdonestatus=Props.assignee.filter(list=>list.status =="Done").length
    return(
        <div className="task-other-opt">
            <button className="ico-lib option-button" onClick={()=>showOptions(!status)}></button>
            {status &&
            <OutsideAlerter
                status={status}
                Triggered={() => showOptions(!status)}
            >

                <ul className="panel-option">

                    {Props.list.status === "Done" && Props.list.createuser === getProfile().id && Props.verified !== "yes" && Props.archived === "no" && Props.list.archive === "no" && Props.list.assigntype !== "self" &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib re-open-ico"
                           onClick={(e) => Props.showReopenConfirm(Props.list.masterid, true)}>Reopen All</a>
                    </li>
                    }

                    {Props.archived === "no" && Props.list.archive === "no" &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib archieve-ico" onClick={(e)=>Props.showConfirmArchive("archive")} >Archive</a>
                    </li>
                    }
                    {(Props.archived === "yes" || Props.list.archive === "yes") &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib archieve-ico" onClick={(e)=>Props.showConfirmArchive("unarchive")}>Restore</a>
                    </li>
                    }

                    {Props.list.draft !== 'yes' && Props.list.verified !== "yes" &&
                    Props.assignee_status !== 'Done' &&
                    Props.archived === "no" && Props.list.archive === "no"
                    &&  (getProfile().id !== Props.creator) &&
                    Props.share && Props.share.length> 0 && Props.share[0].share !== "yes" &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib sub-task-ico" onClick={() => Props.modalOpen('subtask')}>Sub Task</a>
                    </li>
                    }

                    { Props.list.createuser === getProfile().id  && userdonestatus ===0 && Props.list.status !== "Done" && Props.archived === "no" && Props.list.archive === "no" &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib edit-task-ico" onClick={() => Props.modalOpen('edit')}>Edit</a>
                    </li>
                    }
                    {Props.list.createuser === getProfile().id && Props.list.status === 'New' &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib delete-task-ico" onClick={() => Props.deleteTaskConfirm('delete')}>Delete</a>
                    </li>
                    }
                    {Props.list.poolenabled === true && Props.list.status !== "Done" &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib delete-task-ico" onClick={() => Props.quitPoolTaskConfirm('quit')}>Return to Task Pool</a>
                    </li>
                    }
                    {Props.list.draft !== 'yes' &&
                    <li>
                        <a href="javascript:void(0);"
                           className="panel-option-click ico-lib print-ico"
                           onClick={() => window.location.href = ('/task/print/' + getUrlSegment(3) + '/' + getUrlSegment(4))}>Task
                            Print </a>
                    </li>
                    }
                </ul>


            </OutsideAlerter>
            }
        </div>
    )


}
function TaskStatusBar(Props) {
    let verified = Props.list.verified?Props.list.verified:'no';
    let totalMinutes = 0;
    if(Props.assigneesData) {
        for(let i=0; i< Props.assigneesData.length; i++) {
            totalMinutes += parseInt(Props.assigneesData[i].consolidated_time?Props.assigneesData[i].consolidated_time:0);
        }
    }
    let redisData= Props.redisData
    let taskStatus=redisData.length !==0 ?redisData[0].reference:''
    let completedTime=redisData.length !==0 ?redisData[0].redis_created_at:Props.list.donedate
    return(
        <React.Fragment>
            {((Props.assigneestatus === "Done" && verified === "no" && redisData.length ===0) || taskStatus==="markasdone")  &&
                    <div className="task-alert task-completed">
                        <div className="task-alert-left">
                            <h5 className="subheader  ico-lib-v1 completed-task">Task Done - {DateFormatter(completedTime,false)}</h5>
                        </div>
                        <div className="task-alert-right">
                            {parseFloat(Props.worktime) > 0 &&
                            <span className="ico-lib-v1 small-fn">{timeConvertToDays(Props.worktime)}</span>
                            }
                        </div>
                    </div>
            }
            {((verified === "yes" && redisData.length ===0) || taskStatus==="verifyandmarkasdone"  || taskStatus === "verify")&&
                    <div className="task-alert task-verified">
                <div className="task-alert-left">
                    <h5 className="subheader  ico-lib-v1 completed-task">Task Verified - {DateFormatter(completedTime,false)}</h5>
                </div>
                <div className="task-alert-right">
                    {totalMinutes > 0 &&
                    <span className="ico-lib-v1 small-fn">{timeConvertToDays(totalMinutes)}</span>
                    }
                </div>
            </div>
            }
        </React.Fragment>
    )

}
function TaskDateInfo(Props) {
    let redisData=Props.redisData
    let completedTime=redisData.length !==0 ?redisData[0].redis_created_at:Props.list.donedate
    return (
        <div className="task-time-label">
                {Props.list.duedate !== "0000-00-00 00:00:00" &&
                <h5><span className="task-date"> {DateFormatter(Props.list.createdate)}</span> <span className="task-time">{DateFormatter(Props.list.createdate,true,false)}</span></h5>
                }
                {(Props.list.status === "Done" || redisData.length !==0) &&
                <p><span className="task-date">Completed at {DateFormatter(completedTime,true,false)} on {DateFormatter(completedTime,false,true)}</span>
                    {/*<span className="task-time">{ifdue}</span>*/}
                </p>
                }
        </div>


    )

}

function TaskDetailUserInfo(Props) {

    let assigneelist = Props.assignedData.length > 0 ? Props.assignedData : [];
    let inviteUsers=Props.assignedData.filter(item => (item.share === 'yes'))
    let inviteStatus=(inviteUsers.map(item=>item.assignee).includes(getProfile().id))
    return (
        <div className="task-detail-user-info">
            <div className="task-detail-user-info-left">
                <div className="task-detail-user">
                    <div className={`task-detail-user-thumb ${Props.OnlineUsers(Props.list.createuser)?'online':''}`}>
                        <img src={USRIMG_PATH+Props.list.createuser_img_name} />
                    </div>
                    <div className="task-detail-user-det">
                        <h5>{Props.list.createuser_name}</h5>
                        <div className="assign-line">
                            <span className="ico-lib to-ico"></span>
                            <div className="assigned-tau-block single-user">
                                {Props.assigneesData.map((item,index) =>

                                    <React.Fragment key={`assignee-data-panel`+index}>
                                        {item.share === "no" &&
                                        <div className="tau" data-toggle="tooltip"
                                             data-placement="top"
                                             title="" data-original-title={item.user_name}
                                             key={"assig-"+index}>
                                            <OverlayTrigger
                                                key={`1`}
                                                placement={`top`}
                                                overlay={

                                                    <Tooltip id={`tooltip-top`} className={'test'}>
                                                        {item.user_name}
                                                    </Tooltip>
                                                }>
                                                                        <span className="tau-img">
                                                                            <img src={USRIMG_PATH + item.user_img_name}/>
                                                                        </span>
                                            </OverlayTrigger>
                                            <span
                                                className="tau-name">{item.user_name}</span>

                                        </div>
                                        }
                                    </React.Fragment>

                                )}
                                {Props.list.createuser !== getProfile().id && (assigneelist.length > 1) && ((inviteStatus === false)) &&
                                <button className={'assigned-user-task'}></button>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="task-detail-user-info-right">*/}
            {/*    /!*{Props.state.assignee_status !== "Done" && Props.list.duedate !== "0000-00-00 00:00:00" &&*!/*/}
            {/*    {Props.list.duedate !== "0000-00-00 00:00:00" &&*/}
            {/*    <div className="due-date-block">*/}
            {/*        <p>CREATED ON </p>*/}
            {/*        <p><span>{moment.utc(Props.list.createdate).local().format('DD MMM YYYY   |   hh:mm a')}</span></p>*/}
            {/*    </div>*/}
            {/*    }*/}
            {/*</div>*/}
        </div>


    )

}
function TaskDetailContent(Props) {
    const [showReply, toggleReplyBox] = useState(
        Props.showReply
    );

    const [message,setMessage] =useState('')

    function handleChange(e) {
        setMessage(e.target.value);
    }
    function replyToWh () {
        Props.postReplyToWhatsapp(message);
        toggleReplyBox(!showReply)
    }
    function replyToMainChat(from){
        Props.replyToMainChat(message,whatsappcontent,from);
        toggleReplyBox(!showReply)
    }

    /*React.useEffect(() => {
        props.selectForm(props.item, selected);
    }, [selected]);*/

    let whatsappcontent= Props.whcontent || [];

    return(
        <div className="task-detail-panel-content-area">
            <div className="task-detail-panel-content-area-left">
                <h5>{decodeURIComponent(escape(atob(Props.list.subject)))}</h5>
                <p dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(urlify(atob(Props.list.Title)))).replace(/\n/g, '<br />')}} />
                {whatsappcontent.length !== 0 && Props.list.createdfrom !=="singlemainchat" && Props.list.createdfrom !=="externalchat" &&

                <React.Fragment>
                    <div className="task-chat-container">
                        {whatsappcontent.map((item, index) =>
                            <WhMsgBox chat={item} index={index} chatList={whatsappcontent} reference="task"/>
                        )}

{/*
                        <p><a href={`https://data-sepalika.bsynapse.com/whatsappchat?reply=${Props.list.leadId}`}>Reply</a> </p>
*/}

{/*
                        <p><span onClick={() => toggleReplyBox(!showReply)}>Reply</span> </p>
*/}
                        <p><span className="wa-chat-reply-btn" onClick={() => toggleReplyBox(!showReply)}>Reply</span> </p>
                        {showReply &&
                        <div className="wa-task-chat-reply">
                            <textarea name="message" onChange={(e)=>handleChange(e)}></textarea>

                            <div className="wa-task-btn-block">
                                <button className="general-btn" onClick={() => replyToWh()}>Send</button>
                            </div>
                        </div>
                        }


                    </div>
                </React.Fragment>


                }

                {whatsappcontent.length !== 0 && (Props.list.createdfrom ==="singlemainchat" || Props.list.createdfrom ==="externalchat") &&

                <React.Fragment>
                    <div className="task-chat-container">
                        {whatsappcontent.map((item, index) =>
                            <WhMsgBox chat={item} index={index} createdfrom ={Props.list.createdfrom} chatList={whatsappcontent} reference="task"/>
                        )}


                        <p><span className="wa-chat-reply-btn" onClick={() => toggleReplyBox(!showReply)}>Reply</span> </p>
                        {showReply &&
                        <div className="wa-task-chat-reply">
                            <textarea name="message" onChange={(e)=>handleChange(e)}></textarea>

                            <div className="wa-task-btn-block">
                                <button className="general-btn" onClick={() => replyToMainChat(Props.list.createdfrom)}>Send</button>
                            </div>
                        </div>
                        }


                    </div>
                </React.Fragment>


                }



            </div>
        </div>

    )

}
function TaskStatusButton(Props) {
    let taskdetails=Props.redisData
    return(
        <div className="btn-section-left">
                {Props.buttonStatus &&
                <span className={`loader-span`}>
                      <img src={sidebarLoader}/>
                    </span>
                }
                {taskdetails.length ===0 && !Props.buttonStatus&&
                    <React.Fragment>
                        {(
                            (  /*Creator to Creator as assignee  (self task)*/
                                (Props.state.tasktype === 'task' || Props.state.tasktype === 'pool')&&
                                Props.list.createuser === getProfile().id &&
                                Props.assigneestatus !== "Done" &&
                                Props.shared !== "yes" &&
                                Props.parent.status !== "Done" &&
                                (Props.craetorInassignee.length !== 0 && Props.assigneestatus !== "Done"))
                            ||

                            ( /*Creator to Assignee*/
                                (Props.state.tasktype === 'task' || Props.state.tasktype === 'pool')&&
                                Props.list.createuser !== getProfile().id &&
                                Props.assigneestatus !== "Done" &&
                                Props.shared !== "yes" &&
                                Props.parent.status !== "Done" &&
                                (Props.craetorInassignee.length !== 0 )
                            )

                        ) &&
                            <React.Fragment>
                                {Props.state.tasktype === 'task' &&
                                <button
                                    className={`general-btn-green ico-lib verify-button ${(Props.craetorInassignee[0].reassign === "yes" && Props.craetorInassignee[0].subtaskverified !== "yes") ? "disable" : ""}`}
                                    onClick={(e) => {
                                        Props.showmarkasdoneModal(Props.list.masterid, Props.list.createuser)
                                    }}>
                                    {'Mark as Done'}
                                </button>
                                }


                                {Props.state.tasktype !== 'task' && Props.craetorInassignee[0].reassign !== "yes" &&
                                <button className={`general-btn-green ico-lib verify-button`}
                                        onClick={(e) => {
                                            Props.showmarkasdoneModal(Props.list.masterid, Props.list.createuser)
                                        }}>
                                    {'Mark As Read'}
                                </button>
                                }


                                {Props.list.poolenabled === true && Props.craetorInassignee[0].reassign !== "yes" &&
                                <button className="general-btn-green ico-lib quit"
                                        onClick={() => Props.quitPoolTaskConfirm('quit')}>Return to Task Pool
                                </button>
                                }
                            </React.Fragment>

                        }



                        {Props.list.verifyenabled === "yes" &&
                        <>
                            {((Props.list.createuser === getProfile().id) &&
                                Props.list.verified !== "yes" &&
                                ((Props.craetorInassignee.length === 0) ||
                                    (Props.craetorInassignee.length !== 0 && Props.assigneestatus === "Done") ||
                                    (Props.craetorInassignee.length !== 0 && Props.shared === "yes")
                                )
                            ) &&
                                <>
                                    {Props.list.has_parent === "yes" &&
                                    <button
                                        className={`general-btn-green ico-lib verify-button ${Props.parent.status === "Done" ? "" : "disable"}`}
                                        onClick={(e) => {
                                            Props.showmarkasdoneModal(Props.list.masterid, Props.list.createuser, "verifyandmarkasdone")
                                        }}>
                                        {'Verify & Mark As Done'}
                                    </button>
                                    }



                                    <button
                                        className={`general-btn-green ico-lib verify-button ${Props.parent.status === "Done" ? "" : "disable"}`}
                                        onClick={(e) => {Props.showverifyModal(Props.list.masterid, 'verify')
                                        }}>
                                        {'Verify & Close'}
                                    </button>




                            </>
                            }
                        </>
                        }


                        {(Props.list.verifyenabled === "no" && Props.list.markasdoneenabled === "yes")&&
                        <>
                            {((Props.list.createuser === getProfile().id) &&
                                Props.list.verified !== "yes" &&
                                ((Props.craetorInassignee.length === 0) ||
                                    (Props.craetorInassignee.length !== 0 && Props.assigneestatus === "Done") ||
                                    (Props.craetorInassignee.length !== 0 && Props.shared === "yes")
                                )
                            ) &&
                            <button
                                className={`general-btn-green ico-lib verify-button ${Props.parent.status === "Done" ? "" : "disable"}`}
                                onClick={(e) => {
                                    Props.list.has_parent === "yes" ? Props.showmarkasdoneModal(Props.list.masterid, Props.list.createuser, "verifyandmarkasdone") : Props.showmarkasdoneModal(Props.list.masterid, Props.list.createuser, "verifyandmarkasdone")
                                }}>
                                {Props.list.has_parent === "yes" ? 'Mark As Done' : 'Mark As Done'}
                            </button>
                            }
                        </>
                        }

                        {(Props.list.createuser === getProfile().id) &&
                            Props.list.verified !== "yes" && Props.craetorInassignee.length === 0 && Props.parent.status !== "Done" &&
                         <>

                            <button className="general-btn-green ico-lib verify-button"
                                    onClick={(e) => {
                                        Props.forcrClose(Props.list.masterid, Props.list.createuser)
                                    }}>
                                {'Mark as Closed'}
                            </button>
                        </>
                        }





                    </React.Fragment>
                }
        </div>
    )
}

function WorkTimeSection(Props) {
    let redisData=Props.redisData
    let consolidatedTime=redisData.length !==0 ?redisData[0].consolidated_time:Props.state.consolidated_time
    return(
        <div className="working-time-section">
            {((Props.data.multiLoader.loader['time-log-data'] && Props.data.multiLoader.loader['time-log-data'] === true) && (Props.share[0] !== undefined && Props.share[0].share !== "yes")) ?
                <span className={`loader-span`}>
                  <img src={sidebarLoader}/>
                </span>
                :
                <>
                    {(Props.timelogData.length > 0 || redisData.length !==0)&&
                    <div className="time-log-sub-list-right">
                        <p>{(Props.list.status ==="Done")? "WORKING TIME":"" +
                            "TIME TAKEN"} <span>{timeConvert(Props.worktime)}
                            </span> (HR : MIN)
                        </p>
                    </div>
                    }

                </>
            }
        </div>
    )
}
function TaskDetailBox(list)  {
    let redisData=list.redisData
    let redisremarks=redisData.length !==0 ?redisData[0].redisremarks:[]
    return (
        <React.Fragment>
            {list.loader &&
            <span className={`loader-span`}>
                  <LoaderSvg/>
            </span>
            }
            {list.reassign &&
            <React.Fragment>
                {list.subtaskData.map((listitem, index) =>
                    <div className="subtask-detail-panel">
                        <span className="ico-lib-v1 sub-icon"></span>
                        <div className="task-detail-panel" key={`subtask`+index}>
                            <div className="task-detail-panel-content">
                                {listitem.assignees.map((key, index) =>

                                    key.status === "Done" &&
                                    <span key={index} className="done-mark-sm ico-lib-v1 cleared"></span>

                                )}


                                <div className="task-detail-user-info">
                                    <div className="task-detail-user-info-left">
                                        <div className="task-detail-user">
                                            <div className="task-detail-user-det">
                                                <h5>{listitem.creator_name}</h5>
                                                <div className="assign-line">
                                                    <span className="ico-lib-v1 to-sub-ico"></span>
                                                    {listitem.assignees.map((assignee, index) =>
                                                        <AssigneeBox key={index} index={index} assignee={assignee}/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="task-detail-user-info-right">
                                        <div className="due-date-block">
                                            <p>DUE ON</p>
                                            <p className="f-b">{DateFormatter(listitem.duedate,true,true)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="task-detail-panel-content-area">
                                    {/*<h5>{atob(listitem.subject)}</h5>*/}
                                    {/*<p>{atob(listitem.title)}</p>*/}
                                    <h5>{decodeURIComponent(escape(atob(listitem.subject)))}</h5>
                                    <p dangerouslySetInnerHTML={{__html: decodeURIComponent(escape(urlify(atob(listitem.title)))).replace(/\n/g, '<br />')}} />
                                </div>
                            </div>
                            <div className="individual-assigners">
                                {listitem.assignees.map((key, index) =>
                                    <div
                                        className={key.status === "Done" ? "ind-div-assign t-d-st" : "ind-div-assign t-p-st"}>
                                        <div className="info-contianer">
                                            <div className="task-detail-user-info-left">
                                                <div className="task-detail-user-thumb">
                                                    <img src={USRIMG_PATH + key.assignee_img_name}/>
                                                </div>
                                                <div className="task-detail-user-det">
                                                    <h5>{key.assignee_name}</h5>
                                                    <p>{key.status}</p>
                                                </div>
                                            </div>
                                            {((key.hasOwnProperty("mark_as_done_comment")&&
                                                key.mark_as_done_comment !== null &&
                                                key.mark_as_done_comment !==undefined &&
                                                key.mark_as_done_comment.length !==undefined &&
                                                key.mark_as_done_comment.length > 0)  || redisremarks.length > 0) &&
                                            <button className="remarks-text-btn" data-toggle="modal"
                                                    data-target="#dollarM" onClick={(e) => {
                                                list.showremarksModal((redisremarks.length === 0? key.mark_as_done_comment:redisremarks),'reopen')
                                            }}>View Remarks
                                            </button>
                                            }
                                            {key.status === "Done" &&
                                            <div className="task-detail-user-info-right">
                                                <div className="due-date-block">
                                                    <p>COMPLETED ON </p>
                                                    <p className="f-b">
                                                        {DateFormatter(key.donedate,false,true)}
                                                    </p>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>

            }
        </React.Fragment>
    );

}

class IndividualAssigners extends Component {

    constructor(props) {
        super(props);
        this.state={
            clap:false,
            count:0,
            curAss:'',
        }
        this.clapCount=debounce(1000, this.clapCount);

    }

    showOptions=(clap,assignee)=>{
        this.setState({clap:!this.state.clap,[assignee]:assignee,curAss:assignee})

    }
    clapCount=(count,asgne)=>{
     // this.setState({[assignee+"count"]:count+1})
        let asgUsr=this.props.assigneesData.filter(user=>user.assignee===asgne)
        asgUsr[0].points= ((asgUsr[0].points) === ""?0:parseInt(asgUsr[0].points))+1
          this.setState({asgUsr})
        this.props.handleClap(parseInt(asgUsr[0].points),asgne)
    }


    render(){
        let redisData=this.props.redisData
        let redisComplted=redisData.length !==0 ?redisData[0].redis_created_at:''
        let redisconsolidated_time=redisData.length !==0 ?redisData[0].consolidated_time:''
        let redisremarks=redisData.length !==0 ?redisData[0].redisremarks:[]
        let taskStatus=redisData.length !==0 ?redisData[0].reference:''
        return(   <div className="individual-assigners">
            { this.props.assigneesData.map((item,index) =>
                <React.Fragment key={`individual-assigners`+index}>
                    {item.share === "no" &&
                    <div
                        className={item.status === "Done" ? "ind-div-assign t-d-st" : (item.status === "New"?"ind-div-assign t-n-st":'ind-div-assign t-p-st')}
                        key={"assignee-"+index}>
                        <div className="info-contianer">
                            <div className="task-detail-user-info-left">
                                <div className="task-detail-user-thumb">
                                    <img src={USRIMG_PATH + item.user_img_name}/>
                                </div>
                                <div className="task-detail-user-det">
                                    <h5>{item.user_name}</h5>
                                    {(item.status === "Done" || redisComplted !== "") &&
                                    <div className="due-date-block">
                                        <p>COMPLETED ON&nbsp; {redisComplted === '' ? DateFormatter(item.donedate,true,true) : DateFormatter(redisComplted,true,true)}</p>
                                    </div>
                                    }
                                    {taskStatus !== "markasdone" && taskStatus !== "verifyandmarkasdone" &&
                                    <React.Fragment>
                                        {item.hasOwnProperty("reopen") && item.reopen === "yes" && item.status !== "Done" ?
                                            <p>Reopened </p>
                                            :
                                            item.status !== "Done" ? <p>{item.status} </p> : ""}

                                    </React.Fragment>

                                    }


                                </div>
                            </div>

                            {(this.props.verified === "yes" || (redisData.length !==0 && redisData[0].reference==="verify"))&&
                            <div className="clap-reaction">


                                <button className="clap-btn active"
                                        onClick={() => this.clapCount(1,item.assignee)}>
                                    <span>{item.points===""?0:item.points}</span>
                                </button>


                                {/*{this.state.clap === false ?*/}
                                {/*    <button className="clap-btn active"*/}
                                {/*            onClick={() => ((item.points === "" && this.state[item.assignee + "count"] === undefined) ? this.showOptions(!this.state.clap, item.assignee) : "")}>*/}
                                {/*        <span>{item.points === "" ? (this.state[item.assignee + "count"] === undefined ? 0 : this.state[item.assignee + "count"]) : item.points} / 5</span>*/}
                                {/*    </button>*/}

                                {/*    :*/}
                                {/*    <div className="clap-btn active">*/}
                                {/*        <span>{item.points === "" ? (this.state[item.assignee + "count"] === undefined ? 0 : this.state[item.assignee + "count"]) : item.points} / 5</span>*/}
                                {/*    </div>*/}

                                {/*}*/}

                                {/*{item.createuser === getProfile().id && this.state.clap && (this.state[item.assignee] === item.assignee) && (this.state.curAss === item.assignee) &&*/}
                                {/*<OutsideAlerter*/}
                                {/*    status={this.state.clap}*/}
                                {/*    Triggered={() => this.showOptions(false)}>*/}
                                {/*    <div className="reaction-box">*/}
                                {/*        <button className={`clap-btn ${this.state[item.assignee+"count"] >= 1 ? "active" : ""}`}*/}
                                {/*                onClick={() => {*/}
                                {/*                    this.clapCount(1,item.assignee);*/}

                                {/*                }}> </button>*/}
                                {/*        <button className={`clap-btn ${this.state[item.assignee+"count"] >= 2 ? "active" : ""}`}*/}
                                {/*                onClick={() => {*/}
                                {/*                    this.clapCount(2, item.assignee)*/}
                                {/*                }}></button>*/}
                                {/*        <button className={`clap-btn ${this.state[item.assignee+"count"] >= 3 ? "active" : ""}`}*/}
                                {/*                onClick={() => {*/}
                                {/*                    this.clapCount(3, item.assignee)*/}
                                {/*                }}></button>*/}
                                {/*        <button className={`clap-btn ${this.state[item.assignee+"count"] >= 4 ? "active" : ""}`}*/}
                                {/*                onClick={() => {*/}
                                {/*                    this.clapCount(4, item.assignee)*/}
                                {/*                }}></button>*/}
                                {/*        <button className={`clap-btn ${this.state[item.assignee+"count"] >= 5 ? "active" : ""}`}*/}
                                {/*                onClick={() => {*/}
                                {/*                    this.clapCount(5, item.assignee)*/}
                                {/*                }}></button>*/}
                                {/*    </div>*/}
                                {/*</OutsideAlerter>*/}
                                {/*}*/}


                            </div>
                            }

                            {(item.status === "Done" || redisData.length !==0) &&
                            <div className="task-detail-user-info-right jus-btn-block jus-right-btn-block">







                                {item.createuser === getProfile().id && this.props.verified !== "yes" && this.props.data.archive === "no" && this.props.data.assigntype !== "self" && redisData.length ===0 &&
                                <button className="general-btn-green trans-border ico-lib"
                                        onClick={(e) => {
                                            this.props.showReopenConfirm(item.childid, false,item.assignee)
                                        }}>Reopen</button>
                                }


                                <div className="due-date-block" align="right">
                                    {((item.status === "Done"   || taskStatus === "markasdone" || taskStatus === "verifyandmarkasdone") &&
                                        (item.consolidated_time !== "0" || redisconsolidated_time !== "" ) )&&  ( this.props.data.createuser !==  getProfile().id) &&

                                    <p>TIME TAKEN&nbsp;
                                        <span className="f-b">
                                          {redisconsolidated_time ==="" ?timeConvert(item.consolidated_time):timeConvert(redisconsolidated_time)} (HR : MIN)
                                        </span>

                                    </p>

                                    }

                                    {(((this.props.data.hasOwnProperty("verified") && this.props.data.verified === "yes") || item.status === "Done" ) || taskStatus === "verify" ) &&
                                    ( this.props.data.createuser ===  getProfile().id) &&
                                    <p>TIME TAKEN&nbsp;
                                        <span className="f-b">
                                        { timeConvert(item.consolidated_time)} (HR : MIN)
                                        </span>

                                    </p>

                                    }



                                    {((item.hasOwnProperty("mark_as_done_comment")&&
                                        item.mark_as_done_comment !== null &&
                                        item.mark_as_done_comment !==undefined &&
                                        item.mark_as_done_comment.length !==undefined &&
                                        item.mark_as_done_comment.length > 0)  || redisremarks.length > 0) &&
                                    <button className="remarks-text-btn" data-toggle="modal"
                                            data-target="#dollarM" onClick={(e) => {
                                        this.props.showremarksModal((redisremarks.length === 0? item.mark_as_done_comment:redisremarks),'reopen')
                                    }}>View Remarks
                                    </button>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </React.Fragment>
            )}

        </div>)
    }

}
function AttachmentPanel(list){
    //console.log(list)
    let download=list.taskPermission.filter(permission=>permission.action==="Download")[0] !==undefined ? list.taskPermission.filter(permission=>permission.action==="Download")[0].action_permission:""
    if(list.attachmentData.length !== 0) {
        return (
            <div className="task-detail-panel-attachment">
                <div className="attachment-header">
                    <div className="attachment-header-left">
                        <h5>
                            {list.attachType==='resource' ? "Resources" : "Attachments"} <span className="count">({list.attachmentData.length})</span></h5>
                    </div>
                    {/*<div className="attachment-header-right">
                        <span className="download-button" id="download_All"
                           onClick={(e) => list.downloadAll(e,download, list.attachType)}>Download
                            All</span>
                    </div>*/}
                </div>
                <div className="attachment-list-wrapper">
                    <ul className="attachment-list">
                        {list.attachmentData !== undefined && list.attachmentData.map((item, index) =>
                            <React.Fragment>
                                {list.attachType==='attachment' &&
                                    <AttachmentBox item={item} index={index} key={"attach-" + index} preview={list.preview} taskPermission={list.taskPermission}/>
                                }
                                {list.attachType==='resource' &&
                                    <ResourceBox item={item} index={index} key={"resource-" + index} preview={list.preview} taskPermission={list.taskPermission}/>
                                }
                            </React.Fragment>

                        )}

                    </ul>
                </div>

            </div>
        )
    }
    return null

}
function CollectionPanel(list){
    if(list.has_collection && list.collectionData && Array.isArray(list.collectionData)){
        return (
            <div className="task-detail-panel-attachment white-panel">
                <div className="attachment-header">
                    <div className="attachment-header-left">
                        <h5>Forms <span className="count">({list.collectionData ? list.collectionData.length : ''})</span></h5>
                    </div>
                </div>
                <div className="attachment-list-wrapper">
                    <ul className="attachment-list attach_forms">
                        {list.collectionData.map((data,index) => {

                            let formName = atob(data.title);
                            let loggedUsers = data.users ? data.users.filter(user => user.assignee=== getProfile().id):[];
                            let invitedUsers = [];
                            if(loggedUsers.length === 0) {
                                invitedUsers = list.assignees ? list.assignees.filter(user => user.assignee=== getProfile().id):[];
                            }


                            if((list.createUser === getProfile().id || invitedUsers.length >0) && loggedUsers.length === 0) { // Creator only

                                /***************** Whether to show preview form or show form or Error message Begin************/
                                let displayPreview = false;
                                let filledUsers = data.users ? data.users.filter(user => user.has_data === 'yes'):[];
                                let filledFormCount = filledUsers.length;
                                if(filledFormCount > 0) {
                                    displayPreview = true;
                                }

                                /***************** Whether to show preview form or show form or Error message End************/
                                if(list.createdFrom === 'collection' && list.taskStatus !== 'Done') {
                                    return(<li className="ind-forms-file" key={"collection-"+index}>
                                        <a className="forms-header ico-lib" href="javascript:void(0);" onClick={(e)=>list.showCollectionRecords(data.collection, formName,data.users[0]['fieldid'])}>{formName}</a>
                                    </li>)
                                }
                                else{
                                    return( <li className="ind-forms-file" key={"collection-"+index}>
                                        <a className="forms-header ico-lib" href="javascript:void(0);"
                                           onClick={(e)=>{displayPreview === true ? list.previewForm(filledUsers[0]['fieldid'], data.collection, 0): list.showPreviewAlert()} }>
                                            {formName}
                                        </a>
                                    </li>)
                                }

                            }
                            else if(list.createUser === getProfile().id && loggedUsers.length > 0) { // Creator and assignee together
                                if(list.createdFrom === 'collection' && list.taskStatus !== 'Done') {
                                    return(<li className="ind-forms-file" key={"collection-"+index}>
                                        <a className="forms-header ico-lib" href="javascript:void(0);" onClick={(e)=>list.showCollectionRecords(data.collection, formName,data.users[0]['fieldid'])}>{formName}</a>
                                    </li>)
                                }
                                else {
                                    return (<li className="ind-forms-file" key={"collection-" + index}>
                                        <a className="forms-header ico-lib" href="javascript:void(0);" onClick={(e) => {
                                            (loggedUsers[0]['has_data'] === 'yes' && data.users[0]['status'] === 'done') ? list.previewForm(data.users[0]['fieldid'], data.collection, 0) : list.showFormModal(data.collection, formName)
                                        }}>{formName}</a>
                                    </li>)
                                }
                            }
                            else if(list.createUser !== getProfile().id &&  loggedUsers.length > 0 ) { // Assignee only
                                //console.log(list.taskStatus);
                                //console.log(loggedUsers);
                                if(list.createdFrom === 'collection' && list.taskStatus !== 'Done') {
                                    return(<li className="ind-forms-file" key={"collection-"+index}>
                                        <a className="forms-header ico-lib" href="javascript:void(0);" onClick={(e)=>list.showCollectionRecords(data.collection, formName,data.users[0]['fieldid'])}>{formName}</a>
                                    </li>)
                                }
                                else{
                                    return(<li className="ind-forms-file" key={"collection-"+index}>
                                        <a className="forms-header ico-lib" href="javascript:void(0);" onClick={(e)=>{(loggedUsers[0]['has_data'] === 'yes' && list.taskStatus === 'Done') ? list.previewForm(loggedUsers[0]['fieldid'], data.collection,0) : list.showFormModal(data.collection, formName,loggedUsers[0]['fieldid'])}}>{formName}</a>
                                    </li>)
                                }

                            }
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    else
        return null

}
function FilledFormDataPanel(list){
    if(list.has_collection){
        let formName = list.formName ? atob(list.formName) : '';
        return (
            <div className="task-detail-panel-attachment white-panel">
                <div className="attachment-header">
                    <div className="attachment-header-left">
                        <h5>Form Data</h5>
                    </div>
                </div>
                <div className="attachment-list-wrapper">
                    <ul className="attachment-list attach_forms">

                        <li className="ind-forms-file" key={"collection-0"}>
                            <a className="forms-header ico-lib" href="javascript:void(0);"
                               onClick={(e)=>{list.previewForm(list.recordId,list.collectionId, 0)}}>
                                {formName}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    else
        return null

}
function ViewParentPanel(list){
    return (
        <React.Fragment >
            {list.hasparentTask &&
            <div className="task-detail-panel-parent" align="center">
                                <span className="angle-right-ico">
                                    <a href="javascript:void(0);" data-target="parent-task"
                                       onClick={(e) => list.viewParentTask(list.parentId)}>View parent task </a>
                                </span>
            </div>
            }
        </React.Fragment>
    )
}

function NavTab(Props){
    var dataNav=[
        {
            "AcTab": "home-tab",
            "label":"Discussion",
            "icon" :"ico-lib-v1 disscussion-tab-ico"
        },
        {
            "AcTab": "user-tab",
            "label":"User",
            "icon" :"ico-lib-v1 user-tab-ico"
        },
        {
            "AcTab": "file-tab",
            "label":"Files",
            "icon" :"ico-lib-v1 file-tab-ico"
        },
        {
            "AcTab": "rel-tab",
            "label":"Related Task",
            "icon" :"ico-lib-v1 file-stage-ico"
        },
        {
            "AcTab": "act-tab",
            "label":"Activities",
            "icon" :"ico-lib-v1 activities-tab-ico"
        },
        {
            "AcTab": "log-tab",
            "label":"Log",
            "icon" :"ico-lib-v1 act-tab-ico"
        },
        {
            "AcTab": "info-tab",
            "label":"Info",
            "icon" :"ico-lib-v1 info-tab-ico"
        }

    ]
    return (
        <React.Fragment>
            {dataNav.map((label,index) =>
                <li className="nav-item" key={"nav_tab_"+index} onClick={(e) => {Props.getrightTabid(label.AcTab)}}>
                    <a className={Props.rightTab ===  label.AcTab ? 'nav-link active': 'nav-link'} id="act-tab" data-toggle="tab" role="tab"
                       aria-controls="act" aria-selected={Props.rightTab === label.AcTab ? 'true': 'false'} >
                        <span className={label.icon}></span>
                        <span className="tab-text">{label.label}</span>
                    </a>
                </li>
            )}






        </React.Fragment>
    )
}
function ActivityPanel(list){
    return (
        <React.Fragment>
            {list.logData.map((item,index) => {
                    var activityType=""
                    var taskHighlight=""
                    var taskType=""
                    if(item.activity === "invited to task"){
                        activityType="newtask-assign"
                        taskHighlight="newtask-assign-highlight"
                        taskType= `invited ${item.assignees} to task`
                    }
                    else if(item.activity === "Marked as Done"){
                        activityType="task-markdone"
                        taskHighlight="markdone-highlight"
                        taskType= [item.activity]
                    }
                    else if(item.activity === "reopen the Todo"){
                        activityType="task-reopen"
                        taskHighlight="reopen-highlight"
                        taskType= [item.activity]
                    }
                    else{
                        activityType="newtask-assign"
                        taskHighlight="newtask-assign-highlight"
                        taskType= [item.activity]
                    }


                    return(
                        <li key={'activity-panel-box' + index}>
                            <div className={`task-activities-items ${activityType}`}>
                                <p>
                                    <span className={activityType}>{showLocalTime(item.crtdate)}</span>
                                </p>
                                <h5>
                                    <span className="font-bold">{item.creator} </span>
                                    <span className={taskHighlight}> {taskType}</span>
                                </h5>
                            </div>
                        </li>
                    )

                }

            )}
        </React.Fragment>
    )
}
function UsersPanelTab(props){
    if(props.assigneesData.length !==0 )
        return (
            <React.Fragment>
                <div className="spliter-task-user">
                    <h5 className="split-task-header">{props.title} ({props.assigneesData.length})</h5>
                    {props.assigneesData.map((item, index) =>
                        <React.Fragment key={"assignee-tab-" + index}>
                            <div className="ind-task-added-user-list">
                                <div className="ind-added-user-list-left">
                                    <div className="ind-added-user-list-left-thumb">
                                        <a href="javascript:void(0)">
                                            <img title={item.user_name} src={USRIMG_PATH + item.user_img_name}
                                                 alt=""/></a>
                                    </div>
                                    <div className="ind-added-user-list-left-info">
                                        <h5>{item.user_name}</h5>
                                        <p>{item.user_designation}</p>
                                        {props.type === "assigned" &&
                                        <div className="task-status-meter">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar"
                                                     style={{width: item.status === 'Done' ? '100%' : (item.status === "New" ? '0%' : '50%')}}
                                                     aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <p>{item.status === 'Done' ? '100%' : (item.status === "New" ? '0%' : '50%')}</p>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="ind-added-user-list-right">
                                    {item.consolidated_time && props.title === 'Assigned users' && item.consolidated_time !== '0' ?
                                    <div className="date-user-task">
                                        <h5>TIME TAKEN</h5>
                                        {item.consolidated_time && parseFloat(item.consolidated_time) > 0 &&
                                        <p>{item.consolidated_time ? timeConvert(item.consolidated_time) : '0'} (HRR:MIN)</p>
                                        }
                                    </div> : ''}
                                    {/*<button className="ico-lib-v1 chat-ico-task"></button>*/}
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                </div>
            </React.Fragment>
        )
    else
        return null

}
function AttachmentPanelTab(props){
    let download=props.taskPermission.filter(permission=>permission.action==="Download")[0] !==undefined ? props.taskPermission.filter(permission=>permission.action==="Download")[0].action_permission:""

    if(props.allfilesData.length !==0 ||  props.resourceData.length !== 0)
        return (
            <React.Fragment>
                {props.allfilesData.map((item,index) => {
                    let iconClass = getFilePath(item.filename);
                    let filename=getFilePath(item.filename)
                    let contentPath = getAttachmentPath(filename);
                    var albumName;
                    if (isImage(item.filename)) {
                        albumName = 'images'
                    } else if (isVideo(item.filename)) {
                        albumName = 'videos'
                    } else {
                        albumName = 'documents'
                    }
                    return(
                        <p className="ind-file-list file-div" key={"attach-files-"+index}>

                            <a href="javaScript:void(0)" className="attach-click attachlink"
                               // onClick={ ()=>(((isVideo(item.filename) && download === "yes") ||  isVideo(item.filename) ===false) ? forceDownload(contentPath + item.filename, item.filetitle):"")}
                               onClick={ ()=>(isDocument(item.filename)? ( download === "yes" ? forceDownload(contentPath + item.filename, item.filetitle):''):props.preview(contentPath + item.filename, item.filetitle, albumName, download))}
                            >
                                <span className="attach-click-thumb">
                                    {iconClass !== 'image' &&
                                        <div className={"format " + getFileExtension(item.filename)}>
                                            {getFileExtension(item.filename)}
                                        </div>
                                    }
                                    {iconClass === 'image' &&
                                        <img style={{ width: "20px"}}src={contentPath+item.filename} />
                                    }
                                </span>
                                <div className="file-details">
                                    <span className={`file-click-title`}>{item.filetitle.split(".")[0]}</span>
                                    <span className="file-size">{formatBytes(item.filesize)}</span>
                                </div>
                            </a>

                            <div className="attachment-option-tab">
                                {previewModalFormat(item.filename) &&
                                <button className="ico-lib-v2 preview" title="Preview" onClick={(e) => props.preview(contentPath + item.filename, item.filetitle, albumName, download)}/>
                                }
                                {albumName === "documents" && isDocument(item.filename) && download === "yes" &&
                                <button className="ico-lib-v2 download" title="Download"
                                    onClick={(e) => forceDownload(contentPath + item.filename, item.filetitle)}/>
                                }
                                {/*{albumName === "videos" && download === "yes" &&*/}
                                {/*<button className="ico-lib-v2 download" title="Download"*/}
                                {/*    onClick={(e) => forceDownload(contentPath + item.filename, item.filetitle)}/>*/}
                                {/*}*/}
                                </div>

                        </p>
                    )}
                )}
                {props.resourceData.map((item,index) => {
                    let iconClass = getFilePath(item.res_img);
                    let filename=getFilePath(item.res_img)
                    let contentPath = getAttachmentPath(filename);
                    var albumName;
                    if (isImage(item.res_img)) {
                        albumName = 'images'
                    } else if (isVideo(item.res_img)) {
                        albumName = 'videos'
                    } else {
                        albumName = 'documents'
                    }
                    return(
                        <p className="ind-file-list file-div" key={"attach-files-"+index}>

                            <a href="javaScript:void(0)" className="attach-click attachlink"
                               // onClick={ ()=>(((isVideo(item.res_img) && download === "yes") ||  isVideo(item.res_img) ===false) ? forceDownload(contentPath + item.res_img, item.res_img):"")}
                               onClick={ ()=>((isDocument(item.res_img) && download === "yes") ? forceDownload(contentPath + item.res_img, item.res_img):props.preview(contentPath + item.res_img, item.title,albumName,download,item))}
                            >
                                <span className="attach-click-thumb">
                                    {iconClass !== 'image' &&
                                        <div className={"format " + getFileExtension(item.res_img)}>
                                            {getFileExtension(item.res_img)}
                                        </div>
                                    }
                                    {iconClass === 'image' &&
                                        <img style={{ width: "20px"}}src={contentPath+item.res_img} />
                                    }
                                </span>
                                <div className="file-details">
                                    <span className={`file-click-title`}>{item.title}</span>
                                    <span className="file-size">{item.filesize}</span>
                                </div>
                            </a>

                            <div className="attachment-option-tab">
                                {previewModalFormat(item.res_img) &&
                                    <button className="ico-lib-v2 preview" title="Preview" onClick={(e)=>props.preview(contentPath + item.res_img, item.title,albumName,download,item)}></button>
                                }
                                {albumName === "documents" && isDocument(item.res_img) && download === "yes" &&
                                <button className="ico-lib-v2 download" title="Download"
                                        onClick={(e) => forceDownload(contentPath + item.res_img, item.res_img)}></button>
                                }
                                {/*{albumName === "videos" && download === "yes" &&*/}
                                {/*<button className="ico-lib-v2 download" title="Download"*/}
                                {/*        onClick={(e) => forceDownload(contentPath + item.res_img, item.res_img)}></button>*/}
                                {/*}*/}
                                </div>

                        </p>
                    )}
                )}
            </React.Fragment>
        )
    else
        return null

}


class AttachmentBox extends Component {

    constructor(props) {
        super(props);
        this.getAttachmentPath = this.getAttachmentPath.bind(this);
    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(getFilePath(type)) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'video' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }
    render() {
        let index = this.props.index;
        let item = this.props.item;
        let iconClass = CustomFunctions.getFileExtension(item.filename);
        let contentPath = this.getAttachmentPath(item.filename);
        var albumName;
        if (isImage(item.filename)) {
            albumName = 'images'
        } else if (isVideo(item.filename)) {
            albumName = 'videos'
        } else {
            albumName = 'documents'
        }
        ////console.log(this.props.taskPermission.filter(permission=>permission.action==="Download")[0] !==undefined ? this.props.taskPermission.filter(permission=>permission.action==="Download")[0].action_permission:"")
        let download=this.props.taskPermission.filter(permission=>permission.action==="Download")[0] !==undefined ? this.props.taskPermission.filter(permission=>permission.action==="Download")[0].action_permission:""

        return (

            <li className="active" key={index}>
                <a href="javaScript:void(0)" className="attach-click"
                   // onClick={(e)=> (albumName === "videos" && download==="yes" || albumName !== "videos") ? forceDownload(contentPath + item.filename, item.filetitle):""}
                   onClick={(e)=> (isDocument(item.filename)) ?(download==="yes" ?forceDownload(contentPath + item.filename, item.filetitle):''): this.props.preview(contentPath + item.filename, item.filetitle,albumName,download)}
                   data-toggle="tooltip"
                   data-placement="bottom" data-original-title="">
                    <span className="attach-attach-thumb">
                        {iconClass !== 'image' &&
                        <div className={"format " + iconClass}>
                            {iconClass}
                        </div>
                        }
                        {iconClass === 'image' &&
                        <img src={contentPath+ item.filename} />
                        }
                    </span>
                </a>
                <div className={`attach-click-toggle extended-attatch`}>
                    {item.filetitle &&
                    <div className="file-info-previewer">
                        <span className="file-info-name">{item.filetitle}</span>
                    </div>
                    }
                    {previewModalFormat(item.filename) &&
                    <>
                        <div className="file-info-option">
                        <button className="ico-lib-v2 preview-file" title={item.filetitle} onClick= {(previewModalFormat(item.filename)) ? ((e) => this.props.preview(contentPath + item.filename, item.filetitle,albumName,download)) : ((e) => forceDownload(contentPath + item.filename, item.filetitle))} data-toggle="tooltip" >
                        </button>

                        </div>
                    </>
                    }
                    {albumName === "documents" && isDocument(item.filename) && download==="yes"&&
                    <button onClick={(e) => forceDownload(contentPath + item.filename, item.filetitle)}
                            className="ico-lib-v2 download-file">
                    </button>
                    }
                    {/*{albumName === "videos" && download==="yes"&&*/}
                    {/*<button onClick={(e) => forceDownload(contentPath + item.filename, item.filetitle)}*/}
                    {/*        className="ico-lib-v2 download-file">*/}
                    {/*</button>*/}
                    {/*}*/}

                </div>
            </li>

        );
    }
}
class ResourceBox extends Component {

    constructor(props) {
        super(props);
        this.getAttachmentPath = this.getAttachmentPath.bind(this);
    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(getFilePath(type)) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'video' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }
    render() {
        let index = this.props.index;
        let item = this.props.item;
        //console.log(item)
        let iconClass = CustomFunctions.getFileExtension(item.res_img);
        let contentPath = this.getAttachmentPath(item.res_img);
        var albumName;
        if (isImage(item.res_img)) {
            albumName = 'images'
        } else if (isVideo(item.res_img)) {
            albumName = 'videos'
        } else {
            albumName = 'documents'
        }
        ////console.log(this.props.taskPermission.filter(permission=>permission.action==="Download")[0] !==undefined ? this.props.taskPermission.filter(permission=>permission.action==="Download")[0].action_permission:"")
        let download=this.props.taskPermission.filter(permission=>permission.action==="Download")[0] !==undefined ? this.props.taskPermission.filter(permission=>permission.action==="Download")[0].action_permission:""

        return (

            <li className="active" key={index}>
                <a href="javaScript:void(0)" className="attach-click"
                   // onClick={(e)=> (albumName === "videos" && download==="yes" || albumName !== "videos") ? forceDownload(contentPath + item.res_img, item.res_img):""}
                   onClick={(e)=> (isDocument(item.res_img)) ?(download==="yes"? forceDownload(contentPath + item.res_img, item.res_img):''):this.props.preview(contentPath + item.res_img, item.title,albumName,download,item)}
                   data-toggle="tooltip"
                   data-placement="bottom" data-original-title="">
                    <span className="attach-attach-thumb">
                        {iconClass !== 'image' &&
                        <div className={"format " + iconClass}>
                            {iconClass}
                        </div>
                        }
                        {iconClass === 'image' &&
                        <img src={contentPath+ item.res_img} />
                        }
                    </span>
                </a>
                <div className={`attach-click-toggle extended-attatch`}>
                    {item.title &&
                    <div className="file-info-previewer">
                        <span className="file-info-name">{item.title}</span>
                    </div>
                    }
                    {previewModalFormat(item.res_img) &&
                    <>
                        <div className="file-info-option">
                        <button className="ico-lib-v2 preview-file" title={item.filetitle} onClick= {(previewModalFormat(item.res_img)) ? ((e) => this.props.preview(contentPath + item.res_img, item.title,albumName,download,item)) : ((e) => forceDownload(contentPath + item.res_img, item.res_img))} data-toggle="tooltip" >
                        </button>

                        </div>
                    </>
                    }
                    {albumName === "documents" && isDocument(item.res_img) && download==="yes" &&
                    <button onClick={(e) => forceDownload(contentPath + item.res_img, item.res_img)}
                            className="ico-lib-v2 download-file">
                    </button>
                    }
                    {/*{albumName === "videos" && download==="yes"&&*/}
                    {/*<button onClick={(e) => forceDownload(contentPath + item.res_img, item.res_img)}*/}
                    {/*        className="ico-lib-v2 download-file">*/}
                    {/*</button>*/}
                    {/*}*/}
                </div>
            </li>

        );
    }
}
class AssigneeBox extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        let index = this.props.index;
        let assignee = this.props.assignee;



        return (

            <div className="assigned-tau-block single-user" key={'assignee'+assignee}>
                <div className="tau">
                    <span className="tau-img">
                        <img
                            src={USRIMG_PATH + assignee.assignee_img_name}/>
                    </span>
                    <span className="tau-name">{assignee.assignee_name}</span>
                </div>
            </div>
        );
    }
}
class ActivityBox extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(this.props.assignee.reassign === 'yes') {
            this.props.loadSubTask(this.props.assignee.masterid);
        }
    }

    render() {
        let index = this.props.index;
        let assignee = this.props.assignee;



        return null;
    }
}
class TimeLogForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timeLogValues: [{ rawdate: '', hour : '' , min : '', date: moment().format("YYYY-MM-DD") }]
        }
    }
    componentDidMount() {

        // this.setState({ timeLogValues: [{ rawdate: new Date(), hour : '' , min : '' }] });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.timeLogValues !== this.state.timeLogValues) {
            this.props.getTimeLogEntries(this.state.timeLogValues);
        }
    }

    handleDateChange(date, i){

        let timeLogValues = [...this.state.timeLogValues];
        timeLogValues[i]['rawdate'] = date;
        timeLogValues[i]['date'] =  formatDate(date);

        this.setState({
            timeLogValues : timeLogValues
        });
        document.getElementById('date'+i).classList.remove('val-error');

    }

    handleTimeLogChange(i, event) {

        var fieldId = event.target.id;
        var fieldName = event.target.name;
        var fieldValue = event.target.value;

        if(fieldValue !== '') {
            if( fieldValue > parseInt(event.target.max) ) {
                document.getElementById(fieldId).value = event.target.max;
                fieldValue=event.target.max
            }
            else{
                document.getElementById(fieldId).classList.remove('val-error');
            }

        }
        let timeLogValues = [...this.state.timeLogValues];

        if(timeLogValues[i][fieldName] === undefined) {
            timeLogValues[i][fieldName] = [];
        }
        timeLogValues[i][fieldName] = fieldValue;

        delete timeLogValues[i].value;
        this.setState({ timeLogValues });

    }

    addNewLog() {
        this.setState(prevState => ({
            timeLogValues: [...prevState.timeLogValues, { rawdate: '', hour : '', min : '' }]
        }));
    }

    removeLog(i) {
        let timeLogValues = [...this.state.timeLogValues];
        if(timeLogValues.length > 1) {
            timeLogValues.splice(i, 1);
            this.setState({ timeLogValues });
        }

    }



    render() {
        return (
            <>
                {this.state.timeLogValues.map((item, i) => (
                    <div key={i} className="row-list">
                        <div className="time-log-items one-third">
                            <div className="input-log-time date">
                                <DatePickerBox id={"date" + i} handleDateChange={this.handleDateChange.bind(this)}
                                               startDate={item['rawdate']} index={i}  maxDate={new Date()}
                                               placeholderText={`Work Log Date`}
                                />
                            </div>
                        </div>
                        <div className="time-log-items one-third">
                            <div className="input-log-time">
                                <input
                                    type="number"
                                    name="hour"
                                    id={"hour" + i}
                                    min="0"
                                    max="23"
                                    className="log-time-input"
                                    placeholder="Hours"
                                    onChange={e => this.handleTimeLogChange(i, e)}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="time-log-items one-third">
                            <div className="input-log-time addition">
                                <input
                                    type="number"
                                    name="min"
                                    id={"min" + i}
                                    min="0"
                                    max="59"
                                    className="log-time-input"
                                    placeholder="Minutes"
                                    onChange={e => this.handleTimeLogChange(i, e)}
                                />
                            </div>
                        </div>
                        <div className="log-addition">
                            {this.state.timeLogValues.length !== 1 &&
                            <input
                                type="button"
                                value="-"
                                onClick={() => this.removeLog(i)}
                                className="log-addition-link"
                            />
                            }
                           {(this.state.timeLogValues.length-1) === i &&
                            <input type="button" className="log-addition-link"
                                value="+" onClick={() => this.addNewLog()}/>
                            }
                        </div>

                    </div>
                ))}
            </>
        )
    }
}
class TimeLogList extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let index = this.props.index;
        let log = this.props.log;
        let timeLog=parseInt(log.log_hours !==""?(log.log_hours * 60):0) + parseInt(log.log_mins !==""?log.log_mins:0)

        return (
            <div className="time-log-count" key={index}>
                <div className="time-log-sub-list-left">
                    <p> {DateFormatter(log.log_date,false,true)}</p>
                </div>
                <div className="time-log-sub-list-right">
                    <p>TIME TAKEN <span>{timeConvert(timeLog)} </span>(HR : MIN)
                    </p>
                </div>
            </div>
        );
    }
}

function FormTodoTile(props) {

    let cid = props.item.cid;

    let selected = props.combinedFormIds.includes(cid.toString()) ? "selected" : "";

    //console.log(cid)


    return(
        <div className="content-section  form-list-item " data-id={cid} data-action="filter" onClick={ (e) => props.selectForm(props.item)}>
            <div  className={`content-click ${selected} `}>

                <div className="section-info-main">
                    <div className="section-info"><h5>{atob(props.item.title)}</h5></div>
                </div>
            </div></div>
    );
}

function ResourceTodoTile(props) {


    let selected = props.combinedResourceIds.includes(props.item.resource_id) && "selected" ;
    let item_cls  = 'document';
    if(props.item.res_type === '1') {
        item_cls  = 'image';
    }
    else if(props.item.res_type === '2') {
        item_cls  = 'video';
    }else if(props.item.res_type === '3') {
        item_cls  = 'document';
    }else if(props.item.res_type === '4') {
        item_cls  = 'audio';
    }else if(props.item.res_type === '5') {
        item_cls  = 'secured-video';
    }

/*
//console.log(combinedResIds)
*/

    return(
        <div className="content-section  form-list-item " data-action="filter" title={props.item.title} onClick={ (e) => props.selectResource(props.item)}>
            <div  className={`content-click ${selected} `}>

                <div className="section-info-main">
                    <div className={`section-info ${item_cls}`}><h5>{props.item.title}</h5></div>
                </div>
            </div></div>
    );
}

// ............New Design Components...................

function TaskDetailActivities(Props) {

    return(
        <React.Fragment>

            <div className="activities-log-wrapper">
                {Props.todologdata.map((item,index) =>
                    <div className="ind-activities-log">
                        <div className="activities-log-left">
                            <img src={USRIMG_PATH+item.creator_img} />
                        </div>
                        <div className="activities-log-right">
                            <div className="activities-log-right-top">
                                <p> {DateFormatter(item.crtdate,true,true)}</p>
                                <h5><span className="user-highlight">{item.creator}</span> {item.activity}</h5>
                            </div>
                            {item.content &&
                                <div className="activities-log-right-box">
                                    <p>{DateFormatter(item.crtdate,true,true)}</p>
                                    <h5>{atob(item.content)}</h5>
                                    {/*<p>2 Replies</p>*/}
                                </div>
                            }

                        </div>
                    </div>
                )}

            </div>


        </React.Fragment>

    )

}

function TaskDetailLog(Props) {
    return(
        <React.Fragment>


            <div className="split-task-header-container">
                {Props.logpaneldata.length > 0 &&
                <h5 className="split-task-header">Assigned Users ({Props.logpaneldata.length})</h5>
                }
                {Props.logpaneldata.length === 0 &&
                <h5 className="split-task-header">No log added</h5>
                }
                {((Props.list.createuser !== getProfile().id || Props.list.assigntype === 'self') && (Props.share[0] !== undefined && Props.share[0].share !== "yes")) && (Props.list.status !== "Done") &&
                <a href="javascript:void(0);" onClick={(e) => {Props.showtimelogModal(Props.list.masterid)}} data-toggle="modal" data-target="#timeLog">+ Add Time</a>
                }
            </div>
            {Props.logpaneldata.map((assignee,index) =>
                <div className="ind-task-added-user-list">
                    <div className="ind-added-user-list-left">
                        <div className="ind-added-user-list-left-thumb"> <img src={USRIMG_PATH+assignee.assignee_img_name} /></div>
                        <div className="ind-added-user-list-left-info">
                            <h5>{assignee.assigneeName}</h5>
                            <p> TOTAL TIME TAKEN <span
                                className="user-highlight">{timeConvert(assignee.totaltime)}</span> (HR:MIN)</p>
                            {assignee.logs.map((log,index) => {
                                //////console.log(assignee.logs)
                                let timeLog=parseInt(log.log_hours !==""?(log.log_hours * 60):0) + parseInt(log.log_mins !==""?log.log_mins:0)

                                    return <p
                                        key={`assignee=time-log` + index}>  {DateFormatter(log.log_date, false, true)} :&nbsp;{timeConvert(timeLog)}
                                        {/*{log.log_hours>0 ? log.log_hours : '00'} : {log.log_mins>0 ? log.log_mins : '00'}*/}

                                    </p>
                                }
                            )}
                        </div>
                    </div>
                </div>
        )}

        </React.Fragment>

    )

}



function FormTodoTileSystemTask(props) {

    const [selected, selectForm] = useState(
        props.selected
    );

    React.useEffect(() => {
        props.selectForm(props.item, selected);
    }, [selected]);
//////////console.log(props)
    return(
        <div className="content-section  form-list-item " data-action="filter" onClick={ (e) => selectForm(selected === '' ? 'selected' : '')}>
            <a href="javascript:void(0)"  className={"content-click "+ selected}>
                <div className="section-image">{props.item.filename ?
                    <img src={FRMSIMG_PATH + props.item.filename} alt="no-image" /> :
                    <img src={Coverimage} alt="no-image" />
                }
                    <div className="checkbox-wrap">
                        <input type="checkbox" name="" className="chk-opt" id={props.item.cid}/>
                        <label className="chk-txt-opt"></label></div>
                </div>
                <div className="section-info-main">
                    <div className="section-info"><h5>{props.item.title}</h5></div>
                    <div className="section-info-icon">
                        <span className="ico-lib icon-form-task icon-style">{props.item.childs}</span>
                        <span className="ico-lib icon-form-share icon-style">{props.item.share}</span>
                    </div>
                </div>
            </a></div>
    );
}
class AddNewRelatedTask extends Component {
    constructor(props) {
        super(props);
        this.handleMyTaskChange=debounce(500, this.handleMyTaskChange);
    }

    handleMyTaskChange(e,value){
        this.props.handleMyTaskChange(e,value)
    }

    render() {
        return (
            <div className="modal bsy-modal x-large show" id="resourceModal" tabIndex="-1" role="dialog"
                 aria-labelledby="mngModal" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered modal-lg modal-lg--x" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title ico-lib ">Add New Related Task</h5>
                            <button className="ico-lib-list modal-close" data-dismiss="modal"
                                     aria-label="Close" onClick={this.props.showRelatedTask}/>
                        </div>
                        <div className="modal-body  modal-in-colored modal-body-heighted padding-zero">
                            <div className="mang-lbl-block mang-lbl-block-mod-in">
                                <div className="resource-filter-modal-header">
                                    <div className="lbl-panel-search-block">
                                        <input type="text"
                                               placeholder="Search Task..."
                                               className="lbl-search-box"
                                               onChange={(e) => this.handleMyTaskChange(e,e.target.value)}/>
                                        <span className="ico-lib lbl-search-ico"/>
                                    </div>
                                </div>
                                <div className="resource-filter-modal-body">
                                    <ul className="grid-list-switch res-modal">
                                        <li><a className="icon-list"/>
                                        </li>
                                        <li><a className="icon-grid active"/>
                                        </li>
                                    </ul>
                                    <div className="content-main-in" id="relatedTask">
                                        {this.props.myTaskLoader.loader &&
                                            <span className="Loader-holder" style={{'background':'transparent'}}>
                                                <LoaderSvg/>
                                            </span>
                                        }
                                        {this.props.myTaskListListing && this.props.myTaskListListing.map((list,index)=> {
                                            var curentDate = moment.utc().local().format('YYYY-MM-DD')
                                            var dueDate=moment.utc(list.duedate).local().format('YYYY-MM-DD')
                                            let Title = list.subject === '' ? atob(list.Title) : atob(list.subject);
                                            let username = list.createuser_name;
                                            let AssigneeuserName = username.split(" ");
                                            let due = list.duedate !== '0000-00-00 00:00:00'? Datecomparisondue(dueDate, curentDate,'task'):'';
                                            return(
                                                <React.Fragment>
                                                    <Card key={list.Title} className={`ind-task-list`}>
                                                        <Card.Body className={`ind-task-list-click`}>
                                                            <div className="ind-task-list-left">
                                                                <div className="select-chk-block" style={{display: "block"}}>
                                                                    <label className="control control-checkbox"
                                                                           data-action="organise">
                                                                        <input type="checkbox" className="inp-chk" onClick={(e) => this.props.selectTasks(e, list.masterid)}
                                                                               name={"task_" + list.masterid + "_checked"}
                                                                             checked={!!this.props.relatedTasks.includes(list.masterid)}/>
                                                                        <div className="control-indicator"/>
                                                                    </label>
                                                                </div>
                                                                <div className="task-user-block __usercard">
                                                                    <span className="task-user">{AssigneeuserName[0].toLowerCase()}</span>
                                                                    <span className="mobile-com-count">3</span>
                                                                </div>
                                                            </div>
                                                            <div className="ind-task-list-center">
                                                                {list.reassign === 'yes' && <span
                                                                    className="icon-in icon-forward task-forward-btn"></span>}
                                                                <span className="task-text">
                                                                {list.projectcode && list.projectcode.length > 0 &&
                                                                <span
                                                                    className={`project-code`}>{list.projectcode[0]} - </span>
                                                                }
                                                                    {(decodeURIComponent(escape(Title)))}
                                                            </span>
                                                            </div>
                                                            <div className="ind-task-list-right">
                                                                <div className="task-more-block">
                                                                    {list.has_attachemnt === 'yes' && <span
                                                                        className="more-ico icon-in icon-attachment"></span>}
                                                                </div>
                                                                <div className={`due-block ${moment(curentDate).isAfter(dueDate) ? 'due' : ''}`}  >  {due}</div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </React.Fragment>
                                            )}
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer  checklist-footer">
                            {this.props.relatedTasks.length > 0 &&
                            <span id="resourceCount">{this.props.relatedTasks.length} items selected</span>
                            }
                            <button type="button" className="general-btn grey" data-dismiss="modal" onClick={this.props.showRelatedTask}>Cancel
                            </button>
                            <button type="button" className="general-btn" id="fetchResources" onClick={(e) => this.props.addRelatedTask(e)}>Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function TaskInfo(Props) {
    console.log(Props.list)
    return(
        <React.Fragment>
                    {Props.list.duedate !== "0000-00-00 00:00:00" &&
                    <div className="each-task-tag no-icon">
                        <p>CREATED ON : {DateFormatter(Props.list.duedate, true, true).toUpperCase()}</p>
                    </div>
                    }
                    {Props.list.draft !== 'yes' && Props.list.duedate !== "0000-00-00 00:00:00" &&
                    <div className="each-task-tag no-icon">
                        <p>DUE ON: {DateFormatter(Props.list.duedate, true, true).toUpperCase()}</p>
                    </div>
                    }

                    <div className="each-task-tag no-icon">
                        <p>PRIORITY: {Props.list.priority.toUpperCase()}</p>                    
                    </div>

                    {Props.list.has_scheduled === 'yes' && Props.list.scheduleddate !== "0000-00-00 00:00:00" &&
                    <div className="each-task-tag no-icon">
                        <p>SCHEDULED ON: {DateFormatter(Props.list.scheduleddate, true, true).toUpperCase()}</p>                    
                    </div>
                    }

                    {Props.list.projectname !== '' &&
                    <div className="each-task-tag no-icon">

                        <p>PROJECT : {Props.list.projectname.toUpperCase()}<span>
                        {Props.list.projectinfo.length > 0 && Props.list.projectinfo[0].project_code !== null && Props.list.projectinfo[0].project_code !== '' ? '(' + Props.list.projectinfo[0].project_code + ')' : ''}</span></p>                    
                    </div>
                    }

                    {Props.list.projectinfo && Props.list.projectinfo[0] && Props.list.projectinfo[0].project_section && Props.list.projectinfo[0].project_section !== "" &&
                    <div className="each-task-tag no-icon">

                        <p>PROJECT TASK GROUP : {Props.list.projectinfo[0].project_section.toUpperCase()}</p>
                    </div>
                    }

                
        </React.Fragment>

    )

}
export {TaskDetailActivities,TaskDetailLog,AttachmentBox, ResourceBox, ResourceTodoTile,WorkTimeSection,HeaderBox,TaskOptionButton,TaskDetailUserInfo,TaskDateInfo,TaskStatusBar,TaskDetailContent,NavTab,TaskStatusButton, TaskDetailBox,UsersPanelTab,AttachmentPanelTab, ActivityBox, TimeLogForm, TimeLogList,ActivityPanel, IndividualAssigners, AttachmentPanel, ViewParentPanel, CollectionPanel,FilledFormDataPanel, FormTodoTile,FormTodoTileSystemTask,AddNewRelatedTask,TaskInfo}



