import { render } from 'react-dom';
import './index.css';
/**
 * Projection sample
 */
import * as React from "react";
import { MapsComponent, Inject, LayersDirective, LayerDirective, Bubble, BubblesDirective, BubbleDirective, MapsTooltip, Zoom } from '@syncfusion/ej2-react-maps';
import { SampleBase } from './sample-base';
import { internetUsers } from './map-data/population-data';
import * as data from './map-data/bubble-datasource.json';
import * as map from "./worldmap.json";
let shapeData = map;
let datasource = Object.values(data);
const SAMPLE_CSS = `
    .control-fluid {
		padding: 0px !important;
		width:"100%",height:"100%"
    }`;
 class BubblesMap extends SampleBase {
    bubbleRendering(args) {
        args.radius = args.data.value;
    }
     load=()=>{
     }
    render() {
        return (<div className='control-pane' >
                <style>
                    {SAMPLE_CSS}
                </style>
                <div className='control-section row' >
                    <div className='col-md-12' >

                            <MapsComponent
                                id="bubblemap"
                                loaded={this.onMapsLoad.bind(this)} load={this.load}
                                ref={m => this.mapInstance = m} useGroupingSeparator={true} format={"n"}
                                zoomSettings={{
                                    enable: false,
                                    horizontalAlignment: 'Near',
                                    toolBarOrientation: 'Vertical',
                                    toolbars: ['ZoomIn', 'ZoomOut', 'Reset'],
                                    pinchZooming: true
                                }}
                                bubbleRendering={this.bubbleRendering.bind(this)}

                            >
                                <Inject services={[Bubble, MapsTooltip, Zoom]}/>
                                <LayersDirective>
                                    <LayerDirective
                                        shapeData={shapeData}
                                        shapePropertyPath='name'
                                        shapeDataPath='name'
                                        dataSource={datasource}
                                        shapeSettings={{
                                            fill: '#E5E5E5'
                                        }}>
                                        <BubblesDirective>
                                            <BubbleDirective
                                                dataSource={internetUsers}
                                                visible={true}
                                                valuePath='value'
                                                colorValuePath='color'
                                                minRadius={3}
                                                maxRadius={70}
                                                opacity={0.8}
                                                tooltipSettings={{
                                                    visible: true,
                                                    valuePath: 'population',
                                                    template: '<div id="bubbletooltiptemplate" style="width: 165px;background: rgba(53, 63, 76, 0.90); opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding: 10px;border: 1px #abb9c6;border-radius: 4px;">' +
                                                        '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${name}</center></div>' +
                                                        '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' +
                                                        '<div><span style="font-size:13px;color:#cccccc">Rank : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${rank}</span></div>' +
                                                        '<div><span style="font-size:13px;color:#cccccc">Population : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${population}</span></div></div>',
                                                }}
                                            >
                                            </BubbleDirective>
                                        </BubblesDirective>
                                    </LayerDirective>
                                </LayersDirective>
                            </MapsComponent>

                    </div>

                    </div>
            </div>);
    }
    onMapsLoad(args) {
            let maps = document.getElementById('bubblemap');
            maps.setAttribute('title', '');

    };

     tooltipRender(args) {
         if (!args.options["data"]) {
             args.cancel = true;
         }
     }
}

export default BubblesMap;