import React, {Component} from 'react';
import {connect} from "react-redux";
import * as usersActions from "../../../actions/Users"
import {withRouter} from 'react-router';
import {getProfile} from "../../../utilities/AuthService";
import { ES_INDEX} from '../../../constants'
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import Toaster from "../../views/Toaster";

class RoleSettings extends Component {

    constructor(props) {
        super(props);

        this.state={
            offset:500,
            lastOffset:0,
            showDateFilter:false,
            fromdate:'',
            todate:'',
            moduleShow: false,
            urlShow: false,
            link:'',
            param:'',
            fieldid:'',
            indx: '',
            indices: '',
            search: '',
            group: 'All',
            Roles:[],
            Fields:[],
            rolesettings: [],
            UserDetails:[],
            toasterdata:{type:"",msg:"",show:false},
        }

        // this.clearFilter=this.clearFilter.bind(this);
    }
    componentDidMount() {

        this.props.getRoles();
        let collectionid = ES_INDEX + '10003' ;
        let param={
            from: 0,
            size: this.state.loadmorecount,
            search:'',
            "uid": getProfile().id,
            "usertype": getProfile().usertype,
            refer: 'entity',
            "dashboard":false,
            "facetrequired":false
        }
        this.props.EntityFieldDetails(collectionid, param)

        var data = {
            "userid" : getProfile().id,
            "search" :"",
            "team":"",
            "company":"",
            "reportees":"",
            "designation":"",
            "page": this.state.lastOffset + 1,
            "per_page": this.state.offset
        };

        this.props.allUsersPermissionRole(data);

        let paramet = {uid : getProfile().id,group : this.state.group, usertype : getProfile().usertype, searchtext : this.state.search, page: 1, per_page: this.state.offset, index: ES_INDEX,  managecollection : ''};
        this.props.searchEntityRole(paramet);

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        var beta = this;
        if(this.props.GetRoleEntityDetails !== prevProps.GetRoleEntityDetails) {

            this.setState({UserDetails:this.props.GetRoleEntityDetails.RoleEntityDetails,toasterdata:{type:"#3FA9F5",msg:"Settings loaded successfully....",show:true}})
        }

        if(this.props.GetRoleEntityFields.RoleEntityFields !== prevProps.GetRoleEntityFields.RoleEntityFields) {
            this.setState({Fields:this.props.GetRoleEntityFields.RoleEntityFields})
        }

        if(this.props.getAllRoles.allRoles !== prevProps.getAllRoles.allRoles){
            this.setState({rolesettings:this.props.getAllRoles.allRoles})
        }
        if(this.props.saveRoles.saveRoleset !== prevProps.saveRoles.saveRoleset){
            this.setState({loading:0})
            //  this.props.getRoles();

            this.setState({loading:0,toasterdata:{type:"green",msg:"Settings saved successfully.",show:true}})

        }
    }
    toasterClose=()=>{
        this.setState({toasterdata:{type:"",msg:"",show:false}})
    }


    handleInputChange=(e, indx, item)=>{
        let role=[...this.state.rolesettings]
        role[indx][e.target.name]=e.target.value
        this.setState({role,acindex:indx})
        if(e.target.name === "fieldvalue"){
            const select = e.target;
            role[indx]["fieldlabel"]=select.selectedOptions[0].text;
        }


        if(item && item === 'entity'){
            console.log(e.target.value)
            let collectionid = e.target.value;
            let param={
                from: 0,
                size: this.state.loadmorecount,
                search:'',
                "uid": getProfile().id,
                "usertype": getProfile().usertype,
                refer: 'entity',
                "dashboard":false,
                "facetrequired":false
            }
            this.props.RolecollectionFields(collectionid, param)
        }

    }

    saveRole=()=>{
        console.log(this.state.rolesettings)
        let rolesettings = this.state.rolesettings;
        let params={
            "uid": getProfile().id,
            "rolesettings":(rolesettings),
            "index":ES_INDEX
        }
        this.props.saveRolesettings(params)
    }



    render() {
        let Roles=this.state.rolesettings||[];
        let Fields=this.state.UserDetails||[];
        let Modules=this.props.listAllUserspermissionRoles.allUsersPermissionRole;
        let entityList = this.props.getAllEntityParams.searchRoles;
        return (
            <React.Fragment>
                {this.state.toasterdata.show &&
                <Toaster
                    toasterdata={this.state.toasterdata}
                    toasterClose={this.toasterClose}
                />
                }
                <div className="filter-all-wrapper team-settings">
                    <div className="filter-holder">
                        <div className="filter-left-block" id="defaultId">
                            <h5 className="count-header">Redirection URL Settings </h5>
                        </div>

                        <div class="filter-right-block form-detail-top-right align-equal-btn">
                            <button className="general-btn sm-btn" onClick={this.saveRole}>Save</button>
                        </div>

                    </div>
                </div>

                <div className="main-panel-holder has-filter user-lister-container">

                    {this.props.loader.loaderType === 'get-role' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                    }
                    {this.props.loader.loaderType === 'save-role' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                    }
                    {Roles.length === 0 &&
                    <div className='absolute-holder'>
                        <NoData
                            msg={`No Roles added yet`}

                        />
                    </div>
                    }
                    <div className="role-set-wrapper">
                        <div className="table-scrollable">

                            <table className="bsy-table bsy-team-set">
                                <tbody>

                                {this.state.rolesettings.map((list, index) =>
                                    <tr key={index}>
                                        
                                        <td>{list.role}</td>

                                        <td className="sm-wid"><div className="select-chk-block"
                                                                    style={{"display": "block"}}>
                                            <label className="control control-checkbox">Module
                                                <input type="radio" className={`inp-chk ${ list.urltype ==="module"?"active":"" }`}  name="urltype"
                                                       checked={list.urltype ==="module"}
                                                       value={`module`}
                                                       onClick={(e)=>this.handleInputChange(e, index)}
                                                />
                                                <div className="control-indicator"></div>
                                            </label>
                                        </div></td>
                                        <td className="sm-wid"><div className="select-chk-block" style={{"display": "block"}}>
                                            <label className="control control-checkbox">URL
                                                <input type="radio" className={`inp-chk ${ list.urltype ==="url"?"active":"" }`}  name="urltype"
                                                       checked={list.urltype ==="url"}
                                                       value={'url'}
                                                       onClick={(e)=>this.handleInputChange(e, index)}
                                                />
                                                <div className="control-indicator"></div>
                                            </label>
                                        </div></td>
                                        <td align="right">
                                            {list.urltype === 'module' &&
                                            <div className="select-box">
                                                <select value={list.moduleid} name={`moduleid`} onChange={(e)=>this.handleInputChange(e, index)}   >
                                                    <option value="">Select</option>
                                                    {Modules.map((item, index) =>
                                                        <option key={index} value={item.id}>{item.menu}</option>
                                                    )}
                                                </select>
                                            </div>
                                            }
                                            {list.urltype === "url" &&
                                            <div className="form-set-container">
                                                <div class="task-input-block">
                                                    <input type="text"  name={'url'}
                                                           placeholder={`Enter url`} value={list.url}
                                                           onChange={(e)=>this.handleInputChange(e, index)} className="form-control" />
                                                </div>


                                                <div className="select-box">

                                                    {entityList.length === 0 ?
                                                    <span className="Loader-holder">
                                                        <LoaderSvg/>
                                                    </span> :
                                                        <select value={list.params} name={`params`} onChange={(e)=>this.handleInputChange(e, index, 'entity')}   >
                                                            <option value="">Select</option>
                                                            {entityList.map((item, index) =>
                                                                <option onClick={(e)=>this.handleInputChange(e, index)} key={index} data-item={item} value={item._id}>{atob(item._source.name)}</option>
                                                            )}
                                                        </select>
                                                    }


                                                </div>


                                                <div className="select-box">
                                                    <select name={`fieldvalue`} value={list.fieldvalue}
                                                            onChange={(e) => this.handleInputChange(e, index)}>
                                                        <option value="">Select</option>
                                                        {Fields.length > 0 && Fields[0].collectedform.map((list, index) =>
                                                            <option key={index}
                                                                    value={list.masterid}>{list.label}</option>
                                                        )}
                                                    </select>
                                                </div>


                                            </div>
                                            }
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>



            </React.Fragment>


        );
    }
}




const mapStateToProps = state => ({
    loader:state.CommonReducer.loader,
    GetRoleEntityDetails : state.UsersReducer.GetRoleEntityDetails,
    GetRoleEntityFields : state.UsersReducer.GetRoleEntityFields,
    listAllUserspermissionRoles:state.UsersReducer.listAllusersPermissionRole,
    getAllRoles:state.UsersReducer.getAllRoles,
    getAllEntityParams:state.UsersReducer.getAllSearchEntityRoles,
    saveRoles:state.UsersReducer.saveRoles,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    loaderStatus:rootActions.commonActions.loaderStatus,
    activityTracking:rootActions.commonActions.activityTracking,
    EntityFieldDetails:usersActions.EntityFieldDetails,
    RolecollectionFields:usersActions.RolecollectionFields,
    allUsersPermissionRole:usersActions.allUsersPermissionRole,
    getRoles:usersActions.getRoles,
    searchEntityRole:usersActions.searchEntityRole,
    saveRolesettings:usersActions.saveRolesettings

})(RoleSettings));


