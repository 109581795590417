import React, {Component} from 'react';
import moment from "moment";
import {Datecomparison, formatDate} from "../../utilities/CustomFunctions";
import {getProfile} from "../../utilities/AuthService";
import OutsideAlerter from "./OutsideAlerter";


class ProjectTile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showOption : false
        }
        this.showOptions = this.showOptions.bind(this);
    }
    componentDidMount() {
    }

    showOptions(){
        this.setState({showOption : !this.state.showOption});
    }
    render() {
        var list = this.props.list ? this.props.list : '';
        var currentDate = moment().utc().local();
        var endDate = this.props.endDate;
        let description = decodeURIComponent(escape(atob(list.description)))
        return(
            <div className="ind-project-list" key={list.project_id}>
                <div className="project-list-header">
                    <h5 className="ico-lib-v2 energy">{list.portfolioName} / {list.programName}</h5>
                    {(getProfile().usertype === 'root' || getProfile().id === list.crtuser) &&
                    <div className="project-option-block">
                        <button className="ico-lib-v1 more-sym" onClick={() => this.showOptions()}
                                style={{pointerEvents: this.state.showOption === "true" ? "none" : ''}}></button>
                        {this.state.showOption &&
                        <OutsideAlerter status={this.state.showOption} Triggered={() => this.showOptions()}>
                            <ul className="panel-option" style={{display: "block"}}>
                                <li>
                                    <a className="panel-option-click ico-lib form-edit-ico"
                                       onClick={(e) => this.props.AddmodalOpen(e, list.project_id)}>Edit</a>
                                </li>
                                {list.projectStatus === "New" &&
                                <li>
                                    <a className="panel-option-click ico-lib delete-task-ico"  onClick={(e) => this.props.DelmodalOpen(e, list.project_id)}>Delete</a>
                                </li>
                                    }
                                <li>
                                    <a className="panel-option-click ico-lib-v1 dashboard-build-ico"  onClick={(e) => this.props.history.push('/dashboard/builder/dashboard/'+btoa(list.project_id))}>Dashboard</a>
                                </li>
                            </ul>
                        </OutsideAlerter>
                        }
                    </div>
                    }
                </div>
                <div className="project-list-body" onClick={(e) => this.props.history.push(((!this.props.hasDashAccess && this.props.hasCDashAccess)?'/project/details/cdashboard/':'/project/details/dashboard/' ) + btoa(list.project_id))}>
                    {/* <div className="project-list-tag-block">
                        {moment(currentDate).isAfter(endDate) ?
                            <span className="pj-tag">{Datecomparison(endDate, currentDate)}</span> :
                            <span className="pj-tag">Complete on {Datecomparison(endDate, currentDate)}</span>
                        }

                    </div> */}
                    <div className="project-list-desc-block">
                        <h5>{list.project_name}</h5>
                        <p dangerouslySetInnerHTML={{__html: ((((description.length > 90) ? (description.substr(0, 90)+'...') : description)))}}></p>
                    </div>
                </div>
                <div className="project-list-footer">
                    {/* <h5>{list.programName}</h5>
                    <p>Created By {list.creatorname} On {moment.utc(list.crtdate).format('MMM DD, YYYY')}</p> */}
                    <ul className="footer-info">
                        {list.projectcode && <li>Project ID: <span>{list.projectcode}</span></li>}
                        {list.phase !== '' && <li>Stage In: <span>{list.phase}</span></li>}
                        {list.milestoneName !== '' &&<li>{list.milestoneName}: <span>{moment.utc(list.milestoneDate).format('DD MMM YYYY')}</span></li>}
                    </ul>
                </div>
            </div>
        );
    }
}

export default ProjectTile;