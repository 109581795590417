import React, {Component,lazy, Suspense } from 'react';
import SimpleModal from "../../views/SimpleModal";
import LoaderSvg from "../../views/LoaderSVG";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as collectionActions from "../../../actions/Collections";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
class AddNotes extends Component   {
    constructor(props) {
        super(props)
        this.state={
            value:[]
        }


    }
    componentDidMount(){
        let Params = {
            "collection": atob(getUrlSegment(3)),
            "recordid": this.props.recordId,
            "page": "0",
            "per_page": "1"
            // "uid": getProfile().id,
        }
        this.props.listNotes(Params)
      this.setState({value:this.props.value.listAnn.length ===0?"":this.props.value.listAnn[0].message})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log( this.props.value.listAnn)
            if(this.props.value !== prevProps.value){
                this.setState({value:this.props.value.listAnn.length ===0?"":this.props.value.listAnn[0].message})
            }

    }
    changeNotes=(e)=>{

        this.setState({value:e.target.value})
        this.props.changeNotes(e.target.value,this.props.recordId,this.props.chatNotification)
    }

    render() {
        let message=this.state.value
console.log(this.props)
        return (
            <>
                {this.props.value.loader ?
                    <span className="Loader-holder">
                                 <LoaderSvg/>
                     </span>
                    :
                    <textarea name={`notes`} placeholder={`Enter notes here`}
                              value={message}
                              onChange={(e) => this.changeNotes(e)}>

                    </textarea>

                }
                </>
        )
    }
}
const mapStateToProps = state => ({
    listAnnotation : state.CollectionsReducer.listAnnotation,
});
export default  withRouter(connect(mapStateToProps, {
    listNotes:collectionActions.listNotes,
})(AddNotes));

