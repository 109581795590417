import {users} from '../services/users.services';
import {login} from '../utilities/AuthService';
import {entity} from "../services/entity.services";


export const listUsers = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'team-list',
        status: true

    });
    let listUsers = users.listUsers(params);
    listUsers.then(listUsers => {
        dispatch({
            type: 'LIST_USERS_DATA',
            listUsers: listUsers,
            searchParam:params

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'team-list',
            status: false

        });
    }).catch(function (error) {

        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'team-list',
            status: false

        });
    })


};
export const getallUsers = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'users-list',
        status: true

    });
    let listAllUsers = users.getAllUsers(params);
    listAllUsers.then(listAllUsers => {
        dispatch({
            type: 'LIST_ALLUSERS_DATA',
            listAllUsers: listAllUsers,
            searchParam:params

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'users-list',
            status: false

        });
    }).catch(function (error) {
        dispatch({
            type: "NET_WORK_ERROR",
            status:true,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'users-list',
            status: false

        });
    })



};

export const listmoreUsers = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'users-list',
        status: true

    });
    let listMoreUsers = users.getAllUsers(params);
    listMoreUsers.then(listMoreUsers => {
        dispatch({
            type: 'LIST_MORE_USERS_DATA',
            listMoreUsers: listMoreUsers,
            searchParam:params

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'users-list',
            status: false

        });
    }).catch(function (error) {
        //console.log(error);
    })
        .then(function () {
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType: 'team-list',
                status: false

            });
        });


};

export const listUsersDetail = (uid,index) => dispatch => {
    let listUsersDetail = users.listUsersDetail(uid,index);
    listUsersDetail.then(listUsersDetail => {
        dispatch({
            type: 'LIST_USERDETAIL',
            listUsersDetail: listUsersDetail,

        })
    }).catch(function () {
            dispatch({
                type: 'LIST_USERDETAIL',
                listUsersDetail: {data:{results:{data:[]}}}
            });
        });
};


export const listUsersStatics = (uid,intrval,index) => dispatch => {
    let listUsersStatics = users.listUsersStatics(uid,intrval,index);
    listUsersStatics.then(listUsersStatics => {
        dispatch({
            type: 'LIST_USERSTATICS',
            listUsersStatics: listUsersStatics,

        });

    })
};

export const listUsersActlog = (uid,usertype,limit,index) => dispatch => {
    let listUsersActlog = users.listUsersActlog(uid,usertype,limit,index);
    listUsersActlog.then(listUsersActlog => {
        dispatch({
            type: 'LIST_USERACTIVITYLOG',
            listUsersActlog: listUsersActlog,

        })
    })
};

export const listUsersActions = (params) => dispatch => {
    let listUsersActions = users.listUsersActions();
    listUsersActions.then(listUsersActions => {
        dispatch({
            type: 'LIST_USERACTIONS',
            listUsersActions: listUsersActions,

        })
    })
};
export const listUsersAllpermission = (params) => dispatch => {
    let listUsersAllpermission = users.listUsersAllpermission();
    listUsersAllpermission.then(listUsersAllpermission => {

        dispatch({
            type: 'LIST_USERALLPERMISSION',
            listUsersAllpermission: listUsersAllpermission,

        })
    })
};
export const listAllusersPermission = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'userPermission-list',
        status: true

    });
    let listAllusersPermission = users.usersmodulewisepermission(params);
    listAllusersPermission.then(listAllusersPermission => {

        dispatch({
            type: 'ALL_USERMODULEPERMISSION',
            listAllusersPermission: listAllusersPermission,
			searchParam:params			

        });
		    dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'userPermission-list',
            status: false

        });
    })
};
export const listRolemodulePermission = (params) => dispatch => {
    dispatch({
        type: 'ROLE_MODPERMSN_LOADER',
        loading: true

    });
    let listRolemodulePermission = users.listrolemodulepermission(params);
    listRolemodulePermission.then(listRolemodulePermission => {

        dispatch({
            type: 'ALL_ROLEMODULEPERMISSION',
            listRolemodulePermission: listRolemodulePermission

        });
        dispatch({
            type: 'ROLE_MODPERMSN_LOADER',
            loading: false

        });
    })
};
export const moreusersPermission = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'userPermission-list',
        status: true

    });
    let listAllusersPermission = users.usersmodulewisepermission(params);
    listAllusersPermission.then(listAllusersPermission => {

        dispatch({
            type: 'ALL_MODULEPERMISSION',
            listAllusersPermission: listAllusersPermission,
			searchParam:params			

        });
		    dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'userPermission-list',
            status: false

        });
    })
};
export const listUsersPermission = (params) => dispatch => {
    let listUsersPermission = users.listUsersPermission(params);
    listUsersPermission.then(listUsersPermission => {
        dispatch({
            type: 'LIST_USERPERMISSION',
            listUsersPermission: listUsersPermission,

        })
    })
};
export const listAllUsers = () => dispatch => {
    let listUsers = users.listAllUsers();
    listUsers.then(listUsers => {
        dispatch({
            type: 'LIST_ALL_USERS_DATA',
            listUsers: listUsers,

        })
    })
};

/* Notification  */
export const notificationInfo = (params) => dispatch => {
    let notificationInfo = users.notificationInfo(params);
    notificationInfo.then(notificationInfo => {
        dispatch({
            type: 'NOTIFICATION_INFO',
            notificationInfo: notificationInfo,

        })
    })
};
export const clearNotifications = (params) => dispatch => {
    let clearNotifications = users.clearNotifications(params);
    clearNotifications.then(clearNotifications => {
        dispatch({
            type: 'CLEAR_NOTIFICATION',
            clearNotifications: clearNotifications,

        })
    })
};
export const userModuleUpdations = (params) => dispatch => {
    let userModuleUpdations = users.userModuleUpdations(params);
    userModuleUpdations.then(userModuleUpdations => {
        dispatch({
            type: 'MODULE_PERMISSIONUPDATION',
            userModuleUpdations: userModuleUpdations,

        })
    })
};

export const updatePassword = (params) => dispatch => {
    let updatePasswordInfo = users.updatePasswordInfo(params);
    updatePasswordInfo.then(updatePasswordInfo => {
        dispatch({
            type: 'PASSWORD_INFO',
            updatePasswordInfo: updatePasswordInfo,

        })
    })
};

export const profileUpdate = (params) => dispatch => {
    let profileUpdate = users.profileUpdate(params);
    profileUpdate.then(profileUpdate => {
        //////////console.log(profileUpdate.data.data)
        if(profileUpdate.data.data !== ''){

          //  document.cookie = "teamls="+profileUpdate.data.data+"domain=.bsynapse.com"+";path=/";
            // login(profileUpdate.data.data).then(res => {
            //     window.location = "/";
            // })
        }
      else{
            window.location = "/";
        }
        // dispatch({
        //     type: 'PASSWORD_INFO',
        //     updatePasswordInfo: updatePasswordInfo,
        //
        // })
    })
};
export const profilepicUpdate = (userdate) => dispatch => {
    let profilepicUpdate = users.profilepicUpdate(userdate);
    profilepicUpdate.then(profilepicUpdate => {
        //////////console.log(profilepicUpdate.data.data)
        if(profilepicUpdate.data.data !== ''){
            var d = new Date();
            d.setTime(d.getTime());
            var expires = "expires="+d.toUTCString();
           // document.cookie = "teamls="+profilepicUpdate.data.data+";domain=bsynapse.com;path=/;expires=" + expires;
            window.location = "/";
            // login(profilepicUpdate.data.data).then(res => {
            //     window.location = "/";
            // })
        }
        else{
           window.location = "/";
        }

    })
};


export const helpInfo = (params) => dispatch => {
    let helpInfo = users.helpInfo(params);
    dispatch({
        type: 'HELP_INFO_CONTENT_LOADER',
        loading:true,

    });
    helpInfo.then(helpInfo => {
        dispatch({
            type: 'HELP_INFO',
            helpInfo: helpInfo,
        });
        dispatch({
            type: 'HELP_INFO_CONTENT_LOADER',
            loading:false,

        });
    })
};

export const saveUserPermissions = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType:'user-details',
        status:true
    });
    let userPermissionAdded = users.saveUserPermissions(params);
    userPermissionAdded.then(userPermissionAdded => {
        dispatch({
            type: 'SAVE_USER_PERMISSIONS',
            userPermissionAdded: userPermissionAdded
        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType:'user-details',
            status:false
        });
    })
};
export const listUserModulePermission = (params) => dispatch => {
    let listUsersPermission = users.listUsersPermission(params);
    listUsersPermission.then(listUsersPermission => {
        dispatch({
            type: 'LIST_USER_MODULE_PERMISSION',
            listUsersPermission: listUsersPermission,

        })
    })
};
export const deleteUser = (params) => dispatch => {
    let deleteUser = users.deleteUser(params);
    deleteUser.then(deleteUser => {
        dispatch({
            type: 'DELETE_USER',
            deleteUser: deleteUser,

        })
    })
};
export const savePlatformhits = (params) => dispatch => {
    let savePlatformhits = users.savePlatformhits(params);
    savePlatformhits.then(savePlatformhits => {
        dispatch({
            type: 'PLATFORM_HITS',
            savePlatformhits: savePlatformhits,

        })
    })
};

export const userTimespent = (params) => dispatch => {
    let userTimespent = users.userTimespent(params);
    userTimespent.then(userTimespent => {
        dispatch({
            type: 'TIME_SPENT',
            userTimespent: userTimespent,

        })
    })
};

export const listUserInfo = (uid,profileId) => dispatch => {
    let listUserInfo = users.listUserInfo(uid,profileId);
    dispatch({
        type: 'RESET_USERINFO',
    });
    dispatch({
        type: 'RESET_GROUP',
    });

    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType:'user-details',
        status:true
    });
    listUserInfo.then(listUserInfo => {
        dispatch({
            type: 'LIST_USERINFO',
            listUserInfo: listUserInfo,

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType:'user-details',
            status:false
        });
    })
};

export const listTodoUsers = (params) => dispatch => {
    dispatch({
        type: 'USER_LOADING',
        loading : 1
    });
    let listUsers = users.listTodoUsers(params);
    listUsers.then(listUsers => {

        let userList = listUsers.data.results.data.users;
        /*userList = userList.map(item=> { item.uniqueId = item.userType.toLowerCase()+"_"+item.user_id; return item;});
        let organizationList = [];
        let department = [];
        let group = [];
        if(!params.userOnly) {
            organizationList = listUsers.data.results.data.organization;
            department = listUsers.data.results.data.department;
            group = listUsers.data.results.data.group;
            organizationList = organizationList.map(item=> {return {firstName : item.name, lastName : '', designation : '', department : '', userType :'organization', user_id :item.id, uniqueId : "organization_"+item.id  }});
            department = department.map(item=> {return {firstName : item.dep_name, lastName : '', designation : '', department : '', userType :'department', user_id :item.id , uniqueId : "department_"+item.id }});
            group = group.map(item=> {return {firstName : item.group_name, lastName : '', designation : '', department : '', userType :'group', user_id :item.group_id, uniqueId : "group_"+item.group_id }});

        }


        let finalList = [].concat.apply([], [userList,organizationList,department,group]);*/


        dispatch({
            type: 'LIST_TODO_USERS_DATA',
            listUsers: userList,
            search : params.search,
        });

        dispatch({
            type: 'USER_LOADING',
            loading : 0
        });
    })

};

export const listGroup = (params) => dispatch => {
    let listGroups = users.listgroups(params);
    dispatch({
        type: 'RESET_GROUP',
    });
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType:'usergroup-list',
        status:true
    });
    listGroups.then(listGroups => {
        dispatch({
            type: 'LIST_USERGROUPS',
            listGroups: listGroups,

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType:'usergroup-list',
            status:false
        });
    })
        .catch(error => {
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType:'usergroup-list',
                status:false
            });

        });
};
export const saveGroup = (params) => dispatch => {
    let saveGroup = users.saveGroup(params);
    saveGroup.then(saveGroup => {
        dispatch({
            type: 'SAVE_USERGROUP',
            saveGroup: saveGroup,

        })
    })
};
export const allCompanies = (params) => dispatch => {
    let allCompanies = users.listallcompanies(params);
    allCompanies.then(allCompanies => {
        dispatch({
            type: 'LIST_ALLCOMPANIES',
            allCompanies: allCompanies
        })
    })
        .catch(function (error) {
            dispatch({
                type: 'LIST_ALLCOMPANIES',
                allCompanies: []
            })
        })
};
export const allDesignations = (params) => dispatch => {
    let allDesignations = users.listallDesignations(params);
    allDesignations.then(allDesignations => {
        dispatch({
            type: 'LIST_ALLDESIGNATIONS',
            allDesignations: allDesignations
        })
    })
        .catch(function (error) {
            dispatch({
                type: 'LIST_ALLDESIGNATIONS',
                allDesignations: []
            })
        })
};
export const allReportees = (params) => dispatch => {
    let allReportees = users.listallReportees(params);
    allReportees.then(allReportees => {
        dispatch({
            type: 'LIST_ALLREPORTEES',
            allReportees: allReportees
        })
    })
        .catch(function (error) {
            dispatch({
                type: 'LIST_ALLREPORTEES',
                allReportees: []
            })
        })
};
export const UserStatistics = (params) => dispatch => {
    let UserStatistics = users.UserStatisticsDetail(params);
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'user-statistics',
        status: true

    });
    UserStatistics.then(UserStatistics => {
        dispatch({
            type: 'LIST_USER_STATISTICS',
            UserStatistics: UserStatistics
        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'user-statistics',
            status: false

        });
    })
};
export const addmoreGroupUsers = (params) => dispatch => {
    let addmoreusers = users.addmoregroupusers(params);
    addmoreusers.then(addmoreusers => {
        dispatch({
            type: 'SAVE_MOREGROUPUSERS',
            moreUsers: addmoreusers,

        })
    })
};
export const getgroupDetails = (params) => dispatch => {
    let groupdetails = users.groupdetails(params);
    dispatch({
        type: 'RESET_GROUPINFO',
    });
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType:'usergroup-details',
        status:true
    });
    groupdetails.then(groupdetails => {
        dispatch({
            type: 'GET_GROUPINFO',
            groupdetails: groupdetails,

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType:'usergroup-details',
            status:false
        });
    })
        .catch(error => {
            dispatch({
                type: "NET_WORK_ERROR",
                status:true,

            })
            dispatch({
                type: 'LOADER_UPDATE_STATUS',
                loaderType:'usergroup-details',
                status:false
            });

        });
};
export const editGroup = (params) => dispatch => {
    let editGroup = users.editGroup(params);
    editGroup.then(editGroup => {
        dispatch({
            type: 'EDIT_USERGROUP',
            editGroup: editGroup,

        })
    })
};
export const deleteGroup = (params) => dispatch => {
    let deleteGroup = users.deleteGroup(params);
    deleteGroup.then(deleteGroup => {
        dispatch({
            type: 'DELETE_USERGROUP',
            deleteGroup: deleteGroup

        })
    })
};
export const getModeration = (params) => dispatch => {
    let getmoderation = users.getModeration(params);
    getmoderation.then(getmoderation => {
        dispatch({
            type: 'GET_MODERATION',
            getmoderation: getmoderation
        })
    })
        .catch(function (error) {
            dispatch({
                type: 'GET_MODERATION',
                getmoderation: []
            })
        })
};
export const sendEmailTousers = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'send-email',
        status:true

    });
    let sendEmailTousers = users.sendEmailTousers(params);
    //console.log(sendEmailTousers)
    sendEmailTousers.then(sendEmailTousers => {
        dispatch({
            type: 'SEND_USEREMAIL',
            sendEmailTousers: sendEmailTousers,

        })
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'send-email',
            status: false

        });
    })
};

export const EntityFieldDetails = (id, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'role-collection-details',
        status:true,
        loading : 1

    });
    let listRolecollectionDetails = users.listRolecollectionDetails(id, params);
    listRolecollectionDetails.then(listRolecollectionDetails => {

        dispatch({
            type: 'ROLE_ENTITY_DETAIL',
            listEntityFieldDetails: listRolecollectionDetails,
        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'role-collection-details',
            status:false

        });
    })
};
export const RolecollectionFields = (id, params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'role-collection-fields',
        status:true,
        loading : 1

    });
    let listRolecollectionFields = users.listRolecollectionFields(id, params);
    listRolecollectionFields.then(listRolecollectionFields => {

        dispatch({
            type: 'ROLE_ENTITY_FIELD',
            listRolecollectionFields: listRolecollectionFields,
        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'role-collection-fields',
            status:false

        });
    })
};

export const allUsersPermissionRole = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'userPermission-role',
        status: true

    });
    let listAllusersPermissionRole = users.userModulePermissionRole();
    listAllusersPermissionRole.then(listAllusersPermissionRole => {

        dispatch({
            type: 'ALL_USER_MODULE_PERMISSION_ROLE',
            listAllusersPermissionRole: listAllusersPermissionRole,
            searchParam:params

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'userPermission-role',
            status: false

        });
    })
};

export const getRoles = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'get-role',
        status: true

    });

    let listAllRoles = users.listAllRole();

    listAllRoles.then(listAllRoles => {
        dispatch({
            type: 'ALL_ROLES',
            listAllRoles: listAllRoles

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'get-role',
            status: false

        });
    })
};

export const searchEntityRole = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'search-role',
        status: true

    });
    let searchEntityRoles = users.searchEntityRoles(params);
    searchEntityRoles.then(searchEntityRoles => {

        dispatch({
            type: 'SEARCH_ROLES',
            searchEntityRoles: searchEntityRoles

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'search-role',
            status: false

        });
    })
};

export const saveRolesettings = (params) => dispatch => {
    dispatch({
        type: 'LOADER_UPDATE_STATUS',
        loaderType: 'save-role',
        status: true,
        loading: 1

    });
    let saveRolesettings = users.saveRolesettings(params);
    saveRolesettings.then(saveRolesettings => {

        dispatch({
            type: 'SAVE_ROLES',
            saveRolesettings: saveRolesettings

        });
        dispatch({
            type: 'LOADER_UPDATE_STATUS',
            loaderType: 'save-role',
            status: false

        });
    })
};

export const saveRolemodulePermission = (params) => dispatch => {
    let saveRolemodulePermission = users.saveRolemodulePermission(params);
    dispatch({
        type: 'TEAM_LOADER',
        loading:true,

    });
    saveRolemodulePermission.then(saveRolemodulePermission => {
        dispatch({
            type: 'SAVE_ROLEMOD_PERMISSION',
            saveRolemodulePermission: saveRolemodulePermission
        });
        dispatch({
            type: 'TEAM_LOADER',
            loading:false,

        });

    })
};

export const getOrganizationUsers = (params, masterid) => dispatch => {

    let orgUsers = users.organizationUsers(params);

    orgUsers.then(orgUsers => {
        console.log(orgUsers.data.results.data);
        let userData = orgUsers.data.results.data || [];
        userData = userData.map( item => {
            item.recordid = item.user_id;
            item.value = item.firstName + " " + item.lastName;
            return item;
        })
        dispatch({
            type: 'GET_ORG_USER_LIST',
            ['userList_'+masterid]: userData,
            masterid : masterid
        });
    })
};
