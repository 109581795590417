import React from 'react';
import {Form} from "react-bootstrap";
const SingleInput = (props) => (
	<Form.Group >
		<Form.Control className={props.className}  type={props.inputType} name={props.name} placeholder={props.placeholder} defaultValue={props.content} onChange={()=>props.controlFunc(props.name)}/>
	</Form.Group>

);


export default SingleInput;