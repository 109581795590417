import {Dashboard} from '../services/dashboard.services'

export const listallstatics = (params) => dispatch => {
    dispatch({
        type: 'MULTI_LOADER_UPDATE_STATUS',
        loaderType: 'dashboard-userstat',
        status: true

    });
    let listallstatics = Dashboard.listUserstatics(params);
       listallstatics.then(listallstatics => {
        dispatch({
            type: 'LIST_All_STATICS',
            listallstatics:listallstatics

        });
           dispatch({
               type: 'MULTI_LOADER_UPDATE_STATUS',
               loaderType: 'dashboard-userstat',
               status: false

           });
    })

};
export const listDashboardStatistics = (utype, uid) => dispatch => {
    dispatch({
        type: 'MULTI_LOADER_UPDATE_STATUS',
        loaderType: 'dashboard-userstat',
        status: true

    });
    let listdashboardstatics = Dashboard.listDashboardStatics(utype, uid);
    listdashboardstatics.then(listdashboardstatics => {
        dispatch({
            type: 'LIST_All_DASHBOARD_STATICS',
            listdashboardstatics:listdashboardstatics

        });
           dispatch({
               type: 'MULTI_LOADER_UPDATE_STATUS',
               loaderType: 'dashboard-userstat',
               status: false

           });
    })

};
export const listCalendarTasks = (params) => dispatch => {
    dispatch({
        type: 'MULTI_LOADER_UPDATE_STATUS',
        loaderType: 'dashboard-calendar',
        status: true

    });
    let dueTasks = Dashboard.listCalendarTasks(params);
    dueTasks.then(dueTasks => {
        dispatch({
            type: 'LIST_PENDING_TASKS_CNT',
            dueTasks: dueTasks.data.results.duedate,
            status: params.status

        });
        dispatch({
            type: 'MULTI_LOADER_UPDATE_STATUS',
            loaderType: 'dashboard-calendar',
            status: false

        });
    })
};


export const listWeeklyStatistics = (params) => dispatch => {
    dispatch({
        type: 'MULTI_LOADER_UPDATE_STATUS',
        loaderType: 'dashboard-weeklystat',
        status: true

    });
    let weeklyStat = Dashboard.listWeeklyStat(params);
    weeklyStat.then(weeklyStat => {
        dispatch({
            type: 'LIST_WEEKLY_STAT',
            weeklyStat: weeklyStat.data.results,

        });
        dispatch({
            type: 'MULTI_LOADER_UPDATE_STATUS',
            loaderType: 'dashboard-weeklystat',
            status: false

        });
    })
};
