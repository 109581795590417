import React, {Component} from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis,CartesianGrid, Tooltip, Area, Legend } from 'recharts';
function FeaturedBlock(props) {
////console.log(props)
    return (
        <div className="blue-lister">
            <div className="blue-list-header" style={{"background":props.areacolor}}>
                <div className="blue-list-header-left"><h5>{props.chartTitle && props.chartTitle.toUpperCase().charAt(0)}</h5></div>
                <div className="blue-list-header-right"><h5>{props.chartTitle}</h5></div>
                {props.subTitleEnabled &&
                <div className="blue-list-header-right"><h5>{props.subTitle}</h5></div>
                }
            </div>
            <div className="blue-list-body">
                <ul className="blue-single-list">
                    {props.featuredBlock.map((list,index)=>
                      <li key={`featured-block`+index}>{list.value}</li>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default FeaturedBlock;