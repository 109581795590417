import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";
import Modal from "../../views/Modal";
import AddNewNewsMedia from "./AddNewNewsMedia";
import {getProfile} from "../../../utilities/AuthService";
import {FilterAccordian,NewsMediaTile} from "./ProjectNewsMediaData";
import * as projectActions from "../../../actions/Project";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import * as taskActions from "../../../actions/ToDo";
import SimpleModal from "../../views/SimpleModal";
import {DateRange} from "react-date-range";
import moment from "moment";

class ProjectNewsMedia extends Component {

    constructor(props) {
        super(props);

        this.state={
            expandHeader:false,
            Addopen:false,
            openFilter:true,
            offset: 30,
            lastOffset: 0,
            search:'',
            clearList : false,
            loading:0,
            filterparam:'',
            filterOn:false,
            medialist:[],
            showOption:false,
            id:0,
            searchparams:'facebook'
        }
        this.expandHeader = this.expandHeader.bind(this);
        this.AddmodalOpen=this.AddmodalOpen.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);
        this.openFilterSection = this.openFilterSection.bind(this);
        this.getnewsmedia=this.getnewsmedia.bind(this);
        this.handleInputChange =this.handleInputChange.bind(this);
        this.showOptions = this.showOptions.bind(this);
        this.removeelements = this.removeelements.bind(this);
        this.showDateFilter = this.showDateFilter.bind(this);
        this.applyCustomDateRange = this.applyCustomDateRange.bind(this);
    }

    componentDidMount() {
        var projectId = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/newsmedia/'+projectId);
        this.getnewsmedia();
        const container = document.querySelector('.news-media-right');
        container.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        const container = document.querySelector('.news-media-right');
        container.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const container = document.querySelector('.news-media-right')
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;

        //console.log(scrollTop+"----"+scrollHeight+"----"+offsetHeight+"-----"+contentHeight);

        if (contentHeight <= scrollTop) {
            //this.setState({loading : 1});
            let lastOffset = this.state.lastOffset + 1;
            this.setState({lastOffset : lastOffset,clearList:false},
                function(){
                    var params  = {
                        type: this.state.searchparams,
                        page : this.state.lastOffset + 1,
                        per_page: this.state.offset,
                        projectid:atob(getUrlSegment(4))
                    }
                    this.props.updatenewslist(params);
                })

        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.Savenewsandmediadata.newsmediasaved !== this.props.Savenewsandmediadata.newsmediasaved){
            var beta = this;
            setTimeout(function () {
                beta.getnewsmedia();
                beta.AddmodalClose();
                beta.setState({loading : 0});
            }, 1000)

        }
    }
    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }
    AddmodalOpen(e, id){
        e.stopPropagation();
        this.setState({Addopen : true,id:id});
        if(id !== 0){
            var data  = {
                _id:id,
                search: this.state.search,
                page : this.state.lastOffset + 1,
                per_page: this.state.offset,
                projectid:atob(getUrlSegment(4))
            }

            this.props.getNewsMediaInfo(data)
        }
    }
    AddmodalClose(){
        this.setState({Addopen : false,id:0 });
    }
    openFilterSection(){
        this.setState({openFilter:!this.state.openFilter})
    }
    showOptions(id,index){
        if(this.state.showOption === index){
            index = -1;
        }
        this.setState({showOption:index})
      //  this.setState({showOption : !this.state.showOption});
    }

    getnewsmedia(){
        var data  = {
            type: this.state.searchparams,
            page : this.state.lastOffset + 1,
            per_page: this.state.offset,
            projectid:atob(getUrlSegment(4))
        }
       this.props.getNewsMedia(data);
    }

    handleInputChange(search_text){

        this.setState({searchparams: search_text})
        // if(search_text){
        //     if(searchparams.includes(search_text))
        //     {
        //         //searchparams = searchparams.replace((search_text),'');
        //         searchparams=this.removeelements(searchparams,search_text)
        //     }
        //     else if(!searchparams.includes(search_text))
        //     {
        //         searchparams = (searchparams !== '') ? (searchparams + ",") : "";
        //         searchparams += search_text;
        //     }
        //     this.setState({searchparams: searchparams})
        // }
            this.setState({lastOffset:0})

            var data  = {
                page : 1,
                per_page: this.state.offset,
                type:search_text,
                projectid:atob(getUrlSegment(4))
            }
            this.props.getNewsMedia(data);




    }
    removeelements(array,to_remove){
        var elements=array.split(",");
        var remove_index=elements.indexOf(to_remove);
        elements.splice(remove_index,1);
        var result=elements.join(",");
        return result;
    }

    showDateFilter(){
        this.props.overDuePopup(true)
    }

    applyCustomDateRange() {
        //console.log(this.state.dateRange.startDate)
        var startDate = moment.utc(this.state.dateRange.startDate._d).local().format('YYYY-MM-DD');
        var endDate = moment.utc(this.state.dateRange.endDate._d).local().format('YYYY-MM-DD');
        var customDate = startDate+" - "+endDate;
        this.setState({customDate : customDate}, function(){
            this.getnewsmedia();
            this.props.overDuePopup(false)
        })

    }

    handleRangeChange(which, payload) {
        //console.log(which, payload);
        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });
    }
    render() {
        let medialist = this.props.newsMediaData.newsmediadata.content||[];
        let count  = this.props.newsMediaData.newsmediadata.count||{};
        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>
                <div className="ibt-body file-data-wrap">
                    <div className="news-media-controller">
                        {(this.props.newsMediaData.loading || this.state.loading === 1) &&
                        <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                        }
                        <div className="news-media-left">
                            <div className="mob-header">
                                <h5>Filter by:</h5>
                                <button className="ico-lib-v1 close-filter"></button>
                            </div>
                            <FilterAccordian
                                openFilterSection={this.openFilterSection}
                                openFilter={this.state.openFilter}
                                handleInputChange={this.handleInputChange}
                                searchparams={this.state.searchparams}
                                showDateFilter={this.showDateFilter}
                                count={count}
                            />
                        </div>

                        <div className="news-media-right">
                            {medialist.length === 0 &&
                                <div className='absolute-holder'>
                                    <NoData
                                        msg={`No News and Media added yet`}

                                    />
                                </div>
                            }
                            <div className="news-media-right-mob-header">
                                <ul className="dataroom-option">
                                    <li className="mobi-only">
                                        <a className="ico-lib-v3 dr-filter"></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="news-media-each-container">
                                <NewsMediaTile
                                    newsmedialist={medialist}
                                    showOption={this.state.showOption}
                                    showOptions = {this.showOptions}
                                    AddmodalOpen ={this.AddmodalOpen}
                                />
                            </div>
                        </div>
                    </div>

                </div>


                {this.state.Addopen &&
                <Modal title={this.state.id === 0 ? 'Create New News & Media':'Update News & Media'} Close ={this.AddmodalClose}>
                    <AddNewNewsMedia
                        AddmodalClose = {this.AddmodalClose}
                        action={"create"}
                    />

                </Modal>
                }
                <div className={'float-button-box'} onClick={(e)=>this.AddmodalOpen(e, 0)}>
                    <span className={'icon-in icon-plus'}></span>
                </div>
                {this.props.overduepopup.duedata &&
                <SimpleModal

                    Close={()=>this.props.overDuePopup(false)}
                    title={`Custom Date Range`}
                    haveFooter={1}
                    cancelAction={()=>this.props.overDuePopup(false)}
                    OKAction={this.applyCustomDateRange}
                    OKLabel={`Apply`}
                    modalType={'custom-date-range'}
                >
                    <div>
                        <DateRange
                            onChange={this.handleRangeChange.bind(this, 'dateRange')}
                            moveRangeOnFirstSelection={false}
                            className={'PreviewArea'}
                            calendars={1}
                        />
                    </div>


                </SimpleModal>
                }
            </div>
        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
    Savenewsandmediadata:state.ProjectReducer.SaveNewsAndMedia,
    newsMediaData:state.ProjectReducer.GetNewsMediadata,
    overduepopup: state.CommonReducer.overduePopup,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getNewsMedia:projectActions.getNewsMedia,
    getNewsMediaInfo:projectActions.getNewsMediaInfo,
    overDuePopup:taskActions.overDuePopup,
    updatenewslist:projectActions.updateNewsmedialist
})(ProjectNewsMedia));
