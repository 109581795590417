import {combineReducers} from 'redux';
const listallPhases = (state = {phases:{data:[]}}, action) => {
    var _newState;
    switch (action.type) {
        case "LIST_All_PHASE":
            return {
                ...state,
                phases: {
                    data : action.listallphases.data.results.data
                },
            };
            break;
        case "UPDATE_PHASE_LIST":
            _newState = {...state};
            _newState['phases'].data = _newState.phases.data.concat(action.listmorephases.data.results.data);
            return _newState;
            break;

        case "PHASELIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        case "SEARCH_PHASE_DATA":
            return {
                ...state,
                searchResult: {
                    data : action.listallphases.data.results.data
                },
                searchValue: action.value
            };
            break;
        default:
            return state;
    }
};

const getPhaseDetails = (state = {phaseDet:{data:[]}}, action) => {
    switch (action.type) {
        case "GET_PHASE_DET":
            return {
                ...state,
                phaseDet: action.phaseDet.data.results.data,
            };
            break;
        case "GET_PHASEDET_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};


const SavePhase = (state = {phaseSaved : false, loading : false }, action) => {
    switch (action.type) {
        case "UPDATE_PHASE":
            return {
                ...state,
                phaseSaved: action.phaseSaved.data.results,
            };
            break;
        default:
            return state;
    }
};
const getprojectPhaseDetails = (state = {listprojectphase:[]}, action) => {
    switch (action.type) {
        case "LIST_All_PROJECTPHASE":
            return {
                ...state,
                listprojectphase: action.listprojectphase.data.results.data,
            };
            break;
        case "PROJECTPHASELIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};
const getPhasemilestoneDetails = (state = {listphasemilestone:[]}, action) => {
    switch (action.type) {
        case "LIST_All_PROJECTPHASEMILESTONELIST":
            return {
                ...state,
                listphasemilestone: action.listphasemilestone.data.results.data,
            };
            break;
        case "PROJECTPHASEMILESTONELIST_LOADER":
            return {
                ...state,
                loading:action.loading
            };
            break;

        default:
            return state;
    }
};
const DeletePhase = (state = {phaseDeleted : false }, action) => {
    switch (action.type) {
        case "DELETE_PHASE":
            return {
                ...state,
                phaseDeleted: !state.phaseDeleted
            };
            break;
        default:
            return state;
    }
};
export const Phases = combineReducers({
    listallPhases,
    SavePhase,
    getPhaseDetails,
    getprojectPhaseDetails,
    getPhasemilestoneDetails,
    DeletePhase
});
export default Phases;