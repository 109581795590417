import React, {Component, useState, useEffect  } from 'react';
import {FRMSIMG_PATH} from "../../../constants";
import {isValidDate} from "../../../utilities/CustomFunctions"
import NoData from "../../views/NoData";
function ResultTable(Props) {
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        const container = document.querySelector('.form-table');
        container.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        fetchMoreListItems();
    }, [isFetching]);

    function handleScroll() {
        var scrollY = 0;
        var scrollX = 0;
        var doc = document.getElementById("a");
        if(doc.scrollTop  !== scrollY) {
            const container = document.querySelector('.form-table')
            var scrollTop = container.scrollTop;
            var scrollHeight = container.scrollHeight;
            var offsetHeight = container.offsetHeight;
            var contentHeight = scrollHeight - (offsetHeight + 1);
            if (contentHeight <= scrollTop) {
                Props.loadmoreCollectionData()
            };
        }
        setIsFetching(true);
    }

    function fetchMoreListItems() {
        Props.loadmoreCollectionData()
    }
    let showlistField = Props.showlistField.filter(list =>list.showlist === 'Yes')
    ////console.log(Props.collection)
    ////console.log(Props.showlistField)
    return (
        <div className="form-table coll-full-height full-view-tabel" id="a">
            {showlistField.length === 0 &&
            <div className='absolute-holder result-table-no-data'>
                <NoData
                    msg={`No Data linked to this collection`}
                />
            </div>
            }
            <table id="a" className="table data-table">
                <thead>
                <tr>
                    {showlistField.map((title, index) =>
                        <React.Fragment key={`showlist-field`+index}>
                            <th scope="col" >
                                {title.label}
                            </th>
                        </React.Fragment>
                    )}

                </tr>
                </thead>
                <tbody>
                {Props.collection.map((row, rowIndex) =>
                    <tr key={`result-table-content`+rowIndex}>

                        {showlistField.map((title, index) => {
                                let fieldValue = isValidDate(row[title.masterid]);
                                if( title.type === "attachment" ) {
                                    fieldValue = FRMSIMG_PATH + row[title.masterid];
                                }


                                return(
                                    <React.Fragment key={`edit-collection-data` + index}>

                                        <td onDoubleClick={(e) => Props.showPreviewFormModal(e, row['id'], rowIndex)}>
                                            <div className={`form-table-edit`}>
                                                {(title.type === "attachment" || title.type === "signature") ?
                                                    <div className="att-file">
                                                        {fieldValue !== '' &&
                                                        <img src={fieldValue}/>
                                                        }
                                                        {/*<span className="att-text"></span>*/}
                                                    </div>
                                                    :
                                                    <span className="text-overflow">{fieldValue}</span>
                                                }

                                            </div>
                                        </td>

                                    </React.Fragment>
                                )
                            }

                        )}

                    </tr>
                )}

                </tbody>
            </table>
        </div>
    )

}
export  {ResultTable};