import React, {Component} from 'react';
import {getProfile} from '../../../utilities/AuthService';
import io from 'socket.io-client';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import rootActions from "../../../actions"
import OutsideAlerter from "../../views/OutsideAlerter"
import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize';
import {FloatMessageBox,FloatMetaBox,FloatAttachmentBox} from "./ChatData"
import {forceDownload, getUrlSegment, urlMetaData} from "../../../utilities/CustomFunctions";
import {DISCUSSION} from "../../../constants"
import SimpleModal from "../../views/SimpleModal.js";
import LoaderSvg from "../../views/LoaderSVG";
import {InviteUsers} from "../FormsData";
import PdfViewer from "../../views/PdfViewer";
import UploadFile from "../../../utilities/FileUpload/UploadFile";
import  rootService from "../../../services/index";
import notificationSound from "./notification.mp3";
var socket = io(DISCUSSION,{ transports: ["websocket"] });
socket.on('connect', function ()  {
});
const style = {
    maxHeight: "75px",
    resize: "none",
    padding: "9px",
    boxSizing: "border-box",
    fontSize: "15px"
};

class ProjectChat extends Component {
    listRef = null;
    constructor(props) {
        super(props);
        this.state={
            messageData:'',
            hasAttachment:false,
            option:'',
            optionStatus:false,
            hasInviteUsers:false,
            expand:false,
        }
        this.SaveChat=this.SaveChat.bind(this)
        this.addAttachment=this.addAttachment.bind(this)
        this.inviteUsers=this.inviteUsers.bind(this)
        this.handleFile=this.handleFile.bind(this)
        this.preview=this.preview.bind(this)
    }
    urlCheck(msg) {
        var expression =/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        var regex = new RegExp(expression);
        return msg.match(regex)
    }
    preview(url, name,type) {
        this.setState({url: url, name: name, previewStatus: !this.state.previewStatus,type:type})
    }
    expand=(expand)=>{
        if(expand)
            document.body.classList.add('layover-bg');
        else
            document.body.classList.remove('layover-bg');
        this.setState({expand:expand})
    }
    componentDidMount() {
        if(this.props.openSta) {
            this.textarea.focus();
        }
        if(this.props.openSta){
            this.props.msgCnt("")
        }
        this.setState({style:style})
        var audio = new Audio(notificationSound);
        var params = {};
            params.name = getProfile().first_name;
            params.room = this.props.taskid;
            params.host = 'subdomain';
            params.api_path = 'api_path';
            var that=this;
            socket.emit('join', params, function (err) {});

            socket.on('DisscussionnewMessage', function (message) {
                var message=message.text

                if(that.props.type === "task") {
                    if (atob(getUrlSegment(3)) === message.masterid) {
                        that.props.updateDisscussion(message)
                    }
                }
                if(that.props.type === "project") {
                    if (atob(getUrlSegment(4)) === message.masterid) {
                        that.props.updateDisscussion(message)
                    }
                }
                if(that.props.type === "collection") {
                    if (that.props.taskid === message.masterid) {
                        if(!that.props.openSta){
                            that.props.msgCnt(1)
                            audio.play();
                        }
                        if(that.props.sound){

                        }
                        if(that.props.toaster){

                        }

                        that.props.updateDisscussion(message)
                    }
                }
                if(that.props.type === "entity") {
                    if (that.props.taskid === message.masterid) {
                        if(!that.props.openSta){
                            that.props.msgCnt(1)
                            audio.play();
                        }
                        if(that.props.sound){

                        }
                        if(that.props.toaster){

                        }

                        that.props.updateDisscussion(message)
                    }
                }

            })
            socket.on('newAttachmentMessage', function (message) {
                ////////////console.log('new message')
            })
            socket.on('clearMessages', function(data) {
                ////////////////console.log(data);
                //$("#messages").empty();

            });

            socket.on('newMessage', function (message) {

//////////console.log(message)

            })

            socket.on('newAttachmentMessage', function (message) {

                //////////console.log(message)
            })

            socket.on('attachmentMessage', function (message) {
            })
            socket.on('clientEvent', function(data) {
                socket.emit('serverEvent',data);
            });
        this.props.getallcomments(this.props.taskid)
        this.props.allUsers(getProfile().id, 'user')
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.SaveDisscussion !== prevProps.SaveDisscussion) {
           }
        if(this.props.taskid !== '' && this.props.openSta) {
          //  alert(document.getElementById('disscussion').scrollHeight)
            document.getElementById('disscussion').scrollTop = document.getElementById('disscussion').scrollHeight
        }
        if (snapshot !== null && !this.props.allcomments.loader) {
            this.listRef.scrollTop =
                this.listRef.scrollHeight - snapshot;
        }
        if(this.props.openSta !== prevProps.openSta){
            this.props.msgCnt("")
        }
    }
    handleChange(e){
        this.setState({messageData:e.target.value})
    }
    addAttachment(){
       this.setState({hasAttachment:!this.state.hasAttachment})
    }
    inviteUsers(){
        this.setState({hasInviteUsers:!this.state.hasInviteUsers})
    }
    option=(opt)=>{
        this.setState({option:opt,optionStatus:!this.state.optionStatus})
    }
    handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey && this.state.messageData !== '') {
            this.SaveChat();
        }
    }
    SaveChat(e) {

        if (this.state.messageData.trim() === '') {
            return false;
        }
        var that = this
        var _id = Math.round(new Date().getTime() + (Math.random() * 100));
        var metainfo = [];
        var link_array = []
        var has_metaurl = false;
        var j = 0;
        var messageData=that.state.messageData
        if (this.urlCheck(this.state.messageData)) {
            has_metaurl = true;
        }
        if (has_metaurl) {
            var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
           // var urlRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

            this.state.messageData.replace(urlRegex, function (url) {
                link_array.push(url);
            });
            if (link_array.length === 0) {

                var data = {
                    text: that.state.messageData,
                    createuser: getProfile().first_name,
                    username: getProfile().first_name,
                    todo: that.props.taskid,
                    user: getProfile().id,
                    creator: getProfile().first_name + " " + getProfile().last_name,
                    crtdate: moment(),
                    discussion: btoa(unescape(encodeURIComponent(that.state.messageData))),
                    discussionid: _id,
                    filerelator: Math.floor(Date.now() / 1000),
                    has_attachment: "no",
                    has_metaurl: has_metaurl,
                    masterid: that.props.taskid,
                    metainfo: metainfo,
                    userhead: getProfile().image,
                    todoid: that.props.taskid,
                    type :that.props.type
                }
                this.props.savedisscusion(data)
                socket.emit('createDisscussionMessage', data);
                this.setState({messageData: ''})
            }
           else {
                for (var i = 0; i < link_array.length; i++) {
                    let urlData = rootService.chat.urlData(link_array[i]);
                    urlData.then(urlData => {
                        var metainfo1 = urlMetaData(urlData.data.data)
                        metainfo.push({
                            "siteName": metainfo1[0].siteName,
                            "pageUrl": metainfo1[0].pageUrl,
                            "pageTitle": metainfo1[0].pageTitle,
                            "pageUpdated": metainfo1[0].pageUpdated,
                            "pageDescription": metainfo1[0].pageDescription,
                            "pageImage": metainfo1[0].pageImage,
                            "metaid": metainfo1[0].metaid
                        });
                        j++;
                        if (link_array.length === j) {
                            //console.log(this.state)
                            var data = {
                                text: messageData,
                                createuser: getProfile().first_name,
                                username: getProfile().first_name,
                                todo: that.props.taskid,
                                user: getProfile().id,
                                creator: getProfile().first_name + " " + getProfile().last_name,
                                crtdate: moment(),
                                discussion: btoa(unescape(encodeURIComponent(messageData))),
                                discussionid: _id,
                                filerelator: Math.floor(Date.now() / 1000),
                                has_attachment: "no",
                                has_metaurl: has_metaurl,
                                masterid: that.props.taskid,
                                metainfo: metainfo,
                                userhead: getProfile().image,
                                todoid: that.props.taskid,
                                type:that.props.type
                            }
                            //////////console.log(data)
                            this.props.savedisscusion(data)
                            socket.emit('createDisscussionMessage', data);
                            this.setState({messageData: ''})


                        }
                    })

                }
            }


        } else {
            var data = {
                text: that.state.messageData,
                createuser: getProfile().first_name,
                username: getProfile().first_name,
                todo: that.props.taskid,
                user: getProfile().id,
                creator: getProfile().first_name + " " + getProfile().last_name,
                crtdate: moment(),
                discussion: btoa(unescape(encodeURIComponent(that.state.messageData))),
                discussionid: _id,
                filerelator: Math.floor(Date.now() / 1000),
                has_attachment: "no",
                has_metaurl: has_metaurl,
                masterid: that.props.taskid,
                metainfo: metainfo,
                userhead: getProfile().image,
                todoid: that.props.taskid,
                type:that.props.type
            }
            this.props.savedisscusion(data)
            socket.emit('createDisscussionMessage', data);
           let obj=this
            setTimeout(function () {
                obj.setState({messageData: ''})
            }, 1)

        }
        this.setState({messageData: ''})
    }
    lazyUploadStatus=()=>{
        this.setState({displayStatus:"none"})
    }
    handleFile=(file)=>{
        this.setState({hasAttachment:false,displayStatus:"block",attachmentMesage:this.state.messageData,messageData:''})
        var _id= Math.round(new Date().getTime() + (Math.random() * 100));
        var  filerelator= Math.floor(Date.now() / 1000)
        var data = {
            text: this.state.attachmentMesage,
            createuser: getProfile().first_name,
            username: getProfile().first_name,
            todo: this.props.taskid,
            user: getProfile().id,
            creator: getProfile().first_name + " " + getProfile().last_name,
            crtdate: moment(),
            discussion:btoa(decodeURIComponent(escape(this.state.attachmentMesage))),
            discussionid: _id,
            filerelator:filerelator,
            has_attachment: "yes",
            reference:'disscussion',
            has_metaurl: false,
            masterid: this.props.taskid,
            metainfo: [],
            userhead: getProfile().image,
            todoid: this.props.taskid,
            attachment:file,
            type:this.props.type
        }
        var attachments = {
            uid: getProfile().id,
            todoid: this.props.taskid,
            attachment:file,
            reference:'disscussion',
            filerelator:filerelator,
        }
        socket.emit('createDisscussionMessage', data);
        this.props.uploadAttachments(attachments)
        this.props.savedisscusion(data)
        this.setState({messageData: ''})
    }
    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (prevProps.allcomments.data.length < this.props.allcomments.data.length) {
            return (
                this.listRef.scrollHeight - this.listRef.scrollTop
            );
        }
        return null;
    }
    addInviteUsers = (members,id) => {
        this.props.addInviteUsers(members,id,this.props.taskid)
    }

    render() {
        this.date=moment.utc().local().format("DD-MM-YYYY")
        if(this.props.openSta) {
            return (
                <div className="side-bar-panel collection-clik-panel grey-bg-panel list-view-panel">
                    <div id={`progress-holder`} className={'upload-pr-wrap'}></div>
                    <div className="float-panel-header">
                        <h5 className="subheader">Chat</h5>
                        <button className="ico-lib fly-close" onClick={() => this.props.msgOpn("msgOpn")}></button>
                    </div>
                    <div
                        className={`discuss-inner collection-chat-container ${this.state.expand ? "full-height-discuss" : ""}`}
                        tabIndex="2" id={`disscussion`} ref={this.setListRef}>
                        {this.props.allcomments.loader ?
                            <LoaderSvg/>
                            :
                            <React.Fragment>
                                {this.props.allcomments.data.map((list, index) => {
                                        if (moment.utc(this.date).local().format("DD-MM-YYYY") !== moment.utc(list.crtdate).local().format("DD-MM-YYYY")) {
                                            var nextdate = list.crtdate;
                                            this.nextImage = ''
                                            var image = false
                                        }


                                        if (this.nextImage !== list.userhead) {
                                            image = true
                                        } else {
                                            image = false
                                        }
                                        this.date = list.crtdate;
                                        this.nextImage = list.userhead
                                        //console.log(list)
                                        return (
                                            <>
                                                {((moment.utc(nextdate).local().format("DD-MM-YYYY") === moment.utc(list.crtdate).local().format("DD-MM-YYYY")) && nextdate !== undefined) &&
                                                <div className="chat-date-indicator">
                                                    <span
                                                        className="chat-date-in"> {moment.utc(nextdate).local().format('DD-MM-YYYY') === moment.utc().local().format('DD-MM-YYYY') ? 'Today' : moment.utc(nextdate).local().format('DD-MM-YYYY')}</span>
                                                </div>
                                                }
                                                <FloatMessageBox
                                                    showMessageBox={(list.has_metaurl === false && list.has_attachment === 'no')}
                                                    index={index}
                                                    sender={list.sender}
                                                    createdate={list.crtdate}
                                                    message={list.discussion}
                                                    showImage={image}
                                                    userhead={list.userhead}
                                                    creator={list.creator}
                                                    createuser={list.createuser}
                                                    user={list.user}
                                                />
                                                <FloatMetaBox
                                                    showMessageBox={list.has_metaurl}
                                                    index={index}
                                                    sender={list.sender}
                                                    createdate={list.crtdate}
                                                    message={list.discussion}
                                                    showImage={image}
                                                    userhead={list.userhead}
                                                    creator={list.creator}
                                                    metainfo={list.metainfo}
                                                    createuser={list.createuser}
                                                    user={list.user}
                                                />
                                                <FloatAttachmentBox
                                                    showMessageBox={list.has_attachment === 'yes'}
                                                    index={index}
                                                    sender={list.sender}
                                                    createdate={list.crtdate}
                                                    message={list.discussion}
                                                    showImage={image}
                                                    userhead={list.userhead}
                                                    creator={list.creator}
                                                    attachment={list.attachment}
                                                    preview={this.preview}

                                                />
                                            </>
                                        )


                                    }
                                )}
                            </React.Fragment>
                        }
                    </div>
                    <div className={`fixed-chat-bottom ${this.state.expand ? "popover-text" : ""}`}>
                        <div className={`fixed-bottom-left`}>
                            <button className="chat-menu" onClick={(e) => this.option('optionPanel')}></button>
                            {this.state.option === 'optionPanel' &&
                            <OutsideAlerter
                                status={this.state.optionStatus}
                                Triggered={(e) => this.option("")}
                            >
                                <div className="chat-menu-options">
                                    <a href="javascript:void(0);" className="ind-menu attach-menu"
                                       onClick={() => this.addAttachment()}>Attach
                                        Files</a>
                                    {this.props.hasOwnProperty("inviteUsers") &&
                                    this.props.inviteUsers &&
                                    <a onClick={() => this.inviteUsers()} href="javascript:void(0);"
                                       className="ind-menu invite-menu">Invite
                                        Users</a>
                                    }

                                </div>
                            </OutsideAlerter>
                            }
                            {this.state.hasAttachment &&
                            <div style={{display: this.state.displayStatus}}>
                                <SimpleModal
                                    title={`ADD ATTACHMENT`}
                                    Close={this.addAttachment}
                                    extraClass={`record-chat-modal`}
                                >
                                        <textarea id={`messagebox-attachment`} type="text" name="messageData"
                                                  className="upload-comment"
                                                  placeholder="Type here" value={this.state.messageData}
                                                  onChange={(e) => this.handleChange(e)}
                                                  onKeyPress={this.handleKeyPress}/>
                                    <UploadFile
                                        AutoUpdate={false}
                                        getUploadedFile={this.handleFile}
                                        multipleFiles={true}
                                        overLay={false}
                                        dropZone={true}
                                        wrapperClass=""
                                        progressBar={true}
                                        progressContainer={`progress-holder`}
                                        videoAlbum={'videos'} /* only for chat*/
                                        lazyUpload={true}
                                        lazyUploadStatus={this.lazyUploadStatus}
                                    />
                                </SimpleModal>
                            </div>
                            }
                            {this.state.hasInviteUsers &&
                            <SimpleModal
                                title={`Select Users`}
                                Close={this.inviteUsers}
                            >
                                <InviteUsers
                                    allUsers={this.props.allusers.ListAllUsers}
                                    chatUsers={this.props.chatUsers}
                                    assigneesData={this.props.assigneesData}
                                    addInviteUsers={this.addInviteUsers}
                                    Close={this.inviteUsers}
                                />
                            </SimpleModal>
                            }
                            {this.state.previewStatus &&
                            <SimpleModal
                                Close={this.preview}
                            >
                                {this.state.type === "pdf" ?
                                    <div className="preview-area chat-panel ">
                                        <PdfViewer
                                            fileUrl={this.state.url}
                                            fileType={`pdf`}
                                        />
                                    </div>
                                    :
                                    <div className="preview-area chat-panel">
                                        <img className={`chat-image-panel`}
                                             src={this.state.url}/>
                                        <div className="preview-options">
                                            <ul className="resources-filters-icons">
                                                <li><a
                                                    className="rs-tab-btn ico-lib-v1 rc-download single-option"
                                                    onClick={() => forceDownload(this.state.url, this.state.name)}> </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </SimpleModal>
                            }
                        </div>
                        <div className="fixed-bottom-center">
                            <div className="input-block">
                                <TextareaAutosize
                                    useCacheForDOMMeasurements
                                    inputRef={tag => (this.textarea = tag)}
                                    rows={1}
                                    placeholder="Type Here"
                                    className="text-area-expandable"
                                    value={this.state.messageData}
                                    onChange={(e) => this.handleChange(e)}
                                    onKeyPress={this.handleKeyPress}
                                />
                                {/*<button className="expand-btn" onClick={()=>this.expand(!this.state.expand)}> </button>*/}
                            </div>
                        </div>
                        <div className="fixed-bottom-right">
                            <button className="chat-submit" onClick={this.SaveChat}/>
                        </div>
                        {this.state.expand &&
                        <button className="expand-close" onClick={() => this.expand(!this.state.expand)}></button>
                        }
                    </div>
                </div>
            );
        }
        else {
            return (
                <div ref={this.setListRef}>

                </div>

            )
        }
    }
    setListRef = ref => {
        this.listRef = ref;
    };
}
const mapStateToProps = state => ({
    allcomments:state.ChatReducer.GetComments,
    SaveDisscussion:state.ChatReducer.SaveDisscussion,
    allusers: state.ChatReducer.listallusers,
});
export default  withRouter(connect(mapStateToProps, {
    allUsers: rootActions.chatAction.ListAllUsers,
    chatUsers: rootActions.chatAction.chatUsers,
    getallcomments:rootActions.chatAction.GetAllComments,
    savedisscusion:rootActions.chatAction.SaveDisscusion,
    updateDisscussion:rootActions.chatAction.updateDisscussion,
    uploadAttachments:rootActions.chatAction.uploadAttachments,
    addInviteUsers:rootActions.chatAction.addInviteUsers,
})(ProjectChat));


