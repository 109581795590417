import React, {Component} from 'react';
import {getProfile} from "../../../utilities/AuthService";
import 'react-selectize/themes/index.css'
import {withRouter} from 'react-router';
import {connect} from "react-redux";
import * as collectionActions from "../../../actions/Collections";
import * as taskActions from "../../../actions/ToDo";
import {ES_INDEX} from '../../../constants'
import DatePickerBox from "../../views/DatePickerBox"
import AutoComplete from "../../views/AutoComplete"
import {Form} from "react-bootstrap";
import * as usersActions from "../../../actions/Users";
import {formatDate, getUrlSegment} from "../../../utilities/CustomFunctions";
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {USRIMG_PATH} from "../../../constants";
import LoaderSvg from "../../views/LoaderSVG";
import rootActions from "../../../actions";
import ProjectBoardList from "./ProjectBoardList";
import ProjectAddCard from "../ProjectAddCard";

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
class ProjectAddBoard extends Component {

    constructor(props) {
        super(props);

        this.state={
            name:'',
            description : '',
            enddate:new Date(),
            startdate:new Date(),
            projectname:'',
            clientname:'',
            project_id :0,
            selectedItems : [],
            Close:false,
            newDiscussionLoading:0,
            stageforward: 'allUser'
        };
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);


    }

    componentDidMount() {
        this.setState({loading: 1});
        var prjctdtlid = getUrlSegment(4);
        var projectid = (atob(getUrlSegment(4)));
        this.props.mainClick('project', '/project/details/board/'+prjctdtlid);
        //console.log(projectid)
        this.setState({projectid: projectid})

        let Ddata ={
            "projectid": projectid,
            "systype": getProfile().usertype,
            "uid": getProfile().id,
            "from": 0,
            "size": 50,

        }
        this.props.getprojectDiscussions(Ddata)
        let Pdata ={
            "projectid": projectid,
        }
        this.props.projectuserdetail(Pdata);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        var projectid = (atob(getUrlSegment(4)));
        //console.log( projectid)
        var  beta =  this;
        if(prevProps.discussionAdddata.project_discussion !== this.props.discussionAdddata.project_discussion ) {
            setTimeout(function () {
                let Ddata ={
                    "projectid": projectid,
                    "systype": getProfile().usertype,
                    "uid": getProfile().id,
                    "from": 0,
                    "size": 50,

                }
                beta.props.getprojectDiscussions(Ddata);
            }, 1000)
            this.AddmodalClose();


        }

        if(prevProps.userlistAssignmoredata.assigned_users !== this.props.userlistAssignmoredata.assigned_users ) {
            setTimeout(function () {
                let Ddata ={
                    "projectid": projectid,
                    "systype": getProfile().usertype,
                    "uid": getProfile().id,
                    "from": 0,
                    "size": 50,

                }
                beta.props.getprojectDiscussions(Ddata);
            }, 1000)



        }

    }

    AddmodalOpen(e, pid){
        e.stopPropagation();

        if(pid){
            this.setState({prjct_id : pid});
            let projectdata = {_id: pid}
            // this.props.projectInfo(projectdata);
        }
        this.setState({Addopen : true});
    }

    AddmodalClose(){
        this.setState({Addopen : false});
    }



    render() {

        let projectdiscussions = this.props.projectdiscussionsdata.getprojectdiscussions;
        let userlength = projectdiscussions.length;
        let userdetail = this.props.listAllProjectUserData.getprojectusers;
        //console.log(this.state.projectid)
        //console.log(projectdiscussions)
        //console.log(userdetail);
        //console.log(this.state.assigneeslist);

        return (
            <div className="board-list-wrapper">

                    <ProjectBoardList
                        AddmodalClose = {this.AddmodalClose}
                        listtags={userdetail}
                        userlength = {userlength}
                    />

                <div className="board-body nicescroll">
                    <ProjectAddCard
                        AddmodalClose = {this.AddmodalClose}
                        listtags={userdetail}
                        userlength = {userlength}
                    /><ProjectAddCard
                        AddmodalClose = {this.AddmodalClose}
                        listtags={userdetail}
                        userlength = {userlength}
                    /><ProjectAddCard
                        AddmodalClose = {this.AddmodalClose}
                        listtags={userdetail}
                        userlength = {userlength}
                    /><ProjectAddCard
                        AddmodalClose = {this.AddmodalClose}
                        listtags={userdetail}
                        userlength = {userlength}
                    /><ProjectAddCard
                        AddmodalClose = {this.AddmodalClose}
                        listtags={userdetail}
                        userlength = {userlength}
                    />

                    <button className="board-list-add">Add Card</button>
                </div>


            </div>


        );
    }
}


const mapStateToProps = state => ({
    projectdiscussionsdata:state.ProjectReducer.GetProjectDiscussions,
    discussionAdddata:state.ProjectReducer.SaveProjectDiscussion,
    listAllProjectUserData:state.ProjectReducer.GetProjectUsers,
    userlistAssignmoredata:state.ProjectReducer.SaveAssignedUsers,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getprojectDiscussions : projectActions.getProjectDiscussions,
    projectuserdetail:projectActions.getProjectUsers,
    saveAssignedusers:projectActions.saveAssignedUsers,

})(ProjectAddBoard));



