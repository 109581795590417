import {commonService} from "./common.services";
export const dynamicdashboard = {
    saveDashboarddata,
    trackSharedUrl,
    Projectdetails,
    addDashboard,
    getDashboardMenus,
    getFlag
};

function saveDashboarddata(params) {
    return commonService.sendHttpRequest('project/savedashboard', params, "POST");
}
function trackSharedUrl(params){
	return commonService.sendHttpRequest('portfolio/sharedurltracking', params, "POST");
}

function Projectdetails(params){
    return commonService.sendHttpRequest('portfolio/projectdata', params, "GET");
}

function addDashboard(params){
    return commonService.sendHttpRequest('library/createsubmenu', params, "POST");
}
function getDashboardMenus(params){
    return commonService.sendHttpRequest('library/dashboardmenu', params, "GET");
}
function getFlag(params){
    return commonService.sendHttpRequest('collection/flagsettingslist', params, "GET");
}


