import React, { Component } from 'react';
import Signature from '../../lib/react-s3/Signature'
import Policy from '../../lib/react-s3/Policy'
import {xAmzDate, dateYMD} from '../../lib/react-s3/Date'
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import ProgressBar from './ProgressBar';
import {DropZone} from "../../components/views/DropZone";
import {BUCKET_CONFIG, BUCKET_CONFIG_SECURED} from "../../constants/index";
import {isImage, isVideo,isAudio} from "../CustomFunctions";

var progressPercentage =0;
var progressObj;
var ProgressiveLoading=0
var t=0
class ReactS3 {
    static upload(file, config, fn) {

        const fd = new FormData();
        const key = `${config.albumName ? config.albumName + '/' : ''}${fn}`;
        const url = `https://${config.bucketName}.s3.amazonaws.com/`
        fd.append('key', key);
        fd.append('acl', 'public-read');
        fd.append('Content-Type', file.type);
        fd.append('x-amz-meta-uuid', '14365123651274');
        fd.append('x-amz-server-side-encryption', 'AES256')
        fd.append('X-Amz-Credential', `${config.accessKeyId}/${dateYMD}/${config.region}/s3/aws4_request`);
        fd.append('X-Amz-Algorithm', 'AWS4-HMAC-SHA256');
        fd.append('X-Amz-Date', xAmzDate)
        fd.append('x-amz-meta-tag', '');
        fd.append('Policy', Policy.getPolicy(config))
        fd.append('X-Amz-Signature', Signature.getSignature(config, dateYMD, Policy.getPolicy(config)));
        fd.append("file", file);
        return new Promise(async(resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.addEventListener("progress", updateProgress);
            xhr.upload.addEventListener("loadstart", startUpload, false);
            xhr.upload.addEventListener("progress", updateProgress, false);
            xhr.upload.addEventListener("load", uploadCompleted, false)
            xhr.open('POST', url);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    //resolve(xhr.response);
                    console.log("success");
                    resolve({
                        bucket: config.bucketName,
                        key: `${config.albumName ? config.albumName + '/' : ''}${fn}`,
                        location: `${url}${config.albumName ? config.albumName +'/' : ''}${fn}`,
                        result: xhr.response
                    })

                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                    console.log("error");
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send(fd);


            function startUpload (e) {
                console.log("üpload started");
                if(config.progressBar) {
                    progressObj = ReactDOM.render(React.createElement(ProgressBar), document.getElementById(config.progressContainer));
                    progressObj.onProgress(0, config);
                }

                if(config.overLay){
                    document.body.classList.add('layover-bg');
                }
            }

            function updateProgress (e) {
                if (e.lengthComputable && config.progressBar) {
                    console.log(config.totalSize)
                    var percentComplete = ProgressiveLoading / parseInt(config.totalSize) * 100;
                    progressObj.onProgress( percentComplete,config);
                    t=ProgressiveLoading+e.loaded
                } else {
                    console.log("Unable to compute progress information since the total size is unknown")
                }
            }

            function uploadCompleted (e) {
                ProgressiveLoading=t
                if(config.fileLength === (config.uploaded+1)){
                    if(config.progressBar){
                        progressObj.onProgress(100,config);
                    }
                    setTimeout(function () {
                        if(config.overLay){
                            document.body.classList.remove('layover-bg');
                        }
                        if(config.progressBar) {
                            progressObj = ReactDOM.unmountComponentAtNode(document.getElementById(config.progressContainer));
                        }
                    },1500)
                }
                console.log("üpload completed");
            }
        })

    }
}

class UploadFile extends React.Component{
    constructor(props){
        super();
        this.state = {
            qData: '',
            uploadQ: {},
            imageHolder:true,
            selectedFile: null,
            completed: 5,
            Files:"",
            uploaded:0,
            fileData :[],
            onError:false,
            fileDetails: 'other'

        }
       // console.log("progressPercentage"+progressPercentage);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
        // this.onUploadProgress = this.onUploadProgress.bind(this);
    }
    componentWillMount(){
        this.setState({uploadQ:{}})
    }
    componentDidMount() {
        let dat={}
        this.setState({uploadQ:dat})
    }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
    }

    onUploadSuccess(r){
     //   console.log(r)
        var filename = r.key.substring(r.key.lastIndexOf("/") + 1, r.key.length);
        let fileData= {
            "filetype": r.videotype,
            "filesize": r.filesize.toString(),
            "filetitle": r.file,
            "name": r.file,
            "res_type":r.res_type,
            "filename": filename
        }

        fileData=[...this.state.fileData,fileData]
        this.setState({fileData:fileData})
        if((this.state.uploaded+1) === this.state.Files.length){
            this.props.getUploadedFile(fileData);
            this.setState({completed:100,fileData:[],totalSize:0,uploaded:0,Files:''});
            progressPercentage =0;
            ProgressiveLoading=0
            t=0
        }
        else {
            this.setState({completed:0,uploaded:this.state.uploaded+1});
            this.fileChangedHandler(this.state.Files,this.state.uploaded,this.state.totalSize)
        }
    }
    onUploadError=(r,config)=>{

        if(config.progressBar){
            progressObj.onProgress(0,config);
        }

            if(config.overLay){
                document.body.classList.remove('layover-bg');
            }
            if(config.progressBar) {
                progressObj = ReactDOM.unmountComponentAtNode(document.getElementById(config.progressContainer));
            }

        this.setState({onError:true})


    }
    closeError=()=>{
        this.setState({onError:false})
    }

    handleFileUpload = (e) => {
        let totalSize=0;
        let files=e.target.files;
        if(this.props.hasOwnProperty("acceptedFiles")){
            var filePath =files[0].name;
            //\.jpg|\.jpeg|\.png|\.gif
            var allowedExtensions = this.props.acceptedFiles;
            var fileextension = filePath.split('.').pop();
            if(!allowedExtensions.includes(fileextension)){
                this.props.ErrorMessage(true,filePath)
                return false;
            }
            else{
                this.props.ErrorMessage(false,filePath)
            }

        }

        for (var i = 0; i < files.length; i++) {
            totalSize = totalSize + files[i].size;
        }
        this.setState({Files:files,totalSize})
        this.fileChangedHandler(files,0,totalSize,"uploaded")
    };
    handleFileChange=(files,totalSize,status)=>{
        let Files=files
        let lazyUpload=this.props.hasOwnProperty("lazyUpload")?(this.props.lazyUpload===true):false
        if(lazyUpload){
            this.props.lazyUploadStatus()
        }
        this.setState({Files:files,totalSize})
        this.fileChangedHandler(Files,0,totalSize,status)
    }
    fileChangedHandler(files,len,totalSize,status){
        //console.log(files)
        if (status ==="removed"){
            this.props.getUploadedFile(files,0)
            return false
        }
        let config =BUCKET_CONFIG

        if(this.props.hasOwnProperty("secureMode") && this.props.secureMode ){
            config = BUCKET_CONFIG_SECURED
        }

        const file = files[len];
        if(this.props.hasOwnProperty("secureMode") && this.props.secureMode ){
            var res_type =5
            var  fileType="videos"
            var secured=true
        }
        else if(isImage(file.name)){
             res_type =1
             fileType="images"
            secured=false
        }
        else if(isVideo(file.name)){
            res_type =2
            fileType="videos"
            secured=false
        }
        else if(isAudio(file.name)){
            res_type =4
            fileType="documents"
            secured=false
        }
        else{
            res_type =3
            fileType="documents"
            secured=false
        }

        //Files.value=null
        this.setState({selectedFile: file});
        this.setState({uploadstatus: 'uploading'});

        const fnarr = file.name.split('.');
        const fn = 'fn' + new Date().getTime() + "." + fnarr[fnarr.length-1];
        let tmpobj = {'stat':'uploading', 'fn':fn}
        let overLay=this.props.hasOwnProperty("overLay")?this.props.overLay:false
        let progressBar=this.props.hasOwnProperty("progressBar")?this.props.progressBar:true
        let progressContainer=this.props.hasOwnProperty("progressContainer")?this.props.progressContainer:"progress-holder"
        let videoAlbum=this.props.hasOwnProperty("videoAlbum")?(fileType ==="videos"?this.props.videoAlbum:fileType):fileType
        let progressbarType=this.props.hasOwnProperty("progressbarType")?this.props.progressbarType:"custom"

        config.uploaded=len
        config.fileLength=files.length
        config.totalSize=totalSize
        config.res_type=res_type
        config.albumName= secured?"inputs": fileType
        config.overLay=overLay
        config.progressBar=progressBar
        config.progressContainer=progressContainer
        config.videotype=videoAlbum
        config.progressbarType=progressbarType
        this.state.uploadQ[fn] = tmpobj;
        ReactS3.upload(file, config, fn)
            .then((data) => {
                    data['file']=file.name
                    data['filesize']=file.size
                    data['type']=file.type
                    data['res_type']=config.res_type
                    data['albumName']=config.albumName
                    data['videotype']=config.videotype

                    this.onUploadSuccess(data)
                }
            )
            .catch((err) => this.onUploadError(err,config))
    }
    render(){
        let dropZone=this.props.hasOwnProperty("dropZone")?this.props.dropZone:true
        let wrapperClass=this.props.hasOwnProperty("wrapperClass")?this.props.wrapperClass:""
        let multipleFiles=this.props.hasOwnProperty("multipleFiles")?this.props.multipleFiles:true
        let error=this.props.hasOwnProperty("validationError")&&this.props.validationError ?"val-error":""
        let accept=this.props.hasOwnProperty("acceptedFiles") ?this.props.displayFormat:""
        return(
            <React.Fragment>
                {dropZone ?
                    <div className={wrapperClass?wrapperClass:`upload-select `+error}>
                        <DropZone
                            onSubmit={this.handleFileChange}
                            AutoUpdate={this.props.AutoUpdate}
                            multipleFiles={this.props.multipleFiles}
                            acceptedFiles={this.props.displayFormat}
                            secureMode={this.props.secureMode}
                            onError={this.state.onError}
                            // getUploadedFile={this.props.getUploadedFile}
                        />
                    </div>
                    :
                    <input type="file"
                           accept={accept}
                           name="fileAttach"  multiple={multipleFiles} onChange={this.handleFileUpload} />
                }
                {this.state.onError &&
                <UploaadFailed
                closeError={this.closeError}
                />
                }

            </React.Fragment>

        )
    }
}

UploadFile.propTypes = {
    getUploadedFile : PropTypes.func,
    type: PropTypes.string
}

export default UploadFile;

export {UploadFile};


export function  DirectFileUpload(files){
    const config =  BUCKET_CONFIG
    const albumName ='images'
    config.uploaded=0
    config.fileLength=1
    config.totalSize=files.size
    config.res_type=1
    config.albumName=albumName
    config.overLay=true
    config.progressBar=true
    config.progressContainer="progress-holder"
    config.videotype=""
    config.progressbarType="custom"
    const file = files;
    const fnarr = file.name.split('.');
    const fn = 'fn' + new Date().getTime() + "." + fnarr[fnarr.length-1];
    let tmpobj = {'stat':'uploading', 'fn':fn}
    return  ReactS3.upload(file, config, fn)
        .then((data) => {
                data['file']=file.name
                data['filesize']=file.size
                data['type']=file.type
                data['res_type']=config.res_type
                data['albumName']=config.albumName
                data['videotype']=config.videotype
                return Promise.resolve(data);
            }
        )
}


export function UploaadFailed(props) {

        return(  <div className="bs-modal show" style={{"display": "block"}}>
            <div className="bs-modal-dialog">
                <div className="bs-modal-content text-center">
                    <div className="bs-modal-title"><h3>Something went wrong.Please try again</h3></div>
                    <div className="bs-modal-body">
                        <button className="btn btn-default"
                                data-dismiss="modal"
                                onClick={()=>props.closeError()}>
                            Close
                        </button>


                    </div>
                </div>
            </div>
        </div>)

}
