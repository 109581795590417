import {commonService} from "./common.services";
import {API_PATH , ES_INDEX} from '../constants/index'

export const Dashboard = {
    listUserstatics,
    listCalendarTasks,
    listWeeklyStat,
    listDashboardStatics
};

function listUserstatics(params){

    return commonService.sendHttpRequest('todo/userstatistics', params, "GET");
}
function listDashboardStatics(utype, uid){
    let params = {'systype':utype,'uid':uid,'index':ES_INDEX};
    return commonService.sendHttpRequest('todo/userdatastatistics', params, "GET");
}
function listCalendarTasks(params){

    return commonService.sendHttpRequest('todo/taskv2', params, "GET");
}

function listWeeklyStat(params){
    return commonService.sendHttpRequest('todo/weekstatistics', params, "GET");
}