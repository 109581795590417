import React, {useRef, useState} from "react";
import ReactToPrint from "react-to-print";
import CollectionViewPrint from "./CollectionViewPrint";
import {getUrlSegment} from "../../utilities/CustomFunctions";
class ComponentToPrint extends React.Component {

    render() {
        return (
            <CollectionViewPrint/>
        );
    }
}

const PrintWFCollection = (props) => {
    const componentRef = useRef();
    var oldlink = document.getElementsByTagName("link").item(16);
    var newlink = document.createElement("link");
    newlink.setAttribute("rel", "stylesheet");
    newlink.setAttribute("type", "text/css");
    newlink.setAttribute("href", "/assets/print-layout.css");
    document.getElementsByTagName("head").item(0).replaceChild(newlink, oldlink);
    return (
        <div className="wrapper">
            <section className="content-area">
                <div className="right-content-wrapper">
                    <div className="list-wrapper app-form-new">
                        <div className="print-form-slection">
                            <div className="print-form" >
                                <div className="button-header">
                                    <div className="task-nav">
                                        <div className="task-nav-left bg-back">
                                            <button className="backto-list" onClick={()=>window.location.href=("/"+getUrlSegment(1)+"/details/"+getUrlSegment(4))}>Back to {getUrlSegment(1) === 'entity' ? ' entity' : ' collection'}</button>
                                        </div>
                                    </div>
                                    <ReactToPrint
                                        trigger={() => <button className="print-form-btn">Print</button>}
                                        content={() => componentRef.current}
                                    />
                                </div>
                                <ComponentToPrint
                                    ref={componentRef}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default PrintWFCollection

