import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import { MentionsInput, Mention } from 'react-mentions';
import {Scrollbars} from "react-custom-scrollbars";

class Mentions extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            value: '',
            singleLineValue: '',
            mentionData: [],
            dataList: [],
            singleLine:false,
            placeholder:'Type Here'
        };
    }

    componentDidMount() {
        let value = this.props.value;
        let dataList = this.props.showFieldList;
        let singleLine = this.props.singleLine;
        let placeholder = this.props.placeholder;
        this.setState({
            value: value,
            dataList: dataList,
            singleLine: singleLine,
            placeholder: placeholder
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value !== prevProps.value){
            this.setState({value:this.props.value})
        }
        if(this.props.singleLine !== prevProps.singleLine){
            this.setState({singleLine:this.props.singleLine})
        }
    }

    handleChange = (e, newValue, newPlainTextValue, mentions) => {
        let value = newValue;
        let name = this.props.name;
        let index = this.props.index;
        console.log(index)
        this.props.handlechangeValue(e, name, value, index)
        this.setState({
            value: newValue,
            newPlainTextValue: newPlainTextValue,
            mentionData: { newValue, newPlainTextValue, mentions }
        });
    };

    handleChangeSingle = (e, newValue, newPLainTextValue, mentions) => {
        this.setState({
            singleLineValue: newValue
        });
    };

    render() {
        // console.log(this.props)
        // console.log(this.state)
        const userMentionData = this.state.dataList.map(myUser => ({
            id: myUser.masterid,
            display: myUser.label
        }));

        return (
            <div>

                <MentionsInput
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder={this.state.placeholder}
                    className="mentions"
                    singleLine={this.state.singleLine}
                >
                    <Mention
                        type="user"
                        trigger="@"
                        markup="{__id__||__display__}"
                        displayTransform={(id, display) => `{${display}}`}
                        data={userMentionData}
                        className="mentions__mention"

                    />
                </MentionsInput>

            </div>
        );
    }
}

const mapStateToProps = state => ({

});
export default  withRouter(connect(mapStateToProps, {

})(Mentions))
