import axios from 'axios'
import {API_PATH,ES_INDEX} from '../constants/index'
import {getProfile} from "../utilities/AuthService";
import {urlify} from "../utilities/CustomFunctions";
import {commonService} from "./common.services";

 const chat = {
    listAllUsers,
    listAllGroups,
    listChatHistory,
    saveChatData,
    listGroupChat,
    saveGroupChat,
    createGroup,
    uploadFile,
    urlData,
    saveattachments,
    savegroupattachments,
    chatUsers,
    groupInfo,
    updateGroupDescription,
    groupActivate,
    groupDeactivate,
    groupAddMembers,
    getComments,
    saveDisscusion,
    logOut,
    uploadAttachments,
     addInviteUsers,
     replyWmess,
     savelinkGroupEntity,
     deletelinkGroupEntity,
     listlinkgroupsEntity,
     listlinkEntityDetails

};
export default chat;


function replyWmess(param){
    return axios.post("https://bsyntwilio.bsynapse.com/replymessaage",param)
}
function addInviteUsers(members,id,taskid){
    // return axios.post(API_PATH + 'todo/saveinviteusers',{
    //     taskId: taskid,
    //     assignees:members,
    //     uid: id,
    //     index: ES_INDEX,
    // })
    let params={
        taskId: taskid,
        assignees:members,
        uid: id,
    }
    return commonService.sendHttpRequest('todo/saveinviteusers', params, "POST");

}




function listAllUsers(uid,usertype){
    // return axios.get(API_PATH + 'user/allusers?uid='+uid+'&usertype='+usertype+'&'+'&index='+ES_INDEX+'&'+ Math.floor(Date.now() / 1000),{
    //
    // })
    let params={
        uid :  uid,
        usertype:usertype
    }
    return commonService.sendHttpRequest('user/allusers', params, "GET");
    // return axios.get(API_PATH + 'chat/chatusers?uid='+uid+'&offset=20&index=lsinternalcopy',{
    //
    // })


}
function listAllGroups(uid,limit,offset,index){
    // return axios.get(API_PATH + 'chat/grouplist?uid='+uid+'&limit='+limit+'&offset='+offset+'&index='+ES_INDEX+'&'+ Math.floor(Date.now() / 1000),{
    //
    // })
    let params={
        uid :  uid,
        limit:limit,
        offset:offset
    }
    return commonService.sendHttpRequest('chat/grouplist', params, "GET");
}

function listChatHistory(sender,receiver,limit,offset){
    // return axios.get(API_PATH + 'chat/chat?sender='+sender+'&receiver='+receiver+'&limit='+limit+'&offset='+offset+'&index='+ES_INDEX+'&'+ Math.floor(Date.now() / 1000),{
    //
    // })

    let params={
        sender :  sender,
        receiver:receiver,
        limit:limit,
        offset:offset
    }
    return commonService.sendHttpRequest('chat/chat', params, "GET");

}
function saveChatData(sender,receiver,replyto,message,has_attachment,index,has_metaurl,metainfo){
    ////////console.log(metainfo)
    // return axios.post(API_PATH + 'chat/savechat',{
    //
    //     sender: sender,
    //     receiver:receiver,
    //     replyto: replyto,
    //     message:message,
    //     has_attachment: has_attachment,
    //     index: ES_INDEX,
    //     has_metaurl:has_metaurl,
    //     metainfo:metainfo
    //
    //
    // })
    let params={
        sender: sender,
        receiver:receiver,
        replyto: replyto,
        message:message,
        has_attachment: has_attachment,
        has_metaurl:has_metaurl,
        metainfo:metainfo,

    }
    return commonService.sendHttpRequest('chat/savechat', params, "POST");


}


function listGroupChat(sender, groupid, limit, offset, index){
    // return axios.get(API_PATH + 'chat/groupchat?groupid='+groupid+'&limit='+limit+'&offset='+offset+'&index='+ES_INDEX+'&uid='+getProfile().id+'&'+Math.floor(Date.now() / 1000),{
    //
    // })
    let params={
        groupid: groupid,
        limit:limit,
        offset: offset,
        uid:getProfile().id
    }
    return commonService.sendHttpRequest('chat/groupchat', params, "GET");
}

function saveGroupChat(sender,receiver,replyto,message,has_attachment,index,has_metaurl,metainfo,has_whatsapp="no",whatsappinfo=[]){
    // return axios.post(API_PATH + 'chat/savegroupchat',{
    //     groupid: sender,
    //     sender:receiver,
    //     replyto: replyto,
    //     message:message,
    //     has_attachment: has_attachment,
    //     index: ES_INDEX,
    //     has_metaurl:has_metaurl,
    //     metainfo:metainfo
    // })
    let params={
        groupid: sender,
        sender:receiver,
        replyto: replyto,
        message:message,
        has_attachment: has_attachment,
        has_metaurl:has_metaurl,
        metainfo:metainfo,
        has_whatsapp:has_whatsapp,
        whatsappinfo:whatsappinfo
    }
    return commonService.sendHttpRequest('chat/savegroupchat', params, "POST");
}


function createGroup(creator, groupname, groupdesc, has_icon,index){
    // return axios.post(API_PATH + 'chat/creategroup',{
    //     creator: creator,
    //     groupname:groupname,
    //     groupdesc: groupdesc,
    //     has_icon:has_icon,
    //     index: ES_INDEX,
    //
    // })
    let params={
        creator: creator,
        groupname:groupname,
        groupdesc: groupdesc,
        has_icon:has_icon,
    }
    return commonService.sendHttpRequest('chat/creategroup', params, "POST");
}

function uploadFile(file, type,messageid){
    ////////console.log(file)
    return axios.post("https://lsinternalcopy.bsynapse.com/index.php/Chat/uploadFile",file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }


        )
}

function urlData(message){
    // return axios.get(API_PATH + 'chat/urldata?url='+message+'&'+ Math.floor(Date.now() / 1000),{
    //
    // })
    let params={
        url: message,
    }
    return commonService.sendHttpRequest('chat/urldata', params, "GET");
}

function saveattachments(parentid, uid, files,index){
    // return axios.post(API_PATH + 'chat/saveattachments',{
    //     parentid: parentid,
    //     uid:uid,
    //     files: files,
    //     index: ES_INDEX,
    //
    // })
    let params={
        parentid: parentid,
        uid:uid,
        files: files,
    }
    return commonService.sendHttpRequest('chat/saveattachments', params, "POST");
}

function savegroupattachments(parentid, uid, files,index){
    // return axios.post(API_PATH + 'chat/savegroupattachments',{
    //     parentid: parentid,
    //     uid:uid,
    //     files: files,
    //     index: ES_INDEX,
    //
    // })
    let params={
        parentid: parentid,
        uid:uid,
        files: files,
    }
    return commonService.sendHttpRequest('chat/savegroupattachments', params, "POST");
}


async function chatUsers(uid,index){
    // return await axios.get(API_PATH + 'chat/chatusers?uid='+uid+'&index='+ES_INDEX+'&'+ Math.floor(Date.now() / 1000),{
    //
    // })
    let params={
        uid:uid,
    }
    return commonService.sendHttpRequest('chat/chatusers', params, "GET");
}


function groupInfo(groupid){
    // return axios.get(API_PATH + 'chat/groupinfo?groupid='+groupid+'&index='+ES_INDEX+'&'+ Math.floor(Date.now() / 1000),{
    //
    // })
    let params={
        groupid:groupid,
    }
    return commonService.sendHttpRequest('chat/groupinfo', params, "GET");
}

function updateGroupDescription(groupid, groupdesc, creator,index){
    // return axios.post(API_PATH + 'chat/updategroupdesc',{
    //     groupid: groupid,
    //     groupdesc:groupdesc,
    //     creator:creator,
    //     index: ES_INDEX,
    //
    // })
    let params={
        groupid: groupid,
        groupdesc:groupdesc,
        creator:creator,
    }
    return commonService.sendHttpRequest('chat/updategroupdesc', params, "POST");
}
function groupDeactivate(groupid, creator,index){
    // return axios.post(API_PATH + 'chat/group_deactivate',{
    //     groupid: groupid,
    //     creator:creator,
    //     index: ES_INDEX,
    //
    // })
    let params={
        groupid: groupid,
        creator:creator,
    }
    return commonService.sendHttpRequest('chat/group_deactivate', params, "POST");
}

function groupActivate(groupid, creator,index){
    // return axios.post(API_PATH + 'chat/group_activate',{
    //     groupid: groupid,
    //     creator:creator,
    //     index: ES_INDEX,
    //
    // })
    let params={
        groupid: groupid,
        creator:creator,
    }
    return commonService.sendHttpRequest('chat/group_activate', params, "POST");
}

function groupAddMembers(groupid, userid,addedby,mode){
    // return axios.post(API_PATH + 'chat/addmembers',{
    //     groupid: groupid,
    //     userid:userid,
    //     addedby: addedby,
    //     mode:mode,
    //     index:ES_INDEX
    //
    // })
    let params={
            groupid: groupid,
            userid:userid,
            addedby: addedby,
            mode:mode,
    }
    return commonService.sendHttpRequest('chat/addmembers', params, "POST");
}

function logOut(id){
    // return axios.post(API_PATH + 'login/logout',{
    //     sid: id,
    //     subdomain:ES_INDEX
    //
    // })
    let params={
        sid: id,
        subdomain:ES_INDEX
    }
    return commonService.sendHttpRequest('login/logout', params, "POST");
}

function getComments(todoid){
    // return axios.post(API_PATH + 'todo/getcomments',{
    //     todoid: todoid,
    //     index:ES_INDEX
    //
    // })
    let params={
        todoid: todoid,
    }
    return commonService.sendHttpRequest('todo/getcomments', params, "POST");
}
function saveDisscusion(data){

    // return axios.post(API_PATH + 'task/savediscussion',{
    //     todoid: data.todoid,
    //     discussion:decodeURIComponent(escape((atob(data.discussion))) ),
    //     uid:data.user,
    //     reference:'comment',
    //     has_attachment:data.has_attachment,
    //     childid:data.childid,
    //     filerelator:data.filerelator,
    //     sendnotification:false,
    //     metainfo:data.metainfo,
    //     has_metaurl:data.has_metaurl,
    //     index:ES_INDEX
    //
    // })
    let params={
        todoid: data.todoid,
        discussion:decodeURIComponent(escape((atob(data.discussion))) ),
        uid:data.user,
        reference:'comment',
        has_attachment:data.has_attachment,
        childid:data.childid,
        filerelator:data.filerelator,
        sendnotification:false,
        metainfo:data.metainfo,
        has_metaurl:data.has_metaurl,
        type :data.type
    }
    return commonService.sendHttpRequest('task/savediscussion', params, "POST");
}

function savelinkGroupEntity(params){
    return commonService.sendHttpRequest('chat/assign_grouplinktoentity', params, "POST");
}
function deletelinkGroupEntity(params){
    return commonService.sendHttpRequest('chat/deletegrouplinktoentity', params, "POST");
}
function listlinkgroupsEntity(params){
    return commonService.sendHttpRequest('chat/grouplinktoentity', params, "GET");
}
function listlinkEntityDetails(params){
    return commonService.sendHttpRequest('chat/linktoentitydata', params, "GET");
}
function uploadAttachments(data){
    let params={
        todoid: data.todoid,
        uid:data.uid,
        file:data.attachment,
        uniqid:data.filerelator,
        refer:data.reference !== undefined ? 'comment' :'',
    }
    return commonService.sendHttpRequest('task/savetodoattachments', params, "POST");
}