import React, { Component } from 'react';
import {Button, Col, Tab, Tabs, Container, Accordion, Card, Row, Toast} from "react-bootstrap";
import '../../../dashboardBuilder/App.css';
import {Scrollbars} from "react-custom-scrollbars";
import {
  Canvas,
  Palette,
  state,
  Trash,
  core,
  Preview,
  registerPaletteElements
} from 'react-page-maker';

import { elements } from './const';
import LabelContainer from './elements/container/LabelContainer';
import DescriptionContainer from './elements/container/DescriptionContainer';
import ImageContainer from './elements/container/ImageContainer';
import FlexibleContent from './elements/container/FlexibleContent';
import GoogleMap from './elements/container/GoogleMap';
import List from './elements/container/List';
import LoaderSvg from "../../../views/LoaderSVG";
import DraggableCardLayout from "./elements/DraggableCardLayout";
import TitleContainer from "./elements/container/TitleContainer";
import ListCard from "./elements/container/ListCard";
import VideoContainer from "./elements/container/VideoContainer";

import BarchartContainer from '../../../dashboardBuilder/elements/container/BarchartContainer';
import PieChartContainer from '../../../dashboardBuilder/elements/container/PieChartContainer';
import LinechartContainer from '../../../dashboardBuilder/elements/container/LinechartContainer';
import AreachartContainer from '../../../dashboardBuilder/elements/container/AreachartContainer';
import AreaBarChartContainer from '../../../dashboardBuilder/elements/container/AreaBarChartContainer';

class EntityPreview extends Component {
  constructor(props) {
    super(props);

    // register all palette elements
    registerPaletteElements([{
      type: elements.LABEL,
      component: LabelContainer
    },{
      type: elements.DESCRIPTION,
      component: DescriptionContainer
    },
      {
        type: elements.IMAGE,
        component: ImageContainer
      },
      {
        type: elements.FLEXIBLECONTENT,
        component: FlexibleContent
      },
      {
        type: elements.GOOGLEMAP,
        component: GoogleMap
      },
      {
        type: elements.LIST,
        component: List
      },
      {
        type: elements.CARD_LAYOUT,
        component: DraggableCardLayout
      },
      {
        type: elements.TITLE,
        component: TitleContainer
      },
      {
        type: elements.LISTCARD,
        component: ListCard
      },
      {
        type: elements.VIDEOCARD,
        component: VideoContainer
      },

      {
        type: elements.BAR_CHART_CONTAINER,
        component: BarchartContainer
      },

      {
        type: elements.PIE_CHART_CONTAINER,
        component: PieChartContainer
      },

      {
        type: elements.AREA_CHART_CONTAINER,
        component: AreachartContainer
      },
      {
        type: elements.AREA_BAR_CHART_CONTAINER,
        component: AreaBarChartContainer
      },
      {
        type: elements.LINE_CHART_CONTAINER,
        component: LinechartContainer
      }




    ]);

    // state.clearState() triggers this event
    state.addEventListener('flush', (e) => {
      //console.log('flush', e);
    });

    // state.removeElement() triggers this event
    state.addEventListener('removeElement', (e) => {
      //console.log('removeElement', e);
    });

    // state.updateElement() triggers this event
    state.addEventListener('updateElement', (e) => {
      //console.log('updateElement', e);
    });
  }

  state = {
    activeTab: '1',
    currentState: [],
    dashboardLoader:true
  }
  componentDidMount() {
    this.setState({dashboardLoader:false,initialElements:JSON.stringify(this.props.customtemplate.template)})
  }
  componentWillMount() {
    state.addEventListener('change', this._stateChange);
  }
  componentWillUnmount() {
    state.removeEventListener('change', this._stateChange);
  }


  render() {
    return (
        <>
          <div style={{"display": "none"}}>
            <Canvas onDrop={this._onDrop} initialElements={this.props.customtemplate} placeholder="Drop Here"/>
          </div>

            <Scrollbars
                style={{width: '100%', height: 'calc(100% - 70px)'}}
            >
              <Preview>
                {
                  ({children}) => (
                      <Container>
                        {children}
                      </Container>
                  )
                }
              </Preview>
            </Scrollbars>


        </>
    );
  }
}

export default EntityPreview;
