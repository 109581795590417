import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import DashboardUser from './DashboardUser'
import {ES_INDEX, FRMSIMG_PATH,USRIMG_PATH} from '../../constants/index'

class StakeholderTile extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log(this.props.list)
        return (
            <React.Fragment>
            <div className="ind-stakeholders-list  type-view" key={this.props.index}>
                <div className="profile-preview-block">
                    <div className="profile-preview-header">
                        <h5>{this.props.list[1168621764]}</h5>
                    </div>
                    <div className="profile-preview-body">
                        <div className="user-profiles-image">
                            <img src={USRIMG_PATH+this.props.list.uniquevalue}/>
                        </div>
                        <div className="user-profile-det">
                            {this.props.list[977404415] &&
                            <p><span className="investor-category">Shareholder :</span> <span
                                className="investor-category-value">{this.props.list[977404415]}</span></p>
                            }
                            {this.props.list[1460604934] &&
                            <p><span className="investor-category">Director :</span> <span className="investor-category-value">{this.props.list[1460604934]}</span></p>
                            }
                            {this.props.list[574391603] &&
                            <p><span className="investor-category">Contact Point :</span> <span className="investor-category-value">{this.props.list[574391603]} {this.props.list[1435443111]?'(':''}{this.props.list[1435443111]}{this.props.list[1435443111]?')':''}</span></p>
                           }
                            {this.props.list[135242849] &&
                            <p><span className="investor-category">Phone :</span> <span
                                className="investor-category-value">{this.props.list[135242849]}</span></p>
                            }
                            {this.props.list[682016743] &&
                            <p><span className="investor-category">Address :</span> <span
                                className="investor-category-value">{this.props.list[682016743]}</span></p>

                            }
                            {/*<p className="pnone-numb">{this.props.list[135242849]}</p>*/}
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}


export default  withRouter(connect(null, {

})(StakeholderTile));



