import React, {Component} from "react";
import {FRMSDOC_PATH, FRMSIMG_PATH, FRMSPDFDOC_PATH, FRMSVID_PATH} from "../../constants";
import {isImage, forceDownload, getFilePath, previewModalFormat, getFileExtension, isVideo, isAudio, formatBytes, isDocument} from "../../utilities/CustomFunctions";
import VideoPlayer from "./player/VideoPlayer";
import {getProfile} from "../../utilities/AuthService";

class ResourcePreviewAttachments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            previewStatus:false
        }
        this.getAttachmentPath = this.getAttachmentPath.bind(this);
    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(getFilePath(type)) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'video' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }
    preview=(Resource_id,thumb,title,res_type,Imgname,creator)=> {
        this.setState({
            ImgName:thumb,
            Title:title,
            res_type:res_type,
            Res_id:Resource_id,
            creator :creator,
            previewStatus: !this.state.previewStatus
        })
        if(res_type === '1'){
            this.setState({ImgPath:FRMSIMG_PATH})
        } else if (res_type === '3'){
            this.setState({ImgPath:FRMSDOC_PATH})
        } else if(res_type === '2'){
            this.setState({ImgPath:FRMSVID_PATH})
        }else if(res_type === '4'){
            this.setState({ImgPath:FRMSDOC_PATH})
        }else if(res_type === '5'){
            // this.setState({ImgPath:FRMSDOC_PATH})
        }

    }
    closePreview=()=>{
        this.setState({previewStatus: false,
            ImgName:'',
            Title:'',
            res_type:'',
            Res_id:'',
            ImgPath:'',
            creator:''})
    }

    render() {
        let index = this.props.index;
        let item = this.props.item;
        let iconClass = getFileExtension(item.thumb);
        iconClass = iconClass.length > 4 ? "YTUBE" : iconClass;
        let contentPath = this.getAttachmentPath(item.thumb);
        var albumName;
        if (isImage(item.thumb)) {
            albumName = 'images/'
        } else if (isVideo(item.thumb)) {
            albumName = 'videos/'
        } else if (isAudio(item.thumb)) {
            albumName = 'audios/'
        } else if (isDocument(item.thumb)) {
            albumName = 'documents/'
        } else {
            albumName = 'youtube/'
        }
        return (
            <>
            <div className="file-each image-type">
                {!isImage(item.thumb)&&
                <div className={"format " + iconClass}>
                    {iconClass}
                </div>
                }
                {isImage(item.thumb) &&
                <img src={contentPath+ item.thumb} />
                }
                <div className= {`file-each-opt single-opt`}>

                    <button className="opt-file-each preview" title={item.title} onClick={(e)=>this.preview(item.resourceId,item.thumb,item.title,item.res_type,item.ImgPath,item.creator)}></button>

                    {/*{previewModalFormat(item) &&*/}
                    {/*<button className="opt-file-each preview" title={contentPath} onClick= {(previewModalFormat(item)) ? ((e) => this.props.preview(item, iconClass)) : ((e) => forceDownload(contentPath + item, contentPath))}></button>*/}
                    {/*}*/}
                    {/*<button className="opt-file-each download" onClick={(e)=>forceDownload(item, iconClass)}></button>*/}
                </div>
            </div>
                {this.state.previewStatus &&
                <div className="modal fade bsy-modal x-large fl-pv show" id="toModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" aria-hidden="true" style={{display:'block'}} >
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.Title}</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closePreview}></button>
                            </div>
                            <div className="modal-body">
                                <div className="preview-area chat-panel">
                                    {this.state.res_type === '1' &&
                                    <img src={this.state.ImgPath + this.state.ImgName}/>
                                    }
                                    {this.state.res_type === '2' &&
                                    <React.Fragment>
                                        <VideoPlayer
                                            key={this.state.ImgPath + this.state.ImgName}
                                            display={'block'}
                                            width="100%"
                                            height="100%"
                                            src={this.state.ImgPath + this.state.ImgName}
                                        />
                                    </React.Fragment>
                                    }
                                    {this.state.res_type === '3'  &&
                                    <iframe src={FRMSPDFDOC_PATH + this.state.ImgName + '&embedded=true'} width="100%" height="250px"/>
                                    }
                                    {this.state.res_type === '4'  &&
                                    <audio  width="100%" height="100%" controls controlsList="nodownload" key={this.state.ImgPath + this.state.ImgName}>
                                        <source type="audio/mpeg" src={this.state.ImgPath + this.state.ImgName}></source>
                                    </audio >
                                    }
                                    {this.state.res_type === '5' &&
                                    <React.Fragment>
                                        <VideoPlayer
                                            key={this.state.ImgPath + this.state.ImgName}
                                            display={'block'}
                                            width="100%"
                                            height="100%"
                                            src={this.state.ImgPath + this.state.ImgName}
                                            resType='5'
                                            fileName={this.state.ImgName}
                                        />
                                    </React.Fragment>
                                    }
                                    {this.state.res_type === '6'  &&
                                    <iframe width="500" height="250" src={this.state.ImgName} alt="Thumb-img"/>
                                    }
                                    <div className="preview-options">
                                        <ul className="resources-filters-icons">
                                            {this.state.res_type !== 5 &&
                                            <li>
                                                <a className="rs-tab-btn ico-lib-v1 rc-download"
                                                   onClick={(e)=>{forceDownload(this.state.ImgPath + this.state.ImgName, this.state.Title)}}></a>
                                            </li>
                                            }
                                            {this.props.creator === getProfile().id &&
                                            <li><a className="rs-tab-btn ico-lib-v1 rc-delete"
                                                   onClick={(e) => this.props.deleteResource(e)}></a></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>
        );
    }
}export default (ResourcePreviewAttachments)