import React from 'react';
import axios from 'axios';
import { Draggable, state } from 'react-page-maker';
import SimpleModal from "../../../views/SimpleModal"
import {connect} from "react-redux";
import * as collectionActions from "../../../../actions/Collections";
import {withRouter} from 'react-router';
import * as DynamicDashboard from "../../../../actions/DynamicDashboard";
import {API_PATH, ES_INDEX} from "../../../../constants";
import {getUrlSegment} from "../../../../utilities/CustomFunctions";
import {getProfile} from "../../../../utilities/AuthService";
import {SettingsPanel} from "./ViewData";
let graphData= [
  {
    "x": "2018 Q1",
    "y": "500",
    "z": "2018 Q1"
  }]


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class TrendBoxContainer extends React.Component {
  constructor(props,context) {
    super(props,context);
    this.state={
      modalStatus:false,
      cId:'',
      payload:{
        chartTitle:"Title",
        subTitle:"subtitle",
        subTitleEnabled : false,
        cardvalue:0,
        collection:"",
        trendfield:"",
        showfield:"",
        trendData:""
      },
      stackedbarchart : [],
      showlistField:[],
      loading:true,

    }


  }
  componentDidMount() {
    this.setState({payload:this.props.payload})
    this.graphData()
  }

  openSetting=()=>{
    this.setState({modalStatus:!this.state.modalStatus})
    this.props.dragStatus(!this.state.modalStatus)
  }
  handleChangeComplete = (color,param) => {
    //////console.log(param)
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[param]=color
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
    //this.setState({ background: color.hex });
  };
  toggleColorPicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker
    });
  }
  handleInputChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  graphData(){
    var obj = this;
    const collection =  this.props.payload && this.props.payload.collection
    const trendfield = this.props.payload && this.props.payload.trendfield
    const showfield = this.props.payload && this.props.payload.showfield
    this.setState({trendfield:trendfield,showfield:showfield})

    if((collection !=='' && collection !== undefined)) {
      this.setState({cId:collection})
      axios({
        method: "GET",
        url: API_PATH + "collection/showform?_id=" + collection+"&index="+ES_INDEX,
        async: false,
         headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        obj.setState({showlistField:res.data.data});
        return res;

      }).catch(function (err) {
        return err;
      });

      axios({
        method: "GET",
        url: API_PATH + "collection/"+collection +"/collectedinfo?index="+ES_INDEX,
        async: false,
         headers : {'Content-Type' : 'application/json',"X_API_KEY":getProfile().token}
      }).then(function (res) {
        var trendData = res.data.results.data[0];
        obj.setState({trendData: trendData});
        return res;

      }).catch(function (err) {
        return err;
      });
    }
    else{
      obj.setState({stackedbarchart: graphData});

    }
  }
  handleChange=(e)=>{
    if(e.target.name==="collection"){
      this.props.showform({ _id : e.target.value});
    }
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload[e.target.name]=e.target.value
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  chartSaving=()=>{
    var prjctdtlid = atob(getUrlSegment(4));
    let sharetype = getUrlSegment(1);
    let  uid = '';
    if(sharetype === 'public' || sharetype === 'state') {
      uid = 2;
    }
    else{
      uid = getProfile().id;
    }
    let param={
      "projectid": prjctdtlid,
      "uid": uid,
      "index": ES_INDEX,
      "type": this.props.getdashboardChange.Dashboard,
      "dashboard":state.getStorableState()
      ,
      "id": this.props.getdashboardChange.UpdateId
    }
    this.props.chartSaving(param)
    this.setState({modalStatus:false})
  }
  checkBoxchange=(e)=> {
    let val;
    if (e.target.value === "false") {
      val = true
    } else {
      val = false
    }
    const {id, dropzoneID, parentID} = this.props;
    let payload = this.state.payload
    payload[e.target.name] = val
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
      this.graphData()
    });
  }
  /*  drag and drop functions for hierarchial table */

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
        this.state.items,
        result.source.index,
        result.destination.index
    );

    this.setState({
      items
    });
  };
  addData=()=>{
    let data=this.state.items
    let counter=data.length+1
    let newData={
      id: "item-"+new Date().getTime(),
      label: "item "
    }
    let _data=data.concat(newData)
    this.setState({items:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  removeData=(dragid)=>{
    let data=this.state.items
    let _data=data.filter(item=>item.id !== dragid)
    this.setState({items:_data})
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=_data
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleLabelChange=(e,dragid)=>{
    var _newState = this.state.items;
    var index=(_newState.findIndex(obj => obj["id"] === dragid))
    _newState[index][e.target.name]=e.target.value;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=this.state.items
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });
  }
  handleConditionChange=(e)=>{
    var _newState = this.state.condition;
    if(e.target.name ==='field') {
      let t = e.target.value.split("|");
      _newState[0]["field"] = t[0];
      _newState[0]["type"] =  t[1] === "datepicker" ?"date":t[1];
    }
    else{
      _newState[0][e.target.name] = e.target.value;
    }


    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["condition"]=this.state.condition
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });



  }
  handleColorChange=(color,param)=>{

    var _newState = this.state.items;
    var index=(_newState.findIndex(obj => obj["id"] === param))
    _newState[index]["color"]=color;
    const { id, dropzoneID, parentID }= this.props;
    let payload=this.state.payload
    payload["graph"]=this.state.items
    this.setState({payload}, () => {
      state.updateElement(id, dropzoneID, parentID, {
        payload: payload
      });
    });

  }
  handleClick=(status)=>{
    this.setState({colorpickerStatus:status})
  }

  render() {
    ////console.log(this.state);
    const {
      id,
      showBasicContent, showPreview,
      dropzoneID,payload, parentID, name
    } = this.props;


    const chartTitle =  this.state.chartTitle || payload && payload.chartTitle;
    const collection =  this.state.collection || payload && payload.collection ;
    const TrendField =  this.state.trendfield || payload && payload.trendfield ;
    const ShowField =  this.state.showfield || payload && payload.showfield ;
    const trendData =this.state.trendData||[]
    const showData=(trendData.length !== 0 && trendData.collectedform.filter(list => list.masterid === ShowField))
    const trenData=(trendData.length !== 0 && trendData.collectedform.filter(list => list.masterid === TrendField))
    //console.log(showData.length !==0 && showData !== false && showData[0].value[0].value)
    //console.log(trenData)
    let showlistField = []
    if(this.state.showlistField.length !==0) {
      for (let i = 0; i <this.state.showlistField.pages.length; i++) {
        (this.state.showlistField.pages[i].components.filter(list=>list.showlist === 'Yes' && list.visibility !=="hidden")).map(list=>{
          showlistField.push(list)
        })
      }
    }

    let collections=(this.props.listAllCollections.ListAllCollections.data)
    if (showBasicContent) {
      return (
          <Draggable { ...this.props } >
            {
              <span className="drag-icon">{name}</span>
            }
          </Draggable>
      )
    }

    if (showPreview) {
      return (

          <div className="ind-small-tab-dashboard">
            <div className="small-tab-header with-desc-box">
              <h5>{chartTitle} </h5>
            </div>
            <div className="small-tab-footer">
              <h5>{showData.length !==0 && showData !==false && showData[0].value[0].value}</h5>
              <div className="small-tab-graph">
                <span className={trenData.length !==0 && trenData !== false && trenData[0].value[0].value ==="up" ?"high-val":"low-val"}> </span>
              </div>
            </div>
          </div>



      );
    }
    return (
        <React.Fragment>

          <Draggable {...this.props}
                     draggable={!this.props.DragDisabled.dragStatus}
          >
            <SettingsPanel
                id={id}
                name={chartTitle?chartTitle:name}
                dropzoneID={dropzoneID}
                parentID={parentID}
                openSetting={this.openSetting}
                removeElement={<div className="delete-icon" onClick={() => state.removeElement(id, dropzoneID, parentID)}></div>}
            />
          </Draggable>
          {this.state.modalStatus &&
          <SimpleModal
              Close={this.openSetting}
              title={`Trend Box - Setting`}
              haveFooter={1}
              OKLabel="Save"
              OKAction={this.chartSaving}
              asignedUsershow={false}
              cancelLabel="Cancel"
              cancelAction={this.cancelAction}
          >
            <div className="DashBuilder-modal">
              <div className="task-input-container">
                <div className="new-task-input">
                  <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                         autoComplete="off" value={chartTitle}
                         onChange={(e) => this.handleChange(e)}/>
                </div>

              </div>

              <div className="task-input-container margin-b-10">
                <label>Collections:</label>
                <div className="select-box eq-width">
                  <select id="multi-area-chart-collections" name="collection"
                          onChange={(e) => this.handleInputChange(e)} value={collection}>

                    <option value="">Collections</option>
                    {collections.map((list, index) =>
                        <option key={`multi-area-chart-${index}`}
                                value={list._id}>{atob(list.title)}</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="task-input-container margin-b-10">
                <label>Show Field:</label>
                <div className="select-box eq-width">
                  <select id="multi-area-chart-collections" name="showfield"
                          onChange={(e) => this.handleInputChange(e)} value={ShowField}>
                    <option value="">Show Field</option>
                    {showlistField.map((label, index) =>
                        <option
                            key={`multi-area-chart-masterid` + index}
                            value={label.masterid}>{label.label}</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="task-input-container margin-b-10">
                <label>Trend Field:</label>
                <div className="select-box eq-width">
                  <select id="multi-area-chart-collections" name="trendfield"
                          onChange={(e) => this.handleInputChange(e)} value={TrendField}>

                    <option value="">Trend Field</option>
                    {showlistField.map((label, index) =>
                        <option
                            key={`multi-area-chart-masterid` + index}
                            value={label.masterid}>{label.label}</option>
                    )}
                  </select>
                </div>
              </div>








            </div>


          </SimpleModal>
          }


        </React.Fragment>
    )
  }
};
const mapStateToProps = state => ({
  showFormModal:state.CollectionsReducer.showFormModal,
  listAllCollections: state.CollectionsReducer.listAllCollections,
  getdashboardChange:state.DynamicDashboard.getDashboard,
  DragDisabled:state.DynamicDashboard.getDragDisabled
});
export default  withRouter(connect(mapStateToProps, {
  collections: collectionActions.listallcollections,
  showform : collectionActions.showform,
  chartSaving:DynamicDashboard.saveDashboarddata,
  dragStatus :DynamicDashboard.dragStatus,

})(TrendBoxContainer));
