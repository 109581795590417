import React, {Component} from 'react';
import {ES_INDEX, FRMSIMG_PATH, USRIMG_PATH} from "../../../constants";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import moment from 'moment';
import * as projectActions from "../../../actions/Project";
import {Datecomparison, Datetimecomparison, getUrlSegment} from "../../../utilities/CustomFunctions";
import * as commonActions from "../../../actions/Common";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal";
import AddNewProjectDiscussion from "./AddNewProjectDiscussion";
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import NoData from "../../views/NoData";
import ProjectHeader from "../../views/ProjectHeader";
import {Tooltip,OverlayTrigger} from "react-bootstrap"

class ProjectDiscussions extends Component {

    constructor(props) {
        super(props);

        this.state={
            projectid:'',
            Addopen:false,
            Editopen:false,
            userlist: 'false',
            showremarksModal: true,
        }
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.AddmodalClose = this.AddmodalClose.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.closeremarksModal = this.closeremarksModal.bind(this);
        this.handleUserSelect = this.handleUserSelect.bind(this);
        this.saveUserlist = this.saveUserlist.bind(this);
    }



    componentDidMount() {
        this.setState({loading: 1});
        var prjctdtlid = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/discussions/'+prjctdtlid);
        var projectid = (atob(getUrlSegment(4)));
        //console.log(projectid)
        this.setState({projectid: projectid})
        let vdata ={
            "_id": projectid,
        }
        this.props.projectdetail(vdata)
        let Ddata ={
            "projectid": projectid,
            "systype": getProfile().usertype,
            "uid": getProfile().id,
            "from": 0,
            "size": 50,

        }
        this.props.getprojectDiscussions(Ddata)
        let Pdata ={
            "projectid": projectid,
        }
        this.props.projectuserdetail(Pdata);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        var projectid = (atob(getUrlSegment(4)));
        //console.log( projectid)
        var  beta =  this;
        if(prevProps.discussionAdddata.project_discussion !== this.props.discussionAdddata.project_discussion ) {
            setTimeout(function () {
                let Gdata ={
                    "projectid": projectid,
                    "systype": getProfile().usertype,
                    "uid": getProfile().id,
                    "from": 0,
                    "size": 50,

                }
                beta.props.getprojectDiscussions(Gdata);
            }, 1000)
            this.AddmodalClose();


        }

        if(prevProps.userlistAssignmoredata.assigned_users !== this.props.userlistAssignmoredata.assigned_users ) {
            setTimeout(function () {
                let Ddata ={
                    "projectid": projectid,
                    "systype": getProfile().usertype,
                    "uid": getProfile().id,
                    "from": 0,
                    "size": 50,

                }
                beta.props.getprojectDiscussions(Ddata);
            }, 1000)



        }

    }

    AddmodalOpen(e, pid){
        e.stopPropagation();

        if(pid){
            this.setState({prjct_id : pid});
            let projectdata = {_id: pid}
           // this.props.projectInfo(projectdata);
        }
        this.setState({Addopen : true});
    }

    AddmodalClose(){
        this.setState({Addopen : false});
    }

    handleUserChange(e, userlist, assignees, discussion_id){

        //console.log(assignees);
        let userdetail = this.props.listAllProjectUserData.getprojectusers;
        let user_ids = userdetail.map(a => parseInt(a.user_id));
        //console.log(user_ids);
        let assigneeslist =  assignees.map(a => parseInt(a.assigneeuser_id)) ;
        //console.log(assigneeslist);

        this.setState({
            userlist: userlist,
            user_ids: user_ids,
            assigneeslist: assigneeslist,
            discussion_id: discussion_id
        })
    }
    closeremarksModal(){
        this.setState({userlist : false});
    }
    handleUserSelect(e, selectUserid){

        selectUserid = parseInt(selectUserid);
        //console.log(this.state.assigneeslist )
        let assigneeslist = [...this.state.assigneeslist];
        (assigneeslist.indexOf(selectUserid) > -1) ? assigneeslist.splice(assigneeslist.indexOf(selectUserid), 1) : assigneeslist.push(selectUserid);
        this.setState({assigneeslist: assigneeslist});

    }
    saveUserlist(){

        //console.log(this.state.assignee_ids )
        var assigneeslist = this.state.assigneeslist;
        var discussion_id = this.state.discussion_id;
        var projectid = (this.state.projectid =='0' ? moment().valueOf() : this.state.projectid);

        let assignmoreusers = {
            projectid: projectid,
            discussion_id: discussion_id,
            assignees: assigneeslist,
        };

        //console.log(assignmoreusers);
         this.props.saveAssignedusers(assignmoreusers);
        this.setState({userlist : false});

    }

    render() {

        let projectdiscussions = this.props.projectdiscussionsdata.getprojectdiscussions;
        let userlength = projectdiscussions.length;
        let userdetail = this.props.listAllProjectUserData.getprojectusers;
        //console.log(this.state.projectid)
      //  //console.log(projectdiscussions)
        //console.log(userdetail);
        //console.log(this.state.assigneeslist);

        return(

            <div className="list-wrapper">
                {this.props.projectdiscussionsdata.loading &&
                <span className="Loader-holder">
                                                <LoaderSvg/>
                                            </span>
                }
                <div className="form-detail-container data-view field-form">
                    {(projectdiscussions.length === 0) &&
                    <div className='absolute-holder'>
                        <NoData

                            msg={`No Discussions`}

                        />
                    </div>
                    }
                    <ProjectHeader/>
                    <div className="pj-board-container">
                        <div className="pj-chat-wrapper">
                            {projectdiscussions.map((list,index) =>
                            <div className="ind-pj-chat-list" key={index}>
                                <div className="pj-chat-list-left"
                                     onClick={(e)=>this.props.history.push('/project/details/discussions/details/'+btoa(this.state.projectid)+'/'+btoa(list.discussion_id))} >
                                    <div className="chat-list-left-top">
                                        <div className="chat-list-left-top-left">
                                            <img src={USRIMG_PATH + list.creator_img_name}/>
                                        </div>
                                        <div className="chat-list-left-top-right">
                                            <h5 >{list.topic}</h5>
                                            {list.messages !== '' && list.messages[0] !== undefined &&
                                            <p className="ico-lib-v1 comm-ico">Latest reply from @<span className="mention">
                                                { list.messages[0].messageuser_fname}</span>
                                                {' ' + moment.utc(list.messages[0].posteddate).format('DD MMM YYYY') + ', ' + moment.utc(list.messages[0].posteddate).local().format('LT')}
                                            </p>
                                            }
                                        </div>
                                    </div>
                                    <div className="chat-list-left-bottom">
                                        <p>{list.description}</p>
                                    </div>
                                </div>
                                <div className="pj-chat-list-right">
                                    {/*<div className="chat-list-right-top">*/}
                                    {/*    <div className="chat-list-right-options">*/}
                                    {/*        <div className="pin-block ">*/}
                                    {/*            <button className="ico-lib-v1 pinlock-ico"></button>*/}
                                    {/*        </div>*/}

                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="chat-list-right-bottom">
                                        <ul className="pj-added-user">
                                            { list.assignees.map((item,index) =>

                                                <OverlayTrigger
                                                    key={`1`}
                                                    placement={`bottom`}
                                                    overlay={

                                                        <Tooltip id={`tooltip-top`} className={'test'}>
                                                            {item.assigneeuser_fname}
                                                        </Tooltip>
                                                    }>


                                                <li key={index} className={index > 1 ? 'imageRemove' : ''}>
                                                    <div className="ind-added-user-list" key={index}>
                                                        <img src={USRIMG_PATH + item.assigneeuser_img_name}/>
                                                    </div>
                                                </li>
                                                </OverlayTrigger>
                                            )}
                                            <li onClick={(e)=>this.handleUserChange(e, 'true', list.assignees, list.discussion_id)} data-target="#disUserlist" data-id="146850132206">
                                                <button className="float-add-button" ></button>
                                            </li>
                                        </ul>
                                        <p className="ico-lib-v1 comment-pj">{list.messagecnt} Comments</p>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="float-button-block" data-toggle="tooltip" data-placement="top" title="New Discussion" onClick={(e)=>this.AddmodalOpen(e, this.state.projectid)}>
                    <button className="float-button ico-lib float-add"></button>
                </div>

                {this.state.Addopen &&
                <Modal title={(this.state.prjct_id ? 'New Discussion Topic' : 'Discussion Topic')} Close ={this.AddmodalClose.bind(this)}>
                    <AddNewProjectDiscussion
                        AddmodalClose = {this.AddmodalClose}
                        listtags={userdetail}
                        userlength = {userlength}
                    />

                </Modal>
                }

                {this.state.userlist === 'true' &&
                <div className="modal fade bsy-modal s-modal show x-large"  tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" style={{display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">User List</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closeremarksModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="mang-lbl-block un-tick">
                                    <div className="user-list-container" id="Usrlst">

                                        {userdetail.map((list,index) =>
                                            <div key={index} className={`${this.state.assigneeslist.indexOf(parseInt(list.user_id)) > -1 ? 'selected-user':''}`}>
                                                <div className="ind-user-list" data-action="filter"
                                                     data-hideuser={list.user_id}>
                                                    <div className="ind-user-list-left" onClick={(e)=>this.handleUserSelect(e, list.user_id, )}>
                                                        <div className="ind-user-img"><img
                                                            src={USRIMG_PATH + list.img_name} />
                                                        </div>
                                                        <div className="ind-user-name" >
                                                            {list.first_name + ' ' + list.last_name}</div>
                                                    </div>
                                                    <div className="ind-user-list-right">
                                                        <div className="ind-user-list-right">
                                                            <div className="usr-select-button" style={{display:'none'}}><input
                                                                name="text_searchable" type="checkbox" value={list.user} className="input-check" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="general-btn grey" data-dismiss="modal"  onClick={this.closeremarksModal}>Cancel</button>
                                <button type="button" className="general-btn" onClick={this.saveUserlist}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                }


            </div>



        );
    }
}
const mapStateToProps = state => ({

    projectdiscussionsdata:state.ProjectReducer.GetProjectDiscussions,
    discussionAdddata:state.ProjectReducer.SaveProjectDiscussion,
    listAllProjectUserData:state.ProjectReducer.GetProjectUsers,
    userlistAssignmoredata:state.ProjectReducer.SaveAssignedUsers,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getprojectDiscussions : projectActions.getProjectDiscussions,
    projectuserdetail:projectActions.getProjectUsers,
    saveAssignedusers:projectActions.saveAssignedUsers,
    projectdetail:projectActions.listallprojectsdetails,
})(ProjectDiscussions));
