import React from 'react';
import ColorPicker from "../../../../../dashboardBuilder/elements/views/ColorPicker";
class EntityModal extends React.Component {
    render() {

        return(<div className="modal bsy-modal lg-modal body-grey fade show" id="modalPreview" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" style={{"display": "block"}}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">{this.props.title}</h5>
                        <div class="modal-header-options">
                            <button class="modal-option-button" onClick={()=>this.props.Close()}><span class="icon-in icon-close-in"></span></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="chart-settings-container">




                            {this.props.containerData.hasOwnProperty("display") &&
                            <div className="chart-settings-splitter">
                                <div className="chart-settings-splitter-header">
                                    <h5>Display Properties</h5>
                                </div>
                                {this.props.containerData.hasOwnProperty("chartTitle") &&
                                <div className="each-settings-panel-wrap">
                                    <div class="each-settings-panel-wrap-header">
                                        <h5 className="each-settings-panel-header">Chart Details</h5>
                                    </div>
                                    <div class="each-settings-panel-wrap-body">
                                        {this.props.containerData.hasOwnProperty("chartTitle") &&
                                        <div className="each-inp-field">
                                            <label>Chart Title</label>
                                            <div className="new-task-input">
                                                <input type="text" placeholder="title" id="chartTitle" name="chartTitle"
                                                       autoComplete="off" value={this.props.containerData.chartTitle}
                                                       onChange={(e) => this.props.handleChange(e)}/>
                                            </div>
                                        </div>
                                        }
                                        {this.props.containerData.hasOwnProperty("subTitle") &&
                                        <div className="each-inp-field">
                                            <label>Subtitle</label>
                                            <div className="new-task-input">
                                                <input type="text" placeholder="subtitle" id="subTitle" name="subTitle"
                                                       autoComplete="off" value={this.props.containerData.subTitle}
                                                       onChange={(e) => this.props.handleChange(e)}/>
                                            </div>
                                        </div>
                                        }
                                        {this.props.containerData.hasOwnProperty("subTitleEnabled") &&
                                        <div className="select-chk-block" style={{"display": "block"}}>
                                            <label className="control control-checkbox">Enable Subtitle
                                                <input className="inp-chk" type={`checkbox`} name={`subTitleEnabled`}
                                                       checked={this.props.containerData.subTitleEnabled}
                                                       value={this.props.containerData.subTitleEnabled}
                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                <div className="control-indicator"></div>
                                            </label>
                                        </div>
                                        }
                                        {this.props.containerData.hasOwnProperty("buttonUrl") &&
                                        <div className="each-inp-field">
                                            <label>Button Url</label>
                                            <div className="new-task-input">
                                                <input type="text" placeholder="buttonUrl"  name="buttonUrl"
                                                       autoComplete="off" value={this.props.containerData.buttonUrl}
                                                       onChange={(e) => this.props.handleChange(e)}/>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>
                                }



                                {this.props.containerData.hasOwnProperty("buttoncolor") &&
                                <div className="chart-settings-container for-small">
                                    <div className="chart-settings-splitter">
                                        <div className="each-settings-panel-wrap">
                                            <div className="each-settings-panel-wrap-header">
                                                <h5 className="each-settings-panel-header">Button Color</h5>
                                            </div>
                                            <div className="each-settings-panel-wrap-body">

                                                <div className="select-chk-block" style={{"display": "block"}}>
                                                    <label className="control control-checkbox">Background Color
                                                        <ColorPicker
                                                            stroke={`bkColr`}
                                                            color={this.props.containerData.bkColr}
                                                            onChangeComplete={this.props.onChangeComplete}
                                                            handleClickStaus={this.props.handleClick}
                                                        />
                                                    </label>
                                                </div>

                                                <div className="select-chk-block" style={{"display": "block"}}>
                                                    <label className="control control-checkbox">Text Color
                                                            <ColorPicker
                                                                stroke={`TxtColr`}
                                                                color={this.props.containerData.TxtColr}
                                                                onChangeComplete={this.props.onChangeComplete}
                                                                handleClickStaus={this.props.handleClick}
                                                            />
                                                    </label>
                                                </div>




                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }



                            </div>
                           }


                        </div>
                    </div>

                        <div className="modal-footer">
                            <button type="button" className="general-btn grey btn btn-primary"
                                    onClick={() => this.props.cancelAction()}>Ok
                            </button>

                        </div>

                </div>
            </div>
            </div>)
    }

}


export default EntityModal
