import axios from 'axios'
import {API_PATH,ES_INDEX} from '../constants/index'
import {getProfile} from "../utilities/AuthService";

 const pdfbuilder = {
     listallfields,


};
export default pdfbuilder;

function listallfields(uid,usertype){
    return axios.get(API_PATH + 'user/allusers?uid='+uid+'&usertype='+usertype+'&'+'&index='+ES_INDEX+'&'+ Math.floor(Date.now() / 1000),{

    })
    // return axios.get(API_PATH + 'chat/chatusers?uid='+uid+'&offset=20&index=lsinternalcopy',{
    //
    // })


}