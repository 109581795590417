import React from 'react';
import ColorPicker from "../../views/ColorPicker";
import {DragDropContext, Draggable as Drags, Droppable} from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : "#fff",
    ...draggableStyle
});

class EntityModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardFields: []
        }
    }

    componentDidMount() {
        this.setTableData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.containerData.cardFields !== prevProps.containerData.cardFields) {
            this.setTableData()
        }
    }

    setTableData = () => {
        this.setState({
            cardFields: this.props.containerData.cardFields
        })
    }

    handleLabelChange = (e, itemId, type) => {
        let fieldId = e.target.value;
        let tabledata = this.state.cardFields || [];

        let comps = tabledata.filter(tbl => tbl.id === itemId);
        //console.log(comps)

        if (comps.length > 0) {
            comps[0].field = fieldId;

            let fieldData = this.props.containerData.showlistField.filter(item => item.masterid.toString() === fieldId.toString() && item.type === 'datepicker' && item.datetime === 'Yes');

            if (fieldData.length > 0) {
                comps[0].datewithtime = true;
            } else {
                comps[0].datewithtime = false;
            }
        }
        this.props.handleLabelChange(e, itemId, type);
    }

    render() {
        let collections = this.props.containerData.collectionData
        let collection = this.props.containerData.collection
        let enableyaxis = this.props.containerData.enableyaxis
        let legend = this.props.containerData.legend
        let alldata = this.props.containerData.alldata
        let showlistField = this.props.containerData.showlistField
        let enablegradient = this.props.containerData.enablegradient
        let hidearea = this.props.containerData.hidearea
        let sourceType = this.props.containerData.sourceType;
        let condition = this.props.containerData.condition
        let whereCondition = this.props.containerData.whereCondition;
        let collectionFilters = this.props.containerData.collectionFilter || [];
        console.log(showlistField)

        let cardFields = this.state.cardFields;


        return (
            <div className="modal bsy-modal lg-modal body-grey fade show" id="modalPreview" tabIndex="-1" role="dialog"
                 aria-labelledby="exampleModalCenterTitle" style={{"display": "block"}}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.props.title}</h5>
                            <div className="modal-header-options">
                                <button className="modal-option-button" onClick={() => this.props.Close()}><span
                                    className="icon-in icon-close-in"></span></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="chart-settings-container">

                                {this.props.containerData.hasOwnProperty("data") &&
                                <div className="chart-settings-splitter">
                                    <div className="chart-settings-splitter-header">
                                        <h5>Data</h5>
                                    </div>
                                    {this.props.containerData.hasOwnProperty("sourceType") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Data Set</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="each-inp-field">
                                                <label>Select Type</label>
                                                <div className="radio-wrap">
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Collection
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`sourceType`}
                                                                   checked={sourceType.toString() === "true"}
                                                                   value={true}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                    <div className="radio-block">
                                                        <label className="control control-checkbox">Entity
                                                            <input className="inp-chk" type={`radio`}
                                                                   name={`sourceType`}
                                                                   checked={sourceType.toString() === "false"}
                                                                   value={false}
                                                                   onClick={(e) => this.props.radioBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {sourceType.toString() === "true" ?
                                                <div className="each-inp-field">
                                                    <label>Choose Collection</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="collection"
                                                                name="collection"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={collection}>>
                                                            <option value="">Choose Collection</option>
                                                            {collections.map((list, index) =>
                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list.title)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                :
                                                <div className="each-inp-field">
                                                    <label>Choose Entity</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="collection"
                                                                onChange={(e) => this.props.handleInputChange(e)}
                                                                value={collection}>
                                                            <option value="">Choose Entity</option>
                                                            {collections.map((list, index) =>

                                                                <option key={`multi-area-chart-${index}`}
                                                                        value={list._id}>{atob(list._source.name)}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("limit") &&
                                            <div className="each-inp-field">
                                                <label>Limit</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="Limit" id="limit"
                                                           name="limit"
                                                           autoComplete="off"
                                                           value={this.props.containerData.limit}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>

                                            </div>
                                            }
                                        </div>

                                        {this.props.containerData.hasOwnProperty("condition") &&
                                        <>
                                            <div className="each-settings-panel-subheader">
                                                <h5>Condition</h5>
                                            </div>
                                            <div className="each-settings-panel-wrap-body">
                                                <div className="task-input-container">
                                                    {this.props.containerData.hasOwnProperty("whereCondition") &&
                                                    <>
                                                        <div className="each-inp-field">
                                                            <label>Conditions</label>
                                                            {this.props.containerData.whereCondition.map((item, index) =>
                                                                <div className={'panel-each'}>
                                                                    <div className={`settings-panel`}>
                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="field"
                                                                                    onChange={(e) => this.props.handleConditionLabelChange(e, item.id, "whereCondition", item.field)}
                                                                                    value={item.field}>
                                                                                <option value="">Conditions</option>
                                                                                {showlistField.filter(list => (list.type === "number" || list.type === "switch" || list.type === "dropdown" || list.type === "checkbox" || list.type === "text" || list.type === "datepicker")).map((label, index) =>
                                                                                    <option
                                                                                        key={`multi-area-chart-masterid` + index}
                                                                                        value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                        showlistField.filter(list => list.masterid === item.field && (list.type === "switch" || list.type === "dropdown" || list.type === "checkbox"))[0] &&
                                                                        <div className="select-box">
                                                                            <select id="yaxis" name="conditions"
                                                                                    onChange={(e) => this.props.handleConditionLabelChange(e, item.id, "whereCondition", "conditions")}
                                                                                    value={item.conditions}>
                                                                                <option value="">Conditions</option>
                                                                                {showlistField.filter(list => list.masterid === item.field).length !== 0 && showlistField.filter(list => list.masterid === item.field)[0].option.map((lab, index) =>
                                                                                    <option
                                                                                        key={`multi-area-chart-masterid` + index}
                                                                                        value={lab}>{lab}</option>
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        }

                                                                        {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                        showlistField.filter(list => list.masterid === item.field && list.type === "number")[0] &&
                                                                        <>
                                                                            <input type={`text`} name="min"
                                                                                   defaultValue={item.min}
                                                                                   placeholder={`min`}
                                                                                   onChange={(e) => this.props.handleConditionLabelChange(e, item.id, "whereCondition", "number")}
                                                                            />
                                                                            <input type={`text`} name="max"
                                                                                   placeholder={`max`}
                                                                                   defaultValue={item.max}
                                                                                   onChange={(e) => this.props.handleConditionLabelChange(e, item.id, "whereCondition", "number")}
                                                                            />
                                                                        </>
                                                                        }

                                                                        {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                        showlistField.filter(list => list.masterid === item.field && list.type === "datepicker")[0] &&
                                                                        <>
                                                                            <input type={`date`} name="from"
                                                                                   defaultValue={item.from}
                                                                                   placeholder={`from`}
                                                                                   onChange={(e) => this.props.handleConditionLabelChange(e, item.id, "whereCondition", "datepicker")}
                                                                            />
                                                                            <input type={`date`} name="to"
                                                                                   defaultValue={item.to}
                                                                                   placeholder={`to`}
                                                                                   onChange={(e) => this.props.handleConditionLabelChange(e, item.id, "whereCondition", "datepicker")}
                                                                            />
                                                                        </>
                                                                        }
                                                                        {showlistField.filter(list => list.masterid === item.field).length !== 0 &&
                                                                        showlistField.filter(list => list.masterid === item.field && list.type === "text")[0] &&
                                                                        <>
                                                                            <input type={`text`} name="text"
                                                                                   defaultValue={item.text}
                                                                                   placeholder={`text`}
                                                                                   onChange={(e) => this.props.handleConditionLabelChange(e, item.id, "whereCondition", "text")}
                                                                            />

                                                                        </>
                                                                        }


                                                                    </div>
                                                                    <div className={`settings-panel-option`}>
                                                                        {index + 1 === whereCondition.length &&
                                                                        <button className="ico-lib divider-add-button"
                                                                                onClick={() => this.props.addData(item.id, "whereCondition")}>

                                                                        </button>
                                                                        }

                                                                        {whereCondition.length !== 1 &&
                                                                        <button className="ico-lib divider-sub-button"
                                                                                onClick={() => this.props.removeData(item.id, "whereCondition")}>

                                                                        </button>
                                                                        }
                                                                    </div>

                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="each-inp-field">
                                                            <label>Limit</label>
                                                            <div className="new-task-input">
                                                                <input type="text" placeholder="Limit" id="limit"
                                                                       name="limit"
                                                                       autoComplete="off"
                                                                       value={this.props.containerData.limit}
                                                                       onChange={(e) => this.props.handleChange(e)}/>
                                                            </div>

                                                        </div>
                                                    </>
                                                    }

                                                </div>
                                            </div>
                                        </>
                                        }
                                    </div>
                                    }
                                    {this.props.containerData.hasOwnProperty("linkedcol") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="task-input-container">
                                            <div className="each-settings-panel-wrap-header">
                                                <h5 className="each-settings-panel-header">Set tab fields</h5>
                                            </div>


                                            <div className="each-settings-panel-wrap-body">
                                                {this.props.containerData.tabledata.map((item, index) =>
                                                    <div className={'panel-each'}>
                                                        <div className={`settings-panel`}>
                                                            <div className="each-inp-field">
                                                                <label>Fields</label>
                                                                <div className="settings-panel">


                                                                    <input type="text" placeholder="label" name="label"
                                                                           value={item.label}
                                                                           autoComplete="off"
                                                                           onChange={(e) => this.props.handleLabelChange(e, item.id, "tabledata")}/>

                                                                    <input type="text" placeholder="id" name="id"
                                                                           autoComplete="off" value={item.id}
                                                                           onChange={(e) => this.props.handleLabelChange(e, item.id, "tabledata")}/>

                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div className={`settings-panel-option`}>
                                                            {index + 1 === this.props.containerData.tabledata.length &&
                                                            <button className="ico-lib divider-add-button"
                                                                    onClick={() => this.props.addData(item.id, "tabledata")}>

                                                            </button>
                                                            }

                                                            {this.props.containerData.tabledata.length !== 1 &&
                                                            <button className="ico-lib divider-sub-button"
                                                                    onClick={() => this.props.removeData(item.id, "tabledata")}>

                                                            </button>
                                                            }
                                                        </div>


                                                    </div>
                                                )}


                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.props.containerData.hasOwnProperty("entityField") &&

                                    <div className="each-settings-panel-wrap">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Data Set</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            <div className="each-inp-field">
                                                <label>Entity Field</label>
                                                <div className="select-box eq-width">
                                                    <select name="label"
                                                            onChange={(e) => this.props.handleChangeComplete(e)}
                                                            value={this.props.containerData.label}>

                                                        <option value="">Please select</option>
                                                        {this.props.containerData.showlistField.map((label, index) =>
                                                            <option key={`masterid` + index}
                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    }


                                </div>
                                }

                                {this.props.containerData.hasOwnProperty("cardFields") &&
                                <div className="each-settings-panel-wrap">
                                    <div class="task-input-container">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Fields</h5>
                                        </div>

                                        <div className="each-settings-panel-wrap-body">
                                            <div>

                                                <div className={'panel-each'}>
                                                    <div className={`settings-panel`}>
                                                        <input type={`text`} name="main_head_title"
                                                               placeholder={"Main Head Title"}
                                                               defaultValue={""}
                                                               onChange={(e) => this.props.handleLabelChange(e, "main_head_title", "graph")}/>


                                                        <div className="select-box">
                                                            <select id="yaxis" name="main_head_field"
                                                                    onChange={(e) => this.handleLabelChange(e, "main_head_field", "graph")}
                                                                    value={""}>
                                                                <option value="">Select</option>
                                                                {showlistField.map((label, index) =>
                                                                    <option
                                                                        key={`multi-area-chart-masterid` + index}
                                                                        value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                )}
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">


                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                <Droppable droppableId="droppable">
                                                    {(droppableProvided, droppableSnapshot) => (
                                                        <div ref={droppableProvided.innerRef}>
                                                            {cardFields.map((item, index) => (
                                                                <Drags key={item.id} draggableId={item.id}
                                                                       index={index}
                                                                       isDragDisabled={this.props.colorpickerStatus}>
                                                                    {(draggableProvided, draggableSnapshot) => (
                                                                        <div className={'panel-each'}
                                                                             ref={draggableProvided.innerRef}
                                                                             {...draggableProvided.draggableProps}
                                                                             {...draggableProvided.dragHandleProps}
                                                                             style={getItemStyle(
                                                                                 draggableSnapshot.isDragging,
                                                                                 draggableProvided.draggableProps.style
                                                                             )}
                                                                             isDragDisabled={this.props.colorpickerStatus}
                                                                        >
                                                                            <div className={`settings-panel`}>
                                                                                <input type={`text`} name="label"
                                                                                       defaultValue={item.label}
                                                                                       onChange={(e) => this.props.handleLabelChange(e, item.id, "graph")}/>


                                                                                <div className="select-box">
                                                                                    <select id="yaxis" name="field"
                                                                                            onChange={(e) => this.handleLabelChange(e, item.id, "graph")}
                                                                                            value={item.field}>{item.type}
                                                                                        <option value="">Select</option>
                                                                                        {showlistField.map((label, index) =>
                                                                                            <option
                                                                                                key={`multi-area-chart-masterid` + index}
                                                                                                value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                                        )}
                                                                                    </select>
                                                                                </div>

                                                                            </div>
                                                                            {(this.props.hasOwnProperty("repeater") && !this.props.repeater) || this.props.repeater || !this.props.hasOwnProperty("repeater") &&
                                                                            <div
                                                                                className={`settings-panel-option`}>
                                                                                {index + 1 === cardFields.length &&
                                                                                <button
                                                                                    className="ico-lib divider-add-button"
                                                                                    onClick={() => this.props.addData(item.id, "items")}></button>
                                                                                }

                                                                                {cardFields.length !== 1 &&
                                                                                <button
                                                                                    className="ico-lib divider-sub-button"
                                                                                    onClick={() => this.props.removeData(item.id, "items")}></button>
                                                                                }
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </Drags>
                                                            ))}
                                                            {droppableProvided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                    </div>
                                </div>
                                }

                                {this.props.containerData.hasOwnProperty("display") &&
                                <div className="chart-settings-splitter">
                                    <div className="chart-settings-splitter-header">
                                        <h5>Display Properties</h5>
                                    </div>
                                    {this.props.containerData.hasOwnProperty("chartTitle") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="each-settings-panel-wrap-header">
                                            <h5 className="each-settings-panel-header">Chart Details</h5>
                                        </div>
                                        <div className="each-settings-panel-wrap-body">
                                            {this.props.containerData.hasOwnProperty("chartTitle") &&
                                            <div className="each-inp-field">
                                                <label>Chart Title</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="title" id="chartTitle"
                                                           name="chartTitle"
                                                           autoComplete="off"
                                                           value={this.props.containerData.chartTitle}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("subTitle") &&
                                            <div className="each-inp-field">
                                                <label>Subtitle</label>
                                                <div className="new-task-input">
                                                    <input type="text" placeholder="subtitle" id="subTitle"
                                                           name="subTitle"
                                                           autoComplete="off" value={this.props.containerData.subTitle}
                                                           onChange={(e) => this.props.handleChange(e)}/>
                                                </div>
                                            </div>
                                            }
                                            {this.props.containerData.hasOwnProperty("subTitleEnabled") &&
                                            <div className="select-chk-block" style={{"display": "block"}}>
                                                <label className="control control-checkbox">Enable Subtitle
                                                    <input className="inp-chk" type={`checkbox`}
                                                           name={`subTitleEnabled`}
                                                           checked={this.props.containerData.subTitleEnabled}
                                                           value={this.props.containerData.subTitleEnabled}
                                                           onClick={(e) => this.props.checkBoxchange(e)}/>
                                                    <div className="control-indicator"></div>
                                                </label>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    }
                                    {this.props.containerData.hasOwnProperty("accessbox") &&
                                    <div className="chart-settings-container for-small">
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap">
                                                <div className="each-settings-panel-wrap-header">
                                                    <h5 className="each-settings-panel-header">User Access</h5>
                                                </div>
                                                <div className="each-settings-panel-wrap-body">
                                                    {this.props.containerData.hasOwnProperty("add") &&
                                                    <div className="select-chk-block" style={{"display": "block"}}>
                                                        <label className="control control-checkbox">Add
                                                            <input className="inp-chk" type={`checkbox`} name={`add`}
                                                                   checked={this.props.containerData.add}
                                                                   value={this.props.containerData.add}
                                                                   onClick={(e) => this.props.checkBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("edit") &&
                                                    <div className="select-chk-block" style={{"display": "block"}}>
                                                        <label className="control control-checkbox">Edit
                                                            <input className="inp-chk" type={`checkbox`} name={`edit`}
                                                                   checked={this.props.containerData.edit}
                                                                   value={this.props.containerData.edit}
                                                                   onClick={(e) => this.props.checkBoxchange(e)}/>
                                                            <div className="control-indicator"/>
                                                        </label>
                                                    </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {this.props.containerData.hasOwnProperty("filterbox") &&
                                    <div className="chart-settings-container for-small">
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap">
                                                <div className="each-settings-panel-wrap-header">
                                                    <h5 className="each-settings-panel-header">Filters</h5>
                                                </div>
                                                <div className="each-settings-panel-wrap-body">
                                                    {collectionFilters.length > 0 && collectionFilters.map((filter, find) =>

                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">{filter.title}
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`filter_list`}
                                                                       checked={this.props.containerData.filter_list.includes(filter.masterid)}
                                                                       value={filter.masterid}
                                                                       onClick={(e) => this.props.filterCheckBoxChange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                    )}
                                                    {collectionFilters.length === 0 &&
                                                    <label>No Filters</label>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {this.props.containerData.hasOwnProperty("displaytype") &&
                                    <div className="chart-settings-container for-small">
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap">
                                                <div className="each-settings-panel-wrap-header">
                                                    <h5 className="each-settings-panel-header">Display type</h5>
                                                </div>
                                                <div className="each-settings-panel-wrap-body">
                                                    {this.props.containerData.hasOwnProperty("noimage") &&
                                                    <div className="select-chk-block" style={{"display": "block"}}>
                                                        <label className="control control-checkbox">No image
                                                            <input className="inp-chk" type={`checkbox`}
                                                                   name={`noimage`}
                                                                   checked={this.props.containerData.noimage}
                                                                   value={this.props.containerData.noimage}
                                                                   onClick={(e) => this.props.checkBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("fullwidth") &&
                                                    <div className="select-chk-block" style={{"display": "block"}}>
                                                        <label className="control control-checkbox">Full Width
                                                            <input className="inp-chk" type={`checkbox`}
                                                                   name={`fullwidth`}
                                                                   checked={this.props.containerData.fullwidth}
                                                                   value={this.props.containerData.fullwidth}
                                                                   onClick={(e) => this.props.checkBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("singleImage") &&
                                                    <div className="select-chk-block" style={{"display": "block"}}>
                                                        <label className="control control-checkbox">Single Image
                                                            <input className="inp-chk" type={`checkbox`}
                                                                   name={`singleImage`}
                                                                   checked={this.props.containerData.singleImage}
                                                                   value={this.props.containerData.singleImage}
                                                                   onClick={(e) => this.props.checkBoxchange(e)}/>
                                                            <div className="control-indicator"></div>
                                                        </label>
                                                    </div>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <div className="chart-settings-container for-small">
                                        {this.props.containerData.hasOwnProperty("enableyaxis") &&
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap ">
                                                <div className="each-settings-panel-wrap-header">
                                                    <h5 className="each-settings-panel-header">Chart Details</h5>
                                                </div>
                                                <div className="each-settings-panel-wrap-body">
                                                    <div className="task-input-container select-line">

                                                        {this.props.containerData.hasOwnProperty("enableyaxis") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Enable Y Axis
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`enableyaxis`}
                                                                       checked={enableyaxis} value={enableyaxis}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }
                                                        {this.props.containerData.hasOwnProperty("legend") &&

                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Legend
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`legend`}
                                                                       checked={legend}
                                                                       value={legend}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>

                                                        }
                                                        {this.props.containerData.hasOwnProperty("alldata") &&

                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">All Data
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`alldata`}
                                                                       checked={alldata}
                                                                       value={alldata}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>

                                                        }

                                                        {this.props.containerData.hasOwnProperty("stacked") &&

                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Stacked
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`stacked`}
                                                                       checked={this.props.containerData.stacked}
                                                                       value={this.props.containerData.stacked}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>

                                                        }
                                                        {this.props.containerData.hasOwnProperty("enablegradient") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Enable Gradient
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`enablegradient`}
                                                                       checked={enablegradient}
                                                                       value={enablegradient}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }
                                                        {this.props.containerData.hasOwnProperty("hidearea") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Hide Area
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`hidearea`}
                                                                       checked={hidearea}
                                                                       value={hidearea}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>
                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {this.props.containerData.hasOwnProperty("xwidth") || this.props.containerData.hasOwnProperty("graphHeight") &&
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap ">
                                                <div className="each-settings-panel-wrap-header">
                                                    <h5 className="each-settings-panel-header">Axis Range</h5>
                                                </div>
                                                <div className="each-settings-panel-wrap-body">
                                                    {this.props.containerData.hasOwnProperty("min") &&
                                                    <div className="each-inp-field">
                                                        <label>Min</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Min" id="min" name="min"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.min}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>

                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("max") &&
                                                    <div className="each-inp-field">
                                                        <label>Max</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Max" id="max" name="max"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.max}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>

                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("xwidth") &&
                                                    <div className="each-inp-field">
                                                        <label>X Width</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="X width" id="xwidth"
                                                                   name="xwidth"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.xwidth}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("xheight") &&
                                                    <div className="each-inp-field">
                                                        <label>X Height</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="X Height" id="xheight"
                                                                   name="xheight"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.xheight}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("ywidth") &&
                                                    <div className="each-inp-field">
                                                        <label>Y Width</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Y Width" id="ywidth"
                                                                   name="ywidth"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.ywidth}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("yheight") &&
                                                    <div className="each-inp-field">
                                                        <label>Y Height</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Y Height" id="yheight"
                                                                   name="yheight"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.yheight}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("graphHeight") &&
                                                    <div className="each-inp-field">
                                                        <label>Graph Height</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Graph Height"
                                                                   id="graphHeight"
                                                                   name="graphHeight"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.graphHeight}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("xAngle") &&
                                                    <div className="each-inp-field">
                                                        <label>X Angle</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="X Angle" id="xAngle"
                                                                   name="xAngle"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.xAngle}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("yAngle") &&
                                                    <div className="each-inp-field">
                                                        <label>Y Angle</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="Y Angle" id="yAngle"
                                                                   name="yAngle"
                                                                   autoComplete="off"
                                                                   value={this.props.containerData.yAngle}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("transparancy") &&
                                                    <div className="each-inp-field">
                                                        <label>Transperency</label>
                                                        <div className="new-task-input">
                                                            <input type="text" placeholder="transparancy"
                                                                   id="transparancy"
                                                                   name="transparancy" autoComplete="off"
                                                                   value={this.props.containerData.transparancy}
                                                                   onChange={(e) => this.props.handleChange(e)}/>
                                                        </div>
                                                    </div>
                                                    }

                                                    {this.props.containerData.hasOwnProperty("bordercolor") &&
                                                    <div className="each-inp-field">
                                                        <label> Border Color</label>
                                                        <div className="new-task-input">
                                                            <ColorPicker
                                                                stroke={`bordercolor`}
                                                                color={this.props.containerData.bordercolor}
                                                                onChangeComplete={this.props.borderColorChange}
                                                                handleClickStaus={this.props.handleClick}
                                                            />
                                                        </div>
                                                    </div>
                                                    }

                                                    {this.props.containerData.hasOwnProperty("bubblecolor") &&
                                                    <>
                                                        <div className="main-container-wrap">
                                                            <label
                                                                className="task-input-container container-flex"> Bubble
                                                                color</label>
                                                        </div>
                                                        <div className="graph-proprties">
                                                            <div
                                                                className="graph-proprties-color modal-panel-container">
                                                                <div className="task-input-container">
                                                                    <div>
                                                                        {this.props.containerData.bubblecolor.map((item, index) => (
                                                                            <div className={'panel-each'}>
                                                                                <div className={`settings-panel`}>
                                                                                    <input type={`text`} name="label"
                                                                                           defaultValue={item.label}
                                                                                           onChange={(e) => this.props.handleLabelChange(e, item.id, "bubblecolor")}
                                                                                    />
                                                                                    &nbsp;&nbsp;
                                                                                    <ColorPicker
                                                                                        stroke={item.id}
                                                                                        color={item.color}
                                                                                        label={`bubblecolor`}
                                                                                        onChangeComplete={this.props.handleBubblecolorChange}
                                                                                        handleClickStaus={this.props.handleClick}
                                                                                    />

                                                                                </div>
                                                                                <div
                                                                                    className={`settings-panel-option`}>
                                                                                    {index + 1 === this.props.containerData.bubblecolor.length &&
                                                                                    <button
                                                                                        className="ico-lib divider-add-button"
                                                                                        onClick={() => this.props.addData(item.id, "bubblecolor")}></button>
                                                                                    }
                                                                                    {this.props.containerData.bubblecolor.length !== 1 &&
                                                                                    <button
                                                                                        className="ico-lib divider-sub-button"
                                                                                        onClick={() => this.props.removeData(item.id, "bubblecolor")}></button>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        ))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }

                                                    {this.props.containerData.hasOwnProperty("strokecolor") &&
                                                    <>
                                                        <div className="main-container-wrap">
                                                            <label
                                                                className="task-input-container container-flex"> Stroke
                                                                color</label>
                                                        </div>
                                                        <div className="graph-proprties">
                                                            <div
                                                                className="graph-proprties-color modal-panel-container">
                                                                <div className="task-input-container">
                                                                    <div>
                                                                        {this.props.containerData.strokecolor.map((item, index) => (
                                                                            <div className={'panel-each'}>
                                                                                <div className={`settings-panel`}>
                                                                                    <ColorPicker
                                                                                        stroke={item.id}
                                                                                        color={item.color}
                                                                                        label={`strokecolor`}
                                                                                        onChangeComplete={this.props.handleBubblecolorChange}
                                                                                        handleClickStaus={this.props.handleClick}
                                                                                    />

                                                                                </div>
                                                                                <div
                                                                                    className={`settings-panel-option`}>
                                                                                    {index + 1 === this.props.containerData.strokecolor.length &&
                                                                                    <button
                                                                                        className="ico-lib divider-add-button"
                                                                                        onClick={() => this.props.addData(item.id, "strokecolor")}></button>
                                                                                    }
                                                                                    {this.props.containerData.strokecolor.length !== 1 &&
                                                                                    <button
                                                                                        className="ico-lib divider-sub-button"
                                                                                        onClick={() => this.props.removeData(item.id, "strokecolor")}></button>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        ))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }

                                                    {this.props.containerData.hasOwnProperty("fillcolor") &&
                                                    <>
                                                        <div className="main-container-wrap">
                                                            <label className="task-input-container container-flex"> Fill
                                                                color</label>
                                                        </div>
                                                        <div className="graph-proprties">
                                                            <div
                                                                className="graph-proprties-color modal-panel-container">
                                                                <div className="task-input-container">
                                                                    <div>
                                                                        {this.props.containerData.fillcolor.map((item, index) => (
                                                                            <div className={'panel-each'}>
                                                                                <div className={`settings-panel`}>
                                                                                    <ColorPicker
                                                                                        stroke={item.id}
                                                                                        color={item.color}
                                                                                        label={`fillcolor`}
                                                                                        onChangeComplete={this.props.handleBubblecolorChange}
                                                                                        handleClickStaus={this.props.handleClick}
                                                                                    />

                                                                                </div>
                                                                                <div
                                                                                    className={`settings-panel-option`}>
                                                                                    {index + 1 === this.props.containerData.fillcolor.length &&
                                                                                    <button
                                                                                        className="ico-lib divider-add-button"
                                                                                        onClick={() => this.props.addData(item.id, "fillcolor")}></button>
                                                                                    }
                                                                                    {this.props.containerData.fillcolor.length !== 1 &&
                                                                                    <button
                                                                                        className="ico-lib divider-sub-button"
                                                                                        onClick={() => this.props.removeData(item.id, "fillcolor")}></button>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        ))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }
                                                    {this.props.containerData.hasOwnProperty("basedon") &&
                                                    <>
                                                        <div className="main-container-wrap">
                                                            <label
                                                                className="task-input-container container-flex"> Based
                                                                on</label>
                                                        </div>
                                                        <div className="task-input-container">
                                                            <div className="select-box eq-width">
                                                                <select id="multi-area-chart-collections" name="basedon"
                                                                        onChange={(e) => this.props.handleChange(e)}
                                                                        value={this.props.containerData.basedon}>

                                                                    <option value="|">Please select</option>
                                                                    {showlistField.map((label, index) =>
                                                                        <option
                                                                            key={`multi-area-chart-masterid` + index}
                                                                            value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                        }
                                    </div>
                                    {this.props.containerData.hasOwnProperty("linechart") &&
                                    <div className="chart-settings-container for-small">
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap ">
                                                <div className="each-settings-panel-wrap-body">
                                                    <div className="task-input-container select-line">
                                                        <div className="each-inp-field">
                                                            <label>Fields</label>
                                                            <div className="select-box eq-width">
                                                                <select id="priority" name="fields"
                                                                        onChange={(e) => this.props.handleInputChange(e)}
                                                                        value={this.props.containerData.fields}>
                                                                    <option value="">Please Select</option>
                                                                    {showlistField.map((label, index) =>
                                                                        <option
                                                                            value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-field">
                                                            <label>Aggregation:</label>
                                                            <div className="select-box eq-width">
                                                                <select id="priority" name="aggregation"
                                                                        onChange={(e) => this.props.handleInputChange(e)}
                                                                        value={this.props.containerData.aggregation}>
                                                                    <option value="">Please Select</option>
                                                                    <option value="sum">Sum</option>
                                                                    <option value="average">Average</option>
                                                                    <option value="max">Max</option>
                                                                    <option value="min">Min</option>
                                                                    <option value="count">Count</option>

                                                                </select>
                                                            </div>

                                                        </div>
                                                        {this.props.containerData.hasOwnProperty("linecolor") &&
                                                        <div className="each-inp-field">
                                                            <label>Line Colour</label>
                                                            <ColorPicker
                                                                stroke={"linecolor"}
                                                                color={this.props.containerData.linecolor}
                                                                onChangeComplete={this.props.handleChangeComplete}
                                                                handleClickStaus={this.props.handleClick}
                                                            />
                                                        </div>
                                                        }
                                                        {this.props.containerData.hasOwnProperty("checked") &&
                                                        <div className="select-chk-block" style={{"display": "block"}}>
                                                            <label className="control control-checkbox">Enable Graph
                                                                <input className="inp-chk" type={`checkbox`}
                                                                       name={`graph`}
                                                                       checked={this.props.containerData.checked}
                                                                       value={this.props.containerData.checked}
                                                                       onClick={(e) => this.props.checkBoxchange(e)}/>

                                                                <div className="control-indicator"></div>
                                                            </label>
                                                        </div>
                                                        }


                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {this.props.containerData.hasOwnProperty("min") &&
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap ">
                                                <div className="each-settings-panel-wrap-body">
                                                    <div className="task-input-container select-line">
                                                        <div className="each-inp-field">
                                                            <label>Min</label>
                                                            <div className="new-task-input">
                                                                <input type="text" placeholder="Min" id="min" name="min"
                                                                       autoComplete="off"
                                                                       value={this.props.containerData.min}
                                                                       onChange={(e) => this.props.handleChange(e)}/>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-field">
                                                            <label>Max</label>
                                                            <div className="new-task-input">
                                                                <input type="text" placeholder="Max" id="max" name="max"
                                                                       autoComplete="off"
                                                                       value={this.props.containerData.max}
                                                                       onChange={(e) => this.props.handleChange(e)}/>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-field">
                                                            <label>X axis</label>
                                                            <div className="select-box eq-width">
                                                                <select id="xaxis" name="x"
                                                                        onChange={(e) => this.props.handleInputChange(e)}
                                                                        value={this.props.containerData.x}>
                                                                    <option value="">X axis</option>
                                                                    {showlistField.map((label, index) =>
                                                                        <option
                                                                            value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="each-inp-field">
                                                            <label>Y axis</label>
                                                            <div className="select-box eq-width">
                                                                <select id="yaxis" name="y"
                                                                        onChange={(e) => this.props.handleInputChange(e)}
                                                                        value={this.props.containerData.y}>
                                                                    <option value="">Y axis</option>
                                                                    {showlistField.map((label, index) =>
                                                                        <option key={`line-chart-${index}`}
                                                                                value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    }
                                    {this.props.containerData.hasOwnProperty("seriesLabel") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="task-input-container">
                                            {/*<div className="each-settings-panel-wrap-header">*/}
                                            {/*    <h5 className="each-settings-panel-header">Graph axis</h5>*/}
                                            {/*</div>*/}
                                            <div className="each-settings-panel-wrap-body">
                                                <div className="each-inp-field">
                                                    <label>Series Name</label>
                                                    <div className="new-task-input">
                                                        <input type="text" placeholder="series label" id="seriesLabel"
                                                               name="seriesLabel"
                                                               autoComplete="off"
                                                               value={this.props.containerData.seriesLabel}
                                                               onChange={(e) => this.props.handleChange(e)}/>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">
                                                    <label>Field Name</label>
                                                    <div className="select-box eq-width">
                                                        <select id="multi-area-chart-collections" name="seriesField"
                                                                onChange={(e) => this.props.handleChange(e)}
                                                                value={this.props.containerData.seriesField}>

                                                            <option value="|">Please select</option>
                                                            {showlistField.map((label, index) =>
                                                                <option key={`multi-area-chart-masterid` + index}
                                                                        value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="each-inp-field">

                                                    {this.props.containerData.serColor.map((item, index) => (
                                                        <div className={'panel-each'}>
                                                            <div className={`settings-panel dynamic-cp`}>

                                                                <ColorPicker
                                                                    stroke={item.id}
                                                                    color={item.color}
                                                                    label={`serColor`}
                                                                    onChangeComplete={this.props.serColorChange}
                                                                    handleClickStaus={this.props.handleClick}
                                                                />

                                                            </div>
                                                            <div className={`settings-panel-option`}>
                                                                {index + 1 === this.props.containerData.serColor.length &&
                                                                <button className="ico-lib divider-add-button"
                                                                        onClick={() => this.props.addData(item.id, "serColor")}></button>
                                                                }
                                                                {this.props.containerData.serColor.length !== 1 &&
                                                                <button className="ico-lib divider-sub-button"
                                                                        onClick={() => this.props.removeData(item.id, "serColor")}></button>
                                                                }
                                                            </div>
                                                        </div>

                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.props.containerData.hasOwnProperty("setFields") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="task-input-container">
                                            <div className="each-settings-panel-wrap-header">
                                                <h5 className="each-settings-panel-header">Set Fields</h5>
                                            </div>

                                            <div className="each-settings-panel-wrap-body">
                                                {this.props.containerData.setFields.map((item, index) =>
                                                    <>
                                                        <>
                                                            {index === 0 &&
                                                            <div className="each-settings-panel-wrap-header">
                                                                <h5 className="each-settings-panel-header">Mapping
                                                                    Fields</h5>
                                                            </div>
                                                            }
                                                        </>
                                                        <>
                                                            {index === 1 &&
                                                            <div className="each-settings-panel-wrap-header">
                                                                <h5 className="each-settings-panel-header">Listing
                                                                    Fields</h5>
                                                            </div>
                                                            }
                                                        </>
                                                        <div className={'panel-each'}>
                                                            <div className={`settings-panel`}>
                                                                <div className="each-inp-field">
                                                                    <label>Fields</label>
                                                                    <div className="select-box eq-width">
                                                                        <select id="priority" name="masterid"
                                                                                onChange={(e) => this.props.handleLabelChange(e, item.id, "setFields")}
                                                                                value={item.masterid + "|" + item.type}>
                                                                            <option value="">Please Select</option>
                                                                            {showlistField.map((label, index) =>
                                                                                <option key={"m" + index}
                                                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                {item.hasOwnProperty("default") && this.props.containerData.hasOwnProperty("defaultfields") &&
                                                                <div className="each-inp-field">
                                                                    <label>Default Field</label>
                                                                    <div className="select-box eq-width">
                                                                        <select id="priority" name="default"
                                                                                onChange={(e) => this.props.handleDefaultField(e, item.id, "setFields")}
                                                                                value={item.default}>
                                                                            <option value="">Please Select</option>
                                                                            {this.props.containerData.defaultfields.map((label, index) =>
                                                                                <option
                                                                                    value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>


                                                            <div className={`settings-panel-option`}>
                                                                {index + 1 === this.props.containerData.setFields.length &&
                                                                <button className="ico-lib divider-add-button"
                                                                        onClick={() => this.props.addData(item.id, "setFields")}>

                                                                </button>
                                                                }

                                                                {this.props.containerData.setFields.length !== 1 &&
                                                                <button className="ico-lib divider-sub-button"
                                                                        onClick={() => this.props.removeData(item.id, "setFields")}>

                                                                </button>
                                                                }
                                                            </div>

                                                        </div>
                                                    </>
                                                )}

                                            </div>


                                        </div>
                                    </div>
                                    }
                                    {this.props.containerData.hasOwnProperty("buttoncolor") &&
                                    <div className="chart-settings-container for-small">
                                        <div className="chart-settings-splitter">
                                            <div className="each-settings-panel-wrap">
                                                <div className="each-settings-panel-wrap-header">
                                                    <h5 className="each-settings-panel-header">Button Color</h5>
                                                </div>
                                                <div className="each-settings-panel-wrap-body">

                                                    <div className="select-chk-block" style={{"display": "block"}}>
                                                        <label className="control control-checkbox">Background Color
                                                            <ColorPicker
                                                                stroke={`bkColr`}
                                                                color={this.props.containerData.bkColr}
                                                                onChangeComplete={this.props.onChangeComplete}
                                                                handleClickStaus={this.props.handleClick}
                                                            />
                                                        </label>
                                                    </div>

                                                    <div className="select-chk-block" style={{"display": "block"}}>
                                                        <label className="control control-checkbox">Text Color
                                                            <ColorPicker
                                                                stroke={`TxtColr`}
                                                                color={this.props.containerData.TxtColr}
                                                                onChangeComplete={this.props.onChangeComplete}
                                                                handleClickStaus={this.props.handleClick}
                                                            />
                                                        </label>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.props.containerData.hasOwnProperty("mapFields") &&
                                    <div className="each-settings-panel-wrap">
                                        <div className="task-input-container">
                                            <div className="each-settings-panel-wrap-header">
                                                <h5 className="each-settings-panel-header">Set Fields</h5>
                                            </div>
                                            <div className="each-settings-panel-wrap-body">
                                                {this.props.containerData.mapFields.map((item, index) =>
                                                    <div className={'panel-each'}>
                                                        <div className={`settings-panel`}>
                                                            <div className="each-inp-field">
                                                                <label>Fields</label>
                                                                <div className="select-box eq-width">
                                                                    <select id="priority" name="masterid"
                                                                            onChange={(e) => this.props.handleLabelChange(e, item.id, "mapFields")}
                                                                            value={item.masterid}>
                                                                        <option value="">Please Select</option>
                                                                        {showlistField.map((label, index) =>
                                                                            <option
                                                                                value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="each-inp-field">
                                                                <label>Default Field</label>
                                                                <div className="select-box eq-width">
                                                                    <select id="priority" name="default"
                                                                            onChange={(e) => this.props.handleLabelChange(e, item.id, "mapFields")}
                                                                            value={item.default}>
                                                                        <option value="">Please Select</option>
                                                                        {this.props.containerData.defaultfields.map((label, index) =>
                                                                            <option
                                                                                value={label.masterid}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className={`settings-panel-option`}>
                                                            {index + 1 === this.props.containerData.mapFields.length &&
                                                            <button className="ico-lib divider-add-button"
                                                                    onClick={() => this.props.addData(item.id, "mapFields")}>

                                                            </button>
                                                            }

                                                            {this.props.containerData.mapFields.length !== 1 &&
                                                            <button className="ico-lib divider-sub-button"
                                                                    onClick={() => this.props.removeData(item.id, "mapFields")}>

                                                            </button>
                                                            }
                                                        </div>


                                                    </div>
                                                )}


                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {this.props.containerData.hasOwnProperty("minHeight") &&
                                    <div className="each-inp-field">
                                        <label>Min Height</label>
                                        <div className="new-task-input">
                                            <input type="text" placeholder="Min Height"
                                                   name="minHeight"
                                                   autoComplete="off"
                                                   value={this.props.containerData.minHeight}
                                                   onChange={(e) => this.props.handleChange(e)}/>
                                        </div>

                                    </div>
                                    }


                                </div>
                                }


                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="general-btn grey btn btn-primary"
                                    onClick={() => this.props.cancelAction()}>Ok
                            </button>

                        </div>

                    </div>
                </div>
            </div>)
    }

}


export default EntityModal
