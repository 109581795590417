import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import Overview from './Overview'
import Deadlines from './Deadlines'
import Milestone from './Milestone'
import Position from './Position'
import Ownperformance from './Ownperformance'
class DashboardStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            weeklyStatistics: []
        }
    }
    componentWillMount() {

    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.weeklyStatistics !== this.props.weeklyStatistics) {
            var weeklyStatistics = this.props.weeklyStatistics;
            this.setState({weeklyStatistics: weeklyStatistics})
        }
    }
    render() {
        let myOverview = [
            {label: 'To Do', value: this.props.dashboard.todo},
            {label: 'Overdue', value: this.props.dashboard.totaldue},
            {label: 'Due Today', value: this.props.dashboard.total},
            {label: 'Due This Week', value: this.props.dashboard.todo_duethisweek}
        ];
        let overview = [
            {label: 'To Follow Up', value: this.props.dashboard.followup},
            {label: 'Overdue', value: this.props.dashboard.followup_overdue},
            {label: 'Due Today', value: this.props.dashboard.followup_duetoday},
            {label: 'Due This Week', value: this.props.dashboard.followup_duethisweek}
        ];
        return(
            <div className="dash-wrapper-right">
                <div className="dash-wrapper-right-top">
                    <Ownperformance
                        dashboard={this.props.dashboard}
                        type={'own_task'}
                        getWeeklyStatistics={this.props.getWeeklyStatistics}
                        weeklyStatistics={this.state.weeklyStatistics}
                        multiLoader={this.props.multiLoader}
                    />
                    <Overview dataList={myOverview} type={'own_task'} multiLoader={this.props.multiLoader}/>
                </div>
                <div className="dash-wrapper-right-center">
                    <Deadlines dataList={this.props.dashboard} multiLoader={this.props.multiLoader} />
                    <Position type={'main'} dataList={this.props.dashboard}/>
                    <Milestone dataList={this.props.dashboard.milestone || []} multiLoader={this.props.multiLoader} />
                </div>
                <div className="dash-wrapper-right-bottom">
                    <Ownperformance
                        dashboard={this.props.dashboard}
                        type={'delegated_task'}
                        getWeeklyStatistics={this.props.getWeeklyStatistics}
                        weeklyStatistics={this.state.weeklyStatistics}
                        multiLoader={this.props.multiLoader}
                    />
                    <Overview dataList={overview} type={'delegated_task'} multiLoader={this.props.multiLoader}/>
                </div>
            </div>
        );
    }
}

export default DashboardStatistics;