import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {USRIMG_PATH} from "../../../constants";
import rootActions from "../../../actions";
import * as usersActions from "../../../actions/Users";
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import LoaderSvg from "../../views/LoaderSVG";
import {getProfile} from "../../../utilities/AuthService";
import NoData from "../../views/NoData";


class UserGroupDetails extends Component {

    constructor(props) {
        super(props);

        this.state={
            chatPermission : false,
        }
        this.goToChat = this.goToChat.bind(this);
    }

    componentDidMount() {
        this.props.mainClick('group','group/details');
        let params={
            "groupid":atob(getUrlSegment(3)),
            "uid": getProfile().id,
            "usertype" : getProfile().usertype
        }
        this.props.getgroupDetails(params);
        let trackparams={
            "module": "Team",
            "page": window.location.pathname,
            "action": 'groupdetailslisting',
            "browser": window.navigator.userAgent,
            "uid": getProfile().id,
        }
        this.props.activityTracking(trackparams);
    }
    goToChat(e,uid,name,image){
        let userdata='';
        userdata={"receiverId":uid,"type":"One to One","name":name,"userHead":image};
        window.location.href = window.location.origin+'/chat/'+btoa(JSON.stringify(userdata));
    }
    render() {
        let groupDetails = this.props.groupDetails.groupdetails[0]||[];
        let assignees = groupDetails.assignees||[];
		
		let userPermissiondata = this.props.listUsersPermission.UsersPermission;
		let chatPermission=false
        userPermissiondata.forEach(
            function iterator(item,index){
                if(item.module == "Chat" && item.module_permission == "yes"){

                        chatPermission= true;
                }
            }
        );
        let teamPermission=false

        userPermissiondata.forEach(
            function iterator(item,index){
                if(item.module === "Team" && item.module_permission === "yes"){
                    let permission = item.submodule.filter(subitem =>subitem.module === "Group" && subitem.module_permission === "yes")[0]||[];
                    if(permission.module_permission === 'yes') {
                        teamPermission = true;
                    }
                }
            }
        );
        return (

            <div className="form-detail-container data-view field-form" id="scrollpage">
                <div className="form-detail-panel">
                    <div className="form-detail-left">
                        <img src=""/>
                    </div>
                    <div className="form-detail-right">
                        <div className="form-detail-top project-top">
                            <div className="form-detail-top-left">
                                    <button className="proj-back" onClick= {(e)=>this.props.history.push('/group')} ></button>
                                <h5>{groupDetails.groupName}</h5>
                            </div>
                        </div>
                        <div className="form-detail-bottom">
                            <div className="form-detail-bottom-left">
                                <ul className="detail-tab">
                                    <li>
                                        <nav  className="detail-tab-click usrs active"
                                           data-tab="groupTeam">Users</nav>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="list-wrapper pro-overview" id="groupTeam">
                    {teamPermission == true ?
                    <div className="project-content-wrapper group-height">
                        <div className="pj-team-wrapper">

                            <div className="team-wrapper" id="taskusers">
                                {((this.props.loader.loaderType === 'usergroup-details' && this.props.loader.status === true)) &&
                                <span className="Loader-holder">
                                <LoaderSvg/>
                             </span>
                                }
                                {assignees.map((list, index) =>
                                    <div className="ind-team-user" key={index}>
                                        <a className="team-user-click">
                                            <div className="team-user-thumb">
                                                <img
                                                    src={USRIMG_PATH + list.assigneeImage}
                                                    alt="user image"/>
                                                {chatPermission &&
                                                <div className="user-hover">
                                                    <button className="hover-chat"
                                                            onClick={(e) => this.goToChat(e, list.assigneeId, list.assigneeName, list.assigneeImage)}>Chat
                                                    </button>
                                                </div>
                                                }
                                            </div>
                                            <div className="team-user-desc">
                                                <h5>{list.assigneeName}</h5>
                                                {list.designation &&
                                                <p className="designation-in">{list.designation}</p>
                                                }
                                                <p className="mail-text">{list.email}</p>
                                                {list.phone &&
                                                <p className="phone-text">{list.phone}</p>
                                                }
                                            </div>
                                        </a>
                                    </div>
                                )}
                            </div>

                        </div>

                    </div>:
                        <div className='absolute-holder'>
                            <NoData
                                msg={`No permission to access`}

                            />
                        </div>
                    }
                </div>


            </div>





        );
    }
}



const mapStateToProps = state => ({
    loader:state.CommonReducer.loader,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    groupDetails:state.UsersReducer.groupDetails,	
	listUsersPermission:state.UsersReducer.listUsersPermissiondata,

});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    getgroupDetails:usersActions.getgroupDetails,
    activityTracking:rootActions.commonActions.activityTracking
})(UserGroupDetails));


