import React, {Component} from 'react';
import {TemplateHorizotalPreview,} from "./Template2";
import {TemplateVerticalPreview,} from "./Template1";
import {TemplateMiniPreview, TemplateHCardPreview} from "./Template6";
import {HorizonalLongContent} from "./Template5";
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {

    const result = Array.from(list);

    ////////console.log(result)
    const [removed] = result.splice(startIndex, 1);
    ////////console.log(removed)
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "red",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "grey",
    padding: grid,
    width: 250
});

class EditTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            verticaltemp: [{
                "item1": {
                    "label": "",
                    "masterid": ""

                },
                "item2": {
                    "label": "",
                    "masterid": ""

                },
                "item3": {
                    "label": "",
                    "masterid": ""

                },
                "item4": {
                    "label": "",
                    "masterid": ""

                },
                "item5": {
                    "label": "",
                    "masterid": ""

                },
                "item6": {
                    "label": "",
                    "masterid": ""

                },
                "item7": {
                    "label": "",
                    "masterid": ""

                },
                "item8": {
                    "label": "",
                    "masterid": ""

                },
                "item9": {
                    "label": "",
                    "masterid": ""

                },
                "item10": {
                    "label": "",
                    "masterid": ""

                },
                "item11": {
                    "label": "",
                    "masterid": ""

                },
                "item12": {
                    "label": "",
                    "masterid": ""

                },
                "item13": {
                    "label": "",
                    "masterid": ""

                }

            }],
            horizontaltemp: [{
                "item1": {
                    "label": "",
                    "masterid": ""

                },
                "item2": {
                    "label": "",
                    "masterid": ""

                },
                "item3": {
                    "label": "",
                    "masterid": ""

                },
                "item4": {
                    "label": "",
                    "masterid": ""

                },
                "item5": {
                    "label": "",
                    "masterid": ""

                },
                "item6": {
                    "label": "",
                    "masterid": ""

                },
                "item7": {
                    "label": "",
                    "masterid": ""

                },
                "item8": {
                    "label": "",
                    "masterid": ""

                },
                "item9": {
                    "label": "",
                    "masterid": ""

                },
                "item10": {
                    "label": "",
                    "masterid": ""

                },
                "item11": {
                    "label": "",
                    "masterid": ""

                },
                "item12": {
                    "label": "",
                    "masterid": ""

                },
                "item13": {
                    "label": "",
                    "masterid": ""

                },
                "item14": {
                    "label": "",
                    "masterid": ""

                }
            }],
            minitemp: [{
                "item1": {
                    "label": "",
                    "masterid": ""

                },
                "item2": {
                    "label": "",
                    "masterid": ""

                },
                "item3": {
                    "label": "",
                    "masterid": ""

                },
                "item4": {
                    "label": "",
                    "masterid": ""

                },
                "item5": {
                    "label": "",
                    "masterid": ""

                },
                "item6": {
                    "label": "",
                    "masterid": ""

                },
                "item7": {
                    "label": "",
                    "masterid": ""

                },
                "item8": {
                    "label": "",
                    "masterid": ""

                },
                "item9": {
                    "label": "",
                    "masterid": ""

                },
                "item10": {
                    "label": "",
                    "masterid": ""

                },
                "item11": {
                    "label": "",
                    "masterid": ""

                },
                "item12": {
                    "label": "",
                    "masterid": ""

                },
                "item13": {
                    "label": "",
                    "masterid": ""

                }

            }],
            longcontentTemp: [{
                "item1": {
                    "label": "",
                    "masterid": ""
                },
                "item2": {
                    "label": "",
                    "masterid": ""

                },
                "item3": {
                    "label": "",
                    "masterid": ""

                },
                "item4": {
                    "label": "",
                    "masterid": ""

                },
                "item5": {
                    "label": "",
                    "masterid": ""

                },
                "item6": {
                    "label": "",
                    "masterid": ""

                },
                "item7": {
                    "label": "",
                    "masterid": ""

                },
                "item8": {
                    "label": "",
                    "masterid": ""

                },
                "item9": {
                    "label": "",
                    "masterid": ""

                },
                "item10": {
                    "label": "",
                    "masterid": ""

                },
                "item11": {
                    "label": "",
                    "masterid": ""

                },
                "item12": {
                    "label": "",
                    "masterid": ""

                },
                "item13": {
                    "label": "",
                    "masterid": ""

                }
            }],
            verticaltemplate: [],
            horizontaltemplate: [],
            minitemplate: [],
            longcontent: [],
            long1Temp: [{
                "item1": {
                    "label": "",
                    "masterid": ""
                },
                "item2": {
                    "label": "",
                    "masterid": ""

                },
                "item3": {
                    "label": "",
                    "masterid": ""

                },

            }],
            long1template: [],
            kanbancontentTemp: [{
                groupbyinfo:[{
                    groupby:'',
                    "item1": {
                        "label": "",
                        "masterid": ""
                    }
                }] ,
                cardinfo: [{
                    "item2": {
                        "label": "",
                        "masterid": ""

                    },
                    "item3": {
                        "label": "",
                        "masterid": ""

                    },
                }]
            }],
            kanbanTemplate:[{
                groupbyinfo:[{
                    groupby:'',
                    "item1": {
                        "label": "",
                        "masterid": ""
                    }
                }] ,
                cardinfo: [{
                    "item2": {
                        "label": "",
                        "masterid": ""

                    },
                    "item3": {
                        "label": "",
                        "masterid": ""

                    },
                }]
            }]
        }
    }

    componentDidMount() {
        if (this.props.horizontaltemplate.length !== 0) {
            if (!this.props.horizontaltemplate[0].hasOwnProperty("item10")) {
                this.props.horizontaltemplate[0]["item10"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.horizontaltemplate[0]["item11"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.horizontaltemplate[0]["item12"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.horizontaltemplate[0]["item13"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.verticaltemplate[0]["item14"] = {
                    "label": "",
                    "masterid": ""
                }
            }
            if (!this.props.horizontaltemplate[0].hasOwnProperty("item14")) {
                this.props.horizontaltemplate[0]["item14"] = {
                    "label": "",
                    "masterid": ""
                }
            }
        }
        if (this.props.verticaltemplate.length !== 0) {
            if (!this.props.verticaltemplate[0].hasOwnProperty("item10")) {
                this.props.verticaltemplate[0]["item10"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.verticaltemplate[0]["item11"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.verticaltemplate[0]["item12"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.verticaltemplate[0]["item13"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.verticaltemplate[0]["item14"] = {
                    "label": "",
                    "masterid": ""
                }
            }

            if (!this.props.verticaltemplate[0].hasOwnProperty("item14")) {
                this.props.verticaltemplate[0]["item14"] = {
                    "label": "",
                    "masterid": ""
                }
            }
        }
        if (this.props.minitemplate.length !== 0) {
            if (!this.props.minitemplate[0].hasOwnProperty("item10")) {
                this.props.minitemplate[0]["item10"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.minitemplate[0]["item11"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.minitemplate[0]["item12"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.minitemplate[0]["item13"] = {
                    "label": "",
                    "masterid": ""
                }
                this.props.minitemplate[0]["item14"] = {
                    "label": "",
                    "masterid": ""
                }
            }

            if (!this.props.minitemplate[0].hasOwnProperty("item14")) {
                this.props.minitemplate[0]["item14"] = {
                    "label": "",
                    "masterid": ""
                }
            }
        }

        this.setState({
            verticaltemplate: (this.props.verticaltemplate.length === 0 ? this.state.verticaltemp : this.props.verticaltemplate),
            horizontaltemplate: (this.props.horizontaltemplate.length === 0 ? this.state.horizontaltemp : this.props.horizontaltemplate),
            minitemplate: (this.props.minitemplate.length === 0 ? this.state.minitemp : this.props.minitemplate),
//          longcontent:(this.props.longcontent.length === 0 ? this.state.longcontentTemp:  this.props.longcontent),
            long1template: ((!this.props.long1template || this.props.long1template.length === 0) ? this.state.long1Temp : this.props.long1template),
            //kanbanTemplate: ((!this.props.kanbanTemplate || this.props.kanbanTemplate.length === 0) ? this.state.kanbancontentTemp : this.props.kanbanTemplate),
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.default_template !== prevProps.default_template) {
            this.setState({
                    verticaltemplate: (this.props.verticaltemplate.length === 0 ? this.state.verticaltemp : this.props.verticaltemplate),
                    horizontaltemplate: (this.props.horizontaltemplate.length === 0 ? this.state.horizontaltemp : this.props.horizontaltemplate),
                    minitemplate: (this.props.minitemplate.length === 0 ? this.state.minitemp : this.props.minitemplate),
                    long1template: ((this.props.long1template.length === 0 || !this.props.long1template) ? this.state.long1Temp : this.props.long1template),
                   // kanbanTemplate: ((!this.props.kanbanTemplate || this.props.kanbanTemplate.length === 0) ? this.state.kanbancontentTemp : this.props.kanbanTemplate),
                    //  longcontent:(this.props.longcontent.length === 0 ? this.state.longcontentTemp:  this.props.longcontent)
                }
            )
        }
    }

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const horizontaltemplate = reorder(
            this.state.horizontaltemplate,
            result.source.index,
            result.destination.index
        );
        this.setState({
            horizontaltemplate
        });
    };


    handleLabelChange = (e, id, temp) => {
        let sel = e.target
        let labelParam = e.target.value.split("|")
        let masterid = labelParam[0]
        let type = labelParam[1]

        if (temp === 'horizontal') {
            var _newStatehorizontal = this.state.horizontaltemplate;
            [_newStatehorizontal[0][id]][0]["masterid"] = masterid;
            [_newStatehorizontal[0][id]][0]["type"] = type;
            [_newStatehorizontal[0][id]][0]["label"] = sel.options[sel.selectedIndex].text === "Select" ? "" : sel.options[sel.selectedIndex].text;
            this.setState({_newStatehorizontal})
            this.props.saveTempalete(_newStatehorizontal, temp)
        }
        if (temp === 'vertical') {
            var _newStatevertical = this.state.verticaltemplate;
            [_newStatevertical[0][id]][0]["masterid"] = masterid;
            [_newStatevertical[0][id]][0]["type"] = type;
            [_newStatevertical[0][id]][0]["label"] = sel.options[sel.selectedIndex].text === "Select" ? "" : sel.options[sel.selectedIndex].text;
            this.setState({_newStatevertical})
            this.props.saveTempalete(_newStatevertical, temp)
        }
        if (temp === 'mini') {
            var _newStatemini = this.state.minitemplate;
            [_newStatemini[0][id]][0]["masterid"] = masterid;
            [_newStatemini[0][id]][0]["type"] = type;
            [_newStatemini[0][id]][0]["label"] = sel.options[sel.selectedIndex].text === "Select" ? "" : sel.options[sel.selectedIndex].text;
            this.setState({_newStatemini})
            this.props.saveTempalete(_newStatemini, temp)
        }
        if (temp === 'long1') {
            var _newStateLong1 = this.state.long1template;
            [_newStateLong1[0][id]][0]["masterid"] = masterid;
            [_newStateLong1[0][id]][0]["type"] = type;
            [_newStateLong1[0][id]][0]["label"] = sel.options[sel.selectedIndex].text === "Select" ? "" : sel.options[sel.selectedIndex].text;
            this.setState({_newStateLong1})
            this.props.saveTempalete(_newStateLong1, temp)
        }
        if (temp === 'longcontent') {
            var _newStatevertical = this.state.longcontent;
            [_newStatevertical[0][id]][0]["masterid"] = masterid;
            [_newStatevertical[0][id]][0]["type"] = type;
            [_newStatevertical[0][id]][0]["label"] = sel.options[sel.selectedIndex].text === "Select" ? "" : sel.options[sel.selectedIndex].text;
            this.setState({_newStatevertical})
            this.props.saveTempalete(_newStatevertical, temp)
        }
        if (temp === 'kanban') {
            if(id === 'groupby'){
                var _newStateKanban = this.state.kanbanTemplate;
                console.log(_newStateKanban)
                _newStateKanban[0]['groupbyinfo'][0]['groupby'] = e.target.value;
                this.setState({_newStateKanban})
            }else if(id === 'item1') {
                var _newStateKanban = this.state.kanbanTemplate;
                [_newStateKanban[0]['groupbyinfo'][0][id]][0]["masterid"] = masterid;
                [_newStateKanban[0]['groupbyinfo'][0][id]][0]["type"] = type;
                [_newStateKanban[0]['groupbyinfo'][0][id]][0]["label"] = sel.options[sel.selectedIndex].text === "Select" ? "" : sel.options[sel.selectedIndex].text;
                this.setState({_newStateKanban})
                this.props.saveTempalete(_newStateKanban, temp)
            }else {
                var _newStateKanban = this.state.kanbanTemplate;
                [_newStateKanban[0]['cardinfo'][0][id]][0]["masterid"] = masterid;
                [_newStateKanban[0]['cardinfo'][0][id]][0]["type"] = type;
                [_newStateKanban[0]['cardinfo'][0][id]][0]["label"] = sel.options[sel.selectedIndex].text === "Select" ? "" : sel.options[sel.selectedIndex].text;
                this.setState({_newStateKanban})
                this.props.saveTempalete(_newStateKanban, temp)
            }
        }

    }
    handleChange = (e, id, temp) => {
        if (temp === 'horizontal') {
            var _newState = this.state.horizontaltemplate;
            [_newState[0][id]][0]["label"] = e.target.value;

            this.setState({_newState})
            this.props.saveTempalete(_newState, temp)
        }

        if (temp === 'vertical') {
            var _newState = this.state.verticaltemplate;
            [_newState[0][id]][0]["label"] = e.target.value;

            this.setState({_newState})
            this.props.saveTempalete(_newState, temp)
        }

        if (temp === 'mini') {
            var _newState = this.state.minitemplate;
            [_newState[0][id]][0]["label"] = e.target.value;

            this.setState({_newState})
            this.props.saveTempalete(_newState, temp)
        }

        if (temp === 'long1') {
            var _newState = this.state.long1template;
            [_newState[0][id]][0]["label"] = e.target.value;

            this.setState({_newState})
            this.props.saveTempalete(_newState, temp)
        }

        if (temp === 'longcontent') {
            var _newState = this.state.longcontent;
            [_newState[0][id]][0]["label"] = e.target.value;

            this.setState({_newState})
            this.props.saveTempalete(_newState, temp)
        }

    }


    render() {
        let template = this.props.template
        let viewtemplate = this.props.viewtemplate

        let showlistField = []
        let showlistImage = []

        if (this.props.label.showformdata.length !== 0) {
            let compIdArr = this.props.label.showformdata.pages
                .map((page, inde) => {
                    return page.components.filter(list => list.visibility !== "hidden" && (template !== 'long1' ? list.showlist === 'Yes' : true)).map(item => {
                        return item;
                    })
                });
            showlistField = [].concat.apply([], compIdArr);

            let imageCompIdArr = this.props.label.showformdata.pages
                .map((page, inde) => {
                    return page.components.filter(list => list.showlist === 'Yes' && list.visibility !== "hidden" && (list.type === "imageurl" || list.type === "videourl" || list.type === "attachment")).map(item => {
                        return item;
                    })
                });
            showlistImage = [].concat.apply([], imageCompIdArr);


        }


        let initialItems = this.state.initialItems
        let verticaltemplate = this.state.verticaltemplate
        let horizontaltemplate = this.state.horizontaltemplate
        let minitemplate = this.state.minitemplate
        let long1template = this.state.long1template
       // let kanbanTemplate = this.state.kanbanTemplate
        let longcontent = this.state.longcontent
        let verticalImage = this.props.verticalImage
        let miniImage = this.props.miniImage
        let horizontalImage = this.props.horizontalImage
        let long1Image = this.props.long1Image
        let horizontalMapenabled = this.props.horizontalMapenabled
        let verticalMapenabled = this.props.verticalMapenabled
        let miniMapenabled = this.props.miniMapenabled

        return (
            <React.Fragment>
                {template === 'horizontal' &&
                    <div className="ind-collection-card  edit-template list-view">
                        {horizontaltemplate.map((list, index) =>
                            <React.Fragment>
                                <div className="edit-template-left">
                                    <div className="collection-card-left">
                                        <div className="select-box">
                                            <select
                                                onChange={(e) => this.handleLabelChange(e, "item14", "horizontal")}
                                                value={list.item14.masterid}>
                                                <option value="">Select</option>
                                                <option value={"Normal"}>Normal</option>
                                                <option value={"Full"}>Full</option>

                                            </select>
                                        </div>


                                        {horizontalMapenabled && !this.props.horizontalImage &&
                                            <div className="map-properties">
                                                <h5>Map Properties</h5>
                                                <div className="select-box">
                                                    <select onChange={(e) => this.handleLabelChange(e, "item10", "horizontal")}
                                                            value={list.item10.masterid + "|" + list.item10.type}>
                                                        <option value="">Latitude</option>
                                                        {showlistField.filter(list => list.type === "location").map((label, index) =>
                                                            <option key={`masterid` + index}
                                                                    value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                {/*<div className="select-box">*/}
                                                {/*        <select onChange={(e) => this.handleLabelChange(e, "item11","horizontal")}*/}
                                                {/*                value={list.item11.masterid + "|" + list.item11.type}>*/}
                                                {/*            <option value="">Longitude</option>*/}

                                                {/*            {showlistField.filter(list=>list.type === "longitude").map((label, index) =>*/}
                                                {/*                <option key={`masterid` + index}*/}
                                                {/*                        value={label.masterid+"|"+label.type}>{label.hasOwnProperty('display_label')?label.display_label:label.label}</option>*/}
                                                {/*            )}*/}
                                                {/*        </select>*/}
                                                {/*    </div>*/}
                                                <div className="select-box">
                                                    <select onChange={(e) => this.handleLabelChange(e, "item12", "horizontal")}
                                                            value={list.item12.masterid}>
                                                        <option value="">Zoom</option>
                                                        <option key={`masterid` + index} value={5}>5</option>
                                                        <option key={`masterid` + index} value={6}>6</option>
                                                        <option key={`masterid` + index} value={7}>7</option>
                                                        <option key={`masterid` + index} value={8}>8</option>
                                                        <option key={`masterid` + index} value={9}>9</option>
                                                        <option key={`masterid` + index} value={10}>10</option>
                                                    </select>
                                                </div>

                                                <div className="select-box">
                                                    <select onChange={(e) => this.handleLabelChange(e, "item13", "horizontal")}
                                                            value={list.item13.masterid}>
                                                        <option value="">Map Type</option>
                                                        <option key={`masterid` + index} value={"dynamic"}>Dynamic</option>
                                                        <option key={`masterid` + index} value={"static"}>Static</option>

                                                    </select>
                                                </div>


                                            </div>
                                        }
                                        {!this.props.horizontalImage && !horizontalMapenabled &&
                                            <div className="select-box">
                                                <select
                                                    onChange={(e) => this.handleLabelChange(e, "item1", "horizontal")}
                                                    value={list.item1.masterid + "|" + list.item1.type}>
                                                    <option value="">Select</option>
                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                        }
                                        {this.props.horizontalImage &&
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item1", "horizontal")}
                                                        value={list.item1.masterid + "|" + list.item1.type}>
                                                    <option value="">Image</option>
                                                    {showlistImage.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                        }
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item2", "horizontal")}
                                                value={list.item2.masterid + "|" + list.item2.type}>
                                            <option value="">Select</option>
                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item3", "horizontal")}
                                                value={list.item3.masterid + "|" + list.item3.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item4", "horizontal")}
                                                value={list.item4.masterid + "|" + list.item4.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item5", "horizontal")}
                                                value={list.item5.masterid + "|" + list.item5.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">

                                        <select onChange={(e) => this.handleLabelChange(e, "item6", "horizontal")}
                                                value={list.item6.masterid + "|" + list.item6.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item7", "horizontal")}
                                                value={list.item7.masterid + "|" + list.item7.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item8", "horizontal")}
                                                value={list.item8.masterid + "|" + list.item8.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item9", "horizontal")}
                                                value={list.item9.masterid + "|" + list.item9.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        <div className="edit-template-right">
                            <div className="edit-template-right-header">
                                <h5>Preview</h5>
                            </div>
                            <div className="edit-template-right-body">
                                <TemplateHorizotalPreview
                                    horizontaltemplate={horizontaltemplate}
                                    collection={this.props.collection}
                                    horizontalImage={horizontalImage}
                                    horizontalMapenabled={horizontalMapenabled}
                                    verticalMapenabled={verticalMapenabled}
                                    label={this.props.label}
                                />
                            </div>
                        </div>
                    </div>
                }
                {template === 'vertical' &&
                    <div className="card-container grid-view">
                        <div className="ind-collection-card inline-flex-box edit-template">
                            <React.Fragment>
                                {verticaltemplate.map((item, index) =>
                                    <React.Fragment>
                                        <div className="edit-template-left">
                                            <div className="collection-card-left">

                                                {verticalMapenabled && !this.props.verticalImage &&
                                                    <div className="map-properties">
                                                        <h5>Map Properties</h5>
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item10", "vertical")}
                                                                value={item.item10.masterid + "|" + item.item10.type}>
                                                                <option value="">Latitude</option>

                                                                {showlistField.filter(list => list.type === "location" || list.type === "latitude").map((label, index) =>
                                                                    <option key={`masterid` + index}
                                                                            value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        {item.item10.type === "longitude" &&
                                                            <div className="select-box">
                                                                <select
                                                                    onChange={(e) => this.handleLabelChange(e, "item11", "vertical")}
                                                                    value={item.item11.masterid + "|" + item.item11.type}>
                                                                    <option value="">Longitude</option>

                                                                    {showlistField.filter(list => list.type === "longitude").map((label, index) =>
                                                                        <option key={`masterid` + index}
                                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        }
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item12", "vertical")}
                                                                value={item.item12.masterid}>
                                                                <option value="">Zoom</option>

                                                                <option key={`masterid` + index} value={5}>5</option>
                                                                <option key={`masterid` + index} value={6}>6</option>
                                                                <option key={`masterid` + index} value={7}>7</option>
                                                                <option key={`masterid` + index} value={8}>8</option>
                                                                <option key={`masterid` + index} value={9}>9</option>
                                                                <option key={`masterid` + index} value={10}>10</option>
                                                            </select>
                                                        </div>
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item13", "vertical")}
                                                                value={item.item13.masterid}>
                                                                <option value="">Map Type</option>
                                                                <option key={`masterid` + index} value={"dynamic"}>Dynamic
                                                                </option>
                                                                <option key={`masterid` + index} value={"static"}>Static
                                                                </option>

                                                            </select>
                                                        </div>


                                                    </div>
                                                }
                                                {!this.props.verticalImage && verticalMapenabled &&
                                                    <div className="select-box">
                                                        <select onChange={(e) => this.handleLabelChange(e, "item1", 'vertical')}
                                                                value={item.item1.masterid + "|" + item.item1.type}>
                                                            <option value="">Select</option>
                                                            {showlistField.map((label, index) =>

                                                                <option key={`masterid` + index}
                                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                }
                                                {this.props.verticalImage &&
                                                    <div className="select-box">
                                                        <select onChange={(e) => this.handleLabelChange(e, "item1", 'vertical')}
                                                                value={item.item1.masterid + "|" + item.item1.type}>
                                                            <option value="">Image</option>

                                                            {showlistImage.map((label, index) =>
                                                                <option key={`masterid` + index}
                                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                }

                                            </div>


                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item2", 'vertical')}
                                                        value={item.item2.masterid + "|" + item.item2.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item3", 'vertical')}
                                                        value={item.item3.masterid + "|" + item.item3.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item4", 'vertical')}
                                                        value={item.item4.masterid + "|" + item.item4.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item5", 'vertical')}
                                                        value={item.item5.masterid + "|" + item.item5.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item6", 'vertical')}
                                                        value={item.item6.masterid + "|" + item.item6.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item7", 'vertical')}
                                                        value={item.item7.masterid + "|" + item.item7.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item8", 'vertical')}
                                                        value={item.item8.masterid + "|" + item.item8.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item9", 'vertical')}
                                                        value={item.item9.masterid + "|" + item.item9.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>

                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="edit-template-right">
                                    <div className="edit-template-right-header">
                                        <h5>Preview</h5>
                                    </div>
                                    <div className="edit-template-right-body">
                                        <TemplateVerticalPreview
                                            verticaltemplate={verticaltemplate}
                                            collection={this.props.collection}
                                            verticalImage={verticalImage}
                                            horizontalMapenabled={horizontalMapenabled}
                                            verticalMapenabled={verticalMapenabled}
                                            label={this.props.label}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                }
                {template === 'mini' &&
                    <div className="card-container grid-view">
                        <div className="ind-collection-card inline-flex-box edit-template">
                            <React.Fragment>
                                {minitemplate.map((item, index) =>
                                    <React.Fragment>
                                        <div className="edit-template-left">
                                            <div className="collection-card-left">

                                                {miniMapenabled && !this.props.miniImage &&
                                                    <div className="map-properties">
                                                        <h5>Map Properties</h5>
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item10", "mini")}
                                                                value={item.item10.masterid + "|" + item.item10.type}>
                                                                <option value="">Latitude</option>

                                                                {showlistField.filter(list => list.type === "location" || list.type === "latitude").map((label, index) =>
                                                                    <option key={`masterid` + index}
                                                                            value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        {item.item10.type === "longitude" &&
                                                            <div className="select-box">
                                                                <select
                                                                    onChange={(e) => this.handleLabelChange(e, "item11", "mini")}
                                                                    value={item.item11.masterid + "|" + item.item11.type}>
                                                                    <option value="">Longitude</option>

                                                                    {showlistField.filter(list => list.type === "longitude").map((label, index) =>
                                                                        <option key={`masterid` + index}
                                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        }
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item12", "mini")}
                                                                value={item.item12.masterid}>
                                                                <option value="">Zoom</option>

                                                                <option key={`masterid` + index} value={5}>5</option>
                                                                <option key={`masterid` + index} value={6}>6</option>
                                                                <option key={`masterid` + index} value={7}>7</option>
                                                                <option key={`masterid` + index} value={8}>8</option>
                                                                <option key={`masterid` + index} value={9}>9</option>
                                                                <option key={`masterid` + index} value={10}>10</option>
                                                            </select>
                                                        </div>
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item13", "mini")}
                                                                value={item.item13.masterid}>
                                                                <option value="">Map Type</option>
                                                                <option key={`masterid` + index} value={"dynamic"}>Dynamic
                                                                </option>
                                                                <option key={`masterid` + index} value={"static"}>Static
                                                                </option>

                                                            </select>
                                                        </div>


                                                    </div>
                                                }
                                                {!this.props.miniImage && miniMapenabled &&
                                                    <div className="select-box">
                                                        <select onChange={(e) => this.handleLabelChange(e, "item1", 'mini')}
                                                                value={item.item1.masterid + "|" + item.item1.type}>
                                                            <option value="">Select</option>
                                                            {showlistField.map((label, index) =>

                                                                <option key={`masterid` + index}
                                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                }
                                                {this.props.miniImage &&
                                                    <div className="select-box">
                                                        <select onChange={(e) => this.handleLabelChange(e, "item1", 'mini')}
                                                                value={item.item1.masterid + "|" + item.item1.type}>
                                                            <option value="">Image</option>

                                                            {showlistImage.map((label, index) =>
                                                                <option key={`masterid` + index}
                                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                }

                                            </div>


                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item2", 'mini')}
                                                        value={item.item2.masterid + "|" + item.item2.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item3", 'mini')}
                                                        value={item.item3.masterid + "|" + item.item3.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item4", 'mini')}
                                                        value={item.item4.masterid + "|" + item.item4.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item5", 'mini')}
                                                        value={item.item5.masterid + "|" + item.item5.type}>
                                                    <option value="">Select</option>

                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>

                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="edit-template-right">
                                    <div className="edit-template-right-header">
                                        <h5>Preview</h5>
                                    </div>
                                    <div className="edit-template-right-body">
                                        <TemplateMiniPreview
                                            minitemplate={minitemplate}
                                            collection={this.props.collection}
                                            miniImage={miniImage}
                                            horizontalMapenabled={horizontalMapenabled}
                                            miniMapenabled={miniMapenabled}
                                            label={this.props.label}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                }
                {template === 'longcontent' &&
                    <div className="ind-collection-card  edit-template list-view">
                        {horizontaltemplate.map((list, index) =>
                            <React.Fragment>
                                <div className="edit-template-left">
                                    <div className="collection-card-left">
                                        {horizontalMapenabled && !this.props.horizontalImage &&
                                            <div className="map-properties">
                                                <h5>Map Properties</h5>
                                                <div className="select-box">
                                                    <select onChange={(e) => this.handleLabelChange(e, "item10", "longcontent")}
                                                            value={list.item10.masterid + "|" + list.item10.type}>
                                                        <option value="">Latitude</option>
                                                        {showlistField.filter(list => list.type === "latitude").map((label, index) =>
                                                            <option key={`masterid` + index}
                                                                    value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="select-box">
                                                    <select onChange={(e) => this.handleLabelChange(e, "item11", "longcontent")}
                                                            value={list.item11.masterid + "|" + list.item11.type}>
                                                        <option value="">Longitude</option>

                                                        {showlistField.filter(list => list.type === "longitude").map((label, index) =>
                                                            <option key={`masterid` + index}
                                                                    value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="select-box">
                                                    <select onChange={(e) => this.handleLabelChange(e, "item12", "longcontent")}
                                                            value={list.item12.masterid}>
                                                        <option value="">Zoom</option>
                                                        <option key={`masterid` + index} value={5}>5</option>
                                                        <option key={`masterid` + index} value={6}>6</option>
                                                        <option key={`masterid` + index} value={7}>7</option>
                                                        <option key={`masterid` + index} value={8}>8</option>
                                                        <option key={`masterid` + index} value={9}>9</option>
                                                        <option key={`masterid` + index} value={10}>10</option>
                                                    </select>
                                                </div>

                                                <div className="select-box">
                                                    <select onChange={(e) => this.handleLabelChange(e, "item13", "longcontent")}
                                                            value={list.item13.masterid}>
                                                        <option value="">Map Type</option>
                                                        <option key={`masterid` + index} value={"dynamic"}>Dynamic</option>
                                                        <option key={`masterid` + index} value={"static"}>Static</option>

                                                    </select>
                                                </div>


                                            </div>
                                        }
                                        {!this.props.horizontalImage && !horizontalMapenabled &&
                                            <div className="select-box">
                                                <select
                                                    onChange={(e) => this.handleLabelChange(e, "item1", "longcontent")}
                                                    value={list.item1.masterid + "|" + list.item1.type}>
                                                    <option value="">Select</option>
                                                    {showlistField.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                        }
                                        {this.props.horizontalImage &&
                                            <div className="select-box">
                                                <select onChange={(e) => this.handleLabelChange(e, "item1", "longcontent")}
                                                        value={list.item1.masterid + "|" + list.item1.type}>
                                                    <option value="">Image</option>
                                                    {showlistImage.map((label, index) =>
                                                        <option key={`masterid` + index}
                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                        }
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item2", "longcontent")}
                                                value={list.item2.masterid + "|" + list.item2.type}>
                                            <option value="">Select</option>
                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item3", "longcontent")}
                                                value={list.item3.masterid + "|" + list.item3.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item4", "longcontent")}
                                                value={list.item4.masterid + "|" + list.item4.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item5", "longcontent")}
                                                value={list.item5.masterid + "|" + list.item5.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">

                                        <select onChange={(e) => this.handleLabelChange(e, "item6", "longcontent")}
                                                value={list.item6.masterid + "|" + list.item6.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item7", "longcontent")}
                                                value={list.item7.masterid + "|" + list.item7.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item8", "longcontent")}
                                                value={list.item8.masterid + "|" + list.item8.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="select-box">
                                        <select onChange={(e) => this.handleLabelChange(e, "item9", "longcontent")}
                                                value={list.item9.masterid + "|" + list.item9.type}>
                                            <option value="">Select</option>

                                            {showlistField.map((label, index) =>
                                                <option key={`masterid` + index}
                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        <div className="edit-template-right">
                            <div className="edit-template-right-header">
                                <h5>Preview</h5>
                            </div>
                            <div className="edit-template-right-body">
                                <HorizonalLongContent
                                    horizontaltemplate={horizontaltemplate}
                                    collection={this.props.collection}
                                    horizontalImage={horizontalImage}
                                    horizontalMapenabled={horizontalMapenabled}
                                    verticalMapenabled={verticalMapenabled}
                                    label={this.props.label}
                                    longcontent={longcontent}

                                />
                            </div>
                        </div>
                    </div>

                }
                {template === 'long1' &&
                    <div className="card-container grid-view">
                        <div className="ind-collection-card inline-flex-box edit-template">
                            <React.Fragment>
                                {long1template.map((item, index) =>
                                    <React.Fragment>
                                        <div className="edit-template-left">
                                            <div className="collection-card-left">

                                                {this.props.long1Image &&
                                                    <>
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item1", 'long1')}
                                                                value={item.item1.masterid + "|" + item.item1.type}>
                                                                <option value="">Image</option>

                                                                {showlistImage.map((label, index) =>
                                                                    <option key={`masterid` + index}
                                                                            value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item2", 'long1')}
                                                                value={item.item2.masterid + "|" + item.item2.type}>
                                                                <option value="">Select</option>
                                                                {showlistField.map((label, index) =>

                                                                    <option key={`masterid` + index}
                                                                            value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        {item.item3 &&
                                                            <div className="select-box">
                                                                <select
                                                                    onChange={(e) => this.handleLabelChange(e, "item3", 'long1')}
                                                                    value={item.item3.masterid + "|" + item.item3.type}>
                                                                    <option value="">Select</option>
                                                                    {showlistField.map((label, index) =>

                                                                        <option key={`masterid` + index}
                                                                                value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        }
                                                    </>
                                                }

                                                {!this.props.long1Image &&
                                                    <>
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item1", 'long1')}
                                                                value={item.item1.masterid + "|" + item.item1.type}>
                                                                <option value="">Select</option>
                                                                {showlistField.map((label, index) =>

                                                                    <option key={`masterid` + index}
                                                                            value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        <div className="select-box">
                                                            <select
                                                                onChange={(e) => this.handleLabelChange(e, "item2", 'long1')}
                                                                value={item.item2.masterid + "|" + item.item2.type}>
                                                                <option value="">Select</option>
                                                                {showlistField.map((label, index) =>

                                                                    <option key={`masterid` + index}
                                                                            value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className="edit-template-right">
                                    <div className="edit-template-right-header">
                                        <h5>Preview</h5>
                                    </div>
                                    <div className="edit-template-right-body">
                                        <TemplateHCardPreview
                                            templatecontent={long1template}
                                            collection={this.props.collection}
                                            label={this.props.label}
                                            long1Image={long1Image}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                }
                {/*{template === 'kanban' &&*/}
                {/*    <div className="card-container grid-view">*/}
                {/*        <div className="ind-collection-card inline-flex-box edit-template">*/}
                {/*            <React.Fragment>*/}
                {/*                <div className="edit-template-left">*/}
                {/*                    <div className="collection-card-left">*/}
                {/*                        {kanbanTemplate && kanbanTemplate.map((item, index) =>*/}
                {/*                            <React.Fragment key={'kanban_'+index}>*/}
                {/*                                <div className="radio-container">*/}
                {/*                                    <h5>Group By:</h5>*/}
                {/*                                    <div className="radio-wrap">*/}
                {/*                                        <div className="radio-block">*/}
                {/*                                            <input type="radio" name="formfields"*/}
                {/*                                                   checked={item.groupbyinfo[0].groupby === 'formfields'}*/}
                {/*                                                   value={'formfields'}*/}
                {/*                                                   onChange={(e) => this.handleLabelChange(e, "groupby", 'kanban')}/>*/}
                {/*                                            <label>Form Fields</label>*/}

                {/*                                        </div>*/}
                {/*                                        <div className="radio-block">*/}
                {/*                                            <input type="radio" name="flag"*/}
                {/*                                                   checked={item.groupbyinfo[0].groupby === 'flag'}*/}
                {/*                                                   value={'flag'}*/}
                {/*                                                   onChange={(e) => this.handleLabelChange(e, "groupby", 'kanban')}/>*/}
                {/*                                            <label>Flag</label>*/}
                {/*                                        </div>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                                {item.groupbyinfo[0].groupby === 'formfields' &&*/}
                {/*                                    <div className="select-box">*/}
                {/*                                        <select*/}
                {/*                                            onChange={(e) => this.handleLabelChange(e, "item1", 'kanban')}*/}
                {/*                                            value={item.groupbyinfo[0].item1.masterid + "|" + item.groupbyinfo[0].item1.type}*/}
                {/*                                        >*/}
                {/*                                            <option value="">Select</option>*/}
                {/*                                            {showlistField.filter(label=>label.type === "dropdown" || label.type === "switch").map((label, index) =>*/}

                {/*                                                <option key={`masterid` + index}*/}
                {/*                                                        value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>*/}
                {/*                                            )}*/}
                {/*                                        </select>*/}
                {/*                                    </div>*/}
                {/*                                }*/}
                {/*                                <div className="select-box">*/}
                {/*                                    <select*/}
                {/*                                        onChange={(e) => this.handleLabelChange(e, "item2", 'kanban')}*/}
                {/*                                        value={item.cardinfo[0].item2.masterid + "|" + item.cardinfo[0].item2.type}*/}
                {/*                                    >*/}
                {/*                                        <option value="">Card Title</option>*/}
                {/*                                        {showlistField.map((label, index) =>*/}

                {/*                                            <option key={`masterid` + index}*/}
                {/*                                                    value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>*/}
                {/*                                        )}*/}
                {/*                                    </select>*/}
                {/*                                </div>*/}
                {/*                                <div className="select-box">*/}
                {/*                                    <select*/}
                {/*                                        onChange={(e) => this.handleLabelChange(e, "item3", 'kanban')}*/}
                {/*                                        value={item.cardinfo[0].item3.masterid + "|" + item.cardinfo[0].item3.type}*/}
                {/*                                    >*/}
                {/*                                        <option value="">Card Description</option>*/}
                {/*                                        {showlistField.map((label, index) =>*/}

                {/*                                            <option key={`masterid` + index}*/}
                {/*                                                    value={label.masterid + "|" + label.type}>{label.hasOwnProperty('display_label') ? label.display_label : label.label}</option>*/}
                {/*                                        )}*/}
                {/*                                    </select>*/}
                {/*                                </div>*/}
                {/*                            </React.Fragment>*/}
                {/*                        )}*/}
                {/*                    </div>*/}
                {/*                </div>*/}

                {/*                /!*<div className="edit-template-right">*!/*/}
                {/*                /!*    <div className="edit-template-right-header">*!/*/}
                {/*                /!*        <h5>Preview</h5>*!/*/}
                {/*                /!*    </div>*!/*/}
                {/*                /!*    <div className="edit-template-right-body">*!/*/}
                {/*                /!*        <TemplateHCardPreview*!/*/}
                {/*                /!*            templatecontent={long1template}*!/*/}
                {/*                /!*            collection={this.props.collection}*!/*/}
                {/*                /!*            label={this.props.label}*!/*/}
                {/*                /!*            long1Image={long1Image}*!/*/}
                {/*                /!*        />*!/*/}
                {/*                /!*    </div>*!/*/}
                {/*                /!*</div>*!/*/}
                {/*            </React.Fragment>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}

            </React.Fragment>


        )
    }

}

export default EditTemplate;