import React, {Component} from 'react';
import { ResponsiveContainer, BarChart, Line, Bar, XAxis, YAxis,CartesianGrid, Tooltip, Area, Legend, LabelList } from 'recharts';
function BarChartCard(props) {
console.log(props.data)
    return (
        <React.Fragment>
            <ResponsiveContainer>
                <BarChart
                    width={240} height={180} data={props.data}>
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="x" padding={{left:0, right:0}} domain={[0, 500]}/>
                    <YAxis dataKey="y" axisLine={false} tickLine={false}  />
                    <Tooltip />
                    <Bar dataKey="y" fill={"#da731d"} radius={[5, 5, 0, 0]} maxBarSize={25}>
                        <LabelList dataKey="y" position="top" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

        </React.Fragment>
    );
}

export default BarChartCard;