import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getUrlSegment} from "../../../utilities/CustomFunctions";
import rootActions from "../../../actions";
import ProjectHeader from "../../views/ProjectHeader";

class ProjectFinancial extends Component {

    constructor(props) {
        super(props);

        this.state={
            expandHeader:false,
        }
        this.expandHeader = this.expandHeader.bind(this)
    }

    expandHeader(){
        this.setState({expandHeader : !this.state.expandHeader})
    }

    componentDidMount() {
        this.setState({loading: 1});
        var projectId = getUrlSegment(4);
        this.props.mainClick('project', '/project/details/financial/'+projectId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {

        return(

            <div className={`form-detail-container data-view field-form ${this.state.expandHeader === true ? "expands" :""}`}>
                <ProjectHeader expandHeader={this.expandHeader}/>
                <div className="ibt-body">
                    <div className="ibt-financial-body">
                        <div className="list-row">
                            <div className="list-common list-row-left">
                                <div className="ibt-split-table">
                                    <div className="blue-lister">
                                        <div className="grid-header">
                                            <h5>Return on Assets</h5>
                                        </div>
                                        <div className="blue-list-body">
                                            <ul className="graph-tag">
                                                <li className="green-circle">ROA</li>
                                                <li className="blue-circle">Targeted ROA</li>
                                            </ul>
                                            <div className="graph-wrap">
                                                <img src="/assets/images/graph-1.png" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blue-lister">
                                        <div className="grid-header">
                                            <h5>Return on Equity</h5>
                                        </div>
                                        <div className="blue-list-body">
                                            <ul className="graph-tag">
                                                <li className="green-circle">ROA</li>
                                                <li className="blue-circle">Targeted ROA</li>
                                            </ul>
                                            <div className="graph-wrap">
                                                <img src="/assets/images/graph-2.png"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blue-lister">
                                        <div className="grid-header">
                                            <h5>Working Capital Ratio</h5>
                                        </div>
                                        <div className="blue-list-body">
                                            <ul className="graph-tag">
                                                <li className="ash-circle">Ratio</li>
                                            </ul>
                                            <div className="graph-wrap">
                                                <img src="/assets/images/graph-3.png"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blue-lister">
                                        <div className="grid-header">
                                            <h5>Debt Equity Ratio</h5>
                                        </div>
                                        <div className="blue-list-body">
                                            <ul className="graph-tag">
                                                <li className="orange-circle">Ratio</li>
                                            </ul>
                                            <div className="graph-wrap">
                                                <img src="/assets/images/graph-4.png"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-common list-row-right">
                                <div className="ibt-progress-table">
                                    <div className="ibt-progress-table-header">
                                        <h5>Balance Sheet</h5>
                                        <p>All figures in US$</p>
                                    </div>
                                    <div className="ibt-progress-table-body">
                                        <div className="scrollable">
                                            <table className="table ibt-table bs-sheet-tb">
                                                <tbody>
                                                <tr className="main-row">
                                                    <td>TOTAL ASSETS</td>
                                                    <td align="right">620</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="sub-row">
                                                    <td>Current Assets</td>
                                                    <td align="right">300</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Cash</td>
                                                    <td align="right">150</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Accounts receivable</td>
                                                    <td align="right">70</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Inventory</td>
                                                    <td align="right">80</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="sub-row">
                                                    <td> Long-Term Assets</td>
                                                    <td align="right">320</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="main-row">
                                                    <td>TOTAL LIABILITIES</td>
                                                    <td align="right">620</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="sub-row">
                                                    <td>Current Liabilities</td>
                                                    <td align="right">190</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Accounts Payable</td>
                                                    <td align="right">70</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Short Term Debt</td>
                                                    <td align="right">120</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="sub-row">
                                                    <td>Shareholder Equity</td>
                                                    <td align="right">220</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Equity Capital</td>
                                                    <td align="right">200</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Retained Earnings</td>
                                                    <td align="right">20</td>
                                                    <td align="right">
                                                        <img src="/assets/images/bs-graph.png" height="21"/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ibt-split-table two-row">
                            <div className="blue-lister">
                                <div className="grid-header">
                                    <h5>Return on Assets</h5>
                                    <p>62%</p>
                                </div>
                                <div className="blue-list-body">
                                    <ul className="graph-tag">
                                        <li className="navy-circle">Gross Profit Margin</li>
                                        <li className="blue-circle">Targeted Gross Profit Margin</li>
                                    </ul>
                                    <div className="graph-wrap">
                                        <img src="/assets/images/green-graph.png"/>
                                    </div>
                                </div>
                            </div>
                            <div className="blue-lister">
                                <div className="grid-header">
                                    <h5>Net Profit Margin</h5>
                                    <p>32%</p>
                                </div>
                                <div className="blue-list-body">
                                    <ul className="graph-tag">
                                        <li className="blue-circle">Net Profit Margin</li>
                                        <li className="green-circle">Targeted Gross Profit Margin</li>
                                    </ul>
                                    <div className="graph-wrap">
                                        <img src="/assets/images/blue-graph.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="list-row one-row">
                            <div className="list-common list-row-left">
                                <div className="ibt-split-table">
                                    <div className="blue-lister">
                                        <div className="grid-header">
                                            <h5>OPEX</h5>
                                            <p>$40M</p>
                                        </div>
                                        <div className="blue-list-body">
                                            <ul className="graph-tag">
                                                <li className="navy-circle">Salaries</li>
                                                <li className="blue-circle">Insurance</li>
                                                <li className="orange-circle">Marketing</li>
                                                <li className="ash-circle">Lease</li>
                                                <li className="yellow-circle">Utilities</li>
                                                <li className="red-circle">Others</li>
                                            </ul>
                                            <div className="graph-wrap">
                                                <img src="/assets/images/multi-graph.png"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blue-lister">
                                        <div className="grid-header">
                                            <h5>Earning Before Interest and Taxes</h5>
                                        </div>
                                        <div className="blue-list-body">
                                            <ul className="graph-tag">
                                                <li className="blue-circle">Depreciation & Amortization</li>
                                                <li className="navy-circle">EBIT</li>
                                            </ul>
                                            <div className="graph-wrap">
                                                <img src="/assets/images/greenblue-graph.png"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-common list-row-right">
                                <div className="ibt-progress-table">
                                    <div className="ibt-progress-table-header">
                                        <h5>Income Statement</h5>
                                        <p>All figures in US$ Million</p>
                                    </div>
                                    <div className="ibt-progress-table-body">
                                        <div className="scrollable">
                                            <table className="table ibt-table bs-sheet-tb">
                                                <tbody>
                                                <tr className="main-row">
                                                    <td>Revenue</td>
                                                    <td align="right">470</td>
                                                </tr>
                                                <tr className="main-row">
                                                    <td>COGS</td>
                                                    <td align="right">210</td>
                                                </tr>
                                                <tr className="main-row">
                                                    <td>GROSS PROFIT</td>
                                                    <td align="right">70</td>
                                                </tr>
                                                <tr className="main-row">
                                                    <td>OPEX</td>
                                                    <td align="right">40</td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Salaries</td>
                                                    <td align="right">10</td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Insurance</td>
                                                    <td align="right">5</td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Marketing</td>
                                                    <td align="right">5</td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Lease</td>
                                                    <td align="right">10</td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Utilities</td>
                                                    <td align="right">5</td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Other Expences</td>
                                                    <td align="right">5</td>
                                                </tr>
                                                <tr className="main-row">
                                                    <td>EBT</td>
                                                    <td align="right">160</td>
                                                </tr>
                                                <tr className="nested-sub-row">
                                                    <td>Tax</td>
                                                    <td align="right">10</td>
                                                </tr>
                                                <tr className="main-row">
                                                    <td>NET PROFIT</td>
                                                    <td align="right">150</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}
const mapStateToProps = state => ({
    HistoryPushData:state.TaskReducer.HistoryPushData,
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
})(ProjectFinancial));
