import React, { useState , useEffect} from "react";
import DateTimePickerNew from "./DateTimePickerNew";
import moment from "moment";
import OutsideAlerter from "./OutsideAlerter";

function TextField(Props) {
    const [fieldValue, setFieldValue] = useState(Props.value);
    useEffect(() => {
        setFieldValue(Props.value);
    });
    function handleInputChange(e) {
        let fieldName =  e.target.id;
        let fieldValue = e.target.value;
        setFieldValue(fieldValue);
        Props.setFieldValue(fieldName, fieldValue)
    }
    let submitted = Props.submitted;

    return (
        <div className="new-task-input label-perst">
            <label>SUBJECT</label>
            <input type="text" placeholder={Props.placeholder} id={Props.id} name={Props.name} autoComplete="off"
                   defaultValue={fieldValue}
                   className={(Props.submitted && fieldValue === '')? 'val-error' : '' }
                   onChange={handleInputChange}
                    />
        </div>
    );

}
function TextArea(Props) {
    const [fieldValue, setFieldValue] = useState(Props.value);
    useEffect(() => {
        setFieldValue(Props.value);
    });
    const handleInputChange = (e) => {
        let fieldName =  e.target.id;
        let fieldValue = e.target.value;
        setFieldValue(fieldValue);
        Props.setFieldValue(fieldName, fieldValue)
    }
    let submitted = Props.submitted;

    return (
        <div className="new-task-input text-input">
            <textarea className={`task-textarea`} placeholder={Props.placeholder} id={Props.id} name={Props.name} value={fieldValue}
                      onChange={handleInputChange}></textarea>
        </div>

    );

}

function SelectField(Props) {
    const [fieldValue, setFieldValue] = useState(Props.value);
    useEffect(() => {
        setFieldValue(Props.value);
    });
    const handleInputChange = (e) => {
        let fieldName =  e.target.id;
        let fieldValue = e.target.value;
        setFieldValue(fieldValue);
        Props.setFieldValue(fieldName, fieldValue)
    }
    let submitted = Props.submitted;

    return (
        <div className="eq-wid select-box eq-width">
            <select id="priority" name="priority" onChange={handleInputChange} value={fieldValue}>
                {Props.options.map((opt, index) =>
                    <option value={opt.value}>{opt.label}</option>
                )}

            </select>
        </div>

    );

}


function DropDownBox(rest) {
    const [fieldValue, setFieldValue] = useState(rest.value);
    useEffect(() => {
        setFieldValue(rest.value);
    });
    const handleInputChange = (val) => {
        let fieldName = rest.name;
        let fieldValue = val;
        setFieldValue(fieldValue);
        rest.setFieldValue(fieldName, fieldValue)
    }
    let submitted = rest.submitted;
    const [stat, setStatus] = useState(false);
    return (
     <React.Fragment>
         <label className="select-label">{rest.label}: </label>
        <div className="select-box-group dropup">
            <button type="button" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => setStatus(!stat)}>
                {rest.value}
            </button>
            {stat &&
            <OutsideAlerter status={stat} Triggered={() => setStatus(!stat)}>
              <ul className="dropdown-menu" style={{display: "block"}}>
                {rest.options.map((opt, index) =>
                    <li>
                        <nav className={`dropdown-item ${rest.value ===opt.value?"active":'' } `} onClick={() => {handleInputChange(opt.value);setStatus(!stat)}}>{opt.label}</nav>
                    </li>
                )}

            </ul>
            </OutsideAlerter>
            }
         </div>
     </React.Fragment>


    );

}
function DateTimeField(Props) {
    const [fieldValue, setDate] = useState(Props.value);
    const [date_error, setError] = useState(Props.date_error);

    useEffect(() => {
        setDate(Props.value);
        setError(Props.date_error);
    });
    const handleDateChange = (date) => {
        Props.setFieldValue(Props.id, date)
    }
    let submitted = Props.submitted;
    return (
        <div className={`eq-wid input-log-time date task-duedate ${date_error? 'val-error': ''}`}>


            <DateTimePickerNew handleDateChange={handleDateChange}
                               placeholder={Props.placeholder}
                               name={Props.name}
                               id={Props.id}
                               startDate={Props.taskAction === 'edit' ? fieldValue : null}
                               min={Props.min}
                               action={Props.taskAction}
            />
        </div>
    );

}
export {TextField, TextArea, SelectField, DateTimeField,DropDownBox};