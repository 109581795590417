import React, {Component} from 'react';
import {FRMSIMG_PATH} from '../../constants/index'
import Coverimage from '../../images/placeholders/Coverimage.jpg';
import {getUrlSegment} from "../../utilities/CustomFunctions";
import moment from 'moment';
import Modal from "./Modal";
import AddEntityForm from "../pages/entity/AddEntityForm";
import {getProfile} from "../../utilities/AuthService";
import OutsideAlerter from "./OutsideAlerter";
class FormPanelEntity extends Component {

    constructor(props) {
        super(props);
        this.Minimize=this.Minimize.bind(this)
        this.Maximise=this.Maximise.bind(this)
        this.Close=this.Close.bind(this)
        this.showOption=this.showOption.bind(this)

        this.state={
            miniMize:false,
            Maximise:false,
            Close:false,
            EditNewForm: false,
            showEntityOptions: false,
        }
    }



    componentDidMount() {
    }


    componentWillUpdate(nextProps) {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }
    handleAction(e, act, id) {
        this.props.updateAction(e, act, this.props.list, id);
    }
    Minimize(){
        this.setState({Maximise:false,miniMize:true})
    }
    Maximise(){
        if(!this.state.miniMize) {
            this.setState({Maximise: !this.state.Maximise, miniMize: false})
        }
        else{
            this.setState({Maximise: false, miniMize: false})
        }
    }
    Close(){

    }

    showOption=()=>{
        this.setState({showEntityOptions: !this.state.showEntityOptions})

    }

    render() {

        var list = this.props.list;
        var entitydetail = this.props.entitydetail;
        let collectiontitle = decodeURIComponent(escape(atob(list._source.name)))
        let collectiondescription = decodeURIComponent(escape(atob(list._source.description)))
        let detailUrl = '';
        if(getUrlSegment(1) === 'project') {
            detailUrl = '/project/details/entitydetails/' + btoa(list._id)+'/'+getUrlSegment(4);
        }
        else{
            detailUrl = '/entity/details/' + btoa(list._id);
        }

        return (
            <div className="tile-each"  key={btoa(list._id)}>

                <div className="tile-info-all">
                    <div className="tile-description-box">
                        <div className={`tile-entity-description-box${list._source.systemgenerated === 'yes' ? ' sys_entity' : ' gen_entity'}`} onClick={(e)=>this.props.history.push(detailUrl)}>
                        <h5>{collectiontitle}</h5>
                        <p>{collectiondescription}</p>
                        {list._source.category && list._source.category !== "" &&
                            <p className={'category-tag'}>Category : {list._source.category}</p>
                        }
                        {/*<div className="form-option-block">*/}
                        {/*    <button className="ico-lib opt-form-ico"></button>*/}
                        {/*</div>*/}
                        </div>
                        <div className="form-option-block">
                            {(getProfile().usertype === 'Superadmin' || getProfile().usertype === 'root' || list._source.creator.toString() === getProfile().id.toString() ) &&
                            <button className="ico-lib opt-form-ico" onClick={()=>this.showOption()}></button>
                            }
                            {this.state.showEntityOptions &&
                            <OutsideAlerter
                                status={this.state.showEntityOptions}
                                Triggered={() => this.showOption(!this.state.showEntityOptions)}
                            >
                            <ul className="panel-option" id="option-list">
                                <li><nav className="panel-option-click ico-lib-v1 form-dup-ico" onClick={(e) => {
                                    this.props.duplicateEntity(list._id, 'delete');this.showOption(!this.state.showEntityOptions)}}>Duplicate</nav></li>
                                <li><nav className="panel-option-click ico-lib form-edit-ico" onClick={(e) => {
                                    this.props.editEntity('update', list._id);this.showOption(!this.state.showEntityOptions)}} >Edit</nav></li>
                                <li><nav className="panel-option-click ico-lib delete-task-ico" onClick={(e) => {
                                    this.props.deleteEntity(list._id, 'delete');this.showOption(!this.state.showEntityOptions)}} > Delete</nav></li>
                            </ul>
                            </OutsideAlerter>
                            }
                        </div>
                    </div>
                    <div className="tile-options-in">
                        <div className="date-data">
                            {/*{moment(list._source.createdate).format("Do MMM, YYYY")}*/}
                        </div>
                        <div className="other-info">
                            {/*<span className="ico-lib icon-form-task icon-style">{list._source.records ? list._source.records : 0}</span>*/}
                            <span className="ico-lib icon-form-share icon-style">{list._source.share}</span>
                        </div>
                        {/*<div className="span-box-in">*/}
                        {/*    <span className="icon-in icon-stack-filled"></span>*/}
                        {/*    <span className="item-count-in">{list.childs}</span>*/}
                        {/*</div>*/}

                        {/*<div className="span-box-in">*/}
                        {/*    <span className="icon-in icon-share-in"></span>*/}
                        {/*    <span className="item-count-in">{list.share}</span>*/}
                        {/*</div>*/}
                    </div>
                </div>

            </div>


        );
    }
}

export default FormPanelEntity;