import React, {Component} from 'react';


class ProjectFeatures extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {

    }
    render() {

        return (

            <div className="blue-list-body">
                <ul className="blue-single-list">
                    <li>Relatively under-developed area and hence creates strong development</li>
                    <li>Impact - The overall 123MW portfolio creates</li>
                    <li>Opportunities for French companies - Power Trade Agreement: very close to</li>
                    <li>Indian border and the 85 MW can consider export sale</li>
                </ul>
            </div>

        );
    }


}

export default ProjectFeatures;