import React, {Component,useState} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as CustomFunctions from "../../utilities/CustomFunctions";
import LoaderSvg from "./LoaderSVG";
import * as collectionActions from "../../actions/Collections";
import * as entityActions from "../../actions/Entity";
import {getProfile} from "../../utilities/AuthService";
import {ES_INDEX, FRMSDOC_PATH, FRMSIMG_PATH, FRMSVID_PATH, SHARED_LOGIN} from "../../constants";
import {Scrollbars} from "react-custom-scrollbars";
import {
    DateFormatter,
    forceDownload,
    getFilePath,
    isImage,
    isAudio,
    isVideo,
    urlify,
    formatDate, ruleCheck, hasExtension
} from "../../utilities/CustomFunctions";
import {getUrlSegment,getFileExtension} from "../../utilities/CustomFunctions";
import {DataTable} from "../views/DataTable";
import EntityPreview from "../../components/pages/ceshareview/dashboardBuilder/EntityPreview"
import Modal from "./Modal";
import AddToDoTask from "../pages/AddToDoTask";
import ResourcePreviewAttachments from "./ResourcePreviewAttachments";
import SimpleModal from "./SimpleModal";
import * as CeShareViewActions from "../../actions/CeShareView";
import moment from "moment";
import VideoPlayer from "./player/VideoPlayer";
import {Dropdown, Tab, Tabs} from "react-bootstrap";
import {
    CollectionBlock,
    DecisionBlock,
    FormDataBlock,
    PreviousStagesPanel
} from "../pages/workflow-task/WorkFlowTaskDetailsData";
import * as WorkFlowActions from "../../actions/WorkFlow";
import AlertModal from "./AlertModal";
import {closeRecordWorkflow} from "../../actions/WorkFlow";
import FileViewers from "../views/FileViewers";

import "./fileviewer.css";
import AddNotes from "../pages/ceshareview/AddNotes";
import ProjectChat from "../pages/chat/ProjectChat";
import Toaster from "./Toaster";

class ShowFormPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData : [],
            title : '',
            formSubmitted : false,
            metaForm :[],
            overlay_class : '',
            collection : [],
            showlinkdCol:false,
            loadmorecount:50,
            collectionId:'',
            hideLinkPanel:true,
            template:"template2",
            showTask: false,
            showAddNewTaskModal:true,
            addOptions: true,
            showVersionModal:false,
            showFieldVer:false,
            recInd:0,
            previewStatus: false,
            previewAttributes: {},
            previewCompModalStatus: false,
            submissionType : '',
            metasubmitted : false,
            decision_nextstage:'',
            meta_data : [],
            wfDetails : [],
            currentStage : {},
            previousStages : [],
            uploadedFiles : [],
            decisionmeta_data : [],
            decisionmeta_data_result : [],
            submission_decision_statusid : '',
            taskstatus : [],
            proceedModal : false,
            proceed : false,
            proceedDecisionModal : false,
            stageforward : '',
            stageforwardtext : '',
            showAccordian:0,
            finalStage : false,
            note:false,
            pan:false,
            msgOpn:false,
            showDuplicateConfirm : false,
            toasterdata:{type:"",msg:"",show:false}
        }

        this.getAttachmentPath = this.getAttachmentPath.bind(this);
        this.getFormValues = this.getFormValues.bind(this);
        this.nextButton = this.nextButton.bind(this);
        this.downloadAlll = this.downloadAlll.bind(this);
        this.showDuplicateConfirm = this.showDuplicateConfirm.bind(this);
    }

    componentDidMount() {

        let collectionId = getUrlSegment(1)=== 'project' ? getUrlSegment(4) : getUrlSegment(3);
        this.setState({
            collectionId : collectionId,
            id : this.props.state._id
        })
        this.getFormValues();
        this.getCollectionData();
        if(getUrlSegment(1) === "entity" ) {
            let param = {
                entityid: atob(collectionId),
                page: 1,
                per_page: 100,
                index: ES_INDEX,
            }
            this.props.entityCollection(param)
        }
        if(this.props.customtemplate && this.props.customtemplate.length !== 0){
            let masterIds=[]
            if(this.props.customtemplate.name === "template3") {

                this.props.customtemplate.template.map((list) => (
                    masterIds.push(list.masterid)
                ))

            }
            this.setState({masterIds:masterIds,templateItem:this.props.customtemplate.template,template:this.props.customtemplate.name})

        }
        else{
            this.setState({template:"template2"})

        }


    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.getFormdetails.formvalues !== prevProps.getFormdetails.formvalues) {
            this.getFormValues();
        }
        if(this.props.CollectionList !== prevProps.CollectionList) {
            this.getCollectionData();
        }

        //this.setState({loading: 0})
        if(prevProps.HistoryPushData !== this.props.HistoryPushData ) {

            this.setState({showAddNewTaskModal :false,addOptions: false,showTask: false});
        }

        if(this.props.stagetaskInfoDet.StagetaskInfo.task !== prevProps.stagetaskInfoDet.StagetaskInfo.task ){
            if( this.props.getFormdetails.wfinfo.length > 0 && this.props.getFormdetails.wfinfo[0].submissiontype === 'withoutdecision' ){
                let nextstage = this.props.stagetaskInfoDet.StagetaskInfo.task.length > 0 ? this.props.stagetaskInfoDet.StagetaskInfo.task[0].nextstage : [];
                let decision_nextstage = nextstage.length > 0 ? nextstage[0] : '';
                this.setState({decision_nextstage : decision_nextstage})
                if(this.state.meta_data.length === 0) {
                    this.setState({meta_data : this.props.stagetaskInfoDet.StagetaskInfo.metaform})
                }
            }
        }

        if(this.props.getRecordWorkflow.savedMeta !== prevProps.getRecordWorkflow.savedMeta ) {
            this.hideProceedmodal();
            this.hideProceedDecisionmodal();
            this.setState({saveloading : 0});
            let currentStage = this.state.currentStage;
            if(currentStage.submissiontype === 'withoutdecision' ) {
                this.processAssignedNextStage();
            }
        }
        if(this.props.getRecordWorkflow.processed !== prevProps.getRecordWorkflow.processed ) {
            this.hideProceedmodal();
            this.hideProceedDecisionmodal();
            this.setState({saveloading : 0});
            //this.props.getFormData({ _id : this.props.getFormdetails.id, metainfo: true, collectionid : this.state.collectionId, wfinfo: true});
           // this.loadTaskInfoStagewise();
            let currentStage = this.state.currentStage;
            currentStage.status = 'done';
            this.setState({currentStage : currentStage})

        }
        if(this.props.getRecordWorkflow.closed !== prevProps.getRecordWorkflow.closed ) {
            this.hideProceedmodal();
            this.hideProceedDecisionmodal();
            this.setState({saveloading : 0});
            //this.props.getFormData({ _id : this.props.getFormdetails.id, metainfo: true, collectionid : this.state.collectionId, wfinfo: true});

            //this.loadTaskInfoStagewise();
            let currentStage = this.state.currentStage;
            currentStage.status = 'done';
            this.setState({currentStage : currentStage})

        }
        if(this.props.recordDuplicate !== prevProps.recordDuplicate){
            if(this.props.recordDuplicate.data.success){
                this.setState({toasterdata:{type:"green",msg:"Record duplicated successfully",show:true}})
            }
            else{
                this.setState({toasterdata:{type:"red", msg: "Something went wrong",show:true}})
            }
        }

    }

    loadTaskInfoStagewise = () => {
        let wfDetails = this.props.getFormdetails.wfinfo;
        if(wfDetails.length > 0) {
            let wfLen = wfDetails.length;
            let currentStage = wfDetails[wfLen-1];
            let previousStages = wfDetails.slice(0, wfLen-1);
            let wf_meta_users = this.props.getFormdetails.wf_meta_users;
            previousStages = previousStages.reverse();
            previousStages = previousStages.map(stage => {
                stage.assignees = [];
                stage.assignees[0] = {user_name : stage.user_name, user_designation : stage.user_designation};
                return stage;
            })
            let stageid = currentStage.stageid;
            let workflowid = currentStage.workflowid;
            if(stageid && workflowid) {
                this.props.getstagetaskinfo({stageid: stageid, workflowid: workflowid});
            }
            let meta_data = currentStage.meta_form;
            this.setState({ meta_data : meta_data ,assigneeTaskStatus:currentStage.status});
            if(currentStage.has_metafilled && currentStage.has_metafilled === "yes"){
                this.setState({metasubmitted:true});
            }
            if(currentStage.status === "Done"){
                let wf_status_info = currentStage.wf_status_info;
                this.setState({decisionmeta_data_result : wf_status_info, decisionmade: wf_status_info ? wf_status_info.submission_statusid:''})
            }
            this.setState({currentStage : currentStage, previousStages :previousStages})
        }

    }

    getFormValues(){
        let formData = this.props.getFormdetails.formvalues;
        let meta = this.props.getFormdetails.meta;
        let submissionType = this.props.getFormdetails.draft ? 'Draft' :'';
        let formVersions = this.props.getFormdetails.versions;
        let id = this.props.getFormdetails.id;
        let wfDetails = this.props.getFormdetails.wfinfo;
        this.props.LinkedId(id);
        this.loadTaskInfoStagewise();
        this.setState({formData : formData,meta:meta,id:id,formVersions:formVersions, submissionType : submissionType, wfDetails :wfDetails});
    }
    getCollectionData(){
        let collection = [];
        if(this.props.CollectionList) {
            let currentCollection = this.props.CollectionList.filter(item => item.collection === this.props.collectionId);
            if(currentCollection.length >0) {
                if(this.props.formSource === 'CollectionBasedTask') {
                    let collectionids = currentCollection[0].users.filter(user => user.has_data ==='yes').map(item => item.fieldid);
                    collection = collectionids.length> 0 ? collectionids[0].split(",") :[];
                }
                else{
                    collection = currentCollection[0].users.filter(user => user.has_data ==='yes');
                }
            }
        }
        this.setState({
            collection : collection
        })
    }
    showHideForm() {
        this.props.hideFormModal();
    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }
        return filePath;
    }
    renderFields(field, index, childIndex) {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index) :this.showFieldValue(field, index, childIndex);
    }
    showFieldValue(field, index, childIndex) {
        if(field.type === "otp" || field.type === "payment") {
            return false
        }
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
            //////////console.log(field.value[childIndex]);
        }

        return <tr key={index}>
            <td>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</td>
            {field.type === 'formheading' ?
                <td>&nbsp;</td>
                :
                <td> {field.values &&
                field.values.map((value, itemIndex) =>{

                        return(
                            <React.Fragment>
                                {(itemIndex>0 && field.type !== 'attachment' && field.type !== 'imageurl' && field.type !== 'url')? ", ":''}
                                {this.renderFieldType(value, field.type,field.values.length,itemIndex)}
                            </React.Fragment>
                        )
                    }
                )
                }
                </td>}
        </tr>;
    }
    prevButton=(e)=>{
        this.setState({formData : [],metaForm:[]})
        let id = '';
        let collectionid =this.state.collectionId !==""?this.state.collectionId: this.props.collectionId;
        if(getUrlSegment(1) === "entity" && !this.state.showlinkdCol && this.state.hideLinkPanel) {
            id=this.props.entityData.CollectionData[this.props.state.index+1].id
        }
        else if(this.props.formSource === 'Task') {
            id = this.state.collection[this.props.state.index-1].fieldid;
            collectionid = this.state.collection[this.props.state.index-1].collection;
        }
        else if(this.props.formSource === 'CollectionBasedTask') {
            id = this.state.collection[this.props.state.index-1];
        }
        else{
            id=this.props.collectionData.CollectionData[this.props.state.index-1].id
        }
        this.props.slideShow(e,id,this.props.state.index-1,collectionid)
    }
    nextButton=(e)=>{
        this.setState({formData : [],metaForm:[]})
        let id = '';
        let collectionid =this.state.collectionId !==""?this.state.collectionId: this.props.collectionId;
        if(getUrlSegment(1) === "entity" && !this.state.showlinkdCol && this.state.hideLinkPanel) {
            id=this.props.entityData.CollectionData[this.props.state.index+1].id
        }
        else if(this.props.formSource === 'Task') {
            id = this.state.collection[this.props.state.index+1].fieldid;
            collectionid = this.state.collection[this.props.state.index+1].collection;
        }
        else if(this.props.formSource === 'CollectionBasedTask') {
            id = this.state.collection[this.props.state.index+1];
        }
        else{
            id=this.props.collectionData.CollectionData[this.props.state.index+1].id
        }
        this.props.slideShow(e,id,this.props.state.index+1, collectionid)
    }
    renderFieldType(values, fieldType) {
        let fieldValue = '';

        if(fieldType === 'attachment') {
            let uniqname = (values && values.uniqvalue) ? values.uniqvalue : '';
            let filename = values ? values.value : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileType = getFilePath(uniqname);
            var albumName = 'documents';
            if(filename !== ''){
                if (CustomFunctions.isImage(filename)) {
                    albumName = 'images'
                } else if (CustomFunctions.isVideo(filename)) {
                    albumName = 'videos'
                } else if (CustomFunctions.isAudio(filename)) {
                    albumName = 'audios'
                } else {
                    albumName = 'documents'
                }
            }

            if(uniqname !== '') {
                fieldValue =
                    <>
                        <li>
                            {fileType === 'image' ?
                                <>
                                    <a href="javascript:void(0);" className="attach-click"
                                       onClick={(e) => this.preview(contentPath + uniqname, filename,albumName,'yes')}
                                       data-toggle="tooltip"
                                       data-placement="bottom" data-original-title="dummy">
                                        <img className="custom-image" src={FRMSIMG_PATH + uniqname}/>
                                    </a>
                                </>
                                :
                                fileType === 'video' || fileType === 'audio' ?
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => this.preview(contentPath + uniqname, filename,albumName,'yes')}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, filename)}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                            }
                        </li>
                    </>
            }
        }
        else if(fieldType === 'editor') {
            fieldValue = <div dangerouslySetInnerHTML={{__html: values.value}}></div>
        }else if(fieldType === 'signature') {
            fieldValue = <img className="custom-image" src={values.value}/>
        }
        else if(fieldType === 'imageurl') {
            let filenameArr = values.value ? values.value.split(".") :[];
            let filename = '';
            if(filenameArr.length > 0){
                filename = filenameArr[filenameArr.length-1];
            }
            fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(values.value, filename)}>
                <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={values.value}/>
            </div>
        }
        else if(fieldType === 'photocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            if(uniqname !== '' && uniqname !== 'undefined') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={FRMSIMG_PATH + uniqname}/>
                </div>
            }

        }
        else {
            //console.log(fieldType+"----"+values.value)
            fieldValue = (fieldType === 'computedfield') ? (values.value >0 ? parseFloat(values.value).toFixed(2) : 0):values.value;
        }

        return fieldValue;

    }
    showFieldSetValue(field, index) {
        let children = field.values ?field.values:[];
        return <>
            <tr key={index}>

                <td colSpan="2" style={{color: "#3FA9F5"}}>{field.label}</td></tr>
            {children.length > 0 &&
            this.renderChildFields(children, index)
            }
        </>;
    }
    downloadAlll(field, index) {
        let formData = this.state.formData;
        let files = []
        for(var i=0; i< formData.length; i++) {
            let file = []
            if(formData[i].type === 'attachment'){
                if(formData[i].values && formData[i].values.length > 0 && formData[i].values.uniqvalue) {
                    files.push(FRMSIMG_PATH + formData[i].values[0].uniqvalue)
                }
            }
        }
        //id :getProfile().id, change by Uk
        let param={
            id :this.props.state._id,
            files : files,
            collection : this.props.collectionId,
            index:ES_INDEX

        }
        window.open(SHARED_LOGIN.CISERVICE + 'Collection/downloadfolder/'+param.id+"/"+param.collection+"/"+param.index, '_blank');
        ////////console.log(param)
        // this.props.downloadAlll(param)


    }
    renderChildFields(children, index){

        var rows = [];
        for(var j=0; j< children.length; j++) {
            rows.push(this.renderFields(children[j], j, 0 ));
        }
        return rows;
    }
    getLinkedCollection=(collectionid,title)=>{
        let param={
            from: 0,
            size: this.state.loadmorecount,
            index: ES_INDEX,
            search:'',
            "uid": getProfile().id,
            "usertype": getProfile().usertype,
            linkedids:this.state.id,
            "dashboard":false
        }
        this.props.collectionDetails(collectionid, param)
        this.props.showform({ _id : collectionid});
        this.setState({showlinkdCol:true,collectionId:collectionid,CollectionName:title})
    }
    showPreviewFormModal=(e,id,index)=>{
        let collectionid = this.state.collectionId
        this.props.slideShow(e,id,index, collectionid)

        //this.props.getFormData({ _id : id, collectionid : collectionid,metainfo:true});
        this.setState({
            collectionid : collectionid,
            showPreviewForm : true,
            index:index,
            _id : id,
            showlinkdCol:false,
            hideLinkPanel:false
        })
    }
    hidePreviewForm=()=>{
        this.setState({
            formData : [],
            title : '',
            formSubmitted : false,
            metaForm :[],
            collection : [],
            showlinkdCol:false,
            collectionId:'',
            hideLinkPanel:true
        })
        this.props.hidePreviewForm()
    }


    taskAction=()=>{

        this.setState({showTask:true})

    }
    closeAddNewTaskModal(){
        this.setState({showAddNewTaskModal :false,showTask: false,addOptions: false});

    }
    showFormPreviousVersion = (e, field, collectionId, recordId, fieldId) => {
        this.setState({showVersionModal : !this.state.showVersionModal});
        let params = {collectionid : collectionId? collectionId : this.state.collectionid, recordid : recordId, search :'', page : 1, per_page : 100, uid : getProfile().id, fieldid : fieldId };
        this.props.trackVersion(params)

    }
    preview=(url, name,albumName,download)=>{
        this.setState({url: url, name: name, previewStatus: !this.state.previewStatus,albumName:albumName,download:download})
    }
    closePreview=()=>{
        this.setState({previewStatus: false,item:''})
    }
    toasterClose=()=>{
        this.setState({toasterdata:{type:"",msg:"",show:false}})
    }
    showDuplicateConfirm() {
        this.setState({showDuplicateConfirm : !this.state.showDuplicateConfirm});
    }
    handleDuplicate=()=> {
        this.showDuplicateConfirm();
        let param = {
            "cid": this.props.collectionId,
            "recordid": this.state.id,
            "uid": getProfile().id
        }
        this.props.collectionRecordDuplicate(param);
    }
    versionList=()=>{
        this.props.fieldVersion({collectionid:this.props.collectionId, recordid:this.state.id, from:0, size:25})
        this.setState({showFieldVer:true})
    }
    recIndxchange=(inx)=>{
        this.setState({recInd:inx})
    }
    previewCompModal=(previewAttributes)=> {
        this.setState({
            previewAttributes:previewAttributes,
            previewCompModalStatus: !this.state.previewCompModalStatus
        })
    }
    closePreviewCompModal=()=>{
        this.setState({previewCompModalStatus: false, previewAttributes:{}})
    }
    showCollectionModal(collectionid, formName, formDesc) {
        // alert(collectionid);
        this.props.showform({ _id : collectionid, uid : getUrlSegment(5)},'internal');
        this.setState({
            formName : formName,
            formDesc : formDesc,
            collection_id : collectionid,
            showCollectionModal: true
        })
    }
    hideCollectionModal(id) {
        this.setState({
            showCollectionModal: false
        })
    }
    previewCollection(id, collectionid,index) {
        // alert(id)
        // alert(collectionid)
        this.props.getFormData({ _id : id, metainfo: true, collectionid : collectionid, wfinfo: true});
        this.setState({
            collection_id : collectionid,
            showpreviewCollectionFormModal : true,
            index:index,
            _id : id
        })
    }


    hidePreviewCollection() {
        this.setState({
            showpreviewCollectionFormModal : false
        })
    }

    getUploadedFile(uploadedFile, index) {

        let uploadedFiles = this.state.uploadedFiles;
        uploadedFiles.push(uploadedFile);
        this.setState({
            uploadedFiles : uploadedFiles
        });
        var element = document.getElementById("progress-bar-wrapper");
        element.parentNode.removeChild(element);

        let fileType = CustomFunctions.getFileIcon(uploadedFile.fileName);
        let extension = (fileType=== 'image')? '1':(fileType=== 'mp4' ? '2': '3');

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var

        meta_data[index]['value'] = meta_data[index]['value']?meta_data[index]['value']:[];

        let fileOptions = {
            extension : extension,
            uniqval : uploadedFile.fileName.split('/').pop(),
            value : uploadedFile.originalfileName,
            fileId : uploadedFile.fileId
        };

        meta_data[index]['value'].push(fileOptions);

        this.setState({meta_data : meta_data});

    }

    getUploadedFileDecision = (uploadedFile, index) => {

        let uploadedFiles = this.state.uploadedFiles;
        uploadedFiles.push(uploadedFile);
        this.setState({
            uploadedFiles : uploadedFiles
        });
        console.log("inside show form preview")
        var element = document.getElementById("progress-bar-wrapper");
        console.log(element)
        element.parentNode.removeChild(element);

        let fileType = CustomFunctions.getFileIcon(uploadedFile.fileName);
        let extension = (fileType=== 'image')? '1':(fileType=== 'mp4' ? '2': '3');

        var decisionmeta_data = [...this.state.decisionmeta_data]; // make a separate copy of the state var

        decisionmeta_data[index]['value'] = decisionmeta_data[index]['value']?decisionmeta_data[index]['value']:[];

        let fileOptions = {
            extension : extension,
            uniqval : uploadedFile.fileName.split('/').pop(),
            value : uploadedFile.originalfileName,
            fileId : uploadedFile.fileId
        };

        decisionmeta_data[index]['value'].push(fileOptions);

        this.setState({decisionmeta_data : decisionmeta_data});

    }

    removeSelectedAttachment(index) {
        let uploadedFiles = this.state.uploadedFiles;

        if(uploadedFiles) {
            uploadedFiles.splice(index, 1);
            this.setState({uploadedFiles: uploadedFiles});
        }
    }

    submitWorkFlowAction =(e,id) =>{
        e.preventDefault();
        this.setState({
            submitted : true,
            metasubmitted : true
            //nextstageid :id
        });
        let formInvalid = 0;

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var


        if(meta_data.length > 0) {
            for(var i=0; i<meta_data.length; i++) {

                if (meta_data[i]['type'] === "loggedin") {
                    meta_data[i]['value'] =[{value : getProfile().first_name+" "+ getProfile().last_name}];
                }

                if (meta_data[i]['required'] === 'yes') {


                    if (meta_data[i]['type'] !== "attachment") {

                        if (!this.state[meta_data[i]['id']] || this.state[meta_data[i]['id']] === '') {

                            formInvalid++

                        }
                    } else {
                        if (this.state.uploadedFiles.length === 0) {

                            formInvalid++
                        }
                    }
                }
            }
        }
        this.setState({meta_data :meta_data})
        if(formInvalid === 0){

            this.showProceedmodal();
        }
    }

    submitWorkFlow(){
        let stageTaskDetails = this.props.stagetaskInfoDet.StagetaskInfo;
        if(stageTaskDetails) {
            let taskDetails = stageTaskDetails.task && stageTaskDetails.task[0];
            let wfDetails = this.state.wfDetails || [];

            if(taskDetails) {
                let currentStage = this.state.currentStage;

                let workflowid = taskDetails.workflowid;
                let collectionid = taskDetails.collectionid;
                let recordid = currentStage.recordid;


                let status = stageTaskDetails.status.filter( item => item._id ===this.state.stageforward) || [];

                let nextstageid = '';
                if(currentStage.submissiontype === 'withoutdecision') {
                    let nextstagebutton = currentStage.nextstagebutton;
                    nextstageid = nextstagebutton.length >0 ? nextstagebutton[0].id : '';
                }
                let _id = currentStage._id;

                if(this.props.stagetaskInfoDet.StagetaskInfo.metaform.length > 0 ) {
                    // If form exists
                    let metaformdata =  {
                        "metaresult": this.state.meta_data,
                        "uid": getProfile().id,
                        "collectionid": collectionid,
                        "recordid": recordid,
                        "_id" : _id
                    }

                    this.props.saveWorkflowMetaForm(metaformdata);
                    this.setState({metasubmitted:true});
                }

                let processworkflowparams = {

                    previoustask: "0",
                    stageid : nextstageid,
                    collectionid : collectionid,
                    recordid : recordid,
                    workflowid : workflowid,
                    uid : getProfile().id,
                    invokeid : _id,
                    statusinfo: {
                        meta: this.state.decisionmeta_data,
                        submission_status :this.state.stageforwardtext,
                        submission_statusid: this.state.stageforward,
                        submitted_user:getProfile().id,
                    },
                }


                let finalStage = nextstageid === '' ? true :false;
                this.setState({processworkflowparams : processworkflowparams, finalStage : finalStage},function(){
                    if(this.props.stagetaskInfoDet.StagetaskInfo.metaform.length === 0 && currentStage.submissiontype === 'withoutdecision' ) {
                        this.processAssignedNextStage();
                    }
                })

            }
        }
        this.setState({saveloading : 1});

    }
    submitWorkFlowDecisionAction = (e) =>{
        e.preventDefault();
        let currentStage = this.state.currentStage;
        if(currentStage && currentStage.submissiontype === 'withdecision') {
            let nextstageid = this.state.decision_nextstage;
            if(nextstageid === '') {
                this.setState({noDecisionAlert : true});
            }
            else {
                this.setState({
                    decisionsubmitted : true,
                    //nextstageid :id
                });
                let formInvalid = 0;
                var decisionmeta_data = [...this.state.decisionmeta_data]; // make a separate copy of the state var

                if(decisionmeta_data.length > 0) {
                    for(var i=0; i<decisionmeta_data.length; i++) {

                        if (decisionmeta_data[i]['required'] === 'yes') {

                            if (decisionmeta_data[i]['type'] !== "attachment") {

                                if (!this.state[decisionmeta_data[i]['id']] || this.state[decisionmeta_data[i]['id']] === '') {
                                    formInvalid++
                                }
                            } else {
                                if (this.state.uploadedFiles.length === 0) {

                                    formInvalid++
                                }
                            }
                        }
                    }
                }

                if(formInvalid === 0){
                    this.showProceedDecisionmodal();
                }
            }

        }

    }

    showProceedmodal(){
        this.setState({proceedModal : true})
    }
    hideProceedmodal(){
        this.setState({proceedModal : false})
    }
    showProceedDecisionmodal(){
        this.setState({proceedDecisionModal : true})
    }
    hideProceedDecisionmodal(){
        this.setState({proceedDecisionModal : false})
    }
    proceedSubmit(){
        this.setState({proceed : !this.state.proceed})
    }

    hideNoDecisionModal() {
        this.setState({noDecisionAlert : false });
    }
    submitWorkFlowDecision(){
        let stageTaskDetails = this.props.stagetaskInfoDet.StagetaskInfo;
        if(stageTaskDetails) {
            let taskDetails = stageTaskDetails.task && stageTaskDetails.task[0];
            let currentStage = this.state.currentStage || {};

            if(taskDetails) {
                let workflowid = taskDetails.workflowid;
                let collectionid = taskDetails.collectionid;
                let recordid = currentStage.recordid;
                let status = stageTaskDetails.status.filter( item => item._id ===this.state.stageforward) || [];
                console.log(status); //return false;
                let _id = currentStage._id;

                let nextstageid = (status.length >0 && status[0].nextstage && status[0].nextstage.length > 0) ? status[0].nextstage[0] : '';

                let processworkflowparams = {

                    previoustask: "0",
                    stageid : nextstageid,
                    collectionid : collectionid,
                    recordid : recordid,
                    workflowid : workflowid,
                    uid : getProfile().id,
                    invokeid : _id,
                    statusinfo: {
                        meta: this.state.decisionmeta_data,
                        submission_status :this.state.stageforwardtext,
                        submission_statusid: this.state.stageforward,
                        submitted_user:getProfile().id,
                    },
                }
                let finalStage = nextstageid === '' ? true :false;

                /*if(finalStage){
                    if(status.length >0 && status[0].customapi && status[0].customapi.length > 0) {
                        finalStage = false;
                    }
                }*/


                this.setState({processworkflowparams : processworkflowparams,finalStage : finalStage},function(){
                    console.log(processworkflowparams);
                    this.processAssignedNextStage();
                })


            }
        }
        this.setState({saveloading : 1});


    }

    processAssignedNextStage = () => {
        let finalStage = this.state.finalStage;
        let params = this.state.processworkflowparams;
        if(finalStage) {
            params.stageid = 'final';
        }

        /*if(finalStage) {
            params = {
                _id : params.invokeid,
                uid : getProfile().id
            }
            this.props.closeRecordWorkflow(params);
        }
        else{*/
            this.props.processAssignedNextStage(params);
       // }
    }

    processthroughapi() {
        let todoid = this.props.taskdetailsData.taskdetails.parent.masterid;
        let currentstageid = this.props.taskdetailsData.taskdetails.parent.stageid;
        let fieldid = this.props.taskdetailsData.taskdetails.parent.collecteddataid;
        let collectionid = this.props.taskdetailsData.taskdetails.parent.collecteddata;
        let closeworkflowparams = {previoustask : todoid, stageid: currentstageid, recordid : fieldid, collectionid : collectionid, uid : getProfile().id};

        this.props.processthroughapi(closeworkflowparams);
    }
    removeSelectedAttachment(index) {
        let uploadedFiles = this.state.uploadedFiles;

        if(uploadedFiles) {
            uploadedFiles.splice(index, 1);
            this.setState({uploadedFiles: uploadedFiles});
        }
    }

    handleInputchange = (e, index) =>{

        this.setState({
            [e.target.name] : e.target.value
        });

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var

        meta_data[index]['value'] = meta_data[index]['value']?meta_data[index]['value']:[];

        if(e.target.type === 'checkbox'){

            if(e.target.checked) {
                let optionVal = {value : '' };
                optionVal.value = e.target.value;
                meta_data[index]['value'].push(optionVal);
            }
        }
        else {
            meta_data[index]['value'] =[{value : e.target.value}];
        }
        this.setState({meta_data : meta_data});
    }

    handleDateChange(date, index){

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var

        meta_data[index]['value'] = meta_data[index]['value']?meta_data[index]['value']:[];

        meta_data[index]['value'] =[{value : formatDate(date)}];

        this.setState({
            [meta_data[index]['id']] : date
        });

        this.setState({meta_data : meta_data});

    }
    handleDateDecisionchange = (date,index) =>{
        var decisionmeta_data = [...this.state.decisionmeta_data]; // make a separate copy of the state var

        decisionmeta_data[index]['value'] = decisionmeta_data[index]['value']?decisionmeta_data[index]['value']:[];

        decisionmeta_data[index]['value'] =[{value : formatDate(date)}];

        this.setState({
            [decisionmeta_data[index]['id']] : date
        });

        this.setState({decisionmeta_data : decisionmeta_data});
    }

    handleInputDecisionchange = (e,index) =>{
        //console.log(e.target.name)
        //console.log(e.target.value)
        this.setState({
            [e.target.name] : e.target.value
        });

        var decisionmeta_data = [...this.state.decisionmeta_data]; // make a separate copy of the state var

        decisionmeta_data[index]['value'] = decisionmeta_data[index]['value']?decisionmeta_data[index]['value']:[];

        if(e.target.type === 'checkbox'){
            let optionVal = {value : '' };
            if(e.target.checked) {
                optionVal.value = e.target.value;
            }
            decisionmeta_data[index]['value'].push(optionVal);
        }
        else {
            decisionmeta_data[index]['value'] =[{value : e.target.value}];
        }
        this.setState({decisionmeta_data : decisionmeta_data});

    }

    handleDecisionInputchange = (e,decisionmeta,nextstage,stageforwardtext, submission_statusid)=>{
        let decision_nextstage = nextstage.length > 0 ? nextstage.toString() : '';
        //////////console.log(nextstage)
        if((nextstage.length > 0 && nextstage[0] === '') || nextstage.length === 0 ) {
            decision_nextstage = 'final';
        }
        this.setState({
            [e.target.name] : e.target.value,
            decisionmeta_data : decisionmeta,
            decision_nextstage : decision_nextstage,
            stageforwardtext : stageforwardtext,
            decisionSet : true,
            submission_decision_statusid : submission_statusid
        },function(){
            //////////console.log(this.state)
        });
    }

    renderDecisionField=(rules,status,stat,submission_statusid)=> {
        let field = '';
        let fieldRules ={};
        let ruleTrigger='';
        let ruleValue='';
        // //////////console.log(rules)
        if(rules.length > 0) {
            for(var i=0; i<rules.length; i++) {
                let fieldItems = rules[i].fieldItem;
                for(var j=0; j<fieldItems.length; j++) {
                    fieldRules = fieldItems[j].field_rule;
                    field = fieldItems[j].field;
                    ////////////console.log(fieldRules);
                    // for(var k=0; k<fieldRules.length; k++){
                    //     ruleTrigger=fieldRules[k].trigger;
                    //     ruleValue=fieldRules[k].value;
                    // }
                }
            }
        }
        let valdata=(this.state.meta_data.filter(item => (item.id === field)))
        let val=""
        if(valdata.length !==0 && valdata[0].value !== undefined) {
            val = (this.state.meta_data.filter(item => (item.id === field)).map(item => item.value)[0].map(item => item.value));
        }
        //////////console.log(this.status)
        if(valdata.length !==0 && valdata !==undefined && fieldRules !== undefined && ruleCheck(val,fieldRules[0].value,fieldRules[0].trigger)) {
            return (
                <React.Fragment>

                    <li>
                        <div className={(stat._id===submission_statusid) ? " radio-block active" :"radio-block"} key={'status'}>
                            {/*{status}*/}
                            <input type="radio" id={"singlestep" + stat._id}
                                   className="off_type"
                                   name="stageforward" defaultValue={stat._id}
                                   onChange={(e) => this.handleDecisionInputchange(e,stat.meta,stat.nextstage,stat.status, submission_statusid)}
                                   defaultChecked={stat._id === submission_statusid}
                                   value={stat._id}

                            />
                            <label htmlFor={"singlestep" + stat._id}>{stat.status}</label>
                        </div>
                    </li>
                </React.Fragment>
            )
        }
        else{
            return null;
        }



    }

    openAccordian = (e,index) =>{
        e.preventDefault();
        if(this.state.showAccordian === index){
            index=-1;
        }
        this.setState({showAccordian:index})
    }
    changeNotes=(val)=>{
        alert(this.props.recordId)
        this.props.changeNotes(val,this.props.recordId)
    }
    panToggle=(pan)=>{
        if(pan === "note") {
            this.setState({note: true,chat:false})
        }
        else{
            this.setState({chat: true,note:false})

        }
    }
    closePanel=(pan)=>{
        if(pan === "note") {
            this.setState({note: false})
        }
        else{
            this.setState({chat: false})

        }
    }

    msgOpn=(opSt)=>{
        this.setState({[opSt]:!this.state[opSt]})
    }
    msgCnt=(cnt)=>{
        this.setState({msgCnt:cnt})
    }
    render() {

        let collection=''
        let formData = this.state.formData;
        let meta = this.state.meta;
        let submissionType = this.state.submissionType;
        let formVersions = this.state.formVersions;
        let CollectionId=this.props.collectionId
        let _id = this.props.state._id;
        if(getUrlSegment(1) === "entity" && !this.state.showlinkdCol){
            collection = this.props.entityData.CollectionData;
        }
        else{
            collection = this.props.collectionData.CollectionData;
        }
        if(this.props.formSource && ( this.props.formSource === 'Task' || this.props.formSource === 'CollectionBasedTask')) {
            collection = this.state.collection;
        }
        let collectionlength = collection.length;
        if(this.props.formSource && this.props.formSource === 'TaskPool') {
            collectionlength = 1;
        }
        let attachmentfileds = formData.filter((item => (item.type ==='attachment' && (item.values !=='' && item.values.length !== 0) )))
        let linkedDetails = this.props.LinkedCollectionData.linkedDetails;
        let collectiondata = this.props.collectionData;
        if(getUrlSegment(1) === "entity" && !this.state.hideLinkPanel){
            collectionlength = this.props.collectionData.CollectionData.length;
        }
        let cname = getUrlSegment(1) === "entity" ? this.props.entityData.CollectionInfo.title : this.props.collectionData.CollectionInfo.title;
        let collectionRecord={cId:(this.props.collectionId),recordId:this.props.recordId,cName:cname}
        //console.log(collectionRecord)

        let wfDetails = this.state.wfDetails || [];
        let currentStage = this.state.currentStage || {};
        let previousStages = this.state.previousStages || [];




        return (
            <>
            <div className="modal full-wid-mod" id="collection-details" tabIndex="-1" role="dialog"
                 aria-labelledby="mngModal" aria-hidden="true" style={{display: "block"}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-header-left common-left-header active">
                                {this.state.toasterdata.show &&
                                <Toaster
                                    toasterdata={this.state.toasterdata}
                                    toasterClose={this.toasterClose}
                                />
                                }
                                {(getUrlSegment(1) === "entity") &&
                                    <div className="mobile-menu-header">
                                        <div className="toggle-mnu hidden-lg on">
                                            <span/>
                                        </div>
                                    </div>
                                }
                                <div className="common-modal-header" onClick={!this.props.hasOwnProperty("type") ? () => this.hidePreviewForm():''}>
                                    {(getUrlSegment(1) === "entity") ?
                                        <h5>{this.props.entityData.CollectionInfo.title}</h5>
                                        :
                                        <h5>{this.props.collectionData.CollectionInfo.title}</h5>
                                    }
                                </div>
                            </div>
                            {!this.props.hasOwnProperty("type") &&
                                <div className="modal-header-right align-right-option">
                                    <div className="collection-chat" onClick={()=>this.panToggle("chat")}/>
                                    <div className="collection-note" onClick={()=>this.panToggle("note")}/>
                                    <div className="right-preview-options">

                                    {formData.length > 0 && !this.state.showlinkdCol && !this.props.hasOwnProperty("hideButton") &&
                                    <Dropdown>
                                        <Dropdown.Toggle className="more-btn" menuAlign="right">More actions</Dropdown.Toggle>
                                        <Dropdown.Menu className="more-panel">
                                            <Dropdown.Item onClick={(e) => {this.versionList();}} id="version"  className="panel-option-click ico-lib form-version-ico" target={"_blank"}>Versions</Dropdown.Item>

                                            <Dropdown.Item onClick={(e) => {this.taskAction();}} id="task" className="panel-option-click ico-lib form-assign-ico" target={"_blank"}>Task</Dropdown.Item>

                                            {getUrlSegment(1) !== 'task' &&
                                            <Dropdown.Item onClick={() => window.location.href = ('/' + getUrlSegment(1) + '/print/' + btoa(_id) + '/' + btoa(CollectionId))} id="Printl" className="panel-option-click ico-lib print-ico">Print</Dropdown.Item>
                                            }

                                            {
                                            attachmentfileds.length !== 0 &&
                                            <Dropdown.Item onClick={this.downloadAlll} id="downloadall" className="panel-option-click ico-lib export-ico-absolute" target={"_blank"}>Download All</Dropdown.Item>
                                            }

                                            <Dropdown.Item href={this.props.previewSource === 'entity' ? SHARED_LOGIN.CISERVICE + `Collection/exportexcel/` + _id + `/1/` + CollectionId + `/0/` + ES_INDEX : SHARED_LOGIN.CISERVICE + `Collection/exportexcel/` + _id + `/0/` + CollectionId + `/0/` + ES_INDEX}
                                               id="exportExcel" className="panel-option-click ico-lib export-ico-absolute" target={"_blank"}>Export To Excel</Dropdown.Item>

                                            <Dropdown.Item href={SHARED_LOGIN.CISERVICE + `Collection/exportpdf/` + _id + `/` + CollectionId + `/` + ES_INDEX}
                                               id="exportpdf" className="panel-option-click ico-lib export-ico-absolute" target={"_blank"}>Export To PDF</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => {this.showDuplicateConfirm();}} id="duplicate"  className="panel-option-click ico-lib-v1 form-dup-ico" target={"_blank"}>Duplicate Record</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    }


                                        {/* {formData.length > 0 && !this.state.showlinkdCol &&
                                        <React.Fragment>
                                            <button onClick={(e) => {
                                                this.versionList();
                                            }} id="task" className="general-btn btn ico-lib-v1 version-ico"
                                                    target={"_blank"}>Versions
                                            </button>


                                            <button onClick={(e) => {
                                                this.taskAction();
                                            }} id="task" className="general-btn ico-lib coll-task-ico"
                                                    target={"_blank"}>Task
                                            </button>

                                            {getUrlSegment(1) !== 'task' &&
                                            <nav id="Printl" className="general-btn ico-lib print-ico"
                                                 onClick={() => window.location.href = ('/' + getUrlSegment(1) + '/print/' + btoa(_id) + '/' + btoa(CollectionId))}>Print </nav>
                                            }
                                            {
                                                attachmentfileds.length !== 0 &&
                                                <a onClick={this.downloadAlll} id="downloadall"
                                                   className="general-btn ico-lib ex-cel-ico" target={"_blank"}>Download
                                                    All
                                                </a>
                                            }
                                            <a href={this.props.previewSource === 'entity' ? SHARED_LOGIN.CISERVICE + `Collection/exportexcel/` + _id + `/1/` + CollectionId + `/0/` + ES_INDEX : SHARED_LOGIN.CISERVICE + `Collection/exportexcel/` + _id + `/0/` + CollectionId + `/0/` + ES_INDEX}
                                               id="exportExcel" className="general-btn ico-lib ex-cel-ico"
                                               target={"_blank"}>Export To Excel
                                            </a>
                                            <a target={`_blank`}
                                               href={SHARED_LOGIN.CISERVICE + `Collection/exportpdf/` + _id + `/` + CollectionId + `/` + ES_INDEX}
                                               id="exportpdf" className="general-btn ico-lib ex-pdf-ico">Export To
                                                PDF</a>

                                        </React.Fragment>
                                        } */}
                                    </div>
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-label="Close" onClick={() => this.hidePreviewForm()}/>
                                </div>
                            }
                        </div>
                        <div className={`modal-body without-footer grey-bcg ${getUrlSegment(1) === "entity" && this.state.hideLinkPanel?"entity-preview":""}`}>
                            <div className="slider-button-block">
                                {this.props.state.index > 0 && !this.state.showlinkdCol ?
                                    <button className="slider-button ico-lib slide-left"
                                            data-movement="backward-data" onClick={this.prevButton}>

                                    </button>
                                    :
                                    <span/>
                                }

                                {(this.props.state.index < (collectionlength-1) && collectionlength >1) && !this.state.showlinkdCol &&
                                <button className="slider-button ico-lib slide-right"
                                        data-movement="forward-data" onClick={this.nextButton}>

                                </button>
                                }
                            </div>

                            {formData.length > 0 ?
                                <React.Fragment>
                                    {getUrlSegment(1) === "entity" ?
                                        <div className="entity-preview-wrapper">
                                            {this.state.hideLinkPanel&&
                                            //this.state.template !== "template1" &&
                                            <LeftPanel
                                                linkedDetails={linkedDetails}
                                                getLinkedCollection={this.getLinkedCollection}
                                            />
                                            }
                                            {this.state.showlinkdCol &&
                                            <div className="entity-preview-center center-full credential-panel">
                                                <EntityHeader
                                                    formData={formData}
                                                    colEntName={this.props.colEntName.title}
                                                    CollectionName={this.state.CollectionName}
                                                    customtemplate={this.state.templateItem}
                                                    template={this.state.template}
                                                    linkedids={this.state.id}
                                                    meta={meta}
                                                    formVersions={formVersions}
                                                />
                                                <div className="remain-entity-info">
                                                    <DataTable
                                                        label={this.props.showFormModal}
                                                        collection={collectiondata.CollectionData}
                                                        handleSelectAll={this.handleSelectAll}
                                                        showPreviewFormModal={this.showPreviewFormModal}
                                                        state={this.state}
                                                        handleSelectRow={this.handleSelectRow}
                                                        showAssignedUsers={this.showAssignedUsers}
                                                        loadmoreCollectionData={this.loadmoreCollectionData}
                                                        showFormModal={this.showFormModal}
                                                        key={`result-table`}
                                                        permissionsSelected={[]}
                                                        createuser={""}
                                                        meta={meta}
                                                    />
                                                </div>
                                            </div>
                                            }
                                            {!this.state.showlinkdCol &&
                                            <>
                                                <Template3
                                                    formData={formData}
                                                    className={this.state.hideLinkPanel?`entity-preview-center`:'left-panel'}
                                                    collectionId={this.props.collectionId}
                                                    recordId={this.props.recordVersionid}
                                                    meta={meta}
                                                    formVersions={formVersions}
                                                    showFormPreviousVersion= {this.showFormPreviousVersion}
                                                    preview={this.preview}
                                                    previewCompModal={this.previewCompModal}
                                                />
                                                <RightPanel
                                                    meta={meta}
                                                    submissionType={submissionType}
                                                    className={this.state.hideLinkPanel?`entity-preview-right`:"right-panel"}
                                                    workflowEnabled = {wfDetails.length > 0}
                                                    currentStage = {currentStage}
                                                    submission_decision_statusid = {this.state.submission_decision_statusid}
                                                    stageInfo = {this.props.stagetaskInfoDet.StagetaskInfo}
                                                    handleInputchange = {this.handleInputchange}
                                                    handleDecisionInputchange = {this.handleDecisionInputchange}
                                                    handleInputDecisionchange = {this.handleInputDecisionchange}
                                                    submitWorkFlowAction={this.submitWorkFlowAction}
                                                    submitWorkFlowDecisionAction={this.submitWorkFlowDecisionAction}
                                                    stateArray = {this.state}
                                                    openAccordian = {this.openAccordian}
                                                    getAttachmentPath={this.getAttachmentPath}
                                                    currentStageNo = {wfDetails.length}
                                                    decisionmeta_data={this.state.decisionmeta_data}
                                                    getUploadedFileDecision = {this.getUploadedFileDecision}
                                                    changeNotes={this.props.changeNotes}
                                                    recordId={this.props.recordId}
                                                    value={this.props.value}
                                                    note={this.state.note}
                                                    chat={this.state.chat}
                                                    msgOpn={this.msgOpn}
                                                    msgCnt={this.msgCnt}
                                                    msgOpn={this.state.msgOpn}
                                                />
                                            </>
                                            }
                                        </div>
                                        :

                                        <div className="form-preview-wrapper">
                                            <div className="form-preview-holder preview-with-tab">
                                              <>
                                                {this.state.template === "template3" &&
                                                <Template4
                                                    formData={formData}
                                                    className={`left-panel`}
                                                    masterIds={this.state.masterIds}
                                                    customtemplate={this.props.customtemplate}
                                                    collectionId={this.props.collectionId}
                                                    recordId={this.props.recordVersionid}
                                                    meta={meta}
                                                    formVersions={formVersions}
                                                    showFormPreviousVersion= {this.showFormPreviousVersion}
                                                    preview={this.preview}
                                                    previewCompModal={this.previewCompModal}
                                                />
                                                }

                                                  {this.state.template === "temp3ver" &&
                                                  <Template3Vertical
                                                      formData={formData}
                                                      className={`left-panel`}
                                                      masterIds={this.state.masterIds}
                                                      customtemplate={this.props.customtemplate}
                                                      collectionId={this.props.collectionId}
                                                      recordId={this.props.recordVersionid}
                                                      meta={meta}
                                                      formVersions={formVersions}
                                                      showFormPreviousVersion= {this.showFormPreviousVersion}
                                                      preview={this.preview}
                                                      previewCompModal={this.previewCompModal}
                                                  />
                                                  }




                                                  {this.state.template === "template1" &&
                                                  <Template1
                                                      formData={formData}
                                                      className={`left-panel`}
                                                      collectionId={this.props.collectionId}
                                                      recordId={this.props.recordVersionid}
                                                      meta={meta}
                                                      formVersions={formVersions}
                                                      showFormPreviousVersion= {this.showFormPreviousVersion}
                                                      preview={this.preview}
                                                      previewCompModal={this.previewCompModal}
                                                  />
                                                  }

                                                  {this.state.template === "temp1ver" &&
                                                  <Template1Vertical
                                                      formData={formData}
                                                      className={`left-panel`}
                                                      collectionId={this.props.collectionId}
                                                      recordId={this.props.recordVersionid}
                                                      meta={meta}
                                                      formVersions={formVersions}
                                                      showFormPreviousVersion= {this.showFormPreviousVersion}
                                                      preview={this.preview}
                                                      previewCompModal={this.previewCompModal}
                                                  />
                                                  }


                                                  {this.state.template === "template2" &&
                                                      <>
                                                          <Template3
                                                              formData={formData}
                                                              className={`left-panel`}
                                                              collectionId={this.props.collectionId}
                                                              recordId={this.props.recordVersionid}
                                                              meta={meta}
                                                              formVersions={formVersions}
                                                              showFormPreviousVersion= {this.showFormPreviousVersion}
                                                              preview={this.preview}
                                                              previewCompModal={this.previewCompModal}
                                                          />
                                                      </>

                                                  }
                                                  {this.state.template === "template4" &&
                                                      <>
                                                          <Template5
                                                              formData={formData}
                                                              className={`left-panel`}
                                                              masterIds={this.state.masterIds}
                                                              customtemplate={this.props.customtemplate}
                                                              collectionId={this.props.collectionId}
                                                              recordId={this.props.recordVersionid}
                                                              meta={meta}
                                                              formVersions={formVersions}
                                                              showFormPreviousVersion= {this.showFormPreviousVersion}
                                                              preview={this.preview}
                                                              previewCompModal={this.previewCompModal}
                                                          />
                                                          {/*<RightPanel*/}
                                                          {/*    meta={meta}*/}
                                                          {/*    submissionType={submissionType}*/}
                                                          {/*    className={this.state.hideLinkPanel?`entity-preview-right`:"right-panel"}*/}
                                                          {/*    workflowEnabled = {wfDetails.length > 0}*/}
                                                          {/*    currentStage = {currentStage}*/}
                                                          {/*    submission_decision_statusid = {this.state.submission_decision_statusid}*/}
                                                          {/*    stageInfo = {this.props.stagetaskInfoDet.StagetaskInfo}*/}
                                                          {/*    handleInputchange = {this.handleInputchange}*/}
                                                          {/*    handleDecisionInputchange = {this.handleDecisionInputchange}*/}
                                                          {/*    handleInputDecisionchange = {this.handleInputDecisionchange}*/}
                                                          {/*    submitWorkFlowAction={this.submitWorkFlowAction}*/}
                                                          {/*    submitWorkFlowDecisionAction={this.submitWorkFlowDecisionAction}*/}
                                                          {/*    stateArray = {this.state}*/}
                                                          {/*    openAccordian = {this.openAccordian}*/}
                                                          {/*    getAttachmentPath={this.getAttachmentPath}*/}
                                                          {/*    currentStageNo = {wfDetails.length}*/}
                                                          {/*    decisionmeta_data={this.state.decisionmeta_data}*/}

                                                          {/*/>*/}
                                                      </>
                                                  }

                                                  {this.state.template === "template-5" &&
                                                  <Template6
                                                      formData={formData}
                                                      className={`left-panel`}
                                                      masterIds={this.state.masterIds}
                                                      customtemplate={this.props.customtemplate}
                                                      collectionId={this.props.collectionId}
                                                      recordId={this.props.recordVersionid}
                                                      meta={meta}
                                                      formVersions={formVersions}
                                                      showFormPreviousVersion= {this.showFormPreviousVersion}
                                                      preview={this.preview}
                                                      previewCompModal={this.previewCompModal}
                                                  />
                                                  }



                                                <RightPanel
                                                    meta={meta}
                                                    np={submissionType}
                                                    className={`right-panel`}
                                                    workflowEnabled = {wfDetails.length > 0}
                                                    currentStage = {currentStage}
                                                    submission_decision_statusid = {this.state.submission_decision_statusid}
                                                    stageInfo = {this.props.stagetaskInfoDet.StagetaskInfo}
                                                    handleInputchange = {this.handleInputchange}
                                                    handleDecisionInputchange = {this.handleDecisionInputchange}
                                                    handleInputDecisionchange = {this.handleInputDecisionchange}
                                                    handleDateDecisionchange = {this.handleDateDecisionchange}
                                                    submitWorkFlowAction={this.submitWorkFlowAction}
                                                    submitWorkFlowDecisionAction={this.submitWorkFlowDecisionAction}
                                                    stateArray = {this.state}
                                                    openAccordian = {this.openAccordian}
                                                    getAttachmentPath = {this.getAttachmentPath}
                                                    currentStageNo={wfDetails.length}
                                                    decisionmeta_data={this.state.decisionmeta_data}
                                                    getUploadedFileDecision={this.getUploadedFileDecision}
                                                    changeNotes={this.props.changeNotes}
                                                    recordId={this.props.recordId}
                                                    value={this.props.value}
                                                    note={this.state.note}
                                                    chat={this.state.chat}
                                                    closePanel={this.closePanel}
                                                    msgOpn={this.msgOpn}
                                                    msgCnt={this.msgCnt}
                                                    msgOpn={this.state.msgOpn}

                                                />
                                                </>
                                            </div>
                                        </div>

                                    }
                                </React.Fragment>
                                :
                                <span className="Loader-holder">

                                     <LoaderSvg/>
                                </span>
                            }


                        </div>
                    </div>
                </div>

            </div>
                {this.state.showTask &&
                <Modal title={'Create New Task'} Close ={this.closeAddNewTaskModal.bind(this)}>
                    <AddToDoTask
                        projectList={this.props.projectList}
                        tagList={this.props.tagList}
                        listtags={this.props.listtags}
                        modalOpen={this.closeAddNewTaskModal}
                        collectionRecord={collectionRecord}
                        removeSelectedRecord={this.props.removeSelectedRecord}
                        taggedProject = {this.props.taggedProject}
                        projectId = {this.props.projectId}
                        createdfrom={this.props.createdfrom}
                    />

                </Modal>
                }
                {this.state.previewStatus &&
                <SimpleModal
                    Close={this.closePreview}
                >
                    <div className="preview-area chat-panel">
                        {isImage(this.state.url) &&
                        <img id="attachPrv" src={this.state.url}
                        />
                        }
                        {/*{//console.log(this.state.item)}*/}
                        {isVideo(this.state.url) &&

                        <VideoPlayer width="320" height="240"  fileName={this.state.name}   src={this.state.url}/>
                        }

                        {isAudio(this.state.url) &&
                        <audio  width="320" height="240" controls
                        >
                            <source src={this.state.url}
                                    type="audio/mpeg">
                            </source>
                        </audio >
                        }
                        {this.state.item !== undefined && this.state.item.res_type === '6' &&
                        <iframe width="500" height="250" src={this.state.item.res_img} alt="Thumb-img"/>
                        }
                        {this.state.download === "yes"  && !isVideo(this.state.url) &&
                        <div className="preview-options">
                            <ul className="resources-filters-icons">
                                <li><a className="rs-tab-btn ico-lib-v1 rc-download single-option"
                                       onClick={() => forceDownload(this.state.url, this.state.name)}> </a>
                                </li>
                            </ul>
                        </div>
                        }
                    </div>
                </SimpleModal>
                }
                {this.state.previewCompModalStatus &&
                <div className="modal fade bsy-modal x-large fl-pv show" id="toModal" tabIndex="-1" role="dialog"
                     aria-labelledby="mngModal" aria-hidden="true" style={{display:'block'}} >
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Preview</h5>
                                <button className="ico-lib-list modal-close" data-dismiss="modal"
                                        aria-label="Close" onClick={this.closePreviewCompModal}></button>
                            </div>
                            <div className="modal-body">
                                <div class="preview-component">
                                    <div class="preview-embed">
                                        <div className="preview-panel" style={{width:this.state.previewAttributes.previewidth !== '' ? this.state.previewAttributes.previewidth + 'px' : '100%', height:this.state.previewAttributes.previewheight !== '' ? this.state.previewAttributes.previewheight + 'px': '350px'}}>
                                            {this.state.previewAttributes.previewfileurl !== ''  &&
                                            <>
                                                {getFileExtension(this.state.previewAttributes.previewfileurl) === 'doc' || getFileExtension(this.state.previewAttributes.previewfileurl) === 'xls' ?
                                                    <iframe src={'https://docs.google.com/viewer?url='+ this.state.previewAttributes.previewfileurl + '&embedded=true'} width="100%" height="100%" />
                                                    :
                                                    (getFileExtension(this.state.previewAttributes.previewfileurl) === 'jpg' || getFileExtension(this.state.previewAttributes.previewfileurl) === 'png' || getFileExtension(this.state.previewAttributes.previewfileurl) === 'jpeg') ?
                                                        <img src={this.state.previewAttributes.previewfileurl}/>
                                                        :
                                                        <FileViewers
                                                            url={this.state.previewAttributes.previewfileurl}
                                                            type={getFileExtension(this.state.previewAttributes.previewfileurl)}
                                                        />
                                                }
                                            </>
                                            }
                                        </div>

                                        {this.state.previewAttributes.previewbuttons.length !== 0 && this.state.previewAttributes.previewbuttons.map((list, index) =>
                                            <div className="field-button-block">
                                                {list.previewtext !== '' &&
                                                <button className="general-btn" style={{
                                                    color: this.state.previewAttributes.previewtextcolor,
                                                    backgroundColor: this.state.previewAttributes.previewbgcolor
                                                }}>{list.previewtext}</button>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.showVersionModal &&
                <SimpleModal
                    Close={()=>this.setState({showVersionModal:false})}
                    title={`Previous versions`}
                    haveFooter={1}
                    extraClass="version-modal"
                    cancelAction={()=>this.setState({showVersionModal:false})}
                >
                    {this.props.loader.loaderType === 'track-version' && this.props.loader.status === true &&
                    <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                    }
                    {this.props.previousVersion.previousValues && this.props.previousVersion.previousValues.length > 0 ?
                    <div className="each-value-set">
                        <table className="table bsy-table result-table ">
                            <tbody>
                            <tr>
                                <th>Value</th>
                                <th>User</th>
                                <th>Created Date</th>
                            </tr>
                            {this.props.previousVersion.previousValues.map((list, index) =>
                                <tr key={index}>
                                    <td>
                                        {(list.type === 'signature') ?
                                            list.value.map((item, indx) =>
                                                <span key={indx}><img src={item.value} alt={`signature`} /></span>
                                            )
                                            :
                                            (list.type === "attachment")?
                                                list.value.map((item, indx) =>
                                                    <span key={indx}><img src={FRMSIMG_PATH + item.uniqvalue} alt={`attachment`} /></span>
                                                )
                                                :
                                                (list.type === "photocapture")?
                                                    list.value.map((item, indx) =>
                                                        <span key={indx}><img src={FRMSIMG_PATH + item.value} alt={`photocapture`} /></span>
                                                    )
                                                    :
                                                    (list.type === "editor")?
                                                        list.value.map((item, indx) =>
                                                            <span key={indx} dangerouslySetInnerHTML={{__html: item.value}} ></span>
                                                        )
                                                        :
                                                        (list.type === "imageurl")?
                                                            list.value.map((item, indx) =>
                                                                <span key={indx}><img src={item.value} alt={`imageurl`} /></span>
                                                            )
                                                            :
                                                            (list.type === "checkbox")?
                                                                list.value.map((item, indx) =>
                                                                    <span key={indx}>
                                                                        {((list.value.length-1) === indx) ? item.value :item.value+",  "}
                                                                    </span>
                                                                )
                                                                :
                                                                    list.value.map((item, indx) =>
                                                                        <span key={indx}>{item.value}</span>
                                                                    )
                                        }
                                    </td>
                                    <td>
                                        {this.props.previousVersion.userInfodata && this.props.previousVersion.userInfodata !== '' &&
                                        <span>{this.props.previousVersion.userInfodata[list.creator]}</span>
                                        }
                                    </td>
                                    <td align="right">{DateFormatter(list.createdat, true, true)}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>

                    </div>
                        :

                        <div className="center-placeholder"><h5>No Version Found</h5></div>

                    }
                </SimpleModal>
                }

                {this.state.showFieldVer &&
                <SimpleModal
                    Close={() => this.setState({showFieldVer: false})}
                    title={`Record Versions`}
                    haveFooter={false}
                    extraClass="record-version"
                    cancelAction={() => this.setState({showFieldVer: false})}
                >
                    {this.props.listFieldVer.loader  &&
                    <span className="Loader-holder">
                            <LoaderSvg/>
                        </span>
                    }

                    <div className="version-panel-container">
                        <div className="left-content-scroll">
                            {this.props.listFieldVer.data.slice(0,(this.props.listFieldVer.data.length-1)).map((list,index) =>
                            <p className={this.state.recInd===index?"active":""} onClick={()=>this.recIndxchange(index) }>
                                version {parseInt(index) + 1}</p>
                            )}
                        </div>
                        <div className="right-content-scroll">
                            {this.props.listFieldVer.data.length >1 && this.props.listFieldVer.data[this.state.recInd].collectedform.map((item)=>
                                <div className="right-panel-white-box">
                                    <div className="form-single-value-box ">
                                        <p>{item.label ===""?item.fieldname:item.label}
                                        <div className="label-title-block"> </div>
                                        </p>
                                        <h5>
                                        <>
                                            {item.value.map((list,index)=>
                                            <>{item.type !=="signature" && item.type !== "attachment" && item.type !==  "imageurl" ?
                                                <span key={`data`+index}>{list.value} </span>:
                                                (isImage(list.value) || item.type ==="signature" || item.type ===  "imageurl")? <img key={`data`+index} className="custom-image" src={(item.type ==="signature" || item.type === "imageurl" )?list.value:FRMSIMG_PATH+list.uniqvalue}/>: <p key={`data`+index}>{list.value} </p>
                                            }
                                            </>
                                            )}
                                        </>
                                        </h5>
                                    </div>
                                </div>
                            )}
                            {this.props.listFieldVer.data.length === 1 && !this.props.listFieldVer.loader &&
                            <span className="Loader-holder" style={{
                                position: "absolute",
                              }}>
                              <h5> No Versions Available</h5>
                            </span>

                            }


                        </div>
                    </div>

                </SimpleModal>
                }

                {this.state.proceedModal &&
                <AlertModal id="proceed"
                            title="Are you sure to proceed?"
                            OK_button_text="Yes, Proceed"
                            OK_action={() => this.submitWorkFlow()}
                            showModal = {this.state.proceedModal}
                            CANCEL_action={() => this.hideProceedmodal()}
                />
                }
                {this.state.proceedDecisionModal &&
                <AlertModal id="proceed"
                            title="Are you sure to proceed?"
                            OK_button_text="Yes, Proceed"
                            OK_action={() => this.submitWorkFlowDecision()}
                            showModal = {this.state.proceedDecisionModal}
                            CANCEL_action={() => this.hideProceedDecisionmodal()}
                />
                }
                {this.state.showDuplicateConfirm &&
                <AlertModal id="take-pool"
                            title="Are you sure you want to duplicate the record?"
                            OK_button_text="Yes"
                            OK_button_style="btn-danger"
                            OK_action={this.handleDuplicate}
                            showModal = {this.state.showDuplicateConfirm}
                            CANCEL_action={this.showDuplicateConfirm}
                />
                }


            </>

        );


    }
}
const mapStateToProps = state => ({
    collectionData: state.CollectionsReducer.listCollectionDetaildata,
    getFormdetails : state.CollectionsReducer.getFormData,
    LinkedCollectionData : state.EntityReducer.GetLinkedData,
    showFormModal:state.CollectionsReducer.showFormModal,
    entityData: state.EntityReducer.listCollectionDetaildata,
    HistoryPushData:state.TaskReducer.HistoryPushData,
    previousVersion : state.CollectionsReducer.previousVersion,
    loader:state.CommonReducer.loader,
    listFieldVer:state.CeShareView.listFieldVer,
    stagetaskInfoDet : state.workflowReducer.StageTaskInfo,
    getRecordWorkflow : state.workflowReducer.getRecordWorkflow,
    recordDuplicate:state.CeShareView.recordDuplicate,

});

export default  withRouter(connect(mapStateToProps, {
    downloadAlll : entityActions.downloadEntity,
    showform : collectionActions.showform,
    collectionDetails:collectionActions.collectionDetail,
    getFormData : collectionActions.getFormData,
    entityCollection:entityActions.entityCollections,
    LinkedId:entityActions.LinkedId,
    trackVersion : collectionActions.trackVersion,
    fieldVersion:CeShareViewActions.fieldVersion,
    getstagetaskinfo : WorkFlowActions.StageTaskInfoDet,
    saveWorkflowMetaForm : WorkFlowActions.saveWorkflowMetaForm,
    processAssignedNextStage : WorkFlowActions.processAssignedNextStage,
    closeRecordWorkflow : WorkFlowActions.closeRecordWorkflow,
    collectionRecordDuplicate:CeShareViewActions.collectionRecordDuplicate,

})(ShowFormPreview));

function RightPanel(Props) {

    let list = {poolenabled: false, wf_status_info : Props.currentStage.wf_status_info, decisionmeta_data : Props.decisionmeta_data};
    let collectionData = [];
    let step =1;
    let taskstatus = Props.stageInfo.status;


    let submissiontype = '';
    let stateArray = Props.stateArray;
    let meta_data = [];
    let indAssignee = [{share : 'no', status : 'new'}];
    let currentStage = Props.currentStage;
    if(currentStage ) {
        meta_data = currentStage.meta_form;
        submissiontype = currentStage.submissiontype;
        indAssignee[0].status = currentStage.status;
    }

    let previousStages = stateArray.previousStages;

    let submission_decision_statusid = Props.submission_decision_statusid;

    let stageName = Props.currentStage ? Props.currentStage.stagename : '';


    return (
        <>

        {/*<div>

        </div>*/}
        <div className={Props.className}>
            {Props.workflowEnabled &&
                <div className="preview-tab">

                <Tabs defaultActiveKey="current" transition={false}>
                    <Tab eventKey="current" title="Current Stage">

                    <Scrollbars autoHide autoHideTimeout={1000}>
                        <div className="tab-pane-inner">
                            <div className="stage-block">
                                <h5>Stage {Props.currentStageNo} : {stageName}</h5>
                            </div>

                        {meta_data.length > 0 &&
                        <FormDataBlock
                            meta_data={meta_data}
                            handleInputchange={Props.handleInputchange}
                            state={stateArray}
                            getUploadedFile={Props.getUploadedFile}
                            removeSelectedAttachment={Props.removeSelectedAttachment}
                            submitWorkFlowAction={Props.submitWorkFlowAction}
                            handleDateChange={Props.handleDateChange}
                            submissiontype={submissiontype}
                            indAssignee={indAssignee}
                            list={list}
                            quitPoolTaskConfirm={Props.quitPoolTaskConfirm}
                            step={step++}
                            assigneeTaskStatus={Props.assigneeTaskStatus}
                            closeButtonText = {'Close'}
                        />
                        }

                        {submissiontype && submissiontype === "withdecision" &&
                        <DecisionBlock
                            taskstatus={taskstatus}
                            decisionmeta_data={Props.decisionmeta_data}
                            meta_data={meta_data}
                            handleDecisionInputchange={Props.handleDecisionInputchange}
                            formdata={stateArray}
                            state = {stateArray}
                            submitWorkFlowDecisionAction={Props.submitWorkFlowDecisionAction}
                            getUploadedFileDecision={Props.getUploadedFileDecision}
                            removeSelectedAttachment={Props.removeSelectedAttachment}
                            handleInputDecisionchange={Props.handleInputDecisionchange}
                            handleDateDecisionchange={Props.handleDateDecisionchange}
                            submitWorkFlowDecision={Props.submitWorkFlowDecision}
                            renderDecisionField={Props.renderDecisionField}
                            indAssignee={indAssignee}
                            decisionmeta_data_result={Props.decisionmeta_data_result}
                            parent={list}
                            submissiontype={submissiontype}
                            decisionSet={Props.decisionSet}
                            quitPoolTaskConfirm={Props.quitPoolTaskConfirm}
                            step={step++}
                            submission_decision_statusid={submission_decision_statusid}
                            assigneeTaskStatus={Props.assigneeTaskStatus}
                            closeButtonText = {'Close'}
                            handleInputchange={Props.handleInputchange}
                        />
                        }

                        </div>
                        </Scrollbars>
                    </Tab>
                    {previousStages.length > 0 &&
                    <Tab eventKey="previous" title="Previous Stage">
                        <div className="user-list-block">
                            <PreviousStagesPanel
                                previoustasksData={previousStages}
                                openAccordian={Props.openAccordian}
                                getAttachmentPath={Props.getAttachmentPath}
                                state={stateArray}
                            />
                        </div>
                    </Tab>
                    }
                </Tabs>
            </div>

            }





            <ul className="top-20">
                <li>
                    <div className="right-panel-group-each">
                        <h5>CREATED BY</h5>
                        <p>{Props.meta.creator}</p>
                    </div>
                    {Props.meta.createdate !== "" &&
                    <div className="right-panel-group-each">
                        <h5>CREATED ON</h5>
                        <p>{DateFormatter(Props.meta.createdate, true, true)}</p>
                    </div>
                    }
                </li>


                {Props.note &&

                <li>
                    <div className="side-bar-panel collection-clik-panel grey-bg-panel list-view-panel">
                        <div id="progress-holder" className="upload-pr-wrap"/>
                        <div className="float-panel-header"><h5 className="subheader">Note</h5>
                            <button className="ico-lib fly-close" onClick={()=>Props.closePanel("note")}/>
                        </div>
                        <div className="discuss-inner collection-chat-container " tabIndex="2" id="disscussion">

                            <div className="collection-meta-note">
                                <AddNotes
                                    recordId={Props.recordId.toString()}
                                    value={Props.value}
                                    changeNotes={Props.changeNotes}
                                />
                            </div>


                        </div>
                    </div>

                </li>
                }
                {Props.chat &&
                <li>
                    <div className="side-bar-panel collection-clik-panel grey-bg-panel list-view-panel">
                        <div id="progress-holder" className="upload-pr-wrap"/>
                        <div className="float-panel-header"><h5 className="subheader">Chat</h5>
                            <button className="ico-lib fly-close" onClick={()=>Props.closePanel("chat")}/>
                        </div>
                        <div className="discuss-inner collection-chat-container " tabIndex="2" id="disscussion">

                            <div className="collection-meta-note">

                <ProjectChat
                    taskid={Props.recordId.toString()}
                    inviteUsers={false}
                    type={"collection"}
                    msgOpn={Props.closePanel}
                    msgCnt={Props.msgCnt}
                    openSta={Props.chat}
                    sound={true}
                    toaster={true}
                    //  assigneesData={assigneesData}
                />
                            </div>


                        </div>
                    </div>

                </li>
                }



                {((Props.meta.updateby !== "") || (Props.meta.updatedate !== "")) &&
                <li>
                    {Props.meta.updateby !== "" &&
                    <div className="right-panel-group-each">
                        <h5>LAST EDITED BY</h5>
                        <p>{Props.meta.updateby}</p>
                    </div>
                    }
                    {Props.meta.updatedate !== "" &&
                    <div className="right-panel-group-each">
                        <h5>LAST EDITED ON</h5>
                        <p>{DateFormatter(Props.meta.updatedate, true, true)}</p>
                    </div>
                    }

                </li>
                }

                {Props.meta.workflows && Props.meta.workflows.length !== 0 &&
                <li>
                    <div className="right-panel-group-each">
                        <h5>LINKED WORKFLOWS</h5>
                        <ol>
                            {Props.meta.workflows.map((list) =>
                                <li>{list}</li>
                            )}

                        </ol>
                    </div>
                </li>
                }
                {Props.submissionType === 'Draft' &&
                <li>
                    <div className="right-panel-group-each">
                        <h5>SUBMISSION TYPE</h5>
                        <p>Draft</p>
                    </div>
                </li>
                }
            </ul>
        </div>
        </>
    )
}
function LeftPanel(Props) {
    return (
        <div className="entity-preview-left active">
            <button className="mobile-only-button"></button>
            <ul className="linked-collection-record">

                {Props.linkedDetails.length === 0 ?
                    <li>
                        <nav className="active">
                            <h5>There are no collections linked to this entity.</h5>
                        </nav>
                    </li>
                    :
                    <>
                        {Props.linkedDetails.map((list, index) =>
                            <li>
                                <nav className="active">
                                    <h5 onClick={()=>Props.getLinkedCollection(list._id,list._source.title)}>{atob(list._source.title)}</h5>
                                </nav>
                            </li>
                        )}
                    </>

                }

            </ul>
        </div>
    )
}
function EntityHeader(Props) {
    //localStorage.setItem('dashboard', JSON.stringify([]));
    const [visibility, setVisibility] = useState(false);
    return (
        <>
            <div className="form-preview-header">
                <h5>{atob(Props.CollectionName)}</h5>
                <button className="other-credential" onClick={() => setVisibility(true)}> </button>
            </div>
            {visibility && Props.template !== "template1" &&
            <div className="credential-side-panel fly-in">
                <div className="credential-panel-header">
                    <h5>{Props.colEntName}</h5>
                    <button className="credential-panel-close" onClick={() => setVisibility(false)}></button>
                </div>
                <div className="credential-panel-body">
                    <div className="main-entity-info">
                        {Props.formData.slice(0, 4).map((list, index) =>
                            <div className="right-panel-white-box" key={`right-panel-white-box`+index}>
                                <div className="form-single-value-box">
                                    <p>{list.label}</p>
                                    <h5> {list.values.length !== 0 && list.values[0].value}</h5>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
            }

            {visibility && Props.template === "template1" &&
            <div className="credential-side-panel fly-in">
                <div className="credential-panel-header">
                    <h5>{Props.colEntName}</h5>
                    <button className="credential-panel-close" onClick={() => setVisibility(false)}></button>
                </div>
                <EntityPreview
                    entityData={Props.formData}
                    colEntName={Props.colEntName}
                    customtemplate={Props.customtemplate}
                />
            </div>
            }




        </>
    )
}



class Template1 extends React.Component{
    constructor(props){
        super(props)
    }

    showFieldSetValue(field, index) {

        let children = field.values ?field.values:[];

        return <>
            <tr key={index}><td colSpan="2" style={{color: "#3FA9F5"}}>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</td></tr>
            {children.length > 0 &&
            this.renderChildFields(children, index)
            }
        </>;
    }
    showFieldValue(field, index, childIndex) {
        let collectionId = this.props.collectionId ? this.props.collectionId : '';
        let recordId = this.props.recordId ? this.props.recordId : '';
        let fieldId = field.masterid ? field.masterid : '';
        let formMeta = this.props.meta && this.props.meta.version ? this.props.meta.version : 0;
        let formVersions = this.props.formVersions && this.props.formVersions.length > 0 && this.props.formVersions.includes(fieldId) ? true : false;

        if(field.type === "otp" || field.type === "payment") {
        return false
        }
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
            //////////console.log(field.value[childIndex]);


        }

        return <tr key={index}>
            <th>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</th>
            {field.type === 'formheading' ?
                <td>&nbsp;</td>
                :
                field.type === 'displaytext' ?
                    <td dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></td>
                    :
                field.type === 'attachment' ?
                        <td>
                            <ul className='attachment-list' >
                            {field.values &&
                        field.values.map((value, itemIndex) =>
                            this.renderFieldType(value, field,field.values.length,itemIndex))
                        }
                            </ul>
                        </td>
                    :
                <td> {field.values &&
                field.values.map((value, itemIndex) =>
                    this.renderFieldType(value, field,field.values.length,itemIndex))
                }
                    {/* field.linked === 'Yes' && field.value && field.value.length > 0 &&
            this.renderFieldType(field.value[childIndex], field.type)
            */}
                    {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                        <>
                    <button className={'ico-lib-v1 prev-version'} onClick={(e)=>this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                        <div className={`prev-version-highlight`}></div>
                        </>
                    }
                </td>

            }

        </tr>;
    }
    renderFieldType = (values, field,length,index) => {
        let fieldType=field.type
        //console.log(field)
        let fieldValue = '';

        if(fieldType === 'attachment') {
            let uniqname = (values && values.uniqvalue) ? values.uniqvalue : '';
            let filename = values ? values.value : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileType = getFilePath(uniqname);
            var albumName = 'documents';
            if(filename !== ''){
                if (CustomFunctions.isImage(filename)) {
                    albumName = 'images'
                } else if (CustomFunctions.isVideo(filename)) {
                    albumName = 'videos'
                } else if (CustomFunctions.isAudio(filename)) {
                    albumName = 'audios'
                } else {
                    albumName = 'documents'
                }
            }

            if(uniqname !== '') {
                fieldValue =
                    <>
                        <li>
                            {fileType === 'image' ?
                                <>
                                    <a href="javascript:void(0);" className="attach-click"
                                       onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                       data-toggle="tooltip"
                                       data-placement="bottom" data-original-title="dummy">
                                        <img className="custom-image" src={FRMSIMG_PATH + uniqname}/>
                                    </a>
                                </>
                                :
                                fileType === 'video' || fileType === 'audio' ?
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, filename)}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                            }
                        </li>
                    </>
            }
        }
        else if(fieldType === 'videocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileurl = contentPath+uniqname;

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    {isVideo(fileurl) &&

                    <VideoPlayer width="320" height="240"  fileName={uniqname}   src={FRMSVID_PATH+uniqname}/>
                    }
                </div>
            }

        }
        else if(fieldType === 'editor') {
            fieldValue = <div dangerouslySetInnerHTML={{__html: values.value}}></div>
        }
            else if(fieldType === 'number' || fieldType === "computedfield" ) {
                fieldValue = <div>{values.value} </div>
        }
        else if(fieldType === 'signature') {
            fieldValue = <img className="custom-image" src={values.value}/>
        }else if(fieldType === 'rating') {
            let rateValarray = [];
            let rateVal = values.value;
                rateVal = rateVal && rateVal !== '' ? rateVal : 0;
                let count = 5;
                for(var i=0; i<count; i++){
                    rateValarray.push(i)
                }
            fieldValue = <ul className={`rate-star ${field.ratingtype === 'bulb' ?  "bulb" : ""}`}>
                {rateValarray.map((opt, index) =>
                    <li className={`${index < rateVal ?  "active" : ""}`} key={index}></li>
                )}
            </ul>
        }
        else if(fieldType === 'preview') {
            let val = values.value;
            fieldValue = <div className="preview-component">
                {field.previewurldisplay === 'popup' ?
                    <div className="preview-popup">
                        <div className="field-button-block">
                            <button className="general-btn"
                                    onClick={(e) => this.props.previewCompModal(field)}>{field.fieldLabel ? field.fieldLabel : field.label}</button>
                        </div>
                    </div>
                    :
                    <div className="preview-embed">
                        <div className="preview-panel" style={{width:field.previewidth !== '' ? field.previewidth + 'px' : '100%', height:field.previewheight !== '' ? field.previewheight + 'px': '350px'}}>
                            {field.previewfileurl !== '' &&
                            <>
                                {getFileExtension(field.previewfileurl) === 'doc' || getFileExtension(field.previewfileurl) === 'xls' ?
                                    <iframe src={'https://docs.google.com/viewer?url='+ field.previewfileurl + '&embedded=true'} width="100%" height="100%" />
                                    :
                                    (getFileExtension(field.previewfileurl) === 'jpg' || getFileExtension(field.previewfileurl) === 'png' || getFileExtension(field.previewfileurl) === 'jpeg') ?
                                        <img src={field.previewfileurl}/>
                                        :
                                        <FileViewers
                                            url={field.previewfileurl}
                                            type={getFileExtension(field.previewfileurl)}
                                            height={field.previewheight}
                                        />
                                }
                            </>
                            }
                        </div>
                        <div className="field-button-block">
                            {field.previewbuttons.length !== 0 && field.previewbuttons.map((list, index) =>
                                <>
                                    {list.previewtext !== '' &&
                                    <button className="general-btn" style={{
                                        color: list.previewtextcolor,
                                        backgroundColor: list.previewbgcolor
                                    }}>{list.previewtext}</button>
                                    }
                                </>
                            )}
                        </div>

                    </div>
                }

                {field.previewagreerichtext !== '' &&
                <div className="agreement">
                    <div className="custom-check">

                        {field.previewenablecheckbox === 'Yes' &&
                        <div className="custom-checkbox">
                            <input type="checkbox" name={field.hasOwnProperty('fieldname')?field.fieldname:field.label} value={val} checked={val === "yes"}/>
                            <label></label>
                        </div>
                        }
                        <div className="custom-checkbox-text">
                            <p dangerouslySetInnerHTML={{__html: atob(field.previewagreerichtext)}} className=""></p>
                        </div>

                    </div>
                </div>
                }
            </div>
        }
        else if(fieldType === 'resources') {
            let resourceVal = values.value !== undefined && values.value !== '' ? JSON.parse(values.value) : [];
            fieldValue = <div class="form-resource-preview preview-mode">
                {/* <h5>Selected Resources</h5> */}
                <div className="form-resource-block">
                    {resourceVal.length > 0 && resourceVal.map((list, index) =>
                        <ResourcePreviewAttachments
                            item = {list}
                            index={index}
                            // preview={this.preview}
                        />


                    )}
                </div>
            </div>
        }
        else if (fieldType === 'datepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                moment.utc(values.value).local().format('DD-MM-YYYY hh:mm')
                :
                field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                    moment(values.value).format('DD-MM-YYYY hh:mm')
                    :
                    values.value !== '' ?
                        moment(values.value).format('DD-MM-YYYY'):
                        values.value;
        }
        else if (fieldType === 'timepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && values.value !== '' ? moment.utc("2020-12-17 "+ values.value).local().format('HH:mm:ss') : values.value;
        }
        else if (fieldType === 'checkbox' || fieldType === 'switch') {
            if(field.hasOwnProperty(`checkboxoption`) && field.checkboxoption ==="imageoption" ){
                fieldValue=<div className={`option-score-container`}>{((length-1) === index) ?<div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span> </div> : <div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span></div> }</div>

            }
            else{
                fieldValue = ((length-1) === index) ? <>{values.value}{values.weightage !=="" && values.weightage !==undefined?<span className={`weightage`}>Score : {values.weightage}</span>:""}</> : (values.value + (values.weightage !==undefined && values.weightage !==""?<span className={`weightage`}>Score : {values.weightage}</span> :"") )+"," ;

            }
        }

        else {
            fieldValue = values.value;
        }

        return fieldValue;

    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }
        return filePath;
    }
    renderChildFields(children, index){
        /*////////console.log(this.state.formData)
        let fieldSetVal = children.fil((item) => item.values ? item.values.length:0);
        let fieldSetCount = Math.max.apply(null, fieldSetVal) // 4
        ////////console.log(children);
        ////////console.log(fieldSetVal);
        ////////console.log(fieldSetCount);*/
        var rows = [];
        //for (var i = 0; i < fieldSetCount; i++) {
        for(var j=0; j< children.length; j++) {
            rows.push(this.renderFields(children[j], j, 0 ));
        }
        //}
        return rows;

    }
    renderFields(field, index, childIndex) {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index):this.showFieldValue(field, index, childIndex);
    }
    render(){
        return(
            <div className={this.props.className}>
             <div className="right-panel-white-box">
                <table className="table form-preview-table">
                    <tbody>
                    {this.props.formData.filter((item) => item.linked== 'No').map((data,index) =>
                        <React.Fragment>
                            {this.renderFields(data, index)}
                        </React.Fragment>
                    )}

                    </tbody>
                </table>
            </div>
            </div>
        )
    }

}


class Template1Vertical extends React.Component{
    constructor(props){
        super(props)
    }

    showFieldSetValue(field, index) {

        let children = field.values ?field.values:[];

        return <>
            <tr key={index}><td colSpan="2" style={{color: "#3FA9F5"}} className={`template-heading`}>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</td></tr>
            {children.length > 0 &&
            this.renderChildFields(children, index)
            }
        </>;
    }
    showFieldValue(field, index, childIndex) {
        let collectionId = this.props.collectionId ? this.props.collectionId : '';
        let recordId = this.props.recordId ? this.props.recordId : '';
        let fieldId = field.masterid ? field.masterid : '';
        let formMeta = this.props.meta && this.props.meta.version ? this.props.meta.version : 0;
        let formVersions = this.props.formVersions && this.props.formVersions.length > 0 && this.props.formVersions.includes(fieldId) ? true : false;

        if(field.type === "otp" || field.type === "payment") {
            return false
        }
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
            //////////console.log(field.value[childIndex]);


        }

        return <tr key={index}>
            <th>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</th>
            {field.type === 'formheading' ?
                <td>&nbsp;</td>
                :
                field.type === 'displaytext' ?
                    <td dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></td>
                    :
                    field.type === 'attachment' ?
                        <td>
                            <ul className='attachment-list' >
                                {field.values &&
                                field.values.map((value, itemIndex) =>
                                    this.renderFieldType(value, field,field.values.length,itemIndex))
                                }
                            </ul>
                        </td>
                        :
                        <td> {field.values &&
                        field.values.map((value, itemIndex) =>
                            this.renderFieldType(value, field,field.values.length,itemIndex))
                        }
                            {/* field.linked === 'Yes' && field.value && field.value.length > 0 &&
            this.renderFieldType(field.value[childIndex], field.type)
            */}
                            {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                            <>
                                <button className={'ico-lib-v1 prev-version'} onClick={(e)=>this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                                <div className={`prev-version-highlight`}></div>
                            </>
                            }
                        </td>

            }

        </tr>;
    }
    renderFieldType = (values, field,length,index) => {
        let fieldType=field.type
        console.log(field)
        let fieldValue = '';

        if(fieldType === 'attachment') {
            let uniqname = (values && values.uniqvalue) ? values.uniqvalue : '';
            let filename = values ? values.value : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileType = getFilePath(uniqname);
            var albumName = 'documents';
            if(filename !== ''){
                if (CustomFunctions.isImage(filename)) {
                    albumName = 'images'
                } else if (CustomFunctions.isVideo(filename)) {
                    albumName = 'videos'
                } else if (CustomFunctions.isAudio(filename)) {
                    albumName = 'audios'
                } else {
                    albumName = 'documents'
                }
            }

            if(uniqname !== '') {
                fieldValue =
                    <>
                        <li>
                            {fileType === 'image' ?
                                <>
                                    <a href="javascript:void(0);" className="attach-click"
                                       onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                       data-toggle="tooltip"
                                       data-placement="bottom" data-original-title="dummy">
                                        <img className="custom-image" src={FRMSIMG_PATH + uniqname}/>
                                    </a>
                                </>
                                :
                                fileType === 'video' || fileType === 'audio' ?
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, filename)}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                            }
                        </li>
                    </>
            }
        }
        else if(fieldType === 'videocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileurl = contentPath+uniqname;

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    {isVideo(fileurl) &&

                    <VideoPlayer width="320" height="240"  fileName={uniqname}   src={FRMSVID_PATH+uniqname}/>
                    }
                </div>
            }

        }
        else if(fieldType === 'videocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileurl = contentPath+uniqname;

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    {isVideo(fileurl) &&

                    <VideoPlayer width="320" height="240"  fileName={uniqname}   src={FRMSVID_PATH+uniqname}/>
                    }
                </div>
            }

        }
        else if(fieldType === 'editor') {
            fieldValue = <div dangerouslySetInnerHTML={{__html: values.value}}></div>
        }
        else if(fieldType === 'number' || fieldType === "computedfield" ) {
            fieldValue = <div>{values.value} </div>
        }
        else if(fieldType === 'signature') {
            fieldValue = <img className="custom-image" src={values.value}/>
        }else if(fieldType === 'rating') {
            let rateValarray = [];
            let rateVal = values.value;
            rateVal = rateVal && rateVal !== '' ? rateVal : 0;
            let count = 5;
            for(var i=0; i<count; i++){
                rateValarray.push(i)
            }
            fieldValue = <ul className={`rate-star ${field.ratingtype === 'bulb' ?  "bulb" : ""}`}>
                {rateValarray.map((opt, index) =>
                    <li className={`${index < rateVal ?  "active" : ""}`} key={index}></li>
                )}
            </ul>
        }
        else if(fieldType === 'preview') {
            let val = values.value;
            fieldValue = <div className="preview-component">
                {field.previewurldisplay === 'popup' ?
                    <div className="preview-popup">
                        <div className="field-button-block">
                            <button className="general-btn"
                                    onClick={(e) => this.props.previewCompModal(field)}>{field.fieldLabel ? field.fieldLabel : field.label}</button>
                        </div>
                    </div>
                    :
                    <div className="preview-embed">
                        <div className="preview-panel" style={{width:field.previewidth !== '' ? field.previewidth + 'px' : '100%', height:field.previewheight !== '' ? field.previewheight + 'px': '350px'}}>
                            {field.previewfileurl !== '' &&
                            <>
                                {getFileExtension(field.previewfileurl) === 'doc' || getFileExtension(field.previewfileurl) === 'xls' ?
                                    <iframe src={'https://docs.google.com/viewer?url='+ field.previewfileurl + '&embedded=true'} width="100%" height="100%" />
                                    :
                                    (getFileExtension(field.previewfileurl) === 'jpg' || getFileExtension(field.previewfileurl) === 'png' || getFileExtension(field.previewfileurl) === 'jpeg') ?
                                        <img src={field.previewfileurl}/>
                                        :
                                        <FileViewers
                                            url={field.previewfileurl}
                                            type={getFileExtension(field.previewfileurl)}
                                            height={field.previewheight}
                                        />
                                }
                            </>
                            }
                        </div>
                        <div className="field-button-block">
                            {field.previewbuttons.length !== 0 && field.previewbuttons.map((list, index) =>
                                <>
                                    {list.previewtext !== '' &&
                                    <button className="general-btn" style={{
                                        color: list.previewtextcolor,
                                        backgroundColor: list.previewbgcolor
                                    }}>{list.previewtext}</button>
                                    }
                                </>
                            )}
                        </div>

                    </div>
                }

                {field.previewagreerichtext !== '' &&
                <div className="agreement">
                    <div className="custom-check">

                        {field.previewenablecheckbox === 'Yes' &&
                        <div className="custom-checkbox">
                            <input type="checkbox" name={field.hasOwnProperty('fieldname')?field.fieldname:field.label} value={val} checked={val === "yes"}/>
                            <label></label>
                        </div>
                        }
                        <div className="custom-checkbox-text">
                            <p dangerouslySetInnerHTML={{__html: atob(field.previewagreerichtext)}} className=""></p>
                        </div>

                    </div>
                </div>
                }
            </div>
        }
        else if(fieldType === 'resources') {
            let resourceVal = values.value !== undefined && values.value !== '' ? JSON.parse(values.value) : [];
            fieldValue = <div class="form-resource-preview preview-mode">
                {/* <h5>Selected Resources</h5> */}
                <div className="form-resource-block">
                    {resourceVal.length > 0 && resourceVal.map((list, index) =>
                        <ResourcePreviewAttachments
                            item = {list}
                            index={index}
                            // preview={this.preview}
                        />


                    )}
                </div>
            </div>
        }
        else if (fieldType === 'datepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                moment.utc(values.value).local().format('DD-MM-YYYY hh:mm')
                :
                field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                    moment(values.value).format('DD-MM-YYYY hh:mm')
                    :
                    values.value !== '' ?
                        moment(values.value).format('DD-MM-YYYY'):
                        values.value;
        }
        else if (fieldType === 'timepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && values.value !== '' ? moment.utc("2020-12-17 "+ values.value).local().format('HH:mm:ss') : values.value;
        }
        else if (fieldType === 'checkbox' || fieldType === 'switch') {
            if(field.hasOwnProperty(`checkboxoption`) && field.checkboxoption ==="imageoption" ){
                fieldValue=<div className={`option-score-container`}>{((length-1) === index) ?<div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span> </div> : <div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span></div> }</div>

            }
            else{
                fieldValue = ((length-1) === index) ? <>{values.value}{values.weightage !=="" && values.weightage !==undefined?<span className={`weightage`}>Score : {values.weightage}</span>:""}</> : (values.value + (values.weightage !==undefined && values.weightage !==""?<span className={`weightage`}>Score : {values.weightage}</span> :"") )+"," ;

            }
        }

        else {
            fieldValue = values.value;
        }

        return fieldValue;

    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }
        return filePath;
    }
    renderChildFields(children, index){
        /*////////console.log(this.state.formData)
        let fieldSetVal = children.fil((item) => item.values ? item.values.length:0);
        let fieldSetCount = Math.max.apply(null, fieldSetVal) // 4
        ////////console.log(children);
        ////////console.log(fieldSetVal);
        ////////console.log(fieldSetCount);*/
        var rows = [];
        //for (var i = 0; i < fieldSetCount; i++) {
        for(var j=0; j< children.length; j++) {
            rows.push(this.renderFields(children[j], j, 0 ));
        }
        //}
        return rows;

    }
    renderFields(field, index, childIndex) {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index):this.showFieldValue(field, index, childIndex);
    }
    render(){
        return(
            <div className={this.props.className}>
                <div className="right-panel-white-box">
                    <table className="table form-preview-table v-align">
                        <tbody>
                        {this.props.formData.filter((item) => item.linked== 'No').map((data,index) =>
                            <React.Fragment>
                                {this.renderFields(data, index)}
                            </React.Fragment>
                        )}

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

}

class Template3 extends React.Component{
    constructor(props){
        super(props)
    }
    showFieldSetValue(field, index) {
        let children = field.values ?field.values:[];

        return <>
            {index !== 0 ?
                <div className="form-single-value-box">
                    <p>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</p>
                </div>
                :
                <p>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</p>
            }

            <div className="form-single-value-box">
                <div className="scrollable">
                    <table className="table form-preview-table">
                        <tbody>
                        {children.length > 0 &&
                        this.renderChildFields(children, index)
                        }

                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }


    showFieldValue(field, index, childIndex,type) {
        let collectionId = this.props.collectionId ? this.props.collectionId : '';
        let recordId = this.props.recordId ? this.props.recordId : '';
        let fieldId = field.masterid ? field.masterid : '';
        let formMeta = this.props.meta && this.props.meta.version ? this.props.meta.version : 0;
        let formVersions = this.props.formVersions && this.props.formVersions.length > 0 && this.props.formVersions.includes(fieldId) ? true : false;

        if(field.type === "otp" || field.type === "payment") {
            return false
        }
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
        }
        if(type==="") {
            return <div className={`form-single-value-box ${field.type === 'agree'?'with-check-instruction':''}` }>
                {field.type !== 'agree'&&
                <p>
                    {field.hasOwnProperty('fieldname')?field.fieldname:field.label}
                    {type}
                    {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                        <>
                        <div className="label-title-block">
                            <button className={'ico-lib-v1 prev-version'} onClick={(e)=>this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                        </div>
                        <div className={`prev-version-highlight`}></div>
                        </>
                    }
                </p>
                }
                {field.type === 'formheading' ?
                    <td>&nbsp;</td>
                    :
                    field.type === 'text' && field.password === "Yes"?
                        <h5>*****</h5>
                        :
                    field.type === 'displaytext' || field.type === 'agree'  ?
                            <>
                                {field.type === 'agree' &&
                                <>

                                    <div className="select-chk-block" style={{"display": "block"}}>
                                        <label className="control control-checkbox">
                                            <input type="checkbox" className="inp-chk" id="text_searchable" name="dataview"
                                                   checked={field.values.length !== 0 && field.values[0].value === "yes"}/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                    <p>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}{type}</p>
                                </>
                                }
                                <h5 dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></h5>



                            </>

                        :
                        field.type === 'attachment' ?
                            <h5>
                                <ul className='attachment-list'>
                                    {field.values &&
                                    field.values.map((value, itemIndex) =>
                                        this.renderFieldType(value, field,field.values.length,itemIndex))}
                                </ul>
                            </h5>
                            :
                            (field.type === 'text' || field.type === 'number' || field.type === 'textarea' || field.type === 'email' || field.type === 'videourl' || field.type === 'timepicker' || field.type === 'datepicker' || field.type === 'url' || field.type === 'pan') ?
                                <h5>
                                    {field.values &&
                                    field.values.map((value, itemIndex) =>
                                        // this.renderFieldType(value, field,field.values.length,itemIndex)
                                        this.renderFieldType(value, field,field.values.length,itemIndex)
                                    )
                                    }
                                </h5>
                                :
                                <h5>
                                    {field.values &&
                                        field.values.map((value, itemIndex) =>
                                            this.renderFieldType(value, field,field.values.length,itemIndex)
                                        )
                                    }
                                </h5>
                }

            </div>;
        }
        else  if(type==="fieldset") {
            return <div className="col-tr">
                <div className="col-th">
                    {field.hasOwnProperty('fieldname')?field.fieldname:field.label}
                </div>
                {field.type === 'attachment' ?
                    <div className="col-td">
                        <ul className='attachment-list'>
                            {field.values &&
                            field.values.map((value, itemIndex) =>
                                this.renderFieldType(value, field,field.values.length,itemIndex))}
                        </ul>

                        {field.type === 'displaytext' &&
                        <h5 dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></h5>
                        }
                    </div>
                    :
                    field.type === 'datepicker' ?
                        <div className="col-td">
                            {field.values &&
                            field.values.map((value, itemIndex) =>
                                this.renderFieldType(value, field) + (itemIndex !== field.values.length - 1 ? "," : "")
                            )}
                            {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                            <>
                                <button className={'ico-lib-v1 prev-version'}
                                        onClick={(e) => this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                                <div className={`prev-version-highlight`}></div>
                            </>
                            }
                            {field.type === 'displaytext' &&
                            <h5 dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></h5>
                            }
                        </div>
                        :
                        field.type === 'preview' ?
                            <div className="col-td">
                                {field.values &&
                                field.values.map((value, itemIndex) =>
                                    this.renderFieldType(value, field,field.values.length,itemIndex )
                                )}
                            </div>
                    :
                    <div className="col-td">

                        {field.values &&
                        field.values.map((value, itemIndex) =>
                            this.renderFieldType(value, field.type,field.values.length,itemIndex) + (itemIndex !== field.values.length - 1 ? " " : "")
                        )}
                        {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                        <>
                            <button className={'ico-lib-v1 prev-version'}
                                    onClick={(e) => this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                            <div className={`prev-version-highlight`}></div>
                        </>
                        }
                        {field.type === 'displaytext' &&
                        <h5 dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></h5>
                        }
                    </div>
                }
            </div>;



        }


        else{
            return <>
                <tr key={index}>
                    <th>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}{type}{index}{childIndex}</th>
                    {field.type === 'formheading' ?
                        <td>&nbsp;</td>
                        :
                        <td > {field.values &&
                        field.values.map((value, itemIndex) =>
                            this.renderFieldType(value, field,field.values.length,itemIndex))}
                            {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                                <>
                            <button className={'ico-lib-v1 prev-version'} onClick={(e)=>this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                                <div className={`prev-version-highlight`}></div>
                                </>
                            }
                        </td>
                    }
                </tr>

            </>;

        }




    }
    renderFieldType = (values, field,length,index) => {
        let fieldType=field.type !== undefined ?field.type   : field
        let fieldValue = '';
        if(fieldType === 'attachment') {
            let uniqname = (values && values.uniqvalue) ? values.uniqvalue : '';
            let filename = values ? values.value : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileType = getFilePath(uniqname);
            var albumName = 'documents';
            if(filename !== ''){
                if (CustomFunctions.isImage(filename)) {
                    albumName = 'images'
                } else if (CustomFunctions.isVideo(filename)) {
                    albumName = 'videos'
                } else if (CustomFunctions.isAudio(filename)) {
                    albumName = 'audios'
                } else {
                    albumName = 'documents'
                }
            }

            if(uniqname !== '') {
                fieldValue =
                    <>
                        <li>
                            {fileType === 'image' ?
                                <>
                                    <a href="javascript:void(0);" className="attach-click"
                                       onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                       data-toggle="tooltip"
                                       data-placement="bottom" data-original-title="dummy">
                                        <img className="custom-image" src={FRMSIMG_PATH + uniqname}/>
                                    </a>
                                </>
                                :
                                fileType === 'video' || fileType === 'audio' ?
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, filename)}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                            }
                        </li>
                    </>
            }
        }
        else if(fieldType === 'editor') {
            fieldValue = <div dangerouslySetInnerHTML={{__html: values.value}}></div>
        }else if(fieldType === 'signature') {
            fieldValue = <img className="custom-image" src={values.value}/>
        }
        else if(fieldType === 'rating') {
            let rateValarray = [];
            let rateVal = values.value;
            rateVal = rateVal && rateVal !== '' ? rateVal : 0;
            let count = 5;
            for(var i=0; i<count; i++){
                rateValarray.push(i)
            }
            fieldValue = <ul className={`rate-star ${field.ratingtype === 'bulb' ?  "bulb" : ""}`}>
                {rateValarray.map((opt, index) =>
                    <li className={`${index < rateVal ?  "active" : ""}`} key={index}></li>
                )}
            </ul>
        }
        else if(fieldType === 'preview') {
            let val = values.value;
            fieldValue = <div className="preview-component">
                {field.previewurldisplay === 'popup' ?
                    <div className="preview-popup">
                        <div className="field-button-block">
                            <button className="general-btn"
                                    onClick={(e) => this.props.previewCompModal(field)}>{field.fieldLabel ? field.fieldLabel : field.label}</button>
                        </div>
                    </div>
                    :
                    <div className="preview-embed">
                        <div className="preview-panel" style={{width:field.previewidth !== '' ? field.previewidth + 'px' : '100%', height:field.previewheight !== '' ? field.previewheight + 'px': '350px'}}>
                            {field.previewfileurl !== '' &&
                            <>
                                {getFileExtension(field.previewfileurl) === 'doc' || getFileExtension(field.previewfileurl) === 'xls' ?
                                    <iframe src={'https://docs.google.com/viewer?url='+ field.previewfileurl + '&embedded=true'} width="100%" height="100%" />
                                    :
                                    (getFileExtension(field.previewfileurl) === 'jpg' || getFileExtension(field.previewfileurl) === 'png' || getFileExtension(field.previewfileurl) === 'jpeg') ?
                                        <img src={field.previewfileurl}/>
                                        :
                                        <FileViewers
                                            url={field.previewfileurl}
                                            type={getFileExtension(field.previewfileurl)}
                                            height={field.previewheight}
                                        />
                                }
                            </>
                            }
                        </div>
                        <div className="field-button-block">
                            {field.previewbuttons.length !== 0 && field.previewbuttons.map((list, index) =>
                                <>
                                    {list.previewtext !== '' &&
                                    <button className="general-btn" style={{
                                        color: list.previewtextcolor,
                                        backgroundColor: list.previewbgcolor
                                    }}>{list.previewtext}</button>
                                    }
                                </>
                            )}
                        </div>

                    </div>
                }

                {field.previewagreerichtext !== '' &&
                <div className="agreement">
                    <div className="custom-check">

                        {field.previewenablecheckbox === 'Yes' &&
                        <div className="custom-checkbox">
                            <input type="checkbox" name={field.hasOwnProperty('fieldname')?field.fieldname:field.label} value={val} checked={val === "yes"}/>
                            <label></label>
                        </div>
                        }
                        <div className="custom-checkbox-text">
                            <p dangerouslySetInnerHTML={{__html: atob(field.previewagreerichtext)}} className=""></p>
                        </div>

                    </div>
                </div>
                }
            </div>
        }
        else if(fieldType === 'resources') {
            let resourceVal = values.value !== undefined && values.value !== '' ? JSON.parse(values.value) : [];
            fieldValue = <div class="form-resource-preview preview-mode">
                {/* <h5>Selected Resources</h5> */}
                <div className="form-resource-block">
                    {resourceVal.length > 0 && resourceVal.map((list, index) =>
                        <ResourcePreviewAttachments
                            item = {list}
                            index={index}
                            // preview={this.preview}
                        />


                    )}
                </div>
            </div>
        }
        else if(fieldType === 'imageurl') {
            let filenameArr = values.value ? values.value.split(".") :[];
            let filename = '';
            if(filenameArr.length > 0){
                filename = filenameArr[filenameArr.length-1];
            }
            fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(values.value, filename)}>
                <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={values.value}/>
            </div>
        }
        else if(fieldType === 'url') {
            let externalLink = hasExtension(values.value);
            if(field.type !== undefined){
                if(externalLink === true){
                    fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ",  " :"")
                }
                else{
                    fieldValue =  ((length-1) === index) ? <a target="_blank" href={values.value}>{values.value}</a> : <a target="_blank" href={values.value}>{values.value + (values.value !== '' ? ",  " :"")}</a>
                }
            }
            else{
                fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
            }
        }
        else if(fieldType === 'photocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={FRMSIMG_PATH + uniqname}/>
                </div>
            }

        }else if(fieldType === 'videocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileurl = contentPath+uniqname;

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    {isVideo(fileurl) &&

                    <VideoPlayer width="320" height="240"  fileName={uniqname}   src={FRMSVID_PATH+uniqname}/>
                    }
                </div>
            }

        }
        else if (fieldType === 'checkbox' || fieldType === 'switch') {
            if(field.hasOwnProperty(`checkboxoption`) && field.checkboxoption ==="imageoption" ){
                fieldValue= <div className={`option-score-container`}>{((length-1) === index) ?<div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span> </div> : <div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span></div> }</div>

            }
            else{
                fieldValue = ((length-1) === index) ?(values.value + (values.weightage !=="" && values.weightage !==undefined? ' (Score : ' + values.weightage + ')' : ""))  : (values.value + (values.weightage !==undefined && values.weightage !=="" ? ' (Score : ' + values.weightage + ')' :"") )+", " ;

            }
        }
        else if (fieldType === 'dropdown' ) {
            fieldValue = ((length-1) === index) ? values.value :values.value+",  ";
        }

        else if (fieldType === 'datepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                ((length-1) === index) ? moment.utc(values.value).local().format('DD-MM-YYYY hh:mm') : moment.utc(values.value).local().format('DD-MM-YYYY hh:mm') + (values.value !== '' ? ", " :"")
                :
                field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                    ((length-1) === index) ? moment(values.value).format('DD-MM-YYYY hh:mm') : moment(values.value).format('DD-MM-YYYY hh:mm') + (values.value !== '' ? ", " :"")
                    :
                    values.value !== '' ?
                        ((length-1) === index) ? moment(values.value).format('DD-MM-YYYY') : moment(values.value).format('DD-MM-YYYY') + (values.value !== '' ? ", " :"")
                        :
                        ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
        }
        else if (fieldType === 'timepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && values.value !== '' ?
                ((length-1) === index) ? moment.utc("2020-12-17 "+ values.value).local().format('HH:mm:ss') : moment.utc("2020-12-17 "+ values.value).local().format('HH:mm:ss') + (values.value !== '' ? ", " :"")
                    :
                ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
        }
        else if (fieldType === 'computedfield' ) {
            fieldValue = values.value >0 ? parseFloat(values.value).toFixed(2) : 0;
        }

        else {
            fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
        }

        return fieldValue;

    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }
        return filePath;
    }
    showFieldSetChildValue(field, index,childIndex,child) {
        let children = field.values ?field.values:[];
        return <>
            <div className={`col-table ${child?"sub-child":"" }`}>
                <div className="col-tr">
                    <div className="col-header ">
                        {field.hasOwnProperty('fieldname')?field.fieldname:field.label}
                    </div>
                </div>
                {children.length > 0 &&
                this.renderChildFields(children, index,"child")
                }
            </div>

        </>;
    }
    renderFieldsetChild(field, index, childIndex,type="",child) {
        return (field.type === "fieldset") ? this.showFieldSetChildValue(field, index,"child"+child,child) :this.showFieldValue(field, index, childIndex,type);
    }


    renderChildFields(children, index,child){
        var rows = [];
        for(var j=0; j< children.length; j++) {
            rows.push(this.renderFieldsetChild(children[j], index, j,"fieldset" ,child));
        }
        return rows;

    }
    renderFields(field, index, childIndex,type='') {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index) :this.showFieldValue(field, index, childIndex,type);
    }
    render(){
        return(

            <div className={this.props.className}>

                {this.props.formData.filter(((item) => item.linked=== 'No' && item.type !=="otp" && item.type !== "payment")).map((data,index) =>
                    <div  className={`right-panel-white-box ${data.type === "fieldset"?"fieldset address-field" :""} ${data.type === "formheading"?"header" :""}`}>

                        {this.renderFields(data, index)}

                    </div>
                )}
            </div>



        )
    }

}


class Template4 extends React.Component{
    constructor(props){
        super(props)
    }

    showFieldSetValue(field, index) {

        let children = field.values ?field.values:[];

        return <>
            <tr key={index}><td colSpan="2" style={{color: "#3FA9F5"}}>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</td></tr>
            {children.length > 0 &&
            this.renderChildFields(children, index)
            }
        </>;
    }
    showFieldValue(field, index, childIndex) {
        let collectionId = this.props.collectionId ? this.props.collectionId : '';
        let recordId = this.props.recordId ? this.props.recordId : '';
        let fieldId = field.masterid ? field.masterid : '';
        let formMeta = this.props.meta && this.props.meta.version ? this.props.meta.version : 0;
        let formVersions = this.props.formVersions && this.props.formVersions.length > 0 && this.props.formVersions.includes(fieldId) ? true : false;

        if(field.type === "otp" || field.type === "payment") {
            return false
        }
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
            //////////console.log(field.value[childIndex]);


        }

        return <tr key={index}>
            <th>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</th>
            {field.type === 'formheading' ?
                <td>&nbsp;</td>
                :
                field.type === 'displaytext' ?
                    <td dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></td>
                    :
                    field.type === 'attachment' ?
                        <td>
                            <ul className='attachment-list' >
                                {field.values &&
                                field.values.map((value, itemIndex) =>
                                    this.renderFieldType(value, field,field.values.length,itemIndex)
                                )
                                }
                            </ul>
                        </td>
                    :
                <td> {field.values &&
                field.values.map((value, itemIndex) =>
                    this.renderFieldType(value, field,field.values.length,itemIndex)
                )
                }
                    {/* field.linked === 'Yes' && field.value && field.value.length > 0 &&
            this.renderFieldType(field.value[childIndex], field.type)
            */}
                    {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                        <>
                    <button className={'ico-lib-v1 prev-version'} onClick={(e)=>this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                        <div className={`prev-version-highlight`}></div>
                        </>
                    }
                </td>}

        </tr>;
    }

    renderFieldType = (values, field,length,index) => {
        let fieldType=field.type
        let fieldValue = '';
        if(fieldType === 'attachment') {
            let uniqname = (values && values.uniqvalue) ? values.uniqvalue : '';
            let filename = values ? values.value : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileType = getFilePath(uniqname);
            var albumName = 'documents';
            if(filename !== ''){
                if (CustomFunctions.isImage(filename)) {
                    albumName = 'images'
                } else if (CustomFunctions.isVideo(filename)) {
                    albumName = 'videos'
                } else if (CustomFunctions.isAudio(filename)) {
                    albumName = 'audios'
                } else {
                    albumName = 'documents'
                }
            }

            if(uniqname !== '') {
                fieldValue =
                    <>
                        <li>
                            {fileType === 'image' ?
                                <>
                                    <a href="javascript:void(0);" className="attach-click"
                                       onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                       data-toggle="tooltip"
                                       data-placement="bottom" data-original-title="dummy">
                                        <img className="custom-image" src={FRMSIMG_PATH + uniqname}/>
                                    </a>
                                </>
                                :
                                fileType === 'video' || fileType === 'audio' ?
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, filename)}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                            }
                        </li>
                    </>
            }
        }
        else if(fieldType === 'videocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileurl = contentPath+uniqname;

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    {isVideo(fileurl) &&

                    <VideoPlayer width="320" height="240"  fileName={uniqname}   src={FRMSVID_PATH+uniqname}/>
                    }
                </div>
            }

        }
        else if(fieldType === 'editor') {
            fieldValue = <div dangerouslySetInnerHTML={{__html: values.value}}></div>
        }else if(fieldType === 'signature') {
            fieldValue = <img className="custom-image" src={values.value}/>
        }
        else if(fieldType === 'imageurl') {
            let filenameArr = values.value ? values.value.split(".") :[];
            let filename = '';
            if(filenameArr.length > 0){
                filename = filenameArr[filenameArr.length-1];
            }
            fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(values.value, filename)}>
                <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={values.value}/>
            </div>
        }
        else if(fieldType === 'url') {
            let externalLink = hasExtension(values.value);
            if(field.type !== undefined){
                if(externalLink === true){
                    fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ",  " :"")
                }
                else{
                    fieldValue =  ((length-1) === index) ? <a target="_blank" href={values.value}>{values.value}</a> : <a target="_blank" href={values.value}>{values.value + (values.value !== '' ? ",  " :"")}</a>
                }
            }
            else{
                fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
            }
        }
        else if(fieldType === 'photocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            if(uniqname !== '' && uniqname !== 'undefined') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={FRMSIMG_PATH + uniqname}/>
                </div>
            }

        }
        else if (fieldType === 'checkbox' || fieldType === 'switch') {
            if(field.hasOwnProperty(`checkboxoption`) && field.checkboxoption ==="imageoption" ){
                fieldValue=<div className={`option-score-container`}>
                    {((length-1) === index) ?<div className={`option-with-score`}>
                        <img className="custom-image" src={values.value}/>
                        <span className={`weightage`}>Score : {values.weightage}</span> </div> :
                        <div className={`option-with-score`}>

                            <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span></div> }</div>

            }
            else if(field.hasOwnProperty(`checkboxoption`) && field.checkboxoption ==="textoption"){
                fieldValue=<>
                    {values.value}
                        {(values.hasOwnProperty('weightage') && values.weightage !== "") &&
                            <React.Fragment>
                                {((length - 1) === index) ?
                                    <span className={`weightage`}> {`Score :` + values.weightage} </span>
                                    :
                                    <span className={`weightage`}> {`Score :` + values.weightage + ", "}</span>
                                }
                            </React.Fragment>
                        }
                </>
            }
            else{
                fieldValue = ((length-1) === index) ?
                    <>{values.value}{values.weightage !=="" && values.weightage !==undefined?
                        <span className={`weightage`}>Score : {values.weightage}</span>:""}</> :
                    (values.value + (values.weightage !==undefined && values.weightage !==""?
                        <span className={`weightage`}>Score : {values.weightage}</span> :"") )+", " ;

            }
        }
        else if(fieldType === 'rating') {
            let rateValarray = [];
            let rateVal = values.value;
            rateVal = rateVal && rateVal !== '' ? rateVal : 0;
            let count = 5;
            for(var i=0; i<count; i++){
                rateValarray.push(i)
            }
            fieldValue = <ul className={`rate-star ${field.ratingtype === 'bulb' ?  "bulb" : ""}`}>
                {rateValarray.map((opt, index) =>
                    <li className={`${index < rateVal ?  "active" : ""}`} key={index}></li>
                )}
            </ul>
        }
        else if(fieldType === 'preview') {
            let val = values.value;
            fieldValue = <div className="preview-component">
                {field.previewurldisplay === 'popup' ?
                    <div className="preview-popup">
                        <div className="field-button-block">
                            <button className="general-btn"
                                    onClick={(e) => this.props.previewCompModal(field)}>{field.fieldLabel ? field.fieldLabel : field.label}</button>
                        </div>
                    </div>
                    :
                    <div className="preview-embed">
                        <div className="preview-panel" style={{width:field.previewidth !== '' ? field.previewidth + 'px' : '100%', height:field.previewheight !== '' ? field.previewheight + 'px': '350px'}}>
                            {field.previewfileurl !== '' &&
                            <>
                                {getFileExtension(field.previewfileurl) === 'doc' || getFileExtension(field.previewfileurl) === 'xls' ?
                                    <iframe src={'https://docs.google.com/viewer?url='+ field.previewfileurl + '&embedded=true'} width="100%" height="100%" />
                                    :
                                    (getFileExtension(field.previewfileurl) === 'jpg' || getFileExtension(field.previewfileurl) === 'png' || getFileExtension(field.previewfileurl) === 'jpeg') ?
                                        <img src={field.previewfileurl}/>
                                        :
                                        <FileViewers
                                            url={field.previewfileurl}
                                            type={getFileExtension(field.previewfileurl)}
                                            height={field.previewheight}
                                        />
                                }
                            </>
                            }
                        </div>
                        <div className="field-button-block">
                            {field.previewbuttons.length !== 0 && field.previewbuttons.map((list, index) =>
                                <>
                                    {list.previewtext !== '' &&
                                    <button className="general-btn" style={{
                                        color: list.previewtextcolor,
                                        backgroundColor: list.previewbgcolor
                                    }}>{list.previewtext}</button>
                                    }
                                </>
                            )}
                        </div>

                    </div>
                }

                {field.previewagreerichtext !== '' &&
                <div className="agreement">
                    <div className="custom-check">

                        {field.previewenablecheckbox === 'Yes' &&
                        <div className="custom-checkbox">
                            <input type="checkbox" name={field.hasOwnProperty('fieldname')?field.fieldname:field.label} value={val} checked={val === "yes"}/>
                            <label></label>
                        </div>
                        }
                        <div className="custom-checkbox-text">
                            <p dangerouslySetInnerHTML={{__html: atob(field.previewagreerichtext)}} className=""></p>
                        </div>

                    </div>
                </div>
                }
            </div>
        }
        else if(fieldType === 'resources') {
            let resourceVal = values.value !== undefined && values.value !== '' ? JSON.parse(values.value) : [];
            fieldValue = <div class="form-resource-preview preview-mode">
                {/* <h5>Selected Resources</h5> */}
                <div className="form-resource-block">
                    {resourceVal.length > 0 && resourceVal.map((list, index) =>
                        <ResourcePreviewAttachments
                            item = {list}
                            index={index}
                            // preview={this.preview}
                        />


                    )}
                </div>
            </div>
        }

        else if (fieldType === 'datepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                moment.utc(values.value).local().format('DD-MM-YYYY hh:mm')
                :
                field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                    moment(values.value).format('DD-MM-YYYY hh:mm')
                    :
                    values.value !== '' ?
                        moment(values.value).format('DD-MM-YYYY'):
                        values.value;
        }

        else if (fieldType === 'timepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && values.value !== '' ? moment.utc("2020-12-17 "+ values.value).local().format('HH:mm:ss') : values.value;
        }

        else {
            fieldValue = (fieldType === 'computedfield') ? (values.value >0 ? parseFloat(values.value).toFixed(2) : 0):values.value;
        }

        return fieldValue;

    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }
        return filePath;
    }
    renderChildFields(children, index){
        /*////////console.log(this.state.formData)
        let fieldSetVal = children.fil((item) => item.values ? item.values.length:0);
        let fieldSetCount = Math.max.apply(null, fieldSetVal) // 4
        ////////console.log(children);
        ////////console.log(fieldSetVal);
        ////////console.log(fieldSetCount);*/
        var rows = [];
        //for (var i = 0; i < fieldSetCount; i++) {
        for(var j=0; j< children.length; j++) {
            rows.push(this.renderFields(children[j], j, 0 ));
        }
        //}
        return rows;

    }
    renderFields(field, index, childIndex,type='') {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index) :this.showFieldValue(field, index, childIndex,type);
    }

    render(){
       // let firstTemplate=this.props.formData.filter(item => (item.linked === 'No' &&  this.props.masterIds.includes(item.masterid)))
        let secondTemplate=this.props.formData.filter(item => (item.linked === 'No' &&  !this.props.masterIds.includes(item.masterid)))
       // //console.log(firstTemplate)
        let firstTemplate=[]
        if(this.props.customtemplate.length !== 0){

            if(this.props.customtemplate.name === "template3" || this.props.customtemplate.name === "temp3ver") {
                this.props.customtemplate.template.map((item) => (
                    this.props.formData.filter(list=>list.masterid.toString() === item.masterid.toString()).map((list)=>
                        firstTemplate.push(list)
                    )
                ))

            }
        }
        //console.log(firstTemplate)
        return(

            <div className="left-panel with-card with-card-preview">


                <div className="card-container grid-view template-collection card-view-fixed with-image ">
                    <div className="ind-collection-card">
                        {/*{this.props.formData.filter(item => item.linked== 'No' &&  item.masterid === "264220509").map((data,index) =>*/}
                        {/*    <div className="collection-card-left">*/}
                        {/*        {this.showFieldValue(data, index,"")}*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {firstTemplate.map((data,index) =>
                            <>
                                {data.type === "attachment" &&
                                <div className={`collection-card-left ${(index===0 && data.values[0].length !==0) ?(isImage(data.values[0].value)? "":'card-top-block'):''}`}>
                                    <div className="collection-card-image">
                                        {this.showFieldValue(data, index,"")}
                                    </div>
                                </div>
                                }
                                {data.type === "datepicker" &&
                                <div className= {`collection-card-right ${index===0? "card-top-block":''}`}>
                                    <div className="collection-card-right-header">
                                        <p>{data.hasOwnProperty('fieldname')?data.fieldname:data.label} </p>
                                        <h5>{data.values.length !==0 && DateFormatter(data.values[0].value, true, true)}</h5>
                                    </div>
                                </div>
                                }

                                {data.type !== "attachment" && data.type !== "datepicker" &&
                                <div className= {`collection-card-right ${index===0? "card-top-block":''}`}>
                                    <div className="collection-card-right-header">
                                        <p>{data.hasOwnProperty('fieldname')?data.fieldname:data.label}</p>
                                        <h5>{data.values.length !==0 && data.values[0].value}</h5>
                                    </div>
                                </div>
                                }

                            </>
                        )}


                    </div>
                </div>
                <div className="right-panel-white-box">
                    <table className="table form-preview-table">
                        <tbody>
                        {secondTemplate.map((data,index) =>
                            <React.Fragment>

                                {this.renderFields(data, index)}
                            </React.Fragment>
                        )}

                        </tbody>
                    </table>
                </div>



            </div>



        )
    }

}
class Template3Vertical extends React.Component{
    constructor(props){
        super(props)
    }

    showFieldSetValue(field, index) {

        let children = field.values ?field.values:[];

        return <>
            <tr key={index}><td colSpan="2" style={{color: "#3FA9F5"}} className={`template-heading`}>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</td></tr>
            {children.length > 0 &&
            this.renderChildFields(children, index)
            }
        </>;
    }
    showFieldValue(field, index, childIndex) {
        let collectionId = this.props.collectionId ? this.props.collectionId : '';
        let recordId = this.props.recordId ? this.props.recordId : '';
        let fieldId = field.masterid ? field.masterid : '';
        let formMeta = this.props.meta && this.props.meta.version ? this.props.meta.version : 0;
        let formVersions = this.props.formVersions && this.props.formVersions.length > 0 && this.props.formVersions.includes(fieldId) ? true : false;

        if(field.type === "otp" || field.type === "payment") {
            return false
        }
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
            //////////console.log(field.value[childIndex]);


        }

        return <tr key={index}>
            <th>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</th>
            {field.type === 'formheading' ?
                <td>&nbsp;</td>
                :
                field.type === 'displaytext' ?
                    <td dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></td>
                    :
                    field.type === 'attachment' ?
                        <td>
                            <ul className='attachment-list' >
                                {field.values &&
                                field.values.map((value, itemIndex) =>
                                    this.renderFieldType(value, field)
                                )
                                }
                            </ul>
                        </td>
                        :
                        <td> {field.values &&
                        field.values.map((value, itemIndex) =>
                            this.renderFieldType(value, field)
                        )
                        }
                            {/* field.linked === 'Yes' && field.value && field.value.length > 0 &&
            this.renderFieldType(field.value[childIndex], field.type)
            */}
                            {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                            <>
                                <button className={'ico-lib-v1 prev-version'} onClick={(e)=>this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                                <div className={`prev-version-highlight`}></div>
                            </>
                            }
                        </td>}

        </tr>;
    }

    renderFieldType = (values, field,length,index) => {
        //console.log(field)
        let fieldType=field.type
        let fieldValue = '';
        if(fieldType === 'attachment') {
            let uniqname = (values && values.uniqvalue) ? values.uniqvalue : '';
            let filename = values ? values.value : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileType = getFilePath(uniqname);
            var albumName = 'documents';
            if(filename !== ''){
                if (CustomFunctions.isImage(filename)) {
                    albumName = 'images'
                } else if (CustomFunctions.isVideo(filename)) {
                    albumName = 'videos'
                } else if (CustomFunctions.isAudio(filename)) {
                    albumName = 'audios'
                } else {
                    albumName = 'documents'
                }
            }

            if(uniqname !== '') {
                fieldValue =
                    <>
                        <li>
                            {fileType === 'image' ?
                                <>
                                    <a href="javascript:void(0);" className="attach-click"
                                       onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                       data-toggle="tooltip"
                                       data-placement="bottom" data-original-title="dummy">
                                        <img className="custom-image" src={FRMSIMG_PATH + uniqname}/>
                                    </a>
                                </>
                                :
                                fileType === 'video' || fileType === 'audio' ?
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, filename)}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                            }
                        </li>
                    </>
            }
        }
        else if(fieldType === 'videocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileurl = contentPath+uniqname;

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    {isVideo(fileurl) &&

                    <VideoPlayer width="320" height="240"  fileName={uniqname}   src={FRMSVID_PATH+uniqname}/>
                    }
                </div>
            }

        }
        else if(fieldType === 'editor') {
            fieldValue = <div dangerouslySetInnerHTML={{__html: values.value}}></div>
        }else if(fieldType === 'signature') {
            fieldValue = <img className="custom-image" src={values.value}/>
        }
        else if(fieldType === 'imageurl') {
            let filenameArr = values.value ? values.value.split(".") :[];
            let filename = '';
            if(filenameArr.length > 0){
                filename = filenameArr[filenameArr.length-1];
            }
            fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(values.value, filename)}>
                <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={values.value}/>
            </div>
        }
        else if(fieldType === 'url') {
            let externalLink = hasExtension(values.value);
            if(field.type !== undefined){
                if(externalLink === true){
                    fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ",  " :"")
                }
                else{
                    fieldValue =  ((length-1) === index) ? <a target="_blank" href={values.value}>{values.value}</a> : <a target="_blank" href={values.value}>{values.value + (values.value !== '' ? ",  " :"")}</a>
                }
            }
            else{
                fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
            }
        }
        else if(fieldType === 'photocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            if(uniqname !== '' && uniqname !== 'undefined') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={FRMSIMG_PATH + uniqname}/>
                </div>
            }

        }
        else if (fieldType === 'checkbox' || fieldType === 'switch') {
            if(field.hasOwnProperty(`checkboxoption`) && field.checkboxoption ==="imageoption" ){
                fieldValue=<div className={`option-score-container`}>{((length-1) === index) ?<div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span> </div> : <div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span></div> }</div>

            }
            else{
                fieldValue = ((length-1) === index) ? <>{values.value}{values.weightage !=="" && values.weightage !==undefined?<span className={`weightage`}>Score : {values.weightage}</span>:""}</> : (values.value + (values.weightage !==undefined && values.weightage !==""?<span className={`weightage`}>Score : {values.weightage}</span> :"") )+", " ;

            }
        }
        else if(fieldType === 'rating') {
            let rateValarray = [];
            let rateVal = values.value;
            rateVal = rateVal && rateVal !== '' ? rateVal : 0;
            let count = 5;
            for(var i=0; i<count; i++){
                rateValarray.push(i)
            }
            fieldValue = <ul className={`rate-star ${field.ratingtype === 'bulb' ?  "bulb" : ""}`}>
                {rateValarray.map((opt, index) =>
                    <li className={`${index < rateVal ?  "active" : ""}`} key={index}></li>
                )}
            </ul>
        }
        else if(fieldType === 'preview') {
            let val = values.value;
            fieldValue = <div className="preview-component">
                {field.previewurldisplay === 'popup' ?
                    <div className="preview-popup">
                        <div className="field-button-block">
                            <button className="general-btn"
                                    onClick={(e) => this.props.previewCompModal(field)}>{field.fieldLabel ? field.fieldLabel : field.label}</button>
                        </div>
                    </div>
                    :
                    <div className="preview-embed">
                        <div className="preview-panel" style={{width:field.previewidth !== '' ? field.previewidth + 'px' : '100%', height:field.previewheight !== '' ? field.previewheight + 'px': '350px'}}>
                            {field.previewfileurl !== '' &&
                            <>
                                {getFileExtension(field.previewfileurl) === 'doc' || getFileExtension(field.previewfileurl) === 'xls' ?
                                    <iframe src={'https://docs.google.com/viewer?url='+ field.previewfileurl + '&embedded=true'} width="100%" height="100%" />
                                    :
                                    (getFileExtension(field.previewfileurl) === 'jpg' || getFileExtension(field.previewfileurl) === 'png' || getFileExtension(field.previewfileurl) === 'jpeg') ?
                                        <img src={field.previewfileurl}/>
                                        :
                                        <FileViewers
                                            url={field.previewfileurl}
                                            type={getFileExtension(field.previewfileurl)}
                                            height={field.previewheight}
                                        />
                                }
                            </>
                            }
                        </div>
                        <div className="field-button-block">
                            {field.previewbuttons.length !== 0 && field.previewbuttons.map((list, index) =>
                                <>
                                    {list.previewtext !== '' &&
                                    <button className="general-btn" style={{
                                        color: list.previewtextcolor,
                                        backgroundColor: list.previewbgcolor
                                    }}>{list.previewtext}</button>
                                    }
                                </>
                            )}
                        </div>

                    </div>
                }

                {field.previewagreerichtext !== '' &&
                <div className="agreement">
                    <div className="custom-check">

                        {field.previewenablecheckbox === 'Yes' &&
                        <div className="custom-checkbox">
                            <input type="checkbox" name={field.hasOwnProperty('fieldname')?field.fieldname:field.label} value={val} checked={val === "yes"}/>
                            <label></label>
                        </div>
                        }
                        <div className="custom-checkbox-text">
                            <p dangerouslySetInnerHTML={{__html: atob(field.previewagreerichtext)}} className=""></p>
                        </div>

                    </div>
                </div>
                }
            </div>
        }
        else if(fieldType === 'resources') {
            let resourceVal = values.value !== undefined && values.value !== '' ? JSON.parse(values.value) : [];
            fieldValue = <div class="form-resource-preview preview-mode">
                {/* <h5>Selected Resources</h5> */}
                <div className="form-resource-block">
                    {resourceVal.length > 0 && resourceVal.map((list, index) =>
                        <ResourcePreviewAttachments
                            item = {list}
                            index={index}
                            // preview={this.preview}
                        />


                    )}
                </div>
            </div>
        }

        else if (fieldType === 'datepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                moment.utc(values.value).local().format('DD-MM-YYYY hh:mm')
                :
                field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                    moment(values.value).format('DD-MM-YYYY hh:mm')
                    :
                    values.value !== '' ?
                        moment(values.value).format('DD-MM-YYYY'):
                        values.value;
        }

        else if (fieldType === 'timepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && values.value !== '' ? moment.utc("2020-12-17 "+ values.value).local().format('HH:mm:ss') : values.value;
        }

        else {
            fieldValue = (fieldType === 'computedfield') ? (values.value >0 ? parseFloat(values.value).toFixed(2) : 0):values.value;
        }

        return fieldValue;

    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }
        return filePath;
    }
    renderChildFields(children, index){
        /*////////console.log(this.state.formData)
        let fieldSetVal = children.fil((item) => item.values ? item.values.length:0);
        let fieldSetCount = Math.max.apply(null, fieldSetVal) // 4
        ////////console.log(children);
        ////////console.log(fieldSetVal);
        ////////console.log(fieldSetCount);*/
        var rows = [];
        //for (var i = 0; i < fieldSetCount; i++) {
        for(var j=0; j< children.length; j++) {
            rows.push(this.renderFields(children[j], j, 0 ));
        }
        //}
        return rows;

    }
    renderFields(field, index, childIndex,type='') {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index) :this.showFieldValue(field, index, childIndex,type);
    }

    render(){
        // let firstTemplate=this.props.formData.filter(item => (item.linked === 'No' &&  this.props.masterIds.includes(item.masterid)))
        let secondTemplate=this.props.formData.filter(item => (item.linked === 'No' &&  !this.props.masterIds.includes(item.masterid)))
        // //console.log(firstTemplate)
        let firstTemplate=[]
        if(this.props.customtemplate.length !== 0){

            if(this.props.customtemplate.name === "template3" || this.props.customtemplate.name === "temp3ver" ) {
                this.props.customtemplate.template.map((item) => (
                    this.props.formData.filter(list=>list.masterid.toString() === item.masterid.toString()).map((list)=>
                        firstTemplate.push(list)
                    )
                ))

            }
        }
        //console.log(firstTemplate)
        return(

            <div className="left-panel with-card with-card-preview">


                <div className="card-container grid-view template-collection card-view-fixed with-image ">
                    <div className="ind-collection-card">
                        {/*{this.props.formData.filter(item => item.linked== 'No' &&  item.masterid === "264220509").map((data,index) =>*/}
                        {/*    <div className="collection-card-left">*/}
                        {/*        {this.showFieldValue(data, index,"")}*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {firstTemplate.map((data,index) =>
                            <>
                                {data.type === "attachment" &&
                                <div className={`collection-card-left ${(index===0 && data.values[0].length !==0) ?(isImage(data.values[0].value)? "":'card-top-block'):''}`}>
                                    <div className="collection-card-image">
                                        {this.showFieldValue(data, index,"")}
                                    </div>
                                </div>
                                }

                                {data.type === "datepicker" &&
                                <div className= {`collection-card-right ${index===0? "card-top-block":''}`}>
                                    <div className="collection-card-right-header">
                                        <p>{data.hasOwnProperty('fieldname')?data.fieldname:data.label}</p>
                                        <h5>{data.values.length !==0 && DateFormatter(data.values[0].value, true, true)}</h5>

                                    </div>
                                </div>
                                }

                                {data.type !== "attachment" && data.type !== "datepicker" &&
                                <div className= {`collection-card-right ${index===0? "card-top-block":''}`}>
                                    <div className="collection-card-right-header">
                                        <p>{data.hasOwnProperty('fieldname')?data.fieldname:data.label}</p>
                                        <h5>{data.values.length !==0 && data.values[0].value}</h5>
                                    </div>
                                </div>
                                }

                            </>
                        )}


                    </div>
                </div>
                <div className="right-panel-white-box">
                    <table className="table form-preview-table v-align">
                        <tbody>
                        {secondTemplate.map((data,index) =>
                            <React.Fragment>

                                {this.renderFields(data, index)}
                            </React.Fragment>
                        )}

                        </tbody>
                    </table>
                </div>



            </div>



        )
    }

}

class Template5 extends React.Component{
    constructor(props){
        super(props)
    }

    showFieldSetValue(field, index) {

        let children = field.values ?field.values:[];

        return <>
            <tr key={index}><td colSpan="2" style={{color: "#3FA9F5"}}>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</td></tr>
            {children.length > 0 &&
            this.renderChildFields(children, index)
            }
        </>;
    }
    showFieldValue(field, index, childIndex) {
        let collectionId = this.props.collectionId ? this.props.collectionId : '';
        let recordId = this.props.recordId ? this.props.recordId : '';
        let fieldId = field.masterid ? field.masterid : '';
        let formMeta = this.props.meta && this.props.meta.version ? this.props.meta.version : 0;
        let formVersions = this.props.formVersions && this.props.formVersions.length > 0 && this.props.formVersions.includes(fieldId) ? true : false;

        if(field.type === "otp" || field.type === "payment") {
            return false
        }
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
            //////////console.log(field.value[childIndex]);


        }

        return <tr key={index}>
            <th>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</th>
            {field.type === 'formheading' ?
                <td>&nbsp;</td>
                :
                field.type === 'displaytext' ?
                    <td dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></td>
                    :
                    field.type === 'attachment' ?
                    <td>
                        <ul className='attachment-list' >
                            {field.values &&
                            field.values.map((value, itemIndex) =>
                                this.renderFieldType(value, field,field.values.length,itemIndex)
                            )
                            }
                        </ul>
                    </td>
                :
                <td> {field.values &&
                field.values.map((value, itemIndex) =>
                    this.renderFieldType(value, field,field.values.length,itemIndex)
                )
                }
                    {/* field.linked === 'Yes' && field.value && field.value.length > 0 &&
            this.renderFieldType(field.value[childIndex], field.type)
            */}
                    {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                        <>
                    <button className={'ico-lib-v1 prev-version'} onClick={(e)=>this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                        <div className={`prev-version-highlight`}></div>
                        </>
                    }
                </td>}

        </tr>;
    }

    renderFieldType = (values, field,length,index) => {
        //console.log(field)
        let fieldType=field.type
        let fieldValue = '';
        if(fieldType === 'attachment') {
            let uniqname = (values && values.uniqvalue) ? values.uniqvalue : '';
            let filename = values ? values.value : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileType = getFilePath(uniqname);
            var albumName = 'documents';
            if(filename !== ''){
                if (CustomFunctions.isImage(filename)) {
                    albumName = 'images'
                } else if (CustomFunctions.isVideo(filename)) {
                    albumName = 'videos'
                } else if (CustomFunctions.isAudio(filename)) {
                    albumName = 'audios'
                } else {
                    albumName = 'documents'
                }
            }

            if(uniqname !== '') {
                fieldValue =
                    <>
                        <li>
                            {fileType === 'image' ?
                                <>
                                    <a href="javascript:void(0);" className="attach-click"
                                       onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                       data-toggle="tooltip"
                                       data-placement="bottom" data-original-title="dummy">
                                        <img className="custom-image" src={FRMSIMG_PATH + uniqname}/>
                                    </a>
                                </>
                                :
                                fileType === 'video' || fileType === 'audio' ?
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, filename)}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                            }
                        </li>
                    </>
            }
        }
        else if(fieldType === 'videocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileurl = contentPath+uniqname;

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    {isVideo(fileurl) &&

                    <VideoPlayer width="320" height="240"  fileName={uniqname}   src={FRMSVID_PATH+uniqname}/>
                    }
                </div>
            }

        }
        else if(fieldType === 'editor') {
            fieldValue = <div dangerouslySetInnerHTML={{__html: values.value}}></div>
        }else if(fieldType === 'signature') {
            fieldValue = <img className="custom-image" src={values.value}/>
        }
        else if(fieldType === 'imageurl') {
            let filenameArr = values.value ? values.value.split(".") :[];
            let filename = '';
            if(filenameArr.length > 0){
                filename = filenameArr[filenameArr.length-1];
            }
            fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(values.value, filename)}>
                <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={values.value}/>
            </div>
        }
        else if(fieldType === 'url') {
            let externalLink = hasExtension(values.value);
            if(field.type !== undefined){
                if(externalLink === true){
                    fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ",  " :"")
                }
                else{
                    fieldValue =  ((length-1) === index) ? <a target="_blank" href={values.value}>{values.value}</a> : <a target="_blank" href={values.value}>{values.value + (values.value !== '' ? ",  " :"")}</a>
                }
            }
            else{
                fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
            }
        }
        else if(fieldType === 'photocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            if(uniqname !== '' && uniqname !== 'undefined') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={FRMSIMG_PATH + uniqname}/>
                </div>
            }

        }
        else if (fieldType === 'checkbox' || fieldType === 'switch') {
            if(field.hasOwnProperty(`checkboxoption`) && field.checkboxoption ==="imageoption" ){
                fieldValue=<div className={`option-score-container`}>{((length-1) === index) ?<div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span> </div> : <div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span></div> }</div>

            }
            else{
                fieldValue = ((length-1) === index) ? <>{values.value}{values.weightage !=="" && values.weightage !==undefined?<span className={`weightage`}>Score : {values.weightage}</span>:""}</> : (values.value + (values.weightage !==undefined && values.weightage !==""?<span className={`weightage`}>Score : {values.weightage}</span> :"") )+", " ;

            }
        }
        else if(fieldType === 'rating') {
            let rateValarray = [];
            let rateVal = values.value;
            rateVal = rateVal && rateVal !== '' ? rateVal : 0;
            let count = 5;
            for(var i=0; i<count; i++){
                rateValarray.push(i)
            }
            fieldValue = <ul className={`rate-star ${field.ratingtype === 'bulb' ?  "bulb" : ""}`}>
                {rateValarray.map((opt, index) =>
                    <li className={`${index < rateVal ?  "active" : ""}`} key={index}></li>
                )}
            </ul>
        }
        else if(fieldType === 'preview') {
            let val = values.value;
            fieldValue = <div className="preview-component">
                {field.previewurldisplay === 'popup' ?
                    <div className="preview-popup">
                        <div className="field-button-block">
                            <button className="general-btn"
                                    onClick={(e) => this.props.previewCompModal(field)}>{field.fieldLabel ? field.fieldLabel : field.label}</button>
                        </div>
                    </div>
                    :
                    <div className="preview-embed">
                        <div className="preview-panel" style={{width:field.previewidth !== '' ? field.previewidth + 'px' : '100%', height:field.previewheight !== '' ? field.previewheight + 'px': '350px'}}>
                            {field.previewfileurl !== '' &&
                            <>
                                {getFileExtension(field.previewfileurl) === 'doc' || getFileExtension(field.previewfileurl) === 'xls' ?
                                    <iframe src={'https://docs.google.com/viewer?url='+ field.previewfileurl + '&embedded=true'} width="100%" height="100%" />
                                    :
                                    (getFileExtension(field.previewfileurl) === 'jpg' || getFileExtension(field.previewfileurl) === 'png' || getFileExtension(field.previewfileurl) === 'jpeg') ?
                                        <img src={field.previewfileurl}/>
                                        :
                                        <FileViewers
                                            url={field.previewfileurl}
                                            type={getFileExtension(field.previewfileurl)}
                                            height={field.previewheight}
                                        />
                                }
                            </>
                            }
                        </div>
                        <div className="field-button-block">
                            {field.previewbuttons.length !== 0 && field.previewbuttons.map((list, index) =>
                                <>
                                    {list.previewtext !== '' &&
                                    <button className="general-btn" style={{
                                        color: list.previewtextcolor,
                                        backgroundColor: list.previewbgcolor
                                    }}>{list.previewtext}</button>
                                    }
                                </>
                            )}
                        </div>

                    </div>
                }

                {field.previewagreerichtext !== '' &&
                <div className="agreement">
                    <div className="custom-check">

                        {field.previewenablecheckbox === 'Yes' &&
                        <div className="custom-checkbox">
                            <input type="checkbox" name={field.hasOwnProperty('fieldname')?field.fieldname:field.label} value={val} checked={val === "yes"}/>
                            <label></label>
                        </div>
                        }
                        <div className="custom-checkbox-text">
                            <p dangerouslySetInnerHTML={{__html: atob(field.previewagreerichtext)}} className=""></p>
                        </div>

                    </div>
                </div>
                }
            </div>
        }
        else if(fieldType === 'resources') {
            let resourceVal = values.value !== undefined && values.value !== '' ? JSON.parse(values.value) : [];
            fieldValue = <div className="form-resource-preview preview-mode">
                <div className="form-resource-block">
                    {resourceVal.length > 0 && resourceVal.map((list, index) =>
                        <ResourcePreviewAttachments
                            item = {list}
                            index={index}
                            // preview={this.preview}
                        />


                    )}
                </div>
            </div>
        }

        else if (fieldType === 'datepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && field.datetime && field.datetime === 'Yes' ?
                moment.utc(values.value).local().format('DD-MM-YYYY hh:mm')
                :
                field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                    moment(values.value).format('DD-MM-YYYY hh:mm')
                    :
                    values.value !== '' ?
                        moment(values.value).format('DD-MM-YYYY'):
                        values.value;
        }

        else if (fieldType === 'timepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && values.value !== '' ? moment.utc("2020-12-17 "+ values.value).local().format('HH:mm:ss') : values.value;
        }

        else {
            fieldValue = (fieldType === 'computedfield') ? (values.value >0 ? parseFloat(values.value).toFixed(2) : 0):values.value;
        }

        return fieldValue;

    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }
        return filePath;
    }
    renderChildFields(children, index){
        /*//////console.log(this.state.formData)
        let fieldSetVal = children.fil((item) => item.values ? item.values.length:0);
        let fieldSetCount = Math.max.apply(null, fieldSetVal) // 4
        //////console.log(children);
        //////console.log(fieldSetVal);
        //////console.log(fieldSetCount);*/
        var rows = [];
        //for (var i = 0; i < fieldSetCount; i++) {
        for(var j=0; j< children.length; j++) {
            rows.push(this.renderFields(children[j], j, 0 ));
        }
        //}
        return rows;

    }
    renderFields(field, index, childIndex) {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index):this.showFieldValue(field, index, childIndex);
    }

    render(){
        // let firstTemplate=this.props.formData.filter(item => (item.linked === 'No' &&  this.props.masterIds.includes(item.masterid)))
        let firstTemplate =this.props.formData.filter(item => (item.linked === 'No' &&  item.type !== 'fieldset'))
        // console.log(firstTemplate)

        let secondTemplate=this.props.formData.filter(item => (item.linked === 'No' &&  item.type === 'fieldset'))

        return(
            <div className="left-panel multi-card-record">
                <div className="fixed-container">
                     <div className="multi-card-record-fieldset-header">
                        <h5>Fixed Fields</h5>
                    </div>
                    <div className="right-panel-white-box">
                        <table className="table form-preview-table">
                            <tbody>
                            {firstTemplate.map((data,index) =>
                                <React.Fragment>

                                    {this.renderFields(data, index)}
                                </React.Fragment>
                            )}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-container">
                    {secondTemplate.map((data,index) =>
                        <div className={`container-list-new`}>
                            <div className="multi-card-record-fieldset-header">
                                <h5>{data.label}</h5>
                            </div>
                               {data.values !== null && data.values.map((list,i)=> {
                                    return(
                                        <>

                                            {data.values[i].values.map((lists,k)=>
                                                <>
                                                    {i === (data.values.length - 1) &&
                                                    <div className="right-panel-white-box  ">
                                                        {data.values.map((list, i) =>
                                                            <>
                                                           <FieldSets
                                                                label={list.label}
                                                                values={data.values[i].values[k] !==undefined? data.values[i].values[k].value:''}
                                                                collectionId = {this.props.collectionId ? this.props.collectionId : ''}
                                                                   recordId = {this.props.recordId ? this.props.recordId : ''}
                                                                   field = {data}
                                                                fields = {list}
                                                                showFormPreviousVersion={this.props.showFormPreviousVersion}
                                                                meta={this.props.meta}
                                                                formVersions={this.props.formVersions}
                                                                displaytextLabel = {data.values[i].values[k] !==undefined && data.values[i].values[k].type && data.values[i].values[k].type === 'displaytext'? data.values[i].values[k].label:''}
                                                                displaytextVal = {data.values[i].values[k] !==undefined && data.values[i].values[k].type && data.values[i].values[k].type === 'displaytext'? data.values[i].values[k].richtext:''}
                                                            />
                                                            </>
                                                        )}
                                                    </div>
                                                    }
                                                </>
                                            )}
                                        </>
                                    )

                                }

                            )}



                        </div>
                    )}

                </div>

            </div>
        )
    }

}


class Template6 extends React.Component{
    constructor(props){
        super(props)
        this.state={
            preview:false
        }
    }
    showFieldSetValue(field, index) {
        let children = field.values ?field.values:[];

        return <>
            {index !== 0 ?
                <div className="form-single-value-box">
                    <p>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</p>
                </div>
                :
                <p>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}</p>
            }

            <div className="form-single-value-box">
                <div className="scrollable">
                    <table className="table form-preview-table">
                        <tbody>
                        {children.length > 0 &&
                        this.renderChildFields(children, index)
                        }

                        </tbody>
                    </table>
                </div>
            </div>
        </>;
    }


    showFieldValue(field, index, childIndex,type) {
        let collectionId = this.props.collectionId ? this.props.collectionId : '';
        let recordId = this.props.recordId ? this.props.recordId : '';
        let fieldId = field.masterid ? field.masterid : '';
        let formMeta = this.props.meta && this.props.meta.version ? this.props.meta.version : 0;
        let formVersions = this.props.formVersions && this.props.formVersions.length > 0 && this.props.formVersions.includes(fieldId) ? true : false;

        if(field.type === "otp" || field.type === "payment") {
            return false
        }
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
        }
        if(type==="") {
            return <div className={`form-single-value-box ${field.type === 'url'?'file-reader':''} ${field.type === 'agree'?'with-check-instruction':''}` }>
                {field.type !== 'agree'&&
                <p>
                    {field.hasOwnProperty('fieldname')?field.fieldname:field.label}
                    {type}
                    {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                    <>
                        <div className="label-title-block">
                            <button className={'ico-lib-v1 prev-version'} onClick={(e) => this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}/>
                        </div>
                        <div className={`prev-version-highlight`}></div>
                    </>
                    }
                </p>
                }
                {field.type === 'formheading' ?
                    <td>&nbsp;</td>
                    :
                    field.type === 'text' && field.password === "Yes"?
                        <h5>*****</h5>
                        :
                        field.type === 'displaytext' || field.type === 'agree'  ?
                            <>
                                {field.type === 'agree' &&
                                <>

                                    <div className="select-chk-block" style={{"display": "block"}}>
                                        <label className="control control-checkbox">
                                            <input type="checkbox" className="inp-chk" id="text_searchable" name="dataview"
                                                   checked={field.values.length !== 0 && field.values[0].value === "yes"}/>
                                            <div className="control-indicator"></div>
                                        </label>
                                    </div>
                                    <p>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}{type}</p>
                                </>
                                }
                                <h5 dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></h5>



                            </>

                            :
                            field.type === 'attachment' ?
                                <h5>
                                    <ul className='attachment-list'>
                                        {field.values &&
                                        field.values.map((value, itemIndex) =>
                                            this.renderFieldType(value, field,field.values.length,itemIndex))}
                                    </ul>
                                </h5>
                                :
                                (field.type === 'text' || field.type === 'number' || field.type === 'textarea' || field.type === 'email' || field.type === 'videourl' || field.type === 'timepicker' || field.type === 'datepicker' || field.type === 'url' || field.type === 'pan') ?
                                    <h5>
                                        {field.values &&
                                        field.values.map((value, itemIndex) =>
                                            // this.renderFieldType(value, field,field.values.length,itemIndex)
                                            this.renderFieldType(value, field,field.values.length,itemIndex)
                                        )
                                        }
                                    </h5>
                                    :
                                    <h5>
                                        {field.values &&
                                        field.values.map((value, itemIndex) =>
                                            this.renderFieldType(value, field,field.values.length,itemIndex)
                                        )
                                        }
                                    </h5>
                }

            </div>;
        }
        else  if(type==="fieldset") {
            return <div className="col-tr">
                <div className="col-th">
                    {field.hasOwnProperty('fieldname')?field.fieldname:field.label}
                </div>
                {field.type === 'attachment' ?
                    <div className="col-td">
                        <ul className='attachment-list'>
                            {field.values &&
                            field.values.map((value, itemIndex) =>
                                this.renderFieldType(value, field,field.values.length,itemIndex))}
                        </ul>

                        {field.type === 'displaytext' &&
                        <h5 dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></h5>
                        }
                    </div>
                    :
                    field.type === 'datepicker' ?
                        <div className="col-td">
                            {field.values &&
                            field.values.map((value, itemIndex) =>
                                this.renderFieldType(value, field) + (itemIndex !== field.values.length - 1 ? "," : "")
                            )}
                            {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                            <>
                                <button className={'ico-lib-v1 prev-version'}
                                        onClick={(e) => this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                                <div className={`prev-version-highlight`}></div>
                            </>
                            }
                            {field.type === 'displaytext' &&
                            <h5 dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></h5>
                            }
                        </div>
                        :
                        field.type === 'preview' ?
                            <div className="col-td">
                                {field.values &&
                                field.values.map((value, itemIndex) =>
                                    this.renderFieldType(value, field,field.values.length,itemIndex )
                                )}
                            </div>
                        :
                        <div className="col-td">

                            {field.values &&
                            field.values.map((value, itemIndex) =>
                                this.renderFieldType(value, field.type,field.values.length,itemIndex) + (itemIndex !== field.values.length - 1 ? " " : "")
                            )}
                            {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                            <>
                                <button className={'ico-lib-v1 prev-version'}
                                        onClick={(e) => this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                                <div className={`prev-version-highlight`}></div>
                            </>
                            }
                            {field.type === 'displaytext' &&
                            <h5 dangerouslySetInnerHTML={{__html: atob(field.richtext)}}></h5>
                            }
                        </div>
                }
            </div>;



        }


        else{
            return <>
                <tr key={index}>
                    <th>{field.hasOwnProperty('fieldname')?field.fieldname:field.label}{type}{index}{childIndex}</th>
                    {field.type === 'formheading' ?
                        <td>&nbsp;</td>
                        :
                        <td > {field.values &&
                        field.values.map((value, itemIndex) =>
                            this.renderFieldType(value, field,field.values.length,itemIndex))}
                            {field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                            <>
                                <button className={'ico-lib-v1 prev-version'} onClick={(e)=>this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}></button>
                                <div className={`prev-version-highlight`}></div>
                            </>
                            }
                        </td>
                    }
                </tr>

            </>;

        }




    }
    renderFieldType = (values, field,length,index) => {
        let fieldType=field.type !== undefined ?field.type   : field
        let fieldValue = '';
        if(fieldType === 'attachment') {
            let uniqname = (values && values.uniqvalue) ? values.uniqvalue : '';
            let filename = values ? values.value : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileType = getFilePath(uniqname);
            var albumName = 'documents';
            if(filename !== ''){
                if (CustomFunctions.isImage(filename)) {
                    albumName = 'images'
                } else if (CustomFunctions.isVideo(filename)) {
                    albumName = 'videos'
                } else if (CustomFunctions.isAudio(filename)) {
                    albumName = 'audios'
                } else {
                    albumName = 'documents'
                }
            }

            if(uniqname !== '') {
                fieldValue =
                    <>
                        <li>
                            {fileType === 'image' ?
                                <>
                                    <a href="javascript:void(0);" className="attach-click"
                                       onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                       data-toggle="tooltip"
                                       data-placement="bottom" data-original-title="dummy">
                                        <img className="custom-image" src={FRMSIMG_PATH + uniqname}/>
                                    </a>
                                </>
                                :
                                fileType === 'video' || fileType === 'audio' ?
                                    <>
                                        <a href="javascript:void(0);" className="attach-click"
                                           onClick={(e) => this.props.preview(contentPath + uniqname, filename,albumName,'yes')}
                                           data-toggle="tooltip"
                                           data-placement="bottom" data-original-title="dummy">
                                            <div className={"format " + iconClass}>
                                                {iconClass}
                                            </div>
                                        </a>
                                    </>
                                    :
                                    <>

                                        {getFileExtension(filename) === "xlsx" ?

                                            <a className="attach-click"
                                               onClick={(e) => this.FilePreview(contentPath + uniqname, getFileExtension(filename))}
                                               data-toggle="tooltip"
                                               data-placement="bottom">
                                                <div className={"format " + iconClass}>
                                                    {iconClass}
                                                </div>
                                            </a>



                                            :getFileExtension(filename) === "docx" ?


                                            <a className="attach-click"
                                               onClick={(e) => this.FilePreview(contentPath + uniqname, getFileExtension(filename))}
                                               data-toggle="tooltip"
                                               data-placement="bottom">
                                                <div className={"format " + iconClass}>
                                                    {iconClass}
                                                </div>
                                            </a>


                                            : getFileExtension(filename) === "csv" ?

                                                    <a className="attach-click"
                                                       onClick={(e) => this.FilePreview(contentPath + uniqname, getFileExtension(filename))}
                                                       data-toggle="tooltip"
                                                       data-placement="bottom">
                                                        <div className={"format " + iconClass}>
                                                            {iconClass}
                                                        </div>
                                                    </a>

                                                : getFileExtension(filename) === "pdf" ?
                                                        <a className="attach-click"
                                                           onClick={(e) => this.FilePreview(contentPath + uniqname, getFileExtension(filename))}
                                                           data-toggle="tooltip"
                                                           data-placement="bottom">
                                                            <div className={"format " + iconClass}>
                                                                {iconClass}
                                                            </div>
                                                        </a>

                                                    :
                                                    <a className="attach-click"
                                                       onClick={(e) => this.FilePreview(contentPath + uniqname, getFileExtension(filename))}
                                                       data-toggle="tooltip"
                                                       data-placement="bottom">
                                                        <div className={"format " + iconClass}>
                                                            {iconClass}
                                                        </div>
                                                    </a>

                                        }










                                    </>
                            }
                        </li>
                    </>
            }
        }
        else if(fieldType === 'videocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let fileurl = contentPath+uniqname;

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    {isVideo(fileurl) &&

                    <VideoPlayer width="320" height="240"  fileName={uniqname}   src={FRMSVID_PATH+uniqname}/>
                    }
                </div>
            }

        }
        else if(fieldType === 'editor') {
            fieldValue = <div dangerouslySetInnerHTML={{__html: values.value}}></div>
        }else if(fieldType === 'signature') {
            fieldValue = <img className="custom-image" src={values.value}/>
        }
        else if(fieldType === 'rating') {
            let rateValarray = [];
            let rateVal = values.value;
            rateVal = rateVal && rateVal !== '' ? rateVal : 0;
            let count = 5;
            for(var i=0; i<count; i++){
                rateValarray.push(i)
            }
            fieldValue = <ul className={`rate-star ${field.ratingtype === 'bulb' ?  "bulb" : ""}`}>
                {rateValarray.map((opt, index) =>
                    <li className={`${index < rateVal ?  "active" : ""}`} key={index}></li>
                )}
            </ul>
        }
        else if(fieldType === 'preview') {
            let val = values.value;
            fieldValue = <div className="preview-component">
                {field.previewurldisplay === 'popup' ?
                    <div className="preview-popup">
                        <div className="field-button-block">
                            <button className="general-btn"
                                    onClick={(e) => this.props.previewCompModal(field)}>{field.fieldLabel ? field.fieldLabel : field.label}</button>
                        </div>
                    </div>
                    :
                    <div className="preview-embed">
                        <div className="preview-panel" style={{width:field.previewidth !== '' ? field.previewidth + 'px' : '100%', height:field.previewheight !== '' ? field.previewheight + 'px': '350px'}}>
                            {field.previewfileurl !== '' &&
                            <>
                                {getFileExtension(field.previewfileurl) === 'doc' || getFileExtension(field.previewfileurl) === 'xls' ?
                                    <iframe src={'https://docs.google.com/viewer?url='+ field.previewfileurl + '&embedded=true'} width="100%" height="100%" />
                                    :
                                    (getFileExtension(field.previewfileurl) === 'jpg' || getFileExtension(field.previewfileurl) === 'png' || getFileExtension(field.previewfileurl) === 'jpeg') ?
                                        <img src={field.previewfileurl}/>
                                        :
                                        <FileViewers
                                            url={field.previewfileurl}
                                            type={getFileExtension(field.previewfileurl)}
                                            height={field.previewheight}
                                        />
                                }
                            </>
                            }
                        </div>
                        <div className="field-button-block">
                            {field.previewbuttons.length !== 0 && field.previewbuttons.map((list, index) =>
                                <>
                                    {list.previewtext !== '' &&
                                    <button className="general-btn" style={{
                                        color: list.previewtextcolor,
                                        backgroundColor: list.previewbgcolor
                                    }}>{list.previewtext}</button>
                                    }
                                </>
                            )}
                        </div>

                    </div>
                }

                {field.previewagreerichtext !== '' &&
                <div className="agreement">
                    <div className="custom-check">

                        {field.previewenablecheckbox === 'Yes' &&
                        <div className="custom-checkbox">
                            <input type="checkbox" name={field.hasOwnProperty('fieldname')?field.fieldname:field.label} value={val} checked={val === "yes"}/>
                            <label></label>
                        </div>
                        }
                        <div className="custom-checkbox-text">
                            <p dangerouslySetInnerHTML={{__html: atob(field.previewagreerichtext)}} className=""></p>
                        </div>

                    </div>
                </div>
                }
            </div>
        }
        else if(fieldType === 'resources') {
            let resourceVal = values.value !== undefined && values.value !== '' ? JSON.parse(values.value) : [];
            fieldValue = <div class="form-resource-preview preview-mode">
                {/* <h5>Selected Resources</h5> */}
                <div className="form-resource-block">
                    {resourceVal.length > 0 && resourceVal.map((list, index) =>
                        <ResourcePreviewAttachments
                            item = {list}
                            index={index}
                            // preview={this.preview}
                        />


                    )}
                </div>
            </div>
        }
        else if(fieldType === 'imageurl') {
            let filenameArr = values.value ? values.value.split(".") :[];
            let filename = '';
            if(filenameArr.length > 0){
                filename = filenameArr[filenameArr.length-1];
            }
            fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(values.value, filename)}>
                <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={values.value}/>
            </div>
        }
        else if(fieldType === 'url') {
            let filename = values ? values.value : '';
            let iconClass = CustomFunctions.getFileIcon(filename);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);
            let externalLink = hasExtension(values.value);

            if(field.type !== undefined){
                if(getFileExtension(values.value) === "pdf" || getFileExtension(values.value) === "bmp" || getFileExtension(values.value) === "webm" ||  getFileExtension(values.value) === "doc" || getFileExtension(values.value) === "docx" || getFileExtension(values.value) === "mp4" || getFileExtension(values.value) === "mp3" || getFileExtension(values.value) === "jpg" || getFileExtension(values.value) === "jpeg" || getFileExtension(values.value) === "png" || getFileExtension(values.value) === "gif"
                    || getFileExtension(values.value) === "xls" || getFileExtension(values.value) === "xlsx" ||  getFileExtension(values.value) === "csv"){

                    fieldValue =<FileViewers
                        url={filename}
                        type={getFileExtension(values.value)}
                    />
                }
                else if(externalLink === true){
                    fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ",  " :"")
                }
                else{
                    fieldValue =  ((length-1) === index) ? <a target="_blank" href={values.value}>{values.value}</a> : <a target="_blank" href={values.value}>{values.value + (values.value !== '' ? ",  " :"")}</a>
                }
            }
            else{
                fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
            }
        }
        else if(fieldType === 'photocapture') {

            let uniqname = (values && values.uniqvalue)? values.uniqvalue : '';
            let iconClass = CustomFunctions.getFileIcon(uniqname);
            let contentPath = CustomFunctions.getAttachmentPath(iconClass);

            if( uniqname !== '') {
                fieldValue = <div onClick={(e) => CustomFunctions.forceDownload(contentPath + uniqname, uniqname)}>
                    <img className="custom-image" style={{marginRight:'10px', cursor:'pointer'}} src={FRMSIMG_PATH + uniqname}/>
                </div>
            }

        }
        else if (fieldType === 'checkbox' || fieldType === 'switch') {
            if(field.hasOwnProperty(`checkboxoption`) && field.checkboxoption ==="imageoption" ){
                fieldValue= <div className={`option-score-container`}>{((length-1) === index) ?<div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span> </div> : <div className={`option-with-score`}>  <img className="custom-image" src={values.value}/><span className={`weightage`}>Score : {values.weightage}</span></div> }</div>

            }
            else{
                fieldValue = ((length-1) === index) ?(values.value + (values.weightage !=="" && values.weightage !==undefined? ' (Score : ' + values.weightage + ')' : ""))  : (values.value + (values.weightage !==undefined && values.weightage !=="" ? ' (Score : ' + values.weightage + ')' :"") )+", " ;

            }
        }
        else if (fieldType === 'dropdown' ) {
            fieldValue = ((length-1) === index) ? values.value :values.value+",  ";
        }

        else if (fieldType === 'datepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                ((length-1) === index) ? moment.utc(values.value).local().format('DD-MM-YYYY hh:mm') : moment.utc(values.value).local().format('DD-MM-YYYY hh:mm') + (values.value !== '' ? ", " :"")
                :
                field.datetime && field.datetime === 'Yes' && values.value !== '' ?
                    ((length-1) === index) ? moment(values.value).format('DD-MM-YYYY hh:mm') : moment(values.value).format('DD-MM-YYYY hh:mm') + (values.value !== '' ? ", " :"")
                    :
                    values.value !== '' ?
                        ((length-1) === index) ? moment(values.value).format('DD-MM-YYYY') : moment(values.value).format('DD-MM-YYYY') + (values.value !== '' ? ", " :"")
                        :
                        ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
        }
        else if (fieldType === 'timepicker' ) {
            fieldValue = field.autocapture && field.autocapture === 'Yes' && values.value !== '' ?
                ((length-1) === index) ? moment.utc("2020-12-17 "+ values.value).local().format('HH:mm:ss') : moment.utc("2020-12-17 "+ values.value).local().format('HH:mm:ss') + (values.value !== '' ? ", " :"")
                :
                ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
        }
        else if (fieldType === 'computedfield' ) {
            fieldValue = values.value >0 ? parseFloat(values.value).toFixed(2) : 0;
        }

        else {
            fieldValue =  ((length-1) === index) ? values.value :values.value + (values.value !== '' ? ", " :"");
        }

        return fieldValue;

    }

    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }
        return filePath;
    }
    showFieldSetChildValue(field, index,childIndex,child) {
        let children = field.values ?field.values:[];
        return <>
            <div className={`col-table ${child?"sub-child":"" }`}>
                <div className="col-tr">
                    <div className="col-header ">
                        {field.hasOwnProperty('fieldname')?field.fieldname:field.label}
                    </div>
                </div>
                {children.length > 0 &&
                this.renderChildFields(children, index,"child")
                }
            </div>

        </>;
    }
    renderFieldsetChild(field, index, childIndex,type="",child) {
        return (field.type === "fieldset") ? this.showFieldSetChildValue(field, index,"child"+child,child) :this.showFieldValue(field, index, childIndex,type);
    }


    renderChildFields(children, index,child){
        var rows = [];
        for(var j=0; j< children.length; j++) {
            rows.push(this.renderFieldsetChild(children[j], index, j,"fieldset" ,child));
        }
        return rows;

    }
    renderFields(field, index, childIndex,type='') {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index) :this.showFieldValue(field, index, childIndex,type);
    }

    FilePreview=(url,type)=>{

        this.setState({url:url,type:type,preview:!this.state.preview})
    }
    render(){
        return(
            <>

            <div className={this.props.className}>

                {this.props.formData.filter(((item) => item.linked=== 'No' && item.type !=="otp" && item.type !== "payment")).map((data,index) =>
                    <div  className={`right-panel-white-box ${data.type === "fieldset"?"fieldset address-field" :""} ${data.type === "formheading"?"header" :""}`}>

                        {this.renderFields(data, index)}

                    </div>
                )}
            </div>

                {/*{this.state.preview &&*/}
                {/*<SimpleModal*/}
                {/*    Close={()=>this.setState({preview:!this.state.preview})}*/}
                {/*>*/}


                {/*    <FileViewers*/}

                {/*        url={this.state.url}*/}
                {/*        type={this.state.type}*/}


                {/*    />*/}

                {/*</SimpleModal>*/}
                {/*}*/}


                </>

        )
    }

}


class FieldSets extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {
        let collectionId = this.props.collectionId ? this.props.collectionId : '';
        let recordId = this.props.recordId ? this.props.recordId : '';
        let field = this.props.fields ? this.props.fields : '';
        let fieldId = field !== '' && field.masterid ? field.masterid : '';
        let formMeta = this.props.meta && this.props.meta.version ? this.props.meta.version : 0;
        let displaytextVal = this.props.displaytextVal;
        let formVersions = this.props.formVersions && this.props.formVersions.length > 0 && this.props.formVersions.includes(fieldId) ? true : false;
        // console.log(this.props)
        // let values = field.values ? field.values : '';
        // let fieldType = field.type ? field.type : '';
        // let fieldValue = '';
        // let fileType = fieldType === 'attachment' ? getFilePath(this.props.values) : 'documents';
        // let iconClass = CustomFunctions.getFileIcon(this.props.values);

        return( <div className="form-single-value-box ">
            {displaytextVal !== '' ?
            <>
                <p>{this.props.displaytextLabel}</p>
                <h5 dangerouslySetInnerHTML={{__html: atob(displaytextVal)}}/>
            </>
            :
            <>
                <p>{this.props.label}
                    {field !== '' && field.versioning && field.versioning === 'Yes' && formMeta > 1 && formVersions &&
                        <>
                        <div className="label-title-block">
                            <button className={'ico-lib-v1 prev-version'} onClick={(e) => this.props.showFormPreviousVersion(e, field, collectionId, recordId, fieldId)}/>
                        </div>
                            <div className={`prev-version-highlight`}/>
                        </>
                    }
                </p>
                <h5>
                    {this.props.values}
                </h5>
            </>
            }
        </div>)
    }

}


