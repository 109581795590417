import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
import {getProfile} from "../../../utilities/AuthService";
import Modal from "../../views/Modal";
import AddNewPhase from "./AddNewPhase";
import * as phaseActions from "../../../actions/Phases";
import * as usersActions from "../../../actions/Users";
import NoData from "../../views/NoData";
import AlertModal from "../../views/AlertModal";

class Phases extends Component {
    constructor(props) {
        super(props);
        this.state={
            Addopen:false,
            phaseID: 0,
            loading: 0,
            offset: 30,
            lastOffset: 0,
            search:'',
            clearList : false,
            PhasesList:[],
            Delopen:false,
            phasedeleteid:0

        }
        this.AddmodalOpen = this.AddmodalOpen.bind(this);
        this.getPhaseList = this.getPhaseList.bind(this);

        this.listenToScroll=this.listenToScroll.bind(this);

        this.deletePhase=this.deletePhase.bind(this);
        this.showDeletePhaseModal = this.showDeletePhaseModal.bind(this);
        this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
    }

    componentDidMount() {
        this.getPhaseList();
        // var user_data = { uid : getProfile().id, usertype : getProfile().usertype , srchval : '' };
        // this.props.users(user_data);
        this.props.mainClick('settings','/settings/phases');
        const container = document.querySelector('.table-all-wrapper');
        container.addEventListener('scroll', this.listenToScroll)

    }
    componentWillUnmount() {
        const container = document.querySelector('.table-all-wrapper');
        container.removeEventListener('scroll', this.listenToScroll)
    }
    listenToScroll = () => {
        const container = document.querySelector('.table-all-wrapper')
        let lastOffset = document.querySelector('.phaselist').childElementCount;
        //console.log(lastOffset)
        var scrollTop = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var offsetHeight = container.offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;

        //console.log(scrollTop+"----"+scrollHeight+"----"+offsetHeight+"-----"+contentHeight);

        if (contentHeight <= scrollTop) {
            this.setState({loading : 1});
            let lastOffset = this.state.lastOffset + 1;
            this.setState({lastOffset : lastOffset,clearList:false},
                function(){
                  var phaseParams  = {
                            uid :  getProfile().id,
                            usertype : getProfile().usertype,
                            search: this.state.search,
                            page : this.state.lastOffset + 1,
                            per_page: this.state.offset
                        }
                    this.props.updatephaseslist(phaseParams);

                })

        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.phaseDetails.phaseSaved !== this.props.phaseDetails.phaseSaved){
            var beta = this;
            setTimeout(function () {
                beta.getPhaseList();
                beta.AddmodalClose();
            }, 1000)


        }
        if ((prevProps.listAllPhaseData.searchValue !== this.props.listAllPhaseData.searchValue && (this.props.listAllPhaseData.searchValue!== '')) || (prevProps.listAllPhaseData.searchResult !== this.props.listAllPhaseData.searchResult )) {
            this.setState({
                PhasesList: this.props.listAllPhaseData.searchResult.data,
            });
        }
        else if ((prevProps.listAllPhaseData.searchValue !== this.props.listAllPhaseData.searchValue && ( this.props.listAllPhaseData.searchValue === '') ) || (prevProps.listAllPhaseData.phases !== this.props.listAllPhaseData.phases)) {

           this.setState({
                PhasesList: this.props.listAllPhaseData.phases.data,
                loading: 0
            });
        }
        if(prevProps.listAllPhaseData !== this.props.listAllPhaseData){
            this.setState({
                PhasesList: this.props.listAllPhaseData.phases.data,
                loading: 0
            });
        }
        if(prevProps.deletePhasedata.phaseDeleted !== this.props.deletePhasedata.phaseDeleted){
            var beta = this;
            setTimeout(function () {
                beta.getPhaseList();
            }, 1000)
            this.setState({Delopen: false})


        }
     }
    AddmodalOpen(e, pid){
        e.stopPropagation();
        if(pid){
            this.setState({phaseID : pid});
            var phaseParams  = {
                uid :  getProfile().id,
                usertype : getProfile().usertype,
                phaseId : pid
            }
            this.props.getphasedetails(phaseParams);

        }
        this.setState({Addopen : true});
    }

    AddmodalClose(){
        this.setState({Addopen : false , phaseID :0});
    }
    getPhaseList() {
        var phaseParams  = {
            uid :  getProfile().id,
            usertype : getProfile().usertype,
            search: this.state.search,
            page : this.state.lastOffset + 1,
            per_page: this.state.offset
        }
        this.props.phases(phaseParams);

    }
    showDeletePhaseModal(id){
        this.setState({phasedeleteid: id, Delopen: true });
    }
    deletePhase(id){
        this.setState({loading : 1})
        let phasedeleteid = this.state.phasedeleteid;

        let deletedddata = {phasedeleteid : phasedeleteid};
        this.props.deletePhase(deletedddata);
    }
    cancelDeleteModal(){
        this.setState({Delopen : false});

    }
    render() {
        let PhasesList = this.state.PhasesList;
        return (
            <React.Fragment>
            {PhasesList.length === 0 &&
            <div className='absolute-holder'>
                <NoData
                    msg={`No Phases added yet`}

                />
            </div>
            }
            <div className="table-all-wrapper">
                {(this.props.listAllPhaseData.loading || this.state.loading === 1 )&&
                <span className="Loader-holder">
                                <LoaderSvg/>
                            </span>
                }
                <div className="table-scrollable">
                    <table className="bsy-table project-listing-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody className="phaselist">
                        {PhasesList.map((list,index) => {
                                let description = decodeURIComponent(escape(atob(list.description)));
                                return(
                                    <tr key={"program-" + index}>
                                        <td className="high-widthed">
                                            <a>
                                                <div className="project-name-wrappe">
                                                    <div className="project-name pro-ico phase-ico">
                                                    <h5 className="pj-header-list">{list.phaseName}</h5>
                                                    <p dangerouslySetInnerHTML={{__html: ((((description.length > 100) ? (description.substr(0, 100)+'...') : description)))}} />
                                                    </div>
                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                            {(getProfile().id === list.creator) &&
                                            <div className="wf-btn-block">
                                                <button className="ico-lib wf-ed"
                                                        onClick={(e) => this.AddmodalOpen(e, list.phaseId)}
                                                        data-action="editworkflow"></button>
                                                <button className="ico-lib  wf-dt"
                                                        onClick={(e) => {
                                                            this.showDeletePhaseModal(list.phaseId)
                                                        }}></button>
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                    </table>
                </div>
                {this.state.Addopen &&
                <Modal title={(this.state.phaseID === 0 ? 'Create New Phase' : 'Update Phase')} Close ={this.AddmodalClose.bind(this)}>
                    <AddNewPhase
                        AddmodalClose = {this.AddmodalClose}
                        action={this.state.phaseID === 0 ? "create":"update"}
                    />

                </Modal>
                }
                {this.state.Delopen === true &&
                <AlertModal id="delete-vertical"
                            title="Are you sure want to delete?"
                            OK_button_text="Yes, Delete It"
                            OK_action={() => this.deletePhase()}
                            showModal = {this.state.Delopen}
                            CANCEL_action={() => this.cancelDeleteModal()}
                />
                }
                <div className={'float-button-box'} onClick={(e)=>this.AddmodalOpen(e, 0)}>
                    <span className={'icon-in icon-plus'}></span>
                </div>
            </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    listAllPhaseData:state.PhaseReducer.listallPhases,
    phaseDetails : state.PhaseReducer.SavePhase,
    deletePhasedata :state.PhaseReducer.DeletePhase
});
export default  withRouter(connect(mapStateToProps, {
    mainClick:rootActions.commonActions.MenuClick,
    phases:phaseActions.listallphases,
    users:usersActions.listUsers,
    getphasedetails : phaseActions.getphasedetails,
    updatephaseslist : phaseActions.updatephaseslist,
    deletePhase : phaseActions.deletePhase

})(Phases));
