import {combineReducers} from 'redux';
import {collections} from "../services/collections.services";

const listCollectionsdata = (state = {Collections: {data: []}, Group: '', Search: ''}, action) => {
    switch (action.type) {

        case "LIST_All_COLLECTIONS":
            return {
                ...state,
                Collections: {
                    data: action.listallcollections
                },
                Group: action.group,
                Search: action.search

            };
            break;


        default:
            return state;
    }
};

const listSelectedCollections = (state = {SelectedCollections: {results: [], data: [], values: []}}, action) => {

    switch (action.type) {

        case "LIST_SELECTED_COLLECTIONS":
            return {
                ...state,
                SelectedCollections: action.selectedcollections.data.results
            };
            break;

        default:
            return state;
    }
};
const csvImportData = (state = {}, action) => {

    switch (action.type) {

        case "IMPORT_CSV_UPDATE":
            return {
                ...state,
                csvstatus: action.csvstatus
            };
            break;

        default:
            return state;
    }
};
const listCalendarnDetaildata = (state = {
    facet: [],
    fieldview: [],
    showlist: [],
    flaglist: [],
    flagCategory: [],
    customtemplate_other: [],
    mobiletemplate_other: [],
    mobiletemplate_settings: [],
    totalchilds: 0,
    collData: [],
    collLabel: [],
    CollectionInfo: [],
    CollectionData: [],
    CollectionLabel: [],
    CollectionFilter: [],
    dashboard: [],
    workflow_filter: []
}, action) => {
    var _newstate;

    switch (action.type) {

        case "LIST_CALENDAR_DETAIL":
            var info = {"title": "", "description": ""};
            var labels = [];
            var labelsNew = []
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var collLabel = []
            var cnt = 0;
            var indx = 0;
            var results = action.listCollectionDetail.data.results;
            //console.log(results)
            var totalchilds = action.listCollectionDetail.data.results.totalchilds
            var facet = action.listCollectionDetail.data.results.facet
            var flaglist = action.listCollectionDetail.data.results.flaglist
            var flagCategory = action.listCollectionDetail.data.results.category
            var dashboard = action.listCollectionDetail.data.results.dashboard
            var horizontal_template = action.listCollectionDetail.data.results.horizontal_template
            var vertical_template = action.listCollectionDetail.data.results.vertical_template
            var mini_template = action.listCollectionDetail.data.results.mini_template
            var horizontalImage = action.listCollectionDetail.data.results.horizontalImage
            var verticalImage = action.listCollectionDetail.data.results.verticalImage
            var miniImage = action.listCollectionDetail.data.results.miniImage
            var mapTemplate = action.listCollectionDetail.data.results.map_template
            var customtemplate = action.listCollectionDetail.data.results.customtemplate
            var customtemplate_other = action.listCollectionDetail.data.results.customtemplate_other
            var mobiletemplate = action.listCollectionDetail.data.results.mobiletemplate
            var mobiletemplate_other = action.listCollectionDetail.data.results.mobiletemplate_other
            var mobiletemplate_settings = action.listCollectionDetail.data.results.mobiletemplate_settings
            var showlist = action.listCollectionDetail.data.results.showlist
            var fieldview = action.listCollectionDetail.data.results.fieldview
            var workflow_filter = action.listCollectionDetail.data.results.workflow_filter

            if (results.parentinfo[0] !== undefined) {
                info = results.parentinfo[0];
            }
            //let result = results.data[0].collectedform.map(a => a.label);
            if (results.data[0] !== undefined && results.data[0].collectedform) {
                labels = results.data[0].collectedform.map(a => a.label);
                // filter((a) => a.showlist == 'Yes')
            }
            if (results.data[0] !== undefined && results.data[0].collectedform) {
                labelsNew.push(results.data[0].collectedform[0]);
                // filter((a) => a.showlist == 'Yes')
            }
            if (results.data.length > 0) {
                results.data.map((entry, index) => {
                    collectionData[index] = {};
                    collectionData[index]['id'] = entry.id;
                    collectionData[index]['isStar'] = entry.isStar;
                    collectionData[index]['booking_end_time'] = entry.booking_end_time;
                    collectionData[index]['booking_start_time'] = entry.booking_start_time;
                    collectionData[index]['has_annotation'] = entry.has_annotation;
                    collectionData[index]['users'] = entry.users;
                    collectionData[index]['flags'] = entry.flags;
                    collectionData[index]['createdate'] = entry.createdate;
                    collectionData[index]['createuser'] = entry.createuser;
                    collectionData[index]['editPermissionusers'] = entry.editPermissionusers;
                    collectionData[index]['editassignedrecords'] = entry.editassignedrecords;
                    collectionData[index]['editgroup'] = entry.editgroup;
                    collectionData[index]['editownrecords'] = entry.editownrecords;
                    collectionData[index]['editrecordpermission'] = entry.editrecordpermission;
                    collectionIDs.push(entry.id);

                    collData[index] = (entry.collectedform[0])
                    if (collData[index]) {
                        collData[index]['id'] = entry.id;
                        collData[index]['users'] = entry.users;
                    }
                    if (entry.collectedform) {
                        entry.collectedform.map(a => {
                            //.filter((a) => a.showlist == 'Yes')
                            collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['value'] : '';
                            if (a.type === "attachment") {
                                collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                            }
                            /*else if(a.type==="dataload"){
                                if(collectionData[index][a.masterid] === '') {

                                }
                                collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                            }*/

                            if (a.searchable === 'Yes') {
                                if (collectionFilter[a.label] === undefined) {
                                    collectionFilter[a.label] = [];
                                }
                                cnt = collectionFilter[a.label].length;
                                collectionFilter[a.label][cnt] = a.value[0]['value'];
                            }
                        });
                    }
                });
            }
            _newstate = {...state};
            return {
                ...state,
                CollectionInfo: info,
                CollectionLabel: labels.slice(0, 5),
                CollectionData: collectionData,
                CollectionFilter: collectionFilter,
                collectionIDs: collectionIDs,
                collData: collData,
                collLabel: labelsNew,
                totalchilds: totalchilds,
                dashboard: dashboard,
                horizontaltemplate: horizontal_template,
                verticaltemplate: vertical_template,
                minitemplate: mini_template,
                verticalImage: verticalImage,
                miniImage: miniImage,
                horizontalImage: horizontalImage,
                maptemplate: mapTemplate,
                facet: facet.length !== 0 ? facet : _newstate["facet"],
                customtemplate: customtemplate,
                customtemplate_other: customtemplate_other,
                mobiletemplate: mobiletemplate,
                mobiletemplate_other: mobiletemplate_other,
                mobiletemplate_settings: mobiletemplate_settings,
                flaglist: flaglist,
                fieldview: fieldview.length !== 0 ? fieldview[0].fields : [],
                showlist: showlist,
                flagCategory: flagCategory,
                workflow_filter: workflow_filter
            };

            break;

        default:
            return state;
    }
};


const listCollectionDetaildata = (state = {
    facet: [],
    fieldview: [],
    showlist: [],
    flaglist: [],
    flagCategory: [],
    customtemplate_other: [],
    mobiletemplate_other: [],
    mobiletemplate_settings: [],
    totalchilds: 0,
    collData: [],
    collLabel: [],
    CollectionInfo: [],
    CollectionData: [],
    CollectionLabel: [],
    CollectionFilter: [],
    dashboard: [],
    workflow_filter: []
}, action) => {
    var _newstate;
    switch (action.type) {
        case "LIST_ENTITY_DETAIL":
            var info = {"title": "", "description": ""};
            var labels = [];
            var labelsNew = []
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var collLabel = []
            var cnt = 0;
            var indx = 0;
            var results = action.listCollectionDetail.data.results;
            //console.log(results)
            var totalchilds = action.listCollectionDetail.data.results.totalchilds
            var facet = action.listCollectionDetail.data.results.facet
            var flaglist = action.listCollectionDetail.data.results.flaglist
            var flagCategory = action.listCollectionDetail.data.results.category
            var dashboard = action.listCollectionDetail.data.results.dashboard
            var horizontal_template = action.listCollectionDetail.data.results.horizontal_template
            var vertical_template = action.listCollectionDetail.data.results.vertical_template
            var mini_template = action.listCollectionDetail.data.results.mini_template
            var long1template = action.listCollectionDetail.data.results.long1_temp || []
            var horizontalImage = action.listCollectionDetail.data.results.horizontalImage
            var verticalImage = action.listCollectionDetail.data.results.verticalImage
            var miniImage = action.listCollectionDetail.data.results.miniImage
            var mapTemplate = action.listCollectionDetail.data.results.map_template
            var customtemplate = action.listCollectionDetail.data.results.customtemplate
            var customtemplate_other = action.listCollectionDetail.data.results.customtemplate_other
            var mobiletemplate = action.listCollectionDetail.data.results.mobiletemplate
            var mobiletemplate_other = action.listCollectionDetail.data.results.mobiletemplate_other
            var mobiletemplate_settings = action.listCollectionDetail.data.results.mobiletemplate_settings
            var showlist = action.listCollectionDetail.data.results.showlist
            var fieldview = action.listCollectionDetail.data.results.fieldview
            var workflow_filter = action.listCollectionDetail.data.results.workflow_filter

            if (results.parentinfo[0] !== undefined) {
                info = results.parentinfo[0];
            }
            //let result = results.data[0].collectedform.map(a => a.label);
            if (results.data[0] !== undefined && results.data[0].collectedform) {
                labels = results.data[0].collectedform.map(a => a.label);
                // filter((a) => a.showlist == 'Yes')
            }
            if (results.data[0] !== undefined && results.data[0].collectedform) {
                labelsNew.push(results.data[0].collectedform[0]);
                // filter((a) => a.showlist == 'Yes')
            }
            if (results.data.length > 0) {
                results.data.map((entry, index) => {
                    collectionData[index] = {};
                    collectionData[index]['id'] = entry.id;
                    collectionData[index]['isStar'] = entry.isStar;
                    collectionData[index]['booking_end_time'] = entry.booking_end_time;
                    collectionData[index]['booking_start_time'] = entry.booking_start_time;
                    collectionData[index]['has_annotation'] = entry.has_annotation;
                    collectionData[index]['users'] = entry.users;
                    collectionData[index]['flags'] = entry.flags;
                    collectionData[index]['createdate'] = entry.createdate;
                    collectionData[index]['createuser'] = entry.createuser;
                    collectionData[index]['editPermissionusers'] = entry.editPermissionusers;
                    collectionData[index]['editassignedrecords'] = entry.editassignedrecords;
                    collectionData[index]['editgroup'] = entry.editgroup;
                    collectionData[index]['editownrecords'] = entry.editownrecords;
                    collectionData[index]['editrecordpermission'] = entry.editrecordpermission;
                    collectionIDs.push(entry.id);

                    collData[index] = (entry.collectedform[0])
                    if (collData[index]) {
                        collData[index]['id'] = entry.id;
                        collData[index]['users'] = entry.users;
                    }
                    if (entry.collectedform) {
                        entry.collectedform.map(a => {
                            //.filter((a) => a.showlist == 'Yes')
                            collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['value'] : '';
                            if (a.type === "attachment") {
                                collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                            }
                            /*else if(a.type==="dataload"){
                                if(collectionData[index][a.masterid] === '') {

                                }
                                collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                            }*/

                            if (a.searchable === 'Yes') {
                                if (collectionFilter[a.label] === undefined) {
                                    collectionFilter[a.label] = [];
                                }
                                cnt = collectionFilter[a.label].length;
                                collectionFilter[a.label][cnt] = a.value[0]['value'];
                            }
                        });
                    }
                });
            }
            _newstate = {...state};
            return {
                ...state,
                CollectionInfo: info,
                CollectionLabel: labels.slice(0, 5),
                CollectionData: collectionData,
                CollectionFilter: collectionFilter,
                collectionIDs: collectionIDs,
                collData: collData,
                collLabel: labelsNew,
                totalchilds: totalchilds,
                dashboard: dashboard,
                horizontaltemplate: horizontal_template,
                verticaltemplate: vertical_template,
                minitemplate: mini_template,
                long1template: long1template,
                verticalImage: verticalImage,
                miniImage: miniImage,
                horizontalImage: horizontalImage,
                maptemplate: mapTemplate,
                facet: facet.length !== 0 ? facet : _newstate["facet"],
                customtemplate: customtemplate,
                customtemplate_other: customtemplate_other,
                mobiletemplate: mobiletemplate,
                mobiletemplate_other: mobiletemplate_other,
                mobiletemplate_settings: mobiletemplate_settings,
                flaglist: flaglist,
                fieldview: fieldview.length !== 0 ? fieldview[0].fields : [],
                showlist: showlist,
                flagCategory: flagCategory,
                workflow_filter: workflow_filter
            };

            break;
        case "LOADMORE_ENTITY_DETAIL":
            var info = {"title": "", "description": ""};
            var labels = [];
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var cnt = 0;
            var indx = 0;
            var results = action.listEntityDetail.data.results;
            var totalchilds = action.listEntityDetail.data.results.totalchilds
            if (results.parentinfo[0] !== undefined) {
                info = results.parentinfo[0];
            }
            //let result = results.data[0].collectedform.map(a => a.label);
            if (results.data[0] !== undefined && results.data[0].collectedform) {
                labels = results.data[0].collectedform.map(a => a.label);
                // filter((a) => a.showlist == 'Yes')
            }

            if (results.data.length > 0) {
                results.data.map((entry, index) => {
                    collectionData[index] = {};
                    collectionData[index]['id'] = entry.id;
                    collectionData[index]['users'] = entry.users;
                    collectionIDs.push(entry.id);
                    collData[index] = (entry.collectedform[0])
                    collData[index]['id'] = entry.id;
                    collData[index]['users'] = entry.users;
                    collectionData[index]['isStar'] = entry.isStar;
                    collectionData[index]['has_annotation'] = entry.has_annotation;
                    collectionData[index]['flags'] = entry.flags;
                    collectionData[index]['createdate'] = entry.createdate;
                    collectionData[index]['booking_end_time'] = entry.booking_end_time;
                    collectionData[index]['booking_start_time'] = entry.booking_start_time;
                    collectionData[index]['createuser'] = entry.createuser;
                    collectionData[index]['editPermissionusers'] = entry.editPermissionusers;
                    collectionData[index]['editassignedrecords'] = entry.editassignedrecords;
                    collectionData[index]['editgroup'] = entry.editgroup;
                    collectionData[index]['editownrecords'] = entry.editownrecords;
                    collectionData[index]['editrecordpermission'] = entry.editrecordpermission;
                    if (entry.collectedform) {
                        entry.collectedform.map(a => {
                            //.filter((a) => a.showlist == 'Yes')
                            if (a.type === "attachment") {
                                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                                collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                            } else {
                                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined) ? a.value[0]['value'] : '';

                            }

                            if (a.searchable === 'Yes') {
                                if (collectionFilter[a.label] === undefined) {
                                    collectionFilter[a.label] = [];
                                }
                                cnt = collectionFilter[a.label].length;
                                collectionFilter[a.label][cnt] = a.value[0]['value'];
                            }
                        });
                    }
                });
            }

            _newstate = {...state};
            ////////console.log(_newstate)
            ////////console.log(_newstate.collectionData)
            ////////console.log(collectionData)
            _newstate['CollectionInfo'] = info;
            _newstate['CollectionLabel'] = labels.slice(0, 5);
            _newstate['CollectionData'] = _newstate.CollectionData.concat(collectionData);
            _newstate['CollectionFilter'] = collectionFilter;
            _newstate['collData'] = _newstate.collData.concat(collData);
            _newstate['collectionIDs'] = _newstate.collectionIDs.concat(collectionIDs);
            _newstate['totalchilds'] = totalchilds;
            _newstate['dashboard'] = action.listEntityDetail.data.results.dashboard

            return _newstate

            break;
        case "COLLECTION_ENTITY_MAP":
            _newstate = {...state};
            var info = {"title": "", "description": ""};
            var labels = [];
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var collLabel = []
            var cnt = 0;
            var indx = 0;
            var results = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.data : []
            var totalchilds = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.count : 0
            var scrollid = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.scrollid : ""
            var facet = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.facet : _newstate['facet']
            results.map((entry, index) => {
                collData[index] = {}
                collData[index] = entry
            });
            _newstate['scrollid'] = scrollid;
            _newstate['CollectionLabel'] = labels.slice(0, 5);
            _newstate['CollectionData'] = _newstate.CollectionData.concat(collectionData);
            _newstate['collData'] = collData;
            _newstate['CollectionFilter'] = collectionFilter;
            _newstate['collectionIDs'] = _newstate.collectionIDs.concat(collectionIDs);
            _newstate['totalchilds'] = totalchilds;
            _newstate['facet'] = facet;

            return _newstate

            break;
        case "LOAD_MORE_ENTITY_DETAIL_MAP":
            var info = {"title": "", "description": ""};
            var labels = [];
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var collLabel = []
            var cnt = 0;
            var indx = 0;
            //console.log(action.listCollectionDetail.data)
            var results = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.data : []
            var totalchilds = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.count : 0
            var scrollid = action.listCollectionDetail.data !== undefined ? action.listCollectionDetail.data.scrollid : ""

            results.map((entry, index) => {
                collData[index] = {}
                collData[index] = entry
            });

            _newstate = {...state};
            //   _newstate['CollectionInfo']= _newstate.info;
            _newstate['scrollid'] = scrollid;
            _newstate['CollectionLabel'] = labels.slice(0, 5);
            _newstate['CollectionData'] = _newstate.CollectionData.concat(collectionData);
            _newstate['collData'] = _newstate.collData.concat(collData);
            _newstate['CollectionFilter'] = collectionFilter;
            _newstate['collectionIDs'] = _newstate.collectionIDs.concat(collectionIDs);
            _newstate['totalchilds'] = totalchilds;
            //console.log(_newstate)
            return _newstate

            break;
        case "RESET_ENTITY_DATA":
            return {
                ...state,
                facet: [],
                totalchilds: 0,
                CollectionInfo: [],
                collData: [],
                collLabel: [],
                CollectionData: [],
                CollectionLabel: [],
                CollectionFilter: [],
                dashboard: []
            };
            break;

        default:
            return state;
    }
}
const listCollectionDetaildataGe = (state = {
    facet: [],
    fieldview: [],
    showlist: [],
    flaglist: [],
    flagCategory: [],
    customtemplate_other: [],
    mobiletemplate_other: [],
    mobiletemplate_settings: [],
    totalchilds: 0,
    collData: [],
    collLabel: [],
    CollectionInfo: [],
    CollectionData: [],
    CollectionLabel: [],
    CollectionFilter: [],
    dashboard: [],
    workflow_filter: [],
    loading: false
}, action) => {
    var _newstate;
    switch (action.type) {
        case "LIST_ENTITY_DETAILS_GE":
            var info = {"title": "", "description": ""};
            var labels = [];
            var labelsNew = []
            var collectionIDs = [];
            var collectionData = [];
            var collectionFilter = [];
            var collData = []
            var collLabel = []
            var cnt = 0;
            var indx = 0;
            var results = action.listCollectionDetail.data.results;
            //console.log(results)
            var totalchilds = action.listCollectionDetail.data.results.totalchilds
            var facet = action.listCollectionDetail.data.results.facet
            var flaglist = action.listCollectionDetail.data.results.flaglist
            var flagCategory = action.listCollectionDetail.data.results.category
            var dashboard = action.listCollectionDetail.data.results.dashboard
            var horizontal_template = action.listCollectionDetail.data.results.horizontal_template
            var vertical_template = action.listCollectionDetail.data.results.vertical_template
            var mini_template = action.listCollectionDetail.data.results.mini_template
            var horizontalImage = action.listCollectionDetail.data.results.horizontalImage
            var verticalImage = action.listCollectionDetail.data.results.verticalImage
            var miniImage = action.listCollectionDetail.data.results.miniImage
            var mapTemplate = action.listCollectionDetail.data.results.map_template
            var customtemplate = action.listCollectionDetail.data.results.customtemplate
            var customtemplate_other = action.listCollectionDetail.data.results.customtemplate_other
            var mobiletemplate = action.listCollectionDetail.data.results.mobiletemplate
            var mobiletemplate_other = action.listCollectionDetail.data.results.mobiletemplate_other
            var mobiletemplate_settings = action.listCollectionDetail.data.results.mobiletemplate_settings
            var showlist = action.listCollectionDetail.data.results.showlist
            var fieldview = action.listCollectionDetail.data.results.fieldview
            var workflow_filter = action.listCollectionDetail.data.results.workflow_filter

            if (results.parentinfo[0] !== undefined) {
                info = results.parentinfo[0];
            }
            //let result = results.data[0].collectedform.map(a => a.label);
            if (results.data[0] !== undefined && results.data[0].collectedform) {
                labels = results.data[0].collectedform.map(a => a.label);
                // filter((a) => a.showlist == 'Yes')
            }
            if (results.data[0] !== undefined && results.data[0].collectedform) {
                labelsNew.push(results.data[0].collectedform[0]);
                // filter((a) => a.showlist == 'Yes')
            }
            if (results.data.length > 0) {
                results.data.map((entry, index) => {
                    collectionData[index] = {};
                    collectionData[index]['id'] = entry.id;
                    collectionData[index]['isStar'] = entry.isStar;
                    collectionData[index]['booking_end_time'] = entry.booking_end_time;
                    collectionData[index]['booking_start_time'] = entry.booking_start_time;
                    collectionData[index]['has_annotation'] = entry.has_annotation;
                    collectionData[index]['users'] = entry.users;
                    collectionData[index]['flags'] = entry.flags;
                    collectionData[index]['createdate'] = entry.createdate;
                    collectionData[index]['createuser'] = entry.createuser;
                    collectionData[index]['editPermissionusers'] = entry.editPermissionusers;
                    collectionData[index]['editassignedrecords'] = entry.editassignedrecords;
                    collectionData[index]['editgroup'] = entry.editgroup;
                    collectionData[index]['editownrecords'] = entry.editownrecords;
                    collectionData[index]['editrecordpermission'] = entry.editrecordpermission;
                    collectionIDs.push(entry.id);

                    collData[index] = (entry.collectedform[0])
                    if (collData[index]) {
                        collData[index]['id'] = entry.id;
                        collData[index]['users'] = entry.users;
                    }
                    if (entry.collectedform) {
                        entry.collectedform.map(a => {
                            //.filter((a) => a.showlist == 'Yes')
                            collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['value'] : '';
                            if (a.type === "attachment") {
                                collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                                collectionData[index][a.masterid] = (a.value && a.value[0] !== undefined && a.value[0] !== null) ? a.value[0]['uniqvalue'] : '';
                            }
                            /*else if(a.type==="dataload"){
                                if(collectionData[index][a.masterid] === '') {

                                }
                                collectionData[index]["uniquevalue"] = (a.value && a.value[0] !== undefined) ? a.value[0]['uniqvalue'] : '';
                            }*/

                            if (a.searchable === 'Yes') {
                                if (collectionFilter[a.label] === undefined) {
                                    collectionFilter[a.label] = [];
                                }
                                cnt = collectionFilter[a.label].length;
                                collectionFilter[a.label][cnt] = a.value[0]['value'];
                            }
                        });
                    }
                });
            }
            _newstate = {...state};
            return {
                ...state,
                CollectionInfo: info,
                CollectionLabel: labels.slice(0, 5),
                CollectionData: collectionData,
                CollectionFilter: collectionFilter,
                collectionIDs: collectionIDs,
                collData: collData,
                collLabel: labelsNew,
                totalchilds: totalchilds,
                dashboard: dashboard,
                horizontaltemplate: horizontal_template,
                verticaltemplate: vertical_template,
                minitemplate: mini_template,
                verticalImage: verticalImage,
                miniImage: miniImage,
                horizontalImage: horizontalImage,
                maptemplate: mapTemplate,
                facet: facet.length !== 0 ? facet : _newstate["facet"],
                customtemplate: customtemplate,
                customtemplate_other: customtemplate_other,
                mobiletemplate: mobiletemplate,
                mobiletemplate_other: mobiletemplate_other,
                mobiletemplate_settings: mobiletemplate_settings,
                flaglist: flaglist,
                fieldview: fieldview.length !== 0 ? fieldview[0].fields : [],
                showlist: showlist,
                flagCategory: flagCategory,
                workflow_filter: workflow_filter
            };

            break;

        case "LOADER_UPDATE_STATUS_GE" :
            return {
                ...state,
                loading: action.status,
            };
        default:
            return state;
    }
};
const listCollectionFilterData = (state = {CollectionFilter: []}, action) => {
    switch (action.type) {
        case "LIST_COLLECTIONFILTER":
            var collectionFilter = [];
            var cnt = 0;
            var results = action.listCollectionFilter.data.results;

            if (results.data.length > 0) {
                results.data.map((entry, index) => {
                    if (entry.collectedform) {
                        entry.collectedform.filter((a) => a.showlist == 'Yes').map(a => {
                            if (a.searchable === 'Yes') {
                                if (collectionFilter[a.label] === undefined) {
                                    collectionFilter[a.label] = [];
                                }
                                cnt = collectionFilter[a.label].length;
                                if (collectionFilter[a.label].indexOf(a.value[0]['value']) <= -1) {
                                    collectionFilter[a.label][cnt] = a.value[0]['value'];
                                }
                            }
                        });
                    }
                });
            }

            return {
                ...state,
                CollectionFilter: collectionFilter,
            };

            break;


        default:
            return state;
    }
};
const saveMetaForm = (state = {metaformdata: [], loading: false}, action) => {
    switch (action.type) {
        case "LIST_ENTITY_DETAIL":
            return {
                ...state,
                metaformdata: action.metaformdata,
            };
            break;
        case "SAVE_FORM_LOADING":
            return {
                ...state,
                loading: action.loading
            };
            break;
        case "SAVE_METAFORM":
            return {
                ...state,
                metaformdata: action.metaformdata
            };
            break;
        default:
            return state;
    }
};
const showFormModal = (state = {
    _id: '',
    showformdata: [],
    template: 1,
    templateInfo: [],
    status: "",
    width: 960,
    thankyoustatus: "",
    thankyoucontent: "",
    thankyouimgcontent: "",
    profileimgcontent: "",
    thankyoulink: "",
    header: [],
    footer: [],
    auth: false,
    collaborator: true,
    pagelock: '',
    saveasdraft: 'no',
    published_status: false,
    getformcomponents: [],
    computedfield: [],
    buttonlist: [],
    rolesettings: [],
    quickform: false,
    thankyou_settings: [],
    showlist_enabled:[]
}, action) => {
    switch (action.type) {
        case "SHOW_ENTITY_META_FORM":
            return {
                ...state,
                _id: action._id,
                showformdata: action.showformdata,
                template: action.template,
                templateInfo: action.templateInfo,
                width: action.width,
                status: action.status,
                thankyoustatus: action.thankyoustatus,
                thankyoulink: action.thankyoulink,
                thankyoucontent: action.thankyoucontent,
                thankyoulinkfield: action.thankyoulinkfield,
                thankyouimgcontent: action.thankyouimgcontent,
                profileimgcontent: action.profileimgcontent,
                header: action.header,
                footer: action.footer,
                auth: action.auth,
                collaborator: action.collaborator,
                pagelock: action.pagelock,
                saveasdraft: action.saveasdraft,
                buttonlist: action.buttonlist,
                rolesettings: action.rolesettings,
                master_published: action.master_published,
                quickform: action.quickform,
                thankyou_settings: action.thankyou_settings,
                showlist_enabled:action.showlist_enabled
            };
            break;

        case "GET_ENTITY_METAFORM" :
            return {
                ...state,
                getformcomponents: action.getformdata,
            }
            break;
        case "GET_AGGREGATE_DATA" :
            return {
                ...state,
                aggData: action.aggData,
                refparams: action.refparams,
                computedfield: action.computedfield
            }
            break;
        default:
            return state;
    }
};

const getExternalForm = (state = {externalFields: {"values": [], "masterField": ''}}, action) => {
    switch (action.type) {
        case "GET_EXTERNAL_FORM_DATA" :
            return {
                ...state,
                externalFields: {values: action.formData, masterField: action.masterid},
            }

        default:
            return state;
    }
};

const getSavedForm = (state = {formSaved: {}, isFormChanged: false}, action) => {
    switch (action.type) {
        case "SAVE_FORM_DATA" :
            return {
                ...state,
                formSaved: action.formSaved,
                isFormChanged: !state.isFormChanged
            }

        default:
            return state;
    }
};

const manageoader = (state = {loading: 0}, action) => {
    switch (action.type) {
        case "CONTENT_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;


        default:
            return state;
    }
};
const sharedCollection = (state = {loading: 0}, action) => {
    switch (action.type) {
        case "SHARE_COLLECTION":
            return {
                ...state,
                shared: action.shared,
                msg: action.msg
            };
            break;


        default:
            return state;
    }
};

const getChangedCollectionData = (state = {collection_data_modified: []}, action) => {

    switch (action.type) {
        case "MODIFY_COLLECTION_RECORDS":
            return {
                ...state,
                collection_data_modified: action.deleteCollectionData
            };
            break;
        default:
            return state;
    }
};

const saveAssigndata = (state = {results: false}, action) => {

    switch (action.type) {
        case "SAVE_ASSIGN_DATA":
            return {
                ...state,
                results: !state.results
            };
            break;
        default:
            return state;
    }
};

const saveSharedata = (state = {results: false}, action) => {

    switch (action.type) {
        case "SAVE_SHARE_DATA":
            return {
                ...state,
                results: !state.results
            };
            break;
        default:
            return state;
    }
};

const saveCollection = (state = {collectionSaved: false}, action) => {
    switch (action.type) {
        case "COLLECTION_CREATED":
            return {
                ...state,
                collectionSaved: !state.collectionSaved
            };
            break;
        default:
            return state;
    }
};
const getFormData = (state = {formvalues: [], meta: [], wfinfo: [], versions: [], id: '', draft: false}, action) => {
    switch (action.type) {
        case "GET_FORM_DATA":
            return {
                ...state,
                formvalues: action.formvalues,
                meta: action.meta,
                wfinfo: action.wfinfo,
                versions: action.versions,
                id: action.id,
                draft: action.draft
            };
            break;
        case "RESET_FORM_DATA":
            return {
                ...state,
                formvalues: [],
                meta: [],
                versions: []
            };
            break;
        case "GET_FORM_DATA_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};

const collectionSettingsData = (state = {collectionsettings: [], loading: ''}, action) => {
    switch (action.type) {
        case "COLLECTION_SETTINGS":
            return {
                ...state,
                collectionsettings: action.collectionsettings ? action.collectionsettings : state.collectionsettings
            };
            break;
        case "COLLECTION_SETTINGS_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};
const filterDatadetails = (state = {filterData: []}, action) => {
    switch (action.type) {
        case "FORM_FILTER_DATA":
            return {
                ...state,
                filterData: action.filterData.data.results.data
            };
            break;
        default:
            return state;
    }
};

const EntityList = (state = {entityList: []}, action) => {
    // //////console.log(action)
    switch (action.type) {
        case "LIST_ENTITY":
            return {
                ...state,
                entityList: action.entityList
            };
            break;
        case "GET_ENTITY_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};

const CategoryList = (state = {categoryList: []}, action) => {
    switch (action.type) {
        case "LIST_CATEGORY":
            return {
                ...state,
                categoryList: action.categoryList.data.data.hits
            };
            break;
        case "GET_CATEGORY_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};
const collectionCreated = (state = {collectionCreated: []}, action) => {
    switch (action.type) {
        case "CREATE_COLLECTION":
            return {
                ...state,
                collectionCreated: action.collectionCreated
            };
            break;

        default:
            return state;
    }
};

const categoryCreated = (state = {categoryCreated: false, params: {}}, action) => {
    switch (action.type) {
        case "CATEGORY_CREATED":
            return {
                ...state,
                categoryCreated: !state.categoryCreated,
                params: action.params
            };
            break;
        default:
            return state;
    }
};
const savelinkingpermission = (state = {savelinkingpermission: false}, action) => {
    switch (action.type) {
        case "SAVE_LINKING_PERMISSIONS":
            return {
                ...state,
                savelinkingpermission: !state.savelinkingpermission
            };
            break;
        default:
            return state;
    }
};
const savadataSettings = (state = {savadatalevelSettings: false}, action) => {
    switch (action.type) {
        case "SAVE_DATA_SETTINGS":
            return {
                ...state,
                savadatalevelSettings: !state.savadatalevelSettings
            };
            break;
        default:
            return state;
    }
};
const saveFilters = (state = {saveFilters: false}, action) => {
    switch (action.type) {
        case "SAVE_DATA_FILTER":
            return {
                ...state,
                saveFilters: !state.saveFilters
            };
            break;
        default:
            return state;
    }
};
const saveRightPanels = (state = {saveRightPanel: false}, action) => {
    switch (action.type) {
        case "SAVE_RIGHT_PANELS":
            return {
                ...state,
                saveRightPanel: !state.saveRightPanel
            };
            break;
        default:
            return state;
    }
};
const Deleteentity = (state = {deletedEntity: []}, action) => {

    switch (action.type) {
        case "DELETE_ENTITY":
            return {
                ...state,
                deletedEntity: action.deleteEntity
            };
            break;
        default:
            return state;
    }
};
const Duplicateentity = (state = {duplicatedEntity: false}, action) => {
    switch (action.type) {
        case "DUPLICATE_ENTITY":
            return {
                ...state,
                duplicatedEntity: !state.duplicatedEntity
            };
            break;
        default:
            return state;
    }
};

const exportfield = (state = {data: [], meta: []}, action) => {
    switch (action.type) {
        case "EXPORT_DISPLAY_DATA":
            return {
                ...state,
                data: action.exportfield.data.results.data,
                meta: action.exportfield.data.results.meta,
            };
            break;
        default:
            return state;
    }
};

const GetCollectionConfig = (state = {
    getcollectionconfig: {
        dataview: {data: []},
        settings: {data: []},
        propagation: {inheritance: []}
    }, projects: []
}, action) => {
    //////console.log(action)
    switch (action.type) {
        case "ENTITY_CONFIQ":
            if (action.collectionconfig.data.dataview.data !== undefined) {
                return {
                    ...state,
                    getcollectionconfig: action.collectionconfig.data
                };
            } else {
                return {
                    ...state,
                    getcollectionconfig: {dataview: {data: []}, settings: {data: []}, propagation: {inheritance: []}},
                };

            }


            break;
        default:
            return state;
    }
};
const GetEntity = (state = {
    Entity: {data: [], total: 0},
    searchResult: {data: [], total: 0},
    group: "",
    Search: '',
    usertype: '',
    managecollection: "",
    searchEnable: false,
    collectionsubgroup: [],
    ListAllEntity: []
}, action) => {
    // //console.log(action)
    var _newState;
    switch (action.type) {
        case "ENTITY":
            return {
                ...state,
                Entity: {
                    data: action.Entity.data.data,
                    total: action.Entity.data.total
                },
                Search: action.search,
                group: action.group
            };
            break;
        case "LOAD_MORE_ENTITY":
            _newState = {...state};
            _newState['Entity'].data = _newState.Entity.data.concat(action.Entity.data.data);
            return _newState;
            break;

        case "SEARCH_ENTITY_DATA":
            return {
                ...state,
                searchResult: {
                    data: action.listallentity.data.data,
                    total: action.listallentity.data.total,
                    Search: action.search
                },
                Search: action.search,
                usertype: action.usertype,
                searchEnable: action.searchEnable,
                managecollection: action.managecollection,
                group: action.group
            };
            break;

        case "LOAD_MORE_SEARCH_ENTITY":
            _newState = {...state};
            _newState['searchResult'].data = _newState.searchResult.data.concat(action.Entity.data.data);
            return _newState;
            break;

        case "UPDATE_ENTITY":
            return {
                ...state,
                ListAllEntity: action.newEntity
            };
            break;
        case "GET_ENTITY_LOADER":
            return {
                ...state,
                loading: action.loading
            };
            break;
        default:
            return state;
    }
};
const GetEntityDetails = (state = {EntityDetails: []}, action) => {
    //// //////console.log(action.entityDetails)
    switch (action.type) {
        case "ENTITY_DETAILS":
            return {
                ...state,
                EntityDetails: action.entityDetails.data.results.data
            };
            break;
        default:
            return state;
    }
};
const GetLinkedData = (state = {linkedDetails: []}, action) => {
    // //////console.log(action.entityLinkedList)
    switch (action.type) {
        case "LINKED_ENTITY":
            return {
                ...state,
                linkedDetails: action.entityLinkedList.data.results.data
            };
            break;
        default:
            return state;
    }
};

const getCollectionTemplate = (state = {templateData: []}, action) => {
    switch (action.type) {
        case "COLLECTION_SAVE_TEMPLATE":
            return {
                ...state,
                templateData: action.collectionTemplate
            };
            break;
        default:
            return state;
    }
};

const listRule = (state = {generic: [], fieldwise: [], loader: false}, action) => {
    switch (action.type) {
        case "ENTITY_GET_RULE":
            let ruleData = action.getrule
            let generic = []
            let fieldwise = []
            let i = 0;
            let j = 0;
            ruleData.map((list) => {

                    if (list._source.model === "generic") {
                        generic[i] = list._source
                        generic[i]["id"] = list._id
                        if ((list._source.type === "Weekly" || list._source.type === "Yearly")) {
                            if (list._source.pattern !== "") {
                                // generic[i]["pattern"] = list._source.pattern.split(",")
                            } else {
                                generic[i]["pattern"] = []
                            }
                        }
                        i++
                    }
                    if (list._source.model === "fieldwise") {
                        fieldwise[j] = list._source
                        fieldwise[j]["id"] = list._id
                        if ((list._source.type === "Weekly" || list._source.type === "Yearly")) {
                            if (list._source.pattern !== "") {
                                //  generic[j]["pattern"] = list._source.pattern.split(",")
                            } else {
                                generic[j]["pattern"] = []
                            }
                        }
                        j++
                    }
                }
            )
            //////console.log(generic)
            return {
                ...state,
                generic: generic,
                fieldwise: fieldwise
            };
            break;

        case "ENTITY_RULE_LOADER":
            return {
                ...state,
                loader: action.loader,
            };
            break;
        default:
            return state;
    }
};


const getWorkflow = (state = {workflow: []}, action) => {
    switch (action.type) {
        case "LISTING_WORKFLOW":

            return {
                ...state,
                workflow: action.listingWorkflow,
            };

            break;
        default:
            return state;
    }
};
const getlinkedWorkflow = (state = {workflow: []}, action) => {
    switch (action.type) {
        case "LISTING_LINKED_WORKFLOW":
            return {
                ...state,
                workflow: action.linkedWorkflow,
            };
            break;
        default:
            return state;
    }
};

const getWorkflowStatus = (state = {workflow: [], generic: [], fieldwise: []}, action) => {
    switch (action.type) {
        case "DELETE_WORKFLOW":
            return {
                ...state,
                workflow: action.deletelinkedWorkflow,
            };
            break;
        case "DELETE_GENERIC_RULE":
            return {
                ...state,
                generic: action.deletegenericWorkflow,
            };
            break;
        case "DELETE_FIELDWISE_RULE":
            return {
                ...state,
                fieldwise: action.fieldwiseRuleDelted,
            };
            break;
        case "CREATE_GENERAL_RULE":
            return {
                ...state,
                fieldwise: action.createGenericRule,
            };
            break;
        case "SAVE_WORK_FLOW_RULE":
            return {
                ...state,
                workflow: action.saveWorkflowrule,
            };
            break;


        default:
            return state;
    }
};

const statusDeletedRecords = (state = {status: []}, action) => {
    switch (action.type) {
        case "DELETE_ALL_RECORDS_ENTITY":
            return {
                ...state,
                status: action.status,

            };
            break;

        default:
            return state;
    }
};

const LinkedId = (state = {param: ""}, action) => {
    switch (action.type) {
        case "LINKED_ID":
            return {
                ...state,
                param: action.param

            };
            break;

        default:
            return state;
    }
};

const linkedCollectionId = (state = {param: ""}, action) => {
    switch (action.type) {
        case "LINKED_COLLECTION_ID":
            return {
                ...state,
                param: action.param
            };
            break;

        default:
            return state;
    }
};
const LinkedCollectionNames = (state = {leadName: ""}, action) => {
    switch (action.type) {
        case "LINKED_COLLECTION_NAME":
            return {
                ...state,
                leadName: action.leadName
            };
            break;

        default:
            return state;
    }
};

const listAnnotation = (state = {listAnn: []}, action) => {
    switch (action.type) {
        case "LIST_ANNOTATIONS":
            return {
                ...state,
                listAnn: action.listAnn,
                loader: action.loader

            };
            break;

        default:
            return state;
    }
};


const saveAnnotation = (state = {saveAnn: []}, action) => {
    switch (action.type) {
        case "SAVE_ANNOTATIONS":
            return {
                ...state,
                saveAnn: action.saveAnn,

            };
            break;

        default:
            return state;
    }
};
const importCSVData = (state = {csvDatas: {data: []}, csvCodes: '', csvMsgs: ''}, action) => {

    switch (action.type) {

        case "IMPORT_CSV_GET_EN":
            return {
                ...state,
                csvDatas: action.importCSVData,
                csvCodes: action.importCSVData.code ? action.importCSVData.code : '',
                csvMsgs: action.importCSVData.message ? action.importCSVData.message : '',

            };
            break;

        default:
            return state;
    }
};
const invokeEntityWorkflowdata = (state = {status: []}, action) => {
    switch (action.type) {
        case "ENTITY_INVOKE_WORKFLOW":
            return {
                ...state,
                status: action.saveEnitityInvokeWorkflow.data,
            };
            break;
        default:
            return state;
    }
};
export const Entity = combineReducers({
    listCollectionsdata,
    listCollectionDetaildata,
    listCollectionDetaildataGe,
    listSelectedCollections,
    saveMetaForm,
    showFormModal,
    listCollectionFilterData,
    getChangedCollectionData,
    manageoader,
    sharedCollection,
    getExternalForm,
    getSavedForm,
    getFormData,
    EntityList,
    CategoryList,
    collectionCreated,
    categoryCreated,
    collectionSettingsData,
    filterDatadetails,
    exportfield,
    GetCollectionConfig,
    saveAssigndata,
    csvImportData,
    listCalendarnDetaildata,
    savelinkingpermission,
    saveFilters,
    saveRightPanels,
    savadataSettings,
    GetEntity,
    GetEntityDetails,
    saveCollection,
    Deleteentity,
    Duplicateentity,
    GetLinkedData,
    getCollectionTemplate,
    saveSharedata,
    listRule,
    getWorkflow,
    getlinkedWorkflow,
    getWorkflowStatus,
    statusDeletedRecords,
    LinkedId,
    linkedCollectionId,
    LinkedCollectionNames,
    listAnnotation,
    saveAnnotation,
    importCSVData,
    invokeEntityWorkflowdata
});

export default Entity;


