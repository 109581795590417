import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as taskActions from "../../../actions/ToDo";
import {getProfile} from "../../../utilities/AuthService";
import {getUrlSegment} from "../../../utilities/CustomFunctions";

import WorkFlowtaskDetailsIos from "../workflow-task-ios/WorkFlowtaskDetailsIos";
import rootActions from "../../../actions";
import LoaderSvg from "../../views/LoaderSVG";
class WorkflowContainerNew extends Component {

    componentDidMount() {

        this.props.mainClick('task','task/details');
        var taskid = (atob(getUrlSegment(3)));
        var mode = (atob(getUrlSegment(4)));
        this.setState({taskmode : mode});
        //this.props.gettaskdetails(taskid,'root',getUrlSegment(5),mode)
        let data = { _id : taskid, systype: "root", uid : getUrlSegment(5), mode: mode };
        this.props.gettaskdetails(data);
        this.props.getattchmentlist({"_id": taskid, "reference": "All"});
        this.props.getloglist({"_id": taskid, "type": "todo"});


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match !== this.props.match) {
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            this.setState({taskmode : mode});
            //this.props.gettaskdetails(taskid,'root',getUrlSegment(5),mode);

            let data = { _id : taskid, systype: 'user', uid : getUrlSegment(5), mode: mode };
            this.props.gettaskdetails(data);
        }
        if(this.props.modifyTask.task_modified !== prevProps.modifyTask.task_modified){
        //    alert("sfsfsdf");
            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            this.setState({taskmode : mode});
            let data = { _id : taskid, systype: "root", uid : getUrlSegment(5), mode: mode };
           this.props.gettaskdetails(data);
        }
    }

    render() {
        let taskData = this.props.taskdetailsData.taskdetails.parent;
        ////////////console.log(taskData)
        //let list = taskData.parent;
        ////////////console.log(list)
        return(
            <div className="right-content-wrapper" key={`right-content-wrapper`}>
                <div className="task-wrapper task-detail-stand" key={`task-wrapper task-detail-stand`}>
                    {this.props.taskdetailsData.loading &&
                    <span className="Loader-holder">
                        <LoaderSvg/>
                    </span>
                    }
                    {Object.entries(taskData).length > 0  &&
                        <React.Fragment key={"task-detail-type-1"}>

                            {(taskData.createdfrom === "workflow" || taskData.tasktype === "workflow")&&
                            <WorkFlowtaskDetailsIos
                                taskdetailsData={this.props.taskdetailsData}
                                attachmentData={this.props.attachmentData}
                                logData = {this.props.todologData}/>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    taskdetailsData : state.TaskReducer.TaskDetails,
    modifyTask:state.TaskReducer.ModifyTask,
    attachmentData : state.TaskReducer.Attachmentlist,
    todologData : state.TaskReducer.TodoLoglist

});

export default  withRouter(connect(mapStateToProps, {
    gettaskdetails:taskActions.taskdetails,
    mainClick:rootActions.commonActions.MenuClick,
    getattchmentlist : taskActions.getattchments,
    getloglist : taskActions.gettodologs
})(WorkflowContainerNew));
