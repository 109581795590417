import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as collectionActions from "../../../actions/Collections";
import * as WorkFlowActions from "../../../actions/WorkFlow";
import {FRMSIMG_PATH, FRMSDOC_PATH, FRMSVID_PATH, ES_INDEX, NEWVERSION} from "../../../constants";
import * as CustomFunctions from "../../../utilities/CustomFunctions";
import { formatDate, getUrlSegment, ruleCheck, forceDownload} from "../../../utilities/CustomFunctions"
import AlertModal from "../../views/AlertModal";
import AlertModalPool from "../../views/AlertModalPool";
import ShowFormModal from "../../views/ShowFormModal";
import LoaderSvg from "../../views/LoaderSVG";
import SimpleModal from "../../views/SimpleModal";
import * as taskActions from "../../../actions/ToDo";
import ShowFormPreview from "../../views/ShowFormPreview";

/********** Web wf task from site only ************/

import {WorkFlowTaskHeaderBlock,WorkFlowTaskDetailsBlock,SubmitBlock,CollectionBlock,FormDataBlock,DecisionBlock,NavTab,UsersPanelTab,PreviousStagesPanel, WorkflowOptionButton} from "./WorkFlowTaskDetailsData";
import {getProfile} from "../../../utilities/AuthService";
import ChatContainer from "../chat/ChatContainer"

/********** Web wf task from site only ************/

class WorkflowTaskDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabstate :"attach-tab",
            showStage : false,
            meta_data : [],
            decisionmeta_data:[],
            filename: null,
            uploadedFiles : [],
            submitted : false,
            validationErrormodal : false,
            proceedModal : false,
            proceed : false,
            nextstageid:'',
            // duedate: new Date(),
            fileType:'',
            file_name:'',
            selectedcollections:[],
            loading:0,
            saveloading:0,
            assigneeTaskStatus : '',
            showPreviewFormModal: false,
            showmarkasdoneModal : false,
            action:'',
            creator:'',
            proceedDecisionModal:false,
            metasubmitted : false,
            decision_nextstage:'',
            showAccordian:0,
            stageforwardtext:'',
            hiddenFields : [],
            readOnlyFields : [],
            writableFields : [],
            decisionmeta_data_result:[],
            showCollectionModal:false,
            showpreviewCollectionFormModal:false,
            showPreviewAlert:false,
            collection_id:'',
            quitPooltask : false,
            noDecisionAlert : false,
            submission_decision_statusid : '',
            showNoPermissionModal : false,
            closeworkflowparams : [],
            closeTaskOnFormSubmit : false,
            submissionFormId : ''

        }
        this.getTabstate = this.getTabstate.bind(this);
        this.showStages = this.showStages.bind(this);
        this.handleInputchange = this.handleInputchange.bind(this);
        this.handleDecisionInputchange = this.handleDecisionInputchange.bind(this);
        this.removeSelectedAttachment = this.removeSelectedAttachment.bind(this);
        this.showValidationErrormodal = this.showValidationErrormodal.bind(this);
        this.showProceedmodal = this.showProceedmodal.bind(this);
        this.hideProceedmodal = this.hideProceedmodal.bind(this);
        this.proceedSubmit = this.proceedSubmit.bind(this);
        this.submitWorkFlow = this.submitWorkFlow.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.submitWorkFlowAction = this.submitWorkFlowAction.bind(this);
        this.getUploadedFile = this.getUploadedFile.bind(this);

        this.showFormModal = this.showFormModal.bind(this);
        this.hideFormModal = this.hideFormModal.bind(this);
        this.previewForm = this.previewForm.bind(this);
        this.hidePreviewForm = this.hidePreviewForm.bind(this);
        this.getAttachmentPath = this.getAttachmentPath.bind(this);
        this.getFormValues = this.getFormValues.bind(this);

        this.submitWorkFlowDecision = this.submitWorkFlowDecision.bind(this);
        this.handleInputDecisionchange = this.handleInputDecisionchange.bind(this);
        this.handleDateDecisionchange = this.handleDateDecisionchange.bind(this);
        this.submitWorkFlowDecisionAction = this.submitWorkFlowDecisionAction.bind(this);
        this.showProceedDecisionmodal= this.showProceedDecisionmodal.bind(this);
        this.hideProceedDecisionmodal = this.hideProceedDecisionmodal.bind(this);
        this.openAccordian = this.openAccordian.bind(this);

        this.showCollectionModal = this.showCollectionModal.bind(this);
        this.hideCollectionModal = this.hideCollectionModal.bind(this);
        this.previewCollection = this.previewCollection.bind(this);
        this.hidePreviewCollection = this.hidePreviewCollection.bind(this);
        this.showPreviewAlert = this.showPreviewAlert.bind(this);
        this.hidePreviewAlert = this.hidePreviewAlert.bind(this);

        this.quitPoolTaskConfirm = this.quitPoolTaskConfirm.bind(this);
        this.hidePoolTaskConfirm = this.hidePoolTaskConfirm.bind(this);
        this.quitPoolTask = this.quitPoolTask.bind(this);

        this.processthroughapi = this.processthroughapi.bind(this);
        this.hideNoDecisionModal = this.hideNoDecisionModal.bind(this);
    }

    componentDidMount() {
        // this.setState({loading:1})
        var taskid = (atob(getUrlSegment(3)));
        this.setState({taskid : taskid});
        this.loadTaskInfoStagewise();

    }

    loadTaskInfoStagewise = () => {
        let stageid = this.props.taskdetailsData.taskdetails.parent.stageid;
        let workflowid = this.props.taskdetailsData.taskdetails.parent.workflowid;
        if(stageid && workflowid) {
            this.props.getstagetaskinfo({stageid: stageid, workflowid: workflowid});
        }
        let meta_data = this.props.taskdetailsData.taskdetails.parent.meta_form;
        this.setState({ meta_data : meta_data ,assigneeTaskStatus:this.props.taskdetailsData.taskdetails.parent.status});
        if(this.props.taskdetailsData.taskdetails.parent.has_metafilled && this.props.taskdetailsData.taskdetails.parent.has_metafilled === "yes"){
            this.setState({metasubmitted:true});
        }
        if(this.props.taskdetailsData.taskdetails.parent.status === "Done"){
            let wf_status_info = this.props.taskdetailsData.taskdetails.parent.wf_status_info;
            this.setState({decisionmeta_data_result : wf_status_info, decisionmade: wf_status_info ? wf_status_info.submission_statusid:''})
        }
        this.setForms();
        console.log(this.props.taskdetailsData.taskdetails.parent.submissionform)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.modifyTask.async_status !== this.props.modifyTask.async_status) {
            this.setState({ saveloading : 0});
            this.hideProceedDecisionmodal();
            this.hideProceedmodal();
        }


        if(this.props.getSaveMetaForm.metaformdata !== prevProps.getSaveMetaForm.metaformdata ) {
            this.hideProceedmodal();
            this.hideProceedDecisionmodal();
            this.setState({saveloading : 0});
            if(this.props.taskdetailsData.taskdetails.parent.submissiontype === 'withoutdecision' ) {
                this.processworkflow(this.state.closeworkflowparams);
            }
        }
        if(this.props.getProcessWorkFlowDetails.processWorkflow !== prevProps.getProcessWorkFlowDetails.processWorkflow ) {
            this.hideProceedmodal();
            this.hideProceedDecisionmodal();

            let list = this.props.taskdetailsData.taskdetails.parent;
            let assigneeTaskStatus = 'Inproggress'; //Instant updation

            let wfstatus = this.props.getProcessWorkFlowDetails.processWorkflowtask;
            //console.log(wfstatus)
            if(wfstatus.length > 0) {
                assigneeTaskStatus = wfstatus[0].taskdone && 'Done';
            }

            var taskid = (atob(getUrlSegment(3)));
            var mode = (atob(getUrlSegment(4)));
            /*if(list.poolenabled){ //Pool task completeion update
                let poolParams = {todoid : taskid, uid : getProfile().id};
                this.props.completePoolTask(poolParams);
            }*/

            /*let params = { _id : taskid, systype: "root", uid : getProfile().id, mode: mode };
            this.props.gettaskdetails(params);*/

            this.setState({saveloading : 0 , taskmode : mode, assigneeTaskStatus : assigneeTaskStatus});



            /*let verifytaskdata = {
                "taskid": taskid,
                "uid": getProfile().id,
                "index": ES_INDEX,
                "reference": 'markasdone',
                "consolidated_time":0,
                "timelog": [],
                "remarks": [{
                    "content": '',
                    "attachments": []
                }],
                "parentid": ""
            }
            this.props.TaskCompletionasync(verifytaskdata);*/

        }
        if(this.props.getFormdetails.formvalues !== prevProps.getFormdetails.formvalues) {
            this.getFormValues();
            this.setState({loading:0})
        }
        if(this.props.taskdetailsData.taskdetails !== prevProps.taskdetailsData.taskdetails) {
            console.log("000000000000000000000000000000000000")
            this.setForms();
            /*if(this.state.closeTaskOnFormSubmit){
                this.setCloseWFParams();
            }*/
        }
        if(prevProps.poolTasksData.todoPoolTaskQuit !== this.props.poolTasksData.todoPoolTaskQuit ) {
            this.hidePoolTaskConfirm()
            this.props.history.push("/task/pool/"+this.props.poolTasksData.poolId);

        }
        //////////console.log(this.props.stagetaskInfoDet)
        if(this.props.stagetaskInfoDet.StagetaskInfo.task !== prevProps.stagetaskInfoDet.StagetaskInfo.task && this.props.taskdetailsData.taskdetails.parent.submissiontype === 'withoutdecision' ){
            let nextstage = this.props.stagetaskInfoDet.StagetaskInfo.task.length > 0 ? this.props.stagetaskInfoDet.StagetaskInfo.task[0].nextstage : [];
            let decision_nextstage = nextstage.length > 0 ? nextstage[0] : '';
            this.setState({decision_nextstage : decision_nextstage})
            if(this.state.meta_data.length === 0) {
                this.setState({meta_data : this.props.stagetaskInfoDet.StagetaskInfo.metaform})
            }

        }

        if(this.props.formInfo.showformdata !== prevProps.formInfo.showformdata) {
            var formPages = this.props.formInfo.showformdata.pages;
            this.setState({formPages : formPages})

        }



    }

    setForms = () => {
        if(this.props.taskdetailsData.taskdetails) {
            let closeTaskOnFormSubmit = this.state.closeTaskOnFormSubmit;
            let submissionFormId ='';
            if(this.props.taskdetailsData.taskdetails.parent.submissiontype === 'withoutdecision' && this.props.taskdetailsData.taskdetails.parent.submissionform !== '' && this.props.taskdetailsData.taskdetails.parent.submissionform !== null){
                closeTaskOnFormSubmit = true;
                submissionFormId = this.props.taskdetailsData.taskdetails.parent.submissionform;
                this.setCloseWFParams();
            }
            var taskid = (atob(getUrlSegment(3)));
            this.setState({assigneeTaskStatus: this.props.taskdetailsData.taskdetails.parent.status, taskid: taskid, closeTaskOnFormSubmit : closeTaskOnFormSubmit, submissionFormId : submissionFormId});
            let collectionid = this.props.taskdetailsData.taskdetails.parent.collecteddata;
            if (collectionid !== '') {
                this.props.showform({_id: collectionid, uid : getProfile().id});
            }
        }
    }
    setCloseWFParams = () => {
        if(this.props.taskdetailsData.taskdetails) {
            var taskid = (atob(getUrlSegment(3)));

            let todoid = this.props.taskdetailsData.taskdetails.parent.masterid;
            // alert(todoid)
            let workflowid = this.props.taskdetailsData.taskdetails.parent.workflowid;
            let fieldid = this.props.taskdetailsData.taskdetails.parent.collecteddataid;
            let collectionid = this.props.taskdetailsData.taskdetails.parent.collecteddata;

            let nextstageid =  this.props.stagetaskInfoDet.StagetaskInfo.task.length > 0 ? this.props.stagetaskInfoDet.StagetaskInfo.task[0].decisionstage : this.props.taskdetailsData.taskdetails.parent.decisionstage;

            let currentstage = (this.props.taskdetailsData.taskdetails.parent.decisionstage=== "" ) ? 'final' :'';

            let closeworkflowparams = {previoustask : todoid, stageid: nextstageid, fieldid : fieldid, collectionid : collectionid, workflowid : workflowid, stage : currentstage, uid : getProfile().id, recordid : fieldid};
            if(currentstage === 'final' ) { // This is not for submission through api
                let parentid = this.props.taskdetailsData.taskdetails.parent.parent_id;

                closeworkflowparams = {taskid : todoid, uid : getProfile().id , reference : 'markasdone', parentid: parentid,   mode : "",  stage : currentstage, consolidated_time :'0','source' : 'workflow'};
            }



            this.setState({closeworkflowparams : closeworkflowparams, taskid: taskid});

        }
    }
    showPreviewAlert(){
        this.setState({showPreviewAlert: true})
    }
    hidePreviewAlert(){
        this.setState({showPreviewAlert: false})
    }
    getFormValues(){
        let selectedcollections = this.props.getFormdetails.formvalues ? this.props.getFormdetails.formvalues : [];
        this.setState({selectedcollections : selectedcollections});
    }

    getTabstate(id){
        this.setState({tabstate:id})
    }
    showStages(){
        this.setState({ showStage : !this.state.showStage});
        ////////////////console.log(this.state.showStage)

    }
    getTimeLogEntries(timeLogValues) {
        this.setState({ timeLogValues : timeLogValues });

    }
    handleDateChange(date, index){

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var

        meta_data[index]['value'] = meta_data[index]['value']?meta_data[index]['value']:[];

        meta_data[index]['value'] =[{value : formatDate(date)}];

        this.setState({
            [meta_data[index]['id']] : date
        });

        this.setState({meta_data : meta_data});

    }
    handleDateDecisionchange(date,index){
        var decisionmeta_data = [...this.state.decisionmeta_data]; // make a separate copy of the state var

        decisionmeta_data[index]['value'] = decisionmeta_data[index]['value']?decisionmeta_data[index]['value']:[];

        decisionmeta_data[index]['value'] =[{value : formatDate(date)}];

        this.setState({
            [decisionmeta_data[index]['id']] : date
        });

        this.setState({decisionmeta_data : decisionmeta_data});
    }

    handleInputchange(e, index , itemIndex){

        this.setState({
            [e.target.name] : e.target.value
        });

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var

        meta_data[index]['value'] = meta_data[index]['value']?meta_data[index]['value']:[];

        if(e.target.type === 'checkbox'){

            if(e.target.checked) {
                let optionVal = {value : '' };
                optionVal.value = e.target.value;
                meta_data[index]['value'].push(optionVal);
            }
        }
        else {
            meta_data[index]['value'] =[{value : e.target.value}];
        }
        this.setState({meta_data : meta_data});
    }


    handleInputDecisionchange(e,index){
        this.setState({
            [e.target.name] : e.target.value
        });

        var decisionmeta_data = [...this.state.decisionmeta_data]; // make a separate copy of the state var

        decisionmeta_data[index]['value'] = decisionmeta_data[index]['value']?decisionmeta_data[index]['value']:[];

        if(e.target.type === 'checkbox'){
            let optionVal = {value : '' };
            if(e.target.checked) {
                optionVal.value = e.target.value;
            }
            decisionmeta_data[index]['value'].push(optionVal);
        }
        else {
            decisionmeta_data[index]['value'] =[{value : e.target.value}];
        }

        this.setState({decisionmeta_data : decisionmeta_data});

    }

    handleDecisionInputchange(e,decisionmeta,nextstage,stageforwardtext, submission_statusid){
        let decision_nextstage = nextstage.length > 0 ? nextstage.toString() : '';
        //////////console.log(nextstage)
        if((nextstage.length > 0 && nextstage[0] === '') || nextstage.length === 0 ) {
            decision_nextstage = 'final';
        }
        this.setState({
            [e.target.name] : e.target.value,
            decisionmeta_data : decisionmeta,
            decision_nextstage : decision_nextstage,
            stageforwardtext : stageforwardtext,
            decisionSet : true,
            submission_decision_statusid : submission_statusid
        },function(){
            //////////console.log(this.state)
        });
    }

    getUploadedFile(uploadedFile, index) {

        let uploadedFiles = this.state.uploadedFiles;
        uploadedFiles.push(uploadedFile);
        this.setState({
            uploadedFiles : uploadedFiles
        });
        var element = document.getElementById("progress-bar-wrapper");
        element.parentNode.removeChild(element);

        let fileType = CustomFunctions.getFileIcon(uploadedFile.fileName);
        let extension = (fileType=== 'image')? '1':(fileType=== 'mp4' ? '2': '3');

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var

        meta_data[index]['value'] = meta_data[index]['value']?meta_data[index]['value']:[];

        let fileOptions = {
            extension : extension,
            uniqval : uploadedFile.fileName.split('/').pop(),
            value : uploadedFile.originalfileName,
            fileId : uploadedFile.fileId
        };

        meta_data[index]['value'].push(fileOptions);

        this.setState({meta_data : meta_data});

    }
	
	getUploadedFileDecision(uploadedFile, index) {

		let uploadedFiles = this.state.uploadedFiles;
		uploadedFiles.push(uploadedFile);
		this.setState({
			uploadedFiles : uploadedFiles
		});
		var element = document.getElementById("progress-bar-wrapper");
		element.parentNode.removeChild(element);

		let fileType = CustomFunctions.getFileIcon(uploadedFile.fileName);
		let extension = (fileType=== 'image')? '1':(fileType=== 'mp4' ? '2': '3');

		var decisionmeta_data = [...this.state.decisionmeta_data]; // make a separate copy of the state var

		decisionmeta_data[index]['value'] = decisionmeta_data[index]['value']?decisionmeta_data[index]['value']:[];

		let fileOptions = {
			extension : extension,
			uniqval : uploadedFile.fileName.split('/').pop(),
			value : uploadedFile.originalfileName,
			fileId : uploadedFile.fileId
		};

		decisionmeta_data[index]['value'].push(fileOptions);

		this.setState({decisionmeta_data : decisionmeta_data});

    }
    getAttachmentPath(type) {
        let filePath = FRMSIMG_PATH;
        switch(type) {
            case 'image' :  filePath = FRMSIMG_PATH;
                break;
            case 'mp4' : filePath = FRMSVID_PATH;
                break;
            default : filePath = FRMSDOC_PATH;
                break;
        }

        return filePath;
    }

    submitWorkFlowAction(e,id) {
        e.preventDefault();
        this.setState({
            submitted : true,
            metasubmitted : true
            //nextstageid :id
        });
        let formInvalid = 0;

        var meta_data = [...this.state.meta_data]; // make a separate copy of the state var


        if(meta_data.length > 0) {
            for(var i=0; i<meta_data.length; i++) {

                if (meta_data[i]['type'] === "loggedin") {
                    meta_data[i]['value'] =[{value : getProfile().first_name+" "+ getProfile().last_name}];
                }

                if (meta_data[i]['required'] === 'yes') {


                    if (meta_data[i]['type'] !== "attachment") {

                        if (!this.state[meta_data[i]['id']] || this.state[meta_data[i]['id']] === '') {

                            formInvalid++

                        }
                    } else {
                        if (this.state.uploadedFiles.length === 0) {

                            formInvalid++
                        }
                    }
                }
            }
        }
        this.setState({meta_data :meta_data})
        ////console.log(meta_data); //return false;


        if(formInvalid === 0){

            this.showProceedmodal();
        }
    }
    submitWorkFlow(){
        this.setState({saveloading : 1});
        let todoid = this.props.taskdetailsData.taskdetails.parent.masterid;
        // alert(todoid)
        let workflowid = this.props.taskdetailsData.taskdetails.parent.workflowid;
        let fieldid = this.props.taskdetailsData.taskdetails.parent.collecteddataid;
        let collectionid = this.props.taskdetailsData.taskdetails.parent.collecteddata;
        let childtasks = this.props.taskdetailsData.taskdetails.assignees.filter( a =>  a.assignee === getProfile().id);
        let creator = this.props.taskdetailsData.taskdetails.parent.createuser;

        let nextstageid =  this.props.stagetaskInfoDet.StagetaskInfo.task.length > 0 ? this.props.stagetaskInfoDet.StagetaskInfo.task[0].decisionstage : '';

        let currentstage = (this.props.taskdetailsData.taskdetails.parent.decisionstage=== "" ) ? 'final' :'';
        if(this.props.taskdetailsData.taskdetails.parent.submissiontype === 'throughapi' ) {
            nextstageid = this.props.stagetaskInfoDet.StagetaskInfo.task.length > 0 ? this.props.stagetaskInfoDet.StagetaskInfo.task[0].stageid : '';
            currentstage = '';
        }


        let closeworkflowparams = {previoustask : todoid, stageid: nextstageid, fieldid : fieldid, collectionid : collectionid, workflowid : workflowid, stage : currentstage, uid : getProfile().id, recordid : fieldid};
        if(currentstage === 'final' ) { // This is not for submission through api
            let parentid = this.props.taskdetailsData.taskdetails.parent.parent_id;

            closeworkflowparams = {taskid : todoid, uid : getProfile().id , reference : 'markasdone', parentid: parentid,   mode : "",  stage : currentstage, consolidated_time :'0','source' : 'workflow'};
        }

        this.setState({closeworkflowparams : closeworkflowparams})

        if(this.props.stagetaskInfoDet.StagetaskInfo.metaform.length > 0 ) {
            // If form exists
            let metaformdata = {
                "metaresult": this.state.meta_data,
                "uid": getProfile().id,
                "todoid": todoid
            }
            let formsubmission = this.props.taskdetailsData.taskdetails.parent.submissiontype;

            this.props.savemetaform(metaformdata, formsubmission, closeworkflowparams);

            var uploadedFiles = this.state.uploadedFiles;

            var uploadArray = [];

            if (uploadedFiles.length > 0) {
                for (var i = 0; i < uploadedFiles.length; i++) {
                    var uploadedFile = uploadedFiles[i];
                    var fileNameArr = uploadedFile.fileName.split('/');
                    var fileName = fileNameArr.pop();

                    var fileData = {
                        filetype: uploadedFile.fileType.startsWith('image') ? 'images' : (uploadedFile.fileType.startsWith('video') ? 'videos' : 'documents'),
                        filesize: uploadedFile.fileSize,
                        filetitle: uploadedFile.originalfileName,
                        filename: fileName
                    }

                    uploadArray.push(fileData);

                }

                var fileData = {
                    todoid: todoid,
                    uid: getProfile().id,
                    file: uploadArray,
                    refer: 'markasdone',
                    uniqid: new Date().getTime()
                }
                this.props.saveToDoAttachments(fileData);
                this.setState({metasubmitted:true});
            }
        }
        if(this.props.taskdetailsData.taskdetails.parent.meta_form.length === 0 && this.props.taskdetailsData.taskdetails.parent.submissiontype === 'withoutdecision' ) {
            this.processworkflow(closeworkflowparams);
        }
        /*if(this.props.taskdetailsData.taskdetails.parent.submissiontype === 'throughapi' ) {alert("2"); return false;
            this.props.processthroughapi(closeworkflowparams);
        }*/
        //this.proceedSubmit()

    }
    submitWorkFlowDecisionAction(e){
        e.preventDefault();
        if(this.props.taskdetailsData.taskdetails.parent.submissiontype === 'withdecision' ) {
            let nextstageid = this.state.decision_nextstage;
            if(nextstageid === '') {
                this.setState({noDecisionAlert : true});
            }
            else {
                this.setState({
                    decisionsubmitted : true,
                    //nextstageid :id
                });
                let formInvalid = 0;
                var decisionmeta_data = [...this.state.decisionmeta_data]; // make a separate copy of the state var

                if(decisionmeta_data.length > 0) {
                    for(var i=0; i<decisionmeta_data.length; i++) {

                        if (decisionmeta_data[i]['required'] === 'yes') {

                            if (decisionmeta_data[i]['type'] !== "attachment") {

                                if (!this.state[decisionmeta_data[i]['id']] || this.state[decisionmeta_data[i]['id']] === '') {

                                    formInvalid++

                                }
                            } else {
                                if (this.state.uploadedFiles.length === 0) {

                                    formInvalid++
                                }
                            }
                        }
                    }
                }

                if(formInvalid === 0){

                    this.showProceedDecisionmodal();


                }
            }

        }

    }

    hideNoDecisionModal() {
        this.setState({noDecisionAlert : false });
    }
    submitWorkFlowDecision(){
	this.setState({saveloading : 1});
        let todoid = this.props.taskdetailsData.taskdetails.parent.masterid;
        let workflowid = this.props.taskdetailsData.taskdetails.parent.workflowid;
        let fieldid = this.props.taskdetailsData.taskdetails.parent.collecteddataid;
        let collectionid = this.props.taskdetailsData.taskdetails.parent.collecteddata;
        let childtasks = this.props.taskdetailsData.taskdetails.assignees.filter(a => a.assignee === getProfile().id);
        let creator = this.props.taskdetailsData.taskdetails.parent.createuser;
        let nextstageid = this.state.decision_nextstage;

        let currentstage = (this.state.decision_nextstage === "" ) ? 'final' :'';
        let verifytaskdata = {};
        //let closeworkflowparams = {previoustask : todoid, stageid: nextstageid, fieldid : fieldid, collectionid : collectionid, workflowid : workflowid, stage : currentstage};

        let closeworkflowparams = {
            previoustask: todoid,
            stageid: nextstageid,
            fieldid: fieldid,
            collectionid: collectionid,
            workflowid: workflowid,
            uid: getProfile().id,
            statusinfo: {
                meta: this.state.decisionmeta_data,
                submission_status :this.state.stageforwardtext,
                submission_statusid: this.state.stageforward,
                submitted_user:getProfile().id,
            },
            stage : currentstage
        };
        if(currentstage === 'final' ) {
            let parentid = this.props.taskdetailsData.taskdetails.parent.parent_id;


            closeworkflowparams = {
                taskid : todoid,
                uid : getProfile().id,
                index : ES_INDEX,
                reference : 'markasdone',
                consolidated_time :0,
                timelog : [],
                remarks : [{
                    content : '',
                    attachments : []
                }],
                parentid : "",
                stage : currentstage,
                source : "workflow"
            }
            this.setState({verifytaskdata:closeworkflowparams})
        }


        this.processworkflow(closeworkflowparams);

    }

    processworkflow(closeworkflowparams){
        var todoid = closeworkflowparams.previoustask ? closeworkflowparams.previoustask : closeworkflowparams.taskid;
        var currentstage = closeworkflowparams.stage;
        var params = {
            previoustask: todoid,
            uid : getProfile().id,
            redis_value : [
            {
                meta: this.state.decisionmeta_data,
                submission_status :this.state.stageforwardtext,
                submission_statusid: this.state.stageforward,
                submitted_user:getProfile().id,
                taskdone : true
            }
        ]
        }
        closeworkflowparams.pool = this.props.taskdetailsData.taskdetails.parent.poolenabled;
        var asyncparams = {
            taskid : todoid,
            uid : getProfile().id,
            reference : 'markasdone',
            consolidated_time :0,
            timelog : [],
            remarks : [{
                content : '',
                attachments : []
            }],
            parentid : "",
            stage : currentstage,
            source : "workflow",
            poolenabled : this.props.taskdetailsData.taskdetails.parent.poolenabled
        }

        this.props.processworkflow(params, closeworkflowparams,asyncparams);

    }

    processthroughapi() {
        let todoid = this.props.taskdetailsData.taskdetails.parent.masterid;
        let currentstageid = this.props.taskdetailsData.taskdetails.parent.stageid;
        let fieldid = this.props.taskdetailsData.taskdetails.parent.collecteddataid;
        let collectionid = this.props.taskdetailsData.taskdetails.parent.collecteddata;
        let closeworkflowparams = {previoustask : todoid, stageid: currentstageid, recordid : fieldid, collectionid : collectionid, uid : getProfile().id};

        this.props.processthroughapi(closeworkflowparams);
    }
    removeSelectedAttachment(index) {
        let uploadedFiles = this.state.uploadedFiles;

        if(uploadedFiles) {
            uploadedFiles.splice(index, 1);
            this.setState({uploadedFiles: uploadedFiles});
        }
    }

    showValidationErrormodal(){
        this.setState({validationErrormodal : !this.state.validationErrormodal})
    }

    showProceedmodal(){
        this.setState({proceedModal : true})
    }
    hideProceedmodal(){
        this.setState({proceedModal : false})
    }
    showProceedDecisionmodal(){
        this.setState({proceedDecisionModal : true})
    }
    hideProceedDecisionmodal(){
        this.setState({proceedDecisionModal : false})
    }
    proceedSubmit(){
        this.setState({proceed : !this.state.proceed})
    }


    renderFields(field, index, childIndex) {
        return (field.type === "fieldset") ? this.showFieldSetValue(field, index) :this.showFieldValue(field, index, childIndex);
    }

    showFieldValue(field, index, childIndex) {
        childIndex = childIndex? childIndex : 0;
        if(childIndex > 0) {
            //////////////console.log(field.value[childIndex]);
        }

        return <tr key={index}>
            <td>{field.label}</td>
            {field.type === 'formheading' ?
                <td>&nbsp;</td>
                :
                <td> {field.linked === 'No' && field.value &&
                field.value.map((value, itemIndex) =>
                    this.renderFieldType(value, field.type)
                )
                }
                    { field.linked === 'Yes' && field.value && field.value.length > 0 &&
                    this.renderFieldType(field.value[childIndex], field.type)
                    }

                </td>}

        </tr>;
    }
    showFormModal(collectionid, formName, formDesc, collecteddataid, hiddenFields, readOnlyFields, writableFields, settingsType) {
        this.props.getFormData({_id : collecteddataid, metainfo: true, collectionid : collectionid});
        this.setState({
            formName : formName,
            formDesc : formDesc,
            collectionid : collectionid,
            collecteddataid : collecteddataid,
            showFormModal: true,
            hiddenFields : hiddenFields?hiddenFields:[],
            readOnlyFields : readOnlyFields?readOnlyFields:[],
            writableFields : writableFields? writableFields : [],
            settingsType : settingsType ? settingsType : '',
            submissionType : this.props.taskdetailsData.taskdetails.parent.submissiontype
        })
    }
    hideFormModal() {
        this.setState({
            showFormModal: false
        })
    }
   
    previewForm(id, collectionid,index) {
        this.props.getFormData({ _id : id, metainfo: true, collectionid : collectionid});
        this.setState({
            collectionid : collectionid,
            showPreviewFormModal : true,
            index:index,
            _id : id
        })
    }
    hidePreviewForm() {
        this.setState({
            showPreviewFormModal : false
        })
    }
    showNoPermissionModal=() =>{
        this.setState({
            showNoPermissionModal : true
        })
    }
    hideNoPermissionModal=() =>{
        this.setState({
            showNoPermissionModal : false
        })
    }

    renderFieldType(values, fieldType) {
        let fieldValue = '';
        if(fieldType === 'attachment') {
            let iconClass = CustomFunctions.getFileIcon(values.uniqvalue);
            let contentPath = this.getAttachmentPath(iconClass);
            fieldValue = <a href="javascript:void(0);" className="attach-click"
                            onClick={(e) => CustomFunctions.forceDownload(contentPath + values.uniqvalue, values.uniqvalue)}
                            data-toggle="tooltip"
                            data-placement="bottom" data-original-title="dummy">
                {iconClass !== 'image' &&
                <div className={"format " + iconClass}>
                    {iconClass}
                </div>
                }
                {iconClass === 'image' &&
                <img className="custom-image" src={FRMSIMG_PATH + values.uniqvalue}/>
                }

            </a>;
        }
        else {
            fieldValue = values.value;
        }

        return fieldValue;

    }

    showFieldSetValue(field, index) {

        let children = field.value[0].value;

        return <>
            <tr key={index}><td colSpan="2" style={{color: "#3FA9F5"}}>{field.label}</td></tr>
            {children.length > 0 &&
            this.renderChildFields(children, index)
            }
        </>;
    }


    renderChildFields(children, index){
        let childComponents = this.state.selectedcollections.filter((item)=> children.includes(item.masterid));
        let fieldSetVal = childComponents.map((item) => item.value ? item.value.length:0);
        let fieldSetCount = Math.max.apply(null, fieldSetVal) // 4

        var rows = [];
        for (var i = 0; i < fieldSetCount; i++) {
            for(var j=0; j< childComponents.length; j++) {
                rows.push(this.renderFields(childComponents[j], j, i ));
            }
        }
        return rows;

    }

    renderDecisionField=(rules,status,stat,submission_statusid)=> {
        let field = '';
        let fieldRules ={};
        let ruleTrigger='';
        let ruleValue='';
       // //////////console.log(rules)
        if(rules.length > 0) {
            for(var i=0; i<rules.length; i++) {
                let fieldItems = rules[i].fieldItem;
                for(var j=0; j<fieldItems.length; j++) {
                    fieldRules = fieldItems[j].field_rule;
                    field = fieldItems[j].field;
                    ////////////console.log(fieldRules);
                    // for(var k=0; k<fieldRules.length; k++){
                    //     ruleTrigger=fieldRules[k].trigger;
                    //     ruleValue=fieldRules[k].value;
                    // }
                }
            }
        }
        let valdata=(this.state.meta_data.filter(item => (item.id === field)))
        let val=""
        if(valdata.length !==0 && valdata[0].value !== undefined) {
             val = (this.state.meta_data.filter(item => (item.id === field)).map(item => item.value)[0].map(item => item.value));
        }
        //////////console.log(this.status)
        if(valdata.length !==0 && valdata !==undefined && fieldRules !== undefined && ruleCheck(val,fieldRules[0].value,fieldRules[0].trigger)) {
            return (
                <React.Fragment>

                    <li>
                        <div className={(stat._id===submission_statusid) ? " radio-block active" :"radio-block"} key={'status'}>
                            {/*{status}*/}
                            <input type="radio" id={"singlestep" + stat._id}
                                   className="off_type"
                                   name="stageforward" defaultValue={stat._id}
                                   onChange={(e) => this.handleDecisionInputchange(e,stat.meta,stat.nextstage,stat.status, submission_statusid)}
                                   defaultChecked={stat._id === submission_statusid}
                                   value={stat._id}

                            />
                            <label htmlFor={"singlestep" + stat._id}>{stat.status}</label>
                        </div>
                    </li>
                </React.Fragment>
            )
        }
        else{
            return null;
        }



    }
    openAccordian(e,index){
        e.preventDefault();
        if(this.state.showAccordian === index){
            index=-1;
        }
        this.setState({showAccordian:index})
    }


    showCollectionModal(collectionid, formName, formDesc) {
        // alert(collectionid);
        this.props.showform({ _id : collectionid, uid : getUrlSegment(5)},'internal');
        this.setState({
            formName : formName,
            formDesc : formDesc,
            collection_id : collectionid,
            showCollectionModal: true
        })
    }
    hideCollectionModal(id) {
        this.setState({
            showCollectionModal: false
        })
    }
    previewCollection(id, collectionid,index) {
        // alert(id)
        // alert(collectionid)
        this.props.getFormData({ _id : id, metainfo: true, collectionid : collectionid});
        this.setState({
            collection_id : collectionid,
            showpreviewCollectionFormModal : true,
            index:index,
            _id : id
        })
    }


    hidePreviewCollection() {
        this.setState({
            showpreviewCollectionFormModal : false
        })
    }
    quitPoolTaskConfirm(quit){
        this.setState({quitPooltask:!this.state.quitPooltask,
            //delete_action : deleteaction
            });
    }
    hidePoolTaskConfirm(){
        this.setState({quitPooltask:false
            //delete_action : deleteaction
        });
    }
    quitPoolTask() {
        let poolToDoId = atob(getUrlSegment(3));
        var data = {uid: getProfile().id, todoid : poolToDoId};
        this.props.quitTodoPoolTask(data);
    }
    render() {
        let taskData = this.props.taskdetailsData.taskdetails;
        let parentData = taskData.parent.meta_form;
        let assigneesData = ( this.props.taskdetailsData.taskdetails.assignees && this.props.taskdetailsData.taskdetails.assignees !== null) ? this.props.taskdetailsData.taskdetails.assignees : [] ;
        let logData = this.props.taskdetailsData.taskdetails.log;
        let commentData = this.props.taskdetailsData.taskdetails.comment;
        let allfilesData = this.props.taskdetailsData.taskdetails.allfiles;
        let subtaskData = this.props.taskdetailsData.taskdetails.subtask;
        let attachmentData = this.props.attachmentData.attachList;
        let collectionData = this.props.taskdetailsData.taskdetails.collection;
        let formSource = 'Task';
        /****************Rule based Pool Task - retrieving the filled form entry Begin *********/
        if(taskData.parent.has_collecteddata === 'yes') {
            formSource = 'TaskPool';
        }

        let previoustasksData = this.props.taskdetailsData.taskdetails.previoustasks;

        let selectedcollections = this.state.selectedcollections;

        let timelogData = this.props.timelogDetails.timelog.data;
        let previewableAttachments = attachmentData.filter(item => (item.filetype.startsWith('image') || item.filetype.startsWith('video')));
        let list = taskData.parent;
        ////////////console.log(this.props.stagetaskInfoDet.StagetaskInfo);
        let taskstatus = this.props.stagetaskInfoDet.StagetaskInfo.status;
		let indAssignee =  assigneesData.filter(item => item.assignee === getProfile().id);
		let taskstages = this.props.stagetaskInfoDet.StagetaskInfo.stages ? this.props.stagetaskInfoDet.StagetaskInfo.stages : [];
		let currentstageId = list.stageid;
		if( taskData.parent.submissiontype && ( taskData.parent.submissiontype === "throughapi" || taskData.parent.submissiontype === "withoutdecision")) {
            taskstatus = []
        }
		let step = 1;
       //////////console.log(this.state.stageforwardtext);
        return(
            <React.Fragment>
                {list &&
                <React.Fragment>
                    {this.props.taskdetailsData.loading &&
                    <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                    }
                    {this.state.loading === 1 &&
                    <span className="Loader-holder">
                                    <LoaderSvg/>
                                </span>
                    }
                    {this.state.saveloading === 1 &&
                    <span className="Loader-holder">
                                        <LoaderSvg/>
                                   </span>
                    }
                <div className="task-left workflow-task wf-task-left">
                        <div className="task-details-wrapper  wf-task-next niceScroll">
                            <WorkFlowTaskHeaderBlock
                                list={list}
                                showmarkasdoneModal={this.showmarkasdoneModal}
                                taskoptionbutton={<WorkflowOptionButton
                                    list={list}
                                    showReopenConfirm={this.showReopenConfirm}
                                    verified={this.state.verified}
                                    archived={this.state.archived}
                                    showConfirmArchive={this.showConfirmArchive}
                                    modalOpen={this.modalOpen}
                                    deleteTaskConfirm = {this.deleteTaskConfirm}
                                    handleDateChange = {this.handleDateChange}
                                    duedate = {this.state.duedate}
                                    quitPoolTaskConfirm={this.quitPoolTaskConfirm}
                                />}
                            />
                            <WorkFlowTaskDetailsBlock
                                list={list}
                                taskstages={taskstages}
                                currentstageId={currentstageId}
                            />

                            <SubmitBlock parentTask={list} previewForm={this.previewForm}
                                         showFormModal={this.showFormModal} step={step}
                                         formPages={this.state.formPages}
                                         hideFormModal={this.hideFormModal}
                                         showNoPermissionModal={this.showNoPermissionModal}/>

                            <CollectionBlock
                                has_collection={list.has_collection === "yes" ?true:false}
                                createUser={list.createuser}
                                collectionData={collectionData}
                                showCollectionModal={this.showCollectionModal}
                                previewCollection={this.previewCollection}
                                showPreviewAlert={this.showPreviewAlert}
                                step={list.has_collection ? step++ : ''}
                            />

                            {this.state.meta_data.length > 0 &&
                            <FormDataBlock
                                meta_data={this.state.meta_data}
                                handleInputchange={this.handleInputchange}
                                state={this.state}
                                getUploadedFile={this.getUploadedFile}
                                removeSelectedAttachment={this.removeSelectedAttachment}
                                submitWorkFlowAction={this.submitWorkFlowAction}
                                handleDateChange={this.handleDateChange}
                                submissiontype={this.props.taskdetailsData.taskdetails.parent.submissiontype}
                                indAssignee={indAssignee}
                                list={list}
                                quitPoolTaskConfirm={this.quitPoolTaskConfirm}
                                step={step++}
                                assigneeTaskStatus = {this.state.assigneeTaskStatus}
                            />
                            }

                            {taskData.parent.submissiontype && taskData.parent.submissiontype === "withdecision" &&
                            <DecisionBlock
                                taskstatus={taskstatus}
								decisionmeta_data={this.state.decisionmeta_data}
                                meta_data={this.state.meta_data}
                                handleDecisionInputchange={this.handleDecisionInputchange}
                                formdata={this.state}
                                submitWorkFlowDecisionAction={this.submitWorkFlowDecisionAction}
                                getUploadedFileDecision={this.getUploadedFileDecision}
                                removeSelectedAttachment={this.removeSelectedAttachment}
								handleInputDecisionchange={this.handleInputDecisionchange}
                                handleDateDecisionchange={this.handleDateDecisionchange}
                                submitWorkFlowDecision ={this.submitWorkFlowDecision}
                                renderDecisionField = {this.renderDecisionField}
								indAssignee={indAssignee}
                                decisionmeta_data_result={this.state.decisionmeta_data_result}
                                parent = {this.props.taskdetailsData.taskdetails.parent}
                                submissiontype={this.props.taskdetailsData.taskdetails.parent.submissiontype}
                                decisionSet={this.state.decisionSet}
                                quitPoolTaskConfirm={this.quitPoolTaskConfirm}
                                step = {step++}
                                submission_decision_statusid = {this.state.submission_decision_statusid}
                                assigneeTaskStatus = {this.state.assigneeTaskStatus}
                                />
                            }

                            {this.state.meta_data.length === 0 && taskData.parent.submissiontype && taskData.parent.submissiontype === "withoutdecision" && !this.state.closeTaskOnFormSubmit &&
                            <DecisionBlock
                                taskstatus={taskstatus}
                                decisionmeta_data={this.state.decisionmeta_data}
                                meta_data={this.state.meta_data}
                                handleDecisionInputchange={this.handleDecisionInputchange}
                                formdata={this.state}
                                submitWorkFlowAction={this.submitWorkFlowAction}
                                submitWorkFlowDecisionAction={this.submitWorkFlowDecisionAction}
                                getUploadedFileDecision={this.getUploadedFileDecision}
                                removeSelectedAttachment={this.removeSelectedAttachment}
                                handleInputDecisionchange={this.handleInputDecisionchange}
                                handleDateDecisionchange={this.handleDateDecisionchange}
                                submitWorkFlowDecision ={this.submitWorkFlowDecision}
                                renderDecisionField = {this.renderDecisionField}
                                indAssignee={indAssignee}
                                decisionmeta_data_result={this.state.decisionmeta_data_result}
                                parent = {this.props.taskdetailsData.taskdetails.parent}
                                submissiontype={this.props.taskdetailsData.taskdetails.parent.submissiontype}
                                decisionSet={this.state.decisionSet}
                                quitPoolTaskConfirm={this.quitPoolTaskConfirm}
                                step={step++}
                                submission_decision_statusid = {this.state.submission_decision_statusid}
                                assigneeTaskStatus = {this.state.assigneeTaskStatus}
                            />
                            }

                            {this.state.meta_data.length === 0 && taskData.parent.submissiontype && taskData.parent.submissiontype === "throughapi" &&
                            <DecisionBlock
                                taskstatus={taskstatus}
                                decisionmeta_data={this.state.decisionmeta_data}
                                meta_data={this.state.meta_data}
                                handleDecisionInputchange={this.handleDecisionInputchange}
                                formdata={this.state}
                                submitWorkFlowAction={this.processthroughapi}
                                submitWorkFlowDecisionAction={this.submitWorkFlowDecisionAction}
                                getUploadedFileDecision={this.getUploadedFileDecision}
                                removeSelectedAttachment={this.removeSelectedAttachment}
                                handleInputDecisionchange={this.handleInputDecisionchange}
                                handleDateDecisionchange={this.handleDateDecisionchange}
                                submitWorkFlowDecision ={this.submitWorkFlowDecision}
                                renderDecisionField = {this.renderDecisionField}
                                indAssignee={indAssignee}
                                decisionmeta_data_result={this.state.decisionmeta_data_result}
                                parent = {this.props.taskdetailsData.taskdetails.parent}
                                submissiontype={this.props.taskdetailsData.taskdetails.parent.submissiontype}
                                decisionSet={this.state.decisionSet}
                                quitPoolTaskConfirm={this.quitPoolTaskConfirm}
                                step={step++}
                                submission_decision_statusid = {this.state.submission_decision_statusid}
                                assigneeTaskStatus = {this.state.assigneeTaskStatus}
                            />
                            }
                        </div>
                    </div>
                    <div className="task-right">
                        <div className="task-extra-tab-block">
                            <div className="tasktab-scroll-div">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <NavTab
                                        getrightTabid={this.getTabstate}
                                        rightTab={this.state.tabstate}
                                        AcTab={'home-tab'}
                                        label={`Disscussion`}
                                        icon={`ico-lib disscussion`}

                                    />
                                </ul>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                {this.state.tabstate === "home-tab" &&
                                <div className="tab-pane fade show active" id="discussion" role="tabpanel"
                                     aria-labelledby="discussion-tab">
                                    <ChatContainer
                                        taskid={atob(getUrlSegment(3))}
                                        inviteUsers={true}
                                        assigneesData={assigneesData}/>
                                </div>
                                }
                                {this.state.tabstate === "user-tab" &&
                                <div className="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
                                    <div className="user-list-block">
                                        <UsersPanelTab
                                            assigneesData={assigneesData.filter(item => (item.share === 'no'))}
                                            title={`Assigned users`}
                                            type="assigned"
                                        />
                                        <UsersPanelTab
                                            assigneesData={assigneesData.filter(item => (item.share === 'yes'))}
                                            title={`Invited users`}
                                            type="invited"
                                        />
                                    </div>
                                </div>
                                }
                                {this.state.tabstate === "attach-tab" &&
                                <div className="tab-pane fade show active" id="attach" role="tabpanel"
                                     aria-labelledby="attach-tab">
                                    <div className="user-list-block">
                                       <PreviousStagesPanel
                                           previoustasksData={previoustasksData}
                                           openAccordian={this.openAccordian}
                                           getAttachmentPath = {this.getAttachmentPath}
                                           state={this.state}
                                       />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    {this.state.showFormModal &&

                    <ShowFormModal
                        formName={this.state.formName}
                        formDesc={this.state.formDesc}
                        collectionId ={this.state.collectionid}
                        referenceId ={this.state.taskid}
                        hideFormModal={this.hideFormModal}
                        hiddenFields = {this.state.hiddenFields}
                        readOnlyFields = {this.state.readOnlyFields}
                        writableFields = {this.state.writableFields}
                        settingsType = {this.state.settingsType}
                        editId = {this.state.collecteddataid}
                        formSource = "workflow"
                        meta_data = {this.state.meta_data}
                        submissionType = {this.props.taskdetailsData.taskdetails.parent.submissiontype}
                        processthroughapi = {this.processthroughapi}
                        closeTaskOnFormSubmit = {this.state.submissionFormId === this.state.collectionid ?  this.state.closeTaskOnFormSubmit : false}
                        closeworkflowparams = {this.state.closeworkflowparams}
                        submissionFormId = {this.state.submissionFormId}

                    />
                    }
                    {this.state.showPreviewFormModal &&
                    <ShowFormPreview
                        formName={this.state.formName}
                        collectionId ={this.state.collectionid}
                        previewForm={this.previewForm}
                        hidePreviewForm={this.hidePreviewForm}
                        state={this.state}
                        slideShow={this.showPreviewFormModal}
                        formSource={formSource}
                    />
                    }
                    {this.state.proceedModal &&
                    <AlertModal id="proceed"
                                title="Are you sure to proceed?"
                                OK_button_text="Yes, Proceed"
                                OK_action={() => this.submitWorkFlow()}
                                showModal = {this.state.proceedModal}
                                CANCEL_action={() => this.hideProceedmodal()}
                    />
                    }
                    {this.state.proceedDecisionModal &&
                    <AlertModal id="proceed"
                                title="Are you sure to proceed?"
                                OK_button_text="Yes, Proceed"
                                OK_action={() => this.submitWorkFlowDecision()}
                                showModal = {this.state.proceedDecisionModal}
                                CANCEL_action={() => this.hideProceedDecisionmodal()}
                    />
                    }

                    {this.state.showCollectionModal &&

                    <ShowFormModal
                        formName={this.state.formName}
                        formDesc={this.state.formDesc}
                        collectionId ={this.state.collection_id}
                        referenceId ={this.state.taskid}
                        hideFormModal={this.hideCollectionModal}
                        closeTaskOnFormSubmit = {this.state.submissionFormId === this.state.collection_id ?  this.state.closeTaskOnFormSubmit : false}
                        closeworkflowparams = {this.state.closeworkflowparams}
                        submissionFormId = {this.state.submissionFormId}
                    />
                    }
                    {this.state.showpreviewCollectionFormModal &&
                    <ShowFormPreview
                        formName={this.state.formName}
                        collectionId ={this.state.collection_id}
                        previewForm={this.previewCollection}
                        hidePreviewForm={this.hidePreviewCollection}
                        state={this.state}
                        slideShow={this.previewCollection}
                        formSource={formSource}
                    />
                    }

                    {this.state.quitPooltask &&
                    <AlertModalPool id="quit-pool"
                                title={"Pool Task"}
                                desc="Are you sure you want to quit this task?"
                                OK_button_text="Return"
                                OK_action={this.quitPoolTask}
                                showModal = {this.state.quitPooltask}
                                CANCEL_action={() => this.hidePoolTaskConfirm()}
                    />
                    }
                    {this.state.showPreviewAlert &&

                    <SimpleModal

                        Close={()=>this.hidePreviewAlert()}
                        title={`Preview`}
                        haveFooter={false}
                    >
                        No data found

                    </SimpleModal>

                    }

                    {this.state.noDecisionAlert &&

                    <AlertModalPool id="no-decision"
                                    title={"Workflow Decision"}
                                    desc="Please choose a decision"
                                    OK_button_text="Ok"
                                    OK_action={this.hideNoDecisionModal}
                                    showModal = {this.state.noDecisionAlert}
                                    CANCEL_button={`hide`}
                    />

                    }
                    {this.state.showNoPermissionModal &&
                    <AlertModalPool id="no-permission"
                                    title={"Preview From"}
                                    desc="You don't have the permission to view the form"
                                    OK_button_text="Ok"
                                    OK_action={this.hideNoPermissionModal}
                                    showModal={this.state.showNoPermissionModal}
                                    CANCEL_button={`hide`}
                    />
                    }

                </React.Fragment>

                }

            </React.Fragment>


        );
    }
}

const mapStateToProps = state => ({
    taskdetailsData : state.TaskReducer.TaskDetails,
    getFormdetails : state.CollectionsReducer.getFormData,
    getProcessWorkFlowDetails : state.workflowReducer.getProcessWorkFlow,
    getSaveMetaForm : state.CollectionsReducer.saveMetaForm,
    timelogDetails : state.TaskReducer.Gettimelog,
    stagetaskInfoDet : state.workflowReducer.StageTaskInfo,
    poolTasksData : state.TaskReducer.getPoolData,
    modifyTask : state.TaskReducer.ModifyTask,
    formInfo : state.CollectionsReducer.showFormModal
});

export default  withRouter(connect(mapStateToProps, {
    gettaskdetails:taskActions.taskdetails,
    getselectedcollections : collectionActions.getFormData,
    savemetaform : collectionActions.savemetaform,
    processworkflow : WorkFlowActions.ProcessWorkFlowtask,
    processthroughapi : WorkFlowActions.ProcessThroughApi,
    saveToDoAttachments : taskActions.saveToDoAttachments,
    getFormData : collectionActions.getFormData,
    getstagetaskinfo : WorkFlowActions.StageTaskInfoDet,
    TaskCompletionasync:taskActions.postTaskCompletionasync,
    showform : collectionActions.showform,
     quitTodoPoolTask : taskActions.quitTodoPoolTask,
    completePoolTask : taskActions.completePoolTask
})(WorkflowTaskDetails));
