import * as React from 'react';
import {Internationalization} from '@syncfusion/ej2-base';
import AlertModal from '../sc-views/AlertModal';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css";
import './Calendar.css';
import * as SchedulerActions from "../../../actions/Scheduler";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import {Component} from "react";
import {getProfile} from "../../../utilities/AuthService";
import moment from "moment";
import {ES_INDEX} from "../../../constants";
import Toaster from "../../views/Toaster";
import {getUrlSegment} from "../../../utilities/CustomFunctions"
import {getAllSlots} from "../../../actions/Scheduler";


class BookingCalendar extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            hostId: 0,
            userRole: '',
            attendeeId: '',
            eventId: '',
            ppeid: '',
            emailId: '',
            month: '',
            subject: '',
            permissions: {},
            showDialog: false,
            dialog: '',
            meridiem: 'AM',
            holidayList: [],
            leaveList: [],
            slotIntrvl: 30,
            patientId: '',
            postEventId: '',
            currentEventId: '',
            slots: [
                {time: "00:00:00-00:30:00", slot: "00.00 AM - 00.30 AM", m: "AM"},
                {time: "00:30:00-01:00:00", slot: "00.30 AM - 01.00 AM", m: "AM"},
                {time: "01:00:00-01:30:00", slot: "01.00 AM - 01.30 AM", m: "AM"},
                {time: "01:30:00-02:00:00", slot: "01.30 AM - 02.00 AM", m: "AM"},
                {time: "02:00:00-02:30:00", slot: "02.00 AM - 02.30 AM", m: "AM"},
                {time: "02:30:00-03:00:00", slot: "02.30 AM - 03.00 AM", m: "AM"},
                {time: "03:00:00-03:30:00", slot: "03.00 AM - 03.30 AM", m: "AM"},
                {time: "03:30:00-04:00:00", slot: "03.30 AM - 04.00 AM", m: "AM"},
                {time: "04:00:00-04:30:00", slot: "04.00 AM - 04.30 AM", m: "AM"},
                {time: "04:30:00-05:00:00", slot: "04.30 AM - 05.00 AM", m: "AM"},
                {time: "05:00:00-05:30:00", slot: "05.00 AM - 05.30 AM", m: "AM"},
                {time: "05:30:00-06:00:00", slot: "05.30 AM - 06.00 AM", m: "AM"},
                {time: "06:00:00-06:30:00", slot: "06.00 AM - 06.30 AM", m: "AM"},
                {time: "06:30:00-07:00:00", slot: "06.30 AM - 07.00 AM", m: "AM"},
                {time: "07:00:00-07:30:00", slot: "07.00 AM - 07.30 AM", m: "AM"},
                {time: "07:30:00-08:00:00", slot: "07.30 AM - 08.00 AM", m: "AM"},
                {time: "08:00:00-08:30:00", slot: "08.00 AM - 08.30 AM", m: "AM"},
                {time: "08:30:00-09:00:00", slot: "08.30 AM - 09.00 AM", m: "AM"},
                {time: "09:00:00-09:30:00", slot: "09.00 AM - 09.30 AM", m: "AM"},
                {time: "09:30:00-10:00:00", slot: "09.30 AM - 10.00 AM", m: "AM"},
                {time: "10:00:00-10:30:00", slot: "10.00 AM - 10.30 AM", m: "AM"},
                {time: "10:30:00-11:00:00", slot: "10.30 AM - 11.00 AM", m: "AM"},
                {time: "11:00:00-11:30:00", slot: "11.00 AM - 11.30 AM", m: "AM"},
                {time: "11:30:00-12:00:00", slot: "11.30 AM - 12.00 PM", m: "AM"},
                {time: "12:00:00-12:30:00", slot: "12.00 PM - 12.30 PM", m: "PM"},
                {time: "12:30:00-13:00:00", slot: "12.30 PM - 01.00 PM", m: "PM"},
                {time: "13:00:00-13:30:00", slot: "01.00 PM - 01.30 PM", m: "PM"},
                {time: "13:30:00-14:00:00", slot: "01.30 PM - 02.00 PM", m: "PM"},
                {time: "14:00:00-14:30:00", slot: "02.00 PM - 02.30 PM", m: "PM"},
                {time: "14:30:00-15:00:00", slot: "02.30 PM - 03.00 PM", m: "PM"},
                {time: "15:00:00-15:30:00", slot: "03.00 PM - 03.30 PM", m: "PM"},
                {time: "15:30:00-16:00:00", slot: "03.30 PM - 04.00 PM", m: "PM"},
                {time: "16:00:00-16:30:00", slot: "04.00 PM - 04.30 PM", m: "PM"},
                {time: "16:30:00-17:00:00", slot: "04.30 PM - 05.00 PM", m: "PM"},
                {time: "17:00:00-17:30:00", slot: "05.00 PM - 05.30 PM", m: "PM"},
                {time: "17:30:00-18:00:00", slot: "05.30 PM - 06.00 PM", m: "PM"},
                {time: "18:00:00-18:30:00", slot: "06.00 PM - 06.30 PM", m: "PM"},
                {time: "18:30:00-19:00:00", slot: "06.30 PM - 07.00 PM", m: "PM"},
                {time: "19:00:00-19:30:00", slot: "07.00 PM - 07.30 PM", m: "PM"},
                {time: "19:30:00-20:00:00", slot: "07.30 PM - 08.00 PM", m: "PM"},
                {time: "20:00:00-20:30:00", slot: "08.00 PM - 08.30 PM", m: "PM"},
                {time: "20:30:00-21:00:00", slot: "08.30 PM - 09.00 PM", m: "PM"},
                {time: "21:00:00-21:30:00", slot: "09.00 PM - 09.30 PM", m: "PM"},
                {time: "21:30:00-22:00:00", slot: "09.30 PM - 10.00 PM", m: "PM"},
                {time: "22:00:00-22:30:00", slot: "10.00 PM - 10.30 PM", m: "PM"},
                {time: "22:30:00-23:00:00", slot: "10.30 PM - 11.00 PM", m: "PM"},
                {time: "23:00:00-23:30:00", slot: "11.00 PM - 11.30 PM", m: "PM"},
                {time: "23:30:00-24:00:00", slot: "11.30 PM - 12.00 AM", m: "PM"}
            ],
            slotsAvailable: [],
            editorProps: {},
            t: new Date().getTime(),
            id: '',
            events: [],
            bookingDt: new Date(),
            bookingSlot: '',
            prevBookingSlot: '',
            bookingMerdm: 'AM',
            bookingMerdiem: 'AM',
            showAlertModal: false,
            showDeleteAlertModal: false,
            modalId: 0,
            delEventType: '',
            alertMessage: '',
            alertButtons: true,
            argsData: {},
            header: [],
            currentView: 'Month',
            setPanel: false,
            workDays: [
                {id: "0", value: 'Sunday', isChecked: true},
                {id: "1", value: 'Monday', isChecked: true},
                {id: "2", value: 'Tuesday', isChecked: true},
                {id: "3", value: 'Wednesday', isChecked: true},
                {id: "4", value: 'Thursday', isChecked: true},
                {id: "5", value: 'Friday', isChecked: true},
                {id: "6", value: 'Saturday', isChecked: true},
            ],
            wD: [1, 2, 3, 4, 5],
            workHours: {workTime: {startTime: '10:00 AM', endTime: '5:00 PM'}, breakTime: []},
            holidayTitle: "",
            holidayStartTime: new Date(),
            holidayEndTime: new Date(),
            holidayFullDay: true,
            holidayErrors: {
                title: '',
                startDate: '',
                endDate: ''
            },
            leaveTitle: "",
            leaveStartTime: new Date(),
            leaveEndTime: new Date(),
            leaveFullDay: true,
            leaveErrors: {
                title: '',
                startDate: '',
                endDate: ''
            },
            breakTimeError: '',
            enableBooking: true,
            enableGeneral: true,
            selectedEvent: 'Booking',
            showCancel: true,
            view: 'normal',
            startDt: new Date(),
            endDt: new Date(),
            setHosts: false,
            setAttendees: false,
            cellData: {},
            districts: [],
            showrooms: [],
            meetingrooms: [],
            district: '',
            showroom: '',
            meetingroom: '',
            timeSlotList: ["10-11", "11-12", "12-13", "13-14", "14-15", "15-16", "16-17", "17-18", "18-19", "19-20"],
            timeSlots: [
                {"text": "10-11", "value": "10-11"},
                {"text": "11-12", "value": "11-12"},
                {"text": "12-1", "value": "12-13"},
                {"text": "1-2", "value": "13-14"},
                {"text": "2-3", "value": "14-15"},
                {"text": "3-4", "value": "15-16"},
                {"text": "4-5", "value": "16-17"},
                {"text": "5-6", "value": "17-18"},
                {"text": "6-7", "value": "18-19"},
                {"text": "7-8", "value": "19-20"}
            ],
            availableSlots: [],
            bookedSlots: [],
            scheduleMsgShow: false,
            scheduleddates: [],
            toasterdata: {type: "", msg: "", show: false},
            custRecId: ''

        };

        this.actionComplete = this.actionComplete.bind(this);
        this.timeSlotAvailable = this.timeSlotAvailable.bind(this);
        this.manageTimeSlots = this.manageTimeSlots.bind(this);
        this.changeMeridiem = this.changeMeridiem.bind(this);
        this.findAvailableTimeSlots = this.findAvailableTimeSlots.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.selectSlot = this.selectSlot.bind(this);
        this.cancelAlert = this.cancelAlert.bind(this);
        this.loadSettings = this.loadSettings.bind(this);
        this.nextWorkingDay = this.nextWorkingDay.bind(this);
        this.bookNow = this.bookNow.bind(this);
        this.userSelection = this.userSelection.bind(this);
        this.setTimeSlotsForMeetingRooms = this.setTimeSlotsForMeetingRooms.bind(this);


        this.morePopup = {};
        this.loadState = 0;
        //this.workingDays = [1, 2, 3, 4, 5];
        //this.workingHours = {workTime: {startTime: '9:00', endTime: '17:00'}, breakTime: [{startTime: '13:00', endTime: '14:00'}]}
        this.workingDays = [0, 1, 2, 3, 4, 5, 6];
        this.workingHours = {workTime: {startTime: '10:00', endTime: '17:00'}, breakTime: []}

        this.districtElem = React.createRef();
        this.showroomElem = React.createRef();
        this.meetingroomElem = React.createRef();
        this.block3 = React.createRef();
        this.timeslotContainer = React.createRef();
    }


    findEndTimeWithSlotIntrvl(ts, startTime) {
        let es = ts;
        startTime = new Date(startTime).setHours(ts.split(':')[0], ts.split(':')[1], 0, 0);
        let endTime = new Date(new Date(startTime).getTime() + this.state.slotIntrvl * 60000);
        return endTime;
    }

    // return time value from timeslot like 02:00
    getTimeValue(tm, convert = 0) {
        if (convert === 1) {
            tm = this.convertTimeTo24(tm);
        }
        let st = tm.split(':');
        let sd = new Date(new Date().setHours(st[0], st[1])).getTime();
        return sd
    }

    convertTimeTo24(tm, type = 1) {

        if (type === 1) {
            let m = tm.split(' ')[1];
            let t = tm.split(' ')[0];
            let mnts = t.split(':')[1]
            t = parseInt(t.split(':')[0])

            if (m === 'AM' && t === 12) {
                t = 0;
            }
            if (m === 'PM' && t !== 12) {
                t = t + 12;
            }
            return (t + ':' + mnts);
        } else if (type === 2) {
            let t = tm.split(':');
            return (t[0] + ':' + t[1])
        } else if (type === 3) {
            let t = tm.split(':');
            let m = 'AM';
            let newTime = parseInt(t[0]);
            if (parseInt(t[0]) > 12) {
                newTime = parseInt(t[0] - 12);
                m = 'PM'
            }
            if (parseInt(t[0]) === 12) {
                m = 'PM'
            }
            newTime = newTime < 10 ? '0' + newTime : newTime;
            return newTime + ':' + t[1] + ' ' + m;
        }
    }

    loadSettings(settings) {
        console.log(settings)
        if (settings['Working days']) {
            this.workingDays = settings['Working days'];
            let breakTime = [];
            let breakTimeSt = [];
            settings['Break start time'].map((bt, bindx) => {
                    breakTime.push(
                        {
                            startTime: this.convertTimeTo24(settings['Break start time'][bindx], 2),
                            endTime: this.convertTimeTo24(settings['Break end time'][bindx], 2)
                        }
                    );
                    breakTimeSt.push(
                        {
                            startTime: this.convertTimeTo24(settings['Break start time'][bindx], 3),
                            endTime: this.convertTimeTo24(settings['Break end time'][bindx], 3)
                        }
                    );
                }
            )
            this.workingHours = {
                workTime: {
                    startTime: this.convertTimeTo24(settings['Work start time'], 2),
                    endTime: this.convertTimeTo24(settings['Work end time'], 2)
                },
                breakTime: breakTime
            }
            let workDays = [];
            this.state.workDays.map((wD, indx) => {
                    workDays.push({
                        id: wD.id,
                        value: wD.value,
                        isChecked: settings['Working days'].indexOf(parseInt(wD.id)) > -1 ? true : false
                    })
                }
            );
            let workHours = {
                workTime: {
                    startTime: this.convertTimeTo24(settings['Work start time'], 3),
                    endTime: this.convertTimeTo24(settings['Work end time'], 3)
                },
                breakTime: {startTime: '', endTime: ''}
            }


            this.setState({
                workDays: workDays,
                wD: settings['Working days'],
                workHours: workHours
            });


        }
    }


    cancelAlert() {
        this.setState({showAlertModal: false, alertMessage: ''})
    }


    handleChange(e) {
        let cellData = this.state.cellData;
        cellData[[e.target.name]] = e.target.value;
        this.setState({cellData: cellData});
    }

    handleDateChange(e, id, disableAll) {
        console.log(e)
        if (disableAll === false) {
            this.setState({bookingDt: e, id: id || '', bookingSlot: '', bookingMerdm: 'AM'});
            this.props.findAgentAvailableSlots({
                uid: getProfile().id,
                cid: ES_INDEX + "10033",
                date: moment(e).format('YYYY-MM-DD')
            });
        }
    }

    selectSlot(slot) {
        console.log(slot)
        let slotarr = slot.split('-');
        let startTime = '';
        let endTime = '';
        if (slotarr.length > 1) {
            console.log(slotarr)
            startTime = slotarr[0] + ":00 " + (parseInt(slotarr[0]) >= 12 ? 'PM' : 'AM');
            endTime = slotarr[1] + ":00 " + (parseInt(slotarr[1]) >= 12 ? 'PM' : 'AM');

        }
        this.setState({bookingSlot: slot, startTime: startTime, endTime: endTime});
    }

    changeMeridiem(dt, props, m) {
        this.setState({meridiem: m, t: new Date().getTime(), id: props.id, bookingMerdm: m});
    }

    findAvailableTimeSlots(dt, m = 'AM', host = '') {
        let newDt = new Date(dt.valueOf());
        newDt.setHours(0, 0, 0, 0);

        let slotsAvailable = this.manageTimeSlots(new Date(newDt), 1, m, host);
        return (slotsAvailable);
    }

    manageTimeSlots(dt, byMeridiem = 0, m = '', host = '') {

        let today = dt.getFullYear() + '-' + (parseInt(dt.getMonth()) + 1) + '-' + dt.getDate();
        let slots = this.state.slots;
        if (m !== '') {
            slots = this.state.slots.filter((a) => (a.m === m));
        }
        let timeSlotsAvailable = [];


        slots.map((slot, indx) => {
            let isSlotAvailable;
            let isSlotActive;
            let slotTime = slot.time.split('-');//13:30:00-14:00:00
            let st = slotTime[0].split(':');
            let et = slotTime[1].split(':');
            let startValue = new Date(dt.setHours(st[0], st[1], 0, 0));
            let endValue = new Date(dt.setHours(et[0], et[1], 0, 0));
            startValue = new Date(startValue.getTime() - (startValue.getTimezoneOffset() * 60000));
            endValue = new Date(endValue.getTime() - (endValue.getTimezoneOffset() * 60000));

            //isSlotAvailable = this.scheduleObj.isSlotAvailable(startValue, endValue);
            //isSlotAvailable = this.timeSlotAvailable(startValue, endValue, host);
            isSlotAvailable = this.timeSlotAvailable(slot, host);
            isSlotActive = this.isActiveSlot(slot.slot.split(' ')[0].replace('.', ':'), dt, host, slot.m)

            //console.log(isSlotAvailable);
            if (isSlotAvailable === true && isSlotActive === true) {
                if (byMeridiem === 0) {
                    timeSlotsAvailable.push(slot.slot);
                } else if (slot.m === m) {//12.00 AM - 12.30 AM
                    let st = slot.slot.split(' ')[0].replace('.', ':');
                    //console.log(st)
                    timeSlotsAvailable.push(st);
                }

            }

        });
        //console.log(timeSlotsAvailable)

        return (timeSlotsAvailable);

    }


    isLeave(slot, startTime, hostId) {
        let hr = 0;
        let mnt = 0;
        let m = this.state.bookingMerdm;
        let dt = new Date(startTime);
        slot = slot.split(':');
        hr = m === 'PM' ? parseInt(slot[0]) + 12 : parseInt(slot[0]);
        mnt = parseInt(slot[1]);
        let st = (dt.setHours(hr, mnt, 0, 0)) - (dt.getTimezoneOffset() * 60000);

        let et = (st + this.state.slotIntrvl * 60000);
        let leave = this.state.leaveList.filter((a) => (st >= new Date(a.start_time).getTime() && st < new Date(a.end_time).getTime() && a.host_id === hostId));
        if (leave.length > 0) {
            return true;
        } else {
            return false
        }
    }

    timeSlotAvailable(slot, host) {
        let bookings = [];
        let slotTime = slot.time.split("-");
        let hrs = parseInt(slotTime[0].split(":")[0]);
        let slotM = slot.m;
        if (slotM === 'PM' && hrs !== 12) {
            hrs = hrs + 12;
        }
        slot = hrs + ":" + slotTime[0].split(":")[1] + ":00"

        bookings = this.state.events.filter((a) =>
            (slot === a.time)
        );
        return bookings.length === 0 ? true : false;

    }

    isHoliday(dt, flag = 0) {
        dt = dt.getTime() - (dt.getTimezoneOffset() * 60000);
        let holiday = this.state.holidayList.filter((a) => (dt >= new Date(a.start_time).getTime() && dt <= new Date(a.end_time).getTime()));
        return holiday;
    }

    isBreakTime(dt) {
        dt = this.getTimeValue(dt, 0)
        let breakTime = this.workingHours.breakTime.filter((a) => (dt >= this.getTimeValue(a.startTime, 0) && dt < this.getTimeValue(a.endTime, 0)));
        return (breakTime.length > 0 ? true : false);
    }

    nextWorkingDay(dt) {
        if (this.workingDays.indexOf((dt).getDay()) < 0 ||
            this.isHoliday(dt).length > 0) {
            dt.setDate(dt.getDate() + 1);
            this.nextWorkingDay(dt)
        } else {
            return dt;
        }
    }


    bookNow = (args) => {

        console.log(args)
        // args['start_time'] = startTime;
        // args['end_time'] = endTime;
        // args['host_id'] = this.state.hostId;

        if (document.querySelector('.react-datepicker__day--selected').classList.contains('e-disableCell')) {
            this.setState({
                showAlertModal: true,
                alertMessage: 'Please select an active date',
                //bookingDt: args.start_time
            });
            // this.props.toggleModal(
            //     true,
            //     'Alert',
            //     'Please select an active date',
            //     true,
            //     this.props.toggleModal,
            //     false,
            //     this.props.toggleModal,
            //     'md'
            // );
        } else if (this.state.bookingDt === '') {
            this.setState({showAlertModal: true, alertMessage: 'Please select a date'});
            // this.props.toggleModal(
            //     true,
            //     'Alert',
            //     'Please select a date',
            //     true,
            //     this.props.toggleModal,
            //     false,
            //     this.props.toggleModal,
            //     'md'
            // );
        } else if (this.state.bookingSlot === '') {
            this.setState({
                showAlertModal: true,
                alertMessage: 'Please select a time slot',
            });
            // this.props.toggleModal(
            //     true,
            //     'Alert',
            //     'Please select a time slot',
            //     true,
            //     this.props.toggleModal,
            //     false,
            //     this.props.toggleModal,
            //     'md'
            // );
        } else {
            //alert(4)

            // let name = ES_INDEX === 'tata' ? '911236364' : '911236364';
            // let email = ES_INDEX === 'tata' ? '1641960947362' : '1641960947362';
            // let contact_number = ES_INDEX === 'tata' ? '1641960947402' : '1641960947402';
            // let recordId = ES_INDEX === 'tata' ? '1466653631' : '1466653631';
            // let attendee_id = '';

            let params = {
                "schedulingdate": moment(this.state.bookingDt).format('YYYY-MM-DD'),
                "slot": this.state.bookingSlot,
                "name": this.props.bookingCustName,
                "email": this.props.bookingCustEmail,
                "contact_number": this.props.bookingCustPhone,
                "attendeeid": this.state.custRecId,
                "recordId": this.props.bookingCustId,
                "customerUid": this.props.bookingCustUid,
            }
            console.log(params);
            //return false;
            this.props.saveCallSchedule(params);
        }
    }


    actionComplete(data) {

        let startTime = new Date();

        if (data) {
            alert(5)
            console.log(data)

            startTime = new Date(data.start_time.setSeconds(0, 0));
            let endTime = new Date(data.end_time.setSeconds(0, 0));
            startTime = startTime.getTime() - (startTime.getTimezoneOffset() * 60000)
            startTime = new Date(startTime);
            endTime = endTime.getTime() - (endTime.getTimezoneOffset() * 60000)
            endTime = new Date(endTime);
            let mon = parseInt(startTime.getUTCMonth()) + 1;
            let hr = parseInt(startTime.getUTCHours());
            let mnt = parseInt(startTime.getUTCMinutes());

            mnt = mnt < 10 ? '0' + mnt : mnt;
            data['full-time'] = hr + ":" + mnt + ":00";
            if (hr > 12) {
                data['time'] = (hr - 12) + ':' + mnt + ' PM';
            } else if (hr === 12) {
                data['time'] = hr + ':' + mnt + ' PM';
            } else {
                data['time'] = hr + ':' + mnt + ' AM';
            }
            let ehr = parseInt(endTime.getUTCHours());
            let emnt = parseInt(endTime.getUTCMinutes());

            emnt = emnt < 10 ? '0' + emnt : emnt;
            if (ehr > 12) {
                data['etime'] = (ehr - 12) + ':' + emnt + ' PM';
            } else if (ehr === 12) {
                data['etime'] = ehr + ':' + emnt + ' PM';
            } else {
                data['etime'] = ehr + ':' + emnt + ' AM';
            }
            //alert(new Date(startTime.getUTCFullYear(), mon, startTime.getUTCDate(), parseInt(startTime.getUTCHours()), parseInt(startTime.getUTCMinutes())))
            //let instance = new Internationalization();
            //e.data[0]['time'] = instance.formatDate(startTime, { skeleton: 'hm' });
            data['start_time'] = new Date(startTime.getUTCFullYear(), startTime.getUTCMonth(), startTime.getUTCDate(), parseInt(startTime.getUTCHours()), parseInt(startTime.getUTCMinutes()));
            data['end_time'] = new Date(endTime.getUTCFullYear(), endTime.getUTCMonth(), endTime.getUTCDate(), parseInt(endTime.getUTCHours()), parseInt(endTime.getUTCMinutes()));
            data['month'] = mon;
            data['year'] = startTime.getUTCFullYear();
            data['date'] = startTime.getUTCFullYear() + '-' + mon + '-' + startTime.getUTCDate();
            data['host_id'] = this.state.hostId;
            data['full_day'] = data['IsAllDay'] === true ? 'Yes' : 'No';
            data['category'] = 'Booking'
            data['subject'] = '';
            data['eventId'] = this.state.eventId;
            data['ppeid'] = this.state.ppeid;

            console.log(data);
            console.log({
                data: data,
                action: 'save',
                hostId: this.state.hostId,
                userRole: this.state.userRole,
                emailId: this.state.emailId,
                month: this.state.month,
                permissions: this.state.permissions,
                view: this.state.view,
                patientId: this.state.patientId,
                postEventId: this.state.postEventId,
                currentEventId: this.state.currentEventId
            });
            return false;
            this.props.saveBooking(
                {
                    data: data,
                    action: 'save',
                    hostId: this.state.hostId, //Agent Unique Id
                    userRole: "", //
                    emailId: this.state.emailId, //Email Id from customer collection
                    month: this.state.month,
                    permissions: this.state.permissions,
                    view: this.state.view,
                    patientId: this.state.patientId,
                    postEventId: this.state.postEventId,
                    currentEventId: this.state.currentEventId
                });
            this.props.showUserDetails(
                {
                    data: data,
                    action: 'save',
                    hostId: this.state.hostId,
                    userRole: this.state.userRole,
                    emailId: this.state.emailId,
                    month: this.state.month,
                    permissions: this.state.permissions,
                    view: this.state.view,
                    patientId: this.state.patientId,
                    postEventId: this.state.postEventId,
                    currentEventId: this.state.currentEventId,
                    userSelection: this.state
                });
        }
    }

    userSelection(e) {
        this.setState({[e.target.name]: e.target.value});
        // if (e.target.name === 'district') {
        //     this.setState({showroom: '', meetingroom: '', bookingMerdm: 'AM'}, () => {
        //         this.districtElem.current.focus();
        //     })
        //     this.props.findShowrooms({district: e.target.value});
        // }
        // if (e.target.name === 'showroom') {
        //     this.setState({meetingroom: '', bookingMerdm: 'AM'}, () => {
        //         this.showroomElem.current.focus();
        //     })
        //     this.props.findSalespersons({showroom: e.target.value});
        // }
        if (e.target.name === 'meetingroom') {
            if (this.state.meetingrooms.filter((a) => a.id === e.target.value).length > 0) {
                let slots = this.state.meetingrooms.filter((a) => a.id === e.target.value)[0]['slots']
                /*let slots = [
                    "10:00am to 10:30am",
                    "10:30am to 11:00am",
                    "11:00am to 11:30am",
                    "11:30am to 12:00pm",
                    "12:00pm to 12:30pm",
                    "12:30pm to 1:00pm",
                    "1:00pm to 1:30pm",
                    "1:30pm to 2:00pm",
                    "2:00pm to 2:30pm",
                    "2:30pm to 3:00pm",
                    "3:00pm to 3:30pm",
                    "3:30pm to 4:00pm",
                    "4:00pm to 4:30pm",
                    "4:30pm to 5:00pm",
                ]*/
                this.setTimeSlotsForMeetingRooms(slots);

            }
        }
    }

    setTimeSlotsForMeetingRooms(data) {
        let slots = [];//10:00am to 10:30am
        let timeSlots = [];//['01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'];
        /*console.log(data)*/
        data.map((d, indx) => {
            let tm = d.timeslot;
            let parts = tm.split(' to ');
            let m1 = parts[0].slice(-2);
            let m2 = parts[1].slice(-2);
            let t1 = parts[0].replace(m1, '');
            let t2 = parts[1].replace(m2, '');
            let s1 = t1.replace(":", ".");
            let s2 = t2.replace(":", ".");
            slots.push({
                id: d.id,
                time: t1 + ":00-" + t2 + ":00",
                slot: s1 + " " + m1.toUpperCase() + " - " + s2 + " " + m2.toUpperCase(),
                m: m1.toUpperCase()
            });
            timeSlots.push(t1);
        })
        /*console.log(slots);
        console.log(timeSlots);*/
        this.setState({slots: slots, timeSlots: timeSlots});
        this.setState({bookingDt: '', bookingMerdm: 'AM'}, () => {
            this.meetingroomElem.current.focus();
        });
    }


    componentDidMount() {

        this.getAllSlots();

        this.getScheduleCalls()

        this.findAgentAvailableTimeSlots();

        let topElem = document.getElementsByClassName("main-wrapper")[0];
        topElem.scrollTop = 0;

        //this.props.findCities();
        this.loadState = 0;
        //console.log(getProfile());
        localStorage.setItem("calD", JSON.stringify({}));
        const query = new URLSearchParams(window.location.search);
        let hostId = 0;
        let emailId = '';
        let attendeeId = '';
        let eventId = '';

        let month = query.get('month') || '';
        let userRole = '';
        let attendeeRole = userRole === 'Client';
        let startDt = new Date();
        let endDt = new Date();
        let view = 'normal';
        let bookingAvailableFromDt = new Date();
        let ppeid;
        let eventid;
        let patientId;
        let postEventId;
        let currentEventId
        if (bookingAvailableFromDt.getHours() < 12) {
            bookingAvailableFromDt.setDate(bookingAvailableFromDt.getDate() + 1);
        }
        if (bookingAvailableFromDt.getHours() >= 12) {
            bookingAvailableFromDt.setDate(bookingAvailableFromDt.getDate() + 2);
        }
        hostId = this.state.hostId;
        emailId = hostId;
        attendeeId = query.get('attendee') ? query.get('attendee') : '';
        eventId = query.get('eventid') ? query.get('eventid') : '';
        ppeid = query.get('ppeid') ? query.get('ppeid') : '';
        patientId = query.get('patientId') ? query.get('patientId') : '';
        postEventId = query.get('postEventId') ? query.get('postEventId') : '';
        currentEventId = query.get('currentEventId') ? query.get('currentEventId') : '';
        let startd = new Date();//query.get('sd')/
        if (startd) {
            //let sd = startd.split('-');
            startDt = new Date();
            endDt = new Date();
            if (startDt < bookingAvailableFromDt) {
                let nextWDt = this.nextWorkingDay(bookingAvailableFromDt);
                startDt = this.nextWorkingDay(bookingAvailableFromDt);
                endDt = new Date(startDt.valueOf());
                endDt.setDate(endDt.getDate() + 6);
            } else {
                let nextWDt = this.nextWorkingDay(startDt);
                startDt = this.nextWorkingDay(startDt);
                endDt = new Date(startDt.valueOf());
                endDt.setDate(endDt.getDate() + 6);
            }
            endDt.setHours(0, 0, 0);

        }

        // view = 'pt';
        hostId = 0;


        //let timeSlots = this.createTimeSlots(slotConfig);
        let timeSlots = this.state.timeSlots;//['01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'];

        this.setState({
            hostId: hostId,
            emailId: emailId,
            month: month,
            attendeeId: attendeeId,
            eventId: eventId,
            ppeid: ppeid,
            timeSlots: timeSlots,
            userRole: userRole,
            attendeeRole: attendeeRole,
            view: view,
            startDt: startDt,
            //bookingDt: startDt,//startDt,
            endDt: endDt,
            patientId: patientId,
            postEventId: postEventId,
            currentEventId: currentEventId

        });
        let permissions = {
            booking: true,
            booked: true,
            holiday: true,
            leave: true
        }


        this.setState({
            permissions: permissions
        });

        //this.props.findHolidays();
        //this.props.findLeaves(hostId);
        //this.props.getCalendarSettings();

        /*this.props.findBookingsAndLeaves(
            {
                hostId: hostId,
                userRole: userRole,
                emailId: emailId,
                permissions: permissions
            });*/
    }


    componentDidUpdate(prevProps, prevState, snapshot) {


        if (this.props.BookingSlots !== prevProps.BookingSlots) {
            let slotList = this.props.BookingSlots.slotList || [];
            let allSlots = this.props.BookingSlots.allSlots || [];
            let availableSlots = this.props.BookingSlots.availableSlots || [];
            let bookedSlots = this.props.BookingSlots.bookedSlots || [];

            this.setState({
                availableSlots: availableSlots,
                bookedSlots: bookedSlots,
                slotList: slotList
            })

        }
        if (this.props.GetCallData.callsaved !== prevProps.GetCallData.callsaved) {
            let that = this;
            that.setState({
                scheduleMsgShow: true,
                toasterdata: {type: "green", msg: "A call has been scheduled", show: true},
                bookingSlot: '',
                bookingDt: new Date()
            }, function () {
                this.findAgentAvailableTimeSlots()
            })

            let params = {uid: getProfile().id}
            this.props.getScheduleCalls(params);
        }
        if (this.props.GetCallData.scheduleddates !== prevProps.GetCallData.scheduleddates) {

            this.setState({
                scheduleddates: this.props.GetCallData.scheduleddates
            });


        }


        if (this.props.resourceBooking !== prevProps.resourceBooking) {
            let resourceBooking = this.props.resourceBooking.data || [];
            let obj = this;
            this.setState({events: resourceBooking}, () => {
                this.block3.current.focus();
            });
        }

        if (this.props.getFormData.formvalues !== prevProps.getFormData.formvalues && this.props.getFormData.formvalues.length > 0) {

            let formValues = this.props.getFormData.formvalues;
            console.log(formValues)

            let custRecId = '';

            let userNameInfo = formValues.filter(fv => fv.masterid === "911236364");
            if (userNameInfo.length > 0) {
                if (userNameInfo[0] && userNameInfo[0].values && userNameInfo[0].values.length > 0) {

                    //console.log(userNameInfo[0].values[0].recordid)
                    custRecId = userNameInfo[0].values[0].recordid || '';

                    this.setState({
                        custRecId: custRecId
                    })

                }
            }

        }

        /*if(this.props.hostEvents !== prevProps.hostEvents) {
            let hostEvents = this.props.hostEvents.list || [];
            let obj = this;
            this.setState({events: hostEvents}, () => {
                this.loadState += 1;
                if (this.state.attendeeId !== '') {
                    obj.preFilledAppointment();
                }
            });
        }
        if(this.props.hostEvents.list.length === 0 && prevState.attendeeId === '' && this.state.attendeeId !== '') {
            this.preFilledAppointment();
        }
        if(this.props.calendarSettings !== prevProps.calendarSettings) {
            this.loadSettings(this.props.calendarSettings.list)
        }
        if(this.props.calendarHolidays !== prevProps.calendarHolidays) {
            this.setState({holidayList: this.props.calendarHolidays.list}, () => {this.loadState += 1});
        }
        if(this.props.hostLeaves !== prevProps.hostLeaves) {
            this.setState({leaveList: this.props.hostLeaves.list}, () => {this.loadState += 1});
        }*/

        // if (this.props.cities !== prevProps.cities) {
        //     this.setState({districts: this.props.cities.data}, () => {
        //         this.districtElem.current.focus();
        //     });
        // }
        // if (this.props.showrooms !== prevProps.showrooms) {
        //     this.setState({showrooms: this.props.showrooms.data}, () => {
        //         this.showroomElem.current.focus();
        //     });
        // }
        if (this.props.salespersons !== prevProps.salespersons) {
            this.setState({meetingrooms: this.props.salespersons.data}, () => {
                this.meetingroomElem.current.focus();
            });
        }
        if (this.props.bookingStatus !== prevProps.bookingStatus) {
            //this.setState({bookingStatus: this.props.bookingStatus});
        }

    }

    getScheduleCalls = () => {
        let params = {uid: getProfile().id}
        this.props.getScheduleCalls(params);
    }

    getAllSlots = () => {
        let params = {"cid": "2_207157244562", "date": moment().format("DD-MM-YYYY")}
        this.props.getAllSlots(params);
    }

    /*isActiveSlot(slot, startTime, hostId, bookingMerdm) {
        let bookedSlots = this.state.events.map((a) => a.time);
        console.log(bookedSlots);
        let slt;
        if(bookingMerdm === 'PM') {
            slt = parseInt(slot.split(':')[0]) + 12;
            slot = slt + ':' + slot.split(':')[1] + ':00';
        } else {
            slt = parseInt(slot.split(':')[0]);
            slot = slt + ':' + slot.split(':')[1] + ':00';
        }
        console.log(slot + bookedSlots.indexOf(slot))
        if(bookedSlots.indexOf(slot) > -1) {
            return false;
        } else {
            return true
        }
    }*/

    findAgentAvailableTimeSlots = () => {

        this.props.findAgentAvailableSlots({
            uid: getProfile().id,
            cid: "2_207157244562",
            date: moment(new Date()).format('YYYY-MM-DD')
        });
    }

    isActiveSlot(slot, startTime, hostId, bookingMerdm) {

        let timeslot = slot.valueOf();
        let slt;
        let wT = this.workingHours.workTime;
        let bT = this.workingHours.breakTime;

        if (bookingMerdm === 'PM' && parseInt(slot.split(':')[0]) !== 12) {
            slt = parseInt(slot.split(':')[0]) + 12;
            slot = slt + ':' + slot.split(':')[1];
        } else {
            slt = parseInt(slot.split(':')[0]);
            slot = slt + ':' + slot.split(':')[1];
        }


        let slotTime = this.getTimeValue(slot, 1);
        let breakCondn = true;
        /*bT.map((b, indx) => {
            let st = this.getTimeValue(b.startTime, 0);
            let et = this.getTimeValue(b.endTime, 0);
            breakCondn = breakCondn && !(slotTime >= st && slotTime < et)
        });*/

        //console.log(slot  + '-' + parseInt(wT.startTime.split(':')[0]) + '-' + parseInt(wT.endTime.split(':')[0]) + '-' + parseInt(bT.startTime.split(':')[0]));

        if (slotTime >= this.getTimeValue(wT.startTime, 0) &&
            slotTime < this.getTimeValue(wT.endTime, 0) &&
            breakCondn
        ) {
            let hr = 0;
            let mnt = 0;
            let m = this.state.bookingMerdm;
            let dt = new Date(startTime);

            slot = slot.split(':');
            hr = parseInt(slot[0]);
            mnt = parseInt(slot[1]);
            let st = new Date(dt.setHours(hr, mnt, 0, 0));
            let isHoliday = this.isHoliday(st, 1);

            //console.log(this.isLeave(timeslot, startTime, hostId))
            //console.log(isHoliday.length)
            if (this.isLeave(timeslot, startTime, hostId) || isHoliday.length > 0) {

                return false
            } else {

                return true;
            }
        } else {
            return false;
        }
    }

    toasterClose = () => {
        this.setState({toasterdata: {type: "", msg: "", show: false}})
    }

    selectMeridiem = (type) => {
        this.setState({
            bookingMerdiem: type
        })
    }


    render() {
        let props = this.state.cellData;
        console.log(this.state.events);
        if (Object.keys(props).length === 0 || isNaN(props.start_time.getTime())) {
            let calD = localStorage.getItem("calD") ? JSON.parse(localStorage.getItem("calD")) : {};
            props = calD.argsData || {};
            let st = props.start_time ? new Date(props.start_time) : new Date(new Date().setHours(0, 0, 0, 0));
            let et = props.end_time ? new Date(props.end_time) : new Date(new Date().setHours(0, 0, 0, 0));
            props.start_time = (new Date(st.getUTCFullYear(), st.getUTCMonth(), st.getUTCDate(), st.getUTCHours(), st.getUTCMinutes()));
            props.end_time = (new Date(et.getUTCFullYear(), et.getUTCMonth(), et.getUTCDate(), et.getUTCHours(), et.getUTCMinutes()));

            if (!props.masterid && this.state.bookingSlot === '') {
                props.start_time.setHours(0, 0, 0, 0);
            }
        }


        //let timeSlots = this.state.timeSlotList;
        // //console.log(timeSlots);
        // if (this.state.bookingMerdm === 'PM' && timeSlots.indexOf('12:00') < 0) {
        //     timeSlots.unshift('12:30')
        //     timeSlots.unshift('12:00')
        //
        // }
        // if (this.state.bookingMerdm === 'AM' && timeSlots.indexOf('12:00') > -1) {
        //     timeSlots.splice(timeSlots.indexOf('12:00'), 1)
        //     timeSlots.splice(timeSlots.indexOf('12:30'), 1)
        // }
        // let dt = props.start_time ? new Date(props.start_time) : new Date();
        // let today = dt.getDate() + '-' + (parseInt(dt.getMonth()) + 1) + '-' + dt.getFullYear();
        // let slotsAvailable = this.state.slotsAvailable;
        let instance = new Internationalization();
        //
        //
        // let attendee = ''
        // if (this.state.selectedAttendee !== '' && this.state.attendeeAdded === true) {
        //     attendee = this.state.selectedAttendee;
        // }
        // let bookdTimeSlot = props.start_time ? instance.formatDate(dt, {skeleton: 'hm'}) : '';
        // //console.log(bookdTimeSlot)
        // let bs = bookdTimeSlot.split(' ')[0];
        // bs = bs.split(':')[0].length === 1 ? '0' + bs : bs;
        // let timeSlotSelected = this.state.bookingSlot !== '' ? this.state.bookingSlot + ' ' + this.state.bookingMerdm : props.start_time ? instance.formatDate(dt, {skeleton: 'hm'}) : '';
        // let ts = timeSlotSelected.split(' ')[0];
        // let tm = timeSlotSelected.split(' ')[1];
        // let slt = ts.valueOf();
        // if (props.masterid === undefined && this.state.bookingSlot === '') {
        //     slt = '';
        // }
        // if (tm === 'PM' && parseInt(ts) !== 12) {
        //     ts = (parseInt(ts.split(':')[0]) + 12) + ':' + ts.split(':')[1];
        // }
        // ts = ts.split(':')[0].length === 1 ? '0' + ts : ts

        // let startTime = this.state.bookingDt === '' ? props.start_time : this.state.bookingDt;
        // let endTime = new Date();
        //
        // if (startTime) {
        //     endTime = new Date(startTime.valueOf());
        //     let es = '';
        //     endTime = this.findEndTimeWithSlotIntrvl(ts, endTime);
        //     startTime.setHours(ts.split(':')[0] === "12" && tm === 'AM' ? 0 : ts.split(':')[0], ts.split(':')[1]);
        // }
        //
        //
        // slotsAvailable = this.findAvailableTimeSlots(startTime, this.state.bookingMerdm, this.state.hostId);
        //
        // let calD = localStorage.getItem("calD") ? JSON.parse(localStorage.getItem("calD")) : {};
        // if (this.state.showAlertModal === false) {
        //
        //     calD.st = startTime;
        //     calD.et = endTime;
        //     //console.log('************* 5');
        //     localStorage.setItem("calD", JSON.stringify(calD));
        // }
        //
        // if (calD.tm === this.state.bookingMerdm && calD.ts !== "") {
        //     //slotsAvailable.push(calD.ts);
        // }


        //let limitStarts = new Date(this.state.startDt.valueOf());
        let limitStarts = new Date();
        limitStarts.setHours(0, 0, 0)
        let limitEnds = new Date(this.state.endDt.valueOf());
        limitEnds.setHours(0, 0, 0)
        let disableAll = false;

        // let slotsAvailableAM = this.findAvailableTimeSlots(startTime, 'AM', this.state.hostId);
        // let slotsAvailablePM = this.findAvailableTimeSlots(startTime, 'PM', this.state.hostId);
        //let activeSlotsCnt = parseInt(slotsAvailableAM.length) + parseInt(slotsAvailablePM.length);

        let timeSlots = this.state.slotList || [];
        let bookedSlots = this.state.bookedSlots;

        let availableSlots = this.state.availableSlots;
        let activeSlotsCnt = parseInt(availableSlots.length);


        let slt = this.state.bookingSlot;

        let scheduleddates = this.state.scheduleddates || [];
        // scheduleddates = ["2022-01-21", "2022-01-18", "2022-01-25"]

        console.log(this.state.bookingDt)
        console.log(scheduleddates)


        return (
            <div className="main-wrapper-no-layout">
                <div className={`calendar-wrapper patient-wrapper`}>

                    <div
                        className="e-dlg-container e-dlg-center-center e-schedule-dialog-container book-appointment-modal patient-modal"
                        style={{zIndex: 1003, display: "flex", position: "fixed"}}>
                        <div id="_dialog_wrapper"
                             className="e-lib e-dialog e-control e-schedule-dialog e-dlg-modal e-popup e-popup-open"
                             role="dialog"
                             style={{
                                 zIndex: 1003,
                                 width: '500px',
                                 left: '0px',
                                 top: '0px',
                                 minHeight: '300px',
                                 position: 'relative'
                             }}
                             aria-labelledby="_dialog_wrapper_title" aria-describedby="_dialog_wrapper_dialog-content"
                             aria-modal="true" tabIndex="-1">
                            <div className="e-dlg-header-content" id="_dialog_wrapper_dialog-header">

                                <div className="e-dlg-header" id="_dialog_wrapper_title">
                                    <div className="e-title-text">
                                        {getUrlSegment(1) === 'virtualshowroom' ? ' Call Schedule' : 'Book an Appointment'}

                                        {this.props.bookingCustName && this.props.bookingCustName !== '' &&
                                        <> - {this.props.bookingCustName}</>
                                        }

                                    </div>
                                    {this.state.scheduleMsgShow &&
                                    <Toaster
                                        toasterdata={this.state.toasterdata}
                                        toasterClose={this.toasterClose}
                                    />
                                    }
                                </div>
                                <button className="general-btn" onClick={() => {
                                    this.props.showLeaveModal(true)
                                }}>Mark Leave
                                </button>
                            </div>
                            <div className="e-dlg-content" id="_dialog_wrapper_dialog-content">
                                <div className="e-form-container">
                                    <div className="e-schedule-form">
                                        <div>

                                            <div className="custom-event-editor">
                                                <div className="event-editor-block-2"
                                                     key={`calendar-block`}>
                                                    {/*<div className="editor-section">*/}
                                                    {/*    <div className="editor-section-title">DATE AND TIME</div>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="hide">*/}
                                                    {/*    <input type="text"*/}
                                                    {/*           value={startTime}*/}
                                                    {/*           className="e-field"*/}
                                                    {/*           id="start_time"*/}
                                                    {/*           data-name="start_time"*/}
                                                    {/*           onChange={this.handleChange}*/}
                                                    {/*    />*/}
                                                    {/*    <input type="text"*/}
                                                    {/*           id="end_time"*/}
                                                    {/*           data-name="end_time"*/}
                                                    {/*           value={endTime}*/}
                                                    {/*           className="e-field"*/}
                                                    {/*           onChange={this.handleChange}/>*/}
                                                    {/*</div>*/}
                                                    <DatePicker
                                                        selected={this.state.bookingDt === '' ? new Date() : this.state.bookingDt}
                                                        onChange={(e) => this.handleDateChange(e, props.id, disableAll)}
                                                        placeholderText=""
                                                        id={this.props.id ? this.props.id : 'date'}
                                                        className={"form-type-input " + (this.props.addErrorClass ? " red-alert" : "")}
                                                        autoComplete={'off'}
                                                        showMonthYearDropdown={false}
                                                        open={true}
                                                        dateFormat="eee, d MMM"
                                                        //todayButton={false}
                                                        minDate={disableAll === true ? '' : limitStarts}
                                                        /*maxDate={limitEnds}*/
                                                        dayClassName={date => {

                                                            let formatted_date = moment(date).format('YYYY-MM-DD');

                                                            // console.log(scheduleddates)
                                                            // console.log(date)
                                                            let classname = (disableAll === true ? "react-datepicker__day--disabled" : ((this.workingDays.indexOf((date).getDay()) < 0) ? "e-disableCell" : ""));
                                                            classname += scheduleddates.includes(formatted_date) ? " react-datepicker__day--selected-sc" : '';
                                                            return classname;
                                                        }
                                                        }
                                                        disabledKeyboardNavigation
                                                        autoFocus={false}
                                                        animation={false}
                                                        popperClassName="booking-calendar-datepicker"
                                                        popperPlacement="top-end"
                                                        readOnly={disableAll === true ? true : false}
                                                        nextMonthButtonLabel={""}
                                                        previousMonthButtonLabel={""}

                                                    />

                                                </div>
                                                <div className="event-editor-block-3" ref={this.block3}>
                                                    <div className="even-time-block">
                                                        {/*<h5>{instance.formatDate(this.state.bookingDt === '' ? new Date() : this.state.bookingDt, {skeleton: 'E'})}, <span*/}
                                                        {/*    className="font-bold-dark">{instance.formatDate(this.state.bookingDt === '' ? new Date() : this.state.bookingDt, {skeleton: 'yMMMM'})}</span>*/}
                                                        {/*</h5>*/}

                                                        <h5>{moment(this.state.bookingDt === '' ? new Date() : this.state.bookingDt).format('ddd, DD MMMM YYYY')}
                                                        </h5>

                                                        {/*<div className="time-meridiem-wrap">*/}
                                                        {/*    <div*/}
                                                        {/*        className={`time-meridiem${this.state.bookingMerdm === 'AM' ? ' active' : ''}`}*/}
                                                        {/*        onClick={(e) => this.changeMeridiem(startTime, props, 'AM')}>AM*/}
                                                        {/*    </div>*/}
                                                        {/*    <div*/}
                                                        {/*        className={`time-meridiem${this.state.bookingMerdm === 'PM' ? ' active' : ''}`}*/}
                                                        {/*        onClick={(e) => this.changeMeridiem(startTime, props, 'PM')}>PM*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}

                                                        {/*<div className="time-meridiem-wrap">*/}
                                                        {/*    <div*/}
                                                        {/*        className={`time-meridiem${this.state.bookingMerdiem === 'AM' ? ' active' : ''}`}*/}
                                                        {/*        onClick={(e) => this.selectMeridiem('AM')}>AM*/}
                                                        {/*    </div>*/}
                                                        {/*    <div*/}
                                                        {/*        className={`time-meridiem${this.state.bookingMerdiem === 'PM' ? ' active' : ''}`}*/}
                                                        {/*        onClick={(e) => this.selectMeridiem('PM')}>PM*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}


                                                        {this.state.bookingDt !== '' &&
                                                        <div className="time-slots" ref="timeslotContainer">
                                                            <div
                                                                className={`${activeSlotsCnt === 0 ? 'error-field' : 'day-slots-avaible-cnt'}`}>
                                                                {`${activeSlotsCnt === 0 ? 'No slots' : activeSlotsCnt === 1 ? activeSlotsCnt + ' Slot' : activeSlotsCnt + ' Slots'} available for ${instance.formatDate(this.state.bookingDt, {skeleton: 'yMMMd'})}`}</div>


                                                            <h5>AM</h5>
                                                            {timeSlots.slice(0, 6).map((slot, indx) => (
                                                                <React.Fragment key={`times-${indx}`}>

                                                                    <div
                                                                        className={`time-slot${(slt === slot.value || (!availableSlots.includes(slot.value))) ? ' inactive' : (availableSlots.indexOf(slot.value) > -1) ? ' not-booked' : ' inactive'}`}
                                                                        key={`timeslot-${indx}`}
                                                                        onClick={(e) => this.selectSlot(slot.value)}>
                                                                        {slot.text}
                                                                    </div>

                                                                </React.Fragment>
                                                            ))}
                                                            <h5>PM</h5>
                                                            {timeSlots.slice(6).map((slot, indx) => (
                                                                <React.Fragment key={`times-${indx}`}>

                                                                    <div
                                                                        className={`time-slot${(slt === slot.value || (!availableSlots.includes(slot.value))) ? ' inactive' : (availableSlots.indexOf(slot.value) > -1) ? ' not-booked' : ' inactive'}`}
                                                                        key={`timeslot-${indx}`}
                                                                        onClick={(e) => this.selectSlot(slot.value)}>
                                                                        {slot.text}
                                                                    </div>

                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                        }

                                                        {this.state.bookingDt === '' &&
                                                        <div className="time-slots" ref="timeslotContainer">
                                                            <h5>AM</h5>

                                                            {timeSlots.slice(0, 6).map((slot, indx) => (
                                                                <React.Fragment key={`times-${indx}`}>

                                                                    <div
                                                                        className={`time-slot inactive`}
                                                                        key={`timeslot-${indx}`}
                                                                        onClick={(e) => this.selectSlot(slot.value)}>
                                                                        {slot.text}
                                                                    </div>

                                                                </React.Fragment>
                                                            ))}
                                                            <h5>PM</h5>

                                                            {timeSlots.slice(6).map((slot, indx) => (
                                                                <React.Fragment key={`times-${indx}`}>

                                                                    <div
                                                                        className={`time-slot  inactive `}
                                                                        key={`timeslot-${indx}`}
                                                                        onClick={(e) => this.selectSlot(slot.value)}>
                                                                        {slot.text}
                                                                    </div>

                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                        }


                                                    </div>
                                                    {getUrlSegment('1') !== 'virtualshowroom' &&
                                                    <div className="e-footer-content">
                                                        <button type="button"
                                                                className="e-control e-btn e-lib e-primary e-event-save e-flat"
                                                                onClick={(e) => this.bookNow(this.state.cellData)}
                                                                data-ripple="true">Book Now
                                                        </button>
                                                    </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.showAlertModal === true &&
                    <AlertModal id={this.state.modalid}
                                title={this.state.alertMessage}
                                OK_button={this.state.alertButtons === true ? 'show' : 'hide'}
                                OK_button_text="OK"
                                OK_action={this.cancelAlert}
                                showModal={true}
                                CANCEL_action={this.cancelAlert}
                                CANCEL_button={this.state.alertButtons === true ? 'show' : 'hide'}
                                wid={document.documentElement.clientWidth}
                    />

                    }
                    {this.props.calendarMessage.show === true &&
                    <AlertModal id={this.state.modalid}
                                title={this.props.calendarMessage.message}
                                OK_button='hide'
                                OK_button_text="OK"
                                OK_action={this.cancelAlert}
                                showModal={true}
                                CANCEL_action={this.cancelAlert}
                                CANCEL_button='hide'
                                wid={document.documentElement.clientWidth}
                    />
                    }


                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    cities: state.SchedulerReducer.Cities,
    showrooms: state.SchedulerReducer.Showrooms,
    salespersons: state.SchedulerReducer.Salespersons,
    resourceBooking: state.SchedulerReducer.ResourceBooking,
    hostEvents: state.SchedulerReducer.HostEvents,
    loader: state.SchedulerReducer.Loader,
    calendarSettings: state.SchedulerReducer.CalendarSettings,
    calendarHolidays: state.SchedulerReducer.CalendarHolidays,
    hostLeaves: state.SchedulerReducer.HostLeaves,
    calendarMessage: state.SchedulerReducer.CalendarMessage,
    bookingStatus: state.SchedulerReducer.BookingStatus,

    BookingSlots: state.SchedulerReducer.BookingSlots,
    GetCallData: state.SchedulerReducer.GetCallData,
    getFormData: state.CollectionsReducer.getFormData

});

export default connect(mapStateToProps, {
    //findCities: SchedulerActions.findCities,
    //findShowrooms: SchedulerActions.findShowrooms,
    findSalespersons: SchedulerActions.findSalespersons,
    findResourceBookings: SchedulerActions.findResourceBookings,
    saveBooking: SchedulerActions.saveBooking,
    getCalendarSettings: SchedulerActions.getCalendarSettings,
    findHolidays: SchedulerActions.findHolidays,

    findAgentAvailableSlots: SchedulerActions.findAgentAvailableSlots,
    saveCallSchedule: SchedulerActions.saveCallSchedule,
    getScheduleCalls: SchedulerActions.getScheduleCalls,

    getAllSlots: SchedulerActions.getAllSlots

})(BookingCalendar);
